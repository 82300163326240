import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {getFormattedDate} from "../../integration/CommonUtils";

class ResourceRequestCell extends Component {
  render() {
    const project = JSON.parse(JSON.stringify(this.props.dataItem));

    let isValidStatus = false;
    let isPMAllocated = false;
    let isConvertRequest = false
    let isAllocateResourceVisible = false;
    let isWaitingConfirm = false;
    let isClosedStatus = false;
    let preSalesPM = false;
    let pendingReviseCMS = [];

    const projectProjectManagers = project.AllocatedResourceRequestPM;
        //find valid pms from end date
    let projValidManagers = [];
    projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

    if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
        projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
        const lastAssignedManager = projectProjectManagers[0];
        projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
    }

    projValidManagers.forEach(value => {
        if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
            preSalesPM = true;
        }
    });

    // if (project.ProjectStatus.StatusName === 'Working At Risk' || project.ProjectStatus.StatusName === 'Active' || project.ProjectStatus.StatusName === 'Complete' ||
    // project.ProjectStatus.StatusName === 'Closed' || project.ProjectStatus.StatusName === 'Closed-Opportunity Loss' || 
    // project.ProjectStatus.StatusName === 'Close without bidding' || project.ProjectStatus.StatusName === 'Discontinue') {
    //   isValidStatus = true
        if (project.StatusConvertedToActive === true) {
            isConvertRequest = true;
        }
    // }

    if(project.ProjectStatus.StatusName === 'Waiting Confirmation') {
      if (preSalesPM) {
        isWaitingConfirm = true
      }
    } else if (project.ProjectStatus.StatusName === 'Closed' || project.ProjectStatus.StatusName === 'Closed-Opportunity Loss' || 
    project.ProjectStatus.StatusName === 'Close without bidding' || project.ProjectStatus.StatusName === 'Discontinue') {
      isClosedStatus = true;
    }

    const reviseCMSrequestsProjects = project.ReviseCMSRequest;
    if (reviseCMSrequestsProjects && reviseCMSrequestsProjects.length > 0) {
        const sortedArray = reviseCMSrequestsProjects.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        if(sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
          (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)
        ) {
            pendingReviseCMS.push(true);
        } else {
            pendingReviseCMS.push(false);
        }
    } else {
        pendingReviseCMS.push(false);
    }

    let crArray = []
    project.AllChangeRequests = project.ChangeRequests;
    for (const cr of project.ChangeRequests) {
      const crProjectManagers = cr.AllocatedPM;
      //find valid pms from end date
      let crValidManagers = [];
      crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (crValidManagers.length == 0 && crProjectManagers.length > 0){
          crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
          const lastAssignedManager = crProjectManagers[0];
          crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
      }
      crValidManagers.forEach(value => {
          if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
              preSalesPM = true;
          }
      });
      if (cr.Status !== null) {
        // if (cr.Status.StatusName === 'Working At Risk' || cr.Status.StatusName === 'Active' || cr.Status.StatusName === 'Billable with CR' || 
        // cr.Status.StatusName === 'Complete' || cr.Status.StatusName === 'Closed' || cr.Status.StatusName === 'Closed-Opportunity Loss' || 
        // cr.Status.StatusName === 'Close without bidding' || cr.Status.StatusName === 'Discontinue') {
          if (cr.StatusConvertedToActive === true) {
              isConvertRequest = true;
          }
          crArray.push(cr)
        //  }
        if(cr.Status.StatusName === 'Waiting Confirmation') {
          if (preSalesPM) {
            isWaitingConfirm = true
          }
        }
        const reviseCMSrequests = cr.ReviseCMSRequest;// need to changeeeeees
        if (reviseCMSrequests && reviseCMSrequests.length > 0) {
          const sortedArray = reviseCMSrequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          if(sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
            (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)
          ) {
            pendingReviseCMS.push(true);
          } else {
            pendingReviseCMS.push(false);
          }
        } else {
            pendingReviseCMS.push(false);
        }
      }
    }

      let allocatedProjectManager = false;
      if (project.ResourceRequestStatus === 'PM_ALLOCATED') {

        const projectProjectManagers = project.AllocatedResourceRequestPM.filter(manager => !manager.IsPresales);

        //find valid pms from end date
        let projValidManagers = [];
        projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

        if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
          //projValidManagers.push(projectProjectManagers[0]);

          projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
          const lastAssignedManager = projectProjectManagers[0];
          projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

        }

        projValidManagers.forEach(value => {
          if(!value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
            allocatedProjectManager = true;
          }
        });
      }

      if (project.ChangeRequests.length > 0) {
        for(const cr of project.ChangeRequests){
          if (cr.ResourceRequestStatus === 'PM_ALLOCATED') {

            const crProjectManagers = cr.AllocatedPM.filter(manager => !manager.IsPresales);

            //find valid pms from end date
            let crValidManagers = [];
            crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

            if (crValidManagers.length == 0 && crProjectManagers.length > 0){
              //crValidManagers.push(crProjectManagers[0]);

              crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
              const lastAssignedManager = crProjectManagers[0];
              crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

            }

            crValidManagers.forEach(value => {
              if(!value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
                allocatedProjectManager = true;
              }
            });

          }
        }
      }

    // Check user
    const loggedInUserRoles = window.LOGGED_USER && window.LOGGED_USER.roles ? window.LOGGED_USER.roles : [];

    let loggedInUserIsResourceAllocator = false;
    let resManagement = [];
    for (const role of loggedInUserRoles) {
      if (role.Rolename === 'RP') {
        resManagement = role.Permissions.filter(res => res.PermissionName === 'RES_ALC');
        if (resManagement.length > 0) {
          loggedInUserIsResourceAllocator = true
        }
      }
    }

      let rReqStatueAry = [];
      if(project.ResourceRequest && project.ResourceRequest.length > 0){
        for(const rRequest of project.ResourceRequest){
          if(rRequest.Status == 'RES_ALLOC_PENDING' || rRequest.Status == 'RES_ALLOC_REJECTED'){
            rReqStatueAry.push(true);
          } else {
            rReqStatueAry.push(false);
          }
          if(rRequest.ResourceRequestSubLine && rRequest.ResourceRequestSubLine.length > 0){
            for(const rRequestSub of rRequest.ResourceRequestSubLine){
              if(rRequestSub.Status == 'RES_ALLOC_PENDING' || rRequestSub.Status == 'RES_ALLOC_REJECTED'){
                rReqStatueAry.push(true);
              } else {
                rReqStatueAry.push(false);
              }
            }
          }
        }
        isAllocateResourceVisible = (rReqStatueAry.filter(i => i == true).length > 0) ? true : false;
      }
      if(project.ChangeRequests && project.ChangeRequests.length > 0){
        for(const cr of project.ChangeRequests){
          if(cr.ResourceRequest && cr.ResourceRequest.length > 0){
            for(const rRequest of cr.ResourceRequest){
              if(rRequest.Status == 'RES_ALLOC_PENDING' || rRequest.Status == 'RES_ALLOC_REJECTED'){
                rReqStatueAry.push(true);
              } else {
                rReqStatueAry.push(false);
              }
              if(rRequest.ResourceRequestSubLine && rRequest.ResourceRequestSubLine.length > 0){
                for(const rRequestSub of rRequest.ResourceRequestSubLine){
                  if(rRequestSub.Status == 'RES_ALLOC_PENDING' || rRequestSub.Status == 'RES_ALLOC_REJECTED'){
                    rReqStatueAry.push(true);
                  } else {
                    rReqStatueAry.push(false);
                  }
                }
              }
            }
          }
        }
        isAllocateResourceVisible = (rReqStatueAry.filter(i => i == true).length > 0) ? true : false;
      }

    const checkPendingReviseCMSStatus = pendingReviseCMS.some(val => val === false);
    project.ChangeRequests = crArray

    return (
      <td className="action-buttons">

        {(loggedInUserIsResourceAllocator && isAllocateResourceVisible === true) &&  (
          <Link
            to={{
              pathname: '/ProjectResourceRequest/ProjectResourceAllocation',
              action: 'RPApprove',
              project: project
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-module-manager" title="Allocate Resource" />
            </div>
          </Link>
        )}

        {(window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_RES_REQ') > -1))) && (isConvertRequest === true || isWaitingConfirm === true) && (
          <Link
            to={{
              pathname: '/ProjectResourceRequest/ProjectResourceRequest',
              action: 'view',
              project: project
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-preview" title="View" />
            </div>
          </Link>
        )}

        {((allocatedProjectManager === true || isWaitingConfirm === true) && !isClosedStatus && checkPendingReviseCMSStatus) &&  (
          <Link
            to={{
              pathname: '/ProjectResourceRequest/ProjectResourceRequest',
              action: 'create',
              project: project
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-edit" title="Edit" />
            </div>
          </Link>
        )}

        {(project && project.isResouceApprovalPending) &&
          (
            <Link
              to={{
                pathname: '/ProjectResourceRequest/ProjectResourceRequest',
                action: 'RPApproveWF',
                project: project
              }}
            >
              <div className="rounded-link">
              <span className="k-icon k-i-check" title="Approve Resource" />
              </div>
            </Link>      
          )}

          {(project && project.isResouceAllocationApprovalPending) &&
          (
              <Link
                  to={{
                      pathname: '/ProjectResourceRequest/ProjectResourceAllocation',
                      action: 'RPAllocateWF',
                      project: project
                  }}
              >
                  <div className="rounded-link">
                      <span className="k-icon k-i-check" title="Approve Resource Allocation" />
                  </div>
              </Link>
          )}
      </td>
    );
  }
}

export default ResourceRequestCell;
