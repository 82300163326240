import React, { Component } from 'react';
import 'moment-timezone';
import Parser from 'html-react-parser';

class CustomerProjectsCell extends Component {

    render() {
        const value = this.props.dataItem;

        if(value.ChangeRequests.length > 0){
            return <td>
                {value.ChangeRequests[0].CRName}
            </td>;
        } else{
            return <td>
                {value.ProjectName}
            </td>;
        }
    }
}

export default CustomerProjectsCell;
