import http from '../../integration/HttpService';
//import { apiUrl } from '../config.json';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/*User Management*/
/**
 * Retrieve a list of roles.
 * @returns {array} array list of roles
 */
export function getRoleList() {
    const endPoint = apiUrl + '/userManagement/role/listAll';
    return http.get(endPoint);
}

/**
 * searchRoles - search Roles by parameters
 * @param params
 * @returns {array}  array of details of Roles on a grid
 */
export function searchRoles(params) {
  const endPoint = apiUrl + '/userManagement/role/search';
  return http.post(endPoint, params);
}

/**
 * saveRole - Save Role after edit/created
 * @param params
 * @returns {array}  array of saved details of Role
 */
export function saveRole(params) {
    const endPoint = apiUrl + '/userManagement/role/save';
    return http.post(endPoint, params);
}

/**
 * updateRole Role by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param params
 * @param id
 */
export function updateRole(params, id) {
    const endPoint = apiUrl + '/userManagement/role/update/'+ id;
    return http.put(endPoint, params);
}

/**
 * Update Role status by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param params
 * @param id
 */
export function updateRoleStatus(params, id) {
    const endPoint = apiUrl + '/userManagement/role/updateStatus/'+ id;
    return http.put(endPoint, params);
}

/**
 * Retrieves all the records of Permission as a list
 * @returns {array}  array of Permissions
 */
export function getPermissionList() {
    const endPoint = apiUrl + '/userManagement/role/permission/listAll';
    return http.get(endPoint);
}

