import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem, PanelBarUtils, Menu, MenuItem, MenuItemModel, MenuItemLink, MenuItemArrow, Splitter } from '@progress/kendo-react-layout';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactDOM from 'react-dom';

class WorkflowConfigurationSearch extends Component {
    render() {
        return (
            <div>
                <div className="main-card">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-heading">Workflow Configuration Search</div>
                        </div>
                    </div>

                    <form onSubmit={this.submitSearch}>
                        <div className="row">

                            <div className="col-md-2">
                                <div className="d-block">
                                    <label className="new-label">Workflow Name:</label>
                                </div>
                                <div className="d-block">
                                    <Input />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-block">
                                    <label className="new-label">Approver Types:</label>
                                </div>
                                <div className="d-block">
                                    <DropDownList />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-block">
                                    <label className="new-label">Practice/Department:</label>
                                </div>
                                <div className="d-block">
                                    <DropDownList />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-block">
                                    <label className="new-label">Workflow Type:</label>
                                </div>
                                <div className="d-block">
                                    <DropDownList />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-block">
                                    <label className="new-label">Workflow Criteria:</label>
                                </div>
                                <div className="d-block">
                                    <DropDownList />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-block">
                                    <label className="new-label">Workflow Criteria Definition:</label>
                                </div>
                                <div className="d-block">
                                    <DropDownList />
                                </div>
                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-2">
                                <div className="d-block">
                                    <label className="new-label">Approvers:</label>
                                </div>
                                <div className="d-block">
                                    <Input />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-block">
                                    <label className="new-label">Billing Method:</label>
                                </div>
                                <div className="d-block">
                                    <DropDownList />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-block">
                                    <label className="new-label">Belling Region:</label>
                                </div>
                                <div className="d-block">
                                    <DropDownList />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-block">
                                    <label className="new-label">Workflow Status:</label>
                                </div>
                                <div className="d-block">
                                    <DropDownList />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="d-block">
                                    <label className="new-label"></label>
                                </div>
                                <div className="d-block">
                                    <fieldset>
                                        <label className="k-form-field">
                                            <input type="checkbox" id="auth-1rps" className="k-checkbox" />
                                            <label className="k-checkbox-label" for="auth-1rps">Workflows with Pending Approvals</label>
                                        </label>
                                    </fieldset>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-12 btn-align-right">
                                <Button primary={true} type="submit">
                                    Search
                                </Button>
                                <Button type="button">
                                    Clear
                                </Button>
                            </div>
                        </div>
                    </form>

                    <div className="main-seperator" />

                    <div className="row">
                        <div className="col-md-8">
                            <div className="main-heading">Search Results</div>
                        </div>

                        <div className="col-md-4 btn-align-right">
                            <Button title="Export PDF" className="k-button k-primary" onClick={this.export}>
                                Export to Excel
                            </Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Grid
                                style={{ height: '400px' }}
                            >
                                <Column field="ProductID" title="ID" width="40px" />
                                <Column field="ProductName" title="Name" width="250px" />
                                <Column field="Category.CategoryName" title="CategoryName" />
                                <Column field="UnitPrice" title="Price" />
                                <Column field="UnitsInStock" title="In stock" />
                                <Column
                                    field="Discontinued"
                                    cell={props => (
                                        <td>
                                            <input disabled type="checkbox" checked={props.dataItem[props.field]} />
                                        </td>
                                    )}
                                />
                            </Grid>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                            <Button primary={true} type="submit">
                                Save
                            </Button>
                            <Button type="button">
                                Cancel
                            </Button>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default WorkflowConfigurationSearch;