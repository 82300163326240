import http from '../integration/HttpService';
const apiUrl = require('../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * getProjectResources - get All Resources available in the system
 * @returns {object} All PM Resources.
 */
export function getProjectResources() {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resources/all';
  return http.get(endPoint);
}

/**
 * getActiveProjectResources - get All Active Resources available in the system
 * @returns {object} All Active PM Resources.
 */
export function getActiveProjectResources() {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resources/active/all';
  return http.get(endPoint);
}

/**
 * getAllProjectResources - get All Active and Inactive Resources available in the system
 * @returns {object} 
 */
export function getAllProjectResources() {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resources/active/inactive/all';
  return http.get(endPoint);
}

/**
 * getAllRateTypes - get All Active Rate types
 * @returns {object} 
 */
export function getAllRateTypes() {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resources/getAllRateTypes';
  return http.get(endPoint);
}

/**
 * getPMResources - get All PM Resources available in the system
 * @returns {object} All PM Resources.
 */
export function getPMResources() {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resources/getPMResources';
  return http.get(endPoint);
}

/**
 * getPMResources - get All PM Resources available in the system
 * @returns {object} All PM Resources.
 */
export function getAllPMResources() {
    const endPoint = apiUrl + '/resourceManagement/resourceManagement/resources/getAllPMResources';
    return http.get(endPoint);
}

/**
 * getProjectOwners - get All PM Resources available in the system
 * @returns {object} All PM Resources.
 */
export function getProjectOwners() {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resources/getProjectOwners';
  return http.get(endPoint);
}

/**
 * Get default and last override rates for an allocation.
 * @param params
 * @returns {AxiosPromise<any>}
 */
export function getRatesForAllocation(params) {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/ratecard/allocation';
  return http.post(endPoint, params);
}

/**
 * Get deployable status.
 * @param params
 * @returns {AxiosPromise<any>}
 */
export function getDeployableStatus(params) {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resource/deployablestatus';
  return http.get(endPoint, params);
}

/**
 * Get deployable status.
 * @param params
 * @returns {AxiosPromise<any>}
 */
export function getResourceGroup(params) {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resource/resourcegroup';
  return http.get(endPoint, params);
}

/**
 * Get deployable status.
 * @param params
 * @returns {AxiosPromise<any>}
 */
export function getLineStatus(params) {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resource/linestatus';
  return http.get(endPoint, params);
}

/**
 * Get division of user.
 * @param params
 * @returns {AxiosPromise<any>}
 */
export function getDivision(params) {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resource/division';
  return http.get(endPoint, params);
}

/**
 * Get employment type of resources.
 * @param params
 * @returns {AxiosPromise<any>}
 */
export function getEmploymentType(params) {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resource/employmentType';
  return http.get(endPoint, params);
}

/**
 * Get resource request resource mix.
 * @param cmSheetId, version
 * @returns {AxiosPromise<any>}
 */
export function getResourceRequestResourcesByIdAndVersion(cmSheetId, version) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/cmsheet/' + cmSheetId + '/version/' + version;
  return http.get(endPoint);
}

/**
 * Get resource request resource mix.
 * @param cmSheetId, version
 * @returns {AxiosPromise<any>}
 */
export function getStatusIdByName(name) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/statusId/' + name;
  return http.get(endPoint);
}

/**
 * Get Resource Mix details of Resource Request.
 * @param idArray
 * @returns {Promise<AxiosResponse<T>>} Get the Resource Mix Resource Request.
 */
export function getResourceRequestResourceMixData(idArray) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/cmsheet/getResourceMix';
  return http.post(endPoint, idArray);
}

/**
 * Get Previous CMS Resource Mix details of Resource Request.
 * @param idArray
 * @returns {Promise<AxiosResponse<T>>} Get the Previous CMS Resource Mix Resource Request.
 */
export function getPreviousCMSResourceMixData(idArray) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/cmsheet/getPreviousCMSResourceMix';
  return http.post(endPoint, idArray);
}

/**
 * Get Resource Mix details of Resource Request.
 * @param projandCrArray
 * @returns {Promise<AxiosResponse<T>>} Get the Resource Mix Resource Request.
 */
export function getPreSalesResourceMixData(projandCrArray) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/gerPreSalesResourceMix';
  return http.post(endPoint, projandCrArray);
}

/**
 * Update and allocate Resource Request.
 * @param  Resource Request Array
 * @returns {Promise<AxiosResponse<T>>} Updated Resource Mix Resource Request.
 */
export function allocateResources(resources) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/allocateResource';
  return http.put(endPoint, resources);
}

/**
 * delete RequestsIDs.
 * @param idsArray.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function deleteRequest(idsArray,Type,id) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/delete/' + Type + '/id/' +id;
  return http.post(endPoint,idsArray,Type,id);
}

/**
 * delete RequestsIDs.
 * @param idsArray.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function deleteRequestSubLine(idsArray) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/delete/line';
  return http.post(endPoint,idsArray);
}

/**
 * Add New Resource Requests.
 * @param  Resource Request Array
 * @returns {Promise<AxiosResponse<T>>} Added Resource Mix Resource Request.
 */
export function saveResourceRequests(requests) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/save';
  return http.post(endPoint, requests);
}

/**
 * Save Resource Allocation.
 * @param  Resource Allocation Array
 * @returns {Promise<AxiosResponse<T>>} Saved Resource Mix Resource Allocation.
 */
export function saveResourceAllocation(requests) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/saveAllocation';
  return http.post(endPoint, requests);
}

export function updateActivatedResourceRequests(requests) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/updatedActivatedResources';
  return http.put(endPoint, requests);
}

export function updateActivatedResourceRequestSubLine(request) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/updateResourceSubLine';
  return http.put(endPoint, request);
}

/**
 * Update status to Confirm.
 * @param idList
 * @returns {AxiosPromise<any>}
 */
export function updateStatusToConfirm(idList) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/updateConfirm';
  return http.put(endPoint, idList);
}

/**
 * Update resource line history.
 * @param resources array
 * @returns {AxiosPromise<any>}
 */
export function saveResourceLineHistory(resources) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/saveResourceLineHistory';
  return http.post(endPoint, resources);
}

/**
 * Update resource line history.
 * @param resources array
 * @returns {AxiosPromise<any>}
 */
export function getLineHistory(resourceDetails) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/getLineHistory';
  return http.post(endPoint, resourceDetails);
}

/**
 * Search resource.
 * @param searchTxt text
 * @returns {AxiosPromise<any>}
 */
export function searchResource(searchTxt) {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resource/searchResource/' + searchTxt;
  return http.get(endPoint);
}


/**
 * Get all allocated lines.
 * @param resources array
 * @returns {AxiosPromise<any>}
 */
export function getAllAllocatedLines(resourceIds, isPreSales, isSoftbooking) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/getAllAllocatedLines/' + resourceIds + '/' + isPreSales + '/' + isSoftbooking;
  return http.get(endPoint);
}

/**
 * Update status to Cancelled.
 * @param idList
 * @returns {AxiosPromise<any>}
 */
export function updateStatusToCancelled(idList) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/updateCancel';
  return http.put(endPoint, idList);
}

/**
 * Send allocation mails.
 * @param  Resource Allocation Array
 * @returns {Promise<AxiosResponse<T>>} Saved Resource Mix Resource Allocation.
 */
export function sendAllocationDeallocationMails(lineDetils) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/sendAllocationDeallocationMails';
  return http.post(endPoint, lineDetils);
}


/**
 * getResourceRequestSubLines - retrieve resource request sublines
 * @param resourceReqId
 * @returns {AxiosPromise<any>}
 */
export function getResourceRequestSubLines(resourceReqId) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/resourceRequestSubLines/' + resourceReqId;
  return http.get(endPoint);
}

/**
 * getLeavesIntegrated - get Leaves By ResourceId and Date Range
 * @param leaveObj
 * @returns {object} getLeavesIntegrated
 */

 export function getLeavesIntegrated(leaveObj) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/getLeavesIntegrated';
  return http.post(endPoint, leaveObj);
}

/**
 * getProjectOrCrResourceRequest - retrieve resource requests of a project or cr
 * @param type
 * @param id
 * @returns {AxiosPromise<any>}
 */
export function getProjectOrCrResourceRequest(type, id) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/getProjectOrCrResourceRequest/' + type + '/' + id;
  return http.get(endPoint, type, id);
}

/**
 * deallocatePreSalesResourceRequest - update/delete, Presales Resource Request line(s)
 * @param items
 * @returns {object} array object
 */
 export function deallocatePreSalesResourceRequest(item) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/deallocatePreSalesResourceRequest/';
  return http.put(endPoint, item);
}

/**
 * Update presales resource line history.
 * @param resources array
 * @returns {AxiosPromise<any>}
 */
 export function savePresalesResourceLineHistory(resources) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/savePresalesResourceLineHistory';
  return http.post(endPoint, resources);
}

/**
 * add MainLine's History To SubLine.
 * @param  subLineObj subline object
 * @returns {object} array object
 */
 export function addMainLineHistoryToSubLine(subLineObj) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/addMainLineHistoryToSubLine';
  return http.post(endPoint, subLineObj);
}

/**
 * Get Legal Entity Types.
 * @returns {AxiosPromise<any>}
 */
 export function getLegalEntityTypes() {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resource/getLegalEntityTypes';
  return http.get(endPoint);
}

/**
 * Get Delivery Models.
 * @returns {AxiosPromise<any>}
 */
 export function getDeliveryModels() {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resource/getDeliveryModels';
  return http.get(endPoint);
}

/**
 * getResourceRequestSubLines - retrieve resource request sublines
 * @param resourceReqId
 * @returns {AxiosPromise<any>}
 */
 export function deallocateRequestsFromStatusChange(items) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/deallocateRequestsFromStatusChange';
  return http.put(endPoint, items);
}

/**
 * get resource Allocation Percentage history.
 * @param resources array
 * @returns {AxiosPromise<any>}
 */
 export function getAllocationPercentageHistory(resourceDetails) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/getAllocationPercentageHistory';
  return http.post(endPoint, resourceDetails);
}

/**
 * Update resource Allocation Percentage history.
 * @param resources array
 * @returns {AxiosPromise<any>}
 */
 export function updateAllocationPercentageHistory(resourceDetails) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/updateAllocationPercentageHistory';
  return http.post(endPoint, resourceDetails);
}

/**
 * Resource Allocation Percentage Change Notification.
 * @param resourceDetails array
 * @returns {AxiosPromise<any>}
 */
export function allocationChangeNotification(resourceDetails) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/allocationChangeNotification';
  return http.post(endPoint, resourceDetails);
}

/**
 * Get list of data of a Actual Other Expenses.
 * @param cmSheetId.
 * @param version.
 * @returns {string} String representing CMS version.
 */
export function getActualOtherExpenses(cmSheetId, version) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/getActualOtherExpenses/' + cmSheetId + '/version/' + version;
  return http.get(endPoint);
}

/**
 * Get list of data of a Actual Other Expenses.
 * @param cmSheetId.
 * @param version.
 * @returns {string} String representing CMS version.
 */
export function getActualOtherExpensesPreviousCMS(cmSheetId, version) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/getActualOtherExpensesPreviousCMS/' + cmSheetId + '/version/' + version;
  return http.get(endPoint);
}

/**
 * Get list of data of a Actual Other Expenses for revise cms.
 * @param cmSheetId.
 * @param version.
 * @returns {string} String representing CMS version.
 */
export function getActualOtherExpensesForReviseCMS(cmSheetId) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/getActualOtherExpensesForReviseCMS/' + cmSheetId;
  return http.get(endPoint);
}

/**
 * save Actual Other Expenses.
 * @param otherExpensesDetails array
 * @returns {AxiosPromise<any>}
 */
export function saveActualOtherExpenseDates(otherExpensesDetails) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/saveActualOtherExpenseDates';
  return http.post(endPoint, otherExpensesDetails);
}

/**
 * save New Actual Other Expense.
 * @param otherExpenseDetails array
 * @returns {AxiosPromise<any>}
 */
export function saveNewActualOtherExpense(otherExpenseDetails) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/saveNewActualOtherExpense';
  return http.post(endPoint, otherExpenseDetails);
}

/**
 * update Actual Other Expense.
 * @param otherExpenseDetails array
 * @returns {AxiosPromise<any>}
 */
export function updateActualOtherExpense(otherExpenseDetails,actualExpenseId) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/updateActualOtherExpense/'+ actualExpenseId;
  return http.post(endPoint, otherExpenseDetails);
}

/**
 * delete Actual Other Expense cost.
 * @param otherExpenseDetails array
 * @returns {AxiosPromise<any>}
 */
export function deleteActualOtherExpenseCost(otherExpenseDetails,actualExpenseId) {
  const endPoint = apiUrl + '/resourceManagement/resourceRequest/deleteActualOtherExpenseCost/'+ actualExpenseId;
  return http.post(endPoint, otherExpenseDetails);
}

