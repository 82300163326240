/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

class FactoryRowDetails extends Component {
    render() {
        const dataItem = this.props.dataItem;

        return (
            <section>
                <p>
                    <strong>Cost:</strong>{' '}
                    <span className={dataItem.costNoLongerValid === true ? 'text-red' : ''}>
            {dataItem.TotalCost
                ? dataItem.TotalCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : 0}
          </span>{' '}
                    | <strong>Duration:</strong>{' '}
                    {dataItem.Duration ? dataItem.Duration.toFixed(2) : 0.00} |{' '}
                    <strong>Effort:</strong> {dataItem.TotalEffortDays} |{' '}
                    <strong>FTE:</strong>{' '}
                    {(dataItem.Duration > 0)
                        ? dataItem.FTE.toFixed(2)
                        : 0.00}
                </p>
            </section>
        );
    }
}

export default FactoryRowDetails;
