import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList, AutoComplete, MultiSelect, ComboBox } from '@progress/kendo-react-dropdowns';
import { saveCustomer } from './CustomerService';
import { getResourceRegionsIntegrated } from '../../integration/MasterDataService';
import { Button } from '@progress/kendo-react-buttons';
import InfoDialog from '../../integration/components/InfoDialog';
import FormValidator from '../../integration/components/FormValidator';
import ConfirmationDialog from '../../integration/components/ConfirmationDialog';
import { Link } from 'react-router-dom';
import Loader from '../../integration/components/Loader';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import { getInvoiceCycles } from '../../projectManagement/invoice/InvoiceService';

class EditCustomer extends Component {
  defaultItemDropDown = { id: null, Name: 'Please Select' };

  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'customerName',
        method: 'isEmpty',
        validWhen: false,
        message: 'Customer Name cannot be empty.'
      },
      {
        field: 'customerRegionVal',
        method: 'isEmpty',
        validWhen: false,
        message: 'Region cannot be empty.'
      },
      {
        field: 'customerCode',
        method: 'isEmpty',
        validWhen: false,
        message: 'Customer Code cannot be empty.'
      },
      {
        field: 'customerCode',
        method: 'matches',
        args: [/^.{2,5}$/], // an array of additional arguments
        validWhen: true,
        message: 'Customer Code length must be between 2 and 5.'
      },
      {
        field: 'invoiceCycleValue',
        method: 'isEmpty',
        validWhen: false,
        message: 'Invoicing cycle cannot be empty.'
      },
    ]);

    this.state = {
      customerId: null,
      customerName: '',
      customerCode: '',
      customerRegion: this.defaultItemDropDown,
      customerRegionVal: '',
      customerPrograms: [{ id: null, Name: '' }],
      regions: [],
      skip: 0,
      take: 20,
      isEditMode: false,
      isFormDirty: false,
      visible: false,
      showCancelConfirmation: false,
      dialogMessage: '',
      dialogTitle: '',
      validation: this.validator.valid(),
      programValidation: '',
      loading: false,
      poNumber: false,
      customerApproval: false,
      invoiceCycleData: [],
      invoiceCycleValue: null
    };

    this.submitted = false;
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setHeaderTitle();
    await this.populateInvoiceCycles();
    this.populateDefaultData();
  }

  populateInvoiceCycles = async() => {
    await getInvoiceCycles().then(async response => {
      await this.setState({
        invoiceCycleData: response.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  }

  populateDefaultData = () => {
    if (this.props.location.action === 'edit' && this.props.location.customer) {
      const existingCustomer = this.props.location.customer;
      let invoiceCycleValue = null;
      if (existingCustomer.InvoiceCycleId) {
        invoiceCycleValue = this.state.invoiceCycleData.filter(val => val.value === existingCustomer.InvoiceCycleId);
      }
      this.setState({
        customerId: existingCustomer.id,
        customerName: existingCustomer.Name,
        customerCode: existingCustomer.Code,
        customerRegion: existingCustomer.Region,
        customerRegionVal: existingCustomer.Region,
        poNumber: existingCustomer.PONumber,
        customerApproval: existingCustomer.CustomerApproval,
        invoiceCycleValue: invoiceCycleValue ? invoiceCycleValue[0] : null,
        customerPrograms:
          existingCustomer.Programs.length > 0
            ? existingCustomer.Programs
            : [{ id: null, Name: '' }]
      });
    }

    getResourceRegionsIntegrated().then(response => {
      let data = response.data;
      let filteredRegions = data.filter(obj => obj.CostRegion === false);
      this.setState({
        regions: filteredRegions
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleCancelConfirmDialog = () => {
    this.setState({
      showCancelConfirmation: !this.state.showCancelConfirmation
    });
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.redirectToList();
    }
  };

  hasDuplicates = array => {
    return new Set(array).size !== array.length;
  };

  redirectToList = () => {
    this.props.history.push('/admin/customer/search');
  };

  saveCustomerHandle = event => {
    event.preventDefault();

    this.setState({
      loading: true
    });

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    let validForm = validation.isValid;

    if (this.hasDuplicates(this.state.customerPrograms.map(program => program.Name))) {
      validForm = false;
      this.setState({
        programValidation: 'Programs are duplicated. Please insert unique values.'
      });
    }

    if (!validForm) {
      this.setState({
        loading: false,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog(`Please fix the validation errors and save again.`, 'Error');
      return;
    }

    const customer = {
      id: this.state.customerId ? this.state.customerId : null,
      Name: this.state.customerName,
      Code: this.state.customerCode.toUpperCase(),
      regionId:
        this.state.customerRegion && this.state.customerRegion.id
          ? this.state.customerRegion.id
          : null,
      IsActive: true,
      updatedUser: window.LOGGED_USER.displayName,
      PONumber: this.state.poNumber,
      CustomerApproval: this.state.customerApproval,
      InvoiceCycleId: this.state.invoiceCycleValue ? this.state.invoiceCycleValue.value : null,
      Programs: this.state.customerPrograms.filter(program => program.Name)
    };

    saveCustomer(customer)
      .then(res => {
        this.setState({
          loading: false,
          showSuccessImage: true,
          showErrorImage: false
        });
        if (customer.id) {
          this.toggleDialog(
            `The Customer ` + this.state.customerName + ` is Successfully updated.`,
            'Success'
          );
        } else {
          this.toggleDialog(
            `The Customer ` + this.state.customerName + ` is Successfully created.`
          );
        }
        this.setState({});
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          loading: false
        });
        this.setState({
          isDisableSave: false,
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog(error.response.data, 'Error');

      });
  };

  setHeaderTitle = async () => {
    if (this.props.onHeaderTitleChange) {
      this.props.location.action === 'edit'
        ? this.props.onHeaderTitleChange('Edit Customer')
        : this.props.onHeaderTitleChange('Add New Customer');
    }
  };

  handleInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    await this.setState({ [name]: value, isFormDirty: true });
  };

  handleProgramsInputChange = (event, arrayIndex) => {
    const value = event.target.value;
    //await this.setState({ [name]: value, isFormDirty: true });
    var temp = [];

    this.state.customerPrograms.forEach(function (val, index) {
      var program = Object.assign({}, val);
      if (index === arrayIndex) {
        program.Name = value;
      }
      temp.push(program);
    });
    this.setState({
      customerPrograms: temp
    });
  };

  handleRegionInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    let invoiceCycleValue = null;
    if (value.InvoiceCycleId) {
      invoiceCycleValue = this.state.invoiceCycleData.filter(val => val.value === value.InvoiceCycleId);
    }
    await this.setState({
      [name]: value,
      isFormDirty: true,
      customerRegionVal: value && value.id ? value : '',
      invoiceCycleValue: invoiceCycleValue ? invoiceCycleValue[0] : null
    });
  };

  handleInvoiceCycleChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    await this.setState({
      [name]: value,
      isFormDirty: true,
    });
  };

  onProgramAdd = arrayIndex => {
    let valid = true;
    let matchCount = 0;

    if (!this.state.customerPrograms[arrayIndex] || !this.state.customerPrograms[arrayIndex].Name) {
      this.setState({
        programValidation: 'Program cannot be empty.'
      });
      valid = false;
    }

    this.state.customerPrograms.forEach((program, index) => {
      if (this.state.customerPrograms[arrayIndex].Name === program.Name) {
        matchCount++;
      }
    });

    if (matchCount > 1) {
      valid = false;
      this.setState({
        programValidation: 'Program already exists. Please insert a unique name.'
      });
    }

    if (valid) {
      this.setState({
        customerPrograms: [...this.state.customerPrograms, { name: '', id: null }],
        programValidation: ''
      });
    }
  };

  handleOnChangePONumber = async event => {
    this.setState({
      poNumber: !this.state.poNumber
    })
  };

  handleOnChangeCustomerApproval = async event => {
    this.setState({
      customerApproval: !this.state.customerApproval
    })
  };

  render() {
    let validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : this.state.validation;

    return (
      <div className="main-card">
        <div className="main-heading">Customer Details</div>
        <div className="main-seperator" />
        <div className="row">
          <div className="col-md-4">
            <div className="d-block">
              <label className="mandatory">Customer Name:</label>
            </div>
            <div className="d-block">
              <div className="">
                <Input
                  autoComplete="off"
                  type="text"
                  name="customerName"
                  required={true}
                  maxLength="100"
                  value={this.state.customerName}
                  onChange={this.handleInputChange}
                />
                <span className="inline-error">{validation.customerName.message}</span>
              </div>
            </div>

            <div className="d-block">
              <label className="mandatory">Billing Region:</label>
            </div>
            <div className="d-block">
              <div className="">
                <ComboBox
                  name="customerRegion"
                  textField="Name"
                  dataItemKey="id"
                  value={this.state.customerRegion}
                  data={this.state.regions}
                  defaultItem={this.defaultItemDropDown}
                  onChange={this.handleRegionInputChange}
                  required={true}
                />
                <span className="inline-error">{validation.customerRegionVal.message}</span>
              </div>
            </div>

            <div className="d-block">
              <label className="mandatory">Customer Code:</label>
            </div>
            <div className="d-block">
              <div className="">
                <Input
                  autoComplete="off"
                  type="text"
                  name="customerCode"
                  required={true}
                  maxLength="5"
                  value={this.state.customerCode}
                  onChange={this.handleInputChange}
                  style={{ textTransform: 'uppercase' }}
                />
                <span className="inline-error">{validation.customerCode.message}</span>
              </div>
            </div>

            <div className="d-block">
              <label className="mandatory">Invoicing Cycle:</label>
            </div>
            <div className="d-block">
              <div className="">
                <ComboBox
                  name="invoiceCycleValue"
                  textField="name"
                  dataItemKey="value"
                  value={this.state.invoiceCycleValue}
                  data={this.state.invoiceCycleData}
                  placeholder="Please Select"
                  onChange={this.handleInvoiceCycleChange}
                  required={true}
                />
                <span className="inline-error">{validation.invoiceCycleValue.message}</span>
              </div>
            </div>

            <div className="row mt-3">
              <div className="d-block col-md-6">
                <label className="">Required for Invoicing:</label>
              </div>
            </div>

            <div className='row mb-3'>
              <div className="d-block col-md-6">
                <input
                    type="checkbox"
                    name="poNumber"
                    value={this.state.poNumber}
                    className="k-checkbox"
                    checked={this.state.poNumber}
                    onChange={this.handleOnChangePONumber}
                />
                <label className="k-checkbox-label font-weight-bold" htmlFor="pastatus">
                    PO Number
                </label>
              </div>

              <div className="d-block col-md-6">
                <input
                    type="checkbox"
                    name="customerApproval"
                    value={this.state.customerApproval}
                    className="k-checkbox"
                    checked={this.state.customerApproval}
                    onChange={this.handleOnChangeCustomerApproval}
                />
                <label className="k-checkbox-label font-weight-bold" htmlFor="pastatus">
                    Customer Approval
                </label>
              </div>
            </div>

            <div className="d-block">
              <label>Program(s):</label>
            </div>
            <div className="d-block">
              <div className="">
                {this.state.customerPrograms.map((program, index) => {
                  return (
                    <div className="row">
                      <div className="col-md-10">
                        <Input
                          key={index}
                          autoComplete="off"
                          type="text"
                          name="customerProgram"
                          maxLength="100"
                          value={program.Name}
                          onChange={event => {
                            this.handleProgramsInputChange(event, index);
                          }}
                        />
                      </div>
                      {this.state.customerPrograms.length - 1 === index && (
                        <React.Fragment>
                          <div className="col-md-2">
                            <Link
                              to={'#'}
                              onClick={e => {
                                e.preventDefault();
                                this.onProgramAdd(index);
                              }}
                            >
                              <button className="rounded-btn pull-right" type="button">
                                <span className="k-icon k-i-plus"></span>
                              </button>
                            </Link>
                          </div>
                          <span className="inline-error col-md-12">
                            {this.state.programValidation}
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>



            <div>
              {this.state.visible === true && (
                <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                  {this.state.showSuccessImage === true && (
                    <div className="text-center">
                      <img className="successImg" src={SuccessImg} alt="Success" />
                    </div>
                  )}

                  {this.state.showErrorImage === true && (
                    <div className="text-center">
                      <img className="successImg" src={ErrorImg} alt="Error Image" />
                    </div>
                  )}
                  <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                  <DialogActionsBar>
                    {this.state.showSuccessImage === true && (
                      <button className="k-button modal-primary" onClick={this.redirectToList}>
                        OK
                      </button>
                    )}

                    {this.state.showErrorImage === true && (
                      <button className="k-button modal-primary" onClick={this.toggleDialog}>
                        OK
                      </button>
                    )}
                  </DialogActionsBar>
                </Dialog>
              )}
            </div>
            <div>
              {this.state.showCancelConfirmation === true && (
                <ConfirmationDialog
                  dialogTitle={'Please Confirm'}
                  dialogMessage={'This form contain unsaved data. Do you want to close it?'}
                  abortCallback={this.toggleCancelConfirmDialog}
                  confirmCallback={this.redirectToList}
                />
              )}
            </div>

            <Loader loading={this.state.loading} />
          </div>

        </div>
        <div className="row">
          <div className="col-md-12 btn-align-right">
            <Button
              primary={true}
              disabled={this.state.isDisableSave}
              type="submit"
              onClick={this.saveCustomerHandle}
            >
              Save
                </Button>
            <Button onClick={this.onClickCancel}>Cancel</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default EditCustomer;
