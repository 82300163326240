import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Save Practice by given parameters.
 * @param params.
 * @returns {array} array list of all billing Divisions
 */
export function savePractice(params) {
    const endPoint = apiUrl + '/masterData/billingDivisions/save';
    return http.post(endPoint, params);
}

/**
 * Update Practice.
 * @param practice
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function updatePractice(practice, id) {
    const updatePracticeEndpoint = apiUrl + '/masterData/billingDivisions/update/' + id;
    return http.put(updatePracticeEndpoint, practice);
}

/**
 * Update Practice status.
 * @param IsActive.
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function updatePracticeStatus(IsActive, id) {
    const endPoint = apiUrl + '/masterData/billingDivisions/updateStatus/' + id;
    return http.put(endPoint, IsActive);
}

export function updatePracticeSFStatus(IsActive, id) {
    const endPoint = apiUrl + '/masterData/billingDivisions/updateStatusPractice/' + id;
    return http.put(endPoint, IsActive);
}

/**
 * Get all billing Divisions for drop downs.
 * @returns {array} array list of all billing Divisions
 */
export function getBillingDivi() {
    const endPoint = apiUrl + '/masterData/billingDivisions';
    return http.get(endPoint);
}

/**
 * Get all practices for drop downs.
 * @returns {array} array list of all practices
 */
export function getPracticesSF() {
    const endPoint = apiUrl + '/masterData/billingDivisions/practicesList';
    return http.get(endPoint);
}

export function getBillingDiviDropdown() {
    const endPoint = apiUrl + '/masterData/billingDivisions/dropdown';
    return http.get(endPoint);
}

/**
 * Get all billing Divisions for drop downs.
 * @returns {array} array list of all billing Divisions
 */
export function getBillingDivisionById(id) {
    const endPoint = apiUrl + '/masterData/billingDivisions/'+id;
    return http.get(endPoint);
}

/**
 * search all billing Divisions by given parameters.
 * @returns {array} array list of all billing Divisions
 */
export function searchBillingDivi(params) {
    const endPoint = apiUrl + '/masterData/billingDivisions/search';
    return http.post(endPoint, params);
}

export function searchPractices(params) {
    const endPoint = apiUrl + '/masterData/billingDivisions/searchPractices';
    return http.post(endPoint, params);
}

/**
 * Get all active practice types.
 * @returns {array} array list of all practice types
 */
 export function getAllActivePracticeTypes() {
    const endPoint = apiUrl + '/masterData/billingDivisions/practiceTypes/activePracticeTypes';
    return http.get(endPoint);
}

/**
 * Get all billing Divisions and practice mappings
 * @returns {array} array list of all billing Divisions and practice mappings
 */
 export function getAllPracticeDivisionMap() {
    const endPoint = apiUrl + '/masterData/billingDivisions/practiceDivisionMapping/getAllPracticeDivisionMap';
    return http.get(endPoint);
}

export function getDepartmentHeads() {
    const endPoint = apiUrl + '/masterData/billingDivisions/departmentHeads/getDepartmentHeads';
    return http.get(endPoint);
}

export function addDepartmentHead(departmentId, userId) {
    const endPoint = apiUrl + '/masterData/billingDivisions/departmentHeads/addDepartmentHeads/' + departmentId;
    return http.put(endPoint, userId);
}

export function getPracticeHeads() {
    const endPoint = apiUrl + '/masterData/billingDivisions/practiceHeads/getpracticeHeads';
    return http.get(endPoint);
}

export function getAllocatedRegions() {
    const endPoint = apiUrl + '/masterData/billingDivisions/practiceHeads/getAllocatedRegions';
    return http.get(endPoint);
}

export function savePracticeHead(data){
    const endPoint = apiUrl + '/masterData/billingDivisions/practiceHeads/saveData';
    return http.post(endPoint, data);
}

export function getPracticeHeadData(practiceId){
    const endPoint = apiUrl + '/masterData/billingDivisions/practiceHeads/getSavedData/' + practiceId;
    return http.get(endPoint);
}



