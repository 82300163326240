import React, { Component } from 'react';
import 'moment-timezone';
import Parser from 'html-react-parser';

class ApprovalCommentCell extends Component {

    render() {
        const value = this.props.dataItem.Comment;
        if(value==null){
            return <td>
                {value}
            </td>;
        } else{
            return <td><div className={'rich-text-bullets'}>{Parser(value)}</div></td>;
        }
    }
}

export default ApprovalCommentCell;
