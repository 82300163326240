import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Redirect } from 'react-router';
import {
  PanelBar,
  PanelBarUtils
} from '@progress/kendo-react-layout';
import {
  getActiveProjects, getActiveProjectsCRPerformanceFix, getActivityLogDataSort,getActiveProjectsPerformanceFix,
  getFactoryProjByRegionAndPractice, updateLinkToFactory, updateLinkToFactoryCR, getApprovalsData, saveConversionStartdate
} from '../../projectManagement/project/ProjectService';
import ProjectFunctions from './ProjectFunctions';
import CMSheetFunctions from './CMSheetFunctions';
import ChangeRequestFunctions from './ChangeRequestFunctions';
import ResourceRequestFunctions from './ResourceRequestFunctions';
import TaskManagementFunctions from './TaskManagementFunctions';
import { getPendingTasks, getPendingTasksPerformanceFix } from '../../projectManagement/workflow/PendingTaskService';
import { getPendingResourceRequestTasks } from '../../resourceManagement/resourceRequest/ResourceRequestActivationService'
import CMSDeleteDialog from '../../projectManagement/components/dialog/CMSDeleteDialog';
import CMSSelectDialog from '../../projectManagement/components/dialog/CMSSelectDialog';
import PendingTaskTypeCell from './PendingTaskTypeCell';
import PendingTaskDescCell from './PendingTaskDescCell';
import CreatedDateCell from './CreatedDateCell';
import CompletionDateCell from './CompletionDateCell';
import PendingTaskActionsCell from './PendingTaskActionsCell';
import UniqueIdCell from './UniqueIdCell';
import { deleteChangeReqIntegrated, getCRsOfAProjectIntegrated, deleteCMSheetIntegrated, deleteProjectIntegrated } from '../../integration/ProjectManagementService';
import SuccessImg from '../static/images/checked.svg';
import ErrorImg from '../static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import { filterBy, orderBy } from '@progress/kendo-data-query';
import Loader from "../../integration/components/Loader";


import {  ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import ReactQuill from "react-quill";
import { getPermissionList } from '../../userManagement/role/RoleService';

import {
  getProjectStatuses
} from '../../projectManagement/project/ProjectService';

import {
  getPaymentMethods,
  getProjectTypes
} from '../../masterData/common/CommonService';
import { getRegionsIntegrated, getBillingDiviIntegrated, getCustomerProgramsIntegrated, getcustomersIntegrated, getHolidaysIntegrated } from '../../integration/MasterDataService';
import {
  getAllUniqueIds,
  searchProjects,
  getRecentActiveProjects,
  getAllOPIDs,
  deleteProject, getCMSStatus, saveStatusChange, saveStatusChangeHistory,
  saveActivityLog, getActivityLogData
} from '../../projectManagement/project/ProjectService';


import {
  deleteCMSheet,
  getCmsTypes,
  saveCMSheet,
  setWorkflowStatus,
  withdrawCMSheet,
  setReviseCMSWorkflow
} from '../../projectManagement/cmSheet/CMSheetService';

import {
  getCRsOfAProject,
  deleteChangeReq,
  getAllCRUniqueIds,
  updateChangeReq
} from '../../projectManagement/changeRequest/ChangeRequestService';

import $ from "jquery";
import {getDeployableStatus, getPMResources, getProjectOwners, getResourceRequestSubLines, getProjectOrCrResourceRequest, deallocateRequestsFromStatusChange,
  getAllocationPercentageHistory,
  updateAllocationPercentageHistory,
  getLeavesIntegrated,
  getLegalEntityTypes
} from "../../resourceManagement/ResourceService";
import { getAllDocumentTypesByStatus } from '../../masterData/documentType/DocumentTypeService';
import moment from 'moment';
import { uploadFile } from '../../integration/fileUpload/FileUploadService';
import DeleteCell from '../../resourceManagement/DeleteCell';
import StatusFileUploadCell from '../../projectManagement/search/StatusFileUploadCell';
import ActivityLogFilesCell from '../../projectManagement/search/ActivityLogFilesCell'
import { saveAuditTrail } from '../../integration/auditTrail/AuditTrailService';
import ApproversCell from '../../projectManagement/search/ApproversCell';
import ApprovalStatusCell from '../../projectManagement/search/ApprovalStatusCell';
import {selectWorkflowStatus, selectReviseCMSRequestWorkflow} from "../../projectManagement/workflow/WorkflowService";
import StatusChangeCommentCell from '../../projectManagement/search/StatusChangeCommentCell';
import { reStructureDate, getFormattedDate, isWeekend } from '../../integration/CommonUtils'
import { getCostDetails } from '../../masterData/costCard/CostCardService';
import { getAllPendingTimeEntriesForReviseCms } from '../../Timesheets/projectTaskManagement/ProjectTaskManagementService';
import { Input } from '@progress/kendo-react-inputs';

const apiUrl = require('../../config.js').get(process.env.REACT_APP_ENV).apiUrl;
const ProjStatuses = require('../../projectManagement/search/StatusChangeEnums');
const Status = require('../../projectManagement/search/StatusEnums');
const ApprovalStatusChange = require('../../projectManagement/search/ApprovalStatusChangeEnums');

const resourceAll = [];

class Dashboard extends Component {
  isMount = false;
  comboBoxPlaceHolder = 'Please Select';
  CROrProjectCurrentStatus = null;

  popupSet = {
    width: 'auto'
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      projectItems: [],
      activeProjectItems: [],
      Projects: [],
      projectIdToDelete: null,
      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      pendingTasks: [],
      TotalCount:0,
      skip: 0,
      take: 6,
      cmSheetIdToDelete: null,
      cmSheetsArray: [],

      showCRSelectDialog: false,
      changeRequests: [],
      selectedCRId: null,
      redirectToEditCR: false,
      redirectToViewCR: false,
      redirectToEditCMS: false,
      redirectToViewCMS: false,
      redirectToEditViewCMS: false,
      selectedCMSId: null,
      projectIdOfSelectedCMS: null,
      redirectToAppro: '',
      loading: false,
      IsPoolProject: false,
      linkToPoolDialog : false,
      poolProjectsAll : [],
      selectedPoolProject: null,
      createCMS: false,
      isACR: false,
      isAProj : false,
      key:'',

      sort: [
        { field: 'updatedAtOrderBy', dir: 'DESC' }
      ],

      projectId: null,
      requestType: null,
      Statuses: [],

      Customers: [],
      CustomersAll: [],
      Programs: [],
      ProgramsAll: [],
      Divisions: [],
      DivisionsAll: [],
      Regions: [],
      RegionsAll: [],
      ProjectTypes: [],
      ProjectTypesAll: [],
      PaymentMethods: [],
      PaymentMethodsAll: [],
      UniqueIds: [],
      UniqueIdsAll: [],
      opids: [],
      opidsAll: [],

      ProjectStatuses: [],
      Customer: null,
      Program: null,
      Division: null,
      Region: null,
      ProjectType: null,
      PaymentMethod: null,
      UniqueId: null,
      opidvalue: null,
      StartDate: null,

      changeRequest: [],
      showErrorMessageDialog: false,
      showSuccessImg: false,
      showErrorImg: false,

      projectNameToDelete: null,

      CRsOfAProject: [],
      showCRSelectDialogOne: false,
      selectedCMSheet: null,
      selectedCMSReference: null,
      cmsAction: null,
      ChangeRequestAction: '',
      redirectToCreateCMS: false,
      redirectToCreateCMSPool: false,
      redirectToEditCMSPool: false,
      redirectToResourceReq: false,
      redirectToApproveCMS: false,
      selectedProject: '',

      // View CMS state
      projectCMS: [],
      changeRequestCMS: [],
      sortActivityLog: [{ field: 'updatedAt', dir: 'desc' }],

      resourceItems: resourceAll.slice(),
      resourceValue: null,
      CMSStatus: [],
      CMSStatusAll: [],
      SelectedCMSStatus: [],
      UniqueCRIds: [],
      UniqueCRIdsAll: [],
      cmsTypes: [],
      selectedCmsType: null,
      changeStatusDialog: false,

      // Status change validation
      invalidatePermission: true,
      statusChangeCr: null,
      statusValueLst: [],
      selectedChangeToStatus: null,
      projtypeValue: null,
      billingdiviValue: null,
      showStartDateValidationError: false,
      documentType: null,
      allDocumentTypesForStatus: [],
      documentTypesForDropdown: [],
      projectOrCr : null,
      toHomePage : false,
      startdateStatus : null,
      currentStatus: [],

      RBtnCount: true,
      isErrorMessageVisible: false,
      initStatusLst: [],

      files: [],
      selectedFile: "",
      uploadedFileId: "",
      isCannotUpload: true,
      rowCount: 0,
      fileUploadDetails: [],
      deletedFileUploadDetails: [],
      selectedDocTypes: [],
      projMandatoryDocTypes: [],
      CRMandatoryDocTypes: [],
      DocUploadErrorMessageVisible: false,
      fileUploadMessageVisible: false,

      stsChangeComment: '',
      allActivityLogList: [],
      activityLogList: [],

      showCloseSubCategory: false,
      disableFileUpBtnDiv: false,
      disableCommentBoxDiv: false,
      statusChangeObj: {},
      showWorkflowSelectDialog: false,
      matchingWorkflowList: [],
      selectedWorkFlowId: null,
      activityLogId:null,
      isApprovalStatusPending: false,
      reqStatus: null,
      approvalsDetails: [],
      approversName: [],
      resourceReqSubLines: [],
      showStartDateErrorMessage: false,
      keyId:"",
      statusChangeGridDetails: [],
      selectedStatus: null,
      deallocatedMainLines: [],
      deallocatedSubLines: [],
      showCMSWithdrawDialog: false,
      withdrawReason: null,
      showRequestReviseCMSDialog: false,
      requestReviseCMSReason: null,
      showReviseCMSWorkflowSelectDialog: false,
      selectedProjectOrCR: null,
      statusesForFilter: [],
      statusesForFilterAll: [],
      selectedStatuses: [],
      initialAllData: [],
      initLoading: false,
      isPONumberRequired: false,
      poNumber: null,
      showPONumberErrorMessage: false,
      disablePONumber: true,
      billingEntityType: [],
      billingEntityValue: null,
      showBillingEntityErrorMessage: false
    };

    this.toggleChangeStatusDialog = this.toggleChangeStatusDialog.bind(this);
  }

  toggleChangeStatusDialog() {
    this.setState({
      selectedChangeToStatus : null,
      documentType : null,
      changeStatusDialog: !this.state.changeStatusDialog,
      isErrorMessageVisible: false,
      fileUploadDetails: [],
      DocUploadErrorMessageVisible: false,
      fileUploadMessageVisible: false,
      allActivityLogList: [],
      activityLogList: [],
      showCloseSubCategory: false,
      disableFileUpBtnDiv: false,
      disableCommentBoxDiv: false,
      stsChangeComment: '',
      currentStatus: [],
      showStartDateValidationError: false,
      isApprovalStatusPending: false,
      reqStatus: null,
      approvalsDetails: [],
      approversName: [],
      allDocumentTypesForStatus: [],
      documentTypesForDropdown: [],
      showStartDateErrorMessage: false
    });

    this.CROrProjectCurrentStatus = null;
  }

  handleChangeStatusPopUp = async (dataItem) => {

    // validate the permissions
    this.statusChangePermission(dataItem, 'Project');
    const projectTypeObj = {
      Name: dataItem.Type.Name,
      id: dataItem.Type.id
    };

    const billingDiviObj = {
      Name: dataItem.Division.Name,
      id: dataItem.Division.id
    };

    this.setState({
      projtypeValue: projectTypeObj,
      billingdiviValue: billingDiviObj,
      endDateAugmentation: dataItem.EndDate ? reStructureDate(dataItem.EndDate) : null,
      endDateBlanketWork: dataItem.EndDate ? reStructureDate(dataItem.EndDate) : null,
      projectActivated: dataItem.StatusConvertedToActive,
      //currentStatus: dataItem.ProjectStatus,
      currentStatus: dataItem.ProjectStatus ? this.state.currentStatus.push(dataItem.ProjectStatus) : null,
      startdateStatus: reStructureDate(dataItem.StartDate),
      projectOrCr: dataItem,
      isPONumberRequired: dataItem.Customer && dataItem.Customer.PONumber ? true : false,
      poNumber: dataItem.PONumber ? dataItem.PONumber : "",
      billingEntityValue: dataItem.LegalEntity ? dataItem.LegalEntity : null
    });


    //start date - status change popup
    if(dataItem.StartDate === null){
      this.setState({
        startdateStatus: null
      });
    }

    // Open the change status popup
    this.toggleChangeStatusDialog();
  };

  statusChangePermission = async (project, type) =>{

    this.setState({
      loading: true
    });

    const term = 'CHG_TO_';
    const userPermissions = this.filterPermissions(term, window.USER_PERMISSION);
    let userPermissionsLst = [];
    let permissionLst = [];
    let statusLst = [];
    let modifiedStatusLst = [];
    let currentStatus;
    let selectedCR;

    // Get all DB permissions
    await getPermissionList().then(response => {
      permissionLst = response.data;
      userPermissions.forEach(function (value) {
        const item = response.data.filter(i => i.PermissionName === value);
        if (item) {
          userPermissionsLst.push(item[0])
        }
      });
    }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
    });


    if(type === 'Project'){
      currentStatus = (project && project.ProjectStatus) ? project.ProjectStatus : null;
      this.state.currentStatus.push(currentStatus);
      this.CROrProjectCurrentStatus = currentStatus.StatusName;
      this.CROrProjectCurrentStatusId = currentStatus.id;

      await getProjectOrCrResourceRequest(type, project.id).then(res => {
        if(res.data && res.data.length > 0){
          project.ResourceRequest = res.data;
        }
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }
    else if (type === 'ChangeRequest') {
      const selectedCrId = this.state.selectedCRId;
      const selectedCr = project.ChangeRequests.filter(obj => obj.id === selectedCrId);
      this.CROrProjectCurrentStatus = selectedCr[0].Status.StatusName;
      this.CROrProjectCurrentStatusId = selectedCr[0].Status.id;
      selectedCR = selectedCr;

      selectedCr[0].Type = project.Type;
      selectedCr[0].BillingDivision = project.Division;

      const projectTypeObj = {
        Name: selectedCr[0].Type.Name,
        id: selectedCr[0].Type.id
      };

      const billingDiviObj = {
        Name: selectedCr[0].BillingDivision.Name,
        id: selectedCr[0].BillingDivision.id
      };

      await getProjectOrCrResourceRequest(type, selectedCr[0].id).then(res => {
        if(res.data && res.data.length > 0){
          selectedCr[0].ResourceRequest = res.data;
        }
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

      await this.setState({
        projtypeValue: projectTypeObj,
        billingdiviValue: billingDiviObj,
        endDateAugmentation: selectedCr[0].EndDate ? reStructureDate(selectedCr[0].EndDate) : null,
        endDateBlanketWork: selectedCr[0].EndDate ? reStructureDate(selectedCr[0].EndDate) : null,
        projectActivated: selectedCr[0].StatusConvertedToActive,
        //currentStatus: selectedCr[0].StatusId,
        startdateStatus: reStructureDate(selectedCr[0].StartDate),
        projectOrCr: selectedCr[0],
        isPONumberRequired: selectedCr[0].Customer && selectedCr[0].Customer.PONumber ? true : false,
        poNumber: selectedCr[0].PONumber ? selectedCr[0].PONumber : "",
        billingEntityValue: selectedCr[0].LegalEntity ? selectedCr[0].LegalEntity : null
      });

      //start date
      if(selectedCr[0].StartDate === null){
        this.setState({
          startdateStatus: null
        });
      }

      currentStatus = (selectedCr && selectedCr.length > 0) ? selectedCr[0].Status : null;
      this.state.currentStatus.push(currentStatus);
    }

    // filter all permissions related to status change
    permissionLst = this.filterPermissions(term, permissionLst.map(a => a.PermissionName));

    if(currentStatus.Code === 'DEAL_ON_HOLD') {
      const cmsApprovedValidationLst = [Status['Waiting_Confirmation'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermissionsProjectStatus = [Status['Presales'].code, Status['Waiting_Confirmation'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Without_Bidding'].code, Status['Opportunity_Lost'].code]; 
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'PRESALES') {
      const cmsApprovedValidationLst = [Status['Waiting_Confirmation'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermissionsProjectStatus = [Status['Waiting_Confirmation'].code, Status['Deal_On_Hold'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Without_Bidding'].code, Status['Opportunity_Lost'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'WAIT_CONFIRM') {
      const cmsApprovedValidationLst = [Status['Deal_On_Hold'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermissionsProjectStatus = [Status['Deal_On_Hold'].code, Status['Working_At_Risk'].code, Status['Active'].code, Status['Opportunity_Lost'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'WORK_AT_RISK') {
      const cmsApprovedValidationLst = [Status['Active'].code, Status['Project_On_Hold'].code];
      const validPermissionsProjectStatus = [Status['Active'].code, Status['Project_On_Hold'].code, Status['Discontinue'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'PROJECT_ON_HOLD') {
      const cmsApprovedValidationLst = [Status['Active'].code];
      const validPermissionsProjectStatus = [Status['Active'].code, Status['Discontinue'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));

    } else if (currentStatus.Code === 'BILLABLE') { // Active
      let cmsApprovedValidationLst = [Status['Project_On_Hold'].code, Status['Complete'].code];
      let validPermissionsProjectStatus = [Status['Project_On_Hold'].code, Status['Complete'].code, Status['Discontinue'].code];
      if (!this.state.projectOrCr.StatusConvertedToActive) {
        cmsApprovedValidationLst = [Status['Project_On_Hold'].code];
        validPermissionsProjectStatus = [Status['Project_On_Hold'].code];  
      }
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));
    
    } else if (currentStatus.Code === 'COMPLETE') {
      const cmsApprovedValidationLst = [];
      const validPermissionsProjectStatus = [Status['Closed'].code];
      const validPermission = permissionLst.filter(i => validPermissionsProjectStatus.includes(i));
      statusLst = this.getValidPermissions(userPermissionsLst, validPermission, cmsApprovedValidationLst, (type === 'Project' ?  project.CMSheets : selectedCR[0].CMSheets));
    } 

    //copy statusLst
    modifiedStatusLst = statusLst.map((_arrayElement) => Object.assign({}, _arrayElement));
    
    for(let el of modifiedStatusLst){
      switch(el.PermissionDescription){
        case 'Change to Presale': {
          el.PermissionDescription = "Presale";
          break;
        }
        case 'Change to Waiting Confirmation': {
          el.PermissionDescription = "Waiting Confirmation";
          break;
        }
        case 'Change to Deal on-hold': {
          el.PermissionDescription = "Deal on-hold";
          break;
        }
        case 'Change to Working at Risk': {
          el.PermissionDescription = "Working at Risk";
          break;
        }
        case 'Change to Active': {
          el.PermissionDescription = "Active";
          break;
        }
        case 'Change to Project on-hold': {
          el.PermissionDescription = "Project on-hold";
          break;
        }
        case 'Change to Close without bidding': {
          el.PermissionDescription = "Close without bidding";
          break;
        }
        case 'Change to Close – Opportunity Lost': {
          el.PermissionDescription = "Close - Opportunity Lost";
          break;
        }
        case 'Change to Close - Discontinue': {
          el.PermissionDescription = "Close - Discontinue";
          break;
        }
        case 'Change to Complete': {
          el.PermissionDescription = "Complete";
          break;
        }
        case 'Change to Close': {
          el.PermissionDescription = "Close";
          break;
        }
        case 'Change to Closed': {
          el.PermissionDescription = "Closed";
          break;
        }
        default : {
        }
      }
    }

    // Set the field Enable/Disable param
    if (statusLst.length > 0) {
      if (this.isMount) {
        await this.setState({
          invalidatePermission: false,
          statusValueLst: modifiedStatusLst
        });
      }
    } else {
      if (this.isMount) {
        await this.setState({
          invalidatePermission: true
        });
      }
    }
    await this.populateActivityLogData(this.state.projectOrCr, project.id);

    this.setState({
      loading: false
    });
  };

  getValidPermissions = (userPermissionsLst, permissionLst, cmsApprovedValidationLst, CMSheets) => {
    let statusLst = [];
    let statusLstEdit = [];
    this.state.initStatusLst = [];

    //get valid status list
    for(let item of userPermissionsLst) {
      let permission = permissionLst.filter(i => i === item.PermissionName);
      if (permission && permission.length == 1) {
        if(cmsApprovedValidationLst.includes(permission[0])) { // check cMS Approved validation
          let cmSheet = CMSheets[CMSheets ? CMSheets.length - 1 : 0];
          if(cmSheet && cmSheet.Status === 'APPROVED'){
            this.state.initStatusLst.push(item);
          }
        } 
        else {
          this.state.initStatusLst.push(item);
        }
      }
    }

    //copy status list
    statusLstEdit = this.state.initStatusLst.map((_arrayElement) => Object.assign({}, _arrayElement));
    
    //change status --> close
    for(let el of statusLstEdit){
      if((el.PermissionName === Status['Without_Bidding'].code) || (el.PermissionName === Status['Opportunity_Lost'].code) || el.PermissionName === Status['Discontinue'].code){
        el.PermissionName = Status['Close'].code;
        el.PermissionDescription = "Close";
        el.IsActive = true;
        el.id = 108;
      }
    }

    //remove duplicates
    function getUniqueListBy(statusLstEdit, key) {
      return [...new Map(statusLstEdit.map(item => [item[key], item])).values()];
    }

    statusLst = getUniqueListBy(statusLstEdit, 'PermissionName');
    return statusLst;
  };

  filterPermissions = (needle, heystack) => {
    let query = needle.toLowerCase();
    return heystack.filter(item => item.toLowerCase().indexOf(query) >= 0);
  };

  handleOnChangeStatus =  async event => {
    const name = event.target.name;
    const value = event.target.value;

    await this.setState({
      selectedStatus: event,
      disablePONumber: true
    });

    const openCrMessage = "CR(s) belonging to this project are still open";
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const resorceAllocationMessage = `There are Resource allocations running beyond the completion date. Do you want to change the end dates of the overrunning allocations to the Project completion date ${todayDate}?`;
    const titleError = "Error";

    let changeToClose = Status['Close'].code;
    let changeToCloseOppLst = Status['Opportunity_Lost'].code;
    let changeToCloseWthtBid = Status['Without_Bidding'].code;
    let changeToCloseDis = Status['Discontinue'].code;
    let changeToComplete = Status['Complete'].code;

    let openCR = false;
    let projResAllocationBeyondCompDate = false;
    let crResAllocationBeyondCompDate = false;
    let count = 0;
    this.state.isErrorMessageVisible = false;
    this.state.RBtnCount = true;

    let selectedCR;

    $("input:radio").prop('checked', false);
    $("input:radio").prop('disabled', true);

    if (this.isMount) {
      await this.setState({
        [name]: value,
        disableCommentBoxDiv: true
      });

      if((value !== null) && (value.PermissionDescription === "Close")){
        this.setState({
          showCloseSubCategory: true
        });
      }

      if((value !== null) && (value.PermissionDescription !== "Close")){
        this.setState({
          showCloseSubCategory: false
        });
      }

      if(this.state.projectOrCr.ProjectName && this.state.projectOrCr.ChangeRequests.length > 0){
        let elements = (this.state.projectOrCr.ChangeRequests);
      
        for(let el of elements){
          if(el.Status.StatusName === "Active" || 
            el.Status.StatusName === "Presales" || 
            el.Status.StatusName === "Waiting Confirmation" || 
            el.Status.StatusName === "Deal On Hold" || 
            el.Status.StatusName === "Project On Hold" || 
            el.Status.StatusName === "Working At Risk"){
              openCR = true;
              break;
          }
          else{
            openCR = false;
          }
        }
      }
      
      //project resource allocation
      if(this.state.projectOrCr.ProjectName){
        if(this.state.projectOrCr.ResourceRequest && this.state.projectOrCr.ResourceRequest.length > 0){
          let deallocatedMainLines = [];
          let deallocatedSubLines = [];
          for(let resourceReq of this.state.projectOrCr.ResourceRequest){
            if(resourceReq.Status === 'COMPLETE' && resourceReq.IsActive){
              let endDate = reStructureDate(resourceReq.EndDate);
              let completionDate = new Date();
  
              if((resourceReq.AllocatedResourceID !== null) && (endDate.getTime() >= completionDate.getTime())){
                projResAllocationBeyondCompDate = true;
                deallocatedMainLines.push(resourceReq);
                // break;
              }
            }

            if(resourceReq.Status === 'EDIT' && resourceReq.IsActive){
              this.setState({
                loading: true
              });
              
              let resourceReqId = resourceReq.id;
              await getResourceRequestSubLines(resourceReqId).then(response => {
                let subLinesData = response.data;
                this.setState({
                  resourceReqSubLines: subLinesData
                }); 
              }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });

              for(let el of this.state.resourceReqSubLines){
                if(el.Status == 'COMPLETE' && el.IsActive){
                  let endDate = reStructureDate(el.EndDate);
                  let completionDate = new Date();
  
                  if((el.ResourceId !== null) && (endDate.getTime() >= completionDate.getTime())){
                    projResAllocationBeyondCompDate = true;
                    deallocatedSubLines.push(el);
                    // break;
                  }
                }
              }

              this.setState({
                loading: false
              });
            }
          }
          this.setState({
            deallocatedMainLines: deallocatedMainLines,
            deallocatedSubLines: deallocatedSubLines
          });
        }
      }

      //CR resource allocation
      if(!this.state.projectOrCr.ProjectName){
        if(this.state.projectOrCr.ResourceRequest && this.state.projectOrCr.ResourceRequest.length > 0){
          let deallocatedMainLines = [];
          let deallocatedSubLines = [];
          for(let resourceReq of this.state.projectOrCr.ResourceRequest){
            if(resourceReq.Status === 'COMPLETE' && resourceReq.IsActive){
              let endDate = reStructureDate(resourceReq.EndDate);
              let completionDate = new Date();
    
              if((resourceReq.AllocatedResourceID !== null) && (endDate.getTime() >= completionDate.getTime())){
                crResAllocationBeyondCompDate = true;
                deallocatedMainLines.push(resourceReq);
                // break;
              }
            }

            if(resourceReq.Status === 'EDIT' && resourceReq.IsActive){
              this.setState({
                loading: true
              });
              
              let resourceReqId = resourceReq.id;
              await getResourceRequestSubLines(resourceReqId).then(response => {
                let subLinesData = response.data;
                this.setState({
                  resourceReqSubLines: subLinesData
                });
              }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });

              for(let el of this.state.resourceReqSubLines){
                if(el.Status == 'COMPLETE' && el.IsActive){
                  let endDate = reStructureDate(el.EndDate);
                  let completionDate = new Date();
  
                  if((el.ResourceId !== null) && (endDate.getTime() >= completionDate.getTime())){
                    crResAllocationBeyondCompDate = true;
                    deallocatedSubLines.push(el);
                    // break;
                  }
                }
              }

              this.setState({
                loading: false
              });
            }
          }
          this.setState({
            deallocatedMainLines: deallocatedMainLines,
            deallocatedSubLines: deallocatedSubLines
          });
        }
      }

      // Start Date - Status Changed to Active/ Working At Risk
      // Project
      if(this.state.projectOrCr.ProjectName){
        if(value !== null && (value.PermissionDescription === 'Active' || value.PermissionDescription === 'Working at Risk')){
          let activeOrWarStartDate = (this.state.projectOrCr.ActiveOrWARStartDate && this.state.projectOrCr.ActiveOrWARStartDate !== null) ? reStructureDate(this.state.projectOrCr.ActiveOrWARStartDate) : null;
          let projOrCrStartDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? reStructureDate(this.state.projectOrCr.StartDate) : null;

          this.setState({
            startdateStatus: (activeOrWarStartDate !== null) ? (activeOrWarStartDate) : (projOrCrStartDate)
          });
        }
        else{
          let startDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? reStructureDate(this.state.projectOrCr.StartDate) : null;

          this.setState({
            startdateStatus: (startDate !== null) ? startDate : null
          });
        }
      }
      // CR
      if(!this.state.projectOrCr.ProjectName){
        if(value !== null && (value.PermissionDescription === 'Active' || value.PermissionDescription === 'Working At Risk')){
          let activeOrWarStartDate = (this.state.projectOrCr.ActiveOrWARStartDate && this.state.projectOrCr.ActiveOrWARStartDate !== null) ? reStructureDate(this.state.projectOrCr.ActiveOrWARStartDate) : null;
          let projOrCrStartDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? reStructureDate(this.state.projectOrCr.StartDate) : null;

          this.setState({
            startdateStatus: (activeOrWarStartDate !== null) ? (activeOrWarStartDate) : (projOrCrStartDate)
          });
        }
        else{
          let startDate = (this.state.projectOrCr.StartDate && this.state.projectOrCr.StartDate !== null) ? reStructureDate(this.state.projectOrCr.StartDate) : null;

          this.setState({
            startdateStatus: (startDate !== null) ? startDate : null
          });
        }
      }

      if((value !== null) && (value.PermissionName === changeToCloseWthtBid || value.PermissionName === changeToCloseOppLst || value.PermissionName === changeToCloseDis)){
        value.PermissionName = changeToClose;
      }

      if((value !== null) && (value.PermissionName === changeToClose)){
        if(openCR === true){
          this.setState({
            showErrorImg: true
          });
          this.toggleErrorMessageDialog(openCrMessage, titleError);
          this.toggleChangeStatusDialog(); 
        }
        
        if(openCR === false){
          for(let el of this.state.initStatusLst){
            if(el.PermissionName === changeToCloseWthtBid){
              $("input:radio[value='wthtbid']").prop('disabled',false);
              count++;
            }
            else if(el.PermissionName === changeToCloseOppLst){
              $("input:radio[value='oppolost']").prop('disabled',false);
              count++;
            }
            else if(el.PermissionName === changeToCloseDis){
              $("input:radio[value='discontinue']").prop('disabled',false);
              count++;
            }
            this.state.RBtnCount = false;
            console.log('RBtncount : ', this.state.RBtnCount);
          }

          if(count === 1){
            for(let el of this.state.initStatusLst){
              switch (el.PermissionName) {
                case changeToCloseWthtBid:
                  $("input:radio[value='wthtbid']").prop('checked',true);
                  value.PermissionName = changeToCloseWthtBid;
                  this.state.RBtnCount = true;
                  break;
                
                case changeToCloseOppLst:
                  $("input:radio[value='oppolost']").prop('checked',true);
                  value.PermissionName = changeToCloseOppLst;
                  this.state.RBtnCount = true;
                  break;

                case changeToCloseDis:
                  if(projResAllocationBeyondCompDate){
                    $("input:radio[value='discontinue']").prop('checked',false);
                    $("input:radio[value='discontinue']").prop('disabled',true);

                    this.setState({
                      showErrorImg: true
                    });
                    this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
                  }
                  if(crResAllocationBeyondCompDate){
                    $("input:radio[value='discontinue']").prop('checked',false);
                    $("input:radio[value='discontinue']").prop('disabled',true);

                    this.setState({
                      showErrorImg: true
                    });
                    this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
                  }
                  else{
                    $("input:radio[value='discontinue']").prop('checked',true);
                    value.PermissionName = changeToCloseDis;
                    this.state.RBtnCount = true;
                  }
                  break;
              
                default:
                  break;
              }
            }
          }

          else if(count > 1){
            $("input:radio[name='changestatus']").on('change', function(){
              if($("input:radio[value='wthtbid']").is(':checked')){
                value.PermissionName = changeToCloseWthtBid;
              }
              else if($("input:radio[value='oppolost']").is(':checked')){
                value.PermissionName = changeToCloseOppLst;
              }
              else if($("input:radio[value='discontinue']").is(':checked')){
                value.PermissionName = changeToCloseDis;
              }
            });
          }
        }
      }

      if((value !== null) && (value.PermissionName === changeToComplete)){
        let cmsApproved = false;

        for(let cms of this.state.projectOrCr.CMSheets){
          if(cms.Status === "APPROVED"){
            cmsApproved = true;
          }
          else{
            cmsApproved = false;
          }
        }

        if(this.state.projectOrCr.ProjectName && this.state.projectOrCr.ProjectStatus.StatusName === "Active" && cmsApproved === true){
          if(openCR === true){
            this.setState({
              showErrorImg: true
            });
            this.toggleErrorMessageDialog(openCrMessage, titleError);
            this.toggleChangeStatusDialog();
          }
          else if(projResAllocationBeyondCompDate){
            this.setState({
              showErrorImg: true
            });
            this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
          }
        }

        else if(this.state.projectOrCr.Status.StatusName === "Active" && cmsApproved === true){
          if(crResAllocationBeyondCompDate){
            this.setState({
              showErrorImg: true
            });
            this.toggleConfirmDialog(resorceAllocationMessage, this.deallocateResourceRequest);
          }   
        }

        else{
          $("Button[id='statusPopupSaveBtn']").prop('disabled',true);
        }
      }
    }

    if(value !== null){
      //get document types for the selected status

      this.setState({
        loading: true
      });

      let docTypesForProject = [];
      let docTypesForCr = [];
      let statusKey = value.PermissionName;
      let documentTypes = await getAllDocumentTypesByStatus(ProjStatuses[statusKey].value);

      //Project
      if(!(this.state.projectOrCr.ProjectId)){
        docTypesForProject = documentTypes.data.filter(el => el.IsProjectApplicable || el.IsProjectMandatory);
        await this.setState({
          allDocumentTypesForStatus : docTypesForProject
        });
      }
      //CR
      else if(this.state.projectOrCr.ProjectId){
        docTypesForCr = documentTypes.data.filter(el => el.IsCRApplicable || el.IsCRMandatory);
        await this.setState({
          allDocumentTypesForStatus : docTypesForCr
        });
      }

      //document 'isMandatory'
      for(let el of this.state.allDocumentTypesForStatus){
        if(!(this.state.projectOrCr.ProjectId)){
          if(el.IsProjectMandatory){
            el.isMandatory = "true";
          }
        }
        else if(this.state.projectOrCr.ProjectId){
          if(el.IsCRMandatory){
            el.isMandatory = "true";
          }
        }
      }

      if(value.PermissionDescription === 'Active'){
        this.setState({
          isPONumberRequired: this.state.projectOrCr.Customer && this.state.projectOrCr.Customer.PONumber ? true : false,
          poNumber: this.state.projectOrCr.PONumber ? this.state.projectOrCr.PONumber : "",
          billingEntityValue: this.state.projectOrCr.LegalEntity ? this.state.projectOrCr.LegalEntity : null,
          disablePONumber: false
        });
      }

      this.setState({
        documentTypesForDropdown: this.state.allDocumentTypesForStatus,
        loading: false
      });
    }

    if(value === null){
      this.setState({
        showCloseSubCategory: false,
        isErrorMessageVisible: false,
        DocUploadErrorMessageVisible: false,
        fileUploadMessageVisible: false,
        disableFileUpBtnDiv: false,
        disableCommentBoxDiv: false,
        fileUploadDetails: [],
        stsChangeComment: ''
      });
    }
  };

  deallocateResourceRequest = async () => {

    await this.setState({
      loading: true
    });

    let mainLinesToBeUpdated = [];
    let mainLinesToBeDeleted = [];
    let subLinesToBeUpdated = [];
    let subLinesToBeDeleted = [];

    if (this.state.deallocatedMainLines && this.state.deallocatedMainLines.length > 0) {
      for (const request of this.state.deallocatedMainLines) {
        request.ResDeallocationStartDate = moment(new Date()).format('YYYY-MM-DD');

        if(request.ResDeallocationStartDate >= request.StartDate){
          //update data
          await this.deallocateAllocationPercentageHistory(request);
          await this.getCostForDesignation(request);
          mainLinesToBeUpdated.push(request);
        } else {
          let deletedIds = [];
          deletedIds.push(request.id)
          let mainLineItem = {
            deletedIds: deletedIds,
            type: request.Type,
            id: this.state.projectOrCr.id
          };

          mainLinesToBeDeleted.push(mainLineItem);
        }
      }
    }

    if (this.state.deallocatedSubLines && this.state.deallocatedSubLines.length > 0) {
      for (const request of this.state.deallocatedSubLines) {
        request.ResDeallocationStartDate = moment(new Date()).format('YYYY-MM-DD');

        if(request.ResDeallocationStartDate >= request.StartDate){
          //update data
          await this.deallocateAllocationPercentageHistory(request);
          await this.getCostForDesignation(request);
          subLinesToBeUpdated.push(request);
        } else {
          let deletedIds = [];
          deletedIds.push(request.id)
          subLinesToBeDeleted.push(deletedIds);
        }
      }
    }

    let items = {
      projectOrCr: this.state.projectOrCr,
      mainLinesToBeUpdated: mainLinesToBeUpdated,
      mainLinesToBeDeleted: mainLinesToBeDeleted,
      subLinesToBeUpdated: subLinesToBeUpdated,
      subLinesToBeDeleted: subLinesToBeDeleted
    };
    const deallocatedLinesTot = mainLinesToBeUpdated.length + mainLinesToBeDeleted.length + subLinesToBeUpdated.length + subLinesToBeDeleted.length;
 
    await deallocateRequestsFromStatusChange(items)
      .then(async res => {
        if (res.data && res.data.length > 0) {
          this.state.projectOrCr.ResourceRequest = res.data;
          await this.setState({
            showConfirmDialog: false,
            showSuccessImage: true
          });
          await this.toggleMessageDialog(`${deallocatedLinesTot} Resources were deallocated.`, 'Success');
          await this.handleOnChangeStatus(this.state.selectedStatus);
        }

      })
      .catch(async error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

      await this.setState({
        loading: false
      });
  }

  deallocateAllocationPercentageHistory = async (dataItem) => {

    let lineAllocationHistoryList = [];
    const resourceRequestId = dataItem && dataItem.ResourceRequestId ? 
                              dataItem.ResourceRequestId : dataItem.id;
    const resourceRequestIdSub = dataItem && dataItem.ResourceRequestId ? 
                              dataItem.id : null;
    const newEndDate = moment(dataItem.ResDeallocationStartDate).format('YYYY-MM-DD');
    const resourceDetails = {
      resourceRequestId: resourceRequestId,
      resourceRequestIdSub: resourceRequestIdSub,
    };
    await getAllocationPercentageHistory(resourceDetails)
    .then(res => {
      lineAllocationHistoryList = res.data;
    }).catch(err => {
      loggerService.writeLog(err, LOG_TYPES.ERROR);
    });

    const validAlocationHistoryList = lineAllocationHistoryList.filter(record => new Date(record.StartDate) >= new Date(newEndDate) || new Date(record.EndDate) >= new Date(newEndDate));

    for (const record of validAlocationHistoryList) {
      if (record.StartDate > newEndDate && record.EndDate > newEndDate) {
        record.IsActive = false;
      } else if (record.StartDate <= newEndDate && record.EndDate >= newEndDate) {
        record.EndDate = moment(newEndDate).format('YYYY-MM-DD')
      }
    }
    for (const item of validAlocationHistoryList) {
      await this.calculateAllocationHistoryLineEffort(item, dataItem);
    }

    await updateAllocationPercentageHistory(validAlocationHistoryList)
    .then(async res => {
      
    }).catch(err => {
      loggerService.writeLog(err, LOG_TYPES.ERROR);
    });
  }

  calculateAllocationHistoryLineEffort = async (historyLine, request) => {

    let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
    let isHoliday = false;
    let isLeave = false;
    let isHalfDay = false;

    if (request && historyLine.StartDate && historyLine.AllocationPercentage > 0) {

      let startDate = new Date(historyLine.StartDate);
      let endDate = new Date(historyLine.EndDate);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      
      let effort = 0;
      let resourceCountryId = null; //check this
      if (request.OnsiteStatus === "Offshore") {
        resourceCountryId = request.dataValueResourceRegion ? request.dataValueResourceRegion.DefaultCountryId : null;
      } else if (request.OnsiteStatus === "Onsite") {
        resourceCountryId = this.state.projectOrCr.CountryId;
      }
      let holidays = []; 
      holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
        if (res && res.data) {
          return res.data;
        }
        return [];
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      if (!holidays) {
        holidays = [];
      }
      const leaveObj = {
        ResourceId: request.ResourceId, 
        StartDate: moment(historyLine.StartDate).format('YYYY-MM-DD'), 
        EndDate: moment(historyLine.EndDate).format('YYYY-MM-DD')
      }
      let resourceLeaves = await getLeavesIntegrated(leaveObj).then(res => {
        if (res && res.data) {
            return res.data;
        }
        return [];
      })
      .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
      let loop = new Date(startDate);
      while (loop <= endDate) {
        isHoliday = false;
        isLeave = false;
        isHalfDay = false;
        const dateStr = getFormattedDate(loop);
        if (holidays.indexOf(dateStr) > -1) {
          isHoliday = true;
        }

        if (resourceLeaves && resourceLeaves.length > 0) {
          let leaveDate = resourceLeaves.filter(
              leave => leave.LeaveDate === dateStr
          );
          if (leaveDate && leaveDate.length > 0) {
              for (const leave of leaveDate) {
                  if (leave.Duration === '8') {
                      isLeave = true;
                  } else {
                      isHalfDay = true;
                  }
              }
            }
        }

        if (!isHoliday && !isLeave && !isWeekend(loop)) {
          if (!isHalfDay) {
            totalWorkingDays = totalWorkingDays + 1;
          } else {
            totalWorkingDays = totalWorkingDays + 0.5;
          }
        }

        let newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }

      effort = totalWorkingDays * (historyLine.AllocationPercentage / 100);
      historyLine.EffortDays = Number((effort).toFixed(2));
    }
  };

  getCostForDesignation = async dataItem => {

    let billingRegion = 0;
    let projOrCr = null;
    let projectId = null;
    let DirectCostInUSD = 0;
    let CostPerResourceInUSD = 0;
    let totalCostUSD = 0;
    let EffortDays = 0;

    billingRegion = this.state.projectOrCr.BillingRegion;
    projOrCr = this.state.projectOrCr;
    projectId = this.state.projectOrCr.id;

    let newItem = {
      newResProjectOrCRName: this.state.projectOrCr,
      newResRegion:dataItem.dataValueResourceRegion ? dataItem.dataValueResourceRegion : null,
      newOnSiteStatus: dataItem.OnsiteStatus,
      newStartDate: moment(dataItem.StartDate).format('MM/DD/YYYY'),
      newEndDate:  moment(new Date(dataItem.ResDeallocationStartDate)).format('MM/DD/YYYY'),
      newEffortDays: dataItem.EffortDays.toFixed(2),
      newAllocation: dataItem.AllocationPercentage,
      EstimatedEndDate:moment(new Date(dataItem.ResDeallocationStartDate)).format('MM/DD/YYYY'),
      newResDesignation: dataItem.Designation == 57 ? { id: dataItem.Designation } : dataItem.AllocatedDesignationId ?  { id: dataItem.AllocatedDesignationId } : dataItem.Resource ? { id: dataItem.Resource.DesignationId } : null,
      resourceCountry : dataItem.Resource.GeneralCountry,
      projOrCrCountry : projOrCr.CountryId,
      resourceId: null,
      ResourceRequestId: dataItem.ResourceRequestId ? dataItem.ResourceRequestId : dataItem.id,
      ResourceRequestSubLineId: dataItem.ResourceRequestId ? dataItem.id : null,
      CostPerOutSourcedResource: dataItem.Designation == 57 ? (dataItem.CostPerOutSourcedResource && dataItem.CostPerOutSourcedResource > 0) ? dataItem.CostPerOutSourcedResource : dataItem.InitialCostPerResource : null
    };

    let costDate_ = new Date(dataItem.StartDate);

    const costCard = {
      billingRegionId: billingRegion,
      resourceRegionId: dataItem.Resource ? dataItem.Resource.Region : null,
      designationId: dataItem.Designation == 57 ? dataItem.Designation : dataItem.AllocatedDesignationId ? dataItem.AllocatedDesignationId : dataItem.Resource ? dataItem.Resource.DesignationId : null,
      projectId: projectId,
      costDate: moment(new Date()).format('YYYY-MM-DD')
    };

    costDate_ = moment(costDate_).format('YYYY-MM-DD');
    const costItems = {
      costCard : costCard,
      newItem : newItem,
      costDate: costDate_
    };

    await getCostDetails(costItems)
        .then(res => {
          totalCostUSD = res.data.TotalCost;
          DirectCostInUSD = res.data.DirectCost;
          CostPerResourceInUSD = res.data.CostPerResource;
          EffortDays = res.data.EffortDays;
        })
        .catch( async err => {
          totalCostUSD = 0;
          DirectCostInUSD = 0;
          CostPerResourceInUSD = 0;
          EffortDays = 0;
          loggerService.writeLog(err, LOG_TYPES.ERROR);

          const message = 'Cost card record not found for the selected designation and resource region';
          const title = 'Error';
          await this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });

          await this.toggleMessageDialog(message, title);
        });

    dataItem.TotalCost = totalCostUSD;
    dataItem.DirectCost = DirectCostInUSD;
    dataItem.CostPerResource = CostPerResourceInUSD;
    dataItem.EffortDays = EffortDays;

    // Revenue
    if (EffortDays && dataItem.RateValue) {
      dataItem.TotalRevenue = EffortDays * dataItem.RateValue;
    } else {
      dataItem.TotalRevenue = 0;
    }

    // Cost to Revenue ratio
    if (totalCostUSD > 0 && dataItem.TotalRevenue > 0) {
      dataItem.CostToRevenueRatio = (totalCostUSD / dataItem.TotalRevenue).toFixed(2);
    } else {
      dataItem.CostToRevenueRatio = 0;
    }
  };

  handleStatusChangeRbtn = async event => {
    const value = event.target.value;
    let permissionName;

    if($("input:radio[value=value]").prop('checked',true)){ 
      this.state.isErrorMessageVisible = false;
      this.state.RBtnCount = true;

      if(value === 'wthtbid'){
        permissionName = Status['Without_Bidding'].code;
      }
      else if(value === 'oppolost'){
        permissionName = Status['Opportunity_Lost'].code;
      }
      else if(value === 'discontinue'){
        permissionName = Status['Discontinue'].code;
      }
    }

    else if($("input:radio[value=value]").prop('checked',false)){
      this.state.RBtnCount = false;
    }

    //get document types for the selected status
    let docTypesForProject = [];
    let docTypesForCr = [];
    let statusKey = permissionName;
    let documentTypes = await getAllDocumentTypesByStatus(ProjStatuses[statusKey].value);

    //Project
    if(!(this.state.projectOrCr.ProjectId)){
      docTypesForProject = documentTypes.data.filter(el => el.IsProjectApplicable || el.IsProjectMandatory);
      await this.setState({
        allDocumentTypesForStatus : docTypesForProject
      });
    }
    //CR
    else if(this.state.projectOrCr.ProjectId){
      docTypesForCr = documentTypes.data.filter(el => el.IsCRApplicable || el.IsCRMandatory);
      await this.setState({
        allDocumentTypesForStatus : docTypesForCr
      });
    }

    //document 'isMandatory'
    for(let el of this.state.allDocumentTypesForStatus){
      if(!(this.state.projectOrCr.ProjectId)){
        if(el.IsProjectMandatory){
          el.isMandatory = "true";
        }
      }
      else if(this.state.projectOrCr.ProjectId){
        if(el.IsCRMandatory){
          el.isMandatory = "true";
        }
      }
    }
    await this.setState({
      documentTypesForDropdown : this.state.allDocumentTypesForStatus
    });
    
  };


  handleFileUpload = async event => {
    let uploadedFiles = [];

    for(let i=0; i<event.target.files.length; i++){
      uploadedFiles.push(event.target.files[i]);
    }

    for(let i = 0; i < uploadedFiles.length; i++){
      if( uploadedFiles[i].type === "application/pdf" || 
          uploadedFiles[i].type === "application/msword" ||
          uploadedFiles[i].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          uploadedFiles[i].type === "application/vnd.ms-excel" || 
          uploadedFiles[i].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || 
          uploadedFiles[i].type === "application/vnd.ms-powerpoint" || 
          uploadedFiles[i].type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          uploadedFiles[i].type === "image/png" ||
          uploadedFiles[i].type === "image/jpeg" ||
          uploadedFiles[i].type === "application/vnd.ms-outlook" ||
          uploadedFiles[i].type === "application/msoutlook" ||
          uploadedFiles[i].name.includes('.msg') ||
          uploadedFiles[i].name.includes('.htm') ){            
        await this.setState({
          selectedFile: uploadedFiles[i],
          loaded: 0,
          isCannotUpload: false,
          fileUploadMessageVisible: false,
          DocUploadErrorMessageVisible: false,
          loading: true
        });
      }
      else{
        await this.setState({
          fileUploadMessageVisible: true,
          DocUploadErrorMessageVisible: false
        });
      }
      
      if(this.state.isCannotUpload === false){
        // UPLOAD LOGIC
        var form_data = new FormData();
        form_data.append('file', this.state.selectedFile);
        form_data.append('referenceType', 'StatusChange');
        form_data.append('referenceId', this.state.projectOrCr.id);
        form_data.append('docType', this.state.documentType.DocType);

        await uploadFile(form_data).then(res => {
          let fields = {};

          if(this.state.documentType){
            fields.DocType = this.state.documentType.DocType;
            fields.projectId = this.state.projectOrCr.id;
          }
          
          if(res){
            fields.FileUpload = res.data;
          }

          if(this.isMount){
            this.setState(prevState => {
              this.setState({ rowCount: prevState.rowCount + 1 });
              fields.id = this.state.rowCount;
            });
          }

          this.state.fileUploadDetails.push(fields);
          let documentType  = this.state.documentTypesForDropdown.filter(item => item.id !== this.state.documentType.id);      
          
          this.setState({
            //documentType: null,
            selectedFile: [],
            disableFileUpBtnDiv: false,
            loading: false,
            documentTypesForDropdown: documentType
          });
        })
        .catch(error => {
          //error
        });
      }
    }

    if(this.state.fileUploadMessageVisible === false){
      await this.setState({
        documentType: null,
        selectedFile: []
      });
    }
  };

  OnDelete = async (event, id) => {
    event.preventDefault();
    
    let fileUploadDetails = [...this.state.fileUploadDetails];
    let array = [...this.state.fileUploadDetails];
    let deletedFilesCopy = [...this.state.deletedFileUploadDetails];
    let deletedFile = fileUploadDetails.filter(item => item.id === id);
    const deletedDocType = deletedFile[0].FileUpload.DocumentType
    let addDocType  = this.state.allDocumentTypesForStatus.filter(item => item.DocType === deletedDocType);
    let documentType = this.state.documentTypesForDropdown;
    if(addDocType && addDocType.length >0) {
      documentType.push(addDocType[0]);
    }
    
    deletedFilesCopy.push(deletedFile[0]);
    array = array.filter(item => item.id !== id);

    if(this.isMount){
      await this.setState({
        fileUploadDetails: array,
        deletedFileUploadDetails: deletedFilesCopy,
        documentTypesForDropdown: documentType
      });
    }
  };

  filterStatusOnChange = event => {
    this.setState({
      projects: this.filterStatusData(event.filter)
    });
  };

  filterStatusData(filter) {
    const data = this.state.statusValueLst.slice();
    return filterBy(data, filter);
  }

  handleOnChangeDocType =  async event => {
    const name = event.target.name;
    const value = event.target.value;

    if (this.isMount) {
      await this.setState({
        [name]: value,
        DocUploadErrorMessageVisible: false,
        fileUploadMessageVisible: false,
        disableFileUpBtnDiv: true
      });
    }
  };

  filterDocTypeOnChange = event => {
    this.setState({
      projects: this.filterDocTypeData(event.filter)
    });
  };

  filterDocTypeData(filter) {
    const data = this.state.documentTypesForDropdown.slice();
    return filterBy(data, filter);
  }

  handleHighlightMandatoryDocs = (li, itemProps) => {
    if(itemProps.dataItem.isMandatory){
       const itemChildren = <span>{li.props.children} <span className="mandatory"></span></span>;
      return React.cloneElement(li, li.props, itemChildren);
    }
    else{
      const itemChildren = <span>{li.props.children}</span>;
     return React.cloneElement(li, li.props, itemChildren);
    }
  }

  handlePONumberChange = event => {
    let value = event.target.value;
    this.setState({
      poNumber: value,
    });
  }

  handleStartdateChange = event => {
    let startDate = event.target.value;

    this.setState({
      startdateStatus: startDate,
      showStartDateErrorMessage: false
    });
  
    if (startDate) {
      this.setState({
        showStartDateValidationError: false
      });
    }
  };

  onChangeStsChgComment = statusComment => {
    this.setState({
      stsChangeComment: statusComment
    });
  };
  
  handleBillingEntityChange = event => {
    const value = event.target.value;
    this.setState({
      billingEntityValue: value,
      isFormDirty: true
    });
  };

  filterBillingEntities = event => {
    this.setState({
      billingEntityType: this.filterBillingEntityData(event.filter)
    });
  };

  filterBillingEntityData(filter) {
    const data = this.allBillingEntities.slice();
    return filterBy(data, filter);
  }

  onSaveStatusChange = async() => {

    this.setState({
      loading: true
    });

    let proOrcmsId = null;
    let startDateErrorMessage = false;
    let closeStatusErrorMessage = false;
    let pastStartDateErrorMessage = false;

    if (this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk' || this.state.selectedChangeToStatus.PermissionDescription === 'Waiting Confirmation')) {
      if (this.state.startdateStatus == null) {
        startDateErrorMessage = true;
      }
    }

    if ((this.state.currentStatus[0].StatusName === 'Presales') &&
        (this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk')) &&
        ($("DatePicker[name='startdateStatus']").prop('disabled', false)) && (!this.state.projectActivated || this.state.projectActivated == null)) {
          let startDate = moment(this.state.startdateStatus).format('YYYY-MM-DD');
          let currentDate = moment(new Date()).format('YYYY-MM-DD');

          if(startDate < currentDate){
            pastStartDateErrorMessage = true;
          }
    }


    // Project/CR Start Date - Status Changed to Active/Working At Risk
    let activeOrWarStartDate = null;

    if(this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk')){
      activeOrWarStartDate = (this.state.startdateStatus && this.state.startdateStatus !== null) ? (moment(this.state.startdateStatus).format('YYYY-MM-DD')) : null;
    }

    if(!(this.state.RBtnCount)){
      closeStatusErrorMessage = true;
    }

    if((!this.state.projectActivated) &&
      (this.state.selectedChangeToStatus && (this.state.selectedChangeToStatus.PermissionDescription === 'Active' || this.state.selectedChangeToStatus.PermissionDescription === 'Working at Risk'))){
      let conversionStartDate = moment(this.state.startdateStatus).format('YYYY-MM-DD');
      let projOrCrId = this.state.projectOrCr.id;
      let isProject = this.state.projectOrCr.ProjectId ? false : true;

      let item = {
        conversionStartDate: conversionStartDate,
        projOrCrId: projOrCrId,
        isProject: isProject,
        PONumber: this.state.poNumber ? this.state.poNumber.trim() : null, // update PO number
        BillingEntityId: this.state.billingEntityValue ? this.state.billingEntityValue.id : null
      };

      await saveConversionStartdate(item);
    }

    let errorMessage = false;
    let trueCount = 0;
    let falseCount = 0;
    let currentUploadedFiles = [];

    //get mandatory doc types for status
    for(let el of this.state.allDocumentTypesForStatus){
      if(el.IsProjectMandatory){
        this.state.projMandatoryDocTypes.push(el.DocType);
      }
      if(el.IsCRMandatory){
        this.state.CRMandatoryDocTypes.push(el.DocType);
      }
    }

    //remove duplicate files
    function getUniqueListBy(fileUploadDetails, key) {
      return [...new Map(fileUploadDetails.map(item => [item[key], item])).values()];
    }
    currentUploadedFiles = getUniqueListBy(this.state.fileUploadDetails, 'DocType');

    //check for mandatory doc types
    if(currentUploadedFiles.length > 0){
      if(!(this.state.projectOrCr.ProjectId)){
        for(let docType of this.state.projMandatoryDocTypes){
          for(let upFile of currentUploadedFiles){
            if(docType === upFile.DocType){
              trueCount = trueCount+1;
            }
            else{
              falseCount = falseCount+1;
            }
          }
        }
      }
      else if(this.state.projectOrCr.ProjectId){
        for(let docType of this.state.CRMandatoryDocTypes){
          for(let upFile of currentUploadedFiles){
            if(docType === upFile.DocType){
              trueCount = trueCount+1;
            }
            else{
              falseCount = falseCount+1;
            }
          }
        }
      }
    }
    //save without mandatory document upload
    else if(currentUploadedFiles.length === 0){
      if((!this.state.projectOrCr.ProjectId) && (this.state.projMandatoryDocTypes.length > 0)){
        errorMessage = true;
      }
      else if((this.state.projectOrCr.ProjectId) && (this.state.CRMandatoryDocTypes.length > 0)){
        errorMessage = true;
      }
    }

    if(currentUploadedFiles.length > 0){
      if((!this.state.projectOrCr.ProjectId) && (trueCount === this.state.projMandatoryDocTypes.length)){
        errorMessage = false;
      }
      else if((this.state.projectOrCr.ProjectId) && (trueCount === this.state.CRMandatoryDocTypes.length)){
        errorMessage = false;
      }
      else{
        errorMessage = true;
      }
    }

    let poNumberError = false;
    if (this.state.selectedChangeToStatus && this.state.selectedChangeToStatus.PermissionDescription === 'Active' && 
        this.state.isPONumberRequired && (!this.state.poNumber || this.state.poNumber === '')) {
      poNumberError = true;
    }

    let billingEntityError = false;
    if (this.state.selectedChangeToStatus && this.state.selectedChangeToStatus.PermissionDescription === 'Active' && 
        !this.state.billingEntityValue) {
          billingEntityError = true;
    }

    if (this.state.selectedChangeToStatus && this.state.selectedChangeToStatus.PermissionDescription === 'Active') {
      const billingMethod = this.state.projectOrCr.ProjectId ? this.state.projectOrCr.PaymentMethodId : this.state.projectOrCr.PaymentMethod;
      if (billingMethod && billingMethod !== 2 && this.state.projectOrCr.Milestone && this.state.projectOrCr.Milestone.length <= 0) {
        this.setState({
          showErrorImg: true,
          loading: false
        });
        const msg = "Please add a milestone at Project Creation and try again."
        this.toggleErrorMessageDialog(msg, 'Error');
        return;
      }
    }

    if(startDateErrorMessage || closeStatusErrorMessage || errorMessage || pastStartDateErrorMessage || poNumberError || billingEntityError){
      if(closeStatusErrorMessage){
        this.setState({
          isErrorMessageVisible: true,
          loading: false
        });
      }
      if(startDateErrorMessage){
        this.setState({
          showStartDateValidationError: true,
          loading: false
        });
      }
      if(errorMessage){
        this.setState({
          DocUploadErrorMessageVisible: true,
          fileUploadMessageVisible: false,
          projMandatoryDocTypes: [],
          CRMandatoryDocTypes: [],
          loading: false
        });
      }
      if(pastStartDateErrorMessage){
        this.setState({
          showStartDateErrorMessage: true,
          loading: false
        });
      }
      if(poNumberError){
        this.setState({
          showPONumberErrorMessage: true,
          loading: false
        });
      }
      if(billingEntityError){
        this.setState({
          showBillingEntityErrorMessage: true,
          loading: false
        });
      }
      return;
    }

    let projectOrCr = this.state.projectOrCr;
    //update project/cr
    let status = ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value;
    let startDate = this.state.startdateStatus ? (moment(this.state.startdateStatus).format('YYYY-MM-DD')) : null;
    let fileUploadIds = [];
    let uploadedFiles = this.state.fileUploadDetails;
    let fileUpLength = this.state.fileUploadDetails.length;
    let statusComment = this.state.stsChangeComment.replace(/(<([^>]+)>)/ig, '');
    let changedDate = moment(new Date()).format('YYYY-MM-DD');
    let statusName = ProjStatuses[this.state.selectedChangeToStatus.PermissionName].name;

    for(let el of uploadedFiles){
      fileUploadIds.push(el.FileUpload.id);
    }

    const statusChangeObj = {
      //projectId : projectOrCr.id,
      projectId: projectOrCr.ProjectId ? projectOrCr.ProjectId : projectOrCr.id,
      crId: projectOrCr.ProjectId ? projectOrCr.id : null,
      projStartDate : startDate,
      projStatus : status,
      fileIds: fileUploadIds,
      comment: statusComment,
      changedDate: changedDate,
      IsWorkflowExist: false,
      isCr : projectOrCr.ProjectId ? true : false,
      wfId: null,
      createdBy: window.LOGGED_USER.displayName,
      activeOrWarStartDate: activeOrWarStartDate
    };

    await this.populateSortedActivityLogData(statusChangeObj.projectId,statusChangeObj.crId,false);

    if(this.state.sortedActivity && this.state.sortedActivity.length === 1){
      this.setState(
          {
            prevState: null,
          })
    }
    else if(this.state.sortedActivity && this.state.sortedActivity.length === 2){
      this.setState(
          {
            prevState: this.state.sortedActivity[1],
          })
    }

    let startDateWF = this.state.startdateStatus ? (moment(this.state.startdateStatus).format('YYYY-MM-DD')) : (moment(new Date()).format('YYYY-MM-DD'));

    //pick WF for status change
    selectWorkflowStatus(projectOrCr.CMSheets[0] ? projectOrCr.CMSheets[0].id:0,{ EstimatedStartDate: startDateWF, SelectedCurrency: projectOrCr.Currency, PrevStatus: this.state.prevState ? parseInt(this.state.prevState.Status) : 0, CurrentStatus: this.CROrProjectCurrentStatusId,EndStatus: statusChangeObj.projStatus,projectOrCr:projectOrCr}).then(res => {
      if (res.data.length > 1) {

        statusChangeObj.IsWorkflowExist = true;
        statusChangeObj.ApprovalStatus = ApprovalStatusChange.PENDING.value;

        this.setState(
            {
              matchingWorkflowList: res.data,
              cmsId : projectOrCr.CMSheets[0] ? projectOrCr.CMSheets[0].id : null,
              statusChangeObj:statusChangeObj,
              isMoreWorkflow:true
            },
            () => {
              this.toggleWorkflowSelectStatusDialog();
            }
        );
      } else if (res.data.length === 1) {
        this.setState({
          statusChangeObj: statusChangeObj
        })

        statusChangeObj.IsWorkflowExist = true;
        statusChangeObj.ApprovalStatus = ApprovalStatusChange.PENDING.value;
        statusChangeObj.IsOneWorkflow = true;
        statusChangeObj.wfId = res.data[0].id;

        this.saveActivityLogWithStatus(statusChangeObj,projectOrCr,res.data[0].id);
      }
      else {
        this.saveActivityLogWithStatus(statusChangeObj,projectOrCr,null);
      }
    });

    //save on audit trail
    this.onSaveAuditTrail(statusChangeObj, statusName, projectOrCr.UniqueId);
  };

  toggleWorkflowSelectStatusDialog = () => {
    if (this.isMount) {
      this.setState({
        selectedCRId: null,
        IsPoolProject: false,
        loading:false,
        showWorkflowSelectDialog: !this.state.showWorkflowSelectDialog,
      });
    }
  };

  saveActivityLogWithStatus = async (statusChangeObj,projectOrCr,selectedWorkFlowId) => {
    this.setState({
      loading: true
    });

    await saveStatusChangeHistory(statusChangeObj).then(response => {
      this.setState({
            showSuccessImg: true,
            activityLogId:response.data.id
          },
          () => {
            if(statusChangeObj.IsWorkflowExist === false){
              this.setState({
                loading: false
              });
              this.toggleMessageDialog(`Status changed successfully`, 'Success');
              this.toggleChangeStatusDialog();
            }

            if(statusChangeObj.IsOneWorkflow === true){
              this.setState(
                  {
                    selectedWorkFlowId: selectedWorkFlowId
                  },
                  () => {
                    this.setWorkflowToStatus(projectOrCr.CMSheets[0] ? projectOrCr.CMSheets[0].id : null,statusChangeObj);
                  }
              );
            }
          }
      );
    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleMessageDialog(error.response.data, 'Error');
    });
  }

  setWorkflowToStatus = async (cmSheetId,statusChangeObj) => {
    this.setState({
      loading: true
    });

    if(this.state.selectedWorkFlowId !== null){
      var statusChangeObj = this.state.statusChangeObj;
      statusChangeObj.wfId = this.state.selectedWorkFlowId;

      if(this.state.isMoreWorkflow === true) {
        await this.saveActivityLogWithStatus(statusChangeObj,this.state.projectOrCr,null);
      }
      await setWorkflowStatus({ cmSheetId: cmSheetId, workflowId: this.state.selectedWorkFlowId ,statusChangeObj:statusChangeObj,activityLogId : this.state.activityLogId})
          .then(res => {
            if(this.state.isMoreWorkflow === true){
              this.toggleWorkflowSelectStatusDialog();
            }
            this.toggleMessageDialog(`Status Change submitted for Approval`, 'Success');
            this.toggleChangeStatusDialog();

            this.setState({ selectedWorkFlowId: null });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            if(this.state.isMoreWorkflow === true){
              this.toggleWorkflowSelectStatusDialog();
            }
            const message = error.response.data;
            const title = 'Error';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
            this.toggleMessageDialog(message, title);
          });

      this.setState({
        loading: false
      });
    }
  };

  onSaveAuditTrail = (statusChangeObj, statusName, uniqueId) => {
    this.setState({
      loading: true
    });

    let userName = window.LOGGED_USER.displayName;

    //project
    if(statusChangeObj.isCr === false){
      const auditTrailObj = {
        Action: 'Project status changed to ' + statusName,
        RefType: 'Project' ,
        RefId: statusChangeObj.projectId,
        RefName: uniqueId
      }
      saveAuditTrail(auditTrailObj);
    }
    //cr
    else{
      const auditTrailObj = {
        Action: 'CR status changed to ' + statusName,
        RefType: 'Change Request' ,
        RefId: statusChangeObj.crId,
        RefName: uniqueId
      }
      saveAuditTrail(auditTrailObj);
    }
  };

  // onSubmitForApproval = async () => {
  //           this.toggleConfirmDialog(
  //               'Are you sure you want to submit for approval?',
  //               this.submitForApproval
  //           );
  // };



  populateActivityLogData = async (projectOrCr, resourceId) => {
    await getActivityLogData(resourceId).then(response => {
      let ProjActivityLogLst = [];
      let CrActivityLogLst = [];
      let changedDate = '';
      let selectedCrActivityLogLst = [];

      this.setState({
        allActivityLogList: response.data
      });

      //activityLog status name
      for(let activityLogItem of this.state.allActivityLogList){
        for(let status in ProjStatuses){
          if(ProjStatuses[status].value == activityLogItem.Status){
            activityLogItem.Status = ProjStatuses[status].name;
          }
        }
      }

      for(let el of this.state.allActivityLogList){
        changedDate = moment(el.changedDate).format('YYYY-MM-DD') ;
        el.changedDate = changedDate;
      }

      for(let el of this.state.allActivityLogList){
        if(el.CrId === null){
          ProjActivityLogLst.push(el);
        }
        else{
          CrActivityLogLst.push(el);
        }
      }

      if(projectOrCr.ProjectId){
        for(let el of CrActivityLogLst){
          if(el.CrId === projectOrCr.id){
            selectedCrActivityLogLst.push(el);
          }
        }
        this.setState({
          activityLogList: selectedCrActivityLogLst
        });
      }
      else{
        this.setState({
          activityLogList: ProjActivityLogLst
        });
      }


      let maxId = this.state.activityLogList.length > 0 ? (Math.max(...this.state.activityLogList.map(el => el.id))) : 0;
      let lastRecord = this.state.activityLogList.filter(el => el.id == maxId);

      if(lastRecord && lastRecord.length > 0){
        if(lastRecord[0].ApprovalStatus && lastRecord[0].ApprovalStatus === 'PENDING'){
          this.setState({
            isApprovalStatusPending: true,
            reqStatus: lastRecord[0].Status
          });

          let ProjOrCrId = lastRecord[0].CrId !== null ? lastRecord[0].CrId : lastRecord[0].ProjectId;
          let workflowId = lastRecord[0].WorkflowId;
          let type = lastRecord[0].CrId !== null ? 'ChangeRequest' : 'Project';

          const approvalObj = {
            ProjectId: ProjOrCrId,
            WorkflowId: workflowId,
            Type: type
          };

          this.populateApprovalData(approvalObj);
        }
      }

      let statusChangeDetails = [];
      let activityLogList = this.state.activityLogList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

      for (const item of activityLogList) {
        if (item.ApprovalStatus === 'PENDING') {
          let activityLogObj = {
            CrId: item.CrId,
            ProjectId: item.ProjectId,
            FileUpload: item.FileUpload,
            Status: item.Status,
            DateTime: moment(item.createdAt).format("MM-DD-YYYY hh:mm:ss A"),
            changedBy: item.changedBy,
            Approver: item.changedBy,
            ApprovalStatus: '',
            Comment:item.Comment,
          }
          statusChangeDetails.push(activityLogObj);
          if (item.Approvals && item.Approvals.length > 0) {
            let Approvals = item.Approvals.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            for (const approval of Approvals) {
              let approvalObj = {
                CrId: item.CrId,
                ProjectId: item.ProjectId,
                FileUpload: [],
                Status: item.Status,
                DateTime: approval.Date ? moment(approval.Date).format("MM-DD-YYYY hh:mm:ss A") : "",
                changedBy: item.changedBy,
                Approver: approval.Resource.Name,
                ApprovalStatus: approval.Status,
                Comment:approval.Comment,
              }
              statusChangeDetails.push(approvalObj);
            }
          }
        } else if (item.WorkflowId == null){
          let activityLogObj = {
            CrId: item.CrId,
            ProjectId: item.ProjectId,
            FileUpload: item.FileUpload,
            Status: item.Status,
            DateTime: moment(item.createdAt).format("MM-DD-YYYY hh:mm:ss A"),
            changedBy: item.changedBy,
            Approver: item.changedBy,
            ApprovalStatus: '',
            Comment:item.Comment,
          }
          statusChangeDetails.push(activityLogObj);
        }
      }

      this.setState({  
        statusChangeGridDetails: statusChangeDetails,
      });

    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  populateApprovalData = async (approvalObj) => {
    await getApprovalsData(approvalObj).then(response => {
      this.setState({
        approvalsDetails: response.data
      });

      if(this.state.approvalsDetails && this.state.approvalsDetails.length > 0){
        let approvalsDetails = this.state.approvalsDetails;
        let approvers = [];
        
        approvalsDetails.forEach(el => {approvers.push(el.Resource.Name);})

        if(approvers.length > 1){
          var approversSet = "";
          var i = 1;

          for(let approver of approvers){
            if(i >= approvers.length){
              approversSet += approver + '';
            }
            else{
              approversSet += approver + ', ';
            }
            i++;
          }

          this.setState({
            approversName: approversSet
          });
        }
        else{
          this.setState({
            approversName: approvers
          });
        }
      }
      
    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  //redirect to Home
  reDirectToHome = event => {
    this.setState({
      toHomePage: true
    });
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validateCommentProperty = value => {
    if (value && value.length > 0 && value != "<p><br></p>" ) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  subscribe = async(id) =>{
    if(this.state.keyId !== 10)
    {
      await this.setState({
        keyId:id
      });
      if(this.state.keyId === 10)
      {        
        this.resetGridState();
        if (window.USER_PERMISSION && window.USER_PERMISSION.length > 0) {
          await this.setState({
            initLoading:true
          });
          this.populatePendingTasks();
          this.populateactiveProjects();
        }
    
        // this.retrieveRecentActiveProjects(10);
        this.populateStatuses();
        this.populateCustomers();
        this.populateDivisions();
        this.populateRegions();
        this.populateProjectTypes();
        this.populatePaymentMethods();
        this.populateProjectIds();
        this.populateOPIDs();
        this.populatePMResources();
        this.populateCMSStatus();
        this.populateCRIds();
        this.populateCMSTypes();
        this.populateLegalEntityTypess();
      }        
    }
}

  componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    this.setHeaderTitle();       
    
    $(document).ready(function () {
      $("div.title-remove > span > span > span:last-child").removeAttr("title");
    });

    
    this.subscribe(10);  
  }

  componentWillUnmount() {
    this.isMount = false;
    this.unsubscribe(10);
  }

  
  unsubscribe = (id) =>{
    this.setState({
      keyId:id
      });
  }


  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Dashboard');
    }
  };

  resetGridState = () => {
    this.setState({
      skip: 0,
      TotalCount:0
    })
  };

  populateactiveProjects = async () => {
    const statusObj = {
      selectedStatuses: this.state.selectedStatuses        
    }
    await getActiveProjectsPerformanceFix(statusObj).then(async response => {
      if (this.isMount) {
        if(response.data)
        {
          let projectIds = [];
          projectIds = response.data.map(a=>a.id);        
          const SRequest = {
            projectIds: projectIds      
          }
          await getActiveProjectsCRPerformanceFix(SRequest).then(async res=>
          {
              for(const proj of response.data){
                const filteredProject = res.data.find(pro => pro.id === proj.id);
                if(filteredProject){
                  const index = response.data.findIndex(a=>a.id=== proj.id);
                  response.data[index].ChangeRequests = filteredProject.ChangeRequests;
                }
              }
              await this.setState({  
                projectItems: response.data
              },
              async () => {
                let comp = this;
                var projectItemObj = response.data.map(function(project) {
                  var info = {
                    id: project.id,
                    title: project.ProjectName+ (project.Customer ? (" | "+ (project.Customer !== null ? project.Customer.Name : null) +" | "+ (project.UniqueId !== null ? project.UniqueId : null)) : ''),
                    children: [
                      { id: 1, title: 'Project Functions', content: comp.projFunct(project) },
                      { id: 2, title: 'CM Sheet Functions', content: comp.cmsheetFunc(project) },
                      { id: 3, title: 'Change Request Functions', content: comp.changeRequestFunc(project) },
                      { id: 4, title: 'Resource Request Functions', content: comp.resourceRequestFunc(project) },
                      { id: 5, title: 'Task Management Functions', content: comp.taskManagementFunc(project) }
                      
                    ]
                  };
                  return info;
                });
    
                this.setState({
                  activeProjectItems: projectItemObj,
                  data: projectItemObj,
                  initialAllData: this.state.initLoading ? projectItemObj : this.state.initialAllData,
                  initLoading: false
                });
              }
            );                
          });
        } 
      }
    })
    .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  
  };

  handleChangeStatusFilter = async(event) => {
    if (event && event.target && event.target.value) {
      await this.setState({ 
        [event.target.name]: event.target.value, 
      });
    } else {
      await this.setState({ 
        [event.target.name]: []
      });
    }
  }

  submitSearch = async() => {
    await this.setState({
      loading: true
    });
    await this.populateactiveProjects();
    await this.setState({
      loading: false
    });
  }

  cancelSearch = async() => {
    await this.setState({
      loading: true,
      selectedStatuses: []
    });
    await this.populateactiveProjects();
    await this.setState({
      loading: false
    });
  }

  filterChangeStatusFilter = async(event) => {
    await this.setState({
      statusesForFilter: this.filterDataStatusFilter(event.filter)
    });
  };

  filterDataStatusFilter(filter) {
    const data = this.state.statusesForFilterAll.slice();
    return filterBy(data, filter);
  }

  populatePendingTasks = async () => {
    let tasks = [];
    let totalRecords = 0;
    let sortBy = '';
    let sortDir = '';
    if(this.state.sort && this.state.sort.length > 0)
    {
      sortBy = this.state.sort[0].field;
      sortDir = this.state.sort[0].dir;
    }
    let projTasks = [];
      this.setState({
          loading: true
      });
    await getPendingTasksPerformanceFix(this.state.skip, this.state.take, sortBy,sortDir ).then( async response =>{
      if (response.data && response.data.allPendingTask && response.data.allPendingTask.length>1) {
        let projectApprovals = response.data.allPendingTask[0].filter(a=>a.Type !== "TIMESHEET_UNFREEZE_APPROVAL");
        let ids = [];
        if(projectApprovals && projectApprovals.length > 0) {
          ids = projectApprovals.map(a=>a.id);
        }

        let unfreezeApprovals = response.data.allPendingTask[0].filter(a=>a.Type === "TIMESHEET_UNFREEZE_APPROVAL");
        let unfreezeIds =[];
        if(unfreezeApprovals && unfreezeApprovals.length > 0)
        {
          unfreezeIds = unfreezeApprovals.map(a=>a.id);
        }        
        const pendingTaskObj = {
          projectIds: ids,
          unfreezeIds: unfreezeIds
        }
        let pendingTaskProjectDataList;
        let pendingUnfreezeApprovals;
        await getPendingResourceRequestTasks(pendingTaskObj).then(res => {
          pendingTaskProjectDataList = res.data.ProjectDataList;
          pendingUnfreezeApprovals = res.data.UnfreezeApprovals; 
        });

        totalRecords = response.data.totalRows;
        for(const pendingTask of response.data.allPendingTask[0]) {
          let pendingTaskProjectData;
          let reqStatus = null;
          let currentStatus = null;
          let reqStatusId = null;
          let currentStatusId = null;
          let pendingUnfreezeRequest;

          if(pendingTaskProjectDataList && pendingTaskProjectDataList.length > 0)
          {
            pendingTaskProjectData = pendingTaskProjectDataList.find(a=>a.id === pendingTask.id);
          }
          if(pendingUnfreezeApprovals && pendingUnfreezeApprovals.length > 0)
          {
            pendingUnfreezeRequest = pendingUnfreezeApprovals.find(a=>a.id === pendingTask.id);
          }


          if(pendingTask.Type === 'STATUS_CHANGE_APPROVAL') {

            if(pendingTask.CMSheetType ==='Project') {
              currentStatusId = pendingTaskProjectData.Status;

              if(pendingTaskProjectData.ActivityLogs && pendingTaskProjectData.ActivityLogs.length > 0){
                let activityLogs = pendingTaskProjectData.ActivityLogs;
                let records = [];

                activityLogs.forEach(element => {
                  if(element.ApprovalStatus && element.ApprovalStatus == 'PENDING'){
                    records.push(element);
                  }
                });
                if(records && records.length > 0){
                  let lastRecord = records.filter(el => el.id === Math.max(...records.map(el => el.id)));
                  reqStatusId = parseInt(lastRecord[0].Status);
                }
              }

              if(this.state.Statuses.length === 0){
                await this.populateStatuses();
              }

              if(this.state.Statuses && this.state.Statuses.length > 0){
                currentStatus = this.state.Statuses.filter(el => el.id === currentStatusId).length > 0 ? this.state.Statuses.filter(el => el.id === currentStatusId)[0].StatusName : null;
                reqStatus = this.state.Statuses.filter(el => el.id === reqStatusId).length > 0 ? this.state.Statuses.filter(el => el.id === reqStatusId)[0].StatusName : null;
              }
            }

            if(pendingTask.CMSheetType ==='ChangeRequest'){
              let cr = pendingTaskProjectData.ChangeRequests;
              currentStatusId = pendingTaskProjectData.Status;

              if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
                let activityLogs = cr.ActivityLogs;
                let records = [];

                activityLogs.forEach(element => {
                  if(element.ApprovalStatus && element.ApprovalStatus == 'PENDING'){
                    records.push(element);
                  }
                });

                if(records && records.length > 0){
                  let lastRecord = records.filter(el => el.id === Math.max(...records.map(el => el.id)));
                  reqStatusId = parseInt(lastRecord[0].Status);
                }
              }

              if(this.state.Statuses.length === 0){
                this.populateStatuses();
              }

              if(this.state.Statuses && this.state.Statuses.length > 0){
                currentStatus = this.state.Statuses.filter(el => el.id === currentStatusId).length > 0 ? this.state.Statuses.filter(el => el.id === currentStatusId)[0].StatusName : null;
                reqStatus = this.state.Statuses.filter(el => el.id === reqStatusId).length > 0 ? this.state.Statuses.filter(el => el.id === reqStatusId)[0].StatusName : null;
              }
            }
          }
          let Project = {
            id:pendingTask.id,
            UniqueId:pendingTaskProjectData ? pendingTaskProjectData.UniqueId : null,
            StartDate:pendingTaskProjectData ? pendingTaskProjectData.StartDate : null,
            ProjectName: pendingTaskProjectData ? pendingTaskProjectData.ProjectName : null,
            EndDate:pendingTaskProjectData ? pendingTaskProjectData.EndDate : null,
            OPID: pendingTaskProjectData ? pendingTaskProjectData.OPID : null,
            ChangeRequests:pendingTaskProjectData ? pendingTaskProjectData.ChangeRequests:null,
            Region:pendingTaskProjectData ? pendingTaskProjectData.Region:null,
            Division:pendingTaskProjectData ? pendingTaskProjectData.Division:null,
            Customer:pendingTaskProjectData ? pendingTaskProjectData.Customer:null,
            Country:pendingTaskProjectData ? pendingTaskProjectData.Country:null,
            PaymentMethodRecord:pendingTaskProjectData ? pendingTaskProjectData.PaymentMethodRecord:null,
            Offering:pendingTaskProjectData ? pendingTaskProjectData.Offering:null,
            Type:pendingTaskProjectData ? pendingTaskProjectData.Type:null,
            CMSheets:pendingTaskProjectData ? pendingTaskProjectData.CMSheets:null,
            StatusConvertedToActive: pendingTaskProjectData ? pendingTaskProjectData.StatusConvertedToActive : null,
            CurrentStatus : currentStatus,
            ReqStatus: reqStatus,
            UserDefinedEndDate:pendingTaskProjectData ? pendingTaskProjectData.UserDefinedEndDate :null,
            ConvertedToActiveAt:pendingTaskProjectData ? pendingTaskProjectData.ConvertedToActiveAt :null,
            CountryId:pendingTaskProjectData ? pendingTaskProjectData.CountryId :null,
            EstimatedEndDate:pendingTaskProjectData ? pendingTaskProjectData.EstimatedEndDate :null,
            Status: pendingTaskProjectData ? pendingTaskProjectData.Status : null,
          };
          let CMSheet ;
          if(pendingTaskProjectData && pendingTaskProjectData.CMSheets)
          {
            if(pendingTask.CMSheetType ==='ChangeRequest')
            {
              let changeRequest = pendingTaskProjectData.ChangeRequests.filter(a=>a.UniqueId === pendingTask['ChangeRequests.UniqueId']);
              CMSheet = {
                CMSheets:pendingTaskProjectData.CMSheets && pendingTaskProjectData.CMSheets.length > 0 ? pendingTaskProjectData.CMSheets: pendingTaskProjectData.ChangeRequests[0].CMSheets[0],
                Type : pendingTask.CMSheetType,
                Project : Project,
                ChangeRequest : changeRequest[0],
                ChangeRequestUniqueId:pendingTask['ChangeRequests.UniqueId']
              }
            }
            else if(pendingTask.CMSheetType ==='Project')
            {
              pendingTaskProjectData.CMSheets.Type = pendingTask.CMSheetType
              pendingTaskProjectData.CMSheets.Project = Project;
            }

          }

          let pendingTaskObj = {
              Status: pendingTask.Status,
              id: pendingTask.id,
              ApproverId: pendingTask.ApproverId,
              Hours:pendingTask.Hours,
              CMSheetId: pendingTask.CMSheetId,
              CMSheetVersion: pendingTask.CMSheetVersion,
              ResourceRequestId: null,
              CardName: pendingTask.CardName,
              CardGroup: pendingTask.CardGroup,
              CardSequence: pendingTask.CardSequence,
              ApproverResourceId: pendingTask.ApproverResourceId,
              ApproverSequence: pendingTask.ApproverSequence,
              FutureApprover: pendingTask.FutureApprover,
              Date: pendingTask.Date,
              Comment: pendingTask.Comment,
              IsActive: pendingTask.IsActive,
              createdAt: pendingTask.createdAt,
              updatedAt: pendingTask.updatedAt,
              Resource: pendingTask.Resource,
              CMSheet: (pendingTask.CMSheetType ==='ChangeRequest')?CMSheet: pendingTaskProjectData ? pendingTaskProjectData.CMSheets:null,
              Project: Project,
              ChangeRequest: pendingTaskProjectData ? pendingTaskProjectData.ChangeRequests:null,
              Type: pendingTask.Type,
              StatusConvertedToActive: pendingTaskProjectData ? pendingTaskProjectData.StatusConvertedToActive :null,
              CurrentStatus : currentStatus,
              ReqStatus: reqStatus,
              UnFreezeRequests: pendingUnfreezeRequest,
              UserDefinedEndDate:pendingTaskProjectData ? pendingTaskProjectData.UserDefinedEndDate :null,
              ConvertedToActiveAt:pendingTaskProjectData ? pendingTaskProjectData.ConvertedToActiveAt :null,
              CountryId:pendingTaskProjectData ? pendingTaskProjectData.CountryId :null,
              EstimatedEndDate:pendingTaskProjectData ? pendingTaskProjectData.EstimatedEndDate :null,
              updatedAtOrderBy: pendingTask.updatedAtOrderBy,
              InvoiceUniqueId: pendingTask['ChangeRequests.UniqueId'] ? pendingTask['ChangeRequests.UniqueId'] : pendingTask.UniqueId
         }
         tasks.push(pendingTaskObj);
      }
      }
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
    if (this.isMount) {
      await this.setState({
        pendingTasks: tasks,
        TotalCount:totalRecords,
        loading: false
      });
    }

  };


  populateSortedActivityLogData = async (pendingTaskObj,projectId,crId) => {
    this.setState({
      loading: true
    });
    
    let ids = [];
    ids  = {
      projectId : projectId,
      crId: crId
    }

    await getActivityLogDataSort(ids).then(response => {


      pendingTaskObj.sortedActivity = response.data

    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  retrieveRecentActiveProjects = count => {
    getRecentActiveProjects(count)
      .then(res => {
        if (this.isMount) {
          this.setState({
            Projects: res.data.rows
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateStatuses = () => {
    getProjectStatuses()
      .then(res => {
        if (this.isMount) {
          const validStatuses = ['PRESALES', 'WAIT_CONFIRM', 'WORK_AT_RISK', 'BILLABLE']
          const statusesForFilter = res.data.filter(obj => validStatuses.includes(obj.Code));
          this.setState({
            Statuses: res.data,
            statusesForFilter: statusesForFilter,
            statusesForFilterAll: statusesForFilter
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCustomers = () => {
    getcustomersIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            Customers: res.data,
            CustomersAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateDivisions = () => {
    getBillingDiviIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            Divisions: res.data,
            DivisionsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateRegions = () => {
    getRegionsIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            Regions: res.data,
            RegionsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateProjectTypes = () => {
    getProjectTypes()
      .then(res => {
        if (this.isMount) {
          this.setState({
            ProjectTypes: res.data,
            ProjectTypesAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populatePaymentMethods = () => {
    getPaymentMethods()
      .then(res => {
        if (this.isMount) {
          this.setState({
            PaymentMethods: res.data,
            PaymentMethodsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateProjectIds = () => {
    getAllUniqueIds()
      .then(res => {
        if (this.isMount) {
          this.setState({
            UniqueIds: res.data,
            UniqueIdsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateOPIDs = () => {
    getAllOPIDs()
      .then(res => {
        if (this.isMount) {
          this.setState({
            opids: res.data,
            opidsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populatePMResources = async () => {
    let resources = [];

    await getPMResources().then(res => {

      this.setState({
        resourceItemsPro: res.data
      });

      for(const obj of res.data){
        resources.push(obj)
      }

    })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

    await getProjectOwners().then(response => {
      let resourceAll = [];

      this.resourceAllPool = response.data;
      this.setState({
        resourceItemsPool: response.data
      });

      for(const obj of response.data){
        let resource = {
          id:obj.id,
          Name:obj.displayName
        }
        resourceAll = resources.filter(obj => obj.id  === resource.id);
        if(resourceAll && resourceAll.length === 0){
          resources.push(resource)
        }
      }
    })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

    this.resourceAll = resources

    await this.setState({
      resourceItems: resources
    });
  };

  populateCMSStatus = () => {
    getCMSStatus()
      .then(res => {
        if (this.isMount) {
          this.CMSStatusAll = res.data;
          this.setState({
            CMSStatus: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCRIds = () => {
    getAllCRUniqueIds()
      .then(res => {
        if (this.isMount) {
          this.setState({
            UniqueCRIds: res.data,
            UniqueCRIdsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCMSTypes = () => {
    getCmsTypes().then(res => {
      this.setState({
        cmsTypes: res.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateLegalEntityTypess = () => {
    getLegalEntityTypes().then(response => {
      this.allBillingEntities = response.data;
      this.setState({
        billingEntityType: response.data
      });
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  toggleErrorMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
          showErrorMessageDialog: !this.state.showErrorMessageDialog,
          dialogMessage: message,
          dialogTitle: title
      });
    }
  };

  toggleMessageDialog = (message, title) => {
      if (this.isMount) {
          this.setState({
              showMessageDialog: !this.state.showMessageDialog,
              dialogMessage: message,
              dialogTitle: title
          });
      }
  };

  toggleConfirmDialog = async(message, action) => {
    if (this.isMount) {
      await this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
      if (this.state.showConfirmDialog == false) {
        await this.setState({
          selectedChangeToStatus: null,
        });
      }
    }
  };

  toggleCMSWithdrawDialog = (message, action) => {
    this.setState({
        showCMSWithdrawDialog: !this.state.showCMSWithdrawDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action,
        isErrorMessageVisible: false
    });
  };

  toggleRequestReviseCMSDialog = (message, action) => {
    this.setState({
        showRequestReviseCMSDialog: !this.state.showRequestReviseCMSDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action,
        requestReviseCMSReason: null,
        isErrorMessageVisible: false
    });
  };

  handleReviseCMSReasonInputChange = event => {
    const value = event.target.value;
    this.setState({
      requestReviseCMSReason: value
    });
  };

  handleWithdrawReasonInputChange = event => {
    const value = event.target.value;
    this.setState({
      withdrawReason: value
    });
  };

  pageChange = async event => {
    //Sever side call will be go here
    if (this.isMount) {
      await this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
      await this.populatePendingTasks();
    }
  };
  shortChange = async event => {
    //Sever side call will be go here
    if (this.isMount) {
      await this.setState({
        skip: 0,
        take: 6,
        sort: event.sort && event.sort.length > 0 ?event.sort:[{ field: 'updatedAtOrderBy', dir: 'DESC' }
        ],
      });
      await this.populatePendingTasks();
    }
  };


  projFunct = project => {
    return <ProjectFunctions dataItem={project} onDeleteProject={this.deleteProjectById} handleChangeStatusPopUp={this.handleChangeStatusPopUp}/>;
  };

  resourceRequestFunc = project => {
      return <ResourceRequestFunctions dataItem={project}/>
  }

  taskManagementFunc = project => {
    return <TaskManagementFunctions dataItem={project}/>
  }

  editOrViewChangeReq = async (event, project, action, isProjectManager, hasEditCRPermission, crListOfCRManager, crWithNewPMs, crWithExistingPMs) => {
    let presalesChangeReq = null;

    if (action === 'view') {
      await getCRsOfAProjectIntegrated(project.id).then(res => {
        if (res.data.length > 0) {
          presalesChangeReq = res.data.filter(obj => (obj.Status.Code === 'PRESALES' || obj.Status.Code === 'WAIT_CONFIRM' ||
            obj.Status.Code === 'BILLABLE' || obj.Status.Code === 'ON_HOLD' || obj.Status.Code === 'PROJECT_ON_HOLD' ||
            obj.Status.Code === 'DEAL_ON_HOLD' || obj.Status.Code === 'WORK_AT_RISK') && (obj.IsActive === true));
          if (this.isMount) {
            this.setState({
              changeRequests: presalesChangeReq,
              ChangeRequestAction: action,
              IsPoolProject: res.data[0].IsPoolProject,
              action: action
            },
              () => {
                if (presalesChangeReq.length === 1) {
                  if (this.isMount) {
                    this.setState({
                      selectedCRId: presalesChangeReq[0].id,
                      redirectToViewCR: true
                    });
                    this.onEditCRClick();
                  }
                } else {
                  this.toggleCRSelectDialog();
                }
              });
          }
        } else {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleMessageDialog('No CRs found', 'Error');
        }
      })
      .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    } else {
      await getCRsOfAProjectIntegrated(project.id).then(res => {
        const projectManager = isProjectManager;
        const editCRPermission = hasEditCRPermission;
        const changeRequestsAsCRManager = crListOfCRManager;

        let presaleCRs = null;
        let changeReqWithoutPendingCMSApprovals = [];
        let changeReqWithoutPendingStatusApproval = [];

        if (projectManager === true || (editCRPermission && editCRPermission.length > 0)) {
          let crs = res.data;

          if(crs && crs.length > 0){
            for(let cr of crs){
              if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
                cr.ActivityLogs.reverse(); // get the descending order
                if(cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING'){
                  continue;
                }
                else{
                  changeReqWithoutPendingStatusApproval.push(cr);
                }
              }
            }
          }
          
          presaleCRs = changeReqWithoutPendingStatusApproval.filter(obj => (obj.Status.Code === 'PRESALES' || obj.Status.Code === 'WAIT_CONFIRM' ||
            obj.Status.Code === 'BILLABLE' || obj.Status.Code === 'ON_HOLD' || obj.Status.Code === 'PROJECT_ON_HOLD' ||
            obj.Status.Code === 'DEAL_ON_HOLD' || obj.Status.Code === 'WORK_AT_RISK') && obj.IsActive === true);

            let changeRequests = []
            if (presaleCRs && presaleCRs.length>0) {
                for (const cr of presaleCRs) {
                    // if (cr && cr.ResourceRequestStatus && cr.ResourceRequestStatus === 'PM_ALLOCATED') {
                    //     if (cr.AllocatedPM && cr.AllocatedPM.length>0) {
                    //         for(const allocatedPM of cr.AllocatedPM){
                    //             if (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
                    //                 changeRequests.push(cr)
                    //             }
                    //         }
                    //     }
                    // } else {
                    //     if (window.LOGGED_USER.userId.toUpperCase() === cr.PresalesResource.Email.toUpperCase() && cr.IsActive === true) {
                    //         changeRequests.push(cr)
                    //     }

                    // }
                
                    if (cr.AllocatedPM.length > 0) {
                      //valid managers
                      const crProjectManagers = cr.AllocatedPM;
                
                      //find valid pms from end date
                      let validManagers = [];
                      validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())));
                
                      if (validManagers.length === 0 && crProjectManagers.length > 0) {
                        crProjectManagers.sort(function (a, b) {
                          return new Date(b.EndDate) - new Date(a.EndDate)
                        })
                        const lastAssignedManager = crProjectManagers[0];
                        validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate));
                
                      }
                
                      validManagers.forEach(value => {
                        if (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()) {
                          changeRequests.push(cr)
                        }
                      });
                    }

                }
            }

          presaleCRs.forEach(function(changeReq) {
            if (changeReq && changeReq.CMSheets && changeReq.CMSheets.length > 0 && changeReq.CMSheets[0].Status !== 'PENDING_APPROVALS') {
              changeReqWithoutPendingCMSApprovals.push(changeReq);
            } else if (changeReq && changeReq.CMSheets && changeReq.CMSheets.length === 0) {
              changeReqWithoutPendingCMSApprovals.push(changeReq);
            }
          });
        } else {
            let changeRequests = []
            if (project.ChangeRequests && project.ChangeRequests.length>0) {
                for (const cr of project.ChangeRequests) {
                    // if (cr && cr.ResourceRequestStatus && cr.ResourceRequestStatus === 'PM_ALLOCATED') {
                    //     if (cr.AllocatedPM && cr.AllocatedPM.length>0) {
                    //         for(const allocatedPM of cr.AllocatedPM){
                    //             if (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
                    //                 changeRequests.push(cr)
                    //             }
                    //         }
                    //     }
                    // } else {
                    //     if (window.LOGGED_USER.userId.toUpperCase() === cr.PresalesResource.Email.toUpperCase() && cr.IsActive === true) {
                    //         changeRequests.push(cr)
                    //     }
                    // }
                  if (cr.AllocatedPM.length > 0) {
                    //valid managers
                    const crProjectManagers = cr.AllocatedPM;
              
                    //find valid pms from end date
                    let validManagers = [];
                    validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())));
              
                    if (validManagers.length === 0 && crProjectManagers.length > 0) {
                      crProjectManagers.sort(function (a, b) {
                        return new Date(b.EndDate) - new Date(a.EndDate)
                      })
                      const lastAssignedManager = crProjectManagers[0];
                      validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate));
              
                    }
              
                    validManagers.forEach(value => {
                      if (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()) {
                        changeRequests.push(cr)
                      }
                    });
                  }
                }
            }

            if(changeRequests && changeRequests.length > 0){
              for(let cr of changeRequests){
                if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
                  if(cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING'){
                    continue;
                  }
                  else{
                    changeReqWithoutPendingStatusApproval.push(cr);
                  }
                }
              }
            }

          presaleCRs = changeReqWithoutPendingStatusApproval.filter(obj => (obj.Status.Code === 'PRESALES' || obj.Status.Code === 'WAIT_CONFIRM' ||
            obj.Status.Code === 'BILLABLE' || obj.Status.Code === 'ON_HOLD' || obj.Status.Code === 'WORK_AT_RISK') &&
            // window.LOGGED_USER.userId.toUpperCase() === obj.PresalesResource.Email.toUpperCase() &&
            obj.IsActive === true);

          presaleCRs.forEach(function(changeReq) {
            if (changeReq && changeReq.CMSheets && changeReq.CMSheets.length > 0 && changeReq.CMSheets[0].Status !== 'PENDING_APPROVALS') {
              changeReqWithoutPendingCMSApprovals.push(changeReq);
            } else if (changeReq && changeReq.CMSheets && changeReq.CMSheets.length === 0) {
              changeReqWithoutPendingCMSApprovals.push(changeReq);
            }
          });
        }

        if (res.data.length > 0) {
          if (this.isMount) {
            this.setState({
                changeRequests: changeReqWithoutPendingCMSApprovals,
                ChangeRequestAction: action
              },
              () => {
                  if (changeReqWithoutPendingCMSApprovals.length === 1) {
                      if (this.isMount) {
                        this.setState({
                            selectedCRId: changeReqWithoutPendingCMSApprovals[0].id,
                            IsPoolProject: changeReqWithoutPendingCMSApprovals[0].IsPoolProject,
                            redirectToEditCR: true
                        });
                        this.onEditCRClick();
                      }
                  } else if (changeReqWithoutPendingCMSApprovals.length > 1){
                      this.toggleCRSelectDialog();
                  }
              });
          }
        } else {
          if (this.isMount) {
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
          }
          this.toggleMessageDialog('No CRs found', 'Error');
        }
      })
      .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }
  };

    handleDeleteCR = async (event, project, action, isProjectManager, hasEditCRPermission, crListOfCRManager)=>{
        await getCRsOfAProjectIntegrated(project.id).then(res => {
            const presaleCRs = res.data.filter(obj => obj.Status.Code === 'PRESALES' && obj.CMSheets.length === 0 );
            if(res.data.length > 0){
                if (this.isMount) {
                    this.setState({
                        changeRequests: presaleCRs,
                        ChangeRequestAction: action,
                        action: action
                    },
                    () => {
                        if(presaleCRs.length === 1){
                            if (this.isMount) {
                                this.setState(
                                {
                                    selectedCRId: presaleCRs[0].id,
                                    CMSDialog : false
                                });
                            }
                            this.toggleConfirmDialog(
                                'Are you sure, you want to delete the CR?',
                                this.deleteCR
                            );
                        } else {
                            this.toggleCRSelectDialog()
                        }
                    })
                }
            }
            else {
                if (this.isMount) {
                    this.setState({
                        showSuccessImage: false,
                        showErrorImage: true
                    });
                }
                this.toggleMessageDialog('No CRs found', 'Error');
            }
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };


  handleStatusPopUpCR = async (event, Cr, action) => {
    await getCRsOfAProject(Cr.id).then(async res => {

      if (res.data.length > 0) {
        let presalesChangeReq = res.data.filter(obj => (obj.Status.Code === 'PRESALES' || obj.Status.Code === 'WAIT_CONFIRM' ||
            obj.Status.Code === 'BILLABLE' || obj.Status.Code === 'ON_HOLD' || obj.Status.Code === 'PROJECT_ON_HOLD' ||
            obj.Status.Code === 'DEAL_ON_HOLD' || obj.Status.Code === 'WORK_AT_RISK') && (obj.IsActive === true));
        if (this.isMount) {
          await this.setState({
            changeRequests: presalesChangeReq,
            ChangeRequestAction: action,
            statusChangeCr: Cr
          },
          async () => {
              if (res.data.length === 1) {
                await this.setState({
                  selectedCRId: res.data[0].id
                });
                this.onEditCRClick();
              } else {
                this.toggleCRSelectDialog();
              }
            });
        }
      } else {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog('No CRs found', 'Error');
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };


  setSelectedCRId = async (crId, IsPoolProject, cr) => {
    if (this.isMount) {
      this.setState({
        selectedCRId: crId,
        IsPoolProject: IsPoolProject,
        CMSCR: cr
      });
    }
  };

  onEditCRClick = async() => {

    if (this.state.ChangeRequestAction === 'CreateCMSforCR') {
      if (this.isMount) {
        this.setState({
          selectedCMSReference: 'crCMS'
        });
        //linking to pool project
        let poolProjectListAll = [];
        const data = {
          regionId : this.state.CMSCR.BillingRegion,
          practiceId : this.state.CMSCR.BillingDivisionId,
          offeringId : this.state.CMSCR.OfferingId
        };
        poolProjectListAll = await getFactoryProjByRegionAndPractice(data);
        if(poolProjectListAll.data && poolProjectListAll.data.length > 0){
          //pool project pop up
          this.poolProjectsAll = poolProjectListAll.data;
          await this.setState({
            poolProjectsAll: poolProjectListAll.data
          });
          this.toggleLinkProjToPoolDialog();
        }else{
          //create normal cr - direct to create cms page
          this.setState({
            redirectToCreateCMS: true,
          });
        }
      }
      return;
    }

      if (this.state.ChangeRequestAction === 'CreateCMSforCRPool') {
          if (this.isMount) {
              this.setState({
                  redirectToCreateCMSPool: true,
                  selectedCMSReference: 'crCMSPool'
              });
          }
          return;
      }

    if (this.state.ChangeRequestAction === 'delete') {
      deleteChangeReqIntegrated(this.state.selectedCRId).then(res => {
        if (this.isMount) {
          this.setState({
            selectedCRId: null,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleMessageDialog('Successfully deleted', 'Success');
        this.toggleCRSelectDialog();
        this.populateactiveProjects();
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleMessageDialog(error.response.data, 'Error');
      });

      return;
    }

    if (this.state.ChangeRequestAction === 'HandleCrPopUp') {

      // Close the CR selection popup
      if (this.isMount && this.state.showCRSelectDialog === true) {
        this.setState({
          showCRSelectDialog: !this.state.showCRSelectDialog
        });
      }

      // do the permission validation to open the dialog
      this.statusChangePermission(this.state.statusChangeCr, 'ChangeRequest');
      this.toggleChangeStatusDialog();

      return;
    }

    if (this.state.action === 'view') {
      if (this.isMount) {
        this.setState({
          redirectToViewCR: true
        });
      }
    } else {
      if (this.isMount) {
        this.setState({
          redirectToEditCR: true
        });
      }
    }

  };

    deleteCR = () => {
        deleteChangeReqIntegrated(this.state.selectedCRId)
            .then(res => {
                this.populateactiveProjects();

                if (this.isMount) {
                    this.setState({
                        selectedCRId: null,
                        showSuccessImage: true,
                        showErrorImage: false
                    });
                }

                this.toggleConfirmDialog('', null);
                this.toggleMessageDialog('Successfully deleted', 'Success');
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

  toggleCRSelectDialog = () => {
    if (this.isMount) {
      this.setState({
        showCRSelectDialog: !this.state.showCRSelectDialog,
        selectedCRId: null,
        IsPoolProject: false
      });
    }
  };

  cmsheetFunc = project => {
    return <CMSheetFunctions dataItem={project} onSelectCMS={this.onSelectCMS} onSelectCMSPool ={this.onSelectCMSPool} onCreateCMS={this.onCreateCMS}
                             onDeleteCMSheet={this.onClickDeleteCMSheet} onCreateCMSforCR={this.handleCreateCMSforCR}
                    onCreateCMSforCRPool = {this.handleCreateCMSforCRPool} onViewCMS={this.handleOnViewCMS} handleWithdrawCMS={this.handleWithdrawCMS} onViewCMSPool={this.handleOnViewCMSPool}/>;

  };

  changeRequestFunc = project => {
    return <ChangeRequestFunctions dataItem={project} onEditOrViewChangeReq={this.editOrViewChangeReq}
                                   onDeleteCR={this.handleDeleteCR} OnActivateResourceRequest={this.OnActivate} handleStatusPopUpCR={this.handleStatusPopUpCR}/>;
  };

  deleteProjectById = projectId => {
    if (this.isMount) {
      this.setState(
        {
          projectIdToDelete: projectId
        },
        () => {
          this.toggleConfirmDialog(
            'Are you sure, you want to delete the project?',
            this.deleteProject
          );
        }
      );
    }
  };

  deleteProject = () => {
      deleteProjectIntegrated(this.state.projectIdToDelete)
      .then(res => {
        this.populateactiveProjects();
        if (this.isMount) {
          this.setState({
            projectIdToDelete: null,
            showSuccessImage: true,
            showErrorImage: false
          });
        }

        this.toggleConfirmDialog('', null);
        this.toggleMessageDialog('Successfully deleted', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  onClickDeleteCMSheet = project => {
    let cmSheets = [];
    let crCMSheetdel = [];
    let projectCMSheetdel = [];
    if (project) {

        let pmOfProject = false;
        // if (project.ResourceRequestStatus === 'PM_ALLOCATED') {
        //     if (project.AllocatedPM && project.AllocatedPM.length>0) {
        //         for(const pm of project.AllocatedPM) {
        //             if (pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
        //                 pmOfProject = true;
        //             }
        //         }
        //     }
        // } else {
        //     if (project.PresalesResource && project.PresalesResource.Email && window.LOGGED_USER && window.LOGGED_USER.userId &&
        //         project.PresalesResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
        //         pmOfProject = true;
        //     }
        // }

        if (project.AllocatedResourceRequestPM && project.AllocatedResourceRequestPM.length > 0) {
          //find valid pms from end date
          let projectProjectManagers = project.AllocatedResourceRequestPM;
          let projValidManagers = [];
          projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );
    
          if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
    
            projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
            const lastAssignedManager = projectProjectManagers[0];
            projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
    
          }
    
          projValidManagers.forEach(value => {
            if(window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()){
              pmOfProject = true;
            }
          });
        }

        if (project.CMSheets && project.CMSheets.length > 0 && project.CMSheets[0].Status === 'IN_PROGRESS' && pmOfProject === true &&  project.ProjectStatus.Code === 'PRESALES') {
            const cmSheetData = {
                id: project.CMSheets[0].id,
                type: 'Project',
                name: project.ProjectName
            };

            cmSheets.push(cmSheetData);
            projectCMSheetdel.push(cmSheetData);
        }

        project.ChangeRequests.forEach(function (changeReq) {

            let crManager = false;
            // if (changeReq && changeReq.ResourceRequestStatus && changeReq.ResourceRequestStatus === 'PM_ALLOCATED') {
            //     if (changeReq.AllocatedPM && changeReq.AllocatedPM.length>0) {
            //         for(const allocatedPM of changeReq.AllocatedPM){
            //             if (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
            //               crManager = true;
            //             }
            //         }
            //     }
            // } else {
            //     if (window.LOGGED_USER.userId.toUpperCase() === changeReq.PresalesResource.Email.toUpperCase() && changeReq.IsActive === true) {
            //       crManager = true;
            //     }

            // }

            if (changeReq.AllocatedPM.length > 0) {
              //valid managers
              const crProjectManagers = changeReq.AllocatedPM;
        
              //find valid pms from end date
              let validManagers = [];
              validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())));
        
              if (validManagers.length === 0 && crProjectManagers.length > 0) {
                crProjectManagers.sort(function (a, b) {
                  return new Date(b.EndDate) - new Date(a.EndDate)
                })
                const lastAssignedManager = crProjectManagers[0];
                validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate));
        
              }
        
              validManagers.forEach(value => {
                if (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()) {
                  crManager = true;
                }
              });
            }

            if (changeReq.CMSheets && changeReq.CMSheets.length > 0 && changeReq.CMSheets[0].Status === 'IN_PROGRESS' && crManager === true && changeReq.Status.Code === 'PRESALES') {
                const crCMS = {
                    id: changeReq.CMSheets[0].id,
                    type: 'CR',
                    name: changeReq.CRName
                };
                cmSheets.push(crCMS);
                crCMSheetdel.push(crCMS);
            }
        });

        if (this.isMount) {
            this.setState({
                    cmSheetsArray: cmSheets,
                    projectCMSheets: projectCMSheetdel,
                    crCMSheets: crCMSheetdel
                },
                () => {
                    if (projectCMSheetdel.length === 1 && crCMSheetdel.length === 0) {
                        this.setState(
                            {
                                cmSheetIdToDelete: projectCMSheetdel[0].id,
                                CMSDialog: false
                            });
                        this.toggleConfirmDialog(
                            'Are you sure, you want to delete the project CM sheet?',
                            this.deleteCMSheet
                        );
                    } else if (projectCMSheetdel.length === 0 && crCMSheetdel.length === 1) {
                        this.setState(
                            {
                                cmSheetIdToDelete: crCMSheetdel[0].id,
                                CMSDialog: false
                            });
                        this.toggleConfirmDialog(
                            'Are you sure, you want to delete the CR CM sheet?',
                            this.deleteCMSheet
                        );
                    } else {
                        this.toggleCMSSelectDialog();
                    }
                });
        }
    }
  };

  setCMSIdtoDelete = (id, data) => {
    if (this.isMount) {
      this.setState(
        {
          cmSheetIdToDelete: id
        });

    }
  };

  deleteCMSheet = () => {
    if (this.state.cmSheetIdToDelete !== null) {
      deleteCMSheetIntegrated(this.state.cmSheetIdToDelete)
        .then(res => {
          this.populateactiveProjects();
          if (this.isMount) {
            this.setState({
              cmSheetIdToDelete: null,
              showSuccessImage: true,
              showErrorImage: false
            });
          }

            if (!this.state.CMSDialog){
                this.toggleConfirmDialog('', null);
            }

          this.toggleMessageDialog('Successfully deleted', 'Success');
          if (this.state.CMSDialog) {
            this.toggleCMSSelectDialog();
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          if (this.isMount) {
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
          }
          this.toggleMessageDialog(error.response.data, 'Error');
        });
    }
  };

  toggleCMSSelectDialog = () => {
    if (this.isMount) {
      this.setState({
        CMSDialog: !this.state.CMSDialog,
        cmSheetIdToDelete: null
      });
    }
  };

  // Revise and Approve Functionality
  onSelectCMS = async(project, action, projectCMS_, crCMS) => {

    if (this.isMount) {
      this.setState({
          projectIdOfSelectedCMS: project.id,
          projectCMS: projectCMS_,
          changeRequestCMS: crCMS,
          cmsAction: action
        },
        async() => {
          if (projectCMS_.length === 1 && crCMS.length === 0) {
            await this.setSelectedCMSId(projectCMS_[0].data.id, projectCMS_[0].data, 'projectCMS');
            this.selectCMS();
          } else if (projectCMS_.length === 0 && crCMS.length === 1) {
            await this.setSelectedCMSId(crCMS[0].data.id, crCMS[0].data, 'crCMS');
            this.selectCMS();
          } else {
            this.toggleViewCMSSelectDialog();
          }
        });
    }
  };

  onSelectCMSPool = (project, action, projectCMS_, crCMS) => {

    if (this.isMount) {
      this.setState({
          projectIdOfSelectedCMS: project.id,
          projectCMS: projectCMS_,
          changeRequestCMS: crCMS,
          cmsAction: action,
          IsPoolProject:true
        },
        () => {
          if (projectCMS_.length === 1 && crCMS.length === 0) {
            this.setSelectedCMSId(projectCMS_[0].data.id, projectCMS_[0].data, 'projectCMS');
            this.selectCMSPool();
          } else if (projectCMS_.length === 0 && crCMS.length === 1) {
            this.setSelectedCMSId(crCMS[0].data.id, crCMS[0].data, 'crCMS');
            this.selectCMSPool();
          } else {
            this.toggleViewCMSSelectDialog();
          }
        });
    }
  };

  toggleViewCMSSelectDialog = () => {
    if (this.isMount) {
      this.setState({
        CMSSelecctDialog: !this.state.CMSSelecctDialog,
        selectedCMSId: null
      });
    }
  };

  onCreateCMS = async (project, action) => {
    this.setState({
      CMSProject: project,
      isAProj: true
    });
    let poolProjectListAll = [];
    if(project.IsPoolProject !== true){
      const data = {
        regionId : project.BillingRegion,
        practiceId : project.BillingDivision,
        offeringId : project.OfferingId
      };
      poolProjectListAll = await getFactoryProjByRegionAndPractice(data);
    }

    if(poolProjectListAll.data && poolProjectListAll.data.length > 0){
      //pool project pop up
      this.poolProjectsAll = poolProjectListAll.data;
      await this.setState({
        poolProjectsAll: poolProjectListAll.data
      });
      this.toggleLinkProjToPoolDialog();
    }else{
      //create normal project - direct to create cms page
        this.setState({
          createCMS : true
        });
    }

  };

  toggleLinkProjToPoolDialog = async () => {
      await this.setState({
        linkToPoolDialog: !this.state.linkToPoolDialog
      });
  };

  onCreateNormalProject = () => {
    this.setState({
      createCMS : true
    });
  };

  onCreateNormalProjectCR = () => {
    this.setState({
      redirectToCreateCMS: true,
    });
  };

  onCreateFactoryproject = async() => {
    //link project to Factory project
    this.state.CMSProject.PoolProjectId = this.state.selectedPoolProject.id;
    await updateLinkToFactory(this.state.CMSProject, this.state.CMSProject.id)
      .then(res => {
        this.setState({
          createCMS : true
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

    handleCreateCMSforCRPool = (changeReqList, project, action) => {
        if (this.isMount) {
            this.setState({
                    changeRequests: changeReqList,
                    ChangeRequestAction: action,
                    ProjIdOfSelectedCR: project.id,
                    IsPoolProject:true
                },
                () => {
                    if (changeReqList.length === 1) {
                        if (this.isMount) {
                            this.setState({
                                selectedCRId: changeReqList[0].id,
                                redirectToCreateCMSPool: true,
                                selectedCMSReference: 'crCMS'
                            });
                        }
                    } else {
                        this.toggleCRSelectDialog();
                    }
                });
        }
    };

    handleWithdrawCMS = (project, projectCMS, crCMS) => {
      projectCMS = projectCMS.filter(v => v.data.CMSheets[0].Status === "PENDING_APPROVALS")
      crCMS = crCMS.filter(v => v.data.CMSheets[0].Status === "PENDING_APPROVALS")

      if (this.isMount) {
        this.setState(
          {
            projectIdOfSelectedCMS: project.id,
            projectCMS: projectCMS,
            project: project, 
            changeRequestCMS: crCMS,
            cmsAction: 'withdraw'
          },
          () => {
            if (projectCMS.length === 1 && crCMS.length === 0) {
              this.setSelectedCMSId(projectCMS[0].data.id, projectCMS[0].data, 'projectCMS');
              this.selectCMS();
            } 
            else if (projectCMS.length === 0 && crCMS.length === 1) {
              this.setSelectedCMSId(crCMS[0].data.id, crCMS[0].data, 'crCMS');
              this.selectCMS();
            } 
            else {
              this.toggleViewCMSSelectDialog();
            }
          }
        )
      }
    } 
  
    withdrawCMS = () => {
      if (!this.state.withdrawReason) {
        this.setState({
          isErrorMessageVisible: true,
        });
        return;
      } else {
        const approveObj = {
            projectCMS: this.state.selectedCMSheet,
            projectId: this.state.selectedCRId,
            comment: this.state.withdrawReason
        };
        if(this.state.CMSSelecctDialog == true){
          this.toggleViewCMSSelectDialog();
        }
        withdrawCMSheet(approveObj)
          .then( res => {
            this.setState({
              showSuccessImage: true,
              showErrorImage: false,
            });
            if (this.state.showCMSWithdrawDialog) {
              this.toggleCMSWithdrawDialog('', null);
            }
            this.toggleDialog('Withdrawn successfully', 'Success');

            this.populatePendingTasks();
            this.populateactiveProjects();
            this.populateStatuses();
            this.populateCMSStatus();
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            this.toggleDialog(error.response, 'Error');
          });
      }
    }
  
    submitRequestReviseCMS = async() => {
      if (!this.state.requestReviseCMSReason) {
        this.setState({
          isErrorMessageVisible: true,
        });
        return;
      } else {
        const selectedProjectOrCR = this.state.selectedProjectOrCR;
        const projData = {
          Department: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.BillingDivisionId : selectedProjectOrCR.BillingDivision,
          Practice: selectedProjectOrCR.PracticeId,
          Region: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.BillingRegion : selectedProjectOrCR.BillingRegion,
        }
        await selectReviseCMSRequestWorkflow(projData).then(res => {
          if (res.data.length > 1) {
            this.setState(
              {
                matchingWorkflowList: res.data
              },
              () => {
                this.toggleReviseCMSWorkflowSelectDialog();
              }
            );
          } else if (res.data.length === 1) {
            this.setState(
              {
                selectedWorkFlowId: res.data[0].id
              },
              () => {
                this.setWorkflowReviseCMS();
              }
            );
          } else {
            const message =
              'This request has failed to find a appropriate workflow for approvals. Please contact your system administrator to correct this issue.';
            const title = 'Error';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true,
              isErrorMessageVisible: false
            });
            this.toggleMessageDialog(message, title);
          }
        });
      }
    }

    setWorkflowReviseCMS = async() => {
      await this.setState({
        showReviseCMSWorkflowSelectDialog: false,
        showRequestReviseCMSDialog: false
      });
      const selectedProjectOrCR = this.state.selectedProjectOrCR;
      if (selectedProjectOrCR) {
        const workflowData = {
          ProjectId: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.ProjectId : selectedProjectOrCR.id,
          CrId: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.id : null,
          CMSheetId: selectedProjectOrCR.CMSheets[0].id,
          CMSheetVersion: selectedProjectOrCR.CMSheets[0].Version,
          WorkflowId: this.state.selectedWorkFlowId,
          Comment: this.state.requestReviseCMSReason
        };
        await setReviseCMSWorkflow(workflowData)
        .then(async res => {
          await this.setState({
            selectedWorkFlowId: null,
            showErrorImage: false,
            showSuccessImage: true
          });
          this.toggleMessageDialog(`CMS Revision request submitted for Approval`, 'Success');

          this.populatePendingTasks();
          this.populateactiveProjects();
          this.populateStatuses();
          this.populateCMSStatus();

        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
  
        
      }
    }

    toggleReviseCMSWorkflowSelectDialog = () => {
      this.setState({
        showReviseCMSWorkflowSelectDialog: !this.state.showReviseCMSWorkflowSelectDialog
      });
    };

    handleOnViewCMS = (project, action, projectCMS_, crCMS) => {
        if (this.isMount) {
            this.setState({
                    projectIdOfSelectedCMS: project.id,
                    projectCMS: projectCMS_,
                    changeRequestCMS: crCMS,
                    cmsAction: action
                },
                () => {
                    if (projectCMS_.length === 1 && crCMS.length === 0) {
                        this.setSelectedCMSId(projectCMS_[0].data.id, projectCMS_[0].data, 'projectCMS');
                        this.viewEditCMS();
                    } else if (projectCMS_.length === 0 && crCMS.length === 1) {
                        this.setSelectedCMSId(crCMS[0].data.id, crCMS[0].data, 'crCMS');
                        this.viewEditCMS();
                    } else {
                        this.toggleViewCMSSelectDialog();
                    }
                });
        }
    };

    handleOnViewCMSPool = (project, action, projectCMS_, crCMS) => {
        if (this.isMount) {
            this.setState({
                    projectIdOfSelectedCMS: project.id,
                    projectCMS: projectCMS_,
                    changeRequestCMS: crCMS,
                    cmsAction: action,
                    IsPoolProject:true
                },
                () => {
                    if (projectCMS_.length === 1 && crCMS.length === 0) {
                        this.setSelectedCMSId(projectCMS_[0].data.id, projectCMS_[0].data, 'projectCMS');
                        this.viewEditCMSPool();
                    } else if (projectCMS_.length === 0 && crCMS.length === 1) {
                        this.setSelectedCMSId(crCMS[0].data.id, crCMS[0].data, 'crCMS');
                        this.viewEditCMSPool();
                    } else {
                        this.toggleViewCMSSelectDialog();
                    }
                });
        }
    };

    viewEditCMS = () => {
        if (this.state.cmsAction === 'edit' && this.state.IsPoolProject === false) {
            if (this.isMount) {
                this.setState({
                    redirectToEditCMS: true
                });
            }
        } else if (this.state.cmsAction === 'edit' && this.state.IsPoolProject === true) {
            if (this.isMount) {
                this.setState({
                    redirectToEditCMSPool: true
                });
            }
        }
        else if (this.state.cmsAction === 'view' && this.state.IsPoolProject === false) {
            if (this.isMount) {
                this.setState({
                    redirectToEditViewCMS: true
                });
            }
        } else if (this.state.cmsAction === 'view' && this.state.IsPoolProject === true) {
            if (this.isMount) {
                this.setState({
                    redirectToEditViewCMS: true
                });
            }
        }
        else if (this.state.cmsAction === 'revise') {
            if (this.isMount) {
                this.setState({
                    redirectToReviseCMS: true
                });
            }
        } else if (this.state.cmsAction === 'approve') {
            if (this.isMount) {
                this.setState({
                    redirectToApproveCMS: true
                });
            }
        }
    };

    viewEditCMSPool = () => {
        if (this.state.cmsAction === 'edit') {
            if (this.isMount) {
                this.setState({
                    redirectToEditCMSPool: true
                });
            }
        } else if (this.state.cmsAction === 'view') {

            if (this.isMount) {
                this.setState({
                    redirectToEditViewCMS: true
                });
            }
        }
        else if (this.state.cmsAction === 'revise') {
            if (this.isMount) {
                this.setState({
                    redirectToReviseCMS: true
                });
            }
        } else if (this.state.cmsAction === 'approve') {
            if (this.isMount) {
                this.setState({
                    redirectToApproveCMS: true
                });
            }
        }
    };

  onCreateFactoryprojectCR = async() => {
    //link project to Factory project
    this.state.CMSCR.PoolProjectId = this.state.selectedPoolProject.id;
    await updateLinkToFactoryCR(this.state.CMSCR, this.state.CMSCR.id)
      .then(res => {
        this.setState({
          redirectToCreateCMS: true,
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  handleComboChangePoolProject = async event => {
    await this.setState({
      selectedPoolProject: event.target.value
    });
    this.setState({ key: Math.random() });
    //this.forceUpdate();
  };

  filterChangeCombo = async(event) => {
    await this.setState({
      poolProjectsAll: this.filterDataDivision(event.filter)
    });
  };

  filterDataDivision(filter) {
    const data = this.poolProjectsAll.slice();
    return filterBy(data, filter);
  }

  setSelectedCMSId = (cmsId, cmSheet, reference) => {
    if (this.isMount) {
      this.setState({
        selectedCMSId: cmsId,
        selectedCMSReference: reference,
        selectedCMSheet: cmSheet.CMSheets[0],
        selectedCRId: cmSheet.id,
        selectedProjectOrCR: cmSheet
      });
    }
  };

  selectCMS = async() => {
    if (this.state.cmsAction === 'revise') {
      if (this.isMount) {
        this.setState({
          redirectToReviseCMS: true
        });
      }
    }

    else if (this.state.cmsAction === 'edit' && this.state.IsPoolProject === false) {
      if (this.isMount) {
        this.setState({
          redirectToEditCMS: true
        });
      }
      }
      else if (this.state.cmsAction === 'edit' && this.state.IsPoolProject === true) {
      if (this.isMount) {
        this.setState({
          redirectToEditCMSPool: true
        });
      }
    }
    else if (this.state.cmsAction === 'view' && this.state.IsPoolProject === false) {
        if (this.isMount) {
            this.setState({
                redirectToEditViewCMS: true
            });
        }
    } else if (this.state.cmsAction === 'view' && this.state.IsPoolProject === true) {
        if (this.isMount) {
            this.setState({
                redirectToEditViewCMS: true
            });
        }
    }
      else if (this.state.cmsAction === 'approve') {
      if (this.isMount) {
        this.setState({
          redirectToApproveCMS: true
        });
      }
    }
    else if (this.state.cmsAction === 'withdraw') {
      this.toggleCMSWithdrawDialog(
        'You are about to withdraw this CMS from the approval workflow. You will have to submit the CMS for approval again. Do you wish to continue?',
        this.withdrawCMS
      );  
    } else if (this.state.cmsAction === 'requestReviseCMS') {
      this.setState({
        CMSSelecctDialog: false
      }); 
      const selectedProjectOrCR = this.state.selectedProjectOrCR;
      const projData = {
        ProjectId: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.ProjectId : selectedProjectOrCR.id,
        CrId: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.id : null,
      }
      await getAllPendingTimeEntriesForReviseCms(projData).then(res => {
        if(res.data && res.data.length > 0){
          this.setState({
            showErrorImg: true
          });
          const msg = "There are pending time approvals in this project/CR. Please approve/reject them before submitting a request for CMS revision."
          this.toggleErrorMessageDialog(msg, 'Error');
        } else {
          this.toggleRequestReviseCMSDialog(
            'Do you want to request for a CMS revision? Revising the CMS will invalidate all existing Resource Requests and you will have to resubmit new Resource Requests. Do you wish to continue?',
            this.submitRequestReviseCMS
          );      
        }
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }

  };

  selectCMSPool = () => {

    if (this.state.cmsAction === 'revise') {
      if (this.isMount) {
        this.setState({
          redirectToReviseCMS: true
        });
      }
    }

    else if (this.state.cmsAction === 'edit' && this.state.IsPoolProject === false) {
      if (this.isMount) {
        this.setState({
          redirectToEditCMS: true
        });
      }
      }
      else if (this.state.cmsAction === 'edit' && this.state.IsPoolProject === true) {
      if (this.isMount) {
        this.setState({
          redirectToEditCMSPool: true
        });
      }
    }
    else if (this.state.cmsAction === 'view' && this.state.IsPoolProject === false) {
        if (this.isMount) {
            this.setState({
                redirectToEditViewCMS: true
            });
        }
    } else if (this.state.cmsAction === 'view' && this.state.IsPoolProject === true) {
        if (this.isMount) {
            this.setState({
                redirectToEditViewCMS: true
            });
        }
    }
      else if (this.state.cmsAction === 'approve') {
      if (this.isMount) {
        this.setState({
          redirectToApproveCMS: true
        });
      }
    }
  };

  OnActivate = async (event, id, isMoreCR, type, CRs, selectedProject) => {
        event.preventDefault();
        if(CRs.length > 1){
            await this.setState({
              showCRSelectDialogOne: true,
              changeRequest: CRs,
              requestType: 'ChangeRequest',
              selectedProject: selectedProject
            });
        }
        else if (CRs.length === 1) {
            await this.setState({
              redirectToResourceReq: true,
              selectedCRId: id,
              requestType: 'ChangeRequest',
              ChangeRequestForResourceRequest: CRs[0],
              tempCR: CRs,
              selectedProject: selectedProject
            });
        }
  };

  setSelectedCRIdResourceRequest = (crId, cr) => {
      const tempCRArray = [];
      tempCRArray.push(cr);

        if (this.isMount) {
            this.setState({
                selectedCRId: crId,
                tempCR :tempCRArray
            });
        }
  };

  toggleCRSelectDialogForResourceRequest = () => {
        this.setState({
            showCRSelectDialogOne: !this.state.showCRSelectDialogOne,
            selectedCRId: null
        });
  };

  setSelectedWorkflowId = async workflowId => {
    await this.setState({
      selectedWorkFlowId: workflowId
    });
  };

  onCRSelectResourceRequest = () =>{
        this.setState({
            redirectToResourceReq: true
        });
  }

    handleCreateCMSforCR = (changeReqList, project, action) => {
    if (this.isMount) {
      this.setState({
          changeRequests: changeReqList,
          ChangeRequestAction: action,
          ProjIdOfSelectedCR: project.id,
          action: 'create',
          isACR: true
        },
        async() => {
            if (changeReqList.length === 1) {
                if (this.isMount) {
                    this.setState({
                        selectedCRId: changeReqList[0].id,
                        selectedCMSReference: 'crCMS'
                    });
                }

                //linking to pool project
              this.setState({
                CMSCR: changeReqList[0]
              });
              let poolProjectListAll = [];
                const data = {
                  regionId : changeReqList[0].BillingRegion,
                  practiceId : changeReqList[0].BillingDivisionId,
                  offeringId : changeReqList[0].OfferingId
                };
                poolProjectListAll = await getFactoryProjByRegionAndPractice(data);

              if(poolProjectListAll.data && poolProjectListAll.data.length > 0){
                //pool project pop up
                this.poolProjectsAll = poolProjectListAll.data;
                await this.setState({
                  poolProjectsAll: poolProjectListAll.data
                });
                this.toggleLinkProjToPoolDialog();
              }else{
                //create normal cr - direct to create cms page
                this.setState({
                  redirectToCreateCMS: true,
                });
              }
            } else {
                this.toggleCRSelectDialog();
            }
        });
    }
  };

  render() {
    if (this.state.redirectToEditCR === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            pathname: '/changeRequest/edit',
            changeReqId: this.state.selectedCRId,
            action: 'edit'
          }}
        />
      );
    }

    if (this.state.redirectToEditCR === true && this.state.IsPoolProject === true) {
      return (
        <Redirect
          to={{
            pathname: '/poolProjectRevision/poolRevision',
            changeReqId: this.state.selectedCRId,
            action: 'edit'
          }}
        />
      );
    }


    if (this.state.redirectToViewCR === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            pathname: '/changeRequest/edit',
            changeReqId: this.state.selectedCRId,
            action: 'view'
          }}
        />
      );
    }

    if (this.state.redirectToViewCR === true && this.state.IsPoolProject === true) {
      return (
        <Redirect
          to={{
            pathname: '/poolProjectRevision/poolRevision',
            changeReqId: this.state.selectedCRId,
            action: 'view'
          }}
        />
      );
    }

    
    if (this.state.redirectToReviseCMS === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/projects/cmsheet/cmsheet',
            action: 'revise'
          }}
        />
      );
    }

    if (this.state.redirectToReviseCMS === true && this.state.IsPoolProject === true) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/PoolProject/FactoryProjectCMS',
            action: 'revise'
          }}
        />
      );
    }
      if (this.state.redirectToEditViewCMS === true && this.state.IsPoolProject === true) {
          return (
              <Redirect
                  to={{
                      changeReqId: this.state.selectedCRId,
                      projectId: this.state.projectIdOfSelectedCMS,
                      cmSheet: this.state.selectedCMSheet,
                      reference: this.state.selectedCMSReference,
                      pathname: '/PoolProject/FactoryProjectCMS',
                      action: 'view'
                  }}
              />
          );
      }

    if (this.state.redirectToEditViewCMS === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/projects/cmsheet/cmsheet',
            action: 'view'
          }}
        />
      );
    }


    if (this.state.redirectToEditCMS === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/projects/cmsheet/cmsheet',
            action: 'edit'
          }}
        />
      );
    }

    if (this.state.redirectToEditCMSPool === true && this.state.IsPoolProject === true) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.projectIdOfSelectedCMS,
            cmSheet: this.state.selectedCMSheet,
            reference: this.state.selectedCMSReference,
            pathname: '/PoolProject/FactoryProjectCMS',
            action: 'edit'
          }}
        />
      );
    }

      // Create only pool cms
      if (this.state.redirectToCreateCMSPool === true && this.state.IsPoolProject === true) {
          return (
              <Redirect
                  to={{
                      changeReqId: this.state.selectedCRId,
                      projectId: this.state.ProjIdOfSelectedCR,
                      reference: this.state.selectedCMSReference,
                      pathname: '/PoolProject/FactoryProjectCMS',
                      action: 'create'
                  }}
              />
          );
      }

    if (this.state.redirectToCreateCMS === true  && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCRId,
            projectId: this.state.ProjIdOfSelectedCR,
            reference: this.state.selectedCMSReference,
            pathname: '/projects/cmsheet/cmsheet',
            action: 'create'
          }}
        />
      );
    }

    if (this.state.redirectToApproveCMS === true && this.state.IsPoolProject === false) {
      return (
        <Redirect
          to={{
            pathname: '/projects/cmsheet/cmsheet',
            projectId: this.state.projectIdOfSelectedCMS,
            changeReqId: this.state.selectedCRId ? this.state.selectedCRId : 0,
            cmSheet: this.state.selectedCMSheet,
            action: 'approve',
            reference: this.state.selectedCMSReference
          }}
        />
      );
    }

    if (this.state.redirectToApproveCMS === true && this.state.IsPoolProject === true) {
      return (
        <Redirect
          to={{
            pathname: '/PoolProject/FactoryProjectCMS',
            projectId: this.state.projectIdOfSelectedCMS,
            changeReqId: this.state.selectedCRId ? this.state.selectedCRId : 0,
            cmSheet: this.state.selectedCMSheet,
            action: 'approve',
            reference: this.state.selectedCMSReference
          }}
        />
      );
    }


      if (this.state.redirectToResourceReq === true) {
          return (
            <Redirect
              to={{
                changeReqId: null,
                ProjectId: this.state.selectedCRId,
                Type: this.state.requestType,
                ChangeRequest: this.state.tempCR,
                selectedProject: this.state.selectedProject,
                pathname: '/ResourceManagement/ResourceRequestActivation'
              }}
            />
          );
      }

    if (this.state.createCMS === true) {
      return (
        <Redirect
          to={{
            pathname: '/projects/cmsheet/cmsheet',
            projectId: this.state.CMSProject.id,
            reference: 'projectCMS',
            action: 'create'
          }}
        />
      );
    }

    if (this.state.toHomePage === true) {
      return <Redirect to="/" />;
    }

    //show/hide file upload button overlay
    var fileUploadDivStyle = {
      display: ((!this.state.disableFileUpBtnDiv) || (this.state.selectedChangeToStatus == null) || (this.state.invalidatePermission)) ? 'block' : 'none'
    };


    const components = PanelBarUtils.mapItemsToComponents(this.state.data);
    return (
      <div className="container-fluid">
        <div className="row dashboard-top">
          <div className="col-md-8">
            <div className="main-card m-0">
              <div className="row">
                <div className="col-md-9">
                  <div className="main-heading">Pending Task List</div>
                </div>
                <div className="col-md-3">
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="pending-tasklist">
                  <Grid className="tasklist-no-records"
                        data={this.state.pendingTasks}
                        skip={this.state.skip}
                        take={this.state.take}
                        total={this.state.TotalCount}
                        pageable={true}
                        onPageChange={this.pageChange}
                        resizable
                        sortable={true}
                        sort={this.state.sort}
                        onSortChange={this.shortChange}
                  >
                    <Column
                      field="id"
                      title="Task Type"
                      cell={props => <PendingTaskTypeCell {...props} />}
                      width="110px"
                    />
                    <Column field="UniqueID" title="ID" width="80px" cell={props => <UniqueIdCell {...props}/>}/>
                    <Column
                      field="id"
                      title="Task Description"
                      cell={props => <PendingTaskDescCell {...props} />}
                    />
                    <Column
                      field="createdAt"
                      title="Created Date"
                      width="120px"
                      cell={props => <CreatedDateCell {...props} />}
                    />
                    <Column
                      field="createdAt"
                      title="Completion Date"
                      width="140px"
                      cell={props => <CompletionDateCell {...props} />}
                    />
                    <Column
                      field=""
                      title="Actions"
                      width="80px"
                      cell={props => <PendingTaskActionsCell {...props} />}
                    />
                  </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 pl-0">
            <div className="main-card m-0">
              <div className="main-heading">Active Projects</div>
              {(this.state.initialAllData && this.state.initialAllData.length > 0) && (
                <>
                <div className="row">
                  <div className="d-block col-md-12">
                    <label>Project Status:</label>
                  </div>
                </div> 
                <div className="row">
                  <div className="d-block col-md-7">
                    <div className="">
                      <MultiSelect
                        placeholder="Please select"
                        data={this.state.statusesForFilter}
                        textField="StatusName"
                        dataItemKey="id"
                        onChange={this.handleChangeStatusFilter}
                        filterable={true}
                        onFilterChange={this.filterChangeStatusFilter}
                        name="selectedStatuses"
                        value={this.state.selectedStatuses}
                      />
                    </div>
                  </div>
                  <div className="col-md-5 btn-align-right">
                    <Button primary={true} type="submit" onClick={this.submitSearch}>
                      Search
                    </Button>
                    <Button type="reset" onClick={this.cancelSearch}>
                      Clear
                    </Button>
                  </div>
                </div>
                <div className="row"></div>
                <div className="row"></div>
                </>
              )}

              <div className="ex1">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panelbar-wrapper modified">
                      <PanelBar children={components}/>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.CMSDialog === true && (
          <CMSDeleteDialog
            toggleCMSSelectDialog={this.toggleCMSSelectDialog}
            cmSheetsArray={this.state.cmSheetsArray}
            changerequestCMS={this.state.crCMSheets}
            projectCMS={this.state.projectCMSheets}
            cmSheetIdToDelete={this.state.cmSheetIdToDelete}
            setCMSIdtoDelete={this.setCMSIdtoDelete}
            deleteCMSheet={this.deleteCMSheet}
            cmsAction={this.state.cmsAction}
          />
        )}

        {this.state.CMSSelecctDialog === true && (
          <CMSSelectDialog
            toggleViewCMSSelectDialog={this.toggleViewCMSSelectDialog}
            projectCMS={this.state.projectCMS}
            selectedCMSId={this.state.selectedCMSId}
            setSelectedCMSId={this.setSelectedCMSId}
            changeRequestCMS={this.state.changeRequestCMS}
            selectCMS={this.selectCMS}
            cmsAction={this.state.cmsAction}

          />
        )}
        {this.state.showCRSelectDialog === true && (
          <Dialog title={'Select the relevant CR to ' +(this.state.ChangeRequestAction === 'CreateCMSforCR' ? 'create' : (this.state.ChangeRequestAction === 'HandleCrPopUp' ? 'change status' : this.state.ChangeRequestAction))} onClose={this.toggleCRSelectDialog}
                  width="400px">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <form className="k-form modified cr-popup">
                      <div className="k-form-field">
                          {this.state.changeRequests.map((option, i) => {
                              return (
                                  <div className="ml-3">
                                      <input
                                          type="radio"
                                          id={'' + option.id}
                                          name={'' + option.id}
                                          value={option.id}
                                          checked={this.state.selectedCRId === option.id}
                                          className="k-radio"
                                          key={option.id}
                                          onChange={ this.setSelectedCRId.bind(this, option.id, option.IsPoolProject, option) }
                                      />
                                      <label
                                          key={option.id}
                                          htmlFor={'' + option.id}
                                          className="k-radio-label"
                                      >
                                          { option.IsPoolProject === true? option.FinancialYear : option.CRName}
                                          <td className="badge badge-warning ml-2 my-auto px-2 py-1"><span>{option.Status.StatusName}</span> </td>

                                      </label>
                                  </div>
                              );
                          })}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleCRSelectDialog}>
                No
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.onEditCRClick}
                disabled={!this.state.selectedCRId}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

          {this.state.showCRSelectDialogOne === true && (

              <Dialog title="Select the relevant CR to resource request" onClose={this.toggleCRSelectDialogForResourceRequest} width="400px">
                  <div className="row">
                      <div className="col-md-12 ml-4">
                          <div className="row">
                          </div>
                          <div className="row">
                              <div className="k-form-field">
                                  {this.state.changeRequest.map((option, i) => {
                                      return (
                                          <div>
                                              <input
                                                  type="radio"
                                                  id={option.id}
                                                  name={'' + option.id}
                                                  value={option.id}
                                                  checked={this.state.selectedCRId === option.id}
                                                  className="k-radio"
                                                  key={'op'+option.id}
                                                  onChange={this.setSelectedCRIdResourceRequest.bind(this, option.id, option)}
                                              />
                                              <label
                                                  key={'opq'+option.id}
                                                  htmlFor={option.id}
                                                  className="k-radio-label case-insensitive"
                                              >
                                                  {option.CRName}
                                              </label>
                                          </div>
                                      );
                                  })}
                              </div>
                          </div>
                      </div>
                  </div>

                  <DialogActionsBar>
                      <button className="k-button" onClick={this.toggleCRSelectDialogForResourceRequest}>
                          No
                      </button>
                      <button
                          className="k-button modal-primary"
                          onClick={this.onCRSelectResourceRequest}
                      >
                          Yes
                      </button>
                  </DialogActionsBar>
              </Dialog>
          )}


        {/* Factory Project Selection */}
        {this.state.linkToPoolDialog === true && (
        <Dialog title="Factory Project Selection" onClose={this.toggleLinkProjToPoolDialog} width="500px">
          <p style={{ margin: '25px', textAlign: 'center' }}>This project is compatible to be added to a factory
            type customer project. Do you want to create a factory type customer project? </p>
          <div className="d-block">
            <label className="mandatory">Select a factory:</label>
          </div>
          <div className="d-block">
            <div className="">
              <ComboBox
                placeholder="Please select"
                name="selectedPoolProject"
                data={this.state.poolProjectsAll}
                textField="ProjectName"
                dataItemKey="id"
                value={this.state.selectedPoolProject}
                onChange={this.handleComboChangePoolProject}
                required={true}
                filterable={true}
                onFilterChange={this.filterChangeCombo}
              />
            </div>
          </div>
          {this.state.isACR === true && (
            <DialogActionsBar>
              <button className="k-button" onClick={this.onCreateFactoryprojectCR}>
                Create as a Factory Type Project
              </button>
              <button className="k-button modal-primary" onClick={this.onCreateNormalProjectCR}>
                Create as a Normal Project
              </button>
            </DialogActionsBar>
          )}
          {this.state.isAProj === true && (
          <DialogActionsBar>
            <button className="k-button" onClick={this.onCreateFactoryproject}>
              Create as a Factory Type Project
            </button>
            <button className="k-button modal-primary" onClick={this.onCreateNormalProject}>
              Create as a Normal Project
            </button>
          </DialogActionsBar>
          )}
        </Dialog>
        )}


        {/* CHANGE STATUS POPUP */}
        {this.state.changeStatusDialog && <Dialog title={"Change Status - " + (this.state.projectOrCr && this.state.projectOrCr.ProjectName ? this.state.projectOrCr.ProjectName + " - " + this.state.projectOrCr.UniqueId : (this.state.projectOrCr ? this.state.projectOrCr.CRName + " - " + this.state.projectOrCr.UniqueId  : ""))} onClose={this.toggleChangeStatusDialog} width={700}>
          <div className="row">
            <div className="col-md-4">
              <div className="d-block">
                <label>Status:</label>
              </div>
              <div className="d-block">
                <div className="" >
                  <ComboBox
                    name="selectedChangeToStatus"
                    textField="PermissionDescription"
                    dataItemKey="PermissionName"
                    value={this.state.selectedChangeToStatus}
                    data={this.state.statusValueLst}
                    placeholder={this.CROrProjectCurrentStatus}
                    disabled={this.state.invalidatePermission || this.state.isApprovalStatusPending}
                    onChange={this.handleOnChangeStatus}
                    filterable={true}
                    onFilterChange={this.filterStatusOnChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8 my-auto">
              <div className="d-block">
                <label></label>
              </div>

              <div className="d-block">
                {this.state.showCloseSubCategory && (
                  <div className="d-block">
                    <input
                      type="radio"
                      name="changestatus"
                      value="discontinue"
                      className="k-radio"
                      id="discontinue"
                      disabled="true"
                      onChange={this.handleStatusChangeRbtn}
                    />
                    <label className="k-radio-label mr-2" htmlFor="discontinue">
                      Discontinue
                    </label>

                    <input
                      type="radio"
                      name="changestatus"
                      value="wthtbid"
                      className="k-radio"
                      id="wthtbid"
                      disabled="true"
                      onChange={this.handleStatusChangeRbtn}
                    />
                    <label className="k-radio-label mr-2" htmlFor="wthtbid">
                      Without Bidding
                    </label>

                    <input
                      type="radio"
                      name="changestatus"
                      value="oppolost"
                      className="k-radio"
                      id="oppolost"
                      disabled="true"
                      onChange={this.handleStatusChangeRbtn}
                    />
                    <label className="k-radio-label" htmlFor="oppolost">
                      Opportunity Lost
                    </label>

                    <div>
                      {this.state.isErrorMessageVisible === true ? (
                        <span className={this.validateProperty(this.state.projectname)}>
                          Please select a sub-category
                        </span>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>

          <div className="row col-md-auto">
            {this.state.isApprovalStatusPending === true ? (
              <span className={this.validateProperty(this.state.projectname)}>
                {this.state.reqStatus + ' workflow is pending with ' + this.state.approversName}
              </span>
            ) : null}
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="d-block">
                <label>Start Date:</label>
              </div>
              <div className="d-block">
                <div className="" >
                  {((((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) && (this.state.endDateAugmentation !== null)) || (((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order")) && (this.state.endDateBlanketWork !== null))) ?
                    (
                      <DatePicker
                        width="100"
                        name="startdateStatus"
                        onChange={this.handleStartdateChange}
                        value={this.state.startdateStatus}
                        min={new Date()}
                        disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null || this.state.projectActivated || (this.state.currentStatus[0].Code == 'PROJECT_ON_HOLD' && this.state.selectedChangeToStatus.PermissionDescription === 'Active')}
                        format="MM/dd/yyyy"
                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                        required={(((this.state.projtypeValue && this.state.billingdiviValue) && this.state.projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order"))) ? true : false}
                      />
                    ) : (
                      <DatePicker
                        width="100"
                        name="startdateStatus"
                        onChange={this.handleStartdateChange}
                        value={this.state.startdateStatus}
                        min={new Date()}
                        disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null || this.state.projectActivated || (this.state.currentStatus[0].Code == 'PROJECT_ON_HOLD' && this.state.selectedChangeToStatus.PermissionDescription === 'Active')}
                        format="MM/dd/yyyy"
                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                        required={(((this.state.projtypeValue && this.state.billingdiviValue) && this.state.projtypeValue.Name === "Application Support" && this.state.billingdiviValue.id === 30) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Resource Augmentation")) || ((this.state.projtypeValue && this.state.projtypeValue.Name === "Blanket Work Order"))) ? true : false}
                      />
                    )}
                </div>

                <div>
                  {(this.state.showStartDateValidationError) ? 
                    ( <span className={this.validateProperty(this.state.startdateStatus)}>
                        Please enter a start date
                      </span>
                    ) : null}
                </div>

                <div>
                  {(this.state.showStartDateErrorMessage) ? 
                    ( <span className={this.validateProperty()}>
                        Start date cannot be a past date
                      </span>
                    ) : null}
                </div>

              </div>

              <div className="d-block">
                <div className="d-block mandatory">
                  <label>Billing Entity:</label>
                </div>
                <div className="d-block">
                  <div className="" >
                  <ComboBox
                    data={this.state.billingEntityType}
                    textField="Name"
                    dataItemKey="id"
                    placeholder="Please Select"
                    value={this.state.billingEntityValue}
                    onChange={this.handleBillingEntityChange}
                    disabled={this.state.disablePONumber || this.state.selectedChangeToStatus == null}
                    filterable={true}
                    onFilterChange={this.filterBillingEntities}
                  />
                  </div>
                  <div>
                    {this.state.showBillingEntityErrorMessage === true ? (
                        <span className={this.validateProperty(this.state.billingEntityValue)}>
                          Please select a Billing Entity
                        </span>
                      ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="d-block">
                <label>Document Upload:</label>
              </div>
              <div className="d-block">
                <div className="" >

                  <div className="d-flex flex-col justify-content-between">
                    <ComboBox
                      name="documentType"
                      textField="DocType"
                      dataItemKey="id"
                      value={this.state.documentType}
                      data={this.state.documentTypesForDropdown}
                      placeholder={'Please Select'}
                      disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null}
                      onChange={this.handleOnChangeDocType}
                      filterable={true}
                      onFilterChange={this.filterDocTypeOnChange}
                      itemRender={this.handleHighlightMandatoryDocs}
                    />

                    {/*file upload button*/}
                    <div className="d-flex flex-col">
                      <div className="rounded-link ml-3 align-self-center">
                        <span className="k-icon k-i-upload"/>
                      </div>
                      <input 
                        type="file" 
                        name="file" 
                        title="Upload File"
                        className="custom-statusFile-input"
                        disabled={this.state.invalidatePermission || this.state.documentType == null}
                        onChange={this.handleFileUpload}
                        value={this.state.uploadedFileId}
                        multiple={true}
                      />
                    </div>

                    <div id="overlay" style={fileUploadDivStyle}></div>
                  </div>
                  
                  <div>
                    {this.state.DocUploadErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.projectname)}>
                        Please upload mandatory document(s)
                      </span>
                    ) : null}
                  </div>

                  <div>
                    {this.state.fileUploadMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.projectname)}>
                        Only allow (doc, docx, pdf, xls, xlsx, ppt, pptx, jpg, jpeg, png, msg, htm) files
                      </span>
                    ) : null}
                  </div>

                  <pre/>

                  {/*uploaded document section*/}
                  <div className="row">
                    <div className="col-md-12">
                      <Grid 
                      data={this.state.fileUploadDetails}
                      resizable={true}
                      >
                        <Column field="DocType" title="Document Type" width="120px" />
                        <Column
                          field=""
                          title="Document Name"
                          cell={props => (<StatusFileUploadCell ApiUrl={apiUrl} {...props} />)}
                        />
                        <Column 
                          field="" 
                          title="Action"
                          cell={props => (<DeleteCell {...props} OnDelete={this.OnDelete}/>)} 
                          width="60px"
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {this.state.isPONumberRequired && (
              <div className="col-md-4">
                <div className="d-block mandatory">
                  <label>PO Number:</label>
                </div>
                <div className="d-block">
                  <div className="" id="poNumberToolTip">
                    <Input
                      autoComplete="off"
                      type="text"
                      name="poNumber"
                      value={this.state.poNumber}
                      onChange={this.handlePONumberChange}
                      disabled={this.state.disablePONumber || this.state.selectedChangeToStatus == null}
                    />
                    {this.state.showPONumberErrorMessage === true ? (
                      <span className={this.validateProperty(this.state.poNumber)}>
                        Please provide a PO number
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
            
            <div className={this.state.isPONumberRequired ? "col-md-8" : "col-md-12"}>
              <div className="d-block">
                <label className="change-status-labelname">Comment:</label>
              </div>
              <div className="d-block">
                <div className="" >
                  <ReactQuill
                    name="statusChangeComment"
                    onChange={this.onChangeStsChgComment}
                    value={this.state.stsChangeComment}
                    maxLength="100"
                    readOnly={this.state.invalidatePermission || this.state.selectedChangeToStatus === null}
                  />
                </div>
                <div>
                  {(this.state.selectedChangeToStatus != null && (ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 14 || ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 13)) ? 
                    ( <span className={this.validateCommentProperty(this.state.stsChangeComment)}>
                        Please enter a comment
                      </span>
                    ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Grid
                style={{ maxHeight: '125px' }}
                data={orderBy(this.state.statusChangeGridDetails, this.state.sortActivityLog)}
                resizable
                scrollable
              >
                <Column field="Status" title="Status"/>                
                <Column field="Approver" title="Approver" width="140px" />
                <Column field="" title="Approval Status"
                        cell={props => (
                          <ApprovalStatusCell {...props} />
                        )}
                />
                <Column field="DateTime" title="Date and Time"/>
                <Column field="" title="Comment" cell={props => (<StatusChangeCommentCell {...props}/>)} />
                <Column field="" title="Document Uploaded" cell={props => (<ActivityLogFilesCell ApiUrl={apiUrl} name="DocType" {...props} />)}/>
              </Grid>
            </div>
          </div>
          <DialogActionsBar>
            <button className="k-button" onClick={this.toggleChangeStatusDialog}>Cancel</button>
            <button 
              id="statusPopupSaveBtn" 
              className="k-button modal-primary" 
              disabled={this.state.invalidatePermission || this.state.selectedChangeToStatus == null || ((ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 14 || ProjStatuses[this.state.selectedChangeToStatus.PermissionName].value === 13) && (this.state.stsChangeComment == null || this.state.stsChangeComment == "" || this.state.stsChangeComment == "<p><br></p>" ))} 
              onClick={this.onSaveStatusChange}
              >
              Save
            </button>
          </DialogActionsBar>
        </Dialog>}

        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="300px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showCMSWithdrawDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleCMSWithdrawDialog} width="400px">
              <p style={{ margin: '10px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
              <p><label className="mandatory">Reason:</label></p>
              <textarea className="k-textarea" rows="5" cols="50" placeholder="Type Comment"
                        onChange={this.handleWithdrawReasonInputChange}
                        value={this.state.withdrawReason}
              ></textarea>
              {this.state.isErrorMessageVisible === true ? (
                <span className={this.validateProperty(this.state.withdrawReason)}>
                  Please provide a reason.
                </span>
              ) : null}
              <DialogActionsBar>
                  <button className="k-button" onClick={this.toggleCMSWithdrawDialog}>
                      No
                  </button>
                  <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                      Yes
                  </button>
              </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showRequestReviseCMSDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleRequestReviseCMSDialog} width="400px">
              <p style={{ margin: '10px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
              <p><label className="mandatory">Reason for Revision Request:</label></p>
              <textarea className="k-textarea" rows="5" cols="50" placeholder="Type Comment"
                        onChange={this.handleReviseCMSReasonInputChange}
                        value={this.state.requestReviseCMSReason}
              ></textarea>
              {this.state.isErrorMessageVisible === true ? (
                <span className={this.validateProperty(this.state.requestReviseCMSReason)}>
                  Please provide a reason.
                </span>
              ) : null}
              <DialogActionsBar>
                  <button className="k-button" onClick={this.toggleRequestReviseCMSDialog}>
                      No
                  </button>
                  <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                      Yes
                  </button>
              </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showErrorMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleErrorMessageDialog} width="400px">
            {this.state.showErrorImg === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleErrorMessageDialog}>
                OK
                  </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success"/>
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image"/>
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}

        {(this.state.showMessageDialog === true &&
        (this.state.dialogMessage === 'Status changed successfully' || this.state.dialogMessage === 'Status Change submitted for Approval')) && (
          <Dialog title={this.state.dialogTitle} onClose={this.reDirectToHome} width="400px">
            {this.state.showSuccessImg === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button modal-primary"
                      onClick={
                        (this.state.dialogMessage === 'Status changed successfully' || this.state.dialogMessage === 'Status Change submitted for Approval') ? this.reDirectToHome : this.toggleMessageDialog
                      }
              >
                OK
                  </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showWorkflowSelectDialog === true && (
            <Dialog title="Select Workflow" onClose={this.toggleWorkflowSelectStatusDialog} width="300px">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <p className="subtitle-workflow" style={{ textAlign: 'center' }}>Please select a workflow.</p>
                  </div>
                  <div className="row">
                    <form className="k-form modified">
                      <div className="k-form-field">
                        {this.state.matchingWorkflowList.map((option, i) => {
                          return (
                              <div className="field-mod">
                                <input
                                    type="radio"
                                    id={'' + option.id}
                                    name={'' + option.id}
                                    value={option.id}
                                    checked={this.state.selectedWorkFlowId === option.id}
                                    className="k-radio"
                                    key={option.id}
                                    onChange={this.setSelectedWorkflowId.bind(this, option.id)}
                                />
                                <label
                                    key={option.id}
                                    htmlFor={'' + option.id}
                                    className="k-radio-label"
                                >
                                  {option.name}
                                </label>
                              </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <DialogActionsBar>
                <button className="k-button" onClick={this.toggleWorkflowSelectStatusDialog}>
                  No
                </button>
                <button
                    className="k-button modal-primary"
                    onClick={this.setWorkflowToStatus.bind(this, this.state.cmsId,this.state.statusChangeObj)}
                    disabled={!this.state.selectedWorkFlowId}
                >
                  Yes
                </button>
              </DialogActionsBar>

            </Dialog>
        )}

          {this.state.showReviseCMSWorkflowSelectDialog === true && (
            <Dialog title="Select Workflow" onClose={this.toggleReviseCMSWorkflowSelectDialog} width="300px">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <p className="subtitle-workflow" style={{ textAlign: 'center' }}>Please select a workflow.</p>
                  </div>
                  <div className="row">
                    <form className="k-form modified">
                      <div className="k-form-field">
                        {this.state.matchingWorkflowList.map((option, i) => {
                          return (
                              <div className="field-mod">
                                <input
                                    type="radio"
                                    id={'' + option.id}
                                    name={'' + option.id}
                                    value={option.id}
                                    checked={this.state.selectedWorkFlowId === option.id}
                                    className="k-radio"
                                    key={option.id}
                                    onChange={this.setSelectedWorkflowId.bind(this, option.id)}
                                />
                                <label
                                    key={option.id}
                                    htmlFor={'' + option.id}
                                    className="k-radio-label"
                                >
                                  {option.name}
                                </label>
                              </div>
                          );
                        })}
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <DialogActionsBar>
                <button className="k-button" onClick={this.toggleReviseCMSWorkflowSelectDialog}>
                  No
                </button>
                <button
                    className="k-button modal-primary"
                    onClick={this.setWorkflowReviseCMS.bind(this)}
                    disabled={!this.state.selectedWorkFlowId}
                >
                  Yes
                </button>
              </DialogActionsBar>

            </Dialog>
        )}

          <Loader loading={this.state.loading} />
      </div>

    );
  }
};

export default Dashboard;
