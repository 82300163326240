import React, { Component } from 'react';
import {ComboBox, DropDownList, MultiSelect} from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { getCurrencies } from '../../../../masterData/common/CommonService';
import {
    calculateCostDate,
    retrieveConversionRate
} from '../../CMSheetService';
import {formatNumber, getFormattedDate, isWeekend} from '../../../../integration/CommonUtils';
import * as loggerService from '../../../../integration/LoggerService';
import { LOG_TYPES } from '../../../../integration/IntegrationEnums';
import ReactQuill from "react-quill";
import {TabStripTab} from "@progress/kendo-react-layout";
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle, ChartSeries, ChartSeriesItem,
    ChartTitle
} from "@progress/kendo-react-charts";
import {getCustomerProjectsById} from "../../../project/ProjectService";
import ApprovalCommentCell from "../ApprovalCommentCell";
import CustomerProjectsCell from "./CustomerProjectsCell";
import CustomerCell from "./CustomerCell";
import CustomerStatusCell from "./CustomerStatusCell";
import EffortCell from "./EffortCell";
import {getHolidaysIntegrated} from "../../../../integration/MasterDataService";
import moment from "moment";
import RevenueCell from "./RevenueCell";
import AllocationCell from "./AllocationCell";
import DailyRateCell from "./DailyRateCell";
import {getCost, getMultiYearCost} from "../../../../masterData/costCard/CostCardService";
import {getMultiYearInfoByYearandCostCardId} from "../../../../masterData/multiyearIncrement/MultiyearIncrementService";
import {getResourceGroup} from "../../../../resourceManagement/ResourceService";
import Loader from "../../../../integration/components/Loader";
import {Input} from "@progress/kendo-react-inputs";
import {Tooltip} from "reactstrap";

class SummaryPoolProject extends Component {


    isMount = false;
    ticked = [];


    constructor(props) {
        super(props);

        this.state = {
            calculations: [],
            currencies: [],
            dataValueCurrency: null,
            showMessageDialog: false,
            dialogMessage: '',
            dialogTitle: '',
            skip: 0,
            take: 20,
            customerProjects : [],
            netProfitLoss: 0,
            netProfitMargin: 0,
            categories : [],
            firstSeries : [],
            isLoading: false,
        };
    }

    static rowRender(trElement, props) {
        const isHeader = props.dataItem.isHeader;
        const contEffort = props.dataItem.contEffort;
        const className = 'summery-header-style';
        const contclass = 'contract-effort';
        const trProps = { class: isHeader === true ? className : null };
        const cedProps = { class: contEffort === true ? contclass : null };
        if (isHeader) {
            return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
        } else if (contEffort) {
            return React.cloneElement(trElement, { ...cedProps }, trElement.props.children);
        } else
            return trElement;
    }

    pageChange = event => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    };


    componentDidMount() {
        this.isMount = true;
        this.populateResourceGroup();
        this.populateCalculationsGrid();
        this.populateCurrencies();
        this.calculateContractedEffort();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    componentDidUpdate(prevProps) {
        const projectPropsString = JSON.stringify(this.props.project);
        const prevProjectPropsString = JSON.stringify(prevProps.project);
        const crPropsString = JSON.stringify(this.props.changeRequest);
        const crPrevPropsString = JSON.stringify(prevProps.changeRequest);
        const cmSheetPropsString = JSON.stringify(this.props.cmSheet);
        const cmSheetPrevPropsString = JSON.stringify(prevProps.cmSheet);
        const paymentMethodPropsString = JSON.stringify(this.props.selectedPaymentMethod);
        const prevpaymentMethodPropsString = JSON.stringify(prevProps.selectedPaymentMethod);
        const prevcustomerProjectsPropsString = JSON.stringify(this.props.customerProjects);
        if ((prevcustomerProjectsPropsString !== prevcustomerProjectsPropsString || projectPropsString !== prevProjectPropsString || crPropsString !== crPrevPropsString || cmSheetPropsString !== cmSheetPrevPropsString || paymentMethodPropsString !== prevpaymentMethodPropsString) && this.isMount) {
            this.populateCalculationsGrid();
            this.setProjectCurrency();
            this.calculateContractedEffort();
        }


    }

    getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.project !== prevState.project || nextProps.changeRequest !== prevState.changeRequest || nextProps.cmSheet !== prevState.cmSheet || nextProps.selectedPaymentMethod !== prevState.selectedPaymentMethod) {
            this.populateCalculationsGrid();
            this.populateCurrencies();
        }
    }

    populateCurrencies = async () => {
        await getCurrencies()
            .then(res => {
                if (res.data && res.data.length > 0 && res.data[0].id) {
                    if (this.isMount) {
                         this.setState({
                            currencies: res.data
                        });
                    }
                    this.setProjectCurrency();
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateResourceGroup = async () => {
        let list = [];
        let grp1 = 0;
        let grp2 = 0;
        let grp3 = 0;
        let grp4 = 0;
        let grp5 = 0;
        let grp6 = 0;
        let firstSeries = [];
        let finalSeries = [];
        let finalValue = null;

        await getResourceGroup()
            .then(res => {
                if (res.data && res.data.length > 0) {
                        for(const obj of res.data){
                            list.push(obj.name)
                        }

                    this.setState({
                        categories: list
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        if(this.props.cmSheet.ResourceAllocations.length > 0){
            for(const  resource of this.props.cmSheet.ResourceAllocations) {
                if(resource.BillableStatus === true){
                if (resource.ResourceGroup === 'Technical Consultants' || resource.ResourceGroup === 1 || (resource.dataValueResourceGrp ? resource.dataValueResourceGrp.value === 1 : false)) {
                    grp1 += resource.TotalEffortDays
                } else if (resource.ResourceGroup === 'Functional Consultants' || resource.ResourceGroup === 2 || (resource.dataValueResourceGrp ? resource.dataValueResourceGrp.value === 2 : false)) {
                    grp2 += resource.TotalEffortDays
                } else if (resource.ResourceGroup === 'System Consultants' || resource.ResourceGroup === 3 || (resource.dataValueResourceGrp ? resource.dataValueResourceGrp.value === 3 : false)) {
                    grp3 += resource.TotalEffortDays
                } else if (resource.ResourceGroup === 'Software Development – Dev' || resource.ResourceGroup === 4 || (resource.dataValueResourceGrp ? resource.dataValueResourceGrp.value === 4 : false)) {
                    grp4 += resource.TotalEffortDays
                } else if (resource.ResourceGroup === 'Software Development – QA' || resource.ResourceGroup === 5 || (resource.dataValueResourceGrp ? resource.dataValueResourceGrp.value === 5 : false)) {
                    grp5 += resource.TotalEffortDays
                } else if (resource.ResourceGroup === 'Other' || resource.ResourceGroup === 6 || (resource.dataValueResourceGrp ? resource.dataValueResourceGrp.value === 6 : false)) {
                    grp6 += resource.TotalEffortDays
                }
            }
            }

            firstSeries.push(grp1,grp2,grp3,grp4,grp5,grp6)

            console.log("==========firstSeries",firstSeries)

            for(let i = 0;i < firstSeries.length;i++){
                let obj = firstSeries[i]
                finalValue =  (((firstSeries[i] * this.props.project.TargetBillable) / ((this.props.cmSheet.TotalEffortDaysResourceAllocation > 0 ? (this.props.cmSheet.TotalEffortDaysResourceAllocation) : 0.00) * (this.props.project ?  this.props.project.TargetBillable : 0.00))) * 100).toFixed(2)
                finalSeries.push(finalValue);
            }

            console.log("=======finalSeries",finalSeries)

            await this.setState({
                firstSeries: finalSeries,
            });

        }


    };

    setProjectCurrency = async () => {
        if (this.state.dataValueCurrency === null) {
            let currentCurrency = null;
            if (this.props.project && this.props.project.Currency) {
                const filterCurrency = this.state.currencies.filter(
                    obj => obj.id === this.props.project.Currency
                );
                if (filterCurrency.length > 0) {
                    currentCurrency = filterCurrency[0];
                    console.log("======qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",currentCurrency)
                }
            }

            if (this.isMount) {
                await this.setState({
                    dataValueCurrency: currentCurrency
                });
            }
        }
    };

    handleCurrencyChange = async event => {
        const value = event.target.value;
        let proStartDate = new Date(this.props.cmSheet.createdAt);

        console.log("===========proStartDate11111",proStartDate)

            if (this.props.project && this.props.project.StartDate) {
                proStartDate = new Date(this.props.project.StartDate);
            }

        console.log("===========proStartDate2222222",proStartDate)

        const prevRate = await retrieveConversionRate(
            this.state.dataValueCurrency.id,
            proStartDate.getMonth() + 1,
            proStartDate.getFullYear()
        );

            console.log("==========prevRate",prevRate)

        if (prevRate > 0) {
            const newRate = await retrieveConversionRate(
                value.id,
                proStartDate.getMonth() + 1,
                proStartDate.getFullYear()
            );

            console.log("==========newRate",newRate)

            if (newRate > 0) {
                if (value) {
                    this.props.onCurrencyChange(prevRate, newRate, value.id);
                    if (this.isMount) {
                        this.setState({
                            dataValueCurrency: value
                        });
                    }
                }

            } else {
                const message = 'Currency conversion rate not found for selected currency';
                const title = 'Error';
                this.toggleMessageDialog(message, title);
            }

        } else {
            const message = 'Currency conversion rate not found for previously selected currency';
            const title = 'Error';
            this.toggleMessageDialog(message, title);
        }


    };

    populateCalculationsGrid = async () => {
        let totalShiftAllCost = 0;
        let totalJIRACost = 0;
        let totalTravelCost = 0;
        let totalInRegionCost = 0;
        let totalInCOECost = 0;
        let totalOtherCost = 0;
        let totalExpenseNonBillableCost = 0;
        let project = this.props.project

        if(this.props.cmSheet.OtherExpenses.length > 0){
            for(const  expense of this.props.cmSheet.OtherExpenses){

                if(expense.BillableToClient === false){

                    totalExpenseNonBillableCost += expense.Cost

                    if(expense.ExpenseTypeId === 12){
                        totalShiftAllCost += expense.Cost
                    }
                    else if(expense.ExpenseTypeId === 13){
                        totalJIRACost += expense.Cost
                    }
                    else if(expense.ExpenseTypeId === 14){
                        totalTravelCost += expense.Cost
                    }
                    else if(expense.ExpenseTypeId === 15){
                        totalInRegionCost += expense.Cost
                    }
                    else if(expense.ExpenseTypeId === 16){
                        totalInCOECost += expense.Cost
                    }
                    else{
                        totalOtherCost += expense.Cost
                    }

                }

                await this.setState(
                    {
                        TotalShiftAllCost: totalShiftAllCost,
                        TotalJIRACost: totalJIRACost,
                        TotalTravelCost: totalTravelCost,
                        TotalInRegionCost: totalInRegionCost,
                        TotalInCOECost: totalInCOECost,
                        TotalOtherCost: totalOtherCost,
                        TotalExpenseNonBillableCost: totalExpenseNonBillableCost,
                    },
                );

            }
        }

        const calculations = [
            {
                Field: 'Cost of Dedicated Time',
                Value1: '',
                isHeader: true
            },
            {
                Field: 'Deployable Resources',
                Value1: this.props.cmSheet.TotalEffortDaysResourceAllocation > 0 ? (this.props.cmSheet.TotalEffortDaysResourceAllocation).toFixed(2) : 0.00,
            },
            {
                Field: 'Non-Deployable Resources',
                Value1:  this.props.cmSheet.TotalNonDeployableResource > 0 ? this.props.cmSheet.TotalNonDeployableResource.toFixed(2) : 0.00,
            },
            {
                Field: 'Additional Other Expenses',
                Value1: this.state.TotalOtherCost,
                isHidden:this.state.TotalOtherCost > 0 ? false : true
            },
            {
                Field: 'COE other costs',
                Value1: this.state.TotalInCOECost,
                isHidden:this.state.TotalInCOECost > 0 ? false : true
            },
            {
                Field: 'In-region other costs',
                Value1: this.state.TotalInRegionCost,
                isHidden:this.state.TotalInRegionCost > 0 ? false : true
            },
            {
                Field: 'JIRA Licenses',
                Value1: this.state.TotalJIRACost,
                isHidden:this.state.TotalJIRACost > 0 ? false : true
            },
            {
                Field: 'Shift Allowances - Offshore',
                Value1: this.state.TotalShiftAllCost,
                isHidden:this.state.TotalShiftAllCost > 0 ? false : true
            },
            {
                Field: 'Travel Allowances - Offshore',
                Value1: this.state.TotalTravelCost,
                isHidden:this.state.TotalTravelCost > 0 ? false : true
            },
            {
                Field: 'Total Cost Excluding Sales Commission',
                Value1: ((this.props.cmSheet.TotalEffortDaysResourceAllocation > 0 ? (this.props.cmSheet.TotalEffortDaysResourceAllocation) : 0.00) +
                    (this.props.cmSheet.TotalEffortDaysResourceAllocationNonBill > 0 ? this.props.cmSheet.TotalEffortDaysResourceAllocationNonBill : 0.00) + this.state.TotalExpenseNonBillableCost).toFixed(2),
                isHeader: true
            },
            {
                Field: 'Available Capacity at Target Billable Utilization (Days)',
                Value1: ((this.props.cmSheet.TotalEffortDaysResourceAllocation > 0 ? (this.props.cmSheet.TotalEffortDaysResourceAllocation) : 0.00) * (project ? await project.TargetBillable/100 : 0.00)).toFixed(2),
                isHeader: true
            },
            {
                Field: 'Net Profit/Loss',
                Value1: this.state.netProfitLoss !== 0 ? this.state.netProfitLoss.toFixed(2) : 0.00,
                isHeader: true
            },
            {
                Field: 'Net Profit Margin',
                Value1: this.state.netProfitMargin !== 0 ? this.state.netProfitMargin.toFixed(2) : 0.00,
                isHeader: true
            }
        ];

        const calculationTemp = [];
        for (var item of calculations) {
            if (item.isHidden == undefined) {
                calculationTemp.push(item);
            } else if (!item.isHidden) {
                calculationTemp.push(item);
            }
        }
        this.setState(
            {
                calculations: calculationTemp
            },
            () => {
                this.forceUpdate();
            }
        );
    };

    calculateLineStartDate = async (dataItem,data) => {
        let projectStartDate = null;
        projectStartDate = data.StartDate

        if(dataItem.StartDate === null || dataItem.StartDate === ''){
            if(dataItem.Phase && dataItem.Phase.StartDate){
                let value = dataItem.Phase;
                if(value.StartDate < projectStartDate){
                    dataItem.StartDate = null;
                    return dataItem.StartDate;
                }
                else {
                    return value.StartDate;
                }
            }
            else if (dataItem.SubPhase && dataItem.SubPhase.StartDate){
                let value = dataItem.SubPhase;
                if(value.StartDate < projectStartDate){
                    dataItem.StartDate = null;
                    return dataItem.StartDate;
                }
                else {
                    return value.StartDate;
                }
            }
            else {
                return projectStartDate;
            }
        } else{
            if(dataItem.StartDate < projectStartDate){
                return dataItem.StartDate;
            } else {
                return dataItem.StartDate;
            }
        }
    }

    calculateNewEstimatedEndDate = async (workingDays, startDate, countryId) => {
        let startDateObj = new Date(startDate);
        let endDate;

        // estimate end date
        const noOfWorkingDays = workingDays;

        let totalDays = 0;
        let workingDaysCount = 0;

        let holidays = await getHolidaysIntegrated(countryId, startDateObj.getFullYear()).then(
            res => {
                if (res && res.data) {
                    return res.data;
                }
                return [];
            }
        )
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        if (holidays && holidays.length === 0) {
            console.log('Holidays not found for ' + startDateObj.getFullYear());
        } else if (!holidays) {
            holidays = [];
        }

        endDate = new Date(startDate);
        let currentYear = startDateObj.getFullYear();
        let isHoliday = false;

        while (workingDaysCount < noOfWorkingDays - 1 || isWeekend(endDate) || isHoliday) {
            if (currentYear !== endDate.getFullYear()) {
                holidays = await getHolidaysIntegrated(countryId, endDate.getFullYear())
                    .then(res => {
                        if (res && res.data) {
                            return res.data;
                        }
                        return [];
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        return [];
                    });

                currentYear = endDate.getFullYear();
            }

            totalDays++;
            endDate.setDate(endDate.getDate() + 1);

            isHoliday = false;
            const dateStr = getFormattedDate(endDate);
            if (holidays.indexOf(dateStr) > -1) {
                isHoliday = true;
            }

            if (!isWeekend(endDate) && !isHoliday) {
                workingDaysCount++;
            }
        }

        endDate.setDate(endDate.getDate());
        return { endDate, noOfWorkingDays, totalDays };
    };


    calculateLineEndDate = async (dataItem, selectedLine,startDate, generalCountry) => {

        let countryId;
        let totalEffortDays;

        if(selectedLine.EndDate === null || selectedLine.EndDate === ""){
            totalEffortDays = (selectedLine.EffortDays/((selectedLine.AllocationPercentage ? selectedLine.AllocationPercentage : selectedLine.AllocationPercentag) /100));
            if (selectedLine.OnsiteStatus === 'Onsite') {
                countryId = dataItem.CountryId;
            } else {
                countryId = selectedLine.dataValueResourceRe;
            }
            let { endDate, noOfWorkingDays, totalDays } =  await this.calculateNewEstimatedEndDate(totalEffortDays,moment(startDate).format('YYYY-MM-DD'), generalCountry);
            endDate = moment(endDate).format('YYYY-MM-DD')
            return endDate;
        } else {
            return selectedLine.EndDate;
        }
    };

    calculateTotalAvailableTime = async (selectedResourceMixLine, allocationPercentage, ResourceDetails,IsProject) => {

        let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
        let isHoliday = false;
        let resourceCountryId;
        if (selectedResourceMixLine && selectedResourceMixLine.StartDate && selectedResourceMixLine.EndDate && ResourceDetails) {
            let startDate = new Date(selectedResourceMixLine.StartDate);
            let endDate = new Date(selectedResourceMixLine.EndDate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            var TotalAvailableTime = 0;
            if (selectedResourceMixLine.OnsiteStatus === 'Offshore') { // offshore

                if(IsProject === true){
                     resourceCountryId = ResourceDetails ? ResourceDetails.Region.DefaultCountryId : null;
                }
                else{
                     resourceCountryId = selectedResourceMixLine ? selectedResourceMixLine.dataValueResourceRe : null;
                }

                let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if (holidays && holidays.length === 0) {
                } else if (!holidays) {
                    holidays = [];
                }

                let loop = new Date(startDate);
                while (loop <= endDate) {
                    isHoliday = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (!isHoliday && !isWeekend(loop)) {
                        totalWorkingDays++;
                    } else {
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

                TotalAvailableTime = (totalWorkingDays * parseFloat(allocationPercentage))/100;


            }
            else if (selectedResourceMixLine.OnsiteStatus === 'Onsite') {
                const resourceCountryId = ResourceDetails ? ResourceDetails.CountryId : null;
                let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if (holidays && holidays.length === 0) {
                } else if (!holidays) {
                    holidays = [];
                }

                let loop = new Date(startDate);
                while (loop <= endDate) {
                    isHoliday = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (!isHoliday && !isWeekend(loop)) {
                        totalWorkingDays++;
                    } else {
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }


                TotalAvailableTime = (totalWorkingDays * parseFloat(allocationPercentage))/100;

            }

            return TotalAvailableTime;

        } else {
            return null;
        }
    };

    getAvailableDates = async (StartDate, EndDate, allocationPercentage, selectedLine, dataItem,IsProject) => {

        let rStartDate = (StartDate !== null ? moment(StartDate) : null);
        let rEndDate = (EndDate !== null ? moment(EndDate) : null);
        let generalCountry = dataItem ? dataItem.CountryId : null;

        let calculatedStartDate = null;
        if (selectedLine.StartDate) {
            calculatedStartDate = selectedLine.StartDate;
        } else {
            calculatedStartDate = await this.calculateLineStartDate(selectedLine,dataItem);
        }

        let calculatedEndDate = null;
        if (selectedLine.EndDate !== null) {
            calculatedEndDate = selectedLine.EndDate;
        }
        else {
            calculatedEndDate = await this.calculateLineEndDate(dataItem,selectedLine, calculatedStartDate, generalCountry);
        }

        let lineStartDate = moment(calculatedStartDate ? calculatedStartDate : null);
        let lineEndDate = moment(calculatedEndDate ? calculatedEndDate : null);

        let confirmedStartDate = null;
        let confirmedEndDate = null;

        if (lineStartDate.diff(rStartDate) >= 0 && lineEndDate.diff(rEndDate) <= 0) {
            //RL start date and end date is out of range
            confirmedStartDate = lineStartDate;
            confirmedEndDate = lineEndDate;
        } else if(lineStartDate.diff(rStartDate) <= 0 && lineEndDate.diff(rEndDate) >= 0) {
            //RL start date and end date is in range
            confirmedStartDate = rStartDate;
            confirmedEndDate = rEndDate;
        } else if((lineStartDate.diff(rStartDate) >= 0 && lineStartDate.diff(rEndDate) <= 0) && lineEndDate.diff(rEndDate) >= 0) {
            //RL start date out of range and end date is in range
            confirmedStartDate = lineStartDate;
            confirmedEndDate = rEndDate;
        } else if((lineEndDate.diff(rStartDate) >= 0 && lineEndDate.diff(rEndDate) <= 0) && lineStartDate.diff(rStartDate) <= 0) {
            //RL start date is in range and end date is out of range
            confirmedStartDate = rStartDate;
            confirmedEndDate = lineEndDate;
        }


        // Get available days without holidays
        var totalAvailableDays = 0;
        if (confirmedStartDate !== null && confirmedEndDate !== null) {
            let copySelectedLine = Object.assign({}, selectedLine);
            copySelectedLine.StartDate = confirmedStartDate;
            copySelectedLine.EndDate = confirmedEndDate;
            totalAvailableDays = await this.calculateTotalAvailableTime(copySelectedLine, allocationPercentage, dataItem,IsProject);
        }

        // Calculate the date diff
        return totalAvailableDays;
    };

    calculateContractedEffort = async () => {

            let resourceAllocations = [];
            let cmsheets = [];
            let startDate = new Date();
            let endDate = new Date();
            let contractedEffort = 0;
            let revenue = 0;
            let totalCost = 0;
            let IsProject;
            let projectsArray = [];
            let grp1 = 0;
            let grp2 = 0;
            let grp3 = 0;
            let grp4 = 0;
            let grp5 = 0;
            let grp6 = 0;
            let firstSeries = [];
            let finalSeries = [];
            let finalValue = 0;


        const customerProjects = this.props.customerProjects;

        if(this.props.cmSheet.ResourceAllocations.length > 0){
            for(const  resource of this.props.cmSheet.ResourceAllocations){
                if(resource.ResourceGroup === 'Technical Consultants' || resource.ResourceGroup === 1){
                    grp1 += resource.TotalEffortDays
                }
                else if(resource.ResourceGroup === 'Functional Consultants' || resource.ResourceGroup === 2){
                    grp2 += resource.TotalEffortDays
                }
                else if(resource.ResourceGroup === 'System Consultants' || resource.ResourceGroup === 3){
                    grp3 += resource.TotalEffortDays
                }
                else if(resource.ResourceGroup === 'Software Development – Dev' || resource.ResourceGroup === 4){
                    grp4 += resource.TotalEffortDays
                }
                else if(resource.ResourceGroup === 'Software Development – QA' || resource.ResourceGroup === 5){
                    grp5 += resource.TotalEffortDays
                }
                else if(resource.ResourceGroup === 'Other' || resource.ResourceGroup === 6){
                    grp6 += resource.TotalEffortDays
                }
            }

            firstSeries.push(grp1,grp2,grp3,grp4,grp5,grp6)

            for(let i = 0;i < firstSeries.length;i++){
                finalValue =  (((firstSeries[i] * this.props.project.TargetBillable) / ((this.props.cmSheet.TotalEffortDaysResourceAllocation > 0 ? (this.props.cmSheet.TotalEffortDaysResourceAllocation) : 0.00) * (this.props.project ?  this.props.project.TargetBillable : 0.00))) * 100).toFixed(2)

                finalSeries.push(finalValue);
            }

            await this.setState({
                firstSeries: finalSeries,
            });
        }

        if(this.props.project){
            let financialYear = this.props.project.FinancialYear
            startDate.setFullYear(financialYear,3,1)
            endDate.setFullYear(financialYear + 1,2,31)
        }

        else if(this.props.changeRequest){
            let financialYear = this.props.changeRequest.FinancialYear
            startDate.setFullYear(financialYear,3,1)
            endDate.setFullYear(financialYear + 1,2,31)
        }

        for(const project of customerProjects){

            if(project.ChangeRequests.length > 0){

                // customer CR
                IsProject = false;
                resourceAllocations = project.ChangeRequests[0].CMSheets[0].ResourceAllocations
                cmsheets = project.ChangeRequests[0].CMSheets[0]

               if(resourceAllocations.length > 0){
                   for(const  resource of resourceAllocations){
                       contractedEffort += await this.getAvailableDates(startDate,endDate,resource.AllocationPercentag,resource,project.ChangeRequests[0],IsProject);
                       revenue += (contractedEffort * resource.RateValue)
                   }
                   project.ChangeRequests[0].contractedEffort = contractedEffort;

                   project.ChangeRequests[0].totalRevenue = ((revenue * (1+(cmsheets.ContingencyPercentage/100)) * (1-(cmsheets.DiscountPercentage/100)))).toFixed(2);
                   project.ChangeRequests[0].allocEffort = ((contractedEffort/((cmsheets.TotalEffortDaysResourceAllocation > 0 ? (cmsheets.TotalEffortDaysResourceAllocation) : 0.00) * this.props.project.TargetBillable).toFixed(2)) * 100).toFixed(2);
                   project.ChangeRequests[0].totalCost =  Number.isNaN(((cmsheets.TotalCostResourceAllocation / cmsheets.TotalEffortDaysResourceAllocation) * contractedEffort)) ?
                       0.00 : Number(((cmsheets.TotalCostResourceAllocation / cmsheets.TotalEffortDaysResourceAllocation) * contractedEffort))
                   project.ChangeRequests[0].dailyRate = ((project.ChangeRequests[0].totalRevenue)/project.ChangeRequests[0].contractedEffort).toFixed(2);
               }
               else{
                   project.ChangeRequests[0].contractedEffort = 0
                   project.ChangeRequests[0].totalRevenue = 0;
                   project.ChangeRequests[0].allocEffort = 0;
                   project.ChangeRequests[0].totalCost = 0;
                   project.ChangeRequests[0].dailyRate = 0;

               }
            }
        else{
                // customer Projects
                IsProject = true;
                resourceAllocations = project.CMSheets[0].ResourceAllocations
                cmsheets = project.CMSheets[0]

                if(resourceAllocations.length > 0){
                    for(const  resource of resourceAllocations){
                        contractedEffort += await this.getAvailableDates(startDate,endDate,resource.AllocationPercentage,resource,project,IsProject);
                        revenue += (contractedEffort * resource.RateValue)
                    }

                    //const projectObj = JSON.parse(JSON.stringify(project));
                    project.contractedEffort = contractedEffort.toFixed(2);
                    project.totalRevenue = ((revenue * (1+(cmsheets.ContingencyPercentage/100)) * (1-(cmsheets.DiscountPercentage/100)))).toFixed(2);
                    project.allocEffort = ((contractedEffort/this.props.project.TargetBillable) * 100).toFixed(2);
                    project.totalCost =  Number.isNaN(((cmsheets.TotalCostResourceAllocation / cmsheets.TotalEffortDaysResourceAllocation) * contractedEffort)) ?
                        0.00 : Number(((cmsheets.TotalCostResourceAllocation / cmsheets.TotalEffortDaysResourceAllocation) * contractedEffort))
                    project.dailyRate = ((project.totalRevenue)/project.contractedEffort).toFixed(2);
                }
                else{
                    project.contractedEffort = 0
                    project.totalRevenue = 0
                    project.allocEffort = 0
                    project.totalCost = 0
                    project.dailyRate = 0
                }
            }

            const projectObj = JSON.parse(JSON.stringify(project));

            projectsArray.push(projectObj);

        }
           await this.setState({ customerProjects: projectsArray }, );
    }

    headerSelectionChange = async (event) => {
        await this.setState({
            isLoading: true
        });

        console.log("=============lllllllllllllllllllllllll",this.state.isLoading)

        event.dataItem.Selected = !event.dataItem.Selected;
        let totalRevenue = 0;
        let totalContractedEffort = 0;
        let totalSalesCommission = 0;
        let totalNonBillableExpenses = 0;
        let costContingency = 0;
        let netProfitLoss = 0;
        let netProfitMargin = 0;

        const index = this.state.customerProjects.indexOf(event.dataItem);

        if (event.dataItem.Selected === true) {
            this.ticked.push(index);
        } else {
            this.ticked.splice(this.ticked.indexOf(index), 1);
        }

        console.log("==========this.ticked",this.ticked)

        if(this.ticked.length > 0){
            for(let i = 0 ;i < this.ticked.length;i++){
                const selectedResource = JSON.parse(JSON.stringify(this.state.customerProjects[this.ticked[i]]));

                console.log("===========selectedResource",selectedResource)

                if(selectedResource.ChangeRequests.length > 0){
                    let cmsheet = selectedResource.ChangeRequests[0].CMSheets[0]


                    //Total Revenue
                    totalRevenue += Number(selectedResource.ChangeRequests[0].totalRevenue)

                    //Total Contract Effort
                    totalContractedEffort += Number(selectedResource.ChangeRequests[0].contractedEffort)

                    //Total Sales Commission
                    totalSalesCommission += Number.isNaN(((cmsheet.ProjectRevenueAfterDiscount * cmsheet.SalesCommissionPercentage) *
                        (selectedResource.ChangeRequests[0].totalRevenue))
                        / (cmsheet.TotalRevenueResourceAllocation)) ? 0.00 :
                        Number(((cmsheet.ProjectRevenueAfterDiscount * cmsheet.SalesCommissionPercentage) *
                            (selectedResource.ChangeRequests[0].totalRevenue))
                            / (cmsheet.TotalRevenueResourceAllocation))

                    //Total Non-Billable Other Expenses
                    totalNonBillableExpenses += Number.isNaN((cmsheet.NonBillableTotalOtherExpenses * selectedResource.ChangeRequests[0].contractedEffort) / cmsheet.TotalEffortDaysResourceAllocation)
                        ? 0.00 : Number((cmsheet.NonBillableTotalOtherExpenses * selectedResource.ChangeRequests[0].contractedEffort) / cmsheet.TotalEffortDaysResourceAllocation)

                    //Cost Contingency
                    costContingency +=  Number.isNaN(((cmsheet.TotalCostResourceAllocation / cmsheet.TotalEffortDaysResourceAllocation) * selectedResource.ChangeRequests[0].contractedEffort) * (cmsheet.ContingencyPercentage / 100)) ?
                        0.00 : Number(((cmsheet.TotalCostResourceAllocation / cmsheet.TotalEffortDaysResourceAllocation) * selectedResource.ChangeRequests[0].contractedEffort) * (cmsheet.ContingencyPercentage / 100))

                    console.log("=====costContingency...",costContingency)
                }
                else{
                    //Total Revenue
                    totalRevenue += Number(selectedResource.totalRevenue)

                    //Total Contract Effort
                    totalContractedEffort += Number(selectedResource.contractedEffort)

                    //Total Sales Commission
                    totalSalesCommission += Number.isNaN(((selectedResource.CMSheets[0].ProjectRevenueAfterDiscount * selectedResource.CMSheets[0].SalesCommissionPercentage) * (selectedResource.totalRevenue))
                        / (selectedResource.CMSheets[0].TotalRevenueResourceAllocation)) ? 0.00 :
                        Number(((selectedResource.CMSheets[0].ProjectRevenueAfterDiscount * selectedResource.CMSheets[0].SalesCommissionPercentage) * (selectedResource.totalRevenue))
                            / (selectedResource.CMSheets[0].TotalRevenueResourceAllocation))

                    //Total Non-Billable Other Expenses
                    totalNonBillableExpenses += Number.isNaN((selectedResource.CMSheets[0].NonBillableTotalOtherExpenses * selectedResource.contractedEffort) / selectedResource.CMSheets[0].TotalEffortDaysResourceAllocation)
                        ? 0.00 : Number((selectedResource.CMSheets[0].NonBillableTotalOtherExpenses * selectedResource.contractedEffort) / selectedResource.CMSheets[0].TotalEffortDaysResourceAllocation)

                    //Cost Contingency
                    costContingency +=  Number.isNaN(((selectedResource.CMSheets[0].TotalCostResourceAllocation / selectedResource.CMSheets[0].TotalEffortDaysResourceAllocation) * selectedResource.contractedEffort) * (selectedResource.CMSheets[0].ContingencyPercentage / 100)) ?
                        0.00 : Number(((selectedResource.CMSheets[0].TotalCostResourceAllocation / selectedResource.CMSheets[0].TotalEffortDaysResourceAllocation) * selectedResource.contractedEffort) * (selectedResource.CMSheets[0].ContingencyPercentage / 100))

                }
            }

            console.log("=============totalRevenue",totalRevenue)
            console.log("=============totalSalesCommission",totalSalesCommission)
            console.log("=============totalNonBillableExpenses",totalNonBillableExpenses)
            console.log("=============costContingency",costContingency)

            //Net Profit/ Loss
            netProfitLoss = totalRevenue - (((this.props.cmSheet.TotalEffortDaysResourceAllocation > 0 ? (this.props.cmSheet.TotalEffortDaysResourceAllocation) : 0.00) +
                (this.props.cmSheet.TotalEffortDaysResourceAllocationNonBill > 0 ? this.props.cmSheet.TotalEffortDaysResourceAllocationNonBill : 0.00) + this.state.TotalExpenseNonBillableCost).toFixed(2)) - (totalSalesCommission + totalNonBillableExpenses + costContingency)

            //Net Profit Margin
            netProfitMargin = netProfitLoss !== 0 ? (netProfitLoss / totalRevenue) * 100 : 0.00;

            console.log("=============netProfitLoss",netProfitLoss)
            console.log("=============netProfitMargin",netProfitMargin)

            await this.setState({
                netProfitLoss: netProfitLoss,
                netProfitMargin: netProfitMargin,
                selectedTotalRevenue: totalRevenue,
                selectedTotalContractedEffort: totalContractedEffort
            });

            console.log("=========ssssssssssssssssss",this.state.netProfitLoss)

        }

        else {
            await this.setState({
                netProfitLoss: 0,
                netProfitMargin: 0,
                selectedTotalRevenue: 0,
                selectedTotalContractedEffort: 0
            });
        }

        //dedicated team grid update based on selection
        this.state.calculations[6].Value1 = this.state.netProfitLoss
        this.state.calculations[7].Value1 = this.state.netProfitMargin

        await this.setState({
            isLoading:false
        });
    };

    selectionChange = async (event) => {
        await this.setState({
            isLoading: true
        });

        console.log("=============lllllllllllllllllllllllll",this.state.isLoading)

        event.dataItem.Selected = !event.dataItem.Selected;
        let totalRevenue = 0;
        let totalContractedEffort = 0;
        let totalSalesCommission = 0;
        let totalNonBillableExpenses = 0;
        let costContingency = 0;
        let netProfitLoss = 0;
        let netProfitMargin = 0;

        const index = this.state.customerProjects.indexOf(event.dataItem);

        if (event.dataItem.Selected === true) {
            this.ticked.push(index);
        } else {
            this.ticked.splice(this.ticked.indexOf(index), 1);
        }

        console.log("==========this.ticked",this.ticked)

        if(this.ticked.length > 0){
            for(let i = 0 ;i < this.ticked.length;i++){
                const selectedResource = JSON.parse(JSON.stringify(this.state.customerProjects[this.ticked[i]]));

                console.log("===========selectedResource",selectedResource)

                if(selectedResource.ChangeRequests.length > 0){
                    let cmsheet = selectedResource.ChangeRequests[0].CMSheets[0]


                    //Total Revenue
                    totalRevenue += Number(selectedResource.ChangeRequests[0].totalRevenue)

                    //Total Contract Effort
                    totalContractedEffort += Number(selectedResource.ChangeRequests[0].contractedEffort)

                    //Total Sales Commission
                    totalSalesCommission += Number.isNaN(((cmsheet.ProjectRevenueAfterDiscount * cmsheet.SalesCommissionPercentage) *
                        (selectedResource.ChangeRequests[0].totalRevenue))
                        / (cmsheet.TotalRevenueResourceAllocation)) ? 0.00 :
                        Number(((cmsheet.ProjectRevenueAfterDiscount * cmsheet.SalesCommissionPercentage) *
                            (selectedResource.ChangeRequests[0].totalRevenue))
                            / (cmsheet.TotalRevenueResourceAllocation))

                    //Total Non-Billable Other Expenses
                    totalNonBillableExpenses += Number.isNaN((cmsheet.NonBillableTotalOtherExpenses * selectedResource.ChangeRequests[0].contractedEffort) / cmsheet.TotalEffortDaysResourceAllocation)
                    ? 0.00 : Number((cmsheet.NonBillableTotalOtherExpenses * selectedResource.ChangeRequests[0].contractedEffort) / cmsheet.TotalEffortDaysResourceAllocation)

                    //Cost Contingency
                    costContingency +=  Number.isNaN(((cmsheet.TotalCostResourceAllocation / cmsheet.TotalEffortDaysResourceAllocation) * selectedResource.ChangeRequests[0].contractedEffort) * (cmsheet.ContingencyPercentage / 100)) ?
                        0.00 : Number(((cmsheet.TotalCostResourceAllocation / cmsheet.TotalEffortDaysResourceAllocation) * selectedResource.ChangeRequests[0].contractedEffort) * (cmsheet.ContingencyPercentage / 100))

                    console.log("=====costContingency...",costContingency)
                }
                else{
                    //Total Revenue
                    totalRevenue += Number(selectedResource.totalRevenue)

                    //Total Contract Effort
                    totalContractedEffort += Number(selectedResource.contractedEffort)

                    //Total Sales Commission
                    totalSalesCommission += Number.isNaN(((selectedResource.CMSheets[0].ProjectRevenueAfterDiscount * selectedResource.CMSheets[0].SalesCommissionPercentage) * (selectedResource.totalRevenue))
                        / (selectedResource.CMSheets[0].TotalRevenueResourceAllocation)) ? 0.00 :
                        Number(((selectedResource.CMSheets[0].ProjectRevenueAfterDiscount * selectedResource.CMSheets[0].SalesCommissionPercentage) * (selectedResource.totalRevenue))
                            / (selectedResource.CMSheets[0].TotalRevenueResourceAllocation))

                    //Total Non-Billable Other Expenses
                    totalNonBillableExpenses += Number.isNaN((selectedResource.CMSheets[0].NonBillableTotalOtherExpenses * selectedResource.contractedEffort) / selectedResource.CMSheets[0].TotalEffortDaysResourceAllocation)
                        ? 0.00 : Number((selectedResource.CMSheets[0].NonBillableTotalOtherExpenses * selectedResource.contractedEffort) / selectedResource.CMSheets[0].TotalEffortDaysResourceAllocation)

                    //Cost Contingency
                    costContingency +=  Number.isNaN(((selectedResource.CMSheets[0].TotalCostResourceAllocation / selectedResource.CMSheets[0].TotalEffortDaysResourceAllocation) * selectedResource.contractedEffort) * (selectedResource.CMSheets[0].ContingencyPercentage / 100)) ?
                        0.00 : Number(((selectedResource.CMSheets[0].TotalCostResourceAllocation / selectedResource.CMSheets[0].TotalEffortDaysResourceAllocation) * selectedResource.contractedEffort) * (selectedResource.CMSheets[0].ContingencyPercentage / 100))

                }
            }

            console.log("=============totalRevenue",totalRevenue)
            console.log("=============totalSalesCommission",totalSalesCommission)
            console.log("=============totalNonBillableExpenses",totalNonBillableExpenses)
            console.log("=============costContingency",costContingency)

            //Net Profit/ Loss
            netProfitLoss = totalRevenue - (((this.props.cmSheet.TotalEffortDaysResourceAllocation > 0 ? (this.props.cmSheet.TotalEffortDaysResourceAllocation) : 0.00) +
                (this.props.cmSheet.TotalEffortDaysResourceAllocationNonBill > 0 ? this.props.cmSheet.TotalEffortDaysResourceAllocationNonBill : 0.00) + this.state.TotalExpenseNonBillableCost).toFixed(2)) - (totalSalesCommission + totalNonBillableExpenses + costContingency)

            //Net Profit Margin
            netProfitMargin = netProfitLoss !== 0 ? (netProfitLoss / totalRevenue) * 100 : 0.00;

            console.log("=============netProfitLoss",netProfitLoss)
            console.log("=============netProfitMargin",netProfitMargin)

            await this.setState({
                netProfitLoss: netProfitLoss,
                netProfitMargin: netProfitMargin,
                selectedTotalRevenue: totalRevenue,
                selectedTotalContractedEffort: totalContractedEffort
            });

            console.log("=========ssssssssssssssssss",this.state.netProfitLoss)

        }

        else {
            await this.setState({
                netProfitLoss: 0,
                netProfitMargin: 0,
                selectedTotalRevenue: 0,
                selectedTotalContractedEffort: 0
            });
        }

        //dedicated team grid update based on selection
        this.state.calculations[6].Value1 = this.state.netProfitLoss
        this.state.calculations[7].Value1 = this.state.netProfitMargin

        await this.setState({
            isLoading:false
        });
    };

    render() {

        const ChartContainer = () => (
            <Chart>
                <ChartTitle text="Effort"/>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem categories={this.state.categories}>
                        <ChartCategoryAxisTitle text="Resource"/>
                    </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartSeries>
                    <ChartSeriesItem type="bar" gap={2} spacing={0.25} data={this.state.firstSeries}/>

                </ChartSeries>
            </Chart>
        );
        return (

            <div>
                <div>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="" className="mandatory">
                                Currency:
                            </label>
                        </div>
                        <div className="col-md-4">
                            <DropDownList
                                data={this.state.currencies}
                                textField="Code"
                                dataItemKey="id"
                                value={this.state.dataValueCurrency}
                                onChange={this.handleCurrencyChange}
                            />
                        </div>
                    </div>
                    <br />
                </div>

                <div className="row">
                    <div className="col-md-7">
                        <div className="cx-projects mb-2">
                            <Grid
                                style={{height: '400px'}}
                                selectedField="selected"
                                onSelectionChange={this.selectionChange}
                                onHeaderSelectionChange={this.headerSelectionChange}
                                onRowClick={this.rowClick}
                                data={this.state.customerProjects.slice(
                                    this.state.skip,
                                    this.state.take + this.state.skip
                                )}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.customerProjects.length}
                            >
                                <Column
                                    field="selected"
                                    width="50px"
                                />

                                <Column title="Customer Projects"
                                        cell={props => <CustomerProjectsCell {...props} />}
                                       />
                                <Column title="Customer"
                                        cell={props => <CustomerCell {...props} />}
                                       />
                                <Column title="Status"
                                        cell={props => <CustomerStatusCell {...props} />}
                                       />
                                <Column title="Contracted Effort"
                                        cell={props => <EffortCell {...props}/>}
                                       />

                                <Column field="" title="Allocated Effort %"
                                        cell={props => <AllocationCell {...props}/>}
                                />

                                <Column field="" title="Revenue"
                                        cell={props => <RevenueCell {...props}/>}
                                />

                                <Column field="" title="Daily Rate"
                                        cell={props =><DailyRateCell  {...props}/>}/>

                            </Grid>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                        <label>Selected Customer Project/CR Total Contracted Effort:</label>
                                    </div>
                            <div className="col-md-2">
                                <Input value={this.state.selectedTotalContractedEffort} disabled = {true}/>
                            </div>

                            <div className="col-md-4">
                                <label>Selected Customer Project/CR Total Revenue:</label>
                            </div>
                            <div className="col-md-2">
                            <Input value={this.state.selectedTotalRevenue} disabled={true} />
                            </div>
                        </div>

                    </div>
                    <div className="col-md-5">
                        <Grid width="100%" data={this.state.calculations} rowRender={SummaryPoolProject.rowRender} className="summary-grid">
                            <Column
                                field="Field"
                                title="Field"
                                width="300px"
                                headerClassName="calculation-grid-header"
                            />
                            <Column field="Value1" headerClassName="calculation-grid-header"/>
                        </Grid>
                    </div>
                </div>
                <div className="col-md-7">
                    <ChartContainer/>
                </div>

                <Loader loading={this.state.isLoading}/>
            </div>



        );
    }

    toggleMessageDialog = (message, title) => {
        if (this.isMount) {
            this.setState({
                showMessageDialog: !this.state.showMessageDialog,
                dialogMessage: message,
                dialogTitle: title
            });
        }
    };
}

export default SummaryPoolProject;
