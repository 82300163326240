import React from 'react'

const Iframe = ({ source }) => {
  if (!source) {
    return <div>Loading...</div>
  }

  const src = source
  return (
    <div>
      <div>
        <iframe
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            border: 'none'
          }}
          src={src}
        />
      </div>
    </div>
  )
}

export default Iframe
