import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class RegionActionsCell extends Component {
  render() {
    const { OnDeactivate } = this.props;
    const { OnActivate } = this.props;
    const regionStatus = this.props.dataItem.IsActive;
    const region = this.props.dataItem;
    return (
      <td className="action-buttons">
        {regionStatus === true && (window.USER_PERMISSION.indexOf('EDT_RGN') > -1) && (
          <Link
            to={{
              pathname: '/admin/region/createregion',
              action: 'edit',
              region: region
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-edit" title="Edit" />
            </div>
          </Link>
        )}

        {regionStatus === true && (window.USER_PERMISSION.indexOf('EDT_RGN') > -1) && (
          <Link to={this.props} onClick={event => OnDeactivate(event, this.props.dataItem.id)}>
            <div className="rounded-link">
              <span className="k-icon k-i-unsort" title="Deactivate" />
            </div>
          </Link>
        )}

        {regionStatus === false && (window.USER_PERMISSION.indexOf('EDT_RGN') > -1) && (
          <Link
            to={this.props}
            onClick={event => this.props.OnActivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-check" title="Activate" />
            </div>
          </Link>
        )}
      </td>
    );
  }
}

export default RegionActionsCell;
