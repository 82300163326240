import React, { Component } from 'react';

class MultiValueTableCell extends Component {
    
    render() {
        
        var values = this.props.values;

        return (
            <td>
               {values.join(', ')}
            </td>
        )
        
    }
    
}

export default MultiValueTableCell;
