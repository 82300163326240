/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';

class RowCellComboBox extends Component {
    popupSet = {
        width: 'auto'
    };

    handleChange = event => {
        //this.state.isChangeValue = true;
        this.props.onChangeSelection(event, this.props.dataItem,true,true,true);
    };

    handleFilterChange = event => {
        this.props.filterChangeCombo(event, this.props.dataItem);
    };

    render() {
        let addClass = false;
        if (!this.props.dataItem.inEdit) {
            return (
                <td>
                    {this.props.dataItem[this.props.dataValueField]
                        ? this.props.dataItem[this.props.dataValueField][this.props.textField]
                        : "N/A"}
                </td>
            );
        }

        if(this.props.dataItem.dataValueRateType === null || !this.props.dataItem.dataValueRateType || this.props.dataItem.dataValueCostDesignation===null ||
            !this.props.dataItem.dataValueCostDesignation){
            addClass = false;
        }
        else if(this.props.dataItem.dataValueRateType.Name === 'Override Rate' || this.props.dataItem.dataValueRateType.Name === 'Non-Billable/ Admin Rate'
            || this.props.dataItem.dataValueCostDesignation.Name === 'Outsourced'){
            addClass = true;
        }

      return (
            <td>

                <ComboBox className={( this.props.dataValueField=== 'dataValueRateType'  && addClass === true && this.props.dataItem.dataValueRateType!= null && this.props.dataItem.dataValueRateType.Name === 'Override Rate' ? 'badge badge-danger' : 'hidden'
                    && this.props.dataValueField=== 'dataValueRateType' && addClass === true && this.props.dataItem.dataValueRateType!= null && this.props.dataItem.dataValueRateType.Name === 'Non-Billable/ Admin Rate' ? 'badge badge-warning' : 'hidden'
                    && this.props.dataValueField=== 'dataValueCostDesignation' && this.props.dataItem.dataValueCostDesignation!= null && this.props.dataItem.dataValueCostDesignation.Name === 'Outsourced' ? 'badge badge-primary' : 'hidden'
                    && this.props.dataItem.autoPopulatedValueChaged === true && (this.props.dataItem.changedFields.indexOf(this.props.dataValueField) > -1)? 'badge badge-primary' : 'hidden'
                )}
                          data={this.props.dataItem[this.props.dataList]}
                          textField={this.props.textField}
                          dataItemKey={this.props.dataItemKey}
                          value={this.props.dataItem[this.props.dataValueField]}
                          onChange={this.handleChange}
                          name={this.props.name}
                          placeholder={this.props.comboBoxPlaceHolder}
                          filterable={this.props.canFilter}
                          onFilterChange={this.handleFilterChange}
                          disabled={this.props.disabled}
                          required={this.props.required}
                />
            </td>
        );
    }
}

export default RowCellComboBox;
