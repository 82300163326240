import React, { Component, useState } from "react";

class SearchResultsFilesCell extends Component{
    render(){
        const apiUrl = this.props.ApiUrl;
        const proOrCRId = this.props.dataItem.proOrCRId;
        const fileUploads = this.props.dataItem.fileUpload;
        const activityLogData = this.props.dataItem;
        const name = this.props.name;

        return(
            <td className="">
                {fileUploads ? fileUploads.map((item, index) => (
                    <a key={item.id} 
                        href={`${apiUrl}/integration/fileUpload/downloadstsfile?path=StatusChange_${proOrCRId}_${encodeURIComponent(item.FileName)}&&stsFileName=${encodeURIComponent(item.FileName)}`} 
                        target="blank"
                    >                 
                        <span id={`file-${item.id}`} style={{textDecorationLine: "underline"}}>
                            {item.DocumentType}
                        </span>
                        <span>{index < fileUploads.length - 1 ? ' , ' : ''}</span>
                    </a>
                )) : ''}
            </td>
        );
    }
}

export default SearchResultsFilesCell;