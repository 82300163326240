import {
    deleteChangeReq,
    getChangeRequest,
    getCRsOfAProject
} from '../projectManagement/changeRequest/ChangeRequestService';
import {deleteCMSheet, getCMSheet, retrieveConversionRate} from '../projectManagement/cmSheet/CMSheetService';
import {deleteProject, getProject} from '../projectManagement/project/ProjectService';
import {getWorkflowType, getWorkflowParameter, getWorkflowOperator} from '../projectManagement/workflow/WorkflowService';

export function deleteChangeReqIntegrated(changeReqID) {
    return deleteChangeReq(changeReqID);
}

export function getCRsOfAProjectIntegrated(projectId) {
    return getCRsOfAProject(projectId);
}

export function deleteCMSheetIntegrated(cmSheetId) {
    return deleteCMSheet(cmSheetId);
}

export function deleteProjectIntegrated(projectId) {
    return deleteProject(projectId);
}

export function getWorkflowTypeIntegrated() {
    return getWorkflowType();
}

export function getWorkflowParameterIntegrated() {
    return getWorkflowParameter();
}

export function getWorkflowOperatorIntegrated() {
    return getWorkflowOperator();
}

export function getProjectById(projectId) {
    return getProject(projectId);
}

export function getChangeRequestById(changeReqId) {
    return getChangeRequest(changeReqId);
}

export function getCMSIntegrated(CMSheetId, CMSVersion) {
    return getCMSheet(CMSheetId, CMSVersion);
}

export function retrieveConversionRateIntegrated(currencyId, month, year) {
    return retrieveConversionRate(currencyId, month, year);
}
