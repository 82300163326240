import React, { Component } from "react";

class EditPopupIsRevenuePresentCell extends Component {


    toggleIsRevenuePresent = event => {
        event.preventDefault();
        this.props.dataItem.IsRevenuePresent = event.target.checked;
        this.props.toggleIsRevenuePresent(this.props.dataItem);
      };

    render() {
        return(
        <td>
            <fieldset>
                  <label className="k-form-field">
                      <input type="checkbox" className="k-checkbox" 
                        checked={this.props.dataItem.IsRevenuePresent}
                        onChange={this.toggleIsRevenuePresent}
                        />
                  </label>
              </fieldset>
        </td>
        );
    }

}

export default EditPopupIsRevenuePresentCell;
