import React, {Component} from 'react';
import { ComboBox, MultiSelect} from '@progress/kendo-react-dropdowns';
import debounce from 'lodash.debounce';
import * as loggerService from '../../../integration/LoggerService';
import { LOG_TYPES } from '../../../integration/IntegrationEnums';

class AsyncSelectPagination extends Component{

    constructor(props){
        super(props);
        this.onFilterChange = debounce(this.onFilterChange, this.props.delay);
        this.state = {
            dataCaching: [],
            filter: '',
            requestStarted: false,
            loading: false,
            skip: 0,
            data: [],
            total: 0,
        }
    }

    componentDidMount() {
        this.requestData(0, this.state.filter);
    }
    
    filterChange = (event, isOnOpen) => {
        if(isOnOpen && this.props.isFetchOnOpen){
            return this.props.filter(this.props.onOpenValue);
        }
        if(event.filter){
            this.props.filter(event.filter.value);
        }
    };

    async requestData(skip, filter) {
        try {
            this.setState({requestStarted: true});
            const e = await this.props.service({
                [this.props.filterName || 'filter']: filter,
                limit: this.props.pageSize,
                offset: skip
            })
            const {data: {count, rows}} = e;
            const total = count;
            const items = [];
            rows.forEach((element, index) => {
                const { Name } = element;
                const item = { [this.props.dataItemKey]: Name, [this.props.textField]: Name };
                items.push(item);
                this.state.dataCaching[index + skip] = item;
            });

            if (skip === this.state.skip) {
                this.setState({
                    data: this.props.addFilterIfNull ? items.findIndex(e => e.Name.toLowerCase() === filter.toLowerCase()) === -1 ? [...items, {Name: filter}] : items : items,
                    total: total
                });
            }
            this.setState({requestStarted: false});   
        } catch (error) {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        }
    }

    pageChange = (event) => {
        const skip = event.page.skip;
        const filter = this.state.filter;

        if (this.shouldRequestData(skip)) {
            this.requestData(skip, filter);
        }

        const data = this.getCachedData(skip);

        this.setState({
            data: data,
            skip: skip
        });
    }

    onFilterChange = (event) => {
        const filter = event.filter.value;

        this.resetCach();
        this.requestData(0, filter);

        this.setState({
            data: this.props.loadingData,
            skip: 0,
            filter: filter
        });
    }

    filterController = (e, isOnOpen = false) => {
        this.setState({loading: isOnOpen ? isOnOpen && this.props.isFetchOnOpen : true}, () => {
            this.filterChange(e, isOnOpen);
        });
    }

    componentDidUpdate(prevProps){
        if(prevProps.loading !== this.props.loading){
            this.setState({loading: this.props.loading});
        }
    }

    componentWillUnmount() {
        this.resetCach();
    }

    shouldRequestData(skip) {
        for (let i = 0; i < this.props.pageSize; i++) {
            if (!this.state.dataCaching[skip + i]) {
                return true;
            }
        }
        return false;
    }

    getCachedData(skip) {
        const data = [];
        for (let i = 0; i < this.props.pageSize; i++) {
            data.push(this.state.dataCaching[i + skip] || this.props.emptyItem);
        }
        return data;
    }

    resetCach() {
        this.state.dataCaching.length = 0;
    }
    
    render() {
        if (this.props.field === 'TaskGroup'){
            return (
                <ComboBox
                    data={this.state.data}
                    placeholder={this.props.placeholder}
                    name={this.props.name}
                    value={this.props.value}
                    textField={this.props.textField}
                    dataItemKey={this.props.dataItemKey}
                    loading={this.state.requestStarted}
                    filterable={this.props.filterable || true}
                    onFilterChange={this.onFilterChange}
                    virtual={{
                        pageSize: this.props.pageSize,
                        skip: this.state.skip,
                        total: this.state.total
                    }}
                    onPageChange={this.pageChange}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled || false}
                />
            );
        } else {
            return (
                <MultiSelect
                    data={this.state.data}
                    placeholder={this.props.placeholder}
                    name={this.props.name}
                    value={this.props.value}
                    textField={this.props.textField}
                    dataItemKey={this.props.dataItemKey}
                    loading={this.state.requestStarted}
                    filterable={this.props.filterable || true}
                    onFilterChange={this.onFilterChange}
                    virtual={{
                        pageSize: this.props.pageSize,
                        skip: this.state.skip,
                        total: this.state.total
                    }}
                    onPageChange={this.pageChange}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled || false}
                />
            );
        }
    }
}

export default AsyncSelectPagination;