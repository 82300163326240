import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { saveAuditTrail } from '../../../integration/auditTrail/AuditTrailService';
const apiUrl = require('../../../../src/config.js').get(process.env.REACT_APP_ENV).apiUrl;

class ActionsTableCell extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { OnEdit } = this.props;
        const { OnDelete } = this.props;
        const { OnDeActivate } = this.props;
        const { OnActivate } = this.props;
        return (
            <td className="action-buttons">
                {this.props.dataItem.IsActive  === true && (
                    <Link
                        to={{
                            pathname: '/admin/qms/edittemplate',
                            action: 'edit',
                            title: 'Edit Template',
                            id: this.props.dataItem.QMSUpload.map(v => v.id),
                            qmsTemplateId: this.props.dataItem.id,
                            division: this.props.dataItem.QMSUpload.map(v => v.BillingDivision),
                            description: this.props.dataItem.Description,
                            docName: this.props.dataItem.DocName,
                            docCode: this.props.dataItem.QMSUpload.map(v => v.DocCode),
                            qmsTemplate: this.props.dataItem.QMSUpload.length > 0 ? this.props.dataItem.QMSUpload[0].FileUpload : null,
                            docCodePrefix: this.props.dataItem.QMSUpload.map(v => v.DocCodePrefix),
                            docCodeSuffix: this.props.dataItem.QMSUpload.map(v => v.DocCodeSuffix),
                            region: (this.props.dataItem.QMSUpload.length > 0 && this.props.dataItem.QMSUpload.filter(v => v.Region).length > 0) ? this.props.dataItem.QMSUpload.filter(v => v.Region)[0].Region  : null 
                            }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-edit" title="Edit" />
                        </div>
                    </Link>
                )}

                {this.props.dataItem.IsActive  === false && (
                    <Link
                        to={this.props}
                        onClick={(e) => { e.preventDefault(); OnDelete(this.props.dataItem.id); }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-delete" title="Delete" />
                        </div>
                    </Link>
                )}
                {this.props.dataItem.IsActive  === true && (
                    <a href={`${apiUrl}/integration/fileUpload/downloadqms?path=QMSTemplate_${encodeURIComponent(this.props.dataItem.FileUpload.FileName)}&&qmsNum=${encodeURIComponent(this.props.dataItem.FileUpload.FileName)}`} target="_blank"
                        onClick={
                            (e) =>{ const auditTrail = {
                                Action: 'QMS Template downloaded',
                                RefType: 'QMS Number Generator',
                                RefId: '',
                                RefName: this.props.dataItem.FileUpload.FileName
                                };
                                saveAuditTrail(auditTrail);
                            }
                        }
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-download" title="Download" />
                        </div>
                    </a>
                )}
                {this.props.dataItem.IsActive === true && (
                    <Link to={this.props} onClick={event => OnDeActivate(event, this.props.dataItem.id)}>
                    <div class="rounded-link">
                        <span class="k-icon k-i-unsort" title="Deactivate"/>
                    </div>
                    </Link>
                )}
                {this.props.dataItem.IsActive  === false && (
                    <Link to={this.props} onClick={event => OnActivate(event, this.props.dataItem.id)}>
                    <div class="rounded-link">
                        <span class="k-icon k-i-check" title="Activate"/>
                    </div>
                    </Link>
                )}
                    
            </td>
        );
    }
}

export default ActionsTableCell;
