import React, { Component } from "react";
import { Link } from "react-router-dom";

class EditEngagementTypeActionsCell extends Component {
  render() {
    const { OnDeactivate } = this.props;
    const { OnActivate } = this.props;
    const engagementType = this.props.dataItem;
    return (
      <td className="action-buttons">
        {engagementType.IsActive === true && (window.USER_PERMISSION.indexOf('ED_ENG') > -1) && (
                  <Link
                    to={{
                      pathname: "/masterdata/createengagementtype/createengagementtype",
                      engagementType: engagementType,
                      action: "edit",
                    }}
                  >
                    <div className="rounded-link">
                      <span className="k-icon k-i-edit" title="Edit" />
                    </div>
                  </Link>
        )}
         {engagementType.IsActive === true && (window.USER_PERMISSION.indexOf('ED_ENG') > -1) && (
          <Link
            to={this.props}
            onClick={event => OnDeactivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-unsort" title="Deactivate" />
            </div>
          </Link>
        )}
        {engagementType.IsActive === false && (window.USER_PERMISSION.indexOf('ED_ENG') > -1) && (
          <Link
            to={this.props}
            onClick={event => OnActivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-check" title="Activate" />
            </div>
          </Link>
        )}
      </td>
    );
  }
}

export default EditEngagementTypeActionsCell;
