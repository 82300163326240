import React, { Component } from 'react';
import { DropDownList, ComboBox } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import ProjectPhase from './ProjectPhase';
import ProjectSubPhase from './ProjectSubPhase';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import differenceBy from 'lodash/differenceBy';
import { DebounceInput } from 'react-debounce-input';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import PhaseRow from './components/PhaseRow';

import {
    getPaymentMethods, getProjectTypes
} from '../../masterData/common/CommonService';
import {
    getResourceRegionsIntegrated,
    getSalesPersonIntegrated,
    getProjectPartnersIntegrated,
    getAllCountriesWithoutARegionIntegrated,
    getBillingDiviIntegrated, getBillingDiviDropDownIntegrated,
    getPracticeTypesIntegrated, getAllPracticeDivisionMapIntegrated
} from '../../integration/MasterDataService';
import { savePhases,updatePhases } from '../cmSheet/CMSheetService';
import { Tooltip } from 'reactstrap';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import $ from "jquery";
import { getFactoryProjByRegionAndPractice, updateLinkToFactory } from '../project/ProjectService';
import { filterBy } from '@progress/kendo-data-query';
import { getOfferings } from "../../masterData/offering/OfferingService";

class ProjectDetails extends Component {
    defaultItemDropDown = { id: null, Name: 'Please Select' };

    constructor(props) {
        super(props);

        this.state = {
            projectPhaseVisible: false,
            projectSubPhaseVisible: false,

            text: '',

            regions: [],
            regionNamesMap: {},
            countries: [],
            countryNamesMap: {},
            paymentMethods: [],
            paymentMethodNamesMap: {},
            salesPeople: [],
            salesPersonNamesMap: {},
            partners: [],
            partnerNamesMap: {},

            phases: [],
            phasesTemp: [],
            updatedphases: [],
            updatedSubphases: [],

            DiscountValue: 0,
            ServiceRevenueValue: 0,

            showMessageDialog: false,
            dialogMessage: '',
            dialogTitle: '',

            showConfirmDialog: false,
            confirmDialogMessage: '',
            confirmDialogAction: null,

            phaseRemoveIndex: -1,
            subPhaseRemovePhaseIndex: -1,
            subPhaseRemoveSubPhaseIndex: -1,
            isSaveDisable: false,

            countryToolTipOpen: false,
            salesPersonToolTipOpen: false,
            billingMethodToolTipOpen: false,
            partnerToolTipOpen: false,
            departmentToolTipOpen: false,
            engTypeToolTipOpen: false,
            cmsTypeToolTipOpen: false,

            engagementTypes: [],
            engagementTypeNamesMap: {},
            practice: [],
            practiceNamesMap: {},

            APSProfitability: 0,

            selectedPoolProject: null,
            isFactoryProject: false,
            checkDisabled: false,
            removeLinkToPoolDialog: false,
            linkToPoolDialog: false,
            offeringItems: [],
            offeringNames: [],
            offeringToolTipOpen: false,
            practiceItems: [],
            practiceDivisionMap: [],
            practiceToolTipOpen: false,
        };
    }

    handleChange(value) {
        this.setState({ text: value })
    }

    componentDidMount() {
        this.setInitialData();
        this.setDiscount();
        this.setServiceRevenue();
        this.setAPSProfitability();
        this.populateRegions();
        this.populateCountries();
        this.populatePaymentMethods();
        this.populateSalesPeople();
        this.populatePartners();
        this.getPractice();
        this.getEngagementTypes();
        this.populateOfferingItems();
        this.getPracticeTypesIntegrated();
        this.getAllPracticeDivisionMapIntegrated();

        $("div.title-remove > span > span > span:last-child").removeAttr("title");
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.cmSheet &&
            prevProps.cmSheet &&
            this.props.cmSheet.Phases !== prevProps.cmSheet.Phases
        ) {
            var phases = [];
            if(this.props.cmSheet){
                phases = this.props.cmSheet.Phases.filter(each => each.isResourceRequest !== true);
            }
            this.setState({
                phases: phases,
                phasesTemp: this.props.cmSheet ? JSON.parse(JSON.stringify(this.props.cmSheet.Phases)) : []
            });
        }
        if (this.props.cmSheet.DiscountValue !== prevProps.cmSheet.DiscountValue) {
            this.setDiscount();
        }

        if (this.props.cmSheet.ServiceRevenueValue !== prevProps.cmSheet.ServiceRevenueValue) {
            this.setServiceRevenue();
        }

        if (this.props.cmSheet.APSProfitability !== prevProps.cmSheet.APSProfitability) {
            this.setAPSProfitability();
        }
    }

    setDiscount = () => {
        this.setState({
            DiscountValue: this.props.cmSheet.DiscountValue ? this.props.cmSheet.DiscountValue : 0
        });
    };

    setInitialData = async() => {
        try {
            if(!this.isAPS){
                this.props.endDate = null
            }
            var phases = [];
            if(this.props.cmSheet){
                phases = this.props.cmSheet.Phases.filter(each => each.isResourceRequest !== true);
            }
            let phasesTemp = []
            if(this.props.cmSheet ){
                phasesTemp =  JSON.parse(JSON.stringify(this.props.cmSheet.Phases))
                phasesTemp.map(phase=>{
                    phase.isNew = false
                })
            }
            this.setState({
                phases: phases,
                phasesTemp: phasesTemp
            });
    
            //pool project
            let poolProjectListAll = [];
            if(!this.props.IsPoolProject){
                let info = null;
                if(this.props.changeRequest){
                    info = {
                        regionId: this.props.changeRequest.BillingRegion,
                        practiceId : this.props.changeRequest.BillingDivisionId,
                        offeringId : this.props.changeRequest.OfferingId
                    };
                } else {
                    info = {
                        regionId : this.props.project.BillingRegion,
                        practiceId : this.props.project.BillingDivision,
                        offeringId : this.props.project.OfferingId
                    };
                }
                const {data} = await getFactoryProjByRegionAndPractice(info);
                this.setState({
                    poolProjectsAll: data,
                    checkDisabled: data.length === 0
                });
            }
            if(this.props.project.PoolProjectId !== null){
                this.state.isFactoryProject = true;
                for(let eachproj of poolProjectListAll.data){
                    if(this.props.changeRequest && this.props.changeRequest.id){
                        if(eachproj.id == this.props.changeRequest.PoolProjectId){
                            this.state.selectedPoolProject = eachproj;
                        }
                    }else{
                        if(eachproj.id == this.props.project.PoolProjectId){
                            this.state.selectedPoolProject = eachproj;
                        }
                    }
                }
            }
        } catch (error) {
            this.setState({checkDisabled: true});
        }
        
    };

    setServiceRevenue = () => {
        this.setState({
            ServiceRevenueValue: this.props.cmSheet.ServiceRevenueValue ? this.props.cmSheet.ServiceRevenueValue : 0.00
        });
    };

    setAPSProfitability = () => {
        this.setState({
            APSProfitability: this.props.cmSheet.APSProfitability ? this.props.cmSheet.APSProfitability : 0.00
        });
    };

    populateRegions = async () => {
        getResourceRegionsIntegrated()
            .then(res => {
                const regionNames = {};
                for (const region of res.data) {
                    regionNames[region.id] = region.Name;
                }
                this.setState({
                    regions: res.data,
                    regionNamesMap: regionNames
                });
            })
            .catch(e => {
                loggerService.writeLog(e, LOG_TYPES.ERROR);
            });
    };

    populateCountries = async () => {
        getAllCountriesWithoutARegionIntegrated()
            .then(res => {
                const countryNames = {};
                for (const country of res.data) {
                    countryNames[country.id] = country.Name;
                }
                this.setState({
                    countries: res.data,
                    countryNamesMap: countryNames
                });
            })
            .catch(e => {
                loggerService.writeLog(e, LOG_TYPES.ERROR);
            });
    };

    populateOfferingItems = async () => {
        getOfferings().then(response => {
            const offeringNames = {};
            for (const offering of response.data) {
                offeringNames[offering.id] = offering.Name;
            }
            this.setState({
              offeringItems: response.data,
              offeringNames: offeringNames
            });
          }).catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populatePaymentMethods = async () => {
        await getPaymentMethods()
            .then(res => {
                const paymentMethodNames = {};
                for (const pm of res.data) {
                    paymentMethodNames[pm.id] = pm.Name;
                }
                this.setState({
                    paymentMethods: res.data,
                    paymentMethodNamesMap: paymentMethodNames
                });
            })
            .catch(e => {
                loggerService.writeLog(e, LOG_TYPES.ERROR);
            });
    };

    populateSalesPeople = async () => {
        getSalesPersonIntegrated()
            .then(res => {
                const salesPersonNames = {};
                for (const sp of res.data) {
                    salesPersonNames[sp.id] = sp.Name;
                }
                this.setState({
                    salesPeople: res.data,
                    salesPersonNamesMap: salesPersonNames
                });
            })
            .catch(e => {
                loggerService.writeLog(e, LOG_TYPES.ERROR);
            });
    };

    populatePartners = async () => {
        getProjectPartnersIntegrated()
            .then(res => {
                const partnerNames = {};
                for (const partner of res.data) {
                    partnerNames[partner.id] = partner.Name;
                }
                this.setState({
                    partners: res.data,
                    partnerNamesMap: partnerNames
                });
            })
            .catch(e => {
                loggerService.writeLog(e, LOG_TYPES.ERROR);
            });
    };

    getPractice = async () => {

        getBillingDiviDropDownIntegrated().then(response => {
            const projectTypeNames = {};
            const divisions = response.data.filter(el => el.IsActive === true && el.SFCode !== null)
            for (const type of divisions) {
                projectTypeNames[type.id] = type.Name;
            }
            this.setState({
                practice: divisions,
                practiceNamesMap: projectTypeNames
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    }

    getPracticeTypesIntegrated = async () => {

        await getPracticeTypesIntegrated().then(response => {
            this.allPracticeTypes = response.data;
            this.setState({
              practiceItems: response.data
            });
    
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    }

    getAllPracticeDivisionMapIntegrated = async () => {

        await getAllPracticeDivisionMapIntegrated().then(response => {
            this.setState({
                practiceDivisionMap: response.data
            });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
        await this.practiceDivisionMapping();
    }

    practiceDivisionMapping = async () => {
        if (this.props.division && this.allPracticeTypes) {
            let practices = [];
            let practicesForDivision = this.state.practiceDivisionMap.filter(item => item.DepartmentCodeSF === this.props.division.SFCode);
            for (const practice of practicesForDivision) {
                let selectedPractice = this.allPracticeTypes.filter(item => item.SFCode === practice.PracticeCodeSF);
                practices.push(selectedPractice[0]);
            }
            await this.setState({ practiceItems: practices })
        }
      };

    getEngagementTypes = async () => {

        getProjectTypes().then(response => {
            const engagementTypeNames = {};
            for (const type of response.data) {
                engagementTypeNames[type.id] = type.Name;
            }
            this.setState({
                engagementTypes: response.data,
                engagementTypeNamesMap: engagementTypeNames
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    }

    isAPS =  () => {
        let isAPS = false

        if (this.props.division && this.props.type && this.props.division.id === 30 && this.props.type.id === 3) {
            isAPS = true
        }
        return isAPS
    };

    isRsourceAug =  () => {

        if (this.props.type && this.props.type.id === 6) {

            return  true
        }
        return false
    };

    isBlanketWorkOrder =  () => {
        if (this.props.type && this.props.type.id === 5) {
            return  true
        }
        return false
    };

    handlePaymentMethodChange = event => {
        const pmId = event.target.value.id;
        this.props.onPaymentMethodChange(pmId, this.props.cmSheetReference);
    };

    handleSalesPersonChange = event => {
        let spId = null;
        if (event.target.value) {
            spId = event.target.value.id;
        }
        this.props.onSalesPersonChange(spId, this.props.cmSheetReference);
    };

    handlePartnerChange = event => {
        let pId = null;
        if (event.target.value) {
            pId = event.target.value.id;
        }
        this.props.onPartnerChange(pId, this.props.cmSheetReference);
    };

    handleStartdateChange = event => {
        this.props.onStartDateChange(event, this.props.cmSheetReference);
        this.props.IsStartDateChange(this.props.startDate,event.target.value);
    };

    handleEndDateChange = event => {
        this.props.onEndDateChange(event, this.props.cmSheetReference);
    };

    handleDiscountChange = event => {
        if (event.target.name === 'DiscountValue') {
            const field = event.target.name;
            const value = event.target.value;
            this.setState(
                {
                    DiscountValue: value
                },
                () => {
                    this.props.onDiscountChange({ target: { name: field, value: value } });
                }
            );
        } else {
            this.props.onDiscountChange(event);
        }
    };

    handleServiceRevenueChange = event => {
        if (event.target.name === 'ServiceRevenueValue') {
            const field = event.target.name;
            const value = event.target.value;
            this.setState(
                {
                    ServiceRevenueValue: value
                },
                () => {
                    this.props.onServiceRevenueChange({ target: { name: field, value: value } });
                }
            );
        } else {
            this.props.onServiceRevenueChange(event);
        }
    };

    handleAPSChange = event => {
        if (event.target.name === 'APSProfitability') {
            const field = event.target.name;
            const value = event.target.value;
            this.setState(
                {
                    APSProfitability: value === "" ? '0' : value
                },
                () => {
                    this.props.onAPSProfitabilityChange({ target: { name: field, value: value } });
                }
            );
        } else {
            this.props.onAPSProfitabilityChange(event);
        }
    };

    handleContingencyChange = event => {
        this.props.onContingencyChange(event);
    };

    handleCommentChangeDiscount = event => {
        this.props.onCommentChangeDiscount(event);
    };

    handleCommentChangeContingency = event => {
        this.props.onCommentChangeContingency(event);
    };

    handleCommentChangeServiceRevenue = event => {
        this.props.onCommentChangeServiceRevenue(event);
    };

    handleCommentAPSProfitability = event => {

        let html = event;
        let div = document.createElement("div");
        div.innerHTML = html;

        if (div.innerText.length > 1000) {
            this.props.onCommentChangeAPSProfitability(this.props.cmSheet.APSProfitabilityComment);
        } else {
            this.props.onCommentChangeAPSProfitability(event);
        }
    };

    toggleProjectPhase = () => {
        if (this.state.projectPhaseVisible) {
            this.handleCancel();
        }
        this.setState({
            projectPhaseVisible: !this.state.projectPhaseVisible,
            isSaveDisable: true
        });
    };

    toggleProjectSubPhase = () => {
        if (this.state.projectSubPhaseVisible) {
            this.handleCancel();
        }
        this.setState({
            projectSubPhaseVisible: !this.state.projectSubPhaseVisible,
            isSaveDisable: true
        });
    };

    disabledSaveButton = async () => {
        let diff = [];
        let original = this.state.phases;
        let current = this.state.phasesTemp;

        if (original && original.length > 0) {
            diff = await differenceBy(current, original, 'Text');
        } else if (current && current.length > 0) {
            return false;
        }
        if (diff.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    disabledSaveSubButton = async () => {
        let diff = [];
        let original = this.state.phases;
        let current = this.state.phasesTemp;

        if (original && current.SubPhases && current.SubPhases.length > 0) {
            current.SubPhases.map(async item => {
                diff = await differenceBy(current.SubPhases, original.SubPhases, 'Text');
                if (diff.length > 0) {
                    return false;
                } else {
                    return true;
                }
            });
        } else if (current && current.SubPhases && current.SubPhases.length > 0) {
            return false;
        }
    };

    disabledSaveDeleteButton = async () => {
        let diff = [];
        let original = this.state.phases;
        let current = this.state.phasesTemp;

        if (original && original.length > 0) {
            diff = await differenceBy(original, current, 'Text');
        } else if (current && current.length > 0) {
            return false;
        }
        if (diff.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    disabledSaveSubDeleteButton = async () => {
        let diff = [];
        let original = this.state.phases;
        let current = this.state.phasesTemp;

        if (original && current.SubPhases && current.SubPhases.length > 0) {
            current.SubPhases.map(async item => {
                diff = await differenceBy(original.SubPhases, current.SubPhases, 'Text');
                if (diff.length > 0) {
                    return false;
                } else {
                    return true;
                }
            });
        } else if (current && current.SubPhases && current.SubPhases.length > 0) {
            return false;
        }
    };

    handlePhasesAdd = async (phaseName, startDate) => {
        const phasesTemp = this.state.phasesTemp;
        if (phaseName) {
            phaseName = phaseName.trim();
            const exists = phasesTemp.filter(obj => {
                let exist = obj.Text;
                if (exist) {
                    const existLC = exist.toLowerCase();
                    const phaseNameLC = phaseName.toLowerCase();

                    if (phaseNameLC === existLC) {
                        return true;
                    }
                    return false;
                }
                return false;
            });
            if (exists.length > 0) {
                const message = 'Phase name already exists. Please use a unique name';
                const title = 'Name Already Exists';
                this.toggleMessageDialog(message, title);
            } else {
                phasesTemp.push({
                    Text: phaseName,
                    StartDate: startDate,
                    SubPhases: [],
                    isNew:true
                });

                this.setState({
                    phasesTemp: phasesTemp
                });
                let isSaveDisable = await this.disabledSaveButton();
                await this.setState({
                    isSaveDisable: isSaveDisable
                });
            }
        }
    };
    
    handlePhasesUpdate = async (phaseName, startDate,index,isNew) => {
        const phasesTemp = this.state.phasesTemp;
        const otherPhasesTemp = phasesTemp.filter((x,i)=>i !== index);
        if (phaseName) {
            phaseName = phaseName.trim();
            const exists = otherPhasesTemp.filter(obj => {
                let exist = obj.Text;
                if (exist) {
                    const existLC = exist.toLowerCase();
                    const phaseNameLC = phaseName.toLowerCase();

                    if (phaseNameLC === existLC) {
                        return true;
                    }
                    return false;
                }
                return false;
            });
            if (exists.length > 0) {
                const message = 'Phase name already exists. Please use a unique name';
                const title = 'Name Already Exists';
                this.toggleMessageDialog(message, title);
            } else {
                phasesTemp[index].Text = phaseName;
                phasesTemp[index].StartDate = startDate;
                this.setState({
                    phasesTemp: phasesTemp,
                    updatedphases:!isNew?[...this.state.updatedphases,phasesTemp[index]]:[...this.state.updatedphases]
                });
                let isSaveDisable = await this.disabledSaveButton();
                await this.setState({
                    isSaveDisable: isSaveDisable
                });
            }
        }
    };

    handlePhasesRemove = async index => {
        if (index > -1) {
            this.setState(
                {
                    phaseRemoveIndex: index
                },
                () => {
                    const message =
                        'By removing the phase, any sub phases linked to this will be removed as well as any relationships made with the resource and expenses will be removed. Do you want to continue?';
                    this.toggleConfirmDialog(message, this.removePhase);
                }
            );
        }
    };

    removePhase = async () => {
        const phasesTemp = this.state.phasesTemp;
        phasesTemp.splice(this.state.phaseRemoveIndex, 1);

        this.setState(
            {
                phasesTemp: phasesTemp
            },
            () => {
                this.toggleConfirmDialog('', null);
            }
        );
        let isSaveDisable = await this.disabledSaveDeleteButton();
        await this.setState({
            isSaveDisable: isSaveDisable
        });

        if (this.props.cmSheet) {
            //call save phases API
            savePhases(this.props.cmSheet.id, phasesTemp)
                .then(res => {
                    const cmSheet = JSON.parse(JSON.stringify(this.props.cmSheet));
                    cmSheet.Phases = res.data;
                    this.props.onUpdateCMSheet(cmSheet, true);
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        }
    };

    handleSubPhasesAdd = async (phaseIndex, subPhaseName, startDate) => {
        const phasesTemp = this.state.phasesTemp;
        if (subPhaseName && phasesTemp[phaseIndex]) {
            subPhaseName = subPhaseName.trim();
            const exists = phasesTemp[phaseIndex].SubPhases.filter(obj => {
                let exist = obj.Text;
                if (exist) {
                    const existLC = exist.toLowerCase();
                    const subPhaseNameLC = subPhaseName.toLowerCase();

                    if (subPhaseNameLC === existLC) {
                        return true;
                    }
                    return false;
                }
                return false;
            });
            if (exists.length > 0) {
                const message = 'Sub Phase name already exists. Please use a unique name';
                const title = 'Name Already Exists';
                this.toggleMessageDialog(message, title);
            } else {
                phasesTemp[phaseIndex].SubPhases.push({
                    Text: subPhaseName,
                    StartDate: startDate
                });

                this.setState({
                    phasesTemp: phasesTemp
                });

                let isSaveDisable = await this.disabledSaveSubButton();
                await this.setState({
                    isSaveDisable: isSaveDisable
                });
            }
        }
    };
    
    handleSubPhasesUpdate = async (phaseIndex, subPhaseName, startDate,index) => {
        const phasesTemp = this.state.phasesTemp;
        if (subPhaseName && phasesTemp[phaseIndex]) {
            subPhaseName = subPhaseName.trim();
            let subPhases = phasesTemp[phaseIndex].SubPhases.filter((x,i)=>i !== index);
            const exists = subPhases.filter(obj => {
                let exist = obj.Text;
                if (exist) {
                    const existLC = exist.toLowerCase();
                    const subPhaseNameLC = subPhaseName.toLowerCase();

                    if (subPhaseNameLC === existLC) {
                        return true;
                    }
                    return false;
                }
                return false;
            });
            if (exists.length > 0) {
                const message = 'Sub Phase name already exists. Please use a unique name';
                const title = 'Name Already Exists';
                this.toggleMessageDialog(message, title);
            } else {
                phasesTemp[phaseIndex].SubPhases[index].Text = subPhaseName
                phasesTemp[phaseIndex].SubPhases[index].StartDate = startDate
                this.setState({
                    phasesTemp: phasesTemp,
                    updatedSubphases:[...this.state.updatedSubphases,phasesTemp[phaseIndex].SubPhases[index]]
                });

                this.setState({
                    phasesTemp: phasesTemp
                });

                let isSaveDisable = await this.disabledSaveSubButton();
                await this.setState({
                    isSaveDisable: isSaveDisable
                });
            }
        }
    };

    handleSubPhasesRemove = async (phaseIndex, subPhaseIndex) => {
        const phasesTemp = this.state.phasesTemp;
        if (
            phaseIndex > -1 &&
            subPhaseIndex > -1 &&
            phasesTemp[phaseIndex] &&
            phasesTemp[phaseIndex].SubPhases[subPhaseIndex]
        ) {
            this.setState(
                {
                    subPhaseRemovePhaseIndex: phaseIndex,
                    subPhaseRemoveSubPhaseIndex: subPhaseIndex
                },
                () => {
                    const message =
                        'By removing the sub phase, any relationships made with the resource and expenses will be removed. Do you want to continue?';
                    this.toggleConfirmDialog(message, this.removeSubPhase);
                }
            );
        }
    };

    removeSubPhase = async () => {
        const phasesTemp = this.state.phasesTemp;
        phasesTemp[this.state.subPhaseRemovePhaseIndex].SubPhases.splice(
            this.state.subPhaseRemoveSubPhaseIndex,
            1
        );

        this.setState(
            {
                phasesTemp: phasesTemp
            },
            () => {
                this.toggleConfirmDialog('', null);
            }
        );

        let isSaveDisable = await this.disabledSaveSubDeleteButton();
        await this.setState({
            isSaveDisable: isSaveDisable
        });
        if (this.props.cmSheet) {
            //call save phases API
            savePhases(this.props.cmSheet.id, phasesTemp)
                .then(res => {
                    const cmSheet = JSON.parse(JSON.stringify(this.props.cmSheet));
                    cmSheet.Phases = res.data;
                    this.props.onUpdateCMSheet(cmSheet, true);
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        }
    };

    handlePhasesSave = () => {
        if (this.props.cmSheet) {
            const phasesTemp = this.state.phasesTemp;
            //call save phases API
            savePhases(this.props.cmSheet.id, phasesTemp)
                .then(res => {
                    const cmSheet = JSON.parse(JSON.stringify(this.props.cmSheet));
                    cmSheet.Phases = res.data;
                    this.props.onUpdateCMSheet(cmSheet, true);
                    const message = 'Successfully saved.';
                    const title = 'Success';
                    this.toggleMessageDialog(message, title);
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        }
    };
    
    phasesUpdate = () => {
        if (this.props.cmSheet) {
            const phasesTemp = this.state.phasesTemp;
            //call save phases API
            let params = {
                updatedphases:this.state.updatedphases,
                updatedSubphases:this.state.updatedSubphases,
                cmSheetId:this.props.cmSheet.id
            }
            updatePhases(params)
                .then(res => {
                    this.setState({
                        updatedphases:[]
                    })
                    const cmSheet = JSON.parse(JSON.stringify(this.props.cmSheet));
                    cmSheet.Phases = res.data;
                    this.props.onUpdateCMSheet(cmSheet, true);
                    const message = 'Successfully Updated.';
                    const title = 'Success';
                    this.toggleMessageDialog(message, title);
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        }
    };

    handleCancel = () => {
        const phases = this.state.phases;
        this.setState({
            phasesTemp: JSON.parse(JSON.stringify(phases))
        });
    };

    toggleMessageDialog = (message, title) => {
        this.setState({
            showMessageDialog: !this.state.showMessageDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    toggleConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    toggleCountryTooltip = () => {
        this.setState({
            countryToolTipOpen: !this.state.countryToolTipOpen
        });
    };

    toggleOfferingTooltip = () => {
        this.setState({
            offeringToolTipOpen: !this.state.offeringToolTipOpen
        });
    };

    toggleSalesPersonTooltip = () => {
        this.setState({
            salesPersonToolTipOpen: !this.state.salesPersonToolTipOpen
        });
    };

    toggleBillingMethodTooltip = () => {
        this.setState({
            billingMethodToolTipOpen: !this.state.billingMethodToolTipOpen
        });
    };

    toggleCmsTypeTooltip = () => {
        this.setState({
            cmsTypeToolTipOpen: !this.state.cmsTypeToolTipOpen
        });
    };

    togglePartnerTooltip = () => {
        this.setState({
            partnerToolTipOpen: !this.state.partnerToolTipOpen
        });
    };

    toggleDepartmentToolTip = () => {
        this.setState({
            departmentToolTipOpen: !this.state.departmentToolTipOpen
        });
    };

    togglePracticeToolTip = () => {
        this.setState({
            practiceToolTipOpen: !this.state.practiceToolTipOpen
        });
    };

    toggleEngagementTypeTooltip = () => {
        this.setState({
            engTypeToolTipOpen: !this.state.engTypeToolTipOpen
        });
    };

    handleDepartmentChange = async event => {
        await this.props.onDepartmentChange(event, this.props.cmSheetReference);
        this.practiceDivisionMapping();
    };

    handleTypeChange = event => {
        this.props.onEngagementTypeChange(event, this.props.cmSheetReference);
    };

    handleOfferingChange = event => {
        this.props.onOfferingChange(event, this.props.cmSheetReference);
    };

    handlePracticeChange = event => {
        this.props.onProjPracticeChange(event, this.props.cmSheetReference);
    };

    handleCmsTypeChange = event => {
        this.props.onCmsTypeChange(event);
    };

    handleOnChangeInput = async event => {
        const eventObj = event.target;
        await this.setState({
            isFactoryProject: eventObj.checked
        });
        if(eventObj.value == 'true'){ //link project
            this.toggleLinkProjToPoolDialog();
        }else{ //remove the link
            this.toggleRemoveLinkProjToPoolDialog();
        }
    };

    toggleLinkProjToPoolDialog = async () => {
        await this.setState({
            linkToPoolDialog: !this.state.linkToPoolDialog
        });
    };

    handleComboChangePoolProject = async event => {
        await this.setState({
            selectedPoolProject: event.target.value
        });
        this.forceUpdate();
    };

    filterChangeCombo = async(event) => {
        await this.setState({
            poolProjectsAll: this.filterDataDivision(event.filter)
        });
    };

    filterDataDivision(filter) {
        const data = this.poolProjectsAll.slice();
        return filterBy(data, filter);
    }

    onCreateFactoryproject = async() => {
        //link project to Factory project
        this.props.project.PoolProjectId = this.state.selectedPoolProject.id;
        await updateLinkToFactory(this.props.project, this.props.project.id)
          .then(res => {
              this.toggleLinkProjToPoolDialog();
          })
          .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    };

    onCreateNormalProject = async() => {
        await this.setState({
            isFactoryProject: false,
            selectedPoolProject: null
        });
        this.toggleLinkProjToPoolDialog();
    };

    toggleRemoveLinkProjToPoolDialog = async () => {
        await this.setState({
            removeLinkToPoolDialog: !this.state.removeLinkToPoolDialog
        });
    };

    onConvertToNormal = async() => {
        //remove factoryProjectId from project
        this.props.project.PoolProjectId = null;
        await updateLinkToFactory(this.props.project, this.props.project.id)
          .then(res => {
              this.toggleRemoveLinkProjToPoolDialog();
          })
          .catch(error => {
              this.toggleRemoveLinkProjToPoolDialog();
              loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
        await this.setState({
            isFactoryProject: false,
            selectedPoolProject: null
        });
    };

    onContinueAsFactory =() => {
        this.setState({
            isFactoryProject: true
        });
        this.toggleRemoveLinkProjToPoolDialog();
    };

    render() {
        return (
            <div>
                <div className="row">

                    <div className="col-md-1">
                        {this.isAPS() || this.isRsourceAug() || this.isBlanketWorkOrder() ? <label htmlFor="" className="mandatory">Start Date:</label> : <label htmlFor="">Start Date:</label>}
                    </div>

                    <div className="col-md-2">
                        <div className="title-remove">
                        <DatePicker
                            value={this.props.startDate}
                            width="99%"
                            onChange={this.handleStartdateChange}
                            name="StartDate"
                            min={new Date()}
                            format="MM/dd/yyyy"
                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                            disabled={this.props.isViewMode || this.props.isApproveMode || this.props.alreadyActivated}
                        />
                        </div>
                        {((this.isAPS() || this.isRsourceAug() || this.isBlanketWorkOrder()) && this.props.startDate === null) ? <span className={"inline-error"}>
                            Please select a valid start date
                      </span> : <view></view>}
                    </div>

                    <div className="col-md-1">
                        {this.isAPS() ? <label htmlFor="" className="mandatory">End Date:</label> : <label htmlFor="">End Date:</label>}
                    </div>

                    <div className="col-md-2">
                    <div className="title-remove">

                        <DatePicker
                            value={(this.isAPS() || this.isRsourceAug() || this.isBlanketWorkOrder()) ? (this.props.endDate) : null}
                            width="99%"
                            onChange={this.handleEndDateChange}
                            name="EndDate"
                            min={new Date(this.props.startDate)}
                            format="MM/dd/yyyy"
                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                            disabled={this.props.isViewMode || this.props.alreadyActivated || (this.isRsourceAug() ? !this.isRsourceAug() : (this.isBlanketWorkOrder() ? !this.isBlanketWorkOrder() : !this.isAPS()))}
                        />
                        </div>
                        {((this.isAPS() || this.isRsourceAug() || this.isBlanketWorkOrder()) && this.props.endDate === null) ? <span className={"inline-error"}>
                            Please select a valid end date
                      </span> : <view></view>}
                    </div>

                    <div className="col-md-1">
                        <label htmlFor="" className="mandatory">
                            Department:
                      </label>
                    </div>

                    <div className="col-md-2 dd-custom" id="departmentToolTip">
                        <DropDownList
                            textField="Name"
                            dataItemKey="id"
                            data={this.state.practice}
                            value={this.props.division}
                            placeholder="Please Select"
                            disabled={this.props.isViewMode || this.props.alreadyActivated}
                            onChange={this.handleDepartmentChange}
                        />
                        {this.props.division ? (
                            <Tooltip
                                placement="top"
                                isOpen={this.state.departmentToolTipOpen}
                                target="departmentToolTip"
                                toggle={this.toggleDepartmentToolTip}
                            >
                                {this.props.division.Name}
                            </Tooltip>
                        ) : null}
                    </div>

                    <div className="col-md-1">
                        <label htmlFor="">
                            Practice:
                      </label>
                    </div>

                    <div className="col-md-2 dd-custom" id="practiceToolTip">
                        <DropDownList
                            textField="Name"
                            dataItemKey="id"
                            data={this.state.practiceItems}
                            value={this.props.projPractice}
                            placeholder="Please Select"
                            disabled={this.props.isViewMode || this.props.alreadyActivated}
                            onChange={this.handlePracticeChange}
                        />
                        {this.props.projPractice ? (
                            <Tooltip
                                placement="top"
                                isOpen={this.state.practiceToolTipOpen}
                                target="practiceToolTip"
                                toggle={this.togglePracticeToolTip}
                            >
                                {this.props.projPractice.Name}
                            </Tooltip>
                        ) : null}
                    </div>

                </div>

                <div className="row">

                    <div className="col-md-1">
                        <label htmlFor="" className="mandatory">
                            Engagement Type:
                      </label>
                    </div>

                    <div className="col-md-2 dd-custom" id="engagementTypeToolTip">
                        <DropDownList
                            textField="Name"
                            dataItemKey="id"
                            data={this.state.engagementTypes}
                            value={this.props.type}
                            placeholder="Please Select"
                            disabled={this.props.isViewMode || this.props.alreadyActivated}
                            onChange={this.handleTypeChange}
                        />
                        {this.props.type ? (
                            <Tooltip
                                placement="top"
                                isOpen={this.state.engTypeToolTipOpen}
                                target="engagementTypeToolTip"
                                toggle={this.toggleEngagementTypeTooltip}
                            >
                                {this.props.type.Name}
                            </Tooltip>
                        ) : null}
                    </div>

                </div>

                <div className={'row'}>

                <div className="col-md-1">
                        <label htmlFor="" className="mandatory">
                        Offering:
                      </label>
                    </div>

                    <div className="col-md-2 dd-custom" id="offeringToolTip">
                        <DropDownList
                            data={this.state.offeringItems}
                            textField="Name"
                            dataItemKey="id"
                            value={{
                                id: this.props.selectedOffering,
                                Name: this.state.offeringNames[this.props.selectedOffering]
                            }}
                            onChange={this.handleOfferingChange}
                            disabled={this.props.isViewMode || this.props.alreadyActivated}
                        />
                        {this.props.selectedOffering ? (
                            <Tooltip
                                placement="top"
                                isOpen={this.state.offeringToolTipOpen}
                                target="offeringToolTip"
                                toggle={this.toggleOfferingTooltip}
                            >
                                {this.state.offeringNames[this.props.selectedOffering]}
                            </Tooltip>
                        ) : null}
                    </div>

                    <div className="col-md-1">
                        <label htmlFor="" className="mandatory">
                            Billing Region:
                      </label>
                    </div>

                    <div className="col-md-2">
                        <DropDownList
                            data={this.state.regions}
                            textField="Name"
                            dataItemKey="id"
                            value={{
                                id: this.props.selectedRegion,
                                Name: this.state.regionNamesMap[this.props.selectedRegion]
                            }}
                            disabled={true}
                        />
                    </div>

                    <div className="col-md-1">
                        <label htmlFor="" className="mandatory">
                            Project Country:
                      </label>
                    </div>

                    <div className="col-md-2" id="countryToolTip">
                        <DropDownList
                            data={this.state.countries}
                            textField="Name"
                            dataItemKey="id"
                            value={{
                                id: this.props.selectedCountry,
                                Name: this.state.countryNamesMap[this.props.selectedCountry]
                            }}
                            disabled={true}
                        />
                        {this.props.selectedCountry ? (
                            <Tooltip
                                placement="top"
                                isOpen={this.state.countryToolTipOpen}
                                target="countryToolTip"
                                toggle={this.toggleCountryTooltip}
                            >
                                {this.state.countryNamesMap[this.props.selectedCountry]}
                            </Tooltip>
                        ) : null}
                    </div>

                    <div className="col-md-1">
                        <label htmlFor="" className="mandatory">
                            Billing Method:
                      </label>
                    </div>

                    <div className="col-md-2 dd-custom" id="billingMethodToolTip">
                        <DropDownList
                            data={this.state.paymentMethods}
                            textField="Name"
                            dataItemKey="id"
                            value={{
                                id: this.props.selectedPaymentMethod,
                                Name: this.state.paymentMethodNamesMap[this.props.selectedPaymentMethod]
                            }}
                            onChange={this.handlePaymentMethodChange}
                            disabled={
                                this.props.isViewMode || this.props.alreadyActivated ||
                                (this.props.project &&
                                    this.props.project.ProjectStatus &&
                                    (this.props.project.ProjectStatus.Code === 'WORK_AT_RISK' ||
                                        this.props.project.ProjectStatus.Code === 'BILLABLE'))
                            }
                        />
                        {this.props.selectedPaymentMethod ? (
                            <Tooltip
                                placement="top"
                                isOpen={this.state.billingMethodToolTipOpen}
                                target="billingMethodToolTip"
                                toggle={this.toggleBillingMethodTooltip}
                            >
                                {this.state.paymentMethodNamesMap[this.props.selectedPaymentMethod]}
                            </Tooltip>
                        ) : null}
                    </div>
                </div>

                <div className={'row'}>
                    <div className="col-md-1">
                        <label htmlFor="" className="mandatory">
                            CMS Type:
                    </label>
                    </div>

                    <div className="col-md-2 dd-custom" id="cmsTypeToolTip">
                        <DropDownList
                            data={this.props.cmsTypes}
                            textField="name"
                            dataItemKey="value"
                            value={this.props.selectedCmsType}
                            placeholder="Please Select"
                            disabled={this.props.isViewMode}
                            onChange={this.handleCmsTypeChange}
                        />
                        {this.props.selectedCmsType ? (
                            <Tooltip
                                placement="top"
                                isOpen={this.state.cmsTypeToolTipOpen}
                                target="cmsTypeToolTip"
                                toggle={this.toggleCmsTypeTooltip}
                            >
                                {this.props.selectedCmsType.name}
                            </Tooltip>
                        ) : null}
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-1">
                        <label htmlFor="">Discount / (Premium) %:</label>
                    </div>

                    <div className="col-md-2">
                        <DebounceInput
                            debounceTimeout={2000}
                            width="100%"
                            onChange={this.handleDiscountChange}
                            value={Number(this.props.cmSheet.DiscountPercentage).toFixed(2)}
                            disabled={this.props.isViewMode}
                            name="DiscountPercentage"
                            type="number"
                            pattern="[0-9]*"
                            className="k-textbox"
                        />
                    </div>

                    <div className="col-md-1">
                        <label>Discount / (Premium) $:</label>
                    </div>

                    <div className="col-md-2">

                        <DebounceInput
                            debounceTimeout={2000}
                            width="100%"
                            onChange={this.handleDiscountChange}
                            value={Number(this.state.DiscountValue).toFixed(2)}
                            disabled={this.props.isViewMode}
                            name="DiscountValue"
                            type="number"
                            className="k-textbox"
                        />
                    </div>

                    <div className="col-md-1">
                        <label htmlFor="">Comment:</label>
                    </div>

                    <div className={`col-md-5 ${this.props.isViewMode || this.props.cmSheet.DiscountPercentage == 0 ? 'quill-toolbar' : ''}`}>
                        <ReactQuill
                            onChange={this.handleCommentChangeDiscount}
                            readOnly={this.props.cmSheet.DiscountPercentage == 0 || this.props.isViewMode}
                            value={
                                this.props.cmSheet.DiscountComment ? this.props.cmSheet.DiscountComment : ''
                            }
                            name="DiscountComment"
                        />
                        <span
                            className={
                                (this.props.cmSheet.DiscountPercentage != 0 ?
                                    this.props.cmSheet.DiscountComment ?
                                        this.props.cmSheet.DiscountComment.replace(/(<([^>]+)>)/ig, "").length === 0
                                            ? 'inline-error'
                                            : 'd-none'
                                        : 'inline-error'
                                    : 'd-none')
                            }
                        >
                            Please enter a comment
              </span>
                    </div>

                </div>

                <div className="row">

                    <div className="col-md-1">
                        <label htmlFor="">Contingency %:</label>
                    </div>

                    <div className="col-md-2">
                        <DebounceInput
                            debounceTimeout={2000}
                            width="100%"
                            value={this.props.cmSheet.ContingencyPercentage}
                            onChange={this.handleContingencyChange}
                            name="ContingencyPercentage"
                            disabled={this.props.isViewMode}
                            type="number"
                            pattern="[0-9]*"
                            className="k-textbox"
                        />
                    </div>

                    <div className="col-md-1">
                        <label>Contingency $:</label>
                    </div>

                    <div className="col-md-2">
                        <Input
                            width="100%"
                            value={
                                this.props.cmSheet.ContingencyValue > 0
                                    ? this.props.cmSheet.ContingencyValue.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                    : '0.00'
                            }
                            disabled={true}
                            name="ContingencyValue"
                        />
                    </div>

                    <div className="col-md-1">
                        <label htmlFor="">Comment:</label>
                    </div>

                    <div className={`col-md-5 ${this.props.isViewMode || this.props.cmSheet.ContingencyPercentage >= 10 ? 'quill-toolbar' : ''}`}>
                        <ReactQuill
                            onChange={this.handleCommentChangeContingency}
                            readOnly={this.props.cmSheet.ContingencyPercentage >= 10 || this.props.isViewMode}
                            value={
                                this.props.cmSheet.ContingencyComment
                                    ? this.props.cmSheet.ContingencyComment
                                    : ''
                            }
                            name="ContingencyComment"


                        />

                        <span
                            className={
                                (this.props.cmSheet.ContingencyPercentage < 10 ?
                                    this.props.cmSheet.ContingencyComment ?
                                        this.props.cmSheet.ContingencyComment.replace(/(<([^>]+)>)/ig, "").length === 0
                                            ? 'inline-error'
                                            : 'd-none'
                                        : 'inline-error'
                                    : 'd-none')
                            }
                        >
                            Please enter a comment
                </span>
                    </div>
                </div>

                {this.isAPS() ?

                    <div className="row">

                        <div className="col-md-1">
                            <label htmlFor="">APS Profitability:</label>
                        </div>

                        <div className="col-md-2">
                            <DebounceInput
                                debounceTimeout={2000}
                                width="100%"
                                onChange={this.handleAPSChange}
                                value={Number(this.state.APSProfitability).toFixed(2)}
                                disabled={this.props.isViewMode}
                                name="APSProfitability"
                                type="number"
                                className="k-textbox"
                            />
                        </div>

                        <div className="col-md-3">
                        </div>

                        <div className="col-md-1">
                            <label htmlFor="">Comment:</label>
                        </div>

                        <div className={`col-md-5 ${this.props.isViewMode || this.state.APSProfitability <= 0 ? 'quill-toolbar' : ''}`}>
                            <ReactQuill
                                onChange={this.handleCommentAPSProfitability}
                                readOnly={this.state.APSProfitability <= 0 || this.props.isViewMode}
                                value={
                                    this.props.cmSheet.APSProfitabilityComment && this.state.APSProfitability > 0
                                        ? this.props.cmSheet.APSProfitabilityComment
                                        : ''
                                }
                                name="APSProfitabilityComment"
                            />
                        </div>
                    </div> :
                    <view></view>
                }

                <div className="row margin-top-5">

                    <div className="col-md-1">
                        <label htmlFor="">Product / Licensing Revenue:</label>
                    </div>

                    <div className="col-md-2">
                        <DebounceInput
                            debounceTimeout={2000}
                            width="100%"
                            onChange={this.handleServiceRevenueChange}
                            value={Number(this.state.ServiceRevenueValue).toFixed(2)}
                            disabled={this.props.isViewMode}
                            name="DiscountValue"
                            type="number"
                            className="k-textbox"
                        />
                    </div>

                    <div className="col-md-3">
                    </div>

                    <div className="col-md-1">
                        <label htmlFor="">Comment:</label>
                    </div>

                    <div className={`col-md-5 ${this.props.isViewMode || this.state.ServiceRevenueValue <= 0 ? 'quill-toolbar' : ''}`}>
                        <ReactQuill
                            onChange={this.handleCommentChangeServiceRevenue}
                            readOnly={this.state.ServiceRevenueValue <= 0 || this.props.isViewMode}
                            value={
                                this.props.cmSheet.ServiceRevenueComment && this.state.ServiceRevenueValue > 0
                                    ? this.props.cmSheet.ServiceRevenueComment
                                    : ''
                            }
                            name="ServiceRevenueComment"
                        />
                        <span
                            className={
                                (this.state.ServiceRevenueValue > 0 ?
                                    this.props.cmSheet.ServiceRevenueComment ?
                                        this.props.cmSheet.ServiceRevenueComment.replace(/(<([^>]+)>)/ig, "").length === 0
                                            ? 'inline-error'
                                            : 'd-none'
                                        : 'inline-error'
                                    : 'd-none')

                            }
                        >
                            Please enter a comment
            </span>
                    </div>

                </div>

                <div className="row">

                    <div className="col-md-1">
                        <label htmlFor="">Sales Person:</label>
                    </div>

                    <div className="col-md-2" id="salesPersonToolTip">
                        <ComboBox
                            data={this.state.salesPeople}
                            textField="Name"
                            dataItemKey="id"
                            value={{
                                id: this.props.selectedSalesPerson ? this.props.selectedSalesPerson : '',
                                Name: this.props.selectedSalesPerson ? this.state.salesPersonNamesMap[this.props.selectedSalesPerson] : ''
                            }}
                            onChange={this.handleSalesPersonChange}
                            placeholder="Please Select"
                            disabled={this.props.isViewMode}
                        />
                        {this.props.selectedSalesPerson ? (
                            <Tooltip
                                placement="top"
                                isOpen={this.state.salesPersonToolTipOpen}
                                target="salesPersonToolTip"
                                toggle={this.toggleSalesPersonTooltip}
                            >
                                {this.state.salesPersonNamesMap[this.props.selectedSalesPerson]}
                            </Tooltip>
                        ) : null}
                    </div>

                    <div className="col-md-1">
                        <label htmlFor="">Partner:</label>
                    </div>

                    <div className="col-md-2" id="partnerToolTip">
                        <ComboBox
                            data={this.state.partners}
                            textField="Name"
                            dataItemKey="id"
                            value={{
                                id: this.props.selectedPartner ? this.props.selectedPartner : '',
                                Name: this.props.selectedPartner ? this.state.partnerNamesMap[this.props.selectedPartner] : ''
                            }}
                            onChange={this.handlePartnerChange}
                            placeholder="Please Select"
                            disabled={this.props.isViewMode}
                        />
                        {this.props.selectedPartner ? (
                            <Tooltip
                                placement="top"
                                isOpen={this.state.partnerToolTipOpen}
                                target="partnerToolTip"
                                toggle={this.togglePartnerTooltip}
                            >
                                {this.state.partnerNamesMap[this.props.selectedPartner]}
                            </Tooltip>
                        ) : null}
                    </div>

                    <div className="col-md-2">
                    <input
                      type="checkbox"
                      id="factoryproj"
                      className="k-checkbox"
                      name="isFactoryProject"
                      value={this.state.isFactoryProject}
                      onChange={this.handleOnChangeInput}
                      checked={this.state.isFactoryProject}
                      disabled={this.state.checkDisabled}
                    />
                    <label className="k-checkbox-label mandatory" htmlFor="factoryproj">
                      Factory Type Project

                    </label>
                    </div>

                    <div className="col-md-2">
                        <ComboBox
                          placeholder="Please select"
                          name="selectedPoolProject"
                          textField="ProjectName"
                          dataItemKey="id"
                          value={this.state.selectedPoolProject}
                          required={true}
                          disabled={true}
                        />
                    </div>

                </div>

                <div className="row mt-5">
                    <div className="col-md-12">
                        <div className="main-heading">Project Phases</div>

                        <div className="row">
                            <div className="col-md-12 btn-align-left mb-2">
                                <Button
                                    primary={true}
                                    look="outline"
                                    onClick={this.toggleProjectPhase}
                                    disabled={this.props.isApproveMode ? true : this.props.isViewMode}
                                >
                                    Define Project Phases
                              </Button>
                                <Button
                                    primary={true}
                                    look="outline"
                                    onClick={this.toggleProjectSubPhase}
                                    disabled={this.props.isApproveMode ? true : this.props.isViewMode}
                                >
                                    Define Project Sub-Phases
                              </Button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 d-flex overflow-auto pb-3">
                                {this.state.phases.map((value, index) => {
                                    return (
                                        <PhaseRow
                                            phase={value}
                                            isEditPhases={false}
                                            isEditSubPhases={false}
                                            key={index}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <label>Partner Commission</label>
              </div>
              <div className="col-md-6">
                <Input
                  disabled={true}
                  value={
                    this.props.cmSheet.PartnerCommission
                      ? this.props.cmSheet.PartnerCommission.toFixed(2).replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ','
                      )
                      : '0.00'
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label>Revenue after disc. quoted to customer</label>
              </div>
              <div className="col-md-6">
                <Input
                  disabled={true}
                  value={
                    this.props.cmSheet.RevenueAfterDiscountQuotedToCustomer
                      ? this.props.cmSheet.RevenueAfterDiscountQuotedToCustomer.toFixed(2).replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ','
                      )
                      : '0.00'
                  }
                />
              </div>
                </div>
            <div className="row">
              <div className="col-md-6">
                <label>Estimated OPE for customer</label>
              </div>
              <div className="col-md-6">
                <Input
                  disabled={true}
                  value={
                    this.props.cmSheet.EstimatedOPECustomer
                      ? this.props.cmSheet.EstimatedOPECustomer.toFixed(2).replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ','
                      )
                      : '0.00'
                  }
                />
              </div>
              </div>
            </div>
          </div>*/}
                </div>

                {this.state.projectPhaseVisible && (
                    <ProjectPhase
                        project={this.props.project}
                        changeRequest={this.props.changeRequest}
                        Ref={this.props.cmSheetReference}
                        closeMethod={this.toggleProjectPhase}
                        phases={this.state.phasesTemp}
                        onSave={this.handlePhasesSave}
                        onUpdate={this.phasesUpdate}
                        onAddPhase={this.handlePhasesAdd}
                        onUpdatePhase={this.handlePhasesUpdate}
                        onRemovePhase={this.handlePhasesRemove}
                        onCancel={this.handleCancel}
                        originalPhases={this.state.phases}
                        isSaveDisable={this.state.isSaveDisable}
                        endDate={this.props.endDate ? this.props.endDate : null}
                    />
                )}
                {this.state.projectSubPhaseVisible && (
                    <ProjectSubPhase
                        project={this.props.project}
                        changeRequest={this.props.changeRequest}
                        Ref={this.props.cmSheetReference}
                        closeMethod={this.toggleProjectSubPhase}
                        phases={this.state.phasesTemp}
                        onSave={this.handlePhasesSave}
                        onUpdate={this.phasesUpdate}
                        onUpdateSubPhase={this.handleSubPhasesUpdate}
                        onAddSubPhase={this.handleSubPhasesAdd}
                        onRemoveSubPhase={this.handleSubPhasesRemove}
                        onCancel={this.handleCancel}
                        originalPhases={this.state.phases}
                        isSaveDisable={this.state.isSaveDisable}
                        endDate={this.props.endDate ? this.props.endDate : null}
                    />
                )}
                {this.state.showMessageDialog === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                                OK
                        </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleConfirmDialog}>
                                No
                        </button>
                            <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                Yes
                        </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                {/* Factory Project Selection */}
                {this.state.linkToPoolDialog === true && (
                  <Dialog title="Convert to Factory Type Project" onClose={this.toggleLinkProjToPoolDialog} width="500px">
                      <p style={{ margin: '25px', textAlign: 'center' }}>Are you sure you want to convert this to a factory type project? </p>
                      <div className="d-block">
                          <label className="mandatory">Select a factory:</label>
                      </div>
                      <div className="d-block">
                          <div className="">
                              <ComboBox
                                placeholder="Please select"
                                name="selectedPoolProject"
                                data={this.state.poolProjectsAll}
                                textField="ProjectName"
                                dataItemKey="id"
                                value={this.state.selectedPoolProject}
                                onChange={this.handleComboChangePoolProject}
                                required={true}
                                filterable={true}
                                onFilterChange={this.filterChangeCombo}
                              />
                          </div>
                      </div>
                      <DialogActionsBar>
                          <button className="k-button" onClick={this.onCreateFactoryproject}>
                              Convert to a factory type project
                          </button>
                          <button className="k-button modal-primary" onClick={this.onCreateNormalProject}>
                              Continue as a normal project
                          </button>
                      </DialogActionsBar>
                  </Dialog>
                )}

                {/* Convert to Normal Project */}
                {this.state.removeLinkToPoolDialog === true && (
                  <Dialog title="Convert to Normal Project" onClose={this.toggleRemoveLinkProjToPoolDialog} width="500px">
                    <p style={{ margin: '25px', textAlign: 'center' }}>Are you sure you want to convert this factory  type project  to a normal project? </p>

                    <DialogActionsBar>
                        <button className="k-button" onClick={this.onConvertToNormal}>
                            Convert to a normal project
                        </button>
                        <button className="k-button modal-primary" onClick={this.onContinueAsFactory}>
                            Continue as a  factory type project
                        </button>
                    </DialogActionsBar>
                </Dialog>
                    )}

            </div>
        );
    }
}

export default ProjectDetails;
