import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { saveAuditTrail } from '../../integration/auditTrail/AuditTrailService';

import { searchQmsDetail, getDivision, getProjectName, getDocumentTemplate, getQmsNumber } from './QmsNumService';

import { DatePicker } from '@progress/kendo-react-dateinputs';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import moment from 'moment';
import 'moment-timezone';
import * as loggerService from "../LoggerService";
import {LOG_TYPES} from "../IntegrationEnums";
import DateCell from './DateCell';

class ViewQMS extends Component {
  _export;

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {

      qmsDetails: [],
      divisionType: [],
      divisionTypeAll: [],
      projectName: [],
      projectNameAll: [],
      createdBy: [],
      createdByAll: [],
      documentTemplate: [],
      documentTemplateAll: [],
      qmsNumber: [],
      qmsNumberAll: [],
      userList: [],
      
      selectedDivision: [],
      selectedProjectName: [],
      selectedCreatedBy: [],
      selectedDocumentTemplate: [],
      selectedQmsNumber: [],
      startDate: null,
      endDate: null,

      skip: 0,
      take: 20,

      sort: [
        { field: 'updatedAt', dir: 'desc' }
      ],

      loading: false

    };
  }

  componentDidMount() {
    this.isMount = true;
    this.props.onHeaderTitleChange('QMS History');
    this.populateQmsDetails();
    this.populateDivision();
    this.populateProjectName();
    this.populateCreatedBy();
    this.populateDocumentTemplate();
    this.populateQmsNumber();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  populateQmsDetails = () => {
    const fields = {};
    searchQmsDetail(fields)
      .then(res => {  
        if (this.isMount) {
          this.setState({
            qmsDetails: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateDivision = () => {
    getDivision()
      .then(res => {
        if (this.isMount) {
          this.setState({
            divisionType: res.data,
            divisionTypeAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateProjectName = () => {
    getProjectName()
      .then(res => {
        if (this.isMount) {
          this.setState({
            projectName: res.data,
            projectNameAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCreatedBy = () => {
    const fields = {};
    searchQmsDetail(fields)
      .then(res => {
        if (this.isMount) {
          const result = res.data.map(value => (value.User))
          this.state.userList = [...new Map(result.map(val =>[val["displayName"], val])).values()]
          this.setState({
            createdBy: this.state.userList,
            createdByAll: this.state.userList
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateDocumentTemplate = () => {
    getDocumentTemplate()
      .then(res => {
        if (this.isMount) {
          this.setState({
            documentTemplate: res.data,
            documentTemplateAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateQmsNumber = () => {
    getQmsNumber()
      .then(res => {
        if (this.isMount) {
          this.setState({
            qmsNumber: res.data,
            qmsNumberAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };


  handleOnChangeDivision = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  handleOnChangeProjectName = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  handleOnChangeCreatedBy = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  handleOnChangeDocumentTemplate = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  handleOnChangeQmsNumber = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };


  handleStartDateChange = event => {
    let startDate = event.target.value;
    if (this.isMount) {
      this.setState({
        startDate: startDate,
        isFormDirty: true
      });
    }
  };

  handleEndDateChange = event => {
    let endDate = event.target.value;
    if (this.isMount) {
      this.setState({
        endDate: endDate,
        isFormDirty: true
      });
    }
  };

  submitSearch = event => {
    event.preventDefault();

    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    const fields = {};
    if (this.state.selectedDivision) {
      fields.division = this.state.selectedDivision.map(value => value.DISTINCT);
    }
    if (this.state.selectedProjectName) {
      fields.projectName = this.state.selectedProjectName.map(value => value.DISTINCT);
    }
    if (this.state.selectedCreatedBy) {
      fields.createdBy = this.state.selectedCreatedBy.map(value => value.id);
    }
    
    if (this.state.selectedDocumentTemplate) {
      fields.documentTemplateName = this.state.selectedDocumentTemplate.map(value => value.DISTINCT);
    }
    if (this.state.selectedQmsNumber) {
      fields.qmsNumber = this.state.selectedQmsNumber.map(value => value.DISTINCT);
    }

    fields.startDate = this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD")  : '1900-01-01';

    fields.endDate = this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD")  : '2199-12-31';

    searchQmsDetail(fields)
      .then(res => {
        if (this.isMount) {
          this.setState({
            qmsDetails: res.data,
            loading: false,
            skip: 0
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  cancelSearch = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        selectedDivision: [],
        selectedProjectName: [],
        selectedCreatedBy: [],
        selectedDocumentTemplate: [],
        selectedQmsNumber: [],
        startDate: null,
        endDate: null
      });
    }
    this.populateDivision();
    this.populateProjectName();
    this.populateCreatedBy();
    this.populateDocumentTemplate();
    this.populateQmsNumber();
    this.populateQmsDetails();
    
  };

  export = () => {
    const auditTrail = {
      Action: 'QMS Template exported to excel',
      RefType: 'QMS Number Generator',
      RefId: '',
      RefName: ''
    };
    saveAuditTrail(auditTrail);
    let ExcelDataSet = this.state.qmsDetails;
    let createdDate;
    for (let i = 0; i < ExcelDataSet.length; i++) {
      createdDate = moment(ExcelDataSet[i].createdAt).format('MM-DD-YYYY');
      ExcelDataSet[i].createdAt = createdDate;
    }
    ExcelDataSet.sort((a, b) => a - b).reverse();
    this._export.save(ExcelDataSet);
  };

  filterDataDivision(filter) {
    const data = this.state.divisionTypeAll.slice();
    return filterBy(data, filter);
  }

  filterChangeDivision = event => {
    if (this.isMount) {
      this.setState({
        divisionType: this.filterDataDivision(event.filter)
      });
    }
  };

  filterDataProjectName(filter) {
    const data = this.state.projectNameAll.slice();
    return filterBy(data, filter);
  }

  filterChangeProjectName = event => {
    if (this.isMount) {
      this.setState({
        projectName: this.filterDataProjectName(event.filter)
      });
    }
  };

  filterDataCreatedBy(filter) {
    const data = this.state.createdByAll.slice();
    return filterBy(data, filter);
  }

  filterChangeCreatedBy = event => {
    if (this.isMount) {
      this.setState({
        createdBy: this.filterDataCreatedBy(event.filter)
      });
    }
  };

  filterDataDocumentTemplate(filter) {
    const data = this.state.documentTemplateAll.slice();
    return filterBy(data, filter);
  }

  filterChangeDocumentTemplate = event => {
    if (this.isMount) {
      this.setState({
        documentTemplate: this.filterDataDocumentTemplate(event.filter)
      });
    }
  };

  filterDataQmsNumber(filter) {
    const data = this.state.qmsNumberAll.slice();
    return filterBy(data, filter);
  }

  filterChangeQmsNumber = event => {
    if (this.isMount) {
      this.setState({
        qmsNumber: this.filterDataQmsNumber(event.filter)
      });
    }
  };

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">QMS History Search</div>
          </div>
        </div>
        <form onSubmit={this.submitSearch}>
          <div className="main-heading" />
          
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                <div className=""><label>Department:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedDivision"
                    textField="DISTINCT"
                    value={this.state.selectedDivision}
                    data={this.state.divisionType}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeDivision}
                    onChange={this.handleOnChangeDivision}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="d-block">
                <div className=""><label>Project/CR Name:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedProjectName"
                    textField="DISTINCT"
                    value={this.state.selectedProjectName}
                    data={this.state.projectName}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeProjectName}
                    onChange={this.handleOnChangeProjectName} 
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="d-block">
                <div className=""><label>Requested By:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    name="selectedCreatedBy"
                    textField="displayName"
                    value={this.state.selectedCreatedBy}
                    data={this.state.createdBy}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeCreatedBy}
                    onChange={this.handleOnChangeCreatedBy}
                  />
                
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="d-block">
                <div className=""><label>Document Name:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedDocumentTemplate"
                    textField="DISTINCT"
                    value={this.state.selectedDocumentTemplate}
                    data={this.state.documentTemplate}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeDocumentTemplate}
                    onChange={this.handleOnChangeDocumentTemplate} 
                  />
                </div>
              </div>
            </div>
            </div>

            <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                <div className=""><label>QMS Number:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedQmsNumber"
                    textField="DISTINCT"
                    value={this.state.selectedQmsNumber}
                    data={this.state.qmsNumber}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeQmsNumber}
                    onChange={this.handleOnChangeQmsNumber} 
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="d-block">
                  <label htmlFor="">Date From: </label>
                </div>
                <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    width="100"
                    name="startDate"
                    onChange={this.handleStartDateChange}
                    value={this.state.startDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="d-block">
                  <label htmlFor="">Date To:</label>
                </div>
                <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    width="100"
                    name="endDate"
                    onChange={this.handleEndDateChange}
                    value={this.state.endDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                    min={new Date(this.state.startDate)}
                  />
                </div>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch}>
                Clear
              </Button>
            </div>
          </div>

        </form>

        <div className="main-seperator" />



        <div className="row">
          <div className="col-md-6">
            <div className="main-heading">Search Results</div>
          </div>
          <div className="col-md-6">
          <div className="btn-align-right">
              <button
                title="Export Excel"
                className="k-button k-primary"
                onClick={this.export}
              >
                Export to Excel
                    </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            
            <ExcelExport
              ref={(exporter) => {
                this._export = exporter;
              }}
              fileName={'QMS List_' + new Date()}
            >
              <Grid
                data={orderBy(this.state.qmsDetails, this.state.sort).slice(
                  this.state.skip,
                  this.state.take + this.state.skip
                )}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.qmsDetails.length}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
                sortable
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }}
                className="qms-detail-grid"
              >
                <Column field="division" title="Department" />
                <Column field="documentTemplateName" title="Document Name" />
                <Column field="projectName" title="Project/CR Name" />
                <Column field="qmsNumber" title="QMS Number" />
                <Column field="createdAt" title="Created At" 
                cell={props => <DateCell {...props} />} />
                <Column field="User.displayName" title="Requested By" />
              </Grid>
            </ExcelExport>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewQMS;



