import React, { Component } from 'react';
import 'moment-timezone';
import moment from 'moment';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy } from '@progress/kendo-data-query';
import { Redirect } from 'react-router';
import { firstDayOfMonth, lastDayOfMonth } from '@progress/kendo-date-math';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { getProjectStatuses } from "../../projectManagement/project/ProjectService";
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import {saveDocumentType, updateDocumentType, getAllDocumentTypesByName, getActivityLogData } from './DocumentTypeService'
import { uniq } from 'lodash';

class DocumentTypeDetails extends Component {

    isMount = false;
  
    constructor(props) {
      super(props);
      this.state = {
        statusItems: [],
        documentNames: [],
        documentName: '',
        isFormDirty: false,
        visible: false,
        dialogMessage: '',
        dialogTitle: '',
        isErrorMessageVisible: false,
        toSearch: false,
        isDisableSave: false,
        showCancelConfirmation: false,
        previousDocType: ''
      };
    }
  
    componentDidMount() {
      this.isMount = true;
      window.scrollTo(0, 0);
      this.setHeaderTitle();
      this.retrieveAllProjectStatuses();
      this.retrieveActivityLogData();
      this.state.documentNames = this.props.location.documentNames;
    }
  
    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Document Type');
        }
    };

    retrieveAllProjectStatuses = () => {
      getProjectStatuses().then(response => {
        let list = response.data;
        list = response.data.filter(obj => (obj.Code !== 'BILLABALE_CR' && obj.Code !== 'ON_HOLD'));

        list.forEach(data => {
          data.IsCRMandatory = false;
          data.IsProjectMandatory = false;
          data.IsCRApplicable = false;
          data.IsProjectApplicable = false;
          data.docTypeID = null;
        })

        this.setState({
          statusItems: list
        });

        if(this.props.location.action === 'view' || this.props.location.action === 'edit' ){
          this.setProjectStatuses()
        }
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }

    setProjectStatuses(){

      const valuePassed = this.props.location.docType;

      getAllDocumentTypesByName(valuePassed.DocType).then(response => {
        let docTypes = response.data;
        const list = this.state.statusItems

        list.forEach(data => {

          const index = docTypes.findIndex(obj => obj.Status.id == data.id);

          if (index > -1){
            const val = docTypes[index]
            data.IsCRMandatory = val.IsCRMandatory;
            data.IsProjectMandatory =  val.IsProjectMandatory;
            data.IsCRApplicable =  val.IsCRApplicable;
            data.IsProjectApplicable =  val.IsProjectApplicable;
            data.docTypeID = val.id;
          }
        })

        this.setState({
          statusItems: list,
          documentName : valuePassed.DocType,
          previousDocType: valuePassed.DocType
        });
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }


    retrieveActivityLogData = () => {

      if(this.props.location.action === 'edit' ){

        const docType = this.props.location.docType;

        getActivityLogData(docType.DocType).then(response => {
          let activityLogData = response.data;
          let usedStatuses = [];
          let uniqueUsedStatuses = [];
          let statusItems = this.state.statusItems;
          let statuses = [];

          if(activityLogData && activityLogData.length > 0){
            activityLogData.forEach(el => {
              if(el.ActivityLog){
                usedStatuses.push(el.ActivityLog.Status);
              }
            })
          }

          uniqueUsedStatuses = uniq(usedStatuses, false);

          uniqueUsedStatuses.forEach(el => {
            let item = { statusId: el };
            statuses.push(item);
          })
          
          if((statusItems && statusItems.length > 0) && (statuses && statuses.length > 0)){
            for(let el of statusItems){
              for(let sts of statuses){
                if(sts.statusId == el.id){
                  el.disableBtn = true;
                  break;
                }
                else{
                  el.disableBtn = false;
                }
              }
            }
          }

        }).catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      }
    }


    onRadioBtnChanged = async event => {

      let values = this.state.statusItems
      const index = values.findIndex(obj => obj.id == event.target.id);
      let item = values[index];

      this.state.isFormDirty = true;

      switch (event.target.name) {
        case 'project_A' + event.target.id:
          if (item.IsProjectApplicable === false){
            item.IsProjectApplicable = true;
            item.IsProjectMandatory = false;
          }else if (item.IsProjectApplicable === true){
            item.IsProjectApplicable = false;
            item.IsProjectMandatory = false;
          }
          break;
        case 'project_M'+ event.target.id:
          if (item.IsProjectMandatory === false){
            item.IsProjectApplicable = false;
            item.IsProjectMandatory = true;
          }else if (item.IsProjectMandatory === true){
            item.IsProjectApplicable = false;
            item.IsProjectMandatory = false;
          }
          break;
        case 'cr_A' + event.target.id:
          if (item.IsCRApplicable === false){
            item.IsCRApplicable = true;
            item.IsCRMandatory = false;
          }else if (item.IsCRApplicable === true){
            item.IsCRApplicable = false;
            item.IsCRMandatory = false;
          }
          break;
        case 'cr_M' + event.target.id:
          if (item.IsCRMandatory === false){
            item.IsCRApplicable = false;
            item.IsCRMandatory = true;
          }else if (item.IsCRMandatory === true){
            item.IsCRApplicable = false;
            item.IsCRMandatory = false;
          }
          break;
        default:
          break
      }

      values[index] = item;
      this.setState({statusItems: values})
    }

    toggleDialog = (message, title) => {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    };

    toggleCancelConfirmDialog = () => {
      this.setState({
        showCancelConfirmation: !this.state.showCancelConfirmation
      });
    };

    validateProperty = value => {
        if (value) {
          return 'd-none';
        } else {
          return 'inline-error';
        }
      };

    validation = () => {
        if (
          this.validateProperty(this.state.documentName)
            .toString()
            .includes('error')
        ) {
          return false;
        } else  {
          return true;
        }
      };

    isDocExists = () => {
        const index = this.state.documentNames.findIndex(val => val.name.toUpperCase() === this.state.documentName.toUpperCase());
        if (index === -1){
          return false
        }else{
          return true
        }
      }

    handleChangeDocTypeName = async event => {
      await this.setState({ documentName: event.target.value, isErrorMessageVisible: false, isFormDirty: true });
    };

    handleSubmit = event => {
      event.preventDefault();

        if (!this.validation()) {
          this.setState({
            isErrorMessageVisible: true
          });
          this.setState({
            visible: true,
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleDialog(
            'Please fix the highlighted errors to continue',
            'Error'
          );
        } else if (this.props.location.action === 'create' && this.isDocExists()){
          this.setState({
            visible: true,
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleDialog(
            'Document type already exists. Please change the Document type name',
            'Error'
          );
        } else {
          this.setState({
            isErrorMessageVisible: false
          });
          if (this.props.location.action === 'edit') {
            this.updateDocument(event);
          } else {
            this.createDocType(event);
            this.setState({
              isDisableSave: true
            });
          }
        }

    }

    updateDocument = event => {

      const values = this.state.statusItems;
      const values_to_send = [];
      let docName = this.state.documentName.trim();

      values.forEach(data => {
        const docTypeData = {
          DocType: docName,
          IsActive: true,
          StatusId: data.id,
          IsCRMandatory: data.IsCRMandatory,
          IsProjectMandatory: data.IsProjectMandatory,
          IsCRApplicable: data.IsCRApplicable,
          IsProjectApplicable: data.IsProjectApplicable,
          UpdatedUser: window.LOGGED_USER.displayName,
          updatedAt: new Date(),
          docTypeID: data.docTypeID,
          previousDocType: this.state.previousDocType
        };
        values_to_send.push(docTypeData)
      })

      const obj = {
        docTypes : values_to_send
      }

    updateDocumentType(obj)
      .then(res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog(`The Document type \`${this.state.documentName} \' is successfully updated`, 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          isDisableSave: false,
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog(error.response.data, 'Error');
      });
  };

    createDocType = event => {

      const values = this.state.statusItems;
      const values_to_send = [];
      let docName = this.state.documentName.trim();

      values.forEach(data => {
        const docTypeData = {
          DocType: docName,
          StatusId: data.id,
          IsActive: true,
          IsCRMandatory: data.IsCRMandatory,
          IsProjectMandatory: data.IsProjectMandatory,
          IsCRApplicable: data.IsCRApplicable,
          IsProjectApplicable: data.IsProjectApplicable,
          UpdatedUser: window.LOGGED_USER.displayName,
          CreatedUser: window.LOGGED_USER.displayName,
          createdAt: new Date(),
          updatedAt: new Date()
        };
        values_to_send.push(docTypeData)
      })

      const obj = {
        docTypes : values_to_send
      }

      saveDocumentType(obj)
        .then(res => {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
          this.toggleDialog(
            `The Document Type \'${this.state.documentName}\' is successfully created`,
            'Success'
          );
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.toggleDialog(error.response.data, 'Error');
          this.setState({
            isDisableSave: false
          });
        });
    };

    onClickCancel = event => {
      if (this.state.isFormDirty) {
        this.toggleCancelConfirmDialog();
      } else {
        this.navigateBack(event);
      }
    };

    navigateBack = event => {
      this.setState({
        toSearch: true
      });
    };

    render() {
      if (this.state.toSearch === true) {
        return <Redirect to="/masterdata/documenttype/documenttypesearch"/>;
      }
      return (
        <div className="main-card">
        <div className="row">
            <div className="col-md-12">
                <div className="main-heading">Document Type Details</div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-2">
                <div className="d-block">
                    <label className="mandatory">Document Type:</label>
                </div>
                <div className="d-block">
                    <div className="">
                        <Input
                          name="documentName"
                          autoComplete="off"
                          value={this.state.documentName}
                          required={true}
                          maxLength={50}
                          onChange={this.handleChangeDocTypeName}
                          disabled={this.props.location.action === 'view'}
                        />
                      {this.state.isErrorMessageVisible === true ? (
                        <span className={this.validateProperty(this.state.regionName)}>
                            Document type is mandatory
                        </span>
                      ) : null}
                    </div>
                </div>
            </div>
        </div>

        <div className="main-seperator"></div>

        <div className="row">
            <div className="col-md-12">
                <div className="main-heading">Search Results</div>
            </div>
        </div>

        <div className="row mt-0">
            <div className="col-md-12 doc-details">
                <Grid 
                    resizable
                    data={this.state.statusItems}
                >
                    <Column field="StatusName" title="Status" className="float-left"/>
                    <Column field="" title="Project">
                        <Column title="Applicable" cell={props => (<td>
                            <input type="radio"
                                   id={props.dataItem.id}
                                   name={"project_A" + props.dataItem.id}
                                   className="k-radio"
                                   checked={props.dataItem.IsProjectApplicable === true}
                                   onClick={this.onRadioBtnChanged}
                                   disabled={(this.props.location.action === 'view')}
                            />
                        </td>)}/>
                        <Column title="Mandatory" cell={props => (<td>
                            <input type="radio"
                                   id={props.dataItem.id}
                                   name={"project_M" + props.dataItem.id}
                                   className="k-radio"
                                   checked={props.dataItem.IsProjectMandatory === true}
                                   onClick={this.onRadioBtnChanged}
                                   disabled={(this.props.location.action === 'view') }
                            />
                        </td>)}/>
                    </Column>
                    <Column field="" title="CR">
                        <Column title="Applicable" cell={props => (<td>
                            <input type="radio"
                                   id={props.dataItem.id}
                                   name={"cr_A" + props.dataItem.id}
                                   className="k-radio"
                                   checked={props.dataItem.IsCRApplicable === true}
                                   onClick={this.onRadioBtnChanged}
                                   disabled={(this.props.location.action === 'view')}
                            />
                        </td>)}/>
                        <Column title="Mandatory" cell={props => (<td>
                            <input type="radio"
                                   id={props.dataItem.id}
                                   name={"cr_M" + props.dataItem.id}
                                   className="k-radio"
                                   checked={props.dataItem.IsCRMandatory === true}
                                   onClick={this.onRadioBtnChanged}
                                   disabled={(this.props.location.action === 'view')}
                            />
                        </td>)}/>
                    </Column>
                        
                </Grid>
            </div>
        </div>

          <div className="row mt-3">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.handleSubmit} disabled={this.state.isDisableSave || this.props.location.action === 'view'}>
                Save
              </Button>
              <Button type="button" onClick={this.onClickCancel}>
                Cancel
              </Button>
            </div>
          </div>

          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error Image"/>
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.navigateBack}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>

          <div>
            {this.state.showCancelConfirmation === true && (
              <Dialog
                title="Please Confirm"
                onClose={this.toggleCancelConfirmDialog}
                width="300px"
              >
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contains unsaved changes. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                    No
                  </button>
                  <button
                    className="k-button modal-primary"
                    onClick={this.navigateBack}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>

    </div>
      );
    }   
  }
  
  export default DocumentTypeDetails;
