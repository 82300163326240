import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';

class InfoDialog extends Component {

    render() {

        return (
            <Dialog title={this.props.dialogTitle} onClose={this.props.dismissCallback} width="300px">
                {this.props.showSuccessImage === true && (
                    <div className="text-center">
                        <img className="successImg" src={SuccessImg} alt="Success"/>
                    </div>
                )}
                {this.props.showErrorImage === true && (
                    <div className="text-center">
                        <img className="successImg" src={ErrorImg} alt="Error Image"/>
                    </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.props.dialogMessage}</p>
                <DialogActionsBar>
                    <button className="k-button modal-primary" onClick={this.props.dismissCallback} >
                    OK
                    </button>
                </DialogActionsBar>
            </Dialog>
        )

    }
}

export default InfoDialog;
