import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem, PanelBarUtils, Menu, MenuItem, MenuItemModel, MenuItemLink, MenuItemArrow, Splitter } from '@progress/kendo-react-layout';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactDOM from 'react-dom';
import '../Spark.css';


class SkillSearch extends Component {
    render() {
        return (
            <div>
                <div className="main-card">

                    <div className="row">

                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Skill Category Name:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Status:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>

                        <div className="col-md-4 offset-4 btn-align-right">
                            <Button primary={true}>Create New</Button>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                            <Button primary={true}>Search</Button>
                            <Button>Clear</Button>
                        </div>
                    </div>

                </div>

                <div className="main-card">

                    <div className="row">

                        <div className="col-md-6">
                            <div className="main-heading">
                                Search Results
                            </div>
                        </div>

                        <div className="col-md-6 btn-align-right">
                            <Button primary={true}>Export to Excel</Button>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Grid
                                style={{ height: '100px' }}
                            >
                                <Column field="ProductID" title="ID" width="40px" />
                                <Column field="ProductName" title="Name" width="250px" />
                                <Column field="Category.CategoryName" title="CategoryName" />
                                <Column field="UnitPrice" title="Price" />
                                <Column field="UnitsInStock" title="In stock" />
                            </Grid>
                        </div>
                    </div>

                </div>

                {/* <Dialog title={"Skill Type"} onClose={this.toggleDialog} width={200}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-block">
                                <label className="new-label">Skill Category:</label>
                            </div>
                            <div className="d-block">
                                <Input />
                            </div>
                        </div>
                    </div>
                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>Save</button>
                    </DialogActionsBar>
                </Dialog> */}



                {/* <Dialog title={"Skill Type"} onClose={this.toggleDialog} width={600}>
                    <div className="row">

                        <div className="col-md-4">
                            <div className="d-block">
                                <label className="new-label">Skill:</label>
                            </div>
                            <div className="d-block">
                                <Input />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="d-block">
                                <label className="new-label">Skill Category:</label>
                            </div>
                            <div className="d-block">
                                <Input />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="d-block">
                                <label className="new-label">Skill Owner:</label>
                            </div>
                            <div className="d-block">
                                <Input />
                            </div>
                        </div>

                    </div>
                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>Save</button>
                    </DialogActionsBar>
                </Dialog> */}

                {/* <Dialog title={"Bulk Replace Skill Owner"} onClose={this.toggleDialog} width={600}>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-inline-block mr-2"><label>Current Skill Owner(s):</label></div>
                            <div className="d-inline-block skill-owner-wrap">
                                <div>Text</div>
                                <div>Text</div>
                                <div>Text</div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-6">
                            <div className="d-block">
                                <label className="new-label">Skill Category:</label>
                            </div>
                            <div className="d-block">
                                <Input />
                            </div>
                        </div>

                    </div>
                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>Save</button>
                    </DialogActionsBar>
                </Dialog> */}

                {/* <Dialog title={"Define Skill Criteria"} onClose={this.toggleDialog} width={1100}>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="d-block">
                                <label className="new-label">Skill Category:</label>
                            </div>
                            <div className="d-block">Technical Skills</div>
                        </div>

                        <div className="col-md-4">
                            <div className="d-block">
                                <label className="new-label">Skill Type:</label>
                            </div>
                            <div className="d-block">Java Development</div>
                        </div>

                        <div className="col-md-4">
                            <div className="d-block">
                                <label className="new-label">Skill Owner:</label>
                            </div>
                            <div className="d-block">Not Evaluated</div>
                        </div>
                    </div>

                    <div className="main-seperator"></div>

                    <div className="main-heading">
                        Add Skill Evaluation Criteria
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="d-block">
                                <label className="new-label">Skill Criteria:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="d-block">
                                <label className="new-label"></label>
                            </div>
                            <div className="d-block">
                                <textarea className="w-100" rows="1" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="d-block">
                                <label className="new-label">Skill Level:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-12 btn-align-right">
                            <Button primary={true}>Add</Button>
                            <Button>Clear</Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Grid style={{ height: '100px' }}>
                                <Column field="Beginner" title="Beginner" />
                                <Column field="Practitioner" title="Practitioner" />
                                <Column field="Expert" title="Expert" />
                            </Grid>
                        </div>
                    </div>

                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>Save</button>
                    </DialogActionsBar>
                </Dialog> */}



            </div>
        );
    }
}

export default SkillSearch;
