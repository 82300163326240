import React, { Component } from 'react';
import ParallelCard from './ParallelCard';

class WorkflowApprovers extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      onAddParallel,
      Card,
      onRemoveParallel,
      OnMinusApprovers,
      OnPlusApprovers,
      filterChangeApprover,
      Approvers,
      selectedApproverValue,
      approvalTypes,
      handleChangeDropDown,
      isEditMode,
      isDuplicateMode,
      handleOnChangeApprovalType,
      validateProperty
    } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 add-level-overflow">
            {Card &&
              Card.map((mainItem, index) => {
                return (
                  <div className="add-level" key={`${index}`}>
                    <div className="add-level-wrap">
                      {mainItem.mainApproverCardHorizontal &&
                        mainItem.mainApproverCardHorizontal.map((item, indexVer) => {
                          let vertCardLength = mainItem.mainApproverCardHorizontal.length;
                          return item.approverCardVertical.map((itemVert, indexPar) => {
                            let classN = '';
                            if (indexVer !== vertCardLength - 1) {
                              classN = 'd-none';
                            }
                            return (
                              <ParallelCard
                                key={`${index}/${indexVer}/${indexPar}`}
                                Approvers={Approvers}
                                Card={this.props.Card}
                                CardApprovers={itemVert}
                                onAddParallel={() => onAddParallel(index)}
                                onRemoveParallel={() => onRemoveParallel(index, indexVer)}
                                OnMinusApprovers={() => OnMinusApprovers(index, indexVer, indexPar)}
                                OnPlusApprovers={() => OnPlusApprovers(index, indexVer, indexPar)}
                                filterChangeApprover={filterChangeApprover}
                                selectedApproverValue={selectedApproverValue}
                                index={index}
                                indexVer={indexVer}
                                indexPar={indexPar}
                                handleChangeDropDown={handleChangeDropDown}
                                isEditMode={isEditMode}
                                isDuplicateMode={isDuplicateMode}
                                classN={classN}
                                handleOnChangeApprovalType={handleOnChangeApprovalType}
                                approvalTypes={approvalTypes}
                              />
                            );
                          });
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WorkflowApprovers;
