import React, { Component } from "react";

class EditSubLineActionCell extends Component {

    


    render() {
        return(
            <td className="action-buttons">
                {/* <a href="#">
                    <div className="rounded-link" title="Delete">
                        <span className="k-icon k-i-delete" />
                    </div>
                </a> */}
                
            </td>
        );
    }

}

export default EditSubLineActionCell;
