import React, { Component } from 'react';

class CriteriaRangeCell extends Component {
  render() {
    if(this.props.dataItem.WorkflowOperator.Name === 'Less than'){
      const value = 'Less than ' + this.props.dataItem.Value2
      return <td>
          {value}
      </td>;
    }else if(this.props.dataItem.WorkflowOperator.Name === 'Between'){
      const value = 'Between  ' + this.props.dataItem.Value1 + ' - ' + this.props.dataItem.Value2
      return <td>
        {value}
      </td>;
    }else if(this.props.dataItem.WorkflowOperator.Name === 'Equal to'){
      const value = 'Equal to ' + this.props.dataItem.Value2
      return <td>
        {value}
      </td>;
    } else if(this.props.dataItem.WorkflowOperator.Name === 'Greater than'){
      const value = 'Greater than ' + this.props.dataItem.Value2
      return <td>
        {value}
      </td>;
    }else{
      return <td>
        {""}
      </td>;
    }
  }
}

export default CriteriaRangeCell;
