import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Redirect } from 'react-router';
import {
  getProjectResources
} from '../../resourceManagement/ResourceService';
import {
  saveSalesPerson,
  updateSalesPerson
} from './CommissionService';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../../src/integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

class SalesPersonCreate extends Component {
  default;

  isMount = false;

  popupSet = {
    width: 'auto'
  };

  constructor(props) {
    super(props);
    console.log(window.LOGGED_USER);
    this.state = {
      toSalesPerson: false,
      salesPersonText: '',
      commissionPercentageText: '',
      salesPersonId: null,
      salesPersonValue: null,
      salesPersonActive: false,
      visible: false,
      success: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      isEnableCreate: false,
      isEnableUpdate: false,
      isDisableCombo: false,
      isDisableEditMode: false,
      isDisableSave: false,
      isSaveButtonClicked: false
    };

    this.handleChangeSalesPersonName = this.handleChangeSalesPersonName.bind(
      this
    );
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    this.isMount = true;

    if (this.props.location.action === 'edit') {
      this.updateSalesPersonDetail(this.props.location.criteriaItem);
      if (this.isMount) {
        this.setState({ isDisableCombo: true });
      }
    }

    await getProjectResources()
      .then(response => {
        if (this.isMount) {
          this.setState({
            SalesPersonData: response.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  render() {
    if (this.state.toSalesPerson === true) {
      return (
        <Redirect
          to={{
            pathname: '/admin/Commissions/Commissions',
            CriteriaStatus: 'SalesPerson'
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <div className="main-card">
          <div className="main-heading">Sales Person Details</div>
          <div className="main-seperator"/>
          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                  <label className="mandatory">Sales Person Name:</label>
                </div>
                <div className="d-block">
                <div className="" id="commissionNameToolTip">
                  <ComboBox
                    placeholder="Please Select"
                    name="salesPerson"
                    data={this.state.SalesPersonData}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleChangeSalesPersonName}
                    required={true}
                    value={this.state.salesPersonValue}
                    filterable={true}
                    // onFilterChange={this.filterChangeDesignationGroup}
                    disabled={this.state.isDisableCombo}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span
                      className={this.validateProperty(this.state.salesPersonValue)}
                    >
                      Please select a Sales Person
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>

            <div className="col-md-2">
              <div className="d-block">
                  <label className="mandatory">Commission %:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <Input
                    autoComplete="off"
                    type="number"
                    name="commissionPercentageText"
                    required={true}
                    maxLength="100"
                    value={this.state.commissionPercentageText}
                    onChange={this.handleChangeCommissionPercentage}
                    disabled={this.state.isEditMode}
                    min="0"
                    max="100"
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span
                      className={this.validateProperty(
                        this.state.commissionPercentageText
                      )}
                    >
                      Please enter Commission %
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 btn-align-right">
              <Button
                primary={true}
                disabled={this.state.isDisableSave}
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
              <Button onClick={this.onClickCancel}>Cancel</Button>
            </div>
          </div>
          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error"/>
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.navigateBack}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.showCancelConfirmation === true && (
              <Dialog
                title="Please Confirm"
                onClose={this.toggleCancelConfirmDialog}
                width="300px"
              >
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contains unsaved changes. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button
                    className="k-button active"
                    onClick={this.toggleCancelConfirmDialog}
                  >
                    No
                  </button>
                  <button
                    className="k-button modal-primary"
                    onClick={this.navigateBack}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  navigateBack = event => {
    if (this.isMount) {
      this.setState({
        toSalesPerson: true
      });
    }
  };

  handleChangeSalesPersonName = async event => {
    if (this.isMount) {
      await this.setState({
        salesPersonValue: event.target.value,
        isFormDirty: true
      });
    }
    await this.validateProperty(event.target.value);
  };

  handleChangeCommissionPercentage = async event => {
    if (this.isMount) {
      await this.setState({
        commissionPercentageText: event.target.value,
        isFormDirty: true
      });
    }
    await this.validateProperty(event.target.value);
  };

  updateSalesPersonDetail = SalesPerson => {
    if (this.isMount) {
      this.setState({
        isDisableEditMode: true
      });
    }
    if (SalesPerson) {
      this.isEnableCreate = true;
      this.isEnableUpdate = false;
      let SalesPersonGroupObj = null;
      let SalesPersonStatus = false;
      if (SalesPerson) {
        SalesPersonGroupObj = {
          Name: SalesPerson.Name,
          id: SalesPerson.id
        };
      }
      if (SalesPerson.IsActive) {
        SalesPersonStatus = true;
      }
      if (this.isMount) {
        this.setState({
          salesPersonValue: SalesPersonGroupObj,
          commissionPercentageText: SalesPerson.CommissionPercentage,
          SalesPersonActive: SalesPersonStatus
        });
      }
    } else {
      return null;
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    console.dir(event.target);
    if (!this.validation()) {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: true,
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleDialog(
        'Please fix the highlighted errors to continue',
        'Error'
      );
    } else {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: false
        });
      }
      if (this.props.location.action === 'edit') {
        this.updateSalesPerson(event);
      } else {
        this.createSalesPerson(event);
        if (this.isMount) {
          this.setState({
            isDisableSave: true
          });
        }
      }
    }
  };

  createSalesPerson = event => {
    const SalesPerson = {
      Name: this.state.salesPersonValue.Name,
      ResourceId: this.state.salesPersonValue.id,
      CommissionPercentage: this.state.commissionPercentageText,
      IsActive: true,
      updatedUser: window.LOGGED_USER.displayName
    };
    saveSalesPerson(SalesPerson)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(
          `The SalesPerson ${res.data.Name} is successfully created`,
          'Success'
        );
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            isDisableSave: false,
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  updateSalesPerson = event => {
    const SalesPerson = {
      Name: this.state.salesPersonValue.Name,
      ResourceId: this.state.salesPersonValue.ResourceId,
      CommissionPercentage: this.state.commissionPercentageText,
      updatedUser: window.LOGGED_USER.displayName,
      IsActive:
        this.state.salesPersonValue.IsActive === undefined
          ? true
          : this.state.salesPersonValue.IsActive
    };

    updateSalesPerson(SalesPerson, this.state.salesPersonValue.id)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(
          'The SalesPerson has been successfully updated',
          'Success'
        );
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  validation = () => {
    if (
      this.validateProperty(this.state.commissionPercentageText).includes(
        'error'
      )
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.salesPersonValue).includes('error')
    ) {
      return false;
    } else if (
      this.state.commissionPercentageText > 100 ||
      this.state.commissionPercentageText < 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleCancelConfirmDialog = () => {
    if (this.isMount) {
      this.setState({
        showCancelConfirmation: !this.state.showCancelConfirmation
      });
    }
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };
}

export default SalesPersonCreate;
