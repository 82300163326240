import http from '../HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Search qmsDetails.
 * @param qmsDetails - (object)
 * @returns List of QMS Details.
 */
export function searchQmsDetail(qmsDetails) {
  const endPoint = apiUrl + '/qms/qmsListView/search';
  return http.post(endPoint, qmsDetails);
}


/**
 * Get Division.
 * @returns List of Division details.
 */
export function getDivision() {
  const endPoint = apiUrl + '/qms/qmsListView/getDivision';
  return http.post(endPoint);
}

/**
 * Get ProjectName.
 * @returns List of ProjectNames.
 */
export function getProjectName() {
  const endPoint = apiUrl + '/qms/qmsListView/getProjectName';
  return http.post(endPoint);
}

/**
 * Get DocumentTemplates.
 * @returns List of DocumentTemplates.
 */
export function getDocumentTemplate() {
  const endPoint = apiUrl + '/qms/qmsListView/getDocumentTemplate';
  return http.post(endPoint);
}

/**
 * Get QmsNumber.
 * @returns List of QmsNumbers.
 */
export function getQmsNumber() {
  const endPoint = apiUrl + '/qms/qmsListView/getQmsNumber';
  return http.post(endPoint);
}

 /** 
 * saveQMSDetails - Save QMS Details
 * @param params
 * @returns {array} array of saved details of QMS 
 */
export function saveQMSDetails(params) {
  const endPoint = apiUrl + '/qms/qmsListView/saveQMSDetails';
  return http.post(endPoint, params);
}