import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { MultiSelect  } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Link } from 'react-router-dom';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import { searchHrDesignations, updateHRDesignationStatus, getAllActiveHRDesignations, getCostDesignations } from './HrDesignationService';
import CreatedDateCell from '../designation/components/CreatedDateCell';
import UpdatedDateCell from '../designation/components/UpdatedDateCell';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import HrDesignationActionCell from './components/HrDesignationActionCell';
import { Redirect } from 'react-router';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';

class HRDesignationSearch extends Component {

  isMount = false;

  componentDidMount() {
    this.isMount = true;
    window.scrollTo(0, 0);
    this.setHeaderTitle();
    this.populateHRDesignations();
    this.populateCostDesignations();

    this.onSearchRequest();
  }

  componentWillUnmount() {
    this.isMount = false;
    
  }

  constructor(props) {
    super(props);

    this.state = {
      hrDesignations: [],
      hrAll: [],
      costDesignationAll: [],
      gridValue: [],
      CostDesignations: [],
      skip: 0,
      take: 20,
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      SearchRequest: {
        DesignationName: '',
        CostDesignation: ''
      },
      showSuccessImage: false,
      showErrorImage: false,
      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      deactivateDesignationId: null,
      deactivatePopupVisible: false,
      activateDesignationId: null,
      activatePopupVisible: false,
      DesignationName: '',
      CostDesignation: ''
    };
  }

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">HR Designation Search</div>
          </div>
          {/* <div className="col-md-4">
            <Button primary={true} className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('EDT_HR_DESG') > -1)}>
              <Link
                style={{ textDecoration: 'none', color: 'white' }}
                to={{
                  pathname: '/admin/hrdesignation/create',
                  action: 'create'
                }}
              >
                Create New HR Designation
              </Link>
            </Button>
          </div> */}
        </div>

        <form>
          <div className="row">
          <div className="col-md-2">
              <div className="d-block">
                  <label>HR Designation Name:</label>
                </div>
                <div className="d-block">
                <div className="">
                   <Input
                    name="DesignationName"
                    value={this.state.SearchRequest.DesignationName}
                    onChange={this.handleOnChangeInput}
                    // textField="Name"
                    // data={this.state.hrDesignations}
                    // placeholder={'Please Select'}
                    // filterable={true}
                    // onFilterChange={this.filterOnChange}
                  />    
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>
            <div className="col-md-2">
              <div className="d-block">
                  <label>Cost Designation Name :</label>
                </div>
                <div className="d-block">
                <div className="">
                <Input
                    name="CostDesignation"
                    value={this.state.SearchRequest.CostDesignation}
                    onChange={this.handleOnChangeInput}
                    // textField="Name"
                    // data={this.state.CostDesignations}
                    // placeholder={'Please Select'}
                    // filterable={true}
                    // onFilterChange={this.filterOnChange}
                  />    
                </div>
              </div>
            </div>

            {/* <div className="col-md-1"></div>
            <div className="col-md-2">
              <div className="d-block">
                  <label>Designation Level:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                  />
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>
            <div className="col-md-2">
              <div className="d-block">
                  <label>Status:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                  />
                </div>
              </div>
            </div> */}

            </div>
            <div className="row">
                <div className="col-md-12 btn-align-right">
                    <Button primary={true} type="submit" style={{ marginRight: '5px' }} onClick={this.submitSearch}>
                        Search
                    </Button>
                    <Button type="button" onClick={this.cancelSearch} >
                        Clear
                    </Button> 
                </div>
            </div>
          

          <div className="main-seperator"/>

          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">Search Results</div>
            </div>
          </div>

          <div className="row mt-0">
            <div className="col-md-12">
              <Grid
                data={orderBy(this.state.gridValue, this.state.sort).slice(
                  this.state.skip,
                  this.state.take + this.state.skip
                )}
                ref={(g) => {
                  this.grid = g;
                }}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.gridValue.length}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }
                }
              >
                <Column field="Name" title="HR Designation"/>
                <Column
                  field="CostDesignations"
                  title="Cost Designation"
                />
                <Column
                  field="HrDesignationLevel"
                  title="Designation Level"
                />
                <Column
                  field="createdAt"
                  title="Created Date"
                  width="120px"
                  cell={props => <CreatedDateCell {...props} />}
                />
                <Column field="updatedAt" title="Last Modified Date"
                  width="120px"
                  cell={props => <UpdatedDateCell {...props} />}
                />
                <Column field="updatedUser" width="120px" title="Last Modified User" />
                <Column
                  field=""
                  title="Actions"
                  width="150px"
                  cell={props => (
                    <HrDesignationActionCell
                      {...props}
                      OnDeactivate={this.OnDeactivate}
                      OnActivate={this.OnActivate}
                    />
                  )}
                />
              </Grid>
            </div>
          </div>
          <div>
            {this.state.showMessageDialog === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error"/>
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.deactivatePopupVisible && (
              <Dialog title={'Please Confirm'} onClose={this.deactivateConfirmationClose}>
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to Deactivate this?
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.deactivateConfirmationClose}>
                    No
                  </button>
                  <button
                    className="k-button modal-primary"
                    onClick={(e) => {this.deactivateConfirmationOk(e)}}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.activatePopupVisible && (
              <Dialog title={'Please Confirm'} onClose={this.activateConfirmationClose}>
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to Activate this?
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.activateConfirmationClose}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={(e) => {this.activateConfirmationOk(e)}}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </form>
      </div>
    );
  }

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  filterOnChange = event => {
    clearTimeout(this.timeout);
    const field = event.target.name;
    this.timeout = setTimeout(() => {
      switch (field) {
        case 'DesignationName': {
          if (this.isMount) {
            this.setState({
                hrDesignations: this.filterComboData(event.filter, this.state.hrAll)
            });
          }
          break;
        }
        case 'CostDesignation': {
          if (this.isMount) {
            this.setState({
              CostDesignations: this.filterComboData(event.filter, this.state.costDesignationAll)
            });
          }
          break;
        }
        default: {
          break;
        }
      }
    }, 0)
  }
  
  handleOnChangeInput = event => {
    const field = event.target.name;
    const value = event.target.value;
    if (this.isMount) {
      this.setState(prevState => {
        return {
          SearchRequest: { ...prevState.SearchRequest, [field]: value }
        };
      });
    }
  };

  submitSearch = (event) => {
    if (event) {
      event.preventDefault();
      if (this.isMount) {
        this.setState({
          loading: true
        });
      }
    }
    this.onSearchRequest();

  };

  populateHRDesignations = () => {
    getAllActiveHRDesignations().then(res => {
      if (this.isMount) {
        this.setState({
          hrDesignations: res.data,
          hrAll: res.data,
          loading: false,
          skip: 0
        });
      }
    }).catch(error => {
      this.setState({
        loading: true
      });
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });

  }

  populateCostDesignations = () => {
    getCostDesignations().then(res => {
      if (this.isMount) {
        this.setState({
          CostDesignations: res.data,
          costDesignationAll: res.data,
          loading: false,
          skip: 0
        });
      }
    }).catch(error => {
      this.setState({
        loading: true
      });
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  }

  onSearchRequest = (event) => {
    if (event) {
      event.preventDefault();
    }

    if(this.isMount){
      searchHrDesignations(this.state.SearchRequest).then(res => {
        this.setState({
          gridValue: res.data,
          loading: false,
          skip: 0
        });
      })
      .catch(error => {
        this.setState({
          loading: true
        });
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }
  }

  cancelSearch = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        loading: true,
        SearchRequest: {
          DesignationName: '',
          CostDesignation: ''
        }
      }, () => {
        this.submitSearch(null);
      });
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  setHeaderTitle = async () => {
    this.props.onHeaderTitleChange('HR Designation');
  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  OnDeactivate = async (event, id) => {
    event.preventDefault();
    if (this.isMount) {
      await this.setState({
        deactivateDesignationId: id
      });
    }
    this.deactivateConfirmationClose();
  };

  deactivateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: !this.state.deactivatePopupVisible
      });
    }
  };

  deactivateConfirmationOk = async (e) => {
    e.preventDefault();
    await this.setState({
      loading: true
    });

    await updateHRDesignationStatus({ IsActive: false }, this.state.deactivateDesignationId)
      .then(async response => {
        if (this.isMount) {
          await this.setState({
            deactivatePopupVisible: false,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleMessageDialog('The Designation has been Deactivated successfully', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.onSearchRequest();
  };

  OnActivate = async (event, id) => {
    event.preventDefault();
    if (this.isMount) {
      await this.setState({
        activateDesignationId: id
      });
    }
    this.activateConfirmationClose();
  };

  activateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        activatePopupVisible: !this.state.activatePopupVisible
      });
    }
  };

  activateConfirmationOk = async (e) => {
    e.preventDefault();
    await this.setState({
      loading: true
    });
    await updateHRDesignationStatus({ IsActive: true }, this.state.activateDesignationId)
      .then(async response => {
        if (this.isMount) {
          await this.setState({
            activatePopupVisible: false,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleMessageDialog('The Designation has been Activated successfully', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.onSearchRequest();
  };
}

export default HRDesignationSearch;
