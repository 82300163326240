import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';

import HeaderCellAddButton from '../common/HeaderCellAddButton';
import RowCellDeleteButton from '../common/RowCellDeleteButton';
import RowCellComboBox from '../common/RowCellComboBox';
import RowCellNumericTextBox from '../common/RowCellNumericTextBox';

import { filterBy } from '@progress/kendo-data-query';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { getExpenseTypes, getBudgetedRate } from '../../../../masterData/common/CommonService';
import { retrieveConversionRate } from '../../CMSheetService';
import Loader from '../../../../integration/components/Loader';
import * as loggerService from '../../../../integration/LoggerService';
import { LOG_TYPES } from '../../../../integration/IntegrationEnums';
import ResourceRequestStatusCell from "../../../../resourceManagement/components/ResourceRequestStatusCell";
import OtherExpenseCommentCell from "../OtherExpenseCommentCell";
import ResourceAllocations from "../resourceAllocations/ResourceAllocations";

const travelInsString = 'TRAVEL_INSURANCE';
const airTicketsString = 'AIR_TICKETS';
const perdiemString = 'PER_DIEM';
const accommodationString = 'ACCOMMODATION';
const incentivesString = 'INCENTIVES';
const swlicenseString = 'SW_LICENSE';
const hardwareString = 'HARDWARE';
const breakfastString = 'BREAKFAST';
const visaString = 'VISA';
const otherString = 'OTHER';
const shiftAlloString = 'SHIFT_ALLOWANCE';
const jiraString = 'JIRA_LICENSES';
const travelAllOffString = 'TRAVEL_ALL_OFFSHORE';
const regionOtherString = 'REGION_OTHER_COSTS';
const ceoOtherString = 'COE_OTHER_COSTS';

class OtherExpensesPool extends Component {
    comboBoxPlaceHolder = 'Please Select';
    billableStatusValues = [
        { id: 1, Text: 'Billable', value: true },
        { id: 2, Text: 'Non-Billable', value: false }
    ];

    constructor(props) {
        super(props);

        this.state = {
            startDateDisplay: '',
            endDateDisplay: '',
            autoPopulated: false,

            otherExpenses: [],
            dataLists: [],

            PhasesAll: [],
            ExpenseTypesAll: [],
            billableStatus: this.billableStatusValues,
            BillableTotalOtherExpenses: 0,
            NonBillableTotalOtherExpenses: 0,
            OtherExpensesTotal: 0,

            showMessageDialog: false,
            dialogMessage: '',
            dialogTitle: '',

            showConfirmDialog: false,
            confirmDialogMessage: '',
            confirmDialogAction: null,

            rowItemToDelete: [],
            commonCountryId: null,

            skip: 0,
            take: 20,
            isChangeValue: false,
            IsPoolProject:true,
            Comment:'',
            deleteResourceChecked: false,
        };

        this.itemChange = this.itemChange.bind(this);
    }

    componentDidMount() {
        this.populateData();
        this.setStartDateAndEndDate();
        this.captureCountryData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.project !== prevProps.project) {
            this.setStartDateAndEndDate();
        }

        if (this.props.cmSheet !== prevProps.cmSheet) {
            this.populateDataOnUpdate();
        }
    }

    pageChange = event => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    };



    setStartDateAndEndDate = () => {
        let startDate = new Date();
        let endDate = new Date();

        if (this.props.cmSheetReference === 'crCMS') {
            if (this.props.changeRequest && this.props.changeRequest.EstimatedStartDate && this.props.changeRequest.EstimatedEndDate) {
                startDate = new Date(this.props.changeRequest.EstimatedStartDate);
                endDate = new Date(this.props.changeRequest.EstimatedEndDate);

                if (startDate > endDate) {
                    this.setState({
                        notValidEndDate: true
                    });
                } else {
                    this.setState({
                        notValidEndDate: false
                    });
                }
            }
        } else {
            if (this.props.project && this.props.project.EstimatedStartDate && this.props.project.EstimatedEndDate) {

                startDate = this.props.project.StartDate !== null ? new Date(this.props.project.StartDate) : new Date(this.props.project.EstimatedStartDate) ;
                endDate = this.props.project.EndDate !== null ? new Date(this.props.project.EndDate) : new Date(this.props.project.EstimatedEndDate);

                if (startDate > endDate) {
                    this.setState({
                        notValidEndDate: true
                    });
                } else {
                    this.setState({
                        notValidEndDate: false
                    });
                }
            }
        }

        if (startDate && endDate) {
            this.setState({
                startDateDisplay:
                    startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear(),
                endDateDisplay:
                    endDate.getMonth() + 1 + '/' + endDate.getDate() + '/' + endDate.getFullYear()
            });
        } else {
            this.setState({
                startDateDisplay: '',
                endDateDisplay: ''
            });
        }
    };

    captureCountryData = () => {
        let countryId = null;
        if (this.props.cmSheetReference === 'crCMS') {
            countryId = this.props.changeRequest.CountryId;
        } else {
            countryId = this.props.project.CountryId;
        }

        this.setState({
            commonCountryId: countryId
        });

    };

    populateData = () => {
        if (this.props.cmSheet) {
            this.setState(
                {
                    PhasesAll: this.props.cmSheet.Phases.slice()
                },
                () => {
                    this.populateExpenseTypes();
                }
            );
        }
    };

    populateDataOnUpdate = () => {
        this.setState(
            {
                PhasesAll: this.props.cmSheet.Phases.slice()
            },
            () => {
                this.populateOtherExpenses();
            }
        );
    };

    populateExpenseTypes = () => {
        getExpenseTypes()
            .then(res => {
                this.setState(
                    {
                        ExpenseTypesAll: res && res.data ? res.data : []
                    },
                    () => {
                        this.populateOtherExpenses();
                    }
                );
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateOtherExpenses = () => {
        const otherExpenses = this.props.cmSheet.OtherExpenses;
        let i = 0;
        for (const expense of otherExpenses) {
            expense.index = i;
            i++;

            // Expense type
            expense.ExpenseTypes = this.state.ExpenseTypesAll.slice();
            if (expense.ExpenseTypeId) {
                const filterType = expense.ExpenseTypes.filter(obj => obj.id === expense.ExpenseTypeId);
                if (filterType.length > 0) {
                    expense.dataValueExpenseType = filterType[0];
                }
            }

            // billable status
            expense.billableStatus = this.billableStatusValues;
            if (expense.BillableToClient === true) {
                expense.dataValueBillable = this.billableStatusValues[0];
            } else if (expense.BillableToClient === false) {
                expense.dataValueBillable = this.billableStatusValues[1];
            } else {
                expense.dataValueBillable = null;
            }



            // set editable
            expense.inEdit = true;
        }



        this.setState({
            otherExpenses: otherExpenses,
            BillableTotalOtherExpenses: this.props.cmSheet.BillableTotalOtherExpenses
                ? this.props.cmSheet.BillableTotalOtherExpenses
                : 0,
            NonBillableTotalOtherExpenses: this.props.cmSheet.NonBillableTotalOtherExpenses
                ? this.props.cmSheet.NonBillableTotalOtherExpenses
                : 0,
            OtherExpensesTotal: this.props.cmSheet.OtherExpensesTotal
                ? this.props.cmSheet.OtherExpensesTotal
                : 0
        });
    };

    handleAddButtonClick = () => {
        const otherExpenses = JSON.parse(JSON.stringify(this.state.otherExpenses));

        for (const expense of otherExpenses) {
            expense.index = expense.index + 1;
        }

        otherExpenses.unshift({
            index: 0,
            dataValueExpenseType: null,
            dataValueBillable: this.billableStatusValues[0],
            billableStatus: this.billableStatusValues,
            BillableToClient: true,
            element: null,
            inEdit: true,
            BudgetedOccurrences: 0,
            BudgetedRate: 0,
            Cost: 0,
            Comment:'',
            Phases: this.state.PhasesAll,
            SubPhases: [],
            ExpenseTypes: this.state.ExpenseTypesAll
        });

        this.setState(
            {
                otherExpenses: otherExpenses
            },
            () => {
                this.updateCMSheet();
            }
        );
    };

    handleDeleteButtonClick = dataItem => {
        const expenses = this.state.otherExpenses.slice();

        let resourceToDelete = expenses.filter(resource => resource.Selected === true);

        this.setState(
            {
                rowItemToDelete: resourceToDelete
            },
            () => {
                const message = 'Are you sure, you want to delete this item?';
                this.toggleConfirmDialog(message, this.deleteRow);
            }
        );
    };

    deleteRow = () => {
        let deletedIndex = [];
        const expenses = this.state.otherExpenses.slice();
        for(let obj of this.state.rowItemToDelete){
            deletedIndex.push(obj.index);
        }

        for (let i = deletedIndex.length - 1; i >= 0; i--) {
            expenses.splice(deletedIndex[i], 1);
        }

        for (let i = 0; i < expenses.length; i++) {
            expenses[i].index = i;
        }

        this.setState(
            {
                otherExpenses: expenses
            },
            () => {
                const totalValues = this.getTotalValues();
                this.setState(
                    {
                        TotalFTEResourceAllocation: totalValues.TotalFTEResourceAllocation,
                        TotalRevenueResourceAllocation: totalValues.TotalRevenueResourceAllocation,
                        TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation,
                        TotalEffortDaysResourceAllocationNonBill: totalValues.TotalEffortDaysResourceAllocationNonBill,
                        TotalCostToRevenueRatioResourceAllocation: totalValues.TotalCostToRevenueRatioResourceAllocation,
                        TotalEffortDaysResourceAllocationFixed: totalValues.TotalEffortDaysResourceAllocationFixed,
                        TotalEffortDaysResourceAllocationTnM: totalValues.TotalEffortDaysResourceAllocationTnM,
                        rowItemToDelete: null
                    },
                    () => {
                        this.toggleConfirmDialog('', null);
                        this.updateCMSheet();
                    }
                );
            }
        );
    };

    handleChangeComboBox = async (event, dataItem) => {
        const expenses = this.state.otherExpenses.slice();
        const index = dataItem.index;
        const field = event.target.name;
        const value = event.target.value;

        if (index > -1) {
            switch (field) {
                case 'Phase': {
                    expenses[index].PhaseId = value ? value.id : null;
                    expenses[index].dataValuePhase = value;

                    if (expenses[index].isAutoPopulated) {
                        expenses[index].autoPopulatedValueChaged = true;
                        if (!(expenses[index].changedFields.indexOf('dataValuePhase') > -1)) {
                            expenses[index].changedFields.push('dataValuePhase');
                        }
                    }

                    // load sub phases on phase change
                    if (value) {
                        const filterPhase = this.state.PhasesAll.filter(obj => obj.id === value.id);
                        if (filterPhase.length > 0) {
                            expenses[index].SubPhases = filterPhase[0].SubPhases;
                            expenses[index].dataValueSubPhase = null;
                            expenses[index].SubPhaseId = null;
                        }
                    } else {
                        expenses[index].SubPhases = [];
                        expenses[index].dataValueSubPhase = null;
                        expenses[index].SubPhaseId = null;
                    }

                    break;
                }
                case 'SubPhase': {
                    expenses[index].SubPhaseId = value ? value.id : null;
                    expenses[index].dataValueSubPhase = value;

                    if (expenses[index].isAutoPopulated) {
                        expenses[index].autoPopulatedValueChaged = true;
                        if (!(expenses[index].changedFields.indexOf('dataValueSubPhase') > -1)) {
                            expenses[index].changedFields.push('dataValueSubPhase');
                        }
                    }

                    break;
                }

                case 'BillableStatus': {
                    expenses[index].BillableToClient = value && value.id ? value.value : null;
                    expenses[index].dataValueBillable = value;

                    if (expenses[index].isAutoPopulated) {
                        expenses[index].autoPopulatedValueChaged = true;
                        if (!(expenses[index].changedFields.indexOf('dataValueBillable') > -1)) {
                            expenses[index].changedFields.push('dataValueBillable');
                        }
                    }

                    break;
                }

                case 'ExpenseType': {
                    expenses[index].ExpenseTypeId = value ? value.id : null;
                    expenses[index].dataValueExpenseType = value;
                    if (expenses[index].ResourceRegion > 0 && expenses[index].ExpenseTypeId > 0) {
                        // retrieve budgeted rate
                        const rate = await this.retrieveBudgetedRate(
                            expenses[index].ExpenseTypeId,
                            this.state.commonCountryId,
                            expenses[index].ResourceRegion
                        );

                        expenses[index].BudgetedRate = rate > 0 ? rate : 0;
                        expenses[index].StandardRate = rate > 0 ? rate : 0;
                    }
                    expenses[index].rateNoLongerValid = false;
                    if (expenses[index].isAutoPopulated) {
                        expenses[index].autoPopulatedValueChaged = true;
                        if (!(expenses[index].changedFields.indexOf('dataValueExpenseType') > -1)) {
                            expenses[index].changedFields.push('dataValueExpenseType');
                        }
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        }

        this.setState(
            {
                autoPopulated: true,
                otherExpenses: expenses,
                isChangeValue: true

            },
            () => {
                const totalValues = this.getTotalValues();

                this.setState(
                    {
                        NonBillableTotalOtherExpenses: totalValues.nonBillTotal,
                        BillableTotalOtherExpenses: totalValues.billTotal,
                        OtherExpensesTotal: totalValues.nonBillTotal + totalValues.billTotal
                    },
                    () => {
                        this.updateCMSheet();
                    }
                );
            }
        );
        this.setState({
            autoPopulated: false
        });
    };

    filterChangeCombo = (event, dataItem) => {
        clearTimeout(this.timeout);

        const field = event.target.name;
        const filter = event.filter;

        this.timeout = setTimeout(() => {
            switch (field) {
                case 'Phase': {
                    dataItem.Phases = this.filterComboData(filter, this.state.PhasesAll);
                    break;
                }
                case 'SubPhase': {
                    dataItem.SubPhases = this.filterComboData(filter, dataItem.SubPhasesAll);
                    break;
                }
                case 'ExpenseType': {
                    dataItem.ExpenseTypes = this.filterComboData(filter, this.state.ExpenseTypesAll);
                    break;
                }
                default: {
                    break;
                }
            }

            this.setState({
                otherExpenses: [...this.state.otherExpenses]
            });
        }, 500);
    };

    filterComboData(filter, allData) {

        let data = [];
        if (allData) {
            data = allData;
        }
        data = data.slice();
        return filterBy(data, filter);
    }


    itemChange = event => {

        const expenses = this.state.otherExpenses.slice();
        const index = event.dataItem.index;
        const value = event.value;
        const name = event.field;


        event.dataItem[name] = value;
        if (event.dataItem.BudgetedOccurrences > 0 && event.dataItem.BudgetedRate > 0) {
            event.dataItem.Cost = event.dataItem.BudgetedRate * event.dataItem.BudgetedOccurrences;
        } else {
            event.dataItem.Cost = 0;
        }

        if (name === 'BudgetedRate') {
            event.dataItem.rateNoLongerValid = false;
            if (expenses[index].isAutoPopulated) {
                expenses[index].autoPopulatedValueChaged = true;
                if (!(expenses[index].changedFields.indexOf('BudgetedRate') > -1)) {
                    expenses[index].changedFields.push('BudgetedRate');
                }
            }
        }

        if (name === 'BudgetedOccurrences') {
            event.dataItem.rateNoLongerValid = false;
            if (expenses[index].isAutoPopulated) {
                expenses[index].autoPopulatedValueChaged = true;
                if (!(expenses[index].changedFields.indexOf('BudgetedOccurrences') > -1)) {
                    expenses[index].changedFields.push('BudgetedOccurrences');
                }
            }
        }



        this.setState(
            {
                autoPopulated: true,
                otherExpenses: [...this.state.otherExpenses]
            },
            () => {
                const totalValues = this.getTotalValues();
                this.setState(
                    {
                        NonBillableTotalOtherExpenses: totalValues.nonBillTotal,
                        BillableTotalOtherExpenses: totalValues.billTotal,
                        OtherExpensesTotal: totalValues.nonBillTotal + totalValues.billTotal
                    },
                    () => {
                        this.updateCMSheet();
                    }
                );
                this.setState({
                    autoPopulated: false
                });
            }
        );
    };

    updateCMSheet = async () => {
        const cmSheet = JSON.parse(JSON.stringify(this.props.cmSheet));
        cmSheet.BillableTotalOtherExpenses = this.state.BillableTotalOtherExpenses;
        cmSheet.NonBillableTotalOtherExpenses = this.state.NonBillableTotalOtherExpenses;
        cmSheet.OtherExpensesTotal = this.state.OtherExpensesTotal;

        cmSheet.OtherExpenses = this.state.otherExpenses;


        await this.props.onUpdateCMSheet(cmSheet, false);
    };

    getTotalValues = () => {
        let nonBillTotal = 0;
        let billTotal = 0;

        for (const expense of this.state.otherExpenses) {
            if (expense.BillableToClient === true && expense.Cost) {
                billTotal = billTotal + expense.Cost;
            }

            if (expense.BillableToClient === false && expense.Cost) {
                nonBillTotal = nonBillTotal + expense.Cost;
            }
        }

        return { nonBillTotal: nonBillTotal, billTotal: billTotal };
    };

    onClickAutoPopulate = () => {
        if (this.state.otherExpenses.length > 0) {
            const message = 'Are you sure, you want to re-populate?';
            this.toggleConfirmDialog(message, this.autoPopulateOtherExpenses);
        } else {
            this.autoPopulateOtherExpenses();
        }
    };

    autoPopulateOtherExpenses = async () => {
        this.state.autoPopulated = true;
        const onsiteAllocations = this.props.cmSheet.ResourceAllocations.filter(
            obj => obj.OnsiteStatus === 'Onsite'
        );

        const ResourceRegionGroups = [];
        const otherExpenses = [];

        // Group records
        for (const allocation of onsiteAllocations) {
            // group resource region
            const ResourceRegionGroupsFilter = ResourceRegionGroups.filter(
                obj =>
                    obj.ResourceRegion === allocation.ResourceRegion
            );

            if (ResourceRegionGroupsFilter.length > 0) {
                const totalResourceDays =
                    allocation.NoOfResources > 0 && allocation.TotalDays > 0
                        ? allocation.NoOfResources * allocation.TotalDays
                        : 0;

                ResourceRegionGroupsFilter[0].NoOfResources =
                    ResourceRegionGroupsFilter[0].NoOfResources + allocation.NoOfResources;
                ResourceRegionGroupsFilter[0].TotalDays =
                    ResourceRegionGroupsFilter[0].TotalDays + allocation.TotalDays;
                ResourceRegionGroupsFilter[0].TotalEffortDays =
                    ResourceRegionGroupsFilter[0].TotalEffortDays + allocation.TotalEffortDays;
                ResourceRegionGroupsFilter[0].TotalResourceDays =
                    ResourceRegionGroupsFilter[0].TotalResourceDays + totalResourceDays;
            } else {

                ResourceRegionGroups.push({
                    ResourceRegion: allocation.ResourceRegion,
                    NoOfResources: allocation.NoOfResources,
                    TotalDays: allocation.TotalDays,
                    TotalEffortDays: allocation.TotalEffortDays,
                    TotalResourceDays:
                        allocation.NoOfResources > 0 && allocation.TotalDays > 0
                            ? allocation.NoOfResources * allocation.TotalDays
                            : 0
                });
            }
        }

        // Populate list
        let expenseTypes = this.state.ExpenseTypesAll.slice();
        if (expenseTypes.length === 0) {
            expenseTypes = await getExpenseTypes()
                .then(res => {
                    this.setState({
                        ExpenseTypesAll: res && res.data ? res.data : []
                    });
                    return res && res.data ? res.data : [];
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        }
        if (expenseTypes.length > 0) {

            for (const group of ResourceRegionGroups) {
                for (const expenseType of expenseTypes) {

                    let budgetedOccurrences = 0;
                    let rate = 0;

                    if (expenseType.Code === travelInsString) {
                        // Travel Insurance
                        budgetedOccurrences = group.NoOfResources;
                        // Travel Insurance/ Per Diem/Accommodation  - Values for the Employed Country
                        rate = await this.retrieveBudgetedRate(expenseType.id, this.state.commonCountryId, 0);
                    }

                    if (expenseType.Code === airTicketsString) {
                        //Air Tickets
                        budgetedOccurrences = group.NoOfResources;
                        rate = await this.retrieveBudgetedRate(
                            expenseType.id,
                            this.state.commonCountryId,
                            group.ResourceRegion
                        );
                    }

                    if (expenseType.Code === perdiemString || expenseType.Code === accommodationString) {
                        // Per Diem, Accomadation
                        budgetedOccurrences = group.TotalResourceDays;
                        // Travel Insurance/ Per Diem/Accommodation  - Values for the Employed Country
                        rate = await this.retrieveBudgetedRate(expenseType.id, this.state.commonCountryId, 0);
                    }
                    if (
                        expenseType.Code === incentivesString ||
                        expenseType.Code === swlicenseString ||
                        expenseType.Code === hardwareString
                    )
                    {
                        // Incentives, S/W License, Hardware
                        // User can add values manually in phase 1
                        // budgetedOccurrences = group.TotalEffortDays;

                    }
                    if (expenseType.Code === breakfastString) {
                        // Breakfast
                        budgetedOccurrences = group.TotalResourceDays;
                        // User can add rate manually in phase 1
                    }
                    if (expenseType.Code === visaString) {
                        // Visa
                        budgetedOccurrences = group.NoOfResources;
                        // User can add rate manually in phase 1
                    }

                    // if(expenseType.Code === shiftAlloString ||
                    //     expenseType.Code === jiraString ||
                    //     expenseType.Code === travelAllOffString ||
                    //     expenseType.Code === regionOtherString ||
                    //     expenseType.Code === ceoOtherString)
                    // {
                    //
                    //     budgetedOccurrences = 0.00
                    // }


                    if (expenseType.Code !== otherString) {

                        // Insert new item
                        otherExpenses.push({
                            ExpenseTypeId: expenseType.id,
                            BillableToClient: expenseType.Code === incentivesString ? false : true, // Incentives non-billable
                            BudgetedOccurrences: budgetedOccurrences ? budgetedOccurrences : 0.00,
                            BudgetedRate: rate > 0 ? rate : 0,
                            // InitialRate: rate,
                            Cost: rate > 0 ? budgetedOccurrences * rate : 0,
                            isAutoPopulated: true,
                            changedFields: []
                        });
                    }
                }
            }
        } else {
            this.toggleMessageDialog('Active expense types not found', 'Error');
        }

        this.setState(
            {
                otherExpenses: otherExpenses
            },
            () => {
                const totalValues = this.getTotalValues();
                this.setState(
                    {
                        NonBillableTotalOtherExpenses: totalValues.nonBillTotal,
                        BillableTotalOtherExpenses: totalValues.billTotal,
                        OtherExpensesTotal: totalValues.nonBillTotal + totalValues.billTotal
                    },
                    () => {
                        if (this.state.showConfirmDialog === true) {
                            this.toggleConfirmDialog('', null);
                        }
                        this.updateCMSheet();
                    }
                );
            }
        );
        this.state.autoPopulated = false;
    };

    retrieveBudgetedRate = async (expenseType, projectCountry, resourceRegion) => {
        this.setState({
            autoPopulated: true
        });
        const rate = await getBudgetedRate(expenseType, projectCountry, resourceRegion)
            .then(async res => {
                if (res.data && res.data.length > 0) {
                    const rateRecord = res.data[0];

                    let projectOrCrObj = {};
                    if (this.props.cmSheetReference === 'crCMS') {
                        projectOrCrObj = this.props.changeRequest;
                    } else {
                        projectOrCrObj = this.props.project;
                    }


                    if (
                        projectOrCrObj.Region &&
                        projectOrCrObj.Region.Currency &&
                        projectOrCrObj.Region.Currency.id > 0
                    ) {
                        if (rateRecord.CurrencyId !== projectOrCrObj.Region.Currency.id) {
                            // currency conversion
                            const proStartDate = new Date(projectOrCrObj.StartDate);
                            // rate record currency, usd
                            const rateRecordCurrencyRate = await retrieveConversionRate(
                                rateRecord.CurrencyId,
                                proStartDate.getMonth() + 1,
                                proStartDate.getFullYear()
                            );
                            if (rateRecordCurrencyRate > 0) {
                                // project currency, usd
                                const projectCurrencyRate = await retrieveConversionRate(
                                    projectOrCrObj.Region.Currency.id,
                                    proStartDate.getMonth() + 1,
                                    proStartDate.getFullYear()
                                );
                                if (projectCurrencyRate > 0) {
                                    return (rateRecord.Rate / rateRecordCurrencyRate) * projectCurrencyRate;
                                }
                                return 0; // conversion rate not found for project currency
                            }
                            return 0; // conversion rate not found for rate record currency
                        } else {
                            return rateRecord.Rate;
                        }
                    } else {
                        this.toggleMessageDialog('Billing region currency not found', 'Error');
                    }
                } else {
                    return -1; // budgeted rate not found
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                return -2; // error
            });

        this.setState({
            autoPopulated: false
        });
        return rate;
    };

    onChangeCommentOtherExpense = async callbackData => {
        await this.setState({
            Comment: callbackData.Comment,
        },
            () => {
                this.updateCMSheet();
            }
            );
    };

    toggleMessageDialog = (message, title) => {
        this.setState({
            showMessageDialog: !this.state.showMessageDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    toggleConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action,
            deleteResourceChecked: !this.state.deleteResourceChecked
        });
    };

    selectionChange = (event) => {
        event.dataItem.Selected = !event.dataItem.Selected;
        this.forceUpdate();

        if (event.dataItem.Selected === true) {
            this.setState({
                deleteResourceChecked: true
            });
        } else {
            let deleteResourceBtnChecked = false;
            this.state.otherExpenses.forEach(function(resource) {
                if (resource.Selected === true) {
                    deleteResourceBtnChecked = true;
                }
            });
            this.setState({
                deleteResourceChecked: deleteResourceBtnChecked
            });
        }
    };

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        if (event.syntheticEvent.target.checked === true) {
            this.state.otherExpenses.forEach(item => item.Selected = checked);
            this.forceUpdate();

            this.setState({
                deleteResourceChecked: true
            });

            // this.setState({
            //     resourcesToDuplicate: this.state.resourceAllocations
            // });
        } else {
            if (this.state.otherExpenses && this.state.otherExpenses.length !== 0) {
                //this.toggleDuplicateResourceButton();
                this.state.otherExpenses.forEach(item => item.Selected = checked);
            }
        }
    };

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 btn-align-right">
                        <Button
                            primary={true}
                            look="outline"
                            onClick={this.onClickAutoPopulate}
                            disabled={this.props.isViewMode}
                        >
                            Auto Populate
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 btn-align-left">
                        <Button
                            primary={true}
                            look="outline"
                            onClick={this.handleDeleteButtonClick}
                            disabled={this.state.deleteResourceChecked === false || this.props.isViewMode}
                        >
                            Delete
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Loader loading={this.state.autoPopulated}/>
                        <div className="cms-oe">
                            <Grid
                                data={this.state.otherExpenses.slice(
                                    this.state.skip,
                                    this.state.take + this.state.skip
                                )}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.otherExpenses.length}
                                pageable={true}
                                onPageChange={this.pageChange}
                                onItemChange={this.itemChange}
                                onSelectionChange={this.selectionChange}
                                onHeaderSelectionChange={this.headerSelectionChange}
                                selectedField="Selected"
                                editField="inEdit"
                                resizable
                            >
                                {!this.props.isViewMode && !(this.state.otherExpenses.length === 0) &&
                                <Column
                                    field="Selected"
                                    width="50px"
                                    headerSelectionValue={
                                        this.state.otherExpenses.findIndex(dataItem => dataItem.Selected === false) === -1
                                    }/>}
                                <Column
                                    field=""
                                    title=""
                                    width="60px"
                                    headerCell={props => (
                                        <HeaderCellAddButton
                                            {...props}
                                            disabled={this.props.isViewMode}
                                            onAddButtonClick={this.handleAddButtonClick}
                                        />
                                    )}
                                    cell={props => (
                                        <td></td>
                                    )}

                                    // cell={props => (
                                    //     <RowCellDeleteButton
                                    //         {...props}
                                    //         disabled={this.props.isViewMode}
                                    //         onDeleteButtonClick={this.handleDeleteButtonClick}
                                    //     />
                                    // )}

                                />
                                <Column
                                    field="ExpenseTypeId"
                                    headerCell={props => <label className="mandatory">Expense Type</label>}
                                    width="175px"
                                    cell={this.ComboBoxCell}
                                    maxLength={25}
                                />
                                <Column
                                    field="BudgetedOccurrences"
                                    headerCell={props => <label className="mandatory">Budgeted Occurrence</label>}
                                    width="180px"
                                    cell={this.NumericTextBoxCell}
                                    maxLength={4}
                                />
                                <Column
                                    field="BudgetedRate"
                                    headerCell={props => <label className="mandatory">Budgeted Rate</label>}
                                    width="180px"
                                    cell={this.NumericTextBoxCell}
                                    maxLength={7}
                                />
                                <Column
                                    field="BillableToClient"
                                    headerCell={props => (
                                        <label className="mandatory">Billable to the Client (OPE)</label>
                                    )}
                                    width="180px"
                                    cell={this.ComboBoxCell}
                                    maxLength={12}
                                />
                                <Column
                                    field="Cost"
                                    headerCell={props => <label>Cost</label>}
                                    width="120px"
                                    format="{0:n2}"
                                    editable={false}
                                    maxLength={9}
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Comment</label>}
                                    width="240px"
                                    cell={props => (<OtherExpenseCommentCell {...props} handleChangeComboBox ={this.handleChangeComboBox} otherExpenses = {this.state.otherExpenses} updateCMSheet = {this.updateCMSheet} viewMode = {this.props.isViewMode}/>)}
                                    maxLength={100}
                                />
                            </Grid>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5">
                                <label>Non-Billable Total:</label>
                            </div>
                            <div className="col-md-7">
                                <Input
                                    disabled={true}
                                    value={this.state.NonBillableTotalOtherExpenses.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5">
                                <label>Billable Total:</label>
                            </div>
                            <div className="col-md-7">
                                <Input
                                    disabled={true}
                                    value={this.state.BillableTotalOtherExpenses.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5">
                                <label>Total:</label>
                            </div>
                            <div className="col-md-7">
                                <Input
                                    disabled={true}
                                    value={this.state.OtherExpensesTotal.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showMessageDialog === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                                OK
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleConfirmDialog}>
                                No
                            </button>
                            <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </div>
        );
    }

    /** ============= Grid Editor Cells ============================ */

    ComboBoxCell = props => {
        if (props.field === 'BillableToClient') {
            return (
                <RowCellComboBox
                    {...props}
                    dataList="billableStatus"
                    textField="Text"
                    dataItemKey="id"
                    dataValueField="dataValueBillable"
                    onChangeSelection={this.handleChangeComboBox}
                    comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                    name="BillableStatus"
                    disabled={this.props.isViewMode}
                />
            );
        }

        if (props.field === 'ExpenseTypeId') {
            return (
                <RowCellComboBox
                    {...props}
                    dataList="ExpenseTypes"
                    textField="Name"
                    dataItemKey="id"
                    dataValueField="dataValueExpenseType"
                    onChangeSelection={this.handleChangeComboBox}
                    name="ExpenseType"
                    comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                    canFilter={true}
                    filterChangeCombo={this.filterChangeCombo}
                    disabled={this.props.isViewMode}
                    isChange={this.state.isChangeValue}
                />

            );
        }

    };

    DropDownCell = props => {
        if (props.field === 'BillableToClient') {
            return (
                <RowCellComboBox
                    {...props}
                    dataList={this.billableStatusValues}
                    textField="Text"
                    dataItemKey="id"
                    dataValueField="dataValueBillable"
                    onChangeSelection={this.handleChangeDropDown}
                    name="BillableStatus"
                    disabled={this.props.isViewMode}
                />
            );
        }
    };

    NumericTextBoxCell = props => {
        if (props.field === 'BudgetedOccurrences') {
            return (
                <RowCellNumericTextBox
                    {...props}
                    disabled={this.props.isViewMode}
                    minValue={0}
                    dataValueField="BudgetedOccurrences"
                    onChange={this.itemChange}
                    toFixed={0}
                />
            );
        }

        if (props.field === 'BudgetedRate') {
            return (
                <RowCellNumericTextBox
                    {...props}
                    disabled={this.props.isViewMode}
                    minValue={0}
                    onChange={this.itemChange}
                    dataValueField="BudgetedRate"
                    toFixed={0}
                    styleClass={props.dataItem.rateNoLongerValid === true ? 'border-red' : ''}
                />
            );
        }
    };
}

export default OtherExpensesPool;
