/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import _ from 'lodash';
import { ComboBox } from '@progress/kendo-react-dropdowns/dist/npm/main';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/main';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import ReactQuill from "react-quill";
import RowCellComboBox from '../cmSheet/component/common/RowCellComboBox';
import RowCellDatePicker from '../cmSheet/component/common/RowCellDatePicker';
import RowCellNumericTextBox from '../cmSheet/component/common/RowCellNumericTextBox';
import { getAllDesignations } from '../../masterData/designation/DesignationService';
import { getActiveResourceRegionsIntegrated, getHolidaysIntegrated, getRegionCurrencyIntegrated } from '../../integration/MasterDataService';
import { getRateTypes } from '../../masterData/rateCard/RateCardService';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import { filterBy } from '@progress/kendo-data-query';
import ResourceRequestStatusCell from '../../resourceManagement/components/ResourceRequestStatusCell';
import {
    calculateCostDate,
    calculateProjectEndDate,
    getStartDateByPriority,
    retrieveConversionRate, saveCMSheet
} from '../cmSheet/CMSheetService';
import { getFormattedDate, isWeekend, reStructureDate } from '../../integration/CommonUtils';
import moment from 'moment';
import { getCost, getMultiYearCost } from '../../masterData/costCard/CostCardService';
import { submitForResourceAllocation } from '../../resourceManagement/resourceRequest/ResourceRequestActivationService';
import RowCellDeleteButton from '../cmSheet/component/common/RowCellDeleteButton';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import { deleteRequest, deallocatePreSalesResourceRequest } from '../../resourceManagement/ResourceService';
import { retrieveConversionRateIntegrated } from '../../integration/ProjectManagementService';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import RowCellCommentBox from '../cmSheet/component/common/RowCellCommentBox';

const CUSTOM_RATE = 'CUSTOM_RATE';

class ResourceRequest extends Component {
    comboBoxPlaceHolder = 'Please Select';
    popupSet = {
        width: 'auto'
    };
    OnsiteStatusValues = [{ id: 1, Name: 'Onsite' }, { id: 2, Name: 'Offshore' }];

    constructor(props) {
        super(props);
        this.state = {
            isViewMode: false,
            preSalesResourceAllocations : [],
            DesignationsAll: [],
            RegionsAll: [],
            RateTypes: [],
            isErrorAllocationPercentage: false,
            costDate: new Date(),
            TotalCostResourceAllocation: 0,
            TotalEffortDaysResourceAllocation: 0,
            popupVisible: false,
            isLoading: true,
            rowItemToDelete: null,
            disableRow : false,
            allocationDisabled : true,
            allocComment : null,

            showConfirmDialog: false,
            confirmDialogMessage: '',
            confirmDialogAction: null,
            showDeallocConfirmDialog: false,
            resDeallocationItems: null,
            deallocationStartDate: null,
            resDeallocationStartDate: null,
            resDeallocationEndDate: null,
            resDeallocationDesignation: null,
            isCanDeallocateRequest: false,
            managerComment: '',

            skip: 0,
            take:10
        }
    }

    componentDidMount() {
        try {
            this.populateData();
        } catch (e) {
            loggerService.writeLog('Error loading data for pre-sales resource allocation.', LOG_TYPES.ERROR);
        }
    }

    componentDidUpdate(prevProps){
        if (this.props.action && this.props.action !== prevProps.action) {
            this.setState({
                isViewMode: this.props.action === 'view' ? true : false
            });
        }
        if (!_.isEqual(this.props.preSalesResourceAllocations, prevProps.preSalesResourceAllocations)) {
            this.populateAllocations();
        }
    }

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    populateData = async () => {
        const resDesg = await getAllDesignations();
        const resReg = await getActiveResourceRegionsIntegrated();
        const resRT = await getRateTypes();

        let rateTypes = [];
        if (resRT.data && resRT.data.length > 0) {
            for (const rateType of resRT.data) {
                if (rateType.Code !== CUSTOM_RATE) {
                    rateType.Rate = 0;
                    rateType.Text = rateType.Name + ' - 0.00';
                } else {
                    rateType.Text = rateType.Name;
                }
                rateTypes.push(rateType);
            }
        }

        await this.setState(
          {
              DesignationsAll: resDesg.data,
              RegionsAll: resReg.data,
              RateTypes: rateTypes
          },
          () => {
              this.populateAllocations();
          }
        );
    };

    populateAllocations = async () => {
        this.setState({
            isLoading: true
        });
        const allocations = this.props.preSalesResourceAllocations.slice();
        let i = 0;
        for (const allocation of allocations) {
            allocation.index = i;
            i++;

            // Designation
            allocation.CostDesignations = this.state.DesignationsAll.slice();
                if (allocation.Designation) {
                    const filterCostDesignation = allocation.CostDesignations.filter(
                      obj => obj.id === allocation.Designation
                    );
                    if (filterCostDesignation.length > 0) {
                        allocation.dataValueCostDesignation = filterCostDesignation[0];
                    }
                }

            // Resource Region
            allocation.ResourceRegions = this.state.RegionsAll.slice();
            if (allocation.ResourceRegion) {
                const filterResourceRegion = allocation.ResourceRegions.filter(
                  obj => obj.id === allocation.ResourceRegion
                );
                if (filterResourceRegion.length > 0) {
                    allocation.dataValueResourceRegion = filterResourceRegion[0];
                }
            }

            allocation.OnsiteStatuses = this.OnsiteStatusValues;
            if (allocation.OnsiteStatus === 'Onsite') {
                allocation.dataValueOnsiteStatus = this.OnsiteStatusValues[0];
            } else if (allocation.OnsiteStatus === 'Offshore') {
                allocation.dataValueOnsiteStatus = this.OnsiteStatusValues[1];
            } else {
                allocation.dataValueOnsiteStatus = null;
            }

            // set start date
            if (allocation.StartDate) {
                allocation.DateStartDate = reStructureDate(allocation.StartDate);
                allocation.StartDate = reStructureDate(allocation.StartDate);
            } else {
                allocation.DateStartDate = null;
                allocation.StartDate = null;
            }

            //end date
            if (allocation.EndDate) {
                allocation.EndDate = reStructureDate(allocation.EndDate);
            } else {
                allocation.EndDate = null;
            }

            //comment
            if(allocation.Comment){
                this.state.allocComment = '';
            }

            // Set edit mode
            if(allocation.Status === "RES_ALLOC_PENDING" || allocation.Status === "COMPLETE" || allocation.Status === "RES_ALLOC_CANCELLED"){
                allocation.inEdit = false;
            }else{
                allocation.inEdit = true;
            }
        }


        if (allocations && allocations.length > 0) {
            allocations.forEach(function(allocation) {
                allocation.Selected = false;
            });
        }

        await this.setState({
            preSalesResourceAllocations: allocations
        },
        async () => {
              const totalValues = await this.getTotalValues();
              this.setState({
                    TotalCostResourceAllocation: totalValues.TotalCostResourceAllocation,
                    TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation
                });
          }
          );
        this.setState({
            isLoading: false
        });
    };

    addPreSalesResource = async () => {
        const allocations = this.state.preSalesResourceAllocations.slice();
        for (const allocation of allocations) {
            allocation.index = allocation.index + 1;
        }

        const newItem = {
            index: 0,
            dataValuePhase: null,
            dataValueSubPhase: null,
            dataValueProjectRole: null,
            dataValueCostDesignation: null,
            dataValueResourceRegion: null,
            dataValueOnsiteStatus: null,
            FullProject: false,
            StartDate: null,
            endDate: null,
            DateStartDate: null,
            EndDate: null,
            DatesDisabled: false,
            AllocationPercentage: 100,
            AllocationHours: 0,
            dataValueRateType: null,
            RateType: null,
            RateValue: 0,
            RateValueDisabled: true,
            NoOfResources: 1,
            TotalCost: 0,
            TotalEffortDays: 0,
            EffortDays: 0,
            EffortHours: 0,
            inEdit: true,
            CostDesignations: this.state.DesignationsAll,
            ResourceRegions: this.state.RegionsAll.slice(),
            isStartDateChanged:false,
            Status:'NOT_SUBMITTED',
            IsPreSales: true,
            Comment: null,
            IsActive: true,
            StartDateMin: new Date(),
            Designation: null,
            selected: false
        };

        await this.calculateRowValues(newItem);
        allocations.unshift(newItem);

        this.setState(
          {
              preSalesResourceAllocations: allocations
          }, () => {
              this.updateProject();
          }
        );
    };

    calculateTotalEffort = async (allocation, projOrCrCountryId ) => {

        if (allocation && allocation.StartDate && allocation.EndDate) {

            let startDate = new Date(allocation.StartDate);
            let endDate = new Date(allocation.EndDate);

            let defaultCountry = projOrCrCountryId;
            if (allocation.OnsiteStatus === 'Onsite') {
                defaultCountry = projOrCrCountryId;
            } else {
                defaultCountry = allocation.dataValueResourceRegion ? allocation.dataValueResourceRegion.DefaultCountryId : null;
            } 

            let currentYear = startDate.getFullYear();
            let startDateTimeZone = new Date(startDate);
            startDateTimeZone.setHours(0, 0, 0, 0);
            let end_date = moment(new Date(endDate));
            let start_date = moment(new Date(startDateTimeZone));

            let loop = new Date(start_date);
            let totalWorkingDays = 0;
            let isHoliday = false;

            while(currentYear <= endDate.getFullYear()){
                // Holidays
                let holidays = await getHolidaysIntegrated(defaultCountry, currentYear).then(res => {
                    if (res && res.data) {  return res.data;    }
                    return [];
                }).catch(error => { loggerService.writeLog(error, LOG_TYPES.ERROR);
                    return [];
                });

                if (holidays && holidays.length === 0) {
                    console.log('Holidays not found for ' + startDate.getFullYear());
                } else if (!holidays) {
                    holidays = [];
                }
                while (loop <= end_date && loop <= new Date(currentYear, 11, 31)) {
                    isHoliday = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (!isHoliday && !isWeekend(loop)) {
                        totalWorkingDays++;
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

                currentYear = currentYear + 1;

            }

            return totalWorkingDays;
        }

    };

    calculateRowValues = async allocation => {

        // Effort Days
        if (allocation.DateStartDate && allocation.EndDate ) {
            let effortDays = 0;
            effortDays = await this.calculateTotalEffort(allocation, this.props.countryValue.id);
            const tempEffortDays = effortDays * allocation.AllocationPercentage/100;
            allocation.TotalEffortDays = Number(tempEffortDays.toFixed(2));
            allocation.EffortDays = Number(tempEffortDays.toFixed(2));
            if (allocation.EffortDays <= 0) {
                const message = 'Effort days is 0.';
                const title = 'Error';
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                allocation.StartDate = null;
                allocation.DateStartDate = null;
                allocation.EndDate = null;
                this.toggleMessageDialog(message, title);
            }
        } else {
            allocation.TotalEffortDays = 0;
            allocation.EffortDays = 0;
        }

        //date
        allocation.StartDateType = 'RESOURCE_ALLOCATION';
        //Date passed to get the cost card for the particular designation
        this.setState({
            costDate: allocation.StartDate
        });

        allocation.EstimatedEndDate_= getFormattedDate(allocation.EndDate);
        allocation.TotalDays = allocation.EndDate - allocation.StartDate;

        // Cost
        if(allocation.EffortDays > 0){
            await this.getCostForDesignation(allocation);
        }else{
            allocation.TotalCost = 0;
        }

    };

    getCostForDesignation = async dataItem => {

        let region = null;
        let billingRegion = 0;
        let projOrCr = null;

        region = this.props.billingregionValue;
        billingRegion = this.props.billingregionValue.id;
        projOrCr = this.props.project;

            // Other designation
            if (dataItem.ResourceRegion && dataItem.Designation && billingRegion) {
                const costDate_ = new Date(dataItem.DateStartDate);
                const costCard = {
                    billingRegionId: billingRegion,
                    resourceRegionId: dataItem.ResourceRegion,
                    designationId: this.state.resDeallocationDesignation ? this.state.resDeallocationDesignation : dataItem.Designation,
                    costDate: moment(costDate_).format('YYYY-MM-DD')
                };

                this.setState({
                    costDate: costDate_
                });
                await getCost(costCard)
                  .then(async res => {
                      if (res.data.length > 0 && res.data[0].id > 0) {
                          const costCard = res.data[0];
                          const costRecord = {
                              CurrencyId: costCard.BillingRegion.Currency.id,
                              Overhead: costCard.CostCardDesignations[0].Overhead,
                              CostToCompany: costCard.CostCardDesignations[0].CostToCompany
                          };

                          let costPerResourceTotal = costRecord.Overhead + costRecord.CostToCompany;
                          if (costPerResourceTotal === 0) {
                              dataItem.CostPerResource = 0;
                              dataItem.DirectCost = 0;
                              dataItem.TotalCost = 0;
                              const message =
                                'Cost card record not found for the selected designation and resource region';
                              const title = 'Error';
                              this.setState({
                                  showSuccessImage: false,
                                  showErrorImage: true
                              });
                              this.toggleMessageDialog(message, title);
                          } else {

                              if (region && region.Currency && region.Currency.id > 0) {

                                let costPerResource = costRecord.Overhead + costRecord.CostToCompany;
                                dataItem.CostPerResource = costPerResource;
                                dataItem.DirectCost = costRecord.CostToCompany;

                                await this.calculateTotalCost(dataItem, costPerResource, res.data[0].id, 0);
                                
                              } else {
                                  dataItem.CostPerResource = 0;
                                  dataItem.DirectCost = 0;
                                  dataItem.TotalCost = 0;
                                  this.toggleMessageDialog('Billing region currency not found', 'Error');
                              }

                          }
                      } else {
                        let costDate = costDate_;
                        const costYear = costDate_.getFullYear();
                        const costMonth = costDate_.getMonth() + 1;
                        if (costMonth == 1 || costMonth == 2 || costMonth == 3) { // check if the month is Jan/Feb/March
                            let prevYear = (costYear - 1).toString();
                            costDate = prevYear + '-03-31';
                        } else {
                            costDate = costYear + '-03-31';
                        }
                        costCard.costDate = moment(costDate).format("YYYY-MM-DD"); // previous financial year date
                        await getCost(costCard)
                        .then(async res => {
                            if (res.data.length > 0 && res.data[0].id > 0) {
                                const costCard = res.data[0];
                                const costRecord = {
                                    CurrencyId: costCard.BillingRegion.Currency.id,
                                    Overhead: costCard.CostCardDesignations[0].Overhead,
                                    CostToCompany: costCard.CostCardDesignations[0].CostToCompany
                                };

                                let costPerResourceTotal = costRecord.Overhead + costRecord.CostToCompany;
                                if (costPerResourceTotal === 0) {
                                    dataItem.CostPerResource = 0;
                                    dataItem.DirectCost = 0;
                                    dataItem.TotalCost = 0;
                                    const message =
                                        'Cost card record not found for the selected designation and resource region';
                                    const title = 'Error';
                                    this.setState({
                                        showSuccessImage: false,
                                        showErrorImage: true
                                    });
                                    this.toggleMessageDialog(message, title);
                                } else {

                                    if (region && region.Currency && region.Currency.id > 0) {

                                        let costPerResource = costRecord.Overhead + costRecord.CostToCompany;
                                        dataItem.CostPerResource = costPerResource;
                                        dataItem.DirectCost = costRecord.CostToCompany;

                                        await this.calculateTotalCost(dataItem, costPerResource, res.data[0].id, 0);
                                        
                                    } else {
                                        dataItem.CostPerResource = 0;
                                        dataItem.DirectCost = 0;
                                        dataItem.TotalCost = 0;
                                        this.toggleMessageDialog('Billing region currency not found', 'Error');
                                    }
                                }
                            } else {
                                dataItem.CostPerResource = 0;
                                dataItem.DirectCost = 0;
                                dataItem.TotalCost = 0;
                                const message =
                                    'Cost card record not found for the selected designation and resource region';
                                const title = 'Error';
                                this.setState({
                                    showSuccessImage: false,
                                    showErrorImage: true
                                });
                                this.toggleMessageDialog(message, title);
                            }
                        })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                            dataItem.CostPerResource = 0;
                            dataItem.DirectCost = 0;
                            dataItem.TotalCost = 0;
                        });
                      }
                  })
                  .catch(error => {
                      loggerService.writeLog(error, LOG_TYPES.ERROR);
                      dataItem.CostPerResource = 0;
                      dataItem.DirectCost = 0;
                      dataItem.TotalCost = 0;
                  });
            } else {
                dataItem.CostPerResource = 0;
                dataItem.DirectCost = 0;
                dataItem.TotalCost = 0;
            }

            dataItem.costNoLongerValid = false;
    };

    calculateTotalCost = async (dataItem, costPerResource, costCardId, ratePerUSD) => {
        let costforMultiYear = 0;
        costforMultiYear = dataItem.EffortDays * costPerResource;
        dataItem.TotalCost = costforMultiYear;
    };

    ComboBoxCell = props => {

        if (props.field === 'Designation') {
            return (
              <RowCellComboBox
                {...props}
                dataList="CostDesignations"
                textField="Name"
                dataItemKey="id"
                dataValueField="dataValueCostDesignation"
                onChangeSelection={this.handleChangeComboBox}
                name="CostDesignation"
                comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                canFilter={true}
                filterChangeCombo={this.filterChangeCombo}
                disabled={this.props.viewMode || this.props.statusValue ? (this.props.statusValue !== null ?this.props.statusValue.StatusName !== 'Presales' : false) : false}
                required={true}
              />
            );
        }

        if (props.field === 'ResourceRegion') {
            return (
              <RowCellComboBox
                {...props}
                dataList="ResourceRegions"
                textField="Name"
                dataItemKey="id"
                dataValueField="dataValueResourceRegion"
                onChangeSelection={this.handleChangeComboBox}
                name="ResourceRegion"
                comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                canFilter={true}
                filterChangeCombo={this.filterChangeCombo}
                disabled={this.props.viewMode || this.props.statusValue ? (this.props.statusValue !== null ?this.props.statusValue.StatusName !== 'Presales' : false) : false}
                required={true}
              />
            );
        }

        if (props.field === 'OnsiteStatus') {
            return (
              <RowCellComboBox
                {...props}
                dataList="OnsiteStatuses"
                textField="Name"
                dataItemKey="id"
                dataValueField="dataValueOnsiteStatus"
                onChangeSelection={this.handleChangeComboBox}
                name="OnsiteStatus"
                comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                filterChangeCombo={this.filterChangeCombo}
                disabled={this.props.isViewMode}
                required={true}
              />
            );
          }
        };

    handleChangeComboBox = async (event, dataItem) => {
        const allocations = this.state.preSalesResourceAllocations.slice();
        const index = dataItem.index;
        const field = event.target.name;
        const value = event.target.value;

        if (index > -1) {
            switch (field) {
                case 'CostDesignation': {
                    allocations[index].Designation = value ? value.id : null;
                    allocations[index].dataValueCostDesignation = value;
                    allocations[index].CostPerOutSourcedResource = 0;
                    await this.calculateRowValues(allocations[index]);

                    break;
                }
                case 'ResourceRegion': {
                    allocations[index].ResourceRegion = value ? value.id : null;
                    allocations[index].dataValueResourceRegion = value;
                    await this.calculateRowValues(allocations[index]);
                    break;
                }
                case 'OnsiteStatus': {
                    allocations[index].OnsiteStatus = value ? value.Name : null;
                    allocations[index].dataValueOnsiteStatus = value;
                    await this.calculateRowValues(allocations[index]);
                    break;
                }
                default: {
                    break;
                }
            }
        }

        await this.setState(
          {
              preSalesResourceAllocations: allocations
          },
          async () => {
              const totalValues = await this.getTotalValues();
              this.setState(
                {
                    TotalCostResourceAllocation: totalValues.TotalCostResourceAllocation,
                    TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation
                },
                () => {
                    this.updateProject();
                }
              );
          }
        );
    };

    getTotalValues = async () => {
        let totalCostResourceAllocation = 0;
        let TotalCostResourceAllocation = 0;
        let totalEffortDaysResourceAllocation = 0;

        let region = null;
        let billingRegion = 0;
        let startDate = null;
        let projcurrencyId = 0;

        region = this.props.billingregionValue;
        billingRegion = this.props.billingregionValue ? this.props.billingregionValue.id : null;
        

        for (const allocation of this.state.preSalesResourceAllocations) {
            if(allocation.Status !== 'RES_ALLOC_CANCELLED'){
                totalCostResourceAllocation = Number(allocation.TotalCost);
                totalEffortDaysResourceAllocation =
              totalEffortDaysResourceAllocation + allocation.TotalEffortDays;

                // Convert calculations to USD if in other exchange rates
                startDate = this.props.startdate ? this.props.startdate : allocation.StartDate;

                await getRegionCurrencyIntegrated(billingRegion).then(response => {
                    projcurrencyId = response.data.CurrencyId;
                }).catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

                if(projcurrencyId !== 1){
                    const proStartDate = new Date(startDate);
                    const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                        projcurrencyId,
                        proStartDate.getMonth() + 1,
                        proStartDate.getFullYear()
                    );

                    // Convert calculations to relevent currencies based on the Project/CR region
                    totalCostResourceAllocation = (Number(totalCostResourceAllocation * projrateRecordCurrencyRate)).toFixed(2);

                    TotalCostResourceAllocation = Number(TotalCostResourceAllocation) + Number(totalCostResourceAllocation)
                }
                else {
                    TotalCostResourceAllocation = Number(TotalCostResourceAllocation) + Number(totalCostResourceAllocation)
                }

            }
        }
        return {
            TotalCostResourceAllocation:  TotalCostResourceAllocation,
            TotalEffortDaysResourceAllocation: totalEffortDaysResourceAllocation
        };
    };

    DatePickerCell = props => {
        if (props.field === 'DateStartDate') {
            return (
              <RowCellDatePicker
                {...props}
                disabled={this.props.viewMode || (this.props.statusValue ? (this.props.statusValue !== null ?this.props.statusValue.StatusName !== 'Presales' : false) : false)}
                minDateField="StartDateMin"
                //maxDateField="StartDateMax"
                onChange={this.itemChange}
                required={true}
                Type="preSales"
                //StartDateIsAPastDate={this.state.StartDateIsAPastDate}
              />
            );
        }

        if (props.field === 'EndDate') {
            return (
              <RowCellDatePicker
                {...props}
                disabled={this.props.viewMode || (this.props.statusValue ? (this.props.statusValue !== null ?this.props.statusValue.StatusName !== 'Presales' : false) : false)}
                minDateField="DateStartDate"
                //maxDateField="StartDateMax"
                onChange={this.itemChange}
                required={true}
              />
            );
        }
    };

    itemChange = async event => {
        const value = event.value;
        const name = event.field;
        const dataItem = event.dataItem;
        this.setState({
            isLoading: true
        });
        var decimal = /^[-+]?[0-9]+\.[0-9]+$/;
        switch (name) {
            case 'DateStartDate':
                await this.handleDatePickerChange(event);
                break;
            case 'EndDate':
                await this.handleDatePickerChange(event);
                break;
            case 'AllocationPercentage':
                dataItem[name] = Number((Number(value)).toFixed(2));
                if (!value || value === '0') {
                    dataItem.AllocationPercentage = 0;
                    this.setState({ isErrorAllocationPercentage: true });
                } else {
                    this.setState({ isErrorAllocationPercentage: false });
                }
                break;
            case 'Comment':
                await this.handlePMCommentChange(event);
                break;
    }
        await this.calculateRowValues(dataItem);
                this.setState(
                  {
                      preSalesResourceAllocations: [...this.state.preSalesResourceAllocations]
                  },
                  async () => {
                      const totalValues = await this.getTotalValues();
                      this.setState(
                        {
                            TotalCostResourceAllocation: totalValues.TotalCostResourceAllocation,
                            TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation
                        },
                        () => {
                            this.updateProject();
                        }
                      );
                  }
                );
    };

    handleDatePickerChange = async event => {
        const field = event.field;
        const dataItem = event.dataItem;
        this.setState({
            isLoading: true
        });
        // Start Date
        if (field === 'DateStartDate') {

            this.setState({
                isStartDateChanged: true
            });
            if (event.value) {
                let startDate = new Date(event.value);
                startDate.setHours(0, 0, 0, 0);

                if (startDate && (startDate >= new Date(dataItem.StartDateMin))) {
                    this.setState({
                        StartDateIsAPastDate: false
                    });
                    dataItem.StartDate = startDate;
                } else {
                    this.setState({
                        StartDateIsAPastDate: true
                    });
                    dataItem.StartDate = startDate;
                }

                dataItem.DateStartDate = startDate;

            } else {
                dataItem.StartDate = null;
                dataItem.DateStartDate = null;
            }
        }
        else if (field === 'EndDate') {
            if (event.value) {
                let endDate = new Date(event.value);
                endDate.setHours(0, 0, 0, 0);
                dataItem.endDate = endDate;
                dataItem.EndDate = endDate;
            } else {
                dataItem.EndDate = null;
                dataItem.endDate = null;
            }
        }
        this.setState({
            isLoading: false
        });
    };

    handlePMCommentChange = async(event) => {
        const allocations = this.state.preSalesResourceAllocations.slice();
        const dataItem = event.dataItem;
        const index = dataItem.index;

        if (index > -1) {
            allocations[index].Comment = event.value;
            allocations[index].ManagerComment = event.value;
        }

        await this.setState(
            {
                preSalesResourceAllocations: allocations
            },
            async () => {
                const totalValues = await this.getTotalValues();
                this.setState(
                  {
                      TotalCostResourceAllocation: totalValues.TotalCostResourceAllocation,
                      TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation
                  },
                  () => {
                      this.updateProject();
                  }
                );
            }
          );
      };

    NumericTextBoxCell = props => {
        if (props.field === 'AllocationPercentage') {
            return (
              <RowCellNumericTextBox
                {...props}
                minValue={1}
                onChange={this.itemChange}
                toFixed={2}
                required={true}
                isError={this.state.isErrorAllocationPercentage}
                isRed={this.state.isRedBadge}
                disabled={this.props.viewMode || this.props.statusValue ? (this.props.statusValue !== null ?this.props.statusValue.StatusName !== 'Presales' : false) : false}
              />

            );
        }
    };

    CommentBoxCell = props => {
        if (props.field === 'Comment') {
            return (
                <RowCellCommentBox
                    {...props}
                    onChange={this.itemChange}
                    disabled={this.props.viewMode || this.props.statusValue ? (this.props.statusValue !== null ?this.props.statusValue.StatusName !== 'Presales' : false) : false}
              />
            );
        }
    };

    filterComboData(filter, allData) {
        const data = allData.slice();
        return filterBy(data, filter);
    }

    filterChangeCombo = (event, dataItem) => {
        clearTimeout(this.timeout);

        const field = event.target.name;
        const filter = event.filter;

        this.timeout = setTimeout(() => {
            switch (field) {
                case 'CostDesignation': {
                        dataItem.CostDesignations = this.filterComboData(filter, this.state.DesignationsAll);
                    break;
                }
                case 'ResourceRegion': {
                    dataItem.ResourceRegions = this.filterComboData(filter, this.state.RegionsAll);
                    break;
                }
                case 'OnsiteStatus': {
                    dataItem.OnsiteStatuses = this.filterComboData(filter, this.OnsiteStatusValues);
                    break;
                }
                default: {
                    break;
                }
            }

            this.setState({
                preSalesResourceAllocations: [...this.state.preSalesResourceAllocations]
            });

        }, 500);
    };

    updateProject = async () => {
        await this.props.onUpdateProj(this.state.preSalesResourceAllocations, this.state.allocComment);
    };

    allocationValidation = (selectedResources) => {
        for(const eachAllocation of selectedResources){
            if (eachAllocation.Designation === null || eachAllocation.ResourceRegion === null || eachAllocation.StartDate === null || eachAllocation.EndDate === null || eachAllocation.AllocationPercentage === null || eachAllocation.AllocationPercentage === 0 || !eachAllocation.OnsiteStatus){
                return true;
                break;
            }
        }
    }

    onSubmitForApproval = async event => {
        var props = this.props;
        const selectedResources = this.state.preSalesResourceAllocations.filter(resource => resource.selected === true);
        if(this.allocationValidation(selectedResources)){
            this.setState({
                showErrorImage : true
            })
            this.toggleDialog('Please fill mandatory fields in selected lines to continue', 'Error');
        }else{
            if (selectedResources && selectedResources.length > 0) {
                selectedResources.forEach(function (resource) {
                    resource.Status = 7;
                    resource.selected = false;
                    resource.Type = props.Type;
                    resource.ProjectId = props.project.id
                });
                const allocationObj = {
                    mainLines : selectedResources,
                    uniqueId : this.props.project.UniqueId,
                    projectName : this.props.project.CRName ? this.props.project.CRName : this.props.project.ProjectName
                };
                submitForResourceAllocation(allocationObj)
                  .then(response => {
                      let message = 'Selected resources was sent for resource allocation.';
                      const title = 'Success';
                      this.setState({
                            showSuccessImage: true,
                            showErrorImage: false,
                            isSubmitted: true,
                            allocationDisabled : true
                        },
                        () => {
                            this.updateProject();
                        });
                      this.toggleDialog(message, title);
                  })
                  .catch(error => {
                      loggerService.writeLog(error, LOG_TYPES.ERROR);
                      this.toggleDialog(error.response.data, 'Error');
                  });
            }
        }
    };

    toggleDialog = async (message, title) => {
        await this.setState({
            popupVisible: !this.state.popupVisible,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    selectionChange = (event) => {
        event.dataItem.selected = !event.dataItem.selected;
        this.updateProject();
        this.selectedList();
    };

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        this.state.preSalesResourceAllocations.forEach(item => item.selected = checked);
        this.updateProject();
        this.selectedList();
    };

    selectedList = () => {
        var isDisabled = false;
        let isDeallocateRequest = false;
        const selectedResources = this.state.preSalesResourceAllocations.filter(resource => resource.selected === true);
        if(selectedResources.length === 0){
            isDisabled = true;
        }
        if (selectedResources && selectedResources.length > 0) {
            for(const resource of selectedResources){
                if(resource.Status !== 'NOT_SUBMITTED') {
                    isDisabled = true;
                }
            }
            for(const resource of selectedResources) {
                if (resource.Status === 'COMPLETE') {
                    isDeallocateRequest = true;
                } else {
                    isDeallocateRequest = false;
                    break;
                }
            }
        }
        this.setState({
            allocationDisabled : isDisabled,
            isCanDeallocateRequest : isDeallocateRequest
        })
    };

    pageChange = event => {
        this.setState({
          skip: event.page.skip,
          take: event.page.take
        });
    };

    handleDeleteButtonClick = dataItem => {
        this.setState(
          {
              rowItemToDelete: dataItem
          },
          () => {
              const message = 'Are you sure you want to continue to delete?';
              this.toggleConfirmDialog(message, this.deleteRow);
          }
        );
    };

    handleResourceDeallocate = async () => {
        const selectedAllocations = await this.state.preSalesResourceAllocations.filter(resource => resource.selected === true);
        if(selectedAllocations && selectedAllocations.length > 0) {
            let startDate = new Date();
            let endDate = new Date();
            if(selectedAllocations.length === 1){
                endDate = (selectedAllocations[0].EndDate && selectedAllocations[0].EndDate !== null) ? (moment(selectedAllocations[0].EndDate).format('YYYY-MM-DD')) : null;
            } else if(selectedAllocations.length > 1){
                endDate = (selectedAllocations[0].EndDate && selectedAllocations[0].EndDate !== null) ? (moment(selectedAllocations[0].EndDate).format('YYYY-MM-DD')) : null;
                // Get minimum resource end date
                for(let res of selectedAllocations){
                  if(moment(res.EndDate).format('YYYY-MM-DD') < endDate){
                    endDate = moment(res.EndDate).format('YYYY-MM-DD');
                  }
                }
            }
            this.setState(
            {
                resDeallocationItems: selectedAllocations,
                resDeallocationStartDate: new Date(startDate),
                resDeallocationEndDate: new Date(endDate)
            },
            () => {
                const message = 'By deallocation, you will be removing the allocated resource from the project. You will no longer be able to use this line for further work. Are you sure you want to remove this?';
                this.toggleDeallocationConfirmDialog(message, this.deallocateResourceRequest);
            }
            );

        }
    };

    handleDeallocationStartDateChange = event => {
        let deallocationStartDate = event.target.value;
        this.setState({
            deallocationStartDate: new Date(deallocationStartDate),
        });
    };

    deallocateResourceRequest = async () => {
        let requestsToBeUpdated = [];
        let requestsToBeDeleted = [];
        const resDeallocationItems = this.state.resDeallocationItems;
        const preSalesResourceAllocations = this.state.preSalesResourceAllocations.slice();
        
        if (resDeallocationItems && resDeallocationItems.length > 0) {
            for (const request of resDeallocationItems) {
                request.ResDeallocationStartDate = moment(new Date(this.state.deallocationStartDate)).format('YYYY-MM-DD');
                const deallocationStartDate = moment(new Date(this.state.deallocationStartDate)).format('YYYY-MM-DD');
                const resStartDate = moment(new Date(request.StartDate)).format('YYYY-MM-DD');

                if(new Date(deallocationStartDate) >= new Date(resStartDate)){
                    request.EndDate = new Date(this.state.deallocationStartDate);
        
                    await this.setState({
                        resDeallocationDesignation: request.AllocatedDesignationId ? request.AllocatedDesignationId : request.AllocatedResource ? request.AllocatedResource.Resource.DesignationId : request.Designation
                    });
                    
                    await this.calculateRowValues(request);
                    requestsToBeUpdated.push(request)

                } else {
                    requestsToBeDeleted.push(request)
                    let deletedRow = preSalesResourceAllocations.splice(request.index, 1);
                }
            }

            if (requestsToBeUpdated.length > 0 || requestsToBeDeleted.length > 0) {

                let items = {
                    requestsToBeUpdated: requestsToBeUpdated,
                    requestsToBeDeleted: requestsToBeDeleted,
                };
                await deallocatePreSalesResourceRequest(items).then(async res => {
                    this.toggleDeallocationConfirmDialog('', null);
                    if (requestsToBeDeleted.length > 0) {
                        for (let i = 0; i < preSalesResourceAllocations.length; i++) {
                            preSalesResourceAllocations[i].index = i;
                        }
                    }
                    
                    this.setState(
                        {
                            preSalesResourceAllocations: preSalesResourceAllocations
                        },
                        async () => {
                            const totalValues = await this.getTotalValues();
                            this.setState(
                            {
                                TotalCostResourceAllocation: totalValues.TotalCostResourceAllocation,
                                TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation
                            },
                            async () => {
                                await this.updateProject();
                                await this.setState({
                                    resDeallocationDesignation: null,
                                    showSuccessImage: true,
                                    showErrorImage: false
                                });
                                this.toggleMessageDialog('Successfully Deallocated', 'Success');
                            }
                        );
                        }
                    );
                }).catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
            }
            await this.setState({
                isCanDeallocateRequest: false,
                resDeallocationDesignation: null
            });
        }
    }

    toggleDeallocationConfirmDialog = (message, action) => {
        this.setState({
            showDeallocConfirmDialog: !this.state.showDeallocConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action,
        });
    };

    toggleConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    toggleMessageDialog = (message, title) => {
        this.setState({
            showMessageDialog: !this.state.showMessageDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    deleteRow = async () => {
        const allocations = this.state.preSalesResourceAllocations.slice();
        const index = this.state.rowItemToDelete.index;
        if (index > -1) {
            var deletedRow = allocations.splice(index, 1);
            if(deletedRow[0].id){
                await deleteRequest([deletedRow[0].id], deletedRow[0].Type, deletedRow[0].ProjectId)
                  .then(res => {
                  })
                  .catch(error => {
                      loggerService.writeLog(error, LOG_TYPES.ERROR);
                      this.toggleMessageDialog(error.response.data, 'Error');
                  });
            }
        }

        for (let i = 0; i < allocations.length; i++) {
            allocations[i].index = i;
        }
        this.setState(
          {
              preSalesResourceAllocations: allocations
          },
          async () => {
              const totalValues = await this.getTotalValues();
              this.setState(
                {
                    TotalCostResourceAllocation: totalValues.TotalCostResourceAllocation,
                    TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation,
                    rowItemToDelete: null
                },
                () => {
                    this.toggleConfirmDialog('', null);
                    this.updateProject();
                }
              );
          }
        );
    };

    handleCommentChange = allocComment => {
        //var allocComment = commentIn.replace(/(<([^>]+)>)/ig, '');
        if (allocComment.trim() !== '') {
            this.setState({
                allocComment: allocComment,
                isFormDirty: true
            },
              () => {
                this.updateProject();
              });
        }
    };

    render() {
        return (
            <div>
                <div className="main-heading">Pre-sales Resource Request</div>
                <div className="row" hidden={true}>
                    <div className="col-md-12">
                        <Grid>
                            <Column field="ProductID" title="ID" width="40px"/>
                            <Column field="ProductName" title="Name" width="250px"/>
                            <Column field="Category.CategoryName" title="CategoryName"/>
                            <Column field="UnitPrice" title="Price" width="80px"/>
                            <Column field="UnitsInStock" title="In stock" width="80px"/>
                        </Grid>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 btn-align-left mb-2">
                        <Button primary={true}
                                className="mr-2"
                                type="submit"
                                onClick={this.addPreSalesResource}
                                disabled={this.props.viewMode || (this.props.statusValue ? (this.props.statusValue !== null ?this.props.statusValue.StatusName !== 'Presales' : false) : false)}
                        >
                            Add
                                </Button>
                   
                        <Button primary={true}
                                className="mr-2"
                                onClick={this.handleResourceDeallocate}
                                disabled={this.props.viewMode || this.state.isCanDeallocateRequest === false}
                        >
                            Deallocate
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 presales-sec">
                        <Grid 
                            style={{ height: '250px' }}
                            data={this.state.preSalesResourceAllocations.slice(
                                this.state.skip,
                                this.state.take + this.state.skip
                            )}
                            selectedField="selected"
                            onSelectionChange={this.selectionChange}
                            onHeaderSelectionChange={this.headerSelectionChange}
                            skip={this.state.skip}
                            take={this.state.take}
                            total={this.state.preSalesResourceAllocations.length}
                            pageable={true}
                            onPageChange={this.pageChange}
                            resizable
                        >
                            <Column
                                field="selected"
                                width=""
                                //disabled={true}
                                headerSelectionValue={
                                    (this.state.preSalesResourceAllocations.length > 0 && this.state.preSalesResourceAllocations[0].selected) ? 
                                    (this.state.preSalesResourceAllocations.findIndex(dataItem => dataItem.selected === false) === -1) : 
                                    false
                                }
                            />
                            <Column
                                field="Designation"
                                headerCell={props => <label>Designation</label>}
                                width="250px"
                                maxLength={10}
                                cell={this.ComboBoxCell}
                            />
                            <Column
                                field="ResourceRegion"
                                headerCell={props => <label>Resource Region</label>}
                                width="150px"
                                maxLength={10}
                                cell={this.ComboBoxCell}
                            />
                            <Column
                                field="OnsiteStatus"
                                headerCell={props => <label>Onsite / Offshore</label>}
                                width="150px"
                                maxLength={10}
                                cell={this.ComboBoxCell}
                            />
                            <Column
                                field="DateStartDate"
                                headerCell={props => <label>Start Date</label>}
                                width="140px"
                                maxLength={40}
                                cell={this.DatePickerCell}
                            />
                            <Column
                                field="EndDate"
                                headerCell={props => <label>End Date</label>}
                                width="140px"
                                maxLength={40}
                                cell={this.DatePickerCell}
                            />
                            <Column
                                field="AllocationPercentage"
                                headerCell={props => <label>Allocation (%)</label>}
                                width="100px"
                                maxLength={6}
                                cell={this.NumericTextBoxCell}
                            />
                            <Column
                                field=""
                                headerCell={props => <label>Status</label>}
                                width="200px"
                                cell={props => (<ResourceRequestStatusCell {...props} />)}
                            />
                            <Column
                                field="Comment"
                                headerCell={props => <label>Comment</label>}
                                width="240px"
                                maxLength={6}
                                cell={this.CommentBoxCell}
                            />
                            <Column
                                field="AllocatedResource.Resource.Name"
                                headerCell={props => <label>Resource</label>}
                                width="150px"
                            />
                            <Column
                                field=""
                                headerCell={props => <label>Actions</label>}
                                width="75px"
                                cell={props => (
                                  <RowCellDeleteButton
                                    {...props}
                                    disabled={this.props.viewMode || (this.props.statusValue ? (this.props.statusValue !== null ?this.props.statusValue.StatusName !== 'Presales' : false) : false) || props.dataItem.Status !== 'NOT_SUBMITTED'}
                                    onDeleteButtonClick={this.handleDeleteButtonClick}
                                  />
                                )}
                            />
                        </Grid>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2">
                        <div className="d-block">
                            <label>Total Cost:</label>
                        </div>
                        <div className="d-block">
                            <Input
                                disabled={true}
                                value={(this.props.billingregionValue && this.props.billingregionValue.Currency ? this.props.billingregionValue.Currency.Code : '') + ' ' + Number(this.state.TotalCostResourceAllocation).toFixed(2)}
                            />
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="d-block">
                            <label>Total Effort:</label>
                        </div>
                        <div className="d-block">
                            <Input
                                disabled={true}
                                value={this.state.TotalEffortDaysResourceAllocation.toFixed(2)}
                            />
                        </div>
                    </div>
                </div>

                {/* <div className="row mt-3">
                    <div className="col-md-5">
                        <label htmlFor="">Project Owner's Comment:</label>
                    </div>
                    <div className="col-md-12" >
                        <ReactQuill
                          name="Comment"
                          onChange={this.handleCommentChange}
                          value={this.state.allocComment ? this.state.allocComment : null}
                          readOnly = {this.props.viewMode || (this.props.statusValue ? (this.props.statusValue !== null ?this.props.statusValue.StatusName !== 'Presales' : false) : false)}
                          maxLength="100"
                        />
                    </div>
                </div> */}

                {/*<div className="row mt-3">
                    <div className="col-md-3">
                        <div className="d-block">
                                <label htmlFor="" className="mandatory">
                                    Allocate a Project Manager:
                                </label>
                            </div>
                            <div className="d-block">
                            <div className="">
                                <ComboBox
                                    data={this.props.resourceItems}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    value={this.props.resourceValue}
                                    onChange={this.props.handleResourceChange}
                                    required={true}
                                    filterable={true}
                                    onFilterChange={this.props.filterChangeResource}
                                    disabled={this.props.viewMode}
                                />
                                {this.props.errorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.props.resourceValue)}>
                                      Please allocate a project manager
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>*/}

                {this.state.showConfirmDialog === true && (
                  <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                      <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                      <DialogActionsBar>
                          <button className="k-button" onClick={this.toggleConfirmDialog}>
                              No
                          </button>
                          <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                              Yes
                          </button>
                      </DialogActionsBar>
                  </Dialog>
                )}

                {this.state.showDeallocConfirmDialog === true && (
                <Dialog title="Resource Deallocation" onClose={this.toggleDeallocationConfirmDialog} width="400px">

                    <div className="d-flex flex-col justify-content-start" style={{margin: '25px'}}>
                        <div className="d-block">
                            <label>Deallocation From</label>
                        </div>

                        <div className="d-block col-md-1">
                            <label></label>
                        </div>

                        <div className="d-block">
                            <div>
                            <DatePicker
                                width="100"
                                name="deallocationStartDate"
                                min={this.state.resDeallocationStartDate}
                                max={this.state.resDeallocationEndDate}
                                format="MM/dd/yyyy"
                                formatPlaceholder={{year: 'YYYY', month: 'MM', day: 'DD'}}
                                onChange={this.handleDeallocationStartDateChange}
                            />
                            </div>

                        </div>
                    </div>

                    <div>
                        <p style={{ margin: '25px', textAlign: 'justify' }}>{this.state.confirmDialogMessage}</p>
                    </div>

                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDeallocationConfirmDialog}>
                            Cancel
                        </button>

                        <button className="k-button modal-primary" disabled={this.state.disableConfirmButton} onClick={this.state.confirmDialogAction}>
                            Confirm
                        </button>
                    </DialogActionsBar>
                </Dialog>
                )}

                {this.state.showMessageDialog === true && (
                  <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

                      {this.state.showSuccessImage === true && (
                        <div className="text-center">
                            <img className="successImg" src={SuccessImg} alt="Success"/>
                        </div>
                      )}

                      {this.state.showErrorImage === true && (
                        <div className="text-center">
                            <img className="successImg" src={ErrorImg} alt="Error Image"/>
                        </div>
                      )}

                      <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                      <DialogActionsBar>
                          {this.state.showErrorImage === true && (
                            <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                                OK
                            </button>
                          )}
                          {(this.state.showSuccessImage === true && this.state.showErrorImage === false) && (
                            <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                                OK
                            </button>
                          )}
                      </DialogActionsBar>
                  </Dialog>
                )}

                <div>
                    {this.state.popupVisible === true && (
                      <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                          {this.state.showSuccessImage === true && (
                            <div className="text-center">
                                <img className="successImg" src={SuccessImg} alt="Success"/>
                            </div>
                          )}

                          {this.state.showErrorImage === true && (
                            <div className="text-center">
                                <img className="successImg" src={ErrorImg} alt="Error Image"/>
                            </div>
                          )}
                          <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                          <DialogActionsBar>

                              {this.state.showSuccessImage === true && this.state.isSubmitted && (
                                <button className="k-button modal-primary" onClick={this.toggleDialog}>
                                    OK
                                </button>
                              )}

                              {this.state.showSuccessImage === true && !this.state.isSubmitted && (
                                <button className="k-button modal-primary" onClick={this.toggleDialog}>
                                    OK
                                </button>
                              )}

                              {this.state.showErrorImage === true && (
                                <button className="k-button modal-primary" onClick={this.toggleDialog}>
                                    OK
                                </button>
                              )}
                          </DialogActionsBar>
                      </Dialog>
                    )}
                </div>

            </div>
        );
    }
}

export default ResourceRequest;
