import React, { Component } from 'react';
import MultiIncrementYearPicker from './components/MultiIncrementYearPicker';
import { Redirect } from 'react-router';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';

import {
  getActiveRateCards
} from '../rateCard/RateCardService';
import {
  saveMultiyearIncrements,
  updateMultiyearIncrements,
  getMultiYearInfoByYear,
  updateMultiyearIncrementsSendMail
} from './MultiyearIncrementService';
import { getActiveCostCardsIntegrated } from '../../integration/MasterDataService'

import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { orderBy } from '@progress/kendo-data-query';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import $ from "jquery";

class CreateMultiyearIncrement extends Component {

  isMount = false;
  lastSelectedIndex = 0;

  constructor(props) {
    super(props);
    this.state = {
      action: '',
      selectedYear: null,
      isFormDirty: false,
      showBulkChangeIncrement: false,
      data: [],
      gridDetails: [],
      skip: 0,
      take: 20,
      SearchRequest: {
        selectedYear: null
      },
      sort: [{ field: 'Type', dir: 'asc' }],
      multiyearinfo: null,
      isDisableEditMode: true,
      editModeYear: null,
      ratevalue: null,
      bulkIncrement: null,
      isDisableBulkIncrement: true,
      isDisableBulkSubmit: true,
      isDisableSave: true,
      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      isErrorMessageVisible: false,
      visible: false,
      modifiedUser: '',
      existYear: null,
      toMultiyearInfo: false,
      isSuccessful: false,
      isBulkOperation: false,
      isFromBulkIncrement: false,
      editedCostCardIds: []
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.setHeaderTitle(this.props.location.action);
    window.scrollTo(0, 0);

    if (this.isMount) {
      this.setState(
        {
          action: this.props.location.action,
          multiyearinfo: this.props.location.multiyearinfo ? this.props.location.multiyearinfo : null
        },
        () => {
          if (this.props.location.action === 'edit') {
            this.setState({
              editModeYear: this.props.location.multiyearinfo.Year
            });
            this.renderEditModeGrid(this.props.location.multiyearinfo);
          } else {
            this.populateMultiyearIncrements();
          }
        }
      );
    }

    $(document).ready(function () {
      $("div.title-remove > div > span > span > span:last-child").removeAttr("title");
    });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async action => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      if (action === 'edit') {
        this.props.onHeaderTitleChange('Edit Multi-Year Increments');
      } else {
        this.props.onHeaderTitleChange('Create Multi-Year Increments');
      }
    }
  };

  handleSubmit = async event => {
    if (this.props.location.action === 'edit') {
      await this.updateIncrements(event);
    } else {
      if (!this.validation()) {
        if (this.isMount) {
          this.setState({
            isErrorMessageVisible: true,
            showMessageDialog: true,
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleMessageDialog('Please fix the highlighted errors to continue', 'Error');
      } else {
        if (this.isMount) {
          this.setState(
            {
              isErrorMessageVisible: false,
              isDisableSave: true
            },
            () => {
              this.saveIncrements(event);
            }
          );
        }
      }
    }
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validation = () => {
    if (
      this.validateProperty(this.state.SearchRequest.selectedYear)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  updateIncrements = async event => {
    let modifiedUser;
    let currentdate = new Date();
    const userDetails = window.LOGGED_USER;
    if (userDetails) {
      modifiedUser = userDetails.displayName;
    }
    let list = this.state.gridDetails.slice();
    let data = [];
    if (list.length > 0) {
      for (const item of list) {
        if (item.Type === 'Rate Card') {
          const rateData = {
            id: item.id,
            RatePercentage: item.RatePercentage,
            LastModifiedDate: currentdate,
            LastModifiedUser: modifiedUser,
            MultiyearInfoGridId: item.MultiyearInfoGridId
          };
          data.push(rateData);
        }
        if (item.Type === 'Cost Card') {
          const costData = {
            id: item.id,
            RatePercentage: item.RatePercentage,
            LastModifiedDate: currentdate,
            LastModifiedUser: modifiedUser,
            MultiyearInfoGridId: item.MultiyearInfoGridId
          };
          data.push(costData);
        }
      }
    }

    await updateMultiyearIncrements(data)
      .then(async res => {
        const editedCostCardIds = [...new Set(this.state.editedCostCardIds)];
        await updateMultiyearIncrementsSendMail(editedCostCardIds);
        if (this.isMount) {
          this.setState({
            editModeYear: '',
            gridDetails: [],
            isDisableSave: true,
            isSuccessful: true,
            isDisableBulkIncrement: true,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        if (!this.state.isFromBulkIncrement){
          this.toggleMessageDialog('Successfully updated the incremental values!', 'Success');
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleMessageDialog('Error in updating the incremental values!', 'Error');
      });
  };

  saveIncrements = event => {
    let list = this.state.gridDetails.slice();
    let data = [];
    let modifiedUser;
    let currentdate = new Date();
    let resultset = [];

    getMultiYearInfoByYear({ Year: this.state.SearchRequest.selectedYear }).then(res => {
      resultset = res.data;

      if (resultset.length === 0) {
        const userDetails = window.LOGGED_USER;
        if (userDetails) {
          modifiedUser = userDetails.displayName;
        }
        for (const item of list) {
          if (item.Type === 'Rate Card') {
            const rateData = {
              RatePercentage: item.RatePercentage,
              Year: this.state.SearchRequest.selectedYear,
              LastModifiedUser: modifiedUser,
              CreatedDate: currentdate,
              LastModifiedDate: currentdate,
              Type: item.Type,
              RateCardId: item.id
            };
            data.push(rateData);
          }
        }
        for (const item of list) {
          if (item.Type === 'Cost Card') {
            const costData = {
              RatePercentage: item.RatePercentage,
              Year: this.state.SearchRequest.selectedYear,
              LastModifiedUser: modifiedUser,
              CreatedDate: currentdate,
              LastModifiedDate: currentdate,
              Type: item.Type,
              CostCardId: item.id
            };
            data.push(costData);
          }
        }

        saveMultiyearIncrements(data)
          .then(res => {
            if (this.isMount) {
              this.setState({
                gridDetails: [],
                isDisableSave: true,
                selectedYear: null,
                isDisableBulkIncrement: true,
                isSuccessful: true,
                showSuccessImage: true,
                showErrorImage: false
              });
            }
            this.toggleMessageDialog(
              `Multi-year Increment ${this.state.SearchRequest.selectedYear} is successfully created`,
              'Success'
            );
            if (this.isMount) {
              this.setState({
                SearchRequest: {
                  selectedYear: null
                }
              });
            }
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            if (this.isMount) {
              this.setState({
                showSuccessImage: false,
                showErrorImage: true
              });
            }
            this.toggleMessageDialog('Error in saving the multiyear records!', 'Error');
          });
      } else {
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleMessageDialog(
          'The selected year exists already.Please choose another year',
          'Error'
        );
        if (this.isMount) {
          this.setState({
            isDisableSave: false
          });
        }
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  renderEditModeGrid = multiyearinfo => {
    let list = [];
    if (multiyearinfo.MultiyearIncrementGrids && multiyearinfo.MultiyearIncrementGrids.length > 0) {
      list = multiyearinfo.MultiyearIncrementGrids;
      for (const item of list) {
        if (item.Type === 'Rate Card') {
          item.Name = item.RateCard.Name;
          if (item.RateCard.Region) {
            item.Region = item.RateCard.Region.Name;
          } else {
            item.Region = 'N/A';
          }
          if (item.RateCard.Customer) {
            item.CustomerName = item.RateCard.Customer.Name;
          } else {
            item.CustomerName = 'N/A';
          }
          item.BillingRegion = 'N/A';
          item.ResourceRegion = 'N/A';
        }
        if (item.Type === 'Cost Card') {
          item.Name = item.CostCard.Name;
          if (item.CostCard.BillingRegion) {
            item.BillingRegion = item.CostCard.BillingRegion.Name;
          } else {
            item.BillingRegion = 'N/A';
          }
          if (item.CostCard.ResourceRegion) {
            item.ResourceRegion = item.CostCard.ResourceRegion.Name;
          } else {
            item.ResourceRegion = 'N/A';
          }
          item.Region = 'N/A';
          item.CustomerName = 'N/A';
        }
      }
    }
    if (this.isMount) {
      this.setState({
        gridDetails: list.map(dataItem => Object.assign({ selected: false }, dataItem)),
        selectedInfoGrid: multiyearinfo,
        isDisableSave: false
      });
    }
  };

  itemChange(event) {
    event.dataItem[event.field] = event.value;
  }

  populateMultiyearIncrements = () => {
    let list = [];
    getActiveRateCards().then(res => {
      let arr = res.data;

      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          list.push(arr[i]);
        }
      }

      for (const item of list) {
        if (item.CustomerId || item.RegionId) {
          item.Region = item.Region.Name;
          if (item.Customer) {
            item.CustomerName = item.Customer.Name;
          } else {
            item.CustomerName = 'N/A';
          }

          item.Type = 'Rate Card';
          item.BillingRegion = 'N/A';
          item.ResourceRegion = 'N/A';
          item.RatePercentage = null;
        }
      }

      if (list.length === 0) {
        if (this.isMount) {
          this.setState({
            isDisableSave: true
          });
        }
      }
      if (this.isMount) {
        this.setState({
          gridDetails: list.map(dataItem => Object.assign({ selected: false }, dataItem)),
          isDisableSave: false
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    getActiveCostCardsIntegrated().then(res => {
      let costcards = res.data;
      if (costcards && costcards.length > 0) {
        for (let i = 0; i < costcards.length; i++) {
          list.push(costcards[i]);
        }
      }
      for (const item of list) {
        if (item.ResourceRegionId || item.BillingRegionId) {
          item.BillingRegion = item.BillingRegion.Name;
          item.ResourceRegion = item.ResourceRegion.Name;
          item.Type = 'Cost Card';
          item.Region = 'N/A';
          item.CustomerName = 'N/A';
          item.RatePercentage = null;
        }
      }
      if (this.isMount) {
        this.setState({
          gridDetails: list.map(dataItem => Object.assign({ selected: false }, dataItem)),
          isDisableSave: false
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  handleOnChangeYear = event => {
    const value = event.value;
    const field = event.name;
    if (value) {
      if (this.isMount) {
        this.setState({
          isFormDirty: true
        });
      }
    } else {
      if (this.isMount) {
        this.setState({
          isFormDirty: false
        });
      }
    }
    if (this.isMount) {
      this.setState(prevState => {
        return {
          SearchRequest: { ...prevState.SearchRequest, [field]: value }
        };
      });
    }
  };

  sortChange = event => {
    if (this.isMount) {
      this.setState({
        sort: event.sort
      });
    }
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };

  navigateBack = event => {

    if (this.state.isBulkOperation === true) {
      this.setState({
        isBulkOperation: false,
        showMessageDialog: false
      });
    } else {
      if (this.isMount) {
        this.setState({
          toMultiyearInfo: true
        });
      }
    }
  };

  toggleCancelConfirmDialog = () => {
    if (this.isMount) {
      this.setState({
        showCancelConfirmation: !this.state.showCancelConfirmation
      });
    }
  };

  onClickBulkIncrement = () => {
    if (this.isMount) {
      this.setState({ showBulkChangeIncrement: true, isBulkOperation: true });
    }
  };

  toggleCloseBulkDialog = () => {
    if (this.isMount) {
      this.setState({ showBulkChangeIncrement: false, isBulkOperation: false });
    }
  };

  changeBulkIncrement = event => {
    if (this.isMount) {
      this.setState({
        bulkIncrement: event.target.value
      });

      this.setState({
        isFormDirty: true
      });
      if (event.target.value > 0) {
        this.setState({
          isDisableBulkSubmit: false
        });
      }
    }
  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  handleSubmitBulkIncrement = async event => {
    let list = this.state.gridDetails.slice();
    let selectedRows = list.filter(obj => {
      if (obj.selected === true) {
        return true;
      }
      return null;
    });

    event.preventDefault();

    if (selectedRows.length > 0) {
      for (let i = 0; i < selectedRows.length; i++) {
        selectedRows[i].RatePercentage = this.state.bulkIncrement;
      }
    }

    if (this.isMount) {
      await this.setState({
        gridDetails: list,
        showSuccessImage: true,
        showErrorImage: false,
        isFromBulkIncrement: true
      });
    }
    this.toggleCloseBulkDialog();
    await this.handleSubmit();
    this.toggleMessageDialog('Successfully changed the incremental values in bulk!', 'Success');
    for (const item of selectedRows) {
      item.selected = false;
    }

    if (this.isMount) {
      this.setState({
        isFormDirty: true,
        isSuccessful: false,
        isDisableBulkIncrement: true,
        isFromBulkIncrement: false
      });
    }
  };

  pageChange = event => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  };

  selectionChange = event => {
    event.dataItem.selected = !event.dataItem.selected;

    let list = this.state.gridDetails.slice();
    let selectedRows = list.filter(obj => {
      if (obj.selected === true) {
        return true;
      }
      return null;
    });
    if (selectedRows.length > 0) {
      if (this.isMount) {
        this.setState({
          isDisableBulkIncrement: false
        });
      }
    }
    if (selectedRows.length === 0) {
      if (this.isMount) {
        this.setState({
          isDisableBulkIncrement: true
        });
      }
    }
    this.forceUpdate();
  };

  rowClick = event => {
    let last = this.lastSelectedIndex;
    const current = this.state.gridDetails.findIndex(dataItem => dataItem === event.dataItem);

    if (!event.nativeEvent.shiftKey) {
      this.lastSelectedIndex = last = current;
    }

    if (!event.nativeEvent.ctrlKey) {
      this.state.gridDetails.forEach(item => (item.selected = false));
    }
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      this.state.gridDetails[i].selected = select;
    }
    this.forceUpdate();
  };

  headerSelectionChange = event => {
    const checked = event.syntheticEvent.target.checked;
    if (checked === true) {
      if (this.isMount) {
        this.setState({
          isDisableBulkIncrement: false
        });
      }
    }
    if (checked === false) {
      if (this.isMount) {
        this.setState({
          isDisableBulkIncrement: true
        });
      }
    }
    this.state.gridDetails.forEach(item => (item.selected = checked));
    this.forceUpdate();
  };

  handleCellChange = (event, dataItem) => {
    const rateValue = event.target.value;

    if (this.isMount) {
      this.setState({
        isFormDirty: true
      });
    }
    let list = this.state.gridDetails.slice();
    let record = list.filter(obj => obj.id === dataItem.id && obj.Type === dataItem.Type);

    if (record.length > 0) {
      record[0].RatePercentage = rateValue;
    }
    let editedCostCardIds = this.state.editedCostCardIds;
    if (dataItem.CostCard) {
      editedCostCardIds.push(dataItem.CostCard.id);
    }

    if (this.isMount) {
      this.setState({
        gridDetails: list,
        editedCostCardIds: editedCostCardIds
      });
    }
  };

  render() {
    if (this.state.toMultiyearInfo === true) {
      return <Redirect to="/admin/multiyearincrement/multiyearincrementinfo" />;
    }
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-12">
            <div className="main-heading">Create Multi-Year Increment</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="d-block">
              <label>Financial Year:</label>
            </div>
            <div className="d-block">
              {this.state.action === 'edit' ? (
                <div className="">
                  <Input
                    autoComplete="off"
                    type="text"
                    value={this.state.editModeYear || ''}
                    disabled={this.state.isDisableEditMode}
                  />
                </div>
              ) : (
                  <div className="title-remove">
                    <MultiIncrementYearPicker
                      value={this.state.SearchRequest.selectedYear}
                      onChange={this.handleOnChangeYear}
                      name="selectedYear"
                      autoComplete="off"
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.SearchRequest.selectedYear)}>
                        Please choose a year
                    </span>
                    ) : null}
                  </div>
                )}
            </div>
          </div>
        </div>

        <div className="main-seperator" />

        <div className="row">
          <div className="col-md-12">
            <div className="main-heading">Search Results</div>
          </div>
        </div>

        <div className="row mt-0">
          <div className="col-md-12">
            <Grid
              data={orderBy(this.state.gridDetails, this.state.sort).slice(
                this.state.skip,
                this.state.take + this.state.skip
              )}
              skip={this.state.skip}
              take={this.state.take}
              total={this.state.gridDetails.length}
              pageable={true}
              onPageChange={this.pageChange}
              resizable
              onItemChange={this.itemChange}
              sortable={true}
              sort={this.state.sort}
              onSortChange={this.sortChange}
              selectedField="selected"
              onSelectionChange={this.selectionChange}
              onHeaderSelectionChange={this.headerSelectionChange}
              onRowClick={this.rowClick}
            >
              <Column
                field="selected"
                width="50px"
                headerSelectionValue={
                  this.state.gridDetails.findIndex(dataItem => dataItem.selected === false) === -1
                }
              />
              <Column field="Name" title="Rate/Cost Card Name" width="180px" />
              <Column field="Type" title="Type" />
              <Column field="Region" title="Region" />
              <Column field="CustomerName" title="Customer" />
              <Column field="BillingRegion" title="Billing Region" />
              <Column field="ResourceRegion" title="Resource Region" />
              <Column
                field="RatePercentage"
                title="Increment for the year (%)"
                width="180px"
                cell={this.NumericTextBoxCell}
              />
            </Grid>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 btn-align-right">
            <Button
              primary={true}
              disabled={this.state.isDisableSave}
              type="submit"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button
              onClick={this.onClickBulkIncrement}
              primary={true}
              disabled={this.state.isDisableBulkIncrement}
            >
              Bulk Increment
            </Button>
            <Button onClick={this.onClickCancel}>
              Cancel
            </Button>
          </div>
        </div>
        <div>
          {this.state.showCancelConfirmation === true && (
            <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
              <p style={{ margin: '25px', textAlign: 'center' }}>
                This form contains unsaved changes. Would you like to close it?
              </p>
              <DialogActionsBar>
                <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.navigateBack}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          {this.state.showBulkChangeIncrement === true && (
            <Dialog
              title="Bulk changes for increment %"
              onClose={this.toggleCloseBulkDialog}
              width="400px"
            >
              <div>
                <div className="row">
                  <div className="col-md-5">
                    <label>Add increment value:</label>
                  </div>
                  <div className="col-md-5">
                    <NumericTextBox
                      name="bulkIncrement"
                      value={this.state.bulkIncrement}
                      min={0}
                      width={'100%'}
                      format="n2"
                      onChange={this.changeBulkIncrement}
                      max={100}
                    />
                  </div>
                  <div className="col-md-2 pl-0">
                    <label>%</label>
                  </div>
                </div>
              </div>
              <DialogActionsBar>
                <button
                  disabled={this.state.isDisableBulkSubmit}
                  className="k-button active modal-primary"
                  onClick={this.handleSubmitBulkIncrement}
                >
                  Submit
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.navigateBack}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    );
  }

  NumericTextBoxCell = props => {
    if (props.field === 'RatePercentage') {
      return (
        <td>
          <NumericTextBox
            width="100%"
            onChange={event => this.handleCellChange(event, props.dataItem)}
            value={Number(props.dataItem.RatePercentage)}
            min={0}
            max={100}
          />
        </td>
      );
    }

    return null;
  };
}

export default CreateMultiyearIncrement;
