import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Update CostCard by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param data
 * @param costCardId
 */
export function updateCostCard(data, costCardId) {
  const endPoint = apiUrl + '/masterData/costCard/update/' + costCardId;
  return http.put(endPoint, data);
}

/**
 * Save CostCard Details.
 * @returns {array} array list of CostCard Details
 * @param params
 */
export function saveCostCard(params) {
  const endPoint = apiUrl + '/masterData/costCard/save';
  return http.post(endPoint, params);
}

/**
 * Update CostCard Status by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param IsActive
 * @param costCardId
 */
export function updateCostCardStatus(IsActive, costCardId) {
  const endPoint = apiUrl + '/masterData/costCard/updateStatus/' + costCardId;
  return http.put(endPoint, IsActive);
}

/**
 * Get multiyear cost info from cost card.
 * @param params
 * @returns cost card array
 */
export function getMultiYearCost(params) {
  const endPoint = apiUrl + '/masterData/costCard/rate/multiyear';
  return http.post(endPoint, params);
}

/**
 * Get Cost for drop downs by given params.
 * @returns {array} array list of Default Rate
 * @param params
 */
export function getCost(params) {
  const endPoint = apiUrl + '/masterData/costcard/rate';
  return http.post(endPoint, params);
}

/**
 * Get all Active Cost Cards.
 * @returns {array} array list of Active Cost Cards
 */
export function getActiveCostCards() {
  const endPoint = apiUrl + '/masterData/costCard/getactivecostcards';
  return http.get(endPoint);
}

/**
 * Get CostCard Details.
 * @returns {array} array list of CostCard Details
 * @param costCardId
 */
export function getCostCardDetails(costCardId) {
  const endPoint = apiUrl + '/masterData/costCard/' + costCardId;
  return http.get(endPoint);
}

/**
 * Search CostCard Details.
 * @returns {array} array list of CostCard Details
 * @param params
 */
export function searchCostCards(params) {
  const endPoint = apiUrl + '/masterData/costCard/search';
  return http.post(endPoint, params);
}

/**
 * Export CostCard Details.
 * @returns {array} array list of CostCard Details
 * @param params
 */
 export function exportCostCards(params) {
  const endPoint = apiUrl + '/masterData/costCard/export';
  return http.post(endPoint, params);
}

/**
 * Get Costs of a resource.
 * @param costItems
 * @returns {AxiosPromise<any>}
 */
export function getCostDetails(costItems) {
  const endPoint = apiUrl + '/masterData/costCard/getCostDetails';
  return http.post(endPoint, costItems);
}

export function getProjectDetails(projectId) {
  const endPoint = apiUrl + '/masterData/costCard/getProjectFromProjectId/' + projectId;
  return http.get(endPoint);
}

export function getCRDetails(crId) {
  const endPoint = apiUrl + '/masterData/costCard/getCRFromCrId/' + crId;
  return http.get(endPoint);
}

/**
 * Get Costs of a resource.
 * @param costItems
 * @returns {AxiosPromise<any>}
 */
export function getCostofAllocatedResource(costItems) {
  const endPoint = apiUrl + '/masterData/costCard/getCostofAllocatedResource';
  return http.post(endPoint, costItems);
}
