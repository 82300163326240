import React, { Component, useEffect, useState  } from 'react';
import { GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-react-dialogs';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from "@progress/kendo-data-query";
import { Tooltip } from 'reactstrap';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { getHolidaysIntegrated } from '../../integration/MasterDataService';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import { getFormattedDate, isWeekend } from '../../integration/CommonUtils';
import approvedStatus from '../enums/ApprovedStatusTypes';
import {
  DateInput,
  Calendar,
  DatePicker,
  DateTimePicker,
  TimePicker,
  MultiViewCalendar,
  DateRangePicker
} from '@progress/kendo-react-dateinputs';
import {
  getResourceList,
  getProjectTaskAllocations,
  getTaskList,
  getEnterdTotalHours,
  getBilableCalculatedTotals,
  getTimeCards,
  getExcelData,
  getProjects,
  getChangeRequest,
  getNonProjectTaskGroup,
  getProjectTask,
  getNonProjectTak,
  getStatistics,
  saveSearchCriteria,
  getProjectTaskAllocationsWithTime,
  getSearchCriteria,
  listOfResource,
  resourceEntries,
  usersList
} from './ViewAllTimeSheetService';
import {getFilteredUsers} from '../../userManagement/user/userService';
import {getActiveResourceRegions, getResourceRegions, getRegions} from '../../masterData/region/RegionService';
import {getBillingDivi} from '../../masterData/practice/PracticeService';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import AsyncSelectMultipleServices from '../nonProjectTaskSearch/components/AsyncSelectMultipleServices';
import AsyncSelectMultiSelect from '../nonProjectTaskSearch/components/AsyncSelectMultiSelect';
import Loader from '../../integration/components/Loader';
import 'moment-timezone';
import Moment from 'react-moment';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import { getPracticeTypesIntegrated, getAllPracticeDivisionMapIntegrated } from '../../integration/MasterDataService';
import WorkLocationStatus from '../enums/WorkLocationStatus';

const moment = require('moment');

class ViewAllTimeheets extends Component {
  isMount = false;
  lastSelectedIndex = 0;

  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
      nonbilldialog: false,
      unfreezeTa: false,
      rejectTa: false,
      status: 0,
      windowDialogVisible: false,
      timesheet: [<td />],
      totalHrsCalcu: [<td />],
      resourceaccordion: [<td />],
      allpendingtimeapp: [<td />],
      taskdetailtooltipopen: false,
      newDate: new Date(),
      firstDayOfWeek: null,
      LastDayOfWeek: null,
      dateOfSun: null,
      dateOfMon: null,
      dateOfTue: null,
      dateOfWed: null,
      dateOfTur: null,
      dateOfFri: null,
      dateOfSat: null,
      selectedProjectOrCr: null,
      selectedTask: null,
      selectedResource: [],
      selectedPractice: null,
      selectedRegion: null,
      IsApprNotNeed: false,
      IsRejected: false,
      IsApprovalNonBillable: false,
      IsApprovalBillable: false,
      IsPendiingApproval: false,
      IsMyResources: false,
      confirmedProjFilterValue: null,
      confirmedTaskFilterValue: null,
      confirmedResourceFilterValue: null,
      ConfirmeIsApprNotNeed: false,
      ConfirmeIsRejected: false,
      ConfirmeIsApprovalNonBillable: false,
      ConfirmeIsApprovalBillable: false,
      ConfirmeIsPendiingApproval: false,
      resourceList: [],
      loader: true,
      allChecked: false,
      list: [
        {
          id: 1,
          isChecked: false,
          Project: 'M3 Implementation',
          Customer: 'Beega',
          Task: 'M3 Master Data Configurations',
          Hours: 6,
          Description: 'Lorem Ipsum'
        },
        {
          id: 2,
          isChecked: false,
          Project: 'M3 Upgrade',
          Customer: 'Avera',
          Task: 'Testing',
          Hours: 2,
          Description: 'Testing 123'
        }
      ],
      practice: [],
      region: [],
      selectedResourceRegion: [],
      selectedResourcePractice: [],
      selectedProject: [],
      selectedCR: [],
      selectedTask: [],
      selectedResource: [],
      selectedLineManger: [],
      selectedApprover: [],
      selectedProjectRegion: [],
      selectedProjectPractice: [],
      downloadExcel: false,
      logDateFrom: new Date(),
      logDateTo: new Date(),
      enterdHours: 0,
      billableHours: 0,
      nonBillableHours: 0,
      pendingHours: 0,
      rejectedHours: 0,
      entries: [],
      weekTotal: [],
      bilableTotal: [],
      expandedPanels: new Set(),
      fromInitialRender: false,
      billingRegion: [],
      openStatToolTip: false,
      nonBillableGroup: [],
      IsApprovalNotNeeded: false,
      filterRun: false,
      visible: false,
      dialogMessage: '',
      dialogTitle: '',
      showErrorImage: false,
      showSuccessImage: false,
      showSaveFilterViewDialog: false,
      filterViewName: null,
      filterViewId: null,
      savedFilterViews: [],
      selectedResourceDepartment: [],
      practicesData: [],
      practiceDataAll: [],
      practiceDivisionMap: [],
      pmUserRole: false,
      clientExcel: false
    };

    this.toggleWindowDialog = this.toggleWindowDialog.bind(this);
    this.toggleRejectDialog = this.toggleRejectDialog.bind(this);
    this.toggleNonbillDialog = this.toggleNonbillDialog.bind(this);
    this.toggleUnfreezeDialog = this.toggleUnfreezeDialog.bind(this);
  }
  taskList = [];
  getTaskList = async (userId, type) => {
    try {
      this.setState({expandedPanels: this.state.expandedPanels.add(userId)}, async () => {
        const {data} = await resourceEntries({
          project: this.state.selectedProject.length > 0 ? this.state.selectedProject.map(value => value) : [],
          projectRegion: this.state.selectedProjectRegion.length > 0 ? this.state.selectedProjectRegion.map(value => value.id) : [],
          projectPractice: this.state.selectedProjectPractice.length > 0 ? this.state.selectedProjectPractice.map(value => value.id) : [],
          task: this.state.selectedTask.length > 0 ?  this.state.selectedTask.map(e => e.Name) : [],
          changeRequests: this.state.selectedCR.length > 0 ? this.state.selectedCR.map( e => e.id) : [],
          resourceId: [userId],
          resourceRegion: this.state.selectedResourceRegion.length > 0 ?  this.state.selectedResourceRegion.map(value => value.id) : [],
          resourceDepartment: this.state.selectedResourceDepartment.length > 0 ?  this.state.selectedResourceDepartment.map(value => value.id) : [],
          resourcePractice: this.state.selectedResourcePractice.length > 0 ?  this.state.selectedResourcePractice.map(value => value.id) : [],
          approver: this.state.selectedApprover.length > 0 ?  this.state.selectedApprover.map(value => value.id) : [],
          LineManagerId: this.state.selectedLineManger.length > 0 ? this.state.selectedLineManger.map(value => value.id) : [],
          logDateFrom: moment(this.state.firstDayOfWeek || new Date()).startOf('isoWeek').format('YYYY-MM-DD'),
          logDateTo: moment(this.state.LastDayOfWeek || new Date()).endOf('isoWeek').format('YYYY-MM-DD'),
          IsRejected: this.state.IsRejected,
          IsApprovalNonBillable: this.state.IsApprovalNonBillable,
          IsApprovalBillable: this.state.IsApprovalBillable,
          IsPendiingApproval: this.state.IsPendiingApproval,
          IsApprovalNotNeeded: this.state.IsApprovalNotNeeded,
          pmUserRole: this.state.pmUserRole
        });
        const {finalValue, projectTotalWeek, bilableTotalWeek} = data;
        this.taskList = finalValue;
        let params = this.taskList.map((task, index) => {
          return { index: index, task: task.taskId };
        });
        await getProjectTaskAllocationsWithTime(params).then(res => {
          if (res.data && res.data.length > 0) {
            res.data.forEach(i => {
              this.taskList[i.index].allocationHours = i.allocationHours;
            });
          }
        });
        this.setState({entries: {
          ...this.state.entries,
          [userId]: finalValue},
          weekTotal: {
            ...this.state.weekTotal,
            [userId]: projectTotalWeek
          },
          bilableTotal: {
            ...this.state.bilableTotal,
            [userId]: bilableTotalWeek
          }
        });
      });
    } catch (error) { 
    }
  };

  getBilableCalculatedTotals = userId => {
    let params = {
      firstDate: moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
      lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
      project: this.state.confirmedProjFilterValue,
      task: this.state.confirmedTaskFilterValue,
      IsApprNotNeed: this.state.IsApprNotNeed,
      IsRejected: this.state.IsRejected,
      IsApprovalNonBillable: this.state.IsApprovalNonBillable,
      IsApprovalBillable: this.state.IsApprovalBillable,
      IsPendiingApproval: this.state.IsPendiingApproval,
      approver: null,
      selectedApprover: this.state.selectedApprover ? this.state.selectedApprover.id : null,
      userId: [userId]
    };
    getBilableCalculatedTotals(params).then(res => {
      let allData = this.state.resourceList;
      allData.filter(x => x.userId === userId)[0].monBillableTotal = res.data[0].monBillableTotal;
      allData.filter(x => x.userId === userId)[0].tueBillableTotal = res.data[0].tueBillableTotal;
      allData.filter(x => x.userId === userId)[0].wedBillableTotal = res.data[0].wedBillableTotal;
      allData.filter(x => x.userId === userId)[0].thuBillableTotal = res.data[0].thuBillableTotal;
      allData.filter(x => x.userId === userId)[0].friBillableTotal = res.data[0].friBillableTotal;
      allData.filter(x => x.userId === userId)[0].satBillableTotal = res.data[0].satBillableTotal;
      allData.filter(x => x.userId === userId)[0].sunBillableTotal = res.data[0].sunBillableTotal;
      this.setState(
        {
          resourceList: allData
        },
        () => {
          this.getTimeCards(userId);
        }
      );
    });
  };

  getTimeCards = userId => {
    let alldata = this.state.resourceList;
    let objIndex = alldata.findIndex(obj => obj.userId === userId);
    let data = alldata[objIndex].tasks;
    let IsSelected = alldata[objIndex].selected;
    data.forEach(async task => {
      let nonProjAllocations = [];
      let projAllcations = [];
      if (task.taskType == 'NPT') {
        nonProjAllocations = task.allocations;
      }
      if (task.taskType == 'PT' || task.taskType == 'CRT') {
        projAllcations = task.allocations;
      }
      const params = {
        firstDate: moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
        lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
        projAllcations: projAllcations,
        nonProjAllocations: nonProjAllocations,
        IsApprNotNeed: this.state.ConfirmeIsApprNotNeed,
        IsRejected: this.state.ConfirmeIsRejected,
        IsApprovalNonBillable: this.state.ConfirmeIsApprovalNonBillable,
        IsApprovalBillable: this.state.ConfirmeIsApprovalBillable,
        IsPendiingApproval: this.state.ConfirmeIsPendiingApproval
      };

      await getTimeCards(params).then(res => {
        if (res.data.length !== 0) {
          let cardWithoutAutoGenarated = res.data.filter(
            x => x.ApprovedStatus !== approvedStatus.Auto_Generated.value
          );
          alldata[objIndex].noOfCards =
            alldata[objIndex].noOfCards + cardWithoutAutoGenarated.length;
          res.data.forEach(timeEntry => {
            const day = moment(timeEntry.LogDateTime);
            let durationParts = timeEntry.Duration.split('.');
            let duration = durationParts[0];
            if (typeof durationParts[1] !== 'undefined') {
              duration =
                parseInt(duration) +
                ':' +
                Math.round(parseFloat(`0.${durationParts[1]}`) * 60)
                  .toString()
                  .padStart(2, '0');
            } else {
              duration = parseInt(duration) + ':' + '00';
            }
            const card = {
              projOrGroupId:
                timeEntry.ProjectTaskAllocation === null
                  ? timeEntry.NonProjectTaskAllocation.NonProjectTask.TaskGroupId
                  : timeEntry.ProjectTaskAllocation.ProjectTask.ProjectId,
              IsApproverNeeded:
                timeEntry.ProjectTaskAllocation === null
                  ? timeEntry.NonProjectTaskAllocation.NonProjectTask.IsApproverNeeded
                  : false,
              CrId:
                timeEntry.ProjectTaskAllocation !== null
                  ? timeEntry.ProjectTaskAllocation.ProjectTask.CRId !== null
                    ? timeEntry.ProjectTaskAllocation.ProjectTask.CRId
                    : null
                  : null,
              cardId: timeEntry.id,
              IsBillable:
                timeEntry.ProjectTaskAllocation !== null
                  ? timeEntry.ProjectTaskAllocation.ProjectTask.BillabilityTypeId ===
                      'NONBILLABLE' ||
                    timeEntry.ProjectTaskAllocation.ProjectTask.IsPresales === true
                    ? false
                    : true
                  : false,
              RateType:
                timeEntry.ProjectTaskAllocation !== null
                  ? timeEntry.ProjectTaskAllocation.ResourceRequest.RateType
                  : null,
              userId:
                timeEntry.ProjectTaskAllocation === null
                  ? timeEntry.NonProjectTaskAllocation.UserId
                  : timeEntry.ProjectTaskAllocation.ResourceId,
              checked:
                timeEntry.ApprovedStatus === approvedStatus.Auto_Generated.value
                  ? false
                  : IsSelected,
              comment: timeEntry.Comment,
              TicketId: timeEntry.TicketId,
              duration: duration,
              endTime: timeEntry.ToDateTime,
              startTime: timeEntry.FromDateTime,
              approvedStatus: timeEntry.ApprovedStatus,
              ApprovedNonBillingCatagoryId: timeEntry.ApprovedNonBillingCatagoryId,
              ApproveFreezeStatus: timeEntry.ApproveFreezeStatus,
              ApprovedBy: timeEntry.ApprovedBy,
              EntryFreezeStatus: timeEntry.EntryFreezeStatus,
              FromDateTime: timeEntry.FromDateTime,
              ToDateTime: timeEntry.ToDateTime,
              RejectReason: timeEntry.RejectReason,
              ResonOfMove: timeEntry.ResonOfMove,
              LogDateTime: timeEntry.LogDateTime,
              project: task.Project,
              task: task.Task,
              taskId: task.taskId,
              taskType: task.taskType,
              isShiftTask: task.isShiftTask,
              IsOverEightHour: false,
              allocationId:
                timeEntry.ProjectTaskAllocationId !== null
                  ? timeEntry.ProjectTaskAllocationId
                  : timeEntry.NonProjectTaskAllocationId
            };
            switch (day.day()) {
              case 0:
                {
                  if (alldata[objIndex].sunBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Sun.cards.push(card);
                }
                break;
              case 1:
                {
                  if (alldata[objIndex].monBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Mon.cards.push(card);
                }
                break;
              case 2:
                {
                  if (alldata[objIndex].tueBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Tue.cards.push(card);
                }
                break;
              case 3:
                {
                  if (alldata[objIndex].wedBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Wed.cards.push(card);
                }
                break;
              case 4:
                {
                  if (alldata[objIndex].thuBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Thu.cards.push(card);
                }
                break;
              case 5:
                {
                  if (alldata[objIndex].friBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Fri.cards.push(card);
                }
                break;
              case 6:
                {
                  if (alldata[objIndex].satBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Sat.cards.push(card);
                }
                break;
              default:
                break;
            }
          });
        }
      });
      this.setState({
        resourceList: alldata
      });
    });
  };

  prevWeek = () => {
    const date = this.state.newDate;
    const newDate = new Date();
    const prevWeek = new Date(
      newDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() - 7)
    );
    this.setState(
      {
        newDate: prevWeek,
        resourceList: []
      },
      () => this.setWeekDates(prevWeek)
    );
  };

  nextWeek = () => {
    const date = this.state.newDate;
    const newDate = new Date();
    const nextWeek = new Date(
      newDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 7)
    );
    this.setState(
      {
        newDate: nextWeek,
        resourceList: []
      },
      () => this.setWeekDates(nextWeek)
    );
  };

  setWeekDates = (date, fromInitialRender = false) => {
    if (date !== null) {
      this.setState({
        fromInitialRender,
        newDate: date,
        isActiveEdit: false,
        monDayTotal: 0,
        tueDayTotal: 0,
        wedDayTotal: 0,
        thrDayTotal: 0,
        friDayTotal: 0,
        satDayTotal: 0,
        sunDayTotal: 0,
        totalHourOfWeek: 0
      });
      this.datesHandler(date);
    }
  };

  datesHandler = date => {
    if (moment(date).day() !== 0) {
      const FirstdayOfWeek = new Date();
      const FirstMonOfWeek = new Date();
      const LastDayOfWeek = new Date();
      const firstday = new Date(
        FirstdayOfWeek.setFullYear(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - date.getDay()
        )
      );
      this.setState(
        {
          firstDayOfWeek: new Date(
            FirstMonOfWeek.setFullYear(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() - date.getDay() + 1
            )
          ),
          LastDayOfWeek: new Date(
            LastDayOfWeek.setFullYear(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() - date.getDay() + 7
            )
          ),
          dateOfSun: new Date(moment(firstday).add(7, 'days')),
          dateOfMon: new Date(moment(firstday).add(1, 'days')),
          dateOfTue: new Date(moment(firstday).add(2, 'days')),
          dateOfWed: new Date(moment(firstday).add(3, 'days')),
          dateOfTur: new Date(moment(firstday).add(4, 'days')),
          dateOfFri: new Date(moment(firstday).add(5, 'days')),
          dateOfSat: new Date(moment(firstday).add(6, 'days'))
        },
        () => {
          if(!this.state.fromInitialRender){
            return this.runFilterQuery();
          } 
          this.setState({loader: false});
        }
      );
    } else {
      const firstday = new Date(moment(date).subtract(6, 'days'));
      this.setState(
        {
          firstDayOfWeek: new Date(moment(date).subtract(6, 'days')),
          LastDayOfWeek: new Date(moment(date)),
          dateOfSun: new Date(moment(firstday).add(6, 'days')),
          dateOfMon: new Date(moment(firstday)),
          dateOfTue: new Date(moment(firstday).add(1, 'days')),
          dateOfWed: new Date(moment(firstday).add(2, 'days')),
          dateOfTur: new Date(moment(firstday).add(3, 'days')),
          dateOfFri: new Date(moment(firstday).add(4, 'days')),
          dateOfSat: new Date(moment(firstday).add(5, 'days'))
        },
        () => {
          this.setState({loader: true}, () => {
            if(!this.state.fromInitialRender){
              this.getResourceList();
            } else {
              this.setState({loader: false});
            }
          })
        }
      );
    }
  };

  getResourceList = async () => {
    try {
      const {data} = await listOfResource({
        project: this.state.selectedProject.length > 0 ? this.state.selectedProject.map(value => value) : [],
        projectRegion: this.state.selectedProjectRegion.length > 0 ? this.state.selectedProjectRegion.map(value => value.id) : [],
        projectPractice: this.state.selectedProjectPractice.length > 0 ? this.state.selectedProjectPractice.map(value => value.id) : [],
        task: this.state.selectedTask.length > 0 ?  this.state.selectedTask.map(e => e.Name) : [],
        changeRequests: this.state.selectedCR.length > 0 ? this.state.selectedCR.map( e => e.id) : [],
        resourceId: this.state.selectedResource.length > 0 ?  this.state.selectedResource.map(value => value.id) : [],
        resourceRegion: this.state.selectedResourceRegion.length > 0 ?  this.state.selectedResourceRegion.map(value => value.id) : [],
        resourceDepartment: this.state.selectedResourceDepartment.length > 0 ?  this.state.selectedResourceDepartment.map(value => value.id) : [],
        resourcePractice: this.state.selectedResourcePractice.length > 0 ?  this.state.selectedResourcePractice.map(value => value.id) : [],
        approver: this.state.selectedApprover.length > 0 ?  this.state.selectedApprover.map(value => value.id) : [],
        LineManagerId: this.state.selectedLineManger.length > 0 ?  this.state.selectedLineManger.map(value => value.id) : [],
        logDateFrom: moment(this.state.firstDayOfWeek || new Date()).startOf('isoWeek').format('YYYY-MM-DD'),
        logDateTo: moment(this.state.LastDayOfWeek || new Date()).endOf('isoWeek').format('YYYY-MM-DD'),
        IsRejected: this.state.IsRejected,
        IsApprovalNonBillable: this.state.IsApprovalNonBillable,
        IsApprovalBillable: this.state.IsApprovalBillable,
        IsPendiingApproval: this.state.IsPendiingApproval,
        IsApprovalNotNeeded: this.state.IsApprovalNotNeeded,
        pmUserRole: this.state.pmUserRole
      });
      await this.expandedPanelQuery();
      this.setState({
        resourceList: data,
        loader: false
      });
      return Promise.resolve();
    } catch (error) {
      this.setState({loader: false});
      return Promise.reject(error);
    }
  };

  roundUp =(num, precision)=> {
    num = parseFloat(`0.${num}`) * 60;
    num = num.toFixed(2) /10
   precision = Math.pow(10, precision)
   return (Math.ceil(num * precision) / precision)*10
 }


  setTotalTime = (userId, day = null) => {
    const userWeekSum = this.state.weekTotal[userId];
    if(!userWeekSum || !day || !Array.isArray(userWeekSum)){
      return <td>{0}</td>;
    }
    const filteredDay = userWeekSum.filter(e => e.day === day);
    if(filteredDay.length !== 1){
      return <td>{0}</td>;
    }
    const time = filteredDay[0].sum;
    let durationParts = time.toString().split('.');
    let duration = durationParts[0];
    let durMinPart = this.roundUp(durationParts[1],1)
    .toString()
    .padStart(2, '0');
    if (typeof durationParts[1] !== 'undefined') {
      if(parseInt(durMinPart) === 60){
        duration = parseInt(duration) + 1;
        durMinPart = '00';
      }else{
        durMinPart = this.roundUp(durationParts[1],1).toString().padStart(2, '0');;
      }
      duration =
        parseInt(duration) +
        ':' +
        durMinPart;
      return <td>{duration}</td>;
    } else {
      duration = parseInt(duration) + ':' + '00';
      return <td>{duration}</td>;
    }
  };
  setTotalOfWeek = times => {
    let durationParts = times.toString().split('.');
    let duration = durationParts[0];
    let durMinPart = this.roundUp(durationParts[1],1)
    .toString()
    .padStart(2, '0');
    if (typeof durationParts[1] !== 'undefined') {
      if(parseInt(durMinPart) === 60){
        duration = parseInt(duration) + 1;
        durMinPart = '00';
      }else{
        durMinPart = this.roundUp(durationParts[1],1).toString().padStart(2, '0');;
      }
      duration =
        parseInt(duration) +
        ':' +
        durMinPart;
      return duration;
    } else {
      return (duration = parseInt(duration));
    }
  };

  TotalHoursTimeEnt = props => {
    let total = this.state.list.reduce((acc, current) => acc + current.Hours, 0);
    return <td colSpan={this.props.colSpan}>{total} hours</td>;
  };
  customCell = props => {
    // return <td>{'test'}</td>;
    const data = props.dataItem[props.field];
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const checkCellColor = (taskStartDate, taskEndDate, allocation, day) => {
      switch (day) {
        case 'Mon':
          {
            let currentDate = moment(this.state.dateOfMon).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              return { borderTop: '1px solid #ffff' };
            }
          }
          break;
        case 'Tue':
          {
            let currentDate = moment(this.state.dateOfTue).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              return { borderTop: '1px solid #ffff' };
            }
          }
          break;
        case 'Wed':
          {
            let currentDate = moment(this.state.dateOfWed).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              return { borderTop: '1px solid #ffff' };
            }
          }
          break;
        case 'Thu':
          {
            let currentDate = moment(this.state.dateOfTur).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              return { borderTop: '1px solid #ffff' };
            }
          }
          break;
        case 'Fri':
          {
            let currentDate = moment(this.state.dateOfFri).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              return { borderTop: '1px solid #ffff' };
            }
          }
          break;
        case 'Sat':
          {
            let currentDate = moment(this.state.dateOfSat).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              return { borderTop: '1px solid #ffff' };
            }
          }
          break;
        case 'Sun':
          {
            let currentDate = moment(this.state.dateOfSun).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              return { borderTop: '1px solid #ffff' };
            }
          }
          break;
      }
    };

    return (
      <td
        style={checkCellColor(
           props.dataItem.TaskStartDate,
          props.dataItem.TaskEndDate,
          props.dataItem[props.field].ProjectTaskAllocationId || props.dataItem[props.field].NonProjectTaskAllocationId,
          props.field
        )}
      >
        {data.length !== 0 &&
          data.map((card, index) => (
            <Card
              cardData={card}
              key={index}
              checked={card.checked}
              checkeBoxHandler={this.checkeBoxClickHandler}
              index={index}
              approvedStatus={card.ApprovedStatus}
              taskId={props.dataItem.taskId}
              data={props.dataItem}
              date={props.field}
              pmUserRole={this.state.pmUserRole}
            />
          ))}
      </td>
    );
  };
  handleChange = e => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState(prevState => {
      let { list, allChecked } = prevState;
      if (itemName === 'checkAll') {
        allChecked = checked;
        list = list.map(item => ({ ...item, isChecked: checked }));
      } else {
        list = list.map(item => (item.name === itemName ? { ...item, isChecked: checked } : item));
        allChecked = list.every(item => item.isChecked);
      }
      return { list, allChecked };
    });
  };

  renderList = () => {
    return this.state.list.map(item => (
      <div>
        <div className="card">
          <div className="col-md-12">
            <div class="timesheet-red row">
              <input
                key={item.id}
                type="checkbox"
                checked={item.isChecked}
                onChange={this.handleChange}
                className="k-checkbox mr-2"
              />
              <span
                htmlFor="ts-activity"
                className="ts-act d-inline-block"
                onClick={this.toggleWindowDialog}
              >
                <span className=" d-inline-block my-auto text-truncate ta-desc">
                  {item.Hours} - {item.Description}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  toggleTaskDetailTooltip = () => {
    if (this.isMount) {
      this.setState({
        taskdetailtooltipopen: !this.state.taskdetailtooltipopen
      });
    }
  };

  toggleRejectDialog() {
    this.setState({
      rejectTa: !this.state.rejectTa
    });
  }

  toggleUnfreezeDialog() {
    this.setState({
      unfreezeTa: !this.state.unfreezeTa
    });
  }

  toggleWindowDialog() {
    this.setState({
      windowDialogVisible: !this.state.windowDialogVisible
    });
  }

  radioViewHandler = status => {
    this.setState({ status });
  };

  toggleNonbillDialog = () => {
    this.setState({
      nonbilldialog: !this.state.nonbilldialog
    });
  };

  getInitialData = async () => {
    try {
      const [region, billingRegion, division] = await Promise.all([getResourceRegions(), getRegions(), getBillingDivi()]);
      await this.setState({
        region: region.data,
        billingRegion: billingRegion.data,
        practice: division.data
      });
      await this.getAllPracticeDivisionMappings();
      await this.getAllPractice();
      await this.getSavedFilterViews(); 
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    this.setHeaderTitle();
    this.setWeekDates(new Date(), true);
    this.checkUserRole();
    this.getInitialData();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  checkUserRole = async () => {
    const userRoles = window.LOGGED_USER.roles;
    const pmUserRole = userRoles.some(obj => obj.Rolename === 'PM' || obj.Rolename === 'PMO' || obj.Rolename === 'Finance Manager' || obj.Rolename === 'RP' || obj.Rolename === 'System Admin' );
    await this.setState({
      pmUserRole: pmUserRole
    });
  }

  getAllPractice = async () => {
    await getPracticeTypesIntegrated() 
      .then(async res => {
        await this.setState({
          practiceDataAll: res.data,
          practicesData: res.data,
          practiceDataForFilter: res.data
        });
        await this.practiceDivisionMapping();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getAllPracticeDivisionMappings = async() => {
    await getAllPracticeDivisionMapIntegrated()
      .then(response => {
        this.setState({
            practiceDivisionMap: response.data
        });
    })
    .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  practiceDivisionMapping = async () => {
    if (this.state.selectedResourceDepartment && this.state.selectedResourceDepartment.length > 0) {
        let practices = [];
        for (const department of this.state.selectedResourceDepartment) {
          let practicesForDivision = this.state.practiceDivisionMap.filter(item => item.DepartmentCodeSF === department.SFCode);
          for (const practice of practicesForDivision) {
            let selectedPractice = this.state.practiceDataAll.filter(item => item.SFCode === practice.PracticeCodeSF);
            practices.push(selectedPractice[0]);
          }
        }
        const uniquePractices = [...new Set(practices)];
        await this.setState({ 
          practicesData: uniquePractices, 
          practiceDataForFilter: uniquePractices 
        })
    } else {
      await this.setState({ 
        practicesData: this.state.practiceDataAll,
        practiceDataForFilter: this.state.practiceDataAll 
      })
    }
  };

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('View All Timesheets');
    }
  };

  headerSelectionChange = event => {
    const checked = event.syntheticEvent.target.checked;
    this.state.Workflows.forEach(item => (item.Selected = checked));
    this.forceUpdate();
  };

  handleSelect = e => {
    this.setState({ selected: e.selected });
  };

  selectionChange = event => {
    event.dataItem.Selected = !event.dataItem.Selected;
    this.forceUpdate();
  };

  rowClick = event => {
    let last = this.lastSelectedIndex;
    const data = [...this.state.data];
    const current = data.findIndex(dataItem => dataItem === event.dataItem);

    if (!event.nativeEvent.shiftKey) {
      this.lastSelectedIndex = last = current;
    }

    if (!event.nativeEvent.ctrlKey) {
      data.forEach(item => (item.selected = false));
    }
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      data[i].selected = select;
    }
    this.setState({ data });
  };

  filledArray = (size, item) => {
    const loadingData = [];
      while (loadingData.length < size) {
      loadingData.push({ ...item });
    }
    return loadingData;
  }

  filterChange = (event) => {
    const field = event.target.name.split('_')[1];
    this.setState({
      [event.target.name]: filterBy(this.state[field].slice(), event.filter)
    });
  };

  filterChangeResourcePractice = (event) => {
    this.setState({
      practicesData: filterBy(this.state.practiceDataForFilter.slice(), event.filter)
    });
  };

  filterOnChange = (e, field) => {
    this.setState({[field]: e});
  }

  exportToExcel = async () => {
    try {
      this.setState({loader: true, downloadExcel: false}, async () => {
        const {data} = await getExcelData({
          project: this.state.selectedProject.length > 0 ? this.state.selectedProject.map(value => value) : [],
          projectRegion: this.state.selectedProjectRegion.length > 0 ? this.state.selectedProjectRegion.map(value => value.id) : [],
          projectPractice: this.state.selectedProjectPractice.length > 0 ? this.state.selectedProjectPractice.map(value => value.id) : [],
          task: this.state.selectedTask.length > 0 ?  this.state.selectedTask.map(e => e.Name) : [],
          changeRequests: this.state.selectedCR.length > 0 ? this.state.selectedCR.map( e => e.id) : [],
          resourceId: window.USER_PERMISSION.indexOf('VW_TM_ST_ALL') > -1 || window.USER_PERMISSION.indexOf('VW_TM_ST_FIN') > -1 ? this.state.selectedResource.length > 0 ?  this.state.selectedResource.map(value => value.id) : [] :
                      [window.LOGGED_USER.user],
          resourceRegion: this.state.selectedResourceRegion.length > 0 ?  this.state.selectedResourceRegion.map(value => value.id) : [],
          resourceDepartment: this.state.selectedResourceDepartment.length > 0 ?  this.state.selectedResourceDepartment.map(value => value.id) : [],
          resourcePractice: this.state.selectedResourcePractice.length > 0 ?  this.state.selectedResourcePractice.map(value => value.id) : [],
          approver: this.state.selectedApprover.length > 0 ? this.state.selectedApprover.map(value => value.id) : [],
          selectedApprover: this.state.selectedApprover.length > 0 ? this.state.selectedApprover.map(value => value.id) : [],
          LineManagerId: this.state.selectedLineManger.length > 0 ? this.state.selectedLineManger.map(value => value.id) : [],
          logDateFrom: moment(this.state.logDateFrom).format('YYYY-MM-DD'),
          logDateTo: moment(this.state.logDateTo).format('YYYY-MM-DD'),
          IsRejected: this.state.IsRejected,
          IsApprovalNonBillable: this.state.IsApprovalNonBillable,
          IsApprovalBillable: this.state.IsApprovalBillable,
          IsPendiingApproval: this.state.IsPendiingApproval,
          IsApprovalNotNeeded: this.state.IsApprovalNotNeeded
        });
        this._export.save(data);
        this.setState({loader: false});
      })
    } catch (error) {
      this.setState({loader: false});
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  }

  clearFilters = () => {
    this.setState({
      selectedResourceRegion: [],
      selectedResourceDepartment: [],
      selectedResourcePractice: [],
      selectedProject: [],
      selectedCR: [],
      selectedTask: [],
      selectedResource: [],
      selectedLineManger: [],
      selectedApprover: [],
      selectedProjectRegion: [],
      selectedProjectPractice: [],
      project_practice: null,
      resource_practice: null,
      IsRejected: false,
      IsApprovalNonBillable: false,
      IsApprovalBillable: false,
      IsPendiingApproval: false,
      IsApprovalNotNeeded: false,
      entries: [],
      weekTotal: [],
      bilableTotal: [],
      expandedPanels: new Set(),
      resourceList: [],
      billableHours: 0,
      nonBillableHours: 0,
      pendingHours: 0,
      rejectedHours: 0,
      enterdHours: 0,
      filterViewId: null
    })
  }

  selectedProjectValue = (proj, additionalKey = 'proId', typeKey = 'type') => {
    if(!proj){
      return null;
    }
    const type = proj[typeKey];
    switch(type){
      case 'Project': return {
        ...proj,
        [typeKey]: 'project',
        [additionalKey]: proj.id
      }
      case 'ChangeRequest': return {
        ...proj,
        [typeKey]: 'cr',
        [additionalKey]: proj.id
      }
      default: return {
        ...proj,
        [typeKey]: 'NPT',
        [additionalKey]: proj.id
      }
    }
  }

  runStaticstics = async () => {
    try {
      const {data} = await getStatistics({
        project: this.state.selectedProject.length > 0 ? this.state.selectedProject.map(value => value) : [],
        projectRegion: this.state.selectedProjectRegion.length > 0 ? this.state.selectedProjectRegion.map(value => value.id) : [],
        projectPractice: this.state.selectedProjectPractice.length > 0 ? this.state.selectedProjectPractice.map(value => value.id) : [],
        task: this.state.selectedTask.length > 0 ?  this.state.selectedTask.map(e => e.Name) : [],
        changeRequests: this.state.selectedCR.length > 0 ? this.state.selectedCR.map( e => e.id) : [],
        resourceId: this.state.selectedResource.length > 0 ?  this.state.selectedResource.map(value => value.id) : [],
        resourceRegion: this.state.selectedResourceRegion.length > 0 ?  this.state.selectedResourceRegion.map(value => value.id) : [],
        resourceDepartment: this.state.selectedResourceDepartment.length > 0 ?  this.state.selectedResourceDepartment.map(value => value.id) : [],
        resourcePractice: this.state.selectedResourcePractice.length > 0 ?  this.state.selectedResourcePractice.map(value => value.id) : [],
        approver: this.state.selectedApprover.length > 0 ?  this.state.selectedApprover.map(value => value.id) : [],
        LineManagerId: this.state.selectedLineManger.length > 0 ?  this.state.selectedLineManger.map(value => value.id) : [],
        logDateFrom: moment(this.state.firstDayOfWeek || new Date()).startOf('isoWeek').format('YYYY-MM-DD'),
        logDateTo: moment(this.state.LastDayOfWeek || new Date()).endOf('isoWeek').format('YYYY-MM-DD'),
        IsRejected: this.state.IsRejected,
        IsApprovalNonBillable: this.state.IsApprovalNonBillable,
        IsApprovalBillable: this.state.IsApprovalBillable,
        IsPendiingApproval: this.state.IsPendiingApproval,
        IsApprovalNotNeeded: this.state.IsApprovalNotNeeded,
        pmUserRole: this.state.pmUserRole
      });
      const {enterdHours, billableHours, nonBillableHours, pendingHours, rejectedHours, nonBillableGroup} = data;
      this.setState({enterdHours, billableHours, nonBillableHours, pendingHours, rejectedHours, nonBillableGroup});
      return Promise.resolve();
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
      return Promise.reject(error);
    }
  }

  expandedPanelQuery = async () => {
    try {
      const expaned = Array.from(this.state.expandedPanels);
      if(expaned.length > 0){
        const expandedTasks = await expaned.map(async e => {
          const {data} = await resourceEntries({
            project: this.state.selectedProject.length > 0 ? this.state.selectedProject.map(value => value) : [],
            projectRegion: this.state.selectedProjectRegion.length > 0 ? this.state.selectedProjectRegion.map(value => value.id) : [],
            projectPractice: this.state.selectedProjectPractice.length > 0 ? this.state.selectedProjectPractice.map(value => value.id) : [],
            task: this.state.selectedTask.length > 0 ?  this.state.selectedTask.map(e => e.Name) : [],
            changeRequests: this.state.selectedCR.length > 0 ? this.state.selectedCR.map( e => e.id) : [],
            resourceId: [e],
            resourceRegion: this.state.selectedResourceRegion.length > 0 ?  this.state.selectedResourceRegion.map(value => value.id) : [],
            resourceDepartment: this.state.selectedResourceDepartment.length > 0 ?  this.state.selectedResourceDepartment.map(value => value.id) : [],
            resourcePractice: this.state.selectedResourcePractice.length > 0 ?  this.state.selectedResourcePractice.map(value => value.id) : [],
            approver: this.state.selectedApprover.length > 0 ?  this.state.selectedApprover.map(value => value.id) : [],
            LineManagerId: this.state.selectedLineManger.length > 0 ? this.state.selectedLineManger.map(value => value.id) : [],
            logDateFrom: moment(this.state.firstDayOfWeek || new Date()).startOf('isoWeek').format('YYYY-MM-DD'),
            logDateTo: moment(this.state.LastDayOfWeek || new Date()).endOf('isoWeek').format('YYYY-MM-DD'),
            IsRejected: this.state.IsRejected,
            IsApprovalNonBillable: this.state.IsApprovalNonBillable,
            IsApprovalBillable: this.state.IsApprovalBillable,
            IsPendiingApproval: this.state.IsPendiingApproval,
            IsApprovalNotNeeded: this.state.IsApprovalNotNeeded,
            pmUserRole: this.state.pmUserRole
          });
          const {finalValue, projectTotalWeek, bilableTotalWeek} = data;
          this.setState({entries: {
            ...this.state.entries,
            [e]: finalValue},
            weekTotal: {
              ...this.state.weekTotal,
              [e]: projectTotalWeek
            },
            bilableTotal: {
              ...this.state.bilableTotal,
              [e]: bilableTotalWeek
            }
          });
        });
        await Promise.all(expandedTasks);
        return Promise.resolve();
      }
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
      return Promise.reject();
    }
  }

  runFilterQuery = async () => {
    this.setState({loader: true, filterRun: true}, async () => {
      try {
        await Promise.all([
          this.getResourceList(),
          this.runStaticstics()
        ]);
        await this.expandedPanelQuery();
        this.setState({
          loader: false
        })
      } catch (error) {
        this.setState({loader: false});
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      }
    })
  }

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleSaveFilterViewDialog = async () => {
    if (this.state.selectedProject.length > 0 || this.state.selectedProjectRegion.length > 0 || 
      this.state.selectedProjectPractice.length > 0 || this.state.selectedCR.length > 0 ||
      this.state.selectedTask.length > 0 || this.state.selectedResource.length > 0 || 
      this.state.selectedResourceRegion.length > 0 || this.state.selectedResourceDepartment.length > 0 || this.state.selectedResourcePractice.length > 0 ||
      this.state.selectedApprover.length > 0 || this.state.selectedLineManger.length > 0 || this.state.IsRejected || 
      this.state.IsApprovalNonBillable || this.state.IsApprovalBillable || this.state.IsPendiingApproval || 
      this.state.IsApprovalNotNeeded) {
      if (this.state.filterViewId) {
        const savedFilter = this.state.savedFilterViews.filter(x => x.filterId === this.state.filterViewId);
        await this.setState({
          filterViewName: savedFilter[0].filterName
        });
      } else {
        await this.setState({
          filterViewName: null
        });
      }
      await this.setState({
        showSaveFilterViewDialog: !this.state.showSaveFilterViewDialog
      });
    } else {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('Please select at least one filter criteria', 'Error');
    }
  };

  handleFilterNameInputChange = event => {
    const value = event.target.value;
    this.setState({
      filterViewName: value
    });
  };

  setSelectedFilterView = async (filterViewId) => {
    if (filterViewId === this.state.filterViewId) {
      await this.setState({
        filterViewId: null
      });
    } else {
      await this.setState({
        filterViewId: filterViewId
      });
      await this.getSearchCriteria();
    } 
  };

  getSavedFilterViews = async () => {
    await getSearchCriteria().then(async res => {
      await this.setState({
        savedFilterViews: res.data
      });
    });
  };

  getSearchCriteria = async () => {
    const savedFilterView = this.state.savedFilterViews.filter(x => x.filterId === this.state.filterViewId);
    if (savedFilterView && savedFilterView.length > 0) {

      const savedFilterCriterias = savedFilterView[0];

      let projectRegions = [];
      let projectPractices = [];
      let resourceRegions = [];
      let resourceDepartments = [];
      let resourcePractices = [];
      let selectedProjectsAndGroups = [];
      let selectedChangeRequests = [];
      let selectedTasks = [];
      let selectedResources = [];
      let selectedLineManagers = [];
      let selectedApprovers = [];

      let savedProjects = savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'project');
      if (savedProjects && savedProjects.length > 0) {
        selectedProjectsAndGroups = savedFilterCriterias.SelectedProjectsAndGroups
      }

      let savedCRs = savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'changeRequests');
      if (savedCRs && savedCRs.length > 0) {
        selectedChangeRequests = savedFilterCriterias.SelectedChangeRequests
      }

      let savedTasks = savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'task');
      if (savedTasks && savedTasks.length > 0) {
        selectedTasks = savedFilterCriterias.SelectedTasks
      }

      let savedResources = savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'resourceId');
      if (savedResources && savedResources.length > 0) {
        selectedResources = savedFilterCriterias.SelectedResources
      }

      let savedLineManagers = savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'LineManagerId');
      if (savedLineManagers && savedLineManagers.length > 0) {
        selectedLineManagers = savedFilterCriterias.SelectedLineManagers
      }

      let savedApprovers = savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'approver');
      if (savedApprovers && savedApprovers.length > 0) {
        selectedApprovers = savedFilterCriterias.SelectedApprovers
      }

      let savedProjectRegions = savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'projectRegion');
      if (savedProjectRegions && savedProjectRegions.length > 0) {
        for(let value of savedProjectRegions){
          projectRegions.push(this.state.billingRegion.find(x => value.searchValue == x.id));
        }
      }

      let savedProjectPractices = savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'projectPractice');
      if (savedProjectPractices && savedProjectPractices.length > 0) {
        for(let value of savedProjectPractices){
          projectPractices.push(this.state.practice.find(x => value.searchValue == x.id));
        }
      }
      let savedResourceRegions = savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'resourceRegion');
      if (savedResourceRegions && savedResourceRegions.length > 0) {
        for(let value of savedResourceRegions){
          resourceRegions.push(this.state.region.find(x => value.searchValue == x.id));
        }
      }

      let savedResourceDepartments = savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'resourceDepartment');
      if (savedResourceDepartments && savedResourceDepartments.length > 0) {
        for(let value of savedResourceDepartments){
          resourceDepartments.push(this.state.practice.find(x => value.searchValue == x.id));
        }
      }

      let savedResourcePractices = savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'resourcePractice');
      if (savedResourcePractices && savedResourcePractices.length > 0) {
        for(let value of savedResourcePractices){
          resourcePractices.push(this.state.practiceDataAll.find(x => value.searchValue == x.id));
        }
      }

      await this.setState(
        {
          selectedProject: selectedProjectsAndGroups,
          selectedCR: selectedChangeRequests,
          selectedTask: selectedTasks,
          selectedResource: selectedResources,
          selectedApprover: selectedApprovers,
          selectedLineManger: selectedLineManagers,
          selectedProjectRegion: projectRegions,
          selectedProjectPractice: projectPractices,
          selectedResourceRegion: resourceRegions,
          selectedResourceDepartment: resourceDepartments,
          selectedResourcePractice: resourcePractices,
          IsPendiingApproval:
            savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'IsPendiingApproval')[0].searchValue ===
            'true'
              ? true
              : false,
          IsApprovalBillable:
            savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'IsApprovalBillable')[0].searchValue ===
            'true'
              ? true
              : false,
          IsApprovalNonBillable:
            savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'IsApprovalNonBillable')[0].searchValue ===
            'true'
              ? true
              : false,
          IsRejected:
            savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'IsRejected')[0].searchValue === 'true'
              ? true
              : false,
          IsApprovalNotNeeded:
            savedFilterCriterias.SearchCriteria.filter(x => x.searchCriteria === 'IsApprovalNotNeeded')[0].searchValue === 'true'
              ? true
              : false
            
        },
        async () => {
          // await this.runFilterQuery();
        });
    }
  };

  saveSearchQuery = async () => {
    try {
      if (this.state.savedFilterViews && this.state.savedFilterViews.length >= 5 && !this.state.filterViewId) {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleSaveFilterViewDialog();
        this.toggleDialog('Only 5 filter views can be saved. Replace an existing view if required.', 'Error');
        return;
      }
      if (this.state.selectedProject.length > 0 || this.state.selectedProjectRegion.length > 0 || 
        this.state.selectedProjectPractice.length > 0 || this.state.selectedCR.length > 0 ||
        this.state.selectedTask.length > 0 || this.state.selectedResource.length > 0 || 
        this.state.selectedResourceRegion.length > 0 || this.state.selectedResourceDepartment.length > 0 || this.state.selectedResourcePractice.length > 0 || 
        this.state.selectedApprover.length > 0 || this.state.selectedLineManger.length > 0 || this.state.IsRejected || 
        this.state.IsApprovalNonBillable || this.state.IsApprovalBillable || this.state.IsPendiingApproval || 
        this.state.IsApprovalNotNeeded) {
          await saveSearchCriteria({
            filterViewId: this.state.filterViewId,
            filterViewName: this.state.filterViewName,
            project: this.state.selectedProject ? this.state.selectedProject.map(value => value.uniqueName) : null,
            projectRegion: this.state.selectedProjectRegion ? this.state.selectedProjectRegion.map(value => value.id) : null,
            projectPractice: this.state.selectedProjectPractice ? this.state.selectedProjectPractice.map(value => value.id) : null,
            changeRequests: this.state.selectedCR.length > 0 ? this.state.selectedCR.map(value => value.id) : null,
            task: this.state.selectedTask.length > 0 ? this.state.selectedTask.map(value => value.uniqueName) : null,
            resourceId: this.state.selectedResource ? this.state.selectedResource.map(value => value.id) : null,
            resourceRegion: this.state.selectedResourceRegion ? this.state.selectedResourceRegion.map(value => value.id) : null,
            resourceDepartment: this.state.selectedResourceDepartment ? this.state.selectedResourceDepartment.map(value => value.id) : null,
            resourcePractice: this.state.selectedResourcePractice.length > 0 ?  this.state.selectedResourcePractice.map(value => value.id) : [],
            approver: this.state.selectedApprover ? this.state.selectedApprover.map(value => value.id) : null,
            LineManagerId: this.state.selectedLineManger ? this.state.selectedLineManger.map(value => value.id) : null,
            IsRejected: this.state.IsRejected,
            IsApprovalNonBillable: this.state.IsApprovalNonBillable,
            IsApprovalBillable: this.state.IsApprovalBillable,
            IsPendiingApproval: this.state.IsPendiingApproval,
            IsApprovalNotNeeded: this.state.IsApprovalNotNeeded
          }).then(() => {
            this.setState({
              showSuccessImage: true,
              showErrorImage: false
            });
            this.toggleSaveFilterViewDialog();
            this.toggleDialog('Filter View saved successfully.', 'Success');
            this.getSavedFilterViews();
          });
      } else {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleSaveFilterViewDialog();
        this.toggleDialog('Please select at least one filter criteria', 'Error');
      }
      
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  }

  handleOnChangeResourceDepartment = async (e) => {
    await this.setState({selectedResourceDepartment: e.target.value});
    await this.practiceDivisionMapping();
  };

  render() {
    const { status } = this.state;

    return (
      <div className="main-card">
        <Loader loading={this.state.loader} />
        {this.state.clientExcel === false ? (
          <ExcelExport
          ref={(exporter) => {
            this._export = exporter;
          }}
          fileName={'AllTimeSheetView'}
        >
          <ExcelExportColumn field="Date" title="Date" locked={true}/>
          <ExcelExportColumn field="ProjectName" title="Project/ Non Project Task Group  Name " locked={true}/>
          {window.USER_PERMISSION.indexOf('VW_TM_ST_FIN') > -1 && (
            <ExcelExportColumn field="ProjectRegion" title="Project Region"/>
          )}
          {window.USER_PERMISSION.indexOf('VW_TM_ST_FIN') > -1 && (
            <ExcelExportColumn field="CustomerName" title="Customer"/>
          )}
          <ExcelExportColumn field="OPID" title="Project ID" locked={true}/>
          <ExcelExportColumn field="Task" title="Task (Project/ Non-Project)"/>
          <ExcelExportColumn field="Type" title="Task Type"/>
          <ExcelExportColumn field="Resource" title="Resource Name"/>
          <ExcelExportColumn field="projectRole" title="Project Role"/>
          {window.USER_PERMISSION.indexOf('VW_TM_ST_FIN') > -1 && (
            <ExcelExportColumn field="CostDesignation" title="Cost Designation"/>
          )}
          <ExcelExportColumn field="Duration" title={this.state.pmUserRole ? "Resource Entered Duration": 'Duration'} cellOptions={{ format:"0.00;-0.00"}}/> 
          {this.state.pmUserRole && (
            <ExcelExportColumn field="ApprovedDuration" title="Approved Duration" cellOptions={{ format:"0.00;-0.00"}}/> 
          )} 
          <ExcelExportColumn field="TimeEntered" title="Time Entered Date"/>
          <ExcelExportColumn field="Status" title="Approval Status"/>
          {this.state.pmUserRole && (
            <ExcelExportColumn field="BillingRate" title="Hourly Rate"/>
          )}
          {this.state.pmUserRole && (
            <ExcelExportColumn field="ProjectCurrency" title="Project Currency"/>
          )}
          <ExcelExportColumn field="WorkLocation" title="Work Location"/>
          {this.state.pmUserRole ?
            <ExcelExportColumn field="ApprovedTicketId" title="Ticket ID"/> : 
            <ExcelExportColumn field="TicketId" title="Ticket ID"/>
          }
          {this.state.pmUserRole ?
            <ExcelExportColumn field="ApprovedComment" title="Time Entry Comments"/> : 
            <ExcelExportColumn field="Comment" title="Time Entry Comments"/>
          }
          <ExcelExportColumn field="Approver" title="Approver"/>
          <ExcelExportColumn field="ApprovedBy" title="ApprovedBy"/>
          <ExcelExportColumn field="LastApprovedTime" title="LastApprovedTime"/>
          <ExcelExportColumn field="RejectReason" title="Reject Comment"/>
          <ExcelExportColumn field="ResonOfMove" title="Move Comment"/>
        </ExcelExport> 
        ) : (
        <ExcelExport
          ref={(exporter) => {
            this._export = exporter;
          }}
          fileName={'AllTimeSheetView'}
        >
          <ExcelExportColumn field="Date" title="Date" locked={true}/>
          <ExcelExportColumn field="ProjectName" title="Project/ Non Project Task Group  Name " locked={true}/>
          <ExcelExportColumn field="projectRole" title="Project Role"/>
          <ExcelExportColumn field="Resource" title="Resource Name"/>
          <ExcelExportColumn field="ApprovedDuration" title="Duration" cellOptions={{ format:"0.00;-0.00"}}/> 
          <ExcelExportColumn field="BillingRate" title="Hourly Rate"/>
          <ExcelExportColumn field="ProjectCurrency" title="Project Currency"/>
          <ExcelExportColumn field="ApprovedComment" title="Time Entry Comments"/>
        </ExcelExport>   
        )}
          
        <div className="row">
          <div className="col-md-12">
            <PanelBar className="filter-panel">
              <PanelBarItem
                title={<div className="filter-timesheet">View All Timesheets</div>}
                expanded={true}
                animation={true}
              >
                <div className="row mt-2">
                  <div className="col-md-2">
                    <form className="k-form modified">
                      <div className="k-form-field">
                        <div className="d-block">
                          <label>Status</label>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="d-block mb-2">
                              <input
                                type="checkbox"
                                id="pastatus"
                                className="k-checkbox"
                                name="pending approvals"
                                checked={this.state.IsPendiingApproval}
                                onClick={() => this.setState({IsPendiingApproval : !this.state.IsPendiingApproval})}
                              />
                              <label
                                className="k-checkbox-label mr-3 font-weight-bold"
                                htmlFor="pastatus"
                              >
                                Pending Approvals
                              </label>
                            </div>

                            <div className="d-block mb-2">
                              <input
                                type="checkbox"
                                id="appbill"
                                className="k-checkbox"
                                name="approved-b"
                                checked={this.state.IsApprovalBillable}
                                onClick={() => this.setState({IsApprovalBillable : !this.state.IsApprovalBillable})}
                              />
                              <label
                                className="k-checkbox-label mr-3 font-weight-bold"
                                htmlFor="appbill"
                              >
                                Approved - Billable
                              </label>
                            </div>

                            <div className="d-block mb-2">
                              <input
                                type="checkbox"
                                id="appnbill"
                                className="k-checkbox"
                                name="approved-n-b"
                                checked={this.state.IsApprovalNonBillable}
                                onClick={() => this.setState({IsApprovalNonBillable : !this.state.IsApprovalNonBillable})}
                              />
                              <label
                                className="k-checkbox-label mr-3 font-weight-bold"
                                htmlFor="appnbill"
                              >
                                Approved - Non-Billable
                              </label>
                            </div>
                            <div className="d-block mb-2">
                              <input
                                type="checkbox"
                                id="rejected"
                                className="k-checkbox"
                                name="rejected"
                                checked={this.state.IsRejected}
                                onClick={() => this.setState({IsRejected : !this.state.IsRejected})}
                              />
                              <label
                                className="k-checkbox-label mr-3 font-weight-bold"
                                htmlFor="rejected"
                              >
                                Rejected
                              </label>
                            </div>
                            <div className="d-block mb-2">
                              <input
                                type="checkbox"
                                id="appntneeded"
                                className="k-checkbox"
                                name="approved-n-n"
                                checked={this.state.IsApprovalNotNeeded}
                                onClick={() => this.setState({IsApprovalNotNeeded : !this.state.IsApprovalNotNeeded})}
                              />
                              <label
                                className="k-checkbox-label mr-3 font-weight-bold"
                                htmlFor="appntneeded"
                              >
                                Approval Not needed
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="col-md-2">
                  {this.state.savedFilterViews && this.state.savedFilterViews.length > 0 && (
                        <div className="k-form-field">
                          <div className="d-block">
                            <label>Filter Views</label>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              {this.state.savedFilterViews.map((option, i) => {
                                return (
                                    <div className="d-block mb-2">
                                        <input
                                            type="radio"
                                            id={'' + option.filterId}
                                            name={'' + option.filterId}
                                            value={option.filterId}
                                            checked={this.state.filterViewId === option.filterId}
                                            className="k-radio"
                                            key={option.filterId}
                                            onClick={e => {
                                              this.setSelectedFilterView(option.filterId);
                                            }}
                                        />
                                        <label
                                            key={option.filterId}
                                            htmlFor={'' + option.filterId}
                                            className="k-radio-label"
                                        >
                                            { option.filterName}
                                        </label>
                                    </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="col-md-8">
                    <div className="row mt-2">
                      <div className="col-md-3">
                        <label className="">Project/ Task Group:</label>
                        <AsyncSelectMultipleServices
                            services={(this.state.selectedProjectPractice.length > 0 || this.state.selectedProjectRegion.length > 0) ? [getProjects] : [getProjects, getNonProjectTaskGroup]}
                            pageSize={25}
                            emptyItem={{Name: 'Loading...'}}
                            loadingData={this.filledArray(10, {Name: 'Loading...'})}
                            delay={300}
                            textField={'Name'}
                            dataItemKey={'uniqueName'}
                            name="project"
                            value={this.state.selectedProject}
                            onChange={e => {
                              if(!e.target.value){
                                return this.setState({
                                  selectedProject: [],
                                  selectedCR: [],
                                  selectedTask: [],
                                  selectedResource: [],
                                })
                              }
                              this.setState({selectedProject: e.target.value});
                            }}
                            additionalBind={{projectPractice: null, projectRegion: null}}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="">CR:</label>
                        <AsyncSelectMultiSelect
                            services={[getChangeRequest]}
                            pageSize={10}
                            emptyItem={{Name: 'Loading...'}}
                            loadingData={this.filledArray(10, {Name: 'Loading...'})}
                            delay={300}
                            textField={'Name'}
                            dataItemKey={'Name'}
                            name="ChangeRequest"
                            value={this.state.selectedCR}
                            onChange={e => {
                              this.setState({selectedCR: [...e.value]})
                            }}
                            additionalBind={{
                              projectRegion: this.state.selectedProjectRegion.length > 0 ? this.state.selectedProjectRegion.map(value => value.id) : [],
                              projectPractice: this.state.selectedProjectPractice.length > 0 ? this.state.selectedProjectPractice.map(value => value.id) : [],
                              ProjectId: this.state.selectedProject.length > 0 && this.state.selectedProject.map(v => v.type).includes('Project') ? this.state.selectedProject.map(v => v.id) : this.state.selectedProject.length > 0 ? ['NPG'] : []}}
                        />
                      </div> 
                      <div className="col-md-3">
                        <label className="">Task:</label>
                        <AsyncSelectMultiSelect
                            services={(this.state.selectedProjectPractice.length > 0 || this.state.selectedProjectRegion.length > 0 || (this.state.selectedProject.length > 0 && this.state.selectedProject.filter(v => v.type).length > 0)) ? [getProjectTask] : (this.state.selectedProject.length > 0 && this.state.selectedProject.filter(v => !v.type).length > 0) ? [getNonProjectTak] : [getProjectTask, getNonProjectTak]}
                            pageSize={10}
                            emptyItem={{Name: 'Loading...'}}
                            loadingData={this.filledArray(10, {Name: 'Loading...'})}
                            delay={300}
                            textField={'Name'}
                            dataItemKey={'Name'}
                            name="task"
                            value={this.state.selectedTask}
                            onChange={e => {
                              this.setState({selectedTask: [...e.value]}) 
                            }}
                            additionalBind={this.state.selectedProject.length > 0 && this.state.selectedProject.map(v => v.type) ? {type: this.state.selectedProject.map(v => v.type), ['id']: this.state.selectedProject.map(v => v.id), changeRequests: this.state.selectedCR.map( e => e.id)} : this.state.selectedProject.length > 0 ? {id : this.state.selectedProject.map(v => v.id)} : []}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="">Resource:</label>
                        <AsyncSelectMultipleServices
                            services={!this.state.selectedProject.length > 0 && this.state.selectedCR.length === 0 && this.state.selectedTask.length === 0 ? [getFilteredUsers] : [usersList]}
                            pageSize={10}
                            emptyItem={{displayName: 'Loading...'}}
                            loadingData={this.filledArray(10, {displayName: 'Loading...'})}
                            delay={300}
                            textField={'displayName'}
                            dataItemKey={'displayName'}
                            name="resource"
                            value={this.state.selectedResource}
                            onChange={e => this.setState({selectedResource: e.target.value})}
                            additionalBind={{
                              project: this.state.selectedProject.map(e => e),
                              task: this.state.selectedTask.map(e => e.Name),
                              changeRequests: this.state.selectedCR.map( e => e.id),
                            }}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="">Line Manager:</label>
                        <AsyncSelectMultipleServices
                            services={[getFilteredUsers]}
                            pageSize={10}
                            emptyItem={{displayName: 'Loading...'}}
                            loadingData={this.filledArray(10, {displayName: 'Loading...'})}
                            delay={300}
                            textField={'displayName'}
                            dataItemKey={'displayName'}
                            name="lineManager"
                            value={this.state.selectedLineManger}
                            onChange={e => this.setState({selectedLineManger: e.target.value})}
                            additionalBind={{}}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="">Project Region:</label>
                        <MultiSelect  
                        data={this.state.billingRegion}
                        name="project_region"
                        textField="Name"
                        placeholder={'Please Select'}
                        onChange={e => this.setState({selectedProjectRegion: e.target.value})}
                        value={this.state.selectedProjectRegion}
                        filterable={true}
                        onFilterChange={this.filterChange}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="">Resource Region:</label>
                        <MultiSelect 
                          data={this.state.resource_region || this.state.region}
                          name="resource_region"
                          textField="Name"
                          placeholder={'Please Select'}
                          onChange={e => this.setState({selectedResourceRegion: e.target.value})}
                          value={this.state.selectedResourceRegion}
                          filterable={true}
                          onFilterChange={this.filterChange}
                        />
                      </div>

                      <div className="col-md-3">
                        <label className="">Project Department:</label>
                        <MultiSelect
                          data={this.state.project_practice || this.state.practice}
                          name="project_practice"
                          textField="Name"
                          placeholder={'Please Select'}
                          onChange={e => this.setState({selectedProjectPractice: e.target.value})}
                          value={this.state.selectedProjectPractice}
                          filterable={true}
                          onFilterChange={this.filterChange}
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="">Resource Department:</label>
                        <MultiSelect  
                        data={this.state.resource_practice || this.state.practice}
                        name="resource_practice"
                        textField="Name"
                        placeholder={'Please Select'}
                        onChange={this.handleOnChangeResourceDepartment}
                        value={this.state.selectedResourceDepartment}
                        filterable={true}
                        onFilterChange={this.filterChange}
                        />
                      </div>

                      <div className="col-md-3">
                        <label className="">Resource Practice:</label>
                        <MultiSelect  
                          data={this.state.practicesData}
                          name="resource_practicesData"
                          textField="Name"
                          placeholder={'Please Select'}
                          onChange={e => this.setState({selectedResourcePractice: e.target.value})}
                          value={this.state.selectedResourcePractice}
                          filterable={true}
                          onFilterChange={this.filterChangeResourcePractice}
                        />
                      </div>

                      <div className="col-md-3">
                        <label className="">Approver:</label>
                        <AsyncSelectMultipleServices
                            services={[getFilteredUsers]}
                            pageSize={10}
                            emptyItem={{displayName: 'Loading...'}}
                            value={this.state.selectedApprover}
                            loadingData={this.filledArray(10, {displayName: 'Loading...'})}
                            delay={300}
                            textField={'displayName'}
                            dataItemKey={'displayName'}
                            name="approver"
                            onChange={e => this.setState({selectedApprover: e.target.value})}
                            additionalBind={{}}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-6"><label className="w-100">Entered Days</label></div>
                      <div className="col-md-4 pl-0"><Input value={this.state.enterdHours} disabled={true} className="w-100" /></div>
                    </div>
                  </div>

                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-3">
                          <div className="row">
                            <div className="col-md-6 pr-0"><label>Billable Days</label></div>
                            <div className="col-md-6 pl-0"><Input value={this.state.billableHours} disabled={true} /></div>
                          </div>
                      </div>

                      <div className="col-md-3">
                          <div className="row" id="StatNonBillable">
                            <div className="col-md-6 pr-0"><label>Non-Billable Days</label></div>
                            <div className="col-md-6 pl-0"><Input value={this.state.nonBillableHours} disabled={true} /></div>
                          </div>
                          {this.state.nonBillableGroup.length > 0 &&
                            <Tooltip placement="right" className="timecard-tooltip" isOpen={this.state.openStatToolTip} target="StatNonBillable" toggle={() => {
                              this.setState({openStatToolTip: !this.state.openStatToolTip})
                            }}>
                              {this.state.nonBillableGroup && this.state.nonBillableGroup.map(e => (
                                <div className="text-left">
                                  <div>
                                    {`${e.XDuration}`}{' - '}
                                    {e.Approval}
                                  </div>
                                </div>
                              ))}
                            </Tooltip>
                          }
                      </div>

                      <div className="col-md-3">
                          <div className="row">
                            <div className="col-md-6 pr-0"><label>Pending Approval Days</label></div>
                            <div className="col-md-6 pl-0"><Input value={this.state.pendingHours} disabled={true} /></div>
                          </div>
                      </div>

                      <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-6 pr-0"><label>Rejected Days</label></div>
                            <div className="col-md-6 pl-0"><Input value={this.state.rejectedHours} disabled={true} /></div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-12 btn-align-right">
                    <Button primary={true} className="mb-3" type="button" onClick={() => this.toggleSaveFilterViewDialog()}>
                      Save
                    </Button>
                    <Button primary={true} className="mb-3" type="submit" onClick={this.runFilterQuery}>
                      Search
                    </Button>
                    <Button type="reset" className="mb-3" onClick={this.clearFilters}>
                      Clear
                    </Button>
                  </div>
                </div>
              </PanelBarItem>
            </PanelBar>
          </div>
        </div>

        <div className="main-seperator" />

        {/* Weekly Section */}
        <>
          {/* <div className="daily-panel-toolbar mt-3 px-2 py-2 fade-in">
                            <div className="row ">
                                <div className="col-md-12">
                                    <div className="font-weight-normal">
                                        <input
                                            type="checkbox"
                                            id="reso-name"
                                            className="k-checkbox mr-2"
                                            name="resourcename"
                                        />
                                        <span htmlFor="reso-name" >
                                            <label className="my-1 resource-name">Resource : Allan Smith</label>
                                        </span>
                                        <span className="resc-green ml-3">13 Hours / 8 Hours</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}

          <div className="panel-toolbar mt-3 px-2 py-2 fade-in">
            <div className="row ">
              <div class="col-md-4">
                <div className="d-inline-block d-flex align-items-center">
                  <div className="d-inline-block mr-2">
                    <label>Date:</label>
                  </div>
                  <div className="d-inline-block mr-2">
                    <DatePicker
                      defaultValue={new Date()}
                      format="MM/dd/yyyy"
                      value={this.state.newDate}
                      onChange={event => this.setWeekDates(event.target.value)}
                    />
                  </div>

                  <div className="d-inline-block">
                    <a type="button" onClick={() => this.prevWeek()}>
                      <div class="rounded-link d-flex align-items-center justify-content-center mr-2">
                        <span class="k-icon k-i-arrow-chevron-left" />
                      </div>
                    </a>

                    <a type="button" onClick={() => this.nextWeek()}>
                      <div class="rounded-link d-flex align-items-center justify-content-center mr-2">
                        <span class="k-icon k-i-arrow-chevron-right" />
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-8 btn-align-right">
                {
                  window.USER_PERMISSION.indexOf('VW_TM_ST_ALL') > -1 ||  
                  window.USER_PERMISSION.indexOf('VW_TM_ST_IL') > -1 ||  
                  window.USER_PERMISSION.indexOf('VW_TM_ST_FIN') > -1 ?
                  <Button primary={true} onClick={() => this.setState({downloadExcel: true, clientExcel: false})}>Export to Excel</Button>:
                  null
                }
                {
                  this.state.pmUserRole ?
                  <Button primary={true} onClick={() => this.setState({downloadExcel: true, clientExcel: true})}>Export to Client Excel</Button>:
                  null
                }
              </div>
            </div>
          </div>
          <div className="row window-ta fade-in">
            <div className="col-md-12 panel-dark">
              {this.state.filterRun && this.state.resourceList.length === 0 &&
                  <div className="no-record-wrap">No records available</div>
              }
              {this.state.resourceList.length !== 0 &&
                this.state.resourceList.map(resource => (
                  <PanelBar
                    
                    className="mt-2"
                    key={resource.userId}
                    onSelect={e => {
                      this.getTaskList(resource.userId, 'initial');
                    }}
                  >
                    <PanelBarItem
                      expanded={false}
                      animation={true}
                      title={
                        <div className="font-weight-bold">
                          <label htmlFor="reso-name" className="reso-name">
                            Resource : {resource.userName}{' '}
                          </label>
                          {parseInt(
                              this.setTotalOfWeek(resource.totalOfWeek)
                                .toString()
                                .split(':')[0]
                            ) >= 40 ? (
                              <span className="resc-green ml-3">
                                {this.setTotalOfWeek(resource.totalOfWeek)} Hours / 40 Hours
                              </span>
                            ) : (
                              <span className="resc-red ml-3">
                                {this.setTotalOfWeek(resource.totalOfWeek)} Hours / 40 Hours
                              </span>
                            )}
                        </div>
                      }
                    >
                      <div className="py-3 grid-weekly">
                        <Grid
                          onSelectionChange={this.selectionChange}
                          onRowClick={this.rowClick}
                          selectedField="selected"
                          data={this.state.entries[resource.userId]}
                          onHeaderSelectionChange={this.headerSelectionChange}
                        >
                          <Column field="selected" width="30px" />
                          <Column field="Project" width="130px" title="Project/ Task Group" />
                          <Column field="Task" width="130px" title="Task" />

                          <Column
                            field="Mon"
                            title={
                              <div className="row">
                                <div className="col-md-4">
                                  <label htmlFor="ts-day">Mon</label>
                                  <div>{moment(this.state.firstDayOfWeek).format('MM/DD')}</div>
                                </div>
                                <div className="col-md-2 my-auto">
                                  <input
                                    type="checkbox"
                                    name="checkAll"
                                    checked={this.state.allChecked}
                                    onChange={this.handleChange}
                                    className="k-checkbox"
                                  />
                                </div>
                              </div>
                            }
                            footerCell={() => this.setTotalTime(resource.userId, 'Mon')}
                            cell={this.customCell}
                          />
                          <Column
                            field="Tue"
                            title={
                              <div className="row">
                                <div className="col-md-4">
                                  <label htmlFor="ts-day">Tue</label>
                                  <div>{moment(this.state.firstDayOfWeek).add(1, 'day').format('MM/DD')}</div>
                                </div>
                                <div className="col-md-2 my-auto">
                                  <input
                                    type="checkbox"
                                    id="ts-day"
                                    className="k-checkbox"
                                    name="tsact"
                                  />
                                </div>
                              </div>
                            }
                            footerCell={() => this.setTotalTime(resource.userId, 'Tue')}
                            cell={this.customCell}
                          />
                          <Column
                            field="Wed"
                            title={
                              <div className="row">
                                <div className="col-md-4">
                                  <label htmlFor="ts-day">Wed</label>
                                  <div>{moment(this.state.firstDayOfWeek).add(2, 'days').format('MM/DD')}</div>
                                </div>
                                <div className="col-md-2 my-auto">
                                  <input
                                    type="checkbox"
                                    id="ts-day"
                                    className="k-checkbox"
                                    name="tsact"
                                  />
                                </div>
                              </div>
                            }
                            footerCell={() => this.setTotalTime(resource.userId, 'Wed')}
                            cell={this.customCell}
                          />

                          <Column
                            field="Thu"
                            title={
                              <div className="row">
                                <div className="col-md-4">
                                  <label htmlFor="ts-day">Thu</label>
                                  <div>{moment(this.state.firstDayOfWeek).add(3, 'days').format('MM/DD')}</div>
                                </div>
                                <div className="col-md-2 my-auto">
                                  <input
                                    type="checkbox"
                                    id="ts-day"
                                    className="k-checkbox"
                                    name="tsact"
                                  />
                                </div>
                              </div>
                            }
                            footerCell={() => this.setTotalTime(resource.userId, 'Thu')}
                            cell={this.customCell}
                          />

                          <Column
                            field="Fri"
                            title={
                              <div className="row">
                                <div className="col-md-4">
                                  <label htmlFor="ts-day">Fri</label>
                                  <div>{moment(this.state.firstDayOfWeek).add(4, 'days').format('MM/DD')}</div>
                                </div>
                                <div className="col-md-2 my-auto">
                                  <input
                                    type="checkbox"
                                    id="ts-day"
                                    className="k-checkbox"
                                    name="tsact"
                                  />
                                </div>
                              </div>
                            }
                            footerCell={() => this.setTotalTime(resource.userId, 'Fri')}
                              cell={this.customCell}
                          />
                          <Column
                            field="Sat"
                            title={
                              <div className="row">
                                <div className="col-md-4">
                                  <label htmlFor="ts-day">Sat</label>
                                  <div>{moment(this.state.firstDayOfWeek).add(5, 'days').format('MM/DD')}</div>
                                </div>
                                <div className="col-md-2 my-auto">
                                  <input
                                    type="checkbox"
                                    id="ts-day"
                                    className="k-checkbox"
                                    name="tsact"
                                  />
                                </div>
                              </div>
                            }
                            footerCell={() => this.setTotalTime(resource.userId, 'Sat')}
                              cell={this.customCell}
                          />
                          <Column
                            field="Sun"
                            title={
                              <div className="row">
                                <div className="col-md-4">
                                  <label htmlFor="ts-day">Sun</label>
                                  <div>{moment(this.state.firstDayOfWeek).add(6, 'days').format('MM/DD')}</div>
                                </div>
                                <div className="col-md-2 my-auto">
                                  <input
                                    type="checkbox"
                                    id="ts-day"
                                    className="k-checkbox"
                                    name="tsact"
                                  />
                                </div>
                              </div>
                            }
                            footerCell={() => this.setTotalTime(resource.userId, 'Sun')}
                              cell={this.customCell}
                          />
                          <Column
                            className="totperweek"
                            field=""
                            width="80px"
                            cell={props => <TotalPerWeek data={props} />}
                              footerCell={'test'}
                          />
                        </Grid>
                      </div>
                    </PanelBarItem>
                  </PanelBar>
                ))}
            </div>
            {this.state.windowDialogVisible && (
              <div className="timesheetwindow">
                <Window
                  width={360}
                  style={{ height: 'auto', zIndex: '99999' }}
                  initialTop={300}
                  initialLeft={300}
                  draggable={true}
                  resizable={false}
                  title={
                    <div className="col-md-12 text-right py-0">
                      <span className="k-icon k-i-move" alt="Move" />
                    </div>
                  }
                >
                  <form className="k-form">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="text-center hr-circle text-white font-weight-bold">
                          <div classname="time-hours">3h</div>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <label className="ts-title">Item Entry Component Update</label>
                        <div className="ts-descp">M3 Master Data Configurations</div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <button
                        type="button"
                        className="k-button mr-2"
                        onClick={this.toggleWindowDialog}
                      >
                        Reject
                      </button>
                      <button
                        type="button"
                        className="k-button k-primary mr-2"
                        onClick={this.toggleWindowDialog}
                      >
                        Approve Billable
                      </button>
                      <button
                        type="button"
                        className="k-button k-primary"
                        onClick={this.toggleWindowDialog}
                      >
                        Approve Non-Billable
                      </button>
                    </div>
                  </form>
                </Window>
              </div>
            )}
          </div>
        </>
        {/* End of Weekly Section */}

        {this.state.downloadExcel && (
          <Dialog
            title={'Date Range'}
            onClose={() => this.setState({downloadExcel: !this.state.downloadExcel})}
            width={400}
          >
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">
                <div className="d-block">
                  <label>From:</label>
                </div>
                <div className="d-block">
                    <DatePicker
                      defaultValue={new Date()}
                      format="MM/dd/yyyy"
                      value={this.state.logDateFrom}
                      onChange={e => this.setState({logDateFrom: e.target.value})}
                    />
                </div>
                <div className="d-block">
                  <label>To:</label>
                </div>
                <div className="d-block">
                    <DatePicker
                      defaultValue={new Date()}
                      format="MM/dd/yyyy"
                      value={this.state.logDateTo}
                      min={this.state.logDateFrom}
                      onChange={e => this.setState({logDateTo: e.target.value})}
                    />
                </div>
              </div>
              <div className="col-md-2" />
            </div>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={() => this.setState({downloadExcel: !this.state.downloadExcel})}>
                Cancel
              </button>
              <button className="k-button " onClick={this.exportToExcel}>
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.rejectTa && (
          <Dialog
            title={'Reason for Timesheet Rejection'}
            onClose={this.toggleRejectDialog}
            width={400}
          >
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">
                <div className="d-block">
                  <label>Reason:</label>
                </div>
                <div className="d-block">
                  <textarea className="k-textarea" />
                </div>
              </div>
              <div className="col-md-2" />
            </div>
            <DialogActionsBar>
              <button className="k-button " onClick={this.toggleRejectDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.toggleRejectDialog}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.nonbilldialog && (
          <Dialog
            title={'Select Non-Billable Category'}
            onClose={this.toggleNonbillDialog}
            width={400}
          >
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <div className="d-block">
                  <label>Category:</label>
                </div>
                <div className="d-block">
                  <ComboBox />
                </div>
                <div className="col-md-3" />
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button " onClick={this.toggleNonbillDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.toggleNonbillDialog}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.unfreezeTa && (
          <Dialog
            title={'Reason to Unfreeze Timesheet'}
            onClose={this.toggleUnfreezeDialog}
            width={400}
          >
            <div className="row">
              {/* <div className="col-md-1"></div> */}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-2" />
                  <div className="col-md-6">
                    <div className="d-block">
                      <label>Project:</label>
                    </div>
                    <div className="d-block">
                      <ComboBox />
                    </div>
                    <div className="col-md-3" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2" />
                  <div className="col-md-8">
                    <div className="d-block">
                      <label>Reason:</label>
                    </div>
                    <div className="d-block">
                      <textarea className="k-textarea" />
                    </div>
                  </div>
                  <div className="col-md-2" />
                </div>
              </div>
              {/* <div className="col-md-1"></div> */}
            </div>
            <DialogActionsBar>
              <button className="k-button " onClick={this.toggleUnfreezeDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.toggleUnfreezeDialog}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.visible === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">
            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleDialog}>
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showSaveFilterViewDialog === true && (
          <Dialog title="Save Filter View" onClose={this.toggleSaveFilterViewDialog} width="250px">
              <p><label className="mandatory">View Name:</label></p>
              <Input 
                className="k-textarea" 
                placeholder="Add text"
                onChange={this.handleFilterNameInputChange}
                value={this.state.filterViewName}
                required={true}
                maxLength="50"
              />
              <DialogActionsBar>
                  <button className="k-button" onClick={this.toggleSaveFilterViewDialog}>
                      Cancel
                  </button>
                  <button 
                    className="k-button modal-primary" 
                    onClick={this.saveSearchQuery} 
                    disabled={!this.state.filterViewName || this.state.filterViewName === ""}
                  >
                      Save
                  </button>
              </DialogActionsBar>
          </Dialog>
        )}

      </div>
    );
  }
}
const Card = props => {
  const workLocationStatuses = Object.values(WorkLocationStatus);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const colorIndicator = props => {
    if (props.approvedStatus === approvedStatus.Pending_Approval.value) {
      return 'timesheet-none row';
    } else if (props.approvedStatus === approvedStatus.Approved.value) {
      return 'timesheet-green row';
    } else if (props.approvedStatus === approvedStatus.Rejected.value) {
      return 'timesheet-gray row';
    } else if (props.approvedStatus === approvedStatus.Auto_Generated.value) {
      return 'timesheet-light-blue row';
    } else if (props.approvedStatus === approvedStatus.Leave.value) {
      return 'timesheet-light-blue row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_General.value
    ) {
      return 'timesheet-blue row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_ESE.value
    ) {
      return 'timesheet-yellow row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_PMM.value
    ) {
      return 'timesheet-purple row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_RWK.value
    ) {
      return 'timesheet-orange row';
    }
  };
  const checkOveride = data => {
    if (data.allocationHours.length !== 0) {
      let times = props.data.allocationHours.filter(x => x.user === parseInt(props.data.userId))[0];
      if (typeof times !== 'undefined') {
        if (times.isOverride) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const convertedDuration = durationValue => {
    let durationParts = durationValue.toString().split('.');
    let duration = durationParts[0];
    if (typeof durationParts[1] !== 'undefined') {
      duration =
        parseInt(duration) +
        ':' +
        Math.round(parseFloat(`0.${durationParts[1]}`) * 60)
          .toString()
          .padStart(2, '0');
      return duration
    } else {
      duration = parseInt(duration) + ':' + '00';
      return duration;
    }
  }
  let workLocationName = props.cardData.WorkLocationId ? workLocationStatuses.filter(obj => obj.value == props.cardData.WorkLocationId)[0].name : '';
  let timeDuration = props.pmUserRole ? props.cardData.ApprovedDuration ? props.cardData.ApprovedDuration : props.cardData.Duration : props.cardData.Duration;
  let timeComment = props.pmUserRole ? props.cardData.ApprovedComment : props.cardData.Comment;
  let timeTicketId = props.pmUserRole ? props.cardData.ApprovedTicketId : props.cardData.TicketId;

  return (
    <div className="card my-2" id={'tooltip-' + props.cardData.id}>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={'tooltip-' + props.cardData.id}
        toggle={toggle}
        className="timecard-tooltip"
      >
        <div className="text-left">
          <div>
            {`Duration :${convertedDuration(timeDuration)}`}{' '}
            {props.cardData.ProjectTaskAllocation && props.cardData.ProjectTaskAllocation.ShiftFrom && props.cardData.FromDateTime !== null
              ? `(${props.cardData.FromDateTime} - ${props.cardData.ToDateTime})`
              : null}
          </div>
          <div>{`Comment : ${timeComment}`}</div>
          <div>{`Ticket ID : ${timeTicketId ? timeTicketId : ''}`}</div>
          {props.pmUserRole && props.cardData.PMEdited ? (
            <div>{`Resource Entered Duration : ${props.cardData.Duration}`}</div>
          ) : null}
          {props.pmUserRole && props.cardData.PMEdited ? (
             <div>{`Resource Entered Comment : ${props.cardData.Comment}`}</div>
          ) : null}
          {props.pmUserRole && props.cardData.PMEdited ? (
             <div>{`Resource Entered Ticket ID : ${props.cardData.TicketId ? props.cardData.TicketId : ''}`}</div>
          ) : null}
          <div>{`Work Location : ${workLocationName}`}</div>
          {props.cardData.ApprovedStatus === approvedStatus.Pending_Approval.value && (
            <div>Status : Approval Pending</div>
          )}
          {props.cardData.ApprovedStatus === approvedStatus.Approved.value && (
            <div> Status : Approved – Billable</div>
          )}
          {props.cardData.ApprovedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId ===
            approvedStatus.Approved_NB_General.value ? (
            <div> Status : Approved Non-Billable - General</div>
          ) : null}
          {props.cardData.approvedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId ===
            approvedStatus.Approved_NB_ESE.value ? (
            <div> Status : Approved Non-Billable - Estimation Issues</div>
          ) : null}
          {props.cardData.approvedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId ===
            approvedStatus.Approved_NB_PMM.value ? (
            <div> Status : Approved Non-Billable - Mismanagement</div>
          ) : null}
          {props.cardData.approvedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId ===
            approvedStatus.Approved_NB_RWK.value ? (
            <div> Status : Approved Non-Billable - Rework</div>
          ) : null}
          {props.cardData.ApprovedStatus === approvedStatus.Rejected.value && (
            <div> Status : Rejected</div>
          )}
          {props.cardData.ApprovedStatus === approvedStatus.Auto_Generated.value && (
            <div>Status : Automatic Approvals</div>
          )}
          {props.cardData.ApprovedStatus === approvedStatus.Leave.value && !props.cardData.IsPending && (
            <div>Status : Leave</div>
          )}
          {props.cardData.ApprovedStatus === approvedStatus.Leave.value && props.cardData.IsPending && (
              <div>Status : Leave - Pending Approval</div>
          )}
          {props.cardData.ApprovedStatus === approvedStatus.Approved.value ||
          props.cardData.ApprovedStatus === approvedStatus.Approved_NB.value ? (
            <div>Approved By : {props.cardData.ApprovedBy}</div>
          ) : null}
          {props.cardData.ApprovedStatus === approvedStatus.Rejected.value && (
            <div>Reject Reason : {props.cardData.RejectReason}</div>
          )}
          {props.cardData.ResonOfMove !== null && (
            <div>Reason to Move : {props.cardData.ResonOfMove}</div>
          )}
          {props.cardData.IsBillable === true && props.cardData.RateType === 5 ? (
            <div>
              Warning : This user has a non-billable allocation and should not be entering billable
              timesheets.
            </div>
          ) : null}
          {props.cardData.IsOverEightHour === true ? (
            <div>Warning : This user has entered more than 8 hours of billable work.</div>
          ) : null}
          {checkOveride(props.data) ? (
            <div>
              Warning : This user has utilized all the allocated billable time for the Project.
            </div>
          ) : null}
          <div>
            {`Time Entry Date : `}{' '}
            <Moment format="MM-DD-YYYY hh:mm:ss A">
               {props.cardData.createdAt}
            </Moment>
          </div>
        </div>
      </Tooltip>
      <div className="col-md-12">
        <div className={colorIndicator(props)}>
          <input
            type="checkbox"
            className="k-checkbox mr-2"
            name="tsact"
            checked={props.checked}
            disabled={true}
            onClick={() => {
              props.checkeBoxHandler(props.index, props.taskId, props.date, props.data);
            }}
          />
          <span htmlFor="ts-activity" className="ts-act d-inline-block">
            {(props.cardData.ProjectTaskAllocation && props.cardData.ProjectTaskAllocation.ProjectTask && 
            (props.cardData.ProjectTaskAllocation.ProjectTask.BillabilityTypeId === 'BILLABLE')) && 
            (props.cardData.ProjectTaskAllocation && props.cardData.ProjectTaskAllocation.ResourceRequest && props.cardData.ProjectTaskAllocation.ResourceRequest.RateType === 5) ||
            props.cardData.IsOverEightHour === true ||
            checkOveride(props.data) ? (
              <span
                className=" d-inline-block my-auto"
                style={{ color: 'red' }}
              >
                {convertedDuration(timeDuration)}
              </span>
            ) : (
              <span className=" d-inline-block my-auto">
                {convertedDuration(timeDuration)}
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
export default ViewAllTimeheets;
class TotalPerWeek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPerWeek: 0
    };
  }

  durationCalculation = (value) => {
    let durationParts = value.toString().split('.');
    let duration = durationParts[0];
    let durMinPart =Math.round(parseFloat(`0.${durationParts[1]}`) * 60)
    .toString()
    .padStart(2, '0');
    if (typeof durationParts[1] !== 'undefined') {
      if(parseInt(durMinPart) === 60){
        duration = parseInt(duration) + 1;
        durMinPart = '00';
      }else{
        durMinPart = Math.round(parseFloat(`0.${durationParts[1]}`) * 60)
        .toString()
        .padStart(2, '0');
      }
      duration =
        parseInt(duration) +
        ':' +
        durMinPart;
      return duration;
    } else {
      return (duration = parseInt(duration));
    }
  }

  componentDidMount() {
    const rowData = this.props.data ? this.props.data.dataItem : null;
    if(!rowData){
      return this.setState({
        totalDuration: 0
      })
    }
    const {totalDuration} = rowData;
    this.setState({
      totalPerWeek: this.durationCalculation(totalDuration)});
  }
  render() {
    return <td className="text-center totperweek">{this.state.totalPerWeek}</td>;
  }
}