import React, { Component } from 'react';

class ProjectCell extends Component {
    render() {
        const value = this.props.dataItem.Project;
        if (value) {
            return <td>{value.UniqueId + " - " + value.ProjectName}</td>;
        } else {
            return <td></td>;
        }

    }
}

export default ProjectCell;
