/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import moment from 'moment';
import $ from "jquery";

class RowCellDatePicker extends Component {
    handleChange = async event => {
        this.props.onChange({
            dataItem: this.props.dataItem,
            field: this.props.field,
            syntheticEvent: event.syntheticEvent,
            value: event.target.value
        });
    };


    componentDidMount() {
        $(document).ready(function () {
            $("div.title-remove > span > span > span:last-child").removeAttr("title");
        });
    }

    reStructureDate=(date)=>{
        if(date){
          return new Date([moment(date).format('YYYY'), moment(date).format('M'), moment(date).format('D')].join('/'))
        }else{
          return date
        }
    }

     render() {
        if (!this.props.dataItem.inEdit) {
            const date = this.props.dataItem[this.props.field];
            const dateStr = date
                ? date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
                : '';

            return <td>{dateStr}</td>;
        }

        let date = null;
        let min = null;
        if (this.props.Type === 'ResourceRequestActivation') {
             min = this.props.proStartDate;
        } else {
             min = this.props.dataItem[this.props.minDateField];
        }

        let max = this.props.dataItem[this.props.maxDateField]

        if (this.props.field === 'DateStartDate') {
            if (this.props.dataItem['DateStartDate']) {
                date = new Date(this.props.dataItem['DateStartDate']);
            }
        } else if (this.props.field === 'EndDate') {
            if (this.props.dataItem['EndDate']) {
                date = new Date(this.props.dataItem['EndDate']);
            }
        } else {
            date = new Date(this.props.dataItem[this.props.field]);
        }


         if (this.props.Type === 'ResourceRequestActivation') {
             if (this.props.proStartDate) {
                 min = new Date(this.props.proStartDate)
             } else {
                 min = new Date()
             }
         } else {
             if (this.props.dataItem[this.props.minDateField]) {
                 min = new Date(this.props.dataItem[this.props.minDateField])
             } else {
                 min = new Date()
             }
         }


        if (this.props.dataItem[this.props.maxDateField]) {
            max = new Date(this.props.dataItem[this.props.maxDateField])
        }


        return (
            <td>
                <div className="rowcelldp">

                    <div className="title-remove">
                        {max === null && (
                            <DatePicker
                                width="100%"
                                onChange={this.handleChange}
                                value={date}
                                min={min}
                                disabled={this.props.disabled}
                                format="MM/dd/yyyy"
                                required={this.props.required ? this.props.required : false}
                                formatPlaceholder={{year: 'YYYY', month: 'MM', day: 'DD'}}
                                className={this.props.StartDateIsAPastDate && this.props.StartDateIsAPastDate === true ? 'text-red' : ''}
                            />
                        )
                        }
                        {
                            max !== null && (
                                <DatePicker
                                    width="100%"
                                    onChange={this.handleChange}
                                    value={date}
                                    min={min}
                                    max={max}
                                    disabled={this.props.disabled}
                                    format="MM/dd/yyyy"
                                    required={this.props.required ? this.props.required : false}
                                    formatPlaceholder={{year: 'YYYY', month: 'MM', day: 'DD'}}
                                    className={this.props.StartDateIsAPastDate && this.props.StartDateIsAPastDate === true ? 'text-red' : ''}
                                />
                            )
                        }
                    </div>
                    {this.props.Type === 'ResourceRequest' && !this.props.dataItem[this.props.field] && !this.props.field === "EndDate" && (
                        <span className={'inline-error'}>
                            Please enter a start date
                </span>
                    )}

                    {this.props.Type === 'ResourceRequestActivation' && !this.props.dataItem[this.props.field] && !this.props.field === "EndDate" && (
                        <span className={'inline-error'}>
                            Please enter a start date
                </span>
                    )}

                </div>
            </td>
        );
    }
}

export default RowCellDatePicker;
