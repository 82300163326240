import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import {getFormattedDate} from "../../../integration/CommonUtils";

const billableString = 'BILLABLE';
const billablecrString = 'BILLABALE_CR';
const closeString = 'CLOSED';
const closeoplostString = 'CLOSE_OP_LOST';
const closewithoutbidString = 'CLOSE_WITHOUT_BID';
const closeSting = 'close';

class ProjectCell extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChangeStatusPopUp = event => {
    event.preventDefault();
    const project = this.props.dataItem;
    if (project) {
      this.props.handleChangeStatusPopUp(project);
    }
  };

  handleStatusPopUpCR = ( event, project) =>{
    event.preventDefault();
    this.props.handleStatusPopUpCR(event, project, 'HandleCrPopUp');
  };

  handleEditCR = (event, proj, isProjectManager, hasEditCRPermission, crListOfCRManager) => {
    event.preventDefault();
    this.props.onEditCR(event, proj, isProjectManager, hasEditCRPermission, crListOfCRManager);
  };

  onClickProjectSection = (event, action, param) => {
      event.preventDefault();

    // Change request create
    if(action === 'create'){
      this.setState({
        redirectToCreateCR : true
      });
    }
    // Project Edit
    else if(action === 'edit'){
      this.setState({
        redirectToEditProject : true
      });
    }
    // Project View
    else if(action === 'view'){
      this.setState({
        redirectToViewProject : true
      });
    }

  }
  render() {
    const project = this.props.dataItem;
    const projectStatusCode = project.ProjectStatus ? project.ProjectStatus.Code : '';

    let programeName = '';
    let customerName = '';
    let regionName = '';
    let divisionName = '';
    let PMName = '';
    let projectStatus = '';
    let isPendingStatusProj = false;
    let isPendingStatusCr = false;
    let editableCR = true;

    if (this.props.dataItem.Program) {
      programeName = this.props.dataItem.Program.Name;
    }
    if (this.props.dataItem.Customer) {
      customerName = this.props.dataItem.Customer.Name;
    }
    if (this.props.dataItem.Region) {
      regionName = this.props.dataItem.Region.Name;
    }
    if (this.props.dataItem.Division) {
      divisionName = this.props.dataItem.Division.Name;
    }
    if (this.props.dataItem.PresalesResource) {
      PMName = this.props.dataItem.PresalesResource.Name;
    }
    if (this.props.dataItem.ProjectStatus) {
      projectStatus = this.props.dataItem.ProjectStatus.StatusName;
    }
    let isClosedStatus = false;
    if (project.ProjectStatus) {
      let projectName = project.ProjectStatus.StatusName;
      isClosedStatus = projectName
        .toString()
        .toLowerCase()
        .includes(closeSting);
    }

    if(project.ActivityLogs && project.ActivityLogs.length > 0){
      project.ActivityLogs.sort((a, b) => (b.createdAt > a.createdAt) ? 1 : -1);

      if(project.ActivityLogs[0].ApprovalStatus && project.ActivityLogs[0].ApprovalStatus === 'PENDING'){
        isPendingStatusProj = true;
      }
      else{
        isPendingStatusProj = false;
      }
    }

    const crs = (project.ChangeRequests && project.ChangeRequests.length > 0) ? project.ChangeRequests : [];

    if(crs && crs.length > 0){
      for(let cr of crs){
        if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
          if(cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING'){
            isPendingStatusCr = true;
          }
          else{
            isPendingStatusCr = false;
            break;
          }
        }
      }
      for(let cr of crs){
        if(cr.Status && (cr.Status.Code === 'PRESALES' || cr.Status.Code === 'WAIT_CONFIRM' ||
        cr.Status.Code === 'BILLABLE' || cr.Status.Code === 'ON_HOLD' || cr.Status.Code === 'PROJECT_ON_HOLD' || 
        cr.Status.Code === 'DEAL_ON_HOLD' || cr.Status.Code === 'WORK_AT_RISK')){
          editableCR = true;
          break;
        }
        else{
          editableCR = false;
        }
      }
    }

      const presaleCRs = project.ChangeRequests.filter(obj => obj.Status && obj.Status.Code === 'PRESALES' || obj.Status && obj.Status.Code === 'WAIT_CONFIRM' ||
        obj.Status && obj.Status.Code === 'BILLABLE' || obj.Status && obj.Status.Code === 'ON_HOLD' || obj.Status && obj.Status.Code === 'WORK_AT_RISK');

      let projectManager = false;
      let projectStatusDef = '';
      let pendingCMSApprovals = false;
      const editProjPermission = window.USER_PERMISSION.filter(obj => obj === 'EDT_PRJ');
      const deleteProjPermission = window.USER_PERMISSION.filter(obj => obj === 'DLT_PRJ');
      const viewProjPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_PRJ');
      const createPoolProjPermission = window.USER_PERMISSION.filter(obj => obj === 'FAC_PRO');
      const viewPoolProjPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_FAC_PRJ');
      const viewStatusChangePermission = window.USER_PERMISSION.filter(obj => obj === 'VIEW_CHANGE_STATUS');
      const changeToStatusPermissions = window.USER_PERMISSION.filter(obj => obj === 'CHG_TO_WAITING_CONFIRMATION' || obj === 'CHG_TO_CLOSE_DISCONTINUE' ||
          obj === 'CHG_TO_PROJECT_ON_HOLD' || obj === 'CHG_TO_PRESALE' || obj === 'CHG_TO_CLOSE_WITHOUT_BIDDING' || obj === 'CHG_TO_CLOSE_OPPORTUNITY_LOST' ||
          obj === 'CHG_TO_DEAL_ON_HOLD' || obj === 'CHG_TO_WORKING_AT_RISK' || obj === 'CHG_TO_ACTIVE' || obj === 'CHG_TO_COMPLETE' || obj === 'CHG_TO_CLOSED');


    const projectProjectManagers = project.AllocatedResourceRequestPM;

    //find valid pms from end date
    let projValidManagers = [];
    projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

    if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
      //projValidManagers.push(projectProjectManagers[0]);
      projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
      const lastAssignedManager = projectProjectManagers[0];
      projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

    }

    projValidManagers.forEach(value => {
      if(window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()){
        projectManager = true;
      }
    });

      if (project.ProjectStatus) {
          let projectStatusName = project.ProjectStatus.StatusName;
          projectStatusDef = projectStatusName;
      }

      const cmSheets = project.CMSheets;
      if (cmSheets) {
          for (const cmSheet of cmSheets) {
              if (cmSheet.Status === 'PENDING_APPROVALS') {
                  pendingCMSApprovals = true
                  break;
              }
          }
      }

      let displayBtnPro;
      let displayBtnCR;


      for(let el of changeToStatusPermissions){
        switch(el){
          case 'CHG_TO_DEAL_ON_HOLD' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_PRESALE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_WAITING_CONFIRMATION' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_WORKING_AT_RISK' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_ACTIVE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'WORK_AT_RISK' || project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM' ||
            project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PROJECT_ON_HOLD')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_PROJECT_ON_HOLD' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'WORK_AT_RISK' || project.ProjectStatus.Code == 'BILLABLE')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_WITHOUT_BIDDING' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_OPPORTUNITY_LOST' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_COMPLETE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'BILLABLE')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_DISCONTINUE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'BILLABLE' || project.ProjectStatus.Code == 'WORK_AT_RISK' || project.ProjectStatus.Code == 'PROJECT_ON_HOLD')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_CLOSED' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'COMPLETE')){
              displayBtnPro = true;
            }
            break;
          }
          default : {
          }
        }
      }

      if(viewStatusChangePermission && viewStatusChangePermission.length > 0){
          displayBtnPro = true;
      }

      var displayStatusButtonPro = {
          display: displayBtnPro === true ? 'block' : 'none'
      }



      for(let el of changeToStatusPermissions){
        switch(el){
          case 'CHG_TO_DEAL_ON_HOLD' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'PRESALES' || obj.Status && obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_PRESALE' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'DEAL_ON_HOLD')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_WAITING_CONFIRMATION' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status && obj.Status.Code == 'PRESALES')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_WORKING_AT_RISK' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status && obj.Status.Code == 'PRESALES' || obj.Status && obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_ACTIVE' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'WORK_AT_RISK' || obj.Status && obj.Status.Code == 'PRESALES' || obj.Status && obj.Status.Code == 'WAIT_CONFIRM' ||
              obj.Status && obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status && obj.Status.Code == 'PROJECT_ON_HOLD')).length > 0 )){
                displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_PROJECT_ON_HOLD' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'WORK_AT_RISK' || obj.Status && obj.Status.Code == 'BILLABLE')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_WITHOUT_BIDDING' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status && obj.Status.Code == 'PRESALES')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_OPPORTUNITY_LOST' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status && obj.Status.Code == 'PRESALES' || obj.Status && obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_COMPLETE' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'BILLABLE')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_DISCONTINUE' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'BILLABLE' || obj.Status && obj.Status.Code == 'WORK_AT_RISK' || obj.Status && obj.Status.Code == 'PROJECT_ON_HOLD')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_CLOSED' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'COMPLETE')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          default : {
          }
        }
      }

      if(viewStatusChangePermission && viewStatusChangePermission.length > 0){
          displayBtnCR = true;
      }

      var displayStatusButtonCR = {
          display: displayBtnCR === true ? 'block' : 'none'
      }


      // Change Request related changes
      const activeCRs = project.ChangeRequests.filter(cr => cr.IsActive === true).length;
      let crManager = false;

    projValidManagers.forEach(value => {
      if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
        projectManager = true;
      }
    });

      const crManagerChangeRequests = [];
    let changeRequestsWithPMs = [];

    if (project.ChangeRequests && project.ChangeRequests.length>0) {
      for (const obj of project.ChangeRequests) {
        if(obj.IsActive === true){
          const crProjectManagers = obj.AllocatedPM;

          //find valid pms from end date
          let crValidManagers = [];
          crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

          if (crValidManagers.length == 0 && crProjectManagers.length > 0){
           // crValidManagers.push(crProjectManagers[0]);
            crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
            const lastAssignedManager = crProjectManagers[0];
            crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
          }
          for(const allocatedPM of crValidManagers){
            if (allocatedPM.IsPresales && (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase())) {
              crManagerChangeRequests.push(obj);
            }
            if (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
              changeRequestsWithPMs.push(obj);
            }
          }
        }
      }
    }

      const deleteChangeReqCRManager = project.ChangeRequests.filter(obj => obj.IsActive === true && obj.Status && obj.Status.Code === 'PRESALES' && obj.CMSheets.length === 0 ).length;

      let changeReqWithoutPendingApprovals = [];
      project.ChangeRequests.forEach(function (changeReq) {
          let CMSheet = null;
          if(changeReq && changeReq.IsActive === true && changeReq.CMSheets.length>0){
              if(changeReq.CMSheets[0].Status !== 'PENDING_APPROVALS'){
                  changeReqWithoutPendingApprovals.push(changeReq);
              }
          }
          else if(changeReq && changeReq.IsActive === true ){
              changeReqWithoutPendingApprovals.push(changeReq);
          }
      });

      const createCRPermission = window.USER_PERMISSION.filter(obj => obj === 'CRT_PRJ');
      const editCRPermission = window.USER_PERMISSION.filter(obj => obj === 'EDT_PRJ');
      const deleteCRPermission = window.USER_PERMISSION.filter(obj => obj === 'DLT_PRJ');

      // Change Request Create
      if(this.state.redirectToCreateCR){
          this.props.onClickProject();
          return (
              <Redirect
                  to={{
                      pathname: '/changeRequest/create',
                      action: 'create',
                      projectId: project.id
                  }}
              />
          );
      }

      // Project Edit
      if(this.state.redirectToEditProject){
          this.props.onClickProject();
          if(project.IsPoolProject === true){
              return (
                  <Redirect
                      to={{
                          pathname: '/PoolProject/FactoryMain',
                          projectId: project.id,
                          action: 'edit'
                      }}
                  />
              );
          }
          else{
              return (
                  <Redirect
                      to={{
                          pathname: '/projects/create/create',
                          projectId: project.id,
                          action: 'edit'
                      }}
                  />
              );
          }

      }

      // Project View
      if(this.state.redirectToViewProject){
          this.props.onClickProject();
          if(project.IsPoolProject === true){
              return (
                  <Redirect
                      to={{
                          pathname: '/PoolProject/FactoryMain',
                          projectId: project.id,
                          action: 'view'
                      }}
                  />
              );
          }
          else{
              return (
                  <Redirect
                      to={{
                          pathname: '/projects/create/create',
                          projectId: project.id,
                          action: 'view'
                      }}
                  />
              );
          }

      }
      // Task Management
      var hasTaskAccess = projectManager ? true: false;
      for (const level of ['VW_PRJTASKMNGMT', 'ADD_EDIT_TASKS', 'ADD_EDIT_RESCS']) {
        if (!hasTaskAccess) {
          hasTaskAccess = window.USER_PERMISSION.includes(level);
        } else {
          break;
        }
      }

      if(!hasTaskAccess && project.ChangeRequests && project.ChangeRequests.length > 0){
        for (const cr of project.ChangeRequests) {
          if(!hasTaskAccess){
          if (cr && cr.ResourceRequestStatus && cr.ResourceRequestStatus === 'PM_ALLOCATED') {
            if ((cr.AllocatedPM && cr.AllocatedPM.length > 0 && cr.AllocatedPM.find(pm => pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()))) {
              hasTaskAccess = true;
            }
          } else {
            if (window.LOGGED_USER.userId.toUpperCase() === cr.PresalesResource.Email.toUpperCase() && cr.IsActive === true) {
              hasTaskAccess = true;
            }
          }
          }else{
            break;
          }
        }
      }

    return (
      <td>

          { ( (viewProjPermission && viewProjPermission.length>0) || projectManager === true) &&(
          <a href="#" onClick={event => this.onClickProjectSection(event, 'view', project.id)}>
            <div class="rounded-link" title="View">
              <span class="k-icon k-i-preview" />
            </div>
          </a>
              )}

          { ( (((editProjPermission && editProjPermission.length>0) || projectManager === true ) && (project.IsPoolProject === false)) &&
              (( (projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' )  && pendingCMSApprovals===false) ) && (!isPendingStatusProj)
          ) &&(
              <Link
                  to={{
                      pathname: '/projects/create/create',
                      projectId: project.id,
                      action: 'edit'
                  }}
              >
                  <div className="rounded-link" title="Edit">
                      <span className="k-icon k-i-edit" />
                  </div>
              </Link>
          )}


          { (((createPoolProjPermission && createPoolProjPermission.length>0) && (project.IsPoolProject === true)) &&
              (( (projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' )  && pendingCMSApprovals===false) )
          ) &&(
              <Link
                  to={{
                      pathname: '/PoolProject/FactoryMain',
                      projectId: project.id,
                      action: 'edit'
                  }}
              >
                  <div className="rounded-link" title="Edit">
                      <span className="k-icon k-i-edit" />
                  </div>
              </Link>
          )}

        { project && window.LOGGED_USER.userId.toUpperCase() === project.PresalesResource.Email.toUpperCase() && (
            <a href="#" onClick={event => this.onClickProjectSection(event, 'create', project.id)}>
                <div className="rounded-link" title="Create a CR">
                  <span className="k-icon k-i-track-changes"/>
                </div>
            </a>
        )}

          { ( ( projectManager === true || (editCRPermission && editCRPermission.length>0) || (changeRequestsWithPMs&& changeRequestsWithPMs.length>0) ) &&
              (changeReqWithoutPendingApprovals.length>0) && (!isPendingStatusCr) && editableCR
          ) && (
              <a href="#" onClick={event => this.handleEditCR(event, project, projectManager, editCRPermission, crManagerChangeRequests)}>
                  <div className="rounded-link" title="Edit CR">
                      <span className="k-icon k-i-edit"/>
                  </div>
              </a>
          )}


        {/**change status popup */}
        {((viewStatusChangePermission && viewStatusChangePermission.length > 0) || (changeToStatusPermissions && changeToStatusPermissions.length > 0)) && (
            <a href="#" onClick={this.handleChangeStatusPopUp}>
              <div className="rounded-link d-inline" title="Status Change" style={displayStatusButtonPro}>
                <span className="k-icon k-i-wrench" />
              </div>
            </a>
        )}

        {project.ChangeRequests.length > 0 && ((viewStatusChangePermission && viewStatusChangePermission.length > 0)  || (changeToStatusPermissions && changeToStatusPermissions.length > 0)) && (
            <a href="#" onClick={event => this.handleStatusPopUpCR(event, project)}>
              <div className="rounded-link d-inline" title="Status Change - CR"  style={displayStatusButtonCR}>
                <span className="k-icon k-i-wrench" />
              </div>
            </a>
          )}
        {/**Task Management Button */}
        
   {hasTaskAccess && (
          <Link
            to={{
              pathname: '/timesheets/projecttaskmanagement/projecttaskmanagement',
              projectId: project.id,
              action: 'view',
              project:project
            }}
          >
            <div className="rounded-link" title="View/ Edit Task Management">
              <span className="k-icon k-i-edit" />
            </div>
          </Link>
          )}
        
        <div className="gs-disc">
          <label>{`${this.props.dataItem.UniqueId} | ${
            this.props.dataItem.ProjectName
          } | ${customerName} | ${programeName} | ${PMName} | ${projectStatus} | ${divisionName} | ${regionName}`}</label>
        </div>
      </td>
    );
  }
}

export default ProjectCell;
