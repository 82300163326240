import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { createApproverType, updateApproverType } from './ApproverTypeService';

import { Redirect } from 'react-router';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../../src/integration/LoggerService";
import { LOG_TYPES } from "../../../src/integration/IntegrationEnums";

const ACTION_CREATE = 'create';
const ACTION_EDIT = 'edit';

class ApproverTypeCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      approverType: {
        ApproverTypeName: ''
      },

      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      redirectToSearch: false,

      hasUnsavedData: false,

      isNameInvalid: false,
      disabledSave: false
    };
  }

  componentDidMount() {
    this.setHeaderTitle(this.props.location.action);

    if (this.props.location.action === ACTION_EDIT && this.props.location.approver) {
      this.setState({
        approverType: this.props.location.approver
      });
    }
  }

  setHeaderTitle = async action => {
    if (this.props.onHeaderTitleChange) {
      if (action === ACTION_CREATE) {
        this.props.onHeaderTitleChange('Create New Approver Type');
      } else if (action === ACTION_EDIT) {
        this.props.onHeaderTitleChange('Edit Approver Type');
      }
    }
  };

  handleOnChangeName = event => {
    const value = event.target.value;

    this.setState(prevState => {
      return {
        approverType: { ...prevState.approverType, ApproverTypeName: value },
        hasUnsavedData: true,
        isNameInvalid: false
      };
    });
  };

  handleSaveButtonClick = event => {
    event.preventDefault();
    const userDetails = window.LOGGED_USER;
    let user;
    if (userDetails) {
      user = userDetails.displayName;
    }
    let currentDate = new Date();
    if (!this.state.approverType.ApproverTypeName) {
      this.setState({
        isNameInvalid: true
      });
      return;
    }

    this.setState(
      {
        disabledSave: true
      },
      () => {
        if (this.props.location.action === ACTION_CREATE) {
          const approvertype = {
            Name: this.state.approverType.ApproverTypeName,
            lastmodDate: currentDate,
            lastmodUser: user,
            createdUser: user
          };


          createApproverType(approvertype)
            .then(res => {
              this.setState({
                disabledSave: false,
                showSuccessImage: true,
                showErrorImage: false
              });
              const message = `The approver type ${this.state.approverType.ApproverTypeName} is successfully created`;
              const title = 'Success';
              this.toggleMessageDialog(message, title);
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
              this.setState({
                disabledSave: false,
                showSuccessImage: false,
                showErrorImage: true
              });
              const message = error.response.data;
              const title = 'Error';
              this.toggleMessageDialog(message, title);
            });
        }

        if (this.props.location.action === ACTION_EDIT) {
          updateApproverType(this.state.approverType)
            .then(res => {
              this.setState({
                disabledSave: false,
                showSuccessImage: true,
                showErrorImage: false
              });
              const message = 'The approver type has been successfully updated';
              const title = 'Success';
              this.toggleMessageDialog(message, title);
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
              this.setState({
                disabledSave: false,
                showSuccessImage: false,
                showErrorImage: true
              });
              const message = error.response.data;
              const title = 'Error';
              this.toggleMessageDialog(message, title);
            });
        }
      }
    );
  };

  toggleMessageDialog = (message, title) => {
    if (this.state.showMessageDialog === true && this.state.dialogTitle === 'Success') {
      this.navigateToSearch();
    } else {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleConfirmDialog = (message, action) => {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
      confirmDialogMessage: message,
      confirmDialogAction: action
    });
  };

  navigateToSearch = () => {
    this.setState({
      redirectToSearch: true
    });
  };

  onClickCancel = event => {
    event.preventDefault();
    if (this.state.hasUnsavedData === true) {
      this.toggleConfirmDialog(
        'This form contain unsaved data. Do you want to close it?',
        this.navigateToSearch
      );
    } else {
      this.navigateToSearch();
    }
  };

  render() {
    if (this.state.redirectToSearch === true) {
      return <Redirect to="/admin/approvertype/searchapprovertype" />;
    }

    return (
      <div className="main-card">
        <div className="main-heading">Approver Type Details</div>
        <div className="row">
          <div className="col-md-3">
            <div className="d-block">
                <label className="mandatory">Approver Type:</label>
              <div className="d-block">
                <div className="">
                  <Input
                    value={this.state.approverType.ApproverTypeName}
                    onChange={this.handleOnChangeName}
                    maxLength={100}
                    required={true}
                  />
                  <span className={this.state.isNameInvalid ? 'inline-error' : 'd-none'}>
                    Please enter an approver type
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 btn-align-right">
            <Button
              primary={true}
              onClick={this.handleSaveButtonClick}
              disabled={this.state.disabledSave}
            >
              Save
            </Button>
            <Button onClick={this.onClickCancel}>Cancel</Button>
          </div>
        </div>

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    );
  }
}

export default ApproverTypeCreate;
