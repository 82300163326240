/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import ProjectDetails from '../projectDetails/ProjectDetails';
import OtherExpenses from '../cmSheet/component/expenses/OtherExpenses';
import ResourceAllocations from '../cmSheet/component/resourceAllocations/ResourceAllocations';
import ChartSection from '../cmSheet/component/chartSection/ChartSection';
import { getActiveProjects } from '../../projectManagement/project/ProjectService';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';

import { Redirect } from 'react-router';

import { Tooltip } from 'reactstrap';

import Loader from '../../integration/components/Loader';
import moment from 'moment';
import { formatNumber, roundNumber, reStructureDate } from '../../integration/CommonUtils';

import { getHolidaysIntegrated } from '../../integration/MasterDataService';

import {
  getCMSheet,
  createCMSheet,
  saveCMSheet,
  approveCMSheet,
  rejectCMSheet,
  setWorkflow,
  getCMSheetApprovals,
  reviseCMSheet,
  calculateDuration,
  calculateCostDate,
  retrieveConversionRate,
  getStartDateByPriority,
  calculateProjectEndDate,
  getCmsTypes,
  getCMSheetbyProjectIdAndType,
  getReviseCMSheetApprovals,
  approveReviseCmsRequest,
  rejectReviseCmsRequest
} from '../../projectManagement/cmSheet/CMSheetService';

import { updateProject, getProject, getProjectStatisticsForReviseCMS } from '../../projectManagement/project/ProjectService';
import { getDefaultRate } from '../../masterData/rateCard/RateCardService';
import { getCost } from '../../masterData/costCard/CostCardService';
import { getBudgetedRate } from '../../masterData/common/CommonService';
import {
  getRatesForAllocation,
  getProjectResources,
  getActualOtherExpensesForReviseCMS
} from '../../resourceManagement/ResourceService';
import {
  getPartnerCommission,
  getSalesCommission
} from '../../masterData/commission/CommissionService';

import {
  getChangeRequest,
  createInitialCMsheet,
  updateChangeReq
} from '../changeRequest/ChangeRequestService';

import { selectWorkflow } from '../workflow/WorkflowService';
import cmSheetInitData from '../../ui/static/data/cm-sheet.json';
import ExcelDownload from './component/ExcelDownload';
import { getFormattedDate, isWeekend } from '../../integration/CommonUtils';

import FileUpload from '../../integration/fileUpload/FileUpload';

import { saveAuditTrail } from '../../integration/auditTrail/AuditTrailService';
import { getRateTypes } from '../../masterData/rateCard/RateCardService';
import ReactQuill from 'react-quill';
import ApprovalCommentCell from './component/ApprovalCommentCell';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import DateCell from './DateCell';
import { getAllPendingTimeEntriesForReviseCms, getPrevCMSResourceRequestsWithTime } from '../../Timesheets/projectTaskManagement/ProjectTaskManagementService';
import { retrieveConversionRateIntegrated } from '../../integration/ProjectManagementService';

const ACTION_CREATE = 'create';
const ACTION_VIEW = 'view';
const ACTION_EDIT = 'edit';
const ACTION_APPROVE = 'approve';
const ACTION_REVISE = 'revise';
const ACTION_REVISECMS_APPROVE = 'reviseCMSApprove';

const ADMIN_RATE = 'ADMIN_RATE';
const APPROVED_PROJ_RATE = 'APPROVED_PROJ_RATE';
const STANDARD_RATE = 'REGION_RATE';
const PRE_AGREED_RATE = 'CUSTOMER_RATE';
const LAST_OVERRIDE_RATE = 'LAST_CUS_RATE';
const PROJECT_APPROVED_RATE = 'PROJECT_APPROVED_RATE';
const PROJECT_APPROVED_CR_RATE = 'PROJECT_APPROVED_CR_RATE';

const CUSTOM_RATE = 'CUSTOM_RATE';


class CMSheet extends Component {
  popupSet = {
    width: 'auto'
  };

  cmsStatuses = {
    IN_PROGRESS: 'In Progress',
    PENDING_APPROVALS: 'Pending Approvals',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    EXPIRED: 'Expired',
    WITHDRAWN: 'Withdrawn'
  };

  constructor(props) {
    super(props);
    this.state = {
      action: '',
      selected: 0,

      projects: [],
      EstimatedStartDate: [],
      project: {},
      cmSheet: cmSheetInitData,
      locationCMSheet: null,
      customer: {},

      cmSheetVersions: [],
      selectedVersion: null,
      cmsTypes: [],
      selectedCmsType: {},
      LastVersion: null,

      selectedRegion: 0,
      selectedCountry: 0,
      selectedPaymentMethod: 0,
      selectedSalesPerson: 0,
      selectedPartner: 0,
      StartDate: null,
      endDate: null,
      startDateMin: new Date(),
      startDateChanged: false,

      isViewMode: props.location.action === ACTION_VIEW,
      isApproveMode: false,

      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',

      confTitle: '',

      showConfirmDialog: false,
      showConfirmSaveDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      lastSelectedCurrencyId: 0,
      defaultCurrencyId: 0,

      approvalComment: '',
      approversList: [],
      approveParams: null,
      rejectParams: null,

      matchingWorkflowList: [],
      showWorkflowSelectDialog: false,
      selectedWorkFlowId: null,

      navigateToSearchScreen: false,
      navigateToDashboard: false,

      hasUnSavedData: false,
      disableExcelDownload: false,
      didRun: false,
      isInfoTab: false,

      projectNameToolTipOpen: false,
      customerNameToolTipOpen: false,
      projectIdToolTipOpen: false,
      opIdToolTipOpen: false,
      statusToolTipOpen: false,
      cmsVersionToolTipOpen: false,
      cmsStatusToolTipOpen: false,

      isSaving: false,
      isLoading: true,
      excelDataObsolete: false,

      changeRequest: [],
      PMName: '',
      showSuccessImage: false,
      showErrorImage: false,

      division: null,
      type: null,

      infoTabStartDateChanged: false,
      infoTabEndDateChanged: false,
      apsTypeHasChanged: false,
      //for resource augmentation
      augTypeHasChanged: false,
      //for blanket work order projects
      blanketTypeHasChanged: false,

      // visibility handle for file upload
      isRolePM: false,

      calculations: [],
      IsPoolProject: false,
      keyId: '',
      reSetCostValue:false,
      updateCost:false,
      showCostRateChangedConfirmDialog:false,
      rejectDialogAction:null,
      isClickedApprovalBtn: false,
      resetAndSaveFromOutSide:false,
      saveFromOutSide:false,
      approveUFromOutSide:false,
      approveRFromOutSide:false,
      selectedOffering: null,
      showVersionChangeConfirmDialog: false,
      cmsVersionChangeData: null,
      newRate:0,
      prevRate:0,
      projPractice: null,
      reviseCmsApproversList: [],
      reviseCmsPmComment: null,
      reviseCmsRequestId: null,
      alreadyActivated: false,
      prevCMSResourceRequests: [],
      showPrevCmsErrorDialog: false,
      prevCmsLessEffortResources: [],
      prevCMSOtherExpenses: [],
      ongoingRowCalculation: false
    };
  }

  componentWillUnmount() {
    this.unsubscribe(10);
  }

  unsubscribe = id => {
    this.setState({
      keyId: id
    });
  };

  subscribe = async id => {
    if (this.state.keyId !== 10) {
      await this.setState({
        keyId: id
      });
      if (this.state.keyId === 10) {
        // Load CMS Types
        this.loadCmsTypes();

        this.setState(
          {
            action: this.props.location.action,
            locationCMSheet: this.props.location.cmSheet,
            cmsReference: this.props.location.reference
          },
          () => {
            if (this.props.location.reference && this.props.location.reference === 'crCMS') {
              this.handleProjectChange(
                this.props.location.projectId,
                this.props.location.changeReqId
              );
            } else if (this.props.location.projectId > 0) {
              this.handleProjectChange(this.props.location.projectId, 0);
            } else {
              this.setState({
                isViewMode: true,
                isLoading: false
              });
            }

            if (
              this.props.location.action !== ACTION_CREATE &&
              this.props.location.action !== ACTION_EDIT
            ) {
              this.setState({
                isViewMode: true
              });
            }
          }
        );

        // Load active projects
        this.populateactiveProjects();
        this.populateCalculationsGrid();
      }
    }
  };

  componentDidMount() {
    if (!this.props.location.action) {
      this.navigateToSearchScreen();
    } else {
      this.setHeaderTitle(this.props.location.action);
      this.subscribe(10);
    }
  }

  populateactiveProjects = async () => {
    await getActiveProjects()
      .then(async response => {
        this.IsPMcheck(response.data);
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  IsPMcheck = data => {
    var project = null;
    for (var item of data) {
      if (item.id == this.props.location.projectId) {
        project = item;
      }
    }

    if (project == null) {
      return;
    }

    if (this.props.location.reference && this.props.location.reference === 'crCMS') {
      // Change Request

      // Check the Project first
      if (project.ResourceRequestStatus === 'PM_ALLOCATED') {
        if (project.AllocatedResourceRequestPM && project.AllocatedResourceRequestPM.length > 0) {
          for (const pm of project.AllocatedResourceRequestPM) {
            if (pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
              this.setState({ isRolePM: true });
            }
          }
        }
      } else if (project.ResourceRequestStatus !== 'PM_ALLOCATED') {
        if (
          project.PresalesResource &&
          project.PresalesResource.Email &&
          window.LOGGED_USER &&
          window.LOGGED_USER.userId &&
          project.PresalesResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()
        ) {
          this.setState({ isRolePM: true });
        } else {
          // Then check the CR
          if (project.ChangeRequests.length > 0) {
            for (const cr of project.ChangeRequests) {
              if (cr.ResourceRequestStatus === 'PM_ALLOCATED') {
                if (cr.AllocatedPM && cr.AllocatedPM.length > 0) {
                  for (const pm of cr.AllocatedPM) {
                    if (
                      pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()
                    ) {
                      this.setState({ isRolePM: true });
                    }
                  }
                }
              } else if (cr.ResourceRequestStatus !== 'PM_ALLOCATED') {
                if (
                  cr.PresalesResource &&
                  cr.PresalesResource.Email &&
                  window.LOGGED_USER &&
                  window.LOGGED_USER.userId &&
                  cr.PresalesResource.Email.toLowerCase() ===
                    window.LOGGED_USER.userId.toLowerCase()
                ) {
                  this.setState({ isRolePM: true });
                }
              }
            }
          }
        }
      }
    } else {
      // Project

      if (project.ResourceRequestStatus === 'PM_ALLOCATED') {
        if (project.AllocatedResourceRequestPM && project.AllocatedResourceRequestPM.length > 0) {
          for (const pm of project.AllocatedResourceRequestPM) {
            if (pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
              this.setState({ isRolePM: true });
            }
          }
        }
      } else if (project.ResourceRequestStatus !== 'PM_ALLOCATED') {
        if (
          project.PresalesResource &&
          project.PresalesResource.Email &&
          window.LOGGED_USER &&
          window.LOGGED_USER.userId &&
          project.PresalesResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()
        ) {
          this.setState({ isRolePM: true });
        }
      }
    }
  };

  loadCmsTypes = () => {
    getCmsTypes()
      .then(res => {
        this.setState({
          cmsTypes: res.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  handleCmsTypeChange = event => {
    const value = event.target.value;

    this.setState({
      selectedCmsType: value,
      hasUnSavedData: true
    });
  };

  componentDidUpdate() {
    if (this.state.hasUnSavedData && this.state.didRun === false) {
      this.setState({ disableExcelDownload: true, didRun: true });
    }
  }

  onSaveAuditTrail = (project, cmSheetId) => {
    const auditTrail = {
      Action: 'CM Sheet viewed',
      RefType: 'CM Sheet',
      RefId: cmSheetId,
      RefName: project.OPID
    };
    saveAuditTrail(auditTrail);
  };

  setHeaderTitle = async action => {
    if (this.props.onHeaderTitleChange) {
      if (action === ACTION_CREATE) {
        this.props.onHeaderTitleChange('Create Cost Management Sheet');
      } else if (action === ACTION_EDIT) {
        this.props.onHeaderTitleChange('Edit Cost Management Sheet');
      } else if (action === ACTION_VIEW) {
        this.props.onHeaderTitleChange('View Cost Management Sheet');
      } else if (action === ACTION_APPROVE) {
        this.props.onHeaderTitleChange('Approve Cost Management Sheet');
      } else if (action === ACTION_REVISE) {
        this.props.onHeaderTitleChange('Revise Cost Management Sheet');
      } else if (action === ACTION_REVISECMS_APPROVE) {
        this.props.onHeaderTitleChange('Approve Cost Management Sheet Revision');
      }
    }
  };

  /**
   * populateCurrentApprovals - populate current resources which has approvals in the cmsheet
   * @param {cmSheetId, cmSheetVersion} cmsheet id and the cmsheet version
   * @returns {object} All Approvers Resource List.
   */
  populateCurrentApprovals = (cmSheetId, cmSheetVersion) => {
    getCMSheetApprovals(cmSheetId, cmSheetVersion)
      .then(res => {
        console.log(
          'cms getting duplicated - Populate Current CMSheet Approvals - cmSheetId :',
          cmSheetId,
          ' cmSheetVersion:',
          cmSheetVersion
        );
        let approversList = res.data;
        let submittedUser = this.state.cmSheet.SubmittedUser;
        if (!submittedUser) {
          if (this.state.cmSheet.Type === "Project") {
            submittedUser = this.state.cmSheet.Project.PresalesResource ? this.state.cmSheet.Project.PresalesResource.Name : ''
          } else {
            submittedUser = this.state.cmSheet.ChangeRequest.PresalesResource ? this.state.cmSheet.ChangeRequest.PresalesResource.Name : ''
          }
        }
        const requestedUser = {
          Approver: submittedUser,
          Status: '',
          Comment: '',
          GroupName: 'PM',
          createdAt: res.data[0].createdAt,
          updatedAt: res.data[0].createdAt
        }
        if (this.state.cmSheet.Status && this.state.cmSheet.Status == "WITHDRAWN") {
          const withdrawnUser = {
            Approver: submittedUser,
            Status: this.cmsStatuses[this.state.cmSheet.Status],
            Comment: '',
            GroupName: 'PM',
            createdAt: res.data[0].updatedAt,
            updatedAt: res.data[0].updatedAt
          }
          approversList.push(withdrawnUser);
        }
        approversList.unshift(requestedUser);
        this.setState({
          approversList: approversList,
          disableExcelDownload: false
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populatePrevCMSResourceRequestsWithTime = async(projectId, projectType) => {
    const projectData = {
      ProjectId: projectId,
      Type: projectType
    }
    await getPrevCMSResourceRequestsWithTime(projectData)
      .then(async res => {
        if (res.data && res.data.length > 0) {
          await this.setState({
            prevCMSResourceRequests: res.data,
          });
        } else {
          await this.setState({
            prevCMSResourceRequests: [],
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getActualOtherExpensesForReviseCMS = async(cmsId) => {
    await getActualOtherExpensesForReviseCMS(cmsId)
      .then(async res => {
        if (res.data && res.data.length > 0) {
          await this.setState({
            prevCMSOtherExpenses: res.data,
          });
        } else {
          await this.setState({
            prevCMSOtherExpenses: [],
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  /**
   * populateReviseCmsRequest - populate current resources which has approvals in the cmsheet
   * @param {cmSheetId, cmSheetVersion} cmsheet id and the cmsheet version
   * @returns {object} All Approvers Resource List.
   */
  populateReviseCmsRequest = async(cmSheetId, cmSheetVersion) => {
    await getReviseCMSheetApprovals(cmSheetId, cmSheetVersion)
      .then(async res => {
        if (res.data && res.data.length > 0) {
          let approversList = [];
          let reviseCmsRequestId = null;
          let reviseCmsPmComment = null;
          for (const record of res.data){
            const requestedUser = {
              Approver: record.RequestedUser,
              Status: '',
              Comment: record.Comment,
              GroupName: 'PM',
              createdAt: record.createdAt,
              updatedAt: record.createdAt
            }
            approversList.push(requestedUser);
            approversList = approversList.concat(record.Approvals);

            if (record.ApprovalStatus == "PENDING_APPROVALS") {
              reviseCmsRequestId = record.id;
              reviseCmsPmComment = record.Comment;
            }
          }

          await this.setState({
            reviseCmsApproversList: approversList,
            disableExcelDownload: false,
            reviseCmsPmComment: reviseCmsPmComment,
            reviseCmsRequestId: reviseCmsRequestId
          });
        } else {
          await this.setState({
            reviseCmsApproversList: [],
            disableExcelDownload: false,
            reviseCmsPmComment: null,
            reviseCmsRequestId: null
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  handleProjectChange = (projectId, changeReqId) => {
    if (changeReqId && changeReqId !== 0) {
      getChangeRequest(changeReqId)
        .then(res => {
          const changeRequest = res.data;
          if (this.state.action === ACTION_VIEW) {
            this.onSaveAuditTrail(res.data, this.state.locationCMSheet.id);
          }
          const project = res.data.Project;
          //set project details
          const startDateMin = changeRequest.StartDate
            ? reStructureDate(changeRequest.StartDate)
            : new Date();
          const startDate = changeRequest.StartDate ? reStructureDate(changeRequest.StartDate) : null;
          let alreadyActivated = false;
          if (changeRequest.StatusConvertedToActive) {
            alreadyActivated = true;
          }
          this.setState(
            {
              changeRequest: changeRequest,
              project: project,
              customer: res.data.Customer,
              selectedRegion: changeRequest.BillingRegion,
              selectedCountry: changeRequest.CountryId,
              selectedPaymentMethod: changeRequest.PaymentMethod,
              selectedSalesPerson: changeRequest.SalesPerson ? changeRequest.SalesPerson.id : '',
              selectedPartner: changeRequest.Partner,
              StartDate: startDate,
              startDateMin: startDateMin,
              lastSelectedCurrencyId:
                changeRequest.Region && changeRequest.Region.Currency
                  ? changeRequest.Region.Currency.id
                  : 0,
              defaultCurrencyId:
                changeRequest.Region && changeRequest.Region.Currency
                  ? changeRequest.Region.Currency.id
                  : 0,
              division: changeRequest.BillingDivision,
              endDate: changeRequest.EndDate ? reStructureDate(changeRequest.EndDate) : null,
              type: changeRequest.Type,
              selectedOffering: changeRequest.OfferingId,
              projPractice: changeRequest.CRPractice,
              alreadyActivated: alreadyActivated
            },
            () => {
              if (this.state.action !== ACTION_CREATE) {
                const cmSheet = this.state.locationCMSheet;
                if (cmSheet) {
                  getCMSheet(cmSheet.id, cmSheet.Version)
                    .then(res => {
                      console.log(
                        'cms getting duplicated - Get ChangeRequest CMSheet - cmSheetId: ',
                        cmSheet.id,
                        ' cmSheetVersion: ',
                        cmSheet.Version
                      );
                      console.log('cms getting duplicated - ChangeRequest CMSheet :', res.data);
                      const txtVersion = res.data.MajorVersion + '.' + res.data.Version;
                      const item = { Text: txtVersion, Version: res.data.Version };
                    
                      const totalValuesRA = this.getResourceAllocationsTotalValues(
                        res.data.ResourceAllocations
                      );


                      this.setState(
                        {
                          LastVersion: res.data.Version,
                          selectedVersion: item,
                          pmComment: res.data.PMComment,
                          selectedCmsType: res.data.CmsType
                        },
                        async () => {
                          const cmSheet = res.data;
                  
                          cmSheet.NonBillableResourceTotalCost =
                          totalValuesRA.NonBillableResourceTotalCost;

                          cmSheet.NonBillableResourceEffortDays =
                          totalValuesRA.NonBillableResourceEffortDays;
                          if (
                            changeRequest &&
                            changeRequest.AllocatedPM &&
                            changeRequest.AllocatedPM.length > 0
                          ) {
                            const crProjectManagers = changeRequest.AllocatedPM;

                            //find valid pms from end date
                            let validManagers = [];
                            validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())));

                            if (validManagers.length === 0 && crProjectManagers.length > 0) {
                              crProjectManagers.sort(function (a, b) {
                                return new Date(b.EndDate) - new Date(a.EndDate)
                              })
                              const lastAssignedManager = crProjectManagers[0];
                              validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate));
                            }
                            this.setState({
                              PMName: validManagers[0].PMResource.Name
                            });
                          }
                          await this.calculateHeaderFields(changeRequest, cmSheet);
                          await this.populateCMSheetVersions(cmSheet, 'crCMS');
                          this.setState({
                            isLoading: false,
                            excelDataObsolete: true
                          });
                        }
                      );
                    })
                    .catch(error => {
                      loggerService.writeLog(error, LOG_TYPES.ERROR);
                      this.setState({
                        isLoading: false
                      });
                    });
                }
              }

              if (this.state.action === ACTION_CREATE) {
                getCMSheetbyProjectIdAndType(changeRequest.id, 'ChangeRequest').then(response => {
                  if (response && response.data && response.data.length > 0) {
                    const data = response.data[0];
                    const txtVersion = data.MajorVersion + '.' + data.Version;
                    const item = { Text: txtVersion, Version: data.Version };
                    const cmSheet = data;
                    if (
                      changeRequest &&
                      changeRequest.AllocatedPM &&
                      changeRequest.AllocatedPM.length > 0
                    ) {
                      const crProjectManagers = changeRequest.AllocatedPM;

                      //find valid pms from end date
                      let validManagers = [];
                      validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())));

                      if (validManagers.length === 0 && crProjectManagers.length > 0) {
                        crProjectManagers.sort(function (a, b) {
                          return new Date(b.EndDate) - new Date(a.EndDate)
                        })
                        const lastAssignedManager = crProjectManagers[0];
                        validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate));
                      }
                      this.setState({
                        PMName: validManagers[0].PMResource.Name
                      });
                    }
                      this.setState(
                        {
                          LastVersion: data.Version,
                          selectedVersion: item
                        },
                        async () => {
                          await this.calculateHeaderFields(changeRequest, cmSheet);
                          await this.populateCMSheetVersions(cmSheet, 'crCMS');
                          this.setState({
                            isLoading: false
                          });
                        }
                      );
                  } else {
                    const cmSheetCreatedDate = {
                      createdDate: new Date()
                    };
                    createInitialCMsheet(changeRequest.id, cmSheetCreatedDate)
                      .then(async res => {
                        console.log(
                          'cms getting duplicated - Create Initial CMsheet (CR) - changeRequestId :',
                          changeRequest.id,
                          ' cmSheetCreatedDate:',
                          cmSheetCreatedDate
                        );
                        console.log(
                          'cms getting duplicated - Created Initial CMsheet (CR) :',
                          res.data
                        );
                        const txtVersion = res.data.MajorVersion + '.' + res.data.Version;
                        const item = { Text: txtVersion, Version: res.data.Version };
                        const cmSheet = res.data;
                        if (
                          changeRequest &&
                          changeRequest.AllocatedPM &&
                          changeRequest.AllocatedPM.length > 0
                        ) {
                          const crProjectManagers = changeRequest.AllocatedPM;

                          //find valid pms from end date
                          let validManagers = [];
                          validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())));

                          if (validManagers.length === 0 && crProjectManagers.length > 0) {
                            crProjectManagers.sort(function (a, b) {
                              return new Date(b.EndDate) - new Date(a.EndDate)
                            })
                            const lastAssignedManager = crProjectManagers[0];
                            validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate));
                          }
                          this.setState({
                            PMName: validManagers[0].PMResource.Name
                          });
                        }
                          this.setState(
                            {
                              LastVersion: res.data.Version,
                              selectedVersion: item
                            },
                            async () => {
                              await this.calculateHeaderFields(changeRequest, cmSheet);
                              await this.populateCMSheetVersions(cmSheet, 'crCMS');
                              this.setState({
                                isLoading: false
                              });
                            }
                          );
                      })
                      .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        this.setState({
                          isLoading: false
                        });
                      });
                  }
                }).catch(error => {
                  loggerService.writeLog(error, LOG_TYPES.ERROR);
                  this.setState({
                    isLoading: false
                  });
                });
              }
              this.setState({
                isLoading: false
              });
            }
          );
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.setState({
            isLoading: false
          });
        });
    } else {
      getProject(projectId)
        .then(res => {
          if (res && res.data && res.data.id === projectId) {
            const project = res.data;
            let alreadyActivated = false;
            if (project.StatusConvertedToActive) {
              alreadyActivated = true;
            }
            if (this.state.action === ACTION_VIEW) {
              this.onSaveAuditTrail(res.data, this.state.locationCMSheet.id);
            } //set project details
            const startDateMin = project.StartDate ? reStructureDate(project.StartDate) : new Date();
            this.setState(
              {
                project: project,
                customer: project.Customer,
                selectedRegion: project.BillingRegion,
                selectedCountry: project.CountryId,
                selectedPaymentMethod: project.PaymentMethod.id,
                selectedSalesPerson: project.SalesPerson,
                selectedPartner: project.Partner,
                StartDate: project.StartDate ? reStructureDate(project.StartDate) : null,
                startDateMin: startDateMin,
                lastSelectedCurrencyId:
                  project.Region && project.Region.Currency ? project.Region.Currency.id : 0,
                defaultCurrencyId:
                  project.Region && project.Region.Currency ? project.Region.Currency.id : 0,
                division: project.Division,
                endDate: project.EndDate ? reStructureDate(project.EndDate) : null,
                type: project.Type,
                selectedOffering: project.OfferingId,
                projPractice: project.ProjectPractice,
                alreadyActivated: alreadyActivated
              },
              () => {
                if (this.state.action !== ACTION_CREATE) {
                  const cmSheet = this.state.locationCMSheet;
                  if (cmSheet) {
                    let cmSheetId = cmSheet.id;
                    let cmSheetVersion = cmSheet.Version;
                    if (!cmSheetId) {
                      cmSheetId = cmSheet[0].id;
                    }
                    if (cmSheetVersion === undefined) {
                      cmSheetVersion = cmSheet[0].Version;
                    }
                    getCMSheet(cmSheetId, cmSheetVersion)
                      .then(res => {
                        console.log(
                          'cms getting duplicated - Get Project CMSheet - cmSheetId: ',
                          cmSheetId,
                          ' cmSheetVersion: ',
                          cmSheetVersion
                        );
                        console.log('cms getting duplicated - Project CMSheet :', res.data);
                        const txtVersion = res.data.MajorVersion + '.' + res.data.Version;
                        const item = { Text: txtVersion, Version: res.data.Version };
                        let cmSheetAfterCal = res.data;
                        const totalValuesRA = this.getResourceAllocationsTotalValues(
                          res.data.ResourceAllocations
                        );

                        const otherExpensesTotal = this.getOtherExpensesTotalValues(
                          res.data.OtherExpenses
                        );

                        cmSheetAfterCal.TotalCostResourceAllocation =
                          totalValuesRA.TotalCostResourceAllocation;
                        cmSheetAfterCal.TotalRevenueResourceAllocation =
                          totalValuesRA.TotalRevenueResourceAllocation;
                        cmSheetAfterCal.TotalCostToRevenueRatioResourceAllocation =
                          totalValuesRA.TotalCostToRevenueRatioResourceAllocation;

                        cmSheetAfterCal.TotalEffortDaysResourceAllocationFixed =
                          totalValuesRA.TotalEffortDaysResourceAllocationFixed;

                        cmSheetAfterCal.TotalEffortDaysResourceAllocationTnM =
                          totalValuesRA.TotalEffortDaysResourceAllocationTnM;

                        cmSheetAfterCal.NonBillableResourceTotalCost =
                          totalValuesRA.NonBillableResourceTotalCost;

                        cmSheetAfterCal.NonBillableResourceEffortDays =
                          totalValuesRA.NonBillableResourceEffortDays;

                        cmSheetAfterCal.BillableTotalOtherExpenses = otherExpensesTotal.billTotal;
                        cmSheetAfterCal.NonBillableTotalOtherExpenses =
                          otherExpensesTotal.nonBillTotal;
                        cmSheetAfterCal.OtherExpensesTotal =
                          otherExpensesTotal.billTotal + otherExpensesTotal.nonBillTotal;
                        this.setState(
                          {
                            LastVersion: res.data.Version,
                            selectedVersion: item,
                            pmComment: res.data.PMComment,
                            selectedCmsType: res.data.CmsType
                          },
                          async () => {
                            if (project && project.AllocatedResourceRequestPM && project.AllocatedResourceRequestPM.length > 0) {
                              let projectProjectManagers = project.AllocatedResourceRequestPM;
                              let projValidManagers = [];
                              projValidManagers = project.AllocatedResourceRequestPM.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );
  
                              if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
                                projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
                                const lastAssignedManager = projectProjectManagers[0];
                                projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
                              }
  
                              this.setState({
                                PMName: projValidManagers[0].PMResource.Name
                              });
                            }

                            await this.calculateHeaderFields(project, cmSheetAfterCal);
                            await this.populateCMSheetVersions(cmSheetAfterCal, 'projectCMS');
                            this.setState({
                              isLoading: false,
                              excelDataObsolete: true
                            });
                          }
                        );
                      })
                      .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        this.setState({
                          isLoading: false
                        });
                      });
                  }
                }

                if (this.state.action === ACTION_CREATE) {
                  getCMSheetbyProjectIdAndType(project.id, 'Project').then(response => {
                    if (response && response.data && response.data.length > 0) {
                      const data = response.data[0];
                      const txtVersion = data.MajorVersion + '.' + data.Version;
                      const item = { Text: txtVersion, Version: data.Version };
                      this.setState(
                        {
                          LastVersion: data.Version,
                          selectedVersion: item
                        },
                        async () => {
                          const cmSheet = data;
                          if (project && project.AllocatedResourceRequestPM && project.AllocatedResourceRequestPM.length > 0) {
                            let projectProjectManagers = project.AllocatedResourceRequestPM;
                            let projValidManagers = [];
                            projValidManagers = project.AllocatedResourceRequestPM.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

                            if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
                              projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
                              const lastAssignedManager = projectProjectManagers[0];
                              projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
                            }

                            this.setState({
                              PMName: projValidManagers[0].PMResource.Name
                            });
                          }
                            // await this.LoadResource(project.ResourceId);
                            await this.calculateHeaderFields(project, cmSheet);
                            await this.populateCMSheetVersions(cmSheet, 'projectCMS');
                            this.setState({
                              isLoading: false
                            });
                        }
                      );
                    } else {
                      const cmSheetCreatedDate = {
                        createdDate: new Date()
                      };
                      createCMSheet(project.id, cmSheetCreatedDate)
                        .then(res => {
                          console.log(
                            'cms getting duplicated - Create Initial CMsheet (Project) - ProjectId :',
                            project.id,
                            ' cmSheetCreatedDate: ',
                            cmSheetCreatedDate
                          );
                          console.log(
                            'cms getting duplicated - Created Initial CMsheet (Project) :',
                            res.data
                          );
                          const txtVersion = res.data.MajorVersion + '.' + res.data.Version;
                          const item = { Text: txtVersion, Version: res.data.Version };
                          this.setState(
                            {
                              LastVersion: res.data.Version,
                              selectedVersion: item
                            },
                            async () => {
                              const cmSheet = res.data;
                              if (project && project.AllocatedResourceRequestPM && project.AllocatedResourceRequestPM.length > 0) {
                                let projectProjectManagers = project.AllocatedResourceRequestPM;
                                let projValidManagers = [];
                                projValidManagers = project.AllocatedResourceRequestPM.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

                                if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
                                  projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
                                  const lastAssignedManager = projectProjectManagers[0];
                                  projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
                                }

                                this.setState({
                                  PMName: projValidManagers[0].PMResource.Name
                                });
                              }
                              // await this.LoadResource(project.ResourceId);
                              await this.calculateHeaderFields(project, cmSheet);
                              await this.populateCMSheetVersions(cmSheet, 'projectCMS');
                              this.setState({
                                isLoading: false
                              });
                            }
                          );
                        })
                        .catch(error => {
                          loggerService.writeLog(error, LOG_TYPES.ERROR);
                          this.setState({
                            isLoading: false
                          });
                        });
                    }
                  }).catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                    this.setState({
                      isLoading: false
                    });
                  });            
                }
              }
            );
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.setState({
            isLoading: false
          });
        });
    }
  };

  populateCMSheetVersions = (cmSheet, cmsType) => {
    const list = [];
    let version = cmSheet.Version;
    while (version >= 0) {
      const txtVersion = cmSheet.MajorVersion + '.' + version;
      const item = { Text: txtVersion, Version: version };
      list.push(item);
      version = version - 1;
    }
    console.log(
      'cms getting duplicated - Populate CMSheet Versions - cmSheetVersions :',
      list,
      ' selectedVersion:',
      list[0]
    );

    this.setState(
      {
        cmSheetVersions: list,
        selectedVersion: list[0]
      },
      () => {
        this.processCMSheetData(cmSheet, cmsType);
      }
    );
  };

  toggleVersionChangeDialog = async (event) => {
    if (event.value) {
      if (event.value.Version !== this.state.LastVersion && this.state.selectedVersion.Version === this.state.LastVersion) {
        this.setState({
          showVersionChangeConfirmDialog: !this.state.showVersionChangeConfirmDialog,
          confirmDialogMessage: "This CMS contains unsaved changes. Do you want to view a different version?",
          cmsVersionChangeData: event
        });
      } else {
        this.handleVersionChange(event);
      }
    } else {
      this.setState({
        showVersionChangeConfirmDialog: !this.state.showVersionChangeConfirmDialog
      });
    }
  };

  handleVersionChange = event => {
    const value = event.value;
    const version = value.Version;
    this.setState(
      {
        cmsVersionChangeData: null,
        showVersionChangeConfirmDialog: false,
        selectedVersion: value,
        isViewMode:
          version !== this.state.LastVersion ||
          (this.state.action !== ACTION_CREATE && this.state.action !== ACTION_EDIT)
      },
      () => {
        getCMSheet(this.state.cmSheet.id, version)
          .then(res => {
            console.log(
              'cms getting duplicated - handleVersionChange => getCMSheet - cmSheetId :',
              this.state.cmSheet.id,
              ' cmSheetVersion:',
              version
            );
            let cmsType = '';
            if(res.data.Type === "ChangeRequest") {
              cmsType = 'crCMS'
            } else {
              cmsType = 'projectCMS'
            }
            this.processCMSheetData(res.data, cmsType);
            this.setState({
              excelDataObsolete: true
            });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      }
    );
  };

  processCMSheetData = async (cmSheet, cmsType) => {
    if (cmsType === 'crCMS') {
      this.setState(
        {
          cmSheet: cmSheet,
          StartDate:
            this.state.selectedVersion.Version === this.state.LastVersion
              ? this.state.changeRequest.StartDate
                ? reStructureDate(this.state.changeRequest.StartDate)
                : null
              : cmSheet.StartDate
              ? reStructureDate(cmSheet.StartDate)
              : null,
          selectedPaymentMethod:
            this.state.selectedVersion.Version === this.state.LastVersion
              ? this.state.changeRequest.PaymentMethod.id
              : cmSheet.PaymentMethod,
          selectedSalesPerson:
            this.state.selectedVersion.Version === this.state.LastVersion
              ? this.state.changeRequest.SalesPerson
                ? this.state.changeRequest.SalesPerson.id
                : ''
              : cmSheet.SalesPerson,
          selectedPartner:
            this.state.selectedVersion.Version === this.state.LastVersion
              ? this.state.changeRequest.Partner
                ? this.state.changeRequest.Partner.id
                : ''
              : cmSheet.Partner,
          selectedCmsType: cmSheet.CmsType
        },
        () => {
          if (
            this.state.selectedVersion &&
            this.state.selectedVersion.Version === this.state.LastVersion &&
            this.state.action === ACTION_EDIT
          ) {
            this.checkForMasterDataRatesChanges(false);
          }
        }
      );
    } else {
      this.setState(
        {
          cmSheet: cmSheet,
          StartDate:
            this.state.selectedVersion.Version === this.state.LastVersion
              ? this.state.project.StartDate
                ? reStructureDate(this.state.project.StartDate)
                : null
              : cmSheet.StartDate
              ? reStructureDate(cmSheet.StartDate)
              : null,
          selectedPaymentMethod:
            this.state.selectedVersion.Version === this.state.LastVersion
              ? this.state.project.PaymentMethod
              : cmSheet.PaymentMethod,
          selectedSalesPerson:
            this.state.selectedVersion.Version === this.state.LastVersion
              ? this.state.project.SalesPerson
              : cmSheet.SalesPerson,
          selectedPartner:
            this.state.selectedVersion.Version === this.state.LastVersion
              ? this.state.project.Partner
              : cmSheet.Partner,
          selectedCmsType: cmSheet.CmsType
        },
        () => {
          if (
            this.state.selectedVersion &&
            this.state.selectedVersion.Version === this.state.LastVersion &&
            this.state.action === ACTION_EDIT
          ) {
            this.checkForMasterDataRatesChanges(false);
          }
        }
      );
    }

    if (this.state.alreadyActivated) {
      const projectType = cmsType === 'crCMS' ? "ChangeRequest" : "Project";
      const projectId = cmSheet.ProjectId;
      this.populatePrevCMSResourceRequestsWithTime(projectId, projectType);
      this.getActualOtherExpensesForReviseCMS(cmSheet.id);
    }

    if (this.state.action === ACTION_APPROVE) {
      this.setState({ isViewMode: false, isApproveMode: true });
      this.populateCurrentApprovals(cmSheet.id, cmSheet.Version);
    }

    if (this.state.action === ACTION_REVISECMS_APPROVE || this.state.action === ACTION_VIEW) {
      this.setState({ isApproveMode: true });
      this.populateReviseCmsRequest(cmSheet.id, cmSheet.Version);
    }

    if (
      this.state.action === ACTION_VIEW ||
      this.state.action === ACTION_EDIT ||
      this.state.action === ACTION_REVISE ||
      this.state.action === ACTION_APPROVE ||
      this.state.action === ACTION_REVISECMS_APPROVE
    ) {
      this.populateCurrentApprovals(cmSheet.id, cmSheet.Version);

      let projectOrCR = null;
      if (this.state.cmsReference === 'crCMS') {
        projectOrCR = this.state.changeRequest;
        this.setState({
          selectedSalesPerson:
            cmSheet.ChangeRequest && cmSheet.ChangeRequest.SalesPerson
              ? cmSheet.ChangeRequest.SalesPerson.id
              : null
        });
      } else {
        projectOrCR = this.state.project;
        this.setState({
          selectedSalesPerson:
            cmSheet.Project && cmSheet.Project.SalesPersonRecord
              ? cmSheet.Project.SalesPersonRecord.id
              : null
        });
      }
      this.setState({
        pmComment: cmSheet.PMComment
      });
      if (projectOrCR) {
        this.calculateHeaderFields(projectOrCR, cmSheet);
      }
    }
  };

  LoadResource = async projectManagerId => {
    await getProjectResources()
      .then(response => {
        if (response && response.data && response.data.length > 0) {
          const filteredPMs = response.data.filter(obj => obj.id === projectManagerId);
          if (filteredPMs && filteredPMs.length > 0) {
            this.setState({
              PMName: filteredPMs[0].Name
            });
          }
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  checkForMasterDataRatesChanges = async (fromChild) => {
    let ratesHaveBeenChanged = false;
    let projectOrCR = null;
    let billingRegion = 0;
    let Region = null;
    let projectId = null;
    let changes = {
                    ratesHaveBeenChanged:false,
                    changeData:[]
                  }
    if (this.state.cmsReference === 'crCMS') {
      projectOrCR = this.state.changeRequest;
      billingRegion = this.state.changeRequest.BillingRegion;
      Region = this.state.changeRequest.Region;
      projectId = projectOrCR.ProjectId;
    } else {
      projectOrCR = this.state.project;
      billingRegion = this.state.project.BillingRegion;
      Region = this.state.project.Region;
      projectId = projectOrCR.id;
    }

    if (this.state.cmSheet && projectOrCR) {
      const cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
      // check resource allocations
      if (cmSheet.ResourceAllocations) {
        const allocations = cmSheet.ResourceAllocations;
        for (const allocation of allocations) {
          let alloChanges = {
            id:allocation.id,
            ratesHaveBeenChanged:ratesHaveBeenChanged,
            isRateChange:false,
            isCostChange:false,
          }
          // Check Cost
          if (
            //!(allocation.CostPerOutSourcedResource > 0) &&
            allocation.ResourceRegion &&
            allocation.Designation &&
            billingRegion &&
            allocation.CostPerResource > 0
          ) {
            let costDate = calculateCostDate(allocation, cmSheet, projectOrCR);
            const costCard = {
              billingRegionId: billingRegion,
              resourceRegionId: allocation.ResourceRegion,
              designationId: allocation.Designation,
              costDate: moment(costDate).format('YYYY-MM-DD'),
              projectId: projectId
            };
            await getCost(costCard)
              .then(async res => {
                if (res.data.length > 0 && res.data[0].id > 0) {
                  const costCard = res.data[0];
                  const costRecord = {
                    CurrencyId: costCard.BillingRegion.Currency.id,
                    Overhead: costCard.CostCardDesignations[0].Overhead,
                    CostToCompany: costCard.CostCardDesignations[0].CostToCompany
                  };

                  if (Region && Region.Currency) {
                    // if currency id equals project currency, no need of conversion
                    if (Region.Currency.Code === 'USD') {
                      let costPerResource = costRecord.Overhead + costRecord.CostToCompany;
                      if (allocation.Designation === 57) {
                        costPerResource = costRecord.Overhead + allocation.CostPerOutSourcedResource;
                      }
                      if (roundNumber(costPerResource, 2) !== roundNumber(allocation.CostPerResource, 2)) {
                        ratesHaveBeenChanged = true;
                        allocation.costNoLongerValid = true;
                        alloChanges.isCostChange = true;
                      }else{
                        allocation.costNoLongerValid = false;
                        alloChanges.isCostChange = false
                      }
                      // allocation.CostPerResource = costPerResource;
                    } else {
                      const ratePerUSD = await retrieveConversionRate(
                        Region.Currency.id,
                        new Date(costDate).getMonth() + 1,
                        new Date(costDate).getFullYear()
                      );

                      if (ratePerUSD > 0) {
                        let costPerResource =
                          (costRecord.Overhead + costRecord.CostToCompany) * ratePerUSD;
                        if (allocation.Designation === 57) {
                          const overHeadCost = costRecord.Overhead * ratePerUSD;
                          costPerResource = overHeadCost + allocation.CostPerOutSourcedResource;
                        }
                        if (roundNumber(costPerResource, 2) !== roundNumber(allocation.CostPerResource, 2)) {
                          ratesHaveBeenChanged = true;
                          allocation.costNoLongerValid = true;
                          alloChanges.isCostChange = true;
                        }else{
                          allocation.costNoLongerValid = false;
                          alloChanges.isCostChange = false;
                        }

                        // allocation.CostPerResource = costPerResource;

                        // in here currencyId of costRecord is always 1(USD)
                        // if it changed in future, then it should be converted to usd first as commented below
                        // code is commented in resourceAllocations.js
                      }
                    }
                  }
                } else {
                  let newCostDate = costDate;
                  const costYear = new Date(costDate).getFullYear();
                  const costMonth = new Date(costDate).getMonth() + 1;
                  if (costMonth == 1 || costMonth == 2 || costMonth == 3) { // check if the month is Jan/Feb/March
                    let prevYear = (costYear - 1).toString();
                    newCostDate = prevYear + '-03-31';
                  } else {
                    newCostDate = costYear + '-03-31';
                  }
                  costCard.costDate = moment(newCostDate).format("YYYY-MM-DD"); // previous financial year date
                  await getCost(costCard)
                    .then(async res => {
                      if (res.data.length > 0 && res.data[0].id > 0) {
                        const costCard = res.data[0];
                        const costRecord = {
                          CurrencyId: costCard.BillingRegion.Currency.id,
                          Overhead: costCard.CostCardDesignations[0].Overhead,
                          CostToCompany: costCard.CostCardDesignations[0].CostToCompany
                        };

                        if (Region && Region.Currency) {
                          // if currency id equals project currency, no need of conversion
                          if (Region.Currency.Code === 'USD') {
                            let costPerResource = costRecord.Overhead + costRecord.CostToCompany;
                            if (allocation.Designation === 57) {
                              costPerResource = costRecord.Overhead + allocation.CostPerOutSourcedResource;
                            }
                            if (roundNumber(costPerResource, 2) !== roundNumber(allocation.CostPerResource, 2)) {
                              ratesHaveBeenChanged = true;
                              allocation.costNoLongerValid = true;
                              alloChanges.isCostChange = true;
                            }else{
                              allocation.costNoLongerValid = false;
                              alloChanges.isCostChange = false
                            }
                            // allocation.CostPerResource = costPerResource;
                          } else {
                            const ratePerUSD = await retrieveConversionRate(
                              Region.Currency.id,
                              new Date(costDate).getMonth() + 1,
                              new Date(costDate).getFullYear()
                            );

                            if (ratePerUSD > 0) {
                              let costPerResource =
                                (costRecord.Overhead + costRecord.CostToCompany) * ratePerUSD;
                              if (allocation.Designation === 57) {
                                const overHeadCost = costRecord.Overhead * ratePerUSD;
                                costPerResource = overHeadCost + allocation.CostPerOutSourcedResource;
                              }
                              if (roundNumber(costPerResource, 2) !== roundNumber(allocation.CostPerResource, 2)) {
                                ratesHaveBeenChanged = true;
                                allocation.costNoLongerValid = true;
                                alloChanges.isCostChange = true;
                              }else{
                                allocation.costNoLongerValid = false;
                                alloChanges.isCostChange = false;
                              }

                              // allocation.CostPerResource = costPerResource;

                              // in here currencyId of costRecord is always 1(USD)
                              // if it changed in future, then it should be converted to usd first as commented below
                              // code is commented in resourceAllocations.js
                            }
                          }
                        }
                      } else {
                        ratesHaveBeenChanged = true;
                        allocation.costNoLongerValid = true;
                        alloChanges.isCostChange = true;
                      }
                    })
                    .catch(error => {
                      loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });
                }
              })
              .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });
          }

          // Check Rate
          if (
            allocation.dataValueRateType &&
            billingRegion &&
            allocation.ProjectRole > 0 &&
            (allocation.dataValueRateType.Code === 'REGION_RATE' ||
              allocation.dataValueRateType.Code === 'CUSTOMER_RATE' ||
              allocation.dataValueRateType.Code === 'PROJECT_APPROVED_RATE' ||
              allocation.dataValueRateType.Code === 'PROJECT_APPROVED_CR_RATE')
          ) {
            const costDate = calculateCostDate(allocation, cmSheet, projectOrCR);

            const rateCardParams = {
              customerId: projectOrCR.CustomerId,
              regionId: projectOrCR.BillingRegion,
              role: allocation.ProjectRole,
              projectDate: costDate,
              data: projectOrCR,
              dataType: this.state.cmsReference,
              projectId: projectId
            };

            await getRatesForAllocation(rateCardParams)
              .then(res => {
                let rate = 0;
                // Commenting this code. Might need in the future
                // or might have added this to support a specific scenario
                /* if (res.data.defaultRate === null) {
                   ratesHaveBeenChanged = true;
                   allocation.rateNoLongerValid = true;
                 }*/

                if (res.data) {
                  if (allocation.dataValueRateType.Code === 'PROJECT_APPROVED_CR_RATE') {
                    res.data.projectSpecificCrRate !== null ? rate = res.data.projectSpecificCrRate.Rate : rate = null;
                  } else if (allocation.dataValueRateType.Code === 'PROJECT_APPROVED_RATE') {
                    res.data.projectSpecificRate !== null ? rate = res.data.projectSpecificRate.Rate : rate = null;
                  } else if (allocation.dataValueRateType.Code === 'CUSTOMER_RATE') {
                    if(res.data.customerRate !== null ){
                      if (res.data.customerRate.CustomerId > 0) {
                        rate = res.data.customerRate.Rate;
                      }
                    }else{
                      rate = null;
                    }
                  } else if (allocation.dataValueRateType.Code === 'REGION_RATE') {
                    res.data.defaultRate !== null ? rate = res.data.defaultRate.Rate : rate = null;
                  }
                }

                if(this.checkSelectedCurrency()){
                  rate = this.calculateNewRate(rate, this.state.prevRate, this.state.newRate);
                }

                if (allocation.RateValue !== rate) {
                  ratesHaveBeenChanged = true;
                  allocation.rateNoLongerValid = true
                  alloChanges.isRateChange = true;
                }else{
                  allocation.rateNoLongerValid = false;
                  alloChanges.isRateChange = false;
                }
              })
              .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });
            }
            changes.changeData.push(alloChanges)
        }
      }

      // Check Other expenses Rates
      if (cmSheet.OtherExpenses) {
        const expenses = cmSheet.OtherExpenses;
        for (const expense of expenses) {
          if (
            expense.dataValueExpenseType &&
            expense.ResourceRegion > 0 &&
            expense.BudgetedRate === expense.StandardRate
          ) {
            if (
              // in phase 1 consider only Air Tickets, Per Diem, Accomadation
              expense.dataValueExpenseType.Code === 'AIR_TICKETS' ||
              expense.dataValueExpenseType.Code === 'PER_DIEM' ||
              expense.dataValueExpenseType.Code === 'ACCOMMODATION'
            ) {
              const rate = await this.retrieveBudgetedRate(
                expense.ExpenseTypeId,
                this.state.project.CountryId,
                expense.dataValueExpenseType.Code === 'AIR_TICKETS' ? expense.ResourceRegion : 0
              );

              if (rate !== expense.StandardRate) {
                ratesHaveBeenChanged = true;
                expense.rateNoLongerValid = true;
              }
            }
          }
        }
      }

      !fromChild && this.setState(
        {
          cmSheet: cmSheet
        },
        () => {
          if (ratesHaveBeenChanged) {
            this.handleUpdateCMSheetModel(this.state.cmSheet, true);
            const message = "Cost/Rate cards have been updated. Do you want to update the CMS with the new values? Selecting 'No' will reset the rows with the updated values.";
            this.setState({
              reSetCostValue:ratesHaveBeenChanged,
              showSuccessImage: false,
              showErrorImage: true
            },()=>{
              this.toggleCostRateChangedConfirmDialog(message);
            })
          }
        }
      );
    }
    changes.ratesHaveBeenChanged = ratesHaveBeenChanged;
    return changes;
  };

  retrieveBudgetedRate = async (expenseType, projectCountry, resourceRegion) => {
    const rate = await getBudgetedRate(expenseType, projectCountry, resourceRegion)
      .then(async res => {
        if (res.data && res.data.length > 0) {
          const rateRecord = res.data[0];
          if (
            this.state.project.Region &&
            this.state.project.Region.Currency &&
            this.state.project.Region.Currency.id > 0
          ) {
            if (rateRecord.CurrencyId !== this.state.project.Region.Currency.id) {
              // currency conversion
              const proStartDate = reStructureDate(this.state.project.StartDate);
              // rate record currency, usd
              const rateRecordCurrencyRate = await retrieveConversionRate(
                rateRecord.CurrencyId,
                proStartDate.getMonth() + 1,
                proStartDate.getFullYear()
              );
              if (rateRecordCurrencyRate > 0) {
                // project currency, usd
                const projectCurrencyRate = await retrieveConversionRate(
                  this.state.project.Region.Currency.id,
                  proStartDate.getMonth() + 1,
                  proStartDate.getFullYear()
                );
                if (projectCurrencyRate > 0) {
                  return (rateRecord.Rate / rateRecordCurrencyRate) * projectCurrencyRate;
                }
                return 0; // conversion rate not found for project currency
              }
              return 0; // conversion rate not found for rate record currency
            } else {
              return rateRecord.Rate;
            }
          } else {
            return -1; // region currency not found
          }
        } else {
          return -1; // budgeted rate not found
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        return -2; // error
      });

    return rate;
  };

  handleCurrencyChange = async (prevRate, newRate, currencyId) => {
    let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
    cmSheet = await this.calculateValuesOnCurrencyChange(cmSheet, prevRate, newRate);
    if (cmSheet) {
      this.setState({
        cmSheet: cmSheet,
        lastSelectedCurrencyId: currencyId,
        prevRate:prevRate,
        newRate:newRate
      });
    }
  };

  checkSelectedCurrency=()=>{
    if(this.state.defaultCurrencyId !== this.state.lastSelectedCurrencyId){
      return true
    }else{
      return false
    }
  }

  approvalChildMethod = () => {
    return [];
  };

  childMethod = () => {
    return [];
  };
  
  populateData = () => {
    return [];
  };

  setChildMethod = newMethod => {
    this.childMethod = newMethod;
  };
  
  setApprovalChildMethod = newMethod => {
    this.approvalChildMethod = newMethod;
  };
  
  setPopulateData = newMethod => {
    this.populateData = newMethod;
  };

  changeCostRateUpdateStatus=()=>{
    this.setState({
      reSetCostValue :false
    })
  }
  
  changeOutsideFunctionStatus=()=>{
    this.setState({
      resetAndSaveFromOutSide :false,
      saveFromOutSide :false,
      approveUFromOutSide :false,
      approveRFromOutSide :false,
    })
  }

  calculateValuesOnCurrencyChange = async (cmSheet, prevRate, newRate) => {
    let result = null;

    // update resource allocations
    const resourceAllocations = this.state.cmSheet.ResourceAllocations.slice();

    let totalCostResourceAllocation = 0;
    let totalRevenueResourceAllocation = 0;
    let totalEffortDaysResourceAllocation = 0;
    let totalCostToRevenueRatioResourceAllocation = 0;

    for (let allocation of resourceAllocations) {
      allocation.TotalCost = Number(allocation.TotalCost.toFixed(6));
      allocation.RateValue = this.calculateNewRate(allocation.RateValue, prevRate, newRate);
      allocation.TotalCost = this.calculateNewRate(allocation.TotalCost, prevRate, newRate);
      allocation.TotalRevenue = this.calculateNewRate(allocation.TotalRevenue, prevRate, newRate);
      allocation.StandardRate = this.calculateNewRate(allocation.StandardRate, prevRate, newRate);
      allocation.CostToRevenueRatio = allocation.TotalCost / allocation.TotalRevenue;
      if (allocation.CostPerOutSourcedResource > 0) {
        allocation.CostPerOutSourcedResource =
          (allocation.CostPerOutSourcedResource / prevRate) * newRate;
      }

      totalCostResourceAllocation = totalCostResourceAllocation + allocation.TotalCost;
      totalRevenueResourceAllocation = totalRevenueResourceAllocation + allocation.TotalRevenue;
      totalEffortDaysResourceAllocation =
        totalEffortDaysResourceAllocation + allocation.TotalEffortDays;
      totalCostToRevenueRatioResourceAllocation =
        totalCostResourceAllocation / totalRevenueResourceAllocation;
    }

    // update other expenses
    const otherExpenses = this.state.cmSheet.OtherExpenses.slice();

    let nonBillTotal = 0;
    let billTotal = 0;

    for (let expense of otherExpenses) {
      expense.BudgetedRate = this.calculateNewRate(expense.BudgetedRate, prevRate, newRate);
      expense.Cost = this.calculateNewRate(expense.Cost, prevRate, newRate);

      if (expense.BillableToClient === true && expense.Cost) {
        billTotal = billTotal + expense.Cost;
      }

      if (expense.BillableToClient === false && expense.Cost) {
        nonBillTotal = nonBillTotal + expense.Cost;
      }
    }

    // update cm sheet
    cmSheet.TotalCostResourceAllocation = totalCostResourceAllocation;
    cmSheet.TotalRevenueResourceAllocation = totalRevenueResourceAllocation;
    cmSheet.TotalEffortDaysResourceAllocation = totalEffortDaysResourceAllocation;
    cmSheet.TotalCostToRevenueRatioResourceAllocation = totalCostToRevenueRatioResourceAllocation;
    cmSheet.ResourceAllocations = resourceAllocations;

    cmSheet.BillableTotalOtherExpenses = billTotal;
    cmSheet.NonBillableTotalOtherExpenses = nonBillTotal;
    cmSheet.OtherExpensesTotal = billTotal + nonBillTotal;
    cmSheet.OtherExpenses = otherExpenses;

    // Other calculation values
    cmSheet.ProjectRevenueAfterDiscount = this.calculateNewRate(
      cmSheet.ProjectRevenueAfterDiscount,
      prevRate,
      newRate
    );
    cmSheet.NetProfitLoss = this.calculateNewRate(cmSheet.NetProfitLoss, prevRate, newRate);
    cmSheet.AverageRatePerDay = this.calculateNewRate(cmSheet.AverageRatePerDay, prevRate, newRate);
    cmSheet.PartnerCommission = this.calculateNewRate(cmSheet.PartnerCommission, prevRate, newRate);
    cmSheet.ContingencyValue = this.calculateNewRate(cmSheet.ContingencyValue, prevRate, newRate);
    cmSheet.DiscountValue = this.calculateNewRate(cmSheet.DiscountValue, prevRate, newRate);
    cmSheet.ServiceRevenueValue = this.calculateNewRate(
      cmSheet.ServiceRevenueValue,
      prevRate,
      newRate
    );
    cmSheet.APSProfitability = this.calculateNewRate(cmSheet.APSProfitability, prevRate, newRate);
    cmSheet.RateDiscount = this.calculateNewRate(cmSheet.RateDiscount, prevRate, newRate);
    cmSheet.RevenueAfterDiscountQuotedToCustomer = this.calculateNewRate(
      cmSheet.RevenueAfterDiscountQuotedToCustomer,
      prevRate,
      newRate
    );
    cmSheet.EstimatedOPECustomer = this.calculateNewRate(
      cmSheet.EstimatedOPECustomer,
      prevRate,
      newRate
    );
    cmSheet.TotalProjectValue = this.calculateNewRate(cmSheet.TotalProjectValue, prevRate, newRate);
    cmSheet.NetDiscountValue = this.calculateNewRate(cmSheet.NetDiscountValue, prevRate, newRate);
    cmSheet.GrossProfitFromResourceDeployment = this.calculateNewRate(
      cmSheet.GrossProfitFromResourceDeployment,
      prevRate,
      newRate
    );
    cmSheet.salesCommision = this.calculateNewRate(cmSheet.salesCommision, prevRate, newRate);
    cmSheet.NonBillableResourceTotalCost = this.calculateNewRate(cmSheet.NonBillableResourceTotalCost, prevRate, newRate);
    result = cmSheet;
    return result;
  };

  calculateNewRate = (value, prevRate, newRate) => {
    return value ? (value / prevRate) * newRate : 0;
  };

  handlePaymentMethodChange = (pmId, cmSheetReference) => {
    if (cmSheetReference === 'crCMS') {
      this.setState(prevState => {
        return {
          selectedPaymentMethod: pmId,
          changeRequest: { ...prevState.changeRequest, PaymentMethod: pmId, PaymentMethodId: pmId },
          hasUnSavedData: true
        };
      });
    } else {
      this.setState(prevState => {
        return {
          selectedPaymentMethod: pmId,
          project: { ...prevState.project, PaymentMethod: pmId },
          hasUnSavedData: true
        };
      });
    }
  };

  handleSalesPersonChange = (spId, cmsSheetRef) => {
    if (cmsSheetRef === 'crCMS') {
      this.setState(
        prevState => {
          return {
            selectedSalesPerson: spId,
            SalesPersonId: spId,
            changeRequest: { ...prevState.changeRequest, SalesPerson: spId, SalesPersonId: spId }
          };
        },
        () => {
          let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
          this.calculateHeaderFields(this.state.changeRequest, cmSheet);
          this.setState({
            cmSheet: cmSheet,
            hasUnSavedData: true
          });
        }
      );
    } else {
      this.setState(
        prevState => {
          return {
            selectedSalesPerson: spId,
            project: { ...prevState.project, SalesPerson: spId }
          };
        },
        () => {
          let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
          this.calculateHeaderFields(this.state.project, cmSheet);
          this.setState({
            cmSheet: cmSheet,
            hasUnSavedData: true
          });
        }
      );
    }
  };

  handelPartnerChange = (pId, cmsReference) => {
    if (cmsReference === 'crCMS') {
      this.setState(
        prevState => {
          return {
            selectedPartner: pId,
            changeRequest: { ...prevState.changeRequest, Partner: pId, PartnerId: pId }
          };
        },
        () => {
          let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
          this.calculateHeaderFields(this.state.changeRequest, cmSheet);
          this.setState({
            cmSheet: cmSheet,
            hasUnSavedData: true
          });
        }
      );
    } else {
      this.setState(
        prevState => {
          return {
            selectedPartner: pId,
            project: { ...prevState.project, Partner: pId }
          };
        },
        () => {
          let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
          this.calculateHeaderFields(this.state.project, cmSheet);
          this.setState({
            cmSheet: cmSheet,
            hasUnSavedData: true
          });
        }
      );
    }
  };

  onStartDateChange = async (event, cmSheetRef) => {
    let startDate = null;

    if (cmSheetRef === 'crCMS') {
      if (event.target.value === '') {
        this.state.changeRequest.EstimatedStartDate = new Date();
        startDate = null;
      } else {
        this.state.changeRequest.EstimatedStartDate = event.target.value;
        startDate = event.target.value;
      }

      let startDateStr = '';
      let endDate = null;
      let endDateStr = '';
      if (this.isAPS()) {
        if (startDate) {
          startDateStr = getFormattedDate(startDate);
          endDate = reStructureDate(startDate.valueOf());
          endDate.setFullYear(endDate.getFullYear() + 1);
          endDate.setDate(endDate.getDate() - 1);
          endDateStr = getFormattedDate(endDate);
        }
      }

      this.setState(
        prevState => {
          return {
            StartDate: startDate,
            endDate: endDate,
            changeRequest: {
              ...prevState.changeRequest,
              StartDate: startDateStr === '' ? startDate : startDateStr,
              EndDate: endDateStr === '' ? endDate : endDateStr
            },
            hasUnSavedData: true,
            infoTabStartDateChanged:
              this.isAPS() || this.isResourceAug() || this.isBlanketWorkOrder() ? true : false
          };
        },
        async () => {
          let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
          for (const allocation of cmSheet.ResourceAllocations) {
            if (allocation.OnsiteStatus) {
              let countryID = null;
              if (allocation.OnsiteStatus === 'Onsite') {
                countryID = this.state.changeRequest.CountryId;
              } else {
                countryID = allocation.dataValueResourceRegion.DefaultCountryId;
              }
    
              let { endDate, noOfWorkingDays, totalDays } = await calculateProjectEndDate(
                allocation,
                startDate,
                countryID
              );
    
              allocation.EstimatedEndDate = moment(reStructureDate(endDate)).format('YYYY-MM-DD');
            }
          }
          this.calculateHeaderFields(this.state.changeRequest, cmSheet);
          this.setState({
            cmSheet: cmSheet,
            hasUnSavedData: true
          });
        }
      );
    } else {
      if (event.target.value === '' || event.target.value === null) {
        this.state.project.EstimatedStartDate = new Date();
        startDate = null;
      } else {
        this.state.project.EstimatedStartDate = event.target.value;
        startDate = event.target.value;
      }

      let startDateStr = '';
      let endDate = null;
      let endDateStr = '';

      if (this.isAPS()) {
        if (startDate) {
          startDateStr = getFormattedDate(startDate);
          endDate = reStructureDate(startDate.valueOf());
          endDate.setFullYear(endDate.getFullYear() + 1);
          endDate.setDate(endDate.getDate() - 1);
          endDateStr = getFormattedDate(endDate);
        }
      }

      this.setState(
        prevState => {
          return {
            StartDate: startDate,
            endDate: endDate,
            project: {
              ...prevState.project,
              StartDate: startDateStr === '' ? startDate : startDateStr,
              EndDate: endDateStr === '' ? endDate : endDateStr
            },
            hasUnSavedData: true,
            infoTabStartDateChanged:
              this.isAPS() || this.isResourceAug() || this.isBlanketWorkOrder() ? true : false
          };
        },
        async () => {       
          let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
          for (const allocation of cmSheet.ResourceAllocations) {
            if (allocation.OnsiteStatus) {
              let countryID = null;
              if (allocation.OnsiteStatus === 'Onsite') {
                countryID = this.state.project.CountryId;
              } else {
                countryID = allocation.dataValueResourceRegion.DefaultCountryId;
              }
    
              let { endDate, noOfWorkingDays, totalDays } = await calculateProjectEndDate(
                allocation,
                startDate,
                countryID
              );
    
              allocation.EstimatedEndDate = moment(reStructureDate(endDate)).format('YYYY-MM-DD');
            }
          }
          this.calculateHeaderFields(this.state.project, cmSheet);
          this.setState({
            cmSheet: cmSheet,
            hasUnSavedData: true
          });
        }
      );
    }
  };

  IsStartDateChange=(oldSDate,newSDate)=>{
    if(oldSDate === newSDate || this.isAPS() || this.isResourceAug() || this.isBlanketWorkOrder()){
      this.setState({
        startDateChanged:false
      })
    }else{
      if (this.state.cmSheet.ResourceAllocations && this.state.cmSheet.ResourceAllocations.length > 0) {
        this.setState({
          startDateChanged:true
        })
      }    
    }
  }

  toggleDateChangeDialog=()=>{
    this.setState({
      startDateChanged:!this.state.startDateChanged
    })
  }

  onDepartmentChange = (event, cmSheetRef) => {
    let startDate = null;

    const division = event.target.value;

    if (cmSheetRef === 'crCMS') {
      if (division && this.state.type && ((division.id === 30 && this.state.type.id === 3) || this.state.type.id === 5 || this.state.type.id === 6)) {
        if (this.state.changeRequest.StartDate && this.state.changeRequest.EndDate === null) {
          let endDate = reStructureDate(this.state.changeRequest.StartDate.valueOf());
          endDate.setFullYear(endDate.getFullYear() + 1);
          endDate.setDate(endDate.getDate() - 1);
          this.setState({
            endDate: endDate
          });
          this.state.changeRequest.EndDate = endDate;
        }

        this.setState({
          infoTabStartDateChanged: true
        });
      } else {
        this.state.changeRequest.EndDate = null;

        this.setState({
          infoTabStartDateChanged: false
        });
      }
    } else {
      if (division && this.state.type && ((division.id === 30 && this.state.type.id === 3) || this.state.type.id === 5 || this.state.type.id === 6)) {
        if (this.state.project.StartDate && this.state.project.EndDate === null) {
          let endDate = reStructureDate(this.state.project.StartDate.valueOf());
          endDate.setFullYear(endDate.getFullYear() + 1);
          endDate.setDate(endDate.getDate() - 1);
          this.setState({
            endDate: endDate
          });
          this.state.project.EndDate = endDate;
        }

        this.setState({
          infoTabStartDateChanged: true
        });
      } else {
        this.state.project.EndDate = null;

        this.setState({
          infoTabStartDateChanged: false
        });
      }
    }

    if (this.isAPS()) {
      this.setState({
        apsTypeHasChanged: true
      });
    } else {
      this.setState({
        apsTypeHasChanged: false
      });
    }

    if (cmSheetRef === 'crCMS') {
      this.setState(prevState => {
        return {
          division: division,
          changeRequest: {
            ...prevState.changeRequest,
            BillingDivision: division,
            BillingDivisionId: division.id,
            PracticeId: null
          },
          hasUnSavedData: true,
          projPractice: null
        };
      });
    } else {
      this.setState(prevState => {
        return {
          division: division,
          project: {
            ...prevState.project,
            Division: division,
            BillingDivision: division.id,
            PracticeId: null
          },
          hasUnSavedData: true,
          projPractice: null
        };
      });
    }
  };

  onProjPracticeChange = async (event, cmSheetRef) => {
    let practice = event.target.value;
    if (cmSheetRef === 'crCMS') {
      this.setState(prevState => {
        return {
          projPractice: practice,
          changeRequest: {
            ...prevState.changeRequest,
            PracticeId: practice.id
          },
          hasUnSavedData: true
        };
      });
    } else {
      this.setState(prevState => {
        return {
          projPractice: practice,
          project: {
            ...prevState.project,
            PracticeId: practice.id
          },
          hasUnSavedData: true
        };
      });
    }
  }

  onEngagementTypeChange = async (event, cmSheetRef) => {
    let startDate = null;
    let type = event.target.value;

    if (cmSheetRef === 'crCMS') {
      if (this.state.division && type && this.state.division.id === 30 && type.id === 3) {
        if ((await this.state.changeRequest.StartDate) && this.state.changeRequest.EndDate === '') {
          let endDate = reStructureDate(this.state.changeRequest.StartDate.valueOf());
          endDate.setFullYear(endDate.getFullYear() + 1);
          endDate.setDate(endDate.getDate() - 1);
          this.setState({
            endDate: endDate
          });
          this.state.changeRequest.EndDate = endDate;
        }
      } else {
        this.state.changeRequest.EndDate = '';
      }
    } else {
      if ((await this.state.division) && type && this.state.division.id === 30 && type.id === 3) {
        if (this.state.project.StartDate && this.state.project.EndDate === '') {
          let endDate = reStructureDate(this.state.project.StartDate.valueOf());
          endDate.setFullYear(endDate.getFullYear() + 1);
          endDate.setDate(endDate.getDate() - 1);
          this.setState({
            endDate: endDate
          });
          this.state.project.EndDate = endDate;
        }
      } else {
        if (type.id === 5 || type.id === 6) {
          this.state.project.EndDate = this.state.endDate;
        } else {
          if (this.state.project.StartDate && this.state.project.EndDate !== '') {
            this.state.project.EndDate = '';
          }
        }
      }
    }

    if (type && !((this.state.division.id === 30 && type.id === 3) || type.id === 5 || type.id === 6)) {
      let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
      for (const allocation of cmSheet.ResourceAllocations) {      
        allocation.EndDate = null;
        allocation.StartDateMax = null;
      }
      this.setState({
        cmSheet: cmSheet,
        endDate: null
      });
    }

    if (this.isAPS()) {
      this.setState({
        apsTypeHasChanged: true
      });
    } else {
      this.setState({
        apsTypeHasChanged: false
      });
    }

    if (this.isResourceAug()) {
      this.setState({
        augTypeHasChanged: true
      });
    } else {
      this.setState({
        augTypeHasChanged: false
      });
    }

    if (this.isBlanketWorkOrder()) {
      this.setState({
        blanketTypeHasChanged: true
      });
    } else {
      this.setState({
        blanketTypeHasChanged: false
      });
    }

    if (cmSheetRef === 'crCMS') {
      this.setState(prevState => {
        return {
          type: type,
          changeRequest: {
            ...prevState.changeRequest,
            Type: type,
            ProjectTypeId: type.id
          },
          hasUnSavedData: true
        };
      });
    } else {
      this.setState(prevState => {
        return {
          type: type,
          project: {
            ...prevState.project,
            Type: type,
            ProjectType: type.id
          },
          hasUnSavedData: true
        };
      });
    }
  };

  onOfferingChange = async (event, cmSheetRef) => {
    let offering = event.target.value;
    if (cmSheetRef === 'crCMS') {
      this.setState(prevState => {
        return {
          selectedOffering: offering.id,
          changeRequest: {
            ...prevState.changeRequest,
            OfferingId: offering.id
          },
          hasUnSavedData: true
        };
      });
    } else {
      this.setState(prevState => {
        return {
          selectedOffering: offering.id,
          project: {
            ...prevState.project,
            OfferingId: offering.id
          },
          hasUnSavedData: true
        };
      });
    }
  }

  onEndDateChange = async (event, cmSheetRef) => {
    let newEndDate = null;

    if (cmSheetRef === 'crCMS') {
      if (event.target.value !== '') {
        newEndDate = event.target.value;
      }

      let newEndDateStr = '';
      if (newEndDate) {
        newEndDateStr = getFormattedDate(newEndDate);
      }

      await this.setState(
        prevState => {
          return {
            endDate: newEndDate,
            changeRequest: {
              ...prevState.changeRequest,
              EndDate: newEndDateStr === '' ? newEndDate : newEndDateStr
            },
            hasUnSavedData: true,
            infoTabStartDateChanged:
              this.isAPS() ||
              this.isResourceAug() ||
              (this.isBlanketWorkOrder() && this.state.infoTabStartDateChanged === true)
                ? true
                : false
          };
        },
        () => {
          let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
          this.calculateHeaderFields(this.state.changeRequest, cmSheet);
          this.setState({
            cmSheet: cmSheet,
            hasUnSavedData: true
          });
        }
      );
    } else {
      if (event.target.value !== '') {
        newEndDate = event.target.value;
      }
      let newEndDateStr = '';
      if (newEndDate) {
        newEndDateStr = getFormattedDate(newEndDate);
      }
      this.setState(
        prevState => {
          return {
            endDate: newEndDate,
            project: {
              ...prevState.project,
              EndDate: newEndDateStr === '' ? newEndDate : newEndDateStr
            },
            hasUnSavedData: true,
            infoTabStartDateChanged:
              this.isAPS() ||
              this.isResourceAug() ||
              (this.isBlanketWorkOrder() && this.state.infoTabStartDateChanged === true)
                ? true
                : false
          };
        },
        () => {
          let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
          this.calculateHeaderFields(this.state.project, cmSheet);
          this.setState({
            cmSheet: cmSheet,
            hasUnSavedData: true
          });
        }
      );
    }
  };

  handleDiscountChange = async event => {
    this.state.cmSheet.DiscountPercentage = null;
    const value = event.target.value;
    this.setState({
      isLoading: true
    });
    let disPerc = 0;

    if (event.target.name === 'DiscountPercentage') {
      disPerc = Number(value);
      disPerc = disPerc.toFixed(2);
    } else {
      const divider =
        this.state.cmSheet.TotalRevenueResourceAllocation +
        this.state.cmSheet.ContingencyValue +
        Number(this.state.cmSheet.ServiceRevenueValue);
      if (divider > 0) {
        disPerc = (value / divider) * 100;
        disPerc = disPerc;
      }
    }

    const cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
    cmSheet.DiscountPercentage = disPerc;

    if (this.state.cmsReference === 'crCMS') {
      await this.calculateHeaderFields(this.state.changeRequest, cmSheet);
    } else {
      await this.calculateHeaderFields(this.state.project, cmSheet);
    }

    this.setState(prevState => {
      return {
        cmSheet: cmSheet,
        hasUnSavedData: true,
        isLoading: false
      };
    });
  };

  handleServiceRevenueChange = async event => {
    const value = event.target.value;
    this.setState({
      isLoading: true
    });

    const cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
    cmSheet.ServiceRevenueValue = Number(value).toFixed(2);

    if (this.state.cmsReference === 'crCMS') {
      await this.calculateHeaderFields(this.state.changeRequest, cmSheet);
    } else {
      await this.calculateHeaderFields(this.state.project, cmSheet);
    }

    this.setState(prevState => {
      return {
        cmSheet: cmSheet,
        hasUnSavedData: true,
        isLoading: false
      };
    });
  };

  handleAPSProfitabilityChange = async event => {
    const value = event.target.value;
    this.setState({
      isLoading: true
    });

    const cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
    if (value === '') {
      cmSheet.APSProfitability = Number(0).toFixed(2);
    } else {
      cmSheet.APSProfitability = Number(value).toFixed(2);
    }

    if (this.state.cmsReference === 'crCMS') {
      await this.calculateHeaderFields(this.state.changeRequest, cmSheet);
    } else {
      await this.calculateHeaderFields(this.state.project, cmSheet);
    }

    this.setState(prevState => {
      return {
        cmSheet: cmSheet,
        hasUnSavedData: true,
        isLoading: false
      };
    });
  };

  handleContingencyChange = async event => {
    this.state.cmSheet.ContingencyPercentage = null;
    this.state.cmSheet.ContingencyValue = null;
    const value = event.target.value;
    this.setState({
      isLoading: true
    });
    let conPerc = Number(value);

    const cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
    conPerc = conPerc.toFixed(2);

    cmSheet.ContingencyPercentage = conPerc;

    if (this.state.cmsReference === 'crCMS') {
      await this.calculateHeaderFields(this.state.changeRequest, cmSheet);
    } else {
      await this.calculateHeaderFields(this.state.project, cmSheet);
    }

    this.setState(prevState => {
      return {
        cmSheet: cmSheet,

        hasUnSavedData: true,
        isLoading: false
      };
    });
  };

  handleCommentChange = comment => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let Comment = comment.replace(/(<([^>]+)>)/gi, '');
      let prevComment = this.state.pmComment ? this.state.pmComment : this.state.PMName;

      if (Comment.trim() == '') {
        let pmName = this.state.PMName;
        this.setState({
          pmComment: pmName,
          hasUnSavedData: true
        });
      } else {
        let isCommentEdit = false;

        if (this.state.action == 'edit') {
          if (comment !== prevComment) {
            isCommentEdit = true;
          } else {
            isCommentEdit = false;
          }
        }

        this.setState({
          pmComment: comment
        });

        if (
          this.state.action == 'view' ||
          (this.state.action == 'edit' && !isCommentEdit) ||
          this.state.action == 'approve'
        ) {
          this.setState({
            hasUnSavedData: false
          });
        } else {
          this.setState({
            hasUnSavedData: true
          });
        }
      }
    }, 1000);
  };

  handleCommentChangeDiscount = comment => {
    this.setState(prevState => {
      return {
        cmSheet: { ...prevState.cmSheet, DiscountComment: comment },
        hasUnSavedData: true
      };
    });
  };

  handleCommentChangeContingency = comment => {
    this.setState(prevState => {
      return {
        cmSheet: { ...prevState.cmSheet, ContingencyComment: comment },
        hasUnSavedData: true
      };
    });
  };

  handleCommentChangeServiceRevenue = comment => {
    this.setState(prevState => {
      return {
        cmSheet: { ...prevState.cmSheet, ServiceRevenueComment: comment },
        hasUnSavedData: true
      };
    });
  };

  handleCommentAPSProfitability = comment => {
    this.setState(prevState => {
      return {
        cmSheet: { ...prevState.cmSheet, APSProfitabilityComment: comment },
        hasUnSavedData: true
      };
    });
  };

  handleTabSelect = e => {
    if (e.selected === 1) {
      this.setState({
        isInfoTab: true
      });
    } else if (e.selected === 2) {
      if (this.isAPS() || this.isResourceAug() || this.isBlanketWorkOrder()) {
        const startDate = this.state.StartDate ? true : false;
        const endDate = this.state.endDate ? true : false;
        if (startDate === false || endDate === false) {
          const message = 'Please fill all mandatory values in Info tab';
          const title = 'Error';
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleMessageDialog(message, title);
          return;
        }
      }
    } else {
      this.setState({
        isInfoTab: false
      });
    }
    this.setState({ selected: e.selected });
  };

  cmsSaveBtnDisabled = async (action) => {
    await this.setState({
      ongoingRowCalculation: action
    });
  }

  handleUpdateCMSheetModel = async (cmSheet, isUpdatePhases) => {
    if (isUpdatePhases === true) {
      // check newly added resource allocations
      const resourceAllocations = [];
      for (const allocation of this.state.cmSheet.ResourceAllocations) {
        let tempAllocation = allocation;
        if (allocation.OnsiteStatus === 1) {
          tempAllocation.OnsiteStatus = allocation.OnsiteStatuses[0].Name;
          tempAllocation.dataValueOnsiteStatus = allocation.OnsiteStatuses[0];
        } else if (allocation.OnsiteStatus === 2) {
          tempAllocation.OnsiteStatus = allocation.OnsiteStatuses[1].Name;
          tempAllocation.dataValueOnsiteStatus = allocation.OnsiteStatuses[1];
        }

        if (allocation.PhaseId > 0) {
          const phaseFilter = cmSheet.Phases.filter(obj => obj.id === allocation.PhaseId);
          if (phaseFilter.length > 0) {
            if (allocation.SubPhaseId > 0) {
              const subPhases = phaseFilter[0].SubPhases;
              const subPhaseFilter = subPhases.filter(obj => obj.id === allocation.SubPhaseId);
              if (subPhaseFilter.length > 0) {
                resourceAllocations.push(tempAllocation);
              }
            } else {
              resourceAllocations.push(tempAllocation);
            }
          }
        } else {
          resourceAllocations.push(tempAllocation);
        }
      }

      // check newly added other expenses
      const otherExpenses = [];
      for (const expense of this.state.cmSheet.OtherExpenses) {
        if (expense.PhaseId > 0) {
          const phaseFilter = cmSheet.Phases.filter(obj => obj.id === expense.PhaseId);
          if (phaseFilter.length > 0) {
            if (expense.SubPhaseId > 0) {
              const subPhases = phaseFilter[0].SubPhases;
              const subPhaseFilter = subPhases.filter(obj => obj.id === expense.SubPhaseId);
              if (subPhaseFilter.length > 0) {
                otherExpenses.push(expense);
              }
            } else {
              otherExpenses.push(expense);
            }
          }
        } else {
          otherExpenses.push(expense);
        }
      }

      // set cm sheet fields to cm sheet
      cmSheet.ResourceAllocations = resourceAllocations;
      const totalValuesRA = this.getResourceAllocationsTotalValues(resourceAllocations);
      cmSheet.TotalCostResourceAllocation = totalValuesRA.TotalCostResourceAllocation;
      cmSheet.TotalRevenueResourceAllocation = totalValuesRA.TotalRevenueResourceAllocation;
      cmSheet.TotalEffortDaysResourceAllocation = totalValuesRA.TotalEffortDaysResourceAllocation;
      cmSheet.TotalCostToRevenueRatioResourceAllocation =
        totalValuesRA.TotalCostToRevenueRatioResourceAllocation;
      cmSheet.TotalEffortDaysResourceAllocationFixed =
        totalValuesRA.TotalEffortDaysResourceAllocationFixed;
      cmSheet.TotalEffortDaysResourceAllocationTnM =
        totalValuesRA.TotalEffortDaysResourceAllocationTnM;
      cmSheet.NonBillableResourceTotalCost = totalValuesRA.NonBillableResourceTotalCost;
      cmSheet.NonBillableResourceEffortDays = totalValuesRA.NonBillableResourceEffortDays;

      // set other expenses fields to cm sheet
      cmSheet.OtherExpenses = otherExpenses;
      const totalValuesOE = this.getOtherExpensesTotalValues(otherExpenses);
      cmSheet.BillableTotalOtherExpenses = totalValuesOE.billTotal;
      cmSheet.NonBillableTotalOtherExpenses = totalValuesOE.nonBillTotal;
      cmSheet.OtherExpensesTotal = totalValuesOE.billTotal + totalValuesOE.nonBillTotal;
    } else {
      // check newly added resource allocations
      const resourceAllocations = [];
      for (const allocation of cmSheet.ResourceAllocations) {
        let tempAllocation = allocation;
        if (allocation.OnsiteStatus === 1) {
          tempAllocation.OnsiteStatus = allocation.OnsiteStatuses[0].Name;
          tempAllocation.dataValueOnsiteStatus = allocation.OnsiteStatuses[0];
          resourceAllocations.push(tempAllocation);
        } else if (allocation.OnsiteStatus === 2) {
          tempAllocation.OnsiteStatus = allocation.OnsiteStatuses[1].Name;
          tempAllocation.dataValueOnsiteStatus = allocation.OnsiteStatuses[1];
          resourceAllocations.push(tempAllocation);
        } else {
          resourceAllocations.push(allocation);
        }
      }
      cmSheet.ResourceAllocations = resourceAllocations;
    }

    if (this.state.cmsReference === 'crCMS') {
      await this.calculateHeaderFields(this.state.changeRequest, cmSheet);
    } else {
      await this.calculateHeaderFields(this.state.project, cmSheet);
    }

    this.setState(
      {
        cmSheet: cmSheet,
        hasUnSavedData: true,
        disableExcelDownload: true
      },
      () => {}
    );
  };

  getResourceAllocationsTotalValues = resourceAllocations => {
    let totalCostResourceAllocation = 0;
    let totalRevenueResourceAllocation = 0;
    let totalEffortDaysResourceAllocation = 0;
    let totalCostToRevenueRatioResourceAllocation = 0;

    let NonBillableResourceTotalCost = 0;
    let NonBillableResourceEffortDays = 0;

    // Fixed and T&M Effort days
    let totalEffortDaysResourceAllocationFixed = 0;
    let totalEffortDaysResourceAllocationTnM = 0;

    for (const allocation of resourceAllocations) {
      totalCostResourceAllocation = totalCostResourceAllocation + allocation.TotalCost;
      totalRevenueResourceAllocation = totalRevenueResourceAllocation + allocation.TotalRevenue;
      totalEffortDaysResourceAllocation =
        totalEffortDaysResourceAllocation + allocation.TotalEffortDays;

      // Fixed and T&M Effort days
      if (allocation.PaymentMethod == 1) {
        // Fixed
        totalEffortDaysResourceAllocationFixed =
          totalEffortDaysResourceAllocationFixed + allocation.TotalEffortDays;
      } else if (allocation.PaymentMethod == 2) {
        // T&M
        totalEffortDaysResourceAllocationTnM =
          totalEffortDaysResourceAllocationTnM + allocation.TotalEffortDays;
      }

      if(allocation.RateType === 5){
        NonBillableResourceEffortDays = NonBillableResourceEffortDays + allocation.TotalEffortDays
        NonBillableResourceTotalCost = NonBillableResourceTotalCost + allocation.TotalCost
      }

      totalCostToRevenueRatioResourceAllocation =
        totalCostResourceAllocation / totalRevenueResourceAllocation;
    }

    return {
      TotalCostResourceAllocation: totalCostResourceAllocation,
      TotalRevenueResourceAllocation: totalRevenueResourceAllocation,
      TotalEffortDaysResourceAllocation: totalEffortDaysResourceAllocation,
      TotalCostToRevenueRatioResourceAllocation: totalCostToRevenueRatioResourceAllocation,
      TotalEffortDaysResourceAllocationFixed: totalEffortDaysResourceAllocationFixed,
      TotalEffortDaysResourceAllocationTnM: totalEffortDaysResourceAllocationTnM,
      NonBillableResourceEffortDays: NonBillableResourceEffortDays,
      NonBillableResourceTotalCost: NonBillableResourceTotalCost,
    };
  };

  getOtherExpensesTotalValues = otherExpenses => {
    let nonBillTotal = 0;
    let billTotal = 0;

    for (const expense of otherExpenses) {
      if (expense.BillableToClient === true && expense.Cost) {
        billTotal = billTotal + expense.Cost;
      }

      if (expense.BillableToClient === false && expense.Cost) {
        nonBillTotal = nonBillTotal + expense.Cost;
      }
    }

    return { nonBillTotal: nonBillTotal, billTotal: billTotal };
  };

  calculateHeaderFields = async (project, cmSheet) => {
    console.log('cms getting duplicated - Calculate Header Fields Initial:', cmSheet);
    let estDuration = '';
    let revenueAfterDiscount = 0;
    let netProfitLoss = 0;
    let netProfitMargin = 0;
    let averageRatePerDay = 0;
    let valueDiscount = 0;
    let rateDiscount = 0;
    let contingencyValue = 0;
    let netDiscountValue = 0;
    let netDiscountPercentage = 0;
    let impactOfDiscount = 0;
    let grossProfitResourceDep = 0;
    let grossProfitMarginPercentage = 0;
    let partnerCommission = 0;
    let revenueAfterDiscQuotedToCustomer = 0;
    let estimatedOPECustomer = 0;
    let totalProjectValue = 0;
    let salesCommision = 0;
    let workingDaysCount = 0;
    //HashanS rate discount percentage
    let rateDiscountPercentage = 0;

    // set estimated project start and end dates

    const phases = cmSheet.Phases;

    cmSheet.ResourceAllocations.forEach(function(resource, index) {
      let resPhase = phases.filter(e => e.id === resource.PhaseId);
      const dataValuePhase = resPhase[0];
      cmSheet.ResourceAllocations[index].dataValuePhase = dataValuePhase;

      if (resPhase.length > 0) {
        if (resPhase[0].SubPhases) {
          let resSubPhase = resPhase[0].SubPhases.filter(e => e.id === resource.SubPhaseId);
          const dataValueSubPhase = resSubPhase[0];
          cmSheet.ResourceAllocations[index].dataValueSubPhase = dataValueSubPhase;
        }
      }

      cmSheet.ResourceAllocations[index].DateStartDate = resource.StartDate;
    });

    if (this.state.action === 'view') {
      let endDate = null;
      let { startDate, allocationStartDate } = await this.getEstimatedStartDate(project, cmSheet);
      let statusofProjOrCr = null;
      if (this.state.cmsReference === 'crCMS') {
        statusofProjOrCr = project.Status.Code;
      } else {
        statusofProjOrCr = project.ProjectStatus.Code;
      }

      if (statusofProjOrCr === 'BILLABLE' || statusofProjOrCr === 'PRESALES') {
        let totalDays = 0;
        let totalWorkingDayCount = cmSheet.TotalWorkingDaysCount;

        let endDateBillable = reStructureDate(startDate);

        let maxResource = await this.getResourceWithMaxEndDate(project, cmSheet);
        let defaultCountry = null;
        if (maxResource) {
          let onsiteStatus = maxResource.OnsiteStatus ? maxResource.OnsiteStatus : '';

          if (onsiteStatus && onsiteStatus === 'Offshore') {
            defaultCountry = maxResource.dataValueResourceRegion
              ? maxResource.dataValueResourceRegion.DefaultCountryId
              : project.CountryId;
          } else {
            defaultCountry = project.CountryId;
          }
        }

        const holidays = await getHolidaysIntegrated(defaultCountry, endDateBillable.getFullYear())
          .then(res => {
            if (res && res.data) {
              return res.data;
            }
            return [];
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            return [];
          });

        let isHoliday = false;
        for (let i = 1; i < totalWorkingDayCount; i++) {
          totalDays++;
          endDateBillable.setDate(endDateBillable.getDate() + 1);

          isHoliday = false;
          const dateStr = getFormattedDate(endDateBillable);
          if (holidays.indexOf(dateStr) > -1) {
            isHoliday = true;
          }

          if (isHoliday || isWeekend(endDateBillable)) {
            totalWorkingDayCount++;
          }

        }
        endDate = endDateBillable;
      } else {
        endDate = await this.getEstimatedEndDate(project, cmSheet);
      }

      // update project object
      const projectClone = Object.assign({}, project);
      projectClone.EstimatedStartDate = getFormattedDate(startDate);
      if (!this.isAPS() && !this.isResourceAug() && !this.isBlanketWorkOrder()) {
        projectClone.EstimatedEndDate = getFormattedDate(endDate);
        projectClone.EndDate = getFormattedDate(endDate);
      }

      if (this.state.cmsReference === 'crCMS') {
        this.setState({
          changeRequest: projectClone,
          endDate: reStructureDate(endDate)
        });
      } else {
        this.setState({
          project: projectClone,
          endDate: reStructureDate(endDate)
        });
      }

      // Project Duration
      if (this.isAPS() || this.isResourceAug() || this.isBlanketWorkOrder()) {
        if (this.state.cmsReference === 'crCMS') {
          this.setState({
            endDate: reStructureDate(this.state.changeRequest.EndDate)
          });
          if (this.state.changeRequest.StartDate && this.state.endDate) {
            const sdObj = reStructureDate(this.state.changeRequest.StartDate);
            const edObj = reStructureDate(this.state.endDate);
            const sd = sdObj.getTime();
            const ed = edObj.getTime();
            estDuration = calculateDuration(sd, ed);
          } else {
            estDuration = '';
          }
        } else {
          this.setState({
            endDate: reStructureDate(this.state.project.EndDate)
          });
          if (this.state.project.StartDate && this.state.endDate) {
            const sdObj = reStructureDate(this.state.project.StartDate);
            const edObj = reStructureDate(this.state.endDate);
            const sd = sdObj.getTime();
            const ed = edObj.getTime();
            estDuration = calculateDuration(sd, ed);
          } else {
            estDuration = '';
          }
        }
      } else {
        if (startDate && endDate) {
          const sd = reStructureDate(startDate.getTime());
          const ed = reStructureDate(endDate.getTime());
          estDuration = calculateDuration(sd, ed);
        } else {
          estDuration = '';
        }
      }

      cmSheet.ProjectDuration = estDuration;
    } else {
      let { startDate, allocationStartDate } = await this.getEstimatedStartDate(project, cmSheet);

      let endDate = null;
      if (this.state.action === 'approve' || this.state.action === 'revise') {
        let endDate_ = reStructureDate(startDate);
        let totalDays = 0;
        let totalWorkingDayCount = cmSheet.TotalWorkingDaysCount;

        let maxResource = await this.getResourceWithMaxEndDate(project, cmSheet);
        let defaultCountry = null;
        if (maxResource) {
          let onsiteStatus = maxResource.OnsiteStatus ? maxResource.OnsiteStatus : '';
          if (onsiteStatus && onsiteStatus === 'Offshore') {
            defaultCountry = maxResource.dataValueResourceRegion
              ? maxResource.dataValueResourceRegion.DefaultCountryId
              : project.CountryId;
          } else {
            defaultCountry = project.CountryId;
          }
        }

        const holidays = await getHolidaysIntegrated(defaultCountry, endDate_.getFullYear())
          .then(res => {
            if (res && res.data) {
              return res.data;
            }
            return [];
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            return [];
          });

        let isHoliday = false;

        //if start date is a holiday or weekend
        let startisHoliday = false;
        const dateStr = getFormattedDate(endDate_);
        if (holidays.indexOf(dateStr) > -1) {
          startisHoliday = true;
        }

        if (startisHoliday || isWeekend(endDate_)) {
          totalWorkingDayCount++;
        }

        for (let i = 1; i < totalWorkingDayCount; i++) {
          endDate_.setDate(endDate_.getDate() + 1);
          isHoliday = false;
          const dateStr = getFormattedDate(endDate_);
          if (holidays.indexOf(dateStr) > -1) {
            isHoliday = true;
          }

          if (isHoliday || isWeekend(endDate_)) {
            totalWorkingDayCount++;
          }
        }
        endDate = endDate_;
      } else {
        endDate = await this.getEstimatedEndDate(project, cmSheet);
      }

      // update project object
      const projectClone = Object.assign({}, project);
      projectClone.EstimatedStartDate = getFormattedDate(startDate);
      projectClone.EstimatedEndDate = getFormattedDate(endDate);

      if (this.state.cmsReference === 'crCMS') {
        this.setState({
          changeRequest: projectClone
        });
      } else {
        this.setState({
          project: projectClone
        });
      }

      if (this.isAPS() || this.isResourceAug() || this.isBlanketWorkOrder()) {
        if (this.state.cmsReference === 'crCMS') {
          if (this.state.changeRequest.StartDate && this.state.endDate) {
            const sdObj = reStructureDate(this.state.changeRequest.StartDate);
            const edObj = reStructureDate(this.state.endDate);
            const sd = sdObj.getTime();
            const ed = edObj.getTime();
            estDuration = calculateDuration(sd, ed);
          } else {
            estDuration = '';
          }
        } else {
          if (this.state.project.StartDate && this.state.endDate) {
            const sdObj = reStructureDate(this.state.project.StartDate);
            const edObj = reStructureDate(this.state.endDate);
            const sd = sdObj.getTime();
            const ed = edObj.getTime();
            estDuration = calculateDuration(sd, ed);
          } else {
            estDuration = '';
          }
        }
      } else {
        if (startDate && endDate) {
          const sd = reStructureDate(startDate.getTime());
          const ed = reStructureDate(endDate.getTime());
          estDuration = calculateDuration(sd, ed);
        } else {
          estDuration = '';
        }
      }

      if (this.state.action === 'approve' || this.state.action === 'revise' || this.state.action === 'reviseCMSApprove') {
      } else {
        let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
        let isHoliday = false;
        if (startDate && endDate) {
          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(0, 0, 0, 0);
          let end_date = moment(reStructureDate(endDate));
          let start_date = moment(reStructureDate(startDate));

          let maxResource = await this.getResourceWithMaxEndDate(project, cmSheet);
          let defaultCountry = null;
          if (maxResource) {
            let onsiteStatus = maxResource.OnsiteStatus ? maxResource.OnsiteStatus : '';

            if (onsiteStatus && onsiteStatus === 'Offshore') {
              defaultCountry = maxResource.dataValueResourceRegion
                ? maxResource.dataValueResourceRegion.DefaultCountryId
                : project.CountryId;
            } else {
              defaultCountry = project.CountryId;
            }
          }
          //max end date of the resource mix should be the end date
          if (maxResource) {
            end_date = moment(reStructureDate(maxResource.EstimatedEndDate));
          }
          // HOLIDAYS
          let holidays = await getHolidaysIntegrated(defaultCountry, startDate.getFullYear())
            .then(res => {
              if (res && res.data) {
                return res.data;
              }
              return [];
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

          if (holidays && holidays.length === 0) {
          } else if (!holidays) {
            holidays = [];
          }

          let loop = new Date(start_date);
          while (loop <= end_date) {
            isHoliday = false;
            const dateStr = getFormattedDate(loop);
            if (holidays.indexOf(dateStr) > -1) {
              isHoliday = true;
            }

            if (!isHoliday && !isWeekend(loop)) {
              totalWorkingDays++;
            } else {
            }

            let newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
          }
        }
        workingDaysCount = totalWorkingDays;
        cmSheet.TotalWorkingDaysCount = workingDaysCount;
        cmSheet.ProjectDuration = estDuration;
      }
    }

    const serviceRevenue =
      cmSheet.ServiceRevenueValue > 0 ? Number(cmSheet.ServiceRevenueValue).toFixed(2) : 0;

    // Contingency
    contingencyValue =
      (Number(cmSheet.TotalRevenueResourceAllocation) + Number(serviceRevenue)) *
      (Number(cmSheet.ContingencyPercentage) / 100);

    // Value discount / (premium)
    const discountVal = (
      (Number(cmSheet.TotalRevenueResourceAllocation) +
        Number(serviceRevenue) +
        Number(contingencyValue)) *
      (Number(cmSheet.DiscountPercentage) / 100)
    ).toFixed(2);
    const x = Math.pow(10, Number(2) + 1);
    valueDiscount = Number((Number(discountVal) + 1 / x).toFixed(2));

    // Revenue to company after discount
    revenueAfterDiscount =
      Number(cmSheet.TotalRevenueResourceAllocation) +
      Number(serviceRevenue) -
      Number(valueDiscount) +
      Number(contingencyValue);

    // Net profit / (loss)
    let salesCommissionPercentage = 0;
    if (
      this.state.action === 'view' ||
      this.state.action === 'approve' ||
      this.state.action === 'revise' ||
      this.state.action === 'reviseCMSApprove'
    ) {
      salesCommissionPercentage = cmSheet.SalesCommissionPercentage
        ? cmSheet.SalesCommissionPercentage
        : 0;
    } else {
      salesCommissionPercentage = await this.retrieveSalesCommission(project);
    }

    // Average rate per day
    averageRatePerDay =
      cmSheet.TotalEffortDaysResourceAllocation > 0
        ? revenueAfterDiscount / cmSheet.TotalEffortDaysResourceAllocation
        : 0;

    // Rate discount / (premium)
    rateDiscount = await this.calculateRateDiscount(project, cmSheet);

    // Rate discount / (premium) Percentage Hashans
    if (rateDiscount != 0) {
      rateDiscountPercentage =
        (rateDiscount / (cmSheet.TotalRevenueResourceAllocation + cmSheet.ContingencyValue)) * 100;
    } else {
      rateDiscountPercentage = 0;
    }

    // Partner Commission
    if (
      this.state.action === 'view' ||
      this.state.action === 'approve' ||
      this.state.action === 'revise' ||
      this.state.action === 'reviseCMSApprove'
    ) {
      partnerCommission = cmSheet.PartnerCommission ? cmSheet.PartnerCommission : 0;
    } else {
      const partnerCommisionPercentage = await this.retrievePartnerCommission(project);
      const left = 1 - partnerCommisionPercentage / 100;

      if (left > 0 && project.Partner) {
        partnerCommission =
          revenueAfterDiscount / left -
          (Number(cmSheet.TotalRevenueResourceAllocation) -
            Number(valueDiscount) +
            Number(contingencyValue));
      } else {
        partnerCommission = 0;
      }
    }

    // Revenue After Discount Quoted To Customer
    revenueAfterDiscQuotedToCustomer = revenueAfterDiscount + partnerCommission;

    // Estimated OPE Customer
    estimatedOPECustomer = cmSheet.BillableTotalOtherExpenses;

    // Total project value
    totalProjectValue = revenueAfterDiscQuotedToCustomer + estimatedOPECustomer;

    // Negative discount / (premium)
    netDiscountValue = valueDiscount + rateDiscount;

    // Negative discount / (premium) % >> Negative Rate Discount
    const dicSum = await this.calculateNegativeRateDiscount(project, cmSheet);
    netDiscountPercentage =
      (dicSum /
        (Number(cmSheet.TotalRevenueResourceAllocation) +
          Number(cmSheet.ServiceRevenueValue) +
          Number(contingencyValue))) *
      100;

    // Gross Profit from resource deployment
    const directCostSum = await this.calculateSumOfDirectCost(project, cmSheet);

    //Cost Contingency Val
    const contingencyVal = Number(
      (Number(cmSheet.TotalCostResourceAllocation) * cmSheet.ContingencyPercentage) / 100
    ).toFixed(2);

    grossProfitResourceDep =
      revenueAfterDiscount -
      serviceRevenue -
      directCostSum -
      cmSheet.NonBillableTotalOtherExpenses -
      contingencyVal;

    // Gross profit margin %
    grossProfitMarginPercentage =
      revenueAfterDiscount > 0 ? (grossProfitResourceDep / revenueAfterDiscount) * 100 : 0;

    netProfitLoss =
      revenueAfterDiscount -
      cmSheet.TotalCostResourceAllocation -
      revenueAfterDiscount * (salesCommissionPercentage / 100) -
      cmSheet.NonBillableTotalOtherExpenses -
      contingencyVal;

    // Net profit margin %
    netProfitMargin = revenueAfterDiscount > 0 ? (netProfitLoss / revenueAfterDiscount) * 100 : 0;

    //Sales commission HashanS
    salesCommision = revenueAfterDiscount * (salesCommissionPercentage / 100);

    cmSheet.ProjectRevenueAfterDiscount = revenueAfterDiscount;
    cmSheet.NetProfitLoss = netProfitLoss;
    cmSheet.NetProfitMargin = netProfitMargin;
    cmSheet.AverageRatePerDay = roundNumber(averageRatePerDay, 2);
    cmSheet.PartnerCommission = partnerCommission;
    cmSheet.ContingencyValue = contingencyValue;
    cmSheet.DiscountValue = valueDiscount;
    cmSheet.RateDiscount = rateDiscount;
    cmSheet.RevenueAfterDiscountQuotedToCustomer = revenueAfterDiscQuotedToCustomer;
    cmSheet.EstimatedOPECustomer = estimatedOPECustomer;
    cmSheet.TotalProjectValue = totalProjectValue;
    cmSheet.NetDiscountValue = netDiscountValue;
    cmSheet.NetDiscountPercentage = netDiscountPercentage;
    cmSheet.GrossProfitFromResourceDeployment = grossProfitResourceDep;
    cmSheet.GrossProfitMargin = grossProfitMarginPercentage;
    cmSheet.SalesCommissionPercentage = salesCommissionPercentage;
    cmSheet.salesCommision = salesCommision;
    cmSheet.rateDiscountPercentage = rateDiscountPercentage;
    cmSheet.ServiceRevenueValue = serviceRevenue;

    this.setState({
      cmSheet: cmSheet
    });
    console.log('cms getting duplicated - Calculate Header Fields After: ', cmSheet);
  };

  getEstimatedStartDate = async (project, cmSheet) => {
    let startDate = null;
    let allocationStartDate;
    let nullAllocDate = null;

    if (project && project.StartDate) {
      startDate = reStructureDate(project.StartDate);
    }

    if (!startDate) {
      let minDate = null;

      for (let allocation of cmSheet.ResourceAllocations) {
        if (allocation.StartDate) {
          const tempDate = reStructureDate(allocation.StartDate);
          if ((minDate && tempDate < minDate) || !minDate) {
            minDate = tempDate;
          }
        } else if (allocation.dataValueSubPhase && allocation.dataValueSubPhase.StartDate) {
          const tempDate = reStructureDate(allocation.dataValueSubPhase.StartDate);
          if ((minDate && tempDate < minDate) || !minDate) {
            minDate = tempDate;
          }
        } else if (allocation.dataValuePhase && allocation.dataValuePhase.StartDate) {
          const tempDate = reStructureDate(allocation.dataValuePhase.StartDate);
          if ((minDate && tempDate < minDate) || !minDate) {
            minDate = tempDate;
          }
        } else {
          nullAllocDate = new Date();
        }
        if (minDate) {
          startDate = minDate;
        } else {
          startDate = new Date();
        }
      }

      if (startDate > nullAllocDate && nullAllocDate) {
        startDate = nullAllocDate;
      }
      if (!startDate) {
        startDate = new Date();
      }
    }

    allocationStartDate = startDate;

    return { startDate, allocationStartDate };
  };

  getEstimatedEndDate = async (project, cmSheet) => {
    let maxDate = null;

    for (const allocation of cmSheet.ResourceAllocations) {
      if (allocation.EndDate !== null) {
        const tempDate = reStructureDate(allocation.EndDate);
        if ((maxDate && tempDate > maxDate) || !maxDate) {
          maxDate = tempDate;
        }
      } else if (allocation.EstimatedEndDate !== null) {
        const tempDate = reStructureDate(allocation.EstimatedEndDate);
        if ((maxDate && tempDate > maxDate) || !maxDate) {
          maxDate = tempDate;
        }
      }
    }
    return maxDate;
  };

  getResourceWithMaxEndDate = async (project, cmSheet) => {
    let maxDate = null;
    let maxAllocation = null;
    for (const allocation of cmSheet.ResourceAllocations) {
      if (allocation.EstimatedEndDate) {
        const tempDate = reStructureDate(allocation.EstimatedEndDate);
        const tempAllocation = allocation;
        if ((maxDate && tempDate > maxDate) || !maxDate) {
          maxDate = tempDate;
          maxAllocation = tempAllocation;
        }
      }
    }
    return maxAllocation;
  };

  retrieveSalesCommission = async project => {
    let salesCommision = 0;
    let salesPersonId = 0;

    if (this.state.cmsReference === 'crCMS') {
      salesPersonId = project.SalesPersonId ? project.SalesPersonId : 0;
    } else {
      salesPersonId = project.SalesPerson ? project.SalesPerson : 0;
    }

    if (salesPersonId > 0) {
      salesCommision = await getSalesCommission(salesPersonId)
        .then(res => {
          if (res.data && res.data.CommissionPercentage) {
            return res.data.CommissionPercentage;
          }
          return 0;
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.handleServerCallbackError('Error in retrieving sales commission');
          return 0;
        });
    }
    return salesCommision;
  };

  retrieveDefaultRate = async (rateCardParams, allocation) => {
    return getDefaultRate(rateCardParams)
      .then(res => {
        if (res.data.length > 0) {
          return res.data[0].Rate;
        } else {
          return -1;
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.handleServerCallbackError('Error in retrieving rates');
        return -2;
      });
  };

  /**
   * Get rates for allocations.
   * @param rateCardParams
   * @returns rate for the allocation
   */
  retrieveRateForAllocation = async rateCardParams => {
    return getRatesForAllocation(rateCardParams)
      .then(res => {
        if (res.data) {
          if (
            res.data.projectSpecificCrRate &&
            res.data.projectSpecificCrRate.Rate > 0 &&
            this.state.cmsReference === 'crCMS'
          ) {
            return res.data.projectSpecificCrRate.Rate;
          } else if (res.data.projectSpecificRate && res.data.projectSpecificRate.Rate > 0) {
            return res.data.projectSpecificRate.Rate;
          } else if (res.data.customerRate && res.data.customerRate.Rate > 0) {
            return res.data.customerRate.Rate;
          } else if (res.data.defaultRate && res.data.defaultRate.Rate > 0) {
            return res.data.defaultRate.Rate;
          } else {
            return 0;
          }
        } else {
          return -1;
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  handleServerCallbackError = message => {
    message = message || 'Internal server error';
    const title = 'Error';
    this.toggleMessageDialog(message, title);
  };

  retrievePartnerCommission = async project => {
    let partner = 0;

    if (this.state.cmsReference === 'crCMS') {
      partner = project.PartnerId ? project.PartnerId : 0;
    } else {
      partner = project.Partner ? project.Partner : 0;
    }

    let partnerCommision = 0;
    if (partner > 0) {
      partnerCommision = await getPartnerCommission(partner)
        .then(res => {
          if (res.data && res.data.CommissionPercentage) {
            return res.data.CommissionPercentage;
          }
          return 0;
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.handleServerCallbackError('Error in retrieving partner commission');
          return 0;
        });
    }
    return partnerCommision;
  };

  calculateNegativeRateDiscount = async (project, cmSheet) => {
    // ignore outsourced
    let allocations = await cmSheet.ResourceAllocations.filter(
      obj => obj.dataValueCostDesignation && obj.dataValueCostDesignation.Name !== 'Outsourced'
    );

    // ignore Non-Billable/ Admin Rate
    allocations = await allocations.filter(
      obj => obj.dataValueRateType && obj.dataValueRateType.Code !== ADMIN_RATE
    );

    // ignore Approved Project Rate, Standard Rate, LAST OVERRIDE RATE, PRE-AGREED RATE
    allocations = await allocations.filter(
      obj =>
        (obj.dataValueRateType && obj.dataValueRateType.Code !== PROJECT_APPROVED_CR_RATE) ||
        (obj.dataValueRateType && obj.dataValueRateType.Code !== PROJECT_APPROVED_RATE) ||
        (obj.dataValueRateType && obj.dataValueRateType.Code !== APPROVED_PROJ_RATE) ||
        (obj.dataValueRateType && obj.dataValueRateType.Code !== STANDARD_RATE) ||
        (obj.dataValueRateType && obj.dataValueRateType.Code !== LAST_OVERRIDE_RATE) ||
        (obj.dataValueRateType && obj.dataValueRateType.Code !== PRE_AGREED_RATE)
    );

    let dicSum = 0;
    let overriddenRate = 0;
    for (const allocation of allocations) {
      if (allocation.ProjectRole > 0) {
        //Filter overridden rates
        if (allocation.dataValueRateType.id === 4) {
          overriddenRate = allocation.RateValue;
          const costDate = calculateCostDate(allocation, cmSheet, project);
          const rateCardParams = {
            customerId: project.CustomerId,
            regionId:
              this.state.cmsReference === 'crCMS' ? project.BillingRegion : project.BillingRegion,
            role: allocation.ProjectRole,
            projectDate: costDate,
            data: project,
            dataType: this.state.cmsReference,
            projectId: this.state.cmsReference === 'crCMS' ? project.ProjectId : project.id
          };

          let standardRate = 0;
          if (
            this.state.action === 'approve' ||
            this.state.action === 'revise' ||
            this.state.action === 'view' ||
            this.state.action === 'reviseCMSApprove'
          ) {
            standardRate = allocation.StandardRate !== null ? allocation.StandardRate : 0;
          } else {
            standardRate = await this.retrieveRateForAllocation(rateCardParams);
          }
          if (standardRate !== 0 && overriddenRate < standardRate) {
            dicSum = dicSum + (standardRate - overriddenRate) * allocation.EffortDays;
          }
        }
      }
    }
    return dicSum;
  };

  calculateRateDiscount = async (project, cmSheet) => {
    let sum = 0;
    // ignore outsourced
    let allocations = await cmSheet.ResourceAllocations.filter(
      obj => obj.dataValueCostDesignation && obj.dataValueCostDesignation.Name !== 'Outsourced'
    );

    // ignore Non-Billable/ Admin Rate
    allocations = await allocations.filter(
      obj => obj.dataValueRateType && obj.dataValueRateType.Code !== ADMIN_RATE
    );

    // ignore Approved Project Rate
    allocations = await allocations.filter(
      obj => obj.dataValueRateType && obj.dataValueRateType.Code !== APPROVED_PROJ_RATE
    );

    // ignore Standard Rate
    allocations = await allocations.filter(
      obj => obj.dataValueRateType && obj.dataValueRateType.Code !== STANDARD_RATE
    );

    // IGNORE LAST OVERRIDE RATE
    allocations = await allocations.filter(
      obj => obj.dataValueRateType && obj.dataValueRateType.Code !== LAST_OVERRIDE_RATE
    );

    // IGNORE PRE-AGREED RATE
    allocations = await allocations.filter(
      obj => obj.dataValueRateType && obj.dataValueRateType.Code !== PRE_AGREED_RATE
    );

    // IGNORE PROJECT SPECIFIC RATE
    allocations = await allocations.filter(
      obj => obj.dataValueRateType && obj.dataValueRateType.Code !== PROJECT_APPROVED_RATE
    );

    // IGNORE PROJECT SPECIFIC CR RATE
    allocations = await allocations.filter(
      obj => obj.dataValueRateType && obj.dataValueRateType.Code !== PROJECT_APPROVED_CR_RATE
    );

    // calculate sum
    for (const allocation of allocations) {
      if (allocation.ProjectRole > 0) {
        const costDate = calculateCostDate(allocation, cmSheet, project);

        const rateCardParams = {
          customerId: project.CustomerId,
          regionId:
            this.state.cmsReference === 'crCMS' ? project.BillingRegion : project.BillingRegion,
          role: allocation.ProjectRole,
          projectDate: costDate,
          data: project,
          dataType: this.state.cmsReference,
          projectId: this.state.cmsReference === 'crCMS' ? project.ProjectId : project.id
        };

        let standardRate = 0;
        if (
          this.state.action === 'approve' ||
          this.state.action === 'revise' ||
          this.state.action === 'view' ||
          this.state.action === 'reviseCMSApprove'
        ) {
          standardRate = allocation.StandardRate !== null ? allocation.StandardRate : 0;
        } else {
          standardRate = await this.retrieveRateForAllocation(rateCardParams);
        }

        if (standardRate != 0) {
          sum = sum + (standardRate - allocation.RateValue) * allocation.TotalEffortDays;
        }
      }
    }

    return sum;
  };

  populateCalculationsGrid = () => {
    const calculations = [
      {
        Field: 'Cost of Dedicated Time',
        Value1: '',
        isHeader: true
      },
      {
        Field: 'Deployable Resources',
        Value1: ''
      },
      {
        Field: 'Non-Deployable Resources',
        Value1: ''
      },
      {
        Field: 'Non-Billable OPE',
        Value1: ''
      },
      {
        Field: 'JIRA Licenses',
        Value1: ''
      },
      {
        Field: 'Shift Allowances - Offshore',
        Value1: ''
      },
      {
        Field: 'Travel Allowances - Offshore',
        Value1: ''
      },
      {
        Field: 'In-region other costs',
        Value1: ''
      },
      {
        Field: 'COE other costs',
        Value1: ''
      },
      {
        Field: 'Total Cost Excluding Sales Commission',
        Value1: '',
        isHeader: true
      },
      {
        Field: 'Available Capacity at Target Billable Utilization (Days)',
        Value1: '',
        isHeader: true
      },
      {
        Field: 'Net Profit/Loss',
        Value1: '',
        isHeader: true
      },
      {
        Field: 'Net Profit Margin',
        Value1: '',
        isHeader: true
      }
    ];

    const calculationTemp = [];
    for (var item of calculations) {
      if (item.isHidden == undefined) {
        calculationTemp.push(item);
      } else if (!item.isHidden) {
        calculationTemp.push(item);
      }
    }
    this.setState(
      {
        calculations: calculationTemp
      },
      () => {
        this.forceUpdate();
      }
    );
  };
  calculateDefaultRate = async (project, cmSheet) => {
    let sum = 0;
    // ignore outsourced
    const allocations = cmSheet.ResourceAllocations.filter(
      obj => obj.dataValueCostDesignation && obj.dataValueCostDesignation.Name !== 'Outsourced'
    );
    // calculate sum
    for (const allocation of allocations) {
      const costDate = calculateCostDate(allocation, cmSheet, project);

      const rateCardParams = {
        customerId: project.CustomerId,
        regionId:
          this.state.cmsReference === 'crCMS' ? project.BillingRegion : project.BillingRegion,
        role: allocation.ProjectRole,
        projectDate: costDate
      };

      if (allocation.ProjectRole > 0) {
        const standardRate = await this.retrieveDefaultRate(rateCardParams, allocation);
        if (standardRate >= 0) {
          sum = sum + standardRate * allocation.TotalEffortDays;
        } else {
          sum = 0;
          break;
        }
      }
    }

    return sum;
  };

  calculateSumOfDirectCost = async (project, cmSheet) => {
    let sum = 0;
    for (const allocation of cmSheet.ResourceAllocations) {
      if (allocation.ResourceRegion > 0 && allocation.Designation > 0) {
        let directCost = 0;
        if (
          allocation.dataValueCostDesignation &&
          allocation.dataValueCostDesignation.Name === 'Outsourced'
        ) {
          directCost =
            allocation.CostPerOutSourcedResource > 0 ? allocation.CostPerOutSourcedResource : 0;
        } else {
          directCost = allocation.DirectCost > 0 ? allocation.DirectCost : 0;
        }
        sum = sum + directCost * allocation.TotalEffortDays;
      }
    }
    return sum;
  };

  rateCostUpdateAndSave=async()=>{
    if(this.state.selected !== 2){
      this.setState({
        saveFromOutSide:true
      },()=>{
        this.setState({ selected: 2},async()=>{
          await this.childMethod(true);
          this.setState({
            showConfirmSaveDialog: !this.state.showConfirmSaveDialog,
          })
        });
      })
    }else{
      await this.childMethod(true);
      this.setState({
        showConfirmSaveDialog: !this.state.showConfirmSaveDialog,
      })
    }
  }

  rateCostUpdateApproval=async()=>{
    if(this.state.selected !== 2){
      this.setState({
        resetAndSaveFromOutSide:true
      },()=>{
        this.setState({ selected: 2},async()=>{
          await this.approvalChildMethod(true);
          this.setState({
            showConfirmSaveDialog: !this.state.showConfirmSaveDialog,
          })
        });
      })
    }else{
      await this.approvalChildMethod(true);
      this.setState({
        showConfirmSaveDialog: !this.state.showConfirmSaveDialog,
      })
    }
  }

  rateCostReSetAndSave=async()=>{
    if(this.state.selected !== 2){
      this.setState({
        resetAndSaveFromOutSide:true
      },()=>{
        this.setState({ selected: 2},async()=>{
          this.setState({
            showConfirmSaveDialog: !this.state.showConfirmSaveDialog,
          })
          this.saveCMS(false)
        });
      })
    }else{
      this.setState({
        showConfirmSaveDialog: !this.state.showConfirmSaveDialog,
      })
      this.saveCMS(false)
    }
  }
  
  resetAndSaveFromOutSide=async(value)=>{
    this.setState({
      resetAndSaveFromOutSide:value
    })
  }

  rateCostReSetApproval=async()=>{
    if(this.state.selected !== 2){
      this.setState({
        resetAndSaveFromOutSide:true
      },()=>{
        this.setState({ selected: 2},async()=>{
          await this.approvalChildMethod(false);
          this.setState({
            showConfirmSaveDialog: !this.state.showConfirmSaveDialog,
          })
        });
      })
    }else{
      await this.approvalChildMethod(false);
      this.setState({
        showConfirmSaveDialog: !this.state.showConfirmSaveDialog,
      })
    }
  }

  saveCMS= async (populateDropDowns)=>{
    if (this.validateApplicationSupport() && this.validateAllocationsStat(this.state.cmSheet,true) && this.validateApplicationWithPhases()) {
      if (this.validateApplicationSupporResourceMix()) {
        if (this.state.cmsReference === 'crCMS') {
          await this.saveCMSheetBasedOnRef(
            this.state.lastSelectedCurrencyId,
            this.state.changeRequest,
            this.state.cmSheet,
            this.state.cmsReference
          );
          populateDropDowns && this.populateData(false,false)
        } else {
          await this.saveCMSheetBasedOnRef(
            this.state.lastSelectedCurrencyId,
            this.state.project,
            this.state.cmSheet,
            this.state.cmsReference
          );
          populateDropDowns && this.populateData(false,false)
        }
      }
    }
    this.changeOutsideFunctionStatus()
  }

  handleSaveButtonClick = async event => {
    await this.setState({
      isLoading: true
    });
    let changedAllo = await this.checkForMasterDataRatesChanges(true);
    if (event) {
      event.preventDefault();
    }
    if(changedAllo.ratesHaveBeenChanged){
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleConfirmSaveDialog(
        'Confirmation',
        'Cost/Rate cards have been updated. Do you want to update the CMS with the new values?',()=>this.rateCostUpdateAndSave(),()=>this.rateCostReSetAndSave())
    }else{
      this.saveCMS(true)
    }
    await this.setState({
      isLoading: false
    });
  };

  saveCMSheetBasedOnRef = async (lastSelectedCurrencyId, projectOrCR, cmSheet_, cmsReference) => {
    try {
      if (lastSelectedCurrencyId !== projectOrCR.Region.Currency.id) {
        const message =
          'Selected currency is not the project currency(' + projectOrCR.Region.Currency.Code + ')';
        const title = 'Currency';
        this.toggleMessageDialog(message, title);
        return;
      }

      this.setState({
        isSaving: true
      });

      let expenses = [];
      let allocations = [];
      let cmSheet = JSON.parse(JSON.stringify(cmSheet_));

      cmSheet.CmsType = this.state.selectedCmsType.value;

      // validate other expenses
      for (const expense of cmSheet_.OtherExpenses) {
        const exp = JSON.parse(JSON.stringify(expense));

        delete exp.dataValueBillable;
        delete exp.dataValueExpenseType;
        delete exp.dataValuePhase;
        delete exp.dataValueSubPhase;
        delete exp.element;
        delete exp.inEdit;
        delete exp.index;

        expenses.push(exp);
      }
      cmSheet.OtherExpenses = expenses;

      let isResourceAllocationsValid = true;

      for (const allocation of cmSheet_.ResourceAllocations) {
        const alloc = JSON.parse(JSON.stringify(allocation));

        let paymentMethod = null;

        if (cmSheet.Type === 'ChangeRequest') {
          paymentMethod = projectOrCR.PaymentMethodId;
        } else {
          paymentMethod = projectOrCR.PaymentMethod;
        }

        if (alloc.PaymentMethod && paymentMethod !== 3) {
          alloc.PaymentMethod = null;
        }

        // In case in the latter stage of development, if we need this validation
        /*if (this.state.cmsReference === 'crCMS') {
            if (!allocation.PaymentMethod && this.state.changeRequest.PaymentMethod === 3) {
                const message = 'Incomplete billing methods (Fixed / T&M) in resource mix';
                const title = 'Error';
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleMessageDialog(message, title);
                isResourceAllocationsValid = false;
                break;
            }
        } else {
            if (!allocation.PaymentMethod && this.state.project.PaymentMethod === 3) {
                const message = 'Incomplete billing methods (Fixed / T&M) in resource mix';
                const title = 'Error';
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleMessageDialog(message, title);
                isResourceAllocationsValid = false;
                break;
            }
        }*/

        if (!(allocation.AllocationPercentage > 0)) {
          let message = 'Allocation percentage cannot be zero';
          const title = 'Error';
          this.toggleMessageDialog(message, title);
          isResourceAllocationsValid = false;
          break;
        }

        let proStatDate = projectOrCR.StartDate ? reStructureDate(projectOrCR.StartDate) : new Date(projectOrCR.StartDate)
        let alloStatDate = allocation.StartDate ? reStructureDate(allocation.StartDate) : new Date(allocation.StartDate)
        proStatDate = reStructureDate( proStatDate.setHours(0,0,0,0))
        alloStatDate = reStructureDate(alloStatDate.setHours(0,0,0,0))
        if (
          allocation.StartDate &&
          this.state.project.StartDate &&
          alloStatDate < proStatDate
        ) {
          const message =
            'Date values are not valid in resource allocations!( Start dates are not valid )';
          const title = 'Error';
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleMessageDialog(message, title);
          isResourceAllocationsValid = false;
          break;
        } else if (
          allocation.dataValueSubPhase &&
          allocation.dataValueSubPhase.StartDate &&
          this.state.project.StartDate &&
          allocation.dataValueSubPhase.StartDate < projectOrCR.StartDate
        ) {
          const message =
            'Date values are not valid in resource allocations!( Start dates are not valid )';
          const title = 'Error';
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleMessageDialog(message, title);
          isResourceAllocationsValid = false;
          break;
        } else if (
          allocation.dataValuePhase &&
          allocation.dataValuePhase.StartDate &&
          this.state.project.StartDate &&
          allocation.dataValuePhase.StartDate < projectOrCR.StartDate
        ) {
          const message =
            'Date values are not valid in resource allocations!( Start dates are not valid )';
          const title = 'Error';
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleMessageDialog(message, title);
          isResourceAllocationsValid = false;
          break;
        }

        let startDateObj = await getStartDateByPriority(allocation, projectOrCR, cmSheet_);
        let startDate = startDateObj.date;
        allocation.StartDateType = startDateObj.type;

        let countryID = null;

        if (allocation.OnsiteStatus) {
          if (allocation.OnsiteStatus === 'Onsite') {
            countryID = projectOrCR.CountryId;
          } else {
            countryID = allocation.dataValueResourceRegion.DefaultCountryId;
          }

          let { endDate, noOfWorkingDays, totalDays } = await calculateProjectEndDate(
            allocation,
            startDate,
            countryID
          );

          alloc.EstimatedEndDate = moment(reStructureDate(endDate)).format('YYYY-MM-DD');
        }

        // remove unnecessary fields before send to server
        delete alloc.index;
        delete alloc.dataValuePhase;
        delete alloc.dataValueSubPhase;
        delete alloc.dataValueProjectRole;
        delete alloc.dataValueCostDesignation;
        delete alloc.dataValueResourceRegion;
        delete alloc.dataValueOnsiteStatus;
        delete alloc.DateStartDate;
        delete alloc.StartDateMin;
        delete alloc.StartDateMax;
        delete alloc.DatesDisabled;
        delete alloc.dataValuePaymentMethod;
        delete alloc.dataValueRateType;
        delete alloc.RateValueDisabled;
        delete alloc.inEdit;
        delete alloc.Phases;
        delete alloc.SubPhases;
        delete alloc.ProjectRoles;
        delete alloc.CostDesignations;
        delete alloc.ResourceRegions;

        alloc.StartDate = alloc.StartDate
          ? moment(alloc.StartDate).format('YYYY-MM-DD')
          : alloc.StartDate;
        alloc.EndDate = alloc.EndDate ? moment(alloc.EndDate).format('YYYY-MM-DD') : alloc.EndDate;
        allocations.push(alloc);
      }
      cmSheet.ResourceAllocations = allocations;
      cmSheet.PMComment = this.state.pmComment;

      if (!isResourceAllocationsValid) {
        this.setState({
          isSaving: false
        });
        return;
      }

      let projOrCR = null;
      if (cmsReference === 'crCMS') {
        projOrCR = this.state.changeRequest;
        if (projOrCR.EndDate === '') {
          projOrCR.EndDate = null;
        }
        this.state.changeRequest.StartDate = this.state.changeRequest.StartDate
          ? moment(this.state.changeRequest.StartDate).format('YYYY-MM-DD')
          : null;
        await updateChangeReq(this.state.changeRequest, this.state.changeRequest.id)
          .then(res => {
            setTimeout(() => {
              if (res.data && res.data.StartDate) {
                res.data.EstimatedStartDate = res.data.StartDate;
                res.data.EstimatedEndDate = this.state.changeRequest.EstimatedEndDate;
              } else {
                res.data.EstimatedStartDate = this.state.changeRequest.EstimatedStartDate;
                res.data.EstimatedEndDate = this.state.changeRequest.EstimatedEndDate;
              }

              this.setState({
                changeRequest: res.data
              });
            }, 500);
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      } else {
        projOrCR = this.state.project;
        if (projOrCR.EndDate === '') {
          projOrCR.EndDate = null;
        }
        this.state.project.StartDate = this.state.project.StartDate
          ? moment(this.state.project.StartDate).format('YYYY-MM-DD')
          : null;
        await updateProject(this.state.project, this.state.project.id)
          .then(async res => {
            setTimeout(() => {
              this.setState({
                project: res.data
              });
            }, 500);
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      }

      if (cmSheet.ContigencyValue === null) {
        cmSheet.ContigencyValue = 0;
        cmSheet.ContingencyPercentage = 0;
      }
      if (cmSheet.DiscountValue === null) {
        cmSheet.DiscountValue = 0;
        cmSheet.DiscountPercentage = 0;
      }
      if (cmSheet.DiscountPercentage === null) {
        cmSheet.DiscountPercentage = 0;
        cmSheet.DiscountValue = 0;
      }

      for (const obj of cmSheet.ResourceAllocations) {
        if (obj.TotalDays === '' || obj.TotalDays === 0) {
          obj.TotalDays = 0;
        }
      }

      cmSheet.IsPoolProject = this.state.IsPoolProject;
      await saveCMSheet(cmSheet)
        .then(async res => {
          console.log('cms getting duplicated - saveCMSheetBasedOnRef - cmSheet : ', cmSheet);
          cmSheet = res.data;
          this.setState(
            {
              cmSheet: cmSheet,
              hasUnSavedData: false,
              didRun: false,
              disableExcelDownload: false,
              isSaving: false,
              excelDataObsolete: true
            },
            async () => {
              const message = 'Saved Successfully';
              const title = 'Success';
              this.setState({
                showSuccessImage: true,
                showErrorImage: false
              });
              this.toggleMessageDialog(message, title);
            }
          );

          await this.calculateHeaderFields(projOrCR, res.data);
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.setState({
            isSaving: false
          });
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleMessageDialog(error.response.data, 'Error');
        });
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
      this.setState({
        isSaving: false
      });
    }
  };

  validateApplicationSupport = () => {
    if (this.isAPS()) {
      if (this.state.StartDate === null) {
        const message = 'Please enter a valid start date for Application Support CMS';
        const title = 'Start Date';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
        return false;
      }

      if (this.state.endDate === null) {
        const message = 'Please enter a valid end date for Application Support CMS';
        const title = 'End Date';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
        return false;
      }
      return true;
    } else if (this.isResourceAug()) {
      if (this.state.StartDate === null) {
        const message = 'Please enter a valid start date for Resource Augmentation CMS';
        const title = 'Start Date';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
        return false;
      }

      if (this.state.endDate === null) {
        const message = 'Please enter a valid end date for Resource Augmentation CMS';
        const title = 'End Date';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
        return false;
      }
      return true;
    } else if (this.isBlanketWorkOrder()) {
      if (this.state.StartDate === null) {
        const message = 'Please enter a valid start date for Blanket Work Order CMS';
        const title = 'Start Date';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
        return false;
      }

      if (this.state.endDate === null) {
        const message = 'Please enter a valid end date for Blanket Work Order CMS';
        const title = 'End Date';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
        return false;
      }
      return true;
    } else {
      return true;
    }
  };
  
  validateApplicationWithPhases =()=>{
    if(this.state.cmSheet.Phases.length !== 0){
      const phases = this.state.cmSheet.Phases;
      let phaseStartDate  = reStructureDate(this.state.cmSheet.Phases.sort((x, y) => {
        return new Date(x.StartDate).getTime() - new Date(y.StartDate).getTime();
      })[0].StartDate);
      phaseStartDate = reStructureDate(phaseStartDate.setHours(0,0,0,0));
      let startDate = new Date();
      if (this.isAPS() || this.isResourceAug() || this.isBlanketWorkOrder()) {
        if (this.state.cmsReference === 'crCMS') {
          startDate = reStructureDate(this.state.changeRequest.StartDate);
          startDate = reStructureDate( startDate.setHours(0,0,0,0))
          if(startDate > phaseStartDate){
            const message =
              'Project start date is later than a phase start date. Please change the start date of the phase(s) to on or after the project start date to continue';
            const title = 'Warning';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
            this.toggleMessageDialog(message, title);
            return false;
          }
          if(this.state.cmSheet.ResourceAllocations.length !== 0){
            let execute = 0;
            for(let allocation of this.state.cmSheet.ResourceAllocations){
              if(allocation.SubPhaseId !== null && allocation.StartDate !== null){
                let resPhase = phases.filter(e => e.id === allocation.PhaseId);
                if (resPhase.length > 0) {
                  let resSubPhase = resPhase[0].SubPhases.filter(e => e.id === allocation.SubPhaseId);
                  if (resSubPhase.length > 0) {
                    let subPhaseStartDate = reStructureDate(resSubPhase[0].StartDate)
                    subPhaseStartDate = reStructureDate( subPhaseStartDate.setHours(0,0,0,0));
                    let alloStartDate = reStructureDate(allocation.StartDate);
                    alloStartDate = reStructureDate( alloStartDate.setHours(0,0,0,0));
                    if(subPhaseStartDate > alloStartDate){
                      const message =
                      'Resource start date cannot be earlier than the sub phase start date.';
                      const title = 'Warning';
                      this.setState({
                        showSuccessImage: false,
                        showErrorImage: true
                      });
                      this.toggleMessageDialog(message, title);
                      return false;
                    }
                  }
                }
              } else if(allocation.PhaseId !== null && allocation.StartDate !== null){
                let resPhase = phases.filter(e => e.id === allocation.PhaseId);
                if (resPhase.length > 0) {
                  let phaseStartDate = reStructureDate(resPhase[0].StartDate)
                  phaseStartDate = reStructureDate( phaseStartDate.setHours(0,0,0,0));
                  let alloStartDate = reStructureDate(allocation.StartDate);
                  alloStartDate = reStructureDate( alloStartDate.setHours(0,0,0,0));
                  if(phaseStartDate > alloStartDate){
                    const message =
                    'Resource start date cannot be earlier than the phase start date.';
                    const title = 'Warning';
                    this.setState({
                      showSuccessImage: false,
                      showErrorImage: true
                    });
                    this.toggleMessageDialog(message, title);
                    return false;
                  }
                }
              }
              execute = execute + 1;
              if(execute === this.state.cmSheet.ResourceAllocations.length){
               return true
              }
            }
          }
        } else {
          startDate = reStructureDate(this.state.project.StartDate);
          startDate = reStructureDate( startDate.setHours(0,0,0,0))
          if(startDate > phaseStartDate){
            const message =
              'Project start date is later than a phase start date. Please change the start date of the phase(s) to on or after the project start date to continue';
            const title = 'Warning';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
            this.toggleMessageDialog(message, title);
            return false;
          }
          if(this.state.cmSheet.ResourceAllocations.length !== 0){
            let execute = 0;
            for(let allocation of this.state.cmSheet.ResourceAllocations){
              if(allocation.SubPhaseId !== null && allocation.StartDate !== null){
                let resPhase = phases.filter(e => e.id === allocation.PhaseId);
                if (resPhase.length > 0) {
                  let resSubPhase = resPhase[0].SubPhases.filter(e => e.id === allocation.SubPhaseId);
                  if (resSubPhase.length > 0) {
                    let subPhaseStartDate = reStructureDate(resSubPhase[0].StartDate)
                    subPhaseStartDate = reStructureDate( subPhaseStartDate.setHours(0,0,0,0));
                    let alloStartDate = reStructureDate(allocation.StartDate);
                    alloStartDate = reStructureDate( alloStartDate.setHours(0,0,0,0));
                    if(subPhaseStartDate > alloStartDate){
                      const message =
                      'Resource start date cannot be earlier than the sub phase start date.';
                      const title = 'Warning';
                      this.setState({
                        showSuccessImage: false,
                        showErrorImage: true
                      });
                      this.toggleMessageDialog(message, title);
                      return false;
                    }
                  }
                }
              } else if(allocation.PhaseId !== null && allocation.StartDate !== null){
                let resPhase = phases.filter(e => e.id === allocation.PhaseId);
                if (resPhase.length > 0) {
                  let phaseStartDate = reStructureDate(resPhase[0].StartDate)
                  phaseStartDate = reStructureDate( phaseStartDate.setHours(0,0,0,0));
                  let alloStartDate = reStructureDate(allocation.StartDate);
                  alloStartDate = reStructureDate( alloStartDate.setHours(0,0,0,0));
                  if(phaseStartDate > alloStartDate){
                    const message =
                    'Resource start date cannot be earlier than the phase start date.';
                    const title = 'Warning';
                    this.setState({
                      showSuccessImage: false,
                      showErrorImage: true
                    });
                    this.toggleMessageDialog(message, title);
                    return false;
                  }
                }
              }
              execute = execute + 1;
              if(execute === this.state.cmSheet.ResourceAllocations.length){
               return true
              }
            }
          }
        }
      } else {
        if (this.state.cmsReference === 'crCMS') {
          if (
            this.state.changeRequest &&
            this.state.changeRequest.EstimatedStartDate 
          ) {
            startDate = reStructureDate(this.state.changeRequest.EstimatedStartDate);
            startDate = reStructureDate( startDate.setHours(0,0,0,0))
            if(startDate > phaseStartDate){
              const message =
              'Project start date is later than a phase start date. Please change the start date of the phase(s) to on or after the project start date to continue';
              const title = 'Warning';
              this.setState({
                showSuccessImage: false,
                showErrorImage: true
              });
              this.toggleMessageDialog(message, title);
              return false;
            } 
            if(this.state.cmSheet.ResourceAllocations.length !== 0){
              let execute = 0;
              for(let allocation of this.state.cmSheet.ResourceAllocations){
                if(allocation.SubPhaseId !== null && allocation.StartDate !== null){
                  let resPhase = phases.filter(e => e.id === allocation.PhaseId);
                  if (resPhase.length > 0) {
                    let resSubPhase = resPhase[0].SubPhases.filter(e => e.id === allocation.SubPhaseId);
                    if (resSubPhase.length > 0) {
                      let subPhaseStartDate = reStructureDate(resSubPhase[0].StartDate)
                      subPhaseStartDate = reStructureDate( subPhaseStartDate.setHours(0,0,0,0));
                      let alloStartDate = reStructureDate(allocation.StartDate);
                      alloStartDate = reStructureDate( alloStartDate.setHours(0,0,0,0));
                      if(subPhaseStartDate > alloStartDate){
                        const message =
                        'Resource start date cannot be earlier than the sub phase start date.';
                        const title = 'Warning';
                        this.setState({
                          showSuccessImage: false,
                          showErrorImage: true
                        });
                        this.toggleMessageDialog(message, title);
                        return false;
                      }
                    }
                  }
                } else if(allocation.PhaseId !== null && allocation.StartDate !== null){
                  let resPhase = phases.filter(e => e.id === allocation.PhaseId);
                  if (resPhase.length > 0) {
                    let phaseStartDate = reStructureDate(resPhase[0].StartDate)
                    phaseStartDate = reStructureDate( phaseStartDate.setHours(0,0,0,0));
                    let alloStartDate = reStructureDate(allocation.StartDate);
                    alloStartDate = reStructureDate( alloStartDate.setHours(0,0,0,0));
                    if(phaseStartDate > alloStartDate){
                      const message =
                      'Resource start date cannot be earlier than the phase start date.';
                      const title = 'Warning';
                      this.setState({
                        showSuccessImage: false,
                        showErrorImage: true
                      });
                      this.toggleMessageDialog(message, title);
                      return false;
                    }
                  }
                }
              execute = execute + 1;
               if(execute === this.state.cmSheet.ResourceAllocations.length){
                return true
               }
              }
            }         
          }
        } else {
          if (
            this.state.project &&
            this.state.project.EstimatedStartDate
          ) {
            startDate = reStructureDate(this.state.project.EstimatedStartDate);
            startDate = reStructureDate( startDate.setHours(0,0,0,0))
            if(startDate > phaseStartDate){
              const message =
              'Project start date is later than a phase start date. Please change the start date of the phase(s) to on or after the project start date to continue';
              const title = 'Warning';
              this.setState({
                showSuccessImage: false,
                showErrorImage: true
              });
              this.toggleMessageDialog(message, title);
              return false;
            }  
            if(this.state.cmSheet.ResourceAllocations.length !== 0){
              let execute = 0;
              for(let allocation of this.state.cmSheet.ResourceAllocations){
                if(allocation.SubPhaseId !== null && allocation.StartDate !== null){
                  let resPhase = phases.filter(e => e.id === allocation.PhaseId);
                  if (resPhase.length > 0) {
                    let resSubPhase = resPhase[0].SubPhases.filter(e => e.id === allocation.SubPhaseId);
                    if (resSubPhase.length > 0) {
                      let subPhaseStartDate = reStructureDate(resSubPhase[0].StartDate)
                      subPhaseStartDate = reStructureDate( subPhaseStartDate.setHours(0,0,0,0));
                      let alloStartDate = reStructureDate(allocation.StartDate);
                      alloStartDate = reStructureDate( alloStartDate.setHours(0,0,0,0));
                      if(subPhaseStartDate > alloStartDate){
                        const message =
                        'Resource start date cannot be earlier than the sub phase start date.';
                        const title = 'Warning';
                        this.setState({
                          showSuccessImage: false,
                          showErrorImage: true
                        });
                        this.toggleMessageDialog(message, title);
                        return false;
                      }
                    }
                  }
                } else if(allocation.PhaseId !== null && allocation.StartDate !== null){
                  let resPhase = phases.filter(e => e.id === allocation.PhaseId);
                  if (resPhase.length > 0) {
                    let phaseStartDate = reStructureDate(resPhase[0].StartDate)
                    phaseStartDate = reStructureDate( phaseStartDate.setHours(0,0,0,0));
                    let alloStartDate = reStructureDate(allocation.StartDate);
                    alloStartDate = reStructureDate( alloStartDate.setHours(0,0,0,0));
                    if(phaseStartDate > alloStartDate){
                      const message =
                      'Resource start date cannot be earlier than the phase start date.';
                      const title = 'Warning';
                      this.setState({
                        showSuccessImage: false,
                        showErrorImage: true
                      });
                      this.toggleMessageDialog(message, title);
                      return false;
                    }
                  }
                }
               execute = execute + 1;
               if(execute === this.state.cmSheet.ResourceAllocations.length){
                return true
               }
              }
            }   
          }
        }
      } 
    }else{
      return true
    }
    return true
  }

  validateApplicationSupporResourceMix = () => {
    let resourceValid = true;
    if (this.isAPS() || this.isResourceAug() || this.isBlanketWorkOrder()) {
      for (const allocation of this.state.cmSheet.ResourceAllocations) {
        if (allocation.StartDate && this.state.StartDate) {
          let startDateObj = reStructureDate(allocation.StartDate);
          let startDiffMS = this.state.StartDate.getTime() - startDateObj.getTime();
          let startDaysDiff = Math.floor(startDiffMS / (1000 * 60 * 60 * 24));

          if (startDaysDiff > 0) {
            const message = 'Allocation Start Date cannot be lesser than the Project/CR Start Date';
            const title = 'Allocation Start Date';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
            this.toggleMessageDialog(message, title);
            resourceValid = false;
            break;
          }
        }

        if (allocation.StartDate === null) {
          const message = 'Invalid Allocation Start Date';
          const title = 'Allocation Start Date';
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleMessageDialog(message, title);
          resourceValid = false;
          break;
        }

        if (allocation.EndDate && this.state.endDate) {
          const endDateObj = reStructureDate(allocation.EndDate);
          endDateObj.setHours(0, 0, 0, 0);

          let msDiff = this.state.endDate.getTime() - endDateObj.getTime();
          let daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24));

          if (daysDiff < 0) {
            const message = 'Allocation End Date cannot be greater than the Project/CR End Date';
            const title = 'Allocation End Date';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
            this.toggleMessageDialog(message, title);
            resourceValid = false;
            break;
          }
        }

        if (allocation.EndDate === null) {
          const message = 'Invalid Allocation End Date';
          const title = 'Allocation End Date';
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleMessageDialog(message, title);
          resourceValid = false;
          break;
        }
      }

      // CMS Type Validation
      if (
        this.state.selectedCmsType == undefined ||
        Object.getOwnPropertyNames(this.state.selectedCmsType).length === 0
      ) {
        const message = 'CMS Type field cannot be empty';
        const title = 'CMS Type';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
        resourceValid = false;
      }

      if (!resourceValid) {
        return false;
      }
      return true;
    } else {
      // CMS Type Validation
      if (
        this.state.selectedCmsType == undefined ||
        Object.getOwnPropertyNames(this.state.selectedCmsType).length === 0
      ) {
        const message = 'CMS Type field cannot be empty';
        const title = 'CMS Type';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
        resourceValid = false;
      }

      if (!resourceValid) {
        return false;
      }

      return true;
    }
  };

  validateAllocationsStat=(cmsheet,isSave)=>{
    let valid = true;
      for(const allocation of cmsheet.ResourceAllocations){
        if(this.state.StartDate && moment(allocation.StartDate).format('YYYY-MM-DD') <  moment(this.state.StartDate).format('YYYY-MM-DD')){
          valid = false;
          const message = 'Date values are not valid in resource allocations!( Start dates are not valid )';
          const title = 'Error';
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleMessageDialog(message, title);
          break;
        }
        if(allocation.TotalCost === 0 || allocation.TotalCost === null){
          if(!isSave){
            valid = false;
            const message = "The Resource Mix has lines with cost as '0'. Please reach out to the finance team to get the costs updated for the selected Designations to submit for approval.";
            const title = 'Error';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
            this.toggleMessageDialog(message, title);
            break;
          }else{
            valid = false;
            const message = "The Resource Mix has lines with cost as '0'. Please reach out to the finance team to get the costs updated for the selected Designations. Do you want to save anyway? ";
            this.toggleConfirmSaveDialog('Confirmation',message,async()=>{
              this.toggleConfirmSaveDialog('','', null,()=>{this.setState({showConfirmSaveDialog:!this.state.showConfirmSaveDialog})});
              if (this.validateApplicationSupporResourceMix()) {
                if (this.state.cmsReference === 'crCMS') {
                  await this.saveCMSheetBasedOnRef(
                    this.state.lastSelectedCurrencyId,
                    this.state.changeRequest,
                    this.state.cmSheet,
                    this.state.cmsReference
                  );
                } else {
                  await this.saveCMSheetBasedOnRef(
                    this.state.lastSelectedCurrencyId,
                    this.state.project,
                    this.state.cmSheet,
                    this.state.cmsReference
                  );
                }
              }
            },()=>{this.setState({showConfirmSaveDialog:!this.state.showConfirmSaveDialog})})
            break;
          }
        }
      }
    return valid
  }

  isAPS = () => {
    let isAPS = false;
    if (
      this.state.division &&
      this.state.type &&
      this.state.division.id === 30 &&
      this.state.type.id === 3
    ) {
      isAPS = true;
    }
    return isAPS;
  };

  isResourceAug = () => {
    let isResourceAug = false;
    if (this.state.type && this.state.type.id === 6) {
      isResourceAug = true;
    }
    return isResourceAug;
  };

  isBlanketWorkOrder = () => {
    let isBlanketWork = false;
    if (this.state.type && this.state.type.id === 5) {
      isBlanketWork = true;
    }
    return isBlanketWork;
  };

  onSubmitForApproval = async () => {
    await this.setState({
      isLoading: true
    });
    let changedAllo = await this.checkForMasterDataRatesChanges(true);
    if (!changedAllo.ratesHaveBeenChanged) {
      if (this.validateApplicationSupport() && this.validateAllocationsStat(this.state.cmSheet,false) && this.validateApplicationWithPhases()) {
        if (this.validateApplicationSupporResourceMix()) {
          let projOrCR = null;
          this.state.cmSheet.PMComment = this.state.pmComment;
          if (this.state.cmsReference === 'crCMS') {
            var lastSelectedCurrencyId = this.state.lastSelectedCurrencyId;
            projOrCR = this.state.changeRequest;

            // Check the currency - CR
            if (lastSelectedCurrencyId !== projOrCR.Region.Currency.id) {
              const message =
                'Selected currency is not the project currency(' +
                projOrCR.Region.Currency.Code +
                ')';
              const title = 'Currency';
              this.setState({
                isClickedApprovalBtn: false,
                showSuccessImage: false,
                showErrorImage: true,
                isLoading: false
              });
              this.toggleMessageDialog(message, title);
              return;
            }

            if (projOrCR.EndDate === '') {
              projOrCR.EndDate = null;
            }
            this.state.changeRequest.StartDate = this.state.changeRequest.StartDate
              ? moment(this.state.changeRequest.StartDate).format('YYYY-MM-DD')
              : moment(new Date()).format('YYYY-MM-DD');
            await updateChangeReq(this.state.changeRequest, this.state.changeRequest.id)
              .then(res => {})
              .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });
          } else {
            projOrCR = this.state.project;
            var lastSelectedCurrencyId = this.state.lastSelectedCurrencyId;

            // Check the currency - project
            if (lastSelectedCurrencyId !== projOrCR.Region.Currency.id) {
              const message =
                'Selected currency is not the project currency(' +
                projOrCR.Region.Currency.Code +
                ')';
              const title = 'Currency';
              this.setState({
                isClickedApprovalBtn: false,
                showSuccessImage: false,
                showErrorImage: true,
                isLoading: false
              });
              this.toggleMessageDialog(message, title);
              return;
            }

            if (projOrCR.EndDate === '') {
              projOrCR.EndDate = null;
            }
            this.state.project.StartDate = this.state.project.StartDate
              ? moment(this.state.project.StartDate).format('YYYY-MM-DD')
              : moment(new Date()).format('YYYY-MM-DD');
            await updateProject(this.state.project, this.state.project.id)
              .then(res => {})
              .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });
          }

          if (
            this.state.cmSheet.ResourceAllocations &&
            this.state.cmSheet.ResourceAllocations.length > 0
          ) {
            for (const allocation of this.state.cmSheet.ResourceAllocations) {
              if (allocation.StartDate) {
                allocation.StartDate = moment(allocation.StartDate).format('YYYY-MM-DD');
              }

              if (allocation.EndDate) {
                allocation.EndDate = moment(allocation.EndDate).format('YYYY-MM-DD');
              }
            }
          }

          // CMS Type
          this.state.cmSheet.CmsType = this.state.selectedCmsType.value;
          await saveCMSheet(this.state.cmSheet)
            .then(async res => {
              console.log(
                'cms getting duplicated - On Submit For Approval - saveCMSheet: ',
                this.state.cmSheet
              );
              this.setState({
                hasUnSavedData: false,
                didRun: false,
                disableExcelDownload: false
              });
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

          if (
            this.state.cmSheet.ContingencyPercentage < 10 &&
            !this.state.cmSheet.ContingencyComment
          ) {
            const message = 'Please enter a comment for contingency before submitting for Approval';
            const title = 'Comment';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true,
              isClickedApprovalBtn: false,
              isLoading: false
            });
            this.toggleMessageDialog(message, title);
            return;
          }

          if (!this.state.cmSheet.DiscountComment && this.state.cmSheet.DiscountPercentage > 0) {
            const message = 'Please enter a comment for discount before submitting for Approval';
            const title = 'Comment';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true,
              isClickedApprovalBtn: false,
              isLoading: false
            });
            this.toggleMessageDialog(message, title);
            return;
          }

          if (
            this.state.cmSheet.ServiceRevenueValue > 0 &&
            this.state.cmSheet.ServiceRevenueComment &&
            this.state.cmSheet.ServiceRevenueComment.replace(/(<([^>]+)>)/gi, '').length === 0
          ) {
            const message =
              'Please enter a comment for product / licensing revenue before submitting for Approval';
            const title = 'Comment';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true,
              isClickedApprovalBtn: false,
              isLoading: false
            });
            this.toggleMessageDialog(message, title);
            return;
          }

          let today = new Date();
          today.setHours(0, 0, 0, 0);

          if (this.state.cmsReference === 'crCMS') {
            if (
              this.state.changeRequest.StartDate &&
              (reStructureDate(this.state.changeRequest.StartDate) < today && !this.state.alreadyActivated)
            ) {
              const message = 'The change request start date cannot be a past date!';
              const title = 'Comment';
              this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                isClickedApprovalBtn: false,
                isLoading: false
              });
              this.toggleMessageDialog(message, title);
              return;
            }
          } else {
            if (this.state.project.StartDate && (reStructureDate(this.state.project.StartDate) < today && !this.state.alreadyActivated)) {
              const message = 'The project start date cannot be a past date!';
              const title = 'Error';
              this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                isClickedApprovalBtn: false,
                isLoading: false
              });
              this.toggleMessageDialog(message, title);
              return;
            }
          }

          let isExpenseValid = true;
          let isResourceAllocationsValid = true;

          // check no longer valid rates
          let ratesAreValid = true;
          if (
            this.state.cmSheet.ResourceAllocations &&
            this.state.cmSheet.ResourceAllocations.length > 0
          ) {
            for (const allocation of this.state.cmSheet.ResourceAllocations) {
              if (allocation.costNoLongerValid === true || allocation.rateNoLongerValid === true) {
                ratesAreValid = false;
                break;
              }
              if (this.state.cmsReference === 'crCMS') {
                if (!allocation.PaymentMethod && this.state.changeRequest.PaymentMethod === 3) {
                  const message = 'Incomplete billing methods (Fixed / T&M) in resource mix';
                  const title = 'Error';
                  this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                    isClickedApprovalBtn: false
                  });
                  this.toggleMessageDialog(message, title);
                  isResourceAllocationsValid = false;
                  break;
                }
                if (
                  allocation.StartDate &&
                  this.state.changeRequest.StartDate &&
                  allocation.StartDate < this.state.changeRequest.StartDate
                ) {
                  const message =
                    'Date values are not valid in resource allocations!( Start dates are not valid )';
                  const title = 'Error';
                  this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                    isClickedApprovalBtn: false
                  });
                  this.toggleMessageDialog(message, title);
                  isResourceAllocationsValid = false;
                  break;
                } else if (
                  allocation.dataValueSubPhase &&
                  allocation.dataValueSubPhase.StartDate &&
                  this.state.changeRequest.StartDate &&
                  allocation.dataValueSubPhase.StartDate < this.state.changeRequest.StartDate
                ) {
                  const message =
                    'Date values are not valid in resource allocations!( Start dates are not valid )';
                  const title = 'Error';
                  this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                    isClickedApprovalBtn: false
                  });
                  this.toggleMessageDialog(message, title);
                  isResourceAllocationsValid = false;
                  break;
                } else if (
                  allocation.dataValuePhase &&
                  allocation.dataValuePhase.StartDate &&
                  this.state.changeRequest.StartDate &&
                  allocation.dataValuePhase.StartDate < this.state.changeRequest.StartDate
                ) {
                  const message =
                    'Date values are not valid in resource allocations!( Start dates are not valid )';
                  const title = 'Error';
                  this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                    isClickedApprovalBtn: false
                  });
                  this.toggleMessageDialog(message, title);
                  isResourceAllocationsValid = false;
                  break;
                }
              } else {
                if (!allocation.PaymentMethod && this.state.project.PaymentMethod === 3) {
                  const message = 'Incomplete billing methods (Fixed / T&M) in resource mix';
                  const title = 'Error';
                  this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                    isClickedApprovalBtn: false
                  });
                  this.toggleMessageDialog(message, title);
                  isResourceAllocationsValid = false;
                  break;
                }
                if (
                  allocation.StartDate &&
                  this.state.project.StartDate &&
                  allocation.StartDate < this.state.project.StartDate
                ) {
                  const message =
                    'Date values are not valid in resource allocations!( Start dates are not valid )';
                  const title = 'Error';
                  this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                    isClickedApprovalBtn: false
                  });
                  this.toggleMessageDialog(message, title);
                  isResourceAllocationsValid = false;
                  break;
                } else if (
                  allocation.dataValueSubPhase &&
                  allocation.dataValueSubPhase.StartDate &&
                  this.state.project.StartDate &&
                  allocation.dataValueSubPhase.StartDate < this.state.project.StartDate
                ) {
                  const message =
                    'Date values are not valid in resource allocations!( Start dates are not valid )';
                  const title = 'Error';
                  this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                    isClickedApprovalBtn: false
                  });
                  this.toggleMessageDialog(message, title);
                  isResourceAllocationsValid = false;
                  break;
                } else if (
                  allocation.dataValuePhase &&
                  allocation.dataValuePhase.StartDate &&
                  this.state.project.StartDate &&
                  allocation.dataValuePhase.StartDate < this.state.project.StartDate
                ) {
                  const message =
                    'Date values are not valid in resource allocations!( Start dates are not valid )';
                  const title = 'Error';
                  this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                    isClickedApprovalBtn: false
                  });
                  this.toggleMessageDialog(message, title);
                  isResourceAllocationsValid = false;
                  break;
                }
              }

              if (
                !(
                  allocation.ProjectRole > 0 &&
                  allocation.Designation > 0 &&
                  allocation.ResourceRegion > 0 &&
                  allocation.OnsiteStatus &&
                  allocation.AllocationPercentage > 0 &&
                  allocation.NoOfResources &&
                  allocation.EffortDays > 0
                )
              ) {
                let message = 'Please fill all mandatory values in resource mix';
                const title = 'Error';
                this.setState({
                  showSuccessImage: false,
                  showErrorImage: true,
                  isClickedApprovalBtn: false
                });
                this.toggleMessageDialog(message, title);
                isResourceAllocationsValid = false;
                break;
              }

              // 5 = Rate Type ('Non Billable-Admin Rate')
              if (allocation.RateType !== 5 && !(allocation.RateValue > 0)) {
                let message = 'Please fill all mandatory values in resource mix';
                const title = 'Error';
                this.setState({
                  showSuccessImage: false,
                  showErrorImage: true,
                  isClickedApprovalBtn: false
                });
                this.toggleMessageDialog(message, title);
                isResourceAllocationsValid = false;
                break;
              }

              if (
                allocation.dataValueRateType &&
                allocation.dataValueRateType.Code !== ADMIN_RATE &&
                !allocation.RateValue
              ) {
                let message = 'Please fill all mandatory values in resource mix';
                const title = 'Error';
                this.setState({
                  showSuccessImage: false,
                  showErrorImage: true,
                  isClickedApprovalBtn: false
                });
                this.toggleMessageDialog(message, title);
                isResourceAllocationsValid = false;
                break;
              }
            }
          }

          if (!isResourceAllocationsValid) {
            this.setState({
              isSaving: false,
              isClickedApprovalBtn: false,
              isLoading: false
            });
            return;
          }

          if (!ratesAreValid) {
            const message =
              'The existing rates are not valid in resource mix. Please fix them before submit for approval';
            const title = 'Warning';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true,
              isClickedApprovalBtn: false,
              isLoading: false
            });
            this.toggleMessageDialog(message, title);
            return;
          }

          if (this.state.cmSheet.OtherExpenses && this.state.cmSheet.OtherExpenses.length > 0) {
            for (const expense of this.state.cmSheet.OtherExpenses) {
              if (expense.rateNoLongerValid === true) {
                ratesAreValid = false;
                break;
              }

              if (
                !(
                  expense.ExpenseTypeId > 0 &&
                  expense.BudgetedOccurrences > 0 &&
                  expense.BudgetedRate > 0 &&
                  (expense.BillableToClient === true || expense.BillableToClient === false)
                )
              ) {
                const message = 'Please fill all mandatory values in other expenses';
                const title = 'Error';
                this.setState({
                  showSuccessImage: false,
                  showErrorImage: true,
                  isClickedApprovalBtn: false
                });
                this.toggleMessageDialog(message, title);
                isExpenseValid = false;
                break;
              }
            }
          }

          if (!isExpenseValid) {
            this.setState({
              isLoading: false
            });
            return;
          }

          if (!ratesAreValid) {
            const message =
              'The existing rates are not valid in other expenses. Please fix them before submit for approval';
            const title = 'Warning';
            this.setState({
              showSuccessImage: false,
              showErrorImage: true,
              isClickedApprovalBtn: false,
              isLoading: false
            });
            this.toggleMessageDialog(message, title);
            return;
          }

          if (this.state.alreadyActivated) {
            if (this.state.pmComment == '' || !this.state.pmComment || this.state.pmComment === `<p>${this.state.PMName}</p>`) {
              const message =
                "Please add a comment for the Project Manager's Comment section";
              const title = 'Error';
              this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                isClickedApprovalBtn: false,
                isLoading: false
              });
              this.toggleMessageDialog(message, title);
              return;
            }
            
            const projData = {
              ProjectId: this.props.location.reference === 'crCMS' ? this.state.changeRequest.ProjectId : this.state.project.id,
              CrId: this.props.location.reference === 'crCMS' ? this.state.changeRequest.id : null,
            }
            let unApprovedTimeEntries = [];
            let newCostLessThanApprovedCost = false;
            await getAllPendingTimeEntriesForReviseCms(projData).then(async res => {
              if(res.data && res.data.length > 0){
                unApprovedTimeEntries = res.data;
              } else {         
                await getProjectStatisticsForReviseCMS(projData).then(async res => {
                  if(res.data && res.data.length > 0){
                    const projStat = res.data[0];
                    let revisedCmsTotalCost = this.state.cmSheet.TotalCostResourceAllocation
                    let approvedTimeCost = (projStat.FixedLineCapValDCSum && projStat.FixedLineCapValDCSum !== null ? Number(projStat.FixedLineCapValDCSum) : 0) + 
                      (projStat.FixedLineCapValOHSum && projStat.FixedLineCapValOHSum !== null ? Number(projStat.FixedLineCapValOHSum) : 0) + 
                      (projStat.TMLineCapValDCSum && projStat.TMLineCapValDCSum !== null ? Number(projStat.TMLineCapValDCSum) : 0) + 
                      (projStat.TMLineCapValOHSum && projStat.TMLineCapValOHSum !== null ? Number(projStat.TMLineCapValOHSum) : 0);

                      if (projOrCR.Region.Currency.id !== 1) {
                        const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                          projOrCR.Region.Currency.id,
                          new Date(projOrCR.StartDate).getMonth() + 1,
                          new Date(projOrCR.StartDate).getFullYear()
                        );
                        revisedCmsTotalCost = Number(this.state.cmSheet.TotalCostResourceAllocation) / Number(projrateRecordCurrencyRate);
                      }

                      if (revisedCmsTotalCost < approvedTimeCost) {
                        newCostLessThanApprovedCost = true;
                      }
                    
                  }
                }).catch(error => {
                  loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
                    
              }
            }).catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

            if (unApprovedTimeEntries && unApprovedTimeEntries.length > 0) {
              const message =
              "There are pending time approvals in this project/CR. Please approve/reject them before submitting this CM Sheet for approval.";
              const title = 'Error';
              this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                isClickedApprovalBtn: false,
                isLoading: false
              });
              this.toggleMessageDialog(message, title);
              return;
            } else if ((this.state.cmSheet.ResourceAllocations && this.state.cmSheet.ResourceAllocations.length > 0) && 
              (this.state.prevCMSResourceRequests && this.state.prevCMSResourceRequests.length > 0)) {
                let lessEffortResources = [];
                const projectType = this.props.location.reference === 'crCMS' ? "ChangeRequest" : "Project";
                const projectId = this.state.cmSheet.ProjectId;
                await this.populatePrevCMSResourceRequestsWithTime(projectId, projectType);
                const cmsUniqueRoles = [...new Set(this.state.prevCMSResourceRequests.map(item => item.ProjectRole))]
                for (const role of cmsUniqueRoles) {
                  let cmsRoleTotalEffort = 0;
                  let prevCmsRoleTotalEffort = 0;
              
                  const cmsResources = this.state.cmSheet.ResourceAllocations.filter(item => item.ProjectRole === role);
                  for (const res of cmsResources) {
                    cmsRoleTotalEffort = cmsRoleTotalEffort + Number(res.EffortDays);
                  }

                  const prevCmsResources = this.state.prevCMSResourceRequests.filter(item => item.ProjectRole === role);
                  for (const res of prevCmsResources) {
                    prevCmsRoleTotalEffort = prevCmsRoleTotalEffort + Number(res.TimeEntryTotalInDays);
                  }

                  if (cmsRoleTotalEffort < prevCmsRoleTotalEffort) {
                    const roleObj = {
                      ProjectRole: role,
                      ProjectRoleName: prevCmsResources[0].ProjectRoleName,
                      cmsRoleTotalEffort: cmsRoleTotalEffort,
                      prevCmsRoleTotalEffort: prevCmsRoleTotalEffort
                    }
                    lessEffortResources.push(roleObj);
                  }
                }
                if (lessEffortResources && lessEffortResources.length > 0) {
                  const message =
                  "These project roles have effort lower than the total approved time entered for them in the previous CMS versions. Please make sure they have effort minimum or more than the total approved time for the respective roles before submitting for approval.";
                  const title = 'Error';
                  this.setState({
                    prevCmsLessEffortResources: lessEffortResources,
                    showSuccessImage: false,
                    showErrorImage: true,
                    isClickedApprovalBtn: false,
                    isLoading: false
                  });
                  this.toggleMessagePrevCmsErrorDialog(message, title);
                  return;
                }
              } else if (newCostLessThanApprovedCost) {
                const message =
                "The cost of this revised CM Sheet is lower than the approved time from the previous CM Sheet. Please make sure the cost is equal or greater than the approved time of the previous CM Sheet.";
                const title = 'Error';
                this.setState({
                  showSuccessImage: false,
                  showErrorImage: true,
                  isClickedApprovalBtn: false,
                  isLoading: false
                });
                this.toggleMessageDialog(message, title);
                return;
              }
          }

          let nonbillableResourceCount = this.state.cmSheet.ResourceAllocations.filter(
            obj => obj.dataValueRateType && obj.dataValueRateType.Code === ADMIN_RATE
          );

          if (nonbillableResourceCount.length === this.state.cmSheet.ResourceAllocations.length) {
            if (
              this.state.cmSheet.TotalCostResourceAllocation > 0 &&
              this.state.cmSheet.TotalEffortDaysResourceAllocation > 0
            ) {
              this.toggleConfirmDialog(
                'Are you sure you want to submit for approval?',
                this.submitForApproval
              );
            } else {
              const message =
                'This CM Sheet has no valid data for Resource mix to submit for approval.';
              const title = 'Invalid CM Sheet';
              this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                isClickedApprovalBtn: false
              });
              this.toggleMessageDialog(message, title);
            }
          } else {
            if (
              this.state.cmSheet.TotalCostResourceAllocation > 0 &&
              this.state.cmSheet.TotalRevenueResourceAllocation > 0 &&
              this.state.cmSheet.TotalEffortDaysResourceAllocation > 0
            ) {
              this.toggleConfirmDialog(
                'Are you sure you want to submit for approval?',
                this.submitForApproval
              );
            } else {
              const message =
                'This CM Sheet has no valid data for Resource mix to submit for approval.';
              const title = 'Invalid CM Sheet';
              this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                isClickedApprovalBtn: false
              });
              this.toggleMessageDialog(message, title);
            }
          }
          this.populateData()
        }
      }
    } else {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true,
        isLoading: false
      });
      this.toggleConfirmSaveDialog(
        'Confirmation',
        "Cost/Rate cards have been updated. Do you want to update the CMS with the new values? Selecting 'No' will reset the rows with the updated values.",()=>this.rateCostUpdateApproval(),()=>this.rateCostReSetApproval());
    }

    await this.setState({
      isClickedApprovalBtn: false,
      isLoading: false
    });
  };

  submitForApproval = async () => {
    await this.setState({
      isLoading: true
    });
    // let EstimatedStartDate;
    if (this.props.location.reference === 'crCMS') {
      this.state.EstimatedStartDate = this.state.changeRequest.EstimatedStartDate
        ? this.state.changeRequest.EstimatedStartDate
        : new Date();
    } else {
      //Project
      this.state.EstimatedStartDate = this.state.project.EstimatedStartDate;
    }
    await selectWorkflow(this.state.cmSheet.id, {
      EstimatedStartDate: this.state.EstimatedStartDate,
      SelectedCurrency: this.state.lastSelectedCurrencyId
    }).then(res => {
      if (res.data.length > 1) {
        this.setState(
          {
            matchingWorkflowList: res.data
          },
          () => {
            this.toggleWorkflowSelectDialog();
          }
        );
      } else if (res.data.length === 1) {
        this.setState(
          {
            selectedWorkFlowId: res.data[0].id
          },
          () => {
            this.setWorkflowToCMSheet();
          }
        );
      } else {
        const message =
          'This CMS has failed to find a appropriate workflow for approvals. Please contact your system administrator to correct this issue';
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
      }
    });
    this.toggleConfirmDialog('', null);
    await this.setState({
      isLoading: false
    });
  };

  setWorkflowToCMSheet = async () => {
    await this.setState({
      isLoading: true
    });
    await setWorkflow({ cmSheetId: this.state.cmSheet.id, workflowId: this.state.selectedWorkFlowId })
      .then(res => {
        this.toggleWorkflowSelectDialog();
        this.setState({ selectedWorkFlowId: null });
        this.navigateToDashboard();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleWorkflowSelectDialog();
        const message = error.response.data;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
      });
      await this.setState({
        isLoading: false
      });
  };

  setSelectedWorkflowId = workflowId => {
    this.setState({
      selectedWorkFlowId: workflowId
    });
  };

  onChangeApprovalComment = comment => {
    let Comment = comment.replace(/(<([^>]+)>)/gi, '');
    this.setState({
      approvalComment: comment
    });
  };

  onApproveCMSheet = () => {
    let appComment = this.state.approvalComment.replace(/(<([^>]+)>)/gi, '');
      this.setState(
        {
          approveParams: {
            cmSheetId: this.state.cmSheet.id,
            comment: this.state.approvalComment,
            selectedCurrency: this.state.lastSelectedCurrencyId,
            estimateStartDate:
              this.props.location.reference === 'crCMS'
                ? this.state.changeRequest.EstimatedStartDate
                : this.state.project.EstimatedStartDate
          }
        },
        () => {
          this.toggleConfirmDialog('Are you sure you want to approve this?', this.approveCMSheet);
        }
      );
  };

  approveCMSheet = () => {
    this.setState({
      isLoading: true
    });
    approveCMSheet(this.state.approveParams)
      .then(res => {
        this.toggleConfirmDialog('', null);
        this.setState({ approveParams: null, isLoading: false });
        this.navigateToDashboard();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleConfirmDialog('', null);
        const message = error.response.data;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          isLoading: false
        });
        this.toggleMessageDialog(message, title);
      });
  };

  onRejectCMSheet = () => {
    if (this.state.approvalComment && this.state.approvalComment.trim().length > 0) {
      this.setState(
        {
          rejectParams: {
            cmSheetId: this.state.cmSheet.id,
            comment: this.state.approvalComment
          }
        },
        () => {
          this.toggleConfirmDialog('Are you sure you want to reject this?', this.rejectCMSheet);
        }
      );
    } else {
      const message = 'Comment cannot be empty';
      const title = 'Error';
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleMessageDialog(message, title);
    }
  };

  rejectCMSheet = () => {
    rejectCMSheet(this.state.rejectParams)
      .then(res => {
        this.toggleConfirmDialog('', null);
        this.setState({ rejectParams: null });
        this.navigateToDashboard();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleConfirmDialog('', null);
        const message = error.response.data;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
      });
  };

  onApproveReviseCMSheetRequest = () => {
    this.toggleConfirmDialog('Are you sure you want to approve this?', this.approveReviseCMSheetRequest);
  };

  approveReviseCMSheetRequest = () => {
    this.setState({
      isLoading: true
    });

    const approveParams = {
      cmSheetId: this.state.cmSheet.id,
      cmSheetVersion: this.state.cmSheet.Version,
      comment: this.state.approvalComment,
      reviseCmsRequestId: this.state.reviseCmsRequestId
    }

    approveReviseCmsRequest(approveParams)
      .then(res => {
        this.toggleConfirmDialog('', null);
        this.setState({ isLoading: false });
        this.navigateToDashboard();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleConfirmDialog('', null);
        const message = error.response.data;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          isLoading: false
        });
        this.toggleMessageDialog(message, title);
      });
  };

  onRejectReviseCMSheetRequest = () => {
    if (this.state.approvalComment && this.state.approvalComment.trim().length > 0) {
      this.toggleConfirmDialog('Are you sure you want to reject this?', this.rejectReviseCMSheetRequest);
    } else {
      const message = 'Comment cannot be empty';
      const title = 'Error';
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleMessageDialog(message, title);
    }
  };

  rejectReviseCMSheetRequest = async () => {
    const rejectParams = {
      cmSheetId: this.state.cmSheet.id,
      cmSheetVersion: this.state.cmSheet.Version,
      comment: this.state.approvalComment,
      reviseCmsRequestId: this.state.reviseCmsRequestId
    }
    await rejectReviseCmsRequest(rejectParams)
      .then(res => {
        this.toggleConfirmDialog('', null);
        this.navigateToDashboard();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleConfirmDialog('', null);
        const message = error.response.data;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
      });
  };

  onCancelApproval = () => {
    this.navigateToSearchScreen();
  };

  handleReviseCMSheet = () => {
    this.toggleConfirmDialog('Are you sure, you want to revise cm sheet?', this.reviseCMSheet);
  };

  reviseCMSheet = () => {
    reviseCMSheet(this.state.cmSheet.id)
      .then(async res => {
        console.log('cms getting duplicated - reviseCMSheet - cmSheetId :', this.state.cmSheet.id);
        console.log('cms getting duplicated - reviseCMSheet Data: ', res.data);
        // if (res.data) {
        //   const updatedCMSheet = res.data;
        //   const filterResourceAllocations = updatedCMSheet.resourceAllocations.filter(
        //     obj => obj.StartDateType === CMS_CREATED_DATE || obj.StartDateType === CMS_REVISED_DATE
        //   );
        //   if()
        // }
        this.setState(
          {
            action: ACTION_EDIT,
            isViewMode: false,
            locationCMSheet: res.data,
            selectedCmsType: {}
          },
          () => {
            this.toggleConfirmDialog('', null);

            this.handleProjectChange(this.state.project.id, this.state.changeRequest.id);
          }
        );

        // this.toggleConfirmDialog('', null);
        // this.navigateToDashboard();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleConfirmDialog('', null);
        const message = error.response.data;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
      });
  };

  onCancelRevise = () => {
    this.navigateToSearchScreen();
  };

  onCancelButtonClick = () => {
    if (
      (this.state.hasUnSavedData === true && this.state.action === 'edit') ||
      this.state.action === 'create'
    ) {
      this.toggleConfirmDialog(
        'This form contains unsaved data. Do you want to close it?',
        this.navigateToSearchScreen
      );
    } else {
      this.navigateToSearchScreen();
    }
  };

  navigateToSearchScreen = () => {
    this.setState({
      navigateToSearchScreen: true
    });
  };

  navigateToDashboard = () => {
    this.setState({
      navigateToDashboard: true
    });
  };

  toggleMessageDialog = (message, title) => {
    this.setState({
      showMessageDialog: !this.state.showMessageDialog,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleMessagePrevCmsErrorDialog = (message, title) => {
    this.setState({
      showPrevCmsErrorDialog: !this.state.showPrevCmsErrorDialog,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleConfirmDialog = (message, action) => {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
      confirmDialogMessage: message,
      confirmDialogAction: action
    });
  };

  toggleCostRateChangedConfirmDialog = (message) => {
    this.setState({
      showCostRateChangedConfirmDialog: !this.state.showCostRateChangedConfirmDialog,
      confirmDialogMessage: message
    });
  };

  toggleConfirmSaveDialog = (title ,message, action,rejectAction) => {
    this.setState({
      showConfirmSaveDialog: !this.state.showConfirmSaveDialog,
      confirmDialogMessage: message,
      confirmDialogAction: action,
      rejectDialogAction: rejectAction,
      confTitle:title
    });
  };

  toggleWorkflowSelectDialog = () => {
    this.setState({
      showWorkflowSelectDialog: !this.state.showWorkflowSelectDialog
    });
  };

  //tool tip toggles
  toggleProjectToolTip = () => {
    this.setState({
      projectNameToolTipOpen: !this.state.projectNameToolTipOpen
    });
  };

  toggleProjectIdToolTip = () => {
    this.setState({
      projectIdToolTipOpen: !this.state.projectIdToolTipOpen
    });
  };

  toggleProjectCustomerToolTip = () => {
    this.setState({
      customerNameToolTipOpen: !this.state.customerNameToolTipOpen
    });
  };

  toggleopIdToolTip = () => {
    this.setState({
      opIdToolTipOpen: !this.state.opIdToolTipOpen
    });
  };

  toggleStatusToolTip = () => {
    this.setState({
      statusToolTipOpen: !this.state.statusToolTipOpen
    });
  };

  toggleCMSStatusToolTip = () => {
    this.setState({
      cmsStatusToolTipOpen: !this.state.cmsStatusToolTipOpen
    });
  };

  onStartDateChangeInfoTab = callbackData => {
    this.setState({
      infoTabStartDateChanged: callbackData.infoTabStartDateChanged
    });
  };
  
  rowRender(trElement, props) {
    const isHeader = props.dataItem.isHeader;
    const contEffort = props.dataItem.contEffort;
    const className = 'summery-header-style';
    const contclass = 'contract-effort';
    const trProps = { class: isHeader === true ? className : null };
    const cedProps = { class: contEffort === true ? contclass : null };
    if (isHeader) {
      return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    } else if (contEffort) {
      return React.cloneElement(trElement, { ...cedProps }, trElement.props.children);
    } else return trElement;
  }

  render() {
    if (this.state.navigateToDashboard === true) {
      return <Redirect to="/" />;
    }
    if (this.state.navigateToSearchScreen === true) {
      return <Redirect to="/projects/search/search" />;
    }

    return (
      <div className="main-card">
        <Loader loading={this.state.isLoading} />
        <div className="row">
          {this.props.location.reference === 'crCMS' && (
            <div className="col-md-2">
              <div className="">
                <div className="d-block">
                  <label>CR Name:</label>
                </div>
                <div className="d-block" id="projectNameToolTip">
                  <Input disabled value={this.state.changeRequest.CRName} />
                </div>
                {this.state.project.ProjectName ? (
                  <Tooltip
                    placement="top"
                    isOpen={this.state.projectNameToolTipOpen}
                    target="projectNameToolTip"
                    toggle={this.toggleProjectToolTip}
                  >
                    {this.state.project.ProjectName}
                  </Tooltip>
                ) : null}
              </div>
            </div>
          )}

          {this.props.location.reference === 'crCMS' && (
            <div className="col-md-2">
              <div className="">
                <div className="d-block">
                  <label>CR ID:</label>
                </div>
                <div className="d-block" id="projectNameToolTip">
                  <Input disabled value={this.state.changeRequest.UniqueId} />
                </div>
                {this.state.project.ProjectName ? (
                  <Tooltip
                    placement="top"
                    isOpen={this.state.projectNameToolTipOpen}
                    target="projectNameToolTip"
                    toggle={this.toggleProjectToolTip}
                  >
                    {this.state.project.ProjectName}
                  </Tooltip>
                ) : null}
              </div>
            </div>
          )}

          {this.props.location.reference === 'crCMS' && (
            <div className="col-md-2">
              <div className="">
                <div className="d-block">
                  <label>CR OP ID:</label>
                </div>
                <div className="d-block" id="opIdToolTip">
                  <Input disabled value={this.state.changeRequest.OPID} />
                </div>
                {this.state.project.OPID ? (
                  <Tooltip
                    placement="top"
                    isOpen={this.state.opIdToolTipOpen}
                    target="opIdToolTip"
                    toggle={this.toggleopIdToolTip}
                  >
                    {this.state.project.OPID}
                  </Tooltip>
                ) : null}
              </div>
            </div>
          )}

          {this.props.location.reference === 'crCMS' && (
            <>
              <div className="col-md-2">
                <div className="">
                  <div className="d-block">
                    <label>CR CMS Version:</label>
                  </div>
                  <div className="d-block" id="cmsVersionToolTip">
                    <DropDownList
                      data={this.state.cmSheetVersions}
                      textField="Text"
                      dataItemKey="Version"
                      value={this.state.selectedVersion}
                      onChange={this.toggleVersionChangeDialog}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="">
                  <div className="d-block">
                    <label>CR CMS Status:</label>
                  </div>
                  <div className="d-block" id="cmsStatusToolTip">
                    <Input value={this.cmsStatuses[this.state.cmSheet.Status]} disabled />
                  </div>
                  {this.cmsStatuses[this.state.cmSheet.Status] ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.cmsStatusToolTipOpen}
                      target="cmsStatusToolTip"
                      toggle={this.toggleCMSStatusToolTip}
                    >
                      {this.cmsStatuses[this.state.cmSheet.Status]}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>

        {this.props.location.reference === 'crCMS' && <div className="col-md-12" />}

        <div className="row">
          <div className="col-md-2">
            <div className="">
              <div className="d-block">
                <label>Project Name:</label>
              </div>
              <div className="d-block" id="projectNameToolTip">
                <Input disabled value={this.state.project.ProjectName} />
              </div>
              {this.state.project.ProjectName ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.projectNameToolTipOpen}
                  target="projectNameToolTip"
                  toggle={this.toggleProjectToolTip}
                >
                  {this.state.project.ProjectName}
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="col-md-2">
            <div className="">
              <div className="d-block">
                <label>Customer Name:</label>
              </div>
              <div className="d-block" id="customerNameToolTip">
                <Input disabled value={this.state.customer ? this.state.customer.Name : null} />
              </div>
              <Tooltip
                placement="top"
                isOpen={this.state.customerNameToolTipOpen}
                target="customerNameToolTip"
                toggle={this.toggleProjectCustomerToolTip}
              >
                {this.state.customer.Name}
              </Tooltip>
            </div>
          </div>

          <div className="col-md-2">
            <div className="">
              <div className="d-block">
                <label>Project ID:</label>
              </div>
              <div className="d-block" id="projectIdToolTip">
                <Input disabled value={this.state.project.UniqueId} />
              </div>
              {this.state.project.UniqueId ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.projectIdToolTipOpen}
                  target="projectIdToolTip"
                  toggle={this.toggleProjectIdToolTip}
                >
                  {this.state.project.UniqueId}
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="col-md-2">
            <div className="">
              <div className="d-block">
                <label>OP ID:</label>
              </div>
              <div className="d-block" id="opIdToolTip">
                <Input disabled value={this.state.project.OPID} />
              </div>
              {this.state.project.OPID ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.opIdToolTipOpen}
                  target="opIdToolTip"
                  toggle={this.toggleopIdToolTip}
                >
                  {this.state.project.OPID}
                </Tooltip>
              ) : null}
            </div>
          </div>

          {this.props.location.reference !== 'crCMS' && (
            <div className="col-md-2">
              <div className="">
                <div className="d-block">
                  <label>Status:</label>
                </div>
                <div className="d-block" id="statusToolTip">
                  <Input
                    disabled
                    value={
                      this.state.project.ProjectStatus
                        ? this.state.project.ProjectStatus.StatusName
                        : ''
                    }
                  />
                </div>

                {this.state.project.ProjectStatus ? (
                  <Tooltip
                    placement="top"
                    isOpen={this.state.statusToolTipOpen}
                    target="statusToolTip"
                    toggle={this.toggleStatusToolTip}
                  >
                    {this.state.project.ProjectStatus.StatusName}
                  </Tooltip>
                ) : null}
              </div>
            </div>
          )}
        </div>

        {this.state.cmsReference !== 'crCMS' && (
          <div className="row">
            <div className="col-md-2">
              <div className="">
                <div className="d-block">
                  <label>CMS Version:</label>
                </div>
                <div className="d-block" id="cmsVersionToolTip">
                  <DropDownList
                    data={this.state.cmSheetVersions}
                    textField="Text"
                    dataItemKey="Version"
                    value={this.state.selectedVersion}
                    onChange={this.toggleVersionChangeDialog}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="">
                <div className="d-block">
                  <label>CMS Status:</label>
                </div>
                <div className="d-block" id="cmsStatusToolTip">
                  <Input value={this.cmsStatuses[this.state.cmSheet.Status]} disabled />
                </div>
                {this.cmsStatuses[this.state.cmSheet.Status] ? (
                  <Tooltip
                    placement="top"
                    isOpen={this.state.cmsStatusToolTipOpen}
                    target="cmsStatusToolTip"
                    toggle={this.toggleCMSStatusToolTip}
                  >
                    {this.cmsStatuses[this.state.cmSheet.Status]}
                  </Tooltip>
                ) : null}
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            {!this.state.isLoading && (
              <ExcelDownload
                project={this.state.project}
                cmSheet={this.state.cmSheet}
                data={
                  this.state.cmsReference === 'crCMS'
                    ? this.state.changeRequest
                    : this.state.project
                }
                changeRequest={this.state.changeRequest}
                cmsReference={this.state.cmsReference}
                version={this.state.selectedVersion}
                disableExcelDownload={this.state.disableExcelDownload}
                dataUpdated={() => {
                  this.setState({ excelDataObsolete: false });
                }}
                updateData={this.state.excelDataObsolete}
                cmSheetAction={this.state.action}
              />
            )}
          </div>
        </div>

        <div className="main-seperator" />

        <div className="row cm-view-wrap">
          <div className="col-md-12">
            <TabStrip selected={this.state.selected} onSelect={this.handleTabSelect}>
              <TabStripTab title="Summary">
                <ChartSection
                  project={this.state.project}
                  changeRequest={this.state.changeRequest}
                  cmSheet={this.state.cmSheet}
                  onCurrencyChange={this.handleCurrencyChange}
                  lastSelectedCurrencyId={this.state.lastSelectedCurrencyId}
                  selectedPaymentMethod={this.state.selectedPaymentMethod}
                />
              </TabStripTab>
              <TabStripTab title="Info">
                <ProjectDetails
                  project={this.state.project}
                  cmSheet={this.state.cmSheet}
                  cmSheetReference={this.state.cmsReference}
                  changeRequest={this.state.changeRequest}
                  selectedRegion={this.state.selectedRegion}
                  selectedCountry={this.state.selectedCountry}
                  selectedPaymentMethod={this.state.selectedPaymentMethod}
                  onPaymentMethodChange={this.handlePaymentMethodChange}
                  selectedSalesPerson={this.state.selectedSalesPerson}
                  onSalesPersonChange={this.handleSalesPersonChange}
                  selectedPartner={this.state.selectedPartner}
                  onPartnerChange={this.handelPartnerChange}
                  startDate={this.state.StartDate}
                  endDate={this.state.endDate}
                  startDateMin={this.state.startDateMin}
                  onStartDateChange={this.onStartDateChange}
                  onEndDateChange={this.onEndDateChange}
                  onDiscountChange={this.handleDiscountChange}
                  onServiceRevenueChange={this.handleServiceRevenueChange}
                  onAPSProfitabilityChange={this.handleAPSProfitabilityChange}
                  onContingencyChange={this.handleContingencyChange}
                  onCommentChangeDiscount={this.handleCommentChangeDiscount}
                  onCommentChangeContingency={this.handleCommentChangeContingency}
                  onCommentChangeServiceRevenue={this.handleCommentChangeServiceRevenue}
                  onCommentChangeAPSProfitability={this.handleCommentAPSProfitability}
                  onUpdateCMSheet={this.handleUpdateCMSheetModel}
                  IsStartDateChange={this.IsStartDateChange}
                  isViewMode={this.state.isViewMode}
                  isApproveMode={this.state.isApproveMode}
                  division={this.state.division}
                  projPractice={this.state.projPractice}
                  type={this.state.type}
                  onDepartmentChange={this.onDepartmentChange}
                  onEngagementTypeChange={this.onEngagementTypeChange}
                  cmsTypes={this.state.cmsTypes}
                  selectedCmsType={this.state.selectedCmsType}
                  onCmsTypeChange={this.handleCmsTypeChange}
                  selectedOffering={this.state.selectedOffering}
                  onOfferingChange={this.onOfferingChange}
                  onProjPracticeChange={this.onProjPracticeChange}
                  alreadyActivated={this.state.alreadyActivated}
                />
              </TabStripTab>

              <TabStripTab title="Resource Mix">
                <ResourceAllocations
                  isViewMode={this.state.isViewMode}
                  project={this.state.project}
                  changeRequest={this.state.changeRequest}
                  cmSheet={this.state.cmSheet}
                  display={true}
                  startDate={this.state.StartDate}
                  endDate={this.state.endDate}
                  checkForMasterDataRatesChanges={this.checkForMasterDataRatesChanges}
                  cmSheetReference={this.state.cmsReference}
                  onUpdateCMSheet={this.handleUpdateCMSheetModel}
                  isAPS={this.isAPS()}
                  isResourceAug={this.isResourceAug()}
                  saveCMS={this.saveCMS}
                  isBlanketWorkOrder={this.isBlanketWorkOrder()}
                  infoTabStartDateChanged={this.state.infoTabStartDateChanged}
                  apsTypeHasChanged={this.state.apsTypeHasChanged}
                  augTypeHasChanged={this.state.augTypeHasChanged}
                  blanketTypeHasChanged={this.state.blanketTypeHasChanged}
                  onStartDateChange={callbackData => this.onStartDateChangeInfoTab(callbackData)}
                  setChildMethod={this.setChildMethod}
                  changeCostRateUpdateStatus={this.changeCostRateUpdateStatus}
                  setApprovalChildMethod={this.setApprovalChildMethod}
                  setPopulateData={this.setPopulateData}
                  IsStartDateChanged={this.state.startDateChanged}
                  toggleDateChangeDialog={this.toggleDateChangeDialog}
                  reSetCostValue={this.state.reSetCostValue}
                  lastSelectedCurrencyId={this.state.lastSelectedCurrencyId}
                  defaultCurrencyId={this.state.defaultCurrencyId}
                  updateCost={this.state.updateCost}
                  resetAndSaveFromOutSide={this.state.resetAndSaveFromOutSide}
                  saveFromOutSide={this.state.saveFromOutSide}
                  approveUFromOutSide={this.state.approveUFromOutSide}
                  approveRFromOutSide={this.state.approveRFromOutSide}
                  checkSelectedCurrency={this.checkSelectedCurrency}
                  changeOutsideFunctionStatus={this.changeOutsideFunctionStatus}
                  isApproveMode={this.state.isApproveMode}
                  alreadyActivated={this.state.alreadyActivated}
                  prevCMSResourceRequests={this.state.prevCMSResourceRequests}
                  cmsSaveBtnDisabled={this.cmsSaveBtnDisabled}
                />
              </TabStripTab>

              <TabStripTab title="Other Expenses">
                <OtherExpenses
                  isViewMode={this.state.isViewMode}
                  project={this.state.project}
                  cmSheet={this.state.cmSheet}
                  onUpdateCMSheet={this.handleUpdateCMSheetModel}
                  cmSheetReference={this.state.cmsReference}
                  changeRequest={this.state.changeRequest}
                  alreadyActivated={this.state.alreadyActivated}
                  prevCMSOtherExpenses={this.state.prevCMSOtherExpenses}
                />
              </TabStripTab>

              <TabStripTab title="File Upload">
                {this.cmsStatuses[this.state.cmSheet.Status] && (
                  <FileUpload
                    referenceType="CMsheet"
                    referenceId={this.state.cmSheet.id}
                    cmSheet={this.state.cmSheet}
                    cmSheetAction={this.state.action}
                    viewingMode={this.state.isViewMode}
                    isRolePM={this.state.isRolePM}
                  />
                )}
              </TabStripTab>
            </TabStrip>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <label htmlFor="">Project Manager's Comment:</label>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <ReactQuill
              name="PMComment"
              onChange={this.handleCommentChange}
              readOnly={
                this.state.isViewMode ||
                this.state.action === ACTION_APPROVE ||
                this.state.action === ACTION_REVISE
              }
              value={this.state.pmComment ? this.state.pmComment : this.state.PMName}
              maxLength="1000"
            />
          </div>
        </div>
        
        {this.state.action !== ACTION_APPROVE && this.state.action !== ACTION_REVISE && this.state.action !== ACTION_REVISECMS_APPROVE && (
          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button
                primary={true}
                disabled={this.state.isViewMode || this.state.isClickedApprovalBtn || this.state.ongoingRowCalculation}
                onClick={this.onSubmitForApproval}
              >
                Submit for Approval
              </Button>
              <Button
                primary={true}
                onClick={this.handleSaveButtonClick}
                disabled={this.state.isViewMode || this.state.isSaving || this.state.ongoingRowCalculation}
              >
                Save
              </Button>
              <Button onClick={this.onCancelButtonClick}>Cancel</Button>
            </div>
          </div>
        )}

        {/* {this.state.action === 'approve' && <div className="main-seperator" />} */}
        {this.state.action === ACTION_APPROVE && (
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5">
                  <label htmlFor="">Comment:</label>
                </div>
                <div className="col-md-12">
                  <ReactQuill
                    name="approvalcomment"
                    onChange={this.onChangeApprovalComment}
                    value={this.state.approvalComment}
                    maxLength="1000"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 btn-align-right">
                  <Button primary onClick={this.onApproveCMSheet}>
                    Approve
                  </Button>
                  <Button primary onClick={this.onRejectCMSheet}>
                    Reject
                  </Button>
                  <Button onClick={this.onCancelApproval}>Cancel</Button>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <Grid width="100%" data={this.state.approversList} resizable>
                    <Column field="GroupName" title="Group" width="200px" />
                    <Column field="Approver" title="Approver" width="200px" />
                    <Column field="Status" title="Status" width="150px" />
                    <Column field="updatedAt" title="Date and Time" width="150px" 
                      cell={props => <DateCell {...props} />} />
                    <Column
                      title="Comment"
                      cell={props => <ApprovalCommentCell {...props} />}
                      width="450px"
                    />
                    <Column field="" />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.action === ACTION_REVISECMS_APPROVE && (
          <div className="row">
            <div className="col-md-12">
            <div className="row">
                <div className="col-md-5">
                  <label htmlFor="">CMS Revision Comment:</label>
                </div>
                <div className="col-md-12">
                  <ReactQuill
                    name="reviseCmsPmComment"
                    value={this.state.reviseCmsPmComment}
                    maxLength="1000"
                    readOnly={
                      this.state.action === ACTION_REVISECMS_APPROVE
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <label htmlFor="">Comment:</label>
                </div>
                <div className="col-md-12">
                  <ReactQuill
                    name="approvalcomment"
                    onChange={this.onChangeApprovalComment}
                    value={this.state.approvalComment}
                    maxLength="1000"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 btn-align-right">
                  <Button primary onClick={this.onApproveReviseCMSheetRequest}>
                    Approve
                  </Button>
                  <Button primary onClick={this.onRejectReviseCMSheetRequest}>
                    Reject
                  </Button>
                  <Button onClick={this.onCancelApproval}>Cancel</Button>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <Grid width="100%" data={this.state.approversList} resizable>
                    <Column field="GroupName" title="Group" width="200px" />
                    <Column field="Approver" title="Approver" width="200px" />
                    <Column field="Status" title="Status" width="150px" />
                    <Column field="updatedAt" title="Date and Time" width="150px" 
                      cell={props => <DateCell {...props} />} />
                    <Column
                      title="Comment"
                      cell={props => <ApprovalCommentCell {...props} />}
                      width="450px"
                    />
                    <Column field="" />
                  </Grid>
                </div>
              </div>
              <div className="row">
              <div className="col-md-5">
                  <label htmlFor="">CMS Revision History:</label>
                </div>
                <div className="col-md-12">
                  <Grid width="100%" data={this.state.reviseCmsApproversList} resizable>
                    <Column field="GroupName" title="Group" width="200px" />
                    <Column field="Approver" title="Approver" width="200px" />
                    <Column field="Status" title="Status" width="150px" />
                    <Column field="updatedAt" title="Date and Time" width="150px" 
                      cell={props => <DateCell {...props} />} />
                    <Column
                      title="Comment"
                      cell={props => <ApprovalCommentCell {...props} />}
                      width="450px"
                    />
                    <Column field="" />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.action === ACTION_VIEW && (
          <div className="row">
            <div className="col-md-12">
              <div className="row" />
              <br />
              <div className="row">
                <div className="col-md-12">
                  <Grid width="100%" data={this.state.approversList} resizable>
                    <Column field="GroupName" title="Group" width="200px" />
                    <Column field="Approver" title="Approver" width="200px" />
                    <Column field="Status" title="Status" width="150px" />
                    <Column field="updatedAt" title="Date and Time" width="150px" 
                      cell={props => <DateCell {...props} />} />
                    <Column
                      title="Comment"
                      cell={props => <ApprovalCommentCell {...props} />}
                      width="450px"
                    />
                    <Column field="" />
                  </Grid>
                </div>
              </div>
              {this.state.reviseCmsApproversList && this.state.reviseCmsApproversList.length > 0 && (
                <div className="row">
                <div className="col-md-5">
                    <label htmlFor="">CMS Revision History:</label>
                  </div>
                  <div className="col-md-12">
                    <Grid width="100%" data={this.state.reviseCmsApproversList} resizable>
                      <Column field="GroupName" title="Group" width="200px" />
                      <Column field="Approver" title="Approver" width="200px" />
                      <Column field="Status" title="Status" width="150px" />
                      <Column field="updatedAt" title="Date and Time" width="150px" 
                        cell={props => <DateCell {...props} />} />
                      <Column
                        title="Comment"
                        cell={props => <ApprovalCommentCell {...props} />}
                        width="450px"
                      />
                      <Column field="" />
                    </Grid>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.action === ACTION_REVISE && (
          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} onClick={this.handleReviseCMSheet}>
                Revise
              </Button>
              <Button onClick={this.onCancelRevise}>Cancel</Button>
            </div>
          </div>
        )}

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showPrevCmsErrorDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessagePrevCmsErrorDialog} width="400px">

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <ul>
              {
                this.state.prevCmsLessEffortResources.map(resource =>
                  <div>
                    <li>
                      <div className="row">
                        <div className="col-8">
                          {resource.ProjectRoleName}
                        </div>
                      </div>
                    </li>
                  </div>
                )
              }
            </ul>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleMessagePrevCmsErrorDialog}>
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showVersionChangeConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleVersionChangeDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleVersionChangeDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={()=>{this.handleVersionChange(this.state.cmsVersionChangeData)}}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showCostRateChangedConfirmDialog === true && (
          <Dialog title="Confirm" onClose={()=>{this.setState({ updateCost:false, showCostRateChangedConfirmDialog: !this.state.showCostRateChangedConfirmDialog
         },()=>this.handleTabSelect({selected:2}))}} width="400px">
            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}
            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={()=>{this.setState({  updateCost:false, showCostRateChangedConfirmDialog: !this.state.showCostRateChangedConfirmDialog },()=>this.handleTabSelect({selected:2}))}}>
                No
              </button>
              <button className="k-button modal-primary" onClick={()=>{ this.setState({ updateCost:true, showCostRateChangedConfirmDialog: !this.state.showCostRateChangedConfirmDialog },()=>this.handleTabSelect({selected:2}))}}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showConfirmSaveDialog === true && (
          <Dialog title={this.state.confTitle} onClose={this.state.rejectDialogAction} width="400px">
            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
            <button className="k-button" onClick={this.state.rejectDialogAction}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showWorkflowSelectDialog === true && (
          <Dialog title="Select Workflow" onClose={this.toggleWorkflowSelectDialog} width="300px">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <p className="subtitle-workflow" style={{ textAlign: 'center' }}>
                    Please select the applicable workflow to send for approval.
                  </p>
                </div>
                <div className="row">
                  <form className="k-form modified">
                    <div className="k-form-field">
                      {this.state.matchingWorkflowList.map((option, i) => {
                        return (
                          <div className="field-mod">
                            <input
                              type="radio"
                              id={'' + option.id}
                              name={'' + option.id}
                              value={option.id}
                              checked={this.state.selectedWorkFlowId === option.id}
                              className="k-radio"
                              key={option.id}
                              onChange={this.setSelectedWorkflowId.bind(this, option.id)}
                            />
                            <label
                              key={option.id}
                              htmlFor={'' + option.id}
                              className="k-radio-label"
                              title={"Approvers: " + option.approvers}
                            >
                              {option.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleWorkflowSelectDialog}>
                No
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.setWorkflowToCMSheet}
                disabled={!this.state.selectedWorkFlowId}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    );
  }
}

export default CMSheet;
