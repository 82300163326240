import React, { Component } from 'react';
import {Link} from "react-router-dom";



class CRIdLinkCell extends Component {

    render() {
        return (
            <td>
            <Link
                to={{
                    pathname: '/changeRequest/edit',
                    changeReqId: this.props.dataItem.id,
                    action: 'view'
                }}
            >
                {this.props.dataItem.UniqueId}

            </Link>
            </td>
    );
    }
}

export default CRIdLinkCell;