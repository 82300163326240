import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;


/**
 * get Exchange Rate.
 * @returns {array} array list of Exchange Rate
 */
export function getExchangeRate() {
  const endPoint = apiUrl + '/masterData/exchangeRate/exchangerate';
  return http.get(endPoint);
}

/**
 * Search Exchange Rate by param.
 * @returns {array} array list of Exchange Rate
 * @param params
 */
export function searchExchangeRate(params) {
  const endPoint = apiUrl + '/masterData/exchangeRate/exchangerate/search';
  return http.post(endPoint, params);
}

/**
 * Save Exchange Rate when create/update.
 * @returns {array} array list of Exchange Rate
 * @param params
 */
export function saveExchangeRate(params) {
  const endPoint = apiUrl + '/masterData/exchangeRate/exchangerate/save';
  return http.post(endPoint, params);
}

/**
 * Update Exchange Rate by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param exchangerate
 * @param id
 */
export function updateExchangeRate(exchangerate, id) {
  const updateExchangeRateEndpoint = apiUrl + '/masterData/exchangeRate/exchangerate/update/' + id;
  return http.put(updateExchangeRateEndpoint, exchangerate);
}

/**
 * Update Exchange Rate status by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param IsActive
 * @param id
 */
export function updateExchangeRateStatus(IsActive, id) {
  const endPoint = apiUrl + '/masterData/exchangeRate/exchangerate/updateStatus/' + id;
  return http.put(endPoint, IsActive);
}

/**
 * Get Conversion Rate By Currency by given params.
 * @returns {array} array list of Conversion Rate details
 * @param currencyId
 * @param month
 * @param year
 */
export function getConversionRateByCurrency(currencyId, month, year) {
  const endPoint =
    apiUrl + '/masterData/exchangeRate/conversion-rate/currency/' + currencyId + '/date/' + year + '/' + month;
  return http.get(endPoint);
}
