import React, { Component } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { getCurrencies } from '../../../../masterData/common/CommonService';
import {
  retrieveConversionRate
} from '../../../cmSheet/CMSheetService';
import { formatNumber } from '../../../../integration/CommonUtils';
import * as loggerService from '../../../../integration/LoggerService';
import { LOG_TYPES } from '../../../../integration/IntegrationEnums';


class ChartSection extends Component {


  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      calculations: [],
      currencies: [],
      dataValueCurrency: null,
      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: ''
    };
  }

  static rowRender(trElement, props) {
    const isHeader = props.dataItem.isHeader;
    const contEffort = props.dataItem.contEffort;
    const className = 'summery-header-style';
    const contclass = 'contract-effort';
    const trProps = { class: isHeader === true ? className : null };
    const cedProps = { class: contEffort === true ? contclass : null };
    if (isHeader) {
      return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    } else if (contEffort) {
      return React.cloneElement(trElement, { ...cedProps }, trElement.props.children);
    } else 
    return trElement;
  }

  unsubscribe = (id) =>{
      this.setState({
        keyId:id
        });
    }
    subscribe = async(id) =>{
      if(this.state.keyId !== 10)
      {
        await this.setState({
          keyId:id
        });
        if(this.state.keyId === 10)
        {          
          this.populateCalculationsGrid();
          this.populateCurrencies();
        }        
      }
    }


  componentDidMount() {
    this.isMount = true;
    this.subscribe(10);
  }

  componentWillUnmount() {
    this.isMount = false;
    this.unsubscribe(10);
  }

  componentDidUpdate(prevProps) {
    const projectPropsString = JSON.stringify(this.props.project);
    const prevProjectPropsString = JSON.stringify(prevProps.project);
    const crPropsString = JSON.stringify(this.props.changeRequest);
    const crPrevPropsString = JSON.stringify(prevProps.changeRequest);
    const cmSheetPropsString = JSON.stringify(this.props.cmSheet);
    const cmSheetPrevPropsString = JSON.stringify(prevProps.cmSheet);
    const paymentMethodPropsString = JSON.stringify(this.props.selectedPaymentMethod);
    const prevpaymentMethodPropsString = JSON.stringify(prevProps.selectedPaymentMethod);
    if ((projectPropsString !== prevProjectPropsString || crPropsString !== crPrevPropsString || cmSheetPropsString !== cmSheetPrevPropsString || paymentMethodPropsString !== prevpaymentMethodPropsString) && this.isMount) {
      this.populateCalculationsGrid();
      this.setProjectCurrency();
    }
  }

  getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.project !== prevState.project || nextProps.changeRequest !== prevState.changeRequest || nextProps.cmSheet !== prevState.cmSheet || nextProps.selectedPaymentMethod !== prevState.selectedPaymentMethod) {
      this.populateCalculationsGrid();
      this.populateCurrencies();
    }
  }

  populateCurrencies = () => {
    getCurrencies()
      .then(res => {
        if (res.data && res.data.length > 0 && res.data[0].id) {
          if (this.isMount) {
            this.setState({
              currencies: res.data
            });
          }
          this.setProjectCurrency();
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  setProjectCurrency = () => {
    if (this.state.dataValueCurrency === null) {
      let currentCurrency = null;
      if (this.props.lastSelectedCurrencyId) {
        const filterCurrency = this.state.currencies.filter(
          obj => obj.id === this.props.lastSelectedCurrencyId
        );
        if (filterCurrency.length > 0) {
          currentCurrency = filterCurrency[0];
        }
      }

      if (this.isMount) {
        this.setState({
          dataValueCurrency: currentCurrency
        });
      }
    }
  };

  handleCurrencyChange = async event => {
    const value = event.target.value;
    let proStartDate = new Date(this.props.cmSheet.createdAt);

    if (this.props.cmSheet.Type === 'Project') {
      if (this.props.project.StartDate) {
        proStartDate = new Date(this.props.project.StartDate);
      }
    } else {
      if (this.props.changeRequest.StartDate) {
        proStartDate = new Date(this.props.changeRequest.StartDate);
      }
    }

    const prevRate = await retrieveConversionRate(
      this.state.dataValueCurrency.id,
      proStartDate.getMonth() + 1,
      proStartDate.getFullYear()
    );

    if (prevRate > 0) {
      const newRate = await retrieveConversionRate(
        value.id,
        proStartDate.getMonth() + 1,
        proStartDate.getFullYear()
      );

      if (newRate > 0) {
        if (value) {
          this.props.onCurrencyChange(prevRate, newRate, value.id);
          if (this.isMount) {
            this.setState({
              dataValueCurrency: value
            });
          }
        }

      } else {
        const message = 'Currency conversion rate not found for selected currency';
        const title = 'Error';
        this.toggleMessageDialog(message, title);
      }

    } else {
      const message = 'Currency conversion rate not found for previously selected currency';
      const title = 'Error';
      this.toggleMessageDialog(message, title);
    }


  };

  //order of the details changed HashanS
  populateCalculationsGrid = () => {
    const calculations = [
      {
        Field: 'Duration | Effort',
        Value1: '',
        Value2: '',
        isHeader: true
      },
      {
        Field: 'Est. Project Duration (Calendar Days)',
        Value1: '',
        Value2: this.props.cmSheet.ProjectDuration
      },
      {
        Field: 'Contracted Effort Days | Hours',
        Value1: formatNumber(this.props.cmSheet.TotalEffortDaysResourceAllocation),
        Value2: this.props.cmSheet.TotalEffortDaysResourceAllocation * 8
      },
      {
        Field: 'Non-billable Effort days | Cost',
        Value1: this.props.cmSheet.NonBillableResourceEffortDays,
        Value2: this.props.cmSheet.NonBillableResourceTotalCost,
      },
      {
        Field: 'Fixed Effort Days | Hours',
        Value1: formatNumber(this.props.cmSheet.TotalEffortDaysResourceAllocationFixed),
        Value2: this.props.cmSheet.TotalEffortDaysResourceAllocationFixed * 8,
        isHidden: this.props.selectedPaymentMethod == 3 ? false : true,
        contEffort: true

      },
      {
        Field: 'T&M Effort Days | Hours',
        Value1: formatNumber(this.props.cmSheet.TotalEffortDaysResourceAllocationTnM),
        Value2: this.props.cmSheet.TotalEffortDaysResourceAllocationTnM * 8,
        isHidden: this.props.selectedPaymentMethod == 3 ? false : true,
        contEffort: true
      },
      //-------------------------------------------------------------
      {
        Field: 'Project Value | Project Revenue',
        Value1: '',
        Value2: '',
        isHeader: true
      },
      {
        Field: 'Total Project Value to Customer',
        Value1: '',
        Value2: this.props.cmSheet.TotalProjectValue
      },
      {
        Field: 'Estimated OPE for Customer',
        Value1: '',
        Value2: this.props.cmSheet.EstimatedOPECustomer
      },
      {
        Field: 'Partner Commission',
        Value1: '',
        Value2: this.props.cmSheet.PartnerCommission
      },
      {
        Field: 'Revenue to Fortude After Discount',
        Value1: '',
        Value2: this.props.cmSheet.ProjectRevenueAfterDiscount
      },
      {
        Field: 'Average Rate per Day',
        Value1: '',
        Value2: parseFloat(this.props.cmSheet.AverageRatePerDay)
      },
      //-----------------------------------------------------------------
      {
        Field: 'Discounts | Deductions',
        Value1: '',
        Value2: '',
        isHeader: true
      },
      {
        Field: 'Value Discount / (Premium)',
        Value1:
          this.props.cmSheet.DiscountPercentage === 0
            ? formatNumber(Number(this.props.cmSheet.DiscountPercentage))
            : formatNumber(Number(this.props.cmSheet.DiscountPercentage)) + '',
        Value2: this.props.cmSheet.DiscountValue
      },
      {
        Field: 'Rate Discount / (Premium)',
        Value1: '',
        Value2: this.props.cmSheet.RateDiscount
      },
      {
        Field: 'Non-billable Expenses',
        Value1: '',
        Value2: this.props.cmSheet.NonBillableTotalOtherExpenses
      },
      //--------------------------------------------------------------------
      {
        Field: 'Profitability',
        Value1: '',
        Value2: '',
        isHeader: true
      },
      {
        Field: 'Gross margin % | Gross Profit From Resource Deployment',
        Value1:
          this.props.cmSheet.GrossProfitMargin !== 0
            ? formatNumber(this.props.cmSheet.GrossProfitMargin) + '%'
            : formatNumber(this.props.cmSheet.GrossProfitMargin),
        Value2: this.props.cmSheet.GrossProfitFromResourceDeployment
      },
      {
        Field: 'Net Margin % | Net Profit / (Loss)',
        Value1:
          this.props.cmSheet.NetProfitMargin !== 0
            ? formatNumber(this.props.cmSheet.NetProfitMargin) + '%'
            : formatNumber(this.props.cmSheet.NetProfitMargin),
        Value2: this.props.cmSheet.NetProfitLoss
      }
      // ,
      // {
      //   Field: 'Negative Discount/ (premium) | %',
      //   Value1: this.props.cmSheet.NetDiscountValue,
      //   Value2: this.props.cmSheet.NetDiscountPercentage
      // },
      // { Field: 'Impact of discount/ (premium) on NP margin', Value1: '', Value2: '' }
    ];

    // Enable/disable rows
    const calculationTemp = [];
    for (var item of calculations) {
      if (item.isHidden == undefined) {
        calculationTemp.push(item);
      } else if (!item.isHidden) {
        calculationTemp.push(item);
      }
    }

    this.setState(
      {
        calculations: calculationTemp
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  render() {
    return (
      <div className="row cms-grid">
        <div className="col-md-8 sub-chart-section">
          <div className="row">
            <div className="col-md-3">
              <label htmlFor="" className="mandatory">
                Currency:
              </label>
            </div>
            <div className="col-md-4">
              <DropDownList
                data={this.state.currencies}
                textField="Code"
                dataItemKey="id"
                value={this.state.dataValueCurrency}
                onChange={this.handleCurrencyChange}
              />
            </div>
          </div>
          <br />
          <Grid width="100%" data={this.state.calculations} rowRender={ChartSection.rowRender} className="summary-grid">
            <Column
              field="Field"
              title="Field"
              width="355px"
              headerClassName="calculation-grid-header"
            />
            <Column field="Value1" width="130px" headerClassName="calculation-grid-header" />
            <Column
              field="Value2"
              width="150px"
              cell={this.ValueCell}
              headerClassName="calculation-grid-header"
            />
          </Grid>
        </div>
        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    );
  }

  ValueCell = props => {
    const value = props.dataItem[props.field];

    if(props.dataItem.Field.toString() == 'Value Discount / (Premium)' || props.dataItem.Field.toString() == 'Rate Discount / (Premium)' ) {
      if (value === '') {
        return (
          <td>
          </td>
        );
      } else if (!isNaN(value) && value < 0){
        return (
          <td>
            <span>
              {isNaN(value) ? value : formatNumber(value)}
            </span>
          </td>
        );
      } else if(!isNaN(value) && value > 0){
        return (
          <td>
            <label className="text-red">
              {isNaN(value) ? value : formatNumber(value)}
            </label>
          </td>
        );
      }else{
        return (
          <td>
            <label>
              {isNaN(value) ? value : formatNumber(value)}
            </label>
          </td>
        );
      }
    }
    else{
      if (value === '') {
        return (
          <td>
          </td>
        );
      } else {
        return (
          <td>
            <label className={!isNaN(value) && value < 0 ? 'text-red' : ''}>
              {isNaN(value) ? value : formatNumber(value)}
            </label>
          </td>
        );
      }
    }

  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };
}

export default ChartSection;
