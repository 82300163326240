import http from '../HttpService';
//import { apiUrl } from '../config.json';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Save AuditTrails/ User activities. Ex: user log in.
 * @param auditTrail - (array)
 * @returns {array} auditTrail
 */
export function saveAuditTrail(auditTrail) {
  const endPoint = apiUrl + '/integration/auditTrail/save';
  return http.post(endPoint, auditTrail);
}

/**
 * Search AuditTrails.
 * @param auditTrail - (object)
 * @returns List of details with AuditTrails.
 */
export function searchAuditDetail(auditTrail) {
  const endPoint = apiUrl + '/integration/auditTrail/search';
  return http.post(endPoint, auditTrail);
}

/**
 * Get Reference Type.
 * @returns List of details with AuditTrails.
 */
export function getRefType() {
  const endPoint = apiUrl + '/integration/auditTrail/getRefType';
  return http.post(endPoint);
}

/**
 * Get Dist User Name.
 * @returns List of details with AuditTrails.
 */
export function getDistUserName() {
  const endPoint = apiUrl + '/integration/auditTrail/getUserName';
  return http.post(endPoint);
}

/**
 * Search All AuditTrails.
 * @param auditTrail - (object)
 * @returns List of details with AuditTrails.
 */
export function getAuditTrailDataAll(auditTrail) {
  const endPoint = apiUrl + '/integration/auditTrail/searchAll';
  return http.post(endPoint, auditTrail);
}
