import React, { Component } from "react";
import Button from '@progress/kendo-react-buttons/dist/es/Button';

class ResourceRequestStatusListCell extends Component {




  render() {
    return (
      <td className="action-buttons">
        
      </td>
    );
  }

}

export default ResourceRequestStatusListCell;
