module.exports = Object.freeze({
    Pending_Approval: { value: 1, name: 'Pending Approval' },
    Approved: { value: 2, name: 'Approved – Billable' },
    Rejected : { value: 3, name: 'Rejected ' },
    Auto_Generated: { value: 4, name: 'Automatic Approvals' },
    Approved_NB: { value: 5, name: 'Approved Non-Billable' },
    Approved_NB_ESE: { value: 6, name: 'Approved Non-Billable ESE' },
    Approved_NB_PMM: { value: 7, name: 'Approved Non-Billable PMM' },
    Approved_NB_RWK: { value: 8, name: 'Approved Non-Billable RWK' },
    Approved_NB_General: { value: 9, name: 'Approved Non-Billable General' },
    Leave: { value: 10, name: 'Leave' },
  });
  