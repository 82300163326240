import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import {
    ComboBox
} from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
    getBillingDiviIntegrated,
    getRegionInfoIntegrated,
    getResourceRegionsIntegrated
} from '../integration/MasterDataService';
import * as loggerService from '../integration/LoggerService';
import { LOG_TYPES } from '../integration/IntegrationEnums';
import { searchQmsGenerateTemp, getQMSTemplatebyId } from './search/QmsTempService';
import { filterBy } from '@progress/kendo-data-query';
import { getAllProjects } from '../projectManagement/project/ProjectService';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { getAllChangeRequests } from '../projectManagement/changeRequest/ChangeRequestService';
import { saveQMSDetails } from './qmsListView/QmsNumService';
import { getUserList } from './../userManagement/user/userService';
import { Redirect } from 'react-router-dom';
import SuccessImg from '../../src/ui/static/images/checked.svg';
import ErrorImg from '../../src/ui/static/images/cancel.svg';

const apiUrl = require('../../src/config.js').get(process.env.REACT_APP_ENV).apiUrl;

class GenerateQMSNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            divisions: [],
            divisionsAll: [],
            templateNames : [],
            templateNamesAll : [],
            selectedDivision: null,
            templateNamesInitial : [],
            projects: [],
            changeRequests: [],
            projectsAndChangeRequests: [],
            selectedTemplateName: null,
            selectedProjectName: '',
            userId: '',
            QMSDocumentTitle: '',
            selectedTempId: null,
            selectedQMSTemplateName: null,
            qmsTitle: null,
            isErrorMessageVisible: false,
            redirectToHome: false,
            visibleConfirmationDialog: false,
            disableDocumentName: true,
            billingRegions: [],
            billingRegionsAll: [],
            templateNamesRegion : [],
            templateNamesRegionAll : [],
            selectedRegionValue: '',
            loggedUser: '',
            description: '',
            isClickedSave: false
        }
    }

    async componentDidMount() {
        const userDetails = window.LOGGED_USER;
        this.setState({
            loggedUser: userDetails.userId
        });
        this.isMount = true;
        window.scrollTo(0, 0);
        this.setHeaderTitle();
        this.populateDivisions();
        this.populateTemplates();
        this.populateProjectsAndCrs();
        await this.populateUserId();
        this.populateRegions();
      }

    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
          this.props.onHeaderTitleChange('QMS Number Generator');
        }
      };

    toggleDialog = (message, title) => {
        this.setState({
            visible: !this.state.visible,
            dialogMessage: message,
            dialogTitle: title,
        });
    };

    populateProjectsAndCrs = async () => {
        await getAllProjects().then(response => {
            this.allProjects = [];
            for (var item of response.data) {
                if(item.IsActive){
                    let displayName = item.ProjectName + " - " + item.OPID;
                    this.allProjects.push(displayName);
                }
            }

            this.allProjects.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));

            if (this.isMount) {
                this.setState({
                    projects: this.allProjects
                });
            }
        })
          .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
          });

          await getAllChangeRequests().then(response => {
            this.allChangeRequests = [];
            for (var item of response.data) {
                if(item.IsActive){
                    let displayName = item.CRName + " - " + item.OPID;
                    this.allChangeRequests.push(displayName);
                }
            }

            this.allChangeRequests.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));

            if (this.isMount) {
                this.setState({
                    changeRequests: this.allChangeRequests
                });
            }
        })
          .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
          });

          const allProjectsAndChangeRequests = this.state.projects.concat(this.state.changeRequests);
          if (this.isMount) {
            this.setState({
                projectsAndChangeRequests: allProjectsAndChangeRequests
            });
        }
    };

    populateDivisions = () => {
        getBillingDiviIntegrated().then(response => {
            const activeBillingDivisions = response.data.filter(value => value.IsActive === true && value.SFCode !== null );
            this.allPractices = activeBillingDivisions;
            this.setState({
                divisions: activeBillingDivisions,
                divisionsAll: activeBillingDivisions
            });
        })
          .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    };

    populateRegions = () => {
        getRegionInfoIntegrated().then(response => {
            const billingRegion = [];
            response.data.forEach(function (region) {
                if (region.IsActive === true) {
                    billingRegion.push(region);
                }
            });
            this.setState({
                billingRegions: billingRegion,
                billingRegionsAll: billingRegion
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateTemplates = () => {
        const fields = {};
        searchQmsGenerateTemp(fields)
          .then(res => {
              if (this.isMount) {
                  const activeTemp = res.data.filter(value => value.IsActive === true);
                  this.setState({
                      templateNamesInitial: activeTemp,
                      templateNames : activeTemp,
                      templateNamesAll : activeTemp
                  });
              }
          })
          .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    }

    populateUserId = () =>{
        getUserList().then(response => {
            console.log('=========response.data=========',response.data);
            console.log('=========this.state.loggedUser=========',this.state.loggedUser);
            for(const user of response.data){
                if(user.Username.toUpperCase() === this.state.loggedUser.toUpperCase()){
                    console.log('=========user=========',user);
                    this.setState({
                        user: user,
                        userId: user.id
                    });
                }
            }
        }).catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };


    handleSubmit = async (event) => {
        
        if (!this.validation()){
            event.preventDefault();
            if(this.isMount){
                this.setState({ 
                    isErrorMessageVisible: true,
                    visible: true,
                    showErrorImage: true,
                    showSuccessImage: false
                });            
            }
            this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
        }
        else{
            if(this.isMount){
                this.setState({
                    isErrorMessageVisible: false
                });
            }
            let qmsTemp = null;
            await getQMSTemplatebyId(this.state.selectedTemplateName.id).then(response => {
                qmsTemp = response.data[0];
            }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

            const QMSNumber = Number(qmsTemp ? qmsTemp.QMSNumber : this.state.selectedTemplateName.QMSNumber) + 1 ;
            var updatedQmsNo = '';
            var QMSDocumentTitle = '';
            var QMSDownloadDocTitle = '';
            const selectedTempFileName = this.state.selectedTemplateName.FileUpload.FileName;
            const fileType = selectedTempFileName.split('.');

            if(QMSNumber.toString().length === 1) {
                updatedQmsNo = ('000').concat(QMSNumber.toString());
                QMSDocumentTitle = this.state.selectedTemplateName.DocCode.concat(' ').concat(updatedQmsNo);
                QMSDownloadDocTitle = this.state.selectedTemplateName.DocName.concat('_').concat(this.state.selectedTemplateName.DocCode).concat(' ').concat(updatedQmsNo).concat('.').concat(fileType[fileType.length - 1]);
            }else if(QMSNumber.toString().length === 2) {
                updatedQmsNo = ('00').concat(QMSNumber.toString());
                QMSDocumentTitle = this.state.selectedTemplateName.DocCode.concat(' ').concat(updatedQmsNo);
                QMSDownloadDocTitle = this.state.selectedTemplateName.DocName.concat('_').concat(this.state.selectedTemplateName.DocCode).concat(' ').concat(updatedQmsNo).concat('.').concat(fileType[fileType.length - 1]);
            }else if(QMSNumber.toString().length === 3) {
                updatedQmsNo = ('0').concat(QMSNumber.toString());
                QMSDocumentTitle = this.state.selectedTemplateName.DocCode.concat(' ').concat(updatedQmsNo);
                QMSDownloadDocTitle = this.state.selectedTemplateName.DocName.concat('_').concat(this.state.selectedTemplateName.DocCode).concat(' ').concat(updatedQmsNo).concat('.').concat(fileType[fileType.length - 1]);
            }else{
                updatedQmsNo = QMSNumber;
                QMSDocumentTitle = this.state.selectedTemplateName.DocCode.concat(' ').concat(updatedQmsNo);
                QMSDownloadDocTitle = this.state.selectedTemplateName.DocName.concat('_').concat(this.state.selectedTemplateName.DocCode).concat(' ').concat(updatedQmsNo).concat('.').concat(fileType[fileType.length - 1]);
            }
            await this.setState({
                isClickedSave: true,
                qmsTitle: QMSDownloadDocTitle
            },
            () => {
                document.getElementById("submitQMS").click();
            });
            let division = this.state.selectedDivision.Name;
            let documentTemplateName = this.state.selectedTemplateName.DocName;
            let projectName = this.state.selectedProjectName;
            let qmsNumber = QMSDocumentTitle;
            let createdBy = this.state.userId;

            if (this.state.selectedProjectName !== '') {
                const validProject = this.state.projectsAndChangeRequests.filter(val => val === this.state.selectedProjectName);
                if (validProject && validProject.length <= 0) {
                    projectName = '';
                }
            }

            const QMSDetailsObj = {
                division: division,
                documentTemplateName: documentTemplateName,
                projectName: projectName,
                qmsNumber: qmsNumber,
                createdBy: createdBy,
                updatedQmsNo: updatedQmsNo,
                qmsId: this.state.selectedTemplateName.id
            };
            console.log('=======QMSDetailsObj========',QMSDetailsObj);
            let QMSDocumentName = this.state.selectedTemplateName.DocName.concat('_').concat(QMSDocumentTitle);
            await saveQMSDetails(QMSDetailsObj).then(response => {
                this.toggleDialog(`${QMSDocumentName} is successfully generated.`, 'Success');
                this.populateDivisions();
                this.populateTemplates();
                this.setState({
                    showSuccessImage: true,
                    showErrorImage: false
                });
            }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleDialog(error.response.data, 'Error');
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
            });

            this.setState({
                selectedDivision: '',
                selectedTemplateName: '',
                selectedProjectName: '',
                isFormDirty: false,
                disableDocumentName: true,
                selectedRegionValue: '',
                description: ''
            });
        }      
    }

    validation = () => {
        if (
            this.validateProperty(this.state.selectedDivision)
              .toString()
              .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.selectedTemplateName)
              .toString()
              .includes('error')
        ) {
            return false;
        }
        else if (
            this.state.selectedDivision.Name === "Legal and Compliance" &&
            this.validateProperty(this.state.selectedRegionValue)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else {
            return true;
        }
    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    onClickCancel = () => {
        if(this.state.isFormDirty === true){
         this.toggleConfirmationDialog('The form contains unsubmitted data, Do you want to close it?', 'Confirm');
        }
        else{
         this.setState({
             redirectToHome: true
         });
        }
     };

    onCancelOk = (event) => {
        event.preventDefault();
        if (this.isMount) {
            this.setState({
                selectedDivision: '',
                selectedTemplateName: '',
                selectedProjectName: '',
                showSuccessImage: false,
                isFormDirty: false,
                redirectToHome: true,
                selectedRegionValue : '',
                description: ''
            });
        }
        this.toggleConfirmationDialog('', null);
    };

    toggleConfirmationDialog = (message, title) => {
        this.setState({
            visibleConfirmationDialog: !this.state.visibleConfirmationDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    handleOnChangeDivision = event => {
        const valueObj = event.target.value;
        const field = event.target.name;
        if (this.isMount) {
            this.setState({
                [field]: valueObj,
                isFormDirty: valueObj ? true : false,
            });
        }
        if(valueObj !== null && valueObj.Name != "Legal and Compliance"){
            const templatesForDiv = this.state.templateNamesInitial.filter(value => value.DivisionId === valueObj.id);
            this.setState({
                templateNames: templatesForDiv,
                templateNamesAll : templatesForDiv,
                disableDocumentName: false
            });
        } else if(valueObj !== null && valueObj.Name === "Legal and Compliance"){
            const templatesForDiv = this.state.templateNamesInitial.filter(value => value.DivisionId === valueObj.id);
            this.setState({
                templateNamesRegion: templatesForDiv,
                templateNamesRegionAll : templatesForDiv,
                disableDocumentName: true
            });
        }
        else{
            this.setState({
                templateNames: this.state.templateNamesInitial,
                templateNamesAll : this.state.templateNamesInitial,
                disableDocumentName: true,
                selectedTemplateName: null
            });
            this.populateDivisions();
        }

    };

    filterChangeDivision = event => {
        const field = event.target.name;
            if (this.isMount) {
                        this.setState({
                            divisions: this.filterComboData(event.filter, this.state.divisionsAll)
                        });
            }
    };

    handleOnChangeRegion = event => {
        const valueObj = event.target.value;
        const field = event.target.name;
        if (this.isMount) {
            this.setState({
                [field]: valueObj,
                isFormDirty: valueObj ? true : false,
            });
        }
        if(valueObj !== null){
            const templatesForDiv = this.state.templateNamesRegionAll.filter(value => value.RegionId === valueObj.id);
            this.setState({
                templateNames: templatesForDiv,
                templateNamesAll : templatesForDiv,
                disableDocumentName: false
            });
        }else{
            this.setState({
                templateNames: this.state.templateNamesInitial,
                templateNamesAll : this.state.templateNamesInitial,
                disableDocumentName: true,
                selectedTemplateName: null
            });
            this.populateDivisions();
        }

    };

    filterChangeRegion = event => {
        const field = event.target.name;
        if (this.isMount) {
            this.setState({
                divisions: this.filterComboData(event.filter, this.state.billingRegionsAll)
            });
        }
    };

    handleOnChangeTemplateName = event => {
        const valueObj = event.target.value;
        const field = event.target.name;

        if (this.isMount) {
            this.setState({
                [field]: valueObj,
                isFormDirty: valueObj ? true : false,
            });
        }
        if(valueObj !== null) {
            const divisionsOfTemp = this.allPractices.filter(value => value.id === valueObj.DivisionId);
            const selectedTempId = valueObj.FileId;
            const selectedTempFileName = valueObj.FileUpload.FileName;
            const QMSNumber = Number(valueObj.QMSNumber) + 1 ;
            const fileType = selectedTempFileName.split('.');
            var updatedQmsNo = '';
            var QMSDocumentTitle = '';
            
            if(valueObj.Description.length > 0) {
                this.setState({
                    description: valueObj.Description
                })
            } else {
                this.setState({
                    description: ''
                })
            }

            if(QMSNumber.toString().length === 1) {
                console.log('========000 QMSNumber.toString()========',QMSNumber.toString());
                updatedQmsNo = ('000').concat(QMSNumber.toString());
                QMSDocumentTitle = valueObj.DocName.concat('_').concat(valueObj.DocCode).concat(' ').concat(updatedQmsNo).concat('.').concat(fileType[fileType.length - 1]);
            }else if(QMSNumber.toString().length === 2) {
                console.log('========00 QMSNumber.toString()========',QMSNumber.toString());
                updatedQmsNo = ('00').concat(QMSNumber.toString());
                QMSDocumentTitle = valueObj.DocName.concat('_').concat(valueObj.DocCode).concat(' ').concat(updatedQmsNo).concat('.').concat(fileType[fileType.length - 1]);
            }else if(QMSNumber.toString().length === 3) {
                console.log('========0 QMSNumber.toString()========',QMSNumber.toString());
                updatedQmsNo = ('0').concat(QMSNumber.toString());
                QMSDocumentTitle = valueObj.DocName.concat('_').concat(valueObj.DocCode).concat(' ').concat(updatedQmsNo).concat('.').concat(fileType[fileType.length - 1]);
            }else{
                console.log('========QMSNumber========',QMSNumber);
                updatedQmsNo = QMSNumber;
                QMSDocumentTitle = valueObj.DocName.concat('_').concat(valueObj.DocCode).concat(' ').concat(updatedQmsNo).concat('.').concat(fileType[fileType.length - 1]);
            }
            console.log('========QMSDocumentTitle========',QMSDocumentTitle);
            this.setState({
                divisions: divisionsOfTemp,
                divisionsAll : divisionsOfTemp,
                selectedTempId: selectedTempId,
                selectedQMSTemplateName: selectedTempFileName,
                qmsTitle : QMSDocumentTitle
            });
        }else{
            this.setState({
                divisions: this.allPractices,
                divisionsAll : this.allPractices
            });
        }

    };

    filterChangeTemplateName = event => {
        const field = event.target.name;
        if (this.isMount) {
            this.setState({
                templateNames: this.filterComboData(event.filter, this.state.templateNamesAll)
            });
        }
    };

    filterComboData(filter, allData) {
        const data = allData.slice();
        return filterBy(data, filter);
    }

    handleOnChangeProjectAndCr = (event) => {
        const value = event.target.value;

        this.setState({
            projectsData: this.filterProjectsAnsCrData(value),
            selectedProjectName: value,
            isFormDirty: value ? true : false,
        });
    }

    filterProjectsAnsCrData(value) {
        const data = this.state.projectsAndChangeRequests;
        const filter = {
            value: value,
            operator: 'contains',
            ignoreCase: true
        };
        return value ? filterBy(data, filter) : data;
    }
    render() {
        if (this.state.redirectToHome === true) {
            return <Redirect to="/" />;
        }
        return (
            <div className="main-card">
                <div className="row">
                    <div className="col-md-9">
                        <div className="main-heading">QMS Number Generator</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="d-block">
                            <label>Department:</label>
                        </div>
                        <div className="">
                            <ComboBox
                              placeholder="Please Select"
                              data={this.state.divisions}
                              value={this.state.selectedDivision}
                              textField="Name"
                              dataItemKey="id"
                              onChange={this.handleOnChangeDivision}
                              name="selectedDivision"
                              filterable={true}
                              onFilterChange={this.filterChangeDivision}
                            />
                            
                            {this.state.isErrorMessageVisible === true ? (
                                <span className={this.validateProperty(this.state.selectedDivision)}>
                                    Please select a Department
                                </span>
                            ) : null}
                        </div>
                    </div>
                </div>

                {this.state.selectedDivision && this.state.selectedDivision.Name === "Legal and Compliance" && (
                <div className="row">
                    <div className="col-md-4">
                        <div className="d-block">
                            <label>Region:</label>
                        </div>
                        <div className="">
                            <ComboBox
                                placeholder="Please Select"
                                data={this.state.billingRegions}
                                value={this.state.selectedRegionValue}
                                textField="Name"
                                dataItemKey="id"
                                onChange={this.handleOnChangeRegion}
                                name="selectedRegionValue"
                                filterable={true}
                                onFilterChange={this.filterChangeRegion}
                            />

                            {this.state.isErrorMessageVisible === true ? (
                                <span className={this.validateProperty(this.state.selectedRegionValue)}>
                                    Please select a Region
                                </span>
                            ) : null}
                        </div>
                    </div>
                </div>
                )}

                <div className="row">
                <div className="col-md-4">
                        <div className="d-block">
                            <label>Document Name:</label>
                        </div>
                        <div className="d-block">
                            <ComboBox
                              placeholder="Please Select"
                              data={this.state.templateNames}
                              value={this.state.selectedTemplateName}
                              textField="DocName"
                              dataItemKey="id"
                              onChange={this.handleOnChangeTemplateName}
                              name="selectedTemplateName"
                              filterable={true}
                              onFilterChange={this.filterChangeTemplateName}
                              disabled={this.state.disableDocumentName}
                            />
                            {this.state.isErrorMessageVisible === true ? (
                                <span className={this.validateProperty(this.state.selectedTemplateName)}>
                                    Please select a document Name
                                </span>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="d-block">
                            <label>Description:</label>
                        </div>
                        <div className="d-block">
                        <Input
                            type="text"
                            name="description"
                            value={this.state.description}
                            disabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="d-block">
                            <label>Project/CR Name:</label>
                        </div>
                        <div className="d-block">
                            <AutoComplete
                                data={this.state.projectsData}
                                value={this.state.selectedProjectName}
                                onChange={this.handleOnChangeProjectAndCr}
                                name="selectedProjectName"
                                style={{width:'100%'}}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 btn-align-right">
                        <Button primary={true} type="submit" onClick={this.handleSubmit}>
                            Submit
                        </Button>
                        {this.state.isClickedSave && (
                            <a href={`${apiUrl}/integration/fileUpload/downloadqms?path=QMSTemplate_${encodeURIComponent(this.state.selectedQMSTemplateName)}&&qmsNum=${encodeURIComponent(this.state.qmsTitle)}`} target="_blank">
                                <Button  id={"submitQMS"} className='d-none'>
                                    Submit
                                </Button>
                            </a>
                        )}
                        <Button type="button" onClick={this.onClickCancel} style={{ marginRight: '5px' }}>
                            Cancel
                        </Button>
                    </div>
                </div>

                <div>
                    {this.state.visible === true && (
                        <Dialog
                            title={this.state.dialogTitle}
                            onClose={this.toggleDialog}
                            width="400px">
                            {this.state.showSuccessImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={SuccessImg} alt="Success"/>
                                </div>
                            )}

                            {this.state.showErrorImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={ErrorImg} alt="Error Image"/>
                                </div>
                            )}
                            <p style={{ margin: "25px", textAlign: "center" }}>
                                {this.state.dialogMessage}
                            </p>
                            <DialogActionsBar>
                                <button
                                    className="k-button modal-primary"
                                    onClick={
                                        this.toggleDialog
                                    }>
                                    OK
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
                <div>
                    {this.state.visibleConfirmationDialog === true && (
                        <Dialog
                            title={this.state.dialogTitle}
                            onClose={this.toggleConfirmationDialog}
                            width="400px">
                            {this.state.showSuccessImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={SuccessImg} alt="Success"/>
                                </div>
                            )}

                            {this.state.showErrorImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={ErrorImg} alt="Error Image"/>
                                </div>
                            )}
                            <p style={{ margin: "25px", textAlign: "center" }}>
                                {this.state.dialogMessage}
                            </p>
                            <DialogActionsBar>
                                <button
                                    className="k-button"
                                    onClick={this.toggleConfirmationDialog}>
                                    No
                                </button>
                                <button
                                    className="k-button modal-primary"
                                    onClick={this.onCancelOk}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
            </div>
        );
    }
}

export default GenerateQMSNumber;
