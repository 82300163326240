import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * getProjectResources - get All Resources available in the system
 * @returns {object} All PM Resources.
 */
export function updateResourceRequestResourceDetails(resourceRequestResourceData) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/update';
    return http.put(endPoint, resourceRequestResourceData);
}

/**
 * submitForResourceAllocation - submit for resource allocation
 * @returns {object} All Resources.
 */
export function submitForResourceAllocation(resourceRequestResourceData) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/submitForAllocation/';
    return http.put(endPoint, resourceRequestResourceData);
}


/**
 * submitForResourceAllocation - submit for resource allocation
 * @returns {object} All Resources.
 */
export function submitForResourceSoftBook(selectedLineArray) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/submitForSoftBooking/';
    return http.put(endPoint, selectedLineArray);
}

/**
 * deallocationEmail - deallocation for resource request
 * @returns {object} All Resources.
 */
export function deallocationEmail(request) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/deallocationEmail/';
    return http.put(endPoint, request);
}



/**
 * submitForResourceAllocation - submit for resource allocation
 * @returns {object} All Resources.
 */
export function submitForResourceDeallocate(request) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/submitForDeallocate/';
    return http.put(endPoint,request);
}

/**
 * submitForResourceAllocation - submit for resource allocation
 * @returns {object} All Resources.
 */
export function submitForResourceDeallocateSubLine(request) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/submitForDeallocateSub/';
    return http.put(endPoint,request);
}

/**
 * submitForResourceAllocation - submit for resource allocation
 * @returns {object} All Resources.
 */
export function submitForResourceSoftBookSubLine(selectedLineArray) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/submitForSoftBookingSubline/';
    return http.put(endPoint, selectedLineArray);
}

/**
 * createNewPMResourceRequest - create new PM resource request available in the system
 * @returns {object} PM Resource Request.
 */
export function createNewPMResourceRequest(resourceRequestResourceData) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/create/';
    return http.post(endPoint, resourceRequestResourceData);
}

/**
 * getPendingResourceRequestTasks - get All Pending Resource Request Task available in the system
 * @returns {object} All Pending Resource Request Tasks.
 */
export function getPendingResourceRequestTasks(loggedInUserIsAResourcePlanner) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/getpendingresourcerequests/';
    return http.post(endPoint, loggedInUserIsAResourcePlanner);
}

export function approveResourceRequest(resourceRequestResourceData) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/approveRequest/';
    return http.post(endPoint, resourceRequestResourceData);
}

export function rejectResourceRequest(resourceRequestResourceData) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/rejectRequest/';
    return http.post(endPoint, resourceRequestResourceData);
}

export function getAllApproversForResource(resourceRequestData) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/getAllApproversForResource';
    return http.post(endPoint,resourceRequestData);
}

/**
 * deallocateResourceRequestItems - update/delete, Resource Request mainLine(s)/subline(s)
 * @param items
 * @returns {object} array object
 */
export function deallocateResourceRequestItems(items) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/deallocateResourceRequestItems/';
    return http.put(endPoint, items);
}

/**
 * presalesEndDateChange -change end date of presales resources
 * @returns {object} presales resources.
 */
export function presalesEndDateChange(presalesData) {
    const endPoint = apiUrl + '/resourceManagement/resourceRequest/presalesEndDateChange';
    return http.put(endPoint, presalesData);
}