import React, { Component } from 'react';
import approvedStatus from '../../enums/ApprovedStatusTypes';

class TotalPerWeek extends Component {
    constructor(props) {
      super(props);
      this.state = {
        totalPerWeek: 0
      };
    }
    componentDidMount() {
      let totalPerWeekHour = 0;
      let totalPerWeekMinute = 0;
      this.props.data.dataItem.Mon.cards.length !== 0 &&
        this.props.data.dataItem.Mon.cards.forEach(x => {
          if (
            x.approvedStatus !== approvedStatus.Rejected.value &&
            x.approvedStatus !== approvedStatus.Leave.value
          ) {
            totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
            if (typeof x.duration.split(':')[1] !== 'undefined') {
              totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
            }
          }
        });
      this.props.data.dataItem.Tue.cards.length !== 0 &&
        this.props.data.dataItem.Tue.cards.forEach(x => {
          if (
            x.approvedStatus !== approvedStatus.Rejected.value &&
            x.approvedStatus !== approvedStatus.Leave.value
          ) {
            totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
            if (typeof x.duration.split(':')[1] !== 'undefined') {
              totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
            }
          }
        });
      this.props.data.dataItem.Wed.cards.length !== 0 &&
        this.props.data.dataItem.Wed.cards.forEach(x => {
          if (
            x.approvedStatus !== approvedStatus.Rejected.value &&
            x.approvedStatus !== approvedStatus.Leave.value
          ) {
            totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
            if (typeof x.duration.split(':')[1] !== 'undefined') {
              totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
            }
          }
        });
      this.props.data.dataItem.Thu.cards.length !== 0 &&
        this.props.data.dataItem.Thu.cards.forEach(x => {
          if (
            x.approvedStatus !== approvedStatus.Rejected.value &&
            x.approvedStatus !== approvedStatus.Leave.value
          ) {
            totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
            if (typeof x.duration.split(':')[1] !== 'undefined') {
              totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
            }
          }
        });
      this.props.data.dataItem.Fri.cards.length !== 0 &&
        this.props.data.dataItem.Fri.cards.forEach(x => {
          if (
            x.approvedStatus !== approvedStatus.Rejected.value &&
            x.approvedStatus !== approvedStatus.Leave.value
          ) {
            totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
            if (typeof x.duration.split(':')[1] !== 'undefined') {
              totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
            }
          }
        });
      this.props.data.dataItem.Sat.cards.length !== 0 &&
        this.props.data.dataItem.Sat.cards.forEach(x => {
          if (
            x.approvedStatus !== approvedStatus.Rejected.value &&
            x.approvedStatus !== approvedStatus.Leave.value
          ) {
            totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
            if (typeof x.duration.split(':')[1] !== 'undefined') {
              totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
            }
          }
        });
      this.props.data.dataItem.Sun.cards.length !== 0 &&
        this.props.data.dataItem.Sun.cards.forEach(x => {
          if (
            x.approvedStatus !== approvedStatus.Rejected.value &&
            x.approvedStatus !== approvedStatus.Leave.value
          ) {
            totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
            if (typeof x.duration.split(':')[1] !== 'undefined') {
              totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
            }
          }
        });
      this.setState({
        totalPerWeek:
          totalPerWeekMinute === 0
            ? totalPerWeekHour + ':' + '00'
            : totalPerWeekHour +
              parseInt(totalPerWeekMinute / 60) +
              ':' +
              (totalPerWeekMinute % 60).toString().padStart(2, '0')
      });
    }
    render() {
      return <td className="text-center totperweek">{this.state.totalPerWeek}</td>;
    }
  }
  export default TotalPerWeek;