import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class EditRoleActionsCell extends Component {
    render() {
        const { OnDeactivate } = this.props;
        const { OnActivate } = this.props;
        const role = this.props.dataItem;
        return (
            <td className="action-buttons">
                {role.IsActive === true && (role.Rolename !== "SuperAdmin" || window.USER_PERMISSION.indexOf('ROLE_MGT') > -1) && (
                    <Link
                        to={{
                            pathname: '/admin/createRole/createRole',
                            role: role,
                            action: 'edit'
                        }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-edit" title="Edit" />
                        </div>
                    </Link>
                )}
                {role.IsActive === true && (role.Rolename !== "SuperAdmin" || window.USER_PERMISSION.indexOf('ROLE_MGT') > -1) && (
                    <Link
                        to={this.props}
                        onClick={(event) => OnDeactivate(event, this.props.dataItem.id)}
                    >
                        <div className="rounded-link">

                            <span className="k-icon k-i-unsort" title="Deactivate" />
                        </div>
                    </Link>
                )}
                {role.IsActive === false && window.USER_PERMISSION.indexOf('ROLE_MGT') > -1 && (
                    <Link
                        to={this.props}
                        onClick={(event) => OnActivate(event, this.props.dataItem.id)}
                    >
                        <div className="rounded-link">

                            <span className="k-icon k-i-check" title="Activate" />
                        </div>
                    </Link>
                )}
            </td>
        );
    }
}

export default EditRoleActionsCell;
