import React from 'react';
import { Input } from "@progress/kendo-react-inputs";
import { Tooltip } from "reactstrap";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import FormValidator from '../../integration/components/FormValidator';
import { getProjectStatuses } from "../project/ProjectService";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Redirect } from 'react-router';
import ReactQuill from "react-quill";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import * as loggerService from "../../integration/LoggerService";
import { getFormattedDate, reStructureDate } from "../../integration/CommonUtils";
import EditChangeRequest from "./EditChangeRequest";
import $ from "jquery";
import moment from "moment";

class EditCRDetails extends React.Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'CRName',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter a CR name.'
            },
            {
                field: 'statusValue',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select a CR status.'
            },
            {
                field: 'CRDescription',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter the CR description.'
            }
        ]);

        this.state = {
            CRName: '',
            CRDescription: '',
            startDate: null,
            startDateMin: new Date(),
            isFormDirty: false,
            permissions: {
                CRName: true,
                CRDescription: true,
                description: true,
                startDate: null,
                status: true,
                endDate: false,
                endDateAugmentation:false,
                endDateBlanketWork:false
            },
            validation: this.validator.valid(),
            showStartDateValidationError: false,
            statusItems: [],
            statusValue: null,
            disableCRStatus: false,
            visible: false,
            dialogMessage: '',
            dialogTitle: '',
            showEndDateValidationError: false,
            isViewMode: false,
            isEditMode: false
        };
    }

    createStateObject = source => {
        if (source) {
            return Object.assign({}, source);
        } else {
            return null;
        }
    };

    componentDidMount() {

        this.setStateFromCR(this.props.changeReqDetails);
        this.setState({
            endDate: this.props.endDate,
            endDateAugmentation:this.props.endDateAugmentation,
            endDateBlanketWork:this.props.endDateBlanketWork
        });

        this.populateDropDowns();
        this.populateEndDateInEdit();

        $(document).ready(function () {
            $("div.title-remove > span > span > span:last-child").removeAttr("title");
        });
    }

    componentDidUpdate(prevProps) {
        if((this.props.billingDiviValue && this.props.billingDiviValue.id === 30) && (this.props.changeReqDetails.Type && this.props.changeReqDetails.Type.id === 3)){
            this.props.permissions.endDate = true;
        } else if ((this.props.billingDiviValue && this.props.billingDiviValue.id !== 30) || (this.props.changeReqDetails.Type && this.props.changeReqDetails.Type.id !== 3)){
            this.props.permissions.endDate = false;
        }
        else if((this.props.changeReqDetails.Type && this.props.changeReqDetails.Type.id === 6)){
            this.props.permissions.endDateAugmentation = true;
        }
        else if((this.props.changeReqDetails.Type && this.props.changeReqDetails.Type.id !== 6)){
            this.props.permissions.endDateAugmentation = false;
        }
        else if((this.props.changeReqDetails.Type && this.props.changeReqDetails.Type.id === 5)){
            this.props.permissions.endDateBlanketWork = true;
        }
        else if((this.props.changeReqDetails.Type && this.props.changeReqDetails.Type.id !== 5)){
            this.props.permissions.endDateBlanketWork = false;
        }

        if (this.props !== prevProps) {

            this.setState({
                isOnHold: this.props.isStatusOnHold
            });
            if (this.state.isOnHold) {
                this.props.permissions.CRName = false;
                this.props.permissions.startDate = false;
            }
        }
    }



    populateEndDateInEdit = () => {
        if (this.props.action === 'edit') {
            this.state.permissions.practice = false;
            this.state.permissions.engagementType = false;
        }
    }

    populateDropDowns = () => {
        getProjectStatuses().then(response => {
            let list = response.data;
            // Remove Billable with CR
            list = response.data.filter(obj => obj.Code !== 'BILLABALE_CR');
            this.setState({
                statusItems: list
            });
            if (this.props.action !== 'edit' && this.props.action !== 'view') {
                const presalesOption = response.data.filter(obj => obj.Code === 'PRESALES');
                this.setState({
                    statusValue: presalesOption.length > 0 ? presalesOption[0] : null,
                    statusItems: presalesOption,
                    startDateMin: new Date('1970-01-01Z00:00:00:000'),
                });
            }
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    setStateFromCR = changeReqDetails => {
        const statusObj = this.createStateObject(changeReqDetails.Status);

        if (changeReqDetails.CMSheets && changeReqDetails.CMSheets.length > 0 && changeReqDetails.CMSheets[0].Status === 'IN_PROGRESS') {
            this.setState({
                disableCRStatus: true
            });
        }

        this.setState({
            CRName: changeReqDetails.CRName,
            CRDescription: changeReqDetails.CRDescription ? changeReqDetails.CRDescription : '',
            startDate: changeReqDetails.StartDate ? reStructureDate(changeReqDetails.StartDate) : null,
            endDate: changeReqDetails.EndDate ? new Date(changeReqDetails.EndDate) : null,
            statusValue: statusObj,
            isViewMode: this.props.action === 'view' ? true : false,
            isEditMode: this.props.action === 'edit' ? true : false
        });
    };

    getCRDetailFromState = () => {
        return {
            CRName: this.state.CRName,
            CRDescription: this.state.CRDescription,
            StartDate: this.state.startDate,
            EndDate: this.state.endDateAugmentation ? this.state.endDateAugmentation : (this.state.endDateBlanketWork ? this.state.endDateBlanketWork : this.state.endDate),
            StatusId: this.state.statusValue ? this.state.statusValue.id : this.state.statusValue
        }
    };

    handleEnddateChange = async event => {

        if(this.props.projTypeValue && this.props.projTypeValue.Name === 'Resource Augmentation'){
            let endDateAug = event.target.value;

            await this.setState({
                endDateAugmentation: endDateAug,
                isFormDirty: true
            });

            this.props.onHandleEndDate(endDateAug);

            if (endDateAug) {
                this.state.showEndDateValidationError = false
            } else {
                this.state.showEndDateValidationError = true;

            }
        }
        else
        if(this.props.projTypeValue && this.props.projTypeValue.Name === 'Blanket Work Order'){
            let endDateBlanket = event.target.value;

            await this.setState({
                endDateBlanketWork: endDateBlanket,
                isFormDirty: true
            });

            this.props.onHandleEndDate(endDateBlanket);

            if (endDateBlanket) {
                this.state.showEndDateValidationError = false
            } else {
                this.state.showEndDateValidationError = true;
            }
        }
        else {

            let endDate = event.target.value;

            await this.setState({
                endDate: endDate,
                isFormDirty: true
            });
            this.props.onHandleEndDate(endDate);

            if (this.state.endDate) {
                this.state.showEndDateValidationError = false
            } else {
                this.state.showEndDateValidationError = true;
                this.state.showStartDateValidationError = true;
            }
        }

    };



    handleStartdateChange = event => {
        let startDate = event.target.value;

        if (this.state.statusValue.Code === 'BILLABLE' || this.state.statusValue.Code === 'WORK_AT_RISK') {
            this.setState({ startDateMin: new Date() });
        }
        else {
            this.setState({ startDateMin: new Date('1970-01-01Z00:00:00:000') });
        }

        this.setState({
            startDate: reStructureDate(startDate),
            isFormDirty: true,
            showStartDateValidationError :true,
        });
        this.props.onHandleStartDate(startDate)
    };

    handleInputChange = async event => {
        const name = event.target.name;
        const value = event.target.value;

        if (name === 'startDate') {
            if (value) {
                let startDateStr = '';
                if(!this.props.isResourceAug || !this.props.isBlanketWork){
                    let endDate = null;
                    let endDateStr = '';
                    if (endDate)
                        startDateStr = getFormattedDate(value)
                    endDate = new Date(value.valueOf())
                    endDate.setFullYear(endDate.getFullYear() + 1);
                    endDate.setDate(endDate.getDate() - 1);
                    endDateStr = getFormattedDate(endDate);
                    this.state.endDate = endDate;
                }


            }

            if (this.state.statusValue.Code === 'BILLABLE' || this.state.statusValue.Code === 'WORK_AT_RISK') {
                this.setState({ startDateMin: new Date() });
            }
            else {
                this.setState({ startDateMin: new Date('1970-01-01Z00:00:00:000') });
            }
        }

        await this.setState(
            { [name]: value, isFormDirty: true },
            () => {
                const validation = this.validator.validate(this.state);
                this.props.onDetailsChange({ changeReqDetails: this.getCRDetailFromState(), isValid: validation.isValid, statusValue: this.state.statusValue });
            }
        );
    };

    handleCRDescriptionChange = async description => {
        await this.setState(
            { CRDescription: description },
            () => {
                const validation = this.validator.validate(this.state);
                this.props.onDetailsChange({ changeReqDetails: this.getCRDetailFromState(), isValid: validation.isValid, statusValue: this.state.statusValue });
            }
        );
    }

    handleStatusChange = event => {
        // 1 - Presales - PRESALES
        // 2 - Waiting Confirmation - WAIT_CONFIRM
        // 3 - Closed-Opportunity Loss - CLOSE_OP_LOST
        // 4 - On Hold - ON_HOLD
        // 5 - Working At Risk - WORK_AT_RISK
        // 6 - Billable - BILLABLE
        // 7 - Billable with CR - BILLABALE_CR
        // 8 - Closed - CLOSED
        // 9 - Close without bidding - CLOSE_WITHOUT_BID

        if (event.target.value) {
            if (this.props.action === 'edit') {
                const currentProjectStatusCode = this.props.changeReqDetails.Status.Code;
                const cmSheets = this.props.changeReqDetails.CMSheets;
                let hasApprovedCMS = false;
                for (const cmSheet of cmSheets) {
                    if (cmSheet.Status === 'APPROVED') {
                        hasApprovedCMS = true;
                        break;
                    }
                }

                const selectedStatusCode = event.target.value.Code;
                const selectedStatusName = event.target.value.StatusName;

                if (selectedStatusCode === 'BILLABLE' || selectedStatusCode === 'WORK_AT_RISK') {
                    this.setState({
                        startDateMin: new Date()
                    });
                }

                if (
                    selectedStatusCode === 'PRESALES' &&
                    !(
                        currentProjectStatusCode === 'PRESALES' ||
                        currentProjectStatusCode === 'WAIT_CONFIRM' ||
                        currentProjectStatusCode === 'ON_HOLD'
                    )
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // When there is an approved CMS, status can be changed to Waiting Confirmation or Closed-Opportunity Loss
                if (
                    (selectedStatusCode === 'WAIT_CONFIRM' || selectedStatusCode === 'CLOSE_OP_LOST') &&
                    !hasApprovedCMS
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // status can be changed to Close without bidding before converting to a Billable or Working at Risk status
                if (
                    selectedStatusCode === 'CLOSE_WITHOUT_BID' &&
                    (currentProjectStatusCode === 'WORK_AT_RISK' ||
                        currentProjectStatusCode === 'BILLABLE' ||
                        currentProjectStatusCode === 'BILLABALE_CR')
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Any project in Presales, Waiting Confirmation, On Hold with an Approved CMS can be changed to Working At Risk
                if (
                    selectedStatusCode === 'WORK_AT_RISK' &&
                    (!(
                        currentProjectStatusCode === 'PRESALES' ||
                        currentProjectStatusCode === 'WAIT_CONFIRM' ||
                        currentProjectStatusCode === 'ON_HOLD' ||
                        currentProjectStatusCode === 'WORK_AT_RISK'
                    ) ||
                        !hasApprovedCMS)
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Any project in Presales, Waiting Confirmation, On Hold or Working at Risk with an Approved CMS can be changed to Billable
                if (
                    selectedStatusCode === 'BILLABLE' &&
                    (!(
                        currentProjectStatusCode === 'PRESALES' ||
                        currentProjectStatusCode === 'WAIT_CONFIRM' ||
                        currentProjectStatusCode === 'ON_HOLD' ||
                        currentProjectStatusCode === 'WORK_AT_RISK' ||
                        currentProjectStatusCode === 'BILLABLE'
                    ) ||
                        !hasApprovedCMS)
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Billable with CR is an internal status change, not a user change
                if (selectedStatusCode === 'BILLABALE_CR' && currentProjectStatusCode !== 'BILLABALE_CR') {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Any Billable, Working at Risk or On Hold project can be converted to Closed
                if (
                    selectedStatusCode === 'CLOSED' &&
                    !(
                        currentProjectStatusCode === 'WORK_AT_RISK' ||
                        currentProjectStatusCode === 'BILLABLE' ||
                        currentProjectStatusCode === 'ON_HOLD'
                    )
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Any Project in any status can be converted to on hold.
            }
        }

        this.setState(
            { [event.target.name]: event.target.value, isFormDirty: true, statusValue: event.target.value },
            () => {
                const validation = this.validator.validate(this.state);
                this.props.onDetailsChange({ changeReqDetails: this.getCRDetailFromState(), isValid: validation.isValid });
                this.props.onStatusChange({ statusValues: this.state.statusValue });
            }
        );


    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    toggleDialog = (message, title) => {
        this.setState({
            visible: !this.state.visible,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    reDirectToDashboard = event => {
        this.setState({
            toDashboard: true
        });
    };

    render() {
        if (this.state.toDashboard === true) {
            return <Redirect to="/" />;
        }

        let validation = this.props.showValidations === true // if show validations property is true
            ? this.validator.validate(this.state) // then check validity every time we render
            : this.state.validation;
        return (
            <React.Fragment>
                <div className="main-heading">Change Request Details</div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <div className="d-block">
                                <label htmlFor="" className="mandatory">
                                    CR Name:
                              </label>
                            </div>
                            <div className="d-block" id="projnameToolTip">
                                <Input
                                    autoComplete="off"
                                    type="text"
                                    name="CRName"
                                    value={this.state.CRName}
                                    onChange={this.handleInputChange}
                                    required={true}
                                    disabled={this.state.isViewMode || !this.props.permissions.CRName}
                                    maxLength="100"
                                />
                                <span className="inline-error">{validation.CRName.message}</span>
                            </div>
                            {this.state.CRName ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.projnameToolTipOpen}
                                    target="projnameToolTip"
                                    toggle={this.toggleProjNameToolTip}
                                >
                                    {this.state.CRName}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-1"></div>

                    <div className="col-md-2">
                        <div className="">
                            {(((this.props.billingDiviValue && this.props.projTypeValue) && this.props.billingDiviValue.id === 30 && this.props.projTypeValue.Name === "Application Support") || (this.props.projTypeValue && this.props.projTypeValue.Name === "Resource Augmentation") || (this.props.projTypeValue && this.props.projTypeValue.Name === "Blanket Work Order")) ? (
                                <div className="d-block" className="mandatory">
                                    <label htmlFor="">Start Date:</label>
                                </div>
                            ) :
                                (
                                    <div className="d-block">
                                        <label htmlFor="">Start Date:</label>
                                    </div>
                                )
                            }

                            <div className="d-block">
                                <div className="title-remove">

                                    {((((this.props.projTypeValue && this.props.projTypeValue.Name === "Resource Augmentation")) && (this.props.endDateAugmentation !== null)) || (((this.props.projTypeValue && this.props.projTypeValue.Name === "Blanket Work Order")) && (this.props.endDateBlanketWork !== null))) ?
                                        (
                                            <DatePicker
                                                width="100"
                                                name="startDate"
                                                onChange={this.handleStartdateChange}
                                                value={reStructureDate(this.state.startDate)}
                                                min={(this.props.action === 'create'  || this.props.action === 'edit') ? new Date() : this.state.startDateMin}
                                                disabled={this.state.isViewMode || (this.state.permissions.startDate ? !this.state.permissions.startDate : !this.props.permissions.startDate)
                                                }
                                                format="MM/dd/yyyy"
                                                formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                                required={(((this.props.projTypeValue && this.props.billingdiviValue) && this.props.projTypeValue.Name === "Application Support" && this.props.billingdiviValue.id === 30) || ((this.props.projTypeValue && this.props.projTypeValue.Name === "Resource Augmentation")) || ((this.props.projTypeValue && this.props.projTypeValue.Name === "Blanket Work Order"))) ? true : false}
                                                max={(this.props.endDateAugmentation ? this.props.endDateAugmentation : this.props.endDateBlanketWork)}
                                            />
                                        ) : (
                                            <DatePicker
                                                width="100"
                                                name="startDate"
                                                onChange={this.handleStartdateChange}
                                                value={reStructureDate(this.state.startDate)}
                                                min={(this.props.action === 'create' || this.props.action === 'edit') ? new Date() : this.state.startDateMin}
                                                disabled={this.state.isViewMode || (this.state.permissions.startDate ? !this.state.permissions.startDate : !this.props.permissions.startDate)
                                                }
                                                format="MM/dd/yyyy"
                                                formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                                required={(((this.props.projTypeValue && this.props.billingdiviValue) && this.props.projTypeValue.Name === "Application Support" && this.props.billingdiviValue.id === 30) || ((this.props.projTypeValue && this.props.projTypeValue.Name === "Resource Augmentation")) || ((this.props.projTypeValue && this.props.projTypeValue.Name === "Blanket Work Order"))) ? true : false}
                                            />
                                        )}
                                </div>
                                {((!this.props.startDate &&
                                    this.state.statusValue &&
                                    (this.state.statusValue.Code === 'BILLABLE' ||
                                        this.state.statusValue.Code === 'WORK_AT_RISK')) ||(this.state.showStartDateValidationError === true || this.props.showStartDateValidationError === true)) ? (
                                        <span className={this.validateProperty(this.state.startDate)}>
                                            Please enter a start date
                                        </span>
                                    ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>

                    {(this.props.isResourceAug || this.props.isBlanketWork || this.props.isAPS) && (
                        <>
                            <div className="col-md-2">
                                <div className="">
                                    <div className="d-block" className="mandatory" >
                                        <label htmlFor="">End Date:</label>
                                    </div>
                                    <div className="d-block">

                                        <DatePicker
                                            width="100"
                                            name="endDate"
                                            onChange={this.handleEnddateChange}
                                            value={this.props.permissions.endDateAugmentation ? this.props.endDateAugmentation : (this.props.permissions.endDateBlanketWork ? this.props.endDateBlanketWork : this.props.endDate)}
                                            min={new Date(this.state.startDate)}
                                            disabled={this.state.isViewMode || (this.props.permissions.endDateAugmentation ? !this.props.permissions.endDateAugmentation : (this.props.permissions.endDateBlanketWork ? !this.props.permissions.endDateBlanketWork : !this.props.permissions.endDate))}
                                            format="MM/dd/yyyy"
                                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                            required={(((this.props.projTypeValue && this.props.billingdiviValue) && this.props.projTypeValue.Name === "Application Support" && this.props.billingdiviValue.id === 30) || ((this.props.projTypeValue && this.props.projTypeValue.Name === "Resource Augmentation")) || ((this.props.projTypeValue && this.props.projTypeValue.Name === "Blanket Work Order"))) ? true : false}
                                        />

                                        {this.props.endDate === null &&
                                        this.props.isAPS ? (
                                            <span className={this.validateProperty(this.props.endDate)}>
                                                    Please enter a end date
                                        </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        
                        <div className="col-md-1"></div>
                        </>
                    )}

                    { this.props.changeReqDetails && (this.props.action && (this.props.action === 'edit' || this.props.action === 'view') && (!this.props.isResourceAug && !this.props.isBlanketWork && !this.props.isAPS)) && (
                            <>
                                <div className="col-md-2">
                                <div className="">
                                    <div className="d-block">
                                            <label htmlFor="">End Date:</label>
                                    </div>
                                    <div className="d-block">
                                            <DatePicker
                                                width="70"
                                                name="enddate"
                                                value={ this.props.endDate
                                                    ? reStructureDate(this.props.endDate)
                                                    : this.props.estimatedEndDate ?
                                                    reStructureDate(this.props.estimatedEndDate)
                                                    : null
                                                }
                                                disabled={ true }
                                                format="MM/dd/yyyy"
                                                formatPlaceholder={ { year: 'YYYY', month: 'MM', day: 'DD' } }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                            </>
                    ) }

                    <div className="col-md-2">
                        <div className="">
                            <div className="d-block">
                                <label htmlFor="" className="mandatory">
                                    Status:
                              </label>
                            </div>
                            <div className="d-block" id="statusToolTip">
                                <ComboBox
                                    data={this.state.statusItems}
                                    name="statusValue"
                                    textField="StatusName"
                                    dataItemKey="id"
                                    onChange={this.handleStatusChange}
                                    value={this.state.statusValue}
                                    required={true}
                                    disabled={
                                        this.state.isViewMode ||
                                        this.state.isEditMode ||
                                        !this.state.permissions.status ||
                                        (window.USER_PERMISSION &&
                                            window.USER_PERMISSION.indexOf('CHG_PRJ_STAT') === -1)
                                    }
                                />
                                {this.props.errorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.state.statusValue)}>
                                        Please select a status
                                    </span>
                                ) : null}
                            </div>
                            {this.state.statusValue ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.statusToolTipOpen}
                                    target="statusToolTip"
                                    toggle={this.toggleStatusToolTip}
                                >
                                    {this.state.statusValue.StatusName}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="d-block">
                        <label htmlFor="" className="mandatory">CR Description:</label>
                    </div>
                    <div className="d-block">
                        <ReactQuill
                            className={this.state.CRDescription == '' ? "quil-required" : ""}
                            name="CRDescription"
                            onChange={this.handleCRDescriptionChange}
                            value={this.state.CRDescription}
                            maxLength="2000"
                            readOnly={this.state.isViewMode || (this.props.statusValue ? this.props.statusValue.Code === 'ON_HOLD' : false && this.props.action === 'edit')}
                        />
                        <span className="inline-error">{validation.CRDescription.message}</span>

                    </div>
                </div>
                {this.state.visible === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.reDirectToDashboard} width="300px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                        <DialogActionsBar>
                            <button
                                className="k-button modal-primary"
                                onClick={
                                    this.state.dialogTitle === 'Error' ? this.toggleDialog : this.reDirectToDashboard
                                }
                            >
                                OK
                        </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </React.Fragment>
        );
    }

}

export default EditCRDetails;
