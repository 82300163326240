import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ActionsCell extends Component {
  render() {
    const { OnDeactivate } = this.props;
    const { OnActivate } = this.props;
    const isActive = this.props.dataItem.IsActive;
    const docType = this.props.dataItem;
    return (
      <td className="action-buttons">

        { (
          <Link
            to={{
              pathname: '/masterdata/documenttype/documenttypedetails',
              action: 'view',
              docType: docType
            }}
          >
            <div className="rounded-link" title="View">
              <span className="k-icon k-i-preview" />
            </div>
          </Link>
        )}

        {isActive === true && (window.USER_PERMISSION.indexOf('EDT_DOC_TYPE') > -1) && (
          <Link
            to={{
              pathname: '/masterdata/documenttype/documenttypedetails',
              action: 'edit',
              docType: docType,
              documentNames: this.props.doctypeNames
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-edit" title="Edit" />
            </div>
          </Link>
        )}

        {isActive === false && (window.USER_PERMISSION.indexOf('EDT_DOC_TYPE') > -1) && (
          <Link
            to={this.props}
            onClick={event => this.props.OnActivate(event, this.props.dataItem.id, this.props.dataItem.DocType)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-check" title="Activate" />
            </div>
          </Link>
        )}

        {isActive === true && (window.USER_PERMISSION.indexOf('EDT_DOC_TYPE') > -1) && (
          <Link to={this.props} onClick={event => OnDeactivate(event, this.props.dataItem.id, this.props.dataItem.DocType)}>
            <div className="rounded-link">
              <span className="k-icon k-i-unsort" title="Deactivate" />
            </div>
          </Link>
        )}

      </td>
    );
  }
}

export default ActionsCell;
