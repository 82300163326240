import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Redirect } from 'react-router';
import { Tooltip } from 'reactstrap';
import { saveRole, updateRole, getPermissionList } from './RoleService';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

class RoleCreate extends Component {

  default;

  popupSet = {
    width: 'auto'
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.populatePermissionList();
    this.setHeaderTitle();
  }

  setHeaderTitle = async () => {
    if (this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Create/Edit Role');
    }
  };

  lastSelectedIndex = 0;

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      skip: 0,
      take: 20,
      rolenameToolTipOpen: false,
      toRoleList: false,
      Rolename: '',
      RoleDescription: '',
      roleId: null,
      selectedPermissionList: [],
      roleActive: false,
      visible: false,
      success: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      isEnableCreate: false,
      isEnableUpdate: false,
      isDisableCombo: false,
      isDisableEditMode: false,
      isDisableSave: false,
      isSaveButtonClicked: false,
      isFormDirty: false
    };

    this.handleChangeRoleName = this.handleChangeRoleName.bind(this);
    this.handleChangeRoleDescription = this.handleChangeRoleDescription.bind(this);
  }

  populatePermissionList = () => {
    getPermissionList().then(response => {
      console.log(response.data);

      this.setState({
        data: response.data.map(dataItem => Object.assign({ selected: false }, dataItem))
      });

      //Load list
      if (this.props.location.action === 'edit') {
        this.updateRoleDetails(this.props.location.role);
        console.log('::::::::::::::::::::::::::::::::::EDIT USER::::::::::::::::::::::::::::::::::::');
        console.log(this.props.location.role);
      }

    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  selectionChange = (event) => {
    this.setState({
      isFormDirty: true
    });
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
    console.log(':::::::::::::::::::::::::::SELECTION CHANGE::::::::::::::::::::::::::::::::');
    console.log(this.state.data);
  };

  rowClick = (event) => {
    let last = this.lastSelectedIndex;
    const current = this.state.data.findIndex(dataItem => dataItem === event.dataItem);

    if (!event.nativeEvent.shiftKey) {
      this.lastSelectedIndex = last = current;
    }

    if (!event.nativeEvent.ctrlKey) {
      this.state.data.forEach(item => item.selected != false);
    }
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      this.state.data[i].selected = select;
    }
    this.forceUpdate();

    console.log(':::::::::::::::::::::::::::ROW CLICKED::::::::::::::::::::::::::::::::');
    console.log(this.state.data);
  };

  headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    this.state.data.forEach(item => item.selected = checked);
    this.forceUpdate();

    console.log(':::::::::::::::::::::::::::HEADER CHANGE::::::::::::::::::::::::::::::::');
    console.log(this.state.data);
  };

  pageChange = event => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  };

  render() {
    if (this.state.toRoleList === true) {
      return <Redirect to="/admin/rolemanagement/rolemanagement"/>;
    }
    return (
      <React.Fragment>
        <div className="main-card">
          <div className="main-heading">Role Details</div>
          <div className="main-seperator"></div>
          <div className="row">

            <div className="col-md-2">
              <div className="d-block">
                  <label className="mandatory">Role Name:</label>
                  </div>
                  <div className="d-block">
                <div className="" id="RolenameToolTip">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="Rolename"
                    required={true}
                    maxLength="100"
                    value={this.state.Rolename}
                    onChange={this.handleChangeRoleName}
                    disabled={this.state.isEditMode}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.Rolename)}>
                                            Please enter a Role name
                                        </span>
                  ) : null}
                  {this.state.Rolename ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.rolenameToolTipOpen}
                      target="RolenameToolTip"
                      toggle={this.toggleRolenameToolTip}
                    >
                      {this.state.Rolename}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>

            <div className="col-md-4">
              <div className="d-block">
                  <label className="mandatory">Role Description:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="RoleDescription"
                    required={true}
                    maxLength="100"
                    value={this.state.RoleDescription}
                    onChange={this.handleChangeRoleDescription}
                    disabled={this.state.isEditMode}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.RoleDescription)}>
                                            Please add a Role Description
                                        </span>
                  ) : null}
                </div>
              </div>
            </div>

          </div>

          <div className="row mt-4">
            <div className="col-md-12">

              <Grid data={this.state.data.slice(this.state.skip, this.state.take + this.state.skip)}
                    skip={this.state.skip}
                    take={this.state.take}
                    total={this.state.data.length}
                    pageable={true}
                    onPageChange={this.pageChange}
                    selectedField="selected"
                    onSelectionChange={this.selectionChange}
                    onHeaderSelectionChange={this.headerSelectionChange}
                    onRowClick={this.rowClick}
                    resizable>
                <Column
                  field="selected"
                  width="50px"
                  headerSelectionValue={
                    this.state.data.findIndex(dataItem => dataItem.selected === false) === -1
                  }/>
                <Column field="PermissionName" width="400px" title="Permission Name"/>
                <Column field="PermissionDescription" title="Permission Description"/>
              </Grid>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} disabled={this.state.isDisableSave} type="submit"
                      onClick={this.handleSubmit}>Save</Button>
              <Button onClick={this.onClickCancel}>Cancel</Button>
            </div>
          </div>
          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="300px">
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                <DialogActionsBar>
                  <button
                    className="k-button modal-primary"
                    onClick={
                      this.state.dialogTitle === 'Error'
                        ? this.toggleDialog
                        : this.navigateBack
                    }
                  >
                    OK
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.showCancelConfirmation === true && (
              <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contains unsaved changes. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.navigateBack}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  navigateBack = event => {
    this.setState({
      toRoleList: true
    });
  };

  handleChangeRoleDescription = async event => {
    await this.setState({ RoleDescription: event.target.value, isFormDirty: true });
    await this.validateProperty(event.target.value);
  };

  handleChangeRoleName = async event => {
    await this.setState({ Rolename: event.target.value, isFormDirty: true });
    await this.validateProperty(event.target.value);
  };

  toggleRolenameToolTip = () => {
    this.setState({
      workflowNameToolTipOpen: !this.state.workflowNameToolTipOpen
    });
  };

  handleChangeProjectRole = event => {
    const field = event.target.name;
    // const value = event.target.value;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [field]: value,
      isFormDirty: true
    });
  };

  updateRoleDetails = role => {
    this.setState({
      isDisableEditMode: true
    });
    if (role) {
      this.isEnableCreate = true;
      this.isEnableUpdate = false;
      let roleStatus = false;

      if (role.IsActive) {
        roleStatus = true;
      }

      //Create selection
      this.state.data.forEach(function(item) {
        role.Permissions.forEach(function(itemSelected) {
          if (item.id === itemSelected.id) {
            item.selected = true;
          }
        });
      });

      this.setState({
        Rolename: role.Rolename,
        roleId: role.id,
        roleActive: roleStatus,
        RoleDescription: role.RoleDescription,
        selectedPermissionList: role.Permissions
      });
    } else {
      return null;
    }
  };


  handleSubmit = event => {
    event.preventDefault();

    console.dir(event.target);
    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true
      });
      this.setState({ visible: true });
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else {
      this.setState({
        isErrorMessageVisible: false
      });
      if (this.props.location.action === 'edit') {
        this.updateRole(event);
      } else {
        this.createRole(event);
      }
    }
  };

  createRole = event => {

    var selectedItems = this.state.data.filter(obj => {
      if (obj.selected === true) {
        return true;
      }
      return null;
    });

    if (!selectedItems.length > 0) {
      this.toggleDialog('Please select atleast one permission to proceed.', 'Error');
    } else {

      this.setState({
        isDisableSave: true
      });

      const role = {
        Rolename: this.state.Rolename,
        RoleDescription: this.state.RoleDescription,
        IsActive: true,
        Permissions: selectedItems
      };

      console.log('::::::::::::::::::::::::::::::SAVE:::::::::::::::::::::::::::::::');
      console.log(role);

      saveRole(role)
        .then(res => {
          this.toggleDialog(`The Role ${res.data} is successfully created`, 'Success');
          this.setState({});
        })

        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.toggleDialog(error.response.data, 'Error');
          this.setState({
            isDisableSave: false
          });
        });
    }
  };

  updateRole = event => {

    var selectedItems = this.state.data.filter(obj => {
      if (obj.selected === true) {
        return true;
      }
      return null;
    });

    if (!selectedItems.length > 0) {
      this.toggleDialog('Please select atleast one permission to proceed.', 'Error');
    } else {

      this.setState({
        isDisableSave: true
      });

      //Prepare Roles list to update
      const updatePermissionList = [];
      const insertPermissionList = selectedItems;
      const deletePermissionList = this.state.selectedPermissionList;

      deletePermissionList.forEach(function(exitstRoles, indexExist) {
        insertPermissionList.forEach(function(clientRoles, indexClient) {
          if (exitstRoles.id === clientRoles.id) {
            updatePermissionList.push(exitstRoles); //Add found item to update list
            deletePermissionList.splice(indexExist, 1); //Removed found item from delete list
            insertPermissionList.splice(indexClient, 1); //Remove found item from insert list
          }
        });
      });

      const role = {
        Rolename: this.state.Rolename,
        RoleDescription: this.state.RoleDescription,
        IsActive: this.state.roleActive,
        UpdatePermissions: updatePermissionList,
        InsertPermissions: insertPermissionList,
        DeletePermissions: deletePermissionList
      };

      console.log('::::::::::::::::::::::::::::::UPDATE:::::::::::::::::::::::::::::::');
      console.log(role);

      updateRole(role, this.state.roleId)
        .then(res => {
          this.toggleDialog('The Role has been successfully updated', 'Success');
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.toggleDialog(error.response.data, 'Error');
        });

    }
  };

  validation = () => {

    if (
      this.validateProperty(this.state.Rolename)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.RoleDescription)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleCancelConfirmDialog = () => {
    this.setState({
      showCancelConfirmation: !this.state.showCancelConfirmation
    });
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };


}

export default RoleCreate;
