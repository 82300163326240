import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import { Button } from '@progress/kendo-react-buttons';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class IRFExcelDownload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            irfData: null,
            projectTimeEntries: null,
            milestoneData: null,
            uniqueId: '',
            nullData: [{ columns: [], data: [] }],
        };
    }

    componentDidMount() {
        if (this.props.updateData === true) {
            this.populateIRData();
        }
    }

    componentDidUpdate = async() => {
        if (this.props.updateData === true) {
            this.props.dataUpdated();
            await this.populateIRData();
            document.getElementById("irexcel").click();
        }
    }

    populateIRData = async() => {
        let irfData = [];
        let timeEntries = [];
        let uniqueId = '';

        const borderStyle = {
            border: {
                top: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" }
            }
        };

        if (this.props.irdata) {
            const invoiceRequest = this.props.irdata;
            const selectedProjectOrCr =   invoiceRequest.CrId ? invoiceRequest.ChangeRequest : invoiceRequest.Project; 
            uniqueId =  selectedProjectOrCr.UniqueId;
            const billingMethodId =   invoiceRequest.CrId ? invoiceRequest.ChangeRequest.PaymentMethod.id : invoiceRequest.Project.PaymentMethodRecord.id;
            let clientConfirm = 'No';
            if (billingMethodId && billingMethodId === 2 && 
                invoiceRequest.ApprovalFileId && invoiceRequest.FileUpload) {
                    clientConfirm = 'Yes';
            }

            if (billingMethodId && billingMethodId !== 2 && 
                invoiceRequest.Milestone !== null && invoiceRequest.Milestone.FileUpload) {
                    clientConfirm = 'Yes';
            }
            irfData.push([
                {
                    value: invoiceRequest.LegalEntity ? invoiceRequest.LegalEntity.Name : '',
                    style: borderStyle
                },
                {
                    value: selectedProjectOrCr.Region ? selectedProjectOrCr.Region.Name : '',
                    style: borderStyle
                },
                {
                    value: selectedProjectOrCr.Offering ? selectedProjectOrCr.Offering.Name : '',
                    style: borderStyle
                },
                {
                    value: selectedProjectOrCr.Customer ? selectedProjectOrCr.Customer.Name : '',
                    style: borderStyle
                },
                {
                    value: selectedProjectOrCr.ProjectPractice ? selectedProjectOrCr.ProjectPractice.Name : selectedProjectOrCr.CRPractice ? selectedProjectOrCr.CRPractice.Name : '',
                    style: borderStyle
                },
                {
                    value: invoiceRequest.FeeType ? invoiceRequest.FeeType : '',
                    style: borderStyle
                },
                {
                    value: invoiceRequest.InvoicingType ? invoiceRequest.InvoicingType : '',
                    style: borderStyle
                },
                {
                    value: invoiceRequest.ProjectName ? invoiceRequest.ProjectName : '',
                    style: borderStyle
                },
                {
                    value: selectedProjectOrCr.PONumber ? selectedProjectOrCr.PONumber : '',
                    style: borderStyle
                },
                {
                    value: selectedProjectOrCr.OPID ? selectedProjectOrCr.OPID : '',
                    style: borderStyle
                },
                {
                    value: invoiceRequest.Description ? invoiceRequest.Description.replace(/(<([^>]+)>)/ig,"") : '',
                    style: borderStyle
                },
                {
                    value: invoiceRequest.UpdatedUser ? invoiceRequest.UpdatedUser : '',
                    style: borderStyle
                },
                {
                    value: clientConfirm,
                    style: borderStyle
                },
                {
                    value: selectedProjectOrCr.Region ? selectedProjectOrCr.Region.Currency.Code : '',
                    style: borderStyle
                },
                {
                    value: invoiceRequest.InvoiceAmount ? invoiceRequest.InvoiceAmount : '',
                    style: borderStyle
                },
                {
                    value: invoiceRequest.LastApprovedDate ? invoiceRequest.LastApprovedDate : '',
                    style: borderStyle
                },
                {
                    value: invoiceRequest.InvoicingMonth ? moment(invoiceRequest.InvoicingMonth).format('MMMM') : '',
                    style: borderStyle
                },
                {
                    value: invoiceRequest.Remarks ? invoiceRequest.Remarks.replace(/(<([^>]+)>)/ig,"") : '',
                    style: borderStyle
                },
                {
                    value: invoiceRequest.StatusName ? invoiceRequest.StatusName : '',
                    style: borderStyle
                }
            ])

            if (invoiceRequest.TimeEntries && invoiceRequest.TimeEntries.length > 0) {
                for (const timeEntry of invoiceRequest.TimeEntries) {
                    timeEntries.push([
                        {
                            value: timeEntry.LogDateTime ? timeEntry.LogDateTime : '',
                            style: borderStyle
                        },
                        {
                            value: timeEntry.ProjectName ? timeEntry.ProjectName : '',
                            style: borderStyle
                        },
                        {
                            value: timeEntry.Resource ? timeEntry.Resource : '',
                            style: borderStyle
                        },
                        {
                            value: timeEntry.ApprovedComment ? timeEntry.ApprovedComment : '',
                            style: borderStyle
                        },
                        {
                            value: timeEntry.ApprovedDuration ? timeEntry.ApprovedDuration : '',
                            style: borderStyle
                        },
                        {
                            value: selectedProjectOrCr.Region ? selectedProjectOrCr.Region.Currency.Code : '',
                            style: borderStyle
                        },
                        {
                            value: timeEntry.RatePerHour ? timeEntry.RatePerHour : '',
                            style: borderStyle
                        },
                        {
                            value: timeEntry.Revenue ? Number(timeEntry.Revenue).toFixed(2) : '',
                            style: borderStyle
                        }
                    ]);
                }
                timeEntries.push([
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: ''
                    },
                    {
                        value: invoiceRequest.TotalAmount ? Number(invoiceRequest.TotalAmount).toFixed(2) : '',
                        style: {font: {bold: true} }
                    }
                ]);
            }
       
            let emptyRow = [
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 200 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 350 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 150 } },
                { title: '', width: { wpx: 350 } },
                { title: '', width: { wpx: 200 } }
            ];
    
            let irfColumns = [
                { value: 'Billing Entity',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Region',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Solution',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Client',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Practice',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Invoicing type',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Inv/CRN',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Project',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Purchase Order No.',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'OPP ID',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Invoice Description',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Sender',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Client Confirmation',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Currency',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Amount',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Invoice Request approved date',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Month',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Remarks',  style: {font: { bold: true }, border: borderStyle.border}},
                { value: 'Status',  style: {font: { bold: true }, border: borderStyle.border}},
            ];

            let teColumns = [
                { title: '',  width: { wpx: 150 } },
                { title: '',  width: { wpx: 150 } },
                { title: '',  width: { wpx: 150 } },
                { title: '',  width: { wpx: 150 } },
                { title: '',  width: { wpx: 150 } },
                { title: '',  width: { wpx: 150 } },
                { title: '',  width: { wpx: 150 } },
                { title: '',  width: { wpx: 200 } }
            ];

            let teColumnNames = [
                {value: 'Date', style: {font: { bold: true }, border: borderStyle.border}},
                {value: 'Project Name', style: {font: { bold: true }, border: borderStyle.border}},
                {value: 'Resource Name', style: {font: { bold: true }, border: borderStyle.border}},
                {value: 'Time Entry Comments', style: {font: { bold: true }, border: borderStyle.border}},
                {value: 'Effort Hours', style: {font: { bold: true }, border: borderStyle.border}},
                {value: 'Currency', style: {font: { bold: true }, border: borderStyle.border}},
                {value: 'Hourly Rate', style: {font: { bold: true }, border: borderStyle.border}},
                {value: 'Revenue', style: {font: { bold: true }, border: borderStyle.border}},
            ];
            let teData = [teColumnNames, ...timeEntries];

            const irDataGrid = [
                {
                    columns: [
                        {title: 'Invoice Request', width: {wpx: 150}},
                        {title: '', width: {wpx: 150}},
                    ],
                    data: []
                },
                {
                    columns: emptyRow,
                    data: [irfColumns, ...irfData]
                },
                {
                    columns: [],
                    data: []
                },
                {
                    columns: teColumns,
                    data: teData
                },
            ];

            await this.setState({
                irfDataGrid: irDataGrid,
                uniqueId: uniqueId
            });
        }

    };

    render() {
        return (
            <ExcelFile
                filename={`Invoice Request - ${this.state.uniqueId}`}
                element={ 
                    <Button id={"irexcel"} className="d-none">
                        {''}
                    </Button>
                }
            >
                <ExcelSheet
                    dataSet={this.state.irfDataGrid ? this.state.irfDataGrid : this.state.nullData}
                    name="Invoice Request"
                />
            </ExcelFile>
        );
    }
}

export default IRFExcelDownload;
