import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import {ComboBox, MultiSelect} from '@progress/kendo-react-dropdowns';
import Loader from '../../integration/components/Loader';
import {
    getBillingDiviIntegrated,
    getcustomersIntegrated,
    getRegionsIntegrated
} from "../../integration/MasterDataService";
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import {filterBy} from "@progress/kendo-data-query";
import {
    approveStatusChange,
    getAllUniqueIds,
    getProject,
    getProjectOrCRByApprover,
    getProjectStatuses, rejectStatusChange,
    saveSearchCriteria,
    getSavedSearchCriterians
} from "../project/ProjectService";
import {getAllCRUniqueIds, getChangeRequest} from "../changeRequest/ChangeRequestService";
import {getPMResources, getProjectOwners} from "../../resourceManagement/ResourceService";
import {approveResourceRequest} from "../../resourceManagement/resourceRequest/ResourceRequestActivationService";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import SearchResultsFileCell from './SearchResultsFileCell';
import { Redirect } from 'react-router';
import moment from 'moment';
import { uniq } from 'lodash';
import {getHolidaysIntegrated} from '../../integration/MasterDataService';
import {getFormattedDate, isWeekend, reStructureDate} from '../../integration/CommonUtils';
import Parser from 'html-react-parser';
import {getCMSheet} from '../cmSheet/CMSheetService';
import {orderBy} from '@progress/kendo-data-query';

const apiUrl = require('../../config.js').get(process.env.REACT_APP_ENV).apiUrl;
const testData = ["Baseball", "Basketball", "Cricket", "Field Hockey", "Football", "Table Tennis", "Tennis", "Volleyball"];

class ApproveChangeStatus extends Component {
    ticked = [];

    constructor(props) {
        super(props);

        this.state = {
            value: [],
            Workflows: [],
            Divisions: [],
            Regions: [],
            DivisionsAll: [],
            selectedResources:[],
            allGridData:[],
            filteredProjects: [],
            Division: null,
            billingRegion: null,
            customer: null,
            projectCR: null,
            projectManager: null,
            requestedStatus: null,
            ProjectOrCrUniqueIds: [],
            ProjectOrCrUniqueIdsAll: [],
            allGridDataInitial: [],
            loading: false,
            userId: null,
            savedSearchParams: [],
            gridDataFromSavedParams: [],
            showMessageDialog: false,
            showRejectCommentDialog: false,
            dialogMessage: '',
            dialogTitle: '',
            rejectComment:null,
            resourceItems:[],
            isFormDirty: false,
            StatusesAll: [],
            Statuses: [],
            ActivityDataAll: [],
            projectOrCRsData: [],
            skip: 0,
            take: 10,
            sort: [{ field: '', dir: ''}],
            approverComment: null
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.setHeaderTitle();
        this.setUserId();
        await this.setInitialData();
        await this.populateProjectOrCr();
    }

    setInitialData = async () => {
        this.setState({
            loading: true
        });
         this.populateDivisions();
        this.populateCustomers();
        this.populateRegions();
        await this.populateStatuses();
        this.populateProjectIds();
        this.populateCRIds();
        this.populatePMResources();
        this.populateSavedSearchCriterians();
    }

    setHeaderTitle = async () => {
        if (this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Approve Change Status');
        }
    };

    setUserId = async () => {
        const userId = window.LOGGED_USER.userId;
        this.setState({
            userId: userId
        });
    };

    populateDivisions = async () => {

        await getBillingDiviIntegrated()
            .then(res => {
                    this.setState({
                        Divisions: res.data,
                        DivisionsAll: res.data
                    });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateCustomers = () => {

        getcustomersIntegrated()
            .then(res => {
                    this.setState({
                        Customers: res.data,
                        CustomersAll: res.data
                    });

            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateRegions = () => {
        getRegionsIntegrated()
            .then(res => {
                    this.setState({
                        Regions: res.data,
                        RegionsAll: res.data
                    });

            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateStatuses = async () => {

        await getProjectStatuses()
            .then(res => {
                    this.setState({
                        Statuses: res.data
                    });

            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateProjectIds = () => {
        getAllUniqueIds()
            .then(res => {

                    this.setState({
                        UniqueIds: res.data,
                        UniqueIdsAll: res.data
                    });

            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateCRIds = () => {
        getAllCRUniqueIds()
            .then(res => {

                    this.setState({
                        UniqueCRIds: res.data,
                        UniqueCRIdsAll: res.data
                    });

            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populatePMResources = async () => {
        let resources = [];

        await getPMResources().then(res => {
            this.setState({
                resourceItemsPro: res.data
            });

            for(const obj of res.data){
                resources.push(obj)
            }

        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        await getProjectOwners().then(response => {
            let resourceAll = [];

            this.resourceAllPool = response.data;
            this.setState({
                resourceItemsPool: response.data
            });

            for(const obj of response.data){
                let resource = {
                    id:obj.id,
                    Name:obj.displayName
                }
                resourceAll = resources.filter(obj => obj.id  === resource.id);
                if(resourceAll && resourceAll.length === 0){
                    resources.push(resource)
                }
            }
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        this.resourceAll = resources

        await this.setState({
            resourceItems: resources
        });
    };

    populateProjectOrCr = async () => {
        let resource = window.LOGGED_USER
        await getProjectOrCRByApprover(resource)
            .then(res => {
                    this.setState({
                        ActivityDataAll: res.data
                    },
                        () => {
                        this.setProjectDataToGrid();
                        });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    }

    populateSavedSearchCriterians = async () => {
        const userId = window.LOGGED_USER.userId;
        await getSavedSearchCriterians(userId)
        .then(res => {
            this.setState({
                savedSearchParams: res.data
            });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    setSavedSearchParams = async () => {
        let allGridData = this.state.allGridDataInitial;
        let savedSearchParams = this.state.savedSearchParams;
        let division = [];
        let billingRegion = [];
        let customer = [];
        let projectCR = [];
        let projectManager = [];
        let requestedStatus = [];

        if(savedSearchParams && savedSearchParams.length > 0){
            for(let el of savedSearchParams){
                if(el.searchCriteria == 'Practice'){
                    const item = {
                        Name: el.searchValue
                    };
                    division.push(item);
                }
                if(el.searchCriteria == 'Billing Region'){
                    const item = {
                        Name: el.searchValue
                    };
                    billingRegion.push(item);
                }
                if(el.searchCriteria == 'Customer'){
                    const item = {
                        Name: el.searchValue
                    };
                    customer.push(item);
                }
                if(el.searchCriteria == 'Project or CR'){
                    const item = {
                        Name: el.searchValue
                    };
                    projectCR.push(item);
                }
                if(el.searchCriteria == 'Project Manager'){
                    const item = {
                        Name: el.searchValue
                    };
                    projectManager.push(item);
                }
                if(el.searchCriteria == 'Requested Status'){
                    const item = {
                        StatusName: el.searchValue
                    };
                    requestedStatus.push(item);
                }
            }
        }

        if(division && division.length > 0){
            this.state.Division = division;
        }
        if(billingRegion && billingRegion.length > 0){
           this.state.billingRegion = billingRegion;
        }
        if(customer && customer.length > 0){
           this.state.customer = customer;
        }
        if(projectCR && projectCR.length > 0){
           this.state.projectCR = projectCR;
        }
        if(projectManager && projectManager.length > 0){
           this.state.projectManager = projectManager;
        }
        if(requestedStatus && requestedStatus.length > 0){
           this.state.requestedStatus = requestedStatus;
        }

        this.submitSearch();
    };

    onClickApprove = event => {
        this.toggleConfirmDialog('Are you sure you want to approve the selected status change(s)?', this.approveConfirm);
    };

    navigateToDashboard = async () => {
        await this.setState({
            navigateToDashboard: true
        });
    };

    onClickReject =  event => {
         this.toggleRejectCommentDialog('Status Change Reject - Comment');
    };

    approveConfirm = async event => {
        const approveObj = {
            statusLines: this.state.selectedResources ? this.state.selectedResources : [],
            comment: this.state.approverComment
        };

        this.toggleConfirmDialog('', null);

        await approveStatusChange(approveObj)
            .then(res => {
                this.setState({
                    selectedResources: [],
                });
                this.ticked = [];
                this.toggleDialog('Successfully Approved', 'Success');
                this.navigateToDashboard();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleDialog(error.response.data, 'Error');
            });
    };

    rejectConfirm = async event => {

        this.setState({
            showRejectCommentDialog: !this.state.showRejectCommentDialog
        });

        const approveObj = {
            projectId: this.state.selectedResources ? this.state.selectedResources[0].proOrCRId : null,
            statusLines: this.state.selectedResources ? this.state.selectedResources : [],
            comment: this.state.rejectComment
        };

        this.toggleRejectCommentDialog('', null);

        await rejectStatusChange(approveObj)
            .then(res => {
                this.setState({
                    selectedResources: [],
                });
                this.ticked = [];
                this.toggleDialog(' Successfully Rejected', 'Success');
                this.navigateToDashboard();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleDialog(error.response.data, 'Error');
            });
    };


    setProjectDataToGrid = async () => {
        let projectOrCRsData = [];
        let item = null;
        let allGridData = [];
        let ProjectOrCrUniqueIds = [];
        let ProjectOrCRItems = null;
        let divisions = [];
        let billingRegions = [];
        let customers = [];
        let projectOrCr = [];
        let projManagers = [];
        let reqStatuses = [];
        let uniqueDivisions = [];
        let uniqueBillingRegions = [];
        let uniqueCustomers = [];
        let uniqueProjCrs = [];
        let uniqueProjManagers = [];
        let uniqueReqStatus = [];
        let endDate = null;
        let projOrCrStartDate = null;
        let currency = '';

        //filter data to set the grid
        if(this.state.ActivityDataAll.length > 0){
            for(const obj of this.state.ActivityDataAll){
                if(obj.ActivityLog !== null){
                    //for CR
                    if(obj.ActivityLog.CrId !== null && obj.ActivityLog.ProjectId !== null){
                        await getChangeRequest(obj.ActivityLog.CrId)
                            .then(res => {
                                res.data.ActivityLog = obj.ActivityLog
                                projectOrCRsData.push(res.data)
                            })
                            .catch(error => {
                                loggerService.writeLog(error, LOG_TYPES.ERROR);
                            })
                    }
                    //for Project
                    else if(!obj.ActivityLog.CrId && obj.ActivityLog.ProjectId !== null){
                        await getProject(obj.ActivityLog.ProjectId)
                            .then(response => {
                                response.data.ActivityLog = obj.ActivityLog
                                projectOrCRsData.push(response.data)
                            })
                            .catch(error => {
                                loggerService.writeLog(error, LOG_TYPES.ERROR);
                            });
                    }
                }
            }
            this.setState({
                projectOrCRsData: projectOrCRsData ? projectOrCRsData : ''
            });
        }
        

        //set object as grid
        if(this.state.projectOrCRsData.length > 0){
            for(const obj of this.state.projectOrCRsData){
                let cmsData = [];

                //get cmsheet data
                if(obj.CMSheets.length > 0){
                    try {
                        const response = await getCMSheet(obj.CMSheets[0].id, obj.CMSheets[0].Version); 
                        cmsData.push(response.data);
    
                    } catch (error) {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    }
                }

                //has cms
                if(cmsData && cmsData.length > 0){
                    //phase and subphase set
                    const phases = cmsData[0].Phases;

                    cmsData[0].ResourceAllocations.forEach(function (resource, index){
                        let resPhase = phases.filter(e => e.id === resource.PhaseId);
                        const dataValuePhase = resPhase[0];
                        cmsData[0].ResourceAllocations[index].dataValuePhase = dataValuePhase;
    
                        if(resPhase.length > 0){
                            if(resPhase[0].SubPhases){
                                let resSubPhase = resPhase[0].SubPhases.filter(e => e.id === resource.SubPhaseId);
                                const dataValueSubPhase = resSubPhase[0];
                                cmsData[0].ResourceAllocations[index].dataValueSubPhase = dataValueSubPhase;
                            }
                        }
    
                        cmsData[0].ResourceAllocations[index].DateStartDate = resource.StartDate;
                    });
    
                    let statusofProjOrCr = null;
                    let { startDate, allocationStartDate } = await this.getEstimatedStartDate(obj, cmsData[0]);
                    projOrCrStartDate = startDate;
    
                    if(obj.CRName && obj.CRName !== null){
                        statusofProjOrCr = obj.Status.Code;
                    }
                    else{
                        statusofProjOrCr = obj.ProjectStatus.Code;
                    }
    
                    if(statusofProjOrCr === 'BILLABLE' || statusofProjOrCr === 'PRESALES'){
                        //APS, BWO, AUG projects/crs
                        if(obj.Type.id === 3 || obj.Type.id === 5 || obj.Type.id === 6){
                            endDate = obj.EndDate ? obj.EndDate : '';
                        }
                        //other projects/crs
                        else{
                            let totalDays = 0;
                            let totalWorkingDayCount = cmsData[0].TotalWorkingDaysCount;
                            let endDateBillable = reStructureDate(startDate);
                            let maxResource = await this.getResourceWithMaxEndDate(obj, cmsData[0]);
                            let defaultCountry = null;
        
                            if(maxResource){
                                let onsiteStatus = maxResource.OnsiteStatus ? maxResource.OnsiteStatus : '';
        
                                if(onsiteStatus && onsiteStatus === 'Offshore'){
                                    defaultCountry = maxResource.dataValueResourceRegion ? maxResource.dataValueResourceRegion.DefaultCountryId : obj.CountryId;
                                }
                                else{
                                    defaultCountry = obj.CountryId;
                                }
                            }
        
                            const holidays = await getHolidaysIntegrated(defaultCountry, endDateBillable.getFullYear())
                            .then(res => {
                                if (res && res.data) {
                                return res.data;
                                }
                                return [];
                            })
                            .catch(error => {
                                loggerService.writeLog(error, LOG_TYPES.ERROR);
                                return [];
                            });
                    
                            let isHoliday = false;
                            for(let i = 1; i < totalWorkingDayCount; i++){
                                totalDays++;
                                endDateBillable.setDate(endDateBillable.getDate() + 1);
                    
                                isHoliday = false;
                                const dateStr = getFormattedDate(endDateBillable);
        
                                if(holidays.indexOf(dateStr) > -1){
                                    isHoliday = true;
                                }
                    
                                if(isHoliday){
                                    totalWorkingDayCount++;
                                }
                    
                                if(isWeekend(endDateBillable)){
                                    totalWorkingDayCount++;
                                }
                            }
        
                            endDate = endDateBillable;

                        }
                    } 
                    else{
                        //APS, BWO, AUG projects/crs
                        if(obj.Type.id === 3 || obj.Type.id === 5 || obj.Type.id === 6){
                            endDate = obj.EndDate ? obj.EndDate : '';
                        }
                        //other projects/crs
                        else{
                            endDate = await this.getEstimatedEndDate(obj, cmsData[0]);
                        }
                    }
                }
                //no cms
                else{
                    //APS, BWO, AUG projects/crs
                    if(obj.Type.id === 3 || obj.Type.id === 5 || obj.Type.id === 6){
                        if(obj.StartDate && obj.StartDate !== null){
                            if((obj.ActivityLog) && (obj.ActivityLog.StartDate && obj.ActivityLog.StartDate !== null)){
                                if(obj.StartDate !== obj.ActivityLog.StartDate){
                                    projOrCrStartDate = obj.ActivityLog.StartDate;
                                }
                                else{
                                    projOrCrStartDate = obj.StartDate;
                                }
                            }
                            else{
                                projOrCrStartDate = obj.StartDate;
                            }
                        }
                        
                        endDate = obj.EndDate ? obj.EndDate : '';
                    }
                    //other projects/crs
                    else{
                        if(obj.StartDate && obj.StartDate !== null){
                            if((obj.ActivityLog) && (obj.ActivityLog.StartDate && obj.ActivityLog.StartDate !== null)){
                                if(obj.StartDate !== obj.ActivityLog.StartDate){
                                    projOrCrStartDate = obj.ActivityLog.StartDate;
                                }
                                else{
                                    projOrCrStartDate = obj.StartDate;
                                }
                            }
                            else{
                                projOrCrStartDate = obj.StartDate;
                            }
                        }
                        else if(obj.StartDate === null){
                            if((obj.ActivityLog) && (obj.ActivityLog.StartDate && obj.ActivityLog.StartDate !== null)){
                                projOrCrStartDate = obj.ActivityLog.StartDate;
                            }
                            else{
                                projOrCrStartDate = '';
                            }
                        }
    
                        endDate = obj.EndDate ? obj.EndDate : '';
                    }
                }
                let pmResource = null;
                
                if(obj.CRName && obj.CRName !== null){
                    currency = (obj.Region &&  obj.Region.Currency && obj.Region.Currency.Code) ? obj.Region.Currency.Code : '';

                    const crProjectManagers = obj.AllocatedPM;
                    //find valid pms from end date
                    let crValidManagers = [];
                    crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

                    if (crValidManagers.length == 0 && crProjectManagers.length > 0){
                        crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
                        const lastAssignedManager = crProjectManagers[0];
                        crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
                    }
                    if (crValidManagers.length > 0) {
                        pmResource = crValidManagers[0].PMResource
                    }

                    item = {
                        proOrCRId:obj.id,
                        projectCRName : obj.CRName,
                        billingDivision:this.state.Divisions && this.state.Divisions.filter(item => item.id === obj.BillingDivisionId).length > 0 ? this.state.Divisions.filter(item => item.id === obj.BillingDivisionId)[0].Name : '',
                        billingRegion:this.state.Regions && this.state.Regions.filter(item => item.id === obj.BillingRegion).length > 0 ? this.state.Regions.filter(item => item.id === obj.BillingRegion)[0].Name : '',
                        customer:this.state.Customers && this.state.Customers.filter(item => item.id === obj.Customer.id).length > 0 ? this.state.Customers.filter(item => item.id === obj.Customer.id)[0].Name : '',
                        projectCRId:this.state.UniqueCRIds && this.state.UniqueCRIds.filter(item => item.UniqueId === obj.UniqueId).length > 0 ? this.state.UniqueCRIds.filter(item => item.UniqueId === obj.UniqueId)[0].UniqueId : '',
                        startDate: projOrCrStartDate ? moment(projOrCrStartDate).format('YYYY-MM-DD') : '',
                        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
                        projectManager:(this.state.resourceItemsPro && pmResource && this.state.resourceItemsPro.filter(item => item.id === pmResource.id).length > 0 )? this.state.resourceItemsPro.filter(item => item.id === pmResource.id)[0].Name : '',
                        currentStatus:this.state.Statuses && this.state.Statuses.filter(item => item.id === obj.Status.id).length > 0 ? this.state.Statuses.filter(item => item.id === obj.Status.id)[0].StatusName : '',
                        requestedStatus:this.state.Statuses && this.state.Statuses.filter(item => parseInt(item.id) === parseInt(obj.ActivityLog.Status)).length > 0 ? this.state.Statuses.filter(item => parseInt(item.id) === parseInt(obj.ActivityLog.Status))[0].StatusName : '',
                        requestedStatusId:this.state.Statuses && this.state.Statuses.filter(item => parseInt(item.id) === parseInt(obj.ActivityLog.Status)).length > 0 ? this.state.Statuses.filter(item => parseInt(item.id) === parseInt(obj.ActivityLog.Status))[0].id : '',
                        comment:(obj.ActivityLog && obj.ActivityLog.Comment) ? obj.ActivityLog.Comment : '',
                        activityLogId:(obj.ActivityLog && obj.ActivityLog.id) ? obj.ActivityLog.id : '',
                        fileUpload: (obj.ActivityLog && obj.ActivityLog.FileUpload.length > 0) ? obj.ActivityLog.FileUpload : '',
                        isCR:true,
                        proId:(obj.ActivityLog && obj.ActivityLog.ProjectId) ? obj.ActivityLog.ProjectId : null,
                        crId:(obj.ActivityLog && obj.ActivityLog.CrId) ? obj.ActivityLog.CrId : null,
                        wfId: (obj.ActivityLog && obj.ActivityLog.WorkflowId) ? obj.ActivityLog.WorkflowId : null,
                        revenueafterDiscount: (obj.CMSheets && obj.CMSheets.length > 0 && obj.CMSheets[0].ProjectRevenueAfterDiscount) ? (currency + ' ' + Number(obj.CMSheets[0].ProjectRevenueAfterDiscount).toFixed(2)) : '',
                        changedBy: (obj.ActivityLog && obj.ActivityLog.changedBy) ? obj.ActivityLog.changedBy : null,
                    }
    
                    allGridData.push(item)
    
                    ProjectOrCRItems = {
                        Name: obj.CRName,
                        id: (obj.ActivityLog && obj.ActivityLog.CrId !== null) ? obj.ActivityLog.CrId : ''
                    }
                    ProjectOrCrUniqueIds.push(ProjectOrCRItems);
                }
                else{
                    currency = (obj.Region &&  obj.Region.Currency && obj.Region.Currency.Code) ? obj.Region.Currency.Code : '';

                    const projectProjectManagers = obj.AllocatedResourceRequestPM;
                    //find valid pms from end date
                    let projValidManagers = [];
                    projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

                    if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
                        projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
                        const lastAssignedManager = projectProjectManagers[0];
                        projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
                    }
                    if (projValidManagers.length > 0) {
                        pmResource = projValidManagers[0].PMResource
                    }
                    item = {
                        proOrCRId:obj.id,
                        projectCRName : obj.ProjectName,
                        billingDivision:this.state.Divisions && this.state.Divisions.filter(item => item.id === obj.BillingDivision).length > 0 ? this.state.Divisions.filter(item => item.id === obj.BillingDivision)[0].Name : '',
                        billingRegion:this.state.Regions && this.state.Regions.filter(item => item.id === obj.BillingRegion).length > 0 ? this.state.Regions.filter(item => item.id === obj.BillingRegion)[0].Name : '',
                        customer:this.state.Customers && this.state.Customers.filter(item => item.id === obj.Customer.id).length > 0 ? this.state.Customers.filter(item => item.id === obj.Customer.id)[0].Name : '',
                        projectCRId:this.state.UniqueIds  && this.state.UniqueIds.filter(item => item.UniqueId === obj.UniqueId).length > 0 ? this.state.UniqueIds.filter(item => item.UniqueId === obj.UniqueId)[0].UniqueId : '',
                        startDate: projOrCrStartDate ? moment(projOrCrStartDate).format('YYYY-MM-DD') : '',
                        endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
                        projectManager:this.state.resourceItemsPro && pmResource && this.state.resourceItemsPro.filter(item => item.id === pmResource.id).length > 0 ? this.state.resourceItemsPro.filter(item => item.id === pmResource.id)[0].Name : '',
                        currentStatus:this.state.Statuses && this.state.Statuses.filter(item => item.id === obj.Status).length > 0 ? this.state.Statuses.filter(item => item.id === obj.Status)[0].StatusName : '',
                        requestedStatus:this.state.Statuses && this.state.Statuses.filter(item => parseInt(item.id) === parseInt(obj.ActivityLog.Status)).length > 0 ? this.state.Statuses.filter(item => parseInt(item.id) === parseInt(obj.ActivityLog.Status))[0].StatusName : '',
                        requestedStatusId:this.state.Statuses && this.state.Statuses.filter(item => parseInt(item.id) === parseInt(obj.ActivityLog.Status)).length > 0 ? this.state.Statuses.filter(item => parseInt(item.id) === parseInt(obj.ActivityLog.Status))[0].id : '',
                        comment:(obj.ActivityLog && obj.ActivityLog.Comment) ? obj.ActivityLog.Comment : '',
                        activityLogId:(obj.ActivityLog && obj.ActivityLog.id) ? obj.ActivityLog.id : '',
                        isCR:false,
                        proId:(obj.ActivityLog && obj.ActivityLog.ProjectId) ? obj.ActivityLog.ProjectId : null,
                        crId:(obj.ActivityLog && obj.ActivityLog.CrId) ? obj.ActivityLog.CrId : null,
                        fileUpload: (obj.ActivityLog && obj.ActivityLog.FileUpload.length > 0) ? obj.ActivityLog.FileUpload : '',
                        wfId: (obj.ActivityLog && obj.ActivityLog.WorkflowId) ? obj.ActivityLog.WorkflowId : null,
                        revenueafterDiscount: (obj.CMSheets && obj.CMSheets.length > 0 && obj.CMSheets[0].ProjectRevenueAfterDiscount) ? (currency + ' ' + Number(obj.CMSheets[0].ProjectRevenueAfterDiscount).toFixed(2)) : '',
                        changedBy: (obj.ActivityLog && obj.ActivityLog.changedBy) ? obj.ActivityLog.changedBy : null,
                    }
    
                    allGridData.push(item)
    
                    ProjectOrCRItems = {
                        Name: obj.ProjectName,
                        id: (obj.ActivityLog && obj.ActivityLog.ProjectId !== null) ? obj.ActivityLog.ProjectId : ''
                    }
                    ProjectOrCrUniqueIds.push(ProjectOrCRItems);
                }
    
            }
        }
        

        if(allGridData && allGridData.length > 0){
            for(let el of allGridData){
                for(let item of this.state.Divisions){
                    if(item.Name === el.billingDivision){
                        divisions.push(item);
                    }
                }
                for(let item of this.state.Regions){
                    if(item.Name === el.billingRegion){
                        billingRegions.push(item);
                    }
                }
                for(let item of this.state.Customers){
                    if(item.Name === el.customer){
                        customers.push(item);
                    }
                }
                for(let item of ProjectOrCrUniqueIds){
                    if(item.Name === el.projectCRName){
                        projectOrCr.push(item);
                    }
                }
                if(this.state.resourceItems.length > 0){
                    for(let item of this.state.resourceItems){
                        if(item.Name === el.projectManager){
                            projManagers.push(item);
                        }
                    }
                }

                for(let item of this.state.Statuses){
                    if(item.StatusName === el.requestedStatus){
                        reqStatuses.push(item);
                    }
                }
            }
        }

        uniqueDivisions = uniq(divisions, false);
        uniqueBillingRegions = uniq(billingRegions, false);
        uniqueCustomers = uniq(customers, false);
        uniqueProjCrs = uniq(projectOrCr, false);
        uniqueProjManagers = uniq(projManagers, false);
        uniqueReqStatus = uniq(reqStatuses, false);

        await this.setState({
            allGridData: allGridData,
            allGridDataInitial: allGridData,
            gridDataFromSavedParams: allGridData,
            loading: false,
            Divisions: uniqueDivisions,
            DivisionsAll: uniqueDivisions,
            Regions: uniqueBillingRegions,
            RegionsAll: uniqueBillingRegions,
            Customers: uniqueCustomers,
            CustomersAll: uniqueCustomers,
            ProjectOrCrUniqueIds: uniqueProjCrs,
            ProjectOrCrUniqueIdsAll: uniqueProjCrs,
            resourceItems: uniqueProjManagers,
            Statuses: uniqueReqStatus,
            StatusesAll: uniqueReqStatus
        });

        let savedSearchParams = this.state.savedSearchParams;
        if(savedSearchParams && savedSearchParams.length > 0){
            await this.setSavedSearchParams();
        }

        await this.setState({
            loading: false
        });

    }


    getEstimatedStartDate =  async (projectOrCr, cmSheet) => {
        let startDate = null;
        let allocationStartDate;
        let nullAllocDate = null;

        if((projectOrCr) && (projectOrCr.StartDate && projectOrCr.StartDate !== null)){
            if(projectOrCr.ActivityLog && projectOrCr.ActivityLog.StartDate !== null){
                if(projectOrCr.ActivityLog.StartDate !== projectOrCr.StartDate){
                    startDate = reStructureDate(projectOrCr.ActivityLog.StartDate);
                }
                else{
                    startDate = reStructureDate(projectOrCr.StartDate);
                }
            }
            else{
                startDate = reStructureDate(projectOrCr.StartDate);
            }

        }
        
        if(!startDate){
            let minDate = null;
            
            for(let allocation of cmSheet.ResourceAllocations){
                if(allocation.StartDate){
                    const tempDate = reStructureDate(allocation.StartDate);
                    if((minDate && tempDate < minDate) || !minDate){
                        minDate = tempDate;
                    }
                } 
                else if(allocation.dataValueSubPhase && allocation.dataValueSubPhase.StartDate){
                    const tempDate = reStructureDate(allocation.dataValueSubPhase.StartDate);
                    if((minDate && tempDate < minDate) || !minDate){
                        minDate = tempDate;
                    }
                } 
                else if(allocation.dataValuePhase && allocation.dataValuePhase.StartDate){
                    const tempDate = reStructureDate(allocation.dataValuePhase.StartDate);
                    if((minDate && tempDate < minDate) || !minDate){
                        minDate = tempDate;
                    }
                } 
                else{
                    nullAllocDate = new Date();
                }

                if(minDate){
                    startDate = minDate;
                } 
                else{
                    startDate = new Date();
                }
            }
      
            if(startDate > nullAllocDate && nullAllocDate){
                startDate = nullAllocDate;
            }
        }
        
        allocationStartDate = startDate;
        return {startDate, allocationStartDate};
    }



    getResourceWithMaxEndDate = async (projectOrCr, cmSheet) => {
        let maxDate = null;
        let maxAllocation = null;

        for (const allocation of cmSheet.ResourceAllocations) {
          if (allocation.EstimatedEndDate){
            const tempDate = reStructureDate(allocation.EstimatedEndDate);
            const tempAllocation = allocation;

            if ((maxDate && tempDate > maxDate) || !maxDate) {
              maxDate = tempDate;
              maxAllocation = tempAllocation;
            }
          }
        }
        return maxAllocation;
    };


    getEstimatedEndDate = async (projectOrCr, cmSheet) => {
        let maxDate = null;
    
        for(const allocation of cmSheet.ResourceAllocations){
            if(allocation.EndDate !== null){
                const tempDate = reStructureDate(allocation.EndDate);
                if((maxDate && tempDate > maxDate) || !maxDate){
                    maxDate = tempDate;
                }
            }
            else if(allocation.EstimatedEndDate !== null){
                const tempDate = reStructureDate(allocation.EstimatedEndDate);
                if((maxDate && tempDate > maxDate) || !maxDate){
                    maxDate = tempDate;
                }
            }
        }
        return maxDate;
    };


    toggleDialog = async (message, title) => {
        if(message){
            await this.setState({
                popupVisible: !this.state.popupVisible,
                dialogMessage: message,
                dialogTitle: title
            });
        }
    };

    filterChangeCombo = event => {
        clearTimeout(this.timeout);
        const field = event.target.name;
        this.timeout = setTimeout(() => {
            switch (field) {
                case 'customer': {
                    this.setState({
                        Customers: this.filterComboData(event.filter, this.state.CustomersAll)
                    });
                    break;
                }
                case 'Program': {
                    this.setState({
                        Programs: this.filterComboData(event.filter, this.state.ProgramsAll)
                    });
                    break;
                }
                case 'Division': {
                    this.setState({
                        Divisions: this.filterComboData(event.filter, this.state.DivisionsAll)
                    });
                    break;
                }
                case 'billingRegion': {
                    this.setState({
                        Regions: this.filterComboData(event.filter, this.state.RegionsAll)
                    });
                    break;
                }
                case 'ProjectType': {
                    this.setState({
                        ProjectTypes: this.filterComboData(event.filter, this.state.ProjectTypesAll)
                    });
                    break;
                }
                case 'PaymentMethod': {
                    this.setState({
                        PaymentMethods: this.filterComboData(event.filter, this.state.PaymentMethodsAll)
                    });
                    break;
                }
                case 'UniqueId': {
                    this.setState({
                        UniqueIds: this.filterComboData(event.filter, this.state.UniqueIdsAll)
                    });
                    break;
                }
                case 'opidvalue': {
                    this.setState({
                        opids: this.filterComboData(event.filter, this.state.opidsAll)
                    });
                    break;
                }
                case 'projectManager': {
                    this.setState({
                        resourceItems: this.filterComboData(event.filter, this.resourceAll)
                    });
                    break;
                }
                case 'UniqueCRId': {
                    this.setState({
                        UniqueCRIds: this.filterComboData(event.filter, this.state.UniqueCRIdsAll)
                    });
                    break;
                }
                case 'projectCR': {
                    this.setState({
                        ProjectOrCrUniqueIds: this.filterComboData(event.filter, this.state.ProjectOrCrUniqueIdsAll)
                    });
                    break;
                }
                case 'requestedStatus': {
                    this.setState({
                        Statuses: this.filterComboData(event.filter, this.state.StatusesAll)
                    });
                    break;
                }
                default: {
                }
            }
        }, 0);
    };

    filterComboData(filter, allData) {
        const data = allData.slice();
        return filterBy(data, filter);
    }

    toggleConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    toggleMessageDialog = (message, title) => {
        this.setState({
            showMessageDialog: !this.state.showMessageDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    toggleRejectCommentDialog = (title) => {
        this.setState({
            showRejectCommentDialog: !this.state.showRejectCommentDialog,
            dialogTitle: title
        });
    };

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    };

    onChange = (event) => {
        this.setState({
            value: [...event.target.value]
        });
    };

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        this.state.allGridData.forEach(item => item.Selected = checked);
        this.forceUpdate();

        const selected = this.state.allGridData.filter(resource => resource.Selected === true);
        this.setState({
            selectedResources: selected
        });
    };
    
    selectionChange = async (event) => {
        event.dataItem.Selected = !event.dataItem.Selected;

        const index = this.state.allGridData.indexOf(event.dataItem);
        if (event.dataItem.Selected) {
            this.ticked.push(index);
        } else {
            this.ticked.splice(this.ticked.indexOf(index), 1);
        }

        this.forceUpdate();

        const selected = await this.state.allGridData.filter(resource => resource.Selected === true);
        await this.setState({
            selectedResources: selected,
            numSelectedLines: this.ticked.length
        });
    };

    handleOnChangeDropDown = async event => {
        const name = event.target.name;
        const value = event.target.value;

        await this.setState({
            [name]: value,
            isFormDirty: value.length > 0 ? true : false
        });
    };

    submitSearch = event => {
        if(event){
            event.preventDefault();
        }

        let allGridData = this.state.allGridDataInitial;
        let division = this.state.Division;
        let billingRegion = this.state.billingRegion;
        let customer = this.state.customer;
        let projectCR = this.state.projectCR;
        let projectManager = this.state.projectManager;
        let requestedStatus = this.state.requestedStatus;
        let gridData = [];
        let filteredData = [];
        let id = 0;

        let gridDataByDivision = [];
        let gridDataByBillingRegion = [];
        let gridDataByCustomer = [];
        let gridDataByProjectCR = [];
        let gridDataByProjectManager = [];

        if(allGridData && allGridData.length > 0){
            if(division && division.length > 0){
                for(let record of allGridData){
                    division.filter(el => el.Name === record.billingDivision ? gridDataByDivision.push(record) : '');
                }
            }
            else{
                for(let record of allGridData){
                    gridDataByDivision.push(record);
                }
            }

            if(billingRegion && billingRegion.length > 0){
                for(let record of gridDataByDivision){
                    billingRegion.filter(el => el.Name === record.billingRegion ? gridDataByBillingRegion.push(record) : '');
                }
            }
            else{
                for(let record of gridDataByDivision){
                    gridDataByBillingRegion.push(record);
                }
            }

            if(customer && customer.length > 0){
                for(let record of gridDataByBillingRegion){
                    customer.filter(el => el.Name === record.customer ? gridDataByCustomer.push(record) : '');
                }
            }
            else{
                for(let record of gridDataByBillingRegion){
                    gridDataByCustomer.push(record);
                }
            }

            if(projectCR && projectCR.length > 0){
                for(let record of gridDataByCustomer){
                    projectCR.filter(el => el.Name === record.projectCRName ? gridDataByProjectCR.push(record) : '');
                }
            }
            else{
                for(let record of gridDataByCustomer){
                    gridDataByProjectCR.push(record);
                }
            }

            if(projectManager && projectManager.length > 0){
                for(let record of gridDataByProjectCR){
                    projectManager.filter(el => el.Name === record.projectManager ? gridDataByProjectManager.push(record) : '');
                }
            }
            else{
                for(let record of gridDataByProjectCR){
                    gridDataByProjectManager.push(record);
                }
            }

            if(requestedStatus && requestedStatus.length > 0){
                for(let record of gridDataByProjectManager){
                    requestedStatus.filter(el => el.StatusName === record.requestedStatus ? gridData.push(record) : '');
                }
            }
            else{
                for(let record of gridDataByProjectManager){
                    gridData.push(record);
                }
            }
        }
        
        gridData.forEach(el => {
            el.Selected = false;
        })

        this.setState({
            allGridData: gridData,
            selectedResources:[]
         });
    };

    cancelSearch = event => {
        event.preventDefault();
        this.setState({
            Division: null,
            billingRegion: null,
            customer: null,
            projectCR: null,
            projectManager: null,
            requestedStatus: null,
            savedSearchParams: [],
            isFormDirty: false,
            selectedResources:[]
        });
        this.setProjectDataToGrid();
    };

    handleRejectReasonInputChange = event => {
        const value = event.target.value;
        this.setState({
            rejectComment: value
        });
    };

    handleApproveReasonInputChange = event => {
        const value = event.target.value;
        this.setState({
            approverComment: value
        });
    };

    saveSearch = async event => {
        event.preventDefault();

        let division = this.state.Division;
        let billingRegion = this.state.billingRegion;
        let customer = this.state.customer;
        let projectCR = this.state.projectCR;
        let projectManager = this.state.projectManager;
        let requestedStatus = this.state.requestedStatus;
        let userId = this.state.userId;

        const searchCriteriasObj = {
            Practice: (division && division.length > 0) ? division : '',
            BillingRegion: (billingRegion && billingRegion.length > 0) ? billingRegion : '',
            Customer: (customer && customer.length > 0) ? customer : '',
            ProjectCR: (projectCR && projectCR.length > 0) ? projectCR : '',
            ProjectManager: (projectManager && projectManager.length > 0) ? projectManager : '',
            ReqStatus: (requestedStatus && requestedStatus.length > 0) ? requestedStatus : '',
            userId: userId
        };

        if((this.state.isFormDirty === true) ||
            (!searchCriteriasObj.Practice && !searchCriteriasObj.BillingRegion && !searchCriteriasObj.Customer &&
            !searchCriteriasObj.ProjectCR && !searchCriteriasObj.ProjectManager && !searchCriteriasObj.ReqStatus)) {

            await saveSearchCriteria(searchCriteriasObj).then(response => {
                this.setState({
                    showSuccessImg: true
                });
                this.toggleMessageDialog('Selected search criterians are successfully saved.', 'Success');
            }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        }
    };

    render() {
        if (this.state.navigateToDashboard === true) {
            return <Redirect to="/" />;
        }
        return (
            <div className="main-card">
                <div className="row">
                    <div className="col-md-8">
                        <div className="main-heading">Approve Change Status</div>
                    </div>
                </div>

                <form onSubmit={this.submitSearch}>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="d-block">
                                <label>Department:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <MultiSelect
                                        className="text-break"
                                        id="practice"
                                        data={this.state.Divisions}
                                        textField="Name"
                                        dataItemKey="id"
                                        value={this.state.Division}
                                        onChange={this.handleOnChangeDropDown}
                                        name="Division"
                                        placeholder="Please Select"
                                        filterable={true}
                                        onFilterChange={this.filterChangeCombo}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="d-block">
                                <label>Billing Region:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <MultiSelect
                                        className="text-break"
                                        name="billingRegion"
                                        id="billingRegion"
                                        data={this.state.Regions}
                                        textField="Name"
                                        onChange={this.handleOnChangeDropDown}
                                        value={this.state.billingRegion}
                                        placeholder="Please Select"
                                        onFilterChange={this.filterChangeCombo}
                                        filterable={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="d-block">
                                <label>Customer:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <MultiSelect
                                        className="text-break"
                                        name="customer"
                                        id="customer"
                                        data={this.state.Customers}
                                        textField="Name"
                                        onChange={this.handleOnChangeDropDown}
                                        value={this.state.customer}
                                        placeholder="Please Select"
                                        onFilterChange={this.filterChangeCombo}
                                        filterable={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="d-block">
                                <label>Project/CR:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <MultiSelect
                                        className="text-break"
                                        name="projectCR"
                                        id="projectCR"
                                        data={this.state.ProjectOrCrUniqueIds}
                                        textField="Name"
                                        onChange={this.handleOnChangeDropDown}
                                        value={this.state.projectCR}
                                        placeholder="Please Select"
                                        onFilterChange={this.filterChangeCombo}
                                        filterable={true}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-md-3">
                            <div className="d-block">
                                <label>Project Manager:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <MultiSelect
                                        className="text-break"
                                        name="projectManager"
                                        id="projectManager"
                                        data={this.state.resourceItems}
                                        textField="Name"
                                        onChange={this.handleOnChangeDropDown}
                                        value={this.state.projectManager}
                                        placeholder="Please Select"
                                        onFilterChange={this.filterChangeCombo}
                                        filterable={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="d-block">
                                <label>Requested Status:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <MultiSelect
                                        className="text-break"
                                        name="requestedStatus"
                                        id="requestedStatus"
                                        data={this.state.Statuses}
                                        textField="StatusName"
                                        onChange={this.handleOnChangeDropDown}
                                        value={this.state.requestedStatus}
                                        placeholder="Please Select"
                                        onFilterChange={this.filterChangeCombo}
                                        filterable={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                            <Button primary={true} type="submit" onClick={this.submitSearch}>
                                Search
                            </Button>
                            <Button type="button" onClick={this.saveSearch}>
                                Save
                            </Button>
                            <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
                                Clear
                            </Button>
                        </div>
                    </div>


                    <div className="main-seperator"></div>

                    <div className="row">
                        <div className="col-md-8">
                            <div className="main-heading">Search Results</div>
                        </div>

                        <div className="col-md-4">
                            <Button primary={true} disabled={this.state.selectedResources.length?(false):true} 
                                type="button" onClick={this.onClickApprove}  className="pull-right">
                                Approve
                            </Button>

                            <Button primary={true} disabled={this.state.selectedResources.length?(false):true} 
                                type="button" onClick={this.onClickReject} style={{marginRight:'5px'}} className="pull-right">
                                Reject
                            </Button>
                        </div>
                    </div>

                    <div className="row mt-0">
                        <div className="col-md-12">
                            <Grid
                                data={orderBy(this.state.allGridData, this.state.sort).slice(
                                    this.state.skip, 
                                    this.state.take + this.state.skip
                                )}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.allGridData.length}
                                pageable={true}
                                onPageChange={this.pageChange}
                                resizable
                                sortable={true}
                                sort={this.state.sort}
                                onSortChange={(e) => {
                                    this.setState({
                                        sort: e.sort
                                    });
                                }}
                                selectedField="Selected"
                                onHeaderSelectionChange={this.headerSelectionChange}
                                onSelectionChange={this.selectionChange}
                                className="search-result-grid"
                            >
                                <Column
                                    field="Selected"
                                    width="50px"
                                    headerSelectionValue={
                                        this.state.allGridData.findIndex(dataItem => dataItem.Selected === false) === -1
                                }/>
                                <Column
                                    field="billingDivision"
                                    title="Practice"
                                    width="100px"
                                />

                                <Column
                                    field="billingRegion"
                                    title="Billing region"
                                    width="100px"
                                />

                                <Column
                                    field="customer"
                                    title="Customer"
                                    width="100px"
                                />

                                <Column
                                    field="projectCRName"
                                    title="Project/CR Name"
                                    width="100px"
                                />

                                <Column
                                    field="projectCRId"
                                    title="Project/CR ID"
                                    width="100px"
                                />

                                <Column
                                    field="startDate"
                                    title="Start Date"
                                    width="75px"

                                />
                                <Column
                                    field="endDate"
                                    title="End Date"
                                    width="75px"
                                />

                                <Column
                                    field="projectManager"
                                    title="Project Manager"
                                    width="100px"
                                />
                                <Column
                                    field="currentStatus"
                                    title="Current Status"
                                    width="100px"
                                />
                                <Column
                                    field="requestedStatus"
                                    title="Requested Status"
                                    width="100px"
                                />
                                <Column
                                    field="revenueafterDiscount"
                                    title="Revenue for Fortude after Discount"
                                    width="200px"
                                />
                                <Column
                                    field="comment"
                                    title="Comment"
                                    width="200px"
                                    cell={props => (props.dataItem.comment !== null ? <td><div className={'rich-text-bullets'}>{Parser(props.dataItem.comment)}</div></td> : <td>{''}</td>)}
                                />
                                <Column
                                    field=""
                                    title="Document Uploaded"
                                    width="200px"
                                    cell={props => (<SearchResultsFileCell ApiUrl={apiUrl} name="DocType" {...props} />)}
                                />

                            </Grid>
                        </div>
                    </div>

                </form>

                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ margin: '15px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                        <p><label>Approver's Comment (Optional):</label></p>
                        <textarea className="k-textarea" rows="5" cols="50" placeholder="Type Comment"
                                  onChange={this.handleApproveReasonInputChange}
                                  value={this.state.approverComment}
                        ></textarea>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleConfirmDialog}>
                                No
                            </button>
                            <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                <div>
                    {this.state.popupVisible === true && (
                        <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                            <DialogActionsBar>
                                    <button className="k-button modal-primary" onClick={this.toggleDialog()}>
                                        OK
                                    </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

                {this.state.showMessageDialog == true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
                      <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                      <DialogActionsBar>
                        <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                          OK
                        </button>
                      </DialogActionsBar>
                    </Dialog>
                )}

                {this.state.showRejectCommentDialog == true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleRejectCommentDialog} width="400px">
                        <textarea className="k-textarea" rows="5" cols="50" placeholder="Type Comment"
                                  onChange={this.handleRejectReasonInputChange}
                                  value={this.state.rejectComment}
                        ></textarea>
                        <DialogActionsBar>
                                <button className="k-button modal-primary" onClick={this.rejectConfirm}>
                                    OK
                                </button>
                        </DialogActionsBar>
                    </Dialog>
                )}


                <Loader loading={this.state.loading} />
            </div>

        );
    }
}

export default ApproveChangeStatus;
