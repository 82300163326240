import React, { Component } from "react";
import { Link } from "react-router-dom";

class EditDesignationGroupActionsCell extends Component {
  render() {
    const { OnDeactivate } = this.props;
    const { OnActivate } = this.props;
    const designationGroup = this.props.dataItem;
    return (
      <td className="action-buttons">
        {designationGroup.IsActive === true && (window.USER_PERMISSION.indexOf('ED_DEG_GP') > -1) && (
          <Link
            to={{
              pathname: "/admin/createdesignationgroup/createdesignationgroup",
              designationGroup: designationGroup,
              action: "edit",
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-edit" title="Edit" />
            </div>
          </Link>
        )}
        {designationGroup.IsActive === true && (window.USER_PERMISSION.indexOf('ED_DEG_GP') > -1) && (
          <Link
            to={this.props}
            onClick={event => OnDeactivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-unsort" title="Deactivate" />
            </div>
          </Link>
        )}
        {designationGroup.IsActive === false && (window.USER_PERMISSION.indexOf('ED_DEG_GP') > -1) && (
          <Link
            to={this.props}
            onClick={event => OnActivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-check" title="Activate" />
            </div>
          </Link>
        )}
      </td>
    );
  }
}

export default EditDesignationGroupActionsCell;
