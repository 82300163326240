import React, { Component } from 'react';
import { Menu } from '@progress/kendo-react-layout';

import {
  Button,
} from '@progress/kendo-react-buttons';
import {AutoComplete, ComboBox} from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import SystemSearch from '../../integration/components/SystemSearch';
import {saveAuditTrail} from "../../integration/auditTrail/AuditTrailService";
import {getAllUniqueIds,fullTextSearchProjectsNew, fullTextSearchProjectsCR} from "../../projectManagement/project/ProjectService";
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import DarkModeToggle from "../../DarkModeToggle";

const serverUrl = require('../../config').get(process.env.REACT_APP_ENV).serverUrl;

const opidAll = [];
class TopBar extends Component {
  state = {
    globalSearch: '',
    searchResult: [],
    isSearchResultVisible: false,
    skip: 0,
    take: 15,
  };

  componentDidMount() {
  }

  populateOPIDs = () => {
    getAllUniqueIds()
      .then(res => {
        this.opidAll = res.data;
        this.setState({
          opids: res.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  // themes = ["Light", "Dark", "Fortude"]

  render() {
    let items = [
      {
        text: `${this.props.displayName}`,
        icon: 'user',
        items: [{ text: 'Log Out', data: { action: 'logout' } }]
      }
    ];
    return (
      <form onSubmit={this.handleSubmit} className='top-bar-wrapper'>
        <div className="top-bar">
          <div className="page-title">{this.props.headerTitle}</div>

          <div className="user-info">
            <Menu items={items} onSelect={this.onSelect} />
          </div>

          <div className="vertical-menu-seperator" />

          <div className="global-search">
            <div className="row">
              <div className="mr-2">
                <label>Search:</label>
              </div>
              <div className="searchbar">
                <input
                  name="globalSearch"
                  value={this.state.globalSearch}
                  onChange={this.handleChangeGlobalSearch}
                />
              </div>
              <div className="search-btn">
                <Button type="submit" icon="search" look="bare" disabled={window.USER_PERMISSION.length <= 0} onClick={this.handleSubmit} />
                {this.state.isSearchResultVisible && this.globalSearchResult(this.state.searchResult)}
              </div>
              <div className="vertical-menu-seperator" />
              <div className="mr-4">
                <DarkModeToggle />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }

  handleSubmit = async e => {
    e.preventDefault();
    await this.setState({ searchResult: [],
      skip: 0,
      take: 15
    });
    await this.search();
  };

  search = async () => {
    const Query = {
      Query: this.state.globalSearch,
      skip:this.state.skip,
      take:this.state.take
    };

    if(this.state.globalSearch){
      await fullTextSearchProjectsNew(Query)
      .then(async res => {  
        let projectIds = [];
        projectIds = res.data.rows.map(a=>a.id); 
        const Data = {
          projectIds:projectIds,
          Query: this.state.globalSearch
        }       
        await fullTextSearchProjectsCR(Data).then(async response=>
          {
              for(const proj of res.data.rows){
                const filteredProject = response.data.find(pro => pro.id === proj.id);
                if(filteredProject){
                  const index = res.data.rows.findIndex(a=>a.id=== proj.id);
                  res.data.rows[index].ChangeRequests = filteredProject.ChangeRequests;

                  if(filteredProject.ChangeRequests)
                  {
                    for(const ChangeRequest of filteredProject.ChangeRequests){
                      if(ChangeRequest.CMSheets && ChangeRequest.CMSheets.length > 0)
                      {
                        const cmSheets = ChangeRequest.CMSheets.sort((a, b) => b.MajorVersion - a.MajorVersion).sort((a, b) => b.Version - a.Version);
                        const cmSheet = cmSheets[0];
                        if(cmSheet){
                            const obj = {
                              id: cmSheet.id,
                              CurrentProfitability: cmSheet.CurrentProfitability,
                              Version: cmSheet.Version,
                              Status: cmSheet.Status,
                              MajorVersion: cmSheet.MajorVersion,
                              userHasPendingApproval: cmSheet.userHasPendingApproval,
                              IsCRCMS: true,
                              CrId: ChangeRequest.UniqueId
                            }
                            res.data.rows[index].CMSheets.push(obj);
                          }
                      }                    
                    }
                  }                  
                }               
              }  
              await this.setState({
                searchResult: res.data,
                isSearchResultVisible: true
              });             
          });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }
  };

  pageChange = event => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    }, () => this.search());    
  };

  globalSearchResult = searchResult => {
    return <SystemSearch searchResult={searchResult} search = {this.search} skip = {this.state.skip} take = {this.state.take} pageChange={this.pageChange} handelOnClick={this.handelOnClick} handleOpenChangeStatus = {this.handleOpenChangeStatus} />;
  };
  handleChangeGlobalSearch = async event => {

    await this.setState({
      globalSearch: event.target.value
    });


    if (this.state.globalSearch === '') {
      this.setState({ searchResult: [], 
        isSearchResultVisible: false
      });
    }

  };

  filterData(value) {
    const data = this.opidAll.slice();
    const filter = {
      field: 'UniqueId',
      operator: 'contains',
      ignoreCase: true,
      value: value
    };
    return value ? filterBy(data, filter) : data;
  }

  handelOnClick = () => {
    this.setState({
      globalSearch: '',
      searchResult: [],
      isSearchResultVisible: false
    });
  };

  handleOpenChangeStatus = () => {
    this.setState({
      globalSearch: ''
    });
  };

  onSelect = event => {
    if (event && event.item && event.item.data) {
      const action = event.item.data.action;
      const userDetails = window.LOGGED_USER;
      const auditTrail = {
        Action: 'User logged out',
        RefType: 'Log out',
        RefId: userDetails.userId,
        RefName: userDetails.displayName
      }
      if (action) {
        if (action === 'logout') {
          saveAuditTrail(auditTrail)
            .then(res => {
              this.props.logoutCallback();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });
        }
      }
    }
  };

  onSaveAuditTrail = () => {
    const userDetails = window.LOGGED_USER;
    const auditTrail = {
      Action: 'User logged out',
      RefType: 'Log out',
      RefId: userDetails.userId,
      RefName: userDetails.displayName
    }
    saveAuditTrail(auditTrail);
  }
}

export default TopBar;
