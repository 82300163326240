import http from '../HttpService';
//import { apiUrl } from '../config.json';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * uploadCMSFile - Upload a new File
 * @param file
 * @returns {object}
 */
export function uploadFile(file) {
    const endPoint = apiUrl + '/integration/fileUpload/upload/';
    return http.post(endPoint, file);
}

/**
 * replaceFile
 * @param file
 * @return {object} - Uploaded file
 */
export function replaceFile(file) {
    const endPoint = apiUrl + '/integration/fileUpload/replace/';
    return http.post(endPoint, file);
}

/**
 * downloadFile_ - Download a File
 * @param id
 * @returns {array}
 */
export function downloadFile_(id) {
    const endPoint = apiUrl + '/file/download?path='+id;
    return http.get(endPoint);
}

/**
 * deleteFileById - Delete a file by File Id
 * params( fileId)
 * @returns [0] or [1]
 */
export  function deleteFileById(fileId) {
    const endPoint = apiUrl + '/integration/fileUpload/file/'+fileId+'/remove';
    return http.get(endPoint, null);
}

/**
 * getCMSFilesByCMSheet - Retrieve files based on Cm sheet id
 * params(cmSheetId)
 * @returns {array} List of File
 */
export function getCMSFilesByCMSheet(cmSheetId) {
    const endPoint = apiUrl + '/file/cmSheet/files/' + cmSheetId;
    return http.get(endPoint, null);
}

