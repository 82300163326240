import React, { Component } from 'react';
import 'moment-timezone';
import Parser from 'html-react-parser';
import {getCurrencies} from "../../../../masterData/common/CommonService";
import * as loggerService from "../../../../integration/LoggerService";
import {LOG_TYPES} from "../../../../integration/IntegrationEnums";
import {getCustomerPrograms, getcustomers} from "../../../../masterData/customer/CustomerService";

class CustomerCell extends Component {

    constructor(props) {
        super(props);

        // this.state = {
        //     key:null
        // }
    }


     getCustomerName = async () => {
         let value = this.props.dataItem;
         let filterCustomer = [];
           await getcustomers()
             .then(res => {
                if (res.data) {


                          filterCustomer = res.data.filter(
                         obj => obj.id === value.ChangeRequests[0].CustomerId
                          );


                    value.ChangeRequests[0].CustomerName = filterCustomer[0].Name
                }
                 })


         //await this.setState({ key: Math.random() });

      }


    render() {
        let value = this.props.dataItem;

        if(value.ChangeRequests.length > 0){

            this.getCustomerName()

            // return <td key={this.state.key}>
            //     {value.ChangeRequests[0].CustomerName}
            // </td>;

            return <td>
                {value.ChangeRequests[0].CustomerName}
            </td>;

        } else{
            return <td>
                {value.Customer.Name}
            </td>;
        }
    }
}

export default CustomerCell;
