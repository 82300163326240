/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';

class RowCellDropDown extends Component {
    popupSet = {
        width: 'auto'
    };

    handleChange = event => {
        this.props.onChangeSelection(event, this.props.dataItem);
    };

    render() {
        if (
            !this.props.dataItem.inEdit &&
            !(
                this.props.field === 'PaymentMethod' &&
                this.props.dataItem.PaymentMethodShouldBeChanged === true
            )
        ) {
            return (
                <td>
                    {this.props.dataItem[this.props.dataValueField]
                        ? this.props.dataItem[this.props.dataValueField][this.props.textField]
                        : ''}
                </td>
            );
        }
        return (
            <td>
                <DropDownList className={(this.props.dataItem.autoPopulatedValueChaged === true && (this.props.dataItem.changedFields.indexOf(this.props.dataValueField) > -1)? 'badge badge-primary' : 'hidden')}
                              data={this.props.dataList}
                              textField={this.props.textField}
                              dataItemKey={this.props.dataItemKey}
                              value={this.props.dataItem[this.props.dataValueField]}
                              onChange={this.handleChange}
                              name={this.props.name}
                    // defaultItem={this.props.defaultItem}
                              disabled={this.props.disabled}
                              required={this.props.required}
                />
            </td>
        );
    }
}

export default RowCellDropDown;
