import React, { Component } from 'react';

class Footer extends Component {

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="rms-footer">© 2021 Fortude (Pvt) Ltd. All Rights Reserved.</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;