import React, { Component } from 'react';
import { GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import _ from 'lodash';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-react-dialogs';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import Loader from '../../integration/components/Loader';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import AsyncSelectPagination from './components/AsyncSelectPagination';
import {
  getAllNonProjectTasks,
  getAllNonProjectTaskUsers,
  searchNonProjectTasks,
  getAllNonProjectTaskGroups,
  saveNonProjectTask,
  updateNonProjectTaskStatus,
  deleteNonProjectTask,
  editNonProjectTask,
  getNonProjectTaskAllowcations,
  getAllocatedBillingRegion,
  getAllocatedPractice,
  getAllocatedDepartment,
  getNonProjectTaskApprovers,
  getallactiveusers,
  saveBulkResourceAddition,
  checkTimeEntryEnteredOrNot,
  replaceApproverOfNonProjectTasks,
  removeResourceOfNonProjectTasks,
  getFilteredNonProjectTasks,
  getFilteredNonProjectTaskGroups,
  getFilteredNonProjectTaskNames,
  getNonProjectTaskById,
  getNonProjectTaskExcel,
  getFilteredTask,
  saveBulkApproverAddition,
  saveBulkApproverRemoval,
  getResourceAllocatedRegion
} from './NonProjectTasksService';
import { getUserListIntegrated } from '../../integration/UserManagementSrvice';
import {
  getTaskTypesIntegrated,
  getRegionsIntegrated,
  getResourceRegionsIntegrated,
  getBillingDiviIntegrated,
  getPracticeTypesIntegrated,
  getAllPracticeDivisionMapIntegrated,
  getActiveAllocatedRegionsIntegrated
} from '../../integration/MasterDataService';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';

import {
  DateInput,
  Calendar,
  DatePicker,
  TimePicker,
  MultiViewCalendar,
  DateRangePicker
} from '@progress/kendo-react-dateinputs';
import TaskStatusCell from './components/TaskStatusCell';
import ApprovalTypeCell from './components/ApprovalTypeCell';
import ApproverCell from './components/ApproverCell';
import CreatedDateCell from './components/CreatedDateCell';
import UpdatedDateCell from './components/UpdatedDateCell';
import ActionsCell from './components/ActionsCell';
import AsyncSelect from './components/AsyncSelect';
import { DebounceInput } from 'react-debounce-input';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { saveAuditTrail } from '../../integration/auditTrail/AuditTrailService';
import moment from 'moment';
import 'moment-timezone';

const activeRepApprovers = [];

class NonProjectTaskSearch extends Component {
  _export;

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
      skip: 0,
      take: 20,
      total:0,
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      currentlySelectedRows: [],
      allNonProjectTasks: [],
      taskNamesAll: [],
      taskNames: [],
      selectedTask: [],
      isDisableResourceSave: true,
      isDisableReplaceSave: true,
      isDisableRemovalSave: true,
      status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
      selectedStatus: { Name: "Active", status: true },
      approvalType: [
        { Name: 'Approval Needed', status: true },
        { Name: 'Approval Not Needed', status: false }
      ],
      selectedApprovalType: '',
      selectedTaskGroup: [],
      taskGroups: [],
      sameUserValidationVisible: false,
      isDisableBulkResource: true,
      isDisableBulkReplace: true,
      isDisableBulkRemoval: true,
      inactiveFlag: false,
      usersAll: [],
      activeUsersAll: [],
      approvedUsers: [],
      selectedApproveUser: [],
      assignedUsers: [],
      selectedAssignUser: [],
      taskTypes: [],
      taskTypesAll: [],
      rescAddVisible: false,
      activeTaskTypesAll: [],
      failedArray: [],
      selectedTaskType: [],
      showAddDialog: false,
      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      loading: false,
      nonProjectTasksAll: [],
      isBulkResourceAddition: false,
      isBulkApprovalNotNeeded: false,
      resourceRemovalDate: null,
      actionText: '',
      nonProjectTasks: [],
      individualUsers: [],
      frequency: [
        { Name: 'Daily', code: 'DAILY' },
        { Name: 'Weekly', code: 'WEEKLY' },
        { Name: 'Monthly', code: 'MONTHLY' }
      ],
      weeks: [{ week: 'First' }, { week: 'Second' }, { week: 'Third' }, { week: 'Last' }],
      selectedWeek: null,
      days: [
        { day: 'Monday' },
        { day: 'Tuesday' },
        { day: 'Wednesday' },
        { day: 'Thursday' },
        { day: 'Friday' },
        { day: 'Saturday' },
        { day: 'Sunday' }
      ],
      selectedDay: null,
      assignedTo: [
        { Name: 'All Users', code: 'ALL' },
        { Name: 'All Deployable Users', code: 'DEPLOYABLE' },
        { Name: 'Department', code: 'DEPARTMENT' },
        { Name: 'Resource Region', code: 'RES_REGION' },
        { Name: 'Individual', code: 'INDIVIDUAL' },
        { Name: 'Practice', code: 'PRACTICE' },
        { Name: 'Allocated Region', code: 'ALLOC_REGION' },
        { Name: 'Multiple Assaignment', code: 'MULTIPLE' }
      ],
      regionsAll: [],
      regions: [],
      practiceAll: [],
      practice: [],
      users: [],
      assignedResourcesAll: [],
      assignedResources: [],
      individuals: [],
      isErrorMessageVisible: false,
      visible: false,
      showSuccessImage: false,
      showErrorImage: false,
      dialogMessage: '',
      dialogTitle: '',
      isDisableSave: false,
      editMode: false,
      viewMode: false,
      activeMode: false,
      newReplacingApprovers: [],
      newRepSelectedApprover: null,
      existingApprovers: [],
      selectedExistingApprover: null,
      selectedRemovalApprovers: [],
      newApprovers: [],
      newTaskTypes: [],

      //add
      actionPopupTitle: 'Add/Edit Non-Project Task',
      newSelectedTaskGroup: [],
      isNewTaskGroup: false,
      newTaskName: null,
      newSelectedTaskType: [],
      newTaskGroup: null,
      newSelectedApprover: [],
      newIsBillable: true,
      newIsNonBillable: false,
      newStartDate: null,
      newEndDate: null,
      noEndDate: true,
      isApprovalNotNeeded: false,
      newTimeDuration: 0.0,
      newFrequency: null,
      selectedFrequency: null,
      selectedAssignedTo: null,
      newSelectedRegion: [],
      newSelectedPractice: [],
      newSelectedIndividuals: [],
      bulkresourceassignment: false,
      deactivatePopupVisible: false,
      activatePopupVisible: false,
      deletePopupVisible: false,
      cancelNonProjectTaskPopupVisible: false,
      deactivateUserId: null,
      activateTaskId: null,
      deleteUserId: null,
      editTask: 0,
      successTasksCount: 0,
      nptSelectedCount: 0,
      addEditDefaultTaskVisible: false,
      currentAllowcation: [],
      allowcationList: [],
      allocatedBillingRegion: [],
      allocatedPractice: [],
      approvers: [],
      filteredTasks: [],
      selectedAsyncTask: null,
      taskQuery: null,
      totalTasksCount: 0,
      taskSkip: 10,
      loadingTasks: false,
      filteredGroups: [],
      selectedFilteredTaskGroup: null,
      loadingGroups: false,
      IsWithAllocation: false,
      newAllocation: null,
      isStartDateEditable: false,
      isTaskTypeEditable: false,
      isFormDirty: false,
      nptReplaceResponseDialogVisible: false,
      allcationGridLoader: false,
      editNptModelLoder: false,
      disableNoEndDate: false,
      headerSelected: false,
      selectedItems: null,
      checkedList: [],
      uncheckedList: [],
      uncheckedItems: null,
      selectedPage: 1,
      selectedAssignedApprover: [],
      newAssignedApprovers: [],
      isDisableBulkApproverAssign: true,
      bulkApproverAssignment: false,
      resApproversVisible: false,
      approverRemovalUsers: [],
      selectedApproverRemoval: [],
      isDisableBulkApproverRemoval: true,
      bulkApproverRemoval: false,
      resApproverRemovalVisible: false,
      department: [],
      departmentAll: [],
      allocatedDepartment: [],
      newSelectedDepartment: [],
      allDepartments: [],
      allocatedRegions: [],
      newSelectedAllocatedRegion: [],
      resourceAllocatedRegion: [],
      practiceDivisionMap: [],
      selectedpracticeData: [],
      isSelectedDepartment: false,
      isSelectedPractice: false,
      isSelectedResourceRegion: false,
      isSelectedAllocatedRegion: false,
      showDepartmentValidateDialog: false,
      invalidDepartments: [],
      allocatedRegionsAll: [],
      newSelectedDepartmentSearch: [],
      newSelectedPracticeSearch:[],
      departmentsData: [],
      practicesData: []
    };

    this.toggleBulkRescAssignmentDialog = this.toggleBulkRescAssignmentDialog.bind(this);
  }

  toggleBulkApproverReplacementDialog = () => {
    if (this.state.inactiveFlag) {
      this.toggleInactiveTaskErrorDialog();
    } else {
      if(!this.state.isFormDirty){
      this.setState({
        bulkApproverReplacement: !this.state.bulkApproverReplacement,
        showErrorImage: false
      });
      this.clearBulkApproverFields();
      }else{
      this.setState({
        cancelNonProjectTaskPopupVisible:true
      })
    }
    }
  };

  clearBulkApproverFields =()=>{
    this.setState({
      selectedExistingApprover: null,
      newRepSelectedApprover: null,
      existingApprovers: this.state.usersAll,
      newReplacingApprovers: this.state.usersAll
    });
  }

  toggleBulkReourceRemovalDialog = async () => {
    if (this.state.inactiveFlag) {
      this.toggleInactiveTaskErrorDialog();
    } else {
      this.setState({loading: true}, async () => {
        try {
          const selectedNPT = await this.getSelectedUsers();
          this.setState({loading: false});
          let npTasksWithoutApproval = selectedNPT.filter(
            resource => resource.selected === true && resource.IsApproverNeeded === true
          );
          let npTasksWithApproval = selectedNPT.filter(
            resource => resource.selected === true && resource.IsApproverNeeded === false
          );
          if (
            npTasksWithApproval &&
            npTasksWithApproval.length > 0 &&
            npTasksWithoutApproval &&
            npTasksWithoutApproval.length > 0
          ) {
            this.setState({
              showErrorImage: true
            });
            this.toggleDialog('Select tasks related to one approval type', 'Error');
          } else {
            let isBulkApprovalNotNeeded = false;
            if (npTasksWithoutApproval && npTasksWithoutApproval.length > 0) {
              isBulkApprovalNotNeeded = true;
            }
            if(!this.state.isFormDirty){
            this.setState({
              bulkResourceRemoval: !this.state.bulkResourceRemoval,
              isBulkApprovalNotNeeded: isBulkApprovalNotNeeded,
              showErrorImage: false
            });
            }else{
              this.setState({
                cancelNonProjectTaskPopupVisible:true
              });
            };
          }
        } catch (error) {
          this.setState({loading: false});
        }
      })
    }

    if (this.state.bulkResourceRemoval === false) {
      this.setState({
        existingApprovers: this.state.usersAll,
        selectedRemovalApprovers: null,
        resourceRemovalDate: null,
        isDisableRemovalSave: true
      });
    }
  };

  toggleBulkRescAssignmentDialog() {
    if (this.state.inactiveFlag) {
      this.toggleInactiveTaskErrorDialog();
    } else {
      if(!this.state.isFormDirty){
        this.setState({
          selectedAssignedResource: [],
          isDisableResourceSave: true,
          bulkresourceassignment: !this.state.bulkresourceassignment,
          showErrorImage: false
        });
      }else{
        this.setState({
          cancelNonProjectTaskPopupVisible:true
        });
      }
    }
  }

  toggleBulkApproverAssignmentDialog = () => {
    if (this.state.inactiveFlag) {
      this.toggleInactiveTaskErrorDialog();
    } else {
      if(!this.state.isFormDirty){
        this.setState({
          selectedAssignedApprover: [],
          isDisableResourceSave: true,
          bulkApproverAssignment: !this.state.bulkApproverAssignment,
          showErrorImage: false
        });
      }else{
        this.setState({
          cancelNonProjectTaskPopupVisible:true
        });
      }
    }
  }

  toggleBulkApproverRemovalDialog = () => {
    if (this.state.inactiveFlag) {
      this.toggleInactiveTaskErrorDialog();
    } else {
      if(!this.state.isFormDirty){
        this.setState({
          selectedApproverRemoval: [],
          isDisableResourceSave: true,
          bulkApproverRemoval: !this.state.bulkApproverRemoval,
          showErrorImage: false
        });
      }else{
        this.setState({
          cancelNonProjectTaskPopupVisible:true
        });
      }
    }
  }

  toggleInactiveTaskErrorDialog() {
    let message = 'Bulk Function cannot be done on Inactive Non-Project tasks.';
    let title = 'Error';
    this.setState({
      showErrorImage: true,
      showSuccessImage: false
    });
    this.toggleDialog(message, title);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    this.setState({
      loading: true
    }, async() => {
      this.setHeaderTitle();
      //this.setTotalGridSize();
      this.getAllNonProjectTasks();
      this.getAllUsers();
      this.getAllTaskTypes();
      this.getAllTaskGroups();
      this.getAllRegions();
      await this.getAllPracticeDivisionMappings();
      this.getAllPractice();
      this.getAllNonProjectTaskUsers();
      this.getAllocatedBillingRegion();
      this.getResourceAllocatedRegion();
      this.getAllocatedPractice();
      this.getNonProjectTaskApprovers();
      this.getAllActiveUsers();
      this.getAllDepartment();
      this.getAllocatedDepartment();
      this.getActiveAllocatedRegions();
    })
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Non-Project Task');
    }
  };

  getAllActiveUsers = () => {
    getallactiveusers().then(resp => {
      if (this.isMount) {
        this.setState({
          assignedResourcesAll: resp.data,
          assignedResources: resp.data
        });
      }
    });
  };
  getAllNonProjectTasks = () => {
    let obj = {
      task: this.state.selectedTask && this.state.selectedTask.name && this.state.selectedTask.name.length > 0 ? this.state.selectedTask.name.map(value => value.Name) : [],
      taskTypeId: this.state.selectedTaskType.map(value => value.id),
      assignedUserId: this.state.selectedAssignUser.map(value => value.id),
      approverId: this.state.selectedApproveUser.map(value => value.id),
      status: this.state.selectedStatus ? this.state.selectedStatus.status : null,
      approvalType: this.state.selectedApprovalType
        ? this.state.selectedApprovalType.status
        : null,
      taskGroupId: this.state.selectedTaskGroup.map(value => value.id),
      department: this.state.newSelectedDepartmentSearch.map(value => value.id),
      practice: this.state.newSelectedPracticeSearch.map(value => value.id),
    };
    getAllNonProjectTasks(obj, this.state.skip, this.state.take, this.state.sort)
      .then(res => {
        if (this.isMount) {
          this.state.taskNames = [];
          let taskNames = [];

          res.data.data.forEach(data => {
            const index = taskNames.findIndex(obj => obj.name === data.Name);
            if (index === -1) {
              const valueToAdd = {
                name: data.Name
              };
              taskNames.push(valueToAdd);
            }
          });

          this.setState({
              allNonProjectTasks: res.data.data.map(dataItem => {
                return {...dataItem, selected: false}
              }
            ),
            selectedItems: {[this.state.selectedPage]: []},
            total: res.data.total,
            taskNames: taskNames,
            taskNamesAll: taskNames,
            loading: false
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          loading: false
        })
      });
  };

  setTotalGridSize =()=> {
    getAllNonProjectTasks(undefined, 0, 0, this.state.sort)
    .then(res => {
      if(this.isMount && res && res.data){
        this.setState({total: res.data.length})
      }
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  }

  getAllUsers = () => {
    getUserListIntegrated()
      .then(res => {
        if (this.isMount) {
          let allActiveUsers = res.data.filter(obj => obj.IsActive === true);
          this.setState({
            usersAll: res.data,
            activeUsersAll: allActiveUsers,
            approvedUsers: res.data,
            assignedUsers: res.data,
            existingApprovers: res.data,
            newReplacingApprovers: res.data,
            newApprovers: allActiveUsers,
            individuals: allActiveUsers,
            newAssignedApprovers: allActiveUsers,
            approverRemovalUsers: res.data
          });
          this.getActiveReplacingApprovers();
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getActiveReplacingApprovers = () => {
    this.activeRepApprovers = this.state.newReplacingApprovers.filter(function(approver) {
      return approver.IsActive == true;
    });
    this.setState({
      newReplacingApprovers: this.activeRepApprovers
    });
  };

  getAllNonProjectTaskUsers = () => {
    getAllNonProjectTaskUsers()
      .then(res => {
        if (this.isMount) {
          this.setState({
            individualUsers: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getNonProjectTaskApprovers = () => {
    getNonProjectTaskApprovers()
      .then(res => {
        if (this.isMount) {
          this.setState({
            approvers: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getAllocatedBillingRegion = () => {
    getAllocatedBillingRegion()
      .then(res => {
        if (this.isMount) {
          this.setState({
            allocatedBillingRegion: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getResourceAllocatedRegion = () => {
    getResourceAllocatedRegion()
      .then(res => {
        if (this.isMount) {
          this.setState({
            resourceAllocatedRegion: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getAllocatedPractice = () => {
    getAllocatedPractice()
      .then(res => {
        if (this.isMount) {
          this.setState({
            allocatedPractice: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getAllocatedDepartment = () => {
    getAllocatedDepartment()
      .then(res => {
        if (this.isMount) {
          this.setState({
            allocatedDepartment: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getAllRegions = () => {
    getResourceRegionsIntegrated()
      .then(res => {
        this.setState({
          regionsAll: res.data.filter(value => value.IsActive == true),
          regions: res.data.filter(value => value.IsActive == true)
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getActiveAllocatedRegions = () => {
    getActiveAllocatedRegionsIntegrated()
      .then(res => {
        this.setState({
          allocatedRegions: res.data.filter(value => value.IsActive == true),
          allocatedRegionsAll: res.data.filter(value => value.IsActive == true)
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getAllPractice = async () => {
    await getPracticeTypesIntegrated() 
      .then(async res => {
        await this.setState({
          practiceAll: res.data,
          practice: res.data,
          practicesData: res.data
        });
        await this.practiceDivisionMapping();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getAllDepartment = () => {
    getBillingDiviIntegrated()
      .then(res => {
        this.setState({
          departmentAll: res.data.filter(obj => obj.IsActive == true && obj.SFCode !== null),
          department: res.data.filter(obj => obj.IsActive == true && obj.SFCode !== null),
          allDepartments: res.data,
          departmentsData: res.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getAllPracticeDivisionMappings = () => {
    getAllPracticeDivisionMapIntegrated()
      .then(response => {
        this.setState({
            practiceDivisionMap: response.data
        });
    })
    .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  getAllTaskTypes = () => {
    getTaskTypesIntegrated()
      .then(res => {
        if (this.isMount) {
          let initialNonBillableTypes = res.data.filter(
            obj => obj.BillabilityTypeId !== 'BILLABLE'
          );
          let activeNonBillableTypes = res.data.filter(
            obj => obj.BillabilityTypeId !== 'BILLABLE' && obj.IsActive === true
          );
          this.setState({
            taskTypes: res.data,
            taskTypesAll: initialNonBillableTypes,
            activeTaskTypesAll: activeNonBillableTypes,
            newTaskTypes: activeNonBillableTypes
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getAllTaskGroups = () => {
    getAllNonProjectTaskGroups()
      .then(res => {
        if (this.isMount) {
          this.setState({
            nonProjectTasks: res.data,
            nonProjectTasksAll: res.data,
            taskGroups: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  handleSelect = e => {
    this.setState({ selected: e.selected });
  };

  onSelect = async(item) => {
    let selections = this.state.currentlySelectedRows;
    selections.push(item)
    await this.setState({currentlySelectedRows: selections});
  }

  onRemove = async(item) => {
    let selections = this.state.currentlySelectedRows;
    const rowIndex = selections.findIndex(i => i.id == item.id);
    selections.splice(rowIndex, 1);
    await this.setState({currentlySelectedRows: selections});
  }

  dividedList = () => {
    const idList = this.state.allNonProjectTasks.map(e => e.id);
    const selectedList = this.state.allNonProjectTasks.filter(e => e.selected).map(e => e.id);
    const unselectedList = this.state.allNonProjectTasks.filter(e => !e.selected).map(e => e.id);
    return {
      checkedList: Array.from(new Set([..._.difference(idList, unselectedList)])) ,
      uncheckedList: Array.from(new Set([..._.difference(this.state.uncheckedList, selectedList), ...unselectedList])) 
    }
  }

  selectionChange = async(event) => {
    const selected = event.syntheticEvent.target.checked;
    const index = this.state.allNonProjectTasks.findIndex(e => e.id === event.dataItem.id);
    this.setState({
      allNonProjectTasks: this.state.allNonProjectTasks.map((e, i) => {
        if(i === index){
          return {
            ...e,
            selected
          }
        }
        return e;
      })
    }, () => {
      const {checkedList, uncheckedList} = this.dividedList();
      this.setState({
        checkedList,
        uncheckedList,
        inactiveFlag: false,

      });
      const selectedTask = this.state.allNonProjectTasks.filter(e => e.selected);
      if(selectedTask.filter(e => !e.IsActive).length > 0){
        this.setState({ inactiveFlag: true });
      }

      if (selectedTask.length > 0) {
        if (this.isMount) {
          this.setState({
            isDisableBulkResource: false,
            isDisableBulkRemoval: false,
            isDisableBulkReplace: false,
            isDisableBulkApproverAssign: false,
            isDisableBulkApproverRemoval: false
          });
        }
      }

      if (selectedTask.length === 0) {
        if (this.isMount) {
          this.setState({
            isDisableBulkResource: true,
            isDisableBulkRemoval: true,
            isDisableBulkReplace: true,
            isDisableBulkApproverAssign: true,
            isDisableBulkApproverRemoval: true
          });
        }
      }
    })
  };

  bulkActionHandle = () => {
    this.setState({ inactiveFlag: false }, () => {
      const selectedTask = this.state.allNonProjectTasks.filter(e => e.selected);
      if(selectedTask.filter(e => !e.IsActive).length > 0){
        this.setState({ inactiveFlag: true });
      }

      if (this.state.checkedList.length > 0) {
        if (this.isMount) {
          this.setState({
            isDisableBulkResource: false,
            isDisableBulkRemoval: false,
            isDisableBulkReplace: false,
            isDisableBulkApproverAssign: false,
            isDisableBulkApproverRemoval:false
          });
        }
      }
      if (this.state.checkedList.length === 0) {
        if (this.isMount) {
          this.setState({
            isDisableBulkResource: true,
            isDisableBulkRemoval: true,
            isDisableBulkReplace: true,
            isDisableBulkApproverAssign: true,
            isDisableBulkApproverRemoval: true
          });
        }
      }
    });
  }
  
  headerSelectionChange = async(event) => {
    const headerSelected = event.syntheticEvent.target.checked;
    this.setState({headerSelected}, () => {

      if(this.state.headerSelected){
        return this.setState({
          allNonProjectTasks: this.state.allNonProjectTasks.map(e => {
            return {
              ...e,
              selected: true
            }
          }),
          checkedList: Array.from(new Set([...this.state.checkedList, ...this.state.allNonProjectTasks.map(e => e.id)])),
          uncheckedList: []
        }, () => {
          this.bulkActionHandle();
        })
      }
      this.setState({
        allNonProjectTasks: this.state.allNonProjectTasks.map(e => {
          return {
            ...e,
            selected: false
          }
        }),
        checkedList: [],
        uncheckedList: Array.from(new Set([...this.state.checkedList, ...this.state.allNonProjectTasks.map(e => e.id)]))
      }, () => {
        this.bulkActionHandle()
      })
    });
  };

  toggleApproverReplaceResponseDialog = () => {
    if (this.state.nptReplaceResponseDialogVisible) {
      this.setState({
        selectedExistingApprover: null,
        selectedRemovalApprovers: [],
        newRepSelectedApprover: null,
        actionText: ''
      });
    }else{
      this.setState({
        selectedTask: [],
        selectedTaskType: [],
        selectedAssignUser: [],
        selectedApproveUser: [],
        selectedStatus: null,
        selectedApprovalType: null,
        selectedTaskGroup: [],
      })
    }
    if (this.state.selectedExistingApprover == null) {
      this.state.actionText = 'updated';
    } else {
      this.state.actionText = 'replaced';
    }
    this.setState({
      nptReplaceResponseDialogVisible: !this.state.nptReplaceResponseDialogVisible
    });
  };

  toggleResourceRemovalResponseDialog = () => {
    if (this.state.nptResourceRemovalRespDialogVisible) {
      this.setState({
        selectedExistingApprover: null,
        selectedRemovalApprovers: [],
        newRepSelectedApprover: null,
        actionText: ''
      });
    }

    this.setState({
      nptResourceRemovalRespDialogVisible: !this.state.nptResourceRemovalRespDialogVisible
    });
  };

  handleOnChangeInput = async event => {
    const field = event.target.name;
    this.setState({
      isFormDirty: true
    });
    switch (field) {
      case 'taskName': {
        if (this.isMount) {
          this.setState({
            selectedTask: {name: event.target.value ? event.target.value.map(value => value) : []}
          });
        }
        break;
      }
      case 'searchStatus': {
        if (this.isMount) {
          this.setState({
            selectedStatus: event.target.value
          });
        }
        break;
      }
      case 'approvalType': {
        if (this.isMount) {
          this.setState({
            selectedApprovalType: event.target.value
          });
        }
        break;
      }
      case 'assignedUsers': {
        if (this.isMount) {
          this.setState({
            selectedAssignUser: event.target.value
          });
        }
        break;
      }
      case 'approvedUsers': {
        if (this.isMount) {
          this.setState({
            selectedApproveUser: event.target.value
          });
        }
        break;
      }
      case 'taskTypes': {
        if (this.isMount) {
          this.setState({
            selectedTaskType: event.target.value
          });
        }
        break;
      }
      case 'nonProjectTasks': {
        if (this.isMount) {
          this.setState({
            newSelectedTaskGroup: event.target.value
          });
        }
        break;
      }
      case 'newTaskType': {
        if (this.isMount) {
          this.setState({
            newSelectedTaskType: event.target.value
          });
        }
        break;
      }
      case 'newApprovers': {
        if (this.isMount) {
          this.setState({
            newSelectedApprover: event.target.value
          });
        }
        break;
      }
      case 'removalApprovers': {
        let targetValue = event.target.value;
        if (this.isMount) {
          this.setState({
            selectedRemovalApprovers: event.target.value,
            isDisableRemovalSave: true
          });

          if (this.state.isBulkApprovalNotNeeded) {
            if (this.state.resourceRemovalDate && targetValue.length > 0) {
              this.setState({
                isDisableRemovalSave: false
              });
            }
          } else {
            if (targetValue.length > 0) {
              this.setState({
                isDisableRemovalSave: false
              });
            }
          }
        }
      }
      case 'newRegion': {
        if (this.isMount) {
          await this.setState({
            newSelectedRegion: event.target.value
          });
          await this.regionsDropDownValidation()
        }
        break;
      }
      case 'newAllocatedRegion': {
        if (this.isMount) {
          await this.setState({
            newSelectedAllocatedRegion: event.target.value
          });
          await this.regionsDropDownValidation()
        }
        break;
      }
      case 'newPractice': {
        if (this.isMount) {
          this.setState({
            newSelectedPractice: event.target.value
          });
        }
        break;
      }
      case 'newDepartment': {
        if (this.isMount) {
          await this.setState({
            newSelectedDepartment: event.target.value
          });
          await this.practiceDivisionMapping();
        }
        break;
      }
      case 'individualUser': {
        if (this.isMount) {
          this.setState({
            newSelectedIndividuals: event.target.value
          });
        }
        break;
      }
      case 'newTaskGroup': {
        if (this.isMount) {
          this.setState({
            newTaskGroup: event.target.value
          });
        }
        break;
      }
      case 'existingApprover': {
        if (this.isMount) {
          this.setState({
            selectedExistingApprover: event.target.value
          });
        }
        break;
      }
      case 'newReplacingApprover': {
        if (this.isMount) {
          this.setState({
            newRepSelectedApprover: event.target.value
          });
          if (this.state.newRepSelectedApprover) {
            this.setState({
              isDisableReplaceSave: true
            });
          } else {
            this.setState({
              isDisableReplaceSave: false
            });
          }
        }
        break;
      }
      case 'newTaskName': {
        if (this.isMount) {
          this.setState({
            newTaskName: event.target.value
          });
        }
        break;
      }
      case 'taskGroup': {
        if (this.isMount) {
          this.setState({
            selectedTaskGroup: event.target.value
          });
        }
        break;
      }
      case 'assignedResources': {
        let targetValue = event.target.value;
        if (this.isMount) {
          this.setState({
            selectedAssignedResource: event.target.value
          });
          if (targetValue.length > 0) {
            this.setState({
              isDisableResourceSave: false
            });
          } else {
            this.setState({
              isDisableResourceSave: true
            });
          }
        }
        break;
      }
      case 'assignedApprovers': {
        let targetValue = event.target.value;
        if (this.isMount) {
          this.setState({
            selectedAssignedApprover: event.target.value
          });
          if (targetValue.length > 0) {
            this.setState({
              isDisableResourceSave: false
            });
          } else {
            this.setState({
              isDisableResourceSave: true
            });
          }
        }
        break;
      }
      case 'approverRemoval': {
        let targetValue = event.target.value;
        if (this.isMount) {
          this.setState({
            selectedApproverRemoval: event.target.value
          });
          if (targetValue.length > 0) {
            this.setState({
              isDisableResourceSave: false
            });
          } else {
            this.setState({
              isDisableResourceSave: true
            });
          }
        }
        break;
      }
      case 'newPracticeSearch': {
        if (this.isMount) {
          this.setState({
            newSelectedPracticeSearch: event.target.value
          });
        }
        break;
      }
      case 'newDepartmentSearch': {
        if (this.isMount) {
          await this.setState({
            newSelectedDepartmentSearch: event.target.value
          });
          await this.practiceDivisionMappingSearch();
        }
        break;
      }

      default: {
        break;
      }
    }
  };

  handleOnChangeCheckBox = event => {
    const field = event.target.name;

    if (this.isMount) {
      switch (field) {
        case 'isNewTaskGroup':
          if (event.target.checked) {
            this.state.newSelectedTaskGroup = null;
          } else {
            this.state.newTaskGroup = null;
          }
          break;
        case 'noEndDate':
          if (event.target.checked) {
            this.setState({
              newEndDate: null,
              isApprovalNotNeeded: false,
              selectedFrequency: null,
              selectedDay: null,
              selectedWeek: null,
              newTimeDuration: 0.0
            });
          }
          break;
        case 'isApprovalNotNeeded':
          if (!event.target.checked) {
            this.setState({
              selectedFrequency: null,
              selectedDay: null,
              selectedWeek: null,
              newTimeDuration: 0.0
            });
          } else {
            this.state.selectedFrequency = 'DAILY';
          }
          break;
        case 'IsWithAllocation':
          if (!event.target.checked) {
            this.setState({
              newAllocation: ""
            });
          }
          break;
        default:
          break;
      }
      this.setState({ [field]: event.target.checked });
    }
  };

  handleRemovalDateChange = event => {
    let removalDate = event.target.value;

    if (this.isMount) {
      this.setState({
        resourceRemovalDate: removalDate
      });
      if (this.state.isBulkApprovalNotNeeded) {
        if (removalDate && this.state.selectedRemovalApprovers.length > 0) {
          this.setState({
            isDisableRemovalSave: false
          });
        } else {
          this.setState({
            isDisableRemovalSave: true
          });
        }
      }
    }
  };

  handleStartDateChange = event => {
    let startDate = event.target.value;

    if (this.isMount) {
      this.setState({
        newStartDate: startDate,
        isFormDirty: true
      });
    }
  };

  handleEndDateChange = event => {
    let endDate = event.target.value;

    if (this.isMount) {
      this.setState({
        newEndDate: endDate,
        isFormDirty: true
      });
    }
  };

  handleTimeDuration = event => {
    let value = event.target.value;
    let name = event.target.name;

    if (name === 'newTimeDuration') {
      if (value || value == '') {
        if (value == '') {
          value = 0;
        }
        if (value <= 0) {
          this.setState({ newTimeDuration: 0.0 });
        } else {
          const splitted = value.split(':');
          if(Number(splitted[0]) >= 0 && (Number(splitted[0]) < 24 || (Number(splitted[0]) === 24 && (!splitted[1] || Number(splitted[1]) === 0)))){
            this.setState({ newTimeDuration: Number(value).toFixed(2) });
          }else{
            this.setState({ newTimeDuration: 0.0 });
          }
        }
      }
    } else if (name === 'newAllocation') {
      if (value) {
        if (value < 0) {
          this.setState({ newAllocation: Number(0).toFixed(2) });
        } else {
          this.setState({ newAllocation: Number(value).toFixed(2) });
        }
      }
    }
  };

  handleFrequency = event => {
    let field = event.target.id;
    this.setState({
      selectedFrequency: field
    });
    if (this.isMount) {
      switch (field) {
        case 'DAILY': {
          this.setState({
            selectedDay: null,
            selectedWeek: null
          });
          break;
        }
        case 'WEEKLY': {
          this.setState({
            selectedDay: null,
            selectedWeek: null
          });
          break;
        }
        case 'MONTHLY': {
          break;
        }
        default:
          break;
      }
    }
  };

  handleAssignedTo = event => {
    let field = event.target.id;
    this.setState({
      selectedAssignedTo: field
    });
    if (this.isMount) {
      switch (field) {
        case 'ALL': {
          this.setState({
            newSelectedPractice: [],
            newSelectedDepartment: [],
            newSelectedRegion: [],
            newSelectedIndividuals: [],
            newSelectedAllocatedRegion: [],
            isSelectedDepartment: false,
            isSelectedPractice: false,
            isSelectedResourceRegion: false,
            isSelectedAllocatedRegion: false
          });
          break;
        }
        case 'DEPLOYABLE': {
          this.setState({
            newSelectedPractice: [],
            newSelectedDepartment: [],
            newSelectedRegion: [],
            newSelectedIndividuals: [],
            newSelectedAllocatedRegion: [],
            isSelectedDepartment: false,
            isSelectedPractice: false,
            isSelectedResourceRegion: false,
            isSelectedAllocatedRegion: false
          });
          break;
        }
        case 'PRACTICE': {
          this.setState({
            newSelectedRegion: [],
            newSelectedIndividuals: [],
            newSelectedDepartment: [],
            newSelectedAllocatedRegion: []
          });
          break;
        }
        case 'DEPARTMENT': {
          this.setState({
            newSelectedPractice: [],
            newSelectedRegion: [],
            newSelectedIndividuals: [],
            newSelectedAllocatedRegion: []
          });
          break;
        }
        case 'RES_REGION': {
          this.setState({
            newSelectedPractice: [],
            newSelectedDepartment: [],
            newSelectedIndividuals: [],
            newSelectedAllocatedRegion: []
          });
          break;
        }
        case 'ALLOC_REGION': {
          this.setState({
            newSelectedPractice: [],
            newSelectedDepartment: [],
            newSelectedIndividuals: [],
            newSelectedRegion: []
          });
          break;
        }
        case 'INDIVIDUAL': {
          this.setState({
            newSelectedPractice: [],
            newSelectedDepartment: [],
            newSelectedRegion: [],
            newSelectedAllocatedRegion: [],
            isSelectedDepartment: false,
            isSelectedPractice: false,
            isSelectedResourceRegion: false,
            isSelectedAllocatedRegion: false
          });
          break;
        }
        default:
          break;
      }
    }
  };

  handleAssignedToDepartment = async event => {
    let field = event.target.id;
    await this.setState({
      isSelectedDepartment: !this.state.isSelectedDepartment
    });
    if (this.state.isSelectedDepartment) {
      if (this.state.isSelectedPractice || this.state.isSelectedResourceRegion || this.state.isSelectedAllocatedRegion) {
        await this.setState({
          selectedAssignedTo: this.state.assignedTo[7].code
        });
      } else {
        await this.setState({
          selectedAssignedTo: field,
          newSelectedPractice: [],
          newSelectedRegion: [],
          newSelectedIndividuals: [],
          newSelectedAllocatedRegion: []
        });
      }
    } else {
      await this.setState({
        newSelectedDepartment: [],
        selectedpracticeData: this.state.practiceAll
      });
    }

    if (this.state.isSelectedPractice) {
      await this.setState({
        isSelectedPractice: false,
        newSelectedPractice: []
      });
    }
  }

  handleAssignedToPractice = async event => {
    let field = event.target.id;
    await this.setState({
      isSelectedPractice: !this.state.isSelectedPractice
    });
    if (this.state.isSelectedPractice) {
      if (this.state.isSelectedDepartment || this.state.isSelectedResourceRegion || this.state.isSelectedAllocatedRegion) {
        await this.setState({
          selectedAssignedTo: this.state.assignedTo[7].code
        });
      } else {
        await this.setState({
          selectedAssignedTo: field,
          newSelectedDepartment: [],
          newSelectedRegion: [],
          newSelectedIndividuals: [],
          newSelectedAllocatedRegion: []
        });
      }
    } else {
      await this.setState({
        newSelectedPractice: [],
      });
    }
  }

  handleAssignedToResourceRegion = async event => {
    let field = event.target.id;
    await this.setState({
      isSelectedResourceRegion: !this.state.isSelectedResourceRegion
    });
    if (this.state.isSelectedResourceRegion) {
      if (this.state.isSelectedPractice || this.state.isSelectedDepartment || this.state.isSelectedAllocatedRegion) {
        await this.setState({
          selectedAssignedTo: this.state.assignedTo[7].code
        });
      } else {
        await this.setState({
          selectedAssignedTo: field,
          newSelectedPractice: [],
          newSelectedDepartment: [],
          newSelectedIndividuals: [],
          newSelectedAllocatedRegion: []
        });
      }
    } else {
      await this.setState({
        newSelectedRegion: [],
      });
    }
    await this.regionsDropDownValidation()
  }

  handleAssignedToAllocatedRegion = async event => {
    let field = event.target.id;
    await this.setState({
      isSelectedAllocatedRegion: !this.state.isSelectedAllocatedRegion
    });
    if (this.state.isSelectedAllocatedRegion) {
      if (this.state.isSelectedPractice || this.state.isSelectedResourceRegion || this.state.isSelectedResourceRegion) {
        await this.setState({
          selectedAssignedTo: this.state.assignedTo[7].code
        });
      } else {
        await this.setState({
          selectedAssignedTo: field,
          newSelectedPractice: [],
          newSelectedRegion: [],
          newSelectedIndividuals: [],
          newSelectedDepartment: []
        });
      }
    } else {
      await this.setState({
        newSelectedAllocatedRegion: [],
      });
    }
    await this.regionsDropDownValidation()
  }

  practiceDivisionMapping = async () => {
    if (this.state.newSelectedDepartment && this.state.newSelectedDepartment.length > 0) {
        let practices = [];
        for (const department of this.state.newSelectedDepartment) {
          let practicesForDivision = this.state.practiceDivisionMap.filter(item => item.DepartmentCodeSF === department.SFCode);
          for (const practice of practicesForDivision) {
            let selectedPractice = this.state.practiceAll.filter(item => item.SFCode === practice.PracticeCodeSF);
            practices.push(selectedPractice[0]);
          }
        }
        const uniquePractices = [...new Set(practices)];
        await this.setState({ selectedpracticeData: uniquePractices })
    } else {
      await this.setState({ selectedpracticeData: this.state.practiceAll })
    }
  };

  practiceDivisionMappingSearch = async () => {
    if (this.state.newSelectedDepartmentSearch && this.state.newSelectedDepartmentSearch.length > 0) {
        let practices = [];
        for (const department of this.state.newSelectedDepartmentSearch) {
          let practicesForDivision = this.state.practiceDivisionMap.filter(item => item.DepartmentCodeSF === department.SFCode);
          for (const practice of practicesForDivision) {
            let selectedPractice = this.state.practiceAll.filter(item => item.SFCode === practice.PracticeCodeSF);
            practices.push(selectedPractice[0]);
          }
        }
        const uniquePractices = [...new Set(practices)];
        await this.setState({ practicesData: uniquePractices })
    } else {
      await this.setState({ practicesData: this.state.practiceAll })
    }
  };

  regionsDropDownValidation = async () => {
    if (this.state.newSelectedAllocatedRegion.length > 1 && this.state.newSelectedRegion.length == 1) {
      await this.setState({
        regions: [],
      });
    } else if (this.state.newSelectedRegion.length > 1 && this.state.newSelectedAllocatedRegion.length == 1) {
      await this.setState({
        allocatedRegions: [],
      });
    } else {
      await this.setState({
        regions: this.state.regionsAll,
        allocatedRegions: this.state.allocatedRegionsAll
      });
    }
  };

  rowClick = event => {
    // let last = this.lastSelectedIndex;
    // const data = [...this.state.data];
    // const current = data.findIndex(dataItem => dataItem === event.dataItem);
    //
    // if (!event.nativeEvent.shiftKey) {
    //   this.lastSelectedIndex = last = current;
    // }
    //
    // if (!event.nativeEvent.ctrlKey) {
    //   data.forEach(item => (item.selected = false));
    // }
    // const select = !event.dataItem.selected;
    // for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
    //   data[i].selected = select;
    // }
    // this.setState({ data });
  };

  pageChange = async(event) => {
    if (this.isMount) {
      const selectedPage = Number((event.page.skip/event.page.take) + 1);
      this.setState({
        skip: event.page.skip
      }, () => {
        this.pageChangeSearch();
      });
    }
  };

  pageChangeSearch = async (event) => {
    event && event.preventDefault();
    if (this.isMount) {
      this.setState({loading:true});
      let obj = {
        task: this.state.selectedTask && this.state.selectedTask.name && this.state.selectedTask.name.length > 0 ? this.state.selectedTask.name.map(value => value.Name) : [],
        taskTypeId: this.state.selectedTaskType.map(value => value.id),
        assignedUserId: this.state.selectedAssignUser.map(value => value.id),
        approverId: this.state.selectedApproveUser.map(value => value.id),
        status: this.state.selectedStatus ? this.state.selectedStatus.status : null,
        approvalType: this.state.selectedApprovalType
          ? this.state.selectedApprovalType.status
          : null,
        taskGroupId: this.state.selectedTaskGroup.map(value => value.id),
        department: this.state.newSelectedDepartmentSearch.map(value => value.id),
        practice: this.state.newSelectedPracticeSearch.map(value => value.id),
      };
      getAllNonProjectTasks(obj, this.state.skip, this.state.take, this.state.sort)
        .then(res => {
          this.setState({
            allNonProjectTasks: this.state.headerSelected && this.state.uncheckedList.length === 0 ? res.data.data.map(e => {
              return {
                ...e,
                selected: true
              }
            }) : this.state.headerSelected && this.state.uncheckedList.length > 0 ? res.data.data.map(e => {
              const {id} = e;
              if(this.state.uncheckedList.includes(id)){
                return {
                  ...e,
                  selected: false
                }
              }
              return {...e, selected: true};
            }) : res.data.data.map(e => {
              const {id} = e;
              if(this.state.checkedList.includes(id)){
                return {
                  ...e,
                  selected: true
                }
              }
              return {...e, selected: false};
            }),
            total: res.data.total,
            isFormDirty: false,
            loading: false
          });
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }
  }

  onClickConfirm = async () => {
    if (this.state.selectedExistingApprover !== this.state.newRepSelectedApprover) {
      this.toggleConfirmDialog(
        'Are you sure you want to proceed?',
        this.handleSaveReplacementForTasks
      );
    } else {
      this.toggleDialogSameUserValidation(
        'Existing and new approver cannot be the same user',
        'Warning'
      );
    }
  };

  onClickResourceRemovalConfirm = async () => {
    if (this.state.isBulkApprovalNotNeeded) {
      if (this.state.resourceRemovalDate && this.state.selectedRemovalApprovers.length > 0) {
        this.handleRemoveResourcesForTasks();
      } else {
        this.setState({
          bulkResourceRemoval: false,
          showErrorImage: true
        });
        this.toggleDialog('Please select the Resource(s) to be removed and the Date.', 'Warning');
        this.setState({
          bulkResourceRemoval: true
        });
      }
    } else {
      if (this.state.selectedRemovalApprovers.length > 0) {
        this.handleRemoveResourcesForTasks();
      } else {
        this.setState({
          bulkResourceRemoval: false,
          showErrorImage: true
        });
        this.toggleDialog('Please select the Resource(s) to be removed.', 'Warning');
        this.setState({
          bulkResourceRemoval: true
        });
      }
    }
  };

  toggleDialogSameUserValidation = (message, title) => {
    this.setState({
      bulkApproverReplacement: !this.state.bulkApproverReplacement,
      sameUserValidationVisible: !this.state.sameUserValidationVisible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleConfirmDialog = async (message, action) => {
    await this.setState({
      bulkApproverReplacement: !this.state.bulkApproverReplacement,
      showConfirmDialog: !this.state.showConfirmDialog,
      confirmDialogMessage: message,
      confirmDialogAction: action
    });
  };

  filterOnChange = event => {
    clearTimeout(this.timeout);
    const field = event.target.name;
    this.timeout = setTimeout(() => {
      switch (field) {
        case 'taskName': {
          if (this.isMount) {
            this.setState({
              taskNames: this.filterComboData(event.filter, this.state.taskNamesAll)
            });
          }
          break;
        }
        case 'assignedUsers': {
          if (this.isMount) {
            this.setState({
              assignedUsers: this.filterComboData(event.filter, this.state.usersAll)
            });
          }
          break;
        }
        case 'approvedUsers': {
          if (this.isMount) {
            this.setState({
              approvedUsers: this.filterComboData(event.filter, this.state.usersAll)
            });
          }
          break;
        }
        case 'taskTypes': {
          if (this.isMount) {
            this.setState({
              taskTypes: this.filterComboData(event.filter, this.state.taskTypesAll)
            });
          }
          break;
        }
        case 'nonProjectTasks': {
          if (this.isMount) {
            this.setState({
              nonProjectTasks: this.filterComboData(event.filter, this.state.nonProjectTasksAll)
            });
          }
          break;
        }
        case 'newTaskType': {
          if (this.isMount) {
            this.setState({
              newTaskTypes: this.filterComboData(event.filter, this.state.activeTaskTypesAll)
            });
          }
          break;
        }
        case 'newApprovers': {
          if (this.isMount) {
            this.setState({
              newApprovers: this.filterComboData(event.filter, this.state.activeUsersAll)
            });
          }
          break;
        }
        case 'newRegion': {
          if (this.isMount) {
            this.setState({
              regions: this.filterComboData(event.filter, this.state.regionsAll)
            });
          }
          break;
        }
        case 'newAllocatedRegion': {
          if (this.isMount) {
            this.setState({
              allocatedRegions: this.filterComboData(event.filter, this.state.allocatedRegionsAll)
            });
          }
          break;
        }
        case 'individualUser': {
          if (this.isMount) {
            this.setState({
              individuals: this.filterComboData(event.filter, this.state.activeUsersAll)
            });
          }
          break;
        }
        case 'newPractice': {
          if (this.isMount) {
            this.setState({
              selectedpracticeData: this.filterComboData(event.filter, this.state.practiceAll)
            });
          }
          break;
        }
        case 'newDepartment': {
          if (this.isMount) {
            this.setState({
              department: this.filterComboData(event.filter, this.state.departmentAll)
            });
          }
          break;
        }
        case 'assignedResources': {
          if (this.isMount) {
            this.setState({
              assignedResources: this.filterComboData(event.filter, this.state.assignedResourcesAll)
            });
          }
          break;
        }
        case 'newReplacingApprover': {
          if (this.isMount) {
            this.setState({
              newReplacingApprovers: this.filterComboData(event.filter, this.activeRepApprovers)
            });
          }
          break;
        }
        case 'removalApprovers': {
          if (this.isMount) {
            this.setState({
              existingApprovers: this.filterComboData(event.filter, this.state.usersAll)
            });
          }
          break;
        }
        case 'taskGroup': {
          if (this.isMount) {
            this.setState({
              taskGroups: this.filterComboData(event.filter, this.state.nonProjectTasksAll)
            });
          }
        }
        case 'existingApprover': {
          if (this.isMount) {
            this.setState({
              existingApprovers: this.filterComboData(event.filter, this.state.usersAll)
            });
          }
          break;
        }
        case 'assignedApprovers': {
          if (this.isMount) {
            this.setState({
              newAssignedApprovers: this.filterComboData(event.filter, this.state.activeUsersAll)
            });
          }
          break;
        }
        case 'approverRemoval': {
          if (this.isMount) {
            this.setState({
              approverRemovalUsers: this.filterComboData(event.filter, this.state.usersAll)
            });
          }
          break;
        }
        case 'newPracticeSearch': {
          if (this.isMount) {
            this.setState({
              practicesData: this.filterComboData(event.filter, this.state.practiceAll)
            });
          }
          break;
        }
        case 'newDepartmentSearch': {
          if (this.isMount) {
            this.setState({
              departmentsData: this.filterComboData(event.filter, this.state.allDepartments)
            });
          }
          break;
        }
        default: {
          break;
        }
      }
    }, 0);
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  submitSearch = event => {
    event && event.preventDefault();
    if (this.isMount) {
      this.setState({loading:true});
      let obj = {
        task: this.state.selectedTask && this.state.selectedTask.name && this.state.selectedTask.name.length > 0 ? this.state.selectedTask.name.map(value => value.Name) : [],
        taskTypeId: this.state.selectedTaskType.map(value => value.id),
        assignedUserId: this.state.selectedAssignUser.map(value => value.id),
        approverId: this.state.selectedApproveUser.map(value => value.id),
        status: this.state.selectedStatus ? this.state.selectedStatus.status : null,
        approvalType: this.state.selectedApprovalType
          ? this.state.selectedApprovalType.status
          : null,
        taskGroupId: this.state.selectedTaskGroup.map(value => value.id),
        department: this.state.newSelectedDepartmentSearch.map(value => value.id),
        practice: this.state.newSelectedPracticeSearch.map(value => value.id),
      };
      getAllNonProjectTasks(obj, this.state.skip, this.state.take, this.state.sort)
        .then(res => {
          this.setState({
            allNonProjectTasks: res.data.data.map(e => {
              return {
                ...e,
                selected: false
              }
            }),
            headerSelected: false,
            total: res.data.total,
            isFormDirty: false,
            loading: false
          });
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }
  };

  cancelSearch = async event => {
    event.preventDefault();
    if (this.isMount) {
      await this.setState({
        selectedTask: [],
        checkedList: [],
        uncheckedList: [],
        headerSelected: false,
        selectedTaskType: [],
        selectedAssignUser: [],
        selectedApproveUser: [],
        selectedStatus: null,
        selectedApprovalType: null,
        selectedTaskGroup: [],
        newSelectedDepartment: [],
        newSelectedPractice: [],
        taskNames: this.state.taskNamesAll,
        taskTypes: this.state.taskTypesAll,
        taskGroups: this.state.nonProjectTasksAll,
        approvedUsers: this.state.usersAll,
        assignedUsers: this.state.usersAll,
        departmentAll: this.state.departmentAll,
        practiceAll: this.state.practiceAll,
        newSelectedDepartmentSearch: [],
        newSelectedPracticeSearch: []
      });
    }
    this.getAllNonProjectTasks();
  };

  checkTimeEntryEnteredOrNot = taskId => {
    checkTimeEntryEnteredOrNot(taskId).then(res => {
      this.setState({
        isTaskTypeEditable: res.data
      });
    });
  };

  checkStartDateEditableOrNot = (startDate, IsApproverNeeded, taskId) => {
    if (IsApproverNeeded) {
      if (moment(startDate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
        this.setState({
          isStartDateEditable: true,
          editNptModelLoder: false
        });
      } else {
        this.setState({editNptModelLoder: false})
      } 
    } else {
      checkTimeEntryEnteredOrNot(taskId).then(res => {
        this.setState({
          isStartDateEditable: res.data,
          editNptModelLoder: false
        });
      });
    }
  };

  toggleAddDialog = async (type, taskId) => {
    if (type === 1) {
      await this.setState({
        actionPopupTitle: 'Add/Edit Non-Project Task',
        editMode: false,
        viewMode: false,
        isStartDateEditable: false,
        isTaskTypeEditable: false,
        newSelectedTaskGroup: null,
        isNewTaskGroup: false,
        newTaskName: null,
        selectedAsyncTask: null,
        filteredGroups: [],
        selectedFilteredTaskGroup: null,
        filteredTasks: [],
        newSelectedTaskType: null,
        newTaskGroup: null,
        newSelectedApprover: [],
        newStartDate: null,
        newEndDate: null,
        noEndDate: true,
        isApprovalNotNeeded: false,
        newTimeDuration: 0.0,
        newFrequency: null,
        selectedFrequency: null,
        selectedAssignedTo: null,
        newSelectedRegion: [],
        newSelectedDepartment: [],
        newSelectedPractice: [],
        newSelectedIndividuals: [],
        showAddDialog: !this.state.showAddDialog,
        IsWithAllocation: false,
        newAllocation: null,
        selectedWeek: null,
        selectedDay: null,
        isErrorMessageVisible: false,
        newSelectedAllocatedRegion: [],
        isSelectedDepartment: false,
        isSelectedPractice: false,
        isSelectedResourceRegion: false,
        isSelectedAllocatedRegion: false
      });
    } else if (type === 2 || type === 3 || type === 4 || type === 5) {
      const {data} = await getNonProjectTaskById([taskId]);
      const TaskData = data[0];
      type === 2 && 
        this.setState({
          editNptModelLoder: true
        });
      type !== 5 && (await this.checkTimeEntryEnteredOrNot(taskId));
      type !== 5 &&
        this.checkStartDateEditableOrNot(TaskData.StartDate, TaskData.IsApproverNeeded, taskId);
      let UsersArray = [];
      let RegionArray = [];
      let allocatedRegionArray = [];
      let departmentArray = [];
      let practiceArray = [];
      let approverArray = [];
      if (this.state.approvers.length !== 0) {
        const approver = this.state.approvers.filter(x => {
          if (x.NonProjectTaskId === TaskData.id && x.IsDeleted === false) {
            return x;
          }
        });
        approver.forEach(approver => {
          approverArray.push(this.state.usersAll.filter(x => x.id === approver.ApproverId)[0]);
        });
      }

      if (TaskData.AssignedTypeID === 'INDIVIDUAL') {
        const users = this.state.individualUsers.filter(x => {
          if (x.NonProjectTaskId === TaskData.id && (x.IsActive || x.Islatest)) {
            return x;
          }
        });
        users.forEach(user => {
          if (UsersArray.length === 0) {
            const filteredUser = this.state.individuals.filter(x => x.id === user.UserId);
            if(Array.isArray(filteredUser) && filteredUser.length === 1){
              UsersArray.push(filteredUser[0]);
            }
          } else {
            if (!UsersArray.some(x => x.id === user.UserId)) {
              const filteredUser = this.state.individuals.filter(x => x.id === user.UserId);
              if(Array.isArray(filteredUser) && filteredUser.length === 1){
                UsersArray.push(filteredUser[0]);
              }
            }
          }
        });
      }
      if (TaskData.AssignedTypeID === 'RES_REGION') {
        const region = this.state.allocatedBillingRegion.filter(x => {
          if (x.NonProjectTaskId === TaskData.id && x.IsActive === true) {
            return x;
          }
        });
        region.forEach(reg => {
          RegionArray.push(this.state.regions.filter(x => x.id === reg.BillingRegionId)[0]);
        });
      }
      if (TaskData.AssignedTypeID === 'ALLOC_REGION') {
        const allocRegion = this.state.resourceAllocatedRegion.filter(x => {
          if (x.NonProjectTaskId === TaskData.id && x.IsActive === true) {
            return x;
          }
        });
        allocRegion.forEach(reg => {
          allocatedRegionArray.push(this.state.allocatedRegions.filter(x => x.id === reg.AllocatedRegionId)[0]);
        });
      }
      if (TaskData.AssignedTypeID === 'PRACTICE') {
        const practice = this.state.allocatedPractice.filter(x => {
          if (x.NonProjectTaskId === TaskData.id && x.IsActive === true) {
            return x;
          }
        });
        practice.forEach(prac => {
          practiceArray.push(this.state.practice.filter(x => x.id === prac.PracticeId)[0]);
        });
      }
      if (TaskData.AssignedTypeID === 'DEPARTMENT') {
        const department = this.state.allocatedDepartment.filter(x => {
          if (x.NonProjectTaskId === TaskData.id && x.IsActive === true) {
            return x;
          }
        });
        department.forEach(prac => {
          departmentArray.push(this.state.allDepartments.filter(x => x.id === prac.DepartmentId)[0]);
        });
      }
      if (TaskData.AssignedTypeID === 'MULTIPLE') {
        const department = this.state.allocatedDepartment.filter(x => {
          if (x.NonProjectTaskId === TaskData.id && x.IsActive === true) {
            return x;
          }
        });
        department.forEach(prac => {
          departmentArray.push(this.state.allDepartments.filter(x => x.id === prac.DepartmentId)[0]);
        });

        const practice = this.state.allocatedPractice.filter(x => {
          if (x.NonProjectTaskId === TaskData.id && x.IsActive === true) {
            return x;
          }
        });
        practice.forEach(prac => {
          practiceArray.push(this.state.practice.filter(x => x.id === prac.PracticeId)[0]);
        });

        const region = this.state.allocatedBillingRegion.filter(x => {
          if (x.NonProjectTaskId === TaskData.id && x.IsActive === true) {
            return x;
          }
        });
        region.forEach(reg => {
          RegionArray.push(this.state.regions.filter(x => x.id === reg.BillingRegionId)[0]);
        });

        const allocRegion = this.state.resourceAllocatedRegion.filter(x => {
          if (x.NonProjectTaskId === TaskData.id && x.IsActive === true) {
            return x;
          }
        });
        allocRegion.forEach(reg => {
          allocatedRegionArray.push(this.state.allocatedRegions.filter(x => x.id === reg.AllocatedRegionId)[0]);
        });
      }
      await this.setState({
        actionPopupTitle: type === 5 ? 'Duplicate Non-Project Task' : type === 3 ? 'View Non-Project Task':'Add/Edit Non-Project Task',
        editMode: type === 2 ? true : false,
        viewMode: type === 3 ? true : false,
        activeMode: type === 4 ? true : false,
        editTask: taskId,
        newSelectedTaskGroup: TaskData.TaskGroup ? TaskData.TaskGroup.Name : null,
        selectedFilteredTaskGroup: TaskData.TaskGroup,
        filteredGroups: [TaskData.TaskGroup],
        isNewTaskGroup: false,
        newTaskName: type === 5 ? '' : TaskData.Name !== null ? TaskData.Name : null,
        selectedAsyncTask: type === 5 ? null : TaskData.Name ? { Name: TaskData.Name } : null,
        filteredTasks: type === 5 ? [] : TaskData.Name ? [{ Name: TaskData.Name }] : [],
        newSelectedTaskType: TaskData.TaskType !== null ? TaskData.TaskType : null,
        newTaskGroup: null,
        newSelectedApprover: approverArray,
        newIsBillable: TaskData.BillabilityTypeId === 'NONBILLABLE' ? false : true,
        newIsNonBillable: false,
        newStartDate: TaskData.StartDate !== null ? new Date(TaskData.StartDate) : null,
        newEndDate:
          type === 4 ? null : TaskData.EndDate !== null ? new Date(TaskData.EndDate) : null,
        noEndDate: type === 4 ? false : TaskData.EndDate !== null ? false : true,
        disableNoEndDate: type === 4 ? TaskData.IsApproverNeeded === true ? true:false : false,
        isApprovalNotNeeded: TaskData.IsApproverNeeded,
        newTimeDuration: TaskData.ActualTimeEntry,
        newFrequency: null,
        selectedFrequency: TaskData.FrequencyID,
        selectedAssignedTo: TaskData.AssignedTypeID,
        IsWithAllocation: TaskData.IsWithAllocation,
        newAllocation: TaskData.allocation,
        newSelectedRegion: RegionArray,
        newSelectedAllocatedRegion: allocatedRegionArray,
        newSelectedDepartment: departmentArray,
        newSelectedPractice: practiceArray,
        newSelectedIndividuals: UsersArray,
        showAddDialog: !this.state.showAddDialog,
        isSelectedDepartment: departmentArray.length > 0 ? true : false,
        isSelectedPractice: practiceArray.length > 0 ? true : false,
        isSelectedResourceRegion: RegionArray.length > 0 ? true : false,
        isSelectedAllocatedRegion: allocatedRegionArray.length > 0 ? true : false,
        selectedWeek:
          TaskData.WeekOfMonth !== null
            ? this.state.weeks.filter(x => x.week === TaskData.WeekOfMonth)[0]
            : null,
        selectedDay:
          TaskData.DayOfweek !== null
            ? this.state.days.filter(x => x.day === TaskData.DayOfweek)[0]
            : null
      });
      if (type === 3) {
        const auditTrail = {
          Action: 'Non Project Task View',
          RefType: 'Non Project Task',
          RefId: taskId,
          RefName: TaskData.Name
        };
        saveAuditTrail(auditTrail);
      }
    } else {
      this.setState({
        showAddDialog: !this.state.showAddDialog,
        isStartDateEditable: false,
        disableNoEndDate: false,
      });
    }
    this.getAllTaskGroups();
    this.regionsDropDownValidation();
    this.practiceDivisionMapping();
    this.setState({
      nonProjectTasks: this.state.nonProjectTasksAll
    });
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validation = () => {
    if (
      this.validateTaskGroup()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.newTaskName)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.newSelectedTaskType)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.newStartDate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateEndDate()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateTimeDuration()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateFrequency()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateMonthlyFrequencyWeek()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateMonthlyFrequencyDay()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.selectedAssignedTo)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateDepartment()
        .toString()
        .includes('error')
    ) {
      return false;
    }
    else if (
      this.validatePractice()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateRegion()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateAlloctedRegion()
        .toString()
        .includes('error')
    ) {
      return false;
    }else if (
      this.validateApprovers()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateIndividualAssignment()
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  validateDepartmentPracticeMapping = () => {
    if (this.state.isSelectedDepartment && this.state.isSelectedPractice && 
      this.state.newSelectedDepartment.length > 0 && this.state.newSelectedPractice.length > 0) {
        let invalidDepartments = [];
      for (const department of this.state.newSelectedDepartment) {
        let practicesForDivision = this.state.practiceDivisionMap.filter(item => 
          item.DepartmentCodeSF === department.SFCode).map(value => value.PracticeCodeSF);
        let selectedPracticesForDivisions = this.state.newSelectedPractice.map(item => item.SFCode);
        let check = selectedPracticesForDivisions.some(val=> practicesForDivision.includes(val));
        if (!check) {
          invalidDepartments.push(department)
        }
      }
      this.setState({
        invalidDepartments: invalidDepartments,
      });
      if (invalidDepartments.length == 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  validateCaseSensitiveTaskGroup = () => {
    if (this.state.isNewTaskGroup) {
      const taskGroup = this.state.nonProjectTasks.filter(
        x => x.Name.toLowerCase() === this.state.newTaskGroup.toLowerCase()
      )[0];
      if (typeof taskGroup !== 'undefined') {
        return true;
      } else {
        return false;
      }
    }
  };
  validateStratDate = () => {
    if (
      moment(this.state.newStartDate).format('ddd') === 'Sun' ||
      moment(this.state.newStartDate).format('ddd') === 'Sat'
    ) {
      return true;
    } else {
      return false;
    }
  };
  validateTaskGroup = () => {
    if (this.state.isNewTaskGroup) {
      return this.validateProperty(this.state.newTaskGroup);
    } else {
      return this.validateProperty(this.state.newSelectedTaskGroup);
    }
  };

  validateEndDate = () => {
    if (!this.state.noEndDate) {
      return this.validateProperty(this.state.newEndDate);
    } else {
      return 'd-none';
    }
  };

  validateFrequency = () => {
    if (this.state.isApprovalNotNeeded) {
      return this.validateProperty(this.state.selectedFrequency);
    } else {
      return 'd-none';
    }
  };

  validateMonthlyFrequencyWeek = () => {
    if (this.state.isApprovalNotNeeded) {
      if (this.state.selectedFrequency === this.state.frequency[2].code) {
        return this.validateProperty(this.state.selectedWeek);
      } else {
        return 'd-none';
      }
    } else {
      return 'd-none';
    }
  };
  validateMonthlyFrequencyDay = () => {
    if (this.state.isApprovalNotNeeded) {
      if (this.state.selectedFrequency === this.state.frequency[2].code) {
        return this.validateProperty(this.state.selectedDay);
      } else {
        return 'd-none';
      }
    } else {
      return 'd-none';
    }
  };

  validateDepartment = () => {
    if (this.state.isSelectedDepartment) {
      if (this.state.newSelectedDepartment.length === 0) {
        return 'inline-error';
      } else {
        return 'd-none';
      }
    } else {
      return 'd-none';
    }
  };

  validatePractice = () => {
    if (this.state.isSelectedPractice) {
      if (this.state.newSelectedPractice.length === 0) {
        return 'inline-error';
      } else {
        return 'd-none';
      }
    } else {
      return 'd-none';
    }
  };

  validateRegion = () => {
    if (this.state.isSelectedResourceRegion) {
      if (this.state.newSelectedRegion.length === 0) {
        return 'inline-error';
      } else {
        return 'd-none';
      }
    } else {
      return 'd-none';
    }
  };

  validateAlloctedRegion = () => {
    if (this.state.isSelectedAllocatedRegion) {
      if (this.state.newSelectedAllocatedRegion.length === 0) {
        return 'inline-error';
      } else {
        return 'd-none';
      }
    } else {
      return 'd-none';
    }
  };

  validateApprovers = () => {
    if (this.state.newSelectedApprover.length === 0) {
      return 'inline-error';
    } else {
      return 'd-none';
    }
  };

  validateIndividualAssignment = () => {
    if (this.state.selectedAssignedTo === this.state.assignedTo[4].code) {
      if (this.state.newSelectedIndividuals.length === 0) {
        return 'inline-error';
      } else {
        return 'd-none';
      }
    } else {
      return 'd-none';
    }
  };

  validateTimeDuration = () => {
    if (this.state.isApprovalNotNeeded) {
      return this.state.newTimeDuration === 0.0 ? 'inline-error' : 'd-none';
    } else {
      return 'd-none';
    }
  };

  toggleDialog = (message, title) => {
    if (!this.state.visible) {
      this.setState({
        loading: false
      });
    }
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleDepartmentValidateDialog = async (message, title) => {
    await this.setState({
      showDepartmentValidateDialog: !this.state.showDepartmentValidateDialog,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleAddEditDefaultTask = id => {
    this.setState({
      addEditDefaultTaskVisible: !this.state.addEditDefaultTaskVisible,
      allcationGridLoader: true,
      allowcationList: []
    });
    getNonProjectTaskAllowcations(id).then(allowcation => {
      var allowcationList = [];
      if (allowcation.data.length !== 0) {
        var allowcationTime = allowcation.data[0].ActualTimeEntry;
        this.setState({
          currentAllowcation: allowcation.data
        });
        allowcation.data[0].NonProjectTaskAllocation.length !== 0 &&
          allowcation.data[0].NonProjectTaskAllocation.forEach(allow => {
            let endDate = null;
            allow.EndDate === null ? (endDate = 'N/A') : (endDate = moment(allow.EndDate).format('MM-DD-YYYY'));
            const allowcation = {
              Resource: allow.User.displayName,
              Date: moment(allow.StartDate).format('MM-DD-YYYY') + '  -  ' + endDate,
              Allocation: allow.Allocation, //allowcationTime,
              Utilization: Number(allow.Utilization.toFixed(2)),
              Status: allow.IsActive === true ? 'Active' : 'Removed'
            };
            allowcationList.push(allowcation);
          });
      }
      this.setState(
        {
          allowcationList: allowcationList
        },
        () => {
          this.setState({
            allcationGridLoader: false
          });
        }
      );
    });
  };

  filledArray = (size, item) => {
    const loadingData = [];
      while (loadingData.length < size) {
      loadingData.push({ ...item });
    }
    return loadingData;
  }

  handleSubmitCreate = event => {
    event.preventDefault();
    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true,
        visible: true,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else if (this.validateCaseSensitiveTaskGroup()) {
      this.setState({
        isErrorMessageVisible: true,
        visible: true,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('Task group already exist, either use it or add a unique value.', 'Error');
    } else if (this.validateStratDate()) {
      this.setState({
        isErrorMessageVisible: true,
        visible: true,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog(
        'Selected Start Date falls on a Weekend. Please select another date.',
        'Error'
      );
    } else if(this.validateDepartmentPracticeMapping()) {
      this.setState({
        isErrorMessageVisible: true,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDepartmentValidateDialog(
        'Select at least 1 practice from the below department(s):',
        'Error'
      );
    } else {
      this.createNonProjectTask(event);
      this.setState({
        isDisableSave: true,
        isStartDateEditable: false,
        loading: true
      });
    }
  };

  handleEditSubmit = event => {
    event.preventDefault();
    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true,
        visible: true,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else if (this.validateCaseSensitiveTaskGroup()) {
      this.setState({
        isErrorMessageVisible: true,
        visible: true,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog(
        'Task Group already exists. Please use a different Task Group name.',
        'Error'
      );
    } else if (this.validateStratDate()) {
      this.setState({
        isErrorMessageVisible: true,
        visible: true,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog(
        'Selected Start Date falls on a Weekend. Please select another date.',
        'Error'
      );
    } else {
      this.editSubmitHandler(event);
      this.setState({
        isDisableSave: true,
        isStartDateEditable: false,
        loading: true
      });
    }
  };

  createNonProjectTask = event => {
    const NonProjectTask = {
      Name: this.state.newTaskName,
      taskType: this.state.newSelectedTaskType,
      isBillable: this.state.newIsBillable,
      selectedFrequency: this.state.selectedFrequency,
      selectedAssignedTo: this.state.selectedAssignedTo,
      isNewTaskGroup: this.state.isNewTaskGroup,
      newTaskGroupName: this.state.newTaskGroup,
      newStartDate: moment(this.state.newStartDate).format('YYYY-MM-DD'),
      newEndDate:
        this.state.newEndDate !== null ? moment(this.state.newEndDate).format('YYYY-MM-DD') : null,
      newSelectedDepartment: this.state.newSelectedDepartment,
      newSelectedPractice: this.state.newSelectedPractice,
      newSelectedRegion: this.state.newSelectedRegion,
      newSelectedAllocatedRegion: this.state.newSelectedAllocatedRegion,
      newSelectedIndividuals: this.state.newSelectedIndividuals,
      newTimeDuration: this.state.newTimeDuration,
      IsApproverNeeded: this.state.isApprovalNotNeeded,
      approver: this.state.newSelectedApprover,
      selectedTaskGroup: this.state.newSelectedTaskGroup ? this.state.newSelectedTaskGroup : null,
      createdUser: window.LOGGED_USER.displayName,
      updatedUser: window.LOGGED_USER.displayName,
      IsWithAllocation: this.state.IsWithAllocation,
      allocation: this.state.newAllocation == "" ? null : this.state.newAllocation,
      week: this.state.selectedWeek ? this.state.selectedWeek : null,
      day: this.state.selectedDay ? this.state.selectedDay : null,
      createdAt: new Date(),
      updatedAt: new Date()
    };
    saveNonProjectTask(NonProjectTask)
      .then(res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false,
          isDisableSave: false,
          isFormDirty: false,
          loading: false
        });
        this.toggleDialog(`Non project task was successfully created`, 'Success');
        this.toggleAddDialog();
        if (this.state.actionPopupTitle === 'Duplicate Non-Project Task') {
          const auditTrail = {
            Action: 'Non Project Task duplicate',
            RefType: 'Non Project Task',
            RefId: res.data.id,
            RefName: NonProjectTask.Name
          };
          saveAuditTrail(auditTrail);
        } else {
          const auditTrail = {
            Action: 'Non Project Task created',
            RefType: 'Non Project Task',
            RefId: res.data.id,
            RefName: NonProjectTask.Name
          };
          saveAuditTrail(auditTrail);
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          loading: false
        });
        this.toggleDialog(error.response.data, 'Error');
        this.setState({
          isDisableSave: false
        });
      });
  };

  editSubmitHandler = event => {
    const NonProjectTask = {
      Name: this.state.newTaskName,
      taskType: this.state.newSelectedTaskType,
      isBillable: this.state.newIsBillable,
      selectedFrequency: this.state.selectedFrequency,
      selectedAssignedTo: this.state.selectedAssignedTo,
      isNewTaskGroup: this.state.isNewTaskGroup,
      newTaskGroupName: this.state.newTaskGroup,
      newStartDate: moment(this.state.newStartDate).format('YYYY-MM-DD'),
      newEndDate:
        this.state.newEndDate !== null ? moment(this.state.newEndDate).format('YYYY-MM-DD') : null,
      newSelectedDepartment: this.state.newSelectedDepartment,
      newSelectedPractice: this.state.newSelectedPractice,
      newSelectedRegion: this.state.newSelectedRegion,
      newSelectedAllocatedRegion: this.state.newSelectedAllocatedRegion,
      newSelectedIndividuals: this.state.newSelectedIndividuals,
      newTimeDuration: this.state.newTimeDuration,
      IsApproverNeeded: this.state.isApprovalNotNeeded,
      approver: this.state.newSelectedApprover,
      selectedTaskGroup: this.state.newSelectedTaskGroup ? this.state.newSelectedTaskGroup : null,
      createdUser: window.LOGGED_USER.displayName,
      updatedUser: window.LOGGED_USER.displayName,
      IsWithAllocation: this.state.IsWithAllocation,
      allocation: this.state.newAllocation == "" ? null : this.state.newAllocation,
      week: this.state.selectedWeek !== null ? this.state.selectedWeek : null,
      day: this.state.selectedDay !== null ? this.state.selectedDay : null,
      createdAt: new Date(),
      updatedAt: new Date()
    };
    editNonProjectTask(NonProjectTask, this.state.editTask)
      .then(res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false,
          isDisableSave: false,
          isFormDirty: false,
          loading: false
        });
        this.toggleDialog(`Non project task was successfully updated.`, 'Success');
        this.toggleAddDialog();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          isDisableSave: true,
          loading: false
        });
        this.toggleDialog(error.response, 'Error');
        this.setState({
          isDisableSave: false,
          loading: false
        });
      });
  };

  navigateBack = event => {
    this.setState({
      selectedTask: [],
      selectedTaskType: [],
      selectedAssignUser: [],
      selectedApproveUser: [],
      selectedStatus: this.state.selectedStatus,
      selectedApprovalType: null,
      selectedTaskGroup: [],
      visible: false,
      showSuccessImage: false,
      showErrorImage: false
    });
    this.getAllNonProjectTasks();
    this.getAllNonProjectTaskUsers();
    this.getNonProjectTaskApprovers();
    this.getAllocatedBillingRegion();
    this.getAllocatedPractice();
    this.getAllocatedDepartment();
    this.getResourceAllocatedRegion();
  };
  OnDeactivate = (event, id) => {
    event.preventDefault();
    this.setState(
      {
        deactivateUserId: id
      },
      () => {
        this.deactivateConfirmationClose();
      }
    );
  };
  OnDelete = (event, id) => {
    event.preventDefault();
    this.setState(
      {
        deleteUserId: id
      },
      () => {
        this.deleteConfirmationClose();
      }
    );
  };
  OnDuplicate = (event, id) => {
    event.preventDefault();
    this.setState(
      {
        deleteUserId: id
      },
      () => {
        this.deleteConfirmationClose();
      }
    );
  };
  toggleCancelPopUpNonprojectTask = () => {
    if (this.state.isFormDirty === true) {
      this.setState({
        cancelNonProjectTaskPopupVisible: !this.state.cancelNonProjectTaskPopupVisible
      });
    } else {
      this.setState({
        isErrorMessageVisible: false,
      });
      if(!this.state.bulkApproverReplacement && !this.state.bulkresourceassignment && !this.state.bulkResourceRemoval && !this.state.bulkApproverAssignment && !this.state.bulkApproverRemoval){
        this.toggleAddDialog();
      }
    }
  };
  confirmCancelYes = () => {
    if(!this.state.bulkApproverReplacement && !this.state.bulkresourceassignment && !this.state.bulkResourceRemoval && !this.state.bulkApproverAssignment && !this.state.bulkApproverRemoval){
      this.toggleAddDialog();
    }
    this.toggleCancelPopUpNonprojectTask();
    this.setState({
      isFormDirty: false,
      bulkApproverReplacement: false,
      bulkresourceassignment:false,
      bulkResourceRemoval:false,
      showErrorImage:false,
      bulkApproverAssignment: false,
      bulkApproverRemoval: false
    });
    this.clearBulkApproverFields();
  };
  confirmCancelNo = () => {
    this.toggleCancelPopUpNonprojectTask();
  };
  deleteConfirmationClose = () => {
    this.setState({
      deletePopupVisible: !this.state.deletePopupVisible
    });
  };
  deactivateConfirmationClose = () => {
    this.setState({
      deactivatePopupVisible: !this.state.deactivatePopupVisible
    });
  };
  deactivateConfirmationOk = async () => {
    await updateNonProjectTaskStatus({ IsActive: false }, this.state.deactivateUserId)
      .then(response => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog('The Task has been Deactivated successfully', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    this.setState(
      {
        deactivatePopupVisible: false,
        loading: false
      },
      () => {
        this.getAllNonProjectTasks();
      }
    );
  };
  deleteConfirmationOk = async () => {
    await deleteNonProjectTask({ IsDeleted: true }, this.state.deleteUserId)
      .then(response => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog('The Task has been Deleted successfully', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    this.setState(
      {
        deletePopupVisible: false,
        loading: false
      },
      () => {
        this.getAllNonProjectTasks();
      }
    );
  };
  activateConfirmationOk = async () => {
    this.toggleAddDialog(4, this.state.activateTaskId);
    this.setState({
      activatePopupVisible: false,
      loading: false
    });
  };

  export = () => {
    this.setState({
      loading: true
    }, async () => {
      try {
        const {data} = await getNonProjectTaskExcel({
          task: this.state.selectedTask && this.state.selectedTask.name && this.state.selectedTask.name.length > 0 ? this.state.selectedTask.name.map(value => value.Name) : null,
          taskTypeId: this.state.selectedTaskType && this.state.selectedTaskType.length > 0 ? this.state.selectedTaskType.map(value => value.id) : null,
          assignedUserId: this.state.selectedAssignUser && this.state.selectedAssignUser.length > 0 ? this.state.selectedAssignUser.map(value => value.id) : null,
          approverId: this.state.selectedApproveUser && this.state.selectedApproveUser.length > 0  ? this.state.selectedApproveUser.map(value => value.id) : null,
          status: this.state.selectedStatus ? this.state.selectedStatus.status : null,
          approvalType: this.state.selectedApprovalType
            ? this.state.selectedApprovalType.status
            : null,
          taskGroupId: this.state.selectedTaskGroup && this.state.selectedTaskGroup.length > 0 ? this.state.selectedTaskGroup.map(value => value.id) : null,
          department: this.state.newSelectedDepartmentSearch && this.state.newSelectedDepartmentSearch.length > 0 ? this.state.newSelectedDepartmentSearch.map(value => value.id) : null,
          practice: this.state.newSelectedPracticeSearch && this.state.newSelectedPracticeSearch.length > 0 ? this.state.newSelectedPracticeSearch.map(value => value.id) : null,
        });
        this._export.save(data);
        this.setState({loading: false});
      } catch (error) {
        this.setState({loading: false});
      }
    });
  };

  populateExcelData(ExcelDataSet) {
    const set = [];
    for (const set of ExcelDataSet) {
      if (set.IsActive) {
        set.status = 'Active';
      } else {
        set.status = 'Inactive';
      }

      if (set.IsApproverNeeded) {
        set.ApprovalType = 'Approval Not Needed';
      } else {
        set.ApprovalType = 'Approval Needed';
      }
      set.createdDate = moment(set.createdAt).format('MM-DD-YYYY');
      set.updatedDate = moment(set.updatedAt).format('MM-DD-YYYY');

      const approvers = [];
      let nameStr = '';
      for (const approvers of set.NonProjectTaskApprover) {
        if(approvers && approvers.User){
        let name = approvers.User.displayName;
        if (nameStr == '') {
          nameStr += name;
        } else {
          nameStr += ', ' + name;
        }
        }
      }
      set.Approvers = nameStr;
    }
    return ExcelDataSet;
  }

  reActivateTask = async () => {
    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true,
        visible: true,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else {
      this.setState({
        loading: true
      });
      await updateNonProjectTaskStatus(
        {
          IsActive: true,
          EndDate:
            this.state.newEndDate !== null
              ? moment(this.state.newEndDate).format('YYYY-MM-DD')
              : this.state.newEndDate
        },
        this.state.activateTaskId
      )
        .then(response => {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false,
            loading: false
          });
          this.toggleDialog('The Task has been activated successfully', 'Success');
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      this.setState(
        {
          activatePopupVisible: false,
          loading: false,
          activeMode: false
        },
        () => {
          this.getAllNonProjectTasks();
        }
      );
      this.toggleAddDialog();
    }
  };
  OnActivate = (event, id) => {
    event.preventDefault();
    this.setState(
      {
        activateTaskId: id
      },
      () => {
        this.activateConfirmationClose();
      }
    );
  };
  activateConfirmationClose = () => {
    this.setState({
      activatePopupVisible: !this.state.activatePopupVisible
    });
  };

  getSelectedUsers = async () => {
    try {
      const {data} = await getFilteredTask({
        task: this.state.selectedTask ? this.state.selectedTask.name : null,
        taskTypeId: this.state.selectedTaskType ? this.state.selectedTaskType.id : null,
        assignedUserId: this.state.selectedAssignUser ? this.state.selectedAssignUser.id : null,
        approverId: this.state.selectedApproveUser ? this.state.selectedApproveUser.id : null,
        status: this.state.selectedStatus ? this.state.selectedStatus.status : null,
        approvalType: this.state.selectedApprovalType
          ? this.state.selectedApprovalType.status
          : null,
        taskGroupId: this.state.selectedTaskGroup ? this.state.selectedTaskGroup.id : null,
        isAllSelected: this.state.headerSelected,
        list: this.state.headerSelected ? this.state.uncheckedList : this.state.checkedList
      });

      return this.state.headerSelected && this.state.uncheckedList.length === 0 ? data.map(e => {
        return {
          ...e,
          selected: true
        }
      }) : this.state.headerSelected && this.state.uncheckedList.length > 0 ? data.map(e => {
        const {id} = e;
        if(this.state.uncheckedList.includes(id)){
          return {
            ...e,
            selected: false
          }
        }
        return {...e, selected: true};
      }) : data.map(e => {
        const {id} = e;
        if(this.state.checkedList.includes(id)){
          return {
            ...e,
            selected: true
          }
        }
        return {...e, selected: false};
      })
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
      return [];
    }
  }

  handleSaveReplacementForTasks = async () => {
    try {
      let failedTaskCount = 0;
      let successTaskCount = 0;
      let failedTasks = [];
      const userDetails = window.LOGGED_USER;
      let modifiedUser;

      if (!this.state.selectedItems) {
        loggerService.writeLog('Empty selected Task', LOG_TYPES.ERROR);
      }

      if (userDetails && userDetails.displayName !== null) {
        modifiedUser = userDetails.displayName;
      }

      const nptData = {
        existingApprover: this.state.selectedExistingApprover,
        replacingApprover: this.state.newRepSelectedApprover,
        updatedUser: modifiedUser
      };

      const filter = {
          task: this.state.selectedTask && this.state.selectedTask.name && this.state.selectedTask.name.length > 0 ? this.state.selectedTask.name.map(value => value.Name) : null,
          taskTypeId: this.state.selectedTaskType && this.state.selectedTaskType.length > 0 ? this.state.selectedTaskType.map(value => value.id) : null,
          assignedUserId: this.state.selectedAssignUser && this.state.selectedAssignUser.length > 0 ? this.state.selectedAssignUser.map(value => value.id) : null,
          approverId: this.state.selectedApproveUser && this.state.selectedApproveUser.length > 0  ? this.state.selectedApproveUser.map(value => value.id) : null,
          status: this.state.selectedStatus ? this.state.selectedStatus.status : null,
          approvalType: this.state.selectedApprovalType
            ? this.state.selectedApprovalType.status
            : null,
          taskGroupId: this.state.selectedTaskGroup && this.state.selectedTaskGroup.length > 0 ? this.state.selectedTaskGroup.map(value => value.id) : null,
          isAllSelected: this.state.headerSelected,
          list: this.state.headerSelected ? this.state.uncheckedList : this.state.checkedList,
          department: this.state.newSelectedDepartmentSearch && this.state.newSelectedDepartmentSearch.length > 0 ? this.state.newSelectedDepartmentSearch.map(value => value.id): null,
          practice: this.state.newSelectedPracticeSearch && this.state.newSelectedPracticeSearch.length > 0 ? this.state.newSelectedPracticeSearch.map(value => value.id) : null,
      }

      const {data: {responseTask, total}} = await replaceApproverOfNonProjectTasks({nptData, filter}, this.state.newRepSelectedApprover.id)
      responseTask.forEach(function(task) {
        failedTasks.push(task.Name);
      });
      failedTaskCount = failedTasks.length;
      successTaskCount = total - failedTaskCount;

      this.setState({
        bulkApproverReplacement: false,
        checkedList: [],
        uncheckedList: [],
        headerSelected: false,
        showSuccessImage: true,
        showErrorImage: false,
        isDisableBulkReplace: true,
        isDisableBulkResource: true,
        isDisableBulkRemoval: true,
        isDisableReplaceSave: true,
        isDisableRemovalSave: true,
        showConfirmDialog: false,
        failedArray: failedTasks,
        successTasksCount: successTaskCount,
        nptSelectedCount: total,
        sort: [{ field: 'updatedAt', dir: 'desc' }],
        isDisableBulkApproverAssign: true,
        isDisableBulkApproverRemoval: true
      });

      this.toggleApproverReplaceResponseDialog();


      this.submitSearch();

      this.setState({
        existingApprovers: this.state.usersAll,
        newReplacingApprovers: this.state.usersAll
      });
      this.getActiveReplacingApprovers();
      this.getNonProjectTaskApprovers();
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  };

  handleSaveApproverAssignedForTasks = async () => {
    try {
      let failedTaskCount = 0;
      let successTaskCount = 0;
      let failedTasks = [];
      const userDetails = window.LOGGED_USER;
      let modifiedUser;

      if (!this.state.selectedItems) {
        loggerService.writeLog('Empty selected Task', LOG_TYPES.ERROR);
      }

      if (userDetails && userDetails.displayName !== null) {
        modifiedUser = userDetails.displayName;
      }

      const nptData = {
        assignedApprovers: this.state.selectedAssignedApprover,
        updatedUser: modifiedUser
      };

      const filter = {
          task: this.state.selectedTask && this.state.selectedTask.name && this.state.selectedTask.name.length > 0 ? this.state.selectedTask.name.map(value => value.Name) : null,
          taskTypeId: this.state.selectedTaskType && this.state.selectedTaskType.length > 0 ? this.state.selectedTaskType.map(value => value.id) : null,
          assignedUserId: this.state.selectedAssignUser && this.state.selectedAssignUser.length > 0 ? this.state.selectedAssignUser.map(value => value.id) : null,
          approverId: this.state.selectedApproveUser && this.state.selectedApproveUser.length > 0  ? this.state.selectedApproveUser.map(value => value.id) : null,
          status: this.state.selectedStatus ? this.state.selectedStatus.status : null,
          approvalType: this.state.selectedApprovalType
            ? this.state.selectedApprovalType.status
            : null,
          taskGroupId: this.state.selectedTaskGroup && this.state.selectedTaskGroup.length > 0 ? this.state.selectedTaskGroup.map(value => value.id) : null,
          isAllSelected: this.state.headerSelected,
          list: this.state.headerSelected ? this.state.uncheckedList : this.state.checkedList,
          department: this.state.newSelectedDepartmentSearch && this.state.newSelectedDepartmentSearch.length > 0 ? this.state.newSelectedDepartmentSearch.map(value => value.id): null,
          practice: this.state.newSelectedPracticeSearch && this.state.newSelectedPracticeSearch.length > 0 ? this.state.newSelectedPracticeSearch.map(value => value.id) : null,
      }

      const {data: {responseTask, total}} = await saveBulkApproverAddition({nptData, filter})
      responseTask.forEach(function(task) {
        failedTasks.push(task.Name);
      });
      failedTaskCount = failedTasks.length;
      successTaskCount = total - failedTaskCount;

      this.setState({
        bulkApproverAssignment: false,
        bulkApproverReplacement: false,
        checkedList: [],
        uncheckedList: [],
        headerSelected: false,
        showSuccessImage: true,
        showErrorImage: false,
        isDisableBulkReplace: true,
        isDisableBulkResource: true,
        isDisableBulkRemoval: true,
        isDisableReplaceSave: true,
        isDisableRemovalSave: true,
        isDisableBulkApproverAssign: true,
        isDisableBulkApproverRemoval: true,
        showConfirmDialog: false,
        failedArray: failedTasks,
        successTasksCount: successTaskCount,
        nptSelectedCount: total,
        sort: [{ field: 'updatedAt', dir: 'desc' }],
        newAssignedApprovers: this.state.activeUsersAll
      });

      this.toggleApproverAdditionDialog();
      this.getNonProjectTaskApprovers();
      this.submitSearch();

    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  };

  handleSaveRemovedApproversForTasks = async () => {
    try {
      let failedTaskCount = 0;
      let successTaskCount = 0;
      let failedTasks = [];
      const userDetails = window.LOGGED_USER;
      let modifiedUser;

      if (!this.state.selectedItems) {
        loggerService.writeLog('Empty selected Task', LOG_TYPES.ERROR);
      }

      if (userDetails && userDetails.displayName !== null) {
        modifiedUser = userDetails.displayName;
      }

      const nptData = {
        removedApprovers: this.state.selectedApproverRemoval,
        updatedUser: modifiedUser
      };

      const filter = {
          task: this.state.selectedTask && this.state.selectedTask.name && this.state.selectedTask.name.length > 0 ? this.state.selectedTask.name.map(value => value.Name) : null,
          taskTypeId: this.state.selectedTaskType && this.state.selectedTaskType.length > 0 ? this.state.selectedTaskType.map(value => value.id) : null,
          assignedUserId: this.state.selectedAssignUser && this.state.selectedAssignUser.length > 0 ? this.state.selectedAssignUser.map(value => value.id) : null,
          approverId: this.state.selectedApproveUser && this.state.selectedApproveUser.length > 0  ? this.state.selectedApproveUser.map(value => value.id) : null,
          status: this.state.selectedStatus ? this.state.selectedStatus.status : null,
          approvalType: this.state.selectedApprovalType
            ? this.state.selectedApprovalType.status
            : null,
          taskGroupId: this.state.selectedTaskGroup && this.state.selectedTaskGroup.length > 0 ? this.state.selectedTaskGroup.map(value => value.id) : null,
          isAllSelected: this.state.headerSelected,
          list: this.state.headerSelected ? this.state.uncheckedList : this.state.checkedList,
          department: this.state.newSelectedDepartmentSearch && this.state.newSelectedDepartmentSearch.length > 0 ? this.state.newSelectedDepartmentSearch.map(value => value.id): null,
          practice: this.state.newSelectedPracticeSearch && this.state.newSelectedPracticeSearch.length > 0 ? this.state.newSelectedPracticeSearch.map(value => value.id) : null,
      }

      const {data: {responseTask, total}} = await saveBulkApproverRemoval({nptData, filter})
      responseTask.forEach(function(task) {
        failedTasks.push(task.Name);
      });
      failedTaskCount = failedTasks.length;
      successTaskCount = total - failedTaskCount;

      this.setState({
        bulkApproverRemoval: false,
        bulkApproverReplacement: false,
        checkedList: [],
        uncheckedList: [],
        headerSelected: false,
        showSuccessImage: true,
        showErrorImage: false,
        isDisableBulkReplace: true,
        isDisableBulkResource: true,
        isDisableBulkRemoval: true,
        isDisableReplaceSave: true,
        isDisableRemovalSave: true,
        isDisableBulkApproverAssign: true,
        isDisableBulkApproverRemoval: true,
        showConfirmDialog: false,
        failedArray: failedTasks,
        successTasksCount: successTaskCount,
        nptSelectedCount: total,
        sort: [{ field: 'updatedAt', dir: 'desc' }],
        approverRemovalUsers: this.state.usersAll,
      });

      this.toggleApproverRemovalDialog();
      this.getNonProjectTaskApprovers();
      this.submitSearch();

    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  };

  handleRemoveResourcesForTasks = async () => {
    try {
      let nptArray = [];
      let failedTaskCount = 0;
      let successTaskCount = 0;
      let failedTasks = [];
      const userDetails = window.LOGGED_USER;
      let modifiedUser;

      if (userDetails && userDetails.displayName !== null) {
        modifiedUser = userDetails.displayName;
      }

      const filter = {
        task: this.state.selectedTask && this.state.selectedTask.name && this.state.selectedTask.name.length > 0 ? this.state.selectedTask.name.map(value => value.Name) : null,
        taskTypeId: this.state.selectedTaskType && this.state.selectedTaskType.length > 0 ? this.state.selectedTaskType.map(value => value.id) : null,
        assignedUserId: this.state.selectedAssignUser && this.state.selectedAssignUser.length > 0 ? this.state.selectedAssignUser.map(value => value.id) : null,
        approverId: this.state.selectedApproveUser && this.state.selectedApproveUser.length > 0  ? this.state.selectedApproveUser.map(value => value.id) : null,
        status: this.state.selectedStatus ? this.state.selectedStatus.status : null,
        approvalType: this.state.selectedApprovalType
          ? this.state.selectedApprovalType.status
          : null,
        taskGroupId: this.state.selectedTaskGroup && this.state.selectedTaskGroup.length > 0 ? this.state.selectedTaskGroup.map(value => value.id) : null,
        isAllSelected: this.state.headerSelected,
        list: this.state.headerSelected ? this.state.uncheckedList : this.state.checkedList,
        department: this.state.newSelectedDepartmentSearch && this.state.newSelectedDepartmentSearch.length > 0 ? this.state.newSelectedDepartmentSearch.map(value => value.id): null,
          practice: this.state.newSelectedPracticeSearch && this.state.newSelectedPracticeSearch.length > 0 ? this.state.newSelectedPracticeSearch.map(value => value.id) : null,
      }

      const nptData = {
        nonProjectTasks: nptArray,
        removeResources: this.state.selectedRemovalApprovers,
        removeDate: this.state.resourceRemovalDate
          ? moment(this.state.resourceRemovalDate).format('YYYY-MM-DD')
          : null,
        updatedUser: modifiedUser
      };

      const {data: {responseTask, total}} = await removeResourceOfNonProjectTasks({nptData, filter})
      responseTask.forEach(function(task) {
        failedTasks.push(task.Name);
      });
      failedTaskCount = failedTasks.length;
      successTaskCount = total - failedTaskCount;

      this.setState({
        bulkApproverReplacement: false,
        bulkResourceRemoval: false,
        showSuccessImage: true,
        showErrorImage: false,
        checkedList: [],
        uncheckedList: [],
        headerSelected: false,
        isDisableBulkReplace: true,
        isDisableBulkResource: true,
        isDisableBulkRemoval: true,
        isDisableReplaceSave: true,
        isDisableRemovalSave: true,
        showConfirmDialog: false,
        failedArray: failedTasks,
        successTasksCount: successTaskCount,
        nptSelectedCount: total,
        sort: [{ field: 'updatedAt', dir: 'desc' }],
        isDisableBulkApproverAssign: true,
        isDisableBulkApproverRemoval: true
      });

      this.toggleResourceRemovalResponseDialog();
      this.getAllNonProjectTaskUsers()

      this.submitSearch();

      this.setState({
        existingApprovers: this.state.usersAll,
        selectedRemovalApprovers: null,
        resourceRemovalDate: null,
        isBulkApprovalNotNeeded: false,
        isDisableRemovalSave: true
      });
    } catch (error) {
        
    }
  };

  handleSaveResourceforTasks = async () => {
    try {
      let failedTaskCount = 0;
      let successTaskCount = 0;
      let failedTasks = [];
      const userDetails = window.LOGGED_USER;
      let modifiedUser;

      if (userDetails && userDetails.displayName !== null) {
        modifiedUser = userDetails.displayName;
      }

      const filter = {
        task: this.state.selectedTask && this.state.selectedTask.name && this.state.selectedTask.name.length > 0 ? this.state.selectedTask.name.map(value => value.Name) : null,
        taskTypeId: this.state.selectedTaskType && this.state.selectedTaskType.length > 0 ? this.state.selectedTaskType.map(value => value.id) : null,
        assignedUserId: this.state.selectedAssignUser && this.state.selectedAssignUser.length > 0 ? this.state.selectedAssignUser.map(value => value.id) : null,
        approverId: this.state.selectedApproveUser && this.state.selectedApproveUser.length > 0  ? this.state.selectedApproveUser.map(value => value.id) : null,
        status: this.state.selectedStatus ? this.state.selectedStatus.status : null,
        approvalType: this.state.selectedApprovalType
          ? this.state.selectedApprovalType.status
          : null,
        taskGroupId: this.state.selectedTaskGroup && this.state.selectedTaskGroup.length > 0 ? this.state.selectedTaskGroup.map(value => value.id) : null,
        isAllSelected: this.state.headerSelected,
        list: this.state.headerSelected ? this.state.uncheckedList : this.state.checkedList,
        department: this.state.newSelectedDepartmentSearch && this.state.newSelectedDepartmentSearch.length > 0 ? this.state.newSelectedDepartmentSearch.map(value => value.id): null,
        practice: this.state.newSelectedPracticeSearch && this.state.newSelectedPracticeSearch.length > 0 ? this.state.newSelectedPracticeSearch.map(value => value.id) : null,
      }

      const nptData = {
        assignResources: this.state.selectedAssignedResource,
        updatedUser: modifiedUser
      };

      const {data: {responseTask, total}} = await saveBulkResourceAddition({nptData, filter});
      responseTask.forEach(function(task) {
        failedTasks.push(task.Name);
      });
      failedTaskCount = failedTasks.length;
      successTaskCount = total - failedTaskCount;

      this.setState({
        bulkApproverReplacement: false,
        bulkResourceRemoval: false,
        bulkresourceassignment: false,
        showSuccessImage: true,
        showErrorImage: false,
        isDisableSave: false,
        isDisableBulkReplace: true,
        isDisableBulkResource: true,
        isDisableBulkRemoval: true,
        isDisableReplaceSave: true,
        isDisableRemovalSave: true,
        showConfirmDialog: false,
        failedArray: failedTasks,
        successTasksCount: successTaskCount,
        nptSelectedCount: total,
        sort: [{ field: 'updatedAt', dir: 'desc' }],
        isDisableBulkApproverAssign: true,
        isDisableBulkApproverRemoval: true
      });

      this.toggleResourceAdditionDialog();
      this.getAllNonProjectTaskUsers();

      this.submitSearch();
      
    } catch (error) {
        
    }
  };

  toggleResourceAdditionDialog = () => {
    this.setState({
      rescAddVisible: !this.state.rescAddVisible,
      showErrorImage: false,
      showSuccessImage: false
    });
  };

  toggleApproverAdditionDialog = () => {
    this.setState({
      resApproversVisible: !this.state.resApproversVisible,
      showErrorImage: false,
      showSuccessImage: false
    });
  };

  toggleApproverRemovalDialog = () => {
    this.setState({
      resApproverRemovalVisible: !this.state.resApproverRemovalVisible,
      showErrorImage: false,
      showSuccessImage: false
    });
  };

  fetchFilteredNonProjectTasks = task => {
    this.setState({ loadingTasks: true }, async () => {
      try {
        const { data } = await getFilteredNonProjectTasks({ task });
        this.setState({
          filteredTasks:
            data.findIndex(e => e.Name.toLowerCase() === task.toLowerCase()) === -1
              ? [...data, { Name: task }]
              : data,
          loadingTasks: false
        });
      } catch (error) {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState(
          {
            showSuccessImage: false,
            showErrorImage: true,
            loadingTasks: false
          },
          () => {
            this.toggleDialog('Something went wrong', 'Error');
          }
        );
      }
    });
  };

  fetchFilteredNonProjectTaskGroups = group => {
    this.setState({ loadingGroups: true }, async () => {
      try {
        const { data } = await getFilteredNonProjectTaskGroups({ group });
        this.setState({
          filteredGroups:
            data.findIndex(e => e.Name.toLowerCase() === group.toLowerCase()) === -1
              ? [...data, { Name: group }]
              : data,
          loadingGroups: false
        });
      } catch (error) {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState(
          {
            showSuccessImage: false,
            showErrorImage: true,
            loadingGroups: false
          },
          () => {
            this.toggleDialog('Something went wrong', 'Error');
          }
        );
      }
    });
  };

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Non-Project Task Search</div>
          </div>
          <div className="col-md-4">
            <Button
              primary={true}
              onClick={() => this.toggleAddDialog(1, null)}
              className="pull-right"
              disabled={!(window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1)}
            >
              Create New Non-Project Task
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2 col-sm-4">
            <div className="d-block">
              <label>Task:</label>
            </div>
            <div className="d-block">
              <AsyncSelectPagination
                service={getFilteredNonProjectTaskNames}
                placeholder={'Please Select'}
                pageSize={10}
                emptyItem={{Name: 'Loading...'}}
                loadingData={this.filledArray(10, {Name: 'Loading...'})}
                textField={'Name'}
                dataItemKey={'Name'}
                delay={300}
                name="taskName"
                addFilterIfNull={false}
                value={this.state.selectedTask.name ? this.state.selectedTask.name.map(v => v) : []}
                onChange={this.handleOnChangeInput}
                isFetchOnOpen={true}
                onOpenValue={''}
                field={'TaskName'}
              />
            </div>
          </div>
          <div className="col-md-1" />

          <div className="col-md-2 col-sm-4">
            <div className="d-block">
              <label>Task Type:</label>
            </div>
            <div className="d-block">
              <MultiSelect
                data={this.state.taskTypes}
                name="taskTypes"
                textField="Name"
                placeholder={'Please Select'}
                onChange={this.handleOnChangeInput}
                value={this.state.selectedTaskType}
                filterable={true}
                onFilterChange={this.filterOnChange}
              />
            </div>
          </div>
          <div className="col-md-1" />

          <div className="col-md-2 col-sm-4">
            <div className="d-block">
              <label>Task Group:</label>
            </div>
            <div className="d-block">
              <MultiSelect
                name="taskGroup"
                textField="Name"
                value={this.state.selectedTaskGroup}
                data={this.state.taskGroups}
                placeholder={'Please Select'}
                onChange={this.handleOnChangeInput}
                filterable={true}
                onFilterChange={this.filterOnChange}
              />
            </div>
          </div>
          <div className="col-md-1" />

          <div className="col-md-2 col-sm-4">
            <div className="d-block">
              <label>Department:</label>
            </div>
            <div className="d-block">
              <MultiSelect
                name="newDepartmentSearch"
                textField="Name"
                value={this.state.newSelectedDepartmentSearch}
                data={this.state.departmentsData}
                placeholder={'Please Select'}
                onChange={this.handleOnChangeInput}
                filterable={true}
                onFilterChange={this.filterOnChange}
              />
            </div>
          </div>
          {/* <div className="col-md-2 col-sm-4">
            <div className="d-block">
              <label>Status:</label>
            </div>
            <div className="d-block">
              <ComboBox
                name="searchStatus"
                textField="Name"
                dataItemKey="status"
                value={this.state.selectedStatus}
                data={this.state.status}
                placeholder={'Please Select'}
                onChange={this.handleOnChangeInput}
              />
            </div>
          </div> */}
        </div>

        <div className="row">
          <div className="col-md-2 col-sm-4">
            <div className="d-block">
              <label>Approver:</label>
            </div>
            <div className="d-block">
              <MultiSelect
                data={this.state.approvedUsers}
                name="approvedUsers"
                textField="displayName"
                placeholder={'Please Select'}
                onChange={this.handleOnChangeInput}
                value={this.state.selectedApproveUser}
                filterable={true}
                onFilterChange={this.filterOnChange}
              />
            </div>
          </div>
          <div className="col-md-1" />

          <div className="col-md-2 col-sm-4">
            <div className="d-block">
              <label>Approval Type:</label>
            </div>
            <div className="d-block">
              <ComboBox
                name="approvalType"
                textField="Name"
                dataItemKey="status"
                value={this.state.selectedApprovalType}
                data={this.state.approvalType}
                placeholder={'Please Select'}
                onChange={this.handleOnChangeInput}
              />
            </div>
          </div>

          <div className="col-md-1" />

          <div className="col-md-2 col-sm-4">
            <div className="d-block">
              <label>Assigned User:</label>
            </div>
            <div className="d-block">
              <MultiSelect
                data={this.state.assignedUsers}
                name="assignedUsers"
                textField="displayName"
                placeholder={'Please Select'}
                onChange={this.handleOnChangeInput}
                value={this.state.selectedAssignUser}
                filterable={true}
                onFilterChange={this.filterOnChange}
              />
            </div>
          </div>

          <div className="col-md-1" />
          <div className="col-md-2 col-sm-4">
            <div className="d-block">
              <label>Practice:</label>
            </div>
            <div className="d-block">
              <MultiSelect
                name="newPracticeSearch"
                textField="Name"
                value={this.state.newSelectedPracticeSearch}
                data={this.state.practicesData}
                placeholder={'Please Select'}
                onChange={this.handleOnChangeInput}
                filterable={true}
                onFilterChange={this.filterOnChange}
              />
            </div>
          </div>
        </div>
        <div className='row'>
        <div className="col-md-2 col-sm-4">
            <div className="d-block">
              <label>Status:</label>
            </div>
            <div className="d-block">
              <ComboBox
                name="searchStatus"
                textField="Name"
                dataItemKey="status"
                value={this.state.selectedStatus}
                data={this.state.status}
                placeholder={'Please Select'}
                onChange={this.handleOnChangeInput}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 btn-align-right mt-2">
            <Button primary={true} type="submit" onClick={async(e) => {await this.setState({skip:0, currentlySelectedRows:[]});this.submitSearch(e);}}>
              Search
            </Button>
            <Button type="button" style={{ marginRight: '5px' }} onClick={this.cancelSearch}>
              Clear
            </Button>
          </div>
        </div>

        <div className="main-seperator" />

        <div className="row">
          <div className="col-md-12">
            <div className="main-heading">Search Results</div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="grid-header">
              <div className="row">
                <div className="col-md-12">
                  <div className="btn-align-left">
                    <Button
                      look="outline"
                      primary={true}
                      type="button"
                      onClick={this.toggleBulkApproverReplacementDialog}
                      disabled={
                        !(window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1) ||
                        this.state.isDisableBulkReplace
                      }
                    >
                      Bulk Approver Replacement
                    </Button>
                    <Button
                      look="outline"
                      onClick={this.toggleBulkApproverRemovalDialog}
                      primary={true}
                      type="button"
                      disabled={
                        !(window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1) ||
                        this.state.isDisableBulkApproverRemoval
                      }
                    >
                      Bulk Approver Removal
                    </Button>
                    <Button
                      look="outline"
                      onClick={this.toggleBulkApproverAssignmentDialog}
                      primary={true}
                      type="button"
                      disabled={
                        !(window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1) ||
                        this.state.isDisableBulkApproverAssign
                      }
                    >
                      Bulk Approver Assignment
                    </Button>
                    <Button
                      look="outline"
                      primary={true}
                      type="button"
                      onClick={this.toggleBulkReourceRemovalDialog}
                      disabled={
                        !(window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1) ||
                        this.state.isDisableBulkRemoval
                      }
                    >
                      Bulk Resource Removal
                    </Button>
                    <Button
                      look="outline"
                      onClick={this.toggleBulkRescAssignmentDialog}
                      primary={true}
                      type="button"
                      disabled={
                        !(window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1) ||
                        this.state.isDisableBulkResource
                      }
                    >
                      Bulk Resource Assignment
                    </Button>
                  </div>
                  <div className="btn-align-right">
                    <Button title="Export Excel" className="k-button k-primary" onClick={this.export}>
                      Export to Excel
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <br />
            <ExcelExport
              ref={exporter => {
                this._export = exporter;
              }}
              fileName={'Non Project Tasks List ' + moment(new Date()).format('DD-MM-YYYY')}
            >
              <ExcelExportColumn field="Name" title="Task" />
              <ExcelExportColumn field="TaskType.Name" title="Task Type" />
              <ExcelExportColumn field="TaskGroup.Name" title="Task Group" />
              <ExcelExportColumn field="ActiveStatus" title="Status" />
              <ExcelExportColumn
                field="TotalActualTimeEntry"
                title="Actual Time Entry"
                cellOptions={{ textAlign: 'left' }}
              />
              <ExcelExportColumn field="ApprovalStatus" title="Approval Type" />
              <ExcelExportColumn field="NonProjectTaskApprover" title="Approver/s" />
              <ExcelExportColumn field="createdDate" title="Created Date" />
              <ExcelExportColumn field="updatedDate" title="Last Modified Date" />
              <ExcelExportColumn field="updatedUser" title="Last Modified User" />
            </ExcelExport>
            <Grid
              data={this.state.allNonProjectTasks}
              skip={this.state.skip}
              take={this.state.take}
              resizable
              pageable={true}
              total={this.state.total}
              onPageChange={this.pageChange}
              sortable={{
                allowUnsort: false,
                mode: "single"
              }}
              onSelectionChange={this.selectionChange}
              onHeaderSelectionChange={this.headerSelectionChange}
              onRowClick={this.rowClick}
              selectedField="selected"
              sort={this.state.sort}
              onSortChange={async(e) => {
                this.setState({
                  sort: e.sort
                }, () => {
                  this.pageChangeSearch();
                });
              }}
            >
              <Column
                field="selected"
                width="50px"
                headerSelectionValue={this.state.headerSelected && this.state.uncheckedList.length === 0}
              />
              <Column field="Name" title="Task" />
              <Column field="TaskType.Name" title="Task Type" />
              <Column field="TaskGroup.Name" title="Task Group" />
              <Column title="Status" field="Status" />
              <Column field="TotalActualTimeEntry" title="Actual Time Entry" cell={props=><td>{props.dataItem.TotalActualTimeEntry && parseFloat(props.dataItem.TotalActualTimeEntry).toFixed(2)}</td>}/>
              <Column title="Approval Type" field="IsApproverNeeded" />
              <Column
                field="NonProjectTaskApprover"
                title="Approver/s"
                width="110px"
              />
              <Column title="Created Date" field="createdAt" />
              <Column title="Last Modified Date" field="updatedAt" />
              <Column field="updatedUser" title="Last Modified User" />
              <Column
                field=""
                title="Actions"
                width="160px"
                cell={props => (
                  <ActionsCell
                    {...props}
                    OnDeactivate={this.OnDeactivate}
                    OnActivate={this.OnActivate}
                    OnDelete={this.OnDelete}
                    OnDuplicate={this.OnDuplicate}
                    doctypeNames={this.state.doctypeNames}
                    EditModel={this.toggleAddDialog}
                    ViewModel={this.toggleAddDialog}
                    DuplicateModel={this.toggleAddDialog}
                    AddEditDefaultTask={this.toggleAddEditDefaultTask}
                  />
                )}
              />
              {/*  <Column title="Actions" width="200px" cell={props => (<td className="action-buttons">*/}
              {/*  <a href="#">*/}
              {/*    <div className="rounded-link" title="View">*/}
              {/*      <span className="k-icon k-i-preview k-i-eye" />*/}
              {/*    </div>*/}
              {/*  </a>*/}
              {/*  <a href="#">*/}
              {/*    <div className="rounded-link" title="Edit Task">*/}
              {/*      <span className="k-icon k-i-edit" />*/}
              {/*    </div>*/}
              {/*  </a>*/}
              {/*  <a href="#">*/}
              {/*    <div className="rounded-link" title="Edit Resources">*/}
              {/*      <span className="k-icon k-i-page-properties" />*/}
              {/*    </div>*/}
              {/*  </a>*/}
              {/*  <a href="#">*/}
              {/*    <div className="rounded-link" title="Close">*/}
              {/*      <span className="k-icon k-i-close k-i-x" />*/}
              {/*    </div>*/}
              {/*  </a>*/}
              {/*  <a href="#">*/}
              {/*    <div className="rounded-link" title="Duplicate">*/}
              {/*      <span className="k-icon k-i-copy k-i-files" />*/}
              {/*    </div>*/}
              {/*  </a>*/}
              {/*</td>)} />*/}
            </Grid>
          </div>
        </div>

        {/* Bulk Recourse Assignment Modal */}
        {/* <Dialog title={'Bulk Resource Assignment'} width={500}>
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="d-block">
                <label>Resource(s) to be added:</label>
              </div>
              <div className="d-block">
                <ComboBox />
              </div>
            </div>
            <div className="col-md-3" />
          </div>
          <DialogActionsBar>
            <button className="k-button ">Cancel</button>
            <button className="k-button modal-primary">Save</button>
          </DialogActionsBar>
        </Dialog> */}

        {/* Bulk Approver Replacement Modal */}
        {/* <Dialog title={'Bulk Approver Replacement'} width={500}>
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6">
              <div className="d-block">
                <label>Approver:</label>
              </div>
              <div className="d-block">
                <ComboBox />
              </div>
            </div>
            <div className="col-md-3" />
          </div>
          <DialogActionsBar>
            <button className="k-button ">Cancel</button>
            <button className="k-button modal-primary">Save</button>
          </DialogActionsBar>
        </Dialog> */}

        {/* Bulk Resource Removal Modal */}
        {/* <Dialog title={'Bulk Resource Removal'} width={500}>
          <div className="row">
            <div className="col-md-6">
              <div className="d-block">
                <label>Resource(s) to be removed:</label>
              </div>
              <div className="d-block">
                <MultiSelect />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-block">
                <label>Date:</label>
              </div>
              <div className="d-block">
                <DatePicker />
              </div>
            </div>
          </div>
          <DialogActionsBar>
            <button className="k-button ">Cancel</button>
            <button className="k-button modal-primary">Save</button>
          </DialogActionsBar>
        </Dialog> */}

        {/* Add/Edit Non-Project Task Modal */}

        <div>
          {this.state.showAddDialog === true && (
            <Dialog
              title={this.state.actionPopupTitle}
              width={700}
              height={500}
              className="overflow-auto"
              onClose={() => this.toggleCancelPopUpNonprojectTask()}
            >
              <div className="row">
                {this.state.editNptModelLoder === true ? (
                  <div className="k-loading-mask">
                    <span className="k-loading-text">Loading</span>
                    <div className="k-loading-image" />
                    <div className="k-loading-color" />
                  </div>
                ) : (
                  <div />
                )}
                <div className="col-md-4">
                  <div className="d-block">
                    <label>Task Group:</label>
                  </div>
                  <div className="d-block">
                    <AsyncSelectPagination
                      service={getFilteredNonProjectTaskGroups}
                      filterName={'group'}
                      disabled={
                        this.state.viewMode ||
                        this.state.activeMode ||
                        this.state.isTaskTypeEditable
                      }
                      placeholder={'Please Select'}
                      pageSize={10}
                      emptyItem={{Name: 'Loading...'}}
                      loadingData={this.filledArray(10, {Name: 'Loading...'})}
                      textField={'Name'}
                      dataItemKey={'Name'}
                      delay={300}
                      name="newTaskName"
                      addFilterIfNull={true}
                      value={this.state.selectedFilteredTaskGroup}
                      onChange={e => {
                          this.setState({
                            selectedFilteredTaskGroup: e.target.value || null,
                            newSelectedTaskGroup: e.target.value ? e.target.value.Name : null,
                            isFormDirty: true
                          })
                        }
                      }
                      isFetchOnOpen={true}
                      onOpenValue={''}
                      field={'TaskGroup'}
                    />
                    {this.state.isErrorMessageVisible === true && !this.state.isNewTaskGroup ? (
                      <span className={this.validateProperty(this.state.newSelectedTaskGroup)}>
                        Task group is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="d-block">
                    <label>Task Name:</label>
                  </div>
                  <div className="d-block">
                    <AsyncSelect
                      filter={this.fetchFilteredNonProjectTasks}
                      disabled={
                        this.state.viewMode ||
                        this.state.activeMode ||
                        this.state.isTaskTypeEditable
                      }
                      loading={this.state.loadingTasks}
                      delay={300}
                      data={this.state.filteredTasks}
                      textField={'Name'}
                      dataItemKeyf={'Name'}
                      name="newTaskName"
                      value={this.state.selectedAsyncTask}
                      onChange={e =>
                        this.setState({
                          selectedAsyncTask: e.target.value,
                          newTaskName: e.target.value.Name,
                          isFormDirty: true
                        })
                      }
                      isFetchOnOpen={true}
                      onOpenValue={''}
                    />
                    {this.state.isErrorMessageVisible ? (
                      <span className={this.validateProperty(this.state.newTaskName)}>
                        Task name is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
                {/* <div className="row">
                <div className="col-md-2">
                  <div className="d-block">
                    <input
                      type="radio"
                      id="newBillable"
                      className="k-radio"
                      checked={this.state.newIsBillable}
                      onChange={this.handleOnChangeBillableStatus_Add}
                      name="newIsBillable"
                      disabled={this.state.viewMode}
                    />
                    <label className="k-radio-label" htmlFor="newBillable">
                      Billable
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-block">
                    <input
                      type="radio"
                      id="newNonBillable"
                      className="k-radio"
                      checked={!this.state.newIsBillable}
                      onChange={this.handleOnChangeBillableStatus_Add}
                      name="newIsBillable"
                      disabled={this.state.viewMode}
                    />
                    <label className="k-radio-label" htmlFor="newNonBillable">
                      Non-billable
                    </label>
                  </div>
                </div>
              </div> */}

                <div className="col-md-4">
                  <div className="d-block">
                    <label className="mandatory">Task Type:</label>
                  </div>
                  <div className="d-block">
                    <ComboBox
                      data={this.state.newTaskTypes}
                      name="newTaskType"
                      textField="Name"
                      placeholder={'Please Select'}
                      onChange={this.handleOnChangeInput}
                      value={this.state.newSelectedTaskType}
                      filterable={true}
                      required={true}
                      onFilterChange={this.filterOnChange}
                      disabled={
                        this.state.viewMode ||
                        this.state.activeMode ||
                        this.state.isTaskTypeEditable
                      }
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.newSelectedTaskType)}>
                        Task type is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="d-block">
                    <label className="mandatory">Start Date:</label>
                  </div>
                  <div className="d-block">
                    {this.state.newEndDate === null && (
                      <DatePicker
                        name="newStartDate"
                        onChange={this.handleStartDateChange}
                        required={true}
                        value={this.state.newStartDate}
                        format="MM/dd/yyyy"
                        min={
                          this.state.viewMode ||
                          this.state.activeMode ||
                          this.state.isStartDateEditable
                            ? new Date(this.state.newStartDate)
                            : new Date()
                        }
                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                        disabled={
                          this.state.viewMode ||
                          this.state.activeMode ||
                          this.state.isStartDateEditable
                        }
                      />
                    )}
                    {this.state.newEndDate !== null && (
                      <DatePicker
                        name="newStartDate"
                        onChange={this.handleStartDateChange}
                        required={true}
                        value={this.state.newStartDate}
                        format="MM/dd/yyyy"
                        min={
                          this.state.viewMode ||
                          this.state.activeMode ||
                          this.state.isStartDateEditable
                            ? new Date(this.state.newStartDate)
                            : new Date()
                        }
                        max={this.state.newEndDate}
                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                        disabled={
                          this.state.viewMode ||
                          this.state.activeMode ||
                          this.state.isStartDateEditable
                        }
                      />
                    )}
                    {this.state.isErrorMessageVisible ? (
                      <span className={this.validateProperty(this.state.newStartDate)}>
                        Start date is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label>End Date:</label>
                  </div>
                  <div className="d-block">
                    <DatePicker
                      name="newStartDate"
                      onChange={this.handleEndDateChange}
                      value={this.state.newEndDate}
                      format="MM/dd/yyyy"
                      disabled={this.state.noEndDate || this.state.viewMode}
                      min={this.state.newStartDate ? this.state.newStartDate : new Date()}
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                    />
                    {this.state.isErrorMessageVisible && !this.state.noEndDate ? (
                      <span className={this.validateProperty(this.state.newEndDate)}>
                        End date is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label />
                  </div>
                  <div className="d-block mt-2">
                    <input
                      type="checkbox"
                      id="noEndDate"
                      className="k-checkbox"
                      checked={this.state.noEndDate}
                      onChange={this.handleOnChangeCheckBox}
                      name="noEndDate"
                      disabled={this.state.viewMode || this.state.disableNoEndDate}
                    />
                    <label className="k-checkbox-label" htmlFor="noEndDate">
                      No End Date
                    </label>
                  </div>
                </div>
              </div>

              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="d-block">
                    <label className="mandatory">Approver:</label>
                  </div>
                  <div className="d-block">
                    <MultiSelect
                      data={this.state.newApprovers}
                      dataItemKey="id"
                      name="newApprovers"
                      textField="displayName"
                      required={true}
                      onChange={this.handleOnChangeInput}
                      placeholder={'Please Select'}
                      value={this.state.newSelectedApprover}
                      disabled={this.state.viewMode || this.state.activeMode}
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateApprovers()}>Approver is mandatory</span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label />
                  </div>
                  <div className="d-block mt-2">
                    <input
                      type="checkbox"
                      id="isApprovalNeededCheckBox"
                      className="k-checkbox"
                      checked={this.state.isApprovalNotNeeded}
                      onChange={this.handleOnChangeCheckBox}
                      name="isApprovalNotNeeded"
                      disabled={
                        this.state.viewMode ||
                        this.state.editMode ||
                        this.state.activeMode ||
                        this.state.noEndDate
                      }
                    />
                    <label className="k-checkbox-label" htmlFor="isApprovalNeededCheckBox">
                      Approval Not Needed
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label>Time Duration (hours) :</label>
                  </div>
                  <div className="d-block">
                    <DebounceInput
                      debounceTimeout={2000}
                      value={this.state.newTimeDuration}
                      onChange={this.handleTimeDuration}
                      name="newTimeDuration"
                      type="number"
                      pattern="[0-9]*"
                      className="k-textbox"
                      disabled={
                        !this.state.isApprovalNotNeeded ||
                        this.state.viewMode ||
                        this.state.editMode ||
                        this.state.activeMode
                      }
                    />
                    {this.state.isErrorMessageVisible === true && this.state.isApprovalNotNeeded ? (
                      <span className={this.validateTimeDuration()}>
                        Time duration is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="d-block">
                    <label>Recurrent Frequency:</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 d-flex align-items-center justify-content-between">
                  <div className="d-block">
                    <input
                      type="radio"
                      id="DAILY"
                      className="k-radio"
                      checked={this.state.selectedFrequency === this.state.frequency[0].code}
                      onChange={this.handleFrequency}
                      name="frequency"
                      disabled={
                        !this.state.isApprovalNotNeeded ||
                        this.state.viewMode ||
                        this.state.editMode ||
                        this.state.activeMode
                      }
                    />
                    <label className="k-radio-label" htmlFor="DAILY">
                      Daily
                    </label>
                  </div>
                  {this.state.isErrorMessageVisible === true && this.state.isApprovalNotNeeded ? (
                    <span className={this.validateFrequency()}>
                      Mandatory when approval not needed
                    </span>
                  ) : null}

                  <div className="d-block">
                    <input
                      type="radio"
                      id="WEEKLY"
                      className="k-radio"
                      checked={this.state.selectedFrequency === this.state.frequency[1].code}
                      onChange={this.handleFrequency}
                      name="frequency"
                      disabled={
                        !this.state.isApprovalNotNeeded ||
                        this.state.viewMode ||
                        this.state.editMode ||
                        this.state.activeMode
                      }
                    />
                    <label className="k-radio-label" htmlFor="WEEKLY">
                      Weekly
                    </label>
                  </div>

                  <div className="d-block">
                    <input
                      type="radio"
                      id="MONTHLY"
                      className="k-radio"
                      checked={this.state.selectedFrequency === this.state.frequency[2].code}
                      onChange={this.handleFrequency}
                      name="frequency"
                      disabled={
                        !this.state.isApprovalNotNeeded ||
                        this.state.viewMode ||
                        this.state.editMode ||
                        this.state.activeMode
                      }
                    />
                    <label className="k-radio-label" htmlFor="MONTHLY">
                      Monthly
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4 d-flex align-items-center">
                  <label className="mr-2">The</label>
                  <ComboBox
                    className="w-100"
                    data={this.state.weeks}
                    name="week"
                    textField="week"
                    value={this.state.selectedWeek}
                    placeholder={'Please Select'}
                    onChange={e => this.setState({ selectedWeek: e.target.value })}
                    disabled={
                      this.state.selectedFrequency !== 'MONTHLY' ||
                      this.state.viewMode ||
                      this.state.editMode ||
                      this.state.activeMode
                    }
                  />
                </div>
                <div className="col-md-4 d-flex align-items-center">
                  <ComboBox
                    className="w-100"
                    data={this.state.days}
                    name="days"
                    value={this.state.selectedDay}
                    textField="day"
                    onChange={e => this.setState({ selectedDay: e.target.value })}
                    placeholder={'Please Select'}
                    disabled={
                      this.state.selectedFrequency !== 'MONTHLY' ||
                      this.state.viewMode ||
                      this.state.editMode ||
                      this.state.activeMode
                    }
                  />
                </div>
                <label>of every month</label>
              </div>

              <div className="row">
                <div className="col-md-4">
                  {this.state.isErrorMessageVisible === true && this.state.isApprovalNotNeeded ? (
                    <span className={this.validateMonthlyFrequencyWeek()}>
                      Mandatory when selected Monthly
                    </span>
                  ) : null}
                </div>
                <div className="col-md-4">
                  {this.state.isErrorMessageVisible === true && this.state.isApprovalNotNeeded ? (
                    <span className={this.validateMonthlyFrequencyDay()}>
                      Mandatory when selected Monthly
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="d-block">
                    <label className="k-form-field">
                      <input
                        type="checkbox"
                        id="IsWithAllocation"
                        className="k-checkbox"
                        checked={this.state.IsWithAllocation}
                        onChange={this.handleOnChangeCheckBox}
                        name="IsWithAllocation"
                        disabled={
                          this.state.viewMode ||
                          this.state.activeMode ||
                          this.state.noEndDate
                        }
                      />
                      <label className="k-checkbox-label" htmlFor="IsWithAllocation">
                        With Allocation
                      </label>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-0">
                <div className="col-md-4">
                  <div className="d-block">
                    <DebounceInput
                      debounceTimeout={2000}
                      value={this.state.newAllocation}
                      onChange={this.handleTimeDuration}
                      name="newAllocation"
                      type="number"
                      pattern="[0-9]*"
                      className="k-textbox"
                      disabled={
                        !this.state.IsWithAllocation ||
                        this.state.viewMode ||
                        this.state.activeMode
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    className="k-checkbox-label position-absolute ml-n4 mt-2"
                    htmlFor="IsWithAllocation"
                  >
                    %
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="d-block">
                    <label>Assign To:</label>
                  </div>
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.selectedAssignedTo)}>
                      Please select an option under "Assign to" to assign the relevant users
                    </span>
                  ) : null}
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateDepartment()}>
                      Mandatory when selected department
                    </span>
                  ) : null}
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validatePractice()}>
                      Mandatory when selected practice
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="d-block">
                    <input
                      type="radio"
                      id="ALL"
                      className="k-radio"
                      checked={this.state.selectedAssignedTo === this.state.assignedTo[0].code}
                      onChange={this.handleAssignedTo}
                      disabled={this.state.editMode || this.state.viewMode || this.state.activeMode}
                      name="assignedTo"
                    />
                    <label className="k-radio-label" htmlFor="ALL">
                      All Resources
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-block">
                    <input
                      type="radio"
                      id="DEPLOYABLE"
                      className="k-radio"
                      checked={this.state.selectedAssignedTo === this.state.assignedTo[1].code}
                      onChange={this.handleAssignedTo}
                      disabled={this.state.editMode || this.state.viewMode || this.state.activeMode}
                      name="assignedTo"
                    />
                    <label className="k-radio-label" htmlFor="DEPLOYABLE">
                      All Deployable Resources
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <div className="d-block">
                    <input
                      type="checkbox"
                      id="DEPARTMENT"
                      className="k-checkbox"
                      checked={this.state.isSelectedDepartment}
                      onChange={this.handleAssignedToDepartment}
                      disabled={this.state.editMode || this.state.viewMode || this.state.activeMode}
                      name="assignedTo"
                    />
                    <label className="k-checkbox-label" htmlFor="DEPARTMENT">
                      Department
                    </label>
                  </div>
                  <div className="d-block mt-2">
                    <MultiSelect
                      data={this.state.department}
                      dataItemKey="id"
                      name="newDepartment"
                      textField="Name"
                      placeholder={'Please Select'}
                      onChange={this.handleOnChangeInput}
                      value={this.state.newSelectedDepartment}
                      defaultValue={this.state.newSelectedDepartment}
                      disabled={
                        !this.state.isSelectedDepartment ||
                        this.state.editMode ||
                        this.state.viewMode ||
                        this.state.activeMode
                      }
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-block">
                    <input
                      type="checkbox"
                      id="PRACTICE"
                      className="k-checkbox"
                      checked={this.state.isSelectedPractice}
                      onChange={this.handleAssignedToPractice}
                      disabled={this.state.editMode || this.state.viewMode || this.state.activeMode}
                      name="assignedTo"
                    />
                    <label className="k-checkbox-label" htmlFor="PRACTICE">
                      Practice
                    </label>
                  </div>
                  <div className="d-block mt-2">
                    <MultiSelect
                      data={this.state.selectedpracticeData}
                      dataItemKey="id"
                      name="newPractice"
                      textField="Name"
                      placeholder={'Please Select'}
                      onChange={this.handleOnChangeInput}
                      value={this.state.newSelectedPractice}
                      defaultValue={this.state.newSelectedPractice}
                      disabled={
                        !this.state.isSelectedPractice ||
                        this.state.editMode ||
                        this.state.viewMode ||
                        this.state.activeMode
                      }
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-block">
                    <input
                      type="checkbox"
                      id="RES_REGION"
                      className="k-checkbox"
                      checked={this.state.isSelectedResourceRegion}
                      onChange={this.handleAssignedToResourceRegion}
                      disabled={this.state.editMode || this.state.viewMode || this.state.activeMode}
                      name="assignedTo"
                    />
                    <label className="k-checkbox-label" htmlFor="RES_REGION">
                      Resource Region
                    </label>
                  </div>
                  <div className="d-block mt-2">
                    <MultiSelect
                      data={this.state.regions}
                      dataItemKey="id"
                      name="newRegion"
                      textField="Name"
                      placeholder={'Please Select'}
                      onChange={this.handleOnChangeInput}
                      value={this.state.newSelectedRegion}
                      defaultValue={this.state.newSelectedRegion}
                      disabled={
                        !this.state.isSelectedResourceRegion ||
                        this.state.editMode ||
                        this.state.viewMode ||
                        this.state.activeMode
                      }
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateRegion()}>Mandatory when selected region</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-block">
                    <input
                      type="checkbox"
                      id="ALLOC_REGION"
                      className="k-checkbox"
                      checked={this.state.isSelectedAllocatedRegion}
                      onChange={this.handleAssignedToAllocatedRegion}
                      disabled={this.state.editMode || this.state.viewMode || this.state.activeMode}
                      name="assignedTo"
                    />
                    <label className="k-checkbox-label" htmlFor="ALLOC_REGION">
                      Resource Assignment
                    </label>
                  </div>
                  <div className="d-block mt-2">
                    <MultiSelect
                      data={this.state.allocatedRegions}
                      dataItemKey="id"
                      name="newAllocatedRegion"
                      textField="Name"
                      placeholder={'Please Select'}
                      onChange={this.handleOnChangeInput}
                      value={this.state.newSelectedAllocatedRegion}
                      defaultValue={this.state.newSelectedAllocatedRegion}
                      disabled={
                        !this.state.isSelectedAllocatedRegion ||
                        this.state.editMode ||
                        this.state.viewMode ||
                        this.state.activeMode
                      }
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateAlloctedRegion()}>Mandatory when selected allocated region</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-block">
                    <input
                      type="radio"
                      id="INDIVIDUAL"
                      className="k-radio"
                      checked={this.state.selectedAssignedTo === this.state.assignedTo[4].code}
                      onChange={this.handleAssignedTo}
                      disabled={this.state.editMode || this.state.viewMode || this.state.activeMode}
                      name="assignedTo"
                    />
                    <label className="k-radio-label" htmlFor="INDIVIDUAL">
                      Individuals
                    </label>
                  </div>
                  <div className="d-block mt-2">
                    <MultiSelect
                      data={this.state.individuals}
                      dataItemKey="id"
                      textField="displayName"
                      name="individualUser"
                      onChange={this.handleOnChangeInput}
                      defaultValue={this.state.newSelectedIndividuals}
                      value={this.state.newSelectedIndividuals}
                      placeholder="Please Select"
                      disabled={
                        this.state.selectedAssignedTo !== this.state.assignedTo[4].code ||
                        this.state.viewMode ||
                        this.state.editMode ||
                        this.state.activeMode
                      }
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateIndividualAssignment()}>
                        Mandatory when selected individuals
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <DialogActionsBar>
                {!this.state.viewMode && (
                  <button
                    className="k-button "
                    onClick={() => this.toggleCancelPopUpNonprojectTask()}
                  >
                    Cancel
                  </button>
                )}
                <button
                  className="k-button modal-primary"
                  onClick={event => {
                    if (this.state.editMode) {
                      this.handleEditSubmit(event);
                    } else if (this.state.viewMode) {
                      this.toggleAddDialog();
                    } else if (this.state.activeMode) {
                      this.reActivateTask();
                    } else {
                      this.handleSubmitCreate(event);
                    }
                  }}
                  disabled={this.state.isDisableSave}
                >
                  {!this.state.viewMode || this.state.activeMode ? 'Save' : 'OK'}
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.showConfirmDialog && (
            <Dialog title={'Confirm'} onClose={this.toggleConfirmDialog} width="400px">
              <p style={{ margin: '25px', textAlign: 'center' }}>
                {this.state.confirmDialogMessage}
              </p>

              <DialogActionsBar>
                <button className="k-button" onClick={this.toggleConfirmDialog}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.visible === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">
              {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success" />
                </div>
              )}

              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image" />
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

              <DialogActionsBar>
                {this.state.showSuccessImage === true && (
                  <button className="k-button modal-primary" onClick={this.navigateBack}>
                    OK
                  </button>
                )}

                {this.state.showErrorImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleDialog}>
                    OK
                  </button>
                )}
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.showDepartmentValidateDialog === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleDepartmentValidateDialog} width="400px">
              {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success" />
                </div>
              )}

              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image" />
                </div>
              )}
              <p style={{ margin: '20px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
              {this.state.invalidDepartments && this.state.invalidDepartments.length > 0 && (
                <p style={{ margin: '10px', textAlign: 'center' }}>
                  {this.state.invalidDepartments.map(i => {
                    return (
                      <li key={i.id}>{i.Name}</li>
                    );
                  })}
                </p>
              )}

              <DialogActionsBar>
                <button className="k-button modal-primary" onClick={this.toggleDepartmentValidateDialog}>
                  OK
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.sameUserValidationVisible === true && (
            <Dialog
              title={this.state.dialogTitle}
              onClose={this.toggleDialogSameUserValidation}
              width="400px"
            >
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.toggleDialogSameUserValidation}
                >
                  OK
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        {/* Add/Edit Default Task Modal */}
        {this.state.addEditDefaultTaskVisible ? (
          <Dialog title={'View Resources'} width={700} onClose={this.toggleAddEditDefaultTask}>
            <div className="row">
              <div className="col-md-4">
                <div className="d-block">
                  <label>Task Name:</label>
                </div>
                <div className="d-block">
                  <Input
                    disabled
                    value={ this.state.allcationGridLoader === true ? '':
                      this.state.currentAllowcation.length !== 0 &&
                      this.state.currentAllowcation[0].Name
                    }
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="d-block">
                  <label>Task Type:</label>
                </div>
                <div className="d-block">
                  <Input
                    disabled
                    value={this.state.allcationGridLoader === true ? '':
                      this.state.currentAllowcation.length !== 0 &&
                      this.state.currentAllowcation[0].TaskType.Name
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mt-2">
                {this.state.allcationGridLoader === true ? (
                  <div className="k-loading-mask">
                    <span className="k-loading-text">Loading</span>
                    <div className="k-loading-image" />
                    <div className="k-loading-color" />
                  </div>
                ) : (
                  <div />
                )}
                <Grid resizable data={this.state.allowcationList} style={{ height: '400px' }}>
                  <Column field="Resource" title="Resource" width="" />
                  <Column field="Date" title="Date" width="" />
                  <Column field="Allocation" title="Allocation (Hrs)" width="" />
                  <Column field="Utilization" title="Current Utilization (Hrs)" width="" />
                  <Column field="Status" title="Status" width="" />
                </Grid>
              </div>
            </div>
          </Dialog>
        ) : null}

        {/* Add/Edit Resources of Non-Project Task Modal */}
        {/* <Dialog title={'Add/Edit Resources of Non-Project Task'} width={700}>
          <div className="row">
            <div className="col-md-4">
              <div className="d-block">
                <label>Task Name:</label>
              </div>
              <div className="d-block">
                <Input disabled />
              </div>
            </div>

            <div className="col-md-4">
              <div className="d-block">
                <label>Task Type:</label>
              </div>
              <div className="d-block">
                <Input disabled />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="d-block">
                <label>Resource:</label>
              </div>
              <div className="d-block">
                <ComboBox />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="button">
                Add
              </Button>
              <Button type="button">Remove</Button>
              <Button type="button">Clear</Button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mt-2">
              <Grid resizable>
                <Column title="Resource" width="" />
                <Column title="Date" width="" />
                <Column title="Allocation (Hrs)" width="" />
                <Column title="Utilization So Far (Hrs)" width="" />
                <Column title="Status" width="" />
              </Grid>
            </div>
          </div>
          <DialogActionsBar>
            <button className="k-button ">Cancel</button>
            <button className="k-button modal-primary">Save</button>
          </DialogActionsBar>
        </Dialog> */}

        {this.state.bulkApproverReplacement && (
          <Dialog
            title={'Bulk Approver Replacement'}
            onClose={this.toggleBulkApproverReplacementDialog}
            width="400px"
          >
            <div className="row">
              <div className="col-md-12">
                <div className="d-block">
                  <label>Existing Approver:</label>
                </div>
                <div className="d-block">
                  <ComboBox
                    data={this.state.existingApprovers}
                    name="existingApprover"
                    textField="displayName"
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}
                    value={this.state.selectedExistingApprover}
                    filterable={true}
                    onFilterChange={this.filterOnChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="d-block">
                  <label>New Approver:</label>
                </div>
                <div className="d-block">
                  <ComboBox
                    data={this.state.newReplacingApprovers}
                    name="newReplacingApprover"
                    textField="displayName"
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}
                    value={this.state.newRepSelectedApprover}
                    filterable={true}
                    onFilterChange={this.filterOnChange}
                  />
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleBulkApproverReplacementDialog}>
                Cancel
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.onClickConfirm}
                disabled={this.state.isDisableReplaceSave}
              >
                Save
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.bulkResourceRemoval && (
          <Dialog
            title={'Bulk Resource Removal'}
            onClose={this.toggleBulkReourceRemovalDialog}
            width="400px"
          >
            <div className="row">
              <div className="col-md-12">
                <div className="d-block">
                  <label>Resource(s) to be Removed:</label>
                </div>
                <div className="d-block overflow-auto" style={{ maxHeight: '300px' }}>
                  <MultiSelect
                    data={this.state.existingApprovers}
                    name="removalApprovers"
                    textField="displayName"
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}
                    value={this.state.selectedRemovalApprovers}
                    defaultValue={this.state.selectedRemovalApprovers}
                    filterable={true}
                    onFilterChange={this.filterOnChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="d-block">
                  <label>Date:</label>
                </div>
                <div className="d-block">
                  <DatePicker
                    name="removalDate"
                    onChange={this.handleRemovalDateChange}
                    value={this.state.resourceRemovalDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                    disabled={!this.state.isBulkApprovalNotNeeded}
                  />
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleBulkReourceRemovalDialog}>
                Cancel
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.onClickResourceRemovalConfirm}
                disabled={this.state.isDisableRemovalSave}
              >
                Save
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.rescAddVisible === true && (
          <Dialog
            title="Bulk Resource Addition"
            onClose={this.toggleResourceAdditionDialog}
            width="400px"
          >
            <p style={{ margin: '25px' }}>
              {' '}
              Successfully assigned resources for {this.state.successTasksCount} out of{' '}
              {this.state.nptSelectedCount} Non-Project task/s.
            </p>

            {this.state.failedArray.length > 0 && (
              <span>
                <p style={{ margin: '25px' }}>
                  Please verify if the resources selected are valid to assign for Non Project Task/s
                  listed below.
                </p>
                <ul>
                  {this.state.failedArray.map(task => (
                    <div>
                      <li>
                        <div className="row">
                          <div className="col-8">{task}</div>
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              </span>
            )}
            <DialogActionsBar>
              <button
                className="k-button modal-primary"
                onClick={this.toggleResourceAdditionDialog}
              >
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.resApproversVisible === true && (
          <Dialog
            title="Bulk Resource Addition"
            onClose={this.toggleApproverAdditionDialog}
            width="400px"
          >
            <p style={{ margin: '25px' }}>
              {' '}
              Successfully assigned approvers for {this.state.successTasksCount} out of{' '}
              {this.state.nptSelectedCount} Non-Project task/s.
            </p>

            {this.state.failedArray.length > 0 && (
              <span>
                <p style={{ margin: '25px' }}>
                  Please verify if the resources selected are valid to assign for Non Project Task/s
                  listed below.
                </p>
                <ul>
                  {this.state.failedArray.map(task => (
                    <div>
                      <li>
                        <div className="row">
                          <div className="col-8">{task}</div>
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              </span>
            )}
            <DialogActionsBar>
              <button
                className="k-button modal-primary"
                onClick={this.toggleApproverAdditionDialog}
              >
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.resApproverRemovalVisible === true && (
          <Dialog
            title="Bulk Resource Addition"
            onClose={this.toggleApproverRemovalDialog}
            width="400px"
          >
            <p style={{ margin: '25px' }}>
              {' '}
              Successfully removed approvers {this.state.successTasksCount} out of{' '}
              {this.state.nptSelectedCount} Non-Project task/s.
            </p>

            {this.state.failedArray.length > 0 && (
              <span>
                <p style={{ margin: '25px' }}>
                  Cannot remove all approvers from a Non Project Task. 
                  Please verify if the existing approvers are the only approvers for the Non Project Task/s listed below.
                </p>
                <ul>
                  {this.state.failedArray.map(task => (
                    <div>
                      <li>
                        <div className="row">
                          <div className="col-8">{task}</div>
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              </span>
            )}
            <DialogActionsBar>
              <button
                className="k-button modal-primary"
                onClick={this.toggleApproverRemovalDialog}
              >
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.nptReplaceResponseDialogVisible === true && (
          <Dialog
            title="Bulk Approver Replacement"
            onClose={this.toggleApproverReplaceResponseDialog}
            width="400px"
          >
            <p style={{ margin: '25px' }}>
              {' '}
              Successfully {this.state.actionText} {this.state.successTasksCount} out of{' '}
              {this.state.nptSelectedCount} Non-Project task/s with the new approver{' '}
              {this.state.newRepSelectedApprover.displayName}
            </p>

            {this.state.failedArray.length > 0 && (
              <span>
                <p style={{ margin: '25px' }}>
                  Please verify if the existing and/or new approver selected are valid for the Non
                  Project Task/s listed below
                </p>
                <ul>
                  {this.state.failedArray.map(task => (
                    <div>
                      <li>
                        <div className="row">
                          <div className="col-8">{task}</div>
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              </span>
            )}

            <DialogActionsBar>
              <button
                className="k-button modal-primary"
                onClick={this.toggleApproverReplaceResponseDialog}
              >
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.nptResourceRemovalRespDialogVisible === true && (
          <Dialog
            title="Bulk Resource Removal"
            onClose={this.toggleResourceRemovalResponseDialog}
            width="400px"
          >
            <p style={{ margin: '25px' }}>
              {' '}
              Successfully removed resources {this.state.successTasksCount} out of{' '}
              {this.state.nptSelectedCount} Non-Project task/s.
            </p>

            {this.state.failedArray.length > 0 && (
              <span>
                <p style={{ margin: '25px' }}>
                  Please verify if the existing resources selected are valid for the Non Project
                  Task/s listed below
                </p>
                <ul>
                  {this.state.failedArray.map(task => (
                    <div>
                      <li>
                        <div className="row">
                          <div className="col-8">{task}</div>
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              </span>
            )}

            <DialogActionsBar>
              <button
                className="k-button modal-primary"
                onClick={this.toggleResourceRemovalResponseDialog}
              >
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.bulkresourceassignment && (
          <Dialog
            title={'Bulk Resource Assignment'}
            onClose={this.toggleBulkRescAssignmentDialog}
            width="400px"
          >
            <div className="row">
              <div className="col-md-12">
                <div className="d-block">
                  <label>Resource(s) to be added:</label>
                </div>
                <div className="d-block">
                  <MultiSelect
                    data={this.state.assignedResources}
                    name="assignedResources"
                    textField="displayName"
                    id="id"
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}
                    value={this.state.selectedAssignedResource}
                    defaultValue={this.state.selectedAssignedResource}
                    filterable={true}
                    onFilterChange={this.filterOnChange}
                  />
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleBulkRescAssignmentDialog}>
                Cancel
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.handleSaveResourceforTasks}
                disabled={this.state.isDisableResourceSave}
              >
                Save
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.bulkApproverAssignment && (
          <Dialog
            title={'Bulk Approver Assignment'}
            onClose={this.toggleBulkApproverAssignmentDialog}
            width="400px"
          >
            <div className="row">
              <div className="col-md-12">
                <div className="d-block">
                  <label>Approver(s) to be added:</label>
                </div>
                <div className="d-block">
                  <MultiSelect
                    data={this.state.newAssignedApprovers}
                    name="assignedApprovers"
                    textField="displayName"
                    id="id"
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}
                    value={this.state.selectedAssignedApprover}
                    filterable={true}
                    onFilterChange={this.filterOnChange}
                  />
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleBulkApproverAssignmentDialog}>
                Cancel
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.handleSaveApproverAssignedForTasks}
                disabled={this.state.isDisableResourceSave}
              >
                Save
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.bulkApproverRemoval && (
          <Dialog
            title={'Bulk Approver Removal'}
            onClose={this.toggleBulkApproverRemovalDialog}
            width="400px"
          >
            <div className="row">
              <div className="col-md-12">
                <div className="d-block">
                  <label>Approver(s) to be Removed:</label>
                </div>
                <div className="d-block">
                  <MultiSelect
                    data={this.state.approverRemovalUsers}
                    name="approverRemoval"
                    textField="displayName"
                    id="id"
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}
                    value={this.state.selectedApproverRemoval}
                    filterable={true}
                    onFilterChange={this.filterOnChange}
                  />
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleBulkApproverRemovalDialog}>
                Cancel
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.handleSaveRemovedApproversForTasks}
                disabled={this.state.isDisableResourceSave}
              >
                Save
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.deactivatePopupVisible && (
          <Dialog title={'Please Confirm'} onClose={this.deactivateConfirmationClose}>
            <p style={{ margin: '25px', textAlign: 'center' }}>
              Are you sure you want to Deactivate this Task?
            </p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.deactivateConfirmationClose}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.deactivateConfirmationOk}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.cancelNonProjectTaskPopupVisible && (
          <Dialog title={'Please Confirm'} onClose={() => this.confirmCancelNo()}>
            <p style={{ margin: '25px', textAlign: 'center' }}>
              This form contains unsaved data. Do you want to close it?
            </p>
            <DialogActionsBar>
              <button className="k-button" onClick={() => this.confirmCancelNo()}>
                No
              </button>
              <button className="k-button modal-primary" onClick={() => this.confirmCancelYes()}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.activatePopupVisible && (
          <Dialog title={'Please Confirm'} onClose={this.activateConfirmationClose}>
            <p style={{ margin: '25px', textAlign: 'center' }}>
              Are you sure you want to activate this Task?
            </p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.activateConfirmationClose}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.activateConfirmationOk}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.deletePopupVisible && (
          <Dialog title={'Please Confirm'} onClose={this.deleteConfirmationClose}>
            <p style={{ margin: '25px', textAlign: 'center' }}>
              Are you sure you want to Delete this Task?
            </p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.deleteConfirmationClose}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.deleteConfirmationOk}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default NonProjectTaskSearch;
