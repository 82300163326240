import React, { Component } from "react";
import { Link } from "react-router-dom";

class DeleteCell extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { OnDelete } = this.props;

    return (
      <td class="action-buttons">
        <div class="rounded-link">
          <Link
            to={this.props}
            onClick={event => OnDelete(event, this.props.dataItem.id)}
          >
          <span class="k-icon k-i-delete" title="Delete"/>
          </Link>
        </div>
      </td>
    );
  }
}

export default DeleteCell;
