import React, { Component } from "react";

class AllocatedResourceCell extends Component {

  toggleDialogResourceSearch = event => {
    event.preventDefault();
    this.props.toggleDialogResourceSearch(this.props.dataItem, "OPEN");
  };


  render() {
    return(
      (this.props.dataItem.locationActionAttribute == "RPAllocateWF" ? (
          <td>
            {this.props.dataItem.AllocatedResource ? (this.props.dataItem.AllocatedResource.displayName ? <div className="addoreditresource">{this.props.dataItem.AllocatedResource.displayName}</div> : <div className="addoreditresource">{this.props.dataItem.AllocatedResource.Name}</div>) : <div></div>}
          </td>
        ) : (
          <td  onClick={this.toggleDialogResourceSearch}>
            {this.props.dataItem.AllocatedResource ? ( this.props.dataItem.AllocatedResource.displayName ? <div className="addoreditresource">{this.props.dataItem.AllocatedResource.displayName}</div> : <div className="addoreditresource">{this.props.dataItem.AllocatedResource.Name}</div>) : <div className="addoreditresource font-weight-bold">Add Resource</div>}
          </td>
        )
      )
    );
  }

}

export default AllocatedResourceCell;
