import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import {
    updateDesignationStatus,
    searchDesignations,
    getCostCardDesignations,
    saveActivatedDesignationsCostCard,
    getRateCardRoles,
    saveActivatedDesignationRateCard, getAllDesignations,
    getDesignations
} from './DesignationService';

import {getRateCardsForGridIntegrated, searchCostCardsIntegrated, getDesignationGroupsIntegrated} from '../../integration/MasterDataService'

import CreatedDateCell from './components/CreatedDateCell';
import UpdatedDateCell from './components/UpdatedDateCell';
import StatusCell from './components/StatusCell';
import EditDesignationActionsCell from './components/EditDesignationActionsCell';
import ProjectRoleCell from './components/ProjectRoleCell';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';

import { Link } from 'react-router-dom';
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

class Designation extends Component {

  isMount = false;

  async componentDidMount() {
    this.isMount = true;
    window.scrollTo(0, 0);
    this.setHeaderTitle();
    this.populateCostDesignation();
    this.populateDesignations();
    this.populateCostCardDesignations();

    await getDesignationGroupsIntegrated()
      .then(response => {
        this.designationGroupDataAll = response.data.filter(el => el.IsActive === true && el.SFCode !== null);
        if (this.isMount) {
          this.setState({
            designationGroupData: response.data.filter(el => el.IsActive === true && el.SFCode !== null)
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      designations: [],
      costDesignationsAll: [],
      costDesignations: [],
      skip: 0,
      take: 20,
      SearchRequest: {
        DesignationName: '',
        searchStatus: { Name: 'Active', status: true },
        isProjectRole: false,
        designationGroupValue: []
      },
      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      deactivateDesignationId: 0,
      deactivatePopupVisible: false,
      status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
      searchState: false,
      activateDesignationId: 0,
      activatePopupVisible: false,
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      loading: false

    };
    this.grid = null;

    this.handleChangeDesignationGroup = this.handleChangeDesignationGroup.bind(this);
  }

  populateCostCardDesignations = () => {
    getCostCardDesignations().then(res => {
      console.log(res);
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
     });
  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  handleChangeDesignationGroup = async event => {
    if (this.isMount) {
      const field = event.target.name;
      const value = event.target.value;

      this.setState( prevState => {
        return {
          SearchRequest: { ...prevState.SearchRequest, [field]: value }
        };
      });
    }
  };

  populateCostDesignation = () => {
    getDesignations().then(res => {
      if (this.isMount) {
        this.setState({
          costDesignations: res.data,
          costDesignationsAll: res.data
        });
      }
    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  }
  
  populateDesignations = () => {
      searchDesignations(this.state.SearchRequest).then(response => {
        if (this.isMount) {
          this.setState({
            designations: response.data
          });
        }
      })    
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  filterChangeDesignationGroup = event => {
    if (this.isMount) {
      this.setState({
        designationGroupData: this.filterDataDesignationGroup(event.filter)
      });
    }
  };
  
  filterDataDesignationGroup(filter) {
    const data = this.designationGroupDataAll.slice();
    return filterBy(data, filter);
  }

  toggleChange = (event) => {
    const field = event.target.name;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    this.setState( prevState => {
      return {
        SearchRequest: { ...prevState.SearchRequest, [field]: value }
      };
    });
  }

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  filterOnChange = event => {
    clearTimeout(this.timeout);
    const field = event.target.name;
    this.timeout = setTimeout(() => {
      switch (field) {
        case 'DesignationName': {
          if (this.isMount) {
            this.setState({
              costDesignations: this.filterComboData(event.filter, this.state.costDesignationsAll)
            });
          }
          break;
        }
        default: {
          break;
        }
      }
    }, 0)
  }

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Cost Designation Search</div>
          </div>
          <div className="col-md-4">
            <Button primary={true} className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('ED_DEG') > -1)}>
              <Link
                style={{ textDecoration: 'none', color: 'white' }}
                to={{
                  pathname: '/admin/createdesignation/createdesignation',
                  action: 'create'
                }}
              >
                Create New Cost Designation
              </Link>
            </Button>
          </div>
        </div>

        <form onSubmit={this.submitSearch}>
          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                  <label>Cost Designation Name:</label>
                </div>
                <div className="d-block">
                <div className="">     
                  <Input
                    name="DesignationName"
                    value={this.state.SearchRequest.DesignationName}
                    onChange={this.handleOnChangeInput}
                    // textField="Name"
                    // dataItemKey="id"
                    // data={this.state.costDesignations}
                    // placeholder={'Please Select'}
                    // filterable={true}
                    // onFilterChange={this.filterOnChange}
                  /> 
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                  <label className="">Designation Group:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <MultiSelect
                    placeholder="Please Select"
                    name="designationGroupValue"
                    data={this.state.designationGroupData}
                    value={this.state.SearchRequest.designationGroupValue}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleChangeDesignationGroup}
                    filterable={true}
                    onFilterChange={this.filterChangeDesignationGroup}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                  <label>Status:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                    name="searchStatus"
                    textField="Name"
                    dataItemKey="status"
                    value={this.state.SearchRequest.searchStatus}
                    data={this.state.status}
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label"></label>
              </div>
              <div className="d-block">
                <fieldset>
                  <label className="k-form-field">
                    <input
                      type="checkbox"
                      id="auth-1rps"
                      name="isProjectRole"
                      className="k-checkbox"
                      checked={this.state.SearchRequest.isProjectRole}
                      onChange={this.toggleChange }/>
                    <label className="k-checkbox-label" htmlFor="auth-1rps">Project Role</label>
                  </label>
                </fieldset>
              </div>
            </div>
            </div>
            <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
                Clear
              </Button>
            </div>
            </div>
          

          <div className="main-seperator"/>

          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">Search Results</div>
            </div>
          </div>

          <div className="row mt-0">
            <div className="col-md-12">
              <Grid
                data={orderBy(this.state.designations, this.state.sort).slice(
                  this.state.skip,
                  this.state.take + this.state.skip
                )}
                ref={(g) => {
                  this.grid = g;
                }}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.designations.length}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }
                }
                className="search-result-grid"
              >
                <Column field="Name" title="Cost Designation"/>
                <Column
                  field="IsProjectRole"
                  title="Project Role"
                  width="100px"
                  cell={props => <ProjectRoleCell {...props} />}
                />
                <Column field="DesignationCategory.Name" title="Group"/>
                <Column
                  field="createdAt"
                  title="Created Date"
                  width="120px"
                  cell={props => <CreatedDateCell {...props} />}
                />
                <Column
                  field="updatedAt"
                  title="Last Modified Date"
                  width="120px"
                  cell={props => <UpdatedDateCell {...props} />}
                />
                <Column field="updatedUser" title="Last Modified User" width="120px"  />
                <Column field="IsActive" title="Status" width="100px" cell={props => <StatusCell {...props} />}/>
                <Column
                  field=""
                  title="Actions"
                  width="150px"
                  cell={props => (
                    <EditDesignationActionsCell
                      {...props}
                      OnDeactivate={this.OnDeactivate}
                      OnActivate={this.OnActivate}
                    />
                  )}
                />
              </Grid>
            </div>
          </div>
          <div>
            {this.state.deactivatePopupVisible && (
              <Dialog title={'Please Confirm'} onClose={this.deactivateConfirmationClose}>
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to Deactivate this?
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.deactivateConfirmationClose}>
                    No
                  </button>
                  <button
                    className="k-button modal-primary"
                    onClick={this.deactivateConfirmationOk}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>

          <div>
            {this.state.showMessageDialog === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error"/>
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.activatePopupVisible && (
              <Dialog title={'Please Confirm'} onClose={this.activateConfirmationClose}>
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to Activate this?
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.activateConfirmationClose}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.activateConfirmationOk}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </form>
        <Loader loading={this.state.loading}/>
      </div>
    );
  }

  setHeaderTitle = async () => {
    this.props.onHeaderTitleChange('Create Cost Designation');
  };

  submitSearch = (event) => {
    if(event){
      event.preventDefault();
      if (this.isMount) {
        this.setState({
          loading: true
        });
      }
    }
      
    this.setState(
      prevState => {
        const SRequest = {
          ...prevState.SearchRequest
        };
        return {
          SearchRequest: SRequest
        };
      },
      () => {
        searchDesignations(this.state.SearchRequest).then(res => {
          this.setState({
            designations: res.data,
            loading: false,
            skip: 0
          });
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.setState({
            loading: false
          });
        });
      }
    );
  };

  cancelSearch = event => {
    if (this.isMount) {
      this.setState({
        SearchRequest: {
          DesignationName: '',
          searchStatus: null,
          isProjectRole: false,
          designationGroupValue: []
        }
      });
    }
    this.submitSearch(null);
  };

  handleOnChangeInput = event => {
    const field = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (this.isMount) {
      this.setState(prevState => {
        return {
          SearchRequest: { ...prevState.SearchRequest, [field]: value }
        };
      });
    }
  };

  OnDeactivate = async (event, id) => {
    event.preventDefault();
    if (this.isMount) {
      await this.setState({
        deactivateDesignationId: id
      });
    }
    this.deactivateConfirmationClose();
  };

  deactivateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: !this.state.deactivatePopupVisible
      });
    }
  };

  deactivateConfirmationOk = async () => {
    await updateDesignationStatus({ IsActive: false }, this.state.deactivateDesignationId)
      .then(response => {
        this.toggleMessageDialog('The Designation has been Deactivated successfully', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.populateDesignations();
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: false,
        showSuccessImage: true,
        showErrorImage: false
      });
    }
  };

  OnActivate = async (event, id) => {
    event.preventDefault();
    if (this.isMount) {
      await this.setState({
        activateDesignationId: id
      });
    }
    this.activateConfirmationClose();
  };

  activateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        activatePopupVisible: !this.state.activatePopupVisible
      });
    }
  };

  activateConfirmationOk = async () => {
    let costCardDesignations = [];
    let costCardDesignationsid = [];
    let costcardfilteredArray = [];
    let ratecardfilteredArray = [];
    let costcardid = [];
    let recordId;
    let allCostCards = [];
    let allRateCards = [];
    let ratecardid = [];
    let ratecardRoles = [];
    let ratecardRolesIds = [];
    let recordIdWithProRole = 0;
    let allDesignations = 0;

    await updateDesignationStatus({ IsActive: true }, this.state.activateDesignationId)
      .then(response => {
        recordId = this.state.activateDesignationId;
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    //cost cards
    const fields = {};
    await searchCostCardsIntegrated(fields).then(res => {
      allCostCards = res.data;
      for (const item of allCostCards) {
        costcardid.push(item.id);
      }
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });

    await getCostCardDesignations().then(res => {
      costCardDesignations = res.data;
      let records = costCardDesignations.filter(obj => obj.DesignationId === recordId);
      for (const item of records) {
        costCardDesignationsid.push(item.CostCardId);
      }

      let uniquecostIds = costcardid.filter(function(obj) {
        return costCardDesignationsid.indexOf(obj) === -1;
      });

      if (uniquecostIds.length > 0) {
        for (const record of uniquecostIds) {
          const costcardDesignation = {
            CostCardId: record,
            DesignationId: recordId,
            Overhead: 0,
            CostToCompany: 0
          };
          costcardfilteredArray.push(costcardDesignation);
        }

        saveActivatedDesignationsCostCard(costcardfilteredArray);
      }
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });

    await getRateCardsForGridIntegrated().then(res => {
      allRateCards = res.data;

      for (const ratecard of allRateCards) {
        ratecardid.push(ratecard.id);
      }
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });

    await getAllDesignations().then(res => {
      allDesignations = res.data
      for (const desig of allDesignations) {
        if(desig.id === recordId && desig.IsProjectRole === true){
            recordIdWithProRole = recordId;
        }
      }
    })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      if(recordIdWithProRole !== 0) {
          await getRateCardRoles()
              .then(res => {
                  ratecardRoles = res.data;
                  let extractedArray = ratecardRoles.filter(obj => obj.RoleId === recordIdWithProRole);
                  if (extractedArray.length > 0) {
                      for (const rec of extractedArray) {
                          ratecardRolesIds.push(rec.RateCardId);
                      }
                  }
                  let uniquerateIds = ratecardid.filter(function (obj) {
                      return ratecardRolesIds.indexOf(obj) === -1;
                  });

                  if (uniquerateIds.length > 0) {
                      for (const detail of uniquerateIds) {

                          const ratecardDesignation = {
                              RateCardId: detail,
                              RoleId: recordIdWithProRole,
                              Rate: 0
                          };

                          ratecardfilteredArray.push(ratecardDesignation);
                      }

                      saveActivatedDesignationRateCard(ratecardfilteredArray);
                  }
              })
              .catch(error => {
                  loggerService.writeLog(error, LOG_TYPES.ERROR);
              });

      }

    await this.populateDesignations();
    if (this.isMount) {
      this.setState({
        showSuccessImage: true,
        showErrorImage: false
      });
    }
    this.toggleMessageDialog('The Designation has been Activated successfully', 'Success');
    if (this.isMount) {
      await this.setState({
        activatePopupVisible: false
      });
    }
  };
}

export default Designation;
