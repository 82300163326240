import React, {Component} from 'react';
import ReactQuill from 'react-quill';
class Quil extends Component {
    constructor(props) {
        super(props);
        this.quillRef = null;
        this.reactQuillRef = null;
    }

    componentDidMount() {
        this.attachQuillRefs();
    }

    componentDidUpdate() {
        this.attachQuillRefs();
    }

    attachQuillRefs = () => {
        if (!this.reactQuillRef || typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();
    }

    handlePMCommentChange = (e) => {
        if(this.quillRef.getLength() - 1 > this.props.maxLength) {
            this.quillRef.deleteText(this.props.maxLength, this.quillRef.getLength());
        }
        this.props.handlePMCommentChange(e);
    }

    render() {
        return (
            <ReactQuill
                ref={(el) => { this.reactQuillRef = el }}
                value={this.props.managerComment}
                onChange={this.handlePMCommentChange}
            />
        )
    }
}
export default Quil;