import React, { Component } from 'react';

class ActiveInactiveTableCell extends Component {
    render() {
        
        var isActive = this.props.value;

        if (isActive) {
            return <td>Active</td>;
        } else {
            return <td>Inactive</td>;
        }

    }
}

export default ActiveInactiveTableCell;
