import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;
const timeSheetRouter = apiUrl + '/timeSheetManagement/timeSheetEntry/'


/**
 * getTask - retirive task list
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
export  function getTask(parms){
    const endPoint = timeSheetRouter + 'taskList';
    return  http.post(endPoint,parms);
}

/**
 * getTask - retirive task list
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
 export  function getDailyTimeEntries(parms){
  const endPoint = timeSheetRouter + 'dailytimeentries';
  return  http.post(endPoint,parms);
}

/**
 * getTimeCards - retirive time entries
 * @returns {Object}  Object of saved details of  time entry
 * @param parms
 */
export  function getTimeCards(parms){
    const endPoint = timeSheetRouter + 'getTimeCards';
    return  http.post(endPoint,parms);
}

/**
 * getCalculatedTotals - get calculated totals
 * @returns {Object}  Object of saved details of  time entry
 * @param parms
 */
export  function getCalculatedTotals(parms){
    const endPoint = timeSheetRouter + 'getCalculatedTotals';
    return  http.post(endPoint,parms);
}

/**
 * getTimeEntryTotalForCurrentDate - get calculated totals for current date
 * @returns {Object}  Object of saved details of  time entry
 * @param parms
 */
export  function getTimeEntryTotalForCurrentDate(parms){
  const endPoint = timeSheetRouter + 'getTimeEntryTotalForCurrentDate';
  return  http.post(endPoint,parms);
}

/**
 * getLatestTimeEntryByDate - get latest time entry by date
 * @returns {Object}  Object of latest time entry
 * @param parms
 */
export  function getLatestTimeEntryByDate(parms){
  const endPoint = timeSheetRouter + 'getLatestTimeEntryByDate';
  return  http.post(endPoint, parms);
}


/**
 * delete - delete Saved  time entry
 * @returns {Object}  Object of saved details of  time entry
 * @param timeEntryIds
 */
export  function deleteTimeEntry(timeEntryIds){
    const endPoint = timeSheetRouter + 'deleteTimeEntry';
    return  http.post(endPoint,timeEntryIds);
}

/**
 * delete - delete Saved  time entry
 * @returns {Object}  Object of saved details of  time entry
 * @param timeEntryIds
 */
 export  function deleteTimeEntryDaily(timeEntryIds){
  const endPoint = timeSheetRouter + 'deleteTimeEntryDaily';
  return  http.post(endPoint,timeEntryIds);
}

/**
 * save - Save time entry
 * @returns {Object}  Object of saved details of  time entry
 * @param timeEntry
 */
export function saveTimeEntry(timeEntry) {
    const endPoint = timeSheetRouter + 'saveTimeEntry';
    return http.post(endPoint, timeEntry);
}

/**
 * UpdateTimeEntry - Edit ResouceTimeEntry
 * @returns {Object}  Object of Updated of TimeEntry
 * @param timeEntry
 * @param timeEntryId
 */
export function UpdateTimeEntry(timeEntry,timeEntryId) {
    const endPoint = timeSheetRouter + 'updateTimeEntry/' + timeEntryId
    return http.put(endPoint, timeEntry);
  }

/**
 * saveSearchCriteria - save Search Criteria
 * @returns {Object}  Object of saved of Search Criteria
 * @param Search
 */
export function saveSearchCriteria(Search) {
    const endPoint = timeSheetRouter + 'saveSearchCriteria'
    return http.post(endPoint, Search);
  }


/**
 * getSearchCriteria - get saved Search Criteria
 * @returns {Object}  Object of saved of Search Criteria
 */
export function getSearchCriteria() {
    const endPoint = timeSheetRouter + 'getSearchCriteria'
    return http.get(endPoint);
  }

/**
 * getDataForProjectFilter - get saved project and non project task group
 * @returns {Object}  Object of saved of Search Criteria
 */
export function getDataForProjectFilter() {
    const endPoint = timeSheetRouter + 'getDataForProjectFilter'
    return http.get(endPoint);
  }

/**
 * getDataForTaskFilter - get saved project task and non project task 
 * @returns {Object}  Object of saved  tasks
 */
export function getDataForTaskFilter(parms) {
    const endPoint = timeSheetRouter + 'getDataForTaskFilter'
    return http.post(endPoint,parms);
  }

export function getAllPendingTimes(data) {
    const endPoint = timeSheetRouter + 'getpendingtimeentries'
    return http.post(endPoint, data);
}
  /**
 * getTimeEntryFreezeDaysByUser - get Time Entry Freeze Days By User
 * @returns {Object}  Object of saved of Search Criteria
 */
export function getTimeEntryFreezeDaysByUser() {
  const endPoint = timeSheetRouter + 'getTimeEntryFreezeDaysByUser'
  return http.get(endPoint);
}

/**
 * setWorkflow - set workflow
 * @returns {Object}  Object of set workflow
 * @param UnfreezeRequestData
 */
 export function setWorkflow(UnfreezeRequestData) {
  const endPoint = timeSheetRouter + 'setWorkflow'
  return http.post(endPoint, UnfreezeRequestData);
}

/**
 * searchUnfreezeApprovals - get Unfreeze Approvals based on search criteria
 * @param fields - (object)
 * @returns {Object}  Object of Unfreeze Approvals
 */
 export function searchUnfreezeApprovals(fields) {
  const endPoint = timeSheetRouter + 'searchUnfreezeApprovals'
  return http.post(endPoint, fields);
}

/**
 * getUnfreezeRequestUsersAndType - get Unfreeze Approvals
 * @param fields - (object)
 * @returns {Object}  Object of Unfreeze Approvals
 */
 export function getUnfreezeRequestUsersAndType(fields) {
  const endPoint = timeSheetRouter + 'getUnfreezeRequestUsersAndType'
  return http.post(endPoint, fields);
}

/**
 * saveUnfreezeWfSearchCriteria - save Unfreeze Workflow Search Criteria
 * @returns {Object}  Object of saved of Unfreeze  Workflow Search Criteria
 * @param Search
 */
 export function saveUnfreezeWfSearchCriteria(Search) {
  const endPoint = timeSheetRouter + 'saveUnfreezeWfSearchCriteria'
  return http.post(endPoint, Search);
}

/**
 * getUnfreezeWfSearchCriteria - get unfreeze workflow search criteria
 * @returns {Object}  Object of saved of unfreeze workflow Search Criteria
 */
 export function getUnfreezeWfSearchCriteria() {
  const endPoint = timeSheetRouter + 'getUnfreezeWfSearchCriteria'
  return http.get(endPoint);
}

/**
 * approve the unfreeze request
 * @param data
 * @returns {array} array object of requests
 */
 export function approveUnfreezeRequest(data) {
  const endPoint = timeSheetRouter + 'approveUnfreezeRequest'
  return http.post(endPoint, data);
}

/**
 * reject the unfreeze request
 * @param data.
 * @returns {array} array object of Projects
 */
 export function rejectUnfreezeRequest(data) {
  const endPoint = timeSheetRouter + 'rejectUnfreezeRequest';
  return http.post(endPoint,data);
}

/**
 * getUnfreezeWfSearchCriteria - get unfreeze workflow search criteria
 * @returns {Object}  Object of saved of unfreeze workflow Search Criteria
 */
 export function getUnfreezeTimeEntryCalculations(userId, unFreezeRequestId, requestType) {
  const endPoint = timeSheetRouter + 'getUnfreezeTimeEntryCalculations/' + userId + '/' + unFreezeRequestId  + '/' + requestType;
  return http.get(endPoint);
}

/**
 * getApproverUnfreezeDates - retirive Approver UnfreezeDates
 * @returns {Object}  Object of approver UnfreezeDates
 * @param parms
 */
 export function getApproverUnfreezeDates(parms){
  const endPoint = timeSheetRouter + 'getApproverUnfreezeDates';
  return  http.post(endPoint,parms);
}


 /**
 * getTimeEntryFreezeDaysByUser - get Time Entry Freeze Days By User
 * @returns {Object}  Object of saved of Search Criteria
 */
  export function getAllUnFreezeRequestTimeSheetForWorkflows() {
    const endPoint = timeSheetRouter + 'getAllUnFreezeRequestTimeSheetForWorkflows'
    return http.get(endPoint);
  }

    /**
 * getTimeEntryFreezeDateFromTimesheetFreezeDate - get Time approval Freeze Date
 * @returns {Object}  Object of saved of Search Criteria
 */
export function getTimeEntryFreezeDateFromTimesheetFreezeDate() {
  const endPoint = timeSheetRouter + 'getTimeEntryFreezeDateFromTimesheetFreezeDate'
  return http.get(endPoint);
}

