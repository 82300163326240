import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Search Offerings by given param.
 * @returns {array} array list of Approver Type
 * @param params
 */
export function searchOfferings(params) {
  const endPoint = apiUrl + '/masterData/offerings/search';
  return http.post(endPoint, params);
}

/**
 * Create Offerings by given param.
 * @returns {array} array list of Approver Type
 * @param attributes
 */
export function createOffering(attributes) {
  const endPoint = apiUrl + '/masterData/offerings';
  return http.post(endPoint, attributes);
}

/**
 * Update Offering by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param attributes
 */
export function updateOffering(attributes) {
  console.log(attributes);
  const endPoint = apiUrl + '/masterData/offerings';
  return http.put(endPoint, attributes);
}

/**
 * Get Offerings for drop down in create project.
 * @returns {array} array list of offerings
 */
export function getOfferings() {
  const endPoint = apiUrl + '/masterData/offerings';
  return http.get(endPoint);
}