import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class HolidayCell extends Component {
    render() {
        const value = this.props.dataItem.Holiday;
        const localDate = new Date(value);
        return <td className="holiday-column"><Moment local format="MM-DD">
            {localDate}
        </Moment>
        </td>;
    }
}

export default HolidayCell;