import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-react-dialogs';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { searchIRFData, approveInvoiceRequest, rejectInvoiceRequest, getIRStatuses, getBillableTimeEntries, saveIRFData } from './InvoiceService';
import { getRegionsIntegrated } from '../../integration/MasterDataService';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import * as loggerService from './../../integration/LoggerService';
import { LOG_TYPES } from './../../integration/IntegrationEnums';
import Parser from 'html-react-parser';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import IRFExcelDownload from './IRFExcelDownload';
import { Checkbox } from '@progress/kendo-react-inputs';
import CustomCalendar from './CustomCalendar';
import moment from 'moment';
import 'moment-timezone';
import SuccessImg from '../../../src/ui/static/images/checked.svg';
import ErrorImg from '../../../src/ui/static/images/cancel.svg';
import Loader from './../../integration/components/Loader';
const apiUrl = require('../../../src/config').get(process.env.REACT_APP_ENV).apiUrl;

class IRFView extends Component {
    ticked = [];

    isMount = false;

    constructor(props) {
        super(props);

        this.state = {
            irfdata: [],
            skip: 0,
            take: 20,
            action: null,
            selectedInvoiceRequests: [],
            showMessageDialog: false,
            dialogMessage: '',
            dialogTitle: '',
            showConfirmDialog: false,
            confirmDialogMessage: '',
            confirmDialogAction: null,
            selectedStartDate: null,
            selectedEndDate: null,
            showSuccessImage: false,
            showErrorImage: false,
            selectedRegion: false,
            region: [],
            regionAll: [],
            validRegionsAll: [],
            approverComment: null,
            isErrorMessageVisible: false,
            isClickedRejectBtn: false,
            invoiceRequestStatus: [],
            selectedStatus: null,
            generateIRF: false,
            generateBtnDisable: false,
            approvedIRs: [],
            invoicingMonth: null,
            sort: [
                { field: 'updatedAt', dir: 'desc' }
            ],
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.isMount = true;
        this.setHeaderTitle();
        this.setInitialData();
    }

    setInitialData = async () => {
        await this.setState({
            loading: true
        });
        await this.populateRegions();
        await this.populateIRStatus();
        await this.submitSearch();
        await this.setState({
            loading: false
        });
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange(
                this.props.location.action === 'approve' ? 'Pending Invoice Request Approvals': 'Generate IRF'
            );
        }
    };

    submitSearch = async event => {
        if(event) {
            event.preventDefault();
        }
        
        if (this.isMount) {
          await this.setState({
            loading: true
          });
        }
        const fields = {
          Region: this.state.selectedRegion && this.state.selectedRegion.length > 0 ? this.state.selectedRegion : this.state.validRegionsAll,
          Status: this.state.selectedStatus,
          InvoicingMonth: this.state.invoicingMonth ? moment(new Date(this.state.invoicingMonth)).format('YYYY-MM-DD'): null,
        };

        if (this.props.location.action === "approve"){
            fields.action = true;
            this.setState({
                action: 'approver',
            });
        } else {
            fields.action = false;
        }

        await searchIRFData(fields)
          .then(res => {
            if (this.isMount) {
                const selected = res.data.filter(item => item.Selected === true && (item.Status === 3 || item.Status === 6));
                const generateBtnDisable = selected && selected.length > 0 ? false : true;
                this.setState({
                    irfdata: res.data,
                    generateBtnDisable: generateBtnDisable,
                    loading: false,
                    skip: 0
                });
            }
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
          await this.setState({
            loading: false,
          });
    };

    populateRegions = async() => {
        await getRegionsIntegrated()
          .then(res => {
            if (this.isMount) {
              this.setState({
                region: res.data,
                regionAll: res.data
              }, 
              () => {
                if (this.props.location.action !== "approve") {
                    this.validateRegions();
                }
              });
            }
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    };

    populateIRStatus = async() => {
        await getIRStatuses()
          .then(res => {
            if (this.isMount) {
              this.setState({
                invoiceRequestStatus: res.data,
              });
            }
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      };

    validateRegions = async() => {
        let validRegions = [];
        const userPermissions = window.USER_PERMISSION;

        const asiaSpoc = userPermissions.some(obj => obj === 'ASIA_SPOC');
        if (asiaSpoc) {
            const asiaRegios = this.state.regionAll.filter(x => x.id === 3 || x.id === 5 || x.id === 18 || x.id === 19);
            validRegions = validRegions.concat(asiaRegios);
        }

        const anzSpoc = userPermissions.some(obj => obj === 'ANZ_SPOC');
        if (anzSpoc) {
            const anzRegios = this.state.regionAll.filter(x => x.id === 4);
            validRegions = validRegions.concat(anzRegios);
        }

        const ukSpoc = userPermissions.some(obj => obj === 'UK_EUROPE_SPOC');
        if (ukSpoc) {
            const ukRegios = this.state.regionAll.filter(x => x.id === 1 ||  x.id === 21);
            validRegions = validRegions.concat(ukRegios);
        }

        const usSpoc = userPermissions.some(obj => obj === 'AMERICAS_SPOC');
        if (usSpoc) {
            const usRegios = this.state.regionAll.filter(x => x.id === 2);
            validRegions = validRegions.concat(usRegios);
        }
        if (validRegions && validRegions.length > 0) {
            await this.setState({
                region: validRegions,
                selectedRegion: validRegions,
                validRegionsAll: validRegions
            });
        }
    };

    onClickApprove = async() => {
        await this.setState({
            isClickedRejectBtn: false
        });
        this.toggleConfirmDialog('Are you sure you want to approve the selected invoice request(s)?', this.approveConfirm);
    };

    onClickReject =  async() => {
        await this.setState({
            isClickedRejectBtn: true
        });
        this.toggleConfirmDialog('Are you sure you want to reject the selected invoice request(s)?', this.rejectConfirm);
    };

    approveConfirm = async event => {
        await this.setState({
            loading: true
        });

        const approveObj = {
            requestLines: this.state.selectedInvoiceRequests ? this.state.selectedInvoiceRequests : [],
            comment: this.state.approverComment,
            LastApprovedDate: moment(new Date()).format('YYYY-MM-DD')
        };
    
        this.toggleConfirmDialog('', null);
    
        await approveInvoiceRequest(approveObj)
            .then(res => {
                this.setState({
                    selectedInvoiceRequests: [],
                    showErrorImage: false,
                    showSuccessImage: true
                });
                this.ticked = [];
                this.toggleMessageDialog('Successfully Approved', 'Success');
                this.submitSearch();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
            
    };
    
    rejectConfirm = async event => {
        if (this.state.approverComment) {
            const approveObj = {
                requestLines: this.state.selectedInvoiceRequests ? this.state.selectedInvoiceRequests : [],
                comment: this.state.approverComment
            };
    
            this.toggleConfirmDialog('', null);
    
            await rejectInvoiceRequest(approveObj)
                .then(res => {
                    this.setState({
                        selectedInvoiceRequests: [],
                        showErrorImage: false,
                        showSuccessImage: true,
                        isErrorMessageVisible: false,
                    });
                    this.ticked = [];
                    this.toggleMessageDialog(' Successfully Rejected', 'Success');
                    this.submitSearch();
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });    
        } else {
            this.setState({
                isErrorMessageVisible: true,
            });
        }
    };

    toggleMessageDialog = (message, title) => {
        this.setState({
            showMessageDialog: !this.state.showMessageDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    toggleConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action,
            isErrorMessageVisible: false,
            approverComment: null
        });
    };

    handleInputChange = event => {
        const valueObj = event.target.value;
        const field = event.target.name;
        if (this.isMount) {
          this.setState({
            [field]: valueObj
          });
        }
    };

    handleInvoicingMonthChange = async event => {
        const value = event.target.value;
        if (value) {
            const firstDateOfMonth = new Date(value).setDate(1);
            await this.setState({ 
                invoicingMonth: new Date(firstDateOfMonth)
            });  
        } else {
            await this.setState({ 
                invoicingMonth: null,
            });  
        }
    }
    
    filterChangeRegion = event => {
        if (this.isMount) {
          this.setState({
            region: this.filterData(event.filter, this.state.validRegionsAll)
          });
        }
    };

    filterData(filter, allData) {
        const data = allData.slice();
        return filterBy(data, filter);
    }

    handleApproverComment = event => {
        const value = event.target.value;
        this.setState({
            approverComment: value
        });
    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        let selected = [];
        let generateBtnDisable = this.state.generateBtnDisable;

        if (this.state.action === 'approver') {
            this.state.irfdata.forEach(item => item.Selected = checked);
            this.forceUpdate();
            selected = this.state.irfdata.filter(item => item.Selected === true);
        } else {
            for (const item of this.state.irfdata) {
                if (item.Status === 3 || item.Status === 6) {
                    item.Selected = checked;
                }
            };
            this.forceUpdate();
            selected = this.state.irfdata.filter(item => item.Selected === true && (item.Status === 3 || item.Status === 6));
            generateBtnDisable = selected && selected.length > 0 ? false : true;
        }

        this.setState({
            selectedInvoiceRequests: selected,
            generateBtnDisable: generateBtnDisable
        });
    };

    selectionChange = async (event) => {
        let selected = [];
        let generateBtnDisable = this.state.generateBtnDisable;

        event.dataItem.Selected = !event.dataItem.Selected;

        const index = this.state.irfdata.indexOf(event.dataItem);
        if (event.dataItem.Selected) {
            this.ticked.push(index);
        } else {
            this.ticked.splice(this.ticked.indexOf(index), 1);
        }

        this.forceUpdate();
        if (this.state.action === 'approver') {
            selected = await this.state.irfdata.filter(resource => resource.Selected === true);
        } else {
            selected = this.state.irfdata.filter(item => item.Selected === true && (item.Status === 3 || item.Status === 6));
            generateBtnDisable = selected && selected.length > 0 ? false : true;
        }


        await this.setState({
            selectedInvoiceRequests: selected,
            generateBtnDisable: generateBtnDisable
        });   
    };

    conditionalCellRender = (td, props) => {
        if (this.state.action !== 'approver' && props.field == "Selected" && (props.dataItem.Status !== 3 && props.dataItem.Status !== 6)) {
          return (
            <td>
              <Checkbox disabled={true} />
            </td>
          );
        } else {
          return td;
        }
      };

    cancelSearch = event => {
        event.preventDefault();
        if (this.isMount) {
          this.setState({
            selectedRegion: [],
            selectedStatus: [],
            invoicingMonth: null
          });
        }    
    };

    pageChange = event => {
        if (this.isMount) {
          this.setState({
            skip: event.page.skip,
            take: event.page.take
          });
        }
      };

    export = async (event) => {
        await this.setState({
            loading: true
        });

        let generateBtnDisable = this.state.generateBtnDisable;

        const approvedIRs = this.state.irfdata.filter(ir => (ir.Status === 3 || ir.Status === 6) && ir.Selected);
        if (approvedIRs && approvedIRs.length > 0) {
            for (const ir of approvedIRs) {
                if (ir.Status === 6) {
                    ir.Remarks = ir.Remarks ? ir.Remarks + ' - IRF previously generated' : 'IRF previously generated';
                }
                const projectOrCr = ir.CrId ? ir.ChangeRequest : ir.Project;
                const timeEntryObj = {
                    ProjectId: ir.ProjectId,
                    CrId: ir.CrId,
                    ProjectOrCrName: ir.ProjectName,
                    Type: ir.CrId ? 'ChangeRequest' : 'Project',
                    CurrencyId: ir.CurrencyId ? ir.CurrencyId : projectOrCr.Region.Currency.id,
                    ProjectStartDate: moment(new Date(projectOrCr.StartDate)).format('YYYY-MM-DD'),
                    StartDate: moment(new Date(ir.StartDate)).format('YYYY-MM-DD'),
                    EndDate: moment(new Date(ir.EndDate)).format('YYYY-MM-DD'),
                    InvoiceRequestId: ir.id
                }
                await getBillableTimeEntries(timeEntryObj)
                .then(res => {
                    if (this.isMount) {
                        ir.TimeEntries = res.data.TimeEntries;
                        ir.TotalAmount = res.data.ActualRevenue;
                    }
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
            }
        } else {
            generateBtnDisable = true;
        }
        await this.setState({
            generateBtnDisable: generateBtnDisable,
            approvedIRs: approvedIRs,
            generateIRF: true,
          });
    }

    saveIRFData = async() => {
        if (this.state.approvedIRs &&  this.state.approvedIRs.length > 0) {
            const irObj = {
                InvoiceRequestIds: this.state.approvedIRs.map(ir => ir.id)
            }
            await saveIRFData(irObj)
            .then(res => {
                if (this.isMount) {
                    this.submitSearch()
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        }
        await this.setState({
            loading: false
        });
    }

    render() {

        return (
            <div className="main-card">
                <div className="row">
                    <div className="col-md-8">
                    {this.state.action === 'approver' ?
                        <div className="main-heading">Pending Invoice Request Approvals</div> :
                        <div className="main-heading">Generate IRF</div>
                    }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label className="">Region:</label>
                        <MultiSelect
                            className="text-break"
                            name="selectedRegion"
                            textField="Name"
                            value={this.state.selectedRegion}
                            data={this.state.region}
                            placeholder={'Please Select'}
                            filterable={true}
                            onFilterChange={this.filterChangeRegion}
                            onChange={this.handleInputChange} 
                        />
                    </div>
                    {this.state.action !== 'approver' ?
                        <div className="col-md-3">
                            <label>Status:</label>
                            <MultiSelect
                                className="text-break"
                                name="selectedStatus"
                                textField="name"
                                value={this.state.selectedStatus}
                                data={this.state.invoiceRequestStatus}
                                placeholder={'Please Select'}
                                filterable={true}
                                onChange={this.handleInputChange} 
                            />  
                        </div>
                     : null }
                     {this.state.action !== 'approver' ?
                        <div className="col-md-3">
                            <label>Invoicing Month:</label>
                            <DatePicker
                                width="100"
                                name="invoicingMonth"
                                calendar={CustomCalendar}
                                value={this.state.invoicingMonth}
                                format="MM/yyyy"
                                formatPlaceholder={{ year: 'YYYY', month: 'MM'}}
                                onChange={this.handleInvoicingMonthChange}
                            />
                        </div>
                     : null }
                </div>

                <div className="row mt-4">
                    <div className="col-md-12 btn-align-right">
                        <Button primary={true} className="mb-3" type="submit" onClick={this.submitSearch}>Search</Button>
                        <Button type="reset" className="mb-3" onClick={this.cancelSearch}>Clear</Button>
                    </div>
                </div>

                <div className="main-seperator" />

                <div className="row">
                    <div className="col-md-6">
                        <div className="main-heading">Search Results</div>
                    </div>
                    {this.state.action !== 'approver' ?
                        <div className="col-md-6">
                            <div className="btn-align-right">
                                <Button primary className="pull-left" onClick={this.export} disabled={this.state.generateBtnDisable}>
                                    {'Generate IRF'}
                                </Button>
                                {!this.state.isLoading && (
                                    <IRFExcelDownload
                                        irfdata={this.state.approvedIRs}
                                        dataUpdated={() => {
                                            this.setState({ generateIRF: false });
                                        }}
                                        updateData={this.state.generateIRF}
                                        handleSaveIRFData={this.saveIRFData}
                                    />
                                )}
                            </div>
                        </div> 
                    : null }
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Grid
                            cellRender={this.conditionalCellRender}
                            data={orderBy(this.state.irfdata, this.state.sort).slice(
                                this.state.skip,
                                this.state.take + this.state.skip
                            )}
                            skip={this.state.skip}
                            take={this.state.take}
                            total={this.state.irfdata.length}
                            resizable
                            pageable={true}
                            onPageChange={this.pageChange}
                            sortable={true}
                            sort={this.state.sort}
                            onSortChange={(e) => {
                                this.setState({
                                    sort: e.sort
                                });
                            }}
                            selectedField="Selected"
                            onHeaderSelectionChange={this.headerSelectionChange}
                            onSelectionChange={this.selectionChange}
                            className= {this.state.action !== 'approver' ? "search-result-grid" : "ir-approver-grid"}
                        >
                            <Column field="Selected" width="40px" />
                            {this.state.action !== 'approver' ?
                                <Column field="Region" title="Region" width="100px"/>
                            : null }
                            <Column field="Customer" title="Customer" width="100px"/>
                            <Column field="OPID" title="OP ID" width="100px"/>
                            <Column field="ProjectName" title="Project" width="140px"/>
                            <Column field="Currency" title="Currency" width="80px"/>
                            <Column field="InvoiceAmount" title="Invoice Amount" width="100px"/>
                            {this.state.action === 'approver' ?
                                <Column field="TimeBasedRevenue" title="Time Based Revenue" width="130px"/>
                            : null }
                            {this.state.action === 'approver' ?
                                <Column field="ForecastRevenue" title="Forecasted/Milestone Amount" width="130px"/>
                            : null }
                            {this.state.action === 'approver' ?
                                <Column
                                    field="Milestone"
                                    title="Customer Approval"
                                    width="120px"
                                    cell={props => (props.dataItem.BillingMethodId !== 2 ? 
                                        props.dataItem.Milestone !== null && props.dataItem.Milestone.FileUpload ? 
                                        <td>
                                            <div>
                                                <a href={`${apiUrl}/integration/fileUpload/downloadmilestone?path=Milestone_${encodeURIComponent(props.dataItem.Milestone.FileUpload.FileName)}&&milestoneFileName=${encodeURIComponent(props.dataItem.Milestone.FileUpload.FileName)}`} target="">
                                                    {props.dataItem.Milestone.FileUpload.FileName}
                                                    <span className="k-icon k-i-download ml-2"></span>
                                                </a> 
                                            </div>
                                        </td> : 
                                        <td>{''}</td> :
                                        props.dataItem.ApprovalFileId !== null && props.dataItem.FileUpload ? 
                                        <td>
                                            <div>
                                                <a href={`${apiUrl}/integration/fileUpload/downloadircustomerapproval?path=CustomerApprovalIR_${encodeURIComponent(props.dataItem.FileUpload.FileName)}&&irFileName=${encodeURIComponent(props.dataItem.FileUpload.FileName)}`} target="">
                                                    {props.dataItem.FileUpload.FileName}
                                                    <span className="k-icon k-i-download ml-2"></span>
                                                </a> 
                                            </div>
                                        </td> : 
                                        <td>{''}</td>
                                    )}
                                /> : null 
                            }
                            <Column field="InvoiceMonth" title="Invoice Request Month" width="140px" />
                            <Column field="BillingMethod" title="Billing Method" width="100px"/>
                            <Column field="Offering" title="Solution Type" width="100px"/>                            
                            <Column field="PONumber" title="PO Number" width="100px"/>                            
                            <Column field="UpdatedUser" title="Sender" width="100px"/>  
                            <Column
                                field="Description"
                                title="Invoice Description"
                                width="150px"
                                cell={props => (props.dataItem.Description !== null ? <td><div className={'rich-text-bullets'}>{Parser(props.dataItem.Description)}</div></td> : <td>{''}</td>)}
                            />
                            <Column
                                field="Remarks"
                                title="Invoice Remarks"
                                width="140px"
                                cell={props => (props.dataItem.Remarks !== null ? <td><div className={'rich-text-bullets'}>{Parser(props.dataItem.Remarks)}</div></td> : <td>{''}</td>)}
                            />
                            <Column field="StatusName" title="Status" width="100px"/>
                        </Grid>                            
                    </div>
                </div>

                {this.state.action === 'approver' ?
                    <div className="row my-2">
                        <div className="col-md-12 btn-align-right">
                            <Button
                                primary={true}
                                type="submit"
                                disabled={this.state.selectedInvoiceRequests.length <= 0 || this.state.action !== 'approver'}
                                onClick={this.onClickApprove}
                            >
                                Approve
                            </Button>
                            <Button
                                primary={true}
                                type="reset"
                                disabled={this.state.selectedInvoiceRequests.length <= 0 || this.state.action !== 'approver'}
                                onClick={this.onClickReject}
                            >
                                Reject
                            </Button>
                        </div>
                    </div> 
                : null }

                {this.state.showMessageDialog == true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
                        {this.state.showSuccessImage === true && (
                            <div className="text-center">
                                <img className="successImg" src={SuccessImg} alt="Success"/>
                            </div>
                        )}

                        {this.state.showErrorImage === true && (
                            <div className="text-center">
                                <img className="successImg" src={ErrorImg} alt="Error Image"/>
                            </div>
                        )}
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                            OK
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ margin: '15px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                        <p><label className= {this.state.isClickedRejectBtn ? "mandatory" : ""}>Comment:</label></p>
                        <textarea className="k-textarea" rows="4" cols="30" placeholder="Type Comment"
                                  onChange={this.handleApproverComment}
                                  value={this.state.approverComment}
                        ></textarea>
                        {this.state.isErrorMessageVisible === true ? (
                            <span className={this.validateProperty(this.state.approverComment)}>
                                Please provide a reason.
                            </span>
                        ) : null}
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleConfirmDialog}>
                                No
                            </button>
                            <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                <Loader loading={this.state.loading} />
            </div>
        )
    }
}

export default IRFView;
