/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import moment from 'moment';
import { reStructureDate } from '../../../integration/CommonUtils';

class PhaseRow extends Component {
    formatDate = dateStr => {
        if (dateStr) {
            const startDate = reStructureDate(dateStr);

            return startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear();
        }
        return null;
    };

    render() {
        const phase = this.props.phase;
        return (
            <div className="parent-phase">
                <div className="phase">
                    {phase
                        ? phase.Text +
                        ' ' +
                        (phase && phase.StartDate ? '(' + this.formatDate(phase.StartDate) + ')' : '')
                        : ''}
                    {this.props.isEditPhases ? (
                        <Button className="rounded-btn remove ml-2" onClick={this.props.onRemovePhase}>
                            <span className="k-icon k-i-close" />
                        </Button>
                    ) : null}
                    {this.props.isEditPhases ? (
                        <Button className="rounded-btn remove ml-2" onClick={this.props.onUpdatePhase}>
                            <span className="k-icon  k-i-edit" />
                        </Button>
                    ) : null}
                </div>
                <div className="sub-phase-wrap">
                    {phase.SubPhases
                        ? phase.SubPhases.map((value, index) => {
                            return (
                                <div className="sub-phase mb-2" key={index}>
                                    {value.Text +
                                    ' ' +
                                    (value && value.StartDate
                                        ? '(' + this.formatDate(value.StartDate) + ')'
                                        : '')}
                                    {this.props.isEditSubPhases ? (
                                        <Button
                                            className="rounded-btn remove ml-2"
                                            onClick={() => this.props.onRemoveSubPhase(this.props.phaseIndex, index)}
                                        >
                                            <span className="k-icon k-i-close" />
                                        </Button>
                                    ) : null}
                                    {this.props.isEditSubPhases ? (
                                        <Button
                                            className="rounded-btn remove ml-2"
                                            onClick={() => this.props.onUpdateSubPhase(this.props.phaseIndex, index)}
                                        >
                                            <span className="k-icon k-i-edit" />
                                        </Button>
                                    ) : null}
                                </div>
                            );
                        })
                        : null}
                </div>
            </div>
        );
    }
}

export default PhaseRow;
