import React, { Component } from 'react';
import 'moment-timezone';
import Parser from 'html-react-parser';
import {getcustomers} from "../../../../masterData/customer/CustomerService";
import {getProjectStatuses} from "../../../project/ProjectService";

class EffortCell extends Component {


    render() {

        let value = this.props.dataItem;

        if(value && value.ChangeRequests.length > 0){

            return <td>
                {value.ChangeRequests[0].contractedEffort}
            </td>;

        }
        else{

            let effortDays =  value.contractedEffort

            return <td>
                {value.contractedEffort ? value.contractedEffort : 0}
            </td>;
        }
    }
}

export default EffortCell;
