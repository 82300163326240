import React, { Component } from 'react';
import { deleteFileById, replaceFile, uploadFile } from './FileUploadService';
import { uploadCMSheetFile, getCMSFilesByCMSheetIdandVersion, deleteCMSFile } from '../../projectManagement/cmSheet/CMSheetService';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import Loader from '../components/Loader';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

class FileUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      events: [],
      filePreviews: {},
      referenceType: '',
      referenceId: '',
      visible: false,
      cmSheetAction: '',
      viewMode: false,

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      selectedFile: null,
      replaceBtnDisabled: false,
      isLoading: false
    };
  }

  componentDidMount() {

    this.setState({
      cmSheetAction: this.props.cmSheetAction,
    });

    if(this.props.referenceType === 'CMsheet'){
      this.getSavedCMSheetFile();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.cmSheet !== prevProps.cmSheet) {
      if(this.props.referenceType === 'CMsheet'){
        this.getSavedCMSheetFile();
      }
      this.setState({
        viewMode: this.props.viewingMode
      });
    }
  }

  /**
     * getSavedCMSheetFile - Retrieves all the Cm sheet related files
     * @returns {array} - List of files
     */
  getSavedCMSheetFile = async () => {
    let cmSheets;
    await getCMSFilesByCMSheetIdandVersion(this.props.cmSheet.id, this.props.cmSheet.Version)
        .then(res => {
          if (res) {
            this.setState({files: res.data});
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
  };

  /**
   * replaceFile - Replaces an exisiting file
   * @param event
   * @returns {object}
   */
  replaceFile = (event) => {
    this.setState({
      replaceBtnDisabled: true
    });
    var form_data = new FormData();
    form_data.append('file', this.state.selectedFile);
    form_data.append('referenceType', this.props.referenceType);
    form_data.append('referenceId', this.props.referenceId);
    form_data.append('cmSheetVersion', this.props.cmSheet.Version);

    this.setState({
      isLoading: true
    });
    replaceFile(form_data)
      .then(res => {
        this.toggleConfirmDialog();
        this.setState({
          isLoading: false,
          replaceBtnDisabled: false
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (error.response.status === 413) {
          this.toggleDialog('Your file exceed 15 MB of maximum size allowed for upload files', 'Error')
        }
      });
  };

  toggleDialog = (message, title) => {
    this.getSavedCMSheetFile();
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title,
    });
  };

  toggleConfirmDialog = async (message, action) => {
    await this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
      confirmDialogMessage: message,
      confirmDialogAction: action
    });
  };

  onChangeHandler = async event => {
    await this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    });

    var form_data = new FormData();
    form_data.append('file', this.state.selectedFile);
    form_data.append('referenceType', this.props.referenceType);
    form_data.append('referenceId', this.props.referenceId);
    form_data.append('cmSheetVersion', this.props.cmSheet.Version);

    this.setState({
      isLoading: true
    });
    uploadFile(form_data).then(res => {

        let uploadedFileId = res.data.id;

        if(this.props.referenceType === 'CMsheet') {
          const cmSheetFileData = {
            CMSheetId: this.props.referenceId,
            CMSheetVersion : this.props.cmSheet.Version,
            FileId: uploadedFileId
          };
          uploadCMSheetFile(this.props.referenceId, cmSheetFileData).then(cmsFileResp => {

            if(cmsFileResp){
              this.setState({
                files: cmsFileResp.data,
                isLoading: false
              });
            }

          });
        }
        else {
          // THIS CAN BE PROJECT FILE UPLOAD
        }

        this.toggleDialog('File has been uploaded successfully', 'Upload Status');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          isLoading: false
        });
        if (error.response.status === 400) {
          this.setState({
            isLoading: false
          });
          this.toggleConfirmDialog(error.response.data, this.replaceFile);
        }
        if (error.response.status === 413) {
          this.toggleDialog('Your file exceed 15 MB of maximum size allowed for upload files', 'Error')
        }

      });
  }

  /**
   * removeFile - Removes a file from the ui based on UniqueIdentifier
   * @return {Promise<void>}
   * @param fileId
   */
  removeFile = async (fileId, cmsheetId, cmsheeyVersion) => {
    deleteCMSFile(fileId, cmsheetId, cmsheeyVersion).then(resp => {
      this.getSavedCMSheetFile();
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });

  };

  render() {
    return (
      <div class="main-card">

        <div className="row">
          <div className="col-md-12">
            <Loader loading={this.state.isLoading} />
            {((this.props.cmSheetAction === 'edit' || this.props.cmSheetAction === 'create') && !this.props.viewingMode) && (
              <div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="main-heading">Upload Files</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="custome-file-input-wrapper">
                      <input type="file" name="file" onChange={this.onChangeHandler} value={""} class="custom-file-input" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ul className="file-upload-list">
                                    {
                                      this.state.files.map(file =>
                                        <li className="doc-list" id={file.uid} key={file.FileName}>
                                            <div className="row">
                                              <div className="col-8">
                                                {file.FileName}
                                              </div>
                                              <div className="col-4 btn-align-right">
                                                <a href={`${apiUrl}/integration/fileUpload/download?path=CMsheet_${file.CMSheetId}_${file.CMSheetVersion}_${encodeURIComponent(file.FileName)}`} target="">
                                                  <span className="k-icon k-i-download"></span></a>
                                                <a onClick={() => this.removeFile(file.fileId, file.CMSheetId, file.CMSheetVersion)}><span className="k-icon k-i-close"></span></a>
                                              </div>
                                            </div>
                                        </li>
                                      )
                                    }
                    </ul>

                  </div>
                </div>
                                
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            {(this.props.IsPoolProject !== true ? ((this.props.cmSheetAction === 'revise' || this.props.cmSheetAction === 'approve' || this.props.viewingMode) && this.props.isRolePM) : (this.props.cmSheetAction === 'revise' || this.props.cmSheetAction === 'approve' || this.props.viewingMode)) && (
              <div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="main-heading">Upload Files</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="custome-file-input-wrapper">
                      <input type="file" name="file" onChange={this.onChangeHandler} value={""} class="custom-file-input" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <ul className="file-upload-list">
                      {
                        this.state.files.map(file =>
                          <li className="doc-list" id={file.uid} key={file.FileName}>
                              <div className="row">
                                <div className="col-8">
                                  {file.FileName}
                                </div>
                                <div className="col-4 btn-align-right">
                                  <a href={`${apiUrl}/integration/fileUpload/download?path=CMsheet_${file.CMSheetId}_${file.CMSheetVersion}_${encodeURIComponent(file.FileName)}`} target="">
                                    <span className="k-icon k-i-download"></span></a>
                                  <a onClick={() => this.removeFile(file.fileId, file.CMSheetId, file.CMSheetVersion)}><span className="k-icon k-i-close"></span></a>
                                </div>
                              </div>
                          </li>
                        )
                      }
                    </ul>
                  </div>
                </div>

              </div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            {(this.props.IsPoolProject !== true ? ((this.props.cmSheetAction === 'revise' || this.props.cmSheetAction === 'approve' || this.props.viewingMode) && !this.props.isRolePM) : (this.props.cmSheetAction === 'revise' || this.props.cmSheetAction === 'approve' || this.props.viewingMode)) && (
              <ul className="file-upload-list">
                {
                  this.state.files.map(file => 
                  <li className="doc-list" id={file.uid} key={file.FileName}>
                    <div className="row">
                      <div className="col-8">
                        {file.FileName}
                      </div>
                      <div className="col-4 btn-align-right">
                        <a href={`${apiUrl}/integration/fileUpload/download?path=CMsheet_${file.CMSheetId}_${file.CMSheetVersion}_${encodeURIComponent(file.FileName)}`} target="_blank"><span className="k-icon k-i-download"></span></a>
                      </div>
                    </div>
                  </li>
                  )
                }
              </ul>
            )}
          </div>
        </div>

        <div>
          {this.state.visible === true && (
            <Dialog
              title={this.state.dialogTitle}
              onClose={this.toggleDialog}
              width="300px">
              <p style={{ margin: "25px", textAlign: "center" }}>
                {this.state.dialogMessage}
              </p>
              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={
                    this.state.dialogTitle === "Error" || this.state.dialogTitle === "Upload Status"
                      ? this.toggleDialog
                      : this.navigateBack
                  }>
                  OK
                  </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.showConfirmDialog === true && (
            <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.toggleConfirmDialog}>
                  No
                        </button>
                <button className="k-button modal-primary" onClick={this.state.confirmDialogAction} disabled={this.state.replaceBtnDisabled}>
                  Yes
                        </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>



      </div >
    );
  };
}

export default FileUpload;
