import React, { Component } from "react";
import { Link } from "react-router-dom";

class UploadedFileCell extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const apiUrl = this.props.ApiUrl;
        const visaNo = this.props.dataItem.VisaNo;
        const fileName = this.props.dataItem.FileUpload.FileName;
        return(
            <td className="visa-file">
                <a href={`${apiUrl}/integration/fileUpload/download?path=UserProfileVisa_${visaNo}_${encodeURIComponent(fileName)}`} target="_blank">
                  {fileName}
                  <span className="k-icon k-i-download ml-2"> </span>
                </a>
            </td>
        );
    }
}

export default UploadedFileCell;
