import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { getExpenseTypes } from '../../masterData/common/CommonService';

import { getRateTypes } from '../../masterData/rateCard/RateCardService';
import { searchCustomers } from '../../masterData/customer/CustomerService';

import { getAllDesignationsIntegrated, getResourceRegionsIntegrated } from '../../integration/MasterDataService';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import ConsolidatedCMSheetChart from './components/ConsolidatedCMSheetChart';
import DeleteCell from './components/DeleteCell';
import { getProjCRForConsCMS } from '../project/ProjectService';
import { getCMSheet } from '../cmSheet/CMSheetService';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';

const CUSTOM_RATE = 'CUSTOM_RATE';
const ProjStatuses = require('../search/StatusChangeEnums');

class ConsolidatedCMSheet extends Component {
  _export;

  cmsStatuses = {
    IN_PROGRESS: 'In Progress',
    PENDING_APPROVALS: 'Pending Approvals',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    EXPIRED: 'Expired'
  };

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {

      cmSheetVersions: [],
      selectedVersion: null,
      customerNames: [],
      projectNames: [],
      skip: 0,
      take: 20,
      isErrorMessageVisible: false,
      selectedCustomer: null,
      selectedProject: null,

      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      customerNamesAll: [],
      projectNamesAll: [],
      refName: null,
      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      startDate: null,
      endDate: null,
      sort: [
        { field: '', dir: 'desc' }
      ],
      IdListArray: [],
      cmSheetIdAndVersionList: [],
      CMSheetToConsolidate: [],
      rowCount: 0,
      consolidationCurrency: null,
      deleteId: null,
      deleteVersion: null,
      cmSheetId: null,
      cmsStatus: null,
      disableProject: true,
      disableVersion: true,
      cmsRegion: null,
      action: '',

      DesignationsAll: [],
      RegionsAll: [],
      RateTypes: [],
      ExpenseTypesAll: []
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.populateCustomerNames();
    this.populateData();
    this.setHeaderTitle();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Consolidated CMS');
    }
  };

  populateData = async () => {
    const resDesg = await getAllDesignationsIntegrated();
    const resReg = await getResourceRegionsIntegrated();
    const resRT = await getRateTypes();
    const expsenseTypes = await getExpenseTypes();


    let rateTypes = [];
    if (resRT.data && resRT.data.length > 0) {
      for (const rateType of resRT.data) {
        if (rateType.Code !== CUSTOM_RATE) {
          rateType.Rate = 0;
          rateType.Text = rateType.Name + ' - 0.00';
        } else {
          rateType.Text = rateType.Name;
        }
        rateTypes.push(rateType);
      }
    }

    if (this.isMount) {
      this.setState(
        {
          DesignationsAll: resDesg.data,
          RegionsAll: resReg.data,
          RateTypes: rateTypes,
          ExpenseTypesAll: expsenseTypes.data
        });
    }
  };
  populateCustomerNames = () => {
    searchCustomers()
      .then(res => {
        if (this.isMount) {
          this.setState({
            customerNames: res.data,
            customerNamesAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

  };

  populateProjectNames = async (customerId) => {
    const data = {
      customerId: customerId
    };
    await getProjCRForConsCMS(data)
      .then(res => {
        if (this.isMount) {
          this.setState({
            projectNames: res.data,
            projectNamesAll: res.data,
            selectedProject: null,
            cmSheetVersions: [],
            selectedVersion: null
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleConfirmDialog = (message, action) => {
    if (this.isMount) {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  handleOnChangeCustomer = event => {
    const valueObj = event.target.value;
    const field = event.target.name;

    if (this.isMount) {
      this.setState({
        consolidationCurrency: 0,
        projectNames: [],
        projectNamesAll: [],
        cmSheetVersions: [],
        selectedVersion: null,
        selectedCustomer: null,
        selectedProject: null,
        CMSheetToConsolidate: [],
        IdListArray: [],
        disableProject: true,
        disableVersion: true,
        cmsRegion: null
      });
    }

    if (valueObj) {
      if (this.isMount) {
        this.setState({
          [field]: valueObj,
          disableProject: false
        });
      }

      this.populateProjectNames(valueObj.id);
    }
  };

  handleOnChangeProject = event => {
    const valueObj = event.target.value;
    const field = event.target.name;

    if (this.isMount) {
      this.setState({
        [field]: valueObj,
        disableVersion: true,
        selectedVersion: null,
        cmSheetVersions: []
      });
    }

    if (valueObj && valueObj.CMSheets && valueObj.CMSheets.length > 0) {
      if (this.isMount) {
        this.setState({
          cmSheetId: valueObj.CMSheets[0].id,
          cmsStatus: valueObj.CMSheets[0].Status,
          disableVersion: false
        });
      }
      this.populateCMSheetVersions(valueObj.CMSheets[0].Version, valueObj.CMSheets[0].MajorVersion);
    }
  };

  handleOnChangeVersion = event => {
    const valueObj = event.target.value;

    if (this.isMount) {
      this.setState({
        selectedVersion: valueObj
      },
        () => {
          if (valueObj) {
            const version = valueObj.Version;

            getCMSheet(this.state.cmSheetId, version)
              .then(res => {
                this.setState({
                  cmsStatus: res.data.Status
                });
              })
              .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });
          }
        }
      );
    }
  };

  populateCMSheetVersions = (versionId, majVersion) => {
    const list = [];
    let version = versionId;
    while (version >= 0) {
      const txtVersion = majVersion + '.' + version;
      const item = { Text: txtVersion, Version: version };
      list.push(item);
      version = version - 1;
    }

    if (this.isMount) {
      this.setState(
        {
          cmSheetVersions: list,
          selectedVersion: list[0]
        }
      );
    }
  };

  onAddClick = event => {
    event.preventDefault();

    if (!this.state.selectedCustomer) {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: true,
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleMessageDialog(
        'Please fix the highlighted errors to continue',
        'Error'
      );
      return;
    } else if (!this.state.selectedProject) {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: true,
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleMessageDialog(
        'Please fix the highlighted errors to continue',
        'Error'
      );
      return;
    } else if (!this.state.selectedVersion) {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: true,
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleMessageDialog(
        'Please fix the highlighted errors to continue',
        'Error'
      );
      return;
    } else {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: false
        });
      }
    }

    let cmsList = this.state.CMSheetToConsolidate.filter(items => items.CMSVersion === this.state.selectedVersion.Text && items.ProjectName === this.state.selectedProject.DisplayValue);
    if (cmsList.length > 0) {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      const message = 'This version of CMS ' + this.state.selectedProject.DisplayValue + ' is already added';
      const title = 'Error';
      this.toggleMessageDialog(message, title);
      return;
    }

    let fields = {};
    let consCMSList;

    if (this.state.selectedVersion) {
      fields.CMSVersion = this.state.selectedVersion.Text;
    }

    if (this.state.selectedVersion) {
      fields.version = this.state.selectedVersion;
    }

    if (this.state.selectedProject) {
      fields.ProjectName = this.state.selectedProject.DisplayValue;
      fields.cmSheetID = this.state.selectedProject.CMSheets[0].id;
      fields.Region = this.state.selectedProject.Region.Name;
      fields.CurrencyId = this.state.selectedProject.Region.CurrencyId;

      for(let status in ProjStatuses){
        // Project
        if(this.state.selectedProject.Status && this.state.selectedProject.Status !== null){
          if(ProjStatuses[status].value == this.state.selectedProject.Status){
            fields.ProjectOrCrStatus = ProjStatuses[status].name;
          }
        }
        // Cr
        if(this.state.selectedProject.StatusId && this.state.selectedProject.StatusId !== null){
          if(ProjStatuses[status].value == this.state.selectedProject.StatusId){
            fields.ProjectOrCrStatus = ProjStatuses[status].name;
          }
        }
      }

      if (!this.state.cmsRegion) {
        if (this.isMount) {
          this.setState({
            cmsRegion: fields.Region
          });
        }
      }
      if (this.state.cmsRegion) {
        if (this.state.cmsRegion !== this.state.selectedProject.Region.Name) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          const message = 'CMS default currency do not match with the Customer Region currency. Please Note that this cannot be consolidated. Remove the CMS and try again';
          const title = 'Error';
          this.toggleMessageDialog(message, title);
          return;
        }
      }
    }

    if (this.state.selectedProject) {
      fields.ProjId = this.state.selectedProject.id;
    }

    if (this.state.cmsStatus) {
      fields.Status = this.cmsStatuses[this.state.cmsStatus];
    }

    if (this.isMount) {
      this.setState(prevState => {
        this.setState({ rowCount: prevState.rowCount + 1 });
        fields.id = this.state.rowCount;
      });
    }

    this.state.CMSheetToConsolidate.push(fields);

    consCMSList = this.state.CMSheetToConsolidate;

    let cmSheetIdList;

    let cmSheetVersionAndIdlist;
    const versionObj = {
      id: this.state.selectedProject.CMSheets[0].id,
      Version: this.state.selectedVersion.Version
    };

    this.state.cmSheetIdAndVersionList.push(versionObj);

    cmSheetVersionAndIdlist = this.state.cmSheetIdAndVersionList;

    this.state.IdListArray.push('\'' + this.state.selectedProject.CMSheets[0].id + this.state.selectedVersion.Text + '\'');

    cmSheetIdList = this.state.IdListArray;

    if (this.isMount) {
      this.setState({
        CMSheetToConsolidate: consCMSList,
        IdListArray: cmSheetIdList,
        cmSheetIdAndVersionList: cmSheetVersionAndIdlist,
        consolidationCurrency: fields.CurrencyId
      });
    }
  };

  OnDelete = async (event, id, Version, CMSheetID, CMSheetVersion) => {
    event.preventDefault();

    if (this.isMount) {
      this.setState({
        deleteId: id,
        deleteVersion: Version,
        consolidationCurrency: null,
        removedCMsheetId: CMSheetID,
        removedCMSheetVersion: CMSheetVersion
      });
    }

    this.toggleConfirmDialog('Are you sure you want to delete this item?', null);

  };

  onDeleteOk = () => {
    let cmSheetIdAndVersionList = [...this.state.cmSheetIdAndVersionList];

    const cmSheetIdToRemove = this.state.removedCMsheetId;
    const cmSheetVersionToRemove = this.state.removedCMSheetVersion;
    let tempCmSheetIdAndVersionList = [];
    cmSheetIdAndVersionList.forEach(function (data) {
      if (data.id === cmSheetIdToRemove && data.Version === cmSheetVersionToRemove) {
      } else {
        const versionObj = {
          id: data.id,
          Version: data.Version
        };
        tempCmSheetIdAndVersionList.push(versionObj);
      }
    });


    let IdList = [...this.state.IdListArray];
    IdList = IdList.filter(item => item !== '\'' + this.state.deleteVersion + '\'');

    let array = [...this.state.CMSheetToConsolidate];
    array = array.filter(item => item.id !== this.state.deleteId);

    if (this.isMount) {
      this.setState({
        CMSheetToConsolidate: array,
        IdListArray: IdList,
        cmSheetIdAndVersionList: tempCmSheetIdAndVersionList
      });
    }
    if (!array || array.length === 0) {
      if (this.isMount) {
        this.setState({
          cmsRegion: null
        });
      }
    }
    this.toggleConfirmDialog('', null);
  };

  handleOnChangeInput = event => {
    const field = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (this.isMount) {
      this.setState(prevState => {
        return {
          [field]: value
        };
      });
    }
  };

  filterDataCustomer(filter) {
    const data = this.state.customerNamesAll.slice();
    return filterBy(data, filter);
  }

  filterChangeCustomer = event => {
    if (this.isMount) {
      this.setState({
        customerNames: this.filterDataCustomer(event.filter)
      });
    }
  };

  filterDataProject(filter) {
    const data = this.state.projectNamesAll.slice();
    return filterBy(data, filter);
  }

  filterChangeProject = event => {
    if (this.isMount) {
      this.setState({
        projectNames: this.filterDataProject(event.filter)
      });
    }
  };

  render() {
    return (

      <div className="main-card">

        <form className="mb-3" onSubmit={this.submitSearch}>

          <div className="main-heading" />
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                <div className=""><label className="mandatory">Customer:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    name="selectedCustomer"
                    textField="Name"
                    value={this.state.selectedCustomer}
                    data={this.state.customerNames}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeCustomer}
                    onChange={this.handleOnChangeCustomer}
                    required={true} />
                  {this.state.isErrorMessageVisible === true ? (
                    <span
                      className={this.validateProperty(
                        this.state.selectedCustomer
                      )}
                    >
                      Please select Customer
    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                <div className=""><label className="mandatory">Project/CR:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    name="selectedProject"
                    textField="DisplayValue"
                    value={this.state.selectedProject}
                    data={this.state.projectNames}
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeProject}
                    filterable={true}
                    onFilterChange={this.filterChangeProject}
                    disabled={this.state.disableProject}
                    required={true}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span
                      className={this.validateProperty(
                        this.state.selectedProject
                      )}
                    >
                      Please select Project/CR
                                                </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                <div className=""><label className="mandatory">CMS version:</label></div>
              </div>
              <div className="row">
                <div className="col-md-10">
                  <div className="d-block">
                    <ComboBox
                      dataItemKey="Version"
                      textField="Text"
                      value={this.state.selectedVersion}
                      data={this.state.cmSheetVersions}
                      placeholder={'Please Select'}
                      onChange={this.handleOnChangeVersion}
                      disabled={this.state.disableVersion}
                      required={true}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span
                        className={this.validateProperty(
                          this.state.selectedVersion
                        )}
                      >
                        Please select Version
                                                </span>
                    ) : null}
                  </div>
                </div>



                <div className="col-md-2">
                  <Button primary={true} type="submit" onClick={this.onAddClick} className="rounded-btn">
                    <span className="k-icon k-i-plus" />
                  </Button>
                </div>

              </div>
            </div>
          </div>
        </form>

        <div className="main-seperator" />
        <div className="row">
          <div className="col-md-12">
            <div className="row mt-1">
              <div className="col-md-12">
                <Grid
                  data={orderBy(this.state.CMSheetToConsolidate, this.state.sort)}
                  skip={this.state.skip}
                  take={this.state.take}
                  total={this.state.CMSheetToConsolidate.length}
                  pageable={true}
                  onPageChange={this.pageChange}
                  resizable
                  sortable={true}
                  sort={this.state.sort}
                  onSortChange={(e) => {
                    this.setState({
                      sort: e.sort
                    });
                  }
                  }
                  className="search-result-grid"
                >
                  <Column field="ProjectName" title="Project/CR" />
                  <Column field="ProjectOrCrStatus" title="Project Status" />
                  <Column field="CMSVersion" title="CMS version" />
                  <Column field="Status" title="CMS Status" />
                  <Column field="Region" title="Billing Region" />
                  <Column
                    title="Action"
                    cell={props => (
                      <DeleteCell
                        {...props}
                        OnDelete={this.OnDelete}
                      />
                    )}
                    width="120px"
                  />
                </Grid>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ConsolidatedCMSheetChart
                  IdList={this.state.IdListArray.join(',')}
                  Currency={this.state.consolidationCurrency}
                  DesingationsAll={this.state.DesignationsAll}
                  RateTypes={this.state.RateTypes}
                  RegionsAll={this.state.RegionsAll}
                  ExpenseTypesAll={this.state.ExpenseTypesAll}
                  VersionAndIDList={this.state.cmSheetIdAndVersionList}
                />
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showMessageDialog === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

              {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success" />
                </div>
              )}

              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image" />
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

              <DialogActionsBar>
                {this.state.showSuccessImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                    OK
                </button>
                )}

                {this.state.showErrorImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                    OK
                </button>
                )}
              </DialogActionsBar>
            </Dialog>
          )
        }

        {
          this.state.showConfirmDialog === true && (
            <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
              <DialogActionsBar>
              <button className="k-button " onClick={this.toggleConfirmDialog}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.onDeleteOk}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )
        }
      </div >
    );
  }
}

export default ConsolidatedCMSheet;



