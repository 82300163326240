import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { getRoleList, searchRoles, updateRoleStatus, getPermissionList } from './RoleService';
import CreatedDateCell from './components/CreatedDateCell';
import UpdatedDateCell from './components/UpdatedDateCell';
import StatusCell from './components/StatusCell';
import EditRoleActionsCell from './components/EditRoleActionsCell';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';

import { Link } from 'react-router-dom';
import { orderBy,filterBy } from "@progress/kendo-data-query";
import {
    ExcelExport,
    ExcelExportColumn
  } from '@progress/kendo-react-excel-export';
import Loader from "../../integration/components/Loader";
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import moment from 'moment';
import 'moment-timezone';

class RoleManagement extends Component {
    _export;
    isMount = false;

    componentDidMount() {
        this.isMount = true;
        window.scrollTo(0, 0);
        this.populateRoleList();
        this.populatePermissionList();
        this.setHeaderTitle();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            roleList: [],
            skip: 0,
            take: 20,
            SearchRequest: {
                searchStatus : { Name: 'Active', status: true }
            },
            deactivateRoleId: 0,
            deactivatePopupVisible: false,
            searchStatus: null,
            status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
            activateRoleId: 0,
            activatePopupVisible: false,
            sort: [{ field: 'updatedAt', dir: 'desc' }],
            loading: false,
            permissions:[],
            allPermissions:[]
        };

        this.handlePermissionFilterChange = this.handlePermissionFilterChange.bind(this);
        this.handlePermissionChange = this.handlePermissionChange.bind(this);
        this.exportData = this.exportData.bind(this);
        
    }

    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Role Management');
        }
    };
    populateRoleList = () => {
        this.state.loading = true;
        searchRoles(this.state.SearchRequest).then(res => {
            this.setState({
                roleList: res.data,
                loading: false,
                skip: 0
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    pageChange = event => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    };

    tagRender = (tagData, li) =>
    React.cloneElement(li, li.props, [
      <span key={this.state.permissions.indexOf(tagData.data[0])} className="custom-tag">
        {tagData.data[0].PermissionName}
      </span>,
      li.props.children[1],
    ]);

    render() {
        return (
            <form onSubmit={this.submitSearch} >
                <div className="main-card">

                    <div className="row">
                        <div className="col-md-8">
                            <div className="main-heading">Role Management Search</div>
                        </div>

                        <div className="col-md-4">
                            <Button primary={true} className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 || window.USER_PERMISSION.indexOf('ROLE_MGT') > -1)}>
                                <Link style={{ textDecoration: 'none', color: 'white' }}
                                    to={{
                                        pathname: '/admin/createRole/createRole',
                                        action: 'create'
                                    }}
                                >Create New Role </Link>
                            </Button>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-2">
                            <div className="d-block">
                                <div className=""><label>Role Name:</label></div>
                                </div>
                                <div className="d-block">
                                <div className="">
                                    <Input
                                        value={this.state.SearchRequest.Name}
                                        onChange={this.handleOnChangeInput}
                                        name="Name"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-block">
                                <div className=""><label>Permission:</label></div>
                                </div>
                                <div className="d-block">
                                <div className="">
                                    <MultiSelect
                                        name="Permission"
                                        value={this.state.SearchRequest.Permission}
                                        data={this.state.permissions}
                                        placeholder={'Please Select'}
                                        filterable={true}
                                        textField="SearchString"
                                        dataItemKey="id"
                                        onChange={this.handlePermissionChange}
                                        onFilterChange={this.handlePermissionFilterChange}
                                        tagRender={this.tagRender}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <div className=""><label>Status:</label></div>
                                </div>
                                <div className="d-block">
                                <div className="">
                                    <ComboBox
                                        name="searchStatus"
                                        textField="Name"
                                        dataItemKey="status"
                                        value={this.state.SearchRequest.searchStatus}
                                        data={this.state.status}
                                        placeholder={'Please Select'}
                                        onChange={this.handleOnChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                        </div>

                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                        <Button primary={true} type="submit" onClick={this.submitSearch}>Search</Button>
                            <Button type="button" onClick={this.cancelSearch}>Clear</Button>
                        </div>
                        </div>
                    

                    <div className="main-seperator"></div>

                    <div className="row">
                        <div className="col-md-10"><div className="main-heading">Search Results</div></div>
                        <div className="col-md-2 btn-align-right">
                            <button
                                title="Export to Excel"
                                className="k-button k-primary"
                                onClick={this.exportData}
                            >
                                Export to Excel
                            </button>
                        </div>
                    </div>

                    <div className="row mt-0">
                        <div className="col-md-12">
                            <ExcelExport
                                ref={(exporter) => {
                                    this._export = exporter;
                                }}
                                fileName={'Roles_' + new Date()}
                            >
                                <ExcelExportColumn field="Rolename" title="Role Name" />
                                <ExcelExportColumn field="RoleDescription" title="Role Description" />
                                <ExcelExportColumn field="createdAt" title="Created Date" />
                                <ExcelExportColumn field="updatedAt" title="Last Modified Date" />
                                <ExcelExportColumn field="IsActive" title="Status" />
                                <ExcelExportColumn field="PermissionName" title="Permission Name" />
                                <ExcelExportColumn field="PermissionDescription" title="Permission Description" />
                            </ExcelExport>
                            <Grid data={orderBy(this.state.roleList, this.state.sort).slice(this.state.skip, this.state.take + this.state.skip)}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.roleList.length}
                                pageable={true}
                                onPageChange={this.pageChange}
                                resizable
                                sortable={true}
                                sort={this.state.sort}
                                onSortChange={(e) => {
                                    this.setState({
                                        sort: e.sort
                                    });
                                }
                                }
                                className="search-result-grid"
                            >
                                <Column field="Rolename" title="Role Name" />
                                <Column field="RoleDescription" title="Role Description" />
                                <Column field="createdAt" title="Created Date" cell={props => <CreatedDateCell {...props} />} />
                                <Column field="updatedAt" title="Last Modified Date" cell={props => <UpdatedDateCell {...props} />} />
                                <Column field="IsActive" title="Status" cell={props => <StatusCell {...props} />} />
                                <Column
                                    field=""
                                    title="Actions"
                                    cell={props => <EditRoleActionsCell {...props} OnDeactivate={this.OnDeactivate} OnActivate={this.OnActivate} />}
                                />
                            </Grid>
                        </div>
                    </div>
                    <div>
                        {this.state.deactivatePopupVisible && (
                            <Dialog title={'Please Confirm'} onClose={this.deactivateConfirmationClose}>
                                <p style={{ margin: '25px', textAlign: 'center' }}>
                                    Are you sure you want to Deactivate this Role?
                            </p>
                                <DialogActionsBar>
                                    <button className="k-button" onClick={this.deactivateConfirmationClose}>
                                        No
                                </button>
                                    <button className="k-button modal-primary" onClick={this.deactivateConfirmationOk}>
                                        Yes
                                </button>
                                </DialogActionsBar>
                            </Dialog>
                        )}
                    </div>
                    <div>
                        {this.state.activatePopupVisible && (
                            <Dialog title={'Please Confirm'} onClose={this.activateConfirmationClose}>
                                <p style={{ margin: '25px', textAlign: 'center' }}>
                                    Are you sure you want to Activate this Role?
                            </p>
                                <DialogActionsBar>
                                    <button className="k-button" onClick={this.activateConfirmationClose}>
                                        No
                                </button>
                                    <button className="k-button modal-primary" onClick={this.activateConfirmationOk}>
                                        Yes
                                </button>
                                </DialogActionsBar>
                            </Dialog>
                        )}
                    </div>
                    <Loader loading={this.state.loading} />
                </div>
            </form>
        );
    }

    submitSearch = event => {
        event.preventDefault();

        this.setState({
            loading: true
        });

        this.setState(
            prevState => {
                const SRequest = {
                    ...prevState.SearchRequest
                };
                return {
                    SearchRequest: SRequest
                };
            },
            () => {
                if (this.state.SearchRequest.Name || this.state.SearchRequest.searchStatus || this.state.SearchRequest.Permission) {
                    searchRoles(this.state.SearchRequest).then(res => {
                        this.setState({
                            roleList: res.data,
                            loading: false,
                            skip: 0
                        });
                    })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });
                }
                else {
                    this.populateRoleList();
                }
            }
        );
    };

    cancelSearch = event => {
        event.preventDefault();
        this.setState({
            SearchRequest: {
                Name: '',
                searchStatus: '',
                Permission:[],
            }
        });
        this.populateRoleList();
    };

    handleOnChangeInput = event => {
        const field = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        this.setState(prevState => {
            return {
                SearchRequest: { ...prevState.SearchRequest, [field]: value }
            };
        });
    };

    OnDeactivate = async (event, id) => {
        event.preventDefault();
        await this.setState({
            deactivateRoleId: id
        });
        this.deactivateConfirmationClose();
    };

    deactivateConfirmationClose = async () => {
        await this.setState({
            deactivatePopupVisible: !this.state.deactivatePopupVisible
        });
    };

    deactivateConfirmationOk = async () => {
        await updateRoleStatus({ IsActive: false }, this.state.deactivateRoleId)
            .then(response => {
                this.toggleDialog('The Role has been Deactivated successfully', 'Success');
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        await this.populateRoleList();
        await this.setState({
            deactivatePopupVisible: false,
            loading: false
        });
    };

    OnActivate = async (event, id) => {
        event.preventDefault();
        await this.setState({
            activateRoleId: id
        });
        this.activateConfirmationClose();
    };

    activateConfirmationClose = async () => {
        await this.setState({
            activatePopupVisible: !this.state.activatePopupVisible
        });
    };

    activateConfirmationOk = async () => {
        await updateRoleStatus({ IsActive: true }, this.state.activateRoleId)
            .then(response => {
                this.toggleDialog('The Role has been Activated successfully', 'Success');
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        await this.populateRoleList();
        await this.setState({
            activatePopupVisible: false,
            loading: false
        });
    };

    populatePermissionList = () => {
        getPermissionList().then(response => {
            response.data.forEach(item=>{
                item = Object.defineProperty(item, 'SearchString', {
                    value: item.PermissionName + '-' +item.PermissionDescription,
                    writable: false
                  });
            });

            this.setState({
                permissions: response.data,
                allPermissions :response.data,
            });
        }).catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    handlePermissionFilterChange = event => {
        this.setState({
            permissions: this.filterPermissionsData(event.filter)
        });
    };

    filterPermissionsData(filter) {
        const data = this.state.allPermissions.slice();
        return filterBy(data, filter);
    };

    handlePermissionChange = async event => {
        if (this.isMount) {
            const field = event.target.name;
            const value = event.target.value;
            this.setState(prevState => {
                return {
                    SearchRequest: { ...prevState.SearchRequest, [field]: value }
                };
            });
        }
    };

    exportData = event =>{
        try {
            if(this.state.roleList && this.state.roleList.length >0){
                const ExportData = [];
                this.state.roleList.forEach(obj =>{
                    let object = {
                        Rolename : obj.Rolename,
                        RoleDescription: obj.RoleDescription,
                        createdAt: moment(obj.createdAt).format("MM/DD/YYYY"),
                        updatedAt: moment(obj.updatedAt).format("MM/DD/YYYY"),
                        IsActive: obj.IsActive ? 'Active' : 'Inactive'
                    };

                    if(obj.Permissions && obj.Permissions.length > 0){
                        object.PermissionName = obj.Permissions.map(x => x.PermissionName).join(", ");
                        object.PermissionDescription = obj.Permissions.map(x => x.PermissionDescription).join(", ");
                    }

                    ExportData.push(object);
                });

                this._export.save(ExportData);
            }
            
          } catch (error) {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          }
    }
}

export default RoleManagement;
