import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
    getDepartmentHeads,
    addDepartmentHead
  } from './PracticeService';
  import { Redirect } from 'react-router';
import Loader from '../../integration/components/Loader';
import * as loggerService from "../../../src/integration/LoggerService";
import {LOG_TYPES} from "../../../src/integration/IntegrationEnums";
import SuccessImg from '../../../src/ui/static/images/checked.svg';
import ErrorImg from '../../../src/ui/static/images/cancel.svg';
import { filterBy } from '@progress/kendo-data-query';

class EditDepartment extends Component {
    isMount = false;

    constructor(props){
        super(props);

        this.state = {
            departmentName: this.props.location.data ? this.props.location.data.Name : "",
            departmentheads: [],
            departmentheadsAll: [],
            selectedDepartmentHead: [],
            visibleCancelConfirmDialog: false,
            isMsgOpened: false,
            successMsg: '',

            showSuccessImage: false,
            showErrorImage: false,
            dialogTitle: "",
            dialogMessage: "",
            visible: false,
            isFormDirty: false,
            redirectToSearchDepartment: false
            
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0);
        this.isMount = true;
        this.getDepartmentHeadData();
        this.populateEditData();
        this.setHeaderTitle();
    }

    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Edit Department');
        }
    };

    getDepartmentHeadData = async () =>{
        await getDepartmentHeads().then(response => {
            this.setState({
                departmentheads: response.data[0],
                departmentheadsAll: response.data[0]
            });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });;
    }

    populateEditData = async () =>{
        if (this.props.location.data) {
            this.setState({
                selectedDepartmentHead: this.props.location.data.User
            });
        }
    }

    filterDataDepartmentHead(filter) {
        const data = this.state.departmentheadsAll.slice();
        return filterBy(data, filter);
    }
    
    filterChangeDepartmentHead = event => {
        if (this.isMount) {
            this.setState({
                departmentheads: this.filterDataDepartmentHead(event.filter)
            });
        }
    };

    handleDepartmentHeadChange = async event =>{
        const valueObj = event.target.value;
        if (valueObj) {
            await this.setState({
                selectedDepartmentHead: event.target.value,
                isFormDirty: true
            });
        } else {
            await this.setState({
                selectedDepartmentHead: [],
                isFormDirty: true
            });
        }  
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    toggleCancelConfirmaDialog() {
        this.setState({
            visibleCancelConfirmDialog: !this.state.visibleCancelConfirmDialog
        })
    }

    onClickSave = async () => {
        if (this.state.selectedDepartmentHead && this.state.selectedDepartmentHead.length <= 0) {
            this.setState({
                showSuccessImage: false,
                showErrorImage: true
            })
            let msg = "Please select a Department Head to continue.";
            this.toggleDialog(msg, "Error")
        } else {
            await addDepartmentHead(this.props.location.data.id, this.state.selectedDepartmentHead).then (response =>{
                this.setState({
                    isFormDirty: false,
                    showErrorImage: false,
                    showSuccessImage: true
                })
                let msg = "Department head was succcessfully updated.";
                this.toggleDialog(msg, "Success")
            }).catch(error => {
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                })
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleDialog(error.response.data, 'Error');
            }); 
        }
    }

    toggleDialog = (message, title) => {
        this.setState({
            visible: !this.state.visible,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    onClickCancel = event => {
        event.preventDefault();;
        if(this.state.isFormDirty === true){
            this.toggleCancelConfirmaDialog();
        } else {
            this.redirectToUserSearchDepartment();
        }
    };

    redirectToUserSearchDepartment = () => {
        this.setState({
            redirectToSearchDepartment : true
        })
    }

    render () {

        if (this.state.redirectToSearchDepartment === true) {
            return <Redirect to="/masterdata/practice/practice" />;
        }

        return (
                <div className="main-card">
                    <div className="main-heading">Department Details</div>
                    <div className="main-seperator"/>
                    <div className="row">
                        <div className="col-md-3">
                        <div className="d-block">
                            <label>Department Name:</label>
                            </div>
                            <div className="d-block">
                            <div className="">
                            <Input
                                value={this.state.departmentName}
                                onChange={this.handleOnChangeInput}
                                name="PracticeName"
                                autoComplete="off"
                                disabled    
                            />
                            </div>
                        </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-3">
                            <div className="d-block">
                                <div className="mandatory"><label>Department Head:</label></div>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <ComboBox
                                        data={this.state.departmentheads}
                                        dataItemKey="id"
                                        textField="displayName"
                                        name="selectedDepartmentHead"
                                        required={true}
                                        onChange={this.handleDepartmentHeadChange}
                                        filterable={true}
                                        onFilterChange={this.filterChangeDepartmentHead}
                                        placeholder="Please Select"
                                        value={this.state.selectedDepartmentHead}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                        <Button primary={true} type="submit" onClick={() => this.onClickSave()}>
                            Save
                        </Button>
                        <Button type="button" onClick={this.onClickCancel} style={{ marginRight: '5px' }}>
                            Cancel
                        </Button>
                        </div>
                    </div>
                    <div>
            
            {this.state.visibleCancelConfirmDialog && (
              <Dialog title={'Please Confirm'} onClose={()=> this.toggleCancelConfirmaDialog()}>
                <p style={{ margin: '25px', textAlign: 'center' }}>
                    Do you wish to discard the unsaved data?
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={()=> this.toggleCancelConfirmaDialog()}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={()=> this.redirectToUserSearchDepartment()}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog> 
            )}

            {this.state.visible === true && (
                <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                    <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                    </div>
                )}

                {this.state.showErrorImage === true && (
                    <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error"/>
                    </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                    {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.redirectToUserSearchDepartment}>
                        OK
                    </button>
                    )}

                    {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                        OK
                    </button>
                    )}
                </DialogActionsBar>
                </Dialog>
            )}
                    </div>
                </div>
        )
    }
}

export default EditDepartment;