import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class CreatedDateCell extends Component {
    render() {
        const value = this.props.dataItem.createdAt;
        if (this.props.dataItem.Type === 'RESOURCE_REQUEST_PENDING_APPROVAL') {
            return <td> <Moment format="MM-DD-YYYY">{this.props.dataItem.updatedAtOrderBy}</Moment></td>;
        } else {
            return <td><Moment format="MM-DD-YYYY">{value}</Moment></td>;
        }        
    }
}

export default CreatedDateCell;
