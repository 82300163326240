import React, { Component } from 'react';

import { Calendar } from "@progress/kendo-react-dateinputs";

export class CustomCalendar extends Component {
  render() {
    return (
      <Calendar
        bottomView="year"
        topView="year"
        value={this.props.value}
        onChange={this.props.onChange}
        min={this.props.min}
        max={this.props.max}
      />
    );
  }
}

export default CustomCalendar;