import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy } from '@progress/kendo-data-query';
import { Redirect } from 'react-router';
import { Tooltip } from 'reactstrap';
import {
  getAllDesignations,
  getRateCardRoles,
  saveActivatedDesignationRateCard,
  saveDesignation,
  updateDesignation
} from './DesignationService';
import {
  getDesignationGroupsIntegrated,
  getBillingDiviDropDownIntegrated,
  getRateCardsForGridIntegrated
} from '../../integration/MasterDataService';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

const billingDiviDataAll = [];

class DesignationCreate extends Component {

  default;
  isMount = false;

  popupSet = {
    width: 'auto'
  };

  constructor(props) {
    super(props);
    console.log(window.LOGGED_USER);
    this.state = {
      designationNameToolTipOpen: false,
      toDesignation: false,
      designationText: '',
      designationGroupValue: null,
      designationId: null,
      designationActive: false,
      isProjectRole: false,
      visible: false,
      success: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      isEnableCreate: false,
      isEnableUpdate: false,
      isDisableCombo: false,
      isDisableEditMode: false,
      isDisableSave: false,
      isSaveButtonClicked: false,
      billingDiviData: [],
      SelectedBillingDivision: null
    };

    this.handleChangeDesignationName = this.handleChangeDesignationName.bind(this);
    this.handleChangeDesignationGroup = this.handleChangeDesignationGroup.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    this.setHeaderTitle();
    await this.populateBillingDivisions();
    if (this.props.location.action === 'edit') {
      this.updateDesignationDetails(this.props.location.designation);
    }

    await getDesignationGroupsIntegrated()
      .then(response => {
        this.designationGroupDataAll = response.data.filter(el => el.IsActive === true && el.SFCode !== null);
        if (this.isMount) {
          this.setState({
            designationGroupData: response.data.filter(el => el.IsActive === true && el.SFCode !== null)
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async () => {
    if (this.isMount) {
      if (this.props.location.action === 'edit') {
        this.props.onHeaderTitleChange('Edit Designations');
      } else {
        this.props.onHeaderTitleChange('Create Cost Designations');
      }
    }
  };

  populateBillingDivisions = async () => {
    await getBillingDiviDropDownIntegrated()
        .then(response => {
            this.billingDiviDataAll = response.data.filter(el => el.IsActive === true && el.SFCode !== null);
            this.setState({
              billingDiviData: response.data.filter(el => el.IsActive === true && el.SFCode !== null)
            });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
  };

  render() {
    if (this.state.toDesignation === true) {
      return <Redirect to="/admin/designation/designation"/>;
    }
    return (
      <React.Fragment>
        <div className="main-card">
          <div className="main-heading">Cost Designation Details</div>
          <div className="main-seperator"></div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                  <label className="mandatory">Cost Designation Name:</label>
                </div>
                <div className="d-block">
                <div className="" id="designationNameToolTip">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="designationText"
                    required={true}
                    maxLength="100"
                    value={this.state.designationText}
                    onChange={this.handleChangeDesignationName}
                    disabled={this.state.isEditMode}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.designationText)}>
                                            Please enter a Designation name
                                        </span>
                  ) : null}
                  {this.state.designationText ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.designationNameToolTipOpen}
                      target="designationNameToolTip"
                      toggle={this.toggleDesignationNameToolTip}
                    >
                      {this.state.designationText}
                    </Tooltip>
                  ) : null}
                </div>
              </div>

            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                  <label className="mandatory">Designation Group:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                    placeholder="Please Select"
                    name="designationGroup"
                    data={this.state.designationGroupData}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleChangeDesignationGroup}
                    required={true}
                    value={this.state.designationGroupValue}
                    filterable={true}
                    onFilterChange={this.filterChangeDesignationGroup}
                    disabled={this.state.isEditMode}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.designationGroupValue)}>
                                            Please select a Designation Group
                                        </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                  <label className="mandatory">Department:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                      filterable={true}
                      onFilterChange={this.filterPractice}
                      placeholder="Please select"
                      name="SelectedBillingDivision"
                      data={this.state.billingDiviData}
                      textField="Name"
                      dataItemKey="id"
                      required={true}
                      onChange={this.handlePracticeOnChange}
                      value={this.state.SelectedBillingDivision}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.SelectedBillingDivision)}>
                                            Please select a Practice/Department
                                        </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                <div className="">
                  <div className="k-form-field">
                    <input
                      type="checkbox"
                      id="ch1"
                      className="k-checkbox"
                      value={this.state.isProjectRole}
                      onChange={this.handleChangeProjectRole}
                      checked={this.state.isProjectRole}
                      name="isProjectRole"
                    />
                    <label className="k-checkbox-label" htmlFor="ch1">
                      Project Role
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 btn-align-right">
                  <Button primary={true} disabled={this.state.isDisableSave} type="submit"
                          onClick={this.handleSubmit}>Save</Button>
                  <Button onClick={this.onClickCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.onClickClose} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error"/>
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.navigateBack}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.showCancelConfirmation === true && (
              <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contains unsaved changes. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.navigateBack}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  navigateBack = event => {
    if (this.isMount) {
      this.setState({
        toDesignation: true
      });
    }
  };

  handleChangeDesignationGroup = async event => {
    if (this.isMount) {
      await this.setState({
        designationGroupValue: event.target.value,
        isFormDirty: true
      });
    }
    await this.validateProperty(event.target.value);
  };

  handleChangeDesignationName = async event => {
    if (this.isMount) {
      await this.setState({
        designationText: event.target.value,
        isFormDirty: true
      });
    }
    await this.validateProperty(event.target.value);
  };

  toggleDesignationNameToolTip = () => {
    if (this.isMount) {
      this.setState({
        designationNameToolTipOpen: !this.state.designationNameToolTipOpen
      });
    }
  };

  filterChangeDesignationGroup = event => {
    if (this.isMount) {
      this.setState({
        designationGroupData: this.filterDataDesignationGroup(event.filter)
      });
    }
  };

  filterDataDesignationGroup(filter) {
    const data = this.designationGroupDataAll.slice();
    return filterBy(data, filter);
  }

  handleChangeProjectRole = event => {
    const field = event.target.name;
    // const value = event.target.value;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (this.isMount) {
      this.setState({
        [field]: value,
        isFormDirty: true
      });
    }
  };

  handlePracticeOnChange = async event => {
    if (this.isMount) {
      await this.setState({
        SelectedBillingDivision: event.target.value,
        isFormDirty: true
        });
      }
  };

  filterPractice= event => {
    if (this.isMount) {
      this.setState({
        billingDiviData: this.filterDataPractice(event.filter)
      });
    }
  };

  filterDataPractice(filter) {
    const data = this.billingDiviDataAll.slice();
    return filterBy(data, filter);
  }

  updateDesignationDetails = designation => {
    if (this.isMount) {
      this.setState({
        isDisableEditMode: true
      });
    }
    if (designation) {
      this.isEnableCreate = true;
      this.isEnableUpdate = false;
      let designationGroupObj = null;
      let projectRoleValue = false;
      let designationStatus = false;
      let billingDivision = null;

      if (designation.DesignationCategory) {
        designationGroupObj = {
          Name: designation.DesignationCategory.Name,
          id: designation.DesignationCategory.id
        };
      }

      if (designation.IsProjectRole) {
        projectRoleValue = true;
      }

      if (designation.IsActive) {
        designationStatus = true;
      }

      if(designation.BillingDivision){
        billingDivision = {
          id: designation.BillingDivision.id,
          Name: designation.BillingDivision.Name
        }
      }
      if (this.isMount) {
        this.setState({
          designationText: designation.Name,
          designationGroupValue: designationGroupObj,
          designationId: designation.id,
          isProjectRole: projectRoleValue,
          designationActive: designationStatus,
          SelectedBillingDivision: billingDivision
        });
      }
    } else {
      return null;
    }
  };


  handleSubmit = event => {
    event.preventDefault();
    console.dir(event.target);
    if (!this.validation()) {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: true
        });
        this.setState({
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: false
        });
      }
      if (this.props.location.action === 'edit') {
        this.updateDesignation(event);
      } else {
        this.createDesignation(event);
      }
      if (this.isMount) {
        this.setState({
          isDisableSave: true
        });
      }
    }
  };

  createDesignation = event => {
    let designation;
    if (this.isMount) {
      designation = {
        Name: this.state.designationText,
        CategoryId: this.state.designationGroupValue.id,
        IsProjectRole: this.state.isProjectRole,
        IsActive: true,
        updatedUser: window.LOGGED_USER.displayName,
        BillingDivisionId: this.state.SelectedBillingDivision != null ? this.state.SelectedBillingDivision.id : null
      };
    }

    saveDesignation(designation)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(`The Designation ${res.data.Name} is successfully created`, 'Success');
      })

      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            isDisableSave: false,
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  updateDesignation = event => {
    let ratecardfilteredArray = [];
    let recordId;
    let allRateCards = [];
    let ratecardid = [];
    let ratecardRoles = [];
    let ratecardRolesIds = [];
    let recordIdWithProRole = 0;
    let allDesignations = 0;

    const designation = {
      Name: this.state.designationText,
      CategoryId: this.state.designationGroupValue.id,
      IsProjectRole: this.state.isProjectRole,
      IsActive: this.state.designationActive,
      updatedUser: window.LOGGED_USER.displayName,
      BillingDivisionId: this.state.SelectedBillingDivision != null ? this.state.SelectedBillingDivision.id : null
    };

    updateDesignation(designation, this.state.designationId)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          },
              async ()  => {
                await getRateCardsForGridIntegrated().then(res => {
                  allRateCards = res.data;

                  for (const ratecard of allRateCards) {
                    ratecardid.push(ratecard.id);
                  }
                })
                    .catch(error => {
                      loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                 await getAllDesignations().then(res => {
                  allDesignations = res.data
                  for (const desig of allDesignations) {
                    if(desig.id === recordId && desig.IsProjectRole === true){
                      recordIdWithProRole = recordId;
                    }
                  }
                })
                    .catch(error => {
                      loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if(recordIdWithProRole !== 0) {
                  await getRateCardRoles()
                      .then(res => {
                        ratecardRoles = res.data;
                        let extractedArray = ratecardRoles.filter(obj => obj.RoleId === recordIdWithProRole);
                        if (extractedArray.length > 0) {
                          for (const rec of extractedArray) {
                            ratecardRolesIds.push(rec.RateCardId);
                          }
                        }
                        let uniquerateIds = ratecardid.filter(function (obj) {
                          return ratecardRolesIds.indexOf(obj) === -1;
                        });

                        if (uniquerateIds.length > 0) {
                          for (const detail of uniquerateIds) {

                            const ratecardDesignation = {
                              RateCardId: detail,
                              RoleId: recordIdWithProRole,
                              Rate: 0
                            };

                            ratecardfilteredArray.push(ratecardDesignation);
                          }

                          saveActivatedDesignationRateCard(ratecardfilteredArray);
                        }
                      })
                      .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                      });
                }
          });
        }
        this.toggleDialog('The Designation has been successfully updated', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            isDisableSave: false,
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  validation = () => {

    if (
      this.validateProperty(this.state.designationText)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.designationGroupValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.SelectedBillingDivision)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleCancelConfirmDialog = () => {
    if (this.isMount) {
      this.setState({
        showCancelConfirmation: !this.state.showCancelConfirmation
      });
    }
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };

  onClickClose = event => {
    if (this.state.isDisableSave) {
      this.navigateBack(event);
    } else {
      this.toggleDialog();
    }
  };


}

export default DesignationCreate;
