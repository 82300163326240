import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;
const timeSheetViewRouter = apiUrl + '/timeSheetManagement/ViewTimeSheet/'
const timeSheetApproverRouter = apiUrl + '/timeSheetManagement/timeSheetApproval/'

/**
 * getResourceList - retirive task list
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
 export  function getResourceList(parms){
    const endPoint = timeSheetViewRouter + 'getResourceList';
    return  http.post(endPoint,parms);
}

/**
 * getProjectTaskAllocations by id - get project task allocation with the resource assigned
 * @returns {array}  returns an array of project task allocations
 * @param condition
 */
 export function getProjectTaskAllocations(taskId){
    const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/getAllocations/' + taskId;
    return http.get(endPoint)
  }

/**
 * getTaskList - retirive task list
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
export  function getTaskList(parms){
    const endPoint = timeSheetApproverRouter + 'getTaskList';
    return  http.post(endPoint,parms);
}

/**
 * getEnterdTotalHours - retirive calculations
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
 export  function getEnterdTotalHours(parms){
    const endPoint = timeSheetApproverRouter + 'getEnterdTotalHours';
    return  http.post(endPoint,parms);
}

/**
 * getCalculatedTotals - retirive calculations
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
 export  function getBilableCalculatedTotals(parms){
    const endPoint = timeSheetApproverRouter + 'getBilableCalculatedTotals';
    return  http.post(endPoint,parms);
}

/**
 * getTimeCards - retirive time entries
 * @returns {Object}  Object of saved details of  time entry
 * @param parms
 */
 export  function getTimeCards(parms){
    const endPoint = timeSheetApproverRouter + 'getTimeCards';
    return  http.post(endPoint,parms);
}

/**
 * getExcelData by params - get excel download data by given parameters
 * @returns {array}  returns an array of filtered excel data
 * @param params
 */

export function getExcelData(params){
    return http.post(`${apiUrl}/timesheetManagement/ViewTimeSheet/excel`, params);
}

/**
 * getChangeRequest by params - get projects by given parameters
 * @returns {array}  returns an array of projects
 * @param params
 */

export function getProjects(params){
    return http.post(`${apiUrl}/timesheetManagement/ViewTimeSheet/project`, params)
}

/**
 * getChangeRequest by params - get change request by given parameters
 * @returns {array}  returns an array of change request
 * @param params
 */

export function getChangeRequest(params){
    return http.post(`${apiUrl}/timesheetManagement/ViewTimeSheet/changeRequest`, params)
}

/**
 * getProjectTaskAllocations by id - get project task allocation with the resource assigned
 * @returns {array}  returns an array of project task allocations
 * @param condition
 */
 export function getProjectTaskAllocationsWithTime(data){
    const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/getAllocationswithtime';
    return http.post(endPoint, data);
  }

/**
 * getNonProjectTaskGroup by params - get non project task group by given parameters
 * @returns {array}  returns an array of project task allocations
 * @param params
 */

export function getNonProjectTaskGroup(params){
    return http.post(`${apiUrl}/timesheetManagement/ViewTimeSheet/group`, params)
}

/**
 * getNonProjectTak by params - get project task by given parameters
 * @returns {array}  returns an array of project task allocations
 * @param condition
 */

export function getProjectTask(params){
    return http.post(`${apiUrl}/timesheetManagement/ViewTimeSheet/projectTask`, params)
}

/**
 * getNonProjectTak by params - get non project task 
 * @returns {array}  returns an array of non project task
 * @param params
 */

export function getNonProjectTak(params){
    return http.post(`${apiUrl}/timesheetManagement/ViewTimeSheet/nonProjectTask`, params)
}

/**
 * getStatistics by given filters - hourly based time entries calculations by given filters
 * @returns {array}  returns an object of calculated statistics
 * @param condition
 */

export function getStatistics(params){
    return http.post(`${apiUrl}/timesheetManagement/ViewTimeSheet/statistics`, params)
}

/**
 * saveSearchCriteria - save search criterea
 * @returns 200 status
 * @param params
 */

export function saveSearchCriteria(params){
    return http.post(`${apiUrl}/timesheetManagement/ViewTimeSheet/searchCriteria`, params)
}

/**
 * getSearchCriteria by userId - get saved search criteria
 * @returns {array}  returns an object of saved search criteria
 * @param 
 */

export function getSearchCriteria(){
    return http.get(`${apiUrl}/timesheetManagement/ViewTimeSheet/searchCriteria`)
}

/**
 * listOfResource by filter - get all resource list by given filters
 * @returns {array}  returns an array of resource list
 * @param params
 */

export function listOfResource(params){
    return http.post(`${apiUrl}/timesheetManagement/ViewTimeSheet/resourceList`, params);
}

/**
 * resourceEntries by filters - get expaned resource time entries
 * @returns {array}  returns an array of resource time entries
 * @param params
 */

export function resourceEntries(params){
    return http.post(`${apiUrl}/timesheetManagement/ViewTimeSheet/resourceEntries`, params);
}

/**
 * Resource by Project/CR/Task - get resource list
 * @returns {array}  returns an array of resources
 * @param params
 */

export function usersList(params){
    return http.post(`${apiUrl}/timesheetManagement/ViewTimeSheet/users`, params);
}
