import React, { Component } from "react";
import { Link } from "react-router-dom";

class SearchAction extends Component {
  constructor(props) {
    super(props);
    this.state = {};

  }

  render() {
    const { OnDeactivate } = this.props;
    const { OnActivate } = this.props;
    const criteriaItem = this.props.dataItem;
    const { criteriaStatus } = this.props;

    return (
      <td className="action-buttons">
        {criteriaItem.IsActive === true && criteriaStatus === "SalesPerson" && (window.USER_PERMISSION.indexOf('EDT_COMM') > -1) && (
          <Link
            to={{
              pathname: "/admin/createsalesperson/createsalesperson",
              criteriaItem: criteriaItem,
              action: "edit",
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-edit" title="Edit" />
            </div>
          </Link>
        )}
        {criteriaItem.IsActive === true && criteriaStatus === "partner" && (window.USER_PERMISSION.indexOf('EDT_COMM') > -1) && (
          <Link
            to={{
              pathname: "/admin/createpartner/createpartner",
              criteriaItem: criteriaItem,
              action: "edit",
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-edit" title="Edit" />
            </div>
          </Link>
        )}
        {criteriaItem.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_COMM') > -1) && (
          <Link
            to={this.props}
            onClick={event => OnDeactivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-unsort" title="Deactivate" />
            </div>
          </Link>
        )}
        {criteriaItem.IsActive === false && (window.USER_PERMISSION.indexOf('EDT_COMM') > -1) && (
          <Link
            to={this.props}
            onClick={event => OnActivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-check" title="Activate" />
            </div>
          </Link>
        )}
      </td>
    );
  }
}

export default SearchAction;
