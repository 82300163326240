import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Redirect } from 'react-router';
import { Tooltip } from 'reactstrap';
import { saveEngagementType, updateEngagementType } from './EngagementTypeService';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../../src/integration/LoggerService";
import {LOG_TYPES} from "../../../src/integration/IntegrationEnums";

class EngagementTypeCreate extends Component {

  default;
  isMount = false;

  popupSet = {
    width: 'auto'
  };

  constructor(props) {
    super(props);
    this.state = {
      EngagementTypeNameToolTipOpen: false,
      toEngagementType: false,
      engagementTypeText: '',
      engagementId: null,
      engagementTypeActive: false,
      visible: false,
      success: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      isEnableCreate: false,
      isEnableUpdate: false,
      isDisableCombo: false,
      isDisableEditMode: false,
      isDisableSave: false,
      isSaveButtonClicked: false
    };

    this.handleChangeEngagementTypeName = this.handleChangeEngagementTypeName.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;

    if (this.props.location.action === 'edit') {
      this.updateEngagementTypeDetails(this.props.location.engagementType);
    }
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  render() {
    if (this.state.toEngagementType === true) {
      return <Redirect to="/masterdata/engagementtype/engagementtype"/>;
    }
    return (
      <React.Fragment>
        <div className="main-card">
          <div className="main-heading">Engagement Type Details</div>
          <div className="main-seperator"></div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                  <label className="mandatory">Engagement Type Name:</label>
                </div>
                <div className="d-block">
                <div className="" id="engagementTypeNameToolTip">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="engagementTypeText"
                    required={true}
                    maxLength="100"
                    value={this.state.engagementTypeText}
                    onChange={this.handleChangeEngagementTypeName}
                    disabled={this.state.isEditMode}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.engagementTypeText)}>
                                            Please enter a Engagement Type name
                                        </span>
                  ) : null}
                  {this.state.engagementTypeText ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.EngagementTypeNameToolTipOpen}
                      target="engagementTypeNameToolTip"
                      toggle={this.toggleEngagementTypeNameToolTip}
                    >
                      {this.state.engagementTypeText}
                    </Tooltip>
                  ) : null}
                </div>
              </div>

            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} disabled={this.state.isDisableSave} type="submit"
                      onClick={this.handleSubmit}>Save</Button>
              <Button onClick={this.onClickCancel}>
                Cancel
              </Button>
            </div>
          </div>
          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error"/>
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.navigateBack}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}

          </div>
          <div>
            {this.state.showCancelConfirmation === true && (
              <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contains unsaved changes. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.navigateBack}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  navigateBack = event => {
    if (this.isMount) {
      this.setState({
        toEngagementType: true
      });
    }
  };

  handleChangeEngagementTypeName = async event => {
    if (this.isMount) {
      await this.setState({ engagementTypeText: event.target.value, isFormDirty: true });
    }
    await this.validateProperty(event.target.value);
  };

  toggleEngagementTypeNameToolTip = () => {
    if (this.isMount) {
      this.setState({
        workflowNameToolTipOpen: !this.state.workflowNameToolTipOpen
      });
    }
  };

  updateEngagementTypeDetails = engagementtype => {
    if (this.isMount) {
      this.setState({
        isDisableEditMode: true
      });
    }
    if (engagementtype) {
      this.isEnableCreate = true;
      this.isEnableUpdate = false;
      let engagementtypeStatus = false;


      if (engagementtype.IsActive === true) {
        engagementtypeStatus = true;
      }

      if (this.isMount) {
        this.setState({
          engagementTypeText: engagementtype.Name,
          engagementId: engagementtype.id,
          engagementTypeActive: engagementtypeStatus
        });
      }
    } else {
      return null;
    }
  };


  handleSubmit = event => {
    event.preventDefault();
    console.dir(event.target);
    if (!this.validation()) {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: true
        });
        this.setState({
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: false
        });
      }
      if (this.props.location.action === 'edit') {
        this.updateEngagementTypeEvent(event);
      } else {
        this.createEngagementTypeEvent(event);
        if (this.isMount) {
          this.setState({
            isDisableSave: true
          });
        }
      }
    }
  };

  createEngagementTypeEvent = event => {
    const engagementType = {
      Name: this.state.engagementTypeText,
      IsActive: true,
      updatedUser: window.LOGGED_USER.displayName
    };

    saveEngagementType(engagementType)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(`The Engagement type ${res.data.Name} is successfully created`, 'Success');
      })

      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog(error.response.data, 'Error');
        if (this.isMount) {
          this.setState({
            isDisableSave: false
          });
        }
      });
  };

  updateEngagementTypeEvent = event => {
    const engagementType = {
      Name: this.state.engagementTypeText,
      IsActive: this.state.engagementTypeActive,
      updatedUser: window.LOGGED_USER.displayName
    };

    updateEngagementType(engagementType, this.state.engagementId)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog('The Engagement type has been successfully updated', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  validation = () => {
    if (
      this.validateProperty(this.state.engagementTypeText)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  toggleDialog = (message, title) => {
    if (this.state.showMessageDialog === true && this.state.dialogTitle === 'Success') {
      this.navigateToSearch();
    } else {
      if (this.isMount) {
        this.setState({
          visible: !this.state.visible,
          dialogMessage: message,
          dialogTitle: title
        });
      }
    }
  };

  toggleCancelConfirmDialog = () => {
    if (this.isMount) {
      this.setState({
        showCancelConfirmation: !this.state.showCancelConfirmation
      });
    }
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };


}

export default EngagementTypeCreate;
