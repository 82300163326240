import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import YearPicker from './components/YearPicker';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Link } from 'react-router-dom';

import StatusCell from './components/StatusCell';
import CreatedDateCell from './components/CreatedDateCell';
import UpdatedDateCell from './components/UpdatedDateCell';
import EditActionCell from './components/EditActionCell';

import {getAllCountriesWithoutARegionIntegrated} from '../../integration/MasterDataService';
import {
  searchCalendars
} from './CalendarService';
import Loader from '../../integration/components/Loader';
import * as loggerService from "../../../src/integration/LoggerService";
import {LOG_TYPES} from "../../../src/integration/IntegrationEnums";
import $ from "jquery";

class CalendarSearch extends Component {

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      calendars: [],

      countries: [],
      countriesAll: [],
      selectedCountry: null,
      selectedYear: null,

      skip: 0,
      take: 20,

      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      loading: false
    };
  }

  popupSet = {
    width: 'auto'
  };

  /**
   * searchCalendars()
   * Retrieve All calendars data
   * @returns {array} calendars array
   */
  componentDidMount() {
    this.isMount = true;
    this.props.onHeaderTitleChange('Calendar');

    this.populateCountries();

    const fields = {};
    fields.IsActive = true;

    searchCalendars(fields)
      .then(res => {
        if (this.isMount) {
          this.setState({
            calendars: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

      $(document).ready(function() {
        $("div.title-remove > div > span > span > span:last-child").removeAttr("title");
    });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  /**
   * getCountries()
   * Retrieve All the Countries
   * @returns {array} countries array
   */
  populateCountries = () => {
    const fields = {};
    fields.IsActive = true;
    searchCalendars(fields)
      .then(res => {
        let arr = res.data
        // Filter and the countries where calendars are created and take unqiue values => then take only id, name and code
        arr = arr.filter((v, i, a) => a.findIndex(v2 => (v2.CountryId === v.CountryId)) === i).map(e => {
          return {'id' : e.Country.id, 'Name' : e.Country.Name, 'Code' : e.Country.Code }
        })
        if (this.isMount) {
          this.setState({
            countries: arr,
            countriesAll: arr
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  handleOnChangeCombo = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  filterChangeCombo = event => {
    if (this.isMount) {
      clearTimeout(this.timeout);
      const field = event.target.name;
      this.timeout = setTimeout(() => {
        switch (field) {
          case 'selectedCountry': {
            this.setState({
              countries: this.filterComboData(event.filter, this.state.countriesAll)
            });
            break;
          }
          default: {
          }
        }
      }, 0);
    }
  };

  /**
   * filterComboData()
   * Filter all the Countries and debounce the request.
   * @returns {array} countries array
   */
  filterComboData = ( filter, allData ) => {
    const data = allData.slice();
    return filterBy(data, filter);
  };

  handleOnChangeYear = event => {
    const year = event.value;
    const field = event.name;
    if (this.isMount) {
      this.setState(
        {
          [field]: year
        },
        () => {
          console.log(this.state);
        }
      );
    }
  };

  /**
   * submitSearch()- handle events when click on submit in search.
   * @param event
   * @returns {array} calendars array
   */
  submitSearch = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    const fields = {};
    if (this.state.selectedCountry) {
      fields.CountryId = this.state.selectedCountry.id;
    }

    if (this.state.selectedYear) {
      fields.Year = this.state.selectedYear;
    }

    fields.IsActive = true;

    searchCalendars(fields)
      .then(res => {
        if (this.isMount) {
          this.setState({
            calendars: res.data,
            loading: false,
            skip: 0
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleConfirmDialog = (message, action) => {
    if (this.isMount) {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    }
  };

  cancelSearch = event => {
    if (event) {
      event.preventDefault();
    }

    if (this.isMount) {
      this.setState(
        {
          selectedYear: null,
          selectedCountry: null
        }
      );
    }

    const fields = {};

    fields.IsActive = true;
    searchCalendars(fields)
      .then(res => {
        if (this.isMount) {
          this.setState({
            calendars: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  render() {
    return (
      <div className="main-card">

        <div className="row">

          <div className="col-md-8">
            <div className="main-heading">Calendar Search</div>
          </div>

          <div className="col-md-4">
            <Button primary={true} type="button" className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('ED_CAL') > -1)}>
              <Link style={{ textDecoration: 'none', color: 'white' }}
                    to={{
                      pathname: '/admin/calendar/create',
                      action: 'create'
                    }}
              >
                Create New Calendar
              </Link>
            </Button>
          </div>

        </div>

        <form onSubmit={this.submitSearch}>
          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                  <label>Country:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                    value={this.state.selectedCountry}
                    placeholder="Please Select"
                    data={this.state.countries}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleOnChangeCombo}
                    name="selectedCountry"
                    filterable={true}
                    onFilterChange={this.filterChangeCombo}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>

            <div className="col-md-2">
              <div className="d-block">
                  <label>Year:</label>
                </div>
                <div className="d-block">
                <div className="title-remove">
                  <YearPicker
                    value={this.state.selectedYear}
                    onChange={this.handleOnChangeYear}
                    name="selectedYear"
                    min={new Date()}
                  />
                </div>
              </div>
            </div>
            </div>

                    <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
                Clear
              </Button>
            </div>
            </div>
          
        </form>

        <div className="main-seperator"/>

        <div className="main-heading">Search Results</div>

        <div className="row">
          <div className="col-md-12">
            <Grid
              data={orderBy(this.state.calendars, this.state.sort).slice(this.state.skip, this.state.take + this.state.skip)}
              skip={this.state.skip}
              take={this.state.take}
              total={this.state.calendars.length}
              pageable={true}
              onPageChange={this.pageChange}
              resizable
              sortable={true}
              sort={this.state.sort}
              onSortChange={(e) => {
                this.setState({
                  sort: e.sort
                });
              }
              }
              className="search-result-grid"
            >
              <Column field="Country.Name" title="Country"/>
              <Column field="Year" title="Year" width="100px"/>
              <Column field="createdAt" title="Created Date"  width="130px" cell={props => <CreatedDateCell {...props} />}/>
              <Column field="updatedAt" title="Last Modified Date"  width="130px" cell={props => <UpdatedDateCell {...props} />}/>
              <Column field="UpdatedUser" title="Last Modified User"/>
              <Column field="IsActive" title="Status"  width="100px" cell={props => <StatusCell {...props} />}/>
              <Column field="" title="Action"  width="150px" cell={props => <EditActionCell {...props} />}/>
            </Grid>
          </div>
        </div>

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        <Loader loading={this.state.loading}/>
      </div>
    );
  }
}

export default CalendarSearch;
