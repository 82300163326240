import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Redirect } from 'react-router';
import { Tooltip } from 'reactstrap';
import { saveUser, updateUser, getRoleList } from './userService';

import PermissionListCell from './components/PermissionListCell';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";

class UserCreate extends Component {

  default;

  popupSet = {
    width: 'auto'
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.populateRolesList();
    this.setHeaderTitle();
  }

  setHeaderTitle = async () => {
    if (this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Create/Edit User');
    }
  };

  lastSelectedIndex = 0;

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      skip: 0,
      take: 20,
      usernameToolTipOpen: false,
      toUserList: false,
      Username: '',
      Name: '',
      userId: null,
      selectedRoleList: [],
      userActive: false,
      visible: false,
      success: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      isEnableCreate: false,
      isEnableUpdate: false,
      isDisableCombo: false,
      isDisableEditMode: false,
      isDisableSave: false,
      isSaveButtonClicked: false,
      isFormDirty: false,
      sort: [{ field: '', dir: '' }],
      firstName: '',
      lastName: '',
      middleName: ''
    };

    this.handleChangeUserName = this.handleChangeUserName.bind(this);
    this.handleChangeDisplayFirstName = this.handleChangeDisplayFirstName.bind(this);
    this.handleChangeDisplayLastName = this.handleChangeDisplayLastName.bind(this);
    this.handleChangeDisplayMiddleName = this.handleChangeDisplayMiddleName.bind(this);
    
  }

  populateRolesList = () => {
    getRoleList().then(response => {
      console.log(response.data);

      const activeRoles = response.data.filter(value => value.IsActive === true);
      this.setState({
        data: activeRoles.map(dataItem => Object.assign({ selected: false }, dataItem))
      });

      //Load list
      if (this.props.location.action === 'edit') {
        this.updateUserDetails(this.props.location.user);
        console.log('::::::::::::::::::::::::::::::::::EDIT USER::::::::::::::::::::::::::::::::::::');
        console.log(this.props.location.user);
      }

    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      })
  };

  selectionChange = (event) => {
    this.state.isFormDirty = true;
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
    console.log(':::::::::::::::::::::::::::SELECTION CHANGE::::::::::::::::::::::::::::::::');
    console.log(this.state.data);
  };

  rowClick = (event) => {
    let last = this.lastSelectedIndex;
    const current = this.state.data.findIndex(dataItem => dataItem === event.dataItem);

    if (!event.nativeEvent.shiftKey) {
      this.lastSelectedIndex = last = current;
    }

    if (!event.nativeEvent.ctrlKey) {
      this.state.data.forEach(item => item.selected != false);
    }
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      this.state.data[i].selected = select;
    }
    this.forceUpdate();

    console.log(':::::::::::::::::::::::::::ROW CLICKED::::::::::::::::::::::::::::::::');
    console.log(this.state.data);
  };

  headerSelectionChange = (event) => {
    this.state.isFormDirty = true;
    const checked = event.syntheticEvent.target.checked;
    this.state.data.forEach(item => item.selected = checked);
    this.forceUpdate();

    console.log(':::::::::::::::::::::::::::HEADER CHANGE::::::::::::::::::::::::::::::::');
    console.log(this.state.data);
  };

  pageChange = event => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  };

  rowRender(trElement, props) {
    //const styling = { display: "none" };
    //const stylingOk = { display: "table-row" };
    //const trProps = { style: props.dataItem.Rolename ==='PM' || props.dataItem.Rolename === 'SuperAdmin' ? styling : stylingOk };

    const trProps = { style: null, className: 'k-master-row' };
    const red = { backgroundColor: 'rgb(243, 23, 0, 0.32)' };

    if (props.dataItem.Rolename === 'SuperAdmin') {
      trProps.style = red;
    }

    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  render() {
    if (this.state.toUserList === true) {
      return <Redirect to="/admin/usermanagement/usermanagement" />;
    }
    return (
      <React.Fragment>
        <div className="main-card">
          <div className="main-heading">User Details</div>
          <div className="main-seperator"></div>
          <div className="row mb-4">
            <div className="col-md-2">
              <div className="d-block">
                <label className="mandatory">User Name (Email):</label>
              </div>
              <div className="d-block">
                <div className="" id="UsernameToolTip">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="Username"
                    required={true}
                    maxLength="100"
                    value={this.state.Username}
                    onChange={this.handleChangeUserName}
                    disabled={this.state.isEditMode}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateEmail(this.state.Username)}>
                      Please enter a valid email as User Name.
                                        </span>
                  ) : null}
                  {this.state.Username ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.usernameToolTipOpen}
                      target="UsernameToolTip"
                      toggle={this.toggleUserNameToolTip}
                    >
                      {this.state.Username}
                    </Tooltip>
                  ) : null}
                </div>
              </div>

            </div>

            <div className="col-md-1"></div>

            <div className="col-md-2">
              <div className="d-block">
                <label className="mandatory">First Name:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="FirstName"
                    required={true}
                    maxLength="100"
                    value={this.state.firstName}
                    onChange={this.handleChangeDisplayFirstName}
                    disabled={this.state.isEditMode}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.firstName)}>
                      Please enter a First Name.
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <label>Middle Name:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="MiddleName"
                    maxLength="100"
                    value={this.state.middleName}
                    onChange={this.handleChangeDisplayMiddleName}
                    disabled={this.state.isEditMode}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <label className="mandatory">Last Name:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="LastName"
                    required={true}
                    maxLength="100"
                    value={this.state.lastName}
                    onChange={this.handleChangeDisplayLastName}
                    disabled={this.state.isEditMode}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.lastName)}>
                      Please enter a Last Name.
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Grid data={orderBy(this.state.data, this.state.sort).slice(this.state.skip, this.state.take + this.state.skip)}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.data.length}
                pageable={true}
                onPageChange={this.pageChange}
                selectedField="selected"
                onSelectionChange={this.selectionChange}
                onHeaderSelectionChange={this.headerSelectionChange}
                onRowClick={this.rowClick}
                rowRender={this.rowRender}
                resizable
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }
                }
              >
                <Column
                  field="selected"
                  width="50px"
                  headerSelectionValue={
                    this.state.data.findIndex(dataItem => dataItem.selected === false) === -1
                  } />
                <Column field="Rolename" width="200px" title="Role Name" />
                <Column field="RoleDescription" width="350px" title="Role Description" />
                <Column
                  field=""
                  title="Available Permissions"
                  cell={props => <PermissionListCell {...props} />}
                />
              </Grid>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              {/* <div className="row">
                <div className="col-md-5"> */}
                <Button primary={true} disabled={this.state.isDisableSave} type="submit"
                    onClick={this.handleSubmit} style={{ marginRight: '5px' }}>Save</Button>
                  <Button onClick={this.onClickCancel} >
                    Cancel
                  </Button>
                  
                {/* </div>
              </div> */}
            </div>
          </div>
          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="300px">
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                <DialogActionsBar>
                  <button
                    className="k-button modal-primary"
                    onClick={
                      this.state.dialogTitle === 'Error'
                        ? this.toggleDialog
                        : this.navigateBack
                    }
                  >
                    OK
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.showCancelConfirmation === true && (
              <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contains unsaved changes. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.navigateBack}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  navigateBack = event => {
    this.setState({
      toUserList: true
    });
  };

  handleChangeDisplayFirstName = async event => {
    await this.setState({ firstName: event.target.value, isFormDirty: true });
    await this.validateProperty(event.target.value);
  };

  handleChangeDisplayLastName = async event => {
    await this.setState({ lastName: event.target.value, isFormDirty: true });
    await this.validateProperty(event.target.value);
  };

  handleChangeDisplayMiddleName = async event => {
    await this.setState({ middleName: event.target.value, isFormDirty: true });
  };

  handleChangeUserName = async event => {
    await this.setState({ Username: event.target.value, isFormDirty: true });
    await this.validateProperty(event.target.value);
  };

  toggleUserNameToolTip = () => {
    this.setState({
      workflowNameToolTipOpen: !this.state.workflowNameToolTipOpen
    });
  };

  filterChangeDesignationGroup = event => {
    this.setState({
      designationGroupData: this.filterDataDesignationGroup(event.filter)
    });
  };

  filterDataDesignationGroup(filter) {
    const data = this.designationGroupDataAll.slice();
    return filterBy(data, filter);
  }

  handleChangeProjectRole = event => {
    const field = event.target.name;
    // const value = event.target.value;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState({
      [field]: value,
      isFormDirty: true
    });
  };

  updateUserDetails = user => {
    this.setState({
      isDisableEditMode: true
    });
    if (user) {
      this.isEnableCreate = true;
      this.isEnableUpdate = false;
      let userStatus = false;

      if (user.IsActive) {
        userStatus = true;
      }

      //Create selection
      this.state.data.forEach(function (item) {
        user.Roles.forEach(function (itemSelected) {
          if (item.id === itemSelected.id) {
            item.selected = true;
          }
        });
      });

      this.setState({
        Username: user.Username,
        userId: user.id,
        userActive: userStatus,
        Name: user.displayName,
        selectedRoleList: user.Roles,
        firstName: user.Resource ? user.Resource.FirstName : '',
        lastName: user.Resource ? user.Resource.LastName : '',
        middleName: user.Resource ? user.Resource.MiddleName : '',
      });
    } else {
      return null;
    }
  };


  handleSubmit = event => {
    event.preventDefault();

    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true
      });
      this.setState({ visible: true });
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else {
      this.setState({
        isErrorMessageVisible: false
      });
      if (this.props.location.action === 'edit') {
        this.updateUser(event);
      } else {
        this.createUser(event);
        this.setState({
          isDisableSave: true
        });
      }
    }
  };

  createUser = event => {

    var selectedItems = this.state.data.filter(obj => {
      if (obj.selected === true) {
        return true;
      }
    });

    const user = {
      Username: this.state.Username,
      displayName: this.state.firstName + " " + this.state.lastName,
      IsActive: true,
      Locked: false,
      Email: this.state.Username,
      Roles: selectedItems,
      FirstName: this.state.firstName,
      MiddleName: this.state.middleName,
      LastName: this.state.lastName
    };

    console.log('::::::::::::::::::::::::::::::SAVE:::::::::::::::::::::::::::::::');
    console.log(user);

    saveUser(user)
      .then(res => {
        this.toggleDialog(`The User ${res.data} is successfully created`, 'Success');
        this.setState({});
      })

      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleDialog(error.response.data, 'Error');
        this.setState({
          isDisableSave: false
        });
      });
  };

  updateUser = event => {

    var selectedItems = this.state.data.filter(obj => {
      if (obj.selected === true) {
        return true;
      }
    });

    //Prepare Roles list to update
    const insertRolesList = selectedItems;
    const deleteRolesList = this.state.selectedRoleList;

    const updateRolesFiltered = deleteRolesList.filter(userRole => insertRolesList.find(userRole2 => userRole.id === userRole2.id));
    const deleteRolesFiltered = deleteRolesList.filter(userRole => !insertRolesList.find(userRole2 => userRole.id === userRole2.id));
    const insertRolesFiltered = insertRolesList.filter(userRole => !deleteRolesList.find(userRole2 => userRole.id === userRole2.id));

    const user = {
      Username: this.state.Username,
      displayName: this.state.firstName + " " + this.state.lastName,
      IsActive: this.state.userActive,
      Locked: false,
      Email: this.state.Username,
      UpdateRoles: updateRolesFiltered,
      InsertRoles: insertRolesFiltered,
      DeleteRoles: deleteRolesFiltered,
      FirstName: this.state.firstName,
      MiddleName: this.state.middleName,
      LastName: this.state.lastName
    };

    console.log('::::::::::::::::::::::::::::::UPDATE:::::::::::::::::::::::::::::::');
    console.log(user);

    updateUser(user, this.state.userId)
      .then(res => {
        this.toggleDialog('The User has been successfully updated', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  validateEmail = (email) => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    var testing = expression.test(String(email).toLowerCase());
    console.log(testing);
    if (testing) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validation = () => {

    if (
      this.validateProperty(this.state.Username)
        .toString()
        .includes('error')
    ) {
      return false;
    }
    if (
      this.validateEmail(this.state.Username)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.firstName)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.lastName)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleCancelConfirmDialog = () => {
    this.setState({
      showCancelConfirmation: !this.state.showCancelConfirmation
    });
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };


}

export default UserCreate;
