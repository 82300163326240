import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * getWorkflowDefinitions - retrieves all the Workflow criteria definitions
 * @returns {array}  array of Workflow criteria definitions
 */
export function getWorkflowDefinitions() {
  const endPoint = apiUrl + '/masterData/workflowCriteria/getDefinitions';
  return http.get(endPoint);
}

/**
 * saveflowDefinitions - save criteria definitions
 * @returns {array}  array of Workflow criteria definitions
 */
export function saveWorkflowDefinitions(criteria) {
  const endPoint = apiUrl + '/masterData/workflowCriteria';
  return http.post(endPoint, criteria);
}

/**
 * getWorkflowDefinitionsByParameter - retrieves all the Workflow criteria definitions by parameter
 * @returns {array}  array of Workflow criteria definitions
 */
export function getWorkflowDefinitionsByParameter(parameterId) {
  const endPoint = apiUrl + '/masterData/workflowCriteria/getDefinitions/' + parameterId;
  return http.get(endPoint);
}
