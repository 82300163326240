import React, { Component } from "react";

class AllocationPercentageCell extends Component {

  toggleDialogAllocationPercentage = event => {
    event.preventDefault();
    this.props.toggleDialogAllocationPercentage(this.props.dataItem);
  };


  render() {
    return(
      (this.props.action === 'create' && this.props.dataItem.Status === "COMPLETE" && !this.props.dataItem.IsSoftBookingResource && !this.props.dataItem.IsPreviousCmsResource ? (
        <td  onClick={this.toggleDialogAllocationPercentage}>
            {this.props.dataItem.CurrentAllocationPercentage ?  <div className="addoreditresource">{this.props.dataItem.CurrentAllocationPercentage}</div> : <div></div>}
          </td>    
        ) : (
            <td>
            {this.props.dataItem.CurrentAllocationPercentage ? this.props.dataItem.CurrentAllocationPercentage : <div></div>}
          </td>
        )
      )
    );
  }

}

export default AllocationPercentageCell;
