import React, { Component } from "react";


class ResourceRequestValueFourCell extends Component {
    render() {
        const item = this.props.dataItem;
        const values = item.Value4 ? item.Value4.split("|") : []; 
        if (values && values.length > 1) {
            if(values[1] && values[1].trim() != '0.00'){
                return <td>{values[0]} <span className="highlight-red">  ({values[1].trim()}) </span></td>;
            }else{
                return <td>{values[0]}</td>;
            }
        } else {
            return <td>{item.Value4}</td>;
        }
    }
}

export default ResourceRequestValueFourCell;
