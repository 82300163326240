import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem, PanelBarUtils, Menu, MenuItem, MenuItemModel, MenuItemLink, MenuItemArrow, Splitter } from '@progress/kendo-react-layout';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactDOM from 'react-dom';
import '../Spark.css';


class ResourceProfileSearch extends Component {
    render() {
        return (
            <div>
                <div className="main-card">

                    <div className="row">

                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Resource Name:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Email:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Designation:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">RP Classification:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Profile Status:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Skill Evaluation Status:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Line Manager:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Skill:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-inline-block mr-4">
                                <fieldset>
                                    <label className="k-form-field">
                                        <input type="checkbox" id="auth-1rps" className="k-checkbox" />
                                        <label className="k-checkbox-label" for="auth-1rps">Resources where I am the Line Manager</label>
                                    </label>
                                </fieldset>
                            </div>
                            <div className="d-inline-block">
                                <fieldset>
                                    <label className="k-form-field">
                                        <input type="checkbox" id="auth-2rps" className="k-checkbox" />
                                        <label className="k-checkbox-label" for="auth-2rps">Resources I have to Skill Evaluate</label>
                                    </label>
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-12 btn-align-right">
                            <Button primary={true}>Search</Button>
                            <Button>Clear</Button>
                        </div>
                    </div>

                    <div className="main-seperator"></div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="main-heading">
                                Search Results
                            </div>
                        </div>
                        <div className="col-md-6 btn-align-right">
                            <Button primary={true}>Export to Excel</Button>
                            <Button primary={true}>Bulk Line Manager Replacement</Button>
                            <Button primary={true}>Bulk Assign Skills</Button>
                            <Button>Bulk Remove Skills</Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Grid
                                style={{ height: '100px' }}
                            >
                                <Column field="ProductID" title="ID" width="40px" />
                                <Column field="ProductName" title="Name" width="250px" />
                                <Column field="Category.CategoryName" title="CategoryName" />
                                <Column field="UnitPrice" title="Price" />
                                <Column field="UnitsInStock" title="In stock" />
                            </Grid>
                        </div>
                    </div>

                </div>

                {/* <Dialog title={"Bulk Assign Skills"} onClose={this.toggleDialog} width={200}>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="d-block">
                                <label className="new-label">Skill Type:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                    </div>
                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>Save</button>
                    </DialogActionsBar>
                </Dialog> */}

                {/* <Dialog title={"Bulk Remove Skills"} onClose={this.toggleDialog} width={200}>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="d-block">
                                <label className="new-label">Skill Type:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                    </div>
                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>Save</button>
                    </DialogActionsBar>
                </Dialog> */}

                {/* <Dialog title={"Bulk Remove Skills"} onClose={this.toggleDialog} width={400}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-block">
                                <label className="new-label">Add Skill Type:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <Button primary={true}>Add</Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Grid
                                style={{ height: '100px' }}
                            >
                                <Column field="ProductID" title="ID" width="40px" />
                                <Column field="ProductName" title="Name" width="250px" />
                                <Column field="Category.CategoryName" title="CategoryName" />
                                <Column field="UnitPrice" title="Price" />
                                <Column field="UnitsInStock" title="In stock" />
                            </Grid>
                        </div>
                    </div>
                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>Save</button>
                    </DialogActionsBar>
                </Dialog> */}

                {/* <Dialog title={"Bulk Line Manager Replacement"} onClose={this.toggleDialog} width={600}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-block">
                                <label className="new-label">Current Line Managers(s):</label>
                            </div>
                            <div className="d-block skill-owner-wrap">
                                <div>Text</div>
                                <div>Text</div>
                                <div>Text</div>
                                <div>Text</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-block">
                                <label className="new-label">New Line Manager:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                    </div>
                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>Save</button>
                    </DialogActionsBar>
                </Dialog> */}

            </div>
        );
    }
}

export default ResourceProfileSearch;
