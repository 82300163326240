import * as React from "react";
import { Popup } from "@progress/kendo-react-popup";
const CustomDatePickerPopUp = (props) => {
  return (
    <Popup
      {...props}
      anchorAlign={{
        horizontal: "right",
        vertical: "right",
      }}
      popupAlign={{
        horizontal: "left",
        vertical: "center",
      }}
    />
  );
};

export default CustomDatePickerPopUp;