import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import { Button } from '@progress/kendo-react-buttons';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ExcelDownload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasUnSavedData: false,
            calculations: null,
            disableExcelDownload: false,
            didRun: false,
            calculation: null,
            Info: null,
            ResourceMix: null,
            OtherExpensesExcel: null,
            NullData: [{ columns: [], data: [] }],
            UndefinedDate: 'Undefined'
        };
    }

    componentDidMount() {
        this.updateCalculations();
    }

    updateCalculations = () => {
        this.populateInfoGrid();
        this.populateCalculationsGrid();
        this.populateResourceMix();
        this.populateOtherExpenses();
    };

    componentDidUpdate() {
        if (this.props.updateData === true) {
            this.props.dataUpdated();
            this.updateCalculations();
        }
    }

    populateCalculationsGrid = () => {
        var date = new Date();
        if (this.props.project) {
            const calculations = [
                {
                    columns: [
                        { title: '', width: { wch: 50 } }, // pixels width
                        { title: '', width: { wpx: 50 } }, // char width
                        { title: '', width: { wpx: 100 } }
                    ],
                    data: [
                        [{ value: 'Report Name - CM Sheet' }, { value: '' }, { value: '' }],
                        [
                            { value: 'Genarated Date/Time - ' + moment(date).format('MM-DD-YYYY hh:mm a') },
                            { value: '' },
                            { value: '' }
                        ],
                        [{ value: '' }, { value: '' }, { value: '' }],
                        [
                            { value: 'CMS Details', style: { font: { sz: '12', bold: true } } },
                            { value: '' },
                            { value: '' }
                        ],
                        [
                            {
                                value: this.props.cmsReference === 'crCMS' ? 'Change Request Name' : 'Project Name'
                            },
                            { value: '' },
                            {
                                value:
                                    this.props.cmsReference === 'crCMS'
                                        ? this.props.changeRequest.CRName
                                        : this.props.project.ProjectName
                            }
                        ],
                        [
                            { value: this.props.cmsReference === 'crCMS' ? 'CR ID ' : 'Project ID' },
                            { value: '' },
                            {
                                value:
                                    this.props.cmsReference === 'crCMS'
                                        ? this.props.changeRequest.UniqueId
                                        : this.props.project.UniqueId
                            }
                        ],
                        [
                            { value: 'OP ID' },
                            { value: '' },
                            {
                                value:
                                    this.props.cmsReference === 'crCMS'
                                        ? this.props.changeRequest.OPID
                                        : this.props.project.OPID
                            }
                        ],
                        [
                            { value: 'Status' },
                            { value: '' },
                            {
                                value:
                                    this.props.cmsReference === 'crCMS' && this.props.changeRequest.Status
                                        ? this.props.changeRequest.Status.StatusName
                                        : this.props.project.ProjectStatus ?  this.props.project.ProjectStatus.StatusName:''
                            }
                        ],
                        [
                            { value: 'CMS Version' },
                            { value: '' },
                            {
                                value: this.props.version ? this.props.version.Text : this.state.UndefinedDate
                            }
                        ],
                        [
                            { value: 'Currency' },
                            { value: '' },
                            {
                                value:
                                    this.props.cmsReference === 'crCMS' && this.props.changeRequest.Region
                                        ? this.props.changeRequest.Region.Currency.Code
                                        : this.props.project.Region? this.props.project.Region.Currency.Code:''
                            }
                        ],
                        [{ value: '' }, { value: '' }, { value: '' }],
                        [
                            {
                                value: 'Duration |Effort)',
                                style: { font: { sz: '12', bold: true } }
                            },
                            { value: '' },
                            { value: '' }
                        ],
                        [
                            { value: 'Est. Project Duration (calendar Days)' },
                            { value: '' },
                            {
                                value: this.props.cmSheet.ProjectDuration
                                    ? this.props.cmSheet.ProjectDuration
                                    : this.state.UndefinedDate
                            }
                        ],
                        [
                            { value: 'Contracted Effort Days | Hours' },
                            {
                                value: this.props.cmSheet.TotalEffortDaysResourceAllocation
                                    ? this.props.cmSheet.TotalEffortDaysResourceAllocation
                                    : 0
                            },
                            {
                                value:
                                    (this.props.cmSheet.TotalEffortDaysResourceAllocation
                                        ? this.props.cmSheet.TotalEffortDaysResourceAllocation
                                        : 0) * 8
                            }
                        ],
                         (this.props.data.PaymentMethod === 3) ? [
                            { value: 'Fixed Effort Days | Hours' },
                            {
                                value: this.props.cmSheet.TotalEffortDaysResourceAllocationFixed
                                    ? this.props.cmSheet.TotalEffortDaysResourceAllocationFixed
                                    : 0
                            },
                            {
                                value:
                                    (this.props.cmSheet.TotalEffortDaysResourceAllocationFixed
                                        ? this.props.cmSheet.TotalEffortDaysResourceAllocationFixed
                                        : 0) * 8
                            }
                        ] : [{ value: '' }, { value: '' }, { value: '' }],
                        (this.props.data.PaymentMethod === 3) ? [
                            { value: 'T&M Effort Days | Hours' },
                            {
                                value: this.props.cmSheet.TotalEffortDaysResourceAllocationTnM
                                    ? this.props.cmSheet.TotalEffortDaysResourceAllocationTnM
                                    : 0
                            },
                            {
                                value:
                                    (this.props.cmSheet.TotalEffortDaysResourceAllocationTnM
                                        ? this.props.cmSheet.TotalEffortDaysResourceAllocationTnM
                                        : 0) * 8
                            }
                        ] : [{ value: '' }, { value: '' }, { value: '' }],
                        [{ value: '' }, { value: '' }, { value: '' }],
                        [
                            //
                            {
                                value: 'Project Value | Project Revenue',
                                style: { font: { sz: '12', bold: true } }
                            },
                            { value: '' },
                            { value: '' }
                        ],
                        [
                            { value: 'Total project value to customer' },
                            { value: '' },
                            {
                                value: this.props.cmSheet.TotalProjectValue
                                    ? Number(parseFloat(this.props.cmSheet.TotalProjectValue).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [
                            { value: 'Estimated OPE for customer' },
                            { value: '' },
                            {
                                value: this.props.cmSheet.EstimatedOPECustomer
                                    ? Number(parseFloat(this.props.cmSheet.EstimatedOPECustomer).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [
                            { value: 'Partner Commission' },
                            { value: '' },
                            {
                                value: this.props.cmSheet.PartnerCommission
                                    ? Number(parseFloat(this.props.cmSheet.PartnerCommission).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [
                            { value: 'Revenue to Fortude after discount' },
                            { value: '' },
                            {
                                value: this.props.cmSheet.ProjectRevenueAfterDiscount
                                    ? Number(parseFloat(this.props.cmSheet.ProjectRevenueAfterDiscount).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [
                            { value: 'Average rate per day' },
                            { value: '' },
                            {
                                value: this.props.cmSheet.AverageRatePerDay
                                    ? Number(parseFloat(this.props.cmSheet.AverageRatePerDay).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [{ value: '' }, { value: '' }, { value: '' }],
                        [
                            {
                                value: 'Discounts |Deductions',
                                style: { font: { sz: '12', bold: true } }
                            },
                            { value: '' },
                            { value: '' }
                        ],
                        [
                            { value: 'Value discount/ (premium)' },
                            {
                                value: this.props.cmSheet.DiscountPercentage
                                    ? parseFloat(this.props.cmSheet.DiscountPercentage).toFixed(2) + ''
                                    : '0.00'
                            },
                            {
                                value: this.props.cmSheet.DiscountValue
                                    ? Number(parseFloat(this.props.cmSheet.DiscountValue).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [
                            { value: 'Rate discount/ (premium)' },
                            {
                                value: this.props.cmSheet.rateDiscountPercentage
                                    ? parseFloat(this.props.cmSheet.rateDiscountPercentage).toFixed(2) + ''
                                    : '0.00'
                            },
                            {
                                value: this.props.cmSheet.RateDiscount
                                    ? Number(parseFloat(this.props.cmSheet.RateDiscount).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [
                            { value: 'Non-billable Total' },
                            { value: '' },
                            {
                                value: this.props.cmSheet.NonBillableTotalOtherExpenses
                                    ? Number(parseFloat(this.props.cmSheet.NonBillableTotalOtherExpenses).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [{ value: '' }, { value: '' }, { value: '' }],
                        [
                            {
                                value: 'Profitability',
                                style: { font: { sz: '12', bold: true } }
                            },
                            { value: '' },
                            { value: '' }
                        ],
                        [
                            { value: 'Gross profit from resource deployment | Gross margin %' },
                            {
                                value: this.props.cmSheet.GrossProfitMargin
                                    ? parseFloat(this.props.cmSheet.GrossProfitMargin).toFixed(2) + '%'
                                    : '0.00%'
                            },
                            {
                                value: this.props.cmSheet.GrossProfitFromResourceDeployment
                                    ? Number(
                                        parseFloat(this.props.cmSheet.GrossProfitFromResourceDeployment).toFixed(2)
                                    )
                                    : 0.0
                            }
                        ],
                        [
                            //
                            { value: 'Net Profit/ (loss) | Net Margin %' },
                            {
                                value: this.props.cmSheet.NetProfitMargin
                                    ? parseFloat(this.props.cmSheet.NetProfitMargin).toFixed(2) + '%'
                                    : '0.00%'
                            },
                            {
                                value: this.props.cmSheet.NetProfitLoss
                                    ? Number(parseFloat(this.props.cmSheet.NetProfitLoss).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [
                            { value: 'Negative Discount/ (premium) %' },
                            {
                                value: this.props.cmSheet.NetDiscountPercentage
                                    ? parseFloat(this.props.cmSheet.NetDiscountPercentage).toFixed(2) + '%'
                                    : '0.00%'
                            },
                            {
                                value: this.props.cmSheet.NetDiscountValue
                                    ? Number(this.props.cmSheet.NetDiscountValue.toFixed(2))
                                    : 0.0
                            }
                        ]
                    ]
                }
            ];
            if (this.props.data.PaymentMethod !== 3) {
                calculations[0].data.splice(14,1)
                calculations[0].data.splice(15,1)
             }

            this.setState({ calculation: calculations });
        }
    };

    populateInfoGrid = () => {
        let data = [];
        if (this.props.cmSheet) {
            if (this.props.cmSheet.Phases && this.props.cmSheet.Phases.length > 0) {
                for (var i = 0; i < this.props.cmSheet.Phases.length; i++) {
                    data.push([
                        { value: this.props.cmSheet.Phases[i].Text, style: { font: { sz: '12', bold: true } } },
                        {
                            value:
                                this.props.cmSheet.Phases[i].SubPhases.length > 0
                                    ? this.props.cmSheet.Phases[i].SubPhases[0].Text
                                    : this.state.UndefinedDate
                        }
                    ]);

                    for (var k = 1; k < this.props.cmSheet.Phases[i].SubPhases.length; k++) {
                        data.push([{ value: '' }, { value: this.props.cmSheet.Phases[i].SubPhases[k].Text }]);
                    }
                }
            }
        }

        const info = [
            {
                columns: [
                    { title: '', width: { wch: 30 } }, // pixels width
                    { title: '', width: { wpx: 150 } }
                ],
                data: [
                    [
                        {
                            value: 'Info Details',
                            style: { font: { sz: '12', bold: true } }
                        },
                        { value: '' }
                    ],
                    [
                        { value: 'Start Date' },
                        {
                            value: this.props.data.StartDate
                                ? moment(this.props.data.StartDate).format('MM-DD-YYYY')
                                : this.state.UndefinedDate
                        }
                    ],
                    [
                        { value: 'End Date' },
                        {
                            value: this.props.data.EndDate
                                    ? moment(this.props.data.EndDate).format('MM-DD-YYYY')
                                : this.state.UndefinedDate
                        }
                    ],
                    [{ value: 'Billing Region' }, { value: this.props.data.Region ? this.props.data.Region.Name:'' }],
                    [{ value: 'Billing Method' }, { value: this.props.cmsReference === 'crCMS' && this.props.data.PaymentMethod ? this.props.data.PaymentMethod.Name : this.props.data.PaymentMethodRecord ?this.props.data.PaymentMethodRecord.Name:'' }],
                    [
                        { value: 'CMS Type' },
                        {
                            value: this.props.cmSheet.CmsType ? this.props.cmSheet.CmsType.name : ''
                        }
                    ],
                    [
                        { value: 'Discount' },
                        {
                            value: this.props.cmSheet.DiscountValue
                                ? Number(parseFloat(this.props.cmSheet.DiscountValue).toFixed(2))
                                : 0.0
                        }
                    ],
                    [
                        { value: 'Contingency' },
                        {
                            value: this.props.cmSheet.ContingencyValue
                                ? Number(parseFloat(this.props.cmSheet.ContingencyValue).toFixed(2))
                                : 0.0
                        }
                    ],
                    [
                        { value: 'Product / Licensing revenue' },
                        {
                            value: this.props.cmSheet.ServiceRevenueValue
                                ? Number(parseFloat(this.props.cmSheet.ServiceRevenueValue).toFixed(2))
                                : 0.0
                        }
                    ],
                    [
                        { value: 'Comment (Discount)' },
                        {
                            value: this.props.cmSheet.DiscountComment ? this.props.cmSheet.DiscountComment.replace(/(<([^>]+)>)/ig,"") : ''
                        }
                    ],
                    [
                        { value: 'Comment (Contingency)' },
                        {
                            value: this.props.cmSheet.ContingencyComment
                                ? this.props.cmSheet.ContingencyComment.replace(/(<([^>]+)>)/ig,"")
                                : ''
                        }
                    ],
                    [
                        { value: 'Comment (APS Profitability)' },
                        {
                            value: this.props.cmSheet.APSProfitabilityComment
                              ? this.props.cmSheet.APSProfitabilityComment.replace(/(<([^>]+)>)/ig,"")
                              : ''
                        }
                    ],
                    [
                        { value: 'Comment (Product / Licensing revenue)' },
                        {
                            value: this.props.cmSheet.ServiceRevenueComment
                                ? this.props.cmSheet.ServiceRevenueComment.replace(/(<([^>]+)>)/ig,"")
                                : ''
                        }
                    ],
                    [
                        { value: 'Partner Commission' },
                        {
                            value: this.props.cmSheet.PartnerCommission
                                ? Number(parseFloat(this.props.cmSheet.PartnerCommission).toFixed(2))
                                : 0.0
                        }
                    ],
                    [
                        { value: 'APS Profitability' },
                        {
                            value: this.props.cmSheet.APSProfitability
                              ? Number(parseFloat(this.props.cmSheet.APSProfitability).toFixed(2))
                              : 0.0
                        }
                    ],
                    [
                        { value: 'Revenue after disc. quoted to customer' },
                        {
                            value: this.props.cmSheet.RevenueAfterDiscountQuotedToCustomer
                                ? Number(
                                    parseFloat(this.props.cmSheet.RevenueAfterDiscountQuotedToCustomer).toFixed(2)
                                )
                                : 0.0
                        }
                    ],
                    [
                        { value: 'Estimated OPE for customer' },
                        {
                            value: this.props.cmSheet.EstimatedOPECustomer
                                ? Number(parseFloat(this.props.cmSheet.EstimatedOPECustomer).toFixed(2))
                                : 0.0
                        }
                    ]
                ]
            },
            {
                columns: [
                    { title: '', width: { wch: 30 } }, // pixels width
                    { title: '', width: { wpx: 150 } }
                ],
                data
            }
        ];

        this.setState({ Info: info });
    };

    populateResourceMix = () => {
        let data = [];
        if (this.props.cmSheet) {
            if (this.props.cmSheet.ResourceAllocations) {
                for (var i = 0; i < this.props.cmSheet.ResourceAllocations.length; i++) {
                    if (this.props.cmSheet.ResourceAllocations[i]) {
                        data.push([
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].PhaseId
                                    ? this.props.cmSheet.Phases.filter(
                                        e => e.id === this.props.cmSheet.ResourceAllocations[i].PhaseId
                                    )[0].Text
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].SubPhaseId
                                    ? this.props.cmSheet.Phases.filter(
                                        e => e.id === this.props.cmSheet.ResourceAllocations[i].PhaseId
                                    )[0].SubPhases.filter(
                                        e => e.id === this.props.cmSheet.ResourceAllocations[i].SubPhaseId
                                    )[0].Text
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].dataValueProjectRole
                                    ? this.props.cmSheet.ResourceAllocations[i].dataValueProjectRole.Name
                                    : ' '
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].dataValueCostDesignation
                                    ? this.props.cmSheet.ResourceAllocations[i].dataValueCostDesignation.Name
                                    : ''
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].dataValueResourceRegion
                                    ? this.props.cmSheet.ResourceAllocations[i].dataValueResourceRegion.Name
                                    : ''
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].OnsiteStatus
                                    ? this.props.cmSheet.ResourceAllocations[i].OnsiteStatus
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].StartDate
                                    ? moment(this.props.cmSheet.ResourceAllocations[i].StartDate).format('MM-DD-YYYY')
                                    : this.state.UndefinedDate
                            },
                            ... (this.props.data.BillingDivision === 2 && this.props.data.ProjectType === 3) || (this.props.data.BillingDivision === 2 && this.props.data.ProjectTypeId === 3) || (this.props.data.ProjectType === 5) || (this.props.data.ProjectTypeId === 5)|| (this.props.data.ProjectType === 6) || (this.props.data.ProjectTypeId === 6) ? [{  value: this.props.cmSheet.ResourceAllocations[i].EndDate
                                    ?  moment(this.props.cmSheet.ResourceAllocations[i].EndDate).format('MM-DD-YYYY')
                                    : this.state.UndefinedDate }] : '',
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].EffortDays
                                    ? Number(this.props.cmSheet.ResourceAllocations[i].EffortDays)
                                    : 0
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].EffortHours
                                    ? Number(this.props.cmSheet.ResourceAllocations[i].EffortHours)
                                    : 0
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].AllocationPercentage
                                    ? Number(
                                        parseFloat(
                                            this.props.cmSheet.ResourceAllocations[i].AllocationPercentage
                                        ).toFixed(2)
                                    )
                                    : 0
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].dataValueRateType
                                    ? this.props.cmSheet.ResourceAllocations[i].dataValueRateType.Name
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].RateValue
                                    ? Number(
                                        parseFloat(this.props.cmSheet.ResourceAllocations[i].RateValue).toFixed(2)
                                    )
                                    : 0
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].NoOfResources
                                    ? this.props.cmSheet.ResourceAllocations[i].NoOfResources
                                    : 0
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].TotalCost
                                    ? this.props.cmSheet.ResourceAllocations[i].TotalCost
                                    : 0
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].TotalRevenue
                                    ? this.props.cmSheet.ResourceAllocations[i].TotalRevenue
                                    : 0
                            },
                            {
                                value: this.props.cmSheet.ResourceAllocations[i].CostToRevenueRatio
                                    ? this.props.cmSheet.ResourceAllocations[i].CostToRevenueRatio
                                    : 'N/A'
                            }
                        ]);
                    }


                }
            }

            const resourceMix = [
                {
                    columns: [{ title: 'Resource Mix', width: { wpx: 150 } }],
                    data: [[{ value: '' }]]
                },
                {
                    columns: [
                        { title: 'Phase', width: { wpx: 150 } },
                        { title: 'Sub Phase', width: { wpx: 150 } },
                        { title: 'Project Role', width: { wpx: 180 } },
                        { title: 'Designation(Cost)', width: { wpx: 180 } },
                        { title: 'Resource Region', width: { wpx: 105 } },
                        { title: 'Onsite / Offshore', width: { wpx: 110 } },
                        { title: 'Start Date', width: { wpx: 75 } },
                        { title: 'Effort Days', width: { wpx: 75 } },
                        { title: 'Effort Hours', width: { wpx: 80 } },
                        { title: 'Allocations(%)', width: { wpx: 90 } },
                        { title: 'Rate Type', width: { wpx: 100 } },
                        { title: 'Rate Value', width: { wpx: 75 } },
                        { title: 'No. of Resources', width: { wpx: 110 } },
                        { title: 'Cost', width: { wpx: 110 } },
                        { title: 'Revenue', width: { wpx: 110 } },
                        { title: 'Cost to Revenue Ratio', width: { wpx: 110 } },

                    ],
                    data
                },
                {
                    ySteps: 1, //Space between the 2 data sets
                    columns: [
                        { title: '', width: { wpx: 150 } },
                        { title: '', width: { wpx: 150 } },
                        { title: '', width: { wpx: 180 } },
                        { title: '', width: { wpx: 180 } },
                        { title: '', width: { wpx: 105 } },
                        { title: '', width: { wpx: 110 } },
                        { title: '', width: { wpx: 75 } },
                        { title: '', width: { wpx: 75 } },
                        { title: '', width: { wpx: 80 } },
                        { title: '', width: { wpx: 90 } },
                        { title: '', width: { wpx: 100 } },
                        { title: '', width: { wpx: 75 } },
                        { title: '', width: { wpx: 110 } }
                    ],
                    data: [
                        [
                            { value: 'Cost to Revenue Ratio' },
                            {
                                value: Number(
                                    parseFloat(
                                        (this.props.cmSheet.TotalCostResourceAllocation
                                            ? this.props.cmSheet.TotalCostResourceAllocation
                                            : 0) /
                                        (this.props.cmSheet.TotalRevenueResourceAllocation
                                            ? this.props.cmSheet.TotalRevenueResourceAllocation
                                            : 1)
                                    ).toFixed(2)
                                )
                            }
                        ],
                        [
                            { value: 'Total Cost' },
                            {
                                value: this.props.cmSheet.TotalCostResourceAllocation
                                    ? Number(parseFloat(this.props.cmSheet.TotalCostResourceAllocation).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [
                            { value: 'Total Revenue' },
                            {
                                value: this.props.cmSheet.TotalRevenueResourceAllocation
                                    ? Number(parseFloat(this.props.cmSheet.TotalRevenueResourceAllocation).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [
                            { value: 'Contracted Effort Days' },
                            {
                                value: this.props.cmSheet.TotalEffortDaysResourceAllocation
                                    ? Number(
                                        parseFloat(this.props.cmSheet.TotalEffortDaysResourceAllocation).toFixed(2)
                                    )
                                    : 0.0
                            }
                        ]
                    ]
                }
            ];

            //check APS
            if ((this.props.data.BillingDivision === 2 && this.props.data.ProjectType === 3) || (this.props.data.BillingDivision === 2 && this.props.data.ProjectTypeId === 3) || (this.props.data.ProjectType === 5) || (this.props.data.ProjectTypeId === 5) || (this.props.data.ProjectType === 6) || (this.props.data.ProjectTypeId === 6)) {
               resourceMix[1].columns.splice(7,0, { title: 'End Date', width: { wpx: 75 }})
            }

           

            this.setState({ ResourceMix: resourceMix });

        }
    };

    populateOtherExpenses = () => {
        let data = [];
        if (this.props.cmSheet) {
            if (this.props.cmSheet.OtherExpenses) {
                for (var i = 0; i < this.props.cmSheet.OtherExpenses.length; i++) {

                    let phaseArray = this.props.cmSheet.Phases.filter(e => e.id === this.props.cmSheet.OtherExpenses[i].PhaseId);

                    let subPhaseArray = phaseArray && phaseArray.length>0 ? phaseArray[0].SubPhases.filter(
                        e => e.id === this.props.cmSheet.OtherExpenses[i].SubPhaseId
                    ) : null;

                    data.push([
                        {
                            value: this.props.cmSheet.OtherExpenses[i].PhaseId ? phaseArray && phaseArray.length>0 ? phaseArray[0].Text :''
                                : this.state.UndefinedDate
                        },
                        {
                            value: this.props.cmSheet.OtherExpenses[i].SubPhaseId
                                ? subPhaseArray && subPhaseArray.length>0 ? subPhaseArray[0].Text :''
                                : this.state.UndefinedDate
                        },
                        {
                            value:
                                this.props.cmSheet.OtherExpenses[i] &&
                                this.props.cmSheet.OtherExpenses[i].dataValueExpenseType
                                    ? this.props.cmSheet.OtherExpenses[i].dataValueExpenseType.Name
                                    : this.state.UndefinedDate
                        },
                        {
                            value: this.props.cmSheet.OtherExpenses[i]
                                ? this.props.cmSheet.OtherExpenses[i].BudgetedOccurrences
                                : this.state.UndefinedDate
                        },
                        {
                            value: this.props.cmSheet.OtherExpenses[i]
                                ? Number(parseFloat(this.props.cmSheet.OtherExpenses[i].BudgetedRate).toFixed(2))
                                : 0.0
                        },
                        {
                            value: this.props.cmSheet.OtherExpenses[i]
                                ? this.props.cmSheet.OtherExpenses[i].BillableToClient === true
                                    ? 'Yes'
                                    : 'No'
                                : this.state.UndefinedDate
                        },
                        {
                            value: this.props.cmSheet.OtherExpenses[i]
                                ? Number(parseFloat(this.props.cmSheet.OtherExpenses[i].Cost).toFixed(2))
                                : 0.0
                        }
                    ]);
                }
            }
            const otherExpensesExcel = [
                {
                    columns: [{ title: 'Other expenses', width: { wpx: 150 } }],
                    data: [[{ value: '' }]]
                },
                {
                    columns: [
                        { title: 'Phase', width: { wpx: 150 } },
                        { title: 'Sub phase', width: { wpx: 150 } },
                        { title: 'Expense type', width: { wpx: 120 } },
                        { title: 'Budgeted occurances', width: { wpx: 135 } },
                        { title: 'Budgeted rate', width: { wpx: 90 } },
                        { title: 'Billable to client', width: { wpx: 100 } },
                        { title: 'Cost', width: { wpx: 60 } }
                    ],
                    data
                },
                {
                    ySteps: 1,
                    columns: [
                        { title: '', width: { wpx: 150 } },
                        { title: '', width: { wpx: 150 } },
                        { title: '', width: { wpx: 120 } },
                        { title: '', width: { wpx: 135 } },
                        { title: '', width: { wpx: 90 } },
                        { title: '', width: { wpx: 100 } },
                        { title: '', width: { wpx: 60 } }
                    ],
                    data: [
                        [
                            { value: 'Non-Billable Total' },
                            {
                                value: this.props.cmSheet.NonBillableTotalOtherExpenses
                                    ? Number(parseFloat(this.props.cmSheet.NonBillableTotalOtherExpenses).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [
                            { value: 'Billable Total' },
                            {
                                value: this.props.cmSheet.BillableTotalOtherExpenses
                                    ? Number(parseFloat(this.props.cmSheet.BillableTotalOtherExpenses).toFixed(2))
                                    : 0.0
                            }
                        ],
                        [
                            { value: 'Total' },
                            {
                                value: Number(
                                    parseFloat(
                                        (this.props.cmSheet.NonBillableTotalOtherExpenses
                                            ? this.props.cmSheet.NonBillableTotalOtherExpenses
                                            : 0.0) +
                                        (this.props.cmSheet.BillableTotalOtherExpenses
                                            ? this.props.cmSheet.BillableTotalOtherExpenses
                                            : 0.0)
                                    ).toFixed(2)
                                )
                            }
                        ],
                        [
                            { value: 'Project runs from ' },
                            {
                                value:
                                    this.props.data.EstimatedStartDate + ' to ' + this.props.data.EstimatedEndDate
                            }
                        ]
                    ]
                }
            ];
            this.setState({ OtherExpensesExcel: otherExpensesExcel });
        }
    };

    render() {
        return (
            <ExcelFile
                filename={
                    this.props.cmsReference === 'crCMS'
                        ? this.props.changeRequest.CRName + '_CMSheet'
                        : this.props.project.ProjectName + '_CMSheet'
                }
                element={
                    <Button primary className="pull-right" disabled={(this.props.cmSheetAction !== 'view' && this.props.cmSheetAction !== 'approve') && this.props.disableExcelDownload}>
                        {(this.props.cmSheetAction !== 'view' && this.props.cmSheetAction !== 'approve') && this.props.disableExcelDownload ? 'Save changes to download CMS' : 'Download CMS'}
                    </Button>
                }
            >
                <ExcelSheet
                    dataSet={this.state.calculation ? this.state.calculation : this.state.NullData}
                    name="Summary"
                />
                <ExcelSheet dataSet={this.state.Info ? this.state.Info : this.state.NullData} name="Info" />
                <ExcelSheet
                    dataSet={this.state.ResourceMix ? this.state.ResourceMix : this.state.NullData}
                    name="Resource Mix"
                />
                <ExcelSheet
                    dataSet={
                        this.state.OtherExpensesExcel ? this.state.OtherExpensesExcel : this.state.NullData
                    }
                    name="Other Expenses"
                />
            </ExcelFile>
        );
    }
}

export default ExcelDownload;
