/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import { getFactoryProjByRegionAndPractice } from '../../projectManagement/project/ProjectService';
import {getProjectResources} from "../../resourceManagement/ResourceService";
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import {getFormattedDate} from "../../integration/CommonUtils";
import moment from 'moment';

const closeSting = 'close';

class CMSheetFunctions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      IsProjectOwner:false
    }
  }

  componentDidMount()
  {
    // this.onCheckPoolProjectOwner(this.props.dataItem)
  }

  handleDeleteCMSheet = event => {
    event.preventDefault();
    const project = this.props.dataItem;
    if (project) {
      this.props.onDeleteCMSheet(project);
    }
  };

  handleViewCMS = (event , project, projectCMS, crCMS) =>{
    event.preventDefault();
    this.props.onSelectCMS(project, 'view', projectCMS, crCMS);
  };

  handleCreateCMS = (event, project) => {
    event.preventDefault();
    this.props.onCreateCMS(project, 'create');
  };

  hanldeEditCMS = (event, project, projectCMS, crCMS) =>{
    event.preventDefault();
    this.props.onSelectCMS(project, 'edit', projectCMS, crCMS);
  }

  handleReviseCMS = (event, project, projectCMS, crCMS)=>{
    event.preventDefault();
    this.props.onSelectCMS(project, 'revise', projectCMS, crCMS);
  }

  handleRequestReviseCMS = (event, project, projectCMS, crCMS)=>{
    event.preventDefault();
    this.props.onSelectCMS(project, 'requestReviseCMS', projectCMS, crCMS);
  }

  handleReviseCMSPool = (event, project, projectCMS, crCMS)=>{
    event.preventDefault();
    this.props.onSelectCMSPool(project, 'revise', projectCMS, crCMS);
  }

  handleCreateCMSforCRPool = (event, changeReqList, project) => {
    event.preventDefault();
    this.props.onCreateCMSforCRPool(changeReqList, project, 'CreateCMSforCRPool');
  };

  handleOnViewCMSPool = (event, project, projectCMS, crCMS) => {
    event.preventDefault();
    this.props.onViewCMSPool(project, 'view', projectCMS, crCMS);
  };

  handleWithdrawCMS = (event, project, projectCMS, crCMS) => {
    event.preventDefault();
    if (project) {
      this.props.handleWithdrawCMS(project, projectCMS, crCMS);
    }
  };

  handleApproveCMS = (event, project, projectCMS, crCMS)=>{
    event.preventDefault();
    this.props.onSelectCMS(project, 'approve', projectCMS, crCMS);
  }

  handleApproveCMSPool = (event, project, projectCMS, crCMS)=>{
    event.preventDefault();
    this.props.onSelectCMSPool(project, 'approve', projectCMS, crCMS);
  }

  hanldeEditCMSPool = (event, project, projectCMS, crCMS) => {
    event.preventDefault();
    this.props.onViewCMSPool(project, 'edit', projectCMS, crCMS);
  };

  handleCreateCMSforCR = (event,changeReqList, project) =>{
    event.preventDefault();
    this.props.onCreateCMSforCR(changeReqList, project ,'CreateCMSforCR');
  }

  getStatus(statusArr) {
    if(statusArr.length > 3){
      let tmpArr = statusArr.slice(0, 3)
      let tmpStr = tmpArr.join(", ")
      return tmpStr + "..."
    }else{
      return statusArr.join(", ")
    }
  }

  onCheckPoolProjectOwner = async (project) => {
    let IsProjectOwner = false
    await getProjectResources()
        .then(res => {
          const projectOwner = res.data.filter(obj => obj.id === project.ResourceId)

          IsProjectOwner = projectOwner ? (projectOwner[0].Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase() ? true : false) : false

          this.setState({
            IsProjectOwner: IsProjectOwner
          });

        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

    //return Promise.resolve(IsProjectOwner);
  }

  render() {
    const project = this.props.dataItem;
    let status = 'PENDING';
    let displayStatus = 'Pending';
    let userHasPendingApproval = false;
    let isProjectBillable = project.ProjectStatus.StatusName === 'Billable';
    let manipulatePermissions = project.ManipulateCMSheetPermissions;
    let isClosedStatus = false;
    let bool = this.state.IsProjectOwner;
    let projectStatusDef = '';

    if (project.ProjectStatus) {
      projectStatusDef = project.ProjectStatus.StatusName;
    }

    if (project.ProjectStatus) {
      let projectName = project.ProjectStatus.StatusName;
      isClosedStatus = projectName.toString().toLowerCase().includes(closeSting);
    }

    let viewCMSPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_CMS');
    let viewPoolCMSPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_FAC_PRJ');
    let createPoolCMSPermission = window.USER_PERMISSION.filter(obj => obj === 'FAC_PRO');


    let displayStatusesArray = [];
    // TODO should be changed in phase 1.1 when multiple cm sheets allowed
    if (project.CMSheets.length > 0) {
      status = project.CMSheets[0].Status;
      switch (status) {
        case 'IN_PROGRESS': {
          displayStatus = 'In Progress';
          break;
        }
        case 'PENDING_APPROVALS': {
          displayStatus = 'Pending Approvals';
          break;
        }
        case 'APPROVED': {
          displayStatus = 'Approved';
          break;
        }
        case 'REJECTED': {
          displayStatus = 'Rejected';
          break;
        }
        case 'EXPIRED': {
          displayStatus = 'Expired';
          break;
        }
        case 'WITHDRAWN': {
          displayStatus = 'Withdrawn';
          break;
        }
        default: {
          break;
        }
      }
      displayStatusesArray.push(displayStatus);

      if (project.CMSheets[0].userHasPendingApproval === true) {
        userHasPendingApproval = true;
      }
    } else {
      displayStatusesArray.push('Pending');
    }

    let cmsAvailable = false;
    project.ChangeRequests.forEach(function (CR) {
        if(CR.CMSheets.length > 0){
          cmsAvailable = true;
        }
    })

    if(project.CMSheets.length > 0){
      cmsAvailable = true;
    }

    let changeReqList = [];

    // Edit CMS (Project and CR both) Logic
    let editProjectCMS = [];
    let editCrCMS = [];

    // Delete CMS (Project and CR both) Logic
    let deleteProjectCMS = [];
    let deleteCrCMS = [];

    // View CMS ( Project and CR both ) Logic
    let viewProjectCMS = [];
    let viewCrCMS = [];

    // Revise CMS ( Project and CR both ) Logic
    let reviseProjectCMS = [];
    let reviseCrCMS = [];

    // Request Revise CMS ( Project and CR both ) Logic
    let requestReviseProjectCMS = [];
    let requestReviseCrCMS = [];

    // Approve CMS ( Project and CR) logic
    let approveProjectCMS = [];
    let approveCrCMS = [];

    let pmAccess = false;
    let crAccess = false;

    let displayCRCMSStatusesArray = [];

    let orderedCRS = project.ChangeRequests.sort((a,b) =>  a.id - b.id)

    orderedCRS.forEach(function (CR) {

    let isCRClosedStatus = false;
      const crProjectManagers = CR.AllocatedPM;

      //find valid pms from end date
      let crValidManagers = [];
      crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (crValidManagers.length == 0 && crProjectManagers.length > 0){
        //crValidManagers.push(crProjectManagers[0]);

        crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
        const lastAssignedManager = crProjectManagers[0];
        crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

      }

    if (CR.Status) {
      let crCMSStatus = CR.CMSheets && CR.CMSheets[0] ? CR.CMSheets[0].Status : CR.CMSheets.length === 0 ? 'PendingCMS' : '';

      // Display CMS Status logic
      let diplayCrCMSStatus = null
      switch (crCMSStatus) {
        case 'IN_PROGRESS': {
          diplayCrCMSStatus = 'In Progress';
          break;
        }
        case 'PENDING_APPROVALS': {
          diplayCrCMSStatus = 'Pending Approvals';
          break;
        }
        case 'APPROVED': {
          diplayCrCMSStatus = 'Approved';
          break;
        }
        case 'REJECTED': {
          diplayCrCMSStatus = 'Rejected';
          break;
        }
        case 'EXPIRED': {
          diplayCrCMSStatus = 'Expired';
          break;
        }
        case 'PendingCMS': {
          diplayCrCMSStatus = 'Pending';
          break;
        }
        case 'WITHDRAWN': {
          diplayCrCMSStatus = 'Withdrawn';
          break;
        }
        default: {
          break;
        }
      }
      displayCRCMSStatusesArray.push(diplayCrCMSStatus);
      let crName = CR.Status.StatusName;
      isCRClosedStatus = crName.toString().toLowerCase().includes(closeSting);
    }

        if(CR.CMSheets.length > 0){
          cmsAvailable = true;

        // Edit CMS CR
          if (CR && CR.ResourceRequestStatus && CR.ResourceRequestStatus === 'PM_ALLOCATED') {
            if (crValidManagers && crValidManagers.length>0) {
              let viewCRObj = null;
              let editCRObj = null;
              let isViewCRCMS = false;
              let isEditCRCMS = false;
              for(const allocatedPM of crValidManagers){
                if (!allocatedPM.IsPresales && (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase())) {
                  crAccess = true
                  viewCRObj = {
                    name: CR.CRName,
                    data: CR
                  };
                  isViewCRCMS = true;

                  if (CR.CMSheets[0].Status === 'IN_PROGRESS' && CR.Status.Code === 'PRESALES') {
                    editCRObj = {
                      name: CR.CRName,
                      data: CR
                    };
                    isEditCRCMS = true
                  }  else if (CR.CMSheets[0].Status === 'IN_PROGRESS' && (CR.Status.Code === 'BILLABLE' || CR.Status.Code === 'WORK_AT_RISK')) {
                    editCRObj = {
                      name: CR.CRName,
                      data: CR
                    };
                    isEditCRCMS = true;
                  }

                }
              }
              if (viewCRObj && isViewCRCMS) {
                viewCrCMS.push(viewCRObj);
              }
              if (editCRObj && isEditCRCMS) {
                editCrCMS.push(editCRObj);
              }
            }
          }
          else if((CR.IsPoolProject && CR.IsPoolProject === true)) {

            if (bool === true || viewPoolCMSPermission) {
              const viewCRObj = {
                name: CR.CRName,
                data: CR
              };
              viewCrCMS.push(viewCRObj);
            }

            if (bool === true || createPoolCMSPermission) {
              if (CR.CMSheets[0].Status === 'IN_PROGRESS' && CR.Status.Code === 'PRESALES') {
                const editCRObj = {
                  name: CR.CRName,
                  data: CR
                };
                editCrCMS.push(editCRObj);
              }
            }
          }
          else {
            let viewCRObj = null;
            let editCRObj = null;
            let isViewCRCMS = false;
            let isEditCRCMS = false;
            let isDeleteCRCMS = false;

            crValidManagers.forEach(allocatedPM => {
              if (allocatedPM.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === allocatedPM.PMResource.Email.toUpperCase() && CR.IsActive === true)) {
                crAccess = true;
                viewCRObj = {
                  name: CR.CRName,
                  data: CR
                };
                isViewCRCMS = true;

                if (CR.CMSheets[0].Status === 'IN_PROGRESS' && CR.Status.Code === 'PRESALES') {
                  editCRObj = {
                    name: CR.CRName,
                    data: CR
                  };
                  isEditCRCMS = true;
                  isDeleteCRCMS = true;
                }

              }
            });

            if (viewCRObj && isViewCRCMS) {
              viewCrCMS.push(viewCRObj);
            }
            if (editCRObj && isEditCRCMS) {
              editCrCMS.push(editCRObj);
            }
            if (editCRObj && isDeleteCRCMS) {
              deleteCrCMS.push(editCRObj);
            }


          }

          let reviseCRObj = null;
          let isReviseCRCMS = false;

          crValidManagers.forEach(allocatedPM => {
            // Revise CMS
            if(allocatedPM.IsPresales && ( (CR.CMSheets[0].Status === 'REJECTED' || CR.CMSheets[0].Status === 'APPROVED' || CR.CMSheets[0].Status === 'EXPIRED' || CR.CMSheets[0].Status === 'WITHDRAWN')
              && (CR.Status && CR.Status.Code === 'PRESALES') && window.LOGGED_USER.userId.toUpperCase() === allocatedPM.PMResource.Email.toUpperCase()
            )){
              reviseCRObj={
                name: CR.CRName,
                data : CR
              }
              isReviseCRCMS = true;
            } else if (!allocatedPM.IsPresales && (CR.Status.Code === 'BILLABLE' || CR.Status.Code === 'WORK_AT_RISK') && 
              (CR.CMSheets[0].Status === 'REJECTED' || CR.CMSheets[0].Status === 'APPROVED' || CR.CMSheets[0].Status === 'WITHDRAWN') && window.LOGGED_USER.userId.toUpperCase() === allocatedPM.PMResource.Email.toUpperCase()
            ) {
              const reviseCMSrequests = CR.ReviseCMSRequest;
              if (reviseCMSrequests && reviseCMSrequests.length > 0) {
                const sortedArray = reviseCMSrequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                if(sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved){
                  reviseCRObj={
                    name: CR.CRName,
                    data : CR
                  }
                  isReviseCRCMS = true;
                }
              }
            }
          });

          if (reviseCRObj && isReviseCRCMS) {
            reviseCrCMS.push(reviseCRObj);
          }

        // Approve CMS
        if( CR.CMSheets[0].Status === 'PENDING_APPROVALS' && CR.CMSheets[0].userHasPendingApproval === true && !isCRClosedStatus){
          const approveCRCMSObj={
            name: CR.CRName,
            data : CR
          }
          approveCrCMS.push(approveCRCMSObj);
        }

        if(CR.CMSheets.length > 0 && CR.StatusConvertedToActive && CR.Status && 
          (CR.Status.Code === 'BILLABLE' || CR.Status.Code === 'WORK_AT_RISK') && crAccess) {

          const projActivatedDate = new Date(CR.ConvertedToActiveAt);
          const fromTwoMonthDate = moment(projActivatedDate).add(2, 'months').format('YYYY-MM-DD');
          const currentDate = moment(new Date()).format('YYYY-MM-DD');

          if(new Date(currentDate) <= new Date(fromTwoMonthDate)) {
            let allowReviseCMS = true;
            const reviseCMSrequests = CR.ReviseCMSRequest;
            if (reviseCMSrequests && reviseCMSrequests.length > 0) {
              const sortedArray = reviseCMSrequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
              if(reviseCMSrequests.length >= 2 || sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
              (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)){
                allowReviseCMS = false;
              }
            }

            let isCrPM = false;
            crValidManagers.forEach(allocatedPM => {
              if(!allocatedPM.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === allocatedPM.PMResource.Email.toUpperCase())){
                isCrPM = true;
              }
            });
      
            let reviseCRObj = null;
            let isRequestReviseCRCMS = false;
                
            if(allowReviseCMS && isCrPM) {
              reviseCRObj={
                name: CR.CRName,
                data : CR
              }
              isRequestReviseCRCMS = true;
            }
      
            if (reviseCRObj && isRequestReviseCRCMS) {
              requestReviseCrCMS.push(reviseCRObj);
            }
          }         
        }
      }
      else {
        //This logic is when a CR doesnt have a CMS
          // if (CR && CR.ResourceRequestStatus && CR.ResourceRequestStatus === 'PM_ALLOCATED') {
          //   if (crValidManagers && crValidManagers.length>0) {
          //     for(const allocatedPM of crValidManagers){

          //       if (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()){
          //         if(CR.Status.Code === 'PRESALES'){
          //           changeReqList.push(CR);
          //         }
          //       }

          //     }
          //   }
          // } else {
          //   if(project.IsPoolProject === true && CR.Status.Code === 'PRESALES'){

          //     changeReqList.push(CR);

          //   }
          // }
          if (CR.Status.Code === 'PRESALES') {
            let isCreateCRCMS = false;
            crValidManagers.forEach(value => {
              if (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()) {
                isCreateCRCMS = true;
              }
            });
            if (isCreateCRCMS) {
              changeReqList.push(CR);
            }
          }
      }
    })

    const editProjPermission = window.USER_PERMISSION.filter(obj => obj === 'EDT_PRJ');
    const projectProjectManagers = project.AllocatedResourceRequestPM;

    //find valid pms from end date
    let projValidManagers = [];
    projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

    if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
      //projValidManagers.push(projectProjectManagers[0]);

      projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
      const lastAssignedManager = projectProjectManagers[0];
      projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

    }

    if (project.CMSheets && project.CMSheets.length>0) {
      let projectManager = false;

        if (projValidManagers && projValidManagers.length>0) {
          for(const pm of projValidManagers) {
            if ((pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase())) {
              projectManager = true;
              pmAccess = true;
            }
          }
        }

      if (projectManager === true || viewCMSPermission) {
        const viewProjObj = {
          name: project.ProjectName,
          data: project
        };
        viewProjectCMS.push(viewProjObj);
      }

      if(projectManager === true ){
        if (project.CMSheets[0].Status === 'IN_PROGRESS' && project.ProjectStatus.Code === 'PRESALES') {
          const editProjObj = {
            name: project.ProjectName,
            data: project
          };
          editProjectCMS.push(editProjObj);
          deleteProjectCMS.push(editProjObj);
        } else if (project.CMSheets[0].Status === 'IN_PROGRESS' && (project.ProjectStatus.Code === 'BILLABLE' || project.ProjectStatus.Code === 'WORK_AT_RISK')) {
          const editProjObj = {
            name: project.ProjectName,
            data: project
          };
          editProjectCMS.push(editProjObj);
        }

      }
    }

    if(project.CMSheets.length > 0 && (pmAccess || (editProjPermission && editProjPermission.length > 0))){
      cmsAvailable = true;

      // Revise CMS
      let reviseProjectObj = null;
      let isReviseProjectCMS = false;

      projValidManagers.forEach(value => {
        if(value.IsPresales && ((project.CMSheets[0].Status === 'REJECTED' || project.CMSheets[0].Status === 'APPROVED' || project.CMSheets[0].Status === 'EXPIRED' || project.CMSheets[0].Status === 'WITHDRAWN') &&
          project.ProjectStatus.Code === 'PRESALES' &&
          window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
          reviseProjectObj={
            name: project.ProjectName,
            data : project
          }
          isReviseProjectCMS = true;
        } else if (!value.IsPresales && (project.ProjectStatus.Code === 'BILLABLE' || project.ProjectStatus.Code === 'WORK_AT_RISK') && 
          (project.CMSheets[0].Status === 'REJECTED' || project.CMSheets[0].Status === 'APPROVED' || project.CMSheets[0].Status === 'WITHDRAWN') && window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()
        ) {
          const reviseCMSrequests = project.ReviseCMSRequest;
          if (reviseCMSrequests && reviseCMSrequests.length > 0) {
            const sortedArray = reviseCMSrequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            if(sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved){
              reviseProjectObj={
                name: project.ProjectName,
                data : project
              }
              isReviseProjectCMS = true;
            }
          }
        }
      });

      if (reviseProjectObj && isReviseProjectCMS) {
        reviseProjectCMS.push(reviseProjectObj);
      }

      // Approve CMS
      if( project.CMSheets[0].Status === 'PENDING_APPROVALS' && project.CMSheets[0].userHasPendingApproval === true && !isClosedStatus){
        const approveProjectCMSObj={
          name: project.ProjectName,
          data : project
        }
        approveProjectCMS.push(approveProjectCMSObj);
      }
    }

    if(project.CMSheets.length > 0 && project.StatusConvertedToActive && project.ProjectStatus && 
      (project.ProjectStatus.Code === 'BILLABLE' || project.ProjectStatus.Code === 'WORK_AT_RISK') && pmAccess) {

      const projActivatedDate = new Date(project.ConvertedToActiveAt);
      const fromTwoMonthDate = moment(projActivatedDate).add(2, 'months').format('YYYY-MM-DD');
      const currentDate = moment(new Date()).format('YYYY-MM-DD');
      
      if (new Date(currentDate) <= new Date(fromTwoMonthDate)) {
        let allowReviseCMS = true;
        const reviseCMSrequests = project.ReviseCMSRequest;
        if (reviseCMSrequests && reviseCMSrequests.length > 0) {
          const sortedArray = reviseCMSrequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          if(reviseCMSrequests.length >= 2 || sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
          (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)){
            allowReviseCMS = false;
          }
        }
        let isProjectPM = false;
        projValidManagers.forEach(allocatedPM => {
          if(!allocatedPM.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === allocatedPM.PMResource.Email.toUpperCase())){
            isProjectPM = true;
          }
        });
        
        let reviseProjectObj = null;
        let isRequestReviseProjectCMS = false;
        
        if(allowReviseCMS && isProjectPM) {
          reviseProjectObj={
            name: project.ProjectName,
            data : project
          }
          isRequestReviseProjectCMS = true;
        }
  
        if (reviseProjectObj && isRequestReviseProjectCMS) {
          requestReviseProjectCMS.push(reviseProjectObj);
        }
      }
    }

    displayStatusesArray = displayStatusesArray.concat(displayCRCMSStatusesArray)

    const pendingApprovalStatuses = displayStatusesArray.filter(obj => obj === 'Pending Approvals');

    return (
      <td className="action-buttons" title={displayStatusesArray.join(', ')}>
        Status : {this.getStatus(displayStatusesArray)}
        <br />
        {/*{!isClosedStatus && status === 'PENDING' && manipulatePermissions === true && (
          <PermissibleRender
              userPermissions={window.USER_PERMISSION}
              requiredPermissions={['CRT_CMS']}
          >
            <Link
              to={{
                pathname: '/projects/cmsheet/cmsheet',
                projectId: project.id,
                reference: 'projectCMS',
                action: 'create'
              }}
            >
              <div className="rounded-link">
                <span className="k-icon k-i-track-changes" title="Create" />
              </div>
            </Link>
          </PermissibleRender>
        )}*/}

        {!isClosedStatus && status === 'PENDING' && manipulatePermissions === true && (project.IsPoolProject === false) && (
          <PermissibleRender
            userPermissions={window.USER_PERMISSION}
            requiredPermissions={['CRT_CMS']}
          >
            <a href="#" onClick={event => this.handleCreateCMS(event,project)}>
              <div className="rounded-link">
                <span className="k-icon k-i-track-changes" title="Create"/>
              </div>
            </a>
          </PermissibleRender>
        )}

        {(projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' )
        && status === 'PENDING' && (manipulatePermissions === true || createPoolCMSPermission) && (project.IsPoolProject === true) &&  (
            <Link
                to={{
                  pathname: '/PoolProject/FactoryProjectCMS',
                  projectId: project.id,
                  reference: 'projectCMS',
                  action: 'create'
                }}
            >
              <div className="rounded-link">
                <span className="k-icon k-i-track-changes" title="Create" />
              </div>
            </Link>
        )}

        { ( (viewCrCMS && viewCrCMS.length>0) || (viewProjectCMS && viewProjectCMS.length>0)  )  && ((project.IsPoolProject === false)) && (
              <a href="#" onClick={event => this.handleViewCMS(event,project, viewProjectCMS, viewCrCMS)}>
                <div className="rounded-link">
                  <span className="k-icon k-i-preview" title="View"/>
                </div>
              </a>
        )}

        {((viewCrCMS && viewCrCMS.length>0) || (viewProjectCMS && viewProjectCMS.length>0)) && ((project.IsPoolProject === true)) &&  (
            <a
                href="#"
                onClick={event => this.handleOnViewCMSPool(event, project, viewProjectCMS, viewCrCMS)}
            >
              <div className="rounded-link">
                <span className="k-icon k-i-preview" title="View" />
              </div>
            </a>
        )}

        {((viewCrCMS && viewCrCMS.length>0) || (viewProjectCMS && viewProjectCMS.length>0)) && (pmAccess === true || crAccess === true)  && ((project.IsPoolProject === false) && (pendingApprovalStatuses.length > 0)) && (
            <a
            href="#"
            onClick={event => this.handleWithdrawCMS(event, project, viewProjectCMS, viewCrCMS)}
            >
              <div className="rounded-link" title="Create a CMS for CR">
                  <span className="k-icon k-i-arrow-rotate-ccw" title="Withdraw CMS" />
              </div>
            </a>
        )}

        { (editCrCMS.length>0 || editProjectCMS.length>0) && (pmAccess === true || crAccess === true)  && (project.IsPoolProject === false) && (
            <a href="#" onClick={event => this.hanldeEditCMS(event,project, editProjectCMS, editCrCMS)}>
              < div className="rounded-link">
                <span className="k-icon k-i-edit" title="Edit CR/Project CMS" />
              </div>
            </a>

        )}

        {(editCrCMS.length > 0 || editProjectCMS.length > 0) &&
        (project.IsPoolProject === true) && (
            <a
                href="#"
                onClick={event => this.hanldeEditCMSPool(event, project, editProjectCMS, editCrCMS)}
            >
              <div className="rounded-link">
                <span className="k-icon k-i-edit" title="Edit Factory CR/Project CMS" />
              </div>
            </a>
        )}

        { (approveProjectCMS.length>0 || approveCrCMS.length>0) &&  (project.IsPoolProject === false) &&
        (
            <a href="#" onClick={event => this.handleApproveCMS(event,project, approveProjectCMS, approveCrCMS)}>
              < div className="rounded-link">
                <span className="k-icon k-i-check" title="Approve CMS" />
              </div>
            </a>
        )}

        { (approveProjectCMS.length>0 || approveCrCMS.length>0) &&  (project.IsPoolProject === true) &&
        (
            <a href="#" onClick={event => this.handleApproveCMSPool(event,project, approveProjectCMS, approveCrCMS)}>
              < div className="rounded-link">
                <span className="k-icon k-i-check" title="Approve CMS" />
              </div>
            </a>
        )}

        {(reviseProjectCMS.length > 0 || reviseCrCMS.length >0) && (pmAccess === true || crAccess === true) &&  (project.IsPoolProject === false) &&
        (
            <a href="#" onClick={event => this.handleReviseCMS(event,project, reviseProjectCMS, reviseCrCMS)}>
              < div className="rounded-link">
                <span className="k-icon k-i-categorize" title="Revise CMS" />
              </div>
            </a>
        )}

        {(requestReviseProjectCMS.length > 0 || requestReviseCrCMS.length >0) && (pmAccess === true || crAccess === true) &&  (project.IsPoolProject === false) &&
        (
            <a href="#" onClick={event => this.handleRequestReviseCMS(event,project, requestReviseProjectCMS, requestReviseCrCMS)}>
              < div className="rounded-link">
                <span className="k-icon k-i-reload-sm" title="Request to Revise CMS" />
              </div>
            </a>
        )}

        {(reviseProjectCMS.length > 0 || reviseCrCMS.length >0) && (pmAccess === true || crAccess === true) &&  (project.IsPoolProject === true) &&
        (
            <a href="#" onClick={event => this.handleReviseCMSPool(event,project, reviseProjectCMS, reviseCrCMS)}>
              < div className="rounded-link">
                <span className="k-icon k-i-categorize" title="Revise CMS" />
              </div>
            </a>
        )}

        {(deleteCrCMS.length>0 || deleteProjectCMS.length>0) &&cmsAvailable === true && (pmAccess === true || crAccess === true) && ((project.IsPoolProject === false)) && (
          <PermissibleRender
            userPermissions={window.USER_PERMISSION}
            requiredPermissions={['DLT_CMS']}
          >
            <a href="#" onClick={this.handleDeleteCMSheet}>
              <div className="rounded-link" title="Delete">
                <span className="k-icon k-i-delete" />
              </div>
            </a>
          </PermissibleRender>
        )}

        {(deleteCrCMS.length>0 || deleteProjectCMS.length>0) &&cmsAvailable === true && (pmAccess === true || crAccess === true) && ((project.IsPoolProject === true)) && (
          <PermissibleRender
            userPermissions={window.USER_PERMISSION}
            requiredPermissions={['FAC_PRO']}
          >
            <a href="#" onClick={this.handleDeleteCMSheet}>
              <div className="rounded-link" title="Delete">
                <span className="k-icon k-i-delete" />
              </div>
            </a>
          </PermissibleRender>
        )}

        {changeReqList.length > 0 && (project.IsPoolProject === false) &&(
            <a href="#" onClick={event => this.handleCreateCMSforCR(event,changeReqList, project)}>
              <div className="rounded-link" title="Create a CMS for CR">
                <span className="k-icon k-i-table-properties" />
              </div>
            </a>
        )}

        {changeReqList.length > 0  && (project.IsPoolProject === true) && (
            <a href="#" onClick={event => this.handleCreateCMSforCRPool(event, changeReqList, project)}>
              <div className="rounded-link" title="Create a CMS for Pool CR">
                <span className="k-icon k-i-table-properties" />
              </div>
            </a>
        )}

        {/* <a href="#">
          <div class="rounded-link">
            <span class="k-icon k-i-categorize" title="View Consolidated CMS" />
          </div>
        </a> */}
      </td>
    );
  }
}

export default CMSheetFunctions;
