import React, { Component } from "react";
import { Link } from "react-router-dom";

class EditDesignationActionsCell extends Component {
  render() {
    const { OnDeactivate } = this.props;
    const { OnActivate } = this.props;
    const designation = this.props.dataItem;
    return (
      <td className="action-buttons">
        {designation.IsActive === true && (window.USER_PERMISSION.indexOf('ED_DEG') > -1 ) && (
          <Link
            to={{
              pathname: "/admin/createdesignation/createdesignation",
              designation: designation,
              action: "edit",
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-edit" title="Edit" />
            </div>
          </Link>
        )}
        {designation.IsActive === true && (window.USER_PERMISSION.indexOf('ED_DEG') > -1 ) && (
          <Link
            to={this.props}
            onClick={event => OnDeactivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-unsort" title="Deactivate" />
            </div>
          </Link>
        )}
        {designation.IsActive === false && (window.USER_PERMISSION.indexOf('ED_DEG') > -1 ) && (
          <Link
            to={this.props}
            onClick={event => OnActivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-check" title="Activate" />
            </div>
          </Link>
        )}
      </td>
    );
  }
}

export default EditDesignationActionsCell;
