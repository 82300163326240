import React, { Component } from 'react';

class RowCellCommentBox extends Component {
    handleChange = event => {
        event.preventDefault();
        this.props.onChange({
            dataItem: this.props.dataItem,
            field: this.props.field,
            syntheticEvent: event.syntheticEvent,
            value: event.target.value
        });
    };

    render() {
        let allocationComment = '';
        if (!this.props.dataItem.inEdit) {
            if (this.props.dataItem[this.props.field]) {
                allocationComment = this.props.dataItem[this.props.field]
            }
            return <td>{allocationComment}</td>;
        }
        if (this.props.dataItem[this.props.field]) {
            allocationComment = this.props.dataItem[this.props.field];
        }
        return (
            <td>
                <div className="k-form-field">
                    <textarea
                        className="k-textarea"
                        value={allocationComment}
                        onChange={this.handleChange}
                        maxLength={1000}
                    />
                </div>
            </td>
        );
    }
}

export default RowCellCommentBox;
