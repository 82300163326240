import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class DateCell extends Component {
  render() {
    const value = this.props.dataItem.modifiedAt;
    return <td><div className="date-time"><Moment format="MM-DD-YYYY">
      {value}
    </Moment></div></td>;
  }
}

export default DateCell;