import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Get all Engagement Types for drop down in create project.
 * @returns {array} array list of Engagement Type
 */
export function getEngagementType() {
  const endPoint = apiUrl + '/master/projectTypes/getall';
  return http.get(endPoint);
}

/**
 * search Engagement Types by param.
 * @returns {array} array list of Engagement Type
 * @param params
 */
export function searchEngagementType(params) {
  const endPoint = apiUrl + '/masterData/engagementType/projectTypes/search';
  return http.post(endPoint, params);
}

/**
 * Save Engagement Types whe create/update.
 * @returns {array} array list of Engagement Type
 * @param params
 */
export function saveEngagementType(params) {
  const endPoint = apiUrl + '/masterData/engagementType/projectTypes/save';
  return http.post(endPoint, params);
}

/**
 * Update Engagement Type by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param engagementType
 * @param id
 */
export function updateEngagementType(engagementType, id) {
  const updateDesigEndpoint = apiUrl + '/masterData/engagementType/projectTypes/update/' + id;
  return http.put(updateDesigEndpoint, engagementType);
}

/**
 * Update Engagement Type status by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param IsActive
 * @param id
 */
export function updateEngagementTypeStatus(IsActive, id) {
  const endPoint = apiUrl + '/masterData/engagementType/projectTypes/updateStatus/' + id;
  return http.put(endPoint, IsActive);
}