import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

class ConfirmationDialog extends Component {

    render() {
        return (
            <Dialog title={this.props.dialogTitle} onClose={this.props.dismissCallback}>
                <p style={{ margin: '25px', textAlign: 'center' }}> {this.props.dialogMessage}</p>
                <DialogActionsBar>
                    <button className="k-button" onClick={this.props.abortCallback}>
                        No
                    </button>
                    <button className="k-button modal-primary" onClick={this.props.confirmCallback}>
                        Yes
                    </button>
                </DialogActionsBar>
            </Dialog>
        )
    }
}

export default ConfirmationDialog;
