import React, {Component} from 'react';
import {ComboBox, MultiSelect} from '@progress/kendo-react-dropdowns';
import debounce from 'lodash.debounce';
import _ from 'lodash';
import * as loggerService from '../../../integration/LoggerService';
import { LOG_TYPES } from '../../../integration/IntegrationEnums';

class AsyncSelectMultipleServices extends Component{

    constructor(props){
        super(props);
        this.onFilterChange = debounce(this.onFilterChange, this.props.delay);
        this.state = {
            dataCaching: [],
            services: [],
            filter: '',
            requestStarted: false,
            loading: false,
            skip: 0,
            data: [],
            total: 0,
        }
    }

    static getDerivedStateFromProps(nextProps, state){
        if(!_.isEqual(nextProps.services, state.services)){
            return {
                services: nextProps.services
            }
        }
        if(!_.isEqual(nextProps.additionalBind, state.additionalBind)){
            return {
                additionalBind: nextProps.additionalBind
            }
        }
        return null;
    }

    updateData = (services) => {
        this.setState({services}, () => {
            this.requestData(0, this.state.filter);
        })
    }

    componentDidMount() {
        this.updateData(this.props.services);
    }
    
    filterChange = (event, isOnOpen) => {
        if(isOnOpen && this.props.isFetchOnOpen){
            return this.props.filter(this.props.onOpenValue);
        }
        if(event.filter){
            this.props.filter(event.filter.value);
        }
    };

    async requestData(skip, filter) {
        try {
            this.setState({requestStarted: true});
            const results = await Promise.all(this.state.services.map(async service => {
                return await service({
                    ...this.props.additionalBind,
                    filter,
                    offset: Math.trunc(skip/this.state.services.length),
                    limit: this.props.pageSize
                });
            }));
            const [total, values] = results.reduce((acc, cur) => {
                const {count, rows} = cur.data;
                return [acc[0] + count, [...acc[1], ...rows]];
            }, [0, []]);
            const items = [];
            values.forEach((element, index) => {
                const { Name, ...rest } = element;
                const item = { [this.props.dataItemKey]: element[this.props.dataItemKey], [this.props.textField]: element[this.props.textField], ...rest };
                items.push(item);
                this.state.dataCaching[index + skip] = item;
            });

            if (skip === this.state.skip) {
                this.setState({
                    data: this.props.addFilterIfNull ? items.findIndex(e => e[this.props.textField].toLowerCase() === filter.toLowerCase()) === -1 ? [...items, {[this.props.textField]: filter}] : items : items,
                    total: total
                });
            }
            this.setState({requestStarted: false});
        } catch (error) {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        }
    }

    pageChange = (event) => {
        const skip = event.page.skip;
        const filter = this.state.filter;

        if (this.shouldRequestData(skip)) {
            this.requestData(skip, filter);
        }

        const data = this.getCachedData(skip);

        this.setState({
            data: data,
            skip: skip
        });
    }

    onFilterChange = (event) => {
        const filter = event.filter.value;

        this.resetCach();
        this.requestData(0, filter);

        this.setState({
            data: this.props.loadingData,
            skip: 0,
            filter: filter
        });
    }

    filterController = (e, isOnOpen = false) => {
        this.setState({loading: isOnOpen ? isOnOpen && this.props.isFetchOnOpen : true}, () => {
            this.filterChange(e, isOnOpen);
        });
    }

    componentDidUpdate(prevProps){
        if(prevProps.loading !== this.props.loading){
            this.setState({loading: this.props.loading});
        }

        if(!_.isEqual(this.props.services, prevProps.services)){
            this.updateData(this.props.services);
        }

        if(!_.isEqual(this.props.additionalBind, prevProps.additionalBind)){
            this.updateData(this.props.services);
        }
    }

    componentWillUnmount() {
        this.resetCach();
    }

    shouldRequestData(skip) {
        for (let i = 0; i < this.props.pageSize; i++) {
            if (!this.state.dataCaching[skip + i]) {
                return true;
            }
        }
        return false;
    }

    getCachedData(skip) {
        const data = [];
        for (let i = 0; i < this.props.pageSize; i++) {
            data.push(this.state.dataCaching[i + skip] || this.props.emptyItem);
        }
        return data;
    }

    resetCach() {
        this.state.dataCaching.length = 0;
    }
    
    render() {
        return (
            <MultiSelect
                data={this.state.data}
                name={this.props.name}
                value={this.props.value}
                placeholder={'Please select'}
                textField={this.props.textField}
                dataItemKey={this.props.dataItemKey}
                loading={this.state.requestStarted}
                filterable={this.props.filterable || true}
                onFilterChange={this.onFilterChange}
                virtual={{
                    pageSize: this.props.pageSize,
                    skip: this.state.skip,
                    total: this.state.total
                }}
                onPageChange={this.pageChange}
                onChange={this.props.onChange}
                disabled={this.state.disabled || false}
            />
        );
    }
}

export default AsyncSelectMultipleServices;