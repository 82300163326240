import React, { Component } from 'react';
import moment from 'moment';

class FormatCell extends Component {
  render() {
    const value = this.props.dataItem[this.props.field];
    if (this.props.field == 'PlannedRevenue' || this.props.field == 'ActualRevenue') {
      return <td>{Number(value).toFixed(2)}</td>;
    }
    return <td>{Number(value).toFixed(2) + "%"}</td>;
  }
}

export default FormatCell;