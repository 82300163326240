import React, { Component } from 'react';

class ProjectTypeCell extends Component {
  render() {
    const type = this.props.dataItem.Type;
    const projectOrNonProject = this.props.dataItem.ProjectOrNonProject;
    var value = null;
    
    if (projectOrNonProject === "Project") {
      if (type === "ChangeRequest") {
        value = this.props.dataItem.ChangeRequest.UniqueId
      }
      else {
        value = this.props.dataItem.Project.UniqueId         
      }
    }
    else {
      value = null;
    }

    return <td>
      {value}
    </td>;
  }
}

export default ProjectTypeCell;