import React, { Component } from 'react';
import 'moment-timezone';
import Parser from 'html-react-parser';
import ReactQuill from "react-quill";

class OtherExpenseCommentCell extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Comment:null
        }
    }

    onChangeOtherExpenseComment = async (event) => {
        const Comment = event.target.value;
        const expenses = this.props.otherExpenses.slice();
        const index = this.props.dataItem.index;
        const name = event.target.name;

        this.setState(
            {
                Comment: Comment,
            },
        );

        if (index > -1) {
            expenses[index].Comment = Comment  ? Comment : null;
            expenses[index].dataValueComment = Comment  ? Comment : null;

            //if (expenses[index].isAutoPopulated) {
                //expenses[index].autoPopulatedValueChaged = true;
                // if (!(expenses[index].changedFields.indexOf('dataValueComment') > -1)) {
                //     expenses[index].changedFields.push('dataValueComment');
                // }
            //}
        }

        this.setState(
            {
                //autoPopulated: true,
                otherExpenses: expenses,
                isChangeValue: true

            },
        );
        // this.setState({
        //     autoPopulated: false
        // });

        //this.props.updateCMSheet();


    };


    render() {
        const value = this.props.dataItem.Comment;
        if(value==null){
            return <td>
                <textarea
                    className="k-textarea"
                    name="Comment"
                    onChange={this.onChangeOtherExpenseComment}
                    value={this.state.Comment}
                    maxLength="100"
                    disabled={this.props.viewMode}
                />
            </td>;
        } else{
            return <td>
                <textarea
                    className="k-textarea"
                    name="Comment"
                    onChange={this.onChangeOtherExpenseComment}
                    value={Parser(value)}
                    maxLength="100"
                    disabled={this.props.viewMode}
                />
            </td>;
            //return <td><div className={'rich-text-bullets'}>{Parser(value)}</div></td>;
        }

    }
}

export default OtherExpenseCommentCell;
