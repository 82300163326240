import React, { Component } from 'react';
import {PermissibleRender} from "./ProjectFunctions";
import {Link} from "react-router-dom";
import {getProjectResources} from "../../resourceManagement/ResourceService";
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import {getActivityLogDataSort} from "../../projectManagement/project/ProjectService";
import {getFormattedDate} from "../../integration/CommonUtils";

class ChangeRequestFunctions extends Component{
    constructor(props) {
      super(props);

      this.state = {
        IsProjectOwner:false,
        sortedActivity:[]
      }
    }

    componentDidMount(){
      // this.onCheckPoolProjectOwner(this.props.dataItem);
      this.populateIsPendingStatus();
    }

    populateIsPendingStatus = async () => {
      const project = this.props.dataItem;

      if(project){
        const statusChangeObj = {
          projectId: project.id,
          crId: null,
        };
  
        await this.populateSortedActivityLogData(statusChangeObj.projectId,statusChangeObj.crId,true);
      }
    }
  
    populateSortedActivityLogData = async (projectId,crId,isPending) => {
      let ids = [];
      ids  = {
        projectId : projectId,
        crId: crId,
        isPending:isPending
      }
  
      await getActivityLogDataSort(ids).then(response => {
        this.setState({
          sortedActivity: response.data
        });
      }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    };

    handleEditCR = (event, proj, isProjectManager, hasEditCRPermission, crListOfCRManager, crWithNewlyAllocatedPMs, crWithExistingPMs) => {
        event.preventDefault();
        this.props.onEditOrViewChangeReq(event, proj, 'edit', isProjectManager, hasEditCRPermission, crListOfCRManager, crWithNewlyAllocatedPMs, crWithExistingPMs);
    };

    handleViewCR = ( event, project) =>{
        event.preventDefault();
        this.props.onEditOrViewChangeReq(event, project, 'view', false, false, null);
    };

    handleDeleteCR = (event, project, isProjectManager, hasEditCRPermission, crListOfCRManager) =>{
        event.preventDefault();
        this.props.onDeleteCR(event, project, 'delete', isProjectManager, hasEditCRPermission, crListOfCRManager);
    };


    onCheckPoolProjectOwner = async (project) => {
        let IsProjectOwner = false
        await getProjectResources()
            .then(res => {
                const projectOwner = res.data.filter(obj => obj.id === project.ResourceId)

                IsProjectOwner = projectOwner ? (projectOwner[0].Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase() ? true : false) : false

                this.setState({
                    IsProjectOwner: IsProjectOwner
                });

            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        //return Promise.resolve(IsProjectOwner);
    }

    handleStatusPopUpCR = ( event, project) =>{
      event.preventDefault();
      this.props.handleStatusPopUpCR(event, project, 'HandleCrPopUp');
    };

    render() {
        const project = this.props.dataItem;
        let isConvertRequest = true;
        let isPoolProject = false;
        let beforeConvertCRs = [];
        let isPendingStatus = false;
        let editableCR = true;

        if (project.ProjectStatus) {
            let projectName = project.ProjectStatus.StatusName;
        }

        if(project.IsPoolProject){
          isPoolProject = true;
        }

        const crs = (project.ChangeRequests && project.ChangeRequests.length > 0) ? project.ChangeRequests : [];

        const activeCRs = project.ChangeRequests.filter(cr => cr.IsActive === true).length;

        let projectManager = false;
      const projectProjectManagers = project.AllocatedResourceRequestPM;

      //find valid pms from end date
      let projValidManagers = [];
      projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
        //projValidManagers.push(projectProjectManagers[0]);
          projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
          const lastAssignedManager = projectProjectManagers[0];
          projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

      }

      projValidManagers.forEach(value => {
        if(window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()){
          projectManager = true;
        }
      });

        if(crs && crs.length > 0){
          for(let cr of crs){
            if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
              if(cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING'){
                isPendingStatus = true;
              }
              else{
                isPendingStatus = false;
                break;
              }
            }
          }
          for(let cr of crs){
            if(cr.Status && (cr.Status.Code === 'PRESALES' || cr.Status.Code === 'WAIT_CONFIRM' ||
            cr.Status.Code === 'BILLABLE' || cr.Status.Code === 'ON_HOLD' || cr.Status.Code === 'PROJECT_ON_HOLD' || 
            cr.Status.Code === 'DEAL_ON_HOLD' || cr.Status.Code === 'WORK_AT_RISK')){
              editableCR = true;
              break;
            }
            else{
              editableCR = false;
            }
          }
        }

        let crManagerChangeRequests = [];
      project.ChangeRequests.forEach(function (changeReq) {
        if( changeReq.IsActive === true){
          const crProjectManagers = changeReq.AllocatedPM;

          //find valid pms from end date
          let crValidManagers = [];
          crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

          if (crValidManagers.length == 0 && crProjectManagers.length > 0){
            //crValidManagers.push(crProjectManagers[0]);
              crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
              const lastAssignedManager = crProjectManagers[0];
              crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

          }
          crValidManagers.forEach(value => {
            if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
              crManagerChangeRequests.push(changeReq);
            }
          });
        }
      });

        const deleteChangeReqCRManager = project.ChangeRequests.filter(obj => obj.IsActive === true && obj.Status.Code === 'PRESALES' && obj.CMSheets.length === 0 ).length;

        let changeReqWithoutPendingApprovals = [];
        project.ChangeRequests.forEach(function (changeReq) {
            let CMSheet = null;
            if(changeReq && changeReq.IsActive === true && changeReq.CMSheets.length>0){
                if(changeReq.CMSheets[0].Status !== 'PENDING_APPROVALS'){
                    changeReqWithoutPendingApprovals.push(changeReq);
                }
            }
            else if(changeReq && changeReq.IsActive === true ){
                changeReqWithoutPendingApprovals.push(changeReq);
            }
        });

        const createCRPermission = window.USER_PERMISSION.filter(obj => obj === 'CRT_PRJ');
        const editCRPermission = window.USER_PERMISSION.filter(obj => obj === 'EDT_PRJ');
        const deleteCRPermission = window.USER_PERMISSION.filter(obj => obj === 'DLT_PRJ');
        const viewCRPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_PRJ');
        const viewStatusChangePermission = window.USER_PERMISSION.filter(obj => obj === 'VIEW_CHANGE_STATUS');
        const changeToStatusPermissions = window.USER_PERMISSION.filter(obj => obj === 'CHG_TO_WAITING_CONFIRMATION' || obj === 'CHG_TO_CLOSE_DISCONTINUE' ||
            obj === 'CHG_TO_PROJECT_ON_HOLD' || obj === 'CHG_TO_PRESALE' || obj === 'CHG_TO_CLOSE_WITHOUT_BIDDING' || obj === 'CHG_TO_CLOSE_OPPORTUNITY_LOST' ||
            obj === 'CHG_TO_DEAL_ON_HOLD' || obj === 'CHG_TO_WORKING_AT_RISK' || obj === 'CHG_TO_ACTIVE' || obj === 'CHG_TO_COMPLETE' || obj === 'CHG_TO_CLOSED');


        // CR cell changes
        let isCRStatusCode = false;
        let multipleCR = false;

        if(project.ChangeRequests.length != 0){
            for (const cr of project.ChangeRequests) {
                if((cr.Status.Code === 'WORK_AT_RISK') || (cr.Status.Code === 'BILLABLE')){
                    isCRStatusCode = true;
                    if (cr.StatusConvertedToActive === null) {
                        isConvertRequest = false;
                    }
                }
            }
        }

        const billableAndWorkAtRiskCR = project.ChangeRequests.filter(obj => obj.IsActive === true && (obj.Status.Code === 'BILLABLE' || obj.Status.Code === 'WORK_AT_RISK') ).length;
        if (billableAndWorkAtRiskCR && billableAndWorkAtRiskCR > 1) {
            multipleCR = true
        }

        let changeRequestsWithNewlyAllocatedPMs = [];
        let changeRequestsWithExistingPMs = [];
        if (project.ChangeRequests && project.ChangeRequests.length>0) {
            for (const cr of project.ChangeRequests) {
              if (cr.AllocatedPM && cr.AllocatedPM.length>0) {
                const crProjectManagers = cr.AllocatedPM;

                //find valid pms from end date
                let crValidManagers = [];
                crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

                if (crValidManagers.length == 0 && crProjectManagers.length > 0){
                  //crValidManagers.push(crProjectManagers[0]);
                    crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
                    const lastAssignedManager = crProjectManagers[0];
                    crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

                }
                for(const allocatedPM of crValidManagers){
                  if (allocatedPM.IsPresales && (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) && cr.IsActive === true) {
                    changeRequestsWithExistingPMs.push(cr);
                  }else if (!allocatedPM.IsPresales && (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase())) {
                    changeRequestsWithNewlyAllocatedPMs.push(cr);
                  }
                }
              }
                /*if (cr && cr.ResourceRequestStatus && cr.ResourceRequestStatus === 'PM_ALLOCATED') {
                    if (cr.AllocatedPM && cr.AllocatedPM.length>0) {
                        for(const allocatedPM of cr.AllocatedPM){
                            if (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
                                changeRequestsWithNewlyAllocatedPMs.push(cr);
                            }
                        }
                    }
                } else {
                    if (window.LOGGED_USER.userId.toUpperCase() === cr.PresalesResource.Email.toUpperCase() && cr.IsActive === true) {
                        changeRequestsWithExistingPMs.push(cr)
                    }

                }*/
            }
        }

        //to verify that CR has resource request or not
        if(this.props.dataItem.ChangeRequests && this.props.dataItem.ChangeRequests.length > 0){
            const CRs = this.props.dataItem.ChangeRequests.filter(obj => obj.StatusConvertedToActive === null && ((obj.Status.Code === 'WORK_AT_RISK') || (obj.Status.Code === 'BILLABLE')));
            beforeConvertCRs.push(CRs)
        }


        let displayBtn;

        for(let el of changeToStatusPermissions){
          switch(el){
            case 'CHG_TO_DEAL_ON_HOLD' : {
              if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'PRESALES' || obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
                displayBtn = true;
              }
              break;
            }
            case 'CHG_TO_PRESALE' : {
              if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'DEAL_ON_HOLD')).length > 0 )){
                displayBtn = true;
              }
              break;
            }
            case 'CHG_TO_WAITING_CONFIRMATION' : {
              if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status.Code == 'PRESALES')).length > 0 )){
                displayBtn = true;
              }
              break;
            }
            case 'CHG_TO_WORKING_AT_RISK' : {
              if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status.Code == 'PRESALES' || obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
                displayBtn = true;
              }
              break;
            }
            case 'CHG_TO_ACTIVE' : {
              if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'WORK_AT_RISK' || obj.Status.Code == 'PRESALES' || obj.Status.Code == 'WAIT_CONFIRM' ||
                obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status.Code == 'PROJECT_ON_HOLD')).length > 0 )){
                displayBtn = true;
              }
              break;
            }
            case 'CHG_TO_PROJECT_ON_HOLD' : {
              if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'WORK_AT_RISK' || obj.Status.Code == 'BILLABLE')).length > 0 )){
                displayBtn = true;
              }
              break;
            }
            case 'CHG_TO_CLOSE_WITHOUT_BIDDING' : {
              if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status.Code == 'PRESALES')).length > 0 )){
                displayBtn = true;
              }
              break;
            }
            case 'CHG_TO_CLOSE_OPPORTUNITY_LOST' : {
              if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status.Code == 'PRESALES' || obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
                displayBtn = true;
              }
              break;
            }
            case 'CHG_TO_COMPLETE' : {
              if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'BILLABLE')).length > 0 )){
                displayBtn = true;
              }
              break;
            }
            case 'CHG_TO_CLOSE_DISCONTINUE' : {
              if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'BILLABLE' || obj.Status.Code == 'WORK_AT_RISK' || obj.Status.Code == 'PROJECT_ON_HOLD')).length > 0 )){
                displayBtn = true;
              }
              break;
            }
            case 'CHG_TO_CLOSED' : {
              if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'COMPLETE')).length > 0 )){
                displayBtn = true;
              }
              break;
            }
            default : {
            }
          }
        }


        if(viewStatusChangePermission && viewStatusChangePermission.length > 0){
            displayBtn = true;
        }

        var displayStatusButton = {
            display: displayBtn === true ? 'block' : 'none'
        }


        return (
          <td className="action-buttons">
            Active CRs : {project.ChangeRequests ? activeCRs : 0}
            <br />

            {project &&
              !isPoolProject &&
              (projectManager === true ||
                (createCRPermission && createCRPermission.length > 0)) && (
                <Link
                  to={{
                    pathname: '/changeRequest/create',
                    action: 'create',
                    projectId: project.id
                    // statusValue: project.ProjectStatus
                  }}
                >
                  <div className="rounded-link" title="Create a CR">
                    <span className="k-icon k-i-track-changes" />
                  </div>
                </Link>
              )}

              {project &&
              isPoolProject &&
              (projectManager === true ||
                (createCRPermission && createCRPermission.length > 0)) && (
                <Link
                  to={{
                    pathname: '/poolProjectRevision/poolRevision',
                    action: 'create',
                    projectId: project.id
                    // statusValue: project.ProjectStatus
                  }}
                >
                  <div className="rounded-link" title="Create Factory Project Extension">
                    <span className="k-icon k-i-track-changes" />
                  </div>
                </Link>
              )}

            {(projectManager === true ||
              (editCRPermission && editCRPermission.length > 0) ||
              ((changeRequestsWithExistingPMs &&
                changeRequestsWithExistingPMs.length > 0) ||
                (changeRequestsWithNewlyAllocatedPMs &&
                  changeRequestsWithNewlyAllocatedPMs.length > 0))) &&
              changeReqWithoutPendingApprovals.length > 0 && (!isPendingStatus) && editableCR && (
                <a
                  href="#"
                  onClick={event =>
                    this.handleEditCR(
                      event,
                      project,
                      projectManager,
                      editCRPermission,
                      crManagerChangeRequests,
                      changeRequestsWithNewlyAllocatedPMs,
                      changeRequestsWithExistingPMs
                    )
                  }
                >
                  <div className="rounded-link" title="Edit CR">
                    <span className="k-icon k-i-edit" />
                  </div>
                </a>
              )}
            {project.ChangeRequests.length > 0 &&
              ((viewCRPermission && viewCRPermission.length > 0) ||
                ((changeRequestsWithExistingPMs &&
                  changeRequestsWithExistingPMs.length > 0) ||
                  (changeRequestsWithNewlyAllocatedPMs &&
                    changeRequestsWithNewlyAllocatedPMs.length > 0))) && (
                <a href="#" onClick={event => this.handleViewCR(event, project)}>
                  <div className="rounded-link" title="View CR">
                    <span className="k-icon k-i-preview" />
                  </div>
                </a>
              )}
            {((deleteCRPermission && deleteCRPermission.length > 0) ||
              projectManager === true ||
              (changeRequestsWithNewlyAllocatedPMs &&
                changeRequestsWithNewlyAllocatedPMs.length > 0 &&
                (changeRequestsWithExistingPMs &&
                  changeRequestsWithExistingPMs.length > 0))) &&
              deleteChangeReqCRManager > 0 && (
                <a
                  href="#"
                  onClick={event =>
                    this.handleDeleteCR(
                      event,
                      project,
                      projectManager,
                      deleteCRPermission,
                      deleteChangeReqCRManager
                    )
                  }
                >
                  <div className="rounded-link" title="Delete">
                    <span className="k-icon k-i-delete" />
                  </div>
                </a>
              )}

            {(projectManager === true ||
              (crManagerChangeRequests && crManagerChangeRequests.length > 0)) && ((editCRPermission && editCRPermission.length > 0) || (createCRPermission && createCRPermission.length > 0)) && (!isConvertRequest) &&
              (isCRStatusCode) && (!isPendingStatus) && (
                <Link
                  to={this.props}
                  onClick={event =>
                    this.props.OnActivateResourceRequest(
                      event,
                      this.props.dataItem.ChangeRequests.length === 1
                        ? this.props.dataItem.ChangeRequests[0].id
                        : null,
                      multipleCR,
                      'Change_Request',
                        beforeConvertCRs[0],
                      project.ProjectName
                    )
                  }
                >
                  <div className="rounded-link">
                    <span className="k-icon k-i-check" title="Activate" />
                  </div>
                </Link>
              )}

              {project.ChangeRequests.length > 0 && ((viewStatusChangePermission && viewStatusChangePermission.length > 0) || (changeToStatusPermissions && changeToStatusPermissions.length > 0)) && (
                <a href="#" onClick={event => this.handleStatusPopUpCR(event, project)}>
                  <div className="rounded-link" title="Status Change - CR" style={displayStatusButton}>
                    <span className="k-icon k-i-wrench" />
                  </div>
                </a>
              )}
          </td>
        );
    }

}

export default ChangeRequestFunctions;
