import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class CompletionDateCell extends Component {
    render() {
        const value = this.props.dataItem.createdAt;

        if (this.props.dataItem.Type === 'STATUS_CHANGE_APPROVAL') {
            return <td>-</td>;
        }
        else if (this.props.dataItem.Type === 'TIME_ENTRY_WEEKLY' || this.props.dataItem.Type === 'TIME_APPROVAL_WEEKLY') {
            return <td> <Moment format="MM-DD-YYYY">{value}</Moment></td>;
        }
        else if (this.props.dataItem.Type === 'TIME_ENTRY_UNFREEZE_APPROVAL') {
            return <td> <Moment format="MM-DD-YYYY">{this.props.dataItem.updatedAt}</Moment></td>;
        }
        else if (this.props.dataItem.Type === 'RESOURCE_REQUEST_PENDING_APPROVAL') {
            return <td> <Moment add={{ days: 3 }} format="MM-DD-YYYY">{this.props.dataItem.updatedAtOrderBy}</Moment></td>;
        }
        
        else {
            return <td><Moment add={{ days: 3 }} format="MM-DD-YYYY">
                {value}
            </Moment></td>;
        }

    }
}

export default CompletionDateCell;
