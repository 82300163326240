import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import {
  Input,
} from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
  Button,
} from '@progress/kendo-react-buttons';
import {
  getSalesPersonByName,
  getPartnerByName,
  updateSalesPersonStatus,
  updatePartnerStatus,
  getSalesPerson,
  getProjectPartners,
} from './CommissionService';
import CreatedDateCell from './components/CreatedDateCell';
import UpdatedDateCell from './components/UpdatedDateCell';
import StatusCell from './components/StatusCell';
import SearchAction from './components/CommissionActionCell';

import { Link } from 'react-router-dom';
import { orderBy } from '@progress/kendo-data-query';
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../../src/integration/LoggerService";
import { LOG_TYPES } from "../../../src/integration/IntegrationEnums";

class Commissions extends Component {

  isMount = false;

  componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    if (this.props.location.CriteriaStatus === 'partner') {
      this.populatePartnerByText('%25', this.state.SearchRequest.searchStatus.Name);
      if (this.isMount) {
        this.setState({
          SearchType: 'Partner : ',
          criteriaStatus: 'partner'
        });
      }
    } else {
      this.populateSalesPersonByText('%25', this.state.SearchRequest.searchStatus.Name);
      if (this.isMount) {
        this.setState({
          SearchType: 'Sales Person : ',
          criteriaStatus: 'SalesPerson'
        });
      }
    }
    this.setHeaderTitle();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Commission');
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      Commissions: [],
      skip: 0,
      take: 20,
      SearchRequest: {
        Criteria: '',
        searchStatus: { Name: 'Active', status: true }
      },
      IsActive: '',
      SearchType: '',
      criteriaStatus: '',
      deActivateCommissionId: 0,
      deactivePopupVisible: 0,
      status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
      deactivatePopupVisible: false,
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      loading: false

    };
  }

  // Method to get all salespeople
  populateSalesPerson = () => {
    getSalesPerson().then(response => {
      if (this.isMount) {
        this.setState({
          Commissions: response.data
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };
  // Method to get salespeople based on name
  populateSalesPersonByText = (salesPersonName, IsActive) => {
    getSalesPersonByName(salesPersonName, IsActive).then(response => {
      if (this.isMount) {
        this.setState({ Commissions: response.data });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };
  // Method to get all partners
  populatePartner = () => {
    getProjectPartners().then(response => {
      if (this.isMount) {
        this.setState({
          Commissions: response.data
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };
  // Method to get partner based on name
  populatePartnerByText = (partnerName, IsActive) => {
    getPartnerByName(partnerName, IsActive).then(response => {
      if (this.isMount) {
        this.setState({
          Commissions: response.data
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  handleCriteriaChange = async event => {
    const value = event.target.value;

    await this.setState({
      criteriaStatus: value,
    });

    if (this.state.criteriaStatus === 'SalesPerson') {
      window.scrollTo(0, 0);
      this.populateSalesPersonByText('%25', '%25');
      if (this.isMount) {
        this.setState({
          SearchType: 'Sales Person : ',
          isFormDirty: true
        });
      }
    } else {
      window.scrollTo(0, 0);
      this.populatePartnerByText('%25', '%25');
      if (this.isMount) {
        this.setState({
          SearchType: 'Partner : ',
          isFormDirty: true
        });
      }
    }
  };

  render() {
    return (
      <div>
        <div className="main-card">
          <div className="row">
            <div className="col-md-8">
              <div className="main-heading">Commission Search</div>
            </div>

            <div className="col-md-4">
              {this.state.criteriaStatus === 'SalesPerson' ? (
                <Button primary className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('EDT_COMM') > -1)}>
                  <Link
                    style={{ textDecoration: 'none', color: 'white' }}
                    to={{
                      pathname: '/admin/createsalesperson/createsalesperson',
                      action: 'create'
                    }}
                  >
                    Create New Sales Person
                  </Link>
                </Button>
              ) : (
                <Button primary className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('EDT_COMM') > -1)}>
                    <Link
                      style={{ textDecoration: 'none', color: 'white' }}
                      to={{
                        pathname: '/admin/createpartner/createpartner',
                        action: 'create'
                      }}
                    >
                      Create New Partner
                  </Link>
                  </Button>
                )}
            </div>
          </div>

          <div className="row">

            <div className="col-md-2">
              <div className="d-block">
                  <label>{this.state.SearchType}</label>
              </div>
              <div className="d-block">
                <div className="">
                  <Input
                    value={this.state.SearchRequest.Criteria}
                    onChange={this.handleOnChangeInput}
                    name="Criteria"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>

            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Status:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    name="searchStatus"
                    textField="Name"
                    dataItemKey="status"
                    value={this.state.SearchRequest.searchStatus}
                    data={this.state.status}
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput} />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="d-block">
                <div className="k-form modified">
                  <div className="k-form-field">
                    <input
                      type="radio"
                      name="partner"
                      value="partner"
                      className="k-radio"
                      id="partner"
                      onChange={this.handleCriteriaChange}
                      checked={this.state.criteriaStatus === 'partner'}
                    />
                    <label className="k-radio-label mr-3" htmlFor="partner">
                      Partners
                  </label>

                  <input
                    type="radio"
                    name="SalesPerson"
                    value="SalesPerson"
                    className="k-radio ml-4"
                    id="SalesPerson"
                    onChange={this.handleCriteriaChange}
                    checked={this.state.criteriaStatus === 'SalesPerson'}
                  />
                  <label className="k-radio-label" htmlFor="SalesPerson">
                    Sales Team
                  </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary type="submit" onClick={this.submitSearch}>Search</Button>
              <Button type="submit" onClick={this.submitClear}>Clear</Button>
            </div>
          </div>



          <div className="main-seperator" />

          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">Search Results</div>
            </div>
          </div>

          <div className="row mt-0">
            <div className="col-md-12">
              <Grid
                data={orderBy(this.state.Commissions, this.state.sort).slice(
                  this.state.skip,
                  this.state.take + this.state.skip
                )}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.Commissions.length}
                pageable
                onPageChange={this.pageChange}
                resizable
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }
                }
                className="search-result-grid"
              >
                <Column field="id" title="ID" width="50px" />
                <Column field="Name" title="Name" />
                <Column field="CommissionPercentage" title="Commission %" />
                <Column
                  field="createdAt"
                  title="Created Date"
                  cell={props => <CreatedDateCell {...props} />}
                />
                <Column
                  field="updatedAt"
                  title="Last Modified Date"
                  width="120px"
                  cell={props => <UpdatedDateCell {...props} />}
                />
                <Column field="updatedUser" title="Last Modified User"  width="120px" />
                <Column
                  field="IsActive"
                  title="Status"
                  cell={props => <StatusCell {...props} />}
                  width="100px"
                />
                <Column
                  title="Action"
                  width="150px"
                  cell={props => (
                    <SearchAction
                      {...props}
                      OnDeactivate={this.OnDeactivate}
                      OnActivate={this.OnActivate}
                      criteriaStatus={this.state.criteriaStatus}
                    />
                  )}
                />
              </Grid>
            </div>
          </div>
          <div>
            {this.state.deactivatePopupVisible && (
              <Dialog
                title={'Please Confirm'}
                onClose={this.deactivateConfirmationClose}
              >
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to Deactivate this?
                </p>
                <DialogActionsBar>
                  <button
                    className="k-button"
                    onClick={this.deactivateConfirmationClose}
                  >
                    No
                  </button>
                  <button
                    className="k-button modal-primary"
                    onClick={this.deactivateConfirmationOk}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.activatePopupVisible && (
              <Dialog
                title={'Please Confirm'}
                onClose={this.activateConfirmationClose}
              >
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to Activate this?
                </p>
                <DialogActionsBar>
                  <button
                    className="k-button"
                    onClick={this.activateConfirmationClose}
                  >
                    No
                  </button>
                  <button
                    className="k-button modal-primary"
                    onClick={this.activateConfirmationOk}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success" />
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error" />
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <Loader loading={this.state.loading} />
        </div>
      </div>
    );
  }

  handleOnChangeInput = event => {
    const field = event.target.name;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    if (this.isMount) {
      this.setState(prevState => {
        return {
          SearchRequest: { ...prevState.SearchRequest, [field]: value }
        };
      });
    }
  };

  OnDeactivate = async (event, id) => {
    event.preventDefault();
    if (this.isMount) {
      await this.setState({
        deActivateCommissionId: id
      });
    }
    this.deactivateConfirmationClose();
  };

  deactivateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: !this.state.deactivatePopupVisible
      });
    }
  };

  deactivateConfirmationOk = async () => {
    if (this.state.criteriaStatus === 'SalesPerson') {
      await updateSalesPersonStatus(
        { IsActive: false },
        this.state.deActivateCommissionId
      )
        .then(response => {
          if (this.isMount) {
            this.setState({
              showSuccessImage: true,
              showErrorImage: false
            });
          }
          this.toggleDialog(
            'The sales person has been Deactivated successfully',
            'Success'
          );
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      await this.populateSalesPersonByText('%25', '%25');
      if (this.isMount) {
        await this.setState({
          deactivatePopupVisible: false
        });
      }
    } else {
      await updatePartnerStatus(
        { IsActive: false },
        this.state.deActivateCommissionId
      )
        .then(response => {
          if (this.isMount) {
            this.setState({
              showSuccessImage: true,
              showErrorImage: false
            });
          }
          this.toggleDialog(
            'The partner has been Deactivated successfully',
            'Success'
          );
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      await this.populatePartnerByText('%25', '%25');
      if (this.isMount) {
        await this.setState({
          deactivatePopupVisible: false
        });
      }
    }
  };

  OnActivate = async (event, id) => {
    event.preventDefault();
    if (this.isMount) {
      await this.setState({
        activateDesignationId: id
      });
    }
    this.activateConfirmationClose();
  };

  activateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        activatePopupVisible: !this.state.activatePopupVisible
      });
    }
  };

  activateConfirmationOk = async () => {
    if (this.state.criteriaStatus === 'SalesPerson') {
      await updateSalesPersonStatus(
        { IsActive: true },
        this.state.activateDesignationId
      )
        .then(response => {
          if (this.isMount) {
            this.setState({
              showSuccessImage: true,
              showErrorImage: false
            });
          }
          this.toggleDialog(
            'The Sales person has been Activated successfully',
            'Success'
          );
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      await this.populateSalesPersonByText('%25', '%25');
      if (this.isMount) {
        await this.setState({
          activatePopupVisible: false
        });
      }
    } else {
      await updatePartnerStatus(
        { IsActive: true },
        this.state.activateDesignationId
      )
        .then(response => {
          if (this.isMount) {
            this.setState({
              showSuccessImage: true,
              showErrorImage: false
            });
          }
          this.toggleDialog(
            'The partner has been Activated successfully',
            'Success'
          );
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      await this.populatePartnerByText('%25', '%25');
      if (this.isMount) {
        await this.setState({
          activatePopupVisible: false
        });
      }
    }
  };

  submitClear = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        SearchRequest: {
          Criteria: '',
          searchStatus: null
        }
      });
    }
    {
      this.state.criteriaStatus === 'SalesPerson'
        ? this.populateSalesPersonByText('%25', '%25')
        : this.populatePartnerByText('%25', '%25');
    }
  };

  submitSearch = event => {
    event.preventDefault();

    this.setState({
      loading: true
    });
    if (this.isMount) {
      this.setState(
        prevState => {
          const SRequest = {
            ...prevState.SearchRequest
          };
          return {
            SearchRequest: SRequest
          };
        },
        () => {
          if (this.state.criteriaStatus === 'SalesPerson') {
            if (this.state.SearchRequest) {
              this.populateSalesPersonByText(this.state.SearchRequest.Criteria === '' ? '%25' : this.state.SearchRequest.Criteria, this.state.SearchRequest.searchStatus ? this.state.SearchRequest.searchStatus.Name : '%25');
              this.state.loading = false;
            } else {
              this.populateSalesPersonByText('25%', '%25');
              this.state.loading = false;
            }
          } else {
            if (this.state.SearchRequest) {
              this.populatePartnerByText(this.state.SearchRequest.Criteria === '' ? '%25' : this.state.SearchRequest.Criteria, this.state.SearchRequest.searchStatus ? this.state.SearchRequest.searchStatus.Name : '%25');
              this.state.loading = false;
            } else {
              this.populatePartnerByText('25%', '%25');
              this.state.loading = false;
            }
          }
          this.state.skip = 0;
        });
    }
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };
}

export default Commissions;
