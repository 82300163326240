import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {getFormattedDate} from "../../integration/CommonUtils";

class ActionsCell extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleWithdrawInvoiceRequest = (event, invoiceRequest) => {
        event.preventDefault();
        if (invoiceRequest) {
          this.props.handleWithdrawInvoiceRequest(invoiceRequest);
        }
    };

    handleReviseInvoiceRequest = (event, invoiceRequest) => {
        event.preventDefault();
        if (invoiceRequest) {
          this.props.handleReviseInvoiceRequest(invoiceRequest);
        }
    };

    handleDownloadInvoiceRequest = (event, invoiceRequest) => {
        event.preventDefault();
        if (invoiceRequest) {
          this.props.handleDownloadInvoiceRequest(invoiceRequest);
        }
    };

    render() {

        let allocatedProjectManager = false;

        const projectProjectManagers = this.props.dataItem.CrId ? this.props.dataItem.ChangeRequest.AllocatedPM : this.props.dataItem.Project.AllocatedResourceRequestPM;
        //find valid pms from end date
        if (projectProjectManagers && projectProjectManagers.length > 0) {
            let projValidManagers = [];
            projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );
    
            if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
              projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
              const lastAssignedManager = projectProjectManagers[0];
              projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
            }
    
            projValidManagers.forEach(value => {
              if(!value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
                allocatedProjectManager = true;
              }
            });    
        }

        return (
            <td className="action-buttons">
                {this.props.dataItem.IsActive === true && (
                    <Link
                        to={{
                            pathname: '/projects/invoice/create',
                            action: 'view',
                            title: 'View Invoice Request',
                            dataItem: this.props.dataItem
                            }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-preview" title="View" />
                        </div>
                    </Link>
                )}
                {this.props.dataItem.IsActive === true && allocatedProjectManager && (this.props.dataItem.Status === 1 || this.props.dataItem.Status === 4  || this.props.dataItem.Status === 5) && (
                    <Link
                        to={{
                            pathname: '/projects/invoice/create',
                            action: 'edit',
                            title: 'Edit Invoice Request',
                            dataItem: this.props.dataItem
                            }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-edit" title="Edit" />
                        </div>
                    </Link>
                )}

                {this.props.dataItem.IsActive  === true  && allocatedProjectManager && this.props.dataItem.Status === 2 && (
                    <a
                        href="#"
                        onClick={event => this.handleWithdrawInvoiceRequest(event, this.props.dataItem)}
                    >
                        <div className="rounded-link" title="Withdraw Invoice Request">
                            <span className="k-icon k-i-arrow-rotate-ccw" title="Withdraw Invoice Request" />
                        </div>
                    </a>
                )}

                {this.props.dataItem.IsActive  === true  && allocatedProjectManager && this.props.dataItem.Status === 3 && (
                    <a
                        href="#"
                        onClick={event => this.handleReviseInvoiceRequest(event, this.props.dataItem)}
                    >
                        <div className="rounded-link" title="Revise Invoice Request">
                            <span className="k-icon k-i-categorize" title="Revise Invoice Request" />
                        </div>
                    </a>
                )}

                {this.props.dataItem.IsActive  === true && (
                    <a
                        href="#"
                        onClick={event => this.handleDownloadInvoiceRequest(event, this.props.dataItem)}
                    >   
                        <div className="rounded-link">
                            <span className="k-icon k-i-download" title="Download Invoice Request" />
                        </div>
                    </a>
                )}

                {allocatedProjectManager && (this.props.dataItem.StatusName === 'Pending') && (
                    <Link
                        to={{
                            pathname: '/projects/invoice/create',
                            action: 'pendingIR',
                            title: 'Create Invoice Request',
                            dataItem: this.props.dataItem
                            }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-track-changes" title="Create Invoice Request" />
                        </div>
                    </Link>
                )}

                {allocatedProjectManager && (this.props.dataItem.StatusName === 'Pending') && (
                    <Link
                        to={{
                            pathname: '/timesheets/timesheetapproval',
                            action: 'approve',
                            }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-calendar" title="Time Approval" />
                        </div>
                    </Link>
                )}
                    
            </td>
        );
    }
}

export default ActionsCell;
