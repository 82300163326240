import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs/dist/npm/main';

class CMSDeleteDialog extends Component {

    render() {
        return (

            <Dialog title={"Select the relevant CMS to delete"} onClose={this.props.toggleCMSSelectDialog} width="400px">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="mb-0">Project CMS</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="k-form-field">
                                    {this.props.projectCMS.map((option, i) => {
                                        return (
                                            <div>
                                                <input
                                                    type="radio"
                                                    id={'p' + option.id}
                                                    name={'' + option.id}
                                                    value={option.id}
                                                    checked={this.props.cmSheetIdToDelete === option.id}
                                                    className="k-radio"
                                                    key={'P'+option.id}
                                                    onChange={this.props.setCMSIdtoDelete.bind(this, option.id, option)}
                                                />
                                                <label
                                                    key={option.id}
                                                    htmlFor={'p' + option.id}
                                                    className="k-radio-label"
                                                >
                                                    {option.name} - {option.type} CMS
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        { this.props.projectCMS.length>0 ? '' : (<label> NO Project CMS found</label>)}
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="mb-0">CR CMS</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <form className="k-form modified">
                                    <div className="k-form-field">
                                        {this.props.changerequestCMS.map((option, i) => {
                                            return (
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id={'p' + option.id}
                                                        name={'' + option.id}
                                                        value={option.id}
                                                        checked={this.props.cmSheetIdToDelete === option.id}
                                                        className="k-radio"
                                                        key={'P'+option.id}
                                                        onChange={this.props.setCMSIdtoDelete.bind(this, option.id, option)}
                                                    />
                                                    <label
                                                        key={option.id}
                                                        htmlFor={'p' + option.id}
                                                        className="k-radio-label"
                                                    >
                                                        {option.name} - {option.type} CMS
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row'">
                    <div className="col-md-12">
                        { this.props.changerequestCMS.length>0 ? '' : (<label> NO CR CMS found</label>)}
                    </div>
                </div>

                <DialogActionsBar>
                    <button className="k-button" onClick={this.props.toggleCMSSelectDialog}>
                        No
                    </button>
                    <button
                        className="k-button modal-primary"
                        onClick={this.props.deleteCMSheet}
                        disabled={!this.props.cmSheetIdToDelete}
                    >
                        Yes
                    </button>
                </DialogActionsBar>
            </Dialog>

        )
    }
}

export default CMSDeleteDialog;
