import http from '../integration/HttpService';
//import {serverUrl} from '../config.json';
const serverUrl = require('../config').get(process.env.REACT_APP_ENV).serverUrl;

/**
 * Gets the current logged in user.
 * @returns Logged in user details with permissions.
 */
export function getLoggedInUser() {
  return http.get(`${serverUrl}/api/userManagement/user/getLoggedInUser`);
}

/**
 * Gets a new JWT authentication token given the Azure AccessToken
 * @returns new JWT Token
 */
export function getToken() {
  return http.get(`${serverUrl}/api/auth/getToken`);
}

/**
 * Gets a refreshed JWT token given the current token.
 * @returns JWT Token.
 */
export function refreshToken() {
  return http.get(`${serverUrl}/api/auth/refreshToken`);
}
