import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Get Consolidated CMS Data.
 * @param idArray
 * @returns {array} Get the Consolidated CMS Details
 */
export function getConsolidatedCMSData(idArray) {
    const endPoint = apiUrl + '/projectManagement/consolidatedCMS/getByIdAndVersion';
    return http.post(endPoint, idArray);
}

/**
 * Get Resource Mix details of Consolidated CMS.
 * @param idArray
 * @returns {array} Get the Resource Mix Consolidated CMS Details
 */
export function getConsolidatedResourceMixData(idArray) {
    const endPoint = apiUrl + '/projectManagement/consolidatedCMS/getByIdAndVersionResource';
    return http.post(endPoint, idArray);
}

/**
 * Get Project(s) and Cr(s) Statistics Data.
 * @param idArray
 * @returns {array} Get Project(s) and Cr(s) Statistics Details
 */
export function getProjectAndCrStatistics(idArray) {
    const endPoint = apiUrl + '/projectManagement/consolidatedCMS/getProjectAndCrStatistics';
    return http.post(endPoint, idArray);
}