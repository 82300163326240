import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class EditExchangeRateActionsCell extends Component {
  render() {
    const exchangerate = this.props.dataItem;
    return (
      <td className="action-buttons">
       {exchangerate.IsActive === true &&(window.USER_PERMISSION.indexOf('EDT_EXG_RT') > -1) &&(
        <Link
          to={{
            pathname: '/admin/createExchangeRate/createExchangeRate',
            exchangerate: exchangerate,
            action: 'edit'
          }}
        >
          <div className="rounded-link">
            <span className="k-icon k-i-edit" title="Edit" />
          </div>
        </Link>
        )}
      </td>
    );
  }
}
export default EditExchangeRateActionsCell;
