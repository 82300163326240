/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns/dist/npm/main';
import { Tooltip } from 'reactstrap';
import { Input } from '@progress/kendo-react-inputs/dist/npm/main';

class BillingDetails extends Component {
    popupSet = {
        width: 'auto'
    };

    constructor(props) {
        super(props);
        this.state = {
            isViewMode: false,
            salespersToolTipOpen: false,
            projpartnerToolTipOpen: false,
            billingDivToolTipOpen: false,
            billRegionToolTipOpen: false,
            countryToolTipOpen: false,
            practiceToolTipOpen: false
        };

        const labels = this.props.labels;
        this.partnerLabel = labels && labels.partnerLabel ? labels.partnerLabel : 'Project Partner';
        this.countryLabel = labels && labels.countryLabel ? labels.countryLabel : 'Country';
    }

    componentDidUpdate(prevProps) {
        if (this.props.action && this.props.action !== prevProps.action) {
            this.setState({
                isViewMode: this.props.action === 'view' ? true : false
            });
        }

        if (this.props !== prevProps) {
            this.setState({
                editable: this.props.editable
            });

        }
    }

    handleChange = (event) => {
        this.props.handleBillableOnChange(event);
    };

    handleFilterChange = event => {
        this.props.handleBillableFilterChange(event);
    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    toggleSalesPersonTooltip = () => {
        this.setState({
            salespersToolTipOpen: !this.state.salespersToolTipOpen
        });
    };

    togglePartnerTooltip = () => {
        this.setState({
            projpartnerToolTipOpen: !this.state.projpartnerToolTipOpen
        });
    };

    toggleBillingDivToolTip = () => {
        this.setState({
            billingDivToolTipOpen: !this.state.billingDivToolTipOpen
        });
    };

    togglePracticeToolTip = () => {
        this.setState({
            practiceToolTipOpen: !this.state.practiceToolTipOpen
        });
    };

    toggleBillRegionToolTip = () => {
        this.setState({
            billRegionToolTipOpen: !this.state.billRegionToolTipOpen
        });
    };

    toggleCountryToolTip = () => {
        this.setState({
            countryToolTipOpen: !this.state.countryToolTipOpen
        });
    };

    render() {

        return (
            <div>
                <div className="main-heading">Billing Details</div>
                <div className="row">
                    <div className="col-md-4">
                        <form className="k-form modified">
                            <div className="k-form-field">
                                <input
                                    type="radio"
                                    name="billale"
                                    value="billable"
                                    className="k-radio"
                                    id="billable"
                                    onChange={this.handleChange}
                                    checked={this.props.billableStatus}
                                    disabled={this.props.viewMode || !this.props.permissions.billableStatus}
                                />
                                <label className="k-radio-label mr-3" htmlFor="billable">
                                    Billable
                                </label>

                                <input
                                    type="radio"
                                    name="billale"
                                    value="nonbillable"
                                    className="k-radio"
                                    id="nonbillable"
                                    onChange={this.handleChange}
                                    checked={!this.props.billableStatus}
                                    disabled={this.props.viewMode || !this.props.permissions.billableStatus}
                                />
                                <label className="k-radio-label" htmlFor="nonbillable">
                                    Non-Billable
                                </label>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2">
                        <div className="d-block">
                            <label htmlFor="">Sales Person:</label>
                        </div>
                        <div className="d-block">
                            <div className="" id="salespersToolTip">
                                <ComboBox
                                    data={this.props.salesPersonItems}
                                    textField="Name"
                                    dataItemKey="id"
                                    name="salesPerson"
                                    value={this.props.salesPersonValue}
                                    placeholder="Please Select"
                                    onChange={this.handleChange}
                                    
                                    disabled={this.props.viewMode || !this.props.permissions.salesPerson || this.props.editable === false}
                                    filterable={true}
                                    onFilterChange={this.handleFilterChange}
                                />
                            </div>
                            {this.props.salesPersonValue ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.salespersToolTipOpen}
                                    target="salespersToolTip"
                                    toggle={this.toggleSalesPersonTooltip}
                                >
                                    {this.props.salesPersonValue.Name}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="d-block">
                            <label htmlFor="">{this.partnerLabel}:</label>
                        </div>
                        <div className="d-block">
                            <div className="" id="projpartnerToolTip">
                                <ComboBox
                                    data={this.props.projParnterItems}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    value={this.props.projPartnerValue}
                                    name="partner"
                                    onChange={this.handleChange}
                                    disabled={this.props.viewMode || !this.props.permissions.projectPartner || this.props.editable === false}
                                    filterable={true}
                                    onFilterChange={this.handleFilterChange}
                                />
                            </div>
                            {this.props.projPartnerValue ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.projpartnerToolTipOpen}
                                    target="projpartnerToolTip"
                                    toggle={this.togglePartnerTooltip}
                                >
                                    {this.props.projPartnerValue.Name}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="d-block">
                            <label htmlFor="" className="mandatory">
                                Department:
                                </label>
                        </div>
                        <div className="d-block">
                            <div className="" id="billingDivToolTip">
                                <ComboBox
                                    data={this.props.billingDiviItems}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    value={this.props.billingDiviValue}
                                    name="billingdivi"
                                    onChange={this.handleChange}
                                    required={true}
                                    disabled={this.props.viewMode || this.props.action === 'edit'}
                                    filterable={true}
                                    onFilterChange={this.handleFilterChange}
                                />
                                {this.props.errorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.props.billingDiviValue)}>
                                        Please select a Department
                                    </span>
                                ) : null}
                            </div>
                            {this.props.billingDiviValue ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.billingDivToolTipOpen}
                                    target="billingDivToolTip"
                                    toggle={this.toggleBillingDivToolTip}
                                >
                                    {this.props.billingDiviValue.Name}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="d-block">
                            <label htmlFor="">
                                Practice:
                            </label>
                        </div>
                        <div className="d-block">
                            <div className="" id="practiceToolTip">
                                <ComboBox
                                    data={this.props.practiceItems}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    value={this.props.practiceValue}
                                    name="selectedPractice"
                                    onChange={this.handleChange}
                                    disabled={this.props.viewMode || this.props.action === 'edit'}
                                    filterable={true}
                                    onFilterChange={this.handleFilterChange}
                                />
                            </div>
                            {this.props.practiceValue ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.practiceToolTipOpen}
                                    target="practiceToolTip"
                                    toggle={this.togglePracticeToolTip}
                                >
                                    {this.props.practiceValue.Name}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="d-block">
                            <label htmlFor="" className="mandatory">
                                Billing Region:
                                </label>
                        </div>
                        <div className="d-block">
                            <div className="" id="billregionToolTip">
                                <ComboBox
                                    data={this.props.billingRegionItems}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    value={this.props.billingRegionValue}
                                    name="billingregion"
                                    onChange={this.handleChange}
                                    required={true}
                                    disabled={this.props.viewMode || !this.props.permissions.billingRegion || this.props.editable === false}
                                    filterable={true}
                                    onFilterChange={this.handleFilterChange}
                                />
                                {this.props.errorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.props.billingRegionValue)}>
                                        Please select a billing region
                                    </span>
                                ) : null}
                            </div>
                            {this.props.billingRegionValue ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.billRegionToolTipOpen}
                                    target="billregionToolTip"
                                    toggle={this.toggleBillRegionToolTip}
                                >
                                    {this.props.billingRegionValue.Name}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="d-block">
                            <label htmlFor="" className="mandatory">
                                {this.countryLabel}:
                                </label>
                        </div>
                        <div className="d-block">
                            <div className="" id="countrytooltip">
                                <ComboBox
                                    data={this.props.countryItems}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    onChange={this.handleChange}
                                    value={this.props.countryValue}
                                    name="country"
                                    required={true}
                                    disabled={this.props.viewMode || !this.props.permissions.country || this.props.editable === false}
                                    filterable={true}
                                    onFilterChange={this.handleFilterChange}
                                />
                                {this.props.errorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.props.countryValue)}>
                                        Please select a country
                                    </span>
                                ) : null}
                            </div>
                            {this.props.countryValue ? (
                                <Tooltip
                                    placement="top"
                                    isOpen={this.state.countryToolTipOpen}
                                    target="countrytooltip"
                                    toggle={this.toggleCountryToolTip}
                                >
                                    {this.props.countryValue.Name}
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BillingDetails;
