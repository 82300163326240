import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Get all customers for drop downs.
 * @returns {array} array list of all customers
 */
export function getcustomers() {
  const endPoint = apiUrl + '/masterData/customers/getall';
  return http.get(endPoint);
}

/**
 * Search all customers by given params.
 * @returns {array} array list of customers
 */
export function getCustomersForGrid() {
  const endPoint = apiUrl + '/masterData/customers/getAllForGrid';
  return http.get(endPoint);
}

export function searchCustomers(params) {
  const endPoint = apiUrl + '/masterData/customers/search';
  return http.post(endPoint, params);
}

/**
 * Save customers.
 * @param params.
 * @returns {array} array list of customers details
 */
export function saveCustomer(params) {
  const endPoint = apiUrl + '/masterData/customers/save';
  return http.post(endPoint, params);
}

/**
 * Update customers status.
 * @param IsActive.
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function updateCustomerStatus(IsActive, id) {
  const endPoint = apiUrl + '/masterData/customers/updateStatus/' + id;
  return http.put(endPoint, IsActive);
}

/**
 * Get customer Programs by customerId.
 * @param customerId.
 * @returns {array} array list of customer Programs details
 */
export function getCustomerPrograms(customerId) {
  const endPoint = apiUrl + '/masterData/customers/' + customerId + '/programs';
  return http.get(endPoint);
}