import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PendingTaskActionsCell extends Component {
  render() {

      const pendingTasks = this.props.dataItem;
      let reference = null;
      let cmSheetApprove = [];
      if (pendingTasks.Type === 'CMS_PENDING_APPROVAL' || pendingTasks.Type === 'REVISE_CMS_PENDING_APPROVAL' || pendingTasks.Type === 'FACTORY_POOL_PLANNING_SHEET_APPROVAL') {
          if(pendingTasks.CMSheet && pendingTasks.CMSheet.Type === 'Project'){
              reference = 'projectCMS';
              cmSheetApprove = this.props.dataItem.CMSheet && this.props.dataItem.CMSheet.CMSheets ? this.props.dataItem.CMSheet.CMSheets[0] : this.props.dataItem.CMSheet? this.props.dataItem.CMSheet: null;           
          }else if (pendingTasks.CMSheet && pendingTasks.CMSheet.Type === 'ChangeRequest'){
              reference = 'crCMS';
              cmSheetApprove = this.props.dataItem.CMSheet && this.props.dataItem.CMSheet.ChangeRequest &&  this.props.dataItem.CMSheet.ChangeRequest.CMSheets? this.props.dataItem.CMSheet.ChangeRequest.CMSheets[0] :  null;           
          }
      }
      else
      if (pendingTasks.Type === 'FACTORY_POOL_PLANNING_SHEET_APPROVAL') {
          if(pendingTasks.CMSheet.Type === 'Project'){
              reference = 'projectCMS';
          }else {
              reference = 'crCMS';
          }
      }
      // if (pendingTasks.Type === 'STATUS_CHANGE_APPROVAL') {
      //     if(pendingTasks.CMSheet.Type === 'Project'){
      //         reference = 'projectCMS';
      //     }else {
      //         reference = 'crCMS';
      //     }
      // }
      else if (pendingTasks.Type === 'RESOURCE_REQUEST_PENDING_APPROVAL') {

      }
      const project = this.props.dataItem.CMSheet ? this.props.dataItem.CMSheet.Project : null;

    return (
      <td className="action-buttons">
          { pendingTasks.Type === 'CMS_PENDING_APPROVAL' &&(
        <Link
          to={{
            pathname: '/projects/cmsheet/cmsheet',
            projectId: project ? project.id : 0,
            changeReqId: this.props.dataItem.CMSheet.ChangeRequest ? this.props.dataItem.CMSheet.ChangeRequest.id : 0,
            cmSheet: cmSheetApprove,
            action: 'approve',
            reference: reference
          }}
        >
          <div className="rounded-link">
            <span className="k-icon k-i-track-changes-accept" title="Approve" />
          </div>
        </Link>
          )}

        { pendingTasks.Type === 'REVISE_CMS_PENDING_APPROVAL' &&(
        <Link
          to={{
            pathname: '/projects/cmsheet/cmsheet',
            projectId: project ? project.id : 0,
            changeReqId: this.props.dataItem.CMSheet.ChangeRequest ? this.props.dataItem.CMSheet.ChangeRequest.id : 0,
            cmSheet: cmSheetApprove,
            action: 'reviseCMSApprove',
            reference: reference
          }}
        >
          <div className="rounded-link">
            <span className="k-icon k-i-track-changes-accept" title="Approve" />
          </div>
        </Link>
          )}

          { pendingTasks.Type === 'STATUS_CHANGE_APPROVAL' &&(
              <Link
                  to={{
                      pathname: '/admin/ApproveChangeStatus/ApproveChangeStatus',
                      projectId: project ? project.id : 0,
                      //changeReqId: this.props.dataItem.CMSheet.ChangeRequest ? this.props.dataItem.CMSheet.ChangeRequest.id : 0,
                      //cmSheet: this.props.dataItem.CMSheet,
                      action: 'approve',
                      //reference: reference
                  }}
              >
                  <div className="rounded-link">
                      <span className="k-icon k-i-track-changes-accept" title="Approve" />
                  </div>
              </Link>
          )}

          { pendingTasks.Type === 'FACTORY_POOL_PLANNING_SHEET_APPROVAL' &&(
        <Link
          to={{
            pathname: '/PoolProject/FactoryProjectCMS',
            projectId: project ? project.id : 0,
            changeReqId: this.props.dataItem.CMSheet.ChangeRequest ? this.props.dataItem.CMSheet.ChangeRequest.id : 0,
            cmSheet: this.props.dataItem.CMSheet,
            action: 'approve',
            reference: reference
          }}
        >
          <div className="rounded-link">
            <span className="k-icon k-i-track-changes-accept" title="Approve" />
          </div>
        </Link>
          )}

          { pendingTasks.Type === 'RESOURCE_REQUEST_PENDING_APPROVAL' &&(
              <Link
                  to={{
                      pathname: '/ProjectResourceRequest/ProjectResourceAllocation',
                      project: pendingTasks.Project,
                      action: 'RPApprove',
                  }}
              >
                  <div className="rounded-link">
                      <span className="k-icon k-i-track-changes-accept" title="Approve" />
                  </div>
              </Link>
          )}

          { pendingTasks.Type === 'RESOURCE_REQUEST_APPROVAL_WORKFLOW' &&(
              <Link
                  to={{
                      pathname: '/ProjectResourceRequest/ProjectResourceRequest',
                      project: pendingTasks.Project,
                      action: 'RPApproveWF',
                  }}
              >
                  <div className="rounded-link">
                      <span className="k-icon k-i-track-changes-accept" title="Approve" />
                  </div>
              </Link>
          )}

        { pendingTasks.Type === 'RESOURCE_ALLOCATION_APPROVAL_WORKFLOW' &&(
          <Link
            to={{
              pathname: '/ProjectResourceRequest/ProjectResourceAllocation',
              project: pendingTasks.Project,
              action: 'RPAllocateWF',
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-track-changes-accept" title="Approve" />
            </div>
          </Link>
        )}

        { (pendingTasks.Type === 'TIMESHEET_UNFREEZE_APPROVAL') &&(
          <Link
            to={{
              pathname: '/timesheets/unfreezeworkflow/unfreezeworkflow',
              action: 'approve',
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-track-changes-accept" title="Approve" />
            </div>
          </Link>
        )}

      { (pendingTasks.Type === 'TIME_ENTRY_WEEKLY') &&(
          <Link
            to={{
              pathname: '/timesheets/timesheetentry',
              action: 'approve',
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-track-changes-accept" title="Approve" />
            </div>
          </Link>
        )}

    { (pendingTasks.Type === 'TIME_APPROVAL_WEEKLY' || pendingTasks.Type === 'TIME_ENTRY_UNFREEZE_APPROVAL') &&(
          <Link
            to={{
              pathname: '/timesheets/timesheetapproval',
              action: 'approve',
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-track-changes-accept" title="Approve" />
            </div>
          </Link>
        )}

      { (pendingTasks.Type === 'INVOICE_REQUEST_PENDING_APPROVAL') &&(
          <Link
            to={{
              pathname: '/projects/invoice/irfview',
              action: 'approve',
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-track-changes-accept" title="Approve" />
            </div>
          </Link>
        )}

      </td>
    );
  }
}

export default PendingTaskActionsCell;
