import React, { Component } from 'react';
import {getFormattedDate} from "../../../integration/CommonUtils";

class CMSheetCell extends Component {

  handleViewCMS = (event , project, projectCMS, crCMS) =>{
    event.preventDefault();
    this.props.onSelectCMS(project, 'view', projectCMS, crCMS);
  };

  hanldeEditCMS = (event, project, projectCMS, crCMS) =>{
    event.preventDefault();
    this.props.onSelectCMS(project, 'edit', projectCMS, crCMS);
  }

  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let programeName = '';
    let customerName = '';
    let regionName = '';
    let divisionName = '';
    let PMName = '';
    let projectStatus = '';
    let status = 'PENDING';
    const closeSting = 'close';
    const project = this.props.dataItem;
    if (this.props.dataItem.Program) {
      programeName = this.props.dataItem.Program.Name;
    }
    if (this.props.dataItem.Customer) {
      customerName = this.props.dataItem.Customer.Name;
    }
    if (this.props.dataItem.Region) {
      regionName = this.props.dataItem.Region.Name;
    }
    if (this.props.dataItem.Division) {
      divisionName = this.props.dataItem.Division.Name;
    }
    if (this.props.dataItem.PresalesResource) {
      PMName = this.props.dataItem.PresalesResource.Name;
    }
    if (this.props.dataItem.ProjectStatus) {
      projectStatus = this.props.dataItem.ProjectStatus.StatusName;
    }
    if (project.CMSheets.length > 0) {
    }
    if (project.ProjectStatus) {
      let projectName = project.ProjectStatus.StatusName;
      projectName
          .toString()
          .toLowerCase()
          .includes(closeSting);
    }

    let viewCMSPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_CMS');

    let cmsAvailable = false;
    let changeReqList = [];

    // Edit CMS (Project and CR both) Logic
    let editProjectCMS = [];
    let editCrCMS = [];

    // View CMS ( Project and CR both ) Logic
    let viewProjectCMS = [];
    let viewCrCMS = [];

    // Revise CMS ( Project and CR both ) Logic
    let reviseProjectCMS = [];
    let reviseCrCMS = [];

    let pmAccess = false;
    let crAccess = false;

    project.ChangeRequests.forEach(function (CR) {

      //valid managers
      const crProjectManagers = CR.AllocatedPM;

      //find valid pms from end date
      let validManagers = [];
      validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (validManagers.length === 0 && crProjectManagers.length > 0){
        crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
        const lastAssignedManager = crProjectManagers[0];
        validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

        //validManagers.push(crProjectManagers[0]);
      }

      validManagers.forEach(value => {
        if(window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()){
          crAccess = true;
        }
      });


      if(CR.CMSheets.length > 0){
        cmsAvailable = true;

        validManagers.forEach(value => {
          if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase() || (viewCMSPermission && viewCMSPermission.length>0))){
            const viewCRObj={
              name: CR.CRName,
              data : CR
            }
            viewCrCMS.push(viewCRObj);
          }
        });


        // Edit CMS CR
        if (CR && CR.ResourceRequestStatus && CR.ResourceRequestStatus === 'PM_ALLOCATED') {
          if (CR.AllocatedPM && CR.AllocatedPM.length>0) {
            let editCRObj = null;
            let isEditCRCMS = false;
            for(const allocatedPM of validManagers){

              if ( !allocatedPM.IsPresales && (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase())) {

                if (CR.CMSheets[0].Status === 'IN_PROGRESS' && CR.Status.Code === 'PRESALES') {
                  editCRObj = {
                    name: CR.CRName,
                    data: CR
                  };
                  isEditCRCMS = true
                }

              }
            }
            if (editCRObj && isEditCRCMS) {
              editCrCMS.push(editCRObj);
            }
          }
        } else {
          validManagers.forEach(allocatedPM => {
            if (allocatedPM.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === allocatedPM.PMResource.Email.toUpperCase() && CR.IsActive === true)){
              if (CR.CMSheets[0].Status === 'IN_PROGRESS' && (CR.Status && CR.Status.Code === 'PRESALES')) {
                const editCRObj = {
                  name: CR.CRName,
                  data: CR
                };
                editCrCMS.push(editCRObj);
              }

            }
          });


        }


        // Revise CMS
        validManagers.forEach(value => {
          if(value.IsPresales && ((CR.CMSheets[0].Status === 'REJECTED' || CR.CMSheets[0].Status === 'APPROVED' || CR.CMSheets[0].Status === 'EXPIRED') &&
            window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
            const reviseCRObj={
              name: CR.CRName,
              data : CR
            }
            reviseCrCMS.push(reviseCRObj);
          }
        });


      }
      else {
        //This logic is when a CR doesnt have a CMS
        changeReqList.push(CR);
      }
    })


    const projectProjectManagers = project.AllocatedResourceRequestPM;

    //find valid pms from end date
    let projValidManagers = [];
    projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

    if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
      //projValidManagers.push(projectProjectManagers[0]);
      projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
      const lastAssignedManager = projectProjectManagers[0];
      projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

    }
    projValidManagers.forEach(value => {
      if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
        pmAccess = true;
      }
    });


    if(project.CMSheets.length > 0){
      cmsAvailable = true;

      let projectManager = false;

        if (projValidManagers && projValidManagers.length>0) {
          for(const pm of projValidManagers) {
            if (pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
              projectManager = true
            }
          }
        }

      if( projectManager || (viewCMSPermission && viewCMSPermission.length>0)){
        const isProjectCMS = project.CMSheets.filter(a => !a.IsCRCMS);
        if(isProjectCMS && isProjectCMS.length > 0){
          const viewProjObj={
            name: project.ProjectName,
            data : project
          };
          viewProjectCMS.push(viewProjObj);
        }
      }

      if (projectManager === true) {
        if(project.CMSheets[0].Status === 'IN_PROGRESS' && project.ProjectStatus.Code == "PRESALES"){
          const isProjectCMS = project.CMSheets.filter(a => !a.IsCRCMS);
          if(isProjectCMS && isProjectCMS.length > 0){
            const editProjObj={
              name: project.ProjectName,
              data : project
            };
            editProjectCMS.push(editProjObj);
          }
        }
      }

      // Revise CMS
      projValidManagers.forEach(value => {
        if(value.IsPresales && ((project.CMSheets[0].Status === 'REJECTED' || project.CMSheets[0].Status === 'APPROVED' || project.CMSheets[0].Status === 'EXPIRED'
        ) && window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
          const reviseProjectObj={
            name: project.ProjectName,
            data : project
          }
          reviseProjectCMS.push(reviseProjectObj);
        }
      });

    }
    return (
      <td>
        {this.props.dataItem.CMSheets.map((item, index) => (
          <div key={index}>
            { ((!item.IsCRCMS && ((viewCrCMS && viewCrCMS.length>0) || (viewProjectCMS && viewProjectCMS.length>0)))
              || (item.IsCRCMS && (viewCrCMS ||viewCrCMS.length > 0) && (!viewProjectCMS || viewProjectCMS.length <=0) && index == 0)) && (
                  <a href="#" onClick={event => this.handleViewCMS(event,project, viewProjectCMS, viewCrCMS)}>
                    <div className="rounded-link">
                      <span className="k-icon k-i-preview" title="View"/>
                    </div>
                  </a>
            )}

            { ((!item.IsCRCMS && (editCrCMS.length>0 || editProjectCMS.length>0)) 
              || (item.IsCRCMS && editCrCMS.length> 0 && editProjectCMS.length <= 0 && index == 0))
              && (pmAccess === true || crAccess === true)  && (
                <a href="#" onClick={event => this.hanldeEditCMS(event,project, editProjectCMS, editCrCMS)}>
                  <div className="rounded-link">
                    <span className="k-icon k-i-edit" title="Edit CR/Project CMS" />
                  </div>
                </a>

            )}
            {/**/}

            <div className="gs-disc">
              <label>{`${item.IsCRCMS ? 'CR CMS: ': ''}${item.IsCRCMS ? item.CrId :this.props.dataItem.UniqueId} | ${
                  item.MajorVersion + '.' + item.Version
              }`}</label>
            </div>
          </div>
        ))}
      </td>
    );
  }
}

export default CMSheetCell;
