import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CriteriaActionsCell extends Component {
  render() {
    const criteria = this.props.dataItem;
    return (
      <td className="action-buttons">
        {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_WFCRI') > -1 && (
          <Link
            to={{
              pathname: '/WorkflowCriteriaDefinitionCreate/WorkflowCriteriaDefinitionCreate',
              action: 'edit',
              criteria: criteria.definitions,
              parameterID : criteria.id,
              parameterName : criteria.Name
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-edit" title="Edit" />
            </div>
          </Link>
        )}
      </td>
    );
  }
}

export default CriteriaActionsCell;
