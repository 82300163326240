import React, { Component } from 'react';
import moment from 'moment';
import 'moment-timezone';

class PendingTaskDescCell extends Component {
    render() {
        const pendingTasks = this.props.dataItem;

        
        if (pendingTasks.Type === 'CMS_PENDING_APPROVAL') {
            if(pendingTasks.CMSheet.Type === 'Project'){
                return <td>You have been assigned as an approver for a project CM Sheet.</td>;
            }else {
                return <td>You have been assigned as an approver for a change request CM Sheet.</td>;
            }
        }
        else if (pendingTasks.Type === 'RESOURCE_REQUEST_PENDING_APPROVAL') {
            let region = pendingTasks.Project &&  pendingTasks.Project.Region ? pendingTasks.Project.Region.Name : '';
            return <td>You have been assigned as an approver for a Resource allocation - {region}</td>;
        } 
        else if (pendingTasks.Type === 'RESOURCE_REQUEST_APPROVAL_WORKFLOW') {
            return <td>You have been assigned as an approver for a Resource request</td>;
        }
        else if (pendingTasks.Type === 'RESOURCE_ALLOCATION_APPROVAL_WORKFLOW') {
            let region = pendingTasks.Project &&  pendingTasks.Project.Region ? pendingTasks.Project.Region.Name : '';
            return <td>You have been assigned as an approver for a Resource allocation - {region}</td>;
        }
        else if (pendingTasks.Type === 'FACTORY_POOL_PLANNING_SHEET_APPROVAL') {
            if(pendingTasks.CMSheet.Type === 'Project'){
                return <td>You have been assigned as an approver for a pool project CM Sheet.</td>;
            }else {
                return <td>You have been assigned as an approver for a pool change request CM Sheet.</td>;
            }
        } 
        else if (pendingTasks.Type === 'STATUS_CHANGE_APPROVAL') {
            if(pendingTasks.CurrentStatus !== null && pendingTasks.ReqStatus !== null){
                return <td>{pendingTasks.CurrentStatus + ' to ' + pendingTasks.ReqStatus + ' status change'}</td>;
            }
            else{
                return <td>status change</td>;
            }
        }
        else if (pendingTasks.Type === 'TIME_ENTRY_WEEKLY') {
            let duration = 0;
            let durationParts = pendingTasks.Hours.toString().split('.');
            duration = durationParts[0];
            if (typeof durationParts[1] !== 'undefined') {
                duration =
                parseInt(duration) +
                ':' +
                Math.round(parseFloat(`0.${durationParts[1]}`) * 60)
                    .toString()
                    .padStart(2, '0');
            } else {
                duration = parseInt(duration) + ':' + '00';
            }
            return <td> {duration + ' hours of time entry are incomplete.'}</td>;
        }
        
        else if (this.props.dataItem.Type === 'TIME_APPROVAL_WEEKLY') {
            let duration = 0;
            let durationParts = pendingTasks.Hours.toString().split('.');
            duration = durationParts[0];
            if (typeof durationParts[1] !== 'undefined') {
                duration =
                parseInt(duration) +
                ':' +
                Math.round(parseFloat(`0.${durationParts[1]}`) * 60)
                    .toString()
                    .padStart(2, '0');
            } else {
                duration = parseInt(duration) + ':' + '00';
            }
            return <td> {duration + ' hours of time approvals are incomplete.'}</td>;
        }
        else if (this.props.dataItem.Type === 'TIME_ENTRY_UNFREEZE_APPROVAL') {
            let duration = 0;
            let durationParts = pendingTasks.Hours.toString().split('.');
            duration = durationParts[0];
            if (typeof durationParts[1] !== 'undefined') {
                duration =
                parseInt(duration) +
                ':' +
                Math.round(parseFloat(`0.${durationParts[1]}`) * 60)
                    .toString()
                    .padStart(2, '0');
            } else {
                duration = parseInt(duration) + ':' + '00';
            }
            return <td> {duration + ' hours of time sheet approvals are incomplete.'}</td>;
        }
        else if (pendingTasks.Type === 'TIMESHEET_UNFREEZE_APPROVAL') {
            const unfreezeDates = pendingTasks.UnFreezeRequests.UnfreezeRequestDate;
            let unfreezeDatesArr = [];
            let dateRange = "";

            // get UnFreezeRequest Dates
            if (unfreezeDates && unfreezeDates.length > 0) {
                unfreezeDates.forEach(value => { 
                let date = new Date(value.UnfreezeDate);
                unfreezeDatesArr.push(date); 
                });
            }
            unfreezeDatesArr.sort(function(a,b){
                return new Date(a) - new Date(b);
            });

            let maxDate=moment(new Date(Math.max.apply(null,unfreezeDatesArr))).format("YYYY-MM-DD");
            let minDate=moment(new Date(Math.min.apply(null,unfreezeDatesArr))).format("YYYY-MM-DD");

            if (maxDate === minDate){
                dateRange = maxDate;
            } else {
                let onSequence;
                for (let i=0; i < unfreezeDatesArr.length - 1; i++) {
                    let loopDate = new Date(unfreezeDatesArr[i]);
                    let nextDate = new Date(loopDate.setDate(loopDate.getDate() + 1));

                    let loopNextDate = new Date(unfreezeDatesArr[i+1]);
                    if (nextDate.toDateString() !== loopNextDate.toDateString()) {
                        onSequence = false;
                        break;
                    } else {
                        onSequence = true
                    } 
                }
                if (onSequence === true) {
                    dateRange = minDate + " - " + maxDate;
                } 
                else {
                    let dates = [];
                    for (let item of unfreezeDatesArr) {
                        let date = moment(new Date(item)).format("MM-DD-YYYY");
                        dates.push(date);
                    }
                    dateRange = dates.join(", ");
                }        
            }
            return <td>{dateRange + " "} is requested be unfrozen for approval</td>;
        } else if (pendingTasks.Type === 'REVISE_CMS_PENDING_APPROVAL') {
            if(pendingTasks.CMSheet.Type === 'Project'){
                return <td>You have been assigned as an approver for a project CM Sheet Revision request.</td>;
            }else {
                return <td>You have been assigned as an approver for a change request CM Sheet Revision request.</td>;
            }
        } else if (pendingTasks.Type === 'INVOICE_REQUEST_PENDING_APPROVAL') {
            return <td>You have been assigned as an approver for a invoice request.</td>;
        } 
    }
}

export default PendingTaskDescCell;
