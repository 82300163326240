/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/main';
import RowCellMilestones from './RowCellMilestones';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { deleteMilestone } from '../project/ProjectService';
import { uploadFile } from '../../integration/fileUpload/FileUploadService'
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../../src/ui/static/images/checked.svg';
import ErrorImg from '../../../src/ui/static/images/cancel.svg';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';

class Milestones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isViewMode: false,
            milestones: this.props.milestones,
            project: this.props.project,
            skip: 0,
            take:10,
            showConfirmDialog: false,
            confirmDialogMessage: '',
            confirmDialogAction: null,
            selectedFile: [],
            isCannotUpload: true,
            visible: false,
            dialogMessage: '',
            dialogTitle: '',
            loading: false
        }
    }

    componentDidMount() {
        this.populateMilestones();
    }

    populateMilestones = async () => {
        const milestones = this.props.milestones.slice();
        let i = 0;
        for (const milestone of milestones) {
            milestone.index = i;
            i++;
        }
        this.setState(
            {
              milestones: milestones
            }, () => {
                this.updateProject();
            }
        );
    }

    handleChange = (event) => {
        this.props.handleOnChange(event);
    };

    handleFilterChange = event => {
        this.props.handleFilterChange(event);
    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    addNewMilestone = async () => {
        const milestones = this.state.milestones.slice();
        for (const milestone of milestones) {
            milestone.index = milestone.index + 1;
        }

        const newItem = {
            index: 0,
            ProjectId: null,
            CrId: null,
            Name: '',
            StartDate: null,
            EndDate: null,
            Revenue: null,
            FileId: null,
            IsActive: null,
        };

        milestones.unshift(newItem);

        this.setState(
          {
            milestones: milestones
          }, () => {
              this.updateProject();
          }
        );
    };

    updateProject = async () => {
        await this.props.onUpdateMilestones(this.state.milestones);
    };

    handleDeleteButtonClick = dataItem => {
        this.setState(
          {
              rowItemToDelete: dataItem
          },
          () => {
              const message = 'Are you sure you want to continue to delete?';
              this.toggleConfirmDialog(message, this.deleteRow);
          }
        );
    };

    deleteRow = async () => {
        const milestones = this.state.milestones.slice();
        const index = this.state.rowItemToDelete.index;

        if (index > -1) {
            let deletedRow = milestones.splice(index, 1);
            if(deletedRow[0].id){
                await deleteMilestone(deletedRow[0].id)
                  .then(res => {
                  })
                  .catch(error => {
                      loggerService.writeLog(error, LOG_TYPES.ERROR);
                      this.toggleMessageDialog(error.response.data, 'Error');
                  });
            }
        }

        for (let i = 0; i < milestones.length; i++) {
            milestones[i].index = i;
        }
        await this.setState(
          {
            milestones: milestones
          },
          async () => {
            this.toggleConfirmDialog('', null);
            this.updateProject();
          }
        );
    };

    handleFileUpload = async (event, dataItem) => {

        if((event.target.files[0].type === 'application/pdf' || 
            event.target.files[0].type === 'image/jpeg' || 
            event.target.files[0].type === 'image/png' || 
            event.target.files[0].type === 'application/vnd.ms-project' ||
            event.target.files[0].type === "application/msword" ||
            event.target.files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            event.target.files[0].type === 'application/vnd.ms-powerpoint' ||
            event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
            event.target.files[0].type === 'application/vnd.ms-excel' || 
            event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' ||
            event.target.files[0].type === "application/vnd.ms-outlook" ||
            event.target.files[0].type === "application/msoutlook" ||
            event.target.files[0].name.includes('.msg') ||
            event.target.files[0].name.includes('.htm') ||
            event.target.files[0].name.includes('.mpp')) ) {
                await this.setState({
                    selectedFile: event.target.files[0],
                    loaded: 0,
                    isCannotUpload: false,
                    loading: true
                });
        }
        else {
            const message = "Attachment can be only added as a 'pdf, png, jpg, doc, docx, dotx, xls, xlsx, ppt, pptx, or mpp' file";
            const title = 'Error';
            await this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                isCannotUpload: true
            });
            await this.toggleDialog(message, title);
        }

        if (this.state.isCannotUpload === false) {
            // UPLOAD LOGIC
            var form_data = new FormData();
            form_data.append('file', this.state.selectedFile);
            form_data.append('referenceType', 'Milestone');
            form_data.append('referenceId', this.props.project ? this.props.project.id : null);

            await uploadFile(form_data).then(res => {
                if (res) {
                    this.setState({
                        selectedFile: res.data,
                        loading: false,
                        isFileChanged: true,
                        showSuccessImage: true,
                        showErrorImage: false,
                    },
                    async () => {
                        dataItem.FileUpload = res.data;
                        dataItem.FileId = res.data.id;
                        this.updateProject();
                    });
                }
                this.toggleDialog('File has been uploaded successfully', 'Upload Status');
            }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.setState({
                    loading: false
                });
            });
        }
    };

    toggleConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    toggleDialog = (message, title) => {
        this.setState({
            visible: !this.state.visible,
            dialogMessage: message,
            dialogTitle: title,
        });
    };

    NumericTextBoxCell = props => {
        let isDisable = false;
        if (props.dataItem.InvoiceRequest && (props.dataItem.InvoiceRequest.Status === 2 || props.dataItem.InvoiceRequest.Status === 3 || props.dataItem.InvoiceRequest.Status === 6)) {
            isDisable = true;
        }
        if (props.field === 'Revenue') {
            return (
              <RowCellMilestones
                {...props}
                onChange={this.itemChange}
                Type="Milestone"
                disabled={this.props.viewMode || isDisable}
              />
            );
        } else if (props.field === 'Name') {
            return (
                <RowCellMilestones
                  {...props}
                  onChange={this.itemChange}
                  Type="Milestone"
                  disabled={this.props.viewMode || isDisable}
                />
              );
        }
    };

    ActionCell = props => {
        let isDisable = false;
        let isDisableDelete = false;
        if (props.dataItem.InvoiceRequest && (props.dataItem.InvoiceRequest.Status === 2 || props.dataItem.InvoiceRequest.Status === 3 || props.dataItem.InvoiceRequest.Status === 6)) {
            isDisable = true;
        }
        if (props.dataItem.InvoiceRequest && (props.dataItem.InvoiceRequest.Status === 1 || props.dataItem.InvoiceRequest.Status === 4 ||props.dataItem.InvoiceRequest.Status === 5  || props.dataItem.InvoiceRequest.Status === 6)) {
            isDisableDelete = true;
        }
        return (
            <RowCellMilestones
                {...props}
                onChange={this.itemChange}
                Type="Milestone"
                disabled={this.props.viewMode || isDisable}
                onDeleteButtonClick={this.handleDeleteButtonClick}
                onFileUploadClick={this.handleFileUpload}
                project={this.state.project}
                isDisableDelete={isDisableDelete}
            />
        );
    };


    DatePickerCell = props => {
        let isDisable = false;
        if (props.dataItem.InvoiceRequest && (props.dataItem.InvoiceRequest.Status === 2 || props.dataItem.InvoiceRequest.Status === 3 || props.dataItem.InvoiceRequest.Status === 6)) {
            isDisable = true;
        }
        if (props.field === 'StartDate') {
            props.dataItem.inEdit = true;
            return (
              <RowCellMilestones
                {...props}
                disabled={this.props.viewMode || isDisable}
                onChange={this.itemChange}
                required={true}
                Type="Milestone"
                project={this.state.project}
              />
            );
        } else if (props.field === 'EndDate') {
            return (
              <RowCellMilestones
                {...props}
                disabled={this.props.viewMode || isDisable}
                onChange={this.itemChange}
                required={true}
                Type="Milestone"
                project={this.state.project}
              />
            );
        }
    };

    itemChange = async event => {
        const name = event.field;
        const dataItem = event.dataItem;
        switch (name) {
            case 'StartDate':
                if (event.value) {
                    let startDate = new Date(event.value);
                    startDate.setHours(0, 0, 0, 0);    
                    dataItem.StartDate = startDate;
    
                } else {
                    dataItem.StartDate = null;
                }
                break;
            case 'EndDate':
                if (event.value) {
                    let endDate = new Date(event.value);
                    endDate.setHours(0, 0, 0, 0);
                    dataItem.EndDate = endDate;
                } else {
                    dataItem.EndDate = null;
                }
                break;
            case 'Revenue':
                if (event.value) {
                    dataItem.Revenue = event.value;
                } else {
                    dataItem.Revenue = null;
                }
                break;
            case 'Name':
                if (event.value) {
                    dataItem.Name = event.value;
                } else {
                    dataItem.Name = null;
                }
                break;
        }
        this.setState(
            {
                milestones: [...this.state.milestones]
            },
            async () => {
                this.updateProject();
            }
        );
    };

    pageChange = event => {
        this.setState({
          skip: event.page.skip,
          take: event.page.take
        });
    };

    render() {

        return (
            <div>
                <div className="main-heading">Milestones</div>
                <div className="row">
                    <div className="col-md-12 btn-align-left mb-2">
                        <Button primary={true}
                                className="mr-2"
                                type="submit"
                                onClick={this.addNewMilestone}
                                disabled={this.props.viewMode}
                        >
                            Add
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 presales-sec">
                        <Grid 
                            style={{ height: '250px' }}
                            data={this.state.milestones.slice(
                                this.state.skip,
                                this.state.take + this.state.skip
                            )}
                            skip={this.state.skip}
                            take={this.state.take}
                            total={this.state.milestones.length}
                            pageable={true}
                            onPageChange={this.pageChange}
                            resizable
                        >
                            <Column
                                field="Name"
                                headerCell={props => <label>Name</label>}
                                width="250px"
                                maxLength={10}
                                cell={this.NumericTextBoxCell}
                            />
                            <Column
                                field="StartDate"
                                headerCell={props => <label>Start Date</label>}
                                width="150px"
                                maxLength={10}
                                cell={this.DatePickerCell}
                            />
                            <Column
                                field="EndDate"
                                headerCell={props => <label>End Date</label>}
                                width="150px"
                                maxLength={10}
                                cell={this.DatePickerCell}
                            />
                            <Column
                                field="Revenue"
                                headerCell={props => <label>Milestone Revenue</label>}
                                width="140px"
                                cell={this.NumericTextBoxCell}
                            />
                            <Column
                                field="FileId"
                                headerCell={props => <label>Customer Approval</label>}
                                width="140px"
                                cell={this.ActionCell}
                            />
                            <Column
                                field="Actions"
                                headerCell={props => <label>Actions</label>}
                                width="75px"
                                cell={this.ActionCell}
                            />
                        </Grid>
                    </div>
                </div>

                {this.state.showConfirmDialog === true && (
                  <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                      <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                      <DialogActionsBar>
                          <button className="k-button" onClick={this.toggleConfirmDialog}>
                              No
                          </button>
                          <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                              Yes
                          </button>
                      </DialogActionsBar>
                  </Dialog>
                )}

                <div>
                    {this.state.visible === true && (
                        <Dialog
                            title={this.state.dialogTitle}
                            onClose={this.toggleDialog}
                            width="400px">
                            
                            {this.state.showSuccessImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={SuccessImg} alt="Success"/>
                                </div>
                            )}

                            {this.state.showErrorImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={ErrorImg} alt="Error Image"/>
                                </div>
                            )}
                            <p style={{ margin: "25px", textAlign: "center" }}>
                                {this.state.dialogMessage}
                            </p>
                            <DialogActionsBar>
                                <button
                                    className="k-button modal-primary"
                                    onClick={this.toggleDialog}>
                                    OK
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
                <Loader loading={this.state.loading} />
            </div>
        );
    }
}

export default Milestones;
