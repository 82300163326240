import React, { Component } from 'react';
import { Calendar } from '@progress/kendo-react-dateinputs';

class MultiyearCustomPopup extends Component {
  render() {
    return (
      <Calendar
        {...this.props}
        bottomView="decade"
        topView="decade"
        value={this.props.value}
        onChange={this.props.onChange}
      />
    );
  }
}

export default MultiyearCustomPopup;
