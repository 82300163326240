import { getDesignationGroups } from '../masterData/designationGroup/DesignationGroupService';
import {
  getAllDesignations,
  getDesignations,
  getSkillArea
} from '../masterData/designation/DesignationService';
import { getHolidays } from '../masterData/calendar/CalendarService';
import { getSalesPerson, getProjectPartners } from '../masterData/commission/CommissionService';
import { getRateTypes } from '../masterData/rateCard/RateCardService';
import { getRateCardsForGrid } from '../masterData/rateCard/RateCardService';
import {
  getActiveCostCards,
  getCost,
  getMultiYearCost,
  searchCostCards
} from '../masterData/costCard/CostCardService';
import {
  getMultiYearInformation,
  saveCostCardIncrements,
  deleteMultiYearGridInfo,
  getMultiYearInfoByYearandCostCardId
} from '../masterData/multiyearIncrement/MultiyearIncrementService';
import {
  getResourceRegions,
  getRegionInfo,
  getRegions,
  getRegionsWithCountries,
  saveRegion,
  updateRegion,
  updateRegionStatus,
  getAllCountriesWithoutARegion,
  getRegionCurrency,
  getRegionById,
  getActiveResourceRegions,
  getActiveAllocatedRegions
} from '../masterData/region/RegionService';
import {
  getBillingDivi,
  getBillingDivisionById,
  getBillingDiviDropdown,
  getAllActivePracticeTypes,
  getAllPracticeDivisionMap
} from '../masterData/practice/PracticeService';
import {
  getCustomerPrograms,
  getcustomers,
  searchCustomers
} from '../masterData/customer/CustomerService';
import { getOfferings } from '../masterData/offering/OfferingService';
import {
  getProjectTypes,
  getPaymentMethods,
  getPaymentMethodById
} from '../masterData/common/CommonService';
import {
  getApproverTypes,
  getApproverTypeById
} from '../masterData/approverType/ApproverTypeService';
import { getCMSheet } from '../projectManagement/cmSheet/CMSheetService';
import { getWorkflowDefinitions } from '../masterData/workflowCriteriaDefinition/WorkflowCriteriaDefinitionService';
import { getAllTaskTypes } from '../masterData/taskType/TaskTypeService';

/**
 * Retrieve DesignationGroups
 * @returns {array} Designation Groups
 */
export function getDesignationGroupsIntegrated() {
  return getDesignationGroups();
}

/**
 * Retrieve all DesignationGroups
 * @returns {array} Designation Groups
 */
export function getAllDesignationsIntegrated() {
  return getAllDesignations();
}

/**
 * Retrieve all Designation
 * @returns {array} Designation
 */
export function getDesignationsIntegrated() {
  return getDesignations();
}

// REGION MASTER-DATA SERVICE
/**
 * Retrieve Resource Regions
 * @returns {array} Resource Regions
 */
export function getResourceRegionsIntegrated() {
  return getResourceRegions();
}

/**
 * Retrieve Active Resource Regions
 * @returns {array} Resource Regions
 */
export function getActiveResourceRegionsIntegrated() {
  return getActiveResourceRegions();
}

/**
 * Retrieve Resource Regions
 * @returns {object} Resource Regions
 */
export function getRegionByIdIntegrated(regionID) {
  return getRegionById(regionID);
}

/**
 * Retrieve Regions information
 * @returns {array} Resource information
 */
export function getRegionInfoIntegrated() {
  return getRegionInfo();
}

/**
 * Retrieve Regions
 * @returns {array} Regions
 */
export function getRegionsIntegrated() {
  return getRegions();
}

/**
 * Retrieve Regions With theirs Countries
 * @returns {array} Regions
 */
export function getRegionsWithCountriesIntegrated() {
  return getRegionsWithCountries();
}

/**
 * Save Region after edit/created
 * @returns {array}  array of saved details of Region
 */
export function saveRegionIntegrated() {
  return saveRegion();
}

/**
 * update Region.
 * @returns {object} response 0 or 1 (true or false) as an array
 */
export function updateRegionIntegrated() {
  return updateRegion();
}

/**
 * update Region Status.
 * @returns {object} response 0 or 1 (true or false) as an array
 */
export function updateRegionStatusIntegrated() {
  return updateRegionStatus();
}

/**
 * Retrieve All Countries Without their Regions
 * @returns {array} Countries
 */
export function getAllCountriesWithoutARegionIntegrated() {
  return getAllCountriesWithoutARegion();
}

/**
 * Retrieve Resource Regions
 * @returns {array} Resource Regions
 */
 export function getActiveAllocatedRegionsIntegrated() {
  return getActiveAllocatedRegions();
}

/**
 * Retrieve all billing Divisions for drop downs.
 * @returns {array} array list of all billing Divisions
 */
export function getBillingDiviIntegrated() {
  return getBillingDivi();
}

export function getBillingDiviDropDownIntegrated() {
  return getBillingDiviDropdown();
}

/**
 * Retrieve all billing Divisions for drop downs.
 * @returns {array} array list of all billing Divisions
 */
export function getBillingDivisionByIdIntegrated(id) {
  return getBillingDivisionById(id);
}

/**
 * Retrieve Holidays.
 * @returns {array} array list of Holidays
 * @param countryId
 * @param year
 */
export function getHolidaysIntegrated(countryId, year) {
  return getHolidays(countryId, year);
}

/**
 * Retrieve SalesPerson.
 * @returns {array} array list of Holidays
 */
export function getSalesPersonIntegrated() {
  return getSalesPerson();
}

/**
 * Retrieve Project Partners.
 * @returns {array} array list of Holidays
 */
export function getProjectPartnersIntegrated() {
  return getProjectPartners();
}

/**
 * Retrieve Rate types.
 * @returns {array} array list of RateCard Types
 */
export function getRateTypesIntegrated() {
  return getRateTypes();
}

/**
 * Retrieve RateCards.
 * @returns {array} array list of RateCards
 */
export function getRateCardsForGridIntegrated() {
  return getRateCardsForGrid();
}

/**
 * Retrieve Active CostCards.
 * @returns {array} array list of CostCards
 */
export function getActiveCostCardsIntegrated() {
  return getActiveCostCards();
}

/**
 * Retrieve Cost value.
 * @returns Cost
 */
export function getCostIntegrated(parms) {
  return getCost(parms);
}

/**
 * Retrieve MultiYear Cost value.
 * @returns Cost
 */
export function getMultiYearCostIntegrated(parms) {
  return getMultiYearCost(parms);
}

/**
 * Retrieve all CostCards by parameters.
 * @returns {array} array list of CostCards
 */
export function searchCostCardsIntegrated(parms) {
  return searchCostCards(parms);
}

/**
 * Retrieve all MultiYear Information.
 * @returns {array} array list of MultiYear
 */
export function getMultiYearInformationIntegrated() {
  return getMultiYearInformation();
}

/**
 * Save CostCard Increments
 * @returns {array}  array of saved details of CostCard
 */
export function saveCostCardIncrementsIntegrated(params) {
  return saveCostCardIncrements(params);
}

/**
 * Delete MultiYear Grid Information
 * @returns {object} response 0 or 1 (true or false) as an array
 */
export function deleteMultiYearGridInfoIntegrated(params) {
  return deleteMultiYearGridInfo(params);
}

/**
 * Retrieve MultiYear Information By Year and CostCardId.
 * @returns {array} array list of MultiYear Information
 */
export function getMultiYearInfoByYearandCostCardIdIntegrated(params) {
  return getMultiYearInfoByYearandCostCardId(params);
}

/**
 * Retrieve Customer Programs by parameters.
 * @returns {array} array list of Programs
 */
export function getCustomerProgramsIntegrated(params) {
  return getCustomerPrograms(params);
}

/**
 * Retrieve Customer by parameters.
 * @returns {array} array list of Customers
 */
export function getcustomersIntegrated() {
  return getcustomers();
}

/**
 * Retrieve all Customer by parameters.
 * @returns {array} array list of Customers
 */
export function searchCustomersIntegrated(params) {
  return searchCustomers(params);
}

/**
 * Retrieve all Offerings by parameters.
 * @returns {array} array list of Offerings
 */
export function getOfferingsIntegrated(params) {
  return getOfferings();
}

/**
 * Retrieve all Project Types.
 * @returns {array} array list of Project Types
 */
export function getProjectTypesIntegrated() {
  return getProjectTypes();
}

/**
 * Retrieve all Payment Methods.
 * @returns {array} array list of Payment Methods
 */
export function getPaymentMethodsIntegrated() {
  return getPaymentMethods();
}

/**
 * Retrieve Payment method by id.
 * @returns {object} Payment Method according to the id
 */
export function getPaymentMethodByIdIntegrated(paymentMethodId) {
  return getPaymentMethodById(paymentMethodId);
}

/**
 * Retrieve all Approver Types.
 * @returns {array} array list of Approver Types
 */
export function getApproverTypesIntegrated() {
  return getApproverTypes();
}

/**
 * Retrieve all Workflow Definitions.
 * @returns {array} array list of Workflow Definitions.
 */
export function getWorkflowDefinitionsIntegrated() {
  return getWorkflowDefinitions();
}

/**
 * Retrieve all Approver Types.
 * @returns {array} array list of Approver Types
 */
export function getApproverTypeByIdIntegrated(approverTypeId) {
  return getApproverTypeById(approverTypeId);
}

/**
 * Retrieve currency.
 * @returns {object} currency of region
 */
export function getRegionCurrencyIntegrated(id) {
  return getRegionCurrency(id);
}

/**
 * Retrieve skillArea.
 * @returns Array  of skillArea
 */
export function getskillAreaIntegrated() {
  return getSkillArea();
}

/**
 * Retrieve taskTypes.
 * @returns {Array} get task types
 */
export function getTaskTypesIntegrated() {
  return getAllTaskTypes();
}

/**
 * Retrieve all active practice types.
 * @returns {array} array list of all active practice types
 */
 export function getPracticeTypesIntegrated() {
  return getAllActivePracticeTypes();
}

/**
 * Retrieve all billing Division and practice mappings.
 * @returns {array} array list of all billing Divisions and practice mappings.
 */
 export function getAllPracticeDivisionMapIntegrated() {
  return getAllPracticeDivisionMap();
}