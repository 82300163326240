import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ActionsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { OnDeactivate } = this.props;
    const { OnActivate } = this.props;
    const { EditModel } = this.props;
    const { ViewModel } = this.props;
    const { DuplicateModel } = this.props;
    const { OnDelete } = this.props;
    const isActive = this.props.dataItem.IsActive;
    const TaskId = this.props.dataItem.id;
    const { AddEditDefaultTask } = this.props;

    return (
      <td className="action-buttons">
        {(window.USER_PERMISSION.indexOf('VW_NP_TASK') > -1 || window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1) && (
          <a
            type="button"
            onClick={() => {
              ViewModel(3, TaskId);
            }}
          >
            <div className="rounded-link" title="View">
              <span className="k-icon k-i-preview" title="View" />
            </div>
          </a>
        )}
        {isActive === true && window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1 && (
          <a
            type="button"
            onClick={() => {
              EditModel(2, TaskId);
            }}
          >
            <div className="rounded-link" title="Edit">
              <span className="k-icon k-i-edit" title="Edit" />
            </div>
          </a>
        )}
        {window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1 && (
          <a type="button" onClick={() => AddEditDefaultTask(TaskId)}>
            <div className="rounded-link">
              <span className="k-icon k-i-myspace" title="View Resources" />
            </div>
          </a>
        )}

        {isActive === true && window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1 && (
          <a
            type="button"
            onClick={() => {
              DuplicateModel(5, TaskId);
            }}
          >
            <div className="rounded-link" title="Duplicate">
              <span className="k-icon k-i-backward-element" title="Duplicate" />
            </div>
          </a>
        )}

        {isActive === false && window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1 && (
          <Link
            to={this.props}
            onClick={event => OnActivate(event, TaskId, this.props.dataItem.DocType)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-check" title="Activate" />
            </div>
          </Link>
        )}
        {isActive === false && window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1 && (
          <Link to={this.props} onClick={event => OnDelete(event, this.props.dataItem.id)}>
            <div className="rounded-link">
              <span className="k-icon k-i-delete" title="Delete" />
            </div>
          </Link>
        )}

        {isActive === true &&
          window.USER_PERMISSION.indexOf('CONFIG_NP_TASK') > -1 && (
            <Link
              to={this.props}
              onClick={event =>
                OnDeactivate(event, this.props.dataItem.id, this.props.dataItem.DocType)
              }
            >
              <div className="rounded-link">
                <span className="k-icon k-i-unsort" title="Deactivate" />
              </div>
            </Link>
          )}
      </td>
    );
  }
}

export default ActionsCell;
