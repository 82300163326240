import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {getUserProfile} from "../../../resourceManagement/UserProfileService";
import {getProjectPartnersIntegrated} from "../../../integration/MasterDataService";

class EditUserActionsCell extends Component {
    render() {
        let editUserProfPermission = window.USER_PERMISSION.filter(obj => obj === 'EDT_RSC_PROF');

        const { OnDeactivate } = this.props;
        const { OnActivate } = this.props;
        const { OnDelete } = this.props;
        const user = this.props.dataItem;
        let userDetails = false;

        return (
            <td className="action-buttons">
                {(window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 || window.USER_PERMISSION.indexOf('USR_MGT') > -1) && user.IsActive === true && (user.IsDeleted === false || user.IsDeleted == null)  && (
                    <Link
                        to={{
                            pathname: '/admin/createUser/createUser',
                            user: user,
                            action: 'edit'
                        }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-edit" title="Edit" />
                        </div>
                    </Link>
                )}

                {((editUserProfPermission && editUserProfPermission.length>0) && user.IsActive === true && (user.IsDeleted === false || user.IsDeleted == null)  && (user.Resource && !user.Resource.Region)) &&  (
                    <Link
                        to={{
                            pathname: '/ResourceManagement/EmployeeUseProfile',
                            user: user,
                            action: 'create_user_profile'
                        }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-track-changes" title="Create User Profile" />
                        </div>

                    </Link>
                )}

                {((editUserProfPermission && editUserProfPermission.length>0) && user.IsActive === true && (user.IsDeleted === false || user.IsDeleted == null)  && (user.Resource && user.Resource.Region)) && (
                    <Link
                        to={{
                            pathname: '/ResourceManagement/EmployeeUseProfile',
                            user: user,
                            action: 'edit_user_profile'
                        }}
                    >
                        <div className="rounded-link-green">
                            <span className="k-icon k-i-user" title="Edit User Profile" />
                        </div>


                    </Link>
                )}

                {(window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 || window.USER_PERMISSION.indexOf('USR_MGT') > -1) && user.IsActive === true && (user.IsDeleted === false || user.IsDeleted == null)   && (
                    <Link
                        to={this.props}
                        onClick={(event) => OnDeactivate(event, this.props.dataItem.id)}
                    >
                        <div className="rounded-link">

                            <span className="k-icon k-i-unsort" title="Deactivate" />
                        </div>
                    </Link>
                )}

                {(window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 || window.USER_PERMISSION.indexOf('USR_MGT') > -1) && user.IsActive === false && (user.IsDeleted === false || user.IsDeleted == null)  && (
                    <Link
                        to={this.props}
                        onClick={(event) => OnActivate(event, this.props.dataItem.id)}
                    >
                        <div className="rounded-link">

                            <span className="k-icon k-i-check" title="Activate" />
                        </div>
                    </Link>
                )}

                {(window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 || window.USER_PERMISSION.indexOf('USR_MGT') > -1) && user.IsActive === false && (user.IsDeleted === false || user.IsDeleted == null)  && (
                    <Link
                        to={this.props}
                        onClick={(event) => OnDelete(event, this.props.dataItem.id, user.Email)}
                    >
                        <div className="rounded-link">

                            <span className="k-icon k-i-delete" title="Delete" />
                        </div>
                    </Link>
                )}
            </td>
        );
    }
}

export default EditUserActionsCell;
