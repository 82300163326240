import React, { Component } from 'react';

class PMCell extends Component {
    render() {
        const roles = this.props.dataItem.Roles;

        var result = roles.filter(obj => {
            if(obj.Rolename === "PM") {
                return true;
            }
          })

        if (result.length > 0) {
            return <td><input type="checkbox" className="k-checkbox" value={true} checked={true} readOnly /><label className="k-checkbox-label"> </label></td>;
        } else {
            return <td><input type="checkbox" className="k-checkbox" value={false} checked={false} readOnly /><label className="k-checkbox-label"> </label></td>;
        }

    }
}

export default PMCell;
