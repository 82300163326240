import http from '../HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Search QMS Template Details.
 * @param qmsTempDetails - (object)
 * @returns List of QMS Template Details.
 */
 export function searchQmsTemp(qmsTempDetails) {
  const endPoint = apiUrl + '/qms/qmsSearch/search';
  return http.post(endPoint, qmsTempDetails);
}
 
/**
 * Search QMS Template Details for generate QMS.
 * @param qmsTempDetails - (object)
 * @returns List of QMS Template Details.
 */
 export function searchQmsGenerateTemp(qmsTempDetails) {
  const endPoint = apiUrl + '/qms/qmsSearch/searchqms';
  return http.post(endPoint, qmsTempDetails);
}
 

/**
 * Get Document Name.
 * @returns List of Document Names.
 */
export function getDocumentName() {
  const endPoint = apiUrl + '/qms/qmsSearch/getDocumentName';
  return http.post(endPoint);
}

/**
 * Get DocumentCode.
 * @returns List of Document Codes.
 */
export function getDocumentCode() {
  const endPoint = apiUrl + '/qms/qmsSearch/getDocumentCode';
  return http.post(endPoint);
}

/**
 * delete document Id by param.
 * @param docId.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
 export function deleteQmsTemplate(docId, data) {
  const endPoint = apiUrl + '/qms/qmsSearch/delete/' + docId;
  return http.put(endPoint, data);
}

/**
 * Update QMS Status.
 * @param IsActive.
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
 export function updateQmsStatus(IsActive, id) {
  const endPoint = apiUrl + '/qms/qmsSearch/' + id;
  return http.put(endPoint, IsActive);
}

 /** 
 * saveQMSUploads - Save QMS uploads
 * @param params
 * @returns {array} array of saved details of QMS uploads
 */
export function saveQMSUpload(params) {
   const endPoint = apiUrl + '/qms/qmsSearch/saveQMSUploads';
   return http.post(endPoint, params);
}

/**
 * Get  Template Details.
 * @param qmsTempDetails - (object)
 * @returns List of QMS Template Details.
 */
export function getChangeRequests(ChangeRequests) {
  const endPoint = apiUrl + '/qms/qmsSearch/getChangeRequests';
  return http.post(endPoint, ChangeRequests);
}

/**
 * Update QMSUpload record by param.
 * @param qmsUploadData
 * @param id
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function updateQMSUpload(qmsUploadData) {
  const endPoint = apiUrl + '/qms/qmsSearch/updateQMSUpload';
  return http.post(endPoint, qmsUploadData);
}

 /** 
 * getQMSTemplatebyId - get QMS Template by Id
 * @param params
 * @returns {array} array of QMS Template 
 */
 export function getQMSTemplatebyId(docId) {
  const endPoint = apiUrl + '/qms/qmsSearch/getQMSTemplatebyId/' + docId;
  return http.get(endPoint);
}


