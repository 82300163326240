import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../ui/static/images/loader.svg';

class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { imageSrc, imageStyle, overlayBackground, loading } = this.props;
        const overlayBlockStyle = {
            zIndex: "99999",
            height: "100vh",
            width: "100vw",
            textAlign: "center",
            position: "fixed",
            display: loading ? "block" : "none",
            backgroundColor: overlayBackground,
            top: 0,
            left: 0
        };
        return (
            <div style={overlayBlockStyle} >
                <img style={imageStyle} src={imageSrc} alt={"Loading...."} />
            </div>
        );
    }
}
Loader.propTypes = {
    imageSrc: PropTypes.string,
    overlayBackground: PropTypes.string,
    imageStyle: PropTypes.object,
    loading: PropTypes.bool.isRequired,
};
Loader.defaultProps = {
    imageSrc: loader,
    imageStyle: { marginTop: "20%" },
    overlayBackground: "rgba(0,0,0,0.4)"
};
export default Loader;
