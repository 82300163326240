import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * getAllNonProjectTasks - retrieves all NonProjectTasks
 * @returns {array}  array of NonProjectTasks
 */
export function getAllNonProjectTasks(params, offset, pageSize, sort) {
  const endPoint = apiUrl + `/timeSheetManagement/nonprojecttasks/getallnonprojecttasks/${offset}/${pageSize}/${sort && sort[0]?sort[0].field: null}/${sort && sort[0]?sort[0].dir : null}`;
  return http.post(endPoint, params);
}
/**
 * getAllNonProjectTaskUsers - retrieves all NonProjectTasks Users
 * @returns {array}  array of NonProjectTasks users
 */
export function getAllNonProjectTaskUsers() {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/getAllNonProjectTaskUsers';
  return http.get(endPoint);
}

/**
 * getAllocatedBillingRegion - retrieves all allocated Billing region
 * @returns {array}  array of allocatedBillingRegion
 */
export function getAllocatedBillingRegion() {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/getAllocatedBillingRegion';
  return http.get(endPoint);
}

  /**
 * getResourceAllocatedRegion - retrieves all allocated Billing region
 * @returns {array}  array of allocatedBillingRegion
 */
export function getResourceAllocatedRegion() {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/getResourceAllocatedRegion';
  return http.get(endPoint);
}

/**
 * getNonProjectTaskApprovers - retrieves all approvers in NanProjectTask
 * @returns {array}  array of NonProjectTaskApprovers
 */
export function getNonProjectTaskApprovers() {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/getNonProjectTaskApprovers';
  return http.get(endPoint);
}

/**
 * getAllocatedPractice - retrieves all allocated parctice
 * @returns {array}  array of getAllocatedPractice
 */
export function getAllocatedPractice() {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/getAllocatedPractice';
  return http.get(endPoint);
}


/**
 * getAllocatedDepartment - retrieves all allocated parctice
 * @returns {array}  array of getAllocatedDepartment
 */
export function getAllocatedDepartment() {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/getAllocatedDepartment';
  return http.get(endPoint);
}

/**
 * getNonProjectTaskAllowcations - retrieves all NonProjectTasks allocations
 * @returns {array}  array of NonProjectTasks and NonProjectTaskAllowcations
 */
export function getNonProjectTaskAllowcations(id) {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/getNonProjectTaskAllowcations/'+id;
  return http.get(endPoint,id);
}

/**
 * Update NonProject Task status.
 * @param params.
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function updateNonProjectTaskStatus(params, id) {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/updateNonProjectTaskStatus/'+ id;
  return http.put(endPoint, params);
}

/**
 * Delete NonProject Task status.
 * @param params.
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function deleteNonProjectTask(params, id) {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/deleteNonProjectTask/'+ id;
  return http.put(endPoint, params);
}


/**
 * search all NonProjectTasks by given parameters.
 * @returns {array} array list of all NonProjectTasks
 */
export function searchNonProjectTasks(params) {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/search';
  return http.post(endPoint, params);
}

/**
 * check time entries has enterd or not to NonProjectTask by any user.
 * @returns {boolean} 
 */
export function checkTimeEntryEnteredOrNot(taskId) {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/checkTimeEntryEnteredOrNot/'+taskId;
  return http.get(endPoint);
}


/**
 * getallnonprojecttaskgroups - retrieves all NonProjectTaskGroups
 * @returns {array}  array of NonProjectTaskGroups
 */
export function getAllNonProjectTaskGroups() {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/getallnonprojecttaskgroups';
  return http.get(endPoint);
}

/**
 * Will replace the existing approver with a new approver for the given non project task/s. 
 * If no existing approver is specified, then a new approver will be added to list of approvers.
 * @param nptData.
 * @param newApproverId.
 * @returns {Object} Object will contain the non project tasks on which the bulk function failed to execute 
 */
export function replaceApproverOfNonProjectTasks(nptData, newApproverId){
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/replaceApprover/' + newApproverId;
  return http.put(endPoint, nptData);
}

/**
 * Will remove resource(s) for the given non project task/s.
 * @param nptData.
 * @returns {Object} Object will contain the non project tasks on which the bulk function failed to execute
 */
export function removeResourceOfNonProjectTasks(nptData){
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/bulkresourceremoval';
  return http.post(endPoint, nptData);
}

/**
 * saveNonProjectTask - Save NonProjectTask
 * @returns {Object}  Object of saved details of NonProjectTask
 * @param task
 */
export function saveNonProjectTask(task) {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/save';
  return http.post(endPoint, task);
}

/**
 * editNonProjectTask - Edit NonProjectTask
 * @returns {Object}  Object of Edited details of NonProjectTask
 * @param task
 */
export function editNonProjectTask(task,taskId) {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/edit/'+taskId;
  return http.put(endPoint, task);
}


/**
 * getallactiveusers - Get all active users
 * @returns {array} - returns all active users 
 */
export function getallactiveusers() {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/getallactiveusers';
  return http.get(endPoint);
}

/**
 * saveBulkResourceAddition - Saves resource to all non project tasks selected
 * @param nonProjectTaskArray 
 * @returns {object} newly created record for bulk resource addition for all the non project task/s
 */
export function saveBulkResourceAddition(nonProjectTaskArray) {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/saveBulkResourceAddition';
  return http.post(endPoint, nonProjectTaskArray);
}

/**
 * saveBulkApproverAddition - Save approvers to all non project tasks selected
 * @param nonProjectTaskArray 
 * @returns {object} newly created record for bulk approver addition for all the non project task/s
 */
 export function saveBulkApproverAddition(nonProjectTaskArray) {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/saveBulkApproverAddition';
  return http.post(endPoint, nonProjectTaskArray);
}

/**
 * saveBulkApproverRemoval - remove approvers from all non project tasks selected
 * @param nonProjectTaskArray 
 * @returns {object} newly updated record for bulk approver removal for all the non project task/s
 */
 export function saveBulkApproverRemoval(nonProjectTaskArray) {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/saveBulkApproverRemoval';
  return http.post(endPoint, nonProjectTaskArray);
}

/**
 * getAllocatedDaysNonProjectTasks - get Resource Allocated Days for Non Project Tasks
 * @param resObj 
 * @returns {object} get Resource Allocated Days for Non Project Tasks
 */
 export function getAllocatedDaysNonProjectTasks(resObj) {
  const endPoint = apiUrl + '/timeSheetManagement/nonprojecttasks/getAllocatedDaysNonProjectTasks';
  return http.post(endPoint, resObj);
}

/**
 * getFilteredNonProjectTasks - get filtered Non Project Tasks
 * @param task
 * @returns {object} Filtered non project task names
 */

export function getFilteredNonProjectTasks(task){
  return http.post(`${apiUrl}/timeSheetManagement/nonprojecttasks/tasks`, task);
}

/**
 * getFilteredNonProjectGroups - get filtered Non Project Groups
 * @param task
 * @returns {object} Filtered non project group names
 */

export function getFilteredNonProjectTaskGroups(group) {
  return http.post(`${apiUrl}/timeSheetManagement/nonprojecttasks/groups`, group);
}

/**
 * getFilteredNonProjectGroups - get filtered Non Project Groups
 * @param params
 * @returns {object} Filtered non project task names
 */

 export function getFilteredNonProjectTaskNames(params) {
  return http.post(`${apiUrl}/timeSheetManagement/nonprojecttasks/taskNames`, params);
}

/**
 * getNonProjectTaskById - get Non Project task by given IDs
 * @param taskIds
 * @returns {object} Non Project task that belongs to given IDs
 */

export function getNonProjectTaskById(taskIds) {
  return http.post(`${apiUrl}/timeSheetManagement/nonprojecttasks/nonProjectTask`, {taskIds})
}

/**
 * getNonProjectTaskExcel - get all non project task details by given filters
 * @param params
 * @returns {array} Non Project task details
 */


export function getNonProjectTaskExcel(params) {
  return http.post(`${apiUrl}/timeSheetManagement/nonprojecttasks/getallnonprojecttasks/excel`, params)
}

/**
 * getNonProjectTaskExcel - get all non project task details by given filters
 * @param params
 * @returns {array} Non Project task details
 */


export function getFilteredTask(params) {
  return http.post(`${apiUrl}/timeSheetManagement/nonprojecttasks/filteredTask`, params)
}