import { Grid, GridColumn as Column, GridDetailRow } from "@progress/kendo-react-grid";
import ResourceRequestStatusCell from "./ResourceRequestStatusCell";
import React from "react";
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import ResourceRequestSubLineActionCell from '../EditSubLineActionCell';
import SublineStatusCell from '../SublineStatusCell';
import { Button } from '@progress/kendo-react-buttons';
import ResourceRequestStatusListCell from './ResourceRequestStatusListCell';
import AllocatedResourceCell from './AllocatedResourceCell';
import ResourceRequestSubLineStartDateCell from "./ResourceRequestSubLineStartDateCell";
import MoreDetailsSubline from './MoreDetailsSubline';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ResourceRequestSubLineEndDateCell from "./ResourceRequestSubLineEndDateCell";
import AllocationPercentageCell from './AllocationPercentageCell';


class GridSubLineCell extends GridDetailRow {

  constructor(props) {
    super(props);

    this.state = {
      showEditSubLineDialog: false,
      mainLineObj: this.props.dataItem,
      submoredetails: false,
      visibleLineStatus: false
    }

    this.toggleMoreDialog = this.toggleMoreDialog.bind(this);
    this.toggleLineStatusDialog = this.toggleLineStatusDialog.bind(this);

  }

  onClickMore = async (dataItem) => {
    this.toggleMoreDialog(dataItem);

  }

  toggleMoreDialog(dataItem) {
    this.setState({
      submoredetails: !this.state.submoredetails,
      dataItem: dataItem
    });
  }

  toggleLineStatusDialog(dataItem) {
    this.setState({
      visibleLineStatus: !this.state.visibleLineStatus,
      dataItem: dataItem
    });
  }

  handleSubLineSelect = (dataItem) => {
    if (dataItem) {
      this.props.onSubLineSelect(dataItem);
    }
  }

  handleSubLineEdit = (dataItem) => {
    if (this.state.mainLineObj.Project) {
      this.state.mainLineObj.PaymentMethod = this.state.mainLineObj.Project.PaymentMethodRecord
    } else {
      this.state.mainLineObj.PaymentMethod = this.state.mainLineObj.ChangeRequest.PaymentMethod
    }
    if (dataItem) {
      this.props.onSublineEdit(dataItem, this.state.mainLineObj);
    }
  }

  onClickLineStatus = (dataItem) => {
    this.props.onClickLineStatus(dataItem);
  }

  toggleDialogResourceSearch = (dataItem) => {
    this.props.toggleDialogResourceSearch(dataItem, "OPEN");
  };

  toggleDialogAllocationPercentage = (dataItem) => {
    this.props.toggleDialogAllocationPercentage(dataItem);
  };

  onClickAllocationHistory = (dataItem) => {
    this.props.onClickAllocationHistory(dataItem);
  }

  rowRenderSubSB(subElement, props) {
    const isSubSoftBooking = props.dataItem.IsSoftBookingResource ? true : false
    const isEditItem = props.dataItem.IsAllocatedLine ? true : false
    const className = 'sub-soft-booking-style';
    const edititemclass = 'edit-item-style';
    const isPreviousCmsResource = props.dataItem.IsPreviousCmsResource ? true : false;
    const prevCMSClassName = 'sub-prev-cms-style';
    const subSbProps = { class: isPreviousCmsResource ? prevCMSClassName : isSubSoftBooking ? className : (isEditItem ? edititemclass : null) };
    return React.cloneElement(subElement, { ...subSbProps }, subElement.props.children);
  }

  render() {
    const subdata = this.props.dataItem;
    let finalSub = []
    const subLine = this.props.dataItem.ResourceRequestSubLine && this.props.dataItem.ResourceRequestSubLine.length > 0 ? this.props.dataItem.ResourceRequestSubLine : [];

    if (subLine && subLine.length > 0) {
      for (let sub of subLine) {
        let resourceObj = JSON.parse(JSON.stringify(sub));
        resourceObj.name = subdata.name;
        finalSub.push(resourceObj)
      }
    }

    if (finalSub) {
      return (
        <>
          {finalSub.length > 0 ? (
            <div className="subline-row">

              <Grid data={finalSub}
                selectedField="selected"
                onSelectionChange={this.handleSubLineSelect}
                rowRender={this.rowRenderSubSB}
              >
                <Column
                  field="selected"
                  width="41px"
                  headerSelectionValue={
                    finalSub.findIndex(dataItem => dataItem.selected === false) === -1
                  } />
                <Column
                  field=""
                  width="145px"
                  cell={props => (<SublineStatusCell {...props} />)}
                />
                <Column
                  field="dataValueCostDesignation.Name"
                  width="140px"
                />

                <Column
                  field="StartDate"
                  width="100px"
                  cell={props => (<ResourceRequestSubLineStartDateCell {...props} projectStartDate={this.props.projectStartDate} calculateNewEstimatedEndDate={this.props.calculateNewEstimatedEndDate} props={this.props} />)}
                />
                <Column
                  field="EndDate"
                  width="100px"
                  cell={props => <ResourceRequestSubLineEndDateCell {...props} />} 
                />
                <Column
                  field="EffortDays"
                  width="100px"
                />
                <Column
                  field=""
                  width="100px"
                  cell={props => (<AllocationPercentageCell {...props}
                    action={this.props.action} 
                    toggleDialogAllocationPercentage={this.toggleDialogAllocationPercentage} 
                  />)}
                />
                <Column
                  field="AllocatedResource.Resource.Name"
                  width="150px"
                />
                <Column
                  field="More"
                  width="150px"
                  cell={props => (<MoreDetailsSubline {...props} action={this.props.action}  onClickMore={this.onClickMore} onClickLineStatus={this.onClickLineStatus} onHandleSubLineEdit={this.handleSubLineEdit} onClickAllocationHistory={this.onClickAllocationHistory} />)}
                />


              </Grid>


            </div>
          ) : (
              <div>{''}</div>
            )}

          {this.state.submoredetails && <Dialog title={"More Details"} onClose={this.toggleMoreDialog} width={900}>

            <div className="row my-3">
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Status:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.Status}</label>
                </div>
              </div> 
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Project Role:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.dataValueProjectRole.Name}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Designation (Cost):</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.dataValueCostDesignation.Name}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Resource Region:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.dataValueResourceRegion.Name}</label>
                </div>
              </div>

            </div>
            <div className="main-seperator"></div>

            <div className="row my-3">
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Project / CR:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.name}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Allocated Resource:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.AllocatedResource ? this.state.dataItem.AllocatedResource.Resource.Name : ""}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Allocated Designation:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.AllocatedDesignation}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Allocated Region:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.AllocatedRegion ? this.state.dataItem.AllocatedRegion.Name : ""}</label>
                </div>
              </div>
              
              
              
              
            </div>
            <div className="main-seperator"></div>

            <div className="row my-3">
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Start Date:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.StartDate}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">End Date:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.EndDate}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Effort Days:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.EffortDays && this.state.dataItem.EffortDays > 0 ? this.state.dataItem.EffortDays.toFixed(2) : 0}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Allocation (%):</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.CurrentAllocationPercentage}</label>
                </div>
              </div>



            </div>
            <div className="main-seperator"></div>

            <div className="row my-3">

              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Rate Type:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.RateType && this.props.RateTypes.filter(x=>x.id === this.state.dataItem.RateType)[0].Name}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Revenue:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.Revenue}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Cost:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.Cost}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Cost to Revenue Ratio:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.CostToRevenueRatio}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Onsite / Offshore:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.OnsiteStatus}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Billing Method:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.PaymentMethodRecord ? this.state.dataItem.PaymentMethodRecord.Name : ''}</label>
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleMoreDialog}>Close</button>
            </DialogActionsBar>
          </Dialog>}

          {this.state.visibleLineStatus &&
          <Dialog width={1100} title={"Line Status"} onClose={this.toggleLineStatusDialog}>
            <div className="row">
              <div className="col-md-3">
                <div className="d-block"><label>Designation:</label></div>
                <div className="d-block">
                  <Input disabled={true} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label>Allocated Resource:</label></div>
                <div className="d-block">
                  <Input disabled={true} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label>Allocation:</label></div>
                <div className="d-block">
                  <Input disabled={true} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label>Line Status:</label></div>
                <div className="d-block">
                  <Input disabled={true} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="d-block"><label>Start Date:</label></div>
                <div className="d-block">
                  <Input disabled={true} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label>End Date:</label></div>
                <div className="d-block">
                  <Input disabled={true} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label>Effort:</label></div>
                <div className="d-block">
                  <Input disabled={true} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Grid
                  data={this.state.lineStatusHistoryList}
                  style={{ height: '150px' }}
                >
                  <Column
                    field="ToStatus"
                    title="Status"
                    cell={props => (<ResourceRequestStatusCell {...props} />)}
                    width="140px"
                    maxLength={3}
                  />
                  <Column field="updatedUser" title="User" />
                  <Column field="statusChangedAt" title="Date and Time" />
                  <Column field="Comment" title="Comment" />
                </Grid>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleLineStatusDialog}>Close</button>
            </DialogActionsBar>
          </Dialog>
        }
        </>
      );
    }
  }
}

export default GridSubLineCell;
