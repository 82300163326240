import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * getCostCardDesignations - retrieves all the records from the cost card designations table
 * @returns {array}  array of records from the costcarddesignations table
 */
export function getCostCardDesignations() {
  const endPoint = apiUrl + '/masterData/designation/costcardDesignations';
  return http.get(endPoint);
}
/**
 * saveActivatedDesignationsCostCard - saves the activated designation id to the cost cards which
 * were created when the particular designation was deactivated
 * @returns {object}  the saved record
 * @param params
 */
export function saveActivatedDesignationsCostCard(params) {
  const endPoint = apiUrl + '/masterData/designation/saveActiveDesignations/costcard';
  return http.post(endPoint, params);
}

/**
 * getRateCardRoles - retrives all the records in the ratecardroles table
 * @returns {array} an array of the records in the ratecardroles table
 */
export function getRateCardRoles() {
  const endPoint = apiUrl + '/masterData/designation/get/ratecardroles';
  return http.get(endPoint);
}

/**
 * saveActivatedDesignationRateCard- saves the activated designation id to the rate cards which
 * were created when the particular designation was deactivated
 * @returns {object}  the saved record
 * @param params
 */
export function saveActivatedDesignationRateCard(params) {
  const endPoint = apiUrl + '/masterData/designation/savedesignations/ratecard/';
  return http.post(endPoint, params);
}

/**
 * getDesignations - retrieves records of Designations
 * @returns {array}  array of Designations records
 */
export function getDesignations() {
  const endPoint = apiUrl + '/masterData/designation/designations';
  return http.get(endPoint);
}

/**
 * getAllDesignations - retrieves all the records of Designations
 * @returns {array}  array of Designations records
 */
export function getAllDesignations() {
  const endPoint = apiUrl + '/masterData/designation/allDesignations';
  return http.get(endPoint);
}

/**
 * searchDesignations - search Designations by parameters
 * @param params
 * @returns {array}  array of details of Designations on a grid
 */
export function searchDesignations(params) {
  const endPoint = apiUrl + '/masterData/designation/designations/search';
  return http.post(endPoint, params);
}

/**
 * saveDesignation - Save Designations
 * @param params
 * @returns {array}  array of saved details of Designations
 */
export function saveDesignation(params) {
  const endPoint = apiUrl + '/masterData/designation/designations/save';
  return http.post(endPoint, params);
}

/**
 * Update Designation by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param designation
 * @param id
 */
export function updateDesignation(designation, id) {
  const updateDesigEndpoint = apiUrl + '/masterData/designation/designations/update/' + id;
  return http.put(updateDesigEndpoint, designation);
}

/**
 * Update Designation status by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param IsActive
 * @param id
 */
export function updateDesignationStatus(IsActive, id) {
  const endPoint = apiUrl + '/masterData/designation/designations/updateStatus/' + id;
  return http.put(endPoint, IsActive);
}

/**
 * getSkillArea - retrieves records of SkillArea
 * @returns {array}  array of SkillArea records
 */
export function getSkillArea() {
  const endPoint = apiUrl + '/masterData/designation/skillAreas';
  return http.get(endPoint);
}
