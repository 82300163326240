import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import {
  DropDownList,
  MultiSelect,
  ComboBox
} from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Redirect } from 'react-router';

import {
  getAllCountriesWithoutARegion,
  saveRegion,
  updateRegion
} from './RegionService';

import { getCurrencies } from '../common/CommonService'

import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../../src/integration/LoggerService";
import {LOG_TYPES} from "../../../src/integration/IntegrationEnums";
import { getInvoiceCycles } from '../../projectManagement/invoice/InvoiceService';

class CreateEditRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Countries: [],
      RegionsAll: [],
      Currencies: [],
      Country: null,
      skip: 0,
      take: 20,

      regionName: '',

      countriesCovered: [],
      costRegion: false,
      currencyOfTheRegion: '',
      regionId: '',
      selectedCountries: [],
      defaultCountry: '',

      visible: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      toRegion: false,
      showCancelConfirmation: false,
      invoiceCycleData: [],
      invoiceCycleValue: null
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setHeaderTitle();
    this.populateCurrencies();
    await this.populateInvoiceCycles();

    this.populateCountriesWithoutaRegion();
    if (this.props.location.action === 'edit') {
      this.populateRegionDetails(this.props.location.region);
    }
  }

  populateCountriesWithoutaRegion = () => {
    getAllCountriesWithoutARegion()
      .then(res => {
        this.setState({
          Countries: res.data,
          RegionsAll: res.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCurrencies = () => {
    getCurrencies()
      .then(res => {
        this.setState({
          Currencies: res.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateInvoiceCycles = async() => {
    await getInvoiceCycles().then(async response => {
      await this.setState({
        invoiceCycleData: response.data
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  }

  setHeaderTitle = async () => {
    if (this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Region');
    }
  };

  render() {
    if (this.state.toRegion === true) {
      return <Redirect to="/admin/region/region"/>;
    }
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-9">
            <div className="main-heading">Add New/Edit Region</div>
          </div>
        </div>

        <div className="row">

          <div className="col-md-2">
            <div className="d-block">
                <label className="mandatory">Region Name:</label>
              </div>
              <div className="d-block">
              <div className="" id="regionnameToolTip">
                <Input
                  name="regionName"
                  autoComplete="off"
                  value={this.state.regionName}
                  required={true}
                  onChange={this.handleChangeRegionName}
                  type="text"
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.regionName)}>
                    Region Name is mandatory
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="d-block">
                <label className="mandatory">Currency:</label>
              </div>
              <div className="d-block">
              <div className="" id="regionnameToolTip">
                <ComboBox
                  data={this.state.Currencies}
                  textField="Code"
                  dataItemKey="id"
                  required={true}
                  defaultValue={this.state.currencyOfTheRegion}
                  onChange={this.handleCurrencyChange}
                  placeholder="Please Select"
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span
                    className={this.validateProperty(
                      this.state.currencyOfTheRegion
                    )}
                  >
                    Currency is mandatory if you dont check the 'Cost Region'
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="d-block">
                <label className="mandatory">Countries Covered:</label>
              </div>
              <div className="d-block">
              <div className="" id="regionnameToolTip">
                <MultiSelect
                  data={this.state.Countries}
                  dataItemKey="id"
                  textField="Name"
                  required={true}
                  onChange={this.handleCountriesChange}
                  defaultValue={this.state.countriesCovered}
                  placeholder="Please Select"
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span
                    className={this.validateProperty(
                      this.state.selectedCountries.length
                    )}
                  >
                    Select the countries covered
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="d-block">
                <label className="mandatory">Default Country:</label>
              </div>
              <div className="d-block">
              <div className="" id="regionnameToolTip">
                <DropDownList
                  data={this.state.Countries}
                  dataItemKey="id"
                  textField="Name"
                  required={true}
                  onChange={this.handleDefaultCountryChange}
                  defaultValue={this.state.defaultCountry}
                  placeholder="Please Select"
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span
                    className={this.validateProperty(
                      this.state.defaultCountry
                    )}
                  >
                    Select a Default Country!
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="d-block">
                <label className="mandatory">Invoicing Cycle:</label>
              </div>
              <div className="d-block">
              <div className="">
              <ComboBox
                  name="invoiceCycleValue"
                  textField="name"
                  dataItemKey="value"
                  value={this.state.invoiceCycleValue}
                  data={this.state.invoiceCycleData}
                  onChange={this.handleInvoiceCycleChange}
                  required={true}
                  placeholder="Please Select"
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span
                    className={this.validateProperty(
                      this.state.invoiceCycleValue
                    )}
                  >
                    Invoicing cycle cannot be empty.
                  </span>
                ) : null}
              </div>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-md-3">
            <div className="d-block">
                <label>Cost Region:</label>
              </div>
              <div className="d-block">
              <div className=" k-form-field modified">
                <input
                  type="checkbox"
                  id="ch2"
                  className="k-checkbox"
                  name="costRegion"
                  value={this.state.costRegion}
                  checked={this.state.costRegion}
                  onChange={this.handleCostRegionCheckbox}
                />
                <label className="k-checkbox-label" htmlFor="ch2">
                  Yes
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 btn-align-right">
            <Button primary={true} type="submit" onClick={this.handleSubmit}>
              Save
            </Button>
            <Button type="button" onClick={this.onClickCancel}>
              Cancel
            </Button>
          </div>
        </div>
        <div>
          {this.state.visible === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

              {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success"/>
                </div>
              )}

              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image"/>
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

              <DialogActionsBar>
                {this.state.showSuccessImage === true && (
                  <button className="k-button modal-primary" onClick={this.navigateBack}>
                    OK
                  </button>
                )}

                {this.state.showErrorImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleDialog}>
                    OK
                  </button>
                )}
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <div>
          {this.state.showCancelConfirmation === true && (
            <Dialog
              title="Please Confirm"
              onClose={this.toggleCancelConfirmDialog}
              width="300px"
            >
              <p style={{ margin: '25px', textAlign: 'center' }}>
                This form contains unsaved changes. Do you want to close it?
              </p>
              <DialogActionsBar>
                <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                  No
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={this.navigateBack}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
      </div>
    );
  }

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };

  navigateBack = event => {
    this.setState({
      toRegion: true
    });
  };

  toggleCancelConfirmDialog = () => {
    this.setState({
      showCancelConfirmation: !this.state.showCancelConfirmation
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true
      });
      this.setState({
        visible: true,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog(
        'Please fix the highlighted errors to continue',
        'Error'
      );
    } else {
      this.setState({
        isErrorMessageVisible: false
      });
      if (this.props.location.action === 'edit') {
        this.updateRegion(event);
      } else {
        this.createRegion(event);
        this.setState({
          isDisableSave: true
        });
      }
    }
  };

  updateRegion = event => {
    const region = {
      RegionID: this.state.regionId,
      Name: this.state.regionName,
      updatedAt: new Date(),
      CostRegion: this.state.costRegion,
      CurrencyId: this.state.currencyOfTheRegion,
      updatedUser: window.LOGGED_USER.displayName,
      selectedCountries: this.state.selectedCountries,
      DefaultCountryId: this.state.defaultCountry.id,
      InvoiceCycleId: this.state.invoiceCycleValue ? this.state.invoiceCycleValue.value : null
    };
    updateRegion(this.state.regionId, region)
      .then(res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog(`The Region was successfully updated`, 'Success');
        return <Redirect to="/admin/region/region"/>;
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          isDisableSave: false,
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  createRegion = event => {
    const region = {
      Name: this.state.regionName,
      createdAt: new Date(),
      updatedAt: new Date(),
      CostRegion: this.state.costRegion,
      CurrencyId: this.state.currencyOfTheRegion,
      IsActive: true,
      updatedUser: window.LOGGED_USER.displayName,
      selectedCountries: this.state.selectedCountries,
      DefaultCountryId: this.state.defaultCountry.id,
      InvoiceCycleId: this.state.invoiceCycleValue ? this.state.invoiceCycleValue.value : null
    };

    saveRegion(region)
      .then(res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog(
          `The Region ${res.data.Name} was successfully created`,
          'Success'
        );
        return <Redirect to="/admin/region/region"/>;
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleDialog(error.response.data, 'Error');
        this.setState({
          isDisableSave: false
        });
      });
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  validation = () => {
    if (
      this.validateProperty(this.state.regionName)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.costRegion)
        .toString()
        .includes('error') &&
      this.validateProperty(this.state.currencyOfTheRegion)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.selectedCountries.length)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.defaultCountry).toString().includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.invoiceCycleValue).toString().includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  populateRegionDetails = region => {
    let invoiceCycleValue = null;
    if (region.InvoiceCycleId) {
      invoiceCycleValue = this.state.invoiceCycleData.filter(val => val.value === region.InvoiceCycleId);
    }
    if (region) {
      this.setState({
        regionId: region.id,
        regionName: region.Name,
        costRegion: region.CostRegion,
        countriesCovered: region.Country,
        selectedCountries: region.Country,
        currencyOfTheRegion: region.Currency,
        defaultCountry: region.DefaultCountry,
        invoiceCycleValue: invoiceCycleValue ? invoiceCycleValue[0] : null,
      });
    }
  };

  handleChangeRegionName = async event => {
    await this.setState({ regionName: event.target.value, isFormDirty: true });
  };

  handleCostRegionCheckbox = async event => {
    await this.setState({
      costRegion: event.target.checked,
      isFormDirty: true
    });
  };

  handleCountriesChange = async event => {
    await this.setState({
      selectedCountries: event.target.value,
      isFormDirty: true
    });
  };

  handleDefaultCountryChange = async event => {
    await this.setState({
      defaultCountry: event.target.value,
      isFormDirty: true
    });
  };

  handleInvoiceCycleChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    await this.setState({
      [name]: value,
      isFormDirty: true,
    });
  };

  handleCurrencyChange = async event => {
    await this.setState({
      currencyOfTheRegion: event.target.value,
      isFormDirty: true
    });
  };
}

export default CreateEditRegion;
