import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;
const apiBase = apiUrl + '/projectManagement/changeRequest/';

/**
 * Get a list of details of ChangeRequest.
 * @param changeReqId
 * @returns {array} ChangeRequest
 */
export function getChangeRequest(changeReqId) {
    const endPoint = apiBase + changeReqId;
    return http.get(endPoint);
}

/**
 * Update a ChangeRequest.
 * @param project
 * @param id
 * @returns {array} ChangeRequest
 */
export function updateChangeReq(project, id) {
    const endPoint = apiBase + 'update/' + id;
    return http.put(endPoint, project);
}

/**
 * Save a ChangeRequest.
 * @param changeReq
 * @returns {array} ChangeRequest
 */
export function saveChangeReq(changeReq) {
    return http.post(apiBase, changeReq);
}

/**
 * Get all Unique Ids.
 * @returns {array} array object of Projects.
 */
export function getAllCRUniqueIds() {
    const endPoint = apiBase + '/all/unique-id-CR';
    return http.get(endPoint);
}

/**
 * Get the ChangeRequest of a project.
 * @param projectId
 * @returns {array} ChangeRequest
 */
export function getCRsOfAProject(projectId) {
    const endPoint = apiBase + 'byProject/' + projectId;
    return http.get(endPoint);
}

/**
 * Create Initial CM sheet for CR.
 * @param changeReqID
 * @param cmSheetCreatedDate
 * @returns {array} ChangeRequest
 */
export function createInitialCMsheet(changeReqID, cmSheetCreatedDate) {
    const endPoint = apiBase + changeReqID + '/cmsheet';
    return http.post(endPoint, cmSheetCreatedDate);
}

/**
 * Delete a CM sheet of CR.
 * @param changeReqID
 * @returns {array} list of ChangeRequest Details
 */
export function deleteChangeReq(changeReqID) {
    const endPoint = apiBase + changeReqID;
    return http.delete(endPoint);
}

/**
 * Get All ChangeRequests
 * @param ChangeRequests - (object)
 * @returns List of All ChangeRequests Details.
 */
export function getAllChangeRequests(ChangeRequests) {
    const endPoint = apiBase + '/getAllChangeRequests';
    return http.post(endPoint, ChangeRequests);
  }
