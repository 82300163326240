import React, { Component } from 'react';
const ApprovalStatus = require('./ApprovalStatusChangeEnums');

class ApprovalStatusCell extends Component {

  render() {
    const value = this.props.dataItem;

    if(value.ApprovalStatus && value.ApprovalStatus !== null){
        return <td>{ApprovalStatus[value.ApprovalStatus].name}</td>;
    }else
      return <td>{''}</td>;
  }
}

export default ApprovalStatusCell;
