import React, { Component } from "react";
import Button from '@progress/kendo-react-buttons/dist/es/Button';

class MoreDetailsCell extends Component {

  onClickMore = event => {
    event.preventDefault();
    this.props.onClickMore(this.props.dataItem);
  };

  onClickLineStatus = event => {
    event.preventDefault();
    this.props.onClickLineStatus(this.props.dataItem);
  };

  onClickAllocationHistory = event => {
    event.preventDefault();
    this.props.onClickAllocationHistory(this.props.dataItem);
  };

  render() {
    return (
      <td className="action-buttons">
        <a href="#" onClick={this.onClickMore}>
          <div className="rounded-link">
            <span className="k-icon k-i-insert-middle" title="More Details" />
          </div>
        </a>
        <a href="#" onClick={this.onClickLineStatus}>
                    <div className="rounded-link">
                        <span className="k-icon k-i-info" title="Line Status" />
                    </div>
        </a>
        {this.props.action && (this.props.action === 'create' || this.props.action === 'view') && (
          <a href="#" onClick={this.onClickAllocationHistory}>
            <div className="rounded-link">
                <span className="k-icon k-i-clock" title="Allocation (%) History" />
            </div>
        </a>
        )}        
      </td>
    );
  }

}

export default MoreDetailsCell;
