import React, { Component } from 'react';
import { NumericTextBox, Input } from '@progress/kendo-react-inputs';
import { ComboBox, DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem, PanelBarUtils, Menu, MenuItem, MenuItemModel, MenuItemLink, MenuItemArrow, Splitter } from '@progress/kendo-react-layout';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router';
import '../Spark.css';
import AvatarUploader from 'react-avatar-uploader';
import {
    getAllCountriesWithoutARegionIntegrated,
    getRegionsWithCountriesIntegrated,
    getBillingDiviIntegrated,
    getResourceRegionsIntegrated,
    deleteMultiYearGridInfoIntegrated,
    getRegionInfoIntegrated,
    getActiveResourceRegionsIntegrated,
    getskillAreaIntegrated,
    getPracticeTypesIntegrated,
    getAllPracticeDivisionMapIntegrated,
    getActiveAllocatedRegionsIntegrated
} from "../integration/MasterDataService";
import * as loggerService from "../integration/LoggerService";
import { LOG_TYPES } from "../integration/IntegrationEnums";
import { getAllDesignations } from "../masterData/designation/DesignationService";
import { filterBy, orderBy } from "@progress/kendo-data-query";
import { getDeployableStatus, getDivision, getEmploymentType, getDeliveryModels, getLegalEntityTypes } from "../resourceManagement/ResourceService";
import { getUserList, getUserListActive } from "../userManagement/user/userService";
import { replaceFile, uploadFile } from '../integration/fileUpload/FileUploadService'
import { Tooltip } from "reactstrap";
import moment from "moment";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import DeleteCell from "../resourceManagement/DeleteCell";
import { getUserProfile, saveUserProfile, updateUserProfile } from "./UserProfileService";
import ReactQuill from "react-quill";
import { deleteCMSFile } from "../projectManagement/cmSheet/CMSheetService";
import UploadedFileCell from '../resourceManagement/components/UploadedFileCell';
import EditDeployabilityActionCell from '../resourceManagement/components/EditDeployabilityActionCell';
import UpdatedDateCell from "../masterData/region/Region";
import Loader from '../integration/components/Loader';

const apiUrl = require('../../src/config').get(process.env.REACT_APP_ENV).apiUrl;

const billingDiviDataAll = [];
const DeployableStatusAll = [];
const EmploymentTypeAll = [];
const DivisionAll = [];
const activeUserAll = [];
const SelectedCountryAll = [];
const CountryAll = [];
const RegionAll = [];
const skillAreasAll = [];
const timeEntryRequiredAll = ["Yes","No"];
const practiceDataAll = [];
const deliveryModelAll = [];
const legalEntityTypeAll = [];
const allocatedRegionsAll = [];
const contractTypeAll = [{ id: 1, Name: 'Fixed Price' }, { id: 2, Name: 'Time and Material' }];

class EmployeeUserProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            userId: '',
            firstName: "",
            middleName: "",
            lastName: "",
            designation: "",
            employmentType: "",
            division: "",
            //Practice: "",
            billingRegions: [],
            DeployableStatusPercentage: 0,
            Region: "",
            generalCountry: "",
            lineManager: "",
            passport: null,
            passportImgId: "",
            cvImgId: "",
            country: "",
            VisaNumber: '',
            PlaceOfIssue: '',
            IssueDate: null,
            ExpiryDate: null,
            expiryDateMin: null,
            ExpiryDatePassport: null,
            deployabilityStartDate: new Date(),
            minDeployabilityStartDate: new Date(),
            previousRecordEndDate: null,

            selected: 0,
            users: {},
            DisplayName: "",

            //drop down data
            Designations: [],
            regions: [],
            AllCountries: [],
            countryItems: [],
            allBillingRegions: [],
            regionData: [],
            regionNamesMap: {},
            DesignationsAll: [],
            DeployableStatus: [],
            EmploymentType: [],
            //Division: [],
            activeUser: [],
            userAllVisaDetails: [],
            billingDiviData: billingDiviDataAll.slice(),
            issueDate: null,
            expiryDate: null,
            rowCount: 0,
            resourceRegions: [],
            sort: [
                { field: '', dir: 'desc' }
            ],
            isErrorMessageVisible: false,
            isDisableSave: false,
            showMessageDialog: false,
            showToggleDialog: false,
            dialogMessage: '',
            dialogTitle: '',
            IdListArray: [],
            selectedFile: null,
            selectedPassportFile: '',
            selectedAvatarFile: '',
            selectedCvFile: '',

            //drop down selected data
            SelectedDesignation: null,
            SelectedBillingDivision: null,
            //SelectedDivision: null,
            SelectedEmploymentType: null,
            SelectedDeployableStatus: null,
            SelectedUser: null,
            selectedResourceRegion: null,
            GeneralSelectedCountry: null,
            CountriesAll: null,
            visaNoToolTipOpen: false,
            placeOfIssueToolTipOpen: false,
            ResourceId: '',
            userVisaDeletedDetails: [],
            redirectToUserSearch: false,
            updateOrSave: false,
            HrDesignation: '',
            Department: '',
            PracticeNew: '',
            DeliveryModel: '',
            LegalEntity: '',
            EmploymentTypeNew: '',
            permissions: {
                DeployableStatusPercentage: false,
                //SelectedBillingDivision: false,
                GeneralCountry: false,
                ExpiryDate: false,
                ExpiryDatePassport: false,
                area: false
            },

            // other
            skip: 0,
            take: 20,
            isVisaNoErrorMessageVisible: false,
            isVisaCountryErrorMessageVisible: false,
            isVisaIssueDateErrorMessageVisible: false,
            isVisaExpiryDateErrorMessageVisible: false,
            isPassportExpiryDateErrorMessageVisible: false,

            showFileReplaceConfirmDialog: false,
            confirmReplaceDialogMessage: '',
            replaceBtnDisabled: false,
            isCannotUpload: true,
            isCannotUploadPassport: true,
            skillAreas: [],
            selectedSkillArea: [],
            isSkillAreaError: false,
            deployHistoryDialog: false,
            deployabilityHistory: [],
            initialDeployabilityHistory: [],
            currentDeployableStatus: '',
            currentDeployablePercentage: '',
            isTimeEntryRequired: false,
            selectedTimeEntryRequired: null,
            timeEntryRequired: timeEntryRequiredAll,
            loader: false,
            preEmploymentType: null,
            preDesignation: null,
            isDeployabilityFormDirty: false,
            showDeployabilityCancelConfirmation: false,
            hrDesgToolTipOpen: false,
            depToolTipOpen: false,
            pracToolTipOpen: false,
            delModToolTipOpen: false,
            legalToolTipOpen: false,
            employTypeToolTipOpen : false,
            costDesgToolTipOpen: false,
            practiceData: [],
            SelectedPractice: null,
            legalEntityType: [],
            deliveryModel: [],
            SelectedDeliveryModel: null,
            SelectedLegalEntity: null,
            practiceDivisionMap: [],
            selectedpracticeData: [],
            selectedAllocatedRegion: null,
            allocatedRegions: [],
            PMDesignations: [],
            PMDesignationsAll: [],
            contractTypes: contractTypeAll,
            selectedContractType: null
        }

        this.toggleDeployHistoryDialog = this.toggleDeployHistoryDialog.bind(this);
        this.saveDeployHistoryDialog = this.saveDeployHistoryDialog.bind(this);
        this.cancelDeployHistoryDialog = this.cancelDeployHistoryDialog.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.isMount = true;
        this.setHeaderTitle();

        if (this.props.location.action) {
            this.setState({
                action: this.props.location.action,
                user: this.props.location.user
            }, () => {
                this.handleUserCreateEdit();
            });
        }
    }

    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('User Profile');
        }
    };

    componentWillUnmount() {
        this.isMount = false;
    }

    toggleDeployHistoryDialog = () => {
        this.setState({
            deployHistoryDialog: !this.state.deployHistoryDialog
        });
    }

    toggleHrDesignationTooltip = () => {
        this.setState({
            hrDesgToolTipOpen: !this.state.hrDesgToolTipOpen
        });
    };

    toggleDepTooltip = () => {
        this.setState({
            depToolTipOpen: !this.state.depToolTipOpen
        });
    };

    togglePracTooltip = () => {
        this.setState({
            pracToolTipOpen: !this.state.pracToolTipOpen
        });
    };

    toggleDeliModTooltip = () => {
        this.setState({
            delModToolTipOpen: !this.state.delModToolTipOpen
        });
    };

    toggleLegalEntityTooltip = () => {
        this.setState({
            legalToolTipOpen: !this.state.legalToolTipOpen
        });
    };

    toggleEmpTypeTooltip = () => {
        this.setState({
            employTypeToolTipOpen: !this.state.employTypeToolTipOpen
        });
    };

    toggleCostDesignationTooltip = () => {
        this.setState({
            costDesgToolTipOpen: !this.state.costDesgToolTipOpen
        });
    };

    saveDeployHistoryDialog = () => {
        if (this.state.isDeployabilityFormDirty) {
            this.toggleDeployabilityCancelConfirmDialog();
        } else {
            let todayFormatted = moment(new Date()).format('YYYY-MM-DD');
            let currentDeployability = Object.values(this.state.deployabilityHistory).find(c => (new Date(todayFormatted) >= new Date(c.StartDate) && c.EndDate == null || new Date(todayFormatted) >= new Date(c.StartDate) && new Date(todayFormatted) <= new Date(c.EndDate)));

            this.setState({
                initialDeployabilityHistory: this.state.deployabilityHistory,
                currentDeployableStatus: currentDeployability ? currentDeployability.DeployableStatus.name : '',
                currentDeployablePercentage: currentDeployability ? currentDeployability.DeployableStatusPercentage : '',
                deployHistoryDialog: !this.state.deployHistoryDialog,
                selectedTimeEntryRequired: currentDeployability ? currentDeployability.DeployableStatus.name === 'Fully Deployable' ? 'Yes' : this.state.selectedTimeEntryRequired : null,
                isTimeEntryRequired: currentDeployability ? currentDeployability.DeployableStatus.name === 'Fully Deployable' ? true : this.state.isTimeEntryRequired : this.state.isTimeEntryRequired,
                isDeployabilityFormDirty: false
            });
        }
    }

    cancelDeployHistoryDialog = () => {
        if (this.state.isDeployabilityFormDirty) {
            this.toggleDeployabilityCancelConfirmDialog();
        } else {
            let initialDeployabilityHistory = this.state.initialDeployabilityHistory;
            let latestDeployability = Object.values(initialDeployabilityHistory).find(c => c.EndDate == null);
            let latestMinDeployStartDate = new Date();
            if (latestDeployability && latestDeployability.StartDate > moment(new Date()).format('YYYY-MM-DD')) {
                latestMinDeployStartDate = moment(latestDeployability.StartDate).add(1, "days");
            }

            this.setState({
                deployabilityHistory: initialDeployabilityHistory,
                deployabilityStartDate: new Date(latestMinDeployStartDate),
                minDeployabilityStartDate: new Date(latestMinDeployStartDate),
                deployHistoryDialog: !this.state.deployHistoryDialog
            });
        }
    }

    handleUserCreateEdit = async () => {
        if (this.state.user && this.state.user.Resource) {
            await this.setState({
                loader: true,
                ResourceId: this.state.user.Resource.id,
                userId: this.state.user.id
            });
        }


        if (this.state.action) {
            // await this.populateDesignations();
            await this.populateBillingDivision();
            await this.populateDeployableStatus();
            await this.populateEmploymentType();
            //await this.populateDivision();
            await this.populateActiveUsers();
            //this.populateRegions();
            await this.regionDetails();
            await this.populateAllCountries();
            await this.populateNames();
            await this.getSkillAreas();
            await this.populatePractices();
            await this.populateLegalEntityTypes();
            await this.populateDeliveryModels();
            await this.populatePracticeDivisionMap();
            await this.getAllocatedRegions();

            if (this.state.action === 'edit_user_profile') {
                this.state.permissions.GeneralCountry = true
                await this.populateProfileDetails(this.state.user.Resource.id);
            } else {
                this.setState({loader: false});
            }
        }

    };
    handleSelect = (e) => {
        this.setState({ selected: e.selected })
    };

    populateProfileDetails = async (resourceId) => {
        await getUserProfile(resourceId).then(async response => {
            let profileDetails = response.data;
            if (profileDetails) {
                if((profileDetails[0].EmploymentTypeModel && profileDetails[0].EmploymentTypeModel.id === 1) ||
                    (profileDetails[0].BillingDivision && profileDetails[0].BillingDivision.Name === 'Project Management')){ // Check the Indirect Recruit's EmploymentType
                        await this.populateDesignations().then((Designations) => {
                        const PMDesignations = Designations.slice().filter(resource =>
                            resource.Name.includes('Manager')
                          );
                        this.setState({
                            Designations,
                            DesignationsAll: Designations,
                            PMDesignations: PMDesignations,
                            PMDesignationsAll: PMDesignations
                        })
                    });
                }
                const Designation = profileDetails[0].Designation;
                const HrDesignation = profileDetails[0].HrDesignation;
                const Department = profileDetails[0].BillingDivision;
                const Region = profileDetails[0].ResourceRegion;
                const GeneralCountry = profileDetails[0].GeneralCountryObject;
                const AllocatedRegion = profileDetails[0].AllocatedRegion;

                const PracticeNew = profileDetails[0].ResourcePractice;
                const DeliveryModel = profileDetails[0].DeliveryModel;
                const LegalEntity = profileDetails[0].LegalEntity;
                const EmploymentTypeNew = profileDetails[0].EmploymentTypeModel;

                let lineManager = null

                if (profileDetails[0].LineManagerObject) {
                    lineManager = {
                        id: profileDetails[0].LineManagerObject.id,
                        Name: profileDetails[0].LineManagerObject.displayName
                    };
                }

                const selectedPassportFile = profileDetails[0].PassportImage;
                const selectedCVFile = profileDetails[0].CVImage;
                for (const visa of profileDetails[0].Visas) {
                    visa.CountryName = visa.CountryDetails.Name;
                }
                const Avatar = profileDetails[0].AvatarImage;

                let selectedRegion = this.RegionAll.filter(region => region.id === Region.id);
                let selectedAllocatedRegion = AllocatedRegion ? this.allocatedRegionsAll.filter(region => region.id === AllocatedRegion.id) : null;
                this.SelectedCountryAll = selectedRegion[0].Country;

                if (profileDetails[0].DivisionDetails.name === "Operations") {
                    this.state.permissions.BillingDivision = true
                }

                if (profileDetails[0].DeployableDetails.name === "Partially Deployable") {
                    this.state.permissions.DeployableStatusPercentage = true
                }

                let todayFormatted = moment(new Date()).format('YYYY-MM-DD');
                let currentDeployability = Object.values(profileDetails[0].ResourceDeployability).find(c => (new Date(todayFormatted) >= new Date(c.StartDate) && c.EndDate == null || new Date(todayFormatted) >= new Date(c.StartDate) && new Date(todayFormatted) <= new Date(c.EndDate)));

                for (const deployability of profileDetails[0].ResourceDeployability) {
                    if (deployability.DeployableStatusId) {
                        deployability.DeployableStatus = Object.values(this.DeployableStatusAll).find(c => c.value === deployability.DeployableStatusId);
                    }
                }

                let latestDeployability = Object.values(profileDetails[0].ResourceDeployability).find(c => c.EndDate == null);
                let latestMinDeployStartDate = new Date();
                if (latestDeployability && latestDeployability.StartDate > moment(new Date()).format('YYYY-MM-DD')) {
                    latestMinDeployStartDate = moment(latestDeployability.StartDate).add(1, "days");
                }

                const sortedResourceDeployability = profileDetails[0].ResourceDeployability.sort((a, b) => (a.StartDate < b.StartDate) ? 1 : ((b.StartDate < a.StartDate) ? -1 : 0));

                if (profileDetails[0].IsTimeEntryRequired) {
                    await this.setState({
                        selectedTimeEntryRequired: 'Yes'
                    })
                } 
                else {
                    await this.setState({
                        selectedTimeEntryRequired: 'No'
                    })
                }

                let selectedResSkillAreas = [];
                if (profileDetails[0].ResourceSkillArea && profileDetails[0].ResourceSkillArea.length > 0) {
                    for (const skill of profileDetails[0].ResourceSkillArea) {
                        selectedResSkillAreas.push(skill.SkillArea)
                    }
                }

                let contractType = null;
                if (profileDetails[0].ContractTypeId) {
                    contractType = this.state.contractTypes.filter(val => val.id === profileDetails[0].ContractTypeId)[0]
                }

                await this.setState({
                    firstName: profileDetails[0].FirstName,
                    middleName: profileDetails[0].MiddleName,
                    lastName: profileDetails[0].LastName,
                    SelectedDesignation: Designation,
                    HrDesignation: HrDesignation ? HrDesignation.Name : '',
                    Department: Department ? Department.Name : '',
                    PracticeNew: PracticeNew ? PracticeNew.Name : '',
                    DeliveryModel: DeliveryModel ? DeliveryModel.Name : '',
                    LegalEntity: LegalEntity ? LegalEntity.Name : '',
                    // EmploymentTypeNew: EmploymentTypeNew ? EmploymentTypeNew.Name : '',
                    SelectedBillingDivision: Department,
                    SelectedEmploymentType: EmploymentTypeNew ? EmploymentTypeNew : '',
                    SelectedPractice: PracticeNew,
                    //SelectedDivision: profileDetails[0].DivisionDetails,
                    selectedResourceRegion: selectedRegion[0],
                    GeneralSelectedCountry: GeneralCountry,
                    SelectedUser: lineManager,
                    passport: profileDetails[0].PassportNo,
                    ExpiryDatePassport: profileDetails[0].ExpiryDate !== null ? new Date(profileDetails[0].ExpiryDate) : null,
                    userAllVisaDetails: profileDetails[0].Visas,
                    selectedCVFile: selectedCVFile,
                    selectedPassportFile: selectedPassportFile,
                    selectedAvatarFile: Avatar,
                    countryItems: this.SelectedCountryAll,
                    selectedSkillArea: selectedResSkillAreas,
                    deployabilityHistory: sortedResourceDeployability,
                    initialDeployabilityHistory: sortedResourceDeployability,
                    deployabilityStartDate: new Date(latestMinDeployStartDate),
                    minDeployabilityStartDate: new Date(latestMinDeployStartDate),
                    currentDeployableStatus: currentDeployability ? currentDeployability.DeployableStatus.name : '',
                    currentDeployablePercentage: currentDeployability ? currentDeployability.DeployableStatusPercentage : '',
                    userEmail:profileDetails[0].Email,
                    isTimeEntryRequired: profileDetails[0].IsTimeEntryRequired,
                    preEmploymentType: profileDetails[0].EmploymentTypeModel,
                    preDesignation: Designation,
                    preHRDesignation: HrDesignation ? HrDesignation.Name : '',
                    preSelectedUser: lineManager,
                    SelectedDeliveryModel: DeliveryModel ? DeliveryModel : null,
                    SelectedLegalEntity: LegalEntity ? LegalEntity : null,
                    selectedAllocatedRegion: selectedAllocatedRegion ? selectedAllocatedRegion[0] : null,
                    selectedContractType: contractType
                }, async() => {
                    await this.practiceDivisionMapping();
                    await this.setState({loader: false})
                });

                // if(!HrDesignation || !HrDesignation.Name || HrDesignation.Name === '') {
                //     this.setState({
                //         isDisableSave: true
                //     });
                // }
            }
        }).catch(error => {
            this.setState({loader: false})
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    handleChangeVisaNumber = async event => {
        if (this.isMount) {
            await this.setState({
                VisaNumber: event.target.value,
                isFormDirty: true,
            });
        }
        await this.validateProperty(event.target.value);
    };

    handleChangePassportNumber = async event => {
        if (this.isMount) {
            await this.setState({
                passport: event.target.value,
                isFormDirty: true,
            });
        }
        await this.validateProperty(event.target.value);
    };

    handleChangeDeployableStatusPercentage = async event => {
        if (this.isMount) {
            await this.setState({
                DeployableStatusPercentage: event.target.value,
                isFormDirty: true,
                isDeployabilityFormDirty: true
            });
        }
        await this.validateProperty(event.target.value);
    };

    handleChangeMiddleName = async event => {
        if (this.isMount) {
            await this.setState({
                middleName: event.target.value,
                isFormDirty: true,
            });
        }
        await this.validateProperty(event.target.value);
    };

    handleChangeLastName = async event => {
        if (this.isMount) {
            await this.setState({
                lastName: event.target.value,
                isFormDirty: true,
            });
        }
        await this.validateProperty(event.target.value);
    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    handleChangePlaceOfIssue = async event => {
        if (this.isMount) {
            await this.setState({
                PlaceOfIssue: event.target.value,
                isFormDirty: true
            });
        }
        await this.validateProperty(event.target.value);
    };

    populateNames = () => {
        getUserList().then(response => {
            for (const user of response.data) {
                if (user.id === this.state.userId) {
                    this.state.DisplayName = user.displayName;
                    if (this.state.action === 'create_user_profile') {
                        let NameArray = this.state.DisplayName.split(" ");
                        this.state.firstName = NameArray[0];
                        this.state.lastName = NameArray[1];
                        if (user.Resource && user.Resource.FirstName){
                            this.state.firstName = user.Resource ? user.Resource.FirstName : '';
                            this.state.lastName = user.Resource ? user.Resource.LastName : '';
                            this.state.middleName = user.Resource ? user.Resource.MiddleName : '';
                        }           
                    }
                }
            }
            this.setState({
            });
        })
            .catch(e => {
                loggerService.writeLog(e, LOG_TYPES.ERROR);
            });
    };

    populateDesignations = async () => {
        try {
            const {data} = await getAllDesignations();
            return data;
        } catch (error) {
            throw new Error(error);
        }
    };

    populateDeployableStatus = () => {
        getDeployableStatus()
            .then(res => {
                if (this.isMount) {
                    this.DeployableStatusAll = res.data;
                    this.setState({
                        DeployableStatus: res.data,
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateEmploymentType = () => {
        getEmploymentType()
            .then(res => {
                if (this.isMount) {
                    this.EmploymentTypeAll = res.data;
                    this.setState({
                        EmploymentType: res.data,
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateLegalEntityTypes = () => {
        getLegalEntityTypes()
            .then(res => {
                if (this.isMount) {
                    this.legalEntityTypeAll = res.data;
                    this.setState({
                        legalEntityType: res.data,
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateDeliveryModels = () => {
        getDeliveryModels()
            .then(res => {
                if (this.isMount) {
                    this.deliveryModelAll = res.data;
                    this.setState({
                        deliveryModel: res.data,
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    /*populateDivision = () => {
        getDivision()
            .then(res => {
                if (this.isMount) {
                    this.DivisionAll = res.data;
                    this.setState({
                        Division: res.data,
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };*/

    populateBillingDivision = async () => {
        await getBillingDiviIntegrated()
            .then(response => {
                this.billingDiviDataAll = response.data.filter(el => el.IsActive === true && el.SFCode !== null);;
                this.setState({
                    billingDiviData: response.data.filter(el => el.IsActive === true && el.SFCode !== null)
                });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populatePractices = () => {
        getPracticeTypesIntegrated()
          .then(res => {
            if (this.isMount) {
              this.practiceDataAll = res.data;
              this.setState({
                practiceData: res.data,
              });
            }
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      };

      populatePracticeDivisionMap = async () => {
        await getAllPracticeDivisionMapIntegrated()
            .then(response => {
                this.setState({
                    practiceDivisionMap: response.data
                });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    async regionDetails() {
        getActiveResourceRegionsIntegrated()
            .then(res => {
                this.RegionAll = res.data;
                if (this.isMount) {
                    this.setState({
                        Regions: res.data
                    });
                }

                const resourceRegion = [];
                const billingRegion = [];
                res.data.forEach(function (region) {
                    if (region.CostRegion === false) {
                        billingRegion.push(region);
                    }
                    resourceRegion.push(region);
                });

                if (this.isMount) {
                    this.setState({
                        billingRegions: billingRegion,
                        resourceRegions: resourceRegion
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    async getAllocatedRegions() {
        getActiveAllocatedRegionsIntegrated()
            .then(res => {
                this.allocatedRegionsAll = res.data;
                this.setState({ allocatedRegions: res.data })
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    async getSkillAreas() {
        getskillAreaIntegrated()
            .then(res => {
                const activeSkillAreas = res.data.filter(val => val.IsActive);
                this.skillAreasAll = activeSkillAreas;
                this.setState({ skillAreas: activeSkillAreas })
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };


    populateAllCountries = async () => {
        getAllCountriesWithoutARegionIntegrated()
            .then(res => {
                this.CountryAll = res.data;
                const countryNames = {};
                for (const country of res.data) {
                    countryNames[country.id] = country.Name;
                }
                this.setState({
                    AllCountries: res.data
                });
            })
            .catch(e => {
                loggerService.writeLog(e, LOG_TYPES.ERROR);
            });
    };

    populateCountries = countries => {
        getRegionsWithCountriesIntegrated().then(response => {
            this.SelectedCountryAll = countries;
            this.setState({
                countryItems: countries
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateActiveUsers = async () => {
        await getUserListActive()
            .then(response => {
                this.activeUserAll = response.data;
                this.setState({
                    activeUser: response.data
                });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    filterChangeCombo = event => {
        if (this.isMount) {
            clearTimeout(this.timeout);
            const field = event.target.name;
            this.timeout = setTimeout(() => {
                switch (field) {
                    case 'SelectedDesignation': {
                        this.setState({
                            Designations: this.filterComboData(event.filter, this.state.DesignationsAll),
                            PMDesignations: this.filterComboData(event.filter, this.state.PMDesignationsAll)
                        });
                        break;
                    }
                    case 'SelectedBillingDivision': {
                        this.setState({
                            billingDiviData: this.filterComboData(
                                event.filter,
                                this.billingDiviDataAll
                            )
                        });
                        break;
                    }
                    case 'SelectedPractice': {
                        this.setState({
                            selectedpracticeData: this.filterComboData(
                                event.filter,
                                this.state.selectedpracticeData
                            )
                        });
                        break;
                    }
                    case 'SelectedDeployableStatus': {
                        this.setState({
                            DeployableStatus: this.filterComboData(
                                event.filter,
                                this.DeployableStatusAll
                            )
                        });
                        break;
                    }
                    case 'SelectedEmploymentType': {
                        this.setState({
                            EmploymentType: this.filterComboData(
                                event.filter,
                                this.EmploymentTypeAll
                            )
                        });
                        break;
                    }
                    /*case 'SelectedDivision': {
                        this.setState({
                            Division: this.filterComboData(
                                event.filter,
                                this.DivisionAll
                            )
                        });
                        break;
                    }*/
                    case 'SelectedUser': {
                        this.setState({
                            activeUser: this.filterComboData(
                                event.filter,
                                this.activeUserAll
                            )
                        });
                        break;
                    }
                    case 'selectedResourceRegion': {
                        this.setState({
                            Regions: this.filterComboData(
                                // resourceRegions: this.filterComboData(
                                event.filter,
                                this.RegionAll
                            )
                        });
                        break;
                    }
                    case 'GeneralSelectedCountry': {
                        this.setState({
                            countryItems: this.filterComboData(
                                event.filter,
                                this.SelectedCountryAll
                            )
                        });
                        break;
                    }
                    case 'SelectedCountry': {
                        this.setState({
                            AllCountries: this.filterComboData(
                                event.filter,
                                this.CountryAll
                            )
                        });
                        break;
                    }
                    case 'selectedSkillArea': {
                        this.setState({
                            skillAreas: this.filterComboData(
                                event.filter,
                                this.skillAreasAll
                            )
                        });
                        break;
                    }
                    case 'selectedTimeEntryRequired': {
                        this.setState({
                            timeEntryRequired: this.filterComboData(
                                event.filter,
                                timeEntryRequiredAll
                            )
                        });
                        break;
                    }
                    case 'SelectedDeliveryModel': {
                        this.setState({
                            deliveryModel: this.filterComboData(
                                event.filter,
                                this.deliveryModelAll
                            )
                        });
                        break;
                    }
                    case 'SelectedLegalEntity': {
                        this.setState({
                            legalEntityType: this.filterComboData(
                                // resourceRegions: this.filterComboData(
                                event.filter,
                                this.legalEntityTypeAll
                            )
                        });
                        break;
                    }
                    case 'selectedAllocatedRegion': {
                        this.setState({
                            allocatedRegions: this.filterComboData(
                                event.filter,
                                this.allocatedRegionsAll
                            )
                        });
                        break;
                    }
                    default: {
                    }
                }
            }, 0);
        }
    };

    handleOnSkillAreas = async event => {
        const valueObj = event.target.value;
        const field = event.target.name;
        if (valueObj && valueObj.length <= 5) {
            await this.setState({
                [field]: valueObj,
                isSkillAreaError: false
            });
        } 
    }

    handleOnChangeCombo = async event => {
        const valueObj = event.target.value;
        const field = event.target.name;
        if (this.isMount) {
            await this.setState({
                [field]: valueObj
            }, async () => {
                if(field === 'SelectedEmploymentType'&& valueObj){
                    if(!this.state.preEmploymentType || this.state.preEmploymentType.Name != valueObj.Name){
                        this.setState({
                            SelectedDesignation: (valueObj.value || valueObj.id) === 1 ? this.state.SelectedDesignation : null,
                            HrDesignation: (valueObj.value || valueObj.id) === 1 ? '' : this.state.HrDesignation,
                            SelectedUser: (valueObj.value || valueObj.id) === 1 ? this.state.SelectedUser : null,
                        })
                        if((this.state.SelectedEmploymentType && this.state.SelectedEmploymentType.Name === 'Indirect Recruits') || 
                        (this.state.SelectedBillingDivision && this.state.SelectedBillingDivision.Name === 'Project Management')){
                            try {
                                const Designations = await this.populateDesignations();
                                const PMDesignations = Designations.slice().filter(resource =>
                                    resource.Name.includes('Manager')
                                  );
                                this.setState({
                                    Designations,
                                    DesignationsAll: Designations,
                                    PMDesignations: PMDesignations,
                                    PMDesignationsAll: PMDesignations
                                })
                            } catch (error) {
                                loggerService.writeLog(error, LOG_TYPES.ERROR);
                            }
                        }
                    }else{
                        this.setState({
                            SelectedDesignation: this.state.preDesignation,
                            HrDesignation: this.state.preHRDesignation,
                            SelectedUser: this.state.preSelectedUser,
                        })
                    }
                }
            });
        }
        if (field === 'SelectedCountry' && valueObj) {
            this.setState({
                isVisaCountryErrorMessageVisible: false
            });
        }

        if (field === 'selectedResourceRegion') {
            this.state.GeneralSelectedCountry = "";
            if (valueObj) {
                this.state.permissions.GeneralCountry = true;
                this.populateCountries(valueObj.Country);
            } else {
                this.state.permissions.GeneralCountry = false;
                this.populateCountries([]);
            }
        }
        if (field === 'SelectedDeployableStatus') {
            if (valueObj) {
                if (valueObj.value === 2) {
                    if (this.state.DeployableStatusPercentage === 100) {
                        this.state.DeployableStatusPercentage = 1;
                    }
                    this.state.permissions.DeployableStatusPercentage = true;
                    this.setState({}
                    );
                } else if (valueObj.value === 1 || valueObj.value === 3) {

                    if (valueObj.value === 1) { // fully deployable
                        this.state.DeployableStatusPercentage = 100;
                    } else if (valueObj.value === 3) { // Non deployable
                        this.state.DeployableStatusPercentage = 0;
                    }

                    this.state.permissions.DeployableStatusPercentage = false;
                    this.setState({}
                    );
                } else if (valueObj.value === null) {
                    this.state.DeployableStatusPercentage = 0;
                }
                this.setState({
                    isDeployabilityFormDirty: true
                });
            }
            else {
                this.state.DeployableStatusPercentage = 0;
            }

        }
        /*if (field === 'SelectedDivision') {
            this.state.isSkillAreaError = false;
            if (valueObj !== null) {

                if (valueObj.id === 1) { //For Operation
                    this.state.permissions.BillingDivision = true;
                    this.setState({}
                    );
                } else {
                    //this.state.SelectedBillingDivision = "";
                    this.state.SelectedDeployableStatus = "";
                    this.state.permissions.BillingDivision = false;
                    this.setState({}
                    );
                }
            }
            else {
                //this.state.SelectedBillingDivision = "";
                this.state.SelectedDeployableStatus = "";
                this.state.SelectedDivision = null;
                this.state.permissions.BillingDivision = false;
            }
        }*/
        if (field === 'selectedSkillArea') {
            if (valueObj !== null) {
                this.setState({ isSkillAreaError: false })
            }
        }

        if (field === 'selectedTimeEntryRequired') {
            if (valueObj !== null) {
                await this.setState({ selectedTimeEntryRequired: valueObj })
            }
            if (this.state.selectedTimeEntryRequired === 'Yes') {
                this.setState({
                    isTimeEntryRequired: true,
                })
            } else {
                this.setState({
                    isTimeEntryRequired: false
                })
            }
        }

        if (field === 'SelectedBillingDivision') {
            let practices = [];
            if (valueObj !== null) {
                this.practiceDivisionMapping();
                this.setState({ 
                    SelectedPractice: null
                })
            } else {
                this.setState({ 
                    selectedpracticeData: practices,
                    SelectedPractice: null
                })
            }
        }

        this.setState({
            isFormDirty: true
        });

    };

    practiceDivisionMapping = async () => {
        if (this.state.SelectedEmploymentType && this.state.SelectedEmploymentType.Name === 'Indirect Recruits' && this.state.SelectedBillingDivision) {
            let practices = [];
            let practicesForDivision = this.state.practiceDivisionMap.filter(item => item.DepartmentCodeSF === this.state.SelectedBillingDivision.SFCode);
            for (const practice of practicesForDivision) {
                let selectedPractice = this.state.practiceData.filter(item => item.SFCode === practice.PracticeCodeSF);
                practices.push(selectedPractice[0]);
            }
            this.setState({ selectedpracticeData: practices })
        }
    };

    handleSubmit = event => {
        event.preventDefault();
        if (this.validation()) {
            if (this.isMount) {

                /*if (this.state.SelectedDivision && this.state.SelectedDivision.id === 1 && this.state.deployabilityHistory.length === 0) {
                    this.toggleDialog('Please update the deployability of the user.', 'Error');
                } else {*/
                if (this.state.deployabilityHistory.length === 0) {
                    this.toggleDialog('Please update the deployability of the user.', 'Error');
                } else {
                    this.setState(
                        {
                            disableSave: true
                        },
                        () => {
                            const userProfile = {
                                UserId: this.state.userId,
                                FirstName: this.state.firstName,
                                MiddleName: this.state.middleName,
                                LastName: this.state.lastName,
                                DesignationId: this.state.SelectedEmploymentType && this.state.SelectedEmploymentType.Name === 'Indirect Recruits' ? this.state.SelectedDesignation.id : null,
                                EmploymentType: this.state.SelectedEmploymentType ? this.state.SelectedEmploymentType.id : null,
                                //Division: this.state.SelectedDivision.id,
                                Practice: this.state.SelectedBillingDivision ? this.state.SelectedBillingDivision.id : null,
				                DeployabilityHistory: this.state.deployabilityHistory,
                                Region: this.state.selectedResourceRegion.id,
                                GeneralCountry: this.state.GeneralSelectedCountry.id,
                                LineManager: this.state.SelectedEmploymentType && this.state.SelectedEmploymentType.Name === 'Indirect Recruits' ? this.state.SelectedUser ? this.state.SelectedUser.id : null : null,
                                PassportNo: this.state.passport,
                                ExpiryDate: this.state.ExpiryDatePassport ? new Date(this.state.ExpiryDatePassport) : null,
                                PassportImgId: this.state.selectedPassportFile.id ? this.state.selectedPassportFile.id : null,
                                AvatarImgId: this.state.selectedAvatarFile ? this.state.selectedAvatarFile.id : null,
                                UserCvImgId: this.state.selectedCVFile ? this.state.selectedCVFile.id : null,
                                ResourceId: this.state.ResourceId,
                                VisaDetails: this.state.userAllVisaDetails,
                                // SkillAreaId: this.state.selectedSkillArea ? this.state.selectedSkillArea.id : null,
                                SkillAreas: this.state.selectedSkillArea ? this.state.selectedSkillArea : null,
                                Email: this.state.user.Email,
                                IsTimeEntryRequired: this.state.isTimeEntryRequired,
                                PracticeId: this.state.SelectedPractice ? this.state.SelectedPractice.id : null,
                                DeliveryModelId: this.state.SelectedDeliveryModel ? this.state.SelectedDeliveryModel.id: null,
                                LegalEntityId: this.state.SelectedLegalEntity ? this.state.SelectedLegalEntity.id: null,
                                AllocatedRegionId: this.state.selectedAllocatedRegion ? this.state.selectedAllocatedRegion.id : null,
                                ContractTypeId: this.state.SelectedEmploymentType && this.state.SelectedEmploymentType.Name === 'Indirect Recruits' ? this.state.selectedContractType.id : null,
                            };
                            saveUserProfile(userProfile)
                                .then(res => {
                                    this.setState({
                                        showSuccessImage: true,
                                        showErrorImage: false,
                                        disabledSave: false,
                                        updateOrSave: true
                                    });
                                    const message = 'The user profile has been successfully created';
                                    const title = 'Success';
                                    this.toggleDialog(message, title);
                                })
                                .catch(error => {
                                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                                    const message = error;
                                    const title = 'Error';
                                    this.setState({
                                        showSuccessImage: false,
                                        showErrorImage: true,
                                        disabledSave: false
                                    });
                                    this.toggleMessageDialog(message, title);
                                });
                        }
                    );
                }
            }
        } else {
            this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
            this.setState({
                isErrorMessageVisible: true
            });
        }

    };

    handleUpdate = event => {
        event.preventDefault();

        if (this.validation()) {

            /*if (this.state.SelectedDivision && this.state.SelectedDivision.id === 1 && this.state.deployabilityHistory.length === 0) {
                this.toggleDialog('Please update the deployability of the user.', 'Error');
            } else {*/
            if ( this.state.deployabilityHistory.length === 0) {
                this.toggleDialog('Please update the deployability of the user.', 'Error');
            } else {
                const userProfile = {
                    UserId: this.state.userId,
                    FirstName: this.state.firstName,
                    MiddleName: this.state.middleName,
                    LastName: this.state.lastName,
                    DesignationId: this.state.SelectedDesignation ? this.state.SelectedDesignation.id: null ,
                    HrDesignationId: this.state.HrDesignation ? this.state.HrDesignation.id: null,
                    DeliveryModelId: this.state.SelectedDeliveryModel ? this.state.SelectedDeliveryModel.id: null,
                    LegalEntityId: this.state.SelectedLegalEntity ? this.state.SelectedLegalEntity.id: null,
                    EmploymentType: this.state.SelectedEmploymentType ? this.state.SelectedEmploymentType.id : null,
                    //Division: this.state.SelectedDivision.id,
                    Practice: this.state.SelectedBillingDivision ? this.state.SelectedBillingDivision.id : null,
                    DeployabilityHistory: this.state.deployabilityHistory,
                    Region: this.state.selectedResourceRegion.id,
                    GeneralCountry: this.state.GeneralSelectedCountry.id,
                    LineManager: this.state.SelectedUser ? this.state.SelectedUser.id : null,
                    PassportNo: this.state.passport,
                    ExpiryDate: this.state.ExpiryDatePassport ? moment(this.state.ExpiryDatePassport).format('YYYY-MM-DD') : null,
                    PassportImgId: this.state.selectedPassportFile ? this.state.selectedPassportFile.id : null,
                    AvatarImgId: this.state.selectedAvatarFile ? this.state.selectedAvatarFile.id : null,
                    UserCvImgId: this.state.selectedCVFile ? this.state.selectedCVFile.id : null,
                    ResourceId: this.state.ResourceId,
                    VisaDetails: this.state.userAllVisaDetails,
                    DeletedVisaDetails: this.state.userVisaDeletedDetails,
                    // SkillAreaId: this.state.selectedSkillArea ? this.state.selectedSkillArea.id : null,
                    SkillAreas: this.state.selectedSkillArea ? this.state.selectedSkillArea : null,
                    Email: this.state.userEmail,
                    IsTimeEntryRequired: this.state.isTimeEntryRequired,
                    PracticeId: this.state.SelectedPractice ? this.state.SelectedPractice.id : null,
                    AllocatedRegionId: this.state.selectedAllocatedRegion ? this.state.selectedAllocatedRegion.id : null,
                    ContractTypeId: this.state.SelectedEmploymentType && this.state.SelectedEmploymentType.Name === 'Indirect Recruits' ? this.state.selectedContractType.id : null,
                };

                updateUserProfile(userProfile, this.state.ResourceId)
                    .then(res => {
                        this.setState({
                            showSuccessImage: true,
                            showErrorImage: false,
                            disabledSave: false,
                            updateOrSave: true
                        });
                        const message = 'The user profile has been successfully Updated';
                        const title = 'Success';
                        this.toggleDialog(message, title);
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        const message = error;
                        const title = 'Error';
                        this.setState({
                            showSuccessImage: false,
                            showErrorImage: true,
                            disabledSave: false
                        });
                        this.toggleMessageDialog(message, title);
                    });
            }

        } else {
            this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
            this.setState({
                isErrorMessageVisible: true
            });
        }
    };

    validation = () => {
        if (this.validateProperty(this.state.firstName).toString().includes('error')) {
            return false;
        } else if (this.validateProperty(this.state.lastName).toString().includes('error')) {
            return false;
        } else if (this.state.SelectedEmploymentType && ((this.state.SelectedEmploymentType.Name) === 'Indirect Recruits') && this.validateProperty(this.state.SelectedDesignation).toString().includes('error')) {
            return false;
        } else if (this.state.SelectedEmploymentType && ((this.state.SelectedEmploymentType.Name) === 'Indirect Recruits') && this.validateProperty(this.state.SelectedUser).toString().includes('error')) {
            return false;
        } else if (this.validateProperty(this.state.SelectedEmploymentType).toString().includes('error')) {
            return false;
        } else if (this.validateProperty(this.state.billingDiviData).toString().includes('error')) {
            return false;
        } else if (this.validateProperty(this.state.selectedResourceRegion).toString().includes('error')) {
            return false;
        } else if (this.validateProperty(this.state.GeneralSelectedCountry).toString().includes('error')) {
            return false;
        } else if (this.validateProperty(this.state.selectedTimeEntryRequired).toString().includes('error')){
            return false;
        } else if (this.state.SelectedEmploymentType && ((this.state.SelectedEmploymentType.Name) === 'Indirect Recruits') && this.validateProperty(this.state.SelectedBillingDivision).toString().includes('error')) {
            return false;
        } else if (this.state.SelectedEmploymentType && ((this.state.SelectedEmploymentType.Name) === 'Indirect Recruits') && this.validateProperty(this.state.SelectedPractice).toString().includes('error')) {
            return false;
        } else if (this.state.SelectedEmploymentType && ((this.state.SelectedEmploymentType.Name) === 'Indirect Recruits') && this.validateProperty(this.state.selectedContractType).toString().includes('error')) {
            return false;
        }
        /*else if (this.state.SelectedDivision && this.state.SelectedDivision.id === 1) {
            if (this.validateProperty(this.state.selectedSkillArea).toString().includes('error')) {
                this.setState({ isSkillAreaError: true })
                return false;
            } else {
                return true;
            }
        } */
        else {
            return true;
        }

    };

    /*validateDivisionDetails = (param) => {
        if (this.validateProperty(this.state.selectedSkillArea).toString().includes('error')) {
            return false;
        } else {
            if (this.state.SelectedDivision.name === 'Operations') {
                if (this.validateProperty(this.state.SelectedBillingDivision).toString().includes('error') || this.validateProperty(this.state.selectedSkillArea).toString().includes('error')) {
                    return false;
                } else {
                    return true;
                }
            } else {
                if (this.validateProperty(this.state.SelectedBillingDivision).toString().includes('error') ) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    }*/

    filterComboData = (filter, allData) => {
        const data = allData.slice();
        return filterBy(data, filter);
    };

    pageChange = event => {
        if (this.isMount) {
            this.setState({
                skip: event.page.skip,
                take: event.page.take
            });
        }
    };



    toggleMessageDialog = (message, title) => {
        if (this.isMount) {
            this.setState({
                showMessageDialog: !this.state.showMessageDialog,
                dialogMessage: message,
                dialogTitle: title
            });
        }
    };

    // onAddClick = event => {
    //     event.preventDefault();
    //
    //     if (!this.state.VisaNumber) {
    //         if (this.isMount) {
    //             this.setState({
    //                 isErrorMessageVisible: true,
    //                 visible: true
    //             });
    //         }
    //         this.toggleMessageDialog(
    //             'Please fix the highlighted errors to continue',
    //             'Error'
    //         );
    //         return;
    //     } else if (!this.state.SelectedCountry) {
    //         if (this.isMount) {
    //             this.setState({
    //                 isErrorMessageVisible: true,
    //                 visible: true
    //             });
    //         }
    //         this.toggleMessageDialog(
    //             'Please fix the highlighted errors to continue',
    //             'Error'
    //         );
    //         return;
    //     } else if (!this.state.IssueDate) {
    //         if (this.isMount) {
    //             this.setState({
    //                 isErrorMessageVisible: true,
    //                 visible: true
    //             });
    //         }
    //         this.toggleMessageDialog(
    //             'Please fix the highlighted errors to continue',
    //             'Error'
    //         );
    //         return;
    //     } else if (!this.state.ExpiryDate) {
    //         if (this.isMount) {
    //             this.setState({
    //                 isErrorMessageVisible: true,
    //                 visible: true,
    //             });
    //         }
    //         this.toggleMessageDialog(
    //             'Please fix the highlighted errors to continue',
    //             'Error'
    //         );
    //         return;
    //     } else {
    //         if (this.isMount) {
    //             this.setState({
    //                 isErrorMessageVisible: false
    //             });
    //         }
    //     }
    //
    //
    //     let fields = {};
    //     let consVisaList;
    //
    //     if (this.state.VisaNumber) {
    //         fields.VisaNo = Number(this.state.VisaNumber);
    //     }
    //
    //     if (this.state.SelectedCountry) {
    //         fields.Country = this.state.SelectedCountry.Name;
    //     }
    //
    //     if (this.state.PlaceOfIssue) {
    //         fields.PlaceOfIssue = this.state.PlaceOfIssue;
    //     }
    //
    //     if (this.state.IssueDate) {
    //         fields.IssueDate = moment(this.state.IssueDate).format('YYYY-MM-DD');
    //     }
    //
    //     if (this.state.ExpiryDate) {
    //         fields.ExpiryDate = moment(this.state.ExpiryDate).format('YYYY-MM-DD');
    //     }
    //
    //     if (this.state.FileUpload) {
    //         fields.FileUpload = this.state.FileUpload
    //     }
    //
    //     if (this.isMount) {
    //         this.setState(prevState => {
    //             this.setState({ rowCount: prevState.rowCount + 1 });
    //             fields.id = this.state.rowCount;
    //         });
    //     }
    //
    //     this.state.userAllVisaDetails.push(fields);
    //
    //     this.setState({
    //         VisaNumber: '',
    //         SelectedCountry: '',
    //         IssueDate: '',
    //         ExpiryDate: '',
    //         PlaceOfIssue: ''
    //     });
    // };

    OnDelete = async (event, id) => {
        event.preventDefault();

        if (this.isMount) {
            this.setState({
                deleteId: id,
            });
        }

        this.toggleMessageDialog('Are you sure you want to delete this item?', null);

    };

    OnEdit = async (event, dataItem) => {
        event.preventDefault();

        let previousRecordEndDate = moment(dataItem.StartDate).subtract(1, "days").format('YYYY-MM-DD');

        if (this.isMount) {
            this.setState({
                SelectedDeployableStatus: dataItem.DeployableStatus,
                DeployableStatusPercentage: dataItem.DeployableStatusPercentage,
                deployabilityStartDate: new Date(dataItem.StartDate),
                minDeployabilityStartDate: new Date(dataItem.StartDate),
                previousRecordEndDate: previousRecordEndDate
            });
        }
    };

    onDeleteOk = () => {
        let userAllVisaDetails = [...this.state.userAllVisaDetails];
        let array = [...this.state.userAllVisaDetails];

        let deletedFilesCopy = [...this.state.userVisaDeletedDetails];

        let deletedFile = userAllVisaDetails.filter(item => item.id === this.state.deleteId);
        deletedFilesCopy.push(deletedFile[0]);

        array = array.filter(item => item.id !== this.state.deleteId);

        if (this.isMount) {
            this.setState({
                userAllVisaDetails: array,
                userVisaDeletedDetails: deletedFilesCopy
            });
        }

        this.toggleMessageDialog('', null);
    };

    onChangeHandlerCV = async event => {
        if (event.target.files[0].type === "application/pdf" || event.target.files[0].type === "application/msword" ||
            event.target.files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            await this.setState({
                selectedCVFile: event.target.files[0],
                loaded: 0,
                isCannotUpload: false
            });
        } else {
            const message = "CV can be only added as a PDF";
            const title = 'Error';
            await this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                disabledSave: false,
                isCannotUpload: true
            });
            await this.toggleDialog(message, title);
        }

        if (this.state.isCannotUpload === false) {
            // UPLOAD LOGIC
            var form_data = new FormData();
            form_data.append('file', this.state.selectedCVFile);
            form_data.append('referenceType', 'UserCV');
            form_data.append('referenceId', this.state.userId);

            await uploadFile(form_data).then(res => {
                if (res) {
                    this.setState({
                        selectedCVFile: res.data,
                        isLoading: false,
                    });
                }
                this.toggleDialog('File has been uploaded successfully', 'Upload Status');
            }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.setState({
                    isLoading: false
                });
                if (error.response.status === 400) {
                    this.setState({
                        isLoading: false,
                        fileType: 'cv'
                    });
                    this.toggleFileReplaceConfirmDialog(error.response.data, this.replaceFile);
                }
                if (error.response.status === 413) {
                    this.toggleDialog('Your file exceed 15 MB of maximum size allowed for upload files', 'Error')
                }
            });
        }
    };

    onFinishedUploadingAvatar = (err, res) => {
        this.setState({
            selectedAvatarFile: res.data,
            loaded: 0,
        });

        // UPLOAD LOGIC
        var form_data = new FormData();
        form_data.append('file', this.state.selectedAvatarFile);
        form_data.append('referenceType', 'AvatarImage');
        form_data.append('referenceId', this.state.userId);
        uploadFile(form_data).then(res => {
            if (res) {
                this.setState({
                    selectedAvatarFile: res.data,
                    isLoading: false,
                });
            }
            this.toggleDialog('File has been uploaded successfully', 'Upload Status');
        }
        ).catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    onChangeHandlerPassport = async event => {
        if (event.target.files[0]) {
            if (event.target.files[0].type === "image/png" || event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "application/pdf" || event.target.files[0].type === "image/bmp") {
                await this.setState({
                    selectedPassportFile: event.target.files[0],
                    loaded: 0,
                    isCannotUploadPassport: false
                });
            } else {
                const message = "Passport can be only added as .jpg, .png, .bmp and .pdf";
                const title = 'Error';
                await this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                    disabledSave: false,
                    isCannotUploadPassport: true
                });
                await this.toggleDialog(message, title);
            }

            if (this.state.isCannotUploadPassport === false) {
                // UPLOAD LOGIC
                var form_data = new FormData();
                form_data.append('file', this.state.selectedPassportFile);
                form_data.append('referenceType', 'UserProfilePassport');
                form_data.append('referenceId', this.state.userId);

                await uploadFile(form_data).then(res => {
                    if (res) {
                        this.setState({
                            selectedPassportFile: res.data,
                            isLoading: false,
                        });
                    }

                    this.toggleDialog('File has been uploaded successfully', 'Upload Status');

                }).catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                    this.setState({
                        isLoading: false
                    });
                    if (error.response.status === 400) {
                        this.setState({
                            isLoading: false,
                            fileType: 'passport'
                        });
                        this.toggleFileReplaceConfirmDialog(error.response.data, this.replaceFile);
                    }
                    if (error.response.status === 413) {
                        this.toggleDialog('Your file exceed 15 MB of maximum size allowed for upload files', 'Error')
                    }
                });
            }
        }
    };

    onChangeHandlerVisa = async event => {
        await this.setState({
            selectedFile: event.target.files[0]
        });

        if (!this.state.SelectedCountry) {
            if (this.isMount) {
                this.setState({
                    isVisaCountryErrorMessageVisible: true,
                });
            }
            this.toggleDialog(
                'Please fix the highlighted errors to continue',
                'Error'
            );
            return;
        } else if (!this.state.IssueDate) {
            if (this.isMount) {
                this.setState({
                    isVisaIssueDateErrorMessageVisible: true,
                });
            }
            this.toggleDialog(
                'Please fix the highlighted errors to continue',
                'Error'
            );
            return;
        } else if (!this.state.ExpiryDate) {
            if (this.isMount) {
                this.setState({
                    isVisaExpiryDateErrorMessageVisible: true,
                });
            }
            this.toggleDialog(
                'Please fix the highlighted errors to continue',
                'Error'
            );
            return;
        }
        else {
            if (this.isMount) {

                // UPLOAD LOGIC
                var form_data = new FormData();
                form_data.append('file', this.state.selectedFile);
                form_data.append('referenceType', 'UserProfileVisa');
                form_data.append('referenceId', this.state.VisaNumber);

                uploadFile(form_data).then(res => {
                    let fields = {};

                    if (this.state.VisaNumber) { fields.VisaNo = this.state.VisaNumber; }

                    if (this.state.PlaceOfIssue) { fields.PlaceOfIssue = this.state.PlaceOfIssue; }

                    if (this.state.SelectedCountry) {
                        fields.Country = this.state.SelectedCountry.id;
                        fields.CountryName = this.state.SelectedCountry.Name;
                    }

                    if (this.state.IssueDate) { fields.IssueDate = moment(this.state.IssueDate).format('YYYY-MM-DD'); }

                    if (this.state.ExpiryDate) { fields.ExpiryDate = moment(this.state.ExpiryDate).format('YYYY-MM-DD'); }

                    if (res) {
                        fields.FileUpload = res.data;
                    }
                    if (this.isMount) {
                        this.setState(prevState => {
                            this.setState({ rowCount: prevState.rowCount + 1 });
                            fields.id = this.state.rowCount;
                        });
                    }

                    this.state.userAllVisaDetails.push(fields);

                    this.setState({
                        VisaNumber: '',
                        SelectedCountry: '',
                        IssueDate: '',
                        ExpiryDate: '',
                        PlaceOfIssue: '',
                        isVisaCountryErrorMessageVisible: false,
                        isVisaNoErrorMessageVisible: false,
                        isVisaIssueDateErrorMessageVisible: false,
                        isVisaExpiryDateErrorMessageVisible: false,
                        AllCountries: (this.CountryAll.slice())
                    });

                }).catch(error => {

                });
            }
        }
    };

    toggleDialog = (message, title) => {
        this.setState({
            visible: !this.state.visible,
            dialogMessage: message,
            dialogTitle: title,
        });
    };

    toggleConfirmDialog = async (message, action) => {
        await this.setState({
            showMessageDialog: !this.state.showMessageDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    toggleDeployabilityCancelConfirmDialog = () => {
        if (this.isMount) {
            this.setState({
                showDeployabilityCancelConfirmation: !this.state.showDeployabilityCancelConfirmation
            });
        }
    };

    closeDeployabilityDialog = async () => {
        if (this.isMount) {
            await this.setState({
                isDeployabilityFormDirty: false,
            });
        }
        await this.toggleDeployabilityCancelConfirmDialog();
        await this.cancelDeployHistoryDialog();
    };

    toggleFileReplaceConfirmDialog = async (message) => {
        await this.setState({
            showFileReplaceConfirmDialog: !this.state.showFileReplaceConfirmDialog,
            confirmReplaceDialogMessage: message,
        });
    };

    togglevisaNoToolTip = () => {
        if (this.isMount) {
            this.setState({
                visaNoToolTipOpen: !this.state.visaNoToolTipOpen
            });
        }
    };

    toggleplaceOfIssueToolTip = () => {
        if (this.isMount) {
            this.setState({
                placeOfIssueToolTipOpen: !this.state.placeOfIssueToolTipOpen
            });
        }
    };

    handleIssueDateChange = event => {
        let startDate = event.target.value;

        if (this.isMount) {
            this.setState({
                IssueDate: startDate,
                expiryDateMin: startDate,
                isFormDirty: true
            });
        }
        if (startDate) {
            this.state.permissions.ExpiryDate = true;
            this.setState({
                isVisaIssueDateErrorMessageVisible: false
            });
        } else {
            this.setState({
                isVisaIssueDateErrorMessageVisible: true
            })
        }
    };

    handleExpiryDateChange = event => {
        let startDate = event.target.value;

        if (this.isMount) {
            this.setState({
                ExpiryDate: startDate,
                isFormDirty: true
            });
        }

        if (startDate) {
            this.setState({
                isVisaExpiryDateErrorMessageVisible: false
            });
        } else {
            this.setState({
                isVisaExpiryDateErrorMessageVisible: true
            });
        }
    };

    handleExpiryDatePassportChange = event => {
        let startDate = event.target.value;

        if (this.isMount) {
            this.setState({
                ExpiryDatePassport: startDate,
                isFormDirty: true
            });
        }

        if (startDate) {
            this.setState({
                isPassportExpiryDateErrorMessageVisible: false
            });
        } else {
            this.setState({
                isPassportExpiryDateErrorMessageVisible: true
            });
        }
    };

    handleDeployabilityStartDateChange = event => {
        let startDate = event.target.value;

        if (this.isMount) {
            this.setState({
                deployabilityStartDate: startDate,
                isFormDirty: true,
                isDeployabilityFormDirty: true
            });
        }
    };

    redirectToUserSearch = () => {
        this.setState({
            redirectToUserSearch: true
        });
    }

    replaceFile = (event, type) => {
        this.setState({
            replaceBtnDisabled: true
        });
        var form_data = new FormData();

        if (this.state.fileType === 'passport') {
            form_data.append('file', this.state.selectedPassportFile);
        } else {
            form_data.append('file', this.state.selectedCVFile);
        }

        form_data.append('referenceType', 'UserCV');
        form_data.append('referenceId', this.state.userId);

        this.setState({
            isLoading: true
        });
        replaceFile(form_data)
            .then(res => {
                this.toggleFileReplaceConfirmDialog();

                if (this.state.fileType === 'passport') {
                    this.setState({
                        selectedPassportFile: res.data,
                        isLoading: false,
                    })
                } else {
                    this.setState({
                        selectedCVFile: res.data,
                        isLoading: false,
                        replaceBtnDisabled: false
                    });
                }

            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                if (error.response.status === 413) {
                    this.toggleDialog('Your file exceed 15 MB of maximum size allowed for upload files', 'Error')
                }
            });
    };

    onClickDeployabilityUpdate = () => {

        if (this.state.deployabilityHistory.length === 0) {
            const tempDeploy = {
                id: null,
                DeployableStatus: this.state.SelectedDeployableStatus,
                DeployableStatusPercentage: this.state.DeployableStatusPercentage,
                StartDate: moment(this.state.deployabilityStartDate).format('YYYY-MM-DD'),
                EndDate: null
            };

            let newDeployability = [];
            newDeployability.push(tempDeploy);

            this.setState({
                deployabilityHistory: newDeployability,
                SelectedDeployableStatus: null,
                DeployableStatusPercentage: 0,
                deployabilityStartDate: null,
                minDeployabilityStartDate: new Date(moment(tempDeploy.StartDate).add(1, "days")),
                isDeployabilityFormDirty: false
            });
        } else {
            const previousRecordEndDate = this.state.previousRecordEndDate;
            const existingDeployability = JSON.parse(JSON.stringify(this.state.deployabilityHistory));;
            let newDeployability = existingDeployability;

            const tempDeploy = {
                id: null,
                DeployableStatus: this.state.SelectedDeployableStatus,
                DeployableStatusPercentage: this.state.DeployableStatusPercentage,
                StartDate: moment(this.state.deployabilityStartDate).format('YYYY-MM-DD'),
                EndDate: null
            };

            if (previousRecordEndDate) {
                let stateDeployabilityHistory = [];
                for (const deployability of newDeployability) {
                    if (deployability.EndDate === null) {
                        deployability.StartDate = tempDeploy.StartDate;
                        deployability.DeployableStatusPercentage = tempDeploy.DeployableStatusPercentage;
                        deployability.DeployableStatus = tempDeploy.DeployableStatus;
                    }

                    if (new Date(deployability.EndDate).getTime() === new Date(previousRecordEndDate).getTime()) {
                        deployability.EndDate = moment(this.state.deployabilityStartDate).subtract(1, "days").format('YYYY-MM-DD');
                    }

                    stateDeployabilityHistory.push(deployability)
                }
                stateDeployabilityHistory.sort((a, b) => (a.StartDate < b.StartDate) ? 1 : ((b.StartDate < a.StartDate) ? -1 : 0));
                this.setState({
                    deployabilityHistory: stateDeployabilityHistory,
                    minDeployabilityStartDate: new Date(moment(stateDeployabilityHistory[0].StartDate).add(1, "days")),
                });
            } else {
                let stateDeployabilityHistory = [];
                for (const deployability of newDeployability) {
                    if (deployability.EndDate === null) {
                        deployability.EndDate = moment(this.state.deployabilityStartDate).subtract(1, "days").format('YYYY-MM-DD');
                    }
                    stateDeployabilityHistory.push(deployability);
                }
                stateDeployabilityHistory.push(tempDeploy);
                stateDeployabilityHistory.sort((a, b) => (a.StartDate < b.StartDate) ? 1 : ((b.StartDate < a.StartDate) ? -1 : 0));
                this.setState({
                    deployabilityHistory: stateDeployabilityHistory,
                    minDeployabilityStartDate: new Date(moment(stateDeployabilityHistory[0].StartDate).add(1, "days")),
                });
            }

            this.setState({
                SelectedDeployableStatus: null,
                DeployableStatusPercentage: 0,
                deployabilityStartDate: null,
                previousRecordEndDate: null,
                isDeployabilityFormDirty: false
            });

        }

    }

    render() {
        if (this.state.redirectToUserSearch === true) {
            return <Redirect to="/admin/usermanagement/usermanagement" />;
        }
        return (
            <div>
                <div className="main-card">
                    <div className="row">

                        <div className="col-md-3 text-center">
                            <div className="d-inline-block upld-img">
                                <label className="label-upld-img">Upload Image</label>
                                <AvatarUploader
                                    size={190}
                                    uploadURL={apiUrl + '/integration/fileUpload/upload/'}
                                    fileType={"image/png"}
                                    withCredentials={true}
                                    customHeaders={{ referenceType: 'UserAvatar', referenceId: this.state.userId }}
                                    defaultImg={`${apiUrl}/integration/fileUpload/download?path=UserAvatar_${this.state.userId}_${encodeURIComponent(this.state.selectedAvatarFile ? this.state.selectedAvatarFile.FileName : null)}`}
                                    onFinished={this.onFinishedUploadingAvatar}
                                    placeholder={'Upload Image'} />
                            </div>

                        </div>

                        <div className="col-md-9">
                            <div className="row">

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label">Name:</label>
                                    </div>
                                    <div className="d-block">
                                        <Input className="mandatory"
                                            placeholder="First Name"
                                            value={this.state.firstName}
                                            name="FirstName"
                                            required={true}
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label"></label>
                                    </div>
                                    <div className="d-block">
                                        <Input
                                            placeholder="Middle Name"
                                            value={this.state.middleName}
                                            name="MiddleName"
                                            onChange={this.handleChangeMiddleName}
                                            maxLength="20"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label"></label>
                                    </div>
                                    <div className="d-block">
                                        <Input className="mandatory"
                                            placeholder="Last Name"
                                            value={this.state.lastName}
                                            name="LastName"
                                            required={this.state.isErrorMessageVisible ? true : false}
                                            onChange={this.handleChangeLastName}
                                            maxLength="20"
                                            disabled={true}
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.lastName)}>
                                                Please enter the Last Name
                                            </span>
                                        ) : null}
                                    </div>
                                </div>


                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label">HR Designation:</label>
                                    </div>
                                    <div className="d-block" id="hrDesignationToolTipOpen">
                                        <Input
                                            placeholder=""
                                            value={this.state.HrDesignation}
                                            name="designation"
                                            disabled={true}
                                        />
                                        {/* {this.state.HrDesignation === '' ? (
                                            <span className="inline-error">
                                            HR Designation Synchronized from the HRIS does not exist in Spark. Please create the HR Designation and then map the HR Designation with relevant cost designation before creating the Resource Profile
                                            </span>
                                        ) : null} */}
                                        {this.state.HrDesignation ? (
                                            <Tooltip
                                                placement="top"
                                                isOpen={this.state.hrDesgToolTipOpen}
                                                target="hrDesignationToolTipOpen"
                                                toggle={this.toggleHrDesignationTooltip}
                                            >
                                                {this.state.HrDesignation}
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label">Cost Designation:</label>
                                    </div>
                                    <div className="d-block" id="costDesignationToolTipOpen">
                                        {(!this.state.SelectedEmploymentType || (this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name !== 'Indirect Recruits')) && (this.state.SelectedBillingDivision && this.state.SelectedBillingDivision.Name !== 'Project Management')) &&
                                            <Input
                                                placeholder=""
                                                value={this.state.SelectedDesignation == null ? "" : this.state.SelectedDesignation.Name}
                                                name="designation"
                                                disabled={true}
                                            />
                                        }
                                        {((this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name === 'Indirect Recruits')) || (this.state.SelectedBillingDivision && this.state.SelectedBillingDivision.Name === 'Project Management')) &&
                                            <ComboBox
                                                data={this.state.SelectedBillingDivision && this.state.SelectedBillingDivision.Name === 'Project Management'? this.state.PMDesignations : this.state.Designations}
                                                textField="Name"
                                                dataItemKey="id"
                                                value={this.state.SelectedDesignation}
                                                onChange={this.handleOnChangeCombo}
                                                name="SelectedDesignation"
                                                placeholder="Please Select"
                                                filterable={true}
                                                onFilterChange={this.filterChangeCombo}
                                                required={this.state.isErrorMessageVisible ? true : false}
                                                disabled = {false}
                                            />
                                        }

                                        {this.state.SelectedDesignation ? (
                                            <Tooltip
                                                placement="top"
                                                isOpen={this.state.costDesgToolTipOpen}
                                                target="costDesignationToolTipOpen"
                                                toggle={this.toggleCostDesignationTooltip}
                                            >
                                                {this.state.SelectedDesignation.Name}
                                            </Tooltip>
                                        ) : null}
                                        
                                        {(this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name === 'Indirect Recruits') && this.state.isErrorMessageVisible) ? (
                                            <span className={this.validateProperty(this.state.SelectedDesignation)}>
                                                Please Select Designation
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                                {/* <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label">Employment Type:</label>
                                    </div>
                                    <div className="d-block" id="empTypeToolTipOpen">
                                        <Input
                                            placeholder=""
                                            value={this.state.EmploymentTypeNew}
                                            name="employmentType"
                                            disabled={true}
                                        />
                                        {this.state.EmploymentTypeNew ? (
                                            <Tooltip
                                                placement="top"
                                                isOpen={this.state.employTypeToolTipOpen}
                                                target="empTypeToolTipOpen"
                                                toggle={this.toggleEmpTypeTooltip}
                                            >
                                                {this.state.EmploymentTypeNew}
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                </div> */}
                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label mandatory">Employment Type:</label>
                                    </div>
                                    <div className="d-block">
                                        <ComboBox
                                            data={this.state.EmploymentType}
                                            textField="Name"
                                            dataItemKey="id"
                                            value={this.state.SelectedEmploymentType}
                                            onChange={this.handleOnChangeCombo}
                                            name="SelectedEmploymentType"
                                            placeholder="Please Select"
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            required={this.state.isErrorMessageVisible ? true : false}
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.SelectedEmploymentType)}>
                                                Please Select Employment Type
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                                {/*<div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label" className="mandatory">Division:</label>
                                    </div>
                                    <div className="d-block">
                                        <ComboBox
                                            data={this.state.Division}
                                            textField="name"
                                            dataItemKey="id"
                                            value={this.state.SelectedDivision}
                                            onChange={this.handleOnChangeCombo}
                                            name="SelectedDivision"
                                            placeholder="Please Select"
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            required={this.state.isErrorMessageVisible ? true : false}
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.SelectedDivision)}>
                                                Please Select Division
                                            </span>
                                        ) : null}
                                    </div>
                                </div>*/}

                                {/*<div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label" className="mandatory">Department:</label>
                                    </div>
                                    <div className="d-block">
                                        <ComboBox
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            placeholder="Please select"
                                            name="SelectedBillingDivision"
                                            data={this.state.billingDiviData}
                                            textField="Name"
                                            dataItemKey="id"
                                            onChange={this.handleOnChangeCombo}
                                            value={this.state.SelectedBillingDivision}
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.SelectedBillingDivision)}>
                                                Practice/Department is mandatory for Operations
                                            </span>
                                        ) : null}
                                    </div>
                                </div>*/}

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label mandatory">Department:</label>
                                    </div>
                                    <div className="d-block" id="departmentToolTipOpen">
                                    {(!this.state.SelectedEmploymentType || (this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name !== 'Indirect Recruits'))) &&
                                        <Input
                                            placeholder=""
                                            value={this.state.Department}
                                            name="department"
                                            disabled={true}
                                        />
                                    }
                                    {this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name === 'Indirect Recruits') &&
                                        <ComboBox
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            placeholder="Please select"
                                            name="SelectedBillingDivision"
                                            data={this.state.billingDiviData}
                                            textField="Name"
                                            dataItemKey="id"
                                            onChange={this.handleOnChangeCombo}
                                            value={this.state.SelectedBillingDivision}
                                        />
                                    }
                                        {this.state.Department ? (
                                            <Tooltip
                                                placement="top"
                                                isOpen={this.state.depToolTipOpen}
                                                target="departmentToolTipOpen"
                                                toggle={this.toggleDepTooltip}
                                            >
                                                {this.state.Department}
                                            </Tooltip>
                                        ) : null}

                                        {(this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name === 'Indirect Recruits') && this.state.isErrorMessageVisible) ? (
                                            <span className={this.validateProperty(this.state.SelectedBillingDivision)}>
                                                Please Select Department
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label mandatory">Practice:</label>
                                    </div>
                                    <div className="d-block" id="practiceToolTipOpen">
                                    {(!this.state.SelectedEmploymentType || (this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name !== 'Indirect Recruits'))) &&
                                        <Input
                                            placeholder=""
                                            value={this.state.PracticeNew}
                                            name="practice"
                                            disabled={true}
                                        />
                                    }
                                    {this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name === 'Indirect Recruits') &&
                                        <ComboBox
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            placeholder="Please select"
                                            name="SelectedPractice"
                                            data={this.state.selectedpracticeData}
                                            textField="Name"
                                            dataItemKey="id"
                                            onChange={this.handleOnChangeCombo}
                                            value={this.state.SelectedPractice}
                                            disabled={this.state.SelectedBillingDivision ? false : true}
                                        />
                                    }
                                        {this.state.PracticeNew ? (
                                            <Tooltip
                                                placement="top"
                                                isOpen={this.state.pracToolTipOpen}
                                                target="practiceToolTipOpen"
                                                toggle={this.togglePracTooltip}
                                            >
                                                {this.state.PracticeNew}
                                            </Tooltip>
                                        ) : null}

                                        {(this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name === 'Indirect Recruits') && this.state.isErrorMessageVisible) ? (
                                            <span className={this.validateProperty(this.state.SelectedPractice)}>
                                                Please Select Practice
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label">Delivery Model:</label>
                                    </div>
                                    <div className="d-block" id="deliveryModToolTipOpen">
                                    {(!this.state.SelectedEmploymentType || (this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name !== 'Indirect Recruits'))) &&
                                        <Input
                                            placeholder=""
                                            value={this.state.DeliveryModel}
                                            name="deliveryModel"
                                            disabled={true}
                                        />
                                    }
                                    {this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name === 'Indirect Recruits') &&
                                        <ComboBox
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            placeholder="Please select"
                                            name="SelectedDeliveryModel"
                                            data={this.state.deliveryModel}
                                            textField="Name"
                                            dataItemKey="id"
                                            onChange={this.handleOnChangeCombo}
                                            value={this.state.SelectedDeliveryModel}
                                        />
                                    }
                                        {this.state.DeliveryModel ? (
                                            <Tooltip
                                                placement="top"
                                                isOpen={this.state.delModToolTipOpen}
                                                target="deliveryModToolTipOpen"
                                                toggle={this.toggleDeliModTooltip}
                                            >
                                                {this.state.DeliveryModel}
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label">Legal Entity:</label>
                                    </div>
                                    <div className="d-block" id="legalEntityToolTipOpen">
                                    {(!this.state.SelectedEmploymentType || (this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name !== 'Indirect Recruits'))) &&
                                        <Input
                                            placeholder=""
                                            value={this.state.LegalEntity}
                                            name="LegalEntity"
                                            disabled={true}
                                        />
                                    }
                                    {this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name === 'Indirect Recruits') &&
                                        <ComboBox
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            placeholder="Please select"
                                            name="SelectedLegalEntity"
                                            data={this.state.legalEntityType}
                                            textField="Name"
                                            dataItemKey="id"
                                            onChange={this.handleOnChangeCombo}
                                            value={this.state.SelectedLegalEntity}
                                        />
                                    }
                                        {this.state.LegalEntity ? (
                                            <Tooltip
                                                placement="top"
                                                isOpen={this.state.legalToolTipOpen}
                                                target="legalEntityToolTipOpen"
                                                toggle={this.toggleLegalEntityTooltip}
                                            >
                                                {this.state.LegalEntity}
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                </div>
                            {(this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name === 'Indirect Recruits')) && (
                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label mandatory">Contract Type:</label>
                                    </div>
                                    <div className="d-block" id="contractTypeToolTipOpen">
                                        <ComboBox
                                            data={this.state.contractTypes}
                                            textField="Name"
                                            dataItemKey="id"
                                            value={this.state.selectedContractType}
                                            onChange={this.handleOnChangeCombo}
                                            name="selectedContractType"
                                            placeholder="Please Select"
                                            filterable={true}
                                            required={this.state.isErrorMessageVisible ? true : false}
                                            disabled = {false}
                                        />
                                        
                                        {(this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name === 'Indirect Recruits') && this.state.isErrorMessageVisible) ? (
                                            <span className={this.validateProperty(this.state.selectedContractType)}>
                                                Please Select a Contract Type
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                )}

                            </div>

                            <div className="row">

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label" className="mandatory">Deployable Status:</label>
                                    </div>
                                    <div className="">
                                        <Input
                                            className=" input-btn"
                                            placeholder=""
                                            value={this.state.currentDeployableStatus}
                                            name="currentDeployableStatus"
                                            disabled={true}
                                        />

                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label-mod"></label>
                                    </div>
                                    <div className="">
                                        <Input
                                            className="col-md-9"
                                            placeholder=""
                                            value={this.state.currentDeployablePercentage}
                                            name="currentDeployableStatus"
                                            disabled={true}
                                        />
                                        <Button
                                            className="k-button update-btn col-md-3"
                                            disabled={true}
                                        >
                                            %
                                        </Button>


                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label-mod"></label>
                                    </div>
                                    <div className="">
                                        <Button
                                            type={"button"}
                                            className="k-button update-btn"
                                            onClick={this.toggleDeployHistoryDialog}
                                            // disabled={!this.state.permissions.BillingDivision}
                                        >
                                            Update Deployability
                                        </Button>
                                    </div>

                                </div>

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label">Area:</label>
                                    </div>
                                    <div className="d-block">
                                        <MultiSelect
                                            data={this.state.skillAreas}
                                            textField="name"
                                            dataItemKey="id"
                                            value={this.state.selectedSkillArea}
                                            onChange={this.handleOnSkillAreas}
                                            name="selectedSkillArea"
                                            placeholder="Please Select"
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                        />
                                        {this.state.isErrorMessageVisible === true && this.state.permissions.BillingDivision ? (
                                            <span className={this.validateProperty(this.state.selectedSkillArea)}>
                                                Area is mandatory for Operations
                                            </span>
                                        ) : null}
                                    </div>
                                </div>


                            </div>

                            <div className="row mt-4">
                            <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label" className="mandatory">Time entry required:</label>
                                    </div>
                                    <div className="d-block">
                                        <ComboBox
                                            data={this.state.timeEntryRequired}
                                            value={this.state.selectedTimeEntryRequired}
                                            onChange={this.handleOnChangeCombo}
                                            name="selectedTimeEntryRequired"
                                            placeholder="Please Select"
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            required={this.state.isErrorMessageVisible ? true : false}
                                            disabled = {this.state.currentDeployableStatus ? 
                                                this.state.currentDeployableStatus === 'Fully Deployable' ? true : false : false }
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.selectedTimeEntryRequired)}>
                                                Time entry required is mandatory
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
  
                                <div className="col-md-6 offset-md-3">
                                    <div className="input-cv">

                                        <div className="">
                                            {this.state.selectedCVFile && (
                                                <div className="doc-list" >
                                                    <div className="row  mx-auto my-2">
                                                        <span className="col-md-10 docu-upload">
                                                            {this.state.selectedCVFile.FileName}
                                                        </span>
                                                        <span className="col-md-2 docu-icon text-center">
                                                            <a href={`${apiUrl}/integration/fileUpload/download?path=UserCV_${encodeURIComponent(this.state.ResourceId)}_${encodeURIComponent(this.state.selectedCVFile.FileName)}`} target="_blank">
                                                                <span className="k-icon k-i-download ml-2"></span>
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-3 offset-md-9 overflow-x-hidden">
                                    <input type="file" name="file" onChange={this.onChangeHandlerCV} value={this.state.cvImgId}
                                        className="custom-file-input cv" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 btn-align-right">

                                    {this.state.action && this.state.action === 'edit_user_profile' && (
                                        <Button
                                            type="submit"
                                            primary={true}
                                            disabled={this.state.isViewMode || this.state.isDisableSave}
                                            onClick={this.handleUpdate}
                                        >
                                            Update
                                    </Button>
                                    )}



                                    {this.state.action && this.state.action === 'create_user_profile' && (

                                        <Button
                                            type="submit"
                                            primary={true}
                                            disabled={this.state.isViewMode || this.state.isDisableSave}
                                            onClick={this.handleSubmit}
                                        >
                                            Save
                                        </Button>
                                    )}

                                    <Button type="button" onClick={this.onClickCancel} >
                                        Cancel
                                    </Button>



                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="main-card">
                    <TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
                        <TabStripTab title="General">
                            <div className="row">

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label" className="mandatory">Region:</label>
                                    </div>
                                    <div className="d-block">
                                        <ComboBox
                                            data={this.state.resourceRegions}
                                            textField="Name"
                                            dataItemKey="id"
                                            required={this.state.isErrorMessageVisible ? true : false}
                                            value={this.state.selectedResourceRegion}
                                            onChange={this.handleOnChangeCombo}
                                            placeholder="Please Select"
                                            filterable={true}
                                            name="selectedResourceRegion"
                                            onFilterChange={this.filterChangeCombo}

                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.selectedResourceRegion)}>
                                                Please Select Region
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label">Resource Assignment:</label>
                                    </div>
                                    <div className="d-block">
                                        <ComboBox
                                            data={this.state.allocatedRegions}
                                            textField="Name"
                                            dataItemKey="id"
                                            required={this.state.isErrorMessageVisible ? true : false}
                                            value={this.state.selectedAllocatedRegion}
                                            onChange={this.handleOnChangeCombo}
                                            placeholder="Please Select"
                                            filterable={true}
                                            name="selectedAllocatedRegion"
                                            onFilterChange={this.filterChangeCombo}

                                        />
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label" className="mandatory">Country:</label>
                                    </div>
                                    <div className="d-block">
                                        <ComboBox
                                            data={this.state.countryItems}
                                            textField="Name"
                                            dataItemKey="id"
                                            placeholder="Please Select"
                                            value={this.state.GeneralSelectedCountry}
                                            onChange={this.handleOnChangeCombo}
                                            name="GeneralSelectedCountry"
                                            required={this.state.permissions.GeneralCountry && this.state.isErrorMessageVisible ? true : false}
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            disabled={!this.state.permissions.GeneralCountry}
                                        />
                                        {this.state.isErrorMessageVisible === true && this.state.permissions.GeneralCountry ? (
                                            <span className={this.validateProperty(this.state.GeneralSelectedCountry)}>
                                                Please Select Country
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label" className="">Line Manager:</label>
                                    </div>
                                    <div className="d-block">
                                        {(!this.state.SelectedEmploymentType || (this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name !== 'Indirect Recruits'))) &&
                                            <Input
                                                placeholder=""
                                                value={this.state.SelectedUser ? this.state.SelectedUser.Name : ''}
                                                name="lineManager"
                                                disabled={true}
                                            />
                                        }
                                        {this.state.SelectedEmploymentType && (this.state.SelectedEmploymentType.Name === 'Indirect Recruits') &&
                                            <ComboBox
                                                data={this.state.activeUser}
                                                textField="Name"
                                                dataItemKey="id"
                                                value={this.state.SelectedUser}
                                                onChange={this.handleOnChangeCombo}
                                                name="SelectedUser"
                                                placeholder="Please Select"
                                                filterable={true}
                                                onFilterChange={this.filterChangeCombo}
                                            />
                                        }
                                        {this.state.SelectedEmploymentType && ((this.state.SelectedEmploymentType.Name) === 'Indirect Recruits') && (!this.state.SelectedUser) && this.state.isErrorMessageVisible ? (
                                            <span className="inline-error">
                                            Line Manager Synchronized from the HRIS does not exist in Spark. Please create Line Manager as a Spark User before creating the Resource Profile
                                            </span>
                                        ) : null}
                                    </div>
                                </div>


                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="" >Passport No:</label>
                                    </div>
                                    <div className="d-block">
                                        <div className="new-label">
                                            <Input
                                                textField="Name"
                                                type="Numeric"
                                                // required={true}
                                                dataItemKey="id"
                                                value={this.state.passport}
                                                maxLength="20"
                                                onChange={this.handleChangePassportNumber}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label" className="mandatory">Expiry Date:</label>
                                    </div>
                                    <DatePicker
                                        width="100"
                                        name="ExpiryDatePassport"
                                        onChange={this.handleExpiryDatePassportChange}
                                        required={this.state.isPassportExpiryDateErrorMessageVisible ? true : false}
                                        min={new Date()}
                                        value={this.state.ExpiryDatePassport}
                                        format="MM/dd/yyyy"
                                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                    //disabled={!this.state.permissions.ExpiryDatePassport}
                                    />
                                    <div className="d-block">
                                        {this.state.isPassportExpiryDateErrorMessageVisible === true ? (
                                            <span className={'inline-error'}>
                                                Please Select Expiry date
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6"></div>

                                <div className="col-md-4 input-pass">

                                    <div className="d-block mb-3">
                                        <div className="new-label-mod">
                                            <div className="col-md-8">
                                                {this.state.selectedPassportFile && (
                                                    <div className="doc-list mb-4">
                                                        <div className="row mx-auto my-2">
                                                            <span className="col-md-10 docu-upload">
                                                                {this.state.selectedPassportFile.FileName}
                                                            </span>
                                                            <span className="col-md-2 text-center docu-icon">
                                                                <a href={`${apiUrl}/integration/fileUpload/download?path=UserProfilePassport_${encodeURIComponent(this.state.ResourceId)}_${encodeURIComponent(this.state.selectedPassportFile.FileName)}`} target="_blank">
                                                                    <span className="k-icon k-i-download ml-2" ></span> </a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-block pull-right">
                                        <input type="file" name="file" onChange={this.onChangeHandlerPassport} value={this.state.passportImgId}
                                            className="custom-file-input pass" disabled={!this.state.ExpiryDatePassport} />
                                    </div>
                                </div>
                            </div>

                            <div className="main-seperator"></div>

                            <div className="main-heading">
                                Visa Details
                            </div>

                            <div className="row">

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label" className="mandatory">Visa No:</label>
                                    </div>
                                    <div className="d-block" id="visaNoToolTip">
                                        <Input
                                            autoComplete="off"
                                            type="Numeric"
                                            name="VisaNumber"
                                            required={this.state.isVisaNoErrorMessageVisible ? true : false}
                                            maxLength="50"
                                            value={this.state.VisaNumber}
                                            onChange={this.handleChangeVisaNumber}
                                        />
                                        {this.state.isVisaNoErrorMessageVisible === true ? (
                                            <span className={'inline-error'}>
                                                Please enter a Visa Number
                                        </span>
                                        ) : null}
                                        {this.state.VisaNumber ? (
                                            <Tooltip
                                                placement="top"
                                                isOpen={this.state.visaNoToolTipOpen}
                                                target="visaNoToolTip"
                                                toggle={this.togglevisaNoToolTip}
                                            >
                                                {this.state.VisaNumber}
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label" className="mandatory">Country:</label>
                                    </div>
                                    <div className="d-block">
                                        <ComboBox
                                            data={this.state.AllCountries}
                                            textField="Name"
                                            dataItemKey="id"
                                            placeholder="Please Select"
                                            value={this.state.SelectedCountry}
                                            onChange={this.handleOnChangeCombo}
                                            name="SelectedCountry"
                                            required={this.state.isVisaNoErrorMessageVisible ? true : false}
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                        />
                                        {this.state.isVisaCountryErrorMessageVisible === true ? (
                                            <span className={'inline-error'}>
                                                Please Select Country
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="">Place of Issue:</label>
                                    </div>
                                    <div className="d-block" id="placeOfIssueToolTip">
                                        <Input
                                            autoComplete="off"
                                            type="Numeric"
                                            name="PlaceOfIssue"
                                            maxLength="20"
                                            value={this.state.PlaceOfIssue}
                                            onChange={this.handleChangePlaceOfIssue}
                                        />
                                        {/*{this.state.isErrorMessageVisible === true ? (*/}
                                        {/*    <span className={this.validateProperty(this.state.PlaceOfIssue)}>*/}
                                        {/*    Please enter a */}
                                        {/*</span>*/}
                                        {/*) : null}*/}
                                        {this.state.PlaceOfIssue ? (
                                            <Tooltip
                                                placement="top"
                                                isOpen={this.state.placeOfIssueToolTipOpen}
                                                target="placeOfIssueToolTip"
                                                toggle={this.toggleplaceOfIssueToolTip}
                                            >
                                                {this.state.PlaceOfIssue}
                                            </Tooltip>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label" className="mandatory">Issue Date:</label>
                                    </div>
                                    <div className="d-block">
                                        <DatePicker
                                            width="100"
                                            name="IssueDate"
                                            onChange={this.handleIssueDateChange}
                                            required={this.state.isVisaIssueDateErrorMessageVisible ? true : false}
                                            value={this.state.IssueDate}
                                            format="MM/dd/yyyy"
                                            max={new Date()}
                                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                        />
                                        {this.state.isVisaIssueDateErrorMessageVisible === true ? (
                                            <span className={'inline-error'}>
                                                Please Select Issue date
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label" className="mandatory">Expiry Date:</label>
                                    </div>

                                    <DatePicker
                                        width="100"
                                        name="ExpiryDate"
                                        onChange={this.handleExpiryDateChange}
                                        required={this.state.isVisaExpiryDateErrorMessageVisible ? true : false}
                                        min={this.state.expiryDateMin}
                                        value={this.state.ExpiryDate}
                                        format="MM/dd/yyyy"
                                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                        disabled={!this.state.permissions.ExpiryDate}
                                    />
                                    <div className="d-block">
                                        {this.state.isVisaExpiryDateErrorMessageVisible === true ? (
                                            <span className={'inline-error'}>
                                                Please Select Expiry date
                                            </span>
                                        ) : null}
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-12 pull-right">

                                    <div className="d-block">
                                    </div>
                                    <div className="d-block pull-right">
                                        <input type="file" name="file" onChange={this.onChangeHandlerVisa} value={""}
                                            className="custom-file-input visa" disabled={!this.state.VisaNumber || !this.state.SelectedCountry || !this.state.IssueDate || !this.state.ExpiryDate} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <Grid
                                        data={orderBy(this.state.userAllVisaDetails, this.state.sort)
                                            .slice(
                                                this.state.skip,
                                                this.state.take + this.state.skip
                                            )}
                                        skip={this.state.skip}
                                        take={this.state.take}
                                        total={this.state.userAllVisaDetails.length}
                                        resizable
                                        sortable={true}
                                        pageable={true}
                                        onPageChange={this.pageChange}
                                        sort={this.state.sort}
                                        onSortChange={(e) => {
                                            this.setState({
                                                sort: e.sort
                                            });
                                        }
                                        }
                                    >
                                        <Column field="VisaNo" title="Visa No" width="80px" />
                                        <Column field="CountryName" title="Country" width="100px" />
                                        <Column field="PlaceOfIssue" title="Place of Issue" />
                                        <Column field="IssueDate" title="Issue Date" />
                                        <Column field="ExpiryDate" title="Expiry Date" />
                                        <Column
                                            field=""
                                            title="Attachments"
                                            cell={props => <UploadedFileCell ApiUrl={apiUrl} {...props} />}
                                        />
                                        <Column
                                            title="Action"
                                            cell={props => (
                                                <DeleteCell
                                                    {...props}
                                                    OnDelete={this.OnDelete}
                                                />
                                            )}
                                            width="120px"
                                        />
                                    </Grid>
                                </div>
                            </div>

                        </TabStripTab>

                        <TabStripTab title="Qualifications">
                            <PanelBar className="accordion-panel">

                                <PanelBarItem className="accordion-tab" expanded={false} title="Professional Experience">
                                    <div className="p-2">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <fieldset>
                                                    <label className="k-form-field">
                                                        <input type="checkbox" id="auth-2fa" className="k-checkbox" />
                                                        <label className="k-checkbox-label" for="auth-2fa">Not Applicable</label>
                                                    </label>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Company:</label>
                                                </div>
                                                <div className="d-block">
                                                    <Input />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Location:</label>
                                                </div>
                                                <div className="d-block">
                                                    <ComboBox />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Title:</label>
                                                </div>
                                                <div className="d-block">
                                                    <Input />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Designation:</label>
                                                </div>
                                                <div className="d-block">
                                                    <Input />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Start Date:</label>
                                                </div>
                                                <div className="d-block">
                                                    <DatePicker />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">End Date:</label>
                                                </div>
                                                <div className="d-block">
                                                    <DatePicker />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <fieldset>
                                                    <label className="k-form-field">
                                                        <input type="checkbox" id="auth-3fa" className="k-checkbox" />
                                                        <label className="k-checkbox-label" for="auth-3fa">Current Position</label>
                                                    </label>
                                                </fieldset>
                                            </div>
                                            <div className="col-md-4 offset-4 btn-align-right">
                                                <Button primary={true}>Add</Button>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <Grid
                                                    style={{ height: '100px' }}
                                                >
                                                    <Column field="ProductID" title="ID" width="40px" />
                                                    <Column field="ProductName" title="Name" width="250px" />
                                                    <Column field="Category.CategoryName" title="CategoryName" />
                                                    <Column field="UnitPrice" title="Price" />
                                                    <Column field="UnitsInStock" title="In stock" />
                                                </Grid>
                                            </div>
                                        </div>

                                    </div>
                                </PanelBarItem>

                                <PanelBarItem className="accordion-tab" expanded={false} title="Education">
                                    <div className="p-2">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <fieldset>
                                                    <label className="k-form-field">
                                                        <input type="checkbox" id="auth-4fa" className="k-checkbox" />
                                                        <label className="k-checkbox-label" for="auth-4fa">Not Applicable</label>
                                                    </label>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Level of Qualification:</label>
                                                </div>
                                                <div className="d-block">
                                                    <ComboBox />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Field:</label>
                                                </div>
                                                <div className="d-block">
                                                    <ComboBox />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Institute:</label>
                                                </div>
                                                <div className="d-block">
                                                    <ComboBox />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Qualification Name:</label>
                                                </div>
                                                <div className="d-block">
                                                    <Input />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Start Date:</label>
                                                </div>
                                                <div className="d-block">
                                                    <DatePicker />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">End Date:</label>
                                                </div>
                                                <div className="d-block">
                                                    <DatePicker />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <fieldset>
                                                    <label className="k-form-field">
                                                        <input type="checkbox" id="auth-5fa" className="k-checkbox" />
                                                        <label className="k-checkbox-label" for="auth-5fa">Not Completed Yet</label>
                                                    </label>
                                                </fieldset>
                                            </div>
                                            <div className="col-md-4 offset-4 btn-align-right">
                                                <Button primary={true}>Add Record</Button>
                                                <Button>Cancel</Button>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <Grid
                                                    style={{ height: '100px' }}
                                                >
                                                    <Column field="ProductID" title="ID" width="40px" />
                                                    <Column field="ProductName" title="Name" width="250px" />
                                                    <Column field="Category.CategoryName" title="CategoryName" />
                                                    <Column field="UnitPrice" title="Price" />
                                                    <Column field="UnitsInStock" title="In stock" />
                                                </Grid>
                                            </div>
                                        </div>

                                    </div>
                                </PanelBarItem>

                                <PanelBarItem className="accordion-tab" expanded={false} title="License &amp; Certification">
                                    <div className="p-2">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <fieldset>
                                                    <label className="k-form-field">
                                                        <input type="checkbox" id="auth-6fa" className="k-checkbox" />
                                                        <label className="k-checkbox-label" for="auth-6fa">Not Applicable</label>
                                                    </label>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Name:</label>
                                                </div>
                                                <div className="d-block">
                                                    <ComboBox />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Field:</label>
                                                </div>
                                                <div className="d-block">
                                                    <ComboBox />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Field:</label>
                                                </div>
                                                <div className="d-block">
                                                    <ComboBox />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Obtained Date:</label>
                                                </div>
                                                <div className="d-block">
                                                    <DatePicker />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Offered Institute:</label>
                                                </div>
                                                <div className="d-block">
                                                    <Input />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 btn-align-right">
                                                <Button primary={true}>Add Record</Button>
                                                <Button>Cancel</Button>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <Grid
                                                    style={{ height: '100px' }}
                                                >
                                                    <Column field="ProductID" title="ID" width="40px" />
                                                    <Column field="ProductName" title="Name" width="250px" />
                                                    <Column field="Category.CategoryName" title="CategoryName" />
                                                    <Column field="UnitPrice" title="Price" />
                                                    <Column field="UnitsInStock" title="In stock" />
                                                </Grid>
                                            </div>
                                        </div>

                                    </div>
                                </PanelBarItem>

                                <PanelBarItem className="accordion-tab" expanded={false} title="Accomplishments">
                                    <div className="p-2">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <fieldset>
                                                    <label className="k-form-field">
                                                        <input type="checkbox" id="auth-7fa" className="k-checkbox" />
                                                        <label className="k-checkbox-label" for="auth-7fa">Not Applicable</label>
                                                    </label>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-md-3">
                                                <div className="d-block">
                                                    <label className="new-label">Accomplishment Type:</label>
                                                </div>
                                                <div className="d-block">
                                                    <ComboBox />
                                                </div>
                                            </div>

                                            <div className="col-md-9">
                                                <div className="d-block">
                                                    <label className="new-label">Details of Accomplishment:</label>
                                                </div>
                                                <div className="d-block">
                                                    <textarea className="w-100" rows="5" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 btn-align-right">
                                                <Button primary={true}>Add</Button>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <Grid
                                                    style={{ height: '100px' }}
                                                >
                                                    <Column field="ProductID" title="ID" width="40px" />
                                                    <Column field="ProductName" title="Name" width="250px" />
                                                    <Column field="Category.CategoryName" title="CategoryName" />
                                                    <Column field="UnitPrice" title="Price" />
                                                    <Column field="UnitsInStock" title="In stock" />
                                                </Grid>
                                            </div>
                                        </div>

                                    </div>

                                </PanelBarItem>

                            </PanelBar>
                        </TabStripTab>

                        <TabStripTab title="Skills">
                            <div className="row">
                                <div className="col-md-12">
                                    <Grid
                                        style={{ height: '100px' }}
                                    >
                                        <Column field="ProductID" title="ID" width="40px" />
                                        <Column field="ProductName" title="Name" width="250px" />
                                        <Column field="Category.CategoryName" title="CategoryName" />
                                        <Column field="UnitPrice" title="Price" />
                                        <Column field="UnitsInStock" title="In stock" />
                                    </Grid>
                                </div>
                            </div>
                        </TabStripTab>

                        <TabStripTab title="Project Experience">
                            <PanelBar className="accordion-panel">

                                <PanelBarItem className="accordion-tab" expanded={false} title="Professional Experience">
                                    <div className="p-2">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <fieldset>
                                                    <label className="k-form-field">
                                                        <input type="checkbox" id="auth-7fa" className="k-checkbox" />
                                                        <label className="k-checkbox-label" for="auth-7fa">Not Applicable</label>
                                                    </label>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Company:</label>
                                                </div>
                                                <div className="d-block">
                                                    <Input />
                                                </div>
                                            </div>

                                            <div className="col-md-10">
                                                <div className="d-block">
                                                    <label className="new-label">Project Description:</label>
                                                </div>
                                                <div className="d-block">
                                                    <textarea className="w-100" rows="5" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">Start Date:</label>
                                                </div>
                                                <div className="d-block">
                                                    <DatePicker />
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="d-block">
                                                    <label className="new-label">End Date:</label>
                                                </div>
                                                <div className="d-block">
                                                    <DatePicker />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 btn-align-right">
                                                <Button primary={true}>Add Record</Button>
                                                <Button>Cancel</Button>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <Grid
                                                    style={{ height: '100px' }}
                                                >
                                                    <Column field="ProductID" title="ID" width="40px" />
                                                    <Column field="ProductName" title="Name" width="250px" />
                                                    <Column field="Category.CategoryName" title="CategoryName" />
                                                    <Column field="UnitPrice" title="Price" />
                                                    <Column field="UnitsInStock" title="In stock" />
                                                </Grid>
                                            </div>
                                        </div>

                                    </div>
                                </PanelBarItem>

                                <PanelBarItem className="accordion-tab" expanded={false} title="Technical Skills">
                                    <div className="p-2">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <fieldset>
                                                    <label className="k-form-field">
                                                        <input type="checkbox" id="auth-8fa" className="k-checkbox" />
                                                        <label className="k-checkbox-label" for="auth-8fa">Not Applicable</label>
                                                    </label>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-md-12">
                                                <textarea className="w-100" rows="5" />
                                            </div>

                                        </div>

                                    </div>
                                </PanelBarItem>

                                <PanelBarItem className="accordion-tab" expanded={false} title="Infor ERP Functional Expertise">
                                    <div className="p-2">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <fieldset>
                                                    <label className="k-form-field">
                                                        <input type="checkbox" id="auth-9fa" className="k-checkbox" />
                                                        <label className="k-checkbox-label" for="auth-9fa">Not Applicable</label>
                                                    </label>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-md-12">
                                                <textarea className="w-100" rows="5" />
                                            </div>

                                        </div>

                                    </div>
                                </PanelBarItem>

                                <PanelBarItem className="accordion-tab" expanded={false} title="Experience">
                                    <div className="p-2">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <fieldset>
                                                    <label className="k-form-field">
                                                        <input type="checkbox" id="auth-10fa" className="k-checkbox" />
                                                        <label className="k-checkbox-label" for="auth-10fa">Not Applicable</label>
                                                    </label>
                                                </fieldset>
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col-md-12">
                                                <textarea className="w-100" rows="5" />
                                            </div>

                                        </div>

                                    </div>
                                </PanelBarItem>

                            </PanelBar>
                        </TabStripTab>

                        <TabStripTab title="Utilization">
                            <div className="row">

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label">To:</label>
                                    </div>
                                    <div className="d-block">
                                        <DatePicker />
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label">From:</label>
                                    </div>
                                    <div className="d-block">
                                        <DatePicker />
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label">Project:</label>
                                    </div>
                                    <div className="d-block">
                                        <ComboBox />
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label">Billable / Non-Billable:</label>
                                    </div>
                                    <div className="d-block">
                                        <ComboBox />
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-12 btn-align-right">
                                    <Button primary={true}>Search</Button>
                                    <Button>Clear</Button>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <Grid
                                        style={{ height: '100px' }}
                                    >
                                        <Column field="ProductID" title="ID" width="40px" />
                                        <Column field="ProductName" title="Name" width="250px" />
                                        <Column field="Category.CategoryName" title="CategoryName" />
                                        <Column field="UnitPrice" title="Price" />
                                        <Column field="UnitsInStock" title="In stock" />
                                    </Grid>
                                </div>
                            </div>

                        </TabStripTab>
                    </TabStrip>
                </div>
                {this.state.showMessageDialog === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                                OK
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
                <div>
                    {this.state.showCancelConfirmation === true && (
                        <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>
                                This form contains unsaved changes. Do you want to close it?
                            </p>
                            <DialogActionsBar>
                                <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                                    No
                                </button>
                                <button className="k-button modal-primary" onClick={this.navigateBack}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
                {
                    this.state.showMessageDialog === true && (
                        <Dialog title="Confirm" onClose={this.toggleMessageDialog} width="400px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                            <DialogActionsBar>
                                <button className="k-button" onClick={this.onDeleteOk}>
                                    Yes
                                </button>
                                <button className="k-button modal-primary" onClick={this.toggleConfirmDialog}>
                                    No
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )
                }



                <div>
                    {this.state.visible === true && (
                        <Dialog
                            title={this.state.dialogTitle}
                            onClose={this.toggleDialog}
                            width="300px">
                            <p style={{ margin: "25px", textAlign: "center" }}>
                                {this.state.dialogMessage}
                            </p>
                            <DialogActionsBar>
                                <button
                                    className="k-button modal-primary"
                                    onClick={
                                        this.state.dialogTitle === "Error" || this.state.dialogTitle === "Upload Status"
                                            ? this.toggleDialog
                                            : this.redirectToUserSearch
                                    }>
                                    OK
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}

                    {this.state.showFileReplaceConfirmDialog === true && (
                        <Dialog title="Confirm" onClose={this.toggleFileReplaceConfirmDialog} width="400px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmReplaceDialogMessage}</p>
                            <DialogActionsBar>
                                <button className="k-button" onClick={this.toggleFileReplaceConfirmDialog}>
                                    No
                                </button>
                                <button className="k-button modal-primary" onClick={this.replaceFile} disabled={this.state.replaceBtnDisabled}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}

                    <div>
                        {this.state.deployHistoryDialog && <Dialog title={"Update Deployable Status"} onClose={this.cancelDeployHistoryDialog} >
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label mandatory">Deployable Status:</label>
                                    </div>
                                    <div className="d-block">
                                        <ComboBox
                                            data={this.state.DeployableStatus}
                                            textField="name"
                                            dataItemKey="value"
                                            value={this.state.SelectedDeployableStatus}
                                            onChange={this.handleOnChangeCombo}
                                            name="SelectedDeployableStatus"
                                            required={true}
                                            placeholder="Please Select"
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label mandatory">Deployability %:</label>
                                    </div>
                                    <div className="d-block">
                                        <NumericTextBox
                                            autoComplete="off"
                                            type="Numeric"
                                            name="DeployableStatusPercentage"
                                            required={true}
                                            min={0}
                                            max={100}
                                            width={'100%'}
                                            value={this.state.DeployableStatusPercentage}
                                            onChange={this.handleChangeDeployableStatusPercentage}
                                            textField="name"
                                            dataItemKey="value"
                                            disabled={!this.state.permissions.DeployableStatusPercentage}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label mandatory">Start Date:</label>
                                    </div>
                                    <div className="d-block">
                                        <DatePicker
                                            width="100"
                                            name="DeployabilityStartDate"
                                            onChange={this.handleDeployabilityStartDateChange}
                                            required={true}
                                            min={this.state.minDeployabilityStartDate}
                                            value={this.state.deployabilityStartDate}
                                            format="MM/dd/yyyy"
                                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block">
                                        <label className="new-label"></label>
                                    </div>
                                    <div className="d-block">
                                        <Button onClick={this.onClickDeployabilityUpdate} type="button" disabled={!(this.state.SelectedDeployableStatus && this.state.deployabilityStartDate)} primary={true}>Update</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <Grid
                                        data={orderBy(this.state.deployabilityHistory, this.state.sort)
                                            .slice(
                                                this.state.skip,
                                                this.state.take + this.state.skip
                                            )}
                                        skip={this.state.skip}
                                        take={this.state.take}
                                        total={this.state.deployabilityHistory.length}
                                        resizable
                                        sortable={true}
                                        pageable={true}
                                        onPageChange={this.pageChange}
                                        sort={this.state.sort}
                                        onSortChange={(e) => {
                                            this.setState({
                                                sort: e.sort
                                            });
                                        }
                                        }
                                    >
                                        <Column field="DeployableStatus.name" title="Deployable Status" />
                                        <Column field="DeployableStatusPercentage" title="Deployability %" />
                                        <Column field="StartDate" title="Start Date" />
                                        <Column field="EndDate" title="End Date" />
                                        <Column
                                            title="Action"
                                            cell={props => (
                                                <EditDeployabilityActionCell
                                                    {...props}
                                                    OnEdit={this.OnEdit}
                                                />
                                            )}
                                            width="120px"
                                        />
                                    </Grid>
                                </div>
                            </div>
                            <DialogActionsBar>
                                <button className="k-button" type="button" onClick={this.cancelDeployHistoryDialog}>Cancel</button>
                                <button className="k-button modal-primary" type="button" onClick={this.saveDeployHistoryDialog}>Done</button>
                            </DialogActionsBar>
                        </Dialog>}
                    </div>

                </div>
                <div>
                    {this.state.showDeployabilityCancelConfirmation === true && (
                        <Dialog title="Please Confirm" onClose={this.toggleDeployabilityCancelConfirmDialog} width="300px" >
                            <p style={{ margin: '25px', textAlign: 'center' }}>
                                This form contains unsaved changes. Do you want to close it?
                            </p>
                            <DialogActionsBar>
                                <button className="k-button active" onClick={this.toggleDeployabilityCancelConfirmDialog}>
                                    No
                                </button>
                                <button className="k-button modal-primary" onClick={this.closeDeployabilityDialog}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
                <Loader loading={this.state.loader} />
            </div>
        );
    }
    onClickCancel = event => {
        if (this.state.isFormDirty) {
            this.toggleCancelConfirmDialog();
        } else {
            this.navigateBack(event);
        }
    };

    toggleCancelConfirmDialog = () => {
        if (this.isMount) {
            this.setState({
                showCancelConfirmation: !this.state.showCancelConfirmation
            });
        }
    };

    navigateBack = event => {
        if (this.isMount) {
            this.setState({
                redirectToUserSearch: true,
                visible: !this.state.visible
            });
        }
    };
}

export default EmployeeUserProfile;
