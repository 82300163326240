import React, { Component } from 'react';

class StatusCell extends Component {
    render() {
        const value = this.props.dataItem.IsActive;
        const deleted = this.props.dataItem.IsDeleted;

        if (deleted) {
            return <td>Deleted</td>;
        } else {
            if (value) {
                return <td>Active</td>;
            } else {
                return <td>Inactive</td>;
            }
        }
    }
}

export default StatusCell;
