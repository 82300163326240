import React, { Component } from 'react';

class StatusCell extends Component {
  render() {
    const value = this.props.dataItem.IsActive;
    if (value === true) {
      return <td>Active</td>;
    } else {
      return <td>Inactive</td>;
    }

  }
}

export default StatusCell;
