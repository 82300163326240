import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class RateCardActionsCell extends Component {
    render() {
        const { OnDeactivate } = this.props;
        const { OnActivate } = this.props;
        const rateCard = this.props.dataItem;
        return (
            <td className="action-buttons" >
                {(window.USER_PERMISSION.indexOf('VW_RT_CRD_MGT') > -1) &&
                <Link
                    to={{
                        pathname: '/admin/ratecardedit/ratecardedit',
                        rateCard: rateCard,
                        action: 'view'
                    }}
                >
                    <div className="rounded-link">
                        <span className="k-icon k-i-preview" title="View" />
                    </div>
                </Link>
                }
                {rateCard.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_RATE_CRD') > -1) &&
                    <Link
                        to={{
                            pathname: '/admin/ratecardedit/ratecardedit',
                            rateCard: rateCard,
                            action: 'edit'
                        }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-edit" title="Edit" />
                        </div>
                    </Link>
                }
                {rateCard.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_RATE_CRD') > -1) && (
                    <Link
                        to={this.props}
                        onClick={(e) => { e.preventDefault(); OnDeactivate(this.props.dataItem.id); }}
                    >
                        <div className="rounded-link">

                            <span className="k-icon k-i-unsort" title="Deactivate" />
                        </div>
                    </Link>
                )}
                {rateCard.IsActive === false && (window.USER_PERMISSION.indexOf('EDT_RATE_CRD') > -1) && (
                    <Link
                        to={this.props}
                        onClick={(e) => { e.preventDefault(); OnActivate(this.props.dataItem.id); }}
                    >
                        <div className="rounded-link">

                            <span className="k-icon k-i-check" title="Activate" />
                        </div>
                    </Link>
                )}
                {rateCard.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_RATE_CRD') > -1) && (
                    <Link
                        to={{
                            pathname: '/admin/ratecardedit/ratecardedit',
                            action: 'duplicate',
                            rateCard: rateCard
                        }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-backward-element" title="Duplicate" />
                        </div>
                    </Link>
                )}
            </td>
        );
    }
}

export default RateCardActionsCell;
