import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import { Button } from '@progress/kendo-react-buttons';
import moment from 'moment';
import {formatNumber, isWeekend} from "../../integration/CommonUtils";
import ResourceRequestStatusENUMS from "../components/ResourceRequestStatusEnums";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ExcelDownload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasUnSavedData: false,
            calculations: null,
            disableExcelDownload: false,
            didRun: false,
            calculation: null,
            Info: null,
            ResourceMix: null,
            OtherExpensesExcel: null,
            NullData: [{ columns: [], data: [] }],
            UndefinedDate: '',
            dailyAllocations: null,
        };
    }

    componentDidMount() {
        if (this.props.updateData === true) {
            this.updateCalculations();
        }
    }

    updateCalculations = async() => {
        await this.populateCalculationsGrid();
        await this.populateResourceMix();
    };

    componentDidUpdate = async() => {

        if (this.props.updateData === true) {
            this.props.dataUpdated();
            await this.updateCalculations();
            document.getElementById("rrexcel").click();
        }
    }

    populateCalculationsGrid = () => {
        var date = new Date();
        let CRRow = [];

        CRRow.push([{ value: 'Report Name - RR_Summary' }, { value: '' }, { value: '' }],
            [
                { value: 'Genarated Date/Time - ' + moment(date).format('MM-DD-YYYY hh:mm a') },
                { value: '' },
                { value: '' }
            ],
            [{ value: '' }, { value: '' }, { value: '' }],
            [
                { value: 'Project Details', style: { font: { sz: '12', bold: true } } },
                { value: '' },
                { value: '' }
            ],
            [
                {
                    value: 'Project Name'
                },
                { value: '' },
                {
                    value:
                        this.props.project.ProjectName ?
                            this.props.project.ProjectName: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'Project Id'
                },
                { value: '' },
                {
                    value:
                        this.props.project.UniqueId ?
                            this.props.project.UniqueId: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'Start Date'
                },
                { value: '' },
                {
                    value:
                        this.props.project.StartDate ?
                            this.props.project.StartDate: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'End Date'
                },
                { value: '' },
                {
                    value:
                        this.props.endDate ?
                            this.props.endDate: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'Billing Region'
                },
                { value: '' },
                {
                    value:
                        this.props.project.Region.Name ?
                            this.props.project.Region.Name: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'Country'
                },
                { value: '' },
                {
                    value:
                        this.props.project.Country.Name ?
                            this.props.project.Country.Name: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'Customer'
                },
                { value: '' },
                {
                    value:
                        this.props.project.Customer.Name ?
                            this.props.project.Customer.Name: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'OP Id'
                },
                { value: '' },
                {
                    value:
                        this.props.project.OPID ?
                            this.props.project.OPID: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'Department'
                },
                { value: '' },
                {
                    value:
                        this.props.project.Division.Name ?
                            this.props.project.Division.Name: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'Engagement Type'
                },
                { value: '' },
                {
                    value:
                        this.props.project.Type.Name ?
                            this.props.project.Type.Name: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'Offering'
                },
                { value: '' },
                {
                    value:
                        this.props.project.Offering.Name ?
                            this.props.project.Offering.Name: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'Billing Method'
                },
                { value: '' },
                {
                    value:
                        this.props.project.PaymentMethodRecord.Name ?
                            this.props.project.PaymentMethodRecord.Name: this.state.UndefinedDate
                }
            ],
            [{ value: '' }, { value: '' }, { value: '' }],

            [
                { value: 'CR NAME' },
                { value: 'CR ID' },
                { value: 'CMS VERSION' }
            ]);

        for(const item of this.props.project.ChangeRequests){

            var temp = [
                { value:  item.CRName},
                { value: item.UniqueId},
                { value: item.cmsVersion}
            ];
            CRRow.push(temp);


        }

        CRRow.push(
            [{ value: '' }, { value: '' }, { value: '' }],

            [{ value: '' }, { value: 'Approved CMS',style: { font: { sz: '12', bold: true } } }, { value: '' },{ value: 'Ongoing CMS',style: { font: { sz: '12', bold: true } } }, { value: '' }, { value: 'Variance',style: { font: { sz: '12', bold: true } } }],
            [
                {
                    value: 'Duration |Effort)',
                    style: { font: { sz: '12', bold: true } }
                },
                { value: '' },
                { value: '' }
            ],
            [
                { value: 'Est. Project Duration (Calendar Days)' },
                { value: '' },
                { value: '' },
                { value: '' },
                { value: this.props.calculatedValues.estDuration },
                { value: '' },
                { value: '' }
                ],
            [
                { value: 'Total Contracted Effort Days | Hours' },
                {
                    value: this.props.calculatedValues.totalEffortDaysCMS ? formatNumber(Number(this.props.calculatedValues.totalEffortDaysCMS)) : '0.00'
                },
                {
                    value: formatNumber(Number(this.props.calculatedValues.totalEffortDaysCMS * 8))
                },
                {
                    value: formatNumber(Number(this.props.calculatedValues.totalEffortRM))
                },
                {
                    value : formatNumber(Number(this.props.calculatedValues.totalEffortRM * 8))
                },
                {
                    value : this.props.calculatedValues.totalEffortDaysCMS !== 0 ? ((((formatNumber(Number(this.props.calculatedValues.totalEffortRM)) - formatNumber(Number(this.props.calculatedValues.totalEffortDaysCMS))) / formatNumber(Number(this.props.calculatedValues.totalEffortDaysCMS))) * 100).toFixed(2)) : 0 + '%'
                },
                {
                    value: (formatNumber(Number(this.props.calculatedValues.totalEffortRM)) - formatNumber(Number(this.props.calculatedValues.totalEffortDaysCMS))).toFixed(2)
                }
            ],
            [
                { value: 'Fixed Price Effort' },
                {
                    value: formatNumber(Number(this.props.calculatedValues.fixedPriceEffortApproved))
                },
                {
                    value: ''
                },
                {
                    value: formatNumber(Number(this.props.calculatedValues.fixedPriceEffortOngoing))
                },
                {
                    value: ''
                },
                {
                    value:(this.props.calculatedValues.fixedPriceEffortApproved !== 0.00 ? ((((((Number(this.props.calculatedValues.fixedPriceEffortOngoing)) - (Number(this.props.calculatedValues.fixedPriceEffortApproved)))/ (Number(this.props.calculatedValues.fixedPriceEffortApproved)))) * 100).toFixed(2)) : 0.00)  + '%'
                },
                {
                    value: ((Number(this.props.calculatedValues.fixedPriceEffortOngoing)) - (Number(this.props.calculatedValues.fixedPriceEffortApproved))).toFixed(2)
                },
            ],
            [
                { value: 'T & M Effort' },
                {
                    value: formatNumber(Number(this.props.calculatedValues.TMEffortOngoingApproved))
                },
                {
                    value: ''
                },
                {
                    value: formatNumber(Number(this.props.calculatedValues.TMEffortOngoing))
                },
                {
                    value: ''
                },
                {
                    value:(this.props.calculatedValues.TMEffortOngoingApproved !== 0.00 ? ((((((Number(this.props.calculatedValues.TMEffortOngoing)) - (Number(this.props.calculatedValues.TMEffortOngoingApproved)))/ (Number(this.props.calculatedValues.TMEffortOngoingApproved)))) * 100).toFixed(2)) : 0.00)  + '%'
                },
                {
                    value: (formatNumber(Number(this.props.calculatedValues.TMEffortOngoing)) - formatNumber(Number(this.props.calculatedValues.TMEffortOngoingApproved))).toFixed(2)
                },
            ],
            [{ value: '' }, { value: '' }, { value: '' }],
            [
                //
                {
                    value: 'Project Value | Project Revenue',
                    style: { font: { sz: '12', bold: true } }
                },
                { value: '' },
                { value: '' }
            ],
            [
                { value: 'Revenue to Fortude after discount'},
                { value: '' },
                {
                    value: formatNumber(Number(this.props.calculatedValues.discountedRevenueToFortudeCMS))
                },
                {
                    value: ''
                },
                {
                    value: formatNumber(Number(this.props.calculatedValues.discountedRevenueToFortude))
                },
                {
                    value: this.props.calculatedValues.discountedRevenueToFortudeCMS !== 0 ? ((((((Number(this.props.calculatedValues.discountedRevenueToFortude)) - (Number(this.props.calculatedValues.discountedRevenueToFortudeCMS))) / (Number(this.props.calculatedValues.discountedRevenueToFortudeCMS)))) * 100).toFixed(2)) : 0 + '%'
                },
                {
                    value: ((Number(this.props.calculatedValues.discountedRevenueToFortude)) - (Number(this.props.calculatedValues.discountedRevenueToFortudeCMS))).toFixed(2)
                },
            ],
            [
                { value: 'Average rate per day' },
                {
                    value: ''
                },
                {
                    value: formatNumber(Number(this.props.calculatedValues.discountedRevenueToFortudeCMS / this.props.calculatedValues.totalEffortDaysCMS))
                },
                {
                    value: ''
                },
                {
                    value: formatNumber(Number(this.props.calculatedValues.discountedRevenueToFortude / this.props.calculatedValues.totalEffortRM))
                },
                {
                    value: (this.props.calculatedValues.totalEffortRM !== 0 && this.props.calculatedValues.totalEffortDaysCMS !== 0 && this.props.calculatedValues.discountedRevenueToFortudeCMS !== 0) ? ((((((Number(this.props.calculatedValues.discountedRevenueToFortude / this.props.calculatedValues.totalEffortRM)) - (Number(this.props.calculatedValues.discountedRevenueToFortudeCMS / this.props.calculatedValues.totalEffortDaysCMS))) / (Number(this.props.calculatedValues.discountedRevenueToFortudeCMS / this.props.calculatedValues.totalEffortDaysCMS)))) * 100).toFixed(2)) : 0 + '%'
                },
                {
                    value: (this.props.calculatedValues.totalEffortRM !== 0 && this.props.calculatedValues.totalEffortDaysCMS) ? (((Number(this.props.calculatedValues.discountedRevenueToFortude / this.props.calculatedValues.totalEffortRM)) - (Number(this.props.calculatedValues.discountedRevenueToFortudeCMS / this.props.calculatedValues.totalEffortDaysCMS))).toFixed(2)) : 0
                },
            ],
            [{ value: '' }, { value: '' }, { value: '' }],
            [
                {
                    value: 'Profitability',
                    style: { font: { sz: '12', bold: true } }
                },
                { value: '' },
                { value: '' }
            ],
            [
                //
                { value: 'Gross Margin % | Gross Profit From Resource Deployment' },
                {
                    value: this.props.calculatedValues.GrossProfitMargin ? this.props.calculatedValues.GrossProfitMargin + '%' : '0.00%'
                },
                {
                    value: (this.props.calculatedValues.TotalGrossProfit ? (this.props.calculatedValues.TotalGrossProfit.toFixed(2)) : '0.00')
                },
                {
                    value: this.props.location.action !== 'RPApproveWF' ?
                        (this.props.calculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((this.props.calculatedValues.TotalOngoingGrossProfit / this.props.calculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')
                        : `${(this.props.calculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((this.props.calculatedValues.TotalOngoingGrossProfit / this.props.calculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')} | ${(this.props.approvedCalculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((this.props.approvedCalculatedValues.TotalOngoingGrossProfit / this.props.approvedCalculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')}`,
                },
                {
                    value: this.props.location.action !== 'RPApproveWF' ?
                        (this.props.calculatedValues.TotalOngoingGrossProfit ? formatNumber(Number(this.props.calculatedValues.TotalOngoingGrossProfit)) : '0.00')
                        : `${(this.props.calculatedValues.TotalOngoingGrossProfit ? formatNumber(Number(this.props.calculatedValues.TotalOngoingGrossProfit)) : '0.00')} | ${(this.props.calculatedValues.TotalOngoingGrossProfit ? formatNumber(Number(this.props.calculatedValues.TotalOngoingGrossProfit)) : '0.00')}`,
                },
                {
                    value: ((this.props.calculatedValues.GrossVeriance / (this.props.calculatedValues.TotalGrossProfit ? Number(this.props.calculatedValues.TotalGrossProfit) : 0.00)) * 100).toFixed(2) + '%',
                },
                {
                    value: ((this.props.calculatedValues.GrossVeriance ? (Number(this.props.calculatedValues.GrossVeriance)) : 0.00)).toFixed(2)
                }
            ],
            [
                //
                { value: 'Net Profit/ (loss) | Net Margin %' },
                {
                    value: this.props.calculatedValues.discountedRevenueToFortudeCMS ? formatNumber(Number(parseFloat(((this.props.calculatedValues.netProfitLossCMS / this.props.calculatedValues.discountedRevenueToFortudeCMS) * 100)))) + '%' : '0.00%'
                },
                {
                    value: this.props.calculatedValues.netProfitLossCMS ? formatNumber(Number(this.props.calculatedValues.netProfitLossCMS)) : '0.00'
                },
                {
                    value: this.props.calculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((this.props.calculatedValues.netProfit / this.props.calculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%'
                },
                {
                    value: this.props.calculatedValues.netProfit ? formatNumber(Number(this.props.calculatedValues.netProfit)) : '0.00'
                },
                {
                    value: (this.props.calculatedValues.netProfitLossCMS !== 0) ? ((((Number(this.props.calculatedValues.netProfit) - (Number(this.props.calculatedValues.netProfitLossCMS))) / Number(this.props.calculatedValues.netProfitLossCMS)) * 100).toFixed(2)) + '%' : '0.00%',
                },
                {
                    value: ((this.props.calculatedValues.netProfit ? (Number(this.props.calculatedValues.netProfit)) : 0.00) - (this.props.calculatedValues.netProfitLossCMS ? (Number(this.props.calculatedValues.netProfitLossCMS)) : 0.00)).toFixed(2)
                }
            ]

        );

        if (this.props.calculatedValues) {
            const calculations = [
                {
                    columns: [
                        { title: '', width: { wch: 50 } }, // pixels width
                        { title: '', width: { wch: 15 } }, // char width
                        { title: '', width: { wpx: 100 } },
                        { title: '', width: { wch: 15 } },
                        { title: '', width: { wpx: 100 } },
                        { title: '', width: { wch: 15 } },
                        { title: '', width: { wpx: 100 } },
                    ],
                    data: CRRow
                }
            ];

            this.setState({ calculation: calculations });
        }
    };


    populateResourceMix = async () => {

        let data = [];
        let allocationDataRequests = [];
        let tmp = [];
        let totalValues = [];
        var projList = '';
        let res =  this.props.resourceMix;


        for (let i = 0; i < this.props.resourceMix.length; i++) {

            if(this.props.resourceMix[i].ResourceRequestSubLine.length > 0 ){
                let resource = this.props.resourceMix[i];
                for (let t = 0; t < resource.ResourceRequestSubLine.length; t++) {

                    if(resource.ResourceRequestSubLine[t].StartDate === null || resource.ResourceRequestSubLine[t].StartDate === ''){

                        if(resource.ResourceRequestSubLine[t].Phase && resource.ResourceRequestSubLine[t].Phase.StartDate){

                            let value = resource.ResourceRequestSubLine[t].Phase;

                            if(value.StartDate < this.props.project.StartDate){
                                resource.ResourceRequestSubLine[t].StartDate = null;
                            }
                            else {
                                resource.ResourceRequestSubLine[t].StartDate = value.StartDate;
                            }

                        }
                        else if (resource.ResourceRequestSubLine[t].SubPhase && resource.ResourceRequestSubLine[t].SubPhase.StartDate){
                            let value = resource.ResourceRequestSubLine[t].SubPhase;

                            if(value.StartDate < this.props.project.StartDate){
                                resource.ResourceRequestSubLine[t].StartDate = null;
                            }
                            else {
                                resource.ResourceRequestSubLine[t].StartDate = value.StartDate;
                            }

                        }
                        else {

                            resource.ResourceRequestSubLine[t].StartDate = this.props.project.StartDate;
                        }
                    }
                    // else{

                    //     if( resource.ResourceRequestSubLine[t].StartDate < this.props.project.StartDate){
                    //         resource.ResourceRequestSubLine[t].StartDate = null;

                    //     }
                    //     else {
                    //         resource.ResourceRequestSubLine[t].StartDate = resource.ResourceRequestSubLine[t].StartDate;
                    //     }
                    // }

                }
            }

            if(this.props.resourceMix[i].StartDate === null || this.props.resourceMix[i].StartDate === ''){

                if(this.props.resourceMix[i].Phase && this.props.resourceMix[i].Phase.StartDate){

                    let value = this.props.resourceMix[i].Phase;

                    if(value.StartDate < this.props.project.StartDate){
                        this.props.resourceMix[i].StartDate = null;
                    }
                    else {
                        this.props.resourceMix[i].StartDate = value.StartDate;

                    }

                }
                else if (this.props.resourceMix[i].SubPhase && this.props.resourceMix[i].SubPhase.StartDate){
                    let value = this.props.resourceMix[i].SubPhase;

                    if(value.StartDate < this.props.project.StartDate){
                        this.props.resourceMix[i].StartDate = null;
                    }
                    else {
                        this.props.resourceMix[i].StartDate = value.StartDate;
                    }

                }
                else {
                    this.props.resourceMix[i].StartDate = this.props.project.StartDate;

                }
            }
        }


        if(this.props.newResProjectOrCRNames && this.props.newResProjectOrCRNames.length > 0){
            for(const item of await this.props.newResProjectOrCRNames){
                if (projList === '') {
                    projList = item.name;
                } else {
                    projList = projList + ', ' + item.name;
                }
            };
        }
        else {
            projList.concat(this.state.UndefinedDate);
        }
        tmp.push(
            [{ value: '' }, { value: '' }],
            [
                {
                    value: 'Project/CR'
                },
                { value: projList },
            ]
        );

        /*if(this.props.newResProjectOrCRName){
            for(const item of this.props.newResProjectOrCRName){
                var temp = [
                    { value:  item.Name},

                ];
                tmp.push(temp);
            };
        }
        else{
            tmp.push(
                [{ value: this.state.UndefinedDate }],
            );
        }*/


        var statusList = '';

        if(this.props.SelectedLineStatuses && this.props.SelectedLineStatuses.length > 0){
            for(const item of this.props.SelectedLineStatuses){
                if (statusList === '') {
                    statusList = item.name;
                } else {
                    statusList = statusList + ', ' + item.name;
                }

            };
        }
        else {
            statusList.concat(this.state.UndefinedDate);
        }
        tmp.push(
            [
                {
                    value: 'Status'
                },
                { value: statusList },
            ],
            [{ value: '' }, { value: '' }]
        );

        tmp.push(
            [
                {
                    value: 'Legend',
                    style: {font: { bold: true}}
                },
                { value: '' },
            ],
            [
                {
                     value: 'AAA', 
                },
                { value: 'Main resource line without edits or allocation adjustments' }
            ],
            [
                {
                     value: 'AAA', 
                     style: {font: {bold: true, color: {rgb: 'FFA30000'}} }
                },
                { value: 'Soft Booking Resource Allocation' }
            ],
            [
                {
                     value: 'AAA', 
                     style: {font: { bold: true}, fill: { fgColor: { rgb: 'FFffc7c7' }}} 
                },
                { value: 'Main Resource Line Split into Multiple Sub-lines' }
            ],
            [
                {
                     value: 'AAA', 
                     style: {font: { bold: true}, fill: { fgColor: { rgb: 'FFcfcfcc' }}} 
                },
                { value: 'Sub-line without Allocation % Adjustments' }
            ],
            [
                {
                     value: 'AAA', 
                     style: {font: { bold: true, color: {rgb: 'FF000096'}}, fill: { fgColor: { rgb: 'FFcfcfcc' }}} 
                },
                { value: 'Sub-line with Allocation % Adjustments' }
            ],
            [
                {
                     value: 'AAA', 
                     style: {font: { bold: true, color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' }}} 
                },
                { value: 'Soft Booking Sub-line' }
            ],
            [
                {
                     value: 'AAA', 
                     style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                },
                { value: 'Main Line with Allocation % Adjustments' }
            ],
            [{ value: '' }, { value: '' }]
        );

        totalValues.push(
            [{ value: '' }, { value: '' }, { value: '' }],
            [
                {
                    value: 'Total Cost'
                },
                { value: '' },
                {
                    value:
                        this.props.filteredTotalCost ?
                            this.props.filteredTotalCost: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'Total Revenue'
                },
                { value: '' },
                {
                    value:
                        this.props.filteredTotalRevenue ?
                            this.props.filteredTotalRevenue: this.state.UndefinedDate
                }
            ],
            [
                {
                    value: 'Cost To Revenue Ratio'
                },
                { value: '' },
                {
                    value:
                        this.props.filteredCostToRevenueRatio ?
                            this.props.filteredCostToRevenueRatio: this.state.UndefinedDate
                }
            ],
            [{ value: '' }, { value: '' }, { value: '' }],
        );


        if (this.props.resourceMix) {

            for (let i = 0; i < this.props.resourceMix.length; i++) {


                if (this.props.resourceMix[i]) {


                    let res = this.props.resourceMix[i]



                    if(this.props.resourceMix[i].IsSoftBookingResource === true || 
                        res.ResourceRequestSubLine && res.ResourceRequestSubLine.length > 0){

                        if (this.props.resourceMix[i].Status === "COMPLETE" || this.props.resourceMix[i].Status === "CLOSE") {
                            allocationDataRequests.push(res);
                        }

                        data.push([
                            {
                                value: this.props.resourceMix[i].Type === "ChangeRequest"
                                    ? this.props.resourceMix[i].ChangeRequest.CRName
                                    : this.props.resourceMix[i].Project.ProjectName,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                        {font: { bold: true}, fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: this.props.resourceMix[i].Phase
                                    ? this.props.resourceMix[i].Phase.Text
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true},fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },

                            {
                                value: this.props.resourceMix[i].SubPhase
                                    ? this.props.resourceMix[i].SubPhase.Text
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true},fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: this.props.resourceMix[i].dataValueProjectRole.Name
                                    ? this.props.resourceMix[i].dataValueProjectRole.Name
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true},fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: this.props.resourceMix[i].dataValueCostDesignation.Name
                                    ? this.props.resourceMix[i].dataValueCostDesignation.Name
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true},fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: this.props.resourceMix[i].dataValueResourceRegion.Name
                                    ? this.props.resourceMix[i].dataValueResourceRegion.Name
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true},fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: this.props.resourceMix[i].OnsiteStatus
                                    ? this.props.resourceMix[i].OnsiteStatus
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true},fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: res.ResourceRequestSubLine.length > 0 ? this.state.UndefinedDate 
                                    : this.props.resourceMix[i].StartDate
                                    ? this.props.resourceMix[i].StartDate
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true},fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: res.ResourceRequestSubLine.length > 0 ? this.state.UndefinedDate 
                                    : this.props.resourceMix[i].EndDate
                                    ? this.props.resourceMix[i].EndDate
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true},fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: res.ResourceRequestSubLine.length > 0 ? this.state.UndefinedDate 
                                    : this.props.resourceMix[i].EffortDays
                                    ? this.props.resourceMix[i].EffortDays
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true},fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: res.ResourceRequestSubLine.length > 0 ? this.state.UndefinedDate 
                                    : this.props.resourceMix[i].AllocationPercentage
                                    ? this.props.resourceMix[i].AllocationPercentage
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true},fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: res.ResourceRequestSubLine.length > 0 ? this.state.UndefinedDate 
                                    : this.props.resourceMix[i].AllocatedResource
                                    ? this.props.resourceMix[i].AllocatedResource.Name
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true}, fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: res.ResourceRequestSubLine.length > 0 ? this.state.UndefinedDate 
                                    : this.props.resourceMix[i].AllocatedDesignation
                                    ? this.props.resourceMix[i].AllocatedDesignation
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true}, fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: res.ResourceRequestSubLine.length > 0 ? this.state.UndefinedDate 
                                    : this.props.resourceMix[i].dataValueRateType
                                    ? this.props.resourceMix[i].dataValueRateType.Name
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true}, fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: res.ResourceRequestSubLine.length > 0 ? this.state.UndefinedDate 
                                    : this.props.resourceMix[i].RateValue
                                    ? this.props.resourceMix[i].RateValue
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true}, fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: res.ResourceRequestSubLine.length > 0 ? this.state.UndefinedDate 
                                    : this.props.resourceMix[i].TotalRevenue
                                    ? this.props.resourceMix[i].TotalRevenue
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true}, fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: res.ResourceRequestSubLine.length > 0 ? this.state.UndefinedDate 
                                    : this.props.resourceMix[i].TotalCost
                                    ? this.props.resourceMix[i].TotalCost
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true}, fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: res.ResourceRequestSubLine.length > 0 ? this.state.UndefinedDate 
                                    : this.props.resourceMix[i].CostToRevenueRatio
                                    ? this.props.resourceMix[i].CostToRevenueRatio
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true}, fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                            {
                                value: this.props.resourceMix[i].Status
                                    ? ResourceRequestStatusENUMS[this.props.resourceMix[i].Status]
                                    : this.state.UndefinedDate,
                                style: res.ResourceRequestSubLine.length > 0 ? 
                                    {font: { bold: true}, fill: { fgColor: { rgb: 'FFffc7c7' }}} : {font: {bold: true, color: {rgb: 'FFA30000'}} }
                            },
                        ]);

                    }
                else {

                    if (res.ResourceRequestSubLine && res.ResourceRequestSubLine.length <= 0 && 
                        res.AllocationPercentageHistory && res.AllocationPercentageHistory.length > 1) {
                        
                        if (this.props.resourceMix[i].Status === "COMPLETE" || this.props.resourceMix[i].Status === "CLOSE") {
                            allocationDataRequests.push(res);
                        }
                        
                        data.push([
                            {
                                value: this.props.resourceMix[i].Type === "ChangeRequest"
                                    ? this.props.resourceMix[i].ChangeRequest.CRName
                                    : this.props.resourceMix[i].Project.ProjectName,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].Phase
                                    ? this.props.resourceMix[i].Phase.Text
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].SubPhase
                                    ? this.props.resourceMix[i].SubPhase.Text
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].dataValueProjectRole
                                    ? this.props.resourceMix[i].dataValueProjectRole.Name
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}}}
                            },
                            {
                                value: this.props.resourceMix[i].dataValueCostDesignation.Name
                                    ? this.props.resourceMix[i].dataValueCostDesignation.Name
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}}}
                            },
                            {
                                value: this.props.resourceMix[i].dataValueResourceRegion
                                    ? this.props.resourceMix[i].dataValueResourceRegion.Name
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}}}
                            },
                            {
                                value: this.props.resourceMix[i].OnsiteStatus
                                    ? this.props.resourceMix[i].OnsiteStatus
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].StartDate
                                    ? this.props.resourceMix[i].StartDate
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].EndDate
                                    ? this.props.resourceMix[i].EndDate
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].EffortDays
                                    ? this.props.resourceMix[i].EffortDays
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].CurrentAllocationPercentage
                                    ? this.props.resourceMix[i].CurrentAllocationPercentage
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].AllocatedResource
                                    ? this.props.resourceMix[i].AllocatedResource.Name
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].AllocatedDesignation
                                    ? this.props.resourceMix[i].AllocatedDesignation
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].dataValueRateType
                                    ? this.props.resourceMix[i].dataValueRateType.Name
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].RateValue
                                    ? this.props.resourceMix[i].RateValue
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].TotalRevenue
                                    ? (this.props.resourceMix[i].TotalRevenue)
                                    : '0.00',
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].TotalCost
                                    ? this.props.resourceMix[i].TotalCost
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].CostToRevenueRatio
                                    ? this.props.resourceMix[i].CostToRevenueRatio
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                            {
                                value: this.props.resourceMix[i].Status
                                    ? ResourceRequestStatusENUMS[this.props.resourceMix[i].Status]
                                    : this.state.UndefinedDate,
                                style: {font: {bold: true, color: {rgb: 'FF000096'}} }
                            },
                        ]);

                    } else {

                        if (this.props.resourceMix[i].Status === "COMPLETE" || this.props.resourceMix[i].Status === "CLOSE") {
                            allocationDataRequests.push(res);
                        }

                        data.push([
                            {
                                value: this.props.resourceMix[i].Type === "ChangeRequest"
                                    ? this.props.resourceMix[i].ChangeRequest.CRName
                                    : this.props.resourceMix[i].Project.ProjectName,
                            },
                            {
                                value: this.props.resourceMix[i].Phase
                                    ? this.props.resourceMix[i].Phase.Text
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].SubPhase
                                    ? this.props.resourceMix[i].SubPhase.Text
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].dataValueProjectRole
                                    ? this.props.resourceMix[i].dataValueProjectRole.Name
                                    : this.state.UndefinedDate,
                            },
                            {
                                value: this.props.resourceMix[i].dataValueCostDesignation.Name
                                    ? this.props.resourceMix[i].dataValueCostDesignation.Name
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].dataValueResourceRegion
                                    ? this.props.resourceMix[i].dataValueResourceRegion.Name
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].OnsiteStatus
                                    ? this.props.resourceMix[i].OnsiteStatus
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].StartDate
                                    ? this.props.resourceMix[i].StartDate
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].EndDate
                                    ? this.props.resourceMix[i].EndDate
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].EffortDays
                                    ? this.props.resourceMix[i].EffortDays
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].AllocationPercentage
                                    ? this.props.resourceMix[i].AllocationPercentage
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].AllocatedResource
                                    ? this.props.resourceMix[i].AllocatedResource.Name
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].AllocatedDesignation
                                    ? this.props.resourceMix[i].AllocatedDesignation
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].dataValueRateType
                                    ? this.props.resourceMix[i].dataValueRateType.Name
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].RateValue
                                    ? this.props.resourceMix[i].RateValue
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].TotalRevenue
                                    ? (this.props.resourceMix[i].TotalRevenue)
                                    : '0.00'
                            },
                            {
                                value: this.props.resourceMix[i].TotalCost
                                    ? this.props.resourceMix[i].TotalCost
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].CostToRevenueRatio
                                    ? this.props.resourceMix[i].CostToRevenueRatio
                                    : this.state.UndefinedDate
                            },
                            {
                                value: this.props.resourceMix[i].Status
                                    ? ResourceRequestStatusENUMS[this.props.resourceMix[i].Status] 
                                    : this.state.UndefinedDate
                            },
                        ]);
                    }

                    }

                    if (res.ResourceRequestSubLine && res.ResourceRequestSubLine.length > 0) {

                        for (let t = 0; t < res.ResourceRequestSubLine.length; t++) {


                            if((res.ResourceRequestSubLine[t] && res.ResourceRequestSubLine[t].IsSoftBookingResource === true)){
                                if (res.ResourceRequestSubLine[t].Status === "COMPLETE" || res.ResourceRequestSubLine[t].Status === "CLOSE") {
                                    res.ResourceRequestSubLine[t].Type = this.props.resourceMix[i].Type;
                                    res.ResourceRequestSubLine[t].Project = this.props.resourceMix[i].Project;
                                    res.ResourceRequestSubLine[t].ChangeRequest = this.props.resourceMix[i].ChangeRequest;
                                    allocationDataRequests.push(res.ResourceRequestSubLine[t]);
                                }
                            data.push([
                                {
                                    value: this.props.resourceMix[i].Type === "ChangeRequest"
                                        ? this.props.resourceMix[i].ChangeRequest.CRName
                                        : this.props.resourceMix[i].Project.ProjectName,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].Phase
                                        ? res.ResourceRequestSubLine[t].Phase.Text
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }

                                },
                                {
                                    value: res.ResourceRequestSubLine[t].SubPhase
                                        ? res.ResourceRequestSubLine[t].SubPhase.Text
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].dataValueProjectRole
                                        ? res.ResourceRequestSubLine[t].dataValueProjectRole.Name
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].dataValueCostDesignation
                                        ? res.ResourceRequestSubLine[t].dataValueCostDesignation.Name
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].dataValueResourceRegion
                                        ? res.ResourceRequestSubLine[t].dataValueResourceRegion.Name
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].OnsiteStatus
                                        ? res.ResourceRequestSubLine[t].OnsiteStatus
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].StartDate
                                        ? res.ResourceRequestSubLine[t].StartDate
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].EndDate
                                        ? res.ResourceRequestSubLine[t].EndDate
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].EffortDays
                                        ? res.ResourceRequestSubLine[t].EffortDays
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].AllocationPercentage
                                        ? res.ResourceRequestSubLine[t].AllocationPercentage
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].AllocatedResource
                                      ? res.ResourceRequestSubLine[t].AllocatedResource.displayName
                                      : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].AllocatedDesignation
                                        ? res.ResourceRequestSubLine[t].AllocatedDesignation
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].dataValueRateType
                                        ? res.ResourceRequestSubLine[t].dataValueRateType.Name
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].RateValue
                                        ? res.ResourceRequestSubLine[t].RateValue
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].Revenue
                                        ? Number(res.ResourceRequestSubLine[t].Revenue)
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].Cost
                                        ? Number(res.ResourceRequestSubLine[t].Cost)
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].CostToRevenueRatio
                                        ? res.ResourceRequestSubLine[t].CostToRevenueRatio
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                                {
                                    value: res.ResourceRequestSubLine[t].Status
                                        ? ResourceRequestStatusENUMS[res.ResourceRequestSubLine[t].Status]
                                        : this.state.UndefinedDate,
                                    style: {font: {bold: true ,color: {rgb: 'FFA30000'}}, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                },
                            ]);
                        }
                        else {
                            if ( res.ResourceRequestSubLine[t].AllocationPercentageHistory && res.ResourceRequestSubLine[t].AllocationPercentageHistory.length > 1) {

                                if (res.ResourceRequestSubLine[t].Status === "COMPLETE" || res.ResourceRequestSubLine[t].Status === "CLOSE") {
                                    res.ResourceRequestSubLine[t].Type = this.props.resourceMix[i].Type;
                                    res.ResourceRequestSubLine[t].Project = this.props.resourceMix[i].Project;
                                    res.ResourceRequestSubLine[t].ChangeRequest = this.props.resourceMix[i].ChangeRequest;
                                    allocationDataRequests.push(res.ResourceRequestSubLine[t]);
                                }
                                
                                data.push([
                                    {
                                        value: this.props.resourceMix[i].Type === "ChangeRequest"
                                            ? this.props.resourceMix[i].ChangeRequest.CRName
                                            : this.props.resourceMix[i].Project.ProjectName,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } } }
                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].Phase
                                            ? res.ResourceRequestSubLine[t].Phase.Text
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }  }
                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].SubPhase
                                            ? res.ResourceRequestSubLine[t].SubPhase.Text
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }
                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].dataValueProjectRole
                                            ? res.ResourceRequestSubLine[t].dataValueProjectRole.Name
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].dataValueCostDesignation
                                            ? res.ResourceRequestSubLine[t].dataValueCostDesignation.Name
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].dataValueResourceRegion
                                            ? res.ResourceRequestSubLine[t].dataValueResourceRegion.Name
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].OnsiteStatus
                                            ? res.ResourceRequestSubLine[t].OnsiteStatus
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].StartDate
                                            ? res.ResourceRequestSubLine[t].StartDate
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].EndDate
                                            ? res.ResourceRequestSubLine[t].EndDate
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].EffortDays
                                            ? res.ResourceRequestSubLine[t].EffortDays
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].CurrentAllocationPercentage
                                            ? res.ResourceRequestSubLine[t].CurrentAllocationPercentage
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].AllocatedResource
                                          ? res.ResourceRequestSubLine[t].AllocatedResource.displayName
                                          : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }
                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].AllocatedDesignation
                                            ? res.ResourceRequestSubLine[t].AllocatedDesignation
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].dataValueRateType
                                            ? res.ResourceRequestSubLine[t].dataValueRateType.Name
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].RateValue
                                            ? res.ResourceRequestSubLine[t].RateValue
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },     
                                    {
                                        value: res.ResourceRequestSubLine[t].Revenue
                                            ? Number(res.ResourceRequestSubLine[t].Revenue)
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].Cost
                                            ? Number(res.ResourceRequestSubLine[t].Cost)
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].CostToRevenueRatio
                                            ? res.ResourceRequestSubLine[t].CostToRevenueRatio
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].Status
                                            ? ResourceRequestStatusENUMS[res.ResourceRequestSubLine[t].Status]
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true, color: {rgb: 'FF000096'} }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                ]);
        
                            } else {
                                if (res.ResourceRequestSubLine[t].Status === "COMPLETE" || res.ResourceRequestSubLine[t].Status === "CLOSE") {
                                    res.ResourceRequestSubLine[t].Type = this.props.resourceMix[i].Type;
                                    res.ResourceRequestSubLine[t].Project = this.props.resourceMix[i].Project;
                                    res.ResourceRequestSubLine[t].ChangeRequest = this.props.resourceMix[i].ChangeRequest;
                                    allocationDataRequests.push(res.ResourceRequestSubLine[t]);
                                }
                                data.push([
                                    {
                                        value: this.props.resourceMix[i].Type === "ChangeRequest"
                                            ? this.props.resourceMix[i].ChangeRequest.CRName
                                            : this.props.resourceMix[i].Project.ProjectName,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }
                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].Phase
                                            ? res.ResourceRequestSubLine[t].Phase.Text
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }
                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].SubPhase
                                            ? res.ResourceRequestSubLine[t].SubPhase.Text
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } } }
                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].dataValueProjectRole
                                            ? res.ResourceRequestSubLine[t].dataValueProjectRole.Name
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].dataValueCostDesignation
                                            ? res.ResourceRequestSubLine[t].dataValueCostDesignation.Name
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].dataValueResourceRegion
                                            ? res.ResourceRequestSubLine[t].dataValueResourceRegion.Name
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].OnsiteStatus
                                            ? res.ResourceRequestSubLine[t].OnsiteStatus
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].StartDate
                                            ? res.ResourceRequestSubLine[t].StartDate
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].EndDate
                                            ? res.ResourceRequestSubLine[t].EndDate
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].EffortDays
                                            ? res.ResourceRequestSubLine[t].EffortDays
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].AllocationPercentage
                                            ? res.ResourceRequestSubLine[t].AllocationPercentage
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].AllocatedResource
                                          ? res.ResourceRequestSubLine[t].AllocatedResource.displayName
                                          : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }
                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].AllocatedDesignation
                                            ? res.ResourceRequestSubLine[t].AllocatedDesignation
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].dataValueRateType
                                            ? res.ResourceRequestSubLine[t].dataValueRateType.Name
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].RateValue
                                            ? res.ResourceRequestSubLine[t].RateValue
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].Revenue
                                            ? Number(res.ResourceRequestSubLine[t].Revenue)
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].Cost
                                            ? Number(res.ResourceRequestSubLine[t].Cost)
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].CostToRevenueRatio
                                            ? res.ResourceRequestSubLine[t].CostToRevenueRatio
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                    {
                                        value: res.ResourceRequestSubLine[t].Status
                                            ? ResourceRequestStatusENUMS[res.ResourceRequestSubLine[t].Status]
                                            : this.state.UndefinedDate,
                                        style: { font: { bold: true }, fill: { fgColor: { rgb: 'FFcfcfcc' } }   }

                                    },
                                ]);
                            }
                        }
                    }

                }
                }
            }



            const ResourceMix = [
                {
                    columns: [
                        {title: 'Resource Mix', width: {wpx: 150}},
                        {title: '', width: {wpx: 150}},
                    ],
                    data: tmp
                },
                // {
                //     // ySteps: 1, //Space between the 2 data sets
                //     columns: [
                //         {title: '', width: {wpx: 150}},
                //         {title: '', width: {wpx: 150}},
                //     ],
                //     data: tmp
                // },
                {
                    columns: [
                        {title: 'Project/CR', width: {wpx: 150}},
                        {title: 'Phase', width: {wpx: 150}},
                        {title: 'Sub Phase', width: {wpx: 150}},
                        {title: 'Project Role', width: {wpx: 180}},
                        {title: 'Designation(Cost)', width: {wpx: 180}},
                        {title: 'Resource Region', width: {wpx: 105}},
                        {title: 'Onsite / Offshore', width: {wpx: 110}},
                        {title: 'Start Date', width: {wpx: 75}},
                        {title: 'End Date', width: {wpx: 75}},
                        {title: 'Effort Days', width: {wpx: 75}},
                        {title: 'Allocations(%)', width: {wpx: 90}},
                        {title: 'Allocated Resource', width: {wpx: 100}},
                        {title: 'Allocated Designation', width: {wpx: 75}},
                        {title: 'Rate Type', width: {wpx: 75}},
                        {title: 'Rate Value', width: {wpx: 75}},
                        {title: 'Revenue', width: {wpx: 110}},
                        {title: 'Cost', width: {wpx: 110}},
                        {title: 'Cost To Revenue Ratio', width: {wpx: 110}},
                        {title: 'Status', width: {wpx: 110}}
                    ],
                    data
                },
                {
                    columns: [
                        {title: '', width: {wpx: 150}},
                        {title: '', width: {wpx: 150}},
                    ],
                    data: totalValues
                },
            ];

            await this.populateDailyAllocations(allocationDataRequests);

            await this.setState({
                ResourceMix: ResourceMix,
            });
        }
    };

    populateDailyAllocations = async (allocationDataRequests) => {
        let allocationRowData = [];
        let allocationHeadings = [];
        if (allocationDataRequests && allocationDataRequests.length > 0) {
            // Find the minimum StartDate
            const minDate = allocationDataRequests.reduce((min, obj) => obj.StartDate < min ? obj.StartDate : min, allocationDataRequests[0].StartDate);
            // Find the maximum EndDate
            const maxDate = allocationDataRequests.reduce((max, obj) => obj.EndDate > max ? obj.EndDate : max, allocationDataRequests[0].EndDate);

            const resName = {title: 'Allocated Resource', width: {wpx: 150}};
            const projName = {title: 'Project/CR', width: {wpx: 150}};
            allocationHeadings.push(projName);
            allocationHeadings.push(resName);
            let isFirstRecord = true;

            for (const resource of allocationDataRequests) {
                let resData = [];

                if (resource.IsSoftBookingResource === true) {
                    resData.push({ 
                        value: resource.Type === "ChangeRequest"
                        ? resource.ChangeRequest.CRName
                        : resource.Project.ProjectName,
                        style: {font: {color: {rgb: 'FFA30000'}} }   
                    });
    
                    resData.push({ 
                        value: resource.AllocatedResource
                        ? resource.AllocatedResource.Resource.Name
                        : this.state.UndefinedDate,
                        style: {font: {color: {rgb: 'FFA30000'}} } 
                    });  
                } else {
                    resData.push({ 
                        value: resource.Type === "ChangeRequest"
                        ? resource.ChangeRequest.CRName
                        : resource.Project.ProjectName  
                    });
    
                    resData.push({ 
                        value: resource.AllocatedResource
                        ? resource.AllocatedResource.Resource.Name
                        : this.state.UndefinedDate 
                    });    
                }
                
                let loop = new Date(minDate);
                while (new Date(loop) <= new Date(maxDate)) {
                    if (!isWeekend(loop)) {
                        if (isFirstRecord) {
                            const dateHeading = {title: moment(new Date(loop)).format('DD-MM-YY'), width: {wpx: 60}};
                            allocationHeadings.push(dateHeading);        
                        } 

                        const allocationPercentageHistoryDetails = resource.AllocationPercentageHistory;
                        let matchingAllocationPercentage = null;
                        if (allocationPercentageHistoryDetails && allocationPercentageHistoryDetails.length > 0) {
                            let validHistory  = Object.values(allocationPercentageHistoryDetails).find(c => new Date(moment(new Date(loop)).format('YYYY-MM-DD')) >= new Date(moment(c.StartDate).format('YYYY-MM-DD')) && new Date(moment(new Date(loop)).format('YYYY-MM-DD')) <= new Date(moment(c.EndDate).format('YYYY-MM-DD')));
                            matchingAllocationPercentage = validHistory ? validHistory.AllocationPercentage : this.state.UndefinedDate;
                            if (resource.IsSoftBookingResource === true) {
                                resData.push({ 
                                    value: matchingAllocationPercentage
                                        ? matchingAllocationPercentage + '%'
                                        : this.state.UndefinedDate,
                                    style: {font: {color: {rgb: 'FFA30000'}} } 
                                });
                            } else {
                                resData.push({ 
                                    value: matchingAllocationPercentage
                                        ? matchingAllocationPercentage + '%'
                                        : this.state.UndefinedDate
                                });
                            }                        
                        }
                    }
                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }
                allocationRowData.push(resData);
                isFirstRecord = false;
            }
        }
        let legends = [];
        legends.push(
            [
                {
                     value: 'AAA', 
                },
                { value: 'Permanant Resource Allocation' }
            ],
            [
                {
                     value: 'AAA', 
                     style: {font: {color: {rgb: 'FFA30000'}} }
                },
                { value: 'Soft Booking Resource Allocation' }
            ],
            [{ value: '' }, { value: '' }]);
        const AllocationHistories = [
            {
                columns: [{title: 'Daily Allocation Breakdown', width: {wpx: 150}}],
                data: [[{value: ''}]]
            },
            {
                columns: [{title: 'Legend', width: {wpx: 150}}],
                data: legends
            },
            {
                columns: allocationHeadings,
                data: allocationRowData
            },
        ];
        await this.setState({
            dailyAllocations: AllocationHistories
        });
    }


    render() {
        return (
            <ExcelFile
                filename={this.props.project.ProjectName ? this.props.project.ProjectName + '_ResourceRequest' : 'ResourceRequest'
                }
                element={ 
                    <Button id={"rrexcel"} primary className="d-none">
                        {'Download Resource Request'}
                    </Button>
                }
            >
                <ExcelSheet
                    dataSet={this.state.calculation ? this.state.calculation : this.state.NullData}
                    name="Summary"
                />
                <ExcelSheet
                    dataSet={this.state.ResourceMix ? this.state.ResourceMix : this.state.NullData}
                    name="Resource Mix"
                />
                <ExcelSheet
                    dataSet={this.state.dailyAllocations ? this.state.dailyAllocations : this.state.NullData}
                    name="Daily Allocation Breakdown"
                />
            </ExcelFile>
        );
    }
}

export default ExcelDownload;
