module.exports = Object.freeze({
  NEW_FROM_CMS: 'New From CMS' ,
  NEW_FROM_RM: 'New From RM' ,
  EDIT: 'Edit' ,
  CONFIRMED: 'Confirmed' ,
  RQST_APP_PENDING: 'Request Approval Pending',
  RQST_APP_REJECTED: 'Request Approval Rejected',
  RES_ALLOC_PENDING: 'Resource Allocation Pending',
  RES_ALLOC_CANCELLED: 'Resource Allocation Cancelled',
  REMOVED: 'Removed',
  ALLOC_APP_PENDING: 'Allocation Approval Pending',
  COMPLETE:'Complete',
  RES_ALLOC_REJECTED: 'Resource Allocation Rejected',
  CLOSE: 'Close',
  HIRING_RQUEST: 'Hiring Request',
  NOT_SUBMITTED: 'Not Submitted',
  NA: 'NA',
  AUTOMATIC : 'Automatic Approval',
  APPROVED: 'Approved',
  RES_INVALIDATED: 'Resource Request Invalidated'
});
