import React, { Component } from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactDOM from 'react-dom';
import { getWorkflowOperatorIntegrated } from '../../integration/ProjectManagementService';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import CriteriaRangeCell from './components/CriteriaRangeCell';
import CriteriaEditActionCell from './components/CriteriaEditActionCell';
import { Redirect } from 'react-router';
import { saveWorkflowDefinitions, getWorkflowDefinitionsByParameter } from './WorkflowCriteriaDefinitionService';

const workflowOperatorsDataAll = [];

class WorkflowCriteriaDefinitionCreate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            operators: [],
            selectedOpType: null,
            sequence: null,
            isSaveButtonClicked: false,
            fromVal: 0,
            toVal: 0,
            popupVisible: false,
            dialogTitle: '',
            dialogMessage: '',
            showSuccessImage: false,
            showErrorImage: false,
            sort: [],
            showCancelConfirmation: false,
            goBack: false,
            isFormDirty: false,
            parameterID: this.props.location.parameterID,
            isSubmitted: false,
            deletePopupVisible: false,
            itemToDelete: null,
            editingParam: false,
            editId: null
        }
    }

    async componentDidMount() {
        this.setHeaderTitle();
        this.populateInitialData();
    }

    populateInitialData = async () => {
        let sortedCriteria = [];
        if (this.props.location.criteria) {
            sortedCriteria = this.props.location.criteria.sort(function (a, b) {
                return a.Sequence - b.Sequence;
            });
        }

        let paramName = 'Workflow Master Data - ' + this.props.location.parameterName

        this.setState({
            criteria: sortedCriteria,
            allCriteria: sortedCriteria,
            createdAt: (sortedCriteria.length > 0 ? sortedCriteria[0].createdAt : null),
            parameterId: (sortedCriteria.length > 0 ? sortedCriteria[0].ParameterId : null),
            parameterName: paramName
        });

        await getWorkflowOperatorIntegrated()
            .then(response => {
                this.workflowOperatorsDataAll = response.data;
                this.setState({
                    operators: response.data,
                });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    }

    setHeaderTitle = async () => {
        if (this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Workflow Parameters Definition Create/ Edit');
        }
    };

    filterChangeOpType = event => {
        this.setState({
            operators: this.filterOpType(event.filter)
        });
    };

    filterOpType(filter) {
        const data = this.workflowOperatorsDataAll.slice();
        return filterBy(data, filter);
    }

    handleChange = async event => {
        await this.setState({ selectedOpType: event.target.value, isFormDirty: true });
    };

    handleChangeSequesnce = async event => {
        if (event.target.value) {
            this.setState({ sequence: Number((event.target.value).toFixed(0)), isFormDirty: true });
        } else {
            this.setState({ sequence: null, isFormDirty: true });
        }
    };

    handleChangeFromVal = async event => {
        if (event.target.value) {
            this.setState({ fromVal: Number((event.target.value).toFixed(0)), isFormDirty: true });
        } else {
            this.setState({ fromVal: 0, isFormDirty: true });
        }
    };

    handleChangeToVal = async event => {
        if (event.target.value) {
            this.setState({ toVal: Number((event.target.value).toFixed(0)), isFormDirty: true });
        } else {
            this.setState({ toVal: 0, isFormDirty: true });
        }
    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else if (this.state.isSaveButtonClicked) {
            return 'inline-error';
        } else {
            return 'd-none';
        }
    };

    handleAdd = async e => {
        e.preventDefault();
        await this.setState({ isSaveButtonClicked: true });
        if (!this.validation()) {
            this.setState({
                showSuccessImage: false,
                showErrorImage: true
            });
            this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
        } else if (this.state.criteria.filter(e => e.Sequence === this.state.sequence).length > 0) {
            this.setState({
                showSuccessImage: false,
                showErrorImage: true
            });
            this.toggleDialog('Sequence already exists', 'Error');
        } else {
            const newData = {};
            newData.id = this.state.editId ? this.state.editId : 0;
            newData.Sequence = this.state.sequence;
            newData.WorkflowOperator = this.state.selectedOpType;

            if (this.state.selectedOpType['Name'] === 'Between') {
                newData.Value1 = this.state.fromVal;
                newData.Value2 = this.state.toVal;
            } else {
                newData.Value1 = null;
                newData.Value2 = this.state.toVal;
            }

            const data = this.state.criteria;
            data.push(newData)

            const sortedCriteria = data.sort(function (a, b) {
                return a.Sequence - b.Sequence;
            });

            this.setState({
                criteria: sortedCriteria,
                editingParam: false,
                selectedOpType: null,
                sequence: null,
                fromVal: 0,
                toVal: 0,
                isSaveButtonClicked: false,
                editId: 0
            });
        }
    };

    validateFromToValues = () => {
        if (
            this.state.selectedOpType &&
            this.state.selectedOpType['Name'] === 'Between'
        ) {
            if (
                this.state.toVal <= this.state.fromVal ||
                this.state.fromVal >= this.state.toVal
            ) {
                return 'inline-error';
            } else {
                return 'd-none';
            }
        } else {
            return 'd-none';
        }
    };

    validation = () => {
        if (
            this.validateProperty(this.state.sequence)
                .toString()
                .includes('error')
        ) {
            return false;
        } else if (
            this.validateProperty(this.state.selectedOpType)
                .toString()
                .includes('error')
        ) {
            return false;
        } else if (
            this.validateFromToValues()
                .toString()
                .includes('error')
        ) {
            return false;
        } else {
            return true
        }
    };

    onEdit = async (event, item) => {
        if (event) {
            event.preventDefault();
            const data = JSON.parse(JSON.stringify(this.state.criteria));

            if (this.state.editingParam === true) {

                const parameterBeingEditedCurrently = data.filter(function (e) { return e.Sequence === item.Sequence; });
                if (this.state.currentlyEditingParameter) {
                    const prevparameter = JSON.parse(JSON.stringify(this.state.currentlyEditingParameter[0]));

                    const data = this.state.criteria.filter(x => x.Sequence !== parameterBeingEditedCurrently[0].Sequence);
                    data.push(prevparameter)

                    const sortedCriteria = data.sort(function (a, b) {
                        return a.Sequence - b.Sequence;
                    });

                    let fromVal = 0;
                    let toVal = 0;
                    if (item.WorkflowOperator["Name"] === "Between") {
                        fromVal = Number(item.Value1);
                        toVal = Number(item.Value2);
                    } else {
                        toVal = Number(item.Value2);
                    }

                    this.setState({
                        criteria: sortedCriteria  ,
                        currentlyEditingParameter: parameterBeingEditedCurrently,
                        selectedOpType: item.WorkflowOperator,
                        sequence: item.Sequence,
                        fromVal: fromVal,
                        toVal: toVal,
                        editId: item.id
                    });
                }

            } else {
                const removed = data.filter(function (e) { return e.Sequence !== item.Sequence; });
                const parameterBeingEdited = this.state.allCriteria.filter(function (e) { return e.Sequence === item.Sequence; });

                let fromVal = 0;
                let toVal = 0;
                if (item.WorkflowOperator["Name"] === "Between") {
                    fromVal = Number(item.Value1);
                    toVal = Number(item.Value2);
                } else {
                    toVal = Number(item.Value2);
                }

                await this.setState({
                    editingParam: true,
                    currentlyEditingParameter: parameterBeingEdited,
                    criteria: removed,
                    selectedOpType: item.WorkflowOperator,
                    sequence: item.Sequence,
                    fromVal: fromVal,
                    toVal: toVal,
                    editId: item.id
                })
            }

        }
    };

    deleteConfirmationClose = async () => {
        await this.setState({
            deletePopupVisible: !this.state.deletePopupVisible
        });

    };

    deleteConfirmationOk = async () => {
        const data = JSON.parse(JSON.stringify(this.state.criteria));
        const item = this.state.itemToDelete
        const removed = data.filter(function (e) { return e.Sequence !== item.Sequence; });
        await this.setState({
            criteria: removed,
            deletePopupVisible: false
        })
    };

    onDelete = async (event, item) => {
        if (event) {
            event.preventDefault();
            await this.setState({
                itemToDelete: item
            });
            this.deleteConfirmationClose()
        }
    };

    toggleDialog = async (message, title) => {
        await this.setState({
            popupVisible: !this.state.popupVisible,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    submitCriteria = async event => {

        event.preventDefault();

        const paramID = this.state.parameterID;
        let pastDefinitions = [];

        await getWorkflowDefinitionsByParameter(paramID)
            .then(response => {
                pastDefinitions = JSON.parse(JSON.stringify(response.data));
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        if (this.state.criteria.length > 0) {

            let createdDate = ''
            if (this.state.createdAt === null) {
                createdDate = new Date()
            } else {
                createdDate = this.state.createdAt
            }

            const createdUser = window.LOGGED_USER.displayName
            const paramID = this.state.parameterID

            let criteria = this.state.criteria
            let activeDefinitions = []

            for (const val of criteria) {
                const newData = {
                    id: val.id,
                    Sequence: val.Sequence,
                    OperatorId: val.WorkflowOperator.id,
                    ParameterId: paramID,
                    Value1: val.Value1 ? Number(val.Value1) : 0,
                    Value2: val.Value2 ? Number(val.Value2) : 0,
                    updatedUser: createdUser
                }
                activeDefinitions.push(newData)
            }

            let updateElements = activeDefinitions.filter(o => pastDefinitions.find(o2 => o.id === o2.id));
            let deleteElements = pastDefinitions.filter(o => !activeDefinitions.find(o2 => o.id === o2.id));
            let insertElements = activeDefinitions.filter(o => !pastDefinitions.find(o2 => o.id === o2.id));

            const updatedCriteriaDefinitions = {
                UpdateDefinitions: updateElements,
                InsertDefinitions: insertElements,
                DeleteDefinitions: deleteElements
            };

            await saveWorkflowDefinitions(updatedCriteriaDefinitions)
                .then(res => {
                    this.setState({
                        showSuccessImage: true,
                        showErrorImage: false,
                        isSubmitted: true
                    });
                    this.toggleDialog(
                        ` Criteria definitions were successfully created`,
                        'Success'
                    );
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                    this.toggleDialog(error.response.data, 'Error');
                });

        } else {
            this.setState({
                showSuccessImage: false,
                showErrorImage: true
            });
            this.toggleDialog('Please add data to submit', 'Error');
        }
    };

    onClickCancel = event => {
        if (this.state.isFormDirty) {
            this.toggleCancelConfirmDialog();
        } else {
            this.navigateBack(event);
        }
    };

    toggleCancelConfirmDialog = () => {
        this.setState({
            showCancelConfirmation: !this.state.showCancelConfirmation
        });
    };

    navigateBack = event => {
        this.setState({
            goBack: true
        });
    };

    render() {

        if (this.state.goBack === true) {
            return <Redirect to="/WorkflowCriteriaDefinitionSearch/WorkflowCriteriaDefinitionSearch" />;
        }

        return (
            <div>
                <div className="main-card">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-heading">{this.state.parameterName}</div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-3">
                            <div className="d-block">
                                <label className="new-label mandatory">Sequence:</label>
                            </div>
                            <div className="d-block">
                                <NumericTextBox
                                    name="sequence"
                                    value={this.state.sequence}
                                    onChange={this.handleChangeSequesnce}
                                    required={true}
                                    min={0}
                                    max={999}
                                    width={'100%'}
                                />
                                <span className={this.validateProperty(this.state.sequence)}>
                                    Please select a valid sequence number
                                </span>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="d-block">
                                <label className="new-label mandatory">Type:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox
                                    placeholder="Please select"
                                    name="workflowOperators"
                                    data={this.state.operators}
                                    textField="Name"
                                    dataItemKey="id"
                                    value={this.state.selectedOpType}
                                    onChange={this.handleChange}
                                    required={true}
                                    filterable={true}
                                    onFilterChange={this.filterChangeOpType}
                                />
                                <span className={this.validateProperty(this.state.selectedOpType)}>
                                    Please select a valid type
                                </span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="row">
                                {
                                    (this.state.selectedOpType && this.state.selectedOpType['Name'] === 'Between' &&
                                        <div className="col-md-5">
                                            <div className="d-block">
                                                <label className="new-label mandatory">From:</label>
                                            </div>
                                            <div className="d-block">
                                                <NumericTextBox
                                                    name="fromVal"
                                                    value={this.state.fromVal}
                                                    onChange={this.handleChangeFromVal}
                                                    required={true}
                                                    min={0}
                                                    width={'100%'}
                                                />
                                            </div>
                                        </div>
                                    )
                                }

                                <div className="col-md-5">
                                    <div className="d-block">
                                        <label className="new-label mandatory">To:</label>
                                    </div>
                                    <div className="d-block">
                                        <NumericTextBox
                                            name="toVal"
                                            value={this.state.toVal}
                                            onChange={this.handleChangeToVal}
                                            required={true}
                                            min={0}
                                            width={'100%'}
                                        />
                                        <span
                                            className={this.validateFromToValues()}
                                        >
                                            Ranges need to be incremental
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-block">
                                        <label className="new-label"></label>
                                    </div>
                            <div className="d-block">

                                    <Button className="position-absolute"
                                        primary={true}
                                        onClick={this.handleAdd}
                                    >
                                        Add
                                </Button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="main-seperator" />

                    <div className="row">
                        <div className="col-md-12">
                            <Grid data={orderBy(this.state.criteria, this.state.sort)}
                                resizable
                                sortable={false}
                                className="search-result-grid"
                            >
                                <Column field="Sequence" title="Sequence" />
                                <Column
                                    field="range"
                                    title="Range"
                                    cell={props => <CriteriaRangeCell {...props} />}
                                />
                                <Column
                                    field=""
                                    title="Actions"
                                    cell={props => (
                                        <CriteriaEditActionCell
                                            {...props}
                                            onEdit={this.onEdit}
                                            onDelete={this.onDelete}
                                        />
                                    )}
                                />
                            </Grid>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                            <Button primary={true} type="submit" onClick={this.submitCriteria} disabled={this.state.editingParam}>
                                Save
                            </Button>
                            <Button type="button" onClick={this.onClickCancel}>
                                Cancel
                          </Button>
                        </div>
                    </div>

                </div>

                <div>
                    {this.state.popupVisible === true && (
                        <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                            {this.state.showSuccessImage === true && (
                                <div className="text-center">
                                    <img className="successImg" src={SuccessImg} alt="Success" />
                                </div>
                            )}

                            {this.state.showErrorImage === true && (
                                <div className="text-center">
                                    <img className="successImg" src={ErrorImg} alt="Error Image" />
                                </div>
                            )}
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                            <DialogActionsBar>
                                {this.state.showSuccessImage === true && (
                                    <button className="k-button modal-primary" onClick={this.state.isSubmitted ? this.navigateBack : this.toggleDialog}>
                                        OK
                                </button>
                                )}

                                {this.state.showErrorImage === true && (
                                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                                        OK
                                </button>
                                )}
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

                <div>
                    {this.state.showCancelConfirmation === true && (
                        <Dialog
                            title="Please Confirm"
                            onClose={this.toggleCancelConfirmDialog}
                            width="300px"
                        >
                            <p style={{ margin: '25px', textAlign: 'center' }}>
                                This form contains unsaved changes. Do you want to close it?
                    </p>
                            <DialogActionsBar>
                                <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                                    No
                      </button>
                                <button
                                    className="k-button modal-primary"
                                    onClick={this.navigateBack}
                                >
                                    Yes
                      </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

                <div>
                    {this.state.deletePopupVisible && (
                        <Dialog title={'Please Confirm'} onClose={this.deleteConfirmationClose}>
                            <p style={{ margin: '25px', textAlign: 'center' }}>
                                Are you sure you want to delete this?
                            </p>
                            <DialogActionsBar>
                                <button className="k-button" onClick={this.deleteConfirmationClose}>
                                    No
                                </button>
                                <button className="k-button modal-primary" onClick={this.deleteConfirmationOk}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

            </div>
        );
    }
}

export default WorkflowCriteriaDefinitionCreate;
