/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import {getProjectResources, getResourceRequestResourcesByIdAndVersion} from "../../resourceManagement/ResourceService";
import {getExpenseTypes} from "../../masterData/common/CommonService";
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import {getActivityLogDataSort} from "../project/ProjectService";
import {getFormattedDate} from "../../integration/CommonUtils";
import moment from 'moment';

class CMSheetCell extends Component {

  constructor(props) {
    super(props);
    this.state = {
      IsProjectOwner:false,
    }
  }

  componentDidMount()
  {
    //this.onCheckPoolProjectOwner(this.props.dataItem, this.props.allResources)
  }

  handleDeleteCMSheet = (event, project, projectCMS, crCMS) => {
    event.preventDefault();

    let cmSheets = [];
    let crCMSheets = [];
    let projectCMSheets = [];
    let preSalesPM = false;

    const projectProjectManagers = project.AllocatedResourceRequestPM;
        //find valid pms from end date
    let projValidManagers = [];
    projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

    if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
        projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
        const lastAssignedManager = projectProjectManagers[0];
        projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
    }

    projValidManagers.forEach(value => {
        if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
            preSalesPM = true;
        }
    });

    for (const cr of crCMS) {
      const crProjectManagers = cr.AllocatedPM;
      //find valid pms from end date
      let crValidManagers = [];
      if (crProjectManagers && crProjectManagers.length > 0) {
        crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

        if (crValidManagers.length == 0 && crProjectManagers.length > 0){
            crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
            const lastAssignedManager = crProjectManagers[0];
            crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
        }
        crValidManagers.forEach(value => {
            if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
                preSalesPM = true;
            }
        });
      }
    }

    if(projectCMS.length > 0 && projectCMS[0].data.CMSheets && projectCMS[0].data.CMSheets.length > 0 && projectCMS[0].data.CMSheets[0].Status === 'IN_PROGRESS'){
      const cmSheetData = {
        id: projectCMS[0].data.CMSheets[0].id,
        type: 'Project',
        name: projectCMS[0].name
      };
      projectCMSheets.push(cmSheetData);
      cmSheets.push(cmSheetData);
    }

    if(crCMS.length > 0 && crCMS[0].data.CMSheets && crCMS[0].data.CMSheets.length > 0 && crCMS[0].data.CMSheets[0].Status === 'IN_PROGRESS'){
      crCMS.forEach(function(changeReq) {
        const crCMS = {
          id: changeReq.data.CMSheets[0].id,
          type: 'CR',
          name: changeReq.name
        };
        crCMSheets.push(crCMS);
        cmSheets.push(crCMS);
      });
    }

    if (project) {
      this.props.onDeleteCMSheet(project, cmSheets, projectCMSheets, crCMSheets);
    }
  };

  handleCreateCMSforCR = (event, changeReqList, project) => {
    event.preventDefault();
    this.props.onCreateCMSforCR(changeReqList, project, 'CreateCMSforCR');
  };

  handleCreateCMSforCRPool = (event, changeReqList, project) => {
    event.preventDefault();
    this.props.onCreateCMSforCRPool(changeReqList, project, 'CreateCMSforCRPool');
  };

  handleCreateCMS = (event, project) => {
    event.preventDefault();
    this.props.onCreateCMS(project, 'create');
  };

  handleViewCMS = (event, project, projectCMS, crCMS) => {
    event.preventDefault();
    this.props.onViewCMS(project, 'view', projectCMS, crCMS);
  };

  handleWithdrawCMS = (event, project, projectCMS, crCMS) => {
    event.preventDefault();
    if (project) {
      this.props.handleWithdrawCMS(project, projectCMS, crCMS);
    }
  };

  handleOnViewCMSPool = (event, project, projectCMS, crCMS) => {
    event.preventDefault();
    this.props.onViewCMSPool(project, 'view', projectCMS, crCMS);
  };

  hanldeEditCMS = (event, project, projectCMS, crCMS) => {
    event.preventDefault();
    this.props.onViewCMS(project, 'edit', projectCMS, crCMS);
  };

  hanldeEditCMSPool = (event, project, projectCMS, crCMS) => {
    event.preventDefault();
    this.props.onViewCMSPool(project, 'edit', projectCMS, crCMS);
  };

  handleReviseCMS = (event, project, projectCMS, crCMS) => {
    event.preventDefault();
    this.props.onViewCMS(project, 'revise', projectCMS, crCMS);
  };

  handleRequestReviseCMS = (event, project, projectCMS, crCMS) => {
    event.preventDefault();
    this.props.onViewCMS(project, 'requestReviseCMS', projectCMS, crCMS);
  };

  handleReviseCMSPool = (event, project, projectCMS, crCMS) => {
    event.preventDefault();
    this.props.onViewCMSPool(project, 'revise', projectCMS, crCMS);
  };

  handleApproveCMS = (event, project, projectCMS, crCMS)=>{
    event.preventDefault();
    this.props.onViewCMS(project, 'approve', projectCMS, crCMS);
  }

  handleApproveCMSPool = (event, project, projectCMS, crCMS)=>{
    event.preventDefault();
    this.props.onViewCMSPool(project, 'approve', projectCMS, crCMS);
  }

onCheckPoolProjectOwner = async (project, allResources) => {
    let IsProjectOwner = false
    if(allResources && allResources.length > 0)
    {
       const projectOwner = allResources.filter(obj => obj.id === project.ResourceId)

      IsProjectOwner = projectOwner ? (projectOwner[0].Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase() ? true : false) : false

      this.setState({
        IsProjectOwner: IsProjectOwner
      });
    }        

    //return Promise.resolve(IsProjectOwner);
  }

  render() {
    const project = this.props.dataItem;
    let status = 'PENDING';
    let displayStatus = 'Pending';
    let userHasPendingApproval = false;
    let isProjectBillable = project.ProjectStatus.StatusName === 'Billable';
    let manipulatePermissions = project.ManipulateCMSheetPermissions;
    let projectStatusDef = '';
    let bool = this.state.IsProjectOwner
    let isPendingStatus = false;

    if(project.ActivityLogs && project.ActivityLogs.length > 0){
      project.ActivityLogs.sort((a, b) => (b.createdAt > a.createdAt) ? 1 : -1);
      
      if(project.ActivityLogs[0].ApprovalStatus && project.ActivityLogs[0].ApprovalStatus === 'PENDING'){
        isPendingStatus = true;
      }
      else{
        isPendingStatus = false;
      }
    }

    if (project.ProjectStatus) {
      projectStatusDef = project.ProjectStatus.StatusName;
    }

    let viewCMSPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_CMS');
    let viewPoolCMSPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_FAC_PRJ');
    let createPoolCMSPermission = window.USER_PERMISSION.filter(obj => obj === 'FAC_PRO');

    let displayStatusesArray = [];  

    // TODO should be changed in phase 1.1 when multiple cm sheets allowed
    if (project.CMSheets.length > 0) {
      status = project.CMSheets[0].Status;
      switch (status) {
        case 'IN_PROGRESS': {
          displayStatus = 'In Progress';
          break;
        }
        case 'PENDING_APPROVALS': {
          displayStatus = 'Pending Approvals';
          break;
        }
        case 'APPROVED': {
          displayStatus = 'Approved';
          break;
        }
        case 'REJECTED': {
          displayStatus = 'Rejected';
          break;
        }
        case 'EXPIRED': {
          displayStatus = 'Expired';
          break;
        }
        case 'WITHDRAWN': {
          displayStatus = 'Withdrawn';
          break;
        }
        default: {
          break;
        }
      }
      displayStatusesArray.push(displayStatus);

      if (project.CMSheets[0].userHasPendingApproval === true) {
        userHasPendingApproval = true;
      }
    } else {
      displayStatusesArray.push('Pending');
    }

    let cmsAvailable = false;
    let changeReqList = [];

    // Edit CMS (Project and CR both) Logic
    let editProjectCMS = [];
    let editCrCMS = [];

    // Delete CMS (Project and CR both) Logic
    let deleteProjectCMS = [];
    let deleteCrCMS = [];

    // View CMS ( Project and CR both ) Logic
    let viewProjectCMS = [];
    let viewCrCMS = [];

    // Revise CMS ( Project and CR both ) Logic
    let reviseProjectCMS = [];
    let reviseCrCMS = [];

    // Request Revise CMS ( Project and CR both ) Logic
    let requestReviseProjectCMS = [];
    let requestReviseCrCMS = [];

    // Approve CMS ( Project and CR) logic
    let approveProjectCMS = [];
    let approveCrCMS = [];

    let pmAccess = false;
    let crAccess = false;
    let uniAccess = false;

    project.ChangeRequests.forEach(async function(CR) {
      let crStatusDef = '';
      //valid managers
      const crProjectManagers = CR.AllocatedPM;

      //find valid pms from end date
      let validManagers = [];
      validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (validManagers.length === 0 && crProjectManagers.length > 0){
        //validManagers.push(crProjectManagers[0]);
        crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
        const lastAssignedManager = crProjectManagers[0];
        validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

      }

      if (CR.Status) {
        let crName = CR.Status.StatusName;


        // Change Request CMS status display
        if(CR.CMSheets.length > 0){
          let crCMSstatus = CR.CMSheets && CR.CMSheets[0] ? CR.CMSheets[0].Status : '';
          let crCMSStatusDisplay = null;
          switch (crCMSstatus) {
            case 'IN_PROGRESS': {
              crCMSStatusDisplay = 'In Progress';
              break;
            }
            case 'PENDING_APPROVALS': {
              crCMSStatusDisplay = 'Pending Approvals';
              break;
            }
            case 'APPROVED': {
              crCMSStatusDisplay = 'Approved';
              break;
            }
            case 'REJECTED': {
              crCMSStatusDisplay = 'Rejected';
              break;
            }
            case 'EXPIRED': {
              crCMSStatusDisplay = 'Expired';
              break;
            }
            case 'WITHDRAWN': {
              crCMSStatusDisplay = 'Withdrawn';
              break;
            }
            default: {
              break;
            }
          }
          displayStatusesArray.push(crCMSStatusDisplay);
        } else {
          displayStatusesArray.push('Pending');
        }

        crStatusDef = crName
      }

      if (CR.CMSheets.length > 0) {
        cmsAvailable = true;

        if ((CR && CR.ResourceRequestStatus && CR.ResourceRequestStatus === 'PM_ALLOCATED') && CR.AllocatedPM && CR.AllocatedPM.length>0) {
            let viewCRObj = null;
            let editCRObj = null;
            let isViewCRCMS = false;
            let isEditCRCMS = false;
            for(const allocatedPM of validManagers){

              if ( !allocatedPM.IsPresales && ( (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) || viewCMSPermission)) {
                viewCRObj = {
                  name: CR.CRName,
                  data: CR
                };
                isViewCRCMS = true;
              }

              if ( !allocatedPM.IsPresales && (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase())) {
                crAccess = true

                if (CR.CMSheets[0].Status === 'IN_PROGRESS' && CR.Status.Code === 'PRESALES') {
                  editCRObj = {
                    name: CR.CRName,
                    data: CR
                  };
                  isEditCRCMS = true
                } else if (CR.CMSheets[0].Status === 'IN_PROGRESS' && (CR.Status.Code === 'BILLABLE' || CR.Status.Code === 'WORK_AT_RISK')) {
                  editCRObj = {
                    name: CR.CRName,
                    data: CR
                  };
                  isEditCRCMS = true;
                }

              }
            }
            if (viewCRObj && isViewCRCMS) {
              viewCrCMS.push(viewCRObj);
            }
            if (editCRObj && isEditCRCMS) {
              editCrCMS.push(editCRObj);
            }
        }

        else if((CR.IsPoolProject && CR.IsPoolProject === true)) {

          if (bool === true || viewPoolCMSPermission) {
            const viewCRObj = {
              name: CR.CRName,
              data: CR
            };
            viewCrCMS.push(viewCRObj);
          }

          if (bool === true || createPoolCMSPermission) {
            if (CR.CMSheets[0].Status === 'IN_PROGRESS' && CR.Status.Code === 'PRESALES') {
              const editCRObj = {
                name: CR.CRName,
                data: CR
              };
              editCrCMS.push(editCRObj);
            }
          }
        }

      else {

        if(validManagers.length ===0 && (window.USER_PERMISSION.indexOf('CRT_CMS') > -1)){
          uniAccess = true;
          if (viewCMSPermission) {
            const viewCRObj = {
              name: CR.CRName,
              data: CR
            };
            viewCrCMS.push(viewCRObj);
          }
          if (CR.CMSheets[0].Status === 'IN_PROGRESS' && (CR.Status && CR.Status.Code === 'PRESALES')) {
            const editCRObj = {
              name: CR.CRName,
              data: CR
            };
            editCrCMS.push(editCRObj);
          }
        }

          let isViewCRCMS = false;
          let isEditCRCMS = false;
          let isDeleteCRCMS = false;
          let viewCRObj = null;
          let editCRObj = null;

          validManagers.forEach(value => {
            if (value.IsPresales && ( (value.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) || viewCMSPermission)) {
              viewCRObj = {
                name: CR.CRName,
                data: CR
              };
              isViewCRCMS = true;
            }

            if (value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase() && CR.IsActive === true)) {
              crAccess = true;
              if (CR.CMSheets[0].Status === 'IN_PROGRESS' && (CR.Status && CR.Status.Code === 'PRESALES')) {
                editCRObj = {
                  name: CR.CRName,
                  data: CR
                };
                isEditCRCMS = true;
                isDeleteCRCMS = true;          
              }

            }
          });

          if (viewCRObj && isViewCRCMS) {
            viewCrCMS.push(viewCRObj);
          }
          if (editCRObj && isEditCRCMS) {
            editCrCMS.push(editCRObj);
          }
          if (editCRObj && isDeleteCRCMS) {
            deleteCrCMS.push(editCRObj);
          }
        }

        // Revise CMS
        if (CR.Status !== null) {
          if(validManagers.length ===0){
            if (
              (CR.CMSheets[0].Status === 'REJECTED' ||
                CR.CMSheets[0].Status === 'APPROVED' ||
                CR.CMSheets[0].Status === 'EXPIRED'  ||
                  CR.CMSheets[0].Status === 'WITHDRAWN') && CR.Status.Code === 'PRESALES'
            ) {
              const reviseCRObj = {
                name: CR.CRName,
                data: CR
              };
              reviseCrCMS.push(reviseCRObj);
            }
          }

          let reviseCRObj = null;
          let isReviseCRCMS = false;

          validManagers.forEach(value => {
            if (value.IsPresales &&
              ((CR.CMSheets[0].Status === 'REJECTED' ||
                CR.CMSheets[0].Status === 'APPROVED' ||
                CR.CMSheets[0].Status === 'EXPIRED'  ||
                  CR.CMSheets[0].Status === 'WITHDRAWN') && CR.Status.Code === 'PRESALES' &&
              window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()
            )) {
              reviseCRObj = {
                name: CR.CRName,
                data: CR
              };
              isReviseCRCMS = true;
            } else if (!value.IsPresales && (CR.Status.Code === 'BILLABLE' || CR.Status.Code === 'WORK_AT_RISK') && 
              (CR.CMSheets[0].Status === 'REJECTED' || CR.CMSheets[0].Status === 'APPROVED' || CR.CMSheets[0].Status === 'WITHDRAWN') && window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()
            ) {
              const reviseCMSrequests = CR.ReviseCMSRequest;
              if (reviseCMSrequests && reviseCMSrequests.length > 0) {
                const sortedArray = reviseCMSrequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                if(sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved){
                  reviseCRObj={
                    name: CR.CRName,
                    data : CR
                  }
                  isReviseCRCMS = true;
                }
              }
            }
          });
          if (reviseCRObj && isReviseCRCMS) {
            reviseCrCMS.push(reviseCRObj);
          }
        }

        // Approve CMS
        if( CR.CMSheets[0].Status === 'PENDING_APPROVALS' && CR.CMSheets[0].userHasPendingApproval === true && (crStatusDef=== 'Waiting Confirmation' || crStatusDef=== 'Presales' || crStatusDef === 'On Hold' || crStatusDef === 'Working At Risk' || crStatusDef === 'Active' )){
          const approveCRCMSObj={
            name: CR.CRName,
            data : CR
          }
          approveCrCMS.push(approveCRCMSObj);
        }

        if(CR.CMSheets.length > 0 && CR.StatusConvertedToActive && CR.Status && 
          (CR.Status.Code === 'BILLABLE' || CR.Status.Code === 'WORK_AT_RISK') && crAccess) {

          const projActivatedDate = new Date(CR.ConvertedToActiveAt);
          const fromTwoMonthDate = moment(projActivatedDate).add(2, 'months').format('YYYY-MM-DD');
          const currentDate = moment(new Date()).format('YYYY-MM-DD');

          if(new Date(currentDate) <= new Date(fromTwoMonthDate)) {
            let allowReviseCMS = true;
            const reviseCMSrequests = CR.ReviseCMSRequest;
            if (reviseCMSrequests && reviseCMSrequests.length > 0) {
              const sortedArray = reviseCMSrequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
              if(reviseCMSrequests.length >= 2 || sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
              (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)){
                allowReviseCMS = false;
              }
            }

            let isCrPM = false;
            validManagers.forEach(allocatedPM => {
              if(!allocatedPM.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === allocatedPM.PMResource.Email.toUpperCase())){
                isCrPM = true;
              }
            });

            let reviseCRObj = null;
            let isRequestReviseCRCMS = false;
      
            if(allowReviseCMS && isCrPM) {
              reviseCRObj={
                name: CR.CRName,
                data : CR
              }
              isRequestReviseCRCMS = true;
            }
            if (reviseCRObj && isRequestReviseCRCMS) {
              requestReviseCrCMS.push(reviseCRObj);
            }
          }
        }
      } else {
        //This logic is when a CR doesnt have a CMS
        const crProjectManagers = CR.AllocatedPM;
        //find valid pms from end date
        let validManagers = [];
        validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

        if (validManagers.length == 0 && crProjectManagers.length > 0){
          //validManagers.push(crProjectManagers[0]);
          crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
          const lastAssignedManager = crProjectManagers[0];
          validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

        }

        if (CR && CR.ResourceRequestStatus && CR.ResourceRequestStatus === 'PM_ALLOCATED') {

          validManagers.forEach(value => {
            if ( !value.IsPresales && value.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
              if(CR.Status.Code === 'PRESALES'){
                changeReqList.push(CR);
              }
            }
          });

        } else {
          if(validManagers.length == 0){
            if(CR.Status.Code === 'PRESALES'){
              changeReqList.push(CR);
            }
          }
          let isCreateCRCMS = false;
          validManagers.forEach(value => {
            if (value.IsPresales && value.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
              if(CR.Status.Code === 'PRESALES'){
                isCreateCRCMS = true;
              }
            }
            else if(project.IsPoolProject === true && CR.Status.Code === 'PRESALES'){

              changeReqList.push(CR);

            }
          });
          if (isCreateCRCMS) {
            changeReqList.push(CR);
          }
        }

      }
    });

    /*if (project.ResourceRequestStatus === 'PM_ALLOCATED') {

      if (project.AllocatedPM && project.AllocatedPM.length>0) {
        for(const pm of project.AllocatedPM) {
          if (pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
            pmAccess = true;
          }
        }

      }
    } else {
      if (project.PresalesResource && project.PresalesResource.Email && window.LOGGED_USER && window.LOGGED_USER.userId &&
          project.PresalesResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
        pmAccess = true;
      }
    }
*/

    const projectProjectManagers = project.AllocatedResourceRequestPM;

    //find valid pms from end date
    let projValidManagers = [];
    projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

    if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
      //projValidManagers.push(projectProjectManagers[0]);
      projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
      const lastAssignedManager = projectProjectManagers[0];
      projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
    }

    if(projValidManagers.length === 0 && (window.USER_PERMISSION.indexOf('CRT_CMS') > -1)){
      uniAccess = true;
    }
    projValidManagers.forEach(value => {
      if (value.IsPresales && (value.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase())) {
        pmAccess = true;
      }
    });

    if (project.CMSheets.length > 0) {
      cmsAvailable = true;

      let projectManager = false;
      /*if (project.ResourceRequestStatus === 'PM_ALLOCATED') {

        if (project.AllocatedPM && project.AllocatedPM.length>0) {
          for(const pm of project.AllocatedPM) {
            if (pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
              projectManager = true
            }
          }
        }
      } else {
        if (project.PresalesResource && project.PresalesResource.Email && window.LOGGED_USER && window.LOGGED_USER.userId &&
            project.PresalesResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
          projectManager = true;
        }
      }*/
      projValidManagers.forEach(value => {
        if (value.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
          projectManager = true
        }
      });

      if (viewCMSPermission || projectManager === true) {
        const viewProjObj = {
          name: project.ProjectName,
          data: project
        };
        viewProjectCMS.push(viewProjObj);
      }

      if(projectManager === true){
        if (project.CMSheets[0].Status === 'IN_PROGRESS' && project.ProjectStatus.Code === 'PRESALES') {
          const editProjObj = {
            name: project.ProjectName,
            data: project
          };
          editProjectCMS.push(editProjObj);
          deleteProjectCMS.push(editProjObj);
        } else if (project.CMSheets[0].Status === 'IN_PROGRESS' && (project.ProjectStatus.Code === 'BILLABLE' || project.ProjectStatus.Code === 'WORK_AT_RISK')) {
          const editProjObj = {
            name: project.ProjectName,
            data: project
          };
          editProjectCMS.push(editProjObj);
        }
      }

      if(projValidManagers.length === 0 && (window.USER_PERMISSION.indexOf('CRT_CMS') > -1)){
        if (project.CMSheets[0].Status === 'IN_PROGRESS' && project.ProjectStatus.Code === 'PRESALES') {
          const editProjObj = {
            name: project.ProjectName,
            data: project
          };
          editProjectCMS.push(editProjObj);
        }
      }

      // Revise CMS
      if(projValidManagers.length === 0){
        if (
          (project.CMSheets[0].Status === 'REJECTED' ||
            project.CMSheets[0].Status === 'APPROVED' ||
            project.CMSheets[0].Status === 'EXPIRED' ||
              project.CMSheets[0].Status === 'WITHDRAWN')&& project.ProjectStatus.Code === 'PRESALES'
        ) {
          const reviseProjectObj = {
            name: project.ProjectName,
            data: project
          };
          reviseProjectCMS.push(reviseProjectObj);
        }
      }

      let reviseProjectObj = null;
      let isReviseProjectCMS = false;

      projValidManagers.forEach(value => {
        if (
          value.IsPresales && (
          (project.CMSheets[0].Status === 'REJECTED' ||
            project.CMSheets[0].Status === 'APPROVED' ||
            project.CMSheets[0].Status === 'EXPIRED' ||
              project.CMSheets[0].Status === 'WITHDRAWN')&& project.ProjectStatus.Code === 'PRESALES' &&
          window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())
        ) {
          reviseProjectObj = {
            name: project.ProjectName,
            data: project
          };
          isReviseProjectCMS = true;
        }  else if (!value.IsPresales && (project.ProjectStatus.Code === 'BILLABLE' || project.ProjectStatus.Code === 'WORK_AT_RISK') && 
          (project.CMSheets[0].Status === 'REJECTED' || project.CMSheets[0].Status === 'APPROVED' || project.CMSheets[0].Status === 'WITHDRAWN') && window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()
        ) {
          const reviseCMSrequests = project.ReviseCMSRequest;
          if (reviseCMSrequests && reviseCMSrequests.length > 0) {
            const sortedArray = reviseCMSrequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            if(sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved){
              reviseProjectObj={
                name: project.ProjectName,
                data : project
              }
              isReviseProjectCMS = true;
            }
          }
        }
      });

      if (reviseProjectObj && isReviseProjectCMS) {
        reviseProjectCMS.push(reviseProjectObj);
      }


      // Approve CMS
      if( project.CMSheets[0].Status === 'PENDING_APPROVALS' && project.CMSheets[0].userHasPendingApproval === true && (projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' )){
        const approveProjectCMSObj={
          name: project.ProjectName,
          data : project
        }
        approveProjectCMS.push(approveProjectCMSObj);
      }

      if(project.CMSheets.length > 0 && project.StatusConvertedToActive && project.ProjectStatus && 
        (project.ProjectStatus.Code === 'BILLABLE' || project.ProjectStatus.Code === 'WORK_AT_RISK') && projectManager) {
        
        const projActivatedDate = new Date(project.ConvertedToActiveAt);
        const fromTwoMonthDate = moment(projActivatedDate).add(2, 'months').format('YYYY-MM-DD');
        const currentDate = moment(new Date()).format('YYYY-MM-DD');

        if (new Date(currentDate) <= new Date(fromTwoMonthDate)) {
          let allowReviseCMS = true;
          const reviseCMSrequests = project.ReviseCMSRequest;
          if (reviseCMSrequests && reviseCMSrequests.length > 0) {
            const sortedArray = reviseCMSrequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            if(reviseCMSrequests.length >= 2 || sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
            (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)){
              allowReviseCMS = false;
            }
          }
          let isProjectPM = false;
          projValidManagers.forEach(allocatedPM => {
            if(!allocatedPM.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === allocatedPM.PMResource.Email.toUpperCase())){
              isProjectPM = true;
            }
          });
    
          let reviseProjectObj = null;
          let isRequestReviseProjectCMS = false;
             
          if(allowReviseCMS && isProjectPM) {
            reviseProjectObj={
              name: project.ProjectName,
              data : project
            }
            isRequestReviseProjectCMS = true;
          }    
          if (reviseProjectObj && isRequestReviseProjectCMS) {
            requestReviseProjectCMS.push(reviseProjectObj);
          }
        }
      }
    }

     const inprogressStatuses = displayStatusesArray.filter(obj => obj === 'In Progress');
     const pendingApprovalStatuses = displayStatusesArray.filter(obj => obj === 'Pending Approvals');
     const approvedStatuses = displayStatusesArray.filter(obj => obj === 'Approved');
     const rejectedStatuses = displayStatusesArray.filter(obj => obj === 'Rejected');               
     const expiredStatuses = displayStatusesArray.filter(obj => obj === 'Expired');
     const pendingStatuses = displayStatusesArray.filter(obj => obj === 'Pending');
     const withdrawnStatuses = displayStatusesArray.filter(obj => obj === 'Withdrawn');

    return (
      <td className="action-buttons">
        <div className="action-status mb-1">
          Status:
          {pendingStatuses.length > 0 ? <span className="badge badge-warning mx-1">{'Pending'} <span className="badge badge-danger">{pendingStatuses.length}</span></span>: ''}
          {approvedStatuses.length > 0 ? <span className="badge badge-warning mx-1">{'Approved'} <span className="badge badge-danger">{approvedStatuses.length}</span></span>: ''}
          {rejectedStatuses.length > 0 ? <span className="badge badge-warning mx-1">{'Rejected'} <span className="badge badge-danger">{rejectedStatuses.length}</span></span>: ''}
          {expiredStatuses.length > 0 ? <span className="badge badge-warning mx-1">{'Expired'} <span className="badge badge-danger">{expiredStatuses.length}</span></span>: ''}
          {inprogressStatuses.length > 0 ? <span className="badge badge-warning mx-1">{'In Progress'} <span className="badge badge-danger">{inprogressStatuses.length}</span></span>: ''}
          {pendingApprovalStatuses.length > 0 ? <span className="badge badge-warning mx-1">{'Pending Approvals'} <span className="badge badge-danger">{pendingApprovalStatuses.length}</span></span>: ''}
          {withdrawnStatuses.length > 0 ? <span className="badge badge-warning mx-1">{'Withdrawn'} <span className="badge badge-danger">{withdrawnStatuses.length}</span></span>: ''}
        </div>

        <div className="action-icons">
          {changeReqList.length > 0  && (project.IsPoolProject === false)  && (isPendingStatus !== true) && (isPendingStatus !== true) && (pmAccess === true || crAccess === true || uniAccess === true) && (
            <a href="#" onClick={event => this.handleCreateCMSforCR(event, changeReqList, project)}>
              <div className="rounded-link" title="Create a CMS for CR">
                <span className="k-icon k-i-table-properties" />
              </div>
            </a>
          )}

          {changeReqList.length > 0  && (project.IsPoolProject === true) && (
            <a href="#" onClick={event => this.handleCreateCMSforCRPool(event, changeReqList, project)}>
              <div className="rounded-link" title="Create a CMS for Pool CR">
                <span className="k-icon k-i-table-properties" />
              </div>
            </a>
          )}

          {(projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' )
          && status === 'PENDING' && (project.IsPoolProject === false)  && (isPendingStatus !== true) && (pmAccess === true || crAccess === true || uniAccess === true) && (
            <a href="#" onClick={event => this.handleCreateCMS(event,project)}>
              <div className="rounded-link">
                <span className="k-icon k-i-track-changes" title="Create"/>
              </div>
            </a>
          )}

          {(projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' )
          && status === 'PENDING' && (manipulatePermissions === true || createPoolCMSPermission) && (project.IsPoolProject === true) &&  (
            <Link
              to={{
                pathname: '/PoolProject/FactoryProjectCMS',
                projectId: project.id,
                reference: 'projectCMS',
                action: 'create'
              }}
            >
              <div className="rounded-link">
                <span className="k-icon k-i-track-changes" title="Create" />
              </div>
            </Link>
          )}

          {((viewCrCMS && viewCrCMS.length>0) || (viewProjectCMS && viewProjectCMS.length>0))  && ((project.IsPoolProject === false)) && (
              <a
                href="#"
                onClick={event => this.handleViewCMS(event, project, viewProjectCMS, viewCrCMS)}
              >
                <div className="rounded-link">
                  <span className="k-icon k-i-preview" title="View" />
                </div>
              </a>
          )}

          {((viewCrCMS && viewCrCMS.length>0) || (viewProjectCMS && viewProjectCMS.length>0)) &&
            (pmAccess === true || crAccess === true || uniAccess === true) && ((project.IsPoolProject === false) && (pendingApprovalStatuses.length > 0)) && (
              <a
                href="#"
                onClick={event => this.handleWithdrawCMS(event, project, viewProjectCMS, viewCrCMS)}
              >
                <div className="rounded-link" title="Create a CMS for CR">
                    <span className="k-icon k-i-arrow-rotate-ccw" title="Withdraw CMS" />
                </div>
              </a>
          )}

          {((viewCrCMS && viewCrCMS.length>0) || (viewProjectCMS && viewProjectCMS.length>0)) && ((project.IsPoolProject === true)) &&  (
              <a
                href="#"
                onClick={event => this.handleOnViewCMSPool(event, project, viewProjectCMS, viewCrCMS)}
              >
                <div className="rounded-link">
                  <span className="k-icon k-i-preview" title="View" />
                </div>
              </a>
          )}

          {((editCrCMS.length > 0 || editProjectCMS.length > 0) &&
            (pmAccess === true || crAccess === true || uniAccess === true) && (project.IsPoolProject === false) && (isPendingStatus !== true)) && (
              <a
                href="#"
                onClick={event => this.hanldeEditCMS(event, project, editProjectCMS, editCrCMS)}
              >
                <div className="rounded-link">
                  <span className="k-icon k-i-edit" title="Edit CR/Project CMS" />
                </div>
              </a>
            )}

          {(editCrCMS.length > 0 || editProjectCMS.length > 0) &&
            (project.IsPoolProject === true) && (
              <a
                href="#"
                onClick={event => this.hanldeEditCMSPool(event, project, editProjectCMS, editCrCMS)}
              >
                <div className="rounded-link">
                  <span className="k-icon k-i-edit" title="Edit Factory CR/Project CMS" />
                </div>
              </a>
            )}


          {(projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' ) && (approveProjectCMS.length>0 || approveCrCMS.length>0) && (project.IsPoolProject === true) &&
          (
              <a href="#" onClick={event => this.handleApproveCMSPool(event,project, approveProjectCMS, approveCrCMS)}>
                < div className="rounded-link">
                  <span className="k-icon k-i-check" title="Approve CMS" />
                </div>
              </a>
          )}

          {(projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' ) && (approveProjectCMS.length>0 || approveCrCMS.length>0) && (project.IsPoolProject === false) &&
          (
              <a href="#" onClick={event => this.handleApproveCMS(event,project, approveProjectCMS, approveCrCMS)}>
                < div className="rounded-link">
                  <span className="k-icon k-i-check" title="Approve CMS" />
                </div>
              </a>
          )}

          {(projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' || (projectStatusDef === 'On Hold' && reviseCrCMS.length > 0) ) && (reviseProjectCMS.length > 0 || reviseCrCMS.length > 0) &&
            (pmAccess === true || crAccess === true || uniAccess === true) && (project.IsPoolProject === false) && (
              <a
                href="#"
                onClick={event =>
                  this.handleReviseCMS(event, project, reviseProjectCMS, reviseCrCMS)
                }
              >
                <div className="rounded-link">
                  <span className="k-icon k-i-categorize" title="Revise CMS" />
                </div>
              </a>
            )}
            {(projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' || (projectStatusDef === 'On Hold' && reviseCrCMS.length > 0) ) && (reviseProjectCMS.length > 0 || reviseCrCMS.length > 0) &&
            (pmAccess === true || crAccess === true) && (project.IsPoolProject === true) && (
              <a
                href="#"
                onClick={event =>
                  this.handleReviseCMSPool(event, project, reviseProjectCMS, reviseCrCMS)
                }
              >
                <div className="rounded-link">
                  <span className="k-icon k-i-categorize" title="Revise CMS" />
                </div>
              </a>
            )}
          
          {(requestReviseProjectCMS.length > 0 || requestReviseCrCMS.length >0) &&  (project.IsPoolProject === false) && (
              <a href="#" onClick={event => this.handleRequestReviseCMS(event,project, requestReviseProjectCMS, requestReviseCrCMS)}>
                < div className="rounded-link">
                  <span className="k-icon k-i-reload-sm" title="Request to Revise CMS" />
                </div>
              </a>
          )}

          {(projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' )
          && (deleteCrCMS.length > 0 || deleteProjectCMS.length > 0) && (project.IsPoolProject === false) &&
            (pmAccess === true || crAccess === true || uniAccess === true) && (
              <PermissibleRender
                userPermissions={window.USER_PERMISSION}
                requiredPermissions={['DLT_CMS']}
              >
                <a
                  href="#"
                  onClick={event =>
                    this.handleDeleteCMSheet(event, project, deleteProjectCMS, deleteCrCMS)
                  }
                >
                  <div className="rounded-link" title="Delete">
                    <span className="k-icon k-i-delete" />
                  </div>
                </a>
              </PermissibleRender>
            )}

            {(projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' )
          && (deleteCrCMS.length > 0 || deleteProjectCMS.length > 0) &&  (project.IsPoolProject === true) &&
            (pmAccess === true || crAccess === true) && (
              <PermissibleRender
                userPermissions={window.USER_PERMISSION}
                requiredPermissions={['FAC_PRO']}
              >
                <a
                  href="#"
                  onClick={event =>
                    this.handleDeleteCMSheet(event, project, deleteProjectCMS, deleteCrCMS)
                  }
                >
                  <div className="rounded-link" title="Delete">
                    <span className="k-icon k-i-delete" />
                  </div>
                </a>
              </PermissibleRender>
            )}

          {/* <a href="#">
          <div class="rounded-link">
            <span class="k-icon k-i-categorize" title="View Consolidated CMS" />
          </div>
        </a> */}
        </div>
      </td>
    );
  }
}

export default CMSheetCell;
