import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Get Pending Tasks details.
 * @returns {array} array object of Tasks details.
 */
export function getPendingTasks() {
    const endPoint = apiUrl + '/projectManagement/pendingTasks/getpendingtasks';
    return http.get(endPoint);
}

/**
 * Get Pending Tasks details.
 * @returns {array} array object of Tasks details.
 */
 export function getPendingTasksPerformanceFix(skip, take,sortBy,sortDir) {
    const endPoint = apiUrl + '/projectManagement/pendingTasks/getPendingTasksPerformanceFix/'+skip+'/'+take+'/'+sortBy+'/'+sortDir;
    return http.get(endPoint);
}



export function getProjectPendingTasks(projectId) {
    try{
        const endPoint = apiUrl + '/projectManagement/pendingTasks/getProjectPendingTasks/' + projectId;
        return http.get(endPoint);
    }catch(err){
        return [];
    }
}
