import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import { Button } from '@progress/kendo-react-buttons';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class IRFExcelDownload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            irfData: null,
            projectTimeEntries: null,
            nullData: [{ columns: [], data: [] }],
        };
    }

    componentDidMount() {
        if (this.props.updateData === true) {
            this.populateIRFData();
        }
    }

    componentDidUpdate = async() => {
        if (this.props.updateData === true) {
            this.props.dataUpdated();
            await this.populateIRFData();
            document.getElementById("irfexcel").click();
            this.props.handleSaveIRFData();
        }
    }

    populateIRFData = async() => {
        let irfData = [];
        let projectTimeEntries = [];
        const borderStyle = {
            border: {
                top: { style: "thin", color: "000000" },
                right: { style: "thin", color: "000000" },
                bottom: { style: "thin", color: "000000" },
                left: { style: "thin", color: "000000" }
            }
        };

        if (this.props.irfdata && this.props.irfdata.length > 0) {            
            for (const invoiceRequest of this.props.irfdata) {
                let clientConfirm = 'No';
                if (invoiceRequest.BillingMethodId && invoiceRequest.BillingMethodId === 2 && 
                    invoiceRequest.ApprovalFileId && invoiceRequest.FileUpload) {
                        clientConfirm = 'Yes';
                }

                if (invoiceRequest.BillingMethodId && invoiceRequest.BillingMethodId !== 2 && 
                    invoiceRequest.Milestone !== null && invoiceRequest.Milestone.FileUpload) {
                        clientConfirm = 'Yes';
                }

                irfData.push([
                    {
                        value: invoiceRequest.Region ? invoiceRequest.Region : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.Offering ? invoiceRequest.Offering : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.Customer ? invoiceRequest.Customer : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.LegalEntity ? invoiceRequest.LegalEntity.Name : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.FeeType ? invoiceRequest.FeeType : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.PONumber ? invoiceRequest.PONumber : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.ProjectName ? invoiceRequest.ProjectName : '',
                        style: borderStyle
                    },  
                    {
                        value: invoiceRequest.OPID ? invoiceRequest.OPID : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.SenderEmail ? invoiceRequest.SenderEmail : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.InvoicingType ? invoiceRequest.InvoicingType : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.Description ? invoiceRequest.Description.replace(/(<([^>]+)>)/ig,"") : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.Currency ? invoiceRequest.Currency : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.InvoiceAmount ? invoiceRequest.InvoiceAmount : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.LastApprovedDate ? moment(invoiceRequest.LastApprovedDate).format('MM-DD-YYYY') : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.InvoiceMonth ? invoiceRequest.InvoiceMonth : '',
                        style: borderStyle
                    },
                    {
                        value: invoiceRequest.Remarks ? invoiceRequest.Remarks.replace(/(<([^>]+)>)/ig,"") : '',
                        style: borderStyle
                    },
                ])

                if (invoiceRequest.TimeEntries && invoiceRequest.TimeEntries.length > 0) {
                    let timeEntries = [];
                    let teColumns = [
                        {title: '', width: {wpx: 100}},
                        {title: '', width: {wpx: 200}},
                        {title: '', width: {wpx: 150}},
                        {title: '', width: {wpx: 150}},
                        {title: '', width: {wpx: 250}},
                        {title: '', width: {wpx: 100}},
                        {title: '', width: {wpx: 100}},
                        {title: '', width: {wpx: 100}},
                        {title: '', width: {wpx: 100}},
                    ];
                    timeEntries.push(
                        [ {value: `Invoiced Period: ${moment(invoiceRequest.StartDate).format('MM-DD-YYYY')} - ${moment(invoiceRequest.EndDate).format('MM-DD-YYYY')}`,  style: {font: { bold: true }}}],
                        [ {value: ''}]
                    )
                    timeEntries.push([
                        {
                            value: 'Date',  style: {font: { bold: true }, border: borderStyle.border}
                        },
                        {
                            value: 'Project Name',  style: {font: { bold: true }, border: borderStyle.border}
                        },
                        {
                            value: 'Project Role',  style: {font: { bold: true }, border: borderStyle.border}
                        },
                        {
                            value: 'Resource Name',  style: {font: { bold: true }, border: borderStyle.border}
                        },
                        {
                            value: 'Time Entry Comments',  style: {font: { bold: true }, border: borderStyle.border}
                        },
                        {
                            value: 'Effort Hours',  style: {font: { bold: true }, border: borderStyle.border}
                        },
                        {
                            value: 'Currency',  style: {font: { bold: true }, border: borderStyle.border}
                        },
                        {
                            value: 'Hourly Rate',  style: {font: { bold: true }, border: borderStyle.border}
                        },
                        {
                            value: 'Revenue',  style: {font: { bold: true }, border: borderStyle.border}
                        }
                    ]);
                    for (const timeEntry of invoiceRequest.TimeEntries) {
                        timeEntries.push([
                            {
                                value: timeEntry.LogDateTime ? timeEntry.LogDateTime : '', style: borderStyle
                            },
                            {
                                value: timeEntry.ProjectName ? timeEntry.ProjectName : '', style: borderStyle
                            },
                            {
                                value: timeEntry.ProjectRole ? timeEntry.ProjectRole : '', style: borderStyle
                            },
                            {
                                value: timeEntry.Resource ? timeEntry.Resource : '', style: borderStyle
                            },
                            {
                                value: timeEntry.ApprovedComment ? timeEntry.ApprovedComment : '', style: borderStyle
                            },
                            {
                                value: timeEntry.ApprovedDuration ? timeEntry.ApprovedDuration : '', style: borderStyle
                            },
                            {
                                value: invoiceRequest.Currency ? invoiceRequest.Currency : '', style: borderStyle
                            },
                            {
                                value: timeEntry.RatePerHour ? timeEntry.RatePerHour : '', style: borderStyle
                            },
                            {
                                value: timeEntry.Revenue ? Number(timeEntry.Revenue).toFixed(2) : '', style: borderStyle
                            }
                        ]);
                    }
                    timeEntries.push([
                        {
                            value: ''
                        },
                        {
                            value: ''
                        },
                        {
                            value: ''
                        },
                        {
                            value: ''
                        },
                        {
                            value: ''
                        },
                        {
                            value: ''
                        },
                        {
                            value: ''
                        },
                        {
                            value: ''
                        },
                        {
                            value: invoiceRequest.TotalAmount ? Number(invoiceRequest.TotalAmount).toFixed(2) : '',
                            style: {font: {bold: true} }
                        }
                    ]);
                    const data = [{
                        columns: teColumns,
                        data: timeEntries
                    }]
                    const existingProjectIndex = projectTimeEntries.findIndex(ir => ir.OPID == invoiceRequest.OPID);
                    if (existingProjectIndex > -1) {
                        let existingTimeEntriesData = projectTimeEntries[existingProjectIndex].TimeEntries[0].data;
                        projectTimeEntries[existingProjectIndex].TimeEntries[0].data = existingTimeEntriesData.concat(timeEntries)
                    } else {
                        const timeEntryObj = {
                            OPID: invoiceRequest.OPID,
                            TimeEntries: data
                        }
                        projectTimeEntries.push(timeEntryObj);    
                    }
                }
            }
        }

        let emptyRow = [
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 200 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 350 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 150 } },
            { title: '', width: { wpx: 350 } }
        ];

        let irfColumns = [
            { value: 'Region',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Solution',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Client',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Billing Entity',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Invoicing type',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'PO Number',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Project',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'OP ID',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'PM email ID',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Inv/CRN',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Invoice Description',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Currency',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Amount',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Invoice Request approved date',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Month',  style: {font: { bold: true }, border: borderStyle.border}},
            { value: 'Special Remarks',  style: {font: { bold: true }, border: borderStyle.border}},
        ];

        const irfDataGrid = [
            {
                columns: [
                    {title: 'Invoice Request Form - Fortude', width: {wpx: 150}},
                    {title: '', width: {wpx: 150}},
                ],
                data: []
            },
            {
                columns: emptyRow,
                data: [irfColumns, ...irfData]
            }
        ];

        await this.setState({
            irfDataGrid: irfDataGrid,
            projectTimeEntries: projectTimeEntries
        });

    };

    render() {
        return (
            <ExcelFile
                filename={'Invoice Request Form'}
                element={ 
                    <Button id={"irfexcel"} className="d-none">
                        {''}
                    </Button>
                }
            >
                <ExcelSheet
                    dataSet={this.state.irfDataGrid ? this.state.irfDataGrid : this.state.nullData}
                    name="IRF"
                />
                {this.state.projectTimeEntries && this.state.projectTimeEntries.map((data, index) => (
                    <ExcelSheet
                        key={index}
                        dataSet={data.TimeEntries}
                        name={data.OPID}
                    />
                ))}
            </ExcelFile>
        );
    }
}

export default IRFExcelDownload;
