import React, { Component } from 'react';
import { Grid, GridColumn} from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import moment from 'moment';
import 'moment-timezone';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import { getResourceUtilizations } from './ResourceUtilizationService';
import FormatCell from './FormatCell';
import CustomCalendar from './CustomCalendar';

import Loader from '../../integration/components/Loader';

class ResourceUtilization extends Component {

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      resourceUtilizations: [],
      startDate: null,
      endDate: null,
      loading: false,
      totalPlannedUtilization: 0,
      totalPlannedBillableUtilization: 0,
      totalPlannedNonBillableUtilization: 0,
      totalPlannedRevenue: 0,
      totalActualUtilization: 0,
      totalActualBillableUtilization: 0,
      totalActualNonBillableUtilization: 0,
      totalActualRevenue: 0,
      sort: [
        { field: 'updatedAt', dir: 'desc' }
      ],
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.props.onHeaderTitleChange('Self Utilization & Billing');
    this.populateResourceUtilizations();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  populateResourceUtilizations = async () => {
    await this.setState({
      loading: true
    });

    const previousMonthDate = new Date().setDate(0);
    const previousMonthLastDate = moment(previousMonthDate).format('YYYY-MM-DD');
    const firstDatePrevMonth = new Date(previousMonthLastDate).setDate(1);

    const fields = {
      StartDate: moment(firstDatePrevMonth).format('YYYY-MM-DD'),
      EndDate: moment(previousMonthLastDate).format('YYYY-MM-DD')
    };

    await getResourceUtilizations(fields)
      .then(async res => {
        if (this.isMount) {
          await this.setState({
            startDate: new Date(firstDatePrevMonth),
            endDate: new Date(previousMonthLastDate),
            resourceUtilizations: res.data.ResourceUtilizations,
            totalPlannedUtilization: res.data.TotalPlannedUtilization,
            totalPlannedBillableUtilization: res.data.TotalPlannedBillableUtilization,
            totalPlannedNonBillableUtilization: res.data.TotalPlannedNonBillableUtilization,
            totalPlannedRevenue: res.data.TotalPlannedRevenue,
            totalActualUtilization: res.data.TotalActualUtilization,
            totalActualBillableUtilization: res.data.TotalActualBillableUtilization,
            totalActualNonBillableUtilization: res.data.TotalActualNonBillableUtilization,
            totalActualRevenue: res.data.TotalActualRevenue
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
      await this.setState({
        loading: false
      });
  };

  submitSearch = async event => {
    if (event) {
      event.preventDefault();
    }

    if (this.isMount) {
      await this.setState({
        loading: true
      });
    }

    if (this.state.startDate) {
      const fields = {};

      fields.StartDate = moment(this.state.startDate).format('YYYY-MM-DD');

      if(this.state.endDate){
        fields.EndDate = moment(this.state.endDate).format('YYYY-MM-DD');
      } else {
        const value = moment(this.state.startDate);
        const lastDateOfMonth = value.endOf('month');
        fields.EndDate = moment(lastDateOfMonth).format('YYYY-MM-DD');
      }

      await getResourceUtilizations(fields)
        .then(async res => {
          if (this.isMount) {
            await this.setState({
              resourceUtilizations: res.data.ResourceUtilizations,
              totalPlannedUtilization: res.data.TotalPlannedUtilization,
              totalPlannedBillableUtilization: res.data.TotalPlannedBillableUtilization,
              totalPlannedNonBillableUtilization: res.data.TotalPlannedNonBillableUtilization,
              totalPlannedRevenue: res.data.TotalPlannedRevenue,
              totalActualUtilization: res.data.TotalActualUtilization,
              totalActualBillableUtilization: res.data.TotalActualBillableUtilization,
              totalActualNonBillableUtilization: res.data.TotalActualNonBillableUtilization,
              totalActualRevenue: res.data.TotalActualRevenue
            });
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }

    await this.setState({
      loading: false
    });
  };

  cancelSearch = async event => {
    await this.setState({
      startDate: null,
      endDate: null
    });
  }

  handleStartDateChange = event => {
    let startDate = event.target.value;
    const firstDateOfMonth = new Date(startDate).setDate(1);
    if (this.isMount) {
      this.setState({
        startDate: new Date(firstDateOfMonth),
      });
    }
  };

  handleEndDateChange = event => {
    const value = moment(event.target.value);
    const lastDateOfMonth = value.endOf('month');
    if (this.isMount) {
      this.setState({
        endDate: new Date(lastDateOfMonth),
      });
    }
  };

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Self Utilization & Billing</div>
          </div>
        </div>
        <form onSubmit={this.submitSearch}>
          <div className="main-heading" />
          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                  <label htmlFor="">Month Range: From: </label>
                </div>
                <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    format={'MM/yyyy'}
                    calendar={CustomCalendar}
                    width="100"
                    name="startDate"
                    onChange={this.handleStartDateChange}
                    value={this.state.startDate}
                    formatPlaceholder={{ year: 'YYYY', month: 'MM'}}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>

            <div className="col-md-2">
              <div className="d-block">
                  <label htmlFor="">To:</label>
                </div>
                <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    format={'MM/yyyy'}
                    calendar={CustomCalendar}
                    width="100"
                    name="endDate"
                    onChange={this.handleEndDateChange}
                    value={this.state.endDate}
                    min={this.state.startDate ? new Date(this.state.startDate) : new Date("2020-01-01")}
                    formatPlaceholder={{ year: 'YYYY', month: 'MM'}}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch}>
                Clear
              </Button>
            </div>
          </div>

        </form>

        <div className="main-seperator" />

        <div className="row">
          <div className="col-md-6">
            <div className="main-heading">Search Results</div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">

            <Grid
              data={orderBy(this.state.resourceUtilizations, this.state.sort)}
              className="self-utilization-grid" 
              style={{ maxHeight: '325px' }}
              resizable
              sortable={true}
              sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }}
            >
              <GridColumn 
                field="ProjectName" 
                title="Project"
                width="150px"
                footerCell={() => {
                  return <td>{"Total"}</td>;
                }}
              />
              <GridColumn 
                field="ProjectCode" 
                title="Project Code" 
                width="130px"
                footerCell={() => {
                  return <td>{}</td>;
                }}
              />
              <GridColumn title="Actual">
                <GridColumn 
                  field="ActualUtilization" 
                  title="Total Utilization"
                  width="120px"
                  cell={props => <FormatCell {...props} />} 
                  footerCell={() => {
                    return <td>{Number(this.state.totalActualUtilization).toFixed(2) + "%"}</td>;
                  }}
                />
                <GridColumn 
                  field="ActualBillableUtilization" 
                  title="Billable Utilization"
                  width="120px"
                  cell={props => <FormatCell {...props} />} 
                  footerCell={() => {
                    return <td>{Number(this.state.totalActualBillableUtilization).toFixed(2) + "%"}</td>;
                  }}
                />
                <GridColumn 
                  field="ActualNonBillableUtilization" 
                  title="Non-Billable Utilization"
                  width="120px"
                  cell={props => <FormatCell {...props} />} 
                  footerCell={() => {
                    return <td>{Number(this.state.totalActualNonBillableUtilization).toFixed(2) + "%"}</td>;
                  }}
                />
                <GridColumn 
                  field="ActualRevenue" 
                  title="Revenue (USD)"
                  width="120px"
                  cell={props => <FormatCell {...props} />} 
                  footerCell={() => {
                    return <td>{Number(this.state.totalActualRevenue).toFixed(2)}</td>;
                  }}
                />
              </GridColumn>
              <GridColumn title="Planned">
                <GridColumn 
                  field="PlannedUtilization" 
                  title="Total Utilization"
                  width="120px"
                  cell={props => <FormatCell {...props} />} 
                  footerCell={() => {
                    return <td>{Number(this.state.totalPlannedUtilization).toFixed(2) + "%"}</td>;
                  }}
                />
                <GridColumn 
                  field="PlannedBillableUtilization" 
                  title="Billable Utilization"
                  width="120px"
                  cell={props => <FormatCell {...props} />} 
                  footerCell={() => {
                    return <td>{Number(this.state.totalPlannedBillableUtilization).toFixed(2) + "%"}</td>;
                  }}
                />
                <GridColumn 
                  field="PlannedNonBillableUtilization" 
                  title="Non-Billable Utilization"
                  width="120px"
                  cell={props => <FormatCell {...props} />} 
                  footerCell={() => {
                    return <td>{Number(this.state.totalPlannedNonBillableUtilization).toFixed(2) + "%"}</td>;
                  }}
                />
                <GridColumn 
                  field="PlannedRevenue" 
                  title="Revenue (USD)"
                  width="120px"
                  cell={props => <FormatCell {...props} />} 
                  footerCell={() => {
                    return <td>{Number(this.state.totalPlannedRevenue).toFixed(2)}</td>;
                  }}
                />
              </GridColumn>
            </Grid>
          </div>
        </div>

        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default ResourceUtilization;



