import React, { Component } from "react";
import ResourceRequestStatusENUMS from "./ResourceRequestStatusEnums"
import moment from "moment";



class ResourceRequestLineStartDateCell extends Component {

    render() {
        let dataItem = this.props.dataItem;
        let projectStartDate = null;
        let countryId;
        let totalEffortDays;


        if(dataItem.Type === 'Project'){
            projectStartDate = dataItem.Project.StartDate
        }
        else{
            projectStartDate = dataItem.ChangeRequest.StartDate
        }
        if(this.props.dataItem.IsPreSales){
          return <td>{moment(dataItem.StartDate).format('MM-DD-YYYY')}</td>;
        }else{
          //not presales
          if(this.props.dataItem.StartDate === null || this.props.dataItem.StartDate === ''){
            if (this.props.dataItem.SubPhase && this.props.dataItem.SubPhase.StartDate){
              let value = this.props.dataItem.SubPhase;

              if(value.StartDate < projectStartDate){
                this.props.dataItem.StartDate = projectStartDate;
                return <td>{moment(this.props.dataItem.StartDate).format('MM-DD-YYYY')}</td>;
              }
              else {

                return <td>{moment(value.StartDate).format('MM-DD-YYYY')}</td>;
              }
            }
            else if(this.props.dataItem.Phase && this.props.dataItem.Phase.StartDate){

              let value = this.props.dataItem.Phase;

              if(value.StartDate < projectStartDate){
                dataItem.StartDate = projectStartDate;
                return <td>{moment(dataItem.StartDate).format('MM-DD-YYYY')}</td>;
              }
              else {

                return <td>{moment(value.StartDate).format('MM-DD-YYYY')}</td>;
              }

            }
            else {
              return <td>{moment(projectStartDate).format('MM-DD-YYYY')}</td>;
            }
          }
          else{

            if(new Date(dataItem.StartDate) < new Date(projectStartDate)){
              this.props.dataItem.StartDate = projectStartDate;
              return <td>{moment(dataItem.StartDate).format('MM-DD-YYYY')}</td>;
            }
            else {
              return <td>{moment(dataItem.StartDate).format('MM-DD-YYYY')}</td>;
            }
          }
        }


    }
}

export default ResourceRequestLineStartDateCell;
