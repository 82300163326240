/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { MultiSelect, ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import {Input, NumericTextBox} from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import MultiIncrementYearPicker from '../../masterData/multiyearIncrement/components/MultiIncrementYearPicker';
import { Redirect } from 'react-router';
import { Tooltip } from 'reactstrap';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import Loader from '../../integration/components/Loader';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem, PanelBarUtils, Menu, MenuItem, MenuItemModel, MenuItemLink, MenuItemArrow, Splitter, Drawer, DrawerNavigation, DrawerContent, Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, Avatar, Stepper } from '@progress/kendo-react-layout'
import {
    Chart,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisTitle,
    ChartCategoryAxisItem
} from '@progress/kendo-react-charts';
import ReactQuill from 'react-quill';
import cmSheetInitData from '../../ui/static/data/cm-sheet.json';

import HeaderCellAddButton from '../cmSheet/component/common/HeaderCellAddButton';
import RowCellDeleteButton from '../cmSheet/component/common/RowCellDeleteButton';
import RowCellComboBox from '../cmSheet/component/common/RowCellComboBox';
import RowCellDropDown from '../cmSheet/component/common/RowCellDropDown';
import RowCellDatePicker from '../cmSheet/component/common/RowCellDatePicker';
import RowCellDuplicateButton from '../cmSheet/component/common/RowCellDuplicateButton';
import RowCellNumericTextBox from '../cmSheet/component/common/RowCellNumericTextBox';
import FactoryRowDetails from './FactoryRowDetails'
import {createInitialCMsheet, getChangeRequest, updateChangeReq} from "../changeRequest/ChangeRequestService";
import {
    approveCMSheet,
    calculateProjectEndDate,
    createCMSheet,
    getCMSheet, getCMSheetApprovals,
    getStartDateByPriority, rejectCMSheet, reviseCMSheet,
    saveCMSheet, setWorkflow
} from "../cmSheet/CMSheetService";
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import {getCustomerProjectsById, getProject, updateProject} from "../project/ProjectService";
import {getFormattedDate} from "../../integration/CommonUtils";
import {getResourceRegionsIntegrated} from "../../integration/MasterDataService";
import ResourceAllocations from "../cmSheet/component/resourceAllocations/ResourceAllocations";
import ResourceAllocPool from "../cmSheet/component/resourceAllocations/ResourceAllocPool";
import {getProjectResources} from "../../resourceManagement/ResourceService";
import moment from "moment";
import SuccessImg from "../../ui/static/images/checked.svg";
import ErrorImg from "../../ui/static/images/cancel.svg";
import OtherExpenses from "../cmSheet/component/expenses/OtherExpenses";
import OtherExpensesPool from "../cmSheet/component/expenses/OtherExpensesPool";
import FileUpload from "../../integration/fileUpload/FileUpload";
import ChartSection from "../cmSheet/component/chartSection/ChartSection";
import SummaryPoolProject from "../cmSheet/component/summaryPoolProject/SummaryPoolProject";
import {saveAuditTrail} from "../../integration/auditTrail/AuditTrailService";
import {selectWorkflow, selectWorkflowPool} from "../workflow/WorkflowService";
import ApprovalCommentCell from "../cmSheet/component/ApprovalCommentCell";
import DateCell from "../cmSheet/component/summaryPoolProject/DateCell";



const ACTION_CREATE = 'create';
const ACTION_VIEW = 'view';
const ACTION_EDIT = 'edit';
const ACTION_APPROVE = 'approve';
const ACTION_REVISE = 'revise';

const ADMIN_RATE = 'ADMIN_RATE';
const APPROVED_PROJ_RATE = 'APPROVED_PROJ_RATE';
const STANDARD_RATE = 'REGION_RATE';
const PRE_AGREED_RATE = 'CUSTOMER_RATE';
const LAST_OVERRIDE_RATE = 'LAST_CUS_RATE';
const PROJECT_APPROVED_RATE = 'PROJECT_APPROVED_RATE';
const PROJECT_APPROVED_CR_RATE = 'PROJECT_APPROVED_CR_RATE';

class FactoryProjectCMS extends Component {
    isMount = false;
    lastSelectedIndex = 0;

    popupSet = {
        width: 'auto'
    };

    cmsStatuses = {
        IN_PROGRESS: 'In Progress',
        PENDING_APPROVALS: 'Pending Approvals',
        APPROVED: 'Approved',
        REJECTED: 'Rejected',
        EXPIRED: 'Expired'
    };

    constructor(props) {
        super(props);
        this.state = {
            selected: 0,
            calculations: [],
            factoryResource: [],
            navigateToSearchScreen: false,
            regionNamesMap: {},
            selectedAdditionalRegion : [],
            selectedRegion: null,
            cmSheet: cmSheetInitData,
            IsPoolProject:false,
            customerProjects: [],
            cmSheetVersions: [],
            matchingWorkflowList: [],
            approversList: [],
            isViewMode: props.location.action === ACTION_VIEW,
            PMName: '',
            navigateToDashboard: false,
            approvalComment: '',
            approveParams: null,

        };
    }

    componentDidMount() {
        if (!this.props.location.action) {
            this.navigateToSearchScreen();
        }
        else {
            this.setHeaderTitle(this.props.location.action);

            this.setState(
                {
                    action: this.props.location.action,
                    locationCMSheet: this.props.location.cmSheet,
                    cmsReference: this.props.location.reference
                },
                () => {
                    if (this.props.location.reference && (this.props.location.reference === 'crCMSPool' || this.props.location.reference === 'crCMS')) {
                        this.handleProjectChange(
                            this.props.location.projectId,
                            this.props.location.changeReqId
                        );
                    } else if (this.props.location.projectId > 0) {

                        this.handleProjectChange(this.props.location.projectId, 0);
                    } else {
                        this.setState({
                            isViewMode: true,
                            isLoading: false
                        });
                    }

                    if (
                        this.props.location.action !== ACTION_CREATE &&
                        this.props.location.action !== ACTION_EDIT
                    ) {
                        this.setState({
                            isViewMode: true
                        });
                    }
                }
            );

            // Load active projects
            //this.populateactiveProjects();

        }

        this.setState({
            IsPoolProject:true
        });
    }

    populateRegions = async () => {
        getResourceRegionsIntegrated()
            .then(res => {
                const regionNames = {};
                for (const region of res.data) {
                    regionNames[region.id] = region.Name;
                }
                this.setState({
                    regions: res.data,
                    regionNamesMap: regionNames
                });
            })
            .catch(e => {
                loggerService.writeLog(e, LOG_TYPES.ERROR);
            });
    };

    DatePickerCell = props => {
        return (
            <RowCellDatePicker
                {...props}
            />
        );
    }

    navigateToDashboard = () => {
        this.setState({
            navigateToDashboard: true
        });
    };

    ComboBoxCell = props => {
        return (
            <RowCellComboBox
                {...props}

            />
        );
    }

    rowRender(trElement, props) {
        const isHeader = props.dataItem.isHeader;
        const contEffort = props.dataItem.contEffort;
        const className = 'summery-header-style';
        const contclass = 'contract-effort';
        const trProps = { class: isHeader === true ? className : null };
        const cedProps = { class: contEffort === true ? contclass : null };
        if (isHeader) {
            return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
        } else if (contEffort) {
            return React.cloneElement(trElement, { ...cedProps }, trElement.props.children);
        } else
            return trElement;
    }

    getResourceAllocationsTotalValues =  resourceAllocations => {

        let totalFTEResourceAllocation = 0;
        let totalRevenueResourceAllocation = 0;
        let totalEffortDaysResourceAllocation = 0;
        let totalEffortDaysResourceAllocationNonBill = 0;
        let totalCostToRevenueRatioResourceAllocation = 0;

        // Fixed and T&M Effort days
        let totalEffortDaysResourceAllocationFixed = 0
        let totalEffortDaysResourceAllocationTnM = 0

        for (const allocation of resourceAllocations) {
            totalRevenueResourceAllocation = totalRevenueResourceAllocation + allocation.TotalRevenue;
            if(allocation.BillableStatus === false){
                totalEffortDaysResourceAllocationNonBill =
                    totalEffortDaysResourceAllocationNonBill         + allocation.TotalCost
            }
            else if(allocation.BillableStatus  === true){
                totalFTEResourceAllocation = totalFTEResourceAllocation + allocation.FTE;
                totalEffortDaysResourceAllocation = totalEffortDaysResourceAllocation + allocation.TotalCost;
            }

            totalCostToRevenueRatioResourceAllocation = totalFTEResourceAllocation / totalRevenueResourceAllocation;
        }

        return {
            TotalFTEResourceAllocation: totalFTEResourceAllocation,
            TotalRevenueResourceAllocation: totalRevenueResourceAllocation,
            TotalEffortDaysResourceAllocation: totalEffortDaysResourceAllocation,
            TotalEffortDaysResourceAllocationNonBill: totalEffortDaysResourceAllocationNonBill,
            TotalCostToRevenueRatioResourceAllocation: totalCostToRevenueRatioResourceAllocation,
            TotalEffortDaysResourceAllocationFixed: totalEffortDaysResourceAllocationFixed,
            TotalEffortDaysResourceAllocationTnM: totalEffortDaysResourceAllocationTnM
        };
    };


    handleProjectChange = async (projectId, changeReqId) => {
        let billRegionObj = null;
        let offeringObj = null;
        let statusObj = null;
        let billDivObj = null;
        let selectedRegions = [];
        let selectedOfferings = [];
        let selectedPractices = [];

        //get customer projects
        if (projectId){
            await getCustomerProjectsById(projectId)
                .then(res => {
                    if (res.data && res.data.length > 0) {
                            this.setState({
                                customerProjects: res.data
                            });
                    }
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        }

        if (changeReqId && changeReqId !== 0) {
            await getChangeRequest(changeReqId)
                .then(res => {
                    const changeRequest = res.data;

                    if (this.state.action === ACTION_VIEW) {
                        this.onSaveAuditTrail(res.data, this.state.locationCMSheet.id);
                    }
                    const project = res.data.Project;
                                       for(const obj of project.FactoryProjectBillRegion){
                            billRegionObj = {
                                Name: obj.Region.Name,
                                id: obj.id
                            };
                            selectedRegions = selectedRegions.concat(billRegionObj);
                        }

                    for(const obj of project.FactoryProjectOffering){
                            offeringObj = {
                                Name: obj.Offering.Name,
                                id: obj.id
                            };
                            selectedOfferings = selectedOfferings.concat(offeringObj);
                        }

                        for(const obj of project.FactoryProjectBillDivision){
                            if(obj.IsActive === true){
                                billDivObj = {
                                    Name: obj.BillingDivision.Name,
                                    id: obj.BillingDivision.id
                                };
                                selectedPractices = selectedPractices.concat(billDivObj);
                            }
                        }

                        const resourceObj = {
                            displayName: project.PresalesResource.Name,
                            id: project.PresalesResource.id
                        };

                        const statusObj = {
                            StatusName: changeRequest.Status.StatusName,
                            id: changeRequest.Status.id
                        };

                    //set project details
                    const startDateMin = changeRequest.StartDate
                        ? new Date(changeRequest.StartDate)
                        : new Date();
                    const startDate = changeRequest.StartDate ? new Date(changeRequest.StartDate) : null;

                       this.setState(
                        {
                            changeRequest: changeRequest,
                            CRName: changeRequest.CRName,
                            project: project,
                            selectedRegion: changeRequest.Region,
                            selectedOfferings: selectedOfferings,
                            selectedPractices: selectedPractices,
                            statusValue: statusObj,
                            targetBillable : changeRequest.TargetBillable,
                            StartDate: startDate,
                            startDateMin: startDateMin,
                            lastSelectedCurrencyId:
                                changeRequest.Region && changeRequest.Region.Currency
                                    ? changeRequest.Region.Currency.id
                                    : 0,
                            type: changeRequest.Type,
                            resourceValue: resourceObj,
                            selectedAdditionalRegion: selectedRegions,
                            financialYear:changeRequest.FinancialYear
                        },

                       () => {
                            if (this.state.action !== ACTION_CREATE) {
                                const cmSheet = this.state.locationCMSheet;
                                if (cmSheet) {
                                    getCMSheet(cmSheet.id, cmSheet.Version)
                                        .then(res => {
                                            const txtVersion = res.data.MajorVersion + '.' + res.data.Version;
                                            const item = { Text: txtVersion, Version: res.data.Version };
                                            this.setState(
                                                {
                                                    LastVersion: res.data.Version,
                                                    selectedVersion: item,
                                                    pmComment: res.data.PMComment,
                                                    selectedCmsType: res.data.CmsType
                                                },
                                                async () => {
                                                    const cmSheet = res.data;

                                                    //await this.calculateHeaderFields(changeRequest, cmSheet);
                                                    await this.populateCMSheetVersions(cmSheet, 'crCMS');
                                                    this.setState({
                                                        isLoading: false,
                                                        excelDataObsolete: true
                                                    });
                                                }
                                            );
                                        })
                                        .catch(error => {
                                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                                            this.setState({
                                                isLoading: false
                                            });
                                        });
                                }
                            }

                            if (this.state.action === ACTION_CREATE) {
                                const cmSheetCreatedDate = {
                                    createdDate: new Date()
                                };
                                createInitialCMsheet(changeRequest.id, cmSheetCreatedDate)
                                    .then(async res => {
                                        const txtVersion = res.data.MajorVersion + '.' + res.data.Version;
                                        const item = { Text: txtVersion, Version: res.data.Version };
                                        const cmSheet = res.data;
                                        if (changeRequest && changeRequest.PresalesResource && changeRequest.PresalesResource.id > 0) {
                                            const cmSheet = res.data;
                                            if (changeRequest && changeRequest.PresalesResource && changeRequest.PresalesResource.id > 0) {
                                                this.setState({
                                                    PMName: changeRequest.PresalesResource.Name
                                                });
                                            }
                                            this.setState(
                                                {
                                                    LastVersion: res.data.Version,
                                                    selectedVersion: item
                                                },
                                                async () => {
                                                    //await this.calculateHeaderFields(changeRequest, cmSheet);
                                                    await this.populateCMSheetVersions(cmSheet, 'crCMS');
                                                    this.setState({
                                                        isLoading: false
                                                    });
                                                }
                                            );
                                        }
                                    })
                                    .catch(error => {
                                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                                        this.setState({
                                            isLoading: false
                                        });
                                    });
                            }
                            this.setState({
                                isLoading: false
                            });
                        }

                    );

                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                    this.setState({
                        isLoading: false
                    });
                });



        }
        else {
            getProject(projectId)
                .then(async res => {

                    if (res && res.data && res.data.id === projectId) {
                        const project = res.data;

                        if (this.state.action === ACTION_VIEW) {
                            this.onSaveAuditTrail(res.data, this.state.locationCMSheet.id);
                        } //set project details
                        const startDateMin = project.StartDate ? new Date(project.StartDate) : new Date();

                        for(const obj of project.FactoryProjectBillRegion){
                            billRegionObj = {
                                Name: obj.Region.Name,
                                id: obj.id
                            };
                            selectedRegions = selectedRegions.concat(billRegionObj);
                        }

                        for(const obj of project.FactoryProjectOffering){
                            offeringObj = {
                                Name: obj.Offering.Name,
                                id: obj.id
                            };
                            selectedOfferings = selectedOfferings.concat(offeringObj);
                        }

                        for(const obj of project.FactoryProjectBillDivision){

                            if(obj.IsActive === true){
                                billDivObj = {
                                    Name: obj.BillingDivision.Name,
                                    id: obj.BillingDivision.id
                                };
                                selectedPractices = selectedPractices.concat(billDivObj);
                            }

                        }
                        const resourceObj = {
                            displayName: project.PresalesResource.Name,
                            id: project.PresalesResource.id
                        };
                        const statusObj = {
                            StatusName: project.ProjectStatus.StatusName,
                            id: project.ProjectStatus.id
                        };
                        await this.setState(
                            {
                                project: project,
                                projectName:project.ProjectName,
                                selectedRegion: project.Region,
                                selectedOfferings: selectedOfferings,
                                selectedPractices: selectedPractices,
                                statusValue: statusObj,
                                targetBillable : project.TargetBillable,
                                selectedAdditionalRegion: selectedRegions,
                                StartDate: project.StartDate ? new Date(project.StartDate) : null,
                                startDateMin: startDateMin,
                                lastSelectedCurrencyId:
                                    project.Region && project.Region.Currency ? project.Region.Currency.id : 0,
                                resourceValue: resourceObj,
                                type: project.Type,
                                financialYear:project.FinancialYear
                            },

                            () => {

                                if (this.state.action !== ACTION_CREATE) {
                                    const cmSheet = this.state.locationCMSheet;
                                    if (cmSheet) {
                                        getCMSheet(cmSheet.id, cmSheet.Version)
                                            .then(res => {
                                                const txtVersion = res.data.MajorVersion + '.' + res.data.Version;
                                                const item = { Text: txtVersion, Version: res.data.Version };
                                                let cmSheetAfterCal = res.data;
                                                const totalValuesRA = this.getResourceAllocationsTotalValues(
                                                    res.data.ResourceAllocations
                                                );


                                                const otherExpensesTotal = this.getOtherExpensesTotalValues(
                                                    res.data.OtherExpenses
                                                );

                                                cmSheetAfterCal.TotalDeployableFTE =
                                                    totalValuesRA.TotalFTEResourceAllocation;
                                                cmSheetAfterCal.TotalEffortDaysResourceAllocationNonBill =
                                                    totalValuesRA.TotalEffortDaysResourceAllocationNonBill;
                                                cmSheetAfterCal.TotalEffortDaysResourceAllocation =
                                                    totalValuesRA.TotalEffortDaysResourceAllocation;
                                                cmSheetAfterCal.TotalRevenueResourceAllocation =
                                                    totalValuesRA.TotalRevenueResourceAllocation;
                                                cmSheetAfterCal.TotalCostToRevenueRatioResourceAllocation =
                                                    totalValuesRA.TotalCostToRevenueRatioResourceAllocation;

                                                cmSheetAfterCal.TotalEffortDaysResourceAllocationFixed =
                                                    totalValuesRA.TotalEffortDaysResourceAllocationFixed;

                                                cmSheetAfterCal.TotalEffortDaysResourceAllocationTnM =
                                                    totalValuesRA.TotalEffortDaysResourceAllocationTnM;

                                                cmSheetAfterCal.BillableTotalOtherExpenses = otherExpensesTotal.billTotal;
                                                cmSheetAfterCal.NonBillableTotalOtherExpenses =
                                                    otherExpensesTotal.nonBillTotal;
                                                cmSheetAfterCal.OtherExpensesTotal =
                                                    otherExpensesTotal.billTotal + otherExpensesTotal.nonBillTotal;
                                                this.setState(
                                                    {
                                                        LastVersion: res.data.Version,
                                                        selectedVersion: item,
                                                        pmComment: res.data.PMComment,
                                                        selectedCmsType: res.data.CmsType
                                                    },
                                                    async () => {
                                                        if (project && project.ResourceId > 0) {
                                                            await this.LoadResource(project.ResourceId);
                                                        }
                                                        if (project && project.ResourceId > 0) {
                                                            await this.LoadResource(project.ResourceId);
                                                        }

                                                        //await this.calculateHeaderFields(project, cmSheetAfterCal);

                                                        await this.populateCMSheetVersions(cmSheetAfterCal, 'projectCMS');
                                                        this.setState({
                                                            isLoading: false,
                                                            excelDataObsolete: true
                                                        });
                                                    }
                                                );
                                            })
                                            .catch(error => {
                                                loggerService.writeLog(error, LOG_TYPES.ERROR);
                                                this.setState({
                                                    isLoading: false
                                                });
                                            });
                                    }
                                }

                                if (this.state.action === ACTION_CREATE) {
                                    const cmSheetCreatedDate = {
                                        createdDate: new Date()
                                    };

                                    createCMSheet(project.id, cmSheetCreatedDate)
                                        .then(res => {
                                            const txtVersion = res.data.MajorVersion + '.' + res.data.Version;
                                            const item = { Text: txtVersion, Version: res.data.Version };
                                            this.setState(
                                                {
                                                    LastVersion: res.data.Version,
                                                    selectedVersion: item
                                                },
                                                async () => {
                                                    const cmSheet = res.data;
                                                    if (project && project.ResourceId > 0) {
                                                        await this.LoadResource(project.ResourceId);
                                                        if (project && project.ResourceId > 0) {
                                                            await this.LoadResource(project.ResourceId);
                                                        }
                                                        //await this.calculateHeaderFields(project, cmSheet);
                                                        await this.populateCMSheetVersions(cmSheet, 'projectCMS');
                                                        this.setState({
                                                            isLoading: false
                                                        });
                                                    }
                                                }
                                            );
                                        })
                                        .catch(error => {
                                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                                            this.setState({
                                                isLoading: false
                                            });
                                        });
                                }
                            }
                        );

                    }
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                    this.setState({
                        isLoading: false
                    });
                });
        }

    };

    onSaveAuditTrail = (project, cmSheetId) => {
        const auditTrail = {
            Action: 'Pool CM Sheet viewed',
            RefType: 'CM Sheet',
            RefId: cmSheetId,
            RefName: project.OPID
        };
        saveAuditTrail(auditTrail);
    };

    populateCMSheetVersions = (cmSheet, cmsType) => {
        const list = [];
        let version = cmSheet.Version;
        while (version >= 0) {
            const txtVersion = cmSheet.MajorVersion + '.' + version;
            const item = { Text: txtVersion, Version: version };
            list.push(item);
            version = version - 1;
        }
        this.setState(
            {
                cmSheetVersions: list,
                selectedVersion: list[0]
            },
            () => {
                this.processCMSheetData(cmSheet, cmsType);
            }
        );
    };

    processCMSheetData = async (cmSheet, cmsType) => {
        if (cmsType === 'crCMS') {
            this.setState(
                {
                    cmSheet: cmSheet,
                    StartDate:
                        this.state.selectedVersion.Version === this.state.LastVersion
                            ? this.state.changeRequest.StartDate
                            ? new Date(this.state.changeRequest.StartDate)
                            : null
                            : cmSheet.StartDate
                            ? new Date(cmSheet.StartDate)
                            : null,
                },
                () => {
                    if (
                        this.state.selectedVersion &&
                        this.state.selectedVersion.Version === this.state.LastVersion &&
                        this.state.action === ACTION_EDIT
                    ) {
                        //this.checkForMasterDataRatesChanges();
                    }
                }
            );
        }
        else {
            this.setState(
                {
                    cmSheet: cmSheet,
                    StartDate:
                        this.state.selectedVersion.Version === this.state.LastVersion
                            ? this.state.project.StartDate
                            ? new Date(this.state.project.StartDate)
                            : null
                            : cmSheet.StartDate
                            ? new Date(cmSheet.StartDate)
                            : null,
                },
                () => {
                    if (
                        this.state.selectedVersion &&
                        this.state.selectedVersion.Version === this.state.LastVersion &&
                        this.state.action === ACTION_EDIT
                    ) {
                        //this.checkForMasterDataRatesChanges();
                    }
                }
            );
        }

        if (this.state.action === ACTION_APPROVE) {
            this.setState({ isViewMode: false, isApproveMode: true });
            this.populateCurrentApprovals(cmSheet.id, cmSheet.Version);
        }

        if (this.state.action === ACTION_VIEW || this.state.action === ACTION_EDIT || this.state.action === ACTION_REVISE || this.state.action === ACTION_APPROVE) {

            this.populateCurrentApprovals(cmSheet.id, cmSheet.Version);

            let projectOrCR = null;
            if (this.state.cmsReference === 'crCMS') {
                projectOrCR = this.state.changeRequest;
                this.setState({
                    selectedSalesPerson: cmSheet.ChangeRequest && cmSheet.ChangeRequest.SalesPerson ? cmSheet.ChangeRequest.SalesPerson.id : null
                });
            } else {
                projectOrCR = this.state.project;
                this.setState({
                    selectedSalesPerson: cmSheet.Project && cmSheet.Project.SalesPersonRecord ? cmSheet.Project.SalesPersonRecord.id : null
                });
            }
            this.setState({
                pmComment: cmSheet.PMComment
            });
            if (projectOrCR) {
                //this.calculateHeaderFields(projectOrCR, cmSheet);
            }
        }
    };

    /**
     * populateCurrentApprovals - populate current resources which has approvals in the cmsheet
     * @param {cmSheetId, cmSheetVersion} cmsheet id and the cmsheet version
     * @returns {object} All Approvers Resource List.
     */
    populateCurrentApprovals = (cmSheetId, cmSheetVersion) => {
        getCMSheetApprovals(cmSheetId, cmSheetVersion)
            .then(res => {
                this.setState({
                    approversList: res.data,
                    disableExcelDownload: false
                });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    handleCommentChange = comment => {
        let Comment = comment.replace(/(<([^>]+)>)/ig, '');
        if (Comment.trim() == '') {
            let pmName = this.state.PMName;
            this.setState({
                pmComment: pmName,
                hasUnSavedData: true
            });
        } else {
            this.setState({
                pmComment: comment,
                hasUnSavedData: true
            });
        }
    };

    LoadResource = async (projectManagerId) => {
        await getProjectResources()
            .then(response => {
                if (response && response.data && response.data.length > 0) {
                    const filteredPMs = response.data.filter(obj => obj.id === projectManagerId);
                    if (filteredPMs && filteredPMs.length > 0) {
                        this.setState({
                            PMName: filteredPMs[0].Name
                        });
                    }
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };


    navigateToSearchScreen = () => {
        this.setState({
            navigateToSearchScreen: true
        });
    };

    onCancelApproval = () => {
        this.navigateToSearchScreen();
    };

    onCancelRevise = () => {
        this.navigateToSearchScreen();
    };

    onCancelButtonClick = () => {
        if (this.state.hasUnSavedData === true && this.state.action === 'edit' || this.state.action === 'create') {
            this.toggleConfirmDialog(
                'This form contains unsaved data. Do you want to close it?',
                this.navigateToSearchScreen
            );
        } else {
            this.navigateToSearchScreen();
        }
    };

    toggleConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    setHeaderTitle = async (action) => {
        if (this.props.onHeaderTitleChange) {
            if (action === ACTION_CREATE) {
                this.props.onHeaderTitleChange('Create Cost Management Sheet');
            } else if (action === ACTION_EDIT) {
                this.props.onHeaderTitleChange('Edit Cost Management Sheet');
            } else if (action === ACTION_VIEW) {
                this.props.onHeaderTitleChange('View Cost Management Sheet');
            } else if (action === ACTION_APPROVE) {
                this.props.onHeaderTitleChange('Approve Cost Management Sheet');
            } else if (action === ACTION_REVISE) {
                this.props.onHeaderTitleChange('Revise Cost Management Sheet');
            }
        }
    };


    handleSelect = (e) => {
        this.setState({ selected: e.selected })
    }

    selectionChange = (event) => {
        const data = this.state.data.map(item => {
            if (item.ProductID === event.dataItem.ProductID) {
                item.selected = !event.dataItem.selected;
            }
            return item;
        });
        this.setState({ data });
    }
    rowClick = event => {
        let last = this.lastSelectedIndex;
        const data = [...this.state.data];
        const current = data.findIndex(dataItem => dataItem === event.dataItem);

        if (!event.nativeEvent.shiftKey) {
            this.lastSelectedIndex = last = current;
        }

        if (!event.nativeEvent.ctrlKey) {
            data.forEach(item => (item.selected = false));
        }
        const select = !event.dataItem.selected;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
            data[i].selected = select;
        }
        this.setState({ data });
    };

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        const data = this.state.data.map(item => {
            item.selected = checked;
            return item;
        });
        this.setState({ data });
    }



    handleStartdateChange = (event) => {
        let startDate = null;

        if (this.state.cmsReference === 'crCMS') {
            if (event.target.value === '') {
                this.state.changeRequest.EstimatedStartDate = new Date();
                startDate = null;
            } else {
                this.state.changeRequest.EstimatedStartDate = event.target.value;
                startDate = event.target.value;
            }

            let startDateStr = '';
            let endDate = null;
            let endDateStr = '';

            this.setState(prevState => {
                    return {
                        StartDate: startDate,
                        endDate: endDate,
                        changeRequest: {
                            ...prevState.changeRequest,
                            StartDate: startDateStr === '' ? startDate : startDateStr,
                            EndDate: endDateStr === '' ? endDate : endDateStr,
                        },
                        hasUnSavedData: true,
                    };
                },
                () => {
                    let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
                    //this.calculateHeaderFields(this.state.changeRequest, cmSheet);
                    this.setState({
                        cmSheet: cmSheet,
                        hasUnSavedData: true
                    });
                }
            );
        }
        else {
            if (event.target.value === '' || event.target.value === null) {
                this.state.project.EstimatedStartDate = new Date();
                startDate = null;
            }
            else {
                this.state.project.EstimatedStartDate = event.target.value;
                startDate = event.target.value;
            }

            let startDateStr = '';
            let endDate = null;
            let endDateStr = '';

            this.setState(prevState => {

                    return {
                        StartDate: startDate,
                        endDate: endDate,
                        project: {
                            ...prevState.project,
                            StartDate: startDateStr === '' ? startDate : startDateStr,
                            EndDate: endDateStr === '' ? endDate : endDateStr,
                        },
                        hasUnSavedData: true,

                    };
                },
                () => {
                    let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
                    //this.calculateHeaderFields(this.state.project, cmSheet);
                    this.setState({
                        cmSheet: cmSheet,
                        hasUnSavedData: true
                    });
                }
            );
        }
    };

    handleSaveButtonClick = async event => {

        if (event) {
            event.preventDefault();
        }
                if (this.state.cmsReference === 'crCMS') {
                    await this.saveCMSheetBasedOnRef(
                        this.state.lastSelectedCurrencyId,
                        this.state.changeRequest,
                        this.state.cmSheet,
                        this.state.cmsReference
                    );
                } else {
                    await this.saveCMSheetBasedOnRef(
                        this.state.lastSelectedCurrencyId,
                        this.state.project,
                        this.state.cmSheet,
                        this.state.cmsReference
                    );
                }
    };

    saveCMSheetBasedOnRef = async (lastSelectedCurrencyId, projectOrCR, cmSheet_, cmsReference) => {
    try {
      // if (lastSelectedCurrencyId !== projectOrCR.Region.Currency.id) {
      //   const message =
      //     'Selected currency is not the project currency(' +
      //     projectOrCR.Region.Currency.Code +
      //     ')';
      //   const title = 'Currency';
      //   this.toggleMessageDialog(message, title);
      //   return;
      // }

      this.setState({
        isSaving: true,
        IsPoolProject:true
      });

      let expenses = [];
      let allocations = [];
      let cmSheet = JSON.parse(JSON.stringify(cmSheet_));

      // validate other expenses
      for (const expense of cmSheet_.OtherExpenses) {
        const exp = JSON.parse(JSON.stringify(expense));

        delete exp.dataValueBillable;
        delete exp.dataValueExpenseType;
        delete exp.element;
        delete exp.inEdit;
        delete exp.index;

        expenses.push(exp);
      }
      cmSheet.OtherExpenses = expenses;

      let isResourceAllocationsValid = true;

      for (const allocation of cmSheet_.ResourceAllocations) {
        const alloc = JSON.parse(JSON.stringify(allocation));

        if (
          !(
            allocation.AllocationPercentage > 0
          )
        ) {
          let message = 'Allocation percentage cannot be zero';
          const title = 'Error';
          this.toggleMessageDialog(message, title);
          isResourceAllocationsValid = false;
          break;
        }

        if (
          allocation.StartDate &&
          this.state.project.StartDate &&
          allocation.StartDate < projectOrCR.StartDate
        ) {
          const message =
            'Date values are not valid in resource allocations!( Start dates are not valid )';
          const title = 'Error';
          this.toggleMessageDialog(message, title);
          isResourceAllocationsValid = false;
          break;
        }
        let startDateObj = await getStartDateByPriority(allocation, projectOrCR, cmSheet_);
        let startDate = startDateObj.date;
        allocation.StartDateType = startDateObj.type;

        let countryID = null;

        if (allocation.OnsiteStatus) {
          if (allocation.OnsiteStatus === 'Onsite') {
            countryID = projectOrCR.CountryId;
          } else {
            countryID = allocation.dataValueResourceRegion.DefaultCountryId;
          }

          let { endDate, noOfWorkingDays, totalDays } = await calculateProjectEndDate(
            allocation,
            startDate,
            countryID
          );

          alloc.EstimatedEndDate = moment(endDate).format('YYYY-MM-DD');
        }

        // remove unnecessary fields before send to server
        delete alloc.index;
        delete alloc.dataValuePhase;
        delete alloc.dataValueSubPhase;
        delete alloc.dataValueProjectRole;
        delete alloc.dataValueCostDesignation;
        delete alloc.dataValueResourceRegion;
        delete alloc.dataValueOnsiteStatus;
        delete alloc.dataValueBillableStatus;
        delete alloc.DateStartDate;
        delete alloc.StartDateMin;
        delete alloc.StartDateMax;
        delete alloc.DatesDisabled;
        delete alloc.dataValuePaymentMethod;
        delete alloc.dataValueRateType;
        delete alloc.RateValueDisabled;
        delete alloc.inEdit;
        delete alloc.Phases;
        delete alloc.SubPhases;
        delete alloc.ProjectRoles;
        delete alloc.CostDesignations;
        delete alloc.ResourceRegions;

        alloc.StartDate = alloc.StartDate ? moment(alloc.StartDate).format('YYYY-MM-DD') : alloc.StartDate;
        alloc.EndDate = alloc.EndDate ? moment(alloc.EndDate).format('YYYY-MM-DD') : alloc.EndDate;
        allocations.push(alloc);
      }

      cmSheet.ResourceAllocations = allocations;
      cmSheet.PMComment = this.state.pmComment;

      if (!isResourceAllocationsValid) {
        this.setState({
          isSaving: false
        });
        return;
      }

      let projOrCR = null;
      if (cmsReference === 'crCMS') {
        projOrCR = this.state.changeRequest;
        if (projOrCR.EndDate === '') {
          projOrCR.EndDate = null
        }
        await updateChangeReq(this.state.changeRequest, this.state.changeRequest.id)
          .then(res => {
            setTimeout(() => {

              if (res.data && res.data.StartDate) {
                res.data.EstimatedStartDate = res.data.StartDate;
                res.data.EstimatedEndDate = this.state.changeRequest.EstimatedEndDate
              } else {
                res.data.EstimatedStartDate = this.state.changeRequest.EstimatedStartDate
                res.data.EstimatedEndDate = this.state.changeRequest.EstimatedEndDate
              }

              this.setState({
                changeRequest: res.data
              });
            }, 500);
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      }


      else {
        projOrCR = this.state.project;

        if (projOrCR.EndDate === '') {
          projOrCR.EndDate = null
        }
          projOrCR.TargetBillable = this.state.targetBillable
          projOrCR.StartDate = this.state.StartDate

        await updateProject(this.state.project, this.state.project.id)
          .then(async res => {
            setTimeout(() => {
              this.setState({
                project: res.data
              });
            }, 500);
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      }


      if (cmSheet.ContigencyValue === null) {
        cmSheet.ContigencyValue = 0;
        cmSheet.ContingencyPercentage = 0;
      }
      if (cmSheet.DiscountValue === null) {
        cmSheet.DiscountValue = 0;
        cmSheet.DiscountPercentage = 0;
      }
      if (cmSheet.DiscountPercentage === null) {
        cmSheet.DiscountPercentage = 0;
        cmSheet.DiscountValue = 0;
      }


      for (const obj of cmSheet.ResourceAllocations) {
        if (obj.TotalDays === '' || obj.TotalDays === 0) {
          obj.TotalDays = 0;
        }
      }
      //cmSheet.id = this.state.locationCMSheet.id
        if(!cmSheet.id){
            cmSheet.id = this.state.locationCMSheet.id
        }
      cmSheet.IsPoolProject = this.state.IsPoolProject

      await saveCMSheet(cmSheet)
        .then(async res => {
          cmSheet = res.data;
          this.setState(
            {
              cmSheet: cmSheet,
              hasUnSavedData: false,
              didRun: false,
              disableExcelDownload: false,
              isSaving: false,
              excelDataObsolete: true
            },
            () => {
              const message = 'Saved Successfully';
              const title = 'Success';
              this.setState({
                showSuccessImage: true,
                showErrorImage: false
              });
              this.toggleMessageDialog(message, title);
            }
          );

          //await this.handleUpdateCMSheetModel();

        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.setState({
            isSaving: false
          });
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleMessageDialog(error.response.data, 'Error');
        });
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
      this.setState({
        isSaving: false
      });
    }
  };

    toggleMessageDialog = (message, title) => {
        this.setState({
            showMessageDialog: !this.state.showMessageDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    handleUpdateCMSheetModel = async (cmSheet, isUpdatePhases) => {
        if (isUpdatePhases === true) {
        //     // check newly added resource allocations
        //     const resourceAllocations = [];
        //     for (const allocation of this.state.cmSheet.ResourceAllocations) {
        //         let tempAllocation = allocation;
        //         if (allocation.OnsiteStatus === 1) {
        //             tempAllocation.OnsiteStatus = allocation.OnsiteStatuses[0].Name;
        //             tempAllocation.dataValueOnsiteStatus = allocation.OnsiteStatuses[0];
        //         } else if (allocation.OnsiteStatus === 2) {
        //             tempAllocation.OnsiteStatus = allocation.OnsiteStatuses[1].Name;
        //             tempAllocation.dataValueOnsiteStatus = allocation.OnsiteStatuses[1];
        //         }
        //
        //         if (allocation.PhaseId > 0) {
        //             const phaseFilter = cmSheet.Phases.filter(obj => obj.id === allocation.PhaseId);
        //             if (phaseFilter.length > 0) {
        //                 if (allocation.SubPhaseId > 0) {
        //                     const subPhases = phaseFilter[0].SubPhases;
        //                     const subPhaseFilter = subPhases.filter(obj => obj.id === allocation.SubPhaseId);
        //                     if (subPhaseFilter.length > 0) {
        //                         resourceAllocations.push(tempAllocation);
        //                     }
        //                 } else {
        //                     resourceAllocations.push(tempAllocation);
        //                 }
        //             }
        //         } else {
        //             resourceAllocations.push(tempAllocation);
        //         }
        //     }
        //
        //     // check newly added other expenses
        //     const otherExpenses = [];
        //     for (const expense of this.state.cmSheet.OtherExpenses) {
        //         if (expense.PhaseId > 0) {
        //             const phaseFilter = cmSheet.Phases.filter(obj => obj.id === expense.PhaseId);
        //             if (phaseFilter.length > 0) {
        //                 if (expense.SubPhaseId > 0) {
        //                     const subPhases = phaseFilter[0].SubPhases;
        //                     const subPhaseFilter = subPhases.filter(obj => obj.id === expense.SubPhaseId);
        //                     if (subPhaseFilter.length > 0) {
        //                         otherExpenses.push(expense);
        //                     }
        //                 } else {
        //                     otherExpenses.push(expense);
        //                 }
        //             }
        //         } else {
        //             otherExpenses.push(expense);
        //         }
        //     }
        //
        //     // set cm sheet fields to cm sheet
        //     cmSheet.ResourceAllocations = resourceAllocations;
        //     const totalValuesRA = this.getResourceAllocationsTotalValues(resourceAllocations);
        //     cmSheet.TotalCostResourceAllocation = totalValuesRA.TotalCostResourceAllocation;
        //     cmSheet.TotalRevenueResourceAllocation = totalValuesRA.TotalRevenueResourceAllocation;
        //     cmSheet.TotalEffortDaysResourceAllocation = totalValuesRA.TotalEffortDaysResourceAllocation;
        //     cmSheet.TotalCostToRevenueRatioResourceAllocation = totalValuesRA.TotalCostToRevenueRatioResourceAllocation;
        //     cmSheet.TotalEffortDaysResourceAllocationFixed = totalValuesRA.TotalEffortDaysResourceAllocationFixed;
        //     cmSheet.TotalEffortDaysResourceAllocationTnM = totalValuesRA.TotalEffortDaysResourceAllocationTnM;
        //
        //     // set other expenses fields to cm sheet
        //     cmSheet.OtherExpenses = otherExpenses;
        //     const totalValuesOE = this.getOtherExpensesTotalValues(otherExpenses);
        //     cmSheet.BillableTotalOtherExpenses = totalValuesOE.billTotal;
        //     cmSheet.NonBillableTotalOtherExpenses = totalValuesOE.nonBillTotal;
        //     cmSheet.OtherExpensesTotal = totalValuesOE.billTotal + totalValuesOE.nonBillTotal;
         }
        else {
            // check newly added resource allocations
            const resourceAllocations = [];
            for (const allocation of cmSheet.ResourceAllocations) {
                let tempAllocation = allocation;
                if (allocation.OnsiteStatus === 1) {
                    tempAllocation.OnsiteStatus = allocation.OnsiteStatuses[0].Name;
                    tempAllocation.dataValueOnsiteStatus = allocation.OnsiteStatuses[0];
                    resourceAllocations.push(tempAllocation);
                } else if (allocation.OnsiteStatus === 2) {
                    tempAllocation.OnsiteStatus = allocation.OnsiteStatuses[1].Name;
                    tempAllocation.dataValueOnsiteStatus = allocation.OnsiteStatuses[1];
                    resourceAllocations.push(tempAllocation);
                } else {
                    resourceAllocations.push(allocation);
                }

            }
            cmSheet.ResourceAllocations = resourceAllocations;
        }

        // if (this.state.cmsReference === 'crCMS') {
        //     await this.calculateHeaderFields(this.state.changeRequest, cmSheet);
        // } else {
        //     await this.calculateHeaderFields(this.state.project, cmSheet);
        // }

        this.setState(
            {
                cmSheet: cmSheet,
                hasUnSavedData: true,
                disableExcelDownload: true
            },
            () => {
            }
        );
    };

    handleCurrencyChange = async (prevRate, newRate, currencyId) => {
        let cmSheet = JSON.parse(JSON.stringify(this.state.cmSheet));
        cmSheet = await this.calculateValuesOnCurrencyChange(cmSheet, prevRate, newRate);
        if (cmSheet) {
            this.setState({
                cmSheet: cmSheet,
                lastSelectedCurrencyId: currencyId
            });
        }
    };

    calculateNewRate = (value, prevRate, newRate) => {
        return value ? (value / prevRate) * newRate : 0;
    };

    calculateValuesOnCurrencyChange = async (cmSheet, prevRate, newRate) => {
        let result = null;

        // update resource allocations
        const resourceAllocations = this.state.cmSheet.ResourceAllocations.slice();

        let totalCostResourceAllocation = 0;
        let totalRevenueResourceAllocation = 0;
        let totalEffortDaysResourceAllocation = 0;
        let totalEffortDaysResourceAllocationNonBill = 0;
        let totalFTEResourceAllocation = 0;
        let totalCostToRevenueRatioResourceAllocation = 0;

        for (let allocation of resourceAllocations) {
            allocation.RateValue = this.calculateNewRate(allocation.RateValue, prevRate, newRate);
            allocation.TotalCost = this.calculateNewRate(allocation.TotalCost, prevRate, newRate);
            allocation.TotalRevenue = this.calculateNewRate(allocation.TotalRevenue, prevRate, newRate);
            allocation.CostToRevenueRatio = this.calculateNewRate(allocation.CostToRevenueRatio, prevRate, newRate);
            if (allocation.CostPerOutSourcedResource > 0) {
                allocation.CostPerOutSourcedResource =
                    (allocation.CostPerOutSourcedResource / prevRate) * newRate;
            }

            if(allocation.dataValueBillableStatus && allocation.dataValueBillableStatus.id === 1){

                totalFTEResourceAllocation = (totalFTEResourceAllocation + allocation.FTE);
                totalEffortDaysResourceAllocation = totalEffortDaysResourceAllocation + allocation.TotalCost;

            }

            else if(allocation.dataValueBillableStatus && allocation.dataValueBillableStatus.id === 2){
                totalEffortDaysResourceAllocationNonBill = totalEffortDaysResourceAllocationNonBill + allocation.TotalCost;
            }

            totalCostResourceAllocation = totalCostResourceAllocation + allocation.TotalCost;
            totalRevenueResourceAllocation = totalRevenueResourceAllocation + allocation.TotalRevenue;
            // totalEffortDaysResourceAllocation =
            //     totalEffortDaysResourceAllocation + allocation.TotalEffortDays;
            totalCostToRevenueRatioResourceAllocation = totalFTEResourceAllocation / totalRevenueResourceAllocation;
        }

        // update other expenses
        const otherExpenses = this.state.cmSheet.OtherExpenses.slice();

        let nonBillTotal = 0;
        let billTotal = 0;

        for (let expense of otherExpenses) {
            expense.BudgetedRate = this.calculateNewRate(expense.BudgetedRate, prevRate, newRate);
            expense.Cost = this.calculateNewRate(expense.Cost, prevRate, newRate);

            if (expense.BillableToClient === true && expense.Cost) {
                billTotal = billTotal + expense.Cost;
            }

            if (expense.BillableToClient === false && expense.Cost) {
                nonBillTotal = nonBillTotal + expense.Cost;
            }
        }

        // update cm sheet
        cmSheet.TotalCostResourceAllocation = totalCostResourceAllocation;
        cmSheet.TotalRevenueResourceAllocation = totalRevenueResourceAllocation;
        cmSheet.TotalEffortDaysResourceAllocation = totalEffortDaysResourceAllocation;
        cmSheet.TotalFTEResourceAllocation = totalFTEResourceAllocation;
        cmSheet.TotalEffortDaysResourceAllocationNonBill = totalEffortDaysResourceAllocationNonBill;
        cmSheet.TotalCostToRevenueRatioResourceAllocation = totalCostToRevenueRatioResourceAllocation;
        cmSheet.ResourceAllocations = resourceAllocations;

        cmSheet.BillableTotalOtherExpenses = billTotal;
        cmSheet.NonBillableTotalOtherExpenses = nonBillTotal;
        cmSheet.OtherExpensesTotal = billTotal + nonBillTotal;
        cmSheet.OtherExpenses = otherExpenses;

        // Other calculation values
        cmSheet.ProjectRevenueAfterDiscount = this.calculateNewRate(
            cmSheet.ProjectRevenueAfterDiscount,
            prevRate,
            newRate
        );
        cmSheet.NetProfitLoss = this.calculateNewRate(cmSheet.NetProfitLoss, prevRate, newRate);
        cmSheet.AverageRatePerDay = this.calculateNewRate(cmSheet.AverageRatePerDay, prevRate, newRate);
        console.log(prevRate);
        console.log(newRate);
        console.log(cmSheet.AverageRatePerDay);
        cmSheet.PartnerCommission = this.calculateNewRate(cmSheet.PartnerCommission, prevRate, newRate);
        cmSheet.ContingencyValue = this.calculateNewRate(cmSheet.ContingencyValue, prevRate, newRate);
        cmSheet.DiscountValue = this.calculateNewRate(cmSheet.DiscountValue, prevRate, newRate);
        cmSheet.ServiceRevenueValue = this.calculateNewRate(cmSheet.ServiceRevenueValue, prevRate, newRate);
        cmSheet.APSProfitability = this.calculateNewRate(cmSheet.APSProfitability, prevRate, newRate);
        cmSheet.RateDiscount = this.calculateNewRate(cmSheet.RateDiscount, prevRate, newRate);
        cmSheet.RevenueAfterDiscountQuotedToCustomer = this.calculateNewRate(
            cmSheet.RevenueAfterDiscountQuotedToCustomer,
            prevRate,
            newRate
        );
        cmSheet.EstimatedOPECustomer = this.calculateNewRate(
            cmSheet.EstimatedOPECustomer,
            prevRate,
            newRate
        );
        cmSheet.TotalProjectValue = this.calculateNewRate(cmSheet.TotalProjectValue, prevRate, newRate);
        cmSheet.NetDiscountValue = this.calculateNewRate(cmSheet.NetDiscountValue, prevRate, newRate);
        cmSheet.GrossProfitFromResourceDeployment = this.calculateNewRate(
            cmSheet.GrossProfitFromResourceDeployment,
            prevRate,
            newRate
        );
        cmSheet.salesCommision = this.calculateNewRate(cmSheet.salesCommision, prevRate, newRate);
        result = cmSheet;
        return result;
    };


    getOtherExpensesTotalValues = otherExpenses => {
        let nonBillTotal = 0;
        let billTotal = 0;

        for (const expense of otherExpenses) {
            if (expense.BillableToClient === true && expense.Cost) {
                billTotal = billTotal + expense.Cost;
            }

            if (expense.BillableToClient === false && expense.Cost) {
                nonBillTotal = nonBillTotal + expense.Cost;
            }
        }

        return { nonBillTotal: nonBillTotal, billTotal: billTotal };
    };

    handleOnChange = async (event) => {
        const value = event.target.value;
        const field = event.target.name;
        switch (field) {
            case 'TargetBillableUti': {
                this.setState({
                    targetBillable: value,
                });
                break;
            }
            case 'financialYear': {
                this.setState({
                    financialYear: value,
                });
                break;
            }
            default: {
            }
        }
        this.setState({
            isFormDirty: true
        });
    }

    validateApplicationSupport = () => {
            if (this.state.StartDate === null) {
                const message = 'Please enter a valid start date for Factory Project CMS';
                const title = 'Start Date';
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleMessageDialog(message, title);
                return false;
            }

            return true
    }

    validateApplicationSupporResourceMix = () => {
        let resourceValid = true;
            for (const allocation of this.state.cmSheet.ResourceAllocations) {
                if (allocation.StartDate && this.state.StartDate) {

                    let startDateObj = new Date(allocation.StartDate);
                    let startDiffMS = this.state.StartDate.getTime() - startDateObj.getTime();
                    let startDaysDiff = Math.floor(startDiffMS / (1000 * 60 * 60 * 24));

                    if (startDaysDiff > 0) {
                        const message = 'Allocation Start Date cannot be lesser than the Project/CR Start Date';
                        const title = 'Allocation Start Date';
                        this.setState({
                            showSuccessImage: false,
                            showErrorImage: true
                        });
                        this.toggleMessageDialog(message, title);
                        resourceValid = false;
                        break;
                    }
                }

                if (allocation.StartDate === null) {
                    const message = 'Invalid Allocation Start Date';
                    const title = 'Allocation Start Date';
                    this.setState({
                        showSuccessImage: false,
                        showErrorImage: true
                    });
                    this.toggleMessageDialog(message, title);
                    resourceValid = false;
                    break;
                }

                if (allocation.EndDate && this.state.endDate) {

                    const endDateObj = new Date(allocation.EndDate);
                    endDateObj.setHours(0, 0, 0, 0);

                    let msDiff = this.state.endDate.getTime() - endDateObj.getTime();
                    let daysDiff = Math.floor(msDiff / (1000 * 60 * 60 * 24));

                    if (daysDiff < 0) {
                        const message = 'Allocation End Date cannot be greater than the Project/CR End Date';
                        const title = 'Allocation End Date';
                        this.setState({
                            showSuccessImage: false,
                            showErrorImage: true
                        });
                        this.toggleMessageDialog(message, title);
                        resourceValid = false;
                        break;
                    }
                }

                if (allocation.EndDate === null) {
                    const message = 'Invalid Allocation End Date';
                    const title = 'Allocation End Date';
                    this.setState({
                        showSuccessImage: false,
                        showErrorImage: true
                    });
                    this.toggleMessageDialog(message, title);
                    resourceValid = false;
                    break;
                }
            }

            if (!resourceValid) {
                return false
            }
            return true;
    }

    onSubmitForApproval = async () => {

        if (this.validateApplicationSupport()) {
            if (this.validateApplicationSupporResourceMix()) {
                let projOrCR = null;
                this.state.cmSheet.PMComment = this.state.pmComment;
                if (this.state.cmsReference === 'crCMS') {
                    var lastSelectedCurrencyId = this.state.lastSelectedCurrencyId;
                    projOrCR = this.state.changeRequest;

                    // Check the currency - CR
                    if (lastSelectedCurrencyId !== projOrCR.Region.Currency.id) {
                        const message = 'Selected currency is not the project currency(' + projOrCR.Region.Currency.Code + ')';
                        const title = 'Currency';
                        this.toggleMessageDialog(message, title);
                        return;
                    }

                    if (projOrCR.EndDate === '') {
                        projOrCR.EndDate = null
                    }
                    await updateChangeReq(this.state.changeRequest, this.state.changeRequest.id)
                        .then(res => {
                        })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });

                }
                else {
                    projOrCR = this.state.project;
                    var lastSelectedCurrencyId = this.state.lastSelectedCurrencyId;

                    // Check the currency - project
                    if (lastSelectedCurrencyId !== projOrCR.Region.Currency.id) {
                        const message = 'Selected currency is not the project currency(' + projOrCR.Region.Currency.Code + ')';
                        const title = 'Currency';
                        this.toggleMessageDialog(message, title);
                        return;
                    }

                    if (projOrCR.EndDate === '') {
                        projOrCR.EndDate = null
                    }
                    await updateProject(this.state.project, this.state.project.id)
                        .then(res => {
                        })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });
                }

                if (this.state.cmSheet.ResourceAllocations && this.state.cmSheet.ResourceAllocations.length > 0) {
                    for (const allocation of this.state.cmSheet.ResourceAllocations) {
                        if (allocation.StartDate) {
                            allocation.StartDate = moment(allocation.StartDate).format('YYYY-MM-DD')
                        }

                        if (allocation.EndDate) {
                            allocation.EndDate = moment(allocation.EndDate).format('YYYY-MM-DD');
                        }
                    }

                }

                this.state.cmSheet.IsPoolProject = this.state.IsPoolProject;

                await saveCMSheet(this.state.cmSheet)
                    .then(async res => {
                        this.setState({
                            hasUnSavedData: false,
                            didRun: false,
                            disableExcelDownload: false
                        });
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                let today = new Date();
                today.setHours(0, 0, 0, 0);

                if (this.state.cmsReference === 'crCMS') {
                    if (this.state.changeRequest.StartDate && new Date(this.state.changeRequest.StartDate) < today) {
                        const message = 'The change request start date cannot be a past date!';
                        const title = 'Comment';
                        this.setState({
                            showSuccessImage: false,
                            showErrorImage: true
                        });
                        this.toggleMessageDialog(message, title);
                        return;
                    }
                } else {
                    if (this.state.project.StartDate && new Date(this.state.project.StartDate) < today) {
                        const message = 'The project start date cannot be a past date!';
                        const title = 'Comment';
                        this.setState({
                            showSuccessImage: false,
                            showErrorImage: true
                        });
                        this.toggleMessageDialog(message, title);
                        return;
                    }
                }

                let isExpenseValid = true;
                let isResourceAllocationsValid = true;

                // check no longer valid rates
                let ratesAreValid = true;
                if (
                    this.state.cmSheet.ResourceAllocations &&
                    this.state.cmSheet.ResourceAllocations.length > 0
                ) {
                    for (const allocation of this.state.cmSheet.ResourceAllocations) {

                        if (this.state.cmsReference === 'crCMS') {
                            if (
                                allocation.StartDate &&
                                this.state.changeRequest.StartDate &&
                                allocation.StartDate < this.state.changeRequest.StartDate
                            ) {
                                const message =
                                    'Date values are not valid in resource allocations!( Start dates are not valid )';
                                const title = 'Error';
                                this.setState({
                                    showSuccessImage: false,
                                    showErrorImage: true
                                });
                                this.toggleMessageDialog(message, title);
                                isResourceAllocationsValid = false;
                                break;
                            }
                        }
                        else {
                            if (
                                allocation.StartDate &&
                                this.state.project.StartDate &&
                                allocation.StartDate < this.state.project.StartDate
                            ) {
                                const message =
                                    'Date values are not valid in resource allocations!( Start dates are not valid )';
                                const title = 'Error';
                                this.setState({
                                    showSuccessImage: false,
                                    showErrorImage: true
                                });
                                this.toggleMessageDialog(message, title);
                                isResourceAllocationsValid = false;
                                break;
                            }
                        }

                        if (
                            !(

                                allocation.Designation > 0 &&
                                allocation.ResourceRegion > 0 &&
                                allocation.OnsiteStatus &&
                                allocation.AllocationPercentage > 0 &&
                                allocation.NoOfResources &&
                                allocation.StartDate &&
                                allocation.EndDate &&
                                (allocation.BillableStatus === true || allocation.BillableStatus === false)
                            )
                        ) {
                            let message = 'Please fill all mandatory values in resource mix';
                            const title = 'Error';
                            this.setState({
                                showSuccessImage: false,
                                showErrorImage: true
                            });
                            this.toggleMessageDialog(message, title);
                            isResourceAllocationsValid = false;
                            break;
                        }
                    }
                }

                if (!isResourceAllocationsValid) {
                    this.setState({
                        isSaving: false
                    });
                    return;
                }

                if (this.state.cmSheet.OtherExpenses && this.state.cmSheet.OtherExpenses.length > 0) {
                    for (const expense of this.state.cmSheet.OtherExpenses) {
                        if (expense.rateNoLongerValid === true) {
                            ratesAreValid = false;
                            break;
                        }

                        if (
                            !(
                                expense.ExpenseTypeId > 0 &&
                                expense.BudgetedOccurrences > 0 &&
                                expense.BudgetedRate > 0 &&
                                (expense.BillableToClient === true || expense.BillableToClient === false)
                            )
                        ) {
                            const message = 'Please fill all mandatory values in other expenses';
                            const title = 'Error';
                            this.setState({
                                showSuccessImage: false,
                                showErrorImage: true
                            });
                            this.toggleMessageDialog(message, title);
                            isExpenseValid = false;
                            break;
                        }
                    }
                }

                if (!isExpenseValid) {
                    return;
                }

                if (!ratesAreValid) {
                    const message =
                        'The existing rates are not valid in other expenses. Please fix them before submit for approval';
                    const title = 'Warning';
                    this.setState({
                        showSuccessImage: false,
                        showErrorImage: true
                    });
                    this.toggleMessageDialog(message, title);
                    return;
                }

                let nonbillableResourceCount = this.state.cmSheet.ResourceAllocations.filter(obj => obj.dataValueRateType && obj.dataValueRateType.Code === ADMIN_RATE);

                if (nonbillableResourceCount.length === this.state.cmSheet.ResourceAllocations.length) {
                    if (
                        this.state.cmSheet.TotalCostResourceAllocation > 0 &&
                        this.state.cmSheet.TotalEffortDaysResourceAllocation > 0
                    ) {
                        this.toggleConfirmDialog(
                            'Are you sure you want to submit for approval?',
                            this.submitForApproval
                        );
                    } else {
                        const message = 'This CM Sheet has no valid data for Resource mix to submit for approval.';
                        const title = 'Invalid CM Sheet';
                        this.setState({
                            showSuccessImage: false,
                            showErrorImage: true
                        });
                        this.toggleMessageDialog(message, title);
                    }
                }
                else {
                    // if (
                    //     this.state.cmSheet.TotalCostResourceAllocation > 0 &&
                    //     this.state.cmSheet.TotalRevenueResourceAllocation > 0 &&
                    //     this.state.cmSheet.TotalEffortDaysResourceAllocation > 0
                    // )
                    // {
                        await this.toggleConfirmDialog(
                            'Are you sure you want to submit this for approval?',
                            this.submitForApproval
                        );
                  //  }
                    // else {
                    //     const message = 'This CM Sheet has no valid data for Resource mix to submit for approval.';
                    //     const title = 'Invalid CM Sheet';
                    //     this.setState({
                    //         showSuccessImage: false,
                    //         showErrorImage: true
                    //     });
                    //     this.toggleMessageDialog(message, title);
                    // }
                }
            }
        }
    };

    submitForApproval = async () => {
        // let EstimatedStartDate;
        if (this.props.location.reference === 'crCMS') {
            this.state.EstimatedStartDate = this.state.changeRequest.StartDate ? this.state.changeRequest.StartDate : new Date();
        } else {
            //Project
            this.state.EstimatedStartDate = this.state.project.StartDate;
        }

        //selectWorkflow(this.state.cmSheet.id, { EstimatedStartDate: this.state.EstimatedStartDate, SelectedCurrency: this.state.lastSelectedCurrencyId }).then(res => {
        selectWorkflowPool(this.state.cmSheet.id).then(res => {

            if (res.data.length > 1) {
                this.setState(
                    {
                        matchingWorkflowList: res.data
                    },
                    () => {
                        this.toggleWorkflowSelectDialog();
                    }
                );
            }
        else if (res.data.length === 1) {
                this.setState(
                    {
                        selectedWorkFlowId: res.data[0].id
                    },
                    () => {
                        this.setWorkflowToCMSheet();
                    }
                );
            } else {
                const message =
                    'This CMS has failed to find a appropriate workflow for approvals. Please contact your system administrator to correct this issue';
                const title = 'Error';
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleMessageDialog(message, title);
            }
        });
        this.toggleConfirmDialog('', null);
    };

    toggleWorkflowSelectDialog = () => {
        this.setState({
            showWorkflowSelectDialog: !this.state.showWorkflowSelectDialog
        });
    };

    setWorkflowToCMSheet = () => {
        setWorkflow({ cmSheetId: this.state.cmSheet.id, workflowId: this.state.selectedWorkFlowId })
            .then(res => {
                this.toggleWorkflowSelectDialog();
                this.setState({ selectedWorkFlowId: null });
                this.navigateToDashboard();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleWorkflowSelectDialog();
                const message = error.response.data;
                const title = 'Error';
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleMessageDialog(message, title);
            });
    };

    setSelectedWorkflowId = workflowId => {
        this.setState({
            selectedWorkFlowId: workflowId
        });
    };

    onChangeApprovalComment = comment => {
        let Comment = comment.replace(/(<([^>]+)>)/ig, '');
        this.setState({
            approvalComment: comment
        });
    };

    onRejectCMSheet = () => {
        if (this.state.approvalComment && this.state.approvalComment.trim().length > 0) {
            this.setState(
                {
                    rejectParams: {
                        cmSheetId: this.state.cmSheet.id,
                        comment: this.state.approvalComment
                    }
                },
                () => {
                    this.toggleConfirmDialog('Are you sure you want to reject this?', this.rejectCMSheet);
                }
            );
        } else {
            const message = 'Comment cannot be empty';
            const title = 'Error';
            this.setState({
                showSuccessImage: false,
                showErrorImage: true
            });
            this.toggleMessageDialog(message, title);
        }
    };

    rejectCMSheet = () => {
        rejectCMSheet(this.state.rejectParams)
            .then(res => {
                this.toggleConfirmDialog('', null);
                this.setState({ rejectParams: null });
                this.navigateToDashboard();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleConfirmDialog('', null);
                const message = error.response.data;
                const title = 'Error';
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleMessageDialog(message, title);
            });
    };

    approveCMSheet = () => {
        approveCMSheet(this.state.approveParams)
            .then(res => {
                this.toggleConfirmDialog('', null);
                this.setState({ approveParams: null });
                this.navigateToDashboard();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleConfirmDialog('', null);
                const message = error.response.data;
                const title = 'Error';
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleMessageDialog(message, title);
            });
    };

    onApproveCMSheet = () => {
        if (this.state.approvalComment && this.state.approvalComment.trim().length > 0) {
            this.setState(
                {
                    approveParams: {
                        cmSheetId: this.state.cmSheet.id,
                        comment: this.state.approvalComment,
                        selectedCurrency: this.state.lastSelectedCurrencyId,
                        estimateStartDate: this.props.location.reference === 'crCMS' ? this.state.changeRequest.StartDate : this.state.project.StartDate
                    }
                },
                () => {
                    this.toggleConfirmDialog('Are you sure you want to approve this?', this.approveCMSheet);
                }
            );
        } else {
            const message = 'Comment cannot be empty';
            const title = 'Error';
            this.setState({
                showSuccessImage: false,
                showErrorImage: true
            });
            this.toggleMessageDialog(message, title);
        }
    };

    handleReviseCMSheet = () => {
        this.toggleConfirmDialog('Are you sure, you want to revise cm sheet?', this.reviseCMSheet);
    };

    reviseCMSheet = () => {
        reviseCMSheet(this.state.cmSheet.id)
            .then(res => {
                // if (res.data) {
                //   const updatedCMSheet = res.data;
                //   const filterResourceAllocations = updatedCMSheet.resourceAllocations.filter(
                //     obj => obj.StartDateType === CMS_CREATED_DATE || obj.StartDateType === CMS_REVISED_DATE
                //   );
                //   if()
                // }

                this.setState(
                    {
                        action: ACTION_EDIT,
                        isViewMode: false,
                        locationCMSheet: res.data,
                        selectedCmsType: {}
                    },
                    () => {
                        this.toggleConfirmDialog('', null);

                        this.handleProjectChange(this.state.project ? this.state.project.id : null, this.state.changeRequest ? this.state.changeRequest.id : null);
                    }
                );

                // this.toggleConfirmDialog('', null);
                // this.navigateToDashboard();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleConfirmDialog('', null);
                const message = error.response.data;
                const title = 'Error';
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleMessageDialog(message, title);
            });
    };

    handleVersionChange = event => {
        const value = event.target.value;
        const version = value.Version;
        this.setState(
            {
                selectedVersion: value,
                isViewMode:
                    version !== this.state.LastVersion ||
                    (this.state.action !== ACTION_CREATE && this.state.action !== ACTION_EDIT)
            },
            () => {
                getCMSheet(this.state.cmSheet.id, version)
                    .then(res => {
                        this.processCMSheetData(res.data);
                        this.setState({
                            excelDataObsolete: true
                        });
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });
            }
        );
    };

    render() {

        if (this.state.navigateToDashboard === true) {
            return <Redirect to="/" />;
        }

        if (this.state.navigateToSearchScreen === true) {
            return <Redirect to="/projects/search/search"/>;
        }

        // const [firstSeries] = [[100, 123, 234, 343]];
        // const categories = ['Q1', 'Q2', 'Q3', 'Q4'];
        // const ChartContainer = () => (
        //     <Chart>
        //         <ChartTitle text="Effort"/>
        //         <ChartCategoryAxis>
        //             <ChartCategoryAxisItem categories={categories}>
        //                 <ChartCategoryAxisTitle text="Resource"/>
        //             </ChartCategoryAxisItem>
        //         </ChartCategoryAxis>
        //         <ChartSeries>
        //             <ChartSeriesItem type="bar" gap={2} spacing={0.25} data={firstSeries}/>
        //
        //         </ChartSeries>
        //     </Chart>
        // );
        return (
            <div className="main-card">

                <Loader loading={this.state.isLoading}/>

                {/*<div className="row">*/}
                {/*    {this.props.location.reference === 'crCMS' && (*/}
                {/*        <div className="col-md-2">*/}
                {/*            <div className="">*/}
                {/*                <div className="d-block">*/}
                {/*                    <label>CR Name:</label>*/}
                {/*                </div>*/}
                {/*                <div className="d-block" id="projectNameToolTip">*/}
                {/*                    <Input disabled value={this.state.changeRequest.CRName} />*/}
                {/*                </div>*/}
                {/*                {this.state.project.ProjectName ? (*/}
                {/*                    <Tooltip*/}
                {/*                        placement="top"*/}
                {/*                        isOpen={this.state.projectNameToolTipOpen}*/}
                {/*                        target="projectNameToolTip"*/}
                {/*                        toggle={this.toggleProjectToolTip}*/}
                {/*                    >*/}
                {/*                        {this.state.project.ProjectName}*/}
                {/*                    </Tooltip>*/}
                {/*                ) : null}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}

                {/*    {this.props.location.reference === 'crCMS' && (*/}
                {/*        <div className="col-md-2">*/}
                {/*            <div className="">*/}
                {/*                <div className="d-block">*/}
                {/*                    <label>CR ID:</label>*/}
                {/*                </div>*/}
                {/*                <div className="d-block" id="projectNameToolTip">*/}
                {/*                    <Input disabled value={this.state.changeRequest.UniqueId} />*/}
                {/*                </div>*/}
                {/*                {this.state.project.ProjectName ? (*/}
                {/*                    <Tooltip*/}
                {/*                        placement="top"*/}
                {/*                        isOpen={this.state.projectNameToolTipOpen}*/}
                {/*                        target="projectNameToolTip"*/}
                {/*                        toggle={this.toggleProjectToolTip}*/}
                {/*                    >*/}
                {/*                        {this.state.project.ProjectName}*/}
                {/*                    </Tooltip>*/}
                {/*                ) : null}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}

                {/*    {this.props.location.reference === 'crCMS' && (*/}
                {/*        <div className="col-md-2">*/}
                {/*            <div className="">*/}
                {/*                <div className="d-block">*/}
                {/*                    <label>CR OP ID:</label>*/}
                {/*                </div>*/}
                {/*                <div className="d-block" id="opIdToolTip">*/}
                {/*                    <Input disabled value={this.state.changeRequest.OPID} />*/}
                {/*                </div>*/}
                {/*                {this.state.project.OPID ? (*/}
                {/*                    <Tooltip*/}
                {/*                        placement="top"*/}
                {/*                        isOpen={this.state.opIdToolTipOpen}*/}
                {/*                        target="opIdToolTip"*/}
                {/*                        toggle={this.toggleopIdToolTip}*/}
                {/*                    >*/}
                {/*                        {this.state.project.OPID}*/}
                {/*                    </Tooltip>*/}
                {/*                ) : null}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}

                {/*    {this.props.location.reference === 'crCMS' && (*/}
                {/*        <>*/}
                {/*            <div className="col-md-2">*/}
                {/*                <div className="">*/}
                {/*                    <div className="d-block">*/}
                {/*                        <label>CR CMS Version:</label>*/}
                {/*                    </div>*/}
                {/*                    <div className="d-block" id="cmsVersionToolTip">*/}
                {/*                        <DropDownList*/}
                {/*                            data={this.state.cmSheetVersions}*/}
                {/*                            textField="Text"*/}
                {/*                            dataItemKey="Version"*/}
                {/*                            value={this.state.selectedVersion}*/}
                {/*                            onChange={this.handleVersionChange}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}

                {/*            <div className="col-md-2">*/}
                {/*                <div className="">*/}
                {/*                    <div className="d-block">*/}
                {/*                        <label>CR CMS Status:</label>*/}
                {/*                    </div>*/}
                {/*                    <div className="d-block" id="cmsStatusToolTip">*/}
                {/*                        <Input value={this.cmsStatuses[this.state.cmSheet.Status]} disabled />*/}
                {/*                    </div>*/}
                {/*                    {this.cmsStatuses[this.state.cmSheet.Status] ? (*/}
                {/*                        <Tooltip*/}
                {/*                            placement="top"*/}
                {/*                            isOpen={this.state.cmsStatusToolTipOpen}*/}
                {/*                            target="cmsStatusToolTip"*/}
                {/*                            toggle={this.toggleCMSStatusToolTip}*/}
                {/*                        >*/}
                {/*                            {this.cmsStatuses[this.state.cmSheet.Status]}*/}
                {/*                        </Tooltip>*/}
                {/*                    ) : null}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </>*/}
                {/*    )}*/}
                {/*</div>*/}

                {/*{this.props.location.reference === 'crCMS' && (*/}
                {/*    <div className="col-md-12">*/}

                {/*    </div>*/}
                {/*)}*/}

                <div className="row">
                    <div className="col-md-3">
                        <div className="d-block">
                            <label>Project Name:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                                <Input
                                    disabled value={this.state.CRName ? this.state.CRName :this.state.projectName}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="d-block">
                            <label>Start Date:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                                <DatePicker
                                    value={this.state.StartDate}
                                    width="99%"
                                    onChange={this.handleStartdateChange}
                                    name="StartDate"
                                    min={new Date()}
                                    format="MM/dd/yyyy"
                                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                    disabled={this.state.isViewMode}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="d-block">
                            <label className="">Financial Year:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                                <MultiIncrementYearPicker
                                    disabled value={this.state.financialYear}
                                    disabled={this.state.isViewMode}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="d-block">
                            <label >Primary Billing Region:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                                <DropDownList
                                    value={this.state.selectedRegion}
                                    textField="Name"
                                    dataItemKey="id"
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                <div className="col-md-3">
                        <div className="d-block">
                            <label>Additional Billing Region:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                                <MultiSelect
                                    value={this.state.selectedAdditionalRegion}
                                    textField="Name"
                                    dataItemKey="id"
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-3">
                        <div className="d-block">
                            <label>Practice/Department:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                                <MultiSelect
                                    value={this.state.selectedPractices}
                                    textField="Name"
                                    dataItemKey="id"
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="d-block">
                            <label>Offering:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                                <MultiSelect
                                    value={this.state.selectedOfferings}
                                    textField="Name"
                                    dataItemKey="id"
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="col-md-3">
                        <div className="d-block">
                            <label>Project Owner:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                                <ComboBox
                                    textField="displayName"
                                    dataItemKey="id"
                                    value={this.state.resourceValue}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                <div className="col-md-3">
                        <div className="d-block">
                            <label className="mandatory">Target Billable Utilization:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                                <NumericTextBox
                                    autoComplete="off"
                                    type="Numeric"
                                    name="TargetBillableUti"
                                    required={true}
                                    min={0}
                                    max={100}
                                    width={'100%'}
                                    onChange={this.handleOnChange}
                                    textField="name"
                                    dataItemKey="value"
                                    disabled={this.state.isViewMode}
                                    value={this.state.targetBillable}
                                />
                            </div>
                        </div>
                    </div>

                    {this.state.cmsReference !== 'crCMS' && (
                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block">
                                        <label>CMS Version:</label>
                                    </div>
                                    <div className="d-block" id="cmsVersionToolTip">
                                        <DropDownList
                                            data={this.state.cmSheetVersions}
                                            textField="Text"
                                            dataItemKey="Version"
                                            value={this.state.selectedVersion}
                                            onChange={this.handleVersionChange}
                                        />
                                    </div>
                                </div>
                            </div>
                    )}

                    {this.props.location.reference === 'crCMS' && (
                        <>
                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block">
                                        <label>CR CMS Version:</label>
                                    </div>
                                    <div className="d-block" id="cmsVersionToolTip">
                                        <DropDownList
                                            data={this.state.cmSheetVersions}
                                            textField="Text"
                                            dataItemKey="Version"
                                            value={this.state.selectedVersion}
                                            onChange={this.handleVersionChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {/*<div className="col-md-3">*/}
                    {/*    <div className="d-block">*/}
                    {/*        <label>CMS Version:</label>*/}
                    {/*    </div>*/}
                    {/*    <div className="d-block">*/}
                    {/*        <div className="">*/}
                    {/*            <DropDownList*/}
                    {/*                disabled={this.state.isViewMode}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>

                {this.state.action !== ACTION_APPROVE && this.state.action !== ACTION_REVISE && (
                <div className="row">
                    <div className="col-md-12 btn-align-right">
                        <Button primary={true}
                                disabled={this.state.isViewMode}
                                onClick={this.onSubmitForApproval}
                        >
                            Submit for Approval
                        </Button>
                        <Button primary={true}
                                onClick={this.handleSaveButtonClick}
                                disabled={this.state.isViewMode || this.state.isSaving}
                        >
                            Save
                        </Button>
                        <Button primary={true}>
                            Export to Excel
                        </Button>
                        <Button primary={true}>
                            Copy to
                        </Button>
                        <Button type="button"
                                onClick={this.onCancelButtonClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                )}

                {this.state.action === ACTION_APPROVE && (
                    <div className="row">
                        <div className="col-md-12 btn-align-right">

                                <Button primary onClick={this.onRejectCMSheet}>
                                    Reject
                                </Button>

                                <Button primary onClick={this.onApproveCMSheet}>
                                    Approve
                                </Button>
                        </div>
                    </div>
                )}

                {this.state.action === ACTION_REVISE && (
                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                            <Button primary={true} onClick={this.handleReviseCMSheet}>
                                Revise
                            </Button>
                            <Button onClick={this.onCancelRevise}>Cancel</Button>
                        </div>
                    </div>
                )}

                <div className="main-seperator"></div>

                <TabStrip selected={this.state.selected} onSelect={this.handleSelect}>

                    <TabStripTab title="Summary">
                        <SummaryPoolProject
                            project={this.state.project}
                            changeRequest={this.state.changeRequest}
                            cmSheet={this.state.cmSheet}
                            onCurrencyChange={this.handleCurrencyChange}
                            lastSelectedCurrencyId={this.state.lastSelectedCurrencyId}
                            customerProjects={this.state.customerProjects}
                        />
                    </TabStripTab>
                    <TabStripTab title="Resource Mix">

                        <ResourceAllocPool
                            isViewMode={this.state.isViewMode}
                            project={this.state.project}
                            changeRequest={this.state.changeRequest}
                            cmSheet={this.state.cmSheet}
                            startDate={this.state.StartDate}
                            //endDate={this.state.endDate}
                            cmSheetReference={this.state.cmsReference}
                            onUpdateCMSheet={this.handleUpdateCMSheetModel}
                            // infoTabStartDateChanged={this.state.infoTabStartDateChanged}
                            // onStartDateChange={callbackData => this.onStartDateChangeInfoTab(callbackData)}
                        />

                    </TabStripTab>
                    <TabStripTab title="Other Expenses">
                        <OtherExpensesPool
                            isViewMode={this.state.isViewMode}
                            project={this.state.project}
                            cmSheet={this.state.cmSheet}
                            onUpdateCMSheet={this.handleUpdateCMSheetModel}
                            cmSheetReference={this.state.cmsReference}
                            changeRequest={this.state.changeRequest}
                        />
                    </TabStripTab>

                    <TabStripTab title="File Upload">

                        {this.cmsStatuses[this.state.cmSheet.Status] && (
                            <FileUpload
                                referenceType="CMsheet"
                                referenceId={this.state.cmSheet.id}
                                cmSheet={this.state.cmSheet}
                                cmSheetAction={this.state.action}
                                viewingMode={this.state.isViewMode}
                                IsPoolProject = {true}
                                //isRolePM={this.state.isRolePM}
                            />
                        )}
                    </TabStripTab>
                </TabStrip>

                <div className="row mt-3">
                    <div className="col-md-5">
                        <label htmlFor="">Project Owner's Comment:</label>
                    </div>
                    <div className="col-md-12">
                        <ReactQuill
                            name="PMComment"
                            onChange={this.handleCommentChange}
                            readOnly={
                                this.state.isViewMode ||
                                this.state.action === ACTION_APPROVE ||
                                this.state.action === ACTION_REVISE
                            }
                            value={this.state.pmComment ? this.state.pmComment : this.state.PMName}
                            maxLength="1000"

                        />
                    </div>

                    {this.state.action === ACTION_APPROVE && (
                        <div className="w-100">
                        <div className="col-md-12 mt-3">
                            <label htmlFor="">Comment:</label>
                        </div>
                        <div className="col-md-12">
                            <ReactQuill
                                name="approvalcomment"
                                onChange={this.onChangeApprovalComment}
                                value={this.state.approvalComment}
                                maxLength="1000"
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <Grid width="100%" data={this.state.approversList} resizable>
                                <Column field="GroupName" title="Group" width="200px" />
                                <Column field="Approver" title="Approver" width="200px" />
                                <Column field="Status" title="Status" width="150px" />
                                <Column title="Comment"
                                        cell={props => <ApprovalCommentCell {...props} />}
                                        width="450px" />
                                <Column field="createdAt" title="Approved/Rejected Date and Time"
                                        cell={props => <DateCell {...props} />} width="150px" />
                            </Grid>
                        </div>
                    </div>
                    )}

                    {this.state.action === ACTION_VIEW && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row"></div>
                                <br />
                                <div className="row">
                                    <div className="col-md-12">
                                        <Grid width="100%" data={this.state.approversList} resizable>
                                            <Column field="GroupName" title="Group" width="200px" />
                                            <Column field="Approver" title="Approver" width="200px" />
                                            <Column field="Status" title="Status" width="150px" />
                                            <Column title="Comment"
                                                    cell={props => <ApprovalCommentCell {...props} />}
                                                    width="450px" />
                                            <Column field="createdAt" title="Approved/Rejected Date and Time"
                                                    cell={props => <DateCell {...props} />} width="150px" />
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                    {this.state.showMessageDialog === true && (
                        <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                            <DialogActionsBar>
                                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                                    OK
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}

                    {this.state.showConfirmDialog === true && (
                        <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                            <DialogActionsBar>
                                <button className="k-button" onClick={this.toggleConfirmDialog}>
                                    No
                                </button>
                                <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}

                    {this.state.showWorkflowSelectDialog === true && (
                        <Dialog title="Select Workflow" onClose={this.toggleWorkflowSelectDialog} width="300px">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <p className="subtitle-workflow" style={{ textAlign: 'center' }}>Please select a workflow.</p>
                                    </div>
                                    <div className="row">
                                        <form className="k-form modified">
                                            <div className="k-form-field">
                                                {this.state.matchingWorkflowList.map((option, i) => {
                                                    return (
                                                        <div className="field-mod">
                                                            <input
                                                                type="radio"
                                                                id={'' + option.id}
                                                                name={'' + option.id}
                                                                value={option.id}
                                                                checked={this.state.selectedWorkFlowId === option.id}
                                                                className="k-radio"
                                                                key={option.id}
                                                                onChange={this.setSelectedWorkflowId.bind(this, option.id)}
                                                            />

                                                            <label
                                                                key={option.id}
                                                                htmlFor={'' + option.id}
                                                                className="k-radio-label"
                                                            >
                                                                {option.Text}
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <DialogActionsBar>
                                <button className="k-button" onClick={this.toggleWorkflowSelectDialog}>
                                    No
                                </button>
                                <button
                                    className="k-button modal-primary"
                                    onClick={this.setWorkflowToCMSheet}
                                    disabled={!this.state.selectedWorkFlowId}
                                >
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

                {this.state.showMessageDialog === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

                        {this.state.showSuccessImage === true && (
                            <div className="text-center">
                                <img className="successImg" src={SuccessImg} alt="Success" />
                            </div>
                        )}

                        {this.state.showErrorImage === true && (
                            <div className="text-center">
                                <img className="successImg" src={ErrorImg} alt="Error Image" />
                            </div>
                        )}
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                        <DialogActionsBar>
                            {this.state.showSuccessImage === true && (
                                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                                    OK
                                </button>
                            )}

                            {this.state.showErrorImage === true && (
                                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                                    OK
                                </button>
                            )}
                        </DialogActionsBar>
                    </Dialog>
                )}
                {this.state.showWorkflowSelectDialog === true && (
                    <Dialog title="Select Workflow" onClose={this.toggleWorkflowSelectDialog} width="300px">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <p className="subtitle-workflow" style={{ textAlign: 'center' }}>Please select a workflow.</p>
                                </div>
                                <div className="row">
                                    <form className="k-form modified">
                                        <div className="k-form-field">
                                            {this.state.matchingWorkflowList.map((option, i) => {
                                                return (
                                                    <div className="field-mod">
                                                        <input
                                                            type="radio"
                                                            id={'' + option.id}
                                                            name={'' + option.id}
                                                            value={option.id}
                                                            checked={this.state.selectedWorkFlowId === option.id}
                                                            className="k-radio"
                                                            key={option.id}
                                                            onChange={this.setSelectedWorkflowId.bind(this, option.id)}
                                                        />
                                                        <label
                                                            key={option.id}
                                                            htmlFor={'' + option.id}
                                                            className="k-radio-label"
                                                        >
                                                            {option.Text}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleWorkflowSelectDialog}>
                                No
                            </button>
                            <button
                                className="k-button modal-primary"
                                onClick={this.setWorkflowToCMSheet}
                                disabled={!this.state.selectedWorkFlowId}
                            >
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </div>
        );
    }
}


export default FactoryProjectCMS;
