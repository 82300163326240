import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import {
  TabStrip,
  TabStripTab,
  PanelBar,
  PanelBarItem,
  PanelBarUtils
} from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Link } from 'react-router-dom';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { Popup } from '@progress/kendo-react-popup';
import { Tooltip, UncontrolledTooltip } from 'reactstrap';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Redirect } from 'react-router';
import {
  DateInput,
  Calendar,
  DatePicker,
  TimePicker,
  MultiViewCalendar,
  DateRangePicker
} from '@progress/kendo-react-dateinputs';
import {
  getCRsOfAProject,
  deleteChangeReq,
  getAllCRUniqueIds
} from '../../projectManagement/changeRequest/ChangeRequestService';
import {
  getAllProjectsforTaskManagement,
  getCalculatedProjectStatistics
} from '../../projectManagement/project/ProjectService';
import { getAllTaskTypes } from './../.././masterData/taskType/TaskTypeService';
import { getAllDesignations } from './../.././masterData/designation/DesignationService';
import 'moment-timezone';
import moment from 'moment/moment';
import * as loggerService from '../../integration/LoggerService';
import {
  getAllProjectTasks,
  saveProjectTask,
  getAllProjectTaskAllocations,
  getProjectTaskAllocations,
  getTaskAllocationsbyTaskIdPerformanceFix,
  deleteTask,
  updateTask,
  getAllPresalesResourcesByProjId,
  getResourceRequestSublines,
  getResource,
  saveResource,
  getAllResourcesAndUsersById,
  loadProjectandTaskData,
  getProjectResourceUtilizationBreakdown,
  getTimeEntryDataforTaskManagment,
  getTimeEntryDataforTaskManagmentPerformanceFix,
  updateResource,
  deleteResource,
  getCurrentUtilizesOfResources,
  getExistingTaskResources
} from './ProjectTaskManagementService';
import { getCmsTypes } from '../../projectManagement/cmSheet/CMSheetService';
import TagList from '@progress/kendo-react-dropdowns/dist/npm/MultiSelect/TagList';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import TaskStatusCell from '../nonProjectTaskSearch/components/TaskStatusCell';
import ProjectTaskGrid from './components/ProjectTaskGrid';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem
} from '@progress/kendo-react-charts';
import CMSheetFunctions from '../../ui/dashboard/CMSheetFunctions';
import { getHolidaysIntegrated } from '../../integration/MasterDataService';
import { getFormattedDate, isWeekend, reStructureDate } from '../../integration/CommonUtils';
import Loader from '../../integration/components/Loader';
import { saveAuditTrail } from '../../integration/auditTrail/AuditTrailService';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import { CustomEndDatePickerPopUp } from "./components/CustomEndDatePickerPopUp";
import { CustomStartDatePickerPopUp } from "./components/CustomStartDatePickerPopUp";

class ProjectTaskManagement extends Component {
  isMount = false;
  project = null;
  serverData = [];
  maxMinDatesTask = [];
  deletedTasks = [];
  panelBars = [];
  panelBarTaskIds = [];
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      showMessageDialog: false,
      dialogMessage: '',
      failedResources: [],
      dialogTitle: '',
      selected: 0,
      projectName: null,
      gridDetails: [],
      addEditTaskDialog: false,
      duplicateTask: false,
      addEditRescTask: false,
      projectCMS: [],
      crCMS: [],
      taskResources: [],
      taskResourcesDropDown: [],
      CRsOfAProject: [],
      selectedCRId: null,
      selectedResource: [],
      selectedCMS: null,
      selectedCMSId: null,
      allProjects: [],
      selectedCMSheet: null,
      selectedTask: null,
      selectedProjectOrCr: null,
      taskStartdate: null,
      taskEnddate: null,
      resourceFullName: null,
      resourceEmailId: null,
      ProjectOrCrName: null,
      shiftstartTime: null,
      shiftendTime: null,
      projAllocStartDate: null,
      projAllocEndDate: null,
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      skip: 0,
      take: 10,
      taskName: '',
      startDateMin: new Date(),
      startDateMax: new Date(),
      EndDateMax: new Date(),
      startDateMinAddResource: new Date(),
      EndDateMaxAddResource: new Date(),
      TotalCount: 0,
      startDateMaxForProjAlloc: new Date(),
      selectedCMSReference: null,
      changeRequestCMS: [],
      dropdowntasktype: [],
      isShiftToDisabled: true,
      allProjectTaskAllocs: [],
      dropdownprojectcr: [],
      redirectToEditViewCMS: false,
      redirectToEditCR: false,
      ChangeRequestAction: '',
      viewCRDialog: false,
      selectedOption: '',
      projectIdOfSelectedCMS: null,
      viewCMSDialog: false,
      cmsAction: null,
      projectTasks: [],
      allowdata: true,
      disableTaskType: true,
      billableStatus: '',
      cancelAddEditTaskVisible: false,
      cancelEditTaskVisible: false,
      cancelAddResourceVisible: false,
      allTaskTypes: [],
      crCMsheets: [],
      isFormDirty: false,
      resourcegrid: [],
      resourcegridForChildView: [],
      currentChildTask: null,
      resourceBillability: ' ',
      incrementalBilling: false,
      createdTasksForProject: [],
      currentlySelectedTask: null,
      allDesignations: [],
      currentlySelectedTaskBillability: null,
      currentlSelectedTaskStartDate: null,
      currentlySelectedTaskEndDate: null,
      isErrorMessageVisible: false,
      isDisableBillableBtn: false,
      isDisableAddBtnAddResc: false,
      isDisableClearBtnAddResc: false,
      isDisableAddRescDropdown: false,
      isDisableAddRescStartDate: false,
      isDisableAddRescIncrementBill: false,
      isDisableAddRescEndDate: false,
      isDisableAddRescShiftStartTime: false,
      isDisableEditTaskBtn: false,
      isDisableDeleteBtn: false,
      isDisableDuplicateTaskBtn: false,
      editrescgrid: [<td />],
      billableTooltipOpen: false,
      nonBillableTooltipOpen: false,
      incrementTooltip: false,
      shiftTooltip: false,
      addEditResourceTaskName: null,
      isPresalesTask: false,
      projecttaskmanage: [<td />],
      efforthours: [],

      valueptm: { text: 'Project002', id: 2 },
      valuett: { text: 'Configuration', id: 1 },

      dataset: [],
      visible: false,
      resourceName: '',
      resourceDesignation: '',
      resourceUtilizationBreakdown: [],
      isProjectManager: false,
      loading: false,
      expandedResources: [],
      popUpBox: { isPopUp: false, popUpMessage: '', popUpTitile: '', callBack: null },
      isTimeEntryAvailable: null,
      isTimeEntryAvailableResource: null,
      taskId: null,
      EndDateMin: new Date(),
      projectId: null,
      crIds: [],
      selectedResourceId: null,
      isProjectManagerCR: false,
      projectActivated: false,
      crActivated: false,
      resourceExist: false,
      isReadOnly: false,
      expandedPanels: [],
      isduplicateTask: false,
      projectStartDate: null,
      projectEndDate: null,
      latestTimeEntryDate: null,
      resourceMaxStartDate: null,
      showSuccessImage: false,
      showErrorImage: false,
      duplicateTaskResources: [],
      allDuplicateTaskResources: [],
      selectedTaskForDuplicate: null,
      multiTaskResourcesDialog: false,
      projOrCrTasks: [],
      projOrCrTasksAll: [],
      projOrCrResources: [],
      projOrCrResourcesAll: [],
      selectedProjOrCrTasks: [],
      selectedProjOrCrResources: [],
      multiTaskStartDate: null,
      multiTaskEndDate: null,
      multiTaskDateMax: null,
      multiTaskDateMin: null,
      isErrorMessageVisibleForDates: false,
      isDisableMultiTaskSaveBtn: false,
      cancelMultiTaskResourceVisible: false,
      multiTaskAllocations: [],
      invalidMultiTasks: [],
      invalidMultiTaskResources: [],
      confirmMultiTaskAllocationVisible: false,
      totalAllocationCount: 0
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    if(typeof this.props.location.project !== "undefined"){
      localStorage.setItem( 'currentProjInTaskManagment',JSON.stringify( this.props.location.project) );
      this.project = this.props.location.project;
    }else{
      this.project =JSON.parse( localStorage.getItem( 'currentProjInTaskManagment' ))
    }
    if (this.project) {
      this.setState({
        loading: true,
        projectActivated: this.project.Status !== 1 ? true : false
      });

      this.setHeaderTitle();
      this.populateProjectFields();
      this.retrieveAllTaskTypes();
      this.populateProjectCMS();
      await this.loadProjectandTask(this.project.id);
      this.setProjectDropdownData();
      this.setGlobalAccessLevels();
      this.populateAllProjects();
      this.populateAllDesignations();
      this.enableDisableBillability();
      this.populateProjectStatistics(this.project);
      this.setStartDateMin(null);
      this.setEndDateMax(null);
      this.generateMaxMinDatesforTasks();
      this.setProjectCrIds(this.project);
    }
  }

  setProjectCrIds = project => {
    let crId = [];
    if (project.ChangeRequests && project.ChangeRequests.length > 0) {
      project.ChangeRequests.forEach(el => {
        crId.push(el.id);
      });
    }
    this.setState({
      projectId: project.id,
      crIds: crId
    });
  };

  loadProjectandTask = async projectId => {
    await loadProjectandTaskData(projectId)
      .then(async res => {
        if (res && res.data) {
          let arr = [];
          let stateObj = { CRsOfAProject: [], selectedCRId: null, projectTasks: [], data: [] };
          if (res.data.CRData && res.data.CRData.length > 0) {
            stateObj.CRsOfAProject = res.data.CRData;
            stateObj.selectedCRId = res.data.CRData.length == 1 ? res.data.CRData[0].id : null;
          }

          if (res.data.projectTasks && res.data.projectTasks.length > 0) {
            stateObj.projectTasks = res.data.projectTasks;
            let projectStartDate = this.project.StartDate
              ? moment(this.project.StartDate).format('MM/DD/YYYY')
              : this.project.EstimatedStartDate
              ? moment(this.project.EstimatedStartDate).format('MM/DD/YYYY')
              : '';
            let projectEndDate = this.project.UserDefinedEndDate
              ? moment(this.project.UserDefinedEndDate).format('MM/DD/YYYY')
              : this.project.EndDate
              ? moment(this.project.EndDate).format('MM/DD/YYYY')
              : this.project.EstimatedEndDate
              ? moment(this.project.EstimatedEndDate).format('MM/DD/YYYY')
              : '';

            const projectDropDown = {
              id: this.project.id,
              title: this.project.ProjectName + ' - ' + projectStartDate + ' to ' + projectEndDate,
              children: []
            };

            const preSaleDropDown = {
              title: 'Presales Tasks',
              children: []
            };
            projectDropDown.children.push(preSaleDropDown);

            //presale tasks
            for (let task of stateObj.projectTasks.sort((a, b) => {
              return a.CRId === b.CRId ? 0 : a.CRId === null ? -1 : 1;
            })) {
              let billabilityId = null;
              if (task.BillabilityTypeId === 'BILLABLE') {
                billabilityId = 'B';
              }
              if (task.BillabilityTypeId === 'NONBILLABLE') {
                billabilityId = 'NB';
              }
              task['isProjectManager'] = null;
              let taskName = 'Presales task for ' + this.project.ProjectName;
              let crData = null;
              if (task.ProjectType == 'Project') {
                let isProjManager = await this.verifyProjectManager(this.project);
                task.isProjectManager = isProjManager;
              } else {
                crData = stateObj.CRsOfAProject.find(i => i.id === task.CRId);
                if (crData) {
                  taskName = 'Presales task for ' + crData.CRName;
                  let isCRManager = await this.verifyCRManager(crData);
                  task.isProjectManager = isCRManager;
                }
              }

              if (
                task.IsPresales &&
                (task.isProjectManager || window.USER_PERMISSION.includes('VW_PRJTASKMNGMT'))
              ) {
                let formatedstartDate = task.StartDate
                  ? moment(task.StartDate).format('MM/DD/YYYY')
                  : ' ';
                let formatedendDate = null;
                if (!crData) {
                  formatedendDate = task.EndDate
                    ? moment(task.EndDate).format('MM/DD/YYYY')
                    : ' ';
                } else {
                  formatedendDate = task.EndDate
                    ? moment(task.EndDate).format('MM/DD/YYYY')
                    : ' ';
                }
                const presaledetail = {
                  id: task.id,
                  title: (
                    <span>
                      {taskName} - {formatedstartDate} to {formatedendDate} -{' '}
                      <span className="billability-badge">{billabilityId}</span>{' '}
                    </span>
                  ),
                  isCollapsed: true,
                  isProjectManager: task.isProjectManager,
                  content: []
                };
                task['DisplayTaskName'] = `${taskName} - ${formatedstartDate} to ${formatedendDate}`;

                this.loadResourceAllocation(task, presaledetail, true);
                preSaleDropDown.children.push(presaledetail);
              } else if (
                task.CRId === null &&
                !task.IsPresales &&
                (task.isProjectManager || window.USER_PERMISSION.includes('VW_PRJTASKMNGMT'))
              ) {
                let formatedstartDate = task.StartDate
                  ? moment(task.StartDate).format('MM/DD/YYYY')
                  : ' ';
                let formatedendDate = task.EndDate
                  ? moment(task.EndDate).format('MM/DD/YYYY')
                  : ' ';
                const record = {
                  id: task.id,
                  title: (
                    <span>
                      {task.Name} - {formatedstartDate} to {formatedendDate} -{' '}
                      <span className="billability-badge">{billabilityId}</span>{' '}
                    </span>
                  ),
                  isCollapsed: true,
                  isProjectManager: task.isProjectManager,
                  content: []
                };
                task['DisplayTaskName'] = `${task.Name} - ${formatedstartDate} to ${formatedendDate}`;

                this.loadResourceAllocation(task, record, true);
                projectDropDown.children.push(record);
              }
            }

            for (let cr of stateObj.CRsOfAProject.filter(i => i.StatusId !== 1)) {
              let crTaskData = stateObj.projectTasks.filter(i => i.CRId === cr.id && !i.IsPresales);
              if (crTaskData && crTaskData.length > 0) {
                let startDate = cr.StartDate
                  ? moment(cr.StartDate).format('MM/DD/YYYY')
                  : cr.EstimatedStartDate
                  ? moment(cr.EstimatedStartDate).format('MM/DD/YYYY')
                  : ' ';
                let endDate = cr.EndDate
                  ? moment(cr.EndDate).format('MM/DD/YYYY')
                  : cr.EstimatedEndDate
                  ? moment(cr.EstimatedEndDate).format('MM/DD/YYYY')
                  : ' ';

                const crDetail = {
                  id: cr.id,
                  title: (
                    <span className="ptm-accordion-cr">
                      {cr.CRName} {' - ' + startDate + ' to ' + endDate}{' '}
                    </span>
                  ),
                  children: []
                };

                for (let crTask of crTaskData) {
                  let billabilityId = null;
                  let formatedstartDate = crTask.StartDate
                    ? moment(crTask.StartDate).format('MM/DD/YYYY')
                    : ' ';
                  let formatedendDate = crTask.EndDate
                    ? moment(crTask.EndDate).format('MM/DD/YYYY')
                    : ' ';

                  if (crTask.BillabilityTypeId === 'BILLABLE') {
                    billabilityId = 'B';
                  }
                  if (crTask.BillabilityTypeId === 'NONBILLABLE') {
                    billabilityId = 'NB';
                  }
                  crTask['isProjectManager'] = await this.verifyCRManager(cr);
                  crTask['DisplayTaskName'] = `${crTask.Name} - ${formatedstartDate} to ${formatedendDate}`;
                  const crTaskDetail = {
                    id: crTask.id,
                    title: (
                      <span>
                        {crTask.Name} - {formatedstartDate} to {formatedendDate} -{' '}
                        <span className="billability-badge">{billabilityId}</span>{' '}
                      </span>
                    ),
                    isCollapsed: true,
                    isProjectManager: crTask.isProjectManager,
                    content: []
                  };
                  if (
                    crTask.isProjectManager ||
                    window.USER_PERMISSION.includes('VW_PRJTASKMNGMT')
                  ) {
                    this.loadResourceAllocation(crTask, crTaskDetail, true);
                    crDetail.children.push(crTaskDetail);
                  }
                }

                projectDropDown.children.push(crDetail);
              }
            }
            arr.push(projectDropDown);
          }
          stateObj.data = arr;
          this.serverData = arr;
          this.setState({
            CRsOfAProject: stateObj.CRsOfAProject,
            selectedCRId: stateObj.selectedCRId,
            projectTasks: stateObj.projectTasks,
            data: stateObj.data && stateObj.data.length > 0 ? stateObj.data : [],
            loading: false
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  onOpenPopupBox = (title, message, callBack) => {
    this.setState({
      popUpBox: {
        isPopUp: true,
        popUpMessage: message,
        popUpTitile: title,
        callBack: callBack
      }
    });
  };

  onClosePopupBox = () => {
    this.setState({
      popUpBox: {
        isPopUp: false,
        popUpMessage: '',
        popUpTitile: '',
        callBack: null
      }
    });
  };

  onDeleteTaskButtonClick = async taskId => {
    await getTimeEntryDataforTaskManagment(taskId).then(async res => {
      if (res && res.data && res.data.isTimeEntryAvailable) {
        this.toggleMessageDialog(
          'Task cannot be deleted as resources have entered time against the task.',
          'Error'
        );
      } else {
        this.onOpenPopupBox('Confirm', 'Are you sure you want to delete this task?', async () => {
          this.onClosePopupBox();
          this.setState({ loading: true });
          await deleteTask(taskId).then(async res => {
            if (res && res.data && res.data.message) {
              this.removePanelBar(taskId);
              await this.loadProjectandTask(this.project.id);
              this.toggleMessageDialog(res.data.message, 'Status');
            }
          });
        });
      }
    });
  };

  removePanelBar = taskId => {
    let privateKey = this.panelBarTaskIds.find(i => i.id == taskId);
    if (privateKey) {
      let expanded = this.state.expandedPanels;
      expanded.splice(expanded.indexOf(privateKey.key), 1);
      let poistionIndex = privateKey.key.length - 1;
      for (let keyIndex = 0; keyIndex < expanded.length; keyIndex++) {
        let keyValue = expanded[keyIndex];
        if (keyValue.length >= privateKey.key.length) {
          let value = Number(keyValue[poistionIndex]);
          if (value >= Number(privateKey.key[poistionIndex])) {
            expanded[keyIndex] = this.setCharAt(keyValue, poistionIndex, (--value).toString());
          }
        }
      }
      this.setState({ expandedPanels: expanded });
    }
  };

  setCharAt = (str, index, chr) => {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
  };

  onDeleteResourceButtonClick = resourceId => {
    this.onOpenPopupBox(
      'Confirm',
      'Are you sure you want to delete this resource from the task?',
      async () => {
        this.setState({ loading: true });
        this.onClosePopupBox();
        await deleteResource(resourceId).then(async res => {
          if (res && res.data && res.data.message) {
            this.toggleMessageDialog(res.data.message, 'Status');
            this.setState({
              selectedResource: [],
              resourceBillability: ' ',
              projAllocStartDate: null,
              projAllocEndDate: null,
              shiftstartTime: null,
              shiftendTime: null,
              incrementalBilling: false
            });
            this.clearAddResourceFields();
            this.loadTimeEntryData(this.state.currentlySelectedTaskId);
          }
        });
      }
    );
  };

  loadResourceAllocation = async (task, taskdetail, isCollapsed, skip) => {
    taskdetail.content = 'Loading...';
    let skipValue = skip;
    let take = 10;
    if (!skipValue){
      skipValue = 0;
    }
    await getTaskAllocationsbyTaskIdPerformanceFix(taskdetail.id, skipValue, take).then(async res => {
      if (res && res.data.rows) {
        let resourceTask = JSON.parse(JSON.stringify(task));
        resourceTask['Resources'] = [];

        const disableBtn = resourceTask.IsPresales ? resourceTask.IsPresales : resourceTask.IsDefaultTask ? resourceTask.IsDefaultTask : resourceTask.IsPresales;
        resourceTask['isDisableDuplicateTaskBtn'] = disableBtn;
        resourceTask['isDisableEditTaskBtn'] = disableBtn;
        resourceTask['isDisableDeleteBtn'] = disableBtn;
        let totalCount = res.data.count;
        for (let record of res.data.rows) {
          record['DesignationName'] =
            record.Resource && record.Resource.Designation
              ? record.Resource.Designation.Name
              : null;
          record['DisplayDate'] =
            moment(record.StartDate).format('MM/DD/YYYY') +
            '  -  ' +
            moment(record.EndDate).format('MM/DD/YYYY');
          record['Billability'] = record.Billability === 'BILLABLE' ? 'Billable' : 'Non-Billable';

          // Assigned hours of resource for the task
          let effortDays = 0;
          let effortHours = 0;

          if (
            record.StartDate &&
            record.StartDate !== null &&
            (record.EndDate && record.EndDate !== null)
          ) {
            let totalWorkingDays = 0;
            let isHoliday = false;
            let holidays = 0;
            let startDate = new Date(record.StartDate);
            let endDate = new Date(record.EndDate);
            let resourceRegion =
              record.Resource && record.Resource !== null && record.Resource.ResourceRegion
                ? record.Resource.ResourceRegion
                : null;
            let allocPercentage = 0;

            if (record.ResourceAllocationSublineId && record.ResourceAllocationSublineId !== null) {
              // subline allocation percentage
              if (
                record.ResourceRequestSubline &&
                record.ResourceRequestSubline !== null &&
                record.ResourceRequestSubline.AllocationPercentage &&
                record.ResourceRequestSubline.AllocationPercentage !== null
              ) {
                allocPercentage = record.ResourceRequestSubline.AllocationPercentage;
              }
            } else {
              // mainline allocation percentage
              if (
                record.ResourceRequest &&
                record.ResourceRequest !== null &&
                record.ResourceRequest.AllocationPercentage &&
                record.ResourceRequest.AllocationPercentage !== null
              ) {
                allocPercentage = record.ResourceRequest.AllocationPercentage;
              }
            }

            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            const resourceCountryId =
              resourceRegion !== null && resourceRegion.DefaultCountryId
                ? resourceRegion.DefaultCountryId
                : null;
            if (resourceCountryId !== null) {
              holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear())
                .then(res => {
                  if (res && res.data) {
                    return res.data;
                  }
                  return [];
                })
                .catch(error => {
                  loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
            }

            let loop = new Date(startDate);
            while (loop <= endDate) {
              isHoliday = false;
              const dateStr = getFormattedDate(loop);
              if (holidays.indexOf(dateStr) > -1) {
                isHoliday = true;
              }
              if (!isHoliday && !isWeekend(loop)) {
                totalWorkingDays++;
              }
              let newDate = loop.setDate(loop.getDate() + 1);
              loop = new Date(newDate);
            }
            effortDays = totalWorkingDays * (allocPercentage / 100);
            effortHours = effortDays * 8;
          } else {
            effortDays = 0;
            effortHours = 0;
          }

          // Billable, Nonbillable, Unapproved, Utilized so far
          let timeEntries = record.TimeEntry ? record.TimeEntry : [];
          let unapproved = 0;
          let billableApp = 0;
          let nonbillableApp = 0;
          let utilized = 0;

          if (timeEntries.length > 0) {
            unapproved =
              timeEntries.filter(el => el.Approved_Status == 1).length > 0
                ? Number(timeEntries.filter(el => el.Approved_Status == 1)[0].Duration)
                : 0;
            billableApp =
              timeEntries.filter(el => el.Approved_Status == 2).length > 0
                ? Number(timeEntries.filter(el => el.Approved_Status == 2)[0].Duration)
                : 0;
            nonbillableApp =
              timeEntries.filter(el => el.Approved_Status == 5).length > 0
                ? Number(timeEntries.filter(el => el.Approved_Status == 5)[0].Duration)
                : 0;
          }
          utilized = billableApp + nonbillableApp;

          record['AssignedHours'] = effortHours !== 0 ? effortHours.toFixed(2) : '';
          record['Billable'] = billableApp.toFixed(2);
          record['NonBillable'] = nonbillableApp.toFixed(2);
          record['Unapproved'] = unapproved.toFixed(2);
          record['Utilized'] = utilized.toFixed(2);

          resourceTask.Resources.push(record);
          resourceTask.TotalCount = totalCount;
          resourceTask.taskdetail = taskdetail;
        }

        taskdetail.content = this.fetchChildren(resourceTask);
        this.setState({});
      }
    });
  };

  setHeaderTitle = () => {
    if (this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Project Task Management');
    }
  };

  populateProjectFields = () => {
    if (this.project) {
      let objArray = [
        {
          projectName: this.project.ProjectName,
          projectId: this.project.UniqueId,
          projStartDate: this.project.StartDate ? 
            moment(this.project.StartDate).format('MM-DD-YYYY') 
            : this.project.EstimatedStartDate ? 
            moment(this.project.EstimatedStartDate).format('MM-DD-YYYY') : null,
          projEndDate: this.project.UserDefinedEndDate
            ? moment(this.project.UserDefinedEndDate).format('MM-DD-YYYY')
            : this.project.EstimatedEndDate
            ? moment(this.project.EstimatedEndDate).format('MM-DD-YYYY')
            : null,
          projCustomer: this.project.Customer.Name,
          projBillRegion: this.project.Region.Name,
          projPractice: this.project.Division.Name,
          projEngageType: this.project.Type.Name,
          projBillMethod: this.project.PaymentMethodRecord.Name
        }
      ];

      this.setState({
        gridDetails: objArray
      });
    }
  };

  retrieveAllTaskTypes = async () => {
    await getAllTaskTypes().then(response => {
      if (response && response.data && response.data.length > 0) {
        this.setState({
          allTaskTypes: response.data.filter(obj => obj.IsActive)
        });
      }
    });
  };

  handleInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    await this.setState({ [name]: value, isFormDirty: true });
  };

  generateMaxMinDatesforTasks = async () => {
    this.maxMinDatesTask = [
      {
        id: this.project.id,
        start: this.state.startDateMin,
        end: this.state.startDateMax
      }
    ];
    await getCRsOfAProject(this.project.id).then(res => {
      if (res && res.data && res.data.length > 0) {
        res.data.forEach(cr => {
          let crDateObj = {
            id: cr.id,
            start: cr.StartDate
              ? reStructureDate(cr.StartDate)
              : cr.EstimatedStartDate
              ? reStructureDate(cr.EstimatedStartDate)
              : reStructureDate('1900/01/01'),
            end: cr.EndDate
              ? reStructureDate(cr.EndDate)
              : cr.EstimatedEndDate
              ? reStructureDate(cr.EstimatedEndDate)
              : reStructureDate('2100/01/01')
          };
          this.maxMinDatesTask.push(crDateObj);
        });
      }
    });
  };

  handleInputChangeForProjectOrCR = async event => {
    const name = event.target.name;
    const value = event.target.value;

    await this.setState({ [name]: value, isFormDirty: true });

    if (value) {
      if (value.BillableStatus === false) {
        this.setState({
          isDisableBillableBtn: true
        });
      } else {
        this.setState({
          isDisableBillableBtn: false
        });
      }

      if (!this.state.isTaskUpdate) {
        this.setStartDateMin(value, null);
        this.setEndDateMax(value, null);
      } else if (this.maxMinDatesTask.length > 0) {
        let dateRangeMaxMin = this.maxMinDatesTask.find(i => i.id == value.id);
        if (dateRangeMaxMin) {
          this.setState({
            startDateMin: dateRangeMaxMin.start,
            startDateMax: dateRangeMaxMin.end,
            startDateMinforEndDate: dateRangeMaxMin.start,
            EndDateMax: dateRangeMaxMin.end
          });
        }
      }
      if (this.state.duplicateTask) {
        await this.populateTaskDuplicateResources(this.state.selectedTaskForDuplicate);
      }
    }
  };

  handleMultiTaskProjectOrCRChange = async event => {
    const name = event.target.name;
    const value = event.target.value;

    let projOrCrTasks = []
    if (value) {
      if (value.Category === 'Project') {
        projOrCrTasks = this.state.projectTasks.filter(val => !val.IsPresales && val.ProjectId == value.id && !val.CRId);
      } else {
        projOrCrTasks = this.state.projectTasks.filter(val => !val.IsPresales && val.CRId == value.id);
      }
      this.setStartDateMin(value, null);
      this.setEndDateMax(value, null);
      await this.setState({ 
        [name]: value,
        isFormDirty: true, 
        projOrCrTasks: projOrCrTasks,
        projOrCrTasksAll: projOrCrTasks,
        selectedProjOrCrTasks: [],
        selectedProjOrCrResources: [],
        multiTaskStartDate: null,
        multiTaskEndDate: null
      });
      await this.populateProjectResourcesForMultiTask(value);
    } else {
      await this.setState({ 
        [name]: value, 
        projOrCrTasks: projOrCrTasks,
        projOrCrTasksAll: projOrCrTasks,
        projOrCrResources: [],
        projOrCrResourcesAll: [],
        selectedProjOrCrTasks: [],
        selectedProjOrCrResources: [],
        multiTaskStartDate: null,
        multiTaskEndDate: null
      });
    } 
  };

  handleMultiTaskInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    if (value && value.length <= 10) {
      await this.setState({ 
        [name]: value,
        isFormDirty: true
      });
      await this.checkMinMaxDatesForMultiTask();
    }
    if (value && value.length <= 0) {
      await this.setState({ 
        selectedProjOrCrResources: [],
        multiTaskStartDate: null,
        multiTaskEndDate: null
      });
    }
  };

  handleMultiResourcesInputChange = async event => {
    let isValidResource = false;
    const name = event.target.name;
    const value = event.target.value;
    const latestSelectedResource = event.value.slice(-1)[0];
    if (value.length > this.state.selectedProjOrCrResources.length) {
      for (const task of this.state.selectedProjOrCrTasks) {
        const resourceStartDate = moment(latestSelectedResource.StartDate).format('MM/DD/YYYY');
        const resourceEndDate =  moment(latestSelectedResource.EndDate).format('MM/DD/YYYY');
        const taskStartDate = moment(task.StartDate).format('MM/DD/YYYY'); 
        const taskEndDate = moment(task.EndDate).format('MM/DD/YYYY');
        
        if (
          new Date(resourceStartDate) > new Date(taskEndDate) ||
          new Date(resourceEndDate) < new Date(taskStartDate) 
        ) {
          isValidResource = false;
        } else {
          isValidResource = true;
          break;
        }
      }

      if (value && value.length <= 10 && isValidResource) {
        await this.setState({ 
          [name]: value,
          isFormDirty: true
        });  
      }
    } else {
      if (value && value.length <= 10) {
        await this.setState({ 
          [name]: value,
          isFormDirty: true
        });  
      }
    }
    
  };

  checkMinMaxDatesForMultiTask = async () => {
    let selectedProjOrCrTasks = this.state.selectedProjOrCrTasks;
    if (selectedProjOrCrTasks && selectedProjOrCrTasks.length > 0) {
      let startDateArray = selectedProjOrCrTasks.map(val => val.StartDate);
      let endDateArray = selectedProjOrCrTasks.map(val => val.EndDate);
      let minDate = startDateArray.reduce((a, b) => {
        return a < b ? a : b;
      });
      let maxDate = endDateArray.reduce((a, b) => {
        return a > b ? a : b; 
      });
  
      await this.setState({ 
        multiTaskDateMin: minDate ? new Date(minDate) : null,
        multiTaskDateMax: maxDate ? new Date(maxDate) : null,
      });  
    }
  }

  filterOnChange = event => {
    const field = event.target.name;
    switch (field) {
      case 'selectedProjOrCrTasks':
        {
          this.setState({
            projOrCrTasks: this.filterComboData(
              event.filter,
              this.state.projOrCrTasksAll
            )
          });
        }
        break;
      case 'selectedProjOrCrResources':
        {
          this.setState({
            projOrCrResources: this.filterComboData(event.filter, this.state.projOrCrResourcesAll)
          });
        }
        break;
      default: {
        break;
      }
    }
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  handleShiftTimeChange = async event => {
    const value = event.target.value;
    this.setState({
      shiftstartTime: value,
      isFormDirty: true
    });
    if (value) {
      var endTime = event.target.value;

      endTime.setHours(endTime.getHours() + 8);

      this.setState({
        shiftendTime: endTime
      });
    } else {
      this.setState({
        shiftendTime: null
      });
    }
  };

  handleResourceChange = async event => {
    let resources = event.target.value;
    if (resources && resources.length > 0) {
      if (resources.length <= 10) {
        this.setState({
          selectedResource: resources,
          isFormDirty: true
        });

        if (resources.filter(i => i.TotalRevenue > 0).length === resources.length) {
          this.setState({
            resourceBillability: 'Billable'
          });
        } else if (resources.filter(i => i.TotalRevenue === 0).length === resources.length) {
          this.setState({
            resourceBillability: 'Non-Billable'
          });
        } else {
          this.setState({
            resourceBillability: ''
          });
        }
      }
    } else {
      this.setState({
        selectedResource: [],
        isFormDirty: false
      });
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      }, () => {
        this.loadTimeEntryData(this.state.currentlySelectedTaskId)
      });
    }
  };

  toggleCRDialog = () => {
    if (this.state.CRsOfAProject.length === 0) {
      this.toggleMessageDialog('No CRs found', 'Error');
    } else {
      this.setState({
        viewCRDialog: !this.state.viewCRDialog
      });
    }
  };

  toggleCMSDialog = () => {
    this.setState({
      viewCMSDialog: !this.state.viewCMSDialog
    });
  };
  populateAllProjects = async () => {
    await getAllProjectsforTaskManagement().then(response => {
      this.setState({
        allProjects: response.data
      });
    });
  };
  toggleDuplicateTaskDialog = async task => {
    let selectedTask = this.state.projectTasks.find(i => i.id == task.id);
    let selectedProjCR = this.state.dropdownprojectcr.find(
      i => i.id == (task.CRId ? task.CRId : task.ProjectId)
    );
    if (selectedTask) {
      let taskStart =moment(task.StartDate).format('YYYY-MM-DD');
      let taskEnd =moment(task.EndDate).format('YYYY-MM-DD');
      let formatedTaskStartDate = [moment(taskStart).format('YYYY'), moment(taskStart).format('M'), moment(taskStart).format('D')].join('/');
      let formatedTaskEndDate = [moment(taskEnd).format('YYYY'), moment(taskEnd).format('M'), moment(taskEnd).format('D')].join('/');

      await this.setState({
        selectedTaskForDuplicate: selectedTask,
        selectedProjectOrCr: selectedProjCR,
        taskStartdate: new Date(formatedTaskStartDate),
        taskEnddate: new Date(formatedTaskEndDate),
      });
      await this.populateTaskDuplicateResources(selectedTask);

      this.setStartDateMin(selectedProjCR, null);
      this.setEndDateMax(selectedProjCR, null);
      this.handleBillableChange({ target: { value: task.BillabilityTypeId.toLowerCase() } });
      this.setState({
        isFormDirty: false,
        duplicateTask: !this.state.duplicateTask,
        taskName: task.Name,
        selectedProjectOrCr: selectedProjCR,
        selectedTask: !task.IsPresales
          ? this.state.allTaskTypes.find(obj => obj.id === task.TaskTypeId)
          : { BillabilityTypeId: 'NONBILLABLE', IsActive: false, Name: 'Presales', id: 1 },
        billableStatus: task.BillabilityTypeId.toLowerCase(),
        taskStartdate: new Date(formatedTaskStartDate),
        taskEnddate: new Date(formatedTaskEndDate),
        disableTaskType: false,
        isErrorMessageVisible: false,
        isTimeEntryAvailable: false,
        isDisableEditTaskBtn: task.isDisableEditTaskBtn,
        isduplicateTask: true
      });
    }
  };

  toggleMessageDialog = async (message, title) => {
    if (this.isMount) {
      await this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title,
        loading: false
      });
      if (!this.state.showMessageDialog) {
        await this.setState({
          failedResources: [],
          showSuccessImage: false,
          showErrorImage: false
        });
      }
    }
  };

  handleChange = event => {
    this.setState({
      valueptm: event.target.valueptm,
      valuett: event.target.valuett
    });
  };

  onChange = event => {
    this.setState({
      allowdata: event.target.checked
    });
  };

  toggleBillableToolTip = () => {
    this.setState({
      billableTooltipOpen: !this.state.billableTooltipOpen
    });
  };

  toggleNonBillableToolTip = () => {
    this.setState({
      nonBillableTooltipOpen: !this.state.nonBillableTooltipOpen
    });
  };

  toggleIncrementToolTip = () => {
    this.setState({
      incrementTooltip: !this.state.incrementTooltip
    });
  };

  verifyProjectManager = async (project) => {
    if (project.AllocatedResourceRequestPM && project.AllocatedResourceRequestPM.length > 0) {
      //find valid pms from end date
      let projectProjectManagers = project.AllocatedResourceRequestPM;
      let projValidManagers = [];
      projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (projValidManagers.length == 0 && projectProjectManagers.length > 0){

        projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
        const lastAssignedManager = projectProjectManagers[0];
        projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

      }
      for (const value of projValidManagers){
        if(window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()){
          return true;
        }
      }
    }
    return false;
  };

  verifyCRManager = async cr => {
    /*if (
      cr.AllocatedPM.length > 0 &&
      cr.AllocatedPM.find(
        pm => pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()
      )
    ) {
      return true;
    }*/
    if (cr.AllocatedPM.length > 0) {
      //valid managers
      const crProjectManagers = cr.AllocatedPM;

      //find valid pms from end date
      let validManagers = [];
      validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())));

      if (validManagers.length === 0 && crProjectManagers.length > 0) {
        crProjectManagers.sort(function (a, b) {
          return new Date(b.EndDate) - new Date(a.EndDate)
        })
        const lastAssignedManager = crProjectManagers[0];
        validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate));

      }
      for (const value of validManagers){
        if (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()) {
          return true;
        }
      }
    }
    return false;
  };

  toggleShiftToolTip = () => {
    this.setState({
      shiftTooltip: !this.state.shiftTooltip
    });
  };

  toggleAddEditDialog = () => {
    this.setStartDateMin(this.project, null);
    this.setEndDateMax(this.project, null);
    this.setState({
      isFormDirty: false,
      taskName: '',
      selectedProjectOrCr: null,
      billableStatus: '',
      selectedTask: null,
      taskStartdate: null,
      taskEnddate: null,
      disableTaskType: true,
      isErrorMessageVisible: false,
      isTimeEntryAvailable: null,
      isTaskUpdate: false,
      isDisableEditTaskBtn: false,
      addEditTaskDialog: !this.state.addEditTaskDialog,
      taskId: null,
      isduplicateTask: false
    });
    if (this.state.dropdownprojectcr.length > 0) {
      this.setStartDateMin(this.state.dropdownprojectcr[0], null);
      this.setEndDateMax(this.state.dropdownprojectcr[0], null);  
    }
    if (!this.state.addEditTaskDialog) {
      this.setState({
        selectedProjectOrCr:
          this.state.dropdownprojectcr.length > 0 ? this.state.dropdownprojectcr[0] : ''
      });
    }
  };

  toggleMultiTaskResourcesDialog = () => {
    this.setState({
      multiTaskResourcesDialog: !this.state.multiTaskResourcesDialog,
      projOrCrTasks: [],
      projOrCrResources: [],
      selectedProjectOrCr: [],
      selectedProjOrCrTasks: [],
      selectedProjOrCrResources: [],
      multiTaskStartDate: null,
      multiTaskEndDate: null,
      isErrorMessageVisible: false,
      isErrorMessageVisibleForDates: false,
      isFormDirty: false
    });
  };

  onDuplicateTaskButtonClick = async task => {
    let selectedTask = this.state.projectTasks.find(i => i.id == task.id);
    let selectedProjCR = this.state.dropdownprojectcr.find(
      i => i.id == (task.CRId ? task.CRId : task.ProjectId)
    );
    if (selectedTask) {
      this.setStartDateMin(selectedProjCR, null);
      this.setEndDateMax(selectedProjCR, null);
      this.handleBillableChange({ target: { value: task.BillabilityTypeId.toLowerCase() } });
      let taskStart =moment(task.StartDate).format('YYYY-MM-DD');
      let taskEnd =moment(task.EndDate).format('YYYY-MM-DD');
      let formatedTaskStartDate = [moment(taskStart).format('YYYY'), moment(taskStart).format('M'), moment(taskStart).format('D')].join('/');
      let formatedTaskEndDate = [moment(taskEnd).format('YYYY'), moment(taskEnd).format('M'), moment(taskEnd).format('D')].join('/');
      this.setState({
        isFormDirty: false,
        taskName: selectedTask.Name,
        selectedProjectOrCr: selectedProjCR,
        billableStatus: task.BillabilityTypeId.toLowerCase(),
        selectedTask: !task.IsPresales
          ? this.state.allTaskTypes.find(obj => obj.id === task.TaskTypeId)
          : { BillabilityTypeId: 'NONBILLABLE', IsActive: false, Name: 'Presales', id: 1 },
        taskStartdate: new Date(formatedTaskStartDate),
        taskEnddate: new Date(formatedTaskEndDate),
        disableTaskType: false,
        isErrorMessageVisible: false,
        isTimeEntryAvailable: false,
        addEditTaskDialog: true,
        isDisableEditTaskBtn: task.isDisableEditTaskBtn,
        isduplicateTask: true
      });
    }
  };

  onEditResourceFilterChange = e => {
    let filteredResources = [];
    if (e && e.filter && e.filter.value) {
      filteredResources = this.state.taskResources.filter(i =>
        i.DisplayText.toLowerCase().includes(e.filter.value.toLowerCase())
      );
    } else {
      filteredResources = this.state.taskResources;
    }
    this.setState({ taskResourcesDropDown: filteredResources });
  };

  onEditTaskButtonClick = async task => {
    await getTimeEntryDataforTaskManagment(task.id).then(response => {
      if (response && response.data) {
        let res = response.data;
        let selectedTask = this.state.projectTasks.find(i => i.id == task.id);
        let selectedProjCR = this.state.dropdownprojectcr.find(
          i => i.id == (task.CRId ? task.CRId : task.ProjectId)
        );
        let resStart =moment(res.StartDate).format('YYYY-MM-DD')
        let taskStart =moment(task.StartDate).format('YYYY-MM-DD')
        let formatedResStartDate = [moment(resStart).format('YYYY'), moment(resStart).format('M'), moment(resStart).format('D')].join('/');
        let formatedTaskStartDate = [moment(taskStart).format('YYYY'), moment(taskStart).format('M'), moment(taskStart).format('D')].join('/');
        if (selectedTask) {
          this.setStartDateMin(selectedProjCR, res);
          this.setEndDateMax(selectedProjCR, res);
          this.handleBillableChange({ target: { value: task.BillabilityTypeId.toLowerCase() } });
          this.setState({
            isFormDirty: false,
            taskId: selectedTask.id,
            taskName: selectedTask.Name,
            latestTimeEntryDate: res.latestTimeEntryDate,
            resourceMaxStartDate: res.resourceMaxStartDate,
            selectedProjectOrCr: selectedProjCR,
            billableStatus: task.BillabilityTypeId.toLowerCase(),
            selectedTask: !task.IsPresales
              ? this.state.allTaskTypes.find(obj => obj.id === task.TaskTypeId)
              : { BillabilityTypeId: 'NONBILLABLE', IsActive: false, Name: 'Presales', id: 1 },
            taskStartdate:
              res.StartDate && new Date(formatedResStartDate) < new Date(formatedTaskStartDate)
                ? new Date(formatedResStartDate)
                : new Date(formatedTaskStartDate),
            taskEnddate: this.getTaskEndDate(task, res),
            disableTaskType: false,
            isErrorMessageVisible: false,
            isTimeEntryAvailable: res.isTimeEntryAvailable,
            addEditTaskDialog: true,
            isDisableEditTaskBtn: task.isDisableEditTaskBtn,
            isTaskUpdate: true
          });
        }
      }
    });
  };

  duplicateTaskName = (id, taskName) => {
    if (
      this.state.projectTasks.find(i => i.Name == taskName && (i.ProjectId == id || i.CRId == id))
    ) {
      return true;
    }
    return false;
  };

  getTaskEndDate = (task, res) => {
    if (task.IsPresales) {
      if (task.CRId) {
        let cr = this.state.CRsOfAProject.find(i => i.id === task.CRId);
        let ConvertedToActiveAtCr = moment(cr.ConvertedToActiveAt).format('YYYY-MM-DD')
        let formatedConvertedToActiveAtCr = [moment(ConvertedToActiveAtCr).format('YYYY'), moment(ConvertedToActiveAtCr).format('M'), moment(ConvertedToActiveAtCr).format('D')].join('/');
        return cr && cr.ConvertedToActiveAt ? new Date(formatedConvertedToActiveAtCr) : null;
      } else {
        let ConvertedToActiveAtPro = moment(this.project.ConvertedToActiveAt ).format('YYYY-MM-DD');
        let formatedConvertedToActiveAt = [moment(ConvertedToActiveAtPro).format('YYYY'), moment(ConvertedToActiveAtPro).format('M'), moment(ConvertedToActiveAtPro).format('D')].join('/');
        return this.project.ConvertedToActiveAt ? new Date(formatedConvertedToActiveAt) : null;
      }
    } else {
      let resEnd = moment(res.EndDate).format('YYYY-MM-DD');
      let taskEnd = moment(task.EndDate).format('YYYY-MM-DD');
      let formatedResEnd = [moment(resEnd).format('YYYY'), moment(resEnd).format('M'), moment(resEnd).format('D')].join('/');
      let formatedTaskEnd = [moment(taskEnd).format('YYYY'), moment(taskEnd).format('M'), moment(taskEnd).format('D')].join('/');

      return res.EndDate && new Date(formatedResEnd) > new Date(formatedTaskEnd)
        ? new Date(formatedResEnd)
        : new Date(formatedTaskEnd);
    }
  };

  onEditResc = async resource => {
    let resc = this.state.taskResources.find(
      i =>
        (resource.ResourceAllocationSublineId && i.id == resource.ResourceAllocationSublineId) ||
        i.id == resource.ResourceAllocationId
    );
    if (resource.isTimeEntryAvailable) {
      this.setStartDateMinAddResourceTimeEntered(this.state.currentChildTask, resource);
    }
    this.setState({
      selectedResourceId: resource.id,
      isTimeEntryAvailableResource: resource.isTimeEntryAvailable ? true : false,
      selectedResource: resc ? [resc] : [],
      projAllocStartDate: reStructureDate(resource.StartDate),
      projAllocEndDate: reStructureDate(resource.EndDate),
      shiftstartTime: resource.ShiftFrom ? reStructureDate(resource.ShiftFrom) : null,
      shiftendTime: resource.ShiftTo ? reStructureDate(resource.ShiftTo) : null,
      incrementalBilling: resource.IsIncremental
    });
    this.handleResourceChange({ target: { value: resc ? [resc] : [] } });
  };

  loadTimeEntryData = async taksid => {
    let resourceGrid = [];
    let currentUtilizes = [];
    let totalAllocationCount = 0;

    let taskData = {
      TaskId: taksid,
      ProjectId: this.state.projectId,
      CrIds: this.state.crIds,
      IsPreSalesTask: this.state.isPresalesTask
    };
    // Current utilizes - Edit resource popup
    await getCurrentUtilizesOfResources(taskData)
      .then(res => {
        if (res.data && res.data.length > 0) {
          currentUtilizes = res.data;
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    await getTimeEntryDataforTaskManagmentPerformanceFix(taksid, this.state.skip, this.state.take).then(res => {
      if (res.data && res.data.allocations) {
        totalAllocationCount = res.data.totalAllocationsCount;
        if (currentUtilizes.length > 0) {
          for (let alloc of res.data.allocations) {
            alloc.TotalEffort =
              currentUtilizes.filter(el => el.ResId == alloc.ResourceId).length > 0
                ? currentUtilizes.filter(el => el.ResId == alloc.ResourceId)[0].EffortDays
                : 0;

            let billAppTime =
              currentUtilizes.filter(el => el.ResId == alloc.ResourceId).length > 0
                ? currentUtilizes.filter(el => el.ResId == alloc.ResourceId)[0].BillableApproved
                : 0;
            let nonBillAppTime =
              currentUtilizes.filter(el => el.ResId == alloc.ResourceId).length > 0
                ? currentUtilizes.filter(el => el.ResId == alloc.ResourceId)[0].NonBillApproved
                : 0;
            let totalAppTime = Number(billAppTime) + Number(nonBillAppTime);
            alloc.ApprovedTime = totalAppTime;

            alloc.UnapprovedTime =
              currentUtilizes.filter(el => el.ResId == alloc.ResourceId).length > 0
                ? Number(currentUtilizes.filter(el => el.ResId == alloc.ResourceId)[0].Unapproved)
                : 0;
          }
        }

        resourceGrid = res.data.allocations;
      }
    });
    this.setState({
      resourcegrid: resourceGrid,
      totalAllocationCount: totalAllocationCount
    });
  };

  getBillableLable(billableId) {
    switch (billableId) {
      case 'BILLABLE':
        return 'Billable';
      case 'BOTH':
        return 'Both';
      case 'NONBILLABLE':
        return 'Non-Billable';
    }
  }

  toggleAddEditResc = async task => {
    if (this.state.isFormDirty === false) {
      //this.loadProjectandTask(this.project.id);

      let formatedstartDate, formatedendDate;
      let billability = this.getBillableLable(task.BillabilityTypeId);

      if (task && task.StartDate) {
        let d = moment(task.StartDate, 'YYYY/MM/DD');
        let month = d.format('M');
        let day = d.format('D');
        let year = d.format('YYYY');

        formatedstartDate = [month, day, year].join('/');
      }
      if (task && task.EndDate) {
        var d = moment(task.EndDate, 'YYYY/MM/DD');
        let month = d.format('M');
        let day = d.format('D');
        let year = d.format('YYYY');

        formatedendDate = [month, day, year].join('/');
      }

      this.setState({
        isFormDirty: false,
        resourcegrid: [],
        selectedResourceId: null,
        isTimeEntryAvailableResource: null,
        isErrorMessageVisible: false,
        selectedResource: null,
        resourceBillability: ' ',
        incrementalBilling: false,
        projAllocStartDate: null,
        projAllocEndDate: null,
        shiftstartTime: null,
        shiftendTime: null,
        addEditRescTask: true,
        currentlySelectedTaskId: task.id,
        currentChildTask: task,
        currentlySelectedTask: task.Name,
        currentlySelectedTaskBillability: billability,
        currentlSelectedTaskStartDate: formatedstartDate ? formatedstartDate : null,
        currentlySelectedTaskEndDate: formatedendDate ? formatedendDate : null
      });

      //check whether it is presales task
      if (task.IsPresales === true) {
        this.setState({
          currentChildTask: task,
          isDisableAddBtnAddResc: true,
          isDisableAddRescDropdown: true,
          isDisableAddRescStartDate: true,
          isDisableAddRescEndDate: true,
          isDisableAddRescIncrementBill: true,
          isDisableClearBtnAddResc: true,
          isDisableAddRescShiftStartTime: true
        });
        this.state.isPresalesTask = true;
      } else {
        this.setState({
          currentChildTask: task,
          isDisableAddBtnAddResc: false,
          isDisableAddRescDropdown: false,
          isDisableAddRescStartDate: false,
          isDisableAddRescEndDate: false,
          isDisableAddRescIncrementBill: false,
          isDisableClearBtnAddResc: false,
          isDisableAddRescShiftStartTime: false
        });
        this.state.isPresalesTask = false;

        if (task.ProjectType === 'Project') {
          let result = this.state.allProjects.filter(obj => obj.id === task.ProjectId);
          if (result && result.length > 0) {
            this.setState({
              ProjectOrCrName: result[0].ProjectName
            });
          }
        }
        if (task.ProjectType === 'ChangeRequest') {
          let result = this.state.CRsOfAProject.filter(obj => obj.id === task.CRId);
          if (result && result.length > 0) {
            this.setState({
              ProjectOrCrName: result[0].CRName
            });
          }
        }

        this.setStartDateMinAddResource(task);
        this.setEndDateMaxAddResource(task);

        this.populateProjectResources(task);
      }
      await this.loadTimeEntryData(task.id);
    } else {
      //this.populateAllProjectTasks();
      this.loadProjectandTask(this.project.id);
    }
  };

  onClickSubmit = () => {
    if (this.state.isFormDirty) {
      this.setState({
        cancelEditTaskVisible: true
      });
    } else {
      this.setState({
        addEditRescTask: false
      });
    }
    this.loadProjectandTask(this.project.id);
  };

  populateProjectStatistics = projectData => {
    let project = projectData;

    getCalculatedProjectStatistics(project)
      .then(response => {
        if (response.data.length > 0) {
          let calculatedStatistics = response.data;
          let calculatedData = [];

          let item = {
            BaselineEffort: calculatedStatistics[0].BaselineEffort.toFixed(2),
            Allocations: calculatedStatistics[0].Allocations.toFixed(2),
            UnApproved: calculatedStatistics[0].UnApproved.toFixed(2),
            ActualEffort: calculatedStatistics[0].ActualEffort.toFixed(2),
            BillableEffort: calculatedStatistics[0].BillableEffort.toFixed(2),
            NonBillableEffort: calculatedStatistics[0].NonBillableEffort.toFixed(2),
            NonBillableESE: calculatedStatistics[0].NonBillableESE.toFixed(2),
            NonBillableRWK: calculatedStatistics[0].NonBillableRWK.toFixed(2),
            NonBillablePMM: calculatedStatistics[0].NonBillablePMM.toFixed(2),
            NonBillableGeneral: calculatedStatistics[0].NonBillableGeneral.toFixed(2),
            Rejected: calculatedStatistics[0].Rejected.toFixed(2),
            OverUnderUtilization: calculatedStatistics[0].OverUnderUtilization.toFixed(2)
          };

          calculatedData.push(item);
          this.setState({
            efforthours: calculatedData,
            loading: false
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          loading: false
        });
      });
  };

  enableDisableBillability = () => {
    if (this.project) {
      if (this.project.BillableStatus === false) {
        this.setState({
          isDisableBillableBtn: true
        });
      } else {
        this.setState({
          isDisableBillableBtn: false
        });
      }
    }
  };

  populateAllDesignations = () => {
    getAllDesignations().then(res => {
      this.setState({
        allDesignations: res.data
      });
    });
  };
  toggleClearAddResource = () => {
    if (this.state.isFormDirty === true) {
      this.setState({
        cancelAddResourceVisible: !this.state.cancelAddResourceVisible
      });
    }
  };
  clearAddResourceFields = () => {
    this.setStartDateMinAddResource(this.state.currentChildTask);
    this.setEndDateMaxAddResource(this.state.currentChildTask);
    this.setState({
      onEditTaskButtonClick: false,
      selectedResourceId: null,
      cancelAddResourceVisible: false,
      selectedResource: [],
      projAllocStartDate: null,
      projAllocEndDate: null,
      shiftstartTime: null,
      shiftendTime: null,
      resourceBillability: ' ',
      incrementalBilling: false,
      isTimeEntryAvailableResource: null,
      isFormDirty: false,
      isErrorMessageVisible: false
    });
  };

  toggleDialog = () => {
    this.setState({
      visible: !this.state.visible
    });
  };

  onClickResourceView = (event, props, projectId) => {
    event.preventDefault();
    const resourceId = props.ResourceId;

    getProjectResourceUtilizationBreakdown(resourceId, projectId)
      .then(response => {
        const resUtilizationCalc = Object.values(response.data);
        this.setState({
          resourceUtilizationBreakdown: resUtilizationCalc,
          resourceName: props.Name,
          resourceDesignation: props.DesignationName,
          visible: true
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  toggleCancelAdditEditTask = () => {
    if (this.state.isFormDirty === true) {
      this.setState({
        cancelAddEditTaskVisible: !this.state.cancelAddEditTaskVisible,
        isTimeEntryAvailable: null,
        isDisableEditTaskBtn: false,
        isTaskUpdate: false,
        isduplicateTask: false
      });
    } else {
      this.toggleAddEditDialog();
    }
  };

  toggleCancelMultiTaskResourceDialog = () => {
    if (this.state.isFormDirty === true) {
      this.setState({
        cancelMultiTaskResourceVisible: !this.state.cancelMultiTaskResourceVisible,
      });
    } else {
      this.toggleMultiTaskResourcesDialog();
    }
  };


  toggleConfirmMultiTaskAllocationDialog = () => {
    this.setState({
      confirmMultiTaskAllocationVisible: !this.state.confirmMultiTaskAllocationVisible,
    });
  };

  confirmMultiTaskCancelYes = () => {
    this.toggleCancelMultiTaskResourceDialog();
    this.toggleMultiTaskResourcesDialog();
    this.setState({
      isFormDirty: false
    });
  };

  confirmCancelYes = () => {
    this.toggleAddEditDialog();
    this.toggleCancelAdditEditTask();
    this.setState({
      isFormDirty: false
    });
  };
  confirmCancelNo = () => {
    this.toggleCancelAdditEditTask();
  };
  confirmCancelTaskYes = () => {
    this.clearAddResourceFields();
    this.setState({
      isFormDirty: false,
      cancelEditTaskVisible: false,
      addEditRescTask: false
    });
  };
  confirmCancelTaskNo = () => {
    this.setState({
      cancelEditTaskVisible: false
    });
  };

  populateProjectResources = async task => {
    let projectType = task.ProjectType;
    let projectId = null;
    let resources = [];
    let resourcDropdown = [];

    this.setState({
      currentChildTask: task
    });

    if (task.CRId !== null) {
      projectId = task.CRId;
    } else {
      projectId = task.ProjectId;
    }

    await getAllResourcesAndUsersById(projectType, projectId).then(res => {
      let data = [];

      if (res.data && res.data.length > 0) {
        for (let item of res.data) {
          data.push(item);
        }
      }

      let completeStatus = data.filter(obj => obj.Status === 'COMPLETE');
      let subLines = data.filter(
        obj => obj.Status !== 'COMPLETE' && obj.ResourceRequestSubLine.length > 0
      );

      if (completeStatus && completeStatus.length > 0) {
        for (let item of completeStatus) {
          if (item.Resource && !item.IsSoftBookingResource) {
            item.Resource.category = 'ResourceReq';
            item.Resource.StartDate = item.StartDate;
            item.Resource.EndDate = item.EndDate;
            item.Resource.TotalRevenue = item.TotalRevenue;
            item.Resource.ResourceRequestId = item.id;
            item.Resource.RateType = item.RateType
            resources.push(item.Resource);
          }
        }
      }

      if (subLines && subLines.length > 0) {
        for (let line of subLines) {
          for (let allocation of line.ResourceRequestSubLine) {
            if (allocation.Status === 'COMPLETE' && !allocation.IsSoftBookingResource) {
              if (allocation.AllocatedResource && allocation.AllocatedResource.Resource) {
                allocation.AllocatedResource.Resource.category = 'subline';
                allocation.AllocatedResource.Resource.StartDate = allocation.StartDate;
                allocation.AllocatedResource.Resource.EndDate = allocation.EndDate;
                allocation.AllocatedResource.Resource.TotalRevenue = allocation.Revenue;
                allocation.AllocatedResource.Resource.sublineId = allocation.id;
                allocation.AllocatedResource.Resource.RateType = allocation.RateType
                allocation.AllocatedResource.Resource.ResourceRequestId =
                  allocation.ResourceRequestId;
                resources.push(allocation.AllocatedResource.Resource);
              }
            }
          }
        }
      }
    });

    for (let item of resources) {
      if (item) {
        let resourceStartDate = item.StartDate ? new Date(item.StartDate) : null;
        let resourceEndDate = item.EndDate ? new Date(item.EndDate) : null;
        let taskStartDate = new Date(task.StartDate);
        let taskEndDate = new Date(task.EndDate);
        let formattedRescStartDate = moment(item.StartDate).format('MM/DD/YYYY');
        let formattedRescEndDate = moment(item.EndDate).format('MM/DD/YYYY');

        if (
          !(
            (resourceStartDate < taskStartDate && resourceEndDate < taskStartDate) ||
            (resourceStartDate > taskEndDate && resourceEndDate > taskEndDate)
          )
        ) {
          //validate whether resource falls within the task start date and
          //isue here - resouce subline id

          let billability = item.RateType && item.RateType == 5 ? 'Non-Billable' : 'Billable';

          const dataItem = {
            id:
              item.category == 'subline'
                ? item.sublineId
                : item.ResourceRequestId
                ? item.ResourceRequestId
                : null,
            Name: item.Name ? item.Name : null,
            ResourceId: item.id ? item.id : null,
            Email: item.Email ? item.Email : null,
            TotalRevenue: item.TotalRevenue && item.TotalRevenue > 0 ? item.TotalRevenue : 0,
            Taskid: task.id,
            ResourceAllocationid: item.ResourceRequestId ? item.ResourceRequestId : null,
            ResourceAllocationSublineId: item.category == 'subline' ? item.sublineId : null,
            DisplayText: item.Name + '-' + formattedRescStartDate + ' to ' + formattedRescEndDate + '-' + billability,
            UserId: item.UserId,
            MainLineId: item.MainLineId,
            StartDate: item.StartDate,
            EndDate: item.EndDate
          };
          resourcDropdown.push(dataItem);
        }
      }
    }

    this.setState({
      taskResources: resourcDropdown,
      taskResourcesDropDown: resourcDropdown
    });
  };

  populateProjectResourcesForMultiTask = async projOrCr => {
    let projectType = projOrCr.Category;
    let projectId = projOrCr.id;
    let resources = [];

    await getAllResourcesAndUsersById(projectType, projectId).then(res => {

      if (res.data && res.data.length > 0) {
        let completeStatus = res.data.filter(obj => obj.Status === 'COMPLETE');
        let subLines = res.data.filter(
          obj => obj.Status !== 'COMPLETE' && obj.ResourceRequestSubLine.length > 0
        );

        if (completeStatus && completeStatus.length > 0) {
          for (let item of completeStatus) {
            if (item.Resource && !item.IsSoftBookingResource) {
              let formattedRescStartDate = moment(item.StartDate).format('MM/DD/YYYY');
              let formattedRescEndDate = moment(item.EndDate).format('MM/DD/YYYY');

              const dataItem = {
                id: item.id,
                Name: item.Resource.Name ? item.Resource.Name : null,
                ResourceId: item.Resource.id ? item.Resource.id : null,
                Email: item.Resource.Email ? item.Resource.Email : null,
                TotalRevenue: item.TotalRevenue && item.TotalRevenue > 0 ? item.TotalRevenue : 0,
                ResourceAllocationid: item.id ? item.id : null,
                ResourceAllocationSublineId: null,
                DisplayText: item.Resource.Name + '-' + formattedRescStartDate + ' to ' + formattedRescEndDate,
                UserId: item.Resource.id ? item.Resource.id : null,
                StartDate: item.StartDate,
                EndDate: item.EndDate,
              };
              resources.push(dataItem);
            }
          }
        }

        if (subLines && subLines.length > 0) {
          for (let line of subLines) {
            for (let allocation of line.ResourceRequestSubLine) {
              if (allocation.Status === 'COMPLETE' && !allocation.IsSoftBookingResource) {
                if (allocation.AllocatedResource && allocation.AllocatedResource.Resource) {
                  let formattedRescStartDate = moment(allocation.StartDate).format('MM/DD/YYYY');
                  let formattedRescEndDate = moment(allocation.EndDate).format('MM/DD/YYYY');

                  const dataItem = {
                    id: allocation.id,
                    Name: allocation.AllocatedResource.Resource.Name ? allocation.AllocatedResource.Resource.Name : null,
                    ResourceId: allocation.AllocatedResource.Resource.id ? allocation.AllocatedResource.Resource.id : null,
                    Email: allocation.AllocatedResource.Resource.Email ? allocation.AllocatedResource.Resource.Email : null,
                    TotalRevenue: allocation.Revenue,
                    ResourceAllocationid: allocation.ResourceRequestId,
                    ResourceAllocationSublineId: allocation.id,
                    DisplayText: allocation.AllocatedResource.Resource.Name + '-' + formattedRescStartDate + ' to ' + formattedRescEndDate,
                    UserId: allocation.AllocatedResource.Resource.UserId,
                    StartDate: allocation.StartDate,
                    EndDate: allocation.EndDate
                  };
                  resources.push(dataItem);
                }
              }
            }
          }
        }
      }
    });
    this.setState({
      projOrCrResources: resources,
      projOrCrResourcesAll: resources
    });
  };

  populateTaskDuplicateResources = async task => {
    let projectType = task.ProjectType;
    let projectId = null;
    let resources = [];
    let validResources = [];
    let existingResources = [];
    let existingAllocationData = [];

    projectType = this.state.selectedProjectOrCr.Category;
    projectId = this.state.selectedProjectOrCr.id;

    await getExistingTaskResources(task.id).then(res => {
      if (res.data && res.data.length > 0) {
        existingAllocationData = res.data;
        for (let item of res.data) {
          existingResources.push(item.ResourceId);
        }
        existingResources = [...new Set(existingResources)]
      }
    });

    await getAllResourcesAndUsersById(projectType, projectId).then(res => {
      let completeStatus = res.data.filter(obj => obj.Status === 'COMPLETE');
      let subLines = res.data.filter(
        obj => obj.Status !== 'COMPLETE' && obj.ResourceRequestSubLine.length > 0
      );

      if (completeStatus && completeStatus.length > 0) {
        for (let item of completeStatus) {
          if (item.Resource && !item.IsSoftBookingResource && existingResources.includes(item.Resource.id)) {
            let formattedRescStartDate = moment(item.StartDate).format('MM/DD/YYYY');
            let formattedRescEndDate = moment(item.EndDate).format('MM/DD/YYYY');

            let latestAllocation = existingAllocationData.filter(obj => obj.ResourceId === item.Resource.id);
    
            const dataItem = {
              id: item.id,
              Name: item.Resource.Name ? item.Resource.Name : null,
              ResourceId: item.Resource.id ? item.Resource.id : null,
              Email: item.Resource.Email ? item.Resource.Email : null,
              TotalRevenue: item.TotalRevenue && item.TotalRevenue > 0 ? item.TotalRevenue : 0,
              Taskid: task.id,
              ResourceAllocationid: item.id ? item.id : null,
              ResourceAllocationSublineId: null,
              AssignedDuration: formattedRescStartDate + ' - ' + formattedRescEndDate,
              UserId: item.Resource.id ? item.Resource.id : null,
              StartDate: item.StartDate,
              EndDate: item.EndDate,
              IsIncremental: latestAllocation && latestAllocation.length > 0 ? latestAllocation[0].IsIncremental : false
            };
            resources.push(dataItem);
            if (
              !(new Date(item.StartDate) > new Date(this.state.taskEnddate) ||
              new Date(item.EndDate) < new Date(this.state.taskStartdate) )
            ) {
              validResources.push(dataItem);
            }
          }
        }
      }

      if (subLines && subLines.length > 0) {
        for (let line of subLines) {
          for (let allocation of line.ResourceRequestSubLine) {
            if (allocation.Status === 'COMPLETE' && !allocation.IsSoftBookingResource) {
              if (allocation.AllocatedResource && allocation.AllocatedResource.Resource && existingResources.includes(allocation.AllocatedResource.Resource.id)) {
                let formattedRescStartDate = moment(allocation.StartDate).format('MM/DD/YYYY');
                let formattedRescEndDate = moment(allocation.EndDate).format('MM/DD/YYYY');

                let latestAllocation = existingAllocationData.filter(obj => obj.ResourceId === allocation.AllocatedResource.Resource.id);
        
                const dataItem = {
                  id:allocation.id,
                  Name: allocation.AllocatedResource.Resource.Name ? allocation.AllocatedResource.Resource.Name : null,
                  ResourceId: allocation.AllocatedResource.Resource.id ? allocation.AllocatedResource.Resource.id : null,
                  Email: allocation.AllocatedResource.Resource.Email ? allocation.AllocatedResource.Resource.Email : null,
                  TotalRevenue: allocation.Revenue && allocation.Revenue > 0 ? allocation.Revenue : 0,
                  Taskid: task.id,
                  ResourceAllocationid: allocation.ResourceRequestId ? allocation.ResourceRequestId : null,
                  ResourceAllocationSublineId: allocation.id ? allocation.id : null,
                  AssignedDuration: formattedRescStartDate + ' - ' + formattedRescEndDate,
                  UserId: allocation.AllocatedResource.Resource.id,
                  StartDate: allocation.StartDate,
                  EndDate: allocation.EndDate,
                  IsIncremental: latestAllocation && latestAllocation.length > 0 ? latestAllocation[0].IsIncremental : false
                };
                resources.push(dataItem);
                if (
                  !(new Date(allocation.StartDate) > new Date(this.state.taskEnddate) ||
                  new Date(allocation.EndDate) < new Date(this.state.taskStartdate) )
                ) {
                  validResources.push(dataItem);
                }
              }
            }
          }
        }
      }
    });

    await this.setState({
      allDuplicateTaskResources: resources,
      duplicateTaskResources: validResources,
    });
  };

  fetchRoot = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let data = this.serverData.map(item => {
          return {
            ...item,
            children: undefined,
            content: (
              <div className="custom-loading-template">
                <h4>LOADING</h4>
                <span className="k-icon k-i-loading" />
              </div>
            )
          };
        });

        resolve(data);
      }, 1000);
    });
  };

  fetchChildren = task => {
    let dataSet = [];
    let totalCount = 0;
    if (task.Resources && task.Resources.length > 0) {
      dataSet = task.Resources;
      totalCount = task.TotalCount;
    }
    return (
      <div>
        {/*  WILL NEED LATER AFTER IMPLEMENTING TIME SHEET ENTRY
                       <span className="badge badge-prjname ml-2 px-1 py-1" id="billableTooltip">B
                                <Tooltip
                                            placement="top"
                                            isOpen={this.state.billableTooltipOpen}
                                            target="billableTooltip"
                                            toggle={this.toggleBillableToolTip(task)}
                                        >
                                            Billable
                                        </Tooltip>
                                    </span>

                                    <span className="badge badge-prjname ml-2 px-1 py-1" id="nonBillableTooltip">N
                                    <Tooltip
                                            placement="top"
                                            isOpen={this.state.nonBillableTooltipOpen}
                                            target="nonBillableTooltip"
                                            toggle={this.toggleNonBillableToolTip}
                                        >
                                            Non-Billable
                                        </Tooltip>
                                    </span> */}
        <div className="row mt-2 mx-2">
          <div className="col-md-12">
            <ProjectTaskGrid
              data={dataSet}
              onClickResourceView={this.onClickResourceView}
              projectId={task.ProjectId}
              totalCount={totalCount}
              task={task}
              taskdetail={task.taskdetail}
              loadResourceAllocation={this.loadResourceAllocation}
            />
          </div>
        </div>

        <div className="col-md-12 py-2">
          <div className="row">
            <div className="col-md-12 mb-2 btn-align-right">
              <Button
                className="k-button k-primary mr-1"
                type="button"
                disabled={task.isDisableDuplicateTaskBtn || !task.isProjectManager}
                onClick={() => this.toggleDuplicateTaskDialog(task)}
              >
                Duplicate Task
              </Button>
              {/* toggleDuplicateTaskDialog */}
              <Button
                className="k-button k-primary mr-1"
                type="button"
                disabled={task.isDisableEditTaskBtn || !task.isProjectManager}
                onClick={() => this.onEditTaskButtonClick(task)}
              >
                Edit Task
              </Button>
              <Button
                className="k-button k-primary mr-1"
                type="button"
                disabled={
                  task.isDisableEditTaskBtn || !task.isProjectManager
                }
                onClick={() => {
                  this.setState({ sFormDirty: false });
                  this.toggleAddEditResc(task);
                }}
              >
                Edit Resources
              </Button>
              <Button
                className="k-button"
                type="button"
                disabled={task.isDisableDeleteBtn || !task.isProjectManager}
                onClick={() => this.onDeleteTaskButtonClick(task.id)}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  populateProjectCMS = async () => {
    let cmsProjArray = [];
    let cmsCRArray = [];
    if (this.project) {
      this.project.CMSheets.forEach(cms => {
        if (!cms.IsCRCMS) {
          cmsProjArray.push({
            projId: this.project.id,
            crId: null,
            id: cms.id,
            name: this.project.ProjectName,
            Status: cms.Status,
            Version: cms.Version
          });
        }
      });

      if (this.project.ChangeRequests && this.project.ChangeRequests.length > 0) {
        this.project.ChangeRequests.forEach(cr => {
          cr.CMSheets.forEach(crCM => {
            cmsCRArray.push({
              projId: this.project.id,
              crId: cr.id,
              id: crCM.id,
              name: cr.CRName,
              Status: crCM.Status,
              Version: crCM.Version
            });
          });
        });
      }
    }
    this.setState({
      projectCMS: cmsProjArray,
      crCMS: cmsCRArray
    });
  };

  isValidDuplicateResourceDate = () => {
    let validResources = [];
    let invalidResources = [];
    if (this.state.taskStartdate && this.state.taskEnddate) {
      this.state.duplicateTaskResources.forEach(selectedResource => {
        const resourceStartDate = moment(selectedResource.StartDate).format('MM/DD/YYYY');
        const resourceEndDate =  moment(selectedResource.EndDate).format('MM/DD/YYYY');
        const seletedStartDate = moment(this.state.taskStartdate).format('MM/DD/YYYY'); 
        const seletedEndDate = moment(this.state.taskEnddate).format('MM/DD/YYYY');
       
        if (
          new Date(resourceStartDate) > new Date(seletedEndDate) ||
          new Date(resourceEndDate) < new Date(seletedStartDate) 
        ) {
          invalidResources.push(selectedResource);
        } else {
          validResources.push(selectedResource);
        }
      });
    }
    return { valid: validResources, invalid: invalidResources };
  };

  validateResourcesOnDateChange = () => {
    let validResources = [];
    let invalidResources = [];
    if (this.state.taskStartdate && this.state.taskEnddate) {
      this.state.allDuplicateTaskResources.forEach(selectedResource => {
        const resourceStartDate = moment(selectedResource.StartDate).format('MM/DD/YYYY');
        const resourceEndDate =  moment(selectedResource.EndDate).format('MM/DD/YYYY');
        const seletedStartDate = moment(this.state.taskStartdate).format('MM/DD/YYYY'); 
        const seletedEndDate = moment(this.state.taskEnddate).format('MM/DD/YYYY');
       
        if (
          new Date(resourceStartDate) > new Date(seletedEndDate) ||
          new Date(resourceEndDate) < new Date(seletedStartDate) 
        ) {
          invalidResources.push(selectedResource);
        } else {
          validResources.push(selectedResource);
        }
      });
    }
    return { valid: validResources, invalid: invalidResources };
  };

  saveDuplicateResourceAllocations = async(resourceData, task) => {
    const userDetails = window.LOGGED_USER;
    let modifiedUser;
    if (userDetails && userDetails.displayName !== null) {
      modifiedUser = userDetails.displayName;
    }

    let projectAllocations = [];
    for (const selectedResource of resourceData.valid){
      let rStartDate = moment(selectedResource.StartDate ).format('MM/DD/YYYY');;
      let rEndDate = moment(selectedResource.EndDate).format('MM/DD/YYYY');;
      let taskStartDate = moment(task.StartDate).format('MM/DD/YYYY');;
      let taskEndDate = moment(task.EndDate).format('MM/DD/YYYY');;

      let confirmedStartDate = null;
      let confirmedEndDate = null;

      if (new Date(taskStartDate) >= new Date(rStartDate) && new Date(taskEndDate) <= new Date(rEndDate)) {
          //RR start date and end date is out of range
          confirmedStartDate = taskStartDate;
          confirmedEndDate = taskEndDate;
      } else if(new Date(taskStartDate) <= new Date(rStartDate) && new Date(taskEndDate) >= new Date(rEndDate)) {
          //RR start date and end date is in range
          confirmedStartDate = rStartDate;
          confirmedEndDate = rEndDate;
      } else if((new Date(taskStartDate) >= new Date(rStartDate) && new Date(taskStartDate) <= new Date(rEndDate)) && new Date(taskEndDate) >= new Date(rEndDate)) {
          //RR start date out of range and end date is in range
          confirmedStartDate = taskStartDate;
          confirmedEndDate = rEndDate;
      } else if((new Date(taskEndDate) >= new Date(rStartDate) && new Date(taskEndDate) <= new Date(rEndDate)) && new Date(taskStartDate) <= new Date(rStartDate)) {
          //RR start date is in range and end date is out of range
          confirmedStartDate = rStartDate;
          confirmedEndDate = taskEndDate;
      }

      const projectAllocation = {
        Name: selectedResource.Name,
        ProjectTaskId: task ? task.id : null,
        ResourceAllocationId: selectedResource.ResourceAllocationid,
        ResourceAllocationSublineId: selectedResource.ResourceAllocationSublineId,
        ResourceId: selectedResource.ResourceId,
        StartDate: moment(new Date(confirmedStartDate)).format('MM/DD/YYYY'),
        EndDate: moment(new Date(confirmedEndDate)).format('MM/DD/YYYY'),
        ShiftFrom: this.state.shiftstartTime, // check with randira
        ShiftTo: this.state.shiftendTime,
        IsIncremental: selectedResource.IsIncremental,
        updatedUser: modifiedUser,
        IsActive: true,
        BillabilityTypeId:selectedResource.TotalRevenue > 0 ? 1 : 2,
        UserId: selectedResource.UserId,
        details: []
      };

      if (task.ProjectType === 'Project') {
        let result = this.state.allProjects.filter(obj => obj.id === task.ProjectId);
        if (result && result.length > 0) {
          await this.setState({
            ProjectOrCrName: result[0].ProjectName
          });
        }
      } else if (task.ProjectType === 'ChangeRequest') {
        let result = this.state.CRsOfAProject.filter(obj => obj.id === task.CRId);
        if (result && result.length > 0) {
          await this.setState({
            ProjectOrCrName: result[0].CRName
          });
        }
      }
      
      const resourceDetails = {
        projectName: this.state.ProjectOrCrName,
        Email: selectedResource.Email,
        Name: selectedResource.Name
      };
      projectAllocation.details.push(resourceDetails);

      projectAllocations.push(projectAllocation);
    };

    await saveResource(projectAllocations).then(async res => {
    })
    .catch(error => {
      const message = error.response.data;
      const title = 'Error';

      this.toggleMessageDialog(message, title);
    });
  }

  handleSubmitDuplicateTask = async event => {
    event.preventDefault();
    if (!this.validation()) {
      const message = `Please fix the highlighted errors to continue`;
      const title = 'Error';
      this.setState({
        isErrorMessageVisible: true
      });
      this.toggleMessageDialog(message, title);
    } else if (
      !this.state.isTaskUpdate &&
      this.duplicateTaskName(this.state.selectedProjectOrCr.id, this.state.taskName)
    ) {
      const message = `Task name already exists`;
      const title = 'Error';
      this.toggleMessageDialog(message, title);
    } else if (new Date(moment(this.state.taskStartdate).format('MM/DD/YYYY')) > new Date(moment(this.state.taskEnddate).format('MM/DD/YYYY'))) {
      const message = `The Task End Date cannot be earlier than the Start Date.`;
      const title = 'Error';
      this.toggleMessageDialog(message, title);
    } else if (new Date(moment(this.state.selectedProjectOrCr.StartDate).format('MM/DD/YYYY')) > new Date(moment(this.state.taskStartdate).format('MM/DD/YYYY'))) {
      const message = `The task Start Date cannot be earlier than the Project/CR Start Date.`;
      const title = 'Error';
      this.toggleMessageDialog(message, title);
    } else {
      if (this.isMount) {
        this.setState(
          {
            disabledSave: true,
            loading: true
          },
          async () => {
            const userDetails = window.LOGGED_USER;
            let modifiedUser;
            let billabilityStatus;
            if (userDetails && userDetails.displayName !== null) {
              modifiedUser = userDetails.displayName;
            }
  
            if (this.state.billableStatus === 'billable') {
              billabilityStatus = 1;
            }
            if (this.state.billableStatus === 'nonbillable') {
              billabilityStatus = 2;
            }
            if (this.state.billableStatus === 'both') {
              billabilityStatus = 3;
            }
  
            const taskObj = {
              Name: this.state.taskName,
              ProjectId: this.project.id,
              CRId:
                this.state.selectedProjectOrCr.Category == 'ChangeRequest'
                  ? this.state.selectedProjectOrCr.id
                  : null,
              ProjectType:
                this.state.selectedProjectOrCr.Category == 'ChangeRequest'
                  ? 'ChangeRequest'
                  : 'Project',
              TaskTypeId: this.state.selectedTask.id,
              BillabilityTypeId: billabilityStatus,
              StartDate: moment(this.state.taskStartdate).format('MM/DD/YYYY'),
              EndDate: moment(this.state.taskEnddate).format('MM/DD/YYYY'),
              updatedUser: modifiedUser,
              IsActive: true,
              IsPresales: false,
              OPID: this.state.selectedProjectOrCr.OPID
            };

            let resourceData = await this.isValidDuplicateResourceDate();
  
            await saveProjectTask(taskObj)
              .then(async res => {
                const newTask = res.data;
                await this.saveDuplicateResourceAllocations(resourceData, newTask);

                if (resourceData.invalid.length > 0) {
                  const message = `The specified Start and End Date is not within the duration of the task or the resource allocation period for the following allocations:`;
                  const title = 'Partial Success';
                  this.setState({
                    failedResources: resourceData.invalid,
                    isErrorMessageVisible: true,
                    showSuccessImage: true
                  });
                  this.toggleMessageDialog(message, title);
                } else {
                  let message = 'Task duplication successful with the resources matching the task duration.';
                  const title = 'Success';
                  if (resourceData && resourceData.valid.length == 0) {
                    message = 'Task duplication successful.';
                  }
                  this.setState({
                    showSuccessImage: true
                  });
                  this.toggleMessageDialog(message, title);
                }
                this.setState({
                  duplicateTask: !this.state.duplicateTask,
                  isTimeEntryAvailable: null,
                  loading: false,
                  isFormDirty: false,
                  isduplicateTask: false
                });
                await this.loadProjectandTask(this.project.id);
                const auditTrail = {
                  Action: 'Duplicate task',
                  RefType: 'Project Task Management',
                  RefId: taskObj.Name,
                  RefName: taskObj.OPID + ' - ' + taskObj.Name
                };
                saveAuditTrail(auditTrail);
              })
              .catch(error => {
                const message = error.response.data;
                const title = 'Error';
                this.toggleMessageDialog(message, title);
                this.setState({
                  duplicateTask: !this.state.duplicateTask
                });
              });
          }
        );
      }
    }
  };

  handleSubmitMultiTaskAssignment = async event => {
    event.preventDefault();
    if (!this.validateMultiTask()) {
      const message = `Please fix the highlighted errors to continue`;
      const title = 'Error';
      this.setState({
        showErrorImage: true,
        isErrorMessageVisible: true
      });
      this.toggleMessageDialog(message, title);
    } else if ((this.state.multiTaskStartDate && !this.state.multiTaskEndDate) || (this.state.multiTaskEndDate && !this.state.multiTaskStartDate)) {
      const message = `Both dates must be entered if a task assignment duration is being set. To assign the resources to the tasks for the maximum durations possible, leave the date fields blank.`;
      const title = 'Error';
      this.setState({
        showErrorImage: true,
        isErrorMessageVisibleForDates: true
      });
      this.toggleMessageDialog(message, title);
    } else if (this.state.multiTaskStartDate && this.state.multiTaskEndDate && (
      new Date(moment(new Date(this.state.multiTaskStartDate)).format('MM/DD/YYYY')) > new Date(moment(new Date (this.state.multiTaskEndDate)).format('MM/DD/YYYY'))
    )) {
      const message = `The End Date cannot be earlier than the Start Date.`;
      const title = 'Error';
      this.setState({
        showErrorImage: true,
        isErrorMessageVisibleForDates: true
      });
      this.toggleMessageDialog(message, title);
    } else if (this.state.multiTaskStartDate && this.state.multiTaskEndDate && (
      new Date(moment(new Date(this.state.multiTaskStartDate)).format('MM/DD/YYYY')) < new Date(moment(new Date (this.state.multiTaskDateMin)).format('MM/DD/YYYY')) || 
      new Date(moment(new Date(this.state.multiTaskEndDate)).format('MM/DD/YYYY')) > new Date(moment(new Date (this.state.multiTaskDateMax)).format('MM/DD/YYYY'))
      )) {
      const message = `The start or end date does not fall within the durations of the tasks.`;
      const title = 'Error';
      this.setState({
        showErrorImage: true,
        isErrorMessageVisibleForDates: true
      });
      this.toggleMessageDialog(message, title);
    } else {
      await this.setState({
        isDisableMultiTaskSaveBtn: true,
        loading: true        
      },
      async () => {
        if (this.state.multiTaskStartDate && this.state.multiTaskEndDate) {
          await this.validateMultiTaskResourceAllocationsWithDates();
        } else {
          await this.validateMultiTaskResourceAllocations();
        }

        await this.setState({
          loading: false,
          isDisableMultiTaskSaveBtn: false
        });      
      });
    }
  }

  saveMultiTaskResourceAllocations = async() => {
    await saveResource(this.state.multiTaskAllocations).then(async res => {
      const message = `The resource allocations matching the task durations have been assigned.`;
      const title = 'Success';
      this.setState({
        showSuccessImage: true
      });
      this.toggleMessageDialog(message, title);
      await this.loadProjectandTask(this.project.id);

      await this.setState({
        isDisableMultiTaskSaveBtn: false,
        isFormDirty: false,
        multiTaskResourcesDialog: !this.state.multiTaskResourcesDialog,
        loading: false,
        confirmMultiTaskAllocationVisible: false,
        isErrorMessageVisibleForDates: false       
      });  
    })
    .catch(error => {
      const message = error.response.data;
      const title = 'Error';
      this.toggleMessageDialog(message, title);
    });
  }

  validateMultiTaskResourceAllocationsWithDates = async() => {
    let projectAllocations = [];
    let invalidResources = [];
    let invalidTasks = [];

    const userDetails = window.LOGGED_USER;
    let modifiedUser;
    if (userDetails && userDetails.displayName !== null) {
      modifiedUser = userDetails.displayName;
    }

    for (const resource of this.state.selectedProjOrCrResources) {
      let invalidResCount = 0;
      for (const task of this.state.selectedProjOrCrTasks) {
        const resourceStartDate = moment(resource.StartDate).format('MM/DD/YYYY');
        const resourceEndDate =  moment(resource.EndDate).format('MM/DD/YYYY');
        const allocStartDate = moment(this.state.multiTaskStartDate).format('MM/DD/YYYY'); 
        const allocEndDate = moment(this.state.multiTaskEndDate).format('MM/DD/YYYY');
        const taskStartDate = moment(task.StartDate).format('MM/DD/YYYY'); 
        const taskEndDate = moment(task.EndDate).format('MM/DD/YYYY');

        if (
          new Date(resourceStartDate) > new Date(allocEndDate) ||
          new Date(resourceEndDate) < new Date(allocStartDate) || 
          new Date(resourceStartDate) > new Date(taskEndDate) ||
          new Date(resourceEndDate) < new Date(taskStartDate) ||
          new Date(taskStartDate) > new Date(allocEndDate) ||
          new Date(taskEndDate) < new Date(allocStartDate)
        ) {
          invalidResCount++;
        } else {
          let confirmedStartDate = null;
          let confirmedEndDate = null;

          // compare selected dates with RR dates
          if (new Date(allocStartDate) >= new Date(resourceStartDate) && new Date(allocEndDate) <= new Date(resourceEndDate)) {
              //RR start date and end date is out of range
              confirmedStartDate = allocStartDate;
              confirmedEndDate = allocEndDate;
          } else if(new Date(allocStartDate) <= new Date(resourceStartDate) && new Date(allocEndDate) >= new Date(resourceEndDate)) {
              //RR start date and end date is in range
              confirmedStartDate = resourceStartDate;
              confirmedEndDate = resourceEndDate;
          } else if((new Date(allocStartDate) >= new Date(resourceStartDate) && new Date(allocStartDate) <= new Date(resourceEndDate)) && new Date(allocEndDate) >= new Date(resourceEndDate)) {
              //RR start date out of range and end date is in range
              confirmedStartDate = allocStartDate;
              confirmedEndDate = resourceEndDate;
          } else if((new Date(allocEndDate) >= new Date(resourceStartDate) && new Date(allocEndDate) <= new Date(resourceEndDate)) && new Date(allocStartDate) <= new Date(resourceStartDate)) {
              //RR start date is in range and end date is out of range
              confirmedStartDate = resourceStartDate;
              confirmedEndDate = allocEndDate;
          }

          // compare confirmed dates with task dates
          if (new Date(taskStartDate) >= new Date(confirmedStartDate) && new Date(taskEndDate) <= new Date(confirmedEndDate)) {
            //confirmed start date and end date is out of range
            confirmedStartDate = taskStartDate;
            confirmedEndDate = taskEndDate;
          } else if(new Date(taskStartDate) <= new Date(confirmedStartDate) && new Date(taskEndDate) >= new Date(confirmedEndDate)) {
              //confirmed start date and end date is in range
              confirmedStartDate = confirmedStartDate;
              confirmedEndDate = confirmedEndDate;
          } else if((new Date(taskStartDate) >= new Date(confirmedStartDate) && new Date(taskStartDate) <= new Date(confirmedEndDate)) && new Date(taskEndDate) >= new Date(confirmedEndDate)) {
              //confirmed start date out of range and end date is in range
              confirmedStartDate = taskStartDate;
              confirmedEndDate = confirmedEndDate;
          } else if((new Date(taskEndDate) >= new Date(confirmedStartDate) && new Date(taskEndDate) <= new Date(confirmedEndDate)) && new Date(taskStartDate) <= new Date(confirmedStartDate)) {
              //confirmed start date is in range and end date is out of range
              confirmedStartDate = confirmedStartDate;
              confirmedEndDate = taskEndDate;
          }

          const projectAllocation = {
            Name: resource.Name,
            ProjectTaskId: task ? task.id : null,
            ResourceAllocationId: resource.ResourceAllocationid,
            ResourceAllocationSublineId: resource.ResourceAllocationSublineId,
            ResourceId: resource.ResourceId,
            StartDate: moment(new Date(confirmedStartDate)).format('MM/DD/YYYY'),
            EndDate: moment(new Date(confirmedEndDate)).format('MM/DD/YYYY'),
            ShiftFrom: null,
            ShiftTo: null,
            IsIncremental: null,
            updatedUser: modifiedUser,
            IsActive: true,
            BillabilityTypeId: resource.TotalRevenue > 0 ? 1 : 2,
            UserId: resource.UserId,
            details: []
          };
  
          if (task.ProjectType === 'Project') {
            let result = this.state.allProjects.filter(obj => obj.id === task.ProjectId);
            if (result && result.length > 0) {
              await this.setState({
                ProjectOrCrName: result[0].ProjectName
              });
            }
          } else if (task.ProjectType === 'ChangeRequest') {
            let result = this.state.CRsOfAProject.filter(obj => obj.id === task.CRId);
            if (result && result.length > 0) {
              await this.setState({
                ProjectOrCrName: result[0].CRName
              });
            }
          }
          
          const resourceDetails = {
            projectName: this.state.ProjectOrCrName,
            Email: resource.Email,
            Name: resource.Name
          };
          projectAllocation.details.push(resourceDetails);
          projectAllocations.push(projectAllocation);
        }
      }
      if (invalidResCount == this.state.selectedProjOrCrTasks.length) {
        invalidResources.push(resource);
      }
    }
    if (projectAllocations && projectAllocations.length > 0) {
      for (const task of this.state.selectedProjOrCrTasks) {
        const allocationsForTask = projectAllocations.filter(obj => obj.ProjectTaskId === task.id);
        if (allocationsForTask.length <= 0) {
          invalidTasks.push(task);
        }
      }

      await this.setState({
        multiTaskAllocations: projectAllocations,
        invalidMultiTasks: invalidTasks,
        invalidMultiTaskResources: invalidResources
      });

      if (invalidResources.length > 0 || invalidTasks.length > 0) {
        this.toggleConfirmMultiTaskAllocationDialog();
      } else {
        this.saveMultiTaskResourceAllocations();
      }

    } else {
      const message = `No resource allocations match the task durations. Please ensure resource allocation durations correspond with task durations and try again.`;
        const title = 'Error';
        this.setState({
          isDisableMultiTaskSaveBtn: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
    }
  }

  validateMultiTaskResourceAllocations = async() => {
    let invalidResources = [];
    let projectAllocations = [];
    let invalidTasks = [];

    const userDetails = window.LOGGED_USER;
    let modifiedUser;
    if (userDetails && userDetails.displayName !== null) {
      modifiedUser = userDetails.displayName;
    }

    for (const resource of this.state.selectedProjOrCrResources) {
      let invalidResCount = 0;
      for (const task of this.state.selectedProjOrCrTasks) {
        const resourceStartDate = moment(resource.StartDate).format('MM/DD/YYYY');
        const resourceEndDate =  moment(resource.EndDate).format('MM/DD/YYYY');
        const taskStartDate = moment(task.StartDate).format('MM/DD/YYYY'); 
        const taskEndDate = moment(task.EndDate).format('MM/DD/YYYY');
       
        if (
          new Date(resourceStartDate) > new Date(taskEndDate) ||
          new Date(resourceEndDate) < new Date(taskStartDate) 
        ) {
          invalidResCount++;
        } else {
          let confirmedStartDate = null;
          let confirmedEndDate = null;

          if (new Date(taskStartDate) >= new Date(resourceStartDate) && new Date(taskEndDate) <= new Date(resourceEndDate)) {
              //RR start date and end date is out of range
              confirmedStartDate = taskStartDate;
              confirmedEndDate = taskEndDate;
          } else if(new Date(taskStartDate) <= new Date(resourceStartDate) && new Date(taskEndDate) >= new Date(resourceEndDate)) {
              //RR start date and end date is in range
              confirmedStartDate = resourceStartDate;
              confirmedEndDate = resourceEndDate;
          } else if((new Date(taskStartDate) >= new Date(resourceStartDate) && new Date(taskStartDate) <= new Date(resourceEndDate)) && new Date(taskEndDate) >= new Date(resourceEndDate)) {
              //RR start date out of range and end date is in range
              confirmedStartDate = taskStartDate;
              confirmedEndDate = resourceEndDate;
          } else if((new Date(taskEndDate) >= new Date(resourceStartDate) && new Date(taskEndDate) <= new Date(resourceEndDate)) && new Date(taskStartDate) <= new Date(resourceStartDate)) {
              //RR start date is in range and end date is out of range
              confirmedStartDate = resourceStartDate;
              confirmedEndDate = taskEndDate;
          }

          const projectAllocation = {
            Name: resource.Name,
            ProjectTaskId: task ? task.id : null,
            ResourceAllocationId: resource.ResourceAllocationid,
            ResourceAllocationSublineId: resource.ResourceAllocationSublineId,
            ResourceId: resource.ResourceId,
            StartDate: moment(new Date(confirmedStartDate)).format('MM/DD/YYYY'),
            EndDate: moment(new Date(confirmedEndDate)).format('MM/DD/YYYY'),
            ShiftFrom: null,
            ShiftTo: null,
            IsIncremental: null,
            updatedUser: modifiedUser,
            IsActive: true,
            BillabilityTypeId: resource.TotalRevenue > 0 ? 1 : 2,
            UserId: resource.UserId,
            details: []
          };

          if (task.ProjectType === 'Project') {
            let result = this.state.allProjects.filter(obj => obj.id === task.ProjectId);
            if (result && result.length > 0) {
              await this.setState({
                ProjectOrCrName: result[0].ProjectName
              });
            }
          } else if (task.ProjectType === 'ChangeRequest') {
            let result = this.state.CRsOfAProject.filter(obj => obj.id === task.CRId);
            if (result && result.length > 0) {
              await this.setState({
                ProjectOrCrName: result[0].CRName
              });
            }
          }
          
          const resourceDetails = {
            projectName: this.state.ProjectOrCrName,
            Email: resource.Email,
            Name: resource.Name
          };
          projectAllocation.details.push(resourceDetails);
          projectAllocations.push(projectAllocation);
        }
      }
      if (invalidResCount == this.state.selectedProjOrCrTasks.length) {
        invalidResources.push(resource);
      }
    }
    if (projectAllocations && projectAllocations.length > 0) {
      for (const task of this.state.selectedProjOrCrTasks) {
        const allocationsForTask = projectAllocations.filter(obj => obj.ProjectTaskId === task.id);
        if (allocationsForTask.length <= 0) {
          invalidTasks.push(task);
        }
      }

      await this.setState({
        multiTaskAllocations: projectAllocations,
        invalidMultiTasks: invalidTasks,
        invalidMultiTaskResources: invalidResources
      });

      if (invalidResources.length > 0 || invalidTasks.length > 0) {
        this.toggleConfirmMultiTaskAllocationDialog();
      } else {
        this.saveMultiTaskResourceAllocations();
      }

    } else {
      const message = `No resource allocations match the task durations. Please ensure resource allocation durations correspond with task durations and try again.`;
        const title = 'Error';
        this.setState({
          isDisableMultiTaskSaveBtn: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    if (!this.validation()) {
      const message = `Please fix the highlighted errors to continue`;
      const title = 'Error';
      this.setState({
        isErrorMessageVisible: true
      });
      this.toggleMessageDialog(message, title);
    } else if (
      !this.state.isTaskUpdate &&
      this.duplicateTaskName(this.state.selectedProjectOrCr.id, this.state.taskName)
    ) {
      const message = `Task name already exists`;
      const title = 'Error';
      this.toggleMessageDialog(message, title);
    } else {
      if (this.isMount) {
        this.setState(
          {
            disabledSave: true,
            loading: true
          },
          () => {
            const userDetails = window.LOGGED_USER;
            let modifiedUser;
            let billabilityStatus;
            if (userDetails && userDetails.displayName !== null) {
              modifiedUser = userDetails.displayName;
            }

            if (this.state.billableStatus === 'billable') {
              billabilityStatus = 1;
            }
            if (this.state.billableStatus === 'nonbillable') {
              billabilityStatus = 2;
            }
            if (this.state.billableStatus === 'both') {
              billabilityStatus = 3;
            }

            const taskObj = {
              Name: this.state.taskName,
              ProjectId: this.project.id,
              CRId:
                this.state.selectedProjectOrCr.Category == 'ChangeRequest'
                  ? this.state.selectedProjectOrCr.id
                  : null,
              ProjectType:
                this.state.selectedProjectOrCr.Category == 'ChangeRequest'
                  ? 'ChangeRequest'
                  : 'Project',
              TaskTypeId: this.state.selectedTask.id,
              BillabilityTypeId: billabilityStatus,
              StartDate: moment(this.state.taskStartdate).format('MM/DD/YYYY'),
              EndDate: moment(this.state.taskEnddate).format('MM/DD/YYYY'),
              updatedUser: modifiedUser,
              IsActive: true,
              IsPresales: false,
              OPID: this.state.selectedProjectOrCr.OPID
            };
            if (!this.state.isTaskUpdate) {
              saveProjectTask(taskObj)
                .then(async res => {
                  const message = `${this.state.taskName} is successfully created`;
                  const title = 'Success';
                  //this.populateAllProjectTasks();
                  await this.loadProjectandTask(this.project.id);
                  this.toggleMessageDialog(message, title);
                  if (this.state.isduplicateTask) {
                    const auditTrail = {
                      Action: 'Duplicate task',
                      RefType: 'Project Task Management',
                      RefId: taskObj.Name,
                      RefName: taskObj.OPID + ' - ' + taskObj.Name
                    };
                    saveAuditTrail(auditTrail);
                  } else {
                    const auditTrail = {
                      Action: 'Create new project task',
                      RefType: 'Project Task Management',
                      RefId: taskObj.Name,
                      RefName: taskObj.OPID + ' - ' + taskObj.Name
                    };
                    saveAuditTrail(auditTrail);
                  }
                  this.setState({
                    addEditTaskDialog: !this.state.addEditTaskDialog,
                    isTimeEntryAvailable: null,
                    loading: false,
                    isFormDirty: false,
                    isduplicateTask: false
                  });
                })
                .catch(error => {
                  const message = error.response.data;
                  const title = 'Error';
                  this.toggleMessageDialog(message, title);
                });
            } else {
              taskObj['id'] = this.state.taskId;
              updateTask(taskObj)
                .then(async res => {
                  const message = `${this.state.taskName} is successfully updated`;
                  const title = 'Success';
                  await this.loadProjectandTask(this.project.id);
                  this.toggleMessageDialog(message, title);
                  this.setState({
                    addEditTaskDialog: !this.state.addEditTaskDialog,
                    isTimeEntryAvailable: null,
                    isTaskUpdate: false,
                    isFormDirty: false,
                    loading: false
                  });
                })
                .catch(error => {
                  const message = error.response.data;
                  const title = 'Error';
                  this.toggleMessageDialog(message, title);
                });
            }
          }
        );
      }
    }
  };

  validateAddResources = () => {
    if (
      this.validateProperty(this.state.selectedResource)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.projAllocStartDate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.projAllocEndDate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      (this.state.shiftstartTime && !this.state.shiftendTime) ||
      (this.state.shiftendTime && !this.state.shiftstartTime)
    ) {
      return false;
    } else {
      return true;
    }
  };

  isValidResourceDate = () => {
    let validResources = [];
    let invalidResources = [];
    if (this.state.projAllocStartDate && this.state.projAllocEndDate) {
      this.state.selectedResource.forEach(selectedResource => {
        const resourceStartDate = moment(selectedResource.StartDate).format('MM/DD/YYYY');
        const resourceEndDate =  moment(selectedResource.EndDate).format('MM/DD/YYYY');
        const seletedStartDate = moment(this.state.projAllocStartDate).format('MM/DD/YYYY'); 
        const seletedEndDate = moment(this.state.projAllocEndDate).format('MM/DD/YYYY');
        let rsStartDate =  new Date([moment(resourceStartDate).format('YYYY'),moment(resourceStartDate).format('M'), moment(resourceStartDate).format('D')].join('/'));
        let rsEndDate =  new Date([moment(resourceEndDate).format('YYYY'),moment(resourceEndDate).format('M'), moment(resourceEndDate).format('D')].join('/'));
        let sStartDate =  new Date([moment(seletedStartDate).format('YYYY'),moment(seletedStartDate).format('M'), moment(seletedStartDate).format('D')].join('/'));
        let sEndDate =  new Date([moment(seletedEndDate).format('YYYY'),moment(seletedEndDate).format('M'), moment(seletedEndDate).format('D')].join('/'));
       
        if (
          rsStartDate &&
          rsEndDate &&
          (sStartDate < rsStartDate || rsEndDate < sEndDate)
        ) {
          invalidResources.push(selectedResource);
        } else {
          validResources.push(selectedResource);
        }
      });
    }
    return { valid: validResources, invalid: invalidResources };
  };

  handleSaveResource = async event => {
    await this.setState({
      isDisableAddBtnAddResc: true
    });
    let resourceData = this.isValidResourceDate();
    if (!this.validateAddResources()) {
      const message = `Please fix the highlighted errors to continue`;
      const title = 'Error';
      this.setState({
        isErrorMessageVisible: true,
        isDisableAddBtnAddResc: false
      });
      this.toggleMessageDialog(message, title);
    } else if (resourceData.valid.length == 0) {
      const message = `The specified Start and End Date is not within the duration of the task or the resource allocation period for the following allocations:`;
      const title = 'Error';
      this.setState({
        failedResources: resourceData.invalid,
        isErrorMessageVisible: true,
        showErrorImage: true,
        isDisableAddBtnAddResc: false
      });
      this.toggleMessageDialog(message, title);
    } else {
      event.preventDefault();
      //validate fields here
      if (this.isMount) {
        const userDetails = window.LOGGED_USER;
        let modifiedUser;
        if (userDetails && userDetails.displayName !== null) {
          modifiedUser = userDetails.displayName;
        }

        let projectAllocations = [];
        resourceData.valid.forEach(selectedResource => {
          const projectAllocation = {
            Name: selectedResource.Name,
            ProjectTaskId: this.state.currentChildTask ? this.state.currentChildTask.id : null,
            ResourceAllocationId: selectedResource.ResourceAllocationid,
            ResourceAllocationSublineId: selectedResource.ResourceAllocationSublineId,
            ResourceId: selectedResource.ResourceId,
            StartDate: moment(this.state.projAllocStartDate).format('MM/DD/YYYY'),
            EndDate: moment(this.state.projAllocEndDate).format('MM/DD/YYYY'),
            ShiftFrom: this.state.shiftstartTime,
            ShiftTo: this.state.shiftendTime,
            IsIncremental: this.state.incrementalBilling,
            updatedUser: modifiedUser,
            IsActive: true,
            BillabilityTypeId:selectedResource.TotalRevenue > 0 ? 1 : 2,
            UserId: selectedResource.UserId,
            details: []
          };

          const resourceDetails = {
            projectName: this.state.ProjectOrCrName,
            Email: selectedResource.Email,
            Name: selectedResource.Name
          };
          projectAllocation.details.push(resourceDetails);

          projectAllocations.push(projectAllocation);
        });

        if (this.state.selectedResourceId == null) {
          await saveResource(projectAllocations)
            .then(async res => {
              if (resourceData.invalid.length > 0) {
                const message = `The specified Start and End Date is not within the duration of the task or the resource allocation period for the following allocations:`;
                const title = 'Partial Success';
                this.setState({
                  failedResources: resourceData.invalid,
                  isErrorMessageVisible: true,
                  showSuccessImage: true
                });
                this.toggleMessageDialog(message, title);
              } else {
                const message = `The resource(s) ${this.state.selectedResource
                  .map(i => {
                    return i.Name;
                  })
                  .join(', ')} has been successfully added to the project task`;
                const title = 'Success';
                this.toggleMessageDialog(message, title);
              }

              this.setState({
                selectedResource: null,
                resourceBillability: ' ',
                projAllocStartDate: null,
                projAllocEndDate: null,
                shiftstartTime: null,
                shiftendTime: null,
                incrementalBilling: false
              });
              await this.loadTimeEntryData(this.state.currentlySelectedTaskId);
              await this.clearAddResourceFields();
            })
            .catch(error => {
              const message = error.response.data;
              const title = 'Error';

              this.toggleMessageDialog(message, title);
            });
        } else {
          projectAllocations[0]['id'] = this.state.selectedResourceId;
          await updateResource(projectAllocations[0])
            .then(async res => {
              const message = `The resource ${this.state.selectedResource
                .map(i => {
                  return i.Name;
                })
                .join(', ')} has been successfully updated`;
              const title = 'Success';
              this.toggleMessageDialog(message, title);
              //this.loadProjectandTask(this.project.id);

              this.setState({
                selectedResource: [],
                resourceBillability: ' ',
                projAllocStartDate: null,
                projAllocEndDate: null,
                shiftstartTime: null,
                shiftendTime: null,
                incrementalBilling: false
              });
              await this.loadTimeEntryData(this.state.currentlySelectedTaskId);
              await this.clearAddResourceFields();
            })
            .catch(error => {
              const message = error.response.data;
              const title = 'Error';

              this.toggleMessageDialog(message, title);
            });
        }
      }
    }
    await this.setState({
      isDisableAddBtnAddResc: false
    });
  };

  isPermissionAvailable = (accessLevels, type, projCRData) => {
    let hasAccess = false;
    if (window.LOGGED_USER && window.LOGGED_USER.userId && window.USER_PERMISSION) {
      if (accessLevels && accessLevels.length > 0) {
        for (const level of accessLevels) {
          if (!hasAccess) {
            hasAccess = window.USER_PERMISSION.includes(level);
          } else {
            break;
          }
        }
      }
      if (!hasAccess && projCRData && type) {
        if (type === 'project') {
          hasAccess = this.verifyProjectManager(projCRData);
        } else {
          hasAccess = this.verifyCRManager(projCRData);
        }
      }
    }
    return hasAccess;
  };

  setGlobalAccessLevels = async () => {
    const accessLevels = ['ADD_EDIT_TASKS'];
    let isProjectManager = false;
    let isCRProjectmanager = false;
    let crActivated = false;
    let projectActivated = false;
    let isProjManager = await this.verifyProjectManager(this.project);

    if (this.project && isProjManager) {
      isProjectManager = true;
    }

    if (this.project && this.project.StatusConvertedToActive) {
      projectActivated = true;
    }

    if (this.state.CRsOfAProject && this.state.CRsOfAProject.length > 0) {
      for (let item of this.state.CRsOfAProject) {
        let isCRManager = await this.verifyCRManager(item);
        if (item.StatusConvertedToActive) {
          crActivated = true;
        }
        if (isCRManager) {
          isCRProjectmanager = true;
        }
      }
    }

    this.setState({
      isProjectManagerCR: isCRProjectmanager,
      isProjectManager: isProjectManager,
      crActivated: crActivated,
      projectActivated: projectActivated
    });
  };

  setProjectDropdownData = async () => {
    let dropdownprojectcr = [];
    const accessLevels = ['ADD_EDIT_TASKS', 'VW_PRJTASKMNGMT'];
    let isProjManager = await this.verifyProjectManager(this.project);
    if (this.project && isProjManager && this.project.ConvertedToActiveAt) {
      const obj = {
        DisplayText: 'Project-' + this.project.ProjectName,
        id: this.project.id,
        Category: 'Project',
        BillableStatus: this.project.BillableStatus,
        StartDate: this.project.StartDate
          ? reStructureDate(this.project.StartDate)
          : this.project.EstimatedStartDate
          ? reStructureDate(this.project.EstimatedStartDate)
          : new Date(1900, 0, 1),
        EndDate: this.project.EndDate
          ? reStructureDate(this.project.EndDate)
          : this.project.UserDefinedEndDate
          ? reStructureDate(this.project.UserDefinedEndDate)
          : this.project.EstimatedEndDate
          ? reStructureDate(this.project.EstimatedEndDate)
          : new Date(2100, 0, 1),
        OPID: this.project.OPID
      };
      dropdownprojectcr.push(obj);
    }

    if (this.state.CRsOfAProject && this.state.CRsOfAProject.length > 0) {
      for (let item of this.state.CRsOfAProject) {
        let isCRManager = await this.verifyCRManager(item);

        if (isCRManager && item.ConvertedToActiveAt) {
          const object = {
            id: item.id,
            DisplayText: 'CR-' + item.CRName,
            Category: 'ChangeRequest',
            BillableStatus: item.BillableStatus,
            StartDate: item.StartDate
              ? reStructureDate(item.StartDate)
              : item.EstimatedStartDate
              ? reStructureDate(item.EstimatedStartDate)
              : new Date(1900, 0, 1),
            EndDate: item.EndDate
              ? reStructureDate(item.EndDate)
              : item.UserDefinedEndDate
              ? reStructureDate(item.UserDefinedEndDate)
              : item.EstimatedEndDate
              ? reStructureDate(item.EstimatedEndDate)
              : new Date(2100, 0, 1),
            OPID: item.OPID
          };
          dropdownprojectcr.push(object);
        }
      }
    }

    this.setState({
      projectEndDate: this.project.UserDefinedEndDate
        ? reStructureDate(this.project.UserDefinedEndDate)
        : null,
      dropdownprojectcr: dropdownprojectcr
    });
  };

  setStartDateMin = (projectCR, task) => {
    if (projectCR) {
      let startDate = projectCR.StartDate
        ? reStructureDate(projectCR.StartDate)
        : projectCR.EstimatedStartDate
        ? reStructureDate(projectCR.EstimatedStartDate)
        : new Date(1900, 0, 1);

      this.setState({
        startDateMin: startDate,
        startDateMinforEndDate: startDate
      });
    }
  };

  setEndDateMax = async (projectCR, task) => {
    if (projectCR) {
      let endDateMax = this.state.projectEndDate
        ? this.state.projectEndDate
        : projectCR.EndDate
        ? reStructureDate(projectCR.EndDate)
        : projectCR.UserDefinedEndDate
        ? reStructureDate(projectCR.UserDefinedEndDate)
        : projectCR.EstimatedEndDate
        ? reStructureDate(projectCR.EstimatedEndDate)
        : new Date(2040, 0, 1);
      let endDateMin = reStructureDate(Date.now());
      let startDateMax = endDateMax;
      let timeEntry = false;
      if (task && task.resourceEndDate && task.resourceStartDate) {
        startDateMax = reStructureDate(task.resourceStartDate);
        endDateMin =
          endDateMin > reStructureDate(task.resourceEndDate) ? endDateMin : reStructureDate(task.resourceEndDate);
        timeEntry = true;
      }

      this.setState({
        EndDateMin: endDateMin,
        EndDateMax: endDateMax,
        startDateMax: startDateMax,
        resourceExist: timeEntry
      });
    }
  };

  setStartDateMinAddResource = async task => {
    if (task) {
      let date = task.StartDate ?moment(task.StartDate).format('YYYY-MM-DD')  : moment(new Date()).format('YYYY-MM-DD');
      let month = moment(date).format('M')
      let day = moment(date).format('D')
      let year = moment(date).format('YYYY')
      let formatedendDate = [year,month, day].join('/');
      this.setState({
        startDateMinAddResource: reStructureDate(formatedendDate),
        startdateMinforEndDateAddResc: reStructureDate(formatedendDate)
      });
    }
  };

  setStartDateMinAddResourceTimeEntered = async (task, resource) => {
    if (task) {
      let date = task.EndDate ? moment(task.EndDate).format('YYYY-MM-DD') :  moment(new Date()).format('YYYY-MM-DD');
      date =
        resource.maxTimeEntered &&  moment(resource.maxTimeEntered).format('YYYY-MM-DD') < date
          ? moment(resource.maxTimeEntered).format('YYYY-MM-DD')
          : date;
      let month = moment(date).format('M')
      let day = moment(date).format('D')
      let year = moment(date).format('YYYY')
      let formatedendDate = [month, day, year].join('/');
      this.setState({
        startdateMinforEndDateAddResc: reStructureDate(formatedendDate)
      });
    }
  };

  setEndDateMaxAddResource = async task => {
    if (task) {
      let date = task.EndDate ?moment(task.EndDate).format('YYYY-MM-DD') : moment(new Date(2100, 0, 1)).format('YYYY-MM-DD');
      let month = moment(date).format('M')
      let day = moment(date).format('D')
      let year = moment(date).format('YYYY')
      let formatedendDate = [year,month, day].join('/');
      this.setState({
        EndDateMaxAddResource:  reStructureDate(formatedendDate),
        startDateMaxForProjAlloc:  reStructureDate(formatedendDate)
      });
    }
  };

  componentWillUnmount = () => {
    this.isMount = false;
  };

  populateCRsofProject = async () => {
    if (this.project && this.project.id) {
      await getCRsOfAProject(this.project.id)
        .then(res => {
          if (res && res.data && res.data.length > 0) {
            this.setState(
              {
                CRsOfAProject: res.data
              },
              () => {
                if (res.data.length === 1) {
                  this.setState({
                    selectedCRId: res.data[0].id
                  });
                }
              }
            );
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }
  };
  editCR = () => {
    if (this.state.ChangeRequestAction === 'CreateCMSforCR') {
      if (this.isMount) {
        this.setState({
          redirectToCreateCMS: true,
          selectedCMSReference: 'crCMS'
        });
      }
      return;
    }

    if (this.state.ChangeRequestAction === 'delete') {
      deleteChangeReq(this.state.selectedCRId)
        .then(res => {
          if (this.isMount) {
            this.setState({
              selectedCRId: null
            });
          }

          this.toggleMessageDialog('Successfully deleted', 'Success');
          this.toggleWorkflowSelectDialog();
          this.retrieveRecentActiveProjects(10);
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      return;
    }
    if (this.isMount) {
      this.setState({
        redirectToEditCR: true
      });
    }
  };

  handleSelect = e => {
    if (e.target.props) {
      let expanded = this.state.expandedPanels;
      if (!e.target.props.expanded) {
        expanded.push(e.target.props.uniquePrivateKey);
        if (e.target.props.id) {
          this.panelBarTaskIds.push({
            key: e.target.props.uniquePrivateKey,
            id: e.target.props.id
          });
        }
      } else {
        expanded.splice(expanded.indexOf(e.target.props.uniquePrivateKey), 1);
      }
      this.setState({ expandedPanels: expanded });
    }
  };

  redirectToProjectCreation = () => {
    if (this.isMount) {
      this.setState({
        redirectToProjectCreate: true
      });
    }
  };

  setSelectedCRId = crId => {
    if (this.isMount) {
      this.setState({
        selectedCRId: crId
      });
    }
  };

  redirectToResourceRequest = async () => {
    let projectPM = false;
    let crPM = false;
    let pendingReviseCMS = [];
    if (this.project) {
      projectPM = await this.verifyProjectManager(this.project)
      const reviseCMSrequestsProjects = this.project.ReviseCMSRequest;
      if (reviseCMSrequestsProjects && reviseCMSrequestsProjects.length > 0) {
          const sortedArray = reviseCMSrequestsProjects.sort((a, b) => b.createdAt - a.createdAt);
          if(sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
            (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)
          ) {
              pendingReviseCMS.push(true);
          } else {
              pendingReviseCMS.push(false);
          }
      } else {
          pendingReviseCMS.push(false);
      }
    }
    if (this.state.CRsOfAProject && this.state.CRsOfAProject.length > 0) {
      for (let cr of this.state.CRsOfAProject) {
        crPM = await this.verifyCRManager(cr);
        const reviseCMSrequests = cr.ReviseCMSRequest;// need to changeeeeees
        if (reviseCMSrequests && reviseCMSrequests.length > 0) {
          const sortedArray = reviseCMSrequests.sort((a, b) => b.createdAt - a.createdAt);
          if(sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
            (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)
          ) {
            pendingReviseCMS.push(true);
          } else {
            pendingReviseCMS.push(false);
          }
        } else {
            pendingReviseCMS.push(false);
        }
        if (crPM) {
          break;
        }
      }
    }
    const checkPendingReviseCMSStatus = pendingReviseCMS.some(val => val === false);
    if ((projectPM || crPM) && checkPendingReviseCMSStatus) {
      if (this.isMount) {
        this.setState({
          redirectToResourceRequest: true
        });
      }
    }
  };

  redirectToViewCMS = () => {
    if (this.project) {
      this.cmsheetFunc(this.project);
    }
  };

  cmsheetFunc = project => {
    return <CMSheetFunctions dataItem={project} onViewCMS={this.handleOnViewCMS} />;
  };

  handleOnViewCMS = (project, action, projectCMS_, crCMS) => {
    if (this.isMount) {
      this.setState(
        {
          projectIdOfSelectedCMS: project.id,
          projectCMS: projectCMS_,
          changeRequestCMS: crCMS,
          cmsAction: action
        },
        () => {
          if (projectCMS_.length === 1 && crCMS.length === 0) {
            this.setSelectedCMSId(projectCMS_[0].data.id, projectCMS_[0].data, 'projectCMS');
            this.viewEditCMS();
          } else if (projectCMS_.length === 0 && crCMS.length === 1) {
            this.setSelectedCMSId(crCMS[0].data.id, crCMS[0].data, 'crCMS');
            this.viewEditCMS();
          } else {
            this.toggleCMSDialog();
          }
        }
      );
    }
  };

  setSelectedCMSId = (cmsId, cmSheet, reference) => {
    if (this.isMount) {
      this.setState({
        selectedCMSId: cmsId,
        selectedCMSReference: reference,
        selectedCMSheet: cmSheet.CMSheets[0],
        selectedCRId: cmSheet.id
      });
    }
  };

  viewEditCMS = () => {
    if (this.state.cmsAction === 'view') {
      if (this.isMount) {
        this.setState({
          redirectToEditViewCMS: true
        });
      }
    }
  };

  handleBillableChange = async event => {
    await this.setState({
      selectedTask: null,
      isFormDirty: true,
      billableStatus: event.target.value
    });
    if (this.isMount) {
      if (this.state.billableStatus === 'billable') {
        let data = this.state.allTaskTypes.filter(obj => obj.BillabilityTypeId === 'BILLABLE');
        let data2 = this.state.allTaskTypes.filter(obj => obj.BillabilityTypeId === 'BOTH');
        let finalArr = data.concat(data2);
        await this.setState({
          dropdowntasktype: finalArr,
          disableTaskType: false
        });
      }
      if (this.state.billableStatus === 'nonbillable') {
        let data = this.state.allTaskTypes.filter(obj => obj.BillabilityTypeId === 'NONBILLABLE');
        let data2 = this.state.allTaskTypes.filter(obj => obj.BillabilityTypeId === 'BOTH');
        let finalArr = data.concat(data2);
        await this.setState({
          dropdowntasktype: finalArr,
          disableTaskType: false
        });
      }
      if (this.state.billableStatus === 'both') {
        let data = this.state.allTaskTypes.filter(obj => obj.BillabilityTypeId === 'BOTH');
        await this.setState({
          dropdowntasktype: data,
          disableTaskType: false
        });
      }
    }
  };

  handleStartdateChange = event => {
    let startDate = event.target.value;
    if (startDate) {
      if (!this.state.resourceExist) {
        this.setState({
          isFormDirty: true,
          taskStartdate: startDate,
          startDateMinforEndDate: startDate
        });
      } else {
        this.setState({
          isFormDirty: true,
          taskStartdate: startDate
        });
      }
    } else {
      this.setState({
        taskStartdate: null
      });
    }
  };

  handleEnddateChange = event => {
    let endDate = event.target.value;
    if (endDate) {
      if (!this.state.resourceExist) {
        this.setState({
          isFormDirty: true,
          taskEnddate: endDate,
          startDateMax: endDate
        });
      } else {
        this.setState({
          isFormDirty: true,
          taskEnddate: endDate
        });
      }
    } else {
      this.setState({
        taskEnddate: null
      });
    }
  };

  handleDuplicateStartdateChange = async event => {
    let startDate = event.target.value;
    if (startDate) {
      await this.setState({
        isFormDirty: true,
        taskStartdate: startDate
      });
      let resourceData = await this.validateResourcesOnDateChange();
      await this.setState({
        duplicateTaskResources: resourceData.valid
      });
    } else {
      this.setState({
        taskStartdate: null
      });
    }
  };

  handleDuplicateEndDateChange = async event => {
    let endDate = event.target.value;
    if (endDate) {
      await this.setState({
        isFormDirty: true,
        taskEnddate: endDate
      });
      let resourceData = await this.validateResourcesOnDateChange();
      await this.setState({
        duplicateTaskResources: resourceData.valid
      });
    } else {
      this.setState({
        taskEnddate: null
      });
    }
  };

  handleStartDateChangeForMultiTask = event => {
    let startDate = event.target.value;
    if (startDate) {
      this.setState({
        multiTaskStartDate: startDate,
        isFormDirty: true
      });
    } else {
      this.setState({
        multiTaskStartDate: null
      });
    }
  };

  handleEndDateChangeForMultiTask = event => {
    let endDate = event.target.value;
    if (endDate) {
      this.setState({
        multiTaskEndDate: endDate,
        isFormDirty: true
      });
    } else {
      this.setState({
        multiTaskEndDate: null
      });
    }
  };

  handleStartdateChangeForAddResc = event => {
    let startDate = event.target.value;
    if (startDate) {
      this.setState({
        isFormDirty: true,
        projAllocStartDate: startDate,
        startdateMinforEndDateAddResc: startDate
      });
    } else {
      this.setState({
        projAllocStartDate: null
      });
    }
  };

  handleEnddateChangeForAddResc = event => {
    let endDate = event.target.value;
    if (endDate) {
      this.setState({
        isFormDirty: true,
        projAllocEndDate: endDate,
        startDateMaxForProjAlloc: endDate
      });
    } else {
      this.setState({
        projAllocEndDate: null
      });
    }
  };

  validateMultiTask = () => {
    if (
      this.validateMultiTaskProperty(this.state.selectedProjectOrCr)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateMultiTaskProperty(this.state.selectedProjOrCrTasks)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateMultiTaskProperty(this.state.selectedProjOrCrResources)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  validateMultiTaskProperty = value => {
    if (value && value.length !== 0) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validation = () => {
    if (
      this.validateProperty(this.state.selectedProjectOrCr)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.taskName)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.selectedTask)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.taskStartdate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.taskEnddate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    let categories = [
      'Project Allocation',
      'Total Utilization as at now',
      'Billable Utilization',
      'Non-Billable Utilization',
      'Non-Billable(ESE)',
      'Non-Billable(PMM)',
      'Non-Billable(RWK)',
      'Non-Billable(General)',
      'Unapproved Utilization',
      'Incremental Billing - Billable',
      'Incremental Billing – Non-Billable',
      'Incremental Billing – Non-Billable(ESE)',
      'Incremental Billing – Non-Billable(PMM)',
      'Incremental Billing – Non-Billable(RWK)',
      'Incremental Billing – Non-Billable(General)',
      'Incremental Billing - Unapproved'
    ];

    const allowdata = this.state.allowdata;
    const project = this.project;

    if (this.state.redirectToProjectCreate === true) {
      return (
        <Redirect
          to={{
            pathname: '/projects/create/create',
            project: project,
            projectId: project.id,
            action: 'view'
          }}
        />
      );
    }
    if (this.state.redirectToResourceRequest === true) {
      return (
        <Redirect
          to={{
            pathname: '/ProjectResourceRequest/ProjectResourceRequest',
            project: project,
            action: 'create'
          }}
        />
      );
    }

    // View only
    if (this.state.redirectToEditViewCMS === true) {
      return (
        <Redirect
          to={{
            changeReqId: this.state.selectedCMS.crId,
            projectId: this.state.selectedCMS.projId,
            cmSheet: this.state.selectedCMS,
            reference: this.state.selectedCMSReference,
            pathname: '/projects/cmsheet/cmsheet',
            action: 'view'
          }}
        />
      );
    }
    if (this.state.redirectToEditCR === true) {
      return (
        <Redirect
          to={{
            pathname: '/changeRequest/edit',
            changeReqId: this.state.selectedCRId,
            action: 'view'
          }}
        />
      );
    }

    const editResourceCell = props => {
      return (
        <td className="action-buttons">
          {((props.dataItem.ResourceRequest && props.dataItem.ResourceRequest.Status === "COMPLETE") || 
          (props.dataItem.ResourceRequestSubline && props.dataItem.ResourceRequestSubline.Status === "COMPLETE")) && (
          <a>
            <div className="rounded-link" title="Edit">
              <span
                className="k-icon k-i-edit"
                onClick={() => {
                  this.onEditResc(props.dataItem);
                }}
              />
            </div>
          </a>
          )}
          <a>
            {!props.dataItem.isTimeEntryAvailable && (
              <div className="rounded-link" title="Delete">
                <span
                  className="k-icon k-i-delete"
                  onClick={() => {
                    this.onDeleteResourceButtonClick(props.dataItem.id);
                  }}
                />
              </div>
            )}
          </a>
        </td>
      );
    };

    const components = PanelBarUtils.mapItemsToComponents(this.state.data);
    const expandedPanels = this.state.expandedPanels;
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-12">
            <Grid data={this.state.gridDetails} resizable>
              <Column field="projectName" title="Project Name" />
              <Column field="projectId" title="Project ID" />
              <Column field="projStartDate" title="Start Date" />
              <Column field="projEndDate" title="End Date" />
              <Column field="projCustomer" title="Customer" />
              <Column field="projBillRegion" title="Billing Region" />
              <Column field="projPractice" title="Practice" />
              <Column field="projEngageType" title="Engagement Type" width="120px" />
              <Column field="projBillMethod" title="Billing Method" />
            </Grid>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-8">
            <Button
              primary={true}
              look="outline"
              onClick={this.redirectToProjectCreation}
              type="button"
              className="mr-1"
            >
              View Project
            </Button>

            <Button
              primary={true}
              onClick={this.toggleCRDialog}
              look="outline"
              type="button"
              className="mr-1"
            >
              View CR/s
            </Button>

            <Button
              primary={true}
              look="outline"
              onClick={this.toggleCMSDialog}
              type="button"
              className="mr-1"
            >
              View CMS/s
            </Button>

            <Button
              primary={true}
              look="outline"
              onClick={this.redirectToResourceRequest}
              type="button"
              className="mr-1"
            >
              Edit Resource Request
            </Button>
          </div>
          <div className="col-md-4 btn-align-right">
            <Button
              primary={true}
              onClick={this.toggleAddEditDialog}
              disabled={
                (!((this.state.isProjectManager && this.state.projectActivated) || 
                (this.state.isProjectManagerCR && this.state.crActivated)))
              }
              type="button"
              className="text-right btn-align-right"
            >
              Add New Task
            </Button>
            <Button
              primary={true}
              onClick={this.toggleMultiTaskResourcesDialog}
              disabled={
                (!((this.state.isProjectManager && this.state.projectActivated) || 
                (this.state.isProjectManagerCR && this.state.crActivated)))
              }
              type="button"
              className="text-right btn-align-right"
            >
              Assign Resources to Multiple Tasks
            </Button>            
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 dayhourgrid" id="statCalculations">
            <Grid resizable data={this.state.efforthours}>
              <GridNoRecords>Loading</GridNoRecords>

              <Column field="BaselineEffort" title="Baseline Effort" />
              <Column field="Allocations" title="Allocated Effort" />
              <Column field="ActualEffort" title="Actual Effort" />
              <Column field="BillableEffort" title="Actual Billable Effort" />

              <Column
                field=""
                title="Actual Non-Billable Effort"
                cell={props => (
                  <td>
                    <td id="ApprovedNonBillableTooltip">{props.dataItem.NonBillableEffort}</td>

                    <UncontrolledTooltip
                      modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                      target="ApprovedNonBillableTooltip"
                      placement="bottom"
                      style={{ maxWidth: '300px' }}
                    >
                      <div className="text-left p-1">
                        <span>Approved - Non-Billable(ESE) : {props.dataItem.NonBillableESE}</span>
                        <br />
                        <span>Approved - Non-Billable(RWK) : {props.dataItem.NonBillableRWK}</span>
                        <br />
                        <span>Approved - Non-Billable(PMM) : {props.dataItem.NonBillablePMM}</span>
                        <br />
                        <span>
                          Approved - Non-Billable(General) : {props.dataItem.NonBillableGeneral}
                        </span>
                      </div>
                    </UncontrolledTooltip>
                  </td>
                )}
              />

              <Column field="UnApproved" title="UnApproved" />
              <Column field="Rejected" title="Rejected" />
              <Column
                field=""
                title="Under/Over Utilized Against Baseline"
                cell={props => (
                  <td>
                    <td className="badge badge-danger">{props.dataItem.OverUnderUtilization}</td>
                  </td>
                )}
              />
            </Grid>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 ptm-accordion">
            {this.state.data && this.state.data.length > 0 && (
              <PanelBar
                children={components}
                onSelect={this.handleSelect}
                expanded={expandedPanels}
              />
            )}
          </div>
          {this.state.addEditTaskDialog && (
            <Dialog
              title={'Add / Edit Task'}
              onClose={() => this.toggleCancelAdditEditTask()}
              width={700}
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label mandatory">Project / CR:</label>
                  </div>
                  <div className="d-block">
                    <ComboBox
                      name="selectedProjectOrCr"
                      data={this.state.dropdownprojectcr}
                      allowCustom={allowdata}
                      textField="DisplayText"
                      dataItemKey="id"
                      required={true}
                      value={this.state.selectedProjectOrCr}
                      disabled={
                        this.state.isTimeEntryAvailable ||
                        this.state.isDisableEditTaskBtn ||
                        this.state.isTaskUpdate
                      }
                      onChange={this.handleInputChangeForProjectOrCR}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.selectedProjectOrCr)}>
                        Selection of project or CR is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label mandatory">Task Name:</label>
                  </div>
                  <div className="d-block">
                    <Input
                      autoComplete="off"
                      type="text"
                      name="taskName"
                      required={true}
                      maxLength="100"
                      value={this.state.taskName}
                      disabled={this.state.isTimeEntryAvailable || this.state.isDisableEditTaskBtn}
                      onChange={this.handleInputChange}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.taskName)}>
                        Task name is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4 my-auto">
                  <form className="k-form modified">
                    <div className="k-form-field">
                      <div className="d-block">
                        <label />
                      </div>
                      <input
                        type="radio"
                        value="billable"
                        id="billable"
                        className="k-radio"
                        checked={this.state.billableStatus === 'billable'}
                        onChange={this.handleBillableChange}
                        disabled={
                          this.state.isDisableBillableBtn ||
                          this.state.isTimeEntryAvailable ||
                          this.state.isDisableEditTaskBtn
                        }
                      />
                      <label className="k-radio-label mr-3" htmlFor="billable">
                        Billable
                      </label>

                      <input
                        type="radio"
                        value="nonbillable"
                        id="nonbillable"
                        className="k-radio"
                        checked={this.state.billableStatus === 'nonbillable'}
                        disabled={
                          this.state.isTimeEntryAvailable || this.state.isDisableEditTaskBtn
                        }
                        onChange={this.handleBillableChange}
                      />
                      <label className="k-radio-label" htmlFor="nonbillable">
                        Non-Billable
                      </label>
                      <input
                        type="radio"
                        value="both"
                        id="both"
                        className="k-radio d-none"
                        checked={this.state.billableStatus === 'both'}
                        onChange={this.handleBillableChange}
                        disabled={
                          this.state.isTimeEntryAvailable || this.state.isDisableEditTaskBtn
                        }
                      />
                      <label className="k-radio-label d-none" htmlFor="both">
                        Both
                      </label>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label mandatory">Task Type:</label>
                  </div>
                  <div className="d-block">
                    <ComboBox
                      name="selectedTask"
                      data={this.state.dropdowntasktype}
                      allowCustom={allowdata}
                      textField="Name"
                      required={true}
                      dataItemKey="id"
                      value={this.state.selectedTask}
                      disabled={
                        this.state.disableTaskType ||
                        this.state.isTimeEntryAvailable ||
                        this.state.isDisableEditTaskBtn
                      }
                      onChange={this.handleInputChange}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.selectedTask)}>
                        Task type is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label mandatory">Start Date:</label>
                  </div>
                  <div className="d-block">
                    <DatePicker
                      name="taskStartdate"
                      value={this.state.taskStartdate}
                      min={this.state.startDateMin}
                      max={this.state.startDateMax}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      required={true}
                      disabled={this.state.isTimeEntryAvailable || this.state.isDisableEditTaskBtn}
                      onChange={this.handleStartdateChange}
                      popup={CustomStartDatePickerPopUp}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.taskStartdate)}>
                        Task start date is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label mandatory">End Date:</label>
                  </div>
                  <div className="d-block">
                    <DatePicker
                      name="taskEnddate"
                      value={this.state.taskEnddate}
                      min={
                        this.state.resourceMaxStartDate !== null 
                          ? (this.state.latestTimeEntryDate !== null && new Date(this.state.latestTimeEntryDate) <= new Date(this.state.resourceMaxStartDate)) ? new Date(this.state.resourceMaxStartDate) :new Date(this.state.latestTimeEntryDate)
                          : this.state.startDateMinforEndDate
                      }
                      disabled={this.state.isDisableEditTaskBtn}
                      max={this.state.EndDateMax}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      required={true}
                      onChange={this.handleEnddateChange}
                      popup={CustomEndDatePickerPopUp}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.taskEnddate)}>
                        Task end date is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <DialogActionsBar>
                <button
                  className="k-button"
                  onClick={this.toggleCancelAdditEditTask}
                  disabled={this.state.isDisableEditTaskBtn}
                >
                  Cancel
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={this.handleSubmit}
                  disabled={this.state.isDisableEditTaskBtn}
                >
                  Save
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.duplicateTask && (
            <Dialog
              title={'Duplicate Task'}
              onClose={() => {
                this.setState({ duplicateTask: false, isFormDirty: false });
              }}
              width={700}
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label">Project / CR:</label>
                  </div>
                  <div className="d-block">
                    <ComboBox
                      name="selectedProjectOrCr"
                      data={this.state.dropdownprojectcr}
                      allowCustom={allowdata}
                      textField="DisplayText"
                      dataItemKey="id"
                      value={this.state.selectedProjectOrCr}
                      onChange={this.handleInputChangeForProjectOrCR}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label mandatory">Task Name:</label>
                  </div>
                  <div className="d-block">
                    <Input
                      autoComplete="off"
                      type="text"
                      name="taskName"
                      required={true}
                      maxLength="100"
                      value={this.state.taskName}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-md-4 my-auto">
                  <form className="k-form modified">
                    <div className="k-form-field">
                      <div className="d-block">
                        <label />
                      </div>
                      <input
                        type="radio"
                        value="billable"
                        id="billable"
                        className="k-radio"
                        disabled={this.state.isDisableBillableBtn}
                        checked={this.state.billableStatus === 'billable'}
                        onChange={this.handleBillableChange}
                      />
                      <label className="k-radio-label mr-3" htmlFor="billable">
                        Billable
                      </label>

                      <input
                        type="radio"
                        value="nonbillable"
                        id="nonbillable"
                        className="k-radio"
                        checked={this.state.billableStatus === 'nonbillable'}
                        onChange={this.handleBillableChange}
                      />
                      <label className="k-radio-label mr-3" htmlFor="nonbillable">
                        Non-Billable
                      </label>
                      <input
                        type="radio"
                        value="both"
                        className="k-radio d-none"
                        id="both"
                        checked={this.state.billableStatus === 'both'}
                        onChange={this.handleBillableChange}
                      />
                      <label className="k-radio-label d-none" htmlFor="both">
                        Both
                      </label>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label">Task Type:</label>
                  </div>
                  <div className="d-block">
                    <ComboBox
                      name="selectedTask"
                      data={this.state.dropdowntasktype}
                      allowCustom={allowdata}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.selectedTask}
                      disabled={this.state.disableTaskType}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label mandatory">Start Date:</label>
                  </div>
                  <div className="d-block">
                    <DatePicker
                      name="taskStartdate"
                      value={this.state.taskStartdate}
                      min={this.state.startDateMin}
                      max={this.state.startDateMax}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      required={true}
                      onChange={this.handleDuplicateStartdateChange}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label mandatory">End Date:</label>
                  </div>
                  <div className="d-block">
                    <DatePicker
                      name="taskEnddate"
                      value={this.state.taskEnddate}
                      min={this.state.taskStartdate ? this.state.taskStartdate : this.state.startDateMinforEndDate}
                      max={this.state.EndDateMax}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      required={true}
                      onChange={this.handleDuplicateEndDateChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <Grid data={this.state.duplicateTaskResources} resizable>
                    <Column field="Name" title="Resource" />
                    <Column field="AssignedDuration" title="Assigned Duration" />
                  </Grid>
                </div>
              </div>

              <DialogActionsBar>
                <button
                  className="k-button"
                  onClick={() => {
                    this.setState({ duplicateTask: false, isFormDirty: false });
                  }}
                >
                  Cancel
                </button>
                <button className="k-button modal-primary" onClick={this.handleSubmitDuplicateTask}>
                  Save
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.multiTaskResourcesDialog && (
            <Dialog
              title={'Assign Resources to Multiple Tasks'}
              onClose={() => this.toggleCancelMultiTaskResourceDialog()}
              width={700}
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label mandatory">Project / CR:</label>
                  </div>
                  <div className="d-block">
                    <ComboBox
                      name="selectedProjectOrCr"
                      data={this.state.dropdownprojectcr}
                      allowCustom={allowdata}
                      textField="DisplayText"
                      placeholder={'Please Select'}
                      dataItemKey="id"
                      required={true}
                      value={this.state.selectedProjectOrCr}
                      onChange={this.handleMultiTaskProjectOrCRChange}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateMultiTaskProperty(this.state.selectedProjectOrCr)}>
                        Selection of project or CR is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label mandatory">Tasks (Max 10):</label>
                  </div>
                  <div className="d-block">
                    <MultiSelect
                      name="selectedProjOrCrTasks"
                      data={this.state.projOrCrTasks}
                      textField="DisplayTaskName"
                      dataItemKey="id"
                      required={true}
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                      value={this.state.selectedProjOrCrTasks}
                      onChange={this.handleMultiTaskInputChange}
                      placeholder={'Please Select'}
                      tags={
                        this.state.selectedProjOrCrTasks && this.state.selectedProjOrCrTasks.length > 0
                          ? this.state.selectedProjOrCrTasks.map(i => {
                              return { text: i.Name, data: [i] };
                            })
                          : []
                      }

                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateMultiTaskProperty(this.state.selectedProjOrCrTasks)}>
                        Selection of one or more tasks is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label mandatory">Resources (Max 10):</label>
                  </div>
                  <div className="d-block">
                    <MultiSelect
                      name="selectedProjOrCrResources"
                      data={this.state.projOrCrResources}
                      textField="DisplayText"
                      dataItemKey="id"
                      placeholder={'Please Select'}
                      required={true}
                      value={this.state.selectedProjOrCrResources}
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                      onChange={this.handleMultiResourcesInputChange}
                      disabled={this.state.selectedProjOrCrTasks.length <= 0}
                      tags={
                        this.state.selectedProjOrCrResources && this.state.selectedProjOrCrResources.length > 0
                          ? this.state.selectedProjOrCrResources.map(i => {
                              return { text: i.Name, data: [i] };
                            })
                          : []
                      }
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateMultiTaskProperty(this.state.selectedProjOrCrResources)}>
                        Selection of one or more resources is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>     
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label">Task Allocation Start Date:</label>
                  </div>
                  <div className="d-block">
                    <DatePicker
                      name="multiTaskStartDate"
                      value={this.state.multiTaskStartDate}
                      min={this.state.multiTaskDateMin ? this.state.multiTaskDateMin : this.state.startDateMin}
                      max={this.state.multiTaskEndDate ? this.state.multiTaskEndDate : this.state.multiTaskDateMax ? this.state.multiTaskDateMax : this.state.startDateMax}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      onChange={this.handleStartDateChangeForMultiTask}
                      popup={CustomStartDatePickerPopUp}
                    />
                    {/* {this.state.isErrorMessageVisibleForDates === true ? (
                      <span className={this.validateProperty(this.state.multiTaskStartDate)}>
                        Task Allocation Start Date is mandatory
                      </span>
                    ) : null} */}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="d-block">
                    <label className="new-label">Task Allocation End Date:</label>
                  </div>
                  <div className="d-block">
                    <DatePicker
                      name="multiTaskEndDate"
                      value={this.state.multiTaskEndDate}
                      min={this.state.multiTaskStartDate ? this.state.multiTaskStartDate : 
                        this.state.multiTaskDateMin ? this.state.multiTaskDateMin : this.state.startDateMin}
                      max={this.state.multiTaskDateMax ? this.state.multiTaskDateMax : this.state.EndDateMax}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      onChange={this.handleEndDateChangeForMultiTask}
                      popup={CustomEndDatePickerPopUp}
                    />
                    {/* {this.state.isErrorMessageVisibleForDates === true ? (
                      <span className={this.validateProperty(this.state.multiTaskEndDate)}>
                        Task Allocation End Date is mandatory
                      </span>
                    ) : null} */}
                  </div>
                </div>
              </div>

              <DialogActionsBar>
                <button
                  className="k-button"
                  onClick={this.toggleCancelMultiTaskResourceDialog}
                >
                  Cancel
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={this.handleSubmitMultiTaskAssignment}
                  disabled={this.state.isDisableMultiTaskSaveBtn}
                >
                  Save
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.addEditRescTask && (
            <Dialog
              title={'Add / Edit Resources for the Task'}
              onClose={this.onClickSubmit}
              width={850}
              height={450}
            >
              <div className="row">
                <div className="col-md-3">
                  <div className="d-block">
                    <label className="new-label">Task Name:</label>
                  </div>
                  <div className="d-block">
                    <Input disabled={true} value={this.state.currentlySelectedTask} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block">
                    <label className="new-label">Type:</label>
                  </div>
                  <div className="d-block">
                    <Input disabled={true} value={this.state.currentlySelectedTaskBillability} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block">
                    <label className="new-label">Start Date:</label>
                  </div>
                  <div className="d-block">
                    <Input disabled={true} value={this.state.currentlSelectedTaskStartDate} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block">
                    <label className="new-label">End Date:</label>
                  </div>
                  <div className="d-block">
                    <Input disabled={true} value={this.state.currentlySelectedTaskEndDate} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="d-block">
                    <label className="new-label mandatory">Resources (Max 10):</label>
                  </div>
                  <div className="d-block">
                    <MultiSelect
                      name="selectedResource"
                      data={this.state.taskResourcesDropDown}
                      textField="DisplayText"
                      dataItemKey="id"
                      suggest={true}
                      required={true}
                      filterable={true}
                      onFilterChange={e => this.onEditResourceFilterChange(e)}
                      value={this.state.selectedResource}
                      onChange={this.handleResourceChange}
                      placeholder={'Please Select'}
                      tags={
                        this.state.selectedResource && this.state.selectedResource.length > 0
                          ? this.state.selectedResource.map(i => {
                              return { text: i.Name, data: [i] };
                            })
                          : []
                      }
                      disabled={
                        this.state.isDisableAddRescDropdown ||
                        this.state.isTimeEntryAvailableResource ||
                        this.state.selectedResourceId
                      }
                    />

                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.selectedResource)}>
                        Resource is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block">
                    <label className="new-label">Type:</label>
                  </div>
                  <div className="d-block">
                    <Input disabled={true} value={this.state.resourceBillability} />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block">
                    <label className="new-label mandatory">Start Date:</label>
                  </div>
                  <div className="d-block">
                    <DatePicker
                      name="projAllocStartDate"
                      value={this.state.projAllocStartDate}
                      min={this.state.startDateMinAddResource}
                      max={this.state.startDateMaxForProjAlloc}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      required={true}
                      onChange={this.handleStartdateChangeForAddResc}
                      disabled={
                        this.state.isDisableAddRescStartDate ||
                        this.state.isTimeEntryAvailableResource
                      }
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.projAllocStartDate)}>
                        Start date is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block">
                    <label className="new-label mandatory">End Date:</label>
                  </div>
                  <div className="d-block">
                    <DatePicker
                      name="projAllocEndDate"
                      value={this.state.projAllocEndDate}
                      min={this.state.startdateMinforEndDateAddResc}
                      max={this.state.EndDateMaxAddResource}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      required={true}
                      onChange={this.handleEnddateChangeForAddResc}
                      disabled={this.state.isDisableAddRescEndDate}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.projAllocEndDate)}>
                        End date is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="d-block">
                    <label className="new-label">Shift:</label>
                  </div>
                  <div className="d-block">
                    <TimePicker
                      name="shiftstartTime"
                      popup={TimePickerPopup}
                      value={this.state.shiftstartTime}
                      onChange={this.handleShiftTimeChange}
                      disabled={
                        this.state.isDisableAddRescShiftStartTime ||
                        this.state.isTimeEntryAvailableResource
                      }
                      format={'HH:mm'}
                      required={this.state.shiftendTime}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block">
                    <label className="new-label" />
                  </div>
                  <div className="d-block">
                    <TimePicker
                      name="shiftendTime"
                      disabled={this.state.isTimeEntryAvailableResource}
                      popup={TimePickerPopup}
                      value={this.state.shiftendTime}
                      onChange={this.handleInputChange}
                      format={'HH:mm'}
                      required={this.state.shiftstartTime}
                    />
                  </div>
                </div>

                <div className="col-md-3 my-auto">
                  <div className="k-form-field">
                    <div className="d-block">
                      <label />
                    </div>
                    <input
                      type="checkbox"
                      id="incb"
                      className="k-checkbox"
                      name="incrementbill"
                      onChange={() => {
                        this.setState({ incrementalBilling: !this.state.incrementalBilling });
                      }}
                      checked={this.state.incrementalBilling}
                      disabled={
                        this.state.isDisableAddRescIncrementBill ||
                        this.state.isTimeEntryAvailableResource
                      }
                    />
                    <label className="k-checkbox-label" htmlFor="incb">
                      Incremental Billing
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 btn-align-right my-2">
                  <Button
                    primary={true}
                    onClick={this.handleSaveResource}
                    disabled={this.state.isDisableAddBtnAddResc}
                    type="button"
                    className="text-right"
                  >
                    Save
                  </Button>
                  <Button
                    primary={false}
                    onClick={this.toggleClearAddResource}
                    disabled={this.state.isDisableClearBtnAddResc}
                    type="button"
                    className="text-right"
                  >
                    Clear
                  </Button>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <Grid
                    data={this.state.resourcegrid}
                    skip={this.state.skip}
                    take={this.state.take}
                    total={this.state.totalAllocationCount}
                    pageable
                    onPageChange={this.pageChange}
                    resizable
                    sortable={true}
                    sort={this.state.sort}
                    onSortChange={e => {
                      this.setState({
                        sort: e.sort
                      });
                    }}
                  >
                    <Column field="Name" title="Resource" />
                    <Column field="Resource.Designation.Name" title="Designation" />
                    <Column
                      title="Billable/Non-Billable"
                      cell={props => (
                        <td>{this.getBillableLable(props.dataItem.BillabilityTypeId)}</td>
                      )}
                    />
                    <Column field="StartDate" title="Start Date" />
                    <Column field="EndDate" title="End Date" />
                    <Column
                      field=""
                      title="Incremental Billing"
                      cell={props => (
                        <td style={{ textAlign: 'center' }}>
                          <input
                            type="checkbox"
                            id="incb2"
                            className="k-checkbox"
                            name="incrementbill"
                            checked={props.dataItem.IsIncremental ? true : false}
                            disabled={true}
                          />
                        </td>
                      )}
                    />
                    <Column
                      field=""
                      title="Shift"
                      cell={props => (
                        <td>
                          {props.dataItem.ShiftFrom && props.dataItem.ShiftTo
                            ? moment(props.dataItem.ShiftFrom).format('HH:mm') +
                              ' - ' +
                              moment(props.dataItem.ShiftTo).format('HH:mm')
                            : null}
                        </td>
                      )}
                    />
                    <Column
                      field="hours"
                      title="Current Utilizes"
                      cell={props => (
                        <td>
                          <span className="badge badge-secondary mr-2">
                            {props.dataItem.TotalEffort
                              ? (props.dataItem.TotalEffort * 8).toFixed(2)
                              : 0.0}
                          </span>
                          <span className="badge badge-success mr-2">
                            {props.dataItem.ApprovedTime
                              ? props.dataItem.ApprovedTime.toFixed(2)
                              : 0.0}
                          </span>
                          <span className="badge badge-danger mr-2">
                            {props.dataItem.UnapprovedTime
                              ? props.dataItem.UnapprovedTime.toFixed(2)
                              : 0.0}
                          </span>
                        </td>
                      )}
                    />
                    <Column field="" title="Action" cell={editResourceCell} />
                  </Grid>
                </div>
              </div>

              <DialogActionsBar>
                <button className="k-button modal-primary" onClick={this.onClickSubmit}>
                  Close
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.viewCRDialog === true && (
            <Dialog title={'View CRs'} onClose={this.toggleCRDialog} width="400px">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12" />
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <form className="k-form modified">
                        <div className="k-form-field">
                          {this.state.CRsOfAProject.map((option, i) => {
                            return (
                              <div className="ml-3">
                                <input
                                  type="radio"
                                  id={'' + option.id}
                                  name={'' + option.id}
                                  value={option.id}
                                  checked={this.state.selectedCRId === option.id}
                                  className="k-radio"
                                  key={option.id}
                                  onChange={this.setSelectedCRId.bind(this, option.id)}
                                />
                                <label
                                  key={option.id}
                                  htmlFor={'' + option.id}
                                  className="k-radio-label k-list-optionlabel"
                                >
                                  {option.CRName}
                                  <td className="badge badge-warning ml-2 my-auto px-2 py-1"><span>{option.Status.StatusName}</span> </td>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <DialogActionsBar>
                <button className="k-button" onClick={this.toggleCRDialog}>
                  No
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={this.editCR}
                  disabled={!this.state.selectedCRId}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.showMessageDialog === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="300px">
              {this.state.showSuccessImage === true && (
                  <div className="text-center">
                      <img className="successImg" src={SuccessImg} alt="Success" />
                  </div>
              )}

              {this.state.showErrorImage === true && (
                  <div className="text-center">
                      <img className="successImg" src={ErrorImg} alt="Error Image" />
                  </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
              {this.state.failedResources && this.state.failedResources.length > 0 && (
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  {this.state.failedResources.map(i => {
                    return (
                      <li key={i.Name}>
                        {i.Name +
                          ' - ' +
                          moment(i.StartDate).format('MM/DD/YYYY') +
                          ' to ' +
                          moment(i.EndDate).format('MM/DD/YYYY')}
                      </li>
                    );
                  })}
                </p>
              )}

              <DialogActionsBar>
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.viewCMSDialog === true && (
            <Dialog
              title={'Select the relevant CMS to View'}
              onClose={this.toggleCMSDialog}
              width="400px"
            >
              <div className="row">
                <div className="col-md-12">
                  <p className="mb-0">Project CMS</p>
                  <div className="row">
                    <div className="col-md-12 dialog-radio">
                      <form className="k-form modified">
                        <div className="k-form-field">
                          {this.state.projectCMS.map((option, i) => {
                            return (
                              <div>
                                <input
                                  type="radio"
                                  id={'' + option.id}
                                  name={'' + option.name}
                                  value={option.id}
                                  checked={this.state.selectedCMSId === option.id}
                                  className="k-radio"
                                  key={option.id}
                                  onChange={() => {
                                    this.setState({
                                      selectedCMSId: option.id,
                                      selectedCMS: option,
                                      selectedCMSReference: null
                                    });
                                  }}
                                />
                                <label
                                  key={option.id}
                                  htmlFor={'' + option.id}
                                  className="k-radio-label"
                                >
                                  {option.name}
                                  <td
                                    id="CMSTooltip"
                                    className="badge badge-warning ml-2 my-auto px-2 py-1"
                                  >
                                    {option.Status}{' '}
                                  </td>
                                  <Tooltip
                                    target="CMSTooltip"
                                    isOpen={this.state.cmsToolTipOpen}
                                    toggle={this.toggleCMSToolTip}
                                    placement="right"
                                    className="cmstooltipopen"
                                  >
                                    Project: {option.name} | Version: {option.Version} | Status:{' '}
                                    {option.Status}
                                  </Tooltip>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {this.state.projectCMS.length > 0 ? '' : <label> No Project CMS found</label>}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <p className="mb-0">CR CMS</p>
                  <div className="row">
                    <div className="col-md-12 dialog-radio">
                      <form className="k-form modified">
                        <div className="k-form-field">
                          {this.state.crCMS.map((option, i) => {
                            return (
                              <div>
                                <input
                                  type="radio"
                                  id={'' + option.id}
                                  name={'' + option.name}
                                  value={option.id}
                                  checked={this.state.selectedCMSId === option.id}
                                  className="k-radio"
                                  key={option.id}
                                  onChange={() => {
                                    this.setState({
                                      selectedCMSId: option.id,
                                      selectedCMS: option,
                                      selectedCMSReference: 'crCMS'
                                    });
                                  }}
                                />
                                <label
                                  key={option.id}
                                  htmlFor={'' + option.id}
                                  className="k-radio-label"
                                >
                                  {option.name}
                                  <td
                                    id="CMSTooltip"
                                    className="badge badge-warning ml-2 my-auto px-2 py-1"
                                  >
                                    {option.Status}{' '}
                                  </td>
                                  <Tooltip
                                    target="CMSTooltip"
                                    isOpen={this.state.cmsToolTipOpen}
                                    toggle={this.toggleCMSToolTip}
                                    placement="right"
                                    className="cmstooltipopen"
                                  >
                                    Project: {option.name} | Version: {option.Version} | Status:{' '}
                                    {option.Status}
                                  </Tooltip>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {this.state.crCMS.length > 0 ? '' : <label> No CR CMS found</label>}
                </div>
              </div>

              <DialogActionsBar>
                <button className="k-button" onClick={this.toggleCMSDialog}>
                  No
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={() => {
                    this.setState({ redirectToEditViewCMS: true });
                  }}
                  disabled={!this.state.selectedCMSId}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          {this.state.cancelAddEditTaskVisible && (
            <Dialog title={'Please Confirm'} onClose={() => this.confirmCancelNo()}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Do you wish to discard the unsaved data?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={() => this.confirmCancelNo()}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={() => this.confirmCancelYes()}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          {this.state.cancelMultiTaskResourceVisible && (
            <Dialog title={'Please Confirm'} onClose={() => this.toggleCancelMultiTaskResourceDialog()}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Do you wish to discard the unsaved data?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={() => this.toggleCancelMultiTaskResourceDialog()}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={() => this.confirmMultiTaskCancelYes()}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          {this.state.confirmMultiTaskAllocationVisible && (
            <Dialog title={'Please Confirm'} onClose={() => this.toggleConfirmMultiTaskAllocationDialog()}>
              {this.state.invalidMultiTasks && this.state.invalidMultiTasks.length > 0 && (
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  The following task(s) are not assigned to any Resource
                  {this.state.invalidMultiTasks.map(i => {
                    return (
                      <li key={i.Name}>
                        {i.Name +
                          ' - ' +
                          moment(i.StartDate).format('MM/DD/YYYY') +
                          ' to ' +
                          moment(i.EndDate).format('MM/DD/YYYY')}
                      </li>
                    );
                  })}
                </p>
              )}
              <p></p>
              {this.state.invalidMultiTaskResources && this.state.invalidMultiTaskResources.length > 0 && (
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  The following resource(s) are not assigned to any task
                  {this.state.invalidMultiTaskResources.map(i => {
                    return (
                      <li key={i.Name}>
                        {i.Name +
                          ' - ' +
                          moment(i.StartDate).format('MM/DD/YYYY') +
                          ' to ' +
                          moment(i.EndDate).format('MM/DD/YYYY')}
                      </li>
                    );
                  })}
                </p>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>Do you wish to continue?</p>
              <DialogActionsBar>
                <button className="k-button" onClick={() => this.toggleConfirmMultiTaskAllocationDialog()}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={() => this.saveMultiTaskResourceAllocations()}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          {this.state.cancelEditTaskVisible && (
            <Dialog title={'Please Confirm'} onClose={() => this.confirmCancelTaskNo()}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Do you wish to discard the unsaved data?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={() => this.confirmCancelTaskNo()}>
                  No
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={() => this.confirmCancelTaskYes()}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.cancelAddResourceVisible && (
            <Dialog title={'Please Confirm'} onClose={() => this.confirmCancelAddResource()}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Do you wish to discard the unsaved data ?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={() => this.toggleClearAddResource()}>
                  No
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={() => this.clearAddResourceFields()}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          {this.state.visible === true && (
            <Dialog
              title={'Resource Level Utilization Details for the Project'}
              onClose={this.toggleDialog}
              width="600px"
            >
              <div className="row">
                <div className="col-md-6 text-right">
                  <label>Name: </label>
                  <span> {this.state.resourceName} </span>
                </div>
                <div className="col-md-6">
                  <label>Designation: </label>
                  <span> {this.state.resourceDesignation} </span>
                </div>
              </div>

              <Chart
                pannable={{
                  lock: "y",
                }}
                zoomable={{
                  mousewheel: {
                    lock: "y",
                  },
                  selection: {
                    lock: "y",
                  },
                }}
              >
                
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem categories={categories} />
                </ChartCategoryAxis>
                <ChartSeries>
                  <ChartSeriesItem
                    type="bar"
                    gap={2}
                    spacing={0.25}
                    data={this.state.resourceUtilizationBreakdown}
                    tooltip={{ visible: true }}
                  />
                </ChartSeries>
              </Chart>
              <DialogActionsBar>
                <button className="k-button modal-primary" onClick={this.toggleDialog}>
                  Close
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.popUpBox.isPopUp && (
            <Dialog title={this.state.popUpBox.popUpTitile} onClose={() => this.onClosePopupBox()}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                {this.state.popUpBox.popUpMessage}
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={() => this.onClosePopupBox()}>
                  No
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={() => {
                    this.state.popUpBox.callBack();
                  }}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

class TimePickerPopup extends React.Component {
  render() {
    return (
      <Popup
        {...this.props}
        anchorAlign={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        popupAlign={{
          horizontal: 'center',
          vertical: 'center'
        }}
      />
    );
  }
}

export default ProjectTaskManagement;
