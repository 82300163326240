import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { searchAuditDetail, getRefType, getDistUserName, getAuditTrailDataAll, saveAuditTrail } from './AuditTrailService';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import DateCell from './DateCell';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import moment from 'moment';
import 'moment-timezone';
import Loader from '../components/Loader';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import $ from "jquery";

class AuditTrailSearch extends Component {
  _export;

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {

      auditTrail: [],
      auditTrailAll: [],
      refType: [],

      skip: 0,
      take: 20,

      selectedRefType: [],

      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      refTypeName: null,
      refTypeAll: [],
      refName: null,
      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      startDate: null,
      endDate: null,
      userList: [],
      userListAll: [],
      selectedUserList: [],
      sort: [
        { field: 'createdAt', dir: 'desc' }
      ],
      loading: false,
      userAction: null
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.props.onHeaderTitleChange('Audit Trail');
    this.populateRefType();
    this.populateAuditTrail();
    this.populateUsers();

    $(document).ready(function() {
      $("div.title-remove > span > span > span:last-child").removeAttr("title");
  });

  
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  populateRefType = () => {
    getRefType()
      .then(res => {
        if (this.isMount) {
          this.setState({
            refType: res.data,
            refTypeAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateUsers = () => {
    getDistUserName().then(res => {
      if (this.isMount) {
        this.setState({
          userList: res.data,
          userListAll: res.data
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateAuditTrail = () => {
    const fields = {};
    fields.count = 20;
    fields.skip = this.state.skip;
    fields.take = this.state.take;
    fields.sort = this.state.sort;

    searchAuditDetail(fields)
      .then(res => {
        if (this.isMount) {
          this.setState({
            auditTrail: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleConfirmDialog = (message, action) => {
    if (this.isMount) {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      }, () => this.submitSearch(null));
    }
  };

  handleOnChangeRefType = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  handleOnChangeUser = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  submitSearch = event => {
    if (event) {
      event.preventDefault();
      this.setState({
        skip: 0,
        take: 20,
        sort: [{ field: 'updatedAt', dir: 'desc' }],
      });
    }

    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    const fields = {};
    if (this.state.selectedRefType) {
      fields.refType = this.state.selectedRefType.map(value => value.DISTINCT);
    }
    if (this.state.refName) {
      fields.refName = this.state.refName;
    }

    if (this.state.userAction) {
      fields.userAction = this.state.userAction;
    }

    if (this.state.selectedUserList) {
      fields.userId = this.state.selectedUserList.map(value => value.DISTINCT);
    }
    if(this.state.startDate){
      let startDate = new Date(new Date(this.state.startDate).getFullYear(), new Date(this.state.startDate).getMonth(), new Date(this.state.startDate).getDate(), 0, 0, 0);
      fields.startDate = moment(startDate).tz('Europe/London', true);
    }
    if(this.state.endDate){
      let endDate = new Date(new Date(this.state.endDate).getFullYear(), new Date(this.state.endDate).getMonth(), new Date(this.state.endDate).getDate(), 23, 59, 59)
      fields.endDate = moment(endDate).tz('Europe/London', true);
    }

    fields.count = 0;
    fields.skip = event ? 0 : this.state.skip;
    fields.take = event ? 20 : this.state.take;
    fields.sort = event || this.state.sort.length === 0 ? [{ field: 'updatedAt', dir: 'desc' }] : this.state.sort;

    searchAuditDetail(fields)
      .then(res => {
        if (this.isMount) {
          this.setState({
            auditTrail: res.data,
            loading: false
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  cancelSearch = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        selectedRefType: null,
        refName: '',
        endDate: null,
        startDate: null,
        selectedUserList: null,
        skip: 0,
        take: 20,
        userAction: null
      }, () => this.submitSearch(null));
    }
    this.populateRefType();
    this.populateUsers();
  };

  handleOnChangeInput = event => {
    const field = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (this.isMount) {
      this.setState(prevState => {
        return {
          [field]: value
        };
      });
    }
  };

  handleStartDateChange = event => {
    let startDate = event.target.value;
    if (this.isMount) {
      this.setState({
        startDate: startDate,
        isFormDirty: true
      });
    }
  };

  handleEndDateChange = event => {
    let endDate = event.target.value;
    if (this.isMount) {
      this.setState({
        endDate: endDate,
        isFormDirty: true
      });
    }
  };

  export = () => {
    this.setState({
      loading: true
    });

    const fields = {};
    fields.sort = this.state.sort;

    if (this.state.selectedRefType) {
      fields.refType = this.state.selectedRefType.DISTINCT;
    }
    if (this.state.refName) {
      fields.refName = this.state.refName;
    }
    if (this.state.userAction) {
      fields.userAction = this.state.userAction;
    }
    if (this.state.selectedUserList) {
      fields.userId = this.state.selectedUserList.DISTINCT;
    }
    if(this.state.startDate){
      fields.startDate = moment(this.state.startDate).format('MM-DD-YYYY');
    }
    if(this.state.endDate){
      fields.endDate = moment(this.state.endDate).format('MM-DD-YYYY');
    }

    getAuditTrailDataAll(fields).then(response => {
      if(response.data){
        this.setState({
          auditTrailAll: response.data
        });
      }

      let ExcelDataSet = this.state.auditTrailAll.rows ? this.state.auditTrailAll.rows : [];
      let createdDate;
      for (let i = 0; i < ExcelDataSet.length; i++) {
        createdDate = moment(ExcelDataSet[i].createdAt).format('DD-MM-YYYY hh:mm:ss A');
        ExcelDataSet[i].createdDate = createdDate;
      }
      ExcelDataSet.sort((a, b) => a - b).reverse();
      this._export.save(ExcelDataSet);
      const auditTrail = {
        Action: 'Export to Excel',
        RefType: 'Audit Trail',
        RefId: window.LOGGED_USER && window.LOGGED_USER.user !== null ? window.LOGGED_USER.user : '',
        RefName: window.LOGGED_USER && window.LOGGED_USER.displayName !== null ? window.LOGGED_USER.displayName : ''
      };
      saveAuditTrail(auditTrail);

      this.setState({
        loading: false
      });
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  filterDataRefType(filter) {
    const data = this.state.refTypeAll.slice();
    return filterBy(data, filter);
  }

  filterChangeRefType = event => {
    if (this.isMount) {
      this.setState({
        refType: this.filterDataRefType(event.filter)
      });
    }
  };

  filterDataUser(filter) {
    const data = this.state.userListAll.slice();
    return filterBy(data, filter);
  }

  filterChangeUser = event => {
    if (this.isMount) {
      this.setState({
        userList: this.filterDataUser(event.filter)
      });
    }
  };

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Audit Trail Search</div>
          </div>
        </div>
        <form onSubmit={this.submitSearch}>
          <div className="main-heading" />
          <div className="row">

            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Reference Type:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    name="selectedRefType"
                    textField="DISTINCT"
                    value={this.state.selectedRefType}
                    data={this.state.refType}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeRefType}
                    onChange={this.handleOnChangeRefType} />
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>

            <div className="col-md-2">
              <div className="d-block">
                <label>Reference:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <Input
                    value={this.state.refName || ''}
                    onChange={this.handleOnChangeInput}
                    name="refName"
                    maxLength="100"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>


            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>User:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    name="selectedUserList"
                    textField="DISTINCT"
                    value={this.state.selectedUserList}
                    data={this.state.userList}
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeUser}
                    filterable
                    onFilterChange={this.filterChangeUser}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>

            <div className="col-md-2">
              <div className="d-block">
                <label>User Action:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <Input
                    value={this.state.userAction || ''}
                    onChange={this.handleOnChangeInput}
                    name="userAction"
                    maxLength="100"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>

          </div>

          <div className="row">

            <div className="col-md-2">
              <div className="d-block">
                  <label htmlFor="">Date Range: From: </label>
                </div>
                <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    width="100"
                    name="startDate"
                    onChange={this.handleStartDateChange}
                    value={this.state.startDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>

            <div className="col-md-2">
              <div className="d-block">
                  <label htmlFor="">To:</label>
                </div>
                <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    width="100"
                    name="endDate"
                    onChange={this.handleEndDateChange}
                    value={this.state.endDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                  />
                </div>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch}>
                Clear
              </Button>
            </div>
          </div>

        </form>

        <div className="main-seperator" />

        <div className="row">
          <div className="col-md-6">
            <div className="main-heading">Search Results</div>
          </div>
          <div className="col-md-6">
          <div className="btn-align-right">
              <button
                title="Export Excel"
                className="k-button k-primary"
                onClick={this.export}
              >
                Export to Excel
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">

            <ExcelExport
              data={this.state.auditTrailAll.rows}
              ref={(exporter) => {
                this._export = exporter;
              }}
              fileName={'Audit trail_' + new Date()}
            >
              <ExcelExportColumn field="createdDate" title="Date and Time"/>
              <ExcelExportColumn field="action" title="User Action"/>
              <ExcelExportColumn field="referenceType" title="Reference Type"/>
              <ExcelExportColumn field="referenceName" title="Reference"/>
              <ExcelExportColumn field="userId" title="User"/>
            </ExcelExport>

            <Grid
              data={this.state.auditTrail.rows}
              skip={this.state.skip}
              take={this.state.take}
              total={this.state.auditTrail.count}
              pageable={true}
              onPageChange={this.pageChange}
              resizable
              sortable={true}
              sort={this.state.sort}
              onSortChange={(e) => {
                this.setState({
                  sort: e.sort
                }, () => this.submitSearch(null));
              }}
            >
              <Column field="createdAt" title="Date and Time"
                cell={props => <DateCell {...props} />} />
              <Column field="action" title="User Action" />
              <Column Column field="referenceType" title="Reference Type" />
              <Column field="referenceName" title="Reference" />
              <Column field="userId" title="User" />
            </Grid>

          </div>
        </div>

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleMessageDialog}>
                OK
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
              <button className="k-button modal-primary" onClick={this.toggleConfirmDialog}>
                No
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        <div>
          {this.state.deactivatePopupVisible && (
            <Dialog title={'Please Confirm'} onClose={this.deactivateConfirmationClose}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Are you sure you want to Deactivate this?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.deactivateConfirmationClose}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.deactivateConfirmationOk}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <div>
          {this.state.activatePopupVisible && (
            <Dialog title={'Please Confirm'} onClose={this.activateConfirmationClose}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Are you sure you want to Activate this?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.activateConfirmationClose}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.activateConfirmationOk}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default AuditTrailSearch;



