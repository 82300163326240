import React, { Component } from "react";
import { Link } from "react-router-dom";

class HrDesignationActionCell extends Component {
  render() {
    const hrDesignation = this.props.dataItem;
    const { OnDeactivate } = this.props;
    const { OnActivate } = this.props;
    return (
      <td className="action-buttons">
        {hrDesignation.IsActive === true &&  (window.USER_PERMISSION.indexOf('EDT_HR_DESG') > -1 ) && (
          <Link
            to={{
              pathname: "/admin/hrdesignation/create",
              designation: hrDesignation,
              action: "edit",
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-edit" title="Edit" />
            </div>
          </Link>
        )}
        {hrDesignation.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_HR_DESG') > -1 ) && (
          <Link
            to={this.props}
            onClick={event => OnDeactivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-unsort" title="Deactivate" />
            </div>
          </Link>
        )}
        {hrDesignation.IsActive === false && (window.USER_PERMISSION.indexOf('EDT_HR_DESG') > -1 ) && (
          <Link
            to={this.props}
            onClick={event => OnActivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-check" title="Activate" />
            </div>
          </Link>
        )}
      </td>
    );
  }
}

export default HrDesignationActionCell;
