import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class DateTableCell extends Component {
    render() {
        const value = this.props.value;
        return <td><Moment format="MM-DD-YYYY">
            {value}
        </Moment></td>;
    }
}

export default DateTableCell;