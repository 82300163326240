import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { getResourceRegionsIntegrated } from '../../integration/MasterDataService';
import { Button } from '@progress/kendo-react-buttons';
import { Link } from 'react-router-dom';
import ActionsTableCell from './components/ActionsTableCell';
import ConfirmationDialog from '../../integration/components/ConfirmationDialog';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import Loader from '../../integration/components/Loader';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import { searchQmsTemp, getDocumentName, getDocumentCode, deleteQmsTemplate, updateQmsStatus } from './QmsTempService';
import DateCell from './DateCell';
import moment from 'moment';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';

class SearchQMS extends Component {

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      deletePopupVisible: false,
      visible: false,
      dialogMessage: '',
      dialogTitle: '',
      loading: false,
      deactivePopupVisible: false,
      deActivateDocId: '',
      activatePopupVisible: false,
      activateDocId: [],
      statusArray: ['Active', 'Inactive'],
      searchStatus: 'Active',

      qmsTempDetails: [],
      documentName: [],
      documentNameAll: [],
      divisionType: [],
      divisionTypeAll: [],
      modifiedBy: [],
      modifiedByAll: [],
      documentCode: [],
      documentCodeAll: [],

      selectedDocumentName: [],
      selectedDivision: [],
      selectedModifiedBy: [],
      selectedDocumentCode: [],
      startDate: null,
      endDate: null,
      docNameToDelete: null,

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      showMessageDialog: false,

      skip: 0,
      take: 20,

      sort: [
        { field: 'updatedAt', dir: 'desc' }
      ],
    };
  }

  async componentDidMount() {
    this.isMount = true;
    window.scrollTo(0, 0);
    this.setHeaderTitle();
    this.populateQmsTempDetails();
    this.populateDocumentName();
    this.populateDocumentCode();
    this.populateModifiedBy();
    this.populateDivision();

  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('QMS Templates');
    }
  };

  populateQmsTempDetails = () => {
    const fields = {};
    if (this.state.searchStatus) {
      fields.status = this.state.searchStatus  ?  this.state.searchStatus : null;
    }
    searchQmsTemp(fields)
      .then(res => {
        if (this.isMount) {
          const activeTemplates = res.data.filter(value => ((value.IsDeleted === false || value.IsDeleted === null) && value.IsActive === true ));
          this.setState({
            qmsTempDetails: activeTemplates
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateDocumentName = () => {
    getDocumentName()
      .then(res => {
        if (this.isMount) {
          this.setState({
            documentName: res.data,
            documentNameAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateDocumentCode = () => {
    getDocumentCode()
      .then(res => {
        if (this.isMount) {
          this.setState({
            documentCode: res.data,
            documentCodeAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateModifiedBy = () => {
    const fields = {};
    searchQmsTemp(fields)
      .then(res => {
        if (this.isMount) {
          const temp = res.data.filter(value => value.IsDeleted === false || value.IsDeleted === null);
          const result = temp.map(value => (value.User))
          const userList = [...new Map(result.map(val =>[val["displayName"], val])).values()]
          this.setState({
            modifiedBy: userList,
            modifiedByAll: userList
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateDivision = () => {
    const fields = {};
    searchQmsTemp(fields)
    .then(res => {
      if (this.isMount) {
        const temp = res.data.filter(value => value.IsDeleted === false || value.IsDeleted === null);
        const result = temp.map(value => (value.QMSUpload.map(v => v.BillingDivision)))
        let arr = []
        result.map(val => val.length > 0 ? arr.push(val) : null)
        const divisions = [...new Map(arr.map(val =>[val[0].Name , val[0]])).values()]
        this.setState({
          divisionType: divisions,
          divisionTypeAll: divisions
        });
      }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  OnEdit = () => {
    
  };

  OnDeActivate = async (event, docId) => {
    if (event) {
      event.preventDefault();
    }
    await this.setState({
      deActivateDocId: docId
    });
    this.confirmationClose();
  };

  OnActivate = async (event, docId) => {
    if (event) {
      event.preventDefault();
    }
    await this.setState({
       activateDocId: docId
    });
    await this.activateConfirmationClose();
  };

  confirmationClose = async () => {
    await this.setState({
      deactivePopupVisible: !this.state.deactivePopupVisible
    });
  };

  confirmationOk = async () => {
    await updateQmsStatus(
      { 
        IsActive: false,
        ModifiedBy: window.LOGGED_USER.user 
      },
      this.state.deActivateDocId
    )
      .then(response => {
        this.toggleMessageDialog(
          'The QMS Template has been deactivated successfully',
          'Success'
        );
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    this.submitSearch(null);
    this.populateDocumentName();
    this.populateDocumentCode();
    this.populateModifiedBy();
    this.populateDivision();
    this.setState({
      deactivePopupVisible: false,
      showSuccessImage : true,
      showErrorImage : false,
    });
  };

  activateConfirmationClose = () => {
    this.setState({
      activatePopupVisible: !this.state.activatePopupVisible
    });
  };


  activateConfirmationOk = async () => {
    await updateQmsStatus({ 
      IsActive: true, 
      ModifiedBy: window.LOGGED_USER.user  
    },
     this.state.activateDocId
     )
      .then(response => {
        this.toggleMessageDialog(
          'The Template has been activated successfully',
          'Success'
        );
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

      this.submitSearch(null);
      this.populateDocumentName();
      this.populateDocumentCode();
      this.populateModifiedBy();
      this.populateDivision();
      this.setState({
        showSuccessImage : true,
        showErrorImage : false,
        activatePopupVisible: false
      });
  };

  deleteQmsTemplateId = (docName) => {
    if (this.isMount) {
      this.setState(
        {
          docNameToDelete : docName
        },
        () => {
          this.toggleConfirmDialog(
            'Are you sure you want to delete the record?',
            this.deleteQmsTemplate
          );
          this.setState({
            showErrorImage: true,
            showSuccessImage: false
          })
        }
      );
    }
  };

  deleteQmsTemplate = () => {
    const data = { 
      ModifiedBy: window.LOGGED_USER.user  
    }
    deleteQmsTemplate(this.state.docNameToDelete, data)
      .then(res => {
        if (this.isMount) {
          this.setState({
            docNameToDelete: null,
          });
        }
        this.toggleConfirmDialog('', null);
        this.toggleMessageDialog('Successfully Deleted', 'Success');
        this.submitSearch(null);
        this.populateDocumentName();
        this.populateDocumentCode();
        this.populateModifiedBy();
        this.populateDivision();
        this.setState({
          showSuccessImage : true,
          showErrorImage : false
        })
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleMessageDialog(error.response.data, 'Error');
      });
  };

  toggleMessageDialog = (message, title) => {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
  };

  toggleConfirmDialog = (message, action) => {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
  };

  handleInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    await this.setState({ [name]: value, isFormDirty: true });
  };

  filterDataDocumentName(filter) {
    const data = this.state.documentNameAll.slice();
    return filterBy(data, filter);
  }

  filterChangeDocumentName = event => {
    if (this.isMount) {
      this.setState({
        documentName: this.filterDataDocumentName(event.filter)
      });
    }
  };

  filterDataDocumentCode(filter) {
    const data = this.state.documentCodeAll.slice();
    return filterBy(data, filter);
  }

  filterChangeDocumentCode = event => {
    if (this.isMount) {
      this.setState({
        documentCode: this.filterDataDocumentCode(event.filter)
      });
    }
  };

  filterDataModifiedBy(filter) {
    const data = this.state.modifiedByAll.slice();
    return filterBy(data, filter);
  }

  filterChangeModifiedBy = event => {
    if (this.isMount) {
      this.setState({
        modifiedBy: this.filterDataModifiedBy(event.filter)
      });
    }
  };

  filterDataDivision(filter) {
    const data = this.state.divisionTypeAll.slice();
    return filterBy(data, filter);
  }

  filterChangeDivision = event => {
    if (this.isMount) {
      this.setState({
        divisionType: this.filterDataDivision(event.filter)
      });
    }
  };

  handleStartDateChange = event => {
    let startDate = event.target.value;
    if (this.isMount) {
      this.setState({
        startDate: startDate,
        isFormDirty: true
      });
    }
  };

  handleEndDateChange = event => {
    let endDate = event.target.value;
    if (this.isMount) {
      this.setState({
        endDate: endDate,
        isFormDirty: true
      });
    }
  };

  submitSearch = async event => {
    if(event){
      event.preventDefault();
    }
    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    const fields = {};
    if (this.state.selectedDocumentName) {
      fields.DocName = this.state.selectedDocumentName.map(value => value.DISTINCT);
    }
    if (this.state.selectedDocumentCode) {
      fields.DocCode = this.state.selectedDocumentCode.map(value => value.DISTINCT);
    }
    if (this.state.selectedModifiedBy) {
      fields.ModifiedBy = this.state.selectedModifiedBy.map(value => value.id);
    }

    if (this.state.selectedDivision) {
      fields.DivisionId = this.state.selectedDivision.map(value => value.id);
    }

    if (this.state.searchStatus) {
      fields.status = this.state.searchStatus  ?  this.state.searchStatus : null;
    }

    fields.startDate = this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD")  : '1900-01-01';

    fields.endDate = this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD")  : '2199-12-31';

    await searchQmsTemp(fields)
      .then(async res => {
        if (this.isMount) {
          let templates = res.data.filter(value => value.IsDeleted === false || value.IsDeleted === null);

          await this.setState({
            qmsTempDetails: templates,
            loading: false,
            skip: 0
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  cancelSearch = async event => {
    event.preventDefault();
    if (this.isMount) {
     await this.setState({
        selectedDocumentName: [],
        selectedDocumentCode: [],
        selectedModifiedBy: [],
        selectedDivision: [],
        searchStatus: null,
        startDate: null,
        endDate: null
      });
    }
    this.populateDocumentName();
    this.populateDocumentCode();
    this.populateModifiedBy();
    this.populateDivision();
    this.submitSearch(null);
  };

  export = async () => {
    await this.setState({
      loading: true
    });

    await this.submitSearch(null);
    const exportData = [];
    if (this.state.qmsTempDetails && this.state.qmsTempDetails.length > 0) {
      this.state.qmsTempDetails.forEach(obj => {
        let qmsObj = {
          DocName: obj.DocName,
          Department: obj.QMSUpload.map(value => value.BillingDivision.Name).toString(),
          DocCode: obj.QMSUpload.map(value => value.DocCode).toString(),
          Description: obj.Description,
          ModifiedAt: obj.modifiedAt,
          Template: obj.FileUpload.FileName,
          ModifiedBy: obj.User ? obj.User.displayName : '',
          Status: obj.IsActive ? "Active" : "Inactive"
        }
        exportData.push(qmsObj);
      });
    }
    this._export.save(exportData);

    await this.setState({
      loading: false
    });
  };

  CategoryCell = props => {
    return <td>{props.dataItem[props.field].map(v => v.BillingDivision.Name).join(", ")}</td>;
  };

  render() {
    return (
      <div className="main-card">

        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">QMS Templates Search</div>
          </div>

          <div className="col-md-4">
            <Button onClick={this.onClickCancel} primary={true} className="pull-right mb-2" disabled={!(window.USER_PERMISSION.indexOf('EDT_QMS') > -1)}>
              <Link
                style={{ textDecoration: 'none', color: 'black' }}
                to={{
                  pathname: '/admin/qms/addtemplate',
                  action: 'create'
                }}
              >
                Add Template
              </Link>
            </Button>
          </div>
        </div>

        <form onSubmit={this.submitSearch}>
          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                <label>Document Name:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedDocumentName"
                    textField="DISTINCT"
                    // dataItemKey="id"
                    value={this.state.selectedDocumentName}
                    data={this.state.documentName}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeDocumentName}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label>Department:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedDivision"
                    textField="Name"
                    // dataItemKey="id"
                    value={this.state.selectedDivision}
                    data={this.state.divisionType}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeDivision}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label>Modified By:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    name="selectedModifiedBy"
                    textField="displayName"
                    // dataItemKey="status"
                    value={this.state.selectedModifiedBy}
                    data={this.state.modifiedBy}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeModifiedBy}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
          
            <div className="col-md-2">
              <div className="d-block">
                <label>Document Code:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedDocumentCode"
                    textField="DISTINCT"
                    // dataItemKey="id"
                    value={this.state.selectedDocumentCode}
                    data={this.state.documentCode}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeDocumentCode}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>

          <div className="col-md-2">
              <div className="d-block">
                <label>Modified From:</label>
              </div>
              <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    width="100%"
                    value={this.state.startDate}
                    onChange={this.handleStartDateChange}
                    name="startDate"
                    format="MM/dd/yyyy"
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label>Modified To:</label>
              </div>
              <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    width="100%"
                    value={this.state.endDate}
                    onChange={this.handleEndDateChange}
                    name="endDate"
                    format="MM/dd/yyyy"
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                    min={new Date(this.state.startDate)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label">Status:</label>
              </div>
              <div className="d-block">
                <ComboBox
                  name="searchStatus"
                  value={this.state.searchStatus}
                  data={this.state.statusArray}
                  placeholder={'Select status'}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
                Clear
              </Button>
            </div>
          </div>


          <div className="main-seperator"></div>

          <div className="row">
            <div className="col-md-6">
              <div className="main-heading">Search Results</div>
            </div>
            <div className="col-md-6">
              <div className="btn-align-right">
                <button
                  title="Export Excel"
                  className="k-button k-primary"
                  onClick={this.export}
                >
                  Export to Excel
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-0">
            <div className="col-md-12">

            <ExcelExport
              ref={(exporter) => {
                this._export = exporter;
              }}
              fileName={'QMS Templates ' + new Date()}
            >
              <ExcelExportColumn field="DocName" title="Document Name"/>
              <ExcelExportColumn field="Department" title="Department"/>
              <ExcelExportColumn field="DocCode" title="Document Code"/>
              <ExcelExportColumn field="Template" title="Template"/>
              <ExcelExportColumn field="Description" title="Description"/>
              <ExcelExportColumn field="ModifiedAt" title="Modified Date"/>
              <ExcelExportColumn field="ModifiedBy" title="Modified By"/>
              <ExcelExportColumn field="Status" title="Status"/>
            </ExcelExport>

          {this.state.qmsTempDetails.length >  0 ? <Grid
                data={orderBy(this.state.qmsTempDetails, this.state.sort).slice(
                  this.state.skip,
                  this.state.take + this.state.skip
                )}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.qmsTempDetails.length}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }
                }
                className="search-result-grid"
              >
                <Column
                  id="id"
                  field="QMSUpload"
                  title="Document Name"
                  cell={props =>
                    props.dataItem[props.field].length > 0 ? <td>
                      {props.dataItem[props.field][0].DocName}
                    </td> : null
                    }
                />


                <Column
                  id="id"
                  //field={this.state.qmsTempDetails.map(v => v.QMSUpload.BillingDivision.Name)}
                  field="QMSUpload"
                  title="Department"
                  cell={props =>
                    <td>
                      {props.dataItem[props.field].map(v => v.BillingDivision.Name).join(", ")}
                    </td>}
                  />

                <Column
                  id="id"
                  field="QMSUpload"
                  title="Document Code"
                  cell={props =>
                    <td>
                      {props.dataItem[props.field].map(v => v.DocCode).join(", ")}
                    </td>}
                />

                <Column
                  id="id"
                  field="QMSUpload"
                  title="Template"
                  cell={props =>
                    props.dataItem[props.field].length > 0 ? <td>
                      {props.dataItem[props.field][0].FileUpload.FileName}
                    </td> : null
                  }
                />

                {/* <Column
                  field=""
                  title="Download"
                /> */}

                <Column
                  id="id"
                  field="QMSUpload"
                  title="Description"
                  cell={props =>
                    props.dataItem[props.field].length > 0 ? <td>
                      {props.dataItem[props.field][0].Description}
                    </td> : null
                    }
                />

                <Column
                  id="id"
                  field="modifiedAt"
                  title="Modified Date"
                  cell={props => props.dataItem.QMSUpload.length > 0 ? <DateCell {...props} /> : null}
                />

                <Column
                  id="id"
                  field="QMSUpload"
                  title="Modified By"
                  cell={props =>
                    props.dataItem[props.field].length > 0 ? <td>
                      {props.dataItem[props.field][0].User.displayName}
                    </td> : null
                    }
                />

                <Column
                  id="id"
                  title="Actions"
                  cell={props => props.dataItem.QMSUpload.length > 0 ?
                    <ActionsTableCell
                      {...props}
                      OnEdit={this.OnEdit}
                      OnDelete={this.deleteQmsTemplateId}
                      OnDeActivate={this.OnDeActivate}
                      OnActivate={this.OnActivate}
                    />

                  : null}
                />
              </Grid> : null}
            </div>
          </div>

          <div>
            
            {this.state.showMessageDialog === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
                
                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success" />
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error Image" />
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                <DialogActionsBar>
                  <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                    OK
                      </button>
                </DialogActionsBar>
              </Dialog>
            )}
            {this.state.showConfirmDialog === true && (
              <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                
                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success" />
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error Image" />
                  </div>
                )}

                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.toggleConfirmDialog}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>

          <div>
            {this.state.deactivePopupVisible && (
              <Dialog
                title={'Please Confirm'}
                onClose={this.confirmationClose}
                width={260}
                height={175}
              >
                <p style={{ margin: '25px', textAlign: 'center' }}>
                 Are you sure you want to Deactivate this Template?
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.confirmationClose}>
                    No
                  </button>
                  <button
                    className="k-button modal-primary"
                    onClick={this.confirmationOk}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
        </div>

        <div>
          {this.state.activatePopupVisible && (
            <Dialog
              title={'Please Confirm'}
              onClose={this.activateConfirmationClose}
              width={260}
              height={200}
            >
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Are you sure you want to activate the Template?
              </p>
              <DialogActionsBar>
                <button
                  className="k-button"
                  onClick={this.activateConfirmationClose}
                >
                  No
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={this.activateConfirmationOk}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <div>
          {this.state.togglePopupVisible && (
            <Dialog
              title={this.state.dialogTitle}
              onClose={this.toggleMessageDialog}
            >
              <p style={{ margin: '25px', textAlign: 'center' }}>
                {this.state.dialogMessage}
              </p>
              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.toggleMessageDialog}
                >
                  Ok
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          </div>
          {/* {this.state.deletePopupVisible === true && (
              <ConfirmationDialog
                dialogTitle={'Please Confirm'}
                dialogMessage={'Are you sure you want to Delete this?'}
                dismissCallback={this.deleteConfirmationToggle}
                abortCallback={this.deleteConfirmationToggle}
                confirmCallback={this.deleteConfirmationToggle}
              />
            )}*/}

          {/* <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success" />
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error Image" />
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div> */}
          <Loader loading={this.state.loading} />
        </form>

      </div>

    );
  }
}

export default SearchQMS;
