import React, { Component } from 'react';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { ComboBox, DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons'
import ExcelDownload from './components/ExcelDownload';
import {
    TabStrip,
    TabStripTab
} from '@progress/kendo-react-layout';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column, GridColumn, GridDetailRow } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-react-dialogs';
import '../Spark.css';
import moment from 'moment';
import { getConsolidatedCMSDataIntegrated } from '../integration/ConsolidatedCMSService';
import { getProjectPendingTasks } from './../projectManagement/workflow/PendingTaskService';
import {
    formatNumber,
    getFormattedDate,
    getFormattedDateAndTime,
    isWeekend,
    roundNumber,
    reStructureDate
} from '../integration/CommonUtils';
import {
    getResourceRequestResourceMixData,
    getActiveProjectResources,
    allocateResources,
    deleteRequest,
    saveResourceRequests,
    getRatesForAllocation,
    updateStatusToConfirm,
    deleteRequestSubLine,
    saveResourceAllocation, getPreSalesResourceMixData, sendAllocationDeallocationMails,
    getLineStatus, saveResourceLineHistory, getLineHistory, searchResource, getAllAllocatedLines, updateStatusToCancelled, getLeavesIntegrated ,getAllRateTypes,
    getActualOtherExpenses,
    getActualOtherExpensesPreviousCMS
} from './ResourceService';
import {
    getAllDesignationsIntegrated,
    getHolidaysIntegrated,
    getResourceRegionsIntegrated,
    getRateTypesIntegrated,
    getCostIntegrated,
    getRegionCurrencyIntegrated
} from '../integration/MasterDataService';
import { getExpenseTypes, getPaymentMethods } from '../masterData/common/CommonService';
import {getAllTaskTypes} from '../masterData/taskType/TaskTypeService';
import ProjectORCrCell from './resourceRequest/convertActive/ProjectORCrCell';
import { filterBy } from '@progress/kendo-data-query';
import * as loggerService from '../integration/LoggerService';
import { LOG_TYPES } from '../integration/IntegrationEnums';
import {
    getPhasesAndSubPhases,
    savePhasesOfResourceRequests,
    getCMSheet, calculateDuration,
    getCMSheetOtherExpenses
} from '../projectManagement/cmSheet/CMSheetService';
import PhaseRow from './components/PhaseRow';
import Quils from './components/Quil';
import { DebounceInput } from 'react-debounce-input';
import SuccessImg from '../ui/static/images/checked.svg';
import ErrorImg from '../ui/static/images/cancel.svg';
import { Redirect } from 'react-router';
import { updateProject, getProjectStatistics } from '../projectManagement/project/ProjectService';
import ResourceRequestStatusCell from './components/ResourceRequestStatusCell';
import ResourceRequestLineStartDateCell from './components/ResourceRequestLineStartDateCell';
import GridSubLineCellResourceAllocation from './components/GridSubLineCellResourceAllocation';
import Loader from '../integration/components/Loader';
import { retrieveConversionRateIntegrated } from '../integration/ProjectManagementService';
import {
    submitForResourceAllocation,
    approveResourceRequest,
    rejectResourceRequest,
    getAllApproversForResource
} from './resourceRequest/ResourceRequestActivationService';
import { saveAuditTrail } from '../integration/auditTrail/AuditTrailService';
import {
    selectResourceAllocationWorkflow,
    selectResourceRequestWorkflow, setWorkFlow
} from '../projectManagement/workflow/WorkflowService';

import ResourceRequestValueThreeCell from './components/ResourceRequestValueThreeCell';
import ResourceRequestValueFourCell from './components/ResourceRequestValueFourCell';
import ResourceRequestSubLineActionCell from './EditSubLineActionCell';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import ResourceRequestStatusListCell from './components/ResourceRequestStatusListCell';
import AllocatedResourceCell from './components/AllocatedResourceCell';
import EditPopupIsRevenuePresentCell from './components/EditPopupIsRevenuePresentCell';
import {getCRsOfAProject} from "../projectManagement/changeRequest/ChangeRequestService";
import { getCostDetails, getMultiYearCost } from '../masterData/costCard/CostCardService';
import { getMultiYearInfoByYearandCostCardId } from '../masterData/multiyearIncrement/MultiyearIncrementService';
import MoreDetailsCell from "./components/MoreDetailsCell"
import ResourceRequestLineEndDateCell from "./components/ResourceRequestLineEndDateCell";
import { getAllocatedDaysNonProjectTasks } from '../Timesheets/nonProjectTaskSearch/NonProjectTasksService';
import {saveProjectTask,saveResource,getAllProjectTasks,addPreSaleTask} from '../Timesheets/projectTaskManagement/ProjectTaskManagementService';
import ResourceRequestEditStatusCell from './components/ResourceRequestEditStatusCell';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import ResourceRequestStatusENUMS from "./components/ResourceRequestStatusEnums";
import LineHistoryDateCell from './components/LineHistoryDateCell';
import { saveFrontEndLogs } from '../integration/FrontEndLogService';

const CUSTOM_RATE = 'CUSTOM_RATE';
const ResourceRequestStatus = require('../resourceManagement/components/ResourceRequestStatusEnums');


class ProjectResourceAllocation extends Component {

    isMount = false;
    allTheNames = [];
    designationsAll = [];
    usersAll = [];
    usersForDesignation = [];
    TmpDesignationsAll = [];
    TmpDesignation = null;
    TmpSubPhasesAll = [];
    forRemoveResource = [];
    regionsAll = [];
    paymentMethodsAll = [];
    rateTypesAll = [];
    onSiteStatusAll = [{ id: 1, Name: 'Onsite' }, { id: 2, Name: 'Offshore' }];
    ticked = [];
    allocationId = [];
    sublineTicked = [];

    state = {
        sort: [
            { dir: 'asc' }
        ]
    }

    constructor(props) {
        super(props);

        this.state = {
            selected: 0,
            phaseSubPhaseId: null,
            editableItem: null,
            phasesSubPhases: [],
            project: this.props.location.project,
            isSaveDisable: false,
            calculations: [],
            resourceMix: [],
            allocationSummaryResources: [],
            showSuccessImage: false,
            showErrorImage: false,
            popupVisible: false,
            isSubPhaseEdit: false,
            newStartDateMin: null,
            RolesAll: [],
            RegionsAll: [],
            tmpDesignationsAll: [],
            RateTypes: [],
            isEditButton: false,
            isAddDone: false,
            ExpenseTypesAll: [],
            namesAll: [],
            allRateTypes: [],
            usersAll: [],
            onSiteStatusAll: (this.onSiteStatusAll),
            newResPhase: null,
            newResSubPhase: null,
            hasUnSavedData: false,
            newResProjectOrCRName: null,
            newResProjectOrCRNames: null,
            newResRole: null,
            newResDesignation: null,
            newResRegion: null,
            newOnSiteStatus: null,
            newStartDate: null,
            newEndDate: null,
            newEffortDays: 0,
            newAllocation: 100,
            newPaymentMethod: null,
            newPaymentMethodAll: [],
            newProjectCurrency: null,
            newCost: null,
            newRate: null,
            newRatesAll: [],
            isStartDateVisible: false,
            showEditDialog: false,
            showAddDialog: false,
            isSaveButtonVisibleEdit: false,

            addResourceGridData: [],
            selectedType: null,
            projectPhaseVisible: false,
            projectSubPhaseVisible: false,
            startDate: null,
            startDateMin: null,
            startDateIsaPastDate: false,
            phasesTemp: [],
            isfullSelected: false,

            phaseStartDate: null,
            selectedProjecOrCrForPhase: null,
            phaseName: '',
            subPhaseName: '',
            selectedPhaseIndex: -1,
            minPhaseDate: null,
            minSubPhaseDate: null,
            selectedProjectOrCrPhases: [],
            subPhaseStartDate: null,

            subPhaseRemovePhaseIndex: null,
            subPhaseRemoveSubPhaseIndex: null,

            showConfirmDialog: false,
            confirmDialogMessage: '',
            confirmDialogAction: null,

            selectedCMSId: null,

            showMessageDialog: false,
            dialogMessage: '',
            dialogTitle: '',
            isCanRemoveRequest: false,

            goBack: false,
            showCancelConfirmation: false,

            rejectReason: '',
            showRejectConfirmation: false,

            visible: false,
            notEditableResource: false,

            // sub line related changes
            selctedResourceSubLineStartDate: null,
            selctedResourceSubLineEndDate: null,
            selectedProjecOrCrSublineResources: [],
            subLineStartDateMin: null,
            subLineEndDateMax: null,
            mainLineofsublineObj: null,
            hasSubLines: false,
            sublineCost: null,

            editableResourceSelectedPhase: null,
            editableResourceSelectedSubPhase: null,

            allProjectCrPhases: [],
            subPhasesOfSelectedPhase: [],

            disableSelectedResourceStartEndDate: false,

            subLineDefaultAllocationPercentage: 0,
            subLineEffortDays: 0,
            subLineDefaultRateType: null,
            subLineDefaultRateValue: 0,
            selectedResourcesSubLine: [],
            isCheckedSubline: false,
            //CostValues
            yearslotsWithinStartEndProjectDates: [],
            editableResourceSubLines: [],
            showEditSubLineDialog: false,

            subLineUpdatedDialog: false,

            showOutsourcedCostDialog: false,
            allocatedDesignation: null,
            activeCRs: [],
            allocatedProjectManager: false,
            isCancelAddPopUp: false,

            requestAllocation: false,
            requestAllocationSub: false,
            confirmStatus: false,
            confirmStatusSub: false,

            calculatedValues: null,
            approvedCalculatedValues: null,
            approveAddSubLine: false,
            //for soft booking
            isCanSoftBooking: false,
            softBookingDialog: false,
            IsSoftBookingResorce: false,
            goBackCancel: false,
            requestAllocationSoft: false,
            requestAllocationSoftResubmit: false,
            requestAllocationSubSoft: false,
            requestAllocationSubSoftResubmit: false,
            requestAllocationSoftDate: false,
            requestAllocationSoftSubDate: false,

            userDefinedEndDate: null,
            previousEndDate: null,

            //for line filter
            SelectedLineStatuses: [],
            LineStatus: [],
            LineStatusAll: [],
            selectedProjectOrCRName: [],
            filteredTotalCost: null,
            filteredTotalRevenue: null,
            filteredCostToRevenueRatio: null,
            forFilterResourceLine: [],
            filteredResourceMix: [],
            excelDataObsolete: false,

            isLoading: true,
            matchingWorkflowList: [],
            selectedWorkFlowId: null,
            visibleLineStatus: false,
            managerComment: null,
            lineStatusHistoryList: [],

            resourchSearchVal: "",
            filteredResourceData: [],
            resourceSearchCellSelectedDataItm: null,
            requestType: null,
            isSubLinesValid: true,
            lineCostVariance: 0,
            isMainLinesAllocated: true,
            isSelectedValidMainLines: false,
            hasCMSSubLines: false,
            isAddedFromAllocation: false,
            CRsOfAPro: [],
            isDisableAllocationLineAddBtn: true,
            mainmoredetails: false,
            isEditResource: false,
            // for actual calculations
            cmsCalculations: null,
            calculatedActValues: null,
            showCostValidationDialog: false,
            isNoCostVariance: false,
            isNoCostVarianceSubLine: false,
            showCostValidationDialog: false,
            designations: [],
            pmDesignations: [],
            pmDesignationsAll: [],
            designationsWithoutPms: [],
            designationsWithoutPmsAll: [],
            approveResourcesDialogDisabled: false,
            isInititalPM:  false,
            otherExpenses: [],
            selectedItemRA: null
        }


        this.toggleDialog = this.toggleDialog.bind(this);

        this.expandChange = this.expandChange.bind(this);

        this.toggleLineStatusDialog = this.toggleLineStatusDialog.bind(this);

        this.toggleMoreDialog = this.toggleMoreDialog.bind(this);


    }

    expandChange = (event) => {
        event.dataItem.expanded = !event.dataItem.expanded;

        this.setState({
            selectedMainLineIndex: event.dataItem.index
        });
        this.forceUpdate();
    };

    onClickMore = async (dataItem) => {
        this.toggleMoreDialog(dataItem);

    }

    toggleMoreDialog(dataItem) {
        this.setState({
            mainmoredetails: !this.state.mainmoredetails,
            dataItem: dataItem
        });
    }

    onClickLineStatus = async (dataItem) => {
        if (dataItem.ResourceRequestId) {
            await this.getLineHistory(dataItem.ResourceRequestId, dataItem.id);
        } else {
            await this.getLineHistory(dataItem.id, null);
        }
        this.toggleLineStatusDialog(dataItem);
    };

    toggleLineStatusDialog(dataItem) {
        this.setState({
            visibleLineStatus: !this.state.visibleLineStatus,
            dataItem: dataItem
        });
    }

    getCRSOfPro = async (project) => {
        await getCRsOfAProject(project.id).then(res => {
            if (res.data && res.data.length > 0) {
                this.setState({
                    CRsOfAPro: res.data
                });
            }
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

    }

    toggleDialogResourceSearch = (dataItem, toggleStatus) => {

        // Check user
        const loggedInUserRoles = window.LOGGED_USER && window.LOGGED_USER.roles ? window.LOGGED_USER.roles : [];

        let loggedInUserIsResourceAllocator = false;
        let resManagement = [];
        for (const role of loggedInUserRoles) {
            if (role.Rolename === 'RP') {
                resManagement = role.Permissions.filter(res => res.PermissionName === 'RES_ALC');
                if (resManagement.length > 0) {
                    loggedInUserIsResourceAllocator = true
                }
            }
        }


        if (loggedInUserIsResourceAllocator) {

            if (dataItem.ResourceRequestSubLine && dataItem.ResourceRequestSubLine.length > 0) {
                return;
            }

            if (toggleStatus == 'OPEN') { // Openning toggle
                this.setState({
                    visible: !this.state.visible,
                    resourchSearchVal: "",
                    resourceSearchCellSelectedDataItm: dataItem,
                    filteredResourceData: []
                });
            } else { // Close toggle
                this.setState({
                    visible: !this.state.visible,
                    resourchSearchVal: "",
                    resourceSearchCellSelectedDataItm: null,
                    filteredResourceData: []
                });
            }
        }

    };

    async componentDidMount() {
        if (this.props.location.hasOwnProperty('project')) {
            await this.getCRSOfPro(this.props.location.project)
            this.isMount = true;
            this.setHeaderTitle();
            await this.populateData();
            await this.getInitialData();
        }
        this.getAllRateTypes()
    }
    getAllRateTypes= async ()=>{
        await getAllRateTypes().then(res=>{
          this.setState({
            allRateTypes:res.data
          })
        })
      }
    setHeaderTitle = async () => {
        if (this.props.location.action === 'RPApprove') {
            this.props.onHeaderTitleChange('Resource Allocation');
        } else if (this.props.location.action === 'RPAllocateWF') {
            this.props.onHeaderTitleChange('Resource Allocation Approval');
        } else {
            this.props.onHeaderTitleChange('Resource Request');
        }
    };

    populateLineStatus = async () => {
        let defaultStatus = [];
        await getLineStatus()
            .then(res => {
                if (this.isMount) {
                    this.setState({
                        LineStatus: res.data,
                        LineStatusAll: res.data,
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        //for default status filter
        if (this.state.LineStatus) {
            for (const status of this.state.LineStatus) {
                if (status.code === 'NEW_FROM_CMS' || status.code === 'NEW_FROM_RM' || status.code === 'CONFIRMED' || status.code === 'EDIT') {
                    defaultStatus.push(status);
                }
            }


            this.setState({
                SelectedLineStatuses: defaultStatus,
            });


        }




    };

    getInitialData = async () => {
        this.setState({
            isLoading: true
        });
        const idList = this.getIDListArray();
        const param = idList.join(',');
        await this.populateConsolidatedResourceMix();
        await this.handleCalculations();
        await this.handleActualCalculations();
        await this.filterCalculations();
        await this.submitSearch();
        await this.setRequestTypes();
        this.setState({
            isLoading: false
        });
    };

    setRequestTypes = async () => {
        let requetTypes = [];
        const PRE_SALES = " Pre Sales";
        const SOFT_BOOKING = " Soft Booking";
        const PROJECT = " Project";

        for (var item of this.state.resourceMix) {

            if (item.IsPreSales) { // Pre sales check
                if (requetTypes.filter(element => element == PRE_SALES).length == 0) {
                    requetTypes.push(PRE_SALES);
                }
            } else if (item.IsSoftBookingResource) { // Soft booking check
                if (requetTypes.filter(element => element == SOFT_BOOKING).length == 0) {
                    requetTypes.push(SOFT_BOOKING);
                }
            } else {
                if (requetTypes.filter(element => element == PROJECT).length == 0) {
                    requetTypes.push(PROJECT);
                }
            }
        }

        this.setState({
            requestType: requetTypes.join()
        });
    };

    componentWillUnmount() {
        this.isMount = false;
    }

    handleInputChange = event => {
        const value = event.target.value;
        const field = event.target.name;
        this.setState({
            [field]: value
        });
    };

    populateData = async () => {
        this.setAllNames();
        this.setEndDate();
        this.populateLineStatus();
        const resDesg = await getAllDesignationsIntegrated();
        const resReg = await getResourceRegionsIntegrated();
        const resUsers = await getActiveProjectResources();
        const expsenseTypes = await getExpenseTypes();

        await getPaymentMethods().then(response => {
            this.paymentMethodsAll = response.data;
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        await getRateTypesIntegrated().then(resRT => {
            let rateTypes = [];
            if (resRT.data && resRT.data.length > 0) {
                for (const rateType of resRT.data) {
                    if (rateType.Code !== CUSTOM_RATE) {
                        rateType.Rate = 0;
                        rateType.name = rateType.Name + ' - 0.00';
                    } else {
                        rateType.name = rateType.Name;
                    }
                    rateTypes.push(rateType);
                }
            }
            this.state.newRatesAll = rateTypes;
            this.rateTypesAll = rateTypes;
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        this.designationsAll = resDesg.data;
        this.regionsAll = resReg.data;
        this.usersAll = resUsers.data;

        this.getPhasesAndSubPhases(this.state.project.id);

        //set min date for add resource request
        let oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

        //getActiveCRs
        if (this.state.project.AllChangeRequests){
            this.state.project.ChangeRequests = this.state.project.AllChangeRequests;
        }
        const allCRs = this.state.project.ChangeRequests;
        let filteredCRs = [];
        if(allCRs && allCRs.length >0)
        {
            filteredCRs = allCRs.filter(eachCR => eachCR.StatusConvertedToActive === true);
            for (let cr of filteredCRs) {
                cr.cmsVersion = cr.CMSheets[0].MajorVersion + '.' + cr.CMSheets[0].Version;
            }
        }

        const pmDesignations = resDesg.data.slice().filter(resource =>
            resource.Name.includes('Manager')
          );
        const designationsWithoutPms = resDesg.data.slice().filter(resource =>
            !resource.Name.includes('Manager')
        );

        if (this.isMount) {
            await this.setState(
                {
                    RolesAll: resDesg.data,
                    RegionsAll: resReg.data,
                    ExpenseTypesAll: expsenseTypes.data ? expsenseTypes.data.filter(obj => obj.IsPoolProject === false): [],
                    addResourceMinDate: oneWeekAgo,
                    activeCRs: filteredCRs,
                    designations: resDesg.data,
                    pmDesignations: pmDesignations,
                    pmDesignationsAll: pmDesignations,
                    designationsWithoutPms: designationsWithoutPms,
                    designationsWithoutPmsAll:designationsWithoutPms
                });
        }

        await this.populateOtherExpenses();

    };

    populateOtherExpenses = async () => {
        let otherExpenses = [];
        const validProjectAndCrs = this.state.namesAll;
        for (const record of validProjectAndCrs) {
          const cmsId = record.CMSheetId;
          const cmsVersion = record.CMSheetVersion;
          let cmsOtherExpenses = [];
          let actualOtherExpenses = [];
          let actualOtherExpensesPreviousCMS = [];
    
          await getCMSheetOtherExpenses(cmsId, cmsVersion).then(res => {
            cmsOtherExpenses = res.data;
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    
          await getActualOtherExpenses(cmsId, cmsVersion).then(res => {
            actualOtherExpenses = res.data;
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });

          await getActualOtherExpensesPreviousCMS(cmsId, cmsVersion).then(res => {
            actualOtherExpensesPreviousCMS = res.data;
            if (actualOtherExpensesPreviousCMS && actualOtherExpensesPreviousCMS.length > 0) {
              actualOtherExpenses = actualOtherExpenses.concat(actualOtherExpensesPreviousCMS);
            }
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    
          let projcurrencyId = 0;
          await getRegionCurrencyIntegrated(record.billingRegion).then(response => {
            projcurrencyId = response.data.CurrencyId;
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
          const proStartDate = new Date(record.StartDate);
          const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
            projcurrencyId,
            proStartDate.getMonth() + 1,
            proStartDate.getFullYear()
          );
    
          if (cmsOtherExpenses && cmsOtherExpenses.length > 0) {
            for (const cmsExpense of cmsOtherExpenses) {
              const actualExpense = actualOtherExpenses.filter(val => val.ExpenseTypeId === cmsExpense.ExpenseTypeId && val.CMSOtherExpenseId === cmsExpense.id);
              const expenseType = this.state.ExpenseTypesAll.filter(obj => obj.id === cmsExpense.ExpenseTypeId);
              
              const otherExpenseObj = {
                CMSheetId: cmsId,
                CMSOtherExpenseId: cmsExpense.id,
                CMSheetVersion: cmsVersion,
                ProjectId: actualExpense.length > 0 ? actualExpense[0].ProjectId : record.type == 'Project' ? record.id : record.ProjectId,
                CrId: actualExpense.length > 0 ? actualExpense[0].CrId : record.type == 'ChangeRequest' ? record.id : null,
                ProjectName: record.name,
                ProjectStartDate: record.StartDate,
                ExpenseType: expenseType.length > 0 ? expenseType[0].Name : null,
                ExpenseTypeId: cmsExpense.ExpenseTypeId,
                BudgetedOccurrences: cmsExpense.BudgetedOccurrences,
                BudgetedRate: Number(cmsExpense.BudgetedRate / projrateRecordCurrencyRate).toFixed(2),
                BillableToClient: cmsExpense.BillableToClient ? "Billable" : "Non-Billable",
                CMSCost: Number(cmsExpense.Cost / projrateRecordCurrencyRate).toFixed(2),
                TotalActualCost: actualExpense.length > 0 ? actualExpense[0].TotalActualCost : 0,
                IsResourceRequest: actualExpense.length > 0 ? actualExpense[0].IsResourceRequest : false,
                ActualExpense: actualExpense.length > 0 ? actualExpense[0] : null,
                ActualExpenseId: actualExpense.length > 0 ? actualExpense[0].id : null
              }
              otherExpenses.push(otherExpenseObj);
            }
          }
    
          const expensesFromResourceRequest = actualOtherExpenses.filter(val => val.IsResourceRequest);
          if (expensesFromResourceRequest && expensesFromResourceRequest.length > 0) {
            for (const expense of expensesFromResourceRequest) {
              const cmsExpense = cmsOtherExpenses.filter(val => val.ExpenseTypeId === expense.ExpenseTypeId && val.id === expense.CMSOtherExpenseId);
              if (cmsExpense && cmsExpense.length <= 0) {
                const expenseType = this.state.ExpenseTypesAll.filter(obj => obj.id === expense.ExpenseTypeId);
                const otherExpenseObj = {
                  CMSheetId: cmsId,
                  CMSOtherExpenseId: expense.CMSOtherExpenseId,
                  CMSheetVersion: cmsVersion,
                  ProjectId: expense.ProjectId ? expense.ProjectId : record.type == 'Project' ? record.id : record.ProjectId,
                  CrId: expense.CrId > 0 ? expense.CrId : record.type == 'ChangeRequest' ? record.id : null,  
                  ProjectName: record.name,
                  ProjectStartDate: record.StartDate,
                  ExpenseType: expenseType.length > 0 ? expenseType[0].Name : null,
                  ExpenseTypeId: expense.ExpenseTypeId,
                  BudgetedOccurrences: 0,
                  BudgetedRate: 0,
                  BillableToClient: expense.BillableToClient ? "Billable" : "Non-Billable",
                  CMSCost: 0,
                  TotalActualCost: expense.TotalActualCost,
                  IsResourceRequest: expense.IsResourceRequest,
                  ActualExpense: expense,
                  ActualExpenseId: expense.id
                }
                otherExpenses.push(otherExpenseObj);
              }
            }
          }

          if (actualOtherExpensesPreviousCMS && actualOtherExpensesPreviousCMS.length > 0) {
            for (const expense of actualOtherExpensesPreviousCMS) {
              const allowEditDelete = expense.ActualOtherExpensesDate && expense.ActualOtherExpensesDate.length > 0 ? false : true;
              const expenseData = otherExpenses.filter(val => val.ExpenseTypeId === expense.ExpenseTypeId && val.CMSheetId === expense.CMSheetId);
              if (expenseData && expenseData.length <= 0) {
                const expenseType = this.state.ExpenseTypesAll.filter(obj => obj.id === expense.ExpenseTypeId);
                const otherExpenseObj = {
                  CMSheetId: cmsId,
                  CMSOtherExpenseId: expense.CMSOtherExpenseId,
                  CMSheetVersion: cmsVersion,
                  ProjectId: expense.ProjectId ? expense.ProjectId : record.type == 'Project' ? record.id : record.ProjectId,
                  CrId: expense.CrId > 0 ? expense.CrId : record.type == 'ChangeRequest' ? record.id : null,  
                  ProjectName: record.name,
                  ProjectStartDate: record.StartDate,
                  ExpenseType: expenseType.length > 0 ? expenseType[0].Name : null,
                  ExpenseTypeId: expense.ExpenseTypeId,
                  BudgetedOccurrences: 0,
                  BudgetedRate: 0,
                  BillableToClient: expense.BillableToClient ? "Billable" : "Non-Billable",
                  CMSCost: 0,
                  TotalActualCost: expense.TotalActualCost && expense.TotalActualCost > 0 ? Number(expense.TotalActualCost).toFixed(2) : 0,
                  IsResourceRequest: expense.IsResourceRequest,
                  ActualExpense: expense,
                  ActualExpenseId: expense.id,
                  allowEditDelete: allowEditDelete
                }
                otherExpenses.push(otherExpenseObj);
              }
            }
          }
        }
        await this.setState({
          otherExpenses: otherExpenses
        });
      }

      calculateActualNonBillableOtherExpenses = async(cmsId, cmsVersion, projectStatus) => {
        let otherExpensesTotal = 0;
        const closedStatusList = ['Closed', 'Discontinue', 'Close without bidding', 'Closed-Opportunity Loss', 'Complete'];
        const otherExpenses = this.state.otherExpenses;
        const projectOrCrOtherExpenses = otherExpenses.filter(val => val.CMSheetId == cmsId && val.BillableToClient === "Non-Billable");
        if (!closedStatusList.includes(projectStatus)) {
          for (const expense of projectOrCrOtherExpenses) {
            if (Number(expense.CMSCost) >= Number(expense.TotalActualCost)) {
              otherExpensesTotal += Number(expense.CMSCost);
            } else {
              otherExpensesTotal += Number(expense.TotalActualCost);
            }
          }
        } else {
          for (const expense of projectOrCrOtherExpenses) {
            if (expense.TotalActualCost !== 0) {
                otherExpensesTotal += Number(expense.TotalActualCost);
            } else {
                otherExpensesTotal += Number(expense.CMSCost);
            }
          }
        }
        return otherExpensesTotal;
      }

    rowRender(trElement, props) {
        const isHeader = props.dataItem.isHeader;
        const className = 'summery-header-style';
        const trProps = { class: isHeader === true ? className : null };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    rowRenderSB(sbElement, props) {
        const isSoftBooking = props.dataItem.IsSoftBookingResource ? true : false
        const isPreSales = props.dataItem.IsPreSales ? true : false
        const className = 'soft-booking-style';
        const presalesclass = 'pre-sales-style';
        const sbProps = { id: isSoftBooking ? className : (isPreSales ? presalesclass : null) };
        return React.cloneElement(sbElement, { ...sbProps }, sbElement.props.children);
    }

    getStartDate = () => {
        let dates = [];
        let minDate = "";
        if (this.state.project.StartDate) {
            dates.push(reStructureDate(this.state.project.StartDate));
        }
        if(this.state.project && this.state.project.ChangeRequests &&  this.state.project.ChangeRequests.length > 0)
        {
            for (let cr of this.state.project.ChangeRequests) {
                if (cr.StartDate) {
                    dates.push(reStructureDate(cr.StartDate));
                }
            }
        }
        if (dates && dates.length > 0) {
            const earliest = reStructureDate(Math.min.apply(null, dates));
            minDate = moment(earliest).format('MM-DD-YYYY');
        }
        return minDate;
    };

    getEndDate = () => {
        let dates = [];
        if (this.state.project.EndDate) {
            dates.push(reStructureDate(this.state.project.EndDate));
        }
        for (let cr of this.state.project.ChangeRequests) {
            if (cr.EndDate) {
                dates.push(reStructureDate(cr.EndDate));
            }
        }
        if (dates.length > 0) {
            const latest = reStructureDate(Math.max.apply(null, dates));
            let maxDate = moment(latest).format('MM-DD-YYYY');
            return maxDate;
        } else {
            return '';
        }
    };

    handleProjEndDateChange = (event) => {
        if (this.state.project.UserDefinedEndDate === null) {
            this.setState({
                userDefinedEndDate: event.value
            }
            )
        } else {
            this.setState({
                previousEndDate: this.state.project.UserDefinedEndDate
            },
                () => {
                    this.setState({
                        userDefinedEndDate: event.value
                    })
                }
            )
        }
    };

    getMaxEndDate = () => {
        var projectStartDate = this.getStartDate();
        var resourceLineEndDate = new Date();
        for (let eachLine of this.state.resourceMix) {
            if (reStructureDate(eachLine.EndDate) > reStructureDate(resourceLineEndDate)) {
                resourceLineEndDate = eachLine.EndDate;
            }
        }
        if ((reStructureDate(projectStartDate) > new Date()) && ((reStructureDate(projectStartDate) > reStructureDate(resourceLineEndDate)))) {
            return projectStartDate;
        } else if ((reStructureDate(resourceLineEndDate) > new Date()) && (reStructureDate(resourceLineEndDate) > reStructureDate(projectStartDate))) {
            return resourceLineEndDate;
        } else {
            var currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + 1);
            return currentDate;
        }
    };

    getProjCMS = () => {
        let projCMS;
        if (this.props.location.project && this.props.location.project.CMSheets && this.props.location.project.CMSheets.length > 0) {
            projCMS = this.props.location.project.CMSheets[0].MajorVersion + '.' + this.props.location.project.CMSheets[0].Version;
        } else {
            projCMS = null;
        }
        return projCMS;
    };

    /* getCRNames = () => {
        const crNames = []
        for (let cr of this.state.project.ChangeRequests) {
            crNames.push(cr.CRName)
        }
        return crNames.join(', ')
    }

    getVersions = () => {
        let versions = ["1." + this.props.location.project.CMSheets[0].Version]
        for (let cr of this.props.location.project.ChangeRequests) {
            versions.push("1." + cr.CMSheets[0].Version)
        }
        return versions.join(", ")
    }*/

    async getPhasesAndSubPhases(ProjectId) {
        getPhasesAndSubPhases(ProjectId)
            .then(res => {
                let existingPhasesArray = [];
                const projectAndCr = res.data;
                let tempProjAndCrDataArray = [];
                let phasesSubPhasesObj = [];
                if (projectAndCr && projectAndCr.length > 0) {
                    projectAndCr.forEach(function (data) {
                        if (data.CMSheets[0].Phases.length > 0) {
                            phasesSubPhasesObj.push(data);
                        }
                        if (data.ProjectName) {
                            const projObj = {
                                Type: 'Project',
                                Name: data.ProjectName,
                                id: data.id,
                                Project: data
                            };
                            tempProjAndCrDataArray.push(projObj);
                        } else {
                            const crObj = {
                                Type: 'ChangeRequest',
                                Name: data.CRName,
                                id: data.id,
                                ChangeRequest: data
                            };
                            tempProjAndCrDataArray.push(crObj);
                        }

                        if (data.CMSheets && data.CMSheets.length > 0) {
                            const existingPhases = data.CMSheets[0].Phases;

                            if (existingPhases && existingPhases.length > 0) {
                                for (const phase of existingPhases) {
                                    existingPhasesArray.push(phase);
                                }
                            }
                        }


                    });
                }

                this.setState({
                    phasesTemp: existingPhasesArray,
                    phasesTempAll: existingPhasesArray,
                    phasesSubPhases: phasesSubPhasesObj,
                    projectAndCrData: tempProjAndCrDataArray
                });

            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    /*getCRIDs = () => {
        const crIDs = []
        for (let cr of this.state.project.ChangeRequests) {
            crIDs.push(cr.UniqueId)
        }
        return crIDs.join(',')
    }*/

    getIDListArray = () => {
        let idList = [];
        const projectVersion = this.state.project && this.state.project.CMSheets && this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].MajorVersion + '.' + this.state.project.CMSheets[0].Version : null;
        const projectID = '\'' + ( this.state.project && this.state.project.CMSheets && this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null) + projectVersion + '\'';
        idList.push(projectID);

        if(this.state.project && this.state.project.ChangeRequests && this.state.project.ChangeRequests.length > 0)
        {
            for (const cr of this.state.project.ChangeRequests) {
                if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
                    const crVersion = cr.CMSheets[0].MajorVersion + '.' + cr.CMSheets[0].Version;
                    const crID = '\'' + cr.CMSheets[0].id + crVersion + '\'';
                    idList.push(crID);
                }
            }
        }        
        return idList;
    };

    
    setAllNames = async () => {

        let theList = [];
        let crList = [];
        let allObjs = [];

        const projectObj = {
            id: this.state.project.id,
            name: this.state.project.ProjectName,
            type: 'Project',
            CMSheetId: this.state.project && this.state.project.CMSheets && this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null,
            CMSheetVersion: this.state.project && this.state.project.CMSheets && this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Version : null,
            StartDate: this.state.project.StartDate,
            paymentMethod: this.state.project.PaymentMethod,
            billingRegion: this.state.project.BillingRegion ? this.state.project.BillingRegion : this.state.project.Region ? this.state.project.Region.id : null,
            customerId: this.state.project.CustomerId,
            countryId: this.state.project.CountryId
        };

        theList.push(projectObj);
        allObjs.push(projectObj);

        for (const cr of this.state.CRsOfAPro) {
            if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
                const crObj = {
                    id: cr.id,
                    name: cr.CRName,
                    type: 'ChangeRequest',
                    CMSheetId: cr.CMSheets[0].id,
                    CMSheetVersion: cr.CMSheets[0].Version,
                    StartDate: cr.StartDate,
                    paymentMethod: cr.PaymentMethodId,
                    billingRegion: cr.BillingRegion,
                    customerId: cr.CustomerId,
                    countryId: cr.CountryId,
                    ProjectId: cr.ProjectId
                };
                theList.push(crObj);
            }
            const anyCRObj = {
                id: cr.id,
                name: cr.CRName,
                type: 'ChangeRequest',
                CMSheetId: cr.CMSheets.length > 0 ? cr.CMSheets[0].id : null,
                CMSheetVersion: cr.CMSheets.length > 0 ? cr.CMSheets[0].Version : null,
                StartDate: cr.StartDate,
                paymentMethod: cr.PaymentMethodId,
                billingRegion: cr.BillingRegion,
                customerId: cr.CustomerId
            };
            allObjs.push(anyCRObj);
        }

        this.allTheNames = theList;

        await this.setState({
            namesAll: theList,
            proObj: projectObj,
            allCrsAndProjs: allObjs
        });

        //check allocated PM is in project or CR
        let crAll = [];
        let theCRList = [];
        if (this.state.project.ResourceRequestStatus === 'PM_ALLOCATED') {
            if (this.state.project.AllocatedResourceRequestPM && this.state.project.AllocatedResourceRequestPM.length > 0) {
                for (const pm of this.state.project.AllocatedResourceRequestPM) {
                    if (pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
                        this.state.allocatedProjectManager = true
                    }
                }
            }
        }
        if (this.state.project.ChangeRequests && this.state.allocatedProjectManager === false) {
            for (const cr of this.state.project.ChangeRequests) {
                if (cr.ResourceRequestStatus === 'PM_ALLOCATED') {
                    if (cr.AllocatedPM && cr.AllocatedPM.length > 0) {
                        for (const pm of cr.AllocatedPM) {
                            if (pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
                                crAll.push(cr)
                            }
                        }
                    }
                }
            }
        }

        for (const cr of crAll) {
            if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
                const crObj = {
                    id: cr.id,
                    name: cr.CRName,
                    type: "ChangeRequest",
                    CMSheetId: cr.CMSheets[0].id,
                    CMSheetVersion: cr.CMSheets[0].Version,
                    StartDate: cr.StartDate,
                    paymentMethod: cr.PaymentMethodId,
                    billingRegion: cr.BillingRegion,
                    customerId: cr.CustomerId,
                };
                theCRList.push(crObj)
            }
        }

        await this.setState({
            crObj: theCRList
        });
    }

    setEndDate = async () => {
        //resource Mix max end date
        var resourceLineEndDate = new Date();
        for (let eachLine of this.state.resourceMix) {
            if (new Date(eachLine.EndDate) > new Date(resourceLineEndDate)) {
                resourceLineEndDate = eachLine.EndDate;
            }
        }
        if (this.state.project.UserDefinedEndDate === null) {
            var project = this.state.project;
            var userDefinedEndDate = null;
            var userDefinedEndDateandTime = [];
            if (project.ConvertedToActiveAt !== null) { //only during activation this date gets saved
                if (project.StartDate !== project.EstimatedStartDate) {
                    var totalEffortDays = await this.calculateTotalEffort(project.EstimatedStartDate, project.StartDate, project.CountryId);
                    if (totalEffortDays > 0) {
                        let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, project.EstimatedEndDate, project.CountryId);
                        project.EstimatedEndDate = endDate;
                    }
                    project.EstimatedStartDate = project.StartDate;
                }
                userDefinedEndDateandTime = [project.EstimatedEndDate, project.ConvertedToActiveAt];
            }

            for (const cr of project.ChangeRequests) {
                if (cr.ConvertedToActiveAt && cr.ConvertedToActiveAt !== null) {
                    if (cr.StartDate !== cr.EstimatedStartDate) {
                        var totalEffortDays = await this.calculateTotalEffort(cr.EstimatedStartDate, cr.StartDate, cr.CountryId);
                        if (totalEffortDays > 0) {
                            let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, cr.EstimatedEndDate, cr.CountryId);
                            cr.EstimatedEndDate = endDate;
                        }
                        cr.EstimatedStartDate = cr.StartDate;
                    }
                    if (userDefinedEndDateandTime.length === 0) {
                        userDefinedEndDateandTime = [cr.EstimatedEndDate, cr.ConvertedToActiveAt];
                    } else if (userDefinedEndDateandTime.length > 0 && cr.ConvertedToActiveAt < userDefinedEndDateandTime[1]) {
                        userDefinedEndDateandTime = [cr.EstimatedEndDate, cr.ConvertedToActiveAt];
                    }
                }
            }
            if (userDefinedEndDateandTime[0] && (new Date(userDefinedEndDateandTime[0]) > new Date()) && (new Date(resourceLineEndDate) < new Date(userDefinedEndDateandTime[0])) && (new Date(userDefinedEndDateandTime[0]) > new Date(this.getStartDate()))) {
                userDefinedEndDate = reStructureDate(userDefinedEndDateandTime[0]);
            }

            this.setState({
                project: project,
                userDefinedEndDate: userDefinedEndDate
            })
        } else {
            await this.setState({
                previousEndDate: reStructureDate(this.state.project.UserDefinedEndDate)
            })
            if ((new Date(this.state.project.UserDefinedEndDate) > new Date()) && (new Date(resourceLineEndDate) < new Date(this.state.project.UserDefinedEndDate))) {
                this.setState({
                    userDefinedEndDate: reStructureDate(this.state.project.UserDefinedEndDate)
                })
            }
        }
    };

    onUserDefinedEndDateChange = () => {
        const userDetails = window.LOGGED_USER;
        const auditTrail = {
            Action: this.state.previousEndDate === null ? ('End date revised to ' + moment(this.state.userDefinedEndDate).format('MM-DD-YYYY')) : ('End date revised to ' + moment(this.state.userDefinedEndDate).format('MM-DD-YYYY') + ' from ' + moment(this.state.previousEndDate).format('MM-DD-YYYY')),
            RefType: 'Resoure Request',
            RefId: this.state.project.id,
            RefName: this.state.project.ProjectName
        };
        saveAuditTrail(auditTrail);
    };

    calculateTotalEffort = async (startDateIn, endDateIn, projOrCrCountryId) => {

        if (startDateIn && endDateIn) {
            let startDate = reStructureDate(startDateIn);
            let endDate = reStructureDate(endDateIn);

            let defaultCountry = projOrCrCountryId;

            let currentYear = startDate.getFullYear();
            let startDateTimeZone = reStructureDate(startDate);
            startDateTimeZone.setHours(0, 0, 0, 0);
            let end_date = moment(reStructureDate(endDate));
            let start_date = moment(reStructureDate(startDateTimeZone));

            let loop = reStructureDate(start_date);
            let totalWorkingDays = 0;
            let isHoliday = false;

            while (currentYear <= endDate.getFullYear()) {
                // Holidays
                let holidays = await getHolidaysIntegrated(defaultCountry, currentYear).then(res => {
                    if (res && res.data) { return res.data; }
                    return [];
                }).catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                    return [];
                });

                if (holidays && holidays.length === 0) {
                    console.log('Holidays not found for ' + startDate.getFullYear());
                } else if (!holidays) {
                    holidays = [];
                }
                while (loop <= end_date && loop <= reStructureDate(currentYear, 11, 31)) {
                    isHoliday = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (!isHoliday && !isWeekend(loop)) {
                        totalWorkingDays++;
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = reStructureDate(newDate);
                }

                currentYear = currentYear + 1;

            }
            return totalWorkingDays;
        }

    };

    calculateNewEstimatedEndDate = async (workingDays, startDate, countryId) => {
        let startDateObj = reStructureDate(startDate);
        let endDate;

        // estimate end date
        const noOfWorkingDays = workingDays;

        let totalDays = 0;
        let workingDaysCount = 0;

        let holidays = await getHolidaysIntegrated(countryId, startDateObj.getFullYear()).then(
            res => {
                if (res && res.data) {
                    return res.data;
                }
                return [];
            }
        )
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        if (holidays && holidays.length === 0) {
            console.log('Holidays not found for ' + startDateObj.getFullYear());
        } else if (!holidays) {
            holidays = [];
        }

        // set start date when start date is a weekday or holiday
        let formattedStartDate = reStructureDate(startDate);
        while((isWeekend(formattedStartDate)) || (holidays.indexOf(getFormattedDate(formattedStartDate)) > -1)){
            formattedStartDate.setDate(formattedStartDate.getDate() + 1);
        }

        endDate = reStructureDate(formattedStartDate);
        let currentYear = startDateObj.getFullYear();
        let isHoliday = false;

        while (workingDaysCount < noOfWorkingDays - 1 || isWeekend(endDate) || isHoliday) {
            if (currentYear !== endDate.getFullYear()) {
                holidays = await getHolidaysIntegrated(countryId, endDate.getFullYear())
                    .then(res => {
                        if (res && res.data) {
                            return res.data;
                        }
                        return [];
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        return [];
                    });

                currentYear = endDate.getFullYear();
            }

            totalDays++;
            endDate.setDate(endDate.getDate() + 1);

            isHoliday = false;
            const dateStr = getFormattedDate(endDate);
            if (holidays.indexOf(dateStr) > -1) {
                isHoliday = true;
            }

            if (!isWeekend(endDate) && !isHoliday) {
                workingDaysCount++;
            }
        }

        endDate.setDate(endDate.getDate());
        return { endDate, noOfWorkingDays, totalDays };
    };

    getCMSheetVersionAndIdList = () => {
        let theList = [];
        // check if the project is activated or is in Waiting Confirmation status
        if ((this.state.project && this.state.project.StatusConvertedToActive && this.state.project.StatusConvertedToActive === true) || this.state.project.Status === 2) {
            const projectVersionObj = {
                id: this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null,
                Version: this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Version : null
            };
            theList.push(projectVersionObj);
        }
        if(this.state.project && this.state.project.ChangeRequests)
        {
            for (const cr of this.state.project.ChangeRequests) {
                if ((cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) || cr.StatusId === 2) { // check if the cr is activated or is in Waiting Confirmation status
                    if (cr.CMSheets && cr.CMSheets.length > 0) {
                        const crVersionObj = {
                            id: cr.CMSheets[0].id,
                            Version: cr.CMSheets[0].Version
                        };
                        theList.push(crVersionObj);
                    }
                }
            }
        }
        
        return theList;
    };


    populateConsolidatedResourceMix = async () => {
        const IdList = this.getCMSheetVersionAndIdList();
        if (IdList && IdList.length > 0) {
            if (this.props.location.action === 'RPApprove' || this.props.location.action === 'RPAllocateWF') {
                await getResourceRequestResourceMixData(IdList).then(async response => {
                    const res = response.data;
                    const allocationSummaryResources = [];
                    for (let resource of res) {
                        for (let element of resource) {
                            allocationSummaryResources.push(element);
                        }
                    }
                    this.setState({
                        allocationSummaryResources: allocationSummaryResources
                    });
                });
            }
            let pendingLinesForApprove = [];
            const tempPendingTaskRes = await getProjectPendingTasks(this.state.project.id);
            if (tempPendingTaskRes && tempPendingTaskRes.data && tempPendingTaskRes.data.length > 0) {
                pendingLinesForApprove = tempPendingTaskRes.data;
            }
            await getResourceRequestResourceMixData(IdList).then(async response => {
                const res = response.data;

                this.setState({
                    forRemoveResource: res,
                    forSoftBook: res
                });

                const resources = [];
                let index = 0;
                let allProjectCRphases = [];
                var delayInMilliseconds = 1000; //1 second

                setTimeout(function () {
                    //your code to be executed after 1 second
                }, delayInMilliseconds);
                for (let resource of res) {
                    for (let element of resource) {
                        element.selected = false;
                        element.index = index;
                        element.name = this.getProjectOrCRName(element);

                        if (element.AllocatedResource) {
                            element.AllocatedResource.displayName = element.AllocatedResource.Name;
                        }

                        if (this.props.location.action === 'RPApprove') {
                            if (element.ResourceRequestSubLine.length > 0) {
                                var pendingSubline = false;
                                var sublinesAll = false;
                                var allocateSublines = [];
                                var ResourceRequestSubLinesAll = [];
                                for (const subline of element.ResourceRequestSubLine) {

                                    if (subline.Status === 'RES_ALLOC_PENDING' || subline.Status === 'RES_ALLOC_REJECTED' || (subline.Status === "COMPLETE" && subline.IsSoftBookingResource)) {

                                        let filteredAllocatedName = {};
                                        let filteredAllocatedDesig = {};

                                        if (subline.ResourceId) {
                                            // Name
                                            filteredAllocatedName = this.usersAll.filter(function (itm) {
                                                return subline.ResourceId === itm.id;
                                            });
                                            subline.AllocatedResource.Name = filteredAllocatedName[0].Name;

                                            // Designation
                                            if (filteredAllocatedName[0] && subline.AllocatedDesignationId) {
                                                filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                                                    return (subline.AllocatedDesignationId === itm.id);
                                                });

                                            } else {
                                                filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                                                    return (filteredAllocatedName[0].DesignationId === itm.id);
                                                });
                                            }
                                            if(filteredAllocatedDesig.length > 0) {
                                                subline.AllocatedDesignation = filteredAllocatedDesig[0].Name;
                                            }

                                            // Allocated region
                                            const AllocatedRegion = this.regionsAll.filter(function (itm) {
                                                return subline.AllocatedResource.Resource.Region === itm.id;
                                            });
                                            subline.AllocatedRegion = AllocatedRegion[0];
                                        }

                                        pendingSubline = true;
                                        allocateSublines.push(subline);
                                    }
                                    if (subline) {

                                        let filteredAllocatedName = {};
                                        let filteredAllocatedDesig = {};

                                        if (subline.ResourceId) {
                                            // Name
                                            filteredAllocatedName = this.usersAll.filter(function (itm) {
                                                return subline.ResourceId === itm.id;
                                            });
                                            subline.AllocatedResource = filteredAllocatedName[0];

                                            // Designation
                                            if (filteredAllocatedName[0] && subline.AllocatedDesignationId) {
                                                filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                                                    return (subline.AllocatedDesignationId === itm.id);
                                                });

                                            } else {
                                                filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                                                    if(filteredAllocatedName.length > 0){
                                                        return (filteredAllocatedName[0].DesignationId === itm.id);
                                                    }
                                                });
                                            }
                                            if(filteredAllocatedDesig.length > 0) {
                                                subline.AllocatedDesignation = filteredAllocatedDesig[0].Name;
                                            }
                                        }

                                        sublinesAll = true;
                                        ResourceRequestSubLinesAll.push(subline);
                                    }
                                }
                                if (pendingSubline === true) {
                                    element.ResourceRequestSubLine = allocateSublines;
                                    resources.push(element);
                                    index++;
                                }
                                if (sublinesAll === true) {
                                    element.ResourceRequestSubLinesAll = ResourceRequestSubLinesAll;
                                }
                            }
                            else if (element.Status === 'RES_ALLOC_PENDING' || element.Status === 'EDIT' || element.Status === 'RES_ALLOC_REJECTED' || (element.Status === "COMPLETE" && element.IsSoftBookingResource)) {
                                resources.push(element);
                                index++;
                            }
                        } else
                            if (this.props.location.action === 'RPAllocateWF') {
                            element.locationActionAttribute = "RPAllocateWF";
                            if (element.ResourceRequestSubLine.length > 0) {
                                var pendingSubline = false;
                                var requestedSublines = [];
                                for (const subline of element.ResourceRequestSubLine) {
                                    if (subline.Status === 'ALLOC_APP_PENDING') {
                                        const approvalSubLine = pendingLinesForApprove.filter(a => a.ResourceRequestId == subline.ResourceRequestId && a.SublineResourceRequestId == subline.id);
                                        if (approvalSubLine && approvalSubLine.length > 0) {

                                            let filteredAllocatedName = {};
                                            let filteredAllocatedDesig = {};

                                            if (subline.ResourceId) {
                                                // Name
                                                filteredAllocatedName = this.usersAll.filter(function (itm) {
                                                    return subline.ResourceId === itm.id;
                                                });
                                                subline.AllocatedResource.Name = filteredAllocatedName[0].Name;
    
                                                // Designation
                                                if (filteredAllocatedName[0] && subline.AllocatedDesignationId) {
                                                    filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                                                        return (subline.AllocatedDesignationId === itm.id);
                                                    });

                                                } else {
                                                    filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                                                        if(filteredAllocatedName.length > 0){
                                                            return (filteredAllocatedName[0].DesignationId === itm.id);
                                                        }
                                                    });
                                                }

                                                if(filteredAllocatedDesig.length > 0) {
                                                    subline.AllocatedDesignation = filteredAllocatedDesig[0].Name;
                                                }
    
                                                // Allocated region
                                                const AllocatedRegion = this.regionsAll.filter(function (itm) {
                                                    return subline.AllocatedResource.Resource.Region === itm.id;
                                                });
                                                subline.AllocatedRegion = AllocatedRegion[0];
                                            }

                                            pendingSubline = true;
                                            requestedSublines.push(subline);
                                        }
                                    }
                                }
                                if (pendingSubline === true) {
                                    element.ResourceRequestSubLine = requestedSublines;
                                    resources.push(element);
                                    index++;
                                }
                            }
                            else if (element.Status === 'ALLOC_APP_PENDING') {
                                const approvalLine = pendingLinesForApprove.filter(a => a.ResourceRequestId == element.id && a.SublineResourceRequestId == null);
                                if (approvalLine && approvalLine.length > 0) {
                                    resources.push(element);
                                    index++;
                                }
                            }
                        } else {
                            resources.push(element);
                            index++;
                        }

                        let allPhases = [];
                        if (this.state.phasesTempAll && this.state.phasesTempAll.length > 0) {
                            allPhases = this.state.phasesTempAll.filter(res => res.CMSheetId === element.CMSheetId);
                        }
                        element.Phases = allPhases;

                        if (element.PhaseId && allPhases.length > 0) {
                            let linePhase = allPhases.filter(res => res.id === element.PhaseId);
                            element.Phase = linePhase[0];
                            if (element.SubPhaseId) {
                                let lineSubPhase = null;
                                if (linePhase[0].SubPhases && linePhase[0].SubPhases.length > 0) {
                                    let subPhases = linePhase[0].SubPhases;
                                    lineSubPhase = subPhases.filter(res => res.id === element.SubPhaseId);
                                    element.SubPhase = lineSubPhase[0];               
                                }
                            }
                        } else {
                            element.Phase = null;
                            element.SubPhase = null; 
                        }

                        // if (element.Type === 'Project') {
                        //     if (element.Project && element.Project.CMSheets && element.Project.CMSheets.length > 0) {
                        //         const cmsheet = element.Project.CMSheets[0];
                        //         if (cmsheet.Phases && cmsheet.Phases.length > 0) {
                        //             for (const phase of cmsheet.Phases) {
                        //                 allProjectCRphases.push(phase);
                        //             }
                        //         }
                        //     }
                        // } else {
                        //     if (element.ChangeRequest && element.ChangeRequest.CMSheets && element.ChangeRequest.CMSheets.length > 0) {
                        //         const cmsheet = element.ChangeRequest.CMSheets[0];
                        //         if (cmsheet.Phases && cmsheet.Phases.length > 0) {
                        //             for (const phase of cmsheet.Phases) {
                        //                 allProjectCRphases.push(phase);
                        //             }
                        //         }
                        //     }
                        // }

                        if (element.ResourceRequestSubLinesAll && element.ResourceRequestSubLinesAll.length > 0) {
                            let index = 0;
                            let minStartDate = element.ResourceRequestSubLinesAll[0].StartDate;
                            let maxEndDate = element.ResourceRequestSubLinesAll[0].EndDate;
                            let cost = 0;
                            let revenue = 0;
                            let EffortDays = 0;
                            let CostVariance = 0;

                            for (const subLine of element.ResourceRequestSubLinesAll) {
                                
                                if (subLine.PhaseId && allPhases.length > 0) {
                                    let linePhase = allPhases.filter(res => res.id === subLine.PhaseId);
                                    subLine.Phase = linePhase[0];
                                    if (subLine.SubPhaseId) {
                                      let lineSubPhase = null;
                                      if (linePhase[0].SubPhases && linePhase[0].SubPhases.length > 0) {
                                        let subPhases = linePhase[0].SubPhases;
                                        lineSubPhase = subPhases.filter(res => res.id === subLine.SubPhaseId);
                                        subLine.SubPhase = lineSubPhase[0];               
                                      }
                                    }
                                } else {
                                    subLine.Phase = null;
                                    subLine.SubPhase = null; 
                                } 

                                if (subLine.EffortDays) {
                                    EffortDays += subLine.EffortDays;
                                }

                                if (minStartDate > subLine.StartDate) {
                                    minStartDate = subLine.StartDate;
                                }

                                if (maxEndDate < subLine.EndDate) {
                                    maxEndDate = subLine.EndDate;
                                }

                                if (subLine.Cost) {
                                    let tmpCost = Number(subLine.Cost);
                                    cost = cost + tmpCost;
                                }

                                if (subLine.Revenue) {
                                    revenue += Number(subLine.Revenue)
                                }

                                if (subLine.CostVariance) {
                                    CostVariance += Number(subLine.CostVariance);
                                }

                                element.TotalCost = cost.toFixed(2);

                                subLine.index = index;
                                index++;
                            }

                            if (this.props.location.action === 'RPApprove') {

                                // Set the revanue
                                if (revenue !== 0) {
                                    element.TotalRevenue = revenue.toFixed(2);
                                }

                                const costToRevenueRatio = Number(revenue) === 0 ? 0.00 : cost / revenue;
                                element.CostToRevenueRatio = costToRevenueRatio.toFixed(2);

                            } else {
                                element.TotalRevenue = revenue.toFixed(2);
                                const costToRevenueRatio = Number(revenue) === 0 ? 0.00 : cost / revenue;
                                element.CostToRevenueRatio = costToRevenueRatio.toFixed(2);
                            }

                            element.StartDate = minStartDate;
                            element.EndDate = maxEndDate;
                            element.EffortDays = EffortDays.toFixed(2);
                            element.CostVariance = CostVariance.toFixed(2);
                            element.Status = 'EDIT';

                        }
                        const costToRevenueRatio = Number(element.TotalRevenue) === 0 ? 0.00 : element.TotalCost / element.TotalRevenue;
                        element.CostToRevenueRatio = costToRevenueRatio.toFixed(2);  
                    }
                }

                const uniquePhases = [];
                // allProjectCRphases.map(x => uniquePhases.filter(a => a.id == x.id).length > 0 ? null : uniquePhases.push(x));

                resources.map(dataItem => Object.assign({ selected: false, expanded: false }, dataItem));
                let resourcesTemp = [];
                let filteredArray = [];
                for (const resource of resources) {
                    const resourceObj = JSON.parse(JSON.stringify(resource));
                    // resourceObj.Phases = uniquePhases;

                    const filteredArray = this.designationsAll.filter(function (itm) {
                        return resourceObj.dataValueProjectRole.CategoryId === itm.CategoryId;
                    });

                    resourceObj.Designations = filteredArray;
                    let filteredAllocatedName = {};
                    let filteredAllocatedDesig = {};
                    if (resource.AllocatedResourceID != null) {
                        filteredAllocatedName = this.usersAll.filter(function (itm) {
                            return resourceObj.AllocatedResourceID === itm.id;
                        });

                        if(filteredAllocatedName && filteredAllocatedName.length > 0){
                            resourceObj.AllocatedResource.Name = filteredAllocatedName[0].Name;
                            if (filteredAllocatedName[0] && resourceObj.AllocatedDesignationId) {
                                filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                                    return (resourceObj.AllocatedDesignationId === itm.id);
                                });

                            } else {
                                filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                                    if(filteredAllocatedName.length > 0){
                                        return (filteredAllocatedName[0].DesignationId === itm.id);
                                    }
                                });
                            }
                            if(filteredAllocatedDesig && filteredAllocatedDesig.length > 0 ) {
                                resourceObj.AllocatedDesignation = filteredAllocatedDesig[0].Name;
                            }
                       }
                        const AllocatedRegion = this.regionsAll.filter(function (itm) {
                            return resourceObj.AllocatedResource.Resource.Region === itm.id;
                        });
                        resourceObj.AllocatedRegion = AllocatedRegion[0];
                    }
                    resourcesTemp.push(resourceObj);
                }

                resourcesTemp.forEach(function (item) {
                    if (item.ResourceRequestSubLine.length > 0) {
                        item.expanded = true;
                    } else {

                    }
                });


                for (const resource of resourcesTemp) {
                    let countryId;
                    let projectOrCrCountry = resource.Type === "Project" ? resource.Project.CountryId : resource.ChangeRequest.CountryId;
                    resource.projectOrCrCountry = projectOrCrCountry;
                    if (resource.EndDate === null && resource.StartDate !== null) {
                      let totalEffortDays = 0;
                      totalEffortDays = (resource.EffortDays / (resource.AllocationPercentage / 100));
          
                      if (resource.OnsiteStatus === 'Onsite') {
                        countryId = resource.Type === "Project" ? resource.Project.CountryId : resource.ChangeRequest.CountryId;
                      } else {
                        countryId = resource.dataValueResourceRegion.DefaultCountryId;
                      }
          
                      if(resource.Type === 'Project'){
                        if(resource.StartDate < resource.Project.StartDate){
                          let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.Project.StartDate, countryId);
                          resource.EndDate = moment(endDate).format('YYYY-MM-DD');
                        }
                        else{
                          let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.StartDate, countryId);
                          resource.EndDate = moment(endDate).format('YYYY-MM-DD');
                        }
                      }
                      else if(resource.Type === 'ChangeRequest'){
                        if(resource.StartDate < resource.ChangeRequest.StartDate){
                          let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.ChangeRequest.StartDate, countryId);
                          resource.EndDate = moment(endDate).format('YYYY-MM-DD');
                        }
                        else{
                          let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.StartDate, countryId);
                          resource.EndDate = moment(endDate).format('YYYY-MM-DD');
                        }
                      }
                    }
          
                    
                    else if((resource.Type === 'Project') && (resource.StartDate < resource.Project.StartDate)){
                      let totalEffortDays = 0;
                      totalEffortDays = (resource.EffortDays / (resource.AllocationPercentage / 100));
          
                      if (resource.OnsiteStatus === 'Onsite') {
                        countryId = resource.Project.CountryId;
                      } else {
                        countryId = resource.dataValueResourceRegion.DefaultCountryId;
                      }
                      
                      let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.Project.StartDate, countryId);
                      resource.EndDate = moment(endDate).format('YYYY-MM-DD');
                    }
          
                    else if((resource.Type === 'ChangeRequest') && (resource.StartDate < resource.ChangeRequest.StartDate)){
                      let totalEffortDays = 0;
                      totalEffortDays = (resource.EffortDays / (resource.AllocationPercentage / 100));
          
                      if (resource.OnsiteStatus === 'Onsite') {
                        countryId = resource.ChangeRequest.CountryId;
                      } else {
                        countryId = resource.dataValueResourceRegion.DefaultCountryId;
                      }
                      
                      let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.ChangeRequest.StartDate, countryId);
                      resource.EndDate = moment(endDate).format('YYYY-MM-DD');
                    }
          
                }

                let locationAction = this.props.location.action;
                for (var item of resourcesTemp) {
                    if (item && item.ResourceRequestSubLine.length > 0) {
                        for (var subline of item.ResourceRequestSubLine) {
                            subline.locationActionAttribute = locationAction;
                            let projectOrCrCountry = item.Type === "Project" ? item.Project.CountryId : item.ChangeRequest.CountryId;
                            subline.projectOrCrCountry = projectOrCrCountry;
                        }
                    }
                }

                this.setState({
                    resourceMix: resourcesTemp,
                    allProjectCrPhases: uniquePhases,
                    forFilterResourceLine: resourcesTemp,
                });
            })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        } else {
            this.setState({
                resourceMix: []
            });
        }
        if (this.props.location.action === 'RPApprove') {
            await this.populatePresalesResourceMix();
        }

        await this.sortResourceMixByLastModifiedDate();

    };

    sortResourceMixByLastModifiedDate = async () => {
        await this.state.resourceMix.sort(function (a, b) {
            var keyA = new Date(a.LastUpdatedByUserAt),
                keyB = new Date(b.LastUpdatedByUserAt);
            // Compare the 2 dates
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
        });

        await this.state.resourceMix.forEach(function (resourceItem) {
            if (resourceItem.ResourceRequestSubLine && resourceItem.ResourceRequestSubLine.length > 0) {
                resourceItem.ResourceRequestSubLine.sort(function (a, b) {
                    var keyA = new Date(a.LastUpdatedByUserAt),
                        keyB = new Date(b.LastUpdatedByUserAt);
                    // Compare the 2 dates
                    if (keyA < keyB) return 1;
                    if (keyA > keyB) return -1;
                    return 0;
                });
            }
        });

        var indexRes = 0;
        for (const eachLine of this.state.resourceMix) {
            eachLine.index = indexRes;
            indexRes++;
            var indexSub = 0;
            if (eachLine.ResourceRequestSubLine && eachLine.ResourceRequestSubLine.length > 0) {
                for (const eachSub of eachLine.ResourceRequestSubLine) {
                    eachSub.index = indexSub;
                    indexSub++;
                }
            }
        }
    };

    populatePresalesResourceMix = async () => {
        await getPreSalesResourceMixData(this.state.allCrsAndProjs)
            .then(response => {
                var tempResourceMix = [];
                var index = 0;
                if (this.state.resourceMix.length > 0) {
                    tempResourceMix = this.state.resourceMix;
                    index = tempResourceMix.length;
                }
                for (let resource of response.data) {
                    for (let element of resource) {
                        let projectOrCrCountry = element.Type === "Project" ? element.Project.CountryId : element.ChangeRequest.CountryId;
                        element.projectOrCrCountry = projectOrCrCountry;
                        if (element.Status === 'RES_ALLOC_PENDING') {
                            var duplicates = tempResourceMix.filter(beforeItem => beforeItem.id == element.id);
                            if (duplicates.length === 0) {
                                element.index = index;

                                // Add allocated resource name and designation
                                if (element.AllocatedResourceID != null) {
                                    let filteredAllocatedName = {};
                                    let filteredAllocatedDesig = {};

                                    // Name
                                    filteredAllocatedName = this.usersAll.filter(function (itm) {
                                        return element.AllocatedResourceID === itm.id;
                                    });
                                    element.AllocatedResource.Name = filteredAllocatedName[0].Name;

                                    // Designation
                                    if (filteredAllocatedName[0] && element.AllocatedDesignationId) {
                                        filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                                            return (element.AllocatedDesignationId === itm.id);
                                        });

                                    } else {
                                        filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                                            if(filteredAllocatedName.length > 0){
                                                return (filteredAllocatedName[0].DesignationId === itm.id);
                                            }
                                        });
                                    }
                                    if(filteredAllocatedDesig.length > 0) {
                                        element.AllocatedDesignation = filteredAllocatedDesig[0].Name;
                                    }

                                    // Region
                                    const AllocatedRegion = this.regionsAll.filter(function (itm) {
                                        return element.AllocatedResource.Resource.Region === itm.id;
                                    });
                                    element.AllocatedRegion = AllocatedRegion[0];
                                }

                                tempResourceMix.push(element);
                                index++;
                            }
                        }
                    }
                }
                this.setState({
                    resourceMix: tempResourceMix,
                    forFilterResourceLine: tempResourceMix
                })
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateInitialCMSToGrid = () => {
        if (this.state.calculatedValues && this.state.calculatedActValues) {
            const calculatedValues = this.state.calculatedValues;
            const approvedCalculatedValues = this.state.approvedCalculatedValues;
            const calculatedActValues = this.state.calculatedActValues;

            const calculations = [
                {
                    Field: '',
                    Value1: 'Approved CMS Value',
                    Value2: '',
                    Value3: 'CMS Value Based on Allocation',
                    Value4: '',
                    Value5: 'Variance %',
                    Value6: 'Variance Value',
                    Value7: 'Ongoing CMS Value',
                    Value8: '',
                    Value9: 'Variance %',
                    Value10: 'Variance Value',
                },
                {
                    Field: 'Duration | Effort',
                    Value1: '',
                    Value2: '',
                    Value3: '',
                    isHeader: true
                },
                {
                    Field: 'Est. Project Duration (Calendar Days)',
                    Value1: '',
                    Value2: calculatedValues.estDuration,
                    Value3: '',
                    Value4: calculatedValues.estDuration,
                    Value5: '',
                    Value6: '',
                    Value7: '',
                    Value8: (calculatedActValues !== null ? calculatedActValues.EstDuration : 0) + ' days',
                    Value9: '',
                    Value10: ''
                },
                {
                    Field: 'Total Contracted Effort Days | Hours',
                    Value1: formatNumber(Number(calculatedValues.totalEffortDaysCMS)),
                    Value2: formatNumber(Number(calculatedValues.totalEffortDaysCMS * 8)),
                    Value3: this.props.location.action !== 'RPApproveWF' ?
                        formatNumber(Number(calculatedValues.totalEffortRM))
                        : ` ${formatNumber(Number(calculatedValues.totalEffortRM))} |  ${formatNumber(Number(approvedCalculatedValues.totalEffortRM))} `,
                    Value4: this.props.location.action !== 'RPApproveWF' ?
                        formatNumber(Number(calculatedValues.totalEffortRM * 8))
                        : ` ${formatNumber(Number(calculatedValues.totalEffortRM * 8))} | ${formatNumber(Number(approvedCalculatedValues.totalEffortRM * 8))} `,
                    Value5: (calculatedValues.totalEffortDaysCMS !== 0 ? formatNumber(Number(((((formatNumber(Number(calculatedValues.totalEffortRM)) - formatNumber(Number(calculatedValues.totalEffortDaysCMS))) / formatNumber(Number(calculatedValues.totalEffortDaysCMS))) * 100).toFixed(2)))) : 0) + '%',
                    Value6: formatNumber(Number((formatNumber(Number(calculatedValues.totalEffortRM)) - formatNumber(Number(calculatedValues.totalEffortDaysCMS))).toFixed(2))),

                    Value7: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ContractedEffortDays)) : 0.00,
                    Value8: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ContractedEffort)) : 0.00,
                    Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ConEffVarPercentage)) : 0.00) + '%',
                    Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ConEffVariance)) : 0.00
                },
                {
                    Field: 'Fixed Price Effort',
                    Value1: formatNumber(Number(calculatedValues.fixedPriceEffortApproved)),
                    Value2: '',
                    Value3: this.props.location.action !== 'RPApproveWF' ?
                        formatNumber(Number(calculatedValues.fixedPriceEffortOngoing))
                        : `${formatNumber(Number(calculatedValues.fixedPriceEffortOngoing))} | ${formatNumber(Number(approvedCalculatedValues.fixedPriceEffortOngoing))}`,
                    Value4: '',
                    Value5: (calculatedValues.fixedPriceEffortApproved !== 0.00 ? ((((((Number(calculatedValues.fixedPriceEffortOngoing)) - (Number(calculatedValues.fixedPriceEffortApproved))) / (Number(calculatedValues.fixedPriceEffortApproved)))) * 100).toFixed(2)) : 0.00) + '%',
                    Value6: formatNumber(Number(((Number(calculatedValues.fixedPriceEffortOngoing)) - (Number(calculatedValues.fixedPriceEffortApproved))).toFixed(2))),

                    Value7: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.FixedEffort)) : 0.00,
                    Value8: '',
                    Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.FixedEffVarPercentage)) : 0.00) + '%',
                    Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.FixedEffVariance)) : 0.00
                },
                {
                    Field: 'T & M Effort',
                    Value1: formatNumber(Number(calculatedValues.TMEffortOngoingApproved)),
                    Value2: '',
                    Value3: this.props.location.action !== 'RPApproveWF' ?
                        formatNumber(Number(calculatedValues.TMEffortOngoing))
                        : `${formatNumber(Number(calculatedValues.TMEffortOngoing))} | ${formatNumber(Number(approvedCalculatedValues.TMEffortOngoing))}`,
                    Value4: '',
                    Value5: (calculatedValues.TMEffortOngoingApproved !== 0.00 ? ((((((Number(calculatedValues.TMEffortOngoing)) - (Number(calculatedValues.TMEffortOngoingApproved))) / (Number(calculatedValues.TMEffortOngoingApproved)))) * 100).toFixed(2)) : 0.00) + '%',
                    Value6: (formatNumber(Number(calculatedValues.TMEffortOngoing)) - formatNumber(Number(calculatedValues.TMEffortOngoingApproved))).toFixed(2),

                    Value7: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.TMEffort)) : 0.00,
                    Value8: '',
                    Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.TmEffVarPercentage)) : 0.00) + '%',
                    Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.TmEffVariance)) : 0.00
                },
                {
                    Field: 'Billable',
                    Value1: '',
                    Value2: '',
                    Value3: '',
                    Value4: '',
                    Value5: '',
                    Value6: '',
            
                    Value7: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.Billable)) : 0.00,
                    Value8: '',
                    Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.BillVarPercentage)) : 0.00) + '%',
                    Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.BillVariance)) : 0.00
                },
                {
                    Field: 'Non-Billable',
                    Value1: '',
                    Value2: '',
                    Value3: '',
                    Value4: '',
                    Value5: '',
                    Value6: '',
            
                    Value7: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NonBillable)) : 0.00,
                    Value8: '',
                    Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NBVarPercentage)) : 0.00) + '%',
                    Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NBVariance)) : 0.00
                },
                //-------------------------------------------------------------
                {
                    Field: 'Project Value | Project Revenue',
                    Value1: '',
                    Value2: '',
                    Value3: '',
                    isHeader: true
                },
                {
                    Field: 'Revenue to Fortude after discount',
                    Value1: '',
                    Value2: formatNumber(Number(calculatedValues.discountedRevenueToFortudeCMS)),
                    Value3: '',
                    Value4: this.props.location.action !== 'RPApproveWF' ?
                        formatNumber(Number(calculatedValues.discountedRevenueToFortude))
                        : `${formatNumber(Number(calculatedValues.discountedRevenueToFortude))} | ${formatNumber(Number(approvedCalculatedValues.discountedRevenueToFortude))}`,
                    Value5: (calculatedValues.discountedRevenueToFortudeCMS !== 0 ? ((((((Number(calculatedValues.discountedRevenueToFortude)) - (Number(calculatedValues.discountedRevenueToFortudeCMS))) / (Number(calculatedValues.discountedRevenueToFortudeCMS)))) * 100).toFixed(2)) : 0) + '%',
                    Value6: ((Number(calculatedValues.discountedRevenueToFortude)) - (Number(calculatedValues.discountedRevenueToFortudeCMS))).toFixed(2),

                    Value7: '',
                    Value8: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.Revenue)) : 0.00,
                    Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.RevVarPercentage)) : 0.00) + '%',
                    Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.RevVariance)) : 0.00
                },

                {
                    Field: 'Average Rate per Day',
                    Value1: '',
                    Value2: formatNumber(Number(calculatedValues.discountedRevenueToFortudeCMS / calculatedValues.totalEffortDaysCMS)),
                    Value3: '',
                    Value4: formatNumber(Number(calculatedValues.discountedRevenueToFortude / calculatedValues.totalEffortRM)),
                    Value5: ((calculatedValues.totalEffortRM !== 0 && calculatedValues.totalEffortDaysCMS !== 0 && calculatedValues.discountedRevenueToFortudeCMS !== 0) ? ((((((Number(calculatedValues.discountedRevenueToFortude / calculatedValues.totalEffortRM)) - (Number(calculatedValues.discountedRevenueToFortudeCMS / calculatedValues.totalEffortDaysCMS))) / (Number(calculatedValues.discountedRevenueToFortudeCMS / calculatedValues.totalEffortDaysCMS)))) * 100).toFixed(2)) : 0) + '%',
                    Value6: (calculatedValues.totalEffortRM !== 0 && calculatedValues.totalEffortDaysCMS) ? (((Number(calculatedValues.discountedRevenueToFortude / calculatedValues.totalEffortRM)) - (Number(calculatedValues.discountedRevenueToFortudeCMS / calculatedValues.totalEffortDaysCMS))).toFixed(2)) : 0,

                    Value7: '',
                    Value8: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.AvgRatePerDay)) : 0.00,
                    Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.AvgRateVarPercentage)) : 0.00) + '%',
                    Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.AvgRateVariance)) : 0.00
                },


                //--------------------------------------------------------------------
                {
                    Field: 'Profitability',
                    Value1: '',
                    Value2: '',
                    Value3: '',
                    isHeader: true
                },
                /*{
          Field: 'Gross margin % | Gross profit from resource deployment',
          Value1: initialCMS.projectrevenueafterdiscount ? formatNumber(Number(parseFloat(((initialCMS.grossprofitfromresourcedeployment / initialCMS.projectrevenueafterdiscount) * 100)))) + '%' : '0.00%',
          Value2: formatNumber(Number(initialCMS.grossprofitfromresourcedeployment)),
          Value3: ''
        },*/
                {
                    Field: 'Gross Margin % | Gross Profit From Resource Deployment',
                    Value1: calculatedValues.GrossProfitMargin,
                    Value2: formatNumber(Number(calculatedValues.TotalGrossProfit.toFixed(2))),
                    Value3: this.props.location.action !== 'RPApproveWF' ?
                        (calculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((calculatedValues.TotalOngoingGrossProfit / calculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')
                        : `${(calculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((calculatedValues.TotalOngoingGrossProfit / calculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')} | ${(approvedCalculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((approvedCalculatedValues.TotalOngoingGrossProfit / approvedCalculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')}`,
                    Value4: this.props.location.action !== 'RPApproveWF' ?
                        (calculatedValues.TotalOngoingGrossProfit ? formatNumber(Number(calculatedValues.TotalOngoingGrossProfit)) : '0.00')
                        : `${(calculatedValues.TotalOngoingGrossProfit ? formatNumber(Number(calculatedValues.TotalOngoingGrossProfit)) : '0.00')} | ${(calculatedValues.TotalOngoingGrossProfit ? formatNumber(Number(calculatedValues.TotalOngoingGrossProfit)) : '0.00')}`,
                    Value5: ( calculatedValues.TotalGrossProfit !== 0 ? (formatNumber(Number(((calculatedValues.GrossVeriance / (calculatedValues.TotalGrossProfit ? Number(calculatedValues.TotalGrossProfit) : 0.00)) * 100).toFixed(2))) + '%' ) : '0.00%'),
                    Value6: formatNumber(Number(calculatedValues.GrossVeriance)),

                    Value7: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossPercentage) * 100) : 0.00) + '%',
                    Value8: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossMargin)) : 0.00,
                    Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossMarginVarPercentage)) : 0.00) + '%',
                    Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossMarginVariance)) : 0.00
                },
                {
                    Field: 'Net Margin % | Net Profit / (Loss)',
                    Value1: calculatedValues.discountedRevenueToFortudeCMS ? formatNumber(Number(parseFloat(((calculatedValues.netProfitLossCMS / calculatedValues.discountedRevenueToFortudeCMS) * 100)))) + '%' : '0.00%',
                    Value2: calculatedValues.netProfitLossCMS ? formatNumber(Number(calculatedValues.netProfitLossCMS)) : '0.00',
                    Value3: this.props.location.action !== 'RPApproveWF' ?
                        (calculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((calculatedValues.netProfit / calculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')
                        : `${(calculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((calculatedValues.netProfit / calculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')} | ${(approvedCalculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((approvedCalculatedValues.netProfit / approvedCalculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')}`,
                    Value4: this.props.location.action !== 'RPApproveWF' ?
                        (calculatedValues.netProfit ? formatNumber(Number(calculatedValues.netProfit)) : '0.00')
                        : `${(calculatedValues.netProfit ? formatNumber(Number(calculatedValues.netProfit)) : '0.00')} | ${(approvedCalculatedValues.netProfit ? formatNumber(Number(approvedCalculatedValues.netProfit)) : '0.00')}`,
                    Value5: (calculatedValues.netProfitLossCMS !== 0) ? formatNumber(Number(((((Number(calculatedValues.netProfit) - (Number(calculatedValues.netProfitLossCMS))) / Number(calculatedValues.netProfitLossCMS)) * 100).toFixed(2)))) + '%' : '0.00%',
                    Value6: formatNumber(Number(((calculatedValues.netProfit ? (Number(calculatedValues.netProfit)) : 0.00) - (calculatedValues.netProfitLossCMS ? (Number(calculatedValues.netProfitLossCMS)) : 0.00)).toFixed(2))),

                    Value7: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetPercentage) * 100) : 0.00) + '%',
                    Value8: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetMargin)) : 0.00,
                    Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetMarginVarPercentage)) : 0.00) + '%',
                    Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetMarginVariance)) : 0.00
                }
            ];
            if (this.isMount) {
                this.setState({
                    calculations: calculations
                },
                    () => {
                        this.forceUpdate();
                    }
                );
            }
        }

    };

    toggleProjectPhase = async () => {
        await this.setState({
            projectPhaseVisible: !this.state.projectPhaseVisible,
            isEditButton: false
        });
    };

    toggleProjectSubPhase = async () => {
        await this.setState({
            projectSubPhaseVisible: !this.state.projectSubPhaseVisible,
            selectedProjecOrCrForPhase: null
        });
    };

    onStartDateChange = async event => {
        let startDate = null;
        if (event.value === null) {
            startDate = null;
        } else {
            startDate = new Date(event.target.value);
        }

        await this.setState(prevState => {
            return {
                startDate: startDate
            };
        });

        if ((startDate && (new Date(startDate) >= new Date(this.state.startDateMin))) || startDate === null) {
            this.setState({
                startDateIsaPastDate: false
            });
        } else {
            this.setState({ startDateIsaPastDate: true });
        }
    };

    toggleMessageDialog = (message, title) => {
        this.setState({
            showMessageDialog: !this.state.showMessageDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    toggleCostValidatioDialog = (message, title) => {
        this.setState({
          showCostValidationDialog: !this.state.showCostValidationDialog,
          dialogMessage: message,
          dialogTitle: title
        });
      };

    handlePhasesAdd = async (phaseName, startDate, projectOrCr) => {
        const cmsheetId = projectOrCr.Type === 'Project' ? projectOrCr.Project.CMSheets[0].id : projectOrCr.ChangeRequest.CMSheets[0].id;
        const phasesTemp = this.state.phasesTemp;

        if (phaseName) {
            phaseName = phaseName.trim();
            const exists = phasesTemp.filter(obj => {
                let exist = obj.Text;
                if (exist) {
                    const existLC = exist.toLowerCase();
                    const phaseNameLC = phaseName.toLowerCase();

                    if (phaseNameLC === existLC) {
                        return true;
                    }
                    return false;
                }
                return false;
            });
            if (exists.length > 0) {
                const message = 'Phase name already exists. Please use a unique name';
                const title = 'Name Already Exists';
                this.toggleMessageDialog(message, title);
            } else {
                phasesTemp.push({
                    Text: phaseName,
                    StartDate: startDate,
                    SubPhases: [],
                    CMSheetId: cmsheetId
                });

                this.setState({
                    phasesTemp: phasesTemp,
                    selectedCMSId: cmsheetId
                });
            }
        }
    };

    handlePhasesEdit = async (id, phaseName, startDate, projectOrCr) => {
        const cmsheetId = projectOrCr[0].Type === 'Project' ? projectOrCr[0].Project.CMSheets[0].id : projectOrCr[0].ChangeRequest.CMSheets[0].id;
        const phasesTemp = this.state.phasesTemp;
        if (phaseName) {
            phaseName = phaseName.trim();
            phasesTemp.filter(obj => {
                const startDateFormatted = getFormattedDate(startDate);
                if (obj.id === id) {
                    obj.Text = phaseName;
                    obj.StartDate = startDateFormatted;
                }
            });
            this.setState({
                phasesTemp: phasesTemp,
                selectedCMSId: cmsheetId
            });
        }
    };

    handleSubPhasesEdit = async (id, phaseName, subPhaseName, startDate, projectOrCr) => {
        const cmsheetId = projectOrCr[0].Type === 'Project' ? projectOrCr[0].Project.CMSheets[0].id : projectOrCr[0].ChangeRequest.CMSheets[0].id;
        const phasesTemp = this.state.phasesTemp;
        if (subPhaseName) {
            const startDateFormatted = getFormattedDate(startDate);
            this.state.phasesTemp[this.state.phaseEditIndex].SubPhases[this.state.subPhaseEditIndex].Text = subPhaseName;
            this.state.phasesTemp[this.state.phaseEditIndex].SubPhases[this.state.subPhaseEditIndex].StartDate = startDateFormatted;

            this.setState({
                phasesTemp: phasesTemp,
                selectedCMSId: cmsheetId
            });
        }
    };

    handlePhasesSave = async () => {
        if (this.state.phasesTemp) {
            const phasesTemp = this.state.phasesTemp;
            //call save phases API
            await savePhasesOfResourceRequests(this.state.selectedCMSId, phasesTemp)
                .then(res => {

                    this.toggleProjectPhase();
                    const message = 'Successfully saved.';
                    const title = 'Success';
                    this.toggleMessageDialog(message, title);
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        }

        await this.getPhasesAndSubPhases(this.state.project.id);
    };

    getAllocationRates = async (role, type, rateCardParamOnEdit) => {
        if (role === null) { // when use state value change to this.state.newResRole
            this.state.newRatesAll = this.rateTypesAll;
        } else {
            let customerRate, regionRate, lastCustomRate, lastApprovedProject_Rate = 0;
            let lastCustomRateProjectName = '';
            let rateCardParams = null;
            if (type === 'edit') {
                rateCardParams = {
                    customerId: rateCardParamOnEdit.CustomerId,
                    regionId: rateCardParamOnEdit.RegionId,
                    role: role,
                    projectDate: rateCardParamOnEdit.CostDate,
                    dataType: rateCardParamOnEdit.type,
                    resourceId: this.state.editableItem.id
                };

            } else if (type === 'add') {
                rateCardParams = {
                    customerId: this.state.newResProjectOrCRName ? this.state.newResProjectOrCRName.customerId : null,
                    regionId: this.state.newResProjectOrCRName ? this.state.newResProjectOrCRName.billingRegion : null,
                    role: role.id,
                    projectDate: new Date(),
                    dataType: (this.state.newResProjectOrCRName ? (this.state.newResProjectOrCRName.type === 'project' ? 'projectCMS' : "crCMS") : null)
                };
            }
            
            await getRatesForAllocation(rateCardParams)            
                .then(async result => {
                    const defaultRate = result.data.defaultRate;
                    const customersRate = result.data.customerRate;
                    const lastCustomerRate = result.data.lastCustomerRate;
                    const lastApprovedProjRate = result.data.lastApprovedrate;
                    const customRate = result.data.customRate;

                    //For standard rate
                    if (defaultRate && defaultRate.Rate > 0) {
                        regionRate = defaultRate.Rate > 0 ? defaultRate.Rate : 0;
                    }
                    //For pre-agreed rate
                    if (customersRate && customersRate.Rate > 0) {
                        customerRate = customersRate.Rate > 0 ? customersRate.Rate : 0;
                    }
                    //For last overridden rate
                    if (lastCustomerRate && lastCustomerRate.Rate > 0) {
                        lastCustomRate = lastCustomerRate.Rate;
                        lastCustomRateProjectName = lastCustomerRate.Project.Name;
                    }
                    //For overridden rate
                    if (lastApprovedProjRate && lastApprovedProjRate.Rate > 0) {
                        lastApprovedProject_Rate = lastApprovedProjRate.Rate;
                    }

                    if (!customersRate || customersRate.Rate <= 0) {
                        if (!regionRate || regionRate.Rate <= 0) {
                            this.setState({
                                showSuccessImage: false,
                                showErrorImage: true
                            });
                            const message = 'Standard rate not found for ' + this.state.newResRole.Name;
                            const title = 'Error';
                            this.toggleDialog(message, title);
                        }
                    }
                    let tmpRates = this.rateTypesAll;
                    for (let rateType of tmpRates) {
                        if (rateType.Code === 'CUSTOMER_RATE') {
                            rateType.Rate = customerRate;
                            rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
                        }
                        if (rateType.Code === 'REGION_RATE') {
                            rateType.Rate = regionRate;
                            rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
                        }
                        if (rateType.Code === 'LAST_CUS_RATE') {
                            rateType.Rate = lastCustomRate;
                            rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
                            rateType.LastCustomerRateProject = lastCustomRateProjectName;
                        }
                        if (rateType.Code === 'APPROVED_PROJ_RATE') {
                            rateType.Rate = lastApprovedProject_Rate;
                            rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
                        }
                        if (rateType.Code === 'CUSTOM_RATE') {
                            rateType.Rate = Number(customRate);
                            rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
                        }
                    }

                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

            let tmpRates = this.rateTypesAll;
            if (type === 'add') {
                this.state.newRatesAll = tmpRates;
            } else if (type === 'edit') {
                const defaultRateType = tmpRates.filter(rateType => rateType.id === this.state.editableItem.dataValueRateType.id);
                const availableRateTypes = tmpRates.filter(rateType => (rateType.Rate && rateType.Rate !== 0) || rateType.Code === "ADMIN_RATE");
                await this.setState({
                    RateTypes: availableRateTypes,
                    subLineDefaultRateType: defaultRateType[0],
                    subLineDefaultRateValue: defaultRateType[0].Rate
                });
            }
        }

    };

    // For actual, forecast breakdown
    formatValueEightCell = props => {
        if (props.dataItem.Field == 'Est. Project Duration (Calendar Days)') {
            return (
                <td>
                    <td id="EstProjDurationToolTip">
                        {props.dataItem.Value8}
                    </td>
                    
                    <UncontrolledTooltip
                        modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                        target="EstProjDurationToolTip"
                        placement="bottom"
                        style={{ maxWidth: '300px' }}
                    >
                        <div className="text-left p-1">
                            <span>Actual: {this.state.calculatedActValues.EstDurationAct  + ' days'}</span>
                            <br />
                            <span>Forecast: {this.state.calculatedActValues.EstDurationFt + ' days'}</span>
                        </div>
                    </UncontrolledTooltip>
                </td>
            );
        }
        if ((props.dataItem.Field == 'Revenue to Fortude after discount') && this.state.project.PaymentMethodRecord && this.state.project.PaymentMethodRecord.id == 2) {
            return (
              <td>
                <td id="RevenuetoFortudeToolTip">
                  {props.dataItem.Value8}
                </td>
      
                <UncontrolledTooltip
                  modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                  target="RevenuetoFortudeToolTip"
                  placement="bottom"
                  style={{ maxWidth: '300px' }}
                >
                  <div className="text-left p-1">
                    <span>Actual: {this.state.calculatedActValues.RevenueAct}</span>
                    <br />
                    <span>Forecast: {this.state.calculatedActValues.RevenueFt}</span>
                  </div>
                </UncontrolledTooltip>
              </td>
            );
        }
        else {
            return (
                <td>
                    {props.dataItem.Value8}
                </td>
            );
        }
    };

    formatValueSevenCell = props => {
        if (props.dataItem.Field == 'Total Contracted Effort Days | Hours') {
            return (
                <td>
                    <td id="ContractedEffort">
                        {props.dataItem.Value7}
                    </td>
                
                    <UncontrolledTooltip
                        modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                        target="ContractedEffort"
                        placement="bottom"
                        style={{ maxWidth: '300px' }}
                    >
                        <div className="text-left p-1">
                            <span>Actual: {Number(this.state.calculatedActValues.ContractedEffortAct).toFixed(2)  + ' days'}</span>
                            <br />
                            <span>Forecast: {Number(this.state.calculatedActValues.ContractedEffortFt).toFixed(2) + ' days'}</span>
                        </div>
                    </UncontrolledTooltip>
                </td>
            );
        }
        if (props.dataItem.Field == 'Fixed Price Effort') {
            return (
                <td>
                    <td id="FixedEffort">
                        {props.dataItem.Value7}
                    </td>
                    
                    <UncontrolledTooltip
                        modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                        target="FixedEffort"
                        placement="bottom"
                        style={{ maxWidth: '300px' }}
                    >
                        <div className="text-left p-1">
                            <span>Actual: {Number(this.state.calculatedActValues.FixedEffortAct).toFixed(2)  + ' days'}</span>
                            <br />
                            <span>Forecast: {Number(this.state.calculatedActValues.FixedEffortFt).toFixed(2) + ' days'}</span>
                        </div>
                    </UncontrolledTooltip>
                </td>
            );
        }
        if (props.dataItem.Field == 'T & M Effort') {
            return (
                <td>
                    <td id="TMEffort">
                        {props.dataItem.Value7}
                    </td>
                
                    <UncontrolledTooltip
                        modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                        target="TMEffort"
                        placement="bottom"
                        style={{ maxWidth: '300px' }}
                    >
                        <div className="text-left p-1">
                            <span>Actual: {Number(this.state.calculatedActValues.TMEffortAct).toFixed(2)  + ' days'}</span>
                            <br />
                            <span>Forecast: {Number(this.state.calculatedActValues.TMEffortFt).toFixed(2) + ' days'}</span>
                        </div>
                    </UncontrolledTooltip>
                </td>
            );
        }
        if (props.dataItem.Field == 'Billable') {
            return (
                <td>
                    <td id="Billable">
                        {props.dataItem.Value7}
                    </td>
                
                    <UncontrolledTooltip
                        modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                        target="Billable"
                        placement="bottom"
                        style={{ maxWidth: '300px' }}
                    >
                        <div className="text-left p-1">
                            <span>Actual: {Number(this.state.calculatedActValues.BillApproveTimeSum).toFixed(2)  + ' days'}</span>
                            <br />
                            <span>Forecast: {Number(this.state.calculatedActValues.BillLineFutureAllocSum).toFixed(2) + ' days'}</span>
                        </div>
                    </UncontrolledTooltip>
                </td>
            );
        }
        if (props.dataItem.Field == 'Non-Billable') {
            return (
                <td>
                    <td id="NonBillable">
                        {props.dataItem.Value7}
                    </td>
                    
                    <UncontrolledTooltip
                        modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
                        target="NonBillable"
                        placement="bottom"
                        style={{ maxWidth: '300px' }}
                    >
                        <div className="text-left p-1">
                            <span>Actual: {Number(this.state.calculatedActValues.NBApproveTimeSum).toFixed(2)  + ' days'}</span>
                            <br />
                            <span>Forecast: {Number(this.state.calculatedActValues.NBLineFutureAllocSum).toFixed(2) + ' days'}</span>
                        </div>
                    </UncontrolledTooltip>
                </td>
            );
        }
        else {
            return (
                <td>
                    {props.dataItem.Value7}
                </td>
            );
        }
    };

    addPhase = async () => {
        this.setState({
            isAddDone: true
        });
        if (!this.state.isEditButton) {
            let startDateStr = null;
            let selectedProjectOrCr = null;
            if (this.state.phaseStartDate) {
                startDateStr = getFormattedDate(this.state.phaseStartDate);
            }

            if (this.state.selectedProjecOrCrForPhase) {
                selectedProjectOrCr = this.state.selectedProjecOrCrForPhase;
            }
            this.handlePhasesAdd(this.state.phaseName, startDateStr, selectedProjectOrCr);

            this.state.cancelDataPhaseName = this.state.phaseName;
            this.state.cancelDataProjectId = this.state.selectedProjecOrCrForPhase;

            this.setState({
                phaseName: '',
                startDate: '',
                selectedProjecOrCrForPhase: null,
                phaseStartDate: null,
                startDateIsaPastDate: false
            });
        } else {
            this.handlePhasesEdit(this.state.phaseSubPhaseId, this.state.phaseName, this.state.phaseStartDate, this.state.projectAndCrData);

            await this.setState({
                isSaveButtonVisibleEdit: true,
                phaseName: '',
                startDate: '',
                selectedProjecOrCrForPhase: null,
                phaseStartDate: null,
                startDateIsaPastDate: false,
                isEditButton: false
            });
        }
    };

    handlePhasesRemove = async (index, value) => {
        if (index > -1) {
            this.setState(
                {
                    phaseRemoveIndex: index,
                    selectedCMSId: value.CMSheetId
                },
                () => {
                    const message =
                        'By removing the phase, any sub phases linked to this will be removed as well as any relationships made with the resource and expenses will be removed. Do you want to continue?';
                    this.toggleConfirmDialog(message, this.removePhase);
                }
            );
        }
    };

    handleResourceRemove = async () => {
        if (this.state.userDefinedEndDate === null) {
            this.setState({
                showSuccessImage: false,
                showErrorImage: true
            });
            this.toggleDialog('Please insert an End date to proceed', 'Error');
        } else {
            if (new Date(this.state.project.UserDefinedEndDate).getTime() !== new Date(this.state.userDefinedEndDate).getTime()) {
                //update project
                this.state.project.UserDefinedEndDate = moment(this.state.userDefinedEndDate).format('YYYY-MM-DD');
                updateProject(this.state.project, this.state.project.id)
                    .then(res => {
                        this.onUserDefinedEndDateChange();
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });
            }

            
            // Revenue present validation
            let currentSubLine = this.state.selectedResourcesSubLine
            let isRevenuePresentInSelectedLines = false;
            for(var item of currentSubLine){
                if(item.IsRevenuePresent){
                    isRevenuePresentInSelectedLines = true;
                }
            }

            if(isRevenuePresentInSelectedLines){
                this.toggleMessageDialog('Lines with a revenue allocated cannot be removed. Please remove the "Revenue Present" option in line edit popup and try again.', 'Error');
                return;
            }


            this.setState(
                {
                    isResurceRequestRemove: true,
                    isCanRemoveRequest: false
                },
                () => {
                    const message =
                        'Are you sure you want to remove the selected line(s) from resource allocation?';
                    this.toggleConfirmDialog(message, this.removeResourceRequest);
                }
            );

        }

        this.submitSearch();
    };

    handleResourcesSave = async () => {
        if (this.props.location.action !== 'RPApprove') {
            if (this.state.userDefinedEndDate === null) {
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleDialog('Please insert an End date to proceed', 'Error');
            } else {
                if (new Date(this.state.project.UserDefinedEndDate).getTime() !== new Date(this.state.userDefinedEndDate).getTime()) {
                    //update project
                    this.state.project.UserDefinedEndDate = moment(this.state.userDefinedEndDate).format('YYYY-MM-DD');
                    updateProject(this.state.project, this.state.project.id)
                        .then(res => {
                            this.onUserDefinedEndDateChange();
                        })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });
                }
                await this.setState(
                    {
                        isClickSaveButton: true
                    },
                );
                await this.handleResourceSave(this.state.resourceMix);
                if (this.state.resourcesSaved === true) {
                    this.setState({
                        showSuccessImage: true,
                        showErrorImage: false
                    });
                    this.toggleDialog(
                        ` Successfully saved`,
                        'Success'
                    );
                }
            }
        } else {
            await this.setState(
                {
                    isClickSaveButton: true
                },
            );
            await this.handleResourceSave(this.state.resourceMix);
        }

        this.submitSearch();
    };

    handleResourceSave = async (resourcesToSave) => {

        if (this.state.isClickSaveButton === true) {
            if (this.props.location.action === 'RPApprove') {
                let objsToSend = [];
                const selectedResources = resourcesToSave;
                if (selectedResources && selectedResources.length > 0) {
                    for (const each of selectedResources) {
                        if (each.Status == 'EDIT') {
                            each.Status = 3;
                        } else if (each.Status == 'RES_ALLOC_PENDING') {
                            each.Status = 7;
                        } else if (each.Status == 'RES_ALLOC_REJECTED'){
                            each.Status = 12;
                        } else if (each.Status == "COMPLETE" && each.IsSoftBookingResource){
                            each.Status = 11;
                        }
                        objsToSend.push(each);
                    }

                    await saveResourceAllocation(resourcesToSave)
                        .then(async res => {
                            this.setState({
                                showSuccessImage: true,
                                showErrorImage: false
                            });
                                             
                            this.toggleDialog(
                                ` Successfully saved`,
                                'Success'
                            );
                         
                            await this.getInitialData()
                            this.forceUpdate()
                        })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                            this.toggleDialog(error.response.data, 'Error');
                        });
                }
            } else {
                this.setState({
                    resourcesSaved: false
                });
                await saveResourceRequests(resourcesToSave)
                    .then(async res => {
                        this.setState({
                            resourcesSaved: true
                        });

                        await this.getInitialData();
                        this.forceUpdate();
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        this.toggleDialog(error.response.data, 'Error');
                    });

                this.getInitialData()
                this.forceUpdate()

            }
            this.ticked = [];
            this.allocationId = [];
            this.sublineTicked = [];
            this.setState({
                hasSubLines: false,
                confirmStatus: false,
                confirmStatusSub: false
            })
        }
        else {
            if (this.props.location.action === 'RPApprove') {
                let objsToSend = [];
                const selectedResources = resourcesToSave;
                if (selectedResources && selectedResources.length > 0) {
                    for (const each of selectedResources) {
                        if (each.Status == 'EDIT') {
                            each.Status = 3;
                        } else if (each.Status == 'RES_ALLOC_PENDING') {
                            each.Status = 7;
                        } else if (each.Status == 'RES_ALLOC_REJECTED'){
                            each.Status = 12;
                        } else if (each.Status == "COMPLETE" && each.IsSoftBookingResource){
                            each.Status = 11;
                        }
                        objsToSend.push(each);
                    }

                    await saveResourceAllocation(resourcesToSave)
                        .then(res => {
                            this.getInitialData()
                            this.forceUpdate()
                        })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                            this.toggleDialog(error.response.data, 'Error');
                        });
                }
            } else {

                await saveResourceRequests(resourcesToSave)
                    .then(res => {
                        this.getInitialData()
                        this.forceUpdate()
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        this.toggleDialog(error.response.data, 'Error');
                    });
            }
            this.ticked = [];
            this.allocationId = [];
            this.sublineTicked = [];
            this.setState({
                hasSubLines: false,
                confirmStatus: false,
                confirmStatusSub: false
            })
        }
    };

    cancelRequests = async () => {
        let currentRequestsToClose = this.state.selectedResources;
        let currentSubLinesToClose = this.state.selectedResourcesSubLine;

        let allocatedLines = [];
        let deallocatedLines = [];

        if (currentRequestsToClose && currentRequestsToClose.length > 0) {
            for (let resource of currentRequestsToClose) {
                if (resource.ResourceId !== null) {
                    resource.DirectCost = resource.InitialDirectCost;
                    resource.TotalCost = resource.InitialTotalCost;
                    resource.CostPerResource = resource.InitialCostPerResource;
                }
                resource.ToStatus = 8;

                 // Track the deallocated lines
                if (resource.softbookingPreviousResource !== null) { 
                    let tmpDeallocateAry = {
                        LineId: resource.id,
                        ResourceId: resource.softbookingPreviousResource,
                        Type: 'MainLine'
                    };
                    deallocatedLines.push(tmpDeallocateAry);
                }
            }
        }
        if (currentSubLinesToClose && currentSubLinesToClose.length > 0) {
            for (let resource of currentSubLinesToClose) {
                if (resource.ResourceId !== null) {
                    resource.Cost = resource.InitialCost;
                    resource.DirectCost = resource.InitialDirectCost;
                    resource.CostPerResource = resource.InitialCostPerResource;
                }
                resource.ToStatus = 8;

                // Track the deallocated lines
                if (resource.softbookingPreviousResource !== null) {
                    let tmpDeallocateAry = {
                        LineId: resource.id,
                        ResourceId: resource.softbookingPreviousResource,
                        Type: 'SubLine'
                    };
                    deallocatedLines.push(tmpDeallocateAry);
                }
            }
        }
        const cancelObj = {
            mainLines: currentRequestsToClose,
            subLines: currentSubLinesToClose,
            project: this.state.proObj
        };
        await updateStatusToCancelled(cancelObj).then(async res => {
            await this.updateLineHistory(currentRequestsToClose, currentSubLinesToClose);
            if (deallocatedLines && deallocatedLines.length > 0) {
                let allocationAry = [];
                allocationAry.push(allocatedLines);
                allocationAry.push(deallocatedLines);
                await sendAllocationDeallocationMails(allocationAry);
            }
            await this.setState({
                showSuccessImage: true,
                showErrorImage: false,
                managerComment: null,
                selectedResources: [],
                selectedResourcesSubLine: []
            });
            this.toggleDialog(
                ` Successfully cancelled`,
                'Success'
            );
            this.getInitialData();
        })
            .catch(error => {
                this.setState({
                    managerComment: null
                });
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.getInitialData();
            });

    };

    EditPhase = async (index, value) => {
        this.setState(
            {
                isEditButton: true
            }
        );
        const projectAndCrData = this.state.projectAndCrData;
        projectAndCrData.filter(obj => {
            if (obj.Type === 'Project') {
                if (obj.Project.CMSheets[0].id === value.CMSheetId) {
                    this.setState(
                        {
                            minPhaseDate: obj.Project.StartDate ? reStructureDate(obj.Project.StartDate) : reStructureDate(obj.Project.currentDate),
                            selectedProjecOrCrForPhase: obj
                        }
                    );
                }
            } else if (obj.Type === 'ChangeRequest') {
                if (obj.ChangeRequest.CMSheets[0].id === value.CMSheetId) {
                    this.setState(
                        {
                            minPhaseDate: obj.ChangeRequest.StartDate ? reStructureDate(obj.ChangeRequest.StartDate) : reStructureDate(obj.ChangeRequest.currentDate),
                            selectedProjecOrCrForPhase: obj
                        }
                    );
                }
            }
        });

        if (index > -1) {
            const phasesTemp = this.state.phasesTemp;
            await this.setState(
                {
                    phaseEditIndex: index,
                    selectedCMSId: value.CMSheetId,
                    phaseSubPhaseId: value.id,
                    phaseName: phasesTemp[index].Text,
                    phaseStartDate: phasesTemp[index].StartDate ? reStructureDate(phasesTemp[index].StartDate) : null
                }
            );
        }
    };

    EditSubPhase = async (phaseIndex, subPhaseIndex, value) => {
        const phasesTemp = this.state.selectedProjectOrCrPhases;
        this.setState(
            {
                isEditButton: true,
                isSubPhaseEdit: true
            }
        );
        const projectAndCrData = this.state.projectAndCrData;
        projectAndCrData.filter(obj => {
            if (obj.Type === 'Project') {
                if (obj.Project.CMSheets[0].id === value.CMSheetId) {
                    this.setState(
                        {
                            minSubPhaseDate: obj.Project.StartDate ? reStructureDate(obj.Project.StartDate) : reStructureDate(obj.Project.currentDate),
                            selectedProjecOrCrForPhase: obj.Name
                        }
                    );
                }
            } else if (obj.Type === 'ChangeRequest') {
                if (obj.ChangeRequest.CMSheets[0].id === value.CMSheetId) {
                    this.setState(
                        {
                            minSubPhaseDate: obj.ChangeRequest.StartDate ? reStructureDate(obj.ChangeRequest.StartDate) : reStructureDate(obj.ChangeRequest.currentDate),
                            selectedProjecOrCrForPhase: obj.Name
                        }
                    );
                }
            }
        });

        if (phaseIndex > -1 && subPhaseIndex > -1 && phasesTemp[phaseIndex] && phasesTemp[phaseIndex].SubPhases[subPhaseIndex]) {
            await this.setState(
                {
                    phaseEditIndex: phaseIndex,
                    subPhaseEditIndex: subPhaseIndex,
                    selectedCMSId: value.CMSheetId,
                    phaseSubPhaseId: value.id,
                    subPhaseName: phasesTemp[phaseIndex].SubPhases[subPhaseIndex].Text,
                    subPhaseStartDate: phasesTemp[phaseIndex].SubPhases[subPhaseIndex].StartDate ? reStructureDate(phasesTemp[phaseIndex].SubPhases[subPhaseIndex].StartDate) : null
                }
            );
            this.state.selectedProjectOrCrPhases[this.state.selectedPhaseIndex] = phasesTemp[phaseIndex].Text;
        }
    };

    handleSubPhasesRemove = async (phaseIndex, subPhaseIndex) => {
        const phasesTemp = this.state.selectedProjectOrCrPhases;

        if (phaseIndex > -1 && subPhaseIndex > -1 && phasesTemp[phaseIndex] && phasesTemp[phaseIndex].SubPhases[subPhaseIndex]) {
            this.setState(
                {
                    subPhaseRemovePhaseIndex: phaseIndex,
                    subPhaseRemoveSubPhaseIndex: subPhaseIndex
                },
                () => {
                    const message =
                        'By removing the sub phase, any relationships made with the resource and expenses will be removed. Do you want to continue?';
                    this.toggleConfirmDialog(message, this.removeSubPhase);
                }
            );
        }
    };

    removePhase = async () => {
        const phasesTemp = this.state.phasesTemp;
        phasesTemp.splice(this.state.phaseRemoveIndex, 1);

        this.setState(
            {
                phasesTemp: phasesTemp
            },
            () => {
                this.toggleConfirmDialog('', null);
            }
        );

        if (this.state.phasesTemp) {
            const phasesTemp = this.state.phasesTemp;
            //call save phases API
            savePhasesOfResourceRequests(this.state.selectedCMSId, phasesTemp)
                .then(res => {

                    const message = 'Successfully saved.';
                    const title = 'Success';
                    this.toggleMessageDialog(message, title);
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        }
    };

    removeResourceRequest = async () => {
        const IdList = this.getCMSheetVersionAndIdList();
        await getResourceRequestResourceMixData(IdList).then(response => {
            const res = response.data;

            this.setState({
                forRemoveResource: res,
                DeleteResourceRequest: true,
                isCanRemoveRequest: false
            });

        });


        let currentRequests = this.state.selectedResources;
        let Requests = this.state.forRemoveResource[0];
        let deletedIds = [];
        let deletedIndex = [];
        let Type = null;
        let id = null;
        let reasourceAll = this.state.forRemoveResource[0];
        let currentSubLine = this.state.selectedResourcesSubLine

        this.forceUpdate();

        for (let i = 1; i < this.state.forRemoveResource.length; i++) {
            reasourceAll = reasourceAll.concat(this.state.forRemoveResource[i])
        }

        if (currentRequests) {
            await currentRequests.map(function (obj) {
                if (obj.selected) {
                    Type = obj.Type;
                    id = obj.Project ? obj.Project.id : obj.ChangeRequest.id;
                    deletedIds.push(obj.id);
                    deletedIndex.push(obj.index);
                }
            });
        }


        await this.setState({
            resourceMix: reasourceAll
        });

        for (let i = deletedIndex.length - 1; i >= 0; i--) {
            this.state.resourceMix.splice(deletedIndex[i], 1);
        }


        if (this.state.isCheckedSubline) {
            if (currentSubLine) {
                await currentSubLine.map(function (obj) {
                    if (obj.selected) {
                        deletedIds.push(obj.id);
                        deletedIndex.push(obj.index);
                    }
                });
            }



            for (let i = deletedIndex.length - 1; i >= 0; i--) {
                for (const resourcemix of this.state.resourceMix) {
                    for (const sub of resourcemix.ResourceRequestSubLine) {
                        if (sub.index === deletedIndex[i]) {
                            resourcemix.ResourceRequestSubLine.splice(deletedIndex[i], 1);
                        }
                    }
                }
            }


            await deleteRequestSubLine(deletedIds)
                .then(res => {
                    this.getInitialData();
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                    this.toggleMessageDialog(error.response.data, 'Error');
                });
        }

        await deleteRequest(deletedIds, Type, id)
            .then(res => {
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleMessageDialog(error.response.data, 'Error');
            });


        this.toggleConfirmDialog('', null);
        this.toggleMessageDialog('Successfully Deleted', 'Success');

        this.submitSearch();

        this.setState({
            DeleteResourceRequest: false
        });
    };

    toggleOutsourcedCostDialog = () => {
        this.setState({
            showOutsourcedCostDialog: !this.state.showOutsourcedCostDialog
        });
    };

    handleOutsourcedCostInputChange = event => {
        const value = event.target.value;
        this.setState({
            outsourcedCost: value
        });
    };

    confirmOutsourcedCost = async () => {
        this.setState({
            showSuccessImage: true,
            showErrorImage: false
        });
        if (this.state.editableItem) {
            this.state.editableItem.CostPerResource = this.state.outsourcedCost;
        }
        this.toggleDialog(
            ` Successfully saved`,
            'Success'
        );

        this.toggleOutsourcedCostDialog();
    };

    handleSelect = (e) => {
        this.setState({ selected: e.selected });
    };

    handleCalculations = async (isFromApproverView) => {

        //ongoing cms values
        let totalCostRM = Number(0.00);
        let totalGrossCostProject = Number(0.00);
        let totalGrossCostCr = Number(0.00);
        let totalRevenueRM = Number(0.00);
        let totalEffortRM = Number(0.00);
        let calculatedValues = {};
        let discountedRevenueToFortude = Number(0.00);
        let netProfit = Number(0.00);
        let fixedPriceEffortOngoing = Number(0.00);
        let TMEffortOngoing = Number(0.00);

        //approved cms values
        let totalEffortDaysCMS = 0.00;
        let discountedRevenueToFortudeCMS = Number(0.00);
        let netProfitLossCMS = Number(0.00);
        let fixedPriceEffortApproved = Number(0.00);
        let TMEffortApproved = Number(0.00);
        let totalCostCMS = Number(0.00);

        let TotalGrossProfit = Number(0.00);
        let TotalOngoingGrossProfit = Number(0.00);
        let ServiceRevenue = Number(0.00);
        let GrossProfitMargin;

        let calculationResourceMix = await this.state.resourceMix;
        console.log("this.state.resourceMix : ", this.state.resourceMix);

        const invalidStatusList = ['NEW_FROM_CMS', 'NEW_FROM_RM', 'REMOVED', 'RQST_APP_REJECTED', 'EDIT', 'RES_ALLOC_CANCELLED'];
        const approvedStatusList = ['CONFIRMED', 'RES_ALLOC_PENDING', 'ALLOC_APP_PENDING', 'COMPLETE', 'CLOSE'];

        //For Resource Allocation Summary Calculations
        if (this.props.location.action === 'RPApprove' || this.props.location.action === 'RPAllocateWF') {
            calculationResourceMix = this.state.allocationSummaryResources;
            console.log("this.state.allocationSummaryResources : ", this.state.allocationSummaryResources);
        }
        console.log("calculationResourceMix : ", calculationResourceMix);
if(calculationResourceMix && calculationResourceMix.length > 0)
{
        for (const mainLine of calculationResourceMix) {
            if (mainLine.IsPreSales === false) {
                if (mainLine.Project && mainLine.Project.StatusConvertedToActive === true && mainLine.Type === "Project") {
                    if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                        for (const subLine of mainLine.ResourceRequestSubLine) {
                            if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                                totalCostRM += Number(subLine.Cost);
                                totalRevenueRM += Number(subLine.Revenue);
                                totalEffortRM += Number(subLine.EffortDays);
                                totalGrossCostProject += Number(subLine.DirectCost);

                                if (mainLine.Project.PaymentMethod === 1) {
                                    fixedPriceEffortOngoing += Number(subLine.EffortDays);
                                }
                                else if (mainLine.Project.PaymentMethod === 2) {
                                    TMEffortOngoing += Number(subLine.EffortDays);
                                }
                                else if (mainLine.Project.PaymentMethod === 3) {
                                    //for calculate fixed price effort
                                    if (subLine.PaymentMethod === 1) {
                                        fixedPriceEffortOngoing += Number(subLine.EffortDays)
                                    }
                                    else {
                                        //for calculate T & M effort
                                        TMEffortOngoing += Number(subLine.EffortDays)
                                    }
                                }

                            }
                        }
                    } else {
                        if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
                            totalCostRM += Number(mainLine.TotalCost);
                            totalRevenueRM += Number(mainLine.TotalRevenue);
                            totalEffortRM += Number(mainLine.EffortDays);
                            totalGrossCostProject += Number(mainLine.DirectCost);

                            if (mainLine.Project.PaymentMethod === 1) {
                                fixedPriceEffortOngoing += Number(mainLine.EffortDays);
                            }
                            else if (mainLine.Project.PaymentMethod === 2) {
                                TMEffortOngoing += Number(mainLine.EffortDays);
                            }
                            else if (mainLine.Project.PaymentMethod === 3) {

                                //for calculate fixed price effort
                                if (mainLine.PaymentMethod === 1) {
                                    fixedPriceEffortOngoing += Number(mainLine.EffortDays)

                                } else if (mainLine.PaymentMethod === 2) {
                                    //for calculate T & M effort
                                    TMEffortOngoing += Number(mainLine.EffortDays)

                                }
                                else if (mainLine.PaymentMethod === 3) {

                                    fixedPriceEffortOngoing += Number(mainLine.EffortDays)
                                }
                            }

                        }
                    }
                } else if (mainLine.ChangeRequest && mainLine.ChangeRequest.StatusConvertedToActive === true) {
                    if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                        for (const subLine of mainLine.ResourceRequestSubLine) {
                            if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                                totalCostRM += Number(subLine.Cost);
                                totalRevenueRM += Number(subLine.Revenue);
                                totalEffortRM += Number(subLine.EffortDays);
                                totalGrossCostCr += Number(subLine.DirectCost);


                                if (mainLine.ChangeRequest.PaymentMethodId === 1) {
                                    fixedPriceEffortOngoing += Number(subLine.EffortDays);
                                }
                                else if (mainLine.ChangeRequest.PaymentMethodId === 2) {
                                    TMEffortOngoing += Number(subLine.EffortDays);
                                }
                                else if (mainLine.ChangeRequest.PaymentMethodId === 3) {
                                    //for calculate fixed price effort
                                    if (subLine.PaymentMethod === 1) {
                                        fixedPriceEffortOngoing += Number(subLine.EffortDays)

                                    }
                                    else {
                                        //for calculate T & M effort
                                        TMEffortOngoing += Number(subLine.EffortDays)

                                    }
                                }

                            }
                        }
                    } else {
                        if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
                            totalCostRM += Number(mainLine.TotalCost);
                            totalRevenueRM += Number(mainLine.TotalRevenue);
                            totalEffortRM += Number(mainLine.EffortDays);
                            totalGrossCostCr += Number(mainLine.DirectCost);

                            if (mainLine.ChangeRequest.PaymentMethodId === 1) {

                                fixedPriceEffortOngoing += Number(mainLine.EffortDays);
                            } else if (mainLine.ChangeRequest.PaymentMethodId === 2) {
                                TMEffortOngoing += Number(mainLine.EffortDays);
                            } else if (mainLine.ChangeRequest.PaymentMethodId === 3) {
                                //for calculate fixed price effort
                                if (mainLine.PaymentMethod === 1) {
                                    fixedPriceEffortOngoing += mainLine.EffortDays

                                } else {
                                    //for calculate T & M effort
                                    TMEffortOngoing += mainLine.EffortDays

                                }
                            }

                        }
                    }
                }
            }
        }
    }
        if (this.state.project && this.state.project.StatusConvertedToActive && this.state.project.StatusConvertedToActive === true) {

            const projectVersion = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Version : null;
            const projectID = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null;
            let projcurrencyId = 0;
            let cmsRevenueAfterDiscountFixed = Number(0.00);

            await getRegionCurrencyIntegrated(this.state.project.Region.id).then(response => { //currencyId
                projcurrencyId = response.data.CurrencyId;
            })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
            const proStartDate = reStructureDate(this.state.project.StartDate);
            const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated( //conversionRate
                projcurrencyId,
                proStartDate.getMonth() + 1,
                proStartDate.getFullYear()
            );

            //approved CMS values
            if (projectID !== null && projectVersion !== null) {
                await getCMSheet(projectID, projectVersion).then(res => {

                    if (res.data.Project.PaymentMethod === 1) {
                        fixedPriceEffortApproved += res.data.TotalEffortDaysResourceAllocation
                    }
                    else if (res.data.Project.PaymentMethod === 2) {
                        TMEffortApproved += res.data.TotalEffortDaysResourceAllocation
                    }
                    else if (res.data.Project.PaymentMethod === 3) {
                        for (let alloc of res.data.ResourceAllocations) {

                            if (alloc.PaymentMethod === 1) {
                                fixedPriceEffortApproved += alloc.TotalEffortDays
                            }
                            else {
                                TMEffortApproved += alloc.TotalEffortDays
                            }
                        }
                    }
                    totalEffortDaysCMS += Number(res.data.TotalEffortDaysResourceAllocation);

                    // Total gross profit
                    TotalGrossProfit += projcurrencyId !== 1 ? Number(res.data.GrossProfitFromResourceDeployment) / Number(projrateRecordCurrencyRate) : Number(res.data.GrossProfitFromResourceDeployment);

                    // Service revenue
                    ServiceRevenue += Number(res.data.ServiceRevenueValue);

                })
            }

            if (projectID !== null && projectVersion !== null) {
                await getCMSheet(projectID, projectVersion).then(res => {
                    //Revenue to Fortude after discount - CMS
                    const discountVal = (   // Value discount / (premium)
                        (Number(res.data.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate) + Number(res.data.ContingencyValue / projrateRecordCurrencyRate)) *
                        (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
                    const x = Math.pow(10, Number(2) + 1);
                    const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
                    cmsRevenueAfterDiscountFixed =
                        Number(res.data.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate) - Number(valueDiscount) + Number(res.data.ContingencyValue / projrateRecordCurrencyRate); // Revenue to company after discount
                    discountedRevenueToFortudeCMS += cmsRevenueAfterDiscountFixed;

                    //Net Profit - cms
                    const contingencyVal =
                        Number(Number(res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2);
                    const netProfitLossCMSproj = cmsRevenueAfterDiscountFixed -
                        res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate -
                        cmsRevenueAfterDiscountFixed * (res.data.SalesCommissionPercentage / 100) -
                        res.data.NonBillableTotalOtherExpenses / projrateRecordCurrencyRate -
                        contingencyVal;
                    netProfitLossCMS += netProfitLossCMSproj;
                    totalCostCMS += Number(res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate);
                    totalCostCMS += Number(contingencyVal);
                })
            }

            //on-Going CMS values - based on project type
            //revenue to fortude after discount - ongoing
            if (this.state.project.PaymentMethodRecord.id === 1) {    //fixed project
                discountedRevenueToFortude += cmsRevenueAfterDiscountFixed;
            } else if (this.state.project.PaymentMethodRecord.id === 2) { //T&M
                //Sum of revenue from billable lines + sum of (Service Revenue - Discount + Contingency) from CMS
                for (const mainLine of calculationResourceMix) {
                    if (mainLine.CMSheetId === projectID) { //Sum of revenue from billable lines of project
                        if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                            for (const subLine of mainLine.ResourceRequestSubLine) {
                                if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                                    discountedRevenueToFortude += Number(subLine.Revenue);
                                }
                            }
                        } else {
                            if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
                                discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                            }
                        }
                    }
                }
                if (projectID !== null && projectVersion !== null) {
                    await getCMSheet(projectID, projectVersion).then(res => {
                        discountedRevenueToFortude += Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate);
                        discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue / projrateRecordCurrencyRate);
                        // discountedRevenueToFortude += Number(res.data.ContingencyValue / projrateRecordCurrencyRate);
                    })
                }
            } else if (this.state.project.PaymentMethodRecord.id === 3) { //hybrid
                //T&M and fixed
                for (const mainLine of calculationResourceMix) {
                    if (mainLine.CMSheetId === projectID && (mainLine.PaymentMethod === 2 || mainLine.PaymentMethod === 1)) { //Sum of revenue from billable lines of project
                        if (mainLine.ResourceRequestSubLine.length > 0) {
                            for (const subLine of mainLine.ResourceRequestSubLine) {
                                if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                                    discountedRevenueToFortude += Number(subLine.Revenue);
                                }
                            }
                        } else {
                            if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
                                discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                            }
                        }
                    }
                }
                if (projectID !== null && projectVersion !== null) {
                    await getCMSheet(projectID, projectVersion).then(res => {
                        discountedRevenueToFortude += Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate);
                        discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue / projrateRecordCurrencyRate);
                        // discountedRevenueToFortude += Number(res.data.ContingencyValue / projrateRecordCurrencyRate);
                    })
                }
            }
        }

        //revenue to fortude after discount calculations of CR
        for (const cr of this.state.project.ChangeRequests) {
            if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
                const crVersion = cr.CMSheets[0].Version;
                const crID = cr.CMSheets[0].id;
                let currencyIdCR = 0;
                let cmsRevenueAfterDiscountCR = Number(0.00);

                await getRegionCurrencyIntegrated(cr.BillingRegion).then(response => {  //curencyID
                    currencyIdCR = response.data.CurrencyId;
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });
                const crStartDate = reStructureDate(cr.StartDate);
                const crRateRecordCurrencyRate = await retrieveConversionRateIntegrated(  //conversion rate
                    currencyIdCR,
                    crStartDate.getMonth() + 1,
                    crStartDate.getFullYear()
                );

                //approved CMS values
                if (crID !== null && crVersion !== null) {
                    await getCMSheet(crID, crVersion).then(res => {

                        if (res.data.ChangeRequest.PaymentMethodId === 1) {
                            fixedPriceEffortApproved += res.data.TotalEffortDaysResourceAllocation
                        }
                        else if (res.data.ChangeRequest.PaymentMethodId === 2) {
                            TMEffortApproved += res.data.TotalEffortDaysResourceAllocation
                        }
                        else if (res.data.ChangeRequest.PaymentMethodId === 3) {
                            for (let alloc of res.data.ResourceAllocations) {

                                if (alloc.PaymentMethod === 1) {
                                    fixedPriceEffortApproved += alloc.TotalEffortDays
                                }
                                else {
                                    TMEffortApproved += alloc.TotalEffortDays
                                }
                            }
                        }
                        totalEffortDaysCMS += Number(res.data.TotalEffortDaysResourceAllocation);

                        // Total gross profit
                        TotalGrossProfit += currencyIdCR !== 1 ? Number(res.data.GrossProfitFromResourceDeployment) / Number(crRateRecordCurrencyRate) : Number(res.data.GrossProfitFromResourceDeployment);

                        // Service revenue
                        ServiceRevenue += Number(res.data.ServiceRevenueValue);
                    })
                }

                // Revenue to Fortude after discount - cms calculations
                if (crID !== null && crVersion !== null) {
                    await getCMSheet(crID, crVersion).then(res => {
                        const discountVal = (   // Value discount / (premium)
                            (Number(res.data.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate) + Number(res.data.ContingencyValue / crRateRecordCurrencyRate)) *
                            (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
                        const x = Math.pow(10, Number(2) + 1);
                        const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
                        cmsRevenueAfterDiscountCR =
                            Number(res.data.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate) - Number(valueDiscount) + Number(res.data.ContingencyValue / crRateRecordCurrencyRate); // Revenue to company after discount
                        discountedRevenueToFortudeCMS += cmsRevenueAfterDiscountCR;

                        //Net Profit - cms
                        const contingencyVal =
                            Number(Number(res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2);
                        const netProfitLossCMScr = cmsRevenueAfterDiscountCR -
                            res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate -
                            cmsRevenueAfterDiscountCR * (res.data.SalesCommissionPercentage / 100) -
                            res.data.NonBillableTotalOtherExpenses / crRateRecordCurrencyRate -
                            contingencyVal;
                        netProfitLossCMS += netProfitLossCMScr;
                        totalCostCMS += Number(res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate);
                        totalCostCMS += Number(contingencyVal);
                    })
                }

                //on-Going CMS values - based on CR type
                // Revenue to Fortude after discount - ongoing
                if (cr.PaymentMethodId === 1) { //fixed CR
                    discountedRevenueToFortude += cmsRevenueAfterDiscountCR;
                }
                else if (cr.PaymentMethodId === 2) { //T&M crs
                    for (const mainLine of calculationResourceMix) {
                        if (mainLine.CMSheetId === crID) {  //Sum of revenue from billable lines of CR
                            if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                                for (const subLine of mainLine.ResourceRequestSubLine) {
                                    if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                                        discountedRevenueToFortude += Number(subLine.Revenue);
                                    }
                                }
                            } else {
                                if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
                                    discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                                }
                            }
                        }
                    }
                    await getCMSheet(crID, crVersion).then(res => {
                        discountedRevenueToFortude += Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate);
                        discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue / crRateRecordCurrencyRate);
                        // discountedRevenueToFortude += Number(res.data.ContingencyValue / crRateRecordCurrencyRate);
                    })
                } else if (cr.PaymentMethodId === 3) { //hybrid
                    //T&M and fixed
                    for (const mainLine of calculationResourceMix) {
                        if (mainLine.CMSheetId === crID && (mainLine.PaymentMethod === 2 || mainLine.PaymentMethod === 1)) {  //Sum of revenue from billable lines of CR
                            if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                                for (const subLine of mainLine.ResourceRequestSubLine) {
                                    if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                                        discountedRevenueToFortude += Number(subLine.Revenue);
                                    }
                                }
                            } else {
                                if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
                                    discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                                }
                            }
                        }
                    }
                    if (crID !== null && crVersion !== null) {
                        await getCMSheet(crID, crVersion).then(res => {
                            discountedRevenueToFortude += Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate);
                            discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue / crRateRecordCurrencyRate);
                            // discountedRevenueToFortude += Number(res.data.ContingencyValue / crRateRecordCurrencyRate);
                        })
                    }
                }
            }
        }

        //Net profit calculations
        netProfit += discountedRevenueToFortude; //Revenue to Fortude after discount
        netProfit = netProfit - totalCostRM; //Cost from Resource Deployment

        if (this.state.project.StatusConvertedToActive === true) {
            const projectVersion = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Version : null;
            const projectID = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null;
            let projcurrencyId = 0;
            await getRegionCurrencyIntegrated(this.state.project.Region.id).then(response => {
                projcurrencyId = response.data.CurrencyId;
            })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
            const proStartDate = reStructureDate(this.state.project.StartDate);
            const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                projcurrencyId,
                proStartDate.getMonth() + 1,
                proStartDate.getFullYear()
            );
            if (projectID !== null && projectVersion !== null) {
                await getCMSheet(projectID, projectVersion).then(res => {
                    netProfit = netProfit - Number(res.data.NonBillableTotalOtherExpenses / projrateRecordCurrencyRate); //Non-Billable Other Expenses from CMSs
                    // netProfit = netProfit - Number(Number(res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
                    //Sales Commission from CMSs
                    const discountVal = (   // Value discount / (premium)
                        (Number(res.data.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate) + Number(res.data.ContingencyValue / projrateRecordCurrencyRate)) *
                        (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
                    const x = Math.pow(10, Number(2) + 1);
                    const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
                    const cmsRevenueAfterDiscount =
                        Number(res.data.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate) - Number(valueDiscount) + Number(res.data.ContingencyValue / projrateRecordCurrencyRate); // Revenue to company after discount
                    netProfit = netProfit - Number((cmsRevenueAfterDiscount * res.data.SalesCommissionPercentage / 100).toFixed(2)); //Sales Commission from CMSs
                })
            }
        }
        for (const cr of this.state.project.ChangeRequests) {
            if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
                const crVersion = cr.CMSheets[0].Version;
                const crID = cr.CMSheets[0].id;
                let currencyIdCR = 0;
                await getRegionCurrencyIntegrated(cr.BillingRegion).then(response => {
                    currencyIdCR = response.data.CurrencyId;
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });
                const crStartDate = reStructureDate(cr.StartDate);
                const crRateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                    currencyIdCR,
                    crStartDate.getMonth() + 1,
                    crStartDate.getFullYear()
                );
                await getCMSheet(crID, crVersion).then(res => {
                    netProfit = netProfit - Number(res.data.NonBillableTotalOtherExpenses / crRateRecordCurrencyRate); //Non-Billable Other Expenses from CMSs
                    // netProfit = netProfit - Number(Number(res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
                    // - Sales Commission from CMSs
                    const discountVal = (     // Value discount / (premium)
                        (Number(res.data.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate) + Number(res.data.ContingencyValue / crRateRecordCurrencyRate)) *
                        (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
                    const x = Math.pow(10, Number(2) + 1);
                    const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
                    const cmsRevenueAfterDiscount =
                        Number(res.data.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate) - Number(valueDiscount) + Number(res.data.ContingencyValue / crRateRecordCurrencyRate); // Revenue to company after discount
                    netProfit = netProfit - Number((cmsRevenueAfterDiscount * res.data.SalesCommissionPercentage / 100).toFixed(2)); //Sales Commission from CMSs
                })
            }
        }

        // TotalOngoingGrossProfit calculation
        // TotalOngoingGrossProfit = discountedRevenueToFortude - totalGrossCostProject;
        // TotalOngoingGrossProfit = TotalOngoingGrossProfit - totalGrossCostCr;

        TotalOngoingGrossProfit = discountedRevenueToFortude;

        if (this.state.project.StatusConvertedToActive === true) { // Project
            const projectVersion = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Version : null;
            const projectID = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null;

            let projcurrencyId = 0;
            await getRegionCurrencyIntegrated(this.state.project.Region.id).then(response => {
                projcurrencyId = response.data.CurrencyId;
            })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

            const proStartDate = reStructureDate(this.state.project.StartDate);
            const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                projcurrencyId,
                proStartDate.getMonth() + 1,
                proStartDate.getFullYear()
            );

            // Calculate on going gross profit
            if (projectID !== null && projectVersion !== null) {
                await getCMSheet(projectID, projectVersion).then(res => {
                    TotalOngoingGrossProfit = TotalOngoingGrossProfit - totalGrossCostProject;
                    TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.NonBillableTotalOtherExpenses / projrateRecordCurrencyRate);

                    let costContingencyVal = Number(Number(res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
                    // TotalOngoingGrossProfit = TotalOngoingGrossProfit - costContingencyVal;
                    
                    TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate); 

                })
            }
        }

        TotalOngoingGrossProfit = TotalOngoingGrossProfit - totalGrossCostCr;

        for (const cr of this.state.project.ChangeRequests) {
            if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
                const crVersion = cr.CMSheets[0].Version;
                const crID = cr.CMSheets[0].id;

                let currencyIdCR = 0;
                await getRegionCurrencyIntegrated(cr.BillingRegion).then(response => {
                    currencyIdCR = response.data.CurrencyId;
                }).catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

                const crStartDate = reStructureDate(cr.StartDate);
                const crRateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                    currencyIdCR,
                    crStartDate.getMonth() + 1,
                    crStartDate.getFullYear()
                );

                await getCMSheet(crID, crVersion).then(res => {
                    TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.NonBillableTotalOtherExpenses / crRateRecordCurrencyRate);

                    let costContingencyVal = Number(Number(res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
                    // TotalOngoingGrossProfit = TotalOngoingGrossProfit - costContingencyVal;

                    TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate); 

                })
            }
        }

        //estimated project duration
        var estDuration = '';
        var startDate = this.getStartDate();
        if (this.state.userDefinedEndDate) {
            const sd = reStructureDate(startDate);
            const ed = reStructureDate(this.state.userDefinedEndDate);
            estDuration = calculateDuration(sd, ed);
        } else {
            estDuration = '';
        }


        calculatedValues.totalEffortRM = totalEffortRM;
        calculatedValues.totalRevenueRM = totalRevenueRM;
        calculatedValues.totalCostRM = totalCostRM;
        calculatedValues.discountedRevenueToFortude = discountedRevenueToFortude;
        calculatedValues.netProfit = netProfit;
        calculatedValues.totalEffortDaysCMS = totalEffortDaysCMS;
        calculatedValues.discountedRevenueToFortudeCMS = discountedRevenueToFortudeCMS;
        calculatedValues.fixedPriceEffortOngoing = fixedPriceEffortOngoing;
        calculatedValues.fixedPriceEffortApproved = fixedPriceEffortApproved;
        calculatedValues.TMEffortOngoing = TMEffortOngoing;
        calculatedValues.TMEffortOngoingApproved = TMEffortApproved;
        calculatedValues.netProfitLossCMS = netProfitLossCMS;
        calculatedValues.estDuration = estDuration;
        calculatedValues.totalCostCMS = totalCostCMS;

        // Gross Profit / Gross Profit Marging %
        calculatedValues.TotalGrossProfit = TotalGrossProfit;
        GrossProfitMargin = calculatedValues.discountedRevenueToFortudeCMS ? formatNumber(Number(parseFloat(((calculatedValues.TotalGrossProfit / calculatedValues.discountedRevenueToFortudeCMS) * 100)))) + '%' : '0.00%';
        calculatedValues.GrossProfitMargin = GrossProfitMargin;

        // Gross - Ongoing CMS Value / Ongoing CMS Value percentage
        calculatedValues.TotalOngoingGrossProfit = TotalOngoingGrossProfit;


        // Gross - Variance value / percentage
        let GrossVeriance = ((calculatedValues.TotalOngoingGrossProfit ? Number(Math.abs(calculatedValues.TotalOngoingGrossProfit)) : 0.00) - (calculatedValues.TotalGrossProfit ? Number(Math.abs(calculatedValues.TotalGrossProfit)) : 0.00)).toFixed(2);
        calculatedValues.GrossVeriance = GrossVeriance;



        if (!isFromApproverView) {
            await this.setState({
                calculatedValues: calculatedValues,
                cmsCalculations: calculatedValues
            });
        } else {
            await this.setState({
                approvedCalculatedValues: calculatedValues,
                cmsCalculations: calculatedValues
            });
        }

        if ((this.props.location.action !== 'RPApproveWF') || (this.props.location.action === 'RPApproveWF' && isFromApproverView)) {
            this.populateInitialCMSToGrid();
        }
    }

    // Actual Calculations
    handleActualCalculations = async () => {
        let fixedEffort = 0;
        let fixedLineApproveTimeSum = 0;
        let fixedLineFutureAllocSum = 0;
        let tmEffort = 0;
        let tmLineApproveTimeSum = 0;
        let tmLineFutureAllocSum = 0;
        let billEffort = 0;
        let billApproveTimeSum = 0;
        let billLineFutureAllocSum = 0;
        let nonBillEffort = 0;
        let nbApproveTimeSum = 0;
        let nbLineFutureAllocSum = 0;
        let contractedEffort = 0;
        let contractedEffAct = 0;
        let contractedEffFt = 0;
        let revenue = 0;
        let avgRatePerDay = 0;
        let totalCost = 0;
        let directCost = 0;
        let grossMargin = 0;
        let grossMarginPercentage = 0;
        let netMargin = 0;
        let netMarginPercentage = 0;
        let estDuration = 0;
        let estDurationFt = 0;
        let estDurationAct = 0;
        let firstAppDate = null;
        let latestAppDate = null;
        let earliestAllocDate = null;
        let furthestAllocDate = null;

        // For variance
        let conEffVariance = 0;
        let conEffVarPercentage = 0;
        let fixedEffVariance = 0;
        let fixedEffVarPercentage = 0;
        let tmEffVariance = 0;
        let tmEffVarPercentage = 0;
        let billVariance = 0;
        let billVarPercentage = 0;
        let nbVariance = 0;
        let nbVarPercentage = 0;
        let revVariance = 0;
        let revVarPercentage = 0;
        let avgRateVariance = 0;
        let avgRateVarPercentage = 0;
        let grossMarginVariance = 0;
        let grossMarginVarPercentage = 0;
        let netMarginVariance = 0;
        let netMarginVarPercentage = 0;
        let revenueAct = 0;
        let revenueFt = 0;

        let projStatistics = [];
        let calculatedActValues = {};

        let project = this.state.project;
        let crs = this.state.project.ChangeRequests;
        let cmsCalculations = this.state.cmsCalculations;
        let calculationResourceMix = this.state.resourceMix;
        let projectStatus = this.state.project.ProjectStatus ? this.state.project.ProjectStatus.StatusName : null;
        const closedStatusList = ['Closed', 'Discontinue', 'Close without bidding', 'Closed-Opportunity Loss', 'Complete'];

        //For Resource Allocation Summary Calculations
        if (this.props.location.action === 'RPApprove' || this.props.location.action === 'RPApproveWF') {
            calculationResourceMix = this.state.allocationSummaryResources;
        }

        // Get project and that project's cr(s) statistics
        await getProjectStatistics(project.id).then(response => {
            if (response.data && response.data.length > 0) {
                projStatistics = response.data;
            }
        }).catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

        // Project
        if (project && project !== null) {
            let projCms = null;
            let tmBillAppTimeRev = 0;
            let tmFtAllocRev = 0;
            let fixedLineTotCost = 0;
            let tmLineTotCost = 0;
            let fixedLineDirCost = 0;
            let tmLineDirCost = 0;

            let projStat = projStatistics.length > 0 ? 
                            (projStatistics.filter(el => el.ProjectId == project.id && el.CrId == null).length > 0 ? 
                            projStatistics.filter(el => el.ProjectId == project.id && el.CrId == null)[0] : null) : null;

            const projectVersion = project.CMSheets.length > 0 ? project.CMSheets[0].Version : null;
            const projectID = project.CMSheets.length > 0 ? project.CMSheets[0].id : null;

            if (projectID !== null && projectVersion !== null) {
                await getCMSheet(projectID, projectVersion).then(res => {
                    if (res.data && res.data !== null) {
                        projCms = res.data;
                    }
                }).catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
            }

            if (projStat && projStat !== null) {
                // For Est Project Duration
                firstAppDate = (firstAppDate !== null && projStat.FirstApproveDate && projStat.FirstApproveDate !== null && firstAppDate < projStat.FirstApproveDate) ? 
                               firstAppDate : projStat.FirstApproveDate;
                latestAppDate = (latestAppDate !== null && projStat.LatestApproveDate && projStat.LatestApproveDate !== null && latestAppDate > projStat.LatestApproveDate) ? 
                                latestAppDate : projStat.LatestApproveDate;
                furthestAllocDate = (furthestAllocDate !== null && projStat.FurthestAllocDate && projStat.FurthestAllocDate !== null && furthestAllocDate > projStat.FurthestAllocDate) ? 
                                    furthestAllocDate : projStat.FurthestAllocDate;
                earliestAllocDate = (earliestAllocDate !== null && projStat.EarliestAllocDate && projStat.EarliestAllocDate !== null && earliestAllocDate < projStat.EarliestAllocDate) ? 
                                    earliestAllocDate : projStat.EarliestAllocDate;

                // For Fixed Price Effort
                let fxdLineAppTimeSum = (projStat.FixedLineApproveTimeSum && projStat.FixedLineApproveTimeSum !== null ? Number(projStat.FixedLineApproveTimeSum) : 0);
                let fxdLineFtAllocSum = (projStat.FixedLineFutureAllocSum && projStat.FixedLineFutureAllocSum !== null ? Number(projStat.FixedLineFutureAllocSum) : 0);
                fixedEffort += ((fxdLineAppTimeSum / 8) + (fxdLineFtAllocSum));
                fixedLineApproveTimeSum += (fxdLineAppTimeSum / 8);
                fixedLineFutureAllocSum += (fxdLineFtAllocSum);
                
                // For T&M Effort
                let tmLineAppTimeSum = (projStat.TMLineApproveTimeSum && projStat.TMLineApproveTimeSum !== null ? Number(projStat.TMLineApproveTimeSum) : 0);
                let tmLineFtAllocSum = (projStat.TMLineFutureAllocSum && projStat.TMLineFutureAllocSum !== null ? Number(projStat.TMLineFutureAllocSum) : 0);
                tmEffort += ((tmLineAppTimeSum / 8) + (tmLineFtAllocSum));
                tmLineApproveTimeSum += (tmLineAppTimeSum / 8);
                tmLineFutureAllocSum += (tmLineFtAllocSum);

                // For Billable Effort
                let billAppTimeSum = (projStat.BillApproveTimeSum && projStat.BillApproveTimeSum !== null ? Number(projStat.BillApproveTimeSum) : 0);
                let billLineFtAllocSum = (projStat.BillLineFutureAllocSum && projStat.BillLineFutureAllocSum !== null ? Number(projStat.BillLineFutureAllocSum) : 0);
                billEffort += ((billAppTimeSum / 8) + (billLineFtAllocSum));
                billApproveTimeSum += (billAppTimeSum / 8);
                billLineFutureAllocSum += (billLineFtAllocSum);

                // For NonBillable Effort
                let nbAppTimeSum = (projStat.NBApproveTimeSum && projStat.NBApproveTimeSum !== null ? Number(projStat.NBApproveTimeSum) : 0);
                let nbLineFtAllocSum = (projStat.NBLineFutureAllocSum && projStat.NBLineFutureAllocSum !== null ? Number(projStat.NBLineFutureAllocSum) : 0);
                nonBillEffort += ((nbAppTimeSum / 8) + (nbLineFtAllocSum));
                nbApproveTimeSum += (nbAppTimeSum / 8);
                nbLineFutureAllocSum += (nbLineFtAllocSum);

                // For Revenue
                tmBillAppTimeRev = projStat.TMLineBillApproveTimeDRSum && projStat.TMLineBillApproveTimeDRSum !== null ? 
                                   Number(projStat.TMLineBillApproveTimeDRSum) : 0;
                tmFtAllocRev = projStat.TMLineBillFutureAllocDRSum && projStat.TMLineBillFutureAllocDRSum !== null ? 
                               Number(projStat.TMLineBillFutureAllocDRSum) : 0;

                // For Total Cost
                fixedLineTotCost = /*(projStat.FixedLineCapValDCSum && projStat.FixedLineCapValDCSum !== null ? Number(projStat.FixedLineCapValDCSum) : 0) + 
                                   (projStat.FixedLineCapValOHSum && projStat.FixedLineCapValOHSum !== null ? Number(projStat.FixedLineCapValOHSum) : 0) +*/ 
                                   (projStat.FixedLineFutureAllocDCSum && projStat.FixedLineFutureAllocDCSum !== null ? Number(projStat.FixedLineFutureAllocDCSum) : 0) + 
                                   (projStat.FixedLineFutureAllocOHSum && projStat.FixedLineFutureAllocOHSum !== null ? Number(projStat.FixedLineFutureAllocOHSum) : 0);

                tmLineTotCost = /*(projStat.TMLineCapValDCSum && projStat.TMLineCapValDCSum !== null ? Number(projStat.TMLineCapValDCSum) : 0) + 
                                (projStat.TMLineCapValOHSum && projStat.TMLineCapValOHSum !== null ? Number(projStat.TMLineCapValOHSum) : 0) + */
                                (projStat.TMLineFutureAllocDCSum && projStat.TMLineFutureAllocDCSum !== null ? Number(projStat.TMLineFutureAllocDCSum) : 0) + 
                                (projStat.TMLineFutureAllocOHSum && projStat.TMLineFutureAllocOHSum !== null ? Number(projStat.TMLineFutureAllocOHSum) : 0);

                // For Direct Cost
                fixedLineDirCost = /*(projStat.FixedLineCapValDCSum && projStat.FixedLineCapValDCSum !== null ? Number(projStat.FixedLineCapValDCSum) : 0) + */
                                   (projStat.FixedLineFutureAllocDCSum && projStat.FixedLineFutureAllocDCSum !== null ? Number(projStat.FixedLineFutureAllocDCSum) : 0);

                tmLineDirCost = /*(projStat.TMLineCapValDCSum && projStat.TMLineCapValDCSum !== null ? Number(projStat.TMLineCapValDCSum) : 0) + */
                                (projStat.TMLineFutureAllocDCSum && projStat.TMLineFutureAllocDCSum !== null ? Number(projStat.TMLineFutureAllocDCSum) : 0);
            }

            let projcurrencyId = 0;
            let billingRegion = this.state.project.BillingRegion ? this.state.project.BillingRegion : this.state.project.Region.id;
            await getRegionCurrencyIntegrated(billingRegion).then(response => {
                projcurrencyId = response.data.CurrencyId;
            })
                .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
            const proStartDate = new Date(project.StartDate);
            const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                projcurrencyId,
                proStartDate.getMonth() + 1,
                proStartDate.getFullYear()
            );

            if (projCms !== null) {
                let projRevenue = 0;
                let serviceRev = projCms.ServiceRevenueValue && projCms.ServiceRevenueValue !== null ? Number(projCms.ServiceRevenueValue / projrateRecordCurrencyRate) : 0;
                let discountPercentage = projCms.DiscountPercentage && projCms.DiscountPercentage !== null ? (Number(projCms.DiscountPercentage) / 100) : 0;
                // let nonBillExpenses = ((projCms.Status && projCms.Status == 'APPROVED') && (projCms.NonBillableTotalOtherExpenses && projCms.NonBillableTotalOtherExpenses !== null)) ?
                //                       (Number(projCms.NonBillableTotalOtherExpenses / projrateRecordCurrencyRate)) : 0;
                let otherExpensesTotal = await this.calculateActualNonBillableOtherExpenses(projectID, projectVersion, projectStatus);

                let salesComPercentage = ((projCms.Status && projCms.Status == 'APPROVED') && (projCms.SalesCommissionPercentage && projCms.SalesCommissionPercentage !== null)) ?
                                         (Number(projCms.SalesCommissionPercentage) / 100) : 0;

                // Revenue To Company
                if ((project.StatusConvertedToActive && project.StatusConvertedToActive === true) && (project.PaymentMethodRecord && project.PaymentMethodRecord.id == 1)){ //Fixed
                    const serviceRevenue = serviceRev.toFixed(2);
                    // Contingency
                    const contingencyValue = (Number(projCms.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(serviceRevenue)) * (Number(projCms.ContingencyPercentage) / 100);
                    // Value discount / (premium)
                    const discountVal = ((Number(projCms.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(serviceRevenue) + Number(contingencyValue)) * (discountPercentage)).toFixed(2);
                    const x = Math.pow(10, Number(2) + 1);
                    const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
                    // Revenue to company after discount
                    const revenueAfterDiscount = Number(projCms.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(serviceRevenue) - Number(valueDiscount) + Number(contingencyValue);

                    projRevenue += revenueAfterDiscount;
                }
                if (project.PaymentMethodRecord && project.PaymentMethodRecord.id == 2) { //T&M
                    projRevenue += ((tmBillAppTimeRev + tmFtAllocRev + serviceRev) * (1 - discountPercentage));
                    revenueAct +=  (tmBillAppTimeRev + serviceRev) * (1 - discountPercentage);
                    revenueFt += tmFtAllocRev * (1 - discountPercentage);
                }
                if (project.PaymentMethodRecord && project.PaymentMethodRecord.id == 3) { //Hybrid
                    // Revenue for Fixed price lines
                    let revForFixed = 0;
                    if (projCms.Status && projCms.Status == 'APPROVED') {
                        for (let mainLine of calculationResourceMix) {
                            if (mainLine.PaymentMethod && mainLine.PaymentMethod == 1 && mainLine.Type && mainLine.Type == 'Project') {
                                if (mainLine.ResourceRequestSubLine.length > 0) {
                                    for (let subLine of mainLine.ResourceRequestSubLine) {
                                        if (subLine.Status && (subLine.Status == 'COMPLETE' || subLine.Status == 'CLOSE')) {
                                            revForFixed += (subLine.Revenue && subLine.Revenue !== null ?  Number(subLine.Revenue) : 0);
                                        }
                                    }
                                }
                                else {
                                    if (mainLine.Status && (mainLine.Status == 'COMPLETE' || mainLine.Status == 'CLOSE')) {
                                        revForFixed += (mainLine.TotalRevenue && mainLine.TotalRevenue !== null ? Number(mainLine.TotalRevenue) : 0);
                                    }
                                }
                            }
                        }
                    }

                    // Revenue for T&M lines
                    let revForTM = (tmBillAppTimeRev + tmFtAllocRev + serviceRev);
                    projRevenue += ((revForFixed + revForTM) * (1 - discountPercentage));
                }
                revenue += projRevenue;

                // Total Cost
                totalCost += ((fixedLineTotCost) + (tmLineTotCost) + (otherExpensesTotal) + (projRevenue * salesComPercentage));

                // Direct Cost
                directCost += ((fixedLineDirCost) + (tmLineDirCost) + (otherExpensesTotal));
            }
        }

        // CR(s)
        if (crs && crs.length > 0) {
            for (let cr of crs) {
                let crCms = null;
                let tmBillAppTimeRev = 0;
                let tmFtAllocRev = 0;
                let fixedLineTotCost = 0;
                let tmLineTotCost = 0;
                let fixedLineDirCost = 0;
                let tmLineDirCost = 0;
                let crStatus = cr.Status ? cr.Status.StatusName : null;

                let projStat = projStatistics.length > 0 ? 
                            (projStatistics.filter(el => el.ProjectId == project.id && el.CrId == cr.id).length > 0 ? 
                            projStatistics.filter(el => el.ProjectId == project.id && el.CrId == cr.id)[0] : null) : null;

                const crVersion = cr.CMSheets.length > 0 ? cr.CMSheets[0].Version : null;
                const crID = cr.CMSheets.length > 0 ? cr.CMSheets[0].id : null;

                if (crID !== null && crVersion !== null) {
                    await getCMSheet(crID, crVersion).then(res => {
                        if (res.data && res.data !== null) {
                            crCms = res.data;
                        }
                    }).catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });
                }

                if (projStat && projStat !== null) {
                    // For Est Project Duration
                    firstAppDate = (firstAppDate !== null && projStat.FirstApproveDate && projStat.FirstApproveDate !== null && firstAppDate < projStat.FirstApproveDate) ? 
                                    firstAppDate : projStat.FirstApproveDate;
                    latestAppDate = (latestAppDate !== null && projStat.LatestApproveDate && projStat.LatestApproveDate !== null && latestAppDate > projStat.LatestApproveDate) ? 
                                    latestAppDate : projStat.LatestApproveDate;
                    furthestAllocDate = (furthestAllocDate !== null && projStat.FurthestAllocDate && projStat.FurthestAllocDate !== null && furthestAllocDate > projStat.FurthestAllocDate) ? 
                                        furthestAllocDate : projStat.FurthestAllocDate;
                    earliestAllocDate = (earliestAllocDate !== null && projStat.EarliestAllocDate && projStat.EarliestAllocDate !== null && earliestAllocDate < projStat.EarliestAllocDate) ? 
                                        earliestAllocDate : projStat.EarliestAllocDate;

                    // For Fixed Price Effort
                    let fxdLineAppTimeSum = (projStat.FixedLineApproveTimeSum && projStat.FixedLineApproveTimeSum !== null ? Number(projStat.FixedLineApproveTimeSum) : 0);
                    let fxdLineFtAllocSum = (projStat.FixedLineFutureAllocSum && projStat.FixedLineFutureAllocSum !== null ? Number(projStat.FixedLineFutureAllocSum) : 0);
                    fixedEffort += ((fxdLineAppTimeSum / 8) + (fxdLineFtAllocSum));
                    fixedLineApproveTimeSum += (fxdLineAppTimeSum / 8);
                    fixedLineFutureAllocSum += fxdLineFtAllocSum;
                    
                    // For T&M Effort
                    let tmLineAppTimeSum = (projStat.TMLineApproveTimeSum && projStat.TMLineApproveTimeSum !== null ? Number(projStat.TMLineApproveTimeSum) : 0);
                    let tmLineFtAllocSum = (projStat.TMLineFutureAllocSum && projStat.TMLineFutureAllocSum !== null ? Number(projStat.TMLineFutureAllocSum) : 0);
                    tmEffort += ((tmLineAppTimeSum / 8) + (tmLineFtAllocSum));
                    tmLineApproveTimeSum += (tmLineAppTimeSum / 8);
                    tmLineFutureAllocSum += tmLineFtAllocSum;

                    // For Billable Effort
                    let billAppTimeSum = (projStat.BillApproveTimeSum && projStat.BillApproveTimeSum !== null ? Number(projStat.BillApproveTimeSum) : 0);
                    let billLineFtAllocSum = (projStat.BillLineFutureAllocSum && projStat.BillLineFutureAllocSum !== null ? Number(projStat.BillLineFutureAllocSum) : 0);
                    billEffort += ((billAppTimeSum / 8) + (billLineFtAllocSum));
                    billApproveTimeSum += (billAppTimeSum / 8);
                    billLineFutureAllocSum += (billLineFtAllocSum);

                    // For NonBillable Effort
                    let nbAppTimeSum = (projStat.NBApproveTimeSum && projStat.NBApproveTimeSum !== null ? Number(projStat.NBApproveTimeSum) : 0);
                    let nbLineFtAllocSum = (projStat.NBLineFutureAllocSum && projStat.NBLineFutureAllocSum !== null ? Number(projStat.NBLineFutureAllocSum) : 0);
                    nonBillEffort += ((nbAppTimeSum / 8) + (nbLineFtAllocSum));
                    nbApproveTimeSum += (nbAppTimeSum / 8);
                    nbLineFutureAllocSum += (nbLineFtAllocSum);

                    // For Revenue
                    tmBillAppTimeRev = projStat.TMLineBillApproveTimeDRSum && projStat.TMLineBillApproveTimeDRSum !== null ? 
                                            Number(projStat.TMLineBillApproveTimeDRSum) : 0;
                    tmFtAllocRev = projStat.TMLineBillFutureAllocDRSum && projStat.TMLineBillFutureAllocDRSum !== null ? 
                                        Number(projStat.TMLineBillFutureAllocDRSum) : 0;

                    // For Total Cost
                    fixedLineTotCost = /*(projStat.FixedLineCapValDCSum && projStat.FixedLineCapValDCSum !== null ? Number(projStat.FixedLineCapValDCSum) : 0) + 
                                        (projStat.FixedLineCapValOHSum && projStat.FixedLineCapValOHSum !== null ? Number(projStat.FixedLineCapValOHSum) : 0) + */
                                        (!projStat.FixedLineFutureAllocDCSum && projStat.FixedLineFutureAllocDCSum !== null ? Number(projStat.FixedLineFutureAllocDCSum) : 0) + 
                                        (projStat.FixedLineFutureAllocOHSum && projStat.FixedLineFutureAllocOHSum !== null ? Number(projStat.FixedLineFutureAllocOHSum) : 0);

                    tmLineTotCost = /*(projStat.TMLineCapValDCSum && projStat.TMLineCapValDCSum !== null ? Number(projStat.TMLineCapValDCSum) : 0) + 
                                    (projStat.TMLineCapValOHSum && projStat.TMLineCapValOHSum !== null ? Number(projStat.TMLineCapValOHSum) : 0) + */
                                    (projStat.TMLineFutureAllocDCSum && projStat.TMLineFutureAllocDCSum !== null ? Number(projStat.TMLineFutureAllocDCSum) : 0) + 
                                    (projStat.TMLineFutureAllocOHSum && projStat.TMLineFutureAllocOHSum !== null ? Number(projStat.TMLineFutureAllocOHSum) : 0);

                    // For Direct Cost
                    fixedLineDirCost = /*(projStat.FixedLineCapValDCSum && projStat.FixedLineCapValDCSum !== null ? Number(projStat.FixedLineCapValDCSum) : 0) + */
                                        (projStat.FixedLineFutureAllocDCSum && projStat.FixedLineFutureAllocDCSum !== null ? Number(projStat.FixedLineFutureAllocDCSum) : 0);

                    tmLineDirCost = /*(projStat.TMLineCapValDCSum && projStat.TMLineCapValDCSum !== null ? Number(projStat.TMLineCapValDCSum) : 0) + */
                                    (projStat.TMLineFutureAllocDCSum && projStat.TMLineFutureAllocDCSum !== null ? Number(projStat.TMLineFutureAllocDCSum) : 0);
                }

                let currencyIdCR = 0;
                await getRegionCurrencyIntegrated(cr.BillingRegion).then(response => {
                currencyIdCR = response.data.CurrencyId;
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
                const crStartDate = new Date(cr.StartDate);
                const crRateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                    currencyIdCR,
                    crStartDate.getMonth() + 1,
                    crStartDate.getFullYear()
                );

                if (crCms !== null) {
                    let crRevenue = 0;
                    let serviceRev = crCms.ServiceRevenueValue && crCms.ServiceRevenueValue !== null ? Number(crCms.ServiceRevenueValue / crRateRecordCurrencyRate) : 0;
                    let discountPercentage = crCms.DiscountPercentage && crCms.DiscountPercentage !== null ? (Number(crCms.DiscountPercentage) / 100) : 0;
                    // let nonBillExpenses = (crCms.Status && crCms.Status == 'APPROVED') && (crCms.NonBillableTotalOtherExpenses && crCms.NonBillableTotalOtherExpenses !== null) ?
                    //                         Number(crCms.NonBillableTotalOtherExpenses / crRateRecordCurrencyRate) : 0;
                    let otherExpensesTotal = await this.calculateActualNonBillableOtherExpenses(crID, crVersion, crStatus);
                    let salesComPercentage = (crCms.Status && crCms.Status == 'APPROVED') && (crCms.SalesCommissionPercentage && crCms.SalesCommissionPercentage !== null) ?
                                            (Number(crCms.SalesCommissionPercentage) / 100) : 0;

                    // Revenue To Company
                    if ((cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) && (cr.PaymentMethodId && cr.PaymentMethodId == 1)) { //Fixed
                        const serviceRevenue = serviceRev.toFixed(2);
                        // Contingency
                        const contingencyValue = (Number(crCms.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(serviceRevenue)) * (Number(crCms.ContingencyPercentage) / 100);
                        // Value discount / (premium)
                        const discountVal = ((Number(crCms.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(serviceRevenue) + Number(contingencyValue)) * (discountPercentage)).toFixed(2);
                        const x = Math.pow(10, Number(2) + 1);
                        const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
                        // Revenue to company after discount
                        const revenueAfterDiscount = Number(crCms.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(serviceRevenue) - Number(valueDiscount) + Number(contingencyValue);

                        crRevenue += revenueAfterDiscount;
                    }
                    if (cr.PaymentMethodId && cr.PaymentMethodId == 2) { //T&M
                        crRevenue += ((tmBillAppTimeRev + tmFtAllocRev + serviceRev) * (1 - discountPercentage));
                        revenueAct +=  (tmBillAppTimeRev + serviceRev) * (1 - discountPercentage);
                        revenueFt += tmFtAllocRev * (1 - discountPercentage);
                    }
                    if (cr.PaymentMethodId && cr.PaymentMethodId == 3) { //Hybrid
                        // Revenue for Fixed lines
                        let revForFixed = 0;
                        if (crCms.Status && crCms.Status == 'APPROVED') {
                            for (let mainLine of calculationResourceMix) {
                                if (mainLine.PaymentMethod && mainLine.PaymentMethod === 1 && mainLine.Type && mainLine.Type == 'ChangeRequest') {
                                    if (mainLine.ResourceRequestSubLine.length > 0) {
                                        for (let subLine of mainLine.ResourceRequestSubLine) {
                                            if (subLine.Status && (subLine.Status == 'COMPLETE' || subLine.Status == 'CLOSE')) {
                                                revForFixed += (subLine.Revenue && subLine.Revenue !== null ? Number(subLine.Revenue) : 0);
                                            }
                                        }
                                    }
                                    else {
                                        if (mainLine.Status && (mainLine.Status == 'COMPLETE' || mainLine.Status == 'CLOSE')) {
                                            revForFixed += (mainLine.TotalRevenue && mainLine.TotalRevenue !== null ? Number(mainLine.TotalRevenue) : 0);
                                        }
                                    }
                                }
                            }
                        }
                        
                        // Revenue for T&M lines
                        let revForTM = (tmBillAppTimeRev + tmFtAllocRev + serviceRev);
                        crRevenue += ((revForFixed + revForTM) * (1 - discountPercentage));
                    }
                    revenue += crRevenue;

                    // Total Cost
                    totalCost += ((fixedLineTotCost) + (tmLineTotCost) + (otherExpensesTotal) + (crRevenue * salesComPercentage));

                    // Direct Cost
                    directCost += ((fixedLineDirCost) + (tmLineDirCost) + (otherExpensesTotal));
                }
            }
        }

        // Est Project Duration
        let startDateAct = firstAppDate !== null ? reStructureDate(firstAppDate) : null;
        let endDateAct = latestAppDate !== null ? reStructureDate(latestAppDate) : null;
        if (startDateAct !== null && endDateAct !== null) {
            endDateAct.setDate(endDateAct.getDate() + 1);
            estDurationAct = moment(endDateAct).diff(moment(startDateAct), 'days');
        } else {
            estDurationAct = 0;
        }

        let startDateFt = latestAppDate !== null ? reStructureDate(latestAppDate) : reStructureDate(earliestAllocDate);
        let endDateFt = furthestAllocDate !== null ? reStructureDate(furthestAllocDate) : null;
        if (startDateFt !== null && endDateFt !== null) {
            if(latestAppDate !== null){
                startDateFt.setDate(startDateFt.getDate() + 1);
            }
            endDateFt.setDate(endDateFt.getDate() + 1);

            estDurationFt = moment(endDateFt).diff(moment(startDateFt), 'days');
        } else {
            estDurationFt = 0
        }
        estDuration = estDurationAct + estDurationFt;

        // Contracted Effort(Days), Avg Rate Per Day
        contractedEffort = Number(fixedEffort.toFixed(2)) + Number(tmEffort.toFixed(2));
        contractedEffAct = Number(fixedLineApproveTimeSum.toFixed(2)) + Number(tmLineApproveTimeSum.toFixed(2));
        contractedEffFt = Number(fixedLineFutureAllocSum.toFixed(2)) + Number(tmLineFutureAllocSum.toFixed(2));
        avgRatePerDay = contractedEffort !== 0 ? (Number(revenue.toFixed(2)) / contractedEffort) : 0;

        // Gross Margin, Gross Margin Percentage
        grossMargin = Number(revenue.toFixed(2)) - Number(directCost.toFixed(2));
        grossMarginPercentage = revenue !== 0 ? (Number(grossMargin.toFixed(2)) / Number(revenue.toFixed(2))) : 0;

        // Net Margin, Net Margin Percentage
        netMargin = Number(revenue.toFixed(2)) - Number(totalCost.toFixed(2));
        netMarginPercentage = revenue !== 0 ? (Number(netMargin.toFixed(2)) / Number(revenue.toFixed(2))) : 0;


        // CMS Values - For Variance Calculations
        let totEffDaysCms = (cmsCalculations !== null && cmsCalculations.totalEffortDaysCMS && cmsCalculations.totalEffortDaysCMS !== null) ? 
                            (Number(cmsCalculations.totalEffortDaysCMS).toFixed(2)) : 0;
        let fixedPriceEffCms = (cmsCalculations !== null && cmsCalculations.fixedPriceEffortApproved && cmsCalculations.fixedPriceEffortApproved !== null) ? 
                               (Number(cmsCalculations.fixedPriceEffortApproved).toFixed(2)) : 0;
        let tmEffCms = (cmsCalculations !== null && cmsCalculations.TMEffortOngoingApproved && cmsCalculations.TMEffortOngoingApproved !== null) ? 
                       (Number(cmsCalculations.TMEffortOngoingApproved).toFixed(2)) : 0;
        let revenueCms = (cmsCalculations !== null && cmsCalculations.discountedRevenueToFortudeCMS && cmsCalculations.discountedRevenueToFortudeCMS !== null) ? 
                         (Number(cmsCalculations.discountedRevenueToFortudeCMS).toFixed(2)) : 0;
        let grossProfitCms = (cmsCalculations !== null && cmsCalculations.TotalGrossProfit && cmsCalculations.TotalGrossProfit !== null) ? 
                             (Number(cmsCalculations.TotalGrossProfit).toFixed(2)) : 0;
        let netProfitCms = (cmsCalculations !== null && cmsCalculations.netProfitLossCMS && cmsCalculations.netProfitLossCMS !== null) ? 
                           (Number(cmsCalculations.netProfitLossCMS).toFixed(2)) : 0;

        // Variance Calculations
        conEffVariance = Number(contractedEffort.toFixed(2)) - Number(totEffDaysCms);
        conEffVarPercentage = Number(totEffDaysCms) !== 0 ? ((Number(conEffVariance) / Number(totEffDaysCms)) * 100 ) : 0;
        fixedEffVariance = Number(fixedEffort.toFixed(2)) - Number(fixedPriceEffCms);
        fixedEffVarPercentage = Number(fixedPriceEffCms) !== 0 ? ((Number(fixedEffVariance) / Number(fixedPriceEffCms)) * 100 ) : 0;
        tmEffVariance = Number(tmEffort.toFixed(2)) - Number(tmEffCms);
        tmEffVarPercentage = Number(tmEffCms) !== 0 ? ((Number(tmEffVariance) / Number(tmEffCms)) * 100 ) : 0;
        billVariance = Number(billEffort.toFixed(2)) - 0;
        billVarPercentage = 0 ;
        nbVariance = Number(nonBillEffort.toFixed(2)) - 0;
        nbVarPercentage = 0;
        revVariance = Number(revenue.toFixed(2)) - Number(revenueCms);
        revVarPercentage = Number(revenueCms) !== 0 ? ((Number(revVariance) / Number(revenueCms)) * 100 ) : 0;
        avgRateVariance = Number(avgRatePerDay.toFixed(2)) - (Number(totEffDaysCms) !== 0 ? Number((Number(revenueCms) / Number(totEffDaysCms)).toFixed(2)) : 0);
        avgRateVarPercentage = (Number(totEffDaysCms) !== 0 && Number(revenueCms) !== 0) ? (( Number(avgRateVariance) / Number((Number(revenueCms) / Number(totEffDaysCms)).toFixed(2)) ) * 100 ) : 0;
        grossMarginVariance = Number(grossMargin.toFixed(2)) - Number(grossProfitCms);
        grossMarginVarPercentage = Number(grossProfitCms) !== 0 ? ((Number(grossMarginVariance) / Number(grossProfitCms)) * 100 ) : 0;
        netMarginVariance = Number(netMargin.toFixed(2)) - Number(netProfitCms);
        netMarginVarPercentage = Number(netProfitCms) !== 0 ? ((Number(netMarginVariance) / Number(netProfitCms)) * 100 ) : 0;

        calculatedActValues = {
            EstDuration: estDuration,
            EstDurationAct: estDurationAct,
            EstDurationFt: estDurationFt,
            FixedEffort: fixedEffort,
            FixedEffortAct: fixedLineApproveTimeSum,
            FixedEffortFt: fixedLineFutureAllocSum,
            TMEffort: tmEffort,
            TMEffortAct: tmLineApproveTimeSum,
            TMEffortFt: tmLineFutureAllocSum,
            ContractedEffort: contractedEffort * 8,
            ContractedEffortDays: contractedEffort,
            ContractedEffortAct: contractedEffAct,
            ContractedEffortFt: contractedEffFt,
            Billable: billEffort,
            BillApproveTimeSum: billApproveTimeSum,
            BillLineFutureAllocSum: billLineFutureAllocSum,
            NonBillable: nonBillEffort,
            NBApproveTimeSum: nbApproveTimeSum,
            NBLineFutureAllocSum: nbLineFutureAllocSum,
            Revenue: revenue,
            AvgRatePerDay: avgRatePerDay,
            GrossMargin: grossMargin,
            GrossPercentage: grossMarginPercentage,
            NetMargin: netMargin,
            NetPercentage: netMarginPercentage,

            // Variance
            ConEffVariance: conEffVariance,
            ConEffVarPercentage: conEffVarPercentage,
            FixedEffVariance: fixedEffVariance,
            FixedEffVarPercentage: fixedEffVarPercentage,
            TmEffVariance: tmEffVariance,
            TmEffVarPercentage: tmEffVarPercentage,
            BillVariance: billVariance,
            BillVarPercentage: billVarPercentage,
            NBVariance: nbVariance,
            NBVarPercentage: nbVarPercentage,
            RevVariance: revVariance,
            RevVarPercentage: revVarPercentage,
            AvgRateVariance: avgRateVariance,
            AvgRateVarPercentage: avgRateVarPercentage,
            GrossMarginVariance: grossMarginVariance,
            GrossMarginVarPercentage: grossMarginVarPercentage,
            NetMarginVariance: netMarginVariance,
            NetMarginVarPercentage: netMarginVarPercentage,
            RevenueAct: Number(revenueAct).toFixed(2),
            RevenueFt: Number(revenueFt).toFixed(2)
        };

        this.setState({
            calculatedActValues: calculatedActValues
        });
        this.populateInitialCMSToGrid();
    };

    handleComboChangeEdit = async event => {
        const value = event.target.value;
        const name = event.target.name;
        switch (name) {
            case 'editRole':
                if (value) {
                    let filteredArray = this.designationsAll.filter(function (itm) {
                        return value.CategoryId === itm.CategoryId || itm.Name === 'Outsourced';
                    });
                    this.TmpDesignationsAll = filteredArray;
                    this.state.editableItem.dataValueProjectRole = value;
                    this.state.editableItem.ProjectRole = value.id;
                    this.state.tmpDesignationsAll = filteredArray;
                } else {
                    this.TmpDesignationsAll = [];
                    this.state.tmpDesignationsAll = [];
                    this.state.editableItem.dataValueProjectRole = null;
                    this.state.editableItem.ProjectRole = null;
                }
                this.state.editableItem.dataValueCostDesignation = null;
                this.state.editableItem.Designation = null;
                this.forceUpdate();
                break;
            case 'editDesignation':
                if (value) {
                    //const index = event.dataItem.index;
                    const index = event.target.index;
                    this.state.editableItem.dataValueCostDesignation = value;
                    this.state.editableItem.Designation = value.id;

                    if (value && value.Name === 'Outsourced') {
                        await this.setState(
                            {
                                OutsourcedCostDataItemIndex: index
                            },
                            () => {
                                this.toggleOutsourcedCostDialog();
                            }
                        );
                    }
                } else {
                    this.state.editableItem.dataValueCostDesignation = null;
                    this.state.editableItem.Designation = null;
                }
                this.forceUpdate();
                break;
            case 'editRegion':
                if (value) {
                    this.state.editableItem.dataValueResourceRegion = value;
                    this.state.editableItem.ResourceRegion = value.id;
                } else {
                    this.state.editableItem.dataValueResourceRegion = null;
                    this.state.editableItem.ResourceRegion = null;
                }
                this.forceUpdate();
                break;
            case 'editOnSiteStatus':
                if (value) {
                    this.state.editableItem.OnsiteStatus = value.Name;
                } else {
                    this.state.editableItem.OnsiteStatus = null;
                }
                this.forceUpdate();
                break;
            case 'editAllocatedResource':
                if (value) {
                    await this.setAllocatedDesignation(value);
                    this.state.editableItem.AllocatedResource = value;
                    this.state.editableItem.ResourceId = value.id;
                    this.state.editableItem.AllocatedResourceID = value.id;
                    this.state.editableItem.AllocatedDesignation = this.state.allocatedDesignation;
                } else {
                    this.state.editableItem.AllocatedResource = null;
                    this.state.editableItem.AllocatedResourceID = null;
                    this.state.editableItem.ResourceId = null;
                    this.state.editableItem.AllocatedDesignation = null;
                }
                this.forceUpdate();
                break;
            case 'editPhase':
                if (value) {
                    this.state.editableItem.Phase = value;
                    this.state.editableItem.PhaseId = value.id;

                    let subPhasesOfSelectedPhase = [];
                    if (value && value.SubPhases && value.SubPhases.length > 0) {
                        subPhasesOfSelectedPhase = value.SubPhases;
                    }

                    this.setState({
                        subPhasesOfSelectedPhase: subPhasesOfSelectedPhase
                    });

                } else {
                    this.state.editableItem.Phase = null;
                    this.state.editableItem.PhaseId = null;

                    this.state.editableItem.SubPhase = null;
                    this.state.editableItem.SubPhaseId = null;

                    this.setState({
                        subPhasesOfSelectedPhase: []
                    });

                }
                break;
            case 'editSubPhase':
                if (value) {
                    this.state.editableItem.SubPhase = value;
                    this.state.editableItem.SubPhaseId = value.id;
                } else {
                    this.state.editableItem.SubPhase = null;
                    this.state.editableItem.SubPhaseId = null;
                }
                this.forceUpdate();
                break;
            default:
                break;
        }
    };

    setAllocatedDesignation = async (value) => {
        let selectedDesignation = this.designationsAll.filter(
            resource => resource.id === value.DesignationId
        );
        await this.setState({
            allocatedDesignation: selectedDesignation[0].Name
        });
    };

    handleAddStartDateChange = event => {
        let startDate = event.target.value;
        let name = event.target.name;
        switch (name) {
            case 'editStartDate':
                this.state.editableItem.StartDate = moment((reStructureDate(startDate))).format('MM/DD/YYYY');
                this.forceUpdate();
                break;
            case 'editEndDate':
                this.state.editableItem.StartDate = moment((reStructureDate(startDate))).format('MM/DD/YYYY');
                this.forceUpdate();
                break;
            case 'addStartDate':
                if (startDate) {
                    this.setState({
                        newStartDate: (reStructureDate(startDate)),
                        hasUnSavedData: true
                    });

                    this.calculateEffortForAddResource();
                } else {
                    this.setState({
                        newStartDate: null
                    });
                }

                break;
            default:
                break;
        }
    };

    handleOnChangeAllocatedDesignation = async event => {
        let item = event.target.name;
        item.AllocatedDesignationObj = event.target.value;
        await this.setState({
            filteredResourceData: this.state.filteredResourceData
        });
    }

    filterResourceDesignation = async event => {
        this.setState({
            designations: this.filterMultiData(event.filter, this.designationsAll),
            pmDesignations: this.filterMultiData(event.filter, this.state.pmDesignationsAll),
            designationsWithoutPms: this.filterMultiData(event.filter, this.state.designationsWithoutPmsAll)
        });
    }

    filterMultiData(filter, allData) {
        const data = allData.slice();
        return filterBy(data, filter);
    }

    filter = async event => {
        const name = event.target.name;
        switch (name) {
            //Line filteration
            case 'SelectedLineStatuses':
                this.setState({
                    LineStatus: this.filterMultiData(event.filter, this.state.LineStatusAll)
                });
                break;
            //Add new values
            case 'newResProjectOrCRName':
                await this.setState({
                    namesAll: this.filterData(event.filter, name)
                });
                break;
            case 'newResPhase':
                this.setState({
                    phasesTemp: this.filterData(event.filter, name)
                });
                break;
            case 'newResSubPhase':
                this.setState({
                    tmpSubPhasesAll: this.filterData(event.filter, name)
                });
                break;
            case 'newResRole':
                this.setState({
                    RolesAll: this.filterData(event.filter, name)
                });
                break;
            case 'newResDesignation':
                this.setState({
                    tmpDesignationsAll: this.filterData(event.filter, name)
                });
                break;
            case 'newResRegion':
                this.setState({
                    RegionsAll: this.filterData(event.filter, name)
                });
                break;
            case 'newOnSiteStatus':
                this.setState({
                    onSiteStatusAll: this.filterData(event.filter, name)
                });
                break;
            //Edit values
            case 'editRegion':
                this.setState({
                    RegionsAll: this.filterData(event.filter, name)
                });
                break;
            case 'editOnSiteStatus':
                this.setState({
                    onSiteStatusAll: this.filterData(event.filter, name)
                });
                break;
            case 'editAllocatedResource':
                this.setState({
                    usersAll: this.filterData(event.filter, name)
                });
                break;
            default:
                break;
        }
    };

    filterData(filter, name) {
        switch (name) {
            //Adds
            case 'newResProjectOrCRName':
                const nameData = this.allTheNames.slice();
                return filterBy(nameData, filter);
            case 'newResPhase':
                const phaseData = this.state.phasesTemp.slice();
                return filterBy(phaseData, filter);
            case 'newResSubPhase':
                const subphaseData = this.TmpSubPhasesAll.slice();
                return filterBy(subphaseData, filter);
            case 'newResRole':
                const roleData = this.designationsAll.slice();
                return filterBy(roleData, filter);
            case 'newResDesignation':
                const desgData = this.TmpDesignationsAll.slice();
                return filterBy(desgData, filter);
            case 'newResRegion':
                const regionData = this.regionsAll.slice();
                return filterBy(regionData, filter);
            case 'newOnSiteStatus':
                const onsiteData = this.onSiteStatusAll.slice();
                return filterBy(onsiteData, filter);

            //Edits
            case 'editRegion':
                const editRegionData = this.regionsAll.slice();
                return filterBy(editRegionData, filter);
            case 'editOnSiteStatus':
                const editOnsiteData = this.onSiteStatusAll.slice();
                return filterBy(editOnsiteData, filter);
            case 'editAllocatedResource':
                const editAllocationResData = this.usersForDesignation.slice();
                return filterBy(editAllocationResData, filter);
            default:
                break;
        }
    }

    editResource = async event => {
        if (this.props.location.action !== 'RPApprove') {
            if (this.state.userDefinedEndDate === null) {
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleDialog('Please insert an End date to proceed', 'Error');
            } else {
                if (new Date(this.state.project.UserDefinedEndDate).getTime() !== new Date(this.state.userDefinedEndDate).getTime()) {
                    //update project
                    this.state.project.UserDefinedEndDate = moment(this.state.userDefinedEndDate).format('YYYY-MM-DD');
                    updateProject(this.state.project, this.state.project.id)
                        .then(res => {
                            this.onUserDefinedEndDateChange();
                        })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });
                }
                this.editProcess();
            }
        } else {
            this.editProcess();
        }

        this.submitSearch();
    };

    editProcess = async () => {
        const resDesg = await getAllDesignationsIntegrated();
        let selecteddesignationId = this.state.editableItem.Designation;
        let filteredArray = [];
        for (const itm of this.usersAll) {
            if (itm.DesignationId) {
                /*for (const desig of resDesg.data) {
                        if (desig.Name.includes("Manager")) {*/
                filteredArray.push(itm);
                /*  }
                    }*/
            }
        }
        ;

        const filteredArr = Object.values(
            filteredArray.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
        );

        this.usersForDesignation = filteredArr;
        this.state.usersAll = filteredArr;

        if (this.state.editableItem) {

            const editableResourceRequest = this.state.editableItem;
            let subLineStartDateMin = null;
            let subLineEndDateMax = null;
            let customerId, regionId, projectOrCrDate, type = null;

            /*TODO
      convert to ProjectConversionStartDate(activation start date) from StartDate*/
            if (editableResourceRequest.Type === 'Project') {
                if (editableResourceRequest.Project && editableResourceRequest.Project.StartDate) {
                    subLineStartDateMin = editableResourceRequest.Project.StartDate;
                } else {
                    subLineStartDateMin = new Date();
                }

                if (editableResourceRequest.Project && editableResourceRequest.Project.EstimatedEndDate) {
                    subLineEndDateMax = editableResourceRequest.Project.EstimatedEndDate;
                } else {
                    subLineEndDateMax = editableResourceRequest.EstimatedEndDate;
                }

                customerId = editableResourceRequest.Project.CustomerId;
                regionId = editableResourceRequest.Project.BillingRegion;
                projectOrCrDate = editableResourceRequest.StartDate !== null ? editableResourceRequest.StartDate : editableResourceRequest.Project.StartDate;
                type = 'projectCMS';

            } else {
                /*TODO
        convert to ChangeRequestConversionStartDate - activation startdate of CR*/
                if (editableResourceRequest.ChangeRequest && editableResourceRequest.ChangeRequest.StartDate) {
                    subLineStartDateMin = editableResourceRequest.ChangeRequest.StartDate;
                } else {
                    subLineStartDateMin = new Date();
                }

                if (editableResourceRequest.Project && editableResourceRequest.Project.EstimatedEndDate) {
                    subLineEndDateMax = editableResourceRequest.Project.EstimatedEndDate;
                } else {
                    subLineEndDateMax = editableResourceRequest.EstimatedEndDate;
                }

                customerId = editableResourceRequest.ChangeRequest.CustomerId;
                regionId = editableResourceRequest.ChangeRequest.BillingRegion;
                projectOrCrDate = editableResourceRequest.StartDate !== null ? editableResourceRequest.ChangeRequest.StartDate : editableResourceRequest.ChangeRequest.StartDate;
                type = 'crCMS';
            }

            const rateCardParamsObj = {
                CustomerId: customerId,
                RegionId: regionId,
                CostDate: projectOrCrDate,
                Type: type
            };

            this.getAllocationRates(editableResourceRequest.ProjectRole, 'edit', rateCardParamsObj);

            this.setState({
                subLineStartDateMin: subLineStartDateMin,
                subLineEndDateMax: subLineEndDateMax,
                disableSelectedResourceStartEndDate: true
            });
        }
        if (this.isMount) {
            this.setState({
                selctedResourceSubLineStartDate: null,
                selctedResourceSubLineEndDate: null,
                hasSubLines: false,
                confirmStatus: false,
                confirmStatusSub: false,
                selectedResources: [],
                selectedResourcesSubLine: [],
                requestAllocation: false,
                requestAllocationSoftDate: false,
                requestAllocationSoftSubDate: false,
                requestAllocationSoftResubmit: false,
                requestAllocationSubSoftResubmit: false,
                requestAllocationSubSoft: false,
                requestAllocationSoft: false
            });
        }
        this.toggleEditDialog();
        this.ticked = [];
        this.allocationId = [];
        this.sublineTicked = [];
    };

    toggleEditDialog = () => {
        if (this.isMount) {
            this.clearSubLineOnAddValues();
            this.setState({
                showEditDialog: !this.state.showEditDialog,
                isEditResource: true
            });
        }
    };

    toggleDialog = async (message, title) => {
        await this.setState({
            popupVisible: !this.state.popupVisible,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    toggleConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    getCostCard = async dataItem => {
        let newItem = dataItem;
        let costDate_ = reStructureDate(dataItem.newStartDate);
        const costCard = {
            billingRegionId: newItem.newResProjectOrCRName.billingRegion,
            resourceRegionId: newItem.newResRegion.id,
            designationId: newItem.newResDesignation.id,
            costDate: moment(costDate_).format('YYYY-MM-DD'),
            projectId: this.props.location.project.id
        };
        this.setState({
            costDate: costDate_
        });
        costDate_ = moment(costDate_).format('YYYY-MM-DD');
        const costItems = {
            costCard : costCard,
            newItem : newItem,
            costDate: costDate_
        };

        await getCostDetails(costItems)
          .then(async res => {
            // if (!(newItem.newResDesignation && newItem.newResDesignation.id === 57)) {
                if (res.data) {        
                    if (res.data.TotalCost === 0) {
                        const message = 'Cost card not found for the selected designation and resource region, or the effort could be 0. Please check and try again or contact Finance to update the cost card.';
                        const title = 'Error';
                        await this.setState({
                            showSuccessImage: false,
                            showErrorImage: true
                        });
                        await this.toggleCostValidatioDialog(message, title);
                    }
                }
            // }
              newItem.CostPerResource = res.data.CostPerResource;
              newItem.DirectCost = res.data.DirectCost;
              newItem.TotalCost = res.data.TotalCost;
              newItem.EffortDays = res.data.EffortDays;
              newItem.leaveCount = res.data.leaveCount;
          })
          .catch( async err => {
              newItem.CostPerResource = 0;
              newItem.DirectCost = 0;
              newItem.TotalCost = 0;
              loggerService.writeLog(err, LOG_TYPES.ERROR);

              const message = 'Cost card not found for the selected designation and resource region, or the effort could be 0. Please check and try again or contact Finance to update the cost card.';
              const title = 'Error';
              await this.setState({
                  showSuccessImage: false,
                  showErrorImage: true
              });

              await this.toggleMessageDialog(message, title);
          });
    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    validateNumber = value => {
        if (value) {
            if (value > 0) {
                return 'd-none';
            }
            return 'inline-error';
        } else {
            return 'inline-error';
        }
    };

    validateEditingValues = () => {
        if (this.validateProperty(this.state.editableItem.dataValueResourceRegion).toString().includes('error')) {
            return false;
        } else if (this.validateProperty(this.state.editableItem.OnsiteStatus).toString().includes('error')) {
            return false;
        } else if (this.validateProperty(this.state.editableItem.EffortDays).toString().includes('error')) {
            return false;
        } else if (this.validateProperty(this.state.editableItem.dataValueCostDesignation).toString().includes('error')) {
            return false;
        } else {
            return true;
        }
    };

    validateSublineEditingValues = () => {
        if (this.validateProperty(this.state.selectedSubLineToEdit.dataValueResourceRegion).toString().includes('error')) {
            return false;
        } else {
            return true;
        }
    };

    selectionChange = async (event) => {

        event.dataItem.selected = !event.dataItem.selected;
        const index = this.state.resourceMix.indexOf(event.dataItem);
        if (event.dataItem.selected) {
            this.ticked.push(index);
            if (event.dataItem.AllocatedResourceID != null) {
                this.allocationId.push(event.dataItem.AllocatedResourceID);
            }
        } else {
            this.ticked.splice(this.ticked.indexOf(index), 1);
            if (event.dataItem.AllocatedResourceID != null) {
                this.allocationId.splice(this.allocationId.indexOf(index), 1);
            }
        }
        if (this.ticked.length === 1 && this.sublineTicked.length !== 1) {
            const selectedResource = JSON.parse(JSON.stringify(this.state.resourceMix[this.ticked[0]]));
            if (this.props.location.action !== 'RPApprove' && (selectedResource.Status === 'RQST_APP_PENDING' || selectedResource.Status === 'RES_ALLOC_PENDING' || selectedResource.Status === 'ALLOC_APP_PENDING' || selectedResource.Status === 'EDIT')) {
                this.state.notEditableResource = true;
            } else {
                this.state.notEditableResource = false;
            }
            let resourcePaymentMethod = null;
            if (selectedResource.Type === 'Project') {
                if (selectedResource.Project && selectedResource.Project.PaymentMethodRecord && selectedResource.Project.PaymentMethodRecord.id !== 3) {
                    resourcePaymentMethod = selectedResource.Project.PaymentMethodRecord;
                } else {
                    resourcePaymentMethod = selectedResource.dataValuePaymentMethod;
                }

            } else {
                if (selectedResource.ChangeRequest && selectedResource.ChangeRequest.PaymentMethod && selectedResource.ChangeRequest.PaymentMethod.id !== 3) {
                    resourcePaymentMethod = selectedResource.ChangeRequest.PaymentMethod;
                } else {
                    resourcePaymentMethod = selectedResource.dataValuePaymentMethod;
                }
            }

            var filteredArray = [];

            if (event.dataItem.IsPreSales && event.dataItem.IsPreSales === true) {
            } else {
                if (selectedResource.dataValueProjectRole) {
                    filteredArray = this.designationsAll.filter(function (itm) {
                        return selectedResource.dataValueProjectRole.CategoryId === itm.CategoryId || itm.Name === 'Outsourced';
                    });
                }
            }

            this.state.tmpDesignationsAll = filteredArray;

            selectedResource.ResourcePaymentMethod = resourcePaymentMethod;
            if (!selectedResource.SubLines) {
                selectedResource.SubLines = [];
            }
            selectedResource.index = index;
            selectedResource.Designations = filteredArray;
            this.state.editableItem = selectedResource;

        }
        //Resource Allocation
        if (this.ticked.length === 1 && this.props.location.action === 'RPApprove') {
            const isMainLinesSelected = await this.state.resourceMix.filter(resource => resource.selected === true).length;
            if(isMainLinesSelected !== 0){
                const selectedResource = JSON.parse(JSON.stringify(this.state.resourceMix[this.ticked[0]]));
                if (selectedResource.Status === 'RES_ALLOC_PENDING' || selectedResource.Status === 'EDIT' || selectedResource.Status === 'RES_ALLOC_REJECTED'){
                    this.state.notEditableResource = false;
                } else {
                    this.state.notEditableResource = true;
                }
            }
        }

        this.forceUpdate();

        const selectedl = await this.state.resourceMix.filter(resource => resource.selected === true);

        let hasSubLines = false;
        let requestAllocation = false;
        let requestAllocationSoft = false;
        let requestAllocationSoftResubmit = false;
        let requestAllocationSoftDate = false;
        let confirmStatus = false;
        let isCanSoftBooking = false;
        let isDisableAllocationLineAddBtn = true;
        let lineCostVariance = 0;
        let isMainLinesAllocated = true;
        let isEditBtnVisibilityValid = false;
        let hasCMSSubLines = false;
        let isNoCostVariance = false;

        if (selectedl && selectedl.length > 0) {

            //resourceAllocationCheck
            for (const resource of selectedl) {
                if ((resource.Status !== 'CONFIRMED' && resource.Status !== 'RQST_APP_REJECTED' &&
                    resource.Status !== 'RES_ALLOC_CANCELLED') && (resource.Status !== 'RES_ALLOC_PENDING' || !resource.IsSoftBookingResource)) {
                    requestAllocation = true
                }
            }

            //softbook check
            for (const resource of selectedl) {
                if (resource.Status !== 'CONFIRMED' || resource.IsSoftBookingResource) {
                    requestAllocationSoft = true
                }
            }

            //for soft booking date check
            for (const resource of selectedl) {
                if (resource.EndDate === null || resource.StartDate === null) {
                    requestAllocationSoftDate = true
                }
            }

            //resubmitting same softbook check
            for (const resource of selectedl) {
                if (resource.IsSoftBookingResource) {
                    requestAllocationSoftResubmit = true
                }
                if (!resource.IsPreSales && !resource.IsSoftBookingResource && resource.CostVariance == null) {
                    isNoCostVariance = true;
                }
            }

            //confirmStatus
            for (const resource of selectedl) {
                if (resource.Status !== 'EDIT' && resource.Status !== 'NEW_FROM_RM' &&
                    resource.Status !== 'NEW_FROM_CMS') {
                    confirmStatus = true;
                }
            }

            //check whether mainline has sublines
            for (const resource of selectedl) {
                if (resource.ResourceRequestSubLine && resource.ResourceRequestSubLine.length > 0) {
                    let sublines = resource.ResourceRequestSubLine.filter(res => res.IsActive === true);
                    if (sublines.length > 0) {
                        hasSubLines = true;
                        hasCMSSubLines = true;
                    }
                }
            }

            //for soft booking resource request
            for (const resource of selectedl) {
                if (resource.Status === 'CONFIRMED') {
                    isCanSoftBooking = true;
                } else {
                    isCanSoftBooking = false;
                }
            }

            //for edit popup add sublines add btn
            if (selectedl && selectedl.length === 1) {
                isDisableAllocationLineAddBtn = false;
            } else {
                isDisableAllocationLineAddBtn = true;
            }

            // Calculate cost variance
            for (const resource of selectedl) {
                if (resource.ResourceRequestSubLine && resource.ResourceRequestSubLine.length > 0) {
                    for (var subline of resource.ResourceRequestSubLine) {
                        lineCostVariance += Number(subline.CostVariance);
                    }
                }
            }

            //check whether selected mainlines are allocated - Button Submit for allocation
            let allocateAry = [];
            for (const resource of selectedl) {
                if (!resource.ResourceRequestSubLine) {
                    let isAllocated = (resource.AllocatedResourceID === null) ? true : false;
                    allocateAry.push(isAllocated);
                } else {
                    if (resource.ResourceRequestSubLine && resource.ResourceRequestSubLine.length === 0) {
                        let isAllocated = (resource.AllocatedResourceID === null) ? true : false;
                        allocateAry.push(isAllocated);
                    } else {
                        allocateAry.push(true);
                    }
                }
            }
            isMainLinesAllocated = (allocateAry.filter(i => i === false).length === allocateAry.length) ? true : false;

            // Check the main line validity - Button Edit
            let lineStatusAry = [];
            for (const resource of selectedl) {
                if (resource.IsPreSales) {
                    lineStatusAry.push(false);
                } else if (resource.IsSoftBookingResource) {
                    lineStatusAry.push(false);
                }
                else if (resource.ResourceRequestSubLine && resource.ResourceRequestSubLine.length > 0) {
                    for (const subline of resource.ResourceRequestSubLine) {
                        if (subline.IsSoftBookingResource) {
                            lineStatusAry.push(false);
                        }
                    }
                }
            }
            isEditBtnVisibilityValid = (lineStatusAry.filter(i => i == true).length == lineStatusAry.length) ? true : false;
        }

        let isInititalPM = false;
        const inititalPMRequests = await this.state.resourceMix.filter(resource => resource.selected === true && resource.IsInitialPMResource === true);
        if (inititalPMRequests && inititalPMRequests.length > 0) {
            isInititalPM = true
        }

        await this.setState({
            lineCostVariance: lineCostVariance,
            isCanSoftBooking: isCanSoftBooking,
            selectedResources: selectedl,
            hasSubLines: hasSubLines,
            hasCMSSubLines: hasCMSSubLines,
            requestAllocation: requestAllocation,
            requestAllocationSoft: requestAllocationSoft,
            requestAllocationSoftDate: requestAllocationSoftDate,
            requestAllocationSoftResubmit: requestAllocationSoftResubmit,
            confirmStatus: confirmStatus,
            isDisableAllocationLineAddBtn: isDisableAllocationLineAddBtn,
            isMainLinesAllocated: isMainLinesAllocated,
            isSelectedValidMainLines: isEditBtnVisibilityValid,
            isNoCostVariance: isNoCostVariance,
            isInititalPM: isInititalPM
        });

    };

    headerSelectionChange = async (event) => {
        const checked = event.syntheticEvent.target.checked;
        this.ticked = [];
        this.sublineTicked = [];
        this.allocationId = [];
        this.setState({
            hasSubLines: false
        })
        var resourceList = [];
        if (this.props.location.action !== 'RPApprove') {
            resourceList = this.state.filteredResourceMix;
        } else {
            resourceList = this.state.resourceMix;
        }
        if (checked) {
            let i;
            for (i = 0; i < resourceList.length; i++) {
                this.ticked.push(i);
                if (resourceList[i].AllocatedResourceID != null) {
                    this.allocationId.push(resourceList[i].AllocatedResourceID);
                }
            }
        }
        this.state.editableItem = null;
        this.state.resourceMix.forEach(item => item.selected = checked);
        this.forceUpdate();

        const selectedl = resourceList.filter(resource => resource.selected === true);
        if (selectedl.length === resourceList.length) {
            let isRemoveRequest = false;
            let hasSubLines = false;
            let requestAllocation = false;
            let requestAllocationSoft = false;
            let requestAllocationSoftResubmit = false;
            let requestAllocationSoftDate = false;
            let confirmStatus = false;
            let isCanSoftBooking = false;

            if (selectedl && selectedl.length > 0) {

                //resourceAllocationCheck
                for (const resource of selectedl) {
                    if ((resource.Status !== 'CONFIRMED' && resource.Status !== 'RQST_APP_REJECTED') && (resource.Status !== 'RES_ALLOC_PENDING' || !resource.IsSoftBookingResource)) {
                        requestAllocation = true
                    }
                }

                //softbook check
                for (const resource of selectedl) {
                    if (resource.Status !== 'CONFIRMED' || resource.IsSoftBookingResource) {
                        requestAllocationSoft = true
                    }
                }

                //for soft booking date check
                for (const resource of selectedl) {
                    if (resource.EndDate === null || resource.StartDate === null) {
                        requestAllocationSoftDate = true
                    }
                }

                //resubmitting same softbook check
                for (const resource of selectedl) {
                    if (resource.IsSoftBookingResource) {
                        requestAllocationSoftResubmit = true
                    }
                }

                //confirmStatus
                for (const resource of selectedl) {
                    if (resource.Status !== 'EDIT' && resource.Status !== 'NEW_FROM_RM' &&
                        resource.Status !== 'NEW_FROM_CMS' && resource.Status !== 'RES_ALLOC_CANCELLED') {
                        confirmStatus = true;
                    }
                }

                //check whether mainline has sublines
                for (const resource of selectedl) {
                    if (resource.ResourceRequestSubLine && resource.ResourceRequestSubLine.length > 0) {
                        let sublines = resource.ResourceRequestSubLine.filter(res => res.IsActive === true);
                        if (sublines.length > 0) {
                            hasSubLines = true;
                        }
                    }
                }

                //for soft booking resource request
                for (const resource of selectedl) {
                    if (resource.Status === 'CONFIRMED') {
                        isCanSoftBooking = true;
                    } else {
                        isCanSoftBooking = false;
                    }
                }
            }

            await this.setState({
                isCanSoftBooking: isCanSoftBooking,
                selectedResources: selectedl,
                hasSubLines: hasSubLines,
                requestAllocation: requestAllocation,
                requestAllocationSoft: requestAllocationSoft,
                requestAllocationSoftDate: requestAllocationSoftDate,
                requestAllocationSoftResubmit: requestAllocationSoftResubmit,
                confirmStatus: confirmStatus
            });

        }

    };

    getProjectOrCRName = (dataItem) => {
        if (dataItem.Type === 'Project') {
            return dataItem.Project.ProjectName;
        } else if (dataItem.Type === 'ChangeRequest') {
            return dataItem.ChangeRequest.CRName;
        } else {
            return '';
        }
    };

    handleRejectReasonInputChange = event => {
        const value = event.target.value;

        this.setState({
            rejectReason: value
        });
    };

    // Phase related changes
    handleOnChangeInput = event => {
        const field = event.target.name;
        const value = event.target.value;

        let minDate = null;
        if (value && value.Type === 'Project') {
            if (value.Project.StartDate) {
                minDate = reStructureDate(value.Project.StartDate);
            } else {
                minDate = new Date();
            }
        } else {

            if (value) {
                if (value.ChangeRequest.StartDate) {
                    minDate = reStructureDate(value.ChangeRequest.StartDate);
                } else {
                    minDate = new Date();
                }
            }
        }
        this.setState({
            minPhaseDate: minDate,
            selectedProjecOrCrForPhase: value
        });
    };

    handlePhaseNameInputChange = event => {
        const field = event.target.name;
        const value = event.target.value;

        this.setState({
            phaseName: value
        });
    };

    handlePhaseStartDateChange = event => {
        const field = event.target.name;
        const value = event.target.value;

        this.setState({
            phaseStartDate: value
        });
    };

    handleCancel = async () => {
        let canceledPhaseIndex = this.state.canceledPhaseIndex;

        if (this.state.cancelDataPhaseName) {
            const newPhase = this.state.phasesTemp.filter(obj =>
                obj.Text !== this.state.cancelDataPhaseName
            );

            await this.setState({
                phasesTemp: newPhase,
                projectPhaseVisible: !this.state.projectPhaseVisible,
                selectedProjecOrCrForPhase: null
            });
        } else if (this.state.cancelDataSubPhaseName) {
            //should be refactored - might not need this part
            const newSubPhase = this.state.phasesTemp[canceledPhaseIndex].SubPhases.filter(obj =>
                obj.Text !== this.state.cancelDataSubPhaseName
            );

            this.state.phasesTemp[canceledPhaseIndex].SubPhases = newSubPhase;

            await this.setState({
                projectSubPhaseVisible: !this.state.projectSubPhaseVisible,
                selectedProjecOrCrForPhase: null
            });
        } else {
            await this.setState({
                projectPhaseVisible: !this.state.projectPhaseVisible
            });
        }


    };

    handleCancelSubPhase = async () => {
        let canceledPhaseIndex = this.state.canceledPhaseIndex;

        if (this.state.cancelDataPhaseName) {
            //should be refactored - might not need this part
            const newPhase = this.state.phasesTemp.filter(obj =>
                obj.Text !== this.state.cancelDataPhaseName
            );

            await this.setState({
                phasesTemp: newPhase,
                projectPhaseVisible: !this.state.projectPhaseVisible,
                selectedProjecOrCrForPhase: null
            });
        } else if (this.state.cancelDataSubPhaseName) {
            const newSubPhase = this.state.phasesTemp[canceledPhaseIndex].SubPhases.filter(obj =>
                obj.Text !== this.state.cancelDataSubPhaseName
            );

            this.state.phasesTemp[canceledPhaseIndex].SubPhases = newSubPhase;

            await this.setState({
                projectSubPhaseVisible: !this.state.projectSubPhaseVisible,
                selectedProjecOrCrForPhase: null
            });
        } else {
            await this.setState({
                projectSubPhaseVisible: !this.state.projectSubPhaseVisible
            });
        }


    };

    // sub phase related changes
    handleProjectCRChangeForSubPhase = event => {
        const field = event.target.name;
        const value = event.target.value;

        let selectedProjectOrCRPhases = null;
        let minDate = null;
        if (value && value.Type === 'Project') {
            if (value.Project.StartDate) {
                minDate = reStructureDate(value.Project.StartDate);
            } else {
                minDate = new Date();
            }

            if (value.Project && value.Project.CMSheets && value.Project.CMSheets.length > 0) {
                if (value.Project.CMSheets[0].Phases && value.Project.CMSheets[0].Phases.length > 0) {
                    selectedProjectOrCRPhases = value.Project.CMSheets[0].Phases;
                }
            }

        } else {
            if (value.ChangeRequest.StartDate) {
                minDate = reStructureDate(value.ChangeRequest.StartDate);
            } else {
                minDate = new Date();
            }

            if (value.ChangeRequest && value.ChangeRequest.CMSheets && value.ChangeRequest.CMSheets.length > 0) {
                if (value.ChangeRequest.CMSheets[0].Phases && value.ChangeRequest.CMSheets[0].Phases.length > 0) {
                    selectedProjectOrCRPhases = value.ChangeRequest.CMSheets[0].Phases;
                }
            }

        }

        this.setState({
            minSubPhaseDate: minDate,
            selectedProjecOrCrForPhase: value,
            selectedProjectOrCrPhases: selectedProjectOrCRPhases
        });

    };

    handleSubPhaseNameInputChange = event => {
        const field = event.target.name;
        const value = event.target.value;

        this.setState({
            subPhaseName: value
        });
    };

    handlePhaseChange = event => {
        const value = event.target.value;
        this.setState(
            prevState => {
                return {
                    selectedPhaseIndex: prevState.selectedProjectOrCrPhases.indexOf(value)
                };
            },
            () => {
                //  this.setStartDateMin();
            }
        );
    };

    handleSubPhaseStartDateChange = event => {
        const field = event.target.name;
        const value = event.target.value;

        this.setState({
            subPhaseStartDate: value
        });
    };

    addSubPhase = async () => {
        this.setState({
            isAddDone: true
        });

        if (!this.state.isEditButton) {
            let startDateStr = null;
            let selectedProjectOrCr = null;
            if (this.state.subPhaseStartDate) {
                startDateStr = getFormattedDate(this.state.subPhaseStartDate);
            }

            if (this.state.selectedProjecOrCrForPhase) {
                selectedProjectOrCr = this.state.selectedProjecOrCrForPhase;
            }

            if (this.state.selectedPhaseIndex) {

            }

            this.handleSubPhasesAdd(this.state.selectedPhaseIndex, this.state.subPhaseName, startDateStr, this.state.selectedProjectOrCrPhases, selectedProjectOrCr);

            this.state.canceledPhaseIndex = this.state.selectedPhaseIndex;
            this.state.cancelDataSubPhaseName = this.state.subPhaseName;
            this.state.cancelDataProjectId = this.state.selectedProjecOrCrForPhase;


            this.setState({
                subPhaseName: '',
                startDate: '',
                selectedPhaseIndex: -1
            });
        } else {
            this.handleSubPhasesEdit(this.state.phaseSubPhaseId, this.state.phaseName, this.state.subPhaseName, this.state.subPhaseStartDate, this.state.projectAndCrData);

            await this.setState({
                isSaveButtonVisibleEdit: true,
                subPhaseName: '',
                startDate: '',
                selectedPhaseIndex: -1,
                isEditButton: false
            });
        }
    };

    handleSubPhasesAdd = async (phaseIndex, subPhaseName, startDate, selectedProjectOrCrPhases, projectOrCr) => {
        const cmsheetId = projectOrCr.Type === 'Project' ? projectOrCr.Project.CMSheets[0].id : projectOrCr.ChangeRequest.CMSheets[0].id;
        const phasesTemp = selectedProjectOrCrPhases;

        if (subPhaseName && phasesTemp[phaseIndex]) {
            subPhaseName = subPhaseName.trim();
            const exists = phasesTemp[phaseIndex].SubPhases.filter(obj => {
                let exist = obj.Text;
                if (exist) {
                    const existLC = exist.toLowerCase();
                    const subPhaseNameLC = subPhaseName.toLowerCase();

                    if (subPhaseNameLC === existLC) {
                        return true;
                    }
                    return false;
                }
                return false;
            });
            if (exists.length > 0) {
                const message = 'Sub Phase name already exists. Please use a unique name';
                const title = 'Name Already Exists';
                this.toggleMessageDialog(message, title);
            } else {
                phasesTemp[phaseIndex].SubPhases.push({
                    Text: subPhaseName,
                    StartDate: startDate
                });

                this.setState({
                    selectedProjectOrCrPhases: phasesTemp,
                    selectedCMSId: cmsheetId
                });

            }
        }

    };

    removeSubPhase = async () => {
        const phasesTemp = this.state.selectedProjectOrCrPhases;
        phasesTemp[this.state.subPhaseRemovePhaseIndex].SubPhases.splice(this.state.subPhaseRemoveSubPhaseIndex, 1);

        this.setState(
            {
                selectedProjectOrCrPhases: phasesTemp
            },
            () => {
                this.toggleConfirmDialog('', null);
            }
        );

    };

    handleSubPhaseSave = () => {
        if (this.state.selectedProjectOrCrPhases) {
            const phasesTemp = this.state.selectedProjectOrCrPhases;
            //call save phases API
            savePhasesOfResourceRequests(this.state.selectedCMSId, phasesTemp)
                .then(res => {

                    this.toggleProjectSubPhase();
                    const message = 'Successfully saved.';
                    const title = 'Success';
                    this.toggleMessageDialog(message, title);
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        }
    };

    addEditingValueToGrid = async event => {
        if (!this.validateEditingValues()) {
            this.setState({
                showSuccessImage: false,
                showErrorImage: true
            });
            this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
        } else {

            let isRevenuePresentNotChecked = true;
            var selectedResource = this.state.editableItem;
            if (selectedResource.ResourceRequestSubLine && selectedResource.ResourceRequestSubLine.length > 0) {
                for (var item of selectedResource.ResourceRequestSubLine) {
                    if (item.IsRevenuePresent) {
                        isRevenuePresentNotChecked = false;
                    }
                }
            }

            if (isRevenuePresentNotChecked) {
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleDialog('Revenue of the original line should be assigned to at least one sub line. Please check the "Revenue Present" option for at least one option where the resources are to be allocated.', 'Error');
                return;
            }

            if (this.props.location.action == 'RPApprove') {
                saveFrontEndLogs("Warn","main line's status change RA - Before",`ResourceRequestId : ${this.state.resourceMix[this.state.editableItem.index].id}, Status : ${this.state.resourceMix[this.state.editableItem.index].Status}`);
                this.state.resourceMix[this.state.editableItem.index].Status = 3;
                saveFrontEndLogs("Warn","main line's status change RA - After",`ResourceRequestId : ${this.state.resourceMix[this.state.editableItem.index].id}, Status : ${this.state.resourceMix[this.state.editableItem.index].Status}`);
                this.state.resourceMix[this.state.editableItem.index].selected = false;
                this.state.resourceMix[this.state.editableItem.index].IsActive = true;

                // Revert the resource data
                this.state.resourceMix[this.state.editableItem.index].AllocatedResourceID = null;
                this.state.resourceMix[this.state.editableItem.index].ResourceId = null;
                this.state.resourceMix[this.state.editableItem.index].softbookingPreviousResource = null;
                this.state.resourceMix[this.state.editableItem.index].CostPerResource = this.state.resourceMix[this.state.editableItem.index].InitialCostPerResource;
                this.state.resourceMix[this.state.editableItem.index].DirectCost = this.state.resourceMix[this.state.editableItem.index].InitialDirectCost;
                this.state.resourceMix[this.state.editableItem.index].TotalCost = this.state.resourceMix[this.state.editableItem.index].InitialTotalCost;
                this.state.resourceMix[this.state.editableItem.index].CostVariance = null;
                this.state.resourceMix[this.state.editableItem.index].InitialCostPerResource = null;
                this.state.resourceMix[this.state.editableItem.index].InitialDirectCost = null;
                this.state.resourceMix[this.state.editableItem.index].InitialTotalCost = null;

                this.state.resourceMix[this.state.editableItem.index].ResourceRequestSubLine = this.state.editableItem.ResourceRequestSubLine;
            } else {
                this.state.editableItem.selected = false;
                this.state.editableItem.IsActive = true;
                this.state.resourceMix[this.state.editableItem.index] = this.state.editableItem;
            }

            this.state.resourceMix[this.state.editableItem.index].LastUpdatedByUserAt = new Date();
            var resourceEdited = [this.state.resourceMix[this.state.editableItem.index]];

            // Split the revenue
            if (resourceEdited[0].ResourceRequestSubLine && resourceEdited[0].ResourceRequestSubLine.length > 0) {

                let isRevenueSplit = [];
                let revenuePresentTotEffortDays = 0.00;
                let totRevenue = resourceEdited[0].TotalRevenue;

                for (var item of resourceEdited[0].ResourceRequestSubLine) {
                    
                    if (item.Status == 'EDIT') {
                        item.Status = 3;
                    } else if (item.Status == 'RES_ALLOC_PENDING') {
                        item.Status = 7;
                    } else if (item.Status == 'RES_ALLOC_REJECTED') {
                        item.Status = 12;
                    }

                    if (item.IsRevenuePresent) {
                        isRevenueSplit.push(item.index);
                        revenuePresentTotEffortDays += Number(item.EffortDays);
                    } else {
                        item.Revenue = 0.00;
                        item.CostToRevenueRatio = 0.00;
                    }
                }

                for (var index of isRevenueSplit) {
                    var subLine = resourceEdited[0].ResourceRequestSubLine[index];
                    subLine.Revenue = ((totRevenue / revenuePresentTotEffortDays) * Number(subLine.EffortDays)).toFixed(2);
                    subLine.CostToRevenueRatio = (subLine.Revenue !== '0.00') ? (Number(subLine.Cost) / Number(subLine.Revenue)).toFixed(2) : 0.00;
                    resourceEdited[0].ResourceRequestSubLine[index] = subLine;
                }
            }

            this.state.showEditDialog = false;
            this.state.editableItem = null;

            this.forceUpdate();
            this.handleResourceSave(resourceEdited);
        }
    };

    addMainLineAsSubLine = (mainLine) => {
        var mainLineStatus = mainLine.Status;
        if (mainLineStatus !== 'NEW_FROM_CMS' && mainLineStatus !== 'NEW_FROM_RM' && mainLineStatus !== 'EDIT') {
            const index = this.state.editableItem.ResourceRequestSubLine ? this.state.editableItem.ResourceRequestSubLine.length : 0;
            const subLineObj = {
                StartDate: mainLine.StartDate,
                EndDate: mainLine.EndDate,
                EffortDays: mainLine.EffortDays,
                AllocationPercentage: mainLine.AllocationPercentage,
                RateType: mainLine.RateType,
                Rate: mainLine.RateValue,
                Status: mainLineStatus,
                index: index,
                RateValue: mainLine.RateValue,
                CostPerResource: mainLine.CostPerResource,
                DirectCost: mainLine.DirectCost,
                Cost: mainLine.TotalCost,
                Revenue: 0,
                CostToRevenueRatio: 0.00,
                selected: false,
                ResourceRequestId: mainLine.id ? mainLine.id : null,
                dataValueResourceRegion: mainLine.dataValueResourceRegion,
                Phase: mainLine.Phase,
                SubPhase: mainLine.SubPhase,
                ProjectRole: mainLine.ProjectRole,
                Designation: mainLine.Designation,
                ResourceRegion: mainLine.ResourceRegion,
                OnsiteStatus: mainLine.OnsiteStatus,
                PhaseId: mainLine.PhaseId,
                SubPhaseId: mainLine.SubPhaseId,
                PaymentMethod: mainLine.PaymentMethod,
                dataValueCostDesignation: mainLine.dataValueCostDesignation,
                dataValueProjectRole: mainLine.dataValueProjectRole,
                EffortOverrun: false,
                LastUpdatedByUserAt: new Date(),
                IsAllocatedLine: false,
                IsActive: true,
                IsRevenuePresent: false,
                ResourceId: mainLine.AllocatedResourceID,
                CostVariance: mainLine.CostVariance,
                InitialCostPerResource: mainLine.InitialCostPerResource,
                InitialDirectCost: mainLine.InitialDirectCost,
                InitialCost: mainLine.InitialTotalCost
            };
            if (this.state.editableItem.ResourceRequestSubLine) {
                this.state.editableItem.ResourceRequestSubLine[index] = subLineObj;
            } else {
                this.state.editableItem.ResourceRequestSubLine = [];
                this.state.editableItem.ResourceRequestSubLine[index] = subLineObj;
            }
        }
    }

    submitApprovalToggle = async () => {

        await this.setState({
            approveResourcesDialog: !this.state.approveResourcesDialog
        });

    };

    toggleClosePhaseDialog = () => {
        if (this.isMount) {
            this.setState({
                projectPhaseVisible: !this.state.projectPhaseVisible
            });
        }
    };

    toggleCloseSubPhaseDialog = () => {
        if (this.isMount) {
            this.setState({
                projectSubPhaseVisible: !this.state.projectSubPhaseVisible
            });
        }
    };

    submitApprovalForResources = async event => {

        await this.setState({
            approveResourcesDialogDisabled: true
        });

        this.state.approveResourcesDialog = false;
        let objsToSend = [];
        let mainlines = [];
        let mainlinesWf = [];
        let sublines = [];
        let sublinesWf = [];
        let goThroughwf = false;
        let linesFromBoth = false;

        for (const resource of this.state.resourceMix) {

            // Update main lines
            if (resource.selected) {
                if(resource.IsPreSales){
                    resource.Status = 11; // Set to complete, no workflow
                    resource.Comment = this.state.managerComment;
                    resource.complete = true;
                } else{
                    if(resource.TotalCost - resource.InitialTotalCost > 500){
                        resource.Status = 10; // Allocation approval pending
                        resource.Comment = this.state.managerComment;
                        goThroughwf = true;
                    }else{
                        resource.Status = 11; // Set to complete
                        resource.Comment = this.state.managerComment;
                        resource.complete = true;
                    }
                }
            } else {
                if (resource.Status == 'EDIT') {
                    resource.Status = 3;
                    let sublineEvaulate = false;
                    let requestedCost = 0.00;
                    let allocatedCost = 0.00;
                    let sublinesFromRR = false;
                    let sublinesFromRA = false;
                    //which sublines are selected
                    if (resource.ResourceRequestSubLine && resource.ResourceRequestSubLine.length > 0) {
                        for (const subline of resource.ResourceRequestSubLine) {
                            if((subline.Status == 'RES_ALLOC_PENDING' || subline.Status == 'RES_ALLOC_REJECTED') && subline.IsAllocatedLine !== true){
                                sublinesFromRR = true;
                                if(subline.selected){
                                    if(subline.Cost - subline.InitialCost > 500){ //allocated cost is higher than requested
                                        subline.Status = 10; // Allocation approval pending
                                        goThroughwf = true;
                                        resource.selected = true; // set the main line as selected
                                    }else { //allocated cost is less or equal to requested
                                        subline.Status = 11; // Set to complete
                                        resource.selected = true; // set the main line as selected
                                        resource.complete = true;
                                    }
                                }
                            }
                            if(subline.selected && subline.IsAllocatedLine){
                                sublinesFromRA = true;
                            }
                            if (subline.selected) {
                                sublineEvaulate = true;
                            }
                        }
                        if(sublinesFromRA && sublinesFromRR){
                            linesFromBoth = true;
                        }
                    }
                        if(sublineEvaulate == true){
                            for (const subline of resource.ResourceRequestSubLinesAll) {
                                if((subline.Status == 'RES_ALLOC_PENDING' || subline.Status == 'RES_ALLOC_CANCELLED'|| subline.Status == 'COMPLETE'|| subline.Status == 'RES_ALLOC_REJECTED'|| subline.Status == 'ALLOC_APP_PENDING') && subline.IsAllocatedLine !== true){
                                    //resources added by RM
                                    if(subline.InitialCost){
                                        requestedCost += Number(subline.InitialCost);
                                    }else{
                                        requestedCost += Number(subline.Cost); //cancelled without allocation
                                    }

                                }
                                if((subline.Status == 'RES_ALLOC_PENDING' || subline.Status == 'COMPLETE'|| subline.Status == 'ALLOC_APP_PENDING' || subline.Status == 'RES_ALLOC_REJECTED') && subline.IsActive){
                                    //resource allocation pending, and allocation rejected lines added by RP or RM
                                    if(subline.ResourceId){ //To see whether line is allocated
                                        allocatedCost += Number(subline.Cost);
                                    }
                                }
                            }
                        }
                        if(allocatedCost - requestedCost > 500){  //sublines go through the wf - logic only valid for lines added in RA
                            for (const subline of resource.ResourceRequestSubLine) {
                                if(subline.selected && subline.IsAllocatedLine){
                                    subline.Status = 10; // Allocation approval pending
                                    goThroughwf = true;
                                    resource.selected = true; // set the main line as selected
                                }
                            }
                        }else{
                            for (const subline of resource.ResourceRequestSubLine) {
                                if (subline.selected && subline.IsAllocatedLine) {
                                    subline.Status = 11; // Set to complete
                                    resource.selected = true; // set the main line as selected
                                    resource.complete = true;
                                }
                            }
                        }
                } else if (resource.Status == 'RES_ALLOC_PENDING') {
                    resource.Status = 7;
                }
            }
        }

        //Sublines from RR should be sumbitted/cancelled before submitting lines added from RA
        if(linesFromBoth){
            const message =
              'Submit/Cancel sublines added by PM before submitting newly added sublines';
            const title = 'Error';
            this.setState({
                showSuccessImage: false,
                showErrorImage: true
            });
            this.toggleMessageDialog(message, title);
            this.submitSearch();
        } else{
            const selectedResources = this.state.resourceMix.filter(resource => resource.selected === true);
            let selectedResourcesCompleted = [];
            let selectedResourcesAllocated = [];

            if (selectedResources && selectedResources.length > 0) {

                for (const resource of selectedResources) {

                    if (resource.Status == 11) {
                        resource.ToStatus = 11;
                        mainlines.push(resource);
                        selectedResourcesCompleted.push(resource);
                    }else if(resource.Status == 10) {
                        resource.ToStatus = 10;
                        mainlinesWf.push(resource);
                        selectedResourcesAllocated.push(resource);
                    }

                    if (resource.ResourceRequestSubLine && resource.ResourceRequestSubLine.length > 0) {
                        let sublinesCompleted = [];
                        let sublinesAllocated = [];
                        for (const subline of resource.ResourceRequestSubLine) {
                            if (subline.Status == 11) {
                                subline.ToStatus = 11;
                                sublines.push(subline);
                                sublinesCompleted.push(subline);
                            }else if (subline.Status == 10) {
                                subline.ToStatus = 10;
                                sublinesWf.push(subline);
                                sublinesAllocated.push(subline);
                            }
                        }
                        if(sublinesCompleted.length > 0){
                            resource.ResourceRequestSubLine = sublinesCompleted;
                            selectedResourcesCompleted.push(resource);
                        }else if(sublinesAllocated.length > 0){
                            resource.ResourceRequestSubLine = sublinesAllocated;
                            selectedResourcesAllocated.push(resource);
                        }
                    }
                }

                let allocatedLines = [];
                let deallocatedLines = [];

                // Set the Allocation/Deallocation mail logics
                for (const resource of selectedResources) {

                    // Track the allocated lines
                    if (resource.Status !== 3) { // do not consider EDIT status lines
                        if(resource.IsSoftBookingResource) {
                            resource.softbookingPreviousResource = resource.AllocatedResourceID; // to track the previous resource
    
                            // Allocated line details
                            let tmpAry = {
                                LineId: resource.id,
                                ResourceId: resource.AllocatedResourceID,
                                Type: 'MainLine'
                            };
                            allocatedLines.push(tmpAry);
    
                        } else if (resource.softbookingPreviousResource !== null) { // Track the deallocated/reallocated lines
    
                            if (resource.softbookingPreviousResource !== resource.AllocatedResourceID) {
    
                                // Allocated line details
                                let tmpAry = {
                                    LineId: resource.id,
                                    ResourceId: resource.AllocatedResourceID,
                                    Type: 'MainLine'
                                };
                                allocatedLines.push(tmpAry);
    
                                // Deallocated line details
                                let tmpDeallocateAry = {
                                    LineId: resource.id,
                                    ResourceId: resource.softbookingPreviousResource,
                                    Type: 'MainLine'
                                };
                                deallocatedLines.push(tmpDeallocateAry);
                            }
                        }
                    }

                    if (resource.ResourceRequestSubLine && resource.ResourceRequestSubLine.length > 0) {
                        for (const subline of resource.ResourceRequestSubLine) {

                            // Track the allocated lines
                            if (subline.IsSoftBookingResource) {
                                subline.softbookingPreviousResource = subline.ResourceId; // to track the previous resource

                                // Allocated line details
                                let tmpAry = {
                                    LineId: subline.id,
                                    ResourceId: subline.ResourceId,
                                    Type: 'SubLine'
                                };
                                allocatedLines.push(tmpAry);

                            } else if (subline.softbookingPreviousResource !== null) { // Track the deallocated/reallocated lines

                                if (subline.softbookingPreviousResource !== subline.ResourceId) {

                                    // Allocated line details
                                    let tmpAry = {
                                        LineId: subline.id,
                                        ResourceId: subline.ResourceId,
                                        Type: 'SubLine'
                                    };
                                    allocatedLines.push(tmpAry);

                                    // Deallocated line details
                                    let tmpDeallocateAry = {
                                        LineId: subline.id,
                                        ResourceId: subline.softbookingPreviousResource,
                                        Type: 'SubLine'
                                    };
                                    deallocatedLines.push(tmpDeallocateAry);
                                }
                            }
                        }
                    }
                }
                loggerService.writeLog('PM Allocation: ' + JSON.stringify(selectedResourcesCompleted), LOG_TYPES.INFO);
                // Save changes
                await this.handleResourceSave(selectedResourcesCompleted);
                loggerService.writeLog('PM Allocation: Saved Resources', LOG_TYPES.INFO);
                for (const each of selectedResourcesCompleted) {

                    each.StartDateType = 1;
                    each.LastUpdatedByUserAt = new Date();
                    each.Comment = this.state.managerComment;
                    delete objsToSend.name;
                    delete objsToSend.index;
                    delete objsToSend.selected;
                    delete objsToSend.AllocatedResource;
                    delete objsToSend.ChangeRequest;
                    delete objsToSend.Project;
                    delete objsToSend.SubPhase;
                    delete objsToSend.Phase;
                    delete objsToSend.dataValueRateType;
                    delete objsToSend.dataValueResourceRegion;
                    delete objsToSend.dataValueProjectRole;
                    delete objsToSend.dataValueCostDesignation;

                    each.ResourceId = each.AllocatedResource ? each.AllocatedResource.id : null;
                    each.ResourceRequestId = each.id;
                    if(each.complete == true) {
                        objsToSend.push(each);
                    }
                }
                if(objsToSend.length > 0) {
                    loggerService.writeLog('PM Allocation: Set status to complete', LOG_TYPES.INFO);
                    await allocateResources(objsToSend) //set status to complete
                      .then(async res => {
                          loggerService.writeLog('PM Allocation: Allocated Resources', LOG_TYPES.INFO);
                          let allocationAry = [];
                          allocationAry.push(allocatedLines);
                          allocationAry.push(deallocatedLines);
                          await sendAllocationDeallocationMails(allocationAry);

                          // Save line history
                          this.updateLineHistory(mainlines, sublines);
                          this.setState({
                              managerComment: null
                          });
                          if(goThroughwf == false){
                              this.setState({
                                  showSuccessImage: true,
                                  showErrorImage: false,
                                  isSubmitted: false
                              });
                              this.toggleDialog(
                                'Resource allocation for selected line(s) successfully submitted',
                                'Success'
                              );
                               
                              //do the presales task saving here

                              const userDetails = window.LOGGED_USER;
                              let result = [];
                              await getAllTaskTypes().then(async resp => {                        
                                result = resp.data.filter(obj => obj.Name === 'Presales' && obj.BillabilityTypeId === 'NONBILLABLE');
                            })

                            let projectData = objsToSend.filter(obj => obj.Type === 'Project' && obj.Project.Status === 1);
                            let crData = objsToSend.filter(obj => obj.Type === 'ChangeRequest' && obj.ChangeRequest.StatusId === 1);
                            let taskTypeId = result && result.length > 0 ? result[0].id : null;
                            let modifiedUser = userDetails && userDetails.displayName ? userDetails.displayName: null;
                            if((projectData && projectData.length > 0) || (crData && crData.length > 0)){
                            addPreSaleTask({
                                ProjectId: projectData && projectData.length>0 ? projectData[0].Project.id: crData[0].ChangeRequest.ProjectId,
                                ProjectData: projectData,
                                CRData: crData,
                                TaskTypeId: taskTypeId,
                                ModifiedUser: modifiedUser
                            }).then(res => {
                                if(res){
                                    console.log(res);
                                }
                            }) 
                            }
                        this.getInitialData();  
                        }
                      })
                      .catch(error => {
                          loggerService.writeLog(error, LOG_TYPES.ERROR);
                          if(error&&error.response){
                          this.toggleDialog(error.response.data, 'Error');
                          }
                          this.submitSearch();
                      });

                }
            }

            //send theough wf
            this.submitForApprovalWf(goThroughwf,mainlinesWf,sublinesWf);
        }
        await this.setState({
            approveResourcesDialogDisabled: false
        });
    };

    submitForApprovalWf = (goThroughwf, mainlinesWf, sublinesWf) => {
        if (goThroughwf) {
            var wfTypesArray = [];
            var wfObject = null;
            if(mainlinesWf.length > 0){
                for(const main of mainlinesWf){
                    var costVariation = null;
                    costVariation = (Number(main.CostVariance)/Number(main.InitialTotalCost)) * 100;
                    wfObject = {costVariation: costVariation , allocatedRegion: main.AllocatedResource.Resource.Region , requestedRegion: main.ResourceRegion};
                    wfTypesArray.push(wfObject);
                }
            }
            if(sublinesWf.length > 0){
                for(const sub of sublinesWf){
                    if(sub.AllocatedResource.Resource){
                        var costVariation = null;
                        costVariation = (Number(sub.CostVariance)/Number(sub.InitialCost)) * 100;

                        wfObject = {costVariation: costVariation , allocatedRegion: sub.AllocatedResource.Resource.Region , requestedRegion: sub.ResourceRegion};
                    } else{
                        for(const mainLine of this.state.allocationSummaryResources){
                            if(mainLine.id == sub.ResourceRequestId){
                                for(const subline of mainLine.ResourceRequestSubLine){
                                    if (subline.id == sub.id){
                                        var costVariation = null;
                                        costVariation = (Number(sub.CostVariance)/Number(sub.InitialCost)) * 100;
                                        wfObject = {costVariation: costVariation , allocatedRegion: subline.AllocatedResource.Resource.Region , requestedRegion: sub.ResourceRegion};
                                    }
                                }
                            }
                        }
                    }
                    wfTypesArray.push(wfObject);
                }
            }
            var array = wfTypesArray,
                seen = Object.create(null),
                result = array.filter(o => {
                    var key = ['costVariation', 'allocatedRegion', 'requestedRegion'].map(k => o[k]).join('|');
                    if (!seen[key]) {
                        seen[key] = true;
                        return true;
                    }
                });

            if (result.length > 1) {
                const message =
                  'Please select lines of the same cost variation range, requested resource region and allocated resource region for Resource Allocation Submission';
                const title = 'Error';
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                    selectedResources: [],
                    selectedResourcesSubLine: [],
                });
                this.toggleMessageDialog(message, title);
                this.submitSearch();
            } else {
                selectResourceAllocationWorkflow(result[0]).then(res => {
                    if (res.data.length > 1) {
                        this.setState(
                            {
                                matchingWorkflowList: res.data
                            },
                            () => {
                                this.toggleWorkflowSelectDialogRR();
                            }
                        );
                    } else if (res.data.length === 1) {
                        this.setState(
                            {
                                selectedWorkFlowId: res.data[0].id
                            },
                            () => {
                                this.setWorkflowToResources();
                            }
                        );
                    } else {
                        const message =
                          'This Project has failed to find an appropriate workflow for approvals. Please contact your system administrator to correct this issue';
                        const title = 'Error';
                        this.setState({
                            showSuccessImage: false,
                            showErrorImage: true
                        });
                        this.toggleMessageDialog(message, title);
                        this.submitSearch();
                    }
                });
            }
        }
    };

    sendForAllocation = () => {

        var currentRequestsFinal = [];
        var currentSubLinesFinal = [];
        if (this.state.selectedResources && this.state.selectedResources.length > 0) {
            currentRequestsFinal = this.state.selectedResources.filter(eachLine => eachLine.Status === 7);
        }
        if (this.state.selectedResourcesSubLine && this.state.selectedResourcesSubLine.length > 0) {
            currentSubLinesFinal = this.state.selectedResourcesSubLine.filter(eachLine => eachLine.Status === 7);
        }

        if (currentRequestsFinal.length > 0 || currentSubLinesFinal.length > 0) {
            const allocationObj = {
                mainLines: currentRequestsFinal,
                subLines: currentSubLinesFinal,
                uniqueId: this.state.project.UniqueId,
                projectName: this.state.project.ProjectName
            };
            submitForResourceAllocation(allocationObj)
                .then(response => {
                    this.updateLineHistory(currentRequestsFinal, currentSubLinesFinal);
                    let message = 'Selected resources were sent for resource allocation.';
                    const title = 'Success';
                    this.setState({
                        showSuccessImage: true,
                        showErrorImage: false,
                    });
                    this.toggleDialog(message, title);
                    this.getInitialData();

                })
                .catch(error => {
                    this.setState({
                        showSuccessImage: false,
                        showErrorImage: true
                    });
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                    this.toggleDialog(error.response.data, 'Error');
                });
        }
    }

    updateLineHistory = (mainLines, subLines) => {
        var pmcomment = this.state.managerComment ? this.state.managerComment.replace(/(<([^>]+)>)/ig, '') : null;
        saveResourceLineHistory({ mainLines: mainLines, subLines: subLines, comment: pmcomment, type: 'ResourceAllocation' })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

    };

  getLineHistory = async (resourceRequestId, resourceRequestIdSub) => {
    this.setState({
      lineStatusHistoryList: []
    });
    const resourceDetails = {
      resourceRequestId: resourceRequestId,
      resourceRequestIdSub: resourceRequestIdSub
    };

    var approvals = [];
    await getAllApproversForResource({ mainLineId: resourceRequestId, subLineId: resourceRequestIdSub, projId: this.state.project.id })
      .then(res => {
        approvals = res.data;
      }).catch(err => {
        loggerService.writeLog(err, LOG_TYPES.ERROR);
      });

    getLineHistory(resourceDetails)
      .then(res => {
        for (const each of res.data) {
          each.statusChangedAt = each.statusChangedAt ? each.statusChangedAt : null;
          each.GroupCardName = each.Approval ? each.Approval.CardName : null;
          if(!each.Approval && (each.ToStatus == 'COMPLETE' || each.ToStatus == 'RES_ALLOC_CANCELLED')) {
            each.GroupCardName = 'RP'
          } else if (!each.Approval && each.id === res.data[0].id) {
            each.GroupCardName = 'PM'
          }
        }
        //append pending approvers to the end
        if (res.data[res.data.length - 1].ToStatus == 'RQST_APP_PENDING' || res.data[res.data.length - 1].ToStatus == 'ALLOC_APP_PENDING') { //request approval pending/allocation approval pending - append all approvers
          let lastStatus = res.data[res.data.length - 1].ToStatus;
          for (const approval of approvals) {
            if (approval.Status == "PENDING" && lastStatus == 'RQST_APP_PENDING') {
              var history = {
                ToStatus: 'RQST_APP_PENDING',
                updatedUser: approval.Resource.Name,
                GroupCardName: approval.CardName
              };
              res.data.push(history);
            } else if (approval.Status == "PENDING" && lastStatus == 'ALLOC_APP_PENDING') {
              var history = {
                ToStatus: 'ALLOC_APP_PENDING',
                updatedUser: approval.Resource.Name,
                GroupCardName: approval.CardName
              };
              res.data.push(history);
            } else {
              var history = {
                ToStatus: approval.Status,
                updatedUser: approval.Resource.Name,
                statusChangedAt: approval.Date ? approval.Date : null,
                GroupCardName: approval.CardName
              };
              res.data.push(history);
            }
          }
        }
        this.setState({
          lineStatusHistoryList: res.data
        });
      })
      .catch(err => {
        loggerService.writeLog(err, LOG_TYPES.ERROR);
      });
  };

    toggleWorkflowSelectDialogRR = async () => {
        await this.setState({
            showWorkflowSelectDialogRR: !this.state.showWorkflowSelectDialogRR
        });
        if (this.state.showWorkflowSelectDialogRR === false) {
            this.submitSearch();
        }
    };

    setSelectedWorkflowId = workflowId => {
        this.setState({
            selectedWorkFlowId: workflowId
        });
    };

    setWorkflowToResources = async () => {
        var mainLinesToSend = [];
        var subLinesToSend = [];
        if (this.state.selectedResources) {
            mainLinesToSend = this.state.selectedResources.filter(line => line.Status === 10);
        }
        if (this.state.selectedResourcesSubLine) {
            subLinesToSend = this.state.selectedResourcesSubLine.filter(line => line.Status === 10);
        }

        const workflowData =
        {
            mainLines: mainLinesToSend,
            subLines: subLinesToSend,
            projId: this.state.project.id,
            workflowId: this.state.selectedWorkFlowId
        };
        await this.updateLineHistory(mainLinesToSend, subLinesToSend);
        setWorkFlow(workflowData)
            .then(res => {
                if (this.state.matchingWorkflowList.length > 1) {
                    this.toggleWorkflowSelectDialogRR();
                }
                this.setState({
                    selectedWorkFlowId: null,
                    selectedResources: [],
                    selectedResourcesSubLine: []
                });
                this.setState({
                    showSuccessImage: true,
                    showErrorImage: false,
                    isSubmitted: false
                });
                this.toggleDialog(
                    'Resource allocation for selected line(s) successfully submitted',
                    'Success'
                );
                this.submitSearch();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                if (this.state.matchingWorkflowList.length > 1) {
                    this.toggleWorkflowSelectDialogRR();
                }
                const message = error.response.data;
                const title = 'Error';
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                this.toggleMessageDialog(message, title);
                this.submitSearch();
            });
    };

    onClickCancel = event => {
        this.toggleCancelConfirmDialog();
    };

    onClickReject = event => {
        this.setState({
            rejectReason: ''
        });
        this.toggleRejectConfirmDialog();
    };

    onClickApprove = event => {
        this.toggleConfirmDialog('Are you sure you want to approve this?', this.approveConfirm);
    };

    approveConfirm = async event => {
        const approveObj = {
            projectId: this.state.project.id,
            mainLines: this.state.selectedResources ? this.state.selectedResources : [],
            subLines: this.state.selectedResourcesSubLine ? this.state.selectedResourcesSubLine : []
        };
        this.toggleConfirmDialog('', null);

        approveResourceRequest(approveObj)
            .then(res => {
                this.setState({
                    selectedResources: [],
                    selectedResourcesSubLine: [],
                    showSuccessImage: true,
                    showErrorImage: false,
                });
                this.ticked = [];
                this.toggleDialog('Selected resources were approved successfully', 'Success');
             
                this.getInitialData();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleDialog(error.response.data, 'Error');
            });
    };

    rejectConfirm = event => {
        const rejectObj = {
            projectId: this.state.project.id,
            comment: this.state.rejectReason,
            mainLines: this.state.selectedResources ? this.state.selectedResources : [],
            subLines: this.state.selectedResourcesSubLine ? this.state.selectedResourcesSubLine : [],
            statusIn: 10
        };

        this.toggleRejectConfirmDialog();
        rejectResourceRequest(rejectObj)
            .then(res => {
                this.setState({
                    rejectReason: '',
                    selectedResources: [],
                    selectedResourcesSubLine: [],
                    showSuccessImage: true,
                    showErrorImage: false,
                });
                this.ticked = [];
                this.toggleDialog('Selected resources were rejected successfully', 'Success');
                this.getInitialData();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleDialog(error.response.data, 'Error');
            });
    };

    toggleCancelConfirmDialog = () => {
        this.setState({
            showCancelConfirmation: !this.state.showCancelConfirmation
        });
    };

    toggleRejectConfirmDialog = () => {
        this.setState({
            showRejectConfirmation: !this.state.showRejectConfirmation
        });
    };

    navigateBack = event => {
        if (this.state.isCancelAddPopUp === true) {
            this.setState({
                showAddDialog: false,
                showRejectConfirmation: false,
                showCancelConfirmation: false
            });
        }
        else if (this.state.isEditResource) {
            this.clearSubLineOnAddValues();
            this.setState({
                showEditDialog: false, 
                showCancelConfirmation: false, 
                isEditResource: false
            })
        } 
        else {
            this.setState({
                goBack: true
            });
        }
    };

    // Resource request sub line related changes
    handleSubLineStartDateChange = async event => {
        let endDate = event.target.value;

        const dateObj = {
            StartDate: moment(endDate).format('YYYY-MM-DD')
        };

        await this.setState({
            selctedResourceSubLineStartDate: endDate,
            isFormDirty: true
        });

        await this.calculateEffort();
    };

    handleSubLineEndDateChange = async event => {
        let endDate = event.target.value;

        const dateObj = {
            EndDate: moment(endDate).format('YYYY-MM-DD')
        };

        await this.setState({
            selctedResourceSubLineEndDate: endDate
        });

        await this.calculateEffort();
    };

    handleSubLineAllocationPercentageChange = async event => {
        let allocPercentage = event.target.value;
        await this.setState({
            isDisableAllocationLineAddBtn: true
        });

        if (allocPercentage && allocPercentage > 100) {
            allocPercentage = 100;
        } else if (!allocPercentage) {
            allocPercentage = 100;
        } else if (allocPercentage === 0) {
            allocPercentage = 100;
        }

        const allocPercentageObj = {
            allocation: allocPercentage
        };

        await this.setState({
            subLineDefaultAllocationPercentage: Number((Number(allocPercentage)).toFixed(2))
        });

        if (this.state.showEditSubLineDialog === true) {
            await this.calculateEffortForEditSubline()
        } else {
            await this.calculateEffort();
        }
        await this.setState({
            isDisableAllocationLineAddBtn: false
        });

    };

    handleSubLineRateTypeChange = async event => {
        let rateType = event.target.value;

        let index = 0;
        if (this.state.editableItem && this.state.editableItem.SubLines && this.state.editableItem.SubLines.length > 0) {
            for (let index of this.state.editableItem.SubLines) {
                index++;
            }
        }

        let resourceLineObj = [];
        if (index > 0) {
            resourceLineObj = JSON.parse(JSON.stringify(this.state.editableItem.SubLines[index]));
        }

        resourceLineObj.RateType = rateType;
        resourceLineObj.Rate = rateType.Rate;

        this.state.editableItem.SubLines[index] = resourceLineObj;

        await this.setState({
            subLineDefaultRateType: rateType,
            subLineDefaultRateValue: rateType.Rate
        });

        this.calculateEffort();
    };

    calculateEffort = async () => {
        let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
        let isHoliday = false;
        if (this.state.selctedResourceSubLineStartDate && this.state.selctedResourceSubLineEndDate && this.state.subLineDefaultAllocationPercentage > 0) {
            let startDate = this.state.selctedResourceSubLineStartDate;
            let endDate = this.state.selctedResourceSubLineEndDate;
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            const resourceCountryId = this.state.editableItem.dataValueResourceRegion.DefaultCountryId;
            let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                if (res && res.data) {
                    return res.data;
                }
                return [];
            })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

            if (holidays && holidays.length === 0) {
            } else if (!holidays) {
                holidays = [];
            }

            let loop = new Date(startDate);
            while (loop <= endDate) {
                isHoliday = false;
                const dateStr = getFormattedDate(loop);
                if (holidays.indexOf(dateStr) > -1) {
                    isHoliday = true;
                }

                if (!isHoliday && !isWeekend(loop)) {
                    totalWorkingDays++;
                } else {
                }

                let newDate = loop.setDate(loop.getDate() + 1);
                loop = new Date(newDate);
            }
            const effort = totalWorkingDays * (this.state.subLineDefaultAllocationPercentage / 100);
            this.setState({
                subLineEffortDays: effort
            });
        }
    };

    calculateEffortForAddResource = async () => {
        await this.setState({
            isLoading: true
        });
        let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
        let isHoliday = false;
        if (this.state.newStartDate && this.state.newEndDate && this.state.newAllocation > 0 && this.state.newOnSiteStatus && this.state.newResProjectOrCRName) {
            let startDate = this.state.newStartDate;
            let endDate = this.state.newEndDate;
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            var effort = 0;
            if (this.state.newOnSiteStatus.id === 2) {
                const resourceCountryId = this.state.newResRegion ? this.state.newResRegion.DefaultCountryId : null;
                let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if (holidays && holidays.length === 0) {
                } else if (!holidays) {
                    holidays = [];
                }

                let loop = new Date(startDate);
                while (loop <= endDate) {
                    isHoliday = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (!isHoliday && !isWeekend(loop)) {
                        totalWorkingDays++;
                    } else {
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

                effort = totalWorkingDays * (this.state.newAllocation / 100);
            } else if (this.state.newOnSiteStatus.id === 1) {
                const resourceCountryId = this.state.newResProjectOrCRName ? this.state.newResProjectOrCRName.countryId : null;
                let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if (holidays && holidays.length === 0) {
                } else if (!holidays) {
                    holidays = [];
                }

                let loop = new Date(startDate);
                while (loop <= endDate) {
                    isHoliday = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (!isHoliday && !isWeekend(loop)) {
                        totalWorkingDays++;
                    } else {
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

                effort = totalWorkingDays * (this.state.newAllocation / 100);
            }
            await this.setState({
                isLoading: false,
                newEffortDays: effort
            });
        } else {
            this.setState({
                isLoading: false
            });
        }
    };

    calculateEffortForEditSubline = async () => {
        let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
        let isHoliday = false;
        if (this.state.selectedSubLineToEdit.StartDate && this.state.selectedSubLineToEdit.EndDate && this.state.subLineDefaultAllocationPercentage > 0) {
            let startDate = reStructureDate(this.state.selectedSubLineToEdit.StartDate);
            let endDate = reStructureDate(this.state.selectedSubLineToEdit.EndDate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            const resourceCountryId = this.state.selectedSubLineToEdit.dataValueResourceRegion.DefaultCountryId;
            let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                if (res && res.data) {
                    return res.data;
                }
                return [];
            })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

            if (holidays && holidays.length === 0) {
            } else if (!holidays) {
                holidays = [];
            }

            let loop = reStructureDate(startDate);
            while (loop <= endDate) {
                isHoliday = false;
                const dateStr = getFormattedDate(loop);
                if (holidays.indexOf(dateStr) > -1) {
                    isHoliday = true;
                }

                if (!isHoliday && !isWeekend(loop)) {
                    totalWorkingDays++;
                } else {
                }

                let newDate = loop.setDate(loop.getDate() + 1);
                loop = reStructureDate(newDate);
            }
            const effort = totalWorkingDays * (this.state.subLineDefaultAllocationPercentage / 100);
            this.state.selectedSubLineToEdit.EffortDays = effort

            for (let item of this.state.resourceMix) {
                if (item.id === this.state.selectedSubLineToEdit.ResourceRequestId) {
                    const totalCost = (item.CostPerResource * effort).toFixed(2)
                    const totalRev = (item.RateValue * effort).toFixed(2)
                    this.state.selectedSubLineToEdit.AllocationPercentage = this.state.subLineDefaultAllocationPercentage
                    this.state.selectedSubLineToEdit.Cost = totalCost;
                    this.state.selectedSubLineToEdit.Revenue = totalRev;
                    this.state.selectedSubLineToEdit.CostToRevenueRatio = (totalCost / totalRev).toFixed(2);
                    this.forceUpdate()
                    return
                }
            }
        }
    }

    onAddSubLineToGrid = async () => {
        await this.setState({
            isDisableAllocationLineAddBtn: true
        });

        let totalEffortDays = this.state.editableItem.TotalEffortDays;
        let currentEffortDays = 0.00;

        if (this.state.editableItem.ResourceRequestSubLine) {
            for (const subline of this.state.editableItem.ResourceRequestSubLine) {
                if (subline.RateType !== 5) {
                    currentEffortDays += Number(subline.EffortDays);
                }
            }
        }

        currentEffortDays += Number(this.state.subLineEffortDays.toFixed(2));
        if (currentEffortDays > totalEffortDays) {
            if (this.state.editableItem.ResourcePaymentMethod.id === 1) {
                this.setState({
                    setNonBillable: true
                });
            }
            await this.setState({
                effortOverrun: true
            });
            this.addSubLineToggle();

        } else {
            await this.setState({
                effortOverrun: false
            });
            this.confirmAddSubLineToGrid();
        }
    }

    confirmAddSubLineToGrid = async () => {
        let projcurrencyId = 0;
        let rateValueInUSD = null;
        let rateInUSD = null;
        let TotalRevenueInUSD = null;
        let DirectCostInUSD = 0;
        let CostPerResourceInUSD = 0;
        let month = '';
        let year = '';
        let StartDateArray;
        let SelectedbillingRegion = '';
        let index;
        let totalCostUSD = 0;
        let addMainlineCheck = false;

        this.setState({
            approveAddSubLine: false
        });
        index = this.state.editableItem.ResourceRequestSubLine ? this.state.editableItem.ResourceRequestSubLine.length : 0;
        if(index == 0){
            addMainlineCheck = true;
        }
        let billingRegion =this.state.editableItem.ChangeRequest ? this.state.editableItem.ChangeRequest.BillingRegion : this.state.editableItem.Project.BillingRegion;
        await getRegionCurrencyIntegrated(billingRegion).then(response => {
            this.state.newProjectCurrency = response.data;
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        let costDate_ = reStructureDate(this.state.selctedResourceSubLineStartDate);
        const costCard = {
            billingRegionId: this.state.editableItem.ChangeRequest ? this.state.editableItem.ChangeRequest.BillingRegion : this.state.editableItem.Project.BillingRegion,
            resourceRegionId: this.state.editableItem.dataValueResourceRegion.id,
            designationId: this.state.editableItem.dataValueCostDesignation.id,
            costDate: moment(costDate_).format('YYYY-MM-DD'),
            projectId: this.props.location.project.id
        };

        let newItem = {
            newResProjectOrCRName: this.state.editableItem.ChangeRequest ? this.state.editableItem.ChangeRequest : this.state.editableItem.Project,
            newResRegion: this.state.editableItem.dataValueResourceRegion,
            newOnSiteStatus: this.state.editableItem.OnsiteStatus,
            newStartDate: moment(this.state.selctedResourceSubLineStartDate).format('MM/DD/YYYY'),
            newEndDate: moment(this.state.selctedResourceSubLineEndDate).format('MM/DD/YYYY'),
            newEffortDays: this.state.subLineEffortDays.toFixed(2),
            newAllocation: parseFloat(this.state.subLineDefaultAllocationPercentage).toFixed(2),
            EstimatedEndDate: moment(this.state.selctedResourceSubLineEndDate).format('MM/DD/YYYY'),
            newResDesignation: this.state.editableItem.dataValueCostDesignation,
            resourceCountry : this.state.editableItem.dataValueResourceRegion.DefaultCountryId,
            projOrCrCountry : this.state.editableItem.ChangeRequest ? this.state.editableItem.ChangeRequest.countryId : this.state.editableItem.Project.countryId,
            resourceId: null
        };
        costDate_ = moment(costDate_).format('YYYY-MM-DD');
        const costItems = {
            costCard : costCard,
            newItem : newItem,
            costDate: costDate_
        }
        await getCostDetails(costItems)
          .then(res => {
              totalCostUSD = res.data.TotalCost.toFixed(2);
              DirectCostInUSD = res.data.DirectCost;
              CostPerResourceInUSD = res.data.CostPerResource;
          })
          .catch( async err => {
              totalCostUSD = 0;
              DirectCostInUSD = 0;
              CostPerResourceInUSD = 0;
              loggerService.writeLog(err, LOG_TYPES.ERROR);

              const message = 'Cost card record not found for the selected designation and resource region';
              const title = 'Error';
              await this.setState({
                  showSuccessImage: false,
                  showErrorImage: true
              });

              await this.toggleMessageDialog(message, title);
          });

        const totalRev = this.state.setNonBillable === true ? Number(0.00) : (this.state.subLineDefaultRateValue * this.state.subLineEffortDays).toFixed(2);

        //get selected pro/cr billing region
        if (this.state.editableItem.Type === 'Project') {
            SelectedbillingRegion = this.state.editableItem.Project.BillingRegion
        }
        else {
            SelectedbillingRegion = this.state.editableItem.ChangeRequest.BillingRegion
        }


        //when the currency is != USD
        await getRegionCurrencyIntegrated(SelectedbillingRegion).then(response => {
            projcurrencyId = response.data.CurrencyId;
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });


        // Get year and month of the project
        StartDateArray = moment(this.state.selctedResourceSubLineStartDate).format('YYYY-MM-DD').split('-');
        year = StartDateArray[0];
        month = StartDateArray[1];

        if (projcurrencyId !== 1) {

            this.setState({
                isNotCurrencyUSD: true
            });

            const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                projcurrencyId,
                month,
                year
            );

            rateValueInUSD = (this.state.subLineDefaultRateValue / projrateRecordCurrencyRate).toFixed(2);
            rateInUSD = (this.state.subLineDefaultRateValue / projrateRecordCurrencyRate).toFixed(2);
            TotalRevenueInUSD = (totalRev / projrateRecordCurrencyRate).toFixed(2);
        }

        const subLineObj = {
            StartDate: moment(this.state.selctedResourceSubLineStartDate).format('YYYY-MM-DD'),
            EndDate: moment(this.state.selctedResourceSubLineEndDate).format('YYYY-MM-DD'),
            EffortDays: this.state.subLineEffortDays.toFixed(2),
            AllocationPercentage: parseFloat(this.state.subLineDefaultAllocationPercentage).toFixed(2),
            RateType: this.state.setNonBillable === true ? 5 : this.state.subLineDefaultRateType.id,
            Rate: this.state.setNonBillable === true ? Number(0.00) : (this.state.isNotCurrencyUSD === true ? rateInUSD : this.state.subLineDefaultRateValue),
            Status: 'RES_ALLOC_PENDING',
            index: index,
            RateValue: this.state.setNonBillable === true ? Number(0.00) : (this.state.isNotCurrencyUSD === true ? rateValueInUSD : this.state.subLineDefaultRateValue),
            CostPerResource: CostPerResourceInUSD,
            DirectCost: DirectCostInUSD,
            Cost: totalCostUSD,
            Revenue: 0,
            CostToRevenueRatio: 0.00,
            selected: false,
            ResourceRequestId: this.state.editableItem.id ? this.state.editableItem.id : null,
            dataValueResourceRegion: this.state.editableItem.dataValueResourceRegion,
            Phase: this.state.editableItem.Phase,
            SubPhase: this.state.editableItem.SubPhase,
            ProjectRole: this.state.editableItem.ProjectRole,
            Designation: this.state.editableItem.Designation,
            ResourceRegion: this.state.editableItem.ResourceRegion,
            OnsiteStatus: this.state.editableItem.OnsiteStatus,
            PhaseId: this.state.editableItem.PhaseId,
            SubPhaseId: this.state.editableItem.SubPhaseId,
            PaymentMethod: this.state.editableItem.PaymentMethod,
            dataValueCostDesignation: this.state.editableItem.dataValueCostDesignation,
            dataValueProjectRole: this.state.editableItem.dataValueProjectRole,
            LastUpdatedByUserAt: new Date(),
            EffortOverrun: this.state.effortOverrun,
            IsAllocatedLine: true,
            IsActive: true,
            IsRevenuePresent: false
        };

        if (this.state.editableItem.ResourceRequestSubLine) {
            this.state.editableItem.ResourceRequestSubLine[subLineObj.index] = subLineObj;
        } else {
            this.state.editableItem.ResourceRequestSubLine = [];
            this.state.editableItem.ResourceRequestSubLine[subLineObj.index] = subLineObj;
        }

        if (!this.state.hasCMSSubLines && addMainlineCheck) {
            // Add main line as sub line
            if (this.props.location.action == 'RPApprove') {
                this.addMainLineAsSubLine(this.state.resourceMix[this.state.editableItem.index]);
            }
        }

        this.setState({
            editableResourceSubLines: this.state.editableItem.ResourceRequestSubLine
        });
        this.clearSubLineOnAddValues();
    };

    clearSubLineOnAddValues = () => {
        this.setState({
            selctedResourceSubLineStartDate: null,
            selctedResourceSubLineEndDate: null,
            subLineDefaultAllocationPercentage: 0,
            subLineEffortDays: 0,
            setNonBillable: false,
            isMainLinesAllocated: true
        });
    };

    addSubLineToggle = async () => {
        await this.setState({
            approveAddSubLine: !this.state.approveAddSubLine,
        });
    }

    // SUBLINE CHANGES
    onSubLineSelect = async (param) => {
        const checked = param.syntheticEvent.target.checked;

        let isRemoveRequest = false;
        let resourceSubLine = [];
        let resourceLine = [];
        let isHaveMainLine = false;
        let requestAllocation = false;
        let requestAllocationSoft = false;
        let requestAllocationSoftResubmit = false;
        let requestAllocationSoftDate = false;

        let confirmStatus = false;
        let isCanSoftBooking = false;
        let isSubLinesValid = false;
        let isAddedFromAllocation = false;
        let isNoCostVarianceSubLine = false;

        await this.setState({
            isCheckedSubline: true,
        });

        if (param) {
            if (param.dataItem.hasOwnProperty('selected')) {
                param.dataItem.selected = !param.dataItem.selected
            } else {
                param.dataItem["selected"] = true
            }
            for (let item of this.state.resourceMix) {
                if (item.id === param.dataItem.ResourceRequestId) {
                    item.ResourceRequestSubLine[param.dataItem.index] = param.dataItem
                    // resourceSubLine.push(item.ResourceRequestSubLine)
                    this.forceUpdate();

                }
            }
            //TODO
            //indexes are incorrect
            const index = this.state.resourceMix.indexOf(param.dataItem);

            if (checked) {
                this.ticked.push(index);
                this.sublineTicked.push(index);
            } else {
                this.ticked.splice(this.ticked.indexOf(index), 1);
                this.sublineTicked.splice(this.ticked.indexOf(index), 1);
            }

            for (const item of this.state.resourceMix) {
                if (item.ResourceRequestSubLine && item.ResourceRequestSubLine.length > 0) {
                    item.ResourceRequestSubLine.filter(resource => {
                        if (resource.selected === true) {
                            resource['Type'] = item.Type
                            resource.Project = item.Project;
                            (item.Type === 'ChangeRequest') ? resource.ChangeRequest = item.ChangeRequest: resource.ChangeRequest = null;
                            resourceSubLine.push(resource)
                        }
                    })
                }
            }

            //requestAllocation
            for (const resource of resourceSubLine) {
                if ((resource.Status !== 'CONFIRMED' && resource.Status !== 'RQST_APP_REJECTED') && (resource.Status !== 'RES_ALLOC_PENDING' || !resource.IsSoftBookingResource)) {
                    requestAllocation = true;
                }
            }

            //soft booking
            for (const resource of resourceSubLine) {
                if (resource.Status !== 'CONFIRMED' || resource.IsSoftBookingResource) {
                    requestAllocationSoft = true;
                }
            }

            //soft booking to date check
            for (const resource of resourceSubLine) {
                if (resource.StartDate === null || resource.EndDate === null) {
                    requestAllocationSoftDate = true;
                }
            }

            //resubmit same soft book
            for (const resource of resourceSubLine) {
                if (resource.IsSoftBookingResource) {
                    requestAllocationSoftResubmit = true;
                }
                if (!resource.IsSoftBookingResource && resource.CostVariance == null) {
                    isNoCostVarianceSubLine = true;
                }
            }

            //confirm
            for (const resource of resourceSubLine) {
                if (resource.Status !== 'EDIT' && resource.Status !== 'NEW_FROM_RM' &&
                    resource.Status !== 'NEW_FROM_CMS' && resource.Status !== 'RES_ALLOC_CANCELLED') {
                    confirmStatus = true;
                }
            }

            //Enable/disable cancel request button
            for (const resource of resourceSubLine) {
                if (resource.IsAllocatedLine === true) {
                    isAddedFromAllocation = true;
                }
            }

            //Check all the sublines are valide for approval
            let selectedSubLinesAry = [];
            for (const resource of resourceSubLine) {
                if (resource.ResourceId !== null) {
                    selectedSubLinesAry.push(true);
                } else {
                    selectedSubLinesAry.push(false);
                }
            }
            isSubLinesValid = (selectedSubLinesAry.filter(i => i == true).length == selectedSubLinesAry.length) ? true : false;


            const selected = this.state.resourceMix.filter(resource => resource.selected === true);
            resourceLine.push(selected)

            if (selected.length > 0) {
                isHaveMainLine = true;

                //for soft booking resource request
                for (const item of resourceLine) {
                    if (item.Status === 'CONFIRMED') {
                        isCanSoftBooking = true;
                    } else {
                        isCanSoftBooking = false;
                        break;
                    }
                }

            }

            // Enabla/Disable remove line btn
            let isAllocatedLine = [];
            if (resourceSubLine.length > 0) {
                for (const item of resourceSubLine) {
                    if (item.IsAllocatedLine) {
                        isAllocatedLine.push(true);
                    } else {
                        isAllocatedLine.push(false);
                    }
                }
                isRemoveRequest = (isAllocatedLine.filter(i => i == true).length == isAllocatedLine.length) ? true : false;
            } else {
                isRemoveRequest = false;
            }


            //for soft booking resource
            if (isCanSoftBooking === true || isHaveMainLine === false) {
                for (const item of resourceSubLine) {
                    if (item.Status === 'CONFIRMED') {
                        isCanSoftBooking = true;
                    } else {
                        isCanSoftBooking = false;
                    }
                }
            }

            await this.setState({
                isCanRemoveRequest: isRemoveRequest,
                isCanSoftBooking: isCanSoftBooking,
                selectedResourcesSubLine: resourceSubLine,
                requestAllocationSub: requestAllocation,
                requestAllocationSubSoft: requestAllocationSoft,
                requestAllocationSoftSubDate: requestAllocationSoftDate,
                requestAllocationSubSoftResubmit: requestAllocationSoftResubmit,
                confirmStatusSub: confirmStatus,
                isSubLinesValid: isSubLinesValid,
                isAddedFromAllocation: isAddedFromAllocation,
                isNoCostVarianceSubLine: isNoCostVarianceSubLine
            });
        }
    }

    onSubLineEdit = (param, mainLineofsublineObj) => {

        if (param) {
            this.setState({
                selectedSubLineToEdit: param,
                mainLineofsublineObj: mainLineofsublineObj
            }, () => {
                this.toggleSubLineEditDialog();
            })

        }
    };

    handleOnChangeDropDown = async event => {
        const field = event.target.name;
        const value = event.target.value;


        if (this.isMount) {
            await this.setState({
                [field]: value,
                excelDataObsolete: true
            });
        }

    };

    toggleSubLineEditDialog = () => {
        this.setState({ showEditSubLineDialog: !this.state.showEditSubLineDialog });
    };

    handleComboBoxChangeOnSubLineEdit = async event => {
        const value = event.target.value;
        const name = event.target.name;
        switch (name) {
            case 'editRole':
                if (value) {
                    let filteredArray = this.designationsAll.filter(function (itm) {
                        return value.CategoryId === itm.CategoryId || itm.Name === 'Outsourced';
                    });
                    this.TmpDesignationsAll = filteredArray;
                    this.state.editableItem.dataValueProjectRole = value;
                    this.state.editableItem.ProjectRole = value.id;
                    this.state.tmpDesignationsAll = filteredArray;
                } else {
                    this.TmpDesignationsAll = [];
                    this.state.tmpDesignationsAll = [];
                    this.state.editableItem.dataValueProjectRole = null;
                    this.state.editableItem.ProjectRole = null;
                }
                this.state.editableItem.dataValueCostDesignation = null;
                this.state.editableItem.Designation = null;
                this.forceUpdate();
                break;
            case 'editSubLineDesignation':
                if (value) {
                    this.state.selectedSubLineToEdit.dataValueCostDesignation = value;
                    this.state.selectedSubLineToEdit.Designation = value.id;
                } else {
                    this.state.selectedSubLineToEdit.dataValueCostDesignation = null;
                    this.state.selectedSubLineToEdit.Designation = null;
                }
                this.forceUpdate();
                await this.updateSublineCost();
                break;
            case 'editSubLineOnSiteStatus':
                if (value) {
                    this.state.selectedSubLineToEdit.OnsiteStatus = value.Name;
                } else {
                    this.state.selectedSubLineToEdit.OnsiteStatus = null;
                }
                this.forceUpdate();
                break;
            case 'editAllocatedResource':
                if (value) {
                    this.state.editableItem.AllocatedResource = value;
                    this.state.editableItem.AllocatedResourceID = value.id;
                    this.state.editableItem.ResourceId = value.id;
                } else {
                    this.state.editableItem.AllocatedResource = null;
                    this.state.editableItem.AllocatedResourceID = null;
                    this.state.editableItem.ResourceId = null;
                }
                this.forceUpdate();
                break;
            case 'editSubLinePhase':
                if (value) {
                    this.state.selectedSubLineToEdit.Phase = value;
                    this.state.selectedSubLineToEdit.PhaseId = value.id;

                    let subPhasesOfSelectedPhase = [];
                    if (value && value.SubPhases && value.SubPhases.length > 0) {
                        subPhasesOfSelectedPhase = value.SubPhases;
                    }
                    this.setState({
                        subPhasesOfSelectedPhase: subPhasesOfSelectedPhase
                    });

                } else {
                    this.state.selectedSubLineToEdit.Phase = null;
                    this.state.selectedSubLineToEdit.PhaseId = null;

                    this.state.selectedSubLineToEdit.SubPhase = null;
                    this.state.selectedSubLineToEdit.SubPhaseId = null;

                    this.setState({
                        subPhasesOfSelectedPhase: []
                    });

                }
                break;
            case 'editSubLineSubPhase':
                if (value) {
                    this.state.selectedSubLineToEdit.SubPhase = value;
                    this.state.selectedSubLineToEdit.SubPhaseId = value.id;
                } else {
                    this.state.selectedSubLineToEdit.SubPhase = null;
                    this.state.selectedSubLineToEdit.SubPhaseId = null;
                }
                this.forceUpdate();
                break;
            case 'editSubLineRegion':
                if (value) {
                    this.state.selectedSubLineToEdit.dataValueResourceRegion = value;
                    this.state.selectedSubLineToEdit.RegionId = value.id;
                    this.state.selectedSubLineToEdit.ResourceRegion = value.id;
                } else {
                    this.state.selectedSubLineToEdit.dataValueResourceRegion = null;
                    this.state.selectedSubLineToEdit.RegionId = null;
                    this.state.selectedSubLineToEdit.ResourceRegion = null;
                }
                this.forceUpdate();
                await this.updateSublineCost();
                break;
            default:
                break;
        }
    };

    updateSublineCost = async () => {

        let newItem = {};
        let totalCostUSD = null;
        let DirectCostInUSD = null;
        let CostPerResourceInUSD = null;
        let CostToRevenueRatio = 0;
        let totalRev = 0;

        let billingRegion = this.state.mainLineofsublineObj.ChangeRequest ? this.state.mainLineofsublineObj.ChangeRequest.BillingRegion : this.state.mainLineofsublineObj.Project.BillingRegion;
        await getRegionCurrencyIntegrated(billingRegion).then(response => {
            this.state.newProjectCurrency = response.data;
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        const costCard = {
            billingRegionId: this.state.mainLineofsublineObj.ChangeRequest ? this.state.mainLineofsublineObj.ChangeRequest.BillingRegion : this.state.mainLineofsublineObj.Project.BillingRegion,
            resourceRegionId: this.state.selectedSubLineToEdit.dataValueResourceRegion.id,
            designationId: this.state.selectedSubLineToEdit.dataValueCostDesignation.id,
            costDate: moment(new Date()).format('YYYY-MM-DD'),
            projectId: this.props.location.project.id
        };

        await this.getCostDetails(costCard, newItem);

        totalCostUSD = (this.state.sublineCost.CostPerResource * this.state.selectedSubLineToEdit.EffortDays).toFixed(2);
        DirectCostInUSD = this.state.sublineCost.DirectCost;
        CostPerResourceInUSD = this.state.sublineCost.CostPerResource;
        totalRev = this.state.selectedSubLineToEdit.Revenue;

        CostToRevenueRatio = Number(totalRev) == 0 ? 0.00 : (totalCostUSD / totalRev).toFixed(2);

        this.state.selectedSubLineToEdit.Cost = totalCostUSD;
        this.state.selectedSubLineToEdit.DirectCost = DirectCostInUSD;
        this.state.selectedSubLineToEdit.CostPerResource = CostPerResourceInUSD;
        this.state.selectedSubLineToEdit.CostToRevenueRatio = CostToRevenueRatio;

    };

    toggleSubLineUpdatedDialog = () => {
        this.setState({
            subLineUpdatedDialog: !this.state.subLineUpdatedDialog
        });
    };


    //to filter project or CR
    filterProjectOrCR = async (filteredArr) => {
        let filteredResourceMixLines = [];
        for (const item of filteredArr) {

            for (const obj of this.state.newResProjectOrCRNames) {
                if (obj.type === item.Type) {
                    if (obj.type === 'ChangeRequest') {
                        if (obj.id === item.ChangeRequest.id) {
                            filteredResourceMixLines.push(item)
                        }
                    } else {
                        if (obj.id === item.Project.id) {
                            filteredResourceMixLines.push(item)
                        }
                    }
                }
            }
        }
        return filteredResourceMixLines;
    };

    //to filter by status of resource mix lines
    filterByStatus = async (filterArr) => {

        let finalized = [];

        for (const item of filterArr) {

            let newSubLine = [];
            let index = 0;
            if (item.ResourceRequestSubLine && item.ResourceRequestSubLine.length >= 1) {

                for (const resourceSubLine of item.ResourceRequestSubLine) {

                    for (const status of this.state.SelectedLineStatuses) {
                        if ((resourceSubLine.Status === status.code)) {
                            resourceSubLine.index = index;
                            newSubLine.push(resourceSubLine);
                            index++;
                        }
                    }
                }

                if (newSubLine.length > 0) {
                    item.ResourceRequestSubLine = newSubLine;
                    finalized.push(item);
                }


            }

            else {
                for (const status of this.state.SelectedLineStatuses) {
                    if ((item.Status === status.code)) {

                        finalized.push(item);

                    }
                }
            }
        }

        return finalized;

    }


    submitSearch = async event => {
        var filteredMix = [];
        var index = 0;
        await this.populateConsolidatedResourceMix();
        let newResProjectOrCRNames = this.state.newResProjectOrCRNames;
        let reasourceAll = this.state.forFilterResourceLine;
        const IdList = this.getCMSheetVersionAndIdList();

        if (event) {
            event.preventDefault();
        }

        this.setState({
            loading: true,
            filteredTotalCost: '',
            filteredTotalRevenue: '',
            filteredCostToRevenueRatio: '',
            newResProjectOrCRNames: newResProjectOrCRNames,
        });

        if ((this.state.newResProjectOrCRNames && this.state.newResProjectOrCRNames.length >= 1) && (this.state.SelectedLineStatuses && this.state.SelectedLineStatuses.length >= 1)) {

            let filteredProCR = [];
            let filteredProCRStatus = [];

            filteredProCR = await this.filterProjectOrCR(reasourceAll);
            filteredProCRStatus = await this.filterByStatus(filteredProCR);
            filteredMix = filteredProCRStatus;
        }
        else if (this.state.newResProjectOrCRNames && this.state.newResProjectOrCRNames.length >= 1) {
            let projectOnlyFilter = [];

            projectOnlyFilter = await this.filterProjectOrCR(reasourceAll);
            filteredMix = projectOnlyFilter;
        }
        else if (this.state.SelectedLineStatuses && this.state.SelectedLineStatuses.length >= 1) {
            let statusOnlyFilter = [];

            statusOnlyFilter = await this.filterByStatus(reasourceAll);
            filteredMix = statusOnlyFilter;

        }

        for (const eachLine of filteredMix) {
            eachLine.index = index;
            index++;
            var indexSub = 0;
            if (eachLine.ResourceRequestSubLine && eachLine.ResourceRequestSubLine.length > 0) {
                for (const eachSub of eachLine.ResourceRequestSubLine) {
                    eachSub.index = indexSub;
                    indexSub++;
                }
            }
        }

        await this.setState({
            filteredResourceMix: filteredMix,
            loading: false,
            skip: 0,
            excelDataObsolete: true
        });

        await this.filterCalculations();

        this.ticked = [];
        this.sublineTicked = [];
        this.allocationId = [];
        this.setState({
            hasSubLines: false,
            confirmStatus: false,
            confirmStatusSub: false,
            isAddedFromAllocation: false
        })
    };


    filterCalculations = async () => {

        let totalCost = null;
        let totalRevenue = null;
        let costToRevenueRatio = null;

        if (this.state.filteredResourceMix && this.state.filteredResourceMix.length > 0) {
            for (const item of this.state.filteredResourceMix) {


                //calculate total Revenue
                totalRevenue += Number(item.TotalRevenue)

                //calculate total cost
                totalCost += Number(item.TotalCost)
            }

            //calculate Cost to Revenue Ratio
            if (totalRevenue === 0) {
                costToRevenueRatio = '0.00';
            }
            else {
                costToRevenueRatio = (totalCost / totalRevenue).toFixed(2)
            }

            this.setState({
                filteredTotalCost: totalCost.toFixed(2),
                filteredTotalRevenue: totalRevenue.toFixed(2),
                filteredCostToRevenueRatio: costToRevenueRatio,
                excelDataObsolete: true
            });
        }
    }


    onUpdateSubLineResource = async () => {
        if (!this.validateSublineEditingValues()) {
            /*await this.setState({
         showSuccessImage: false,
         showErrorImage: true
       });
       this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
     */} else {
            for (let item of this.state.resourceMix) {

                if (item.id === this.state.selectedSubLineToEdit.ResourceRequestId) {
                    this.state.selectedSubLineToEdit.Status = "EDIT";
                    this.state.selectedSubLineToEdit.LastUpdatedByUserAt = new Date();
                    item.ResourceRequestSubLine[this.state.selectedSubLineToEdit.index] = this.state.selectedSubLineToEdit;
                    this.toggleSubLineEditDialog();
                    var resourcesToSave = [item];
                    this.handleResourceSave(resourcesToSave);
                    return
                }
            }

        }
    };

    filterChangeCombo = (event, dataItem) => {
        clearTimeout(this.timeout);

        const field = event.target.name;
        const filter = event.filter;

        this.timeout = setTimeout(() => {
            switch (field) {
                case 'editPhase': {
                    this.state.editableItem.Phases = this.filterComboData(filter, this.state.allProjectCrPhases);
                    break;
                }
                case 'editDesignation':
                    this.state.editableItem.Designations = this.filterComboData(filter, this.state.tmpDesignationsAll);
                default: {
                    break;
                }
            }

            this.setState({
                resourceMix: [...this.state.resourceMix]
            });
        }, 500);
    };

    filterComboData = (filter, allData) => {
        const data = allData.slice();
        return filterBy(data, filter);
    }

    handlePMCommentChange = (content) => {
        this.setState({
            managerComment: content
        })
    };

    calculateTotalAvailableTime = async (selectedResourceMixLine, allocationPercentage, ResourceDetails, allocationPercentageHistory) => {

        let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
        let isHoliday = false;
        if (selectedResourceMixLine && selectedResourceMixLine.StartDate && selectedResourceMixLine.EndDate && ResourceDetails) {
            let startDate = reStructureDate(selectedResourceMixLine.StartDate);
            let endDate = reStructureDate(selectedResourceMixLine.EndDate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            var TotalAvailableTime = 0;
            if (!selectedResourceMixLine.IsPreSales && selectedResourceMixLine.OnsiteStatus === 'Offshore') { // offshore
                const resourceCountryId = ResourceDetails ? ResourceDetails.GeneralCountry : null;
                let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if (holidays && holidays.length === 0) {
                } else if (!holidays) {
                    holidays = [];
                }

                let loop = new Date(startDate);
                if (allocationPercentageHistory && allocationPercentageHistory.length > 0) {
                    while (loop <= endDate) {
                        const matchingHistoryLine = allocationPercentageHistory.filter(record => {
                            const loopDate = moment(moment(loop).format('YYYY-MM-DD'));
                            const recordStartDate = moment(record.StartDate);
                            const recordEndDate = moment(record.EndDate);
                            return loopDate.isBetween(recordStartDate, recordEndDate) ||
                            loopDate.isSame(recordStartDate) || loopDate.isSame(recordEndDate)
                        });
                        isHoliday = false;
                        const dateStr = getFormattedDate(loop);
                        if (holidays.indexOf(dateStr) > -1) {
                            isHoliday = true;
                        }
    
                        if (!isHoliday && !isWeekend(loop)) {
                            totalWorkingDays = totalWorkingDays + (1 * (parseFloat(matchingHistoryLine[0].AllocationPercentage)/100));
                        } else {
                        }
    
                        let newDate = loop.setDate(loop.getDate() + 1);
                        loop = new Date(newDate);
                    }
                    TotalAvailableTime = totalWorkingDays;    
                } else {
                while (loop <= endDate) {
                    isHoliday = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (!isHoliday && !isWeekend(loop)) {
                        totalWorkingDays++;
                    } else {
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

                TotalAvailableTime = (totalWorkingDays * parseFloat(allocationPercentage))/100;

                }
            } else if (!selectedResourceMixLine.IsPreSales && selectedResourceMixLine.OnsiteStatus === 'Onsite') {
                // const resourceCountryId = ResourceDetails ? ResourceDetails.GeneralCountry : null;
                const resourceCountryId = selectedResourceMixLine ? selectedResourceMixLine.projectOrCrCountry : null;
                let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if (holidays && holidays.length === 0) {
                } else if (!holidays) {
                    holidays = [];
                }

                let loop = new Date(startDate);
                if (allocationPercentageHistory && allocationPercentageHistory.length > 0) {
                    while (loop <= endDate) {
                        const matchingHistoryLine = allocationPercentageHistory.filter(record => {
                            const loopDate = moment(moment(loop).format('YYYY-MM-DD'));
                            const recordStartDate = moment(record.StartDate);
                            const recordEndDate = moment(record.EndDate);
                            return loopDate.isBetween(recordStartDate, recordEndDate) ||
                            loopDate.isSame(recordStartDate) || loopDate.isSame(recordEndDate)
                        });
                        isHoliday = false;
                        const dateStr = getFormattedDate(loop);

                        if (holidays.indexOf(dateStr) > -1) {
                            isHoliday = true;
                        }
    
                        if (!isHoliday && !isWeekend(loop)) {
                            totalWorkingDays = totalWorkingDays + (1 * (parseFloat(matchingHistoryLine[0].AllocationPercentage)/100));
                        } else {
                        }
    
                        let newDate = loop.setDate(loop.getDate() + 1);
                        loop = new Date(newDate);
                    }
                    TotalAvailableTime = totalWorkingDays;    
                } else {
                while (loop <= endDate) {
                    isHoliday = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (!isHoliday && !isWeekend(loop)) {
                        totalWorkingDays++;
                    } else {
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

                TotalAvailableTime = (totalWorkingDays * parseFloat(allocationPercentage))/100;
                }
                
            } else if (selectedResourceMixLine.IsPreSales) {
                let resourceCountryId = null;
                if (selectedResourceMixLine.OnsiteStatus === 'Onsite') {
                    resourceCountryId = selectedResourceMixLine ? selectedResourceMixLine.projectOrCrCountry : null;
                } else {
                    resourceCountryId = ResourceDetails ? ResourceDetails.GeneralCountry : null;
                }
                let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if (holidays && holidays.length === 0) {
                } else if (!holidays) {
                    holidays = [];
                }

                let loop = new Date(startDate);
                while (loop <= endDate) {
                    isHoliday = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (!isHoliday && !isWeekend(loop)) {
                        totalWorkingDays++;
                    } else {
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

                TotalAvailableTime = (totalWorkingDays * parseFloat(allocationPercentage))/100;

            }

            return TotalAvailableTime;

        } else {
            return null;
        }
    };

    calculateTotalAvailability = async (selectedResourceMixLine, ResourceDetails) => {

        let startDate = null;
        let endDate = null;
        let generalCountry = ResourceDetails ? ResourceDetails.GeneralCountry : null;

        if (selectedResourceMixLine.StartDate) {
            startDate = reStructureDate(selectedResourceMixLine.StartDate);
        } else {
            let calculatedStartDate = await this.calculateLineStartDate(selectedResourceMixLine, generalCountry);
            startDate = reStructureDate(calculatedStartDate);
        }

        if (selectedResourceMixLine.EndDate) {
            endDate = reStructureDate(selectedResourceMixLine.EndDate);
        } else {
            let calculatedEndDate = await this.calculateLineEndDate(selectedResourceMixLine, startDate, generalCountry);
            endDate = reStructureDate(calculatedEndDate);
        }

        let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
        let isHoliday = false;
        let isLeave = false;
        let isHalfDay = false;
        if (selectedResourceMixLine && startDate && endDate && ResourceDetails) {

            if (!selectedResourceMixLine.IsPreSales && selectedResourceMixLine.OnsiteStatus === 'Offshore') { // offshore
                const resourceCountryId = ResourceDetails ? ResourceDetails.GeneralCountry : null;
                let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if (holidays && holidays.length === 0) {
                } else if (!holidays) {
                    holidays = [];
                }

                const resourceId = ResourceDetails ? ResourceDetails.id : null;
                const leaveObj = {
                    ResourceId: resourceId, 
                    StartDate: moment(startDate).format('YYYY-MM-DD'), 
                    EndDate: moment(endDate).format('YYYY-MM-DD')
                }
                let leaves = await getLeavesIntegrated(leaveObj).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

                let loop = new Date(startDate);
                while (loop <= endDate) {
                    isHoliday = false;
                    isLeave = false;
                    isHalfDay = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (leaves && leaves.length > 0) {
                        let leaveDate = leaves.filter(
                            leave => leave.LeaveDate === dateStr
                        );
                        if (leaveDate && leaveDate.length > 0) {
                            for (const leave of leaveDate) {
                                if (leave.Duration === '8') {
                                    isLeave = true;
                                } else {
                                    isHalfDay = true;
                                }
                            }
                        }
                    }

                    if (!isHoliday && !isWeekend(loop) && !isLeave) {
                        let deployabilityHistory = ResourceDetails.ResourceDeployability;
                        let matchingDeployability = Object.values(deployabilityHistory).find(c => new Date(dateStr).getTime() >= new Date(c.StartDate).getTime() && c.EndDate === null  || (new Date(dateStr).getTime() >= new Date(c.StartDate).getTime() && new Date(dateStr).getTime() <= new Date(c.EndDate).getTime()));
                        if (!isHalfDay) {
                            totalWorkingDays = totalWorkingDays + ((1 * parseFloat(matchingDeployability && matchingDeployability.DeployableStatusPercentage ? matchingDeployability.DeployableStatusPercentage : 0))/100);
                        } else {
                            totalWorkingDays = totalWorkingDays + ((0.5 * parseFloat(matchingDeployability && matchingDeployability.DeployableStatusPercentage ? matchingDeployability.DeployableStatusPercentage : 0))/100);
                        }
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

            } else if (!selectedResourceMixLine.IsPreSales && selectedResourceMixLine.OnsiteStatus === 'Onsite') {
                // const resourceCountryId = ResourceDetails ? ResourceDetails.GeneralCountry : null;
                const resourceCountryId = selectedResourceMixLine ? selectedResourceMixLine.projectOrCrCountry : null;
                let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if (holidays && holidays.length === 0) {
                } else if (!holidays) {
                    holidays = [];
                }

                const resourceId = ResourceDetails ? ResourceDetails.id : null;
                const leaveObj = {
                    ResourceId: resourceId, 
                    StartDate: moment(startDate).format('YYYY-MM-DD'), 
                    EndDate: moment(endDate).format('YYYY-MM-DD')
                }
                let leaves = await getLeavesIntegrated(leaveObj).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

                let loop = new Date(startDate);
                while (loop <= endDate) {
                    isHoliday = false;
                    isLeave = false;
                    isHalfDay = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (leaves && leaves.length > 0) {
                        let leaveDate = leaves.filter(
                            leave => leave.LeaveDate === dateStr
                        );
                        if (leaveDate && leaveDate.length > 0) {
                            for (const leave of leaveDate) {
                                if (leave.Duration === '8') {
                                    isLeave = true;
                                } else {
                                    isHalfDay = true;
                                }
                            }
                        }
                    }

                    if (!isHoliday && !isWeekend(loop) && !isLeave) {
                        let deployabilityHistory = ResourceDetails.ResourceDeployability;
                        let matchingDeployability = Object.values(deployabilityHistory).find(c => new Date(dateStr).getTime() >= new Date(c.StartDate).getTime() && c.EndDate === null  || (new Date(dateStr).getTime() >= new Date(c.StartDate).getTime() && new Date(dateStr).getTime() <= new Date(c.EndDate).getTime()));
                        if (!isHalfDay) {
                            totalWorkingDays = totalWorkingDays + ((1 * parseFloat(matchingDeployability && matchingDeployability.DeployableStatusPercentage ? matchingDeployability.DeployableStatusPercentage : 0))/100);
                        } else {
                            totalWorkingDays = totalWorkingDays + ((0.5 * parseFloat(matchingDeployability && matchingDeployability.DeployableStatusPercentage ? matchingDeployability.DeployableStatusPercentage : 0))/100);
                        }
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

            } else if (selectedResourceMixLine.IsPreSales) {
                let resourceCountryId = null;
                if (selectedResourceMixLine.OnsiteStatus === 'Onsite') {
                    resourceCountryId = selectedResourceMixLine ? selectedResourceMixLine.projectOrCrCountry : null;
                } else {
                    resourceCountryId = ResourceDetails ? ResourceDetails.GeneralCountry : null;
                }
                let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if (holidays && holidays.length === 0) {
                } else if (!holidays) {
                    holidays = [];
                }

                const resourceId = ResourceDetails ? ResourceDetails.id : null;
                const leaveObj = {
                    ResourceId: resourceId, 
                    StartDate: moment(startDate).format('YYYY-MM-DD'), 
                    EndDate: moment(endDate).format('YYYY-MM-DD')
                }
                let leaves = await getLeavesIntegrated(leaveObj).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

                let loop = new Date(startDate);
                while (loop <= endDate) {
                    isHoliday = false;
                    isLeave = false;
                    isHalfDay = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (leaves && leaves.length > 0) {
                        let leaveDate = leaves.filter(
                            leave => leave.LeaveDate === dateStr
                        );
                        if (leaveDate && leaveDate.length > 0) {
                            for (const leave of leaveDate) {
                                if (leave.Duration === '8') {
                                    isLeave = true;
                                } else {
                                    isHalfDay = true;
                                }
                            }
                        }
                    }

                    if (!isHoliday && !isWeekend(loop) && !isLeave) {
                        let deployabilityHistory = ResourceDetails.ResourceDeployability;
                        let matchingDeployability = Object.values(deployabilityHistory).find(c => new Date(dateStr).getTime() >= new Date(c.StartDate).getTime() && c.EndDate === null  || (new Date(dateStr).getTime() >= new Date(c.StartDate).getTime() && new Date(dateStr).getTime() <= new Date(c.EndDate).getTime()));
                        if (!isHalfDay) {
                            totalWorkingDays = totalWorkingDays + ((1 * parseFloat(matchingDeployability && matchingDeployability.DeployableStatusPercentage ? matchingDeployability.DeployableStatusPercentage : 0))/100);
                        } else {
                            totalWorkingDays = totalWorkingDays + ((0.5 * parseFloat(matchingDeployability && matchingDeployability.DeployableStatusPercentage ? matchingDeployability.DeployableStatusPercentage : 0))/100);
                        }
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

            }
            return totalWorkingDays;

        } else {
            return null;
        }
    };

    getUtilizeDays = async (type, dataItem, selectedLine) => {

        var ids = [];
        let presales = false;
        let softbooking = false;
        let resourceRequestAllocatedDays = 0;
        let resourceRequestSubLinesAllocatedDays = 0;

        if (dataItem) { ids.push(dataItem.id) }
        else { return null }

        if (type == 'PROJECT') {
            presales = false;
            softbooking = false;
        } else if (type == 'PRESALES') {
            presales = true;
            softbooking = false;
        } else if (type == 'SOFTBOOKING') {
            presales = false;
            softbooking = true;
        }

        await getAllAllocatedLines(ids, presales, softbooking)
            .then(async res => {

                // Check the ResourceRequest
                for (const resourceRequest of res.data.ResourceRequest) {
                    if (resourceRequest.Status !== "EDIT" && resourceRequest.Status !== "RES_ALLOC_REJECTED" && resourceRequest.Status !== "RES_ALLOC_CANCELLED") {
                        resourceRequestAllocatedDays += await this.getAvailableDates(resourceRequest.StartDate, resourceRequest.EndDate, resourceRequest.AllocationPercentage, selectedLine, dataItem, resourceRequest.AllocationPercentageHistory);
                    }
                }

                // Check the ResourceRequestSubLines
                for (const resourceRequestSubLines of res.data.ResourceRequestSubLines) {
                    if (resourceRequestSubLines.Status !== "RES_ALLOC_REJECTED" && resourceRequestSubLines.Status !== "RES_ALLOC_CANCELLED") {
                        resourceRequestSubLinesAllocatedDays += await this.getAvailableDates(resourceRequestSubLines.StartDate, resourceRequestSubLines.EndDate, resourceRequestSubLines.AllocationPercentage, selectedLine, dataItem, resourceRequestSubLines.AllocationPercentageHistory);
                    }
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
            
        return (resourceRequestAllocatedDays + resourceRequestSubLinesAllocatedDays);
    };

    getAvailableDates = async (StartDate, EndDate, allocationPercentage, selectedLine, dataItem, allocationPercentageHistory) => {

        let rStartDate = (StartDate !== null ? moment(StartDate) : null);
        let rEndDate = (EndDate !== null ? moment(EndDate) : null);
        let generalCountry = dataItem ? dataItem.GeneralCountry : null;

        let calculatedStartDate = null;
        if (selectedLine.StartDate) {
            calculatedStartDate = selectedLine.StartDate;
        } else {
            calculatedStartDate = await this.calculateLineStartDate(selectedLine);
        }

        let calculatedEndDate = await this.calculateLineEndDate(selectedLine, calculatedStartDate, generalCountry);
        if (selectedLine.EndDate) {
            calculatedEndDate = selectedLine.EndDate;
        } else {
            calculatedEndDate = await this.calculateLineEndDate(selectedLine, calculatedStartDate, generalCountry);
        }

        let lineStartDate = moment(calculatedStartDate ? calculatedStartDate : null);
        let lineEndDate = moment(calculatedEndDate ? calculatedEndDate : null);

        let confirmedStartDate = null;
        let confirmedEndDate = null;

        if (lineStartDate.diff(rStartDate) >= 0 && lineEndDate.diff(rEndDate) <= 0) {
            //RR start date and end date is out of range
            confirmedStartDate = lineStartDate;
            confirmedEndDate = lineEndDate;
        } else if(lineStartDate.diff(rStartDate) <= 0 && lineEndDate.diff(rEndDate) >= 0) {
            //RR start date and end date is in range
            confirmedStartDate = rStartDate;
            confirmedEndDate = rEndDate;
        } else if((lineStartDate.diff(rStartDate) >= 0 && lineStartDate.diff(rEndDate) <= 0) && lineEndDate.diff(rEndDate) >= 0) {
            //RR start date out of range and end date is in range
            confirmedStartDate = lineStartDate;
            confirmedEndDate = rEndDate;
        } else if((lineEndDate.diff(rStartDate) >= 0 && lineEndDate.diff(rEndDate) <= 0) && lineStartDate.diff(rStartDate) <= 0) {
            //RR start date is in range and end date is out of range
            confirmedStartDate = rStartDate;
            confirmedEndDate = lineEndDate;
        }

        // Get available days without holidays
        var totalAvailableDays = 0;
        if (confirmedStartDate !== null && confirmedEndDate !== null) {
            let copySelectedLine = Object.assign({}, selectedLine);
            copySelectedLine.StartDate = confirmedStartDate;
            copySelectedLine.EndDate = confirmedEndDate;
            totalAvailableDays = await this.calculateTotalAvailableTime(copySelectedLine, allocationPercentage, dataItem, allocationPercentageHistory);
        }

        // Calculate the date diff
        return totalAvailableDays;
    };

    calculateLineStartDate = async (dataItem) => {
        let projectStartDate = null;
        if (dataItem.Type === 'Project'){
            projectStartDate = dataItem.Project.StartDate
        } else{
            projectStartDate = dataItem.ChangeRequest.StartDate
        }
        if(dataItem.StartDate === null || dataItem.StartDate === ''){
            if(dataItem.Phase && dataItem.Phase.StartDate){
                let value = dataItem.Phase;
                if(value.StartDate < projectStartDate){
                    dataItem.StartDate = null;
                    return dataItem.StartDate;
                }
                else {
                    return value.StartDate;
                }
            }
            else if (dataItem.SubPhase && dataItem.SubPhase.StartDate){
                let value = dataItem.SubPhase;
                if(value.StartDate < projectStartDate){
                    dataItem.StartDate = null;
                    return dataItem.StartDate;
                }
                else {
                    return value.StartDate;
                }
            }
            else {
                return projectStartDate;
            }
        } else{
            if(dataItem.StartDate < projectStartDate){
                return dataItem.StartDate;
            } else {
                return dataItem.StartDate;
            }
        }
    }

    calculateLineEndDate = async (dataItem, startDate, generalCountry) => {

        let countryId;
        let totalEffortDays;
        if(dataItem.EndDate === null || dataItem.EndDate === ""){
            totalEffortDays = (dataItem.EffortDays/(dataItem.AllocationPercentage /100));
            if (dataItem.OnsiteStatus === 'Onsite') {
                countryId = dataItem.Type === "Project" ? dataItem.Project.CountryId : dataItem.ChangeRequest.CountryId;
            } else {
                countryId = dataItem.dataValueResourceRegion.DefaultCountryId;
            }
            let { endDate, noOfWorkingDays, totalDays } =  await this.calculateNewEstimatedEndDate(totalEffortDays,moment(startDate).format('YYYY-MM-DD'), generalCountry);
            endDate = moment(endDate).format('YYYY-MM-DD')
            return endDate;
        } else {
            return dataItem.EndDate;
        }
    };

    loadResources = async (event) => {

        if (event) {
            event.preventDefault();
        }

        this.setState({isLoading: true});

        let searchVal = this.state.resourchSearchVal;
        let searchedDataLst = [];

        await searchResource(searchVal)
            .then(async res => {

                for (const item of res.data) {

                    item.totalAvailableTime = await this.calculateTotalAvailability(this.state.resourceSearchCellSelectedDataItm, item);
                    if (item.totalAvailableTime !== 0) {
                        let availableDays = await this.getUtilizeDays('PROJECT', item, this.state.resourceSearchCellSelectedDataItm);
                        item.ProjectUtilization = Number(((Number(availableDays) / parseFloat(item.totalAvailableTime)) * 100).toFixed(2));
                    } else {
                        item.ProjectUtilization = 0;
                    }

                    if (item.totalAvailableTime !== 0) {
                        let availableDays = await this.getUtilizeDays('PRESALES', item, this.state.resourceSearchCellSelectedDataItm);
                        item.PresalesUtilization = Number(((Number(availableDays) / parseFloat(item.totalAvailableTime)) * 100).toFixed(2));
                    } else {
                        item.PresalesUtilization = 0;
                    }
                    if (item.totalAvailableTime !== 0) {
                        let availableDays = await this.getUtilizeDays('SOFTBOOKING', item, this.state.resourceSearchCellSelectedDataItm);
                        item.SoftbookingUtilization = Number(((Number(availableDays) / parseFloat(item.totalAvailableTime)) * 100).toFixed(2));
                    } else {
                        item.SoftbookingUtilization = 0;
                    }

                    if (item.totalAvailableTime !== 0) {
                        let resObj = {
                            resourceId: item.id,
                            resourceCountry: item.GeneralCountry,
                            startDate:  this.state.resourceSearchCellSelectedDataItm.StartDate,
                            endDate: this.state.resourceSearchCellSelectedDataItm.EndDate
                        }

                        await getAllocatedDaysNonProjectTasks(resObj).then(res => {
                            let availableDays = res.data.TotalWorkingDays;
                            item.NonBillableUtilization = Number(((Number(availableDays) / parseFloat(item.totalAvailableTime)) * 100).toFixed(2));
                        })
                       
                    } else {
                        item.NonBillableUtilization = 0;
                    }

                    if (item.totalAvailableTime !== 0) {
                        searchedDataLst.push(item);
                    }

                    item.AllocatedDesignationObj = item.Designation;
                }

                await this.setState({
                    filteredResourceData: searchedDataLst,
                    isLoading: false,
                });

            })
            .catch(error => {
                this.setState({isLoading: false});
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    handleResourceSearchInputChange = event => {
        const field = event.target.name;
        const value = event.target.value;

        this.setState({
            resourchSearchVal: value
        });
    };

    saveAllocatedResourceValidation = async (selectedItem) => {

        await this.setState({
            selectedItemRA: selectedItem
        });

        if (selectedItem) {
            if (selectedItem.EmploymentType == 1 && this.state.resourceSearchCellSelectedDataItm.Designation !== 57) {
                this.toggleConfirmDialog(
                    'The selected resource is an outsourced resource. However, the resource request is for an internal resource. Do you want to proceed?', 
                    this.saveAllocatedResource
                );
            } else if (selectedItem.EmploymentType !== 1 && this.state.resourceSearchCellSelectedDataItm.Designation == 57) {
                this.toggleConfirmDialog(
                    'The selected resource is an internal resource. However, the resource request is for an outsourced resource. Do you want to proceed?', 
                    this.saveAllocatedResource
                );
            } else {
                this.saveAllocatedResource();
            }            
        } else {
            return;
        }
    }

    saveAllocatedResource = async () => {
        const selectedItem = this.state.selectedItemRA;
        var allocatedObj = null;
        let newItem = {};

        if (this.state.showConfirmDialog) {
            this.toggleConfirmDialog('', null);
        }

        if (selectedItem) {
            if (selectedItem.AllocatedDesignationObj && selectedItem.AllocatedDesignationObj.id) {
                allocatedObj = {
                    Name: selectedItem.Name,
                    id: selectedItem.id,
                    DesignationId: selectedItem.AllocatedDesignationObj.id,
                    ResourceRegionName: (selectedItem.ResourceRegion) ? selectedItem.ResourceRegion.Name : '',
                    ResourceRegionId: selectedItem.Region
                };
            } else {
                const message = 'This Resource does not have a Cost Designation. Please ensure the Resource has a Cost Designation before allocating.';
                const title = 'Error';
                await this.setState({
                    showSuccessImage: false,
                    showErrorImage: true
                });
                await this.toggleCostValidatioDialog(message, title);
                return;
            }
            
        } else {
            return;
        }

        // Update the resource mix object
        if (this.state.resourceSearchCellSelectedDataItm) {

            // Check Main lines
            for (const resourcemix of this.state.resourceMix) {
                if (resourcemix.id == this.state.resourceSearchCellSelectedDataItm.id) {

                    if (resourcemix.AllocatedResourceID == null && !resourcemix.InitialTotalCost) {
                        resourcemix.InitialDirectCost = resourcemix.DirectCost;
                        resourcemix.InitialTotalCost = resourcemix.TotalCost;
                        resourcemix.InitialCostPerResource = resourcemix.CostPerResource;
                    }

                    if (resourcemix.Status == 'EDIT') {
                        resourcemix.Status = 3;
                    } else if (resourcemix.Status == 'RES_ALLOC_PENDING') {
                        resourcemix.Status = 7;
                    } else if (resourcemix.Status == 'RES_ALLOC_REJECTED'){
                        resourcemix.Status = 12;
                    } else if (resourcemix.Status == "COMPLETE" && resourcemix.IsSoftBookingResource){
                        resourcemix.Status = 11;
                    }

                    await this.setAllocatedDesignation(allocatedObj);
                    resourcemix.AllocatedResource = allocatedObj;
                    resourcemix.AllocatedResourceID = selectedItem.id;
                    resourcemix.ResourceId = selectedItem.id;
                    resourcemix.AllocatedDesignation = this.state.allocatedDesignation;
                    resourcemix.ResourceRegionName = allocatedObj.ResourceRegionName;
                    resourcemix.AllocatedDesignationId = allocatedObj.DesignationId;

                    if (!resourcemix.IsSoftBookingResource) { // Exclude softbooking

                        let billingRegion;
                        if (resourcemix.Type === 'ChangeRequest') {
                            billingRegion = resourcemix.ChangeRequest.BillingRegion;
                        } else {
                            billingRegion = resourcemix.Project.BillingRegion;
                        }

                        // Load currency for region
                        await getRegionCurrencyIntegrated(billingRegion).then(response => {
                            this.state.newProjectCurrency = response.data;
                        }).catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });

                        // Update designation
                        let selectedDesignation = this.designationsAll.filter(
                            resource => resource.id === allocatedObj.DesignationId
                        );

                        var projOrCrObj = this.state.projectAndCrData.filter(projOrCr => projOrCr.Name == resourcemix.name);
                        let countryId = this.state.resourceSearchCellSelectedDataItm.ChangeRequest ? this.state.resourceSearchCellSelectedDataItm.ChangeRequest.CountryId : this.state.resourceSearchCellSelectedDataItm.Project.CountryId;
                        newItem.newResDesignation = resourcemix.Designation !== 57 ? selectedDesignation[0] : resourcemix.dataValueCostDesignation;
                        newItem.newResProjectOrCRName = { billingRegion: billingRegion, countryId : countryId };
                        newItem.newResRegion = selectedItem.ResourceRegion;
                        newItem.newEffortDays = resourcemix.EffortDays;
                        newItem.newRate = { Rate: 0 };
                        newItem.newStartDate = resourcemix.StartDate ? moment(resourcemix.StartDate).format('YYYY-MM-DD') : null;
                        newItem.newOnSiteStatus = resourcemix.OnsiteStatus;
                        newItem.newEndDate = resourcemix.EndDate ? moment(resourcemix.EndDate).format('YYYY-MM-DD') : null;
                        newItem.newAllocation = resourcemix.AllocationPercentage;
                        newItem.EstimatedEndDate = resourcemix.EndDate ? moment(resourcemix.EndDate).format('YYYY-MM-DD') : null;
                        newItem.resourceCountry = selectedItem.GeneralCountry;
                        newItem.projOrCrCountry = countryId;
                        newItem.resourceId = selectedItem.id;
                        newItem.CostPerOutSourcedResource = resourcemix.Designation == 57 ? (resourcemix.CostPerOutSourcedResource && resourcemix.CostPerOutSourcedResource > 0) ? resourcemix.CostPerOutSourcedResource : resourcemix.InitialCostPerResource : null;

                        // Update Cost details
                        let costToRevenueRatio = 0;
                        newItem = await this.getCostCard(newItem).then(async() => {

                            if (newItem.TotalCost === 0) {
                                resourcemix.ResourceId = null;
                                resourcemix.AllocatedResource = null;
                                resourcemix.AllocatedResourceID = null;
                                resourcemix.AllocatedDesignation = null;
                                resourcemix.AllocatedDesignationId = null;
                                return;
                            }

                            // Update resource mix cost data
                            resourcemix.TotalCost = newItem.TotalCost;
                            resourcemix.DirectCost = newItem.DirectCost;
                            resourcemix.CostPerResource = newItem.CostPerResource;
                            resourcemix.CostVariance = (Number(resourcemix.TotalCost) - Number(resourcemix.InitialTotalCost)).toFixed(2);
                            //update effort after leave adjustment
                            resourcemix.EffortDays = newItem.EffortDays;
                            resourcemix.TotalEffortDays = newItem.EffortDays;
                            resourcemix.TotalRevenue = resourcemix.RateValue ? (Number(resourcemix.RateValue) * newItem.EffortDays) : 0;
                            resourcemix.LeaveCount = newItem.leaveCount;

                            if (Number(resourcemix.TotalRevenue) === 0) {
                                resourcemix.CostToRevenueRatio = 0;
                                newItem.CostToRevenueRatio = 0;
                            } else {
                                costToRevenueRatio = (Number(resourcemix.TotalCost / resourcemix.TotalRevenue).toFixed(2));
                                resourcemix.CostToRevenueRatio = costToRevenueRatio;
                                newItem.CostToRevenueRatio = costToRevenueRatio;
                            }

                            if(newItem.leaveCount > 0){
                                //toggle message
                                const message = 'This resource has requested for a leave during the assignment period, the effort is recalculated accordingly';
                                const title = 'Leave Adjustment';

                                await this.toggleMessageDialog(message, title);
                            }
                        });
                    }

                    if (resourcemix.IsSoftBookingResource) { // check softbooking lines

                        let billingRegion;
                        if (resourcemix.Type === 'ChangeRequest') {
                            billingRegion = resourcemix.ChangeRequest.BillingRegion;
                        } else {
                            billingRegion = resourcemix.Project.BillingRegion;
                        }

                        // Load currency for region
                        await getRegionCurrencyIntegrated(billingRegion).then(response => {
                            this.state.newProjectCurrency = response.data;
                        }).catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });

                        // Update designation
                        let selectedDesignation = this.designationsAll.filter(
                            resource => resource.id === resourcemix.Designation
                        );

                        var projOrCrObj = this.state.projectAndCrData.filter(projOrCr => projOrCr.Name == resourcemix.name);
                        let countryId = this.state.resourceSearchCellSelectedDataItm.ChangeRequest ? this.state.resourceSearchCellSelectedDataItm.ChangeRequest.CountryId : this.state.resourceSearchCellSelectedDataItm.Project.CountryId;
                        newItem.newResDesignation = selectedDesignation[0];
                        newItem.newResProjectOrCRName = { billingRegion: billingRegion, countryId : countryId };
                        newItem.newResRegion = resourcemix.dataValueResourceRegion; 
                        newItem.newEffortDays = resourcemix.EffortDays;
                        newItem.newRate = { Rate: 0 };
                        newItem.newStartDate = resourcemix.StartDate ? moment(resourcemix.StartDate).format('YYYY-MM-DD') : null;
                        newItem.newOnSiteStatus = resourcemix.OnsiteStatus;
                        newItem.newEndDate = resourcemix.EndDate ? moment(resourcemix.EndDate).format('YYYY-MM-DD') : null;
                        newItem.newAllocation = resourcemix.AllocationPercentage;
                        newItem.EstimatedEndDate = resourcemix.EndDate ? moment(resourcemix.EndDate).format('YYYY-MM-DD') : null;
                        newItem.resourceCountry = selectedItem.GeneralCountry;
                        newItem.projOrCrCountry = countryId;
                        newItem.resourceId = selectedItem.id;
                        newItem.CostPerOutSourcedResource = resourcemix.Designation == 57 ? (resourcemix.CostPerOutSourcedResource && resourcemix.CostPerOutSourcedResource > 0) ? resourcemix.CostPerOutSourcedResource : resourcemix.InitialCostPerResource : null;

                        // Update Cost details
                        let costToRevenueRatio = 0;
                        newItem = await this.getCostCard(newItem).then(async() => {
                            if (!(newItem.newResDesignation && newItem.newResDesignation.id === 57)) {
                                if (newItem.TotalCost === 0) {
                                    resourcemix.ResourceId = null;
                                    resourcemix.AllocatedResource = null;
                                    resourcemix.AllocatedResourceID = null;
                                    resourcemix.AllocatedDesignation = null;
                                    resourcemix.AllocatedDesignationId = null;
                                    return;
                                }

                                // Update resource mix cost data
                                resourcemix.TotalCost = newItem.TotalCost;
                                resourcemix.DirectCost = newItem.DirectCost;
                                resourcemix.CostPerResource = newItem.CostPerResource;
                                //update effort after leave adjustment
                                resourcemix.EffortDays = newItem.EffortDays;
                                resourcemix.TotalEffortDays = newItem.EffortDays;
                                resourcemix.TotalRevenue = resourcemix.RateValue ? (Number(resourcemix.RateValue) * newItem.EffortDays) : 0;
                                resourcemix.LeaveCount = newItem.leaveCount;

                                if (Number(resourcemix.TotalRevenue) === 0) {
                                    resourcemix.CostToRevenueRatio = 0;
                                    newItem.CostToRevenueRatio = 0;
                                } else {
                                    costToRevenueRatio = (Number(resourcemix.TotalCost / resourcemix.TotalRevenue).toFixed(2));
                                    resourcemix.CostToRevenueRatio = costToRevenueRatio;
                                    newItem.CostToRevenueRatio = costToRevenueRatio;
                                }

                                if(newItem.leaveCount > 0){
                                    //toggle message
                                    const message = 'This resource has requested for a leave during the assignment period, the effort is recalculated accordingly';
                                    const title = 'Leave Adjustment';

                                    await this.toggleMessageDialog(message, title);
                                }
                            }
                        });
                    }

                } else if (resourcemix.ResourceRequestSubLine && resourcemix.ResourceRequestSubLine.length > 0) { // Check Sub lines
                    for (const subline of resourcemix.ResourceRequestSubLine) {
                        if (subline.id == this.state.resourceSearchCellSelectedDataItm.id) {

                            if (subline.ResourceId == null && !subline.InitialCost) {
                                subline.InitialCost = subline.Cost;
                                subline.InitialDirectCost = subline.DirectCost;
                                subline.InitialCostPerResource = subline.CostPerResource;
                            }

                            subline.ResourceRegionName = allocatedObj.ResourceRegionName;

                            if (subline.Status == 'EDIT') {
                                subline.Status = 3;
                            } else if (subline.Status == 'RES_ALLOC_PENDING') {
                                subline.Status = 7;
                            } else if (subline.Status == 'RES_ALLOC_REJECTED'){
                                subline.Status = 12;
                            } else if (subline.Status == "COMPLETE" && subline.IsSoftBookingResource ){
                                subline.Status = 11;
                            }

                            subline.ResourceId = selectedItem.id;
                            subline.AllocatedDesignationId = allocatedObj.DesignationId;

                            if (!subline.IsSoftBookingResource) {

                                let billingRegion;
                                if (resourcemix.Type === 'ChangeRequest') {
                                    billingRegion = resourcemix.ChangeRequest.BillingRegion;
                                } else {
                                    billingRegion = resourcemix.Project.BillingRegion;
                                }

                                // Load currency for region
                                await getRegionCurrencyIntegrated(billingRegion).then(response => {
                                    this.state.newProjectCurrency = response.data;
                                }).catch(error => {
                                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                                });

                                // Update designation
                                let selectedDesignation = this.designationsAll.filter(
                                    resource => resource.id === allocatedObj.DesignationId
                                );

                                let countryId = (resourcemix.Type == "ChangeRequest") ? resourcemix.ChangeRequest.CountryId : resourcemix.Project.CountryId;

                                newItem.newResDesignation = subline.Designation !== 57 ? selectedDesignation[0] : subline.dataValueCostDesignation;
                                newItem.newResProjectOrCRName = { billingRegion: billingRegion, countryId: countryId };
                                newItem.newResRegion = selectedItem.ResourceRegion;
                                newItem.newEffortDays = subline.EffortDays;
                                newItem.newRate = { Rate: 0 };
                                newItem.newStartDate = subline.StartDate ? moment(subline.StartDate).format('YYYY-MM-DD') : null;
                                newItem.newOnSiteStatus = subline.OnsiteStatus;
                                newItem.newEndDate = subline.EndDate ? moment(subline.EndDate).format('YYYY-MM-DD') : null;
                                newItem.newAllocation = subline.AllocationPercentage;
                                newItem.EstimatedEndDate = subline.EndDate ? moment(subline.EndDate).format('YYYY-MM-DD') : null;
                                newItem.resourceCountry = selectedItem.GeneralCountry;
                                newItem.projOrCrCountry = countryId;
                                newItem.resourceId = selectedItem.id;
                                newItem.CostPerOutSourcedResource = subline.Designation == 57 ? (subline.CostPerOutSourcedResource && subline.CostPerOutSourcedResource > 0) ? subline.CostPerOutSourcedResource : subline.InitialCostPerResource : null;

                                // Update Cost details
                                let costToRevenueRatio = 0;
                                newItem = await this.getCostCard(newItem).then(async() => {

                                    if (newItem.TotalCost === 0) {
                                        subline.ResourceId = null;
                                        subline.AllocatedDesignationId = null;
                                        return;
                                    }

                                    // Update resource mix cost data
                                    subline.Cost = newItem.TotalCost;
                                    subline.DirectCost = newItem.DirectCost;
                                    subline.CostPerResource = newItem.CostPerResource;
                                    subline.CostVariance = (Number(subline.Cost) - Number(subline.InitialCost)).toFixed(2);
                                    //update effort after leave adjustment
                                    subline.EffortDays = newItem.EffortDays;
                                    subline.Revenue = subline.RateValue ? (Number(subline.RateValue) * newItem.EffortDays) : 0;
                                    subline.LeaveCount = newItem.leaveCount;

                                    if (Number(subline.Revenue) === 0) {
                                        newItem.CostToRevenueRatio = 0;
                                        subline.CostToRevenueRatio = 0;
                                    } else {
                                        costToRevenueRatio = (Number(newItem.TotalCost / subline.Revenue).toFixed(2));
                                        newItem.CostToRevenueRatio = costToRevenueRatio;
                                        subline.CostToRevenueRatio = costToRevenueRatio;
                                    }

                                    if(newItem.leaveCount > 0){
                                        //toggle message
                                        const message = 'This resource has requested for a leave during the assignment period, the effort is recalculated accordingly';
                                        const title = 'Leave Adjustment';

                                        await this.toggleMessageDialog(message, title);
                                    }
                                });
                            }
                            if (subline.IsSoftBookingResource) {

                                let billingRegion;
                                if (resourcemix.Type === 'ChangeRequest') {
                                    billingRegion = resourcemix.ChangeRequest.BillingRegion;
                                } else {
                                    billingRegion = resourcemix.Project.BillingRegion;
                                }

                                // Load currency for region
                                await getRegionCurrencyIntegrated(billingRegion).then(response => {
                                    this.state.newProjectCurrency = response.data;
                                }).catch(error => {
                                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                                });

                                // Update designation
                                let selectedDesignation = this.designationsAll.filter(
                                    resource => resource.id === subline.Designation
                                );

                                let countryId = (resourcemix.Type == "ChangeRequest") ? resourcemix.ChangeRequest.CountryId : resourcemix.Project.CountryId;

                                newItem.newResDesignation = selectedDesignation[0];
                                newItem.newResProjectOrCRName = { billingRegion: billingRegion, countryId: countryId };
                                newItem.newResRegion = subline.dataValueResourceRegion;
                                newItem.newEffortDays = subline.EffortDays;
                                newItem.newRate = { Rate: 0 };
                                newItem.newStartDate = subline.StartDate ? moment(subline.StartDate).format('YYYY-MM-DD') : null;
                                newItem.newOnSiteStatus = subline.OnsiteStatus;
                                newItem.newEndDate = subline.EndDate ? moment(subline.EndDate).format('YYYY-MM-DD') : null;
                                newItem.newAllocation = subline.AllocationPercentage;
                                newItem.EstimatedEndDate = subline.EndDate ? moment(subline.EndDate).format('YYYY-MM-DD') : null;
                                newItem.resourceCountry = selectedItem.GeneralCountry;
                                newItem.projOrCrCountry = countryId;
                                newItem.resourceId = selectedItem.id;
                                newItem.CostPerOutSourcedResource = subline.Designation == 57 ? (subline.CostPerOutSourcedResource && subline.CostPerOutSourcedResource > 0) ? subline.CostPerOutSourcedResource : subline.InitialCostPerResource : null;

                                // Update Cost details
                                let costToRevenueRatio = 0;
                                newItem = await this.getCostCard(newItem).then(async() => {
                                    if (!(newItem.newResDesignation && newItem.newResDesignation.id === 57)) {
                                        if (newItem.TotalCost === 0) {
                                            subline.ResourceId = null;
                                            subline.AllocatedDesignationId = null;
                                            return;
                                        }

                                        // Update resource mix cost data
                                        subline.Cost = newItem.TotalCost;
                                        subline.DirectCost = newItem.DirectCost;
                                        subline.CostPerResource = newItem.CostPerResource;
                                        //update effort after leave adjustment
                                        subline.EffortDays = newItem.EffortDays;
                                        subline.Revenue = subline.RateValue ? (Number(subline.RateValue) * newItem.EffortDays) : 0;
                                        subline.LeaveCount = newItem.leaveCount;

                                        if (Number(subline.Revenue) === 0) {
                                            newItem.CostToRevenueRatio = 0;
                                            subline.CostToRevenueRatio = 0;
                                        } else {
                                            costToRevenueRatio = (Number(newItem.TotalCost / subline.Revenue).toFixed(2));
                                            newItem.CostToRevenueRatio = costToRevenueRatio;
                                            subline.CostToRevenueRatio = costToRevenueRatio;
                                        }

                                        if(newItem.leaveCount > 0){
                                            //toggle message
                                            const message = 'This resource has requested for a leave during the assignment period, the effort is recalculated accordingly';
                                            const title = 'Leave Adjustment';

                                            await this.toggleMessageDialog(message, title);
                                        }
                                    }
                                });
                            }
                        }
                    }
                }

            }
        } else {
            return;
        }

        for (const resourcemix of this.state.resourceMix) {

            if (resourcemix.Status == 'EDIT') {
                resourcemix.Status = 3;
            } else if (resourcemix.Status == 'RES_ALLOC_PENDING') {
                resourcemix.Status = 7;
            } else if (resourcemix.Status == 'RES_ALLOC_REJECTED'){
                resourcemix.Status = 12;
            } else if (resourcemix.Status == "COMPLETE" && resourcemix.IsSoftBookingResource){
                resourcemix.Status = 11;
            }

            if (resourcemix.ResourceRequestSubLine && resourcemix.ResourceRequestSubLine.length > 0) {
                for (const subline of resourcemix.ResourceRequestSubLine) {
                    if (subline.Status == 'EDIT') {
                        subline.Status = 3;
                    } else if (subline.Status == 'RES_ALLOC_PENDING') {
                        subline.Status = 7;
                    } else if (subline.Status == 'RES_ALLOC_REJECTED'){
                        subline.Status = 12;
                    } else if (subline.Status == "COMPLETE" && subline.IsSoftBookingResource){
                        subline.Status = 11;
                    }
                }
            }
        }

        let allocatedLines = [];
        let deallocatedLines = [];
        for (const resourcemix of this.state.resourceMix) {
            if(resourcemix.Status === 11 && resourcemix.id == this.state.resourceSearchCellSelectedDataItm.id){
                if(resourcemix.IsSoftBookingResource) {
                    // Allocated line details
                    let tmpAry = {
                        LineId: resourcemix.id,
                        ResourceId: resourcemix.AllocatedResourceID,
                        Type: 'MainLine'
                    };
                    allocatedLines.push(tmpAry);

                    // Deallocated line details
                    let tmpDeallocateAry = {
                        LineId: resourcemix.id,
                        ResourceId: resourcemix.softbookingPreviousResource,
                        Type: 'MainLine'
                    };
                    deallocatedLines.push(tmpDeallocateAry);                    
                    resourcemix.softbookingPreviousResource = resourcemix.AllocatedResourceID; // to track the previous resource

                } 
                else if (resourcemix.softbookingPreviousResource !== null) { // Track the deallocated/reallocated lines
                    if (resourcemix.softbookingPreviousResource !== resourcemix.AllocatedResourceID) {
                        // Allocated line details
                        let tmpAry = {
                            LineId: resourcemix.id,
                            ResourceId: resourcemix.AllocatedResourceID,
                            Type: 'MainLine'
                        };
                        allocatedLines.push(tmpAry);

                        // Deallocated line details
                        let tmpDeallocateAry = {
                            LineId: resourcemix.id,
                            ResourceId: resourcemix.softbookingPreviousResource,
                            Type: 'MainLine'
                        };
                        deallocatedLines.push(tmpDeallocateAry);
                    }
                }
            }

            if (resourcemix.ResourceRequestSubLine && resourcemix.ResourceRequestSubLine.length > 0) {
                for (const subline of resourcemix.ResourceRequestSubLine) {
                    if(subline.Status === 11 && subline.id == this.state.resourceSearchCellSelectedDataItm.id){
                         // Track the allocated lines
                         if (subline.IsSoftBookingResource) {

                            // Allocated line details
                            let tmpAry = {
                                LineId: subline.id,
                                ResourceId: subline.ResourceId,
                                Type: 'SubLine'
                            };
                            allocatedLines.push(tmpAry);

                            // Deallocated line details
                            let tmpDeallocateAry = {
                                LineId: subline.id,
                                ResourceId: subline.softbookingPreviousResource,
                                Type: 'SubLine'
                            };
                            deallocatedLines.push(tmpDeallocateAry);
                            subline.softbookingPreviousResource = subline.ResourceId; // to track the previous resource

                        } 
                        else if (subline.softbookingPreviousResource !== null) { // Track the deallocated/reallocated lines
                            if (subline.softbookingPreviousResource !== subline.ResourceId) {
                                // Allocated line details
                                let tmpAry = {
                                    LineId: subline.id,
                                    ResourceId: subline.ResourceId,
                                    Type: 'SubLine'
                                };
                                allocatedLines.push(tmpAry);

                                // Deallocated line details
                                let tmpDeallocateAry = {
                                    LineId: subline.id,
                                    ResourceId: subline.softbookingPreviousResource,
                                    Type: 'SubLine'
                                };
                                deallocatedLines.push(tmpDeallocateAry);
                            }
                        }
                    }
                }
            }
        }
        
        // Save allocation
        await this.handleResourceSave(this.state.resourceMix);

        if(allocatedLines.length > 0 || deallocatedLines.length > 0){
            for(let allocated of allocatedLines){
                let index = allocatedLines.indexOf(allocated);
                if(allocated.ResourceId == deallocatedLines[index].ResourceId ){
                    if (index > -1) {
                        allocatedLines.splice(index, 1);
                        deallocatedLines.splice(index, 1);
                    }
                }
            }
            let allocationAry = [];
            allocationAry.push(allocatedLines);
            allocationAry.push(deallocatedLines);
            if(allocationAry.length > 0){
                await sendAllocationDeallocationMails(allocationAry);
            }
        }

        this.setState({
            visible: !this.state.visible,
            resourchSearchVal: "",
            resourceSearchCellSelectedDataItm: null,
            filteredResourceData: []
        });
    };

    toggleIsRevenuePresent = async (dataItem) => {

        let editableItem = this.state.editableItem;
        if (editableItem && editableItem.ResourceRequestSubLine.length > 0) {
            for (var item of editableItem.ResourceRequestSubLine) {
                if (item.index == dataItem.index) {
                    item.IsRevenuePresent = dataItem.IsRevenuePresent;
                }
            }
        }

        this.setState({
            editableItem: editableItem
        });

    };

    render() {
        if (this.state.goBack === true) {
            return <Redirect to="/projects/search/search" />;
        }

        if (!this.props.location.hasOwnProperty('project')) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <div className="main-card">

                    <TabStrip selected={this.state.selected} onSelect={this.handleSelect}>

                        <TabStripTab title="Summary">
                            <Loader loading={this.state.isLoading} />

                            <div className="row">

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">Project Name:</label></div>
                                        <div className="col-md-7"><label
                                            className="font-weight-normal">{this.state.project ? this.state.project.ProjectName : ''}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">Project ID:</label></div>
                                        <div className="col-md-7"><label
                                            className="font-weight-normal">{this.state.project ? this.state.project.UniqueId : ''} {this.getProjCMS() ? '(' + this.getProjCMS() + ')' : ''}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">Start Date:</label></div>
                                        <div className="col-md-7"><label className="font-weight-normal">{this.getStartDate()}</label></div>
                                    </div>
                                </div>

                                {/* End Date */}
                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">End Date:</label></div>
                                        <div className="col-md-7">
                                            {this.props.location.action === 'RPApprove' && (
                                                <label className="font-weight-normal">{this.state.userDefinedEndDate !== null ? moment(this.state.userDefinedEndDate).format('MM-DD-YYYY') : ''}</label>
                                            )}
                                            {this.props.location.action !== 'RPApprove' && (
                                                <DatePicker
                                                    format="MM/dd/yyyy"
                                                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                                    value={this.state.userDefinedEndDate}
                                                    onChange={this.handleProjEndDateChange}
                                                    name="projEndDate"
                                                    min={reStructureDate(this.getMaxEndDate())}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="resc-separator"></div>

                            <div className="row">

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">Billing Region:</label></div>
                                        <div className="col-md-7"><label
                                            className="font-weight-normal">{this.state.project && this.state.project.Region ? this.state.project.Region.Name : ''}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">Country:</label></div>
                                        <div className="col-md-7"><label
                                            className="font-weight-normal">{this.state.project && this.state.project.Country ? this.state.project.Country.Name : ''}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">Customer:</label></div>
                                        <div className="col-md-7"><label
                                            className="font-weight-normal">{this.state.project && this.state.project.Customer? this.state.project.Customer.Name : ''}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">OP ID:</label></div>
                                        <div className="col-md-7"><label
                                            className="font-weight-normal">{this.state.project ? this.state.project.OPID : ''}</label></div>
                                    </div>
                                </div>

                                {/* <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">CR Name:</label></div>
                                        <div className="col-md-7"><label className="font-weight-normal">{this.getCRNames()}</label></div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">CR ID:</label></div>
                                        <div className="col-md-7"><label className="font-weight-normal">{this.getCRIDs()}</label></div>
                                    </div>
                                </div> */}
                            </div>

                            <div className="resc-separator"></div>

                            <div className="row">


                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">Department:</label></div>
                                        <div className="col-md-7"><label
                                            className="font-weight-normal">{this.state.project && this.state.project.Division ? this.state.project.Division.Name : ''}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">Engagement Type:</label></div>
                                        <div className="col-md-7"><label
                                            className="font-weight-normal">{this.state.project && this.state.project.Type ? this.state.project.Type.Name : ''}</label>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">Offering:</label></div>
                                        <div className="col-md-7"><label
                                            className="font-weight-normal">{this.state.project && this.state.project.Offering ? this.state.project.Offering.Name : ''}</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">Billing Method:</label></div>
                                        <div className="col-md-7"><label
                                            className="font-weight-normal">{this.state.project && this.state.project.PaymentMethodRecord ? this.state.project.PaymentMethodRecord.Name : ''}</label>
                                        </div>
                                    </div>
                                </div>


                                {/* <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">CMS version:</label></div>
                                        <div className="col-md-7"><label className="font-weight-normal">{this.getVersions()}</label></div>
                                    </div>
                                </div> */}
                            </div>

                            <div className="resc-separator"></div>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">Request Type:</label></div>
                                        <div className="col-md-7"><label
                                            className="font-weight-normal">{this.state.requestType ? this.state.requestType : ''}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="resc-separator"></div>

                            {this.state.activeCRs.length > 0 && (
                                <div className="row mt-4">
                                    <div className="col-md-12 crdetails-grid">
                                        <Grid data={this.state.activeCRs}
                                            resizable
                                            total={this.state.activeCRs.length}
                                        >
                                            <Column field="CRName" title="CR Name" />
                                            <Column field="UniqueId" title="CR ID" />
                                            <Column field="cmsVersion" title="CMS Version" />
                                        </Grid>
                                    </div>
                                </div>
                            )}


                            {/*<div className="row mt-3 mb-3">*/}
                            {/*  {this.props.location.action !== 'RPApprove' &&*/}
                            {/*  <div className="col-md-12 btn-align-left">*/}
                            {/*    <Button type="button" primary={true} look="outline" onClick={this.toggleProjectPhase}>Add Project*/}
                            {/*      Phases</Button>*/}
                            {/*    <Button type="button" primary={true} look="outline" onClick={this.toggleProjectSubPhase}>Add Project*/}
                            {/*      Sub Phases</Button>*/}
                            {/*  </div>*/}
                            {/*  }*/}
                            {/*</div>*/}

                            {/*{this.state.phasesSubPhases.length > 0 && (*/}
                            {/*  <div className="row">*/}
                            {/*    <div className="col-md-12">*/}
                            {/*      <Grid data={this.state.phasesSubPhases}*/}
                            {/*            resizable*/}
                            {/*            total={this.state.phasesSubPhases.length}*/}

                            {/*      >*/}
                            {/*        <Column*/}
                            {/*          field=""*/}
                            {/*          title="Type"*/}
                            {/*          cell={props => (*/}
                            {/*            <ResourceRequestTypeCell*/}
                            {/*              {...props}*/}
                            {/*            />*/}
                            {/*          )}*/}
                            {/*        />*/}
                            {/*        <Column*/}
                            {/*          field=""*/}
                            {/*          title="Name"*/}
                            {/*          cell={props => (*/}
                            {/*            <ResourceRequestNameCell*/}
                            {/*              {...props}*/}
                            {/*            />*/}
                            {/*          )}*/}
                            {/*        />*/}
                            {/*        <Column*/}
                            {/*          field=""*/}
                            {/*          title="Phase"*/}
                            {/*          cell={props => (*/}
                            {/*            <ResourceRequestPhaseCell*/}
                            {/*              {...props}*/}
                            {/*            />*/}
                            {/*          )}*/}
                            {/*        />*/}
                            {/*        <Column field="phaseStartDate" title="Start Date"/>*/}
                            {/*        <Column field="SubPhase_name" title="Sub-Phase"/>*/}
                            {/*        <Column field="subPhaseStartDate" title="Start Date"/>*/}
                            {/*        {this.props.location.action === 'RPApprove' &&*/}
                            {/*        <Column*/}
                            {/*          field=""*/}
                            {/*          title="Action"*/}
                            {/*          cell={props => (*/}
                            {/*            <ResourceRequestNameCell*/}
                            {/*              {...props}*/}
                            {/*            />*/}
                            {/*          )}*/}
                            {/*        />*/}
                            {/*        }*/}

                            {/*      </Grid>*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                            {/*)}*/}

                            <div className="main-seperator"></div>

                            <div className="row mt-4 resource-chart">
                                <div className="col-md-12 sub-chart-section">
                                    <Grid width="100%" data={this.state.calculations} rowRender={this.rowRender}
                                        className="summary-grid" resizable={true}>
                                        <Column
                                            field="Field"
                                            title="Field"
                                            width="250px"
                                            headerClassName="calculation-grid-header"
                                        />
                                        <Column className="col-head" field="Value1" width="130px"
                                            headerClassName="calculation-grid-header" />
                                        <Column
                                            field="Value2"
                                            headerClassName="calculation-grid-header" className="font-weight-bold"
                                        />
                                        <Column className="col-head" field="Value3" width="130px" cell={ResourceRequestValueThreeCell}
                                            headerClassName="calculation-grid-header" />
                                        <Column field="Value4" cell={ResourceRequestValueFourCell} headerClassName="calculation-grid-header" />
                                        <Column className="col-head" field="Value5"
                                            headerClassName="calculation-grid-header" />
                                        <Column field="Value6" headerClassName="calculation-grid-header" />
                                        <Column className="col-head" field="Value7" headerClassName="calculation-grid-header" cell={this.formatValueSevenCell}/>
                                        <Column className="col-head" field="Value8" headerClassName="calculation-grid-header" cell={this.formatValueEightCell} />
                                        <Column field="Value9" headerClassName="calculation-grid-header" />
                                        <Column field="Value10" headerClassName="calculation-grid-header" />
                                    </Grid>
                                </div>
                            </div>

                            {this.state.projectPhaseVisible === true && (
                                <div>
                                    <Dialog title={'Define Project Phases'} onClose={this.toggleClosePhaseDialog} width={600}>
                                        <div className="row">
                                            <div className="col-md-6 pro-phases">
                                                <div className="">
                                                    <div className="d-block">
                                                        <label htmlFor="">Project/CR:</label>
                                                    </div>
                                                    <div className="d-block pr-0">
                                                        <ComboBox
                                                            name="searchStatus"
                                                            textField="Name"
                                                            dataItemKey="id"
                                                            value={this.state.selectedProjecOrCrForPhase}
                                                            data={this.state.projectAndCrData}
                                                            placeholder={'Please Select'}
                                                            onChange={this.handleOnChangeInput}
                                                            disabled={this.state.isEditButton}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="d-block">
                                                        <label htmlFor="">Phase Name:</label>
                                                    </div>
                                                    <div className="d-block pr-0">
                                                        <Input
                                                            value={this.state.phaseName}
                                                            onChange={this.handlePhaseNameInputChange}
                                                            name="phaseName"
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="d-block">
                                                        <label htmlFor="">Start Date:</label>
                                                    </div>
                                                    <div className="d-block pr-0">
                                                        <DatePicker
                                                            disabled={!this.state.selectedProjecOrCrForPhase}
                                                            value={this.state.phaseStartDate}
                                                            width="99%"
                                                            onChange={this.handlePhaseStartDateChange}
                                                            name="startDate"
                                                            min={this.state.minPhaseDate}
                                                            format="MM/dd/yyyy"
                                                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-7 phase-button">
                                                        <Button
                                                            className="k-button project-phase"
                                                            disabled={!this.state.phaseName || !this.state.selectedProjecOrCrForPhase}
                                                            onClick={this.addPhase}
                                                        > Add phase
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 overflow-auto pb-2">
                                                {this.state.phasesTemp.map((value, index) => {
                                                    return (
                                                        <PhaseRow
                                                            phase={value}
                                                            isEditPhases={true}
                                                            isEditSubPhases={false}
                                                            onRemovePhase={() => this.handlePhasesRemove(index, value)}
                                                            onEditPhase={() => this.EditPhase(index, value)}
                                                            key={index}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <DialogActionsBar>
                                            <button
                                                className="k-button"
                                                disabled={!(this.state.isAddDone)}
                                                onClick={this.handlePhasesSave}
                                            >
                                                Save
                                            </button>
                                            <button className="k-button modal-primary" onClick={this.handleCancel}>
                                                Cancel
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                </div>
                            )}

                            {this.state.projectSubPhaseVisible && (
                                <div>
                                    <Dialog title={'Define Project Sub-Phases'} onClose={this.toggleCloseSubPhaseDialog} width={600}>
                                        <div className="row">
                                            <div className="col-md-6 pro-subphase">
                                                <div className="">
                                                    <div className="d-block">
                                                        <label htmlFor="">Project/CR:</label>
                                                    </div>
                                                    <div className="d-block pr-0">
                                                        <ComboBox
                                                            name="searchStatus"
                                                            textField="Name"
                                                            dataItemKey="id"
                                                            value={this.state.selectedProjecOrCrForPhase}
                                                            data={this.state.projectAndCrData}
                                                            placeholder={'Please Select'}
                                                            onChange={this.handleProjectCRChangeForSubPhase}
                                                            disabled={this.state.isEditButton}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="d-block">
                                                        <label for="">Sub-Phase Name:</label>
                                                    </div>
                                                    <div className="d-block pr-0">
                                                        <Input
                                                            value={this.state.subPhaseName}
                                                            onChange={this.handleSubPhaseNameInputChange}
                                                            name="subPhaseName"
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <div className="d-block">
                                                        <label for="">Phase:</label>
                                                    </div>
                                                    <div className="d-block pr-0">
                                                        <DropDownList
                                                            value={this.state.selectedPhaseIndex === -1 ? '' : this.state.selectedProjectOrCrPhases[this.state.selectedPhaseIndex]}
                                                            data={this.state.selectedProjectOrCrPhases}
                                                            textField="Text"
                                                            dataItemKey="id"
                                                            onChange={this.handlePhaseChange}
                                                            disabled={this.state.isEditButton}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <div className="d-block">
                                                        <label for="">Start Date:</label>
                                                    </div>
                                                    <div className="d-block pr-0">
                                                        <DatePicker
                                                            value={this.state.subPhaseStartDate}
                                                            disabled={!this.state.selectedProjecOrCrForPhase}
                                                            width="99%"
                                                            onChange={this.handleSubPhaseStartDateChange}
                                                            name="startDate"
                                                            min={this.state.minSubPhaseDate}
                                                            format="MM/dd/yyyy"
                                                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-7 phase-button">
                                                        <Button
                                                            className="k-button project-phase"
                                                            disabled={!this.state.subPhaseName || !this.state.selectedPhaseIndex < 0}
                                                            onClick={this.addSubPhase}
                                                        > Add sub-phase
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-12 overflow-auto pb-2">
                                                    {this.state.selectedProjectOrCrPhases && this.state.selectedProjectOrCrPhases.map((value, index) => {
                                                        return (
                                                            <PhaseRow
                                                                phase={value}
                                                                isEditPhases={false}
                                                                isEditSubPhases={true}
                                                                onRemoveSubPhase={this.handleSubPhasesRemove}
                                                                onEditSubPhase={this.EditSubPhase}
                                                                key={index}
                                                                phaseIndex={index}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                        <DialogActionsBar>
                                            <button
                                                className="k-button"
                                                disabled={!this.state.isAddDone}
                                                onClick={this.handleSubPhaseSave}
                                            >
                                                Save
                                            </button>
                                            <button className="k-button modal-primary" onClick={this.handleCancelSubPhase}>
                                                Cancel
                                            </button>
                                        </DialogActionsBar>
                                    </Dialog>
                                </div>
                            )}

                        </TabStripTab>

                        <TabStripTab title="Resource Mix">
                            <Loader loading={this.state.isLoading} />
                            {this.props.location.action !== 'RPApprove' && this.props.location.action !== 'RPAllocateWF' && (
                                <>
                                    <PanelBar className="resourcemix-search">
                                        <PanelBarItem expanded={true} title={<div className="filter-timesheet"> Resource Mix Filter</div>}>
                                            <div className="col-md-12 row mb-4 resreqresmix">
                                                <div className="col-md-4">
                                                    <div className="d-block">
                                                        <div className=""><label>Project / CR:</label></div>
                                                    </div>
                                                    <div className="d-block">
                                                        <div className="">
                                                            <MultiSelect
                                                                name="newResProjectOrCRNames"
                                                                data={this.state.allocatedProjectManager === true ? this.state.namesAll : this.state.crObj}
                                                                textField="name"
                                                                dataItemKey="id"
                                                                value={this.state.newResProjectOrCRNames}
                                                                filterable={true}
                                                                onFilterChange={this.filter}
                                                                onChange={this.handleOnChangeDropDown}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-8">
                                                    <div className="row">
                                                        <div className="col-md-10">
                                                            <div className="d-block">
                                                                <div className=""><label>Status:</label></div>
                                                            </div>
                                                            <div className="d-block">
                                                                <div className="">
                                                                    <MultiSelect
                                                                        data={this.state.LineStatus}
                                                                        textField="name"
                                                                        dataItemKey="value"
                                                                        value={this.state.SelectedLineStatuses}
                                                                        onChange={this.handleOnChangeDropDown}
                                                                        name="SelectedLineStatuses"
                                                                        placeholder="Please Select"
                                                                        filterable={true}
                                                                        onFilterChange={this.filter}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 mt-auto">
                                                            <button className="k-button k-primary" onClick={this.submitSearch}>Filter</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-2 mt-auto">
                    <div className="align-middle">
                    <button className="k-button k-primary" onClick={this.submitSearch}>Filter</button>
                    </div> */}
                                                {/* {this.state.workflowDialog && <Dialog title={"Resource Line Workflow"} onClose={this.toggleWorkflow}>
                                        <div className="col-md-12">
                                            <Grid style={{ height: '200px' }}
                                                resizable
                                            >
                                                <Column
                                                    field=""
                                                    headerCell={props => <label>Action</label>}
                                                    width="120px"
                                                    maxLength={10}
                                                />
                                                <Column
                                                    field=""
                                                    headerCell={props => <label>Date and Time</label>}
                                                    width="120px"
                                                    maxLength={10}
                                                />
                                                <Column
                                                    field=""
                                                    headerCell={props => <label>User</label>}
                                                    width="120px"
                                                    maxLength={40}
                                                />
                                                <Column
                                                    field=""
                                                    headerCell={props => <label>Status</label>}
                                                    width="120px"
                                                    maxLength={40}
                                                />
                                            </Grid>
                                        </div>
                                        <DialogActionsBar>
                                            <button className="k-button" onClick={this.toggleWorkflow}>Close</button>
                                        </DialogActionsBar>
                                    </Dialog>} */}
                                                {/* </div> */}
                                            </div>

                                            <div className="col-md-12 row mb-4">
                                                <div className="col-md-3 mt-auto">
                                                    <div className="d-block">
                                                        <div className=""><label>Revenue from Resource Deployment:</label></div>
                                                    </div>
                                                    <div className="d-block">
                                                        <div className="">
                                                            <Input
                                                                disabled={true}
                                                                value={this.state.filteredTotalRevenue}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 mt-auto">
                                                    <div className="d-block">
                                                        <div className=""><label>Cost of Resource Deployment:</label></div>
                                                    </div>
                                                    <div className="d-block">
                                                        <div className="">
                                                            <Input
                                                                disabled={true}
                                                                value={this.state.filteredTotalCost}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-3 mt-auto">
                                                    <div className="d-block">
                                                        <div className=""><label>Cost to Revenue Ratio:</label></div>
                                                    </div>
                                                    <div className="d-block">
                                                        <div className="">
                                                            <Input
                                                                disabled={true}
                                                                value={this.state.filteredCostToRevenueRatio}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-3 mt-auto">
                                                    <div className="">
                                                        {!this.state.isLoading && (
                                                            <ExcelDownload
                                                                project={this.state.project}
                                                                newResProjectOrCRNames={this.state.newResProjectOrCRNames}
                                                                SelectedLineStatuses={this.state.SelectedLineStatuses}
                                                                filteredTotalRevenue={this.state.filteredTotalRevenue}
                                                                filteredTotalCost={this.state.filteredTotalCost}
                                                                filteredCostToRevenueRatio={this.state.filteredCostToRevenueRatio}
                                                                calculatedValues={this.state.calculatedValues}
                                                                resourceMix={this.state.filteredResourceMix ? this.state.filteredResourceMix : this.state.ResourceMix}
                                                                dataUpdated={() => {
                                                                    this.setState({ excelDataObsolete: false });
                                                                }}
                                                                updateData={this.state.excelDataObsolete}
                                                            />
                                                        )}
                                                    </div>
                                                </div>



                                            </div>
                                        </PanelBarItem>
                                    </PanelBar>
                                    <div className="main-seperator"></div>
                                </>
                            )}

                            <div className="row mt-3">
                                <div className="col-md-12 btn-align-right">
                                    {this.props.location.action === 'RPApprove' && (
                                        <>
                                            <Button type="button" primary={true}
                                                disabled={this.ticked.length == 0 || this.state.notEditableResource || this.state.hasSubLines || !this.state.isMainLinesAllocated || !this.state.isSubLinesValid || this.state.isNoCostVariance || this.state.isNoCostVarianceSubLine}
                                                onClick={this.submitApprovalToggle}>Submit Allocation</Button>
                                            <Button type="button" primary={true}
                                                disabled={this.ticked.length == 0 || this.state.hasSubLines || (this.state.managerComment ? this.state.managerComment.replace(/(<([^>]+)>)/ig, '') == '' : true) || this.state.isAddedFromAllocation || this.state.isInititalPM}
                                                onClick={this.cancelRequests}>Cancel Request</Button>
                                        </>
                                    )}

                                    {this.props.location.action !== 'RPAllocateWF' && (
                                        <>
                                            <Button type="button" primary={true} onClick={this.handleResourcesSave} disabled={this.props.location.action === 'view'}>Save</Button>
                                            <Button type="button" primary={true} onClick={this.handleResourceRemove}
                                                disabled={!this.state.isCanRemoveRequest}>
                                                Remove Lines</Button>
                                            {/* <Button type="button" primary={true} onClick={this.editResource} disabled={!this.state.isSelectedValidMainLines || this.ticked.length !== 1 || this.sublineTicked.length !== 0 || this.state.notEditableResource || this.props.location.action === 'view'}>Edit
                                                Resource</Button> */}
                                            <Button type="button" onClick={this.onClickCancel} disabled={this.props.location.action === 'view'}>Cancel</Button>
                                        </>
                                    )}
                                </div>
                            </div>

                            {this.props.location.action === 'RPAllocateWF' &&
                                (
                                    <div className="row mt-3">
                                        <div className="col-md-12 btn-align-right">
                                            <Button type="button" primary={true}
                                                disabled={this.ticked.length == 0 || this.state.hasSubLines}
                                                onClick={this.onClickApprove}>Approve</Button>

                                            <Button type="button" primary={true}
                                                disabled={this.ticked.length == 0 || this.state.hasSubLines}
                                                onClick={this.onClickReject}>Reject</Button>

                                            <Button type="button" onClick={this.onClickCancel}>Cancel</Button>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="table-subrow mt-3 resource-mix-grid-wrap">

                                <Grid data={(this.props.location.action !== 'RPApprove' && this.props.location.action !== 'RPAllocateWF') ? this.state.filteredResourceMix : this.state.resourceMix}
                                    rowRender={this.rowRenderSB}
                                    style={{ height: '400px' }}
                                    skip={this.state.skip}
                                    take={this.state.take}
                                    selectedField="selected"
                                    onPageChange={this.pageChange}
                                    onItemChange={this.itemChange}
                                    //onHeaderSelectionChange={this.headerSelectionChange}
                                    onSelectionChange={this.selectionChange}
                                    className="resource-mix-grid"
                                    detail={props => (<GridSubLineCellResourceAllocation {...props} RateTypes={this.state.allRateTypes} onSubLineSelect={this.onSubLineSelect} onSublineEdit={this.onSubLineEdit} onClickLineStatus={this.onClickLineStatus} rowRenderSubSB={this.rowRenderSubSB} projectStartDate={this.state.project.StartDate} calculateNewEstimatedEndDate={this.calculateNewEstimatedEndDate} toggleDialogResourceSearch={this.toggleDialogResourceSearch} />)}
                                    expandField="expanded"
                                    onExpandChange={this.expandChange}
                                    sortable={true}
                                    sort={this.state.sort}
                                    onSortChange={this.sortChange}
                                    resizable
                                >
                                    <Column
                                        field="selected"
                                        width="41px"
                                        headerSelectionValue={
                                            this.state.resourceMix.findIndex(dataItem => dataItem.selected === false) === -1
                                        } />

                                    {this.state.projectSubPhaseVisible && (
                                        <Column
                                            field=""
                                            headerCell={props => <label></label>}
                                            width="50px"
                                        />
                                    )}
                                    <Column
                                        field=""
                                        headerCell={props => <label className="">Status</label>}
                                        cell={props => (<ResourceRequestStatusCell {...props} />)}
                                        width="150px"
                                        maxLength={3}
                                    />
                                    <Column
                                        field="dataValueCostDesignation.Name"
                                        headerCell={props => <label className="">Designation (Cost)</label>}
                                        width="140px"
                                        maxLength={40}
                                    />
                                    <Column
                                        field=""
                                        headerCell={props => <label>Start Date</label>}
                                        cell={props => (<ResourceRequestLineStartDateCell {...props} projectStartDate={this.state.project.StartDate} calculateNewEstimatedEndDate={this.calculateNewEstimatedEndDate} />)}
                                        width="100px"
                                    />
                                    <Column
                                        field="EndDate"
                                        headerCell={props => <label>End Date</label>}
                                        cell={props => (<ResourceRequestLineEndDateCell {...props} projectStartDate={this.state.project.StartDate} calculateNewEstimatedEndDate={this.calculateNewEstimatedEndDate} />)}
                                        width="100px"
                                    />
                                    <Column
                                        field="EffortDays"
                                        headerCell={props => <label className="">Effort Days</label>}
                                        width="100px"
                                        maxLength={3}
                                    />
                                    <Column
                                        field="AllocationPercentage"
                                        headerCell={props => <label className="">Allocation (%)</label>}
                                        width="100px"
                                        maxLength={3}
                                    />

                                    <Column
                                        field=""
                                        headerCell={props => <label className="">Allocated Resource</label>}
                                        width="120px"
                                        maxLength={3}
                                        cell={props => (<AllocatedResourceCell {...props} toggleDialogResourceSearch={this.toggleDialogResourceSearch} />)}
                                    />
                                    <Column
                                        field="CostVariance"
                                        headerCell={props => <label className="">Cost Variance</label>}
                                        maxLength={3}
                                        width="90px"
                                    />
                                    {/* <Column
                                        field=""
                                        headerCell={props => <label>Project / CR</label>}
                                        width="140px"
                                        maxLength={10}
                                        cell={ProjectORCrCell}
                                    />
                                    <Column
                                        field="dataValueProjectRole.Name"
                                        headerCell={props => <label className="">Project Role</label>}
                                        width="140px"
                                        maxLength={40}
                                    />

                                    <Column
                                        field="dataValueResourceRegion.Name"
                                        headerCell={props => <label className="">Resource Region</label>}
                                        width="120px"
                                        maxLength={6}
                                    />
                                    <Column
                                        field="OnsiteStatus"
                                        headerCell={props => <label className="">Onsite / Offshore</label>}
                                        width="130px"
                                        maxLength={5}
                                    />


                                    <Column
                                        field="AllocatedDesignation"
                                        headerCell={props => <label className="">Allocated Designation</label>}
                                        width="130px"
                                        maxLength={3}
                                    />
                                    <Column
                                        field="AllocatedRegion.Name"
                                        headerCell={props => <label className="">Allocated Region</label>}
                                        width="130px"
                                        maxLength={3}
                                    />
                                    
                                    <Column
                                        field="TotalRevenue"
                                        headerCell={props => <label className="">Revenue</label>}
                                        width="120px"
                                        maxLength={3}
                                    />
                                    <Column
                                        field="TotalCost"
                                        headerCell={props => <label className="">Cost</label>}
                                        width="120px"
                                        maxLength={3}
                                    />
                                    <Column
                                        field="CostToRevenueRatio"
                                        headerCell={props => <label className="">Cost to Revenue Ratio</label>}
                                        width="150px"
                                        maxLength={3}
                                    /> */}
                                    <Column
                                        width="120px"
                                        field=""
                                        cell={props => (<MoreDetailsCell {...props} onClickMore={this.onClickMore} onClickLineStatus={this.onClickLineStatus}/>)}
                                    />

                                </Grid>
                            </div>

                            {this.state.mainmoredetails &&

                                <Dialog title={"More Details"} onClose={this.toggleMoreDialog} width={1000}>
            
                                    
                                    <div className="row my-3">
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Status:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.Status}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Project Role:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.dataValueProjectRole ? this.state.dataItem.dataValueProjectRole.Name : ""}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Designation (Cost):</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.dataValueCostDesignation.Name}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Resource Region:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.dataValueResourceRegion.Name}</label>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="main-seperator"></div>
                                    <div className="row my-3">
                                    <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Project / CR:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.ChangeRequest ? this.state.dataItem.ChangeRequest.CRName : this.state.dataItem.Project.ProjectName}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Allocated Resource:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.AllocatedResource ? this.state.dataItem.AllocatedResource.Resource.Name : ""}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Allocated Designation:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.AllocatedDesignation}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Allocated Resource Region:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.AllocatedRegion ? this.state.dataItem.AllocatedRegion.Name : ""}</label>
                                            </div>
                                        </div>

                                        
                                        
                                    </div>
                                    <div className="main-seperator"></div>
                                    <div className="row my-3">
                                        
                                    <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Start Date:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{moment(this.state.dataItem.StartDate).format('MM-DD-YYYY')}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">End Date:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{moment(this.state.dataItem.EndDate).format('MM-DD-YYYY')}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Effort Days:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.EffortDays && this.state.dataItem.EffortDays > 0 ? Number(this.state.dataItem.EffortDays).toFixed(2): 0}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Allocation (%):</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.AllocationPercentage}</label>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                    <div className="main-seperator"></div>

                                    <div className="row my-3">
                                        
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Rate Type:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.dataValueRateType && this.state.dataItem.dataValueRateType.Name }</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Revenue:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.TotalRevenue ? Number(this.state.dataItem.TotalRevenue).toFixed(2) : 0.00}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Cost:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.TotalCost ? Number(this.state.dataItem.TotalCost).toFixed(2) : 0.00}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Cost to Revenue Ratio:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.CostToRevenueRatio ? Number(this.state.dataItem.CostToRevenueRatio).toFixed(2) : 0.00}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Onsite / Offshore:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.OnsiteStatus}</label>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="main-seperator"></div>

                                    <div className="row my-3">
                                    <div className="col-md-3">
                                            <div className="d-block"><label className="font-weight-bold mr-1">Cost Variance:</label></div>
                                            <div className="d-block">
                                                <label className="font-weight-normal">{this.state.dataItem.CostVariance}</label>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <DialogActionsBar>
                                        <button className="k-button modal-primary" onClick={this.toggleMoreDialog}>Close</button>
                                    </DialogActionsBar>
                                </Dialog>}


                            {this.state.visible &&
                                <div className="prr-window">
                                    <Window title={"Search"} initialHeight={400} onClose={this.toggleDialogResourceSearch}>
                                        <div className="form-inline justify-content-between">
                                            <div class="form-group w-75">
                                                <Input
                                                    value={this.state.resourchSearchVal}
                                                    onChange={this.handleResourceSearchInputChange}
                                                    name="resourceSearch"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <button className="k-button k-primary" onClick={this.loadResources}>Search</button>
                                            <div className="main-seperator" />
                                        </div>
                                        <div className="resources">
                                            <span className="title-two">Resource Suggestions</span>

                                            {this.state.filteredResourceData.map(item => (
                                                <div className="d-flex res-parent mt-2">
                                                    <button className="resource-button btn" onClick={() => this.saveAllocatedResourceValidation(item)} >
                                                        <span className="k-icon k-i-arrow-60-left"></span>
                                                    </button>
                                                    <div className="resource-details ml-2 ">
                                                        <div className="text-uppercase font-weight-bold">{item.Name}</div>
                                                        <div>
                                                            <ComboBox
                                                                data={this.state.designations}
                                                                textField="Name"
                                                                dataItemKey="id"
                                                                value={item.AllocatedDesignationObj}
                                                                onChange={this.handleOnChangeAllocatedDesignation}
                                                                name={item}
                                                                placeholder="Please Select"
                                                                filterable={true}
                                                                onFilterChange={this.filterResourceDesignation}
                                                                disabled = {false}
                                                            />
                                                        </div>
                                                        <div>Project Utilization: {Number.isNaN(item.ProjectUtilization) ? 'NA' : item.ProjectUtilization + ' %'}</div>
                                                        <div>Presales Utilization: {Number.isNaN(item.PresalesUtilization) ? 'NA' : item.PresalesUtilization + ' %'}</div>
                                                        <div>Softbooking Utilization: {Number.isNaN(item.SoftbookingUtilization) ? 'NA' : item.SoftbookingUtilization + ' %'}</div>
                                                        <div>Non Billable Utilization: {Number.isNaN(item.NonBillableUtilization) ? 'NA' : item.NonBillableUtilization + ' %'}</div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </Window>
                                </div>
                            }

                            {this.props.location.action !== 'RPAllocateWF' && (
                                <>
                                    <div className="row mt-3">
                                        <div className="col-md-8">
                                            <label htmlFor="">Resource Planner's Comment:</label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <Quils
                                                maxLength={200}
                                                managerComment={this.state.managerComment}
                                                handlePMCommentChange={this.handlePMCommentChange}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                        </TabStripTab>
                    </TabStrip>



                </div>

                {/* <Dialog title={"Define Skill"} onClose={this.toggleDialog} width={700}>

                    <div className="row mb-3">
                        <div className="col-md-12 btn-align-right">
                            <Button type="button" primary={true}>Add</Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Grid style={{ height: '400px' }}>
                                <Column field="Type" title="Type" />
                                <Column field="Skill" title="Skill" />
                                <Column field="ProficiencyLevel" title="Proficiency Level" />
                                <Column field="Actions" title="Actions" />
                            </Grid>
                        </div>
                    </div>

                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>Save</button>
                    </DialogActionsBar>
                </Dialog> */}

                {this.state.visibleLineStatus &&
                    <Dialog width={1100} title={"Line Status"} onClose={this.toggleLineStatusDialog}>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="d-block"><label>Designation:</label></div>
                                <div className="d-block">
                                    <Input 
                                    value={this.state.dataItem.AllocatedResource ? this.state.dataItem.AllocatedDesignation : this.state.dataItem.dataValueCostDesignation.Name}
                                    disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="d-block"><label>Allocated Resource:</label></div>
                                <div className="d-block">
                                    <Input 
                                    value={this.state.dataItem.AllocatedResource ? this.state.dataItem.AllocatedResource.Name : ""}
                                    disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="d-block"><label>Allocation:</label></div>
                                <div className="d-block">
                                    <Input 
                                    value={this.state.dataItem.AllocationPercentage ? this.state.dataItem.AllocationPercentage : ""}
                                    disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="d-block"><label>Line Status:</label></div>
                                <div className="d-block">
                                    <Input 
                                    value={this.state.dataItem.Status ? ResourceRequestStatusENUMS[this.state.dataItem.Status] : ""}
                                    disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="d-block"><label>Start Date:</label></div>
                                <div className="d-block">
                                    <Input 
                                    value={this.state.dataItem.StartDate ? moment(this.state.dataItem.StartDate).format('MM-DD-YYYY') : ""}
                                    disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="d-block"><label>End Date:</label></div>
                                <div className="d-block">
                                    <Input 
                                    value={this.state.dataItem.EndDate ? moment(this.state.dataItem.EndDate).format('MM-DD-YYYY') : ""}
                                    disabled={true} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="d-block"><label>Effort:</label></div>
                                <div className="d-block">
                                    <Input 
                                    value={this.state.dataItem.EffortDays ? this.state.dataItem.EffortDays : ""}
                                    disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Grid
                                    data={this.state.lineStatusHistoryList}
                                    style={{ height: '150px' }}
                                >
                                    <Column field="GroupCardName" title="Group" width="150px"/>
                                    <Column field="updatedUser" title="User" width="200px"/>
                                    <Column
                                        field="ToStatus"
                                        title="Status"
                                        cell={props => (<ResourceRequestStatusCell {...props} />)}
                                        width="200px"
                                        maxLength={3}
                                    />
                                    <Column field="statusChangedAt" title="Date and Time" 
                                        cell={props => (<LineHistoryDateCell {...props} />)}/>
                                    <Column field="Comment" title="Comment" />
                                </Grid>
                            </div>
                        </div>
                        <DialogActionsBar>
                            <button className="k-button modal-primary" onClick={this.toggleLineStatusDialog}>Close</button>
                        </DialogActionsBar>
                    </Dialog>
                }

                {this.state.showEditDialog === true && (
                    <Dialog title={'Edit Resource Main Line'} onClose={this.toggleCancelConfirmDialog} width={900} height={450}>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block"><label className="mandatory">Project / CR:</label></div>
                                    <div className="d-block">
                                        <ComboBox
                                            placeholder="Please select"
                                            name="editProjectOrCRName"
                                            value={this.state.editableItem.name}
                                            onChange={this.handleComboChangeEdit}
                                            required={true}
                                            disabled={true}
                                        />
                                        <span className={this.validateProperty(this.state.editableItem.name)}>
                                            Please select a valid type
                                  </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block"><label>Phase :</label></div>
                                    <div className="d-block">
                                        <ComboBox
                                            placeholder="Please select"
                                            name="editPhase"
                                            data={this.state.editableItem.Phases}
                                            textField="Text"
                                            dataItemKey="id"
                                            value={this.state.editableItem.Phase}
                                            onChange={this.handleComboChangeEdit}
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            disabled={this.props.location.action === 'RPApprove' ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block"><label>Sub-Phase :</label></div>
                                    <div className="d-block">
                                        <ComboBox
                                            placeholder="Please select"
                                            name="editSubPhase"
                                            data={this.state.subPhasesOfSelectedPhase}
                                            textField="Text"
                                            dataItemKey="id"
                                            value={this.state.editableItem.SubPhase}
                                            onChange={this.handleComboChangeEdit}
                                            disabled={this.props.location.action === 'RPApprove' ? true : false}
                                        // filterable={true}
                                        // onFilterChange={this.filter}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block"><label>Fixed/T&M:</label></div>
                                <div className="d-block">
                                    <Input
                                        value={this.state.editableItem.ResourcePaymentMethod.Name}
                                        //onChange={this.handleOnChangeInput}
                                        name="PaymentMethod"
                                        autoComplete="off"
                                        disabled={true}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block"><label>Start Date:</label></div>
                                    <div className="d-block">
                                        <DatePicker
                                            name="editStartDate"
                                            value={this.state.editableItem.StartDate === null ? '' : reStructureDate(this.state.editableItem.StartDate)}
                                            min={new Date()}
                                            format="MM/dd/yyyy"
                                            onChange={this.handleAddStartDateChange}
                                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                            disabled={this.state.disableSelectedResourceStartEndDate}
                                        >
                                        </DatePicker>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block"><label>End Date:</label></div>
                                    <div className="d-block">
                                        <DatePicker
                                            name="editEndDate"
                                            value={this.state.editableItem.EndDate === null ? '' : reStructureDate(this.state.editableItem.EndDate)}
                                            min={new Date()}
                                            format="MM/dd/yyyy"
                                            onChange={this.handleAddStartDateChange}
                                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                            disabled={this.state.disableSelectedResourceStartEndDate}
                                        >
                                        </DatePicker>
                                    </div>
                                </div>
                            </div>

                            {
                                <div className="col-md-3">
                                    <div className="d-block"><label className="mandatory">Effort Days:</label></div>
                                    <div className="d-block">
                                        <ComboBox
                                            placeholder="Please select"
                                            name="editEffortDays"
                                            value={this.state.editableItem.TotalEffortDays > 0 ? Number(this.state.editableItem.TotalEffortDays).toFixed(2) : Number(this.state.editableItem.EffortDays).toFixed(2)}
                                            onChange={this.handleComboChangeEdit}
                                            required={true}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            }

                            {<div className="col-md-3">
                                <div className="d-block"><label className="mandatory">Allocation (%):</label></div>
                                <div className="d-block">
                                    <ComboBox
                                        placeholder="Please select"
                                        name="editAllocationPrecentage"
                                        value={this.state.editableItem.AllocationPercentage}
                                        onChange={this.handleComboChangeEdit}
                                        required={true}
                                        disabled={true}
                                    />
                                </div>
                            </div>}

                        </div>

                        <div className="row">

                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block"><label className="mandatory">Project Role:</label></div>
                                    <div className="d-block">
                                        <ComboBox
                                            placeholder="Please select"
                                            name="editRole"
                                            data={this.state.RolesAll}
                                            textField="Name"
                                            dataItemKey="id"
                                            value={this.state.editableItem.dataValueProjectRole}
                                            onChange={this.handleComboChangeEdit}
                                            required={true}
                                            filterable={true}
                                            onFilterChange={this.filter}
                                            disabled={this.state.editableItem.CMSResource && this.state.editableItem.CMSResource === true || this.props.location.action === 'RPApprove'}
                                        />
                                        <span className={this.validateProperty(this.state.editableItem.dataValueProjectRole)}>
                                            Please select a valid Role
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block"><label className="mandatory">Designation:</label></div>
                                    <div className="d-block">
                                        <ComboBox
                                            placeholder="Please select"
                                            name="editDesignation"
                                            data={this.state.editableItem.Designations}
                                            textField="Name"
                                            dataItemKey="id"
                                            value={this.state.editableItem.dataValueCostDesignation}
                                            onChange={this.handleComboChangeEdit}
                                            required={true}
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            disabled={this.props.location.action === 'RPApprove' ? true : false}
                                        />
                                        <span className={this.validateProperty(this.state.editableItem.dataValueCostDesignation)}>
                                            Please select a valid Role
                                 </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block"><label className="mandatory">Resource Region:</label></div>
                                    <div className="d-block">
                                        <ComboBox
                                            placeholder="Please select"
                                            name="editRegion"
                                            data={this.state.RegionsAll}
                                            textField="Name"
                                            dataItemKey="id"
                                            value={this.state.editableItem.dataValueResourceRegion}
                                            onChange={this.handleComboChangeEdit}
                                            required={true}
                                            filterable={true}
                                            onFilterChange={this.filter}
                                            disabled={this.props.location.action === 'RPApprove' ? true : false}
                                        />
                                        <span className={this.validateProperty(this.state.editableItem.dataValueResourceRegion)}>
                                            Please select a valid Region
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block"><label className="mandatory">Onsite / Offshore:</label></div>
                                <div className="d-block">
                                    <ComboBox
                                        placeholder="Please select"
                                        name="editOnSiteStatus"
                                        data={this.state.onSiteStatusAll}
                                        value={this.state.editableItem.OnsiteStatus === 'Onsite' ? this.onSiteStatusAll[0] : this.state.editableItem.OnsiteStatus === 'Offshore' ? this.onSiteStatusAll[1] : null}
                                        textField="Name"
                                        dataItemKey="id"
                                        onChange={this.handleComboChangeEdit}
                                        required={true}
                                        filterable={true}
                                        onFilterChange={this.filter}
                                        disabled={this.props.location.action === 'RPApprove' ? true : false}
                                    />
                                    <span className={this.validateProperty(this.state.editableItem.OnsiteStatus)}>
                                        Please select a valid Value
                                      </span>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="d-block"><label>Rate:</label></div>
                                <div className="d-block">
                                    <Input
                                        value={this.state.editableItem.RateValue}
                                        //onChange={this.handleOnChangeInput}
                                        name="editRateValue"
                                        autoComplete="off"
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block"><label>Revenue:</label></div>
                                <div className="d-block">
                                    <Input
                                        value={this.state.editableItem.TotalRevenue && this.state.editableItem.TotalRevenue > 0 ? Number(this.state.editableItem.TotalRevenue).toFixed(2) : 0.00}
                                        //onChange={this.handleOnChangeInput}
                                        name="editRevenueValue"
                                        autoComplete="off"
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block"><label>Cost:</label></div>
                                <div className="d-block">
                                    <Input
                                        value={this.state.editableItem.TotalCost}
                                        //onChange={this.handleOnChangeInput}
                                        name="editTotalCostValue"
                                        autoComplete="off"
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block"><label>Cost to Revenue Ratio:</label></div>
                                <div className="d-block">
                                    <Input
                                        value={this.state.editableItem.CostToRevenueRatio}
                                        //onChange={this.handleOnChangeInput}
                                        name="editTotalCostValue"
                                        autoComplete="off"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="d-block"><label>Cost Variation:</label></div>
                                <div className="d-block">
                                    <Input
                                        value={this.state.lineCostVariance}
                                        name="editCostVariation"
                                        autoComplete="off"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block"><label>Start Date:</label></div>
                                    <div className="d-block">
                                        <DatePicker
                                            width="100"
                                            name="endDate"
                                            onChange={this.handleSubLineStartDateChange}
                                            value={this.state.selctedResourceSubLineStartDate}
                                            min={reStructureDate(this.state.subLineStartDateMin)}
                                            max={this.state.selctedResourceSubLineEndDate === null ? this.state.userDefinedEndDate ? reStructureDate(this.state.userDefinedEndDate) : reStructureDate(this.state.subLineStartDateMin) : this.state.selctedResourceSubLineEndDate}
                                            format="MM/dd/yyyy"
                                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                        />

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="">
                                    <div className="d-block"><label>End Date:</label></div>
                                    <div className="d-block">
                                        <DatePicker
                                            width="100"
                                            name="endDate"
                                            onChange={this.handleSubLineEndDateChange}
                                            value={this.state.selctedResourceSubLineEndDate}
                                            min={this.state.selctedResourceSubLineStartDate === null ? reStructureDate(this.state.subLineStartDateMin) : this.state.selctedResourceSubLineStartDate}
                                            max={this.state.userDefinedEndDate ? reStructureDate(this.state.userDefinedEndDate) : reStructureDate(this.state.subLineStartDateMin)}
                                            format="MM/dd/yyyy"
                                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-block"><label>Allocation %:</label></div>
                                <div className="d-block">
                                    <DebounceInput
                                        debounceTimeout={2000}
                                        width="100%"
                                        value={this.state.subLineDefaultAllocationPercentage}
                                        onChange={this.handleSubLineAllocationPercentageChange}
                                        name="subLineAllocPercentage"
                                        type="number"
                                        pattern="[0-9]*"
                                        className="k-textbox"
                                    />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-block"><label>Effort:</label></div>
                                <div className="d-block">
                                    <DebounceInput
                                        debounceTimeout={1000}
                                        width="100%"
                                        value={
                                            this.state.subLineEffortDays > 0
                                                ? this.state.subLineEffortDays.toFixed(2).replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ','
                                                )
                                                : '0'}
                                        name="subLineEffortDays"
                                        type="number"
                                        pattern="[0-9]*"
                                        className="k-textbox"
                                    />
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="d-block"><label></label></div>
                                <div className="d-block billable-mt">
                                    <Button type="button" primary={true}
                                        disabled={this.state.isDisableAllocationLineAddBtn || this.state.subLineEffortDays === 0}
                                        onClick={this.onAddSubLineToGrid}
                                    >Add</Button>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5 mb-5">
                            <div className="col-md-12">
                                <Grid className="resource-mix-grid"
                                    data={this.state.editableItem.ResourceRequestSubLine}
                                >

                                    <Column
                                        field="StartDate"
                                        headerCell={props => <label className="">Start Date</label>}
                                    />
                                    <Column
                                        field="EndDate"
                                        headerCell={props => <label className="">End Date</label>}
                                    />
                                    <Column
                                        field="AllocationPercentage"
                                        headerCell={props => <label className="">Allocation %</label>}
                                    />
                                    <Column
                                        field="EffortDays"
                                        headerCell={props => <label className="">Effort</label>}
                                    />
                                    <Column
                                        field="IsRevenuePresent"
                                        headerCell={props => <label className="">Revenue Present</label>}
                                        cell={props => (<EditPopupIsRevenuePresentCell {...props} toggleIsRevenuePresent={this.toggleIsRevenuePresent} />)}
                                    />
                                    <Column
                                        field="Status"
                                        headerCell={props => <label className="">Status</label>}
                                        cell={props => (<ResourceRequestEditStatusCell {...props} />)}
                                    />

                                </Grid>
                            </div>
                        </div>


                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleCancelConfirmDialog}>Cancel</button>
                            <button className="k-button modal-primary" onClick={this.addEditingValueToGrid}>Save</button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                <div>
                    {this.state.popupVisible === true && (
                        <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                            {this.state.showSuccessImage === true && (
                                <div className="text-center">
                                    <img className="successImg" src={SuccessImg} alt="Success" />
                                </div>
                            )}

                            {this.state.showErrorImage === true && (
                                <div className="text-center">
                                    <img className="successImg" src={ErrorImg} alt="Error Image" />
                                </div>
                            )}
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                            <DialogActionsBar>

                                {(this.state.showSuccessImage === true && this.state.isSubmitted) && (
                                    <button className="k-button modal-primary" onClick={this.navigateBack}>
                                        OK
                                    </button>
                                )}

                                {(this.state.showSuccessImage === true && !this.state.isSubmitted) ||
                                    (this.state.showErrorImage === true) ?
                                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                                        OK
                                    </button> :
                                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                                        OK
                                    </button>
                                }

                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleConfirmDialog}>
                                No
                            </button>
                            <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                {this.state.showOutsourcedCostDialog === true && (
                    <Dialog title="Enter Cost" onClose={this.toggleOutsourcedCostDialog} width="400px">
                        <div className="row">
                            <div className="col-md-12">
                                <p style={{ margin: '25px', textAlign: 'center' }}>
                                    Please enter the cost per resource{' '}
                                    {this.state.newResRole &&
                                        this.state.newResRole.Currency &&
                                        this.state.newResRole.Currency.Code
                                        ? ' in ' + this.state.newResRole.Currency.Code
                                        : ''}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <label for="">Cost:</label>
                            </div>
                            <div className="col-md-5 pr-0">
                                <NumericTextBox
                                    value={this.state.outsourcedCost}
                                    onChange={this.handleOutsourcedCostInputChange}
                                    min={0} />
                            </div>
                        </div>

                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleOutsourcedCostDialog}>
                                Cancel
                            </button>
                            <button
                                className="k-button modal-primary"
                                onClick={this.confirmOutsourcedCost}
                                disabled={this.state.outsourcedCost === 0}
                            >
                                Save
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                {this.state.showMessageDialog === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                                OK
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                {this.state.showCostValidationDialog === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleCostValidatioDialog} width="400px">

                        {this.state.showErrorImage === true && (
                        <div className="text-center">
                            <img className="successImg" src={ErrorImg} alt="Error Image"/>
                        </div>
                        )}

                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                        <DialogActionsBar>
                        {this.state.showErrorImage === true && (
                            <button className="k-button modal-primary" onClick={this.toggleCostValidatioDialog}>
                            OK
                            </button>
                        )}
                        </DialogActionsBar>
                    </Dialog>
                )}

                <div>
                    {this.state.showEditSubLineDialog === true && (
                        <Dialog title={'Edit Resource Sub Line'} onClose={this.toggleSubLineEditDialog} width={800}>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="">
                                        <div className="d-block"><label className="mandatory">Project / CR:</label></div>
                                        <div className="d-block">
                                            <ComboBox
                                                placeholder="Please select"
                                                name="editProjectOrCRName"
                                                value={this.state.mainLineofsublineObj.name}
                                                //onChange={this.handleComboChangeEdit}
                                                required={true}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="">
                                        <div className="d-block"><label>Phase :</label></div>
                                        <div className="d-block">
                                            <ComboBox
                                                placeholder="Please select"
                                                name="editSubLinePhase"
                                                data={this.state.allProjectCrPhases}
                                                textField="Text"
                                                dataItemKey="id"
                                                value={this.state.selectedSubLineToEdit.Phase}
                                                onChange={this.handleComboBoxChangeOnSubLineEdit}
                                            //required={true}
                                            //filterable={true}
                                            //onFilterChange={this.filter}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="">
                                        <div className="d-block"><label>Sub-Phase :</label></div>
                                        <div className="d-block">
                                            <ComboBox
                                                placeholder="Please select"
                                                name="editSubLineSubPhase"
                                                data={this.state.subPhasesOfSelectedPhase}
                                                textField="Text"
                                                dataItemKey="id"
                                                value={this.state.selectedSubLineToEdit.SubPhase}
                                                onChange={this.handleComboBoxChangeOnSubLineEdit}
                                            //required={true}
                                            // filterable={true}
                                            // onFilterChange={this.filter}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block"><label>Fixed/T&M:</label></div>
                                    <div className="d-block">
                                        <Input
                                            value={this.state.mainLineofsublineObj.PaymentMethod.Name === 'Hybrid' ? this.state.mainLineofsublineObj.dataValuePaymentMethod.Name : this.state.mainLineofsublineObj.PaymentMethod.Name}
                                            //onChange={this.handleOnChangeInput}
                                            name="PaymentMethod"
                                            autoComplete="off"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="row">

                                <div className="col-md-3">
                                    <div className="">
                                        <div className="d-block"><label>Start Date:</label></div>
                                        <div className="d-block">
                                            <DatePicker
                                                name="editStartDate"
                                                value={reStructureDate(this.state.selectedSubLineToEdit.StartDate)}
                                                format="MM/dd/yyyy"
                                                disabled={true}
                                                formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                            >
                                            </DatePicker>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="">
                                        <div className="d-block"><label>End Date:</label></div>
                                        <div className="d-block">
                                            <DatePicker
                                                name="editEndDate"
                                                value={reStructureDate(this.state.selectedSubLineToEdit.EndDate)}
                                                format="MM/dd/yyyy"
                                                formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                                disabled={true}
                                            >
                                            </DatePicker>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-md-3">
                  <div className="d-block"><label>Effort Days:</label></div>
                  <div className="d-block">
                    <DebounceInput
                      width="100%"
                      type="number"
                      pattern="[0-9]*"
                      className="k-textbox"
                    />
                  </div>
                </div> */}

                                {/* <div className="col-md-3">
                  <div className="d-block"><label>Allocation (%):</label></div>
                  <div className="d-block">
                    <DebounceInput
                      width="100%"
                      type="number"
                      pattern="[0-9]*"
                      className="k-textbox"
                    />
                  </div>
                </div> */}

                            </div>
                            <div className="row">

                                <div className="col-md-3">
                                    <div className="">
                                        <div className="d-block"><label className="mandatory">Project Role:</label></div>
                                        <div className="d-block">
                                            <ComboBox
                                                placeholder="Please select"
                                                name="editRole"
                                                data={this.state.allProjectCrPhases}
                                                textField="Name"
                                                dataItemKey="id"
                                                value={this.state.selectedSubLineToEdit.dataValueProjectRole}
                                                onChange={this.handleComboChangeEdit}
                                                required={true}
                                                filterable={true}
                                                onFilterChange={this.filter}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="">
                                        <div className="d-block"><label className="mandatory">Designation:</label></div>
                                        <div className="d-block">
                                            <ComboBox
                                                placeholder="Please select"
                                                name="editSubLineDesignation"
                                                data={this.state.tmpDesignationsAll}
                                                textField="Name"
                                                dataItemKey="id"
                                                value={this.state.selectedSubLineToEdit.dataValueCostDesignation}
                                                onChange={this.handleComboBoxChangeOnSubLineEdit}
                                                required={true}
                                                filterable={true}
                                                onFilterChange={this.filter}
                                            />
                                            <span className={this.validateProperty(this.state.selectedSubLineToEdit.dataValueCostDesignation)}>
                                                Please select a valid Role
                                 </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="">
                                        <div className="d-block"><label className="mandatory">Resource Region:</label></div>
                                        <div className="d-block">
                                            <ComboBox
                                                placeholder="Please select"
                                                name="editSubLineRegion"
                                                data={this.state.RegionsAll}
                                                textField="Name"
                                                dataItemKey="id"
                                                value={this.state.selectedSubLineToEdit.dataValueResourceRegion}
                                                onChange={this.handleComboBoxChangeOnSubLineEdit}
                                                required={true}
                                                filterable={true}
                                                onFilterChange={this.filter}
                                            />
                                            <span className={this.validateProperty(this.state.selectedSubLineToEdit.dataValueResourceRegion)}>
                                                Please select a valid Region
                                        </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block"><label className="mandatory">Onsite / Offshore:</label></div>
                                    <div className="d-block">
                                        <ComboBox
                                            placeholder="Please select"
                                            name="editSubLineOnSiteStatus"
                                            data={this.state.onSiteStatusAll}
                                            value={this.state.selectedSubLineToEdit.OnsiteStatus === 'Onsite' ? this.onSiteStatusAll[0] : this.state.selectedSubLineToEdit.OnsiteStatus === 'Offshore' ? this.onSiteStatusAll[1] : null}
                                            textField="Name"
                                            dataItemKey="id"
                                            onChange={this.handleComboBoxChangeOnSubLineEdit}
                                            required={true}
                                        />
                                        <span className={this.validateProperty(this.state.selectedSubLineToEdit.OnsiteStatus)}>
                                            Please select a valid Value
                                      </span>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="d-block"><label>Rate:</label></div>
                                    <div className="d-block">
                                        <Input
                                            value={this.state.selectedSubLineToEdit.RateValue}
                                            name="editRateValue"
                                            autoComplete="off"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block"><label>Revenue:</label></div>
                                    <div className="d-block">
                                        <Input
                                            value={this.state.selectedSubLineToEdit.Revenue && this.state.selectedSubLineToEdit.Revenue > 0 ? Number(this.state.selectedSubLineToEdit.Revenue).toFixed(2) : 0.00}
                                            name="editRevenueValue"
                                            autoComplete="off"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block"><label>Cost:</label></div>
                                    <div className="d-block">
                                        <Input
                                            value={this.state.selectedSubLineToEdit.Cost}
                                            name="editTotalCostValue"
                                            autoComplete="off"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-block"><label>Cost to Revenue Ratio:</label></div>
                                    <div className="d-block">
                                        <Input
                                            value={this.state.selectedSubLineToEdit.CostToRevenueRatio}
                                            name="editTotalCostValue"
                                            autoComplete="off"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                {/*<div className="col-md-2">
                                    <div className="d-block"><label>Allocation %:</label></div>
                  <div className="d-block">
                                        <DebounceInput
                                          debounceTimeout={2000}
                                          width="100%"
                                          value={this.state.selectedSubLineToEdit.AllocationPercentage}
                                          onChange={this.handleSubLineAllocationPercentageChange}
                                          name="subLineAllocPercentage"
                                          type="number"
                                          pattern="[0-9]*"
                                          className="k-textbox"
                                          disabled={false}
                                        />
                                    </div>
                                </div>*/}

                                {/*<div className="col-md-2">
                                    <div className="d-block"><label>Effort:</label></div>
                                    <div className="d-block">
                                        <DebounceInput
                                          debounceTimeout={1000}
                                          width="100%"
                                          value={this.state.selectedSubLineToEdit.EffortDays}
                                          name="subLineEffortDays"
                                          type="number"
                                          pattern="[0-9]*"
                                          className="k-textbox"
                      disabled={true}
                    />
                  </div>
                </div>*/}
                            </div>

                            <div className="row mt-3 mb-3">
                                <div className="col-md-12 btn-align-right">
                                    <Button type="button" primary={true} onClick={this.onUpdateSubLineResource}>Update Sub line</Button>
                                    <Button type="button" onClick={this.toggleSubLineEditDialog}>Clear</Button>
                                </div>
                            </div>

                        </Dialog>
                    )}

                    {this.state.subLineUpdatedDialog === true && (
                        <Dialog title={this.state.dialogTitle} onClose={this.toggleSubLineUpdatedDialog} width="400px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                            <DialogActionsBar>
                                <button className="k-button modal-primary" onClick={this.toggleSubLineUpdatedDialog}>
                                    OK
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}

                </div>

                {/*<div>*/}
                {/*    {this.state.showCancelConfirmation === true && (*/}
                {/*      <Dialog*/}
                {/*        title="Please Confirm"*/}
                {/*        onClose={this.toggleCancelConfirmDialog}*/}
                {/*        width="300px"*/}
                {/*      >*/}
                {/*          <p style={{ margin: '25px', textAlign: 'center' }}>*/}
                {/*              Do you want to cancel the Resource Request ?*/}
                {/*          </p>*/}
                {/*          <DialogActionsBar>*/}
                {/*              <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>*/}
                {/*                  No*/}
                {/*              </button>*/}
                {/*              <button*/}
                {/*                className="k-button modal-primary"*/}
                {/*                onClick={this.navigateBack}*/}
                {/*              >*/}
                {/*                  Yes*/}
                {/*              </button>*/}
                {/*          </DialogActionsBar>*/}
                {/*      </Dialog>*/}
                {/*    )}*/}
                {/*</div>*/}

                <div>
                    {this.state.approveResourcesDialog && (
                        <Dialog title={'Please Confirm'} onClose={this.submitApprovalToggle}>
                            <p style={{ margin: '25px', textAlign: 'center' }}>
                                Do you want to send {this.ticked.length} selected line(s) for resource allocation?
                            </p>
                            <DialogActionsBar>
                                <button className="k-button active" onClick={this.submitApprovalToggle}>
                                    No
                                </button>
                                <button className="k-button modal-primary" disabled={this.state.approveResourcesDialogDisabled} onClick={this.submitApprovalForResources}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

                <div>
                    {this.state.approveAddSubLine && (
                        <Dialog title={'Please Confirm'} onClose={this.addSubLineToggle}>
                            <p style={{ margin: '25px', textAlign: 'center' }}>
                                Effort is exceeding the main line effort. Do you want to proceed?
                            </p>
                            <DialogActionsBar>
                                <button className="k-button active" onClick={this.addSubLineToggle}>
                                    No
                                </button>
                                <button className="k-button modal-primary" onClick={this.confirmAddSubLineToGrid}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

                <div>
                    {this.state.showCancelConfirmation === true && (
                        <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>
                                This form contains unsaved changes. Do you want to close it?
                            </p>
                            <DialogActionsBar>
                                <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                                    No
                                </button>
                                <button className="k-button modal-primary" onClick={this.navigateBack}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

                <div>
                    {this.state.showRejectConfirmation === true && (
                        <Dialog title="Please Confirm" onClose={this.toggleRejectConfirmDialog} width="300px">
                            <label htmlFor="" className="mandatory">
                                Reject Reason
                            </label>
                            <textarea className="k-textarea" maxLength="1000" value={this.state.rejectReason} rows="5" cols="50" onChange={this.handleRejectReasonInputChange}></textarea>
                            <DialogActionsBar>
                                <button className="k-button active" onClick={this.toggleRejectConfirmDialog}>
                                    Close
                                </button>
                                <button className="k-button modal-primary" disabled={!this.state.rejectReason || this.state.rejectReason === ''} onClick={this.rejectConfirm}>
                                    Submit
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

                {this.state.showWorkflowSelectDialogRR === true && (
                    <Dialog title="Select Workflow" onClose={this.toggleWorkflowSelectDialogRR} width="300px">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <p className="subtitle-workflow" style={{ textAlign: 'center' }}>Please select the applicable workflow to send for approval.</p>
                                </div>
                                <div className="row">
                                    <form className="k-form modified">
                                        <div className="k-form-field">
                                            {this.state.matchingWorkflowList.map((eachOption, i) => {
                                                return (
                                                    <div className="field-mod">
                                                        <input
                                                            type="radio"
                                                            id={'' + eachOption.id}
                                                            name={'' + eachOption.id}
                                                            value={eachOption.id}
                                                            checked={this.state.selectedWorkFlowId === eachOption.id}
                                                            className="k-radio"
                                                            key={eachOption.id}
                                                            onChange={this.setSelectedWorkflowId.bind(this, eachOption.id)}
                                                        />
                                                        <label
                                                            key={eachOption.id}
                                                            htmlFor={'' + eachOption.id}
                                                            className="k-radio-label"
                                                            title={"Approvers: " + eachOption.approvers}
                                                        >
                                                            {eachOption.name}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleWorkflowSelectDialogRR}>
                                No
                            </button>
                            <button
                                className="k-button modal-primary"
                                onClick={this.setWorkflowToResources}
                                disabled={!this.state.selectedWorkFlowId}
                            >
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

            </div>
        );
    }
}

export default ProjectResourceAllocation;
