import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;
const apiBase = apiUrl + '/projectManagement/invoice/'

/**
 * getProjectsAndCrs - retirive all PM allocated projects and crs
 * @returns {Object}  Object of projects and crs
 */
export  function getProjectsAndCrs(){
    const endPoint = apiBase + 'getProjectsAndCrs';
    return  http.get(endPoint);
}

/**
 * getBillableTimeEntries - retirive all billable time entries
 * @returns {Object}  Object of billable time entries
 * @param params
 */
export  function getBillableTimeEntries(params){
    const endPoint = apiBase + 'getBillableTimeEntries';
    return  http.post(endPoint, params);
}

/**
 * getForecastRevenue - retirive forecast revenue of project
 * @returns {Object}  Object of forecast revenue
 * @param params
 */
export  function getForecastRevenue(params){
    const endPoint = apiBase + 'getForecastRevenue';
    return  http.post(endPoint, params);
}

/**
 * getPendingApprovalTimeEntries - retirive all pending approval time entries
 * @returns {Object}  Object of pending approval time entries
 * @param params
 */
export  function getPendingApprovalTimeEntries(params){
    const endPoint = apiBase + 'getPendingApprovalTimeEntries';
    return  http.post(endPoint, params);
}

/**
 * saveInvoiceRequest - save Invoice Request
 * @returns {Object}  Object of saved Invoice Request
 * @param params
 */
export  function saveInvoiceRequest(params){
    const endPoint = apiBase + 'saveInvoiceRequest';
    return  http.post(endPoint, params);
}

/**
 * updateInvoiceRequest - update Invoice Request
 * @returns {Object}  Object of updated Invoice Request
 * @param params
 */
export  function updateInvoiceRequest(invoiceId, params){
    const endPoint = apiBase + 'updateInvoiceRequest/' + invoiceId;
    return  http.post(endPoint, params);
}

/**
 * searchInvoiceRequests - search Invoice Requests
 * @returns {Object}  Object of Invoice Requests
 * @param params
 */
export  function searchInvoiceRequests(params){
    const endPoint = apiBase + 'searchInvoiceRequests';
    return  http.post(endPoint, params);
}

/**
 * getAllProjectsAndCRsData - retirive all projects and crs
 * @returns {Object}  Object of projects and crs
 */
export  function getAllProjectsAndCRsData(params){
    const endPoint = apiBase + 'getAllProjectsAndCRsData';
    return  http.post(endPoint, params);
}

/**
 * getInvoiceCreatedUsers - retirive all invoice created users
 * @returns {Object}  Object of invoice created users
 */
export  function getInvoiceCreatedUsers(){
    const endPoint = apiBase + 'getInvoiceCreatedUsers';
    return  http.get(endPoint);
}

/**
 * getIRStatuses - retirive all invoice request status
 * @returns {Object}  Object of invoice request status
 */
export  function getIRStatuses(){
    const endPoint = apiBase + 'getIRStatuses';
    return  http.get(endPoint);
}

/**
 * Set WF for Invoice Requests.
 * @param workflowData.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function setInvoiceWorkflow(workflowData) {
    const endPoint = apiBase + '/setInvoiceWorkflow';
    return http.post(endPoint, workflowData);
}
  
/**
 * searchIRFData - search Invoice Requests Form
 * @returns {Object}  Object of Invoice Requests
 * @param params
 */
export  function searchIRFData(params){
    const endPoint = apiBase + 'searchIRFData';
    return  http.post(endPoint, params);
}

/**
 * approveInvoiceRequest - approve Invoice Request
 * @returns {Object}  Object of Invoice Requests
 * @param params
 */
export  function approveInvoiceRequest(params){
    const endPoint = apiBase + 'approveInvoiceRequest';
    return  http.post(endPoint, params);
}

/**
 * rejectInvoiceRequest - reject Invoice Request
 * @returns {Object}  Object of Invoice Requests
 * @param params
 */
export  function rejectInvoiceRequest(params){
    const endPoint = apiBase + 'rejectInvoiceRequest';
    return  http.post(endPoint, params);
}

/**
 * withdraw Invoice Request
 * @param params.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function withdrawInvoiceRequest(params) {
    const endPoint = apiBase + 'withdrawInvoiceRequest';
    return http.put(endPoint, params);
}

/**
 * revise Invoice Request
 * @param params.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function reviseInvoiceRequest(params) {
    const endPoint = apiBase + 'reviseInvoiceRequest';
    return http.put(endPoint, params);
}

/**
 * saveIRFData - save IRF Data
 * @returns {Object}  saved IRF Data
 * @param params
 */
export  function saveIRFData(params){
    const endPoint = apiBase + 'saveIRFData';
    return  http.post(endPoint, params);
}

/**
 * getInvoiceCycles - retirive all invoice cycles
 * @returns {Object}  Object of invoice cycles
 */
export  function getInvoiceCycles(){
    const endPoint = apiBase + 'getInvoiceCycles';
    return  http.get(endPoint);
}

/**
 * getAllProjectsAndCRsDataPendingIR - retirive all projects and crs
 * @returns {Object}  Object of projects and crs
 */
export  function getAllProjectsAndCRsDataPendingIR(params){
    const endPoint = apiBase + 'getAllProjectsAndCRsDataPendingIR';
    return  http.post(endPoint, params);
}

/**
 * getPendingInvoiceRequests - get Pending InvoiceRequests
 * @returns {Object}  saved IRF Data
 * @param params
 */
export  function getPendingInvoiceRequests(params){
    const endPoint = apiBase + 'getPendingInvoiceRequests';
    return  http.post(endPoint, params);
}

/**
 * updateMilestoneFile - update Milestone File
 * @returns {Object}  updated value
 * @param params
 */
export  function updateMilestoneFile(milestoneId){
    const endPoint = apiBase + 'updateMilestoneFile/' + milestoneId;
    return  http.post(endPoint);
}