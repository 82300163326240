import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem, PanelBarUtils, Menu, MenuItem, MenuItemModel, MenuItemLink, MenuItemArrow, Splitter } from '@progress/kendo-react-layout';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactDOM from 'react-dom';

import {getWorkflowTypeIntegrated, getWorkflowParameterIntegrated} from '../../integration/ProjectManagementService'
import {getWorkflowDefinitions} from './WorkflowCriteriaDefinitionService'
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import UpdatedDateCell from './components/UpdatedDateCell';
import UpdatedUserCell from './components/UpdatedUserCell';
import CriteriaActionsCell from './components/CriteriaActionsCell';

const workflowTypeDataAll = [];

class WorkflowCriteriaDefinitionSearch extends Component {

  constructor(props) {
    super(props);

    this.state = {
      workflowTypes       : [],
      selectedWFType      : null,
      workflowParameters  : [],
      workflowCriterias   : [],
      loadedData: false,
      sort                : [{ field: 'updatedAt', dir: 'desc' }]
    }
  }

  async componentDidMount() {

    this.setHeaderTitle();
    this.submitSearch();


  }

  setHeaderTitle = async () => {
    if (this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Workflow Parameters Definition');
    }
  };

    cancelSearch = () =>{
      this.setState({
        loadedData: false
      });
      this.submitSearch()
    }

    submitSearch = async event => {
        await getWorkflowTypeIntegrated()
            .then(response => {
                this.workflowTypeDataAll = response.data;
                this.setState({
                    workflowTypes : response.data,
                });
                if(!this.state.loadedData){
                  this.setState({
                      selectedWFType: this.workflowTypeDataAll[0],
                      loadedData: true
                  });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        await getWorkflowParameterIntegrated()
            .then(response => {
                const resp = response.data;
                const parameters = [];
                for(let value of resp){
                    if(value.Code !== "bid" && value.Code !== "bir" && value.Code !== "pmo"&& value.Code !== "rreg" && value.Code !== "areg" &&
                       value.Code !== "pres" && value.Code !== "curs" && value.Code !== "ends"){
                        parameters.push(value)
                    }
                }
                this.setState({
                    workflowParameters: parameters
                });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        await getWorkflowDefinitions()
            .then(response => {
                const definitions = response.data;
                const param = this.state.workflowParameters;
                let workflowParameter = []
                let updatedAt = '';
                let updatedUser = '';

                for (let val of param){
                    const definitionsForParam = [];
                    for (let i=0; i<definitions.length; i++){
                        if(definitions[i].ParameterId === val.id){
                            updatedAt = definitions[i].updatedAt;
                            updatedUser = definitions[i].updatedUser;
                            definitionsForParam.push(definitions[i]);
                            definitions.splice(i, 1);
                            i--;
                        }
                    }
                    val.definitions = definitionsForParam;
                    val.updatedAt = updatedAt;
                    val.updatedUser = updatedUser;
                    val.levels = definitionsForParam.length

                    if(typeof this.state.selectedWFType !== 'undefined'){
                      const selectedWFType = this.state.selectedWFType;
                      if(selectedWFType.id == 1){
                        if(val.Code == "rev" || val.Code == "npm" || val.Code == "nto" || val.Code == "dis" || val.Code == "con"){
                          workflowParameter.push(val)
                        }
                      } 
                      else if(selectedWFType.id == 3){
                        if(val.Code == "cvar"){
                          workflowParameter.push(val)
                        }
                      }
                      else if(selectedWFType.id == 5){
                        if(val.Code == "rev"){
                          workflowParameter.push(val)
                        }
                      }
                    }
                }
                this.setState({
                    workflowCriterias : workflowParameter
                })
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

  filterChangeWorkflowType = event => {
    this.setState({
      workflowTypes: this.filterDataWorkflowType(event.filter)
    });
  };

  filterDataWorkflowType(filter) {
    const data = this.workflowTypeDataAll.slice();
    return filterBy(data, filter);
  }

  handleChange = async event => {
    await this.setState({ selectedWFType: event.target.value});
  };

  render() {
        return (
            <div>
                <div className="main-card">

                    <div className="row">
                        <div className="col-md-8">
                            <div className="main-heading">Workflow Parameters Definition</div>
                        </div>
                    </div>


                        <div className="row">
                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="new-label mandatory">Workflow Type:</label>
                                </div>
                                <div className="d-block">
                                    <ComboBox
                                      placeholder="Please select"
                                      name="workflowType"
                                      data={this.state.workflowTypes}
                                      textField="Text"
                                      dataItemKey="id"
                                      value={this.state.selectedWFType}
                                      onChange={this.handleChange}
                                      required={true}
                                      filterable={true}
                                      onFilterChange={this.filterChangeWorkflowType}
                                      />
                                </div>
                            </div>

                            <div className="col-md-9">
                                <div className="d-block">
                                    <label className="new-label"></label>
                                </div>
                                <div className="d-block btn-align-right">
                                    <Button primary={true} type="submit" onClick={this.submitSearch}>
                                        Search
                                    </Button>
                                    <Button onClick={this.cancelSearch} >
                                        Clear
                                    </Button>
                                </div>
                            </div>
                        </div>

                    <div className="main-seperator" />

                    <div className="row">
                        <div className="col-md-8">
                            <div className="main-heading">Search Results</div>
                        </div>
                    </div>

                    <form onSubmit={this.submitSearch}>
                    <div className="row">
                        <div className="col-md-12">
                          <Grid data={orderBy(this.state.workflowCriterias, this.state.sort)}
                                resizable
                                sortable={true}
                                sort={this.state.sort}
                                onSortChange={(e) => {
                                  this.setState({
                                    sort: e.sort
                                  });
                                }
                                }
                                className="search-result-grid"
                          >
                            <Column field="Name" title="Criteria"/>
                            <Column
                              field="levels"
                              title="Levels"
                            />
                            <Column
                              field="updatedAt"
                              title="Last Modified Date"
                              cell={props => <UpdatedDateCell {...props} />}
                            />
                            <Column
                              field="updatedUser"
                              title="Last Updated User"
                              cell={props => <UpdatedUserCell {...props} />}
                            />
                            <Column
                              field=""
                              title="Actions"
                              cell={props => (
                                <CriteriaActionsCell
                                  {...props}
                                />
                              )}
                            />
                          </Grid>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default WorkflowCriteriaDefinitionSearch;