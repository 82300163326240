import React, { Component } from "react";

class OtherExpensesActionsCell extends Component {

  onClickAddOtherExpense = event => {
    event.preventDefault();
    this.props.onClickAddOtherExpense(this.props.dataItem);
  };

  onClickOtherExpenseHistory = event => {
    event.preventDefault();
    this.props.onClickOtherExpenseHistory(this.props.dataItem);
  };

  onClickEditOtherExpense = event => {
    event.preventDefault();
    this.props.onClickEditOtherExpense(this.props.dataItem);
  };

  onClickDeleteOtherExpense = event => {
    event.preventDefault();
    this.props.onClickDeleteOtherExpense(this.props.dataItem);
  };

  render() {
    return (
      <td className="action-buttons">
        {this.props.action && (this.props.action === 'create') && (
          <a href="#" onClick={this.onClickAddOtherExpense}>
            <div className="rounded-link">
                <span className="k-icon k-i-plus" title="Add Actual Cost" />
            </div>
        </a>
        )}
        {this.props.action && (this.props.action === 'create') && (this.props.dataItem.allowEditDelete) && (
          <a href="#" onClick={this.onClickEditOtherExpense}>
            <div className="rounded-link">
                <span className="k-icon k-i-edit" title="Edit Expense Type" />
            </div>
        </a>
        )}
        {this.props.action && (this.props.action === 'create') && (this.props.dataItem.allowEditDelete) && (
          <a href="#" onClick={this.onClickDeleteOtherExpense}>
            <div className="rounded-link">
                <span className="k-icon k-i-delete" title="Delete Expense Type" />
            </div>
        </a>
        )} 
        <a href="#" onClick={this.onClickOtherExpenseHistory}>
          <div className="rounded-link">
            <span className="k-icon k-i-clock" title="Actual Cost History" />
          </div>
        </a>   
      </td>
    );
  }

}

export default OtherExpensesActionsCell;
