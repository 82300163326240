import http from '../../integration/HttpService';
import { getConversionRateByCurrency } from '../../masterData/exchangeRate/ExchangeRateService';
import {getHolidaysIntegrated} from '../../integration/MasterDataService'
import { getFormattedDate, isWeekend, reStructureDate } from '../../integration/CommonUtils';
import moment from "moment";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import * as loggerService from "../../integration/LoggerService";

//import { apiUrl } from '../config.json';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;
const cmsheetRoute = apiUrl + '/projectManagement/cmsheet/';

const PROJECT_START_DATE = 'PROJECT';
const PHASE_START_DATE = 'PHASE';
const SUB_PHASE_START_DATE = 'SUB_PHASE';
const ALLOC_START_DATE = 'RESOURCE_ALLOCATION';
const CMS_CREATED_DATE = 'CMS_CREATED';

/**
 * Get CM sheet version.
 * @param projectId.
 * @returns {string} String representing CMS version.
 */
export function getCMSheetVersion(projectId) {
  const endPoint = cmsheetRoute + projectId + '/cmsheet/version';
  return http.get(endPoint);
}

/**
 * Get list of data of a CM sheet.
 * @param cmSheetId.
 * @param version.
 * @returns {string} String representing CMS version.
 */
export function getCMSheet(cmSheetId, version) {
  const endPoint = cmsheetRoute + cmSheetId + '/version/' + version;
  return http.get(endPoint);
}

/**
 * Get list of data of a CM sheet Other Expenses.
 * @param cmSheetId.
 * @param version.
 * @returns {string} String representing CMS version.
 */
export function getCMSheetOtherExpenses(cmSheetId, version) {
  const endPoint = cmsheetRoute + 'getCMSheetOtherExpenses/' + cmSheetId + '/version/' + version;
  return http.get(endPoint);
}

/**
 * Create a CM sheet.
 * @param projectId.
 * @param cmSheetCreatedDate.
 * @returns {string} String representing CMS version.
 */
export function createCMSheet(projectId, cmSheetCreatedDate) {
  const endPoint = cmsheetRoute + projectId + '/cmsheet';
  return http.post(endPoint, cmSheetCreatedDate);
}

/**
 * Save Phases in a CM sheet.
 * @param cmSheetId.
 * @param phases.
 * @returns {array} created/updated details of Phase.
 */
export function savePhases(cmSheetId, phases) {
  const endPoint = cmsheetRoute + cmSheetId + '/phases';
  return http.post(endPoint, phases);
}

/**
 * Save Phases in a CM sheet.
 * @param cmSheetId.
 * @param phases.
 * @returns {array} created/updated details of Phase.
 */
export function updatePhases(phases) {
  const endPoint = cmsheetRoute + '/updatePhases';
  return http.post(endPoint, phases);
}

export function savePhasesOfResourceRequests(cmSheetId, phases) {
  const endPoint = cmsheetRoute + cmSheetId + '/phases/resourcerequest';
  return http.post(endPoint, phases);
}

/**
 * Get Phases in a CM sheet.
 * @param cmSheetId.
 * @param phases.
 * @returns {array} created/updated details of Phase.
 */
export function getPhasesAndSubPhases(projectId) {
  const endPoint = cmsheetRoute + projectId + '/phases_SubPhases';
  return http.get(endPoint);
}

/**
 * get CMSheet by ProjectId And Type.
 * @param projectId.
 * @param type.
 * @returns {array} array object of cms details.
 */
 export function getCMSheetbyProjectIdAndType(projectId, type) {
  const endPoint = cmsheetRoute + '/projectId/' + projectId + '/type/' + type;
  return http.get(endPoint);
}

/**
 * Save CM sheet.
 * @param cmSheet.
 * @returns {array} array of object created/updated details of CMS.
 */
export function saveCMSheet(cmSheet) {
  const endPoint = cmsheetRoute;
  return http.put(endPoint, cmSheet);
}

/**
 * Approve a CM sheet.
 * @param params.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function approveCMSheet(params) {
  const endPoint = cmsheetRoute + 'approve';
  return http.put(endPoint, params);
}

/**
 * Reject a CM sheet.
 * @param params.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function rejectCMSheet(params) {
  const endPoint = cmsheetRoute + 'reject';
  return http.put(endPoint, params);
}

/**
 * Approve a Revise CM sheet request.
 * @param params.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function approveReviseCmsRequest(params) {
  const endPoint = cmsheetRoute + 'approveReviseCmsRequest';
  return http.put(endPoint, params);
}

/**
 * Reject a Revise CM sheet Request.
 * @param params.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function rejectReviseCmsRequest(params) {
  const endPoint = cmsheetRoute + 'rejectReviseCmsRequest';
  return http.put(endPoint, params);
}

/**
 * Withdraw a CM sheet.
 * @param params.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function withdrawCMSheet(params) {
  const endPoint = cmsheetRoute + 'withdraw';
  return http.put(endPoint, params);
}

/**
 * Pick a WF for CM sheet.
 * @param params.
 * @returns {array} array object WFs details.
 */
export function setWorkflow(params) {
  const endPoint = cmsheetRoute + 'workflow';
  return http.put(endPoint, params);
}

/**
 * Pick a WF for Project Status.
 * @param params.
 * @returns {array} array object WFs details.
 */
export function setWorkflowStatus(params) {
  const endPoint = cmsheetRoute + 'workflowStatus';
  return http.put(endPoint, params);
}

/**
 * Pick a WF for CM sheet.
 * @param params.
 * @returns {array} array object WFs details.
 */
export function setReviseCMSWorkflow(params) {
  const endPoint = cmsheetRoute + 'reviseCMSWorkflow';
  return http.post(endPoint, params);
}

/**
 * get CMSheetApprovals list for a CM sheet.
 * @param cmSheetId.
 * @param version.
 * @returns {array} array object of approver details.
 */
export function getCMSheetApprovals(cmSheetId, version) {
  const endPoint = cmsheetRoute + cmSheetId + '/version/' + version + '/approvals';
  return http.get(endPoint);
}

/**
 * get CMSheetApprovals list for a CM sheet.
 * @param cmSheetId.
 * @param version.
 * @returns {array} array object of approver details.
 */
export function getReviseCMSheetApprovals(cmSheetId, version) {
  const endPoint = cmsheetRoute + cmSheetId + '/version/' + version + '/revisecmsapprovals';
  return http.get(endPoint);
}

/**
 * get StatusChangeApprovals list for a project.
 * @param projectId.
 * @param activitylogId.
 * @returns {array} array object of approver details.
 */
export function getStatusChangeApprovals(projectId) {
  const endPoint = cmsheetRoute + projectId + '/activity' + '/approvals';
  return http.get(endPoint);
}

/**
 * get CMSheetApprovals list for a CM sheet.
 * @param cmSheetId.
 * @param version.
 * @returns {array} array object of approver details.
 */
export function getStatusApprovals(cmSheetId, version) {
  const endPoint = cmsheetRoute + cmSheetId + '/version/' + version + '/approvals';
  return http.get(endPoint);
}

/**
 * Revise a CM sheet.
 * @param cmSheetId.
 * @returns {array} array object of CMS details.
 */
export function reviseCMSheet(cmSheetId) {
  const endPoint = cmsheetRoute + 'revise/' + cmSheetId;
  return http.put(endPoint, null);
}

// export function getCMSheetsbyProjectId(projectId) {
//   const endPoint = cmsheetRoute + '/projectid/' + projectId;
//   return http.get(endPoint);
// }

/**
 * Delete a CM sheet.
 * @param cmSheetId.
 * @param projectId.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function deleteCMSheet(cmSheetId, projectId) {
  const endPoint = cmsheetRoute + cmSheetId;
  return http.delete(endPoint, null);
}

/**
 * upload s File to the CMSheet.
 * @param cmSheetId.
 * @param cmsFileData
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function uploadCMSheetFile(cmSheetId, cmsFileData) {
  const endPoint = cmsheetRoute + cmSheetId + '/file';
  return http.post(endPoint, cmsFileData);
}

/**
 * getCMSFilesByCMSheetIdandVersion - Retrieve files based on Cm sheet id and Version
 * params(cmSheetId, version)
 * @returns {array} List of File
 */
export function getCMSFilesByCMSheetIdandVersion(cmSheetId, version) {
  const endPoint = cmsheetRoute + cmSheetId + '/version/' + version + '/file';
  return http.get(endPoint, null);
}

/**
 * Delete a File of CM sheet.
 * @returns {object}  response 0 or 1 (true or false) as an array
 * @param fileID
 */
export function deleteCMSFile(fileID, cmsheetId, cmsheetVersion) {
  const endPoint = cmsheetRoute + 'deleteCMSFile/' + fileID + '/cmsheetId/' + cmsheetId + '/cmsheetVersion/' + cmsheetVersion;
  return http.get(endPoint, null);
}

export function getCmsTypes() {
  const endPoint = cmsheetRoute + 'getCmsTypes';
  return http.get(endPoint);
}

/**
 * Calculates and convert the date difference to the human readable format.
 * @param startDate Starting date.
 * @param endDate End date.
 * @returns {string} String representing number of months and days.
 */
export function calculateDuration(startDate, endDate) {

  //Format dates to the Date objects.
  const endDateFormatted = reStructureDate(endDate);
  const startDateFormatted = reStructureDate(startDate);
  // Get the month from the start date and end date.
  const startMonth = startDateFormatted.getMonth();
  const startYear = startDateFormatted.getFullYear();

  // Get the year from the start date and the end date.
  let endYear = endDateFormatted.getFullYear();
  let endMonth = endDateFormatted.getMonth();

  // Get the date from the start date and end date.
  const startDay = startDateFormatted.getDate();
  let endDay = endDateFormatted.getDate() + 1;

  // These variables are using to determine the dates of the month.
  let beforeDays = 0;
  let endDays = 0;

  // If the month is ether January, March, May, July, August, October or December days for the month will be 31.
  // This please do add the type checking "===" equalizer because Date will return the numeric when calling "getMonth", "getFullYear" or "getDate" functions
  if ((endMonth === 0) || (endMonth === 2) || (endMonth === 4) || (endMonth === 6) || (endMonth === 7) || (endMonth === 9) || (endMonth === 11)) {
    endDays = 31;
  }
  if ((startMonth === 0) || (startMonth === 2) || (startMonth === 4) || (startMonth === 6) || (startMonth === 7) || (startMonth === 9) || (startMonth === 11)) {
    beforeDays = 31;
  }

  // If the month is ether April, June, September or November days for the month will be 30.
  if ((endMonth === 3) || (endMonth === 5) || (endMonth === 8) || (endMonth === 10)) {
    endDays = 30;
  }
  if ((startMonth === 3) || (startMonth === 5) || (startMonth === 8) || (startMonth === 10)) {
    beforeDays = 30;
  }

  // For the month of February for the leap years amount of days will be 29.
  if (endMonth === 1 && ((endYear % 4 === 0) && (endYear % 100 !== 0)) || (endYear % 400 === 0)) {
    endDays = 29;
  }
  if (startMonth === 1 && ((startYear % 4 === 0) && (startYear % 100 !== 0)) || (startYear % 400 === 0)) {
    beforeDays = 29;
  }

  // For non leap years days for the February month will be 28.
  if (endMonth === 1 && ((endYear % 4 !== 0) || (endYear % 100 === 0))) {
    endDays = 28;
  }
  if (startMonth === 1 && ((startYear % 4 !== 0) || (startYear % 100 === 0))) {
    beforeDays = 28;
  }

  let firstMonthDiff = beforeDays - startDay + 1;

  if (endDay - startDay < 0) {
    endMonth = endMonth - 1;
    endDay = endDay + beforeDays;
  }

  let daysDiff = endDay - startDay;
  //console.log("daysDiff : "+ daysDiff);

  if (endMonth - startMonth < 0) {
    endYear = endYear - 1;
    //console.log("endYear : "+ endYear);
    endMonth = endMonth + 12;
    //console.log("endMonth : "+ endMonth);
  }

  let monthDiff = endMonth - startMonth;
  //console.log("monthDiff : "+ monthDiff);

  let yearDiff = endYear - startYear;
  //console.log("yearDiff : "+ yearDiff);

  if (daysDiff === endDays) {
    daysDiff = 0;
    monthDiff = monthDiff + 1;
    //console.log("monthDiff : "+ monthDiff);

    if (monthDiff === 12) {
      monthDiff = 0;
      yearDiff = yearDiff + 1;
      //console.log("yearDiff : "+ yearDiff);
    }
  }

  if ((firstMonthDiff !== beforeDays) && (endDay - 1 === endDays)) {
    daysDiff = firstMonthDiff;
    //console.log("daysDiff : "+ daysDiff);
  }

  let humanReadableString = '';

  if (yearDiff !== 0) {
    if (yearDiff > 1) {
      humanReadableString = humanReadableString + yearDiff + ' Years' + ' ';
    } else {
      humanReadableString = humanReadableString + yearDiff + ' Year' + ' ';
    }
  }

  if (monthDiff !== 0) {
    if (monthDiff > 1) {
      humanReadableString = humanReadableString + monthDiff + ' months ';
    } else {
      humanReadableString = humanReadableString + monthDiff + ' month ';
    }
  }

  if (daysDiff !== 0) {
    if (daysDiff > 1) {
      humanReadableString = humanReadableString + daysDiff + ' days';
    } else {
      humanReadableString = humanReadableString + daysDiff + ' day';
    }
  }
  return humanReadableString;
}


/**
 * Calculate prioritized date to retrieve cost for designation.
 * @param allocation allocation details
 * @param cmSheet CM Sheet
 * @param project project which cost is calculated for.
 * @returns {Date}
 */
export function calculateCostDate(allocation, cmSheet, project) {
  let costDate = new Date();
  let subPhaseStartDate = null;
  let phaseStartDate = null;

  const phaseFilter = cmSheet.Phases.filter(obj => obj.id === allocation.PhaseId);
  if (phaseFilter.length > 0) {
    if (allocation.SubPhaseId > 0) {
      const subPhases = phaseFilter[0].SubPhases;
      const subPhaseFilter = subPhases.filter(obj => obj.id === allocation.SubPhaseId);
      if (subPhaseFilter.length > 0) {
        subPhaseStartDate = subPhaseFilter[0].StartDate;
      }
    } else {
      phaseStartDate = phaseFilter[0].StartDate;
    }
  }

  // set startDate by priority
  if (allocation.StartDate) {
    costDate = reStructureDate(allocation.StartDate);
  } else if (subPhaseStartDate) {
    costDate = reStructureDate(subPhaseStartDate);
  } else if (phaseStartDate) {
    costDate = reStructureDate(phaseStartDate);
  } else if (project && project.StartDate) {
    costDate = reStructureDate(project.StartDate);
  } else if (cmSheet.LastRevisedDate) {
    costDate = reStructureDate(cmSheet.LastRevisedDate);
  } else if(cmSheet.Status && cmSheet.Status === 'APPROVED'){
    const tempDate = reStructureDate(cmSheet.updatedAt);
    costDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate());
  } else  {
    const tempDate = new Date();
    costDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate());
  }

  let formattedCostDate = costDate? moment(costDate).format('YYYY-MM-DD') : costDate;

  return formattedCostDate;
}

/**
 * Retrives conversion rate for a currency for given month and year.
 * @param currencyId
 * @param month
 * @param year
 * @returns {Promise<AxiosResponse<any> | number>}
 */
export async function retrieveConversionRate(currencyId, month, year) {
  let rate = 0;
  rate = await getConversionRateByCurrency(currencyId, month, year)
    .then(resCR => {
      if (resCR.data.length > 0 && resCR.data[0].id) {
        const cRateRecord = resCR.data[0];
        return cRateRecord.CurrencyConversionDetails[0].RatePerUSD;
      } else {
        return 0;
      }
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
      return -2; // error
    });

  return rate;
}

/**
 * Calculates the estimated project end date while counting in holidays and weekends.
 * @param allocation
 * @param startDate
 * @param countryId
 * @returns {Promise<{noOfWorkingDays: number, totalDays: number, endDate: Date}>}
 */
export async function calculateProjectEndDate(allocation, startDate, countryId) {
  let startDateObj = reStructureDate(startDate);
  let endDate;

  // estimate end date
  const noOfWorkingDays = (allocation.EffortDays * 100) / allocation.AllocationPercentage;

  let totalDays = 1;
  let workingDaysCount = 1;

  let holidays = await getHolidaysIntegrated(countryId, startDateObj.getFullYear()).then(
    res => {
      if (res && res.data) {
        return res.data;
      }
      return [];
    }
  )
  .catch(error => {
    loggerService.writeLog(error, LOG_TYPES.ERROR);
  });

  if (holidays && holidays.length === 0) {
    console.log('Holidays not found for ' + startDateObj.getFullYear());
  } else if (!holidays) {
    holidays = [];
  }

  endDate = reStructureDate(startDate);
  let currentYear = startDateObj.getFullYear();
  let isHoliday = false;

  const dateStr = getFormattedDate(endDate);
  if (holidays.indexOf(dateStr) > -1) {
    isHoliday = true;
  }

  if (isWeekend(endDate) || isHoliday) {      
    workingDaysCount = 0;    
  }

  while (workingDaysCount < noOfWorkingDays || isWeekend(endDate) || isHoliday) {
    if (currentYear !== endDate.getFullYear()) {
      holidays = await getHolidaysIntegrated(countryId, endDate.getFullYear())
        .then(res => {
          if (res && res.data) {
            return res.data;
          }
          return [];
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          return [];
        });

      currentYear = endDate.getFullYear();
    }

    totalDays++;
    endDate.setDate(endDate.getDate() + 1);

    isHoliday = false;
    const dateStr = getFormattedDate(endDate);
    if (holidays.indexOf(dateStr) > -1) {
      isHoliday = true;
    }

    if (!isWeekend(endDate) && !isHoliday) {
      workingDaysCount++;
    }
  }

  endDate.setDate(endDate.getDate());
  return { endDate, noOfWorkingDays, totalDays };
}

/**
 * Get prioritized project start date.
 * @param allocation
 * @param project
 * @param cmSheet
 */
export function getStartDateByPriority(allocation, project, cmSheet) {
  let startDate = {};
  startDate.date = new Date();

  // set startDate by priority
  if (allocation.DateStartDate) {
    startDate.date = reStructureDate(allocation.DateStartDate);
    startDate.type = ALLOC_START_DATE;
  } else if (allocation.dataValueSubPhase && allocation.dataValueSubPhase.StartDate) {
    startDate.date = reStructureDate(allocation.dataValueSubPhase.StartDate);
    startDate.type = SUB_PHASE_START_DATE;
  } else if (allocation.dataValuePhase && allocation.dataValuePhase.StartDate) {
    startDate.date = reStructureDate(allocation.dataValuePhase.StartDate);
    startDate.type = PHASE_START_DATE;
  } else if (project && project.StartDate) {
    startDate.date = reStructureDate(project.StartDate);
    startDate.type = PROJECT_START_DATE;
  } else {
    const tempDate = new Date();
    startDate.date = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate());
    startDate.type = CMS_CREATED_DATE;
  }
  return startDate;
}


/**
 * Calculates the working days for the old duration.
 * @param totalDays
 * @param startDate
 * @param countryId
 * @returns {Promise<{noOfWorkingDays: number, totalDays: number, endDate: Date}>}
 */
export async function calculateWorkingDateByDuration(totalDays, startDate, countryId) {
  let endDateOld;

  let startDateObj = new Date(startDate);

  // estimate end date
  let noOfWorkingDays = totalDays;

  let totalDaysOld = 0;
  let workingDaysCountOld = 0;

  let holidays = await getHolidaysIntegrated(countryId, startDateObj.getFullYear()).then(
    res => {
      if (res && res.data) {
        return res.data;
      }
      return [];
    }
  )
  .catch(error => {
    loggerService.writeLog(error, LOG_TYPES.ERROR);
  });

  if (holidays && holidays.length === 0) {
  } else if (!holidays) {
    holidays = [];
  }

  endDateOld = new Date(startDate);
  let currentYear = startDateObj.getFullYear();

  while (noOfWorkingDays > 0) {
    if (currentYear !== endDateOld.getFullYear()) {
      holidays = await getHolidaysIntegrated(countryId, endDateOld.getFullYear())
        .then(res => {
          if (res && res.data) {
            return res.data;
          }
          return [];
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          return [];
        });

      currentYear = endDateOld.getFullYear();
    }

    totalDaysOld++;
    noOfWorkingDays--;
    endDateOld.setDate(endDateOld.getDate() + 1);

    let isHoliday = false;
    const dateStr = getFormattedDate(endDateOld);
    if (holidays.indexOf(dateStr) > -1) {
      isHoliday = true;
    }

    if (!isWeekend(endDateOld) && !isHoliday) {
      workingDaysCountOld++;
    }
  }

  endDateOld.setDate(endDateOld.getDate());
  return { endDateOld, workingDaysCountOld, totalDaysOld };
}

/**
 * Calculates the estimated project end date while counting in holidays and weekends based on Project start date.
 * @param totalEffortDays
 * @param startDate
 * @param countryId
 * @returns {Promise<{endDateNew: Date, workingDaysCountNew: number, totalDaysNew: number}>}
 */
export async function calculateProjectEndDateByProjectStartDate(totalEffortDays, startDate, countryId) {
  let endDateNew;
  let startDateObj = new Date(startDate);

  // estimate end date
  let noOfWorkingDays = totalEffortDays;

  let totalDaysNew = 0;
  let workingDaysCountNew = 0;

  let holidays = await getHolidaysIntegrated(countryId, startDateObj.getFullYear()).then(
    res => {
      if (res && res.data) {
        return res.data;
      }
      return [];
    }
  )
  .catch(error => {
    loggerService.writeLog(error, LOG_TYPES.ERROR);
  });

  if (holidays && holidays.length === 0) {
  } else if (!holidays) {
    holidays = [];
  }

  endDateNew = new Date(startDateObj.getTime());
  let currentYear = startDateObj.getFullYear();
  let isHoliday = false;

  while (workingDaysCountNew < noOfWorkingDays - 1 || isWeekend(endDateNew) || isHoliday) {
    if (currentYear !== endDateNew.getFullYear()) {
      holidays = await getHolidaysIntegrated(countryId, endDateNew.getFullYear())
        .then(res => {
          if (res && res.data) {
            return res.data;
          }
          return [];
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          return [];
        });

      currentYear = endDateNew.getFullYear();
    }

    totalDaysNew++;
    endDateNew.setDate(endDateNew.getDate() + 1);

    isHoliday = false;
    const dateStr = getFormattedDate(endDateNew);
    if (holidays.indexOf(dateStr) > -1) {
      isHoliday = true;
    }

    if (!isWeekend(endDateNew) && !isHoliday) {
      workingDaysCountNew++;
    }
  }

  endDateNew.setDate(endDateNew.getDate());
  return { endDateNew, workingDaysCountNew, totalDaysNew };
}

