import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class DateCell extends Component {
  render() {
    const value = this.props.dataItem.updatedAt;
    const approvalStatus = this.props.dataItem.Status;
    if (approvalStatus === 'Pending') {
      return <td>{''}</td>
    } else {
      return <td><div className="date-time"><Moment format="MM-DD-YYYY hh:mm:ss A">
      {value}
    </Moment></div></td>;
    }
  }
}

export default DateCell;