import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {getFormattedDate} from "../../integration/CommonUtils";

class ResourceRequestFunctions extends Component{
    render() {
        const project = this.props.dataItem;
        let isProjectManager = false
        let isConvertRequest = false
        let isWaitingConfirm = false;
        let preSalesPM = false;
        let pendingReviseCMS = [];     

        const projectProjectManagers = project.AllocatedResourceRequestPM;
        //find valid pms from end date
        let projValidManagers = [];
        projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

        if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
            projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
            const lastAssignedManager = projectProjectManagers[0];
            projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
        }

        projValidManagers.forEach(value => {
            if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
                preSalesPM = true;
            }
        });

        // if (project.ProjectStatus.StatusName === 'Working At Risk' || project.ProjectStatus.StatusName === 'Active' || project.ProjectStatus.StatusName === 'Complete' ||
        // project.ProjectStatus.StatusName === 'Closed' || project.ProjectStatus.StatusName === 'Closed-Opportunity Loss' || 
        // project.ProjectStatus.StatusName === 'Close without bidding' || project.ProjectStatus.StatusName === 'Discontinue') {
            if (project.StatusConvertedToActive === true) {
                isConvertRequest = true;
            }
        // }

        if(project.ProjectStatus.StatusName === 'Waiting Confirmation') {
            if (preSalesPM) {
                isWaitingConfirm = true
            }
        }

        const reviseCMSrequestsProjects = project.ReviseCMSRequest;
        if (reviseCMSrequestsProjects && reviseCMSrequestsProjects.length > 0) {
            const sortedArray = reviseCMSrequestsProjects.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            if(sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
                (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)
            ) {
                pendingReviseCMS.push(true);
            } else {
                pendingReviseCMS.push(false);
            }
        } else {
            pendingReviseCMS.push(false);
        }

        for (const cr of project.ChangeRequests) {
            const crProjectManagers = cr.AllocatedPM;
            //find valid pms from end date
            let crValidManagers = [];
            crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

            if (crValidManagers.length == 0 && crProjectManagers.length > 0){
                crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
                const lastAssignedManager = crProjectManagers[0];
                crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
            }
            crValidManagers.forEach(value => {
                if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
                    preSalesPM = true;
                }
            });

            // if (cr.Status.StatusName === 'Working At Risk' || cr.Status.StatusName === 'Active' || cr.Status.StatusName === 'Billable with CR' || 
            // cr.Status.StatusName === 'Complete' || cr.Status.StatusName === 'Closed' || cr.Status.StatusName === 'Closed-Opportunity Loss' || 
            // cr.Status.StatusName === 'Close without bidding' || cr.Status.StatusName === 'Discontinue') {
                if (cr.StatusConvertedToActive === true) {
                    isConvertRequest = true;
                }
            // }
            if(cr.Status.StatusName === 'Waiting Confirmation') {
                if (preSalesPM) {
                  isWaitingConfirm = true
                }
            }

            const reviseCMSrequests = cr.ReviseCMSRequest;// need to changeeeeees
            if (reviseCMSrequests && reviseCMSrequests.length > 0) {
                const sortedArray = reviseCMSrequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                if(sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
                (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)
              ) {
                pendingReviseCMS.push(true);
              } else {
                pendingReviseCMS.push(false);
              }
            } else {
                pendingReviseCMS.push(false);
            }
        }

        if (project.PresalesResource && project.PresalesResource.Email && window.LOGGED_USER && window.LOGGED_USER.userId &&
            project.PresalesResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
            isProjectManager = true;
        }

        let allocatedProjectManager = false;
        if (project.ResourceRequestStatus === 'PM_ALLOCATED') {
            const projectProjectManagers = project.AllocatedResourceRequestPM.filter(manager => !manager.IsPresales);

            //find valid pms from end date
            let projValidManagers = [];
            projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

            if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
                //projValidManagers.push(projectProjectManagers[0]);

                projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
                const lastAssignedManager = projectProjectManagers[0];
                projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

            }

            projValidManagers.forEach(value => {
                if(!value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
                    allocatedProjectManager = true;
                }
            });
        }

        if (project.ChangeRequests.length > 0) {
            for(const cr of project.ChangeRequests){
                if (cr.ResourceRequestStatus === 'PM_ALLOCATED') {
                    const crProjectManagers = cr.AllocatedPM.filter(manager => !manager.IsPresales);

                    //find valid pms from end date
                    let crValidManagers = [];
                    crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

                    if (crValidManagers.length == 0 && crProjectManagers.length > 0){
                        //crValidManagers.push(crProjectManagers[0]);
                        crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
                        const lastAssignedManager = crProjectManagers[0];
                        crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

                    }
                    crValidManagers.forEach(value => {
                        if(!value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
                            allocatedProjectManager = true;
                        }
                    });

                }
            }
        }

        const checkPendingReviseCMSStatus = pendingReviseCMS.some(val => val === false);

        return (
            <td className="action-buttons">

                {((allocatedProjectManager === true || isWaitingConfirm === true) && checkPendingReviseCMSStatus) && (
                    <Link
                        to={{
                            pathname: '/ProjectResourceRequest/ProjectResourceRequest',
                            action: 'create',
                            project: project
                        }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-edit" title="Edit" />
                        </div>
                    </Link>
                )}

                {(window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_RES_REQ') > -1))) && (isConvertRequest === true || isWaitingConfirm === true) && (
                    <Link
                        to={{
                            pathname: '/ProjectResourceRequest/ProjectResourceRequest',
                            action: 'view',
                            project: project
                        }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-preview" title="View" />
                        </div>
                    </Link>
                )}
            </td>
        );
    }
}

export default ResourceRequestFunctions;