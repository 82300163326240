/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {getActivityLogDataSort} from "../../projectManagement/project/ProjectService";
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import {getFormattedDate} from "../../integration/CommonUtils";
const closeSting = 'close';

class ProjectFunctions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sortedActivity:[]
    }
  }

  componentDidMount(){
    this.populateIsPendingStatus();
  }

  populateIsPendingStatus = async () => {
    const project = this.props.dataItem;

    if(project){
      const statusChangeObj = {
        projectId: project.id,
        crId: null,
      };

      await this.populateSortedActivityLogData(statusChangeObj.projectId,statusChangeObj.crId,true);
    }
  }

  populateSortedActivityLogData = async (projectId,crId,isPending) => {
    let ids = [];
    ids  = {
      projectId : projectId,
      crId: crId,
      isPending:isPending
    }

    await getActivityLogDataSort(ids).then(response => {
      this.setState({
        sortedActivity: response.data
      });
    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };


  handleChangeStatusPopUp = event => {
    event.preventDefault();
    const project = this.props.dataItem;
    if (project) {
      this.props.handleChangeStatusPopUp(project);
    }
  };


  handleDeleteProject = event => {
    event.preventDefault();
    const project = this.props.dataItem;
    if (project) {
      this.props.onDeleteProject(project.id);
    }
  };

    handleViewCR = ( event, project) =>{
        event.preventDefault();
        this.props.onEditCR(event, project.ChangeRequests, 'view');
    };

    handleEditCR = (event, presaleCRs) => {
        event.preventDefault();
        this.props.onEditCR(event, presaleCRs, 'edit');
    };

  render() {
    const project = this.props.dataItem;
    let isLoggedAssignedPM = false;
    let isPendingStatus = false;

    if(project.ActivityLogs && project.ActivityLogs.length > 0){
      project.ActivityLogs.sort((a, b) => (b.createdAt > a.createdAt) ? 1 : -1);

      if(project.ActivityLogs[0].ApprovalStatus && project.ActivityLogs[0].ApprovalStatus === 'PENDING'){
        isPendingStatus = true;
      }
      else{
        isPendingStatus = false;
      }
    }
    

    if (
      project.PresalesResource &&
      project.PresalesResource.Email &&
      window.LOGGED_USER &&
      window.LOGGED_USER.userId &&
      project.PresalesResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()
    ) {
    }

    let isClosedStatus = false;
    if (project.ProjectStatus) {
      let projectName = project.ProjectStatus.StatusName;
      projectName
          .toString()
          .toLowerCase()
          .includes(closeSting);
    }
      project.ChangeRequests.filter(obj => (obj.Status.Code === 'PRESALES' || obj.Status.Code === 'WAIT_CONFIRM' ||
          obj.Status.Code === 'BILLABLE' || obj.Status.Code === 'ON_HOLD' || obj.Status.Code === 'WORK_AT_RISK'));
      let projectManager = false;
      let projectStatusDef = '';
      let pendingCMSApprovals = false;
      const editProjPermission = window.USER_PERMISSION.filter(obj => obj === 'EDT_PRJ');
      const createProjPermission = window.USER_PERMISSION.filter(obj => obj === 'CRT_PRJ');
      const deleteProjPermission = window.USER_PERMISSION.filter(obj => obj === 'DLT_PRJ');
      const viewProjPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_PRJ');
      const createPoolProjPermission = window.USER_PERMISSION.filter(obj => obj === 'FAC_PRO');
      const viewPoolProjPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_FAC_PRJ');
      const viewStatusChangePermission = window.USER_PERMISSION.filter(obj => obj === 'VIEW_CHANGE_STATUS');
      const changeToStatusPermissions = window.USER_PERMISSION.filter(obj => obj === 'CHG_TO_WAITING_CONFIRMATION' || obj === 'CHG_TO_CLOSE_DISCONTINUE' ||
          obj === 'CHG_TO_PROJECT_ON_HOLD' || obj === 'CHG_TO_PRESALE' || obj === 'CHG_TO_CLOSE_WITHOUT_BIDDING' || obj === 'CHG_TO_CLOSE_OPPORTUNITY_LOST' ||
          obj === 'CHG_TO_DEAL_ON_HOLD' || obj === 'CHG_TO_WORKING_AT_RISK' || obj === 'CHG_TO_ACTIVE' || obj === 'CHG_TO_COMPLETE' || obj === 'CHG_TO_CLOSED');


    const projectProjectManagers = project.AllocatedResourceRequestPM;

    //find valid pms from end date
    let projValidManagers = [];
    projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

    if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
      //projValidManagers.push(projectProjectManagers[0]);
      projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
      const lastAssignedManager = projectProjectManagers[0];
      projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

    }

    projValidManagers.forEach(value => {
      if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
        projectManager = true;
      }
    });

      if (project.ProjectStatus) {
          let projectStatusName = project.ProjectStatus.StatusName;
          projectStatusDef = projectStatusName;
      }

      const cmSheets = project.CMSheets;
      if (cmSheets) {
          for (const cmSheet of cmSheets) {
              if (cmSheet.Status === 'PENDING_APPROVALS') {
                  pendingCMSApprovals = true
                  break;
              }
          }
      }

      let displayBtn;

      for(let el of changeToStatusPermissions){
        switch(el){
          case 'CHG_TO_DEAL_ON_HOLD' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM')){
              displayBtn = true;
            }
            break;
          }
          case 'CHG_TO_PRESALE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD')){
              displayBtn = true;
            }
            break;
          }
          case 'CHG_TO_WAITING_CONFIRMATION' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES')){
              displayBtn = true;
            }
            break;
          }
          case 'CHG_TO_WORKING_AT_RISK' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM')){
              displayBtn = true;
            }
            break;
          }
          case 'CHG_TO_ACTIVE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'WORK_AT_RISK' || project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM' ||
            project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PROJECT_ON_HOLD')){
              displayBtn = true;
            }
            break;
          }
          case 'CHG_TO_PROJECT_ON_HOLD' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'WORK_AT_RISK' || project.ProjectStatus.Code == 'BILLABLE')){
              displayBtn = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_WITHOUT_BIDDING' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES')){
              displayBtn = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_OPPORTUNITY_LOST' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM')){
              displayBtn = true;
            }
            break;
          }
          case 'CHG_TO_COMPLETE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'BILLABLE')){
              displayBtn = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_DISCONTINUE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'BILLABLE' || project.ProjectStatus.Code == 'WORK_AT_RISK' || project.ProjectStatus.Code == 'PROJECT_ON_HOLD')){
              displayBtn = true;
            }
            break;
          }
          case 'CHG_TO_CLOSED' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'COMPLETE')){
              displayBtn = true;
            }
            break;
          }
          default : {
          }
        }
      }

      if(viewStatusChangePermission && viewStatusChangePermission.length > 0){
          displayBtn = true;
      }

      var displayStatusButton = {
          display: displayBtn === true ? 'block' : 'none'
      }



      return (
      <table>
        <tbody>
      <td className="action-buttons">
        Status : {project.ProjectStatus ? project.ProjectStatus.StatusName : ''}
        <br />
          { (  ((viewProjPermission && viewProjPermission.length>0) || projectManager === true) && project.IsPoolProject === false) && (
              <Link
                  to={{
                      pathname: '/projects/create/create',
                      projectId: project.id,
                      action: 'view'
                  }}
              >
                  <div className="rounded-link" title="View">
                      <span className="k-icon k-i-preview" />
                  </div>
              </Link>
          )}

          { ((viewPoolProjPermission && viewPoolProjPermission.length>0))&& (project.IsPoolProject === true) && (
              <Link
                  to={{
                      pathname: '/PoolProject/FactoryMain',
                      projectId: project.id,
                      action: 'view'
                  }}
              >
                  <div className="rounded-link" title="View">
                      <span className="k-icon k-i-preview" />
                  </div>
              </Link>
          )}

          { ( (((editProjPermission && editProjPermission.length>0) || projectManager === true ) && (project.IsPoolProject === false)) &&
              ( (  (projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' ) && pendingCMSApprovals===false) ) &&
              (!isPendingStatus)
          ) &&(
              <Link
                  to={{
                      pathname: '/projects/create/create',
                      projectId: project.id,
                      action: 'edit'
                  }}
              >
                  <div className="rounded-link" title="Edit">
                      <span className="k-icon k-i-edit" />
                  </div>
              </Link>
          )}

          { (((createPoolProjPermission && createPoolProjPermission.length>0) && (project.IsPoolProject === true)) &&
              (( (projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' )  && pendingCMSApprovals===false) )
          ) &&(
              <Link
                  to={{
                      pathname: '/PoolProject/FactoryMain',
                      projectId: project.id,
                      action: 'edit'
                  }}
              >
                  <div className="rounded-link" title="Edit">
                      <span className="k-icon k-i-edit" />
                  </div>
              </Link>
          )}

        {/* <a href="#">
          <div class="rounded-link" title="Approve">
            <span class="k-icon k-i-track-changes-accept" />
          </div>
        </a> */}

          {  ((projectManager === true || (deleteProjPermission && deleteProjPermission.length>0) ) || (createPoolProjPermission && createPoolProjPermission.length>0))  && project.CMSheets.length === 0 && (
              <a href="#" onClick={this.handleDeleteProject}>
                  <div className="rounded-link" title="Delete">
                      <span className="k-icon k-i-delete" />
                  </div>
              </a>
          )}

        {/*
          * remove this to enable RES_REQ*/}

          {(project.ProjectStatus.Code === 'WORK_AT_RISK' || project.ProjectStatus.Code === 'BILLABLE') &&  ((editProjPermission && editProjPermission.length > 0) || (createProjPermission && createProjPermission.length > 0)) && (project.StatusConvertedToActive === null) &&
            (!isPendingStatus) && (
              <Link
                  to={{
                      changeReqId: null,
                      ProjectId: project.id,
                      Project: project,
                      Type: 'Project',
                      pathname: '/ResourceManagement/ResourceRequestActivation',
                      action: 'activate'
                  }}
              >
                  <div className="rounded-link">
                      <span className="k-icon k-i-check" title="Activate" />
                  </div>
              </Link>
          )}

        {/* <a href="#">
          <div class="rounded-link" title="Close">
            <span class="k-icon k-i-close" />
          </div>
        </a> */}

        {/**change status popup button*/}
        {(((viewStatusChangePermission && viewStatusChangePermission.length > 0) || (changeToStatusPermissions && changeToStatusPermissions.length > 0)) && (project.IsPoolProject === false)) && (
            <a href="#" onClick={this.handleChangeStatusPopUp}>
                <div className="rounded-link" title="Status Change" style={displayStatusButton}>
                    <span className="k-icon k-i-wrench" />
                </div>
            </a>
        )}

      </td></tbody></table>
    );
  }
}

export default ProjectFunctions;
