import React, { Component } from 'react';
import Moment from 'react-moment';

class UpdatedUserCell extends Component {
  render() {
    if(this.props.dataItem.definitions.length > 0){
      const value = this.props.dataItem.definitions[0].updatedUser;
      return <td>
        {value}
      </td>;
    }else{
      return <td>
        {""}
      </td>;
    }
  }
}

export default UpdatedUserCell;