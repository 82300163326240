import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import {getFormattedDate} from "../../../integration/CommonUtils";

const billableString = 'BILLABLE';
const billablecrString = 'BILLABALE_CR';
const closeString = 'CLOSED';
const closeoplostString = 'CLOSE_OP_LOST';
const closewithoutbidString = 'CLOSE_WITHOUT_BID';
const closeSting = 'close';

class AllDataCell extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleViewCMS = (event , project, projectCMS, crCMS) =>{
    event.preventDefault();
    this.props.onSelectCMS(project, 'view', projectCMS, crCMS);
  };

  hanldeEditCMS = (event, project, projectCMS, crCMS) =>{
    event.preventDefault();
    this.props.onSelectCMS(project, 'edit', projectCMS, crCMS);
  }

  handleChangeStatusPopUp = event => {
    event.preventDefault();
    const project = this.props.dataItem;
    if (project) {
      this.props.handleChangeStatusPopUp(project);
    }
  };

  handleStatusPopUpCR = ( event, project) =>{
    event.preventDefault();
    this.props.handleStatusPopUpCR(event, project, 'HandleCrPopUp');
  };

  handleEditCR = (event, proj, isProjectManager, hasEditCRPermission) => {
    event.preventDefault();
    this.props.onEditCR(event, proj, isProjectManager, hasEditCRPermission);
  };

  onClickProjectSection = (event, action, param) => {
      event.preventDefault();

    // Change request create
    if(action === 'create'){
      this.setState({
        redirectToCreateCR : true
      });
    }
    // Project Edit
    else if(action === 'edit'){
      this.setState({
        redirectToEditProject : true
      });
    }
    // Project View
    else if(action === 'view'){
      this.setState({
        redirectToViewProject : true
      });
    }

  }
  render() {
    const project = this.props.dataItem;
    const projectStatusCode = project.ProjectStatus ? project.ProjectStatus.Code : '';

    let programeName = '';
    let customerName = '';
    let regionName = '';
    let divisionName = '';
    let PMName = '';
    let projectStatus = '';
    let isPendingStatusProj = false;
    let isPendingStatusCr = false;
    let editableCR = true;

    if (this.props.dataItem.Program) {
      programeName = this.props.dataItem.Program.Name;
    }
    if (this.props.dataItem.Customer) {
      customerName = this.props.dataItem.Customer.Name;
    }
    if (this.props.dataItem.Region) {
      regionName = this.props.dataItem.Region.Name;
    }
    if (this.props.dataItem.Division) {
      divisionName = this.props.dataItem.Division.Name;
    }
    if (this.props.dataItem.PresalesResource) {
      PMName = this.props.dataItem.PresalesResource.Name;
    }
    if (this.props.dataItem.ProjectStatus) {
      projectStatus = this.props.dataItem.ProjectStatus.StatusName;
    }
    let isClosedStatus = false;
    if (project.ProjectStatus) {
      let projectName = project.ProjectStatus.StatusName;
      isClosedStatus = projectName
        .toString()
        .toLowerCase()
        .includes(closeSting);
    }

    // Project and CRs

    if(project.ActivityLogs && project.ActivityLogs.length > 0){
      project.ActivityLogs.sort((a, b) => (b.createdAt > a.createdAt) ? 1 : -1);

      if(project.ActivityLogs[0].ApprovalStatus && project.ActivityLogs[0].ApprovalStatus === 'PENDING'){
        isPendingStatusProj = true;
      }
      else{
        isPendingStatusProj = false;
      }
    }

    const crs = (project.ChangeRequests && project.ChangeRequests.length > 0) ? project.ChangeRequests : [];

    if(crs && crs.length > 0){
      for(let cr of crs){
        if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
          if(cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING'){
            isPendingStatusCr = true;
          }
          else{
            isPendingStatusCr = false;
            break;
          }
        }
      }
      for(let cr of crs){
        if(cr.Status && (cr.Status.Code === 'PRESALES' || cr.Status.Code === 'WAIT_CONFIRM' ||
        cr.Status.Code === 'BILLABLE' || cr.Status.Code === 'ON_HOLD' || cr.Status.Code === 'PROJECT_ON_HOLD' || 
        cr.Status.Code === 'DEAL_ON_HOLD' || cr.Status.Code === 'WORK_AT_RISK')){
          editableCR = true;
          break;
        }
        else{
          editableCR = false;
        }
      }
    }

      const presaleCRs = project.ChangeRequests.filter(obj => obj.Status && obj.Status.Code === 'PRESALES' || obj.Status && obj.Status.Code === 'WAIT_CONFIRM' ||
        obj.Status && obj.Status.Code === 'BILLABLE' || obj.Status && obj.Status.Code === 'ON_HOLD' || obj.Status && obj.Status.Code === 'WORK_AT_RISK');

      let projectManager = false;
      let projectStatusDef = '';
      let pendingCMSApprovals = false;
      const editProjPermission = window.USER_PERMISSION.filter(obj => obj === 'EDT_PRJ');
      const deleteProjPermission = window.USER_PERMISSION.filter(obj => obj === 'DLT_PRJ');
      const viewProjPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_PRJ');
      const createPoolProjPermission = window.USER_PERMISSION.filter(obj => obj === 'FAC_PRO');
      const viewPoolProjPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_FAC_PRJ');
      const viewStatusChangePermission = window.USER_PERMISSION.filter(obj => obj === 'VIEW_CHANGE_STATUS');
      const changeToStatusPermissions = window.USER_PERMISSION.filter(obj => obj === 'CHG_TO_WAITING_CONFIRMATION' || obj === 'CHG_TO_CLOSE_DISCONTINUE' ||
          obj === 'CHG_TO_PROJECT_ON_HOLD' || obj === 'CHG_TO_PRESALE' || obj === 'CHG_TO_CLOSE_WITHOUT_BIDDING' || obj === 'CHG_TO_CLOSE_OPPORTUNITY_LOST' ||
          obj === 'CHG_TO_DEAL_ON_HOLD' || obj === 'CHG_TO_WORKING_AT_RISK' || obj === 'CHG_TO_ACTIVE' || obj === 'CHG_TO_COMPLETE' || obj === 'CHG_TO_CLOSED');

    const projectProjectManagers = project.AllocatedResourceRequestPM;

    //find valid pms from end date
    let projValidManagers = [];
    projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

    if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
      //projValidManagers.push(projectProjectManagers[0]);
      projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
      const lastAssignedManager = projectProjectManagers[0];
      projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

    }

    let PMNames = [];
    projValidManagers.forEach(value => {
      if(window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()){
        projectManager = true;
      }
      PMNames.push(value.PMResource.Name);
    });
    PMName = PMNames.toString();

      if (project.ProjectStatus) {
          let projectStatusName = project.ProjectStatus.StatusName;
          projectStatusDef = projectStatusName;
      }

      const cmSheets = project.CMSheets;
      if (cmSheets) {
          for (const cmSheet of cmSheets) {
              if (cmSheet.Status === 'PENDING_APPROVALS') {
                  pendingCMSApprovals = true
                  break;
              }
          }
      }

      let displayBtnPro;
      let displayBtnCR;

      for(let el of changeToStatusPermissions){
        switch(el){
          case 'CHG_TO_DEAL_ON_HOLD' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_PRESALE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_WAITING_CONFIRMATION' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_WORKING_AT_RISK' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_ACTIVE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'WORK_AT_RISK' || project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM' ||
            project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PROJECT_ON_HOLD')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_PROJECT_ON_HOLD' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'WORK_AT_RISK' || project.ProjectStatus.Code == 'BILLABLE')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_WITHOUT_BIDDING' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_OPPORTUNITY_LOST' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'DEAL_ON_HOLD' || project.ProjectStatus.Code == 'PRESALES' || project.ProjectStatus.Code == 'WAIT_CONFIRM')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_COMPLETE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'BILLABLE')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_DISCONTINUE' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'BILLABLE' || project.ProjectStatus.Code == 'WORK_AT_RISK' || project.ProjectStatus.Code == 'PROJECT_ON_HOLD')){
              displayBtnPro = true;
            }
            break;
          }
          case 'CHG_TO_CLOSED' : {
            if(project.ProjectStatus && (project.ProjectStatus.Code == 'COMPLETE')){
              displayBtnPro = true;
            }
            break;
          }
          default : {
          }
        }
      }

      if(viewStatusChangePermission && viewStatusChangePermission.length > 0){
          displayBtnPro = true;
      }

      var displayStatusButtonPro = {
          display: displayBtnPro === true ? 'block' : 'none'
      }

      for(let el of changeToStatusPermissions){
        switch(el){
          case 'CHG_TO_DEAL_ON_HOLD' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'PRESALES' || obj.Status && obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_PRESALE' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'DEAL_ON_HOLD')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_WAITING_CONFIRMATION' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status && obj.Status.Code == 'PRESALES')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_WORKING_AT_RISK' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status && obj.Status.Code == 'PRESALES' || obj.Status && obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_ACTIVE' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'WORK_AT_RISK' || obj.Status && obj.Status.Code == 'PRESALES' || obj.Status && obj.Status.Code == 'WAIT_CONFIRM' ||
              obj.Status && obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status && obj.Status.Code == 'PROJECT_ON_HOLD')).length > 0 )){
                displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_PROJECT_ON_HOLD' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'WORK_AT_RISK' || obj.Status && obj.Status.Code == 'BILLABLE')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_WITHOUT_BIDDING' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status && obj.Status.Code == 'PRESALES')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_OPPORTUNITY_LOST' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status && obj.Status.Code == 'PRESALES' || obj.Status && obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_COMPLETE' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'BILLABLE')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_CLOSE_DISCONTINUE' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'BILLABLE' || obj.Status && obj.Status.Code == 'WORK_AT_RISK' || obj.Status && obj.Status.Code == 'PROJECT_ON_HOLD')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          case 'CHG_TO_CLOSED' : {
            if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status && obj.Status.Code == 'COMPLETE')).length > 0 )){
              displayBtnCR = true;
            }
            break;
          }
          default : {
          }
        }
      }

      if(viewStatusChangePermission && viewStatusChangePermission.length > 0){
          displayBtnCR = true;
      }

      var displayStatusButtonCR = {
          display: displayBtnCR === true ? 'block' : 'none'
      }

      // Change Request related changes
      const activeCRs = project.ChangeRequests.filter(cr => cr.IsActive === true).length;
      let crManager = false;

    projValidManagers.forEach(value => {
      if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
        projectManager = true;
      }
    });

      // const crManagerChangeRequests = project.ChangeRequests.filter(obj => obj.IsActive === true && window.LOGGED_USER.userId.toUpperCase() === obj.PresalesResource.Email.toUpperCase());

      let changeRequestsWithPMs = [];
      if (project.ChangeRequests && project.ChangeRequests.length>0) {
          for (const cr of project.ChangeRequests) {
                  if (cr.AllocatedPM && cr.AllocatedPM.length>0) {
                    const crProjectManagers = cr.AllocatedPM;

                    //find valid pms from end date
                    let crValidManagers = [];
                    crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

                    if (crValidManagers.length == 0 && crProjectManagers.length > 0){
                      //crValidManagers.push(crProjectManagers[0]);
                      crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
                      const lastAssignedManager = crProjectManagers[0];
                      crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

                    }
                      for(const allocatedPM of crValidManagers){
                          if (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
                              changeRequestsWithPMs.push(cr);
                          }
                      }
                  }
          }
      }

      const deleteChangeReqCRManager = project.ChangeRequests.filter(obj => obj.IsActive === true && obj.Status && obj.Status.Code === 'PRESALES' && obj.CMSheets.length === 0 ).length;

      let changeReqWithoutPendingApprovals = [];
      project.ChangeRequests.forEach(function (changeReq) {
          let CMSheet = null;
          if(changeReq && changeReq.IsActive === true && changeReq.CMSheets.length>0){
              if(changeReq.CMSheets[0].Status !== 'PENDING_APPROVALS'){
                  changeReqWithoutPendingApprovals.push(changeReq);
              }
          }
          else if(changeReq && changeReq.IsActive === true ){
              changeReqWithoutPendingApprovals.push(changeReq);
          }
      });

      const createCRPermission = window.USER_PERMISSION.filter(obj => obj === 'CRT_PRJ');
      const editCRPermission = window.USER_PERMISSION.filter(obj => obj === 'EDT_PRJ');
      const deleteCRPermission = window.USER_PERMISSION.filter(obj => obj === 'DLT_PRJ');

      // Change Request Create
      if(this.state.redirectToCreateCR){
          this.props.onClickProject();
          return (
              <Redirect
                  to={{
                      pathname: '/changeRequest/create',
                      action: 'create',
                      projectId: project.id
                  }}
              />
          );
      }

      // Project Edit
      if(this.state.redirectToEditProject){
          this.props.onClickProject();
          if(project.IsPoolProject === true){
              return (
                  <Redirect
                      to={{
                          pathname: '/PoolProject/FactoryMain',
                          projectId: project.id,
                          action: 'edit'
                      }}
                  />
              );
          }
          else{
              return (
                  <Redirect
                      to={{
                          pathname: '/projects/create/create',
                          projectId: project.id,
                          action: 'edit'
                      }}
                  />
              );
          }

      }

      // Project View
      if(this.state.redirectToViewProject){
          this.props.onClickProject();
          if(project.IsPoolProject === true){
              return (
                  <Redirect
                      to={{
                          pathname: '/PoolProject/FactoryMain',
                          projectId: project.id,
                          action: 'view'
                      }}
                  />
              );
          }
          else{
              return (
                  <Redirect
                      to={{
                          pathname: '/projects/create/create',
                          projectId: project.id,
                          action: 'view'
                      }}
                  />
              );
          }

      }

    // CMS
    let viewCMSPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_CMS');

    let cmsAvailable = false;
    let changeReqList = [];

    // Edit CMS (Project and CR both) Logic
    let editProjectCMS = [];
    let editCrCMS = [];

    // View CMS ( Project and CR both ) Logic
    let viewProjectCMS = [];
    let viewCrCMS = [];

    // Revise CMS ( Project and CR both ) Logic
    let reviseProjectCMS = [];
    let reviseCrCMS = [];

    let crAccess = false;

    project.ChangeRequests.forEach(function (CR) {
      const crProjectManagers = CR.AllocatedPM;

      //find valid pms from end date
      let crValidManagers = [];
      crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (crValidManagers.length == 0 && crProjectManagers.length > 0){
        //crValidManagers.push(crProjectManagers[0]);
        crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
        const lastAssignedManager = crProjectManagers[0];
        crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

      }

      crValidManagers.forEach(value => {
        if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
          crAccess = true;
        }
      });

      if(CR.CMSheets.length > 0){
        cmsAvailable = true;
        let isViewCRCMS = false;
        let isEditCRCMS = false;
        let viewCRObj = null;
        let editCRObj = null;

        crValidManagers.forEach(value => {

          //View
          if((value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())) || (viewCMSPermission && viewCMSPermission.length>0)){
            viewCRObj={
              name: CR.CRName,
              data : CR
            }
            isViewCRCMS = true;
          }

          // Edit CMS CR
          if (CR.CMSheets[0].Status === 'IN_PROGRESS' && (CR.Status && CR.Status.Code === 'PRESALES')) {
            if((window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()) && !value.IsPresales){
              editCRObj = {
                name: CR.CRName,
                data: CR
              };
              isEditCRCMS = true;
            }
            else if((window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()) && CR.IsActive === true && value.IsPresales){
              editCRObj = {
                name: CR.CRName,
                data: CR
              };
              isEditCRCMS = true;
            }
          }

          // Revise CMS
          if((CR.CMSheets[0].Status === 'REJECTED' || CR.CMSheets[0].Status === 'APPROVED' || CR.CMSheets[0].Status === 'EXPIRED') &&
            ((window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()) && value.IsPresales)){
            const reviseCRObj={
              name: CR.CRName,
              data : CR
            }
            reviseCrCMS.push(reviseCRObj);
          }
        });

        if (viewCRObj && isViewCRCMS) {
          viewCrCMS.push(viewCRObj);
        }
        if (editCRObj && isEditCRCMS) {
          editCrCMS.push(editCRObj);
        }

      }
      else {
        //This logic is when a CR doesnt have a CMS
        changeReqList.push(CR);
      }
    })

    if(project.CMSheets.length > 0){
      cmsAvailable = true;

      let projectManager = false;

      const projectProjectManagers = project.AllocatedResourceRequestPM;

      //find valid pms from end date
      let projValidManagers = [];
      projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
        //projValidManagers.push(projectProjectManagers[0]);
        projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
        const lastAssignedManager = projectProjectManagers[0];
        projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

      }

      projValidManagers.forEach(value => {
        if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
          projectManager = true;
        }
      });

      if( projectManager || (viewCMSPermission && viewCMSPermission.length>0)){
        const isProjectCMS = project.CMSheets.filter(a => !a.IsCRCMS);
        if(isProjectCMS && isProjectCMS.length > 0){
          const viewProjObj={
            name: project.ProjectName,
            data : project
          };
          viewProjectCMS.push(viewProjObj);
        }
      }

      if (projectManager === true) {
        if(project.CMSheets[0].Status === 'IN_PROGRESS' && project.ProjectStatus.Code == "PRESALES"){
          const isProjectCMS = project.CMSheets.filter(a => !a.IsCRCMS);
          if(isProjectCMS && isProjectCMS.length > 0){
            const editProjObj={
              name: project.ProjectName,
              data : project
            };
            editProjectCMS.push(editProjObj);
          }
        }
      }

      // Revise CMS
      projValidManagers.forEach(value => {
        if((project.CMSheets[0].Status === 'REJECTED' || project.CMSheets[0].Status === 'APPROVED' || project.CMSheets[0].Status === 'EXPIRED'
        ) &&(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()))){
          const reviseProjectObj={
            name: project.ProjectName,
            data : project
          }
          reviseProjectCMS.push(reviseProjectObj);
        }
      });
    }

    // Resource Request
    let isConvertRequest = false;
    let isWaitingConfirm = false;
    let isClosedStatusProject = false;

    // if (project.ProjectStatus.StatusName === 'Working At Risk' || project.ProjectStatus.StatusName === 'Active' || project.ProjectStatus.StatusName === 'Complete' ||
    // project.ProjectStatus.StatusName === 'Closed' || project.ProjectStatus.StatusName === 'Closed-Opportunity Loss' || 
    // project.ProjectStatus.StatusName === 'Close without bidding' || project.ProjectStatus.StatusName === 'Discontinue') {
        if (project.StatusConvertedToActive === true) {
            isConvertRequest = true;
        }
    // }

    if(project.ProjectStatus.StatusName === 'Waiting Confirmation') {
      if (projectManager) {
        isWaitingConfirm = true
      }
    } else if (project.ProjectStatus.StatusName === 'Closed' || project.ProjectStatus.StatusName === 'Closed-Opportunity Loss' || 
    project.ProjectStatus.StatusName === 'Close without bidding' || project.ProjectStatus.StatusName === 'Discontinue') {
      isClosedStatusProject = true;
    }

    let crArray = []
    project.AllChangeRequests = project.ChangeRequests;
    for (const cr of project.ChangeRequests) {
      let preSalesPM = false;
      const crProjectManagers = cr.AllocatedPM;
      //find valid pms from end date
      let crValidManagers = [];
      crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (crValidManagers.length == 0 && crProjectManagers.length > 0){
          crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
          const lastAssignedManager = crProjectManagers[0];
          crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
      }
      crValidManagers.forEach(value => {
          if(value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
              preSalesPM = true;
          }
      });

      if (cr.Status !== null) {
        // if (cr.Status.StatusName === 'Working At Risk' || cr.Status.StatusName === 'Active' || cr.Status.StatusName === 'Billable with CR' || 
        // cr.Status.StatusName === 'Complete' || cr.Status.StatusName === 'Closed' || cr.Status.StatusName === 'Closed-Opportunity Loss' || 
        // cr.Status.StatusName === 'Close without bidding' || cr.Status.StatusName === 'Discontinue') {
          if (cr.StatusConvertedToActive === true) {
              isConvertRequest = true;
          }
          crArray.push(cr)
        //  }
        if(cr.Status.StatusName === 'Waiting Confirmation') {
          if (preSalesPM) {
            isWaitingConfirm = true
          }
        }
      }
    }

    let allocatedProjectManager = false;
    if (project.ResourceRequestStatus === 'PM_ALLOCATED') {
      const projectProjectManagers = project.AllocatedResourceRequestPM.filter(manager => !manager.IsPresales);
      //find valid pms from end date
      let projValidManagers = [];
      projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
          projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
          const lastAssignedManager = projectProjectManagers[0];
          projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
      }
      projValidManagers.forEach(value => {
        if(!value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
          allocatedProjectManager = true
        }
      });
    }

      if (project.ChangeRequests.length > 0) {
        for(const cr of project.ChangeRequests){
          if (cr.ResourceRequestStatus === 'PM_ALLOCATED') {
            const crProjectManagers = cr.AllocatedPM.filter(manager => !manager.IsPresales);;

            //find valid pms from end date
            let crValidManagers = [];
            crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

            if (crValidManagers.length == 0 && crProjectManagers.length > 0){
              //crValidManagers.push(crProjectManagers[0]);
              crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
              const lastAssignedManager = crProjectManagers[0];
              crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

            }
            crValidManagers.forEach(value => {
              if(!value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
                allocatedProjectManager = true
              }
            });
          }
        }
      }

      // Task Management
      var hasTaskAccess = projectManager ? true: false;
      for (const level of ['VW_PRJTASKMNGMT', 'ADD_EDIT_TASKS', 'ADD_EDIT_RESCS']) {
        if (!hasTaskAccess) {
          hasTaskAccess = window.USER_PERMISSION.includes(level);
        } else {
          break;
        }
      }

      if(!hasTaskAccess && project.ChangeRequests && project.ChangeRequests.length > 0){
        for (const cr of project.ChangeRequests) {
          if(!hasTaskAccess){
            const crProjectManagers = cr.AllocatedPM;

            //find valid pms from end date
            let crValidManagers = [];
            crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

            if (crValidManagers.length == 0 && crProjectManagers.length > 0){
              //crValidManagers.push(crProjectManagers[0]);
              crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
              const lastAssignedManager = crProjectManagers[0];
              crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

            }
            crValidManagers.forEach(value => {
              if(cr.IsActive === true && value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
                hasTaskAccess = true;
              }
              if(!value.IsPresales && (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase())){
                hasTaskAccess = true;
              }
            });
          }else{
            break;
          }
        }
      }

    return (
      <>
      <td>
        <div className='row'>
          <div className='col d-flex'>
        
            {((viewProjPermission && viewProjPermission.length>0) || projectManager === true) &&(
              <a href="#" onClick={event => this.onClickProjectSection(event, 'view', project.id)}>
                <div className='d-flex align-items-center mr-2'>
                  <div class="rounded-link" title="View">
                    <span class="k-icon k-i-preview" />
                  </div>
                  <span className='text-white'>View</span>
                </div>
              </a>
            )}

            {((((editProjPermission && editProjPermission.length>0) || projectManager === true ) && (project.IsPoolProject === false)) &&
              (( (projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' )  && pendingCMSApprovals===false) ) && (!isPendingStatusProj)
              ) &&(
                  <Link
                      to={{
                          pathname: '/projects/create/create',
                          projectId: project.id,
                          action: 'edit'
                      }}
                  >
                    <div className='d-flex align-items-center mr-2'>
                      <div class="rounded-link" title="Edit">
                        <span class="k-icon k-i-edit" />
                      </div>
                      <span className='text-white'>Edit</span>
                    </div>
                  </Link>
              )}

              {(((createPoolProjPermission && createPoolProjPermission.length>0) && (project.IsPoolProject === true)) &&
                  (( (projectStatusDef=== 'Waiting Confirmation' || projectStatusDef=== 'Presales' || projectStatusDef === 'On Hold' || projectStatusDef === 'Working At Risk' || projectStatusDef === 'Active' )  && pendingCMSApprovals===false) )
                ) &&(
                  <Link
                      to={{
                          pathname: '/PoolProject/FactoryMain',
                          projectId: project.id,
                          action: 'edit'
                      }}
                  >

                      <div className='d-flex align-items-center mr-2'>
                        <div class="rounded-link" title="Edit">
                          <span class="k-icon k-i-edit" />
                        </div>
                        <span className='text-white'>Edit</span>
                      </div>
                  </Link>
                )}

              {/**change status popup */}
              {((viewStatusChangePermission && viewStatusChangePermission.length > 0) || (changeToStatusPermissions && changeToStatusPermissions.length > 0)) && (
                  <a href="#" onClick={this.handleChangeStatusPopUp}>

                      <div className='d-flex align-items-center mr-2'>
                        <div class="rounded-link d-inline" title="Status Change" style={displayStatusButtonPro}>
                          <span class="k-icon k-i-wrench" />
                        </div>
                        <span className='text-white'>Status Change</span>
                      </div>
                  </a>
              )}

              {projectManager && (
                <a href="#" onClick={event => this.onClickProjectSection(event, 'create', project.id)}>
                  <div className='d-flex align-items-center mr-2'>
                      <div class="rounded-link" title="Create a CR">
                        <span class="k-icon k-i-track-changes" />
                      </div>
                      <span className='text-white'>Create a CR</span>
                    </div>
                </a>
              )}

              {((projectManager === true || (editCRPermission && editCRPermission.length>0) || (changeRequestsWithPMs&& changeRequestsWithPMs.length>0) ) &&
                  (changeReqWithoutPendingApprovals.length>0) && (!isPendingStatusCr) && editableCR
                ) && (
                  <a href="#" onClick={event => this.handleEditCR(event, project, projectManager, editCRPermission)}>
                      <div className='d-flex align-items-center mr-2'>
                        <div class="rounded-link" title="Edit CR">
                          <span class="k-icon k-i-edit" />
                        </div>
                        <span className='text-white'>Edit CR</span>
                      </div>
                  </a>
                )}

            {/**change status popup - CR */}
            {project.ChangeRequests.length > 0 && ((viewStatusChangePermission && viewStatusChangePermission.length > 0)  || (changeToStatusPermissions && changeToStatusPermissions.length > 0)) && (
                <a href="#" onClick={event => this.handleStatusPopUpCR(event, project)}>

                  <div className='d-flex align-items-center mr-2'>
                        <div class="rounded-link d-inline" title="Status Change - CR" style={displayStatusButtonCR}>
                          <span class="k-icon k-i-wrench" />
                        </div>
                        <span className='text-white'>Status Change - CR</span>
                      </div>
                </a>
              )}
         
              {/**Task Management Button */}     
              {hasTaskAccess && (
                <Link
                  to={{
                    pathname: '/timesheets/projecttaskmanagement/projecttaskmanagement',
                    projectId: project.id,
                    action: 'view',
                    project:project
                  }}
                >

                  <div className='d-flex align-items-center mr-2'>
                    <div class="rounded-link" title="View / Edit Task Management">
                      <span class="k-icon k-i-edit" />
                    </div>
                    <span className='text-white'>View / Edit Tasks</span>
                  </div>
                </Link>
              )}

              {this.props.dataItem.CMSheets.map((item, index) => (
                <div key={index}>
                  { ((!item.IsCRCMS && ((viewCrCMS && viewCrCMS.length>0) || (viewProjectCMS && viewProjectCMS.length>0)))
                    || (item.IsCRCMS && (viewCrCMS ||viewCrCMS.length > 0) && (!viewProjectCMS || viewProjectCMS.length <=0) && index == 0)) && (
                        <a href="#" onClick={event => this.handleViewCMS(event,project, viewProjectCMS, viewCrCMS)}>
                          <div className='d-flex align-items-center mr-2'>
                            <div class="rounded-link" title="View">
                              <span class="k-icon k-i-preview" />
                            </div>
                            <span className='text-white'>View CMS</span>
                          </div>
                        </a>
                  )}
                </div>
              ))}

              <div className='d-flex align-items-center ml-4'>
                  <span className='text-white'>CRs: {crs.length}</span>
              </div>

          </div>
        </div>

        <div className='row'>
          <div className='col d-flex'>
            <div className="gs-disc">
              <label>{`${this.props.dataItem.UniqueId} | ${
                this.props.dataItem.ProjectName
              } | ${customerName} | ${programeName} | ${PMName} | ${projectStatus} | ${divisionName} | ${regionName}`}</label>
            </div>
          </div>
        </div>

        {/**Resource Request Functions*/}
        <div className='row'>
          <div className='col d-flex'>

            {(window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_RES_REQ') > -1))) && (isConvertRequest === true || isWaitingConfirm === true) && (
              <Link
                to={{
                  pathname: '/ProjectResourceRequest/ProjectResourceRequest',
                  action: 'view',
                  project: project
                }}
              >
                <div className='d-flex align-items-center mr-2'>
                  <div class="rounded-link" title="View">
                    <span class="k-icon k-i-preview" />
                  </div>
                  <span className='text-white'>View Resource Request</span>
                </div>
              </Link>
            )}

            {((allocatedProjectManager === true || isWaitingConfirm === true) && !isClosedStatusProject) &&  (
              <Link
                to={{
                  pathname: '/ProjectResourceRequest/ProjectResourceRequest',
                  action: 'create',
                  project: project
                }}
              >
                <div className='d-flex align-items-center mr-2'>
                  <div class="rounded-link" title="Edit">
                    <span class="k-icon k-i-edit" />
                  </div>
                  <span className='text-white'>Edit Resource Request</span>
                </div>
              </Link>
            )}
          </div>
        </div> 
        {((this.props.resultLength - 1) !== this.props.dataIndex) && (
        <hr className='border-top border-white' />  
        )}
      </td>
      </>
    );
  }
}

export default AllDataCell;
