import React from 'react';

const formatNumber = value => {
  if (isNaN(value)) {
    return value;
  } else {
    if (value) {
      return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else if (value === 0) {
      return '0.00';
    } else {
      return '';
    }
  }
};

const WorkflowParameterConfigrations = props => {
  const nameString = 'Name';
  const idString = 'id';
  const value1String = 'Value1';
  const value2String = 'Value2';
  const revenueString = 'Revenue';
  const lessThanString = 'Less than';
  const betweenString = 'Between';
  const greaterThanString = 'Greater than';
  const equalToString = 'Equal to';
  const billingDiviString = 'Department';
  const billingRegionString = 'Billing Region';
  const requestedResourceRegionString = 'Request Resource Region';
  const allocatedResourceRegionString = 'Allocated Resource Region';
  const paymentMethodString = 'Payment Method';
  const prevStatusString = 'Previous Status';
  const currentStatusString = 'Current Status';
  const endStatusString = 'End Status';
  const practiceString = 'Practice';
  const engagementTypeString = 'Engagement Type';
  const parameters = props.dataItem.WorkflowCriteria;
  let practiceCount = [];

  for(const obj of parameters){
     if(obj.ParameterId === 5){
       practiceCount.push(obj)
     }
  }

  return (
    <td>
      <ul>
        {parameters.length > 0 && parameters.map(item =>
          item.WorkflowParameter[nameString] === billingDiviString ? (
            <li key={item[idString]}>
              {'Department = '}
              {props.BillingDivision.find(a => a.id === item[value1String])
                ? props.BillingDivision.find(a => a.id === item[value1String]).Name
                : ''}
            </li>
          ) : item.WorkflowParameter[nameString] === practiceString ? (
            <li key={item[idString]}>
              {'Practice = '}
              {props.ResourcePractice.find(a => a.id === item[value1String])
                ? props.ResourcePractice.find(a => a.id === item[value1String]).Name
                : ''}
            </li>
          )  : item.WorkflowParameter[nameString] === billingRegionString ? (
            <li key={item[idString]}>
              {item.WorkflowParameter[nameString]} {' = '}
              {props.BillingRegion.find(a => a.id === item[value1String])
                ? props.BillingRegion.find(a => a.id === item[value1String]).Name
                : ''}
            </li>
          ): item.WorkflowParameter[nameString] === requestedResourceRegionString ? (
              <li key={item[idString]}>
                {item.WorkflowParameter[nameString]} {' = '}
                {props.ResourceRegion.find(a => a.id === item[value1String])
                  ? props.ResourceRegion.find(a => a.id === item[value1String]).Name
                  : ''}
              </li>
            ) : item.WorkflowParameter[nameString] === allocatedResourceRegionString ? (
            <li key={item[idString]}>
              {item.WorkflowParameter[nameString]} {' = '}
              {props.ResourceRegion.find(a => a.id === item[value1String])
                ? props.ResourceRegion.find(a => a.id === item[value1String]).Name
                : ''}
            </li>
          ) : item.WorkflowParameter[nameString] === paymentMethodString ? (
            <li key={item[idString]}>
              {'Billing Method = '}
              {props.PaymentMethodAll.find(a => a.id === item[value1String])
                ? props.PaymentMethodAll.find(a => a.id === item[value1String]).Name
                : ''}
            </li>
          ) : item.WorkflowParameter[nameString] === engagementTypeString ? (
            <li key={item[idString]}>
              {'Engagement Type = '}
              {props.EngagementTypes.find(a => a.id === item[value1String])
                ? props.EngagementTypes.find(a => a.id === item[value1String]).Name
                : ''}
            </li>
          )

          : item.WorkflowParameter[nameString] === prevStatusString  ? (
          <li key={item[idString]}>
          {'Previous Status = '}
          {props.statusAll.find(a => a.id === item[value1String])
              ? props.statusAll.find(a => a.id === item[value1String]).StatusName
              : ' -'}
          </li>
          )
          : item.WorkflowParameter[nameString] === currentStatusString ? (
          <li key={item[idString]}>
          {'Current Status = '}
          {props.statusAll.find(a => a.id === item[value1String])
              ? props.statusAll.find(a => a.id === item[value1String]).StatusName
              : ''}
          </li>
          )
          : item.WorkflowParameter[nameString] === endStatusString ? (
          <li key={item[idString]}>
          {'End Status = '}
          {props.statusAll.find(a => a.id === item[value1String])
              ? props.statusAll.find(a => a.id === item[value1String]).StatusName
              : ''}
          </li>
          )

          : item.WorkflowParameter[nameString] !== revenueString &&
            item.WorkflowOperator[nameString] === greaterThanString ? (
                    <li key={item[idString]}>
                      {item.WorkflowParameter[nameString]} {' > '} {formatNumber(item[value2String])} %
            </li>
                  ) : item.WorkflowParameter[nameString] !== revenueString &&
                    item.WorkflowOperator[nameString] === lessThanString ? (
                      <li key={item[idString]}>
                        {item.WorkflowParameter[nameString]} {' < '} {formatNumber(item[value2String])} %
            </li>
                    ) : item.WorkflowParameter[nameString] !== revenueString &&
                      item.WorkflowOperator[nameString] === equalToString ? (
                        <li key={item[idString]}>
                          {item.WorkflowParameter[nameString]} {' = '} {formatNumber(item[value2String])} %
            </li>
                      ) : item.WorkflowParameter[nameString] !== revenueString &&
                        item.WorkflowOperator[nameString] === betweenString ? (
                          <li key={item[idString]}>
                            {item.WorkflowParameter[nameString]} from {formatNumber(item[value1String])} to{' '}
                            {formatNumber(item[value2String])} %
            </li>
                        ) : item.WorkflowParameter[nameString] === revenueString &&
                          item.WorkflowOperator[nameString] === greaterThanString ? (
                            <li key={item[idString]}>
                              {item.WorkflowParameter[nameString]} {' > '} {formatNumber(item[value2String])}
                            </li>
                          ) : item.WorkflowParameter[nameString] === revenueString &&
                            item.WorkflowOperator[nameString] === lessThanString ? (
                              <li key={item[idString]}>
                                {item.WorkflowParameter[nameString]} {' < '} {formatNumber(item[value2String])}
                              </li>
                            ) : item.WorkflowParameter[nameString] === revenueString &&
                              item.WorkflowOperator[nameString] === equalToString ? (
                                <li key={item[idString]}>
                                  {item.WorkflowParameter[nameString]} {' = '} {formatNumber(item[value2String])}
                                </li>
                              ) : (
                                <li key={item[idString]}>
                                  {item.WorkflowParameter[nameString]} from {formatNumber(item[value1String])} to{' '}
                                  {formatNumber(item[value2String])}
                                </li>
                              )
        )}
      </ul>
    </td>
  );
};

export default WorkflowParameterConfigrations;
