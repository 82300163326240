import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem, PanelBarUtils, Menu, MenuItem, MenuItemModel, MenuItemLink, MenuItemArrow, Splitter } from '@progress/kendo-react-layout';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactDOM from 'react-dom';

class ActiveStatus extends Component {
    render() {
        return (
            <div>
                <div className="main-card">

                    <div className="row">

                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-5"><label>Project Start Date:</label></div>
                                <div className="col-md-7">
                                    <Input />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-5"><label>CM Version:</label></div>
                                <div className="col-md-7">
                                    <ComboBox />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="main-seperator"></div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="main-heading">Resource Request for Project PM</div>
                        </div>
                        <div className="col-md-6 btn-align-right">
                            <Button type="button" primary={true}>Add</Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Grid
                                style={{ height: '400px' }}>
                                <Column field="ProductID" title="ID" width="40px" />
                                <Column field="ProductName" title="Name" width="250px" />
                                <Column field="Category.CategoryName" title="CategoryName" />
                                <Column field="UnitPrice" title="Price" />
                                <Column field="UnitsInStock" title="In stock" />
                            </Grid>
                        </div>
                    </div>

                    <div className="main-seperator"></div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-heading">Define Time and Material Cap</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-inline-block mr-2">
                                <fieldset>
                                    <label className="k-form-field">
                                        <input type="checkbox" id="auth-1" className="k-checkbox" />
                                        <label className="k-checkbox-label" for="auth-1">Has a maximum amount for the time and material effort for the porject</label>
                                    </label>
                                </fieldset>
                            </div>
                            < div className="d-inline-block">
                                <Input />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                            <Button type="button" primary={true}>Convert the Project to Active</Button>
                            <Button type="button">Cancel</Button>
                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default ActiveStatus;