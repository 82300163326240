import React, { Component } from 'react';

import { DatePicker } from '@progress/kendo-react-dateinputs';
import MultiyearCustomPopup from './MultiyearCustomPopup';

class MultiIncrementYearPicker extends Component {
  constructor(props) {
    super(props);

    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth();
    var day = currentDate.getDate();
    this.state = {
      value: null,
      startYearMin: new Date(year + 1, month, day),
      startYearMinPool: new Date(year - 1, month, day)
    };
  }

  componentDidMount() {
    if (this.props.value) {
      const date = new Date(this.props.value, 0, 1);
      this.setState({
        value: date
      });
    }
  }


  componentDidUpdate(prevProps) {
    if (this.props.value && this.props.value !== prevProps.value) {
      const date = new Date(this.props.value, 0, 1);
      this.setState({
        value: date,

      });
    } else {
      if (this.props.value !== prevProps.value) {
        this.setState({
          value: null
        });
      }
    }
  }

  handleChange = event => {
    const date = event.value;
    this.setState({
      value: date
    });

    let year = 0;
    if (date) {
      year = date.getFullYear();
    }
    this.props.onChange({ value: year, name: this.props.name });
  };

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <DatePicker
          calendar={props => <MultiyearCustomPopup {...props} />}
          onChange={this.handleChange}
          value={this.state.value}
          width="100%"
          format="yyyy"
          formatPlaceholder={{ year: 'YYYY' }}
          disabled={this.state.disabled}
          min={this.props.IsPoolProject ? this.state.startYearMinPool : (this.props.isFactryProjectExtension && this.props.value) ? new Date(this.props.value + 1 , 1, 1) : this.state.startYearMin}
        />
      </div>
    );
  }
}

export default MultiIncrementYearPicker;
