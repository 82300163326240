/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { bool } from "prop-types";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {getActivityLogDataSort} from "../project/ProjectService";
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import {getFormattedDate} from "../../integration/CommonUtils";

const closeSting = 'close';

class CRDetailsCell extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount(){
  }

  handleEditCR = (event, proj, isProjectManager, hasEditCRPermission, crListOfCRManager, crListOfNewlyAddedManager, crListOfExistingManager) => {
    event.preventDefault();
    this.props.onEditCR(event, proj, 'edit', isProjectManager, hasEditCRPermission, crListOfCRManager, crListOfNewlyAddedManager, crListOfExistingManager);
  };

  handleViewCR = (event, project) => {
    event.preventDefault();
    this.props.onEditCR(event, project, 'view', false, false, null);
  };

    handleStatusPopUpCR = ( event, project) =>{
      event.preventDefault();
      this.props.handleStatusPopUpCR(event, project, 'HandleCrPopUp');
    };

    handleDeleteCR = (event, project, isProjectManager, hasEditCRPermission, crListOfCRManager) =>{
        event.preventDefault();
        this.props.onDeleteCR(event, project, 'delete', isProjectManager, hasEditCRPermission, crListOfCRManager);
    }


  render() {
    const project = this.props.dataItem;
    let projectStatusDef = '';
    let CRSttusCode = '';
    let multipleCR = false;
    let isConvertRequest = true;
    let isPoolProject = false;
    let beforeConvertCRs = [];
    let isPendingStatus = false;
    let editableCR = true;

    if (project.ProjectStatus) {
      projectStatusDef = project.ProjectStatus.StatusName;
    }

    if(project.IsPoolProject){
      isPoolProject = true;
    }

    const projectChangeRequests = (project.ChangeRequests && project.ChangeRequests.length > 0) ? project.ChangeRequests : [];
    const crs = projectChangeRequests.filter(obj => obj.Status !== null);

    let isCRStatusCode = false;

    if (project.ChangeRequests.length != 0) {
      for (const cr of project.ChangeRequests) {
        if (cr.Status !== null) {
          if ((cr.Status.Code === 'WORK_AT_RISK') || (cr.Status.Code === 'BILLABLE')) {
            isCRStatusCode = true;
            if (cr.StatusConvertedToActive === null) {
              isConvertRequest = false;
            }
          }        
        }
      }
    }

    if(crs && crs.length > 0){
      for(let cr of crs){
        if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
          if(cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING'){
            isPendingStatus = true;
          }
          else{
            isPendingStatus = false;
            break;
          }
        }
      }
      for(let cr of crs){
        if(cr.Status && (cr.Status.Code === 'PRESALES' || cr.Status.Code === 'WAIT_CONFIRM' ||
        cr.Status.Code === 'BILLABLE' || cr.Status.Code === 'ON_HOLD' || cr.Status.Code === 'PROJECT_ON_HOLD' || 
        cr.Status.Code === 'DEAL_ON_HOLD' || cr.Status.Code === 'WORK_AT_RISK')){
          editableCR = true;
          break;
        }
        else{
          editableCR = false;
        }
      }
    }

    const projectCRs = project.ChangeRequests.filter(obj => obj.IsActive === true && obj.Status !== null);
    const billableAndWorkAtRiskCR = projectCRs.filter(obj => obj.IsActive === true && (obj.Status.Code === 'BILLABLE' || obj.Status.Code === 'WORK_AT_RISK')).length;
    if (billableAndWorkAtRiskCR && billableAndWorkAtRiskCR > 1) {
      multipleCR = true

    }

    const activeCRs = project.ChangeRequests.filter(cr => cr.IsActive === true).length;

    let projectManager = false;

    const projectProjectManagers = project.AllocatedResourceRequestPM;

    //find valid pms from end date
    let projValidManagers = [];
    projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

    if (projValidManagers.length == 0 && projectProjectManagers.length > 0){
      //projValidManagers.push(projectProjectManagers[0]);
      projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
      const lastAssignedManager = projectProjectManagers[0];
      projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));
    }

    projValidManagers.forEach(value => {
      if(window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()){
        projectManager = true;
      }
    });

    let changeRequestsWithNewlyAllocatedPMs = [];
    let changeRequestsWithExistingPMs = [];
    if (project.ChangeRequests && project.ChangeRequests.length > 0) {
      for (const cr of project.ChangeRequests) {
        if (cr.AllocatedPM && cr.AllocatedPM.length>0) {
          const crProjectManagers = cr.AllocatedPM;

          //find valid pms from end date
          let crValidManagers = [];
          crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

          if (crValidManagers.length == 0 && crProjectManagers.length > 0){
            //crValidManagers.push(crProjectManagers[0]);
            crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
            var lastAssignedManager = crProjectManagers[0];
            crValidManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

          }
          for(const allocatedPM of crValidManagers){
            if (!allocatedPM.IsPresales && (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase())) {
              changeRequestsWithNewlyAllocatedPMs.push(cr);
            } else if (allocatedPM.IsPresales && (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase())) {
              changeRequestsWithExistingPMs.push(cr);
            }
          }
        }
      }
    }

    const crManagerChangeRequests = [];
    project.ChangeRequests.forEach(function (changeReq) {
      if(changeReq.IsActive === true){
        if (changeReq.AllocatedPM && changeReq.AllocatedPM.length>0) {
          const crProjectManagers = changeReq.AllocatedPM;

          //find valid pms from end date
          let crValidManagers = [];
          crValidManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

          if (crValidManagers.length == 0 && crProjectManagers.length > 0){
            crValidManagers.push(crProjectManagers[0]);
          }
          for(const allocatedPM of crValidManagers){
            if (allocatedPM.IsPresales && (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase())) {
              crManagerChangeRequests.push(changeReq);
            }
          }
        }
      }
    });

    const deleteChangeReqCRManager = projectCRs.filter(obj => obj.IsActive === true && obj.Status.Code === 'PRESALES' && obj.CMSheets.length === 0).length;

    let changeReqWithoutPendingApprovals = [];
    let changeReqClosedCount = 0;
    project.ChangeRequests.forEach(function (changeReq) {
      let CMSheet = null;
      if (changeReq && changeReq.IsActive === true && changeReq.CMSheets.length > 0) {
        if (changeReq.CMSheets[0].Status !== 'PENDING_APPROVALS') {
          changeReqWithoutPendingApprovals.push(changeReq);
        }
      }
      else if (changeReq && changeReq.IsActive === true) {
        changeReqWithoutPendingApprovals.push(changeReq);
      }

      if (changeReq.Status !== null) {
        let crStatus = changeReq.Status.StatusName
        if (crStatus === 'Closed-Opportunity Loss' || crStatus === 'Closed' || crStatus === 'Close without bidding') {
          changeReqClosedCount++
        }
      }
    });

    const createCRPermission = window.USER_PERMISSION.filter(obj => obj === 'CRT_PRJ');
    const createCRPoolPermission = window.USER_PERMISSION.filter(obj => obj === 'FAC_PRO');
    const editCRPermission = window.USER_PERMISSION.filter(obj => obj === 'EDT_PRJ');
    const deleteCRPermission = window.USER_PERMISSION.filter(obj => obj === 'DLT_PRJ');
    const viewStatusChangePermission = window.USER_PERMISSION.filter(obj => obj === 'VIEW_CHANGE_STATUS');
    const changeToStatusPermissions = window.USER_PERMISSION.filter(obj => obj === 'CHG_TO_WAITING_CONFIRMATION' || obj === 'CHG_TO_CLOSE_DISCONTINUE' ||
    obj === 'CHG_TO_PROJECT_ON_HOLD' || obj === 'CHG_TO_PRESALE' || obj === 'CHG_TO_CLOSE_WITHOUT_BIDDING' || obj === 'CHG_TO_CLOSE_OPPORTUNITY_LOST' ||
    obj === 'CHG_TO_DEAL_ON_HOLD' || obj === 'CHG_TO_WORKING_AT_RISK' || obj === 'CHG_TO_ACTIVE' || obj === 'CHG_TO_COMPLETE' || obj === 'CHG_TO_CLOSED');


    //to verify that CR has resource request or not
    if (this.props.dataItem.ChangeRequests && this.props.dataItem.ChangeRequests.length > 0) {
      const changeRequests = this.props.dataItem.ChangeRequests.filter(obj => obj.Status !== null);
      const CRs = changeRequests.filter(obj => obj.StatusConvertedToActive === null && ((obj.Status.Code === 'WORK_AT_RISK') || (obj.Status.Code === 'BILLABLE')));
      beforeConvertCRs.push(CRs)
    }


    let displayBtn;

    for(let el of changeToStatusPermissions){
      switch(el){
        case 'CHG_TO_DEAL_ON_HOLD' : {
          if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'PRESALES' || obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
            displayBtn = true;
          }
          break;
        }
        case 'CHG_TO_PRESALE' : {
          if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'DEAL_ON_HOLD')).length > 0 )){
            displayBtn = true;
          }
          break;
        }
        case 'CHG_TO_WAITING_CONFIRMATION' : {
          if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status.Code == 'PRESALES')).length > 0 )){
            displayBtn = true;
          }
          break;
        }
        case 'CHG_TO_WORKING_AT_RISK' : {
          if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status.Code == 'PRESALES' || obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
            displayBtn = true;
          }
          break;
        }
        case 'CHG_TO_ACTIVE' : {
          if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'WORK_AT_RISK' || obj.Status.Code == 'PRESALES' || obj.Status.Code == 'WAIT_CONFIRM' ||
            obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status.Code == 'PROJECT_ON_HOLD')).length > 0 )){
            displayBtn = true;
          }
          break;
        }
        case 'CHG_TO_PROJECT_ON_HOLD' : {
          if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'WORK_AT_RISK' || obj.Status.Code == 'BILLABLE')).length > 0 )){
            displayBtn = true;
          }
          break;
        }
        case 'CHG_TO_CLOSE_WITHOUT_BIDDING' : {
          if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status.Code == 'PRESALES')).length > 0 )){
            displayBtn = true;
          }
          break;
        }
        case 'CHG_TO_CLOSE_OPPORTUNITY_LOST' : {
          if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'DEAL_ON_HOLD' || obj.Status.Code == 'PRESALES' || obj.Status.Code == 'WAIT_CONFIRM')).length > 0 )){
            displayBtn = true;
          }
          break;
        }
        case 'CHG_TO_COMPLETE' : {
          if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'BILLABLE')).length > 0 )){
            displayBtn = true;
          }
          break;
        }
        case 'CHG_TO_CLOSE_DISCONTINUE' : {
          if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'BILLABLE' || obj.Status.Code == 'WORK_AT_RISK' || obj.Status.Code == 'PROJECT_ON_HOLD')).length > 0 )){
            displayBtn = true;
          }
          break;
        }
        case 'CHG_TO_CLOSED' : {
          if((crs && crs.length > 0) && (crs.filter(obj => (obj.Status.Code == 'COMPLETE')).length > 0 )){
            displayBtn = true;
          }
          break;
        }
        default : {
        }
      }
    }
    
    if(viewStatusChangePermission && viewStatusChangePermission.length > 0){
      displayBtn = true;
    }

    var displayStatusButton = {
      display: displayBtn === true ? 'block' : 'none'
    }


    return (
      <td className="action-buttons">
        CRs : {project.ChangeRequests ? activeCRs : 0}
        <br />

        {(projectStatusDef === 'Waiting Confirmation' ||
          projectStatusDef === 'Presales' ||
          projectStatusDef === 'On Hold' ||
          projectStatusDef === 'Working At Risk' ||
          projectStatusDef === 'Active') &&
          project &&
          !isPoolProject &&
          (projectManager || (createCRPermission && createCRPermission.length > 0)) && (
            <Link
              to={{
                pathname: '/changeRequest/create',
                action: 'create',
                projectId: project.id
                // statusValue: project.ProjectStatus
              }}
            >
              <div className="rounded-link" title="Create a CR">
                <span className="k-icon k-i-track-changes" />
              </div>
            </Link>
          )}

        {(projectStatusDef === 'Waiting Confirmation' ||
          projectStatusDef === 'Presales' ||
          projectStatusDef === 'On Hold' ||
          projectStatusDef === 'Working At Risk' ||
          projectStatusDef === 'Active') &&
          project &&
          isPoolProject &&
          (projectManager || (createCRPoolPermission && createCRPoolPermission.length > 0)) && (
            <Link
              to={{
                pathname: '/poolProjectRevision/poolRevision',
                action: 'create',
                projectId: project.id
                // statusValue: project.ProjectStatus
              }}
            >
              <div className="rounded-link" title="Create Factory Project Extension">
                <span className="k-icon k-i-track-changes" />
              </div>
            </Link>
          )}

        {(projectStatusDef === 'Waiting Confirmation' ||
          projectStatusDef === 'Presales' ||
          projectStatusDef === 'On Hold' ||
          projectStatusDef === 'Working At Risk' ||
          projectStatusDef === 'Active') &&
          (projectManager === true ||
            (editCRPermission && editCRPermission.length > 0) ||
            ((changeRequestsWithExistingPMs && changeRequestsWithExistingPMs.length > 0) ||
              (changeRequestsWithNewlyAllocatedPMs &&
                changeRequestsWithNewlyAllocatedPMs.length > 0))) &&
          changeReqWithoutPendingApprovals.length > 0 &&
          changeReqWithoutPendingApprovals.length !== changeReqClosedCount && (!isPendingStatus) && editableCR && (
            <a
              href="#"
              onClick={event =>
                this.handleEditCR(
                  event,
                  project,
                  projectManager,
                  editCRPermission,
                  crManagerChangeRequests,
                  changeRequestsWithNewlyAllocatedPMs,
                  changeRequestsWithExistingPMs
                )
              }
            >
              <div className="rounded-link" title="Edit CR">
                <span className="k-icon k-i-edit" />
              </div>
            </a>
          )}
        {project.ChangeRequests.length > 0 && (
          <a href="#" onClick={event => this.handleViewCR(event, project)}>
            <div className="rounded-link" title="View CR">
              <span className="k-icon k-i-preview" />
            </div>
          </a>
        )}

        {project.ChangeRequests.length > 0 && ((viewStatusChangePermission && viewStatusChangePermission.length > 0) || (changeToStatusPermissions && changeToStatusPermissions.length > 0)) && (
          <a href="#" onClick={event => this.handleStatusPopUpCR(event, project)}>
            <div className="rounded-link" title="Status Change - CR" style={displayStatusButton}>
              <span className="k-icon k-i-wrench" />
            </div>
          </a>
        )}

        {((projectManager === true ||
          (editCRPermission && editCRPermission.length > 0) || (createCRPermission && createCRPermission.length > 0) ||
          ((changeRequestsWithExistingPMs && changeRequestsWithExistingPMs.length > 0))) && 
          (!isConvertRequest) && 
          (isCRStatusCode) && 
          (!isPendingStatus)) && (
            <Link
              to={this.props}
              onClick={event =>
                this.props.OnActivateResourceRequest(
                  event,
                  this.props.dataItem.ChangeRequests.length === 1
                    ? this.props.dataItem.ChangeRequests[0].id
                    : null,
                  multipleCR,
                  'Change_Request',
                  beforeConvertCRs[0],
                  project.ProjectName
                )
              }
            >
              <div className="rounded-link">
                <span className="k-icon k-i-check" title="Activate" />
              </div>
            </Link>
          )}

        {(((projectStatusDef === 'Waiting Confirmation' ||
          projectStatusDef === 'Presales' ||
          projectStatusDef === 'On Hold' ||
          projectStatusDef === 'Working At Risk' ||
          projectStatusDef === 'Active') &&
          (deleteCRPermission && deleteCRPermission.length > 0)) ||
          projectManager === true ||
          (changeRequestsWithNewlyAllocatedPMs &&
            changeRequestsWithNewlyAllocatedPMs.length > 0 &&
            (changeRequestsWithExistingPMs && changeRequestsWithExistingPMs.length > 0))) &&
          deleteChangeReqCRManager > 0 && (
            <a
              href="#"
              onClick={event =>
                this.handleDeleteCR(
                  event,
                  project,
                  projectManager,
                  deleteCRPermission,
                  deleteChangeReqCRManager
                )
              }
            >
              <div className="rounded-link" title="Delete">
                <span className="k-icon k-i-delete" />
              </div>
            </a>
        )}

      </td>
    );
  }
}

export default CRDetailsCell;
