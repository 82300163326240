module.exports = Object.freeze({
    Presales : { value: 1 , name: 'Presales',code: 'CHG_TO_PRESALE' } ,
    Waiting_Confirmation : { value: 2 , name: 'Waiting Confirmation',code:'CHG_TO_WAITING_CONFIRMATION' } ,
    Opportunity_Lost : { value: 3, name: 'Closed-Opportunity Loss',code:'CHG_TO_CLOSE_OPPORTUNITY_LOST' } ,
    Working_At_Risk : { value: 5, name: 'Working At Risk',code:'CHG_TO_WORKING_AT_RISK' } ,
    Active : { value: 6, name: 'Active',code:'CHG_TO_ACTIVE' } ,
    Without_Bidding : { value: 9, name: 'Close without bidding',code:'CHG_TO_CLOSE_WITHOUT_BIDDING' } ,
    Complete : { value: 11, name: 'Complete',code:'CHG_TO_COMPLETE' },
    Deal_On_Hold : { value: 12, name: 'Deal On Hold',code:'CHG_TO_DEAL_ON_HOLD' } ,
    Project_On_Hold : { value: 13, name: 'Project On Hold',code:'CHG_TO_PROJECT_ON_HOLD' } ,
    Discontinue : { value: 14, name: 'Discontinue',code:'CHG_TO_CLOSE_DISCONTINUE' },
    Close : { value: 15, name: 'Close', code: 'CHG_TO_CLOSE' },
    Closed : { value: 8, name: 'Closed', code: 'CHG_TO_CLOSED' }
  });
  