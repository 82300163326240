/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import {
  searchOfferings,
  updateOffering
} from './OfferingService';

import { Redirect } from 'react-router';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { orderBy } from '@progress/kendo-data-query';
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import moment from 'moment';

const ACTION_CREATE = 'create';
const ACTION_EDIT = 'edit';

class OfferingsSearch extends Component {

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      offerings: [],

      offeringName: '',

      skip: 0,
      take: 20,

      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      selectedOffering: null,
      offeringAction: '',
      searchStatus: null,
      status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      loading: false
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.setHeaderTitle();
    this.submitSearch(null);
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Offering');
    }
  };

  handleOnChangeName = event => {
    const value = event.target.value;
    if (this.isMount) {
      this.setState({
        offeringName: value
      });
    }
  };

  submitSearch = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    const fields = {};
    if (this.state.offeringName) {
      fields.Name = this.state.offeringName;
    }
    if (this.state.searchStatus) {
      fields.IsActive = this.state.searchStatus ? this.state.searchStatus.Name : null;
    }

    searchOfferings(fields)
      .then(res => {
        if (this.isMount) {
          this.setState({
            offerings: res.data,
            loading: false,
            skip: 0
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleConfirmDialog = (message, action) => {
    if (this.isMount) {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  navigateToCreate = () => {
    if (this.isMount) {
      this.setState({
        redirectToCreate: true
      });
    }
  };

  onClickCreate = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState(
        {
          offeringAction: ACTION_CREATE
        },
        () => {
          this.navigateToCreate();
        }
      );
    }
  };

  onClickEdit = (event, offering) => {
    event.preventDefault();
    if (this.isMount) {
      this.setState(
        {
          offeringAction: ACTION_EDIT,
          selectedOffering: offering
        },
        () => {
          this.navigateToCreate();
        }
      );
    }
  };

  onClickActivate = async (event, offering) => {
    event.preventDefault();
    const record = JSON.parse(JSON.stringify(offering));
    record.IsActive = true;
    if (this.isMount) {
      this.setState(
        {
          selectedOffering: record
        },
        () => {
          this.toggleConfirmDialog(
            'Are you sure you want to activate this?',
            this.activateOffering
          );
        }
      );
    }
  };

  onClickDeactivate = async (event, offering) => {
    event.preventDefault();
    const record = JSON.parse(JSON.stringify(offering));
    record.IsActive = false;
    if (this.isMount) {
      this.setState(
        {
          selectedOffering: record
        },
        () => {
          this.toggleConfirmDialog(
            'Are you sure you want to deactivate this?',
            this.deactivateOffering
          );
        }
      );
    }
  };

  deactivateOffering = () => {
    const record = this.state.selectedOffering;
    updateOffering(record)
      .then(async res => {
        const offerings = this.state.offerings.slice();
        const filter = offerings.filter(obj => obj.id === record.id);
        if (filter.length > 0) {
          filter[0].IsActive = false;
        }
        if (this.isMount) {
          this.setState({
            offerings: offerings,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        const message = 'Successfully deactivated';
        const title = 'Success';
        this.toggleMessageDialog(message, title);
        this.submitSearch(null);
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        const message =
          error && error.response ? error.response.data : 'An error occurred';
        const title = 'Error';
        this.toggleMessageDialog(message, title);
        this.submitSearch(null);
      });
    this.toggleConfirmDialog('', null);
  };

  activateOffering = () => {
    const record = this.state.selectedOffering;
    updateOffering(record)
      .then(res => {
        const offerings = this.state.offerings.slice();
        const filter = offerings.filter(obj => obj.id === record.id);
        if (filter.length > 0) {
          filter[0].IsActive = true;
        }
        if (this.isMount) {
          this.setState({
            offerings: offerings,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        const message = 'Successfully activated';
        const title = 'Success';
        this.toggleMessageDialog(message, title);
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        const message =
          error && error.response ? error.response.data : 'An error occurred';
        const title = 'Error';
        this.toggleMessageDialog(message, title);
      });
    this.toggleConfirmDialog('', null);
  };

  clearForm = async event => {
    if (event) {
      event.preventDefault();
    }
    if (this.isMount) {
      this.setState(
        {
          offeringName: '',
          searchStatus: null
        },
        () => {
          this.submitSearch(null);
        }
      );
    }
  };

  render() {
    if (this.state.redirectToCreate === true) {
      return (
        <Redirect
          to={{
            pathname: '/admin/offerings/create',
            offering: this.state.selectedOffering,
            action: this.state.offeringAction
          }}
        />
      );
    }
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Offering Search</div>
          </div>

          <div className="col-md-4">
            <Button
              primary={true}
              type="button"
              onClick={this.onClickCreate}
              className="pull-right"
              disabled={!(window.USER_PERMISSION.indexOf('EDT_OFF') > -1)}
            >
              Create New Offering
            </Button>
          </div>
        </div>

        <form onSubmit={this.submitSearch}>
          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                  <label>Offering Name:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <Input
                    onChange={this.handleOnChangeName}
                    value={this.state.offeringName}
                    maxLength={100}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Status:</label></div>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                    name="searchStatus"
                    textField="Name"
                    dataItemKey="status"
                    value={this.state.searchStatus}
                    data={this.state.status}
                    placeholder={'Please Select'}
                    onChange={this.handleInputChange}/>
                </div>
              </div>
            </div>
            </div>
            <div className="row">
            <div className="col-md-12">
              <div className="btn-align-right">
                <Button
                  primary={true}
                  type="submit"
                  onClick={this.submitSearch}
                >
                  Search
                </Button>
                <Button type="button" onClick={this.clearForm}>
                  Clear
                </Button>
              </div>
            </div>
            </div>
        </form>

        <div className="main-seperator"/>

        <div className="main-heading">Search Results</div>

        <div className="row">
          <div className="col-md-12">
            <Grid
              data={orderBy(this.state.offerings, this.state.sort).slice(
                this.state.skip,
                this.state.take + this.state.skip
              )}
              skip={this.state.skip}
              take={this.state.take}
              total={this.state.offerings.length}
              pageable={true}
              onPageChange={this.pageChange}
              resizable
              sortable={true}
              sort={this.state.sort}
              onSortChange={(e) => {
                this.setState({
                  sort: e.sort
                });
              }
              }
              className="search-result-grid"
            >
              <Column field="Name" title="Offering Name"/>
              <Column
                field="createdAt"
                title="Created Date"
                width="150px"
                cell={this.dateCell}
              />
              <Column
                field="updatedAt"
                title="Last Modified Date"
                width="150px"
                cell={this.dateCell}
              />
              <Column
                field="UpdatedUser"
                title="Last Modified User"
                width="120px"
              />
              <Column field="IsActive" title="Status" width="100px" cell={this.statusCell}/>
              <Column field="" title="Action" width="150px" cell={this.actionButtonCell}/>
            </Grid>
          </div>
        </div>

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success"/>
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image"/>
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showConfirmDialog === true && (
          <Dialog
            title="Confirm"
            onClose={this.toggleConfirmDialog}
            width="400px"
          >
            <p style={{ margin: '25px', textAlign: 'center' }}>
              {this.state.confirmDialogMessage}
            </p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.state.confirmDialogAction}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        <Loader loading={this.state.loading}/>
      </div>
    );
  }

  dateCell = props => {
    if (props.dataItem) {
      let date = null;
      if (props.field === 'createdAt') {
        date = new Date(props.dataItem.createdAt);
      }

      if (props.field === 'updatedAt') {
        date = new Date(props.dataItem.updatedAt);
      }

      return (
        <td>
          {date ? 
            moment(date).format("MM-DD-YYYY")
            : ''}
        </td>
      );
    }
    return null;
  };

  statusCell = props => {
    if (props.dataItem) {
      return (
        <td>{props.dataItem.IsActive === true ? 'Active' : 'Inactive'}</td>
      );
    }
    return null;
  };

  actionButtonCell = props => {
    return (
      <td className="action-buttons">
        {props.dataItem.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_OFF') > -1) && (
          <a
            href="#"
            onClick={event => this.onClickEdit(event, props.dataItem)}
          >
            <div className="rounded-link" title="Edit">
              <span className="k-icon k-i-edit"/>
            </div>
          </a>
        )}
        {props.dataItem.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_OFF') > -1) && (
          <a
            href="#"
            onClick={event => this.onClickDeactivate(event, props.dataItem)}
          >
            <div className="rounded-link" title="Deactivate">
              <span className="k-icon k-i-unsort"/>
            </div>
          </a>
        )}
        {props.dataItem.IsActive === false && (window.USER_PERMISSION.indexOf('EDT_OFF') > -1) && (
          <a
            href="#"
            onClick={event => this.onClickActivate(event, props.dataItem)}
          >
            <div className="rounded-link" title="Activate">
              <span className="k-icon k-i-check"/>
            </div>
          </a>
        )}
      </td>
    );
  };

  handleInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    await this.setState({ [name]: value, isFormDirty: true });
  };
}

export default OfferingsSearch;
