import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { getDesignationLevels, getCostDesignations, saveHrDesignation } from './HrDesignationService';
import { filterBy } from '@progress/kendo-data-query';
import FormValidator from '../../integration/components/FormValidator';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Redirect } from 'react-router';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import Loader from '../../integration/components/Loader';

class CreateHRDesignation extends Component {

    isMount = false;

    async componentDidMount() {

        this.isMount = true;
        window.scrollTo(0, 0);
        this.setHeaderTitle();

        this.handleChangeDesignationName = this.handleChangeDesignationName.bind(this);
        this.handleChangeDesignationLevel = this.handleChangeDesignationLevel.bind(this);

        await this.loadInitialData();

        if(this.props.location && this.props.location.action === 'edit'){
          this.setInitialData(this.props.location.designation);
        }
    }

    setInitialData = (hrdesignation) => {
      this.setState({
        designationId: hrdesignation.id,
        designationName: hrdesignation.Name,
        designationLevel: hrdesignation.HrDesignationLevelObj,
        costDesignations: hrdesignation.CostDesignationsObj,
        isEditmode: true
      });
    }

    loadInitialData = async () => {
      await getDesignationLevels()
      .then(response => {
        if (this.isMount) {
          this.setState({
              designationLevelData: response.data,
              designationLevelDataAll: response.data
          });
        }
      })
      .catch(error => {
       console.error(error);
      });

      await getCostDesignations()
      .then(response => {
        if (this.isMount) {
          this.setState({
              costDesignationData: response.data,
              costDesignationDataAll: response.data
          });
        }
      })
      .catch(error => {
       console.error(error);
      });
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    constructor(props) {
        super(props);
        this.validator = new FormValidator([
            {
              field: 'designationName',
              method: 'isEmpty',
              validWhen: false,
              message: 'Designation Name cannot be empty.'
            },
            {
              field: 'designationLevel',
              method: 'isEmpty',
              validWhen: false,
              message: 'Designation level cannot be empty.'
            }
        ]);

        
        this.state = {
            designationId: null,
            designationName: '',
            designationLevel: null,
            costDesignations: null,
            designationLevelData: [],
            designationLevelDataAll: [],
            costDesignationData: [],
            costDesignationDataAll: [],
            validation: this.validator.valid(),
            showCancelConfirmation: false,
            toDesignation: false,
            isEditmode: false,
            loading: false,
            visible: false,
            showSuccessImage: false,
            showErrorImage: true,
            dialogMessage: '',
            dialogTitle: ''
        };

        this.submitted = false;
    }

    render() {

        if (this.state.toDesignation === true) {
            return <Redirect to="/admin/hrdesignation/search"/>;
        }

        let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;

        return (
            <div className="main-card">
                <div className="row">
                    <div className="col-md-8">
                        <div className="main-heading">Designation Details</div>
                    </div>
                    <div className="col-md-4">

                    </div>
                </div>

                <form>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="d-block">
                                <label className="mandatory">HR Designation Name:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                <Input
                                    autoComplete="off"
                                    type="text"
                                    name="designationName"
                                    required={true}
                                    maxLength="100"
                                    value={this.state.designationName}
                                    onChange={this.handleChangeDesignationName}
                                />
                                <span className="inline-error">{validation.designationName.message}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <div className="d-block">
                                <label>Designation Level:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                <ComboBox
                                    placeholder="Please Select"
                                    name="designationLevel"
                                    data={this.state.designationLevelData}
                                    textField="Name"
                                    dataItemKey="id"
                                    onChange={this.handleChangeDesignationLevel}
                                    // required={true}
                                    value={this.state.designationLevel}
                                    filterable={true}
                                    onFilterChange={this.filterChangeDesignationLevel}
                                />
                                 {/* <span className="inline-error">{validation.designationLevel.message}</span> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <div className="d-block">
                                <label>Cost Designation:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                <MultiSelect
                                    name="costDesignation"
                                    data={this.state.costDesignationData}
                                    textField="Name"
                                    dataItemKey="id"
                                    value={this.state.costDesignations}
                                    filterable={true}
                                    onFilterChange={this.filterChangeCostDesignation}
                                    onChange={this.handleChangeCostDesignation}
                                />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                            <Button primary={true} type="submit" style={{ marginRight: '5px' }} 
                            // disabled={!this.state.isFormDirty}  
                            onClick={this.onClickSave}>
                                Save
                            </Button>
                            <Button type="button" onClick={this.onClickCancel}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </form>
                <Loader loading={this.state.loading} />
                <div>
                  {this.state.visible === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                      {this.state.showSuccessImage === true && (
                        <div className="text-center">
                          <img className="successImg" src={SuccessImg} alt="Success" />
                        </div>
                      )}

                      {this.state.showErrorImage === true && (
                        <div className="text-center">
                          <img className="successImg" src={ErrorImg} alt="Error Image" />
                        </div>
                      )}
                      <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                      <DialogActionsBar>
                        {this.state.showSuccessImage === true && (
                          <button className="k-button modal-primary" onClick={this.navigateBack}>
                            OK
                          </button>
                        )}

                        {this.state.showErrorImage === true && (
                          <button className="k-button modal-primary" onClick={this.toggleDialog}>
                            OK
                          </button>
                        )}
                      </DialogActionsBar>
                    </Dialog>
                  )}
                </div>
                <div>
                    {this.state.showCancelConfirmation === true && (
                    <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>
                        This form contains unsaved changes. Do you want to close it?
                        </p>
                        <DialogActionsBar>
                        <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                            No
                        </button>
                        <button className="k-button modal-primary" onClick={this.navigateBack}>
                            Yes
                        </button>
                        </DialogActionsBar>
                    </Dialog>
                    )}
                </div>
            </div>
        );
    }

    handleChangeDesignationName = async event => {
        if (this.isMount) {
          await this.setState({
            designationName: event.target.value,
            isFormDirty: true
          });
        }
        await this.validateProperty(event.target.value);
    };

    handleChangeDesignationLevel = async event => {
        if (this.isMount) {
          await this.setState({
            designationLevel: event.target.value,
            isFormDirty: true
          });
        }
        await this.validateProperty(event.target.value);
    };

    filterChangeDesignationLevel = event => {
        if (this.isMount) {
          this.setState({
            designationLevelData: this.filterDataDesignationLevel(event.filter)
          });
        }
      };

    filterDataDesignationLevel(filter) {
        const data = this.state.designationLevelDataAll.slice();
        return filterBy(data, filter);
      }

    handleChangeCostDesignation = async event => {
        if (this.isMount) {
            await this.setState({
                costDesignations: event.target.value,
                isFormDirty: true
            });
        }

    };

    filterChangeCostDesignation = event => {
        if (this.isMount) {
          this.setState({
            costDesignationData: this.filterDataCostDesignation(event.filter)
          });
        }
      };
    
      filterDataCostDesignation(filter) {
        const data = this.state.costDesignationDataAll.slice();
        return filterBy(data, filter);
      }

    validateProperty = value => {
        if (value) {
          return 'd-none';
        } else {
          return 'inline-error';
        }
    };

    toggleDialog = (message, title) => {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    };

    onClickCancel = event => {
        if (this.state.isFormDirty) {
          this.toggleCancelConfirmDialog();
        } else {
          this.navigateBack(event);
        }
    };

    onClickSave = event => {
      event.preventDefault();
      this.setState({
        loading: true
      });

      const validation = this.validator.validate(this.state);
      this.setState({ validation });
      this.submitted = true;

      let validForm = validation.isValid;

      // if (!validForm) {
      //   this.setState({
      //     loading: false,
      //     showSuccessImage: false,
      //     showErrorImage: true
      //   });
      //   this.toggleDialog(`Please fix the validation errors and save again.`, 'Error');
      //   return;
      // }

      const designation = {
        id: this.state.designationId,
        Name: this.state.designationName,
        DesignationLevelId: this.state.designationLevel ? this.state.designationLevel.id : null,
        CostDesignations: this.state.costDesignations,
        updatedUser: window.LOGGED_USER.displayName
      };

      saveHrDesignation(designation)
      .then(res => {
        this.setState({
          loading: false,
          showSuccessImage: true,
          showErrorImage: false
        });
        if (this.state.designationId) {
          this.toggleDialog(
            `HR Designation ` + this.state.designationName + ` is successfully updated.`,
            'Success'
          );
        } else {
          this.toggleDialog(
            `HR Designation ` + this.state.designationName + ` is successfully created.`
          );
        }
        this.setState({});
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          loading: false
        });
        this.setState({
          isDisableSave: false,
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog(error.response.data, 'Error');
      });

    }

    toggleCancelConfirmDialog = () => {
        if (this.isMount) {
          this.setState({
            showCancelConfirmation: !this.state.showCancelConfirmation
          });
        }
      };

      navigateBack = event => {
        if (this.isMount) {
          this.setState({
            toDesignation: true
          });
        }
      };

    setHeaderTitle = async () => {
        if (this.isMount) {
            if (this.props.location.action === 'edit') {
                this.props.onHeaderTitleChange('Edit HR Designations');
            } else {
                this.props.onHeaderTitleChange('Create HR Designations');
            }
        }
    };
}

export default CreateHRDesignation;
