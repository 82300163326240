module.exports = Object.freeze({
  CHG_TO_PRESALE : { value: 1 , name: 'Presales',code: 'PRESALES' } ,
  CHG_TO_WAITING_CONFIRMATION : { value: 2 , name: 'Waiting Confirmation',code:'WAIT_CONFIRM' } ,
  CHG_TO_CLOSE_OPPORTUNITY_LOST: { value: 3, name: 'Closed-Opportunity Loss',code:'CLOSE_OP_LOST' } ,
  CHG_TO_WORKING_AT_RISK : { value: 5, name: 'Working At Risk',code:'WORK_AT_RISK' } ,
  CHG_TO_ACTIVE : { value: 6, name: 'Active',code:'BILLABLE' } ,
  CHG_TO_CLOSE_WITHOUT_BIDDING: { value: 9, name: 'Close without bidding',code:'CLOSE_WITHOUT_BID' } ,
  CHG_TO_COMPLETE: { value: 11, name: 'Complete',code:'COMPLETE' },
  CHG_TO_DEAL_ON_HOLD : { value: 12, name: 'Deal On Hold',code:'DEAL_ON_HOLD' } ,
  CHG_TO_PROJECT_ON_HOLD : { value: 13, name: 'Project On Hold',code:'PROJECT_ON_HOLD' } ,
  CHG_TO_CLOSE_DISCONTINUE: { value: 14, name: 'Discontinue',code:'DISCONTINUE' },
  CHG_TO_CLOSE : { value: 15, name: 'Close', code: 'CLOSE' },
  CHG_TO_CLOSED : { value: 8, name: 'Closed', code: 'CLOSED' }
});
