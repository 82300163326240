import React, { Component } from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import TopBar from './TopBar';

// Uncomment Below lines for christmas decorations.
//import SnowStorm from 'react-snowstorm';
//const Snow = require('react-snow-effect');

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: false
    };
  }

  toggleCollapse = () => {
    this.setState({
      isCollapse: !this.state.isCollapse
    });
  };

  render() {
    return (
      <div>
        {/*<SnowStorm animationInterval={50} followMouse={false} flakesMax={500} flakesMaxActive={400} vMaxY={5}/>
        <Snow/>*/}
        <script>
          window.onbeforeunload = function () {
            window.scrollTo(0, 0)
          }
        </script>
        <Navigation onToggleCollapse={this.toggleCollapse} />
        <div className={this.state.isCollapse ? "main-container-collapse" : "main-container"} >

          <TopBar headerTitle={this.props.headerTitle} displayName={this.props.displayName} logoutCallback={this.props.logoutCallback} />

          <div className="content">
            <main>{this.props.children}</main>
          </div>

          <Footer />
        </div>
      </div>
    );
  }
}

export default Layout;
