import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Create Approver Type.
 * @returns {array} array list of Approver Type
 * @param attributes
 */
export function createApproverType(attributes) {
  const endPoint = apiUrl + '/masterData/approverType/createapprovertypes';
  return http.post(endPoint, attributes);
}

/**
 * Search Approver Types by given param.
 * @returns {array} array list of Approver Type
 * @param params
 */
export function searchApproverTypes(params) {
  const endPoint = apiUrl + '/masterData/approverType/approvertypes/search';
  return http.post(endPoint, params);
}

/**
 * Update Approver Types by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param attributes
 */
export function updateApproverType(attributes) {
  const endPoint = apiUrl + '/masterData/approverType/updateapprovertypes';
  return http.put(endPoint, attributes);
}

/**
 * Retrieve all Approver Types.
 * @returns {array} array list of Approver Types
 */
export function getApproverTypes() {
  const endPoint = apiUrl + '/masterData/approverType/getapprovertypes';
  return http.get(endPoint, null);
}

/**
 * Retrieve Approver type by id
 * @returns {object} Approver Type
 */
export function getApproverTypeById(approverTypeId) {
  const endPoint = apiUrl + '/masterData/approverType/getbyid/' + approverTypeId;
  return http.get(endPoint, null);
}
