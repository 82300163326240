import React, { Component } from 'react';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { searchInvoiceRequests, getAllProjectsAndCRsData, getInvoiceCreatedUsers, getIRStatuses, withdrawInvoiceRequest, reviseInvoiceRequest, getBillableTimeEntries } from './InvoiceService';
import { getRegionsIntegrated } from '../../integration/MasterDataService';
import ActionsCell from './ActionsCell';
import Loader from '../../integration/components/Loader';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import SuccessImg from '../../../src/ui/static/images/checked.svg';
import ErrorImg from '../../../src/ui/static/images/cancel.svg';
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import * as loggerService from "../../integration/LoggerService";
import moment from 'moment';
import 'moment-timezone';
import IRExcel from './IRExcel';

class InvoiceRequestSearch extends Component {
  _export;

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {

      invoiceRequests: [],
      projectAndCRs: [],
      projectAndCRsAll: [],
      region: [],
      regionAll: [],
      createdUser: [],
      createdUserAll: [],      
      selectedRegion: [],
      selectedProjectName: [],
      selectedCreatedUser: [],
      validRegions: [],
      invoiceRequestStatus: [],
      selectedStatus: null,
      selectedStartDate: null,
      selectedEndDate: null,
      userRoleAllowed: false,
      showInvoiceWithdrawDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      isErrorMessageVisible: false,
      withdrawReason: null,
      selectedInvoiceRequest: null,
      showSuccessImage: false,
      showErrorImage: false,
      visible: false,
      dialogMessage: '',
      dialogTitle: '',
      skip: 0,
      take: 20,
      generateIR: false,

      sort: [
        { field: 'updatedAt', dir: 'desc' }
      ],

      loading: false

    };
  }

  componentDidMount() {
    this.isMount = true;
    this.props.onHeaderTitleChange('View All Invoice Requests');
    this.setInitialData();
  }

  setInitialData = async() => {
    await this.setState({
      loading: true
    });
    await this.populateRegions();
    await this.checkUserRole();
    await this.validateRegions();
    await this.populateProjectsAndCRs();
    await this.populateCreatedUsers();
    await this.populateIRStatus();
    await this.populateInvoiceRequestDetails();
    await this.setState({
      loading: false
    });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  checkUserRole = async () => {
    const userRoles = window.LOGGED_USER.roles;
    const allowedUserRole = userRoles.some(obj => obj.Rolename === 'PMO' || obj.Rolename === 'Finance Manager' || obj.Rolename === 'System Admin' );
    await this.setState({
      userRoleAllowed: allowedUserRole
    });
  }

  validateRegions = async() => {
    let validRegions = [];
    const userPermissions = window.USER_PERMISSION;

    const asiaSpoc = userPermissions.some(obj => obj === 'ASIA_SPOC');
    if (asiaSpoc) {
        const asiaRegios = this.state.regionAll.filter(x => x.id === 3 || x.id === 5 || x.id === 18 || x.id === 19);
        validRegions = validRegions.concat(asiaRegios);
    }

    const anzSpoc = userPermissions.some(obj => obj === 'ANZ_SPOC');
    if (anzSpoc) {
        const anzRegios = this.state.regionAll.filter(x => x.id === 4);
        validRegions = validRegions.concat(anzRegios);
    }

    const ukSpoc = userPermissions.some(obj => obj === 'UK_EUROPE_SPOC');
    if (ukSpoc) {
        const ukRegios = this.state.regionAll.filter(x => x.id === 1 ||  x.id === 21);
        validRegions = validRegions.concat(ukRegios);
    }

    const usSpoc = userPermissions.some(obj => obj === 'AMERICAS_SPOC');
    if (usSpoc) {
        const usRegios = this.state.regionAll.filter(x => x.id === 2);
        validRegions = validRegions.concat(usRegios);
    }

    if (validRegions && validRegions.length > 0 && !this.state.userRoleAllowed) {
      await this.setState({
        region: validRegions,
        validRegions: validRegions
      });
    }
};

  populateInvoiceRequestDetails = async() => {
    const fields = {};
    fields.count = 0;
    fields.skip = this.state.skip;
    fields.take = this.state.take;
    fields.sort = this.state.sort;
    fields.Projects = this.state.projectAndCRsAll;
    if (this.state.projectAndCRsAll && this.state.projectAndCRsAll.length > 0) {
      await searchInvoiceRequests(fields)
      .then(res => {  
        if (this.isMount) {
          this.setState({
            invoiceRequests: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }
  };

  populateProjectsAndCRs = async() => {
    const fields = {};
    fields.AllAccess = this.state.userRoleAllowed;
    fields.ValidRegions = this.state.validRegions.map(value => value.id);;
    await getAllProjectsAndCRsData(fields)
      .then(res => {  
        if (this.isMount) {
          this.setState({
            projectAndCRs: res.data,
            projectAndCRsAll: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateRegions = async() => {
    await getRegionsIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            region: res.data,
            regionAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCreatedUsers = () => {
    getInvoiceCreatedUsers()
      .then(res => {
        if (this.isMount) {
          this.setState({
            createdUser: res.data,
            createdUserAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateIRStatus = () => {
    getIRStatuses()
      .then(res => {
        if (this.isMount) {
          this.setState({
            invoiceRequestStatus: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  filterData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  filterChangeProjectName = event => {
    if (this.isMount) {
      this.setState({
        projectAndCRs: this.filterData(event.filter, this.state.projectAndCRsAll)
      });
    }
  };

  filterChangeRegion = event => {
    if (this.isMount) {
      this.setState({
        region: this.filterData(event.filter, this.state.regionAll)
      });
    }
  };

  filterChangeCreatedUser = event => {
    if (this.isMount) {
      this.setState({
        createdUser: this.filterData(event.filter, this.state.createdUserAll)
      });
    }
  };

  handleInputChange = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  handleDateChange = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      if (valueObj) {
        const date = new Date(valueObj);
        this.setState({
          [field]: date
        });  
      } else {
        this.setState({
          [field]: valueObj
        }); 
      }
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      }, () => this.submitSearch(null));
    }
  };

  submitSearch = async event => {
    if (event) {
      event.preventDefault();
    } 

    if (this.isMount) {
      await this.setState({
        loading: true
      });
    }
    const fields = {
      Projects: this.state.selectedProjectName && this.state.selectedProjectName.length > 0 ? this.state.selectedProjectName : this.state.projectAndCRsAll,
      Region: this.state.selectedRegion,
      CreatedUser: this.state.selectedCreatedUser,
      StartDate: this.state.selectedStartDate ? moment(this.state.selectedStartDate).format("YYYY-MM-DD")  : null,
      EndDate: this.state.selectedEndDate ? moment(this.state.selectedEndDate).format("YYYY-MM-DD")  : null,
      Status: this.state.selectedStatus
    };

    fields.count = 0;
    fields.skip = event ? 0 : this.state.skip;
    fields.take = event ? 20 : this.state.take;
    fields.sort = event || this.state.sort.length === 0 ? [{ field: 'updatedAt', dir: 'desc' }] : this.state.sort;

    if (fields.Projects && fields.Projects.length > 0) {
      await searchInvoiceRequests(fields)
      .then(res => {
        if (this.isMount) {
          this.setState({
            invoiceRequests: res.data,
            loading: false,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }   
      await this.setState({
        loading: false,
      });
  };

  cancelSearch = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        selectedProjectName: [],
        selectedRegion: [],
        selectedCreatedUser: [],
        selectedStartDate: null,
        selectedEndDate: null,
        selectedStatus: []
      });
    }    
  };

  handleReviseInvoiceRequest = async(invoiceRequest) => {
    if (invoiceRequest && invoiceRequest.Status === 3) {
      await this.setState({
        selectedInvoiceRequest: invoiceRequest
      });
      this.toggleInvoiceRequestWithdrawDialog(
        'You are about to revise this Invoice Request. You will have to submit the Invoice Request for approval again. Do you wish to continue?',
        this.reviseInvoiceRequest
      );   
    }
  }

  reviseInvoiceRequest = async () => {
    if (!this.state.withdrawReason) {
      this.setState({
        isErrorMessageVisible: true,
      });
      return;
    } else {
      await this.setState({
        loading: true
      });
      const approveObj = {
        InvoiceRequest: this.state.selectedInvoiceRequest,
        comment: this.state.withdrawReason
      };  
      
      await reviseInvoiceRequest(approveObj)
        .then(async res => {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
          if (this.state.showInvoiceWithdrawDialog) {
            this.toggleInvoiceRequestWithdrawDialog('', null);
          }
          this.toggleDialog('Revised successfully', 'Success');
          await this.submitSearch();
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.toggleDialog(error.response, 'Error');
        });
        await this.setState({
          loading: false
        });
    }
  }

  handleWithdrawInvoiceRequest = async(invoiceRequest) => {
    if (invoiceRequest && invoiceRequest.Status === 2) {
      await this.setState({
        selectedInvoiceRequest: invoiceRequest
      });
      this.toggleInvoiceRequestWithdrawDialog(
        'You are about to withdraw this Invoice Request from the approval workflow. You will have to submit the Invoice Request for approval again. Do you wish to continue?',
        this.withdrawInvoiceRequest
      );   
    }
  }

  withdrawInvoiceRequest = async () => {
    if (!this.state.withdrawReason) {
      this.setState({
        isErrorMessageVisible: true,
      });
      return;
    } else {
      await this.setState({
        loading: true
      });
      const approveObj = {
        InvoiceRequest: this.state.selectedInvoiceRequest,
        comment: this.state.withdrawReason
      };  
      
      await withdrawInvoiceRequest(approveObj)
        .then(async res => {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
          if (this.state.showInvoiceWithdrawDialog) {
            this.toggleInvoiceRequestWithdrawDialog('', null);
          }
          this.toggleDialog('Withdrawn successfully', 'Success');
          await this.submitSearch();
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.toggleDialog(error.response, 'Error');
        });
        await this.setState({
          loading: false
        });
    }
  }

  handleDownloadInvoiceRequest = async(invoiceRequest) => {
    if (invoiceRequest) {
      const projectOrCr = invoiceRequest.CrId ? invoiceRequest.ChangeRequest : invoiceRequest.Project;
      const billingMethodId =   invoiceRequest.CrId ? invoiceRequest.ChangeRequest.PaymentMethod.id : invoiceRequest.Project.PaymentMethodRecord.id;

      const timeEntryObj = {
          ProjectId: invoiceRequest.ProjectId,
          CrId: invoiceRequest.CrId,
          ProjectOrCrName: invoiceRequest.ProjectName,
          Type: invoiceRequest.CrId ? 'ChangeRequest' : 'Project',
          CurrencyId: projectOrCr.Region.Currency.id,
          ProjectStartDate: moment(new Date(projectOrCr.StartDate)).format('YYYY-MM-DD'),
          StartDate: moment(new Date(invoiceRequest.StartDate)).format('YYYY-MM-DD'),
          EndDate: moment(new Date(invoiceRequest.EndDate)).format('YYYY-MM-DD'),
          InvoiceRequestId: this.state.invoiceRequest && 
            (this.state.invoiceRequest.Status === 2 || this.state.invoiceRequest.Status === 3 || this.state.invoiceRequest.Status === 6) ? 
            this.state.invoiceRequest.id : null
      }
      await getBillableTimeEntries(timeEntryObj)
      .then(res => {
          if (this.isMount) {
            invoiceRequest.TimeEntries = res.data.TimeEntries;
            invoiceRequest.TotalAmount = res.data.ActualRevenue;
          }
      })
      .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
      invoiceRequest.Type = 'Invoice';
      await this.setState({
        generateIR: true,
        selectedInvoiceRequest: invoiceRequest
      });  
    }
  }

  toggleInvoiceRequestWithdrawDialog = (message, action) => {
    this.setState({
        showInvoiceWithdrawDialog: !this.state.showInvoiceWithdrawDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action,
        withdrawReason: '',
        isErrorMessageVisible: false
    });
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  handleWithdrawReasonInputChange = event => {
    const value = event.target.value;
    this.setState({
      withdrawReason: value
    });
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">View All Invoice Requests</div>
          </div>
        </div>
        <form onSubmit={this.submitSearch}>
          <div className="main-heading" />
          
          <div className="row">

            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Project/CR Name:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedProjectName"
                    textField="DisplayName"
                    value={this.state.selectedProjectName}
                    data={this.state.projectAndCRs}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeProjectName}
                    onChange={this.handleInputChange} 
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Region:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedRegion"
                    textField="Name"
                    value={this.state.selectedRegion}
                    data={this.state.region}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeRegion}
                    onChange={this.handleInputChange} 
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Created By:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    name="selectedCreatedUser"
                    textField="DISTINCT"
                    value={this.state.selectedCreatedUser}
                    data={this.state.createdUser}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeCreatedUser}
                    onChange={this.handleInputChange}
                  />   
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                  <label htmlFor="">Date From: </label>
                </div>
                <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    width="100"
                    name="selectedStartDate"
                    onChange={this.handleDateChange}
                    value={this.state.selectedStartDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                  <label htmlFor="">Date To:</label>
                </div>
                <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    width="100"
                    name="selectedEndDate"
                    onChange={this.handleDateChange}
                    value={this.state.selectedEndDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                    min={new Date(this.state.selectedStartDate)}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Status:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedStatus"
                    textField="name"
                    value={this.state.selectedStatus}
                    data={this.state.invoiceRequestStatus}
                    placeholder={'Please Select'}
                    filterable={true}
                    onChange={this.handleInputChange} 
                  />
                </div>
              </div>
            </div>

          </div>

          <div className="row mt-3">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch}>
                Clear
              </Button>
            </div>
          </div>

        </form>

        <div className="main-seperator" />

        <div className="col-md-6">
            <div className="btn-align-right">
                {!this.state.isLoading && (
                    <IRExcel
                        irdata={this.state.selectedInvoiceRequest}
                        dataUpdated={() => {
                            this.setState({ generateIR: false });
                        }}
                        updateData={this.state.generateIR}
                    />
                )}
            </div>
        </div> 

        <div className="row">
          <div className="col-md-6">
            <div className="main-heading">Search Results</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
              <Grid
                data={this.state.invoiceRequests.rows}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.invoiceRequests.count}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
              >
                <Column field="ProjectName" title="Project/CR Name" />
                <Column field="Region" title="Region" />
                <Column field="BillingMethod" title="Billing Method" />
                <Column field="StartDate" title="Start Date" />
                <Column field="EndDate" title="End Date" />
                <Column field="UpdatedUser" title="Created By" />
                <Column field="updatedAtDateTime" title="Last Modified Date" />
                <Column field="StatusName" title="Status" />
                <Column field="" title="Actions" 
                  cell={props => 
                    <ActionsCell
                        {...props}
                        handleWithdrawInvoiceRequest={this.handleWithdrawInvoiceRequest}
                        handleReviseInvoiceRequest={this.handleReviseInvoiceRequest}
                        handleDownloadInvoiceRequest={this.handleDownloadInvoiceRequest}
                    />
                  }
                />
              </Grid>
          </div>
        </div>

        {this.state.showInvoiceWithdrawDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleInvoiceRequestWithdrawDialog} width="400px">
              <p style={{ margin: '10px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
              <p><label className="mandatory">Reason:</label></p>
              <textarea className="k-textarea" rows="5" cols="50" placeholder="Type Comment"
                        onChange={this.handleWithdrawReasonInputChange}
                        value={this.state.withdrawReason}
              ></textarea>
              {this.state.isErrorMessageVisible === true ? (
                <span className={this.validateProperty(this.state.withdrawReason)}>
                  Please provide a reason.
                </span>
              ) : null}
              <DialogActionsBar>
                  <button className="k-button" onClick={this.toggleInvoiceRequestWithdrawDialog}>
                      No
                  </button>
                  <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                      Yes
                  </button>
              </DialogActionsBar>
          </Dialog>
        )}

        {this.state.visible === true && (
          <Dialog
              title={this.state.dialogTitle}
              onClose={this.toggleDialog}
              width="400px">
              
              {this.state.showSuccessImage === true && (
                  <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
              )}

              {this.state.showErrorImage === true && (
                  <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image"/>
                  </div>
              )}

              <p style={{ margin: "25px", textAlign: "center" }}> {this.state.dialogMessage} </p>

              <DialogActionsBar>
                  <button
                      className="k-button modal-primary"
                      onClick={this.toggleDialog}>
                      OK
                  </button>
              </DialogActionsBar>
          </Dialog>
        )}

        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default InvoiceRequestSearch;



