import * as loglevel from 'loglevel';
import { LOG_TYPES } from './IntegrationEnums';

/**
 * Writes logs for all information, Error and warnings.
 * @returns string
 * @param message
 * @param type
 */
export function writeLog(message, type) {
  if (type === 'info') {
    loglevel.setLevel(LOG_TYPES.INFO);
    const logger = loglevel.getLogger('INFO');
    logger.info('====================================================================================');
    logger.info('Log type : INFO, Message : ' + message);
    logger.info('====================================================================================');
  } else if (type === 'error') {
    loglevel.setLevel(LOG_TYPES.ERROR);
    const logger = loglevel.getLogger('ERROR');
    logger.error('====================================================================================');
    logger.error('Log type : Error, Message : ' + message);
    logger.error('====================================================================================');
  } else {
    loglevel.setLevel(LOG_TYPES.WARN);
    const logger = loglevel.getLogger('WARN');
    logger.warn('====================================================================================');
    logger.warn('Log type : Warn, Message : ' + message);
    logger.warn('====================================================================================');
  }

}

export default loglevel;
