import React, { Component } from 'react';

import { DatePicker } from '@progress/kendo-react-dateinputs';
import CustomPopup from './CustomPopup';

class YearPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null
    };
  }

  componentDidMount() {
    if (this.props.value) {
      const date = new Date(this.props.value, 0, 1);
      this.setState({
        value: date
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value && this.props.value !== prevProps.value) {
      const date = new Date(this.props.value, 0, 1);
      this.setState({
        value: date
      });
    }
    else if(this.props.value === null && prevProps.value !== null) {
      this.setState({
        value: null
      });
    }
  }

  handleChange = event => {
    const date = event.value;
    this.setState({
      value: date
    });

    let year = 0;
    if (date) {
      year = date.getFullYear();
    }
    this.props.onChange({ value: year, name: this.props.name });
  };

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <DatePicker
          calendar={props => <CustomPopup {...props} />}
          onChange={this.handleChange}
          value={this.state.value}
          required={true}
          width="100%"
          format="yyyy"
          formatPlaceholder={{ year: 'YYYY' }}
          disabled={this.props.disabled}
          min={this.props.min}
          max={this.props.max}
        />
      </div>
    );
  }
}

export default YearPicker;
