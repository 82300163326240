import React, { Component } from "react";
import ResourceRequestStatusENUMS from "../resourceManagement/components/ResourceRequestStatusEnums";

class SublineStatusCell extends Component {

    render() {
        if (this.props.dataItem.Status) {
            if (this.props.dataItem.Status === 'NEW_FROM_CMS') {
                const value = 'NEW_FROM_CMS';
                return <td><td className="badge badge-warning font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else if (this.props.dataItem.Status === 'NEW_FROM_RM') {
                const value = 'NEW_FROM_RM';
                return <td><td className="badge badge-warning font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else if (this.props.dataItem.Status === 'EDIT') {
                const value = 'EDIT';
                return <td><td className="badge badge-warning font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else if (this.props.dataItem.Status === 'CONFIRMED') {
                const value = 'CONFIRMED';
                return <td><td className="badge badge-warning font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else if (this.props.dataItem.Status === 'RQST_APP_PENDING') {
                const value = 'RQST_APP_PENDING';
                return <td><td className="badge badge-primary font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else if (this.props.dataItem.Status === 'RES_ALLOC_PENDING') {
                const value = 'RES_ALLOC_PENDING';
                return <td><td className="badge badge-primary font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else if (this.props.dataItem.Status === 'ALLOC_APP_PENDING') {
                const value = 'ALLOC_APP_PENDING';
                return <td><td className="badge badge-primary font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else if (this.props.dataItem.Status === 'RQST_APP_REJECTED') {
                const value = 'RQST_APP_REJECTED';
                return <td><td className="badge badge-danger font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else if (this.props.dataItem.Status === 'RES_ALLOC_CANCELLED') {
                const value = 'RES_ALLOC_CANCELLED';
                return <td><td className="badge badge-danger font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else if (this.props.dataItem.Status === 'RES_ALLOC_REJECTED') {
                const value = 'RES_ALLOC_REJECTED';
                return <td><td className="badge badge-danger font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else if (this.props.dataItem.Status === 'COMPLETE') {
                const value = 'COMPLETE';
                return <td><td className="badge badge-success font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else if (this.props.dataItem.Status === 'CLOSE') {
                const value = 'CLOSE';
                return <td><td className="badge badge-secondary font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            } else if(this.props.dataItem.Status === 'RES_INVALIDATED'){
                const value = 'RES_INVALIDATED';
                return <td><td className="badge badge-danger font-weight-bold">{ResourceRequestStatusENUMS[value]}</td></td>;
            }
            else {
                return <td> </td>
            }
        } else {
            const value = this.props.dataItem.ToStatus;
            return <td>{ResourceRequestStatusENUMS[value]}</td>;
        }
    }
    // <td className="">

    //  <td className="badge badge-primary font-weight-bold">Test</td>

    // </td>



}

export default SublineStatusCell;
