/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { searchApproverTypes, updateApproverType } from './ApproverTypeService';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Redirect } from 'react-router';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import moment from 'moment';
import 'moment-timezone';
import Moment from 'react-moment';
import { filterBy } from '@progress/kendo-data-query';
import { orderBy } from '@progress/kendo-data-query';
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../../src/integration/LoggerService";
import {LOG_TYPES} from "../../../src/integration/IntegrationEnums";

const ACTION_CREATE = 'create';
const ACTION_EDIT = 'edit';

class ApproverTypeSearch extends Component {
  _export;
  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      approverTypes: [],
      approverName: '',

      skip: 0,
      take: 20,

      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      sort: [{ field: 'ApproverTypeName', dir: 'asc' }],
      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      approversList: [],
      selectedApprover: null,
      Action: '',
      searchStatus: null,
      status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
      loading: false
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.setHeaderTitle();
    this.submitSearch(null);
    this.populateApprovers();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Approver Type');
    }
  };
  sortChange = event => {
    if (this.isMount) {
      this.setState({
        sort: event.sort
      });
    }
  };
  populateApprovers = () => {
    let fields = null;
    searchApproverTypes(fields).then(res => {
      this.allApprovers = res.data;
      if (this.isMount) {
        this.setState({
          approversList: res.data
        });
      }
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  handleOnChangeRegion = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  filterBillingMethod = event => {
    if (this.isMount) {
      this.setState({
        approversList: this.filterBillingMethodData(event.filter)
      });
    }
  };

  filterBillingMethodData(filter) {
    const data = this.allApprovers.slice();
    return filterBy(data, filter);
  }

  submitSearch = event => {
    if (event) {
      event.preventDefault();
    }

    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    const fields = {};
    if (this.state.approverName) {
      fields.Name = this.state.approverName.ApproverTypeName;
    }
    if (this.state.searchStatus) {
      fields.IsActive = this.state.searchStatus ? this.state.searchStatus.Name : null;
    }

    searchApproverTypes(fields)
      .then(res => {
        if (this.isMount) {
          this.setState({
            approverTypes: res.data,
            loading: false,
            skip: 0
          });
        }
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleConfirmDialog = (message, action) => {
    if (this.isMount) {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  navigateToCreate = () => {
    if (this.isMount) {
      this.setState({
        redirectToCreate: true
      });
    }
  };

  onClickCreate = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState(
        {
          Action: ACTION_CREATE
        },
        () => {
          this.navigateToCreate();
        }
      );
    }
  };

  export = () => {
    let ExcelDataSet = this.state.approverTypes;
    let createdDate, updatedDate;
    for (let i = 0; i < ExcelDataSet.length; i++) {
      createdDate = moment(ExcelDataSet[i].createdAt).format('MM-DD-YYYY hh:mm:ss A');
      ExcelDataSet[i].createdAt = createdDate;
      updatedDate = moment(ExcelDataSet[i].updatedAt).format('MM-DD-YYYY hh:mm:ss A');
      ExcelDataSet[i].updatedAt = updatedDate;
      if (ExcelDataSet[i].IsActive === true) {
        ExcelDataSet[i].IsActive = 'Active';
      }
      if (ExcelDataSet[i].IsActive === false) {
        ExcelDataSet[i].IsActive = 'Inactive';
      }
    }
    ExcelDataSet.sort((a, b) => a - b).reverse();
    this._export.save(ExcelDataSet);
  };

  onClickEdit = (event, approver) => {
    event.preventDefault();
    if (this.isMount) {
      this.setState(
        {
          Action: ACTION_EDIT,
          selectedApprover: approver
        },
        () => {
          this.navigateToCreate();
        }
      );
    }
  };

  onClickDeactivate = (event, approver) => {
    event.preventDefault();
    const record = JSON.parse(JSON.stringify(approver));
    record.IsActive = false;
    if (this.isMount) {
      this.setState(
        {
          selectedApprover: record
        },
        () => {
          this.toggleConfirmDialog(
            'Are you sure you want to deactivate this?',
            this.deactivateApprover
          );
        }
      );
    }
  };

  onClickActivate = (event, approver) => {
    event.preventDefault();
    const record = JSON.parse(JSON.stringify(approver));
    record.IsActive = true;
    if (this.isMount) {
      this.setState(
        {
          selectedApprover: record
        },
        () => {
          this.toggleConfirmDialog(
            'Are you sure you want to Reactivate this?',
            this.activateApprover
          );
        }
      );
    }
  };

  activateApprover = () => {
    const record = this.state.selectedApprover;
    updateApproverType(record)
      .then(res => {
        const approvers = this.state.approverTypes.slice();
        const filter = approvers.filter(obj => obj.id === record.id);
        if (filter.length > 0) {
          filter[0].IsActive = true;
        }
        if (this.isMount) {
          this.setState({
            approverTypes: approvers,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        const message = 'Successfully activated';
        const title = 'Success';
        this.toggleMessageDialog(message, title);
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        const message = error && error.response ? error.response.data : 'An error occured';
        const title = 'Error';
        this.toggleMessageDialog(message, title);
      });
    this.toggleConfirmDialog('', null);
  };

  deactivateApprover = () => {
    const record = this.state.selectedApprover;
    updateApproverType(record)
      .then(res => {
        const approvers = this.state.approverTypes.slice();
        const filter = approvers.filter(obj => obj.id === record.id);
        if (filter.length > 0) {
          filter[0].IsActive = false;
        }
        if (this.isMount) {
          this.setState({
            approverTypes: approvers,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        const message = 'Successfully deactivated';
        const title = 'Success';
        this.toggleMessageDialog(message, title);
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        const message = error && error.response ? error.response.data : 'An error occured';
        const title = 'Error';
        this.toggleMessageDialog(message, title);
      });
    this.toggleConfirmDialog('', null);
  };

  clearForm = async event => {
    if (event) {
      event.preventDefault();
    }
    if (this.isMount) {
      this.setState(
        {
          approverName: '',
          searchStatus: null
        },
        () => {
          this.submitSearch(null);
        }
      );
    }
  };

  render() {
    if (this.state.redirectToCreate === true) {
      return (
        <Redirect
          to={{
            pathname: '/admin/approvertype/createapprovertype',
            approver: this.state.selectedApprover,
            action: this.state.Action
          }}
        />
      );
    }
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Approver Type Search</div>
          </div>

          <div className="col-md-4">
            <Button
              primary={true}
              type="button"
              onClick={this.onClickCreate}
              className="pull-right"
              disabled={!(window.USER_PERMISSION.indexOf('EDT_APPR_TY') > -1)}
            >
              Create New Approver Type
            </Button>
          </div>
        </div>

        <form onSubmit={this.submitSearch}>
          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                  <label>Approver Type:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                    placeholder="Please Select"
                    data={this.state.approversList}
                    value={this.state.approverName}
                    textField="ApproverTypeName"
                    dataItemKey="id"
                    onChange={this.handleOnChangeRegion}
                    name="approverName"
                    filterable={true}
                    onFilterChange={this.filterBillingMethod}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>
            <div className="col-md-2">
              <div className="d-block">
                  <label>Status:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                    name="searchStatus"
                    textField="Name"
                    dataItemKey="status"
                    value={this.state.searchStatus}
                    data={this.state.status}
                    placeholder={'Please Select'}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
            </div>
            <div className="row">
            <div className="col-md-12">
              <div className="btn-align-right">
                <Button primary={true} type="submit" onClick={this.submitSearch}>
                  Search
                </Button>
                <Button type="button" onClick={this.clearForm}>
                  Clear
                </Button>
              </div>
            </div>
            </div>
          
        </form>

        <div className="main-seperator"/>

        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Search Results</div>
          </div>

          <div className="col-md-4 btn-align-right">
            <button title="Export Excel" className="k-button k-primary" onClick={this.export}>
              Export to Excel
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <ExcelExport
              ref={exporter => {
                this._export = exporter;
              }}
              fileName={'ApproverType MasterData_' + new Date()}
            >


              <Grid
                data={orderBy(this.state.approverTypes, this.state.sort).slice(
                  this.state.skip,
                  this.state.take + this.state.skip
                )}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.approverTypes.length}
                pageable={true}
                onPageChange={this.pageChange}
                sortable={true}
                sort={this.state.sort}
                onSortChange={this.sortChange}
                resizable
                className="approver-type-grid"
              >
                <Column field="ApproverTypeName" title="Approver Type"/>
                <Column field="createdAt" title="Created Date" width="130px" cell={this.dateCell}/>
                <Column field="updatedAt" title="Last Modified Date" width="130px" cell={this.dateCell}/>
                <Column
                  field="LastModifiedUser"
                  title="Last Modified User"
                  cell={this.userNameCell}
                />
                <Column field="IsActive" title="Status" width="100px" cell={this.statusCell}/>
                <Column field="" title="Action" width="150px" cell={this.actionButtonCell}/>
              </Grid>
            </ExcelExport>
          </div>
        </div>

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success"/>
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image"/>
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        <Loader loading={this.state.loading}/>
      </div>
    );
  }

  dateCell = props => {
    if (props.dataItem) {
      let date = null;
      if (props.field === 'updatedAt') {
        date = new Date(props.dataItem.updatedAt);
      }

      if (props.field === 'createdAt') {
        date = new Date(props.dataItem.createdAt);
      }

      return (
        <td><Moment format="MM-DD-YYYY">
          {date}
        </Moment></td>
      );
    }
    return null;
  };

  statusCell = props => {
    if (props.dataItem) {
      return <td>{props.dataItem.IsActive === true ? 'Active' : 'Inactive'}</td>;
    }
    return null;
  };

  userNameCell = props => {
    if (props.field === 'LastModifiedUser') {
      let name = props.dataItem.LastModifiedUser;
      return <td>{name}</td>;
    }
    return null;
  };

  actionButtonCell = props => {
    return (
      <td className="action-buttons">
        {props.dataItem.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_APPR_TY') > -1) && (
          <a href="#" onClick={event => this.onClickEdit(event, props.dataItem)}>
            <div className="rounded-link" title="Edit">
              <span className="k-icon k-i-edit"/>
            </div>
          </a>
        )}
        {props.dataItem.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_APPR_TY') > -1) && (
          <a href="#" onClick={event => this.onClickDeactivate(event, props.dataItem)}>
            <div className="rounded-link" title="Deactivate">
              <span className="k-icon k-i-unsort"/>
            </div>
          </a>
        )}
        {props.dataItem.IsActive === false && (window.USER_PERMISSION.indexOf('EDT_APPR_TY') > -1) && (
          <a href="#" onClick={event => this.onClickActivate(event, props.dataItem)}>
            <div className="rounded-link" title="Activate">
              <span className="k-icon k-i-check"/>
            </div>
          </a>
        )}
      </td>
    );
  };

  handleInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    if (this.isMount) {
      await this.setState({ [name]: value, isFormDirty: true });
    }
  };
}

export default ApproverTypeSearch;
