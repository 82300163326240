import React, { Component } from "react";
import ResourceRequestStatusENUMS from "./ResourceRequestStatusEnums";

class ResourceRequestEditStatusCell extends Component {

  render() {
    const value = this.props.dataItem.Status;
    return <td>{ResourceRequestStatusENUMS[value]}</td>;
  }

}

export default ResourceRequestEditStatusCell;
