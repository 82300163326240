import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;
const resourceUtilizationRouter = apiUrl + '/timeSheetManagement/resourceUtilization/'

/**
 * getResourceUtilizations - retirive all resource utilizations
 * @returns {Object}  Object of resource utilizations
 * @param parms
 */
export  function getResourceUtilizations(parms){
    const endPoint = resourceUtilizationRouter + 'getResourceUtilizations';
    return  http.post(endPoint,parms);
}

