import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CostCardActionsCell extends Component {
    render() {
        const { OnDeactivate } = this.props;
        const { OnActivate } = this.props;
        const costCard = this.props.dataItem;
        return (
            <td className="action-buttons">
                {(window.USER_PERMISSION.indexOf('VW_CST_CRD') > -1) &&
                <Link
                    to={{
                        pathname: '/admin/costcard/edit',
                        action: 'view',
                        costcard: costCard
                    }}
                >
                    <div className="rounded-link">
                        <span className="k-icon k-i-preview" title="View" />
                    </div>
                </Link>
                }
                {costCard.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_COS_CR') > -1) &&(
                    <Link
                        to={{
                            pathname: '/admin/costcard/edit',
                            action: 'edit',
                            costcard: costCard
                        }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-edit" title="Edit" />
                        </div>
                    </Link>

                )}

                {costCard.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_COS_CR') > -1) && (
                    <Link
                        to={{
                            pathname: '/admin/costcard/edit',
                            action: 'duplicate',
                            costcard: costCard
                        }}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-backward-element" title="Duplicate" />
                        </div>
                    </Link>
                )}

                {costCard.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_COS_CR') > -1) && (
                    <Link
                        to={this.props}
                        onClick={(event) => this.props.OnDeactivate(event, this.props.dataItem.id)}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-unsort" title="Deactivate" />
                        </div>
                    </Link>
                )}

                {costCard.IsActive === false && (window.USER_PERMISSION.indexOf('EDT_COS_CR') > -1) && (
                    <Link
                        to={this.props}
                        onClick={(event) => this.props.OnActivate(event, this.props.dataItem.id)}
                    >
                        <div className="rounded-link">
                            <span className="k-icon k-i-check" title="Activate" />
                        </div>
                    </Link>
                )}
            </td>
        );
    }
}

export default CostCardActionsCell;
