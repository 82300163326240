import React, { Component } from 'react';

class ProjectORCrCell extends Component {
  render() {
    if (this.props.dataItem.Type === 'Project'){
      return <td>
        {this.props.dataItem.Project.ProjectName}
      </td>;
    }else if(this.props.dataItem.Type === 'ChangeRequest'){
      return <td>
        {this.props.dataItem.ChangeRequest.CRName}
      </td>;
    }else{
      return <td>
        {""}
      </td>;
    }
  }
}

export default ProjectORCrCell;