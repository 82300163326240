/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

class RowDetails extends Component {
    render() {
        const dataItem = this.props.dataItem;
        return (
            <section>
                <p>
                    <strong>Cost:</strong>{' '}
                    <span className={dataItem.costNoLongerValid === true ? 'text-red' : ''}>
            {dataItem.TotalCost
                ? dataItem.TotalCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : 0}
          </span>{' '}
                    | <strong>Revenue:</strong>{' '}
                    <span className={dataItem.rateNoLongerValid === true ? 'text-red' : ''}>{dataItem.TotalRevenue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} |{' '}</span>
                    <strong>Effort Days:</strong> {dataItem.TotalEffortDays} |{' '}
                    <strong>Cost to Revenue Ratio:</strong>{' '}
                    <span className={dataItem.costNoLongerValid === true || dataItem.rateNoLongerValid === true ? 'text-red' : ''}>
                    {(dataItem.TotalCost > 0 && dataItem.TotalRevenue > 0 && dataItem.CostToRevenueRatio !== null)
                        ? dataItem.CostToRevenueRatio.toFixed(2)
                        : "N/A"}
                    </span>
                    {(dataItem.RateType === 4 || dataItem.RateType === 3) && (
                        <>
                        {' '} | <strong>Standard Rate:</strong>{' '}
                        <span className={dataItem.costNoLongerValid === true || dataItem.rateNoLongerValid === true ? 'text-red' : ''}>
                            {Number(dataItem.StandardRate).toFixed(2)}
                        </span> 

                        {' '} | <strong>Override Rate Variance:</strong>{' '}
                        <span className={dataItem.costNoLongerValid === true || dataItem.rateNoLongerValid === true ? 'text-red' : ''}>
                            {Number(Number(dataItem.RateValue) - Number(dataItem.StandardRate)).toFixed(2)}
                        </span> 

                        {' '} | <strong>Override Rate Variance Ratio:</strong>{' '}
                        <span className={dataItem.costNoLongerValid === true || dataItem.rateNoLongerValid === true ? 'text-red' : ''}>
                            {(dataItem.RateValue && dataItem.RateValue > 0 && dataItem.StandardRate && dataItem.StandardRate > 0) ? 
                                Number(Number(dataItem.StandardRate) / Number(dataItem.RateValue)).toFixed(2)
                                : "N/A"}
                        </span> 
                        </>
                    )}
                </p>
            </section>
        );
    }
}

export default RowDetails;
