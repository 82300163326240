/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {getFormattedDate} from "../../integration/CommonUtils";

class TaskManagementCell extends Component {
  render() {
    const project = this.props.dataItem;
    let projectManager = false;
    let allocatedResources = false;
    const viewProjTaskMngt = window.USER_PERMISSION.filter(obj => obj === 'VW_PRJTASKMNGMT');

    const projectProjectManagers = project.AllocatedResourceRequestPM;
    if (projectProjectManagers.length > 0){
      //find valid pms from end date
      let projValidManagers = [];
      projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (projValidManagers.length == 0 && projectProjectManagers.length > 0){

        projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
        const lastAssignedManager = projectProjectManagers[0];
        projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

      }

      projValidManagers.forEach(value => {
        if(window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()){
          projectManager = true;
        }
      });
    }

    return (
      <td className="action-buttons">
       Status : {project.ProjectStatus ? project.ProjectStatus.StatusName : ''}
        <br />
  

   {((viewProjTaskMngt && viewProjTaskMngt.length>0) || projectManager === true) &&(
          <Link
            to={{
              pathname: '/timesheets/projecttaskmanagement/projecttaskmanagement',
              projectId: project.id,
              action: 'view',
              project:project
            }}
          >
            <div className="rounded-link" title="View/Edit Task Management">
              <span className="k-icon k-i-edit" />
            </div>
          </Link>
          )}
      </td>
    );
  }
}

export default TaskManagementCell;