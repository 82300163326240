import React, { Component } from 'react';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-react-dialogs';
import { searchOnsiteResources, 
    deleteOnsiteResource, 
    saveOnsiteResource, 
    updateOnsiteResource } from './ResourceOnsiteDurationService';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { getRegionsWithCountriesIntegrated } from '../../integration/MasterDataService';
import StartDateCell from './StartDateCell';
import EndDateCell from './EndDateCell';
import ProjectTypeCell from './ProjectTypeCell';
import ResourceOnsiteActionCell from './ResourceOnsiteActionCell';
import { Link } from 'react-router-dom';
import { getUserList } from '../../userManagement/user/userService';
import { getAllChangeRequests } from '../../projectManagement/changeRequest/ChangeRequestService';
import { getAllProjects } from '../../projectManagement/project/ProjectService';
import moment from 'moment';
import 'moment-timezone';
import { Input } from '@progress/kendo-react-inputs';
import Loader from '../../integration/components/Loader';

class ResourceOnsiteDuration extends Component {

    isMount = false;

    constructor(props) {
        super(props);
        this.state = {
            addnewdialog: false,
            loading: false,
            onsiteResources: [],
            resource: [],
            resourceAll: [],
            selectedResource: [],
            country: [],
            countryAll: [],
            selectedResourceCountry: null,
            selectedOnsiteCountry: null,
            startDate: null,
            endDate: null,
            skip: 0,
            take: 20,
            showDelete: false,
            showEdit: false,
            userList: [],
            userListAll: [],
            selectedUser: null,
            resourceCountry: [],
            onsiteCountry: [],
            onsiteCountryAll: [],
            addStartDate: null,
            addEndDate: null,
            minDate: null,
            projects: [],
            changeRequests: [],
            projectsAndChangeRequests: [],
            disableProjects: true,
            projectSpecific: '',
            resIdToDelete: null,
            showConfirmDialog: false,
            confirmDialogMessage: '',
            confirmDialogAction: null,
            showMessageDialog: false,
            dialogMessage: '',
            dialogTitle: '',
            showMessageDialog: false,
            projectSpecific: "",
            isErrorMessageVisible: false,
            action: '',
            onsiteResourceId: null,
            isOverlapping: false,
            showOverlapingMessageDialog: false,

            sort: [
                { field: 'updatedAt', dir: 'desc' }
            ],
        };
        this.toggleAddNewDialog = this.toggleAddNewDialog.bind(this);
    }

    toggleAddNewDialog() {
        this.setState({
            addnewdialog: !this.state.addnewdialog
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.isMount = true;
        this.setHeaderTitle();
        this.populateOnsiteResources();
        this.populateResources();
        this.populateCountries();
        this.populateUserList();
        this.populateProjectsAndCr();
        this.handleMinDate();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    setHeaderTitle = async () => {
        if (this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Resource Calendar Mapping');
        }
    };

    populateOnsiteResources = () => {
        const fields = {};
        searchOnsiteResources(fields)
          .then(res => {
            if (this.isMount) {
            const activeOnsiteResources= res.data.filter(value => value.IsActive === true);
              this.setState({
                onsiteResources: activeOnsiteResources
              });
            }
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      };

    populateResources = () => {
        const fields = {};
        searchOnsiteResources(fields)
            .then(res => {
                if (this.isMount) {
                    const activeOnsiteResources= res.data.filter(value => value.IsActive === true);
                    const result = activeOnsiteResources.map(value => (value.Resource))
                    const resourceList = [...new Map(result.map(val =>[val["Name"], val])).values()]
                    this.setState({
                        resource: resourceList,
                        resourceAll: resourceList
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateCountries = () => {
        getRegionsWithCountriesIntegrated()
        .then(res => {
            if (this.isMount) {
                const result = res.data.map(value => (value.Country))
                this.allCountries = [];
                for (var item of result){         
                    for (var country of item){
                        this.allCountries.push(country);
                    }      
                } 
                const countryList = [...new Map(this.allCountries.map(val =>[val["Name"], val])).values()]
                countryList.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
                this.setState({
                    country: countryList,
                    countryAll: countryList,
                    onsiteCountry: countryList,
                    onsiteCountryAll: countryList
                });
            }
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    populateUserList = () =>{
        getUserList().then(response => {
            if(this.isMount) {
                const activeUsers = response.data.filter(value => value.IsActive === true);
                const resource = activeUsers.map(value => (value.Resource))
                this.setState({
                    userList: resource,
                    userListAll: resource
                });
            }
        }).catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    populateProjectsAndCr = async () => {
        await getAllProjects().then(response => {
          this.allProjects = [];
    
          for (var project of response.data) {
            if (project.IsActive) {
              var obj = {
                'id': project.id,
                'UniqueId': project.UniqueId,
                'OPID': project.OPID,
                'Type': 'Project',
                'ProjectName': project.ProjectName,
                'displayName': project.ProjectName + " - " + project.UniqueId,
              }
              this.allProjects.push(obj);
            }
          };
    
          this.allProjects.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));
    
          if (this.isMount) {
            this.setState({
              projects: this.allProjects
            });
          }
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });

          await getAllChangeRequests().then(response => {
            this.allChangeRequests = [];
      
            for (var cr of response.data) {
              if (cr.IsActive) {
                var obj = {
                  'id': cr.id,
                  'UniqueId': cr.UniqueId,
                  'OPID': cr.OPID,
                  'ProjectId': cr.ProjectId,
                  'CRName': cr.CRName,
                  'Type': 'ChangeRequest',
                  'displayName': cr.CRName + " - " + cr.UniqueId,
                }
                this.allChangeRequests.push(obj);
              }
            };
      
            this.allChangeRequests.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));
      
            if (this.isMount) {
              this.setState({
                changeRequests: this.allChangeRequests
              });
            }
          })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
            
            const allProjectsAndChangeRequests = this.state.projects.concat(this.state.changeRequests);
            if (this.isMount) {
              this.setState({
                  projectsAndChangeRequests: allProjectsAndChangeRequests,
                  projectsAndChangeRequestsAll: allProjectsAndChangeRequests
              });
            }
      };

    handleInputChange = async event => {
        const name = event.target.name;
        const value = event.target.value;
        await this.setState({ [name]: value, isFormDirty: true });
    };

    handleStartDateChange = event => {
        let startDate = event.target.value;
        if (this.isMount) {
            this.setState({
                startDate: startDate,
                isFormDirty: true,
            });
        }
    };

    handleEndDateChange = event => {
        let endDate = event.target.value;
        if (this.isMount) {
            this.setState({
                endDate: endDate,
                isFormDirty: true,
            });
        }
    };

    handleMinDate = () => {
        var minDate = moment().subtract(21, 'days').format("YYYY-MM-DD");
        this.setState({
            minDate: minDate
          });
    }

    handleAddStartDateChange = event => {
        let addStartDate = event.target.value;
        if (this.isMount) {
            this.setState({
                addStartDate: addStartDate,
                isFormDirty: true
            });
        }
    };

    handleAddEndDateChange = event => {
        let addEndDate = event.target.value;
        if (this.isMount) {
            this.setState({
                addEndDate: addEndDate,
                isFormDirty: true,
            });
        }
    };

    handleOnChangeUser = event => {
        const valueObj = event.target.value;
        const field = event.target.name;
        if (this.isMount) {
            this.setState({
                [field]: valueObj,
                isFormDirty: valueObj ? true : false,
            });
        }
        if(valueObj !== null){
            const resCountry = this.state.country.filter(value => value.id === valueObj.GeneralCountry);
            if (resCountry && resCountry.length > 0) {
                this.setState({
                    resourceCountry: resCountry,
                    selectedAddResourceCountry: resCountry[0]
                });
                const onSiteCountries = this.state.country.filter(value => value !== resCountry[0]);
                this.setState({
                    onsiteCountry: onSiteCountries,
                    onsiteCountryAll: onSiteCountries
                });
                if (resCountry[0] === this.state.selectedAddOnsiteCountry) {
                    this.setState({
                        selectedAddOnsiteCountry: null
                    });
                }
            }         
        }else{
            this.setState({
                selectedAddResourceCountry: null,
                onsiteCountry: this.state.country,
                onsiteCountryAll: this.state.country,
                selectedAddOnsiteCountry: null
            });
        }
    };

    handleOnChangeProjectSpec = event => {
        const valueObj = event.target.value;
        const field = event.target.name;
        if (this.isMount) {
            this.setState({
                projectSpecific: valueObj,
                isFormDirty: valueObj ? true : false,
            });

            if(valueObj === "Project"){
                this.setState({
                    disableProjects: false
                });
            }
            else if(valueObj === "NonProject") {
                this.setState({
                    disableProjects: true,
                    selectedProjectOrCr: null
                });
            }
        }      
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        if (!this.validation()){
            if(this.isMount){
                this.setState({ 
                    isErrorMessageVisible: true,
                    visible: true,
                    showErrorImage: true,
                    showSuccessImage: false
                });            
            }
            this.toggleMessageDialog('Please fix the highlighted errors to continue', 'Error');
        }
        else{
            if(!this.validateOverlapping()){
                if(this.isMount){
                    this.setState({ 
                        isErrorMessageVisible: true,
                        visible: true,
                        showErrorImage: true,
                        showSuccessImage: false,
                        isOverlapping: false,
                        action: ''
                    });            
                }
                this.toggleOverlapingMessageDialog('Onsite durations are overlapping', 'Error');
            }
            else{
                if(this.isMount){
                    this.setState({
                        isErrorMessageVisible: false,
                        isOverlapping: false
                    });
                }

                var projectSpecific = "";
                var projectType = null;
                if (this.state.projectSpecific === 'Project') {
                    projectSpecific = "Project";
                    projectType = this.state.selectedProjectOrCr.Type;
                }else{
                    projectSpecific = "Non-Project";
                    projectType = null;
                }
    
                var selectedProject = null;
                var selectedChangeRequest = null;
                if (this.state.selectedProjectOrCr) {
                    if (this.state.selectedProjectOrCr.Type === "Project"){
                        selectedProject = this.state.selectedProjectOrCr.id;
                        selectedChangeRequest = null;
                    }
                    else {
                        selectedProject = this.state.selectedProjectOrCr.ProjectId;
                        selectedChangeRequest = this.state.selectedProjectOrCr.id;
                    }
                    
                }else{
                    selectedProject = null;
                    selectedChangeRequest = null
                }

                var selectedAddResourceCountry = null;
                if (this.state.selectedAddResourceCountry) {
                    selectedAddResourceCountry = this.state.selectedAddResourceCountry.id;
                }else{
                    selectedAddResourceCountry = null;
                }

                let onsiteResourceId = this.state.onsiteResourceId;
                let onsiteStartDate = moment(this.state.addStartDate).format("YYYY-MM-DD");
                let onsiteEndDate = moment(this.state.addEndDate).format("YYYY-MM-DD");
        
                var OnsiteResourceObj = {
                    ResourceId: this.state.selectedUser.id,
                    ResourceCountryId: selectedAddResourceCountry,
                    OnsiteCountryId: this.state.selectedAddOnsiteCountry.id,
                    AutomaticOrManual: "Manual",
                    ProjectOrNonProject: projectSpecific,
                    Type: projectType,
                    ProjectId: selectedProject,
                    ChangeRequestId: selectedChangeRequest,
                    StartDate: onsiteStartDate,
                    EndDate: onsiteEndDate,
                    IsActive: true,
                    onsiteResourceId: onsiteResourceId
                };

                if (this.state.action !== "edit") {
                    await saveOnsiteResource(OnsiteResourceObj).then(response => {
                        this.setState({
                            showSuccessImage: true,
                            showErrorImage: false
                        });
                        this.toggleMessageDialog('Onsite resource added successfully.', 'Success');
                        
                    }).catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });
                }
                else{
                    await updateOnsiteResource(OnsiteResourceObj, onsiteResourceId).then(response => {
                        this.setState({
                            showSuccessImage: true,
                            showErrorImage: false,
                            action: '',
                        });
                        this.toggleMessageDialog('Onsite resource updated successfully.', 'Success');
                        
                    }).catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });
                }
            }
            

            this.populateOnsiteResources();
            this.populateUserList();
            this.populateCountries();
            this.populateResources();
            this.populateProjectsAndCr();

            this.setState({
                selectedUser: null,
                selectedAddResourceCountry: null,
                selectedAddOnsiteCountry: null,
                addStartDate: null,
                addEndDate: null,
                projectSpecific: null,
                selectedProjectOrCr: null,
                showSuccessImage: false,
                isFormDirty: false,
                disableProjects: true,
                onsiteResourceId: null,
                addnewdialog: !this.state.addnewdialog,
                endDate: null,
                startDate: null,
                selectedOnsiteCountry: null,
                selectedResourceCountry: null,
                selectedResource: []
            });
        }  
    }
    
    onClickNo = () => {
        if(this.state.isFormDirty === true){
         this.toggleConfirmationDialog('This form contains unsaved data, do you want to close it?', 'Confirm');
        }
        else{
         this.setState({
            addnewdialog: !this.state.addnewdialog,
            selectedUser: null,
            selectedAddResourceCountry: null,
            selectedAddOnsiteCountry: null,
            addStartDate: null,
            addEndDate: null,
            projectSpecific: null,
            selectedProjectOrCr: null,
            showSuccessImage: false,
            isFormDirty: false,
            onsiteResourceId: null,
            disableProjects: true,
            isErrorMessageVisible: false
         });
        }
     };

    onCancelOk = (event) => {
        event.preventDefault();
        if (this.isMount) {
            this.setState({
                selectedUser: null,
                selectedAddResourceCountry: null,
                selectedAddOnsiteCountry: null,
                addStartDate: null,
                addEndDate: null,
                projectSpecific: null,
                selectedProjectOrCr: null,
                showSuccessImage: false,
                isFormDirty: false,
                disableProjects: true,
                onsiteResourceId: null,
                addnewdialog: !this.state.addnewdialog
            });
        }
        this.toggleConfirmationDialog('', null);
        this.populateUserList();
        this.populateCountries();
        this.populateResources();
        this.populateProjectsAndCr();
    };

    validateOverlapping = () => {

        if (this.state.onsiteResources.length === 0) {
            return true;
        }
        else {
            const selectedUserArr= this.state.onsiteResources.filter(value => value.ResourceId === this.state.selectedUser.id);
            if (selectedUserArr && selectedUserArr.length > 0) {
                const userOnsiteDurations = selectedUserArr.filter(value => value.id !== this.state.onsiteResourceId);
                if (userOnsiteDurations && userOnsiteDurations.length > 0) {
                    for (const resource of userOnsiteDurations){
                        if (resource.OnsiteCountryId !== this.state.selectedAddOnsiteCountry.id) {

                            let resStartDate = moment(resource.StartDate);
                            let resEndDate = moment(resource.EndDate);
                            let addStartDate = moment(this.state.addStartDate);
                            let addEndDate = moment(this.state.addEndDate);

                            if (addStartDate.isBetween(resStartDate, resEndDate) || addEndDate.isBetween(resStartDate, resEndDate) || 
                            resStartDate.isBetween(addStartDate, addEndDate) || addStartDate.isSame(resStartDate) || 
                            addEndDate.isSame(resStartDate) || addStartDate.isSame(resEndDate)) {
                                if (this.isMount) {
                                    this.setState({ 
                                        isOverlapping: true
                                    });
                                }
                                return false;
                            } 
                        }                      
                    } 
                }
            }                                 

            if (this.state.isOverlapping === false) {
                return true;
            }
        }
    }
    
    validation = () => {
        if (
            this.validateProperty(this.state.selectedUser)
              .toString()
              .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.selectedAddOnsiteCountry)
              .toString()
              .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.addStartDate)
              .toString()
              .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.addEndDate)
              .toString()
              .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.projectSpecific)
              .toString()
              .includes('error')
        ) {
            return false;
        }
        else if (
            (this.state.projectSpecific === 'Project') && this.validateProperty(this.state.selectedProjectOrCr)
              .toString()
              .includes('error')
        ) {
            return false;
        }   
        else {
            return true;
        }
    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };
     

    filterDataResource(filter) {
        const data = this.state.resourceAll.slice();
        return filterBy(data, filter);
    }
    
    filterChangeResource = event => {
        if (this.isMount) {
            this.setState({
                resource: this.filterDataResource(event.filter)
            });
        }
    };

    filterDataCountry(filter) {
        const data = this.state.countryAll.slice();
        return filterBy(data, filter);
    }

    filterChangeCountry = event => {
        if (this.isMount) {
            this.setState({
                country: this.filterDataCountry(event.filter)
            });
        }
    };

    filterDataUsers(filter) {
        const data = this.state.userListAll.slice();
        return filterBy(data, filter);
    }
    
    filterChangeUsers = event => {
        if (this.isMount) {
            this.setState({
                userList: this.filterDataUsers(event.filter)
            });
        }
    };

    filterDataOnsiteCountry(filter) {
        const data = this.state.onsiteCountryAll.slice();
        return filterBy(data, filter);
    }
    
    filterOnsiteCountry = event => {
        if (this.isMount) {
            this.setState({
                onsiteCountry: this.filterDataOnsiteCountry(event.filter)
            });
        }
    };

    filterProjectData(filter) {
        const data = this.state.projectsAndChangeRequestsAll.slice();
        return filterBy(data, filter);
    }

    filterProjectOnChange = event => {
        this.setState({
            projectsAndChangeRequests: this.filterProjectData(event.filter)
        });
    };


    submitSearch = event => {
        event.preventDefault();

        if (this.isMount) {
            this.setState({
                loading: true
            });
        }

        const fields = {};
        if (this.state.selectedResource) {
            fields.ResourceId = this.state.selectedResource.map(value => value.id);
        }
        if (this.state.selectedResourceCountry) {
            fields.ResourceCountryId = this.state.selectedResourceCountry.map(value => value.id);
        }
        if (this.state.selectedOnsiteCountry) {
            fields.OnsiteCountryId = this.state.selectedOnsiteCountry.map(value => value.id);
        }

        fields.startDate = this.state.startDate ? moment(this.state.startDate).format("YYYY-MM-DD") : '1900-01-01';

        fields.endDate = this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : '2199-12-31';

        searchOnsiteResources(fields)
            .then(res => {
            if (this.isMount) {
                const activeOnsiteResources= res.data.filter(value => value.IsActive === true);
                this.setState({
                    onsiteResources: activeOnsiteResources,
                    loading: false,
                    skip: 0
                });
            }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    cancelSearch = event => {
        event.preventDefault();
        if (this.isMount) {
            this.setState({
                selectedResource: [],
                selectedResourceCountry: [],
                selectedOnsiteCountry: [],
                startDate: null,
                endDate: null
            });
        }
        this.populateOnsiteResources();
        this.populateResources();
        this.populateCountries();
    };

    deleteOnsiteResourceId = (resId) => {
        if (this.isMount) {
          this.setState(
            {
              resIdToDelete: resId
            },
            () => {
              this.toggleDeleteConfirmDialog(
                'Are you sure you want to delete this onsite time duration?',
                this.deleteOnsiteResource
              );
            }
          );
        }
      };
    
    deleteOnsiteResource = () => {
        deleteOnsiteResource(this.state.resIdToDelete)
        .then(res => {
        if (this.isMount) {
            this.setState({
                resIdToDelete: null,
            });
        }
        this.toggleDeleteConfirmDialog('', null);
        this.toggleMessageDialog('Successfully Deleted', 'Success');
        this.populateOnsiteResources();
        this.populateResources();
        this.populateCountries();
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
        if (this.isMount) {
            this.setState({
                selectedResource: [],
                selectedResourceCountry: [],
                selectedOnsiteCountry: [],
                startDate: null,
                endDate: null
            });
        }
    };

    onClickEdit = async (data) => {
        if (this.isMount) {
          await this.setState(
            {
                action: 'edit',
            }
          );
        }
        
        if (data && data.ProjectOrNonProject === "Project") {
            let project = null;
            if (data.Type === "ChangeRequest") {
                project = {"displayName": data.ChangeRequest.CRName + " - " + data.ChangeRequest.UniqueId};
            }
            else {
                project = {"displayName": data.Project.ProjectName + " - " + data.Project.UniqueId};
            }     
            this.setState({
                selectedUser: data.Resource,
                selectedAddResourceCountry: data.ResourceCountry,
                selectedAddOnsiteCountry: data.OnsiteCountry,
                addStartDate: new Date(data.StartDate),
                addEndDate: new Date(data.EndDate),
                projectSpecific: "Project",
                selectedProjectOrCr: project,
                onsiteResourceId: data.id,
                disableProjects: false
            });
        }
        if (data && data.ProjectOrNonProject === "Non-Project") {
            this.setState({
                selectedUser: data.Resource,
                selectedAddResourceCountry: data.ResourceCountry,
                selectedAddOnsiteCountry: data.OnsiteCountry,
                addStartDate: new Date(data.StartDate),
                addEndDate: new Date(data.EndDate),
                projectSpecific: "NonProject",
                selectedProjectOrCr: null,
                onsiteResourceId: data.id,
            });
        }
        this.toggleAddNewDialog();
    }
    
    toggleMessageDialog = (message, title) => {
        this.setState({
            showMessageDialog: !this.state.showMessageDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    toggleOverlapingMessageDialog = (message, title) => {
        this.setState({
            showOverlapingMessageDialog: !this.state.showOverlapingMessageDialog,
            dialogMessage: message,
            dialogTitle: title,
            isErrorMessageVisible: false
        });
    };

    toggleDeleteConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    toggleConfirmationDialog = (message, title) => {
        this.setState({
            visibleConfirmationDialog: !this.state.visibleConfirmationDialog,
            dialogMessage: message,
            dialogTitle: title,
            isErrorMessageVisible: false
        });
    };

    pageChange = event => {
        if (this.isMount) {
          this.setState({
            skip: event.page.skip,
            take: event.page.take
          });
        }
    };

    render() {
        return (
            <div>
                <div className="main-card">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="main-heading">Resource Calendar Mapping Search</div>                       
                        </div>
                    
                        <div className="col-md-4 btn-align-right">
                            <Button primary={true} onClick={this.toggleAddNewDialog} disabled={!(window.USER_PERMISSION.indexOf('EDT_OS_RES') > -1)}>
                                Add New Resource Calendar Mapping
                            </Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <div className="d-block">
                                <label>Resource:</label>
                            </div>
                            <div className="d-block">
                                <MultiSelect
                                    name="selectedResource"
                                    textField="Name"
                                    value={this.state.selectedResource}
                                    data={this.state.resource}
                                    placeholder={'Please Select'}
                                    filterable={true}
                                    onFilterChange={this.filterChangeResource}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="d-block">
                                <label>Resource Country:</label>
                            </div>
                            <div className="d-block">
                                <MultiSelect
                                    name="selectedResourceCountry"
                                    textField="Name"
                                    value={this.state.selectedResourceCountry}
                                    data={this.state.country}
                                    placeholder={'Please Select'}
                                    filterable={true}
                                    onFilterChange={this.filterChangeCountry}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="d-block">
                                <label>Onsite Country:</label>
                            </div>
                            <div className="d-block">
                                <MultiSelect
                                    name="selectedOnsiteCountry"
                                    textField="Name"
                                    value={this.state.selectedOnsiteCountry}
                                    data={this.state.onsiteCountry}
                                    placeholder={'Please Select'}
                                    filterable={true}
                                    onFilterChange={this.filterOnsiteCountry}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="d-block">
                                <label>Start Date:</label>
                            </div>
                            <div className="d-block">
                                <DatePicker
                                    width="100%"
                                    value={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    name="startDate"
                                    format="MM/dd/yyyy"
                                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                />
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="d-block">
                                <label>End Date:</label>
                            </div>
                            <div className="d-block">
                                <DatePicker
                                    width="100%"
                                    value={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    name="endDate"
                                    min={new Date(this.state.startDate)}
                                    format="MM/dd/yyyy"
                                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                        <Button primary={true} type="submit" onClick={this.submitSearch}>
                            Search
                        </Button>
                        <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
                            Clear
                        </Button>
                        </div>
                    </div>

                    <div className="main-seperator"></div>

                    <div className="row">
                        <div className="col-md-12">
                        <div className="main-heading">Search Results</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Grid
                                data={orderBy(this.state.onsiteResources, this.state.sort).slice(
                                    this.state.skip,
                                    this.state.take + this.state.skip
                                )}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.onsiteResources.length}
                                pageable={true}
                                onPageChange={this.pageChange}
                                resizable
                                sortable={true}
                                sort={this.state.sort}
                                onSortChange={(e) => {
                                    this.setState({
                                        sort: e.sort
                                    });
                                 }
                                }
                                className="resource-onsite-result-grid"
                            >
                                <Column title="Resource" width="100px" field="Resource.Name" />
                                <Column title="Resource Country" width="150px" field="ResourceCountry.Name" />
                                <Column title="Onsite Country" width="100px" field="OnsiteCountry.Name" />
                                <Column title="Automatic / Manual" width="100px" field="AutomaticOrManual" />
                                <Column title="Project / Non-Project" width="100px" field="ProjectOrNonProject" />
                                <Column title="Project" width="150px"
                                cell={props => <ProjectTypeCell {...props} /> }/>
                                <Column title="Start Date" width="100px" field="StartDate" 
                                cell={props => <StartDateCell {...props} />} />
                                <Column title="End Date" width="100px" field="EndDate"
                                cell={props => <EndDateCell {...props} />}  />
                                
                                <Column title="Action" width="80px" cell={props => (
                                    
                                    <ResourceOnsiteActionCell
                                        {...props}
                                        OnEdit={this.onClickEdit}
                                        OnDelete={this.deleteOnsiteResourceId}
                                  />
                                )}
                                 /> 
                            </Grid>
                            {this.state.addnewdialog &&
                                <Dialog title={"Add / Edit Resource Calendar Mapping"} onClose={this.onClickNo} width={600}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="d-block">
                                                <label>Resource Name:</label>
                                            </div>
                                            <div className="d-block">
                                                <ComboBox
                                                    name="selectedUser"
                                                    textField="Name"
                                                    value={this.state.selectedUser}
                                                    data={this.state.userList}
                                                    placeholder={'Please Select'}
                                                    filterable={true}
                                                    onFilterChange={this.filterChangeUsers}
                                                    onChange={this.handleOnChangeUser}
                                                />
                                                {this.state.isErrorMessageVisible === true ? (
                                                    <span className={this.validateProperty(this.state.selectedUser)}>
                                                        Please select a Resource
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="d-block">
                                                <label>Resource Country:</label>
                                            </div>
                                            <div className="d-block">
                                                <Input
                                                    name="selectedAddResourceCountry"
                                                    value={this.state.selectedAddResourceCountry ? this.state.selectedAddResourceCountry.Name : ''}
                                                    placeholder={'Please Select'}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="d-block">
                                                <label>Onsite Country:</label>
                                            </div>
                                            <div className="d-block">
                                                <ComboBox
                                                    name="selectedAddOnsiteCountry"
                                                    textField="Name"
                                                    value={this.state.selectedAddOnsiteCountry}
                                                    data={this.state.onsiteCountry}
                                                    placeholder={'Please Select'}
                                                    filterable={true}
                                                    onFilterChange={this.filterOnsiteCountry}
                                                    onChange={this.handleInputChange}
                                                />
                                                {this.state.isErrorMessageVisible === true ? (
                                                    <span className={this.validateProperty(this.state.selectedAddOnsiteCountry)}>
                                                        Please select a Onsite Country
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-block">
                                                <label>Start Date:</label>
                                            </div>
                                            <div className="d-block">
                                                <DatePicker
                                                 width="100%"
                                                 value={this.state.addStartDate}
                                                 onChange={this.handleAddStartDateChange}
                                                 name="addStartDate"
                                                 format="MM/dd/yyyy"
                                                 min={new Date(this.state.minDate)}
                                                 max={this.state.addEndDate ? new Date(this.state.addEndDate): new Date('2199-12-31')}
                                                 formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                                />
                                                {this.state.isErrorMessageVisible === true ? (
                                                    <span className={this.validateProperty(this.state.addStartDate)}>
                                                        Please select a Start Date
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="d-block">
                                                <label>End Date:</label>
                                            </div>
                                            <div className="d-block">
                                                <DatePicker
                                                width="100%"
                                                value={this.state.addEndDate}
                                                onChange={this.handleAddEndDateChange}
                                                name="addEndDate"
                                                format="MM/dd/yyyy"
                                                min={this.state.addStartDate ? new Date(this.state.addStartDate): new Date(this.state.minDate)}
                                                formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                                />
                                                {this.state.isErrorMessageVisible === true ? (
                                                    <span className={this.validateProperty(this.state.addEndDate)}>
                                                        Please select a End Date
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-block">
                                                <label></label>
                                            </div>
                                            <label className="mr-3">Project Specific:</label>
                                            <input
                                                type="radio"
                                                name="Yes"
                                                value="Project"
                                                className="k-radio"
                                                id="YESRadio"
                                                onChange={this.handleOnChangeProjectSpec}
                                                checked={this.state.projectSpecific === 'Project'}
                                            />
                                            <label className="k-radio-label mr-1" htmlFor="YESRadio">
                                                Yes
                                            </label>

                                            <input
                                                type="radio"
                                                name="No"
                                                value="NonProject"
                                                className="k-radio ml-4"
                                                id="NORadio"
                                                onChange={this.handleOnChangeProjectSpec}
                                                checked={this.state.projectSpecific === 'NonProject'}
                                            />
                                            <label className="k-radio-label" htmlFor="NORadio">
                                                No
                                            </label>
                                            <br></br>
                                            {this.state.isErrorMessageVisible === true ? (
                                                    <span className={this.validateProperty(this.state.projectSpecific)}>
                                                        Please select a Project Specific
                                                    </span>
                                                ) : null}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="d-block">
                                                <label>Project:</label>
                                            </div>
                                            <div className="d-block">
                                                <ComboBox
                                                    name="selectedProjectOrCr"
                                                    textField="displayName"
                                                    dataItemKey="id"
                                                    value={this.state.selectedProjectOrCr}
                                                    data={this.state.projectsAndChangeRequests}
                                                    placeholder={'Please Select'}
                                                    filterable={true}
                                                    onChange={this.handleInputChange}
                                                    disabled={this.state.disableProjects}
                                                    onFilterChange={this.filterProjectOnChange}
                                                />
                                                {this.state.isErrorMessageVisible === true && this.state.projectSpecific === 'Project' ? (
                                                    <span className={this.validateProperty(this.state.selectedProjectOrCr)}>
                                                        Please select a Project 
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <DialogActionsBar>
                                        <button className="k-button" onClick={this.onClickNo}>Close</button>
                                        <button className="k-button modal-primary" onClick={this.handleSubmit}>Save</button>
                                    </DialogActionsBar>
                                </Dialog>}
                        </div>
                    </div>
                    <div>       
                        {this.state.showMessageDialog === true && (
                        <Dialog title={this.state.dialogTitle} onClose={this.onClickNo} width="400px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                            <DialogActionsBar>
                            <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                                OK
                            </button>
                            </DialogActionsBar>
                        </Dialog>
                        )}
                        {this.state.showOverlapingMessageDialog === true && (
                        <Dialog title={this.state.dialogTitle} onClose={this.onClickNo} width="400px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                            <DialogActionsBar>
                            <button className="k-button modal-primary" onClick={this.toggleOverlapingMessageDialog}>
                                OK
                            </button>
                            </DialogActionsBar>
                        </Dialog>
                        )}
                        {this.state.showConfirmDialog === true && (
                        <Dialog title="Confirm" onClose={this.toggleDeleteConfirmDialog} width="400px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                            <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleDeleteConfirmDialog}>
                                No
                            </button>
                            <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                Yes
                            </button>
                            </DialogActionsBar>
                        </Dialog>
                        )}
                    </div>
                    <div>
                    {this.state.visibleConfirmationDialog === true && (
                        <Dialog 
                            title={this.state.dialogTitle} 
                            onClose={this.toggleConfirmationDialog} 
                            width="400px">
                            
                            <p style={{ margin: "25px", textAlign: "center" }}>
                                {this.state.dialogMessage}
                            </p>
                            <DialogActionsBar>                               
                                <button 
                                    className="k-button" 
                                    onClick={this.toggleConfirmationDialog}>
                                    No
                                </button>
                                <button 
                                    className="k-button modal-primary"
                                    onClick={this.onCancelOk}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
                </div>
                <Loader loading={this.state.loading} />

            </div >
        );
    }
}

export default ResourceOnsiteDuration;
