import React, { Component } from "react";

class ApproverCell extends Component {
  render() {
    const nonProjectTask = this.props.dataItem;
    let approvers = '';
    if(Array.isArray(nonProjectTask.NonProjectTaskApprover)){
      const nptApprover = [];
      for (const nptApprover of nonProjectTask.NonProjectTaskApprover){
          let name = nptApprover.User.displayName;
          if (approvers == ''){
              approvers += name;
          }
          else{
              approvers += ', ' + name
          }
      }
      nonProjectTask.Approvers = approvers;
    } else {
      approvers = nonProjectTask.NonProjectTaskApprover;
    }
    return <td>{approvers || ''}</td>;
  }
}
export default ApproverCell;