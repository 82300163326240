import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class UpdatedDateCell extends Component {
    render() {
        if(this.props.dataItem.definitions.length > 0){
            const value = this.props.dataItem.definitions[0].updatedAt;
            return <td><Moment format="MM-DD-YYYY">
                {value}
            </Moment></td>;
        }else{
            return <td>
                {""}
            </td>;
        }
    }
}

export default UpdatedDateCell;