import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * getAllDocumentTypes - retrieves all DocumentTypes
 * @returns {array}  array of DocumentTypes
 */
export function getAllDocumentTypes() {
  const endPoint = apiUrl + '/masterData/doctype/doctypes/getalldoctypes';
  return http.get(endPoint);
}

/**
 * saveDocumentType - Save DocumentType
 * @returns {array}  array of saved details of DocumentType
 * @param docType
 */
export function saveDocumentType(docType) {
  const saveRegionEndPoint = apiUrl + '/masterData/doctype/doctypes/save';
  return http.post(saveRegionEndPoint, docType);
}

/**
 * updateDocumentType by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param documentType
 */
export function updateDocumentType(documentType) {
  const updateRegEndPoint = apiUrl + '/masterData/doctype/doctypes/update';
  return http.put(updateRegEndPoint, documentType);
}


/**
 * Update updateDocumentTypeStatus  by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param IsActive
 * @param docID
 */
export function updateDocumentTypeStatus(IsActive, docID) {
  const updateRegionEndpoint = apiUrl + '/masterData/doctype/doctypes/updateStatus/' + docID;
  return http.put(updateRegionEndpoint, IsActive);
}

/**
 * getAllDocumentTypesByName - retrieves all DocumentTypes by Name
 * @returns {array}  array of DocumentTypes
 */
export function getAllDocumentTypesByName(docName) {
  const endPoint = apiUrl + '/masterData/doctype/doctypes/getbyname/' + encodeURIComponent(docName);
  return http.get(endPoint);
}




/**
 * getActivityLogData - retrieves status change related document types' activitylog data
 * @returns {array}  array of activitylog data
 */
export function getActivityLogData(docName) {
  const endPoint = apiUrl + '/masterData/doctype/doctypes/getActivityLogData/' + encodeURIComponent(docName);
  return http.get(endPoint);
}


/**
 * search all DocumentTypes by given parameters.
 * @returns {array} array list of all DocumentTypes
 */
export function searchDocTypes(params) {
  const endPoint = apiUrl + '/masterData/doctype/doctypes/search';
  return http.post(endPoint, params);
}

/**
 * getAllDocumentTypesByName - retrieves all DocumentTypes by Status
 * @returns {array}  array of DocumentTypes
 */
export function getAllDocumentTypesByStatus(docStatus) {
  const endPoint = apiUrl + '/masterData/doctype/doctypes/getbystatus/' + docStatus;
  return http.get(endPoint);
}
