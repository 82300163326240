import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { orderBy } from '@progress/kendo-data-query';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';

import { Link } from 'react-router-dom';
import RegionActionsCell from './components/RegionActionsCell';
import StatusCell from './components/StatusCell';
import CountriesCell from './components/CountriesCell';
import CreatedDateCell from './components/CreatedDateCell';
import UpdatedDateCell from './components/UpdatedDateCell';

import { getRegionInfo, updateRegionStatus, searchRegions } from './RegionService';
import CostRegionCell from './components/CostRegionCell';
import SuccessImg from '../../ui/static/images/checked.svg';
import Loader from '../../integration/components/Loader';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../../src/integration/LoggerService";
import {LOG_TYPES} from "../../../src/integration/IntegrationEnums";

class Region extends Component {

  isMount = false;

  componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    this.setHeaderTitle();
    this.processSearchRegions();
  }

  constructor(props) {
    super(props);
    this.cancelSearch = this.cancelSearch.bind(this);

    this.state = {
      RegionResources: [],
      RegionCountryResources: [],
      RegionInfo: [],
      data: [],
      deactivatePopupVisible: false,
      activatePopupVisible: false,
      activateRegionID: 0,
      deactivateRegionID: 0,
      skip: 0,
      take: 20,
      sort: [{ field: 'updatedAt', dir: 'desc' }],

      searchStatus: { Name: 'Active', status: true },
      status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
      regionName : '',
      isCostRegion: false,
      loading: false
    };
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  retrieveRegionInfoList = () => {
    getRegionInfo()
      .then(res => {
        if (this.isMount) {
          this.setState({
            RegionInfo: res.data
          });
        }

      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Region');
    }
  };

  submitSearch = event => {
    event.preventDefault();
    this.processSearchRegions();
  };

  processSearchRegions  = () => {
    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    const fields = {};
    if (this.state.regionName) {
      fields.Name = this.state.regionName;
    }

    fields.CostRegion = this.state.isCostRegion;

    if (this.state.searchStatus) {
      fields.IsActive = this.state.searchStatus.status;
    }

    searchRegions(fields)
      .then(res => {
        this.setState({
          RegionInfo: res.data,
          loading: false,
          skip: 0
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  }

  
  handleOnChangeInput = event => {
    const field = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (this.isMount) {
      this.setState(prevState => {
        return {
          [field]: value
        };
      });
    }
  };

  cancelSearch = event => {
    if (this.isMount) {
      this.setState({
        regionName: '',
        searchStatus: null,
        isCostRegion: false,
      }, () => { this.processSearchRegions() });
    }
  };

  handleChange = (event) => {
    if (this.isMount) {
      this.setState({
        isCostRegion: event.value,
      });
    }
  };

  toggleChange = () => {
    this.setState({
      isCostRegion: !this.state.isCostRegion,
    });
  }

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Region Search</div>
          </div>
          <div className="col-md-4">
            <Button onClick={this.onClickCancel} primary={true} className="pull-right mb-2" disabled={!(window.USER_PERMISSION.indexOf('EDT_RGN') > -1)}>
              <Link
                style={{ textDecoration: 'none', color: 'black' }}
                to={{
                  pathname: '/admin/region/createregion',
                  action: 'create'
                }}
              >
                Create New Region
              </Link>
            </Button>
          </div>
        </div>
        <form onSubmit={this.submitSearch}>
          <div className="main-heading"/>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                  <label>Region Name:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <Input
                    value={this.state.regionName}
                    onChange={this.handleOnChangeInput}
                    name="regionName"
                    maxLength="100"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="d-block">
                <label className="new-label"></label>
              </div>
              <div className="d-block">
                <fieldset>
                  <label className="k-form-field">
                    <input
                      type="checkbox"
                      id="auth-1rps"
                      name="costRegion"
                      className="k-checkbox"
                      checked={this.state.isCostRegion}
                      onChange={this.toggleChange }/>
                    <label className="k-checkbox-label" htmlFor="auth-1rps">Cost Region</label>
                  </label>
                </fieldset>
              </div>
            </div>
        
            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Status:</label></div>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                    name="searchStatus"
                    textField="Name"
                    dataItemKey="status"
                    value={this.state.searchStatus}
                    data={this.state.status}
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}/>
                </div>
              </div>
            </div>
          </div>

                <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
                Clear
              </Button>
            </div>
            </div>
        </form>

        <div className="main-seperator"/>

        <div className="row">
          <div className="col-md-12">
            <div className="main-heading">Search Results</div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Grid 
                  data={orderBy(this.state.RegionInfo, this.state.sort).slice(
                    this.state.skip,
                    this.state.take + this.state.skip
                  )}
                  resizable
                  sortable={true}
                  sort={this.state.sort}
                  onSortChange={(e) => {
                    this.setState({
                      sort: e.sort
                    });
                  }
                  }
                  skip={this.state.skip}
                  take={this.state.take}
                  total={this.state.RegionInfo.length}
                  pageable={true}
                  onPageChange={this.pageChange}
                  className="search-result-grid"
            >
              <Column field="Name" title="Region" width="140px"/>
              <Column
                field=""
                title="Countries"
                width="110px"
                cell={props => <CountriesCell {...props} />}
              />
              <Column field="Currency.Code" title="Rate Card Currency"/>
              <Column field="CostRegion" title="Cost Region" width="120px"
                      cell={props => <CostRegionCell {...props} />}/>
              <Column
                field="createdAt"
                title="Created Date"
                width="140px"
                cell={props => <CreatedDateCell {...props} />}
              />
              <Column
                field="updatedAt"
                title="Last Modified Date"
                width="120px"
                cell={props => <UpdatedDateCell {...props} />}
              />
              <Column field="updatedUser" title="Last Modified User" width="120px"/>
              <Column field="IsActive" title="Status" width="100px" cell={props => <StatusCell {...props} />}/>
              <Column
                field=""
                width="150px"
                title="Actions"
                cell={props => (
                  <RegionActionsCell
                    {...props}
                    OnDeactivate={this.OnDeactivate}
                    OnActivate={this.OnActivate}
                  />
                )}
              />
            </Grid>
          </div>
        </div>

        <div>
          {this.state.deactivatePopupVisible && (
            <Dialog title={'Please Confirm'} onClose={this.deactivateConfirmationClose}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Are you sure you want to Deactivate this?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.deactivateConfirmationClose}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.deactivateConfirmationOk}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <div>
          {this.state.activatePopupVisible && (
            <Dialog title={'Please Confirm'} onClose={this.activateConfirmationClose}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Are you sure you want to Activate this?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.activateConfirmationClose}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.activateConfirmationOk}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.visible === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

              {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success"/>
                </div>
              )}

              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error"/>
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

              <DialogActionsBar>
                {this.state.showSuccessImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleDialog}>
                    OK
                  </button>
                )}

                {this.state.showErrorImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleDialog}>
                    OK
                  </button>
                )}
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <Loader loading={this.state.loading}/>
      </div>
    );
  }

  OnActivate = async (event, id) => {
    if (event) {
      event.preventDefault();
      if (this.isMount) {
        await this.setState({ activateRegionID: id });
      }
      await this.activateConfirmationClose();
    }
  };
  activateConfirmationClose = () => {
    if (this.isMount) {
      this.setState({
        activatePopupVisible: !this.state.activatePopupVisible
      });
    }
  };

  activateConfirmationOk = async () => {
    await updateRegionStatus({ IsActive: true }, this.state.activateRegionID)
      .then(response => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog('The Region has been Activated successfully', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.retrieveRegionInfoList();
    if (this.isMount) {
      await this.setState({
        activatePopupVisible: false
      });
    }
  };

  OnDeactivate = async (event, id) => {
    if (event) {
      event.preventDefault();
    }
    if (this.isMount) {
      await this.setState({
        deactivateRegionID: id
      });
    }
    this.deactivateConfirmationClose();
  };

  deactivateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: !this.state.deactivatePopupVisible
      });
    }
  };

  deactivateConfirmationOk = async () => {
    await updateRegionStatus({ IsActive: false }, this.state.deactivateRegionID)
      .then(response => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog('The Region has been Deactivated successfully', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.retrieveRegionInfoList();
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: false
      });
    }
  };


  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };
}

export default Region;
