import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import Button from '@progress/kendo-react-buttons/dist/npm/Button';
import { mapToApproverModel } from './MapApproverModel';

class SearchAction extends Component {
  constructor(props) {
    super(props);
    this.state = { popupVisible: false };
  }

  render() {
    const workflow = this.props;
    const { OnDeActivate } = this.props;
    const { OnActivate } = this.props;
    const { openApprovers } = this.props;
    const { onDelete } = this.props;
    const formattedApproverModel = mapToApproverModel(
      this.props.dataItem,
      this.props.ApproverDataAll
    );
    return (
      <td className="action-buttons">
        <Button
          type="button"
          primary={true}
          onClick={() => openApprovers(formattedApproverModel)}
          class="k-icon k-i-preview rounded-btn primary"
          title="View"
        >
        </Button>
        {/* {this.props.dataItem.IsActive === true && (
          <PermissibleRender
            userPermissions={window.USER_PERMISSION}
            requiredPermissions={['WF_CUS']}
          >
            <Link
              to={{
                pathname: '/admin/createworkflow/createworkflow',
                workflow: workflow,
                workflowId: this.props.dataItem.id,
                action: 'edit'
              }}
            >
              <div class="rounded-link">
                <span class="k-icon k-i-categorize" title="Change Approvers"/>
              </div>
            </Link>
          </PermissibleRender>
        )} */}
        {/* {this.props.dataItem.IsActive === true && (
          <PermissibleRender
            userPermissions={window.USER_PERMISSION}
            requiredPermissions={['WF_CUS']}
          >
            <Link
              to={{
                pathname: '/admin/createworkflow/createworkflow',
                workflow: workflow,
                workflowId: this.props.dataItem.id,
                action: 'WK_edit'
              }}
            >
            </Link>
          </PermissibleRender>
        )} */}
        {this.props.dataItem.IsActive === true && (
          <PermissibleRender
            userPermissions={window.USER_PERMISSION}
            requiredPermissions={['WF_CUS']}
          >
            <Link
              to={{
                pathname: '/admin/createworkflow/createworkflow',
                workflow: workflow,
                action: 'edit'
              }}
            >
              < div class='rounded-link'>
                <span class='k-icon k-i-edit' title='Edit'/>
              </div>
            </Link>
          </PermissibleRender>
        )}
        {this.props.dataItem.IsActive === true && (
          <PermissibleRender
            userPermissions={window.USER_PERMISSION}
            requiredPermissions={['WF_CUS']}
          >
            <Link
              to={{
                pathname: '/admin/createworkflow/createworkflow',
                workflow: workflow,
                action: 'duplicate'
              }}
            >
              {' '}
              <div class="rounded-link">
                <span class="k-icon k-i-backward-element" title="Duplicate"/>
              </div>
            </Link>
          </PermissibleRender>
        )}
        {this.props.dataItem.IsActive === true && (
          <PermissibleRender
            userPermissions={window.USER_PERMISSION}
            requiredPermissions={['WF_CUS']}
          >
            <Link to={this.props} onClick={event => OnDeActivate(event, this.props.dataItem.id)}>
              {' '}
              <div class="rounded-link">
                <span class="k-icon k-i-unsort" title="Deactivate"/>
              </div>
            </Link>
          </PermissibleRender>
        )}
        {this.props.dataItem.IsActive === false && (
          <PermissibleRender
            userPermissions={window.USER_PERMISSION}
            requiredPermissions={['WF_CUS']}
          >
            <Link to={this.props} onClick={event => OnActivate(event, this.props.dataItem.id)}>
              {' '}
              <div class="rounded-link">
                <span class="k-icon k-i-check" title="Activate"/>
              </div>
            </Link>
          </PermissibleRender>
        )}
        {this.props.dataItem.IsActive === false && (
          <PermissibleRender
            userPermissions={window.USER_PERMISSION}
            requiredPermissions={['WF_CUS']}
          >
            <Link to={this.props} onClick={event => onDelete(event, this.props.dataItem)}>
              <div className="rounded-link">
                <span className="k-icon k-i-delete" title="Delete"/>
              </div>
            </Link>
          </PermissibleRender>
        )}
      </td>
    );
  }
}

export default SearchAction;
