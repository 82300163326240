import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

class Timeline extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);
    }

    closeDialog = () => {
        this.props.closeMethod();
    };

    render() {
        return (
            <div>
                <Dialog title={'Timeline View'} onClose={this.closeDialog} width={1200} height={700}>
                    <div className="row">
                        <div className="col-md-12">
                            <Grid>
                                <Column field="ProductID" title="ID" width="40px" />
                                <Column field="ProductName" title="Name" width="250px" />
                                <Column field="Category.CategoryName" title="CategoryName" />
                                <Column field="UnitPrice" title="Price" width="80px" />
                                <Column field="UnitsInStock" title="In stock" width="80px" />
                            </Grid>
                        </div>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default Timeline;
