import moment from 'moment';

/**
 * Formats the date in YYYY-MM-DD format.
 * @param dateObj
 * @returns {string|null}
 */
export function getFormattedDate(dateObj) {
  if (dateObj) {
    const month = dateObj.getMonth() + 1;
    const date = dateObj.getDate();
    return (
      dateObj.getFullYear() +
      '-' +
      (month <= 9 ? '0' + month : month) +
      '-' +
      (date <= 9 ? '0' + date : date)
    );
  } else {
    return null;
  }
}

/**
 * ReStructure Date.
 * @param dateObj
 * @returns {string|null}
 */
 export function reStructureDate(date){
  if(date) {
    return new Date([moment(date).format('YYYY'), moment(date).format('M'), moment(date).format('D')].join('/'))
  } else {
    return date
  }
}

/**
 * Formats the date in YYYY-MM-DD format.
 * @param dateObj
 * @returns {string|null}
 */
export function getFormattedDateAndTime(dateObj) {
  if (dateObj) {
    const month = dateObj.getMonth() + 1;
    const date = dateObj.getDate();
    const hours = dateObj.getHours();
    const mins = dateObj.getMinutes();
    const secs = dateObj.getSeconds();

    return (
      dateObj.getFullYear() +
      '/' +
      (month <= 9 ? '0' + month : month) +
      '/' +
      (date <= 9 ? '0' + date : date) +
        ' ' +
      (hours <= 9 ? '0' + hours : hours) +
        ':' +
      (mins <= 9 ? '0' + mins : mins) +
        ':' +
      (secs <= 9 ? '0' + secs : secs)

    );
  } else {
    return null;
  }
}

/**
 * Checks if given date is a saturday or sunday.
 * @param date
 * @returns {boolean}
 */
export function isWeekend(date) {
  return date.getDay() % 6 === 0;
}

/**
 * Formats a decimal number with 2 decimal places.
 * @param number
 * @returns {string}
 */
export function formatNumber(number) {
  if (number) {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '0.00';
  }
}

/**
 * Formats a decimal number with given decimal places.
 * @param value
 * @param scale
 * @returns {string}
 */
export function roundNumber(value, scale) {
  if(!("" + value).includes("e")) {
    return +(Math.round(value + "e+" + scale)  + "e-" + scale);
  } else {
    let arr = ("" + value).split("e");
    let sig = "";
    if(+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}





