import http from './HttpService';
const apiUrl = require('./../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Save FrontEnd Logs.
 * @param auditTrail - (array)
 * @returns {array} frontEndLogs
 */
export function saveFrontEndLogs(type, title, message) {
  const log = {
    type: type,
    title: title,
    message: message
  }
  const endPoint = apiUrl + '/integration/frontEndLogs/save';
  return http.post(endPoint, log);
}
