import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import {
  DatePicker
} from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import {
  Button
} from '@progress/kendo-react-buttons';

import { saveRateCardIncrements, updateRateCard, saveRateCard } from './RateCardService'
import { getDesignationsIntegrated, getRegionsIntegrated, getMultiYearInformationIntegrated, getcustomersIntegrated } from '../../integration/MasterDataService';
import { getAllProjects } from '../../projectManagement/project/ProjectService';

import Loader from '../../integration/components/Loader';
import { Redirect } from 'react-router';
import 'moment-timezone';
import moment from 'moment/moment';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import $ from "jquery";
import {filterBy} from "@progress/kendo-data-query";

class RateCardEdit extends Component {


  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      isCreate: false,
      isDuplicate: false,
      regions: [],
      customers: [],
      projects: [],
      designations: [],
      loading: false,
      editID: null,
      isDisableSave: false,
      disableRegion: false,

      editingRateCardId: '',
      rateCardName: '',
      selectedRegion: null,
      selectedCustomer: null,
      isSelectedCrRate: false,
      isCrRateDisabled: true,
      selectedProject: null,
      startDate: null,
      endDate: null,

      visible: false,
      success: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',

      isFormDirty: false,
      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      minStartDate: null,

      goBack: false
    };
  }

  async componentDidMount() {

    $(document).ready(function () {
      $("div.title-remove > span > span > span:last-child").removeAttr("title");
    });

    this.isMount = true;
    const action = this.props.location.action;

    if (action === 'create') {
      this.populateRegions();
      this.populateDesignations();
      this.setState({
        isEdit: false,
        isCreate: true,
        isDuplicate: false,
        disableRegion: false
      });
    } else if (action === 'edit') {
      const rateCard = this.props.location.rateCard;
      var obj = {};
      if(rateCard.Project != null){
        obj = {
          'id': rateCard.Project.id,
          'ProjectName': rateCard.Project.ProjectName,
          'UniqueId': rateCard.Project.UniqueId,
          'OPID': rateCard.Project.OPID,
          'displayName': rateCard.Project.UniqueId +" - "+rateCard.Project.ProjectName,
        }
      }

      const tmpRoles = rateCard.RateCardRoles;
      const tmpDesignations = [];
      let roles = tmpRoles.filter(obj => obj.Role.IsActive === true);
      roles.forEach(function (designation) {
        const value = {};
        value.id = designation.id;
        value.Name = designation.Role.Name;
        value.pastRate = designation.Rate;
        value.newRate = designation.Rate;
        value.IsActive = designation.Role.IsActive;
        tmpDesignations.push(value);
      });
      tmpDesignations.sort((a, b) => (a.Name > b.Name ? 1 : -1));
      if (this.isMount) {
        this.setState({
          isEdit: true,
          isCreate: false,
          isDuplicate: false,
          disableRegion: true,
          designations: tmpDesignations,

          rateCardName: rateCard.Name,
          editingRateCardId: rateCard.id,
          selectedCustomer: rateCard.Customer,
          selectedRegion: rateCard.Region,
          selectedProject: obj,
          isCrRateDisabled: rateCard.ProjectId == null ? true : false,
          isSelectedCrRate: rateCard.IsProjectCr == null ? false : true,
          startDate: new Date(rateCard.StartDate),
          endDate: new Date(rateCard.EndDate)
        });
      }
    } else if (action === 'view') {
      const rateCard = this.props.location.rateCard;
      var obj = {};
      if(rateCard.Project != null){
        obj = {
          'id': rateCard.Project.id,
          'ProjectName': rateCard.Project.ProjectName,
          'UniqueId': rateCard.Project.UniqueId,
          'OPID': rateCard.Project.OPID,
          'displayName': rateCard.Project.UniqueId +" - "+rateCard.Project.ProjectName,
        }
      }

      const tmpRoles = rateCard.RateCardRoles;
      const tmpDesignations = [];
      let roles = tmpRoles.filter(obj => obj.Role.IsActive === true);
      roles.forEach(function (designation) {
        const value = {};
        value.id = designation.id;
        value.Name = designation.Role.Name;
        value.pastRate = designation.Rate;
        value.newRate = designation.Rate;
        value.IsActive = designation.Role.IsActive;
        tmpDesignations.push(value);
      });
      tmpDesignations.sort((a, b) => (a.Name > b.Name ? 1 : -1));
      if (this.isMount) {
        this.setState({
          isEdit: true,
          isCreate: false,
          isDuplicate: false,
          disableRegion: true,
          designations: tmpDesignations,

          rateCardName: rateCard.Name,
          editingRateCardId: rateCard.id,
          selectedCustomer: rateCard.Customer,
          selectedRegion: rateCard.Region,
          selectedProject: obj,
          isSelectedCrRate: rateCard.IsProjectCr == null ? false : true,
          startDate: new Date(rateCard.StartDate),
          endDate: new Date(rateCard.EndDate)
        });
      }

    } else if (action === 'duplicate') {
      this.populateRegions();

      const rateCard = this.props.location.rateCard;
      const tmpRoles = rateCard.RateCardRoles;
      const tmpDesignations = [];
      tmpRoles.forEach(function (designation) {
        if (designation.Role.IsActive === true) {
          const value = {};
          value.id = designation.Role.id;
          value.Name = designation.Role.Name;
          value.pastRate = designation.Rate;
          value.newRate = designation.Rate;
          value.IsActive = designation.Role.IsActive;
          tmpDesignations.push(value);
        }
      });

      tmpDesignations.sort((a, b) => (a.Name > b.Name ? 1 : -1));
      if (this.isMount) {
        this.setState({
          isEdit: false,
          isCreate: false,
          isDuplicate: true,
          disableRegion: false,
          designations: tmpDesignations
        });
      }
    }

    this.setHeaderTitle();
    this.populateCustomers();
    this.populateProjects();
    this.setMinStartDate();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setMinStartDate = async () => {
    let minDate = new Date();
    const currentYear = minDate.getFullYear();
    const currentMonth = minDate.getMonth() + 1;
    if (currentMonth == 1 || currentMonth == 2 || currentMonth == 3) { // check if the month is Jan/Feb/March
      let prevYear = (currentYear - 1).toString();
      minDate = prevYear + '-04-01';
    } else {
      minDate = currentYear + '-04-01';
    }
    minDate = new Date(moment(minDate).format("YYYY-MM-DD"));
    await this.setState({
      minStartDate: minDate
    });
  };

  populateRegions = async () => {
    getRegionsIntegrated().then(response => {
      if (this.isMount) {
        let allRegions = response.data;
        let activeRegions = [];
        activeRegions = allRegions.filter(function(approver){
          return approver.IsActive == true;
        });
        this.setState({
          regions: activeRegions
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateProjects = async () => {
    await getAllProjects().then(response => {
      this.allProjects = [];
      for (var item of response.data) {
        if(item.IsActive){
          var obj = {
            'id': item.id,
            'ProjectName': item.ProjectName,
            'UniqueId': item.UniqueId,
            'OPID': item.OPID,
            'displayName': item.UniqueId +" - "+item.ProjectName,
          }
          this.allProjects.push(obj);
        }
      }

      this.allProjects.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));

      if (this.isMount) {
        this.setState({
          projects: this.allProjects
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCustomers = async () => {
    await getcustomersIntegrated().then(response => {
      this.allCustomers = response.data;
      if (this.isMount) {
        this.setState({
          customers: response.data
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateDesignations = async () => {
    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    await getDesignationsIntegrated()
      .then(response => {
        const data = response.data;
        const tmp = [];
        for (var i = 0; i < response.data.length; i++) {
          if (data[i].IsProjectRole === true) {
            data[i].pastRate = 0;
            data[i].newRate = 0;
            tmp.push(data[i]);
          }
        }
        if (this.isMount) {
          this.setState({
            loading: false,
            designations: tmp
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  handleInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    if (this.isMount) {
      await this.setState({ [name]: value, isFormDirty: true });
    }
  };

  handleProjectChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    if (this.isMount) {
      await this.setState({ [name]: value, isFormDirty: true });

      if(this.state.selectedProject != null){
        this.setState({
          isCrRateDisabled:  false
        });
        
      } else {
        this.setState({
          isCrRateDisabled:  true,
          isSelectedCrRate: false
        });
      }

    }
  };

  setHeaderTitle = async () => {
    if (this.props.onHeaderTitleChange) {
      const action = this.props.location.action;
      if (action === 'create') {
        this.props.onHeaderTitleChange('Create Rate Card');
      } else if (action === 'view') {
        this.props.onHeaderTitleChange('View Rate Card');
      } else if (action === 'edit') {
        this.props.onHeaderTitleChange('Edit Rate Card');
      } else if (action === 'duplicate') {
        this.props.onHeaderTitleChange('Duplicate Rate Card');
      }
    }
  };

  handleStartDateChange = event => {
    let startDate = event.target.value;

    if (this.isMount) {
      this.setState({
        startDate: startDate,
        isFormDirty: true
      });
    }
  };
  handleEndDateChange = event => {
    let endDate = event.target.value;

    if (this.isMount) {
      this.setState({
        endDate: endDate,
        isFormDirty: true
      });
    }
  };

  rowClick = e => {
    if (e.dataItem.IsActive === true) {
      if (this.isMount) {
        this.setState({
          editID: e.dataItem.id
        });
      }
    }
  };

  itemChange = e => {
    const data = this.state.designations.slice();
    const index = data.findIndex(d => d.id === e.dataItem.id);
    let cellValue = 0;
    if (e.value != null && e.value > 0) {
      cellValue = e.value;
    }
    data[index] = { ...data[index], [e.field]: Number(cellValue.toFixed(2)) };
    if (this.isMount) {
      this.setState({
        designations: data,
        isFormDirty: true
      });
    }
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  handleSubmit = event => {
    event.preventDefault();

    if (!this.validation()) {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: true,
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else if (this.state.endDate.getTime() < this.state.startDate.getTime()) {
      if (this.isMount) {
        this.setState({
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleDialog('End date cannot be a date before the Start date', 'Error');
    } else {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: false
        });
      }
      if (this.state.isCreate || this.state.isDuplicate) {
        this.createRateCard(event);
      } else {
        this.updateRateCard(event);
      }
      if (this.isMount) {
        this.setState({
          isDisableSave: true
        });
      }
    }
  };

  createRateCard = event => {
    const designations = this.state.designations;
    const rateCardDesignations = [];

    designations.forEach(function (data) {
      const tmp = {
        RoleId: data.id,
        Rate: data.newRate
      };
      rateCardDesignations.push(tmp);
    });

    const rateCard = {
      Name: this.state.rateCardName.trim(),
      RegionId: this.state.selectedRegion.id,
      CustomerId: this.state.selectedCustomer ? this.state.selectedCustomer.id : null,
      StartDate: moment(this.state.startDate).format('YYYY-MM-DD'),
      EndDate: moment(this.state.endDate).format('YYYY-MM-DD'),
      RateCardRoles: rateCardDesignations,
      IsActive: true,
      updatedUser: window.LOGGED_USER.displayName,
      ProjectId: (this.state.selectedProject != null) ? this.state.selectedProject.id : null,
      IsProjectCr: this.state.isSelectedCrRate
    };

    saveRateCard(rateCard)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(`The rate card is created successfully.`, 'Success');
        let data = [];
        getMultiYearInformationIntegrated().then(response => {
          let yearRecords = response.data;
          if (yearRecords.length > 0) {
            for (const item of yearRecords) {
              if (item.id) {
                const rateData = {
                  MultiyearInfoGridId: item.id,
                  RateCardId: res.data.id,
                  Type: 'Rate Card'
                };
                data.push(rateData);
              }
            }
            saveRateCardIncrements(data);
          }
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      })

      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            isDisableSave: false,
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog('Error occurred while saving, please click on save again and proceed.', 'Error');
      });
  };

  updateRateCard = event => {
    const designations = this.state.designations;
    const rateCardDesignations = [];

    designations.forEach(function (data) {
      const tmp = {
        id: data.id,
        Rate: data.newRate
      };
      rateCardDesignations.push(tmp);
    });

    const rateCard = {
      id: this.state.editingRateCardId,
      Name: this.state.rateCardName.trim(),
      RegionId: this.state.selectedRegion.id,
      CustomerId: this.state.selectedCustomer ? this.state.selectedCustomer.id : null,
      StartDate: moment(this.state.startDate).format('YYYY-MM-DD'),
      EndDate: moment(this.state.endDate).format('YYYY-MM-DD'),
      RateCardRoles: rateCardDesignations,
      IsActive: true,
      updatedUser: window.LOGGED_USER.displayName,
      ProjectId: (this.state.selectedProject != null) ? this.state.selectedProject.id : null,
      IsProjectCr: this.state.isSelectedCrRate
    };

    updateRateCard(rateCard)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(`The rate card is updated successfully.`, 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            isDisableSave: false,
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog('Error occurred while saving, please click on save again and proceed.', 'Error');
      });
  };

  validation = () => {
    if (
      this.validateProperty(this.state.rateCardName)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.selectedRegion)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.startDate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.endDate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleCancelConfirmDialog = () => {
    if (this.isMount) {
      this.setState({
        showCancelConfirmation: !this.state.showCancelConfirmation
      });
    }
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };

  onClickClose = event => {
    if (this.state.isDisableSave) {
      this.navigateBack(event);
    } else {
      this.toggleDialog();
    }
  };

  navigateBack = event => {
    if (this.isMount) {
      this.setState({
        goBack: true
      });
    }
  };

  filterOnChange = event => {
    this.setState({
      customers: this.filterData(event.filter)
    });
  };

  filterData(filter) {
    const data = this.allCustomers.slice();
    return filterBy(data, filter);
  }

  filterProjectOnChange = event => {
    // var count = event.filter.value;
    // if(count != undefined && count.length > 4){
      this.setState({
        projects: this.filterProjectData(event.filter)
      });
    //}
  };

  filterProjectData(filter) {
    const data = this.allProjects.slice();
    return filterBy(data, filter);
  }

  onChange = (event) => {

    this.setState({
      isSelectedCrRate: event.target.checked
    });

  };

  render() {
    if (this.state.goBack === true) {
      return <Redirect to="/admin/ratecard/ratecard" />;
    }
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            {this.props.location.action === 'view' && (
                <div className="main-heading">View Rate Card</div>
            )}
            {this.props.location.action === 'edit' && (
              <div className="main-heading">Edit Rate Card</div>
            )}
            {this.props.location.action === 'duplicate' && (
              <div className="main-heading">Duplicate Rate Card</div>
            )}
            {this.props.location.action === 'create' && (
              <div className="main-heading">Create Rate Card</div>
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-2">
            <div className="d-block">
              <label className="mandatory">
                Rate Card Name:
                </label>
            </div>
            <div className="d-block">
              <div className="">
                <Input
                  value={this.state.rateCardName}
                  onChange={this.handleInputChange}
                  name="rateCardName"
                  autoComplete="off"
                  maxLength="100"
                  disabled={this.props.location.action === 'view'}
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.rateCardName)}>
                    Rate card name cannot be empty
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="d-block">
              <label className="mandatory">
                Region:
                </label>
            </div>
            <div className="d-block">
              <div className="">
                <ComboBox
                  name="selectedRegion"
                  textField="Name"
                  dataItemKey="id"
                  value={this.state.selectedRegion}
                  data={this.state.regions}
                  placeholder={'Select Region'}
                  disabled={this.state.disableRegion || this.props.location.action === 'view'}
                  onChange={this.handleInputChange}
                />

                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.selectedRegion)}>
                    Please select region
                  </span>
                ) : null}
              </div>
            </div>
          </div>


          <div className="col-md-2">
            <div className="d-block">
              <label className="optional">
                Customer:
                </label>
            </div>
            <div className="d-block">
              <div className="">
                <ComboBox
                  name="selectedCustomer"
                  textField="Name"
                  dataItemKey="id"
                  value={this.state.selectedCustomer}
                  data={this.state.customers}
                  placeholder={'Select Customer'}
                  onChange={this.handleInputChange}
                  disabled={this.props.location.action === 'view'}
                  filterable={true}
                  onFilterChange={this.filterOnChange}
                />
              </div>
            </div>
          </div>

          <div className="col-md-2">
            <div className="d-block">
              <label className="optional">
                Project:
                </label>
            </div>
            <div className="d-block">
              <div className="">
                <ComboBox
                  name="selectedProject"
                  textField="displayName"
                  dataItemKey="id"
                  value={this.state.selectedProject}
                  data={this.state.projects}
                  placeholder={'Select Project'}
                  onChange={this.handleProjectChange}
                  disabled={this.props.location.action === 'view'}
                  filterable={true}
                  onFilterChange={this.filterProjectOnChange}
                />
              </div>
            </div>
          </div>

          <div className="col-md-2 my-auto">
            <div className="d-block">
              <label></label>
            </div>
            <div className="d-block">
              <fieldset>
                  <label className="k-form-field">
                      <input type="checkbox" id="projectCrRate" className="k-checkbox" 
                        checked={this.state.isSelectedCrRate}
                        onChange={this.onChange}
                        disabled = {this.state.isCrRateDisabled}
                        />
                      <label className="k-checkbox-label" for="auth-1">CR Rate</label>
                  </label>
              </fieldset>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-md-2">
            <div className="d-block">
              <label className="mandatory">Validity Period: From: </label>
            </div>
            <div className="d-block">
              <div className="title-remove">
                <DatePicker
                  width="100"
                  name="startDate"
                  onChange={this.handleStartDateChange}
                  required={true}
                  value={this.state.startDate}
                  format="MM/dd/yyyy"
                  min={new Date(this.state.minStartDate)}
                  formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                  disabled={this.props.location.action === 'view'}
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.startDate)}>
                    Start date cannot be empty
                  </span>
                ) : null}
              </div>
            </div>
          </div>


          <div className="col-md-2">
            <div className="d-block">
              <label className="mandatory">To:</label>
            </div>
            <div className="d-block">
              <div className="title-remove">
                <DatePicker
                  width="100"
                  name="endDate"
                  onChange={this.handleEndDateChange}
                  required={true}
                  value={this.state.endDate}
                  format="MM/dd/yyyy"
                  min={new Date()}
                  formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                  disabled={this.props.location.action === 'view'}
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.endDate)}>
                    End date cannot be empty
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <br></br>

        <div className="row mt-0">
          <div className="col-md-12">
            <Grid
              skip={0}
              take={this.state.designations.length}
              total={this.state.designations.length}
              pageable={false}
              data={this.state.designations.map(item =>
                Object.assign(
                  {
                    inEdit: item.id === this.state.editID
                  },
                  item
                )
              )}
              editField="inEdit"
              onRowClick={this.rowClick}
              onItemChange={this.itemChange}
              resizable
            >
              <Column field="Name" title="Designation" editable={false} />
              <Column field="pastRate" title="Current Rate" editable={false} />
              {this.props.location.action !== 'view' &&
              <Column field="newRate" title="New Rate" editor="numeric"/>
              }
            </Grid>
          </div>
        </div>

        <div className="row">
            {this.props.location.action !== 'view' &&
            <div className="col-md-12 btn-align-right">
                <Button
                    primary={true}
                    disabled={this.state.isDisableSave}
                    type="submit"
                    onClick={this.handleSubmit}
                >
                    Save
                </Button>
                <Button type="button" onClick={this.onClickCancel}>
                    Cancel
                </Button>
            </div>
            }

            {this.props.location.action === 'view' &&
            <div className="col-md-12 btn-align-right">
                <Button type="button" onClick={this.onClickCancel}>
                    Close
                </Button>
            </div>
            }
        </div>
        <div>
          {this.state.visible === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.onClickClose} width="400px">

              {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success" />
                </div>
              )}

              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image" />
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

              <DialogActionsBar>
                {this.state.showSuccessImage === true && (
                  <button className="k-button modal-primary" onClick={this.navigateBack}>
                    OK
                  </button>
                )}

                {this.state.showErrorImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleDialog}>
                    OK
                  </button>
                )}
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <div>
          {this.state.showCancelConfirmation === true && (
            <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
              <p style={{ margin: '25px', textAlign: 'center' }}>
                This form contains unsaved changes. Do you want to close it?
              </p>
              <DialogActionsBar>
                <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.navigateBack}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default RateCardEdit;
