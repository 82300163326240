import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Redirect } from 'react-router';
import { Tooltip } from 'reactstrap';
import { savePractice, updatePractice } from './PracticeService';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

class CreatePractice extends Component {
  default;

  isMount = false;

  popupSet = {
    width: 'auto'
  };

  constructor(props) {
    super(props);
    this.state = {
      practiceNameToolTipOpen: false,
      practiceText: '',
      practiceCode: '',
      practiceId: null,
      practiceActive: false,
      visible: false,
      success: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      isEnableCreate: false,
      isEnableUpdate: false,
      isDisableCombo: false,
      isDisableEditMode: false,
      isDisableSave: false,
      isSaveButtonClicked: false
    };

    this.handleChangePracticeText = this.handleChangePracticeText.bind(this);
    this.handleChangePracticeCode = this.handleChangePracticeCode.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;

    if (this.props.location.action === 'edit') {
      this.updatePracticeDetails(this.props.location.practice);
    }
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  render() {
    if (this.state.toPractice === true) {
      return <Redirect to="/masterdata/practice/practice"/>;
    }
    return (
      <React.Fragment>
        <div className="main-card">
          <div className="main-heading">Practice/Department Details</div>
          <div className="main-seperator"/>
          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                  <label className="mandatory">Practice/Department Name:</label>
                </div>
                <div className="d-block">
                <div className="" id="practiceNameToolTip">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="practiceText"
                    required={true}
                    maxLength="100"
                    value={this.state.practiceText}
                    onChange={this.handleChangePracticeText}
                    disabled={this.state.isEditMode}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span
                      className={this.validateProperty(this.state.practiceText)}
                    >
                      Please enter a Practice/Department name
                    </span>
                  ) : null}
                  {this.state.practiceText ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.practiceNameToolTipOpen}
                      target="practiceNameToolTip"
                      toggle={this.togglePracticeNameToolTip}
                    >
                      {this.state.practiceText}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-2" id="practiceCodeToolTip">
              <div className="d-block">
                  <label className="mandatory">Practice/Department Code:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="practiceCode"
                    required={true}
                    maxLength="5"
                    value={this.state.practiceCode}
                    onChange={this.handleChangePracticeCode}
                    disabled={this.state.isEditMode}
                    style={{ textTransform: 'uppercase' }}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span
                      className={this.validateProperty(this.state.practiceCode)}
                    >
                      Please enter a Practice/Department code.
                    </span>
                  ) : null}
                  {this.state.isErrorMessageVisible === true ? (
                    <span
                      className={this.validatePropertyPracticeCode(
                        this.state.practiceCode
                      )}
                    >
                      Valid Practice/Department code should contain minimum 2 characters.
                    </span>
                  ) : null}
                  {this.state.practiceCode ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.practiceNameToolTipOpen}
                      target="practiceCodeToolTip"
                      toggle={this.togglePracticeNameToolTip}
                    >
                      {this.state.practiceCode}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 btn-align-right">
              <Button
                primary={true}
                disabled={this.state.isDisableSave}
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
              <Button onClick={this.onClickCancel}>Cancel</Button>
            </div>
          </div>
          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error Image"/>
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.navigateBack}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.showCancelConfirmation === true && (
              <Dialog
                title="Please Confirm"
                onClose={this.toggleCancelConfirmDialog}
                width="300px"
              >
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contains unsaved changes. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button
                    className="k-button active"
                    onClick={this.toggleCancelConfirmDialog}
                  >
                    No
                  </button>
                  <button
                    className="k-button modal-primary"
                    onClick={this.navigateBack}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error d-block';
    }
  };

  validatePropertyPracticeCode = value => {
    if (value && value.length >= 2) {
      return 'd-none';
    } else {
      return 'inline-error d-block';
    }
  };

  navigateBack = event => {
    if (this.isMount) {
      this.setState({
        toPractice: true
      });
    }
  };

  handleChangePracticeCode = async event => {
    if (this.isMount) {
      await this.setState({
        practiceCode: event.target.value,
        isFormDirty: true
      });
    }
    await this.validateProperty(event.target.value);
    await this.validatePropertyPracticeCode(event.target.value);
  };

  handleChangePracticeText = async event => {
    if (this.isMount) {
      await this.setState({
        practiceText: event.target.value,
        isFormDirty: true
      });
    }
    await this.validateProperty(event.target.value);
  };

  togglePracticeNameToolTip = () => {
    if (this.isMount) {
      this.setState({
        workflowNameToolTipOpen: !this.state.workflowNameToolTipOpen
      });
    }
  };

  updatePracticeDetails = practice => {
    if (this.isMount) {
      this.setState({
        isDisableEditMode: true
      });
    }
    if (practice) {
      let practiceStatus = false;

      if (practice.IsActive) {
        practiceStatus = true;
      }

      if (this.isMount) {
        this.setState({
          practiceText: practice.Name,
          practiceCode: practice.Code,
          practiceId: practice.id,
          practiceActive: practiceStatus
        });
      }
    } else {
      return null;
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    console.dir(event.target);
    if (!this.validation()) {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: true,
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleDialog(
        'Please fix the highlighted errors to continue',
        'Error'
      );
    } else {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: false
        });
      }
      if (this.props.location.action === 'edit') {
        this.updatePractice(event);
      } else {
        this.createPractice(event);
        if (this.isMount) {
          this.setState({
            isDisableSave: true
          });
        }
      }
    }
  };

  createPractice = event => {
    const practice = {
      Name: this.state.practiceText,
      Code: this.state.practiceCode,
      IsActive: true,
      updatedUser: window.LOGGED_USER.displayName
    };

    savePractice(practice)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(
          `The Practice/Department ${res.data.Name} is successfully created`,
          'Success'
        );
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog(error.response.data, 'Error');
        if (this.isMount) {
          this.setState({
            isDisableSave: false
          });
        }
      });
  };

  updatePractice = event => {
    const practice = {
      Name: this.state.practiceText,
      Code: this.state.practiceCode,
      IsActive: this.state.practiceActive,
      updatedUser: window.LOGGED_USER.displayName
    };

    updatePractice(practice, this.state.practiceId)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(
          'The Practice/Department has been successfully updated',
          'Success'
        );
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  validation = () => {
    if (
      this.validateProperty(this.state.practiceText)
        .toString()
        .includes('error')
    ) {
      return false;
    }
    if (
      this.validatePropertyPracticeCode(this.state.practiceCode)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.practiceCode)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleCancelConfirmDialog = () => {
    if (this.isMount) {
      this.setState({
        showCancelConfirmation: !this.state.showCancelConfirmation
      });
    }
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };
}

export default CreatePractice;
