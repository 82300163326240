import React, { Component } from "react";

class OtherExpenseActualCostActionCell extends Component {

  onClickEditActualExpenseCost = event => {
    event.preventDefault();
    this.props.onClickEditActualExpenseCost(this.props.dataItem);
  };

  onClickDeleteActualExpenseCost = event => {
    event.preventDefault();
    this.props.onClickDeleteActualExpenseCost(this.props.dataItem);
  };

  render() {
    return (
      <td className="action-buttons">
        {this.props.action && (this.props.action === 'create') && (this.props.dataItem.allowEditDelete) && (
          <a href="#" onClick={this.onClickEditActualExpenseCost}>
            <div className="rounded-link">
                <span className="k-icon k-i-edit" title="Edit" />
            </div>
        </a>
        )}
        {this.props.action && (this.props.action === 'create')&& (this.props.dataItem.allowEditDelete) && (
          <a href="#" onClick={this.onClickDeleteActualExpenseCost}>
            <div className="rounded-link">
                <span className="k-icon k-i-delete" title="Delete" />
            </div>
        </a>
        )}
      </td>
    );
  }

}

export default OtherExpenseActualCostActionCell;
