import React, { Component } from 'react';

class  UniqueIdCell extends Component{

    render() {
        const pendingTasks = this.props.dataItem;
        if (this.props.dataItem.Type === 'CMS_PENDING_APPROVAL') {
            
            if(pendingTasks.CMSheet && pendingTasks.CMSheet.Type && pendingTasks.CMSheet.Type === 'Project'){
                return <td>{pendingTasks.CMSheet.Project? pendingTasks.CMSheet.Project.UniqueId: ''}</td>
            }else {                
                return <td>{pendingTasks.CMSheet && pendingTasks.CMSheet.ChangeRequestUniqueId? pendingTasks.CMSheet.ChangeRequestUniqueId: ''}</td>
            }
        } else if (this.props.dataItem.Type === 'STATUS_CHANGE_APPROVAL') {
            if(pendingTasks.CMSheet && pendingTasks.CMSheet.Type === 'Project'){
                return <td>{pendingTasks.CMSheet.Project? pendingTasks.CMSheet.Project.UniqueId: ''}</td>
            }else{
                return <td>{pendingTasks.CMSheet && pendingTasks.CMSheet.ChangeRequestUniqueId? pendingTasks.CMSheet.ChangeRequestUniqueId: ''}</td>
            }
        }
        else
            if (this.props.dataItem.Type === 'RESOURCE_REQUEST_PENDING_APPROVAL' || this.props.dataItem.Type === 'RESOURCE_REQUEST_APPROVAL_WORKFLOW'|| this.props.dataItem.Type === 'RESOURCE_ALLOCATION_APPROVAL_WORKFLOW') {
            return <td>{pendingTasks.Project? pendingTasks.Project.UniqueId: ''}</td>
        }
        else
            if (this.props.dataItem.Type === 'TIMESHEET_UNFREEZE_APPROVAL') {
                if (pendingTasks.UnFreezeRequests && pendingTasks.UnFreezeRequests.RequestType === "TimeEntry"){
                    return <td>{pendingTasks.UnFreezeRequests && pendingTasks.UnFreezeRequests.EntryUser ? pendingTasks.UnFreezeRequests.EntryUser.displayName : ''}</td>
                }else {
                    return <td>{pendingTasks.UnFreezeRequests && pendingTasks.UnFreezeRequests.ApproverUser ? pendingTasks.UnFreezeRequests.ApproverUser.displayName : ''}</td>
                }          
        }
        else
        if (this.props.dataItem.Type === 'TIME_ENTRY_WEEKLY' || this.props.dataItem.Type === 'TIME_APPROVAL_WEEKLY' ||
        this.props.dataItem.Type === 'TIME_ENTRY_UNFREEZE_APPROVAL' ) {
            return <td>{'N/A'}</td>
        }
        else if (this.props.dataItem.Type === 'REVISE_CMS_PENDING_APPROVAL') {
            
            if(pendingTasks.CMSheet && pendingTasks.CMSheet.Type && pendingTasks.CMSheet.Type === 'Project'){
                return <td>{pendingTasks.CMSheet.Project? pendingTasks.CMSheet.Project.UniqueId: ''}</td>
            }else {                
                return <td>{pendingTasks.CMSheet && pendingTasks.CMSheet.ChangeRequestUniqueId? pendingTasks.CMSheet.ChangeRequestUniqueId: ''}</td>
            }
        }
        else if (this.props.dataItem.Type === 'INVOICE_REQUEST_PENDING_APPROVAL') {
            return <td>{pendingTasks.InvoiceUniqueId ? pendingTasks.InvoiceUniqueId : ''}</td>
        }  
        else {
                return <td>{''}</td>
            }
    }

}

export default UniqueIdCell;