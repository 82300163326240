import React, { Component } from 'react';
import { GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-react-dialogs';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { DateInput, Calendar, DatePicker, TimePicker, MultiViewCalendar, DateRangePicker } from '@progress/kendo-react-dateinputs';
import { saveUnfreezeWfSearchCriteria, getUnfreezeRequestUsersAndType, searchUnfreezeApprovals,
    getUnfreezeWfSearchCriteria, approveUnfreezeRequest, rejectUnfreezeRequest, getUnfreezeTimeEntryCalculations } from '../TimeSheetEntryService';
import * as loggerService from '../../../integration/LoggerService';
import { LOG_TYPES } from '../../../integration/IntegrationEnums';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import moment from 'moment';
import 'moment-timezone';
import Loader from '../../../integration/components/Loader';
import { isNull } from 'lodash';

class UnfreezeWorkFlow extends Component {
    ticked = [];

    isMount = false;

    constructor(props) {
        super(props);

        this.state = {
            selected: 0,
            unfreezeApprovals: [],
            user: [],
            userAll: [],
            requestType: [],
            requestTypeAll: [],
            startDate: null,
            endDate: null,
            status: 1,
            selectedUser: [],
            selectedRequestType: [],
            allGridData:[],
            allGridDataInitial: [],
            loading: false,
            savedSearchParams: [],
            gridDataFromSavedParams: [],
            showMessageDialog: false,
            showRejectCommentDialog: false,
            dialogMessage: '',
            dialogTitle: '',
            rejectComment:null,
            selectedResources: [],
            numSelectedLines: null,
            isPendingApproval: false,
            isApproved: false,
            isRejected: false,
            isActiveApprovedOrReject: false,
            action: null,
            skip: 0,
            take: 20,
            initialLoad: true,
            sort: [{ field: 'updatedAt', dir: 'desc'}],
            filter: ""
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.isMount = true;
        this.setHeaderTitle();
        this.setInitialData();
    }

    setInitialData = async () => {
        this.setState({
            loading: true
        });
        await this.populateUnfreezeUsersAndType();
        await this.populateSavedSearchCriterians();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Unfreeze Requests');
        }
    };

    populateUnfreezeUsersAndType = async () => {
        const fields = {};
        if (this.props.location.action === "approve"){
            fields.action = true;
            this.setState({
                action: 'approver',
            });
        } else {
            fields.action = false;
        }
        await getUnfreezeRequestUsersAndType(fields).then(res => {
            if (this.isMount) {
                this.setState({
                    user: res.data.Users,
                    userAll: res.data.Users,
                    requestType: res.data.RequestTypes,
                    requestTypeAll: res.data.RequestTypes
                });
            }
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    populateSavedSearchCriterians = async () => {
        await getUnfreezeWfSearchCriteria()
        .then(async res => {
            await this.setState({
                savedSearchParams: res.data
            });
            await this.setSavedSearchParams();
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    setSavedSearchParams = async () => {
        let savedSearchParams = this.state.savedSearchParams;
        let status = null;
        let users = [];
        let startDate = null;
        let endDate = null;
        let requestTypes = [];
        let currentUsers = this.state.user;

        if(savedSearchParams && savedSearchParams.length > 0){
            for(let el of savedSearchParams) {
                if(el.searchCriteria == 'Status'){
                    if (el.searchValue !== null) {
                        status = parseInt(el.searchValue);
                    }
                }
                if(el.searchCriteria == 'Users'){
                    const item = {
                        displayName: el.searchValue
                    };
                    users.push(item);
                }
                if(el.searchCriteria == 'StartDate'){
                    if (el.searchValue !== null) {
                        startDate = new Date(el.searchValue);
                    }
                }
                if(el.searchCriteria == 'EndDate'){
                    if (el.searchValue !== null) {
                        endDate = new Date(el.searchValue);
                    }
                }
                if(el.searchCriteria == 'RequestType'){
                    const item = {
                        RequestType: el.searchValue
                    };
                    requestTypes.push(item);
                }
            }

            if(status) {
                await this.setState({
                    status: status
                });
            }
            if(users && users.length > 0) {
                let currUsers = [];
                for(let record of users) {
                    currUsers = currentUsers.filter(el => el.displayName === record.displayName);
                }
                await this.setState({
                    selectedUser: currUsers
                });
            }
            if(startDate) {
                await this.setState({
                    startDate: startDate
                });
            }
            if(endDate) {
                await this.setState({
                    endDate: endDate
                });
            }
            if(requestTypes && requestTypes.length > 0) {
                await this.setState({
                    selectedRequestType: requestTypes
                });
            }
        }
        await this.submitSearch();
    };

    onClickApprove = event => {
        this.toggleConfirmDialog('Are you sure you want to approve the selected unfreeze request(s)?', this.approveConfirm);
    };

    onClickReject =  event => {
        this.toggleConfirmDialog('Are you sure you want to reject the selected unfreeze request(s)?', this.rejectConfirm);
   };

   approveConfirm = async event => {
    const approveObj = {
        requestLines: this.state.selectedResources ? this.state.selectedResources : [],
    };

    this.toggleConfirmDialog('', null);

    await approveUnfreezeRequest(approveObj)
        .then(res => {
            this.setState({
                selectedResources: [],
                savedSearchParams: [],
            });
            this.ticked = [];
            this.toggleMessageDialog('Successfully Approved', 'Success');
            this.populateUnfreezeUsersAndType();
            this.submitSearch();
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            // this.toggleMessageDialog(error.res.data, 'Error');
        });
    };

    rejectConfirm = async event => {
        const approveObj = {
            requestLines: this.state.selectedResources ? this.state.selectedResources : [],
            comment: this.state.rejectComment
        };

        this.toggleConfirmDialog('', null);

        await rejectUnfreezeRequest(approveObj)
            .then(res => {
                this.setState({
                    selectedResources: [],
                    savedSearchParams: [],
                });
                this.ticked = [];
                this.toggleMessageDialog(' Successfully Rejected', 'Success');
                this.populateUnfreezeUsersAndType();
                this.submitSearch();
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                // this.toggleMessageDialog(error.response.data, 'Error');
            });
    };

    setUnfreezeRequestDataToGrid = async () => {
        let item = null;
        let allGridData = [];
        let status =null;
        let statusName =null;

        if(this.state.unfreezeApprovals.count > 0){
            for(const obj of this.state.unfreezeApprovals.rows){
                let billableEffortHours;
                let nonBillableEffortHours;
                let unApprovedEffortHours;
                let approvers = [];
                
                if (obj.Approval && obj.Approval.length > 0) {
                    for(const approver of obj.Approval){
                        approvers.push(approver.Resource.Name)
                    }    
                }

                if (this.props.location.action === "approve"){
                    const loggedUser = obj.Approval.filter(resource => resource.Resource.Email.toUpperCase() === window.LOGGED_USER.userId.toUpperCase());
                    if (loggedUser[0].Status === "PENDING"){
                        status = 1;
                        statusName = "Pending Approvals";
                    } else if (loggedUser[0].Status === "APPROVED") {
                        status = 2;
                        statusName = "Approved";
                    } else {
                        status = 3;
                        statusName = "Rejected";
                    }
                }
                else {
                    if (obj.Status === "Pending_Approval"){
                        status = 1;
                        statusName = "Pending Approvals";
                    } else if (obj.Status === "Approved") {
                        status = 2;
                        statusName = "Approved";
                    } else {
                        status = 3;
                        statusName = "Rejected";
                    }
                }

                const unfreezeDates = obj.UnfreezeRequestDate;
                let unfreezeDatesArr = [];

                // get UnFreezeRequest Dates
                if (unfreezeDates && unfreezeDates.length > 0) {
                  unfreezeDates.forEach(value => { 
                    let date = new Date(value.UnfreezeDate);
                    unfreezeDatesArr.push(date);
                  });
                }
                unfreezeDatesArr.sort(function(a,b){
                    return new Date(a) - new Date(b);
                });

                let maxDate=moment(new Date(Math.max.apply(null,unfreezeDatesArr))).format("MM-DD-YYYY");
                let minDate=moment(new Date(Math.min.apply(null,unfreezeDatesArr))).format("MM-DD-YYYY");
                let dateRange = "";
                let requestType = "";
                let userName = "";
                let userId;

                if (maxDate === minDate){
                    dateRange = maxDate;
                } else {
                    let onSequence;
                    for (let i=0; i < unfreezeDatesArr.length - 1; i++) {
                        let loopDate = new Date(unfreezeDatesArr[i]);
                        let nextDate = new Date(loopDate.setDate(loopDate.getDate() + 1));

                        let loopNextDate = new Date(unfreezeDatesArr[i+1]);
                        if (nextDate.toDateString() !== loopNextDate.toDateString()) {
                            onSequence = false;
                            break;
                        } else {
                            onSequence = true
                        }
                    }
                    if (onSequence === true) {
                        dateRange = minDate + " - " + maxDate;
                    }
                    else {
                        let dates = [];
                        for (let item of unfreezeDatesArr) {
                            let date = moment(new Date(item)).format("MM-DD-YYYY");
                            dates.push(date);
                        }
                        dateRange = dates.join(", ");
                    }
                }

                if (obj.RequestType === "TimeEntry") {
                    requestType = "Time Entry";
                    userName = obj.EntryUser.displayName;
                    userId = obj.EntryUserId;
                } else {
                    requestType = "Time Approval";
                    userName = obj.ApproverUser.displayName;
                    userId = obj.ApproverUserId;
                }

                const unFreezeRequestId = obj.id;

                await getUnfreezeTimeEntryCalculations(userId, unFreezeRequestId, obj.RequestType).then(res => {
                    const calculations = res.data;
                    billableEffortHours = Number(calculations.BillableEffortHours.toFixed(2));
                    nonBillableEffortHours = Number(calculations.NonBillableEffortHours.toFixed(2));
                    unApprovedEffortHours = Number(calculations.UnApprovedEffortHours.toFixed(2));
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                    // this.toggleMessageDialog(error.response.data, 'Error');
                });


                item = {
                    id: obj.id,
                    UnFreezeRequestId: obj.id,
                    UnFreezeRequest: obj,
                    status: status,
                    user: userName,
                    dateRange: dateRange,
                    startDate: minDate,
                    endDate: maxDate,
                    requestType: obj.RequestType,
                    RequestType: requestType,
                    reason: obj.Comment,
                    billableEffortHours: billableEffortHours,
                    nonBillableEffortHours: nonBillableEffortHours,
                    unApprovedEffortHours: unApprovedEffortHours,
                    statusName: statusName,
                    updatedAt: obj.updatedAt,
                    createdAt: moment(new Date(obj.createdAt)).format("MM-DD-YYYY hh:mm:ss A"),
                    approvers: approvers.join(", ")
                }
                allGridData.push(item)

            }
        }

        await this.setState({
            allGridData: allGridData,
            allGridDataInitial: allGridData,
            gridDataFromSavedParams: allGridData,
        });

        await this.setState({
            loading: false
        });
    }

    handleSelect = (e) => {
        this.setState({ selected: e.selected });
    }
    
    handleInputChange = async event => {
        const name = event.target.name;
        const value = event.target.value;
        await this.setState({
             [name]: value, 
             isFormDirty: true
        });
    };

    handleOnChangePendingStatus = async event => {
        this.setState({
            isPendingApproval: !this.state.isPendingApproval,
            isApproved: false,
            isRejected: false,
          })
          if (this.state.isPendingApproval !== true){
              this.state.status = 1
          } else {
            this.state.status = null
          }
    };

    handleOnChangeApprovedStatus = async event => {
        this.setState({
            isApproved: !this.state.isApproved,
            isPendingApproval: false,
            isRejected: false,
          })
          if (this.state.isApproved !== true){
              this.state.status = 2
          } else {
            this.state.status = null
          }
    };

    handleOnChangeRejectedStatus = async event => {
        this.setState({
            isRejected: !this.state.isRejected,
            isPendingApproval: false,
            isApproved: false,
          })
          if (this.state.isRejected !== true){
              this.state.status = 3
          } else {
            this.state.status = null
          }
    };

    handleStartDateChange = event => {
        let startDate = event.target.value;
        if (this.isMount) {
            this.setState({
                startDate: startDate,
                isFormDirty: true,
            });
        }
    };

    handleEndDateChange = event => {
        let endDate = event.target.value;
        if (this.isMount) {
            this.setState({
                endDate: endDate,
                isFormDirty: true,
            });
        }
    };

    filterDataUser(filter) {
        const data = this.state.userAll.slice();
        return filterBy(data, filter);
    }
    
    filterChangeUser = event => {
        if (this.isMount) {
            this.setState({
                user: this.filterDataUser(event.filter),
                filter: event.filter.value
            });
        }
    };

    onBlur = () => {
        if (this.state.filter) {
          this.setState({
            user: this.state.userAll.slice(),
            filter: ""
          });
        }
    };

    filterDataRequestType(filter) {
        const data = this.state.requestTypeAll.slice();
        return filterBy(data, filter);
    }
    
    filterChangeRequestType = event => {
        if (this.isMount) {
            this.setState({
                requestType: this.filterDataRequestType(event.filter)
            });
        }
    };

    toggleMessageDialog = (message, title) => {
        this.setState({
            showMessageDialog: !this.state.showMessageDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    toggleConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    toggleRejectCommentDialog = (title) => {
        this.setState({
            showRejectCommentDialog: !this.state.showRejectCommentDialog,
            dialogTitle: title
        });
    };

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        },
        () => {
            this.submitSearch(null);
        });
    };

    handleRejectReasonInputChange = event => {
        const value = event.target.value;
        this.setState({
            rejectComment: value
        });
    };

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        this.state.allGridData.forEach(item => item.Selected = checked);
        this.forceUpdate();

        const selected = this.state.allGridData.filter(resource => resource.Selected === true);
        const checkStatus = selected.some(val => val.status === 2 || val.status === 3);
        if (checkStatus === true) {
            this.setState({
                isActiveApprovedOrReject: false
            });
        } else {
            this.setState({
                isActiveApprovedOrReject: true
            });
        }
        this.setState({
            selectedResources: selected
        });
    };

    selectionChange = async (event) => {
        event.dataItem.Selected = !event.dataItem.Selected;

        const index = this.state.allGridData.indexOf(event.dataItem);
        if (event.dataItem.Selected) {
            this.ticked.push(index);
        } else {
            this.ticked.splice(this.ticked.indexOf(index), 1);
        }

        this.forceUpdate();

        const selected = await this.state.allGridData.filter(resource => resource.Selected === true);
        const checkStatus = selected.some(val => val.status === 2 || val.status === 3);
        if (checkStatus === true) {
            this.setState({
                isActiveApprovedOrReject: false
            });
        } else {
            this.setState({
                isActiveApprovedOrReject: true
            });
        }
        await this.setState({
            selectedResources: selected,
            numSelectedLines: this.ticked.length
        });
    };

    saveSearchCriteria = async event => {
        // event.preventDefault();

        let users = this.state.selectedUser;
        let requestType = this.state.selectedRequestType;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let status = this.state.status;

        const searchCriteriasObj = {
            users: (users && users.length > 0) ? users : '',
            requestType: (requestType && requestType.length > 0) ? requestType : '',
            startDate: startDate ? startDate : null,
            endDate: endDate ? endDate : null,
            status: status ? status : null,
        };

        await saveUnfreezeWfSearchCriteria(searchCriteriasObj).then(response => {
            this.setState({
                showSuccessImg: true
            });
            this.toggleMessageDialog('Selected search criterians are successfully saved.', 'Success');
        }).catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    submitSearch = event => {
        if (event) {
            event.preventDefault();
            this.setState({
              skip: 0,
              take: 20,
              sort: [{ field: 'updatedAt', dir: 'desc' }],
            });
          }

        if (this.isMount) {
            this.setState({
                loading: true
            });
        }

        if (this.state.status === 1){
            this.state.isPendingApproval = true
        } else if (this.state.status === 2){
            this.state.isApproved = true
        } else if (this.state.status === 3){
            this.state.isRejected = true
        }

        const fields = {};

        if (this.state.selectedUser.length > 0) {
            fields.UserId = this.state.selectedUser.map(value => value.id);
        }

        if (this.state.status) {
            fields.Status = this.state.status;
        }

        if (this.state.selectedRequestType.length > 0) {
            fields.RequestType = this.state.selectedRequestType.map(value => value.RequestType);
        }

        fields.StartDate = this.state.startDate ? moment(this.state.startDate).format("MM-DD-YYYY") : null;

        fields.EndDate = this.state.endDate ? moment(this.state.endDate).format("MM-DD-YYYY") : null;

        fields.skip = event ? 0 : this.state.skip;
        fields.take = event ? 20 : this.state.take;

        if (this.props.location.action === "approve"){
            fields.action = true;
            this.setState({
                action: 'approver',
            });
        } else {
            fields.action = false;
        }

        searchUnfreezeApprovals(fields)
            .then(res => {
            if (this.isMount) {
                this.setState({
                    unfreezeApprovals: res.data,
                    savedSearchParams: [],
                    selectedResources:[],
                    initialLoad: false
                },
                () => {
                    this.setUnfreezeRequestDataToGrid();
                });
            }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    clearFilters = event => {
        event.preventDefault();
        if (this.isMount) {
            this.setState({
                selectedUser: [],
                selectedRequestType: [],
                startDate: null,
                endDate: null,
                status: null,
                savedSearchParams: [],
                isFormDirty: false,
                selectedResources:[],
                isPendingApproval: false,
                isApproved: false,
                isRejected: false,
                skip: 0,
                take: 20
            },() => {
                this.submitSearch(null);
            });
        }
        this.populateUnfreezeUsersAndType();
    };

    render() {

        return (
            <div className="main-card">
                <div className="row">
                    <div className="col-md-12">
                        <PanelBar className="filter-panel">
                            <PanelBarItem title={<div className="filter-timesheet">Unfreeze Request Search</div>} expanded={true} animation={true}>
                                    <div className="row mt-2">
                                        <div className="col-md-2">
                                            <form className="k-form modified">
                                                <div className="k-form-field">
                                                    <label className="px-1">Status</label>
                                                    <div className="row my-auto">
                                                        <div className="col-md-12">
                                                            <div className="d-block px-1">
                                                                <input
                                                                    type="checkbox"
                                                                    name="pending approvals"
                                                                    value={this.state.isPendingApproval}
                                                                    className="k-checkbox"
                                                                    checked={this.state.isPendingApproval}
                                                                    id="pastatus"
                                                                    onChange={this.handleOnChangePendingStatus}
                                                               />
                                                                <label className="k-checkbox-label font-weight-bold" htmlFor="pastatus">
                                                                    Pending Approvals
                                                                </label>
                                                            </div>
                                                            <div className="d-block px-1 py-1">
                                                                <input
                                                                    type="checkbox"
                                                                    name="Approved"
                                                                    value="2"
                                                                    className="k-checkbox"
                                                                    checked={this.state.isApproved}
                                                                    id="approved"
                                                                    onChange={this.handleOnChangeApprovedStatus}
                                                                />
                                                                <label className="k-checkbox-label font-weight-bold" htmlFor="approved">
                                                                    Approved
                                                                </label>
                                                            </div>
                                                            <div className="d-block px-1 py-1">
                                                                <input
                                                                    type="checkbox"
                                                                    name="Rejected"
                                                                    value="3"
                                                                    className="k-checkbox"
                                                                    checked={this.state.isRejected}
                                                                    id="rejected"
                                                                    onChange={this.handleOnChangeRejectedStatus}
                                                                />
                                                                <label className="k-checkbox-label font-weight-bold" htmlFor="rejected">
                                                                    Rejected
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label className="">User</label>
                                                    <MultiSelect
                                                        name="selectedUser"
                                                        textField="displayName"
                                                        value={this.state.selectedUser}
                                                        data={this.state.user}
                                                        placeholder={'Please Select'}
                                                        filterable={true}
                                                        onFilterChange={this.filterChangeUser}
                                                        onChange={this.handleInputChange}
                                                        onBlur={this.onBlur}
                                                        filter={this.state.filter}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="">Start Date</label>
                                                    <DatePicker
                                                        width="100%"
                                                        value={this.state.startDate}
                                                        onChange={this.handleStartDateChange}
                                                        name="startDate"
                                                        format="MM/dd/yyyy"
                                                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                                        max={this.state.endDate ? new Date(this.state.endDate): new Date('2199-12-31')}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="">End Date</label>
                                                    <DatePicker
                                                        width="100%"
                                                        value={this.state.endDate}
                                                        onChange={this.handleEndDateChange}
                                                        name="endDate"
                                                        min={new Date(this.state.startDate)}
                                                        format="MM/dd/yyyy"
                                                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <label className="">Request Type</label>
                                                    <MultiSelect 
                                                        name="selectedRequestType"
                                                        textField="RequestType"
                                                        value={this.state.selectedRequestType}
                                                        data={this.state.requestType}
                                                        placeholder={'Please Select'}
                                                        filterable={true}
                                                        onFilterChange={this.filterChangeRequestType}
                                                        onChange={this.handleInputChange}
                                                     />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <div className="row">
                                    <div className="col-md-12 btn-align-right">
                                        <Button primary={true} className="mb-3" type="button" onClick={() => this.saveSearchCriteria()}>Save</Button>
                                        <Button primary={true} className="mb-3" type="submit" onClick={this.submitSearch}>Search</Button>
                                        <Button type="reset" className="mb-3" onClick={this.clearFilters}>Clear</Button>
                                    </div>
                                </div>
                            </PanelBarItem>
                        </PanelBar>
                    </div>
                </div>
                <div className="main-seperator" />

                <div className="row">
                    <div className="col-md-12">
                        <div className="main-heading">Search Results</div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Grid
                            data={this.state.allGridData}
                            skip={this.state.skip}
                            take={this.state.take}
                            total={this.state.unfreezeApprovals.count}
                            resizable
                            pageable={true}
                            onPageChange={this.pageChange}
                            sortable={true}
                            sort={this.state.sort}
                            onSortChange={(e) => {
                                this.setState({
                                    sort: e.sort
                                });
                            }}
                            selectedField="Selected"
                            onHeaderSelectionChange={this.headerSelectionChange}
                            onSelectionChange={this.selectionChange}
                            className="search-result-grid"
                        >
                            {this.state.action === 'approver' ?
                            <Column
                                field="Selected"
                                width="50px"
                            /> : null }
                            <Column title="User" field="user" width="150px" />
                            <Column title="Date Range" field="dateRange" width="150px" />
                            <Column title="Request Type" field="RequestType" width="150px" />
                            <Column title="Requested Date" field="createdAt" width="150px" />
                            <Column title="Billable Hours" field="billableEffortHours" width="150px" />
                            <Column title="Non-Billable Hours" field="nonBillableEffortHours" width="150px" />
                            <Column title="Unapproved Hours" field="unApprovedEffortHours" width="150px" />
                            <Column title="Reasons for Unfreeze" field="reason" width="150px" />
                            <Column title="Approver(s)" field="approvers" width="150px" />
                            <Column title="Status" field="statusName" width="200px" />
                        </Grid>
                    </div>
                </div>

                {this.state.action === 'approver' ?
                <div className="row my-2">
                    <div className="col-md-12 btn-align-right">
                        <Button
                            primary={true}
                            type="submit"
                            disabled={!this.state.isActiveApprovedOrReject || this.state.selectedResources.length <= 0 || this.state.action !== 'approver'}
                            onClick={this.onClickApprove}
                        >
                            Approve
                        </Button>
                        <Button
                            type="reset"
                            disabled={!this.state.isActiveApprovedOrReject || this.state.selectedResources.length <= 0 || this.state.action !== 'approver'}
                            onClick={this.onClickReject}
                        >
                            Reject
                        </Button>
                    </div>
                </div> : null }

                {this.state.showMessageDialog == true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
                      <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                      <DialogActionsBar>
                        <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                          OK
                        </button>
                      </DialogActionsBar>
                    </Dialog>
                )}

                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleConfirmDialog}>
                                No
                            </button>
                            <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                {this.state.showRejectCommentDialog == true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleRejectCommentDialog} width="400px">
                        <textarea className="k-textarea" rows="5" cols="50" placeholder="Type Comment"
                                  onChange={this.handleRejectReasonInputChange}
                                  value={this.state.rejectComment}
                        ></textarea>
                        <DialogActionsBar>
                                <button className="k-button modal-primary" onClick={this.rejectConfirm}>
                                    OK
                                </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                <Loader loading={this.state.loading} />
            </div>
        )
    }


}

export default UnfreezeWorkFlow;
