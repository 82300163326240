import React, { Component } from 'react';
import '../src/Spark.css';
import NetworkDetector from './integration/NetworkDetector';
import '@progress/kendo-theme-bootstrap/dist/all.css';
import { Switch, Route } from 'react-router';
import Layout from './ui/layout/Layout';
import CreateProject from './projectManagement/project/Create';
import Dashboard from './ui/dashboard/Home';
import CMSheet from './projectManagement/cmSheet/CMSheet';
import WorkflowCreate from './projectManagement/workflow/CreateWorkflow';
import Workflow from './projectManagement/workflow/Workflow';
import ProjectSearch from './projectManagement/search/Search';
import { getLoggedInUser, getToken, refreshToken } from './userManagement/LoginService';
import AllocatePresales from './resourceManagement/AllocatePresales';
import RateCard from './masterData/rateCard/RateCard';
import loader from './ui/static/images/loader.svg';
import Designation from './masterData/designation/Designation';
import DesignationGroup from './masterData/designationGroup/DesignationGroup';
import Commissions from './masterData/commission/Commissions';
import DesignationCreate from './masterData/designation/CreateDesignation';
import ExchangeRate from './masterData/exchangeRate/ExchangeRate';
import Practice from './masterData/practice/Practice';
import PracticeSF from './masterData/practice/PracticeSF';
import MultiyearIncrementInfo from './masterData/multiyearIncrement/MultiyearIncrementInfo';
import CreateMultiyearIncrement from './masterData/multiyearIncrement/CreateMultiyearIncrement';
import Region from './masterData/region/Region';
import CreateEditRegion from './masterData/region/CreateEditRegion';
import PracticeCreate from './masterData/practice/CreatePractice';
import CalendarSearch from './masterData/calendar/CalendarSearch';
import CalendarCreate from './masterData/calendar/CalendarCreate';
import DesignationGroupCreate from './masterData/designationGroup/CreateDesignationGroup';
import SalesPersonCreate from './masterData/commission/CreateSalesPerson';
import PartnerCreate from './masterData/commission/CreatePartner';
import Help from './integration/help/Help';
import OfferingsSearch from './masterData/offering/SearchOffering';
import CreateOffering from './masterData/offering/CreateOffering';
import EngagementType from './masterData/engagementType/EngagementType';
import EngagementTypeCreate from './masterData/engagementType/CreateEngagementType';
import EditCustomer from './masterData/customer/EditCustomer';
import SearchCustomer from './masterData/customer/SearchCustomer';
import ExchangeRateCreate from './masterData/exchangeRate/CreateExchangeRate';
import UserManagement from './userManagement/user/UserManagement';
import UserCreate from './userManagement/user/CreateUser';
import RoleManagement from './userManagement/role/RoleManagement';
import RoleCreate from './userManagement/role/CreateRole';
import CostCardSearch from './masterData/costCard/CostCardSearch';
import CreateCostCard from './masterData/costCard/CreateCostCard';
import EditCostCard from './masterData/costCard/EditCostCard';
import RateCardEdit from './masterData/rateCard/RateCardEdit';
import ApproverTypeCreate from './masterData/approverType/ApproverTypeCreate';
import ApproverTypeSearch from './masterData/approverType/ApproverTypeSearch';
import NotFoundImage from './ui/static/images/404-page-04.png';
import ReactGA from 'react-ga';
import { AzureAD, MsalAuthProviderFactory, LoginType } from 'react-aad-msal';
import AuditTrailSearch from './integration/auditTrail/AuditTrail';
import { saveAuditTrail } from './integration/auditTrail/AuditTrailService';
import EditChangeRequest from './projectManagement/changeRequest/EditChangeRequest';
import ConsolidatedCMSheet from './projectManagement/consolidatedCMSheet/ConsolidatedCMSheet';
import * as loggerService from './integration/LoggerService';
import { LOG_TYPES } from './integration/IntegrationEnums';
import EmployeeUserProfile from './resourceManagement/EmplyeeUserProfile';
import SkillSearch from './resourceManagement/SkillSearch';
import EvaluateSkills from './resourceManagement/EvaluateSkills';
import ResourceProfileSearch from './resourceManagement/ResourceProfileSearch';
import ProjectResourceRequest from './resourceManagement/ProjectResourceRequest';
import ProjectResourceAllocation from './resourceManagement/ProjectResourceAllocation';
import WorkflowCriteriaDefinitionSearch from './masterData/workflowCriteriaDefinition/WorkflowCriteriaDefinitionSearch';
import WorkflowCriteriaDefinitionCreate from './masterData/workflowCriteriaDefinition/WorkflowCriteriaDefinitionCreate';
import WorkflowConfigurationSearch from './Workflow/WorkflowConfigurationSearch/WorkflowConfigurationSearch';
import ActiveStatus from './activestatus/activestatus';
import ResourceRequestActivation from './resourceManagement/resourceRequest/ResourceRequestActivation';
import DocumentTypeSearch from './masterData/documentType/DocumentTypeSearch';
import DocumentTypeDetails from './masterData/documentType/DocumentTypeDetails';
import HRDesignationSearch from './masterData/hrDesignation/HRDesignationSearch';
import CreateHRDesignation from './masterData/hrDesignation/CreateHRDesignation';
import NonProjectTaskSearch from './Timesheets/nonProjectTaskSearch/NonProjectTaskSearch';
import FactoryMain from './projectManagement/poolProject/FactoryMain';
import FactoryProjectCMS from './projectManagement/poolProject/FactoryProjectCMS';
import FactoryResourceRequest from './projectManagement/poolProject/FactoryResourceRequest';
import PoolRevision from './projectManagement/poolProjectRevision/poolRevision';
import TaskType from './masterData/taskType/TaskType';
import ApproveChangeStatus from './projectManagement/workflow/ApproveChangeStatus';
import SearchQMS from './qms/search/SearchQMS';
import AddTemplate from './qms/search/AddTemplate';
import GenerateQMSNumber from './qms/GenerateQMSNumber';
import ProjectTaskManagement from './Timesheets/projectTaskManagement/ProjectTaskManagement';
import TimeSheetEntry from './Timesheets/timeSheetEntry/TimeSheetEntry';
import TimeSheetApprovalDaily from './Timesheets/timeSheetApproval/TimeSheetAppovalDaily';
import ViewAllTimeSheets from './Timesheets/viewAllTimeheets/ViewAllTimeSheets';
//import ResourceRequest from './components/Common/SubComponents/ResourceRequest';
import ViewQMS from './qms/qmsListView/ViewQMS';
import ResourceOnsiteDuration from './resourceManagement/resourceOnsiteDuration/ResourceOnsiteDuration';
import UnfreezeWorkFlow from './Timesheets/timeSheetEntry/unFreezeWorkflow/UnFreezeWorkflow';
import EditDepartment from './masterData/practice/EditDepartment';
import EditPractice from './masterData/practice/EditPractice';
import SimplifiedTimeEntry from './Timesheets/timeSheetEntry/SimplifiedTimeEntry';
import ResourceUtilization from './Timesheets/resourceUtilization/ResourceUtilization';
import CreateInvoiceRequest from './projectManagement/invoice/CreateInvoiceRequest';
import InvoiceRequestSearch from './projectManagement/invoice/InvoiceRequestSearch';
import IRFView from './projectManagement/invoice/IRFView';
import PendingIR from './projectManagement/invoice/PendingIR';

const analyticsCode = require('./config').get(process.env.REACT_APP_ENV).analyticsCode;
const config = require('./config').get(process.env.REACT_APP_ENV);
const Page404 = ({ location }) => (
  <div className="container-image-error">
    <div className="error-image mx-auto">
      <a href="/">
        <img className="err-img" src={NotFoundImage} alt="Error Image" />{' '}
      </a>
      <div className="error-message">
        <div>Aha! You see! You can be wrong!</div>
        <div>OR</div>
        <div>You do not have permission to access this page. </div>
        <div>Please contact the admin to request access</div>
        <div>
          <a href="/">Go Back To Home</a>
        </div>
      </div>
    </div>
  </div>
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headerTitle: '',
      displayName: '',
      authenticated: false
    };

    this.config = {
      auth: {
        authority: config.authority,
        clientId: config.clientId,
        redirectUri: window.location.protocol + '//' + window.location.host
      },
      cache: {
        storeAuthStateInCookie: true
      }
    };

    this.authenticationParameters = {
      scopes: config.scopes
    };
  }

  static initializeReactGA() {
    ReactGA.initialize(analyticsCode);
    ReactGA.pageview('/');
  }

  componentDidMount() {
    document.getElementById('initialLoader').remove();
    App.initializeReactGA();
    loggerService.writeLog('Token Refreshed.', LOG_TYPES.INFO);
  }

  callInitApis = async () => {
    if (!window.LOGGED_USER || !window.USER_PERMISSION) {
      getLoggedInUser().then(res => {
        //Get distinct permissions
        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }

        if (res && res.data) {
          window.LOGGED_USER = res.data.user;
          var permission = [];
          if (res.data.user.roles != null) {
            for (let per of res.data.user.roles) {
              for (let item of per.Permissions) {
                permission.push(item.PermissionName);
              }
            }
          }

          const unique = permission.filter(onlyUnique);

          //Permission list
          window.USER_PERMISSION = unique;
          this.setState({
            displayName: window.LOGGED_USER.displayName
          });
        }

        if (!this.state.authenticated) {
          this.setState({
            authenticated: true
          });
        }

        this.onSaveAuditTrail();
      });
    }
  };

  onSaveAuditTrail = () => {
    const userDetails = window.LOGGED_USER;
    const auditTrail = {
      Action: 'User logged in',
      RefType: 'Log in',
      RefId: userDetails.userId,
      RefName: userDetails.displayName
    };
    saveAuditTrail(auditTrail);
  };

  handleHeaderTitleChange = title => {
    if (title !== this.state.headerTitle) {
      this.setState({
        headerTitle: title
      });

      ReactGA.event({
        category: 'User Actions',
        action: title
      });
    }
  };

  /**
   * Gets the JWT given the azure access token and initializes permissions
   * @param accountInfo Account infor from react msal
   */
  handleAccountInfo = accountInfo => {
    localStorage.setItem('accessToken', accountInfo.jwtAccessToken);
    getToken()
      .then(res => {
        localStorage.setItem('accessToken', null);
        // Initializes timer to update JWT token periodically.
        setInterval(this.updateJWTToken, config.jwtRefreshInterval * 60 * 1000);
        this.callInitApis();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  authenticationSuccess = logout => {
    return this.state.authenticated ? (
      this.getAppContent(logout)
    ) : (
      <div className="loader">
        <img src={loader} alt="RMS Loader" />
      </div>
    );
  };

  unauthenticatedFunction = loginFunction => {
    loginFunction();
    return <div className={'center-div'}>Please wait while Spark Logs you in....</div>;
  };

  /**
   * Updates the local storage with new JWT token.
   */
  updateJWTToken = () => {
    refreshToken()
      .then(res => {
        loggerService.writeLog('Token Refreshed.', LOG_TYPES.INFO);
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  /**
   * Returns the main application ui
   * @param logout the logout callback from react msal
   * @returns COmponent with application content
   */
  getAppContent = logout => {
    return (
      <div>
        <Layout
          headerTitle={this.state.headerTitle}
          displayName={this.state.displayName}
          logoutCallback={logout}
        >
          <Switch>
            <Route
              exact={true}
              path="/"
              component={props => (
                <Dashboard
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/projects/create/create"
              component={props => (
                <CreateProject
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/projects/cmsheet/cmsheet"
              component={props => (
                <CMSheet
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/PoolProject/FactoryProjectCMS"
              component={props => (
                <FactoryProjectCMS
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/changeRequest/view"
              component={props => (
                <EditChangeRequest
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/changeRequest/edit"
              component={props => (
                <EditChangeRequest
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/changeRequest/create"
              component={props => (
                <EditChangeRequest
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/projects/invoice/create"
              component={props => (
                <CreateInvoiceRequest
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/projects/invoice/invoicehistory"
              component={props => (
                <InvoiceRequestSearch
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/projects/invoice/irfview"
              component={props => (
                <IRFView
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/projects/invoice/pendinginvoicerequests"
              component={props => (
                <PendingIR
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/poolProjectRevision/poolRevision"
              component={props => (
                <PoolRevision {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
              )}
            />
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_QMS') > -1 && (
              <Route
                path="/qms/search/search"
                component={props => (
                  <SearchQMS {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                )}
              />
            )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('VW_QMS') > -1 && (
              <Route
                path="/qms/viewqmslist/viewqmslist"
                component={props => (
                  <ViewQMS
                    {...props}
                    timestamp={new Date().toString()}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            <Route
              path="/qms/generateNumber/generateNumber"
              component={props => (
                <GenerateQMSNumber {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
              )}
            />
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_QMS') > -1 && (
              <Route
                path="/admin/qms/addtemplate"
                component={props => (
                  <AddTemplate {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                )}
              />
            )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_QMS') > -1 && (
              <Route
                path="/admin/qms/edittemplate"
                component={props => (
                  <AddTemplate {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                )}
              />
            )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('WF_CUS') > -1 && (
              <Route
                path="/admin/createworkflow/createworkflow"
                component={props => (
                  <WorkflowCreate
                    {...props}
                    timestamp={new Date().toString()}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            <Route
              path="/admin/ApproveChangeStatus/ApproveChangeStatus"
              component={props => (
                <ApproveChangeStatus
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('VW_WKF') > -1 && (
              <Route
                path="/admin/workflow/workflow"
                component={props => (
                  <Workflow
                    {...props}
                    timestamp={new Date().toString()}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION &&
              (window.USER_PERMISSION.indexOf('RES_MGMNT') > -1 ||
                window.USER_PERMISSION.indexOf('VW_PRJ') > -1 ||
                window.USER_PERMISSION.indexOf('FAC_PRO') > -1 ||
                window.USER_PERMISSION.indexOf('VW_FAC_PRJ') > -1) &&
              window.USER_PERMISSION.indexOf('VW_PRJ') > -1 && (
                <Route
                  path="/projects/search/search"
                  component={props => (
                    <ProjectSearch
                      {...props}
                      timestamp={new Date().toString()}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            <Route
              path="/resources/allocatepresales/allocatepresales"
              component={AllocatePresales}
            />
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_CUST') > -1) || (window.USER_PERMISSION.indexOf('EDT_CUST') > -1)) && (
                <Route
                  path="/admin/customer/search"
                  component={props => (
                    <SearchCustomer {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_CUST') > -1 && (
              <Route
                path="/admin/customer/edit"
                component={props => (
                  <EditCustomer {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                )}
              />
            )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_RT_CRD_MGT') > -1) || (window.USER_PERMISSION.indexOf('EDT_RATE_CRD') > -1)) && (
                <Route
                  path="/admin/ratecard/ratecard"
                  component={props => (
                    <RateCard {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                  )}
                />
              )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_RT_CRD_MGT') > -1) || (window.USER_PERMISSION.indexOf('EDT_RATE_CRD') > -1)) && (
                <Route
                  path="/admin/ratecardedit/ratecardedit"
                  component={props => (
                    <RateCardEdit {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                  )}
                />
              )}
            {window.USER_PERMISSION && (window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 || 
                    window.USER_PERMISSION.indexOf('ROLE_MGT') > -1) && (
              <Route
                path="/admin/rolemanagement/rolemanagement"
                component={props => (
                  <RoleManagement
                    {...props}
                    timestamp={new Date().toString()}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && (window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 || 
                    window.USER_PERMISSION.indexOf('ROLE_MGT') > -1) && (
              <Route
                path="/admin/createrole/createrole"
                component={props => (
                  <RoleCreate
                    timestamp={new Date().toString()}
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_DSG') > -1) || (window.USER_PERMISSION.indexOf('ED_DEG') > -1)) && (
                <Route
                  path="/admin/designation/designation"
                  component={props => (
                    <Designation {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                  )}
                />
              )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_DSG_GRP') > -1) || (window.USER_PERMISSION.indexOf('ED_DEG_GP') > -1)) && (
                <Route
                  path="/admin/designationgroup/designationgroup"
                  component={props => (
                    <DesignationGroup
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('ED_DEG') > -1 && (
              <Route
                path="/admin/createdesignation/createdesignation"
                component={props => (
                  <DesignationCreate
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('ED_DEG_GP') > -1 && (
              <Route
                path="/admin/createdesignationgroup/createdesignationgroup"
                component={props => (
                  <DesignationGroupCreate
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('VW_COMM') > -1 && (
              <Route
                path="/admin/CreatePartner/CreatePartner"
                component={props => <PartnerCreate timestamp={new Date().toString()} {...props} />}
              />
            )}
            <Route
                path="/help/help"
                component={props => (
                  <Help
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            /*start*/
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_ENG_TYP') > -1) || (window.USER_PERMISSION.indexOf('ED_ENG') > -1)) && (
                <Route
                  path="/masterdata/engagementtype/engagementtype"
                  component={props => (
                    <EngagementType
                      timestamp={new Date().toString()}
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('ED_ENG') > -1 && (
              <Route
                path="/masterdata/createengagementtype/createengagementtype"
                component={props => (
                  <EngagementTypeCreate timestamp={new Date().toString()} {...props} />
                )}
              />
            )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_PRCT') > -1) || (window.USER_PERMISSION.indexOf('ED_PRA') > -1)) && (
                <Route
                  path="/masterdata/practice/practice"
                  component={props => (
                    <Practice
                      timestamp={new Date().toString()}
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_PRCT') > -1) || (window.USER_PERMISSION.indexOf('ED_PRA') > -1)) && (
                <Route
                    path="/masterdata/practice/practiceSF"
                    component={props => (
                        <PracticeSF
                            timestamp={new Date().toString()}
                            {...props}
                            onHeaderTitleChange={this.handleHeaderTitleChange}
                        />
                    )}
                />
            )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('ED_PRA') > -1 && (
              <Route
                path="/masterdata/createpractice/createpractice"
                component={props => (
                  <PracticeCreate
                    timestamp={new Date().toString()}
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('ED_CAL') > -1 && (
              <Route
                path="/admin/calendar/create"
                component={props => (
                  <CalendarCreate
                    timestamp={new Date().toString()}
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_CLDR') > -1) || (window.USER_PERMISSION.indexOf('ED_CAL') > -1)) && (
                <Route
                  path="/admin/Calendar/Search"
                  component={props => (
                    <CalendarSearch
                      timestamp={new Date().toString()}
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_COMM') > -1) || (window.USER_PERMISSION.indexOf('EDT_COMM') > -1)) && (
                <Route
                  path="/admin/commissions/commissions"
                  component={props => (
                    <Commissions
                      timestamp={new Date().toString()}
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_COMM') > -1 && (
              <Route
                path="/admin/createsalesperson/createsalesperson"
                component={props => (
                  <SalesPersonCreate
                    timestamp={new Date().toString()}
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION &&
              (window.USER_PERMISSION.indexOf('VW_APPR_TY') > -1 ||
                window.USER_PERMISSION.indexOf('EDT_APPR_TY') > -1) && (
                <Route
                  path="/admin/approvertype/searchapprovertype"
                  component={props => (
                    <ApproverTypeSearch
                      timestamp={new Date().toString()}
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_APPR_TY') > -1 && (
              <Route
                path="/admin/approvertype/createapprovertype"
                component={props => (
                  <ApproverTypeCreate
                    timestamp={new Date().toString()}
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_EXG_RT') > -1) || (window.USER_PERMISSION.indexOf('EDT_EXG_RT') > -1)) && (
                <Route
                  path="/admin/ExchangeRate/ExchangeRate"
                  component={props => (
                    <ExchangeRate
                      timestamp={new Date().toString()}
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_EXG_RT') > -1 && (
              <Route
                path="/admin/createExchangeRate/createExchangeRate"
                component={props => (
                  <ExchangeRateCreate
                    timestamp={new Date().toString()}
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_OFF_TYP') > -1) || (window.USER_PERMISSION.indexOf('EDT_OFF') > -1)) && (
                <Route
                  path="/admin/offerings/search"
                  component={props => (
                    <OfferingsSearch
                      timestamp={new Date().toString()}
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_OFF') > -1 && (
              <Route
                path="/admin/offerings/create"
                component={props => (
                  <CreateOffering
                    timestamp={new Date().toString()}
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION &&
              (window.USER_PERMISSION.indexOf('VW_MUL_YR_INC') > -1 ||
                window.USER_PERMISSION.indexOf('EDT_MUL_YR_INC') > -1) && (
                <Route
                  path="/admin/multiyearincrement/multiyearincrementinfo"
                  component={props => (
                    <MultiyearIncrementInfo
                      timestamp={new Date().toString()}
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_MUL_YR_INC') > -1 && (
              <Route
                path="/admin/createmultiyearincrement/createmultiyearincrement"
                component={props => (
                  <CreateMultiyearIncrement
                    timestamp={new Date().toString()}
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_REG') > -1) || (window.USER_PERMISSION.indexOf('EDT_RGN') > -1)) && (
                <Route
                  path="/admin/region/region"
                  component={props => (
                    <Region
                      {...props}
                      timestamp={new Date().toString()}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_RGN') > -1 && (
              <Route
                path="/admin/region/createregion"
                component={props => (
                  <CreateEditRegion
                    {...props}
                    timestamp={new Date().toString()}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_CST_CRD') > -1) || (window.USER_PERMISSION.indexOf('EDT_COS_CR') > -1)) && (
                <Route
                  path="/admin/CostCard/Search"
                  component={props => (
                    <CostCardSearch {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                  )}
                />
              )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_CST_CRD') > -1) || (window.USER_PERMISSION.indexOf('EDT_COS_CR') > -1)) && (
                <Route
                  path="/admin/costcard/edit"
                  component={props => (
                    <EditCostCard {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_COS_CR') > -1 && (
              <Route
                path="/admin/costcard/create"
                component={props => (
                  <CreateCostCard {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                )}
              />
            )}
            {window.USER_PERMISSION &&
              (window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 ||
                window.USER_PERMISSION.indexOf('EDT_RSC_PROF') > -1 ||
                window.USER_PERMISSION.indexOf('USR_MGT') > -1) && (
                <Route
                  path="/admin/usermanagement/usermanagement"
                  component={props => (
                    <UserManagement
                      {...props}
                      timestamp={new Date().toString()}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && (window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 ||
                window.USER_PERMISSION.indexOf('USR_MGT') > -1) && (
              <Route
                path="/admin/createuser/createuser"
                component={props => (
                  <UserCreate
                    {...props}
                    timestamp={new Date().toString()}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('VW_AT') > -1 && (
              <Route
                path="/admin/audittrail/audittrailsearch"
                component={props => (
                  <AuditTrailSearch
                    {...props}
                    timestamp={new Date().toString()}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('VW_AT') > -1 && (
              <Route
                path="/admin/audittrail/audittrailsearch"
                component={props => (
                  <AuditTrailSearch
                    {...props}
                    timestamp={new Date().toString()}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('VW_CMS') > -1 && (
              <Route
                path="/projects/CMSheet/ConsolidatedCMSheet/ConsolidatedCMSheet"
                component={props => (
                  <ConsolidatedCMSheet
                    {...props}
                    timestamp={new Date().toString()}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_RSC_PROF') > -1 && (
              <Route
                path="/ResourceManagement/EmployeeUseProfile"
                component={props => (
                  <EmployeeUserProfile {...props} timestamp={new Date().toString()} />
                )}
              />
            )}
            {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_PRCT') > -1) || (window.USER_PERMISSION.indexOf('ED_PRA') > -1)) && (
                <Route
                  path="/masterdata/practice/editdepartment"
                  component={props => (
                    <EditDepartment
                      timestamp={new Date().toString()}
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
              {window.USER_PERMISSION && ((window.USER_PERMISSION.indexOf('VW_PRCT') > -1) || (window.USER_PERMISSION.indexOf('ED_PRA') > -1)) && (
                <Route
                  path="/masterdata/practice/editpractice"
                  component={props => (
                    <EditPractice
                      timestamp={new Date().toString()}
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            <Route
              path="/SkillSearch/SkillSearch"
              component={props => <SkillSearch {...props} timestamp={new Date().toString()} />}
            />
            <Route
              path="/EvaluateSkills/EvaluateSkills"
              component={props => <EvaluateSkills {...props} timestamp={new Date().toString()} />}
            />
            <Route
              path="/ResourceProfileSearch/ResourceProfileSearch"
              component={props => (
                <ResourceProfileSearch {...props} timestamp={new Date().toString()} />
              )}
            />
            <Route
              path="/ProjectResourceRequest/ProjectResourceRequest"
              component={props => (
                <ProjectResourceRequest
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/ProjectResourceRequest/ProjectResourceAllocation"
              component={props => (
                <ProjectResourceAllocation
                  {...props}
                  timestamp={new Date().toString()}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            {window.USER_PERMISSION &&
              (window.USER_PERMISSION.indexOf('VW_WFCRI') > -1 ||
                window.USER_PERMISSION.indexOf('EDT_WFCRI') > -1) && (
                <Route
                  path="/WorkflowCriteriaDefinitionSearch/WorkflowCriteriaDefinitionSearch"
                  component={props => (
                    <WorkflowCriteriaDefinitionSearch
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                      timestamp={new Date().toString()}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_WFCRI') > -1 && (
              <Route
                path="/WorkflowCriteriaDefinitionCreate/WorkflowCriteriaDefinitionCreate"
                component={props => (
                  <WorkflowCriteriaDefinitionCreate
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                    timestamp={new Date().toString()}
                  />
                )}
              />
            )}
            <Route
              path="/WorkflowConfigurationSearch/WorkflowConfigurationSearch"
              component={props => (
                <WorkflowConfigurationSearch {...props} timestamp={new Date().toString()} />
              )}
            />
            <Route
              path="/ActiveStatus/ActiveStatus"
              component={props => <ActiveStatus {...props} timestamp={new Date().toString()} />}
            />
            <Route
              path="/ResourceManagement/ResourceRequestActivation"
              component={props => (
                <ResourceRequestActivation
                  {...props}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                  timestamp={new Date().toString()}
                />
              )}
            />
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('FAC_PRO') > -1 && (
              <Route
                path="/PoolProject/FactoryMain"
                component={props => (
                  <FactoryMain
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                    timestamp={new Date().toString()}
                  />
                )}
              />
            )}
            <Route
              path="/PoolProject/FactoryProjectCMS"
              component={props => (
                <FactoryProjectCMS
                  {...props}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                  timestamp={new Date().toString()}
                />
              )}
            />
            <Route
              path="/PoolProject/FactoryResourceRequest"
              component={props => (
                <FactoryResourceRequest
                  {...props}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                  timestamp={new Date().toString()}
                />
              )}
            />
            <Route
              path="/masterdata/documenttype/documenttypesearch"
              component={props => (
                <DocumentTypeSearch {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
              )}
            />
            <Route
              path="/masterdata/documenttype/documenttypedetails"
              component={props => (
                <DocumentTypeDetails
                  {...props}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            {window.USER_PERMISSION &&
              (window.USER_PERMISSION.indexOf('VW_HR_DESG') > -1 ||
                window.USER_PERMISSION.indexOf('EDT_HR_DESG') > -1) && (
                <Route
                  path="/admin/hrdesignation/search"
                  component={props => (
                    <HRDesignationSearch
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />
              )}
            {window.USER_PERMISSION && window.USER_PERMISSION.indexOf('EDT_HR_DESG') > -1 && (
              <Route
                path="/admin/hrdesignation/create"
                component={props => (
                  <CreateHRDesignation
                    {...props}
                    onHeaderTitleChange={this.handleHeaderTitleChange}
                  />
                )}
              />
            )}
              {window.USER_PERMISSION && (window.USER_PERMISSION.indexOf('VW_OS_RES') > -1 || window.USER_PERMISSION.indexOf('EDT_OS_RES') > -1) && (
                  <Route
                      path="/resourceManagement/resourceOnsiteDuration/resourceOnsiteDuration"
                      component={props => (
                          <ResourceOnsiteDuration
                              {...props}
                              onHeaderTitleChange={this.handleHeaderTitleChange}
                          />
                      )}
                  />
              )}
            <Route
              path="/timesheets/nonprojecttasksearch/nonprojecttasksearch"
              component={props => (
                <NonProjectTaskSearch
                  {...props}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/timesheets/timesheetentry"
              component={props => (
                <TimeSheetEntry {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
              )}
            />
            <Route
              path="/timesheets/timesheetentrylite"
              component={props => (
                <SimplifiedTimeEntry {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
              )}
            />
            <Route
              path="/timesheets/resourceutilization"
              component={props => (
                <ResourceUtilization {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
              )}
            />
            <Route
              path="/timesheets/unfreezeworkflow/unfreezeworkflow"
              component={props => (
                <UnfreezeWorkFlow {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                )}
            />
            <Route
              path="/timesheets/timesheetapproval"
              component={props => (
                <TimeSheetApprovalDaily
                  {...props}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />
            <Route
              path="/timesheets/viewalltimesheets"
              component={props => (
                <ViewAllTimeSheets
                  {...props}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />

            <Route
              path="/masterdata/practice/editdepartment"
              component={props => (
                <EditDepartment
                  {...props}
                  onHeaderTitleChange={this.handleHeaderTitleChange}
                />
              )}
            />

            {window.USER_PERMISSION &&
              (window.USER_PERMISSION.indexOf('VW_TSKTYP') > -1 ||
                window.USER_PERMISSION.indexOf('EDT_TSKTYP') > -1) && (
                <Route
                  path="/masterdata/tasktype/tasktype"
                  component={props => (
                    <TaskType {...props} onHeaderTitleChange={this.handleHeaderTitleChange} />
                  )}
                />
              )}

                <Route
                  path="/timesheets/projecttaskmanagement/projecttaskmanagement"
                  component={props => (
                    <ProjectTaskManagement
                      {...props}
                      onHeaderTitleChange={this.handleHeaderTitleChange}
                    />
                  )}
                />

            <Route component={Page404} />
          </Switch>
        </Layout>
      </div>
    );
  };

  render() {
    return (
      // Wraps main application contents in an authentication higher component.
      <AzureAD
        provider={
          new MsalAuthProviderFactory(
            this.config,
            this.authenticationParameters,
            LoginType.Redirect
          )
        }
        unauthenticatedFunction={this.unauthenticatedFunction}
        authenticatedFunction={this.authenticationSuccess}
        accountInfoCallback={this.handleAccountInfo}
      />
    );
  }
}

export default NetworkDetector(App);
