import React, { Component } from 'react';
import {ComboBox, MultiSelect} from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy } from '@progress/kendo-data-query';
import { Redirect } from 'react-router';
import differenceBy from 'lodash/differenceBy';
import { Tooltip } from 'reactstrap';
import { saveWorkflow, saveWorkflowApproversOnEdit, updateWorkflow } from '../workflow/WorkflowService';
import { getWorkflowType, getWorkflowOperator, getWorkflowParameter } from '../workflow/WorkflowService';
import {
  getPaymentMethods,
  getProjectTypes
} from '../../masterData/common/CommonService';
import WorkflowApprovers from './CreateWorkflowApprovers';
import { mapToApproverModel } from './components/MapApproverModel';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import { getActiveProjectResources } from '../../resourceManagement/ResourceService';
import {
  getRegionsWithCountriesIntegrated,
  getBillingDiviIntegrated,
  getApproverTypesIntegrated, getResourceRegionsIntegrated,
  getPracticeTypesIntegrated,
  getAllPracticeDivisionMapIntegrated
} from '../../integration/MasterDataService';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import Loader from '../../integration/components/Loader';

import { getWorkflowDefinitions } from '../../masterData/workflowCriteriaDefinition/WorkflowCriteriaDefinitionService';
import {getProjectStatuses} from "../project/ProjectService";

const billingRegionAll = [];
const resourceRegionAll = [];
const workflowTypeDataAll = [];
const workflowOperatorRevDataAll = [];
const workflowOperatorNonDataAll = [];
const workflowOperatorDisDataAll = [];
const workflowOperatorConDataAll = [];
const workflowParameterDataAll = [];
const workflowOperatorCostVarDataAll = [];
const billingDivisionAll = [];
const resourcesAll = [];
const paymentMethodAll = [];
let newRevenueParameters;
let newCostVarParameters;
let newNetProfitMarginParameters;
let newDiscountsParameters;
let newNetDiscountsParameters;
let newContingencyParameters;
const approverTypeAll = [];
const statusesAll = [];
const practiceAll = [];
const engagementTypesAll = [];

class WorkflowCreate extends Component {
  popupSet = {
    width: 'auto'
  };

  constructor(props) {
    super(props);
    this.state = {
      workflowParameters: [],
      workflowRevenueParameters: [],
      workflowCostVarParameters: [],
      workflownetProfitMarginParameters: [],
      workflowDiscountsParameters: [],
      workflowNetDiscountsParameters: [],
      workflowContingencyParameters: [],
      field: 'ApproverTypeName',
      toSearchWorkFlow: false,
      toDashboard: false,
      workflowTypeData: workflowTypeDataAll.slice(),
      workflowOperatorRevData: workflowOperatorRevDataAll.slice(),
      workflowOperatorCostVarData: workflowOperatorCostVarDataAll.slice(),
      workflowOperatorNonData: workflowOperatorNonDataAll.slice(),
      workflowOperatorDisData: workflowOperatorDisDataAll.slice(),
      workflowOperatorConData: workflowOperatorConDataAll.slice(),
      workflowParameterData: workflowParameterDataAll.slice(),
      workflowOperatorData: [],
      billingRegion: billingRegionAll.slice(),
      statuses: statusesAll.slice(),
      resourceRegion: resourceRegionAll.slice(),
      billingDivision: billingDivisionAll.slice(),
      resources: [],
      resource: resourcesAll.slice(),
      value: null,
      noofResourceSelected: null,
      selectedBillingDivision: null,
      selectedBillingDivisions: [],
      selectedBillingRegion: null,
      selectedBillingRegions: [],
      selectedRequestResourceRegion: null,
      selectedAllocatedResourceRegion: null,
      data: [],
      workflowText: '',
      Approvers: [],
      saveSucess: '',
      popupVisible: false,
      visible: false,
      dialogMessage: '',
      dialogTitle: '',
      loading: false,
      main: [
        {
          mainApproverCardHorizontal: [
            {
              approverCardVertical: [
                {
                  approverListVer: [
                    {
                      ApproverData: this.resourcesAll,
                      SelectedData: [{ SelectedId: 0, SelectedValue: '', DBGroupId: 0 }]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      index: 0,
      existingStateResource: [],
      isError: false,
      isFormDirty: false,
      resource1: resourcesAll.slice(),
      approvalTypes: approverTypeAll.slice(),
      ApproverGroup: [],
      isEditMode: false,
      UpdateApproversList: [],
      isDuplicateMode: false,
      confirmationPopupVisible: false,
      cardRemoveMainIndex: 0,
      cardRemoveHoriIndex: 0,
      isSaveButtonClicked: false,
      workflowNameToolTipOpen: false,
      isDisableSave: false,
      paymentMethods: [],
      selectedPaymentMethod: null,
      selectedRevenueValue: null,
      selectedNetProfitValue: null,
      selectedDiscountValue: null,
      selectedNetDiscountValue: null,
      selectedContingencyValue: null,
      selectedCostVarValue: null,
      paymentMethod: paymentMethodAll,
      workflowId: null,
      keyId:"",
      practice: practiceAll.slice(),
      selectedPractice: null,
      practiceDivisionMap: [],
      selectedpracticeData: [],
      engagementTypes: engagementTypesAll.slice(),
      selectedEngagementType: null,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
    this.toggleDialogCancel = this.toggleDialogCancel.bind(this);
  }

  componentWillUnmount() {
    this.unsubscribe(10);
  }

  unsubscribe = (id) =>{
    this.setState({
      keyId:id
      });
  }

  subscribe = async(id) =>{
    if(this.state.keyId !== 10)
    {
      await this.setState({
        keyId:id
      });
      if(this.state.keyId === 10)
      {
        if (this.props.location.action === 'edit') {
          await this.setState({ isEditMode: true });
          await this.updateWorkflow(this.props.location.workflow);
          this.state.workflowId = this.props.location.workflow.dataItem.id;
        }
        if (this.props.location.action === 'WK_edit') {
          await this.setState({ isEditMode: false });
          await this.updateWorkflow(this.props.location.workflow);
        }
        if (this.props.location.action === 'duplicate') {
          await this.setState({ isDuplicateMode: true });
          await this.updateWorkflow(this.props.location.workflow);
        }
    
        await getWorkflowType()
          .then(response => {
            this.workflowTypeDataAll = response.data;
            this.setState({
              workflowTypeData: response.data
            });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    
    
        await getWorkflowOperator()
          .then(response => {
            this.workflowOperatorRevDataAll = response.data;
            this.workflowOperatorCostVarDataAll = response.data;
            this.workflowOperatorNonDataAll = response.data;
            this.workflowOperatorDisDataAll = response.data;
            this.workflowOperatorConDataAll = response.data;
            this.setState({
              workflowOperatorRevData: response.data,
              workflowOperatorCostVarData: response.data,
              workflowOperatorNonData: response.data,
              workflowOperatorDisData: response.data,
              workflowOperatorConData: response.data,
              workflowOperatorData: response.data
            });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
        await getWorkflowParameter()
          .then(response => {
            this.workflowParameterDataAll = response.data;
            this.setState({
              workflowParameterData: response.data
            });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    
        await getRegionsWithCountriesIntegrated()
          .then(response => {
            this.billingRegionAll = response.data;
            this.setState({
              billingRegion: response.data
            });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    
        const resReg = await getResourceRegionsIntegrated();
        this.resourceRegionAll = resReg.data;
        this.setState({
          resourceRegion: resReg.data
        });
    
        const paymentMethods = [];
        await getPaymentMethods()
          .then(response => {
            response.data.forEach(function(paymentMethod) {
              const paymentMethod_ = {
                id: paymentMethod.id,
                Name: paymentMethod.Name
              };
              paymentMethods.push(paymentMethod_);
            });
            const paymentMethod_ = { id: 4, Name: 'All' };
            paymentMethods.push(paymentMethod_);
    
            this.paymentMethodAll = paymentMethods;
            this.setState({
              paymentMethods: paymentMethods
            });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    
        await getBillingDiviIntegrated()
          .then(response => {
            this.billingDivisionAll = response.data.filter(el => el.IsActive === true && el.SFCode !== null);
            this.setState({
              billingDivision: response.data.filter(el => el.IsActive === true && el.SFCode !== null)
            });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    
        await getActiveProjectResources()
          .then(response => {
            if (!this.state.isEditMode && !this.state.isDuplicateMode) {
              const newApprover = {
                approverListVer: [
                  {
                    ApproverData: response.data,
                    SelectedData: [{ SelectedId: 0, SelectedValue: '', DBGroupId: 0 }]
                  }
                ]
              };
    
              const newApproverToVertical = {
                approverCardVertical: [newApprover]
              };
    
              const main = [...this.state.main];
              main[0].mainApproverCardHorizontal[0] = newApproverToVertical;
    
              this.setState({ main: main });
            }
            this.resourcesAll = response.data;
    
            this.setState({
              resources: response.data,
              resource1: response.data
            });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    
        await getApproverTypesIntegrated()
          .then(response => {
            this.approverTypeAll = response.data;
            this.setState({
              approvalTypes: response.data
            });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    
        await getPracticeTypesIntegrated() 
          .then(res => {
            this.practiceAll = res.data;
            this.setState({
              practice: res.data
            });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });

          await getAllPracticeDivisionMapIntegrated()
            .then(response => {
                this.setState({
                    practiceDivisionMap: response.data
                });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

          await getProjectTypes().then(response => {
            this.engagementTypesAll = response.data;
            this.setState({
                engagementTypes: response.data,
            });
          })
          .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    
        await getWorkflowDefinitions()
          .then(response => {
            const resp = response.data;
            const parameters = [];
            const revenueParameters = [];
            const netProfitMarginParameters = [];
            const discountsParameters = [];
            const netDiscountsParameters = [];
            const ContingencyParameters = [];
            const costVarParameters = [];
    
            for (let value of resp) {
              if (value.ParameterId === 1) {
                const revenueResult = [];
                if (value.WorkflowOperator.Name === 'Less than') {
                  revenueResult.Name = 'Less than ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Between') {
                  revenueResult.Name = 'Between  ' + value.Value1 + ' - ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Equal to') {
                  revenueResult.Name = 'Equal to ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Greater than') {
                  revenueResult.Name = 'Greater than ' + value.Value2;
                } else {
                  revenueResult.Name = '';
                }
                revenueResult.Value1 = value.Value1;
                revenueResult.Value2 = value.Value2;
                revenueResult.OperatorId = value.WorkflowOperator.id;
                revenueResult.ParameterCriteriaId = value.id;
                revenueParameters.push(revenueResult);
              }
    
              if (value.ParameterId === 2) {
                const netProfitResult = [];
                if (value.WorkflowOperator.Name === 'Less than') {
                  netProfitResult.Name = 'Less than ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Between') {
                  netProfitResult.Name = 'Between  ' + value.Value1 + ' - ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Equal to') {
                  netProfitResult.Name = 'Equal to ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Greater than') {
                  netProfitResult.Name = 'Greater than ' + value.Value2;
                } else {
                  netProfitResult.Name = '';
                }
                netProfitResult.Value1 = value.Value1;
                netProfitResult.Value2 = value.Value2;
                netProfitResult.OperatorId = value.WorkflowOperator.id;
                netProfitResult.ParameterCriteriaId = value.id;
                netProfitMarginParameters.push(netProfitResult);
              }
    
              if (value.ParameterId === 3) {
                const discountResult = [];
                if (value.WorkflowOperator.Name === 'Less than') {
                  discountResult.Name = 'Less than ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Between') {
                  discountResult.Name = 'Between  ' + value.Value1 + ' - ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Equal to') {
                  discountResult.Name = 'Equal to ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Greater than') {
                  discountResult.Name = 'Greater than ' + value.Value2;
                } else {
                  discountResult.Name = '';
                }
                discountResult.Value1 = value.Value1;
                discountResult.Value2 = value.Value2;
                discountResult.OperatorId = value.WorkflowOperator.id;
                discountResult.ParameterCriteriaId = value.id;
                discountsParameters.push(discountResult);
              }
    
              if (value.ParameterId === 8) {
                const netDiscountsResult = [];
                if (value.WorkflowOperator.Name === 'Less than') {
                  netDiscountsResult.Name = 'Less than ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Between') {
                  netDiscountsResult.Name = 'Between  ' + value.Value1 + ' - ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Equal to') {
                  netDiscountsResult.Name = 'Equal to ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Greater than') {
                  netDiscountsResult.Name = 'Greater than ' + value.Value2;
                } else {
                  netDiscountsResult.Name = '';
                }
                netDiscountsResult.Value1 = value.Value1;
                netDiscountsResult.Value2 = value.Value2;
                netDiscountsResult.OperatorId = value.WorkflowOperator.id;
                netDiscountsResult.ParameterCriteriaId = value.id;
                netDiscountsParameters.push(netDiscountsResult);
              }
    
              if (value.ParameterId === 4) {
                const contingencyResult = [];
                if (value.WorkflowOperator.Name === 'Less than') {
                  contingencyResult.Name = 'Less than ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Between') {
                  contingencyResult.Name = 'Between  ' + value.Value1 + ' - ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Equal to') {
                  contingencyResult.Name = 'Equal to ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Greater than') {
                  contingencyResult.Name = 'Greater than ' + value.Value2;
                } else {
                  contingencyResult.Name = '';
                }
                contingencyResult.Value1 = value.Value1;
                contingencyResult.Value2 = value.Value2;
                contingencyResult.OperatorId = value.WorkflowOperator.id;
                contingencyResult.ParameterCriteriaId = value.id;
                ContingencyParameters.push(contingencyResult);
              }
    
              if (value.ParameterId === 9) {
                const costVarianceResult = [];
                if (value.WorkflowOperator.Name === 'Less than') {
                  costVarianceResult.Name = 'Less than ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Between') {
                  costVarianceResult.Name = 'Between  ' + value.Value1 + ' - ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Equal to') {
                  costVarianceResult.Name = 'Equal to ' + value.Value2;
                } else if (value.WorkflowOperator.Name === 'Greater than') {
                  costVarianceResult.Name = 'Greater than ' + value.Value2;
                } else {
                  costVarianceResult.Name = '';
                }
                costVarianceResult.Value1 = value.Value1;
                costVarianceResult.Value2 = value.Value2;
                costVarianceResult.OperatorId = value.WorkflowOperator.id;
                costVarianceResult.ParameterCriteriaId = value.id;
                costVarParameters.push(costVarianceResult);
              }
            }
    
            newRevenueParameters = revenueParameters.map(function(val, index) {
              return {
                id: index,
                Name: val.Name,
                Value1: val.Value1,
                Value2: val.Value2,
                OperatorId: val.OperatorId,
                ParameterCriteriaId: val.ParameterCriteriaId
              };
            });
    
            newCostVarParameters = costVarParameters.map(function(val, index) {
              return {
                id: index,
                Name: val.Name,
                Value1: val.Value1,
                Value2: val.Value2,
                OperatorId: val.OperatorId,
                ParameterCriteriaId: val.ParameterCriteriaId
              };
            });
    
            newNetProfitMarginParameters = netProfitMarginParameters.map(function(val, index) {
              return {
                id: index,
                Name: val.Name,
                Value1: val.Value1,
                Value2: val.Value2,
                OperatorId: val.OperatorId,
                ParameterCriteriaId: val.ParameterCriteriaId
              };
            });
            newDiscountsParameters = discountsParameters.map(function(val, index) {
              return {
                id: index,
                Name: val.Name,
                Value1: val.Value1,
                Value2: val.Value2,
                OperatorId: val.OperatorId,
                ParameterCriteriaId: val.ParameterCriteriaId
              };
            });
            newNetDiscountsParameters = netDiscountsParameters.map(function(val, index) {
              return {
                id: index,
                Name: val.Name,
                Value1: val.Value1,
                Value2: val.Value2,
                OperatorId: val.OperatorId,
                ParameterCriteriaId: val.ParameterCriteriaId
              };
            });
            newContingencyParameters = ContingencyParameters.map(function(val, index) {
              return {
                id: index,
                Name: val.Name,
                Value1: val.Value1,
                Value2: val.Value2,
                OperatorId: val.OperatorId,
                ParameterCriteriaId: val.ParameterCriteriaId
              };
            });
    
            this.setState({
              workflowParameters: parameters,
              workflowRevenueParameters: newRevenueParameters,
              workflowCostVarParameters: newCostVarParameters,
              workflownetProfitMarginParameters: newNetProfitMarginParameters,
              workflowDiscountsParameters: newDiscountsParameters,
              workflowNetDiscountsParameters: newNetDiscountsParameters,
              workflowContingencyParameters: newContingencyParameters
            });
    
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });    
        this.populateStatuses();
        if (this.props.location.action === 'duplicate' || this.props.location.action === 'edit' || this.props.location.action === 'WK_edit') {
          await this.practiceDivisionMapping();
        }   
      }        
    }
}


  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setHeaderTitle(this.props.location.action);
    this.subscribe(10);

    
  }

  populateStatuses = () => {

    getProjectStatuses()
        .then(res => {
          this.statusesAll = res.data.filter(el => el.Code !== 'ON_HOLD');
            this.setState({
              statuses: res.data.filter(el => el.Code !== 'ON_HOLD')
            });

        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
  };
  setHeaderTitle = async action => {
    if (this.props.onHeaderTitleChange) {
      if (action === 'duplicate') {
        this.props.onHeaderTitleChange('Duplicate workflow');
      } else if (action === 'edit') {
        this.props.onHeaderTitleChange('Edit workflow');
      } else if (action === 'WK_edit') {
        this.props.onHeaderTitleChange('view/Edit workflow');
      } else {
        this.props.onHeaderTitleChange('Create a workflow');
      }
    }
  };

  updateWorkflow = async workflowData => {
    const formattedApproverModel = await mapToApproverModel(
      workflowData.dataItem,
      workflowData.ApproverDataAll
    );
    //this.editMain = JSON.parse(JSON.stringify(formattedApproverModel));
    await this.setState({
      main: formattedApproverModel,
      editMain: formattedApproverModel
    });
    const workflowTypeObj = {
      Text: workflowData.dataItem.WorkflowType.Text,
      id: workflowData.dataItem.WorkflowType.id
    };
    let selectedNetProfitObj = null;
    let selectedDiscountObj = null;
    let selectedContingencyObj = null;
    let selectedBillingDiviObj = null;
    let selectedBillingDiviObjs = [];
    let selectedBillingRegionObjs = [];
    let selectedRequestResourceRegionObj = null;
    let selectedAllocatedResourceRegionObj = null;
    let selectedBillingRegionObj = null;
    let selectedNetDiscountObj = null;
    let selectedPaymentMethodObj = null;
    let selectedRevenueObj = null;
    let selectedCostVarObj = null;
    let selectedBillingDivi = null;
    let selectedBillingRegion = null;
    let practiceCount = [];
    let billingRegionCount = [];
    let selectedPrevStatusObj = null;
    let selectedCurrentStatusObj = null;
    let selectedEndStatusObj = null;
    let selectedPracticeObj = null;
    let selectedEngagementTypeObj = null;


    for(const item of workflowData.dataItem.WorkflowCriteria){
      if(item.WorkflowParameter.Name === 'Department') {
        practiceCount.push(item)
      }
      if(item.WorkflowParameter.Name === 'Billing Region') {
        billingRegionCount.push(item)
      }
    }

    workflowData.dataItem.WorkflowCriteria.map(item => {
      if (item.WorkflowParameter.Name === 'Department') {
        selectedBillingDiviObj = {
            id: item.Value1,
            Name: workflowData.BillingDivision.find(a => a.id === item.Value1).Name,
            SFCode: workflowData.BillingDivision.find(a => a.id === item.Value1).SFCode
          };
          selectedBillingDiviObjs.push(selectedBillingDiviObj)
      }
      if (item.WorkflowParameter.Name === 'Billing Region') {
        selectedBillingRegionObj = {
            id: item.Value1,
            Name: workflowData.BillingRegion.find(a => a.id === item.Value1).Name
          };
          selectedBillingRegionObjs.push(selectedBillingRegionObj)
      }
      if (item.WorkflowParameter.Name === 'Request Resource Region') {
        selectedRequestResourceRegionObj = {
          id: item.Value1,
          Name: workflowData.ResourceRegion.find(a => a.id === item.Value1).Name
        };
      }
      if (item.WorkflowParameter.Name === 'Allocated Resource Region') {
        selectedAllocatedResourceRegionObj = {
          id: item.Value1,
          Name: workflowData.ResourceRegion.find(a => a.id === item.Value1).Name
        };
      }
      if (item.WorkflowParameter.Name === 'Payment Method') {
        selectedPaymentMethodObj = {
          id: item.Value1,
          Name: workflowData.PaymentMethodAll.find(a => a.id === item.Value1).Name
        };
      }
      let parameterName = '';
      if (item.WorkflowOperator.Name === 'Less than') {
        parameterName = 'Less than ' + item.Value2;
      } else if (item.WorkflowOperator.Name === 'Between') {
        parameterName = 'Between  ' + item.Value1 + ' - ' + item.Value2;
      } else if (item.WorkflowOperator.Name === 'Equal to') {
        parameterName = 'Equal to ' + item.Value2;
      } else if (item.WorkflowOperator.Name === 'Greater than') {
        parameterName = 'Greater than ' + item.Value2;
      } else {
        parameterName = '';
      }
      if (item.WorkflowParameter.Name === 'Revenue') {
        selectedRevenueObj = {
          id: item.WorkflowParameter.id,
          Name: parameterName,
          Value1: item.Value1,
          Value2: item.Value2,
          OperatorId: item.WorkflowOperator.id,
          ParameterCriteriaId: item.ParameterCriteriaId
        };
      }

      if (item.WorkflowParameter.Name === 'Cost Variation') {
        selectedCostVarObj = {
          id: item.WorkflowParameter.id,
          Name: parameterName,
          Value1: item.Value1,
          Value2: item.Value2,
          OperatorId: item.WorkflowOperator.id,
          ParameterCriteriaId: item.ParameterCriteriaId
        };
      }

      if (item.WorkflowParameter.Name === 'Net Profit Margin') {
        selectedNetProfitObj = {
          id: item.WorkflowParameter.id,
          Name: parameterName,
          Value1: item.Value1,
          Value2: item.Value2,
          OperatorId: item.WorkflowOperator.id,
          ParameterCriteriaId: item.ParameterCriteriaId
        };
      }

      if (item.WorkflowParameter.Name === 'Value Discount') {
        selectedDiscountObj = {
          id: item.WorkflowParameter.id,
          Name: parameterName,
          Value1: item.Value1,
          Value2: item.Value2,
          OperatorId: item.WorkflowOperator.id,
          ParameterCriteriaId: item.ParameterCriteriaId
        };
      }

      if (item.WorkflowParameter.Name === 'Contingency') {
        selectedContingencyObj = {
          id: item.WorkflowParameter.id,
          Name: parameterName,
          Value1: item.Value1,
          Value2: item.Value2,
          OperatorId: item.WorkflowOperator.id,
          ParameterCriteriaId: item.ParameterCriteriaId
        };
      }

      if (item.WorkflowParameter.Name === 'Negative Discount') {
        selectedNetDiscountObj = {
          id: item.WorkflowParameter.id,
          Name: parameterName,
          Value1: item.Value1,
          Value2: item.Value2,
          OperatorId: item.WorkflowOperator.id,
          ParameterCriteriaId: item.ParameterCriteriaId
        };
      }

      if (item.WorkflowParameter.Name === 'Previous Status') {
        if(item.Value1){
          selectedPrevStatusObj = {
            id: item.Value1,
            StatusName: workflowData.statuses.find(a => a.id === item.Value1).StatusName,
            Code: workflowData.statuses.find(a => a.id === item.Value1).Code
          };
        }
      }
      if (item.WorkflowParameter.Name === 'Current Status') {
          selectedCurrentStatusObj = {
            id: item.Value1,
            StatusName: workflowData.statuses.find(a => a.id === item.Value1).StatusName,
            Code: workflowData.statuses.find(a => a.id === item.Value1).Code
          };
      }
      if (item.WorkflowParameter.Name === 'End Status') {
          selectedEndStatusObj = {
            id: item.Value1,
            StatusName: workflowData.statuses.find(a => a.id === item.Value1).StatusName,
            Code: workflowData.statuses.find(a => a.id === item.Value1).Code
          };
      }
      if (item.WorkflowParameter.Name === 'Practice') {
        selectedPracticeObj = {
            id: item.Value1,
            Name: workflowData.ResourcePractice.find(a => a.id === item.Value1).Name
          };
      }
      if (item.WorkflowParameter.Name === 'Engagement Type') {
        selectedEngagementTypeObj = {
            id: item.Value1,
            Name: workflowData.EngagementTypes.find(a => a.id === item.Value1).Name
          };
      }
      return null;
    });

    await this.setState({
      value: workflowTypeObj,
      selectedBillingDivision: selectedBillingDiviObj,
      selectedBillingDivisions: selectedBillingDiviObjs,
      selectedBillingRegion: selectedBillingRegionObj,
      selectedBillingRegions: selectedBillingRegionObjs,
      statusPrevious: selectedPrevStatusObj,
      statusEnd: selectedEndStatusObj,
      statusCurrent: selectedCurrentStatusObj,
      selectedRequestResourceRegion: selectedRequestResourceRegionObj,
      selectedAllocatedResourceRegion: selectedAllocatedResourceRegionObj,
      selectedPaymentMethod: selectedPaymentMethodObj,
      selectedRevenueValue: selectedRevenueObj,
      selectedCostVarValue: selectedCostVarObj,
      selectedNetProfitValue: selectedNetProfitObj,
      selectedDiscountValue: selectedDiscountObj,
      selectedNetDiscountValue: selectedNetDiscountObj,
      selectedContingencyValue: selectedContingencyObj,
      workflowText: workflowData.dataItem.Text,
      selectedPractice: selectedPracticeObj,
      selectedEngagementType: selectedEngagementTypeObj
    });
  };

  validateProperty = value => {
    if (value && value.length !== 0) {
      return 'd-none';
    } else if (this.state.isSaveButtonClicked) {
      return 'inline-error';
    } else {
      return 'd-none';
    }
  };
  filterChangeResource = event => {
    const id = event.target.name.slice(-1) * 1 - 1;
    const tempResource = this.state.resource;
    tempResource[id] = this.filterDataResource(event.filter);
    this.setState({
      resource: tempResource
    });
  };

  filterDataResource(filter) {
    const data = this.resourcesAll.slice();
    return filterBy(data, filter);
  }

  filterChange = event => {
    this.setState({
      billingRegion: this.filterData(event.filter)
    });
  };

  filterData(filter) {
    const data = this.billingRegionAll.slice();
    return filterBy(data, filter);
  }

  filterChangeReg = event => {
    this.setState({
      resourceRegion: this.filterDataReg(event.filter)
    });
  };

  filterDataReg(filter) {
    const data = this.resourceRegionAll.slice();
    return filterBy(data, filter);
  }

  filterChangeWorkflowType = event => {
    this.setState({
      workflowTypeData: this.filterDataWorkflowType(event.filter)
    });
  };

  filterDataWorkflowType(filter) {
    const data = this.workflowTypeDataAll.slice();
    return filterBy(data, filter);
  }

  filterChangeDivision = event => {
    this.setState({
      billingDivision: this.filterDataDivision(event.filter)
    });
  };

  filterDataDivision(filter) {
    const data = this.billingDivisionAll.slice();
    return filterBy(data, filter);
  }

  filterChangePractice = event => {
    this.setState({
      selectedpracticeData: this.filterDataPractice(event.filter)
    });
  };

  filterDataPractice(filter) {
    const data = this.practiceAll.slice();
    return filterBy(data, filter);
  }

  filterChangeEngagementType = event => {
    this.setState({
      selectedEngagementType: this.filterDataEngagementType(event.filter)
    });
  };

  filterDataEngagementType(filter) {
    const data = this.engagementTypesAll.slice();
    return filterBy(data, filter);
  }


  filterChangeRevOperator = event => {
    this.setState({
      workflowOperatorRevData: this.filterDataRevOperator(event.filter)
    });
  };

  filterDataRevOperator(filter) {
    const data = this.workflowOperatorRevDataAll.slice();
    return filterBy(data, filter);
  };

  filterDataStatus = event => {
    this.setState({
      statuses: this.filterDataStatusProject(event.filter)
    });
  };

  filterDataStatusProject(filter) {
    const data = this.statusesAll.slice();
    return filterBy(data, filter);
  };



  filterChangeCostVarOperator = event => {
    this.setState({
      workflowOperatorCostVarData: this.filterDataCostVarOperator(event.filter)
    });
  };

  filterDataCostVarOperator(filter) {
    const data = this.workflowOperatorCostVarDataAll.slice();
    return filterBy(data, filter);
  };

  filterChangeNonOperator = event => {
    this.setState({
      workflowOperatorNonData: this.filterDataNonOperator(event.filter)
    });
  };

  filterDataNonOperator(filter) {
    const data = this.workflowOperatorNonDataAll.slice();
    return filterBy(data, filter);
  }

  filterChangeDisOperator = event => {
    this.setState({
      workflowOperatorDisData: this.filterDataDisOperator(event.filter)
    });
  };

  filterDataDisOperator(filter) {
    const data = this.workflowOperatorDisDataAll.slice();
    return filterBy(data, filter);
  }

  filterChangeConOperator = event => {
    this.setState({
      workflowOperatorConData: this.filterDataConOperator(event.filter)
    });
  };

  filterDataConOperator(filter) {
    const data = this.workflowOperatorConDataAll.slice();
    return filterBy(data, filter);
  }

  reDirectToDashboard = event => {
    this.setState({
      toDashboard: true
    });
  };

  toggleDialog = async (message, title) => {
    await this.setState({
      popupVisible: !this.state.popupVisible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleDialogCancel(message) {
    this.setState({
      visible: !this.state.visible
    });

    if (message === 'Success') {
      this.navigateToWorkflowSearch();
    }
  }

  confirmationClose = async () => {
    await this.setState({
      confirmationPopupVisible: !this.state.confirmationPopupVisible
    });
  };

  handleChangeText = async event => {
    this.setState({ [event.target.name]: Number((event.target.value).toFixed(2)) });
  };

  handleChangeOtherDropDown = async event => {
    if (event && event.target && event.target.value) {
      await this.setState({ 
        [event.target.name]: event.target.value, 
        isFormDirty: true, 
        statuses: this.statusesAll 
      });

      if (this.state.value && (this.state.value.id === 6 || this.state.value.id === 1 || this.state.value.id === 7) && event.target.name === 'selectedBillingDivision') {
        this.practiceDivisionMapping();
        await this.setState({ 
          selectedPractice: null
        });
      }
    } 
    else {
      await this.setState({ 
        [event.target.name]: null
      });
      if (event.target.name === 'selectedBillingDivision') {
        await this.setState({ 
          selectedPractice: null
        });
      }
    }
  };

  practiceDivisionMapping = async () => {
    if (this.state.value && (this.state.value.id === 6 || this.state.value.id === 1 || this.state.value.id === 7) && this.state.selectedBillingDivision) {
        let practices = [];
        let practicesForDivision = this.state.practiceDivisionMap.filter(item => item.DepartmentCodeSF === this.state.selectedBillingDivision.SFCode);
        for (const practice of practicesForDivision) {
            let selectedPractice = this.state.practice.filter(item => item.SFCode === practice.PracticeCodeSF);
            practices.push(selectedPractice[0]);
        }
        this.setState({ selectedpracticeData: practices })
    }
};


  handleChange = async event => {
    await this.setState({ 
      value: event.target.value, 
      isFormDirty: true,
      selectedBillingDivision: null,
      selectedBillingRegion: null,
      selectedPractice: null
     });
    await this.validateProperty(event.target.value);
  };

  handleChangeWorkflowName = async event => {
    await this.setState({ workflowText: event.target.value, isFormDirty: true });
    await this.validateProperty(event.target.value);
  };

  validateApprovers = () => {
    let error = '';
    this.state.main.map((mainItem, mainIndex) => {
      return mainItem.mainApproverCardHorizontal.map((horiItem, groupIndex) => {
        return horiItem.approverCardVertical.map((vertItem, vertIndex) => {
          if (!vertItem.approverType) {
            error = 'inline-error';
          }
          return vertItem.approverListVer.map((approver, index) => {
            if (approver['SelectedData'].length > 0) {
              return approver['SelectedData'].map(approverItem => {
                if (approverItem['SelectedId'] > 0) {
                } else {
                  error = 'inline-error';
                }
              });
            } else {
              error = 'inline-error';
            }
          });
        });
      });
    });
    return error;
  };

  validation = () => {
    if (
      this.validateProperty(this.state.workflowText)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.value)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.selectedBillingDivision)
        .toString()
        .includes('error')&& (this.state.value['id'] !== 3 && this.state.value['id'] !== 4 && this.state.value['id'] !== 5 && this.state.value['id'] !== 2 && this.state.value['id'] !== 7 && this.state.value['id'] !== 8)
    ) {
      return false;
    }
    else if (
          this.validateProperty(this.state.selectedBillingDivisions)
              .toString()
              .includes('error')&& (this.state.value['id'] === 5 || this.state.value['id'] === 2)
      ) {
        return false;
    } else if (
      this.validateProperty(this.state.selectedBillingRegion)
        .toString()
        .includes('error')&& (this.state.value['id'] !== 3 && this.state.value['id'] !== 4 && this.state.value['id'] !== 5 && this.state.value['id'] !== 6 && this.state.value['id'] !== 2 && this.state.value['id'] !== 7 && this.state.value['id'] !== 8)
    ) {
      return false;
    }
    else if (
        this.validateProperty(this.state.selectedBillingRegions)
            .toString()
            .includes('error')&& (this.state.value['id'] === 5 || this.state.value['id'] === 2  || this.state.value['id'] === 8)
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.selectedRequestResourceRegion)
        .toString()
        .includes('error')&& this.state.value['id'] == 3
    ) {
      return false;
    }
    else if (
        this.validateProperty(this.state.statusCurrent)
            .toString()
            .includes('error')&& this.state.value['id'] === 5
    ) {
      return false;
    }
    else if (
        this.validateProperty(this.state.statusEnd)
            .toString()
            .includes('error')&& this.state.value['id'] === 5
    ) {
      return false;
    }else if (
      this.validateProperty(this.state.selectedAllocatedResourceRegion)
        .toString()
        .includes('error')&& this.state.value['id'] == 3
    ) {
      return false;
    }else if (
        this.validateProperty(this.state.selectedRevenueValue)
          .toString()
          .includes('error')&& this.state.value['id'] == 1
      ) {
        return false;
      }else if (
      this.validateProperty(this.state.selectedCostVarValue)
        .toString()
        .includes('error')&& this.state.value['id'] == 3
    ) {
      return false;
    }  else if (
        this.validateProperty(this.state.selectedNetProfitValue)
          .toString()
          .includes('error')&& this.state.value['id'] == 1
      ) {
        return false;
      }  else if (
        this.validateProperty(this.state.selectedPractice)
          .toString()
          .includes('error')&& this.state.value['id'] == 6
      ) {
        return false;
      } else if (this.validateApprovers().includes('error')) {
      return false;
    } else {
      return true;
    }
  };

  updateApprovers = async () => {
    await saveWorkflowApproversOnEdit(
      this.state.UpdateApproversList,
      this.props.location.workflowId
    )
      .then(response => {
        this.setState({
          isDisableSave: false,
          saveSucess: true,
          showSuccessImage: true,
          showErrorImage: false,
          loading: false
        });
        this.toggleDialog('The workflow has been successfully updated', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          isDisableSave: false,
          showSuccessImage: false,
          showErrorImage: true,
          loading: false
        });
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({
      loading: true,
      isDisableSave: true
    });
    if (this.props.location.action === 'WK_edit') {
      await this.mapToViewModelUpdatedApprovers();
      this.updateApprovers();
    } else {
      await this.setState({ isSaveButtonClicked: true });
      if (!this.validation()) {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          loading: false,
          isDisableSave: false
        });
        this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
      } else {
        await this.mapToViewModelApproverandGroup();
        const saveData = await this.mapToViewModel();
        await this.setState({ data: saveData });
        await this.doSubmit();
      }
    }
  };

  mapToViewModelUpdatedApprovers = async () => {
    let approversDB = [];
    this.state.main.map((mainItem, mainIndex) => {
      mainItem.mainApproverCardHorizontal.map((horiItem, groupIndex) => {
        horiItem.approverCardVertical.map((vertItem, vertIndex) => {
          vertItem.approverListVer.map((approver, index) => {
            let dbGroupId = 0;
            approver['SelectedData'].map(approverItem => {
              if (approverItem['DBGroupId'] !== 0) {
                dbGroupId = approverItem['DBGroupId'];
              }
              if (approverItem['DBGroupId'] === 0) {
                const approverDBObj = {
                  ResourceId: approverItem['SelectedId'],
                  Sequence: index,
                  ApproverGroupId: dbGroupId
                };
                approversDB = [...approversDB, approverDBObj];
              }
            });
          });
        });
      });
    });
    await this.setState({ UpdateApproversList: approversDB });
  };

  mapToViewModelApproverandGroup = () => {
    let approverGroupDB = [];
    this.state.main.map((mainItem, mainIndex) => {
      mainItem.mainApproverCardHorizontal.map((horiItem, groupIndex) => {
        horiItem.approverCardVertical.map((vertItem, vertIndex) => {
          let approversDB = [];
          vertItem.approverListVer.map((approver, index) => {
            approver['SelectedData'].map(approverItem => {
              const approverDBObj = {
                ResourceId: approverItem['SelectedId'],
                Sequence: index
              };
              approversDB = [...approversDB, approverDBObj];
            });
          });
          const approverGroupItem = {
            GroupId: mainIndex,
            Sequence: groupIndex,
            ApproverTypeId: vertItem.approverType ? vertItem.approverType.id : 0,
            Approvers: approversDB
          };
          approverGroupDB = [...approverGroupDB, approverGroupItem];
        });
      });
    });
    this.setState({ ApproverGroup: approverGroupDB });
  };

  mapToViewModel() {
    let tmpWorkflowCriteria = [];
    let tmpWorkflowCri = [];
    if(this.state.value && this.state.value['id'] == 1) {

      tmpWorkflowCriteria = [
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Department').id,
          OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
          Value1: this.state.selectedBillingDivision ? this.state.selectedBillingDivision['id'] : 0,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Billing Region').id,
          OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
          Value1: this.state.selectedBillingRegion ? this.state.selectedBillingRegion['id'] : 0,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Payment Method').id,
          OperatorId: this.state.selectedPaymentMethod ? this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id : null,
          Value1: this.state.selectedPaymentMethod ? this.state.selectedPaymentMethod['id'] : 0,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Revenue').id,
          OperatorId: this.state.selectedRevenueValue ? this.state.selectedRevenueValue.OperatorId : null,
          Value1: this.state.selectedRevenueValue ? this.state.selectedRevenueValue.Value1 : 0,
          Value2: this.state.selectedRevenueValue ? this.state.selectedRevenueValue.Value2 : 0,
          Value3: 0,
          ParameterCriteriaId: this.state.selectedRevenueValue ? this.state.selectedRevenueValue.ParameterCriteriaId : null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Net Profit Margin').id,
          OperatorId: this.state.selectedNetProfitValue ? this.state.selectedNetProfitValue.OperatorId : null,
          Value1: this.state.selectedNetProfitValue ? this.state.selectedNetProfitValue.Value1 : 0,
          Value2: this.state.selectedNetProfitValue ? this.state.selectedNetProfitValue.Value2 : 0,
          Value3: 0,
          ParameterCriteriaId: this.state.selectedNetProfitValue ? this.state.selectedNetProfitValue.ParameterCriteriaId : null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Value Discount').id,
          OperatorId: this.state.selectedDiscountValue ? this.state.selectedDiscountValue.OperatorId : null,
          Value1: this.state.selectedDiscountValue ? this.state.selectedDiscountValue.Value1 : 0,
          Value2: this.state.selectedDiscountValue ? this.state.selectedDiscountValue.Value2 : 0,
          Value3: 0,
          ParameterCriteriaId: this.state.selectedDiscountValue ? this.state.selectedDiscountValue.ParameterCriteriaId : null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Negative Discount').id,
          OperatorId: this.state.selectedNetDiscountValue ? this.state.selectedNetDiscountValue.OperatorId : null,
          Value1: this.state.selectedNetDiscountValue ? this.state.selectedNetDiscountValue.Value1 : 0,
          Value2: this.state.selectedNetDiscountValue ? this.state.selectedNetDiscountValue.Value2 : 0,
          Value3: 0,
          ParameterCriteriaId: this.state.selectedNetDiscountValue ? this.state.selectedNetDiscountValue.ParameterCriteriaId : null
        }, {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Contingency').id,
          OperatorId: this.state.selectedContingencyValue ? this.state.selectedContingencyValue.OperatorId : null,
          Value1: this.state.selectedContingencyValue ? this.state.selectedContingencyValue.Value1 : 0,
          Value2: this.state.selectedContingencyValue ? this.state.selectedContingencyValue.Value2 : 0,
          Value3: 0,
          ParameterCriteriaId: this.state.selectedContingencyValue ? this.state.selectedContingencyValue.ParameterCriteriaId : null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Practice').id,
          OperatorId: this.state.selectedPractice ? this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id : null,
          Value1: this.state.selectedPractice ? this.state.selectedPractice['id'] : 0,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Engagement Type').id,
          OperatorId: this.state.selectedEngagementType ? this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id : null,
          Value1: this.state.selectedEngagementType ? this.state.selectedEngagementType['id'] : 0,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        }
      ]
    }
    else if(this.state.value && this.state.value['id'] == 2){
      // tmpWorkflowCriteria = [
      //   {
      //     ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Department').id,
      //     OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
      //     Value1: this.state.selectedBillingDivision['id'],
      //     Value2: 0,
      //     Value3: 0,
      //     ParameterCriteriaId: null
      //   },
      //   {
      //     ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Billing Region').id,
      //     OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
      //     Value1: this.state.selectedBillingRegion['id'],
      //     Value2: 0,
      //     Value3: 0,
      //     ParameterCriteriaId: null
      //   }
      // ]
      for(const obj of this.state.selectedBillingDivisions){
        let item =
                {
                  ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Department').id,
                  OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
                  Value1: obj['id'],
                  Value2: 0,
                  Value3: 0,
                  ParameterCriteriaId: null
                }
        tmpWorkflowCri.push(item);
      }

      for(const obj of this.state.selectedBillingRegions){
        let rec =
                {
                  ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Billing Region').id,
                  OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
                  Value1: obj['id'],
                  Value2: 0,
                  Value3: 0,
                  ParameterCriteriaId: null
                }
        tmpWorkflowCri.push(rec);
      }

      for(const obj of tmpWorkflowCri){
        tmpWorkflowCriteria.push(obj)
      }
    }
    else if(this.state.value && this.state.value['id'] == 3){
      tmpWorkflowCriteria = [
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Cost Variation').id,
          OperatorId: this.state.selectedCostVarValue.OperatorId,
          Value1: this.state.selectedCostVarValue.Value1,
          Value2: this.state.selectedCostVarValue.Value2,
          Value3: 0,
          ParameterCriteriaId: this.state.selectedCostVarValue.ParameterCriteriaId
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Request Resource Region').id,
          OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
          Value1: this.state.selectedRequestResourceRegion['id'],
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Allocated Resource Region').id,
          OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
          Value1: this.state.selectedAllocatedResourceRegion['id'],
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        }
      ]
    }
    else if(this.state.value && this.state.value['id'] == 5){
      for(const obj of this.state.selectedBillingDivisions){
           let item =
                   {
                     ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Department').id,
                     OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
                     Value1: obj['id'],
                     Value2: 0,
                     Value3: 0,
                     ParameterCriteriaId: null
                   }
        tmpWorkflowCri.push(item);
      }

      for(const obj of this.state.selectedBillingRegions){
           let rec =
                   {
                     ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Billing Region').id,
                     OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
                     Value1: obj['id'],
                     Value2: 0,
                     Value3: 0,
                     ParameterCriteriaId: null
                   }
        tmpWorkflowCri.push(rec);

      }

      tmpWorkflowCriteria = [

        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Revenue').id,
          OperatorId: this.state.selectedRevenueValue ? this.state.selectedRevenueValue.OperatorId : null,
          Value1: this.state.selectedRevenueValue ? this.state.selectedRevenueValue.Value1 : 0,
          Value2: this.state.selectedRevenueValue ? this.state.selectedRevenueValue.Value2 : 0,
          Value3: 0,
          ParameterCriteriaId: this.state.selectedRevenueValue ? this.state.selectedRevenueValue.ParameterCriteriaId : null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Previous Status').id,
          OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
          Value1: this.state.statusPrevious ? this.state.statusPrevious['id'] : 0,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Current Status').id,
          OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
          Value1: this.state.statusCurrent ? this.state.statusCurrent['id'] : 0,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'End Status').id,
          OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
          Value1: this.state.statusEnd ? this.state.statusEnd['id'] : 0,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        },
      ]
      for(const obj of tmpWorkflowCri){
        tmpWorkflowCriteria.push(obj)
      }

      tmpWorkflowCriteria = tmpWorkflowCriteria.filter(criteria => criteria.OperatorId !== null);

    }
    else if(this.state.value['id'] == 6){
      tmpWorkflowCriteria = [
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Department').id,
          OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
          Value1: this.state.selectedBillingDivision['id'],
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Practice').id,
          OperatorId: this.state.selectedPractice ? this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id : null,
          Value1: this.state.selectedPractice ? this.state.selectedPractice['id'] : 0,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        }
      ]
    }
    else if(this.state.value['id'] == 7){
      tmpWorkflowCriteria = [
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Department').id,
          OperatorId: this.state.selectedBillingDivision ? this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id : null,
          Value1: this.state.selectedBillingDivision ? this.state.selectedBillingDivision['id'] : null,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Practice').id,
          OperatorId: this.state.selectedPractice ? this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id : null,
          Value1: this.state.selectedPractice ? this.state.selectedPractice['id'] : 0,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        },
        {
          ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Billing Region').id,
          OperatorId: this.state.selectedBillingRegion ? this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id : null,
          Value1: this.state.selectedBillingRegion ? this.state.selectedBillingRegion['id'] : 0,
          Value2: 0,
          Value3: 0,
          ParameterCriteriaId: null
        }
      ]
    } else if(this.state.value['id'] == 8){
      for(const obj of this.state.selectedBillingRegions){
        let rec =
          {
            ParameterId: this.state.workflowParameterData.find(a => a.Name === 'Billing Region').id,
            OperatorId: this.state.workflowOperatorData.find(a => a.Name === 'Equal to').id,
            Value1: obj['id'],
            Value2: 0,
            Value3: 0,
            ParameterCriteriaId: null
          }
        tmpWorkflowCri.push(rec);
      }
      for(const obj of tmpWorkflowCri){
        tmpWorkflowCriteria.push(obj)
      }
    }

    tmpWorkflowCriteria = tmpWorkflowCriteria.filter(criteria => criteria.OperatorId !== null);

    return {
      WorkflowTypeId: this.state.value ? this.state.value['id'] : 0,
      Text: this.state.workflowText,
      IsActive: true,
      WorkflowCriteria: tmpWorkflowCriteria,
      ApproverGroup: this.state.ApproverGroup
    };
  }

  doSubmit = async () => {
    console.log("UserAction",this.props.location.action);
    if (this.props.location.action === 'edit') {
      console.log("Workflow Object for Edit",this.state.data, " ,workflowId - ",this.state.workflowId);
      await updateWorkflow(this.state.data, this.state.workflowId)
        .then(response => {
          this.setState({
            isDisableSave: true,
            saveSucess: true,
            showSuccessImage: true,
            showErrorImage: false,
            loading: false
          });
          this.toggleDialog('The workflow has been successfully updated', 'Success');
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.setState({
            isDisableSave: false,
            showSuccessImage: false,
            showErrorImage: true,
            loading: false
          });
          this.toggleDialog(error.response.data, 'Error');
        });
    } else {
      console.log("Workflow Object",this.state.data);
      await saveWorkflow(this.state.data)
        .then(response => {
          console.log("response987",response);
          this.setState({
            isDisableSave: true,
            saveSucess: true,
            showSuccessImage: true,
            showErrorImage: false,
            loading: false
          });
          this.toggleDialog('The workflow has been successfully created', 'Success');
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.setState({
            isDisableSave: false,
            showSuccessImage: false,
            showErrorImage: true,
            loading: false
          });
          this.toggleDialog(error.response.data, 'Error');
        });
    }
  };

  addCssClassfornonProfitBetween = () => {
    if (
      this.state.nonProfitSelectedValue &&
      this.state.nonProfitSelectedValue['Name'] === 'Between'
    )
      return 'col-md-6';
    else return 'col-md-6 d-none';
  };
  addCssClassfordiscountBetween = () => {
    if (this.state.discountSelectedValue && this.state.discountSelectedValue['Name'] === 'Between')
      return 'col-md-6';
    else return 'col-md-6 d-none';
  };
  addCssClassforNetdiscountBetween = () => {
    if (this.state.netDiscountSelectedValue && this.state.netDiscountSelectedValue['Name'] === 'Between')
      return 'col-md-6';
    else return 'col-md-6 d-none';
  };
  addCssClassforcontingencyBetween = () => {
    if (
      this.state.contingencySelectedValue &&
      this.state.contingencySelectedValue['Name'] === 'Between'
    )
      return 'col-md-6';
    else return 'col-md-6 d-none';
  };
  cardComponent = () => {
    return (
      <WorkflowApprovers
        Approvers={this.state.resourcesAll}
        Card={this.state.main}
        onAddParallel={this.onAddParallel}
        index={this.state.index}
        onRemoveParallel={this.onRemoveParallel}
        OnMinusApprovers={this.OnMinusApprovers}
        OnPlusApprovers={this.OnPlusApprovers}
        filterChangeApprover={this.filterChangeApprover}
        handleChangeDropDown={this.handleChangeDropDown}
        isEditMode={this.state.isEditMode}
        isDuplicateMode={this.state.isDuplicateMode}
        approvalTypes={this.state.approvalTypes}
        handleOnChangeApprovalType={this.handleOnChangeApprovalType}
      />
    );
  };

  handleOnChangeApprovalType = async event => {
    if (event && event.target && event.target.value) {

      const splitName = event.target.name.split('/');
      this.state.main[splitName[0]].mainApproverCardHorizontal[splitName[1]]
        .approverCardVertical[splitName[2]].approverType = event.target.value;

      await this.mapToViewModelApproverandGroup();
      const saveData = await this.mapToViewModel();
      await this.setState({ data: saveData });

    }
  };

  handleChangeDropDown = async event => {
    if (event && event.target && event.target.value) {

      const splitName = event.target.name.split('/');
      const main = [...this.state.main];
      let selectData = [];
      event.target.value.map(item => {
        let DBGId = 0;
        if (item['DBGroupId']) {
          DBGId = item['DBGroupId'];
        }
        selectData = [
          ...selectData,
          { SelectedId: item['id'], SelectedValue: item['Name'], DBGroupId: DBGId }
        ];
      });
      if (this.state.isEditMode) {
        main[splitName[0]].mainApproverCardHorizontal[splitName[1]].approverCardVertical[
          splitName[2]
          ].approverListVer[splitName[3]]['SelectedData'] = selectData;

        this.setState({
          main: main
        });

      } else {
        main[splitName[0]].mainApproverCardHorizontal[splitName[1]].approverCardVertical[
          splitName[2]
          ].approverListVer[splitName[3]]['SelectedData'] = selectData;

        this.setState({
          main: main
        });
      }
    }
  };

  filterChangeApprover = event => {
    const splitName = event.target.name.split('/');

    const filteredApprovers = this.filterDataApprover(event.filter);
    const main = [...this.state.main];

    main[splitName[0]].mainApproverCardHorizontal[splitName[1]].approverCardVertical[
      splitName[2]
      ].approverListVer[splitName[3]]['ApproverData'] = filteredApprovers;

    this.setState({
      main: main
    });
  };

  filterDataApprover(filter) {
    const data = this.resourcesAll.slice();
    return filterBy(data, filter);
  }

  OnPlusApprovers = async (index, indevert, indexPar) => {
    const newApprover = {
      approverListVer: [
        ...this.state.main[index].mainApproverCardHorizontal[indevert].approverCardVertical[
          indexPar
          ].approverListVer,
        {
          ApproverData: this.state.resource1,
          SelectedData: []
        }
      ],
      approverType: this.state.main[index].mainApproverCardHorizontal[indevert].approverCardVertical[indexPar].approverType
    };
    const newApproverToVertical = {
      approverCardVertical: [newApprover]
    };

    const main = [...this.state.main];
    main[index].mainApproverCardHorizontal[indevert] = newApproverToVertical;

    await this.setState({
      main: main
    });
  };

  OnMinusApprovers = async (index, indevert, indexPar) => {

    const newApproverCount =
      this.state.main[index].mainApproverCardHorizontal[indevert].approverCardVertical[indexPar]
        .approverListVer.length - 1;

    if (newApproverCount === 0) {
      return false;
    }

    const main = [...this.state.main];
    this.state.main.filter((item, indexMain) => {
      if (indexMain === index) {
        item.mainApproverCardHorizontal.filter((innerItem, inde) => {
          if (inde === indevert) {
            let approverItem = [];
            let appType = null
            innerItem.approverCardVertical.filter((innerApprover, index) => {
              appType = innerApprover.approverType
              innerApprover.approverListVer.filter((inApprover, indexApp) => {
                if (indexApp !== newApproverCount) {
                  approverItem = [...approverItem, inApprover];
                }
              });
            });
            const newApprover = {
              approverListVer: approverItem,
              approverType: appType
            };
            const newApproverToVertical = {
              approverCardVertical: [newApprover]
            };
            main[index].mainApproverCardHorizontal[indevert] = newApproverToVertical;
          }
        });
      }
    });

    await this.setState({
      main: main
    });
  };

  confirmationOk = async () => {
    await this.setState({
      confirmationPopupVisible: false
    });
    const main = [...this.state.main];
    this.state.main.filter((item, index) => {
      let vertItem = [];
      if (index === this.state.cardRemoveMainIndex) {
        item.mainApproverCardHorizontal.filter((innerItem, inde) => {
          if (inde !== this.state.cardRemoveHoriIndex) {
            vertItem = [...vertItem, innerItem];
          }
          const newMainApproverCardHorizontal = {
            mainApproverCardHorizontal: vertItem
          };
          main[index] = newMainApproverCardHorizontal;
        });
      }
    });

    await this.setState({
      main: main
    });
    const newMain = await this.state.main.filter(
      (mainItem, i) => mainItem.mainApproverCardHorizontal.length !== 0
    );
    await this.setState({
      main: newMain
    });
  };

  onRemoveParallel = async (ind, indexVer) => {
    await this.setState({ cardRemoveMainIndex: ind, cardRemoveHoriIndex: indexVer });
    this.confirmationClose();
  };

  OnLevelClick = async () => {
    const newMainApproverCardHorizontal = {
      mainApproverCardHorizontal: [
        {
          approverCardVertical: [
            {
              approverListVer: [
                {
                  ApproverData: this.state.resource1,
                  SelectedData: []
                }
              ]
            }
          ]
        }
      ]
    };
    const newlist = [...this.state.main, newMainApproverCardHorizontal];
    await this.setState({
      main: newlist
    });
  };

  onAddParallel = async index => {
    const newApproverToVertical = {
      approverCardVertical: [
        {
          approverListVer: [
            {
              ApproverData: this.state.resource1,
              SelectedData: []
            }
          ]
        }
      ]
    };
    const newlist = [...this.state.main[index].mainApproverCardHorizontal, newApproverToVertical];
    const newMainApproverCardHorizontal = {
      mainApproverCardHorizontal: newlist
    };

    const main = [...this.state.main];
    main[index] = newMainApproverCardHorizontal;
    await this.setState({
      main: main
    });
  };
  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleDialogCancel();
    } else {
      this.navigateBack(event);
    }
  };

  navigateBack = event => {
    event.preventDefault();
//this.props.history.goBack();
    this.navigateToWorkflowSearch();
  };

  navigateToWorkflowSearch = event => {
    this.setState({
      toSearchWorkFlow: true
    });
  };

  toggleWorkflowNameToolTip = () => {
    this.setState({
      workflowNameToolTipOpen: !this.state.workflowNameToolTipOpen
    });
  };

  render() {
    if (this.state.toSearchWorkFlow === true) {
      return <Redirect to="/admin/workflow/workflow"/>;
    }
    return (
      <React.Fragment>
        <div className="main-card">
          <div className="main-heading">Workflow Details</div>
          <div class="row">
            <div class="col-md-3">
              <div class="d-block">
                <label className="mandatory">Workflow Name:</label>
              </div>
              <div className="d-block">
                <div className="" id="workflowNameToolTip">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="workflowText"
                    required={true}
                    maxLength="50"
                    value={this.state.workflowText}
                    onChange={this.handleChangeWorkflowName}
                  />
                  <span className={this.validateProperty(this.state.workflowText)}>
                Please enter workflow name
              </span>
                  {this.state.workflowText ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.workflowNameToolTipOpen}
                      target="workflowNameToolTip"
                      toggle={this.toggleWorkflowNameToolTip}
                    >
                      {this.state.workflowText}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="main-seperator"/>

          <div className="main-heading">Parameter Definition</div>
          <div className="row">

            <div className="col-md-3">
              <div className="d-block">
                <label className="mandatory">Workflow Type:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    placeholder="Please select"
                    name="workflowType"
                    data={this.state.workflowTypeData}
                    textField="Text"
                    dataItemKey="id"
                    onChange={this.handleChange}
                    required={true}
                    value={this.state.value}
                    filterable={true}
                    onFilterChange={this.filterChangeWorkflowType}
                    disabled={this.state.isEditMode}
                  />
                  <span className={this.validateProperty(this.state.value)}>
                Please select workflow type
              </span>
                </div>
              </div>
            </div>
            {this.state.value && this.state.value.id !== 8 && (
            <div className="col-md-3">
              {this.state.value && (this.state.value.id !== 2 && this.state.value.id !== 3 && this.state.value.id !== 4 && this.state.value.id !== 5) && (<div>
              <div className="d-block">
              {this.state.value && (this.state.value.id !== 7) ? (
                <label className="mandatory">Department:</label>
              ) :
                <label>Department:</label>
              }
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    className="text-break"
                    placeholder="Please select"
                    name="selectedBillingDivision"
                    data={this.state.billingDivision}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleChangeOtherDropDown}
                    required={(this.state.value && this.state.value.id === 7) ? false : true}
                    value={this.state.selectedBillingDivision}
                    filterable={true}
                    onFilterChange={this.filterChangeDivision}
                  />
                  {this.state.value && (this.state.value.id !== 7 ) && (
                    <span className={this.validateProperty(this.state.selectedBillingDivision)}>
                      Please select Department
                    </span>
                  )}
                  </div>
                </div>
              </div>)}

              {this.state.value && (this.state.value.id === 5 || this.state.value.id === 2) && (<div className="workflow-parameter-practice">
                <div className="d-block">
                  <label className="mandatory">Department:</label>
                </div>
                <div className="d-block">
                  <div className="">
                    <MultiSelect
                        className="text-break"
                        data={this.state.billingDivision}
                        textField="Name"
                        dataItemKey="id"
                        placeholder="Please Select"
                        value={this.state.selectedBillingDivisions}
                        name="selectedBillingDivisions"
                        onChange={this.handleChangeOtherDropDown}
                        filterable={true}
                        onFilterChange={this.filterChangeDivision}
                        required={true}
                    />
                    <span className={this.validateProperty(this.state.selectedBillingDivisions)}>
                Please select Department
              </span>
                  </div>
                </div>
              </div>)}
            </div>
          )}

            {this.state.value && (this.state.value.id === 6 || this.state.value.id === 1 || this.state.value.id === 7) && (<div className="col-md-3 workflow-parameter-practice">
              <div className="d-block">
              {this.state.value && (this.state.value.id === 6 ) ? (
                <label className="mandatory">Practice:</label>
              ) :
                <label>Practice:</label>
              }
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    className="text-break"
                    data={this.state.selectedpracticeData}
                    textField="Name"
                    dataItemKey="id"
                    placeholder="Please Select"
                    value={this.state.selectedPractice}
                    name="selectedPractice"
                    onChange={this.handleChangeOtherDropDown}
                    filterable={true}
                    onFilterChange={this.filterChangePractice}
                    disabled={this.state.selectedBillingDivision ? false : true}
                  />
                  {this.state.value && (this.state.value.id === 6 ) && (
                    <span className={this.validateProperty(this.state.selectedPractice)}>
                      Please select Practice
                    </span>
                  )}
                </div>
              </div>
            </div>)}

            <div className="col-md-3">
              {this.state.value && (this.state.value.id !== 2 && this.state.value.id !== 3 && this.state.value.id !== 4 && this.state.value.id !== 5 && this.state.value.id !== 6 && this.state.value.id !== 8) && (<div>
              <div className="d-block">
              {this.state.value && (this.state.value.id !== 7) ? (
                <label className="mandatory">Billing Region:</label>
              ) :
                <label>Billing Region:</label>
              }
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    className="text-break"
                    placeholder="Please select"
                    onChange={this.handleChangeOtherDropDown}
                    name="selectedBillingRegion"
                    data={this.state.billingRegion}
                    textField="Name"
                    dataItemKey="id"
                    required={(this.state.value && this.state.value.id === 7) ? false : true}
                    value={this.state.selectedBillingRegion}
                    filterable={true}
                    onFilterChange={this.filterChange}
                  />
                {this.state.value && (this.state.value.id !== 7 ) && (
                  <span className={this.validateProperty(this.state.selectedBillingRegion)}>
                    Please select billing region
                  </span>
                )}
                </div>
              </div>
              </div>)}

              {this.state.value && (this.state.value.id === 5 || this.state.value.id === 2 || this.state.value.id === 8) && (<div className="workflow-parameter-billing">
                <div className="d-block">
                  <label className="mandatory">Billing Region:</label>
                </div>
                <div className="d-block">
                  <div className="">
                    <MultiSelect
                        className="text-break"
                        data={this.state.billingRegion}
                        textField="Name"
                        dataItemKey="id"
                        placeholder="Please Select"
                        value={this.state.selectedBillingRegions}
                        name="selectedBillingRegions"
                        onChange={this.handleChangeOtherDropDown}
                        filterable={true}
                        onFilterChange={this.filterChange}
                        required={true}
                    />
                    <span className={this.validateProperty(this.state.selectedBillingRegions)}>
                Please select billing region
              </span>
                  </div>
                </div>
              </div>)}
            </div>
          </div>


          {this.state.value && this.state.value.id === 3 && (
            <div className="row" >
              <div className="col-md-3">
                <div className="d-block">
                  <label className="mandatory">Cost Variation:</label>
                </div>
                <div className="d-block">
                  <div className="">
                    <ComboBox
                      placeholder="Please select"
                      data={this.state.workflowCostVarParameters}
                      textField="Name"
                      dataItemKey="id"
                      onChange={this.handleChangeOtherDropDown}
                      required={true}
                      filterable={true}
                      onFilterChange={this.filterChangeCostVarOperator}
                      name="selectedCostVarValue"
                      value={this.state.selectedCostVarValue}
                      disabled={!this.state.value}
                    />
                    {<span className={this.validateProperty(this.state.selectedCostVarValue)}>
                Please select cost variation
              </span>}
                  </div>
                </div>
              </div>

              {/* <div className="col-md-1"></div> */}

              <div className="col-md-3">
                <div className="d-block">
                  <label className="mandatory">Request Resource Region:</label>
                </div>
                <div className="d-block">
                  <div className="">
                    <ComboBox
                      placeholder="Please select"
                      onChange={this.handleChangeOtherDropDown}
                      name="selectedRequestResourceRegion"
                      data={this.state.resourceRegion}
                      textField="Name"
                      dataItemKey="id"
                      required={true}
                      value={this.state.selectedRequestResourceRegion}
                      filterable={true}
                      onFilterChange={this.filterChangeReg}
                    />
                    {<span className={this.validateProperty(this.state.selectedRequestResourceRegion)}>
                Please select resource request region
              </span>}
                  </div>
                </div>
              </div>

              {/* <div className="col-md-1"></div> */}

              <div className="col-md-3">
                <div className="row">
                    <div className="col-md-12">
                      <div className="d-block">
                        <label className="mandatory">Allocated Resource Region:</label>
                      </div>
                    </div>
                </div>
                <div className="row mt-0">
                    <div className="col-md-12">
                      <div className="d-block">
                          <div className="">
                            <ComboBox
                              placeholder="Please select"
                              onChange={this.handleChangeOtherDropDown}
                              name="selectedAllocatedResourceRegion"
                              data={this.state.resourceRegion}
                              textField="Name"
                              dataItemKey="id"
                              required={true}
                              value={this.state.selectedAllocatedResourceRegion}
                              filterable={true}
                              onFilterChange={this.filterChangeReg}
                            />
                            <span className={this.validateProperty(this.state.selectedAllocatedResourceRegion)}>
                              Please select allocated resource region
                            </span>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          )}

          {this.state.value && this.state.value.id === 1 && (
          <div className="row" >
            <div className="col-md-3">
              <div className="d-block">
                <label>Billing Method:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    onChange={this.handleChangeOtherDropDown}
                    placeholder="Please select"
                    data={this.state.paymentMethods}
                    textField="Name"
                    dataItemKey="id"
                    name="selectedPaymentMethod"
                    value={this.state.selectedPaymentMethod}
                  />
                  {/* <span className={this.validateProperty(this.state.selectedPaymentMethod)}>
                Please select billing method
              </span> */}
                </div>
              </div>
            </div>

            {/* <div className="col-md-1"></div> */}

            <div className="col-md-3">
              <div className="d-block">
                <label className="mandatory">Revenue:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    placeholder="Please select"
                    data={this.state.workflowRevenueParameters}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleChangeOtherDropDown}
                    required={true}
                    filterable={true}
                    onFilterChange={this.filterChangeRevOperator}
                    name="selectedRevenueValue"
                    value={this.state.selectedRevenueValue}
                    disabled={!this.state.value}
                  />
                  {<span className={this.validateProperty(this.state.selectedRevenueValue)}>
                Please select revenue
              </span>}
                </div>
              </div>
            </div>

            {/* <div className="col-md-1"></div> */}

            <div className="col-md-3">
              <div className="d-block">
                <label className="mandatory">Net Profit Margin:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    placeholder="Please select"
                    data={this.state.workflownetProfitMarginParameters}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleChangeOtherDropDown}
                    required={true}
                    filterable={true}
                    onFilterChange={this.filterChangeRevOperator}
                    name="selectedNetProfitValue"
                    value={this.state.selectedNetProfitValue}
                    disabled={!this.state.value}
                  />
                  <span className={this.validateProperty(this.state.selectedNetProfitValue)}>
                Please select net profit margin value
              </span>
                </div>
              </div>
            </div>

            {this.state.value && (this.state.value.id === 1) && (<div className="col-md-3 workflow-parameter-practice">
              <div className="d-block">
                <label>Engagement Type:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    className="text-break"
                    data={this.state.engagementTypes}
                    textField="Name"
                    dataItemKey="id"
                    placeholder="Please Select"
                    value={this.state.selectedEngagementType}
                    name="selectedEngagementType"
                    onChange={this.handleChangeOtherDropDown}
                    filterable={true}
                    onFilterChange={this.filterChangeEngagementType}
                  />
                </div>
              </div>
            </div>)}
            <div className="col-md-1"></div>
          </div>
          )}

          {this.state.value && this.state.value.id === 1 && (
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                <label>Value Discount:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    placeholder="Please select"
                    data={this.state.workflowDiscountsParameters}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleChangeOtherDropDown}
                    filterable={true}
                    onFilterChange={this.filterChangeRevOperator}
                    name="selectedDiscountValue"
                    value={this.state.selectedDiscountValue}
                    disabled={!this.state.value}
                  />
                  {/* <span className={this.validateProperty(this.state.selectedDiscountValue)}>
                Please select value discount
              </span> */}
                </div>
              </div>
            </div>
            {/* <div className="col-md-1"></div> */}
            <div className="col-md-3">
              <div className="d-block">
                <label>Negative Discount:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    placeholder="Please select"
                    data={this.state.workflowNetDiscountsParameters}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleChangeOtherDropDown}
                    filterable={true}
                    onFilterChange={this.filterChangeRevOperator}
                    name="selectedNetDiscountValue"
                    value={this.state.selectedNetDiscountValue}
                    disabled={!this.state.value}
                  />
                  {/* <span className={this.validateProperty(this.state.selectedNetDiscountValue)}>
                Please select negative discount
              </span> */}
                </div>
              </div>
            </div>

            {/* <div className="col-md-1"></div> */}

            <div className="col-md-3">
              <div className="d-block">
                <label>Contingency:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    placeholder="Please select"
                    data={this.state.workflowContingencyParameters}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleChangeOtherDropDown}
                    filterable={true}
                    onFilterChange={this.filterChangeRevOperator}
                    name="selectedContingencyValue"
                    value={this.state.selectedContingencyValue}
                    disabled={!this.state.value}
                  />
                  {/* <span className={this.validateProperty(this.state.selectedContingencyValue)}>
                Please select contingency
              </span> */}
                </div>
              </div>
            </div>
          </div>
          )}

          {this.state.value && this.state.value.id === 5 && (
              <div className="row">
                <div className="col-md-3">
                  <div className="d-block">
                    <label>Revenue:</label>
                  </div>
                  <div className="d-block">
                    <div className="">
                      <ComboBox
                          placeholder="Please select"
                          data={this.state.workflowRevenueParameters}
                          textField="Name"
                          dataItemKey="id"
                          onChange={this.handleChangeOtherDropDown}
                          filterable={true}
                          onFilterChange={this.filterChangeRevOperator}
                          name="selectedRevenueValue"
                          value={this.state.selectedRevenueValue}
                          disabled={!this.state.value}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>

                <div className="col-md-1"></div>

              </div>
          )}

          {this.state.value && this.state.value.id === 5 && (
              <div className="row">
                <div className="col-md-3">
                  <div className="d-block">
                    <label>Previous Status:</label>
                  </div>
                  <div className="d-block">
                    <div className="">
                      <ComboBox
                          placeholder="Please select"
                          data={this.state.statuses}
                          textField="StatusName"
                          dataItemKey="id"
                          onChange={this.handleChangeOtherDropDown}
                          filterable={true}
                          onFilterChange={this.filterDataStatus}
                          name="statusPrevious"
                          value={this.state.statusPrevious}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>

                <div className="col-md-1"></div>

              </div>
          )}

          {this.state.value && this.state.value.id === 5 && (
              <div className="row">
                <div className="col-md-3">
                  <div className="d-block">
                    <label className="mandatory">Current Status:</label>
                  </div>
                  <div className="d-block">
                    <div className="">
                      <ComboBox
                          placeholder="Please select"
                          data={this.state.statuses}
                          textField="StatusName"
                          dataItemKey="id"
                          onChange={this.handleChangeOtherDropDown}
                          required={true}
                          filterable={true}
                          onFilterChange={this.filterDataStatus}
                          name="statusCurrent"
                          value={this.state.statusCurrent}
                      />
                      {<span className={this.validateProperty(this.state.statusCurrent)}>
                Please select current status
              </span>}
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>

                <div className="col-md-1"></div>

              </div>
          )}

          {this.state.value && this.state.value.id === 5 && (
              <div className="row">
                <div className="col-md-3">
                  <div className="d-block">
                    <label className="mandatory">End Status:</label>
                  </div>
                  <div className="d-block">
                    <div className="">
                      <ComboBox
                          placeholder="Please select"
                          data={this.state.statuses}
                          textField="StatusName"
                          dataItemKey="id"
                          onChange={this.handleChangeOtherDropDown}
                          required={true}
                          filterable={true}
                          onFilterChange={this.filterDataStatus}
                          name="statusEnd"
                          value={this.state.statusEnd}
                      />
                      {<span className={this.validateProperty(this.state.statusEnd)}>
                Please select end status
              </span>}
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>

                <div className="col-md-1"></div>

              </div>
          )}



          <div className="main-seperator"/>
          <div className="main-heading">Assign Approvers</div>
          <div className="row">
            <div className="col-md-12">
              <Button
                type="button"
                onClick={this.OnLevelClick}
                primary={true}
                iconClass="k-icon k-i-plus"
                className="rounded-btn-text"
              >
                Add Sequential
              </Button>
            </div>
          </div>
          {this.cardComponent()}
          <div className="row">
            <div className="col-md-12 btn-align-right">
              <PermissibleRender
                userPermissions={window.USER_PERMISSION}
                requiredPermissions={['WF_CUS']}
              >
                <Button
                  primary={true}
                  onClick={this.handleSubmit}
                  disabled={this.state.isDisableSave}
                >
                  Save
                </Button>
              </PermissibleRender>
              <Button onClick={this.onClickCancel}> Cancel</Button>
            </div>
          </div>
          <Loader loading={this.state.loading}/>
        </div>

        <div>
          {this.state.popupVisible === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

              {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success"/>
                </div>
              )}

              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image"/>
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

              <DialogActionsBar>
                {this.state.showSuccessImage === true && (
                  <button className="k-button modal-primary" onClick={this.navigateToWorkflowSearch}>
                    OK
                  </button>
                )}

                {this.state.showErrorImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleDialog}>
                    OK
                  </button>
                )}
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.visible && (
            <Dialog title={'Please Confirm'} onClose={this.toggleDialogCancel}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                This form contains unsaved data. Do you want to close it?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.toggleDialogCancel}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.navigateBack}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <div>

          {this.state.confirmationPopupVisible && (
            <Dialog title={'Please Confirm'} onClose={this.confirmationClose}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                This card may contain unsaved data. Do you want to close it?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.confirmationClose}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.confirmationOk}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

      </React.Fragment>
    );
  }
}

export default WorkflowCreate;
