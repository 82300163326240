import React, { Component } from 'react';

class DeleteActionCell extends Component {
    render() {
        const { OnDeleteHoliday } = this.props;
        const calendar = this.props.dataItem;
        return (
            <td className="action-buttons">
                <div className="rounded-link">
                    <span type="submit" className="k-icon k-i-delete" onClick={() => OnDeleteHoliday(this.props.dataItem)} title="Delete" />
                </div>
            </td>
        );
    }
}

export default DeleteActionCell;
