import { getApproverTypeByIdIntegrated } from '../../../integration/MasterDataService';
import * as loggerService from '../../../integration/LoggerService';
import { LOG_TYPES } from '../../../integration/IntegrationEnums';

let main = [
  {
    mainApproverCardHorizontal: [
      {
        approverCardVertical: [
          {
            approverListVer: [
              {
                ApproverData: [],
                SelectedData: [{ SelectedId: 0, SelectedValue: '', DBGroupId: 0 }]
              }
            ]
          }
        ]
      }
    ]
  }
];

export function mapToApproverModel(data, allApproverData) {
  let ApproverCardHorizontal = [];
  let groupId;
  data.ApproverGroup.map((approverGroupItem, groupIndex) => {
    let approverType = null;
    if (groupId !== approverGroupItem.GroupId) {
      approverType = approverGroupItem.ApproverType;
      let approverSequenceId;
      let selectedData = [];
      approverGroupItem.Approvers.map((vertItem, vertIndex) => {
        if (approverSequenceId !== vertItem.Sequence) {
          approverSequenceId = vertItem.Sequence;
          selectedData = [
            ...selectedData,
            {
              ApproverData: allApproverData,
              SelectedData: [
                {
                  SelectedId: vertItem.ResourceId,
                  SelectedValue: vertItem.Resource.Name,
                  DBGroupId: approverGroupItem.id
                }
              ]
            }
          ];
        } else {
          selectedData[approverSequenceId].SelectedData.push({
            SelectedId: vertItem.ResourceId,
            SelectedValue: vertItem.Resource.Name,
            DBGroupId: approverGroupItem.id
          });
        }
        return null;
      });
      groupId = approverGroupItem.GroupId;
      ApproverCardHorizontal.push({
        mainApproverCardHorizontal: [
          {
            approverCardVertical: [
              {
                approverListVer: selectedData,
                approverType: approverType
              }
            ]
          }
        ]
      });
    } else {
      let approverSequenceId;
      let selectedData = [];
      approverType = approverGroupItem.ApproverType
      approverGroupItem.Approvers.map((vertItem, vertIndex) => {
        if (approverSequenceId !== vertItem.Sequence) {
          approverSequenceId = vertItem.Sequence;
          selectedData = [
            ...selectedData,
            {
              ApproverData: allApproverData,
              SelectedData: [
                {
                  SelectedId: vertItem.ResourceId,
                  SelectedValue: vertItem.Resource.Name,
                  DBGroupId: approverGroupItem.id
                }
              ]
            }
          ];
        } else {
          selectedData[approverSequenceId].SelectedData.push({
            SelectedId: vertItem.ResourceId,
            SelectedValue: vertItem.Resource.Name,
            DBGroupId: approverGroupItem.id
          });
        }
        return null;
      });

      ApproverCardHorizontal[groupId].mainApproverCardHorizontal.push({
        approverCardVertical: [
          {
            approverListVer: selectedData,
            approverType: approverType
          }
        ]
      });
    }
    return null;
  });
  main = ApproverCardHorizontal;
  return main;
}
