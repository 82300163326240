import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import 'moment-timezone';
import moment from 'moment'

import {
    getCostCardDetails,
    updateCostCard,
    saveCostCard
} from "./CostCardService";
import {
    getMultiYearInformationIntegrated,
    getRegionInfoIntegrated,
    saveCostCardIncrementsIntegrated
} from '../../integration/MasterDataService';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Redirect } from 'react-router';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { roundNumber } from "../../integration/CommonUtils";
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import $ from "jquery";
import { getAllProjects } from "../../projectManagement/project/ProjectService";
import { filterBy } from "@progress/kendo-data-query";

class EditCostCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            skip: 0,
            take: 20,

            showMessageDialog: false,
            dialogMessage: '',
            dialogTitle: '',

            designationsList: [],
            costP: "",

            data: [],
            editID: null,
            costCardDetails: [],
            designationDetails: [],
            costCardName: '',
            costCardValidityTo: null,
            costCardValidityFrom: null,

            regions: [],
            projects: [],
            selectedBillingRegion: null,
            selectedResourceRegion: null,
            selectedProject: null,
            billingRegions: [],
            resourceRegions: [],
            disableBillingregion: false,
            disableResourceRegion: false,
            disableProject: false,

            visible: false,
            isErrorMessageVisible: false,
            toRegion: false,
            showCancelConfirmation: false,

            isFormDirty: false,
            showConfirmDialog: false,
            confirmDialogMessage: '',
            confirmDialogAction: null,
            saveButtonDisabled: false,
            minStartDate: null
        };
    }

    async componentDidMount() {

        $(document).ready(function () {
            $("div.title-remove > span > span > span:last-child").removeAttr("title");
        });

        this.setHeaderTitle();
        if (this.props.location.action === 'view') {
            const costCardId = this.props.location.costcard.id;
            const action = 'view';
            this.costCardDetails(costCardId, action);
            this.setState({
                disableBillingregion: true,
                disableResourceRegion: true,
                disableProject: true
            });
        }
        if (this.props.location.action === 'edit') {
            const costCardId = this.props.location.costcard.id;
            const action = 'edit';
            this.costCardDetails(costCardId, action);
            this.setState({
                disableBillingregion: true,
                disableResourceRegion: true,
                disableProject: true
            });
        }
        if (this.props.location.action === 'duplicate') {
            const costCardId = this.props.location.costcard.id;
            const action = 'duplicate';
            this.costCardDetails(costCardId, action);
            this.setState({
                disableBillingregion: false,
                disableResourceRegion: false,
                disableProject: false
            });
        }
        this.regionDetails();
        this.populateProjects();
        this.setMinStartDate();
    }

    setHeaderTitle = async () => {
        if (this.props.onHeaderTitleChange) {
            if (this.props.location.action === 'duplicate') {
                this.props.onHeaderTitleChange('Duplicate Cost Card');
            } else if (this.props.location.action === 'view') {
                this.props.onHeaderTitleChange('View Cost Card');
            } else {
                this.props.onHeaderTitleChange('Edit Cost Card');
            }
        }
    };

    costCardDetails = (costCardId, action) => {
        getCostCardDetails(costCardId)
            .then(res => {
                var obj = {
                    'id': res.data.Project ? res.data.Project.id : null,
                    'UniqueId': res.data.Project ? res.data.Project.UniqueId : null,
                    'OPID': res.data.Project ? res.data.Project.OPID : null,
                    'ProjectName': res.data.Project ? res.data.Project.ProjectName : null,
                    'displayName': res.data.Project ? res.data.Project.UniqueId + " - " + res.data.Project.ProjectName : null,
                }

                if (this.props.location.action === 'view' || this.props.location.action === 'edit') {
                    this.setState({
                        costCardDetails: res.data,
                        selectedBillingRegion: res.data.BillingRegion,
                        selectedResourceRegion: res.data.ResourceRegion,
                        selectedProject: res.data.Project ? obj : null,
                        costCardName: res.data.Name,
                        costCardValidityFrom: new Date(res.data.StartDate),
                        costCardValidityTo: new Date(res.data.EndDate),
                    });
                }
                else if (this.props.location.action === 'duplicate') {
                    this.setState({
                        costCardDetails: res.data,
                        selectedBillingRegion: null,
                        selectedResourceRegion: null,
                        selectedProject: null,
                        costCardName: '',
                        costCardValidityFrom: null,
                        costCardValidityTo: null,
                    });
                }

                const test = res.data.CostCardDesignations;

                let records = test.filter(obj => obj.Designation.IsActive === true);

                const list = [];
                records.forEach(function (data) {
                    const designation = {
                        id: data.Designation.id,
                        Name: data.Designation.Name,
                        IsProjectRole: data.Designation.IsProjectRole,
                        IsActive: data.Designation.IsActive
                    };
                    const costCardDesignation = {
                        CostCardId: data.CostCardId,
                        CostToCompany: data.CostToCompany,
                        Designation: designation,
                        DesignationId: data.DesignationId,
                        Overhead: data.Overhead,
                        createdAt: data.createdAt,
                        id: data.id,
                        selected: false,
                        updatedAt: data.updatedAt,
                        NewCostToCompany: data.CostToCompany,
                        NewOverhead: data.Overhead
                    };

                    if (action === 'duplicate') {
                        if (data.Designation.IsActive === true) {
                            list.push(costCardDesignation);
                        }
                    }
                    else {
                        list.push(costCardDesignation);
                    }


                });

                this.setState({
                    designationDetails: list.slice(0),
                    data: list.slice(0)
                });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    async regionDetails() {
        getRegionInfoIntegrated()
            .then(res => {
                this.setState({
                    regions: res.data
                });

                const resourceRegion = [];
                const billingRegion = [];
                res.data.forEach(function (region) {
                    if (region.CostRegion === false) {
                        billingRegion.push(region);
                    }
                    resourceRegion.push(region);
                });

                this.setState({
                    billingRegions: billingRegion,
                    resourceRegions: resourceRegion
                })
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateProjects = async () => {
        await getAllProjects().then(response => {
            this.allProjects = [];

            for (var project of response.data) {
                if (project.IsActive) {
                    var obj = {
                        'id': project.id,
                        'UniqueId': project.UniqueId,
                        'OPID': project.OPID,
                        'ProjectName': project.ProjectName,
                        'displayName': project.UniqueId + " - " + project.ProjectName,
                    }
                    this.allProjects.push(obj);
                }
            };

            this.allProjects.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));

            this.setState({
                projects: this.allProjects
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    setMinStartDate = async () => {
        let minDate = new Date();
        const currentYear = minDate.getFullYear();
        const currentMonth = minDate.getMonth() + 1;
        if (currentMonth == 1 || currentMonth == 2 || currentMonth == 3) { // check if the month is Jan/Feb/March
          let prevYear = (currentYear - 1).toString();
          minDate = prevYear + '-04-01';
        } else {
          minDate = currentYear + '-04-01';
        }
        minDate = new Date(moment(minDate).format("YYYY-MM-DD"));
        await this.setState({
          minStartDate: minDate
        });
      };

    rowClick = (e) => {
        if (e.dataItem.Designation.IsActive === true) {
            this.setState({
                editID: e.dataItem.id
            });
        }
    };

    itemChange = (e) => {
        const data = this.state.designationDetails.slice();
        const index = data.findIndex(d => d.id === e.dataItem.id);
        let cellValue = 0;
        if (e.value != null && e.value > 0) {
            cellValue = e.value;
        }
        data[index] = { ...data[index], [e.field]: roundNumber(cellValue, 2) };
        this.setState({
            isFormDirty: true,
            designationDetails: data
        });

    };

    render() {
        if (this.state.toRegion === true) {
            return <Redirect to="/admin/CostCard/Search" />
        }
        return (
            <div className="main-card">
                <div className="row">
                    <div className="col-md-8">
                        {this.props.location.action === 'view' && (
                            <div className="main-heading">
                                View Cost Card
                            </div>
                        )}
                        {this.props.location.action === 'edit' && (
                            <div className="main-heading">
                                Edit Cost Card
                                </div>
                        )}
                        {this.props.location.action === 'duplicate' && (
                            <div className="main-heading">
                                Duplicate Cost Card
                            </div>
                        )}

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <div className="d-block">
                                <label className="mandatory">Cost Card Name:</label>
                            </div>
                            <div className="d-block">
                                <Input
                                    name="costCardName"
                                    value={this.state.costCardName}
                                    autoComplete="off"
                                    required={true}
                                    type="text"
                                    onChange={this.handleCostCardNameChange}
                                    disabled={this.props.location.action === 'view'}
                                />
                                {this.state.isErrorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.state.costCardName)}>
                                        Cost Card Name is mandatory
                                        </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <div className="">
                            <div className="d-block">
                                <label className="mandatory">Billing Region:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox
                                    data={this.state.billingRegions}
                                    textField="Name"
                                    dataItemKey="id"
                                    defaultValue={this.state.selectedBillingRegion}
                                    onChange={this.handleBillingRegionChange}
                                    placeholder="Please Select"
                                    disabled={this.state.disableBillingregion}
                                    required={true}
                                />
                                {this.state.isErrorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.state.selectedBillingRegion)}>
                                        Billing Region is mandatory
                                        </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>

                    <div className="col-md-2">
                        <div className="">
                            <div className="d-block">
                                <label className="mandatory">Resource Region:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox
                                    data={this.state.resourceRegions}
                                    textField="Name"
                                    dataItemKey="id"
                                    defaultValue={this.state.selectedResourceRegion}
                                    onChange={this.handleResourceRegionChange}
                                    placeholder="Please Select"
                                    disabled={this.state.disableResourceRegion}
                                    required={true}
                                />
                                {this.state.isErrorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.state.selectedResourceRegion)}>
                                        Resource Region is mandatory
                                        </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>

                    <div className="col-md-2">
                        <div className="d-block">
                            <label className="optional">
                                Project:
                            </label>
                        </div>
                        <div className="d-block">
                            <div className="">
                                <ComboBox
                                    name="selectedProject"
                                    textField="displayName"
                                    dataItemKey="id"
                                    value={this.state.selectedProject}
                                    data={this.state.projects}
                                    disabled={this.state.disableProject}
                                    placeholder={'Please Select'}
                                    onChange={this.handleProjectChange}
                                    filterable={true}
                                    onFilterChange={this.filterProjectOnChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="">
                            <div className="d-block">
                                <label className="mandatory">Validity Period: From:</label>
                            </div>

                            <div className="d-block">
                                <div className="title-remove">
                                    <DatePicker
                                        width="100"
                                        name="fromDate"
                                        onChange={this.handleFromDateChange}
                                        required={true}
                                        value={this.state.costCardValidityFrom}
                                        format="MM/dd/yyyy"
                                        min={new Date(this.state.minStartDate)}
                                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                        disabled={this.props.location.action === 'view'}
                                    />
                                </div>
                                {this.state.isErrorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.state.costCardValidityFrom)}>
                                        Please select From date
                                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>

                    <div className="col-md-2">
                        <div className="">
                            <div className="d-block">
                                <label className="mandatory">To:</label>
                            </div>

                            <div className="d-block">
                                <div className="title-remove">
                                    <DatePicker
                                        width="100"
                                        name="toDate"
                                        onChange={this.handleToDateChange}
                                        required={true}
                                        value={this.state.costCardValidityTo}
                                        format="MM/dd/yyyy"
                                        min={new Date()}
                                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                        disabled={this.props.location.action === 'view'}
                                    />
                                </div>
                                {this.state.isErrorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.state.costCardValidityTo)}>
                                        Date cannot be empty
                                            </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                <br></br>
                <div className="row">
                    <div className="col-md-12">
                        <Grid
                            resizable
                            selectedField="selected"
                            data={this.state.designationDetails.map((item) =>
                                Object.assign({
                                    inEdit: item.id === this.state.editID
                                }, item)
                            )}
                            editField="inEdit"
                            onRowClick={this.rowClick}
                            onItemChange={this.itemChange}
                        >

                            <Column field="Designation.Name" title="Designation" width="250px" editable={false} />
                            <Column
                                field="CostToCompany"
                                title="Current Rate - Direct cost per day"
                                editable={false}
                            />
                            <Column
                                field="Overhead"
                                title="Current Rate - Overhead"
                                editable={false}
                            />
                            {this.props.location.action !== 'view' &&
                                <Column
                                    field="NewCostToCompany"
                                    title="New Rate - Direct cost per day"
                                    editor="numeric"
                                />
                            }
                            {this.props.location.action !== 'view' &&
                                <Column
                                    field="NewOverhead"
                                    title="New Rate - Overhead"
                                    editor="numeric"
                                />
                            }
                        </Grid>
                    </div>
                </div>

                <div className="row">
                    {this.props.location.action !== 'view' &&
                        <div className="col-md-12 btn-align-right">
                            <Button primary={true} type="submit" disabled={this.state.saveButtonDisabled}
                                onClick={this.handleSubmit}>Save</Button>
                            <Button type="button" onClick={this.onClickCancel}>Cancel</Button>
                        </div>
                    }
                    {this.props.location.action === 'view' &&
                        <div className="col-md-12 btn-align-right">
                            <Button type="button" onClick={this.onClickCancel}>Close</Button>
                        </div>
                    }
                </div>

                <div>
                    {this.state.visible === true && (
                        <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="300px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                            <DialogActionsBar>
                                <button
                                    className="k-button modal-primary"
                                    onClick={
                                        this.state.dialogTitle === 'Error'
                                            ? this.toggleDialog
                                            : this.navigateBack
                                    }
                                >
                                    OK
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
                <div>
                    {this.state.showCancelConfirmation === true && (
                        <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                            <p style={{ margin: '25px', textAlign: 'center' }}>
                                This form contains unsaved changes. Do you want to close it?
                            </p>
                            <DialogActionsBar>
                                <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                                    No
                                </button>
                                <button className="k-button modal-primary" onClick={this.navigateBack}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

            </div>
        );
    }

    handleCostCardNameChange = async event => {
        await this.setState({ costCardName: event.target.value, isFormDirty: true });
    };

    handleBillingRegionChange = async event => {
        await this.setState({ selectedBillingRegion: event.target.value, isFormDirty: true });
    };

    handleResourceRegionChange = async event => {
        await this.setState({ selectedResourceRegion: event.target.value, isFormDirty: true });
    };

    handleFromDateChange = async event => {
        await this.setState({ costCardValidityFrom: event.target.value, isFormDirty: true });
    };

    handleToDateChange = async event => {
        await this.setState({ costCardValidityTo: event.target.value, isFormDirty: true });
    };

    handleProjectChange = async event => {
        const name = event.target.name;
        const value = event.target.value;
        await this.setState({ [name]: value, isFormDirty: true });
    };

    filterProjectOnChange = event => {
        this.setState({
            projects: this.filterProjectData(event.filter)
        });
    };

    filterProjectData(filter) {
        const data = this.allProjects.slice();
        return filterBy(data, filter);
    }

    handleSubmit = event => {
        event.preventDefault();

        if (!this.validation()) {
            this.setState({
                isErrorMessageVisible: true
            });
            this.setState({ visible: true });
            this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
        } else if (this.state.costCardValidityTo.getTime() < this.state.costCardValidityFrom.getTime()) {
            this.setState({ visible: true });
            this.toggleDialog('End date cannot be a date before the Start date', 'Error');
        }
        else {
            this.setState({
                isErrorMessageVisible: false,
                saveButtonDisabled: true
            });

            if (this.props.location.action === 'edit') {
                const CostCardDesignationData = {
                    CostCardName: this.state.costCardName.trim(),
                    CostCardDetails: this.state.costCardDetails,
                    DesignationDetails: this.state.designationDetails,
                    ProjectId: this.state.selectedProject ? this.state.selectedProject.id : null,
                    StartDate: moment(this.state.costCardValidityFrom).format("YYYY-MM-DD"),
                    EndDate: moment(this.state.costCardValidityTo).format("YYYY-MM-DD"),
                }
                updateCostCard(CostCardDesignationData, this.state.costCardDetails.id).then(res => {
                    this.toggleDialog(`The Cost Card was successfully updated`, 'Success');
                    this.setState({});
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        this.toggleDialog(error.response.data, 'Error');
                        this.setState({
                            isDisableSave: false
                        });
                    });
            }

            if (this.props.location.action === 'duplicate') {

                const designations = this.state.designationDetails;
                const costCardDesignations = [];

                for(let el of designations){
                    el.StartDate = moment(this.state.costCardValidityFrom).format('YYYY-MM-DD');
                    el.EndDate = moment(this.state.costCardValidityTo).format('YYYY-MM-DD');
                }

                designations.forEach(function (data) {
                    const costCardDesignation = {
                        DesignationId: data.DesignationId,
                        Overhead: data.NewOverhead,
                        CostToCompany: data.NewCostToCompany,
                        StartDate: data.StartDate,
                        EndDate: data.EndDate
                    };
                    costCardDesignations.push(costCardDesignation);
                });

                const costCard = {
                    Name: this.state.costCardName.trim(),
                    ResourceRegionId: this.state.selectedResourceRegion.id,
                    BillingRegionId: this.state.selectedBillingRegion.id,
                    ProjectId: this.state.selectedProject ? this.state.selectedProject.id : null,
                    StartDate: moment(this.state.costCardValidityFrom).format("YYYY-MM-DD"),
                    EndDate: moment(this.state.costCardValidityTo).format("YYYY-MM-DD"),
                    CostCardDesignations: costCardDesignations,
                    IsActive: true,
                    updatedUser: window.LOGGED_USER.displayName
                }

                saveCostCard(costCard)
                    .then(res => {
                        this.toggleDialog(`The cost card is created successfully.`, 'Success');
                        let data = [];
                        getMultiYearInformationIntegrated().then(response => {
                            let yearRecords = response.data;
                            if (yearRecords.length > 0) {
                                for (const item of yearRecords) {
                                    if (item.id) {
                                        const costData = {
                                            MultiyearInfoGridId: item.id,
                                            CostCardId: res.data.id,
                                            Type: 'Cost Card'
                                        };
                                        data.push(costData);
                                    }
                                }

                                saveCostCardIncrementsIntegrated(data);
                            }
                        })
                          .catch(error => {
                              loggerService.writeLog(error, LOG_TYPES.ERROR);
                          });
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        this.setState({
                            isDisableSave: false
                        });
                        this.toggleDialog(error.response.data, 'Error');
                    });
            }
        }

    }

    onClickCancel = event => {
        if (this.state.isFormDirty) {
            this.toggleCancelConfirmDialog();
        } else {
            this.navigateBack(event);
        }
    };

    toggleCancelConfirmDialog = () => {
        this.setState({
            showCancelConfirmation: !this.state.showCancelConfirmation
        });
    };

    validation = () => {
        if (this.validateProperty(this.state.costCardName).toString().includes('error')) {
            return false;
        }
        else if (this.validateProperty(this.state.selectedResourceRegion).toString().includes('error')) {
            return false;
        }
        else if (this.validateProperty(this.state.selectedBillingRegion).toString().includes('error')) {
            return false;
        }
        else if (this.validateProperty(this.state.costCardValidityTo).toString().includes('error')) {
            return false;
        }
        else if (this.validateProperty(this.state.costCardValidityFrom).toString().includes('error')) {
            return false;
        }
        else { return true; }

    }

    toggleDialog = (message, title) => {
        this.setState({
            visible: !this.state.visible,
            dialogMessage: message,
            dialogTitle: title,
            saveButtonDisabled: false
        });
    };

    navigateBack = event => {
        this.setState({
            toRegion: true
        });
    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };
}

export default EditCostCard;
