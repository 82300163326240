import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { getFormattedDate, reStructureDate } from '../../integration/CommonUtils'
import PhaseRow from './components/PhaseRow';
import moment from 'moment';

class ProjectPhase extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);

        this.state = {
            phaseName: '',
            startDate: null,
            startDateMin: null,
            startDateIsaPastDate: false,
            updateMode:false,
            updatedPhaseId:null,
            maxStartDate:null,
            isDiry:false,
            showConfirmDialog:false,
            confirmDialogMessage:'',
            confirmDialogAction:null,
            isNew:true,
            nameExist:false,
            statDateExist:false,
            phaseIndex:null
        };
    }

    componentDidMount() {
        this.setStartDateMin();
    }

    toggleConfirmDialog = (message, action) => {
        this.setState({
          showConfirmDialog: !this.state.showConfirmDialog,
          confirmDialogMessage: message,
          confirmDialogAction: action
        });
      };

    setStartDateMin = () => {
        let startDateMin = null;
        if (this.props.project && this.props.project.StartDate && this.props.Ref === 'projectCMS') {
            startDateMin = reStructureDate(this.props.project.StartDate);
            startDateMin.setHours(0, 0, 0, 0);
            this.setState({ startDateMin: reStructureDate(startDateMin) });
        }
        else if (this.props.changeRequest && this.props.changeRequest.StartDate && this.props.Ref === 'crCMS')
        {
            startDateMin = reStructureDate(this.props.changeRequest.StartDate);
            startDateMin.setHours(0, 0, 0, 0);
            this.setState({ startDateMin: reStructureDate(startDateMin) });
        } else {
            startDateMin = new Date();
            startDateMin.setHours(0, 0, 0, 0);
            this.setState({ startDateMin: startDateMin });
        }
    };

    closeDialog = () => {
        this.props.closeMethod();
    };

    handleInputChange = event => {
        const value = event.target.value;
        const field = event.target.name;
        this.setState({
            [field]: value,
            isDiry:true
        });
    };

    onStartDateChange = async event => {
        let startDate= null
        this.setState({
            statDateExist:false
        })
        if (event.value === null) {
            startDate = null
        }
        else {
            startDate  = new Date(event.target.value);
        }

        await this.setState(prevState => {
            return {
                startDate: startDate
            };
        });

        if ( (startDate && (new Date(startDate) >= new Date(this.state.startDateMin))) || startDate === null) {
            this.setState({
                startDateIsaPastDate: false
            })
        }
        else {
            this.setState({ startDateIsaPastDate: true });
        }
    };

    validation=()=>{
        if(this.state.startDate === null){
            this.setState({
                statDateExist:true
            })
            return false;
        }else if(this.state.phaseName === ""){
            this.setState({
                nameExist:true
            })
            return false;
        }
        else{
            return true
        }
    }

    addPhase = () => {
        if(this.validation()){
            if (this.state.startDate && this.state.startDate < this.state.startDateMin) {
                this.setState({
                    startDateIsaPastDate: true
                })
            }
            else {
                this.setState({
                    startDateIsaPastDate: false
                })
                let startDateStr = null;
                if (this.state.startDate) {
                    startDateStr = getFormattedDate(this.state.startDate);
                }
                this.props.onAddPhase(this.state.phaseName, startDateStr,this.state.isNew);
            }
            this.setState({
                phaseName: '',
                startDate: '',
                startDateIsaPastDate: false,
                isDiry:true
            });
        }
    };
    
    addUpdatedPhase = () => {
       if(this.validation()){
        if (this.state.startDate && this.state.startDate < this.state.startDateMin) {
            this.setState({
                startDateIsaPastDate: true
            })
        }
        else {
            this.setState({
                startDateIsaPastDate: false
            })
            let startDateStr = null;
            if (this.state.startDate) {
                startDateStr = getFormattedDate(this.state.startDate);
            }
            this.props.onUpdatePhase(this.state.phaseName, startDateStr,this.state.phaseIndex,this.state.isNew);
        }
        this.setState({
            phaseName: '',
            startDate: '',
            startDateIsaPastDate: false,
            updateMode:false
        });
       }
    };

    removePhase = index => {
        this.props.onRemovePhase(index);
    };
    
    updatePhase = index => {
        let selectedPhase = this.props.phases[index];
        if(selectedPhase.SubPhases.length !== 0){
            let maxStartDate  = reStructureDate(selectedPhase.SubPhases.sort((x, y) => {
                return new Date(x.StartDate).getTime() - new Date(y.StartDate).getTime();
              })[0].StartDate);
              this.setState({
                maxStartDate:maxStartDate
              })
        }else{
            this.setState({
                maxStartDate:null
              })
        }
        
        this.setState({
            updateMode:true,
            phaseName: selectedPhase.Text,
            updatedPhaseId: selectedPhase.id,
            startDate: reStructureDate(selectedPhase.StartDate),
            isDiry:true,
            isNew:selectedPhase.isNew,
            phaseIndex:index
        })
    };

    handleSave = () => {
        this.setState({
            isDiry:false
        })
        this.props.onSave();
        this.closeDialog();
    };
    
    handleUpdate = () => {
        this.setState({
            isDiry:false
        })
        this.props.onUpdate();
        this.closeDialog();
    };

    handleCancel = () => {
        if(this.state.isDiry){
            this.toggleConfirmDialog(
                'This form contains unsaved data. Do you want to close it?',
                ()=>{
                    this.props.onCancel();
                    this.closeDialog();
                }
              );
        }else{
            this.props.onCancel();
            this.closeDialog();
        }
    };

    render() {
        return (
            <div>
                <Dialog title={'Define Project Phases'} onClose={this.handleCancel} width={400}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <label for="">Phase Name:</label>
                                </div>
                                <div className="col-md-8">
                                    <Input
                                        value={this.state.phaseName}
                                        onChange={this.handleInputChange}
                                        name="phaseName"
                                        autoComplete="off"
                                    />
                                     {this.state.nameExist === true && (
                                        <label className="text-red">phase name is mandatory</label>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label for="">Start Date:</label>
                                </div>

                                {this.props.endDate === null && (
                                <div className="col-md-8">
                                    {this.state.maxStartDate !== null ?<DatePicker
                                        value={reStructureDate(this.state.startDate)}
                                        onChange={this.onStartDateChange}
                                        name="startDate"
                                        min={this.state.startDateMin ? reStructureDate(this.state.startDateMin) : new Date()}
                                        max={ reStructureDate(this.state.maxStartDate) }
                                        format="MM/dd/yyyy"
                                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                    /> :
                                    <DatePicker
                                        value={reStructureDate(this.state.startDate)}
                                        onChange={this.onStartDateChange}
                                        name="startDate"
                                        min={this.state.startDateMin ? reStructureDate(this.state.startDateMin) : new Date()}
                                        format="MM/dd/yyyy"
                                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                    />}
                                    {this.state.startDateIsaPastDate === true && (
                                        <label className="text-red">phase start date is a past date</label>
                                    )}
                                    {this.state.statDateExist === true && (
                                        <label className="text-red">phase start date is mandatory</label>
                                    )}
                                </div>
                                )}

                                {this.props.endDate && (
                                    <div className="col-md-8">
                                        <DatePicker
                                            value={this.state.startDate}
                                            width="99%"
                                            onChange={this.onStartDateChange}
                                            name="startDate"
                                            min={this.state.startDateMin ? reStructureDate(this.state.startDateMin) : new Date()}
                                            format="MM/dd/yyyy"
                                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                            max = {this.state.maxStartDate !== null? reStructureDate(this.state.maxStartDate): this.props.endDate}
                                        />
                                        {this.state.startDateIsaPastDate === true && (
                                            <label className="text-red">phase start date is a past date</label>
                                        )}
                                        {this.state.statDateExist === true && (
                                            <label className="text-red">phase start date is mandatory</label>
                                        )}
                                    </div>
                                )}


                            </div>
                            <div className="row">
                                <div className="col-md-12 phase-button btn-align-right">
                                    {!this.state.updateMode ?
                                     <Button
                                        className="k-button project-phase"
                                        disabled={!this.state.phaseName}
                                        onClick={this.addPhase}
                                    > Add Phase
                                    </Button>:
                                     <Button
                                        className="k-button project-phase"
                                        disabled={!this.state.phaseName}
                                        onClick={this.addUpdatedPhase}
                                    > Update Phase
                                    </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-12">
                            {this.props.phases.map((value, index) => {
                                return (
                                    <PhaseRow
                                        phase={value}
                                        isEditPhases={true}
                                        isEditSubPhases={false}
                                        onRemovePhase={() => this.removePhase(index)}
                                        onUpdatePhase={() => this.updatePhase(index)}
                                        key={index}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <DialogActionsBar>
                        <button className="k-button modal-primary" onClick={this.handleCancel}>
                            Cancel
                        </button>
                        <button
                            className="k-button"
                            disabled={ !this.state.isDiry || this.state.phaseName || this.state.startDate}
                            onClick={ this.handleSave}
                        >
                            Save
                        </button>
                    </DialogActionsBar>
                </Dialog>
                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                        <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleConfirmDialog}>
                            No
                        </button>
                        <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                            Yes
                        </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </div>
        );
    }
}

export default ProjectPhase;
