import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class DateCell extends Component {

    render() {
        const value = this.props.dataItem.createdAt;
        return <td><div className="date-time"><Moment format="DD-MM-YYYY hh:mm:ss A">
            {value}
        </Moment></div></td>;
    }
}

export default DateCell;