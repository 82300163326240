import React from 'react';

import { Switch } from '@progress/kendo-react-inputs'
import { Button } from '@progress/kendo-react-buttons';


const Toggle = ({ checked, onChange, onClick }) => (
  <span className="toggle-control">
    <Switch
      className="dmcheck"
      type="checkbox"
      checked={checked}
      onChange={onChange}
      id="dmcheck"
    />
    
    <label htmlFor="dmcheck" />
    
  </span>
  
);

export default Toggle;