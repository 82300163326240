/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Button } from '@progress/kendo-react-buttons';

class PhaseRow extends Component {
    formatDate = dateStr => {
        if (dateStr) {
            const startDate = new Date(dateStr);

            return startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear();
        }
        return null;
    };

    render() {
        const phase = this.props.phase;
        return (
            <div className="parent-phase">
                <div className="phase">
                    {phase
                        ? phase.Text +
                        ' ' +
                        (phase && phase.StartDate ? '(' + this.formatDate(phase.StartDate) + ')' : '')
                        : ''}
                    {this.props.isEditPhases ? (
                        <div className="act-btn float-right mb-2">
                            <Button className="rounded-btn remove ml-2" onClick={this.props.onRemovePhase}>
                                <span className="k-icon k-i-close"/>
                            </Button>

                            <Button className="rounded-link" onClick={this.props.onEditPhase}>
                                <span className="k-icon k-i-edit" title="Edit" />
                            </Button>
                        </div>

                    ) : null}

                </div>
                <div className="sub-phase-wrap">
                    {phase.SubPhases
                        ? phase.SubPhases.map((value, index) => {
                            return (
                                <div className="sub-phase mb-4" key={index}>
                                    {value.Text +
                                    ' ' +
                                    (value && value.StartDate
                                        ? '(' + this.formatDate(value.StartDate) + ')'
                                        : '')}
                                    {this.props.isEditSubPhases ? (
                                        <div className="act-btn float-right mb-2">
                                        <Button
                                            className="rounded-btn remove ml-2"
                                            onClick={() => this.props.onRemoveSubPhase(this.props.phaseIndex, index)}
                                        >
                                            <span className="k-icon k-i-close" />
                                        </Button>

                                        <Button className="rounded-link" onClick={() => this.props.onEditSubPhase(this.props.phaseIndex, index,value)}>
                                        <span className="k-icon k-i-edit" title="Edit" />
                                        </Button>
                                        </div>

                                    ) : null}
                                </div>
                            );
                        })
                        : null}
                </div>
            </div>
        );
    }
}

export default PhaseRow;
