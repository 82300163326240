import React, { Component } from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { DebounceInput } from 'react-debounce-input';
import { roundNumber } from '../../integration/CommonUtils';
import { Button } from '@progress/kendo-react-buttons';
import { Tooltip } from 'reactstrap';
import moment from 'moment';
import $ from "jquery";
const apiUrl = require('../../../src/config').get(process.env.REACT_APP_ENV).apiUrl;

class RowCellMilestones extends Component {

    constructor(props) {
        super(props);

        this.state = {
            decimalPlaces: 0,
            nameTooltipOpen: false
        };
    }

    handleChange = async event => {
        this.props.onChange({
            dataItem: this.props.dataItem,
            field: this.props.field,
            syntheticEvent: event.syntheticEvent,
            value: event.target.value
        });
    };


    componentDidMount() {
        $(document).ready(function () {
            $("div.title-remove > span > span > span:last-child").removeAttr("title");
        });
    }

    reStructureDate=(date)=>{
        if(date){
          return new Date([moment(date).format('YYYY'), moment(date).format('M'), moment(date).format('D')].join('/'))
        }else{
          return date
        }
    }

    toggleNameTooltip = () => {
        this.setState({
            nameTooltipOpen: !this.state.nameTooltipOpen
        });
    };

    handleButtonClick = () => {
        this.props.onDeleteButtonClick(this.props.dataItem);
    };

    handleFileUpload = async (event) => {
        this.props.onFileUploadClick(event, this.props.dataItem);
    };

    render() {

        let date = null;
        let min = null;

        let max = this.props.dataItem[this.props.maxDateField]

        if (this.props.field === 'StartDate' ||  this.props.field === 'EndDate') {
            max = this.props.project ? (this.props.project.UserDefinedEndDate
            ? new Date(this.props.project.UserDefinedEndDate)
            : this.props.project.EndDate ?
            new Date(this.props.project.EndDate)
            : null) : null;

            min = this.props.project ? (this.props.project.StartDate
            ? new Date(this.props.project.StartDate)
            : new Date()) : new Date();

            if (this.props.field === 'EndDate') {
                min = this.props.dataItem.StartDate ? new Date(this.props.dataItem.StartDate) : min;
            }

            date = this.props.dataItem[this.props.field] ? new Date(this.props.dataItem[this.props.field]) : null;
        }

        return (
            <td>
                <div className="rowcelldp">

                    <div className="title-remove">
                        {(this.props.field === 'StartDate' ||  this.props.field === 'EndDate') && max === null && (
                            <DatePicker
                                width="100%"
                                onChange={this.handleChange}
                                value={date}
                                min={min}
                                disabled={this.props.disabled}
                                format="MM/dd/yyyy"
                                required={true}
                                formatPlaceholder={{year: 'YYYY', month: 'MM', day: 'DD'}}
                            />
                        )}

                        {(this.props.field === 'StartDate' || this.props.field === 'EndDate') && max !== null && (
                            <DatePicker
                                width="100%"
                                onChange={this.handleChange}
                                value={date}
                                min={min}
                                max={max}
                                disabled={this.props.disabled}
                                format="MM/dd/yyyy"
                                required={true}
                                formatPlaceholder={{year: 'YYYY', month: 'MM', day: 'DD'}}
                            />
                        )}

                        {this.props.field === 'Revenue' && (
                            <div>
                                <div className="custom-grid-label" title="Text" href="#" id="milestonerevenue">
                                    <DebounceInput
                                        width="100%"
                                        value={roundNumber(this.props.dataItem[this.props.field],2)}
                                        disabled={this.props.disabled}
                                        type="number"
                                        step={this.state.decimalPlaces}
                                        pattern="[0-9]*"
                                        className="k-textbox"
                                        required={true}
                                        onChange={this.handleChange}
                                        style={this.props.dataItem[this.props.field] ? {} : { 'borderColor': 'rgba(243,23,0,0.5)' }}
                                    />                        
                                </div>
                            </div>
                        )}

                        {this.props.field === 'Name' && (
                            <div>
                                <div className="custom-grid-label" href="#" id={"milestoneName" + this.props.dataItem.index}>
                                    <Input
                                        autoComplete="off"
                                        type="text"
                                        name="milestonename"
                                        value={this.props.dataItem[this.props.field]}
                                        onChange={this.handleChange}
                                        required={true}
                                        disabled={this.props.disabled}
                                        maxLength="255"
                                    />                     
                                </div>
                                {/* {this.props.dataItem[this.props.field] ? (
                                    <Tooltip 
                                        placement="top" 
                                        isOpen={this.state.nameTooltipOpen} 
                                        target={"milestoneName" + this.props.dataItem.index}
                                        toggle={this.toggleNameTooltip}
                                    >
                                        {this.props.dataItem[this.props.field]}
                                    </Tooltip>
                                ) : null} */}
                            </div>
                        )}

                        {this.props.field === 'Actions' && !this.props.disabled && (
                            <div className="action-buttons">
                                <div className="row">
                                    <div className="col-md-3">
                                        <Button
                                            className="rounded-btn remove"
                                            disabled={this.props.disabled || this.props.isDisableDelete}
                                            onClick={this.handleButtonClick}
                                            title="Delete"
                                        >
                                            <span className="k-icon k-i-close" />
                                        </Button>
                                    </div>
                                    <div className="col-md-3 d-flex flex-col">
                                        <div className="rounded-link">
                                            <span className="k-icon k-i-upload"/>
                                        </div>
                                        <input 
                                            type="file" 
                                            name="file" 
                                            title="Upload File"
                                            className="custom-statusFile-input"
                                            disabled={this.props.disabled}
                                            onChange={this.handleFileUpload}
                                            value={this.state.uploadedFileId}
                                            multiple={true}
                                            style={{ margin: 0 }} 
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.props.field === 'FileId' && this.props.dataItem.FileId !== null && (
                            <a href={`${apiUrl}/integration/fileUpload/downloadmilestone?path=Milestone_${encodeURIComponent(this.props.dataItem.FileUpload.FileName)}&&milestoneFileName=${encodeURIComponent(this.props.dataItem.FileUpload.FileName)}`} target="">
                                {this.props.dataItem.FileUpload.FileName}
                                <span className="k-icon k-i-download ml-2"></span>
                            </a>
                        )}

                    </div>
                </div>
            </td>
        );
    }
}

export default RowCellMilestones;
