import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem, PanelBarUtils, Menu, MenuItem, MenuItemModel, MenuItemLink, MenuItemArrow, Splitter } from '@progress/kendo-react-layout';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ReactDOM from 'react-dom';
import '../Spark.css';

class EvaluateSkills extends Component {
    render() {
        return (
            <div>
                <div className="main-card">

                    <div className="row">

                        <div className="col-md-12">
                            <div className="d-inline-block">
                                <label className="new-label">Resource Name:</label>
                            </div>
                            <div className="d-inline-block ml-1">
                                <label className="new-label font-weight-normal">John Richards</label>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <fieldset>
                                <label className="k-form-field">
                                    <input type="checkbox" id="auth-1es" className="k-checkbox" />
                                    <label className="k-checkbox-label" for="auth-1es">Display only Skills assigned to me</label>
                                </label>
                            </fieldset>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-12 btn-align-right">
                            <Button primary={true}>Re-evaluate Bulk Complete in Sale Status</Button>
                            <Button primary={true}>Bulk Suspend</Button>
                            <Button>Bulk Remove</Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Grid
                                style={{ height: '100px' }}
                            >
                                <Column field="ProductID" title="ID" width="40px" />
                                <Column field="ProductName" title="Name" width="250px" />
                                <Column field="Category.CategoryName" title="CategoryName" />
                                <Column field="UnitPrice" title="Price" />
                                <Column field="UnitsInStock" title="In stock" />
                            </Grid>
                        </div>
                    </div>

                </div>

                {/* <Dialog title={"Define Skill Criteria"} onClose={this.toggleDialog} width={1100}>

                    <div className="row">
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Skill Category:</label>
                            </div>
                            <div className="d-block">Technical Skills</div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Skill Type:</label>
                            </div>
                            <div className="d-block">Java Development</div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Evaluation Status:</label>
                            </div>
                            <div className="d-block">Not Evaluated</div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Proficiency:</label>
                            </div>
                            <div className="d-block">Not Evaluated</div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">Last Evaluation:</label>
                            </div>
                            <div className="d-block">N/A</div>
                        </div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <label className="new-label">View Previous Evaluations:</label>
                            </div>
                            <div className="d-block">
                                <ComboBox />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-12">
                            <Grid style={{ height: '100px' }}>
                                <Column field="Beginner" title="Beginner" />
                                <Column field="Practitioner" title="Practitioner" />
                                <Column field="Expert" title="Expert" />
                            </Grid>
                        </div>
                    </div>

                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>Save</button>
                    </DialogActionsBar>
                </Dialog> */}

            </div>
        );
    }
}

export default EvaluateSkills;
