import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Get MultiYear Information By param.
 * @returns {array} array list of MultiYear Information
 * @param params
 */
export function getMultiYearInfoByYear(params) {
  const endPoint = apiUrl + '/masterData/multiYear/info';
  return http.post(endPoint, params);
}

/**
 * Get MultiYear Information.
 * @returns {array} array list of MultiYear Information
 */
export function getMultiYearInformation() {
  const endPoint = apiUrl + '/masterData/multiYear/multiyeargrid/getall/info/masterdata';
  return http.get(endPoint);
}

/**
 * Save MultiYear Increments.
 * @returns {array} array list of MultiYear Increments
 * @param params
 */
export function saveMultiyearIncrements(params) {
  const endPoint = apiUrl + '/masterData/multiYear/savemultiyearincrements';
  return http.post(endPoint, params);
}

/**
 * Save CostCard Increments.
 * @returns {array} array list of CostCard Increments
 * @param params
 */
export function saveCostCardIncrements(params) {
  const endPoint = apiUrl + '/masterData/multiYear/savecostincrements/cost';
  return http.post(endPoint, params);
}

/**
 * Update Multiyear Increments by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param params
 */
export function updateMultiyearIncrements(params) {
  const endPoint = apiUrl + '/masterData/multiYear/updatemultiyearRecord';
  return http.put(endPoint, params);
}

/**
 * Send mails for updated Multiyear Increments
 * @param params
 * @returns Multi year info array
 */
 export function updateMultiyearIncrementsSendMail(params) {
  const endPoint = apiUrl + '/masterData/multiYear/updateMultiyearIncrementsSendMail';
  return http.post(endPoint, params);
}


/**
 * Get multiyear info by rate card id.
 * @param params
 * @returns Multi year info array
 */
export function getMultiYearInfoByYearandRateCardId(params) {
  const endPoint = apiUrl + '/masterData/multiYear/infobyyearandratecardid';
  return http.post(endPoint, params);
}

/**
 * Get multiyear info by cost card id.
 * @param params
 * @returns Multi year info array
 */
export function getMultiYearInfoByYearandCostCardId(params) {
  const endPoint = apiUrl + '/masterData/multiYear/infobyyearandcostcardid';
  return http.post(endPoint, params);
}

/**
 * delete multi year grid infor by id.
 * @param params
 * @returns deleted object
 */
export function deleteMultiYearGridInfo(params) {
  const endPoint = apiUrl + '/masterData/multiYear/delete';
  return http.post(endPoint, params);
}