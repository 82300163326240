import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import {
  // getCustomersForGrid,
  updateCustomerStatus,
  searchCustomers
} from './CustomerService';
import { getResourceRegionsIntegrated } from '../../integration/MasterDataService';
import MultiValueTableCell from '../../integration/components/table/MultiValueTableCell';
import DateTableCell from '../../integration/components/table/DateTableCell';
import ActiveInactiveTableCell from '../../integration/components/table/ActiveInactiveTableCell';
import { Button } from '@progress/kendo-react-buttons';
import { Link } from 'react-router-dom';
import ActionsTableCell from './components/ActionsTableCell';
import ConfirmationDialog from '../../integration/components/ConfirmationDialog';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, MultiSelect  } from '@progress/kendo-react-dropdowns';
import Loader from '../../integration/components/Loader';
import { orderBy } from '@progress/kendo-data-query';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";

class SearchCustomer extends Component {

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      customers: [],
      allCustomers: [],
      skip: 0,
      take: 20,
      activatePopupVisible: false,
      deactivatePopupVisible: false,
      activateCustomerId: 0,
      deactivateCustomerId: 0,
      visible: false,
      dialogMessage: '',
      dialogTitle: '',
      searchCustomer: [],
      searchRegion: [],
      regions: [],
      searchStatus: null,
      status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
      searchState: false,
      loading: false,
      sort: [{ field: 'updatedAt', dir: 'desc' }]
    };
  }

  async componentDidMount() {
    this.isMount = true;
    window.scrollTo(0, 0);
    this.setHeaderTitle();
    this.populateCustomers();

    /**
     * getResourceRegionsIntegrated - Retrieve Resource Regions
     * @returns {object}
     */
    getResourceRegionsIntegrated().then(response => {
      let data = response.data;
      let filteredRegions = data.filter(obj => obj.CostRegion === false);
      if (this.isMount) {
        this.setState({
          regions: filteredRegions
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  populateCustomers = async () => {
    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    await searchCustomers().then(response => {
      if (this.isMount) {
        this.setState({
          loading: false,
          customers: response.data,
          allCustomers: response.data
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  pageChange = event => {
    this.setState({
      skip: event.page.skip,
      take: event.page.take
    });
  };

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Customer');
    }
  };

  OnActivate = async id => {
    await this.setState({
      activateCustomerId: id
    });
    this.activateConfirmationToggle();
  };

  OnDeactivate = async id => {
    await this.setState({
      deactivateCustomerId: id
    });
    this.deactivateConfirmationToggle();
  };

  deactivateConfirmationToggle = async () => {
    await this.setState({
      deactivatePopupVisible: !this.state.deactivatePopupVisible
    });
  };

  deactivateConfirmationOk = async () => {
    this.setState({ loading: true, deactivatePopupVisible: false });

    await updateCustomerStatus({ IsActive: false }, this.state.deactivateCustomerId)
      .then(async response => {
        const promises = [];
        if (this.state.searchState) {
          promises.push(this.submitSearch());
        } else {
          promises.push(this.populateCustomers());
        }
        Promise.all(promises).then(() => {
          this.setState({
            loading: false,
            showSuccessImage: true,
            showErrorImage: false
          });
          this.toggleDialog('The Customer has been Deactivated successfully', 'Success');
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  activateConfirmationToggle = async () => {
    await this.setState({
      activatePopupVisible: !this.state.activatePopupVisible
    });
  };

  activateConfirmationOk = async () => {
    this.setState({ loading: true, activatePopupVisible: false });

    await updateCustomerStatus({ IsActive: true }, this.state.activateCustomerId)
      .then(async response => {
        const promises = [];
        if (this.state.searchState) {
          promises.push(this.submitSearch());
        } else {
          promises.push(this.populateCustomers());
        }
        Promise.all(promises).then(() => {
          this.setState({
            loading: false,
            showSuccessImage: true,
            showErrorImage: false
          });
          this.toggleDialog('The Customer has been Activated successfully', 'Success');
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  handleInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    await this.setState({ [name]: value, isFormDirty: true });
  };

  submitSearch = event => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      loading: true
    });

    const searchRequest = {
      customerName: this.state.searchCustomer.map(value => value.Name),
      regionId: this.state.searchRegion.map(value => value.id),
      IsActive: this.state.searchStatus ? this.state.searchStatus.Name : null
    };

    console.log(searchRequest);

    searchCustomers(searchRequest).then(res => {
      this.setState({
        searchState: true,
        customers: res.data,
        loading: false,
        skip: 0
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  cancelSearch = event => {
    event.preventDefault();
    this.setState({
      searchState: false,
      searchCustomer: [],
      searchRegion: [],
      searchStatus: null
    });
    this.populateCustomers();
  };

  render() {
    return (
      <div>
        <div className="main-card">
          <div className="row">
            <div className="col-md-8">
              <div className="main-heading">Customer Search</div>
            </div>

            <div className="col-md-4">
              <Button primary={true} className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('EDT_CUST') > -1)}>
                <Link
                  style={{ textDecoration: 'none', color: 'white' }}
                  to={{
                    pathname: '/admin/customer/edit',
                    action: 'create'
                  }}
                >
                  Create New Customer
                </Link>
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                <label>Customer Name:</label>
              </div>
              <div className="d-block">
                <div className="">
                <MultiSelect
                    name="searchCustomer"
                    textField="Name"
                    value={this.state.searchCustomer}
                    data={this.state.allCustomers}
                    placeholder={'Please Select'}
                    onChange={this.handleInputChange}
                  />                  
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>

            <div className="col-md-2">
              <div className="d-block">
                <label>Billing Region:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    name="searchRegion"
                    textField="Name"
                    dataItemKey="id"
                    value={this.state.searchRegion}
                    data={this.state.regions}
                    placeholder={'Please Select'}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-1"></div>


            <div className="col-md-2">
              <div className="d-block">
                <label>Status:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    name="searchStatus"
                    textField="Name"
                    dataItemKey="status"
                    value={this.state.searchStatus}
                    data={this.state.status}
                    placeholder={'Please Select'}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
                Clear
              </Button>
            </div>
          </div>


          <div className="main-seperator"></div>

          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">Search Results</div>
            </div>
          </div>

          <div className="row mt-0">
            <div className="col-md-12">
              <Grid
                data={orderBy(this.state.customers, this.state.sort).slice(this.state.skip, this.state.take + this.state.skip)}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.customers.length}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }
                }
                className="search-result-grid"
              >
                <Column field="Name" title="Customer" />
                <Column
                  field=""
                  title="Program(s)"
                  cell={props => (
                    <MultiValueTableCell values={props.dataItem.Programs.map(x => x.Name)} />
                  )}
                />
                <Column field="Region.Name" title="Region" width="100px" />
                <Column
                  field="createdAt"
                  title="Created Date"
                  width="100px"
                  cell={props => <DateTableCell value={props.dataItem.createdAt} />}
                />
                <Column
                  field="updatedAt"
                  title="Last Modified Date"
                  width="100px"
                  cell={props => <DateTableCell value={props.dataItem.updatedAt} />}
                />
                <Column field="updatedUser" title="Last Modified User"   />
                <Column
                  field="IsActive"
                  title="Status"
                  width="100px"
                  cell={props => <ActiveInactiveTableCell value={props.dataItem.IsActive} />}
                />
                <Column
                  title="Actions"
                  width="150px"
                  cell={props => (
                    <ActionsTableCell
                      {...props}
                      OnDeactivate={this.OnDeactivate}
                      OnActivate={this.OnActivate}
                    />
                  )}
                />
              </Grid>
            </div>
          </div>

          <div>
            {this.state.deactivatePopupVisible === true && (
              <ConfirmationDialog
                dialogTitle={'Please Confirm'}
                dialogMessage={'Are you sure you want to Deactivate this?'}
                dismissCallback={this.deactivateConfirmationToggle}
                abortCallback={this.deactivateConfirmationToggle}
                confirmCallback={this.deactivateConfirmationOk}
              />
            )}
          </div>

          <div>
            {this.state.activatePopupVisible && (
              <ConfirmationDialog
                dialogTitle={'Please Confirm'}
                dialogMessage={'Are you sure you want to Reactivate this?'}
                dismissCallback={this.activateConfirmationToggle}
                abortCallback={this.activateConfirmationToggle}
                confirmCallback={this.activateConfirmationOk}
              />
            )}
          </div>
          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success" />
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error Image" />
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>

          <Loader loading={this.state.loading} />
        </div>
      </div>
    );
  }
}

export default SearchCustomer;
