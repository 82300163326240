/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { MultiSelect, ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import {Input, NumericTextBox} from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import MultiIncrementYearPicker from '../../masterData/multiyearIncrement/components/MultiIncrementYearPicker';
import { Redirect } from 'react-router';
import { Tooltip } from 'reactstrap';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import Loader from '../../integration/components/Loader';
import {getProject, getProjectStatuses, saveProject, updateProject} from "../project/ProjectService";
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";
import {getPMResources, getProjectOwners, saveResourceRequests} from "../../resourceManagement/ResourceService";
import {
    getBillingDiviIntegrated,
    getProjectPartnersIntegrated, getRegionsWithCountriesIntegrated,
    getSalesPersonIntegrated
} from "../../integration/MasterDataService";
import {getCurrencies, getPaymentMethods, getProjectTypes} from "../../masterData/common/CommonService";
import {getcustomers} from "../../masterData/customer/CustomerService";
import {getOfferings} from "../../masterData/offering/OfferingService";
import moment from "moment";
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';

const resourceAll = [];

class FactoryMain extends Component {
    isMount = false;

    preDefinedPermissions = {
        projectName: { preSales: true,other: false },
        startDate: { preSales: true, other: false },
        status: { preSales: true,other: true },
        practice: { preSales: true, other: false },
        billingRegion: { preSales: false,  other: false },
        offering: { preSales: true, other: false },
    };


    constructor(props) {
        super(props);
        this.state = {
            billingregionItems: [],
            billingregionValue: null,
            billingDiviValue: [],
            startdate: null,
            offeringValue: [],
            projectname: null,
            dataValueCurrency: {Code: 'USD',id: 1},
            TargetBillable: null,
            statusValue: null,
            billingregionValueAdd:[],
            billingdiviItems: [],
            offeringItems: [],
            statusItems: [],
            currencies: [],
            dialogMessage: '',
            resourceItems: resourceAll.slice(),
            resourceValue: null,
            financialYear: null,
            prevStatus: null,
            toDashboard: false,

            permissions: {
                projectName: true,
                startDate: true,
                status: true,
                practice: true,
                billingRegion: true,
                offering: true,
            },
            creatingAProject: false,
            isErrorMessageVisible: false,
            visible: false,
            dialogTitle: '',
            toSearchScreen: false,
            isFormDirty: false,
            showConfirmDialog: false,
            confirmDialogAction: null,
            loading: false,
            showSuccessImage: false,
            showErrorImage: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.isMount = true;
        this.setHeaderTitle(this.props.location.action);
        this.setInitialData();
    }



    setHeaderTitle = async (action) => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            if (action === 'view') {
                this.props.onHeaderTitleChange('View Pool Project');
            } else if (action === 'edit') {
                this.props.onHeaderTitleChange('Edit Pool Project');
            } else {
                this.props.onHeaderTitleChange('Create a Pool Project');
                this.setState({
                    creatingAProject: true
                });
            }
        }
    };

    renderProjectDetails = project => {
        let selectedOfferings = [];
        let selectedPractices = [];
        let selectedRegions = [];
        if (project) {

            this.isEnableCreate = true;
            this.isEnableUpdate = false;
            let offeringObj = null;
            let billDivObj = null;
            let billRegionObj = null;
            let currency = null;

            const statusObj = {
                StatusName: project.ProjectStatus.StatusName,
                id: project.ProjectStatus.id
            };

            const resourceObj = {
                displayName: project.PresalesResource.Name,
                id: project.PresalesResource.id
            };

            for(const obj of project.FactoryProjectOffering){
                offeringObj = {
                    Name: obj.Offering.Name,
                    id: obj.id
                };
                selectedOfferings = selectedOfferings.concat(offeringObj);
            }

            for(const obj of project.FactoryProjectBillDivision){

                if(obj.IsActive === true){
                    billDivObj = {
                        Name: obj.BillingDivision.Name,
                        id: obj.BillingDivision.id
                    };
                    selectedPractices = selectedPractices.concat(billDivObj);
                }

            }

            for(const obj of project.FactoryProjectBillRegion){
                billRegionObj = {
                    Name: obj.Region.Name,
                    id: obj.id
                };
                selectedRegions = selectedRegions.concat(billRegionObj);
            }

            for(const obj of this.state.currencies){
                if(obj.id === project.Currency){
                    currency = obj;
                }

            }

            const startDateMin = project.StartDate ? new Date(project.StartDate) : new Date();

            this.setState({
                projectname: project.ProjectName,
                startdate: project.StartDate ? new Date(project.StartDate) : null,
                statusValue: statusObj,
                prevStatus: statusObj.id,
                offeringValue:selectedOfferings,
                billingDiviValue: selectedPractices,
                billingregionValue: project.Region,
                resourceValue: resourceObj,
                billingregionValueAdd:selectedRegions,
                financialYear : project.FinancialYear,
                TargetBillable : project.TargetBillable,
                dataValueCurrency : currency,
            });
        }

        else {
            return null;
        }
    };

    updateProjectDetails = project => {
        let selectedOfferings = [];
        let selectedPractices = [];
        let selectedRegions = [];
        if (project) {

            this.isEnableCreate = true;
            this.isEnableUpdate = false;
            let offeringObj = null;
            let billDivObj = null;
            let billRegionObj = null;
            let currency = null;

            const statusObj = {
                StatusName: project.ProjectStatus.StatusName,
                id: project.ProjectStatus.id
            };

            const resourceObj = {
                displayName: project.PresalesResource.Name,
                id: project.PresalesResource.id
            };

            for(const obj of project.FactoryProjectOffering){
                offeringObj = {
                    Name: obj.Offering.Name,
                    id: obj.Offering.id
                };
                selectedOfferings = selectedOfferings.concat(offeringObj);
            }

            for(const obj of project.FactoryProjectBillDivision){

                if(obj.IsActive === true){
                    billDivObj = {
                        Name: obj.BillingDivision.Name,
                        id: obj.BillingDivision.id
                    };
                    selectedPractices = selectedPractices.concat(billDivObj);
                }

            }

            for(const obj of project.FactoryProjectBillRegion){
                billRegionObj = {
                    Name: obj.Region.Name,
                    id: obj.Region.id
                };
                selectedRegions = selectedRegions.concat(billRegionObj);
            }

            for(const obj of this.state.currencies){
                if(obj.id === project.Currency){
                    currency = obj;
                }

            }

            const startDateMin = project.StartDate ? new Date(project.StartDate) : new Date();

            this.setState({
                projectname: project.ProjectName,
                startdate: project.StartDate ? new Date(project.StartDate) : null,
                startDateMin: (project.ProjectStatus.Code === 'BILLABLE' ||
                    project.ProjectStatus.Code === 'WORK_AT_RISK') ? new Date() : new Date('1970-01-01Z00:00:00:000'),
                statusValue: statusObj,
                prevStatus: statusObj.id,
                offeringValue:selectedOfferings,
                billingDiviValue: selectedPractices,
                billingregionValue: project.Region,
                resourceValue: resourceObj,
                billingregionValueAdd:selectedRegions,
                financialYear : project.FinancialYear,
                TargetBillable : project.TargetBillable,
                dataValueCurrency : currency,
            });

        } else {
            return null;
        }
    };

    handleStartdateChange = event => {
        let startDate = event.target.value;

        this.setState({
            startdate: startDate,
            isFormDirty: true
        });

        if (startDate) {
            this.setState({
                showStartDateValidationError: false
            });
        }
    };



    populateDropDownData = async () => {
        await getProjectStatuses().then(async response => {
            if (this.state.action === 'edit') {
                let list = response.data;
                if (
                    this.state.project.ProjectStatus &&
                    this.state.project.ProjectStatus.Code !== 'BILLABALE_CR'
                ) {
                    // Remove Billable with CR
                    list = response.data.filter(obj => obj.Code !== 'BILLABALE_CR');
                }
                await this.setState({
                    statusItems: list
                });
            }
            if (this.state.action !== 'edit' && this.state.action !== 'view') {
                const presalesOption = response.data.filter(obj => obj.Code === 'PRESALES');
                await this.setState({
                    statusValue: presalesOption.length > 0 ? presalesOption[0] : null,
                    statusItems: presalesOption,
                    startDateMin: new Date('1970-01-01Z00:00:00:000')
                });
            }
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        await getProjectOwners().then(response => {
            let resourceItems = [];
            this.resourceAll = response.data;
            this.setState({
                resourceItems: response.data
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        await getBillingDiviIntegrated().then(async response => {
            this.allPractices = response.data;
            await this.setState({
                billingdiviItems: response.data
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        await getRegionsWithCountriesIntegrated().then(async response => {
            this.allBillingRegions = response.data;

            await this.setState({
                billingregionItems: response.data
            });

        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        await getOfferings().then(async response => {
            this.allOfferings = response.data;
            await this.setState({
                offeringItems: response.data
            });
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    populateCurrencies = async () => {
        await getCurrencies()
            .then(async res => {
                if (res.data && res.data.length > 0 && res.data[0].id) {
                    if (this.isMount) {
                        await this.setState({
                            currencies: res.data
                        });
                    }
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    handleStatusChange = event => {
        // 1 - Presales - PRESALES
        // 2 - Waiting Confirmation - WAIT_CONFIRM
        // 3 - Closed-Opportunity Loss - CLOSE_OP_LOST
        // 4 - On Hold - ON_HOLD
        // 5 - Working At Risk - WORK_AT_RISK
        // 6 - Billable - BILLABLE
        // 7 - Billable with CR - BILLABALE_CR
        // 8 - Closed - CLOSED
        // 9 - Close without bidding - CLOSE_WITHOUT_BID


        if (event.target.value) {
            if (event.target.value.Code === 'BILLABLE' || event.target.value.Code === 'WORK_AT_RISK') {
                this.setState({ startDateMin: new Date(), showStartDateValidationError: false });
            }
            else {
                this.setState({ startDateMin: new Date('1970-01-01Z00:00:00:000'), showStartDateValidationError: false });
            }
            if (this.state.action === 'edit') {
                // const currentProjectStatusId = this.state.project.ProjectStatus.id;
                const currentProjectStatusCode = this.state.project.ProjectStatus.Code;
                const cmSheets = this.state.project.CMSheets;
                let hasApprovedCMS = false;
                for (const cmSheet of cmSheets) {
                    if (cmSheet.Status === 'APPROVED') {
                        hasApprovedCMS = true;
                        break;
                    }
                }

                const selectedStatusCode = event.target.value.Code;
                const selectedStatusName = event.target.value.StatusName;

                if (
                    selectedStatusCode === 'PRESALES' &&
                    !(
                        currentProjectStatusCode === 'PRESALES' ||
                        currentProjectStatusCode === 'WAIT_CONFIRM' ||
                        currentProjectStatusCode === 'ON_HOLD'
                    )
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // When there is an approved CMS, status can be changed to Waiting Confirmation or Closed-Opportunity Loss
                if (
                    (selectedStatusCode === 'WAIT_CONFIRM' || selectedStatusCode === 'CLOSE_OP_LOST') &&
                    !hasApprovedCMS
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // status can be changed to Close without bidding before converting to a Billable or Working at Risk status
                if (
                    selectedStatusCode === 'CLOSE_WITHOUT_BID' &&
                    (currentProjectStatusCode === 'WORK_AT_RISK' ||
                        currentProjectStatusCode === 'BILLABLE' ||
                        currentProjectStatusCode === 'BILLABALE_CR')
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Any project in Presales, Waiting Confirmation, On Hold with an Approved CMS can be changed to Working At Risk
                if (
                    selectedStatusCode === 'WORK_AT_RISK' &&
                    (!(
                            currentProjectStatusCode === 'PRESALES' ||
                            currentProjectStatusCode === 'WAIT_CONFIRM' ||
                            currentProjectStatusCode === 'ON_HOLD' ||
                            currentProjectStatusCode === 'WORK_AT_RISK'
                        ) ||
                        !hasApprovedCMS)
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Any project in Presales, Waiting Confirmation, On Hold or Working at Risk with an Approved CMS can be changed to Billable
                if (
                    selectedStatusCode === 'BILLABLE' &&
                    (!(
                            currentProjectStatusCode === 'PRESALES' ||
                            currentProjectStatusCode === 'WAIT_CONFIRM' ||
                            currentProjectStatusCode === 'ON_HOLD' ||
                            currentProjectStatusCode === 'WORK_AT_RISK' ||
                            currentProjectStatusCode === 'BILLABLE'
                        ) ||
                        !hasApprovedCMS)
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Billable with CR is an internal status change, not a user change
                if (selectedStatusCode === 'BILLABALE_CR' && currentProjectStatusCode !== 'BILLABALE_CR') {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Any Billable, Working at Risk or On Hold project can be converted to Closed
                if (
                    selectedStatusCode === 'CLOSED' &&
                    !(
                        currentProjectStatusCode === 'WORK_AT_RISK' ||
                        currentProjectStatusCode === 'BILLABLE' ||
                        currentProjectStatusCode === 'ON_HOLD'
                    )
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                if ((selectedStatusCode === 'BILLABLE' || selectedStatusCode === 'WORK_AT_RISK')) {
                    if (this.state.startdate == null) {
                        this.setState(
                            {
                                showStartDateValidationError: true
                            });
                    }
                }

                if (currentProjectStatusCode === 'ON_HOLD' && (selectedStatusCode === 'BILLABLE' || selectedStatusCode === 'WORK_AT_RISK')) {
                    const permissions = Object.assign({}, this.state.permissions);
                    permissions['description'] = true;
                    this.setState({
                        permissions: permissions
                    });
                }
            }
        }

        this.setState({
            statusValue: event.target.value,
            isFormDirty: true
        });


    };

    setPermissionsOfFields = () => {
        if (this.props.location.action === 'view') {
            this.setState({
                isViewMode: true
            });
        } else if (this.props.location.action === 'edit') {
            const cmSheets = this.state.project.CMSheets;
            if (cmSheets.length > 0) {
                const permissions = Object.assign({}, this.state.permissions);
                if (
                    this.state.project.ProjectStatus &&
                    (this.state.project.ProjectStatus.Code === 'BILLABLE' ||
                        this.state.project.ProjectStatus.Code === 'BILLABALE_CR' ||
                        this.state.project.ProjectStatus.Code === 'WORK_AT_RISK')
                ) {
                    for (const field of Object.keys(permissions)) {
                        permissions[field] = this.preDefinedPermissions[field].billable;
                    }
                } else if (
                    this.state.project.ProjectStatus &&
                    this.state.project.ProjectStatus.Code === 'PRESALES'
                ) {
                    for (const field of Object.keys(permissions)) {
                        permissions[field] = this.preDefinedPermissions[field].preSales;
                    }
                } else {
                    for (const field of Object.keys(permissions)) {
                        permissions[field] = this.preDefinedPermissions[field].other;
                    }
                }

                if (this.state.project.ProjectStatus) {
                    if (this.state.project.ProjectStatus.Code === 'PRESALES' || this.state.project.ProjectStatus.Code === 'WAIT_CONFIRM' || this.state.project.ProjectStatus.Code === 'ON_HOLD' || this.state.project.ProjectStatus.Code === 'WORK_AT_RISK') {
                        permissions['startDate'] = true;
                    }
                }

                this.setState({
                    permissions: permissions
                });
            }
        }
    };


    setInitialData = async () => {

        await  this.populateCurrencies();

        this.setState(
            {
                action: this.props.location.action
            },
            () => {
                if (
                    (this.props.location.action === 'view' || this.props.location.action === 'edit') &&
                    this.props.location.projectId > 0
                ) {
                    getProject(this.props.location.projectId)
                        .then(res => {
                            if (res && res.data && res.data.id === this.props.location.projectId) {
                                if (this.props.location.action === 'view') {
                                    //this.onSaveAuditTrail(res.data, this.props.location.projectId)
                                }

                                this.setState(
                                    {
                                        project: res.data,
                                        preSalesResourceAllocations: res.data.ResourceRequest
                                    },
                                    () => {
                                        if (this.props.location.action === 'view') {
                                            this.renderProjectDetails(res.data);
                                        } else if (this.props.location.action === 'edit') {
                                            this.state.onHoldStatusBool = this.state.project.ProjectStatus.Code === 'ON_HOLD' ? true : false;
                                            this.state.permissions.practice = false;

                                            this.updateProjectDetails(res.data);
                                        } else {
                                            // this.forceUpdate();
                                        }

                                        this.setPermissionsOfFields();
                                        this.populateDropDownData();
                                    }
                                );
                            }
                        })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });
                } else {
                    this.populateDropDownData();
                }
            }
        );
    };


    handleOnChange = async (event) => {
        const value = event.target.value;
        const field = event.target.name;

        switch (field) {
            case 'billingdivi': {
                await this.setState({
                    billingdiviValue: value
                });

                break;
            }
            case 'billingregion': {
                this.setState({
                    billingregionValue: value,
                });
                break;
            }
            case 'billingregionadd': {
                this.setState({
                    billingregionValueAdd: value,
                });
                break;
            }
            case 'offerings': {
                this.setState({
                    offeringValue: value,
                });
                break;
            }
            case 'TargetBillableUti': {
                this.setState({
                    TargetBillable: value,
                });
                break;
            }
            case 'financialYear': {
                this.setState({
                    financialYear: value,
                });
                break;
            }
            default: {
            }
        }
        this.setState({
            isFormDirty: true
        });
    }

    filterPracticesData(filter) {
        const data = this.allPractices.slice();
        return filterBy(data, filter);
    }


    handleBillableFilterChange = (event) => {
        const value = event.target.value;
        const field = event.target.name;

        switch (field) {
            case 'billingdivi': {
                this.setState({
                    billingdiviItems: this.filterPracticesData(event.filter)
                });
                break;
            }
            case 'billingregion': {
                this.setState({
                    billingregionItems: this.filterBillingRegionData(event.filter)
                });
                break;
            }
            case 'billingregionadd': {
                this.setState({
                    billingregionItems: this.filterBillingRegionData(event.filter)
                });
                break;
            }
            case 'offerings': {
                this.setState({
                    offeringItems: this.filterOfferingData(event.filter)
                });
                break;
            }
            case 'projectowner': {
                this.setState({
                    resourceItems: this.filterDataResource(event.filter)
                });
                break;
            }
        }

        this.setState({
            isFormDirty: true
        });
    }

    filterBillingRegionData(filter) {
        const data = this.allBillingRegions.slice();
        return filterBy(data, filter);
    }

    filterOfferingData(filter) {
        const data = this.allOfferings.slice();
        return filterBy(data, filter);
    }

    filterDataResource(filter) {
        const data = this.resourceAll.slice();
        return filterBy(data, filter);
    }

    validateProperty = value => {
        if (value && value.length !== 0) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    toggleConfirmDialog = async (message, action) => {
        await this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    toggleDialog = async (message, title) => {
        await this.setState({
            visible: !this.state.visible,
            dialogMessage: message,
            dialogTitle: title,
            loading:false
        });
    };

    validation =  () => {
        if (
            this.validateProperty(this.state.projectname)
                .toString()
                .includes('error')
        ) {
            return false;
        } else if (
            this.validateProperty(this.state.statusValue)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else if (
             this.validateProperty(this.state.billingDiviValue)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.billingregionValue)
                .toString()
                .includes('error')
        ) {
            return false;
        }else if (
            this.validateProperty(this.state.financialYear)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.TargetBillable)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.startdate)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.resourceValue)
                .toString()
                .includes('error')
        )
        {
            return false;
        }
        else if (
            this.validateProperty(this.state.dataValueCurrency)
                .toString()
                .includes('error')
        )
        {
            return false;
        }
        else {
            return true;
        }
    };

    handleSubmit = event => {

        this.state.loading = true
        event.preventDefault();

        if (this.state.action === 'view') {
            return;
        }

        if (this.state.statusValue && (this.state.statusValue.Code === 'BILLABLE' || this.state.statusValue.Code === 'WORK_AT_RISK')) {
            if (this.state.startdate == null) {
                this.setState(
                    {
                        visible: true,
                        showStartDateValidationError: true
                    });
                this.toggleDialog('The project start date cannot be empty', 'Error');
                return;
            }
        }

        let today = new Date();
        today.setHours(0, 0, 0, 0);
        if(this.state.statusValue && (this.state.statusValue.Code === 'BILLABLE' || this.state.statusValue.Code === 'WORK_AT_RISK'))
        {
            if (this.state.prevStatus !== 5 ){
                if (this.state.startdate && new Date(this.state.startdate) < today) {
                    this.setState({ visible: true });
                    this.toggleDialog('The project start date cannot be a past date!', 'Error');
                    return;
                }
            }
        }

        if (!this.validation()) {
            this.setState({
                isErrorMessageVisible: true
            });
            this.setState({ 
                visible: true,
                showErrorImage: true,
                showSuccessImage: false
            });
            this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
        }

        else {
            this.setState({
                isErrorMessageVisible: false
            });
            if (this.state.action === 'edit') {
                this.setState(
                    {
                        isDisableSave: true
                    },
                    () => {
                        this.updateProject(event);
                    }
                );


            } else { //create

                    this.createProject(event);
            }
        }
    };

    updateProject = event => {
        if (!this.state.startdate && (this.state.statusValue.Code === 'BILLABLE' || this.state.statusValue.Code === 'WORK_AT_RISK')) {
            this.setState({
                showStartDateValidationError: true,
                isDisableSave: false
            });
            return;
        }

        this.updateAfterValidation();
    };

    updateAfterValidation = () => {

        let startDateOnProjectConversionToBillable = null;
        if (this.state.statusValue.Code === 'BILLABLE' || this.state.statusValue.Code === 'WORK_AT_RISK') {
            startDateOnProjectConversionToBillable = this.state.startdate ? new Date(this.state.startdate) : null;
            startDateOnProjectConversionToBillable = startDateOnProjectConversionToBillable ? moment(startDateOnProjectConversionToBillable).format('YYYY-MM-DD') : null
        }

        const project = {
            ProjectName: this.state.projectname.trim(),
            Status: this.state.statusValue.id,
            prevStatus: this.state.prevStatus,
            StatusName: this.state.statusValue.Code,
            StartDate: this.state.startdate ? this.state.startdate.toDateString() : null,
            ProjectConversionStartDate: startDateOnProjectConversionToBillable,
            EndDate: null,
            ProjectDescription: null,
            BillingDivision: null,
            FactoryProjectBillDivision: this.state.billingDiviValue,
            BillingRegion: this.state.billingregionValue.id,
            FactoryProjectBillRegion: this.state.billingregionValueAdd,
            ProjectType: null,
            PaymentMethod: null,
            SalesPerson: null,
            Partner: null,
            CustomerId: null,
            OPID: null,
            ProgramId: null,
            ResourceId: this.state.resourceValue.id,
            CountryId: null,
            OfferingId: null,
            FactoryProjectOffering: this.state.offeringValue,
            TargetBillable: this.state.TargetBillable,
            Currency:this.state.dataValueCurrency.id,
            FinancialYear: this.state.financialYear,
            IsPoolProject: true
        };

        updateProject(project, this.state.project.id)
            .then(res => {
                var allocationsToSave = [];

                // for(const eachAllocation of this.state.preSalesResourceAllocations){
                //     const AllocationObj = {
                //         id : eachAllocation.id,
                //         CMSheetId: res.data.CMSheets[0] ? res.data.CMSheets[0].id : null,
                //         Type: 'Project',
                //         ProjectId: res.data.id,
                //         Status: eachAllocation.selected === true ? 'RES_ALLOC_PENDING' : eachAllocation.Status,
                //         Comment: (eachAllocation.selected === true && this.state.allocComment) ? this.state.allocComment.replace(/(<([^>]+)>)/ig, '') : (eachAllocation.Comment ? eachAllocation.Comment : null),
                //         CMSheetVersion: res.data.CMSheets[0] ? res.data.CMSheets[0].Version : null,
                //         Designation: eachAllocation.Designation,
                //         ResourceRegion: eachAllocation.ResourceRegion,
                //         EstimatedEndDate: eachAllocation.EstimatedEndDate ? eachAllocation.EstimatedEndDate.toDateString() : null,
                //         StartDate: eachAllocation.StartDate ? eachAllocation.StartDate.toDateString() : null,
                //         EndDate: eachAllocation.EndDate ? eachAllocation.EndDate.toDateString() : null,
                //         AllocationPercentage: eachAllocation.AllocationPercentage,
                //         EffortDays: eachAllocation.EffortDays,
                //         TotalEffortDays: eachAllocation.TotalEffortDays,
                //         IsPreSales: eachAllocation.IsPreSales,
                //         IsActive: eachAllocation.IsActive,
                //         ProjectName: res.data.ProjectName,
                //         UniqueId: res.data.UniqueId,
                //         selected: eachAllocation.selected,
                //         CostPerResource: Number(eachAllocation.CostPerResource),
                //         DirectCost: Number(eachAllocation.DirectCost),
                //         TotalCost: Number(eachAllocation.TotalCost)
                //     };
                //     allocationsToSave.push(AllocationObj);
                // }
                //
                // saveResourceRequests(allocationsToSave).
                // then(saveRes => {
                //     this.toggleDialog('The project has been successfully updated', 'Success');
                // })
                //     .catch(error => {
                //         loggerService.writeLog(error, LOG_TYPES.ERROR);
                //         this.toggleDialog('Error occured while saving pre sales resource request', 'Error');
                //         this.setState({
                //             isDisableSave: false
                //         });
                //     });
                this.setState({
                    showErrorImage: false,
                    showSuccessImage: true
                })
                this.toggleDialog('Factory Project is successfully updated', 'Success');

            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleDialog(error.response ? error.response.data : error, 'Error');
                this.setState({
                    isDisableSave: false
                });
            });
    };


    createProject = event => {
        const project = {
            ProjectName: this.state.projectname.trim(),
            Status: this.state.statusValue.id,
            StatusName: this.state.statusValue.Code,
            prevStatus: this.state.prevStatus,
            StartDate: this.state.startdate ? this.state.startdate.toDateString() : null,
            EndDate: null,
            ProjectDescription: null,
            BillableStatus: false,
            BillingDivision: null,
            BillingDivisions: this.state.billingDiviValue,
            BillingRegion: this.state.billingregionValue.id,
            BillingRegions: this.state.billingregionValueAdd,
            ProjectType: null,
            PaymentMethod: null,
            SalesPerson: null,
            Partner: null,
            CustomerId: null,
            OPID: null,
            ProgramId: null,
            ResourceId: this.state.resourceValue.id,
            CountryId: null,
            OfferingId: null,
            Offerings: this.state.offeringValue,
            TargetBillable: this.state.TargetBillable,
            Currency:this.state.dataValueCurrency.id,
            FinancialYear: this.state.financialYear,
            IsPoolProject: true
        };

        saveProject(project)
            .then( async res => {
                var allocationsToSave = [];

                // if(this.state.preSalesResourceAllocations !== null){
                //     for(const eachAllocation of this.state.preSalesResourceAllocations){
                //         const AllocationObj = {
                //             CMSheetId: res.data.CMSheets ? res.data.CMSheets[0].id : null,
                //             Type: 'Project',
                //             ProjectId: res.data.id,
                //             Status: eachAllocation.selected === true ? 'RES_ALLOC_PENDING' : eachAllocation.Status,
                //             Comment: (eachAllocation.selected === true && this.state.allocComment) ? this.state.allocComment.replace(/(<([^>]+)>)/ig, '') : (eachAllocation.Comment ? eachAllocation.Comment : null),
                //             CMSheetVersion: res.data.CMSheets ? res.data.CMSheets[0].Version : null,
                //             Designation: eachAllocation.Designation,
                //             ResourceRegion: eachAllocation.ResourceRegion,
                //             EstimatedEndDate: eachAllocation.EstimatedEndDate ? eachAllocation.EstimatedEndDate.toDateString() : null,
                //             StartDate: eachAllocation.StartDate ? eachAllocation.StartDate.toDateString() : null,
                //             EndDate: eachAllocation.EndDate ? eachAllocation.EndDate.toDateString() : null,
                //             AllocationPercentage: eachAllocation.AllocationPercentage,
                //             EffortDays: eachAllocation.EffortDays,
                //             TotalEffortDays: eachAllocation.TotalEffortDays,
                //             IsPreSales: eachAllocation.IsPreSales,
                //             IsActive: eachAllocation.IsActive,
                //             ProjectName: res.data.ProjectName,
                //             UniqueId: res.data.UniqueId,
                //             selected: eachAllocation.selected,
                //             CostPerResource: Number(eachAllocation.CostPerResource),
                //             DirectCost: Number(eachAllocation.DirectCost),
                //             TotalCost: Number(eachAllocation.TotalCost)
                //         };
                //         allocationsToSave.push(AllocationObj);
                //     }
                //
                //     await saveResourceRequests(allocationsToSave)
                //         .then(
                //             saveRes => {
                //                 this.toggleDialog(`The project ${res.data.UniqueId} is successfully created`, 'Success');
                //             }
                //         ).catch(error => {
                //             loggerService.writeLog(error, LOG_TYPES.ERROR);
                //             this.toggleDialog(error, 'Error');
                //         });
                // }
                //
                // else{
                    this.setState({
                        showErrorImage: false,
                        showSuccessImage: true
                    })
                    this.toggleDialog(`Factory Project is successfully created`, 'Success');

                //}


            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleDialog(error.response.data, 'Error');
                this.setState({
                    isDisableSave: false
                });
            });



    };

    handleProjNameChange = event => {
        this.setState({
            projectname: event.target.value,
            isFormDirty: true
        });
    };

    handleResourceChange = event => {
        this.setState({
            resourceValue: event.target.value,
            isFormDirty: true
        });
    };

    navigateBack = () => {
        this.navigateToSearchScreen();
    };

    navigateToSearchScreen = event => {
        this.setState({
            toSearchScreen: true
        });
    };

    onClickCancel = event => {
        event.preventDefault();
        if (this.state.isFormDirty) {
            this.toggleConfirmDialog(
                'There are unsaved data in the form, do you want to redirect to home page without saving them?',
                this.navigateBack
            );
        } else {
            this.navigateBack();
        }
    };

    handleOnChangeFinancialYear = async event => {
        await this.setState({
            financialYear: event.value,
            isFormDirty: true
        });

    };

    handleCurrencyChange = async event => {
        await this.setState({
            dataValueCurrency: event.target.value,
            isFormDirty: true
        });
    };

    handleOnPracticeChange = async event => {
        await this.setState({
            billingDiviValue: event.target.value,
            isFormDirty: true
        });
    };

    reDirectToDashboard = event => {
        this.setState({
            toDashboard: true
        });
    };

    render() {
        const classes = 'tooltip-inner';
        if (this.state.toDashboard === true) {
            return <Redirect to="/" />;
        }
        if (this.state.toSearchScreen === true) {
            return <Redirect to="/projects/search/search" />;
        }
        return (
            <div className="main-card">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-heading">Factory Main Project Create / Edit</div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="d-block">
                                    <label htmlFor="" className="mandatory">Project Name:</label>
                                </div>
                                <div className="d-block">
                                    <div className="">
                                        <Input
                                            autoComplete="off"
                                            type="text"
                                            name="projectname"
                                            value={this.state.projectname}
                                            onChange={this.handleProjNameChange}
                                            required={true}
                                            //validityStyles={this.state.projectname.touched}
                                            disabled={this.state.isViewMode || !this.state.permissions.projectName}
                                            maxLength="100"
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.projectname)}>
                    Please enter a project name
                  </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Primary Billing Region:</label>
                                </div>
                                <div className="d-block">
                                    <div className="">
                                        <ComboBox
                                            data={this.state.billingregionItems}
                                            textField="Name"
                                            dataItemKey="id"
                                            placeholder="Please Select"
                                            value={this.state.billingregionValue}
                                            name="billingregion"
                                            onChange={this.handleOnChange}
                                            required={true}
                                            disabled={this.state.isViewMode || !this.state.permissions.billingRegion || this.state.action === 'edit'}
                                            filterable={true}
                                            onFilterChange={this.handleBillableFilterChange}
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.billingregionValue)}>
                    Please enter a primary billing region
                  </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Practice/Department:</label>
                                </div>
                                <div className="d-block">

                                    <div className="">
                                        <MultiSelect
                                            data={this.state.billingdiviItems}
                                            textField="Name"
                                            dataItemKey="id"
                                            placeholder="Please Select"
                                            value={this.state.billingDiviValue}
                                            name="billingdivi"
                                            onChange={this.handleOnPracticeChange}
                                            disabled={this.state.isViewMode || !this.state.permissions.billingRegion}
                                            filterable={true}
                                            onFilterChange={this.handleBillableFilterChange}
                                            required={true}
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.billingDiviValue)}>
                    Please enter a Practice/Department
                  </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Start Date:</label>
                                </div>
                                <div className="d-block">
                                    <div className="">
                                                <DatePicker
                                                    width="100"
                                                    name="startdate"
                                                    onChange={this.handleStartdateChange}
                                                    value={this.state.startdate}
                                                    min={this.state.creatingAProject === true || this.state.action === 'edit' ? new Date() : this.state.startDateMin}
                                                    disabled={this.state.isViewMode || !this.state.permissions.startDate}
                                                    format="MM/dd/yyyy"
                                                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                                    required= {true}
                                                />

                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.startdate)}>
                    Please enter a start date
                  </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                        <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Target Billable Utilization:</label>
                                </div>
                                <div className="d-block">
                                    <div className="">
                                        <NumericTextBox
                                            autoComplete="off"
                                            type="Numeric"
                                            name="TargetBillableUti"
                                            required={true}
                                            min={0}
                                            max={100}
                                            width={'100%'}
                                            value={this.state.TargetBillable}
                                            onChange={this.handleOnChange}
                                            textField="name"
                                            dataItemKey="value"
                                            disabled={this.state.isViewMode}
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.TargetBillable)}>
                    Please enter a target billable Utilization
                  </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Currency:</label>
                                </div>
                                <div className="d-block">
                                    <div className="">
                                        <ComboBox
                                            data={this.state.currencies}
                                            textField="Code"
                                            dataItemKey="id"
                                            value={this.state.dataValueCurrency}
                                            onChange={this.handleCurrencyChange}
                                            disabled={this.state.isViewMode}
                                            required={true}
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.dataValueCurrency)}>
                    Please enter a currency
                  </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Project Owner:</label>
                                </div>
                                <div className="d-block">
                                    <div className="">
                                        <ComboBox
                                            data={this.state.resourceItems}
                                            textField="displayName"
                                            dataItemKey="id"
                                            placeholder="Please Select"
                                            value={this.state.resourceValue}
                                            onChange={this.handleResourceChange}
                                            required={true}
                                            filterable={true}
                                            name = "projectowner"
                                            onFilterChange={this.handleBillableFilterChange}
                                            disabled={this.state.isViewMode}
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.resourceValue)}>
                    Please enter a project owner
                  </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label>Additional Billing Region:</label>
                                </div>
                                <div className="d-block">
                                    <div className="">
                                        <MultiSelect
                                            data={ this.state.billingregionValue !== null ? this.state.billingregionItems.filter(obj => obj.id !== this.state.billingregionValue.id) : this.state.billingregionItems}
                                            textField="Name"
                                            dataItemKey="id"
                                            placeholder="Please Select"
                                            value={this.state.billingregionValueAdd}
                                            name="billingregionadd"
                                            onChange={this.handleOnChange}
                                            disabled={this.state.isViewMode || !this.state.permissions.billingRegion}
                                            filterable={true}
                                            onFilterChange={this.handleBillableFilterChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            </div>
                        <div className="row">
                        <div className="col-md-3">
                                <div className="d-block">
                                    <label>Offering:</label>
                                </div>
                                <div className="d-block">
                                    <div className="">
                                        <MultiSelect
                                            data={this.state.offeringItems}
                                            textField="Name"
                                            dataItemKey="id"
                                            placeholder="Please Select"
                                            value={this.state.offeringValue}
                                            name="offerings"
                                            onChange={this.handleOnChange}
                                            filterable={true}
                                            onFilterChange={this.handleBillableFilterChange}
                                            disabled={this.state.isViewMode}
                                        />
                                    </div>
                                </div>
                            </div>
                        
                        
                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Status:</label>
                                </div>
                                <div className="d-block">
                                    <div className="">
                                        <ComboBox
                                            data={this.state.statusItems}
                                            textField="StatusName"
                                            dataItemKey="id"
                                            onChange={this.handleStatusChange}
                                            value={this.state.statusValue}
                                            required={true}
                                            disabled={
                                                this.state.isViewMode ||
                                                !this.state.permissions.status ||
                                                (window.USER_PERMISSION &&
                                                    window.USER_PERMISSION.indexOf('CHG_PRJ_STAT') === -1)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Financial Year:</label>
                                </div>
                                <div className="d-block">

                                    {this.state.action === 'view' ? (
                                        <div className="">
                                            <Input
                                                value={this.state.financialYear}
                                                name="financialYear"
                                                onChange={this.handleOnChangeFinancialYear}
                                                autoComplete="off"
                                                disabled={this.state.isViewMode}
                                            />
                                        </div>
                                   ) : (<div className="title-remove">
                                        <MultiIncrementYearPicker
                                            value={this.state.financialYear}
                                            name="financialYear"
                                            onChange={this.handleOnChangeFinancialYear}
                                            autoComplete="off"
                                            IsPoolProject = {true}
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.financialYear)}>
                    Please enter a financial year
                  </span>
                                        ) : null}
                                    </div>
                                    )}

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 btn-align-right">
                                <Button primary={true}
                                        type="submit"
                                        primary={true}
                                        disabled={this.state.isViewMode || this.state.isDisableSave}
                                        onClick={this.handleSubmit}
                                >
                                    Save
                                </Button>
                                <Button type="button"  onClick={this.onClickCancel}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.visible === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.state.dialogTitle === 'Error' ? this.toggleDialog : this.reDirectToDashboard} width="300px">
                        {this.state.showSuccessImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={SuccessImg} alt="Success"/>
                                </div>
                        )}
                        {this.state.showErrorImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={ErrorImg} alt="Error Image"/>
                                </div>
                        )}
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                        <DialogActionsBar>
                            <button
                                className="k-button modal-primary"
                                onClick={
                                    this.state.dialogTitle === 'Error' ? this.toggleDialog : this.reDirectToDashboard
                                }
                            >
                                OK
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ 'white-space': 'pre-wrap' }}>{this.state.confirmDialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleConfirmDialog}>
                                No
                            </button>
                            <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                <Loader loading={this.state.loading}/>
            </div>
        );
    }
}


export default FactoryMain;
