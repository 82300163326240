import React, { Component } from 'react';
import { ComboBox, DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Redirect, Link } from 'react-router-dom';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { Tooltip } from 'reactstrap';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import moment from 'moment';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';

import {
  getProjectResources
} from '../../resourceManagement/ResourceService';
import {
  getRegionsIntegrated,
  getResourceRegionsIntegrated,
  getBillingDiviIntegrated,
  getApproverTypesIntegrated,
  getWorkflowDefinitionsIntegrated,
  getBillingDivisionByIdIntegrated,
  getRegionByIdIntegrated,
  getPaymentMethodsIntegrated,
  getPaymentMethodByIdIntegrated,
  getPracticeTypesIntegrated,
  getAllPracticeDivisionMapIntegrated
} from '../../integration/MasterDataService';
import {
  getWorkflowType,
  searchWorkflow,
  searchWorkflowNew,
  workfowDelete,
  //updateWorkfowStatus,
  updateWorkfowStatus,
  replaceApproverOfWorkflows,
  delegateApproverOfWorkflows,
  deleteApproverOfWorkflows, getWorkflowParameter, replaceParameterOfWorkflows,
  getAllPendingReviseCMSRequests,
  getAllPendingInvoiceRequests
} from './WorkflowService';
import WorkflowParameterConfigrations from './components/ParameterConfigsCell';
import SearchAction from './components/ActionCell';
import PendingApprovals from './PendingApprovals';
import Loader from '../../integration/components/Loader';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
  ExcelExportGroupHeader
} from '@progress/kendo-react-excel-export';
import { 
  getProjectStatuses, 
  getProjectsForWorkflows,
  getAllProjectsForWorkflows,
  getProjectsforRRview 
} from "../project/ProjectService";

import { 
  getAllUnFreezeRequestTimeSheetForWorkflows
} from "../../Timesheets/timeSheetEntry/TimeSheetEntryService";
import { getProjectTypes } from '../../masterData/common/CommonService';


const workflowTypeDataAll = [];
const billingDiviDataAll = [];
const prevStatusAll = [];
const currentStatusDataAll = [];
const endStatusAll = [];
const statusAll = [];
const approversDataAll = [];
const activeApproversDataAll = [];
const approverTypesAll = [];
const workflowParameterAll = [];
const workflowParameterDefinitionAll = [];
const practiceAll = [];
const engagementTypesAll = [];

class CustomGroupHeader extends ExcelExportGroupHeader {
  render() {
    return (
      `${this.props.value}`
    );
  }
}

class Workflow extends Component {
  state = {
    SearchRequest: {
      workflowTypeId: null,
      billingDivisionId: [],
      statusId: null,
      approverId: [],
      workflowName: '',
      paymentMethodId: [],
      regionId: [],
      withPendingApprovals: false,
      approverTypeId: [],
      workflowParameterId: null,
      workflowCriteria: null,
      status: null,
      skip: 0,
      take: 15,
      isFromExcelExport:false,
      sort:[{ field: '', dir: '' }],
    },
    //Drop down data
    workflowTypeData: workflowTypeDataAll.slice(),
    workflowParameterData: workflowParameterAll.slice(),
    workflowParameterDefinitionData: workflowParameterDefinitionAll.slice(),
    billingDiviData: billingDiviDataAll.slice(),
    statusData: statusAll.slice(),
    approversData: approversDataAll.slice(),
    currentApproversData: approversDataAll.slice(),
    newApproversData: approversDataAll.slice(),
    regionData: [],
    approverTypeData: approverTypesAll.slice(),

    paymentMethods: [],
    paymentMethodsWithAll: [], //Used while Creating a workflow
    costRegions: [],
    resourceRegions: [],
    //drop down selected data
    workflowType: null,
    workflowParameter: null,
    workflowParameterDefinition: null,
    billingDivision: [],
    statuses: null,
    approver: [],
    approverType: [],
    currentApprover: null,
    newApprover: null,

    paymentMethod: [],
    selectedCostRegion: [],
    costRegion: [],
    // for delete workflow
    itemToDelete: null,

    //other
    redirect: false,
    Workflows: [],
    formattedApproverModel: [],
    skip: 0,
    take: 15,
    incompleteWorkflowErrorVisible: false,
    toWorkflow: false,
    deactivePopupVisible: false,
    deActivateWorkflowId: 0,
    activatePopupVisible: false,
    activateWorkFlowId: 0,
    OPID: [],
    UnFreezeApproveDatas:[],
    popupVisible: false,
    togglePopupVisible: false,
    workflowNameToolTipOpen: false,
    sort:[{ field: '', dir: '' }],
    viewProjectPopupVisible: false,
    viewUnFreezeRequestPopUpVisible:false,
    loading: false,

    showConfirmDialog: false,
    confirmDialogAction: null,
    disabled : false,
    changeApproverBtnChecked: false,
    changeApproverPopupVisible: false,
    changeCriteriaPopupVisible: false,
    workflowReplaceResponseDialogVisible: false,
    workflowParameterReplaceResponseDialogVisible: false,
    delegateTillFieldVisible: false,
    workflowsRepleaced: [],
    workflowsWithParametersSelectedToBeReplaced: [],
    delegateTillDate: null,
    workflowFunctionType: '',
    statusArray: ['Active', 'Inactive'],
    searchStatus: 'Active',
    workflowCriteriaDefinition: null,
    allProjects: [],
    WorkflowsForExcel:[],
    allPendingApprovalUnFreezeRequests: [],
    keyId:"",
    viewResourceRequestPopupVisible: false,
    pendingRRApprovals: [],
    allPendingReviseCMSRequests: [],
    disableReplaceParaBtn: false,
    workflowTypeInReplace: null,
    workflowParameterReplace: null,
    workflowParameterDefinitionReplace: null,
    practiceDivisionMap: [],
    allPendingInvoiceRequests: [],
    invoiceRequestData: [],
    viewInvoiceRequestPopUpVisible: false
  };

  unsubscribe = (id) =>{
    this.setState({
      keyId:id
      });
  }

  subscribe = async(id) =>{
      if(this.state.keyId !== 10)
      {
        await this.setState({
          keyId:id
        });
        if(this.state.keyId === 10)
        {
          //await this.retrieveAllActiveProjects();
          await this.retrieveAllUnFreezeRequest();
          await this.populateBillingDivision();
          await this.populateStatuses();
          await this.populateRegion();
          await this.populateWorkflowType();
          await this.populateWorkflowParameter();
          await this.populateApprovers();
          await this.populatePaymentmethod();
          await this.populateBillingRegion();
          await this.populateResourceRegion();
          await this.populateApproverTypes();     
          await this.populatePractices();
          await this.populateProjectTypes();     
          await this.retrieveAllPendingReviseCMSRequests();
          await this.populatePracticeDivisionMapping();
          await this.retrieveAllPendingInvoiceRequests();
        }        
      }
  }

  componentWillUnmount() {
    this.unsubscribe(10);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setHeaderTitle();
    this.subscribe(10); 
  }

  setHeaderTitle = async () => {
    if (this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Workflows');
    }
  };

  populateSearch = async () => {
    this.setState({
      loading: true,
      activatePopupVisible: false,
      delegateTillDate: new Date(moment(new Date()).add(7, 'days'))
    });

    await searchWorkflowNew(this.state.SearchRequest).then(async res => {
      if(res.data && res.data.rows)
      {
        let workflowIds = res.data.rows.map(a=>a.id);

        // For status change workflows
        let selectedWorkflows = res.data.rows;
        await this.retrieveProjectsforWorkflow(workflowIds);

        let projects = this.state.allProjects;

        if(selectedWorkflows && selectedWorkflows.length > 0){
          for(let wf of selectedWorkflows){

            if(wf.WorkflowTypeId == 5){
              let pendingWfCount = 0;
              let statusPendingProjectsCrs = [];

              if(projects && projects.length > 0){
                for(let project of projects){

                  if(project.ActivityLogs && project.ActivityLogs.length > 0){
                    project.ActivityLogs = project.ActivityLogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    if((project.ActivityLogs[0].ApprovalStatus && project.ActivityLogs[0].ApprovalStatus === 'PENDING') && 
                      (project.ActivityLogs[0].WorkflowId && project.ActivityLogs[0].WorkflowId == wf.id)){
                        pendingWfCount ++;
                        project.Type = 'Project';
                        statusPendingProjectsCrs.push(project);
                    }
                  }

                  if(project.ChangeRequests && project.ChangeRequests.length > 0){
                    for(let cr of project.ChangeRequests){
                      if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
                        cr.ActivityLogs = cr.ActivityLogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                        if((cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING') && 
                          (cr.ActivityLogs[0].WorkflowId && cr.ActivityLogs[0].WorkflowId == wf.id)){
                            pendingWfCount ++;
                            cr.Type = 'ChangeRequest';
                            statusPendingProjectsCrs.push(cr);
                        }
                      }
                    }
                  }
                }
              }

              wf.PendingCount = pendingWfCount;
              wf.StatusPendingProjectsCrs = statusPendingProjectsCrs;
            }
          }
        }

        //let sortedWorkfolw = res.data.rows.sort((a, b) => b.id - a.id);

        const work ={
          rows: res.data.rows.map(dataItem => Object.assign({ Selected: false }, dataItem)),
          count: res.data.count
        }

        await this.setState({
          Workflows: work,
          loading: false
        });
          
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };
  pageChange = event => {

    this.setState({
      skip: event.page.skip,
      take: event.page.take
    }, () => this.submitSearch(null));

  };

  populateWorkflowType = async () => {
    await getWorkflowType()
      .then(response => {
        this.workflowTypeDataAll = response.data;
        this.setState({
          workflowTypeData: response.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };
  populateWorkflowParameter = async () => {
    await getWorkflowParameter()
      .then(response => {
        this.workflowParameterAll = response.data;
        let newParam = [];
        let parameterName;
        this.workflowParameterAll.forEach(function(parameter) {
          if (parameter.id === 3) {
            parameterName = 'Value Discount';
            const parameter_ = {
              id: parameter.id,
              Name: parameterName
            };
            newParam.push(parameter_);
          } else if (parameter.id === 5) {
            parameterName = 'Department';
            const parameter_ = {
              id: parameter.id,
              Name: parameterName
            };
            newParam.push(parameter_);
          } else if (parameter.id === 6) {
            parameterName = 'Billing Region';
            const parameter_ = {
              id: parameter.id,
              Name: parameterName
            };
            newParam.push(parameter_);
          } else if (parameter.id === 7) {
            parameterName = 'Billing Method';
            const parameter_ = {
              id: parameter.id,
              Name: parameterName
            };
            newParam.push(parameter_);
          } else if (parameter.id === 8) {
            parameterName = 'Negative Discount';
            const parameter_ = {
              id: parameter.id,
              Name: parameterName
            };
            newParam.push(parameter_);
          } else if (parameter.id === 10) {
            parameterName = 'Request Resource Region';
            const parameter_ = {
              id: parameter.id,
              Name: parameterName
            };
            newParam.push(parameter_);
          } else if (parameter.id === 11) {
            parameterName = 'Allocated Resource Region';
            const parameter_ = {
              id: parameter.id,
              Name: parameterName
            };
            newParam.push(parameter_);
          } else if (parameter.id === 12) {
            parameterName = 'Previous Status';
            const parameter_ = {
              id: parameter.id,
              Name: parameterName
            };
            newParam.push(parameter_);
          }else if (parameter.id === 13) {
            parameterName = 'Current Status';
            const parameter_ = {
              id: parameter.id,
              Name: parameterName
            };
            newParam.push(parameter_);
          }else if (parameter.id === 14) {
            parameterName = 'End Status';
            const parameter_ = {
              id: parameter.id,
              Name: parameterName
            };
            newParam.push(parameter_);
          }else {
            const parameter_ = {
              id: parameter.id,
              Name: parameter.Name
            };
            newParam.push(parameter_);
          }
        });
        this.setState({
          workflowParameterData: newParam
        });
        this.workflowParameterAll = newParam;
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };
  populateWorkflowParameterDefinition = async (parameterId) => {
    await getWorkflowDefinitionsIntegrated()
      .then(response => {
        this.workflowParameterDefinitionAll = response.data;
        let LessThan;
        let GraterThan;
        let Equal;
        let Between;
        let definition_array = [];
        let definitionOp_array = [];

        this.workflowParameterDefinitionAll.forEach(function(dataList) {
          // Revenue , netProfit, Discount, Contingecy, Negative Discount, Cost Variation
          if (parameterId === dataList.ParameterId && (dataList.ParameterId === 1 || dataList.ParameterId === 2 || dataList.ParameterId === 3 || dataList.ParameterId === 4 || dataList.ParameterId === 8 || dataList.ParameterId === 9)) {
            definitionOp_array.push(dataList);
          }
        });

        definitionOp_array.forEach(function(data) {
          //grater than
          if (data.OperatorId === 1) {
            GraterThan = 'Greater than ' + data.Value2;
            const GraterThan_ = {
              id: data.id,
              Name: GraterThan,
              Value1: data.Value1,
              Value2: data.Value2,
              OperatorId: data.OperatorId,
              ParameterId: data.ParameterId
            };
            definition_array.push(GraterThan_);
          }
          //less than
          if (data.OperatorId === 2) {
            LessThan = 'Less than ' + data.Value2;
            const LessThan_ = {
              id: data.id,
              Name: LessThan,
              Value1: data.Value1,
              Value2: data.Value2,
              OperatorId: data.OperatorId,
              ParameterId: data.ParameterId
            };
            definition_array.push(LessThan_);
          }
          //equal than
          if (data.OperatorId === 3) {
            Equal = 'Equal to ' + data.Value2;
            const Equal_ = {
              id: data.id,
              Name: Equal,
              Value1: data.Value1,
              Value2: data.Value2,
              OperatorId: data.OperatorId,
              ParameterId: data.ParameterId
            };
            definition_array.push(Equal_);
          }
          //between
          if (data.OperatorId === 4) {
            Between = 'Between ' + data.Value1 + '-' + data.Value2;
            const Between_ = {
              id: data.id,
              Name: Between,
              Value1: data.Value1,
              Value2: data.Value2,
              OperatorId: data.OperatorId,
              ParameterId: data.ParameterId
            };
            definition_array.push(Between_);
          } else {
          }
        });
        this.setState({
          workflowParameterDefinitionData: definition_array
        });

        //Billing Division
        if (parameterId === 5) {
          this.setState({
            workflowParameterDefinitionData: this.state.billingDiviData.filter(el => el.IsActive === true && el.SFCode !== null)
          });
        }
        // statuses
        if (parameterId === 12 || parameterId === 13 || parameterId === 14) {
          this.setState({
            workflowParameterDefinitionData: this.state.statusData
          });
        }
        //Billing Region
        else if (parameterId === 6) {
          this.setState({
            workflowParameterDefinitionData: this.state.costRegions
          });
        }
        //Request Resource Region
        else if (parameterId === 10) {
          this.setState({
            workflowParameterDefinitionData: this.state.resourceRegions
          });
        }
        //Allocated Resource Region
        else if (parameterId === 11) {
          this.setState({
            workflowParameterDefinitionData: this.state.resourceRegions
          });
        }
        //Payment Method
        else if (parameterId === 7) {
          this.setState({
            workflowParameterDefinitionData: this.state.paymentMethodsWithAll
          });
        }
        // Practice
        else if (parameterId === 15) {
          this.setState({
            workflowParameterDefinitionData: this.state.practiceData
          });
        }
      });
  };

  populateBillingDivision = async () => {
    await getBillingDiviIntegrated()
      .then(response => {
        this.billingDiviDataAll = response.data;
        this.setState({
          billingDiviData: response.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populatePractices = async () => {
    await getPracticeTypesIntegrated()
      .then(response => {
        this.practiceAll = response.data;
        this.setState({
          practiceData: response.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populatePracticeDivisionMapping = async () => {
    await getAllPracticeDivisionMapIntegrated()
      .then(response => {
          this.setState({
              practiceDivisionMap: response.data
          });
      })
      .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

  };

  populateProjectTypes = async () => {
    await getProjectTypes()
      .then(response => {
        this.engagementTypesAll = response.data;
        this.setState({
          engagementTypes: response.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateStatuses = async () => {
    await getProjectStatuses()
        .then(res => {
          this.statusAll = res.data;
          this.setState({
            statusData: res.data
          });
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
  };

  populateApprovers = async () => {
    await getProjectResources()
      .then(response => {
        this.approversDataAll = response.data;
        this.activeApproversDataAll = this.approversDataAll.filter(function(approver) {
          return approver.IsActive == true;
        });
        this.setState({
          approversData: response.data,
          newApproversData: this.activeApproversDataAll,
          currentApproversData: response.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateApproverTypes = async () => {
    await getApproverTypesIntegrated()
      .then(response => {
        this.approverTypesAll = response.data;
        this.setState({
          approverTypeData: response.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
      this.setState({
        loading: false
      });
  };

  populatePaymentmethod = async () => {
    const paymentMethodsAll = [];
    await getPaymentMethodsIntegrated()
      .then(response => {
        response.data.forEach(function(paymentMethod) {
          const paymentMethod_ = {
            id: paymentMethod.id,
            Name: paymentMethod.Name
          };
          paymentMethodsAll.push(paymentMethod_);
        });
        const paymentMethod_ = { id: 4, Name: 'All' };
        paymentMethodsAll.push(paymentMethod_);

        this.setState({
          paymentMethods: response.data,
          paymentMethodsWithAll: paymentMethodsAll
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateBillingRegion = async () => {
    await getRegionsIntegrated()
      .then(response => {
        this.setState({
          costRegions: response.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateResourceRegion = async () => {
    await getResourceRegionsIntegrated()
      .then(response => {
        this.setState({
          resourceRegions: response.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateRegion = async () => {
    await getResourceRegionsIntegrated()
      .then(response => {
        this.setState({
          regionData: response.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  // Retrieve all active projects
  retrieveAllActiveProjects = async () => {
    this.setState({
      loading: true
    });
    try {
      const projects = await getAllProjectsForWorkflows();
      this.setState({
        allProjects: projects.data
      });
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  };


  // Retrieve all active projects
  retrieveProjectsforWorkflow = async (workflowIds) => {
    try {
      const postData = {
        workflowIds:workflowIds
      }
      const projects = await getProjectsForWorkflows(postData);
      this.setState({
        allProjects: projects.data
      });
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  };

   // Retrieve all un freeze request
   retrieveAllUnFreezeRequest = async () => {
    try {
      const unFreezeRequestApprovalPending = await getAllUnFreezeRequestTimeSheetForWorkflows();

      this.setState({
        allPendingApprovalUnFreezeRequests: unFreezeRequestApprovalPending.data
      });
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  };

  retrieveAllPendingReviseCMSRequests = async () => {
    try {
      const allPendingReviseCMSRequests = await getAllPendingReviseCMSRequests();
      this.setState({
        allPendingReviseCMSRequests: allPendingReviseCMSRequests.data
      });
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  };

  retrieveAllPendingInvoiceRequests = async () => {
    try {
      const allPendingInvoiceRequests = await getAllPendingInvoiceRequests();
      this.setState({
        allPendingInvoiceRequests: allPendingInvoiceRequests.data
      });
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  };

  getPracticesForDepartment = async (wfDepartmentIds) => {
    let practices = [];
    if (wfDepartmentIds && wfDepartmentIds.length > 0) {
        let practicesForDivision = this.state.practiceDivisionMap.filter(item => wfDepartmentIds.includes(item.DepartmentCodeSF));
        for (const practice of practicesForDivision) {
            let selectedPractice = this.practiceAll.filter(item => item.SFCode === practice.PracticeCodeSF);
            practices.push(selectedPractice[0].id);
        }
    }
    return practices;
  };

  getDepartmentsForPractice = async (wfPracticeIds) => {
    let departments = [];
    if (wfPracticeIds && wfPracticeIds.length > 0) {
        let departmentsForPractice = this.state.practiceDivisionMap.filter(item => wfPracticeIds.includes(item.PracticeCodeSF));
        for (const practice of departmentsForPractice) {
            let selectedDepartment = this.billingDiviDataAll.filter(item => item.SFCode === practice.DepartmentCodeSF);
            departments.push(selectedDepartment[0].id);
        }
    }
    return departments;
  };

  filterChangeCombo = event => {
    clearTimeout(this.timeout);
    if (event && event.target && event.target.name) {
      const field = event.target.name;
      this.timeout = setTimeout(() => {
        switch (field) {
          case 'workflowType': {
            this.setState({
              workflowTypeData: this.filterComboData(
                event.filter,
                this.workflowTypeDataAll
              ),
              workflowParameter: null,
              workflowParameterDefinition: null
            });
            break;
          }
          case 'billingDivision': {
            this.setState({
              billingDiviData: this.filterComboData(
                event.filter,
                this.billingDiviDataAll
              )
            });
            break;
          }
          case 'approver': {
            this.setState({
              approversData: this.filterComboData(
                event.filter,
                this.approversDataAll
              )
            });
            break;
          }
          case 'approverType': {
            this.setState({
              approverTypeData: this.filterComboData(
                event.filter,
                this.approverTypesAll
              )
            });
            break;
          }
          case 'currentApprover': {
            this.setState({
              currentApproversData: this.filterComboData(
                event.filter,
                this.approversDataAll
              )
            });
            break;
          }
          case 'newApprover': {
            this.setState({
              newApproversData: this.filterComboData(
                event.filter,
                this.activeApproversDataAll
              )
            });
            break;
          }
          case 'workflowParameter': {
            this.setState({
              workflowParameterData: this.filterComboData(
                event.filter,
                this.workflowParameterAll
              )
            });
            break;
          }
          case 'workflowParameterDefinition': {
            this.setState({
              workflowParameterDefinition: this.filterComboData(
                event.filter,
                this.workflowParameterDefinitionAll
              )
            });
            break;
          }
          default: {
          }
        }
      }, 500);
    }
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  handleChangeDropDown = async event => {
    if (event && event.target && event.target.value) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
      this.setState({ [event.target.name]: null });
    }
  };

  handleChangeWorkflowTypeReplace = async event => {
    if (event && event.target && event.target.value) {
      const valueObj = event.target.value;
      let workflowParameters = [];

      if (valueObj.id === 1) { // CMS wf
        let cmsParameters = [1,2,3,4,5,6,7,8,15,16];
        workflowParameters = this.workflowParameterAll.filter(obj => cmsParameters.includes(obj.id))
      } else if(valueObj.id === 2) { // Resource Request Approval wf
        let rrParameters = [5,6];
        workflowParameters = this.workflowParameterAll.filter(obj => rrParameters.includes(obj.id))
      } else if(valueObj.id === 3) { // Resource Allocation Approval wf
        let raParameters = [9, 10, 11];
        workflowParameters = this.workflowParameterAll.filter(obj => raParameters.includes(obj.id))
      } else if(valueObj.id === 4) { // Factory Pool wf
        workflowParameters = [];
      } else if(valueObj.id === 5) { // Status Change wf
        let statusParameters = [1, 5, 6, 12, 13, 14];
        workflowParameters = this.workflowParameterAll.filter(obj => statusParameters.includes(obj.id))
      } else if(valueObj.id === 6) { // Time unfreeze wf
        let timeParameters = [5, 15];
        workflowParameters = this.workflowParameterAll.filter(obj => timeParameters.includes(obj.id))
      } else if(valueObj.id === 7) { // revise cms wf
        let timeParameters = [5, 6, 15];
        workflowParameters = this.workflowParameterAll.filter(obj => timeParameters.includes(obj.id))
      } else if(valueObj.id === 8) { // Invoice request wf
        let timeParameters = [6];
        workflowParameters = this.workflowParameterAll.filter(obj => timeParameters.includes(obj.id))
      } 

      await this.setState({ 
        [event.target.name]: event.target.value,
        workflowParameterData:  workflowParameters,
        workflowParameterReplace: null,
        workflowParameterDefinitionReplace: null
      });

    } else {
      this.setState({ 
        [event.target.name]: null,
        workflowParameterReplace: null,
        workflowParameterDefinitionReplace: null
       });
    }
  };

  handleChangeDropDownWorkflowType = async event => {
    if (event && event.target && event.target.value) {
      if(event.target.value.id == 1){
        var rrParams = [];

        rrParams.push(this.workflowParameterAll[0]);
        rrParams.push(this.workflowParameterAll[1]);
        rrParams.push(this.workflowParameterAll[2]);
        rrParams.push(this.workflowParameterAll[6]);
        rrParams.push(this.workflowParameterAll[7]);
        this.setState({ workflowParameterData: rrParams });
      } else if(event.target.value.id == 2){
        var rrParams = [];
        rrParams.push(this.workflowParameterAll[3]);
        rrParams.push(this.workflowParameterAll[5]);
        this.setState({ workflowParameterData: rrParams });
      }else if(event.target.value.id == 3){
        var rrParams = [];
        rrParams.push(this.workflowParameterAll[8]);
        rrParams.push(this.workflowParameterAll[9]);
        rrParams.push(this.workflowParameterAll[10]);
        this.setState({ workflowParameterData: rrParams });
      }else if(event.target.value.id == 4){
        this.setState({ workflowParameterData: [] });
      }
      this.setState({ [event.target.name]: event.target.value });
    } else {
      this.setState({ [event.target.name]: null });
    }
  };

  handleChangecriteriaDropDown = async event => {
    if (event && event.target && event.target.value) {
      this.setState({ [event.target.name]: event.target.value });
      await this.populateWorkflowParameterDefinition(event.target.value.id);
    } else {
      this.setState({
        [event.target.name]: null,
        workflowParameterDefinition:null
      });
    }
  };

  setRedirect = () => {
    this.setState({
      redirect: true
    });
  };

  handleOnChangeInput = event => {
    const field = event.target.name;
    const value = event.target.value;

    this.setState(prevState => {
      return {
        SearchRequest: { ...prevState.SearchRequest, [field]: value }
      };
    });
  };

  handleCheckedInput = event => {
    const field = event.target.name;
    const value = event.target.checked;

    this.setState(prevState => {
      return {
        SearchRequest: { ...prevState.SearchRequest, [field]: value }
      };
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/admin/createworkflow/createworkflow"/>;
    }
  };

  cancelSearch = async event => {
    await this.setState({
      SearchRequest: {
        workflowTypeId: null,
        billingDivisionId: [],
        approverId: [],
        workflowName: '',
        paymentMethodId: [],
        regionId: [],
        withPendingApprovals: false,
        approverTypeId: [],
        workflowParameterId: null,
        workflowCriteriaId: null,
        status: null,
        skip: 0,
        take: 15,
        sort:[{ field: '', dir: '' }],
        isFromExcelExport:false
      },

      //      costRegions: [],
      //drop down selected data
      costRegion: [],
      paymentMethod: [],
      selectedCostRegion: [],
      workflowParameter: null,
      workflowParameterDefinition: null,
      withPendingApprovals: null,

      //drop down selected data
      workflowType: null,
      billingDivision: [],
      approver: [],
      approverType: [],

      //other
      redirect: false,
      Workflows: [],
      searchStatus: null
    });
    await this.submitSearch();
  };

  approverTable = Card => {
    return (
      <React.Fragment>
        <div className="row" disabled>
          <div className="col-md-12 add-level-overflow">
            {Card &&
            Card.map((mainItem, index) => {
              return (
                <div
                  className="col-md-4 add-level modified"
                  key={index}
                  disabled
                >
                  <div className="add-level-wrap" key={index} disabled>
                    {mainItem.mainApproverCardHorizontal &&
                    mainItem.mainApproverCardHorizontal.map(
                      (item, indexVer) => {
                        return item.approverCardVertical.map(
                          (itemVert, indexPar) => {
                            return this.approverList(
                              itemVert,
                              index,
                              indexVer,
                              indexPar
                            );
                          }
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  };
  selectComponement = (Approvers, index, indexVer, indexPar, i) => {
    let app = [];
    Approvers['SelectedData'].map(item =>
      app.push({ id: item.SelectedId, Name: item.SelectedValue })
    );
    return (
      <div key={`${index}/${indexVer}/${indexPar}/${i}`}>
        <MultiSelect
          disabled
          key={`${index}/${indexVer}/${indexPar}/${i}`}
          placeholder="Please select"
          name={`${index}/${indexVer}/${indexPar}/${i}`}
          data={Approvers['ApproverData']}
          value={app}
          textField="Name"
          dataItemKey="id"
          required={true}
          filterable={true}
          popupSettings={this.popupSet}
        />
      </div>
    );
  };

  approverList = (CardApprovers, index, indexVer, indexPar) => {
    return (
      <div
        className="row add-parallel"
        key={`${index}/${indexVer}/${indexPar}`}
        disabled
      >
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-5">
              <label>Approval Type:</label>
            </div>
            <div className="col-md-7">
              <Input
                autoComplete="off"
                type="text"
                name={`${index}/${indexVer}/${indexPar}`}
                maxLength="50"
                value={CardApprovers.approverType.ApproverTypeName}
                disabled={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" disabled>
              <div className="card" disabled>
                <div className="card-body" disabled>
                  <div className="row add-item" disabled>
                    <div className="col-md-12" disabled>
                      {CardApprovers.approverListVer.map((item, i) => {
                        return this.selectComponement(
                          item,
                          index,
                          indexVer,
                          indexPar,
                          i
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  openApprovers = formattedApproverModel => {
    this.setState({
      popupVisible: true,
      formattedApproverModel: formattedApproverModel
    });
  };
  closeApprover = () => {
    this.setState({ popupVisible: false });
  };

  submitSearch = async event => {
    if (event) {
    event.preventDefault();
    await this.setState({
      skip: 0,
      take: 15,
      sort: [{ field: '', dir: '' }],
    });
  }
    const userDetails = window.LOGGED_USER;

    this.setState({
      loading: true,
      disableReplaceParaBtn: false
    });

    if (userDetails)
      await this.setState(
        prevState => {
          const SRequest = {
            ...prevState.SearchRequest,
            workflowTypeId:
              prevState.workflowType && prevState.workflowType.id
                ? prevState.workflowType.id
                : null,
                billingDivisionId: prevState.billingDivision && prevState.billingDivision.length > 0 ? prevState.billingDivision.map(value => value.id) : [],
                approverId: prevState.approver && prevState.approver.length > 0 ? prevState.approver.map(value => value.id) : [],
                paymentMethodId: prevState.paymentMethod && prevState.paymentMethod.length > 0 ? prevState.paymentMethod.map(value => value.id) : [],
                regionId: prevState.costRegion && prevState.costRegion.length > 0 ? prevState.costRegion.map(value => value.id) : [],
                approverTypeId: prevState.approverType && prevState.approverType.length > 0 ? prevState.approverType.map(value => value.id) : [],
            workflowParameterId:
              prevState.workflowParameter && prevState.workflowParameter.id
                ? prevState.workflowParameter.id
                : null,
            workflowCriteriaId: prevState.workflowParameterDefinition && prevState.workflowParameterDefinition.id
                ? prevState.workflowParameterDefinition.id
                : null,
            status:
              prevState.searchStatus
                ? prevState.searchStatus
                : null,
              isFromExcelExport:false,
              skip: event ? 0 : this.state.skip,
              take: event ? 15 : this.state.take,
              sort: event || this.state.sort.length === 0 ? [{ field: '', dir: '' }] : this.state.sort
          };

          return {
            SearchRequest: SRequest
          };
        },
        () => {
          if (this.state.SearchRequest.workflowParameterId && !this.state.SearchRequest.workflowCriteriaId){
            this.setState({
              incompleteWorkflowErrorVisible: true,
              showErrorImage: true,
              showSuccessImage: false,
              loading: false,
              dialogMessage: "Please select a workflow definition criterion to complete the search"              
            });
          }
          else{
            searchWorkflowNew(this.state.SearchRequest).then(async res => {
            if(res.data && res.data.rows)
            {
              let workflowIds = res.data.rows.map(a=>a.id);
              await this.retrieveProjectsforWorkflow(workflowIds);
                // For status change workflows
                let projects = this.state.allProjects;
                let selectedWorkflows = res.data.rows;

                let allPendingApprovalUnFreezeRequests = this.state.allPendingApprovalUnFreezeRequests

                if(selectedWorkflows && selectedWorkflows.length > 0){
                  for(let wf of selectedWorkflows){

                    if(wf.WorkflowTypeId == 5){
                      let pendingWfCount = 0;
                      let statusPendingProjectsCrs = [];

                      if(projects && projects.length > 0){
                        for(let project of projects){

                          if(project.ActivityLogs && project.ActivityLogs.length > 0){
                            project.ActivityLogs = project.ActivityLogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                            if((project.ActivityLogs[0].ApprovalStatus && project.ActivityLogs[0].ApprovalStatus === 'PENDING') && 
                              (project.ActivityLogs[0].WorkflowId && project.ActivityLogs[0].WorkflowId == wf.id)){
                                pendingWfCount ++;
                                project.Type = 'Project';
                                statusPendingProjectsCrs.push(project);
                            }
                          }

                          if(project.ChangeRequests && project.ChangeRequests.length > 0){
                            for(let cr of project.ChangeRequests){
                              if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
                                cr.ActivityLogs = cr.ActivityLogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                                if((cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING') && 
                                  (cr.ActivityLogs[0].WorkflowId && cr.ActivityLogs[0].WorkflowId == wf.id)){
                                    pendingWfCount ++;
                                    cr.Type = 'ChangeRequest';
                                    statusPendingProjectsCrs.push(cr);
                                }
                              }
                            }
                          }
                        }
                      }

                      wf["PendingCount"] = pendingWfCount;
                      wf.StatusPendingProjectsCrs = statusPendingProjectsCrs;
                    }
                    else if(wf.WorkflowTypeId == 6)
                    {
                      let penlist =[];
                      if(allPendingApprovalUnFreezeRequests && allPendingApprovalUnFreezeRequests.length > 0) 
                      {
                        for(let request of allPendingApprovalUnFreezeRequests)
                        {
                          if(request.Approval && request.Approval.length>0)
                          {
                            let filteredList = [];
                            filteredList = request.Approval.filter(a=>a.WorkflowId == wf.id); 
                            if(filteredList && filteredList.length > 0)
                            {
                              penlist.push({
                                EntryUser:(request.EntryUser)?request.EntryUser.displayName:'',
                                ApproverUser:(request.ApproverUser)?request.ApproverUser.displayName:'',
                                Comment:request.Comment,
                                pendingApprovalList:filteredList
                              });
                            }  
                          }                     
                        }
                        wf.pendingApprovalUnFreezeRequests = penlist;
                        wf["PendingCount"] = penlist.length;
                      }            
                            
                    }else if(wf.WorkflowTypeId == 1){
                      let pendingWfCount = 0;
                      pendingWfCount = wf.CMSheets && wf.CMSheets.length > 0 ? wf.CMSheets.length : 0;
                      wf["PendingCount"] = pendingWfCount;
                    }
                    else if(wf.WorkflowTypeId == 2 || wf.WorkflowTypeId == 3){
                      let pendingWfCount = 0;
                      if ((wf.ResourceRequests && wf.ResourceRequests.length) || 
                      (wf.ResourceRequestSubLines && wf.ResourceRequestSubLines.length)) {
                        pendingWfCount = wf.ResourceRequests.length + wf.ResourceRequestSubLines.length;
                      }
                      wf["PendingCount"] = pendingWfCount;
                    } else if(wf.WorkflowTypeId == 7){
                      let penlist =[];
                      if(this.state.allPendingReviseCMSRequests && this.state.allPendingReviseCMSRequests.length > 0) {
                        for(let request of this.state.allPendingReviseCMSRequests) {
                          if(request.Approval && request.Approval.length>0) {
                            let filteredList = [];
                            filteredList = request.Approval.filter(a=>a.WorkflowId == wf.id); 
                            if(filteredList && filteredList.length > 0) {
                              const cmsheet = request.CrId ? request.ChangeRequest.CMSheets[0] : request.Project.CMSheets[0]
                              const pendingApproval = {
                                CMSheet: cmsheet ? cmsheet : [],
                                Type: request.CrId ? 'ChangeRequest' : 'Project',
                                Project: request.Project,
                                ChangeRequest: request.ChangeRequest,
                                pendingApprovalList:filteredList,
                              }
                              penlist.push(pendingApproval);
                            }  
                          }                     
                        }
                        wf.pendingReviseCMSRequests = penlist;
                        wf["PendingCount"] = penlist.length;
                      }  
                    } else if(wf.WorkflowTypeId == 8){
                      let penlist =[];
                      if(this.state.allPendingInvoiceRequests && this.state.allPendingInvoiceRequests.length > 0) {
                        for(let request of this.state.allPendingInvoiceRequests) {
                          if(request.Approval && request.Approval.length>0) {
                            let filteredList = [];
                            filteredList = request.Approval.filter(a=>a.WorkflowId == wf.id); 
                            if(filteredList && filteredList.length > 0) {
                              const pendingApproval = {
                                dataItem: request,
                                Type: request.CrId ? 'ChangeRequest' : 'Project',
                                Project: request.Project,
                                ChangeRequest: request.ChangeRequest,
                                pendingApprovalList:filteredList,
                              }
                              penlist.push(pendingApproval);
                            }  
                          }                     
                        }
                        wf.pendingInvoiceRequests = penlist;
                        wf["PendingCount"] = penlist.length;
                      }  
                    }
                  }
                }

                const work ={
                  rows: res.data.rows.map(dataItem => Object.assign({ Selected: false }, dataItem)),
                  count: res.data.count
                }

                  await this.setState({
                    Workflows: work,
                    loading: false
                  });

                  let billingDivisionfilteredData = [];

                  if (this.state.billingDivision && this.state.billingDivision.length > 0) {
                    let workflows = this.state.Workflows;
                    let bi = this.state.billingDivision.map(value => value.id);
                    workflows.rows.map(item =>
                      item.WorkflowCriteria.map(workCr =>
                        (workCr.WorkflowParameter.Name && workCr.Value1) ? (
                          workCr.WorkflowParameter['Name'] === 'Department' &&
                          bi.includes(workCr.Value1)
                            ? billingDivisionfilteredData.push(item)
                            : ''
                        ) : ''
                      )
                    );
                  const work ={
                    rows: billingDivisionfilteredData.map(dataItem => Object.assign({ Selected: false }, dataItem)),
                    count: this.state.Workflows.count
                  }

                    await this.setState({
                      Workflows:work
                    });
                    
                  }
            }
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            }); 
          }
        }
      );
  };

  OnDeActivate = async (event, id) => {
    if (event) {
      event.preventDefault();
    }
    await this.setState({
      deActivateWorkflowId: id
    });
    this.confirmationClose();
  };

  OnActivate = async (event, id) => {
    if (event) {
      event.preventDefault();
    }
    await this.setState({ activateWorkFlowId: id });
    await this.activateConfirmationClose();
  };

  confirmationClose = async () => {
    await this.setState({
      deactivePopupVisible: !this.state.deactivePopupVisible
    });
  };

  activateConfirmationClose = () => {
    this.setState({
      activatePopupVisible: !this.state.activatePopupVisible
    });
  };

  activateConfirmationOk = async () => {
    await updateWorkfowStatus({ IsActive: true }, this.state.activateWorkFlowId)
      .then(response => {
        this.toggleDialog(
          'The workflow has been activated successfully',
          'Success'
        );
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.submitSearch();
    await this.setState({
      activatePopupVisible: false
    });
  };

  OnViewUnFreezeRequestApproval = async (event, unfreeRequest, wfTypeId) => {
    if (event) {
      event.preventDefault();      
    }
    let UnFreezeApproveData = [];
    if(wfTypeId && wfTypeId == 6){
      if (unfreeRequest && unfreeRequest.length > 0) {
        unfreeRequest.map(async item => {
          UnFreezeApproveData.push(
            {
              id:item.id,
              Name:item.EntryUser?item.EntryUser:item.ApproverUser?item.ApproverUser:'',
              Comment:item.Comment
            }
            );
        });
      }
    }
    
    await this.setState({
      UnFreezeApproveDatas: UnFreezeApproveData
    });

    if (UnFreezeApproveData && UnFreezeApproveData.length > 0) {
      this.UnFreezeRequestDetailsClose();
    }

  }

  OnViewInvoiceRequestApproval = async (event, invoiceRequest, wfTypeId) => {
    if (event) {
      event.preventDefault();      
    }
    let invoiceRequestData = [];
    if(wfTypeId && wfTypeId == 8){
      if (invoiceRequest && invoiceRequest.length > 0) {
        invoiceRequest.map(async item => {
          invoiceRequestData.push(
            {
              id: item.dataItem.id,
              ProjectName: item.dataItem.CrId ? item.ChangeRequest.CRName : item.Project.ProjectName,
              DateRange: item.dataItem.StartDate + ' - ' + item.dataItem.EndDate
            }
            );
        });
      }
    }  
    await this.setState({
      invoiceRequestData: invoiceRequestData
    });

    if (invoiceRequestData && invoiceRequestData.length > 0) {
      this.invoiceRequestDetailsClose();
    }
  }

  OnViewResourceRequest = async (event, workflow, wfTypeId) => {
    if (event) {
      event.preventDefault();
    }

    const viewResReqPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_RES_REQ');
    let pendingRRApprovalsData = [];

    if (workflow) {
      if(wfTypeId && (wfTypeId == 2 || wfTypeId == 3)){
        let projectIds = [];
        if (workflow.ResourceRequests && workflow.ResourceRequests.length > 0) {
          for (const item of workflow.ResourceRequests) {
            if (item.Type === 'ChangeRequest') {
              projectIds.push(item.ChangeRequest.ProjectId)
            } else {
              projectIds.push(item.Project.id)
            }
          }
        }
        if (workflow.ResourceRequestSubLines && workflow.ResourceRequestSubLines.length > 0) {
          for (const item of workflow.ResourceRequestSubLines) {
            if (item.ResourceRequest.Type === 'ChangeRequest') {
              projectIds.push(item.ResourceRequest.ChangeRequest.ProjectId)
            } else {
              projectIds.push(item.ResourceRequest.Project.id)
            }
          }
        }
        const projData = {
          projectIds: projectIds
        }
        let projects = []
        await getProjectsforRRview(projData).then(response => {
          if (response && response.data && response.data.length > 0) {
            projects = response.data;
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
        if (workflow.ResourceRequests && workflow.ResourceRequests.length > 0) {
          for (const item of workflow.ResourceRequests) {
            if (item.Type === 'ChangeRequest') {
              if (viewResReqPermission && viewResReqPermission.length > 0) {
                let filteredProject = projects.filter(obj => obj.id === item.ChangeRequest.ProjectId);
                let filteredCR = filteredProject[0].ChangeRequests.filter(obj => obj.id === item.ProjectId);
                pendingRRApprovalsData.push({
                  OPID: filteredProject[0].UniqueId,
                  ChangeRequest: filteredCR[0],
                  Type: item.Type,
                  Project: filteredProject[0]
                });
              }
            } else {
              if (viewResReqPermission && viewResReqPermission.length > 0) {
                let filteredProject = projects.filter(obj => obj.id === item.Project.id);
                pendingRRApprovalsData.push({
                  OPID: filteredProject[0].UniqueId,
                  Project: filteredProject[0],
                  Type: item.Type
                });
              }
            }
          }
        }
        if (workflow.ResourceRequestSubLines && workflow.ResourceRequestSubLines.length > 0) {
          for (const item of workflow.ResourceRequestSubLines) {
            if (item.ResourceRequest) {
              if (item.ResourceRequest.Type === 'ChangeRequest') {
                if (viewResReqPermission && viewResReqPermission.length > 0) {
                  let filteredProject = projects.filter(obj => obj.id === item.ResourceRequest.ChangeRequest.ProjectId);
                  let filteredCR = filteredProject[0].ChangeRequests.filter(obj => obj.id === item.ResourceRequest.ProjectId)
                  pendingRRApprovalsData.push({
                    OPID: filteredProject[0].UniqueId,
                    ChangeRequest: filteredCR[0],
                    Type: item.Type,
                    Project: filteredProject[0]
                  });
                }
              } else {
                if (viewResReqPermission && viewResReqPermission.length > 0) {
                  let filteredProject = projects.filter(obj => obj.id === item.ResourceRequest.Project.id)
                  pendingRRApprovalsData.push({
                    OPID: filteredProject[0].UniqueId,
                    Project: filteredProject[0],
                    Type: item.ResourceRequest.Type
                  });
                }
              }
            }     
          }
        }
      }
    }

    await this.setState({
      pendingRRApprovals: pendingRRApprovalsData
    });

    if (pendingRRApprovalsData && pendingRRApprovalsData.length > 0) {
      this.resourceRequestDetailsClose();
    } else {
      this.toggleDialog('Permission denied to view this Project/CR', 'Permission Denied');
    }
  };

  OnViewProject = async (event, cmSheet, wfTypeId) => {
    if (event) {
      event.preventDefault();
    }

    const viewProjOrCRPermission = window.USER_PERMISSION.filter(obj => obj === 'VW_PRJ');
    let OPIDS = [];

    if (cmSheet && cmSheet.length > 0) {
      if(wfTypeId && wfTypeId == 1){
        await Promise.all(
          cmSheet.map(async item => {
            let project;
            if (item.Type === 'ChangeRequest') {
              if ((viewProjOrCRPermission && viewProjOrCRPermission.length > 0)) {
                OPIDS.push({
                  OPID: item.ChangeRequest.UniqueId,
                  ChangeRequest: item.ChangeRequest,
                  Type: item.Type,
                  WorkflowType: 'CMSheet',
                  CMSheet: item
                });
              }
            } else {
              if ((viewProjOrCRPermission && viewProjOrCRPermission.length > 0)) {
                OPIDS.push({
                  OPID: item.Project.UniqueId,
                  Project: item.Project,
                  Type: item.Type,
                  WorkflowType: 'CMSheet',
                  CMSheet: item
                });
              }
            }
          })
        );
      }

      // For status change pending approvals
      if(wfTypeId && wfTypeId == 5){
        await Promise.all(
          cmSheet.map(async item => {
            let project;
            if (item.Type === 'ChangeRequest') {
              if ((viewProjOrCRPermission && viewProjOrCRPermission.length > 0)) {
                OPIDS.push({
                  OPID: item.UniqueId,
                  ChangeRequest: item,
                  Type: item.Type
                });
              }
            } else {
              if ((viewProjOrCRPermission && viewProjOrCRPermission.length > 0)) {
                OPIDS.push({
                  OPID: item.UniqueId,
                  Project: item,
                  Type: item.Type
                });
              }
            }
          })
        );
      }

      if(wfTypeId && wfTypeId == 7){
        await Promise.all(
          cmSheet.map(async item => {
            if (item.Type === 'ChangeRequest') {
              if ((viewProjOrCRPermission && viewProjOrCRPermission.length > 0)) {
                OPIDS.push({
                  OPID: item.ChangeRequest.UniqueId,
                  ChangeRequest: item.ChangeRequest,
                  Type: item.Type,
                  WorkflowType: 'ReviseCMSRequest',
                  CMSheet: item.CMSheet
                });
              }
            } else {
              if ((viewProjOrCRPermission && viewProjOrCRPermission.length > 0)) {
                OPIDS.push({
                  OPID: item.Project.UniqueId,
                  Project: item.Project,
                  Type: item.Type,
                  WorkflowType: 'ReviseCMSRequest',
                  CMSheet: item.CMSheet
                });
              }
            }
          })
        );
      }
    }

    await this.setState({
      OPID: OPIDS
    });

    if (OPIDS && OPIDS.length > 0) {
      this.projectDetailsClose();
    } else {
      this.toggleDialog('Permission denied to view this Project/CR', 'Permission Denied');
    }

  };

  projectDetailsClose = async () => {
    await this.setState({
      viewProjectPopupVisible: !this.state.viewProjectPopupVisible
    });
  };

  resourceRequestDetailsClose = async () => {
    await this.setState({
      viewResourceRequestPopupVisible: !this.state.viewResourceRequestPopupVisible
    });
  };

  UnFreezeRequestDetailsClose = async () => {
    await this.setState({
      viewUnFreezeRequestPopUpVisible: !this.state.viewUnFreezeRequestPopUpVisible
    });
  };

  invoiceRequestDetailsClose = async () => {
    await this.setState({
      viewInvoiceRequestPopUpVisible: !this.state.viewInvoiceRequestPopUpVisible
    });
  };

  confirmationOk = async () => {
    await updateWorkfowStatus(
      { IsActive: false },
      this.state.deActivateWorkflowId
    )
      .then(response => {
        this.toggleDialog(
          'The workflow has been deactivated successfully',
          'Success'
        );
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.submitSearch();
    await this.setState({
      deactivePopupVisible: false
    });
  };

  toggleDialogSameUserValidation = async (message, title) => {
    await this.setState({
      changeApproverPopupVisible: !this.state.changeApproverPopupVisible,
      togglePopupVisibleSameUser: !this.state.togglePopupVisibleSameUser,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleDialog = async (message, title) => {
    await this.setState({
      togglePopupVisible: !this.state.togglePopupVisible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleWorkflowNameToolTip = () => {
    this.setState({
      workflowNameToolTipOpen: !this.state.workflowNameToolTipOpen
    });
  };

  //for delete workflow id
  deleteIdConfirmationClose = async () => {
    await this.setState({
      deletePopupVisible: !this.state.deletePopupVisible
    });

  };

  deleteIdConfirmationOk = async () => {
    const deleteItem = this.state.itemToDelete;
    await workfowDelete(deleteItem.id);
    await this.setState({
      deletePopupVisible: false
    });
    this.submitSearch();
  };
  //for delete workflow id
  onDelete = async (event, id) => {
    if (event) {
      event.preventDefault();
      await this.setState({
        itemToDelete: id
      });
      this.deleteIdConfirmationClose();
    }
  };
  // WORKFLOW REPLACE APPROVER changes
  headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    this.state.Workflows.rows.forEach(item => item.Selected = checked);
    this.forceUpdate();
  };

  selectionChange = (event) => {
    event.dataItem.Selected = !event.dataItem.Selected;
    // let selectedWorkflows = this.state.Workflows.rows.filter(resource => resource.Selected === true);
    // let reviseCMSWorkflows = selectedWorkflows.filter(resource => resource.WorkflowTypeId === 7);
    // if (reviseCMSWorkflows && reviseCMSWorkflows.length > 0) {
    //   this.setState({
    //     disableReplaceParaBtn: true,
    //   });

    // } else {
    //   this.setState({
    //     disableReplaceParaBtn: false,
    //   });
    // }
    this.forceUpdate();
  };

  replaceApproverButtonClick = () => {
    
    if(this.state.Workflows && this.state.Workflows.rows)
    {
      const workflows = this.state.Workflows;
      
      let workflowsWithApproversToBeChanged = workflows.rows.filter(resource => resource.Selected === true);

      if (workflowsWithApproversToBeChanged && workflowsWithApproversToBeChanged.length > 0) {
        this.setState({
          changeApproverPopupVisible: true,
          workflowFunctionType: 'Replace'
        });
      } else {
        this.setState({
          showErrorImage: true,
          showSuccessImage: false,
        });
        this.toggleDialog('Select one or more workflows for the approver replacement', 'Error');
      }
    }    
  };

  replaceParameterButtonClick = async () => {
    if(this.state.Workflows && this.state.Workflows.rows)
    {
      const workflows = this.state.Workflows;
      let workflowsWithApproversToBeChanged = workflows.rows.filter(resource => resource.Selected === true);
  
      if (workflowsWithApproversToBeChanged && workflowsWithApproversToBeChanged.length > 0) {
        this.setState({
          changeCriteriaPopupVisible: true,
          workflowFunctionType: 'Replace Parameter'
        });
      } else {
        this.toggleDialog('No workflow is selected to be replaced', 'Error');
        this.setState({
          showErrorImage: true,
          showSuccessImage: false,
        })
      }
    }    
  };

  deleteApproverTypesButtonClick = () => {
    if(this.state.Workflows && this.state.Workflows.rows)
    {
      const workflows = this.state.Workflows;
      let filteredWorkflows = workflows.rows.filter(resource => resource.Selected === true);
  
      if (filteredWorkflows && filteredWorkflows.length > 0) {
        this.setState({
          deleteApproverPopupVisible: true,
          workflowFunctionType: 'Delete'
        });
      } else {
        this.toggleDialog('No workflow is selected to be deleted', 'Error');
      }
    }    
  };

  changeApproverClose = async () => {
    await this.setState({
      workflowTypeData: this.workflowTypeDataAll,
      approverTypeData: this.approverTypesAll,
      currentApproversData: this.approversDataAll,
      newApproversData: this.activeApproversDataAll,
      changeApproverPopupVisible: !this.state.changeApproverPopupVisible,
      currentApprover: null,
      newApprover: null,
      approverType: null
    });
  };

  changeParameterClose = async () => {
    await this.setState({
      workflowTypeData: this.workflowTypeDataAll,
      approverTypeData: this.approverTypesAll,
      workflowParameterData: this.workflowParameterAll,
      workflowParameterDefinitionData: this.workflowParameterDefinitionAll ? this.workflowParameterDefinitionAll : [],
      changeCriteriaPopupVisible: !this.state.changeCriteriaPopupVisible,
      workflowTypeInReplace: null,
      workflowParameterReplace: null,
      workflowParameterDefinitionReplace: null
    });
  };

  deleteApproverClose = async () => {
    await this.setState({
      workflowTypeData: this.workflowTypeDataAll,
      approverTypeData: this.approverTypesAll,
      approversData: this.approversDataAll,
      currentApproversData: this.approversDataAll,
      deleteApproverPopupVisible: !this.state.deleteApproverPopupVisible,
      currentApprover: null,
      approverType: null
    });
  };

  toggleApproverReplaceResponseDialog = () => {
    this.setState({
      workflowReplaceResponseDialogVisible: !this.state.workflowReplaceResponseDialogVisible
    });
  };

  toggleConfirmDialog = async (message, action) => {
    if (this.state.workflowFunctionType === 'Replace Parameter') {
      await this.setState({
        changeCriteriaPopupVisible: false,
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    } else if (this.state.workflowFunctionType === 'Delete') {
      await this.setState({
        deleteApproverPopupVisible: false,
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    } else if (this.state.workflowFunctionType === 'Replace' || this.state.workflowFunctionType === 'Delegate') {
      await this.setState({
        changeApproverPopupVisible: false,
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    }
  };

  toggleParameterReplaceResponseDialog = () => {
    if (this.state.workflowFunctionType === 'Replace Parameter') {
      this.setState({
        workflowTypeData: this.workflowTypeDataAll,
        approverTypeData: this.approverTypesAll, workflowParameterData: this.workflowParameterAll,
        workflowParameterDefinitionData: this.workflowParameterDefinitionAll,
        showConfirmDialog: !this.state.showConfirmDialog,
        workflowTypeInReplace: null,
        workflowParameterReplace: null,
        workflowParameterDefinitionReplace: null
      });
    } else if (this.state.workflowFunctionType === 'Delete') {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        workflowTypeData: this.workflowTypeDataAll,
        approverTypeData: this.approverTypesAll, approversData: this.approversDataAll,
        currentApproversData: this.approversDataAll, currentApprover: null,
        approverType: null
      });
    } else if (this.state.workflowFunctionType === 'Replace' || this.state.workflowFunctionType === 'Delegate') {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        workflowTypeData: this.workflowTypeDataAll,
        approverTypeData: this.approverTypesAll,
        currentApproversData: this.approversDataAll,
        newApproversData: this.activeApproversDataAll,
        currentApprover: null,
        newApprover: null,
        approverType: null
      });
    }
  };

  toggleConfirmOnCloseAfterReplacement = () => {
    if (this.state.workflowFunctionType === 'Replace Parameter') {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        workflowParameterReplaceResponseDialogVisible: true,
        workflowTypeData: this.workflowTypeDataAll,
        workflowParameterData: this.workflowParameterAll,
        workflowParameterDefinitionData: this.workflowParameterDefinitionAll, 
        workflowTypeInReplace: null,
        workflowParameterReplace: null,
        workflowParameterDefinitionReplace: null
      });
    } else if (this.state.workflowFunctionType === 'Delete') {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        workflowDeleteResponseDialogVisible: true,
        workflowTypeData: this.workflowTypeDataAll,
        approverTypeData: this.approverTypesAll, approversData: this.approversDataAll,
        currentApproversData: this.approversDataAll, currentApprover: null,
        approverType: null
      });
    } else if (this.state.workflowFunctionType === 'Replace' || this.state.workflowFunctionType === 'Delegate') {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        workflowReplaceResponseDialogVisible: true,
        workflowTypeData: this.workflowTypeDataAll,
        approverTypeData: this.approverTypesAll,
        currentApproversData: this.approversDataAll,
        newApproversData: this.activeApproversDataAll,
        currentApprover: null,
        newApprover: null,
        approverType: null
      });
    }
  };

  toggleConfirmOnClose = () => {
    this.setState({
      workflowParameterReplaceResponseDialogVisible: false
    });
  };

  toggleApproverDeleteResponseDialog = () => {
    this.setState({
      workflowDeleteResponseDialogVisible: !this.state.workflowDeleteResponseDialogVisible
    });
  };

  replaceApprover = async () => {

    await this.setState({
      disabled : true
    })

    const newApprover = this.state.newApprover;
    const workflows = this.state.Workflows.rows.slice();
    let workflowsWithApproversToBeChanged = workflows.filter(resource => resource.Selected === true);

    if (this.state.workflowFunctionType === 'Replace') {
      const workflowData = {
        workflows: workflowsWithApproversToBeChanged,
        newApprover: newApprover,
        currentApprover: this.state.currentApprover,
        approverType: this.state.approverType
      };

      await replaceApproverOfWorkflows(workflowData, newApprover.id)
        .then(response => {

          if (response && response.data && response.data.length > 0) {
            this.submitSearch();

            const workflowsWithApproverNotReplaced = workflowsWithApproversToBeChanged.filter(item1 =>
              !response.data.some(item2 => (item2.id === item1.id)));

            this.setState({
              workflowsSelectedToBeReplaced: workflowsWithApproversToBeChanged,
              workflowsApproved: response.data,
              workflowsNotApproved: workflowsWithApproverNotReplaced,
              selectedNewApprover: newApprover,
              changeApproverBtnChecked: false
            }, () => {
              this.toggleConfirmOnCloseAfterReplacement();
            });

          } else {
            this.submitSearch();
            this.setState({
              workflowsSelectedToBeReplaced: workflowsWithApproversToBeChanged,
              workflowsApproved: [],
              workflowsNotApproved: workflowsWithApproversToBeChanged,
              selectedNewApprover: newApprover,
              changeApproverBtnChecked: false
            }, () => {
              this.toggleConfirmOnCloseAfterReplacement();
            });
          }

        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

    } else if (this.state.workflowFunctionType === 'Delegate') {
      const delegateTill = this.state.delegateTillDate;

      const workflowData = {
        workflows: workflowsWithApproversToBeChanged,
        newApprover: newApprover,
        currentApprover: this.state.currentApprover,
        approverType: this.state.approverType,
        delegateTill: moment(this.state.delegateTillDate).format('YYYY-MM-DD')
      };

      await delegateApproverOfWorkflows(workflowData)
        .then(response => {

          if (response && response.data && response.data.length > 0) {
            this.submitSearch();

            const workflowsWithApproverNotReplaced = workflowsWithApproversToBeChanged.filter(item1 =>
              !response.data.some(item2 => (item2.id === item1.id)));

            this.setState({
              workflowsSelectedToBeReplaced: workflowsWithApproversToBeChanged,
              workflowsApproved: response.data,
              workflowsNotApproved: workflowsWithApproverNotReplaced,
              selectedNewApprover: newApprover,
              changeApproverBtnChecked: false
            }, () => {
              this.toggleConfirmOnCloseAfterReplacement();
            });

          } else {
            this.submitSearch();
            this.setState({
              workflowsSelectedToBeReplaced: workflowsWithApproversToBeChanged,
              workflowsApproved: [],
              workflowsNotApproved: workflowsWithApproversToBeChanged,
              selectedNewApprover: newApprover,
              changeApproverBtnChecked: false
            }, () => {
              this.toggleConfirmOnCloseAfterReplacement();
            });
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }

    await this.setState({
      disabled : false
    })

  };

  replaceParameter = async () => {
    const workflows = this.state.Workflows.rows.slice();
    let newDefinitionValue = this.state.Workflows.rows.slice();
    let workflowsWithParameterToBeChanged = workflows.filter(resource => resource.Selected === true);

    if (this.state.workflowParameterReplace && (this.state.workflowParameterReplace.id === 1 || this.state.workflowParameterReplace.id === 2 || this.state.workflowParameterReplace.id === 3 || this.state.workflowParameterReplace.id === 4 || this.state.workflowParameterReplace.id === 8)) {
      newDefinitionValue = this.state.workflowParameterDefinitionReplace;
    } else {
      const data = {
        ParameterId: this.state.workflowParameterReplace.id,
        Value1: this.state.workflowParameterDefinitionReplace.id
      };
      newDefinitionValue = data;
    }

    const paraId = this.state.workflowParameterReplace.id;
    let validWorkflowsToBeReplace = [];
    for (const wf of workflowsWithParameterToBeChanged) {
      if(wf.WorkflowCriteria && wf.WorkflowCriteria.length > 0) {
        const isValidParameter = wf.WorkflowCriteria.filter(val => val.ParameterId == paraId);
        if(isValidParameter && isValidParameter.length === 1) {
          if (paraId === 5 ) {
            const wfPracticeIds = wf.WorkflowCriteria.map(obj => obj.ParameterId == 15 ? obj.Value1 : undefined).filter(Boolean);
            const wfPractices = this.practiceAll.map(obj => wfPracticeIds.includes(obj.id) ? obj.SFCode : undefined).filter(Boolean);
            if (wfPractices && wfPractices.length > 0) {
              const relevantPractices = await this.getDepartmentsForPractice(wfPractices);
              if (relevantPractices.includes(this.state.workflowParameterDefinitionReplace.id)) {
                validWorkflowsToBeReplace.push(wf);
              }
            } else {
              validWorkflowsToBeReplace.push(wf);
            }

          }else if (paraId === 15) {
            const wfDepartmentIds = wf.WorkflowCriteria.map(obj => obj.ParameterId == 5 ? obj.Value1 : undefined).filter(Boolean);
            const wfDepartments = this.billingDiviDataAll.map(obj => wfDepartmentIds.includes(obj.id) ? obj.SFCode : undefined).filter(Boolean);
            if (wfDepartments && wfDepartments.length > 0) {
              const relevantPractices = await this.getPracticesForDepartment(wfDepartments);
              if (relevantPractices.includes(this.state.workflowParameterDefinitionReplace.id)) {
                validWorkflowsToBeReplace.push(wf);
              }
            } else {
              validWorkflowsToBeReplace.push(wf);
            }

          } else {
            validWorkflowsToBeReplace.push(wf);
          }       
        }
      }
    }

    const workflowData = {
      workflows: validWorkflowsToBeReplace,
      workflowType: this.state.workflowTypeInReplace,
      workflowCriteria: this.state.workflowParameterReplace,
      newDefinition: newDefinitionValue
    };

    await replaceParameterOfWorkflows(workflowData)
      .then(response => {
        if (response && response.data && response.data.length > 0) {
          this.submitSearch();

          const workflowsNotReplaced = workflowsWithParameterToBeChanged.filter(item1 =>
            !response.data.some(item2 => (item2.id === item1.id)));

          this.setState({
            workflowsWithParametersSelectedToBeReplaced: workflowsWithParameterToBeChanged,
            workflowsRepleaced: response.data,
            workflowsNotReplaced: workflowsNotReplaced
          }, () => {
            this.toggleConfirmOnCloseAfterReplacement();
          });
        } else {
          this.submitSearch();
          this.setState({
            workflowsWithParametersSelectedToBeReplaced: workflowsWithParameterToBeChanged,
            workflowsRepleaced: [],
            workflowsNotReplaced: workflowsWithParameterToBeChanged
          }, () => {
            this.toggleConfirmOnCloseAfterReplacement();
          });
        }
      });
  };

  // WORKFLOW DELEGATE APPROVER CHANGES
  delegateApproverButtonClick = () => {
    if(this.state.Workflows && this.state.Workflows.rows)
    {
      const workflows = this.state.Workflows.rows;
      let workflowsWithApproversToBeChanged = workflows.filter(resource => resource.Selected === true);
  
      if (workflowsWithApproversToBeChanged && workflowsWithApproversToBeChanged.length > 0) {
        this.setState({
          changeApproverPopupVisible: true,
          delegateTillFieldVisible: true,
          workflowFunctionType: 'Delegate'
        });
      } else {
        this.toggleDialog('No workflow is selected to be delegated', 'Error');
      }
    }    
  };

  handleDelegateTillDateChange = event => {
    let startDate = event.target.value;
    if (startDate) {
      this.setState({
        delegateTillDate: startDate
      });
    }
  };


  deleteApprover = async () => {
    const approverTypeName = this.state.approverType.ApproverTypeName;
    const currentApproverName = this.state.currentApprover ? this.state.currentApprover.Name : null;

    const workflows = this.state.Workflows.rows.slice();
    let workflowsToBeDeleted = workflows.filter(resource => resource.Selected === true);

    let validWorkflowsToBeDeleted = [];
    for (const wf of workflowsToBeDeleted) {
      if(wf.ApproverGroup && wf.ApproverGroup.length > 0) {
        const approverCount = wf.ApproverGroup.filter(resource => resource.Approvers.length > 1);
        if(wf.ApproverGroup.length > 1 || approverCount && approverCount.length > 0) {
          validWorkflowsToBeDeleted.push(wf);
        }
      }
    }

    const workflowData = {
      workflows: validWorkflowsToBeDeleted,
      approverType: this.state.approverType,
      currentApprover: this.state.currentApprover ? this.state.currentApprover : null
    };    

    await deleteApproverOfWorkflows(workflowData, this.state.approverType.id)
      .then(response => {

        if (response && response.data && response.data.length > 0) {
          this.submitSearch();

          const workflowsNotDeleted = workflowsToBeDeleted.filter(item1 =>
            !response.data.some(item2 => (item2.id === item1.id)));

          this.setState({
            workflowsSelectedToBeDeleted: workflowsToBeDeleted,
            workflowsDeleted: response.data,
            workflowsNotDeleted: workflowsNotDeleted,
            selectedApproverTypeName: approverTypeName,
            selectedCurrentApproverName: currentApproverName
          }, () => {
            this.toggleConfirmOnCloseAfterReplacement();
          });

        } else {
          this.submitSearch();
          this.setState({
            workflowsSelectedToBeDeleted: workflowsToBeDeleted,
            workflowsDeleted: [],
            workflowsNotDeleted: workflowsToBeDeleted,
            selectedApproverTypeName: approverTypeName,
            selectedCurrentApproverName: currentApproverName
          }, () => {
            this.toggleConfirmOnCloseAfterReplacement();
          });
        }

      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

  };

  onClickConfirm = async () => {
    if (this.state.workflowFunctionType === 'Replace Parameter') {
      this.toggleConfirmDialog(
        'Are you sure you want to proceed?',
        this.replaceParameter
      );
    } else if (this.state.workflowFunctionType === 'Delete') {
      this.toggleConfirmDialog(
        'Are you sure you want to proceed?',
        this.deleteApprover
      );
    } else if (this.state.workflowFunctionType === 'Replace' || this.state.workflowFunctionType === 'Delegate') {
      if (this.state.newApprover !== this.state.currentApprover) {
        this.toggleConfirmDialog(
          'Are you sure you want to proceed?',
          this.replaceApprover
        );
      } else {
        this.toggleDialogSameUserValidation('Current and new approver cannot be the same user', 'Error');
      }

    }
  };

  populateDataforExport = async () => {
    this.setState({
      loading: true,
      WorkflowsForExcel:[]
    });

    const userDetails = window.LOGGED_USER;
    if (userDetails){
      await this.setState(
              prevState => {
                const SRequest = {
                  ...prevState.SearchRequest,
                  workflowTypeId:
                    prevState.workflowType && prevState.workflowType.id
                      ? prevState.workflowType.id
                      : null,
                  billingDivisionId: prevState.billingDivision.map(value => value.id),
                  approverId: prevState.approver.map(value => value.id),
                  paymentMethodId: prevState.paymentMethod.map(value => value.id),
                  regionId: prevState.costRegion.map(value => value.id),
                  approverTypeId: prevState.approverType.map(value => value.id),
                  workflowParameterId:
                    prevState.workflowParameter && prevState.workflowParameter.id
                      ? prevState.workflowParameter.id
                      : null,
                  workflowCriteriaId: prevState.workflowParameterDefinition && prevState.workflowParameterDefinition.id
                      ? prevState.workflowParameterDefinition.id
                      : null,
                  status:
                    prevState.searchStatus
                      ? prevState.searchStatus
                      : null,
                  isFromExcelExport:true,              
                };
                return {
                  SearchRequest: SRequest
                };
        },
        async () => {
          if (this.state.SearchRequest.workflowParameterId && !this.state.SearchRequest.workflowCriteriaId){
            this.setState({
              incompleteWorkflowErrorVisible: true,
              showErrorImage: true,
              showSuccessImage: false,
              loading: false,
              dialogMessage: "Please select a workflow definition criterion to complete the search"              
            });
          }
          else{
            await searchWorkflowNew(this.state.SearchRequest).then(async res => {
              if(res.data && res.data.rows)
              {
                let workflowIds = res.data.rows.map(a=>a.id);
                await this.retrieveProjectsforWorkflow(workflowIds);
                  // For status change workflows
                  let projects = this.state.allProjects;
                  let selectedWorkflows = res.data.rows;
  
                  let allPendingApprovalUnFreezeRequests = this.state.allPendingApprovalUnFreezeRequests
  
                  if(selectedWorkflows && selectedWorkflows.length > 0){
                    for(let wf of selectedWorkflows){
  
                      if(wf.WorkflowTypeId == 5){
                        let pendingWfCount = 0;
                        let statusPendingProjectsCrs = [];
  
                        if(projects && projects.length > 0){
                          for(let project of projects){
  
                            if(project.ActivityLogs && project.ActivityLogs.length > 0){
                              project.ActivityLogs = project.ActivityLogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                              if((project.ActivityLogs[0].ApprovalStatus && project.ActivityLogs[0].ApprovalStatus === 'PENDING') &&
                                (project.ActivityLogs[0].WorkflowId && project.ActivityLogs[0].WorkflowId == wf.id)){
                                  pendingWfCount ++;
                                  project.Type = 'Project';
                                  statusPendingProjectsCrs.push(project);
                              }
                            }
  
                            if(project.ChangeRequests && project.ChangeRequests.length > 0){
                              for(let cr of project.ChangeRequests){
                                if(cr.ActivityLogs && cr.ActivityLogs.length > 0){
                                  cr.ActivityLogs = cr.ActivityLogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                                  if((cr.ActivityLogs[0].ApprovalStatus && cr.ActivityLogs[0].ApprovalStatus === 'PENDING') && 
                                    (cr.ActivityLogs[0].WorkflowId && cr.ActivityLogs[0].WorkflowId == wf.id)){
                                      pendingWfCount ++;
                                      cr.Type = 'ChangeRequest';
                                      statusPendingProjectsCrs.push(cr);
                                  }
                                }
                              }
                            }
                          }
                        }
  
                        wf["PendingCount"] = pendingWfCount;
                        wf.StatusPendingProjectsCrs = statusPendingProjectsCrs;
                      }
                      else if(wf.WorkflowTypeId == 6)
                      {
                        let penlist =[];
                        if(allPendingApprovalUnFreezeRequests && allPendingApprovalUnFreezeRequests.length > 0) 
                        {
                          for(let request of allPendingApprovalUnFreezeRequests)
                          {
                            if(request.Approval && request.Approval.length>0)
                            {
                              let filteredList = [];
                              filteredList = request.Approval.filter(a=>a.WorkflowId == wf.id); 
                              if(filteredList && filteredList.length > 0)
                              {
                                penlist.push({
                                  EntryUser:(request.EntryUser)?request.EntryUser.displayName:'',
                                  ApproverUser:(request.ApproverUser)?request.ApproverUser.displayName:'',
                                  Comment:request.Comment,
                                  pendingApprovalList:filteredList
                                });
                              }  
                            }                     
                          }
                          wf.pendingApprovalUnFreezeRequests = penlist;
                          wf["PendingCount"] = penlist.length;
                        }
                              
                      }else if(wf.WorkflowTypeId == 1){
                        let pendingWfCount = 0;
                        pendingWfCount = wf.CMSheets && wf.CMSheets.length > 0 ? wf.CMSheets.length : 0;
                        wf["PendingCount"] = pendingWfCount;
                      }
                      else if(wf.WorkflowTypeId == 2 || wf.WorkflowTypeId == 3){
                        let pendingWfCount = 0;
                        if ((wf.ResourceRequests && wf.ResourceRequests.length) || 
                        (wf.ResourceRequestSubLines && wf.ResourceRequestSubLines.length)) {
                          pendingWfCount = wf.ResourceRequests.length + wf.ResourceRequestSubLines.length;
                        }
                        wf["PendingCount"] = pendingWfCount;
                      } else if(wf.WorkflowTypeId == 7){
                        let penlist =[];
                        if(this.state.allPendingReviseCMSRequests && this.state.allPendingReviseCMSRequests.length > 0) {
                          for(let request of this.state.allPendingReviseCMSRequests) {
                            if(request.Approval && request.Approval.length>0) {
                              let filteredList = [];
                              filteredList = request.Approval.filter(a=>a.WorkflowId == wf.id); 
                              if(filteredList && filteredList.length > 0) {
                                const cmsheet = request.CrId ? request.ChangeRequest.CMSheets[0] : request.Project.CMSheets[0]
                                const pendingApproval = {
                                  CMSheet: cmsheet ? cmsheet : [],
                                  Type: request.CrId ? 'ChangeRequest' : 'Project',
                                  Project: request.Project,
                                  ChangeRequest: request.ChangeRequest,
                                  pendingApprovalList:filteredList,
                                }
                                penlist.push(pendingApproval);
                              }  
                            }                     
                          }
                          wf.pendingReviseCMSRequests = penlist;
                          wf["PendingCount"] = penlist.length;
                        }  
                      } else if(wf.WorkflowTypeId == 8){
                        let penlist =[];
                        if(this.state.allPendingInvoiceRequests && this.state.allPendingInvoiceRequests.length > 0) {
                          for(let request of this.state.allPendingInvoiceRequests) {
                            if(request.Approval && request.Approval.length>0) {
                              let filteredList = [];
                              filteredList = request.Approval.filter(a=>a.WorkflowId == wf.id); 
                              if(filteredList && filteredList.length > 0) {
                                const pendingApproval = {
                                  dataItem: request,
                                  Type: request.CrId ? 'ChangeRequest' : 'Project',
                                  Project: request.Project,
                                  ChangeRequest: request.ChangeRequest,
                                  pendingApprovalList:filteredList,
                                }
                                penlist.push(pendingApproval);
                              }  
                            }                     
                          }
                          wf.pendingInvoiceRequests = penlist;
                          wf["PendingCount"] = penlist.length;
                        }  
                      }
                    }
                  }
  
                  const work ={
                    rows: res.data.rows.map(dataItem => Object.assign({ Selected: false }, dataItem)),
                    count: res.data.count
                  }
                await this.setState({
                  WorkflowsForExcel: work,
                });
                let ExcelDataSet = this.state.WorkflowsForExcel.rows;
                const workflowDataSet = await this.populateExcelData(ExcelDataSet);                
                this._export.save(workflowDataSet);
                await this.setState({
                  loading: false,
                });
              }    
            })
              .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });
          }
      });
    }
  };

  export = async () => {
    await this.populateDataforExport();    
  };

  async populateExcelData(ExcelDataSet) {
    const workflowItems = [];

    for (const workflowItem of ExcelDataSet) {
      let wfTypeId = workflowItem.WorkflowTypeId && workflowItem.WorkflowTypeId !== null ? workflowItem.WorkflowTypeId : null;
      let parameterConfiguration = '';
      let j = 0;

      for (const workflowCriteria of workflowItem.WorkflowCriteria) {
        let paramConfigSingleRow = null;
        if (workflowCriteria.WorkflowOperator.id === 1) {
          paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' > ' + workflowCriteria.Value2;
        } else if (workflowCriteria.WorkflowOperator.id === 2) {
          paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' < ' + workflowCriteria.Value2;
        } else if (workflowCriteria.WorkflowOperator.id === 3) {
          if (workflowCriteria.ParameterId === 5) {
            let billingDivi = this.state.billingDiviData ? this.state.billingDiviData.find(a=>a.id === workflowCriteria.Value1):null
            if(billingDivi){
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = ' + billingDivi.Name;
            }
            else{
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = N/A';
            }
          } else if (workflowCriteria.ParameterId === 6) {
            let region = this.state.regionData ? this.state.regionData.find(a=>a.id === workflowCriteria.Value1):null
            if(region)
            {
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = ' + region.Name;
            }
            else
            {
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = N/A';
            }
          } else if (workflowCriteria.ParameterId === 7) {
            let paymentMethod = this.state.paymentMethods ?this.state.paymentMethods.find(a=>a.id === workflowCriteria.Value1):null
            if(paymentMethod)
            {
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = ' + paymentMethod.Name;
            }
            else{
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = N/A';
            }
          } else if(wfTypeId !== null && wfTypeId == 5 && workflowCriteria.ParameterId === 12){ // Previous status - sts chg
            let status = this.state.statusData && this.state.statusData.length > 0 ? this.state.statusData.find(el => el.id == workflowCriteria.Value1) : null;
            if(status && status !== null){
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = ' + status.StatusName;
            }
            else{
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = N/A';
            }
          } else if(wfTypeId !== null && wfTypeId == 5 && workflowCriteria.ParameterId === 13){ // Current status - sts chg
            let status = this.state.statusData && this.state.statusData.length > 0 ? this.state.statusData.find(el => el.id == workflowCriteria.Value1) : null;
            if(status && status !== null){
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = ' + status.StatusName;
            }
            else{
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = N/A';
            }
          } else if(wfTypeId !== null && wfTypeId == 5 && workflowCriteria.ParameterId === 14){ // End status - sts chg
            let status = this.state.statusData && this.state.statusData.length > 0 ? this.state.statusData.find(el => el.id == workflowCriteria.Value1) : null;
            if(status && status !== null){
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = ' + status.StatusName;
            }
            else{
              paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = N/A';
            }
          } else {
            paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' = ' + workflowCriteria.Value2;
          }
        } else if (workflowCriteria.WorkflowOperator.id === 4) {
          paramConfigSingleRow = workflowCriteria.WorkflowParameter.Name + ' from ' + workflowCriteria.Value1 + ' to ' + workflowCriteria.Value2;
        }

        if (j !== workflowItem.WorkflowCriteria.length - 1) {
          paramConfigSingleRow = paramConfigSingleRow + ', ';
        }
        parameterConfiguration = parameterConfiguration + paramConfigSingleRow;
        j++;
      }
      workflowItem.parameterConfiguration = parameterConfiguration;
      workflowItems.push(workflowItem);
    }
    return workflowItems;
  }

  deleteConfirmationOk = async () => {
    const deleteItem = this.state.itemToDelete;
    workfowDelete(deleteItem.id);
    await this.setState({
      deletePopupVisible: false
    });
  };

  onDelete = async (event, id) => {
    if (event) {
      event.preventDefault();
      await this.setState({
        itemToDelete: id
      });
      this.deleteIdConfirmationClose();
    }
  };

  render() {
    return (

      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Workflow Configuration Search</div>
          </div>
          <div className="col-md-4">
            {this.renderRedirect()}
            <PermissibleRender
              userPermissions={window.USER_PERMISSION}
              requiredPermissions={['WF_CUS']}
            >
              <Button
                primary={true}
                type="button"
                onClick={this.setRedirect}
                className="pull-right"
              >
                Create New Workflow
              </Button>
            </PermissibleRender>
          </div>
        </div>

        <form onSubmit={this.submitSearch}>

          <div className="row">

            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label">Workflow Name:</label>
              </div>
              <div className="d-block" id="workflowNameToolTip">
                <Input
                  autoComplete="off"
                  type="text"
                  name="workflowName"
                  maxLength="50"
                  value={this.state.SearchRequest.workflowName}
                  onChange={this.handleOnChangeInput}
                />
                {this.state.SearchRequest.workflowName ? (
                  <Tooltip
                    placement="top"
                    isOpen={this.state.workflowNameToolTipOpen}
                    target="workflowNameToolTip"
                    toggle={this.toggleWorkflowNameToolTip}
                  >
                    {this.state.SearchRequest.workflowName}
                  </Tooltip>
                ) : null}
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label">Approver Types:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                  placeholder="Please select"
                  name="approverType"
                  data={this.state.approverTypeData}
                  textField="ApproverTypeName"
                  dataItemKey="id"
                  onChange={this.handleChangeDropDown}
                  value={this.state.approverType}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label">Department:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                  placeholder="Please select"
                  name="billingDivision"
                  data={this.state.billingDiviData}
                  textField="Name"
                  dataItemKey="id"
                  onChange={this.handleChangeDropDown}
                  value={this.state.billingDivision}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label">Workflow Type:</label>
              </div>
              <div className="d-block">
                <ComboBox
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                  placeholder="Please select"
                  name="workflowType"
                  data={this.state.workflowTypeData}
                  textField="Text"
                  dataItemKey="id"
                  onChange={this.handleChangeDropDownWorkflowType}
                  value={this.state.workflowType}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label">Workflow Parameters:</label>
              </div>
              <div className="d-block">
                <ComboBox
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                  placeholder="Please select"
                  name="workflowParameter"
                  data={this.state.workflowParameterData}
                  textField="Name"
                  dataItemKey="id"
                  onChange={this.handleChangecriteriaDropDown}
                  value={this.state.workflowParameter}
                  disabled={!this.state.workflowType}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label">Parameters Definition:</label>
              </div>
              <div className="d-block">
                <ComboBox
                  filterable={true}
                  disabled={!this.state.workflowParameter}
                  onFilterChange={this.filterChangeCombo}
                  placeholder="Please select"
                  name="workflowParameterDefinition"
                  data={this.state.workflowParameterDefinitionData}
                  textField= {this.state.workflowParameterDefinitionData[0] && this.state.workflowParameterDefinitionData[0].StatusName ? "StatusName" : "Name"}
                  dataItemKey="id"
                  onChange={this.handleChangeDropDown}
                  value={this.state.workflowParameterDefinition}
                />
              </div>
            </div>

          </div>
          <div className="row">

            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label">Approvers:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                  placeholder="Please select"
                  name="approver"
                  data={this.state.approversData}
                  textField="Name"
                  dataItemKey="id"
                  onChange={this.handleChangeDropDown}
                  value={this.state.approver}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label">Billing Method:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                  placeholder="Please select"
                  name="paymentMethod"
                  data={this.state.paymentMethods}
                  textField="Name"
                  dataItemKey="id"
                  onChange={this.handleChangeDropDown}
                  value={this.state.paymentMethod}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label">Billing Region:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                  placeholder="Please select"
                  name="costRegion"
                  data={this.state.costRegions}
                  textField="Name"
                  dataItemKey="id"
                  onChange={this.handleChangeDropDown}
                  value={this.state.costRegion}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label className="new-label">Workflow Status:</label>
              </div>
              <div className="d-block">
                <ComboBox
                  name="searchStatus"
                  value={this.state.searchStatus}
                  data={this.state.statusArray}
                  placeholder={'Select status'}
                  onChange={this.handleChangeDropDown}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="d-block">
                <label className="new-label"></label>
              </div>
              <div className="d-block">
                <fieldset>
                  <label className="k-form-field">
                    <input
                      type="checkbox"
                      id="auth-1rps"
                      name="withPendingApprovals"
                      className="k-checkbox"
                      value={this.state.SearchRequest.withPendingApprovals}
                      onChange={this.handleCheckedInput}/>
                    <label className="k-checkbox-label" htmlFor="auth-1rps">Workflows with Pending Approvals</label>
                  </label>
                </fieldset>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="reset" onClick={this.cancelSearch}>
                Clear
              </Button>
            </div>
          </div>

        </form>
        <div className="main-seperator"/>

        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Search Results</div>
          </div>

          <div className="col-md-4 btn-align-right">
            <Button title="Export Excel" className="k-button k-primary" onClick={this.export}>
              Export to Excel
            </Button>
          </div>
        </div>


        <PermissibleRender
          userPermissions={window.USER_PERMISSION}
          requiredPermissions={['WF_CUS']}
        >
          <Button primary={true} look="outline"
                  type="button" style={{ marginRight: '10px' }} onClick={this.replaceApproverButtonClick}>
            Replace Approvers
          </Button>

          <Button primary={true} look="outline"
                  disabled={this.props.isViewMode}
                  type="button" style={{ margin: '10px' }} onClick={this.delegateApproverButtonClick}>
            Delegate Approvers
          </Button>

          <Button primary={true} look="outline"
                  type="button" style={{ margin: '10px' }} onClick={this.deleteApproverTypesButtonClick}>
            Delete Approvers/ Types
          </Button>

          <Button primary={true} look="outline"
                  // disabled={this.state.disableReplaceParaBtn}
                  type="button" style={{ margin: '10px' }} onClick={this.replaceParameterButtonClick}>
            Replace Parameter Values
          </Button>

        </PermissibleRender>

        <div className="row">
          <div className="col-md-12">
            <div className="wf-grid">
              <ExcelExport
                data={this.state.Workflows.rows}
                ref={(exporter) => {
                  this._export = exporter;
                }}
                fileName={'Workflows_' + new Date()}
              >
                <ExcelExportColumn field="Text" title="Workflow Name" locked={true}/>
                <ExcelExportColumn field="WorkflowType.Text" title="Workflow Type" locked={true}/>
                <ExcelExportColumn field="PendingCount" title="Current Pending Approvals" locked={true}/>
                <ExcelExportColumn field="parameterConfiguration" title="Parameter Configuration"/>
              </ExcelExport>
              <Grid
                data={this.state.Workflows.rows}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.Workflows.count}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
                selectedField="Selected"
                onHeaderSelectionChange={this.headerSelectionChange}
                onSelectionChange={this.selectionChange}
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  }, () => this.submitSearch(null));
                }}
                className="search-result-grid"
              >
                <GridColumn
                  field="Selected"
                  width="50px"
                  headerSelectionValue={
                    this.state.Workflows && this.state.Workflows.rows ? this.state.Workflows.rows.findIndex(dataItem => dataItem.Selected === false) === -1:0
                  }/>
                <GridColumn field="Text" title="Workflow Name" width="auto"/>
                <GridColumn
                  field="WorkflowType.Text"
                  title="Workflow Type"
                  width="auto"
                />
                <GridColumn
                  field=""
                  title="Parameter Configurations"
                  width="auto"
                  cell={props => (
                    <WorkflowParameterConfigrations
                      {...props}
                      BillingDivision={this.billingDiviDataAll}
                      BillingRegion={this.state.regionData}
                      ResourceRegion={this.state.resourceRegions}
                      PaymentMethod={this.state.paymentMethods}
                      PaymentMethodAll={this.state.paymentMethodsWithAll}
                      statusAll={this.statusAll}
                      ResourcePractice = {this.practiceAll}
                      EngagementTypes = {this.engagementTypesAll}
                    />
                  )}
                />
                <GridColumn
                  field=""
                  title="Actions"
                  width="auto"
                  cell={props => (
                    <SearchAction
                      {...props}
                      ApproverDataAll={this.approversDataAll}
                      BillingDivision={this.billingDiviDataAll}
                      statuses={this.statusAll}
                      BillingRegion={this.state.regionData}
                      ResourceRegion={this.state.resourceRegions}
                      PaymentMethod={this.state.paymentMethods}
                      PaymentMethodAll={this.state.paymentMethodsWithAll}
                      ResourcePractice = {this.practiceAll}
                      EngagementTypes = {this.engagementTypesAll}
                      OnDeActivate={this.OnDeActivate}
                      OnActivate={this.OnActivate}
                      openApprovers={this.openApprovers}
                      onDelete={this.onDelete}
                    />
                  )}
                />
                <GridColumn
                  field="CMSheets"
                  title="Pending Approvals in the Workflow as at Now"
                  width="auto"
                  cell={props => (
                    <PendingApprovals
                      {...props}
                      OnViewProject={this.OnViewProject}
                      OnViewUnFreezeRequestApproval = {this.OnViewUnFreezeRequestApproval}
                      OnViewResourceRequest = {this.OnViewResourceRequest}
                      OnViewInvoiceRequestApproval = {this.OnViewInvoiceRequestApproval}
                    />
                  )}
                />
              </Grid>
            </div>
          </div>
        </div>

        <div>
          {this.state.popupVisible && (
            <Dialog title={'Approver Details'} onClose={this.closeApprover}>
              {this.approverTable(this.state.formattedApproverModel)}
              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.closeApprover}
                >
                  Close
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.deletePopupVisible && (
            <Dialog title={'Please Confirm'} onClose={this.deleteIdConfirmationClose}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Are you sure you want to delete this?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.deleteIdConfirmationClose}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.deleteIdConfirmationOk}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.popupVisible && (
            <Dialog title={'Approver Details'} onClose={this.closeApprover}>
              {this.approverTable(this.state.formattedApproverModel)}
              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.closeApprover}
                >
                  Close
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.deactivePopupVisible && (
            <Dialog
              title={'Please Confirm'}
              onClose={this.confirmationClose}
              width={260}
              height={250}
            >
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Once the workflow is deactivated, scenarios under the given
                parameters will no longer have a workflow defined for it. Are
                you sure you want to deactivate this?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.confirmationClose}>
                  No
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={this.confirmationOk}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.activatePopupVisible && (
            <Dialog
              title={'Please Confirm'}
              onClose={this.activateConfirmationClose}
              width={260}
              height={200}
            >
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Are you sure you want to activate the workflow?
              </p>
              <DialogActionsBar>
                <button
                  className="k-button"
                  onClick={this.activateConfirmationClose}
                >
                  No
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={this.activateConfirmationOk}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.showConfirmDialog && (
            <Dialog title={'Confirm'}
                    onClose={this.toggleParameterReplaceResponseDialog}
                    width="400px"
            >
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>

              <DialogActionsBar>
                <button className="k-button"
                        onClick={this.toggleParameterReplaceResponseDialog}
                >
                  No
                </button>
                <button className="k-button modal-primary"
                        onClick={this.state.confirmDialogAction}
                        disabled={this.state.disabled}
                >
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.togglePopupVisible && (
            <Dialog
              title={this.state.dialogTitle}
              onClose={this.toggleDialog}
            >
               {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error"/>
                  </div>
                )}
              <p style={{ margin: '25px', textAlign: 'center' }}>
                {this.state.dialogMessage}
              </p>
              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.toggleDialog}
                >
                  Ok
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          {this.state.togglePopupVisibleSameUser && (
            <Dialog
              title={this.state.dialogTitle}
              onClose={this.toggleDialogSameUserValidation}
            >
              <p style={{ margin: '25px', textAlign: 'center' }}>
                {this.state.dialogMessage}
              </p>
              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.toggleDialogSameUserValidation}
                >
                  Ok
                </button>
              </DialogActionsBar>
            </Dialog>
          )}</div>
        <div>
          {this.state.viewProjectPopupVisible && (
            <Dialog title={'Project / CR Details'} onClose={this.projectDetailsClose}>
              {this.state.OPID.map(item => {
                if (item.WorkflowType && item.WorkflowType === 'CMSheet') {
                  return (
                    <Link
                      key={item.OPID}
                      to={{
                        pathname: '/projects/cmsheet/cmsheet',
                        projectId: item.Project ? item.Project.id : 0,
                        changeReqId: item.ChangeRequest ? item.ChangeRequest.id : 0,
                        cmSheet: item.CMSheet,
                        action: 'view',
                        reference: item.Type === 'ChangeRequest' ? 'crCMS' : 'projectCMS'
                      }}
                    >
                      {item.OPID} - {item.Type === 'ChangeRequest' ? item.ChangeRequest.CRName : item.Project.ProjectName} - {item.Type}
                      <br></br>
                    </Link>
                  );
                } else if (item.WorkflowType && item.WorkflowType === 'ReviseCMSRequest') {
                  return (
                    <Link
                      key={item.OPID}
                      to={{
                        pathname: '/projects/cmsheet/cmsheet',
                        projectId: item.Project ? item.Project.id : 0,
                        changeReqId: item.ChangeRequest ? item.ChangeRequest.id : 0,
                        cmSheet: item.CMSheet,
                        action: 'view',
                        reference: item.Type === 'ChangeRequest' ? 'crCMS' : 'projectCMS'
                      }}
                    >
                      {item.OPID} - {item.Type === 'ChangeRequest' ? item.ChangeRequest.CRName : item.Project.ProjectName} - {item.Type}
                      <br></br>
                    </Link>
                  );
                } else {
                  if (item.Type === 'ChangeRequest') {
                    return (
                      <Link
                        key={item.OPID}
                        to={{
                          pathname: '/changeRequest/edit',
                          changeReqId: item.ChangeRequest.id,
                          action: 'view'
                        }}
                      >
                        {item.OPID} - {item.ChangeRequest.CRName} - {item.Type}
                        <br></br>
                      </Link>
                    );
                  } else {
                    return (
                      <Link
                        key={item.OPID}
                        to={{
                          pathname: '/projects/create/create',
                          project: item.Project,
                          projectId: item.Project.id,
                          action: 'view'
                        }}
                      >
                        {item.OPID} - {item.Project.ProjectName} - {item.Type}
                        <br></br>
                      </Link>
                    );
                  }       
                }

              })}
              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.projectDetailsClose}
                >
                  Ok
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <div>
          {this.state.viewResourceRequestPopupVisible && (
            <Dialog title={'Project / CR Details'} onClose={this.resourceRequestDetailsClose}>
              {this.state.pendingRRApprovals.map(item => {
                if (item.Type === 'ChangeRequest') {
                  return (
                    <Link
                      key={item.OPID}
                      to={{
                        pathname: '/ProjectResourceRequest/ProjectResourceRequest',
                        action: 'view',
                        project: item.Project
                      }}
                    >
                      {item.OPID} - {item.ChangeRequest.CRName} - {item.Type}
                      <br></br>
                    </Link>
                  );
                } else {
                  return (
                    <Link
                      key={item.OPID}
                      to={{
                        pathname: '/ProjectResourceRequest/ProjectResourceRequest',
                        action: 'view',
                        project: item.Project
                      }}
                    >
                      {item.OPID} - {item.Project.ProjectName} - {item.Type}
                      <br></br>
                    </Link>
                  );
                }

              })}
              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.resourceRequestDetailsClose}
                >
                  Ok
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          </div>

        <div>
          {this.state.viewUnFreezeRequestPopUpVisible && (
            <Dialog title={'Unfreeze Workflow'} onClose={this.UnFreezeRequestDetailsClose}>
              {this.state.UnFreezeApproveDatas.map(item => {
                  return (
                    <Link
                      key={item.id}
                      to={{
                        pathname: '/timesheets/unfreezeworkflow/unfreezeworkflow'
                      }}
                    >
                      {item.Name} - {item.Comment}
                      <br></br>
                    </Link>
                  );               

              })}
              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.UnFreezeRequestDetailsClose}
                >
                  Ok
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.viewInvoiceRequestPopUpVisible && (
            <Dialog title={'Invoice Request Workflow'} onClose={this.invoiceRequestDetailsClose}>
              {this.state.invoiceRequestData.map(item => {
                  return (
                    <Link
                      key={item.id}
                      to={{
                        pathname: '/projects/invoice/irfview'
                      }}
                    >
                      {item.ProjectName} - {item.DateRange}
                      <br></br>
                    </Link>
                  );               

              })}
              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.invoiceRequestDetailsClose}
                >
                  Ok
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
            {this.state.incompleteWorkflowErrorVisible === true && (
              <Dialog title='Error' onClose={this.toggleErrDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error"/>
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleErrDialog}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleErrDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>

        <div>
          {this.state.changeApproverPopupVisible && (
            <Dialog title={'Bulk ' + this.state.workflowFunctionType + ' Approvers'} onClose={this.changeApproverClose}>

              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Current Approver:</label>
                    </div>
                    <div className="col-md-8">
                      <ComboBox
                        filterable={true}
                        onFilterChange={this.filterChangeCombo}
                        placeholder="Please select"
                        name="currentApprover"
                        data={this.state.currentApproversData}
                        textField="Name"
                        dataItemKey="id"
                        onChange={this.handleChangeDropDown}
                        value={this.state.currentApprover}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <label>{this.state.workflowFunctionType === 'Delegate' ? 'Delegated Aprrover:' : 'New Approver:'}</label>
                    </div>
                    <div className="col-md-8">
                      <ComboBox
                        filterable={true}
                        onFilterChange={this.filterChangeCombo}
                        placeholder="Please select"
                        name="newApprover"
                        data={this.state.newApproversData}
                        textField="Name"
                        dataItemKey="id"
                        onChange={this.handleChangeDropDown}
                        value={this.state.newApprover}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <label>Approver Type:</label>
                    </div>
                    <div className="col-md-8">
                      <ComboBox
                        filterable={true}
                        onFilterChange={this.filterChangeCombo}
                        placeholder="Please select"
                        name="approverType"
                        data={this.state.approverTypeData}
                        textField="ApproverTypeName"
                        dataItemKey="id"
                        onChange={this.handleChangeDropDown}
                        value={this.state.approverType}
                      />
                    </div>
                  </div>

                  {this.state.workflowFunctionType === 'Delegate' && (
                    <div className="row">
                      <div className="col-md-4">
                        <label>Delegate Till:</label>
                      </div>
                      <div className="col-md-8">
                        <DatePicker
                          width="100"
                          name="startDate"
                          onChange={this.handleDelegateTillDateChange}
                          value={this.state.delegateTillDate}
                          format="MM/dd/yyyy"
                          formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                          min={new Date()}
                        />
                      </div>
                    </div>
                  )}

                </div>
              </div>

              <DialogActionsBar>
                <button className="k-button" onClick={this.changeApproverClose}>
                  Cancel
                </button>
                <button
                    className="k-button modal-primary"
                    onClick={this.onClickConfirm} disabled={!this.state.approverType || !this.state.newApprover
                || !this.state.currentApprover || (this.state.workflowFunctionType === 'Delegate' ? !this.state.delegateTillDate : null)}>
                  {this.state.workflowFunctionType}
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.workflowReplaceResponseDialogVisible === true && (
            <Dialog title='Success' onClose={this.toggleApproverReplaceResponseDialog} width="400px">
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success"/>
              </div>
              <p
                style={{ margin: '25px' }}> Successfully {this.state.workflowFunctionType}d {this.state.workflowsApproved.length} out
                of {this.state.workflowsSelectedToBeReplaced.length} workflows with the new
                approver {this.state.selectedNewApprover.Name}</p>

              {this.state.workflowsNotApproved.length > 0 && (
                <span>
                        <p style={{ margin: '25px' }}>Following workflows did not contain the defined approver in the given approver type section</p>
                        <ul>
                          {
                            this.state.workflowsNotApproved.map(workflow =>
                              <div>
                                <li>
                                  <div className="row">
                                    <div className="col-8">
                                      {workflow.Text}
                                    </div>
                                  </div>
                                </li>
                              </div>
                            )
                          }
                        </ul>
                    </span>
              )}

              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.toggleApproverReplaceResponseDialog}
                >
                  OK
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

        </div>


        <div>
          {this.state.changeCriteriaPopupVisible && (
            <Dialog title={'Bulk Replace Criteria Values'} onClose={this.changeParameterClose}>

              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <label>Workflow Type:</label>
                    </div>
                    <div className="col-md-8">
                      <ComboBox
                        filterable={true}
                        onFilterChange={this.filterChangeCombo}
                        placeholder="Please select"
                        name="workflowTypeInReplace"
                        data={this.state.workflowTypeData}
                        textField="Text"
                        dataItemKey="id"
                        onChange={this.handleChangeWorkflowTypeReplace}
                        value={this.state.workflowTypeInReplace}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <label>Workflow Criteria:</label>
                    </div>
                    <div className="col-md-8">
                      <ComboBox
                        filterable={true}
                        disabled={!this.state.workflowTypeInReplace}
                        onFilterChange={this.filterChangeCombo}
                        placeholder="Please select"
                        name="workflowParameterReplace"
                        data={this.state.workflowParameterData}
                        textField="Name"
                        dataItemKey="id"
                        onChange={this.handleChangecriteriaDropDown}
                        value={this.state.workflowParameterReplace}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <label>New Value:</label>
                    </div>
                    <div className="col-md-8">
                      <ComboBox
                        filterable={true}
                        disabled={!this.state.workflowParameterReplace}
                        onFilterChange={this.filterChangeCombo}
                        placeholder="Please select"
                        name="workflowParameterDefinitionReplace"
                        data={this.state.workflowParameterDefinitionData}
                        textField= {(this.state.workflowParameterDefinitionData.length > 1 && this.state.workflowParameterDefinitionData[1].StatusName) ? "StatusName" : "Name"}
                        dataItemKey="id"
                        onChange={this.handleChangeDropDown}
                        value={this.state.workflowParameterDefinitionReplace}
                      />
                    </div>
                  </div>

                </div>
              </div>

              <DialogActionsBar>
                <button className="k-button" onClick={this.changeParameterClose}>
                  Cancel
                </button>
                <button className="k-button modal-primary" onClick={this.onClickConfirm}
                        disabled={!this.state.workflowParameterDefinitionReplace}>
                  Replace
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.workflowParameterReplaceResponseDialogVisible === true && (
            <Dialog title='Success' onClose={this.toggleConfirmOnClose} width="400px">
               <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success"/>
                </div>
              <p style={{ margin: '25px', textAlign: 'center' }}> Successfully
                replaced {this.state.workflowsRepleaced.length} out
                of {this.state.workflowsWithParametersSelectedToBeReplaced.length} </p>

              {this.state.workflowsNotReplaced.length > 0 && (
                <span>
                      <p style={{ margin: '10px', textAlign: 'left' }}>
                        Following workflows are failed due to one of below issues.
                      </p>
                      <ul>
                        <li>Workflow type is not selected during bulk replacement pop up</li>
                        <li>Workflow does not contain selected parameter</li>
                        <li>Workflow is having multi select parameters</li>
                        <li>Selected parameter is not mapping to existing department - practice mapping</li>
                      </ul>

                        <p style={{ margin: '10px', textAlign: 'left' }}>Workflows:</p>

                        <ul>
                          {
                            this.state.workflowsNotReplaced.map(workflow =>
                              <div>
                                <li>
                                  <div className="row">
                                    <div className="col-8">
                                      {workflow.Text}
                                    </div>
                                  </div>
                                </li>
                              </div>
                            )
                          }
                        </ul>
                      </span>
              )}

              <DialogActionsBar>
                <button className="k-button modal-primary" onClick={this.toggleConfirmOnClose}>
                  OK
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.deleteApproverPopupVisible && (
            <Dialog title={'Bulk Delete Approvers/ Approver Type'} onClose={this.deleteApproverClose}>

              <div className="row">
                <div className="col-md-4">
                  <label>Approver Type:</label>
                </div>
                <div className="col-md-8">
                  <ComboBox
                    filterable={true}
                    onFilterChange={this.filterChangeCombo}
                    placeholder="Please select"
                    name="approverType"
                    data={this.state.approverTypeData}
                    textField="ApproverTypeName"
                    dataItemKey="id"
                    onChange={this.handleChangeDropDown}
                    value={this.state.approverType}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label>Current Approver:</label>
                </div>
                <div className="col-md-8">
                  <ComboBox
                    filterable={true}
                    onFilterChange={this.filterChangeCombo}
                    placeholder="Please select"
                    name="currentApprover"
                    data={this.state.currentApproversData}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleChangeDropDown}
                    value={this.state.currentApprover}
                  />
                </div>
              </div>

              <DialogActionsBar>
                <button className="k-button" onClick={this.deleteApproverClose}>
                  Cancel
                </button>
                <button
                  className="k-button modal-primary" onClick={this.onClickConfirm}
                  disabled={!this.state.approverType}>Delete
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

          {this.state.workflowDeleteResponseDialogVisible === true && (
            <Dialog title='Success' onClose={this.toggleApproverDeleteResponseDialog} width="400px">
               <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success"/>
              </div>
              <p style={{ margin: '25px', textAlign: 'center' }}> Successfully
                Deleted {this.state.workflowsDeleted.length} out
                of {this.state.workflowsSelectedToBeDeleted.length} workflows with the Approver
                Group {this.state.selectedApproverTypeName}</p>


              {this.state.workflowsNotDeleted.length > 0 && (
                <span>
                        <p style={{ margin: '25px', textAlign: 'center' }}>Following workflows did not contain the defined approver in the given approver type section</p>
                        <ul>
                          {
                            this.state.workflowsNotDeleted.map(workflow =>
                              <div>
                                <li>
                                  <div className="row">
                                    <div className="col-8">
                                      {workflow.Text}
                                    </div>
                                  </div>
                                </li>
                              </div>
                            )
                          }
                        </ul>
                      </span>
              )}

              <DialogActionsBar>
                <button
                  className="k-button modal-primary"
                  onClick={this.toggleApproverDeleteResponseDialog}
                >
                  OK
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

        </div>


        <Loader loading={this.state.loading}/>
      </div>
    );
  }

  toggleErrDialog = () => {
      this.setState({
        incompleteWorkflowErrorVisible: !this.state.incompleteWorkflowErrorVisible
      });
  };
}

export default Workflow;
