import React, { Component } from 'react';
import { GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Grid } from '@progress/kendo-react-grid';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import TotalPerWeek from './TotalPerWeek';
import { orderBy } from '@progress/kendo-data-query';
import { Loader as Spinner } from "@progress/kendo-react-indicators";
class Panel extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <PanelBar
          onSelect={e => {
            this.props.getTaskList(this.props.resource.userId, 'initial', 'InWeekly',this.props.resource.isMyResource);
          }}
          className="mt-2"
          key={this.props.resource.userId}
        >
          <input
            type="checkbox"
            className="k-checkbox mr-2 time-approval-panel-checkbox"
            name="resourcename"
            checked={this.props.resource.selected}
            onChange={() => this.props.resourceClickHandler(this.props.resource.userId, false)}
          />
          <PanelBarItem
            animation={true}
            expanded={this.props.allPanelExapnded}
            title={
              <div className="font-weight-bold">
              
                <label className="reso-name ml-4">Resource : {this.props.resource.userName} </label>
                {parseInt(
                  this.props
                    .setTotalOfWeek(this.props.resource.totalOfWeek)
                    .toString()
                    .split(':')[0]
                ) >= 40 ? (
                  <span className="resc-green ml-3">
                    { this.props.setTotalOfWeek(this.props.resource.totalOfWeek) + ' Hours '} / 40 Hours
                  </span>
                ) : (
                  <span className="resc-red ml-3">
                    {this.props.setTotalOfWeek(this.props.resource.totalOfWeek) + ' Hours '} / 40 Hours
                  </span>
                )}
              </div>
            }
          >
            <div className="py-3 grid-weekly">
              <Grid
                onSelectionChange={this.props.rowClick}
                onRowClick={this.props.rowClick}
                selectedField="selected"
                onHeaderSelectionChange={this.props.headerSelectionChange}
                data={orderBy(this.props.resource.tasks, this.props.sortWeekly)}
                sortable={true}
                sort={this.props.sortWeekly}
                onSortChange={e => {
                  this.props.setSortWeekly(e.sort);
                }}
                className="time-approval-grid-weekly"
              >
                <Column field="selected" width="30px" />
                <Column field="Project" width="150px" title="Project/ Task Group" />
                <Column field="Task" width="200px" title="Task" />

                <Column
                  field="Mon"
                  sortable={false}
                  title={
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="ts-day">Mon</label>
                        <div>
                          {this.props.dateOfMon.getMonth() +
                            1 +
                            '/' +
                            this.props.dateOfMon.getDate()}
                        </div>
                      </div>
                      <div className="col-md-2 my-auto">
                        <input
                          type="checkbox"
                          name="checkAll"
                          checked={this.props.resource.monAllSelect}
                          onChange={() => this.props.selectAllDay(1, this.props.resource.userId)}
                          className="k-checkbox"
                        />
                      </div>
                    </div>
                  }
                  footerCell={() => this.props.setTotalTime(this.props.resource.monTotal)}
                  cell={this.props.customCell}
                />
                <Column
                  field="Tue"
                  sortable={false}
                  title={
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="ts-day">Tue</label>
                        <div>
                          {this.props.dateOfTue.getMonth() +
                            1 +
                            '/' +
                            this.props.dateOfTue.getDate()}
                        </div>
                      </div>
                      <div className="col-md-2 my-auto">
                        <input
                          type="checkbox"
                          id="ts-day"
                          className="k-checkbox"
                          name="tsact"
                          checked={this.props.resource.tueAllSelect}
                          onChange={() => this.props.selectAllDay(2, this.props.resource.userId)}
                        />
                      </div>
                    </div>
                  }
                  footerCell={() => this.props.setTotalTime(this.props.resource.tueTotal)}
                  cell={this.props.customCell}
                />
                <Column
                  field="Wed"
                  sortable={false}
                  title={
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="ts-day">Wed</label>
                        <div>
                          {this.props.dateOfWed.getMonth() +
                            1 +
                            '/' +
                            this.props.dateOfWed.getDate()}
                        </div>
                      </div>
                      <div className="col-md-2 my-auto">
                        <input
                          type="checkbox"
                          id="ts-day"
                          className="k-checkbox"
                          name="tsact"
                          checked={this.props.resource.wedAllSelect}
                          onChange={() => this.props.selectAllDay(3, this.props.resource.userId)}
                        />
                      </div>
                    </div>
                  }
                  footerCell={() => this.props.setTotalTime(this.props.resource.wedTotal)}
                  cell={this.props.customCell}
                />

                <Column
                  field="Thu"
                  sortable={false}
                  title={
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="ts-day">Thu</label>
                        <div>
                          {this.props.dateOfTur.getMonth() +
                            1 +
                            '/' +
                            this.props.dateOfTur.getDate()}
                        </div>
                      </div>
                      <div className="col-md-2 my-auto">
                        <input
                          type="checkbox"
                          id="ts-day"
                          className="k-checkbox"
                          name="tsact"
                          checked={this.props.resource.thuAllSelect}
                          onChange={() => this.props.selectAllDay(4, this.props.resource.userId)}
                        />
                      </div>
                    </div>
                  }
                  footerCell={() => this.props.setTotalTime(this.props.resource.thuTotal)}
                  cell={this.props.customCell}
                />

                <Column
                  field="Fri"
                  sortable={false}
                  title={
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="ts-day">Fri</label>
                        <div>
                          {this.props.dateOfFri.getMonth() +
                            1 +
                            '/' +
                            this.props.dateOfFri.getDate()}
                        </div>
                      </div>
                      <div className="col-md-2 my-auto">
                        <input
                          type="checkbox"
                          id="ts-day"
                          className="k-checkbox"
                          name="tsact"
                          checked={this.props.resource.friAllSelect}
                          onChange={() => this.props.selectAllDay(5, this.props.resource.userId)}
                        />
                      </div>
                    </div>
                  }
                  footerCell={() => this.props.setTotalTime(this.props.resource.friTotal)}
                  cell={this.props.customCell}
                />
                <Column
                  field="Sat"
                  sortable={false}
                  title={
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="ts-day">Sat</label>
                        <div>
                          {this.props.dateOfSat.getMonth() +
                            1 +
                            '/' +
                            this.props.dateOfSat.getDate()}
                        </div>
                      </div>
                      <div className="col-md-2 my-auto">
                        <input
                          type="checkbox"
                          id="ts-day"
                          className="k-checkbox"
                          name="tsact"
                          checked={this.props.resource.satAllSelect}
                          onChange={() => this.props.selectAllDay(6, this.props.resource.userId)}
                        />
                      </div>
                    </div>
                  }
                  footerCell={() => this.props.setTotalTime(this.props.resource.satTotal)}
                  cell={this.props.customCell}
                />
                <Column
                  field="Sun"
                  sortable={false}
                  title={
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="ts-day">Sun</label>
                        <div>
                          {this.props.dateOfSun.getMonth() +
                            1 +
                            '/' +
                            this.props.dateOfSun.getDate()}
                        </div>
                      </div>
                      <div className="col-md-2 my-auto">
                        <input
                          type="checkbox"
                          id="ts-day"
                          className="k-checkbox"
                          name="tsact"
                          checked={this.props.resource.sunAllSelect}
                          onChange={() => this.props.selectAllDay(7, this.props.resource.userId)}
                        />
                      </div>
                    </div>
                  }
                  footerCell={() => this.props.setTotalTime(this.props.resource.sunTotal)}
                  cell={this.props.customCell}
                />
                <Column
                  className="totperweek"
                  field=""
                  width="80px"
                  title="Total Per Week"
                  cell={props => <TotalPerWeek data={props} />}
                  footerCell={'test'}
                />
              </Grid>
            </div>
          </PanelBarItem>
        </PanelBar>
      </>
    );
  }
}
export default Panel;
