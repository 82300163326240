import React, { Component } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { getExchangeRate} from './ExchangeRateService';
import CreatedDateCell from './components/CreatedDateCell';
import UpdatedDateCell from './components/UpdatedDateCell';
import StatusCell from './components/StatusCell';
import EditExchangeRateActionsCell from './components/EditExchangeRateActionsCell';
import MonthYearCell from './components/MonthYearCell';
import { searchExchangeRate } from './ExchangeRateService';
import { Link } from 'react-router-dom';
import MonthYearPicker from '../../integration/components/calendar/monthYearPicker/MonthYearPicker';
import { orderBy } from '@progress/kendo-data-query';
import Loader from '../../integration/components/Loader';
import * as loggerService from "../../../src/integration/LoggerService";
import {LOG_TYPES} from "../../../src/integration/IntegrationEnums";
import $ from "jquery";

class ExchangeRate extends Component {

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      exchangerate: [],
      skip: 0,
      take: 20,
      SearchRequest: {
        Year: '',
        Month: ''
      },
      selectedMonthYear: null,
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      loading: false
    };
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    this.populateExchangeRate();
    this.setHeaderTitle();

    $(document).ready(function() {
      $("div.title-remove > div > span > span > span:last-child").removeAttr("title");
  });
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Exchange Rate');
    }
  };

  /**
   * getExchangeRate()
   * Retrieves All the exchange rates
   * @returns {array} exchangeRates
   */
  populateExchangeRate = () => {
    getExchangeRate().then(response => {
      const ExchangeRateDates = response.data;

      ExchangeRateDates.forEach(function(data) {
        const combineDate = new Date(data.Year, data.Month);
        const ISOString = combineDate.toISOString();
        data.MonthYear = ISOString;
      });
      if (this.isMount) {
        this.setState({
          exchangerate: response.data
        });
      }
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  render() {
    return (
      <div>
        <div className="main-card">
          <div className="row">
            <div className="col-md-8">
              <div className="main-heading">Exchange Rate Search</div>
            </div>

            <div className="col-md-4">
              <Button primary={true} className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('EDT_EXG_RT') > -1)}>
                <Link
                  style={{ textDecoration: 'none', color: 'white' }}
                  to={{
                    pathname: '/admin/createExchangeRate/createExchangeRate',
                    action: 'create'
                  }}
                >
                  Create New Exchange Rate
                </Link>
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                  <label>Year and Month selection:</label>
                </div>
                <div className="d-block">
                <div className="title-remove">
                  <MonthYearPicker
                    value={this.state.selectedMonthYear}
                    onChange={this.handleOnChangeMonthYear}
                    required={false}
                    name="selectedMonthYear"
                    format={'MMM yyyy'}
                    formatPlaceholder={{ month: 'MMM', year: 'YYYY' }}
                  />
                </div>
              </div>
            </div>
            </div>

                  <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
                Clear
              </Button>
            </div>
            </div>
          

          <div className="main-seperator"/>

          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">Search Results</div>
            </div>
          </div>

          <div className="row mt-0">
            <div className="col-md-12">
              <Grid
                data={orderBy(this.state.exchangerate, this.state.sort).slice(
                  this.state.skip,
                  this.state.take + this.state.skip
                )}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.exchangerate.length}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }
                }
                className="search-result-grid"
              >
                <Column
                  field="MonthYear"
                  title="Month/ Year"
                  // format="MMMM yyyy"
                  cell={props => (
                    <MonthYearCell {...props} />
                  )}
                />
                <Column
                  field="createdAt"
                  title="Created Date"
                  cell={props => <CreatedDateCell {...props} />}
                />
                <Column
                  field="updatedAt"
                  title="Last Modified Date"
                  cell={props => <UpdatedDateCell {...props} />}
                />
                <Column field="UpdatedUser" title="Last Modified User"  width="120px"/>
                <Column field="IsActive" title="Status"  width="100px" cell={props => <StatusCell {...props} />}/>
                <Column
                  field=""
                  title="Actions"
                  width="150px"
                  cell={props => (
                    <EditExchangeRateActionsCell
                      {...props}
                      OnDeactivate={this.OnDeactivate}
                      OnActivate={this.OnActivate}
                    />
                  )}
                />
              </Grid>
              <Loader loading={this.state.loading}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleOnChangeMonthYear = event => {
    const monthYear = event.value;
    const field = event.name;
    if (this.isMount) {
      this.setState(
        {
          [field]: monthYear,
          selectedMonthYear: monthYear,
          SearchRequest: {
            Year: monthYear.getFullYear(),
            Month: monthYear.getMonth() + 1
          }

        },
        () => {
          console.log(this.state);
        }
      );
    }
  };

  /**
   * searchExchangeRate()
   * @param SearchRequest
   * @returns {array} exchangeRate array
   */
  submitSearch = event => {
    if (this.state.selectedMonthYear !== null) {
      event.preventDefault();
      if (this.isMount) {
        this.setState({
          loading: true
        });
      }
      if (this.isMount) {
        this.setState(
          prevState => {

            const SRequest = {
              ...prevState.SearchRequest
            };
            return {
              SearchRequest: SRequest
            };
          },
          () => {

            searchExchangeRate(this.state.SearchRequest).then(res => {
              this.setState({
                exchangerate: res.data,
                loading: false,
                skip: 0
              });
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
          }
        );
      } else {
        this.populateExchangeRate();
      }
    }
  };

  cancelSearch = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.isMount) {
      this.setState(
        {
          selectedMonthYear: null,
          SearchRequest: null
        },
        () => {
          console.log(this.state);
        }
      );
    }
    this.populateExchangeRate();
  };

}

export default ExchangeRate;
