import React, { Component } from "react";
import { Link } from "react-router-dom";

class EditDepartmentActionCell extends Component {

  // handleOnEdit = (event, dataitem, id) => {
  //   event.preventDefault();
  //   if (dataitem) {
  //     this.props.handleOnEdit(dataitem, id);
  //   }
  // };

  render() {
    const { OnDeactivate } = this.props;
    const { OnActivate } = this.props;
    const { handleOnEdit } = this.props;
    const practice = this.props.dataItem;
    return (
      <td className="action-buttons">
        {/*{practice.IsActive === true && (window.USER_PERMISSION.indexOf('ED_PRA') > -1) &&  (
          <Link
            to={{
              pathname: "/masterdata/createpractice/createpractice",
              practice: practice,
              action: "edit",
            }}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-edit" title="Edit" />
            </div>
          </Link>
        )}*/}
        {practice.IsActive === true && (window.USER_PERMISSION.indexOf('ED_PRA') > -1) && (
          <Link
            to={this.props}
            onClick={event => OnDeactivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-unsort" title="Deactivate" />
            </div>
          </Link>
        )}
        {practice.IsActive === false && (window.USER_PERMISSION.indexOf('ED_PRA') > -1) && (
          <Link
            to={this.props}
            onClick={event => OnActivate(event, this.props.dataItem.id)}
          >
            <div className="rounded-link">
              <span className="k-icon k-i-check" title="Activate" />
            </div>
          </Link>
        )}
        {practice.IsActive === true && (window.USER_PERMISSION.indexOf('ED_PRA') > -1) && (
          <Link
          to={{
            pathname: '/masterdata/practice/editdepartment',
            departmentId: this.props.dataItem.id,
            data: this.props.dataItem,
            action: 'view'
          }}
        >
          <div className="rounded-link">
            <span className="k-icon k-i-edit" title="Edit" />
          </div>
        </Link>
        )}
      </td>
    );
  }
}

export default EditDepartmentActionCell;
