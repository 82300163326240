import React, { Component } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input} from '@progress/kendo-react-inputs';
import { DatePicker} from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';


class AllocatePresales extends Component {

    render() {
        return (
            <div className="main-card">

                <div className="row">

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5"><label for="">Project Name:</label></div>
                            <div className="col-md-7">
                                <Input />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5"><label for="" className="mandatory">Start Date:</label></div>
                            <div className="col-md-7">
                                <DatePicker />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5"><label for="" className="mandatory">End Date:</label></div>
                            <div className="col-md-7">
                                <DatePicker />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5"><label for="">Status:</label></div>
                            <div className="col-md-7">
                                <DropDownList />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-5"><label for="" className="mandatory">Project Description:</label></div>
                    <div className="col-md-12">
                        <textarea className="k-textarea"></textarea>
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5"><label for="" className="mandatory">Customer:</label></div>
                            <div className="col-md-7">
                                <DropDownList />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5"><label for="">Program:</label></div>
                            <div className="col-md-7">
                                <DropDownList />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5"><label for="">OP ID:</label></div>
                            <div className="col-md-7">
                                <Input />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <form className="k-form modified">
                            <div className="k-form-field">
                                <input type="radio" name="group" id="radio1" className="k-radio" />
                                <label className="k-radio-label" for="radio1">Billable</label>

                                <input type="radio" name="group" id="radio2" className="k-radio" />
                                <label className="k-radio-label" for="radio2">Non-Billable</label>
                            </div>
                        </form>
                    </div>

                </div>

                <div className="row">

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5"><label for="" className="mandatory">Practice/Department:</label></div>
                            <div className="col-md-7">
                                <DropDownList />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5"><label for="" className="mandatory">Billing Region:</label></div>
                            <div className="col-md-7">
                                <DropDownList />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5"><label for="" className="mandatory">Project Type:</label></div>
                            <div className="col-md-7">
                                <DropDownList />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-5"><label for="" className="mandatory">Payment Method:</label></div>
                            <div className="col-md-7">
                                <DropDownList />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="main-seperator"></div>

                <div className="row">

                    <div className="col-md-9">

                        <div className="row">
                            <div className="col-md-12"><div className="main-heading">Presales Resource Request</div></div>
                        </div>

                        <div className="row mt-0">
                            <div className="col-md-12">
                                <Grid>
                                    <Column field="ProductID" title="ID" width="40px" />
                                    <Column field="ProductName" title="Name" width="250px" />
                                    <Column field="Category.CategoryName" title="CategoryName" />
                                    <Column field="UnitPrice" title="Price" width="80px" />
                                    <Column field="UnitsInStock" title="In stock" width="80px" />
                                </Grid>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-3 resource-suggestions">

                        <div className="row">
                            <div className="col-md-12"><div className="main-heading">Resource Suggestions</div></div>
                        </div>

                        <div className="row mt-0">
                            <div className="col-md-12">
                                <div className="row search-area">
                                    <div className="col-md-4"><label>Search:</label></div>
                                    <div className="col-md-6 pr-0"><Input /></div>
                                    <div className="col-md-2 search-btn pl-0"><Button icon="search" look="bare"></Button></div>
                                </div>

                                <div className="row list-area">
                                    <div className="col-md-12">

                                        <div className="row item">
                                            <div className="item-wrap">
                                                <div className="col-md-2 item-send"><Button look="bare"><span className="k-icon k-i-arrow-chevron-left"></span></Button></div>
                                                <div className="col-md-8 item-content">
                                                    <div className="name"><label>Duhsman Elvitigala</label></div>
                                                    <div className="untilization">Utilization for period : <span>100%</span></div>
                                                    <div className="allocations">Presales Allocations: <span>24</span></div>
                                                    <div className="skills">Skills:<span>Java, Node</span></div>
                                                </div>
                                                <div className="col-md-2 item-attach"><Button look="bare"><span className="k-icon k-i-attachment"></span></Button></div>
                                            </div>
                                        </div>

                                        <div className="row item">
                                            <div className="item-wrap">
                                                <div className="col-md-2 item-send"><Button look="bare"><span className="k-icon k-i-arrow-chevron-left"></span></Button></div>
                                                <div className="col-md-8 item-content">
                                                    <div className="name"><label>Duhsman Elvitigala</label></div>
                                                    <div className="untilization">Utilization for period : <span>100%</span></div>
                                                    <div className="allocations">Presales Allocations: <span>24</span></div>
                                                    <div className="skills">Skills:<span>Java, Node</span></div>
                                                </div>
                                                <div className="col-md-2 item-attach"><Button look="bare"><span className="k-icon k-i-attachment"></span></Button></div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12 btn-align-right">
                        <Button primary={true}>Search</Button>
                        <Button>Cancel</Button>
                    </div>
                </div>

            </div>
        );
    }
}

export default AllocatePresales;
