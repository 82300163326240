import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { createOffering, updateOffering } from './OfferingService';

import { Redirect } from 'react-router';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

const ACTION_CREATE = 'create';
const ACTION_EDIT = 'edit';

class CreateOffering extends Component {

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      offering: {
        Name: ''
      },

      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      redirectToSearch: false,

      hasUnsavedData: false,

      isNameInvalid: false,
      disabledSave: false
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.setHeaderTitle(this.props.location.action);
    if (this.props.location.action === ACTION_EDIT && this.props.location.offering) {
      if (this.isMount) {
        this.setState({
          offering: this.props.location.offering
        });
      }
    }
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async action => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      if (action === ACTION_CREATE) {
        this.props.onHeaderTitleChange('Add New Offering');
      } else if (action === ACTION_EDIT) {
        this.props.onHeaderTitleChange('Edit Offering');
      }
    }
  };

  handleOnChangeName = event => {
    const value = event.target.value;
    if (this.isMount) {
      this.setState(prevState => {
        return {
          offering: { ...prevState.offering, Name: value },
          hasUnsavedData: true,
          isNameInvalid: false
        };
      });
    }
  };

  handleSaveButtonClick = event => {
    event.preventDefault();

    if (!this.state.offering.Name) {
      if (this.isMount) {
        this.setState({
          isNameInvalid: true
        });
      }
      return;
    }

    if (this.isMount) {
      this.setState(
        {
          disabledSave: true
        },
        () => {
          if (this.props.location.action === ACTION_CREATE) {
            createOffering(this.state.offering)
              .then(res => {
                this.setState({
                  showSuccessImage: true,
                  showErrorImage: false,
                  disabledSave: false
                });
                const message = `The offering ${this.state.offering.Name} is successfully created`;
                const title = 'Success';
                this.toggleMessageDialog(message, title);
              })
              .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                const message = error.response.data;
                const title = 'Error';
                this.setState({
                  showSuccessImage: false,
                  showErrorImage: true,
                  disabledSave: false
                });
                this.toggleMessageDialog(message, title);
              });
          }

          if (this.props.location.action === ACTION_EDIT) {
            updateOffering(this.state.offering)
              .then(res => {
                this.setState({
                  showSuccessImage: true,
                  showErrorImage: false,
                  disabledSave: false
                });
                const message = 'The offering has been successfully updated';
                const title = 'Success';
                this.toggleMessageDialog(message, title);
              })
              .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                const message = error.response.data;
                const title = 'Error';
                this.setState({
                  showSuccessImage: false,
                  showErrorImage: true,
                  disabledSave: false
                });
                this.toggleMessageDialog(message, title);
              });
          }
        }
      );
    }
  };

  toggleMessageDialog = (message, title) => {
    if (this.state.showMessageDialog === true && this.state.dialogTitle === 'Success') {
      this.navigateToSearch();
    } else {
      if (this.isMount) {
        this.setState({
          showMessageDialog: !this.state.showMessageDialog,
          dialogMessage: message,
          dialogTitle: title
        });
      }
    }
  };

  toggleConfirmDialog = (message, action) => {
    if (this.isMount) {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    }
  };

  navigateToSearch = () => {
    if (this.isMount) {
      this.setState({
        redirectToSearch: true
      });
    }
  };

  onClickCancel = event => {
    event.preventDefault();
    if (this.state.hasUnsavedData === true) {
      this.toggleConfirmDialog(
        'This form contain unsaved data. Do you want to close it?',
        this.navigateToSearch
      );
    } else {
      this.navigateToSearch();
    }
  };

  render() {
    if (this.state.redirectToSearch === true) {
      return <Redirect to="/admin/offerings/search"/>;
    }

    return (
      <div className="main-card">
        <div className="main-heading">Offering Details</div>
        <div className="row">
          <div className="col-md-3">
            <div className="d-block">
                <label className="mandatory">Offering Name:</label>
              </div>
              <div className="d-block">
              <div className="">
                <Input
                  value={this.state.offering.Name}
                  onChange={this.handleOnChangeName}
                  maxLength={100}
                  required={true}
                />
                <span className={this.state.isNameInvalid ? 'inline-error' : 'd-none'}>
                  Please enter a name
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 btn-align-right">
            <Button
              primary={true}
              onClick={this.handleSaveButtonClick}
              disabled={this.state.disabledSave}
            >
              Save
            </Button>
            <Button onClick={this.onClickCancel}>Cancel</Button>
          </div>
        </div>

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success"/>
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image"/>
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    );
  }
}

export default CreateOffering;
