import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
    Button
} from '@progress/kendo-react-buttons';
import {
    getPracticesSF,
    searchPractices, updatePracticeSFStatus,
    updatePracticeStatus
} from './PracticeService';
import CreatedDateCell from './components/CreatedDateCell';
import UpdatedDateCell from './components/UpdatedDateCell';
import StatusCell from './components/StatusCell';
import EditPracticeActionsCell from './components/EditPracticeActionsCell';

import { Link } from 'react-router-dom';
import { orderBy } from '@progress/kendo-data-query';
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

class PracticeSF extends Component {

    isMount = false;

    componentDidMount() {

        window.scrollTo(0, 0);
        this.isMount = true;
        this.populatePracticeList();
        this.setHeaderTitle();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Practice');
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            PracticesList: [],
            skip: 0,
            take: 20,
            SearchRequest: {
                PracticeName: '',
                searchStatus: null
            },
            showMessageDialog: false,
            deactivatePracticeId: 0,
            deactivatePopupVisible: false,
            status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
            activatePracticeId: 0,
            activatePopupVisible: false,
            sort: [{ field: 'updatedAt', dir: 'desc' }],
            loading: false
        };
    }

    populatePracticeList = () => {
        getPracticesSF().then(response => {       
            if(response.data){              
                response.data.forEach(e => {
                    let text = "";
                    for(let i of e.PracticeHeadForRegion){
                        let prep = e.PracticeHeadForRegion.indexOf(i) < e.PracticeHeadForRegion.length - 1 ? " , " : " ";
                        let regionName = i.AllRegionHead ? 'All' : i.AllocatedRegion ? i.AllocatedRegion.Name : '';
                        let depName = i.BillingDivision ? i.BillingDivision.Name : '';
                        text = text + depName + " - " + regionName + " - " + i.User.displayName + prep
                    }
                    e.PracticeHead = text;
                });
            }
            if (this.isMount) {
                this.setState({
                    PracticesList: response.data
                });
            }
        })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
    };

    toggleDialog = (message, title) => {
        if (this.isMount) {
            this.setState({
                showMessageDialog: !this.state.showMessageDialog,
                dialogMessage: message,
                dialogTitle: title
            });
        }
    };

    pageChange = event => {
        if (this.isMount) {
            this.setState({
                skip: event.page.skip,
                take: event.page.take
            });
        }
    };

    render() {
        return (
            <div>
                <div className="main-card">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="main-heading">Practice Search</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <div className="d-block">
                                <label>Practice Name:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <Input
                                        value={this.state.SearchRequest.PracticeName}
                                        onChange={this.handleOnChangeInput}
                                        name="PracticeName"
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-2">
                            <div className="d-block">
                                <div className=""><label>Status:</label></div>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <ComboBox
                                        name="searchStatus"
                                        textField="Name"
                                        dataItemKey="status"
                                        value={this.state.SearchRequest.searchStatus}
                                        data={this.state.status}
                                        placeholder={'Please Select'}
                                        onChange={this.handleOnChangeInput}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                            <Button primary={true} type="submit" onClick={this.submitSearch}>
                                Search
                            </Button>
                            <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
                                Clear
                            </Button>
                        </div>
                    </div>

                    <div className="main-seperator"/>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-heading">Search Results</div>
                        </div>
                    </div>

                    <div className="row mt-0">
                        <div className="col-md-12">
                            <Grid
                                data={orderBy(this.state.PracticesList, this.state.sort).slice(
                                    this.state.skip,
                                    this.state.take + this.state.skip
                                )}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.PracticesList.length}
                                pageable={true}
                                onPageChange={this.pageChange}
                                resizable
                                sortable={true}
                                sort={this.state.sort}
                                onSortChange={(e) => {
                                    this.setState({
                                        sort: e.sort
                                    });
                                }
                                }
                                className="search-result-grid"
                            >
                                <Column field="Name" title="Practice"/>
                                <Column field="PracticeHead" title="Practice Head"/>
                                {/*<Column field="SFCode" title="SFCode"/>*/}
                                <Column
                                    field="createdAt"
                                    title="Created Date"
                                    width="130px"
                                    cell={props => <CreatedDateCell {...props} />}
                                />
                                <Column
                                    field="updatedAt"
                                    title="Last Modified Date"
                                    width="130px"
                                    cell={props => <UpdatedDateCell {...props} />}
                                />
                                <Column field="IsActive" title="Status" width="100px" cell={props => <StatusCell {...props} />}/>
                                <Column
                                    field=""
                                    title="Actions"
                                    width="150px"
                                    cell={props => (
                                        <EditPracticeActionsCell
                                            {...props}
                                            OnDeactivate={this.OnDeactivate}
                                            OnActivate={this.OnActivate}
                                        />
                                    )}
                                />
                            </Grid>
                        </div>
                    </div>
                    <div>
                        {this.state.deactivatePopupVisible && (
                            <Dialog title={'Please Confirm'} onClose={this.deactivateConfirmationClose}>
                                <p style={{ margin: '25px', textAlign: 'center' }}>
                                    Are you sure you want to Deactivate this?
                                </p>
                                <DialogActionsBar>
                                    <button className="k-button" onClick={this.deactivateConfirmationClose}>
                                        No
                                    </button>
                                    <button className="k-button modal-primary" onClick={this.deactivateConfirmationOk}>
                                        Yes
                                    </button>
                                </DialogActionsBar>
                            </Dialog>
                        )}
                    </div>
                    {this.state.showMessageDialog === true && (
                        <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                            {this.state.showSuccessImage === true && (
                                <div className="text-center">
                                    <img className="successImg" src={SuccessImg} alt="Success"/>
                                </div>
                            )}

                            {this.state.showErrorImage === true && (
                                <div className="text-center">
                                    <img className="successImg" src={ErrorImg} alt="Error Image"/>
                                </div>
                            )}
                            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                            <DialogActionsBar>
                                {this.state.showSuccessImage === true && (
                                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                                        OK
                                    </button>
                                )}

                                {this.state.showErrorImage === true && (
                                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                                        OK
                                    </button>
                                )}
                            </DialogActionsBar>
                        </Dialog>
                    )}
                    <div>
                        {this.state.activatePopupVisible && (
                            <Dialog title={'Please Confirm'} onClose={this.activateConfirmationClose}>
                                <p style={{ margin: '25px', textAlign: 'center' }}>
                                    Are you sure you want to Activate this?
                                </p>
                                <DialogActionsBar>
                                    <button className="k-button" onClick={this.activateConfirmationClose}>
                                        No
                                    </button>
                                    <button className="k-button modal-primary" onClick={this.activateConfirmationOk}>
                                        Yes
                                    </button>
                                </DialogActionsBar>
                            </Dialog>
                        )}
                    </div>
                    <Loader loading={this.state.loading}/>
                </div>
            </div>
        );
    }

    submitSearch = event => {
        event.preventDefault();
        if (this.isMount) {
            this.setState({
                loading: true
            });

            this.setState(
                prevState => {
                    const SRequest = {
                        ...prevState.SearchRequest
                    };
                    return {
                        SearchRequest: SRequest
                    };
                },
                () => {
                    searchPractices(this.state.SearchRequest).then(res => {
                        res.data.forEach(e => {
                            let text = "";
                            for(let i of e.PracticeHeadForRegion){
                                let prep = e.PracticeHeadForRegion.indexOf(i) < e.PracticeHeadForRegion.length - 1 ? ", " : "";
                                let regionName = i.AllRegionHead ? 'All' : i.AllocatedRegion ? i.AllocatedRegion.Name : '';
                                let depName = i.BillingDivision ? i.BillingDivision.Name : '';
                                text = text + depName + " - " + regionName + " - " + i.User.displayName + prep
                            }
                            e.PracticeHead = text;
                        });
        
                        this.setState({
                            PracticesList: res.data,
                            loading: false,
                            skip: 0
                        });
                    })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });
                }
            );
        }
    };

    handleOnChangeInput = event => {
        const field = event.target.name;
        // const value = event.target.value;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        if (this.isMount) {
            this.setState(prevState => {
                return {
                    SearchRequest: { ...prevState.SearchRequest, [field]: value }
                };
            });
        }
    };

    OnDeactivate = async (event, id) => {
        event.preventDefault();
        if (this.isMount) {
            await this.setState({
                deactivatePracticeId: id
            });
        }
        this.deactivateConfirmationClose();
    };

    deactivateConfirmationClose = async () => {
        if (this.isMount) {
            await this.setState({
                deactivatePopupVisible: !this.state.deactivatePopupVisible
            });
        }
    };

    deactivateConfirmationOk = async () => {
        await updatePracticeSFStatus({ IsActive: false }, this.state.deactivatePracticeId)
            .then(response => {
                if (this.isMount) {
                    this.setState({
                        showSuccessImage: true,
                        showErrorImage: false
                    });
                }
                this.toggleDialog('The Practice has been Deactivated successfully', 'Success');
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        await this.populatePracticeList();
        if (this.isMount) {
            await this.setState({
                deactivatePopupVisible: false
            });
        }
    };

    OnActivate = async (event, id) => {
        event.preventDefault();
        if (this.isMount) {
            await this.setState({
                activatePracticeId: id
            });
        }
        this.activateConfirmationClose();
    };

    activateConfirmationClose = async () => {
        if (this.isMount) {
            await this.setState({
                activatePopupVisible: !this.state.activatePopupVisible
            });
        }
    };

    activateConfirmationOk = async () => {
        await updatePracticeSFStatus({ IsActive: true }, this.state.activatePracticeId)
            .then(response => {
                if (this.isMount) {
                    this.setState({
                        showSuccessImage: true,
                        showErrorImage: false
                    });
                }
                this.toggleDialog('The Department has been Activated successfully', 'Success');
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        await this.populatePracticeList();
        if (this.isMount) {
            await this.setState({
                activatePopupVisible: false
            });
        }
    };

    cancelSearch = event => {
        event.preventDefault();
        if (this.isMount) {
            this.setState({
                SearchRequest: {
                    PracticeName: ''
                }
            });
        }
        this.populatePracticeList();
    };
}

export default PracticeSF;
