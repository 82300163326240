import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

export function getDesignationLevels() {
  const endPoint = apiUrl + '/masterData/hrdesignation/designationlevels';
  return http.get(endPoint);
}

export function getCostDesignations() {
    const endPoint = apiUrl + '/masterData/hrdesignation/costdesignations';
    return http.get(endPoint);
  }

export function saveHrDesignation(params) {
  const endPoint = apiUrl + '/masterData/hrdesignation/save';
  return http.post(endPoint, params);
}

export function searchHrDesignations(params) {
  const endPoint = apiUrl + '/masterData/hrdesignation/search';
  return http.post(endPoint, params);
}

export function getAllActiveHRDesignations() {
  const endPoint = apiUrl + '/masterData/hrdesignation/active-hr-designations';
  return http.get(endPoint);
}

/**
 * Update HR Designation status by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param IsActive
 * @param id
 */
 export function updateHRDesignationStatus(IsActive, id) {
  const endPoint = apiUrl + '/masterData/hrdesignation/updateHRDesignationStatus/' + id;
  return http.put(endPoint, IsActive);
}