import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs/dist/npm/main';
import { Tooltip } from 'reactstrap';

class CMSSelectDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cmsToolTipOpen: false,
            cmsToolTipCROpen: false

        }
    }

    toggleCMSToolTip = () => {
        if (this.isMount) {
            this.setState({
                cmsToolTipOpen: !this.state.cmsToolTipOpen
            });
        }
    };

    toggleCMSCRToolTip = () => {
        if (this.isMount) {
            this.setState({
                cmsToolTipCROpen: !this.state.cmsToolTipCROpen
            });
        }
    };

    dialogStatus = (status) => {
        if (status) {
            switch (status) {
                case 'IN_PROGRESS': {
                    return 'In Progress';
                    break;
                }
                case 'PENDING_APPROVALS': {
                    return 'Pending Approvals';
                    break;
                }
                case 'APPROVED': {
                    return 'Approved';
                    break;
                }
                case 'REJECTED': {
                    return 'Rejected';
                    break;
                }
                case 'EXPIRED': {
                    return 'Expired';
                    break;
                }
                case 'WITHDRAWN': {
                    return 'Withdrawn';
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    render() {
        return (
            <Dialog title={"Select the relevant CMS to " + (this.props.cmsAction === 'requestReviseCMS' ? 'Request Revision' : this.props.cmsAction)} onClose={this.props.toggleViewCMSSelectDialog} width="400px">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="mb-0">Project CMS</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 cms-popdialog">
                                <div className="k-form-field">
                                    {this.props.projectCMS.map((option, i) => {
                                        return (
                                            <div key={'fewufi' + i}>
                                                <input
                                                    type="radio"
                                                    id={'ppppp' + option.data.id}
                                                    name={'ppppp' + option.data.id}
                                                    value={option.data.id}
                                                    checked={this.props.selectedCMSId === option.data.id}
                                                    className="k-radio"
                                                    key={'vvvvv' + i}
                                                    onChange={this.props.setSelectedCMSId.bind(this, option.data.id, option.data, 'projectCMS')}
                                                />
                                                <label
                                                    key={'ppppp' + i}
                                                    htmlFor={'ppppp' + option.data.id}
                                                    className="k-radio-label case-insensitive"
                                                >
                                                    {option.name}
                                                    <td id="CMSTooltip" className="badge badge-warning ml-2 my-auto px-2 py-1">{this.dialogStatus(option.data.CMSheets[0].Status)} </td>
                                                    <Tooltip
                                                        target="CMSTooltip"
                                                        isOpen={this.state.cmsToolTipOpen}
                                                        toggle={this.toggleCMSToolTip}
                                                        placement="right"
                                                        className="cmstooltipopen">
                                                        Project: {option.name} | Version: {option.data.CMSheets[0].Version} | Status: {this.dialogStatus(option.data.CMSheets[0].Status)}
                                                    </Tooltip>
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12 font-black">
                        {this.props.projectCMS.length > 0 ? '' : (<label> NO Project CMS found</label>)}
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="mb-0">Change Request CMS</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 cms-popdialog">
                                <form className="k-form modified">
                                    <div className="k-form-field">
                                        {this.props.changeRequestCMS.map((option, i) => {
                                            return (
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id={'npp' + option.data.id}
                                                        name={'npp' + option.data.id}
                                                        value={option.data.id}
                                                        checked={this.props.selectedCMSId === option.data.id}
                                                        className="k-radio"
                                                        key={'aaapca' + option.id}
                                                        onChange={this.props.setSelectedCMSId.bind(this, option.data.id, option.data, 'crCMS')}
                                                    />
                                                    <label
                                                        key={'xpp' + option.data.id}
                                                        htmlFor={'npp' + option.data.id}
                                                        className="k-radio-label case-insensitive"
                                                    >
                                                        {option.data.IsPoolProject === true ? option.data.FinancialYear : option.name}

                                                        <td id="CMSTooltipCR" className="badge badge-warning ml-2 my-auto px-2 py-1">{this.dialogStatus(option.data.CMSheets[0].Status)}</td>
                                                        <Tooltip
                                                            target="CMSTooltipCR"
                                                            isOpen={this.state.cmsToolTipCROpen}
                                                            toggle={this.toggleCMSCRToolTip}
                                                            placement="right"
                                                            className="cmstooltipopen">
                                                            Project: {option.name} | Version: {option.data.CMSheets[0].Version} | Status: {this.dialogStatus(option.data.CMSheets[0].Status)}
                                                        </Tooltip>
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12">
                        {this.props.changeRequestCMS.length > 0 ? '' : (<label>No Change Request CMS Found</label>)}
                    </div>
                </div>

                <DialogActionsBar>
                    <button className="k-button" onClick={this.props.toggleViewCMSSelectDialog}>
                        No
                    </button>
                    <button
                        className="k-button modal-primary"
                        onClick={this.props.selectCMS}
                        disabled={!this.props.selectedCMSId}
                    >
                        Yes
                    </button>
                </DialogActionsBar>
            </Dialog>

        )
    }
}

export default CMSSelectDialog;
