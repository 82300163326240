import React, { useState, Component } from "react";
import { Link } from "react-router-dom";
import approvedStatus from "../../enums/ApprovedStatusTypes";
import { Tooltip } from 'reactstrap';


const StatusCell = (props) => {

        const dataItem = props.dataItem;

        const toolTip = (value) => {
            const [tooltipOpen, setTooltipOpen] = useState(false);
        
            const toggle = () => setTooltipOpen(!tooltipOpen);
            return(
                <Tooltip placement="right" isOpen={tooltipOpen} target={'TooltipExample' + dataItem.Id} toggle={toggle} className="timecard-tooltip">
                            <div>{value}</div>
                </Tooltip>
            )
        }

        return (
            <td id={'TooltipExample' + dataItem.Id}>
                {dataItem.ApprovedStatus === approvedStatus.Pending_Approval.value && (
                    <span id={'TooltipExample' + dataItem.Id}><span className="timesheet-grid-status timesheet-grid-status-none">&nbsp;</span> Approval Pending {dataItem.ResonOfMove !== null && (toolTip(dataItem.ResonOfMove))}
                    </span>
                )}
                {dataItem.ApprovedStatus === approvedStatus.Approved.value && (
                    <span id={'TooltipExample' + dataItem.Id}><span  className="timesheet-grid-status timesheet-grid-status-green">&nbsp;</span> Approved – Billable</span>
                )}
                {dataItem.ApprovedStatus === approvedStatus.Approved_NB.value &&
                dataItem.ApprovedNonBillingCatagoryId ===
                approvedStatus.Approved_NB_General.value ? (
                    <span id={'TooltipExample' + dataItem.Id}><span  className="timesheet-grid-status timesheet-grid-status-blue">&nbsp;</span> Approved Non-Billable - General</span>
                ) : null}
                {dataItem.ApprovedStatus === approvedStatus.Approved_NB.value &&
                dataItem.ApprovedNonBillingCatagoryId ===
                approvedStatus.Approved_NB_ESE.value ? (
                    <span  id={'TooltipExample' + dataItem.Id}><span className="timesheet-grid-status timesheet-grid-status-yellow">&nbsp;</span> Approved Non-Billable - Estimation Issues</span>
                ) : null}
                {dataItem.ApprovedStatus === approvedStatus.Approved_NB.value &&
                dataItem.ApprovedNonBillingCatagoryId ===
                approvedStatus.Approved_NB_PMM.value ? (
                    <span  id={'TooltipExample' + dataItem.Id}><span className="timesheet-grid-status timesheet-grid-status-purple">&nbsp;</span> Approved Non-Billable - Mismanagement</span>
                ) : null}
                {dataItem.ApprovedStatus === approvedStatus.Approved_NB.value &&
                dataItem.ApprovedNonBillingCatagoryId ===
                approvedStatus.Approved_NB_RWK.value ? (
                    <span  id={'TooltipExample' + dataItem.Id}><span className="timesheet-grid-status timesheet-grid-status-orange">&nbsp;</span> Approved Non-Billable - Rework</span>
                ) : null}
                {dataItem.ApprovedStatus === approvedStatus.Rejected.value && (
                    <span  id={'TooltipExample' + dataItem.Id}><span className="timesheet-grid-status timesheet-grid-status-gray">&nbsp;</span> Rejected {toolTip(dataItem.RejectReason)}</span>
                )}
                {dataItem.ApprovedStatus === approvedStatus.Auto_Generated.value && (
                    <span  id={'TooltipExample' + dataItem.Id}><span className="timesheet-grid-status timesheet-grid-status-light-blue">&nbsp;</span> Automatic Approvals</span>
                )}
                {dataItem.ApprovedStatus === approvedStatus.Leave.value && (
                    <span  id={'TooltipExample' + dataItem.Id}><span className="timesheet-grid-status timesheet-grid-status-light-blue">&nbsp;</span> Leave</span>
                )}
                                {dataItem.ApprovedStatus === approvedStatus.Approved.value ||
                dataItem.ApprovedStatus === approvedStatus.Approved_NB.value ? (
                    toolTip("Approved By : " + dataItem.ApprovedBy)
                ) : null}
            </td>
        );
}

export default StatusCell;
