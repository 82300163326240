/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Button } from '@progress/kendo-react-buttons';

class HeaderCellAddButton extends Component {
    render() {
        return (
            <Button
                className="rounded-btn"
                disabled={this.props.disabled}
                onClick={this.props.onAddButtonClick}
            >
                <span className="k-icon k-i-plus" />
            </Button>
        );
    }
}

export default HeaderCellAddButton;
