import http from '../integration/HttpService';
const apiUrl = require('../config').get(process.env.REACT_APP_ENV).apiUrl;


/**
 * saveUserProfile - Save User Profile(
 * @param params
 * @returns {array}  array of saved details of User Profile
 */
export function saveUserProfile(params) {
  const endPoint = apiUrl + '/resourceManagement/userProfile/save';
  return http.post(endPoint, params);
}

/**
 * Get user profile by given param.
 * @param id.
 * @returns {object}  array object of user profile
 */
export function getUserProfile(id) {
  const endPoint = apiUrl + '/resourceManagement/userProfile/resourcesbyUserId/' + id;
  return http.get(endPoint, null);
}

/**
 * Update User Profile by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param userProfile
 * @param id
 */
export function updateUserProfile(userProfile, id) {
  const updateUserProfileEndpoint = apiUrl + '/resourceManagement/userProfile/update/' + id;
  return http.put(updateUserProfileEndpoint, userProfile);
}

/**
 * uploadCMSFile - Upload a new File
 * @param file
 * @returns {object}
 */
export function uploadFile(file) {
  const endPoint = apiUrl + '/resourceManagement/fileUpload/upload/';
  return http.post(endPoint, file);
}