import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class TaskStatusCell extends Component {
  render() {
    const value = this.props.dataItem.IsActive;
    return <td>{value === true ? 'Active' : 'Inactive'}</td>;
  }
}

export default TaskStatusCell;
