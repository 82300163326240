import React, { Component } from 'react';

class PendingTaskTypeCell extends Component {

    render() {
        const value = this.props.dataItem;

        if (this.props.dataItem.Type === 'CMS_PENDING_APPROVAL') {
            return <td>CMS Approval Pending</td>;
        }
        else if (this.props.dataItem.Type === 'RESOURCE_REQUEST_PENDING_APPROVAL') {
            return <td>Resource Allocation Pending</td>;
        }else if (this.props.dataItem.Type === 'RESOURCE_REQUEST_APPROVAL_WORKFLOW') {
            return <td>Resource Request Approval Pending</td>;
        }
        else if (this.props.dataItem.Type === 'RESOURCE_ALLOCATION_APPROVAL_WORKFLOW') {
            return <td>Resource Allocation Approval Pending</td>;
        }
        else if (this.props.dataItem.Type === 'FACTORY_POOL_PLANNING_SHEET_APPROVAL') {
            return <td>Factory Pool Planning Sheet Approval</td>;
        } else if (this.props.dataItem.Type === 'STATUS_CHANGE_APPROVAL') {
            return <td>Status Change</td>;
        }
        else if (this.props.dataItem.Type === 'TIMESHEET_UNFREEZE_APPROVAL') {
            if (this.props.dataItem.UnFreezeRequests.RequestType === "TimeEntry") {
                return <td>Time Entry Unfreeze Request</td>;
            } else {
                return <td>Time Approval Unfreeze Request</td>;
            }        
        }
        else if (this.props.dataItem.Type === 'TIME_ENTRY_WEEKLY') {
            return <td>Time Entry Freeze</td>;
        }
        else if (this.props.dataItem.Type === 'TIME_APPROVAL_WEEKLY') {
            return <td>Time Approval Freeze</td>;
        }
        else if (this.props.dataItem.Type === 'TIME_ENTRY_UNFREEZE_APPROVAL') {
            return <td>Timesheet Approval Pending</td>;
        }
        else if (this.props.dataItem.Type === 'REVISE_CMS_PENDING_APPROVAL') {
            return <td>CMS Revision Approval Pending</td>;
        }
        else if (this.props.dataItem.Type === 'INVOICE_REQUEST_PENDING_APPROVAL') {
            return <td>Invoice Request Approval Pending</td>;
        }
        


    }
}

export default PendingTaskTypeCell;
