import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';


import CostCardActionsCell from './components/CostCardActionsCell';
import StatusCell from './components/StatusCell';
import CreatedDateCell from './components/CreatedDateCell';
import UpdatedDateCell from './components/UpdatedDateCell';
import ProjectCell from './components/ProjectCell';
import {
  searchCostCards, updateCostCardStatus, exportCostCards
} from './CostCardService';
import {getResourceRegionsIntegrated, getMultiYearInformationIntegrated, saveCostCardIncrementsIntegrated, deleteMultiYearGridInfoIntegrated} from '../../integration/MasterDataService';
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

class CostCardSearch extends Component {
  _export;
  isMount = false;

  constructor(props) {
    super(props);

    this.state = {

      costCards: [],
      resourceRegions: [],
      resourceRegionsAll: [],

      billingRegions: [],
      billingRegionsAll: [],

      costCardName: '',

      selectedResourceRegion: null,
      selectedBillingRegion: null,
      visible: false,

      skip: 0,
      take: 20,

      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      searchStatus: { Name: 'Active', status: true },
      status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      loading: false
    };

    this.exportData = this.exportData.bind(this);
  }

  componentDidMount() {
    this.isMount = true;
    if (this.isMount) {
      this.props.onHeaderTitleChange('Cost Card');
    }
    this.populateCostCards();
    this.populateRegions();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  exportData = event => {
    try {

      if (this.isMount) {
        this.setState({
          loading: true
        });
      }
  
      const fields = {};
      if (this.state.selectedBillingRegion) {
        fields.BillingRegionId = this.state.selectedBillingRegion.id;
      }
  
      if (this.state.selectedResourceRegion) {
        fields.ResourceRegionId = this.state.selectedResourceRegion.id;
      }
  
      if (this.state.costCardName) {
        fields.Name = this.state.costCardName;
      }
  
      if (this.state.searchStatus) {
        fields.IsActive = this.state.searchStatus.Name;
      }
  
      if (this.state.selectedProject) {
        fields.ProjectId = this.state.selectedProject.id;
      }


      exportCostCards(fields).then(res => {
        if (this.isMount) {
          let sortedCostCards = orderBy(res.data, this.state.sort)
          if (sortedCostCards && sortedCostCards.length > 0) {
            const ExportData = [];
            sortedCostCards.forEach(obj => {
              if (obj.CostCardDesignations && obj.CostCardDesignations.length > 0) {
                obj.CostCardDesignations.forEach(x => {
                  let object = {
                    Name: obj.Name,
                    BillingRegion: obj.BillingRegion ? obj.BillingRegion.Name : '',
                    ResourceRegion: obj.ResourceRegion ? obj.ResourceRegion.Name : '',
                    Project: obj.Project ? `${obj.Project.UniqueId} - ${obj.Project.ProjectName}` : '',
                    createdAt: moment(obj.createdAt).format("MM/DD/YYYY"),
                    updatedAt: moment(obj.updatedAt).format("MM/DD/YYYY"),
                    StartDate: moment(obj.StartDate).format("MM/DD/YYYY"),
                    EndDate: moment(obj.EndDate).format("MM/DD/YYYY"),
                    Designation: x.Designation? x.Designation.Name:'',
                    CurrentRateDC: x.CostToCompany,
                    CurrentRateOH: x.Overhead,
                    NewRateDC: x.CostToCompany,
                    NewRateOH: x.Overhead,
                    IsActive: obj.IsActive ? 'Active' : 'Inactive'
                  };
  
                  ExportData.push(object);
                })
              }
            });
            if (ExportData && ExportData.length > 0) {
              this._export.save(ExportData);
            }
          }
        }

        this.setState({
          loading: false
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  }

  populateCostCards = () => {
    const fields = {};
    if (this.state.searchStatus) {
      fields.IsActive = 'Active'
   }

    searchCostCards(fields)
      .then(res => {
        if (this.isMount) {
          this.setState({
            costCards: res.data
          });

          // Load all the projects related to cost cards
          this.populateProjects(res.data);
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateProjects = async (costCards) => {
    this.allProjects = [];

    for(var costCard of costCards){

      var record = this.allProjects.find(i => i.id == costCard.ProjectId);
      var isRecordNotContain = (record == undefined) ? true : false;

      if(costCard.ProjectId != null && isRecordNotContain){
        var obj = {
          'id': costCard.Project.id,
          'UniqueId': costCard.Project.UniqueId,
          'OPID': costCard.Project.OPID,
          'ProjectName': costCard.Project.ProjectName,
          'displayName': costCard.Project.UniqueId +" - "+costCard.Project.ProjectName,
        }
        this.allProjects.push(obj);
      }
    }

    this.allProjects.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));

    if (this.isMount) {
      this.setState({
        projects: this.allProjects
      });
    }
  };

  populateRegions = () => {
    getResourceRegionsIntegrated()
      .then(res => {
        const resourceRegion = [];
        const billingRegion = [];
        res.data.forEach(function(region) {
          if (region.CostRegion === false) {
            billingRegion.push(region);
          }
          resourceRegion.push(region);
        });

        if (this.isMount) {
          this.setState({
            billingRegions: billingRegion,
            billingRegionsAll: billingRegion,
            resourceRegions: resourceRegion,
            resourceRegionsAll: resourceRegion
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  toggleConfirmDialog = (message, action) => {
    if (this.isMount) {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  handleOnChangeRegion = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  filterChangeRegion = event => {
    clearTimeout(this.timeout);
    const field = event.target.name;
    this.timeout = setTimeout(() => {
      if (this.isMount) {
        switch (field) {
          case 'selectedResourceRegion': {
            this.setState({
              resourceRegions: this.filterComboData(event.filter, this.state.resourceRegionsAll)
            });
            break;
          }
          case 'selectedBillingRegion': {
            this.setState({
              billingRegions: this.filterComboData(event.filter, this.state.billingRegionsAll)
            });
            break;
          }
          default: {
          }
        }
      }
    }, 0);
  };

  handleInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    if (this.isMount) {
      await this.setState({ [name]: value, isFormDirty: true });
    }
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  handleOnChangeInput = event => {
    const field = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (this.isMount) {
      this.setState(prevState => {
        return {
          [field]: value
        };
      });
    }
  };

  handleProjectChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    if (this.isMount) {
      await this.setState({ [name]: value, isFormDirty: true });
    }
  };

  filterProjectOnChange = event => {
    this.setState({
      projects: this.filterProjectData(event.filter)
    });
  };

  filterProjectData(filter) {
    const data = this.allProjects.slice();
    return filterBy(data, filter);
  }

  submitSearch = event => {
    event.preventDefault();

    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    const fields = {};
    if (this.state.selectedBillingRegion && this.state.selectedBillingRegion.length > 0) {
      fields.BillingRegionId = this.state.selectedBillingRegion.map(value => value.id);
    }

    if (this.state.selectedResourceRegion && this.state.selectedResourceRegion.length > 0) {
      fields.ResourceRegionId = this.state.selectedResourceRegion.map(value => value.id);
    }

    if (this.state.costCardName) {
      fields.Name = this.state.costCardName;
    }

    if (this.state.searchStatus) {
      fields.IsActive = this.state.searchStatus.Name;
    }

    if (this.state.selectedProject && this.state.selectedProject.length > 0) {
      fields.ProjectId = this.state.selectedProject.map(value => value.id);
    }

    searchCostCards(fields)
      .then(res => {
        this.setState({
          costCards: res.data,
          loading: false,
          skip: 0
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  cancelSearch = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        costCardName: '',
        selectedResourceRegion: [],
        selectedBillingRegion: [],
        searchStatus: null,
        selectedProject: []
      });
    }
    this.populateCostCards();
    this.populateRegions();
  };

  OnDeactivate = async (event, id) => {
    event.preventDefault();
    if (this.isMount) {
      await this.setState({
        deactivateCostCardId: id
      });
    }
    this.deactivateConfirmationClose();
  };

  deactivateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: !this.state.deactivatePopupVisible
      });
    }
  };

  deleteMultiYearGridInfo = async () => {
    getMultiYearInformationIntegrated().then(response => {
      let yearRecords = response.data;
      if (yearRecords.length > 0) {
        for (const item of yearRecords) {
          if (item.id) {
            const multiYearCostCardData = item.MultiyearIncrementGrids.find((gridDetails) => {
              return gridDetails.CostCardId === this.state.deactivateCostCardId;
            });
            if (multiYearCostCardData) {
              const data = {
                id: multiYearCostCardData.id
              };
              deleteMultiYearGridInfoIntegrated(data);
            }
          }
        }
      }
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  deactivateConfirmationOk = async () => {
    await updateCostCardStatus({ IsActive: false }, this.state.deactivateCostCardId)
      .then(response => {
        const promises = [];
        promises.push(this.deleteMultiYearGridInfo());
        Promise.all(promises).then(() => {
          if (this.isMount) {
            this.setState({
              showSuccessImage: true,
              showErrorImage: false
            });
          }
          this.toggleDialog('The Cost Card has been Deactivated successfully', 'Success');
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.populateCostCards();
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: false
      });
    }
  };

  OnActivate = async (event, id) => {
    event.preventDefault();
    if (this.isMount) {
      await this.setState({
        activateCostCardId: id
      });
    }
    this.activateConfirmationClose();
  };

  activateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        activatePopupVisible: !this.state.activatePopupVisible
      });
    }
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  activateConfirmationOk = async () => {
    await updateCostCardStatus({ IsActive: true }, this.state.activateCostCardId)
      .then(response => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog('The Cost Card has been Activated successfully', 'Success');
        let data = [];
        getMultiYearInformationIntegrated().then(response => {
          let yearRecords = response.data;
          if (yearRecords.length > 0) {
            for (const item of yearRecords) {
              if (item.id) {
                const multiYearCostCardData = item.MultiyearIncrementGrids.find((gridDetails) => {
                  return gridDetails.CostCardId === this.state.activateCostCardId;
                });
                if (!multiYearCostCardData) {
                  const costData = {
                    MultiyearInfoGridId: item.id,
                    CostCardId: this.state.activateCostCardId,
                    Type: 'Cost Card'
                  };
                  data.push(costData);
                }
              }
            }
            if (data.length > 0) {
              saveCostCardIncrementsIntegrated(data);
            }
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.populateCostCards();
    if (this.isMount) {
      await this.setState({
        activatePopupVisible: false
      });
    }
  };

  render() {
    return (

      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Cost Card Search</div>
          </div>
          <div className="col-md-4">
            <Button primary={true} className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('EDT_COS_CR') > -1)}>
              <Link
                style={{ textDecoration: 'none', color: 'white' }}
                to={{
                  pathname: '/admin/costcard/create',
                  action: 'create'
                }}
              >
                Create New Cost Card
              </Link>
            </Button>
          </div>
        </div>
        <form onSubmit={this.submitSearch}>
          <div className="main-heading"/>
          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                  <label>Cost Card Name:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <Input
                    value={this.state.costCardName}
                    onChange={this.handleOnChangeInput}
                    name="costCardName"
                    maxLength="100"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          
            <div className="col-md-2">
              <div className="d-block">
                  <label>Resource Region:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <MultiSelect
                    placeholder="Please Select"
                    data={this.state.resourceRegions}
                    value={this.state.selectedResourceRegion}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleOnChangeRegion}
                    name="selectedResourceRegion"
                    filterable={true}
                    onFilterChange={this.filterChangeRegion}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                  <label>Billing Region:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <MultiSelect
                    placeholder="Please Select"
                    data={this.state.billingRegions}
                    value={this.state.selectedBillingRegion}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleOnChangeRegion}
                    name="selectedBillingRegion"
                    filterable={true}
                    onFilterChange={this.filterChangeRegion}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label className="optional">
                  Project:
                </label>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                      name="selectedProject"
                      textField="displayName"
                      dataItemKey="id"
                      value={this.state.selectedProject}
                      data={this.state.projects}
                      placeholder={'Please Select'}
                      onChange={this.handleInputChange}
                      filterable={true}
                      onFilterChange={this.filterProjectOnChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Status:</label></div>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                    name="searchStatus"
                    textField="Name"
                    dataItemKey="status"
                    value={this.state.searchStatus}
                    data={this.state.status}
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}/>
                </div>
              </div>
            </div>
          </div>

                <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
                Clear
              </Button>
            </div>
            </div>
        </form>

        <div className="main-seperator"/>

        <div className="row">
          <div className="col-md-10">
            <div className="main-heading">Search Results</div>
          </div>
          <div className="col-md-2 btn-align-right">
            <button
              title="Export to Excel"
              className="k-button k-primary"
              onClick={this.exportData}>
              Export to Excel
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
          <ExcelExport
              ref={(exporter) => {
                this._export = exporter;
              }}
              fileName={'CostCards ' + new Date()}
            >
              <ExcelExportColumn field="Name" title="Cost Card Name" />
              <ExcelExportColumn field="BillingRegion" title="Billing Region" />
              <ExcelExportColumn field="ResourceRegion" title="Resource Region" />
              <ExcelExportColumn field="Customer" title="Customer" />
              <ExcelExportColumn field="Project" title="Project" />
              <ExcelExportColumn field="createdAt" title="Created Date" />
              <ExcelExportColumn field="updatedAt" title="Last Modified Date" />
              <ExcelExportColumn field="StartDate" title="Validity Period From" />
              <ExcelExportColumn field="EndDate" title="Validity Period To" />
              <ExcelExportColumn field="Designation" title="Designation" />

              <ExcelExportColumn field="CurrentRateDC" title="Current Rate - Direct cost per day" />
              <ExcelExportColumn field="CurrentRateOH" title="Current Rate - Overhead" />
              <ExcelExportColumn field="NewRateDC" title="New Rate - Direct cost per day" />
              <ExcelExportColumn field="NewRateOH" title="New Rate - Overhead" />

              <ExcelExportColumn field="IsDesignationActive" title="Designation Status" />
              <ExcelExportColumn field="IsActive" title="Status" />
            </ExcelExport>
            
            <Grid
              data={orderBy(this.state.costCards, this.state.sort).slice(
                this.state.skip,
                this.state.take + this.state.skip
              )}
              skip={this.state.skip}
              take={this.state.take}
              total={this.state.costCards.length}
              pageable={true}
              onPageChange={this.pageChange}
              resizable
              sortable={true}
              sort={this.state.sort}
              onSortChange={(e) => {
                this.setState({
                  sort: e.sort
                });
              }
              }
              className="search-result-grid"
            >
              <Column field="Name" title="Cost Card Name"/>
              <Column field="BillingRegion.Name" title="Billing Region"/>
              <Column field="ResourceRegion.Name" title="Resource Region"/>
              <Column field="" title="Project"
                      cell={props => <ProjectCell {...props} />}/>
              <Column field="createdAt" title="Created Date"
                      cell={props => <CreatedDateCell {...props} />}/>
              <Column field="updatedAt" title="Last Modified Date"
                      cell={props => <UpdatedDateCell {...props} />}/>
              <Column field="updatedUser" title="Last Modified User" width="120px"/>
              <Column field="IsActive" title="Status" width="100px" cell={props => <StatusCell {...props} />}/>
              <Column
                field=""
                title="Actions"
                width="150px"
                cell={props => (
                  <CostCardActionsCell
                    {...props}
                    OnDeactivate={this.OnDeactivate}
                    OnActivate={this.OnActivate}
                  />
                )}
              />
            </Grid>
          </div>
        </div>
        {this.state.visible === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success"/>
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image"/>
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
              <button className="k-button modal-primary" onClick={this.toggleConfirmDialog}>
                No
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        <div>
          {this.state.deactivatePopupVisible && (
            <Dialog title={'Please Confirm'} onClose={this.deactivateConfirmationClose}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Are you sure you want to Deactivate this?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.deactivateConfirmationClose}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.deactivateConfirmationOk}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <div>
          {this.state.activatePopupVisible && (
            <Dialog title={'Please Confirm'} onClose={this.activateConfirmationClose}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Are you sure you want to Activate this?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.activateConfirmationClose}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.activateConfirmationOk}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <div>

          {this.state.showMessageDialog === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

              {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success"/>
                </div>
              )}

              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image"/>
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

              <DialogActionsBar>
                {this.state.showSuccessImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                    OK
                  </button>
                )}

                {this.state.showErrorImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                    OK
                  </button>
                )}
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <Loader loading={this.state.loading}/>
      </div>

    );
  }
}

export default CostCardSearch;



