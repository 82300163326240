import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CriteriaEditActionCell extends Component {
  render() {
    const { onEdit } = this.props;
    const { onDelete } = this.props;

    return (
      <td className="action-buttons">
        <Link to={this.props} onClick={event => onEdit(event, this.props.dataItem)}>
          <div className="rounded-link">
            <span className="k-icon k-i-edit" title="Edit" />
          </div>
        </Link>
        <Link to={this.props} onClick={event => onDelete(event, this.props.dataItem)}>
          <div className="rounded-link">
            <span className="k-icon k-i-delete" title="Delete" />
          </div>
        </Link>
      </td>
    );
  }
}
export default CriteriaEditActionCell;
