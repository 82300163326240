import React, { Component, useEffect, useState } from 'react';
import { GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Loader as Spinner } from '@progress/kendo-react-indicators';
import { orderBy } from '@progress/kendo-data-query';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-react-dialogs';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { Tooltip } from 'reactstrap';
import approvedStatus from '../enums/ApprovedStatusTypes';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import Loader from '../../integration/components/Loader';
import { getHolidaysIntegrated } from '../../integration/MasterDataService';
import { getFormattedDate, isWeekend } from '../../integration/CommonUtils';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import Panel from './components/Panel';
import DateCell from './components/DateCell';
import CircularJSON from 'circular-json';
import 'moment-timezone';
import Moment from 'react-moment';

import {
  DateInput,
  Calendar,
  DatePicker,
  DateTimePicker,
  TimePicker,
  MultiViewCalendar,
  DateRangePicker
} from '@progress/kendo-react-dateinputs';
import {
  Scheduler,
  AgendaView,
  TimelineView,
  DayView,
  WeekView,
  MonthView,
  SchedulerItem
} from '@progress/kendo-react-scheduler';
import {
  getResourceList,
  getTaskListAPI,
  getTimeCards,
  getDateForProjectFilter,
  getDataForTaskFilter,
  moveTimeEntry,
  getAllResourceListForFilter,
  saveSearchCriteria,
  getSearchCriteria,
  approveBillable,
  approveBillableAllPending,
  approveNonBillable,
  rejectTimeEntry,
  getCalculatedTotals,
  getBilableCalculatedTotals,
  getCalculatedTotalsDaily,
  getBilableCalculatedTotalsDaily,
  getTimeEntries,
  getUserUnFreezeDays,
  getTimeApprovalFreezeDateFromTimesheetFreezeDate,
  setWorkflow,
  getUserUnFreezeDaysWithUserId,
  getAllPendingApprovalTimes,
  checkFutureCardsExist,
  unApprove,
  approveNonBillableAllPending
} from './TimeSheetApprovalService';
import { getProjectTaskAllocationsWihTime } from '../projectTaskManagement/ProjectTaskManagementService';
import moment from 'moment';
import { parseInt } from 'lodash';
import StatusCell from './components/StatusCell';
import DurationCell from './components/DurationCell';
import { getUserList } from '../../userManagement/user/userService';
import { getApproverUnfreezeDates } from '../timeSheetEntry/TimeSheetEntryService';
import UnFreezeStatus from '../enums/UnFreezeStatus';
import { selectTimeSheetUnfreezeRequestWorkflow } from '../../projectManagement/workflow/WorkflowService';
import WorkLocationStatus from '../enums/WorkLocationStatus';
import RowCellNumericTextBox from './components/RowCellNumericTextBox';

class TimeSheetAppovalDaily extends Component {
  isMount = false;
  lastSelectedIndex = 0;
  workLocationStatuses = Object.values(WorkLocationStatus);

  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
      nonbilldialog: false,
      moveDialog: false,
      unfreezeTa: false,
      rejectTa: false,
      loader: false,
      status: 0,
      windowDialogVisible: false,
      resourceList: [],
      timesheet: [<td />],
      totalHrsCalcu: [<td />],
      resourceaccordion: [<td />],
      allpendingtimeapp: [<td />],
      taskdetailtooltipopen: false,
      firstDayOfWeek: null,
      LastDayOfWeek: null,
      dateOfSun: null,
      dateOfMon: null,
      dateOfTue: null,
      dateOfWed: null,
      dateOfTur: null,
      dateOfFri: null,
      dateOfSat: null,
      newDate: new Date(),
      newDateDaily: new Date(),
      successAlert: false,
      successMsg: '',
      allChecked: false,
      expand: false,
      resourceListForFilter: [],
      resourceListForFilterAll: [],
      practiceListForFilter: [],
      practiceListForFilterAll: [],
      regionListForFilter: [],
      regionListForFilterAll: [],
      projectFilter: [],
      projectFilterAll: [],
      taskFilter: [],
      taskFilterAll: [],
      selectedList: [],
      selectedResourceList: [],
      moveTaskFilter: [],
      moveReason: null,
      selectedMoveTaskFilter: null,
      moveTaskFilterAll: [],
      rejectReason: null,
      selectedProjectOrCr: null,
      confirmedProjFilterValue: null,
      selectedTask: null,
      confirmedTaskFilterValue: null,
      selectedResource: [],
      confirmedResourceFilterValue: null,
      selectedPractice: [],
      selectedRegion: [],
      IsApprNotNeed: false,
      ConfirmeIsApprNotNeed: false,
      IsRejected: false,
      IsMyResources: false,
      ConfirmeIsRejected: false,
      IsApprovalNonBillable: false,
      ConfirmeIsApprovalNonBillable: false,
      IsApprovalBillable: false,
      ConfirmeIsApprovalBillable: false,
      IsPendiingApproval: false,
      ConfirmeIsPendiingApproval: false,
      visible: false,
      dialogTitle: '',
      dialogMessage: '',
      showSuccessImage: false,
      showErrorImage: false,
      isErrorMessageVisible: false,
      gridLoader: false,
      refreshState: false,
      IsMyTaskOnly: false,
      isFreeze: false,
      isDailyFreeze: false,
      isAddTimeFreeze: false,
      isFreezeDateOfSun: false,
      isFreezeDateOfMon: false,
      isFreezeDateOfTue: false,
      isFreezeDateOfWed: false,
      isFreezeDateOfTur: false,
      isFreezeDateOfFri: false,
      isFreezeDateOfSat: false,
      isUnfreezeButtonDisable: true,
      unfreezePopupVisible: false,
      unfreezeReason: '',
      matchingWorkflowList: [],
      showWorkflowSelectDialog: false,
      showAlreadyUnfreezeRequestSendResources: false,
      selectedWorkFlowId: 0,
      selectedDates: [],
      isErrorMessageVisibleReason: false,
      timeApprovalFreezeDate: '',
      timeApprovalFreezeDateForDisplay: '',
      timeApprovalUnFreezeDates: [],
      timeApprovalUnFreezeDatesUserId: [],
      taskForMove: [],
      approveNonBillableCategory: [
        {
          id: approvedStatus.Approved_NB_General.value,
          name: 'Non - Billable – General'
        },
        {
          id: approvedStatus.Approved_NB_ESE.value,
          name: 'Non - Billable ESE  - Estimation Issues'
        },
        {
          id: approvedStatus.Approved_NB_PMM.value,
          name: 'Non - Billable PMM - Mismanagement'
        },
        {
          id: approvedStatus.Approved_NB_RWK.value,
          name: 'Non - Billable RWK - Rework'
        }
      ],
      selectedApproveNonBillableCategory: {
        id: approvedStatus.Approved_NB.value,
        name: 'Non - Billable – General'
      },
      list: [
        {
          id: 1,
          isChecked: false,
          Project: 'M3 Implementation',
          Customer: 'Beega',
          Task: 'M3 Master Data Configurations',
          Hours: 1,
          Description: 'Lorem Ipsum'
        },
        {
          id: 2,
          isChecked: false,
          Project: 'M3 Upgrade',
          Customer: 'Avera',
          Task: 'Testing',
          Hours: 1,
          Description: 'Testing 123'
        }
      ],
      allPanelExapnded: null,
      viewStatus: null,
      minDate: null,
      selectAll: false,
      fromResourceDate: [new Date().getFullYear(), new Date().getMonth(), 1].join('-'),
      toResourceDate: new Date(),
      freezedDateDaily: false,
      allPendingUnfreezeDates: [],
      sortDaily: [{ field: 'Id', dir: 'desc' }],
      sortAllPending: [{ field: 'Id', dir: 'desc' }],
      disablePrvBtn: true,
      disableDailyPrvBtn: true,
      sortWeekly: [{ field: 'Id', dir: 'desc' }],
      selectAllDaily: false,
      selectAllWeekly: false,
      allPendingSkip: 0,
      isBillableDataLoading: false,
      taskOpened: false,
      allPendingUnfreezeDatesUserId: [],
      havingtwoMonthBackDates: false,
      unfreezeMessage: '',
      isClickedBillable: false,
      isClickedNonBillable: false,
      TimeNotEntered: false
    };
    this.toggleWindowDialog = this.toggleWindowDialog.bind(this);
    this.toggleRejectDialog = this.toggleRejectDialog.bind(this);
    this.toggleNonbillDialog = this.toggleNonbillDialog.bind(this);
    this.toggleUnfreezeDialog = this.toggleUnfreezeDialog.bind(this);
  }

  deSelectCheckBox = () => {
    let data = this.state.resourceList;
    data.map(user => {
      user.monAllSelect = false;
      user.tueAllSelect = false;
      user.wedAllSelect = false;
      user.thuAllSelect = false;
      user.friAllSelect = false;
      user.satAllSelect = false;
      user.sunAllSelect = false;
    });
    this.setState({
      resourceList: data
    });
  };

  getResourceList = () => {
    const params = {
      firstDate:
        this.state.status === 1
          ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
          : this.state.status === 3
          ? moment(this.state.fromResourceDate).format('YYYY-MM-DD')
          : moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
      lastDate:
        this.state.status === 1
          ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
          : this.state.status === 3
          ? moment(this.state.toResourceDate).format('YYYY-MM-DD')
          : moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
      project: this.state.selectedProjectOrCr,
      task: this.state.selectedTask,
      resource: this.state.selectedResource,
      practice: this.state.selectedPractice,
      region: this.state.selectedRegion,
      IsApprNotNeed: this.state.IsApprNotNeed,
      IsRejected: this.state.IsRejected,
      IsApprovalNonBillable: this.state.IsApprovalNonBillable,
      IsApprovalBillable: this.state.IsApprovalBillable,
      IsPendiingApproval: this.state.IsPendiingApproval,
      IsMyResources: this.state.IsMyResources,
      TimeNotEntered: this.state.TimeNotEntered,
      approver:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
          ? this.state.IsMyTaskOnly !== true
            ? 'GlobleApprover'
            : 'genaralApprover'
          : 'genaralApprover',
      approverId: window.LOGGED_USER.user,
      type: this.state.status
    };

    this.setState(
      {
        confirmedProjFilterValue: this.state.selectedProjectOrCr,
        confirmedTaskFilterValue: this.state.selectedTask,
        confirmedResourceFilterValue: this.state.selectedResource,
        ConfirmeIsApprNotNeed: this.state.IsApprNotNeed,
        ConfirmeIsRejected: this.state.IsRejected,
        ConfirmeIsApprovalNonBillable: this.state.IsApprovalNonBillable,
        ConfirmeIsApprovalBillable: this.state.IsApprovalBillable,
        ConfirmeIsPendiingApproval: this.state.IsPendiingApproval,
        resourceList: [],
        loader: this.state.status != 3 ? true : false,
        selectedList: [],
        selectAllDaily: false,
        selectAllWeekly: false,
        isBillableDataLoading: true
      },
      async () => {
        await getResourceList(params).then(res => {
          this.setState(
            {
              resourceList: res.data,
              loader: false,
              refreshState: !this.state.refreshState
            },
            () => {
              if (this.state.status === 3) {
                if (this.state.resourceList.length > 0) {
                  if (this.state.selectedResource) {
                    this.getTaskList(
                      this.state.selectedResource.userId,
                      'initial',
                      'InPending',
                      this.state.selectedResource.isMyResource
                    );
                  } else if (this.state.selectedProjectOrCr || this.state.selectedTask) {
                    this.getTaskList(
                      res.data[0].userId,
                      'initial',
                      'InPending',
                      res.data[0].isMyResource
                    );
                  } else if (this.state.IsMyResources) {
                    this.getTaskList(
                      res.data[0].userId,
                      'initial',
                      'InPending',
                      res.data[0].isMyResource
                    );
                  } else {
                    let userLogged = res.data.filter(x => x.userId === window.LOGGED_USER.user);
                    if (userLogged.length > 0) {
                      this.getTaskList(userLogged[0].userId, 'initial', 'InPending');
                    } else {
                      this.setState({
                        totalResourceTimeEntryPending: []
                      });
                    }
                  }
                } else {
                  this.setState({
                    totalResourceTimeEntryPending: []
                  });
                }
              }
              this.setUnfreezeButtonEnableDisable();
            }
          );
        });
      }
    );
  };

  clearStatus = () => {
    this.setState({
      IsPendiingApproval: false,
      IsApprovalBillable: false,
      IsApprovalNonBillable: false,
      IsRejected: false,
      IsApprNotNeed: false,
      IsMyResources: false,
      IsMyTaskOnly: false,
      TimeNotEntered: false
    });
  };

  clearFilters = () => {
    this.setState(
      {
        IsPendiingApproval: false,
        IsApprovalBillable: false,
        IsApprovalNonBillable: false,
        IsRejected: false,
        IsApprNotNeed: false,
        IsMyResources: false,
        IsMyTaskOnly: false,
        selectedProjectOrCr: null,
        selectedTask: null,
        selectedResource: [],
        selectedPractice: [],
        selectedRegion: [],
        loader: true,
        TimeNotEntered: false
      },
      async () => {
        if (this.state.status != 3) {
          this.getResourceList();
        } else {
          await this.getPendingApprovals(this.state.fromResourceDate, this.state.toResourceDate);
        }
      }
    );
  };
  taskList = [];
  taskListforMove = [];

  getTaskListToMove = async (userId, date) => {
    this.setState({
      loader: true
    });
    const para = {
      userId: userId,
      firstDate: moment(date).format('YYYY-MM-DD'),
      lastDate: moment(date).format('YYYY-MM-DD'),
      project: null,
      resource: this.state.confirmedResourceFilterValue,
      task: null,
      approver:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
          ? this.state.IsMyTaskOnly !== true
            ? 'GlobleApprover'
            : 'genaralApprover'
          : 'genaralApprover',
      status: 'InWeekly',
      isMyResource: false
    };
    await getTaskListAPI(para).then(async res => {
      this.taskListforMove = res.data;
    });
    this.setState({
      loader: false
    });
  };

  getTaskList = async (userId, type, view, isMyResource) => {
    this.setState({
      loader: this.state.status != 3 ? true : false,
      selectAllDaily: false,
      isBillableDataLoading: true
    });
    //await this.FreezeUI();
    if (this.state.status !== 3) {
      await this.FreezeUI();
    }
    if (view === 'InWeekly') {
      const para = {
        userId: userId,
        firstDate: moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
        lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
        project: null,
        resource: this.state.confirmedResourceFilterValue,
        task: null,
        approver:
          window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
            ? this.state.IsMyTaskOnly !== true
              ? 'GlobleApprover'
              : 'genaralApprover'
            : 'genaralApprover',
        status: view,
        isMyResource: isMyResource
      };
      await getTaskListAPI(para).then(async res => {
        let resourceList = this.state.resourceList;
        let objIndex = resourceList.findIndex(obj => obj.userId === userId);
        resourceList[objIndex].allTasks = res.data;
        this.setState({
          resourceList: resourceList
        });
      });
    }
    const param = {
      userId: userId,
      firstDate:
        view === 'InDaily' ? moment(this.state.newDateDaily).format('YYYY-MM-DD') 
          : view === 'InPending' ? moment(this.state.fromResourceDate).format('YYYY-MM-DD')
          : moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
      lastDate:
        view === 'InDaily' ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
          : view === 'InPending' ? moment(this.state.toResourceDate).format('YYYY-MM-DD')
          : moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
      project: this.state.confirmedProjFilterValue,
      resource: this.state.confirmedResourceFilterValue,
      task: this.state.confirmedTaskFilterValue,
      approver:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
          ? this.state.IsMyTaskOnly !== true
            ? 'GlobleApprover'
            : 'genaralApprover'
          : 'genaralApprover',
      status: view,
      isMyResource: isMyResource
    };
    await getTaskListAPI(param).then(async res => {
      if (res.data.length !== 0) {
        let resourceList = this.state.resourceList;
        let objIndex = resourceList.findIndex(obj => obj.userId === userId);
        if (
          typeof resourceList[objIndex].tasks !== 'undefined' && 
          resourceList[objIndex].tasks.length === 0 ||
          type === 'forUpdate' ||
          resourceList[objIndex].selected === true
        ) {
          this.taskList = res.data;
          let params = this.taskList.map((task, index) => {
            return { index: index, task: task.taskId };
          });
          await getProjectTaskAllocationsWihTime(params).then(res => {
            if (res.data && res.data.length > 0) {
              res.data.forEach(i => {
                this.taskList[i.index].allocationHours = i.allocationHours;
              });
            }
          });

          resourceList[objIndex].tasks = res.data;
          this.setState(
            {
              resourceList: resourceList
            },
            () => {
              if (view === 'InDaily' || view === 'InPending') {
                this.getBilableCalculatedTotalsDaily(userId);
              } else {
                this.setState({ loader: false });
                this.getBilableCalculatedTotals(userId);
              }
            }
          );
        }
        const weekData = {
          userId: userId,
          firstDate:
            this.state.status === 1
              ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
              : this.state.status === 3
              ? moment(this.state.fromResourceDate).format('YYYY-MM-DD')
              : moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
          lastDate:
            this.state.status === 1
              ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
              : this.state.status === 3
              ? moment(this.state.toResourceDate).format('YYYY-MM-DD')
              : moment(this.state.LastDayOfWeek).format('YYYY-MM-DD')
        };
        getApproverUnfreezeDates(weekData).then(async res => {
          if (res.data.length !== 0) {
            let isFreezeDateOfMon = this.state.isFreezeDateOfMon;
            let isFreezeDateOfTue = this.state.isFreezeDateOfTue;
            let isFreezeDateOfWed = this.state.isFreezeDateOfWed;
            let isFreezeDateOfThu = this.state.isFreezeDateOfThu;
            let isFreezeDateOfFri = this.state.isFreezeDateOfFri;
            let isFreezeDateOfSat = this.state.isFreezeDateOfSat;
            let isFreezeDateOfSun = this.state.isFreezeDateOfSun;
            let isFreezeSelectedDateDaily = true;
            const approverUnfreezeDates = res.data;
            for (const record of approverUnfreezeDates) {
              for (const approverUnfreezeDate of record.UnfreezeTasksApproverDate) {
                const date = new Date(approverUnfreezeDate.UnfreezeDate);
                if (this.state.status === 1) {
                  let newDateDaily = moment(this.state.newDateDaily).format('YYYY-MM-DD');
                  let unfreezedDate = moment(date).format('YYYY-MM-DD');
                  if (unfreezedDate === newDateDaily) {
                    isFreezeSelectedDateDaily = false;
                  }
                } else {
                  const dayOfWeek = date.getDay();
                  if (dayOfWeek === 1) {
                    isFreezeDateOfMon = false;
                  } else if (dayOfWeek === 2) {
                    isFreezeDateOfTue = false;
                  } else if (dayOfWeek === 3) {
                    isFreezeDateOfWed = false;
                  } else if (dayOfWeek === 4) {
                    isFreezeDateOfThu = false;
                  } else if (dayOfWeek === 5) {
                    isFreezeDateOfFri = false;
                  } else if (dayOfWeek === 6) {
                    isFreezeDateOfSat = false;
                  } else if (dayOfWeek === 0) {
                    isFreezeDateOfSun = false;
                  }
                }
              }
              if (
                (this.state.status == 2 && this.state.isFreezeDateOfSun) ||
                this.state.isFreezeDateOfMon ||
                this.state.isFreezeDateOfTue ||
                this.state.isFreezeDateOfWed ||
                this.state.isFreezeDateOfThu ||
                this.state.isFreezeDateOfFri ||
                this.state.isFreezeDateOfSat
              ) {
                resourceList[objIndex].isFromUnFreezeTimeEntry = true;
                resourceList[objIndex].isFreezeDateOfMon = isFreezeDateOfMon;
                resourceList[objIndex].isFreezeDateOfTue = isFreezeDateOfTue;
                resourceList[objIndex].isFreezeDateOfWed = isFreezeDateOfWed;
                resourceList[objIndex].isFreezeDateOfThu = isFreezeDateOfThu;
                resourceList[objIndex].isFreezeDateOfFri = isFreezeDateOfFri;
                resourceList[objIndex].isFreezeDateOfSat = isFreezeDateOfSat;
                resourceList[objIndex].isFreezeDateOfSun = isFreezeDateOfSun;
              } else if (this.state.status == 1) {
                resourceList[objIndex].isFromUnFreezeTimeEntry = true;
                resourceList[objIndex].isFreezeSelectedDateDaily = isFreezeSelectedDateDaily;
                await this.setState({
                  isFreeze: isFreezeSelectedDateDaily
                });
              }
            }
            if (
              this.state.status == 2 &&
              (isFreezeDateOfMon ||
                isFreezeDateOfTue ||
                isFreezeDateOfWed ||
                isFreezeDateOfThu ||
                isFreezeDateOfFri ||
                isFreezeDateOfSat ||
                isFreezeDateOfSun)
            ) {
              await this.setState({
                isFreeze: true
              });
            } else {
              await this.setState({
                isFreeze: false
              });
            }
          }
          if (this.state.status != 3) {
            this.setState({ loader: false });
          }
          this.setState({
            resourceList: resourceList
          });
        });
      } else {
        this.setState({
          loader: false,
          totalResourceTimeEntryPending: []
        });
      }
    });
  };

  getTaskOnlyUpdatedUser = () => {
    const userList = this.state.resourceList.filter(x => x.tasks.length !== 0);
    userList.length !== 0 &&
      userList.map(user => {
        if (this.state.status === 1) {
          this.getTaskList(user.userId, 'forUpdate', 'InDaily', user.isMyResource);
        } else if (this.state.status === 3) {
          this.getTaskList(user.userId, 'forUpdate', 'InPending', user.isMyResource);
        } else {
          this.getTaskList(user.userId, 'forUpdate', 'InWeekly', user.isMyResource);
        }
      });
  };

  getTimeCards = userId => {
    let alldata = this.state.resourceList;
    let objIndex = alldata.findIndex(obj => obj.userId === userId);
    let data = alldata[objIndex].tasks;
    let IsSelected = alldata[objIndex].selected;
    data.forEach(async task => {
      let nonProjAllocations = [];
      let projAllcations = [];
      if (task.taskType == 'NPT') {
        nonProjAllocations = task.allocations;
      }
      if (task.taskType == 'PT' || task.taskType == 'CRT') {
        projAllcations = task.allocations;
      }
      const params = {
        firstDate: moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
        lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
        projAllcations: projAllcations,
        nonProjAllocations: nonProjAllocations,
        IsApprNotNeed: this.state.ConfirmeIsApprNotNeed,
        IsRejected: this.state.ConfirmeIsRejected,
        IsApprovalNonBillable: this.state.ConfirmeIsApprovalNonBillable,
        IsApprovalBillable: this.state.ConfirmeIsApprovalBillable,
        IsPendiingApproval: this.state.ConfirmeIsPendiingApproval
      };

      await getTimeCards(params).then(res => {
        if (res.data.length !== 0) {
          let cardWithoutAutoGenarated = res.data.filter(
            x => x.ApprovedStatus !== approvedStatus.Auto_Generated.value
          );
          alldata[objIndex].noOfCards =
            alldata[objIndex].noOfCards + cardWithoutAutoGenarated.length;
          res.data.forEach(timeEntry => {
            const day = moment(timeEntry.LogDateTime);
            let durationParts = timeEntry.ApprovedDuration ? timeEntry.ApprovedDuration.split('.') : timeEntry.Duration.split('.');
            let duration = durationParts[0];
            if (typeof durationParts[1] !== 'undefined') {
              duration =
                parseInt(duration) +
                ':' +
                Math.round(parseFloat(`0.${durationParts[1]}`) * 60)
                  .toString()
                  .padStart(2, '0');
            } else {
              duration = parseInt(duration) + ':' + '00';
            }
            const card = {
              projOrGroupId:
                timeEntry.ProjectTaskAllocation === null
                  ? timeEntry.NonProjectTaskAllocation.NonProjectTask.TaskGroupId
                  : timeEntry.ProjectTaskAllocation.ProjectTask.ProjectId,
              IsApproverNeeded:
                timeEntry.ProjectTaskAllocation === null
                  ? timeEntry.NonProjectTaskAllocation.NonProjectTask.IsApproverNeeded
                  : false,
              CrId:
                timeEntry.ProjectTaskAllocation !== null
                  ? timeEntry.ProjectTaskAllocation.ProjectTask.CRId !== null
                    ? timeEntry.ProjectTaskAllocation.ProjectTask.CRId
                    : null
                  : null,
              cardId: timeEntry.id,
              IsBillable:
                timeEntry.ProjectTaskAllocation !== null
                  ? timeEntry.ProjectTaskAllocation.ProjectTask.BillabilityTypeId ===
                      'NONBILLABLE' ||
                    timeEntry.ProjectTaskAllocation.ProjectTask.IsPresales === true
                    ? false
                    : true
                  : false,
              RateType:
                timeEntry.ProjectTaskAllocation !== null
                  ? timeEntry.ProjectTaskAllocation.ResourceRequest.RateType
                  : null,
              userId:
                timeEntry.ProjectTaskAllocation === null
                  ? timeEntry.NonProjectTaskAllocation.UserId
                  : timeEntry.ProjectTaskAllocation.ResourceId,
              checked:
                timeEntry.ApprovedStatus === approvedStatus.Auto_Generated.value ||
                timeEntry.ApprovedStatus === approvedStatus.Leave.value
                  ? false
                  : IsSelected,
              comment: timeEntry.ApprovedComment ? timeEntry.ApprovedComment : timeEntry.Comment,
              TicketId: timeEntry.ApprovedTicketId ? timeEntry.ApprovedTicketId : timeEntry.TicketId,
              PMEdited: timeEntry.PMEdited,
              UserEnterDuration: timeEntry.PMEdited ? this.setTimeFormat(timeEntry.Duration) : duration,
              UserEnterComment: timeEntry.Comment ? timeEntry.Comment : '',
              UserEnterTicketId: timeEntry.TicketId ? timeEntry.TicketId : '',
              WorkLocationId: timeEntry.WorkLocationId,
              WorkLocationName: timeEntry.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == timeEntry.WorkLocationId)[0].name : '',
              duration: duration,
              endTime: timeEntry.ToDateTime,
              startTime: timeEntry.FromDateTime,
              approvedStatus: timeEntry.ApprovedStatus,
              ApprovedNonBillingCatagoryId: timeEntry.ApprovedNonBillingCatagoryId,
              ApproveFreezeStatus: timeEntry.ApproveFreezeStatus,
              ApprovedBy: timeEntry.ApprovedBy,
              EntryFreezeStatus: timeEntry.EntryFreezeStatus,
              FromDateTime: timeEntry.FromDateTime,
              ToDateTime: timeEntry.ToDateTime,
              RejectReason: timeEntry.RejectReason,
              ResonOfMove: timeEntry.ResonOfMove,
              LogDateTime: timeEntry.LogDateTime,
              project: task.Project,
              task: task.Task,
              taskId: task.taskId,
              taskType: task.taskType,
              isShiftTask: task.isShiftTask,
              IsOverEightHour: false,
              createdAt: timeEntry.createdAt,
              allocationId:
                timeEntry.ProjectTaskAllocationId !== null
                  ? timeEntry.ProjectTaskAllocationId
                  : timeEntry.NonProjectTaskAllocationId,
              IsPending: timeEntry.IsPending
            };

            switch (day.day()) {
              case 0:
                {
                  if (alldata[objIndex].sunBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Sun.cards.push(card);
                }
                break;
              case 1:
                {
                  if (alldata[objIndex].monBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Mon.cards.push(card);
                }
                break;
              case 2:
                {
                  if (alldata[objIndex].tueBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Tue.cards.push(card);
                }
                break;
              case 3:
                {
                  if (alldata[objIndex].wedBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Wed.cards.push(card);
                }
                break;
              case 4:
                {
                  if (alldata[objIndex].thuBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Thu.cards.push(card);
                }
                break;
              case 5:
                {
                  if (alldata[objIndex].friBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Fri.cards.push(card);
                }
                break;
              case 6:
                {
                  if (alldata[objIndex].satBillableTotal > 8) {
                    card.IsOverEightHour = true;
                  }
                  task.Sat.cards.push(card);
                }
                break;
              default:
                break;
            }
          });
        }
      });
      this.setState({
        loader: false,
        resourceList: alldata
      });
    });
  };

  setMinimumAndDefaultsDateForAllPending = async () => {
    if (this.state.totalResourceTimeEntryPendingAll.length > 0) {
      let minDate = this.state.totalResourceTimeEntryPendingAll[0].LogDateTime;
      let totalResourceTimeEntryPending = [];
      let formatedStartDate = this.state.fromResourceDate;
      //let formatedDate = moment(currentDate).format('YYYY-MM-DD')

      for (let i = 0; i < this.state.totalResourceTimeEntryPendingAll.length; i++) {
        //set minimun date from all resource entries
        if (this.state.totalResourceTimeEntryPendingAll[i].LogDateTime < minDate) {
          minDate = this.state.totalResourceTimeEntryPendingAll[i].LogDateTime;
        }

        //set default time entries
        if (
          formatedStartDate <=
            new Date(this.state.totalResourceTimeEntryPendingAll[i].LogDateTime) &&
          new Date(this.state.totalResourceTimeEntryPendingAll[i].LogDateTime) <= new Date()
        ) {
          totalResourceTimeEntryPending.push(this.state.totalResourceTimeEntryPendingAll[i]);
        }
      }

      await this.setState({
        minDate: minDate,
        totalResourceTimeEntryPending: totalResourceTimeEntryPending,
        loader: false
      });
    }
  };

  getUserList = async () => {
    await getUserList()
      .then(res => {
        this.setState({
          usersAll: res.data
        });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getTimeEntries = userId => {
    let alldata = this.state.resourceList;
    let objIndex = alldata.findIndex(obj => obj.userId === userId);
    let data = alldata[objIndex].tasks;
    let resourceId = null;
    let displayName = null;
    let displayId = null;
    let IsSelected = alldata[objIndex].selected;
    alldata[objIndex].totalResourceTimeEntry = [];
    alldata[objIndex].totalResourceTimeEntryPendingAll = [];
    const apiCount = data.length;
    let executed = 0;
    data.forEach(async task => {
      let nonProjAllocations = [];
      let projAllcations = [];

      if (task.taskType == 'NPT') {
        nonProjAllocations = task.allocations;
      }
      if (task.taskType == 'PT' || task.taskType == 'CRT') {
        projAllcations = task.allocations;
      }
      const params = {
        firstDate: moment(this.state.newDateDaily).format('YYYY-MM-DD'),
        //lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
        projAllcations: projAllcations,
        nonProjAllocations: nonProjAllocations,
        IsApprNotNeed: this.state.ConfirmeIsApprNotNeed,
        IsRejected: this.state.ConfirmeIsRejected,
        IsApprovalNonBillable: this.state.ConfirmeIsApprovalNonBillable,
        IsApprovalBillable: this.state.ConfirmeIsApprovalBillable,
        IsPendiingApproval: this.state.ConfirmeIsPendiingApproval,
        type: this.state.status
      };

      await getTimeEntries(params).then(res => {
        executed++;
        if (res.data[0] && res.data[0].NonProjectTaskAllocation) {
          resourceId = res.data[0].NonProjectTaskAllocation.UserId;
          displayName = this.state.usersAll.filter(obj => obj.id === resourceId)[0].displayName;
          displayId = this.state.usersAll.filter(obj => obj.id === resourceId)[0].id;
        } else if (res.data[0] && res.data[0].ProjectTaskAllocation) {
          resourceId = res.data[0].ProjectTaskAllocation.ResourceId;
          displayName = this.state.usersAll.filter(obj => obj.id === resourceId)[0].displayName;
          displayId = this.state.usersAll.filter(obj => obj.id === resourceId)[0].id;
        }

        if (res.data.length !== 0) {
          res.data.forEach(timeEntry => {
            const day = moment(timeEntry.LogDateTime);
            let durationParts = timeEntry.ApprovedDuration ? timeEntry.ApprovedDuration.split('.') : timeEntry.Duration.split('.');
            let duration = durationParts[0];
            if (typeof durationParts[1] !== 'undefined') {
              duration =
                parseInt(duration) +
                ':' +
                Math.round(parseFloat(`0.${durationParts[1]}`) * 60)
                  .toString()
                  .padStart(2, '0');
            } else {
              duration = parseInt(duration) + ':' + '00';
            }
            const entry = {
              ProjectOrTaskGroupId:
                timeEntry.ProjectTaskAllocation === null
                  ? timeEntry.NonProjectTaskAllocation.NonProjectTask.TaskGroupId
                  : timeEntry.ProjectTaskAllocation.ProjectTask.ProjectId,
              IsApproverNeeded:
                timeEntry.ProjectTaskAllocation === null
                  ? timeEntry.NonProjectTaskAllocation.NonProjectTask.IsApproverNeeded
                  : false,
              CrId:
                timeEntry.ProjectTaskAllocation !== null
                  ? timeEntry.ProjectTaskAllocation.ProjectTask.CRId !== null
                    ? timeEntry.ProjectTaskAllocation.ProjectTask.CRId
                    : null
                  : null,
              Id: timeEntry.id,
              IsBillable:
                timeEntry.ProjectTaskAllocation !== null
                  ? timeEntry.ProjectTaskAllocation.ProjectTask.BillabilityTypeId ===
                      'NONBILLABLE' ||
                    timeEntry.ProjectTaskAllocation.ProjectTask.IsPresales === true
                    ? false
                    : true
                  : false,
              RateType:
                timeEntry.ProjectTaskAllocation !== null
                  ? timeEntry.ProjectTaskAllocation.ResourceRequest.RateType
                  : null,
              UserId:
                timeEntry.ProjectTaskAllocation === null
                  ? timeEntry.NonProjectTaskAllocation.UserId
                  : timeEntry.ProjectTaskAllocation.ResourceId,
              checked:
                timeEntry.ApprovedStatus === approvedStatus.Auto_Generated.value ||
                timeEntry.ApprovedStatus === approvedStatus.Leave.value
                  ? false
                  : IsSelected,
              selected:
                timeEntry.ApprovedStatus === approvedStatus.Auto_Generated.value ||
                timeEntry.ApprovedStatus === approvedStatus.Leave.value
                  ? false
                  : IsSelected,
              Comment: timeEntry.ApprovedComment ? timeEntry.ApprovedComment : timeEntry.Comment,
              TicketId: timeEntry.ApprovedTicketId ? timeEntry.ApprovedTicketId : timeEntry.TicketId,
              PMEdited: timeEntry.PMEdited,
              UserEnterDuration: timeEntry.PMEdited ? this.setTimeFormat(timeEntry.Duration) : duration,
              UserEnterComment: timeEntry.Comment ? timeEntry.Comment : '',
              UserEnterTicketId: timeEntry.TicketId ? timeEntry.TicketId : '',
              WorkLocationId: timeEntry.WorkLocationId,
              WorkLocationName: timeEntry.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == timeEntry.WorkLocationId)[0].name : '',
              Resource: displayName,
              ResourceId: displayId,
              Duration: duration,
              endTime: timeEntry.ToDateTime,
              startTime: timeEntry.FromDateTime,
              ApprovedStatus: timeEntry.ApprovedStatus,
              ApprovedNonBillingCatagoryId: timeEntry.ApprovedNonBillingCatagoryId,
              ApproveFreezeStatus: timeEntry.ApproveFreezeStatus,
              ApprovedBy: timeEntry.ApprovedBy,
              EntryFreezeStatus: timeEntry.EntryFreezeStatus,
              FromDateTime: timeEntry.FromDateTime,
              ToDateTime: timeEntry.ToDateTime,
              RejectReason: timeEntry.RejectReason,
              ResonOfMove: timeEntry.ResonOfMove,
              LogDateTime: timeEntry.LogDateTime,
              Project: task.Project,
              Task: task.Task,
              TaskId: task.taskId,
              TaskType: task.taskType,
              IsShiftTask: task.isShiftTask,
              IsOverEightHour: false,
              AllocationHours: task.allocationHours,
              allocationId:
                timeEntry.ProjectTaskAllocationId !== null
                  ? timeEntry.ProjectTaskAllocationId
                  : timeEntry.NonProjectTaskAllocationId,
              createdAt: timeEntry.createdAt != null ? timeEntry.createdAt : null
            };

            {
              if (alldata[objIndex].dayBillableTotal > 8) {
                entry.IsOverEightHour = true;
              }
              alldata[objIndex].totalResourceTimeEntry.push(entry);
              if (this.state.IsMyResources === true) {
                for (let obj of this.state.resourceList) {
                  if (entry.ApprovedStatus === 1 && obj.userId === entry.ResourceId) {
                    alldata[objIndex].totalResourceTimeEntryPendingAll.push(entry);
                  }
                }
              } else {
                if (entry.ApprovedStatus === 1) {
                  alldata[objIndex].totalResourceTimeEntryPendingAll.push(entry);
                }
              }
            }
          });
        }
        if (apiCount == executed) {
          let loggedResource = alldata.filter(obj => obj.userId === userId);
          this.setState(
            {
              resourceList: alldata,
              totalResourceTimeEntryPendingAll: loggedResource[0].totalResourceTimeEntryPendingAll
            },
            () => {
              if (this.state.status === 3) {
                this.setMinimumAndDefaultsDateForAllPending();
              } else {
                this.setState({ loader: false });
              }
            }
          );
        }
      });
    });
  };

  TotalHoursTimeEnt = props => {
    let total = this.state.list.reduce((acc, current) => acc + current.Hours, 0);
    return <td colSpan={this.props.colSpan}>{total} hours</td>;
  };

  handleChange = e => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState(prevState => {
      let { list, allChecked } = prevState;
      if (itemName === 'checkAll') {
        allChecked = checked;
        list = list.map(item => ({ ...item, isChecked: checked }));
      } else {
        list = list.map(item => (item.name === itemName ? { ...item, isChecked: checked } : item));
        allChecked = list.every(item => item.isChecked);
      }
      return { list, allChecked };
    });
  };

  customCell = props => {
    let objIndex = [];
    objIndex = this.state.resourceList.filter(
      obj => obj.userId === props.dataItem.userId && obj.isFromUnFreezeTimeEntry
    );

    const data = props.dataItem[props.field];
    if (data.cards && data.cards.length > 0){
    }

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const checkCellColor = (taskStartDate, taskEndDate, allocation, day) => {
      switch (day) {
        case 'Mon':
          {
            let currentDate = moment(this.state.dateOfMon).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfMon === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfMon === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else {
                return { backgroundColor: 'gray' };
              }
            } else {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfMon === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfMon === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else if (this.state.isFreezeDateOfMon === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Tue':
          {
            let currentDate = moment(this.state.dateOfTue).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfTue === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfTue === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else {
                return { backgroundColor: 'gray' };
              }
            } else {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfTue === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfTue === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else if (this.state.isFreezeDateOfTue === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Wed':
          {
            let currentDate = moment(this.state.dateOfWed).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfWed === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfWed === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else {
                return { backgroundColor: 'gray' };
              }
            } else {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfWed === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfWed === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else if (this.state.isFreezeDateOfWed === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Thu':
          {
            let currentDate = moment(this.state.dateOfTur).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfThu === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfThu === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else {
                return { backgroundColor: 'gray' };
              }
            } else {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfThu === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfThu === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else if (this.state.isFreezeDateOfThu === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Fri':
          {
            let currentDate = moment(this.state.dateOfFri).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfFri === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfFri === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else {
                return { backgroundColor: 'gray' };
              }
            } else {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfFri === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfFri === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else if (this.state.isFreezeDateOfFri === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Sat':
          {
            let currentDate = moment(this.state.dateOfSat).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfSat === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfSat === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else {
                return { backgroundColor: 'gray' };
              }
            } else {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfSat === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfSat === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else if (this.state.isFreezeDateOfSat === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Sun':
          {
            let currentDate = moment(this.state.dateOfSun).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfSun === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfSun === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else {
                return { backgroundColor: 'gray' };
              }
            } else {
              if (objIndex && objIndex.length > 0 && objIndex[0].isFreezeDateOfSun === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else if (
                objIndex &&
                objIndex.length > 0 &&
                objIndex[0].isFreezeDateOfSun === false
              ) {
                return { borderTop: '1px solid #ffff' };
              } else if (this.state.isFreezeDateOfSun === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
      }
    };
    return (
      <td
        style={checkCellColor(
          props.dataItem.StartDate,
          props.dataItem.EndDate,
          props.dataItem[props.field].taskAllocationId,
          props.field
        )}
      >
        {data.length !== 0 &&
          data.cards.map((card, index) => (
            <Card
              cardData={card}
              key={index}
              checked={card.checked}
              checkeBoxHandler={this.checkeBoxClickHandler}
              index={index}
              approvedStatus={card.approvedStatus}
              taskId={props.dataItem.taskId}
              data={props.dataItem}
              date={props.field}
            />
          ))}
      </td>
    );
  };

  toggleTaskDetailTooltip = () => {
    if (this.isMount) {
      this.setState({
        taskdetailtooltipopen: !this.state.taskdetailtooltipopen
      });
    }
  };

  toggleRejectDialog() {
    if (this.state.status != 3) {
      if (
        this.state.selectedList.length === 0 &&
        this.getSelectedResourceList(this.state.resourceList).length === 0
      ) {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('No selected time card(s) or user(s)', 'Error');
      } else {
        this.setState({
          rejectTa: !this.state.rejectTa,
          rejectReason: null,
          isErrorMessageVisible: false
        });
      }
    } else {
      const selectedList = this.state.totalResourceTimeEntryPending.filter(i => i.selected);
      if (selectedList && selectedList.length > 0) {
        this.setState({
          rejectTa: !this.state.rejectTa,
          rejectReason: null,
          isErrorMessageVisible: false
        });
      } else {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('No selected time card(s) or user(s)', 'Error');
      }
    }
  }

  toggleUnfreezeDialog = async () => {
    await this.setState({
      unfreezePopupVisible: !this.state.unfreezePopupVisible,
      isErrorMessageVisible: false,
      unfreezeReason: '',
      isErrorMessageVisibleReason: false
    });
    if (!this.state.unfreezePopupVisible) {
      await this.setState({
        havingtwoMonthBackDates: false,
        unfreezeMessage: ''
      });
    }
  };

  toggleWindowDialog() {
    this.setState({
      windowDialogVisible: !this.state.windowDialogVisible
    });
  }

  radioViewHandler = async (status, callBack) => {
    this.setState({ status }, async () => {
      if (status === 3) {
        await this.setState({
          fromResourceDate: new Date(this.state.timeApprovalFreezeDate),
          toResourceDate: new Date()
        });
        await this.getApproverUnFreezeDaysAllPending(this.state.fromResourceDate, this.state.toResourceDate);
        await this.getPendingApprovals(this.state.fromResourceDate, this.state.toResourceDate);
      }
      if (callBack === true) {
        if (status !== 3) {
          await this.getApproverUnFreezeDays();
          await this.FreezeUI();
          this.getResourceList();
        }
      }
    });
  };

  toggleNonbillDialog = () => {
    if (this.state.status != 3) {
      if (
        this.state.selectedList.length === 0 &&
        this.getSelectedResourceList(this.state.resourceList).length === 0
      ) {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('No selected time card(s) or user(s)', 'Error');
      } else {
        this.setState({
          nonbilldialog: !this.state.nonbilldialog,
          selectedApproveNonBillableCategory: this.state.approveNonBillableCategory[0],
          isErrorMessageVisible: false
        });
      }
    } else {
      const selectedList = this.state.totalResourceTimeEntryPending.filter(i => i.selected);
      if (selectedList && selectedList.length > 0) {
        this.setState({
          nonbilldialog: !this.state.nonbilldialog,
          selectedApproveNonBillableCategory: this.state.approveNonBillableCategory[0],
          isErrorMessageVisible: false
        });
      } else {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('No selected time card(s) or user(s)', 'Error');
      }
    }
  };

  toggleMoveDialogAllPending = async () => {
    if (this.state.moveDialog === false) {
      const selectedList = this.state.totalResourceTimeEntryPending.filter(i => i.selected);
      if (selectedList && selectedList.length > 0) {
        if (selectedList.length == 1) {
          let type = selectedList[0].type;
          let projOrGroupId = selectedList[0].ProjectId
            ? selectedList[0].ProjectId
            : selectedList[0].TaskGroupId;
          let LogDateTime = selectedList[0].LogDateTime;
          let userId = selectedList[0].ResourceId;

          let unmatchTimeCards = [];
          if (type === 'NPT') {
            unmatchTimeCards = selectedList.filter(
              x =>
                x.type !== type ||
                moment(x.LogDateTime).format('YYYY-MM-DD') !==
                  moment(LogDateTime).format('YYYY-MM-DD') ||
                (x.ProjectId !== projOrGroupId && x.TaskGroupId !== projOrGroupId)
            );
          } else {
            unmatchTimeCards = selectedList.filter(
              x =>
                x.type === 'NPT' ||
                moment(x.LogDateTime).format('YYYY-MM-DD') !==
                  moment(LogDateTime).format('YYYY-MM-DD') ||
                (x.ProjectId !== projOrGroupId && x.TaskGroupId !== projOrGroupId)
            );
          }
          if (unmatchTimeCards.length == 0) {
            await this.getTaskListToMove(userId, LogDateTime);

            const taskList = this.taskListforMove;

            let filterdTask = [];
            const day = moment(LogDateTime).format('ddd');
            if (type === 'NPT') {
           
              switch (day) {
                case 'Mon':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Mon.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Tue':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Tue.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Wed':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Wed.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Thu':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Thu.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Fri':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Fri.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Sat':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Sat.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Sun':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Sun.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
              }
          } else {
              switch (day) {
                case 'Mon':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Mon.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Tue':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Tue.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Wed':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Wed.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Thu':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Thu.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Fri':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Fri.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Sat':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Sat.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Sun':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Sun.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
              }
          }
            if (filterdTask.length !== 0) {
              this.setState({
                moveDialog: !this.state.moveDialog,
                moveTaskFilter: filterdTask,
                moveTaskFilterAll: filterdTask,
                selectedMoveTaskFilter: null,
                isErrorMessageVisible: false,
                moveReason: null
              });
            } else {
              this.setState({
                showSuccessImage: false,
                showErrorImage: true
              });
              this.toggleDialog(
                'The selected time entries cannot be moved due to the unavailability of other tasks.',
                'Error'
              );
            }
          } else {
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
            this.toggleDialog(
              'You can only move time entries of one user belonging to one Project/CR or Non-Project task group within the same day',
              'Error'
            );
          }
        } else {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleDialog(
            'You can only move time entries of one user belonging to one Project/CR or Non-Project task group within the same day',
            'Error'
          );
        }
      } else {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('No selected time card(s) or user(s)', 'Error');
      }
    } else {
      this.setState({
        moveDialog: !this.state.moveDialog,
        selectedMoveTaskFilter: null,
        isErrorMessageVisible: false,
        moveReason: null
      });
    }
  };

  toggleMoveDialog = async () => {
    if (this.state.moveDialog === false) {
      let timeEntryList = Object.values(
        this.state.selectedList.reduce((acc, cur) => Object.assign(acc, { [cur.userId]: cur }), {})
      );
      if (
        this.state.selectedList.length === 0 &&
        this.getSelectedResourceList(this.state.resourceList).length === 0
      ) {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('No selected time card(s) or user(s)', 'Error');
      } else if (
        this.getSelectedResourceList(this.state.resourceList).length > 0 ||
        timeEntryList.length > 1
      ) {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog(
          'You can only move time entries of one user belonging to one Project/CR or Non-Project task group within the same day',
          'Error'
        );
      } else {
        let type = this.state.selectedList[0].type;
        let projOrGroupId = this.state.selectedList[0].projOrGroupId;
        let LogDateTime = this.state.selectedList[0].LogDateTime;
        let userId = this.state.selectedList[0].userId;

        let unmatchTimeCards = [];
        if (type === 'NPT') {
          unmatchTimeCards = this.state.selectedList.filter(
            x =>
              x.type !== type ||
              moment(x.LogDateTime).format('YYYY-MM-DD') !==
                moment(LogDateTime).format('YYYY-MM-DD') ||
              x.projOrGroupId !== projOrGroupId
          );
        } else {
          unmatchTimeCards = this.state.selectedList.filter(
            x =>
              x.type === 'NPT' ||
              moment(x.LogDateTime).format('YYYY-MM-DD') !==
                moment(LogDateTime).format('YYYY-MM-DD') ||
              x.projOrGroupId !== projOrGroupId
          );
        }

        if (unmatchTimeCards.length !== 0) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleDialog(
            'You can only move time entries of one user belonging to one Project/CR or Non-Project task group within the same day',
            'Error'
          );
        } else {
          let taskList = [];
          if (this.state.status != 1) {
            taskList = this.state.resourceList.filter(x => x.userId === userId)[0].allTasks;
          } else {
            await this.getTaskListToMove(userId, LogDateTime);
            taskList = this.taskListforMove;
          }
          const day = moment(LogDateTime).format('ddd');
          let filterdTask = [];
          if (type === 'NPT') {
           
              switch (day) {
                case 'Mon':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Mon.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Tue':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Tue.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Wed':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Wed.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Thu':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Thu.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Fri':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Fri.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Sat':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Sat.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Sun':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Sun.taskAllocationId !== null &&
                        x.taskType === 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
              }
          } else {
              switch (day) {
                case 'Mon':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Mon.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Tue':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Tue.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Wed':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Wed.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Thu':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Thu.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Fri':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Fri.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Sat':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Sat.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
                case 'Sun':
                  {
                    filterdTask = taskList.filter(
                      x =>
                        x.Sun.taskAllocationId !== null &&
                        x.taskType !== 'NPT' &&
                        x.projectOrTaskGroupId === projOrGroupId &&
                        !this.state.selectedList.some(i => i.taskId === x.taskId) &&
                        x.IsActive === true
                    );
                  }
                  break;
              }
          }

          if (filterdTask.length !== 0) {
            this.setState({
              moveDialog: !this.state.moveDialog,
              moveTaskFilter: filterdTask,
              moveTaskFilterAll: filterdTask,
              selectedMoveTaskFilter: null,
              isErrorMessageVisible: false,
              moveReason: null
            });
          } else {
            this.setState({
              showSuccessImage: false,
              showErrorImage: true
            });
            this.toggleDialog(
              'The selected time entries cannot be moved due to the unavailability of other tasks.',
              'Error'
            );
          }
        }
      }
    } else {
      this.setState({
        moveDialog: !this.state.moveDialog,
        selectedMoveTaskFilter: null,
        isErrorMessageVisible: false,
        moveReason: null
      });
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    this.setHeaderTitle();
    this.subscribe(10);
  }

  setEnvironment = () => {
    this.setState({
      loader: true
    });
    const promice = [];
    promice.push(this.getDateForProjectFilter());
    promice.push(this.getAllResourceListForFilter());
    Promise.all(promice).then(async () => {
      await this.getUserFreezeDays();
      await this.getSearchCriteria();
      this.getUserList();
    });
  };
  componentWillUnmount() {
    this.isMount = false;
    this.unsubscribe(10);
  }

  subscribe = id => {
    if (this.state.id !== 10) {
      this.setState(
        {
          id: id
        },
        () => {
          if (this.state.id === 10) {
            this.setEnvironment();
          }
        }
      );
    }
  };
  unsubscribe = id => {
    this.setState({
      id: id
    });
  };
  getDateForProjectFilter = async () => {
    let params = {
      approver:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
          ? this.state.IsMyTaskOnly !== true
            ? 'GlobleApprover'
            : 'genaralApprover'
          : 'genaralApprover'
    };
    await getDateForProjectFilter(params).then(res => {
      this.setState({
        projectFilter: res.data,
        projectFilterAll: res.data
      });
    });
  };

  getAllResourceListForFilter = async () => {
    let params = {
      approver:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
          ? this.state.IsMyTaskOnly !== true
            ? 'GlobleApprover'
            : 'genaralApprover'
          : 'genaralApprover'
    };
    await getAllResourceListForFilter(params).then(res => {
      this.setState({
        resourceListForFilter: res.data.resource,
        resourceListForFilterAll: res.data.resource,
        practiceListForFilter: res.data.practice,
        practiceListForFilterAll: res.data.practice,
        regionListForFilter: res.data.region,
        regionListForFilterAll: res.data.region
      });
    });
  };

  getDataForTaskFilter = (id, type, taskVal) => {
    let parms = {
      id: id,
      type: type,
      approver:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
          ? this.state.IsMyTaskOnly !== true
            ? 'GlobleApprover'
            : 'genaralApprover'
          : 'genaralApprover'
    };
    if (taskVal === null) {
      getDataForTaskFilter(parms).then(res => {
        this.setState({
          taskFilter: res.data,
          taskFilterAll: res.data
        });
      });
    } else {
      getDataForTaskFilter(parms).then(res => {
        this.setState(
          {
            taskFilter: res.data,
            taskFilterAll: res.data
          },
          () => {
            const task = this.state.taskFilter.filter(x => x.id === taskVal)[0];
            this.setState({
              selectedTask: task ? task : null
            });
          }
        );
      });
    }
  };

  setHeaderTitle = async () => {
    this.props.onHeaderTitleChange('Timesheet Approval');
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Timesheet Approval');
    }
  };

  headerSelectionChange = event => {
    const checked = event.syntheticEvent.target.checked;
    this.state.Workflows.forEach(item => (item.Selected = checked));
    this.forceUpdate();
  };

  handleSelect = e => {
    this.setState({ selected: e.selected });
  };

  selectionChange = event => {
    event.dataItem.Selected = !event.dataItem.Selected;
    this.forceUpdate();
  };

  rowClick = props => {
    const allData = this.state.resourceList;
    const taskId = props.dataItem.taskId;
    const newData = allData.filter(x => x.userId === props.dataItem.userId)[0].tasks;
    const userData = allData.filter(x => x.userId === props.dataItem.userId)[0]
    if (newData.filter(x => x.taskId === taskId)[0].selected === true) {
      newData
        .filter(x => x.taskId === taskId)[0]
        .Mon.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = false;
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Tue.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = false;
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Wed.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = false;
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Thu.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = false;
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Fri.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = false;
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Sat.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = false;
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Sun.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = false;
          }
        });
      newData.filter(x => x.taskId === taskId)[0].selected = false;

      this.setState(
        {
          resourceList: allData
        },
        () => {
          this.getSelectedCard(this.state.resourceList);
        }
      );
    } else {
      if (
        this.state.isFreezeDateOfMon ||
        this.state.isFreezeDateOfTue ||
        this.state.isFreezeDateOfWed ||
        this.state.isFreezeDateOfThu ||
        this.state.isFreezeDateOfFri ||
        this.state.isFreezeDateOfSat ||
        this.state.isFreezeDateOfSun
      ) {
        this.setState({
          isFreeze: true
        });
      }
      newData
        .filter(x => x.taskId === taskId)[0]
        .Mon.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = true;
            this.setState({
              isFreeze: userData.isFromUnFreezeTimeEntry
                ? userData.isFreezeDateOfMon
                : this.state.isFreezeDateOfMon
            });
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Tue.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = true;
            this.setState({
              isFreeze: userData.isFromUnFreezeTimeEntry
                ? userData.isFreezeDateOfTue
                : this.state.isFreezeDateOfTue
            });
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Wed.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = true;
            this.setState({
              isFreeze: userData.isFromUnFreezeTimeEntry
                ? userData.isFreezeDateOfWed
                : this.state.isFreezeDateOfWed
            });
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Thu.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = true;
            this.setState({
              isFreeze: userData.isFromUnFreezeTimeEntry
                ? userData.isFreezeDateOfThu
                : this.state.isFreezeDateOfThu
            });
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Fri.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = true;
            this.setState({
              isFreeze: userData.isFromUnFreezeTimeEntry
                ? userData.isFreezeDateOfFri
                : this.state.isFreezeDateOfFri
            });
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Sat.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = true;
            this.setState({
              isFreeze: userData.isFromUnFreezeTimeEntry
                ? userData.isFreezeDateOfSat
                : this.state.isFreezeDateOfSat
            });
          }
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Sun.cards.forEach(card => {
          if (
            card.approvedStatus !== approvedStatus.Auto_Generated.value &&
            card.approvedStatus !== approvedStatus.Leave.value
          ) {
            card.checked = true;
            this.setState({
              isFreeze: userData.isFromUnFreezeTimeEntry
                ? userData.isFreezeDateOfSun
                : this.state.isFreezeDateOfSun
            });
          }
        });
      newData.filter(x => x.taskId === taskId)[0].selected = true;

      this.setState(
        {
          resourceList: allData
        },
        () => {
          this.getSelectedCard(this.state.resourceList);
        }
      );
    }
  };

  rowClickAllPending = props => {
    this.state.totalResourceTimeEntryPending.find(i => i.id == props.dataItem.id).selected = !props
      .dataItem.selected;
    let selectedEntries = this.state.totalResourceTimeEntryPending.filter(
      x => x.selected === true && this.isFreezedAllPending(x.LogDateTime, x.ResourceId)
    );
    this.setState({
      totalResourceTimeEntryPending: this.state.totalResourceTimeEntryPending,
      isFreeze: selectedEntries.length !== 0 ? true : false
    });
  };

  rowClickDaily = props => {
    const allData = this.state.resourceList;
    const timeentryId = props.dataItem.Id;
    let newData = [];

    if (this.state.status === 3) {
      newData = this.state.totalResourceTimeEntryPending;
    } else {
      newData = allData.filter(x => x.userId == props.dataItem.UserId)[0].totalResourceTimeEntry;
    }

    if (newData.filter(x => x.Id === timeentryId)[0].selected === true) {
      if (
        newData.filter(x => x.Id === timeentryId)[0].ApprovedStatus !==
          approvedStatus.Auto_Generated.value &&
        newData.filter(x => x.Id === timeentryId)[0].ApprovedStatus !== approvedStatus.Leave.value
      ) {
        newData.filter(x => x.Id === timeentryId)[0].checked = false;
        newData.filter(x => x.Id === timeentryId)[0].selected = false;
      } else {
        newData.filter(x => x.Id === timeentryId)[0].checked = true;
        newData.filter(x => x.Id === timeentryId)[0].selected = false;
      }

      newData.filter(x => x.Id === timeentryId)[0].selected = false;

      this.setState(
        {
          resourceList: allData
        },
        () => {
          const selectedEntries = this.getSelectedTimeEntries(this.state.resourceList);
          if (this.state.status === 3) {
            let freezed = false;
            for (const entry of selectedEntries) {
              freezed = this.isFreezedAllPending(entry.LogDateTime, entry.ResourceId);
              if (freezed) {
                break;
              }
            }

            this.setState({ isFreeze: freezed });
          }
        }
      );
    } else {
      if (
        newData.filter(x => x.Id === timeentryId)[0].ApprovedStatus !==
          approvedStatus.Auto_Generated.value &&
        newData.filter(x => x.Id === timeentryId)[0].ApprovedStatus !== approvedStatus.Leave.value
      ) {
        newData.filter(x => x.Id === timeentryId)[0].checked = true;
        newData.filter(x => x.Id === timeentryId)[0].selected = true;
      } else {
        newData.filter(x => x.Id === timeentryId)[0].checked = false;
        newData.filter(x => x.Id === timeentryId)[0].selected = false;
      }
      this.setState(
        {
          resourceList: allData
        },
        () => {
          const selectedEntries = this.getSelectedTimeEntries(this.state.resourceList);
          if (this.state.status === 3) {
            let freezed = false;
            for (const entry of selectedEntries) {
              freezed = this.isFreezedAllPending(entry.LogDateTime, entry.ResourceId);
              if (freezed) {
                break;
              }
            }
            this.setState({ isFreeze: freezed });
          }
        }
      );
    }
  };

  nextWeek = () => {
    const date = this.state.newDate;
    const newDate = new Date();
    const nextWeek = new Date(
      newDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 7)
    );
    this.setState(
      {
        newDate: nextWeek,
        resourceList: []
      },
      () => this.setWeekDates(nextWeek)
    );
  };

  nextDay = () => {
    const date = this.state.newDateDaily;
    const newDateDaily = new Date();
    const nextDay = new Date(
      newDateDaily.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 1)
    );
    this.setState(
      {
        newDateDaily: nextDay,
        resourceList: []
      },
      () => this.setTotalsOfDates(nextDay)
    );
  };
  prevWeek = () => {
    const date = this.state.newDate;
    const newDate = new Date();
    const prevWeek = new Date(
      newDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() - 7)
    );
    this.setState(
      {
        newDate: prevWeek,
        resourceList: []
      },
      () => this.setWeekDates(prevWeek)
    );
  };

  prevDay = () => {
    const date = this.state.newDateDaily;
    const newDateDaily = new Date();
    const prevDay = new Date(
      newDateDaily.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() - 1)
    );
    this.setState(
      {
        newDateDaily: prevDay,
        resourceList: []
      },
      () => this.setTotalsOfDates(prevDay)
    );
  };

  setTotalsOfDates = async date => {
    if (this.state.status == 1) {
      if (moment(date).format('YYYY-MM-DD') <= moment('01/01/2021').format('YYYY-MM-DD')) {
        this.setState({
          disableDailyPrvBtn: false
        });
      } else {
        this.setState({
          disableDailyPrvBtn: true
        });
      }
    }
    if (date !== null) {
      await this.setState(
        {
          newDateDaily: date,
          isActiveEdit: false,
          dayTotal: 0,
          totalHourOfDay: 0,
          loader: true
        },
        async () => {
          if (this.state.status === 1) {
            await this.getApproverUnFreezeDaysWithUserId();
            await this.FreezeUI();
            this.getResourceList();
            this.updateResourceFilter();
          }
        }
      );
    }
  };

  setWeekDates = date => {
    if (date !== null) {
      this.setState({
        newDate: date,
        isActiveEdit: false,
        monDayTotal: 0,
        tueDayTotal: 0,
        wedDayTotal: 0,
        thrDayTotal: 0,
        friDayTotal: 0,
        satDayTotal: 0,
        sunDayTotal: 0,
        totalHourOfWeek: 0
      });
      this.datesHandler(date);
    }
  };

  setFromDate = async date => {
    if (date !== null) {
      await this.setState({
        fromResourceDate: date,
        isActiveEdit: false
      });

      if (this.state.toResourceDate) {
        await this.getApproverUnFreezeDaysAllPending(date, new Date(this.state.toResourceDate));
        await this.getPendingApprovals(date, this.state.toResourceDate);
      }
      //this.datesHandler(date);
    }
  };

  setToDate = async date => {
    if (date !== null) {
      await this.setState({
        toResourceDate: date,
        isActiveEdit: false
      });

      if (this.state.fromResourceDate) {
        await this.getApproverUnFreezeDaysAllPending(new Date(this.state.fromResourceDate), date);
        await this.getPendingApprovals(this.state.fromResourceDate, date);
      }
      //this.datesHandler(date);
    }
  };

  datesHandler = async date => {
    if (moment(date).day() !== 0) {
      const FirstdayOfWeek = new Date();
      const FirstMonOfWeek = new Date();
      const LastDayOfWeek = new Date();
      const firstday = new Date(
        FirstdayOfWeek.setFullYear(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - date.getDay()
        )
      );
      await this.setState(
        {
          firstDayOfWeek: new Date(
            FirstMonOfWeek.setFullYear(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() - date.getDay() + 1
            )
          ),
          LastDayOfWeek: new Date(
            LastDayOfWeek.setFullYear(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() - date.getDay() + 7
            )
          ),
          dateOfSun: new Date(moment(firstday).add(7, 'days')),
          dateOfMon: new Date(moment(firstday).add(1, 'days')),
          dateOfTue: new Date(moment(firstday).add(2, 'days')),
          dateOfWed: new Date(moment(firstday).add(3, 'days')),
          dateOfTur: new Date(moment(firstday).add(4, 'days')),
          dateOfFri: new Date(moment(firstday).add(5, 'days')),
          dateOfSat: new Date(moment(firstday).add(6, 'days'))
        },
        () => {
          if (this.state.status == 2) {
            if (
              moment(this.state.firstDayOfWeek).format('YYYY-MM-DD') <=
              moment('01/01/2021').format('YYYY-MM-DD')
            ) {
              this.setState({
                disablePrvBtn: false
              });
            } else {
              this.setState({
                disablePrvBtn: true
              });
            }
          }
          if (this.state.status != 3) {
            this.getResourceList();
            this.updateResourceFilter();
          } else {
            this.getPendingApprovals(this.state.fromResourceDate, this.state.toResourceDate);
            // this.getResourceList();
            // this.updateResourceFilter();
          }
        }
      );
    } else {
      const firstday = new Date(moment(date).subtract(6, 'days'));
      await this.setState(
        {
          firstDayOfWeek: new Date(moment(date).subtract(6, 'days')),
          LastDayOfWeek: new Date(moment(date)),
          dateOfSun: new Date(moment(firstday).add(6, 'days')),
          dateOfMon: new Date(moment(firstday)),
          dateOfTue: new Date(moment(firstday).add(1, 'days')),
          dateOfWed: new Date(moment(firstday).add(2, 'days')),
          dateOfTur: new Date(moment(firstday).add(3, 'days')),
          dateOfFri: new Date(moment(firstday).add(4, 'days')),
          dateOfSat: new Date(moment(firstday).add(5, 'days'))
        },
        () => {
          this.getResourceList();
          this.updateResourceFilter();
        }
      );
    }
    await this.FreezeUI();
  };

  roundUp = (num, precision) => {
    num = parseFloat(`0.${num}`) * 60;
    num = num.toFixed(2) / 10;
    precision = Math.pow(10, precision);
    return (Math.ceil(num * precision) / precision) * 10;
  };

  setTotalTime = time => {
    let durationParts = time.toString().split('.');
    let duration = durationParts[0];
    let durMinPart = this.roundUp(durationParts[1],1)
    .toString()
    .padStart(2, '0');
    if (typeof durationParts[1] !== 'undefined') {
      if(parseInt(durMinPart) === 60){
        duration = parseInt(duration) + 1;
        durMinPart = '00';
      }else{
        durMinPart = this.roundUp(durationParts[1],1).toString().padStart(2, '0');;
      }
      duration =
        parseInt(duration) +
        ':' +
        durMinPart;
      return <td>{duration}</td>;
    } else {
      duration = parseInt(duration) + ':' + '00';
      return <td>{duration}</td>;
    }
  };
  setTotalOfWeek = times => {
    let durationParts = times.toString().split('.');
    let duration = durationParts[0];
    let durMinPart = this.roundUp(durationParts[1],1)
    .toString()
    .padStart(2, '0');
    if (typeof durationParts[1] !== 'undefined') {
      if(parseInt(durMinPart) === 60){
        duration = parseInt(duration) + 1;
        durMinPart = '00';
      }else{
        durMinPart = this.roundUp(durationParts[1],1).toString().padStart(2, '0');;
      }
      duration =
        parseInt(duration) +
        ':' +
        durMinPart;
      return duration;
    } else {
      return (duration = parseInt(duration));
    }
  };
  projectFilterHandler = e => {
    this.setState(
      {
        selectedProjectOrCr: e.target.value,
        selectedTask: null,
        taskOpened: false
      },
      () => {
        if (e.target.value !== null) {
          this.getDataForTaskFilter(e.target.value.proId, e.target.value.type, null);
          const params = {
            firstDate: moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
            lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
            project: this.state.selectedProjectOrCr,
            task: this.state.selectedTask,
            resource: null,
            practice: null,
            region: null,
            approverId: window.LOGGED_USER.user,
            approver:
              window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
                ? this.state.IsMyTaskOnly !== true
                  ? 'GlobleApprover'
                  : 'genaralApprover'
                : 'genaralApprover'
          };
          getResourceList(params).then(res => {
            this.setState({
              resourceListForFilter: res.data,
              resourceListForFilterAll: res.data
            });
          });
        } else {
          this.setState({ selectedTask: null });
          this.getAllResourceListForFilter();
        }
      }
    );
  };

  updateResourceFilter = () => {
    const params = {
      firstDate:
        this.state.status === 1
          ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
          : this.state.status === 3
          ? moment(this.state.fromResourceDate).format('YYYY-MM-DD')
          : moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
      lastDate:
        this.state.status === 1
          ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
          : this.state.status === 3
          ? moment(this.state.toResourceDate).format('YYYY-MM-DD')
          : moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
      project: this.state.selectedProjectOrCr,
      task: this.state.selectedTask,
      resource: null,
      practice: null,
      region: null,
      approverId: window.LOGGED_USER.user,
      approver:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
          ? this.state.IsMyTaskOnly !== true
            ? 'GlobleApprover'
            : 'genaralApprover'
          : 'genaralApprover',
          type: this.state.status
    };
    getResourceList(params).then(res => {
      this.setState({
        resourceListForFilter: res.data,
        resourceListForFilterAll: res.data
      });
    });
  };

  getCalculatedTotals = (init, resourceList, timeEntry, secondPart) => {
    let params = {
      firstDate: moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
      lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
      project: this.state.confirmedProjFilterValue,
      task: this.state.confirmedTaskFilterValue,
      IsApprNotNeed: this.state.IsApprNotNeed,
      IsRejected: this.state.IsRejected,
      IsApprovalNonBillable: this.state.IsApprovalNonBillable,
      IsApprovalBillable: this.state.IsApprovalBillable,
      IsPendiingApproval: this.state.IsPendiingApproval,
      approver:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
          ? this.state.IsMyTaskOnly !== true
            ? 'GlobleApprover'
            : 'genaralApprover'
          : 'genaralApprover',
      userId: [],
      status: 'InWeekly'
    };
    let userId = [];
    timeEntry.length !== 0 &&
      timeEntry.map(x => {
        userId.push({ id: x.userId });
      });
    if (!init) {
      resourceList.length !== 0 &&
        resourceList.map(x => {
          userId.push({ id: x });
        });
    } else {
      resourceList.length !== 0 &&
        resourceList.map(x => {
          userId.push({ id: x.userId });
        });
    }
    params.userId = Object.values(
      userId.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
    );

    getCalculatedTotals(params).then(res => {
      if (
        res.data.length !== 0 &&
        this.state.status === 2 &&
        moment(this.state.firstDayOfWeek).format('YYYY-MM-DD') ===
          moment(res.data[0].firstDate).format('YYYY-MM-DD')
      ) {
        let allReso = this.state.resourceList;
        res.data.map(totals => {
          let index = allReso.findIndex(x => x.userId === totals.userId);
          if (index !== -1) {
            allReso[index].monTotal = totals.monTotal;
            allReso[index].tueTotal = totals.tueTotal;
            allReso[index].wedTotal = totals.wedTotal;
            allReso[index].thuTotal = totals.thuTotal;
            allReso[index].friTotal = totals.friTotal;
            allReso[index].satTotal = totals.satTotal;
            allReso[index].sunTotal = totals.sunTotal;
            allReso[index].dailyCalLoader = false;
            allReso[index].totalOfWeek = totals.totalOfWeek;
          }
        });
        if (secondPart.length === 0) {
          this.setState({
            resourceList: allReso
          });
        } else {
          this.setState(
            {
              resourceList: allReso
            },
            () => {
              this.state.status === 2 && this.getCalculatedTotals(true, secondPart, [], []);
            }
          );
        }
      }
    });
  };

  getCalculatedTotalsDaily = (init, resourceList, timeEntry, secondPart) => {
    let params = {
      firstDate: moment(this.state.newDateDaily).format('YYYY-MM-DD'),
      //lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
      project: this.state.confirmedProjFilterValue,
      task: this.state.confirmedTaskFilterValue,
      IsApprNotNeed: this.state.IsApprNotNeed,
      IsRejected: this.state.IsRejected,
      IsApprovalNonBillable: this.state.IsApprovalNonBillable,
      IsApprovalBillable: this.state.IsApprovalBillable,
      IsPendiingApproval: this.state.IsPendiingApproval,
      approver:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
          ? this.state.IsMyTaskOnly !== true
            ? 'GlobleApprover'
            : 'genaralApprover'
          : 'genaralApprover',
      userId: [],
      status: 'InDaily'
    };
    let userId = [];
    timeEntry.length !== 0 &&
      timeEntry.map(x => {
        userId.push({ id: x.userId });
      });
    if (!init) {
      resourceList.length !== 0 &&
        resourceList.map(x => {
          userId.push({ id: x });
        });
    } else {
      resourceList.length !== 0 &&
        resourceList.map(x => {
          userId.push({ id: x.userId });
        });
    }
    params.userId = Object.values(
      userId.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
    );
    getCalculatedTotalsDaily(JSON.parse(CircularJSON.stringify(params))).then(res => {
      if (
        res.data.length !== 0 &&
        this.state.status === 1 &&
        moment(res.data[0].firstDate).format('YYYY-MM-DD') ===
          moment(this.state.newDateDaily).format('YYYY-MM-DD')
      ) {
        let allReso = this.state.resourceList;
        res.data.map(totals => {
          let index = allReso.findIndex(x => x.userId === totals.userId);
          if (index !== -1) {
            allReso[index].dayTotal = totals.dayTotal;
            allReso[index].totalOfDay = totals.totalOfDay;
            allReso[index].dailyCalLoader = false;
          }
        });
        if (secondPart.length === 0) {
          this.setState({
            resourceList: allReso
          });
        } else {
          this.setState(
            {
              resourceList: allReso
            },
            () => {
              this.state.status === 1 && this.getCalculatedTotalsDaily(true, secondPart, [], []);
            }
          );
        }
      }
    });
  };

  getBilableCalculatedTotals = userId => {
    let params = {
      firstDate: moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
      lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
      project: this.state.confirmedProjFilterValue,
      task: this.state.confirmedTaskFilterValue,
      IsApprNotNeed: this.state.IsApprNotNeed,
      IsRejected: this.state.IsRejected,
      IsApprovalNonBillable: this.state.IsApprovalNonBillable,
      IsApprovalBillable: this.state.IsApprovalBillable,
      IsPendiingApproval: this.state.IsPendiingApproval,
      approver:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
          ? this.state.IsMyTaskOnly !== true
            ? 'GlobleApprover'
            : 'genaralApprover'
          : 'genaralApprover',
      userId: [userId],
      status: 'InWeekly'
    };
    getBilableCalculatedTotals(params).then(res => {
      let allData = this.state.resourceList;
      allData.filter(x => x.userId === userId)[0].monBillableTotal = res.data[0].monBillableTotal;
      allData.filter(x => x.userId === userId)[0].tueBillableTotal = res.data[0].tueBillableTotal;
      allData.filter(x => x.userId === userId)[0].wedBillableTotal = res.data[0].wedBillableTotal;
      allData.filter(x => x.userId === userId)[0].thuBillableTotal = res.data[0].thuBillableTotal;
      allData.filter(x => x.userId === userId)[0].friBillableTotal = res.data[0].friBillableTotal;
      allData.filter(x => x.userId === userId)[0].satBillableTotal = res.data[0].satBillableTotal;
      allData.filter(x => x.userId === userId)[0].sunBillableTotal = res.data[0].sunBillableTotal;
      this.setState(
        {
          resourceList: allData
        },
        () => {
          this.getTimeCards(userId);
        }
      );
    });
  };

  getBilableCalculatedTotalsDaily = userId => {
    let params = {
      firstDate: moment(this.state.newDateDaily).format('YYYY-MM-DD'),
      //lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
      project: this.state.confirmedProjFilterValue,
      task: this.state.confirmedTaskFilterValue,
      IsApprNotNeed: this.state.IsApprNotNeed,
      IsRejected: this.state.IsRejected,
      IsApprovalNonBillable: this.state.IsApprovalNonBillable,
      IsApprovalBillable: this.state.IsApprovalBillable,
      IsPendiingApproval: this.state.IsPendiingApproval,
      approver:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
          ? this.state.IsMyTaskOnly !== true
            ? 'GlobleApprover'
            : 'genaralApprover'
          : 'genaralApprover',
      userId: [userId],
      status: this.state.status === 1 ? 'InDaily' : 'InPending'
    };
    getBilableCalculatedTotalsDaily(params).then(res => {
      let allData = this.state.resourceList;
      allData.filter(x => x.userId === userId)[0].dayBillableTotal = res.data[0].dayBillableTotal;
      this.setState(
        {
          resourceList: allData,
          isBillableDataLoading: false
        },
        () => {
          if (this.state.status != 3) {
            this.getTimeEntries(userId);
          } else {
            this.updateGridData(this.state.totalResourceTimeEntryPending);
          }
        }
      );
    });
  };

  getPendingApprovals = (fromDate, toDate) => {
    this.setState({ loader: true, allPendingSkip: 0 });
    const data = {
      firstDate: moment(fromDate).format('YYYY-MM-DD'),
      lastDate: moment(toDate).format('YYYY-MM-DD'),
      globalApprover:
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 && !this.state.IsMyResources
          ? true
          : false,
      projCRId: this.state.selectedProjectOrCr ? this.state.selectedProjectOrCr.proId : null,
      type: this.state.selectedProjectOrCr ? this.state.selectedProjectOrCr.type : null,
      resourceId: this.state.selectedResource ? this.state.selectedResource.map(value => value.userId) : null,
      taskId: this.state.selectedTask ? this.state.selectedTask.taskId : null,
      practice: this.state.selectedPractice ? this.state.selectedPractice.map(value => value.practiceId) : null,
      region: this.state.selectedRegion ? this.state.selectedRegion.map(value => value.regionId) : null,
      IsApprNotNeed: this.state.IsApprNotNeed,
      IsRejected: this.state.IsRejected,
      IsApprovalNonBillable: this.state.IsApprovalNonBillable,
      IsApprovalBillable: this.state.IsApprovalBillable,
      IsPendiingApproval: this.state.IsPendiingApproval,
    };
    getAllPendingApprovalTimes(data).then(res => {
      if (res && res.data) {
        this.updateGridData(res.data);
      } else {
        this.setState({
          allPendingSkip: 0,
          totalResourceTimeEntryPending: [],
          loader: false,
          selectAllDaily: false
        });
      }
    });
  };

  updateGridData = data => {
    data = data ? data : [];
    const response = data.map(item => {
      const task = this.taskList.find(i => i.taskId == item.ProjectTaskId);
      item.Project = item.Project ? item.Project : item.CRName ? item.CRName : item.TaskGroupName;
      item.Task = item.Task ? item.Task : item.NPTask;
      item['IsBillable'] =
        item.BillabilityTypeId && item.BillabilityTypeId != 2 && !item.IsPresales;
      item['selected'] = false;
      item['type'] = item.NonProjectTaskId ? 'NPT' : 'PT';
      item['IsOverEightHour'] = false;
      item['Id'] = item.id;
      item['taskId'] = item.ProjectTaskId ? item.ProjectTaskId : item.NonProjectTaskId;
      item['WorkLocationName'] = item.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == item.WorkLocationId)[0].name : '';
      if (task) {
        item.type = task.taskType;
        item['allocationHours'] = task.allocationHours;
        item['IsShiftTask'] = task.IsShiftTask;
      }
      item['ApprovedDurationValue'] = this.setTimeFormat(item.ApprovedDuration);
      return item;
    });
    this.setState({
      totalResourceTimeEntryPending: response,
      loader: false,
      selectAllDaily: false
    });
  };

  setTimeFormat = time => {
    if (time) {
      let durationParts = time.toString().split('.');
      let duration = durationParts[0];
      let durMinPart = this.roundUp(durationParts[1],1)
      .toString()
      .padStart(2, '0');
      if (typeof durationParts[1] !== 'undefined') {
        if(parseInt(durMinPart) === 60){
          duration = parseInt(duration) + 1;
          durMinPart = '00';
        }else{
          durMinPart = this.roundUp(durationParts[1],1).toString().padStart(2, '0');;
        }
        duration =
          parseInt(duration) +
          ':' + durMinPart
        return duration;
      } else {
        duration = parseInt(duration) + ':' + '00';
        return duration;
      }
    }
  };

  itemChange = async event => {
    const value = event.value;
    const name = event.field;
    const dataItem = event.dataItem;
    if (name === 'ApprovedDurationValue') {
      let duration = null;
      const entryValue = event.value.split(':');
      if (entryValue) {
        if (
          Number(entryValue[0]) >= 0 &&
          (Number(entryValue[0]) < 24 ||
            (Number(entryValue[0]) === 24 && (!entryValue[1] || Number(entryValue[1]) === 0)))
        ) {
          if (this.validateDuration(value).toString().includes('error')) {
            dataItem["IsNotValidDuration"] = true;
          } else {
            dataItem["IsNotValidDuration"] = false;
          }
          duration = value;
        } else {
          duration = '0.0';
        }
      }
      dataItem[name] = duration;
      dataItem.ApprovedDuration = duration;
    } else if (name === 'ApprovedComment') {
      if(!value) {
        dataItem["IsNotValidComment"] = true;
      } else {
        dataItem["IsNotValidComment"] = false;
      }
      dataItem[name] = value;
    } else if (name === 'ApprovedTicketId') {
      dataItem[name] = value;
    }
    dataItem.PMEdited = true;
    
    this.setState({ totalResourceTimeEntryPending: [...this.state.totalResourceTimeEntryPending] });
  }

  NumericTextBoxCell = props => {
    let disableField = false;
    if (props.dataItem.NonProjectTaskId || props.dataItem.InvoiceId) {
      disableField= true;
    }
    if (props.field === 'ApprovedDurationValue') {
      return (
        <RowCellNumericTextBox
          {...props}
          onChange={this.itemChange}
          required={true}
          disabled={disableField}
        />
      );
    } else if (props.field === 'ApprovedComment') {
      return (
        <RowCellNumericTextBox
          {...props}
          onChange={this.itemChange}
          required={true}
          disabled={disableField}
        />
      );
    } else if (props.field === 'ApprovedTicketId') {
      return (
        <RowCellNumericTextBox
          {...props}
          onChange={this.itemChange}
          required={true}
          disabled={disableField}
        />
      );
    }
};

  moveTimeEntryFilter = e => {
    this.setState({
      selectedMoveTaskFilter: e.target.value
    });
  };

  taskFilterHandler = e => {
    this.setState(
      {
        selectedTask: e.target.value
      },
      () => {
        const params = {
          firstDate: moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
          lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
          project: this.state.selectedProjectOrCr,
          task: this.state.selectedTask,
          resource: null,
          practice: null,
          region: null,
          approverId: window.LOGGED_USER.user,
          approver:
            window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
              ? this.state.IsMyTaskOnly !== true
                ? 'GlobleApprover'
                : 'genaralApprover'
              : 'genaralApprover'
        };
        getResourceList(params).then(res => {
          this.setState({
            resourceListForFilter: res.data,
            resourceListForFilterAll: res.data
          });
        });
      }
    );
  };
  resourceFilterHandler = e => {
    this.setState({
      selectedResource: e.target.value
    });
  };
  practiceFilterHandler = e => {
    this.setState({
      selectedPractice: e.target.value
    });
  };
  regionFilterHandler = e => {
    this.setState({
      selectedRegion: e.target.value
    });
  };
  approveNonBillableFilterHandler = e => {
    this.setState({
      selectedApproveNonBillableCategory: e.target.value
    });
  };
  rejectReasonHandler = e => {
    this.setState({
      rejectReason: e.target.value
    });
  };
  moveReasonHandler = e => {
    this.setState({
      moveReason: e.target.value
    });
  };
  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }
  filterOnChange = event => {
    const field = event.target.name;
    switch (field) {
      case 'project':
        {
          this.setState({
            projectFilter: this.filterComboData(event.filter, this.state.projectFilterAll)
          });
        }
        break;
      case 'task':
        {
          this.setState({
            taskFilter: this.filterComboData(event.filter, this.state.taskFilterAll)
          });
        }
        break;
      case 'resource':
        {
          this.setState({
            resourceListForFilter: this.filterComboData(
              event.filter,
              this.state.resourceListForFilterAll
            )
          });
        }
        break;
      case 'practice':
        {
          this.setState({
            practiceListForFilter: this.filterComboData(
              event.filter,
              this.state.practiceListForFilterAll
            )
          });
        }
        break;
      case 'region':
        {
          this.setState({
            regionListForFilter: this.filterComboData(
              event.filter,
              this.state.regionListForFilterAll
            )
          });
        }
        break;
      default: {
        break;
      }
    }
  };
  saveSearchCriteria = () => {
    const search = {
      radioView: this.state.status,
      IsPendiingApproval: this.state.IsPendiingApproval,
      IsApprovalBillable: this.state.IsApprovalBillable,
      IsApprovalNonBillable: this.state.IsApprovalNonBillable,
      IsRejected: this.state.IsRejected,
      IsApprNotNeed: this.state.IsApprNotNeed,
      IsMyResources: this.state.IsMyResources,
      TimeNotEntered: this.state.TimeNotEntered,
      project: this.state.selectedProjectOrCr === null ? null : this.state.selectedProjectOrCr.id,
      task: this.state.selectedTask === null ? null : this.state.selectedTask.id,
      resource: this.state.selectedResource.map(value => value.userId),
      practice: this.state.selectedPractice.map(value => value.id),
      region: this.state.selectedRegion.map(value => value.id)
    };
    saveSearchCriteria(search).then(() => {
      this.setState({
        showSuccessImage: true,
        showErrorImage: false
      });
      this.toggleDialog('Default View Changed', 'Success');
    });
  };
  getSearchCriteria = async () => {
    await getSearchCriteria().then(res => {
      if (res.data.length !== 0) {
        let projVal = res.data.filter(x => x.searchCriteria === 'project')[0].searchValue;
        let taskVal = res.data.filter(x => x.searchCriteria === 'task')[0].searchValue;

        let resoValTemp = res.data.filter(x => x.searchCriteria === 'resource').searchValue !== null ? res.data.filter(x => x.searchCriteria === 'resource') : [];
        let resoVal = resoValTemp.length > 0 ? resoValTemp.map(value => parseInt(value.searchValue)): [];
       
        let regValTemp = res.data.filter(x => x.searchCriteria === 'region').searchValue !== null ? res.data.filter(x => x.searchCriteria === 'region') : [];
        let regVal = regValTemp.length > 0 ? regValTemp.map(value => value.searchValue) : [];
        
        let pracValTemp = res.data.filter(x => x.searchCriteria === 'practice').searchValue !== null ? res.data.filter(x => x.searchCriteria === 'practice') : [];
        let pracVal = pracValTemp.length > 0 ? pracValTemp.map(value => value.searchValue) : [];
        
        
        if (projVal !== null) {
          this.setState(
            {
              selectedProjectOrCr: this.state.projectFilter.filter(x => x.id === projVal)[0]
            },
            async () => {
              let parms = {
                id: this.state.projectFilter.filter(x => x.id === projVal)[0].proId,
                type: this.state.projectFilter.filter(x => x.id === projVal)[0].type,
                approver:
                  window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
                    ? this.state.IsMyTaskOnly !== true
                      ? 'GlobleApprover'
                      : 'genaralApprover'
                    : 'genaralApprover'
              };
              await getDataForTaskFilter(parms).then(res => {
                this.setState(
                  {
                    taskFilter: res.data,
                    taskFilterAll: res.data
                  },
                  () => {
                    const task = this.state.taskFilter.filter(x => x.id === taskVal)[0];
                    this.setState({
                      selectedTask: task ? task : null
                    });
                  }
                );
              });
            }
          );
        }

        const radioValue = res.data.filter(x => x.searchCriteria === 'radioView')[0].searchValue;
        let regionListForFilter = [];
        let practiceListForFilter = [];
        let resourceListForFilter = [];
        if (regVal.length > 0) {
          for(let value of regVal){
            regionListForFilter.push(this.state.regionListForFilter.find(x => value === x.id))
          }
        }
        if (pracVal.length > 0) {
          for(let value of pracVal){
            practiceListForFilter.push(this.state.practiceListForFilter.find(x => value === x.id));
          }
        }       
        if (resoVal.length > 0) {
          for(let value of resoVal){
            resourceListForFilter.push(this.state.resourceListForFilter.find(x => value === x.userId));
          }
        }
        this.setState(
          {
            status: radioValue ? parseInt(radioValue) : 1,
            IsPendiingApproval:
              res.data.filter(x => x.searchCriteria === 'IsPendiingApproval')[0].searchValue ===
              'true'
                ? true : radioValue == 3 ? true
                : false,
            IsApprovalBillable:
              res.data.filter(x => x.searchCriteria === 'IsApprovalBillable')[0].searchValue ===
              'true'
                ? true
                : false,
            IsApprovalNonBillable:
              res.data.filter(x => x.searchCriteria === 'IsApprovalNonBillable')[0].searchValue ===
              'true'
                ? true
                : false,
            IsRejected:
              res.data.filter(x => x.searchCriteria === 'IsRejected')[0].searchValue === 'true'
                ? true
                : false,
            IsApprNotNeed:
              res.data.filter(x => x.searchCriteria === 'IsApprNotNeed')[0].searchValue === 'true'
                ? true
                : false,
            IsMyResources:
              res.data.filter(x => x.searchCriteria === 'IsMyResources')[0].searchValue === 'true'
                ? true
                : false,
            TimeNotEntered:
              res.data.filter(x => x.searchCriteria === 'TimeNotEntered').length > 0 ? 
                res.data.filter(x => x.searchCriteria === 'TimeNotEntered')[0].searchValue === 'true' ? true : false
                : false,
            selectedResource: resourceListForFilter !== null ? resourceListForFilter.map(value => value) : [],
            selectedPractice: practiceListForFilter !== null ? practiceListForFilter.map(value => value) : [],
            selectedRegion: regionListForFilter !== null ? regionListForFilter.map(value => value) : []
              
          },
          () => {
            this.setWeekDates(new Date());
            this.setTotalsOfDates(new Date());
          }
        );
      } else {
        this.setWeekDates(new Date());
        this.setTotalsOfDates(new Date());
        this.radioViewHandler(1, false);
      }
    });
  };

  checkeBoxClickHandler = async (index, taskId, day, data) => {
    let allData = this.state.resourceList;
    const objIndex = allData.filter(x => x.userId === data.userId && x.isFromUnFreezeTimeEntry);
    const newData = allData.filter(x => x.userId === data.userId)[0].tasks;
    let trueVal = 0;
    let noOfCards = 0;
    let truValTask = 0;
    let noOfCardsInTask = 0;
    switch (day) {
      case 'Mon':
        {
          newData.forEach(task => {
            noOfCards = noOfCards + task.Mon.cards.length;
          });
          let chekedCard = newData.filter(x => x.taskId === taskId)[0].Mon.cards[index];
          newData.filter(x => x.taskId === taskId)[0].Mon.cards[
            index
          ].checked = !chekedCard.checked;
          let selected = false;
          newData.forEach(task => {
            task.Mon.cards.forEach(async task => {
              if (task.checked === true) {
                trueVal = trueVal + 1;
                selected = true;
                await this.setState({
                  isFreeze:
                    objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfMon
                      : this.state.isFreezeDateOfMon
                });
              } else {
                allData.filter(x => x.userId === data.userId)[0].monAllSelect = false;
                await this.setState({
                  isFreeze: selected
                    ? objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfMon
                      : this.state.isFreezeDateOfMon
                    : this.state.isFreezeDateOfMon
                });
              }
            });
          });
          if ((trueVal === noOfCards) & (trueVal !== 0)) {
            allData.filter(x => x.userId === data.userId)[0].monAllSelect = true;
          } else {
            allData.filter(x => x.userId === data.userId)[0].monAllSelect = false;
          }
        }
        break;
      case 'Tue':
        {
          newData.forEach(task => {
            noOfCards = noOfCards + task.Tue.cards.length;
          });
          let chekedCard = newData.filter(x => x.taskId === taskId)[0].Tue.cards[index];
          newData.filter(x => x.taskId === taskId)[0].Tue.cards[
            index
          ].checked = !chekedCard.checked;
          let selected = false;
          newData.forEach(task => {
            task.Tue.cards.forEach(async task => {
              if (task.checked === true) {
                trueVal = trueVal + 1;
                selected = true;
                await this.setState({
                  isFreeze:
                    objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfTue
                      : this.state.isFreezeDateOfTue
                });
              } else {
                allData.filter(x => x.userId === data.userId)[0].tueAllSelect = false;
                await this.setState({
                  isFreeze: selected
                    ? objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfTue
                      : this.state.isFreezeDateOfTue
                    : this.state.isFreezeDateOfTue
                });
              }
            });
          });
          if ((trueVal === noOfCards) & (trueVal !== 0)) {
            allData.filter(x => x.userId === data.userId)[0].tueAllSelect = true;
          } else {
            allData.filter(x => x.userId === data.userId)[0].tueAllSelect = false;
          }
        }
        break;
      case 'Wed':
        {
          newData.forEach(task => {
            noOfCards = noOfCards + task.Wed.cards.length;
          });
          let chekedCard = newData.filter(x => x.taskId === taskId)[0].Wed.cards[index];
          newData.filter(x => x.taskId === taskId)[0].Wed.cards[
            index
          ].checked = !chekedCard.checked;
          let selected = false;
          newData.forEach(task => {
            task.Wed.cards.forEach(async task => {
              if (task.checked === true) {
                trueVal = trueVal + 1;
                selected = true;
                await this.setState({
                  isFreeze:
                    objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfWed
                      : this.state.isFreezeDateOfWed
                });
              } else {
                allData.filter(x => x.userId === data.userId)[0].wedAllSelect = false;
                await this.setState({
                  isFreeze: selected
                    ? objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfWed
                      : this.state.isFreezeDateOfWed
                    : this.state.isFreezeDateOfWed
                });
              }
            });
          });
          if ((trueVal === noOfCards) & (trueVal !== 0)) {
            allData.filter(x => x.userId === data.userId)[0].wedAllSelect = true;
          } else {
            allData.filter(x => x.userId === data.userId)[0].wedAllSelect = false;
          }
        }
        break;
      case 'Thu':
        {
          newData.forEach(task => {
            noOfCards = noOfCards + task.Thu.cards.length;
          });
          let chekedCard = newData.filter(x => x.taskId === taskId)[0].Thu.cards[index];
          newData.filter(x => x.taskId === taskId)[0].Thu.cards[
            index
          ].checked = !chekedCard.checked;
          let selected = false;
          newData.forEach(task => {
            task.Thu.cards.forEach(async task => {
              if (task.checked === true) {
                trueVal = trueVal + 1;
                selected = true;
                await this.setState({
                  isFreeze:
                    objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfThu
                      : this.state.isFreezeDateOfThu
                });
              } else {
                allData.filter(x => x.userId === data.userId)[0].thuAllSelect = false;
                await this.setState({
                  isFreeze: selected
                    ? objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfThu
                      : this.state.isFreezeDateOfThu
                    : this.state.isFreezeDateOfThu
                });
              }
            });
          });
          if ((trueVal === noOfCards) & (trueVal !== 0)) {
            allData.filter(x => x.userId === data.userId)[0].thuAllSelect = true;
          } else {
            allData.filter(x => x.userId === data.userId)[0].thuAllSelect = false;
          }
        }
        break;
      case 'Fri':
        {
          newData.forEach(task => {
            noOfCards = noOfCards + task.Fri.cards.length;
          });
          let chekedCard = newData.filter(x => x.taskId === taskId)[0].Fri.cards[index];
          newData.filter(x => x.taskId === taskId)[0].Fri.cards[
            index
          ].checked = !chekedCard.checked;
          let selected = false;
          newData.forEach(task => {
            task.Fri.cards.forEach(async task => {
              if (task.checked === true) {
                trueVal = trueVal + 1;
                selected = true;
                await this.setState({
                  isFreeze:
                    objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfFri
                      : this.state.isFreezeDateOfFri
                });
              } else {
                allData.filter(x => x.userId === data.userId)[0].friAllSelect = false;
                await this.setState({
                  isFreeze: selected
                    ? objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfFri
                      : this.state.isFreezeDateOfFri
                    : this.state.isFreezeDateOfFri
                });
              }
            });
          });
          if ((trueVal === noOfCards) & (trueVal !== 0)) {
            allData.filter(x => x.userId === data.userId)[0].friAllSelect = true;
          } else {
            allData.filter(x => x.userId === data.userId)[0].friAllSelect = false;
          }
        }
        break;
      case 'Sat':
        {
          newData.forEach(task => {
            noOfCards = noOfCards + task.Sat.cards.length;
          });
          let chekedCard = newData.filter(x => x.taskId === taskId)[0].Sat.cards[index];
          newData.filter(x => x.taskId === taskId)[0].Sat.cards[
            index
          ].checked = !chekedCard.checked;
          let selected = false;
          newData.forEach(task => {
            task.Sat.cards.forEach(async task => {
              if (task.checked === true) {
                trueVal = trueVal + 1;
                selected = true;
                await this.setState({
                  isFreeze:
                    objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfSat
                      : this.state.isFreezeDateOfSat
                });
              } else {
                allData.filter(x => x.userId === data.userId)[0].satAllSelect = false;
                await this.setState({
                  isFreeze: selected
                    ? objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfSat
                      : this.state.isFreezeDateOfSat
                    : this.state.isFreezeDateOfSat
                });
              }
            });
          });
          if ((trueVal === noOfCards) & (trueVal !== 0)) {
            allData.filter(x => x.userId === data.userId)[0].satAllSelect = true;
          } else {
            allData.filter(x => x.userId === data.userId)[0].satAllSelect = false;
          }
        }
        break;
      case 'Sun':
        {
          newData.forEach(task => {
            noOfCards = noOfCards + task.Sun.cards.length;
          });
          let chekedCard = newData.filter(x => x.taskId === taskId)[0].Sun.cards[index];
          newData.filter(x => x.taskId === taskId)[0].Sun.cards[
            index
          ].checked = !chekedCard.checked;
          let selected = false;
          newData.forEach(task => {
            task.Sun.cards.forEach(async task => {
              if (task.checked === true) {
                trueVal = trueVal + 1;
                selected = true;
                await this.setState({
                  isFreeze:
                    objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfSun
                      : this.state.isFreezeDateOfSun
                });
              } else {
                allData.filter(x => x.userId === data.userId)[0].sunAllSelect = false;
                await this.setState({
                  isFreeze: selected
                    ? objIndex && objIndex.length > 0
                      ? objIndex[0].isFreezeDateOfSun
                      : this.state.isFreezeDateOfSun
                    : this.state.isFreezeDateOfSun
                });
              }
            });
          });
          if ((trueVal === noOfCards) & (trueVal !== 0)) {
            allData.filter(x => x.userId === data.userId)[0].sunAllSelect = true;
          } else {
            allData.filter(x => x.userId === data.userId)[0].sunAllSelect = false;
          }
        }
        break;
      default: {
        break;
      }
    }
    const taskData = newData.filter(x => x.taskId === taskId)[0];
    noOfCardsInTask =
      taskData.Mon.cards.length +
      taskData.Tue.cards.length +
      taskData.Wed.cards.length +
      taskData.Thu.cards.length +
      taskData.Fri.cards.length +
      taskData.Sat.cards.length +
      taskData.Sun.cards.length;
    taskData.Mon.cards.length !== 0 &&
      taskData.Mon.cards.map(card => {
        if (card.checked === true) {
          truValTask = truValTask + 1;
        }
      });
    taskData.Tue.cards.length !== 0 &&
      taskData.Tue.cards.map(card => {
        if (card.checked === true) {
          truValTask = truValTask + 1;
        }
      });
    taskData.Wed.cards.length !== 0 &&
      taskData.Wed.cards.map(card => {
        if (card.checked === true) {
          truValTask = truValTask + 1;
        }
      });
    taskData.Thu.cards.length !== 0 &&
      taskData.Thu.cards.map(card => {
        if (card.checked === true) {
          truValTask = truValTask + 1;
        }
      });
    taskData.Fri.cards.length !== 0 &&
      taskData.Fri.cards.map(card => {
        if (card.checked === true) {
          truValTask = truValTask + 1;
        }
      });
    taskData.Sat.cards.length !== 0 &&
      taskData.Sat.cards.map(card => {
        if (card.checked === true) {
          truValTask = truValTask + 1;
        }
      });
    taskData.Sun.cards.length !== 0 &&
      taskData.Sun.cards.map(card => {
        if (card.checked === true) {
          truValTask = truValTask + 1;
        }
      });
    if (noOfCardsInTask === truValTask) {
      taskData.selected = true;
    } else {
      taskData.selected = false;
    }
    const promice = this.getSelectedCard(allData);
    await Promise.all(promice);

    this.setState({
      resourceList: allData
    });
  };

  selectAllDay = (day, user) => {
    let allData = this.state.resourceList;
    let newData = allData.filter(x => x.userId === user)[0];
    switch (day) {
      case 1:
        {
          newData.monAllSelect = !newData.monAllSelect;
          if (newData.monAllSelect) {
            newData.tasks.forEach(async task => {
              task.Mon.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = true;
                }
              });
              await this.setState({
                isFreeze: newData.isFromUnFreezeTimeEntry
                  ? newData.isFreezeDateOfMon
                  : this.state.isFreezeDateOfMon
              });
            });
          } else {
            newData.tasks.forEach(async task => {
              task.Mon.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = false;
                }
              });
              await this.setState({
                isFreeze: this.state.isFreezeDateOfMon
              });
            });
          }
        }
        break;
      case 2:
        {
          newData.tueAllSelect = !newData.tueAllSelect;
          if (newData.tueAllSelect) {
            newData.tasks.forEach(async task => {
              task.Tue.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = true;
                }
              });
              await this.setState({
                isFreeze: newData.isFromUnFreezeTimeEntry
                  ? newData.isFreezeDateOfTue
                  : this.state.isFreezeDateOfTue
              });
            });
          } else {
            newData.tasks.forEach(async task => {
              task.Tue.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = false;
                }
              });
              await this.setState({
                isFreeze: this.state.isFreezeDateOfTue
              });
            });
          }
        }
        break;
      case 3:
        {
          newData.wedAllSelect = !newData.wedAllSelect;
          if (newData.wedAllSelect) {
            newData.tasks.forEach(async task => {
              task.Wed.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = true;
                }
              });
              await this.setState({
                isFreeze: newData.isFromUnFreezeTimeEntry
                  ? newData.isFreezeDateOfWed
                  : this.state.isFreezeDateOfWed
              });
            });
          } else {
            newData.tasks.forEach(async task => {
              task.Wed.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = false;
                }
              });
              await this.setState({
                isFreeze: this.state.isFreezeDateOfWed
              });
            });
          }
        }
        break;
      case 4:
        {
          newData.thuAllSelect = !newData.thuAllSelect;
          if (newData.thuAllSelect) {
            newData.tasks.forEach(async task => {
              task.Thu.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = true;
                }
              });
              await this.setState({
                isFreeze: newData.isFromUnFreezeTimeEntry
                  ? newData.isFreezeDateOfThu
                  : this.state.isFreezeDateOfThu
              });
            });
          } else {
            newData.tasks.forEach(async task => {
              task.Thu.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = false;
                }
              });
              await this.setState({
                isFreeze: this.state.isFreezeDateOfThu
              });
            });
          }
        }
        break;
      case 5:
        {
          newData.friAllSelect = !newData.friAllSelect;
          if (newData.friAllSelect) {
            newData.tasks.forEach(async task => {
              task.Fri.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = true;
                }
              });
              await this.setState({
                isFreeze: newData.isFromUnFreezeTimeEntry
                  ? newData.isFreezeDateOfFri
                  : this.state.isFreezeDateOfFri
              });
            });
          } else {
            newData.tasks.forEach(async task => {
              task.Fri.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = false;
                }
              });
              await this.setState({
                isFreeze: this.state.isFreezeDateOfFri
              });
            });
          }
        }
        break;
      case 6:
        {
          newData.satAllSelect = !newData.satAllSelect;
          if (newData.satAllSelect) {
            newData.tasks.forEach(async task => {
              task.Sat.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = true;
                }
              });
              await this.setState({
                isFreeze: newData.isFromUnFreezeTimeEntry
                  ? newData.isFreezeDateOfSat
                  : this.state.isFreezeDateOfSat
              });
            });
          } else {
            newData.tasks.forEach(async task => {
              task.Sat.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = false;
                }
              });
              await this.setState({
                isFreeze: this.state.isFreezeDateOfSat
              });
            });
          }
        }
        break;
      case 7:
        {
          newData.sunAllSelect = !newData.sunAllSelect;
          if (newData.sunAllSelect) {
            newData.tasks.forEach(async task => {
              task.Sun.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = true;
                }
              });
              await this.setState({
                isFreeze: newData.isFromUnFreezeTimeEntry
                  ? newData.isFreezeDateOfSun
                  : this.state.isFreezeDateOfSun
              });
            });
          } else {
            newData.tasks.forEach(async task => {
              task.Sun.cards.forEach(card => {
                if (
                  card.approvedStatus !== approvedStatus.Auto_Generated.value &&
                  card.approvedStatus !== approvedStatus.Leave.value
                ) {
                  card.checked = false;
                }
              });
              await this.setState({
                isFreeze: this.state.isFreezeDateOfSun
              });
            });
          }
        }
        break;
    }
    this.setState(
      {
        resourceList: allData
      },
      () => {
        this.getSelectedCard(this.state.resourceList);
      }
    );
  };

  resourceClickHandler = (userId, allSelect) => {
    let allData = this.state.resourceList;
    const newData = allData.filter(x => x.userId === userId)[0];
    !allSelect ? (newData.selected = !newData.selected) : (newData.selected = true);
    if (newData.tasks.length !== 0) {
      newData.tasks.map(task => {
        task.Mon.cards.length !== 0 &&
          task.Mon.cards.map(async card => {
            if (
              card.approvedStatus !== approvedStatus.Auto_Generated.value &&
              card.approvedStatus !== approvedStatus.Leave.value
            ) {
              card.checked = newData.selected;
            }
            await this.setState({
              isFreeze: newData.isFromUnFreezeTimeEntry
                ? newData.isFreezeDateOfMon
                : this.state.isFreezeDateOfMon
            });
          });
        task.Tue.cards.length !== 0 &&
          task.Tue.cards.map(async card => {
            if (
              card.approvedStatus !== approvedStatus.Auto_Generated.value &&
              card.approvedStatus !== approvedStatus.Leave.value
            ) {
              card.checked = newData.selected;
            }
            await this.setState({
              isFreeze: newData.isFromUnFreezeTimeEntry
                ? newData.isFreezeDateOfTue
                : this.state.isFreezeDateOfTue
            });
          });
        task.Wed.cards.length !== 0 &&
          task.Wed.cards.map(async card => {
            if (
              card.approvedStatus !== approvedStatus.Auto_Generated.value &&
              card.approvedStatus !== approvedStatus.Leave.value
            ) {
              card.checked = newData.selected;
            }
            await this.setState({
              isFreeze: newData.isFromUnFreezeTimeEntry
                ? newData.isFreezeDateOfWed
                : this.state.isFreezeDateOfWed
            });
          });
        task.Thu.cards.length !== 0 &&
          task.Thu.cards.map(async card => {
            if (
              card.approvedStatus !== approvedStatus.Auto_Generated.value &&
              card.approvedStatus !== approvedStatus.Leave.value
            ) {
              card.checked = newData.selected;
            }
            await this.setState({
              isFreeze: newData.isFromUnFreezeTimeEntry
                ? newData.isFreezeDateOfThu
                : this.state.isFreezeDateOfThu
            });
          });
        task.Fri.cards.length !== 0 &&
          task.Fri.cards.map(async card => {
            if (
              card.approvedStatus !== approvedStatus.Auto_Generated.value &&
              card.approvedStatus !== approvedStatus.Leave.value
            ) {
              card.checked = newData.selected;
            }
            await this.setState({
              isFreeze: newData.isFromUnFreezeTimeEntry
                ? newData.isFreezeDateOfFri
                : this.state.isFreezeDateOfFri
            });
          });
        task.Sat.cards.length !== 0 &&
          task.Sat.cards.map(async card => {
            if (
              card.approvedStatus !== approvedStatus.Auto_Generated.value &&
              card.approvedStatus !== approvedStatus.Leave.value
            ) {
              card.checked = newData.selected;
            }
            await this.setState({
              isFreeze: newData.isFromUnFreezeTimeEntry
                ? newData.isFreezeDateOfSat
                : this.state.isFreezeDateOfSat
            });
          });
        task.Sun.cards.length !== 0 &&
          task.Sun.cards.map(async card => {
            if (
              card.approvedStatus !== approvedStatus.Auto_Generated.value &&
              card.approvedStatus !== approvedStatus.Leave.value
            ) {
              card.checked = newData.selected;
            }
            await this.setState({
              isFreeze: newData.isFromUnFreezeTimeEntry
                ? newData.isFreezeDateOfSun
                : this.state.isFreezeDateOfSun
            });
          });
      });
      this.setState({
        selectAllWeekly: allSelect,
        resourceList: allData
      });
    } else {
      this.setState({
        selectAllWeekly: allSelect,
        resourceList: allData
      });
      if (
        this.state.isFreezeDateOfMon &&
        this.state.isFreezeDateOfTue &&
        this.state.isFreezeDateOfWed &&
        this.state.isFreezeDateOfThu &&
        this.state.isFreezeDateOfFri &&
        this.state.isFreezeDateOfSat &&
        this.state.isFreezeDateOfSun
      ) {
        this.setState({
          isFreeze: true
        });
      } else {
        this.setState({
          isFreeze: false
        });
      }
    }
    this.getSelectedCard(this.state.resourceList);
  };

  resourceClickHandlerDaily = (userId, allSelect) => {
    let allData = this.state.resourceList;
    const newData = allData.filter(x => x.userId === userId)[0];
    !allSelect ? (newData.selected = !newData.selected) : (newData.selected = true);

    if (this.state.status === 1) {
      if (newData.totalResourceTimeEntry && newData.totalResourceTimeEntry.length !== 0) {
        newData.totalResourceTimeEntry.map(entry => {
          if (
            entry.ApprovedStatus !== approvedStatus.Auto_Generated.value &&
            entry.ApprovedStatus !== approvedStatus.Leave.value
          ) {
            entry.checked = newData.selected;
            entry.selected = newData.selected;
          }
          this.setState({
            isFreeze: newData.isFromUnFreezeTimeEntry
              ? newData.isFreezeSelectedDateDaily
              : this.state.isFreeze
          });
        });

        this.setState({
          resourceList: allData,
          selectAllDaily: allSelect
        });
      } else {
        this.setState({
          resourceList: allData,
          selectAllDaily: allSelect
        });
      }
    } else if (this.state.status === 3) {
      if (
        newData.totalResourceTimeEntryPendingAll &&
        newData.totalResourceTimeEntryPendingAll.length !== 0
      ) {
        newData.totalResourceTimeEntryPendingAll.map(entry => {
          if (
            entry.ApprovedStatus !== approvedStatus.Auto_Generated.value &&
            entry.ApprovedStatus !== approvedStatus.Leave.value &&
            !this.isFreezedAllPending(entry.LogDateTime, entry.ResourceId)
          ) {
            entry.checked = newData.selected;
            entry.selected = newData.selected;
          }
        });

        this.setState({
          resourceList: allData,
          selectAllDaily: allSelect
        });
      } else {
        this.setState({
          resourceList: allData,
          selectAllDaily: allSelect
        });
      }
    }
    this.getSelectedTimeEntries(this.state.resourceList);
  };

  selectAllHandler = selectAll => {
    this.state.resourceList.map(x => {
      this.resourceClickHandler(x.userId, selectAll);
    });
    this.getSelectedCard(this.state.resourceList);
  };

  selectAllHandlerDaily = async selectAll => {
    this.state.resourceList.map(x => {
      this.resourceClickHandlerDaily(x.userId, selectAll);
    });
    this.getSelectedTimeEntries(this.state.resourceList);
  };

  selectAllHandlerAllPending = selectAll => {
    let isFreeze = false;
    const totalResourceTimeEntryPending = this.state.totalResourceTimeEntryPending.map(i => {
      if (selectAll) {
        if (!this.isFreezedAllPending(i.LogDateTime, i.ResourceId)) {
          i.selected = selectAll;
          isFreeze = false;
        }
        return i;
      } else {
        i.selected = false;
        return i;
      }
    });
    this.setState({
      isFreeze: isFreeze,
      selectAllDaily: selectAll,
      totalResourceTimeEntryPending: totalResourceTimeEntryPending
    });
  };

  getSelectedCard = allResou => {
    let selectedList = [];
    allResou.length !== 0 &&
      allResou.map(data => {
        data.tasks.length !== 0 &&
          data.tasks.forEach(task => {
            task.Mon.cards.length !== 0 &&
              task.Mon.cards.forEach(card => {
                if (card.checked === true) {
                  selectedList.push({
                    card: card.cardId,
                    approvedStatus: card.approvedStatus,
                    type: task.taskType,
                    projOrGroupId: card.projOrGroupId,
                    CrId: card.CrId,
                    LogDateTime: card.LogDateTime,
                    userId: card.userId,
                    duration: card.duration,
                    project: card.project,
                    task: card.task,
                    taskId: card.taskId,
                    IsBillable: card.IsBillable
                  });
                }
              });
            task.Tue.cards.length !== 0 &&
              task.Tue.cards.forEach(card => {
                if (card.checked === true) {
                  selectedList.push({
                    card: card.cardId,
                    approvedStatus: card.approvedStatus,
                    type: task.taskType,
                    projOrGroupId: card.projOrGroupId,
                    CrId: card.CrId,
                    LogDateTime: card.LogDateTime,
                    userId: card.userId,
                    duration: card.duration,
                    project: card.project,
                    task: card.task,
                    taskId: card.taskId,
                    IsBillable: card.IsBillable
                  });
                }
              });
            task.Wed.cards.length !== 0 &&
              task.Wed.cards.forEach(card => {
                if (card.checked === true) {
                  selectedList.push({
                    card: card.cardId,
                    approvedStatus: card.approvedStatus,
                    type: task.taskType,
                    projOrGroupId: card.projOrGroupId,
                    CrId: card.CrId,
                    LogDateTime: card.LogDateTime,
                    userId: card.userId,
                    duration: card.duration,
                    project: card.project,
                    task: card.task,
                    taskId: card.taskId,
                    IsBillable: card.IsBillable
                  });
                }
              });
            task.Thu.cards.length !== 0 &&
              task.Thu.cards.forEach(card => {
                if (card.checked === true) {
                  selectedList.push({
                    card: card.cardId,
                    approvedStatus: card.approvedStatus,
                    type: task.taskType,
                    projOrGroupId: card.projOrGroupId,
                    CrId: card.CrId,
                    LogDateTime: card.LogDateTime,
                    userId: card.userId,
                    duration: card.duration,
                    project: card.project,
                    task: card.task,
                    taskId: card.taskId,
                    IsBillable: card.IsBillable
                  });
                }
              });
            task.Fri.cards.length !== 0 &&
              task.Fri.cards.forEach(card => {
                if (card.checked === true) {
                  selectedList.push({
                    card: card.cardId,
                    approvedStatus: card.approvedStatus,
                    type: task.taskType,
                    projOrGroupId: card.projOrGroupId,
                    CrId: card.CrId,
                    LogDateTime: card.LogDateTime,
                    userId: card.userId,
                    duration: card.duration,
                    project: card.project,
                    task: card.task,
                    taskId: card.taskId,
                    IsBillable: card.IsBillable
                  });
                }
              });
            task.Sat.cards.length !== 0 &&
              task.Sat.cards.forEach(card => {
                if (card.checked === true) {
                  selectedList.push({
                    card: card.cardId,
                    approvedStatus: card.approvedStatus,
                    type: task.taskType,
                    projOrGroupId: card.projOrGroupId,
                    CrId: card.CrId,
                    LogDateTime: card.LogDateTime,
                    userId: card.userId,
                    duration: card.duration,
                    project: card.project,
                    task: card.task,
                    taskId: card.taskId,
                    IsBillable: card.IsBillable
                  });
                }
              });
            task.Sun.cards.length !== 0 &&
              task.Sun.cards.forEach(card => {
                if (card.checked === true) {
                  selectedList.push({
                    card: card.cardId,
                    approvedStatus: card.approvedStatus,
                    type: task.taskType,
                    projOrGroupId: card.projOrGroupId,
                    CrId: card.CrId,
                    LogDateTime: card.LogDateTime,
                    userId: card.userId,
                    duration: card.duration,
                    project: card.project,
                    task: card.task,
                    taskId: card.taskId,
                    IsBillable: card.IsBillable
                  });
                }
              });
          });
      });
    this.setState({
      selectedList: selectedList
    });
    return selectedList;
  };

  getSelectedTimeEntries = allResou => {
    let selectedList = [];

    if (this.state.status === 3) {
      allResou.length !== 0 &&
        allResou.map(data => {
          data.totalResourceTimeEntryPendingAll &&
            data.totalResourceTimeEntryPendingAll.length !== 0 &&
            data.totalResourceTimeEntryPendingAll.forEach(entry => {
              if (entry.checked === true) {
                selectedList.push({
                  card: entry.Id,
                  approvedStatus: entry.ApprovedStatus,
                  type: entry.TaskType,
                  projOrGroupId: entry.ProjectOrTaskGroupId,
                  CrId: entry.CrId,
                  LogDateTime: entry.LogDateTime,
                  userId: entry.UserId,
                  duration: entry.ApprovedDuration ? entry.ApprovedDuration : entry.Duration,
                  project: entry.Project,
                  task: entry.Task,
                  taskId: entry.TaskId,
                  IsBillable: entry.IsBillable
                });
              }
            });
        });
    } else {
      allResou.length !== 0 &&
        allResou.map(data => {
          data.totalResourceTimeEntry &&
            data.totalResourceTimeEntry.length !== 0 &&
            data.totalResourceTimeEntry.forEach(entry => {
              if (entry.checked === true) {
                selectedList.push({
                  card: entry.Id,
                  approvedStatus: entry.ApprovedStatus,
                  type: entry.TaskType,
                  projOrGroupId: entry.ProjectOrTaskGroupId,
                  CrId: entry.CrId,
                  LogDateTime: entry.LogDateTime,
                  userId: entry.UserId,
                  duration: entry.ApprovedDuration ? entry.ApprovedDuration : entry.Duration,
                  project: entry.Project,
                  task: entry.Task,
                  taskId: entry.TaskId,
                  IsBillable: entry.IsBillable
                });
              }
            });
        });
    }

    this.setState({
      selectedList: selectedList
    });
    return selectedList;
  };

  getSelectedResourceList = allResou => {
    let selectedList = [];
    allResou.length !== 0 &&
      allResou.map(resource => {
        if (resource.selected === true) {
          selectedList.push(resource.userId);
        }
      });
    return selectedList;
  };

  toggleDialog = (message, title) => {
    if (!this.state.visible) {
      this.setState({
        loading: false
      });
    }
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };
  notificationAlert = msg => {
    this.setState(
      {
        successAlert: true,
        successMsg: msg
      },
      () => {
        setTimeout(() => {
          this.setState({
            successAlert: false,
            successMsg: ''
          });
        }, 4500);
      }
    );
  };

  validation = entry => {
    if (
      this.validateDurationNullOrNot(entry.ApprovedDurationValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(entry.ApprovedComment)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateDuration(entry.ApprovedDurationValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validateDurationNullOrNot = value => {
    if (
      value !== null &&
      value !== '' 
    ) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validateDuration = (approvedDuration) => {
    let timeRegExWithColon = /^(0?[0-9]|[0-9][0-9]):[0-5][0-9]$/;
    let timeRegExWithOutColon = /^\d{1,2}(\.\d{1,2})?$/;
    if (
      approvedDuration === null ||
      approvedDuration === '' ||
      timeRegExWithColon.test(approvedDuration)
    ) {
      return 'd-none';
    } else if (
      approvedDuration === null ||
      approvedDuration === '' ||
      timeRegExWithOutColon.test(approvedDuration)
    ) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  approveBillableHandlerAllPending = async() => {
    await this.setState({
      isClickedBillable: true
    });
    const selectedList = this.state.totalResourceTimeEntryPending.filter(i => i.selected);
    if (selectedList && selectedList.length > 0) {
      if (!selectedList.find(i => !i.IsBillable)) {
        let cardList = [];
        for (const entry of selectedList) {
          if (!this.validation(entry)) {
            this.setState({
              showSuccessImage: false,
              showErrorImage: true,
              isDisableSave: true,
              loading: false,
              isClickedBillable: false
            });
            this.toggleDialog('Please fix the highlighted errors to proceed', 'Error');
            return;
          } 
          let timeRegExWithColon = /^(0?[0-9]|[0-9][0-9]):[0-5][0-9]$/;
          let timeRegExWithOutColon = /^\d{1,2}(\.\d{1,2})?$/;
          let duration = null;
          if (timeRegExWithColon.test(entry.ApprovedDurationValue)) {
            let times = entry.ApprovedDurationValue.split(':');
            duration = parseFloat(parseFloat(times[0]) + parseFloat(times[1] / 60)).toFixed(5);
          } else if (timeRegExWithOutColon.test(entry.ApprovedDurationValue)) {
            duration = entry.ApprovedDurationValue;
          }
          const card = {
            card: entry.id, 
            userId: entry.ResourceId, 
            ApprovedDuration: duration, 
            ApprovedComment: entry.ApprovedComment, 
            ApprovedTicketId: entry.ApprovedTicketId, 
            PMEdited: entry.PMEdited
          };
          cardList.push(card);
        }
        let parms = {
          cards: cardList,
          // selectedList.map(i => {
          //   return { card: i.id, userId: i.ResourceId, ApprovedDuration: i.ApprovedDuration, ApprovedComment: i.ApprovedComment, ApprovedTicketId: i.ApprovedTicketId, PMEdited: i.PMEdited};
          // }),
          approver: window.LOGGED_USER.displayName,
          LastApprovedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          approverType:
            window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
              ? this.state.IsMyTaskOnly !== true
                ? 'GlobleApprover'
                : 'genaralApprover'
              : 'genaralApprover'
        };

        await approveBillableAllPending(parms)
          .then(res => {
            this.getPendingApprovals(this.state.fromResourceDate, this.state.toResourceDate);
            this.notificationAlert('Successfully approved');
          })
          .catch(error => {
            this.setState({
              showSuccessImage: false,
              showErrorImage: true,
              isDisableSave: true,
              loading: false
            });
            this.toggleDialog(error.response.data, 'Error');
          });
      } else {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          isDisableSave: true,
          loading: false
        });
        this.toggleDialog('Non-Billable tasks cannot be approved as Billable', 'Error');
      }
    } else {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('No selected time card(s) or user(s)', 'Error');
    }
    await this.setState({
      isClickedBillable: false
    });
  };

  approveBillableHandler = async() => {
    await this.setState({
      isClickedBillable: true
    });
    if (
      this.state.selectedList.length === 0 &&
      this.getSelectedResourceList(this.state.resourceList).length === 0
    ) {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('No selected time card(s) or user(s)', 'Error');
    } else {
      let timeEntryList = this.state.selectedList.filter(
        x => x.type === 'NPT' || x.IsBillable === false
      );
      let futureCards = this.state.selectedList.filter(
        x => moment(x.LogDateTime).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')
      );
      if (futureCards.length !== 0) {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('Approval of time entries for future dates is not permitted.', 'Error');
      } else {
        if (timeEntryList.length === 0) {
          let resouceList = this.getSelectedResourceList(this.state.resourceList);
          let parms = {
            cards: this.state.selectedList,
            selectedResource: resouceList,
            approver: window.LOGGED_USER.displayName,
            IsApprNotNeed: this.state.ConfirmeIsApprNotNeed,
            IsRejected: this.state.ConfirmeIsRejected,
            IsApprovalNonBillable: this.state.ConfirmeIsApprovalNonBillable,
            IsApprovalBillable: this.state.ConfirmeIsApprovalBillable,
            IsPendiingApproval: this.state.ConfirmeIsPendiingApproval,
            LastApprovedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            firstDate:
              this.state.status === 1
                ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
                : moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
            lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
            project: this.state.confirmedProjFilterValue,
            task: this.state.confirmedTaskFilterValue,
            approverType:
              window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
                ? this.state.IsMyTaskOnly !== true
                  ? 'GlobleApprover'
                  : 'genaralApprover'
                : 'genaralApprover',
            status:
              this.state.status === 1
                ? 'InDaily'
                : this.state.status === 3
                ? 'InPending'
                : 'InWeekly'
          };

          await approveBillable(parms)
            .then(res => {
              this.getTaskOnlyUpdatedUser();
              if (this.state.status === 1) {
                this.getCalculatedTotalsDaily(false, resouceList, this.state.selectedList, []);
              } else if (this.state.status === 2) {
                this.getCalculatedTotals(false, resouceList, this.state.selectedList, []);
                this.deSelectCheckBox();
              }
              this.notificationAlert('Successfully approved');
            })
            .catch(error => {
              this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                isDisableSave: true,
                loading: false
              });
              this.toggleDialog(error.response.data, 'Error');
            });
        } else {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true,
            isDisableSave: true,
            loading: false
          });
          this.toggleDialog('Non-Billable tasks cannot be approved as Billable', 'Error');
        }
      }
    }
    await this.setState({
      isClickedBillable: false
    });
  };

  unApproveHandler = () => {
    if (
      this.state.selectedList.length === 0 &&
      this.getSelectedResourceList(this.state.resourceList).length === 0
    ) {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('No selected time card(s) or user(s)', 'Error');
    } else {

      let approvedOrRejectedList = this.state.selectedList.filter(x=> x.approvedStatus != 1 && x.approvedStatus != 4 && x.approvedStatus != 10);
      if(approvedOrRejectedList && approvedOrRejectedList.length > 0){
        let resouceList = this.getSelectedResourceList(this.state.resourceList);
        let parms = {
          cards: this.state.selectedList,
          approver: window.LOGGED_USER.displayName
        };
  
        unApprove(parms)
          .then(res => {
            this.getTaskOnlyUpdatedUser();
            if (this.state.status === 1) {
              this.getCalculatedTotalsDaily(false, resouceList, this.state.selectedList, []);
            } else if (this.state.status === 2) {
              this.getCalculatedTotals(false, resouceList, this.state.selectedList, []);
              this.deSelectCheckBox();
            }
            this.notificationAlert('Successfully unapproved');
          })
          .catch(error => {
            this.setState({
              showSuccessImage: false,
              showErrorImage: true,
              isDisableSave: true,
              loading: false
            });
            this.toggleDialog(error.response.data, 'Error');
          });
      }else{
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('Only Rejected or Approved time entries are allowed to be Unapproved', 'Error');
      }
    }
  };

  approveNonBillableHandler = async () => {
    await this.setState({
      isClickedNonBillable: true
    });
    if (
      this.validateProperty(this.state.selectedApproveNonBillableCategory)
        .toString()
        .includes('error')
    ) {
      this.setState({
        isErrorMessageVisible: true
      });
    } else {
      if (this.state.status != 3) {
        let resouceList = this.getSelectedResourceList(this.state.resourceList);
        let timeEntryList = this.state.selectedList.filter(
          x => x.approvedStatus !== approvedStatus.Auto_Generated.value
        );
        let parms = {
          cards: timeEntryList,
          selectedResource: resouceList,
          approver: window.LOGGED_USER.displayName,
          IsApprNotNeed: this.state.ConfirmeIsApprNotNeed,
          IsRejected: this.state.ConfirmeIsRejected,
          IsApprovalNonBillable: this.state.ConfirmeIsApprovalNonBillable,
          IsApprovalBillable: this.state.ConfirmeIsApprovalBillable,
          IsPendiingApproval: this.state.ConfirmeIsPendiingApproval,
          firstDate:
            this.state.status === 1
              ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
              : moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
          lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
          project: this.state.confirmedProjFilterValue,
          task: this.state.confirmedTaskFilterValue,
          LastApprovedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          approverType:
            window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
              ? this.state.IsMyTaskOnly !== true
                ? 'GlobleApprover'
                : 'genaralApprover'
              : 'genaralApprover',
          selectedApproveNonBillableCategory: this.state.selectedApproveNonBillableCategory,
          status:
            this.state.status === 1 ? 'InDaily' : this.state.status === 3 ? 'InPending' : 'InWeekly'
        };
        let futureCards = this.state.selectedList.filter(
          x => moment(x.LogDateTime).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')
        );
        let value = await checkFutureCardsExist(parms);
        if (futureCards.length !== 0 || value.data) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleDialog('Approval of time entries for future dates is not permitted.', 'Error');
        } else {
          await approveNonBillable(parms).then(res => {
            this.getTaskOnlyUpdatedUser();
            this.toggleNonbillDialog();
            this.deSelectCheckBox();
            if (this.state.status === 1) {
              this.getCalculatedTotalsDaily(false, resouceList, timeEntryList, []);
            } else if (this.state.status === 2) {
              this.getCalculatedTotals(false, resouceList, timeEntryList, []);
            }
            this.notificationAlert('Successfully approved');
          });
        }
      } else {
        const selectedList = this.state.totalResourceTimeEntryPending.filter(i => i.selected);
        let cardList = [];
        for (const entry of selectedList) {
          if (!this.validation(entry)) {
            this.setState({
              showSuccessImage: false,
              showErrorImage: true,
              nonbilldialog: false,
              isClickedNonBillable: false,
              loading: false
            });
            this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
            return;
          } 
          let timeRegExWithColon = /^(0?[0-9]|[0-9][0-9]):[0-5][0-9]$/;
          let timeRegExWithOutColon = /^\d{1,2}(\.\d{1,2})?$/;
          let duration = null;
          if (timeRegExWithColon.test(entry.ApprovedDurationValue)) {
            let times = entry.ApprovedDurationValue.split(':');
            duration = parseFloat(parseFloat(times[0]) + parseFloat(times[1] / 60)).toFixed(5);
          } else if (timeRegExWithOutColon.test(entry.ApprovedDurationValue)) {
            duration = entry.ApprovedDurationValue;
          }
          const card = {
            card: entry.id, 
            userId: entry.ResourceId, 
            ApprovedDuration: duration, 
            ApprovedComment: entry.ApprovedComment, 
            ApprovedTicketId: entry.ApprovedTicketId, 
            PMEdited: entry.PMEdited
          };
          cardList.push(card);
        }
        let parms = {
          cards: cardList,
          // selectedList.map(i => {
          //   return { card: i.id, userId: i.ResourceId, ApprovedDuration: i.ApprovedDuration, ApprovedComment: i.ApprovedComment, ApprovedTicketId: i.ApprovedTicketId, PMEdited: i.PMEdited};
          // }),
          approver: window.LOGGED_USER.displayName,
          LastApprovedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          approverType:
            window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
              ? this.state.IsMyTaskOnly !== true
                ? 'GlobleApprover'
                : 'genaralApprover'
              : 'genaralApprover',
          selectedApproveNonBillableCategory: this.state.selectedApproveNonBillableCategory,
        };
        await approveNonBillableAllPending(parms).then(res => {
          this.toggleNonbillDialog();
          this.getPendingApprovals(this.state.fromResourceDate, this.state.toResourceDate);
          this.notificationAlert('Successfully approved');
        });
      }
    }
    await this.setState({
      isClickedNonBillable: false
    });
  };

  moveTimeEntry = () => {
    if (
      this.validateProperty(this.state.selectedMoveTaskFilter)
        .toString()
        .includes('error') ||
      this.validateProperty(this.state.moveReason)
        .toString()
        .includes('error')
    ) {
      this.setState({
        isErrorMessageVisible: true
      });
    } else {
      if (this.state.status != 3) {
        let resouceList = this.getSelectedResourceList(this.state.resourceList);
        let timeEntryList = this.state.selectedList.filter(
          x => x.approvedStatus !== approvedStatus.Auto_Generated.value
        );
        let parms = {
          cards: this.state.selectedList,
          moveReason: this.state.moveReason,
          selectedTask: this.state.selectedMoveTaskFilter,
          approver: window.LOGGED_USER.displayName,
          LastApprovedTime: moment().format('YYYY-MM-DD HH:mm:ss')
        };
        moveTimeEntry(parms).then(res => {
          if (this.state.status === 1) {
            this.getCalculatedTotalsDaily(false, resouceList, timeEntryList, []);
          } else if (this.state.status === 2) {
            this.getCalculatedTotals(false, resouceList, timeEntryList, []);
          }
          this.setState({
            selectedMoveTaskFilter: null,
            isErrorMessageVisible: false,
            moveReason: null,
            moveTaskFilter: [],
            moveTaskFilterAll: [],
            selectedList: []
          });
          this.getTaskOnlyUpdatedUser();
          this.toggleMoveDialog();
          this.deSelectCheckBox();
          this.notificationAlert('Successfully moved');
        });
      } else {
        const selectedList = this.state.totalResourceTimeEntryPending.filter(i => i.selected);
        let parms = {
          cards: selectedList.map(i => {
            return { card: i.id, userId: i.ResourceId ,LogDateTime:i.LogDateTime, project: i.Project, task: i.Task, duration:i.Duration };
          }),
          moveReason: this.state.moveReason,
          selectedTask: this.state.selectedMoveTaskFilter,
          approver: window.LOGGED_USER.displayName,
          LastApprovedTime: moment().format('YYYY-MM-DD HH:mm:ss')
        };
        moveTimeEntry(parms).then(res => {
          this.getPendingApprovals(this.state.fromResourceDate, this.state.toResourceDate);
          this.setState({
            selectedMoveTaskFilter: null,
            isErrorMessageVisible: false,
            moveReason: null,
            moveTaskFilter: [],
            moveTaskFilterAll: [],
            selectedList: []
          });
          this.toggleMoveDialogAllPending();
          this.notificationAlert('Successfully moved');
        });
      }
    }
  };

  rejectTimeEntry = async () => {
    if (
      this.validateProperty(this.state.rejectReason)
        .toString()
        .includes('error')
    ) {
      this.setState({
        isErrorMessageVisible: true
      });
    } else {
      if (this.state.status != 3) {
        let resouceList = this.getSelectedResourceList(this.state.resourceList);
        let timeEntryList = this.state.selectedList.filter(
          x => x.approvedStatus !== approvedStatus.Auto_Generated.value
        );
        let parms = {
          cards: timeEntryList,
          selectedResource: resouceList,
          approver: window.LOGGED_USER.displayName,
          IsApprNotNeed: this.state.ConfirmeIsApprNotNeed,
          IsRejected: this.state.ConfirmeIsRejected,
          IsApprovalNonBillable: this.state.ConfirmeIsApprovalNonBillable,
          IsApprovalBillable: this.state.ConfirmeIsApprovalBillable,
          IsPendiingApproval: this.state.ConfirmeIsPendiingApproval,
          firstDate:
            this.state.status === 1
              ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
              : moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
          lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
          project: this.state.confirmedProjFilterValue,
          task: this.state.confirmedTaskFilterValue,
          approverType:
            window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
              ? this.state.IsMyTaskOnly !== true
                ? 'GlobleApprover'
                : 'genaralApprover'
              : 'genaralApprover',
          rejectReason: this.state.rejectReason,
          status:
            this.state.status === 1 ? 'InDaily' : this.state.status === 3 ? 'InPending' : 'InWeekly'
        };

        let futureCards = this.state.selectedList.filter(
          x => moment(x.LogDateTime).format('YYYY-MM-DD') > moment(new Date()).format('YYYY-MM-DD')
        );
        let value = await checkFutureCardsExist(parms);
        if (futureCards.length !== 0 || value.data) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleDialog(
            'Rejection of time entries for future dates is not permitted.',
            'Error'
          );
        } else {
          rejectTimeEntry(parms).then(res => {
            this.getTaskOnlyUpdatedUser();
            this.toggleRejectDialog();
            this.deSelectCheckBox();
            if (this.state.status === 1) {
              this.getCalculatedTotalsDaily(false, resouceList, timeEntryList, []);
            } else if (this.state.status === 2) {
              this.getCalculatedTotals(false, resouceList, timeEntryList, []);
            }
            this.setState({
              rejectReason: null,
              isErrorMessageVisible: false,
              selectedList: []
            });
            this.notificationAlert('Successfully rejected');
          });
        }
      } else {
        const selectedList = this.state.totalResourceTimeEntryPending.filter(i => i.selected);
        let parms = {
          cards: selectedList.map(i => {
            return { card: i.id, userId: i.ResourceId, LogDateTime:i.LogDateTime, project: i.Project, task: i.Task, duration:i.Duration };
          }),
          selectedResource: selectedList.map(i => {
            return i.ResourceId;
          }),
          approver: window.LOGGED_USER.displayName,
          IsApprNotNeed: this.state.ConfirmeIsApprNotNeed,
          IsRejected: this.state.ConfirmeIsRejected,
          IsApprovalNonBillable: this.state.ConfirmeIsApprovalNonBillable,
          IsApprovalBillable: this.state.ConfirmeIsApprovalBillable,
          IsPendiingApproval: this.state.ConfirmeIsPendiingApproval,
          firstDate:
            this.state.status === 1
              ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
              : moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
          lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
          project: this.state.confirmedProjFilterValue,
          task: this.state.confirmedTaskFilterValue,
          approverType:
            window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1
              ? this.state.IsMyTaskOnly !== true
                ? 'GlobleApprover'
                : 'genaralApprover'
              : 'genaralApprover',
          rejectReason: this.state.rejectReason,
          status: 'InPending'
        };

        rejectTimeEntry(parms).then(res => {
          this.toggleRejectDialog();
          this.getPendingApprovals(this.state.fromResourceDate, this.state.toResourceDate);
          this.setState({
            rejectReason: null,
            isErrorMessageVisible: false,
            selectedList: []
          });
          this.notificationAlert('Successfully rejected');
        });
      }
    }
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  submitSearch = () => {
    this.getPendingApprovals(this.state.fromResourceDate, this.state.toResourceDate);
  };

  expandAllPanels = () => {
    this.setState({
      allPanelExapnded: false
    });
  };

  SubmitUnfreezeRequestSuccess = async () => {
    if (
      this.validateProperty(this.state.unfreezeReason)
        .toString()
        .includes('error')
    ) {
      this.setState({
        isErrorMessageVisibleReason: true
      });
    } else {
      await this.setState({
        loading: true
      });
      await selectTimeSheetUnfreezeRequestWorkflow().then(res => {
        if (res.data.length > 1) {
          this.setState(
            {
              matchingWorkflowList: res.data,
              loading: false
            },
            () => {
              this.toggleWorkflowSelectDialog();
            }
          );
        } else if (res.data.length === 1) {
          this.setState(
            {
              selectedWorkFlowId: res.data[0].id,
              loading: false
            },
            () => {
              this.setWorkflowToResources();
            }
          );
        } else {
          const message =
            'This unfreeze request has failed to find a appropriate workflow for approvals. Please contact your system administrator to correct this issue.';
          const title = 'Error';
          this.setState({
            loading: false,
            showSuccessImage: false,
            showErrorImage: true,
            isErrorMessageVisible: false
          });
          this.toggleMessageDialog(message, title);
        }
      });
    }
  };

  SubmitUnfreezeRequest = async () => {
    let selectedResources = [];
    //GlobalApprover
    if (
      window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
      this.state.IsMyTaskOnly !== true
    ) {
      selectedResources = this.state.resourceList.filter(a => a.selected === true);
      if (selectedResources && selectedResources.length > 0) {
        await this.getApproverUnFreezeDaysWithUserId();
        if (
          this.state.timeApprovalUnFreezeDatesUserId &&
          this.state.timeApprovalUnFreezeDatesUserId.length > 0
        ) {
          let alreadySendUnFreezeRequestResourceList = [];
          let notAlreadySendUnFreezeRequestResourceList = [];
          selectedResources.map((b, i) => {
            let timeApprovalUnFreezeDatesUser = [];
            timeApprovalUnFreezeDatesUser = this.state.timeApprovalUnFreezeDatesUserId.filter(
              a => a.UserId === b.userId
            );
            if (timeApprovalUnFreezeDatesUser && timeApprovalUnFreezeDatesUser.length > 0) {
              let freezeDailySelectedDate = timeApprovalUnFreezeDatesUser[0].UnfreezeTasksApproverDate.filter(
                a =>
                  (a.IsTimeApprovalFreeze === true &&
                    a.TAUnFreezeRequestStatus === UnFreezeStatus.Pending_Approval.value) ||
                  (a.IsTimeApprovalFreeze === false &&
                    a.TAUnFreezeRequestStatus === UnFreezeStatus.Approved.value)
              );
              if (freezeDailySelectedDate && freezeDailySelectedDate.length > 0) {
                alreadySendUnFreezeRequestResourceList.push(
                  timeApprovalUnFreezeDatesUser[0].TimeEntryUser
                );
              } else {
                notAlreadySendUnFreezeRequestResourceList.push(b);
              }
            } else {
              notAlreadySendUnFreezeRequestResourceList.push(b);
            }
          });

          if (
            alreadySendUnFreezeRequestResourceList &&
            alreadySendUnFreezeRequestResourceList.length > 0
          ) {
            await this.setState(
              {
                alreadySendUnFreezeRequestResourceList: alreadySendUnFreezeRequestResourceList,
                notAlreadySendUnFreezeRequestResourceList: notAlreadySendUnFreezeRequestResourceList
              },
              () => {
                this.toggleAlreadyUnfreezeRequestSendResourcesDialogClose();
              }
            );
          } else {
            await this.setState(
              {
                alreadySendUnFreezeRequestResourceList: [],
                notAlreadySendUnFreezeRequestResourceList: notAlreadySendUnFreezeRequestResourceList
              },
              () => {
                this.toggleUnfreezeDialog();
              }
            );
          }
        } else {
          await this.setState(
            {
              notAlreadySendUnFreezeRequestResourceList: selectedResources
            },
            () => {
              this.toggleUnfreezeDialog();
            }
          );
        }
      } else {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog('Please select at least one resource to unfreeze', 'Error');
      }
    } else {
      await this.toggleUnfreezeDialog();
    }
  };

  setSelectedWorkflowId = async workflowId => {
    await this.setState({
      selectedWorkFlowId: workflowId
    });
  };

  toggleWorkflowSelectDialog = () => {
    this.setState({
      showWorkflowSelectDialog: !this.state.showWorkflowSelectDialog
    });
  };
  toggleAlreadyUnfreezeRequestSendResourcesDialogClose = () => {
    this.setState({
      showAlreadyUnfreezeRequestSendResources: !this.state.showAlreadyUnfreezeRequestSendResources
    });
  };

  toggleAlreadyUnfreezeRequestSendResourcesDialog = async () => {
    let validDates = [];
    let inValidDates = [];
    await this.setAllSelectedDaysUnfreeze();
    const twoMonthBackDate = moment().subtract(6, 'week').format('YYYY-MM-DD');
    for (const date of this.state.selectedDates) {
      if (new Date(date) < new Date(twoMonthBackDate)) {
        inValidDates.push(date);
      } else {
        validDates.push(date);
      }
    }

    if (inValidDates.length === this.state.selectedDates.length) {
      const message = `Unfreeze Requests cannot be submitted for dates later than 6 weeks. You can only submit unfreeze requests for dates on or after ${twoMonthBackDate}.` ;
      const title = 'Error';
      this.setState({
        showSuccessImage: false,
        showErrorImage: true,
        isErrorMessageVisible: false
      });
      this.toggleMessageDialog(message, title);
    } else if (inValidDates.length > 0 && validDates.length > 0) {
      const message = `Dates later than 6 weeks will not be Unfrozen. Only dates on and after ${twoMonthBackDate} will be submitted for Unfreezing.`
      await this.setState({
        selectedDates: validDates,
        havingtwoMonthBackDates: true,
        unfreezeMessage: message
      });
      this.SubmitUnfreezeRequest();
     }else {
      this.SubmitUnfreezeRequest();
     }
  };

  setWorkflowToResources = async () => {
    await this.setState({
      loading: true
    });
    let selectedResources = [];
    //GlobalApprover
    if (
      window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
      this.state.IsMyTaskOnly !== true
    ) {
      if (
        this.state.notAlreadySendUnFreezeRequestResourceList &&
        this.state.notAlreadySendUnFreezeRequestResourceList.length > 0
      ) {
        selectedResources = this.state.notAlreadySendUnFreezeRequestResourceList;
      }
    }
    const workflowData = {
      WorkflowId: this.state.selectedWorkFlowId,
      Comment: this.state.unfreezeReason,
      RequestType: 2, // Unfreeze time approval
      UnfreezeDates: this.state.selectedDates,
      startDate:
        this.state.selectedDates && this.state.selectedDates.length > 0
          ? moment(this.state.selectedDates[0]).format('YYYY-MM-DD')
          : '',
      endDate:
        this.state.selectedDates && this.state.selectedDates.length > 0
          ? moment(this.state.selectedDates[this.state.selectedDates.length - 1]).format(
              'YYYY-MM-DD'
            )
          : '',
      selectedUsers: selectedResources && selectedResources.length > 0 ? selectedResources : null
    };
    await setWorkflow(workflowData)
      .then(async res => {
        if (this.state.matchingWorkflowList.length > 1) {
          this.toggleWorkflowSelectDialog();
        }
        this.toggleUnfreezeDialog();
        this.setState({ selectedWorkFlowId: null, unfreezeReason: '' });
        if (
          window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
          this.state.IsMyTaskOnly !== true
        ) {
          selectedResources = this.state.resourceList.filter(a => a.selected === true);
          if (
            selectedResources &&
            selectedResources.length > 0 &&
            selectedResources.length == this.state.resourceList.length
          ) {
            await this.setUnfreezeButtonEnableDisable();
            await this.setState({
              isUnfreezeButtonDisable: true
            });
          }
        } else {
          await this.setUnfreezeButtonEnableDisable();
          await this.setState({
            isUnfreezeButtonDisable: true
          });
        }

        let message = 'Selected time entries were sent for unfreeze approval.';
        const title = 'Success';
        this.setState({
          showSuccessImage: true,
          showErrorImage: false,
          isErrorMessageVisible: false,
          loading: false
        });
        this.toggleDialog(message, title);
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.state.matchingWorkflowList.length > 1) {
          this.toggleWorkflowSelectDialog();
        }
        this.toggleUnfreezeDialog();
        this.setState({ selectedWorkFlowId: null, unfreezeReason: '' });
        const message = error.response.data;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          loading: false
        });
        this.toggleMessageDialog(message, title);
      });
  };

  handleUnfreezeReason = async event => {
    await this.setState({
      unfreezeReason: event.target.value
    });
  };

  getUserFreezeDays = async () => {
    await getTimeApprovalFreezeDateFromTimesheetFreezeDate().then(async res => {
      if (res.data.length > 0) {
        let date = new Date(res.data[0].FreezeDate);
        await this.setState({
          timeApprovalFreezeDate: moment(date).format('YYYY-MM-DD'),
          timeApprovalFreezeDateForDisplay: moment(date).format('MM/DD/YYYY'),
          fromResourceDate: date
        });
      }
    });
  };

  isFreezedAllPending = (date, resourceId) => {
    let resourceUnfreezedDate = [];
    let timeEntryUserUnfreezedates = [];
    let pendingUnfreezeDates = [];

    let freezedDateDaily =
      this.state.timeApprovalFreezeDate &&
      new Date(date) <= new Date(this.state.timeApprovalFreezeDate)
        ? true
        : false;

    if (window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1) {
      resourceUnfreezedDate =
        this.state.allPendingUnfreezeDatesUserId &&
        this.state.allPendingUnfreezeDatesUserId.length > 0 &&
        this.state.allPendingUnfreezeDatesUserId.filter(a => a.UserId === resourceId);
    } else {
      pendingUnfreezeDates = this.state.allPendingUnfreezeDates;
      if (
        this.state.allPendingUnfreezeDatesUserId &&
        this.state.allPendingUnfreezeDatesUserId.length > 0
      ) {
        timeEntryUserUnfreezedates = this.state.allPendingUnfreezeDatesUserId.filter(
          a => a.UserId === resourceId
        );
      }
      resourceUnfreezedDate = pendingUnfreezeDates.concat(timeEntryUserUnfreezedates);
    }

    if (resourceUnfreezedDate && resourceUnfreezedDate.length > 0) {
      for (const req of resourceUnfreezedDate) {
        const unfreezedDate = req.UnfreezeTasksApproverDate.find(
          a =>
            a.UnfreezeDate == moment(date).format('YYYY-MM-DD') && a.IsTimeApprovalFreeze === false
        );
        if (unfreezedDate) {
          freezedDateDaily = false;
          break;
        }
      }
    }
    return freezedDateDaily;
  };

  getApproverUnFreezeDaysAllPending = async (from, to) => {
    const weekData = {
      firstDate: moment(from).format('YYYY-MM-DD'),
      lastDate: moment(to).format('YYYY-MM-DD')
    };

    await getUserUnFreezeDaysWithUserId(weekData).then(async res => {
      if (res.data.length > 0) {
        await this.setState({ allPendingUnfreezeDatesUserId: res.data });
      }
    });

    await getUserUnFreezeDays(weekData).then(async res => {
      if (res.data && res.data.length > 0) {
        await this.setState({ allPendingUnfreezeDates: res.data });
      }
    });
  };

  getApproverUnFreezeDaysDaily = async date => {
    const weekData = {
      firstDate:
        this.state.status === 1
          ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
          : this.state.status === 3
          ? moment(this.state.fromResourceDate).format('YYYY-MM-DD')
          : moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
      lastDate:
        this.state.status === 1
          ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
          : this.state.status === 3
          ? moment(this.state.toResourceDate).format('YYYY-MM-DD')
          : moment(this.state.LastDayOfWeek).format('YYYY-MM-DD')
    };
    let freezedDateDaily =
      this.state.timeApprovalFreezeDate && date <= new Date(this.state.timeApprovalFreezeDate)
        ? true
        : false;
    await getUserUnFreezeDays(weekData).then(res => {
      if (res.data.length > 0) {
        freezedDateDaily = false;
      }
      this.setState({ isFreeze: freezedDateDaily, selectedDates: [weekData.firstDate] });
    });
  };

  getApproverUnFreezeDays = async () => {
    const weekData = {
      firstDate:
        this.state.status === 1
          ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
          : this.state.status === 3
          ? moment(this.state.fromResourceDate).format('YYYY-MM-DD')
          : moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
      lastDate:
        this.state.status === 1
          ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
          : this.state.status === 3
          ? moment(this.state.toResourceDate).format('YYYY-MM-DD')
          : moment(this.state.LastDayOfWeek).format('YYYY-MM-DD')
    };
    await getUserUnFreezeDays(weekData).then(res => {
      if (res.data.length > 0) {
        this.setState({ timeApprovalUnFreezeDates: res.data });
      } else {
        this.setState({ timeApprovalUnFreezeDates: [] });
      }
    });
  };

  getApproverUnFreezeDaysWithUserId = async () => {
    const weekData = {
      firstDate:
        this.state.status === 1
          ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
          : this.state.status === 3
          ? moment(this.state.fromResourceDate).format('YYYY-MM-DD')
          : moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
      lastDate:
        this.state.status === 1
          ? moment(this.state.newDateDaily).format('YYYY-MM-DD')
          : this.state.status === 3
          ? moment(this.state.toResourceDate).format('YYYY-MM-DD')
          : moment(this.state.LastDayOfWeek).format('YYYY-MM-DD')
    };
    await getUserUnFreezeDaysWithUserId(weekData).then(async res => {
      if (res.data.length > 0) {
        await this.setState({ timeApprovalUnFreezeDatesUserId: res.data });
      } else {
        await this.setState({ timeApprovalUnFreezeDatesUserId: [] });
      }
    });
  };

  setUnfreezeButtonEnableDisable = async () => {
    //weekly
    if (
      this.state.status === 2 &&
      window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
      this.state.IsMyTaskOnly !== true &&
      this.state.timeApprovalUnFreezeDatesUserId &&
      this.state.timeApprovalUnFreezeDatesUserId.length > 0 &&
      this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate &&
      this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.length > 0
    ) {
      this.FreezeUIForUnFreezeDates();
    } else if (
      this.state.status === 2 &&
      this.state.timeApprovalUnFreezeDates &&
      this.state.timeApprovalUnFreezeDates.length > 0 &&
      this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate &&
      this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate.length > 0
    ) {
      this.FreezeUIForUnFreezeDates();
    } else if (this.state.status === 2) {
      if (
        this.state.isFreezeDateOfMon ||
        this.state.isFreezeDateOfTue ||
        this.state.isFreezeDateOfWed ||
        this.state.isFreezeDateOfThu ||
        this.state.isFreezeDateOfFri ||
        this.state.isFreezeDateOfSat ||
        this.state.isFreezeDateOfSun
      ) {
        await this.setState({
          isUnfreezeButtonDisable: false
        });
      } else {
        await this.setState({
          isUnfreezeButtonDisable: true
        });
      }
    } else if (
      this.state.status === 1 &&
      window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
      this.state.IsMyTaskOnly !== true &&
      this.state.timeApprovalUnFreezeDatesUserId &&
      this.state.timeApprovalUnFreezeDatesUserId.length > 0 &&
      this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate &&
      this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.length > 0
    ) {
      this.FreezeUIForUnFreezeDates();
    } else if (
      this.state.status === 1 &&
      this.state.timeApprovalUnFreezeDates &&
      this.state.timeApprovalUnFreezeDates.length > 0 &&
      this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate &&
      this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate.length > 0
    ) {
      this.FreezeUIForUnFreezeDates();
    } else if (
      this.state.status === 1 &&
      this.state.resourceList.length === 1 &&
      this.state.timeApprovalUnFreezeDatesUserId &&
      this.state.timeApprovalUnFreezeDatesUserId.length > 0 &&
      this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate &&
      this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.length > 0
    ) {
      this.FreezeUIForUnFreezeDates();
    } else if (this.state.status === 1) {
      if (this.state.isFreeze) {
        await this.setState({
          isUnfreezeButtonDisable: false
        });
      } else {
        await this.setState({
          isUnfreezeButtonDisable: true
        });
      }
    }
  };

  setAllSelectedDaysUnfreeze = async () => {
    let unfreezeSelectedDates = [];
    //weekly
    if (this.state.status === 2) {
      unfreezeSelectedDates.push(moment(this.state.dateOfMon).format('YYYY-MM-DD'));
      unfreezeSelectedDates.push(moment(this.state.dateOfTue).format('YYYY-MM-DD'));
      unfreezeSelectedDates.push(moment(this.state.dateOfWed).format('YYYY-MM-DD'));
      unfreezeSelectedDates.push(moment(this.state.dateOfTur).format('YYYY-MM-DD'));
      unfreezeSelectedDates.push(moment(this.state.dateOfFri).format('YYYY-MM-DD'));
      unfreezeSelectedDates.push(moment(this.state.dateOfSat).format('YYYY-MM-DD'));
      unfreezeSelectedDates.push(moment(this.state.dateOfSun).format('YYYY-MM-DD'));
      await this.setState({
        selectedDates: unfreezeSelectedDates
      });
    } else if (this.state.status === 1) {
      //Daily
      unfreezeSelectedDates.push(moment(this.state.newDateDaily).format('YYYY-MM-DD'));
      await this.setState({
        selectedDates: unfreezeSelectedDates
      });
    }
  };
  FreezeUIForUnFreezeDates = async () => {
    if (this.state.status === 1) {
      let freezeDailySelectedDate = [];
      //GlobalApprover
      if (
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
        this.state.IsMyTaskOnly !== true
      ) {
        if (
          this.state.timeApprovalUnFreezeDatesUserId &&
          this.state.timeApprovalUnFreezeDatesUserId.length === this.state.resourceList.length
        ) {
          let filterdResource = [];
          filterdResource = this.state.timeApprovalUnFreezeDatesUserId.filter(
            a => a.UserId === this.state.resourceList[0].userId
          );
          if (filterdResource.length > 0) {
            freezeDailySelectedDate =
              this.state.timeApprovalUnFreezeDatesUserId &&
              this.state.timeApprovalUnFreezeDatesUserId.length > 0
                ? this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.filter(
                    a => a.UnfreezeDate == moment(this.state.newDateDaily).format('YYYY-MM-DD')
                  )
                : [];
          }
        }
      } else {
        freezeDailySelectedDate =
          this.state.timeApprovalUnFreezeDates && this.state.timeApprovalUnFreezeDates.length > 0
            ? this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate.filter(
                a => a.UnfreezeDate == moment(this.state.newDateDaily).format('YYYY-MM-DD')
              )
            : [];
        if (freezeDailySelectedDate.length <= 0 && this.state.resourceList.length === 1) {
          let filterdResource = [];
          filterdResource = this.state.timeApprovalUnFreezeDatesUserId.filter(
            a => a.UserId === this.state.resourceList[0].userId
          );
          if (filterdResource.length > 0) {
            freezeDailySelectedDate =
              this.state.timeApprovalUnFreezeDatesUserId &&
              this.state.timeApprovalUnFreezeDatesUserId.length > 0
                ? this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.filter(
                    a => a.UnfreezeDate == moment(this.state.newDateDaily).format('YYYY-MM-DD')
                  )
                : [];
          }
        }
      }
      if (
        freezeDailySelectedDate &&
        freezeDailySelectedDate.length > 0 &&
        freezeDailySelectedDate[0].TAUnFreezeRequestStatus &&
        freezeDailySelectedDate[0].TAUnFreezeRequestStatus !==
          UnFreezeStatus.Pending_Approval.value &&
        !freezeDailySelectedDate[0].IsTimeApprovalFreeze
      ) {
        await this.setState({ isFreeze: false });
      } else if (freezeDailySelectedDate.length === 0) {
        await this.setState({ isFreeze: true });
      } else {
        await this.setState({ isFreeze: true });
      }
      if (
        freezeDailySelectedDate &&
        freezeDailySelectedDate.length > 0 &&
        freezeDailySelectedDate[0].TAUnFreezeRequestStatus !==
          UnFreezeStatus.Pending_Approval.value &&
        freezeDailySelectedDate[0].TAUnFreezeRequestStatus !== UnFreezeStatus.Approved.value
      ) {
        await this.setState({
          isUnfreezeButtonDisable: false
        });
      } else if (freezeDailySelectedDate.length === 0) {
        await this.setState({ isUnfreezeButtonDisable: false });
      } else {
        await this.setState({
          isUnfreezeButtonDisable: true
        });
      }
    } else {
      let freezeDateSun;
      let freezeDateMon;
      let freezeDateTue;
      let freezeDateWed;
      let freezeDateThu;
      let freezeDateFri;
      let freezeDateSat;
      if (
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
        this.state.IsMyTaskOnly !== true
      ) {
        if (
          this.state.timeApprovalUnFreezeDatesUserId &&
          this.state.timeApprovalUnFreezeDatesUserId.length === this.state.resourceList.length
        ) {
          let filterdResource = [];
          filterdResource = this.state.timeApprovalUnFreezeDatesUserId.filter(
            a => a.UserId === this.state.resourceList[0].userId
          );
          if (filterdResource.length > 0) {
            freezeDateSun =
              this.state.timeApprovalUnFreezeDatesUserId &&
              this.state.timeApprovalUnFreezeDatesUserId.length > 0
                ? this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.find(
                    a => a.UnfreezeDate == moment(this.state.dateOfSun).format('YYYY-MM-DD')
                  )
                : [];
            freezeDateMon =
              this.state.timeApprovalUnFreezeDatesUserId &&
              this.state.timeApprovalUnFreezeDatesUserId.length > 0
                ? this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.find(
                    a => a.UnfreezeDate == moment(this.state.dateOfMon).format('YYYY-MM-DD')
                  )
                : [];
            freezeDateTue =
              this.state.timeApprovalUnFreezeDatesUserId &&
              this.state.timeApprovalUnFreezeDatesUserId.length > 0
                ? this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.find(
                    a => a.UnfreezeDate == moment(this.state.dateOfTue).format('YYYY-MM-DD')
                  )
                : [];
            freezeDateWed =
              this.state.timeApprovalUnFreezeDatesUserId &&
              this.state.timeApprovalUnFreezeDatesUserId.length > 0
                ? this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.find(
                    a => a.UnfreezeDate == moment(this.state.dateOfWed).format('YYYY-MM-DD')
                  )
                : [];
            freezeDateThu =
              this.state.timeApprovalUnFreezeDatesUserId &&
              this.state.timeApprovalUnFreezeDatesUserId.length > 0
                ? this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.find(
                    a => a.UnfreezeDate == moment(this.state.dateOfTur).format('YYYY-MM-DD')
                  )
                : [];
            freezeDateFri =
              this.state.timeApprovalUnFreezeDatesUserId &&
              this.state.timeApprovalUnFreezeDatesUserId.length > 0
                ? this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.find(
                    a => a.UnfreezeDate == moment(this.state.dateOfFri).format('YYYY-MM-DD')
                  )
                : [];
            freezeDateSat =
              this.state.timeApprovalUnFreezeDatesUserId &&
              this.state.timeApprovalUnFreezeDatesUserId.length > 0
                ? this.state.timeApprovalUnFreezeDatesUserId[0].UnfreezeTasksApproverDate.find(
                    a => a.UnfreezeDate == moment(this.state.dateOfSat).format('YYYY-MM-DD')
                  )
                : [];
          }
        }
      } else {
        freezeDateSun =
          this.state.timeApprovalUnFreezeDates && this.state.timeApprovalUnFreezeDates.length > 0
            ? this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate.find(
                a => a.UnfreezeDate == moment(this.state.dateOfSun).format('YYYY-MM-DD')
              )
            : [];
        freezeDateMon =
          this.state.timeApprovalUnFreezeDates && this.state.timeApprovalUnFreezeDates.length > 0
            ? this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate.find(
                a => a.UnfreezeDate == moment(this.state.dateOfMon).format('YYYY-MM-DD')
              )
            : [];
        freezeDateTue =
          this.state.timeApprovalUnFreezeDates && this.state.timeApprovalUnFreezeDates.length > 0
            ? this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate.find(
                a => a.UnfreezeDate == moment(this.state.dateOfTue).format('YYYY-MM-DD')
              )
            : [];
        freezeDateWed =
          this.state.timeApprovalUnFreezeDates && this.state.timeApprovalUnFreezeDates.length > 0
            ? this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate.find(
                a => a.UnfreezeDate == moment(this.state.dateOfWed).format('YYYY-MM-DD')
              )
            : [];
        freezeDateThu =
          this.state.timeApprovalUnFreezeDates && this.state.timeApprovalUnFreezeDates.length > 0
            ? this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate.find(
                a => a.UnfreezeDate == moment(this.state.dateOfTur).format('YYYY-MM-DD')
              )
            : [];
        freezeDateFri =
          this.state.timeApprovalUnFreezeDates && this.state.timeApprovalUnFreezeDates.length > 0
            ? this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate.find(
                a => a.UnfreezeDate == moment(this.state.dateOfFri).format('YYYY-MM-DD')
              )
            : [];
        freezeDateSat =
          this.state.timeApprovalUnFreezeDates && this.state.timeApprovalUnFreezeDates.length > 0
            ? this.state.timeApprovalUnFreezeDates[0].UnfreezeTasksApproverDate.find(
                a => a.UnfreezeDate == moment(this.state.dateOfSat).format('YYYY-MM-DD')
              )
            : [];
      }

      if (
        freezeDateSun &&
        freezeDateSun.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
        !freezeDateSun.IsTimeApprovalFreeze
      ) {
        await this.setState({ isFreezeDateOfSun: false });
      } else if (new Date(moment(this.state.timeApprovalFreezeDate).format('YYYY-MM-DD')) < new Date(moment(this.state.dateOfSun).format('YYYY-MM-DD'))){
        await this.setState({ isFreezeDateOfSun: false }); 
      } else {
        await this.setState({ isFreezeDateOfSun: true });
      }
      if (
        freezeDateMon &&
        freezeDateMon.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
        !freezeDateMon.IsTimeApprovalFreeze
      ) {
        await this.setState({ isFreezeDateOfMon: false });
      } else if (new Date(moment(this.state.timeApprovalFreezeDate).format('YYYY-MM-DD')) < new Date(moment(this.state.dateOfMon).format('YYYY-MM-DD'))){
        await this.setState({ isFreezeDateOfMon: false }); 
      } else {
        await this.setState({ isFreezeDateOfMon: true });
      }
      if (
        freezeDateTue &&
        freezeDateTue.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
        !freezeDateTue.IsTimeApprovalFreeze
      ) {
        await this.setState({ isFreezeDateOfTue: false });
      } else if (new Date(moment(this.state.timeApprovalFreezeDate).format('YYYY-MM-DD')) < new Date(moment(this.state.dateOfTue).format('YYYY-MM-DD'))){
        await this.setState({ isFreezeDateOfTue: false }); 
      } else {
        await this.setState({ isFreezeDateOfTue: true });
      }
      if (
        freezeDateWed &&
        freezeDateWed.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
        !freezeDateWed.IsTimeApprovalFreeze
      ) {
        await this.setState({ isFreezeDateOfWed: false });
      } else if (new Date(moment(this.state.timeApprovalFreezeDate).format('YYYY-MM-DD')) < new Date(moment(this.state.dateOfWed).format('YYYY-MM-DD'))){
        await this.setState({ isFreezeDateOfWed: false }); 
      } else {
        await this.setState({ isFreezeDateOfWed: true });
      }
      if (
        freezeDateThu &&
        freezeDateThu.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
        !freezeDateThu.IsTimeApprovalFreeze
      ) {
        await this.setState({ isFreezeDateOfThu: false });
      } else if (new Date(moment(this.state.timeApprovalFreezeDate).format('YYYY-MM-DD')) < new Date(moment(this.state.dateOfTur).format('YYYY-MM-DD'))){
        await this.setState({ isFreezeDateOfThu: false }); 
      } else {
        await this.setState({ isFreezeDateOfThu: true });
      }
      if (
        freezeDateFri &&
        freezeDateFri.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
        !freezeDateFri.IsTimeApprovalFreeze
      ) {
        await this.setState({ isFreezeDateOfFri: false });
      } else if (new Date(moment(this.state.timeApprovalFreezeDate).format('YYYY-MM-DD')) < new Date(moment(this.state.dateOfFri).format('YYYY-MM-DD'))){
        await this.setState({ isFreezeDateOfFri: false }); 
      } else {
        await this.setState({ isFreezeDateOfFri: true });
      }
      if (
        freezeDateSat &&
        freezeDateSat.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
        !freezeDateSat.IsTimeApprovalFreeze
      ) {
        await this.setState({ isFreezeDateOfSat: false });
      } else if (new Date(moment(this.state.timeApprovalFreezeDate).format('YYYY-MM-DD')) < new Date(moment(this.state.dateOfSat).format('YYYY-MM-DD'))){
        await this.setState({ isFreezeDateOfSat: false }); 
      } else {
        await this.setState({ isFreezeDateOfSat: true });
      }

      if (
        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
        this.state.IsMyTaskOnly !== true
      ) {
        if (
          this.state.status == 2 &&
          (freezeDateSun &&
            freezeDateSun.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateSun.IsTimeApprovalFreeze &&
            freezeDateMon &&
            freezeDateMon.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateMon.IsTimeApprovalFreeze &&
            freezeDateTue &&
            freezeDateTue.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateTue.IsTimeApprovalFreeze &&
            freezeDateWed &&
            freezeDateWed.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateWed.IsTimeApprovalFreeze &&
            freezeDateThu &&
            freezeDateThu.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateThu.IsTimeApprovalFreeze &&
            freezeDateFri &&
            freezeDateFri.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateFri.IsTimeApprovalFreeze &&
            freezeDateSat &&
            freezeDateSat.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateSat.IsTimeApprovalFreeze)
        ) {
          await this.setState({
            isFreeze: false
          });
        } else if (
          !freezeDateMon &&
          !freezeDateTue &&
          !freezeDateWed &&
          !freezeDateThu &&
          !freezeDateFri &&
          !freezeDateSat &&
          !freezeDateSun
        ) {
          await this.setState({
            isFreeze: true
          });
        } else {
          await this.setState({
            isFreeze: true
          });
        }
      } else {
        if (
          this.state.status == 2 &&
          (freezeDateSun &&
            freezeDateSun.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateSun.IsTimeApprovalFreeze &&
            freezeDateMon &&
            freezeDateMon.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateMon.IsTimeApprovalFreeze &&
            freezeDateTue &&
            freezeDateTue.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateTue.IsTimeApprovalFreeze &&
            freezeDateWed &&
            freezeDateWed.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateWed.IsTimeApprovalFreeze &&
            freezeDateThu &&
            freezeDateThu.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateThu.IsTimeApprovalFreeze &&
            freezeDateFri &&
            freezeDateFri.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateFri.IsTimeApprovalFreeze &&
            freezeDateSat &&
            freezeDateSat.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            !freezeDateSat.IsTimeApprovalFreeze)
        ) {
          await this.setState({
            isFreeze: false
          });
        } else if (
          !freezeDateMon &&
          !freezeDateTue &&
          !freezeDateWed &&
          !freezeDateThu &&
          !freezeDateFri &&
          !freezeDateSat &&
          !freezeDateSun
        ) {
          await this.setState({
            isFreeze: true
          });
        } else {
          await this.setState({
            isFreeze: true
          });
        }
      }
      if (
        freezeDateMon &&
        freezeDateMon.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
        freezeDateMon.TAUnFreezeRequestStatus !== UnFreezeStatus.Approved.value &&
        (freezeDateTue &&
          freezeDateTue.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
          freezeDateTue.TAUnFreezeRequestStatus !== UnFreezeStatus.Approved.value) &&
        (freezeDateWed &&
          freezeDateWed.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
          freezeDateWed.TAUnFreezeRequestStatus !== UnFreezeStatus.Approved.value) &&
        (freezeDateThu &&
          freezeDateThu.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
          freezeDateThu.TAUnFreezeRequestStatus !== UnFreezeStatus.Approved.value) &&
        (freezeDateFri &&
          freezeDateFri.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
          freezeDateFri.TAUnFreezeRequestStatus !== UnFreezeStatus.Approved.value) &&
        (freezeDateSat &&
          freezeDateSat.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
          freezeDateSat.TAUnFreezeRequestStatus !== UnFreezeStatus.Approved.value) &&
        (freezeDateSun &&
          freezeDateSun.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
          freezeDateSun.TAUnFreezeRequestStatus !== UnFreezeStatus.Approved.value)
      ) {
        await this.setState({
          isUnfreezeButtonDisable: false
        });
      } else if (
        !freezeDateMon ||
        !freezeDateTue ||
        !freezeDateWed ||
        !freezeDateThu ||
        !freezeDateFri ||
        !freezeDateSat ||
        !freezeDateSun
      ) {
        await this.setState({
          isUnfreezeButtonDisable: false
        });
      } else {
        await this.setState({
          isUnfreezeButtonDisable: true
        });
      }
    }
  };

  FreezeUI = async () => {
    await this.getApproverUnFreezeDays();
    await this.getApproverUnFreezeDaysWithUserId();
    if (this.state.status === 1) {
      let newDateDaily = moment(this.state.newDateDaily).format('YYYY-MM-DD');
      if (newDateDaily > this.state.timeApprovalFreezeDate) {
        await this.setState({ isFreeze: false });
      } else {
        await this.setState({ isFreeze: true });
      }
    } else {
      let sun = moment(this.state.dateOfSun).format('YYYY-MM-DD');
      let mon = moment(this.state.dateOfMon).format('YYYY-MM-DD');
      let tue = moment(this.state.dateOfTue).format('YYYY-MM-DD');
      let wed = moment(this.state.dateOfWed).format('YYYY-MM-DD');
      let thu = moment(this.state.dateOfTur).format('YYYY-MM-DD');
      let fri = moment(this.state.dateOfFri).format('YYYY-MM-DD');
      let sat = moment(this.state.dateOfSat).format('YYYY-MM-DD');
      if (sun > this.state.timeApprovalFreezeDate) {
        await this.setState({ isFreezeDateOfSun: false });
      } else {
        await this.setState({ isFreezeDateOfSun: true });
      }
      if (mon > this.state.timeApprovalFreezeDate) {
        await this.setState({ isFreezeDateOfMon: false });
      } else {
        await this.setState({ isFreezeDateOfMon: true });
      }
      if (tue > this.state.timeApprovalFreezeDate) {
        await this.setState({ isFreezeDateOfTue: false });
      } else {
        await this.setState({ isFreezeDateOfTue: true });
      }
      if (wed > this.state.timeApprovalFreezeDate) {
        await this.setState({ isFreezeDateOfWed: false });
      } else {
        await this.setState({ isFreezeDateOfWed: true });
      }
      if (thu > this.state.timeApprovalFreezeDate) {
        await this.setState({ isFreezeDateOfThu: false });
      } else {
        await this.setState({ isFreezeDateOfThu: true });
      }
      if (fri > this.state.timeApprovalFreezeDate) {
        await this.setState({ isFreezeDateOfFri: false });
      } else {
        await this.setState({ isFreezeDateOfFri: true });
      }
      if (sat > this.state.timeApprovalFreezeDate) {
        await this.setState({ isFreezeDateOfSat: false });
      } else {
        await this.setState({ isFreezeDateOfSat: true });
      }
      if (
        this.state.status == 2 &&
        this.state.isFreezeDateOfSun &&
        this.state.isFreezeDateOfMon &&
        this.state.isFreezeDateOfTue &&
        this.state.isFreezeDateOfWed &&
        this.state.isFreezeDateOfThu &&
        this.state.isFreezeDateOfFri &&
        this.state.isFreezeDateOfSat
      ) {
        await this.setState({
          isFreeze: true
        });
      } else {
        await this.setState({
          isFreeze: false
        });
      }
    }
    await this.setUnfreezeButtonEnableDisable();
  };

  validateFreezeDate = async date => {
    if (this.state.timeApprovalFreezeDates && this.state.timeApprovalFreezeDates.length > 0) {
      if (this.state.status === 1) {
        let freezeDateDaily = this.state.timeApprovalFreezeDates.find(
          a => a.CalendarDate == moment(this.state.newDateDaily).format('YYYY-MM-DD')
        );
        await this.setState({
          isFreeze:
            freezeDateDaily && freezeDateDaily.isTimeApprovalFreeze
              ? freezeDateDaily.isTimeApprovalFreeze
              : false
        });

        if (this.state.isFreeze) {
          this.setState({
            isUnfreezeButtonDisable: false
          });
        } else {
          this.setState({
            isUnfreezeButtonDisable: true
          });
        }
      } else {
        let freezeDateSun = this.state.timeApprovalFreezeDates.find(
          a => a.CalendarDate == moment(this.state.dateOfSun).format('YYYY-MM-DD')
        );
        let freezeDateMon = this.state.timeApprovalFreezeDates.find(
          a => a.CalendarDate == moment(this.state.dateOfMon).format('YYYY-MM-DD')
        );
        let freezeDateTue = this.state.timeApprovalFreezeDates.find(
          a => a.CalendarDate == moment(this.state.dateOfTue).format('YYYY-MM-DD')
        );
        let freezeDateWed = this.state.timeApprovalFreezeDates.find(
          a => a.CalendarDate == moment(this.state.dateOfWed).format('YYYY-MM-DD')
        );
        let freezeDateThu = this.state.timeApprovalFreezeDates.find(
          a => a.CalendarDate == moment(this.state.dateOfTur).format('YYYY-MM-DD')
        );
        let freezeDateFri = this.state.timeApprovalFreezeDates.find(
          a => a.CalendarDate == moment(this.state.dateOfFri).format('YYYY-MM-DD')
        );
        let freezeDateSat = this.state.timeApprovalFreezeDates.find(
          a => a.CalendarDate == moment(this.state.dateOfSat).format('YYYY-MM-DD')
        );

        await this.setState({
          isFreezeDateOfSun:
            freezeDateSun && freezeDateSun.isTimeApprovalFreeze
              ? freezeDateSun.isTimeApprovalFreeze
              : false,
          isFreezeDateOfMon:
            freezeDateMon && freezeDateMon.isTimeApprovalFreeze
              ? freezeDateMon.isTimeApprovalFreeze
              : false,
          isFreezeDateOfTue:
            freezeDateTue && freezeDateTue.isTimeApprovalFreeze
              ? freezeDateTue.isTimeApprovalFreeze
              : false,
          isFreezeDateOfWed:
            freezeDateWed && freezeDateWed.isTimeApprovalFreeze
              ? freezeDateWed.isTimeApprovalFreeze
              : false,
          isFreezeDateOfThu:
            freezeDateThu && freezeDateThu.isTimeApprovalFreeze
              ? freezeDateThu.isTimeApprovalFreeze
              : false,
          isFreezeDateOfFri:
            freezeDateFri && freezeDateFri.isTimeApprovalFreeze
              ? freezeDateFri.isTimeApprovalFreeze
              : false,
          isFreezeDateOfSat:
            freezeDateSat && freezeDateSat.isTimeApprovalFreeze
              ? freezeDateSat.isTimeApprovalFreeze
              : false
        });
        if (
          this.state.status == 2 &&
          this.state.isFreezeDateOfSun &&
          this.state.isFreezeDateOfMon &&
          this.state.isFreezeDateOfTue &&
          this.state.isFreezeDateOfWed &&
          this.state.isFreezeDateOfThu &&
          this.state.isFreezeDateOfFri &&
          this.state.isFreezeDateOfSat
        ) {
          await this.setState({
            isFreeze: true
          });
        } else {
          await this.setState({
            isFreeze: false
          });
        }
        //weekly
        if (this.state.status === 2) {
          if (
            this.state.isFreezeDateOfMon &&
            freezeDateMon.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value &&
            (this.state.isFreezeDateOfTue &&
              freezeDateTue.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value) &&
            (this.state.isFreezeDateOfWed &&
              freezeDateWed.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value) &&
            (this.state.isFreezeDateOfThu &&
              freezeDateThu.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value) &&
            (this.state.isFreezeDateOfFri &&
              freezeDateFri.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value) &&
            (this.state.isFreezeDateOfSat &&
              freezeDateSat.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value) &&
            (this.state.isFreezeDateOfSun &&
              freezeDateSun.TAUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value)
          ) {
            this.setState({
              isUnfreezeButtonDisable: false
            });
          } else {
            this.setState({
              isUnfreezeButtonDisable: true
            });
          }
        }
      }
    }
  };

  toggleMessageDialog = (message, title) => {
    this.setState({
      showMessageDialog: !this.state.showMessageDialog,
      dialogMessage: message,
      dialogTitle: title,
      unfreezePopupVisible: false
    });
  };

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-12">
            <PanelBar className="filter-panel">
              <PanelBarItem
                title={<div className="filter-timesheet">Timesheet Approval Search</div>}
                expanded={true}
                animation={true}
              >
                <div className="col-md-12 px-1">
                  <div className="row mt-2">
                    <div className="col-md-2">
                      <form className="k-form modified">
                        <div className="k-form-field">
                          <label className="mr-4">View:</label>
                          <div className="my-auto">
                            <div className="d-block mb-2">
                              <input
                                type="radio"
                                name="view"
                                value="dailyview"
                                className="k-radio"
                                checked={this.state.status === 1}
                                id="daily"
                                onClick={e => {
                                  this.radioViewHandler(1, true);
                                }}
                              />
                              <label
                                className="k-radio-label mr-3 font-weight-bold"
                                htmlFor="daily"
                              >
                                Daily
                              </label>
                            </div>
                            <div className="d-block mb-2">
                              <input
                                type="radio"
                                name="view"
                                value="weeklyview"
                                checked={this.state.status === 2}
                                className="k-radio"
                                id="weekly"
                                onClick={e => {
                                  this.radioViewHandler(2, true);
                                }}
                              />
                              <label
                                className="k-radio-label mr-3 font-weight-bold"
                                htmlFor="weekly"
                              >
                                Weekly
                              </label>
                            </div>
                            <div className="d-block mb-2">
                              <input
                                type="radio"
                                name="view"
                                value="allpendingview"
                                className="k-radio"
                                checked={this.state.status === 3}
                                id="allpending"
                                onClick={e => this.radioViewHandler(3, true)}
                              />
                              <label
                                className="k-radio-label font-weight-bold"
                                htmlFor="allpending"
                              >
                                Edit & Approve
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-3">
                      <form className="k-form modified">
                        <div className="k-form-field">
                          <label className="mr-4">Status:</label>
                          <div className="d-block pr-1 mb-2">
                            <input
                              type="checkbox"
                              id="pastatus"
                              className="k-checkbox"
                              name="pending approvals"
                              value={this.state.IsPendiingApproval}
                              checked={this.state.IsPendiingApproval}
                              onChange={() =>
                                this.setState({
                                  IsPendiingApproval: !this.state.IsPendiingApproval
                                })
                              }
                              // disabled={this.state.status === 3}
                            />
                            <label
                              className="k-checkbox-label mr-3 font-weight-bold"
                              htmlFor="pastatus"
                            >
                              Pending Approvals
                            </label>
                          </div>
                          <div className="d-block pr-1 mb-2">
                            <input
                              type="checkbox"
                              id="appbill"
                              className="k-checkbox"
                              name="approved-b"
                              value={this.state.IsApprovalBillable}
                              checked={this.state.IsApprovalBillable}
                              onChange={() =>
                                this.setState({
                                  IsApprovalBillable: !this.state.IsApprovalBillable
                                })
                              }
                              // disabled={this.state.status === 3}
                            />
                            <label
                              className="k-checkbox-label mr-3 font-weight-bold"
                              htmlFor="appbill"
                            >
                              Approved - Billable
                            </label>
                          </div>
                          <div className="d-block pr-1 mb-2">
                            <input
                              type="checkbox"
                              id="appnbill"
                              className="k-checkbox"
                              name="approved-n-b"
                              value={this.state.IsApprovalNonBillable}
                              checked={this.state.IsApprovalNonBillable}
                              onChange={() =>
                                this.setState({
                                  IsApprovalNonBillable: !this.state.IsApprovalNonBillable
                                })
                              }
                              // disabled={this.state.status === 3}
                            />
                            <label
                              className="k-checkbox-label mr-3 font-weight-bold"
                              htmlFor="appnbill"
                            >
                              Approved - Non-Billable
                            </label>
                          </div>
                          <div className="d-block pr-1 mt-2 mb-2">
                            <input
                              type="checkbox"
                              id="reject"
                              className="k-checkbox"
                              name="rejected"
                              value={this.state.IsRejected}
                              checked={this.state.IsRejected}
                              onChange={() => this.setState({ IsRejected: !this.state.IsRejected })}
                              // disabled={this.state.status === 3}
                            />
                            <label
                              className="k-checkbox-label mr-3 font-weight-bold"
                              htmlFor="reject"
                            >
                              Rejected
                            </label>
                          </div>
                          <div className="d-block pr-1 mb-2">
                            <input
                              type="checkbox"
                              id="appNotNeed"
                              className="k-checkbox"
                              name="approvalNotNeed"
                              value={this.state.IsApprNotNeed}
                              checked={this.state.IsApprNotNeed}
                              onChange={() =>
                                this.setState({ IsApprNotNeed: !this.state.IsApprNotNeed })
                              }
                              // disabled={this.state.status === 3}
                            />
                            <label
                              className="k-checkbox-label font-weight-bold"
                              htmlFor="appNotNeed"
                            >
                              Approval Not Needed
                            </label>
                          </div>
                          {window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 && (
                            <div className="d-block pr-1 mb-2">
                              <input
                                type="checkbox"
                                id="IsMyTaskOnly"
                                className="k-checkbox"
                                name="approvalNotNeed"
                                value={this.state.IsMyTaskOnly}
                                checked={this.state.IsMyTaskOnly}
                                onChange={() =>
                                  this.setState({ IsMyTaskOnly: !this.state.IsMyTaskOnly })
                                }
                                disabled={this.state.status === 3}
                              />
                              <label
                                className="k-checkbox-label font-weight-bold"
                                htmlFor="IsMyTaskOnly"
                              >
                                My Task Only
                              </label>
                            </div>
                          )}
                          <div className="d-block pr-1 mb-2">
                            <input
                              type="checkbox"
                              id="te0Resources"
                              className="k-checkbox"
                              name="te0Resources"
                              value={this.state.TimeNotEntered}
                              checked={this.state.TimeNotEntered}
                              onChange={() =>
                                this.setState({ TimeNotEntered: !this.state.TimeNotEntered })
                              }
                              disabled={this.state.status === 3}
                            />
                            <label
                              className="k-checkbox-label font-weight-bold"
                              htmlFor="te0Resources"
                            >
                              Time Not Entered
                            </label>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-md-4">
                          <label className="">Project/ Task Group:</label>
                          <ComboBox
                            name="project"
                            data={this.state.projectFilter}
                            dataItemKey="id"
                            textField="Name"
                            value={this.state.selectedProjectOrCr}
                            onChange={e => this.projectFilterHandler(e)}
                            filterable={true}
                            onFilterChange={this.filterOnChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="">Task:</label>
                          <ComboBox
                            name="task"
                            data={this.state.taskFilter}
                            dataItemKey="id"
                            textField="taskName"
                            opened={this.state.taskOpened}
                            onOpen={e => {
                              this.setState({ taskOpened: true });
                            }}
                            onClose={e => {
                              this.setState({ taskOpened: false });
                            }}
                            value={this.state.selectedTask}
                            disabled={this.state.selectedProjectOrCr === null ? true : false}
                            onChange={e => this.taskFilterHandler(e)}
                            filterable={true}
                            onFilterChange={this.filterOnChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="">Resource:</label>
                          <MultiSelect
                            name="resource"
                            data={this.state.resourceListForFilter}
                            dataItemKey="userId"
                            textField="userName"
                            value={this.state.selectedResource}
                            onChange={e => this.resourceFilterHandler(e)}
                            filterable={true}
                            onFilterChange={this.filterOnChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4">
                          <label className="">Practice:</label>
                          <MultiSelect
                            name="practice"
                            data={this.state.practiceListForFilter}
                            dataItemKey="id"
                            textField="Name"
                            value={this.state.selectedPractice}
                            onChange={e => this.practiceFilterHandler(e)}
                            filterable={true}
                            onFilterChange={this.filterOnChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="">Region:</label>
                          <MultiSelect
                            name="region"
                            data={this.state.regionListForFilter}
                            dataItemKey="id"
                            textField="Name"
                            value={this.state.selectedRegion}
                            onChange={e => this.regionFilterHandler(e)}
                            filterable={true}
                            onFilterChange={this.filterOnChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <div className="d-block pr-1 mt-4 pt-1">
                            <input
                              type="checkbox"
                              id="myresources"
                              className="k-checkbox"
                              name="myresources"
                              value={this.state.IsMyResources}
                              checked={this.state.IsMyResources}
                              onChange={() =>
                                this.setState({ IsMyResources: !this.state.IsMyResources })
                              }
                            />
                            <label
                              className="k-checkbox-label mr-3 font-weight-bold"
                              htmlFor="myresources"
                            >
                              My Reportees
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-2 d-inline-block mr-2">
                  <div className="d-flex align-items-center">
                    <label className="mr-2">Last Time Freeze Date:</label>
                    <label>
                      {this.state.timeApprovalFreezeDateForDisplay !== ''
                        ? moment(this.state.timeApprovalFreezeDateForDisplay).format('MM-DD-YYYY')
                        : null}
                    </label>
                  </div>
                </div>
                <div className="col-md-12 btn-align-right">
                  <Button
                    primary={true}
                    className="mb-3"
                    type="button"
                    onClick={() => this.saveSearchCriteria()}
                  >
                    Save
                  </Button>
                  <Button
                    primary={true}
                    className="mb-3"
                    type="button"
                    onClick={() => {
                      if (this.state.status != 3) {
                        this.getResourceList();
                      } else {
                        this.submitSearch();
                      }
                    }}
                  >
                    Search
                  </Button>
                  <Button type="reset" className="mb-3" onClick={() => this.clearFilters()}>
                    Clear
                  </Button>
                </div>
              </PanelBarItem>
            </PanelBar>
          </div>
        </div>

        <div className="main-seperator" />

        {/* Daily Section */}
        {this.state.status === 1 && (
          <>
            <div className="panel-toolbar mt-3 px-2 py-2 fade-in">
              <div className="row ">
                <div className="col-md-3 d-flex">
                  <label className="mr-2">Date: </label>
                  <DatePicker
                    defaultValue={new Date()}
                    format="MM/dd/yyyy"
                    min={new Date('01/01/2021')}
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                    value={this.state.newDateDaily}
                    onChange={event => this.setTotalsOfDates(event.target.value)}
                  />
                  <div className="d-flex">
                    <a
                      type="button"
                      onClick={() => this.prevDay()}
                      className={!this.state.disableDailyPrvBtn ? 'anchor-disabled' : ''}
                    >
                      <div className="rounded-link d-flex align-items-center justify-content-center mr-2 ml-2">
                        <span className="k-icon k-i-arrow-chevron-left" />
                      </div>
                    </a>
                    <a type="button" onClick={() => this.nextDay()}>
                      <div className="rounded-link d-flex align-items-center justify-content-center mr-2">
                        <span className="k-icon k-i-arrow-chevron-right" />
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-md-9 btn-align-right">
                  <Button
                    primary={true}
                    disabled={
                      this.state.isFreeze &&
                      !(
                        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
                        this.state.IsMyTaskOnly !== true
                      )
                    }
                    onClick={() => this.selectAllHandlerDaily(!this.state.selectAllDaily)}
                  >
                    {this.state.selectAllDaily ? 'Deselect All' : 'Select All'}
                  </Button>
                  <Button
                    primary={true}
                    disabled={true} //this.state.isFreeze
                    onClick={this.expandAllPanels}
                  >
                    Collapse All
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isUnfreezeButtonDisable}
                    onClick={() => this.toggleAlreadyUnfreezeRequestSendResourcesDialog()}
                  >
                    Unfreeze
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze}
                    onClick={this.toggleMoveDialog}
                  >
                    Move
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze}
                    onClick={() => this.unApproveHandler()}
                  >
                    Unapprove
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze}
                    onClick={this.toggleRejectDialog}
                  >
                    Reject
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze}
                    onClick={this.toggleNonbillDialog}
                  >
                    Approve Non-Billable
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze || this.state.isClickedBillable}
                    onClick={() => this.approveBillableHandler()}
                  >
                    Approve Billable
                  </Button>
                </div>
              </div>
            </div>
            <div className="row fade-in approval-daily-panel-wrap">
              <div className="col-md-12 panel-dark">
                {this.state.resourceList.length !== 0 &&
                  this.state.resourceList.map(resource => (
                    <PanelBar
                      onSelect={e => {
                        this.getTaskList(
                          resource.userId,
                          'initial',
                          'InDaily',
                          resource.isMyResource
                        );
                      }}
                      className="mt-2"
                      key={resource.userId}
                    >
                      <input
                        type="checkbox"
                        id="reso-name"
                        className="k-checkbox mr-2 time-approval-panel-checkbox"
                        name="resourcename"
                        checked={resource.selected}
                        onChange={() => this.resourceClickHandlerDaily(resource.userId, false)}
                      />

                      <PanelBarItem
                        animation={true}
                        expanded={this.state.allPanelExapnded}
                        title={
                          <div className="font-weight-bold">
                            <label className="reso-name ml-4">
                              Resource : {resource.userName}{' '}
                            </label>
                            {parseInt(
                              this.setTotalOfWeek(resource.totalOfDay)
                                .toString()
                                .split(':')[0]
                            ) >= 8 ? (
                              <span className="resc-green ml-3">
                                {(
                                  this.setTotalOfWeek(resource.totalOfDay) + ' Hours '
                                )}
                                / 8 Hours
                              </span>
                            ) : (
                              <span className="resc-red ml-3">
                                {(
                                  this.setTotalOfWeek(resource.totalOfDay) + ' Hours '
                                )}
                                / 8 Hours
                              </span>
                            )}
                          </div>
                        }
                      >
                        <div className="col-md-12 py-3">
                          <Grid
                            resizable
                            onSelectionChange={this.rowClickDaily}
                            onRowClick={this.rowClickDaily}
                            selectedField="selected"
                            data={
                              resource.totalResourceTimeEntry
                                ? orderBy(resource.totalResourceTimeEntry, this.state.sortDaily)
                                : []
                            }
                            sortable={true}
                            sort={this.state.sortDaily}
                            onSortChange={e => {
                              this.setState({
                                sortDaily: e.sort
                              });
                            }}
                            className="timesheet-approval-grid-daily"
                          >
                            <Column field="selected" width="50px" />
                            <Column field="Project" title="Project/Task Group" />
                            <Column field="Task" title="Task" />
                            <Column
                              width="110px"
                              field="Duration"
                              title="Duration"
                              cell={props => <DurationCell {...props}  status={this.state.status} resource={resource} />}
                              //cell={this.customCell}
                            />
                            <Column field="Comment" title="Comment" />
                            <Column field="TicketId" title="Ticket ID" />
                            <Column field="WorkLocationName" title="Work Location" />
                            <Column
                              field="ApprovedStatus"
                              title="Status"
                              width="270px"
                              cell={props => <StatusCell {...props} />}
                            />
                            <Column field="createdAt" title="Time Entry Date" cell={props => <DateCell {...props}/>} />
                          </Grid>
                        </div>
                      </PanelBarItem>
                    </PanelBar>
                  ))}
                {this.state.resourceList.length === 0 && (
                  <div className="no-record-wrap">No records available</div>
                )}
              </div>
            </div>
          </>
        )}
        {/* End of Daily Section */}

        {/* Weekly Section */}
        {this.state.status === 2 && (
          <>
            <div className="panel-toolbar mt-3 px-2 py-2 fade-in">
              <div className="row ">
                <div className="col-md-3 d-flex align-items-center">
                  <label className="mr-2">Date: </label>
                  <DatePicker
                    defaultValue={new Date()}
                    format="MM/dd/yyyy"
                    min={new Date('01/01/2021')}
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                    value={this.state.newDate}
                    onChange={event => this.setWeekDates(event.target.value)}
                  />

                  <div className="d-flex">
                    <a
                      type="button"
                      onClick={() => this.prevWeek()}
                      className={!this.state.disablePrvBtn ? 'anchor-disabled' : ''}
                    >
                      <div className="rounded-link d-flex align-items-center justify-content-center mr-2 ml-2">
                        <span className="k-icon k-i-arrow-chevron-left" />
                      </div>
                    </a>

                    <a type="button" onClick={() => this.nextWeek()}>
                      <div className="rounded-link d-flex align-items-center justify-content-center mr-2">
                        <span className="k-icon k-i-arrow-chevron-right" />
                      </div>
                    </a>
                  </div>
                </div>

                <div className="col-md-9 btn-align-right">
                  <Button
                    primary={true}
                    disabled={
                      this.state.isFreeze &&
                      !(
                        window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
                        this.state.IsMyTaskOnly !== true
                      )
                    }
                    onClick={() => this.selectAllHandler(!this.state.selectAllWeekly)}
                  >
                    {this.state.selectAllWeekly ? 'Deselect All' : 'Select All'}
                  </Button>
                  <Button primary={true} onClick={this.expandAllPanels} disabled={true}>
                    Collapse All
                  </Button>
                  <Button
                    primary={true}
                    type="button"
                    disabled={this.state.isUnfreezeButtonDisable}
                    onClick={() => this.toggleAlreadyUnfreezeRequestSendResourcesDialog()}
                  >
                    Unfreeze
                  </Button>
                  <Button
                    disabled={this.state.isFreeze}
                    primary={true}
                    onClick={this.toggleMoveDialog}
                  >
                    Move
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze}
                    onClick={() => this.unApproveHandler()}
                  >
                    Unapprove
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze}
                    onClick={this.toggleRejectDialog}
                  >
                    Reject
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze}
                    onClick={this.toggleNonbillDialog}
                  >
                    Approve Non-Billable
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze || this.state.isClickedBillable}
                    onClick={() => this.approveBillableHandler()}
                  >
                    Approve Billable
                  </Button>
                </div>
              </div>
            </div>
            <div className="row window-ta fade-in approval-weekly-panel-wrap">
              <div className="col-md-12 panel-dark">
                {this.state.resourceList.length !== 0 &&
                  this.state.resourceList.map(resource => (
                    <Panel
                      getTaskList={this.getTaskList}
                      resourceClickHandler={this.resourceClickHandler}
                      allPanelExapnded={this.state.allPanelExapnded}
                      dateOfMon={this.state.dateOfMon}
                      dateOfTue={this.state.dateOfTue}
                      dateOfWed={this.state.dateOfWed}
                      dateOfTur={this.state.dateOfTur}
                      dateOfFri={this.state.dateOfFri}
                      dateOfSat={this.state.dateOfSat}
                      dateOfSun={this.state.dateOfSun}
                      resource={resource}
                      setTotalOfWeek={this.setTotalOfWeek}
                      selectAllDay={this.selectAllDay}
                      setTotalTime={this.setTotalTime}
                      rowClick={this.rowClick}
                      headerSelectionChange={this.headerSelectionChange}
                      customCell={this.customCell}
                      sortWeekly={this.state.sortWeekly}
                      setSortWeekly={e => {
                        this.setState({
                          sortWeekly: e
                        });
                      }}
                    />
                  ))}
                {this.state.resourceList.length === 0 && (
                  <div className="no-record-wrap">No records available</div>
                )}
              </div>
              {this.state.windowDialogVisible && !this.state.isFreeze && (
                <div className="timesheetwindow">
                  <Window
                    width={360}
                    style={{ height: 'auto', zIndex: '99999' }}
                    initialTop={300}
                    initialLeft={300}
                    draggable={true}
                    resizable={false}
                    title={
                      <div className="col-md-12 text-right py-0">
                        <span className="k-icon k-i-move" alt="Move" />
                      </div>
                    }
                  >
                    <form className="k-form">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="text-center hr-circle text-white font-weight-bold">
                            <div classname="time-hours">3h</div>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <label className="ts-title">Item Entry Component Update</label>
                          <div className="ts-descp">M3 Master Data Configurations</div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <button
                          type="button"
                          className="k-button mr-2"
                          onClick={this.toggleWindowDialog}
                        >
                          Reject
                        </button>
                        <button
                          type="button"
                          className="k-button k-primary mr-2"
                          onClick={this.toggleWindowDialog}
                        >
                          Approve Billable
                        </button>
                        <button
                          type="button"
                          className="k-button k-primary"
                          onClick={this.toggleWindowDialog}
                        >
                          Approve Non-Billable
                        </button>
                      </div>
                    </form>
                  </Window>
                </div>
              )}
            </div>
          </>
        )}
        {/* End of Weekly Section */}

        {/* All Pending Section */}
        {this.state.status === 3 && (
          <>
            <div className="panel-toolbar mt-3 px-2 py-2 fade-in">
              <div className="row ">
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-4 ">
                      <label>From:</label>
                    </div>
                    <div class="col-md-8 px-0">
                      <DatePicker
                        defaultValue={new Date(this.state.fromResourceDate)}
                        format="MM/dd/yyyy"
                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                        value={new Date(this.state.fromResourceDate)}
                        onChange={event => this.setFromDate(event.target.value)}
                        max={new Date()}
                        min={
                          this.state.minDate !== null
                            ? new Date(this.state.minDate)
                            : new Date('2021/01/01')
                        }
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-2 ">
                  <div class="row">
                    <div class="col-md-4">
                      <label>To:</label>
                    </div>
                    <div class="col-md-8 px-0">
                      <DatePicker
                        defaultValue={new Date()}
                        format="MM/dd/yyyy"
                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                        value={new Date(this.state.toResourceDate)}
                        onChange={event => this.setToDate(event.target.value)}
                        max={new Date()}
                        min={
                          this.state.fromResourceDate
                            ? new Date(this.state.fromResourceDate)
                            : this.state.minDate
                            ? new Date(this.state.minDate)
                            : new Date('2020/01/01')
                        }
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <div className="row">
                    <div class="col-md-12 d-flex align-items-center">
                      {/* <input
                        type="checkbox"
                        id="toolall"
                        className="k-checkbox"
                        name="toolall"
                        onChange={() => {
                          this.setState({ selectAll: !this.state.selectAll });
                        }}
                        checked={this.state.selectAll}
                      />
                      <label className="k-checkbox-label mr-2 font-weight-bold" htmlFor="toolall">
                        All
                      </label> */}
                      {/* <Button primary={true} type="button" onClick={this.submitSearch}>
                        Go
                      </Button> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 btn-align-right">
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze}
                    onClick={() => this.selectAllHandlerAllPending(!this.state.selectAllDaily)}
                  >
                    {this.state.selectAllDaily ? 'Deselect All' : 'Select All'}
                  </Button>
                  <Button
                    primary={true}
                    onClick={this.toggleMoveDialogAllPending}
                    disabled={this.state.isFreeze}
                  >
                    Move
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze}
                    onClick={this.toggleRejectDialog}
                  >
                    Reject
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze}
                    onClick={this.toggleNonbillDialog}
                  >
                    Approve Non-Billable
                  </Button>
                  <Button
                    primary={true}
                    disabled={this.state.isFreeze || this.state.isClickedBillable}
                    onClick={() => this.approveBillableHandlerAllPending()}
                  >
                    Approve Billable
                  </Button>
                </div>
              </div>
            </div>
            <div className="row fade-in">
              <div className="col-md-12">
                <Grid
                  resizable
                  data={
                    this.state.totalResourceTimeEntryPending
                      ? orderBy(
                          this.state.totalResourceTimeEntryPending,
                          this.state.sortAllPending
                        ).slice(this.state.allPendingSkip, 20 + this.state.allPendingSkip)
                      : []
                  }
                  sortable={true}
                  sort={this.state.sortAllPending}
                  onSortChange={e => {
                    this.setState({
                      sortAllPending: e.sort
                    });
                  }}
                  pageable={true}
                  skip={this.state.allPendingSkip}
                  take={20}
                  total={
                    this.state.totalResourceTimeEntryPending
                      ? this.state.totalResourceTimeEntryPending.length
                      : 0
                  }
                  onPageChange={event => {
                    this.setState({ allPendingSkip: event.page.skip });
                  }}
                  onSelectionChange={this.rowClickAllPending}
                  onRowClick={this.rowClickAllPending}
                  selectedField="selected"
                  className="time-approval-grid-allpending"
                >
                  <Column field="selected" width="25px" />
                  <Column field="LogDateTime" width="100px" title="Date" cell={props=><td>{moment(props.dataItem.LogDateTime).format("MM-DD-YYYY")}</td>}/>
                  <Column field="Resource" width="150px" title="Resource" />
                  <Column field="Project" width="180px" title="Project/Task Group" />
                  <Column field="Task" width="180px" title="Task" />
                  <Column
                    field="ApprovedDurationValue"
                    title="Duration"
                    width="90px"
                    cell={this.NumericTextBoxCell}
                  />
                  <Column field="ApprovedComment" title="Comment" width="200px" cell={this.NumericTextBoxCell}/>
                  <Column field="ApprovedTicketId" title="Ticket ID" width="100px" cell={this.NumericTextBoxCell}/>
                  <Column 
                    field="ApprovedStatus" 
                    title="Status" 
                    width="260px"
                    cell={props => <StatusCell {...props} 
                  />}/>
                  <Column field="WorkLocationName" title="Work Location" width="80px" />
                  <Column field="createdAt" title="Time Entry Date" width="100px" cell={props => <DateCell {...props}/>} />
                  <Column
                    field="LogDateTime"
                    title="Note"
                    width="120px"
                    cell={props => (
                      <td>
                        {this.isFreezedAllPending(
                          props.dataItem.LogDateTime,
                          props.dataItem.ResourceId
                        )
                          ? 'Frozen Timesheet'
                          : 'Open Timesheet'}
                      </td>
                    )}
                  />
                </Grid>
              </div>
            </div>
            {/*<div className="row fade-in">*/}
            {/*  <div className="col-md-12 panel-dark">*/}
            {/*    {this.state.resourceList.length !== 0 &&*/}
            {/*    this.state.resourceList.map(resource => (*/}
            {/*        <PanelBar*/}
            {/*            onSelect={e => {*/}
            {/*              this.getTaskList(resource.userId, 'initial','InPending');*/}
            {/*            }}*/}
            {/*            className="mt-2"*/}
            {/*            key={resource.userId}*/}
            {/*        >*/}
            {/*          <input*/}
            {/*              type="checkbox"*/}
            {/*              id="reso-name"*/}
            {/*              className="k-checkbox mr-2 time-approval-panel-checkbox"*/}
            {/*              name="resourcename"*/}
            {/*              checked={resource.selected}*/}
            {/*              onChange={() => this.resourceClickHandlerDaily(resource.userId)}*/}
            {/*          />*/}

            {/*          <PanelBarItem*/}
            {/*              animation={true}*/}
            {/*              expanded={this.state.allPanelExapnded}*/}
            {/*              title={*/}
            {/*                <div className="font-weight-bold">*/}
            {/*                  <label htmlFor="reso-name" className="reso-name ml-4">*/}
            {/*                    Resource : {resource.userName}{' '}*/}
            {/*                  </label>*/}
            {/*                  /!*{parseInt(*!/*/}
            {/*                  /!*    this.setTotalOfWeek(resource.totalOfDay)*!/*/}
            {/*                  /!*        .toString()*!/*/}
            {/*                  /!*        .split(':')[0]*!/*/}
            {/*                  /!*) >= 8 ? (*!/*/}
            {/*                  /!*    <span className="resc-green ml-3">*!/*/}
            {/*                  /!*  {this.setTotalOfWeek(resource.totalOfDay)} Hours / 8 Hours*!/*/}
            {/*                  /!*</span>*!/*/}
            {/*                  /!*) : (*!/*/}
            {/*                  /!*    <span className="resc-red ml-3">*!/*/}
            {/*                  /!*  {this.setTotalOfWeek(resource.totalOfDay)} Hours / 8 Hours*!/*/}
            {/*                  /!*</span>*!/*/}
            {/*                  /!*)}*!/*/}
            {/*                </div>*/}
            {/*              }*/}
            {/*          >*/}
            {/*            <div className="col-md-12 py-3">*/}
            {/*              <Grid*/}
            {/*                  resizable*/}
            {/*                  onSelectionChange={this.rowClickDaily}*/}
            {/*                  onRowClick={this.rowClickDaily}*/}
            {/*                  selectedField="selected"*/}
            {/*                  data={resource.totalResourceTimeEntry}*/}
            {/*                  //onHeaderSelectionChange={this.headerSelectionChange}*/}
            {/*              >*/}
            {/*                <Column field="selected" width="50px" />*/}
            {/*                <Column field="Project" width="100px" title="Project" />*/}
            {/*                <Column field="Task" width="100px" title="Task" />*/}
            {/*                <Column*/}
            {/*                    field=""*/}
            {/*                    width="150px"*/}
            {/*                    title="Duration (Hours)"*/}
            {/*                    cell={props => (*/}
            {/*                        <DurationCell*/}
            {/*                            {...props}*/}
            {/*                            resource = {resource}*/}
            {/*                        />*/}
            {/*                    )}*/}
            {/*                    //cell={this.customCell}*/}
            {/*                />*/}
            {/*                <Column*/}
            {/*                    field="Comment"*/}
            {/*                    title="Comment"*/}
            {/*                    width="150px"*/}
            {/*                />*/}
            {/*              </Grid>*/}
            {/*            </div>*/}
            {/*          </PanelBarItem>*/}
            {/*        </PanelBar>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </>
        )}
        {/* End of All Pending Section */}

        {this.state.rejectTa && (
          <Dialog
            title={'Reason for Timesheet Rejection'}
            onClose={this.toggleRejectDialog}
            width={400}
          >
            <div className="row">
              <div className="col-md-3">
                <div className="d-block">
                  <label className="mandatory">Reason:</label>
                </div>
              </div>
              <div className="col-md-9">
                <div className="d-block">
                  <textarea
                    className="k-textarea"
                    onChange={this.rejectReasonHandler}
                    maxLength={100}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.rejectReason)}>
                      Reason is mandatory
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button " onClick={this.toggleRejectDialog}>
                Cancel
              </button>
              <button className="k-button modal-primary" onClick={() => this.rejectTimeEntry()}>
                Reject
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.nonbilldialog && (
          <Dialog
            title={'Select Non-Billable Category'}
            onClose={this.toggleNonbillDialog}
            width={400}
          >
            <div className="row">
              <div className="col-md-3">
                <div className="d-block">
                  <label className="mandatory">Category:</label>
                </div>
              </div>

              <div className="col-md-9">
                <div className="d-block">
                  <ComboBox
                    name="approveNonBillableCate"
                    data={this.state.approveNonBillableCategory}
                    dataItemKey="id"
                    textField="name"
                    value={this.state.selectedApproveNonBillableCategory}
                    onChange={e => this.approveNonBillableFilterHandler(e)}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span
                      className={this.validateProperty(
                        this.state.selectedApproveNonBillableCategory
                      )}
                    >
                      Category is mandatory
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button " onClick={this.toggleNonbillDialog}>
                Cancel
              </button>
              <button
                className="k-button modal-primary"
                disabled={this.state.isClickedNonBillable}
                onClick={() => this.approveNonBillableHandler()}
              >
                Save
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.moveDialog && (
          <Dialog
            title={'Move Time Entries'}
            onClose={() => {
              if (this.state.status != 3) {
                this.toggleMoveDialog();
              } else {
                this.toggleMoveDialogAllPending();
              }
            }}
            width={400}
          >
            <div className="row">
              <div className="col-md-4">
                <div className="d-block">
                  <label className="mandatory">Move the time entry to:</label>
                </div>
              </div>

              <div className="col-md-8">
                <div className="d-block">
                  <ComboBox
                    name="approveNonBillableCate"
                    data={this.state.moveTaskFilter}
                    dataItemKey="id"
                    textField="Task"
                    value={this.state.selectedMoveTaskFilter}
                    onChange={e => this.moveTimeEntryFilter(e)}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.selectedMoveTaskFilter)}>
                      Task is mandatory
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="d-block">
                  <label className="mandatory">Reason:</label>
                </div>
              </div>

              <div className="col-md-8">
                <div className="d-block">
                  <textarea
                    className="k-textarea"
                    onChange={this.moveReasonHandler}
                    maxLength={100}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.moveReason)}>
                      Reason is mandatory
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button
                className="k-button "
                onClick={() => {
                  if (this.state.status != 3) {
                    this.toggleMoveDialog();
                  } else {
                    this.toggleMoveDialogAllPending();
                  }
                }}
              >
                Cancel
              </button>
              <button className="k-button modal-primary" onClick={() => this.moveTimeEntry()}>
                Save
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {((window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
          this.state.IsMyTaskOnly !== true &&
          this.state.notAlreadySendUnFreezeRequestResourceList &&
          this.state.notAlreadySendUnFreezeRequestResourceList.length > 0 &&
          this.state.unfreezePopupVisible) ||
          (!(
            window.USER_PERMISSION.indexOf('APRV_TM_SHT_GLBL') > -1 &&
            this.state.IsMyTaskOnly !== true
          ) &&
            this.state.unfreezePopupVisible)) && (
          <Dialog
            title={'Reason to Unfreeze Timesheet Approval'}
            onClose={this.toggleUnfreezeDialog}
            width={400}
          >
            {this.state.havingtwoMonthBackDates === true && (
              <p style={{ margin: '10px', textAlign: 'center' }}>{this.state.unfreezeMessage}</p>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-block">
                      <label className="mandatory">Reason:</label>
                      <div className="d-block">
                        <textarea
                          className="k-textarea"
                          name="unfreezeReason"
                          id="unfreezeReason"
                          value={this.state.unfreezeReason}
                          onChange={this.handleUnfreezeReason}
                          maxLength="500"
                        />
                      </div>
                      {this.state.isErrorMessageVisibleReason === true ? (
                        <span className={this.validateProperty(this.state.unfreezeReason)}>
                          Reason is mandatory
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleUnfreezeDialog}>
                Cancel
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.SubmitUnfreezeRequestSuccess}
              >
                Submit Unfreeze Request
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showWorkflowSelectDialog === true && (
          <Dialog title="Select Workflow" onClose={this.toggleWorkflowSelectDialog} width="300px">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <p className="subtitle-workflow" style={{ textAlign: 'center' }}>
                    Please select the applicable workflow to send for approval.
                  </p>
                </div>
                <div className="row">
                  <form className="k-form modified">
                    <div className="k-form-field">
                      {this.state.matchingWorkflowList.map((eachOption, i) => {
                        return (
                          <div className="field-mod">
                            <input
                              type="radio"
                              id={'' + eachOption.id}
                              name={'' + eachOption.id}
                              value={eachOption.id}
                              checked={this.state.selectedWorkFlowId === eachOption.id}
                              className="k-radio"
                              key={eachOption.id}
                              onChange={this.setSelectedWorkflowId.bind(this, eachOption.id)}
                            />
                            <label
                              key={eachOption.id}
                              htmlFor={'' + eachOption.id}
                              className="k-radio-label"
                            >
                              {eachOption.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleWorkflowSelectDialog}>
                No
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.setWorkflowToResources}
                disabled={!this.state.selectedWorkFlowId}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showAlreadyUnfreezeRequestSendResources === true &&
          this.state.alreadySendUnFreezeRequestResourceList &&
          this.state.alreadySendUnFreezeRequestResourceList.length > 0 && (
            <Dialog
              title="Information"
              onClose={this.toggleAlreadyUnfreezeRequestSendResourcesDialogClose}
              width="400px"
            >
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Spark will not process unfreeze request approvals for the following users as they
                are already unfrozen:
              </p>
              <div className="row">
                <form className="k-form modified">
                  <div className="k-form-field">
                    <ul>
                      {this.state.alreadySendUnFreezeRequestResourceList.map((eachOption, i) => {
                        return (
                          <li>
                            <label
                              key={eachOption.id}
                              htmlFor={'' + eachOption.id}
                              className="my-0"
                            >
                              {eachOption.displayName}
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </form>
              </div>

              <DialogActionsBar>
                <button
                  className="k-button"
                  onClick={this.toggleAlreadyUnfreezeRequestSendResourcesDialogClose}
                >
                  Cancel
                </button>
                <button
                  className="k-button modal-primary"
                  onClick={async () => {
                    if (
                      this.state.notAlreadySendUnFreezeRequestResourceList &&
                      this.state.notAlreadySendUnFreezeRequestResourceList.length > 0
                    ) {
                      await this.toggleAlreadyUnfreezeRequestSendResourcesDialogClose();
                      await this.toggleUnfreezeDialog();
                    } else {
                      this.toggleAlreadyUnfreezeRequestSendResourcesDialogClose();
                    }
                  }}
                >
                  OK
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.visible === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">
            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}
        <Loader loading={this.state.loader} />
        <NotificationGroup
          style={{
            right: 32,
            top: 58,
            alignItems: 'flex-end',
            flexWrap: 'wrap-reverse'
          }}
        >
          <Fade>
            {this.state.successAlert && (
              <Notification
                type={{
                  style: 'success',
                  icon: true
                }}
                closable={true}
                onClose={() => this.setState({ successAlert: false })}
              >
                <span>{this.state.successMsg}</span>
              </Notification>
            )}
          </Fade>
        </NotificationGroup>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

const Card = props => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const colorIndicator = props => {
    if (props.approvedStatus === approvedStatus.Pending_Approval.value) {
      return 'timesheet-none row';
    } else if (props.approvedStatus === approvedStatus.Approved.value) {
      return 'timesheet-green row';
    } else if (props.approvedStatus === approvedStatus.Rejected.value) {
      return 'timesheet-gray row';
    } else if (props.approvedStatus === approvedStatus.Auto_Generated.value) {
      return 'timesheet-light-blue row';
    } else if (props.approvedStatus === approvedStatus.Leave.value) {
      return 'timesheet-light-blue row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_General.value
    ) {
      return 'timesheet-blue row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_ESE.value
    ) {
      return 'timesheet-yellow row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_PMM.value
    ) {
      return 'timesheet-purple row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_RWK.value
    ) {
      return 'timesheet-orange row';
    }
  };

  const checkOveride = data => {
    if (data.allocationHours.length !== 0) {
      let times = props.data.allocationHours.filter(x => x.user === props.data.userId)[0];
      if (typeof times !== 'undefined') {
        if (times.isOverride) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div className="card my-2" id={'tooltip-' + props.cardData.cardId}>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={'tooltip-' + props.cardData.cardId}
        toggle={toggle}
        className="timecard-tooltip"
      >
        <div className="text-left">
          <div>
            {`Duration :${props.cardData.duration}`}{' '}
            {props.data.isShiftTask && props.cardData.FromDateTime !== null
              ? `(${props.cardData.FromDateTime} - ${props.cardData.ToDateTime})`
              : null}
          </div>
          <div>{`Comment : ${props.cardData.comment}`}</div>
          <div>{`Ticket ID : ${props.cardData.TicketId ? props.cardData.TicketId : ''}`}</div>
          {props.cardData.PMEdited ? (
            <div>{`Resource Entered Duration : ${props.cardData.UserEnterDuration}`}</div>
          ) : null}
          {props.cardData.PMEdited ? (
            <div>{`Resource Entered Comment : ${props.cardData.UserEnterComment}`}</div>
          ) : null}
          {props.cardData.PMEdited ? (
            <div>{`Resource Entered Ticket ID : ${props.cardData.UserEnterTicketId}`}</div>
          ) : null}
          <div>{`Work Location : ${props.cardData.WorkLocationName ? props.cardData.WorkLocationName : ''}`}</div>
          {props.cardData.approvedStatus === approvedStatus.Pending_Approval.value && (
            <div>Status : Approval Pending</div>
          )}
          {props.cardData.approvedStatus === approvedStatus.Approved.value && (
            <div> Status : Approved – Billable</div>
          )}
          {props.approvedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId ===
            approvedStatus.Approved_NB_General.value ? (
            <div> Status : Approved Non-Billable - General</div>
          ) : null}
          {props.approvedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_ESE.value ? (
            <div> Status : Approved Non-Billable - Estimation Issues</div>
          ) : null}
          {props.approvedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_PMM.value ? (
            <div> Status : Approved Non-Billable - Mismanagement</div>
          ) : null}
          {props.approvedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_RWK.value ? (
            <div> Status : Approved Non-Billable - Rework</div>
          ) : null}
          {props.cardData.approvedStatus === approvedStatus.Rejected.value && (
            <div> Status : Rejected</div>
          )}
          {props.cardData.approvedStatus === approvedStatus.Auto_Generated.value && (
            <div>Status : Automatic Approvals</div>
          )}
          {props.cardData.approvedStatus === approvedStatus.Leave.value && !props.cardData.IsPending && (
            <div>Status : Leave</div>
          )}
          {props.cardData.approvedStatus === approvedStatus.Leave.value && props.cardData.IsPending && (
              <div>Status : Leave - Pending Approval</div>
          )}
          {props.cardData.approvedStatus === approvedStatus.Approved.value ||
          props.cardData.approvedStatus === approvedStatus.Approved_NB.value ? (
            <div>Approved By : {props.cardData.ApprovedBy}</div>
          ) : null}
          {props.cardData.approvedStatus === approvedStatus.Rejected.value ? (
            <div>Rejected By : {props.cardData.ApprovedBy}</div>
          ) : null}
          {props.cardData.approvedStatus === approvedStatus.Rejected.value && (
            <div>Reject Reason : {props.cardData.RejectReason}</div>
          )}
          {props.cardData.ResonOfMove !== null && (
            <div>Reason to Move : {props.cardData.ResonOfMove}</div>
          )}
          {props.cardData.IsBillable === true && props.cardData.RateType === 5 ? (
            <div>
              Warning : This user has a non-billable allocation and should not be entering billable
              timesheets.
            </div>
          ) : null}
          {props.cardData.IsOverEightHour === true ? (
            <div>Warning : This user has entered more than 8 hours of billable work.</div>
          ) : null}
          {checkOveride(props.data) ? (
            <div>
              Warning : This user has utilized all the allocated billable time for the Project.
            </div>
          ) : null}
          <div>
            {`Time Entry Date : `}{' '}
            <Moment format="MM-DD-YYYY hh:mm:ss A">
               {props.cardData.createdAt}
            </Moment>
          </div>
        </div>
      </Tooltip>
      <div className="col-md-12">
        <div className={colorIndicator(props)}>
          <input
            type="checkbox"
            className="k-checkbox mr-2"
            name="tsact"
            checked={props.checked}
            disabled={
              props.cardData.approvedStatus === approvedStatus.Auto_Generated.value ||
              props.cardData.approvedStatus === approvedStatus.Leave.value
            }
            onClick={() => {
              props.checkeBoxHandler(props.index, props.taskId, props.date, props.data);
            }}
          />
          <span htmlFor="ts-activity" className="ts-act d-inline-block">
            {(props.cardData.IsBillable === true && props.cardData.RateType === 5) ||
            props.cardData.IsOverEightHour === true ||
            checkOveride(props.data) ? (
              <span className=" d-inline-block" style={{ color: 'red' }}>
                {props.cardData.duration}
              </span>
            ) : (
              <span className=" d-inline-block">{props.cardData.duration}</span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
export default TimeSheetAppovalDaily;
