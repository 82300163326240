import React, { Component } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Redirect } from 'react-router';
import { saveExchangeRate, updateExchangeRate } from './ExchangeRateService';
import { getCurrencies } from '../common/CommonService';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import MonthYearPicker from '../../integration/components/calendar/monthYearPicker/MonthYearPicker';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../../src/integration/LoggerService";
import { LOG_TYPES } from "../../../src/integration/IntegrationEnums";
import $ from "jquery";

class ExchangeRateCreate extends Component {
  default;

  isMount = false;
  popupSet = {
    width: 'auto'
  };

  constructor(props) {
    super(props);
    this.state = {
      exchangeRateToolTipOpen: false,
      toExchangeRate: false,

      visible: false,
      success: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      isEnableCreate: false,
      isEnableUpdate: false,
      isDisableCombo: false,
      isDisableEditMode: false,
      isDisableSave: false,
      isSaveButtonClicked: false,
      isFormDirty: false,
      editID: null,
      data: [],
      MonthYear: ''
    };

  }

  setHeaderTitle = async () => {
    if (this.props.location.action === 'create') {
      if (this.props.onHeaderTitleChange) {
        this.props.onHeaderTitleChange('Create Exchange Rate');
      }
    } else {
      if (this.props.onHeaderTitleChange) {
        this.props.onHeaderTitleChange('Edit Exchange Rate');
      }
    }
  };

  /**
   * getCurrencies()
   * Retrieves all the currencies
   * @returns {array} object array of currencies
   */
  async componentDidMount() {

    $(document).ready(function () {
      $("div.title-remove > div > span > span > span:last-child").removeAttr("title");
    });

    window.scrollTo(0, 0);
    this.isMount = true;
    this.setHeaderTitle();
    if (this.props.location.action === 'edit') {
      this.updateExchangeRateDetails(this.props.location.exchangerate);
      if (this.isMount) {
        this.setState({
          isEditMode: true
        });
      }

      await getCurrencies()
        .then(response => {
          if (this.isMount) {
            this.setState({
              currencyData: response.data.slice(0)
            });
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    } else {
      await getCurrencies()
        .then(response => {
          const updatedCostCard = response.data.map(dataItem => Object.assign({ exchangeRateInUSD: 0 }, dataItem));
          if (this.isMount) {
            this.setState({
              currencyData: response.data.slice(0),
              data: updatedCostCard.slice(0)
            });
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  render() {
    if (this.state.toExchangeRate === true) {
      return <Redirect to="/admin/ExchangeRate/ExchangeRate" />;
    }

    return (
      <React.Fragment>
        <div className="main-card">
          <div className="main-heading">Exchange Rate Details</div>
          <div className="main-seperator" />
          <div className="row">
            <div className="col-md-6">
              <div className="row">

                <div className="col-md-5">
                  <div className="d-block">
                    <label className="mandatory">Year and Month selection:</label>
                  </div>
                  <div className="d-block">
                    <div className="title-remove">
                      <MonthYearPicker
                        value={this.state.MonthYear}
                        onChange={this.handleOnChangeMonthYear}
                        required={true}
                        name="MonthYear"
                        format={"MMM yyyy"}
                        formatPlaceholder={{ month: 'MMM', year: 'YYYY' }}
                      />
                      {this.state.isErrorMessageVisible === true ? (
                        <span className={this.validateProperty(this.state.MonthYear)}>
                          Year and Month selection cannot be empty
                          </span>
                      ) : null}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <br></br><br></br>

          <div className="row">
            <div className="col-md-6">
              <Grid
                resizable
                data={this.state.data.map((item) =>
                  Object.assign({
                    inEdit: item.id === this.state.editID
                  }, item)
                )}
                editField="inEdit"
                onRowClick={this.rowClick}
                onItemChange={this.currencyConversionItemChange}
              >
                <Column field="Code" title="Currency Type" editable={false} />
                <Column
                  field="exchangeRateInUSD"
                  title="Exchange rate in USD"
                  editor="numeric"
                />
              </Grid>
            </div>
          </div>


          <div className="row mt-3">
            <div className="col-md-12 btn-align-right">
              <Button
                primary={true}
                disabled={this.state.isDisableSave}
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
              <Button onClick={this.onClickCancel}>
                Cancel
              </Button>
            </div>
          </div>
          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success" />
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error" />
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.navigateBack}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog} >
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.showCancelConfirmation === true && (
              <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contain unsaved data. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.toggleCancelConfirmDialog}>
                    No
                  </button>
                  <button className="k-button active modal-primary" onClick={this.navigateBack}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }


  rowClick = (e) => {
    if (this.isMount) {
      this.setState({
        editID: e.dataItem.id
      });
    }
  };

  currencyConversionItemChange = (e) => {
    const data = this.state.data.slice();
    const index = data.findIndex(d => d.id === e.dataItem.id);
    let cellValue = 0;
    if (e.value != null) {
      cellValue = e.value;
    }
    data[index] = { ...data[index], [e.field]: cellValue };
    if (this.isMount) {
      this.setState({
        isFormDirty: true,
        data: data
      });
    }
  };

  handleOnChangeMonthYear = event => {
    const monthYear = event.value;
    const field = event.name;
    if (this.isMount) {
      this.setState(
        {
          [field]: monthYear,
          MonthYear: monthYear,
          Month: 0,
          Year: 0,
          isFormDirty: true
        },
        () => {
          console.log(this.state);
        }
      );
    }
    if (monthYear) {
      if (this.isMount) {
        this.setState(
          {
            Month: monthYear.getMonth() + 1,
            Year: monthYear.getFullYear()
          },
          () => {
            console.log(this.state);
          }
        );
      }
    }
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  navigateBack = event => {
    if (this.isMount) {
      this.setState({
        toExchangeRate: true
      });
    }
  };

  updateExchangeRateDetails = exchangerate => {
    if (this.isMount) {
      this.setState({
        isDisableEditMode: true
      });
    }
    if (exchangerate) {
      this.isEnableCreate = true;
      this.isEnableUpdate = false;

      const months = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec'
      };
      const date = `${months[exchangerate.Month]} ${exchangerate.Year}`;

      const currencyConversionList_ = [];
      exchangerate.CurrencyConversionDetails.forEach(function (data) {
        const currencyConversionObject = {
          CodeId: data.Currency.id,
          Code: data.Currency.Code,
          exchangeRateInUSD: data.RatePerUSD,
          id: data.id
        };
        currencyConversionList_.push(currencyConversionObject);
      });

      currencyConversionList_.map((item) =>
        Object.assign({
          inEdit: item.id === this.state.editID
        }, item));

      if (this.isMount) {
        this.setState({
          Year: exchangerate.Year,
          Month: exchangerate.Month,
          MonthYear: date,
          id: exchangerate.id,
          data: currencyConversionList_
        });
      }
    } else {
      return null;
    }

  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    if (!this.validation()) {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: true,
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: false
        });
      }
      if (this.props.location.action === 'edit') {
        this.updateExchangeRate(event);
      } else {
        this.createExchangeRate(event);
        if (this.isMount) {
          this.setState({
            isDisableSave: true
          });
        }
      }
    }
  };

  /**
   * saveExchangeRate()
   * Create a new exchange rate
   * @param exchangeRate
   * @returns {array} saved exchange rate object
   */
  createExchangeRate = event => {
    const currencyConversions = this.state.data;
    const currencyConversionList = [];

    currencyConversions.forEach(function (data) {
      const currencyConversionDetail = {
        CurrencyId: data.id,
        RatePerUSD: data.exchangeRateInUSD,
        createdAt: new Date(),
        updatedAt: new Date()
      };
      currencyConversionList.push(currencyConversionDetail);
    });

    const exchangeRate = {
      Month: this.state.Month,
      Year: this.state.Year,
      createdAt: new Date(),
      updatedAt: new Date(),
      IsActive: true,
      UpdatedUser: window.LOGGED_USER.displayName,
      CurrencyConversionDetails: currencyConversionList
    };


    saveExchangeRate(exchangeRate)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(`The Exchange Rate is successfully created`, 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            isDisableSave: false,
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  /**
   * updateExchangeRate()
   * Updates the current exchange rate
   * @param exchangeRate
   * @param exchangeRateId
   * @returns {array} status (0 or 1)
   */
  updateExchangeRate = event => {
    const currencyConversion = {
      Month: this.state.Month,
      Year: this.state.Year,
      id: this.state.id,
      CurrencyConversionDetails: this.state.data,
      UpdatedUser: window.LOGGED_USER.displayName
    };

    updateExchangeRate(currencyConversion, this.state.id)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog('The Exchange Rate has been successfully updated', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  validation = () => {
    if (this.validateProperty(this.state.MonthYear).includes('error')) {
      return false;
    } else {
      return true;
    }
  };

  toggleCancelConfirmDialog = () => {
    if (this.isMount) {
      this.setState({
        showCancelConfirmation: !this.state.showCancelConfirmation
      });
    }
  };
}

export default ExchangeRateCreate;
