import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;
const timeSheetApproverRouter = apiUrl + '/timeSheetManagement/timeSheetApproval/';


/**
 * checkCanApproveTimeCard - check Can Approve Time Card
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
 export  function checkCanApproveTimeCard(parms){
    const endPoint = timeSheetApproverRouter + 'checkCanApproveTimeCard/' + parms;
    return  http.get(endPoint);
}
