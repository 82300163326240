/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Button } from '@progress/kendo-react-buttons';

class RowCellDuplicateButton extends Component {
    
    render() {
        return (
            <td>
                <Button
                    className="rounded-btn remove"
                    disabled={this.props.disabled}
                    onClick={this.handleButtonClick}
                >
                    <span className="k-icon k-i-backward-elemen" />
                </Button>
            </td>
        );
    }
}

export default RowCellDuplicateButton;
