import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem, PanelBarUtils, Menu, MenuItem, MenuItemModel, MenuItemLink, MenuItemArrow, Splitter } from '@progress/kendo-react-layout';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Redirect } from 'react-router';
import ReactDOM from 'react-dom';
import RowCellComboBox from '../../projectManagement/cmSheet/component/common/RowCellComboBox';
import RowCellDropDown from '../../projectManagement/cmSheet/component/common/RowCellDropDown';
import RowCellDatePicker from '../../projectManagement/cmSheet/component/common/RowCellDatePicker';
import RowCellNumericTextBox from '../../projectManagement/cmSheet/component/common/RowCellNumericTextBox';
import { Tooltip } from 'reactstrap';

import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';

import {
  getProjectById,
  getChangeRequestById,
  getCMSIntegrated,
  retrieveConversionRateIntegrated
} from '../../integration/ProjectManagementService';
import { getResourceRequestResourcesByIdAndVersion } from '../ResourceService';
import {
    updateResourceRequestResourceDetails,
    createNewPMResourceRequest,
    presalesEndDateChange
} from './ResourceRequestActivationService'
import { getAllDesignations } from '../../masterData/designation/DesignationService';
import {
  getCostIntegrated,
  getHolidaysIntegrated, getRegionCurrencyIntegrated,
  getResourceRegionsIntegrated
} from '../../integration/MasterDataService';
import { getRateTypes } from '../../masterData/rateCard/RateCardService';
import {filterBy} from "@progress/kendo-data-query";
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import {formatNumber, getFormattedDate, isWeekend, reStructureDate} from "../../integration/CommonUtils";
import { saveAuditTrail } from '../../integration/auditTrail/AuditTrailService';
import moment from 'moment';
import Loader from "../../integration/components/Loader";
import { updateProject } from '../../projectManagement/project/ProjectService';
import { updateChangeReq } from '../../projectManagement/changeRequest/ChangeRequestService';
import { getCostDetails } from '../../masterData/costCard/CostCardService';
import { getPaymentMethods } from '../../masterData/common/CommonService';

const CUSTOM_RATE = 'CUSTOM_RATE';
const OnsiteStatusValuesEnum = [{ id: 1, Name: 'Onsite' }, { id: 2, Name: 'Offshore' }];


class ResourceRequestActivation extends Component {
  isMount = false;
  comboBoxPlaceHolder = 'Please Select';

  constructor(props) {
    super(props);

    this.state = {
      Type: '',
      Project: null,
      ChangeRequest: null,
      StartDate: null,
      StartDateMin: null,
      resourceAllocations: null,

      DesignationsAll: [],
      RegionsAll: [],
      RateTypes: [],

      disableAddPMResourceBtn: false,
      disableResourceRowEdit: false,
      disableResourceRowEditOnAdd: false,
      disableConverToBillableBtn: false,

      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      showSuccessImage: false,
      showErrorImage: false,

      hasUnSavedData: false,
      navigateToSearchScreen: false,
      isAutoPopulateRateType: false,

      projectName: false,
      uniqueId: false,
      projectNameToolTipOpen: false,
      uniqueIdToolTipOpen: false,
      crName: false,
      showCRField: false,
      id: null,

      isErrorEffortDays: false,
      isErrorEffortHours: false,
      isErrorAllocationPercentage: false,
      isLoading: true,
      paymentMethodsAll: [],
      disablePaymentMethod: false

    };
  }

  async componentDidMount() {
      this.setState({
          isLoading: true
      });
    this.setHeaderTitle();

    this.isMount = true;
    await this.populateInitialMasterData();
    await this.setInitialData();
      this.setState({
          isLoading: false
      });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async () => {
    if (this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Activate Project/CR');
    }
  };

  populateInitialMasterData = async () => {
    const resDesg = await getAllDesignations();
    const resReg = await getResourceRegionsIntegrated();
    const resRT = await getRateTypes();
    const paymentMethods = await getPaymentMethods();

        let rateTypes = [];
        let CR = this.props.location.ChangeRequest
        let bool = true;
        let cmsheet = []

        if (this.props.location.Type === 'Project') {

            if (this.props.location.Project) {
                const project = this.props.location.Project;

        if (project && project.CMSheets && project.CMSheets.length > 0) {
          await getResourceRequestResourcesByIdAndVersion(
            project.CMSheets[0].id,
            project.CMSheets[0].Version
          ).then(async result => {
            cmsheet = result.data;
            if (cmsheet.length !== 0) {
              bool = false;
            }
          });
        }

        this.setState({
          projectName: project.ProjectName,
          uniqueId: project.UniqueId,
          id: this.props.location.ProjectId
        });
      }
    } else {
      if (this.props.location.ChangeRequest) {
        const changeReq = this.props.location.ChangeRequest[0];
        if (changeReq && changeReq.CMSheets && changeReq.CMSheets.length > 0) {
          await getResourceRequestResourcesByIdAndVersion(
            changeReq.CMSheets[0].id,
            changeReq.CMSheets[0].Version
          ).then(async result => {
            cmsheet = result.data;
            if (cmsheet.length !== 0) {
              bool = false;
            }
          });
        }
        this.setState({
          crName: changeReq.CRName,
          uniqueId: changeReq.UniqueId,
          showCRField: true,
          projectName: this.props.location.selectedProject,
          id: changeReq.id
        });
      }
    }

        if(bool === true){
            if (resRT.data && resRT.data.length > 0) {
                for (const rateType of resRT.data) {
                    if (rateType.Code !== CUSTOM_RATE) {
                        rateType.Rate = 0;
                        rateType.Text = rateType.Name + ' - 0.00';
                    } else {
                        rateType.Text = rateType.Name;
                    }
                    rateTypes.push(rateType);
                }
            }
        }
        else{
            await this.setState({
                isAutoPopulateRateType:true
            })
        }

    await this.setState({
      DesignationsAll: resDesg.data,
      RegionsAll: resReg.data,
      RateTypes: rateTypes,
      paymentMethodsAll: paymentMethods.data
    });
  };

    setInitialData = async () => {

        this.setState({
            isLoading: true
        });

        if (this.props.location.Type === 'Project') {
            let startDate = null
            if (this.props.location.Project) {
                const project = this.props.location.Project;

                if (project && project.CMSheets && project.CMSheets.length>0) {
                    const cmsheet = await this.populateCMSheetVersionsAndManager(project.CMSheets[0]);
                }


                let startDateMin = new Date();

        if (this.props.location.Project.ProjectConversionStartDate)   {

            let startd = moment(reStructureDate(this.props.location.Project.ProjectConversionStartDate)).format('YYYY-MM-DD')
            let newDate = moment(new Date()).format('YYYY-MM-DD')

            if ( startd < newDate) {
                startDate = null;
            } else {
                startDate = reStructureDate(this.props.location.Project.ProjectConversionStartDate);
                startDate.setHours(0, 0, 0, 0);
            }


            if (startd < newDate) {
                startDateMin = reStructureDate(startd);
            } else {
                startDateMin = new Date();
            }
        }

        else{
            startDate = null;
            startDateMin = new Date();
        }


                await this.setState({
                    Type: this.props.location.Type,
                    Project: this.props.location.Project,
                    StartDate: startDate,
                    StartDateMin: startDateMin,
                    PaymentMethodId:this.props.location.Project.PaymentMethod
                });
            }
        }
        else {
            if (this.props.location.ChangeRequest) {
                let startDate = null

                const changeReq = this.props.location.ChangeRequest[0];
                if (changeReq && changeReq.CMSheets && changeReq.CMSheets.length>0) {
                    await this.populateCMSheetVersionsAndManager(changeReq.CMSheets[0]);
                }

        let startDateMin = new Date();
        if (this.props.location.ChangeRequest[0].ChangeRequestConversionStartDate) {
            let startd = moment(reStructureDate(this.props.location.ChangeRequest[0].ChangeRequestConversionStartDate)).format('YYYY-MM-DD')
            let newDate = moment(new Date()).format('YYYY-MM-DD')

            if ( startd < newDate) {
                startDate = null;
            } else {
                startDate = reStructureDate(this.props.location.ChangeRequest[0].ChangeRequestConversionStartDate);
                startDate.setHours(0, 0, 0, 0);
            }

          if (
            new Date(this.props.location.ChangeRequest[0].ChangeRequestConversionStartDate) <
            new Date()
          ) {
            startDateMin = reStructureDate(changeReq.ChangeRequestConversionStartDate);
          } else {
            startDateMin = new Date();
          }

        } else {
          startDate = null;
          startDateMin = new Date();
        }

                await this.setState({
                    Type: this.props.location.Type,
                    Project: this.props.location.ChangeRequest,
                    ChangeRequest: changeReq,
                    StartDate: startDate,
                    StartDateMin: startDateMin,
                    PaymentMethodId:this.props.location.ChangeRequest.PaymentMethod
                });
            }
        }

        this.setState({
            isLoading: false
        });

    };

  handleStartdateChange = async event => {
    let startDate = event.target.value;



    await this.setState({
      StartDate: startDate,
      hasUnSavedData: true
    });



    if (this.state.resourceAllocations && this.state.resourceAllocations.length > 0) {
      this.state.resourceAllocations.forEach(function(resource) {
        resource.StartDateMin = startDate;



        if (resource.StartDate < startDate) {
          resource.StartDate = null;
          resource.DateStartDate = null;
        }
      });
    }
  };

  reStructureDate=(date)=>{
    if(date){
      return new Date([moment(date).format('YYYY'), moment(date).format('M'), moment(date).format('D')].join('/'))
    }else{
      return date
    }
   }

  populateCMSheetVersionsAndManager = cmSheet => {
    if (cmSheet) {
      getCMSIntegrated(cmSheet.id, cmSheet.Version).then(res => {
        const cmsheetData = res.data;
        const list = [];
        let version = cmsheetData.Version;
        while (version >= 0) {
          const txtVersion = cmsheetData.MajorVersion + '.' + version;
          const item = { Text: txtVersion, Version: version };
          list.push(item);
          version = version - 1;
        }

        this.setState({
          cmSheetVersions: list,
          selectedVersion: list[0]
        });
      });


      getResourceRequestResourcesByIdAndVersion(cmSheet.id, cmSheet.Version).then(async result => {
        const cmsheetData = result.data;
          const resRT = await getRateTypes();
        const tempResources = [];
        const projRoles = this.state.DesignationsAll.slice().filter(resource =>
          resource.Name.includes('Manager')
        );
        const allDesignations = this.state.DesignationsAll.slice().filter(resource =>
          resource.Name.includes('Manager')
        );
        const regionsAll = this.state.RegionsAll.slice();
        const rateTypes = this.state.RateTypes.slice();
        const paymentMethodsAll = this.state.paymentMethodsAll.slice();
        let i = 0;


        if (cmsheetData && cmsheetData.length > 0) {
          const startDateProjOrCR = this.state.StartDate;
          const isAutoPopulateRateType = this.state.isAutoPopulateRateType;
          cmsheetData.forEach(function(resource) {
            if (resource.dataValueProjectRole) {
              if (resource.dataValueProjectRole.Name.includes('Manager')) {
                // INDEX
                resource.index = i;
                i++;

                // RESOURCE PROJ ROLES
                resource.ProjectRoles = projRoles.filter(obj => obj.IsProjectRole === true);
                if (resource.ProjectRole) {
                  const filterRole = resource.ProjectRoles.filter(
                    obj => obj.id === resource.ProjectRole
                  );
                  if (filterRole.length > 0) {
                    resource.dataValueProjectRole = filterRole[0];
                  }
                }

                // RESOURCE DESIGNATIONS
                if (resource.dataValueProjectRole) {
                  resource.CostDesignations = allDesignations.filter(
                    obj =>
                      obj.CategoryId === resource.dataValueProjectRole.CategoryId ||
                      obj.Name === 'Outsourced'
                  );
                  if (resource.Designation) {
                    const filterCostDesignation = resource.CostDesignations.filter(
                      obj => obj.id === resource.Designation
                    );
                    if (filterCostDesignation.length > 0) {
                      resource.dataValueCostDesignation = filterCostDesignation[0];
                    }
                  }
                }

                // RESOURCE REGIONS
                resource.ResourceRegions = regionsAll;
                if (resource.ResourceRegion) {
                  const filterResourceRegion = resource.ResourceRegions.filter(
                    obj => obj.id === resource.ResourceRegion
                  );
                  if (filterResourceRegion.length > 0) {
                    resource.dataValueResourceRegion = filterResourceRegion[0];
                  }
                }

                // RESOURCE ONSITE / OFFSHORE STATUS
                resource.OnsiteStatuses = OnsiteStatusValuesEnum;
                if (resource.OnsiteStatus === 'Onsite') {
                  resource.dataValueOnsiteStatus = OnsiteStatusValuesEnum[0];
                } else if (resource.OnsiteStatus === 'Offshore') {
                  resource.dataValueOnsiteStatus = OnsiteStatusValuesEnum[1];
                } else {
                  resource.dataValueOnsiteStatus = null;
                }
                resource.PaymentMethodAll = paymentMethodsAll.filter(obj => obj.Name !== 'Hybrid');
                if (!resource.PaymentMethod) {
                  resource.PaymentMethod = resource.Type === 'Project' ? resource.Project.PaymentMethod : resource.ChangeRequest.PaymentMethodId;
                  resource.dataValuePaymentMethod = paymentMethodsAll.filter(obj => obj.id === resource.PaymentMethod)[0];
                }

                // ALLOCATION RATE TYPE

                                if(isAutoPopulateRateType === true){
                                    if (resource.dataValueRateType.Code === 'CUSTOMER_RATE') {
                                        //resource.dataValueRateType.Rate = customerRate;
                                        resource.dataValueRateType.Text = resource.dataValueRateType.Name + ' - ' + formatNumber(resource.RateValue);
                                        //rateType.RateCardId = customerRateCardId;
                                    }
                                    if (resource.dataValueRateType.Code === 'REGION_RATE') {
                                        //resource.dataValueRateType.Rate = regionRate;
                                        resource.dataValueRateType.Text = resource.dataValueRateType.Name + ' - ' + formatNumber(resource.RateValue);
                                        //rateType.RateCardId = regionRateCardId;
                                    }
                                    if (resource.dataValueRateType.Code === 'LAST_CUS_RATE') {
                                        //resource.dataValueRateType.Rate = lastCustomRate;
                                        resource.dataValueRateType.Text = resource.dataValueRateType.Name + ' - ' + formatNumber(resource.RateValue);
                                        //rateType.LastCustomerRateProject = lastCustomRateProjectName;
                                    }
                                    if (resource.dataValueRateType.Code === 'APPROVED_PROJ_RATE') {
                                        //resource.dataValueRateType.Rate = lastApprovedProject_Rate;
                                        resource.dataValueRateType.Text = resource.dataValueRateType.Name + ' - ' + formatNumber(resource.RateValue);
                                    }
                                    if (resource.dataValueRateType.Code === 'CUSTOM_RATE') {
                                        resource.dataValueRateType.Text = resource.dataValueRateType.Name;
                                    }
                                    if (resource.dataValueRateType.Code === 'PROJECT_APPROVED_RATE') {
                                      resource.dataValueRateType.Text = resource.dataValueRateType.Name + ' - ' + formatNumber(resource.RateValue);
                                    }
                                    // if (resource.dataValueRateType.Code === 'ADMIN_RATE') {
                                    //     //resource.dataValueRateType.Rate = lastApprovedProject_Rate;
                                    //     resource.dataValueRateType.Text = resource.dataValueRateType.Name + ' - ' + formatNumber(resource.RateValue);
                                    // }
                                                //resource.dataValueRateType.Text = resource.dataValueRateType.Name + ' - ' + resource.RateValue;
                                        }
                                else{
                                    resource.RateTypes = rateTypes;
                                    if (resource.RateType) {
                                        const filterRateType = resource.RateTypes.filter(obj => obj.id === resource.RateType);
                                        if (filterRateType.length > 0) {
                                            resource.dataValueRateType = filterRateType[0];
                                        }
                                    }
                                }

                // ALLOCATION START DATE
                if (resource.Status === 'RES_ALLOC_PENDING') {
                  resource.inEdit = false;

                  if (resource.StartDate) {
                    resource.DateStartDate = new Date(resource.StartDate);
                    resource.StartDate = new Date(resource.StartDate);
                  }
                } else {
                  resource.inEdit = true;
                  if (resource.StartDate && new Date(resource.StartDate) >= startDateProjOrCR) {
                    resource.DateStartDate = new Date([moment(resource.StartDate).format('YYYY'), moment(resource.StartDate).format('M'), moment(resource.StartDate).format('D')].join('/'));
                    resource.StartDate = new Date([moment(resource.StartDate).format('YYYY'), moment(resource.StartDate).format('M'), moment(resource.StartDate).format('D')].join('/'));
                  } else {
                    resource.DateStartDate = null;
                    resource.StartDate = null;
                  }
                }
                resource.StartDateMin = new Date(startDateProjOrCR);
                tempResources.push(resource);
              }
            }
          });

          if (tempResources && tempResources.length > 0) {
            this.setState({
              resourceAllocations: tempResources,
              disableAddPMResourceBtn: true,
              disableResourceRowEdit: true
            });
          }

          if (tempResources && tempResources.length > 1) {
            tempResources.map(dataItem => Object.assign({ selected: false }, dataItem));
            this.setState({
              resourceAllocations: tempResources
            });
          }

          if (tempResources && tempResources.length > 0) {
            const requestedResources = tempResources.filter(resource => resource.Status === 7);
          }

          const existingResources = tempResources.length;
          const resourcesWithPendingApprovals = tempResources.filter(
            resource => resource.Status === 'RES_ALLOC_PENDING'
          ).length;

          if (existingResources === resourcesWithPendingApprovals) {
            this.setState({
              disableConverToBillableBtn: true
            });
          }
        }

      });
    }
  };

  handleChangeComboBox = async (event, dataItem) => {
    const allocations = this.state.resourceAllocations.slice();
    const index = dataItem.index;
    const field = event.target.name;
    const value = event.target.value;

    if (index > -1) {
      switch (field) {
        case 'ProjectRole': {
          allocations[index].ProjectRole = value ? value.id : null;
          allocations[index].dataValueProjectRole = value;

          this.populateDesignations(allocations[index]);
          break;
        }
        case 'CostDesignation': {
          allocations[index].Designation = value ? value.id : null;
          allocations[index].dataValueCostDesignation = value;

          if (value && value.Name === 'Outsourced') {
            this.setState(
              {
                OutsourcedCostDataItemIndex: index
              },
              () => {}
            );
          } else {
            allocations[index].CostPerOutSourcedResource = 0;
          }

          break;
        }
        case 'OnsiteStatus': {
          allocations[index].OnsiteStatus = value ? value.Name : null;
          allocations[index].dataValueOnsiteStatus = value;
          break;
        }
        case 'RateType': {
          allocations[index].RateType = value && value.id ? value.id : null;
          allocations[index].dataValueRateType = value && value.id ? value : null;

          break;
        }
        case 'ResourceRegion': {
          allocations[index].ResourceRegion = value ? value.id : null;
          allocations[index].dataValueResourceRegion = value;

          break;
        }
        case 'PaymentMethod': {
          allocations[index].PaymentMethod = value ? value.id : null;
          allocations[index].dataValuePaymentMethod = value;

          break;
        }
        default: {
          break;
        }
      }
    }

    this.setState(
      {
        resourceAllocations: allocations
      },
      () => {}
    );
  };

  filterChangeCombo = (event, dataItem) => {
    clearTimeout(this.timeout);

    const field = event.target.name;
    const filter = event.filter;

    this.timeout = setTimeout(() => {
      switch (field) {
        case 'ProjectRole': {
          const projRolesAll = this.state.DesignationsAll.filter(
            obj => obj.IsProjectRole === true && obj.Name.includes('Manager')
          );
          dataItem.ProjectRoles = this.filterComboData(filter, projRolesAll);
          break;
        }
        case 'CostDesignation': {
          if (dataItem.dataValueProjectRole) {
            const allDesignations = this.state.DesignationsAll.filter(
              obj =>
                (obj.CategoryId === dataItem.dataValueProjectRole.CategoryId ||
                  obj.Name === 'Outsourced') &&
                obj.Name.includes('Manager')
            );
            dataItem.CostDesignations = this.filterComboData(filter, allDesignations);
          }
          break;
        }
        case 'ResourceRegion': {
          dataItem.ResourceRegions = this.filterComboData(filter, this.state.RegionsAll);
          break;
        }
        default: {
          break;
        }
      }

      this.setState({
        resourceAllocations: [...this.state.resourceAllocations]
      });
    }, 500);
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  populateDesignations = dataItem => {
    if (dataItem.ProjectRole) {
      dataItem.CostDesignations = this.state.DesignationsAll.slice().filter(
        obj =>
          (obj.CategoryId === dataItem.dataValueProjectRole.CategoryId ||
            obj.Name === 'Outsourced') &&
          obj.Name.includes('Manager')
      );
      const filterData = dataItem.CostDesignations.filter(
        obj => obj.id === dataItem.dataValueProjectRole.id
      );
      if (filterData.length > 0) {
        dataItem.dataValueCostDesignation = filterData[0];
        dataItem.Designation = dataItem.dataValueCostDesignation.id;
      }
    } else {
      dataItem.dataValueCostDesignation = null;
      dataItem.Designation = null;
      dataItem.CostDesignations = [];
    }
  };

  handleVersionChange = event => {
    const value = event.target.value;
    const version = value.Version;
  };

  onAddResourceRequestPMResource = async () => {
    const resourceRequestResourceTempArray = [];
    const resRT = await getRateTypes();
      this.setState({
          RateTypes:resRT.data
      });

    const filterRateType = this.state.RateTypes.filter(obj => obj.Code === 'ADMIN_RATE');
      if(filterRateType[0]){
          filterRateType[0].Rate = 0;
          filterRateType[0].Text = filterRateType[0].Name;

      }

    let paymentMethod = null;
    let projectOrCrId = null;
    let cmsheetId = null;
    let cmsheetVersion = null;
    let project = null;
    let dataValuePaymentMethod = null;
    let changeRequest = null;
    let disablePaymentMethod = false;
    if (this.state.Type && this.state.Type === 'Project') {
      projectOrCrId = this.state.Project.id;
      cmsheetId = this.state.Project.CMSheets ? this.state.Project.CMSheets[0].id : null;
      cmsheetVersion = this.state.Project.CMSheets ? this.state.Project.CMSheets[0].Version : null;
      project = this.state.Project;
      if (project.PaymentMethodRecord && project.PaymentMethodRecord.Name !== "Hybrid") {
        paymentMethod = this.state.Project.PaymentMethod;
        dataValuePaymentMethod = this.props.location.Project.PaymentMethod
        ? this.props.location.Project.PaymentMethodRecord
        : null;
        disablePaymentMethod = true;
      }
    } else {
      projectOrCrId = this.state.ChangeRequest.id;
      cmsheetId = this.state.ChangeRequest.CMSheets
        ? this.state.ChangeRequest.CMSheets[0].id
        : null;
      cmsheetVersion = this.state.ChangeRequest.CMSheets
        ? this.state.ChangeRequest.CMSheets[0].Version
        : null;
      changeRequest = this.state.ChangeRequest;
      if (changeRequest.PaymentMethod && changeRequest.PaymentMethod.Name !== "Hybrid") {
        paymentMethod = this.state.ChangeRequest.PaymentMethodId;
        dataValuePaymentMethod = this.props.location.ChangeRequest[0].PaymentMethodId
        ? this.props.location.ChangeRequest[0].PaymentMethod
        : null;
        disablePaymentMethod = true;
      }
    }

    let paymentMethodAll = this.state.paymentMethodsAll.filter(obj => obj.Name !== 'Hybrid');

    const newItem = {
      index: 0,
      PhaseId: null,
      SubPhaseId: null,
      dataValuePhase: null,
      dataValueSubPhase: null,
      dataValueProjectRole: null,
      dataValueCostDesignation: null,
      dataValueResourceRegion: null,
      dataValueOnsiteStatus: null,
      OnsiteStatus: null,
      FullProject: false,
      StartDate: null,
      StartDateMin: this.state.StartDate,
      endDate: null,
      DateStartDate: null,
      EndDate: null,
      DatesDisabled: false,
      dataValuePaymentMethod: dataValuePaymentMethod,
      PaymentMethod: paymentMethod,
      AllocationPercentage: 100,
      AllocationHours: 0,
      RateType: 5,
      RateValue: 0,
      RateValueDisabled: true,
      NoOfResources: 1,
      TotalCost: 0,
      TotalRevenue: 0,
      TotalEffortDays: 0,
      EffortDays: 0,
      Status: 2,
      EffortHours: 0,
      CostToRevenueRatio: 0,
      inEdit: true,
      ProjectRoles: this.state.DesignationsAll.filter(resource =>
        resource.Name.includes('Manager')
      ),
      CostDesignations: [],
      ResourceRegions: this.state.RegionsAll.slice(),
      RateTypes: JSON.parse(JSON.stringify(this.state.RateTypes)),
      dataValueRateType: filterRateType[0],
      OnsiteStatuses: OnsiteStatusValuesEnum,
      CMSheetId: cmsheetId,
      CMSheetVersion: cmsheetVersion,
      Type: this.state.Type,
      ProjectId: projectOrCrId,
      Project: project,
      ChangeRequest: changeRequest,
      PaymentMethodAll: paymentMethodAll
    };

    await resourceRequestResourceTempArray.push(newItem);

    await this.setState({
      resourceAllocations: resourceRequestResourceTempArray,
      disableAddPMResourceBtn: true,
      disableResourceRowEditOnAdd: true,
      disableConverToBillableBtn: false,
      hasUnSavedData: true,
      disablePaymentMethod: disablePaymentMethod
    });

  };

  itemChange = async event => {
    const value = event.value;
    const name = event.field;
    const dataItem = event.dataItem;
    this.setState({
      isLoading: true
    });
    var decimal = /^[-+]?[0-9]+\.[0-9]+$/;
    switch (name) {
      case 'DateStartDate':
        await this.handleDatePickerChange(event);
        break;
      case 'EndDate':
        await this.handleDatePickerChange(event);
        break;
      case 'AllocationPercentage':
        dataItem[name] = value;
        if (!value || value === '0') {
          dataItem.AllocationPercentage = 0;
          this.setState({ isErrorAllocationPercentage: true });
        } else {
          this.setState({ isErrorAllocationPercentage: false });
        }
        if (dataItem.EffortDays >= 0) {
          let countryId = null;
          let totalEffortDays = null;
          let num = dataItem.EffortDays * 8;
          let roundoff = num.toFixed(2);

          totalEffortDays = (dataItem.EffortDays /(dataItem.AllocationPercentage /100));

          if (dataItem.OnsiteStatus === 'Onsite') {
              countryId = dataItem.Type === "Project" ? dataItem.Project.CountryId : dataItem.ChangeRequest.CountryId;
          } else {
              countryId = dataItem.dataValueResourceRegion ? dataItem.dataValueResourceRegion.DefaultCountryId : null;
          }

          if (dataItem.StartDate) {
            let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays,moment(dataItem.StartDate).format('YYYY-MM-DD'),countryId);

            dataItem.endDate = moment(endDate).format('MM-DD-YYYY');
            dataItem.EndDate = moment(endDate).format('MM-DD-YYYY');

            dataItem.EffortHours = roundoff;
          } else {
            dataItem.endDate = null;
            dataItem.EndDate = null;
          }
        }
        break;
      case 'NoOfResources':
        dataItem[name] = value;
        let resourceCount = 0;
        if (value > 0) {
          resourceCount = Number(value).toFixed(0);
          dataItem.NoOfResources = resourceCount;
        }
        if (!value || value === '0' || value < 0) {
          dataItem.NoOfResources = 0;
          this.setState({ isErrorNoOfResources: true });
        } else {
          this.setState({ isErrorNoOfResources: false });
        }
        break;
      case 'RateValue':
        dataItem[name] = value;
        if (!value || value === '0') {
          dataItem.RateValue = 0;
          this.setState({ isErrorRateValue: true });
        } else {
          this.setState({ isErrorRateValue: false });
        }
        break;
      case 'EffortDays': {
        dataItem[name] = value;
        if (value >= 0) {
          let countryId = null;
          let totalEffortDays = null;
          let num = value * 8;
          let roundoff = num.toFixed(2);

            totalEffortDays = (value /(dataItem.AllocationPercentage /100));

            if (dataItem.OnsiteStatus === 'Onsite') {
                countryId = dataItem.Type === "Project" ? dataItem.Project.CountryId : dataItem.ChangeRequest.CountryId;
            } else {
                countryId = dataItem.dataValueResourceRegion ? dataItem.dataValueResourceRegion.DefaultCountryId : null;
            }

            if (dataItem.StartDate) {
              let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays,moment(dataItem.StartDate).format('YYYY-MM-DD'),countryId);

              dataItem.endDate = moment(endDate).format('MM-DD-YYYY');
              dataItem.EndDate = moment(endDate).format('MM-DD-YYYY');

              dataItem.EffortHours = roundoff;
            } else {
              dataItem.endDate = null;
              dataItem.EndDate = null;
            }
        }
        if (!value || value === '0') {
          dataItem.EffortHours = 0;
          dataItem.EffortDays = 0;
          this.setState({ isErrorEffortDays: true });
        } else {
          this.setState({ isErrorEffortDays: false });
        }
        break;
      }
    }

    this.setState({
      resourceAllocations: [...this.state.resourceAllocations],
        isLoading: false
    });
  };

    calculateNewEstimatedEndDate = async (workingDays, startDate, countryId) => {
        let startDateObj = reStructureDate(startDate);
        let endDate;

        // estimate end date
        const noOfWorkingDays = workingDays;

        let totalDays = 0;
        let workingDaysCount = 0;

        let holidays = await getHolidaysIntegrated(countryId, startDateObj.getFullYear()).then(
            res => {
                if (res && res.data) {
                    return res.data;
                }
                return [];
            }
        )
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        if (holidays && holidays.length === 0) {
            console.log('Holidays not found for ' + startDateObj.getFullYear());
        } else if (!holidays) {
            holidays = [];
        }
        console.log("holidays",holidays)
        console.log("startDateObj",startDateObj)

        endDate = reStructureDate(startDate);
        let currentYear = startDateObj.getFullYear();
        let isHoliday = false;

        while (workingDaysCount < noOfWorkingDays - 1 || isWeekend(endDate) || isHoliday) {
            if (currentYear !== endDate.getFullYear()) {
                holidays = await getHolidaysIntegrated(countryId, endDate.getFullYear())
                    .then(res => {
                        if (res && res.data) {
                            return res.data;
                        }
                        return [];
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        return [];
                    });

                currentYear = endDate.getFullYear();
            }

            totalDays++;
            endDate.setDate(endDate.getDate() + 1);

            isHoliday = false;
            const dateStr = getFormattedDate(endDate);
            if (holidays.indexOf(dateStr) > -1) {
                isHoliday = true;
            }

            if (!isWeekend(endDate) && !isHoliday) {
                workingDaysCount++;
            }
        }

        endDate.setDate(endDate.getDate());
        return { endDate, noOfWorkingDays, totalDays };
    };


    handleDatePickerChange = async event => {
    const field = event.field;
    const dataItem = event.dataItem;

    // Start Date
    if (field === 'DateStartDate') {
        let countryId;
        let totalEffortDays;
      if (event.value) {
        let startDate = new Date(event.value);
        startDate.setHours(0, 0, 0, 0);
        console.log("************************************************************")
        console.log("startDate",startDate)
        console.log("dataItem.StartDateMin",new Date(dataItem.StartDateMin))
        console.log("Condition",startDate >= new Date(dataItem.StartDateMin))

        if (startDate && startDate >= new Date(dataItem.StartDateMin)) {
          this.setState({
            StartDateIsAPastDate: false
          });
          dataItem.StartDate = startDate;
        } else {
          this.setState({
            StartDateIsAPastDate: true
          });
          dataItem.StartDate = startDate;
        }

        dataItem.DateStartDate = startDate;

        console.log("dataItem",dataItem)

        totalEffortDays = (dataItem.EffortDays/(dataItem.AllocationPercentage /100));

        if (dataItem.OnsiteStatus === 'Onsite') {
              countryId = dataItem.Type === "Project" ? dataItem.Project.CountryId : dataItem.ChangeRequest.CountryId;
        } else {
              countryId = dataItem.dataValueResourceRegion.DefaultCountryId;
        }
        console.log("totalEffortDays",totalEffortDays)
        console.log("countryId",countryId)

        let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays,moment(startDate).format('YYYY-MM-DD'),countryId);
        console.log("endDate",endDate)

          dataItem.endDate = moment(endDate).format('MM-DD-YYYY');
          dataItem.EndDate = new Date(moment(endDate).format('MM-DD-YYYY'));

      } else {
        dataItem.StartDate = null;
        dataItem.DateStartDate = null;
        console.log("dataItem.StartDate",dataItem.StartDate)
      }
    } else if (field === 'EndDate') {
      if (event.value) {
        let endDate = new Date(event.value);
        endDate.setHours(0, 0, 0, 0);
        dataItem.endDate = endDate;
        dataItem.EndDate = endDate;
      } else {
        dataItem.endDate = null;
        dataItem.EndDate = null;
      }
    }

    this.setState({
      hasUnSavedData: true
    });
  };

  onClickConverProjectToActive = async () => {


      if(this.state.StartDate === null){

          let message = 'Please fix the highlighted errors to continue';
          const title = 'Error';
          await this.setState({
              showSuccessImage: false,
              showErrorImage: true,
              isErrorMessageVisible: true
          });
          this.toggleMessageDialog(message, title);
          return;
      }

      if(this.state.StartDateIsAPastDate){
          const message = 'Not valid start date of Project PM';
          const title = 'Error';
          this.setState({
              showSuccessImage: false,
              showErrorImage: true
          });
          return this.toggleMessageDialog(message, title);
      }

      if (this.state.resourceAllocations && this.state.resourceAllocations.length === 0) {
          let message = 'No Resource was sent for request approval.';
          const title = 'Error';
          this.setState({
              showSuccessImage: false,
              showErrorImage: true
          });
          this.toggleMessageDialog(message, title);
      } else if (this.state.resourceAllocations && this.state.resourceAllocations.length > 0) {
          const filteredData = this.state.resourceAllocations.filter(resource => resource.selected);
          if (filteredData.length === 0 && this.state.resourceAllocations.length > 1) {
              let message = 'Please select a resource to be sent for request approval.';
              const title = 'Error';
              this.setState({
                  showSuccessImage: false,
                  showErrorImage: true
              });
              this.toggleMessageDialog(message, title);
          } else {
              if (this.state.disableResourceRowEditOnAdd) {
                  // ADDING A NEW PM RESOURCE

                  for (const allocation of this.state.resourceAllocations) {
                      const alloc = JSON.parse(JSON.stringify(allocation));

                      if (!alloc.AllocationPercentage || alloc.AllocationPercentage <= 0) {
                          let message = 'Please fix the highlighted errors to continue';
                          const title = 'Error';
                          this.setState({
                              showSuccessImage: false,
                              showErrorImage: true
                          });
                          this.toggleMessageDialog(message, title);
                          return;
                      }

                      if (
                          !alloc.StartDate ||
                          !alloc.dataValueProjectRole ||
                          !alloc.dataValueCostDesignation ||
                          !alloc.dataValueResourceRegion ||
                          !alloc.dataValueOnsiteStatus ||
                          !alloc.EffortDays ||
                          !alloc.PaymentMethod
                      ) {
                          let message = 'Please fix the highlighted errors to continue';
                          const title = 'Error';
                          this.setState({
                              showSuccessImage: false,
                              showErrorImage: true
                          });
                          this.toggleMessageDialog(message, title);
                          return;
                      }
                  }

                  this.state.resourceAllocations[0].Status = 7;

                  //cost calculations
                const billingRegion = this.state.resourceAllocations[0].Type === "Project" ? this.state.resourceAllocations[0].Project.BillingRegion : this.state.resourceAllocations[0].ChangeRequest.BillingRegion;
                const costCard = {
                  billingRegionId: billingRegion,
                  resourceRegionId: this.state.resourceAllocations[0].ResourceRegion,
                  designationId: this.state.resourceAllocations[0].Designation,
                  costDate: moment(new Date()).format('YYYY-MM-DD'),
                  projectId: this.state.resourceAllocations[0].Project !== null ? this.state.resourceAllocations[0].Project.id : null
                };
                
                let newItem = {
                  newResProjectOrCRName: this.state.resourceAllocations[0].Type === "Project"  ? this.state.resourceAllocations[0].Project : this.state.resourceAllocations[0].ChangeRequest,
                  newResRegion: this.state.resourceAllocations[0].dataValueResourceRegion,
                  newOnSiteStatus: this.state.resourceAllocations[0].OnsiteStatus,
                  newStartDate: moment(this.state.resourceAllocations[0].StartDate).format('MM/DD/YYYY'),
                  newEndDate:  moment(new Date(this.state.resourceAllocations[0].EndDate)).format('MM/DD/YYYY'),
                  newEffortDays: Number(this.state.resourceAllocations[0].EffortDays).toFixed(2),
                  newAllocation: this.state.resourceAllocations[0].AllocationPercentage,
                  EstimatedEndDate:moment(new Date(this.state.resourceAllocations[0].EndDate)).format('MM/DD/YYYY'),
                  newResDesignation: this.state.resourceAllocations[0].dataValueCostDesignation,
                  resourceCountry : this.state.resourceAllocations[0].dataValueResourceRegion.DefaultCountryId,
                  projOrCrCountry : this.state.resourceAllocations[0].Type === "Project" ? this.state.resourceAllocations[0].Project.CountryId : this.state.resourceAllocations[0].ChangeRequest.CountryId,
                  resourceId: null
                };

                let costDate_ = moment(new Date(newItem.newStartDate)).format('YYYY-MM-DD');
                const costItems = {
                  costCard : costCard,
                  newItem : newItem,
                  costDate: costDate_
                };
                await getCostDetails(costItems)
                  .then(async res => {
                    console.log("res.data987",res.data)
                      if (res.data && res.data.TotalCost == 0) {
                        this.state.resourceAllocations[0].CostPerResource = 0;
                        this.state.resourceAllocations[0].DirectCost = 0;
                        this.state.resourceAllocations[0].TotalCost = 0;
                    
                        const message = 'Cost card record not found for the selected designation and resource region';
                        const title = 'Error';
                        await this.setState({
                          showSuccessImage: false,
                          showErrorImage: true
                        });
                        await this.toggleMessageDialog(message, title);
                        return;
                      } else {
                        this.state.resourceAllocations[0].CostPerResource = res.data.CostPerResource;
                        this.state.resourceAllocations[0].DirectCost = res.data.DirectCost;
                        this.state.resourceAllocations[0].TotalCost = res.data.TotalCost;
                      }
                  })
                  .catch( async err => {
                    newItem.CostPerResource = 0;
                    newItem.DirectCost = 0;
                    newItem.TotalCost = 0;
                    loggerService.writeLog(err, LOG_TYPES.ERROR);

                    const message = 'Cost card record not found for the selected designation and resource region';
                    const title = 'Error';
                    await this.setState({
                      showSuccessImage: false,
                      showErrorImage: true
                    });

                    await this.toggleMessageDialog(message, title);
                    return;
                  });

                // let region = null;
                // await getRegionCurrencyIntegrated(billingRegion).then(response => {
                //   region = response.data;
                // })
                //   .catch(error => {
                //     loggerService.writeLog(error, LOG_TYPES.ERROR);
                //   });
                // await getCostIntegrated(costCard)
                //   .then(async res => {

                //     if (res.data.length > 0 && res.data[0].id > 0) {
                //       const costCard = res.data[0];
                //       const costRecord = {
                //         CurrencyId: costCard.BillingRegion.Currency.id,
                //         Overhead: costCard.CostCardDesignations[0].Overhead,
                //         CostToCompany: costCard.CostCardDesignations[0].CostToCompany
                //       };

                //       let costPerResourceTotal = costRecord.Overhead + costRecord.CostToCompany;

                      // if (costPerResourceTotal === 0) {
                      //   this.state.resourceAllocations[0].CostPerResource = 0;
                      //   this.state.resourceAllocations[0].DirectCost = 0;
                      //   this.state.resourceAllocations[0].TotalCost = 0;
                      //   const message =
                      //     'Cost card record not found for the selected designation and resource region';
                      //   const title = 'Error';
                      //   await this.setState({
                      //     showSuccessImage: false,
                      //     showErrorImage: true
                      //   });

                      //   await this.toggleMessageDialog(message, title);
                      // } else {

                        // if (region && region.Currency && region.Currency.id > 0) {
                          // if (region.Currency.Code === 'USD') {
                            // let costPerResource = costRecord.Overhead + costRecord.CostToCompany;
                            // this.state.resourceAllocations[0].CostPerResource = (Number(costPerResource).toFixed(2));
                            // this.state.resourceAllocations[0].DirectCost = (Number(costRecord.CostToCompany).toFixed(2));
                            // this.state.resourceAllocations[0].TotalCost = (Number(costPerResource * this.state.resourceAllocations[0].EffortDays).toFixed(2));
                            //await this.calculateTotalCost(newItem, costPerResource, res.data[0].id, 0);
                          // } else {
                          //   const proStartDate = new Date(); //costDate

                          //   const ratePerUSD = await retrieveConversionRateIntegrated(
                          //     region.Currency.id,
                          //     proStartDate.getMonth() + 1,
                          //     proStartDate.getFullYear()
                          //   );

                          //   if (ratePerUSD > 0) {
                          //     let costPerResource =
                          //       (costRecord.Overhead + costRecord.CostToCompany) * ratePerUSD;
                          //     this.state.resourceAllocations[0].CostPerResource = (Number(costPerResource).toFixed(2));
                          //     this.state.resourceAllocations[0].DirectCost = (Number(costRecord.CostToCompany * ratePerUSD).toFixed(2));
                          //     this.state.resourceAllocations[0].TotalCost = (Number(costPerResource * this.state.resourceAllocations[0].EffortDays).toFixed(2));

                          //   } else {
                          //     this.state.resourceAllocations[0].CostPerResource = 0;
                          //     this.state.resourceAllocations[0].DirectCost = 0;
                          //     this.state.resourceAllocations[0].TotalCost = 0;
                          //     const message = 'Currency conversion rate not found for the billing region';
                          //     const title = 'Error';
                          //     this.toggleMessageDialog(message, title);
                          //   }
                          // }
                        // } else {
                        //   this.state.resourceAllocations[0].CostPerResource = 0;
                        //   this.state.resourceAllocations[0].DirectCost = 0;
                        //   this.state.resourceAllocations[0].TotalCost = 0;
                        //   this.toggleMessageDialog('Please select a Project or CR', 'Error');
                        // }

                      // }
                  //   } else {
                  //     this.state.resourceAllocations[0].CostPerResource = 0;
                  //     this.state.resourceAllocations[0].DirectCost = 0;
                  //     this.state.resourceAllocations[0].TotalCost = 0;
                  //     const message =
                  //       'Cost card record not found for the selected designation and resource region';
                  //     const title = 'Error';
                  //     await this.setState({
                  //       showSuccessImage: false,
                  //       showErrorImage: true
                  //     });

                  //     this.toggleMessageDialog(message, title);

                  //   }
                  //   return this.state.resourceAllocations[0];
                  // })
                  // .catch(error => {
                  //   loggerService.writeLog(error, LOG_TYPES.ERROR);
                  //   this.state.resourceAllocations[0].CostPerResource = 0;
                  //   this.state.resourceAllocations[0].DirectCost = 0;
                  //   this.state.resourceAllocations[0].TotalCost = 0;
                  //   return this.state.resourceAllocations[0];
                  // });

                  if (this.state.resourceAllocations[0].TotalCost !== 0) {

                    this.state.resourceAllocations[0].StartDate = moment(this.state.resourceAllocations[0].StartDate).format('YYYY-MM-DD')
                    this.state.resourceAllocations[0].StartDateMin = moment(this.state.resourceAllocations[0].StartDateMin).format('YYYY-MM-DD')
                    this.state.resourceAllocations[0].DateStartDate = moment(this.state.resourceAllocations[0].DateStartDate).format('YYYY-MM-DD')
                    this.state.resourceAllocations[0].IsInitialPMResource = true;
                    this.state.resourceAllocations[0].EndDate = this.state.resourceAllocations[0].EndDate ? moment(this.state.resourceAllocations[0].EndDate).format('YYYY-MM-DD') : this.state.resourceAllocations[0].EndDate;
                    this.state.resourceAllocations[0].Billability = this.state.resourceAllocations[0].RateType && 
                      this.state.resourceAllocations[0].RateType === 5 ? 'Non-Billable' : 'Billable';

                    const projectEndDate = await this.getEstimatedEndDate();

                    const selectedResourceAllocations = {
                        resourceAllocation : this.state.resourceAllocations[0],
                        projectStartDate : await moment(this.state.StartDate).format('YYYY-MM-DD'),
                        projectEndDate : await moment(projectEndDate).format('YYYY-MM-DD')
                    }                

                    if (this.props.location.Type === 'Project') {
                      let allocationEndDate = moment(this.state.resourceAllocations[0].EndDate).format('YYYY-MM-DD');
                      if (new Date(allocationEndDate) > new Date(this.state.Project.EstimatedEndDate)) {              
                        this.state.Project.EstimatedEndDate = this.state.resourceAllocations[0].EndDate;
                        if (this.state.Project.EndDate && new Date(allocationEndDate) > new Date(this.state.Project.EndDate)) {
                          this.state.Project.EndDate = this.state.resourceAllocations[0].EndDate;
                        }
                        await updateProject(this.state.Project, this.state.Project.id)
                      }
                    } else {
                      let allocationEndDate = moment(this.state.resourceAllocations[0].EndDate).format('YYYY-MM-DD');
                      if (new Date(allocationEndDate) > new Date(this.state.ChangeRequest.EstimatedEndDate)) {
                        this.state.ChangeRequest.EstimatedEndDate = this.state.resourceAllocations[0].EndDate;
                        if (this.state.ChangeRequest.EndDate && new Date(allocationEndDate) > new Date(this.state.ChangeRequest.EndDate)) {
                          this.state.ChangeRequest.EndDate = this.state.resourceAllocations[0].EndDate;
                        }
                        await updateChangeReq(this.state.ChangeRequest, this.state.ChangeRequest.id)
                      }
                    }

                    await createNewPMResourceRequest(selectedResourceAllocations)
                        .then(response => {
                            this.presalesEndDateChange();
                            let message = 'New resource was created for resource allocation.';
                            const title = 'Success';
                            //this.onSaveAuditTrail();
                            this.setState({
                                showSuccessImage: true,
                                showErrorImage: false
                            });
                            this.toggleMessageDialog(message, title);
                        })
                        .catch(err => {
                            let message = 'Selected resource was not sent for resource allocation.';
                            const title = 'Success';
                            this.setState({
                                showSuccessImage: true,
                                showErrorImage: false
                            });
                            this.onSaveAuditTrail();
                            this.toggleMessageDialog(message, title);
                        });
                  }
              } else {
                  // UPDATING
                  if (this.state.resourceAllocations && this.state.resourceAllocations.length > 1) {
                      const selctedAllocations = this.state.resourceAllocations.filter(
                          resource => resource.selected === true
                      );
                      if (selctedAllocations.length > 0) {
                          for (const allocation of selctedAllocations) {
                              const alloc = JSON.parse(JSON.stringify(allocation));

                              if (!alloc.StartDate) {
                                  let message = 'Allocation Start Date cannot be empty';
                                  const title = 'Error';
                                  this.setState({
                                      showSuccessImage: false,
                                      showErrorImage: true
                                  });
                                  this.toggleMessageDialog(message, title);
                                  return;
                              }
                          }
                      } else {
                          let message = 'Select a resource to be sent for resource allocation.';
                          const title = 'Error';
                          this.setState({
                              showSuccessImage: false,
                              showErrorImage: true
                          });
                          this.toggleMessageDialog(message, title);
                          return;
                      }

                      selctedAllocations.forEach(function (resource) {
                          resource.Status = 7;
                          resource.IsInitialPMResource = true;
                      });


                      const projectEndDate = await this.getEstimatedEndDate();

                      if (this.props.location.Type === 'Project') {
                        if (this.state.Project.EndDate && new Date(projectEndDate) > new Date(this.state.Project.EndDate)) {
                          this.state.Project.EndDate = moment(projectEndDate).format('YYYY-MM-DD');
                          await updateProject(this.state.Project, this.state.Project.id)
                        }
                      } else {
                        if (this.state.ChangeRequest.EndDate && new Date(projectEndDate) > new Date(this.state.ChangeRequest.EndDate)) {
                          this.state.ChangeRequest.EndDate = moment(projectEndDate).format('YYYY-MM-DD');
                          await updateChangeReq(this.state.ChangeRequest, this.state.ChangeRequest.id)
                        }
                      }

                      const selectedAllocations = {
                          resourceAllocation : selctedAllocations,
                          projectStartDate : await moment(this.state.StartDate).format('YYYY-MM-DD'),
                          projectEndDate : await moment(projectEndDate).format('YYYY-MM-DD')
                      }

                      for(const item of selctedAllocations)
                      {
                          item.StartDate = moment(item.StartDate).format('YYYY-MM-DD')
                          item.StartDateMin = moment(item.StartDateMin).format('YYYY-MM-DD')
                          item.DateStartDate = moment(item.DateStartDate).format('YYYY-MM-DD')
                          item.EndDate = item.EndDate ? moment(item.EndDate).format('YYYY-MM-DD') : item.EndDate;
                      }

                      updateResourceRequestResourceDetails(selectedAllocations)
                          .then(response => {
                              this.presalesEndDateChange();
                              let message = 'Selected resources was sent for resource allocation.';
                              const title = 'Success';
                              this.onSaveAuditTrail();
                              this.setState({
                                  showSuccessImage: true,
                                  showErrorImage: false
                              });
                              this.toggleMessageDialog(message, title);
                          })
                          .catch(error => {
                              loggerService.writeLog(error, LOG_TYPES.ERROR);
                              this.toggleDialog(error.response.data, 'Error');
                          });
                  } else {
                      for (const allocation of this.state.resourceAllocations) {
                          const alloc = JSON.parse(JSON.stringify(allocation));

                          if (
                              !alloc.StartDate ||
                              !alloc.dataValueProjectRole ||
                              !alloc.dataValueCostDesignation ||
                              !alloc.dataValueResourceRegion ||
                              !alloc.dataValueOnsiteStatus ||
                              !alloc.EffortDays
                          ) {
                              let message = 'Please fix the highlighted errors to continue';
                              const title = 'Error';
                              this.setState({
                                  showSuccessImage: false,
                                  showErrorImage: true
                              });
                              this.toggleMessageDialog(message, title);
                              return;
                          }
                      }

                      if (this.state.resourceAllocations && this.state.resourceAllocations.length > 0) {
                          this.state.resourceAllocations.forEach(function (resource) {
                              resource.Status = 7;
                              resource.IsInitialPMResource = true;
                          });
                      }

                      const projectEndDate = await this.getEstimatedEndDate();

                      if (this.props.location.Type === 'Project') {
                        if (this.state.Project.EndDate && new Date(projectEndDate) > new Date(this.state.Project.EndDate)) {
                          this.state.Project.EndDate = moment(projectEndDate).format('YYYY-MM-DD');
                          await updateProject(this.state.Project, this.state.Project.id)
                        }
                      } else {
                        if (this.state.ChangeRequest.EndDate && new Date(projectEndDate) > new Date(this.state.ChangeRequest.EndDate)) {
                          this.state.ChangeRequest.EndDate = moment(projectEndDate).format('YYYY-MM-DD');
                          await updateChangeReq(this.state.ChangeRequest, this.state.ChangeRequest.id)
                        }
                      }

                      const resourceAllocation = {
                          resourceAllocation : this.state.resourceAllocations,
                          projectStartDate : await moment(this.state.StartDate).format('YYYY-MM-DD'),
                          projectEndDate : await moment(projectEndDate).format('YYYY-MM-DD')
                      }

                      for(const item of this.state.resourceAllocations)
                      {
                          item.StartDate = moment(item.StartDate).format('YYYY-MM-DD')
                          item.StartDateMin = moment(item.StartDateMin).format('YYYY-MM-DD')
                          item.DateStartDate = moment(item.DateStartDate).format('YYYY-MM-DD')
                          item.EndDate = item.EndDate ? moment(item.EndDate).format('YYYY-MM-DD') : item.EndDate;
                      }

                      updateResourceRequestResourceDetails(resourceAllocation)
                          .then(response => {
                              this.presalesEndDateChange();
                              let message = 'Selected resources was sent for resource allocation.';
                              const title = 'Success';
                              this.onSaveAuditTrail();
                              this.setState({
                                  showSuccessImage: true,
                                  showErrorImage: false
                              });
                              this.toggleMessageDialog(message, title);
                          })
                          .catch(error => {
                          });
                  }
              }
          }
      }
  };

  getEstimatedEndDate = async () => {
    let maxDate = null;

    for (const allocation of this.state.resourceAllocations) {
      if (allocation.EndDate !== null) {
        const tempDate = reStructureDate(allocation.EndDate);
        if ((maxDate && tempDate > maxDate) || !maxDate) {
          maxDate = tempDate;
        }
      } else if (allocation.EstimatedEndDate !== null) {
        const tempDate = reStructureDate(allocation.EstimatedEndDate);
        if ((maxDate && tempDate > maxDate) || !maxDate) {
          maxDate = tempDate;
        }
      }
    }
    return maxDate;
  };

  presalesEndDateChange = async () => {
      const presalesData = {
          type : this.props.location.Type,
          projId : this.props.location.Type == 'Project' ? this.state.Project.id : this.state.ChangeRequest.id,
          projStart : moment(this.state.StartDate).format('YYYY-MM-DD')
      }
      await presalesEndDateChange(presalesData).then(
      ).catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  }

  toggleMessageDialog = (message, title) => {
    this.setState({
      showMessageDialog: !this.state.showMessageDialog,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleProjectNameToolTip = () => {
    this.setState({
      projectNameToolTipOpen: !this.state.projectNameToolTipOpen
    });
  };

  toggleUniqueIdToolTip = () => {
    this.setState({
      uniqueIdToolTipOpen: !this.state.uniqueIdToolTipOpen
    });
  };

  toggleCRNameToolTip = () => {
    this.setState({
      crNameToolTipOpen: !this.state.crNameToolTipOpen
    });
  };

  selectionChange = event => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  };

  headerSelectionChange = event => {
    const checked = event.syntheticEvent.target.checked;
    this.state.resourceAllocations.forEach(item => (item.selected = checked));
    this.forceUpdate();
  };

  reDirectToDashboard = event => {
    this.setState({
      toDashboard: true
    });
  };

  onClickCancel = event => {
    if (this.state.hasUnSavedData === true) {
      this.toggleConfirmDialog(
        'This form contains unsaved data. Do you want to close it?',
        this.redirectToProjectSearch
      );
    } else {
      this.redirectToProjectSearch();
    }
  };

  redirectToProjectSearch = () => {
    this.setState({
      navigateToSearchScreen: true
    });
  };

  toggleConfirmDialog = (message, action) => {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
      confirmDialogMessage: message,
      confirmDialogAction: action
    });
  };

  onSaveAuditTrail = () => {
    let auditTrail = {};
    if (this.state.Type === 'Project') {
      auditTrail = {
        Action: 'Project Activated',
        RefType: 'Project',
        RefId: this.state.id,
        RefName: this.state.uniqueId
      };
    } else if (this.state.Type === 'ChangeRequest') {
             auditTrail = {
               Action: 'Change Request Activated',
               RefType: 'Change Request',
               RefId: this.state.id,
               RefName: this.state.uniqueId
             };
           }
    saveAuditTrail(auditTrail);
  };

  validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

  render() {
    if (this.state.toDashboard === true) {
      return <Redirect to="/" />;
    }

    if (this.state.navigateToSearchScreen === true) {
      return <Redirect to="/projects/search/search" />;
    }

    return (
      <div>
        <div className="main-card">
            <Loader loading={this.state.isLoading} />
          <div className="row">
            <div className="col-md-3">
              <div className="row">
                <div className="col-md-5">
                  <label className="mandatory">Project Name:</label>
                </div>
                <div className="col-md-7">
                  <div className="" id="projectNameToolTip">
                    <Input
                      autoComplete="off"
                      type="text"
                      name="projectname"
                      value={this.state.projectName}
                      //onChange={this.handleInputChange}
                      required={true}
                      //validityStyles={this.state.projectname.touched}
                      disabled={true}
                    />
                  </div>
                  {this.state.projectName ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.projectNameToolTipOpen}
                      target="projectNameToolTip"
                      toggle={this.toggleProjectNameToolTip}
                    >
                      {this.state.projectName}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </div>

            {this.state.showCRField === true ? (
              <div className="col-md-3">
                <div className="row">
                  <div className="col-md-5">
                    <label className="mandatory">CR Name:</label>
                  </div>
                  <div className="col-md-7">
                    <div className="" id="crNameToolTip">
                      <Input
                        autoComplete="off"
                        type="text"
                        name="crName"
                        value={this.state.crName}
                        //onChange={this.handleInputChange}
                        required={true}
                        //validityStyles={this.state.projectname.touched}
                        disabled={true}
                      />
                    </div>
                    {this.state.crName ? (
                      <Tooltip
                        placement="top"
                        isOpen={this.state.crNameToolTipOpen}
                        target="crNameToolTip"
                        toggle={this.toggleCRNameToolTip}
                      >
                        {this.state.crName}
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            <div className="col-md-3">
              <div className="row">
                <div className="col-md-5">
                  <label className="mandatory">ID:</label>
                </div>
                <div className="col-md-7">
                  <div className="uniqueID" id="uniqueIdToolTip">
                    <Input
                      autoComplete="off"
                      type="text"
                      name="uniqueId"
                      value={this.state.uniqueId}
                      //onChange={this.handleInputChange}
                      required={true}
                      //validityStyles={this.state.projectname.touched}
                      disabled={true}
                    />
                  </div>
                  {this.state.uniqueId ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.uniqueIdToolTipOpen}
                      target="uniqueIdToolTip"
                      toggle={this.toggleUniqueIdToolTip}
                    >
                      {this.state.uniqueId}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">


              <div className="row">
                <div className="col-md-5">
                  <label className="mandatory">Project Start Date:</label>
                </div>
                <div className="col-md-7">
                  <DatePicker
                    width="100"
                    name="startDate"
                    onChange={this.handleStartdateChange}
                    required={true}
                    value={reStructureDate(this.state.StartDate)}
                    format="MM/dd/yyyy"
                    min={reStructureDate(this.state.StartDateMin)}
                    //disabled={true}
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                  />
                    {this.state.isErrorMessageVisible === true ? (
                        <span className={this.validateProperty(this.state.StartDate)}>
                                            Please select a Project Start Date
                                        </span>
                    ) : null}
                </div>
              </div>


            </div>

            <div className="col-md-3">
              <div className="row">
                <div className="col-md-5">
                  <label>CM Version:</label>
                </div>
                <div className="col-md-7">
                  <DropDownList
                    data={this.state.cmSheetVersions}
                    textField="Text"
                    dataItemKey="Version"
                    value={this.state.selectedVersion}
                    onChange={this.handleVersionChange}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="main-seperator" />

          <div className="row">
            <div className="col-md-6">
              <div className="main-heading">Resource Request for Project PM</div>
            </div>
            <div className="col-md-6 btn-align-right">
              <Button
                type="button"
                disabled={this.state.disableAddPMResourceBtn}
                onClick={this.onAddResourceRequestPMResource}
                primary={true}
              >
                Add
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 resc-req">
              <Grid
                data={this.state.resourceAllocations}
                editField="inEdit"
                style={{ height: 'auto' }}
                onItemChange={this.itemChange}
                selectedField="selected"
                onSelectionChange={this.selectionChange}
                onHeaderSelectionChange={this.headerSelectionChange}
                resizable
              >
                {this.state.resourceAllocations && this.state.resourceAllocations.length > 1 && (
                  <Column
                    field="selected"
                    width="50px"
                    headerSelectionValue={
                      this.state.resourceAllocations.findIndex(
                        dataItem => dataItem.selected === false
                      ) === -1
                    }
                  />
                )}

                <Column
                  field="ProjectRole"
                  headerCell={props => <label className="mandatory">Project Role</label>}
                  cell={this.ComboBoxCell}
                  maxLength={40}
                  width="120px"
                />
                <Column
                  field="Designation"
                  headerCell={props => <label className="mandatory">Designation (Cost)</label>}
                  cell={this.ComboBoxCell}
                  maxLength={40}
                  width="150px"
                />
                <Column
                  field="ResourceRegion"
                  headerCell={props => <label className="mandatory">Resource Region</label>}
                  cell={this.ComboBoxCell}
                  maxLength={6}
                  width="150px"
                />
                <Column
                  field="OnsiteStatus"
                  headerCell={props => <label className="mandatory">Onsite / Offshore</label>}
                  cell={this.ComboBoxCell}
                  maxLength={5}
                  width="120px"
                />
                <Column
                  field="DateStartDate"
                  headerCell={props => <label className="mandatory">Start Date</label>}
                  cell={this.DatePickerCell}
                  width="150px"
                />
                  <Column
                      field="EndDate"
                      headerCell={props => <label>End Date</label>}
                      cell={this.DatePickerCell}
                      width="150px"
                  />
                <Column
                  field="EffortDays"
                  headerCell={props => <label className="mandatory">Effort Days</label>}
                  cell={this.NumericTextBoxCell}
                  maxLength={3}
                  width="120px"
                />
                <Column
                  field="AllocationPercentage"
                  headerCell={props => <label className="mandatory">Allocation (%)</label>}
                  cell={this.NumericTextBoxCell}
                  maxLength={3}
                  width="150px"
                />
                <Column
                  field="RateType"
                  headerCell={props => <label className="mandatory">Rate Type</label>}
                  cell={this.ComboBoxCell}
                  maxLength={10}
                  width="200px"
                />
                <Column
                  field="RateValue"
                  headerCell={props => <label className="mandatory">Rate Value</label>}
                  cell={this.NumericTextBoxCell}
                  maxLength={7}
                  width="120px"
                />
                <Column
                  field="PaymentMethod"
                  headerCell={props => <label className="mandatory">Billing Method</label>}
                  cell={this.ComboBoxCell}
                  maxLength={5}
                  width="120px"
                />
              </Grid>
            </div>
          </div>

          <div className="main-seperator" />

                    {(this.state.PaymentMethodId === 2 || this.state.PaymentMethodId === 3)  && (
                        <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-heading">Define Time and Material Cap</div>
                        </div>
                    </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="d-inline-block mr-2">
                    <fieldset>
                      <label className="k-form-field">
                        <input type="checkbox" id="auth-1" className="k-checkbox" />
                        <label className="k-checkbox-label" for="auth-1">
                          Has a Maximum amount for Time and Material Effort for the Project
                        </label>
                      </label>
                    </fieldset>
                  </div>
                  <div className="d-inline-block">
                    <Input />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button
                type="button"
                disabled={this.state.disableConverToBillableBtn}
                primary={true}
                onClick={this.onClickConverProjectToActive}
              >
                Activate the Project
              </Button>
              <Button type="button" onClick={this.onClickCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button
                  className="k-button modal-primary"
                  onClick={
                    this.state.dialogTitle === 'Error'
                      ? this.toggleMessageDialog
                      : this.reDirectToDashboard
                  }
                >
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button
                  className="k-button modal-primary"
                  onClick={
                    this.state.dialogTitle === 'Error'
                      ? this.toggleMessageDialog
                      : this.reDirectToDashboard
                  }
                >
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    );
  }

  ComboBoxCell =  props => {
    if (props.field === 'ProjectRole') {
      return (
        <RowCellComboBox
          {...props}
          dataList="ProjectRoles"
          textField="Name"
          dataItemKey="id"
          dataValueField="dataValueProjectRole"
          onChangeSelection={this.handleChangeComboBox}
          name="ProjectRole"
          comboBoxPlaceHolder={this.comboBoxPlaceHolder}
          canFilter={true}
          filterChangeCombo={this.filterChangeCombo}
          disabled={this.props.isViewMode || this.state.disableResourceRowEdit}
          required={true}
        />
      );
    }

    if (props.field === 'Designation') {
      return (
        <RowCellComboBox
          {...props}
          dataList="CostDesignations"
          textField="Name"
          dataItemKey="id"
          dataValueField="dataValueCostDesignation"
          onChangeSelection={this.handleChangeComboBox}
          name="CostDesignation"
          comboBoxPlaceHolder={this.comboBoxPlaceHolder}
          canFilter={true}
          filterChangeCombo={this.filterChangeCombo}
          disabled={this.props.isViewMode || this.state.disableResourceRowEdit}
          required={true}
        />
      );
    }

    if (props.field === 'ResourceRegion') {
      return (
        <RowCellComboBox
          {...props}
          dataList="ResourceRegions"
          textField="Name"
          dataItemKey="id"
          dataValueField="dataValueResourceRegion"
          onChangeSelection={this.handleChangeComboBox}
          name="ResourceRegion"
          comboBoxPlaceHolder={this.comboBoxPlaceHolder}
          canFilter={true}
          filterChangeCombo={this.filterChangeCombo}
          disabled={this.props.isViewMode || this.state.disableResourceRowEdit}
          required={true}
        />
      );
    }

    if (props.field === 'OnsiteStatus') {
      return (
        <RowCellComboBox
          {...props}
          dataList="OnsiteStatuses"
          textField="Name"
          dataItemKey="id"
          dataValueField="dataValueOnsiteStatus"
          onChangeSelection={this.handleChangeComboBox}
          name="OnsiteStatus"
          comboBoxPlaceHolder={this.comboBoxPlaceHolder}
          disabled={this.props.isViewMode || this.state.disableResourceRowEdit}
          required={true}
        />
      );
    }

    if (props.field === 'RateType') {

    return  (
        <RowCellComboBox
          {...props}
          dataList="RateTypes"
          textField="Text"
          dataItemKey="id"
          dataValueField="dataValueRateType"
          onChangeSelection={this.handleChangeComboBox}
          name="RateType"
          disabled={
            this.props.isViewMode ||
            this.state.disableResourceRowEdit ||
            this.state.disableResourceRowEditOnAdd
          }
          comboBoxPlaceHolder={this.comboBoxPlaceHolder}
          required={true}
        />
      );
    }

    if (props.field === 'PaymentMethod') {
      return (
        <RowCellComboBox
          {...props}
          dataList="PaymentMethodAll"
          textField="Name"
          dataItemKey="id"
          dataValueField="dataValuePaymentMethod"
          onChangeSelection={this.handleChangeComboBox}
          name="PaymentMethod"
          comboBoxPlaceHolder={this.comboBoxPlaceHolder}
          disabled={this.props.isViewMode || this.state.disableResourceRowEdit || this.state.disablePaymentMethod}
          required={true}
        />
      );
    }


  };

  NumericTextBoxCell = props => {
    if (props.field === 'AllocationPercentage') {
      return (
        <RowCellNumericTextBox
          {...props}
          disabled={this.props.isViewMode || this.props.isAPS || this.state.disableResourceRowEdit}
          onChange={this.itemChange}
          required={true}
          minValue={0} 
          toFixed={2}
          isError={this.state.isErrorAllocationPercentage}
          isRed={this.state.isRedBadge}
        />
      );
    }

    if (props.field === 'RateValue') {
      return (
        <RowCellNumericTextBox
          {...props}
          disabled={
            props.dataItem.RateValueDisabled ||
            this.props.isViewMode ||
            this.state.disableResourceRowEditOnAdd ||
            this.state.disableResourceRowEdit
          }
          minValue={0}
          onChange={this.itemChange}
          toFixed={2}
          required={true}
          styleClass={props.dataItem.rateNoLongerValid === true ? 'border-red' : ''}
          isError={this.state.isErrorRateValue}
        />
      );
    }

    if (props.field === 'NoOfResources') {
      return (
        <RowCellNumericTextBox
          {...props}
          disabled={this.props.isViewMode}
          minValue={1}
          onChange={this.itemChange}
          toFixed={0}
          required={true}
          isError={this.state.isErrorNoOfResources}
        />
      );
    }

    if (props.field === 'EffortDays') {
      return (
        <RowCellNumericTextBox
          {...props}
          disabled={this.props.isViewMode || this.state.disableResourceRowEdit}
          minValue={0}
          onChange={this.itemChange}
          toFixed={2}
          required={true}
          isError={this.state.isErrorEffortDays}
        />
      );
    }

    if (props.field === 'EffortHours') {
      return (
        <RowCellNumericTextBox
          {...props}
          disabled={this.props.isViewMode}
          minValue={0}
          onChange={this.itemChange}
          toFixed={0}
          required={true}
          isError={this.state.isErrorEffortHours}
        />
      );
    }
  };

  DatePickerCell =  props => {
    if (props.field === 'DateStartDate') {

      return (
        <RowCellDatePicker
          {...props}
          disabled={props.dataItem.DatesDisabled || this.props.isViewMode}
          minDateField="StartDateMin"
          onChange={this.itemChange}
          StartDateIsAPastDate={true}
          Type={'ResourceRequestActivation'}
          proStartDate={this.state.StartDateMin}
          required={true}
        />
      );
    }

    if (props.field === 'EndDate') {
      return (
        <RowCellDatePicker
          {...props}
          disabled={true}
          minDateField="StartDateMin"
          maxDateField="StartDateMax"
          onChange={this.itemChange}
          Type={'ResourceRequest'}
        />
      );
    }
  };
}

export default ResourceRequestActivation;