import React, { Component } from 'react';
import 'moment-timezone';
import Parser from 'html-react-parser';
import {getcustomers} from "../../../../masterData/customer/CustomerService";
import {getProjectStatuses} from "../../../project/ProjectService";

class DailyRateCell extends Component {


    render() {

        let value = this.props.dataItem;

        if(value && value.ChangeRequests.length > 0){

            return <td>
                {value.ChangeRequests[0].dailyRate}
            </td>;

        }

        else{

            return <td>
                {value.dailyRate}
            </td>;
        }
    }
}

export default DailyRateCell;
