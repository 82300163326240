import React, { Component, useState } from "react";
import Parser from 'html-react-parser';

class StatusChangeCommentCell extends Component{
    render(){
        const value = this.props.dataItem;

        if(value.ApprovalStatus && value.ApprovalStatus == 'REJECTED'){
            if(value.Comment !== null){
                return (
                    <td> 
                        <span>
                            <strong>{'Rejection Comment: '}</strong> 
                        </span>
                        <span>{value.Comment}</span> 
                    </td>
                );
            }
            else{
                return <td>{''}</td>
            }
        }
        else{
            if(value.Comment !== null){
                return <td><div className={'rich-text-bullets'}>{Parser(value.Comment)}</div></td>
            }
            else{
                return <td>{''}</td>
            }
            
        }
    }
}

export default StatusChangeCommentCell;