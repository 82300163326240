import React, { Component } from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { getAllProjectsAndCRsDataPendingIR, getPendingInvoiceRequests } from './InvoiceService';
import { getRegionsIntegrated, getcustomersIntegrated } from '../../integration/MasterDataService';
import ActionsCell from './ActionsCell';
import Loader from '../../integration/components/Loader';
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import * as loggerService from "../../integration/LoggerService";
import 'moment-timezone';

class PendingIR extends Component {
  _export;

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {

      invoiceRequests: [],
      projectAndCRs: [],
      projectAndCRsAll: [],
      region: [],
      regionAll: [],
      customers: [],
      customersAll: [],
      selectedRegion: [],
      selectedProjectName: [],
      selectedCustomer: [],
      validRegions: [],
      userRoleAllowed: false,
      skip: 0,
      take: 20,
      sort: [
        { field: 'updatedAt', dir: 'desc' }
      ],
      loading: false
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.props.onHeaderTitleChange('Pending IR Creation');
    this.setInitialData();
  }

  setInitialData = async() => {
    await this.setState({
      loading: true
    });
    await this.populateRegions();
    await this.checkUserRole();
    await this.validateRegions();
    await this.populateProjectsAndCRs();
    await this.populateCustomers();
    await this.getPendingInvoiceRequests();
    await this.setState({
      loading: false
    });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  checkUserRole = async () => {
    const userRoles = window.LOGGED_USER.roles;
    const allowedUserRole = userRoles.some(obj => obj.Rolename === 'PMO' || obj.Rolename === 'Finance Manager' || obj.Rolename === 'System Admin' );
    await this.setState({
      userRoleAllowed: allowedUserRole
    });
  }

  validateRegions = async() => {
    let validRegions = [];
    const userPermissions = window.USER_PERMISSION;

    const asiaSpoc = userPermissions.some(obj => obj === 'ASIA_SPOC');
    if (asiaSpoc) {
        const asiaRegios = this.state.regionAll.filter(x => x.id === 3 || x.id === 5 || x.id === 18 || x.id === 19);
        validRegions = validRegions.concat(asiaRegios);
    }

    const anzSpoc = userPermissions.some(obj => obj === 'ANZ_SPOC');
    if (anzSpoc) {
        const anzRegios = this.state.regionAll.filter(x => x.id === 4);
        validRegions = validRegions.concat(anzRegios);
    }

    const ukSpoc = userPermissions.some(obj => obj === 'UK_EUROPE_SPOC');
    if (ukSpoc) {
        const ukRegios = this.state.regionAll.filter(x => x.id === 1 ||  x.id === 21);
        validRegions = validRegions.concat(ukRegios);
    }

    const usSpoc = userPermissions.some(obj => obj === 'AMERICAS_SPOC');
    if (usSpoc) {
        const usRegios = this.state.regionAll.filter(x => x.id === 2);
        validRegions = validRegions.concat(usRegios);
    }

    if (validRegions && validRegions.length > 0 && !this.state.userRoleAllowed) {
      await this.setState({
        region: validRegions,
        validRegions: validRegions
      });
    }
};

  populateProjectsAndCRs = async() => {
    const fields = {};
    fields.AllAccess = this.state.userRoleAllowed;
    fields.ValidRegions = this.state.validRegions.map(value => value.id);;
    await getAllProjectsAndCRsDataPendingIR(fields)
      .then(res => {  
        if (this.isMount) {
          this.setState({
            projectAndCRs: res.data,
            projectAndCRsAll: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateRegions = async() => {
    await getRegionsIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            region: res.data,
            regionAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getPendingInvoiceRequests = async() => {
    const fields = {};
    fields.Projects = this.state.projectAndCRsAll;
    if (this.state.projectAndCRsAll && this.state.projectAndCRsAll.length > 0) {
      await getPendingInvoiceRequests(fields)
        .then(res => {
          if (this.isMount) {
              this.setState({
                invoiceRequests: res.data
              });
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }
  };

  populateCustomers = () => {
    getcustomersIntegrated()
      .then(res => {
        if (this.isMount) {
          this.setState({
            customers: res.data,
            customersAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  filterData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  filterChangeProjectName = event => {
    if (this.isMount) {
      this.setState({
        projectAndCRs: this.filterData(event.filter, this.state.projectAndCRsAll)
      });
    }
  };

  filterChangeRegion = event => {
    if (this.isMount) {
      this.setState({
        region: this.filterData(event.filter, this.state.regionAll)
      });
    }
  };

  filterChangeCustomer = event => {
    if (this.isMount) {
      this.setState({
        customers: this.filterData(event.filter, this.state.customersAll)
      });
    }
  };

  handleInputChange = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  submitSearch = async event => {
    if (event) {
      event.preventDefault();
    } 

    if (this.isMount) {
      await this.setState({
        loading: true
      });
    }
    const fields = {
      Projects: this.state.selectedProjectName && this.state.selectedProjectName.length > 0 ? this.state.selectedProjectName : this.state.projectAndCRsAll,
      Region: this.state.selectedRegion,
      Customers: this.state.selectedCustomer,
    };

    if (fields.Projects && fields.Projects.length > 0) {
      await getPendingInvoiceRequests(fields)
      .then(res => {
        if (this.isMount) {
          this.setState({
            invoiceRequests: res.data,
            loading: false,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }   
      await this.setState({
        loading: false,
      });
  };

  cancelSearch = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        selectedProjectName: [],
        selectedRegion: [],
        selectedCustomer: []
      });
    }    
  };
  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Pending IR Creation</div>
          </div>
        </div>
        <form onSubmit={this.submitSearch}>
          <div className="main-heading" />
          
          <div className="row">

            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Project/CR Name:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedProjectName"
                    textField="DisplayName"
                    value={this.state.selectedProjectName}
                    data={this.state.projectAndCRs}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeProjectName}
                    onChange={this.handleInputChange} 
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Region:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedRegion"
                    textField="Name"
                    value={this.state.selectedRegion}
                    data={this.state.region}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeRegion}
                    onChange={this.handleInputChange} 
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Customer:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    className="text-break"
                    name="selectedCustomer"
                    textField="Name"
                    value={this.state.selectedCustomer}
                    data={this.state.customers}
                    placeholder={'Please Select'}
                    filterable={true}
                    onFilterChange={this.filterChangeCustomer}
                    onChange={this.handleInputChange} 
                  />
                </div>
              </div>
            </div>

          </div>

          <div className="row mt-3">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button type="button" onClick={this.cancelSearch}>
                Clear
              </Button>
            </div>
          </div>
        </form>

        <div className="main-seperator" />

        <div className="row">
          <div className="col-md-6">
            <div className="main-heading">Search Results</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
              <Grid
                data={orderBy(this.state.invoiceRequests, this.state.sort).slice(
                    this.state.skip,
                    this.state.take + this.state.skip
                )}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.invoiceRequests.length}
                resizable
                pageable={true}
                onPageChange={this.pageChange}
              >
                <Column field="Region" title="Region" />
                <Column field="ProjectName" title="Project/CR Name" />
                <Column field="Customer" title="Customer" />
                <Column field="BillingMethod" title="Billing Method" />
                <Column field="StartDate" title="Start Date" />
                <Column field="EndDate" title="End Date" />
                <Column field="Month" title="Month" />
                <Column field="StatusName" title="Status" />
                <Column field="" title="Actions" 
                  cell={props => 
                    <ActionsCell
                        {...props}
                    />
                  }
                />
              </Grid>
          </div>
        </div>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}

export default PendingIR;



