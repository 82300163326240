import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import {
  searchDesignationGroups,
  updateDesignationGroupStatus
} from './DesignationGroupService';
import CreatedDateCell from './components/CreatedDateCell';
import UpdatedDateCell from './components/UpdatedDateCell';
import StatusCell from './components/StatusCell';
import EditDesignationGroupActionsCell from './components/EditDesignationGroupActionsCell';

import { Link } from 'react-router-dom';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { orderBy } from '@progress/kendo-data-query';
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

class DesignationGroup extends Component {

  isMount = false;

  componentDidMount() {
    this.isMount = true;
    window.scrollTo(0, 0);
    this.setHeaderTitle();
    this.populateDesignationGroups();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      designationGroups: [],
      skip: 0,
      take: 20,
      SearchRequest: {
        DesignationGroupName: '',
        searchStatus: { Name: 'Active', status: true }
      },
      deactivateDesignationGroupId: 0,
      deactivatePopupVisible: false,
      activateDesignationGroupId: 0,
      activatePopupVisible: false,
      status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      loading: false
    };
  }

  setHeaderTitle = async () => {
    if (this.isMount) {
      this.props.onHeaderTitleChange('Designation Group');
    }
  };

  populateDesignationGroups = () => {
    searchDesignationGroups(this.state.SearchRequest).then(response => {
      if (this.isMount) {
        this.setState({
          designationGroups: response.data
        });
      }
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Designation Group Search</div>
          </div>

          <div className="col-md-4">
            <Button primary={true} className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('ED_DEG_GP') > -1)}>
              <Link
                style={{ textDecoration: 'none', color: 'white' }}
                to={{
                  pathname:
                    '/admin/createdesignationgroup/createdesignationgroup',
                  action: 'create'
                }}
              >
                Create New Designation Group
              </Link>
            </Button>
          </div>
        </div>

        <form onSubmit={this.submitSearch}>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                  <label>Designation Group Name:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <Input
                    value={this.state.SearchRequest.DesignationGroupName}
                    onChange={this.handleOnChangeInput}
                    name="DesignationGroupName"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Status:</label></div>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                    name="searchStatus"
                    textField="Name"
                    dataItemKey="status"
                    value={this.state.SearchRequest.searchStatus}
                    data={this.state.status}
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}/>
                </div>
              </div>
            </div>
            </div>

            <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
              <Button
                type="button"
                onClick={this.cancelSearch}
                style={{ marginRight: '5px' }}
              >
                Clear
              </Button>
            </div>
            </div>
          

          <div className="main-seperator"/>

          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">Search Results</div>
            </div>
          </div>

          <div className="row mt-0">
            <div className="col-md-12">
              <Grid
                data={orderBy(this.state.designationGroups, this.state.sort).slice(
                  this.state.skip,
                  this.state.take + this.state.skip
                )}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.designationGroups.length}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }
                }
                className="search-result-grid"
              >
                <Column field="Name" title="Designation Group"/>
                <Column
                  field="createdAt"
                  title="Created Date"
                  cell={props => <CreatedDateCell {...props} />}
                />
                <Column
                  field="updatedAt"
                  title="Last Modified Date"
                  cell={props => <UpdatedDateCell {...props} />}
                />
                <Column field="updatedUser" title="Last Modified User" width="120px"/>
                <Column
                  field="IsActive"
                  title="Status"
                  width="100px"
                  cell={props => <StatusCell {...props} />}
                />
                <Column
                  field=""
                  title="Actions"
                  width="150px"
                  cell={props => (
                    <EditDesignationGroupActionsCell
                      {...props}
                      OnDeactivate={this.OnDeactivate}
                      OnActivate={this.OnActivate}
                    />
                  )}
                />
              </Grid>
            </div>
          </div>
          <div>
            {this.state.deactivatePopupVisible && (
              <Dialog
                title={'Please Confirm'}
                onClose={this.deactivateConfirmationClose}
              >
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to Deactivate this?
                </p>
                <DialogActionsBar>
                  <button
                    className="k-button"
                    onClick={this.deactivateConfirmationClose}
                  >
                    No
                  </button>
                  <button
                    className="k-button modal-primary"
                    onClick={this.deactivateConfirmationOk}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.activatePopupVisible && (
              <Dialog
                title={'Please Confirm'}
                onClose={this.activateConfirmationClose}
              >
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to Activate this?
                </p>
                <DialogActionsBar>
                  <button
                    className="k-button"
                    onClick={this.activateConfirmationClose}
                  >
                    No
                  </button>
                  <button
                    className="k-button modal-primary"
                    onClick={this.activateConfirmationOk}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
            {this.state.showMessageDialog === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error"/>
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </form>
        <Loader loading={this.state.loading}/>
      </div>
    );
  }

  submitSearch = event => {
    console.log('Submit search');
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        loading: true
      });

      this.setState(
        prevState => {
          const SRequest = {
            ...prevState.SearchRequest
          };
          return {
            SearchRequest: SRequest
          };
        },
        () => {
          searchDesignationGroups(this.state.SearchRequest).then(res => {
            this.setState({
              designationGroups: res.data,
              loading: false,
              skip: 0
            });
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
        }
      );
    }
  };

  cancelSearch = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        SearchRequest: {
          DesignationGroupName: ''
        }
      });
    }
    this.populateDesignationGroups();
  };

  handleOnChangeInput = event => {
    const field = event.target.name;
    // const value = event.target.value;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    if (this.isMount) {
      this.setState(prevState => {
        return {
          SearchRequest: { ...prevState.SearchRequest, [field]: value }
        };
      });
    }
  };

  OnDeactivate = async (event, id) => {
    if (event) {
      event.preventDefault();
    }
    if (this.isMount) {
      await this.setState({
        deactivateDesignationGroupId: id
      });
    }
    this.deactivateConfirmationClose();
  };

  deactivateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: !this.state.deactivatePopupVisible
      });
    }
  };

  deactivateConfirmationOk = async () => {
    if (this.isMount) {
      await updateDesignationGroupStatus(
        { IsActive: false },
        this.state.deactivateDesignationGroupId
      )
        .then(response => {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
          this.toggleDialog(
            'The Designation Group has been Deactivated successfully',
            'Success'
          );
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      await this.populateDesignationGroups();
      await this.setState({
        deactivatePopupVisible: false
      });
    }
    ;
  };

  OnActivate = async (event, id) => {
    if (event) {
      event.preventDefault();
    }
    if (this.isMount) {
      await this.setState({
        activateDesignationGroupId: id
      });
    }
    this.activateConfirmationClose();
  };

  activateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        activatePopupVisible: !this.state.activatePopupVisible
      });
    }
  };

  activateConfirmationOk = async () => {
    if (this.isMount) {
      await updateDesignationGroupStatus(
        { IsActive: true },
        this.state.activateDesignationGroupId
      )
        .then(response => {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
          this.toggleDialog(
            'The Designation Group has been Activated successfully',
            'Success'
          );
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      await this.populateDesignationGroups();
      await this.setState({
        activatePopupVisible: false
      });
    };
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };
}

export default DesignationGroup;
