import React, { Component } from 'react'
import * as ReactDOM from 'react-dom';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';

export default class ProjectTaskGrid extends Component {
    state = { 
        skip: 0, 
        take: 10,
        visible: false
     }

    pageChange = (event) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        }, () => {
            this.props.loadResourceAllocation(this.props.task, this.props.taskdetail, false, this.state.skip)
          });
    }

    actionButtonCell = props => {
        let projectId = this.props.projectId;
        return (
          <td className="action-buttons">
              <a href="#" onClick={event => this.props.onClickResourceView(event, props.dataItem, projectId)}>
                <div className="rounded-link" title="Overall Resource View">
                  <span className="k-icon k-i-preview" />
                </div>
              </a>
          </td>
        );
    };
    
    render() {
        let data = this.props.data;

        return (
            <div>
                <Grid
                    data={data}
                    skip={this.state.skip}
                    take={this.state.take}
                    total={this.props.totalCount}
                    pageable={true}
                    onPageChange={this.pageChange}
                    resizable
                    // sortable={true}
                    // sort={this.state.sort}
                    // onSortChange={(e) => {
                    //     this.setState({
                    //         sort: e.sort
                    //     });
                    // }
                    // } 
                    >
                    <Column field="Name" title="Resource" />
                    {/* <Column field="DesignationName" title="Designation" /> */}
                    <Column field="DisplayDate" title="Assigned Duration" />
                    <Column field="AssignedHours" title="Assigned" />
                    <Column field="Utilized" title="Current Utilization" />
                    <Column field="Billable" title="Billable" />
                    <Column field="NonBillable" title="Non Billable" />
                    <Column field="Unapproved" title="Unapproved" />
                    <Column field="action" title="Action" width="150px" cell={ this.actionButtonCell } />
                </Grid>
            </div>
        )
    }
}
