import React, { Component } from "react";
import { Link } from "react-router-dom";

class EditDeployabilityActionCell extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    render() {
        const { OnEdit } = this.props;
        const dataItem = this.props.dataItem;
        return (
            <td class="action-buttons">
                {dataItem.EndDate == null && (
                <div class="rounded-link">
                    <Link
                        to={this.props}
                        onClick={event => OnEdit(event, this.props.dataItem)}
                    >
                        <span class="k-icon k-i-edit" title="Edit"/>
                    </Link>
                </div>
                )}
            </td>
        );
    }
}

export default EditDeployabilityActionCell;
