import React, { Component } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import '../../App.css';
import '../../ui/themes/Fortude.css';
import { getActiveProjectResources } from '../../resourceManagement/ResourceService';
import * as loggerService from '../../integration/LoggerService';
import { getApproverTypeByIdIntegrated } from '../../integration/MasterDataService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import { saveWorkflowApproversOnEdit } from './WorkflowService';

class ParallelCard extends Component {
  popupSet = {
    width: 'auto'
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  selectComponement = (
    filterChangeApprover,
    Approvers,
    selectedApproverValue,
    index,
    indexVer,
    indexPar,
    i,
    handleChangeDropDown,
    isEditMode,
    isDuplicateMode
  ) => {
    if (isEditMode) {
      let selectedApprover = [];
      Approvers['SelectedData'].map(item =>
        selectedApprover.push({
          id: item.SelectedId,
          Name: item.SelectedValue,
          DBGroupId: item.DBGroupId
        })
      );
      selectedApproverValue = selectedApprover;
    } else if (isDuplicateMode) {
      let selectedApprover = [];
      Approvers['SelectedData'].map(item =>
        selectedApprover.push({
          id: item.SelectedId,
          Name: item.SelectedValue,
          DBGroupId: item.DBGroupId
        })
      );
      selectedApproverValue = selectedApprover;
    }
    return (
      <div key={`${index}/${indexVer}/${indexPar}/${i}`}>
        <MultiSelect
          key={`${index}/${indexVer}/${indexPar}/${i}`}
          placeholder="Please select"
          name={`${index}/${indexVer}/${indexPar}/${i}`}
          data={Approvers['ApproverData']}
          textField="Name"
          dataItemKey="id"
          required={true}
          value={selectedApproverValue}
          filterable={true}
          onFilterChange={filterChangeApprover}
          onChange={handleChangeDropDown}
          className="customStyling"
        />
      </div>
    );
  };

  render() {
    const {
      onAddParallel,
      index,
      onRemoveParallel,
      indexVer,
      OnMinusApprovers,
      OnPlusApprovers,
      CardApprovers,
      approvalTypes,
      indexPar,
      filterChangeApprover,
      selectedApproverValue,
      handleChangeDropDown,
      isEditMode,
      isDuplicateMode,
      classN,
      handleOnChangeApprovalType
    } = this.props;

    let approvalType;
    let cssName = `col-md-6 btn-align-right ${classN}`;
    if (isEditMode) {
      approvalType = CardApprovers.approverType;
    } else if (isDuplicateMode) {
      approvalType = CardApprovers.approverType;
    }

    return (
      <div className="row add-parallel">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-5">
              <label className="mandatory">Approval Type:</label>
            </div>
            <div className="col-md-7">
              <ComboBox
                filterable={true}
                required={true}
                placeholder="Please select"
                name={`${index}/${indexVer}/${indexPar}`}
                data={approvalTypes}
                textField="ApproverTypeName"
                dataItemKey="id"
                onChange={handleOnChangeApprovalType}
                value={approvalType}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row add-item">
                    <div className="col-md-8">
                      {CardApprovers.approverListVer.map((item, i) => {
                        return this.selectComponement(
                          filterChangeApprover,
                          item,
                          selectedApproverValue,
                          index,
                          indexVer,
                          indexPar,
                          i,
                          handleChangeDropDown,
                          isEditMode,
                          isDuplicateMode
                        );
                      })}
                    </div>
                    <div className="col-md-4 btn-align-right button">
                      <Button
                        type="button"
                        className="k-button rounded-btn"
                        onClick={() => OnMinusApprovers(index, indexVer, indexPar)}
                      >
                        <span class="k-icon k-i-minus" />
                      </Button>
                      <Button
                        type="button"
                        className="k-button rounded-btn"
                        onClick={() => OnPlusApprovers(index, indexVer, indexPar)}
                      >
                        <span class="k-icon k-i-plus" />
                      </Button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 btn-align-left">
                      <Button
                        className="rounded-btn"
                        disabled={index === 0 && indexVer === 0}
                        type="button"
                        iconClass="k-icon k-i-close"
                        onClick={() => onRemoveParallel(index, indexVer)}
                      />
                    </div>
                    <div className={cssName}>
                      <Button
                        type="button"
                        onClick={() => onAddParallel(index)}
                        primary={true}
                        iconClass="k-icon k-i-plus"
                        className="rounded-btn-text"
                      >
                        Add Parallel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ParallelCard;
