import React, { Component } from 'react';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import {
  getRateCardsForGrid,
  getRateCardsForGridByStatus,
  updateRateCardStatus,
  searchRateCards,
  saveRateCardIncrements
} from './RateCardService';
import { getRegionsIntegrated, getMultiYearInformationIntegrated, deleteMultiYearGridInfoIntegrated, getcustomersIntegrated } from '../../integration/MasterDataService';
import DateTableCell from './components/DateTableCell';
import ActiveInactiveTableCell from './components/ActiveInactiveTableCell';
import RateCardActionsCell from './components/RateCardActionsCell';
import ConfirmationDialog from '../../integration/components/ConfirmationDialog';
import { getAllProjects } from '../../projectManagement/project/ProjectService';
import Loader from '../../integration/components/Loader';
import { Link } from 'react-router-dom';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import moment from 'moment';
import 'moment-timezone';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';


class RateCard extends Component {
  _export;
  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      rateCards: [],
      customers: [],
      projects: [],
      CustomersAll: [],
      selectedProject: [],
      skip: 0,
      take: 20,
      activatePopupVisible: false,
      deactivatePopupVisible: false,
      activateRateCardId: 0,
      deactivateRateCardId: 0,
      visible: false,
      dialogMessage: '',
      dialogTitle: '',
      searchRateCard: '',
      searchCustomer: [],
      searchRegion: [],
      regions: [],
      reigonAll: [],
      searchState: false,
      loading: false,
      statusArray: ['Active', 'Inactive'],
      searchStatus: 'Active',
      sort: [{ field: 'updatedAt', dir: 'desc' }]
    };

    this.exportData = this.exportData.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    this.setHeaderTitle();
    this.populateCustomers();
    this.populateRateCards();
    this.populateRegions();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  populateProjects = async (rateCards) => {

    this.allProjects = [];
    for(var rateCard of rateCards){

      var record = this.allProjects.find(i => i.id == rateCard.ProjectId);
      var isRecordNotContain = (record == undefined) ? true : false;

      if(rateCard.ProjectId != null && isRecordNotContain){
        var obj = {
          'id': rateCard.Project.id,
          'UniqueId': rateCard.Project.UniqueId,
          'OPID': rateCard.Project.OPID,
          'ProjectName': rateCard.Project.ProjectName,
          'displayName': rateCard.Project.UniqueId +" - "+rateCard.Project.ProjectName,
        }
        this.allProjects.push(obj);
      }
    }

    this.allProjects.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));

    if (this.isMount) {
      this.setState({
        projects: this.allProjects
      });
    }
    
  };

  populateRegions = async () => {
    getRegionsIntegrated().then(response => {
      if (this.isMount) {
        this.setState({
          regions: response.data,
          reigonAll: response.data
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCustomers = async () => {
    await getcustomersIntegrated().then(response => {
      if (this.isMount) {
        this.setState({
          customers: response.data,
          CustomersAll: response.data
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateRateCards = async () => {
    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    let activeStatus = false;

    if (this.state.searchStatus === 'Active') {
      activeStatus = true;
    } else if (this.state.searchStatus === 'Inactive') {
      activeStatus = false;
    }

    await getRateCardsForGridByStatus({ IsActive: activeStatus }).then(response => {
      if (this.isMount) {
        this.setState({
          loading: false,
          rateCards: response.data
        });
      }

      // Load all the projects related to rate cards
      this.populateProjects(response.data);
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  filterProjectOnChange = event => {
    this.setState({
      projects: this.filterProjectData(event.filter)
    });
  };

  filterProjectData(filter) {
    const data = this.allProjects.slice();
    return filterBy(data, filter);
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Rate Card');
    }
  };

  handleInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    if (this.isMount) {
      await this.setState({ [name]: value, isFormDirty: true });
    }
  };

  OnActivate = async id => {
    if (this.isMount) {
      await this.setState({
        activateRateCardId: id
      });
    }
    this.activateConfirmationToggle();
  };

  OnDeactivate = async id => {
    if (this.isMount) {
      await this.setState({
        deactivateRateCardId: id
      });
    }
    this.deactivateConfirmationToggle();
  };

  deactivateConfirmationToggle = async () => {
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: !this.state.deactivatePopupVisible
      });
    }
  };

  deleteMultiYearGridInfo = async () => {
    getMultiYearInformationIntegrated().then(response => {
      let yearRecords = response.data;
      if (yearRecords.length > 0) {
        for (const item of yearRecords) {
          if (item.id) {
            const multiYearRateCardData = item.MultiyearIncrementGrids.find(gridDetails => {
              return gridDetails.RateCardId === this.state.deactivateRateCardId;
            });
            if (multiYearRateCardData) {
              const data = {
                id: multiYearRateCardData.id
              };
              deleteMultiYearGridInfoIntegrated(data);
            }
          }
        }
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  deactivateConfirmationOk = async () => {
    this.setState({ loading: true, deactivatePopupVisible: false });

    await updateRateCardStatus({ IsActive: false }, this.state.deactivateRateCardId)
      .then(async response => {
        const promises = [];
        if (this.state.searchState) {
          promises.push(this.submitSearch());
        } else {
          promises.push(this.populateRateCards());
        }

        promises.push(this.deleteMultiYearGridInfo());

        Promise.all(promises).then(() => {
          if (this.isMount) {
            this.setState({
              loading: false,
              showSuccessImage: true,
              showErrorImage: false
            });
          }
          this.toggleDialog('The Rate Card has been Deactivated successfully', 'Success');
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  activateConfirmationToggle = async () => {
    if (this.isMount) {
      await this.setState({
        activatePopupVisible: !this.state.activatePopupVisible
      });
    }
  };

  addRecordtoMutiYear = async () => {
    let data = [];
    getMultiYearInformationIntegrated().then(response => {
      let yearRecords = response.data;
      if (yearRecords.length > 0) {
        for (const item of yearRecords) {
          if (item.id) {
            const multiYearRateCardData = item.MultiyearIncrementGrids.find(gridDetails => {
              return gridDetails.RateCardId === this.state.activateRateCardId;
            });
            if (!multiYearRateCardData) {
              const rateData = {
                MultiyearInfoGridId: item.id,
                RateCardId: this.state.activateRateCardId,
                Type: 'Rate Card'
              };
              data.push(rateData);
            }
          }
        }
        if (data.length > 0) {
          saveRateCardIncrements(data);
        }
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  activateConfirmationOk = async () => {
    if (this.isMount) {
      this.setState({
        loading: true, activatePopupVisible: false
      });
    }

    await updateRateCardStatus({ IsActive: true }, this.state.activateRateCardId)
      .then(async response => {
        const promises = [];
        if (this.state.searchState) {
          promises.push(this.submitSearch());
        } else {
          promises.push(this.populateRateCards());
        }

        promises.push(this.addRecordtoMutiYear());

        Promise.all(promises).then(() => {
          if (this.isMount) {
            this.setState({
              loading: false,
              showSuccessImage: true,
              showErrorImage: false
            });
          }
          this.toggleDialog('The Rate Card has been Activated successfully', 'Success');
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  submitSearch = event => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      loading: true
    });

    let searchStatus = null;

    if (this.state.searchStatus === 'Active') {
      searchStatus = true;
    } else if (this.state.searchStatus === 'Inactive') {
      searchStatus = false;
    }

    const searchRequest = {
      rateCardName: this.state.searchRateCard,
      regionId: this.state.searchRegion.map(value => value.id),
      customerId: this.state.searchCustomer.map(value => value.id),
      projectId: this.state.selectedProject.map(value => value.id),
      status: searchStatus
    };

    searchRateCards(searchRequest)
      .then(res => {
        if (this.isMount) {
          this.setState({
            searchState: true,
            rateCards: res.data,
            loading: false,
            skip: 0,
            take: 20
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            searchState: false,
            loading: false
          });
        }
      });
  };

  cancelSearch = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        searchRateCard: '',
        searchCustomer: [],
        searchRegion: [],
        searchStatus: '',
        selectedProject: []
      });
    }
    this.populateCustomers();
    this.populateRateCards();
    this.populateRegions();
  };

  filterOnChange = event => {
    clearTimeout(this.timeout);
    const field = event.target.name;
    this.timeout = setTimeout(() => {
      switch (field) {
        case 'searchCustomer': {
          if (this.isMount) {
            this.setState({
              customers: this.filterComboData(event.filter, this.state.CustomersAll)
            });
          }
          break;
        }
        case 'searchRegion': {
          if (this.isMount) {
            this.setState({
              regions: this.filterComboData(event.filter, this.state.reigonAll)
            });
          }
          break;
        }
        default: {
        }
      }
    }, 0);
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  handleInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    if (this.isMount) {
      await this.setState({ [name]: value, isFormDirty: true });
    }
  };

  exportData = event => {
    try {
      let sortedRateCards = orderBy(this.state.rateCards, this.state.sort)
      if (sortedRateCards && sortedRateCards.length > 0) {
        const ExportData = [];
        sortedRateCards.forEach(obj => {
          if (obj.RateCardRoles && obj.RateCardRoles.length > 0) {
            var withRates = obj.RateCardRoles.filter(x => x.Rate > 0);
            if (withRates && withRates.length > 0) {
              withRates.forEach(x => {
                let object = {
                  Name: obj.Name,
                  Region: obj.Region ? obj.Region.Name : '',
                  Customer: obj.Customer ? obj.Customer.Name :'',
                  Project: obj.Project ? obj.Project.ProjectName : '',
                  IsProjectCr: obj.IsProjectCr,
                  createdAt: moment(obj.createdAt).format("MM/DD/YYYY"),
                  updatedAt: moment(obj.updatedAt).format("MM/DD/YYYY"),
                  StartDate: moment(obj.StartDate).format("MM/DD/YYYY"),
                  EndDate: moment(obj.EndDate).format("MM/DD/YYYY"),
                  Designation: x.Role? x.Role.Name:'',
                  CurrentRate: x.Rate,
                  NewRate: x.Rate,
                  IsActive: obj.IsActive ? 'Active' : 'Inactive',
                  IsDesignationActive: x.Role.IsActive ? 'Active' : 'Inactive'
                };

                ExportData.push(object);
              })
            }
          }
        });
        if (ExportData && ExportData.length > 0) {
          this._export.save(ExportData);
        }
      }

    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  }

  render() {
    return (

        <div className="main-card">
          <div className="row">
            <div className="col-md-8">
              <div className="main-heading">Rate Card Search</div>
            </div>
            <div className="col-md-4">
              <Button primary={true} className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('EDT_RATE_CRD') > -1)}>
                <Link
                  style={{ textDecoration: 'none', color: 'white' }}
                  to={{
                    pathname: '/admin/ratecardedit/ratecardedit',
                    action: 'create'
                  }}
                >
                  Create New Rate Card
                </Link>
              </Button>
            </div>
          </div>
          <form onSubmit={this.submitSearch}>
          <div className="row" />

          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                  <label>Rate Card Name:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <Input
                    value={this.state.searchRateCard}
                    onChange={this.handleInputChange}
                    name="searchRateCard"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                  <label className="mandatory">
                    Region:
                  </label>
                </div>
                <div className="d-block">
                <div className="">
                  <MultiSelect
                    name="searchRegion"
                    textField="Name"
                    dataItemKey="id"
                    value={this.state.searchRegion}
                    data={this.state.regions}
                    placeholder={'Select Region'}
                    onChange={this.handleInputChange}
                    filterable={true}
                    onFilterChange={this.filterOnChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                  <label>
                    Customer:
                  </label>
                </div>
                <div className="d-block">
                <div className="">
                  <MultiSelect
                    name="searchCustomer"
                    textField="Name"
                    dataItemKey="id"
                    value={this.state.searchCustomer}
                    data={this.state.customers}
                    placeholder={'Select Customer'}
                    onChange={this.handleInputChange}
                    filterable={true}
                    onFilterChange={this.filterOnChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label className="optional">
                  Project:
                  </label>
              </div>
              <div className="d-block">
                <div className="">
                  <MultiSelect
                    name="selectedProject"
                    textField="displayName"
                    dataItemKey="id"
                    value={this.state.selectedProject}
                    data={this.state.projects}
                    placeholder={'Select Project'}
                    onChange={this.handleInputChange}
                    filterable={true}
                    onFilterChange={this.filterProjectOnChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-block">
                  <label>Status:</label>
                </div>
                <div className="d-block">
                <div className="">
                  <ComboBox
                    name="searchStatus"
                    value={this.state.searchStatus}
                    data={this.state.statusArray}
                    placeholder={'Select status'}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12" align={'right'}>
              <Button type="button" style={{ marginRight: '5px' }} onClick={this.cancelSearch}>
                Clear
              </Button>
              <Button primary={true} type="submit" onClick={this.submitSearch}>
                Search
              </Button>
            </div>
          </div>
          </form>
          <div className="main-seperator" />

          <div className="row">
            <div className="col-md-10">
              <div className="main-heading">Search Results</div>
            </div>
            <div className="col-md-2 btn-align-right">
                            <button
                                title="Export to Excel"
                                className="k-button k-primary"
                                onClick={this.exportData}>
                                Export to Excel
                            </button>
                        </div>
          </div>

          <div className="row mt-0">
            <div className="col-md-12">
            <ExcelExport
              ref={(exporter) => {
                this._export = exporter;
              }}
              fileName={'RateCards ' + new Date()}
            >
              <ExcelExportColumn field="Name" title="Rate Card Name" />
              <ExcelExportColumn field="Region" title="Region" />
              <ExcelExportColumn field="Customer" title="Customer" />
              <ExcelExportColumn field="Project" title="Project" />
              <ExcelExportColumn field="IsProjectCr" title="Contain CR Rates" />
              <ExcelExportColumn field="createdAt" title="Created Date" />
              <ExcelExportColumn field="updatedAt" title="Last Modified Date" />
              <ExcelExportColumn field="StartDate" title="Validity Period From" />
              <ExcelExportColumn field="EndDate" title="Validity Period To" />
              <ExcelExportColumn field="Designation" title="Designation" />
              <ExcelExportColumn field="CurrentRate" title="Current Rate" />
              <ExcelExportColumn field="NewRate" title="New Rate" />
              <ExcelExportColumn field="IsDesignationActive" title="Designation Status" />
              <ExcelExportColumn field="IsActive" title="Status" />
            </ExcelExport>
              {
                <Grid
                  data={orderBy(this.state.rateCards, this.state.sort).slice(
                    this.state.skip,
                    this.state.take + this.state.skip
                  )}
                  skip={this.state.skip}
                  take={this.state.take}
                  total={this.state.rateCards.length}
                  pageable={true}
                  onPageChange={this.pageChange}
                  resizable
                  sortable={true}
                  sort={this.state.sort}
                  onSortChange={(e) => {
                    this.setState({
                      sort: e.sort
                    });
                  }
                  }
                  className="search-result-grid"
                >
                  <Column field="Name" title="Rate Card Name" />
                  <Column field="Region.Name" title="Region" />
                  <Column field="Customer.Name" title="Customer" />
                  <Column
                    field="Project.ProjectName"
                    title="Project"
                  />
                  <Column
                    field="IsProjectCr"
                    title="Contain CR Rates"
                  />
                  <Column
                    field="createdAt"
                    title="Created Date"
                    cell={props => <DateTableCell value={props.dataItem.createdAt} />}
                  />
                  <Column
                    field="updatedAt"
                    title="Last Modified Date"
                    cell={props => <DateTableCell value={props.dataItem.updatedAt} />}
                  />
                  <Column field="updatedUser" title="Last Modified User" width="120px"/>
                  <Column
                    field="IsActive"
                    title="Status"
                    width="100px"
                    cell={props => <ActiveInactiveTableCell value={props.dataItem.IsActive} />}
                  />
                  <Column
                    title="Actions"
                    width="150px"
                    cell={props => (
                      <RateCardActionsCell
                        {...props}
                        OnDeactivate={this.OnDeactivate}
                        OnActivate={this.OnActivate}
                      />
                    )}
                  />
                </Grid>
              }
            </div>
          </div>

          <div>
            {this.state.deactivatePopupVisible === true && (
              <ConfirmationDialog
                dialogTitle={'Please Confirm'}
                dialogMessage={'Are you sure you want to Deactivate this?'}
                dismissCallback={this.deactivateConfirmationToggle}
                abortCallback={this.deactivateConfirmationToggle}
                confirmCallback={this.deactivateConfirmationOk}
              />
            )}
          </div>

          <div>
            {this.state.activatePopupVisible && (
              <ConfirmationDialog
                dialogTitle={'Please Confirm'}
                dialogMessage={'Are you sure you want to Reactivate this?'}
                dismissCallback={this.activateConfirmationToggle}
                abortCallback={this.activateConfirmationToggle}
                confirmCallback={this.activateConfirmationOk}
              />
            )}
          </div>
          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success" />
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error Image" />
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>

          <Loader loading={this.state.loading} />
        </div>
    );
  }
}

export default RateCard;
