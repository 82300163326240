import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;


/**
 * saveTaskType - creates a new task type in the TaskType table
 * @returns {object}  created task tasktype
 * @param condition
 */
export function saveTaskTypes(params) {
    const endPoint = apiUrl + '/masterData/taskType/saveTaskTypes';
    return http.post(endPoint, params);
  }

/**
 * getAllTaskTypes - retrieves all the tasktypes in the database
 * @returns {array}  - all data in TaskType table
 * @param empty
 */
  export function getAllTaskTypes() {
    const endPoint = apiUrl + '/masterData/taskType/allTaskTypes';
    return http.get(endPoint);
  }

/**
 * searchTaskType - search all task types with a given condition
 * @returns {array} - array of objects with the given conditions
 * @param condition 
 */

  export function searchTaskType(params) {
    const endPoint = apiUrl + '/masterData/taskType/searchTaskTypes';
    return http.post(endPoint, params);
  }

  /**
 * updateTaskType - updates the task type 
 * @returns {object} - updated task type
 * @param condition 
 */
  export function updateTaskType(params) {
    const endPoint = apiUrl + '/masterData/taskType/updateTask';
    return http.put(endPoint, params);
  }

 


