import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip, UncontrolledTooltip } from "reactstrap";

class ActivityLogFilesCell extends Component{
    render(){
        const apiUrl = this.props.ApiUrl;
        const projectId = this.props.dataItem.CrId == null ? this.props.dataItem.ProjectId : this.props.dataItem.CrId;
        const fileUploads = this.props.dataItem.FileUpload;
        const activityLogData = this.props.dataItem;
        const name = this.props.name;

        return(
            <td className="">
                {fileUploads.map((item, index) => (
                    <a key={item.id} 
                        href={`${apiUrl}/integration/fileUpload/downloadstsfile?path=StatusChange_${projectId}_${encodeURIComponent(item.FileName)}&&stsFileName=${encodeURIComponent(item.FileName)}`} 
                        target=""
                    >  
                        <div>                 
                            <span id={`file-${item.id}`} style={{textDecorationLine: "underline"}}>
                                {item.DocumentType}
                            </span>
                            <span>{index < fileUploads.length - 1 ? ' , ' : ''}</span>
                        </div>

                        <UncontrolledTooltip modifiers={{preventOverflow: {boundariesElement: 'window'}}} placement="bottom" target={`file-${item.id}`} className="activityLogTooltip">
                            {item.FileName}
                        </UncontrolledTooltip>
                    </a>
                ))}
            </td>
        );
    }
}

export default ActivityLogFilesCell;