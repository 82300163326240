import React, { Component } from 'react';

class ProjectRoleCell extends Component {
    render() {
        const value = this.props.dataItem.IsProjectRole;

        if (value) {
            return <td>Yes</td>;
        } else {
            return <td>No</td>;
        }

    }
}

export default ProjectRoleCell;
