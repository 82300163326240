import React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import moment from 'moment';

import PhaseRow from './components/PhaseRow';
import { getFormattedDate, reStructureDate } from "../../integration/CommonUtils";

class ProjectSubPhase extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props);

        this.state = {
            subPhaseName: '',
            selectedPhaseIndex: -1,
            phasesList: [],
            startDate: null,
            startDateMin: null,
            startDateMinEditMode: null,
            startDateIsaPastDate: false,
            updateMode:false,
            updatedSubPhaseId:null,
            isDiry:false,
            showConfirmDialog:false,
            confirmDialogMessage:'',
            confirmDialogAction:null ,
            startDateExist:false,
            sPhaseIndex:null
        };
    }

    componentDidMount() {
        this.populatePhases();
        this.setStartDateMin();
    }

    toggleConfirmDialog = (message, action) => {
        this.setState({
          showConfirmDialog: !this.state.showConfirmDialog,
          confirmDialogMessage: message,
          confirmDialogAction: action
        });
      };

    setStartDateMin = () => {
        let phase = null;
        let startDateMin = null;
        let startDate = this.state.startDate;

        if (this.state.selectedPhaseIndex > -1) {
            phase = this.props.phases[this.state.selectedPhaseIndex];
        }

        if (phase && phase.StartDate) {
            startDateMin = reStructureDate(phase.StartDate);
        } else if (this.props.project && this.props.project.StartDate && this.props.Ref === 'projectCMS') {
            startDateMin = reStructureDate(this.props.project.StartDate);
        } else if (this.props.changeRequest && this.props.changeRequest.StartDate && this.props.Ref === 'crCMS') {
            startDateMin = reStructureDate(this.props.changeRequest.StartDate);
        }
        else {
            startDateMin = new Date();
        }

        if (startDate < startDateMin) {
            startDate = null;
        }

        startDateMin.setHours(0, 0, 0, 0);
        this.setState({ startDateMin: startDateMin, startDate: startDate });
    };

    populatePhases = () => {
        this.setState({
            phasesList: this.props.phases.map(obj => obj.Text)
        });
    };

    closeDialog = () => {
        this.props.closeMethod();
    };

    handleInputChange = event => {
        const value = event.target.value;
        const field = event.target.name;
        this.setState({
            [field]: value,
            isDiry:true
        });
    };

    handlePhaseChange = event => {
        const value = event.target.value;
        this.setState(
            prevState => {
                return {
                    selectedPhaseIndex: prevState.phasesList.indexOf(value)
                };
            },
            () => {
                this.setStartDateMin();
            }
        );
    };

    onStartDateChange = event => {
        let startDate = null
        this.setState({
            startDateExist:false
        })
        if(event.value === null){
            startDate = null;
        }
        else {
            startDate = event.target.value;
        }

        if ((startDate && startDate >= this.state.startDateMin) || startDate === null) {
            this.setState({
                startDateIsaPastDate: false
            })
        }
        else {
            this.setState({
                startDateIsaPastDate: true
            })
        }

        this.setState(prevState => {
            return {
                startDate: startDate
            };
        });
    };
    
    validation=()=>{
        if(this.state.startDate === null){
            this.setState({
                startDateExist:true
            })
            return false
        }else{
            return true
        }
    }

    addSubPhase = () => {
        if(this.validation()){
            if ( (this.state.startDate && this.state.startDate >= this.state.startDateMin) || this.state.startDate === null) {
                this.setState({
                    startDateIsaPastDate: false
                });
                let startDateStr = null;
                if (this.state.startDate) {
                    startDateStr = getFormattedDate(this.state.startDate);
                }
    
                this.props.onAddSubPhase(this.state.selectedPhaseIndex, this.state.subPhaseName, startDateStr);
            }
            else {
                this.setState({
                    startDateIsaPastDate: true
                });
            }
            this.setState({
                subPhaseName: '',
                startDate: '',
                selectedPhaseIndex : -1,
                updateMode:false
            });
    
        }
    };
    
    updateSubPhase = () => {
        if(this.validation()){
            if ( (this.state.startDate && this.state.startDate >= this.state.startDateMin) || this.state.startDate === null) {
                this.setState({
                    startDateIsaPastDate: false
                });
                let startDateStr = null;
                if (this.state.startDate) {
                    startDateStr = getFormattedDate(this.state.startDate);
                }
    
                this.props.onUpdateSubPhase(this.state.selectedPhaseIndex, this.state.subPhaseName, startDateStr,this.state.sPhaseIndex);
            }
            else {
                this.setState({
                    startDateIsaPastDate: true
                });
            }
            this.setState({
                subPhaseName: '',
                startDate: '',
                selectedPhaseIndex : -1,
                isDiry:true,
                updateMode:false
            });
    
        }
    };

    removeSubPhase = (phaseIndex, subPhaseIndex) => {
        this.props.onRemoveSubPhase(phaseIndex, subPhaseIndex);
    };
    
    onUpdateSubPhase = (phaseIndex, subPhaseIndex) => {
        let selectedSubPhase = this.props.phases[phaseIndex].SubPhases[subPhaseIndex];
        this.setState({
            updateMode:true,
            subPhaseName: selectedSubPhase.Text,
            selectedPhaseIndex: phaseIndex,
            updatedSubPhaseId: selectedSubPhase.id,
            startDate: reStructureDate(selectedSubPhase.StartDate),
            isDiry:true,
            sPhaseIndex:subPhaseIndex,
            startDateMinEditMode:this.props.phases[phaseIndex].StartDate
        })
    };

    handleSave = () => {
        this.props.onSave();
        this.closeDialog();
    };
    
    handleUpdate = () => {
        this.props.onUpdate();
        this.closeDialog();
    };

    handleCancel = () => {
        if(this.state.isDiry){
            this.toggleConfirmDialog(
                'This form contains unsaved data. Do you want to close it?',
                ()=>{
                    this.props.onCancel();
                    this.closeDialog();
                }
              );
        }else{
            this.props.onCancel();
            this.closeDialog();
        }
    };

    render() {
        return (
            <div>
                <Dialog title={'Define Project Sub-Phases'} onClose={this.handleCancel} width={400}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <label for="">Sub-Phase Name:</label>
                                </div>
                                <div className="col-md-8">
                                    <Input
                                        value={this.state.subPhaseName}
                                        onChange={this.handleInputChange}
                                        name="subPhaseName"
                                        autoComplete="off"
                                    />
                                </div>
                                {/* <div className="col-md-2">
                  <Button
                    className="rounded-btn"
                    disabled={!this.state.subPhaseName || this.state.selectedPhaseIndex < 0}
                    onClick={this.addSubPhase}
                  >
                    <span className="k-icon k-i-plus" />
                  </Button>
                </div> */}
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <label for="">Phase:</label>
                                </div>
                                <div className="col-md-8">
                                    <DropDownList disabled={this.state.updateMode} value={this.state.selectedPhaseIndex === -1 ? "" : this.state.phasesList[this.state.selectedPhaseIndex]} data={this.state.phasesList} onChange={this.handlePhaseChange} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <label for="">Start Date:</label>
                                </div>

                                {this.props.endDate  && (
                                <div className="col-md-8">
                                    <DatePicker
                                        value={this.state.startDate}
                                        onChange={this.onStartDateChange}
                                        name="startDate"
                                        min={!this.state.updateMode?this.state.startDateMin:reStructureDate(this.state.startDateMinEditMode)}
                                        format="MM/dd/yyyy"
                                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                        max={this.props.endDate}
                                    />
                                    { this.state.startDateIsaPastDate=== true && (
                                        <label className="text-red">sub-phase start date is a past date</label>
                                    )}
                                </div>
                                )}

                                {this.props.endDate === null  && (
                                    <div className="col-md-8">
                                        <DatePicker
                                            value={this.state.startDate}
                                            onChange={this.onStartDateChange}
                                            name="startDate"
                                            min={!this.state.updateMode?this.state.startDateMin:reStructureDate(this.state.startDateMinEditMode)}
                                            format="MM/dd/yyyy"
                                            formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                        />
                                        { this.state.startDateIsaPastDate=== true && (
                                            <label className="text-red">sub-phase start date is a past date</label>
                                        )}
                                        { this.state.startDateExist === true && (
                                            <label className="text-red">sub-phase start date mandatory</label>
                                        )}
                                    </div>
                                )}



                            </div>
                            <div className="row">
                                <div className="col-md-12 phase-button btn-align-right">
                                    {!this.state.updateMode?
                                    <Button
                                        className="k-button project-phase"
                                        disabled={!this.state.subPhaseName || this.state.selectedPhaseIndex < 0}
                                        onClick={this.addSubPhase}
                                    > Add Sub-Phase
                                    </Button>:
                                    <Button
                                        className="k-button project-phase"
                                        disabled={!this.state.subPhaseName || this.state.selectedPhaseIndex < 0}
                                        onClick={this.updateSubPhase}
                                    > Update Sub-Phase
                                    </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-12">
                            {this.props.phases.map((value, index) => {
                                return (
                                    <PhaseRow
                                        phase={value}
                                        isEditPhases={false}
                                        isEditSubPhases={true}
                                        onRemoveSubPhase={this.removeSubPhase}
                                        onUpdateSubPhase={this.onUpdateSubPhase}
                                        key={index}
                                        phaseIndex={index}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <DialogActionsBar>
                        <button className="k-button modal-primary" onClick={this.handleCancel}>
                            Cancel
                        </button>
                        <button
                            className="k-button"
                            disabled={ !this.state.isDiry || this.state.subPhaseName || this.state.startDate}
                            onClick={this.handleSave}
                        >
                            Save
                        </button>
                    </DialogActionsBar>
                </Dialog>
                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                        <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleConfirmDialog}>
                            No
                        </button>
                        <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                            Yes
                        </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </div>
        );
    }
}

export default ProjectSubPhase;
