import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Redirect } from 'react-router';
import { filterBy } from '@progress/kendo-data-query';

import {
  saveCostCard
} from './CostCardService';
import { getAllProjects } from '../../projectManagement/project/ProjectService';

import { getDesignationsIntegrated, getRegionInfoIntegrated, getMultiYearInformationIntegrated, saveCostCardIncrementsIntegrated } from '../../integration/MasterDataService';
import 'moment-timezone';
import moment from 'moment/moment';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import $ from "jquery";

class CreateCostCard extends Component {

  isMount = false;

  constructor(props) {
    super(props);

    this.state = {
      showMessageDialog: false,

      designationsList: [],
      data: [],
      editID: null,
      billingRegions: [],
      resourceRegions: [],
      projects: [],

      costCardName: '',
      selectedBillingRegion: null,
      selectedResourceRegion: null,
      startDate: null,
      endDate: null,
      selectedProject: null,

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      toCostCard: false,
      isDisableSave: false,

      isFormDirty: false,
      visible: false,
      success: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      minStartDate: null
    };
  }

  async componentDidMount() {

    $(document).ready(function () {
      $("div.title-remove > span > span > span:last-child").removeAttr("title");
    });

    this.isMount = true;
    if (this.isMount) {
      this.props.onHeaderTitleChange('Create Cost Card');
    }
    this.regionDetails();
    this.retrieveDesignations();
    this.populateProjects();
    this.setMinStartDate();
  }

  retrieveDesignations = () => {
    getDesignationsIntegrated()
      .then(res => {
        const updatedCostCard = res.data.map(dataItem =>
          Object.assign({ Overhead: 0, CostToCompany: 0 }, dataItem)
        );
        if (this.isMount) {
          this.setState({
            designationsList: res.data,
            data: updatedCostCard.slice(0)
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  componentWillUnmount() {
    this.isMount = false;
  }

  populateProjects = async () => {
    await getAllProjects().then(response => {
      this.allProjects = [];

      for (var project of response.data) {
        if (project.IsActive) {
          var obj = {
            'id': project.id,
            'UniqueId': project.UniqueId,
            'OPID': project.OPID,
            'ProjectName': project.ProjectName,
            'displayName': project.UniqueId + " - " + project.ProjectName,
          }
          this.allProjects.push(obj);
        }
      };

      this.allProjects.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));

      if (this.isMount) {
        this.setState({
          projects: this.allProjects
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  async regionDetails() {
    getRegionInfoIntegrated()
      .then(res => {

        let allRegions = res.data.filter(obj => obj.IsActive === true);

        if (this.isMount) {
          this.setState({
            regions: allRegions
          });
        }

        const resourceRegion = [];
        const billingRegion = [];
        allRegions.forEach(function (region) {
          if (region.CostRegion === false) {
            billingRegion.push(region);
          }
          resourceRegion.push(region);
        });

        if (this.isMount) {
          this.setState({
            billingRegions: billingRegion,
            resourceRegions: resourceRegion
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  setMinStartDate = async () => {
    let minDate = new Date();
    const currentYear = minDate.getFullYear();
    const currentMonth = minDate.getMonth() + 1;
    if (currentMonth == 1 || currentMonth == 2 || currentMonth == 3) { // check if the month is Jan/Feb/March
      let prevYear = (currentYear - 1).toString();
      minDate = prevYear + '-04-01';
    } else {
      minDate = currentYear + '-04-01';
    }
    minDate = new Date(moment(minDate).format("YYYY-MM-DD"));
    await this.setState({
      minStartDate: minDate
    });
  };

  handleChangeCostCardName = async event => {
    if (this.isMount) {
      await this.setState({
        costCardName: event.target.value,
        isFormDirty: true
      });
    }
    await this.validateProperty(event.target.value);
  };

  handleBillingRegionChange = async event => {
    if (this.isMount) {
      await this.setState({
        selectedBillingRegion: event.target.value,
        isFormDirty: true
      });
    }
    await this.validateProperty(event.target.value);
  };

  handleResourceRegionChange = async event => {
    if (this.isMount) {
      await this.setState({
        selectedResourceRegion: event.target.value,
        isFormDirty: true
      });
    }
    await this.validateProperty(event.target.value);
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  render() {
    if (this.state.toCostCard === true) {
      return <Redirect to="/admin/costcard/search" />;
    }
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Create Cost Card</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="d-block">
              <label className="mandatory">Cost Card Name:</label>
            </div>
            <div className="d-block">
              <div className="">
                <Input
                  autoComplete="off"
                  type="text"
                  name="costCardName"
                  required={true}
                  maxLength="100"
                  value={this.state.costCardName}
                  onChange={this.handleChangeCostCardName}
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.costCardName)}>
                    Cost Card Name cannot be empty
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-2">
            <div className="d-block">
              <label className="mandatory">Billing Region:</label>
            </div>
            <div className="d-block">
              <div className="">
                <ComboBox
                  data={this.state.billingRegions}
                  textField="Name"
                  dataItemKey="id"
                  required={true}
                  value={this.state.selectedBillingRegion}
                  onChange={this.handleBillingRegionChange}
                  placeholder="Please Select"
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.selectedBillingRegion)}>
                    Please select billing region
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-md-1"></div>
          <div className="col-md-2">
            <div className="d-block">
              <label className="mandatory">Resource Region:</label>
            </div>
            <div className="d-block">
              <div className="">
                <ComboBox
                  data={this.state.resourceRegions}
                  textField="Name"
                  dataItemKey="id"
                  required={true}
                  value={this.state.selectedResourceRegion}
                  onChange={this.handleResourceRegionChange}
                  placeholder="Please Select"
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.selectedResourceRegion)}>
                    Please select resource region
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-2">
            <div className="d-block">
              <label className="optional">
                Project:
              </label>
            </div>
            <div className="d-block">
              <div className="">
                <ComboBox
                  name="selectedProject"
                  textField="displayName"
                  dataItemKey="id"
                  value={this.state.selectedProject}
                  data={this.state.projects}
                  placeholder={'Please Select'}
                  onChange={this.handleProjectChange}
                  filterable={true}
                  onFilterChange={this.filterProjectOnChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-2">
            <div className="d-block">
              <label className="mandatory">Validity Period: From:</label>
            </div>
            <div className="d-block">
              <div className="title-remove">
                <DatePicker
                  width="100"
                  name="startDate"
                  onChange={this.handleStartDateChange}
                  required={true}
                  value={this.state.startDate}
                  format="MM/dd/yyyy"
                  min={new Date(this.state.minStartDate)}
                  formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.startDate)}>
                    Date cannot be empty
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-2">
            <div className="d-block">
              <label className="mandatory">To:</label>
            </div>
            <div className="d-block">
              <div className="title-remove">
                <DatePicker
                  width="100"
                  name="endDate"
                  onChange={this.handleEndDateChange}
                  required={true}
                  value={this.state.endDate}
                  format="MM/dd/yyyy"
                  min={new Date()}
                  formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                />
                {this.state.isErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.endDate)}>
                    Date cannot be empty
                  </span>
                ) : null}
              </div>
            </div>
          </div>

        </div>
        <br></br>
        <div className="row">
          <div className="col-md-12">
            <Grid
              resizable
              data={this.state.data.map(item =>
                Object.assign(
                  {
                    inEdit: item.id === this.state.editID
                  },
                  item
                )
              )}
              editField="inEdit"
              onRowClick={this.rowClick}
              onItemChange={this.designationItemChange}
            >
              <Column field="Name" title="Designation" editable={false} />
              <Column field="CostToCompany" title="Direct cost per day" editor="numeric" />
              <Column field="Overhead" title="Overhead" editor="numeric" />
            </Grid>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 btn-align-right">
            <Button
              primary={true}
              disabled={this.state.isDisableSave}
              type="submit"
              onClick={this.handleSubmit}
            >
              Save
            </Button>
            <Button type="button" onClick={this.onClickCancel}>
              Cancel
            </Button>
          </div>
        </div>
        <div>
          {this.state.visible === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.onClickClose} width="400px">

              {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success" />
                </div>
              )}

              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image" />
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

              <DialogActionsBar>
                {this.state.showSuccessImage === true && (
                  <button className="k-button modal-primary" onClick={this.navigateBack}>
                    OK
                  </button>
                )}

                {this.state.showErrorImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleDialog}>
                    OK
                  </button>
                )}
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <div>
          {this.state.showCancelConfirmation === true && (
            <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
              <p style={{ margin: '25px', textAlign: 'center' }}>
                This form contains unsaved changes. Do you want to close it?
              </p>
              <DialogActionsBar>
                <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.navigateBack}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
      </div>
    );
  }

  handleStartDateChange = event => {
    let startDate = event.target.value;

    if (this.isMount) {
      this.setState({
        startDate: startDate,
        isFormDirty: true
      });
    }
  };

  handleEndDateChange = event => {
    let endDate = event.target.value;

    if (this.isMount) {
      this.setState({
        endDate: endDate,
        isFormDirty: true
      });
    }
  };

  handleProjectChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    if (this.isMount) {
      await this.setState({ [name]: value, isFormDirty: true });
    }
  };

  filterProjectOnChange = event => {
    this.setState({
      projects: this.filterProjectData(event.filter)
    });
  };

  filterProjectData(filter) {
    const data = this.allProjects.slice();
    return filterBy(data, filter);
  }

  rowClick = e => {
    if (this.isMount) {
      this.setState({
        editID: e.dataItem.id
      });
    }
  };

  designationItemChange = e => {
    const data = this.state.data.slice();
    const index = data.findIndex(d => d.id === e.dataItem.id);
    let cellValue = 0;
    if (e.value != null && e.value > 0) {
      cellValue = e.value;
    }

    data[index] = { ...data[index], [e.field]: Number(cellValue.toFixed(2)) };
    if (this.isMount) {
      this.setState({
        isFormDirty: true,
        data: data
      });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    if (!this.validation()) {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: true,
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else if (this.state.endDate.getTime() < this.state.startDate.getTime()) {
      if (this.isMount) {
        this.setState({
          visible: true,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleDialog('End date cannot be a date before the Start date', 'Error');
    } else {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: false
        });
      }
      this.createCostCard(event);
      if (this.isMount) {
        this.setState({
          isDisableSave: true
        });
      }
    }
  };

  createCostCard = event => {
    const designations = this.state.data;
    const costCardDesignations = [];

    designations.forEach(function (data) {
      const costCardDesignation = {
        DesignationId: data.id,
        Overhead: data.Overhead,
        CostToCompany: data.CostToCompany,
        StartDate: moment(this.state.startDate).format('YYYY-MM-DD'),
        EndDate: moment(this.state.endDate).format('YYYY-MM-DD')
      };
      costCardDesignations.push(costCardDesignation);
    }.bind(this));

    const costCard = {
      Name: this.state.costCardName.trim(),
      ResourceRegionId: this.state.selectedResourceRegion.id,
      BillingRegionId: this.state.selectedBillingRegion.id,
      StartDate: moment(this.state.startDate).format('YYYY-MM-DD'),
      EndDate: moment(this.state.endDate).format('YYYY-MM-DD'),
      CostCardDesignations: costCardDesignations,
      IsActive: true,
      ProjectId: this.state.selectedProject ? this.state.selectedProject.id : null,
      updatedUser: window.LOGGED_USER.displayName
    };

    saveCostCard(costCard)
      .then(res => {
        if (this.isMount) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(`The cost card is created successfully.`, 'Success');
        let data = [];
        getMultiYearInformationIntegrated().then(response => {
          let yearRecords = response.data;
          if (yearRecords.length > 0) {
            for (const item of yearRecords) {
              if (item.id) {
                const costData = {
                  MultiyearInfoGridId: item.id,
                  CostCardId: res.data.id,
                  Type: 'Cost Card'
                };
                data.push(costData);
              }
            }

            saveCostCardIncrementsIntegrated(data);
          }
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true,
            isDisableSave: false
          });
        }
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  validation = () => {
    if (
      this.validateProperty(this.state.costCardName)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.selectedBillingRegion)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.selectedResourceRegion)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.startDate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.endDate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleCancelConfirmDialog = () => {
    if (this.isMount) {
      this.setState({
        showCancelConfirmation: !this.state.showCancelConfirmation
      });
    }
  };

  onClickClose = event => {
    if (this.state.isDisableSave) {
      this.navigateBack(event);
    } else {
      this.toggleDialog();
    }
  };

  navigateBack = event => {
    if (this.isMount) {
      this.setState({
        toCostCard: true
      });
    }
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };
}

export default CreateCostCard;
