import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import moment from 'moment';
import 'moment-timezone';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import { getUserList, searchUsers, updateUserStatus, getEmploymentType, exportUsers, deleteUser } from './userService';
import { getAllActiveHRDesignations } from '../../masterData/hrDesignation/HrDesignationService';
import { getAllDesignations } from "../../masterData/designation/DesignationService";
import { getBillingDiviDropDownIntegrated, getActiveResourceRegionsIntegrated, getPracticeTypesIntegrated } from '../../integration/MasterDataService';
import { getDivision, getDeployableStatus } from "../../resourceManagement/ResourceService";

import CreatedDateCell from './components/CreatedDateCell';
import UpdatedDateCell from './components/UpdatedDateCell';
import StatusCell from './components/StatusCell';
import PMCell from './components/PMCell';
import SuperAdminCell from './components/SuperAdminCell';
import EditUserActionsCell from './components/EditUserActionsCell';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';

import { Link } from 'react-router-dom';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import Loader from '../../integration/components/Loader';
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import { saveAuditTrail } from '../../integration/auditTrail/AuditTrailService';

class UserManagement extends Component {
  _export;
  isMount = false;
  EmploymentTypeAll = [];
  HRDesignationsAll = [];
  DesignationsAll = [];
  BillingDiviDataAll = [];
  DivisionDataAll = [];
  RegionDataAll = [];
  DeployableStatusAll = [];
  practiceDataAll = [];

  componentDidMount() {
    this.isMount = true;
    window.scrollTo(0, 0);
    this.setHeaderTitle();
    this.populateEmploymentType();
    this.populateHrDesignations();
    this.populateDesignations();
    this.populateBillingDivisions();
    // this.populateDivision();
    this.populateRegionDetails();
    this.populateDeployableStatus();
    this.populatePractices();
    this.searchAllUsers();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      userList: [],
      skip: 0,
      take: 20,
      SearchRequest: {
        searchStatus : { Name: 'Active', status: 1 }
      },
      deactivateUserId: 0,
      deactivatePopupVisible: false,
      activateUserId: 0,
      activatePopupVisible: false,
      searchStatus: null,
      status: [{ Name: 'Active', status: 1 }, { Name: 'Inactive', status: 2 }, { Name: 'Deleted', status: 3 }],
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      loading: false,
      EmploymentType: [],
      HRDesignations: [],
      Designations: [],
      billingDiviData: [],
      DivisionData: [],
      RegionData: [],
      TimeEntryData: [{ Name: 'Yes', status: true }, { Name: 'No', status: false }],
      deleteUserId:0,
      deletePopupVisible: false,
      deleteUserEmail:0,
      deployableStatuses: [],
      practiceData: [],
      userListCount: 0
    };

    this.exportData = this.exportData.bind(this);
  }

  populateUserList = () => {
    getUserList().then(response => {
      if (this.isMount) {
        this.setState({
          userList: response.data,
          loading: false,
          skip: 0
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  searchAllUsers = async() => {
    await this.setState({
      loading: true
    });
    const searchObj = {
      SearchRequest: this.state.SearchRequest,
      skip: this.state.skip,
      take: this.state.take
    }
    await searchUsers(searchObj).then(res => {
      this.setState({
        userList: res.data.data,
        userListCount: res.data.count,
        loading: false
      });
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    this.setState({
      loading: false
    });
  };

  pageChange = async(event) => {
    if (this.isMount) {
      await this.setState({
        skip: event.page.skip
      }, () => {
        this.searchAllUsers();
      });
    }
  };

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('User Management');
    }
  };

  populateEmploymentType = () => {
    getEmploymentType()
      .then(res => {
        if (this.isMount) {
          this.EmploymentTypeAll = res.data;
          this.setState({
            EmploymentType: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateHrDesignations = () => {
    getAllActiveHRDesignations()
      .then(res => {
        if (this.isMount) {
          this.HRDesignationsAll = res.data.filter(el => el.IsActive === true && el.Code !== null);
          this.setState({
            HRDesignations: res.data.filter(el => el.IsActive === true && el.Code !== null)
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateDeployableStatus = () => {
    getDeployableStatus()
      .then(res => {
        if (this.isMount) {
          this.DeployableStatusAll = res.data;
          this.setState({
            deployableStatuses: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };
  

  handleOnChangeCombo = async event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }

    this.setState({
      isFormDirty: true
    });

  };

  filterChangeCombo = event => {
    if (this.isMount) {
      clearTimeout(this.timeout);
      const field = event.target.name;
      this.timeout = setTimeout(() => {
        switch (field) {
          case 'SelectedEmploymentType': {
            this.setState({
              EmploymentType: this.filterComboData(
                event.filter,
                this.EmploymentTypeAll
              )
            });
            break;
          }
          case 'SelectedHRDesignation': {
            this.setState({
              HRDesignations: this.filterComboData(
                event.filter,
                this.HRDesignationsAll
              )
            });
            break;
          }
          case 'SelectedDesignation': {
            this.setState({
              Designations: this.filterComboData(
                event.filter,
                this.DesignationsAll
              )
            });
            break;
          }
          case 'SelectedBillingDivision': {
            this.setState({
              billingDiviData: this.filterComboData(
                event.filter,
                this.BillingDiviDataAll
              )
            });
            break;
          }
          case 'SelectedDivision': {
            this.setState({
              DivisionData: this.filterComboData(
                event.filter,
                this.DivisionDataAll
              )
            });
            break;
          }
          case 'SelectedRegion': {
            this.setState({
              RegionData: this.filterComboData(
                event.filter,
                this.RegionDataAll
              )
            });
            break;
          }
          case 'SelectedDeployable': {
            this.setState({
              deployableStatuses: this.filterComboData(
                event.filter,
                this.DeployableStatusAll
              )
            });
            break;
          }
          case 'SelectedPractice': {
            this.setState({
              practiceData: this.filterComboData(
                event.filter,
                this.practiceDataAll
              )
            });
            break;
          }
          default: {
          }
        }
      }, 0);
    }
  };

  filterComboData = (filter, allData) => {
    const data = allData.slice();
    return filterBy(data, filter);
  };

  populateDesignations = async () => {
    getAllDesignations()
      .then(res => {
        if (this.isMount) {
          this.DesignationsAll = res.data;
          this.setState({
            Designations: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateBillingDivisions = async () => {
    getBillingDiviDropDownIntegrated()
        .then(response => {
            this.BillingDiviDataAll = response.data.filter(el => el.IsActive === true && el.SFCode !== null);
            this.setState({
                billingDiviData: response.data.filter(el => el.IsActive === true && el.SFCode !== null)
            });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
  };

  populateDivision = () => {
    getDivision()
      .then(res => {
        if (this.isMount) {
          this.DivisionDataAll = res.data;
          this.setState({
            DivisionData: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populatePractices = () => {
    getPracticeTypesIntegrated()
      .then(res => {
        if (this.isMount) {
          this.practiceDataAll = res.data;
          this.setState({
            practiceData: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateRegionDetails() {
    getActiveResourceRegionsIntegrated()
      .then(res => {
        this.RegionDataAll = res.data;
        if (this.isMount) {
          this.setState({
            RegionData: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  getDivisionNameFromId(id){
    const division = this.DivisionDataAll.find(x=>x.id == id);
    if(division){
      return division.name;
    }
    return ''
  }

  getEmployementTypeNameFromId(id){
    const empType = this.EmploymentTypeAll.find(x=>x.id == id);
    if(empType){
      return empType.name;
    }
    return ''
  }

  getDeployableStatusFromId(id){
    const depStatus = this.DeployableStatusAll.find(x=>x.value == id);
    if(depStatus){
      return depStatus.name;
    }
    return ''
  }

  exportData = event => {
    event.preventDefault();

    try {
      if (this.isMount) {
        this.setState({
          loading: true
        }, () => {
          exportUsers(this.state.SearchRequest).then(res => {
            if (res.data && res.data.length > 0) {
              const ExportData = [];
              res.data.forEach(obj => {
                let matchingDeployability = null;
                let resourceSkillAreas = [];
                let deployabilityHistory = obj.Resource.ResourceDeployability;
                if (deployabilityHistory && deployabilityHistory.length > 0) {
                  matchingDeployability = Object.values(deployabilityHistory).find(c => new Date().getTime() >= new Date(c.StartDate).getTime() && c.EndDate === null  || (new Date().getTime() >= new Date(c.StartDate).getTime() && new Date().getTime() <= new Date(c.EndDate).getTime()));
                }
                if (obj.Resource.ResourceSkillArea && obj.Resource.ResourceSkillArea.length > 0) {
                  for (const skill of obj.Resource.ResourceSkillArea) {
                    resourceSkillAreas.push(skill.SkillArea.name)
                  }
                  resourceSkillAreas.sort();
                }
                let object = {
                  Name: obj.Resource ? obj.Resource.Name :'',
                  UserName: obj.Username,
                  HrDesignation: obj.Resource.HrDesignation ? obj.Resource.HrDesignation.Name :'',
                  CostDesignation: obj.Resource.Designation ? obj.Resource.Designation.Name :'',
                  Department: obj.Resource.BillingDivision ? obj.Resource.BillingDivision.Name : '',
                  Region: obj.Resource.ResourceRegion ? obj.Resource.ResourceRegion.Name : '',
                  EmploymentType: obj.Resource.EmploymentTypeModel ? obj.Resource.EmploymentTypeModel.Name : '',
                  // Division: this.getDivisionNameFromId(obj.Resource.Division),
                  DeployableStatus: matchingDeployability && matchingDeployability.DeployableStatusId ? this.getDeployableStatusFromId(matchingDeployability.DeployableStatusId) : '',
                  DeployabilityPercentage: matchingDeployability && matchingDeployability.DeployableStatusPercentage !== null ? matchingDeployability.DeployableStatusPercentage : '',
                  Area: resourceSkillAreas && resourceSkillAreas.length > 0 ? resourceSkillAreas.join(', ') : '',
                  Country: obj.Resource.GeneralCountryObject ? obj.Resource.GeneralCountryObject.Name : '',
                  TimeEntryRequired: obj.Resource.IsTimeEntryRequired ? 'Yes' : 'No',
                  LineManager: obj.Resource.LineManagerObject ? obj.Resource.LineManagerObject.displayName : '',
                  UserRole: obj.Roles && obj.Roles.length > 0 ? obj.Roles.map(x=> x.Rolename).join(','): '',
                  CreatedDate: moment(obj.createdAt).format("MM/DD/YYYY"),
                  Status: '',
                  Practice: obj.Resource.ResourcePractice ? obj.Resource.ResourcePractice.Name : '',
                  AllocatedRegion: obj.Resource.AllocatedRegion ? obj.Resource.AllocatedRegion.Name : '',
                  DeliveryModel: obj.Resource.DeliveryModel ? obj.Resource.DeliveryModel.Name : '',
                  LegalEntity: obj.Resource.LegalEntity ? obj.Resource.LegalEntity.Name : '',
                };

                if (obj.IsDeleted) {
                  object.Status = 'Deleted'
                } else if (obj.IsActive) {
                  object.Status = 'Active';
                } else if (obj.IsActive == false) {
                  object.Status = 'Inactive';
                }

                ExportData.push(object);
              });
              if (ExportData && ExportData.length > 0) {
                this._export.save(ExportData);
                const auditTrail = {
                  Action: 'Export to Excel',
                  RefType: 'User Management',
                  RefId: window.LOGGED_USER && window.LOGGED_USER.user !== null ? window.LOGGED_USER.user : '',
                  RefName: window.LOGGED_USER && window.LOGGED_USER.displayName !== null ? window.LOGGED_USER.displayName : ''
                };
                saveAuditTrail(auditTrail);
                if (this.isMount) {
                  this.setState({
                    loading: false
                  });
                }
              }
            }
          })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        });
      }

    } catch (error) {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    }
  }


  render() {
    return (
      <form onSubmit={this.submitSearch}>
        <div className="main-card">

          <div className="row">
            <div className="col-md-8">
              <div className="main-heading">User Management Search</div>
            </div>

            <div className="col-md-4">
              <Button primary={true}
                className="pull-right"
                disabled={!(window.USER_PERMISSION.indexOf('USER_ROLE_MGMNT') > -1 || window.USER_PERMISSION.indexOf('USR_MGT') > -1)}
              >
                <Link style={{ textDecoration: 'none', color: 'white' }}
                  to={{
                    pathname: '/admin/createUser/createUser',
                    action: 'create'
                  }}
                >
                  Create new user
                </Link>
              </Button>
            </div>
          </div>

          <div className="row">

            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Name:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <Input
                    value={this.state.SearchRequest.Name}
                    onChange={this.handleOnChangeInput}
                    name="Name"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Username:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <Input
                    value={this.state.SearchRequest.Username}
                    onChange={this.handleOnChangeInput}
                    name="Username"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <label className="">Employment Type:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  data={this.state.EmploymentType}
                  textField="Name"
                  dataItemKey="id"
                  value={this.state.SearchRequest.SelectedEmploymentType}
                  onChange={this.handleOnChangeInput}
                  name="SelectedEmploymentType"
                  placeholder="Please Select"
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <label className="">HR Designation:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  data={this.state.HRDesignations}
                  textField="Name"
                  dataItemKey="id"
                  value={this.state.SearchRequest.SelectedHRDesignation}
                  onChange={this.handleOnChangeInput}
                  name="SelectedHRDesignation"
                  placeholder="Please Select"
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <label className="">Cost Designation:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  data={this.state.Designations}
                  textField="Name"
                  dataItemKey="id"
                  value={this.state.SearchRequest.SelectedDesignation}
                  onChange={this.handleOnChangeInput}
                  name="SelectedDesignation"
                  placeholder="Please Select"
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <label className="">Deployable Status:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  data={this.state.deployableStatuses}
                  textField="name"
                  dataItemKey="value"
                  value={this.state.SearchRequest.SelectedDeployable}
                  onChange={this.handleOnChangeInput}
                  name="SelectedDeployable"
                  placeholder="Please Select"
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                />
              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-md-2">
              <div className="d-block">
                <label className="">Department:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  data={this.state.billingDiviData}
                  textField="Name"
                  dataItemKey="id"
                  value={this.state.SearchRequest.SelectedBillingDivision}
                  onChange={this.handleOnChangeInput}
                  name="SelectedBillingDivision"
                  placeholder="Please Select"
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                />
              </div>
            </div>
            {/* <div className="col-md-2">
              <div className="d-block">
                <label className="">Division:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  data={this.state.DivisionData}
                  textField="name"
                  dataItemKey="id"
                  value={this.state.SearchRequest.SelectedDivision}
                  onChange={this.handleOnChangeInput}
                  name="SelectedDivision"
                  placeholder="Please Select"
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                />
              </div>
            </div> */}
            <div className="col-md-2">
              <div className="d-block">
                <label className="">Practice:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  data={this.state.practiceData}
                  textField="Name"
                  dataItemKey="id"
                  value={this.state.SearchRequest.SelectedPractice}
                  onChange={this.handleOnChangeInput}
                  name="SelectedPractice"
                  placeholder="Please Select"
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <label className="">Region:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  data={this.state.RegionData}
                  textField="Name"
                  dataItemKey="id"
                  value={this.state.SearchRequest.SelectedRegion}
                  onChange={this.handleOnChangeInput}
                  name="SelectedRegion"
                  placeholder="Please Select"
                  filterable={true}
                  onFilterChange={this.filterChangeCombo}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Time Entry Required:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    name="IsTimeEntryRequired"
                    textField="Name"
                    dataItemKey="status"
                    value={this.state.SearchRequest.IsTimeEntryRequired}
                    data={this.state.TimeEntryData}
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="d-block">
                <div className=""><label>Status:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    name="searchStatus"
                    textField="Name"
                    dataItemKey="status"
                    value={this.state.SearchRequest.searchStatus}
                    data={this.state.status}
                    placeholder={'Please Select'}
                    onChange={this.handleOnChangeInput}
                  />
                </div>
              </div>
            </div>
            </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} type="submit" onClick={this.submitSearch}>Search</Button>
              <Button type="button" onClick={this.cancelSearch}>Clear</Button>
            </div>
          </div>


          {/*<div className="row">
                    <div className="col-md-12">
                        <div className="k-form-field">
                            <input type="checkbox" id="isActiveUser" className="k-checkbox" value={this.state.SearchRequest.IsActiveUser} 
                            onChange={this.handleOnChangeInput} checked={this.state.SearchRequest.IsActiveUser} name="isActiveUser" />
                            <label className="k-checkbox-label" htmlFor="isActiveUser">
                                 Is Active?
                            </label>
                        </div>
                    </div>
                </div>*/}

          <div className="main-seperator"></div>

          <div className="row">
            <div className="col-md-10">
              <div className="main-heading">Search Results</div>
            </div>
            <div className="col-md-2 btn-align-right">
              <button
                title="Export to Excel"
                className="k-button k-primary"
                onClick={this.exportData}>
                Export to Excel
              </button>
            </div>
          </div>

          <div className="row mt-0">
            <div className="col-md-12">
              
                <ExcelExport
                  ref={(exporter) => {
                    this._export = exporter;
                  }}
                  fileName={'Users ' + new Date()}
                >
                  <ExcelExportColumn field="Name" title="Name" />
                  <ExcelExportColumn field="UserName" title="User Name" />
                  <ExcelExportColumn field="HrDesignation" title="HR Designation" />
                  <ExcelExportColumn field="CostDesignation" title="Cost Designation" />
                  <ExcelExportColumn field="Department" title="Department" />
                  <ExcelExportColumn field="Practice" title="Practice" />
                  <ExcelExportColumn field="Region" title="Region" />
                  <ExcelExportColumn field="AllocatedRegion" title="Resource Assignment" />
                  <ExcelExportColumn field="EmploymentType" title="Employment Type" />
                  {/* <ExcelExportColumn field="Division" title="Division" /> */}
                  <ExcelExportColumn field="DeliveryModel" title="Delivery Model" />
                  <ExcelExportColumn field="LegalEntity" title="Legal Entity" />
                  <ExcelExportColumn field="DeployableStatus" title="Deployable Status" />
                  <ExcelExportColumn field="DeployabilityPercentage" title="Deployability Percentage" />
                  <ExcelExportColumn field="Area" title="Area" />
                  <ExcelExportColumn field="Country" title="Country" />
                  <ExcelExportColumn field="TimeEntryRequired" title="Time Entry Required" />
                  <ExcelExportColumn field="LineManager" title="Line Manager" />
                  <ExcelExportColumn field="UserRole" title="User Role" />
                  <ExcelExportColumn field="CreatedDate" title="Created Date" />
                  <ExcelExportColumn field="Status" title="Status" />
                </ExcelExport>
              
              <Grid
                data={this.state.userList}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.userListCount}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }
                }
                className="search-result-grid"
              >
                <Column field="displayName" title="Name" />
                <Column field="Username" title="Username" />
                <Column field="createdAt" title="Created Date" cell={props => <CreatedDateCell {...props} />} />
                <Column field="updatedAt" title="Last Modified Date" cell={props => <UpdatedDateCell {...props} />} />
                <Column field="IsActive" title="Status" cell={props => <StatusCell {...props} />} />
                <Column
                  field=""
                  title="Actions"
                  cell={props => <EditUserActionsCell {...props} OnDeactivate={this.OnDeactivate}
                    OnActivate={this.OnActivate}  OnDelete={this.OnDelete} />}
                />
              </Grid>
            </div>
          </div>
          <div>
            {this.state.deactivatePopupVisible && (
              <Dialog title={'Please Confirm'} onClose={this.deactivateConfirmationClose}>
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to Deactivate this User?
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.deactivateConfirmationClose}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.deactivateConfirmationOk}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.deletePopupVisible && (
              <Dialog title={'Please Confirm'} onClose={this.deleteConfirmationClose}>
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to Delete this User?
                </p>
                <br/>
                <p style={{textAlign: 'center' }}>
                  This action cannot be undone.
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.deleteConfirmationClose}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.deleteConfirmationOk}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.activatePopupVisible && (
              <Dialog title={'Please Confirm'} onClose={this.activateConfirmationClose}>
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to Activate this User?
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.activateConfirmationClose}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.activateConfirmationOk}>
                    Yes
                  </button>
                </DialogActionsBar></Dialog>
            )}
          </div>
          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="300px">
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                <DialogActionsBar>
                  <button className="k-button modal-primary" onClick={this.toggleDialog}>
                    OK
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <Loader loading={this.state.loading} />
        </div>
      </form>
    );
  }
  
  submitSearch = async event => {
    event.preventDefault();

    await this.setState({
      loading: true,
      skip: 0,
    });

    await this.setState(
      prevState => {
        const SRequest = {
          ...prevState.SearchRequest
        };
        return {
          SearchRequest: SRequest
        };
      },
      () => {
        this.searchAllUsers();
      }
    );
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  cancelSearch = async event => {
    event.preventDefault();
    await this.setState({
      skip: 0,
      SearchRequest: {
        Name: '',
        Username: '',
        searchStatus: '',
        SelectedEmploymentType: null,
        SelectedHRDesignation: null,
        SelectedDesignation: null,
        SelectedBillingDivision: null,
        SelectedRegion: null,
        IsTimeEntryRequired: null,
        SelectedDeployable: null,
        SelectedPractice: null
      }
    });
    this.searchAllUsers();
  };

  handleOnChangeInput = event => {
    const field = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.setState(prevState => {
      return {
        SearchRequest: { ...prevState.SearchRequest, [field]: value }
      };
    });
  };

  OnDeactivate = async (event, id) => {
    event.preventDefault();
    await this.setState({
      deactivateUserId: id
    });
    this.deactivateConfirmationClose();
  };

  deactivateConfirmationClose = async () => {
    await this.setState({
      deactivatePopupVisible: !this.state.deactivatePopupVisible
    });
  };

  deleteConfirmationClose = async () => {
    await this.setState({
      deletePopupVisible: !this.state.deletePopupVisible
    });
  };

  deactivateConfirmationOk = async () => {
    await updateUserStatus({ IsActive: false }, this.state.deactivateUserId)
      .then(response => {
        this.toggleDialog('The User has been Deactivated successfully', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.searchAllUsers();
    await this.setState({
      deactivatePopupVisible: false,
      loading: false
    });
  };

  deleteConfirmationOk = async () => {
    await deleteUser({ IsDeleted: true, Email: this.state.deleteUserEmail }, this.state.deleteUserId)
      .then(response => {
        this.toggleDialog('The User has been Deleted successfully', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.searchAllUsers();
    await this.setState({
      deletePopupVisible: false,
      loading: false
    });
  };

  OnActivate = async (event, id) => {
    event.preventDefault();
    await this.setState({
      activateUserId: id
    });
    this.activateConfirmationClose();
  };

  OnDelete = async (event, id, email) => {
    debugger
    event.preventDefault();
    await this.setState({
      deleteUserId: id,
      deleteUserEmail : email
    });
    this.deleteConfirmationClose();
  };

  activateConfirmationClose = async () => {
    await this.setState({
      activatePopupVisible: !this.state.activatePopupVisible
    });
  };

  activateConfirmationOk = async () => {
    await updateUserStatus({ IsActive: true }, this.state.activateUserId)
      .then(response => {
        this.toggleDialog('The User has been Activated successfully', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    await this.searchAllUsers();
    await this.setState({
      activatePopupVisible: false,
      loading: false
    });
  };

}

export default UserManagement;
