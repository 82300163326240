import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PendingApprovals extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const CMSheet = this.props.dataItem.CMSheets;
    const ResourceRequests = this.props.dataItem.ResourceRequests;
    const ResourceRequestSubLines = this.props.dataItem.ResourceRequestSubLines;
    const noofCMSheets = CMSheet.length;
    const noofRequests = ResourceRequests.length + ResourceRequestSubLines.length;
    const { OnViewProject } = this.props;
    const {OnViewUnFreezeRequestApproval} = this.props;
    const { OnViewResourceRequest } = this.props;
    const pendingReviseCMSRequests = this.props.dataItem.pendingReviseCMSRequests;
    const pendingInvoiceRequests = this.props.dataItem.pendingInvoiceRequests;
    const {OnViewInvoiceRequestApproval} = this.props;
    
    // For status change workflows
    const statusPendingProjectsCrs = this.props.dataItem.StatusPendingProjectsCrs ? this.props.dataItem.StatusPendingProjectsCrs : [];
    const statusPendingProjCrCount = statusPendingProjectsCrs?statusPendingProjectsCrs.length:0;

    const pendingApprovalUnFreezeRequests = this.props.dataItem.pendingApprovalUnFreezeRequests?this.props.dataItem.pendingApprovalUnFreezeRequests:[];
    
    const pendingApprovalUnFreezeRequestsCount = pendingApprovalUnFreezeRequests && pendingApprovalUnFreezeRequests.length>0 ? pendingApprovalUnFreezeRequests.length:0;
    
    return (
      <td>
        {this.props.dataItem.WorkflowTypeId == 1 ? (CMSheet.length > 0 ? (
          <Link to={this.props}
                onClick={event => OnViewProject(event, this.props.dataItem.CMSheets, this.props.dataItem.WorkflowTypeId)}>
            {noofCMSheets}
          </Link>
        ) : (
          <div>0</div>
        )) : this.props.dataItem.WorkflowTypeId == 4 ? (CMSheet.length > 0 ? (
            <div>{noofCMSheets}</div>
        ) : (
            <div>0</div>
        )) : this.props.dataItem.WorkflowTypeId == 5 ? (statusPendingProjectsCrs && statusPendingProjectsCrs.length > 0 ? (
              <Link to={this.props}
                    onClick={event => OnViewProject(event, statusPendingProjectsCrs, this.props.dataItem.WorkflowTypeId)}>
                <div>{statusPendingProjCrCount}</div>
              </Link>
        ) : (
          <div>0</div>
      )) :
        this.props.dataItem.WorkflowTypeId == 6 ? (pendingApprovalUnFreezeRequests && pendingApprovalUnFreezeRequests.length > 0 ? (
          <Link to={this.props}
                onClick={event => OnViewUnFreezeRequestApproval(event, pendingApprovalUnFreezeRequests, this.props.dataItem.WorkflowTypeId)}>
            <div>{pendingApprovalUnFreezeRequestsCount}</div>
          </Link>):
        (
          <div>0</div>
        )) : 
        this.props.dataItem.WorkflowTypeId == 3 || this.props.dataItem.WorkflowTypeId == 2 ? (noofRequests > 0 ? (
          <Link to={this.props}
                onClick={event => OnViewResourceRequest(event, this.props.dataItem, this.props.dataItem.WorkflowTypeId)}>
            <div>{noofRequests}</div>
          </Link>):
        (
          <div>0</div>
        )) :
        this.props.dataItem.WorkflowTypeId == 7 ? (pendingReviseCMSRequests && pendingReviseCMSRequests.length > 0 ? (
          <Link to={this.props}
                onClick={event => OnViewProject(event, pendingReviseCMSRequests, this.props.dataItem.WorkflowTypeId)}>
            {pendingReviseCMSRequests.length > 0 ? pendingReviseCMSRequests.length : 0}
          </Link>
        ) : (
          <div>0</div>
        )) :
        this.props.dataItem.WorkflowTypeId == 8 ? (pendingInvoiceRequests && pendingInvoiceRequests.length > 0 ? (
          <Link to={this.props}
                onClick={event => OnViewInvoiceRequestApproval(event, pendingInvoiceRequests, this.props.dataItem.WorkflowTypeId)}>
            {pendingInvoiceRequests.length > 0 ? pendingInvoiceRequests.length : 0}
          </Link>
        ) : (
          <div>0</div>
        )) :
        (
          <div>0</div>
        )}
      </td>
    );
  }
}

export default PendingApprovals;
