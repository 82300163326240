/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Tooltip } from "reactstrap";
import { roundNumber } from '../../../../integration/CommonUtils';

class RowCellNumericTextBox extends Component {
    handleChange = event => {
        this.props.onChange({
            dataItem: this.props.dataItem,
            field: this.props.field,
            syntheticEvent: event.syntheticEvent,
            value: event.target.value
        });
    };

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
            decimalPlaces: 0
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        if (!this.props.dataItem.inEdit) {
            const value = this.props.toFixed
                ? this.props.dataItem[this.props.field].toFixed(this.props.toFixed)
                : this.props.dataItem[this.props.field];
            return <td>{value}</td>;
        }

        if (this.props.toFixed && this.props.toFixed === 2) {
            this.state.decimalPlaces = 0.01;
        }


        const classes = 'tooltip-inner';

        let lastCustomerRateProject = null;

        let addError = false;
        if((this.props.field === 'AllocationPercentage' && this.props.dataItem.AllocationPercentage === 0 ) || (this.props.field === 'EffortDays' && this.props.dataItem.EffortDays === 0 ) || (this.props.field === 'EffortHours' && this.props.dataItem.EffortHours === 0 ) || (this.props.field === 'NoOfResources' && this.props.dataItem.NoOfResources === 0 ) || (this.props.field === 'RateValue' && this.props.dataItem.RateValue === 0 )){
            addError = true;
        }

        if (this.props.field === 'RateValue' && this.props.dataItem.RateType === 3) {
            // Last Customer Rate
            lastCustomerRateProject = this.props.dataItem.LastCustomerRateProject;
        }

        return (
            <td>
                {lastCustomerRateProject && (
                    <div>
                        <div className="custom-grid-label" title="Text" href="#" id="LastCustomRate">
                            <DebounceInput
                                width="100%"
                                value={roundNumber(this.props.dataItem[this.props.field],2)}
                                disabled={this.props.disabled}
                                type="number"
                                step={this.state.decimalPlaces}
                                pattern="[0-9]*"
                                className="k-textbox"
                                style={ addError === true ? { 'borderBottomColor': 'rgba(243,23,0,0.5)' } : {}}
                            />                        </div>
                        <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="LastCustomRate" toggle={this.toggle}>
                            Last Custom Rate - {lastCustomerRateProject} -{' '}
                            {this.props.dataItem[this.props.field].toFixed(2)}
                        </Tooltip>
                    </div>
                )
                }
                {
                    !lastCustomerRateProject && (
                        <div className={this.props.styleClass}>
                            <div className={( this.props.field === 'AllocationPercentage' && this.props.dataItem.AllocationPercentage > 100 ? 'badge badge-danger' : 'hidden')
                            }>
                                <div className={( this.props.dataItem.autoPopulatedValueChaged === true && (this.props.dataItem.changedFields.indexOf(this.props.dataValueField) > -1)? 'badge badge-primary' : 'hidden')
                                }>

                                    <DebounceInput
                                        debounceTimeout={1000}
                                        width="100%"
                                        onChange={this.handleChange}
                                        value={roundNumber(this.props.dataItem[this.props.field],2)}
                                        disabled={this.props.disabled}
                                        required={this.props.required}
                                        type="number"
                                        step={this.state.decimalPlaces}
                                        min={this.props.minValue}
                                        pattern="[0-9]*"
                                        className="k-textbox"
                                        style={ addError === true ? { 'borderBottomColor': 'rgba(243,23,0,0.5)' } : {}}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </td >
        );
    }
}

export default RowCellNumericTextBox;
