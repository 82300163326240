import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Redirect } from 'react-router';
import { Tooltip } from 'reactstrap';
import { saveDesignationGroup, updateDesignationGroup } from './DesignationGroupService';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../integration/LoggerService";
import {LOG_TYPES} from "../../integration/IntegrationEnums";

class DesignationGroupCreate extends Component {

  default;

  popupSet = {
    width: 'auto'
  };

  constructor(props) {
    super(props);
    console.log(window.LOGGED_USER);
    this.state = {
      designationGroupNameToolTipOpen: false,
      toDesignationGroup: false,
      designationGroupText: '',
      designationGroupId: null,
      designationGroupActive: false,
      visible: false,
      success: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      isEnableCreate: false,
      isEnableUpdate: false,
      isDisableCombo: false,
      isDisableEditMode: false,
      isDisableSave: false,
      isSaveButtonClicked: false
    };

    this.handleChangeDesignationGroupName = this.handleChangeDesignationGroupName.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setHeaderTitle();

    if (this.props.location.action === 'edit') {
      this.updateDesignationGroupDetails(this.props.location.designationGroup);
    }
  }

  setHeaderTitle = async () => {
    if (this.props.location.action === 'edit') {
      this.props.onHeaderTitleChange('Edit Designation Groups');
    } else {
      this.props.onHeaderTitleChange('Create Designation Groups');
    }
  };

  render() {
    if (this.state.toDesignationGroup === true) {
      return <Redirect to="/admin/designationGroup/designationGroup"/>;
    }
    return (
      <React.Fragment>
        <div className="main-card">
          <div className="main-heading">Designation Group Details</div>
          <div className="main-seperator"></div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                  <label className="mandatory">Designation Group Name:</label>
                </div>
                <div className="d-block">
                <div className="" id="designationGroupNameToolTip">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="designationGroupText"
                    required={true}
                    maxLength="100"
                    value={this.state.designationGroupText}
                    onChange={this.handleChangeDesignationGroupName}
                    disabled={this.state.isEditMode}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.designationGroupText)}>
                                            Please enter a Designation Group name
                                        </span>
                  ) : null}
                  {this.state.designationGroupText ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.designationGroupNameToolTipOpen}
                      target="designationGroupNameToolTip"
                      toggle={this.toggleDesignationGroupNameToolTip}
                    >
                      {this.state.designationGroupText}
                    </Tooltip>
                  ) : null}
                </div>
              </div>

            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 btn-align-right">
                  <Button primary={true} disabled={this.state.isDisableSave} type="submit"
                          onClick={this.handleSubmit}>Save</Button>
                  <Button onClick={this.onClickCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error"/>
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.navigateBack}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.showCancelConfirmation === true && (
              <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contains unsaved changes. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.navigateBack}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  navigateBack = event => {
    this.setState({
      toDesignationGroup: true
    });
  };

  handleChangeDesignationGroupName = async event => {
    await this.setState({ designationGroupText: event.target.value, isFormDirty: true });
    await this.validateProperty(event.target.value);
  };

  toggleDesignationGroupNameToolTip = () => {
    this.setState({
      designationGroupNameToolTipOpen: !this.state.designationGroupNameToolTipOpen
    });
  };

  updateDesignationGroupDetails = designationGroup => {
    this.setState({
      isDisableEditMode: true
    });
    if (designationGroup) {
      this.isEnableCreate = true;
      this.isEnableUpdate = false;
      let designationGroupStatus = false;

      if (designationGroup.IsActive) {
        designationGroupStatus = true;
      }

      this.setState({
        designationGroupText: designationGroup.Name,
        designationGroupId: designationGroup.id,
        designationGroupActive: designationGroupStatus
      });
    } else {
      return null;
    }

  };

  handleSubmit = event => {
    event.preventDefault();
    console.dir(event.target);
    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true
      });

      this.setState({
        visible: true,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else {
      this.setState({
        isErrorMessageVisible: false
      });
      if (this.props.location.action === 'edit') {
        this.updateDesignationGroup(event);
      } else {
        this.createDesignationGroup(event);
      }
      this.setState({
        isDisableSave: true
      });
    }
  };

  createDesignationGroup = event => {
    const designationGroup = {
      Name: this.state.designationGroupText,
      IsActive: true,
      updatedUser: window.LOGGED_USER.displayName
    };

    saveDesignationGroup(designationGroup)
      .then(res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog(`The Designation Group ${res.data.Name} is successfully created`, 'Success');
      })

      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          isDisableSave: false,
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  updateDesignationGroup = event => {
    const designationGroup = {
      Name: this.state.designationGroupText,
      IsActive: this.state.designationGroupActive,
      updatedUser: window.LOGGED_USER.displayName
    };

    updateDesignationGroup(designationGroup, this.state.designationGroupId)
      .then(res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog('The Designation Group has been successfully updated', 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          isDisableSave: false,
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  validation = () => {

    if (
      this.validateProperty(this.state.designationGroupText)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleCancelConfirmDialog = () => {
    this.setState({
      showCancelConfirmation: !this.state.showCancelConfirmation
    });
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };
}

export default DesignationGroupCreate;
