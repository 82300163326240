import React, { Component } from 'react';
import MultiIncrementYearPicker from './components/MultiIncrementYearPicker';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Redirect } from 'react-router';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { Link } from 'react-router-dom';
import { getMultiYearInfoByYear, getMultiYearInformation } from './MultiyearIncrementService';
import 'moment-timezone';
import moment from 'moment/moment';
import { orderBy } from '@progress/kendo-data-query';
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import $ from "jquery";

const ACTION_CREATE = 'create';
const ACTION_EDIT = 'edit';

class MultiyearIncrementInfo extends Component {

  isMount = false;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      gridDetails: [],
      skip: 0,
      take: 20,
      SearchRequest: {
        selectedYear: null
      },
      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',

      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      redirectToCreate: false,
      selectedRecord: null,
      multiyearAction: '',
      sort: [{ field: 'Year', dir: 'desc' }],
      loading: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    this.populateMultiyearInfo();
    this.setHeaderTitle();

    $(document).ready(function () {
      $("div.title-remove > div > span > span > span:last-child").removeAttr("title");
    });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  navigateToCreate = () => {
    if (this.isMount) {
      this.setState({
        redirectToCreate: true
      });
    }
  };

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Multi-year Increments');
    }
  };

  onClickCreate = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState(
        {
          multiyearAction: ACTION_CREATE
        },
        () => {
          this.navigateToCreate();
        }
      );
    }
  };

  onClickEdit = (event, multiyearinfo) => {
    event.preventDefault();
    if (this.isMount) {
      this.setState(
        {
          multiyearAction: ACTION_EDIT,
          selectedRecord: multiyearinfo
        },
        () => {
          this.navigateToCreate();
        }
      );
    }
  };

  populateMultiyearInfo = () => {
    getMultiYearInformation().then(response => {
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].CreatedDate = moment(response.data[i].CreatedDate).format('MM-DD-YYYY');

        response.data[i].LastModifiedDate = moment(response.data[i].LastModifiedDate).format(
          'MM-DD-YYYY'
        );
      }
      if (this.isMount) {
        this.setState({
          gridDetails: response.data
        });
      }
    });
  };

  submitSearch = event => {
    if (this.state.SearchRequest.selectedYear !== null) {
      event.preventDefault();
      if (this.isMount) {
        this.setState({
          loading: true
        });

        this.setState(
          prevState => {
            const SRequest = {
              ...prevState.SearchRequest
            };
            return {
              SearchRequest: SRequest
            };
          },
          () => {
            getMultiYearInfoByYear({ Year: this.state.SearchRequest.selectedYear }).then(res => {
              for (let i = 0; i < res.data.length; i++) {
                res.data[i].CreatedDate = moment(res.data[i].CreatedDate).format('MM/DD/YYYY');

                res.data[i].LastModifiedDate = moment(res.data[i].LastModifiedDate).format(
                  'MM/DD/YYYY'
                );
              }
              this.setState({
                gridDetails: res.data,
                loading: false,
                skip: 0
              });
            });
          }
        );
      }
    } else {
      this.populateMultiyearInfo();
    }
  };

  handleOnChangeYear = event => {
    const value = event.value;
    const field = event.name;
    if (this.isMount) {
      this.setState(prevState => {
        return {
          SearchRequest: { ...prevState.SearchRequest, [field]: value }
        };
      });
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  cancelSearch = event => {
    if (this.state.SearchRequest.selectedYear === null) {
      this.populateMultiyearInfo();
    }
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        SearchRequest: {
          selectedYear: null
        }
      });
    }
    this.populateMultiyearInfo();
  };

  toggleMessageDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleConfirmDialog = (message, action) => {
    if (this.isMount) {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    }
  };

  statusCell = props => {
    if (props.dataItem) {
      return <td>{props.dataItem.IsActive === true ? 'Active' : 'Inactive'}</td>;
    }
    return null;
  };

  render() {
    if (this.state.redirectToCreate === true) {
      return (
        <Redirect
          to={{
            pathname: '/admin/createmultiyearincrement/createmultiyearincrement',
            multiyearinfo: this.state.selectedRecord,
            action: this.state.multiyearAction
          }}
        />
      );
    }
    return (
      <div>
        <div className="main-card">
          <div className="row">
            <div className="col-md-8">
              <div className="main-heading">
                Multi-Year Increment Search
              </div>
            </div>
            <div className="col-md-4">
              <div className="btn-align-right">
                <Button primary={true} onClick={this.onClickCreate} disabled={!(window.USER_PERMISSION.indexOf('EDT_MUL_YR_INC') > -1)}>Create New Multi-Year Increment</Button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                <label>Financial Year:</label>
              </div>
              <div className="d-block">
                <div className="title-remove">
                  <MultiIncrementYearPicker
                    value={this.state.SearchRequest.selectedYear}
                    onChange={this.handleOnChangeYear}
                    name="selectedYear"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button primary={true} onClick={this.submitSearch} type="submit">Search</Button>
              <Button type="button" onClick={this.cancelSearch}>Clear</Button>
            </div>

          </div>



          <div className="main-seperator" />

          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">Search Results</div>
            </div>
          </div>

          <div className="row mt-0">
            <div className="col-md-12">
              <Grid
                data={orderBy(this.state.gridDetails, this.state.sort).slice(
                  this.state.skip,
                  this.state.take + this.state.skip
                )}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.gridDetails.length}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
                sortable={true}
                sort={this.state.sort}
                onSortChange={(e) => {
                  this.setState({
                    sort: e.sort
                  });
                }
                }
                className="search-result-grid"
              >
                <Column field="Year" title="Financial Year" />
                <Column field="CreatedDate" title="Created Date" />
                <Column field="LastModifiedDate" title="Last Modified Date" />
                <Column field="LastModifiedUser" title="Last Modified User" width="120px" />

                <Column field="IsActive" title="Status" width="100px" cell={this.statusCell} />
                <Column field="" title="Action" width="150px" cell={this.actionButtonCell} />
              </Grid>
            </div>
          </div>

          {this.state.showMessageDialog === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

              {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success" />
                </div>
              )}

              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image" />
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

              <DialogActionsBar>
                {this.state.showSuccessImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                    OK
                  </button>
                )}

                {this.state.showErrorImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                    OK
                  </button>
                )}
              </DialogActionsBar>
            </Dialog>
          )}
          {this.state.showConfirmDialog === true && (
            <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.toggleConfirmDialog}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
          <Loader loading={this.state.loading} />
        </div>
      </div>
    );
  }

  actionButtonCell = props => {
    return (
      <td className="action-buttons">
        {props.dataItem.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_MUL_YR_INC') > -1) && (
          <a href="#" onClick={event => this.onClickEdit(event, props.dataItem)}>
            <div className="rounded-link" title="Edit">
              <span className="k-icon k-i-edit" />
            </div>
          </a>
        )}
      </td>
    );
  };
}

export default MultiyearIncrementInfo;
