import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';

class ResourceOnsiteActionCell extends Component {
  
  render() {
    const { OnEdit } = this.props;
    const { OnDelete } = this.props;
    const startdate = this.props.dataItem.StartDate;
    const enddate = this.props.dataItem.EndDate;
    var showEdit = false;
    var showDelete = false;

    var minDate = moment().subtract(21, 'days').format("YYYY-MM-DD")

    if (enddate >= minDate) {
      showEdit = true;          
    }

    if (startdate >= minDate) {
      showDelete = true;
    }

    return (
      <td className="action-buttons">
        {(window.USER_PERMISSION.indexOf('EDT_OS_RES') > -1 && showEdit === true) && (
          <a
              type="button"
              onClick={(e) => { e.preventDefault(); OnEdit(this.props.dataItem); }}
            >            
                  <div className="rounded-link">
                      <span className="k-icon k-i-edit" title="Edit" />
                  </div>        
          </a>
        )}

        {(window.USER_PERMISSION.indexOf('EDT_OS_RES') > -1 && showDelete === true) && (
          <a
              type="button"
              onClick={(e) => { e.preventDefault(); OnDelete(this.props.dataItem.id); }}
          >        
              <div className="rounded-link">
                  <span className="k-icon k-i-delete" title="Delete" />
              </div>
          </a>
        )}
      </td>
    );
  }
}

export default ResourceOnsiteActionCell;