/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {getFormattedDate} from "../../integration/CommonUtils";

class TaskManagementCell extends Component {
  render() {
    const project = this.props.dataItem;
    let projectManager = false;
    let allocatedResources = false;
    const viewProjTaskMngt = window.USER_PERMISSION.filter(obj => obj === 'VW_PRJTASKMNGMT');
    const addEditTasks = window.USER_PERMISSION.filter(obj => obj === 'ADD_EDIT_TASKS' );
    const addEditResources = window.USER_PERMISSION.filter(obj => obj === 'ADD_EDIT_RESCS' );

    const projectProjectManagers = project.AllocatedResourceRequestPM;
    if (projectProjectManagers.length > 0){
      //find valid pms from end date
      let projValidManagers = [];
      projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

      if (projValidManagers.length == 0 && projectProjectManagers.length > 0){

        projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
        const lastAssignedManager = projectProjectManagers[0];
        projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

      }

      projValidManagers.forEach(value => {
        if(window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()){
          projectManager = true;
        }
      });
    }

    let changeRequestsWithNewlyAllocatedPMs = [];
    if (project.ChangeRequests && project.ChangeRequests.length > 0) {
      for (const cr of project.ChangeRequests) {
          if (cr.AllocatedPM && cr.AllocatedPM.length > 0) {
            /*for (const allocatedPM of cr.AllocatedPM) {
              if (allocatedPM.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
                changeRequestsWithNewlyAllocatedPMs.push(cr);
              }
            }*/
            //valid managers
            const crProjectManagers = cr.AllocatedPM;

            //find valid pms from end date
            let validManagers = [];
            validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );

            if (validManagers.length === 0 && crProjectManagers.length > 0){
              crProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
              const lastAssignedManager = crProjectManagers[0];
              validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

            }

            validManagers.forEach(value => {
              if(window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()){
                changeRequestsWithNewlyAllocatedPMs.push(cr);
              }
            });
          }

      }
    }

    return (
      <td className="action-buttons">

   {(projectManager === true || (viewProjTaskMngt && viewProjTaskMngt.length > 0) ||
   (changeRequestsWithNewlyAllocatedPMs && changeRequestsWithNewlyAllocatedPMs.length > 0) ||
   (viewProjTaskMngt && viewProjTaskMngt.length > 0) ||
   (addEditTasks && addEditTasks.length > 0 )||
   (addEditResources && addEditResources.length)) > 0 && (
          <Link
            to={{
              pathname: '/timesheets/projecttaskmanagement/projecttaskmanagement',
              projectId: project.id,
              action: 'view',
              project:project
            }}
          >
            <div className="rounded-link" title="View/Edit Task Management">
              <span className="k-icon k-i-edit" />
            </div>
          </Link>
          )}
      </td>
    );
  }
}

export default TaskManagementCell;
