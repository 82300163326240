import React, { Component } from 'react';
import 'moment-timezone';
import moment from 'moment';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Redirect } from 'react-router';
import { firstDayOfMonth, lastDayOfMonth } from '@progress/kendo-date-math';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Link } from 'react-router-dom';
import { getAllDocumentTypes, updateDocumentTypeStatus, searchDocTypes} from './DocumentTypeService';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import UpdatedDateCell from './Components/UpdatedDateCell';
import CreatedDateCell from './Components/CreatedDateCell'
import ProjectStatusCell from './Components/ProjectStatusCell';
import ActionsCell from './Components/ActionsCell';
import { getProjectStatuses } from "../../projectManagement/project/ProjectService";
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';

class DocumentTypeSearch extends Component {

    isMount = false;

    constructor(props) {
        super(props);
        this.state = {
          combodata: [],
          doctype: [],
          doctypeNames: [],
          statusItems: [],
          skip: 0,
          take: 20,
          sort: [{ field: 'updatedAt', dir: 'desc' }],
          statusValue: null,
          activatePopupVisible: false,
          activateDocID: null,
          visible: false,
          dialogMessage: '',
          dialogTitle: '',
          showSuccessImage: false,
          showErrorImage: false,
          deactivatePopupVisible: false,
          selectedStatus: { Name: 'Active', status: true },
          selectedProjStatus: [],
          selectedDoc: null,
          status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
        };
    }

    componentDidMount() {
        this.isMount = true;
        window.scrollTo(0, 0);
        this.setHeaderTitle();
        this.prepareSearchDocTypes();
        this.retrieveAllProjectStatuses();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    pageChange = event => {
      if (this.isMount) {
        this.setState({
          skip: event.page.skip,
          take: event.page.take
        });
      }
    };

    retrieveAllDocTypes = () => {
        getAllDocumentTypes()
          .then(res => {
              if (this.isMount) {
                this.state.doctypeNames = []
                let temp = [];
                let temp_docTypes = [];
                let docStatusMap = new Map();     // initialising the hash map

                res.data.forEach(data => {

                  const index = temp.findIndex(obj => obj.name === data.DocType)
                  if (index === -1){
                    const valueToAdd = {
                      name : data.DocType
                    }
                    data.allStatuses = '';
                    docStatusMap.set(data.DocType, "");  // initialising each unique doc type as key with an empty string as a value
                    temp.push(valueToAdd)
                    temp_docTypes.push(data)
                  }
                });

                this.setState({
                  doctype: temp_docTypes,
                  doctypeNames: temp,
                  combodata: temp
                });
                
                res.data.forEach(data => {
                  
                  let key = data.DocType;
                  let existingValue;
                  let newValue;

                  existingValue = docStatusMap.get(key);
                  if (existingValue === ''){
                    newValue = data.Status.StatusName;
                  }
                  else{
                    newValue = existingValue + ', ' + data.Status.StatusName;
                  }
                  docStatusMap.set(key, newValue);
                });
              
                temp_docTypes.forEach(data => {
                  let str = docStatusMap.get(data.DocType);
                  data.allStatuses = str;
                });

                this.setState({
                  doctype: temp_docTypes
                });
              }
          })
          .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    };

    retrieveAllProjectStatuses = () => {
      getProjectStatuses().then(response => {
        let list = response.data;
        list = response.data.filter(obj => (obj.Code !== 'BILLABALE_CR' && obj.Code !== 'ON_HOLD'));
        
        this.setState({
          statusItems: list
        });
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }

    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Document Type');
        }
    };

  OnDeactivate = async (event, id, name) => {
    if (event) {
      event.preventDefault();
    }
    if (this.isMount) {
      await this.setState({
        deactivateDocID: id,
        deactivateDocName: name
      });
    }
    this.deactivateConfirmationClose();
  };

  deactivateConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: !this.state.deactivatePopupVisible
      });
    }
  };

  deactivateConfirmationOk = async () => {

    const val = {
      IsActive: false,
      docTypeID: this.state.deactivateDocID,
      UpdatedUser: window.LOGGED_USER.displayName,
      docType: this.state.deactivateDocName,
      updatedAt: new Date()
    }

    await updateDocumentTypeStatus(val, this.state.deactivateDocID)
      .then(response => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog('The Document type has been Deactivated successfully', 'Success');
        this.retrieveAllDocTypes()
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    if (this.isMount) {
      await this.setState({
        deactivatePopupVisible: false
      });
    }
  };

    OnActivate = async (event, id, name) => {
      if (event) {
        event.preventDefault();
        if (this.isMount) {
          await this.setState({ activateDocID: id, activateDocName: name });
        }
        await this.activateConfirmationClose();
      }
    };

    activateConfirmationClose = () => {
    if (this.isMount) {
      this.setState({
        activatePopupVisible: !this.state.activatePopupVisible
      });
    }
  };

  activateConfirmationOk = async () => {

    const val = {
      IsActive: true,
      docTypeID: this.state.activateDocID,
      docType: this.state.activateDocName,
      UpdatedUser: window.LOGGED_USER.displayName,
      updatedAt: new Date()
    }

    await updateDocumentTypeStatus(val, this.state.activateDocID)
      .then(response => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog('The Document type has been Activated successfully', 'Success');
        this.retrieveAllDocTypes()
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    if (this.isMount) {
      await this.setState({
        activatePopupVisible: false
      });
    }
  };

  submitSearch = event => {
    event.preventDefault();
    this.prepareSearchDocTypes();
  };

  prepareSearchDocTypes = () =>{
    if (this.isMount) {
      let obj = {
        docType : this.state.selectedDoc ? this.state.selectedDoc.name : null,
        Status : this.state.selectedStatus,
        StatusID : this.state.selectedProjStatus.map(value => value.id)
      }
      
      this.executeSearchDocTypes(obj);
    }
  }

  executeSearchDocTypes = (obj) => {
    searchDocTypes(obj).then(res => {

      let temp_docTypes = [];
      let docStatusMap = new Map();

      res.data.forEach(data => {

        const index = temp_docTypes.findIndex(obj => obj.DocType === data.DocType)
        if (index === -1) {
          data.allStatuses = '';
          temp_docTypes.push(data)
        }
        docStatusMap.set(data.DocType, "")
      });

      this.setState({
        doctype: temp_docTypes,
        skip: 0
      });

      res.data.forEach(data => {

        let key = data.DocType;
        let existingValue;
        let newValue;

        existingValue = docStatusMap.get(key);
        if (existingValue === '') {
          newValue = data.Status.StatusName;
        }
        else {
          newValue = existingValue + ', ' + data.Status.StatusName;
        }
        docStatusMap.set(key, newValue);
      });

      temp_docTypes.forEach(data => {
        let str = docStatusMap.get(data.DocType);
        data.allStatuses = str;
      });

      this.setState({
        doctype: temp_docTypes
      });

    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  }

  cancelSearch = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState({
        selectedStatus: null,
        selectedProjStatus: [],
        selectedDoc: null
      });
    }
    this.retrieveAllDocTypes();
  };

  handleOnChangeInput = event => {
    const field = event.target.name;
    if (field === 'searchStatus'){
      this.setState({selectedStatus : event.target.value})
    }

    if (field === 'projStatus'){
      this.setState({selectedProjStatus : event.target.value})
    }

    if (field === 'docName'){
      this.setState({selectedDoc : event.target.value})
    }
  };

  filterOnChange = event => {
    clearTimeout(this.Timeout);
    const field = event.target.name;
    this.timeout = setTimeout(() => {
      switch (field){
        case 'docName': {
          if (this.isMount) {
            this.setState({
              doctypeNames: this.filterComboData(event.filter, this.state.combodata)
            });
          }
          break;
        }
        default: {
        }
      }
    }, 0);
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };


    render() {
        return (
            <div className="main-card">
                <div className="row">
                    <div className="col-md-8">
                        <div className="main-heading">Document Type Search</div>
                    </div>

                    <div className="col-md-4">
                        <Button primary={true}
                                className="pull-right"
                                disabled={!(window.USER_PERMISSION.indexOf('EDT_DOC_TYPE') > -1)}
                        >
                            <Link
                                style={{ textDecoration: 'none', color: 'white' }}
                                to={{
                                    pathname: '/masterdata/documenttype/documenttypedetails',
                                    action: 'create',
                                    documentNames: this.state.doctypeNames
                                }}
                            >
                                Create New Document Type
                </Link>
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-2">
                        <div className="d-block">
                            <label>Document Type:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                              <ComboBox
                                data={this.state.doctypeNames}
                                name="docName"
                                textField="name"
                                placeholder={'Please Select'}
                                onChange={this.handleOnChangeInput}
                                value={this.state.selectedDoc}
                                filterable={true}
                                onFilterChange={this.filterOnChange}
                              />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-1"></div>

                    <div className="col-md-2">
                        <div className="d-block">
                            <label>Project/CR Status:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                              <MultiSelect
                                data={this.state.statusItems}
                                name="projStatus"
                                textField="StatusName"
                                dataItemKey="id"
                                placeholder={'Please Select'}
                                onChange={this.handleOnChangeInput}
                                value={this.state.selectedProjStatus}
                              />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-1"></div>


                    <div className="col-md-2">
                        <div className="d-block">
                            <label>Status:</label>
                        </div>
                        <div className="d-block">
                            <div className="">
                              <ComboBox
                                name="searchStatus"
                                textField="Name"
                                dataItemKey="status"
                                value={this.state.selectedStatus}
                                data={this.state.status}
                                placeholder={'Please Select'}
                                onChange={this.handleOnChangeInput}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 btn-align-right">
                        <Button primary={true} type="submit" onClick={this.submitSearch}>
                            Search
                        </Button>
                        <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
                            Clear
                        </Button>
                    </div>
                </div>

                <div className="main-seperator"></div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="main-heading">Search Results</div>
                    </div>
                </div>

                <div className="row mt-0">
                    <div className="col-md-12">

                            <Grid data={orderBy(this.state.doctype, this.state.sort).slice(
                              this.state.skip,
                              this.state.take + this.state.skip
                            )}
                                  skip={this.state.skip}
                                  take={this.state.take}
                                  resizable
                                  sortable={true}
                                  pageable={true}
                                  total={this.state.doctype.length}
                                  onPageChange={this.pageChange}
                                  sort={this.state.sort}
                                  onSortChange={(e) => {
                                      this.setState({
                                          sort: e.sort
                                      });
                                  }
                                  }
                                  className="search-result-grid"
                            >

                            <Column field="DocType" title="Document Type" />
                            <Column field="allStatuses" title="Project/CR Status" />

                            
                            <Column field="createdAt"
                                    title="Created Date"
                                    cell={props => <CreatedDateCell {...props} />}
                            />
                            <Column
                              field="updatedAt"
                              title="Last Modified Date"
                              width="120px"
                              cell={props => <UpdatedDateCell {...props} />}
                            />
                            <Column field="UpdatedUser" title="Last Modified User"  width="120px" />
                            <Column field="IsActive"
                                    title="Status"
                                    width="100px"
                                    cell={props => <ProjectStatusCell {...props} />}
                            />
                            <Column field=""
                                    title="Actions"
                                    width="150px"
                                    cell={props => (
                                      <ActionsCell
                                        {...props}
                                        OnDeactivate={this.OnDeactivate}
                                        OnActivate={this.OnActivate}
                                        doctypeNames={this.state.doctypeNames}
                                      />
                                )}
                            />
                        </Grid>
                    </div>
                </div>

              <div>
                {this.state.deactivatePopupVisible && (
                  <Dialog title={'Please Confirm'} onClose={this.deactivateConfirmationClose}>
                    <p style={{ margin: '25px', textAlign: 'center' }}>
                      Are you sure you want to Deactivate this?
                    </p>
                    <DialogActionsBar>
                      <button className="k-button" onClick={this.deactivateConfirmationClose}>
                        No
                      </button>
                      <button className="k-button modal-primary" onClick={this.deactivateConfirmationOk}>
                        Yes
                      </button>
                    </DialogActionsBar>
                  </Dialog>
                )}
              </div>

              <div>
                {this.state.activatePopupVisible && (
                  <Dialog title={'Please Confirm'} onClose={this.activateConfirmationClose}>
                    <p style={{ margin: '25px', textAlign: 'center' }}>
                      Are you sure you want to Activate this?
                    </p>
                    <DialogActionsBar>
                      <button className="k-button" onClick={this.activateConfirmationClose}>
                        No
                      </button>
                      <button className="k-button modal-primary" onClick={this.activateConfirmationOk}>
                        Yes
                      </button>
                    </DialogActionsBar>
                  </Dialog>
                )}
              </div>

              <div>
                {this.state.visible === true && (
                  <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                    {this.state.showSuccessImage === true && (
                      <div className="text-center">
                        <img className="successImg" src={SuccessImg} alt="Success"/>
                      </div>
                    )}

                    {this.state.showErrorImage === true && (
                      <div className="text-center">
                        <img className="successImg" src={ErrorImg} alt="Error"/>
                      </div>
                    )}
                    <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                    <DialogActionsBar>
                      {this.state.showSuccessImage === true && (
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>
                          OK
                        </button>
                      )}

                      {this.state.showErrorImage === true && (
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>
                          OK
                        </button>
                      )}
                    </DialogActionsBar>
                  </Dialog>
                )}
              </div>

            </div>
        );
    }
}

export default DocumentTypeSearch;
