import React, { Component } from "react";

class  ApproversCell extends Component{

    constructor(props) {
        super(props);

        this.state = {
            activityList:null,
            sortedActivity:[],
            isPendingStatus:false,
            approversList: []
        }
    }

    componentDidMount()
    {
        this.filterApproversList();
    }

    filterApproversList = () => {
        let dataItem = this.props.dataItem;

        if(dataItem.WorkflowId !== null && dataItem.ApprovalWorkflow){
            for(var eachApproverGroup of dataItem.ApprovalWorkflow.ApproverGroup){
                for(var eachApprover of eachApproverGroup.Approvers){
                    this.state.approversList.push(eachApprover.Resource);
                }
            }
        }
        this.forceUpdate();

    };

    render(){

        const approversList = this.state.approversList;

        var html = "";
        var i = 1;
        for (let approver of approversList) {
            if(i >= approversList.length) {
                html += ''+  approver.Name  + '';
            } else {
                html += ''+  approver.Name  + ',<br/>';    
            }

            i++;
        }
        return <td>{<div dangerouslySetInnerHTML={{__html: html}} />}</td>;
    }
}

export default ApproversCell;
