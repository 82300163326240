import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * getDesignationGroups - retrieves all the Designation Groups records
 * @returns {array}  array of Designation Groups records
 */
export function getDesignationGroups() {
  const endPoint = apiUrl + '/masterData/designationGroup/designationgroups';
  return http.get(endPoint);
}

/**
 * searchDesignationGroups - search Designations Groups by parameters
 * @param params
 * @returns {array}  array of details of Designations Groups on a grid
 */
export function searchDesignationGroups(params) {
  const endPoint = apiUrl + '/masterData/designationGroup/designationgroups/search';
  return http.post(endPoint, params);
}

/**
 * saveDesignationGroup - Save Designations Group
 * @param params
 * @returns {array}  array of saved details of Designations Group
 */
export function saveDesignationGroup(params) {
  const endPoint = apiUrl + '/masterData/designationGroup/designationgroups/save';
  return http.post(endPoint, params);
}

/**
 * Update Designation Group by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param designation
 * @param id
 */
export function updateDesignationGroup(designation, id) {
  const updateDesigGroupEndpoint = apiUrl + '/masterData/designationGroup/designationgroups/update/' + id;
  return http.put(updateDesigGroupEndpoint, designation);
}

/**
 * Update Designation Group status by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param IsActive
 * @param id
 */
export function updateDesignationGroupStatus(IsActive, id) {
  const endPoint = apiUrl + '/masterData/designationGroup/designationgroups/updateStatus/' + id;
  return http.put(endPoint, IsActive);
}
