import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Get RateCards Details For Grid.
 * @returns {array} array list of RateCards Details
 */
export function getRateCardsForGrid() {
  const endPoint = apiUrl + '/masterData/ratecard/getAllForGrid';
  return http.get(endPoint);
}

/**
 * Get RateCards Details For Grid.
 * @returns {array} array list of RateCards Details
 */
 export function getRateCardsForGridByStatus(IsActive) {
  const endPoint = apiUrl + '/masterData/ratecard/getAllForGridByStatus';
  return http.post(endPoint, IsActive);
}

/**
 * Update RateCard Status by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param IsActive
 * @param id
 */
export function updateRateCardStatus(IsActive, id) {
  const endPoint = apiUrl + '/masterData/ratecard/updateStatus/' + id;
  return http.put(endPoint, IsActive);
}

/**
 * Search RateCards Details by given pram.
 * @returns {array} array list of RateCards Details
 * @param params
 */
export function searchRateCards(params) {
  const endPoint = apiUrl + '/masterData/ratecard/search';
  return http.post(endPoint, params);
}

/**
 * Save RateCards Details whe creating/updating.
 * @param {params}
 * @returns {array} array list of RateCards Details
 */
export function saveRateCard(params) {
  const endPoint = apiUrl + '/masterData/ratecard/save';
  return http.post(endPoint, params);
}

/**
 * Update RateCard by given param.
 * @param {params}
 * @returns {object} response 0 or 1 (true or false) as an array
 */
export function updateRateCard(params) {
  const endPoint = apiUrl + '/masterData/ratecard/update';
  return http.post(endPoint, params);
}

/**
 * Save RateCard Increments.
 * @param {params}
 * @returns {array} array list of RateCard Increments
 */
export function saveRateCardIncrements(params) {
  const endPoint = apiUrl + '/masterData/multiYear/saverateincrements';
  return http.post(endPoint, params);
}

/**
 * Get all Rate Types for drop downs.
 * @returns {array} array list of all Rate Types
 */
export function getRateTypes() {
  const endPoint = apiUrl + '/masterData/rateCard/rate-types';
  return http.get(endPoint);
}

/**
 * Get Default Rate for drop downs by given params.
 * @returns {array} array list ofDefault Rate
 * @param params
 */
export function getDefaultRate(params) {
  const endPoint = apiUrl + '/master/rate/default';
  return http.post(endPoint, params);
}

/**
 * Get all Last Custom Rate for drop downs.
 * @returns {array} array list of all Last Custom Rate
 */
export function getLastCustomRate(customerId, regionId, designationId) {
  const endPoint =
    apiUrl +
    '/master/rate/lastcustom/customer/' +
    customerId +
    '/region/' +
    regionId +
    '/role/' +
    designationId;
  return http.get(endPoint);
}

/**
 * Get all Active Rate Cards.
 * @returns {array} array list of Active Rate Cards
 */
export function getActiveRateCards() {
  const endpoint = apiUrl + '/masterData/rateCard/getActiveRateCards';
  return http.get(endpoint);
}
