import React, { Component } from "react";
import Iframe from "./IFrame";

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: "/help/index.html",
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.setHeaderTitle();
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Help');
    }
  };

  render() {
    return (
      <div className="help-wrapper">
        <Iframe source={this.state.src} />
      </div>
    );
  }
}

export default Help;
