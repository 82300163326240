import React, { Component } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import moment from 'moment';
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import { getTask, getDataForProjectFilter, saveTimeEntry, getCalculatedTotals, getTimeEntryTotalForCurrentDate, getLatestTimeEntryByDate } from './TimeSheetEntryService';
import WorkLocationStatus from '../enums/WorkLocationStatus';

class SimplifiedTimeEntry extends Component {
  workLocationStatuses = Object.values(WorkLocationStatus);
  constructor(props) {
    super(props);
    this.state = {
      projectDropdownFilter: [],
      projectDropdownFilterAll: [],
      taskDropdownFilter: [],
      taskDropdownFilterAll: [],
      allData: [],
      currentProjectForDropDown: null,
      currentTaskInDropDown: null,
      selectedCurrentDate: new Date(),
      comment: '',
      duration: '',
      ticketId: '',
      totalHourOfDay: 0,
      isErrorMessageVisible: false,
      isTicketIdErrorMessageVisible: false,
      disableSubmit: false,
      workLocation: null,
      isLocationErrorMessageVisible: false,
    };
  }

  componentDidMount() {
    this.isMount = true;
    window.scrollTo(0, 0);
    this.setHeaderTitle();
    this.getInitialData();
  }

  getInitialData = async() => {
    await this.setState({
      loading: true
    });
    await this.getDataForProjectFilter();
    await this.getTaskList();
    await this.getLatestTimeEntryByDate();
    await this.getDailyCalculatedTotals();
    await this.setState({
      loading: false
    });
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Daily Time Entry');
    }
  };

  getDataForProjectFilter = () => {
    let dropdownData = [];
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    this.isMount &&
      getDataForProjectFilter().then(res => {
        if (res.data.length !== 0) {
          res.data.forEach(task => {
            if (typeof task.ProjectTask === 'undefined') {
              if (
                (!task.EndDate || new Date(moment(task.EndDate).format('YYYY-MM-DD')) >= new Date(currentDate)) &&
                typeof dropdownData.find(x => x.DisplayText === task.TaskGroup.Name) === 'undefined'
              ) {
                let obj = {
                  id: task.id,
                  projId: null,
                  CRId: null,
                  NPTId: task.TaskGroupId,
                  DisplayText: task.TaskGroup.Name,
                  Category: 'NPT' /*NPT - Non project task */,
                  StartDate: task.StartDate,
                  EndDate: task.EndDate
                };
                dropdownData.push(obj);
              }
            } else {
              if (task.ProjectTask.CRId === null) {
                if (
                  (!task.EndDate || new Date(moment(task.EndDate).format('YYYY-MM-DD')) >= new Date(currentDate)) &&
                  typeof dropdownData.find(
                    x => x.DisplayText === task.ProjectTask.Project.ProjectName
                  ) === 'undefined'
                ) {
                  let obj = {
                    id: task.ProjectTask.Project.id,
                    projId: task.ProjectTask.Project.id,
                    CRId: null,
                    NPTId: null,
                    DisplayText: task.ProjectTask.Project.ProjectName,
                    Category: 'PT' /*PT - project task */,
                    StartDate: task.ProjectTask.StartDate,
                    EndDate: task.ProjectTask.EndDate,
                    ProjectDepartment: task.ProjectTask.Project.BillingDivision
                  };
                  dropdownData.push(obj);
                }
              } else {
                if (
                  (!task.EndDate || new Date(moment(task.EndDate).format('YYYY-MM-DD')) >= new Date(currentDate)) &&
                  typeof dropdownData.find(
                    x => x.DisplayText === task.ProjectTask.ChangeRequest.CRName
                  ) === 'undefined'
                ) {
                  let obj = {
                    id: task.ProjectTask.CRId,
                    projId: task.ProjectTask.Project.id,
                    CRId: task.ProjectTask.CRId,
                    NPTId: null,
                    DisplayText: task.ProjectTask.ChangeRequest.CRName,
                    Category: 'CR' /*CR - Change Rewuest */,
                    StartDate: task.ProjectTask.StartDate,
                    EndDate: task.ProjectTask.EndDate,
                    ProjectDepartment: task.ProjectTask.ChangeRequest.BillingDivisionId
                  };
                  dropdownData.push(obj);
                }
              }
            }
          });
          this.setState({
            projectDropdownFilter: dropdownData,
            projectDropdownFilterAll: dropdownData
          });
        }
      });
  };

  getTaskList = async () => {
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    const params = {
      firstDate: currentDate,
      lastDate: currentDate,
      projId: null,
      projType: null,
      crId: null,
      nptId: null
    };
    await getTask(params).then(async res => {
      let dataSet = [];
      if (res.data.length !== 0) {
        await res.data.map(async task => {
          if (typeof task.ProjectType === 'undefined') {
            const taskAllocations = task.NonProjectTaskAllocation.filter(allocation => 
              allocation.IsDeleted === false && 
              moment(allocation.StartDate).format('YYYY-MM-DD') <= currentDate &&
              moment(allocation.EndDate).format('YYYY-MM-DD') >= currentDate);
              
            if(taskAllocations && taskAllocations.length > 0) {
              const proj = {
                taskType: 'NPT' /*Non Project Task*/,
                projectId: null,
                taskId: task.id,
                Project: task.TaskGroup.Name,
                Task: task.Name,
                allocationId: taskAllocations[0].id,
                EndDate: task.EndDate,
                StartDate: task.StartDate,
                IsActive: task.IsActive,
                selected: false,
              };
              dataSet.push(proj);
            }        
          } else {
            if (task.ProjectType === 'ChangeRequest') {
              const taskAllocations = task.ProjectTaskAllocation.filter(allocation => 
                moment(allocation.StartDate).format('YYYY-MM-DD') <= currentDate &&
                moment(allocation.EndDate).format('YYYY-MM-DD') >= currentDate);
              if(taskAllocations && taskAllocations.length > 0) {
                const proj = {
                  taskType: 'CRT' /*Change Request Task*/,
                  projectId: null,
                  taskId: task.id,
                  Project: task.ChangeRequest.CRName,
                  Task: task.Name,
                  allocationId: taskAllocations[0].id,
                  EndDate: task.EndDate,
                  StartDate: task.StartDate,
                  IsActive: task.IsActive,
                  selected: false,
                  BillabilityTypeId: task.BillabilityTypeId
                };
                dataSet.push(proj);
              }       
            } else if (task.ProjectType === 'Project') {
              const taskAllocations = task.ProjectTaskAllocation.filter(allocation => 
                moment(allocation.StartDate).format('YYYY-MM-DD') <= currentDate &&
                moment(allocation.EndDate).format('YYYY-MM-DD') >= currentDate);

              if(taskAllocations && taskAllocations.length > 0) {
                const proj = {
                  taskType: 'PT' /*Project Task*/,
                  projectId: null,
                  taskId: task.id,
                  Project: task.Project.ProjectName,
                  Task: task.Name,
                  allocationId: taskAllocations[0].id,
                  EndDate: task.EndDate,
                  StartDate: task.StartDate,
                  selected: false,
                  IsActive: task.IsActive,
                  BillabilityTypeId: task.BillabilityTypeId
                };
                dataSet.push(proj);
              }   
            }
          }
        });
      }
      await this.setState({
        allData: dataSet
      });
    });
  };

  projectHandler = event => {
    let tasks = this.state.allData.filter(
      x =>
        event.target.value !== null &&
        x.Project === event.target.value.DisplayText &&
        (!x.EndDate ||
          new Date(moment(x.EndDate).format('YYYY-MM-DD')) >= new Date(moment(new Date()).format('YYYY-MM-DD')))
    );

    if (event.target.value && (event.target.value.DisplayText === 'HR - Holidays' || 
      event.target.value.DisplayText === 'HR - Leave')) {
      this.setState({
        workLocation: null,
        isLocationErrorMessageVisible: false
      });
    }

    this.setState({
      currentProjectForDropDown: event.target.value,
      currentProject: event.target.value !== null && event.target.value.DisplayText,
      currentProjectId: event.target.value !== null && event.target.value.DisplayText,
      taskDropdownFilter: tasks,
      taskDropdownFilterAll: tasks,
      isTaskExist: tasks.length !== 0 || event.target.value === null ? false : true,
      currentTaskInDropDown: null,
      isDirty: event.target.name === null ? false : true
    });
  };

  taskHandler = event => {
    if (event.target.value) {
      this.setState({
        currentTaskInDropDown: event.target.value,
        isDirty: event.target.name === null ? false : true
      });  
    } else {
      this.setState({
        currentTaskInDropDown: null
      }); 
    }
  };

  handleCommentInput = e => {
    this.setState({
      comment: e.target.value
    });
  };

  handleTicketIdInput = e => {
    this.setState({
      ticketId: e.target.value
    });
  };

  handleWorkLocationInput = e => {
    this.setState({
      workLocation: e.target.value
    });
  };

  handleDurationInput = e => {
    this.setState({
      duration: e.target.value
    });
  };

  blurDurationInput = e => {
    const value = e.target.value.split(':');
    if (value) {
      if (
        Number(value[0]) >= 0 &&
        (Number(value[0]) < 24 ||
          (Number(value[0]) === 24 && (!value[1] || Number(value[1]) === 0)))
      ) {
        this.setState({
          duration: e.target.value
        });
      } else {
        this.setState({
          duration: '0.0'
        });
      }
    }
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validateDurationNullOrNot = value => {
    if (
      value !== null &&
      value !== 0 &&
      value !== '0' &&
      value !== '' &&
      value !== '00' &&
      value !== '0:00' &&
      value !== '00:00' &&
      value !== '0.0' &&
      value !== '0.00' &&
      value !== '00.00' &&
      value !== '00.0'
    ) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validateDuration = () => {
    let timeRegExWithColon = /^(0?[0-9]|[0-9][0-9]):[0-5][0-9]$/;
    let timeRegExWithOutColon = /^\d{1,2}(\.\d{1,2})?$/;
    if (
      this.state.duration === null ||
      this.state.duration === 0 ||
      this.state.duration === '0' ||
      this.state.duration === '' ||
      timeRegExWithColon.test(this.state.duration)
    ) {
      return 'd-none';
    } else if (
      this.state.duration === null ||
      this.state.duration === 0 ||
      this.state.duration === '0' ||
      this.state.duration === '' ||
      timeRegExWithOutColon.test(this.state.duration)
    ) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validation = event => {
    if (!this.validateWorkLocation()) {
      this.setState({
        isLocationErrorMessageVisible: true,
        isErrorMessageVisible: true
      });
    }
    if (
      this.validateDurationNullOrNot(this.state.duration)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.comment)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateDuration()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.currentProjectForDropDown)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.currentTaskInDropDown)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  validateTicketId = () => {
    if (this.state.currentProjectForDropDown && this.state.currentProjectForDropDown.ProjectDepartment == 30) {
        if (
          this.validateProperty(this.state.ticketId)
            .toString()
            .includes('error')
        ) {
          return false;
        } else {
          return true;
        }
    } else {
      return true;
    }
  };

  validateWorkLocation = () => {
    if (!(this.state.currentProjectForDropDown && (this.state.currentProjectForDropDown.DisplayText === 'HR - Holidays' || 
        this.state.currentProjectForDropDown.DisplayText === 'HR - Leave'))) {
        if (
          this.validateProperty(this.state.workLocation)
            .toString()
            .includes('error')
        ) {
          return false;
        } else {
          return true;
        }
    } else {
      return true;
    }
  };

  onClickClear = async() => {
    await this.setState({
      taskDropdownFilter: [],
      taskDropdownFilterAll: [],
      currentProjectForDropDown: null,
      currentTaskInDropDown: null,
      comment: '',
      duration: '',
      ticketId: '',
      workLocation: null
    });
  };

  submitTimeEntyHandler = async(event) => {
    event.preventDefault();
    await this.setState({
      disableSubmit: true
    });
    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true
      });
    } else if (!this.validateTicketId()) {
      this.setState({
        isTicketIdErrorMessageVisible: true
      });
    } else if (!this.validateWorkLocation()) {
      this.setState({
        isLocationErrorMessageVisible: true
      });
    } else {
      var cardList = [];
      let timeRegExWithColon = /^(0?[0-9]|[0-9][0-9]):[0-5][0-9]$/;
      let timeRegExWithOutColon = /^\d{1,2}(\.\d{1,2})?$/;
      let duration = null;
      if (timeRegExWithColon.test(this.state.duration)) {
        let times = this.state.duration.split(':');
        duration = parseFloat(parseFloat(times[0]) + parseFloat(times[1] / 60)).toFixed(5);
      } else if (timeRegExWithOutColon.test(this.state.duration)) {
        duration = this.state.duration;
      }
      let projectTaskAllocationId = null;
      let nonProjectTaskAllocationId = null;

      if (this.state.currentTaskInDropDown.taskType === 'NPT') {
        nonProjectTaskAllocationId = this.state.currentTaskInDropDown.allocationId;
      } else {
        projectTaskAllocationId = this.state.currentTaskInDropDown.allocationId;
      }
      const card = {
        ProjectTaskAllocationId: projectTaskAllocationId,
        NonProjectTaskAllocationId: nonProjectTaskAllocationId,
        Comment: this.state.comment,
        Duration: duration,
        ApprovedStatus: 1,
        ApprovedBy: null,
        ApprovedNonBillingCatagoryId: null,
        EntryFreezeStatus: null,
        ApproveFreezeStatus: null,
        LogDateTime: moment(this.state.selectedCurrentDate).format('YYYY-MM-DD'),
        RejectReason: null,
        TicketId: this.state.ticketId,
        WorkLocationId: this.state.workLocation ? this.state.workLocation.value : null
      };
      cardList.push(card);
      await saveTimeEntry(cardList).then(async res => {
        await this.getLatestTimeEntryByDate();
        await this.getDailyCalculatedTotals();
        this.setState({
          isErrorMessageVisible: false,
          isTicketIdErrorMessageVisible: false,
          isLocationErrorMessageVisible: false,
          taskDropdownFilter: [],
          taskDropdownFilterAll: [],
          currentProjectForDropDown: null,
          currentTaskInDropDown: null,
          comment: '',
          duration: '',
          ticketId: '',
        });
      });
    }
    await this.setState({
      disableSubmit: false
    });
  }

  getDailyCalculatedTotals = async() => {
    let proAllocations = [];
    let nptAllocations = [];
    for (const task of this.state.allData) {
      if (task.taskType === 'PT' || task.taskType === 'CRT') {
        proAllocations.push(task.allocationId);
      } else {
        nptAllocations.push(task.allocationId);
      }
    }

    let params = {
      proAllocations: proAllocations,
      nptAllocations: nptAllocations,
      currentDate: moment(this.state.selectedCurrentDate).format('YYYY-MM-DD')
    };
    await getTimeEntryTotalForCurrentDate(params).then(async res => {
      const totalTime = await this.setTotalTime(res.data.TimeEntryTotal)
      await this.setState({
        totalHourOfDay: totalTime
      });
    });
  };

  getLatestTimeEntryByDate = async() => {
    let params = {
      date: moment(new Date()).format('YYYY-MM-DD')
    };
    await getLatestTimeEntryByDate(params).then(res => {
      if (res.data && res.data.WorkLocationId) {
        this.setState({
          workLocation: res.data.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == res.data.WorkLocationId)[0] : null,
        });  
      } else {
        this.setState({
          workLocation: null
        });
      }
    });  
  };

  setTotalTime = time => {
    let durationParts = time.toString().split('.');
    let duration = durationParts[0];
    let durMinPart = this.roundUp(durationParts[1],1)
    .toString()
    .padStart(2, '0');
    if (typeof durationParts[1] !== 'undefined') {
      if(parseInt(durMinPart) === 60){
        duration = parseInt(duration) + 1;
        durMinPart = '00';
      }else{
        durMinPart = this.roundUp(durationParts[1],1).toString().padStart(2, '0');;
      }
      duration =
        parseInt(duration) +
        ':' + durMinPart
      return duration;
    } else {
      duration = parseInt(duration) + ':' + '00';
      return duration;
    }
  };

  roundUp =(num, precision)=> {
    num = parseFloat(`0.${num}`) * 60;
    num = num.toFixed(2) /10
    precision = Math.pow(10, precision)
    return (Math.ceil(num * precision) / precision)*10
  }

  filterOnChange = event => {
    const field = event.target.name;
    switch (field) {
      case 'project':
        {
          this.setState({
            projectDropdownFilter: this.filterComboData(
              event.filter,
              this.state.projectDropdownFilterAll
            )
          });
        }
        break;
      case 'taskDropDown':
        {
          this.setState({
            taskDropdownFilter: this.filterComboData(event.filter, this.state.taskDropdownFilterAll)
          });
        }
        break;
      default: {
        break;
      }
    }
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  render() {
    return (
      <div>
        <div className="main-card">
            <div className="row">
                <div className="col-md-8">
                    <div className="main-heading">Daily Time Entry</div>
                </div>
            </div>
            <form onSubmit={e => this.submitTimeEntyHandler(e)}>
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="d-block card-bg-te">
                    <label className="mandatory">Projects/ Task Group:</label>
                  </div>
                  <div className="d-block">
                    <ComboBox
                      data={this.state.projectDropdownFilter}
                      name="project"
                      textField="DisplayText"
                      placeholder={'Please Select'}
                      onChange={this.projectHandler}
                      value={this.state.currentProjectForDropDown}
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.currentProjectForDropDown)}>
                        Project is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="d-block">
                    <div className="d-block card-bg-te">
                      <label className="mandatory">Task:</label>
                    </div>
                    <ComboBox
                      data={this.state.taskDropdownFilter}
                      name="taskDropDown"
                      textField="Task"
                      placeholder={'Please Select'}
                      onChange={this.taskHandler}
                      value={this.state.currentTaskInDropDown}
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.currentTaskInDropDown)}>
                        Task is mandatory
                      </span>
                    ) : null}
                    {this.state.isTaskExist === true ? (
                      <span className="inline-error">
                        No task for selected project
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="d-block">
                    <div className="d-block card-bg-te">
                      <label className="mandatory">Date:</label>
                    </div>
                    <DatePicker
                      name="dateOfTimeEntry"
                      value={this.state.selectedCurrentDate}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      disabled={true}
                    />
                  </div>
                </div>
                 <div className="col-md-4 col-sm-12">
                  <div className="d-block card-bg-te">
                    <label className="mandatory">Comment:</label>
                  </div>
                  <div className="d-block">
                    <textarea
                      className="k-textarea"
                      name="comment0"
                      id="comment0"
                      value={this.state.comment}
                      onChange={this.handleCommentInput}
                      maxLength={250}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span
                        className={this.validateProperty(document.getElementById('comment0').value)}
                      >
                        Comment is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="d-block">
                    <div className="d-block card-bg-te">
                      <label className="mandatory">Work Location:</label>
                    </div>
                    <ComboBox
                      data={this.workLocationStatuses}
                      name="workLocation"
                      textField="name"
                      placeholder={'Please Select'}
                      onChange={this.handleWorkLocationInput}
                      value={this.state.workLocation}
                      filterable={true}
                      disabled={
                        this.state.currentProjectForDropDown && 
                        (this.state.currentProjectForDropDown.DisplayText == 'HR - Holidays' || 
                        this.state.currentProjectForDropDown.DisplayText == 'HR - Leave')
                      }
                    />
                    {this.state.isErrorMessageVisible === true && this.state.isLocationErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.workLocation)}>
                        Work Location is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2 col-sm-6">
                  <div className="d-block card-bg-te">
                    <label className="mandatory">Duration:</label>
                  </div>
                  <div className="d-block">
                    <Input
                      name="duration0"
                      value={this.state.duration}
                      onChange={this.handleDurationInput}
                      onBlur={this.blurDurationInput}
                      disabled={this.state.isAddTimeFreeze}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateDurationNullOrNot(this.state.duration)}>
                        Duration is mandatory
                      </span>
                    ) : null}
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateDuration()}>
                        Duration is not in correct format
                      </span>
                    ) : null}
                  </div>
                </div>
            
                <div className="col-md-2 col-sm-6">
                  <div className="d-block card-bg-te">
                    <label>Ticket ID:</label>
                  </div>
                  <div className="d-block">
                    <Input
                      name="ticketId"
                      value={this.state.ticketId}
                      onChange={this.handleTicketIdInput}
                      maxLength={20}
                    />
                    {this.state.isTicketIdErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.ticketId)}>Ticket ID is mandatory, if Ticket ID is not relevant enter N/A</span>
                    ) : null}
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-md-12 btn-align-right">
                  <Button
                    type="submit"
                    primary={true}
                    disabled={this.state.disableSubmit}
                  >
                    Submit
                  </Button>
                  <Button type="button" onClick={this.onClickClear}>
                    Cancel
                  </Button>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-12 align-left">
                  <div className="d-block">
                  <label>Total Hours Entered Per Day (hh:mm): {this.state.totalHourOfDay}</label>
                  </div>
                </div>
              </div>   
          </form>
        </div>
        <Loader loading={this.state.loading}/>

      </div>
    );
  }
}

export default SimplifiedTimeEntry;
