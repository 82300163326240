import React, { Component } from "react";
import {Link} from "react-router-dom";

class StatusFileUploadCell extends Component{

    render(){
        const apiUrl = this.props.ApiUrl;
        const fileName = this.props.dataItem.FileUpload.FileName;
        const projectId = this.props.dataItem.projectId;

        return(
            <td className="">
                <a href={`${apiUrl}/integration/fileUpload/downloadstsfile?path=StatusChange_${projectId}_${encodeURIComponent(fileName)}&&stsFileName=${encodeURIComponent(fileName)}`} target="">
                {fileName}
                <span className="k-icon k-i-download ml-2"></span>
                </a>
            </td>             
        );
    }
}

export default StatusFileUploadCell;
