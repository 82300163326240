import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;


/**
 * saveTaskType - creates a new task type in the TaskType table
 * @returns {object}  created task tasktype
 * @param condition
 */
export function saveProjectTask(params) {
    const endPoint = apiUrl + '/timesheetManagement/projecttasks/saveProjectTask';
    return http.post(endPoint, params);
  }

  /**
 * getAllProjectTasks - retrieves all the tasks created for a project
 * @returns {array}  array of objects which includes project tasks
 * @param condition
 */
export function getAllProjectTasks(params){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/findprojectTasks/getAllProjectTasks';
  return http.get(endPoint)
}

  /**
 * getAllPresalesResourcesByProjId - retrieves all the presales resources by project id
 * @returns {array}  array of objects which includes presales resources
 * @param condition
 */
export function getAllPresalesResourcesByProjId(projectType, projectId) {
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/getResources/' + projectType + '/' + projectId ;
  return http.get(endPoint);
}

 /**
 * getResourceRequestSublines - retrieves all the resource request sublines
 * @returns {array}  array of objects which includes presales resources
 * @param condition
 */
export function getResourceRequestSublines(resourceRequestId) {
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/getResourceRequestSublines/' + resourceRequestId;
  return http.get(endPoint);
}

/**
 * getResource - retrieves the resource which has the passed resource id
 * @returns {object}  an object which contains the details of the resource
 * @param condition
 */
export function getResource(resourceId) {
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/retrieveResources/' + resourceId;
  return http.get(endPoint);
}

/**
 * saveResource - saves a project task allocation with the resource assigned
 * @returns {object}  an object which contains the details of the project task allocation
 * @param condition
 */
export function saveResource(params){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/saveProjectTaskResource';
  return http.post(endPoint, params);
}

/**
 * getAllProjectTaskAllocations - saves a project task allocation with the resource assigned
 * @returns {array}  returns an array of project task allocations
 * @param condition
 */
export function getAllProjectTaskAllocations(params){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/getAllAllocations';
  return http.get(endPoint)
}


/**
 * getAllResourcesAndUsersById - retrieves all the presales resources by project id
 * @returns {array}  array of objects which includes presales resources
 * @param condition
 */
export function getAllResourcesAndUsersById(projectType, projectId) {
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/getallmainLinesandSublines/' + projectType + '/' + projectId ;
  return http.get(endPoint);
}

/**
 * getProjectResourceUtilizationBreakdown - retrieves all the Project Resource Utilization Breakdown Data
 * @returns {array}  array of objects which includes Resource Utilization Breakdown Data
 * @param condition
 */
 export function getProjectResourceUtilizationBreakdown(resourceId, projectId) {
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/getProjectResourceUtilizationBreakdown/' + resourceId + '/' + projectId;
  return http.get(endPoint);
}

/**
 * loadProjectandTaskData - retrieves roject tasks data by project id
 * @returns {object}  object of cr and tasks data
 * @param condition
 */
 export function loadProjectandTaskData(projectId) {
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projecttasksdata/' + projectId ;
  return http.get(endPoint);
}

/**
 * getProjectTaskAllocations by id - get project task allocation with the resource assigned
 * @returns {array}  returns an array of project task allocations
 * @param condition
 */
 export function getProjectTaskAllocations(taskId){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/getAllocations/' +taskId;
  return http.get(endPoint);
}

/**
 * getProjectTaskAllocations by id PerformanceFix - get project task allocation with the resource assigned
 * @returns {array}  returns an array of project task allocations
 * @param condition
 */
export function getTaskAllocationsbyTaskIdPerformanceFix(taskId, skip, take){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/getAllocationsPerformanceFix/' +taskId  + '/' + skip + '/' + take;
  return http.get(endPoint);
}

/**
 * getProjectTaskAllocations by id - get project task allocation with the resource assigned
 * @returns {array}  returns an array of project task allocations
 * @param condition
 */
 export function getProjectTaskAllocationsWihTime(data){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/getAllocationswithtime';
  return http.post(endPoint, data);
}

/**
 * deleteTask - delete tasks from a project or cr
 * @returns {object}  
 * @param condition
 */
export function deleteTask(taskId){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/gettimeentries/' + taskId;
  return http.get(endPoint)
}

/**
 * updateTask - update added task
 * @returns {object}  
 * @param condition
 */
export function updateTask(task){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/updatetask';
  return http.post(endPoint, task)
}

/**
 * getTimeEntryDataforTaskManagment - get time entry data for task management
 * @returns {object}  
 * @param condition
 */
export function getTimeEntryDataforTaskManagment(taskId){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/gettimeentriesfortaskid/' +taskId;
  return http.get(endPoint)
}

/**
 * getTimeEntryDataforTaskManagmentPerformanceFix - get time entry data for task management
 * @returns {object}  
 * @param condition
 */
export function getTimeEntryDataforTaskManagmentPerformanceFix(taskId, skip, take){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/gettimeentriesfortaskidPerformanceFix/' +taskId  + '/' + skip + '/' + take;;
  return http.get(endPoint)
}

/**
 * updateResource - update task resource assignments
 * @returns {object}  
 * @param condition
 */
export function updateResource(resource){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/allocations/update';
  return http.post(endPoint, resource)
}
/**
 * deleteResource - remove resources from assigned task
 * @returns {object}  
 * @param condition
 */

export function deleteResource(resourceId){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/allocations/delete/' + resourceId;
  return http.get(endPoint)
}
/**
 * addPreSaleTask - add pre sale tasks on resource approval for pre sale projects/ Cr's
 * @returns {object}  
 * @param condition
 */
export function addPreSaleTask(preSaleTask){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/presaletask/add';
  return http.post(endPoint, preSaleTask)
}
/**
 * getCurrentUtilizesOfResources - get current utilized efforts of resources
 * @returns {object}  
 * @param condition
 */
export function getCurrentUtilizesOfResources(params){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/getCurrentUtilizesOfResources';
  return http.post(endPoint, params);
}

/**
 * getExistingTaskResources by id - get project task allocation with the resource assigned
 * @returns {array}  returns an array of project task allocations
 * @param condition
 */
 export function getExistingTaskResources(taskId){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/getExistingTaskResources/' +taskId;
  return http.get(endPoint);
}

/**
 * getAllPendingTimeEntriesForReviseCms - get pending time entries for revise cms
 * @returns {array}  returns an array of pending time entries
 * @param data
 */
export function getAllPendingTimeEntriesForReviseCms(data){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/getAllPendingTimeEntriesForReviseCms';
  return http.post(endPoint, data);
}

/**
 * getPrevCMSResourceRequestsWithTime - get Prev CMS Resource Requests time entries for revise cms
 * @returns {array}  returns an array of Resource Requests
 * @param data
 */
export function getPrevCMSResourceRequestsWithTime(data){
  const endPoint = apiUrl + '/timesheetManagement/projecttasks/projectTaskAllocations/getPrevCMSResourceRequestsWithTime';
  return http.post(endPoint, data);
}