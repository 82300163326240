import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;


/**
 * search Calendars by param.
 * @returns {array} array list of Calendars
 * @param params
 */
export function searchCalendars(params) {
  const endPoint = apiUrl + '/masterData/calendar/calendars/search';
  return http.post(endPoint, params);
}

/**
 * Save Holidays.
 * @returns {array} array list of Holidays
 * @param params
 */
export function saveHoliday(params) {
  const endPoint = apiUrl + '/masterData/calendar/calendars/save';
  return http.post(endPoint, params);
}

/**
 * Get Holidays by given param.
 * @returns {array} array list of Holidays
 * @param countryCalendarId
 */
export function getHolidayByCountryCalendarId(countryCalendarId) {
  const endPoint = apiUrl + '/masterData/calendar/calendars/holiday/' + countryCalendarId;
  return http.get(endPoint);
}

/**
 * Delete Holidays by given param.
 * @returns {array} array list of Holidays
 * @param params
 */
export function deleteHoliday(params) {
  const endPoint = apiUrl + '/masterData/calendar/calendars/delete';
  return http.post(endPoint, params);
}

/**
 * Get Holidays.
 * @returns {array} array list of Holidays
 * @param countryId
 * @param year
 */
export function getHolidays(countryId, year) {
  const endPoint = apiUrl + '/masterData/calendar/country/' + countryId + '/holidays/' + year;
  return http.get(endPoint);
}

