import React, { Component } from 'react';

class MonthYearCell extends Component {
    render() {
        const months = {
            1: 'Jan',
            2: 'Feb',
            3: 'Mar',
            4: 'Apr',
            5: 'May',
            6: 'Jun',
            7: 'Jul',
            8: 'Aug',
            9: 'Sep',
            10: 'Oct',
            11: 'Nov',
            12: 'Dec'
        };
        const dataItem = this.props.dataItem;

        return <td>{months[dataItem.Month]} {dataItem.Year}</td>

    }
}

export default MonthYearCell;
