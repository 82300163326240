import React, { Component } from "react";

class CountriesCell extends Component {
  render() {
    const countries_ = this.props.dataItem.Country;
    let countryCode = "";

    for (let i = 0; i < countries_.length; i++) {
      if (i === countries_.length - 1) {
        countryCode += ` ${countries_[i].Name}`;
      } else {
        countryCode += ` ${countries_[i].Name} /`;
      }
    }
    return <td>{countryCode}</td>;
  }
}
export default CountriesCell;
