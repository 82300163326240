import React, { Component } from 'react';
import 'moment-timezone';
import moment from 'moment';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy } from '@progress/kendo-data-query';
import { Redirect } from 'react-router';
import { firstDayOfMonth, lastDayOfMonth } from '@progress/kendo-date-math';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import YearPicker from './components/YearPicker';
import HolidayCell from './components/HolidayCell';
import DeleteActionCell from './components/DeleteActionCell';

import {
  getAllCountriesWithoutARegionIntegrated
} from '../../integration/MasterDataService';
import {
  getHolidayByCountryCalendarId,
  saveHoliday,
  deleteHoliday
} from './CalendarService';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import * as loggerService from "../../../src/integration/LoggerService";
import { LOG_TYPES } from "../../../src/integration/IntegrationEnums";
import $ from "jquery"

class CalendarCreate extends Component {

  isMount = false;

  constructor(props) {
    super(props);
    this.state = {
      countryData: [],
      countryDataAll: [],
      toCountry: false,
      countryValue: null,
      selectedYear: null,
      holidayComment: null,
      holidayDate: null,
      firstDayOfYear: null,
      lastDayOfYear: null,
      minYear: new Date(),
      holidays: [],
      holidayToBeDeleted: null,
      deletePopupVisible: false,
      visible: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      isDisableCombo: true,
      isDisableSave: false,
      skip: 0,
      take: 20
    };
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
  }

  async componentDidMount() {

    $(document).ready(function() {
      $("div.title-remove > span > span > span:last-child").removeAttr("title");
  });

    $(document).ready(function() {
      $("div.title-remove > div > span > span > span:last-child").removeAttr("title");
  });
  
    this.isMount = true;
    window.scrollTo(0, 0);
    if (this.props.location.action === 'edit') {
      this.props.onHeaderTitleChange('Edit calendar');
      this.state.isDisableCombo = false;
      this.updateCalendarDetails(this.props.location.countryCalendar);

      await getHolidayByCountryCalendarId(this.props.location.countryCalendar.id)
        .then(response => {
          if (this.isMount) {
            this.setState({
              holidays: response.data
            });
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    } else {
      this.props.onHeaderTitleChange('Add New calendar');
    }

    await getAllCountriesWithoutARegionIntegrated()
      .then(response => {
        this.countryDataAll = response.data;
        if (this.isMount) {
          this.setState({
            countryData: response.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  }

  render() {
    if (this.state.toCalendar === true) {
      return <Redirect to="/admin/Calendar/Search" />;
    }
    return (
      <React.Fragment>
        <div className="main-card">
          <div className="main-heading">Calendar Details</div>
          <div className="main-seperator"></div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                <label className="mandatory" >Country:</label>
              </div>
              <div className="d-block">
                <div className="">
                  <ComboBox
                    placeholder="Please Select"
                    name="country"
                    data={this.state.countryData}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleChangeCountry}
                    required={true}
                    value={this.state.countryValue}
                    filterable={true}
                    onFilterChange={this.filterChangeCountry}
                    disabled={this.state.isEditMode}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.countryValue)}>
                      Please select a country
                                        </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                <label className="mandatory" >Year:</label>
              </div>
              <div className="d-block">
                <div className="title-remove">
                  <YearPicker
                    value={this.state.selectedYear}
                    onChange={this.handleOnChangeYear}
                    name="selectedYear"
                    disabled={this.state.isEditMode}
                    min={this.state.minYear}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.selectedYear)}>
                      Please select an year
                                        </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="d-block">
                <label htmlFor="" className="mandatory">
                  Date:
                  </label>
              </div>
              <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    width="100"
                    name="holidayDate"
                    onChange={this.handleHolidayDateChange}
                    required={true}
                    value={this.state.holidayDate}
                    disabled={this.state.isDisableCombo}
                    format="MM/dd/yyyy"
                    min={this.state.firstDayOfYear}
                    max={this.state.lastDayOfYear}
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.holidayDate)}>
                      Please select a holiday
                                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-5">
              <div className="d-block">
                  <label htmlFor="" className="">
                    Comment:
                  </label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-block">
                      <textarea
                        className="k-textarea"
                        name="holidayComment"
                        onChange={this.handleHolidayCommentChange}
                        value={this.state.holidayComment}
                        maxLength="100"
                        disabled={this.state.isDisableCombo}
                      />
                    </div>
                  </div>
                  <div className="col-md-1">
                    {/* <button disabled={this.state.isDisableSave} type="submit" onClick={this.handleSubmit} className="k-icon k-i-plus-circle" style={{ 'fontSize': '32px', 'textAlign': 'center', 'color': ' #0e4261', 'WebkitAppearance': 'none', 'background': 'none', 'border': 'none', 'padding': '0px', 'outline': 'inherit' }} title="Create" ></button> */}
                    <button className="rounded-btn" type="button" disabled={this.state.isDisableSave}
                      onClick={this.handleSubmit}><span
                        className="k-icon k-i-plus"></span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <Grid
                data={this.state.holidays.slice(this.state.skip, this.state.take + this.state.skip)}
                skip={this.state.skip}
                take={this.state.take}
                total={this.state.holidays.length}
                pageable={true}
                onPageChange={this.pageChange}
                resizable
              >
                <Column field="calendar.Country.Name" title="Country" />
                <Column field="calendar.Year" title="Year" />
                <Column field="" title="Holiday" cell={props => <HolidayCell {...props} />} />
                <Column field="HolidayComment" title="Comment" />
                <Column
                  field=""
                  title="Actions"
                  cell={props => <DeleteActionCell {...props} OnDeleteHoliday={this.OnDeleteHoliday} />}
                />
              </Grid>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 btn-align-right">
              <Button onClick={this.onClickCancel}>
                Cancel
              </Button>
            </div>
          </div>

          <div>
            {this.state.visible === true && (
              <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success" />
                  </div>
                )}

                {this.state.showErrorImage === true && (
                  <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error Image" />
                  </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                  {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}

                  {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                      OK
                    </button>
                  )}
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.showCancelConfirmation === true && (
              <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contains unsaved changes. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.navigateBack}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
          <div>
            {this.state.deletePopupVisible && (
              <Dialog title={'Please Confirm'} onClose={this.deleteConfirmationClose}>
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Are you sure you want to delete this?
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={this.deleteConfirmationClose}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.deleteConfirmationOk}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  navigateBack = event => {
    if (this.isMount) {
      this.setState({
        toCalendar: true,
        visible: !this.state.visible
      });
    }
  };

  handleChangeCountry = async event => {
    if (this.isMount) {
      await this.setState({ countryValue: event.target.value, isFormDirty: true });
    }
    await this.validateProperty(event.target.value);
  };

  filterChangeCountry = event => {
    if (this.isMount) {
      this.setState({
        countryData: this.filterDataCountry(event.filter)
      });
    }
  };

  filterDataCountry(filter) {
    const data = this.countryDataAll.slice();
    return filterBy(data, filter);
  }

  handleOnChangeYear = event => {
    const year = event.value;
    const field = event.name;

    //Date of January
    const startDate = new Date(year, 0, 1);
    //Date of December
    const endDate = new Date(year, 11, 1);
    if (this.isMount) {
      this.setState(
        {
          [field]: year,
          firstDayOfYear: firstDayOfMonth(startDate), //returns first date of the year
          lastDayOfYear: lastDayOfMonth(endDate), //returns last date of the year
          isDisableCombo: false,
          isFormDirty: true
        },
        () => {
          console.log(this.state);
        }
      );
    }
  };

  handleHolidayDateChange = event => {
    let holidayDate = event.target.value;
    if (this.isMount) {
      this.setState({
        holidayDate: holidayDate,
        isFormDirty: true
      });
    }
  };

  handleHolidayCommentChange = event => {
    let holidayComment = event.target.value;

    if (this.isMount) {
      this.setState({
        holidayComment: holidayComment,
        isFormDirty: true
      });
    }
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  updateCalendarDetails = calendar => {
    if (this.isMount) {
      this.setState({
        isEditMode: true
      });
    }
    if (calendar) {
      let countryObj = null;

      if (calendar.Country) {
        countryObj = {
          Name: calendar.Country.Name,
          id: calendar.Country.id
        };
      }

      //Date of January
      const startDate = new Date(calendar.Year, 0, 1);
      //Date of December
      const endDate = new Date(calendar.Year, 11, 1);

      if (this.isMount) {
        this.setState({
          selectedYear: calendar.Year,
          countryValue: countryObj,
          firstDayOfYear: firstDayOfMonth(startDate), //returns first date of the year
          lastDayOfYear: lastDayOfMonth(endDate) //returns last date of the year
        });
      }
    } else {
      return null;
    }

  };

  OnDeleteHoliday = async holiday => {
    if (this.isMount) {
      holiday['action'] = this.props.location.action === 'edit' ? 'edit' : 'create'
      await this.setState({
        holidayToBeDeleted: holiday
      });
    }

    this.deleteConfirmationClose();
  };

  deleteConfirmationClose = async () => {
    if (this.isMount) {
      await this.setState({
        deletePopupVisible: !this.state.deletePopupVisible
      });
    }
  };

  deleteConfirmationOk = async () => {
    deleteHoliday(this.state.holidayToBeDeleted)
      .then(res => {
        let deletedHoliday = [];
        deletedHoliday.push(this.state.holidayToBeDeleted);
        const filteredHolidays = this.state.holidays.filter(holiday => !deletedHoliday.includes(holiday));
        if (this.isMount) {
          this.setState({
            holidays: filteredHolidays,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(`The holiday is deleted successfully.`, 'Success');
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        this.toggleDialog(error.response.data, 'Error');
        if (this.isMount) {
          this.setState({
            isDisableSave: false
          });
        }
      });
    if (this.isMount) {
      await this.setState({
        deletePopupVisible: false
      });
    }
  };

  handleSubmit = event => {
    if (this.isMount) {
      this.setState({
        isDisableSave: true
      });
    }
    event.preventDefault();
    console.dir(event.target);
    if (!this.validation()) {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: true,
          visible: true,
          isDisableSave: false,
          showSuccessImage: false,
          showErrorImage: true
        });
      }
      this.toggleDialog('Please fix the highlighted errors to continue.', 'Error');
    } else {
      if (this.isMount) {
        this.setState({
          isErrorMessageVisible: false
        });
      }
      this.createHoliday(event);
    }
  };

  createHoliday = event => {
    const holiday = {
      CountryId: this.state.countryValue.id,
      Year: this.state.selectedYear,
      Holiday: moment(this.state.holidayDate).format('YYYY-MM-DD'),
      HolidayComment: this.state.holidayComment,
      IsActive: true,
      CreatedUser: window.LOGGED_USER.displayName,
      UpdatedUser: window.LOGGED_USER.displayName,
      action: this.props.location.action === 'edit' ? 'edit' : 'create'
    };
    saveHoliday(holiday)
      .then(res => {
        let tempHolidays = this.state.holidays;
        tempHolidays.push(res.data);
        if (this.isMount) {
          this.setState({
            holidays: tempHolidays,
            holidayDate: null,
            holidayComment: '',
            isFormDirty: false,
            isDisableSave: false,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(`The holiday is created successfully.`, 'Success');
      })

      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            isDisableSave: false,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  validation = () => {

    if (
      this.validateProperty(this.state.countryValue)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.selectedYear)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.holidayDate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  toggleDialog = (message, title) => {
    if (this.isMount) {
      this.setState({
        visible: !this.state.visible,
        dialogMessage: message,
        dialogTitle: title
      });
    }
  };

  toggleCancelConfirmDialog = () => {
    if (this.isMount) {
      this.setState({
        showCancelConfirmation: !this.state.showCancelConfirmation
      });
    }
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };
}

export default CalendarCreate;
