import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
    getPracticeHeads,
    getAllocatedRegions,
    savePracticeHead,
    getPracticeHeadData,
    getBillingDiviDropdown,
    getAllPracticeDivisionMap
  } from './PracticeService';
import { Redirect } from 'react-router';
import Loader from '../../integration/components/Loader';
import * as loggerService from "../../../src/integration/LoggerService";
import {LOG_TYPES} from "../../../src/integration/IntegrationEnums";
import SuccessImg from '../../../src/ui/static/images/checked.svg';
import ErrorImg from '../../../src/ui/static/images/cancel.svg';
import { filterBy } from '@progress/kendo-data-query';

class EditPractice extends Component {
    isMount = false;

    constructor(props){
        super(props);

        this.state = {
            practiceName: this.props.location.data ? this.props.location.data.Name : "" ,
            practiceHeads: [],
            practiceHeadsAll: [],
            selectedPracticeHead: null,
            selectedDepartment: null,
            regions: [],
            allRegions: [],
            departments: [],
            allDepartments: [],
            practiceDivisionMap: [],
            selectedRegion: null,
            inputList: [],
            addRegionTextBox: [{}],
            selectedCombination: [],
            showErrorImage: false,
            showSuccessImage: false,
            dialogTitle: "",
            dialogMessage: "",
            visible: false,
            redirectToSearchPractice: false,
            visibleCancelConfirmDialog: false,
            isFormDirty: false,
            isErrorMessageVisible: false,
            hasOnlyOneDepartment: false,
            disableDrpdowns: false
        };
    }

    async componentDidMount(){
        this.isMount = true;
        this.setHeaderTitle();
        await this.getPracticeHeads();
        await this.getAllocatedRegions();
        await this.getDepartments();
        await this.getPracticeDivisionMap();
        await this.getInitialData();
    }

    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Edit Practice');
        }
    };

    componentWillUnmount() {
        this.isMount = false;
    }

    getPracticeHeads = async () => {
        await getPracticeHeads().then(response => {
            this.setState({
                practiceHeads: response.data[0],
                practiceHeadsAll:response.data[0]
              });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    }

    getAllocatedRegions = async () =>{
        await getAllocatedRegions().then(response => {
            let allRegionsData = response.data;
            let Obj = {
                id: null,
                Name: 'All'
            }
            allRegionsData.unshift(Obj);
            this.setState({
                regions: allRegionsData,
                allRegions: allRegionsData
              });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });;
    }

    getDepartments = async () =>{
        await getBillingDiviDropdown().then(response => {
            this.setState({
                departments: response.data,
                allDepartments: response.data
              });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });;
    }

    getPracticeDivisionMap = async () =>{
        await getAllPracticeDivisionMap().then(response => {
            this.setState({
                practiceDivisionMap: response.data,
              });
        })
        .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });;
    }

    getInitialData = async () =>{
        let practiceHeadArray = [];
        if (this.props.location.data) {
            await getPracticeHeadData(this.props.location.data.id).then(async response => {
                if(response.data){
                    for (const item of response.data){
                        let headObj = {
                            id: item.id, 
                            PracticeId:item.PracticeId, 
                            PracticeHead: item.PracticeHead, 
                            displayName: item.User.displayName,  
                            Region: item.AllRegionHead ? null : item.AllocatedRegion.id, 
                            RegionName: item.AllRegionHead ? 'All' : item.AllocatedRegion.Name,
                            DepartmentId: item.DepartmentId,
                            DepartmentName: item.BillingDivision.Name,
                            AllRegionHead: item.AllRegionHead     
                        };
                        practiceHeadArray.push(headObj);                
                    };
                    await this.setState({
                        selectedCombination: practiceHeadArray,
                    });
                }
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
              });
            if (this.props.location.data.id) {
                let departments = [];
                let departmentsForPractice = this.state.practiceDivisionMap.filter(item => item.PracticeCodeSF === this.props.location.data.SFCode);
                for (const practice of departmentsForPractice) {
                    let selectedPractice = this.state.allDepartments.filter(item => item.SFCode === practice.DepartmentCodeSF);
                    departments.push(selectedPractice[0]);
                }
                if (departments && departments.length <= 1) {
                    let disableDrpdowns = false;
                    const hasAllRegionHead = this.state.selectedCombination.filter(item => 
                        item.DepartmentId == departments[0].id && item.AllRegionHead
                    );

                    const checkAddedRegion = this.state.selectedCombination.filter(item => 
                        item.DepartmentId == departments[0].id
                    );

                    if (hasAllRegionHead && hasAllRegionHead.length > 0 || checkAddedRegion.length == this.state.allRegions.length - 1) {
                        disableDrpdowns = true;
                    }
                    await this.setState({ 
                        selectedDepartment: departments[0],
                        hasOnlyOneDepartment: true,
                        disableDrpdowns: disableDrpdowns 
                    })
                }
                await this.setState({ departments: departments })
            }
        }
    }

    handlePracticeHeadChange = async event =>{
        await this.setState({
            selectedPracticeHead: event.target.value,
            isFormDirty: true
        });
    }

    handleDepartmentChange = async event =>{
        const value = event.target.value;
        let hasAllRegionHead = [];
        let checkAddedRegion = [];
        let disableDrpdowns = false;
        if (value) {
            hasAllRegionHead = this.state.selectedCombination.filter(item => 
                item.DepartmentId == value.id && item.AllRegionHead
            );
            checkAddedRegion = this.state.selectedCombination.filter(item => 
                item.DepartmentId == value.id
            );
            if(this.state.selectedRegion){
                const alreadyAddedRegion = this.state.selectedCombination.filter(item => 
                    item.DepartmentId == value.id && this.state.selectedRegion.Name == item.RegionName
                );
                if (alreadyAddedRegion && alreadyAddedRegion.length > 0) {
                    await this.setState({
                        selectedRegion: null,
                    });
                }
            }    
        }

        if (hasAllRegionHead && hasAllRegionHead.length > 0 || checkAddedRegion.length == this.state.allRegions.length - 1) {
            disableDrpdowns = true;
            await this.setState({
                selectedRegion: null,
                selectedPracticeHead: null
            });
        }
        await this.setState({
            selectedDepartment: value,
            isFormDirty: true,
            disableDrpdowns: disableDrpdowns
        });
    }

    handleRegionChange = async event => {
        const value = event.target.value;
        let checkRegion = [];
        let checkAllRegionStatus = [];
        if (this.state.selectedDepartment && value) {
            checkRegion = this.state.selectedCombination.filter(item => 
                item.RegionName == value.Name && item.DepartmentId == this.state.selectedDepartment.id
            );
            if (value.Name == 'All') {
                checkAllRegionStatus = this.state.selectedCombination.filter(item => 
                    !item.AllRegionHead && item.DepartmentId == this.state.selectedDepartment.id
                );
            }
        }

        if (checkRegion.length <= 0 && checkAllRegionStatus.length <= 0) {
            await this.setState({
                selectedRegion: value,
                isFormDirty: true
            })
        } else {
            let msg = "The selected 'Resource Assignment' is already added.";
            if (checkAllRegionStatus.length > 0) {
                msg = "Resource Assignments have been added for this Department. Remove individual Resource Assignment Practice Heads to add a Practice head for 'All'.";
            }
            this.setState({
                showErrorImage: true,
                showSuccessImage: false,
                isErrorMessageVisible: true
            })
            
            this.toggleDialog(msg, "Error");
        }
    }

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };


    filterDataPracticeHead(filter) {
        const data = this.state.practiceHeadsAll.slice();
        return filterBy(data, filter);
    }
    
    filterChangePracticetHead = event => {
        if (this.isMount) {
            this.setState({
                practiceHeads: this.filterDataPracticeHead(event.filter)
            });
        }
    };

    filterDataRegion(filter) {
        const data = this.state.allRegions.slice();
        return filterBy(data, filter);
    }
    
    filterChangeRegion = event => {
        if (this.isMount) {
            this.setState({
                regions: this.filterDataRegion(event.filter)
            });
        }
    };

    addPracticeLine = async () => {
        if (this.state.selectedPracticeHead && this.state.selectedRegion && this.state.selectedDepartment) {
            const newItem = {
                id: 0, 
                PracticeId:this.props.location.data.id, 
                PracticeHead: this.state.selectedPracticeHead.id, 
                displayName: this.state.selectedPracticeHead.displayName,  
                Region: this.state.selectedRegion && this.state.selectedRegion.id ? this.state.selectedRegion.id : null, 
                RegionName: this.state.selectedRegion.Name,
                DepartmentId: this.state.selectedDepartment.id,
                DepartmentName: this.state.selectedDepartment.Name,
                AllRegionHead: this.state.selectedRegion && this.state.selectedRegion.Name == 'All' ? true : false
            };
            this.state.selectedCombination.push(newItem);
    
            let disableDrpdowns = false;
            const hasAllRegionHead = this.state.selectedCombination.filter(item => 
                item.DepartmentId == this.state.selectedDepartment.id && item.AllRegionHead
            );
            const checkAddedRegion = this.state.selectedCombination.filter(item => 
                item.DepartmentId == this.state.selectedDepartment.id
            );

            if (hasAllRegionHead && hasAllRegionHead.length > 0 || checkAddedRegion.length == this.state.allRegions.length - 1) {
                disableDrpdowns = true;
            }
    
            await this.setState({
                selectedPracticeHead: null,
                selectedRegion: null,
                isErrorMessageVisible: false,
                disableDrpdowns: disableDrpdowns,
                practiceHeads: this.state.practiceHeadsAll,
                regions: this.state.allRegions

            })
        } else {
            this.setState({
                showErrorImage: true,
                showSuccessImage: false,
                isErrorMessageVisible: true
            })
            let msg = "Please fix the highlighted errors to continue.";
            this.toggleDialog(msg, "Error");
        }  
    }

    deletePracticeLine = async (index) => {
        var selectedIndexForDelete = this.state.selectedCombination.findIndex(obj => obj.RegionName == index.RegionName && obj.DepartmentId == index.DepartmentId);
        this.state.selectedCombination.splice(selectedIndexForDelete,1);
        var list = this.state.selectedCombination;

        let disableDrpdowns = false;
        let hasAllRegionHead = [];
        if (this.state.selectedDepartment) {
            hasAllRegionHead = list.filter(item => 
                item.DepartmentId == this.state.selectedDepartment.id && item.AllRegionHead
            );
        }

        if (hasAllRegionHead && hasAllRegionHead.length > 0) {
            disableDrpdowns = true;
        }
        await this.setState({
            selectedCombination: list,
            disableDrpdowns: disableDrpdowns
        });
    }

    saveData = async () => {
        if (this.state.selectedCombination && this.state.selectedCombination.length > 0) {
            if (this.state.selectedPracticeHead && this.state.selectedRegion && this.state.selectedDepartment) {
                this.setState({
                    showErrorImage: true,
                    showSuccessImage: false
                })
                let msg = "Please click on add button to save the record to the grid.";
                this.toggleDialog(msg, "Error");
            } else if (this.state.selectedPracticeHead || this.state.selectedRegion){
                this.setState({
                    showErrorImage: true,
                    showSuccessImage: false,
                    isErrorMessageVisible: true
                })
                let msg = "Please fix the highlighted errors to continue.";
                this.toggleDialog(msg, "Error");
            } else {
                await savePracticeHead(this.state.selectedCombination).then(response => {
                    this.setState({
                        showErrorImage: false,
                        showSuccessImage: true
                    })
                    let msg = "Practice heads are succcessfully updated.";
                    this.toggleDialog(msg, "Success");
                })
                .catch(error => {
                    this.setState({
                        showErrorImage: true
                    })
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                    this.toggleDialog(error.response.data, 'Error');
                  });
            }
        } else {
            if (this.state.selectedPracticeHead && this.state.selectedRegion && this.state.selectedDepartment) {
                this.setState({
                    showErrorImage: true,
                    showSuccessImage: false
                })
                let msg = "Please click on add button to save the record to the grid.";
                this.toggleDialog(msg, "Error");
            } else {
                this.setState({
                    showErrorImage: true,
                    showSuccessImage: false,
                    isErrorMessageVisible: true
                })
                let msg = "Please fix the highlighted errors to continue.";
                this.toggleDialog(msg, "Error");
            }
        }
    }

    onClickCancel = event => {
        event.preventDefault();;
        if(this.state.isFormDirty === true){
            this.toggleCancelConfirmaDialog();
        } else {
            this.redirectToUserSearchPractice();
        }
    };

    redirectToUserSearchPractice = () => {
        this.setState({
            redirectToSearchPractice : true
        })
    }

    toggleCancelConfirmaDialog() {
        this.setState({
            visibleCancelConfirmDialog: !this.state.visibleCancelConfirmDialog
        })
    }

    toggleDialog = async (message, title) => {
        this.setState({
          visible: !this.state.visible,
          dialogMessage: message,
          dialogTitle: title
        });
      };

    render () {

        if (this.state.redirectToSearchPractice === true) {
            return <Redirect to="/masterdata/practice/practiceSF" />;
        }

        return (
                <div className="main-card">
                    <div className="main-heading">Practice Details</div>
                    <div className="main-seperator"/>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="d-block">
                                <label className="mandatory">Department:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <ComboBox
                                        data={this.state.departments}
                                        dataItemKey="id"
                                        textField="Name"
                                        required={true}
                                        onChange={this.handleDepartmentChange}
                                        value={this.state.selectedDepartment}
                                        placeholder="Please Select"
                                        name="selectedDepartment"
                                        disabled={this.state.hasOnlyOneDepartment}
                                    />
                                    {this.state.isErrorMessageVisible === true ? (
                                        <span className={this.validateProperty(this.state.selectedDepartment)}>
                                        Please select a Department
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                        <div className="d-block">
                            <label>Practice Name:</label>
                            </div>
                            <div className="d-block">
                            <div className="">
                            <Input
                                value={this.state.practiceName}
                                onChange={this.handleOnChangeInput}
                                name="PracticeName"
                                autoComplete="off"
                                disabled    
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-3">
                            <div className="d-block">
                                <label className="mandatory">Practice Head:</label>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <ComboBox
                                        data={this.state.practiceHeads}
                                        dataItemKey="id"
                                        textField="displayName"
                                        required={true}
                                        onChange={this.handlePracticeHeadChange}
                                        value={this.state.selectedPracticeHead}
                                        placeholder="Please Select"
                                        name="selectedPracticeHead"
                                        filterable={true}
                                        onFilterChange={this.filterChangePracticetHead}
                                        disabled={this.state.disableDrpdowns}
                                    />
                                    {this.state.isErrorMessageVisible === true ? (
                                        <span className={this.validateProperty(this.state.selectedPracticeHead)}>
                                        Please select a Practice Head
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-block">
                                <div className="mandatory"><label>Resource Assignment:</label></div>
                            </div>
                            <div className="d-block">
                                <div className="">
                                    <ComboBox
                                        data={this.state.regions}
                                        dataItemKey="id"
                                        textField="Name"
                                        required={true}
                                        onChange={this.handleRegionChange}
                                        placeholder="Please Select"
                                        value={this.state.selectedRegion}
                                        name="selectedRegion"
                                        filterable={true}
                                        onFilterChange={this.filterChangeRegion}
                                        disabled={this.state.disableDrpdowns}
                                    />
                                    {this.state.isErrorMessageVisible === true ? (
                                        <span className={this.validateProperty(this.state.selectedRegion)}>
                                        Please select a Resource Assignment
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className="d-block">
                                <div className=""><label></label></div>
                            </div>
                            <div className="d-block">
                                <Button className="k-button rounded-btn" type="button" onClick={this.addPracticeLine} disabled={this.state.disableDrpdowns}>
                                    <span class="k-icon k-i-plus" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <br/>

                        {this.state.selectedCombination.map((index) =>( 
                            <div className='row' >
                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <div className="">
                                                <ComboBox
                                                    data={this.state.departments}
                                                    key={index}
                                                    dataItemKey="PracticeHead"
                                                    textField="DepartmentName"
                                                    required={true}
                                                    name={index}
                                                    value={index}
                                                    placeholder="Please Select"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <div className="">
                                                <ComboBox
                                                    data={this.state.practiceHeadsAll}
                                                    key={index}
                                                    dataItemKey="PracticeHead"
                                                    textField="displayName"
                                                    required={true}
                                                    name={index}
                                                    value={index}
                                                    placeholder="Please Select"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <div className="">
                                                <ComboBox
                                                    data={this.state.allRegions}
                                                    key={index}
                                                    name={index}
                                                    dataItemKey="Region"
                                                    textField="RegionName"
                                                    required={true}
                                                    value={index}
                                                    defaultValue={this.state.defaultRegion}
                                                    placeholder="Please Select"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className="d-block">
                                            <div className="col-md-2">                                          
                                            <Button type="button" className="k-button rounded-btn" onClick={()=> this.deletePracticeLine(index)} >
                                                <span class="k-icon k-i-minus" />
                                            </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    <div className="row">
                        <div className="col-md-12 btn-align-right">
                        <Button primary={true} type="submit" onClick={this.saveData}>
                            Save
                        </Button>
                        <Button type="button" onClick={this.onClickCancel} style={{ marginRight: '5px' }}>
                            Cancel
                        </Button>
                        </div>
                    </div>
                    <div>

            {this.state.visibleCancelConfirmDialog && (
              <Dialog title={'Please Confirm'} onClose={()=> this.toggleCancelConfirmaDialog()}>
                <p style={{ margin: '25px', textAlign: 'center' }}>
                    Do you wish to discard the unsaved data?
                </p>
                <DialogActionsBar>
                  <button className="k-button" onClick={()=> this.toggleCancelConfirmaDialog()}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={()=> this.redirectToUserSearchPractice()}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog> 
            )}

            {this.state.visible === true && (
                <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                {this.state.showSuccessImage === true && (
                    <div className="text-center">
                    <img className="successImg" src={SuccessImg} alt="Success"/>
                    </div>
                )}

                {this.state.showErrorImage === true && (
                    <div className="text-center">
                    <img className="successImg" src={ErrorImg} alt="Error"/>
                    </div>
                )}
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                <DialogActionsBar>
                    {this.state.showSuccessImage === true && (
                    <button className="k-button modal-primary" onClick={this.redirectToUserSearchPractice}>
                        OK
                    </button>
                    )}

                    {this.state.showErrorImage === true && (
                    <button className="k-button modal-primary" onClick={this.toggleDialog}>
                        OK
                    </button>
                    )}
                </DialogActionsBar>
                </Dialog>
            )}
                    </div>
                </div>
        )
    }
}

export default EditPractice;