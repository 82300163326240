/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { MultiSelect, ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import MultiIncrementYearPicker from '../../masterData/multiyearIncrement/components/MultiIncrementYearPicker';
import { Redirect } from 'react-router';
import { Tooltip } from 'reactstrap';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import Loader from '../../integration/components/Loader';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem, PanelBarUtils, Menu, MenuItem, MenuItemModel, MenuItemLink, MenuItemArrow, Splitter, Drawer, DrawerNavigation, DrawerContent, Card, CardHeader, CardTitle, CardBody, CardActions, CardImage, Avatar, Stepper } from '@progress/kendo-react-layout'
import {
    Chart,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisTitle,
    ChartCategoryAxisItem
} from '@progress/kendo-react-charts';
import ReactQuill from 'react-quill';

import HeaderCellAddButton from '../cmSheet/component/common/HeaderCellAddButton';
import RowCellDeleteButton from '../cmSheet/component/common/RowCellDeleteButton';
import RowCellComboBox from '../cmSheet/component/common/RowCellComboBox';
import RowCellDropDown from '../cmSheet/component/common/RowCellDropDown';
import RowCellDatePicker from '../cmSheet/component/common/RowCellDatePicker';
import RowCellDuplicateButton from '../cmSheet/component/common/RowCellDuplicateButton';
import RowCellNumericTextBox from '../cmSheet/component/common/RowCellNumericTextBox';
import FactoryRowDetails from './FactoryRowDetails'
import YearPicker from '../../masterData/calendar/components/YearPicker';


class FactoryResourceRequest extends Component {
    isMount = false;
    lastSelectedIndex = 0;


    constructor(props) {
        super(props);
        this.state = {
            selected: 0,
            calculations: [],
            factoryResource: []
        };
    }

    DatePickerCell = props => {
        return (
            <RowCellDatePicker
                {...props}
            />
        );
    }

    ComboBoxCell = props => {
        return (
            <RowCellComboBox
                {...props}

            />
        );
    }

    rowRender(trElement, props) {
        const isHeader = props.dataItem.isHeader;
        const contEffort = props.dataItem.contEffort;
        const className = 'summery-header-style';
        const contclass = 'contract-effort';
        const trProps = { class: isHeader === true ? className : null };
        const cedProps = { class: contEffort === true ? contclass : null };
        if (isHeader) {
            return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
        } else if (contEffort) {
            return React.cloneElement(trElement, { ...cedProps }, trElement.props.children);
        } else
            return trElement;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.isMount = true;
        this.setHeaderTitle();
        this.populateCalculationsGrid();

    }



    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Pool Project');
        }
    };


    handleSelect = (e) => {
        this.setState({ selected: e.selected })
    }

    selectionChange = (event) => {
        const data = this.state.data.map(item => {
            if (item.ProductID === event.dataItem.ProductID) {
                item.selected = !event.dataItem.selected;
            }
            return item;
        });
        this.setState({ data });
    }
    rowClick = event => {
        let last = this.lastSelectedIndex;
        const data = [...this.state.data];
        const current = data.findIndex(dataItem => dataItem === event.dataItem);

        if (!event.nativeEvent.shiftKey) {
            this.lastSelectedIndex = last = current;
        }

        if (!event.nativeEvent.ctrlKey) {
            data.forEach(item => (item.selected = false));
        }
        const select = !event.dataItem.selected;
        for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
            data[i].selected = select;
        }
        this.setState({ data });
    };

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        const data = this.state.data.map(item => {
            item.selected = checked;
            return item;
        });
        this.setState({ data });
    }

    populateCalculationsGrid = () => {
        const calculations = [
            {
                Field: 'Cost of Dedicated Time',
                Value1: 'Baseline',
                Value2: 'Ongoing',
                Value3: 'Variance',
                isHeader: true
            },
            {
                Field: 'Deployable Resources',
                Value1: '163,075',
                Value2: '',
                Value3: ''
            },
            {
                Field: 'Non-Deployable Resources',
                Value1: '7,825',
                Value2: '',
                Value3: ''
            },
            {
                Field: 'Non-Billable OPE',
                Value1: '0',
                Value2: '',
                Value3: ''

            },
            {
                Field: 'JIRA Licenses',
                Value1: '1,440',
                Value2: '',
                Value3: ''
            },
            {
                Field: 'Shift Allowances - Offshore',
                Value1: '86,400',
                Value2: '',
                Value3: ''
            },
            {
                Field: 'Travel Allowances - Offshore',
                Value1: '16,457',
                Value2: '',
                Value3: ''
            },
            {
                Field: 'In-region other costs',
                Value1: '42900',
                Value2: '',
                Value3: ''
            },
            {
                Field: 'COE other costs',
                Value1: '38,500',
                Value2: '',
                Value3: ''
            },
            {
                Field: 'Total Cost Excluding Sales Commission',
                Value1: '356,597',
                Value2: '',
                Value3: '',
                isHeader: true
            },
            {
                Field: 'Available Capacity at Target Billable Utilization (Days)',
                Value1: '2,375',
                Value2: '',
                Value3: '',
                isHeader: true
            },
            {
                Field: 'Net Profit/Loss',
                Value1: '',
                Value2: '',
                Value3: '',
                isHeader: true
            },
            {
                Field: 'Net Profit Margin',
                Value1: '',
                Value2: '',
                Value3: '',
                isHeader: true
            }
        ];

        const calculationTemp = [];
        for (var item of calculations) {
            if (item.isHidden == undefined) {
                calculationTemp.push(item);
            } else if (!item.isHidden) {
                calculationTemp.push(item);
            }
        }
        this.setState(
            {
                calculations: calculationTemp
            },
            () => {
                this.forceUpdate();
            }
        );
    };


    render() {

        const [firstSeries] = [[100, 123, 234, 343]];
        const categories = ['Q1', 'Q2', 'Q3', 'Q4'];
        const ChartContainer = () => (
            <Chart>
                <ChartTitle text="Effort" />
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem categories={categories}>
                        <ChartCategoryAxisTitle text="Designation" />
                    </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                <ChartSeries>
                    <ChartSeriesItem type="bar" gap={2} spacing={0.25} data={firstSeries} />

                </ChartSeries>
            </Chart>
        );
        return (
            <div className="main-card">
                <TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
                    <TabStripTab title="Summary">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="main-heading">Factory Resource Request</div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <label>Project Name:</label>
                                        </div>
                                        <div className="d-block">
                                            <div className="">
                                                <Input
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <label>Start Date:</label>
                                        </div>
                                        <div className="d-block">
                                            <div className="">
                                                <DatePicker
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <label className="">CMS Version:</label>
                                        </div>
                                        <div className="d-block">
                                            <div className="">
                                                <DropDownList
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <label className="mandatory">Billing Region:</label>
                                        </div>
                                        <div className="d-block">
                                            <div className="">
                                                <ComboBox
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <label className="mandatory">Practice/Department:</label>
                                        </div>
                                        <div className="d-block">
                                            <div className="">
                                                <ComboBox
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <label>Offering:</label>
                                        </div>
                                        <div className="d-block">
                                            <div className="">
                                                <ComboBox
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <label>Project Owner:</label>
                                        </div>
                                        <div className="d-block">
                                            <div className="">
                                                <ComboBox
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <label>Target Billable Utilization:</label>
                                        </div>
                                        <div className="d-block">
                                            <div className="">
                                                <Input
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-md-3">
                                        <div className="d-block">
                                            <label>Financial Year:</label>
                                        </div>
                                        <div className="d-block">
                                            <div className="">
                                                <YearPicker
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div className="row mt-4">
                            <div className="col-md-7">
                                <div className="cx-projects">
                                    <Grid
                                        style={{ height: '400px' }}
                                        selectedField="selected"
                                        onSelectionChange={this.selectionChange}
                                        onHeaderSelectionChange={this.headerSelectionChange}
                                        onRowClick={this.rowClick}
                                    >
                                        <Column
                                            field="selected"
                                            width="50px"
                                        />
                                        <Column field="" title="Customer Projects" />
                                        <Column field="" title="Customer" />
                                        <Column field="" title="Status" />
                                        <Column field="" title="Contracted Effort" />
                                        <Column field="" title="Allocated Effort %" />
                                        <Column field="" title="Revenue" />
                                        <Column field="" title="Daily Rate" />
                                    </Grid>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <Grid width="100%" data={this.state.calculations} rowRender={this.rowRender} className="summary-grid">
                                    <Column
                                        field="Field"
                                        title="Field"
                                        width="200px"
                                        headerClassName="calculation-grid-header"
                                    />
                                    <Column field="Value1" headerClassName="calculation-grid-header" />
                                    <Column field="Value2" headerClassName="calculation-grid-header" />
                                    <Column field="Value3" headerClassName="calculation-grid-header" />

                                </Grid>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <ChartContainer />
                        </div>
                    </TabStripTab>
                    <TabStripTab title="Resource Mix">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="d-block">
                                    <label>Financial Year:</label>
                                </div>
                                <div className="d-block">
                                    <div className="">
                                        <YearPicker
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="d-block">
                                    <label>Status:</label>
                                </div>
                                <div className="d-block">
                                    <div className="">
                                        <ComboBox
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col-md-12 btn-align-right">
                                <Button primary={true} type="submit">
                                    Submit for Resource Allocation
                                </Button>
                                <Button primary={true} >
                                    Save
                                </Button>
                                <Button primary={true} >
                                    Add Resource
                                </Button>
                                <Button primary={true} >
                                    Remove Resources
                                </Button>
                                <Button primary={true} >
                                    Edit Resource
                                </Button>
                                <Button primary={true} >
                                    Define Skills
                                </Button>
                                <Button type="button" >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                        <div className="resource-mix-dis">
                            <Grid style={{ height: '450px' }}
                                data={this.state.factoryResource}
                                selectedField="Selected"
                                onHeaderSelectionChange={this.headerSelectionChange}
                                onSelectionChange={this.selectionChange}
                                resizable
                                className="resource-mix-grid"
                                detail={FactoryRowDetails}
                            >
                                <Column
                                    field="Selected"
                                    width="50px"
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Designation</label>}
                                    width="160px"
                                    maxLength={10}
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Resource Region</label>}
                                    width="160px"
                                    maxLength={10}
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Onsite / Offshore</label>}
                                    width="200px"
                                    maxLength={40}
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Start Date</label>}
                                    width="140px"
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>End Date</label>}
                                    width="140px"
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Allocation (%)</label>}
                                    width="120px"
                                    maxLength={3}
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Billable / Non-Billable</label>}
                                    width="100px"
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>FTE</label>}
                                    width="100px"
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Effort</label>}
                                    width="140px"
                                    maxLength={10}
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Revenue</label>}
                                    width="140px"
                                    maxLength={10}
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Cost</label>}
                                    width="140px"
                                    maxLength={10}
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Skill/Experience/Certifications</label>}
                                    width="200px"
                                    maxLength={10}
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Resource Allocated</label>}
                                    width="140px"
                                    maxLength={10}
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Allocated Designation</label>}
                                    width="140px"
                                    maxLength={10}
                                />
                                <Column
                                    field=""
                                    headerCell={props => <label>Status</label>}
                                    width="140px"
                                    maxLength={10}
                                />
                            </Grid>
                        </div>

                        <div className="row mt-2">
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-5">
                                        <label>Deployable FTE:</label>
                                    </div>
                                    <div className="col-md-7">
                                        <Input
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-5">
                                        <label>Deployable resources:</label>
                                    </div>
                                    <div className="col-md-7">
                                        <Input
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-5">
                                        <label>Non-deployable resources:</label>
                                    </div>
                                    <div className="col-md-7">
                                        <Input
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row my-2">
                            <div className="col-md-5">
                                <label htmlFor="">Comment:</label>
                            </div>
                            <div className="col-md-12" >
                                <ReactQuill
                                />
                            </div>
                        </div>
                    </TabStripTab>

                </TabStrip>

            </div>
        );
    }
}


export default FactoryResourceRequest;
