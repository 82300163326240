
import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { Redirect } from 'react-router';
import { savePartner, updatePartner } from './CommissionService';
import SuccessImg from "../../ui/static/images/checked.svg";
import ErrorImg from "../../ui/static/images/cancel.svg";
import * as loggerService from "../../../src/integration/LoggerService";
import {LOG_TYPES} from "../../../src/integration/IntegrationEnums";

class PartnerCreate extends Component {
  default;

  popupSet = {
    width: 'auto'
  };

  constructor(props) {
    super(props);
    console.log(window.LOGGED_USER);
    this.state = {
      toPartner: false,
      PartnerText: '',
      commissionPercentageText: '',
      PartnerId: null,
      PartnerValue: null,
      PartnerActive: false,
      visible: false,
      success: false,
      isErrorMessageVisible: false,
      dialogMessage: '',
      dialogTitle: '',
      isEnableCreate: false,
      isEnableUpdate: false,
      isDisableTextField: false,
      isDisableEditMode: false,
      isDisableSave: false,
      isSaveButtonClicked: false
    };

    this.handleChangePartnerName = this.handleChangePartnerName.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.location.action === 'edit') {
      this.updatePartnerDetail(this.props.location.criteriaItem);
      this.setState({ isDisableTextField: true });
    }
  }

  render() {
    if (this.state.toPartner === true) {
      return (
        <Redirect
          to={{
            pathname: '/admin/Commissions/Commissions',
            CriteriaStatus: 'partner'
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <div className="main-card">
          <div className="main-heading">Partner Details</div>
          <div className="main-seperator"/>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-5">
                  <label>Partner Name :</label>
                </div>
                <div className="col-md-7" id="commissionNameToolTip">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="PartnerText"
                    required
                    maxLength={200}
                    value={this.state.PartnerText}
                    onChange={this.handleChangePartnerName}
                    disabled={this.state.isDisableTextField}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span class={this.validateProperty(this.state.PartnerText)}>
                      Please enter a Partner Name
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-5">
                  <label>Commission %:</label>
                </div>
                <div className="col-md-7">
                  <Input
                    autoComplete="off"
                    type="number"
                    name="commissionPercentageText"
                    required
                    maxLength="100"
                    value={this.state.commissionPercentageText}
                    onChange={this.handleChangeCommissionPercentage}
                    disabled={this.state.isEditMode}
                    min="0"
                    max="100"
                  />
                  {this.state.isErrorMessageVisible === true ? (
                    <span
                      class={this.validateProperty(
                        this.state.commissionPercentageText
                      )}
                    >
                      Please enter Commission %
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 btn-align-right">
              <Button
                primary
                disabled={this.state.isDisableSave}
                type="submit"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
              <Button onClick={this.onClickCancel}>Cancel</Button>
            </div>
          </div>
          <div>
            {this.state.visible === true && (
                <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

                  {this.state.showSuccessImage === true && (
                      <div className="text-center">
                        <img className="successImg" src={SuccessImg} alt="Success"/>
                      </div>
                  )}

                  {this.state.showErrorImage === true && (
                      <div className="text-center">
                        <img className="successImg" src={ErrorImg} alt="Error"/>
                      </div>
                  )}
                  <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

                  <DialogActionsBar>
                    {this.state.showSuccessImage === true && (
                        <button className="k-button modal-primary" onClick={this.navigateBack}>
                          OK
                        </button>
                    )}

                    {this.state.showErrorImage === true && (
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>
                          OK
                        </button>
                    )}
                  </DialogActionsBar>
                </Dialog>
            )}
          </div>
          <div>
            {this.state.showCancelConfirmation === true && (
              <Dialog
                title="Please Confirm"
                onClose={this.toggleCancelConfirmDialog}
                width="300px"
              >
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contains unsaved changes. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button
                    className="k-button active"
                    onClick={this.toggleCancelConfirmDialog}
                  >
                    No
                  </button>
                  <button
                    className="k-button modal-primary"
                    onClick={this.navigateBack}
                  >
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  navigateBack = event => {
    this.setState({
      toPartner: true
    });
  };

  handleChangePartnerName = async event => {
    await this.setState({ PartnerText: event.target.value, isFormDirty: true });
    await this.validateProperty(event.target.value);
  };

  handleChangeCommissionPercentage = async event => {
    await this.setState({
      commissionPercentageText: event.target.value,
      isFormDirty: true
    });
    await this.validateProperty(event.target.value);
  };

  updatePartnerDetail = Partner => {
    console.log(Partner);
    this.setState({
      isDisableEditMode: true
    });
    if (Partner) {
      this.isEnableCreate = true;
      this.isEnableUpdate = false;
      let PartnerGroupObj = null;
      let PartnerStatus = false;
      if (Partner) {
      }
      if (Partner.IsActive) {
        PartnerStatus = true;
      }
      this.setState({
        PartnerValue: Partner,
        PartnerText: Partner.Name,
        commissionPercentageText: Partner.CommissionPercentage,
        PartnerActive: PartnerStatus
      });
    } else {
      return null;
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    console.dir(event.target);
    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true
      });
      this.setState({
        visible: true,
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog(
        'Please fix the highlighted errors to continue',
        'Error'
      );
    } else {
      this.setState({
        isErrorMessageVisible: false
      });
      if (this.props.location.action === 'edit') {
        this.updatePartner(event);
      } else {
        this.createPartner(event);
        this.setState({
          isDisableSave: true
        });
      }
    }
  };

  createPartner = event => {
    const Partner = {
      Name: this.state.PartnerText,
      CommissionPercentage: this.state.commissionPercentageText,
      IsActive: true,
      updatedUser: window.LOGGED_USER.displayName
    };
    savePartner(Partner)
      .then(res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog(
          `The Partner ${res.data.Name} is successfully created`,
          'Success'
        );
        this.setState({});
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog(error.response.data, 'Error');
        this.setState({
          isDisableSave: false
        });
      });
  };

  updatePartner = event => {
    const Partner = {
      Name: this.state.PartnerText,
      CommissionPercentage: this.state.commissionPercentageText,
      IsActive: this.state.IsActive === null ? true : this.state.IsActive,
      updatedUser: window.LOGGED_USER.displayName
    };
    console.log('...............');
    console.log(Partner);
    console.log(window.LOGGED_USER);
    updatePartner(Partner, this.state.PartnerValue.id)
      .then(res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog(
          'The Partner has been successfully updated',
          'Success'
        );
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
          isDisableSave: false,
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  validation = () => {
    if (
      this.validateProperty(this.state.commissionPercentageText).includes(
        'error'
      )
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.PartnerText).includes('error')
    ) {
      return false;
    } else if (
      this.state.commissionPercentageText > 100 ||
      this.state.commissionPercentageText < 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleCancelConfirmDialog = () => {
    this.setState({
      showCancelConfirmation: !this.state.showCancelConfirmation
    });
  };

  onClickCancel = event => {
    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      this.navigateBack(event);
    }
  };
}

export default PartnerCreate;
