import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class ApprovalTypeCell extends Component {
  render() {
    const value = this.props.dataItem.IsApproverNeeded;
    return <td>{value === true ? 'Approval Not Needed' : 'Approval Needed'}</td>;
  }
}

export default ApprovalTypeCell;
