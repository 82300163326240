import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Get Partner Commission by given params.
 * @returns {array} array list of Partner Commission
 * @param partnerId
 */
export function getPartnerCommission(partnerId) {
  const endPoint = apiUrl + '/masterData/commission/partner/' + partnerId;
  return http.get(endPoint);
}

/**
 * Get Sales Commission by given params.
 * @returns {array} array list of Sales Commission
 * @param salesPersonId
 */
export function getSalesCommission(salesPersonId) {
  const endPoint = apiUrl + '/masterData/commission/sales-person/' + salesPersonId;
  return http.get(endPoint);
}

/**
 * Get Sales Person by given params.
 * @returns {array} array list of Sales Person
 * @param SalesPersonName
 * @param IsActive
 */
export function getSalesPersonByName(SalesPersonName, IsActive) {
  const endPoint = apiUrl + '/masterData/commission/salesperson/SearchResult/' + SalesPersonName + '/' + IsActive;
  return http.get(endPoint);
}

/**
 * Get Partner by given params.
 * @returns {array} array list of Partner
 * @param PartnerName
 * @param IsActive
 */
export function getPartnerByName(PartnerName, IsActive) {
  const endPoint = apiUrl + '/masterData/commission/partner/SearchResult/' + PartnerName + '/' + IsActive;
  return http.get(endPoint);
}

/**
 * Save Sales Person.
 * @returns {array} array list of Sales Person
 * @param params
 */
export function saveSalesPerson(params) {
  const endPoint = apiUrl + '/masterData/commission/salesperson/save';
  return http.post(endPoint, params);
}

/**
 * Update Sales Person by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param salesPerson
 * @param id
 */
export function updateSalesPerson(salesPerson, id) {
  const updateSPEndpoint = apiUrl + '/masterData/commission/salesperson/update/' + id;
  return http.put(updateSPEndpoint, salesPerson);
}

/**
 * Update Sales Person status by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param IsActive
 * @param id
 */
export function updateSalesPersonStatus(IsActive, id) {
  const endPoint = apiUrl + '/masterData/commission/salesperson/updateStatus/' + id;
  return http.put(endPoint, IsActive);
}

/**
 * Update Partner status by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param IsActive
 * @param id
 */
export function updatePartnerStatus(IsActive, id) {
  const endPoint = apiUrl + '/masterData/commission/partner/updateStatus/' + id;
  return http.put(endPoint, IsActive);
}

/**
 * Save Partner by when user create/update Partners.
 * @returns {array} array list of Sales Person
 * @param params
 */
export function savePartner(params) {
  const endPoint = apiUrl + '/masterData/commission/partner/save';
  return http.post(endPoint, params);
}

/**
 * Update Partner by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param partner
 * @param id
 */
export function updatePartner(partner, id) {
  const updatePEndpoint = apiUrl + '/masterData/commission/partner/update/' + id;
  return http.put(updatePEndpoint, partner);
}

/**
 * Get all Sales Person for drop downs.
 * @returns {array} array list of all Sales Person
 */
export function getSalesPerson() {
  const endPoint = apiUrl + '/masterData/commission/salesperson/getall';
  return http.get(endPoint);
}

/**
 * Get all Projects Partners.
 * @returns {array} array of all partners
 */
export function getProjectPartners() {
  const endPoint = apiUrl + '/masterData/commission/partners/getall';
  return http.get(endPoint);
}