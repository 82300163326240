import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class EditActionCell extends Component {
    render() {
        const calendar = this.props.dataItem;
        return (
           <td className="action-buttons">
             {calendar.IsActive === true && (window.USER_PERMISSION.indexOf('ED_CAL') > -1 ) && (
                           <Link
                               to={{
                                   pathname: '/admin/calendar/create',
                                   countryCalendar: calendar,
                                   action: 'edit'
                               }}
                           >
                               <div className="rounded-link">
                                   <span className="k-icon k-i-edit" title="Edit" />
                               </div>
                           </Link>
           	)}
           </td>
        );
    }
}

export default EditActionCell;
