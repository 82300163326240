import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * getResourceRegions - retrieves all the Resource Regions records
 * @returns {array}  array of Resource Regions records
 */
export function getResourceRegions() {
  const endPoint = apiUrl + '/masterData/region/resourceRegions/getResourceRegions';
  return http.get(endPoint);
}

/**
 * getActiveResourceRegions - retrieves all the Active Resource Regions records
 * @returns {array}  array of Active Resource Regions records
 */
export function getActiveResourceRegions() {
  const endPoint = apiUrl + '/masterData/region/resourceRegions/getResourceRegions/active';
  return http.get(endPoint);
}

/**
 * getRegions - retrieves all the Regions records
 * @returns {array}  array of Regions records
 */
export function getRegions() {
  const endPoint = apiUrl + '/masterData/region/regions';
  return http.get(endPoint);
}

/**
 * getRegionsWithCountries - retrieves all the Regions With Countries
 * @returns {array}  array of Regions records
 */
export function getRegionsWithCountries() {
  const endPoint = apiUrl + '/masterData/region/regions-countries';
  return http.get(endPoint);
}

/**
 * getRegionInfo - retrieves all the Information of Regions
 * @returns {array}  array of Regions information
 */
export function getRegionInfo() {
  const endPoint = apiUrl + '/masterData/region/resourceregions/getallregionresource';
  return http.get(endPoint);
}

/**
 * Update Region Status by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param IsActive
 * @param regionID
 */
export function updateRegionStatus(IsActive, regionID) {
  const updateRegionEndpoint = apiUrl + '/masterData/region/region/updateStatus/' + regionID;
  return http.put(updateRegionEndpoint, IsActive);
}

/**
 * saveRegion - Save Region
 * @returns {array}  array of saved details of Region
 * @param region
 */
export function saveRegion(region) {
  const saveRegionEndPoint = apiUrl + '/masterData/region/region/save';
  return http.post(saveRegionEndPoint, region);
}

/**
 * Update Region by given param.
 * @returns {object} response 0 or 1 (true or false) as an array
 * @param regionID
 * @param region
 */
export function updateRegion(regionID, region) {
  const updateRegEndPoint = apiUrl + '/masterData/region/region/update/' + regionID;
  return http.put(updateRegEndPoint, region);
}

/**
 * getAllCountriesWithoutARegion - retrieves all the Countries Without ARegion
 * @returns {array}  array of Countries records
 */
export function getAllCountriesWithoutARegion() {
  const endPoint = apiUrl + '/masterData/region/allcountries';
  return http.get(endPoint);
}


/**
 * get Currency by given id
 * @returns {object} response 0 or 1 (true or false)
 * @param regionID
 */
export function getRegionCurrency(regionID) {
    const endPoint = apiUrl + '/masterData/region/region/currency/' + regionID;
    return http.get(endPoint);
}


/**
 * get Region By ID - retrieves region by id
 * @returns {object}  array of Countries records
 */
export function getRegionById(regionId) {
  const endPoint = apiUrl + '/masterData/region/getById/' + regionId;
  return http.get(endPoint);
}

/**
 * Search Region Details.
 * @returns {array} array list of Region Details
 * @param params
 */
 export function searchRegions(params) {
  const endPoint = apiUrl + '/masterData/region/search';
  return http.post(endPoint, params);
}

/**
 * getActiveAllocatedRegions - retrieves all the Allocated Regions records
 * @returns {array}  array of Allocated Regions records
 */
 export function getActiveAllocatedRegions() {
  const endPoint = apiUrl + '/masterData/region/getActiveAllocatedRegions';
  return http.get(endPoint);
}