import http from '../../integration/HttpService';
//import { apiUrl } from '../config.json';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/*User Management*/
/**
 * Get all User List.
 * @returns {object}  array object of User List
 */
export function getUserList() {
  const endPoint = apiUrl + '/userManagement/user/listAll';
  return http.get(endPoint);
}

/**
 * Get all active User List.
 * @returns {object}  array object of User List
 */
export function getUserListActive() {
    const endPoint = apiUrl + '/userManagement/user/listAll/active';
    return http.get(endPoint);
}

/**
 * search Users by param.
 * @param params.
 * @returns {object} array object of Users
 */
export function searchUsers(params) {
  const endPoint = apiUrl + '/userManagement/user/search';
  return http.post(endPoint, params);
}

/**
 * search Users by param.
 * @param params.
 * @returns {object} array object of Users
 */
 export function exportUsers(params) {
  const endPoint = apiUrl + '/userManagement/user/export';
  return http.post(endPoint, params);
}

/**
 * Save User when create/update.
 * @param params.
 * @returns {array} array of User details
 */
export function saveUser(params) {
    const endPoint = apiUrl + '/userManagement/user/save';
    return http.post(endPoint, params);
}

/**
 * Update User.
 * @param params.
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function updateUser(params, id) {
    const endPoint = apiUrl + '/userManagement/user/update/'+ id;
    return http.put(endPoint, params);
}

/**
 * Update User's status.
 * @param params.
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function updateUserStatus(params, id) {
    const endPoint = apiUrl + '/userManagement/user/updateStatus/'+ id;
    return http.put(endPoint, params);
}

/**
 * Update User's status.
 * @param params.
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
 export function deleteUser(params, id) {
  const endPoint = apiUrl + '/userManagement/user/delete/'+ id;
  return http.put(endPoint, params);
}

/**
 * Get all Role List.
 * @returns {array} array of Role List details
 */
export function getRoleList() {
  const endPoint = apiUrl + '/userManagement/role/listAll';
  return http.get(endPoint);
}

/**
 * Get User's status.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
 export function getLoggedInUserStatus() {
  const endPoint = apiUrl + '/userManagement/user/getLoggedInUserStatus';
  return http.get(endPoint);
}

/**
 * Get Filterd user by name.
 * @returns {array}  response list of users
 */

export function getFilteredUsers(params) {
  return http.post(`${apiUrl}/userManagement/user/filteredUsers`, params);
}

/**
 * Get employment type of resources.
 * @param params
 * @returns {AxiosPromise<any>}
 */
 export function getEmploymentType(params) {
  const endPoint = apiUrl + '/resourceManagement/resourceManagement/resource/employmentType';
  return http.get(endPoint, params);
}