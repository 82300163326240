import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

const apiEndpoint = apiUrl + '/projectManagement/project';

/**
 * get Projects by project ID.
 * @param projectId.
 * @returns {array} array object of Projects.
 */
export function getProject(projectId) {
    const endPoint = apiEndpoint + '/' + projectId;
    return http.get(endPoint);
}

/**
 * view Projects by project ID.
 * @param projectId.
 * @returns {array} array object of Projects.
 */
export function viewProject(projectId) {
    const endPoint = apiEndpoint + '/viewProject/' + projectId;
    return http.get(endPoint);
}

/**
 * get Projects by project ID with CR's.
 * @param projectId.
 * @returns {array} array object of Projects.
 */
export function getProjectWithCRs(projectId) {
    const endPoint = apiEndpoint + '/getWithCRs/' + projectId;
    return http.get(endPoint);
}

/**
 * Save Projects when created/updated.
 * @param projects.
 * @returns {array} array object of Projects.
 */
export function saveProject(projects) {
    return http.post(apiEndpoint, projects);
}

/**
 * Get all Unique Ids.
 * @returns {array} array object of Projects.
 */
export function getAllUniqueIds() {
    const endPoint = apiEndpoint + '/all/unique-id';
    return http.get(endPoint);
}

/**
 * Get all Projects or CR For Consolidate CMS.
 * @param data.
 * @returns {array} array object of Projects/CR.
 */
export function getProjCRForConsCMS(data) {
    const endPoint = apiEndpoint + '/getProjectById';
    return http.post(endPoint,data);
}

/**
 * Get all ProjectsIDs.
 * @returns {array} array object of Projects/CR IDs.
 */
export function getAllOPIDs() {
    const endPoint = apiEndpoint + '/all/getallopids';
    return http.get(endPoint);
}

/**
 * Get deployable status.
 * @param params
 * @returns {AxiosPromise<any>}
 */
export function getCMSStatus() {
    const endPoint = apiEndpoint + '/all/getallstatus';
    return http.get(endPoint);
}

/**
 * Search ProjectsIDs by param.
 * @param params.
 * @returns {array} array object of Projects/CR IDs.
 */
export function searchProjects(params) {
    const endPoint = apiEndpoint + '/search';
    return http.post(endPoint, params);
}

/**
 * Search ProjectsIDs by param.
 * @param params.
 * @returns {array} array object of Projects/CR IDs.
 */
 export function searchProjectsNew(count) {
    const endPoint = apiEndpoint + '/search/'+ count;
    return http.get(endPoint);
}

/**
 * Search CR by ProjectsIDs by param.
 * @param params.
 * @returns {array} array object of Projects/CR IDs.
 */
 export function searchProjectsCR(params) {
    const endPoint = apiEndpoint + '/searchCR';
    return http.post(endPoint, params);
}

/**
 * Search ProjectsIDs by param.
 * @param params.
 * @returns {array} array object of Projects/CR IDs.
 */
 export function searchProjectsPerforamnceFix(params) {
    const endPoint = apiEndpoint + '/searchnew';
    return http.post(endPoint, params);
}


/**
 * Update ProjectsIDs by param.
 * @param project.
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function updateProject(project, id) {
    const updateprojendpoint = apiEndpoint + '/updateproject/' + id;
    return http.put(updateprojendpoint, project);
}

/**
 * Get Recent Active Projects by param.
 * @param count.
 * @returns {object}  array object of Recent Active Projects
 */
export function getRecentActiveProjects(count) {
    const endPoint = apiEndpoint + '/recent/active/' + count;
    return http.get(endPoint);
}

/**
 * Get Customer Projects  by param.
 * @param count.
 * @returns {object}  array object of Customer Projects
 */
export function getCustomerProjectsById(id) {
    const endPoint = apiEndpoint + '/customerprojects/' + id;
    return http.get(endPoint);
}

/**
 * delete ProjectsIDs by param.
 * @param projectId.
 * @param projName.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function deleteProject(projectId, projName) {
    const endPoint = apiEndpoint + '/' + projectId;
    return http.delete(endPoint, null);
}

/**
 * Search Active Projects by param.
 * @param user.
 * @returns {object}  array object of Active Projects
 */
export function getActiveProjects() {
    const endPoint = apiEndpoint + '/activeprojects/loggedInUser';
    return http.get(endPoint, null);
}

/**
 * full Text system Search by given param.
 * @param query.
 * @returns {object}  array object of Projects/CMSs
 */
export function fullTextSearchProjects(query) {
    const endPoint = apiEndpoint + '/systemsearch/';
    return http.post(endPoint, query);
}

/**
 * full Text system Search by given param.
 * @param query.
 * @returns {object}  array object of Projects/CMSs
 */
 export function fullTextSearchProjectsNew(query) {
    const endPoint = apiEndpoint + '/systemsearchnew/';
    return http.post(endPoint, query);
}

/**
 * full Text system Search by given param.
 * @param query.
 * @returns {object}  array object of Projects/CMSs
 */
 export function fullTextSearchProjectsCR(query) {
    const endPoint = apiEndpoint + '/systemsearchcr/';
    return http.post(endPoint, query);
}

/**
 * Get projects by given param.
 * @param id.
 * @returns {object}  array object of Projects
 */
export function getProjectsbyId(id) {
    const endPoint = apiEndpoint + '/projectsbyId/' + id;
    return http.get(endPoint, null);
}

/**
 * Get all Project Statuses when user search projects.
 * @returns {array} Information of all Project Statuses
 */
export function getProjectStatuses() {
    const endPoint = apiEndpoint + '/statuses/all';
    return http.get(endPoint);
}

/**
 * Get all Projects.
 * @returns {array} Information of all Projects
 */
export function getAllProjects() {
    const endPoint = apiEndpoint + '/all';
    return http.get(endPoint);
}

/**
 * Get all projects for workflows
 * @returns {object}  array object of Projects
 */
export function getAllProjectsForWorkflows() {
    const endPoint = apiEndpoint + '/getAllProjectsForWorkflows';
    return http.get(endPoint);
}

/**
 * Update status change history.
 * @param resources array
 * @returns {AxiosPromise<any>}
 */
export function saveStatusChangeHistory(resources) {
    const endPoint = apiEndpoint + '/saveStatusChange';
    return http.post(endPoint, resources);
}

/**
 * get activity log data by project/cr id
 * @param id
 * @returns {object} array object of activity log data
 */
export function getActivityLogData(id){
    const endPoint = apiEndpoint + '/activityLogDataById/' + id;
    return http.get(endPoint, null);
}

/**
 * get activity log data by project/cr id sorting and top 3 lines
 * @param id
 * @returns {object} array object of activity log data
 */
export function getActivityLogDataSort(ids){
    const endPoint = apiEndpoint + '/activityLogDataByIdSort'
    return http.post(endPoint, ids);
}



/**
 * get approvals data
 * @param approvalObj
 * @returns {object} array object
 */
export function getApprovalsData(approvalObj){
    const endPoint = apiEndpoint + '/getApprovalsData'
    return http.post(endPoint, approvalObj);
}


/**
 * get all project or crs by approver id
 * @param id
 * @returns {object} array object of activity log data
 */
export function getProjectOrCRByApprover(resource){
    const endPoint = apiEndpoint + '/projectOrCrByResource'
    return http.post(endPoint, resource);
}


/**
 * Get all pool projects by region and practice
 * @param data.
 * @returns {array} array object of Projects
 */
export function getFactoryProjByRegionAndPractice(data) {
    const endPoint = apiEndpoint + '/getfactoryprojbyregionandpractice';
    return http.post(endPoint,data);
}

export function getResourcesById(id) {
    const endPoint = apiEndpoint + '/getResourcesById/' + id;
    return http.get(endPoint);
}

/**
 * approve the status change request
 * @param data.
 * @returns {array} array object of Projects
 */
export function approveStatusChange(data) {
    const endPoint = apiEndpoint + '/approveRequest';
    return http.post(endPoint,data);
}

/**
 * approve the status change request
 * @param data.
 * @returns {array} array object of Projects
 */
export function rejectStatusChange(data) {
    const endPoint = apiEndpoint + '/rejectRequest';
    return http.post(endPoint,data);
}

/**
 * Update Project by param.
 * @param project.
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function updateLinkToFactory(project, id) {
    const updateprojendpoint = apiEndpoint + '/updatelinktofactory/' + id;
    return http.put(updateprojendpoint, project);
}

export function updateLinkToFactoryCR(project, id) {
    const updateprojendpoint = apiEndpoint + '/updatelinktofactorycr/' + id;
    return http.put(updateprojendpoint, project);
}


/**
 * Save search criterias selected.
 * @param data
 * @returns {object} array object of search parameteres
 */
export function saveSearchCriteria(data) {
    const endPoint = apiEndpoint + '/saveSearchCriteria';
    return http.post(endPoint, data);
}

/**
 * Get all saved search criterians by user id
 * @param userId
 * @returns {object} array object of search criterians
 */
export function getSavedSearchCriterians(userId) {
    const endPoint = apiEndpoint + '/getSearchCriteriansByUserId/' + userId;
    return http.get(endPoint, null);
}

/**
 * Save project/cr conversion start date
 * @param data
 * @returns {object}
 */
export function saveConversionStartdate(data) {
    const endPoint = apiEndpoint + '/saveConversionStartdate';
    return http.post(endPoint, data);
}


/**
 * Get all Projects and change requests
 * @returns {array} Information of all Projects and change requests
 */
 export function getAllProjectsforTaskManagement() {
    const endPoint = apiEndpoint + '/projects/allProjectsandCRs';
    return http.get(endPoint);
}

/**
 * Retrieve calculated project statistics
 * @param projectData
 * @returns {array} object of calculated project statistics
 */
export function getCalculatedProjectStatistics(projectData) {
    const endPoint = apiEndpoint + '/getCalculatedProjectStatistics';
    return http.post(endPoint, projectData);
}


/**
 * Get all projects for workflows
 * @returns {object}  array object of Projects
 */
 export function getProjectsForWorkflows(data) {
    const endPoint = apiEndpoint + '/getProjectsForWorkflows';
    return http.post(endPoint,data);
}

/**
 * Get project statistics by project id for Revise CMS.
 * @param data
 * @returns {AxiosPromise<any>}
 */
export function getProjectStatisticsForReviseCMS(data) {
    const endPoint = apiEndpoint + '/getProjectStatisticsForReviseCMS' ;
    return http.post(endPoint, data);
}

/**
 * Get project statistics by project id.
 * @param projectId
 * @returns {AxiosPromise<any>}
 */
export function getProjectStatistics(projectId) {
    const endPoint = apiEndpoint + '/projectStatistics/' + projectId;
    return http.get(endPoint);
}

/**
 * Search Active Projects by param.
 * @param user.
 * @returns {object}  array object of Active Projects
 */
 export function getActiveProjectsPerformanceFix(data) {
    const endPoint = apiEndpoint + '/activeprojectsnew/loggedInUser';
    return http.post(endPoint, data);
}

/**
 * Search Active Projects by param.
 * @param user.
 * @returns {object}  array object of Active Projects
 */
 export function getActiveProjectsCRPerformanceFix(data) {
    const endPoint = apiEndpoint + '/activeprojectscr';
    return http.post(endPoint, data);
}

/**
 * Get projects by ids for workflows RR view
 * @returns {object}  array object of Projects
 */
 export function getProjectsforRRview(data) {
    const endPoint = apiEndpoint + '/getProjectsforRRview';
    return http.post(endPoint,data);
}

/**
 * Save Milestones.
 * @param  milestones array
 * @returns {Promise<AxiosResponse<T>>} Saved milestones.
 */
export function saveMilestones(milestones) {
    const endPoint = apiEndpoint + '/milestones/saveMilestones';
    return http.post(endPoint, milestones);
}

/**
 * delete milestone.
 * @param milestoneId.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function deleteMilestone(milestoneId) {
    const endPoint = apiEndpoint + '/milestones/deleteMilestone/' + milestoneId;
    return http.post(endPoint);
}


