import React, { Component } from 'react';
import 'moment-timezone';
import Parser from 'html-react-parser';
import {getcustomers} from "../../../../masterData/customer/CustomerService";
import {getProjectStatuses} from "../../../project/ProjectService";

class CustomerStatusCell extends Component {

    getStatusName = async () => {
        let value = this.props.dataItem;
        let filterStatus = [];
        await getProjectStatuses()
            .then(res => {
                if (res.data) {
                    filterStatus = res.data.filter(
                        obj => obj.id === value.ChangeRequests[0].StatusId
                    );


                    value.ChangeRequests[0].StatusName = filterStatus[0].StatusName
                }
            })
    }

    render() {
        let value = this.props.dataItem;

        if(value.ChangeRequests.length > 0){

            this.getStatusName()

            return <td>
                {value.ChangeRequests[0].StatusName}
            </td>;

        } else{
            return <td>
                {value.ProjectStatus.StatusName}
            </td>;
        }
    }
}

export default CustomerStatusCell;
