import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Search Onsite Resources Details.
 * @param OnsiteResources - (object)
 * @returns List of Onsite Resources.
 */
export function searchOnsiteResources(OnsiteResources) {
  const endPoint = apiUrl + '/resourceManagement/resourceOnsiteDuration/search';
  return http.post(endPoint, OnsiteResources);
}


/**
 * delete OnsiteResource Id by param.
 * @param resId.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function deleteOnsiteResource(resId) {
  const endPoint = apiUrl + '/resourceManagement/resourceOnsiteDuration/' + resId;
  return http.delete(endPoint);
}

 /** 
 * saveOnsiteResource - Save Onsite Resources
 * @param params
 * @returns {array} array of saved details of Onsite Resources
 */
export function saveOnsiteResource(params) {
  const endPoint = apiUrl + '/resourceManagement/resourceOnsiteDuration/saveOnsiteResource';
  return http.post(endPoint, params);
}

/**
 * update OnsiteResource record by param.
 * @param onsiteResourceData
 * @param id
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function updateOnsiteResource(onsiteResourceData) {
  const endPoint = apiUrl + '/resourceManagement/resourceOnsiteDuration/updateOnsiteResource';
  return http.put(endPoint, onsiteResourceData);
}