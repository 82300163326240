import React, { Component } from "react";
import ResourceRequestStatusENUMS from "./ResourceRequestStatusEnums"
import moment from "moment";



class ResourceRequestLineEndDateCell extends Component {

    constructor(props) {
        super(props);

        this.state = {
            key:null
        }
    }


    onCalculateEndDate = async (startDate) => {

        let dataItem = this.props.dataItem;
        let countryId;
        let totalEffortDays;

        if(dataItem.EndDate === null || dataItem.EndDate === ""){
            totalEffortDays = (dataItem.EffortDays/(dataItem.AllocationPercentage /100));

            if (dataItem.OnsiteStatus === 'Onsite') {
                countryId = dataItem.Type === "Project" ? dataItem.Project.CountryId : dataItem.ChangeRequest.CountryId;
            } else {
                countryId = dataItem.dataValueResourceRegion.DefaultCountryId;
            }

            let { endDate, noOfWorkingDays, totalDays } =  await this.props.calculateNewEstimatedEndDate(totalEffortDays,moment(startDate).format('YYYY-MM-DD'),countryId);

            endDate = moment(endDate).format('YYYY-MM-DD')
            dataItem.EndDate = endDate
            dataItem.EstimatedEndDate = endDate
            this.setState({ key: Math.random() });
            //this.forceUpdate();
        }
    };

     render() {
        let dataItem = this.props.dataItem;
         let projectStartDate = null;
         if(dataItem.Type === 'Project'){
             projectStartDate = dataItem.Project.StartDate
         }
         else{
             projectStartDate = dataItem.ChangeRequest.StartDate
         }

        if (dataItem.StartDate) {
             this.onCalculateEndDate(dataItem.StartDate)
            return <td  key={this.state.key}>{moment(dataItem.EndDate).format('MM-DD-YYYY')}</td>;


        } else {
            if(this.props.dataItem.StartDate === null || this.props.dataItem.StartDate === ''){
                if (this.props.dataItem.SubPhase && this.props.dataItem.SubPhase.StartDate){
                    let value = this.props.dataItem.SubPhase;

                    if(value.StartDate < projectStartDate){
                        this.props.dataItem.StartDate = projectStartDate;
                    }
                    else {
                        this.props.dataItem.StartDate  = value.StartDate;
                    }
                }
                else if(this.props.dataItem.Phase && this.props.dataItem.Phase.StartDate){

                    let value = this.props.dataItem.Phase;

                    if(value.StartDate < projectStartDate){
                        dataItem.StartDate = projectStartDate;
                    }
                    else {
                        dataItem.StartDate = value.StartDate;
                    }

                }
                else {
                    this.props.dataItem.StartDate = projectStartDate;
                }
            }

            this.onCalculateEndDate(dataItem.StartDate)
            return <td  key={this.state.key}>{moment(dataItem.EndDate).format('MM-DD-YYYY')}</td>;
        }
    }

}

export default ResourceRequestLineEndDateCell;
