import React, { Component } from 'react';
import * as loggerService from "../integration/LoggerService";
import {LOG_TYPES} from "../integration/IntegrationEnums";

/**
 * Check if the Internet connectivity is available to the local machine.
 * @param ComposedComponent
 * @returns {NetworkDetector}
 */
export default function (ComposedComponent) {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false
    };

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
    }


    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        const webPing = setInterval(
          () => {
            fetch('//google.com', {
              mode: 'no-cors',
            })
              .then(() => {
                this.setState({ isDisconnected: false }, () => {
                  return clearInterval(webPing)
                });
              }).catch(() => {
              loggerService.writeLog("error", LOG_TYPES.ERROR);
              this.setState({ isDisconnected: true })
          })

          }, 2000);
        return;
      }

      return this.setState({ isDisconnected: true });
    };

    render() {
      const { isDisconnected } = this.state;
      return (
        <div>
          {isDisconnected && (<div className="internet-error">
            Internet Connection Lost
          </div>)
          }
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  }

  return NetworkDetector;
}
