import React, { Component } from 'react';
import { GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-react-dialogs';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { DateInput, Calendar, DatePicker, TimePicker, MultiViewCalendar, DateRangePicker } from '@progress/kendo-react-dateinputs';
import {filterBy, orderBy} from '@progress/kendo-data-query';
import SuccessImg from '../../ui/static/images/checked.svg';
import ErrorImg from '../../ui/static/images/cancel.svg';
import {
  saveTaskTypes, getAllTaskTypes, searchTaskType, updateTaskType
} from './TaskTypeService';
import { windowStage } from '@progress/kendo-react-dialogs/dist/npm/StageEnum';
import 'moment-timezone';
import moment from 'moment/moment';
import { Redirect } from 'react-router';
import * as loggerService from "../../integration/LoggerService";
import Loader from '../../integration/components/Loader';
import { update } from 'lodash';
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import DateTableCell from '../../integration/components/table/DateTableCell';

const ACTION_CREATE = 'create';
const ACTION_EDIT = 'edit';

class TaskType extends Component {

  isMount = false;


  constructor(props) {
    super(props);

    this.state = {
      allTasksTempArray: [],
      selected: 0,
      taskTypeDialog: false,
      taskName: '',
      allBillableStatus: false,
      alltaskSet: [],
      selectedTask: [],
      searchStatus: { Name: 'Active', status: true },
      status: [{ Name: 'Active', status: true }, { Name: 'Inactive', status: false }],
      skip: 0,
      take: 20,
      sort: [{ field: 'updatedAt', dir: 'desc' }],
      gridDetails: [],
      criteriaStatus: '',
      selectedOption: null,
      action: '',
      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      disabledSave: true,
      loading: false,
      billableStatus: '',
      selectedTaskForStatusChange: null,
      taskId: null,
      combodata: []
    }

    this.toggleTaskTypeDialog = this.toggleTaskTypeDialog.bind(this);
    this.handleChangeTaskTypeName = this.handleChangeTaskTypeName.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  toggleTaskTypeDialog() {

    if (this.state.isFormDirty) {
      this.toggleCancelConfirmDialog();
    } else {
      if (this.state.action === "edit") {
        this.setState({
          taskTypeDialog: !this.state.taskTypeDialog,
          disabledSave: false,
          isFormDirty: false
        }, () => this.setAction());
      }
      else {
        this.setState({
          selectedOption: null,
          taskName: null,
          disabledSave: true,
          taskTypeDialog: !this.state.taskTypeDialog,
          isFormDirty: false
        });
      }
    }
  }

  setAction = () => {
    if(this.state.taskTypeDialog == false){
      this.setState({
        action: ""
      });
    }
  };

  toggleCancelConfirmDialog = () => {
    if (this.isMount) {
      this.setState({
        showCancelConfirmation: !this.state.showCancelConfirmation
      });
    }
  };

  navigateToMain = () => {
    if (this.state.action === "edit") {
      this.setState({
        taskTypeDialog: !this.state.taskTypeDialog,
        disabledSave: false,
        showCancelConfirmation: false,
        isFormDirty: false
      }, () => this.setAction());
    }
    else {
      this.setState({
        selectedOption: null,
        taskName: null,
        disabledSave: true,
        taskTypeDialog: !this.state.taskTypeDialog,
        showCancelConfirmation: false,
        isFormDirty: false
      });
    }
  }

  handleChangeTaskTypeName = async event => {

    await this.setState({
      taskName: event.target.value,
    });
    if (!!event.target.value && !!this.state.selectedOption) {
      this.setState({
        disabledSave: false,
        isFormDirty: true
      })
    } else {
      this.setState({
        disabledSave: true,
        isFormDirty: true
      })
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    this.setHeaderTitle();
    this.executeSubmitSearch();
    this.populateTaskTypeCombo();
    this.setState({
      billableStatus: "allBillability"
    });
  }

  componentWillUnmount() {
    this.isMount = false;
  }
  changeAction() {
    this.setState({
      action: "edit"
    });
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Task Type');
    }
  };

  handleSubmit = event => {
    event.preventDefault();

    if (this.isMount) {
      this.setState(
        {
          disabledSave: true
        },
        () => {
          if (this.state.action !== "edit") {
            const userDetails = window.LOGGED_USER;
            let modifiedUser, status; 
            let billabilityStatus;
            if (userDetails && userDetails.displayName !== null) {
              modifiedUser = userDetails.displayName;
            }
            if (this.state.selectedOption === 'bothBillability') {
              billabilityStatus = 3;
              status = 'Both';
            } else if (this.state.selectedOption === 'billable') {
              billabilityStatus = 1;
              status = 'Billable';
            } else if (this.state.selectedOption === 'nonBillable') {
              billabilityStatus = 2;
              status = 'Non-billable'
            }

            const taskObj = {
              Name: this.state.taskName,
              BillabilityTypeId: billabilityStatus,
              IsActive: true,
              updatedUser: modifiedUser
            }
            let result = this.state.allTasksTempArray.filter(obj => obj.Name.toUpperCase() === taskObj.Name.trim().toUpperCase() && obj.BillabilityTypeId == status)
            if (result && result.length > 0){
              this.setState({showSuccessImage: false, showErrorImage: true, disabledSave: true});
              const message = 'Cannot duplicate Task Type within Billable/Non-Billable';
              const title = 'Create New Task Type';
              this.toggleMessageDialog(message, title);
            }
            else{
              saveTaskTypes(taskObj)
              .then(res => {
                this.populateTaskTypeCombo();
                this.setState({
                  billableStatus: 'allBillability',
                  showSuccessImage: true,
                  showErrorImage: false,
                  disabledSave: false,
                });
                const message = `The Task Type \'${this.state.taskName}\' is successfully created`;
                const title = 'Success';
                this.toggleMessageDialog(message, title);
                this.setState({
                  taskTypeDialog: !this.state.taskTypeDialog,
                  showCancelConfirmation: false,
                  isFormDirty: false
                });

              })
              .catch(error => {
                const message = error.response.data;
                const title = 'Error';
                this.setState({
                  showSuccessImage: false,
                  showErrorImage: true,
                  disabledSave: false,
                  showCancelConfirmation: false
                });
                this.toggleMessageDialog(message, title);
              });
          }
            }
            

          else {
            const userDetails = window.LOGGED_USER;
            let modifiedUser, status;
            if (userDetails && userDetails.displayName !== null) {
              modifiedUser = userDetails.displayName;
            }
            let billabilityStatus;

            if (this.state.selectedOption === 'bothBillability') {
              billabilityStatus = 3;
              status = 'Both';
            }
            if (this.state.selectedOption === 'billable') {
              billabilityStatus = 1;
              status = 'Billable';
            }
            if (this.state.selectedOption === 'nonBillable') {
              billabilityStatus = 2;
              status = 'Non-billable';
            }

            const taskObj = {
              id: this.state.taskId,
              Name: this.state.taskName,
              BillabilityTypeId: billabilityStatus,
              IsActive: true,
              updatedUser: modifiedUser
            }
            let result = this.state.allTasksTempArray.filter(obj => obj.Name == taskObj.Name.trim() && obj.BillabilityTypeId == status)
            if (result && result.length > 0){
              this.setState({showSuccessImage: false, showErrorImage: true, disabledSave: true});
              const message = 'Cannot duplicate Task Type within Billable/Non-Billable';
              const title = 'Create New Task Type';
              this.toggleMessageDialog(message, title);
            }
            else{
              updateTaskType(taskObj)
                .then(res => {
                  this.setState({
                    showSuccessImage: true,
                    showErrorImage: false,
                    disabledSave: false
                  });
                  const message = `The task type ${this.state.taskName} is successfully updated`;
                  const title = 'Success';
                  this.toggleMessageDialog(message, title);
                  this.setState({
                    taskTypeDialog: !this.state.taskTypeDialog,
                    action: '',
                    taskName: null,
                    disabledSave: true,
                    selectedOption: null,
                    showCancelConfirmation: false,
                    isFormDirty: false
                  });

                })
                .catch(error => {
                  loggerService.writeLog(error, LOG_TYPES.ERROR);
                  const message = error.response.data;
                  const title = 'Error';
                  this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                    disabledSave: false,
                    showCancelConfirmation: false
                  });
                  this.toggleMessageDialog(message, title);
                });
              }
          }
        }
      );
    }

  }

  handleOnChangeTaskType = event => {
    const valueObj = event.target.value;
    const field = event.target.name;
    if (this.isMount) {
      this.setState({
        [field]: valueObj
      });
    }
  };

  populateAllTasks = () => {
    getAllTaskTypes().then(response => {
      let data = response.data;
      this.setState({
        allTasksTempArray: data
      });
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].BillabilityTypeId !== null) {
          if (response.data[i].BillabilityTypeId === "BILLABLE") {
            response.data[i].BillabilityTypeId = "Billable"
          }
          if (response.data[i].BillabilityTypeId === "NONBILLABLE") {
            response.data[i].BillabilityTypeId = "Non-billable"
          }
          if (response.data[i].BillabilityTypeId === "BOTH") {
            response.data[i].BillabilityTypeId = "Both"
          }
        }
      }
      if (this.isMount) {
        this.setState({
          // alltaskSet: data,
          gridDetails: data
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateTaskTypeCombo = () => {
    getAllTaskTypes().then(response => {
      if (this.isMount) {
        this.setState({
          alltaskSet: response.data,
          combodata: response.data
        });
      }
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  }

  handleInputChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    await this.setState({ [name]: value });
  };

  filterOnChange = event => {
    clearTimeout(this.timeout);
    const field = event.target.name;
    this.timeout = setTimeout(() => {
      switch (field) {
        case 'selectedTask': {
          if (this.isMount) {
            this.setState({
              alltaskSet: this.filterComboData(event.filter, this.state.combodata)
            });
          }
          break;
        }
        default: {
        }
      }
    }, 0);
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  statusCell = props => {
    if (props.dataItem) {
      return <td>{props.dataItem.IsActive === true ? 'Active' : 'Inactive'}</td>;
    }
    return null;
  };

  handleOptionChange = event => {
    this.setState({
      selectedOption: event.target.value
    });
    if (!!this.state.taskName && !!event.target.value) {
      this.setState({
        disabledSave: false,
        isFormDirty: true
      })
    } else {
      this.setState({
        disabledSave: true,
        isFormDirty: true
      })
    }
  };

  handleBillableChange = async event => {
    await this.setState({
      billableStatus: event.target.value
    });
    if (this.isMount) {
      if (this.state.billableStatus === "nonbillableStatus") {
        let data = this.state.combodata.filter(obj => obj.BillabilityTypeId === "NONBILLABLE")
        await this.setState({
          alltaskSet: data
        })
      }
      if (this.state.billableStatus === "BillableStatus") {
        let data = this.state.combodata.filter(obj => obj.BillabilityTypeId === "BILLABLE")
        await this.setState({
          alltaskSet: data
        })

      }
      if (this.state.billableStatus === "bothBillableandNon") {
        let data = this.state.combodata.filter(obj => obj.BillabilityTypeId === "BOTH")
        await this.setState({
          alltaskSet: data
        })

      }
      if (this.state.billableStatus === "allBillability") {
        await this.setState({
          alltaskSet: this.state.combodata
        })

      }
    }

  };

  onClickCreate = event => {
    event.preventDefault();
    if (this.isMount) {
      this.setState(
        {
          taskTypeAction: ACTION_CREATE
        },
        () => {
          this.navigateToCreate();
        }
      );
    }
  };

  navigateToCreate = () => {
    if (this.isMount) {
      this.setState({
        redirectToCreate: true
      });
    }
  };

  toggleMessageDialog = (message, title) => {
    if (this.state.showMessageDialog === true && this.state.dialogTitle === 'Success') {
      this.setState({
        showMessageDialog: !this.state.showMessageDialog,
      });
      this.populateAllTasks();
    } else {
      if (this.isMount) {
        this.setState({
          showMessageDialog: !this.state.showMessageDialog,
          dialogMessage: message,
          dialogTitle: title
        });
      }
    }
  };

  cancelSearch = event => {
    if (event) {
      event.preventDefault();
    }
    this.populateAllTasks();
    this.setState({
      alltaskSet: this.state.combodata,
      selectedTask: null,
      searchStatus: null,
      billableStatus: 'allBillability'
    });
  }

  submitSearch = event => {
    if (event) {
      event.preventDefault();
    }
    this.executeSubmitSearch();
  };


  actionButtonCell = props => {
    return (
      <td className="action-buttons">
        {props.dataItem.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_TSKTYP') > -1) && (
          <a
            href="#"
            onClick={event => this.onClickEdit(event, props.dataItem)}
          >
            <div className="rounded-link" title="Edit">
              <span className="k-icon k-i-edit" />
            </div>
          </a>
        )}
        {props.dataItem.IsActive === true && (window.USER_PERMISSION.indexOf('EDT_TSKTYP') > -1) && (
          <a
            href="#"
            onClick={event => this.onClickDeactivate(event, props.dataItem)}
          >
            <div className="rounded-link" title="Deactivate">
              <span className="k-icon k-i-unsort" />
            </div>
          </a>
        )}
        {props.dataItem.IsActive === false && (window.USER_PERMISSION.indexOf('EDT_TSKTYP') > -1) && (
          <a
            href="#"
            onClick={event => this.onClickActivate(event, props.dataItem)}
          >
            <div className="rounded-link" title="Activate">
              <span className="k-icon k-i-check" />
            </div>
          </a>
        )}
      </td>
    );
  };

  onClickActivate = async (event, data) => {
    event.preventDefault();
    const record = JSON.parse(JSON.stringify(data));
    record.IsActive = true;
    if (this.isMount) {
      this.setState(
        {
          selectedTaskForStatusChange: record
        },
        () => {
          this.toggleConfirmDialog(
            'Are you sure you want to activate this?',
            this.activateTask
          );
        }
      );
    }
  };



  activateTask = () => {
    const record = this.state.selectedTaskForStatusChange;
    let billabilityStatus;
    if (record.BillabilityTypeId === 'Both') {
      billabilityStatus = 3;
    }
    if (record.BillabilityTypeId === 'Billable') {
      billabilityStatus = 1;
    }
    if (record.BillabilityTypeId === 'Non-billable') {
      billabilityStatus = 2;
    }

    const taskObj = {
      id: record.id,
      Name: record.Name,
      BillabilityTypeId: billabilityStatus,
      IsActive: true,
      updatedUser: window.LOGGED_USER && window.LOGGED_USER.displayName !== null ? window.LOGGED_USER.displayName : record.updatedUser
    }
    updateTaskType(taskObj)
      .then(res => {
        const data = this.state.gridDetails.slice();
        const filter = data.filter(obj => obj.id === record.id);
        if (filter.length > 0) {
          filter[0].IsActive = true;
        }
        if (this.isMount) {
          this.setState({
            gridDetails: data,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        const message = 'Successfully activated';
        const title = 'Success';
        this.toggleMessageDialog(message, title);
        this.populateTaskTypeCombo();
      })
      .catch(error => {
        // loggerService.writeLog(error, LOG_TYPES.ERROR);
        const message =
          error && error.response ? error.response.data : 'An error occurred';
        const title = 'Error';
        this.toggleMessageDialog(message, title);
      });
    this.toggleConfirmDialog('', null);
  };


  onClickDeactivate = async (event, data) => {
    event.preventDefault();
    const record = JSON.parse(JSON.stringify(data));
    record.IsActive = false;
    if (this.isMount) {
      this.setState(
        {
          selectedTaskForStatusChange: record
        },
        () => {
          this.toggleConfirmDialog(
            'Are you sure you want to deactivate this?',
            this.deactivateTask
          );
        }
      );
    }
  };

  deactivateTask = () => {
    const record = this.state.selectedTaskForStatusChange;
    let billabilityStatus;
    if (record.BillabilityTypeId === 'Both') {
      billabilityStatus = 3;
    }
    if (record.BillabilityTypeId === 'Billable') {
      billabilityStatus = 1;
    }
    if (record.BillabilityTypeId === 'Non-billable') {
      billabilityStatus = 2;
    }

    const taskObj = {
      id: record.id,
      Name: record.Name,
      BillabilityTypeId: billabilityStatus,
      IsActive: false,
      updatedUser: window.LOGGED_USER && window.LOGGED_USER.displayName !== null ? window.LOGGED_USER.displayName : record.updatedUser
    }
    updateTaskType(taskObj)
      .then(async res => {
        const records = this.state.gridDetails.slice();
        const filter = records.filter(obj => obj.id === record.id);
        if (filter.length > 0) {
          filter[0].IsActive = false;
        }
        if (this.isMount) {
          this.setState({
            gridDetails: records,
            showSuccessImage: true,
            showErrorImage: false
          });
        }
        const message = 'Successfully deactivated';
        const title = 'Success';
        this.toggleMessageDialog(message, title);
        this.submitSearch(null);
        this.populateTaskTypeCombo();
      })
      .catch(error => {
        // loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.isMount) {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
        }
        const message =
          error && error.response ? error.response.data : 'An error occurred';
        const title = 'Error';
        this.toggleMessageDialog(message, title);
        this.submitSearch(null);
      });
    this.toggleConfirmDialog('', null);
  };


  toggleConfirmDialog = (message, action) => {
    if (this.isMount) {
      this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
      });
    }
  };

  onClickEdit = async (event, data) => {
    event.preventDefault();
    if (this.isMount) {
      await this.setState(
        {
          action: ACTION_EDIT
          // selectedTaskForStatusChange: data
        }
      );
    }

    if (data && data.BillabilityTypeId === "Both") {
      this.setState(
        {
          selectedOption: "bothBillability",
          taskName: data.Name,
          taskId: data.id
        }
      );
    }
    if (data && data.BillabilityTypeId === "Non-billable") {
      this.setState(
        {
          selectedOption: "nonBillable",
          taskName: data.Name,
          taskId: data.id
        }
      );
    }
    if (data && data.BillabilityTypeId === "Billable") {
      this.setState(
        {
          selectedOption: "billable",
          taskName: data.Name,
          taskId: data.id
        }
      );
    }


    this.toggleTaskTypeDialog();
  };



  executeSubmitSearch() {
    if (this.isMount) {
      this.setState({
        loading: true
      });
    }

    const fields = {};
    if (this.state.selectedTask && this.state.selectedTask.length > 0) {
      fields.Name = this.state.selectedTask.map(value => value.Name);
    }
    if (this.state.searchStatus && this.state.searchStatus) {
      fields.IsActive = this.state.searchStatus ? this.state.searchStatus.Name : null;
    }
    if (this.state.billableStatus && this.state.billableStatus !== null) {
      if (this.state.billableStatus === "bothBillableandNon") {
        fields.BillabilityTypeId = 3;
      }
      if (this.state.billableStatus === "BillableStatus") {
        fields.BillabilityTypeId = 1;
      }
      if (this.state.billableStatus === "nonbillableStatus") {
        fields.BillabilityTypeId = 2;
      }
    }

    searchTaskType(fields)
      .then(response => {
        let data = response.data;
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].createdAt !== null) {
            response.data[i].createdAt = moment(response.data[i].createdAt).format('MM/DD/YYYY');
          }
          if (response.data[i].updatedAt !== null) {
            response.data[i].updatedAt = moment(response.data[i].updatedAt).format('MM/DD/YYYY');
          }
          if (response.data[i].BillabilityTypeId !== null) {
            if (response.data[i].BillabilityTypeId === "BILLABLE") {
              response.data[i].BillabilityTypeId = "Billable";
            }
            if (response.data[i].BillabilityTypeId === "NONBILLABLE") {
              response.data[i].BillabilityTypeId = "Non-billable";
            }
            if (response.data[i].BillabilityTypeId === "BOTH") {
              response.data[i].BillabilityTypeId = "Both";
            }
          }
        }
        if (this.isMount) {
          this.setState({
            gridDetails: data,
            loading: false,
            skip: 0
          });
        }

      })
      .catch(error => {
      });
  }

  render() {
    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">Task Type Search</div>
          </div>
          <div className="col-md-4">
            <Button primary={true} onClick={this.toggleTaskTypeDialog} className="pull-right" disabled={!(window.USER_PERMISSION.indexOf('EDT_TSKTYP') > -1)}>Create New Task Type</Button>
          </div>
        </div>
        <div className="">
          <div className="row">
            <div className="col-md-2">
              <div className="d-block">
                <label>Task Type:</label>
              </div>
              <div className="d-block">
                <MultiSelect
                  name="selectedTask"
                  textField="Name"
                  dataItemKey="id"
                  value={this.state.selectedTask}
                  data={this.state.alltaskSet}
                  placeholder={'Please Select'}
                  onChange={this.handleInputChange}
                  filterable={true}
                  onFilterChange={this.filterOnChange}
                />
              </div>

            </div>

            <div className="col-md-2">
              <div className="d-block">
                <label>Status:</label>
              </div>
              <div className="d-block">
                <ComboBox
                  name="searchStatus"
                  textField="Name"
                  dataItemKey="status"
                  value={this.state.searchStatus}
                  data={this.state.status}
                  placeholder={'Please Select'}
                  onChange={this.handleInputChange} />
              </div>

            </div>
          </div>
          <div className="row">
            <div className="my-auto mx-4">
              <div className="d-block"><label></label></div>
              <div className="d-block">
                <input
                  type="radio"
                  value="allBillability"
                  id="allBillability"
                  className="k-radio"
                  checked={this.state.billableStatus === 'allBillability'}
                  onChange={this.handleBillableChange}
                />
                <label className="k-radio-label mr-3 font-weight-bold" htmlFor="allBillability">
                  All
                                    </label>

                <input
                  type="radio"
                  value="bothBillableandNon"
                  id="bothBillableandNon"
                  className="k-radio"
                  checked={this.state.billableStatus === 'bothBillableandNon'}
                  onChange={this.handleBillableChange}
                />
                <label className="k-radio-label mr-3 font-weight-bold" htmlFor="bothBillableandNon">
                  Both
                                    </label>
                <input
                  type="radio"
                  value="BillableStatus"
                  id="BillableStatus"
                  className="k-radio"
                  checked={this.state.billableStatus === 'BillableStatus'}
                  onChange={this.handleBillableChange}
                />
                <label className="k-radio-label mr-3 font-weight-bold" htmlFor="BillableStatus">
                  Billable
                                    </label>

                <input
                  type="radio"
                  value="nonbillableStatus"
                  id="nonbillableStatus"

                  className="k-radio"
                  checked={this.state.billableStatus === 'nonbillableStatus'}
                  onChange={this.handleBillableChange}
                />
                <label className="k-radio-label font-weight-bold" htmlFor="nonbillableStatus">
                  Non-Billable
                                    </label>
              </div>
            </div>




          </div>
        </div>

        <div className="row">
          <div className="col-md-12 btn-align-right">
            <Button primary={true} type="submit" onClick={this.submitSearch}>
              Search
                </Button>
            <Button type="button" onClick={this.cancelSearch} style={{ marginRight: '5px' }}>
              Clear
                </Button>
          </div>
        </div>

        <div className="main-seperator"></div>

        <div className="row">
          <div className="col-md-12">
            <div className="main-heading">Search Results</div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Grid data={orderBy(this.state.gridDetails, this.state.sort).slice(
              this.state.skip,
              this.state.take + this.state.skip
            )}
              skip={this.state.skip}
              take={this.state.take}
              total={this.state.gridDetails.length}
              pageable
              onPageChange={this.pageChange}
              resizable
              sortable={true}
              sort={this.state.sort}
              onSortChange={(e) => {
                this.setState({
                  sort: e.sort
                });
              }
              } >
              <Column field="BillabilityTypeId" title="Billable/Non-Billable" width="120px" />
              <Column field="Name" title="Task Type" />
              <Column
                  field="createdAt"
                  title="Created Date"
                  width="130px"
                  cell={props => <DateTableCell value={props.dataItem.createdAt} />}
              />
              <Column
                  field="updatedAt"
                  title="Updated Date"
                  width="130px"
                  cell={props => <DateTableCell value={props.dataItem.updatedAt} />}
              />
              <Column field="updatedUser" title="Last Modified User" />
              <Column field="IsActive" title="Status" width="100px" cell={this.statusCell} />
              <Column field="" title="Actions" width="150px" cell={this.actionButtonCell} />
            </Grid>
          </div>
        </div>

        {this.state.taskTypeDialog &&
          <Dialog title={"Task Type Details"} onClose={this.toggleTaskTypeDialog} width={400}>


            <div className="col-md-12">
              <div className="row mx-auto">
                <div className="">
                  <div className="d-block"><label></label></div>
                  <input
                    type="radio"
                    value="bothBillability"
                    id="both"
                    className="k-radio"
                    checked={this.state.selectedOption === 'bothBillability'}
                    onChange={this.handleOptionChange}
                  />
                  <label className="k-radio-label mr-3 font-weight-bold" htmlFor="both">
                    Both
                                    </label>
                  <input
                    type="radio"
                    value="billable"
                    id="bill"
                    className="k-radio ml-4"
                    checked={this.state.selectedOption === 'billable'}
                    onChange={this.handleOptionChange}
                  />
                  <label className="k-radio-label mr-3 font-weight-bold" htmlFor="bill">
                    Billable
                                    </label>

                  <input
                    type="radio"
                    value="nonBillable"
                    id="non"
                    className="k-radio ml-4"
                    checked={this.state.selectedOption === 'nonBillable'}
                    onChange={this.handleOptionChange}
                  />
                  <label className="k-radio-label font-weight-bold" htmlFor="non">
                    Non-Billable
                                    </label>
                </div>



              </div>
              <div className="row mb-3">
                <div className="col-md-9">
                  <div className="d-block">
                    <label className="mandatory">Task Type:</label>
                  </div>
                  <div className="d-block">
                    <Input
                      autoComplete="off"
                      type="text"
                      name="designationText"
                      required={true}
                      maxLength="50"
                      value={this.state.taskName}
                      onChange={this.handleChangeTaskTypeName}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button " onClick={this.toggleTaskTypeDialog}>Cancel</button>
              <button className="k-button modal-primary" onClick={this.handleSubmit} disabled={this.state.disabledSave}>Save</button>
            </DialogActionsBar>
          </Dialog>}

        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}

        <div>
          {this.state.showCancelConfirmation === true && (
              <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  This form contains unsaved changes. Do you want to close it?
                </p>
                <DialogActionsBar>
                  <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                    No
                  </button>
                  <button className="k-button modal-primary" onClick={this.navigateToMain}>
                    Yes
                  </button>
                </DialogActionsBar>
              </Dialog>
          )}
        </div>

        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        <Loader loading={this.state.loading} />
      </div>
    )
  }


}

export default TaskType;
