/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { MultiSelect, ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import MultiIncrementYearPicker from '../../masterData/multiyearIncrement/components/MultiIncrementYearPicker';
import { Redirect } from 'react-router';
import { Tooltip } from 'reactstrap';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import Loader from '../../integration/components/Loader';
import { getProjectWithCRs, getProjectStatuses } from "../project/ProjectService";
import { saveChangeReq, getChangeRequest, updateChangeReq } from "../changeRequest/ChangeRequestService";
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import { getPMResources, getProjectOwners, saveResourceRequests } from "../../resourceManagement/ResourceService";
import {
    getBillingDiviIntegrated,
    getProjectPartnersIntegrated, getRegionsWithCountriesIntegrated,
    getSalesPersonIntegrated
} from "../../integration/MasterDataService";
import { getCurrencies, getPaymentMethods, getProjectTypes } from "../../masterData/common/CommonService";
import { getcustomers } from "../../masterData/customer/CustomerService";
import { getOfferings } from "../../masterData/offering/OfferingService";
import YearPicker from '../../masterData/calendar/components/YearPicker';

import moment from "moment";

const resourceAll = [];

class poolRevision extends Component {
    isMount = false;

    constructor(props) {
        super(props);
        this.state = {
            billingRegionValue: null,
            additionalBillingregionItems: [],
            practiceValue: null,
            PracticeItems: [],
            CurrencyValue: null,
            AdditionalbillingRegionValue:null,
            offeringValue: [],
            offeringItems: [],
            projectOwnerValue: null,
            project: null,
            changeRequest: null,
            projectName: null,
            allBillingRegions: [],
            currencies: [],
            TargetBillable: null,
            startdate:null,
            financialYear: null,
            statusItems: [],
            statusValue: null,
            dialogMessage: '',
            dialogTitle: '',
            visible: false,
            toDashboard: false,
            toSearchScreen: false,
            isFormDirty: false,
            showConfirmDialog: false,
            confirmDialogAction: null,
            isSaveDisable: false,
            fieldEditability: {
                projectName: true,
                primaryBillingRegion: true,
                additionalBillingRegion: true,
                practice: true,
                offering: true,
                startDate: true,
                status: true,
                targetBillableUtilization: true,
                financialYear: true,
                currency: true,
                projectOwner: true
            }
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.isMount = true;
        this.setHeaderTitle();
        this.setInitialData();
    }

    setHeaderTitle = () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            this.props.onHeaderTitleChange('Pool Project');
        }
    };

    setInitialData = async () => {

        await this.populateCurrencies();

        this.setState(
            {
                action: this.props.location.action
            },
            () => {
                if (this.props.location.action === 'create' && this.props.location.projectId > 0) {

                    getProjectWithCRs(this.props.location.projectId, true)
                        .then(res => {
                            if (res && res.data && res.data.id === this.props.location.projectId) {

                                this.setState(
                                    {
                                        project: res.data,
                                    },
                                    () => {
                                        this.setFieldEditability();
                                        this.populateDropDownData();
                                        this.renderProjectDetails(res.data, false);
                                    }
                                );
                            }
                        })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });
                            
                } else if ((this.props.location.action === 'view' || this.props.location.action === 'edit') && this.props.location.changeReqId > 0){

                    getChangeRequest(this.props.location.changeReqId).then(res => {
                        if (res && res.data && res.data.id === this.props.location.changeReqId) {
                            
                            this.setState(
                                {
                                    changeRequest: res.data,
                                },
                                () => {
                                    this.setFieldEditability();
                                    this.populateDropDownData();
                                    this.renderProjectDetails(res.data, true);
                                }
                            );
                        }
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                }
            }
        );
        
    };

    populateCurrencies = async () => {
        try {
            const {data} = await getCurrencies();
            if (data && data.length > 0 && data[0].id) {
                if (this.isMount) {
                    this.setState({
                        currencies: data
                    });
                }
            }
        } catch (error) {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        }
    };

    renderProjectDetails = async (project, isEditOrView) => {
        if(project){
            let selectedPractices = [];
            let selectedOfferings = [];
            let selectedRegions = [];
            let currency = null;

            // Practice
            for(const obj of project.FactoryProjectBillDivision){
                if(obj.IsActive === true){
                    let billDivObj = {
                        Name: obj.BillingDivision.Name,
                        id: obj.BillingDivision.id
                    };
                    selectedPractices = selectedPractices.concat(billDivObj);
                }
            }

            // Currency
            for(const obj of this.state.currencies){
                if(obj.id === project.Currency){
                    currency = obj;
                }
            }

            // Project owner
            const projectOwner = {
                displayName: project.PresalesResource.Name,
                id: project.PresalesResource.id
            };

            // offering
            for(const obj of project.FactoryProjectOffering){
                let offeringObj = {
                    Name: obj.Offering.Name,
                    id: obj.Offering.id
                };
                selectedOfferings = selectedOfferings.concat(offeringObj);
            }

            // Status
            const statusObj = {
                StatusName: isEditOrView ? project.Status.StatusName : project.ProjectStatus.StatusName,
                id: isEditOrView ? project.Status.id : project.ProjectStatus.id
            };

            // Additional billing Regions
            for(const obj of project.FactoryProjectBillRegion){
                let billRegionObj = {
                    Name: obj.Region.Name,
                    id: obj.Region.id
                };
                selectedRegions = selectedRegions.concat(billRegionObj);
            }

            // Start date - set to current financial year april 1st
            let curFinYear;
            if(project.FinancialYear){
                curFinYear = project.FinancialYear;
            } else {
                curFinYear = new Date().getFullYear();
            }
            let startDate = new Date(curFinYear, 3, 1);

            this.setState({
                projectName: isEditOrView ? project.CRName : project.ProjectName,
                billingRegionValue: project.Region,
                practiceValue: selectedPractices,
                AdditionalbillingRegionValue: selectedRegions,
                CurrencyValue: currency,
                projectOwnerValue: projectOwner,
                offeringValue: selectedOfferings,
                TargetBillable : project.TargetBillable,
                startdate: startDate,
                financialYear : project.FinancialYear,
                statusValue: statusObj,
            });
        }
    };

    setFieldEditability = async () => {

        const fieldEditability = Object.assign({}, this.state.fieldEditability);

        if (this.state.action === 'create') {

            fieldEditability['projectName'] = true;
            fieldEditability['primaryBillingRegion'] = true;
            fieldEditability['additionalBillingRegion'] = true;
            fieldEditability['practice'] = true;
            fieldEditability['offering'] = true;
            fieldEditability['startDate'] = true;
            fieldEditability['status'] = false;
            fieldEditability['targetBillableUtilization'] = true; // Set true untill the CMS create
            fieldEditability['financialYear'] = true; // Set true untill the CMS create
            fieldEditability['currency'] = true;
            fieldEditability['projectOwner'] = true;

            this.setState({
                fieldEditability: fieldEditability,
                isSaveDisable: false
            });

        } else if (this.state.action === 'view') {

            fieldEditability['projectName'] = true;
            fieldEditability['primaryBillingRegion'] = true;
            fieldEditability['additionalBillingRegion'] = true;
            fieldEditability['practice'] = true;
            fieldEditability['offering'] = true;
            fieldEditability['startDate'] = true;
            fieldEditability['status'] = true;
            fieldEditability['targetBillableUtilization'] = true;
            fieldEditability['financialYear'] = true;
            fieldEditability['currency'] = true;
            fieldEditability['projectOwner'] = true;

            this.setState({
                fieldEditability: fieldEditability,
                isSaveDisable: true
            });

        } else if (this.state.action === 'edit') {

            fieldEditability['projectName'] = true;
            fieldEditability['primaryBillingRegion'] = true;
            fieldEditability['additionalBillingRegion'] = true;
            fieldEditability['practice'] = true;
            fieldEditability['offering'] = true;
            fieldEditability['startDate'] = true;
            fieldEditability['status'] = false;
            fieldEditability['targetBillableUtilization'] = false;
            fieldEditability['financialYear'] = false;
            fieldEditability['currency'] = true;
            fieldEditability['projectOwner'] = true;

            this.setState({
                fieldEditability: fieldEditability,
                isSaveDisable: false
            });

        }
        
    };

    populateDropDownData = async () => {
        try {
            const [regionsCountry, projectStatus, offerings, billingDiviIntegrated] = await Promise.all([getRegionsWithCountriesIntegrated(), getProjectStatuses(), getOfferings(), getBillingDiviIntegrated()]);
            this.setState({
                allBillingRegions: regionsCountry.data,
                additionalBillingregionItems: regionsCountry.data,
                statusItems: projectStatus.data,
                offeringItems: offerings.data,
                PracticeItems: billingDiviIntegrated.data
            });
        } catch (error) {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        }
    };

    handleStartdateChange = event => {
        let startDate = event.target.value;

        this.setState({
            startdate: startDate,
            isFormDirty: true
        });

        if (startDate) {
            this.setState({
                showStartDateValidationError: false
            });
        }
    };

    handleOnChangeFinancialYear = event => {

        let currentStartDate = null;
        let changedStartDate = null;

        if(this.state.startdate){
            currentStartDate = moment(this.state.startdate);
            currentStartDate.year(event.value);
            changedStartDate = moment(currentStartDate).toDate();
        }

        this.setState({
            financialYear: event.value,
            startdate: changedStartDate === null ? this.state.startdate : changedStartDate,
            isFormDirty: true
        });
    };

    handleOnChange = (event) => {
        const value = event.target.value;
        const field = event.target.name;

        switch (field) {
            case 'TargetBillableUti': {
                this.setState({
                    TargetBillable: value,
                });
                break;
            }
            default: {
            }
        }
        this.setState({
            isFormDirty: true
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        if (this.state.action === 'view') {
            return;
        }

        if (!this.validation()) {
            this.setState({
                isErrorMessageVisible: true
            });
            this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
        } else {
            this.setState({
                isErrorMessageVisible: false
            });
            if (this.state.action === 'edit') {
                this.setState(
                    {
                        isSaveDisable: true
                    },
                    () => {
                        this.updateProject(event);
                    }
                );
            } else { //create
                this.createProject(event);
            }
        }
    };

    onClickCancel = event => {
        event.preventDefault();
        if (this.state.isFormDirty) {
            this.toggleConfirmDialog(
                'There are unsaved data in the form, do you want to redirect to home page without saving them?',
                this.navigateBack
            );
        } else {
            this.navigateBack();
        }
    };

    navigateBack = () => {
        this.navigateToSearchScreen();
    };

    toggleConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    createProject = event => {

        const ChangeRequest = {
            ProjectId: this.props.location.projectId,
            CRName: this.state.projectName.trim(), // Set the project name to the CR
            UniqueId: null,
            StartDate: this.state.startdate? this.state.startdate.toDateString() : null,
            EndDate: null,
            EstimatedStartDate: null,
            EstimatedEndDate: null,
            ChangeRequestConversionStartDate: null,
            StatusId: this.state.statusValue.id,
            Status: this.state.statusValue.id,
            CRDescription: null,
            BillableStatus: null,
            BillingRegion: this.state.billingRegionValue.id,
            OPID: null,
            CreatedUser: null,
            UpdatedUser: null,
            PresalesResourceId: this.state.projectOwnerValue.id,
            IsActive: true,
            ConvertedToActiveAt: null,
            IsPoolProject: true,
            TargetBillable: this.state.TargetBillable,
            Currency: this.state.CurrencyValue.id,
            FinancialYear: this.state.financialYear,
            BillingRegions: this.state.AdditionalbillingRegionValue,
            Offerings: this.state.offeringValue,
            Practices: this.state.practiceValue
        };

        saveChangeReq(ChangeRequest)
            .then( async res => {
                this.toggleDialog(`Factory Project is successfully created`, 'Success');
                this.setState({
                    isSaveDisable: false
                });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleDialog(error.response.data, 'Error');
                this.setState({
                    isSaveDisable: false
                });
            });
    };

    updateProject = event => {

        var changeRequestObj = Object. assign({}, this.state.changeRequest);
        changeRequestObj.StatusId = this.state.statusValue.id;
        changeRequestObj.TargetBillable = this.state.TargetBillable;
        changeRequestObj.FinancialYear = this.state.financialYear;

        updateChangeReq(changeRequestObj, changeRequestObj.id)
            .then( async res => {
                this.toggleDialog(`Factory Project is successfully updated`, 'Success');
                this.setState({
                    isSaveDisable: false
                });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.toggleDialog(error.response.data, 'Error');
                this.setState({
                    isSaveDisable: false
                });
            });
    };

    toggleDialog = (message, title) => {
        this.setState({
            visible: !this.state.visible,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    validation = () => {

        if (
            this.validateProperty(this.state.TargetBillable)
                .toString()
                .includes('error')
        ) {
            return false;
        } else if (
            this.validateProperty(this.state.statusValue)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.financialYear)
                .toString()
                .includes('error')
        ) {
            return false;
        } else {
            return true;
        }
    };

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    handleStatusChange = event => {
        // 1 - Presales - PRESALES
        // 2 - Waiting Confirmation - WAIT_CONFIRM
        // 3 - Closed-Opportunity Loss - CLOSE_OP_LOST
        // 4 - On Hold - ON_HOLD
        // 5 - Working At Risk - WORK_AT_RISK
        // 6 - Billable - BILLABLE
        // 7 - Billable with CR - BILLABALE_CR
        // 8 - Closed - CLOSED
        // 9 - Close without bidding - CLOSE_WITHOUT_BID
        if (event.target.value) {
            if (event.target.value.Code === 'BILLABLE' || event.target.value.Code === 'WORK_AT_RISK') {
                //this.setState({ startDateMin: new Date(), showStartDateValidationError: false });
            }
            else {
                //this.setState({ startDateMin: new Date('1970-01-01Z00:00:00:000'), showStartDateValidationError: false });
            }

            if (this.state.action === 'edit' || this.state.action === 'create') {
                
                let currentProjectStatusCode;
                let cmSheets;

                if(this.state.action === 'edit'){
                    currentProjectStatusCode = this.state.changeRequest.Status.Code;
                    cmSheets = this.state.changeRequest.CMSheets;

                } else if (this.state.action === 'create') {
                    currentProjectStatusCode = this.state.project.ProjectStatus.Code;
                    cmSheets = this.state.project.CMSheets;
                }
                
                let hasApprovedCMS = false;
                for (const cmSheet of cmSheets) {
                    if (cmSheet.Status === 'APPROVED') {
                        hasApprovedCMS = true;
                        break;
                    }
                }

                const selectedStatusCode = event.target.value.Code;
                const selectedStatusName = event.target.value.StatusName;

                if (
                    selectedStatusCode === 'PRESALES' &&
                    !(
                        currentProjectStatusCode === 'PRESALES' ||
                        currentProjectStatusCode === 'WAIT_CONFIRM' ||
                        currentProjectStatusCode === 'ON_HOLD'
                    )
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // When there is an approved CMS, status can be changed to Waiting Confirmation or Closed-Opportunity Loss
                if (
                    (selectedStatusCode === 'WAIT_CONFIRM' || selectedStatusCode === 'CLOSE_OP_LOST') &&
                    !hasApprovedCMS
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // status can be changed to Close without bidding before converting to a Billable or Working at Risk status
                if (
                    selectedStatusCode === 'CLOSE_WITHOUT_BID' &&
                    (currentProjectStatusCode === 'WORK_AT_RISK' ||
                        currentProjectStatusCode === 'BILLABLE' ||
                        currentProjectStatusCode === 'BILLABALE_CR')
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Any project in Presales, Waiting Confirmation, On Hold with an Approved CMS can be changed to Working At Risk
                if (
                    selectedStatusCode === 'WORK_AT_RISK' &&
                    (!(
                            currentProjectStatusCode === 'PRESALES' ||
                            currentProjectStatusCode === 'WAIT_CONFIRM' ||
                            currentProjectStatusCode === 'ON_HOLD' ||
                            currentProjectStatusCode === 'WORK_AT_RISK'
                        ) ||
                        !hasApprovedCMS)
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Any project in Presales, Waiting Confirmation, On Hold or Working at Risk with an Approved CMS can be changed to Billable
                if (
                    selectedStatusCode === 'BILLABLE' &&
                    (!(
                            currentProjectStatusCode === 'PRESALES' ||
                            currentProjectStatusCode === 'WAIT_CONFIRM' ||
                            currentProjectStatusCode === 'ON_HOLD' ||
                            currentProjectStatusCode === 'WORK_AT_RISK' ||
                            currentProjectStatusCode === 'BILLABLE'
                        ) ||
                        !hasApprovedCMS)
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Billable with CR is an internal status change, not a user change
                if (selectedStatusCode === 'BILLABALE_CR' && currentProjectStatusCode !== 'BILLABALE_CR') {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                // Any Billable, Working at Risk or On Hold project can be converted to Closed
                if (
                    selectedStatusCode === 'CLOSED' &&
                    !(
                        currentProjectStatusCode === 'WORK_AT_RISK' ||
                        currentProjectStatusCode === 'BILLABLE' ||
                        currentProjectStatusCode === 'ON_HOLD'
                    )
                ) {
                    this.toggleDialog(
                        'Project status cannot be changed to ' + selectedStatusName + ' at current state',
                        'Error'
                    );
                    return;
                }

                if ((selectedStatusCode === 'BILLABLE' || selectedStatusCode === 'WORK_AT_RISK')) {
                    if (this.state.startdate == null) {
                        this.setState(
                            {
                                showStartDateValidationError: true
                            });
                    }
                }

                // if (currentProjectStatusCode === 'ON_HOLD' && (selectedStatusCode === 'BILLABLE' || selectedStatusCode === 'WORK_AT_RISK')) {
                //     const permissions = Object.assign({}, this.state.permissions);
                //     permissions['description'] = true;
                //     this.setState({
                //         permissions: permissions
                //     });
                // }
            }
        }

        this.setState({
            statusValue: event.target.value,
            isFormDirty: true
        });
    };

    reDirectToDashboard = event => {
        this.setState({
            toDashboard: true
        });
    };

    navigateToSearchScreen = event => {
        this.setState({
            toSearchScreen: true
        });
    };

    render() {
        const classes = 'tooltip-inner';
        if (this.state.toDashboard === true) {
            return <Redirect to="/" />;
        }
        if (this.state.toSearchScreen === true) {
            return <Redirect to="/projects/search/search" />;
        }

        return (
            <div className="main-card">
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-heading">Annual Factory Pool Revision</div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="d-block">
                                    <label>Project Name:</label>
                                </div>
                                <div className="d-block">
                                    <Input
                                        value={this.state.projectName} 
                                        disabled={this.state.fieldEditability.projectName}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Primary Billing Region:</label>
                                </div>
                                <div className="d-block">
                                    <Input
                                        value={this.state.billingRegionValue ? this.state.billingRegionValue.Name : ''} 
                                        disabled={this.state.fieldEditability.primaryBillingRegion}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Practice/Department:</label>
                                </div>
                                <div className="d-block">
                                    <MultiSelect
                                        data={this.state.PracticeItems}
                                        textField="Name"
                                        dataItemKey="id"
                                        placeholder="Please Select"
                                        value={this.state.practiceValue}
                                        name="practice"
                                        //onChange={this.handleOnPracticeChange}
                                        disabled={this.state.fieldEditability.practice}
                                        filterable={true}
                                        //onFilterChange={this.handleBillableFilterChange}
                                        //required={true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label>Start Date:</label>
                                </div>
                                <div className="d-block">
                                    <DatePicker
                                        width="100"
                                        name="startdate"
                                        onChange={this.handleStartdateChange}
                                        value={this.state.startdate}
                                        min={new Date()}
                                        disabled={this.state.fieldEditability.startDate}
                                        format="MM/dd/yyyy"
                                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                        //required= {true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Target Billable Utilization:</label>
                                </div>
                                <div className="d-block">
                                    <NumericTextBox
                                        autoComplete="off"
                                        type="Numeric"
                                        name="TargetBillableUti"
                                        required={true}
                                        min={0}
                                        max={100}
                                        width={'100%'}
                                        value={this.state.TargetBillable}
                                        onChange={this.handleOnChange}
                                        textField="name"
                                        dataItemKey="value"
                                        disabled={this.state.fieldEditability.targetBillableUtilization}
                                    />
                                    {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.TargetBillable)}>
                    Please enter a target billable Utilization
                  </span>
                                        ) : null}
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label>Currency:</label>
                                </div>
                                <div className="d-block">
                                    <Input
                                        value={(this.state.CurrencyValue) ? this.state.CurrencyValue.Code : ''} 
                                        disabled={this.state.fieldEditability.currency}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label>Project Owner:</label>
                                </div>
                                <div className="d-block">
                                    <Input
                                        value={this.state.projectOwnerValue ? this.state.projectOwnerValue.displayName : ''} 
                                        disabled={this.state.fieldEditability.projectOwner}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label>Additional Billing Region:</label>
                                </div>
                                <div className="d-block">
                                    <MultiSelect
                                        data={this.state.additionalBillingregionItems}
                                        textField="Name"
                                        dataItemKey="id"
                                        placeholder="Please Select"
                                        value={this.state.AdditionalbillingRegionValue}
                                        name="additionalBillingRegion"
                                        //onChange={this.handleOnChange}
                                        disabled={this.state.fieldEditability.additionalBillingRegion}
                                        filterable={true}
                                        //onFilterChange={this.handleBillableFilterChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-3">
                                <div className="d-block">
                                    <label>Offering:</label>
                                </div>
                                <div className="d-block">
                                    <MultiSelect
                                        data={this.state.offeringItems}
                                        textField="Name"
                                        dataItemKey="id"
                                        placeholder="Please Select"
                                        value={this.state.offeringValue}
                                        name="offerings"
                                        //onChange={this.handleOnChange}
                                        filterable={true}
                                        //onFilterChange={this.handleBillableFilterChange}
                                        disabled={this.state.fieldEditability.offering}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Status:</label>
                                </div>
                                <div className="d-block">
                                    <ComboBox
                                        data={this.state.statusItems}
                                        textField="StatusName"
                                        dataItemKey="id"
                                        onChange={this.handleStatusChange}
                                        value={this.state.statusValue}
                                        required={true}
                                        disabled={this.state.fieldEditability.status}
                                    />
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-block">
                                    <label className="mandatory">Financial Year:</label>
                                </div>
                                <div className="d-block">
                                    <div className="title-remove">
                                        <MultiIncrementYearPicker
                                            value={this.state.financialYear}
                                            isFactryProjectExtension={true}
                                            name="financialYear"
                                            onChange={this.handleOnChangeFinancialYear}
                                            autoComplete="off"
                                            required={true}
                                            disabled={this.state.fieldEditability.financialYear}
                                        />
                                        {this.state.isErrorMessageVisible === true ? (
                                            <span className={this.validateProperty(this.state.financialYear)}>
                    Please enter a financial year
                  </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
            <div className="col-md-12 btn-align-right">

            {(this.props.location.action === 'create' || this.props.location.action === 'view' || this.props.location.action === 'edit') && (
              <Button primary={true} 
                    type="submit"
                    disabled={this.state.isSaveDisable}
                    onClick={this.handleSubmit}
                >
                Save
              </Button>
            )}

              <Button type="button"  onClick={this.onClickCancel}>
                Cancel
              </Button>
                </div>
            </div>

                {this.state.visible === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.state.dialogTitle === 'Error' ? this.toggleDialog : this.reDirectToDashboard} width="300px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                        <DialogActionsBar>
                            <button
                                className="k-button modal-primary"
                                onClick={
                                    this.state.dialogTitle === 'Error' ? this.toggleDialog : this.reDirectToDashboard
                                }
                            >
                                OK
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}


                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ 'white-space': 'pre-wrap' }}>{this.state.confirmDialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleConfirmDialog}>
                                No
                            </button>
                            <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

            </div>
        );
    }
}


export default poolRevision;
