import http from '../../integration/HttpService';
const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

/**
 * Get all Payment Methods when user search projects.
 * @returns {array} Information of all payment methods
 */
export function getPaymentMethods() {
  const endPoint = apiUrl + '/masterData/common/payment-methods';
  return http.get(endPoint);
}

/**
 * Get all Payment Methods when user search projects.
 * @returns {array} Information of all payment methods
 */
export function getPaymentMethodById(paymentMethodId) {
  const endPoint = apiUrl + '/masterData/common/payment-methods/'+paymentMethodId;
  return http.get(endPoint);
}

/**
 * Get all Expense Types.
 * @returns {array} array of all Expense Types
 */
export function getExpenseTypes() {
  const endPoint = apiUrl + '/masterData/common/expense-types';
  return http.get(endPoint);
}

/**
 * Get all Project Types for drop downs.
 * @returns {array} array list of all Project Types
 */
export function getProjectTypes() {
  const endPoint = apiUrl + '/masterData/engagementType/projectTypes/getall';
  return http.get(endPoint);
}

// export function getPrograms() {
//   const endPoint = apiUrl + '/master/programs/getall';
//   return http.get(endPoint);
// }

/**
 * Get Currencies.
 * @returns {array} array list of Currency details
 */
export function getCurrencies() {
  const endPoint = apiUrl + '/masterData/exchangeRate/currencies';
  return http.get(endPoint);
}

/**
 * Get Budgeted Rate by given params.
 * @returns {array} array list of Budgeted Rate
 * @param expenseTypeId
 * @param countryId
 * @param regionId
 */
export function getBudgetedRate(expenseTypeId, countryId, regionId) {
  const endPoint =
    apiUrl +
    '/master/budgeted-rate/expense-type/' +
    expenseTypeId +
    '/country/' +
    countryId +
    '/region/' +
    regionId;
  return http.get(endPoint);
}


