import React, {Component} from 'react';
import {ComboBox} from '@progress/kendo-react-dropdowns';
import debounce from 'lodash.debounce';

class AsyncSelect extends Component{

    constructor(props){
        super(props);
        this.filterChange = debounce(this.filterChange, this.props.delay);
        this.state = {
            loading: false,
            skip: 0,
            total: 0,
        }
    }
    
    filterChange = (event, isOnOpen) => {
        if(isOnOpen && this.props.isFetchOnOpen){
            return this.props.filter(this.props.onOpenValue);
        }
        if(event.filter){
            this.props.filter(event.filter.value);
        }
    };

    filterController = (e, isOnOpen = false) => {
        // this for avoid loading icon delay
        this.setState({loading: isOnOpen ? isOnOpen && this.props.isFetchOnOpen : true}, () => {
            this.filterChange(e, isOnOpen);
        });
    }

    componentDidUpdate(prevProps){
        if(prevProps.loading !== this.props.loading){
            this.setState({loading: this.props.loading});
        }
    }
    
    render() {
        return (
            <ComboBox
                data={this.props.data}
                value={this.props.value}
                placeholder={this.props.placeholder || 'Please Select'}
                textField={this.props.textField}
                dataItemKey={this.props.dataItemKey}
                filterable={this.props.filterable || true}
                onFilterChange={this.filterController}
                onOpen={e => this.filterController(e, true)}
                onChange={this.props.onChange}
                loading={this.state.loading}
                disabled={this.props.disabled || false}
            />
        );
    }
}

export default AsyncSelect;