/* eslint-disable no-lone-blocks */
import React, { Component, useState, useEffect, useMemo } from 'react';
import { GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab, PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-react-dialogs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { Popup } from '@progress/kendo-react-popup';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import SuccessImg from '../../ui/static/images/checked.svg';
import approvedStatus from '../enums/ApprovedStatusTypes';
import ErrorImg from '../../ui/static/images/cancel.svg';
import Loader from '../../integration/components/Loader';
import { Tooltip } from 'reactstrap';
import {
  DateInput,
  Calendar,
  DatePicker,
  TimePicker,
  MultiViewCalendar,
  DateRangePicker
} from '@progress/kendo-react-dateinputs';
import {
  getTask,
  getTimeCards,
  saveTimeEntry,
  UpdateTimeEntry,
  getDataForProjectFilter,
  getDataForTaskFilter,
  saveSearchCriteria,
  getSearchCriteria,
  deleteTimeEntry,
  deleteTimeEntryDaily,
  getCalculatedTotals,
  getDailyTimeEntries,
  getAllPendingTimes,
  getFreezeDate,
  getTimeEntryFreezeDaysByUser,
  setWorkflow,
  getTimeEntryFreezeDateFromTimesheetFreezeDate,
  getLatestTimeEntryByDate
} from './TimeSheetEntryService';
import { selectTimeSheetUnfreezeRequestWorkflow } from '../../projectManagement/workflow/WorkflowService';
import * as loggerService from '../../integration/LoggerService';
import { LOG_TYPES } from '../../integration/IntegrationEnums';
import moment from 'moment';
import { getLoggedInUserStatus } from '../../userManagement/user/userService';
import { Route } from 'react-router';
import NotFoundImage from '../../ui/static/images/404-page-04.png';
import UnFreezeStatus from '../enums/UnFreezeStatus';
import WorkLocationStatus from '../enums/WorkLocationStatus';

class ActionsCell extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <td className="action-buttons">
        <a
          type="button"
          onClick={() => {
            this.props.editTimeEntry(this.props.dataIndex);
          }}
        >
          <div className="rounded-link" title="Edit">
            <span className="k-icon k-i-edit" title="Edit" />
          </div>
        </a>
        <a
          type="button"
          onClick={() => {
            this.props.removeTimeEntry(this.props.dataIndex);
          }}
        >
          <div className="rounded-link">
            <span className="k-icon k-i-delete" title="Delete" />
          </div>
        </a>
      </td>
    );
  }
}

const Card = props => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const colorIndicator = props => {
    if (props.approvedStatus === approvedStatus.Pending_Approval.value) {
      return 'timesheet-none row';
    } else if (props.approvedStatus === approvedStatus.Approved.value) {
      return 'timesheet-green row';
    } else if (props.approvedStatus === approvedStatus.Rejected.value) {
      return 'timesheet-gray row';
    } else if (props.approvedStatus === approvedStatus.Auto_Generated.value) {
      return 'timesheet-light-blue row';
    } else if (props.approvedStatus === approvedStatus.Leave.value) {
      return 'timesheet-light-blue row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_General.value
    ) {
      return 'timesheet-blue row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_ESE.value
    ) {
      return 'timesheet-yellow row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_PMM.value
    ) {
      return 'timesheet-purple row';
    } else if (
      props.approvedStatus === approvedStatus.Approved_NB.value &&
      props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_RWK.value
    ) {
      return 'timesheet-orange row';
    }
  };
  return (
    <div className="card my-2" id={'tooltip-' + props.cardData.cardId}>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={'tooltip-' + props.cardData.cardId}
        toggle={toggle}
        className="timecard-tooltip"
      >
        <div className="text-left">
          <div>
            {`Duration : ${props.cardData.duration}`}{' '}
            {props.data.isShiftTask && props.cardData.FromDateTime !== null
              ? `(${props.cardData.FromDateTime} - ${props.cardData.ToDateTime})`
              : null}
          </div>
          <div>{`Comment : ${props.cardData.comment}`}</div>
          <div>{`Ticket ID : ${props.cardData.TicketId ? props.cardData.TicketId : ''}`}</div>
          <div>{`Work Location : ${props.cardData.WorkLocationName ? props.cardData.WorkLocationName : ''}`}</div>
          {props.cardData.approvedStatus === 1 && <div>Status : Approval Pending</div>}
          {props.cardData.approvedStatus === 2 && <div> Status : Approved – Billable</div>}
          {props.cardData.approvedStatus === 3 && <div> Status : Rejected</div>}
          {props.cardData.approvedStatus === 4 && <div>Status : Automatic Approvals</div>}
          {props.cardData.approvedStatus === 10 && !props.cardData.IsPending && <div>Status : Leave</div>}
          {props.cardData.approvedStatus === 10 && props.cardData.IsPending && <div>Status : Leave - Pending Approval</div>}
          {props.cardData.approvedStatus === 2 || props.cardData.approvedStatus === 5 ? (
            <div>Approved By : {props.cardData.ApprovedBy}</div>
          ) : null}
          {props.cardData.approvedStatus === approvedStatus.Rejected.value ? (
            <div>Rejected By : {props.cardData.ApprovedBy}</div>
          ) : null}
          {props.cardData.approvedStatus === 3 && (
            <div>Reject Reason : {props.cardData.RejectReason}</div>
          )}
          {props.cardData.ResonOfMove !== null && (
            <div>Reason to Move : {props.cardData.ResonOfMove}</div>
          )}
          {props.approvedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId ===
            approvedStatus.Approved_NB_General.value ? (
            <div> Status : Approved Non-Billable - General</div>
          ) : null}
          {props.approvedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_ESE.value ? (
            <div> Status : Approved Non-Billable - Estimation Issues</div>
          ) : null}
          {props.approvedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_PMM.value ? (
            <div> Status : Approved Non-Billable - Mismanagement</div>
          ) : null}
          {props.approvedStatus === approvedStatus.Approved_NB.value &&
          props.cardData.ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_RWK.value ? (
            <div> Status : Approved Non-Billable - Rework</div>
          ) : null}
        </div>
      </Tooltip>
      <div className="col-md-12">
        <div className={colorIndicator(props)}>
          <input
            type="checkbox"
            id="ts-activity"
            className="k-checkbox mr-2"
            name="tsact"
            checked={props.checked}
            onClick={() => {
              props.checkeBoxHandler(props.index, props.taskId, props.date, props.data);
            }}
          />
          <span
            htmlFor="ts-activity"
            className="ts-act d-inline-block"
            onClick={() => props.toggleWindowDialog()}
          >
            <span className=" d-inline-block my-auto">{props.cardData.duration}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

const getDurationInHMS = time => {
  const HM = time.split('.');
  return (
    HM[0] +
    ':' +
    Math.round((Number(HM[1]) * 60) / 100).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })
  );
};

const getApprovedStatus = (statusId, ApprovedNonBillingCatagoryId) => {
  switch (statusId) {
    case approvedStatus.Pending_Approval.value:
      return (
        <div>
          <span className="timesheet-grid-status timesheet-grid-status-none">&nbsp;</span>
          &nbsp;Approval Pending
        </div>
      );
    case approvedStatus.Approved.value:
      return (
        <div>
          <span className="timesheet-grid-status timesheet-grid-status-green">&nbsp;</span>
          &nbsp;Approved – Billable
        </div>
      );
    case approvedStatus.Rejected.value:
      return (
        <div>
          <span className="timesheet-grid-status timesheet-grid-status-gray">&nbsp;</span>
          &nbsp;Rejected
        </div>
      );
    case approvedStatus.Auto_Generated.value:
      return (
        <div>
          <span className="timesheet-grid-status timesheet-grid-status-light-blue">&nbsp;</span>
          &nbsp;Automatic Approvals
        </div>
      );
    case approvedStatus.Leave.value:
      return (
        <div>
          <span className="timesheet-grid-status timesheet-grid-status-light-blue">&nbsp;</span>
          &nbsp;Leave
        </div>
      );
    case approvedStatus.Approved_NB.value:
      if (ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_General.value) {
        return (
          <div>
            <span className="timesheet-grid-status timesheet-grid-status-blue">&nbsp;</span>
            &nbsp;Approved Non-Billable - General
          </div>
        );
      } else if (ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_ESE.value) {
        return (
          <div>
            <span className="timesheet-grid-status timesheet-grid-status-yellow">&nbsp;</span>
            &nbsp;Approved Non-Billable - Estimation Issues
          </div>
        );
      } else if (ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_PMM.value) {
        return (
          <div>
            <span className="timesheet-grid-status timesheet-grid-status-purple">&nbsp;</span>
            &nbsp;Approved Non-Billable - Mismanagement
          </div>
        );
      } else if (ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_RWK.value) {
        return (
          <div>
            <span className="timesheet-grid-status timesheet-grid-status-orange">&nbsp;</span>
            &nbsp;Approved Non-Billable - Rework
          </div>
        );
      } else {
        return (
          <div>
            <span className="timesheet-grid-status timesheet-grid-status-blue">&nbsp;</span>
            &nbsp;Approved Non-Billable
          </div>
        );
      }
    default:
      return null;
  }
};

const getStatusCell = props => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <td>
      <div id={'tooltip-' + props.id}>
        {(props.approvedStatus === 2 ||
          props.approvedStatus === 5 ||
          props.ResonOfMove ||
          props.approvedStatus === 3) && (
          <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target={'tooltip-' + props.id}
            toggle={toggle}
            className="timecard-tooltip"
          >
            <div className="text-left">
              {props.approvedStatus === 2 || props.approvedStatus === 5 ? (
                <div>Approved By : {props.ApprovedBy}</div>
              ) : null}
              {props.approvedStatus === 3 && <div>Reject Reason : {props.RejectReason}</div>}
              {props.ResonOfMove !== null && <div>Reason to Move : {props.ResonOfMove}</div>}
            </div>
          </Tooltip>
        )}
        <div>{getApprovedStatus(props.approvedStatus, props.approvedNonBillingCatagoryId)}</div>
      </div>
    </td>
  );
};

const Page404 = ({ location }) => (
  <div className="container-image-error">
    <div className="error-image mx-auto">
      <a href="/">
        <img className="err-img" src={NotFoundImage} alt="Error Image" />{' '}
      </a>
      <div className="error-message">
        <div>Aha! You see! You can be wrong!</div>
        <div>OR</div>
        <div>You do not have permission to access this page. </div>
        <div>Please contact the admin to request access</div>
        <div>
          <a href="/">Go Back To Home</a>
        </div>
      </div>
    </div>
  </div>
);

class TimeSheetEntry extends Component {
  isMount = false;
  lastSelectedIndex = 0;
  workLocationStatuses = Object.values(WorkLocationStatus);

  constructor(props) {
    super(props);

    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.state = {
      isHovering: false
    };

    this.state = {
      data: [],
      allData: [],
      currentTaskId: 0,
      currentDay: null,
      selected: 0,
      nonbilldialog: false,
      unfreezeTa: false,
      rejectTa: false,
      addTimeentry: false,
      status: 0,
      windowDialogVisible: false,
      timesheet: [<td />],
      totalHrsCalcu: [],
      timeentrydailygrid: [],
      timeentryallpending: [<td />],
      timeEntryAdd: [],
      allSelect: false,
      isAllSelectedMon: false,
      isAllSelectedTue: false,
      isAllSelectedWed: false,
      isAllSelectedTur: false,
      isAllSelectedFri: false,
      isAllSelectedSat: false,
      isAllSelectedSun: false,
      total: 0,
      skip: 0,
      pageSize: 5,
      timeRow: [],
      dateOfSun: null,
      dateOfMon: null,
      dateOfTue: null,
      dateOfWed: null,
      dateOfTur: null,
      dateOfFri: null,
      dateOfSat: null,
      monDayTotal: 0,
      tueDayTotal: 0,
      wedDayTotal: 0,
      thrDayTotal: 0,
      friDayTotal: 0,
      satDayTotal: 0,
      sunDayTotal: 0,
      firstDayOfWeek: null,
      LastDayOfWeek: null,
      currentProject: null,
      currentTask: null,
      currentDate: null,
      SelectedCurrentDate: null,
      newDate: null,
      timeEntryCards: [],
      currentProjectId: null,
      isActiveEdit: false,
      comment: '',
      duration: '',
      startTime: '',
      endTime: '',
      approvedStatusType: [
        { Name: 'Pending Approval', value: 1 },
        { Name: 'Approved', value: 2 },
        { Name: 'Rejected', value: 3 }
      ],
      timeEntryEditMode: false,
      timeEntryAddMode: false,
      timeEntryGlobleAddMode: false,
      projectDropdownFilter: [],
      currentNonProjectTaskAllocationId: null,
      currentProjectTaskAllocationId: null,
      currTaskType: null,
      selectedProjectOrCr: null,
      withTimeEntry: false,
      currentProjectForDropDown: null,
      taskDropdownFilter: null,
      currentTaskInDropDown: null,
      deletePopupVisible: false,
      successPopUpvisible: false,
      showSuccessImage: false,
      isShiftTask: false,
      isTaskExist: false,
      refreshState: false,
      durationError: 'd-none',
      isActiveDelete: false,
      totalHourOfWeek: 0,
      timeEntryData: [],
      isDirty: false,
      isTimeEntryFildEmpt: false,
      isDateErrorMessageVisible: false,
      userStatus: true,
      gridLoader: false,
      loader: false,
      newDailyDate: new Date(),
      popUpBox: { isPopUp: false, popUpMessage: '', popUpTitile: '', callBack: null },
      addTimeentrydaily: false,
      totalHourOfDay: 0,
      allPendingFromDate: new Date(),
      allPendingToDate: new Date(),
      allPendingSkip: 0,
      allPendingMinDate: new Date(),
      allPendingMaxDate: new Date(),
      timeEntryFreezeDates: [],
      isFreeze: false,
      isAddTimeFreeze: false,
      isFreezeDateOfSun: false,
      isFreezeDateOfMon: false,
      isFreezeDateOfTue: false,
      isFreezeDateOfWed: false,
      isFreezeDateOfThu: false,
      isFreezeDateOfFri: false,
      isFreezeDateOfSat: false,
      isUnfreezeButtonDisable: true,
      isUnfreezeButtonDisableDaily: true,
      isFreezedDaily: false,
      unfreezePopupVisible: false,
      unfreezeReason: '',
      matchingWorkflowList: [],
      showWorkflowSelectDialog: false,
      selectedWorkFlowId: 0,
      dialogMessage: '',
      selectedDates: [],
      isErrorMessageVisibleReason: false,
      timeEntryFreezeDate: null,
      taskFilter: [],
      taskFilterAll: [],
      selectedTask: null,
      sortDaily: [{ field: 'id', dir: 'desc' }],
      sortAllPending: [{ field: 'id', dir: 'desc' }],
      disablePrvBtn: true,
      disableDailyPrvBtn: true,
      sortWeekly: [{ field: 'id', dir: 'desc' }],
      selectAll: false,
      havingtwoMonthBackDates: false,
      unfreezeMessage: '',
      ticketId: null,
      isTicketIdErrorMessageVisible: false,
      workLocation: null,
      isLocationErrorMessageVisible: false,
    };

    this.toggleWindowDialog = this.toggleWindowDialog.bind(this);
    this.toggleRejectDialog = this.toggleRejectDialog.bind(this);
    this.toggleNonbillDialog = this.toggleNonbillDialog.bind(this);
    this.toggleUnfreezeDialog = this.toggleUnfreezeDialog.bind(this);
    this.toggleAddTimeentry = this.toggleAddTimeentry.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  getDataForTaskFilter = (id, type, taskVal) => {
    let parms = {
      id: id,
      type: type
    };
    if (taskVal === null) {
      getDataForTaskFilter(parms).then(res => {
        this.setState({
          taskFilter: res.data,
          taskFilterAll: res.data
        });
      });
    } else {
      getDataForTaskFilter(parms).then(res => {
        this.setState(
          {
            taskFilter: res.data,
            taskFilterAll: res.data
          },
          () => {
            this.setState({
              selectedTask: this.state.taskFilter.filter(x => x.taskName === taskVal)[0]
            });
          }
        );
      });
    }
  };

  taskFilterHandler = e => {
    this.setState({
      selectedTask: e.target.value
    });
  };

  setCurrentWeekPendingView = () => {
    let curr = new Date();
    let first = curr.getDate() - curr.getDay();
    //let last = first + 6;

    let allPendingFromDate = this.state.timeEntryFreezeDate
      ? new Date(this.state.timeEntryFreezeDate)
      : new Date(curr.setDate(first));
    let allPendingToDate = new Date(Date.now());

    let minDate = new Date();
    minDate = new Date(minDate.setFullYear(minDate.getFullYear() - 1));

    this.setState({
      allPendingMinDate: minDate,
      allPendingMaxDate: new Date(),
      allPendingFromDate: allPendingFromDate,
      allPendingToDate: allPendingToDate
    });
  };

  addTimeEntry = () => {
    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true
      });
    } else if (
      this.validateGlobleAddDatePicker(
        this.state.currentTaskInDropDown.disableDateList,
        this.state.SelectedCurrentDate
      )
        .toString()
        .includes('error')
    ) {
      this.setState({
        isDateErrorMessageVisible: true
      });
    } else if (!this.validateTicketId()) {
      this.setState({
        isTicketIdErrorMessageVisible: true
      });
    } else if (!this.validateWorkLocation()) {
      this.setState({
        isLocationErrorMessageVisible: true
      });
    } else {
      const timeEntry = {
        comment: this.state.comment,
        duration: this.state.duration,
        startTime:
          this.state.startTime !== null
            ? moment(this.state.startTime).format('LT')
            : this.state.startTime,
        endTime:
          this.state.endTime !== null ? moment(this.state.endTime).format('LT') : this.state.endTime,
        TicketId: this.state.ticketId,
        WorkLocationId: this.state.workLocation ? this.state.workLocation.value : null,
        WorkLocationName: this.state.workLocation ? this.state.workLocation.name : null
      };
      this.setState({
        timeEntryData: [...this.state.timeEntryData, timeEntry],
        comment: '',
        duration: '',
        startTime: null,
        endTime: null,
        isErrorMessageVisible: false,
        isTicketIdErrorMessageVisible: false,
        ticketId: '',
        isLocationErrorMessageVisible: false,
        workLocation: this.state.workLocation ? this.state.workLocation : null
      });
    }
  };

  removeTimeEntryFromGrid = index => {
    let timeEntries = this.state.timeEntryData;
    timeEntries.splice(index, 1);
    this.setState({
      timeEntryData: timeEntries
    });
  };

  editTimeEntryInGrid = index => {
    let sampleDate = '2018-12-24';
    this.setState(
      {
        comment: this.state.timeEntryData[index].comment,
        ticketId: this.state.timeEntryData[index].TicketId,
        workLocation: this.state.timeEntryData[index].WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == this.state.timeEntryData[index].WorkLocationId)[0] : null,
        duration: this.state.timeEntryData[index].duration,
        startTime:
          this.state.timeEntryData[index].startTime !== null
            ? new Date(
                moment(
                  `${sampleDate} ${this.state.timeEntryData[index].startTime}`,
                  'YYYY-MM-DD HH:mm:ss'
                ).format()
              )
            : this.state.timeEntryData[index].startTime,
        endTime: this.state.timeEntryData[index].endTime
          ? new Date(
              moment(
                `${sampleDate} ${this.state.timeEntryData[index].endTime}`,
                'YYYY-MM-DD HH:mm:ss'
              ).format()
            )
          : this.state.timeEntryData[index].endTime
      },
      () => {
        let timeEntries = this.state.timeEntryData;
        timeEntries.splice(index, 1);
        this.setState({
          timeEntryData: timeEntries
        });
      }
    );
  };

  saveSearchCriteria = () => {
    const search = {
      radioView: this.state.status,
      withTimeEntry: this.state.withTimeEntry,
      project:
        this.state.status == 3 ? null : this.state.selectedProjectOrCr === null ? null : this.state.selectedProjectOrCr.DisplayText,
      task: this.state.status == 3 ? null : this.state.selectedTask === null ? null : this.state.selectedTask.taskName
    };
    saveSearchCriteria(search).then(res => {
      this.toggleDialog('Default View Changed', 'Success');
    });
  };

  getSearchCriteria = () => {
    getSearchCriteria().then(res => {
      if (res.data.length !== 0) {
        const radioViewValue = res.data.filter(x => x.searchCriteria === 'radioView')[0]
          .searchValue;
        const projectValue = res.data.filter(x => x.searchCriteria === 'project')[0].searchValue;
        let task = null;
        typeof res.data.filter(x => x.searchCriteria === 'task')[0] !== 'undefined'
          ? (task = res.data.filter(x => x.searchCriteria === 'task')[0].searchValue)
          : (task = null);
        if (radioViewValue === '2' && projectValue === null) {
          this.radioViewHandler(2);
          this.getTaskList(
            null,
            null,
            null,
            null,
            false,
            this.state.firstDayOfWeek,
            this.state.LastDayOfWeek
          );
        } else if (radioViewValue === '2' && projectValue !== null) {
          this.radioViewHandler(2);
          if (this.state.projectDropdownFilter.length !== 0) {
            var selectedProj = this.state.projectDropdownFilter.filter(
              x => x.DisplayText === projectValue
            )[0];

            if (selectedProj.Category === 'PT') {
              this.getDataForTaskFilter(selectedProj.id, 'Project', task);
            } else if (selectedProj.Category === 'CR') {
              this.getDataForTaskFilter(selectedProj.id, 'ChangeRequest', task);
            } else {
              this.getDataForTaskFilter(selectedProj.NPTId, 'NPT', task);
            }
          }
          selectedProj
            ? this.setState({
                selectedProjectOrCr: selectedProj
              })
            : this.setState({
                selectedProjectOrCr: null
              });
          selectedProj
            ? this.getTaskList(
                selectedProj.projId,
                selectedProj.CRId,
                selectedProj.NPTId,
                selectedProj.Category,
                false,
                this.state.firstDayOfWeek,
                this.state.LastDayOfWeek
              )
            : this.getTaskList(
                null,
                null,
                null,
                null,
                false,
                this.state.firstDayOfWeek,
                this.state.LastDayOfWeek
              );
        } else if (radioViewValue === '1' && projectValue == null) {
          this.radioViewHandler(1);
          this.getTaskList(
            null,
            null,
            null,
            null,
            false,
            this.state.firstDayOfWeek,
            this.state.LastDayOfWeek
          );
          this.setWeekDates(this.state.newDailyDate, false);
        } else if (radioViewValue === '1' && projectValue !== null) {
          this.radioViewHandler(1);
          if (this.state.projectDropdownFilter.length !== 0) {
            var selectedProj = this.state.projectDropdownFilter.filter(
              x => x.DisplayText === projectValue
            )[0];
          }
          if (selectedProj.Category === 'PT') {
            this.getDataForTaskFilter(selectedProj.id, 'Project', task);
          } else if (selectedProj.Category === 'CR') {
            this.getDataForTaskFilter(selectedProj.id, 'ChangeRequest', task);
          } else {
            this.getDataForTaskFilter(selectedProj.NPTId, 'NPT', task);
          }
          if (selectedProj) {
            this.setState({
              selectedProjectOrCr: selectedProj
            });
            this.getTaskList(
              selectedProj.projId,
              selectedProj.CRId,
              selectedProj.NPTId,
              selectedProj.Category,
              true,
              this.state.firstDayOfWeek,
              this.state.LastDayOfWeek
            );
            this.setWeekDates(this.state.newDailyDate, false);
          } else {
            this.setState({
              selectedProjectOrCr: null
            });
            this.getTaskList(
              null,
              null,
              null,
              null,
              true,
              this.state.firstDayOfWeek,
              this.state.LastDayOfWeek
            );
            this.setWeekDates(this.state.newDailyDate, false);
          }
        } else if (radioViewValue === '3' && projectValue == null) {
          this.getAllPendingEntries(
            this.state.allPendingFromDate,
            this.state.allPendingToDate,
            null,
            null,
            null
          );
          this.radioViewHandler(3);
        } else if (radioViewValue === '3' && projectValue !== null) {
          var selectedProj = this.state.projectDropdownFilter.filter(
            x => x.DisplayText === projectValue
          )[0];
          this.getAllPendingEntries(
            this.state.allPendingFromDate,
            this.state.allPendingToDate,
            selectedProj.projId,
            selectedProj.CRId,
            selectedProj.NPTId
          );
          this.radioViewHandler(3);
        }
      } else {
        this.radioViewHandler(1);
        this.getTaskList(
          null,
          null,
          null,
          null,
          false,
          this.state.firstDayOfWeek,
          this.state.LastDayOfWeek
        );
        this.setWeekDates(this.state.newDailyDate, false);
      }
    });
  };

  toggleAddTimeentry(
    projectId,
    day,
    project,
    task,
    taskId,
    editMode,
    addMode,
    globleAddMode,
    allocationId,
    taskType,
    isShiftTask
  ) {
    if (day === 'Tur') {
      day = 'Thu';
    }
    let currentDate = null;
    let SelectedCurrentDate = null;
    var curr = this.state.newDate;
    if (moment(this.state.newDate).day() === 0) {
      curr = new Date(moment(this.state.newDate).subtract(2, 'days'));
    }
    var newDate = new Date();
    switch (day) {
      case 'Sun':
        {
          currentDate = moment(
            new Date(
              newDate.setFullYear(
                curr.getFullYear(),
                curr.getMonth(),
                curr.getDate() - curr.getDay() + 7
              )
            )
          ).format('DD/MM/YYYY');
          SelectedCurrentDate = new Date(
            newDate.setFullYear(
              curr.getFullYear(),
              curr.getMonth(),
              curr.getDate() - curr.getDay() + 7
            )
          );
        }
        break;
      case 'Mon':
        {
          currentDate = moment(
            new Date(
              newDate.setFullYear(
                curr.getFullYear(),
                curr.getMonth(),
                curr.getDate() - curr.getDay() + 1
              )
            )
          ).format('DD/MM/YYYY');
          SelectedCurrentDate = new Date(
            newDate.setFullYear(
              curr.getFullYear(),
              curr.getMonth(),
              curr.getDate() - curr.getDay() + 1
            )
          );
        }
        break;
      case 'Tue':
        {
          currentDate = moment(
            new Date(
              newDate.setFullYear(
                curr.getFullYear(),
                curr.getMonth(),
                curr.getDate() - curr.getDay() + 2
              )
            )
          ).format('DD/MM/YYYY');
          SelectedCurrentDate = new Date(
            newDate.setFullYear(
              curr.getFullYear(),
              curr.getMonth(),
              curr.getDate() - curr.getDay() + 2
            )
          );
        }
        break;
      case 'Wed':
        {
          currentDate = moment(
            new Date(
              newDate.setFullYear(
                curr.getFullYear(),
                curr.getMonth(),
                curr.getDate() - curr.getDay() + 3
              )
            )
          ).format('DD/MM/YYYY');

          SelectedCurrentDate = new Date(
            newDate.setFullYear(
              curr.getFullYear(),
              curr.getMonth(),
              curr.getDate() - curr.getDay() + 3
            )
          );
        }
        break;
      case 'Thu':
        {
          currentDate = moment(
            new Date(
              newDate.setFullYear(
                curr.getFullYear(),
                curr.getMonth(),
                curr.getDate() - curr.getDay() + 4
              )
            )
          ).format('DD/MM/YYYY');
          SelectedCurrentDate = new Date(
            newDate.setFullYear(
              curr.getFullYear(),
              curr.getMonth(),
              curr.getDate() - curr.getDay() + 4
            )
          );
        }
        break;
      case 'Fri':
        {
          currentDate = moment(
            new Date(
              newDate.setFullYear(
                curr.getFullYear(),
                curr.getMonth(),
                curr.getDate() - curr.getDay() + 5
              )
            )
          ).format('DD/MM/YYYY');
          SelectedCurrentDate = new Date(
            newDate.setFullYear(
              curr.getFullYear(),
              curr.getMonth(),
              curr.getDate() - curr.getDay() + 5
            )
          );
        }
        break;
      case 'Sat': {
        currentDate = moment(
          new Date(
            newDate.setFullYear(
              curr.getFullYear(),
              curr.getMonth(),
              curr.getDate() - curr.getDay() + 6
            )
          )
        ).format('DD/MM/YYYY');
        SelectedCurrentDate = new Date(
          newDate.setFullYear(
            curr.getFullYear(),
            curr.getMonth(),
            curr.getDate() - curr.getDay() + 6
          )
        );
      }
    }
    if (!globleAddMode && !editMode && SelectedCurrentDate && (project !== 'HR - Holidays' && project !== 'HR - Leave')) {
      let params = {
        date: moment(SelectedCurrentDate).format('YYYY-MM-DD')
      };
      getLatestTimeEntryByDate(params).then(res => {
        if (res.data && res.data.WorkLocationId) {
          this.setState({
            workLocation: res.data.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == res.data.WorkLocationId)[0] : null,
          });  
        }
      });  
    }
    taskType === 'NPT' &&
      this.setState({
        currentProjectId: projectId,
        currentNonProjectTaskAllocationId: allocationId,
        currentProjectTaskAllocationId: null,
        currentTaskId: taskId,
        currentDay: day,
        currentDate: currentDate,
        SelectedCurrentDate: SelectedCurrentDate,
        currentProject: project !== null ? project : this.state.currentProject,
        currentProjectForDropDown: this.state.projectDropdownFilter.filter(
          x => x.DisplayText === project
        )[0],
        currentTask: task,
        timeEntryAdd: [],
        timeEntryEditMode: editMode,
        timeEntryAddMode: addMode,
        timeEntryGlobleAddMode: globleAddMode
      });

    taskType === 'CRT' &&
      this.setState({
        currentProjectId: projectId,
        currentNonProjectTaskAllocationId: null,
        currentProjectTaskAllocationId: allocationId,
        currentTaskId: taskId,
        currentDay: day,
        currentDate: currentDate,
        SelectedCurrentDate: SelectedCurrentDate,
        currentProject: project !== null ? project : this.state.currentProject,
        currentProjectForDropDown: this.state.projectDropdownFilter.filter(
          x => x.DisplayText === project
        )[0],
        currentTask: task,
        timeEntryAdd: [],
        timeEntryEditMode: editMode,
        timeEntryAddMode: addMode,
        timeEntryGlobleAddMode: globleAddMode
      });

    taskType === 'PT' &&
      this.setState({
        currentProjectId: projectId,
        currentNonProjectTaskAllocationId: null,
        currentProjectTaskAllocationId: allocationId,
        currentTaskId: taskId,
        currentDay: day,
        currentDate: currentDate,
        SelectedCurrentDate: SelectedCurrentDate,
        currentProject: project !== null ? project : this.state.currentProject,
        currentProjectForDropDown: this.state.projectDropdownFilter.filter(
          x => x.DisplayText === project
        )[0],
        currentTask: task,
        timeEntryAdd: [],
        timeEntryEditMode: editMode,
        timeEntryAddMode: addMode,
        timeEntryGlobleAddMode: globleAddMode
      });
    this.setState({
      addTimeentry: !this.state.addTimeentry,
      isShiftTask: isShiftTask,
      isErrorMessageVisible: false,
      isTicketIdErrorMessageVisible: false,
      isLocationErrorMessageVisible: false,
      isTaskExist: false,
      isAddTimeFreeze: false,
      timeEntryData: []
    });
  }
  toggleMessageDialog = (message, title) => {
    this.setState({
      showMessageDialog: !this.state.showMessageDialog,
      dialogMessage: message,
      dialogTitle: title,
      unfreezePopupVisible: false
    });
  };

  toggleRejectDialog() {
    this.setState({
      rejectTa: !this.state.rejectTa
    });
  }

  toggleUnfreezeDialog = async() => {
    await this.setState({
      unfreezePopupVisible: !this.state.unfreezePopupVisible,
      isErrorMessageVisible: false,
      unfreezeReason: '',
      isErrorMessageVisibleReason: false
    });
    if (!this.state.unfreezePopupVisible) {
      await this.setState({
        havingtwoMonthBackDates: false,
        unfreezeMessage: ''
      });
    }
  }

  toggleWorkflowSelectDialog = () => {
    this.setState({
      showWorkflowSelectDialog: !this.state.showWorkflowSelectDialog
    });
  };

  setSelectedWorkflowId = async workflowId => {
    await this.setState({
      selectedWorkFlowId: workflowId
    });
  };

  SubmitUnfreezeRequest = async () => {
    if (
      this.validateProperty(this.state.unfreezeReason)
        .toString()
        .includes('error')
    ) {
      this.setState({
        isErrorMessageVisibleReason: true
      });
    } else {
      await selectTimeSheetUnfreezeRequestWorkflow().then(res => {
        if (res.data.length > 1) {
          this.setState(
            {
              matchingWorkflowList: res.data
            },
            () => {
              this.toggleWorkflowSelectDialog();
            }
          );
        } else if (res.data.length === 1) {
          this.setState(
            {
              selectedWorkFlowId: res.data[0].id
            },
            () => {
              this.setWorkflowToResources();
            }
          );
        } else {
          const message =
            'This unfreeze request has failed to find a appropriate workflow for approvals. Please contact your system administrator to correct this issue.';
          const title = 'Error';
          this.setState({
            showSuccessImage: false,
            showErrorImage: true,
            isErrorMessageVisible: false
          });
          this.toggleMessageDialog(message, title);
        }
      });
    }
  };

  setAllSelectedDaysUnfreeze = async () => {
    let unfreezeSelectedDates = [];
    if (this.state.isAllSelectedSun) {
      unfreezeSelectedDates.push(moment(this.state.dateOfSun).format('YYYY-MM-DD'));
    }
    if (this.state.isAllSelectedMon) {
      unfreezeSelectedDates.push(moment(this.state.dateOfMon).format('YYYY-MM-DD'));
    }
    if (this.state.isAllSelectedTue) {
      unfreezeSelectedDates.push(moment(this.state.dateOfTue).format('YYYY-MM-DD'));
    }
    if (this.state.isAllSelectedWed) {
      unfreezeSelectedDates.push(moment(this.state.dateOfWed).format('YYYY-MM-DD'));
    }
    if (this.state.isAllSelectedTur) {
      unfreezeSelectedDates.push(moment(this.state.dateOfTur).format('YYYY-MM-DD'));
    }
    if (this.state.isAllSelectedFri) {
      unfreezeSelectedDates.push(moment(this.state.dateOfFri).format('YYYY-MM-DD'));
    }
    if (this.state.isAllSelectedSat) {
      unfreezeSelectedDates.push(moment(this.state.dateOfSat).format('YYYY-MM-DD'));
    }
    await this.setState({
      selectedDates: unfreezeSelectedDates
    });
  };

  setUnfreezeButtonEnableDisable = async () => {
    await this.getUserFreezeDays();
    let freezeDateSun = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfSun).format('YYYY-MM-DD')
    );
    let freezeDateMon = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfMon).format('YYYY-MM-DD')
    );
    let freezeDateTue = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfTue).format('YYYY-MM-DD')
    );
    let freezeDateWed = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfWed).format('YYYY-MM-DD')
    );
    let freezeDateThu = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfTur).format('YYYY-MM-DD')
    );
    let freezeDateFri = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfFri).format('YYYY-MM-DD')
    );
    let freezeDateSat = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfSat).format('YYYY-MM-DD')
    );

    if (
      (this.state.isAllSelectedMon &&
        this.state.isFreezeDateOfMon &&
        freezeDateMon.TEUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value) ||
      (this.state.isAllSelectedTue &&
        this.state.isFreezeDateOfTue &&
        freezeDateTue.TEUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value) ||
      (this.state.isAllSelectedWed &&
        this.state.isFreezeDateOfWed &&
        freezeDateWed.TEUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value) ||
      (this.state.isAllSelectedTur &&
        this.state.isFreezeDateOfThu &&
        freezeDateThu.TEUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value) ||
      (this.state.isAllSelectedFri &&
        this.state.isFreezeDateOfFri &&
        freezeDateFri.TEUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value) ||
      (this.state.isAllSelectedSat &&
        this.state.isFreezeDateOfSat &&
        freezeDateSat.TEUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value) ||
      (this.state.isAllSelectedSun &&
        this.state.isFreezeDateOfSun &&
        freezeDateSun.TEUnFreezeRequestStatus !== UnFreezeStatus.Pending_Approval.value)
    ) {
      this.setState({
        isUnfreezeButtonDisable: false
      });
    } else {
      this.setState({
        isUnfreezeButtonDisable: true
      });
    }
  };

  setWorkflowToResources = async () => {

    const workflowData = {
      WorkflowId: this.state.selectedWorkFlowId,
      Comment: this.state.unfreezeReason,
      RequestType: 1, // Unfreeze time entry
      UnfreezeDates: this.state.selectedDates
    };
    await setWorkflow(workflowData)
      .then(async res => {
        if (this.state.matchingWorkflowList.length > 1) {
          this.toggleWorkflowSelectDialog();
        }
        this.setState({
          loader: true
        });
        this.toggleUnfreezeDialog();
        this.setState({ selectedWorkFlowId: null, unfreezeReason: '' });
        await this.setUnfreezeButtonEnableDisable();
        let message = 'Selected time entries were sent for unfreeze approval.';
        const title = 'Success';
        this.setState({
          showSuccessImage: true,
          showErrorImage: false,
          isErrorMessageVisible: false,
          isUnfreezeButtonDisable: true
        });
        this.toggleDialog(message, title);
        if (this.state.status != 2) {
          this.onRunButtonClick(this.state.newDailyDate, false);
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.state.matchingWorkflowList.length > 1) {
          this.toggleWorkflowSelectDialog();
        }
        this.toggleUnfreezeDialog();
        this.setState({ selectedWorkFlowId: null, unfreezeReason: '' });
        const message = error.response.data;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleMessageDialog(message, title);
      });
    if (this.state.status === 2) {
      for (const date of this.state.selectedDates) {
        let selectedDay = new Date(date).getDay();
        if (selectedDay === 0) {
          selectedDay = 7;
        }
        this.selectAllDay(selectedDay);
      }
    }
    this.setState({
      loader: false
    });
  };

  handleUnfreezeReason = async event => {
    await this.setState({
      unfreezeReason: event.target.value
    });
  };

  toggleWindowDialog() {
    this.setState({
      windowDialogVisible: !this.state.windowDialogVisible
    });
  }
  filterOnChange = event => {
    const field = event.target.name;
    switch (field) {
      case 'project':
        {
          this.setState({
            projectDropdownFilter: this.filterComboData(
              event.filter,
              this.state.projectDropdownFilterAll
            )
          });
        }
        break;
      case 'task':
        {
          this.setState({
            taskFilter: this.filterComboData(event.filter, this.state.taskFilterAll)
          });
        }
        break;
      case 'selectProject':
        {
          this.setState({
            projectDropdownFilter: this.filterComboData(
              event.filter,
              this.state.projectDropdownFilterAll
            )
          });
        }
        break;
      case 'taskDropDown':
        {
          this.setState({
            taskDropdownFilter: this.filterComboData(event.filter, this.state.taskDropdownFilterAll)
          });
        }
        break;
      default: {
        break;
      }
    }
  };
  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }
  headerSelectionChange = event => {
    this.setState(
      {
        allSelect: !this.state.allSelect
      },
      () => {
        if (this.state.allSelect) {
          let data = this.state.data;
          data.forEach(task => {
            task.selected = true;
            task.Mon.cards.forEach(card => {
              card.checked = true;
            });
            task.Tue.cards.forEach(card => {
              card.checked = true;
            });
            task.Wed.cards.forEach(card => {
              card.checked = true;
            });
            task.Tur.cards.forEach(card => {
              card.checked = true;
            });
            task.Fri.cards.forEach(card => {
              card.checked = true;
            });
            task.Sat.cards.forEach(card => {
              card.checked = true;
            });
            task.Sun.cards.forEach(card => {
              card.checked = true;
            });
          });
          this.setState({
            data: data,
            selectAll: false,
            isAllSelectedMon: true,
            isAllSelectedTue: true,
            isAllSelectedWed: true,
            isAllSelectedTur: true,
            isAllSelectedFri: true,
            isAllSelectedSat: true,
            isAllSelectedSun: true
          });
        } else {
          let data = this.state.data;
          data.forEach(task => {
            task.selected = false;
            task.Mon.cards.forEach(card => (card.checked = false));
            task.Tue.cards.forEach(card => (card.checked = false));
            task.Wed.cards.forEach(card => (card.checked = false));
            task.Tur.cards.forEach(card => (card.checked = false));
            task.Fri.cards.forEach(card => (card.checked = false));
            task.Sat.cards.forEach(card => (card.checked = false));
            task.Sun.cards.forEach(card => (card.checked = false));
          });
          this.setState({
            data: data,
            selectAll: false,
            isAllSelectedMon: false,
            isAllSelectedTue: false,
            isAllSelectedWed: false,
            isAllSelectedTur: false,
            isAllSelectedFri: false,
            isAllSelectedSat: false,
            isAllSelectedSun: false
          });
        }
      }
    );
  };
  selectAllButtonHandler = selectAll => {
    let data = this.state.data;
    let selectedList = [];
    data.forEach(task => {
      task.Mon.cards.forEach(card => {
        if (
          (card.approvedStatus === 1 || card.approvedStatus === 4) &&
          !this.state.isFreezeDateOfMon
        ) {
          card.checked = selectAll;
          selectedList.push(card);
        } else {
          card.checked = false;
        }
      });
      task.Tue.cards.forEach(card => {
        if (
          (card.approvedStatus === 1 || card.approvedStatus === 4) &&
          !this.state.isFreezeDateOfTue
        ) {
          card.checked = selectAll;
          selectedList.push(card);
        } else {
          card.checked = false;
        }
      });
      task.Wed.cards.forEach(card => {
        if (
          (card.approvedStatus === 1 || card.approvedStatus === 4) &&
          !this.state.isFreezeDateOfWed
        ) {
          card.checked = selectAll;
          selectedList.push(card);
        } else {
          card.checked = false;
        }
      });
      task.Tur.cards.forEach(card => {
        if (
          (card.approvedStatus === 1 || card.approvedStatus === 4) &&
          !this.state.isFreezeDateOfThu
        ) {
          card.checked = selectAll;
          selectedList.push(card);
        } else {
          card.checked = false;
        }
      });
      task.Fri.cards.forEach(card => {
        if (
          (card.approvedStatus === 1 || card.approvedStatus === 4) &&
          !this.state.isFreezeDateOfFri
        ) {
          card.checked = selectAll;
          selectedList.push(card);
        } else {
          card.checked = false;
        }
      });
      task.Sat.cards.forEach(card => {
        if (
          (card.approvedStatus === 1 || card.approvedStatus === 4) &&
          !this.state.isFreezeDateOfSat
        ) {
          card.checked = selectAll;
          selectedList.push(card);
        } else {
          card.checked = false;
        }
      });
      task.Sun.cards.forEach(card => {
        if (
          (card.approvedStatus === 1 || card.approvedStatus === 4) &&
          !this.state.isFreezeDateOfSun
        ) {
          card.checked = selectAll;
          selectedList.push(card);
        } else {
          card.checked = false;
        }
      });
    });
    if (!selectAll) {
      selectedList = [];
    }
    this.setState({
      data: data,
      isAllSelectedMon: false,
      isAllSelectedTue: false,
      isAllSelectedWed: false,
      isAllSelectedTur: false,
      isAllSelectedFri: false,
      isAllSelectedSat: false,
      isAllSelectedSun: false,
      selectAll: selectAll
    });
    this.deleteButtonHandler(selectedList);
  };
  radioViewHandler = status => {
    this.setState({
      loader: false,
      status: status
    });
  };

  toggleNonbillDialog = () => {
    this.setState({
      nonbilldialog: !this.state.nonbilldialog
    });
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.isMount = true;
    const promice = [];
    this.setState({
      loader: true
    });
    this.getLoggedInUserStatus();
    this.getUserFreezeDays();
    await this.getUserFreezeDate();
    this.setHeaderTitle();
    this.totalHrsGrid();
    this.totalHourePerDay();
    this.getDataForProjectFilter(true);
    this.setCurrentWeekPendingView();
    this.setWeekDates(new Date(), true);
    this.getAllPendingEntries(
      this.state.allPendingFromDate,
      this.state.allPendingToDate,
      null,
      null,
      null
    );
  }

  getUserFreezeDate = async () => {
    await getTimeEntryFreezeDateFromTimesheetFreezeDate().then(async res => {
      if (res.data.length > 0) {
        let date = new Date(res.data[0].FreezeDate);
        await this.setState({ timeEntryFreezeDate: moment(date).format('MM/DD/YYYY') });
      }
    });
  };

  getAllPendingEntries = (fromDate, toDate, projId, crId, nonProjId) => {
    const data = {
      firstDate: moment(fromDate).format('YYYY-MM-DD'),
      lastDate: moment(toDate).format('YYYY-MM-DD'),
      id: projId ? projId : crId ? crId : nonProjId ? nonProjId : null,
      type: projId ? 'project' : crId ? 'cr' : nonProjId ? 'nonProject' : null
    };
    getAllPendingTimes(data).then(res => {
      if (res && res.data) {
        this.setState({ timeentryallpending: res.data, allPendingSkip: 0 });
      }
    });
  };

  getUserFreezeDays = async () => {
    await getTimeEntryFreezeDaysByUser().then(res => {
      if (res.data.length > 0) {
        this.setState({ timeEntryFreezeDates: res.data });
        this.validateFreezeDate();
      }
    });
  };

  getLoggedInUserStatus = () => {
    getLoggedInUserStatus().then(res => {
      if (res.data[0].IsActive === false) {
        this.setState({
          userStatus: false
        });
      }
    });
  };

  getApprovedStatusCell = (statusId, ApprovedNonBillingCatagoryId) => {
    switch (statusId) {
      case approvedStatus.Pending_Approval.value:
        return (
          <td>
            <span className="timesheet-grid-status timesheet-grid-status-none">&nbsp;</span>
            &nbsp;Approval Pending
          </td>
        );
      case approvedStatus.Approved.value:
        return (
          <td>
            <span className="timesheet-grid-status timesheet-grid-status-green">&nbsp;</span>
            &nbsp;Approved – Billable
          </td>
        );
      case approvedStatus.Rejected.value:
        return (
          <td>
            <span className="timesheet-grid-status timesheet-grid-status-gray">&nbsp;</span>
            &nbsp;Rejected
          </td>
        );
      case approvedStatus.Auto_Generated.value:
        return (
          <td>
            <span className="timesheet-grid-status timesheet-grid-status-light-blue">&nbsp;</span>
            &nbsp;Automatic Approvals
          </td>
        );
      case approvedStatus.Leave.value:
        return (
          <td>
            <span className="timesheet-grid-status timesheet-grid-status-light-blue">&nbsp;</span>
            &nbsp;Leave
          </td>
        );
      case approvedStatus.Approved_NB.value:
        if (ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_General.value) {
          return (
            <td>
              <span className="timesheet-grid-status timesheet-grid-status-blue">&nbsp;</span>
              &nbsp;Approved Non-Billable - General
            </td>
          );
        } else if (ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_ESE.value) {
          return (
            <td>
              <span className="timesheet-grid-status timesheet-grid-status-yellow">&nbsp;</span>
              &nbsp;Approved Non-Billable - Estimation Issues
            </td>
          );
        } else if (ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_PMM.value) {
          return (
            <td>
              <span className="timesheet-grid-status timesheet-grid-status-purple">&nbsp;</span>
              &nbsp;Approved Non-Billable - Mismanagement
            </td>
          );
        } else if (ApprovedNonBillingCatagoryId === approvedStatus.Approved_NB_RWK.value) {
          return (
            <td>
              <span className="timesheet-grid-status timesheet-grid-status-orange">&nbsp;</span>
              &nbsp;Approved Non-Billable - Rework
            </td>
          );
        } else {
          return (
            <td>
              <span className="timesheet-grid-status timesheet-grid-status-blue">&nbsp;</span>
              &nbsp;Approved Non-Billable
            </td>
          );
        }
      default:
        return null;
    }
  };

  getApprovedStatusName = statusId => {
    switch (statusId) {
      case 1:
        return 'Pending Approval';
      case 2:
        return 'Approved – Billable';
      case 3:
        return 'Rejected ';
      case 4:
        return 'Automatic Approvals';
      case 5:
        return 'Approved Non-Billable';
      case 6:
        return 'Approved Non-Billable ESE';
      case 7:
        return 'Approved Non-Billable PMM';
      case 8:
        return 'Approved Non-Billable RWK';
      case 9:
        return 'Approved Non-Billable General';
      case 10:
        return 'Leave';
      default:
        return '';
    }
  };

  getDataForProjectFilter = init => {
    let dropdownData = [];
    this.isMount &&
      getDataForProjectFilter().then(res => {
        if (res.data.length !== 0) {
          res.data.forEach(task => {
            if (typeof task.ProjectTask === 'undefined') {
              if (
                typeof dropdownData.find(x => x.DisplayText === task.TaskGroup.Name) === 'undefined'
              ) {
                let obj = {
                  id: task.id,
                  projId: null,
                  CRId: null,
                  NPTId: task.TaskGroupId,
                  DisplayText: task.TaskGroup.Name,
                  Category: 'NPT' /*NPT - Non project task */,
                  StartDate: task.StartDate,
                  EndDate: task.EndDate
                };
                dropdownData.push(obj);
              }
            } else {
              if (task.ProjectTask.CRId === null) {
                if (
                  typeof dropdownData.find(
                    x => x.DisplayText === task.ProjectTask.Project.ProjectName
                  ) === 'undefined'
                ) {
                  let obj = {
                    id: task.ProjectTask.Project.id,
                    projId: task.ProjectTask.Project.id,
                    CRId: null,
                    NPTId: null,
                    DisplayText: task.ProjectTask.Project.ProjectName,
                    Category: 'PT' /*PT - project task */,
                    StartDate: task.ProjectTask.StartDate,
                    EndDate: task.ProjectTask.EndDate,
                    ProjectDepartment: task.ProjectTask.Project.BillingDivision
                  };
                  dropdownData.push(obj);
                }
              } else {
                if (
                  typeof dropdownData.find(
                    x => x.DisplayText === task.ProjectTask.ChangeRequest.CRName
                  ) === 'undefined'
                ) {
                  let obj = {
                    id: task.ProjectTask.CRId,
                    projId: task.ProjectTask.Project.id,
                    CRId: task.ProjectTask.CRId,
                    NPTId: null,
                    DisplayText: task.ProjectTask.ChangeRequest.CRName,
                    Category: 'CR' /*CR - Change Rewuest */,
                    StartDate: task.ProjectTask.StartDate,
                    EndDate: task.ProjectTask.EndDate,
                    ProjectDepartment: task.ProjectTask.ChangeRequest.BillingDivisionId
                  };
                  dropdownData.push(obj);
                }
              }
            }
          });
          if (init === true) {
            this.setState(
              {
                projectDropdownFilter: dropdownData,
                projectDropdownFilterAll: dropdownData
              },
              () => {
                this.getSearchCriteria();
              }
            );
          } else {
            this.setState({
              projectDropdownFilter: dropdownData,
              projectDropdownFilterAll: dropdownData
            });
          }
        }
      });
  };

  getTimeCards = async () => {
    let data = this.state.data;

    let promise = data.map(async task => {
      let projAllcations = [];
      let nonProjAllocations = [];
      if (task.taskType == 'PT' || task.taskType == 'CRT') {
        projAllcations = task.allocations;
      }
      if (task.taskType == 'NPT') {
        nonProjAllocations = task.allocations;
      }

      task.Mon.cards = [];
      task.Tue.cards = [];
      task.Wed.cards = [];
      task.Tur.cards = [];
      task.Fri.cards = [];
      task.Sat.cards = [];
      task.Sun.cards = [];

      const params = {
        firstDate: moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
        lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD'),
        projAllcations: projAllcations,
        nonProjAllocations: nonProjAllocations
      };
      await getTimeCards(params).then(res => {
        this.setState(
          {
            data: data
          },
          () => {
            res.data.forEach(timeEntry => {
              const day = moment(timeEntry.LogDateTime);
              let durationParts = timeEntry.Duration.split('.');
              let duration = durationParts[0];
              if (typeof durationParts[1] !== 'undefined') {
                duration =
                  parseInt(duration) +
                  ':' +
                  Math.round(parseFloat(`0.${durationParts[1]}`) * 60)
                    .toString()
                    .padStart(2, '0');
              } else {
                duration = parseInt(duration) + ':' + '00';
              }
              const card = {
                cardId: timeEntry.id,
                checked: false,
                comment: timeEntry.Comment,
                TicketId: timeEntry.TicketId,
                WorkLocationId: timeEntry.WorkLocationId,
                WorkLocationName: timeEntry.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == timeEntry.WorkLocationId)[0].name : '',
                duration: duration,
                endTime: timeEntry.ToDateTime,
                startTime: timeEntry.FromDateTime,
                approvedStatus: timeEntry.ApprovedStatus,
                ApprovedNonBillingCatagoryId: timeEntry.ApprovedNonBillingCatagoryId,
                ApproveFreezeStatus: timeEntry.ApproveFreezeStatus,
                ApprovedBy: timeEntry.ApprovedBy,
                EntryFreezeStatus: timeEntry.EntryFreezeStatus,
                FromDateTime: timeEntry.FromDateTime,
                ToDateTime: timeEntry.ToDateTime,
                RejectReason: timeEntry.RejectReason,
                ResonOfMove: timeEntry.ResonOfMove,
                LogDateTime: timeEntry.LogDateTime,
                project: task.Project,
                task: task.Task,
                taskId: task.taskId,
                taskType: task.taskType,
                isShiftTask: task.isShiftTask,
                allocationId: timeEntry.ProjectTaskAllocationId !== null ? timeEntry.ProjectTaskAllocationId : timeEntry.NonProjectTaskAllocationId,
                IsPending : timeEntry.IsPending
              };
              switch (day.day()) {
                case 0:
                  task.Sun.cards.push(card);
                  break;
                case 1:
                  task.Mon.cards.push(card);
                  break;
                case 2:
                  task.Tue.cards.push(card);
                  break;
                case 3:
                  task.Wed.cards.push(card);
                  break;
                case 4:
                  task.Tur.cards.push(card);
                  break;
                case 5:
                  task.Fri.cards.push(card);
                  break;
                case 6:
                  task.Sat.cards.push(card);
                  break;
              }
            });
          }
        );
      });
    });
    await Promise.all(promise);
    this.setState({
      refreshState: !this.state.refreshState,
      loader: false
    });
  };

  convertDuration = durationToConvert => {
    let durationParts = durationToConvert.split('.');
    if (durationParts.length > 1) {
      let duration = durationParts[0];
      if (typeof durationParts[1] !== 'undefined') {
        duration =
          parseInt(duration) +
          ':' +
          Math.round(parseFloat(`0.${durationParts[1]}`) * 60)
            .toString()
            .padStart(2, '0');
      } else {
        duration = parseInt(duration) + ':' + '00';
      }
      return duration;
    }
    return durationToConvert;
  };
  getAllocationId = (allTaskAllocations, firstDayOfWeek, lastDayOfWeek) => {
    let taskAllocations = [];
    if (typeof allTaskAllocations[0].ProjectTaskId !== 'undefined') {
      taskAllocations = allTaskAllocations.filter(x => x.IsDeleted === false);
    } else {
      taskAllocations = allTaskAllocations;
    }
    let firstDay = moment(firstDayOfWeek).format('YYYY-MM-DD');
    let lastDay = moment(lastDayOfWeek).format('YYYY-MM-DD');
    let IsAllAlocationNotActive = false;
    if (taskAllocations.length !== 0) {
      let activeAllocations = taskAllocations.filter(x => x.IsActive === false);
      if (activeAllocations.length === taskAllocations.length) {
        IsAllAlocationNotActive = true;
      }
    }

    let allocations = {
      allAllocationOfWeek: [],
      disableDateList: [],
      isShiftTask: false,
      IsAllAlocationNotActive: IsAllAlocationNotActive,
      Mon: null,
      Tue: null,
      Wed: null,
      Thr: null,
      Fri: null,
      Sat: null,
      Sun: null
    };
    taskAllocations.length !== 0 &&
      taskAllocations.map(allo => {
        allocations.allAllocationOfWeek.push(allo.id);
      });
    while (firstDay <= lastDay) {
      const filterdTaskAllocations = taskAllocations.filter(
        allocation =>
          moment(allocation.StartDate).format('YYYY-MM-DD') <= firstDay &&
          moment(allocation.EndDate).format('YYYY-MM-DD') >= firstDay
      );
      let allocation = null;
      if (filterdTaskAllocations.length !== 0) {
        if (filterdTaskAllocations.length > 1) {
          let filtedAllocations = filterdTaskAllocations;

          if (filtedAllocations.length !== 0) {
            allocation = filtedAllocations.sort((x, y) => {
              return new Date(x.StartDate).getTime() - new Date(y.StartDate).getTime();
            })[0];
          }
        } else {
          allocation = filterdTaskAllocations[0];
        }
      }
      if (allocation !== null) {
        if (typeof allocation.ShiftFrom !== 'undefined' && allocation.ShiftFrom !== null) {
          allocations.isShiftTask = true;
        }
        if (allocations.allAllocationOfWeek.length !== 0) {
          if (!allocations.allAllocationOfWeek.includes(allocation.id)) {
            allocations.allAllocationOfWeek.push(allocation.id);
          }
        } else {
          allocations.allAllocationOfWeek.push(allocation.id);
        }
      } else {
        allocations.disableDateList.push(firstDay);
      }
      switch (moment(firstDay).day()) {
        case 0:
          {
            allocations.Sun = allocation !== null ? allocation.id : null;
          }
          break;
        case 1:
          {
            allocations.Mon = allocation !== null ? allocation.id : null;
          }
          break;
        case 2:
          {
            allocations.Tue = allocation !== null ? allocation.id : null;
          }
          break;
        case 3:
          {
            allocations.Wed = allocation !== null ? allocation.id : null;
          }
          break;
        case 4:
          {
            allocations.Thr = allocation !== null ? allocation.id : null;
          }
          break;
        case 5:
          {
            allocations.Fri = allocation !== null ? allocation.id : null;
          }
          break;
        case 6:
          {
            allocations.Sat = allocation !== null ? allocation.id : null;
          }
          break;
      }
      firstDay = moment(moment(firstDay).add(1, 'd')).format('YYYY-MM-DD');
    }
    return allocations;
  };

  clearFilters = () => {
    this.setState(
      {
        selectedProjectOrCr: null,
        withTimeEntry: false,
        selectedTask: null
      },
      () => {
        this.getTaskList(
          null,
          null,
          null,
          null,
          false,
          this.state.firstDayOfWeek,
          this.state.LastDayOfWeek
        );
      }
    );
  };

  getDailyTimeEntryData = async (projId, CRId, NPTID, taskId, projType, firstDay) => {
    this.setState({ loader: true });
    const params = {
      firstDate: moment(firstDay).format('YYYY-MM-DD'),
      projId: projId,
      projType: projType,
      crId: CRId,
      taskId: taskId,
      nptId: NPTID
    };
    let timeEntryData = [];
    let dateFreezed = false;
    let freezedRequestSubmitted = false;
    await getDailyTimeEntries(params).then(async res => {
      if (res && res.data) {
        if (res.data.taskList && res.data.taskList.length > 0) {
          res.data.taskList.forEach(entry => {
            if (entry.ProjectTaskAllocation) {
              const timeEntry = {
                id: entry.id,
                Project: entry.ProjectTaskAllocation.ProjectTask.ProjectType == 'Project'
                  ? entry.ProjectTaskAllocation.ProjectTask.Project.ProjectName
                  : entry.ProjectTaskAllocation.ProjectTask.ChangeRequest.CRName,
                Task: entry.ProjectTaskAllocation.ProjectTask.Name,
                Duration: !isNaN(Number(entry.Duration))
                  ? Number(entry.Duration).toFixed(2)
                  : entry.Duration,
                Comment: entry.Comment,
                approvedStatus: entry.ApprovedStatus,
                approvedNonBillingCatagoryId: entry.ApprovedNonBillingCatagoryId,
                projectId: entry.ProjectTaskAllocation.ProjectTask.Project.id,
                taskId: entry.ProjectTaskAllocation.ProjectTask.id,
                allocationId: entry.ProjectTaskAllocation.id,
                type: 'project',
                FromDateTime: entry.FromDateTime,
                ToDateTime: entry.ToDateTime,
                task: entry.ProjectTaskAllocation.ProjectTask,
                RejectReason: entry.RejectReason,
                ResonOfMove: entry.ResonOfMove,
                ApprovedBy: entry.ApprovedBy,
                TicketId: entry.TicketId,
                WorkLocationId: entry.WorkLocationId,
                WorkLocationName: entry.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == entry.WorkLocationId)[0].name : '',
              };
              timeEntryData.push(timeEntry);
            } else {
              if(entry.NonProjectTaskAllocation.NonProjectTask.TaskGroupId === 1 ||entry.NonProjectTaskAllocation.NonProjectTask.TaskGroupId === 2 ){
                if(moment(firstDay).format('YYYY-MM-DD') >= moment(entry.NonProjectTaskAllocation.StartDate).format('YYYY-MM-DD')){
                  const timeEntry = {
                    id: entry.id,
                    Project: entry.NonProjectTaskAllocation.NonProjectTask.TaskGroup.Name,
                    Task: entry.NonProjectTaskAllocation.NonProjectTask.Name,
                    Duration: !isNaN(Number(entry.Duration))
                      ? Number(entry.Duration).toFixed(2)
                      : entry.Duration,
                    Comment: entry.Comment,
                    approvedStatus: entry.ApprovedStatus,
                    approvedNonBillingCatagoryId: entry.ApprovedNonBillingCatagoryId,
                    projectId: null,
                    taskId: entry.NonProjectTaskAllocation.NonProjectTask.id,
                    allocationId: entry.NonProjectTaskAllocation.id,
                    type: 'nonproject',
                    FromDateTime: entry.FromDateTime,
                    ToDateTime: entry.ToDateTime,
                    task: entry.NonProjectTaskAllocation.NonProjectTask,
                    RejectReason: entry.RejectReason,
                    ResonOfMove: entry.ResonOfMove,
                    ApprovedBy: entry.ApprovedBy,
                    TicketId: entry.TicketId,
                    WorkLocationId: entry.WorkLocationId,
                    WorkLocationName: entry.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == entry.WorkLocationId)[0].name : '',
                  };
                  timeEntryData.push(timeEntry);
                }
              }else{
                const timeEntry = {
                  id: entry.id,
                  Project: entry.NonProjectTaskAllocation.NonProjectTask.TaskGroup.Name,
                  Task: entry.NonProjectTaskAllocation.NonProjectTask.Name,
                  Duration: !isNaN(Number(entry.Duration))
                    ? Number(entry.Duration).toFixed(2)
                    : entry.Duration,
                  Comment: entry.Comment,
                  approvedStatus: entry.ApprovedStatus,
                  approvedNonBillingCatagoryId: entry.ApprovedNonBillingCatagoryId,
                  projectId: null,
                  taskId: entry.NonProjectTaskAllocation.NonProjectTask.id,
                  allocationId: entry.NonProjectTaskAllocation.id,
                  type: 'nonproject',
                  FromDateTime: entry.FromDateTime,
                  ToDateTime: entry.ToDateTime,
                  task: entry.NonProjectTaskAllocation.NonProjectTask,
                  RejectReason: entry.RejectReason,
                  ResonOfMove: entry.ResonOfMove,
                  ApprovedBy: entry.ApprovedBy,
                  TicketId: entry.TicketId,
                  WorkLocationId: entry.WorkLocationId,
                  WorkLocationName: entry.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == entry.WorkLocationId)[0].name : '',
                };
                timeEntryData.push(timeEntry);
              }
            }
          });
        }
        dateFreezed = dateFreezed ? dateFreezed : res.data.isFreezed;
        freezedRequestSubmitted = res.data.freezedRequestStatus == 1 ? true : false;
      }
      this.getDailyCalculatedTotals();
      this.setState({
        loader: false,
        isFreezedDaily: dateFreezed,
        isUnfreezeButtonDisableDaily: !dateFreezed || freezedRequestSubmitted,
        timeentrydailygrid: timeEntryData
      });
    });
  };

  getTaskList = async (projId, CRId, NPTID, projType, globleAdd, firstDay, lastDay) => {
    globleAdd === false &&
      this.setState({
        loader: true
      });
    const params = {
      firstDate: moment(firstDay).format('YYYY-MM-DD'),
      lastDate: moment(lastDay).format('YYYY-MM-DD'),
      projId: projId,
      projType: projType,
      crId: CRId,
      nptId: NPTID
    };
    await getTask(params).then(async res => {
      if (res.data.length !== 0) {
        let dataSet = [];
        await res.data.map(async task => {
          if (typeof task.ProjectType === 'undefined') {
            const allocationIds = await this.getAllocationId(
              task.NonProjectTaskAllocation,
              firstDay,
              lastDay
            );

            const proj = {
              taskType: 'NPT' /*Non Project Task*/,
              projectId: null,
              taskId: task.id,
              Project: task.TaskGroup.Name,
              Task: task.Name,
              allocations: allocationIds.allAllocationOfWeek,
              disableDateList: allocationIds.disableDateList,
              EndDate: task.EndDate,
              StartDate: task.StartDate,
              IsActive: task.IsActive,
              IsAllAlocationNotActive: allocationIds.IsAllAlocationNotActive,
              selected: false,
              isShiftTask: allocationIds.isShiftTask,
              Mon: {
                taskAllocationId: allocationIds.Mon,
                cards: []
              },
              Tue: {
                taskAllocationId: allocationIds.Tue,
                cards: []
              },
              Wed: {
                taskAllocationId: allocationIds.Wed,
                cards: []
              },
              Tur: {
                taskAllocationId: allocationIds.Thr,
                cards: []
              },
              Fri: {
                taskAllocationId: allocationIds.Fri,
                cards: []
              },
              Sat: {
                taskAllocationId: allocationIds.Sat,
                cards: []
              },
              Sun: {
                taskAllocationId: allocationIds.Sun,
                cards: []
              }
            };
            if (
              allocationIds.allAllocationOfWeek.length !== 0 &&
              allocationIds.disableDateList.length !== 7
            ) {
              dataSet.push(proj);
            }
          } else {
            if (task.ProjectType === 'ChangeRequest') {
              const allocationIds = await this.getAllocationId(
                task.ProjectTaskAllocation,
                firstDay,
                lastDay
              );
              const proj = {
                taskType: 'CRT' /*Change Request Task*/,
                projectId: null,
                taskId: task.id,
                Project: task.ChangeRequest.CRName,
                Task: task.Name,
                allocations: allocationIds.allAllocationOfWeek,
                disableDateList: allocationIds.disableDateList,
                EndDate: task.EndDate,
                StartDate: task.StartDate,
                IsActive: task.IsActive,
                IsAllAlocationNotActive: allocationIds.IsAllAlocationNotActive,
                selected: false,
                isShiftTask: allocationIds.isShiftTask,
                BillabilityTypeId: task.BillabilityTypeId,
                Mon: {
                  taskAllocationId: allocationIds.Mon,
                  cards: []
                },
                Tue: {
                  taskAllocationId: allocationIds.Tue,
                  cards: []
                },
                Wed: {
                  taskAllocationId: allocationIds.Wed,
                  cards: []
                },
                Tur: {
                  taskAllocationId: allocationIds.Thr,
                  cards: []
                },
                Fri: {
                  taskAllocationId: allocationIds.Fri,
                  cards: []
                },
                Sat: {
                  taskAllocationId: allocationIds.Sat,
                  cards: []
                },
                Sun: {
                  taskAllocationId: allocationIds.Sun,
                  cards: []
                }
              };
              if (
                allocationIds.allAllocationOfWeek.length !== 0 &&
                allocationIds.disableDateList.length !== 7
              ) {
                dataSet.push(proj);
              }
            } else if (task.ProjectType === 'Project') {
              const allocationIds = await this.getAllocationId(
                task.ProjectTaskAllocation,
                firstDay,
                lastDay
              );
              const proj = {
                taskType: 'PT' /*Project Task*/,
                projectId: null,
                taskId: task.id,
                Project: task.Project.ProjectName,
                Task: task.Name,
                allocations: allocationIds.allAllocationOfWeek,
                disableDateList: allocationIds.disableDateList,
                EndDate: task.EndDate,
                StartDate: task.StartDate,
                selected: false,
                IsActive: task.IsActive,
                IsAllAlocationNotActive: allocationIds.IsAllAlocationNotActive,
                isShiftTask: allocationIds.isShiftTask,
                BillabilityTypeId: task.BillabilityTypeId,
                Mon: {
                  taskAllocationId: allocationIds.Mon,
                  cards: []
                },
                Tue: {
                  taskAllocationId: allocationIds.Tue,
                  cards: []
                },
                Wed: {
                  taskAllocationId: allocationIds.Wed,
                  cards: []
                },
                Tur: {
                  taskAllocationId: allocationIds.Thr,
                  cards: []
                },
                Fri: {
                  taskAllocationId: allocationIds.Fri,
                  cards: []
                },
                Sat: {
                  taskAllocationId: allocationIds.Sat,
                  cards: []
                },
                Sun: {
                  taskAllocationId: allocationIds.Sun,
                  cards: []
                }
              };
              if (
                allocationIds.allAllocationOfWeek.length !== 0 &&
                allocationIds.disableDateList.length !== 7
              ) {
                dataSet.push(proj);
              }
            }
          }
        });
        if (globleAdd === false) {
          let filteredData = dataSet;
          if (this.state.selectedTask !== null) {
            filteredData = filteredData.filter(x => x.taskId === this.state.selectedTask.taskId);
          }
          this.setState(
            {
              data: filteredData,
              selectAll: false,
              isActiveEdit: false,
              isActiveDelete: false
            },
            () => {
              this.getTimeCards();
              this.getCalculatedTotals();
            }
          );
        } else {
          await this.setState({
            allData: dataSet
          });
        }
      } else {
        this.setState({
          data: [],
          monDayTotal: 0,
          tueDayTotal: 0,
          wedDayTotal: 0,
          thrDayTotal: 0,
          friDayTotal: 0,
          satDayTotal: 0,
          sunDayTotal: 0,
          totalHourOfWeek: 0,
          selectAll: false,
          loader: false
        });
      }
    });
  };

  getDurationInHMS = time => {
    const HM = time.split('.');
    return (
      HM[0] +
      ':' +
      Math.round((Number(HM[1]) * 60) / 100).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
    );
  };

  getDailyCalculatedTotals = () => {
    let proAllocations = [];
    let nptAllocations = [];
    this.state.allData.length !== 0 &&
      this.state.allData.map(task => {
        if (task.taskType === 'PT' || task.taskType === 'CRT') {
          task.allocations.length !== 0 &&
            task.allocations.map(id => {
              proAllocations.push(id);
            });
        } else {
          task.allocations.length !== 0 &&
            task.allocations.map(id => {
              nptAllocations.push(id);
            });
        }
      });

    let params = {
      proAllocations: proAllocations,
      nptAllocations: nptAllocations,
      firstDate: moment(this.state.newDailyDate).format('YYYY-MM-DD'),
      lastDate: moment(this.state.newDailyDate).format('YYYY-MM-DD')
    };
    getCalculatedTotals(params).then(res => {
      this.setState({
        totalHourOfDay: this.setTotalTime(res.data.totalOfWeek)
      });
    });
  };

  getCalculatedTotals = () => {
    let proAllocations = [];
    let nptAllocations = [];
    this.state.data.length !== 0 &&
      this.state.data.map(task => {
        if (task.taskType === 'PT' || task.taskType === 'CRT') {
          task.allocations.length !== 0 &&
            task.allocations.map(id => {
              proAllocations.push(id);
            });
        } else {
          task.allocations.length !== 0 &&
            task.allocations.map(id => {
              nptAllocations.push(id);
            });
        }
      });

    let params = {
      proAllocations: proAllocations,
      nptAllocations: nptAllocations,
      firstDate: moment(this.state.firstDayOfWeek).format('YYYY-MM-DD'),
      lastDate: moment(this.state.LastDayOfWeek).format('YYYY-MM-DD')
    };
    getCalculatedTotals(params).then(res => {
      this.setState({
        totalHourOfWeek: this.setTotalTime(res.data.totalOfWeek),
        monDayTotal: this.setTotalTime(res.data.monTotal),
        tueDayTotal: this.setTotalTime(res.data.tueTotal),
        thrDayTotal: this.setTotalTime(res.data.thuTotal),
        wedDayTotal: this.setTotalTime(res.data.wedTotal),
        friDayTotal: this.setTotalTime(res.data.friTotal),
        satDayTotal: this.setTotalTime(res.data.satTotal),
        sunDayTotal: this.setTotalTime(res.data.sunTotal),
        loader: false
      });
    });
  };

   roundUp =(num, precision)=> {
     num = parseFloat(`0.${num}`) * 60;
     num = num.toFixed(2) /10
    precision = Math.pow(10, precision)
    return (Math.ceil(num * precision) / precision)*10
  }

  setTotalTime = time => {
    let durationParts = time.toString().split('.');
    let duration = durationParts[0];
    let durMinPart = this.roundUp(durationParts[1],1)
    .toString()
    .padStart(2, '0');
    if (typeof durationParts[1] !== 'undefined') {
      if(parseInt(durMinPart) === 60){
        duration = parseInt(duration) + 1;
        durMinPart = '00';
      }else{
        durMinPart = this.roundUp(durationParts[1],1).toString().padStart(2, '0');;
      }
      duration =
        parseInt(duration) +
        ':' + durMinPart
      return duration;
    } else {
      duration = parseInt(duration) + ':' + '00';
      return duration;
    }
  };
  componentWillUnmount() {
    this.isMount = false;
  }

  setHeaderTitle = async () => {
    if (this.isMount && this.props.onHeaderTitleChange) {
      this.props.onHeaderTitleChange('Time Entry');
    }
  };

  totalHrsGrid = () => {
    const totalHrsCalcu = [
      {
        Field: 'Total Hours Per Day',
        Value1: '',
        Value2: '',
        Value3: '',
        Value4: '',
        Value5: '',
        Value6: '',
        Value7: '',
        Value8: ''
      }
    ];
    this.setState(
      {
        totalHrsCalcu: totalHrsCalcu
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  handleSelect = e => {
    this.setState({ selected: e.selected });
  };

  blurDurationInput = e => {
    const value = e.target.value.split(':');
    if (value) {
      if (
        Number(value[0]) >= 0 &&
        (Number(value[0]) < 24 ||
          (Number(value[0]) === 24 && (!value[1] || Number(value[1]) === 0)))
      ) {
        this.setState({
          duration: e.target.value
        });
      } else {
        this.setState({
          duration: '0.0'
        });
      }
    }
  };

  handleTimeCardInput = e => {
    let commentIsEmpty = false;
    let durationIsEmpty = false;
    let startDateIsEmpty = false;
    let endTimeIsEmpty = false;
    let ticketIdIsEmpty = false;
    let workLocationIsEmpty = false;
    switch (e.target.name) {
      case 'comment0':
        this.setState({
          comment: e.target.value
        });
        commentIsEmpty = e.target.value === null ? false : true;
        break;
      case 'duration0':
        const value = e.target.value.split(':');
        if (
          Number(value[0]) >= 0 &&
          (Number(value[0]) < 24 ||
            (Number(value[0]) === 24 && (!value[1] || Number(value[1]) === 0)))
        ) {
          durationIsEmpty = e.target.value === null ? false : true;
        } else {
          durationIsEmpty = true;
        }
        this.setState({
          duration: e.target.value
        });
        break;
      case 'startTime0':
        this.setState({
          startTime: e.target.value
        });
        startDateIsEmpty = e.target.value === null ? false : true;
        break;
      case 'endTime0':
        this.setState({
          endTime: e.target.value
        });
        endTimeIsEmpty = e.target.value === null ? false : true;
        break;
      case 'ticketId':
        this.setState({
          ticketId: e.target.value
        });
        ticketIdIsEmpty = e.target.value === null ? false : true;
        break;
      case 'workLocation':
        this.setState({
          workLocation: e.target.value
        });
        workLocationIsEmpty = e.target.value === null ? false : true;
        break;
      default: {
        break;
      }
    }
    if (
      commentIsEmpty === false &&
      durationIsEmpty === false &&
      startDateIsEmpty === false &&
      endTimeIsEmpty === false &&
      ticketIdIsEmpty === false &&
      workLocationIsEmpty === false
    ) {
      this.setState({
        isDirty: false,
        isTimeEntryFildEmpt: false
      });
    } else {
      this.setState({
        isDirty: true,
        isTimeEntryFildEmpt: true
      });
    }
  };

  rowClick = props => {
    const taskId = props.dataItem.taskId;
    const newData = this.state.data;
    if (newData.filter(x => x.taskId === taskId)[0].selected === true) {
      newData
        .filter(x => x.taskId === taskId)[0]
        .Mon.cards.forEach(card => {
          card.checked = false;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Tue.cards.forEach(card => {
          card.checked = false;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Wed.cards.forEach(card => {
          card.checked = false;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Tur.cards.forEach(card => {
          card.checked = false;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Fri.cards.forEach(card => {
          card.checked = false;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Sat.cards.forEach(card => {
          card.checked = false;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Sun.cards.forEach(card => {
          card.checked = false;
        });
      newData.filter(x => x.taskId === taskId)[0].selected = false;

      this.setState(
        {
          data: newData
        },
        () => {
          let selectedList = this.getSelectedCard(newData);
          if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
            this.setState({
              isActiveEdit: true
            });
          } else {
            this.setState({
              isActiveEdit: false
            });
          }
          this.deleteButtonHandler(selectedList);
        }
      );
    } else {
      newData
        .filter(x => x.taskId === taskId)[0]
        .Mon.cards.forEach(card => {
          card.checked = true;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Tue.cards.forEach(card => {
          card.checked = true;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Wed.cards.forEach(card => {
          card.checked = true;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Tur.cards.forEach(card => {
          card.checked = true;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Fri.cards.forEach(card => {
          card.checked = true;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Sat.cards.forEach(card => {
          card.checked = true;
        });
      newData
        .filter(x => x.taskId === taskId)[0]
        .Sun.cards.forEach(card => {
          card.checked = true;
        });
      newData.filter(x => x.taskId === taskId)[0].selected = true;

      this.setState(
        {
          data: newData
        },
        () => {
          let selectedList = this.getSelectedCard(newData);
          if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
            this.setState({
              isActiveEdit: true
            });
          } else {
            this.setState({
              isActiveEdit: false
            });
          }
          this.deleteButtonHandler(selectedList);
          if (
            this.state.isFreezeDateOfMon ||
            this.state.isFreezeDateOfTue ||
            this.state.isFreezeDateOfWed ||
            this.state.isFreezeDateOfThu ||
            this.state.isFreezeDateOfFri ||
            this.state.isFreezeDateOfSat ||
            this.state.isFreezeDateOfSun
          ) {
            this.setState({
              isFreeze: true
            });
          }
        }
      );
    }
  };

  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering
    };
  }

  addTimeEntyHandler = async event => {
    event.preventDefault();
    if (
      (this.state.comment === null || this.state.comment === '') &&
      (this.state.duration === null || this.state.duration === '' || this.state.duration === 0) &&
      (this.state.startDate === null || typeof this.state.startDate === 'undefined') &&
      (this.state.endTime === null || typeof this.state.startDate === 'undefined') &&
      this.state.timeEntryData.length !== 0
    ) {
      this.setState({
        loader: true
      });
      var cardList = [];
      this.state.timeEntryData.forEach(timeEntry => {
        let timeRegExWithColon = /^(0?[0-9]|[0-9][0-9]):[0-5][0-9]$/;
        let timeRegExWithOutColon = /^\d{1,2}(\.\d{1,2})?$/;
        let duration = null;
        if (timeRegExWithColon.test(timeEntry.duration)) {
          let times = timeEntry.duration.split(':');
          duration = parseFloat(parseFloat(times[0]) + parseFloat(times[1] / 60)).toFixed(5);
        } else if (timeRegExWithOutColon.test(timeEntry.duration)) {
          duration = timeEntry.duration;
        }
        const card = {
          ProjectTaskAllocationId: this.state.currentProjectTaskAllocationId,
          NonProjectTaskAllocationId: this.state.currentNonProjectTaskAllocationId,
          Comment: timeEntry.comment,
          Duration: duration,
          FromDateTime: timeEntry.startTime === 'hour:minute AM' ? null : timeEntry.startTime,
          ToDateTime: timeEntry.endTime === 'hour:minute AM' ? null : timeEntry.endTime,
          ApprovedStatus: this.state.approvedStatusType[0].value,
          ApprovedBy: null,
          ApprovedNonBillingCatagoryId: null,
          EntryFreezeStatus: null,
          ApproveFreezeStatus: null,
          LogDateTime: moment(this.state.SelectedCurrentDate).format('YYYY-MM-DD'),
          RejectReason: null,
          TicketId: timeEntry.TicketId,
          WorkLocationId: timeEntry.WorkLocationId ? timeEntry.WorkLocationId : null
        };
        cardList.push(card);
      });
      await saveTimeEntry(cardList).then(res => {
        this.getTimeCards();
        this.getCalculatedTotals();
        this.setState({
          timeEntryEditMode: false,
          timeEntryAddMode: false,
          isActiveEdit: false,
          isActiveDelete: false,
          currentProject: null,
          timeEntryData: []
        });
      });

      if (!this.state.addTimeentrydaily) {
        this.toggleAddTimeentry(0, null, null, null, null, false, false, false, null, null, false);
      } else {
        this.setState({ addTimeentrydaily: false });
        this.onRunButtonClick(this.state.newDailyDate, false);
      }
    } else if (
      (this.state.comment !== null || !this.state.comment !== '') &&
      ((this.state.duration !== null || this.state.duration !== '' || this.state.duration !== 0) &&
        this.state.timeEntryData.length === 0)
    ) {
      if (!this.validation()) {
        this.setState({
          isErrorMessageVisible: true
        });
      } else if (
        this.validateGlobleAddDatePicker(
          this.state.currentTaskInDropDown.disableDateList,
          this.state.SelectedCurrentDate
        )
          .toString()
          .includes('error')
      ) {
        this.setState({
          isDateErrorMessageVisible: true
        });
      } else if (!this.validateTicketId()) {
        this.setState({
          isTicketIdErrorMessageVisible: true
        });
      } else if (!this.validateWorkLocation()) {
        this.setState({
          isLocationErrorMessageVisible: true
        });
      } else {
        this.setState({
          loader: true
        });
        var cardList = [];
        let timeRegExWithColon = /^(0?[0-9]|[0-9][0-9]):[0-5][0-9]$/;
        let timeRegExWithOutColon = /^\d{1,2}(\.\d{1,2})?$/;
        let duration = null;
        if (timeRegExWithColon.test(this.state.duration)) {
          let times = this.state.duration.split(':');
          duration = parseFloat(parseFloat(times[0]) + parseFloat(times[1] / 60)).toFixed(5);
        } else if (timeRegExWithOutColon.test(this.state.duration)) {
          duration = this.state.duration;
        }
        const card = {
          ProjectTaskAllocationId: this.state.currentProjectTaskAllocationId,
          NonProjectTaskAllocationId: this.state.currentNonProjectTaskAllocationId,
          Comment: this.state.comment,
          Duration: duration,
          FromDateTime:
            this.state.startTime === 'hour:minute AM' || this.state.startTime === null
              ? null
              : moment(this.state.startTime).format('LT'),
          ToDateTime:
            this.state.endTime === 'hour:minute AM' || this.state.startTime === null
              ? null
              : moment(this.state.endTime).format('LT'),
          ApprovedStatus: this.state.approvedStatusType[0].value,
          ApprovedBy: null,
          ApprovedNonBillingCatagoryId: null,
          EntryFreezeStatus: null,
          ApproveFreezeStatus: null,
          LogDateTime: moment(this.state.SelectedCurrentDate).format('YYYY-MM-DD'),
          RejectReason: null,
          TicketId: this.state.ticketId,
          WorkLocationId: this.state.workLocation ? this.state.workLocation.value : null
        };
        cardList.push(card);
        await saveTimeEntry(cardList).then(res => {
          this.getTimeCards();
          this.getCalculatedTotals();
          this.setState({
            timeEntryEditMode: false,
            timeEntryAddMode: false,
            isActiveEdit: false,
            isActiveDelete: false,
            currentProject: null,
            timeEntryData: []
          });
        });
        if (!this.state.addTimeentrydaily) {
          this.toggleAddTimeentry(
            0,
            null,
            null,
            null,
            null,
            false,
            false,
            false,
            null,
            null,
            false
          );
        } else {
          this.setState({ addTimeentrydaily: false });
          this.onRunButtonClick(this.state.newDailyDate, false);
        }
      }
    } else if (
      (this.state.comment !== null || this.state.comment !== '') &&
      ((this.state.duration !== null || this.state.duration !== '' || this.state.duration !== 0) &&
        this.state.timeEntryData.length !== 0)
    ) {
      if (!this.validation()) {
        this.setState({
          isErrorMessageVisible: true
        });
      } else if (
        this.validateGlobleAddDatePicker(
          this.state.currentTaskInDropDown.disableDateList,
          this.state.SelectedCurrentDate
        )
          .toString()
          .includes('error')
      ) {
        this.setState({
          isDateErrorMessageVisible: true
        });
      } else if (!this.validateTicketId()) {
        this.setState({
          isTicketIdErrorMessageVisible: true
        });
      } else if (!this.validateWorkLocation()) {
        this.setState({
          isLocationErrorMessageVisible: true
        });
      } else {
        this.setState({
          loader: true
        });
        var cardList = [];
        let timeRegExWithColonOnField = /^(0?[0-9]|1[0-2]):[0-5][0-9]$/;
        let timeRegExWithOutColonOnField = /^\d{1,2}(\.\d{1,2})?$/;
        let durationOnField = null;
        if (timeRegExWithColonOnField.test(this.state.duration)) {
          let times = this.state.duration.split(':');
          durationOnField = parseFloat(parseFloat(times[0]) + parseFloat(times[1] / 60)).toFixed(5);
        } else if (timeRegExWithOutColonOnField.test(this.state.duration)) {
          durationOnField = this.state.duration;
        }
        const CardOnField = {
          ProjectTaskAllocationId: this.state.currentProjectTaskAllocationId,
          NonProjectTaskAllocationId: this.state.currentNonProjectTaskAllocationId,
          Comment: this.state.comment,
          Duration: durationOnField,
          FromDateTime:
            this.state.startTime === 'hour:minute AM' || this.state.startTime === null
              ? null
              : moment(this.state.startTime).format('LT'),
          ToDateTime:
            this.state.endTime === 'hour:minute AM' || this.state.startTime === null
              ? null
              : moment(this.state.endTime).format('LT'),
          ApprovedStatus: this.state.approvedStatusType[0].value,
          ApprovedBy: null,
          ApprovedNonBillingCatagoryId: null,
          EntryFreezeStatus: null,
          ApproveFreezeStatus: null,
          LogDateTime: moment(this.state.SelectedCurrentDate).format('YYYY-MM-DD'),
          RejectReason: null,
          TicketId: this.state.ticketId,
          WorkLocationId: this.state.workLocation ? this.state.workLocation.value : null
        };
        cardList.push(CardOnField);
        this.state.timeEntryData.forEach(timeEntry => {
          let timeRegExWithColon = /^(0?[0-9]|[0-9][0-9]):[0-5][0-9]$/;
          let timeRegExWithOutColon = /^\d{1,2}(\.\d{1,2})?$/;
          let duration = null;
          if (timeRegExWithColon.test(timeEntry.duration)) {
            let times = timeEntry.duration.split(':');
            duration = parseFloat(parseFloat(times[0]) + parseFloat(times[1] / 60)).toFixed(5);
          } else if (timeRegExWithOutColon.test(timeEntry.duration)) {
            duration = timeEntry.duration;
          }
          const card = {
            ProjectTaskAllocationId: this.state.currentProjectTaskAllocationId,
            NonProjectTaskAllocationId: this.state.currentNonProjectTaskAllocationId,
            Comment: timeEntry.comment,
            Duration: duration,
            FromDateTime: timeEntry.startTime === 'hour:minute AM' ? null : timeEntry.startTime,
            ToDateTime: timeEntry.endTime === 'hour:minute AM' ? null : timeEntry.endTime,
            ApprovedStatus: this.state.approvedStatusType[0].value,
            ApprovedBy: null,
            ApprovedNonBillingCatagoryId: null,
            EntryFreezeStatus: null,
            ApproveFreezeStatus: null,
            LogDateTime: moment(this.state.SelectedCurrentDate).format('YYYY-MM-DD'),
            RejectReason: null,
            TicketId: timeEntry.TicketId,
            WorkLocationId: timeEntry.WorkLocationId ? timeEntry.WorkLocationId : null
          };
          cardList.push(card);
        });
        saveTimeEntry(cardList).then(res => {
          this.getTimeCards();
          this.getCalculatedTotals();
          this.setState({
            timeEntryEditMode: false,
            timeEntryAddMode: false,
            isActiveEdit: false,
            isActiveDelete: false,
            currentProject: null,
            timeEntryData: []
          });
        });
        if (!this.state.addTimeentrydaily) {
          this.toggleAddTimeentry(
            0,
            null,
            null,
            null,
            null,
            false,
            false,
            false,
            null,
            null,
            false
          );
        } else {
          this.setState({ addTimeentrydaily: false });
          this.onRunButtonClick(this.state.newDailyDate, false);
        }
      }
    }
  };

  UpdateTimeEntyHandler = async event => {
    event.preventDefault();
    if (!this.validation()) {
      this.setState({
        isErrorMessageVisible: true
      });
    } else if (!this.validateTicketId()) {
      this.setState({
        isTicketIdErrorMessageVisible: true
      });
     } else if (!this.validateWorkLocation()) {
      this.setState({
        isLocationErrorMessageVisible: true
      });
     } else {
      this.setState({
        loader: true
      });
      var target = event.target;
      let timeRegExWithColon = /^(0?[0-9]|[0-9][0-9]):[0-5][0-9]$/;
      let timeRegExWithOutColon = /^\d{1,2}(\.\d{1,2})?$/;
      let duration = null;
      if (timeRegExWithColon.test(target.duration0.value)) {
        let times = target.duration0.value.split(':');
        duration = parseFloat(parseFloat(times[0]) + parseFloat(times[1] / 60)).toFixed(5);
      } else if (timeRegExWithOutColon.test(target.duration0.value)) {
        duration = target.duration0.value;
      }
      // submit card
      const card = {
        ProjectTaskAllocationId: this.state.currentProjectTaskAllocationId,
        NonProjectTaskAllocationId: this.state.currentNonProjectTaskAllocationId,
        Comment: target.comment0.value,
        Duration: duration,
        FromDateTime: target.startTime0.value === 'hour:minute' ? null : target.startTime0.value,
        ToDateTime: target.endTime0.value === 'hour:minute' ? null : target.endTime0.value,
        ApprovedStatus: this.state.approvedStatusType[0].value,
        ApprovedBy: null,
        ApprovedNonBillingCatagoryId: null,
        EntryFreezeStatus: null,
        ApproveFreezeStatus: null,
        LogDateTime: moment(this.state.SelectedCurrentDate).format('YYYY-MM-DD'),
        RejectReason: null,
        LastApprovedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        TicketId: this.state.ticketId,
        WorkLocationId: this.state.workLocation ? this.state.workLocation.value : null
      };
      UpdateTimeEntry(card, this.state.currentTimeCardId).then(res => {
        this.getTimeCards();
        this.getCalculatedTotals();
        this.setState({
          timeEntryEditMode: false,
          timeEntryAddMode: false,
          currentProject: null,
          isActiveEdit: false,
          isActiveDelete: false
        });
      });
      if (!this.state.addTimeentrydaily) {
        this.toggleAddTimeentry(0, null, null, null, null, false, false, false, null, null, false);
      } else {
        this.setState({ addTimeentrydaily: false });
        this.onRunButtonClick(this.state.newDailyDate, false);
      }
    }
  };

  deleteButtonHandler = selectedList => {
    if (selectedList.length >= 1) {
      const checkApprovedStatus = selectedList.some(
        val => val.approvedStatus === 2 || val.approvedStatus === 3 || val.approvedStatus === 5
      );
      if (checkApprovedStatus === true) {
        this.setState({
          isActiveDelete: false
        });
      } else {
        this.setState({
          isActiveDelete: true
        });
      }
      const checkFreezeStatus = selectedList.some(val => val.isFreeze === true);
      if (checkFreezeStatus === true) {
        this.setState({
          isFreeze: true
        });
      } else {
        this.setState({
          isFreeze: false
        });
      }
      if (selectedList.length !== 1) {
        this.setState({
          isActiveEdit: false
        });
      }
    } else {
      this.setState({
        isActiveDelete: false
      });
    }
  };

  checkeBoxClickHandler = async (index, taskId, day, data) => {
    let trueVal = 0;
    let noOfCards = 0;
    const newData = this.state.data;
    switch (day) {
      case 'Mon':
        {
          this.state.data.forEach(task => {
            noOfCards = noOfCards + task.Mon.cards.length;
          });
          this.setState(
            () => {
              let chekedCard = newData.filter(
                x => x.taskId === taskId && x.taskType === data.taskType
              )[0].Mon.cards[index];
              newData.filter(x => x.taskId === taskId && x.taskType === data.taskType)[0].Mon.cards[
                index
              ].checked = !chekedCard.checked;
              let selectedList = this.getSelectedCard(newData);
              this.deleteButtonHandler(selectedList);
              if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
                let sampleDate = '2018-12-24';
                let startTime =
                  selectedList[0].startTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].startTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let endTime =
                  selectedList[0].endTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].endTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let selectedDay = moment(selectedList[0].LogDateTime).format('ddd');
                this.setState({
                  isActiveEdit: true,
                  isShiftTask: selectedList[0].isShiftTask,
                  currentProjectId: data.projectId,
                  currentTaskId: selectedList[0].taskId,
                  currentDay: selectedDay,
                  currentProject: selectedList[0].project,
                  currentNonProjectTaskAllocationId: data.AllocationId,
                  currentTask: selectedList[0].task,
                  currTaskType: selectedList[0].taskType,
                  comment: selectedList[0].comment,
                  ticketId: selectedList[0].TicketId,
                  workLocation: selectedList[0].WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == selectedList[0].WorkLocationId)[0] : null,
                  duration: selectedList[0].duration,
                  startTime: startTime,
                  endTime: endTime,
                  currentTimeCardId: selectedList[0].cardId
                });
              } else {
                this.setState({
                  isActiveEdit: false,
                  isShiftTask: false
                });
              }
              return {
                data: newData
              };
            },
            () => {
              this.state.data.forEach(async task => {
                task.Mon.cards.forEach(task => {
                  if (task.checked === true) {
                    trueVal = trueVal + 1;
                  } else {
                    this.setState({
                      isAllSelectedMon: false
                    });
                  }
                });
              });
              if ((trueVal === noOfCards) & (trueVal !== 0)) {
                this.setState({
                  isAllSelectedMon: true
                });
              } else {
                this.setState({
                  isAllSelectedMon: false
                });
              }
            }
          );
        }

        break;
      case 'Tue':
        {
          this.state.data.forEach(task => {
            noOfCards = noOfCards + task.Tue.cards.length;
          });
          this.setState(
            prevState => {
              let chekedCard = newData.filter(
                x => x.taskId === taskId && x.taskType === data.taskType
              )[0].Tue.cards[index];
              newData.filter(x => x.taskId === taskId && x.taskType === data.taskType)[0].Tue.cards[
                index
              ].checked = !chekedCard.checked;
              let selectedList = this.getSelectedCard(newData);
              this.deleteButtonHandler(selectedList);
              if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
                let sampleDate = '2018-12-24';
                let startTime =
                  selectedList[0].startTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].startTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let endTime =
                  selectedList[0].endTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].endTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let selectedDay = moment(selectedList[0].LogDateTime).format('ddd');
                this.setState({
                  isActiveEdit: true,
                  isShiftTask: selectedList[0].isShiftTask,
                  currentProjectId: data.projectId,
                  currentTaskId: selectedList[0].taskId,
                  currentDay: selectedDay,
                  currentProject: selectedList[0].project,
                  currentNonProjectTaskAllocationId: data.AllocationId,
                  currentTask: selectedList[0].task,
                  currTaskType: selectedList[0].taskType,
                  comment: selectedList[0].comment,
                  ticketId: selectedList[0].TicketId,
                  workLocation: selectedList[0].WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == selectedList[0].WorkLocationId)[0] : null,
                  duration: selectedList[0].duration,
                  startTime: startTime,
                  endTime: endTime,
                  currentTimeCardId: selectedList[0].cardId
                });
              } else {
                this.setState({
                  isActiveEdit: false,
                  isShiftTask: false
                });
              }
              return {
                data: newData
              };
            },
            () => {
              this.state.data.forEach(async task => {
                task.Tue.cards.forEach(task => {
                  if (task.checked === true) {
                    trueVal = trueVal + 1;
                  } else {
                    this.setState({
                      isAllSelectedTue: false
                    });
                  }
                });
              });
              if ((trueVal === noOfCards) & (trueVal !== 0)) {
                this.setState({
                  isAllSelectedTue: true
                });
              } else {
                this.setState({
                  isAllSelectedTue: false
                });
              }
            }
          );
        }
        break;
      case 'Wed':
        {
          this.state.data.forEach(task => {
            noOfCards = noOfCards + task.Wed.cards.length;
          });
          this.setState(
            prevState => {
              let chekedCard = newData.filter(
                x => x.taskId === taskId && x.taskType === data.taskType
              )[0].Wed.cards[index];
              newData.filter(x => x.taskId === taskId && x.taskType === data.taskType)[0].Wed.cards[
                index
              ].checked = !chekedCard.checked;
              let selectedList = this.getSelectedCard(newData);
              this.deleteButtonHandler(selectedList);
              if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
                let sampleDate = '2018-12-24';
                let startTime =
                  selectedList[0].startTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].startTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let endTime =
                  selectedList[0].endTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].endTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let selectedDay = moment(selectedList[0].LogDateTime).format('ddd');
                this.setState({
                  isActiveEdit: true,
                  isShiftTask: selectedList[0].isShiftTask,
                  currentProjectId: data.projectId,
                  currentTaskId: selectedList[0].taskId,
                  currentDay: selectedDay,
                  currentProject: selectedList[0].project,
                  currentNonProjectTaskAllocationId: data.AllocationId,
                  currentTask: selectedList[0].task,
                  currTaskType: selectedList[0].taskType,
                  comment: selectedList[0].comment,
                  ticketId: selectedList[0].TicketId,
                  workLocation: selectedList[0].WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == selectedList[0].WorkLocationId)[0] : null,
                  duration: selectedList[0].duration,
                  startTime: startTime,
                  endTime: endTime,
                  currentTimeCardId: selectedList[0].cardId
                });
              } else {
                this.setState({
                  isActiveEdit: false,
                  isShiftTask: false
                });
              }
              return {
                data: newData
              };
            },
            () => {
              this.state.data.forEach(async task => {
                task.Wed.cards.forEach(task => {
                  if (task.checked === true) {
                    trueVal = trueVal + 1;
                  } else {
                    this.setState({
                      isAllSelectedWed: false
                    });
                  }
                });
              });
              if ((trueVal === noOfCards) & (trueVal !== 0)) {
                this.setState({
                  isAllSelectedWed: true
                });
              } else {
                this.setState({
                  isAllSelectedWed: false
                });
              }
            }
          );
        }
        break;
      case 'Tur':
        {
          this.state.data.forEach(task => {
            noOfCards = noOfCards + task.Tur.cards.length;
          });
          this.setState(
            prevState => {
              let chekedCard = newData.filter(
                x => x.taskId === taskId && x.taskType === data.taskType
              )[0].Tur.cards[index];
              newData.filter(x => x.taskId === taskId && x.taskType === data.taskType)[0].Tur.cards[
                index
              ].checked = !chekedCard.checked;
              let selectedList = this.getSelectedCard(newData);
              this.deleteButtonHandler(selectedList);
              if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
                let sampleDate = '2018-12-24';
                let startTime =
                  selectedList[0].startTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].startTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let endTime =
                  selectedList[0].endTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].endTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let selectedDay = moment(selectedList[0].LogDateTime).format('ddd');
                this.setState({
                  isActiveEdit: true,
                  isShiftTask: selectedList[0].isShiftTask,
                  currentProjectId: data.projectId,
                  currentTaskId: selectedList[0].taskId,
                  currentDay: selectedDay,
                  currentProject: selectedList[0].project,
                  currentNonProjectTaskAllocationId: data.AllocationId,
                  currentTask: selectedList[0].task,
                  currTaskType: selectedList[0].taskType,
                  comment: selectedList[0].comment,
                  ticketId: selectedList[0].TicketId,
                  workLocation: selectedList[0].WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == selectedList[0].WorkLocationId)[0] : null,
                  duration: selectedList[0].duration,
                  startTime: startTime,
                  endTime: endTime,
                  currentTimeCardId: selectedList[0].cardId
                });
              } else {
                this.setState({
                  isActiveEdit: false,
                  isShiftTask: false
                });
              }
              return {
                data: newData
              };
            },
            () => {
              this.state.data.forEach(async task => {
                task.Tur.cards.forEach(task => {
                  if (task.checked === true) {
                    trueVal = trueVal + 1;
                  } else {
                    this.setState({
                      isAllSelectedTur: false
                    });
                  }
                });
              });
              if ((trueVal === noOfCards) & (trueVal !== 0)) {
                this.setState({
                  isAllSelectedTur: true
                });
              } else {
                this.setState({
                  isAllSelectedTur: false
                });
              }
            }
          );
        }
        break;
      case 'Fri':
        {
          this.state.data.forEach(task => {
            noOfCards = noOfCards + task.Fri.cards.length;
          });
          this.setState(
            prevState => {
              let chekedCard = newData.filter(
                x => x.taskId === taskId && x.taskType === data.taskType
              )[0].Fri.cards[index];
              newData.filter(x => x.taskId === taskId && x.taskType === data.taskType)[0].Fri.cards[
                index
              ].checked = !chekedCard.checked;
              let selectedList = this.getSelectedCard(newData);
              this.deleteButtonHandler(selectedList);
              if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
                let sampleDate = '2018-12-24';
                let startTime =
                  selectedList[0].startTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].startTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let endTime =
                  selectedList[0].endTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].endTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let selectedDay = moment(selectedList[0].LogDateTime).format('ddd');
                this.setState({
                  isActiveEdit: true,
                  isShiftTask: selectedList[0].isShiftTask,
                  currentProjectId: data.projectId,
                  currentTaskId: selectedList[0].taskId,
                  currentDay: selectedDay,
                  currentProject: selectedList[0].project,
                  currentNonProjectTaskAllocationId: data.AllocationId,
                  currentTask: selectedList[0].task,
                  currTaskType: selectedList[0].taskType,
                  comment: selectedList[0].comment,
                  ticketId: selectedList[0].TicketId,
                  workLocation: selectedList[0].WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == selectedList[0].WorkLocationId)[0] : null,
                  duration: selectedList[0].duration,
                  startTime: startTime,
                  endTime: endTime,
                  currentTimeCardId: selectedList[0].cardId
                });
              } else {
                this.setState({
                  isActiveEdit: false,
                  isShiftTask: false
                });
              }
              return {
                data: newData
              };
            },
            () => {
              this.state.data.forEach(async task => {
                task.Fri.cards.forEach(task => {
                  if (task.checked === true) {
                    trueVal = trueVal + 1;
                  } else {
                    this.setState({
                      isAllSelectedFri: false
                    });
                  }
                });
              });
              if ((trueVal === noOfCards) & (trueVal !== 0)) {
                this.setState({
                  isAllSelectedFri: true
                });
              } else {
                this.setState({
                  isAllSelectedFri: false
                });
              }
            }
          );
        }
        break;
      case 'Sat':
        {
          this.state.data.forEach(task => {
            noOfCards = noOfCards + task.Sat.cards.length;
          });
          this.setState(
            prevState => {
              let chekedCard = newData.filter(
                x => x.taskId === taskId && x.taskType === data.taskType
              )[0].Sat.cards[index];
              newData.filter(x => x.taskId === taskId && x.taskType === data.taskType)[0].Sat.cards[
                index
              ].checked = !chekedCard.checked;
              let selectedList = this.getSelectedCard(newData);
              this.deleteButtonHandler(selectedList);
              if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
                let sampleDate = '2018-12-24';
                let startTime =
                  selectedList[0].startTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].startTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let endTime =
                  selectedList[0].endTime !== null
                    ? new Date(
                        moment(
                          `${sampleDate} ${selectedList[0].endTime}`,
                          'YYYY-MM-DD HH:mm:ss'
                        ).format()
                      )
                    : null;
                let selectedDay = moment(selectedList[0].LogDateTime).format('ddd');
                this.setState({
                  isActiveEdit: true,
                  isShiftTask: selectedList[0].isShiftTask,
                  currentProjectId: data.projectId,
                  currentTaskId: selectedList[0].taskId,
                  currentDay: selectedDay,
                  currentProject: selectedList[0].project,
                  currentNonProjectTaskAllocationId: data.AllocationId,
                  currentTask: selectedList[0].task,
                  currTaskType: selectedList[0].taskType,
                  comment: selectedList[0].comment,
                  ticketId: selectedList[0].TicketId,
                  workLocation: selectedList[0].WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == selectedList[0].WorkLocationId)[0] : null,
                  duration: selectedList[0].duration,
                  startTime: startTime,
                  endTime: endTime,
                  currentTimeCardId: selectedList[0].cardId
                });
              } else {
                this.setState({
                  isActiveEdit: false,
                  isShiftTask: false
                });
              }
              return {
                data: newData
              };
            },
            () => {
              this.state.data.forEach(async task => {
                task.Sat.cards.forEach(task => {
                  if (task.checked === true) {
                    trueVal = trueVal + 1;
                  } else {
                    this.setState({
                      isAllSelectedSat: false
                    });
                  }
                });
              });
              if ((trueVal === noOfCards) & (trueVal !== 0)) {
                this.setState({
                  isAllSelectedSat: true
                });
              } else {
                this.setState({
                  isAllSelectedSat: false
                });
              }
            }
          );
        }
        break;
      case 'Sun': {
        this.state.data.forEach(task => {
          noOfCards = noOfCards + task.Sun.cards.length;
        });
        this.setState(
          prevState => {
            let chekedCard = newData.filter(
              x => x.taskId === taskId && x.taskType === data.taskType
            )[0].Sun.cards[index];
            newData.filter(x => x.taskId === taskId && x.taskType === data.taskType)[0].Sun.cards[
              index
            ].checked = !chekedCard.checked;
            let selectedList = this.getSelectedCard(newData);
            this.deleteButtonHandler(selectedList);
            if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
              let sampleDate = '2018-12-24';
              let startTime =
                selectedList[0].startTime !== null
                  ? new Date(
                      moment(
                        `${sampleDate} ${selectedList[0].startTime}`,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format()
                    )
                  : null;
              let endTime =
                selectedList[0].endTime !== null
                  ? new Date(
                      moment(
                        `${sampleDate} ${selectedList[0].endTime}`,
                        'YYYY-MM-DD HH:mm:ss'
                      ).format()
                    )
                  : null;
              let selectedDay = moment(selectedList[0].LogDateTime).format('ddd');
              this.setState({
                isActiveEdit: true,
                isShiftTask: selectedList[0].isShiftTask,
                currentProjectId: data.projectId,
                currentTaskId: selectedList[0].taskId,
                currentDay: selectedDay,
                currentNonProjectTaskAllocationId: data.AllocationId,
                currentProject: selectedList[0].project,
                currTaskType: selectedList[0].taskType,
                currentTask: selectedList[0].task,
                comment: selectedList[0].comment,
                ticketId: selectedList[0].TicketId,
                workLocation: selectedList[0].WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == selectedList[0].WorkLocationId)[0] : null,
                duration: selectedList[0].duration,
                startTime: startTime,
                endTime: endTime,
                currentTimeCardId: selectedList[0].cardId
              });
            } else {
              this.setState({
                isActiveEdit: false,
                isShiftTask: false
              });
            }
            return {
              data: newData
            };
          },
          () => {
            this.state.data.forEach(async task => {
              task.Sun.cards.forEach(task => {
                if (task.checked === true) {
                  trueVal = trueVal + 1;
                } else {
                  this.setState({
                    isAllSelectedSun: false
                  });
                }
              });
            });
            if ((trueVal === noOfCards) & (trueVal !== 0)) {
              this.setState({
                isAllSelectedSun: true
              });
            } else {
              this.setState({
                isAllSelectedSun: false
              });
            }
          }
        );
      }
    }
    this.setUnfreezeButtonEnableDisable();
  };

  getSelectedCard = data => {
    let selectedList = [];
    data.forEach(task => {
      task.Mon.cards.length !== 0 &&
        task.Mon.cards.forEach(card => {
          if (card.checked === true) {
            card['isFreeze'] = this.state.isFreezeDateOfMon;
            selectedList.push(card);
          }
        });
      task.Tue.cards.length !== 0 &&
        task.Tue.cards.forEach(card => {
          if (card.checked === true) {
            card['isFreeze'] = this.state.isFreezeDateOfTue;
            selectedList.push(card);
          }
        });
      task.Wed.cards.length !== 0 &&
        task.Wed.cards.forEach(card => {
          if (card.checked === true) {
            card['isFreeze'] = this.state.isFreezeDateOfWed;
            selectedList.push(card);
          }
        });
      task.Tur.cards.length !== 0 &&
        task.Tur.cards.forEach(card => {
          if (card.checked === true) {
            card['isFreeze'] = this.state.isFreezeDateOfThu;
            selectedList.push(card);
          }
        });
      task.Fri.cards.length !== 0 &&
        task.Fri.cards.forEach(card => {
          if (card.checked === true) {
            card['isFreeze'] = this.state.isFreezeDateOfFri;
            selectedList.push(card);
          }
        });
      task.Sat.cards.length !== 0 &&
        task.Sat.cards.forEach(card => {
          if (card.checked === true) {
            card['isFreeze'] = this.state.isFreezeDateOfSat;
            selectedList.push(card);
          }
        });
      task.Sun.cards.length !== 0 &&
        task.Sun.cards.forEach(card => {
          if (card.checked === true) {
            card['isFreeze'] = this.state.isFreezeDateOfSun;
            selectedList.push(card);
          }
        });
    });
    return selectedList;
  };

  selectAllDay = async day => {
    // mon = 1,
    // tue = 2,
    // wed = 3,
    // tur = 4
    // fri = 5,
    // sat = 6,
    // sun = 7
    this.setState({
      loader: true
    });
    if (day === 1) {
      await this.setState({
        isAllSelectedMon: !this.state.isAllSelectedMon
      });
      if (this.state.isAllSelectedMon === false) {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Mon.cards.forEach(card => {
            card.checked = false;
          });
        });
        this.setState({
          isActiveEdit: false
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
      } else {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Mon.cards.forEach(card => {
            card.checked = true;
          });
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
        if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
          this.setState({
            isActiveEdit: true
          });
        } else {
          this.setState({
            isActiveEdit: false
          });
        }
      }
    } else if (day === 2) {
      await this.setState({
        isAllSelectedTue: !this.state.isAllSelectedTue
      });
      if (this.state.isAllSelectedTue === false) {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Tue.cards.forEach(card => {
            card.checked = false;
          });
        });
        this.setState({
          isActiveEdit: false
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
      } else {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Tue.cards.forEach(card => {
            card.checked = true;
          });
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
        if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
          this.setState({
            isActiveEdit: true
          });
        } else {
          this.setState({
            isActiveEdit: false
          });
        }
      }
    } else if (day === 3) {
      await this.setState({
        isAllSelectedWed: !this.state.isAllSelectedWed
      });
      if (this.state.isAllSelectedWed === false) {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Wed.cards.forEach(card => {
            card.checked = false;
          });
        });
        this.setState({
          isActiveEdit: false
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
      } else {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Wed.cards.forEach(card => {
            card.checked = true;
          });
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
        if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
          this.setState({
            isActiveEdit: true
          });
        } else {
          this.setState({
            isActiveEdit: false
          });
        }
      }
    } else if (day === 4) {
      await this.setState({
        isAllSelectedTur: !this.state.isAllSelectedTur
      });
      if (this.state.isAllSelectedTur === false) {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Tur.cards.forEach(card => {
            card.checked = false;
          });
        });
        this.setState({
          isActiveEdit: false
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
      } else {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Tur.cards.forEach(card => {
            card.checked = true;
          });
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
        if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
          this.setState({
            isActiveEdit: true
          });
        } else {
          this.setState({
            isActiveEdit: false
          });
        }
      }
    } else if (day === 5) {
      await this.setState({
        isAllSelectedFri: !this.state.isAllSelectedFri
      });
      if (this.state.isAllSelectedFri === false) {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Fri.cards.forEach(card => {
            card.checked = false;
          });
        });
        this.setState({
          isActiveEdit: false
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
      } else {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Fri.cards.forEach(card => {
            card.checked = true;
          });
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
        if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
          this.setState({
            isActiveEdit: true
          });
        } else {
          this.setState({
            isActiveEdit: false
          });
        }
      }
    } else if (day === 6) {
      await this.setState({
        isAllSelectedSat: !this.state.isAllSelectedSat
      });
      if (this.state.isAllSelectedSat === false) {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Sat.cards.forEach(card => {
            card.checked = false;
          });
        });
        this.setState({
          isActiveEdit: false
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
      } else {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Sat.cards.forEach(card => {
            card.checked = true;
          });
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
        if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
          this.setState({
            isActiveEdit: true
          });
        } else {
          this.setState({
            isActiveEdit: false
          });
        }
      }
    } else if (day === 7) {
      await this.setState({
        isAllSelectedSun: !this.state.isAllSelectedSun
      });
      this.setState({
        isActiveEdit: false
      });
      if (this.state.isAllSelectedSun === false) {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Sun.cards.forEach(card => {
            card.checked = false;
          });
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
      } else {
        const newData = this.state.data;
        newData.forEach(task => {
          task.Sun.cards.forEach(card => {
            card.checked = true;
          });
        });
        let selectedList = this.getSelectedCard(newData);
        this.deleteButtonHandler(selectedList);
        if (selectedList.length === 1 && selectedList[0].approvedStatus === 1) {
          this.setState({
            isActiveEdit: true
          });
        } else {
          this.setState({
            isActiveEdit: false
          });
        }
      }
    }
    await this.setUnfreezeButtonEnableDisable();
    this.setState({
      loader: false
    });
  };
  projectDropDownHandler = event => {
    this.setState(
      {
        selectedProjectOrCr: event.target.value,
        selectedTask: null
      },
      () => {
        if (event.target.value !== null) {
          if (event.target.value.Category === 'PT') {
            this.getDataForTaskFilter(event.target.value.id, 'Project', null);
          } else if (event.target.value.Category === 'CR') {
            this.getDataForTaskFilter(event.target.value.id, 'ChangeRequest', null);
          } else {
            this.getDataForTaskFilter(event.target.value.NPTId, 'NPT', null);
          }
        }
        this.getDataForProjectFilter(false);
      }
    );
  };
  setTimeEntryDropDowns = (props, addMode, globleAddMode) => {
    this.setState(
      {
        taskDropdownFilter: this.state.data.filter(x => x.Project === props.dataItem.Project),
        taskDropdownFilterAll: this.state.data.filter(x => x.Project === props.dataItem.Project),
        currentTaskInDropDown: this.state.data.filter(
          x => x.taskId === props.dataItem.taskId && x.taskType === props.dataItem.taskType
        )[0],
        comment: null,
        ticketId: null,
        workLocation: null,
        duration: null,
        startTime: null,
        endTime: null,
        timeEntryAdd: []
      },
      () => {
        this.toggleAddTimeentry(
          props.dataItem.projectId,
          props.field,
          props.dataItem.Project,
          props.dataItem.Task,
          props.dataItem.taskId,
          false,
          addMode,
          globleAddMode,
          props.dataItem[props.field].taskAllocationId,
          props.dataItem.taskType,
          props.dataItem.isShiftTask
        );
      }
    );
  };
  setEditTimeEntryDropDowns = (editMode, globleAddMode) => {
    this.setState(
      {
        taskDropdownFilter: this.state.data.filter(x => x.Project === this.state.currentProject),
        taskDropdownFilterAll: this.state.data.filter(x => x.Project === this.state.currentProject),
        currentTaskInDropDown: this.state.data.filter(x => x.Task === this.state.currentTask)[0],
        timeEntryAdd: []
      },
      () => {
        this.toggleAddTimeentry(
          this.state.currentProjectId,
          this.state.currentDay,
          this.state.currentProject,
          this.state.currentTask,
          this.state.currentTaskId,
          editMode,
          false,
          globleAddMode,
          this.state.currentNonProjectTaskAllocationId,
          this.state.currTaskType,
          this.state.isShiftTask
        );
      }
    );
  };

  submitUnfreezeRequestSingle = async day => {
    const previousMonthDate = new Date().setDate(0);
    const previousMonthLastDate = moment(previousMonthDate).format('YYYY-MM-DD');
    const monthFirstDate = moment(previousMonthDate).add(1, 'days').format('YYYY-MM-DD');
    const firstDatePrevMonth = new Date(previousMonthLastDate).setDate(1);

    if (new Date(moment().format('YYYY-MM-DD')).getTime() == new Date(moment(monthFirstDate).format('YYYY-MM-DD')).getTime()) {
      if (new Date(moment(day).format('YYYY-MM-DD')) < new Date(moment(firstDatePrevMonth).format('YYYY-MM-DD'))) {
        const message = `Unfreeze Requests cannot be submitted for previous month. You can only submit unfreeze requests for dates on or after ${moment(firstDatePrevMonth).format('YYYY-MM-DD')}.` ;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          isErrorMessageVisible: false
        });
        this.toggleMessageDialog(message, title);
      } else {
        let days = [moment(day).format('YYYY-MM-DD')];
        await this.setState({ selectedDates: days });
        this.toggleUnfreezeDialog();
       }
    } else {
      if (new Date(moment(day).format('YYYY-MM-DD')) <= new Date(previousMonthLastDate)) {
        const message = `Unfreeze Requests cannot be submitted for previous month. You can only submit unfreeze requests for dates on or after ${monthFirstDate}.` ;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          isErrorMessageVisible: false
        });
        this.toggleMessageDialog(message, title);
      } else {
        let days = [moment(day).format('YYYY-MM-DD')];
        await this.setState({ selectedDates: days });
        this.toggleUnfreezeDialog();
       }
    }
  };

  submitUnfreezeRequestWeekly = async () => {
    let validDates = [];
    let inValidDates = [];
    if (this.state.status === 2) {
      await this.setAllSelectedDaysUnfreeze();
    }
    const previousMonthDate = new Date().setDate(0);
    const previousMonthLastDate = moment(previousMonthDate).format('YYYY-MM-DD');
    const monthFirstDate = moment(previousMonthDate).add(1, 'days').format('YYYY-MM-DD');
    const firstDatePrevMonth = new Date(previousMonthLastDate).setDate(1);

    for (const date of this.state.selectedDates) {
      if (new Date(date) <= new Date(previousMonthLastDate)) {
        inValidDates.push(date);
      } else {
        validDates.push(date);
      }
    }
    if (new Date(moment().format('YYYY-MM-DD')).getTime() == new Date(moment(monthFirstDate).format('YYYY-MM-DD')).getTime()) {
      let validPrevDates = [];
      let invalidPrevDates = [];
      for (const date of this.state.selectedDates) {
        if (new Date(date) < new Date(firstDatePrevMonth)) {
          invalidPrevDates.push(date);
        } else {
          validPrevDates.push(date);
        }
      }
      if (invalidPrevDates.length === this.state.selectedDates.length) {
        const message = `Unfreeze Requests cannot be submitted for previous month. You can only submit unfreeze requests for dates on or after ${moment(firstDatePrevMonth).format('YYYY-MM-DD')}.` ;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          isErrorMessageVisible: false
        });
        this.toggleMessageDialog(message, title);
      } else if (invalidPrevDates.length > 0 && validPrevDates.length > 0){
        const message = `Dates in the previous month will not be Unfrozen. Only dates on and after ${moment(firstDatePrevMonth).format('YYYY-MM-DD')} will be submitted for Unfreezing.`
        await this.setState({
          selectedDates: validPrevDates,
          havingtwoMonthBackDates: true,
          unfreezeMessage: message
        });
        this.toggleUnfreezeDialog();
       }else {
        this.toggleUnfreezeDialog();
       }
    } else {
      if (inValidDates.length === this.state.selectedDates.length) {
        const message = `Unfreeze Requests cannot be submitted for previous month. You can only submit unfreeze requests for dates on or after ${monthFirstDate}.` ;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          isErrorMessageVisible: false
        });
        this.toggleMessageDialog(message, title);
      } else if (inValidDates.length > 0 && validDates.length > 0){
        const message = `Dates in the previous month will not be Unfrozen. Only dates on and after ${monthFirstDate} will be submitted for Unfreezing.`
        await this.setState({
          selectedDates: validDates,
          havingtwoMonthBackDates: true,
          unfreezeMessage: message
        });
        this.toggleUnfreezeDialog();
       }else {
        this.toggleUnfreezeDialog();
       }
    }  
  };

  addTimeEntryPending = date => {
    this.setWeekDates(date, false);
    this.addDailyTimeEntry(null, date);
  };

  addDailyTimeEntry = async (edit, date) => {
    await this.getTaskList(
      null,
      null,
      null,
      null,
      true,
      this.state.firstDayOfWeek,
      this.state.LastDayOfWeek
    );
    const tasks = this.state.allData;
    let workLocation = null;
    if (date) {
      let params = {
        date: moment(date).format('YYYY-MM-DD')
      };
      await getLatestTimeEntryByDate(params).then(res => {
        if (res.data && res.data.WorkLocationId) {
          workLocation = res.data.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == res.data.WorkLocationId)[0] : null;
        }
      });  
    }

    if (!edit) {
      //tasks.filter(x => x.Project === this.state.currentProject) - reomved RMS-14880:this.state.projectDropdownFilterAll
      this.setState({
        projectDropdownFilter: this.state.projectDropdownFilterAll,
        taskDropdownFilter: tasks.filter(
          x =>
            x.Project === this.state.currentProject &&
            (!x.EndDate || new Date(x.EndDate) < new Date(date))
        ),
        taskDropdownFilterAll: tasks.filter(
          x =>
            x.Project === this.state.currentProject &&
            (!x.EndDate || new Date(x.EndDate) < new Date(date))
        ),
        currentTaskInDropDown: null,
        currentProjectForDropDown: null,
        comment: null,
        ticketId: null,
        workLocation: workLocation,
        duration: null,
        startTime: null,
        isDirty: false,
        endTime: null,
        currentProjectTaskAllocationId: null,
        currentDate: date,
        SelectedCurrentDate: date,
        currentProject: this.state.currentProject,
        timeEntryAdd: [],
        timeEntryEditMode: false,
        timeEntryAddMode: false,
        addTimeentrydaily: true,
        isAddTimeFreeze: false,
        isShiftTask: false,
        isErrorMessageVisible: false,
        isTicketIdErrorMessageVisible: false,
        isLocationErrorMessageVisible: false,
        isTaskExist: false,
        timeEntryData: [],
        currentProjectId: null,
        currentTaskId: null,
        currentTask: null,
        currentTimeCardId: null
      });
    } else {
      let sampleDate = '2018-12-24';
      this.setState({
        projectDropdownFilter: this.state.projectDropdownFilterAll,
        taskDropdownFilter: tasks.filter(
          x =>
            x.Project === this.state.currentProject &&
            (!x.EndDate || new Date(x.EndDate) < new Date(date))
        ),
        taskDropdownFilterAll: tasks.filter(
          x =>
            x.Project === this.state.currentProject &&
            (!x.EndDate || new Date(x.EndDate) < new Date(date))
        ),
        currentTimeCardId: edit.id,
        isAddTimeFreeze: false,
        currentTaskInDropDown: tasks.find(i => i.taskId == edit.taskId),
        comment: edit.Comment,
        ticketId: edit.TicketId,
        workLocation: edit.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == edit.WorkLocationId)[0] : null,
        duration: this.convertDuration(edit.Duration),
        startTime: edit.FromDateTime
          ? new Date(moment(`${sampleDate} ${edit.FromDateTime}`, 'YYYY-MM-DD HH:mm:ss').format())
          : null,
        isDirty: false,
        endTime: edit.ToDateTime
          ? new Date(moment(`${sampleDate} ${edit.ToDateTime}`, 'YYYY-MM-DD HH:mm:ss').format())
          : null,
        currentDate: date,
        SelectedCurrentDate: date,
        timeEntryAdd: [],
        timeEntryEditMode: true,
        timeEntryAddMode: false,
        addTimeentrydaily: true,
        isShiftTask: false,
        isErrorMessageVisible: false,
        isTicketIdErrorMessageVisible: false,
        isLocationErrorMessageVisible: false,
        isTaskExist: false,
        timeEntryData: [],
        currentProjectId: edit.projectId,
        currentProjectTaskAllocationId: edit.type === 'project' ? edit.allocationId : null,
        currentTaskId: edit.taskId,
        currentProject: edit.Project !== null ? edit.Project : this.state.currentProject,
        currentProjectForDropDown: this.state.projectDropdownFilter.filter(
          x => x.DisplayText === edit.Project
        )[0],
        currentNonProjectTaskAllocationId: edit.type === 'nonproject' ? edit.allocationId : null,
        currentTask: tasks.find(i => i.taskId == edit.taskId)
      });
    }
  };

  setGlobleAddTimeEntryDropDowns = (editMode, globleAddMode) => {
    this.setState(
      {
        taskDropdownFilter: this.state.data.filter(x => x.Project === this.state.currentProject),
        taskDropdownFilterAll: this.state.data.filter(x => x.Project === this.state.currentProject),
        currentTaskInDropDown: null,
        currentProjectForDropDown: null,
        SelectedCurrentDate: null,
        currentProject: null,
        comment: null,
        ticketId: null,
        workLocation: null,
        duration: null,
        startTime: null,
        endTime: null,
        timeEntryAdd: []
      },
      () => {
        this.toggleAddTimeentry(
          this.state.currentProjectId,
          this.state.currentDay,
          this.state.currentProject,
          this.state.currentTask,
          this.state.currentTaskId,
          editMode,
          false,
          globleAddMode,
          this.state.currentNonProjectTaskAllocationId,
          null,
          false
        );
      }
    );
  };

  toggleDialog = (message, title) => {
    this.setState({
      showSuccessImage: true,
      successPopUpvisible: !this.state.successPopUpvisible,
      dialogMessage: message
    });
  };

  toggleDeleteConfirmation = () => {
    this.setState({
      deletePopupVisible: !this.state.deletePopupVisible
    });
  };

  deleteTimeEntry = async () => {
    let timeEntryList = [];
    this.setState({
      loader: true
    });
    let selectedList = await this.getSelectedCard(this.state.data);
    selectedList.length !== 0 &&
      selectedList.forEach(card => {
        const taskList = {
          CardId: card.cardId,
          TaskId: card.taskId,
          TaskName: card.task,
          LogDateTime: card.LogDateTime
        };
        timeEntryList.push(taskList);
      });
    deleteTimeEntry(timeEntryList).then(res => {
      this.getTimeCards();
      this.toggleDeleteConfirmation();
      this.getCalculatedTotals();
      this.setState({
        isActiveDelete: false,
        isAllSelectedMon: false,
        isAllSelectedTue: false,
        isAllSelectedWed: false,
        isAllSelectedTur: false,
        isAllSelectedFri: false,
        isAllSelectedSat: false,
        isAllSelectedSun: false
      });
    });
  };
  customCell = props => {
    const data = props.dataItem[props.field];
    const checkCellColor = (taskStartDate, taskEndDate, allocation, day) => {
      switch (day) {
        case 'Mon':
          {
            let currentDate = moment(this.state.dateOfMon).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              if (this.state.isFreezeDateOfMon === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Tue':
          {
            let currentDate = moment(this.state.dateOfTue).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              if (this.state.isFreezeDateOfTue === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Wed':
          {
            let currentDate = moment(this.state.dateOfWed).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              if (this.state.isFreezeDateOfWed === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Tur':
          {
            let currentDate = moment(this.state.dateOfTur).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              if (this.state.isFreezeDateOfThu === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Fri':
          {
            let currentDate = moment(this.state.dateOfFri).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              if (this.state.isFreezeDateOfFri === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Sat':
          {
            let currentDate = moment(this.state.dateOfSat).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              if (this.state.isFreezeDateOfSat === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
        case 'Sun':
          {
            let currentDate = moment(this.state.dateOfSun).format('YYYY-MM-DD');
            let startDate = moment(taskStartDate).format('YYYY-MM-DD');
            let endDate = moment(taskEndDate).format('YYYY-MM-DD');
            if (
              (currentDate > endDate && taskEndDate !== null) ||
              startDate > currentDate ||
              allocation === null
            ) {
              return { backgroundColor: 'gray' };
            } else {
              if (this.state.isFreezeDateOfSun === true) {
                return { backgroundColor: 'transparent', cursor: 'not-allowed' };
              } else {
                return { borderTop: '1px solid #ffff' };
              }
            }
          }
          break;
      }
    };
    return (
      <td
        style={checkCellColor(
          props.dataItem.StartDate,
          props.dataItem.EndDate,
          props.dataItem[props.field].taskAllocationId,
          props.field
        )}
      >
        {data.length !== 0 ? (
          data.cards.map((card, index) => (
            <Card
              toggleWindowDialog={this.toggleWindowDialog}
              cardData={card}
              key={index}
              checked={card.checked}
              checkeBoxHandler={this.checkeBoxClickHandler}
              index={index}
              approvedStatus={card.approvedStatus}
              taskId={props.dataItem.taskId}
              data={props.dataItem}
              date={props.field}
            />
          ))
        ) : (
          <span />
        )}
        {checkCellColor(
          props.dataItem.StartDate,
          props.dataItem.EndDate,
          props.dataItem[props.field].taskAllocationId,
          props.field
        ).backgroundColor !== 'transparent' &&
        checkCellColor(
          props.dataItem.StartDate,
          props.dataItem.EndDate,
          props.dataItem[props.field].taskAllocationId,
          props.field
        ).backgroundColor !== 'gray' ? (
          <div
            className="cell-click"
            onClick={() => {
              this.setState({ isDirty: false });
              this.setTimeEntryDropDowns(props, true, false);
            }}
          />
        ) : null}
      </td>
    );
  };
  totalHourePerDay = () => {
    let mon = 0;
    let tue = 0;
    let wed = 0;
    let tur = 0;
    let fri = 0;
    let sat = 0;
    let sun = 0;
    this.state.data.forEach(task => {
      task.Mon.forEach(card => {
        mon = mon + card.duration;
      });
      task.Tue.forEach(card => {
        tue = tue + card.duration;
      });
      task.Wed.forEach(card => {
        wed = wed + card.duration;
      });
      task.Tur.forEach(card => {
        tur = tur + card.duration;
      });
      task.Fri.forEach(card => {
        fri = fri + card.duration;
      });
      task.Sat.forEach(card => {
        sat = sat + card.duration;
      });
      task.Sun.forEach(card => {
        sun = sun + card.duration;
      });
    });
    this.setState({
      monhoures: mon,
      tuehoures: tue,
      webhoures: wed,
      turhoures: tur,
      frihoures: fri,
      sathoures: sat,
      sunhoures: sun
    });
  };

  prevDay = () => {
    const date = this.state.newDailyDate;
    const newDate = new Date();
    const nextDay = new Date(
      newDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() - 1)
    );
    this.setState(
      {
        newDate: nextDay,
        newDailyDate: nextDay
      },
      () => this.setWeekDates(nextDay, false)
    );
  };

  nextDay = () => {
    const date = this.state.newDailyDate;
    const newDate = new Date();
    const nextDay = new Date(
      newDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 1)
    );
    this.setState(
      {
        newDate: nextDay,
        newDailyDate: nextDay
      },
      () => this.setWeekDates(nextDay, false)
    );
  };

  prevWeek = () => {
    const date = this.state.newDate;
    const newDate = new Date();
    const prevWeek = new Date(
      newDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() - 7)
    );
    this.setState(
      {
        newDate: prevWeek,
        isActiveEdit: false,
        isAllSelectedMon: false,
        isAllSelectedTue: false,
        isAllSelectedWed: false,
        isAllSelectedTur: false,
        isAllSelectedFri: false,
        isAllSelectedSat: false,
        isAllSelectedSun: false,
        loader: true
      },
      () => this.setWeekDates(prevWeek, false)
    );
  };
  nextWeek = () => {
    const date = this.state.newDate;
    const newDate = new Date();
    const nextWeek = new Date(
      newDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate() + 7)
    );
    this.setState(
      {
        newDate: nextWeek,
        isActiveEdit: false,
        isAllSelectedMon: false,
        isAllSelectedTue: false,
        isAllSelectedWed: false,
        isAllSelectedTur: false,
        isAllSelectedFri: false,
        isAllSelectedSat: false,
        isAllSelectedSun: false,
        loader: true
      },
      () => this.setWeekDates(nextWeek, false)
    );
  };
  setWeekDates = (date, init) => {
    if (date === null) {
      this.setState({
        newDate: new Date()
      });
    } else {
      this.setState({
        newDate: date,
        isActiveEdit: false,
        isFreeze: false
      });
      this.datesHandler(date, init);
    }
  };
  datesHandler = async (date, init) => {
    if (moment(date).day() !== 0) {
      const FirstdayOfWeek = new Date();
      const FirstMonOfWeek = new Date();
      const LastDayOfWeek = new Date();
      const forGridHader = new Date();
      const firstday = new Date(
        FirstdayOfWeek.setFullYear(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - date.getDay()
        )
      );
      await this.setState(
        {
          firstDayOfWeek: new Date(
            FirstMonOfWeek.setFullYear(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() - date.getDay() + 1
            )
          ),
          LastDayOfWeek: new Date(
            LastDayOfWeek.setFullYear(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() - date.getDay() + 7
            )
          ),
          dateOfSun: new Date(moment(firstday).add(7, 'days')),
          dateOfMon: new Date(moment(firstday).add(1, 'days')),
          dateOfTue: new Date(moment(firstday).add(2, 'days')),
          dateOfWed: new Date(moment(firstday).add(3, 'days')),
          dateOfTur: new Date(moment(firstday).add(4, 'days')),
          dateOfFri: new Date(moment(firstday).add(5, 'days')),
          dateOfSat: new Date(moment(firstday).add(6, 'days'))
        },
        () => {
          if (this.state.status == 1) {
            if (
              moment(this.state.newDailyDate).format('YYYY-MM-DD') <=
              moment('01/01/2021').format('YYYY-MM-DD')
            ) {
              this.setState({
                disableDailyPrvBtn: false
              });
            } else {
              this.setState({
                disableDailyPrvBtn: true
              });
            }
          } else if (this.state.status == 2) {
            if (
              moment(this.state.firstDayOfWeek).format('YYYY-MM-DD') <=
              moment('01/01/2021').format('YYYY-MM-DD')
            ) {
              this.setState({
                disablePrvBtn: false
              });
            } else {
              this.setState({
                disablePrvBtn: true
              });
            }
          }
          if (!init) {
            if (this.state.status == 2) {
              this.state.selectedProjectOrCr !== null
                ? this.getTaskList(
                    this.state.selectedProjectOrCr.projId,
                    this.state.selectedProjectOrCr.CRId,
                    this.state.selectedProjectOrCr.NPTId,
                    this.state.selectedProjectOrCr.Category,
                    false,
                    this.state.firstDayOfWeek,
                    this.state.LastDayOfWeek
                  )
                : this.getTaskList(
                    null,
                    null,
                    null,
                    null,
                    false,
                    this.state.firstDayOfWeek,
                    this.state.LastDayOfWeek
                  );
            } else {
              this.onRunButtonClick(date, init);
            }
          }
        }
      );
    } else {
      const firstday = new Date(moment(date).subtract(6, 'days'));
      await this.setState(
        {
          firstDayOfWeek: new Date(moment(date).subtract(6, 'days')),
          LastDayOfWeek: new Date(moment(date)),
          dateOfSun: new Date(moment(firstday).add(6, 'days')),
          dateOfMon: new Date(moment(firstday)),
          dateOfTue: new Date(moment(firstday).add(1, 'days')),
          dateOfWed: new Date(moment(firstday).add(2, 'days')),
          dateOfTur: new Date(moment(firstday).add(3, 'days')),
          dateOfFri: new Date(moment(firstday).add(4, 'days')),
          dateOfSat: new Date(moment(firstday).add(5, 'days'))
        },
        () => {
          if (!init) {
            if (this.state.status == 2) {
              this.state.selectedProjectOrCr !== null
                ? this.getTaskList(
                    this.state.selectedProjectOrCr.projId,
                    this.state.selectedProjectOrCr.CRId,
                    this.state.selectedProjectOrCr.NPTId,
                    this.state.selectedProjectOrCr.Category,
                    false,
                    this.state.firstDayOfWeek,
                    this.state.LastDayOfWeek
                  )
                : this.getTaskList(
                    null,
                    null,
                    null,
                    null,
                    false,
                    this.state.firstDayOfWeek,
                    this.state.LastDayOfWeek
                  );
            } else {
              this.onRunButtonClick(date, init);
            }
          }
        }
      );
    }
    await this.validateFreezeDate(date);
    await this.setUnfreezeButtonEnableDisable();
  };

  validateFreezeDate = async date => {
    let freezeDate = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(date).format('YYYY-MM-DD')
    );

    let freezeDateSun = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfSun).format('YYYY-MM-DD')
    );
    let freezeDateMon = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfMon).format('YYYY-MM-DD')
    );
    let freezeDateTue = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfTue).format('YYYY-MM-DD')
    );
    let freezeDateWed = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfWed).format('YYYY-MM-DD')
    );
    let freezeDateThu = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfTur).format('YYYY-MM-DD')
    );
    let freezeDateFri = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfFri).format('YYYY-MM-DD')
    );
    let freezeDateSat = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(this.state.dateOfSat).format('YYYY-MM-DD')
    );

    let isFreezeFromDB = false;
    isFreezeFromDB =
      freezeDate && freezeDate.isTimeEntryFreeze ? freezeDate.isTimeEntryFreeze : false;

    await this.setState({
      isFreezeDateOfSun:
        freezeDateSun && freezeDateSun.isTimeEntryFreeze ? freezeDateSun.isTimeEntryFreeze : false,
      isFreezeDateOfMon:
        freezeDateMon && freezeDateMon.isTimeEntryFreeze ? freezeDateMon.isTimeEntryFreeze : false,
      isFreezeDateOfTue:
        freezeDateTue && freezeDateTue.isTimeEntryFreeze ? freezeDateTue.isTimeEntryFreeze : false,
      isFreezeDateOfWed:
        freezeDateWed && freezeDateWed.isTimeEntryFreeze ? freezeDateWed.isTimeEntryFreeze : false,
      isFreezeDateOfThu:
        freezeDateThu && freezeDateThu.isTimeEntryFreeze ? freezeDateThu.isTimeEntryFreeze : false,
      isFreezeDateOfFri:
        freezeDateFri && freezeDateFri.isTimeEntryFreeze ? freezeDateFri.isTimeEntryFreeze : false,
      isFreezeDateOfSat:
        freezeDateSat && freezeDateSat.isTimeEntryFreeze ? freezeDateSat.isTimeEntryFreeze : false
    });

    if (
      this.state.isFreezeDateOfMon &&
      this.state.isFreezeDateOfTue &&
      this.state.isFreezeDateOfWed &&
      this.state.isFreezeDateOfThu &&
      this.state.isFreezeDateOfFri &&
      this.state.isFreezeDateOfSat &&
      this.state.isFreezeDateOfSun
    ) {
      this.setState({
        isFreeze: true
      });
    }
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };
  validateDurationNullOrNot = value => {
    if (
      value !== null &&
      value !== 0 &&
      value !== '0' &&
      value !== '' &&
      value !== '00' &&
      value !== '0:00' &&
      value !== '00:00' &&
      value !== '0.0' &&
      value !== '0.00' &&
      value !== '00.00' &&
      value !== '00.0'
    ) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };
  validateStartTime = () => {
    if (this.state.isShiftTask) {
      return this.validateProperty(this.state.startTime);
    } else {
      return 'd-none';
    }
  };
  validateEndTime = () => {
    if (this.state.isShiftTask) {
      return this.validateProperty(this.state.endTime);
    } else {
      return 'd-none';
    }
  };
  validateDuration = () => {
    let timeRegExWithColon = /^(0?[0-9]|[0-9][0-9]):[0-5][0-9]$/;
    let timeRegExWithOutColon = /^\d{1,2}(\.\d{1,2})?$/;
    if (
      this.state.duration === null ||
      this.state.duration === 0 ||
      this.state.duration === '0' ||
      this.state.duration === '' ||
      timeRegExWithColon.test(this.state.duration)
    ) {
      return 'd-none';
    } else if (
      this.state.duration === null ||
      this.state.duration === 0 ||
      this.state.duration === '0' ||
      this.state.duration === '' ||
      timeRegExWithOutColon.test(this.state.duration)
    ) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };
  validateDayOfWeek = date => {
    if (date === null) {
      return false;
    } else {
      return;
    }
  };
  validation = event => {
    const noOfCards = this.state.timeEntryAdd.length;
    if (typeof event !== 'undefined') {
      var target = event.target;
      if (noOfCards !== 0) {
        const limit = (noOfCards + 1) * 4 + 1;
        for (let i = 8; i < limit; i += 4) {
          if (
            this.validateProperty(target.elements[i].value)
              .toString()
              .includes('error')
          ) {
            return false;
          }
        }
      }
    }

    if (!this.validateWorkLocation()) {
      this.setState({
        isLocationErrorMessageVisible: true,
        isErrorMessageVisible: true
      });
    } 

    if (
      this.validateDurationNullOrNot(this.state.duration)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.comment)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateDuration()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateStartTime()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateEndTime()
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.currentProjectForDropDown)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.currentTaskInDropDown)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.SelectedCurrentDate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else {
      return true;
    }
  };

  validateTicketId = () => {
    if (this.state.currentProjectForDropDown && this.state.currentProjectForDropDown.ProjectDepartment == 30) {
        if (
          this.validateProperty(this.state.ticketId)
            .toString()
            .includes('error')
        ) {
          return false;
        } else {
          return true;
        }
    } else {
      return true;
    }
  };

  validateWorkLocation = () => {
    if (!(this.state.currentProjectForDropDown && (this.state.currentProjectForDropDown.DisplayText === 'HR - Holidays' || 
        this.state.currentProjectForDropDown.DisplayText === 'HR - Leave'))) {
        if (
          this.validateProperty(this.state.workLocation)
            .toString()
            .includes('error')
        ) {
          return false;
        } else {
          return true;
        }
    } else {
      return true;
    }
  };

  validateGlobleAddDatePicker = (disableDates, selectedDayte) => {
    if (disableDates.length !== 0) {
      if (disableDates.some(x => x === moment(selectedDayte).format('YYYY-MM-DD'))) {
        return 'inline-error';
      } else {
        return 'd-none';
      }
    } else {
      return 'd-none';
    }
  };

  onDeleteDailyTimeEntry = async timeEntryId => {
    this.onOpenPopupBox('Confirm', 'Are you sure you want to delete?', async () => {
      this.onClosePopupBox();
      await deleteTimeEntryDaily([timeEntryId]).then(res => {
        if (res && res.data) {
          this.onRunButtonClick(this.state.newDailyDate, false);
        }
      });
    });
  };

  onRunButtonClick = async (date, init) => {
    if (this.state.selectedProjectOrCr) {
      await this.getTaskList(
        this.state.selectedProjectOrCr.projId,
        this.state.selectedProjectOrCr.CRId,
        this.state.selectedProjectOrCr.NPTId,
        this.state.selectedProjectOrCr.Category,
        true,
        this.state.firstDayOfWeek,
        this.state.LastDayOfWeek
      );
      if (this.state.status == 1) {
        if (this.state.selectedTask !== null) {
          this.getDailyTimeEntryData(
            this.state.selectedProjectOrCr.projId,
            this.state.selectedProjectOrCr.CRId,
            this.state.selectedProjectOrCr.NPTId,
            this.state.selectedTask.taskId,
            this.state.selectedProjectOrCr.Category,
            date
          );
        } else {
          this.getDailyTimeEntryData(
            this.state.selectedProjectOrCr.projId,
            this.state.selectedProjectOrCr.CRId,
            this.state.selectedProjectOrCr.NPTId,
            null,
            this.state.selectedProjectOrCr.Category,
            date
          );
        }
      }
      if (this.state.status == 3) {
        this.getAllPendingEntries(
          this.state.allPendingFromDate,
          this.state.allPendingToDate,
          null,
          null,
          null
        );
      }
    } else {
      await this.getTaskList(
        null,
        null,
        null,
        null,
        true,
        this.state.firstDayOfWeek,
        this.state.LastDayOfWeek
      );
      if (this.state.status == 1) {
        this.getDailyTimeEntryData(null, null, null, null, null, date);
      }
      if (this.state.status == 3) {
        this.getAllPendingEntries(
          this.state.allPendingFromDate,
          this.state.allPendingToDate,
          null,
          null,
          null
        );
      }
    }
  };

  onOpenPopupBox = (title, message, callBack) => {
    this.setState({
      popUpBox: {
        isPopUp: true,
        popUpMessage: message,
        popUpTitile: title,
        callBack: callBack
      }
    });
  };

  onClosePopupBox = () => {
    this.setState({
      popUpBox: {
        isPopUp: false,
        popUpMessage: '',
        popUpTitile: '',
        callBack: null
      }
    });
  };

  dailyTimeEntryDataHandler = event => {
    const field = event.target.name;
    let tasks = [];
    switch (field) {
      case 'project':
        {
          tasks = this.state.allData.filter(
            x =>
              event.target.value !== null &&
              x.Project === event.target.value.DisplayText &&
              (!x.EndDate ||
                !this.state.SelectedCurrentDate ||
                new Date(moment(x.EndDate).format('YYYY-MM-DD')) >= new Date(moment(this.state.SelectedCurrentDate).format('YYYY-MM-DD')))
          );

          this.setState({
            currentProjectForDropDown: event.target.value,
            currentProject: event.target.value !== null && event.target.value.DisplayText,
            currentProjectId: event.target.value !== null && event.target.value.DisplayText,
            taskDropdownFilter: tasks,
            taskDropdownFilterAll: tasks,
            isTaskExist: tasks.length !== 0 || event.target.value === null ? false : true,
            currentTaskInDropDown: null,
            isShiftTask: false,
            isDirty: event.target.name === null ? false : true
          });
        }
        if (event.target.value && (event.target.value.DisplayText === 'HR - Holidays' || 
          event.target.value.DisplayText === 'HR - Leave')) {
          this.setState({
            workLocation: null,
            isLocationErrorMessageVisible: false
          });
        }
        break;
      default:
        return;
    }
    let freezeDate = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(event.target.value).format('YYYY-MM-DD')
    );

    this.setState({ isAddTimeFreeze: freezeDate && freezeDate.isTimeEntryFreeze ? true : false });
  };

  timeEntryDataHandler = event => {
    const field = event.target.name;
    let tasks = [];
    let promise = [];
    switch (field) {
      case 'project':
        {
          tasks = this.state.allData.filter(
            x =>
              event.target.value !== null &&
              x.Project === event.target.value.DisplayText
          );

          this.setState({
            currentProjectForDropDown: event.target.value,
            currentProject: event.target.value !== null && event.target.value.DisplayText,
            currentProjectId: event.target.value !== null && event.target.value.DisplayText,
            taskDropdownFilter: tasks,
            taskDropdownFilterAll: tasks,
            isTaskExist: tasks.length !== 0 || event.target.value === null ? false : true,
            currentTaskInDropDown: null,
            SelectedCurrentDate: null,
            isShiftTask: false,
            isDirty: event.target.name === null ? false : true
          });
        }
        if (event.target.value && (event.target.value.DisplayText === 'HR - Holidays' || 
          event.target.value.DisplayText === 'HR - Leave')) {
          this.setState({
            workLocation: null,
            isLocationErrorMessageVisible: false
          });
        }
        break;
      case 'taskDropDown':
        {
          if (event.target.value !== null) {
            if (event.target.value.taskType === 'NPT') {
              this.setState({
                currentTaskInDropDown: event.target.value,
                isDirty: event.target.name === null ? false : true
              });
            } else if (event.target.value.taskType === 'CRT') {
              this.setState({
                currentTaskInDropDown: event.target.value,
                isShiftTask: event.target.value.isShiftTask,
                isDirty: event.target.name === null ? false : true
              });
            } else if (event.target.value.taskType === 'PT') {
              this.setState({
                currentTaskInDropDown: event.target.value,
                isShiftTask: event.target.value.isShiftTask,
                isDirty: event.target.name === null ? false : true
              });
            }
            if(this.state.SelectedCurrentDate !== null){
              let day = moment(this.state.SelectedCurrentDate).format('ddd')
              if(event.target.value.taskType === 'NPT'){
                switch(day){
                  case'Mon':{
                   this.setState({
                    currentNonProjectTaskAllocationId:event.target.value.Mon.taskAllocationId,
                    currentProjectTaskAllocationId:null
                   })
                  }
                  break;
                  case'Tue':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Tue.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Wed':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Wed.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Thu':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Tur.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Fri':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Fri.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Sat':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Sat.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Sun':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Sun.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                }
              } else if(event.target.value.taskType === 'CRT' || event.target.value.taskType === 'PT'){
                switch(day){
                  case'Mon':{
                   this.setState({
                    currentProjectTaskAllocationId:event.target.value.Mon.taskAllocationId,
                    currentNonProjectTaskAllocationId:null
                   })
                  }
                  break;
                  case'Tue':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Tue.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Wed':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Wed.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Thu':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Tur.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Fri':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Fri.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Sat':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Sat.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Sun':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Sun.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                }
              }
            }else{
              let day = moment(new Date()).format('ddd')
              if(event.target.value.taskType === 'NPT'){
                switch(day){
                  case'Mon':{
                   this.setState({
                    currentNonProjectTaskAllocationId:event.target.value.Mon.taskAllocationId,
                    currentProjectTaskAllocationId:null
                   })
                  }
                  break;
                  case'Tue':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Tue.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Wed':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Wed.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Thu':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Tur.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Fri':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Fri.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Sat':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Sat.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Sun':{
                    this.setState({
                      currentNonProjectTaskAllocationId:event.target.value.Sun.taskAllocationId,
                      currentProjectTaskAllocationId:null
                     })
                  }
                  break;
                }
              } else if(event.target.value.taskType === 'CRT' || event.target.value.taskType === 'PT'){
                switch(day){
                  case'Mon':{
                   this.setState({
                    currentProjectTaskAllocationId:event.target.value.Mon.taskAllocationId,
                    currentNonProjectTaskAllocationId:null
                   })
                  }
                  break;
                  case'Tue':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Tue.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Wed':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Wed.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Thu':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Tur.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Fri':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Fri.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Sat':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Sat.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                  case'Sun':{
                    this.setState({
                      currentProjectTaskAllocationId:event.target.value.Sun.taskAllocationId,
                      currentNonProjectTaskAllocationId:null
                     })
                  }
                  break;
                }
              }
            }
          } else {
            this.setState({
              currentTaskInDropDown: null
            });
          }
        }
        break;
      case 'dateOfTimeEntry':
        {
          if (this.state.currentTaskInDropDown !== null) {
            if (
              this.validateGlobleAddDatePicker(
                this.state.currentTaskInDropDown.disableDateList,
                event.target.value
              )
                .toString()
                .includes('error')
            ) {
              this.setState({
                isDateErrorMessageVisible: true,
                SelectedCurrentDate: event.target.value
              });
            } else {
              let day = moment(event.target.value).format('ddd')
              let tasktype = this.state.currentTaskInDropDown.taskType;
              this.setState({
                SelectedCurrentDate: event.target.value,
                isDateErrorMessageVisible: false,
                isDirty: event.target.name === null ? false : true
              },()=>{
                if(tasktype === 'NPT'){
                  switch(day){
                    case'Mon':{
                     this.setState({
                      currentNonProjectTaskAllocationId:this.state.currentTaskInDropDown.Mon.taskAllocationId
                     })
                    }
                    break;
                    case'Tue':{
                      this.setState({
                        currentNonProjectTaskAllocationId:this.state.currentTaskInDropDown.Tue.taskAllocationId
                       })
                    }
                    break;
                    case'Wed':{
                      this.setState({
                        currentNonProjectTaskAllocationId:this.state.currentTaskInDropDown.Wed.taskAllocationId
                       })
                    }
                    break;
                    case'Thu':{
                      this.setState({
                        currentNonProjectTaskAllocationId:this.state.currentTaskInDropDown.Tur.taskAllocationId
                       })
                    }
                    break;
                    case'Fri':{
                      this.setState({
                        currentNonProjectTaskAllocationId:this.state.currentTaskInDropDown.Fri.taskAllocationId
                       })
                    }
                    break;
                    case'Sat':{
                      this.setState({
                        currentNonProjectTaskAllocationId:this.state.currentTaskInDropDown.Sat.taskAllocationId
                       })
                    }
                    break;
                    case'Sun':{
                      this.setState({
                        currentNonProjectTaskAllocationId:this.state.currentTaskInDropDown.Sun.taskAllocationId
                       })
                    }
                    break;
                  }
                } else if(tasktype === 'CRT' || tasktype === 'PT'){
                  switch(day){
                    case'Mon':{
                     this.setState({
                      currentProjectTaskAllocationId:this.state.currentTaskInDropDown.Mon.taskAllocationId
                     })
                    }
                    break;
                    case'Tue':{
                      this.setState({
                        currentProjectTaskAllocationId:this.state.currentTaskInDropDown.Tue.taskAllocationId
                       })
                    }
                    break;
                    case'Wed':{
                      this.setState({
                        currentProjectTaskAllocationId:this.state.currentTaskInDropDown.Wed.taskAllocationId
                       })
                    }
                    break;
                    case'Thu':{
                      this.setState({
                        currentProjectTaskAllocationId:this.state.currentTaskInDropDown.Tur.taskAllocationId
                       })
                    }
                    break;
                    case'Fri':{
                      this.setState({
                        currentProjectTaskAllocationId:this.state.currentTaskInDropDown.Fri.taskAllocationId
                       })
                    }
                    break;
                    case'Sat':{
                      this.setState({
                        currentProjectTaskAllocationId:this.state.currentTaskInDropDown.Sat.taskAllocationId
                       })
                    }
                    break;
                    case'Sun':{
                      this.setState({
                        currentProjectTaskAllocationId:this.state.currentTaskInDropDown.Sun.taskAllocationId
                       })
                    }
                    break;
                  }
                }
              });
            }
          } else {
            this.setState({
              SelectedCurrentDate: event.target.value,
              isDateErrorMessageVisible: false,
              isDirty: event.target.name === null ? false : true
            });
          }
          if (event.target.value) {
            let params = {
              date: moment(event.target.value).format('YYYY-MM-DD')
            };
            getLatestTimeEntryByDate(params).then(res => {
              if (res.data && res.data.WorkLocationId) {
                this.setState({
                  workLocation: res.data.WorkLocationId ? this.workLocationStatuses.filter(obj => obj.value == res.data.WorkLocationId)[0] : null,
                });  
              }
            });  
          }
        }
        break;
    }
    let freezeDate = this.state.timeEntryFreezeDates.find(
      a => a.CalendarDate == moment(event.target.value).format('YYYY-MM-DD')
    );

    this.setState({ isAddTimeFreeze: freezeDate && freezeDate.isTimeEntryFreeze ? true : false });
  };
  render() {
    const { status } = this.state;

    return (
      <div className="main-card">
        {this.state.userStatus === true ? (
          <span>
            <div className="row">
              <div className="col-md-12">
                <PanelBar className="filter-panel">
                  <PanelBarItem
                    animation={true}
                    title={<div className="filter-timesheet">Timesheet Entry</div>}
                    expanded={true}
                  >
                    <div className="col-md-12 pl-0">
                      <div className="row- mt-2">
                        <div className="col-md-12 pl-1">
                          <form
                            className="k-form modified"
                            onSubmit={e => {
                              e.preventDefault();
                              this.state.selectedProjectOrCr !== null
                                ? this.getTaskList(
                                    this.state.selectedProjectOrCr.projId,
                                    this.state.selectedProjectOrCr.CRId,
                                    this.state.selectedProjectOrCr.NPTId,
                                    this.state.selectedProjectOrCr.Category,
                                    false,
                                    this.state.firstDayOfWeek,
                                    this.state.LastDayOfWeek
                                  )
                                : this.getTaskList(
                                    null,
                                    null,
                                    null,
                                    null,
                                    false,
                                    this.state.firstDayOfWeek,
                                    this.state.LastDayOfWeek
                                  );
                            }}
                          >
                            <div className="row">
                              <div className="col-md-3">
                                <label className="k-label font-weight-bold">View:</label>
                                <div className="mb-2 d-block mr-3">
                                  <input
                                    type="radio"
                                    name="view"
                                    value="dailyview"
                                    className="k-radio"
                                    checked={status === 1}
                                    id="daily"
                                    onClick={e => {
                                      this.radioViewHandler(1);
                                      this.setWeekDates(this.state.newDailyDate, false);
                                    }}
                                  />
                                  <label className="k-radio-label font-weight-bold" htmlFor="daily">
                                    Daily
                                  </label>
                                </div>
                                <div className="mb-2 d-block mr-3">
                                  <input
                                    type="radio"
                                    name="view"
                                    value="weeklyview"
                                    checked={status === 2}
                                    className="k-radio"
                                    id="weekly"
                                    onClick={e => {
                                      this.radioViewHandler(2);
                                      this.state.selectedProjectOrCr !== null
                                        ? this.getTaskList(
                                            this.state.selectedProjectOrCr.projId,
                                            this.state.selectedProjectOrCr.CRId,
                                            this.state.selectedProjectOrCr.NPTId,
                                            this.state.selectedProjectOrCr.Category,
                                            false,
                                            this.state.firstDayOfWeek,
                                            this.state.LastDayOfWeek
                                          )
                                        : this.getTaskList(
                                            null,
                                            null,
                                            null,
                                            null,
                                            false,
                                            this.state.firstDayOfWeek,
                                            this.state.LastDayOfWeek
                                          );
                                    }}
                                  />
                                  <label
                                    className="k-radio-label font-weight-bold"
                                    htmlFor="weekly"
                                  >
                                    Weekly
                                  </label>
                                </div>
                                <div className="mb-2 d-block mr-5">
                                  <input
                                    type="radio"
                                    name="view"
                                    value="allpendingview"
                                    className="k-radio"
                                    checked={status === 3}
                                    id="allpending"
                                    onClick={async e => {
                                      await this.radioViewHandler(3);
                                      this.onRunButtonClick(null, true);
                                    }}
                                  />
                                  <label
                                    className="k-radio-label font-weight-bold"
                                    htmlFor="allpending"
                                  >
                                    All Pending
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <label className="d-block">Project/ Task Group:</label>
                                <ComboBox
                                  name="selectProject"
                                  data={this.state.projectDropdownFilter}
                                  dataItemKey="id"
                                  textField="DisplayText"
                                  value={
                                    this.state.status !== 3 ? this.state.selectedProjectOrCr : null
                                  }
                                  onChange={this.projectDropDownHandler}
                                  disabled={this.state.status == 3}
                                  filterable={true}
                                  onFilterChange={this.filterOnChange}
                                />
                              </div>
                              <div className="col-md-3">
                                <label className="d-block">Task:</label>
                                <ComboBox
                                  name="task"
                                  data={this.state.taskFilter}
                                  dataItemKey="id"
                                  textField="taskName"
                                  value={this.state.status !== 3 ? this.state.selectedTask : null}
                                  disabled={this.state.selectedProjectOrCr === null ? true : false}
                                  onChange={e => this.taskFilterHandler(e)}
                                  filterable={true}
                                  onFilterChange={this.filterOnChange}
                                />
                              </div>
                              <div className="col-md-3" />
                              <div className="col-md-3">
                                <div className="my-2 d-inline-block mr-2">
                                  <div className="d-flex align-items-center">
                                    <label className="mr-2">Last Time Freeze Date:</label>
                                    <label>
                                      {this.state.timeEntryFreezeDate !== null
                                        ? moment(this.state.timeEntryFreezeDate).format(
                                            'MM-DD-YYYY'
                                          )
                                        : null}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 btn-align-right pr-0">
                      <Button
                        primary={true}
                        className="mb-3"
                        type="button"
                        onClick={e => {
                          this.saveSearchCriteria();
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        primary={true}
                        className="mb-3"
                        type="submit"
                        disabled={this.state.status == 3}
                        onClick={() => {
                          if (this.state.status == 2) {
                            this.state.selectedProjectOrCr !== null
                              ? this.getTaskList(
                                  this.state.selectedProjectOrCr.projId,
                                  this.state.selectedProjectOrCr.CRId,
                                  this.state.selectedProjectOrCr.NPTId,
                                  this.state.selectedProjectOrCr.Category,
                                  false,
                                  this.state.firstDayOfWeek,
                                  this.state.LastDayOfWeek
                                )
                              : this.getTaskList(
                                  null,
                                  null,
                                  null,
                                  null,
                                  false,
                                  this.state.firstDayOfWeek,
                                  this.state.LastDayOfWeek
                                );
                          } else if (this.state.status == 1) {
                            this.onRunButtonClick(this.state.newDailyDate, false);
                          }
                        }}
                      >
                        Search
                      </Button>
                      <Button
                        type="reset"
                        className="mb-3"
                        onClick={e => {
                          this.clearFilters();
                        }}
                        disabled={
                          (this.state.selectedProjectOrCr === null &&
                            this.state.withTimeEntry === false) ||
                          this.state.status == 3
                        }
                      >
                        Clear
                      </Button>
                    </div>
                    <div className="main-seperator" />
                  </PanelBarItem>
                </PanelBar>
              </div>
            </div>
            {/* Daily Section */}
            {status === 1 && (
              <>
                <div className="row fade-in">
                  <div className="col-md-12">
                    <div className="grid-header mt-2 mb-2">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-inline-block d-flex align-items-center">
                            <div className="d-inline-block mr-3">
                              <div className="d-inline-block mr-2">
                                <label>Date:</label>
                              </div>
                              <div className="d-inline-block">
                                <DatePicker
                                  format="MM/dd/yyyy"
                                  formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                  value={this.state.newDailyDate}
                                  min={new Date('01/01/2021')}
                                  onChange={event => {
                                    this.setState({ newDailyDate: event.target.value });
                                    this.setWeekDates(event.target.value, false);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="d-inline-block">
                              <a
                                type="button"
                                onClick={() => this.prevDay()}
                                className={!this.state.disableDailyPrvBtn ? 'anchor-disabled' : ''}
                              >
                                <div class="rounded-link d-flex align-items-center justify-content-center mr-2">
                                  <span class="k-icon k-i-arrow-chevron-left" />
                                </div>
                              </a>
                              <a type="button" onClick={() => this.nextDay()}>
                                <div class="rounded-link d-flex align-items-center justify-content-center mr-2">
                                  <span class="k-icon k-i-arrow-chevron-right" />
                                </div>
                              </a>
                            </div>
                            <div className="d-inline-block font-weight-bold">
                              {parseInt(this.state.totalHourOfDay) >= 8 ? (
                                <div className="d-inline-block font-weight-bold">
                                  <span className="resc-green ml-3">
                                    {this.state.totalHourOfDay} Hours / 8 Hours
                                  </span>
                                </div>
                              ) : (
                                <div className="d-inline-block font-weight-bold">
                                  <span className="resc-red ml-3">
                                    {this.state.totalHourOfDay} Hours / 8 Hours
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 btn-align-right">
                          <Button
                            primary={true}
                            type="button"
                            disabled={this.state.isFreezedDaily}
                            onClick={() => {
                              this.addDailyTimeEntry(null, this.state.newDailyDate);
                            }}
                          >
                            Add Time
                          </Button>
                          <Button
                            primary={true}
                            type="button"
                            disabled={this.state.isUnfreezeButtonDisableDaily}
                            onClick={() => {
                              this.submitUnfreezeRequestSingle(this.state.newDailyDate);
                            }}
                          >
                            Unfreeze
                          </Button>
                        </div>
                      </div>
                    </div>
                    <Grid
                      resizable
                      className="grid-daily"
                      rowHeight="30"
                      data={orderBy(this.state.timeentrydailygrid, this.state.sortDaily)}
                      sortable={true}
                      sort={this.state.sortDaily}
                      onSortChange={e => {
                        this.setState({
                          sortDaily: e.sort
                        });
                      }}
                    >
                      <Column
                        title="Project/Task Group"
                        field="Project"
                        cell={props => <td>{props.dataItem.Project}</td>}
                      />
                      <Column
                        title="Task"
                        field="Task"
                        cell={props => <td>{props.dataItem.Task}</td>}
                        footerCell={props => (
                          <td colSpan={props.colSpan} style={props.style}>
                            Total
                          </td>
                        )}
                      />
                      <Column
                        title="Duration"
                        field="Duration"
                        width="100"
                        cell={props => (
                          <td>
                            {getDurationInHMS(Number(props.dataItem.Duration).toFixed(2))}{' '}
                            {props.dataItem.FromDateTime && props.dataItem.ToDateTime
                              ? `(${props.dataItem.FromDateTime}:${props.dataItem.ToDateTime})`
                              : null}
                          </td>
                        )}
                        footerCell={props => {
                          // const total = this.state.timeentrydailygrid.reduce((a, b) => a + Number(b['Duration']), 0);
                          return (
                            <td colSpan={props.colSpan} style={props.style}>
                              {this.state.totalHourOfDay}
                            </td>
                          );
                        }}
                      />
                      <Column
                        title="Comment"
                        field="Comment"
                        width=""
                        cell={props => <td>{props.dataItem.Comment}</td>}
                      />
                      <Column
                        title="Ticket ID"
                        field="TicketId"
                        width=""
                        cell={props => <td>{props.dataItem.TicketId}</td>}
                      />
                      <Column
                        title="Work Location"
                        field="WorkLocationId"
                        width=""
                        cell={props => <td>{props.dataItem.WorkLocationName}</td>}
                      />
                      <Column
                        title="Status"
                        width="280"
                        field="approvedStatus"
                        cell={props => getStatusCell(props.dataItem)}
                      />
                      <Column
                        title="Actions"
                        width="100"
                        cell={props => (
                          <td>
                            {!this.state.isFreezedDaily &&
                              (props.dataItem.approvedStatus == 1 && (
                                <span>
                                  <a
                                    type="button"
                                    onClick={e => {
                                      this.addDailyTimeEntry(
                                        props.dataItem,
                                        this.state.newDailyDate
                                      );
                                    }}
                                  >
                                    <div className="rounded-link mr-2" title="Edit Time Entry">
                                      <span className="k-icon k-i-edit" />
                                    </div>
                                  </a>
                                </span>
                              ))}
                            {!this.state.isFreezedDaily &&
                              ((props.dataItem.approvedStatus ==
                                approvedStatus.Pending_Approval.value ||
                                props.dataItem.approvedStatus ==
                                  approvedStatus.Auto_Generated.value ||
                                props.dataItem.approvedStatus == approvedStatus.Leave.value) && (
                                <span>
                                  <a
                                    type="button"
                                    onClick={e => {
                                      this.onDeleteDailyTimeEntry(props.dataItem.id);
                                    }}
                                  >
                                    <div className="rounded-link" title="Delete">
                                      <span className="k-icon k-i-delete" />
                                    </div>
                                  </a>
                                </span>
                              ))}
                          </td>
                        )}
                      />
                    </Grid>
                  </div>
                </div>
              </>
            )}
            {/* End of Daily Section */}
            {/* Weekly Section */}
            {status === 2 && (
              <>
                <div className="row fade-in">
                  <div className="col-md-12">
                    <div className="grid-header mt-2 mb-2">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-inline-block d-flex align-items-center">
                            <div className="d-inline-block mr-2">
                              <label>Date:</label>
                            </div>
                            <div className="d-inline-block mr-2">
                              {this.state.isErrorMessageVisible === true ? (
                                <span className={this.validateDayOfWeek()}>Date is mandatory</span>
                              ) : null}
                              <DatePicker
                                defaultValue={new Date()}
                                format="MM/dd/yyyy"
                                formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                                value={this.state.newDate}
                                min={new Date('01/01/2021')}
                                onChange={event => this.setWeekDates(event.target.value, false)}
                              />
                            </div>

                            <div className="d-inline-block">
                              {this.state.disablePrvBtn ? (
                                <a type="button" onClick={() => this.prevWeek()}>
                                  <div class="rounded-link d-flex align-items-center justify-content-center mr-2">
                                    <span class="k-icon k-i-arrow-chevron-left" />
                                  </div>
                                </a>
                              ) : null}

                              <a type="button" onClick={() => this.nextWeek()}>
                                <div class="rounded-link d-flex align-items-center justify-content-center mr-2">
                                  <span class="k-icon k-i-arrow-chevron-right" />
                                </div>
                              </a>
                            </div>
                            {parseInt(this.state.totalHourOfWeek) >= 40 ? (
                              <div className="d-inline-block font-weight-bold">
                                <span className="resc-green ml-3">
                                  {this.state.totalHourOfWeek} Hours / 40 Hours
                                </span>
                              </div>
                            ) : (
                              <div className="d-inline-block font-weight-bold">
                                <span className="resc-red ml-3">
                                  {this.state.totalHourOfWeek} Hours / 40 Hours
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6 btn-align-right">
                          <Button
                            primary={true}
                            type="button"
                            onClick={e => {
                              this.setState({ isDirty: false });
                              this.setGlobleAddTimeEntryDropDowns(false, true);
                              this.getTaskList(
                                null,
                                null,
                                null,
                                null,
                                true,
                                this.state.firstDayOfWeek,
                                this.state.LastDayOfWeek
                              );
                            }}
                          >
                            Add Time
                          </Button>
                          <Button
                            primary={true}
                            type="button"
                            disabled={this.state.isUnfreezeButtonDisable}
                            onClick={() => this.submitUnfreezeRequestWeekly()}
                          >
                            Unfreeze
                          </Button>
                          <Button
                            disabled={this.state.isFreeze}
                            primary={true}
                            type="button"
                            onClick={e => {
                              this.selectAllButtonHandler(!this.state.selectAll);
                            }}
                          >
                            {this.state.selectAll ? 'Deselect All' : 'Select All'}
                          </Button>
                          <Button
                            primary={true}
                            type="button"
                            disabled={!this.state.isActiveEdit || this.state.isFreeze}
                            onClick={() => this.setEditTimeEntryDropDowns(true, false)}
                          >
                            Edit
                          </Button>
                          <Button
                            type="button"
                            primary={true}
                            disabled={!this.state.isActiveDelete || this.state.isFreeze}
                            onClick={() => this.toggleDeleteConfirmation()}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="grid-weekly">
                      <Grid
                        onRowClick={this.rowClick}
                        style={{ height: '475px' }}
                        selectedField="selected"
                        onSelectionChange={this.rowClick}
                        total={this.state.data.length}
                        skip={this.state.skip}
                        onPageChange={this.pageChange}
                        data={orderBy(this.state.data, this.state.sortWeekly)}
                        sortable={true}
                        sort={this.state.sortWeekly}
                        onSortChange={e => {
                          this.setState({
                            sortWeekly: e.sort
                          });
                        }}
                        onHeaderSelectionChange={this.headerSelectionChange}
                      >
                        <GridColumn field="selected" width="30px" />
                        <GridColumn field="Project" title="Project/Task Group" width="150px" />
                        <GridColumn
                          field="Task"
                          title="Task"
                          footerCell={() => {
                            return <td>Total Hours Entered Per Day</td>;
                          }}
                          width="220px"
                        />
                        <GridColumn
                          field="Mon"
                          title={
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="ts-day">Mon</label>
                                <div>
                                  {this.state.dateOfMon.getMonth() +
                                    1 +
                                    '/' +
                                    this.state.dateOfMon.getDate()}
                                </div>
                              </div>
                              <div className="col-md-2 my-auto">
                                <input
                                  type="checkbox"
                                  id="ts-day"
                                  className="k-checkbox"
                                  name="tsact"
                                  checked={this.state.isAllSelectedMon}
                                  onChange={() => this.selectAllDay(1)}
                                />
                              </div>
                            </div>
                          }
                          footerCell={() => {
                            return <td>{this.state.monDayTotal}</td>;
                          }}
                          cell={this.customCell}
                        />
                        <GridColumn
                          field="Tue"
                          title={
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="ts-day">Tue</label>
                                <div>
                                  {this.state.dateOfTue.getMonth() +
                                    1 +
                                    '/' +
                                    this.state.dateOfTue.getDate()}
                                </div>
                              </div>
                              <div className="col-md-2 my-auto">
                                <input
                                  type="checkbox"
                                  id="ts-day"
                                  className="k-checkbox"
                                  name="tsact"
                                  checked={this.state.isAllSelectedTue}
                                  onChange={() => this.selectAllDay(2)}
                                />
                              </div>
                            </div>
                          }
                          cell={this.customCell}
                          footerCell={() => {
                            return <td>{this.state.tueDayTotal}</td>;
                          }}
                        />
                        <GridColumn
                          field="Wed"
                          title={
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="ts-day">Wed</label>
                                <div>
                                  {this.state.dateOfWed.getMonth() +
                                    1 +
                                    '/' +
                                    this.state.dateOfWed.getDate()}
                                </div>
                              </div>
                              <div className="col-md-2 my-auto">
                                <input
                                  type="checkbox"
                                  id="ts-day"
                                  className="k-checkbox"
                                  name="tsact"
                                  checked={this.state.isAllSelectedWed}
                                  onChange={() => this.selectAllDay(3)}
                                />
                              </div>
                            </div>
                          }
                          cell={this.customCell}
                          footerCell={() => {
                            return <td>{this.state.wedDayTotal}</td>;
                          }}
                        />
                        <GridColumn
                          field="Tur"
                          title={
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="ts-day">Thu</label>
                                <div>
                                  {this.state.dateOfTur.getMonth() +
                                    1 +
                                    '/' +
                                    this.state.dateOfTur.getDate()}
                                </div>
                              </div>
                              <div className="col-md-2 my-auto">
                                <input
                                  type="checkbox"
                                  id="ts-day"
                                  className="k-checkbox"
                                  name="tsact"
                                  checked={this.state.isAllSelectedTur}
                                  onChange={() => this.selectAllDay(4)}
                                />
                              </div>
                            </div>
                          }
                          cell={this.customCell}
                          footerCell={() => {
                            return <td>{this.state.thrDayTotal}</td>;
                          }}
                        />
                        <GridColumn
                          field="Fri"
                          title={
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="ts-day">Fri</label>
                                <div>
                                  {this.state.dateOfFri.getMonth() +
                                    1 +
                                    '/' +
                                    this.state.dateOfFri.getDate()}
                                </div>
                              </div>
                              <div className="col-md-2 my-auto">
                                <input
                                  type="checkbox"
                                  id="ts-day"
                                  className="k-checkbox"
                                  name="tsact"
                                  checked={this.state.isAllSelectedFri}
                                  onChange={() => this.selectAllDay(5)}
                                />
                              </div>
                            </div>
                          }
                          cell={this.customCell}
                          footerCell={() => {
                            return <td>{this.state.friDayTotal}</td>;
                          }}
                        />
                        <GridColumn
                          field="Sat"
                          title={
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="ts-day">Sat</label>
                                <div>
                                  {this.state.dateOfSat.getMonth() +
                                    1 +
                                    '/' +
                                    this.state.dateOfSat.getDate()}
                                </div>
                              </div>
                              <div className="col-md-2 my-auto">
                                <input
                                  type="checkbox"
                                  id="ts-day"
                                  className="k-checkbox"
                                  name="tsact"
                                  checked={this.state.isAllSelectedSat}
                                  onChange={() => this.selectAllDay(6)}
                                />
                              </div>
                            </div>
                          }
                          cell={this.customCell}
                          footerCell={() => {
                            return <td>{this.state.satDayTotal}</td>;
                          }}
                        />
                        <GridColumn
                          field="Sun"
                          title={
                            <div className="row">
                              <div className="col-md-4">
                                <label htmlFor="ts-day">Sun</label>
                                <div>
                                  {this.state.dateOfSun.getMonth() +
                                    1 +
                                    '/' +
                                    this.state.dateOfSun.getDate()}
                                </div>
                              </div>
                              <div className="col-md-2 my-auto">
                                <input
                                  type="checkbox"
                                  id="ts-day"
                                  className="k-checkbox"
                                  name="tsact"
                                  checked={this.state.isAllSelectedSun}
                                  onChange={() => this.selectAllDay(7)}
                                />
                              </div>
                            </div>
                          }
                          cell={this.customCell}
                          footerCell={() => {
                            return <td>{this.state.sunDayTotal}</td>;
                          }}
                        />
                        <GridColumn
                          className=" text-center"
                          field="Total Per Week"
                          width="80px"
                          cell={props => <TotalPerWeek data={props} />}
                        />
                      </Grid>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* End of Weekly Section */}
            {/* All Pending Section */}
            {status === 3 && (
              <>
                <div className="row fade-in">
                  <div className="col-md-12">
                    <div className="grid-header mt-2 mb-2">
                      <div className="row">
                        <div className="col-md-12 btn-align-left d-flex align-items-center">
                          <div className="d-inline-block mr-3">
                            <div className="d-inline-block mr-2">
                              <label>From:</label>
                            </div>
                            <div className="d-inline-block">
                              <DatePicker
                                defaultValue={new Date()}
                                format="MM/dd/yyyy"
                                min={
                                  moment(this.state.allPendingMinDate).format('YYYY-MM-DD') <=
                                  moment('01/01/2021').format('YYYY-MM-DD')
                                    ? new Date('01/01/2021')
                                    : this.state.allPendingMinDate
                                }
                                max={this.state.allPendingToDate}
                                value={this.state.allPendingFromDate}
                                onChange={event => {
                                  this.setState({ allPendingFromDate: event.target.value });
                                  this.getAllPendingEntries(
                                    event.target.value,
                                    this.state.allPendingToDate,
                                    null,
                                    null,
                                    null
                                  );
                                }}
                              />
                            </div>
                          </div>

                          <div className="d-inline-block mr-3">
                            <div className="d-inline-block mr-2">
                              <label>To:</label>
                            </div>
                            <div className="d-inline-block">
                              <DatePicker
                                defaultValue={new Date()}
                                format="MM/dd/yyyy"
                                value={this.state.allPendingToDate}
                                min={
                                  moment(this.state.allPendingFromDate).format('YYYY-MM-DD') <=
                                  moment('01/01/2021').format('YYYY-MM-DD')
                                    ? new Date('01/01/2021')
                                    : this.state.allPendingFromDate
                                }
                                max={this.state.allPendingMaxDate}
                                onChange={event => {
                                  this.setState({ allPendingToDate: event.target.value });
                                  this.getAllPendingEntries(
                                    this.state.allPendingFromDate,
                                    event.target.value,
                                    null,
                                    null,
                                    null
                                  );
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className="d-inline-block mr-3">
                            <input type="checkbox" id="all" className="k-checkbox" />
                            <label className="k-checkbox-label" htmlFor="all">
                              All
                            </label>
                          </div> */}
                          {/* <div className="col-md-6 btn-align-right">
                          <Button
                            primary={true}
                            type="button"
                            onClick={() => {
                              this.addDailyTimeEntry(null);
                            }}
                          >
                            Add Time
                          </Button>
                        </div> */}
                        </div>
                      </div>
                    </div>
                    <Grid
                      resizable
                      data={orderBy(
                        this.state.timeentryallpending,
                        this.state.sortAllPending
                      ).slice(this.state.allPendingSkip, 30 + this.state.allPendingSkip)}
                      sortable={true}
                      sort={this.state.sortAllPending}
                      onSortChange={e => {
                        this.setState({
                          sortAllPending: e.sort
                        });
                      }}
                      pageable={true}
                      skip={this.state.allPendingSkip}
                      take={30}
                      total={this.state.timeentryallpending.length}
                      onPageChange={event => {
                        this.setState({ allPendingSkip: event.page.skip });
                      }}
                    >
                      <Column
                        title="Date"
                        width="130px"
                        field="Date"
                        cell={props => <td>{moment(props.dataItem.Date).format('MM-DD-YYYY')}</td>}
                      />
                      <Column
                        title="Remaining Hours"
                        width="130px"
                        field="Duration"
                        cell={props => (
                          <td>
                            {!isNaN(Number(props.dataItem.Duration))
                              ? this.getDurationInHMS(Number(props.dataItem.Duration).toFixed(2))
                              : props.dataItem.Duration}
                          </td>
                        )}
                      />
                      <Column
                        title="Note"
                        width=""
                        field="isFreezed"
                        cell={props => (
                          <td>
                            {props.dataItem.isFreezed
                              ? props.dataItem.freezedRequestStatus == 1
                                ? 'Unfree Request Submitted'
                                : 'Frozen Timesheet'
                              : 'Open Timesheet'}
                          </td>
                        )}
                      />
                      <Column
                        title="Actions"
                        width=""
                        cell={props => (
                          <td>
                            <a
                              type="button"
                              onClick={() => {
                                this.setWeekDates(new Date(props.dataItem.Date), false);
                                this.state.selectedProjectOrCr !== null
                                  ? this.getTaskList(
                                      this.state.selectedProjectOrCr.projId,
                                      this.state.selectedProjectOrCr.CRId,
                                      this.state.selectedProjectOrCr.NPTId,
                                      this.state.selectedProjectOrCr.Category,
                                      false,
                                      this.state.firstDayOfWeek,
                                      this.state.LastDayOfWeek
                                    )
                                  : this.getTaskList(
                                      null,
                                      null,
                                      null,
                                      null,
                                      false,
                                      this.state.firstDayOfWeek,
                                      this.state.LastDayOfWeek
                                    );
                                this.radioViewHandler(2);
                              }}
                            >
                              <div
                                className="rounded-link mr-2"
                                style={{ transform: 'rotate(90deg)' }}
                                title="Go to Week"
                              >
                                <span className="k-icon k-i-arrows-swap timeentry-icon-alignment" />
                              </div>
                            </a>
                            {!props.dataItem.isFreezed && (
                              <a
                                type="button"
                                onClick={() => {
                                  this.addTimeEntryPending(new Date(props.dataItem.Date));
                                }}
                              >
                                <div className="rounded-link mr-2" title="Add Time">
                                  <span className="k-icon k-i-plus" />
                                </div>
                              </a>
                            )}
                            {props.dataItem.isFreezed && props.dataItem.freezedRequestStatus != 1 && (
                              <a
                                type="button"
                                onClick={() => {
                                  this.submitUnfreezeRequestSingle(new Date(props.dataItem.Date));
                                }}
                              >
                                <div
                                  className="rounded-link"
                                  style={{ transform: 'rotate(90deg)' }}
                                  title="Submit Unfreeze Request"
                                >
                                  <span className="k-icon k-i-cancel-outline timeentry-icon-alignment" />
                                </div>
                              </a>
                            )}
                          </td>
                        )}
                      />
                    </Grid>
                  </div>
                </div>
              </>
            )}{' '}
          </span>
        ) : (
          <Route component={Page404} />
        )}
        {/* End of All Pending Section */}

        {this.state.rejectTa && (
          <Dialog
            title={'Reason for Timesheet Rejection'}
            onClose={this.toggleRejectDialog}
            width={400}
          >
            <div className="row">
              <div className="col-md-2" />
              <div className="col-md-8">
                <div className="d-block">
                  <label>Reason:</label>
                </div>
                <div className="d-block">
                  <textarea className="k-textarea" />
                </div>
              </div>
              <div className="col-md-2" />
            </div>
            <DialogActionsBar>
              <button className="k-button " onClick={this.toggleRejectDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.toggleRejectDialog}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.addTimeentry && (
          <form
            onSubmit={e =>
              !this.state.timeEntryEditMode
                ? this.addTimeEntyHandler(e)
                : this.UpdateTimeEntyHandler(e)
            }
          >
            <Dialog
              title={!this.state.timeEntryEditMode ? 'Add Time' : 'Edit Time'}
              onClose={() => {
                if (this.state.isDirty) {
                  this.setState({
                    cancelTimeEntryPopupVisible: true
                  });
                } else {
                  this.setState({
                    timeEntryEditMode: false,
                    timeEntryAddMode: false
                  });
                  this.toggleAddTimeentry(
                    null,
                    null,
                    null,
                    null,
                    null,
                    false,
                    false,
                    false,
                    null,
                    null,
                    false
                  );
                }
              }}
              width={1000}
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="d-block card-bg-te">
                    <label className="mandatory">Project/Task Group:</label>
                  </div>
                  <div className="d-block">
                    <ComboBox
                      data={this.state.projectDropdownFilter}
                      name="project"
                      textField="DisplayText"
                      placeholder={'Please Select'}
                      onChange={this.timeEntryDataHandler}
                      value={this.state.currentProjectForDropDown}
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                      disabled={
                        this.state.timeEntryEditMode ||
                        this.state.timeEntryAddMode ||
                        this.state.timeEntryData.length > 0 ||
                        this.state.isAddTimeFreeze
                      }
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.currentProjectForDropDown)}>
                        Project/Task Group is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-block">
                    <div className="d-block card-bg-te">
                      <label className="mandatory">Task:</label>
                    </div>
                    <ComboBox
                      data={this.state.taskDropdownFilter}
                      name="taskDropDown"
                      textField="Task"
                      placeholder={'Please Select'}
                      onChange={this.timeEntryDataHandler}
                      value={this.state.currentTaskInDropDown}
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                      disabled={
                        this.state.timeEntryEditMode ||
                        this.state.timeEntryAddMode ||
                        this.state.timeEntryData.length > 0 ||
                        this.state.isAddTimeFreeze
                      }
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.currentTaskInDropDown)}>
                        Task is mandatory
                      </span>
                    ) : null}
                    {this.state.isTaskExist === true ? (
                      <span className="inline-error">
                        In this week no task for selected project
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-block">
                    <div className="d-block card-bg-te">
                      <label className="mandatory">Work Location:</label>
                    </div>
                    <ComboBox
                      data={this.workLocationStatuses}
                      name="workLocation"
                      textField="name"
                      placeholder={'Please Select'}
                      onChange={this.handleTimeCardInput}
                      value={this.state.workLocation}
                      filterable={true}
                      disabled={
                        this.state.currentProjectForDropDown && 
                        (this.state.currentProjectForDropDown.DisplayText == 'HR - Holidays' || 
                        this.state.currentProjectForDropDown.DisplayText == 'HR - Leave')
                      }
                    />
                    {this.state.isErrorMessageVisible === true && this.state.isLocationErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.workLocation)}>
                        Work Location is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-block">
                    <div className="d-block card-bg-te">
                      <label className="mandatory">Date:</label>
                    </div>
                    <DatePicker
                      name="dateOfTimeEntry"
                      onChange={this.timeEntryDataHandler}
                      value={this.state.SelectedCurrentDate}
                      min={
                        this.state.currentTaskInDropDown !== null &&
                        moment(this.state.currentTaskInDropDown.StartDate).format('YYYY-MM-DD') >=
                          moment(this.state.firstDayOfWeek).format('YYYY-MM-DD')
                          ? new Date(this.state.currentTaskInDropDown.StartDate)
                          : new Date(this.state.firstDayOfWeek)
                      }
                      max={
                        this.state.currentTaskInDropDown !== null &&
                        moment(this.state.currentTaskInDropDown.EndDate).format('YYYY-MM-DD') <=
                          moment(this.state.LastDayOfWeek).format('YYYY-MM-DD')
                          ? new Date(this.state.currentTaskInDropDown.EndDate)
                          : new Date(this.state.LastDayOfWeek)
                      }
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      disabled={
                        this.state.timeEntryEditMode ||
                        this.state.timeEntryAddMode ||
                        this.state.timeEntryData.length > 0
                      }
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.SelectedCurrentDate)}>
                        Date is mandatory
                      </span>
                    ) : null}
                    {this.state.isDateErrorMessageVisible === true &&
                    this.state.currentTaskInDropDown !== null &&
                    this.state.SelectedCurrentDate !== null ? (
                      <span
                        className={this.validateGlobleAddDatePicker(
                          this.state.currentTaskInDropDown.disableDateList,
                          this.state.SelectedCurrentDate
                        )}
                      >
                        Task is not available on the selected date
                      </span>
                    ) : this.state.isAddTimeFreeze === true ? (
                      <span className="inline-error">
                        Selected date is frozen, and timesheets cannot be entered
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-4">
                  <div className="d-block card-bg-te">
                    <label className="mandatory">Comment:</label>
                  </div>
                  <div className="d-block">
                    <textarea
                      className="k-textarea"
                      name="comment0"
                      id="comment0"
                      value={this.state.comment}
                      onChange={this.handleTimeCardInput}
                      maxLength={250}
                      disabled={this.state.isAddTimeFreeze}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span
                        className={this.validateProperty(document.getElementById('comment0').value)}
                      >
                        Comment is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-block card-bg-te">
                        <label className="mandatory">Duration:</label>
                      </div>
                      <div className="d-block">
                        <Input
                          name="duration0"
                          value={this.state.duration}
                          onChange={this.handleTimeCardInput}
                          onBlur={this.blurDurationInput}
                          disabled={this.state.isAddTimeFreeze}
                        />
                        {this.state.isErrorMessageVisible === true ? (
                          <span className={this.validateDurationNullOrNot(this.state.duration)}>
                            Duration is mandatory
                          </span>
                        ) : null}
                        {this.state.isErrorMessageVisible === true ? (
                          <span className={this.validateDuration()}>
                            Duration is not in correct format
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-block card-bg-te">
                        <label>Ticket ID:</label>
                      </div>
                      <div className="d-block">
                        <Input
                          name="ticketId"
                          value={this.state.ticketId}
                          onChange={this.handleTimeCardInput}
                          maxLength={20}
                        />
                        {this.state.isTicketIdErrorMessageVisible === true ? (
                          <span className={this.validateProperty(this.state.ticketId)}>Ticket ID is mandatory, if Ticket ID is not relevant enter N/A</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-block card-bg-te">
                        <label>Start Time:</label>
                      </div>
                      <div className="d-block">
                        <TimePicker
                          className="w-100"
                          name="startTime0"
                          popup={TimePickerPopup}
                          value={this.state.startTime}
                          onChange={this.handleTimeCardInput}
                          format={'HH:mm'}
                          disabled={!this.state.isShiftTask || this.state.isAddTimeFreeze}
                        />
                        {this.state.isErrorMessageVisible === true ? (
                          <span className={this.validateStartTime()}>Start time is mandatory</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-block card-bg-te">
                        <label>End Time:</label>
                      </div>
                      <div className="d-block">
                        <TimePicker
                          className="w-100"
                          name="endTime0"
                          popup={TimePickerPopup}
                          value={this.state.endTime}
                          onChange={this.handleTimeCardInput}
                          format={'HH:mm'}
                          disabled={!this.state.isShiftTask || this.state.isAddTimeFreeze}
                        />
                        {this.state.isErrorMessageVisible === true ? (
                          <span className={this.validateEndTime()}>End time is mandatory</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 d-flex align-items-top mt-3">
                  <div>
                    <Button
                      type="button"
                      primary={true}
                      onClick={this.addTimeEntry}
                      className="rounded-btn mt-3"
                      disabled={this.state.timeEntryEditMode || this.state.isAddTimeFreeze}
                    >
                      <span className="k-icon k-i-plus" />
                    </Button>
                  </div>
                </div>
              </div>

              <div>
                {!this.state.timeEntryEditMode && (
                  <Grid
                    data={this.state.timeEntryData}
                    resizable={true}
                    style={{ maxHeight: '250px' }}
                  >
                    <GridColumn field="comment" title="Comment" />
                    <GridColumn field="TicketId" title="Ticket ID" />
                    <GridColumn field="duration" title="Duration" />
                    <GridColumn field="WorkLocationName" title="Work Location" />
                    <GridColumn field="startTime" title="Start Time" />
                    <GridColumn field="endTime" title="End Time" />
                    <GridColumn
                      field=""
                      title="Actions"
                      cell={props => (
                        <ActionsCell
                          {...props}
                          removeTimeEntry={this.removeTimeEntryFromGrid}
                          editTimeEntry={this.editTimeEntryInGrid}
                        />
                      )}
                    />
                  </Grid>
                )}
              </div>
              <DialogActionsBar>
                <button
                  className="k-button "
                  type="button"
                  onClick={() => {
                    if (this.state.isDirty) {
                      this.setState({
                        cancelTimeEntryPopupVisible: true
                      });
                    } else {
                      this.setState({
                        timeEntryEditMode: false,
                        timeEntryAddMode: false
                      });
                      this.toggleAddTimeentry(
                        null,
                        null,
                        null,
                        null,
                        null,
                        false,
                        false,
                        false,
                        null,
                        null,
                        false
                      );
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="k-button modal-primary"
                  disabled={!this.state.isDirty || this.state.isAddTimeFreeze}
                >
                  Submit
                </button>
              </DialogActionsBar>
            </Dialog>
          </form>
        )}
        {this.state.addTimeentrydaily && (
          <form
            onSubmit={e =>
              !this.state.timeEntryEditMode
                ? this.addTimeEntyHandler(e)
                : this.UpdateTimeEntyHandler(e)
            }
          >
            <Dialog
              title={!this.state.timeEntryEditMode ? 'Add Time' : 'Edit Time'}
              onClose={() => {
                if (this.state.isDirty) {
                  this.setState({
                    cancelTimeEntryPopupVisible: true
                  });
                } else {
                  this.setState({
                    timeEntryEditMode: false,
                    timeEntryAddMode: false,
                    addTimeentrydaily: false
                  });
                }
              }}
              width={1000}
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="d-block card-bg-te">
                    <label className="mandatory">Projects/ Task Group:</label>
                  </div>
                  <div className="d-block">
                    <ComboBox
                      data={this.state.projectDropdownFilter}
                      name="project"
                      textField="DisplayText"
                      placeholder={'Please Select'}
                      onChange={this.dailyTimeEntryDataHandler}
                      value={this.state.currentProjectForDropDown}
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                      disabled={
                        this.state.timeEntryEditMode ||
                        this.state.timeEntryData.length > 0 ||
                        this.state.isAddTimeFreeze
                      }
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.currentProjectForDropDown)}>
                        Project is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-block">
                    <div className="d-block card-bg-te">
                      <label className="mandatory">Task:</label>
                    </div>
                    <ComboBox
                      data={this.state.taskDropdownFilter}
                      name="taskDropDown"
                      textField="Task"
                      placeholder={'Please Select'}
                      onChange={this.timeEntryDataHandler}
                      value={this.state.currentTaskInDropDown}
                      filterable={true}
                      onFilterChange={this.filterOnChange}
                      disabled={
                        this.state.timeEntryEditMode ||
                        this.state.timeEntryData.length > 0 ||
                        this.state.isAddTimeFreeze
                      }
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.currentTaskInDropDown)}>
                        Task is mandatory
                      </span>
                    ) : null}
                    {this.state.isTaskExist === true ? (
                      <span className="inline-error">
                        In this week no task for selected project
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-block">
                    <div className="d-block card-bg-te">
                      <label className="mandatory">Work Location:</label>
                    </div>
                    <ComboBox
                      data={this.workLocationStatuses}
                      name="workLocation"
                      textField="name"
                      placeholder={'Please Select'}
                      onChange={this.handleTimeCardInput}
                      value={this.state.workLocation}
                      filterable={true}
                      disabled={
                        this.state.currentProjectForDropDown && 
                        (this.state.currentProjectForDropDown.DisplayText == 'HR - Holidays' || 
                        this.state.currentProjectForDropDown.DisplayText == 'HR - Leave')
                      }
                    />
                    {this.state.isErrorMessageVisible === true && this.state.isLocationErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.workLocation)}>
                        Work Location is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-block">
                    <div className="d-block card-bg-te">
                      <label className="mandatory">Date:</label>
                    </div>
                    <DatePicker
                      name="dateOfTimeEntry"
                      onChange={this.timeEntryDataHandler}
                      value={this.state.SelectedCurrentDate}
                      format="MM/dd/yyyy"
                      min={
                        this.state.currentTaskInDropDown !== null &&
                        moment(this.state.currentTaskInDropDown.StartDate).format('YYYY-MM-DD') >=
                          moment(this.state.firstDayOfWeek).format('YYYY-MM-DD')
                          ? new Date(this.state.currentTaskInDropDown.StartDate)
                          : new Date(this.state.firstDayOfWeek)
                      }
                      max={
                        this.state.currentTaskInDropDown !== null &&
                        moment(this.state.currentTaskInDropDown.EndDate).format('YYYY-MM-DD') <=
                          moment(this.state.LastDayOfWeek).format('YYYY-MM-DD')
                          ? new Date(this.state.currentTaskInDropDown.EndDate)
                          : new Date(this.state.LastDayOfWeek)
                      }
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      disabled={
                        this.state.timeEntryEditMode ||
                        this.state.timeEntryAddMode ||
                        this.state.timeEntryData.length > 0 ||
                        this.state.status === 3
                      }
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateProperty(this.state.SelectedCurrentDate)}>
                        Date is mandatory
                      </span>
                    ) : null}
                    {this.state.isDateErrorMessageVisible === true &&
                    this.state.currentTaskInDropDown !== null &&
                    this.state.SelectedCurrentDate !== null ? (
                      <span
                        className={this.validateGlobleAddDatePicker(
                          this.state.currentTaskInDropDown.disableDateList,
                          this.state.SelectedCurrentDate
                        )}
                      >
                        Task is not available on the selected date
                      </span>
                    ) : this.state.isAddTimeFreeze === true ? (
                      <span className="inline-error">
                        Selected date is frozen, and timesheets cannot be entered
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-4">
                  <div className="d-block card-bg-te">
                    <label className="mandatory">Comment:</label>
                  </div>
                  <div className="d-block">
                    <textarea
                      className="k-textarea"
                      name="comment0"
                      id="comment0"
                      value={this.state.comment}
                      onChange={this.handleTimeCardInput}
                      maxLength={250}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span
                        className={this.validateProperty(document.getElementById('comment0').value)}
                      >
                        Comment is mandatory
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-block card-bg-te">
                        <label className="mandatory">Duration:</label>
                      </div>
                      <div className="d-block">
                        <Input
                          name="duration0"
                          value={this.state.duration}
                          onChange={this.handleTimeCardInput}
                          onBlur={this.blurDurationInput}
                          disabled={this.state.isAddTimeFreeze}
                        />
                        {this.state.isErrorMessageVisible === true ? (
                          <span className={this.validateDurationNullOrNot(this.state.duration)}>
                            Duration is mandatory
                          </span>
                        ) : null}
                        {this.state.isErrorMessageVisible === true ? (
                          <span className={this.validateDuration()}>
                            Duration is not in correct format
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-block card-bg-te">
                        <label>Ticket ID:</label>
                      </div>
                      <div className="d-block">
                        <Input
                          name="ticketId"
                          value={this.state.ticketId}
                          onChange={this.handleTimeCardInput}
                          maxLength={20}
                        />
                        {this.state.isTicketIdErrorMessageVisible === true ? (
                          <span className={this.validateProperty(this.state.ticketId)}>Ticket ID is mandatory, if Ticket ID is not relevant enter N/A</span>
                        ) : null}
                      </div>
                    </div>
                </div>
              </div>

                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-block card-bg-te">
                        <label>Start Time:</label>
                      </div>
                      <div className="d-block">
                        <TimePicker
                          className="w-100"
                          name="startTime0"
                          popup={TimePickerPopup}
                          value={this.state.startTime}
                          onChange={this.handleTimeCardInput}
                          format={'HH:mm'}
                          disabled={!this.state.isShiftTask || this.state.isAddTimeFreeze}
                        />
                        {this.state.isErrorMessageVisible === true ? (
                          <span className={this.validateStartTime()}>Start time is mandatory</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-block card-bg-te">
                        <label>End Time:</label>
                      </div>
                      <div className="d-block">
                        <TimePicker
                          className="w-100"
                          name="endTime0"
                          popup={TimePickerPopup}
                          value={this.state.endTime}
                          onChange={this.handleTimeCardInput}
                          format={'HH:mm'}
                          disabled={!this.state.isShiftTask || this.state.isAddTimeFreeze}
                        />
                        {this.state.isErrorMessageVisible === true ? (
                          <span className={this.validateEndTime()}>End time is mandatory</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 d-flex align-items-top mt-3">
                  <div>
                    <Button
                      type="button"
                      primary={true}
                      onClick={this.addTimeEntry}
                      className="rounded-btn mt-3"
                      disabled={this.state.timeEntryEditMode || this.state.isAddTimeFreeze}
                    >
                      <span className="k-icon k-i-plus" />
                    </Button>
                  </div>
                </div>
              </div>

              <div>
                {!this.state.timeEntryEditMode && (
                  <Grid
                    data={this.state.timeEntryData}
                    resizable={true}
                    style={{ maxHeight: '250px' }}
                  >
                    <GridColumn field="comment" title="Comment" />
                    <GridColumn field="TicketId" title="Ticket ID" />
                    <GridColumn field="duration" title="Duration" />
                    <GridColumn field="WorkLocationName" title="Work Location" />
                    <GridColumn field="startTime" title="Start Time" />
                    <GridColumn field="endTime" title="End Time" />
                    <GridColumn
                      field=""
                      title="Actions"
                      cell={props => (
                        <ActionsCell
                          {...props}
                          removeTimeEntry={this.removeTimeEntryFromGrid}
                          editTimeEntry={this.editTimeEntryInGrid}
                        />
                      )}
                    />
                  </Grid>
                )}
              </div>
              <DialogActionsBar>
                <button
                  className="k-button "
                  type="button"
                  onClick={() => {
                    if (this.state.isDirty) {
                      this.setState({
                        cancelTimeEntryPopupVisible: true
                      });
                    } else {
                      this.setState({
                        timeEntryEditMode: false,
                        timeEntryAddMode: false,
                        addTimeentrydaily: false
                      });
                    }
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="k-button modal-primary"
                  disabled={!this.state.isDirty || this.state.isAddTimeFreeze}
                >
                  Submit
                </button>
              </DialogActionsBar>
            </Dialog>
          </form>
        )}
        {this.state.deletePopupVisible && (
          <Dialog title={'Please Confirm'} onClose={() => this.toggleDeleteConfirmation()}>
            <p style={{ margin: '25px', textAlign: 'center' }}>
              Are you sure you want to delete this time entries ?
            </p>
            <DialogActionsBar>
              <button className="k-button" onClick={() => this.toggleDeleteConfirmation()}>
                No
              </button>
              <button
                className="k-button modal-primary"
                onClick={() => {
                  this.setState({
                    monDayTotal: 0,
                    tueDayTotal: 0,
                    wedDayTotal: 0,
                    thrDayTotal: 0,
                    friDayTotal: 0,
                    satDayTotal: 0,
                    sunDayTotal: 0,
                    totalHourOfWeek: 0
                  });
                  this.deleteTimeEntry();
                }}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.cancelTimeEntryPopupVisible && (
          <Dialog
            title={'Please Confirm'}
            onClose={() => this.setState({ cancelTimeEntryPopupVisible: false })}
          >
            <p style={{ margin: '25px', textAlign: 'center' }}>
              This form contains unsaved data. Do you want to close it?
            </p>
            <DialogActionsBar>
              <button
                className="k-button"
                onClick={() => this.setState({ cancelTimeEntryPopupVisible: false })}
              >
                No
              </button>
              <button
                className="k-button modal-primary"
                onClick={() => {
                  if (!this.state.addTimeentrydaily) {
                    this.toggleAddTimeentry(
                      null,
                      null,
                      null,
                      null,
                      null,
                      false,
                      false,
                      false,
                      null,
                      null,
                      false
                    );
                    this.getTimeCards();
                  }
                  this.setState({
                    cancelTimeEntryPopupVisible: false,
                    isActiveDelete: false,
                    isActiveEdit: false,
                    timeEntryData: [],
                    timeEntryEditMode: false,
                    timeEntryAddMode: false,
                    addTimeentrydaily: false,
                    isDirty: false
                  });
                }}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.popUpBox.isPopUp && (
          <Dialog title={this.state.popUpBox.popUpTitile} onClose={() => this.onClosePopupBox()}>
            <p style={{ margin: '25px', textAlign: 'center' }}>
              {this.state.popUpBox.popUpMessage}
            </p>
            <DialogActionsBar>
              <button className="k-button" onClick={() => this.onClosePopupBox()}>
                No
              </button>
              <button
                className="k-button modal-primary"
                onClick={() => {
                  this.state.popUpBox.callBack();
                }}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.nonbilldialog && (
          <Dialog
            title={'Select Non-Billable Category'}
            onClose={this.toggleNonbillDialog}
            width={400}
          >
            <div className="row">
              <div className="col-md-3" />
              <div className="col-md-6">
                <div className="d-block">
                  <label>Category:</label>
                </div>
                <div className="d-block">
                  <ComboBox />
                </div>
                <div className="col-md-3" />
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button " onClick={this.toggleNonbillDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.toggleNonbillDialog}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.successPopUpvisible === true && (
          <Dialog title={'Success'} onClose={this.toggleDialog} width="400px">
            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}
        <Loader loading={this.state.loader} />
        {this.state.unfreezePopupVisible && (
          <Dialog
            title={'Reason to Unfreeze Timesheet'}
            onClose={this.toggleUnfreezeDialog}
            width={400}
          >
            {this.state.havingtwoMonthBackDates === true && (
              <p style={{ margin: '10px', textAlign: 'center' }}>{this.state.unfreezeMessage}</p>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-block">
                      <label className="mandatory">Reason:</label>
                      <div className="d-block">
                        <textarea
                          className="k-textarea"
                          name="unfreezeReason"
                          id="unfreezeReason"
                          value={this.state.unfreezeReason}
                          onChange={this.handleUnfreezeReason}
                          maxLength={500}
                        />
                      </div>
                      {this.state.isErrorMessageVisibleReason === true ? (
                        <span className={this.validateProperty(this.state.unfreezeReason)}>
                          Reason is mandatory
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleUnfreezeDialog}>
                Cancel
              </button>
              <button className="k-button modal-primary" onClick={this.SubmitUnfreezeRequest}>
                Submit Unfreeze Request
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showWorkflowSelectDialog === true && (
          <Dialog title="Select Workflow" onClose={this.toggleWorkflowSelectDialog} width="300px">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <p className="subtitle-workflow" style={{ textAlign: 'center' }}>
                  Please select the applicable workflow to send for approval.
                  </p>
                </div>
                <div className="row">
                  <form className="k-form modified">
                    <div className="k-form-field">
                      {this.state.matchingWorkflowList.map((eachOption, i) => {
                        return (
                          <div className="field-mod">
                            <input
                              type="radio"
                              id={'' + eachOption.id}
                              name={'' + eachOption.id}
                              value={eachOption.id}
                              checked={this.state.selectedWorkFlowId === eachOption.id}
                              className="k-radio"
                              key={eachOption.id}
                              onChange={this.setSelectedWorkflowId.bind(this, eachOption.id)}
                            />
                            <label
                              key={eachOption.id}
                              htmlFor={'' + eachOption.id}
                              className="k-radio-label"
                              title={"Approvers: " + eachOption.approvers}
                            >
                              {eachOption.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleWorkflowSelectDialog}>
                No
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.setWorkflowToResources}
                disabled={!this.state.selectedWorkFlowId}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}
        {this.state.showMessageDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success" />
              </div>
            )}

            {this.state.showErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image" />
              </div>
            )}
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

            <DialogActionsBar>
              {this.state.showSuccessImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}

              {this.state.showErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    );
  }
}

export default TimeSheetEntry;

class TimePickerPopup extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Popup
        {...this.props}
        anchorAlign={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        popupAlign={{
          horizontal: 'center',
          vertical: 'center'
        }}
      />
    );
  }
}

class TotalPerWeek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPerWeek: 0
    };
  }
  componentDidMount() {
    let totalPerWeekHour = 0;
    let totalPerWeekMinute = 0;
    this.props.data.dataItem.Mon.cards.length !== 0 &&
      this.props.data.dataItem.Mon.cards.forEach(x => {
        if (
          x.approvedStatus !== approvedStatus.Rejected.value &&
          x.approvedStatus !== approvedStatus.Leave.value
        ) {
          totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
          if (typeof x.duration.split(':')[1] !== 'undefined') {
            totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
          }
        }
      });
    this.props.data.dataItem.Tue.cards.length !== 0 &&
      this.props.data.dataItem.Tue.cards.forEach(x => {
        if (
          x.approvedStatus !== approvedStatus.Rejected.value &&
          x.approvedStatus !== approvedStatus.Leave.value
        ) {
          totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
          if (typeof x.duration.split(':')[1] !== 'undefined') {
            totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
          }
        }
      });
    this.props.data.dataItem.Wed.cards.length !== 0 &&
      this.props.data.dataItem.Wed.cards.forEach(x => {
        if (
          x.approvedStatus !== approvedStatus.Rejected.value &&
          x.approvedStatus !== approvedStatus.Leave.value
        ) {
          totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
          if (typeof x.duration.split(':')[1] !== 'undefined') {
            totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
          }
        }
      });
    this.props.data.dataItem.Tur.cards.length !== 0 &&
      this.props.data.dataItem.Tur.cards.forEach(x => {
        if (
          x.approvedStatus !== approvedStatus.Rejected.value &&
          x.approvedStatus !== approvedStatus.Leave.value
        ) {
          totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
          if (typeof x.duration.split(':')[1] !== 'undefined') {
            totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
          }
        }
      });
    this.props.data.dataItem.Fri.cards.length !== 0 &&
      this.props.data.dataItem.Fri.cards.forEach(x => {
        if (
          x.approvedStatus !== approvedStatus.Rejected.value &&
          x.approvedStatus !== approvedStatus.Leave.value
        ) {
          totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
          if (typeof x.duration.split(':')[1] !== 'undefined') {
            totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
          }
        }
      });
    this.props.data.dataItem.Sat.cards.length !== 0 &&
      this.props.data.dataItem.Sat.cards.forEach(x => {
        if (
          x.approvedStatus !== approvedStatus.Rejected.value &&
          x.approvedStatus !== approvedStatus.Leave.value
        ) {
          totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
          if (typeof x.duration.split(':')[1] !== 'undefined') {
            totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
          }
        }
      });
    this.props.data.dataItem.Sun.cards.length !== 0 &&
      this.props.data.dataItem.Sun.cards.forEach(x => {
        if (
          x.approvedStatus !== approvedStatus.Rejected.value &&
          x.approvedStatus !== approvedStatus.Leave.value
        ) {
          totalPerWeekHour = totalPerWeekHour + parseInt(x.duration.split(':')[0]);
          if (typeof x.duration.split(':')[1] !== 'undefined') {
            totalPerWeekMinute = totalPerWeekMinute + parseInt(x.duration.split(':')[1]);
          }
        }
      });
    this.setState({
      totalPerWeek:
        totalPerWeekMinute === 0
          ? totalPerWeekHour + ':' + '00'
          : totalPerWeekHour +
            parseInt(totalPerWeekMinute / 60) +
            ':' +
            (totalPerWeekMinute % 60).toString().padStart(2, '0')
    });
  }
  render() {
    return <td className="text-center totperweek">{this.state.totalPerWeek}</td>;
  }
}
