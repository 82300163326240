import React, { Component } from 'react';

class PermissionListCell extends Component {
    render() {
        const permissions = this.props.dataItem.Permissions;
        
        var html = "";
        var i = 1;
        for (let permission of permissions) {
            
            if(i >= permissions.length) {
                html += ''+  permission.PermissionDescription  + ''; 
            } else {
                html += ''+  permission.PermissionDescription  + ', '; 
            }
            
            i++;
        }
        return <td>{html}</td>;

    }
}

export default PermissionListCell;
