import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { replaceFile, uploadFile } from '../../integration/fileUpload/FileUploadService'
import * as loggerService from "../../integration/LoggerService";
import { LOG_TYPES } from "../../integration/IntegrationEnums";

import { Upload } from '@progress/kendo-react-upload';

import { getBillingDiviIntegrated, getRegionInfoIntegrated } from '../../integration/MasterDataService';
import { filterBy } from '@progress/kendo-data-query';
import { getUserList } from '../../userManagement/user/userService';
import { saveQMSUpload, getQMSUploadsList, searchQmsTemp, deleteQmsTemplate, updateQMSUpload } from '../search/QmsTempService';
import { Redirect } from 'react-router-dom';
import { deleteFileById } from '../../integration/fileUpload/FileUploadService';
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../../src/ui/static/images/checked.svg';
import ErrorImg from '../../../src/ui/static/images/cancel.svg';
import moment from 'moment';

const apiUrl = require('../../../src/config').get(process.env.REACT_APP_ENV).apiUrl;

class AddTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            qmsTemplateId: "", 
            selectedCvFile: '',
            isLoading: false,
            fileType: 'qmsTemplate',
            loaded: 0,
            showSuccessImage: false,
            showErrorImage: true,
            disabledSave: false,
            isCannotUpload: true,
            ResourceId: '',
            billingDivisions: [],
            billingDivisionsAll: [],
            selectedDivisionValue: [],
            DocumentName: '',
            QMSUploadsComment: '',
            loggedUser: '',
            user:'',
            userId: '',
            selectedQMSTemplateFile: [],
            showSuccessImg: false,
            visibleConfirmationDialog: false,
            isForedirectToSearchQMSrmDirty: false,
            action: '',
            redirectToSearchQMS: false,
            isActive: true,
            QMSUploadsList: [],
            QMSUploadsListByDivision: [],
            docNamesByDivision: [],
            docCodesByDivision: [],
            isErrorMessageVisible: false,
            isDocNameUnique: true,
            isDocCodeUnique: true,
            selectedDivision: [],
            docNameErrorMessageVisible: false,
            docCodeErrorMessageVisible: false,
            documentIdToDelete: null,
            visibleDeleteConfirmDialog: false,
            loading: false,
            qmsUploadsId: null,
            DocumentNameEdit: '',
            saveSuccess: false,
            isFormDirty: false,
            isFileChanged: false,
            isDivisionChanged: false,
            isDocNameChanged: false,
            isDocCodePrefixChanged: false,
            isDocCodeSuffixChanged: false,
            isCommentChanged: false,
            billingRegions: [],
            billingRegionsAll: [],
            selectedRegion: [],
            selectedRegionValue : null,
            documentCodePrefixes : [{id: 1 , Name: 'INTELLINT'}, {id: 2, Name: 'FTD'}],
            selectedDocCodePrefix: [],
            documentCodeSuffix : [],
            DocumentCodePrefixEdit: [],
            DocumentCodeSuffixEdit: [],
            selectedDivisionValueEdit: [],
            RegionEdit: '',
            visible: false,
            isRegionChanged: false
        }
    }

    componentDidMount() {
        const userDetails = window.LOGGED_USER;
        this.setState({
            loggedUser: userDetails.userId
        });
        window.scrollTo(0, 0);
        this.isMount = true;
        this.setHeaderTitle();
        this.populateDropDownData();
        this.populateUserId();
        this.populateEditData();
        this.populateQMSUploadsList();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    validateProperty = value => {
        if (value) {
            return 'd-none';
        } else {
            return 'inline-error';
        }
    };

    setHeaderTitle = async () => {
        if (this.isMount && this.props.onHeaderTitleChange) {
            if(this.props.location.action == 'create'){
                this.props.onHeaderTitleChange('Add Template');
            }
            else{
                this.props.onHeaderTitleChange('Edit Template');
            }
        }
    };
    
    onChangeHandlerTemplate = async event => {

        const templateSize = (event.target.files[0].size)/1024/1024;

        if((event.target.files[0].type === 'application/pdf' || 
            event.target.files[0].type === 'image/jpeg' || 
            event.target.files[0].type === 'image/png' || 
            event.target.files[0].type === 'application/vnd.ms-project' ||
            event.target.files[0].type === "application/msword" ||
            event.target.files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            event.target.files[0].type === 'application/vnd.ms-powerpoint' ||
            event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
            event.target.files[0].type === 'application/vnd.ms-excel' || 
            event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' ||
          event.target.files[0].name.includes('.mpp')) &&
            (templateSize <= 30)) {                                                                          
            await this.setState({
                selectedQMSTemplateFile: event.target.files[0],
                loaded: 0,
                isCannotUpload: false,
                loading: true
            });
        }
        else if(templateSize > 30){
            await this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                disabledSave: false,
                isCannotUpload: true
            });
            await this.toggleDialog('Your file exceed 30 MB of maximum size allowed for upload files', 'Error');
        }
        else {
            const message = "Template can be only added as a 'pdf, png, jpg, doc, docx, dotx, xls, xlsx, ppt, pptx, or mpp' file";
            const title = 'Error';
            await this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                disabledSave: false,
                isCannotUpload: true
            });
            await this.toggleDialog(message, title);
        }

        if (this.state.isCannotUpload === false) {
            // UPLOAD LOGIC
            var form_data = new FormData();
            form_data.append('file', this.state.selectedQMSTemplateFile);
            form_data.append('referenceType', 'QMSTemplate');
            form_data.append('referenceId', this.state.userId);

            await uploadFile(form_data).then(res => {
                if (res) {
                    this.setState({
                        selectedQMSTemplateFile: res.data,
                        loading: false,
                        isFormDirty: true,
                        isFileChanged: true,
                        showSuccessImage: true,
                        showErrorImage: false,
                    });
                }
                this.toggleDialog('File has been uploaded successfully', 'Upload Status');
            }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.setState({
                    loading: false
                });
                if (error.response.status === 400) {
                    this.setState({
                        loading: false,
                        fileType: 'qmsTemplate',
                        showSuccessImage: false,
                        showErrorImage: true,
                    });
                    this.toggleFileReplaceConfirmDialog(error.response.data, this.replaceFile);
                }
                if (error.response.status === 413) {
                    this.setState({
                        showSuccessImage: false,
                        showErrorImage: true,
                    });
                    this.toggleDialog('Your file exceed 30 MB of maximum size allowed for upload files', 'Error')
                }
            });
        }
    };

    toggleDialog = (message, title) => {
        this.setState({
            visible: !this.state.visible,
            dialogMessage: message,
            dialogTitle: title,
        });
    };

    toggleFileReplaceConfirmDialog = async (message) => {
        await this.setState({
            showFileReplaceConfirmDialog: !this.state.showFileReplaceConfirmDialog,
            confirmReplaceDialogMessage: message,
        });
    };

    toggleConfirmationDialog = (message, title) => {
        this.setState({
            visibleConfirmationDialog: !this.state.visibleConfirmationDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    toggleDeleteConfirmDialog = (message, action) => {
        this.setState({
            visibleDeleteConfirmDialog: !this.state.visibleDeleteConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action
        });
    };

    populateEditData = () => {
        if (this.props.location.action === 'edit'){
            this.setState({
                selectedDivisionValue: this.props.location.division,
                selectedDivisionValueInit : this.props.location.division,
                DocumentName: this.props.location.docName,
                DocumentNameInit: this.props.location.docName,
                DocumentCodeInit: this.props.location.docCode,
                QMSUploadsComment: this.props.location.description,
                QMSUploadsCommentInit: this.props.location.description,
                selectedQMSTemplateFile: this.props.location.qmsTemplate,
                selectedQMSTemplateFileInit: this.props.location.qmsTemplate,
                qmsUploadsId: this.props.location.id,
                selectedRegionValue: this.props.location.region ? this.props.location.region : null,
                selectedDocCodePrefix : this.props.location.docCodePrefix.map(v => v == '1' ? this.state.documentCodePrefixes[0] : this.state.documentCodePrefixes[1]),
                documentCodeSuffix : this.props.location.docCodeSuffix
            });
        }
    }

    populateDropDownData = async() => {
        try{
        const {data} = await getBillingDiviIntegrated();
        const regionData = await getRegionInfoIntegrated();

            const billingDivisions = [];
            const billingRegion = [];

            data.forEach(function(division){
                billingDivisions.push(division);
            });
            regionData.data.forEach(function (region) {
                if (region.IsActive === true) {
                    billingRegion.push(region);
                }
            });

            if(this.isMount){
                const activeBillingDivisions = billingDivisions.filter(value => value.IsActive === true && value.SFCode !== null);
                this.setState({
                    billingDivisions: activeBillingDivisions,
                    billingDivisionsAll: activeBillingDivisions,
                    billingRegions: billingRegion,
                    billingRegionsAll: billingRegion
                });
            }

        }catch (error){
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        }

    };

    populateUserId = () =>{
        getUserList().then(response => {
            for(const user of response.data){
                if(user.Username.toUpperCase() === this.state.loggedUser.toUpperCase()){
                    this.setState({
                        user: user,
                        userId: user.id
                    });
                }
            }
        }).catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

   populateQMSUploadsList = () => {
        const fields = {};
        searchQmsTemp(fields).then(response => {
            if(this.isMount){
                const allTemplates = response.data.filter(value => value);
                this.setState({
                    QMSUploadsList: allTemplates
                });
            }
        }).catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    };

    filterChangeCombo = event => {
        clearTimeout(this.timeout);
        const field = event.target.name;
        switch (field){
            case 'selectedDivisionValue': {
                if (this.isMount) {
                    this.setState({
                        billingDivisions: this.filterComboData(event.filter, this.state.billingDivisionsAll)
                    });
                }
            }
                break;
            case 'selectedRegionValue': {
                if (this.isMount) {
                    this.setState({
                        billingRegions: this.filterComboData(event.filter, this.state.billingRegionsAll)
                    });
                }
            }
                break;
            default:
                break;
        }
    };

    filterComboData(filter, allData){
        const data = allData.slice();
        return filterBy(data, filter);
    };

    handleOnChangeValue = event =>{
        const valueObj = event.target.value;
        const field = event.target.name;
        let prevValueDiv = this.props.location.division;
        let prevValueRegion = this.props.location.region;
        switch (field){
            case 'selectedDivisionValue': {
                if (this.isMount) {
                    this.setState({
                        [field]: valueObj,
                        isFormDirty: valueObj ? true : false,
                        QMSUploadsListByDivision: [],
                        docNamesByDivision: [],
                        docCodesByDivision: []
                    });

                    if(valueObj){
                        this.setState({
                            selectedDivision: valueObj
                        });
                    }

                    let selectedDivisions = this.state.selectedDivisionValue
                    if( selectedDivisions.length > valueObj.length){
                        let difference = selectedDivisions.filter(x => !valueObj.includes(x));
                        let removedIndex = selectedDivisions.indexOf(difference[0])
                        this.state.selectedDocCodePrefix = Object.values(this.state.selectedDocCodePrefix)
                        this.state.documentCodeSuffix = Object.values(this.state.documentCodeSuffix)
                        this.state.selectedDocCodePrefix.splice(removedIndex, 1)
                        this.state.documentCodeSuffix.splice(removedIndex, 1)
                        this.state.selectedDivisionValue.splice(removedIndex, 1)
                    }                        

                    if(this.props.location.action === 'edit'){
                        this.setState({
                            selectedDivisionValueEdit: valueObj,
                            isDivisionChanged: (valueObj && valueObj.id && prevValueDiv && prevValueDiv.id && (valueObj.id == prevValueDiv.id)) ? false : true
                        });
                    }
                }
                break;
            }
            case 'selectedRegionValue' : {
                if (this.isMount) {
                    this.setState({
                        [field]: valueObj,
                        isFormDirty: valueObj ? true : false
                    });
                }
                if(this.props.location.action === 'edit'){
                    this.setState({
                        RegionEdit: valueObj,
                        isRegionChanged: (valueObj && valueObj.id && prevValueRegion && prevValueRegion.id && (valueObj.id == prevValueRegion.id)) ? false : true
                    });
                }
                break;
            }
        }
    };

    handleChangeDocumentName = event => {
        const value = event.target.value;
        let prevValue = this.props.location.docName;

        this.setState({
            DocumentName: value,
            isFormDirty: value ? true : false,
            docNameErrorMessageVisible: false
        });

        if(this.props.location.action === 'edit'){
            this.setState({
                DocumentNameEdit: value,
                isDocNameChanged: (value == prevValue) ? false : true
            });
        }
    };

    handleChangeDocumentCode = event => {
        const value = event.target.value;
        const name = event.target.name;
        let prevValue = this.props.location.docCodePrefix;

        this.setState((prevState) => ({
            ...prevState,
            selectedDocCodePrefix: {
              ...prevState.selectedDocCodePrefix,
              [name]: value,
            },
            isFormDirty: value ? true : false,
            docCodeErrorMessageVisible: false
        }));

        if(this.props.location.action === 'edit'){
            this.setState({
                DocumentCodePrefixEdit: value,
                isDocCodePrefixChanged: (value == prevValue) ? false : true,
            });
        }
    };

    handleChangeDocumentCodeSuffix = event => {
        let value = event.target.value;
        const name = event.target.name;
        value = value.replace(/[^A-Za-z_-]/ig, '');
        let prevValue = this.props.location.docCodeSuffix;
        this.setState((prevState) => ({
            ...prevState,
            documentCodeSuffix: {
              ...prevState.documentCodeSuffix,
              [name]: value.toUpperCase(),
            },
            isFormDirty: value ? true : false,
            docCodeErrorMessageVisible: false
        }));

        if(this.props.location.action === 'edit'){
            this.setState((prevState) => ({
                ...prevState,
                documentCodeSuffix: {
                  ...prevState.documentCodeSuffix,
                  [name]: value.toUpperCase(),
                },
                isDocCodeSuffixChanged: (value == prevValue) ? false : true,

            }));
        }
    };

    handleChangeQMSUploadsComment = event => {
        const value = event.target.value;
        let prevValue = this.props.location.description;

        this.setState({
            QMSUploadsComment: value,
            isFormDirty: value ? true : false
        });

        if(this.props.location.action === 'edit'){
            this.setState({
                isCommentChanged: (value == prevValue) ? false : true
            });
        }
    };

    handleSubmit = async event => {
        event.preventDefault();
        await this.populateQMSUploadsList();

        if (!this.validation()){
            if(this.isMount){
                this.setState({
                    isErrorMessageVisible: true,
                    showSuccessImage: false,
                    showErrorImage: true,
                });
            }
            this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
            return;
        }
        else{
            if(this.isMount){
                this.setState({
                    isErrorMessageVisible: false
                });
            }

            let QMSUploadsListByDivision = [];
            let docNamesByDivision = [];
            let docCodesByDivision = [];
            let docCodeEntered = []
            let docNameEntered = this.state.DocumentName;
            let action = this.props.location.action;

            let i = 0;
            for(let divisionValue of this.state.selectedDivisionValue){

                if(this.state.selectedDocCodePrefix[i] == undefined || this.state.documentCodeSuffix[i] == undefined || this.state.documentCodeSuffix[i] == ''){
                    this.setState({
                        showSuccessImage: false,
                        showErrorImage: true,
                    })
                    this.toggleDialog('Document code cannot be empty or include numbers.', 'Error');
                    return
                }

                let code = this.state.selectedDocCodePrefix[i].Name + '_' + divisionValue.Code + '_' + this.state.documentCodeSuffix[i];
                for(let actCode of this.state.QMSUploadsList){
                    if(action != 'edit' && actCode.QMSUpload.map(v => v.DocCode).includes(code)){
                        this.setState({
                            showSuccessImage: false,
                            showErrorImage: true,
                        })
                        this.toggleDialog('Document code already exists', 'Error');
                        return
                    }
                    if(action != 'edit' && actCode.QMSUpload.map(v => v.DocName).includes(docNameEntered)){
                        this.setState({
                            showSuccessImage: false,
                            showErrorImage: true,
                        })
                        this.toggleDialog('Document name already exists', 'Error');
                        return
                    }
                }
                docCodeEntered.push(code)
                i++;
            }

            let QMSUploadsList = this.state.QMSUploadsList;

            let docNameEdit = this.state.DocumentNameEdit;
            /*let docCodeEdit = this.state.DocumentCodeEdit;*/
            let docCodeEdited = false;
            let documentCodePrefixEdit = this.state.selectedDocCodePrefix;
            let selectedDivisionValueEdit = this.state.selectedDivisionValue;
            let DocumentCodeSuffixEdit = this.state.documentCodeSuffix;
            let docCodeEdit = []
            
            i = 0;
            for(let divisionValue of selectedDivisionValueEdit){
                
                if(this.state.DocumentCodePrefixEdit[i] != null){
                    docCodeEdited = true;
                    documentCodePrefixEdit[i] = this.state.DocumentCodePrefixEdit[i];
                }
                else if(this.state.selectedDivisionValueEdit[i] != null ){
                    docCodeEdited = true;
                    divisionValue[i] = this.state.selectedDivisionValueEdit[i].Code;
                }
                else if(this.state.DocumentCodeSuffixEdit[i] != null){
                    docCodeEdited = true;
                    DocumentCodeSuffixEdit[i] = this.state.DocumentCodeSuffixEdit[i];
                }
                
                let code = documentCodePrefixEdit[i].Name + '_' + selectedDivisionValueEdit[i].Code + '_' + DocumentCodeSuffixEdit[i];
                docCodeEdit.push(code)

                i++;
            }
                
            for(let item of QMSUploadsList){
                for(let division of this.state.selectedDivisionValue){
                    if(action === 'edit'){
                        if(item.DivisionId === division.id){
                            QMSUploadsListByDivision.push(item);
                        }
                    }
                    else{
                        if(item.DivisionId === division.id){
                            QMSUploadsListByDivision.push(item);
                        }
                    }
                }
            }
            for(let item of QMSUploadsListByDivision){
                docNamesByDivision.push(item.DocName);
                docCodesByDivision.push(item.DocCode);
            }

            if(action === 'edit'){
                docNameEntered = docNameEdit;
                docCodeEntered = docCodeEdit;
            }
            
            const docNameStatuses = docNamesByDivision.map(item => {
                if(item.trim().toString().toLowerCase() == docNameEntered.trim().toString().toLowerCase()){
                    return ['exist'];
                }
                else{
                    return ['unique'];
                }
            })

            let uni = []
            let docCodeStatuses = ['unique'];
            if(action === 'edit') {
                if (docCodeEdited) {
                    docCodeStatuses =
                        docCodesByDivision.map(item => {
                            for(let docCode in docCodeEntered){
                                if (item.trim().toString().toLowerCase() == docCode.trim().toString().toLowerCase()) {
                                    uni.push('exist');
                                } else {
                                    uni.push('unique');
                                }
                            }
                        })
                }
            } else {
                docCodeStatuses =
                    docCodesByDivision.map(item => {
                        for(let docCode in docCodeEntered){
                            if (item.trim().toString().toLowerCase() == docCode.trim().toString().toLowerCase()) {
                                uni.push('exist');
                            } else {
                                uni.push('unique');
                            }
                        }
                    })
            }

            if(docNameStatuses.includes('exist')){
                this.state.isDocNameUnique = false;
            }
            if(docCodeStatuses.includes('exist')){
                this.state.isDocCodeUnique = false;
            }
            if(!(docNameStatuses.includes('exist'))){
                this.state.isDocNameUnique = true;
            }
            if(!(docCodeStatuses.includes('exist'))){
                this.state.isDocCodeUnique = true;
            }

            if(this.state.isDocNameUnique == false){
                this.state.docNameErrorMessageVisible = true;
            }
            if(this.state.isDocCodeUnique == false){
                this.state.docCodeErrorMessageVisible = true;
            }
            if(this.state.isDocNameUnique == true && this.state.isDocCodeUnique == true){
                this.saveQMSTemplate(event);
            }
        }
        this.populateDropDownData();
    };

    saveQMSTemplate = async event => {
        let documentName = this.state.DocumentName;
        
        let divisionId = [];
        let documentCode = []
        let docCodePrefix = []
        let documentCodeSuffix = []
        let i = 0;
        for(let divisionValue of this.state.selectedDivisionValue){
            let code = this.state.selectedDocCodePrefix[i].Name + '_' + divisionValue.Code + '_' + this.state.documentCodeSuffix[i];
            documentCode.push(code)
            divisionId.push(divisionValue.id)
            docCodePrefix.push(this.state.selectedDocCodePrefix[i].id)
            documentCodeSuffix.push(this.state.documentCodeSuffix[i])
            i++;
        }

        let description = this.state.QMSUploadsComment;
        let QMSTemplateId = this.state.selectedQMSTemplateFile.id;
        let userId = this.state.userId;
        let isActive = this.state.isActive;
        let action = this.props.location.action;
        let qmsUploadsId = this.state.qmsUploadsId;
        let regionId = this.state.selectedRegionValue ? this.state.selectedRegionValue.id : null;   

        var QMSUploadObj = {
            DivisionId: divisionId,
            DocName: documentName,
            DocCode: documentCode,
            ModifiedBy: userId,
            FileId: QMSTemplateId,
            Description: description,
            IsActive: isActive,
            qmsUploadsId: qmsUploadsId,
            UpdatedUser: window.LOGGED_USER.displayName,
            RegionId : regionId,
            DocCodePrefix: docCodePrefix,
            DocCodeSuffix: documentCodeSuffix,
            templateId: this.props.location.qmsTemplateId
        };

        if(action === 'edit'){
            let i = 0;
            for(let divisionValue of this.state.selectedDivisionValue){   
                if(typeof this.state.selectedDivisionValueInit[i] != "undefined"){
                    if(this.state.selectedDivisionValueInit[i].id !== divisionValue.id || this.state.DocumentCodeInit !== documentCode){
                        /*QMSUploadObj.QMSNumber = '0001';*/
                        QMSUploadObj.modifiedAt = moment(new Date());
                        //QMSUploadObj.id = .QMSUpload.map(v => v.id);
                        
                    }
                    if(this.state.DocumentNameInit !== documentName || this.state.QMSUploadsCommentInit !== description || this.state.selectedQMSTemplateFileInit.id !== QMSTemplateId){
                        QMSUploadObj.modifiedAt = moment(new Date());
                    }
                }
                i++;
            }
            if(this.state.isFileChanged || this.state.isDivisionChanged || this.state.isDocNameChanged || this.state.isDocCodePrefixChanged || this.state.isDocCodeSuffixChanged || this.state.isCommentChanged || this.state.isRegionChanged){
                await updateQMSUpload(QMSUploadObj).then(response => {
                    this.setState({
                        showSuccessImg: true,
                        docNameErrorMessageVisible: false,
                        docCodeErrorMessageVisible: false,
                        showSuccessImage: true,
                        showErrorImage: false,
                        saveSuccess: true
                    });
                    this.toggleDialog(`${this.state.selectedQMSTemplateFile.FileName} is successfully updated.`, 'Success');

                    this.setState({
                        selectedDivisionValue: [],
                        DocumentName: '',
                        QMSUploadsComment: '',
                        selectedQMSTemplateFile: [],
                        isFormDirty: false,
                        isFileChanged: false,
                        isDivisionChanged: false,
                        isDocNameChanged: false,
                        isDocCodePrefixChanged: false,
                        isDocCodeSuffixChanged: false,
                        isCommentChanged: false,
                        selectedRegionValue: null,
                        selectedDocCodePrefix: [],
                        documentCodeSuffix: '',
                        isRegionChanged: false
                    });
                    this.populateQMSUploadsList();
                    
                }).catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                    this.setState({
                        showSuccessImage: false,
                        showErrorImage: true,
                    })
                    this.toggleDialog(error.response.data, 'Error');
                });

            }
        }
        else{
            QMSUploadObj.QMSNumber = '0001';
            await saveQMSUpload(QMSUploadObj).then(response => {
                this.setState({
                    showSuccessImg: true,
                    docNameErrorMessageVisible: false,
                    docCodeErrorMessageVisible: false,
                    showSuccessImage: true,
                    showErrorImage: false,
                    saveSuccess: true
                });
                this.toggleDialog(`${this.state.selectedQMSTemplateFile.FileName} is successfully added.`, 'Success');

                this.setState({
                    selectedDivisionValue: [],
                    DocumentName: '',
                    QMSUploadsComment: '',
                    selectedQMSTemplateFile: [],
                    isFormDirty: false,
                    isFileChanged: false,
                    isDivisionChanged: false,
                    isDocNameChanged: false,
                    isDocCodePrefixChanged: false,
                    isDocCodeSuffixChanged: false,
                    isCommentChanged: false,
                    selectedRegionValue : null,
                    selectedDocCodePrefix: [],
                    documentCodeSuffix: '',
                    isRegionChanged: false
                });
                this.populateQMSUploadsList();

            }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                this.setState({
                    showSuccessImage: false,
                    showErrorImage: true,
                })
                this.toggleDialog(error.response.data, 'Error');
            });
        }
    };

    onClickCancel = () => {
       if(this.state.isFormDirty === true){
        this.toggleConfirmationDialog('Values are not saved! Are you sure you want to cancel?', 'Confirm');
       }
       else{
        this.setState({
            redirectToSearchQMS: true
        });
       }
    };

    onCancelOk = () => {
        this.setState({
            selectedDivisionValue: [],
            DocumentName: '',
            QMSUploadsComment: '',
            selectedQMSTemplateFile: [],
            showSuccessImage: false,
            isFormDirty: false,
            isFileChanged: false,
            isDivisionChanged: false,
            isDocNameChanged: false,
            isDocCodeSuffixChanged: false,
            isDocCodePrefixChanged: false,
            isCommentChanged: false,
            redirectToSearchQMS: true,
            selectedRegionValue : null,
            selectedDocCodePrefix: [],
            documentCodeSuffix: [],
            isRegionChanged: false
        });
        this.toggleConfirmationDialog('', null);
    };

    validation = () => {
        if (this.state.selectedDivisionValue && this.state.selectedDivisionValue.length <= 0) {
            return false;
        }
        else if (
            this.state.selectedDivisionValue.filter(v => v.id === 40).length > 0 &&
            this.validateProperty(this.state.selectedRegionValue)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.DocumentName)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.selectedDocCodePrefix)
                .toString()
                .includes('error')
            ) {
                return false;
        }
        else if (
            this.validateProperty(this.state.documentCodeSuffix)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.selectedQMSTemplateFile.FileName)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else if (
            this.validateProperty(this.state.QMSUploadsComment)
                .toString()
                .includes('error')
        ) {
            return false;
        }
        else {
            return true;
        }
    };

    handleDeleteQmsTemplate = () => {
        const qmsTemplate = this.state.selectedQMSTemplateFile
        if(this.isMount){
            this.setState({
                documentIdToDelete: qmsTemplate.id
            }, () => {
                this.toggleDeleteConfirmDialog('Are you sure you want to delete the uploaded template?', this.onDeleteOk);
            });
        }
    };

    onDeleteOk = () => {
        this.setState({
            loading: true,
        });
        deleteFileById(this.state.documentIdToDelete).then(response => {
            if(this.isMount){
                this.setState({
                    documentIdToDelete: null,
                    selectedQMSTemplateFile: [],
                    isFormDirty: false,
                    showSuccessImage: true,
                    showErrorImage: false,
                    loading: false
                });
            }
            this.toggleDeleteConfirmDialog('', null);
            this.toggleDialog('Successfully deleted', 'Success');
        }).catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            this.setState({
                showSuccessImage: false,
                showErrorImage: true,
                loading: false
            })
            this.toggleDialog(error.response.data, 'Error');
        });
    };

    redirectToUserSearchQMS = () => {
        this.setState({
            redirectToSearchQMS : true
        })
    }

    render() {
        var QMSTemplateStyle = {
            display: (this.state.selectedQMSTemplateFile.length === 0) ? 'none' : 'block'
        };
        
        if (this.state.redirectToSearchQMS === true) {
            return <Redirect to="/qms/search/search" />;
        }
        
        return (
            <div className="main-card">
                <div className="row">
                    <div className="col-md-9">
                        {this.props.location.title ? (
                            <div className="main-heading">{this.props.location.title}</div>                       
                            ) :  <div className="main-heading">Add Template</div>}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="d-block">
                            <label className="mandatory">Department:</label>
                        </div>
                        <div className="d-block">
                            <div>
                                <MultiSelect
                                    data={this.state.billingDivisions}
                                    textField="Name"
                                    dataItemKey="id"
                                    placeholder="Please Select"
                                    value={this.state.selectedDivisionValue}
                                    onChange={this.handleOnChangeValue}
                                    name="selectedDivisionValue"
                                    filterable={true}
                                    onFilterChange={this.filterChangeCombo}
                                    required={true}
                                />
                                {this.state.isErrorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.state.selectedDivisionValue.length > 0)}>
                                      Please select a Department
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    {this.state.selectedDivisionValue.length > 0 && this.state.selectedDivisionValue.filter(v => v.id === 40).length > 0 && (
                    <div className="col-md-4">
                        <div className="d-block">
                            <label className="mandatory">Region:</label>
                        </div>
                        <div className="d-block">
                            <div>
                                <ComboBox
                                  data={this.state.billingRegions}
                                  textField="Name"
                                  dataItemKey="id"
                                  placeholder="Please Select"
                                  value={this.state.selectedRegionValue}
                                  onChange={this.handleOnChangeValue}
                                  name="selectedRegionValue"
                                  filterable={true}
                                  onFilterChange={this.filterChangeCombo}
                                  required={true}
                                />

                                {this.state.isErrorMessageVisible === true ? (
                                  <span className={this.validateProperty(this.state.selectedRegionValue)}>
                                      Please select a Region
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    )}
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className="d-block">
                            <label className="mandatory">Document Name:</label>
                        </div>
                        <div className="d-block">
                            <Input
                                name="DocumentName"
                                autoComplete="off"
                                type="text"
                                maxLength="100"
                                value={this.state.DocumentName}
                                onChange={this.handleChangeDocumentName}
                                required={true}
                            />

                            {this.state.isErrorMessageVisible === true ? (
                                <span className={this.validateProperty(this.state.DocumentName)} >
                                  Please enter a document name
                                </span>
                            ) : null}

                            {this.state.docNameErrorMessageVisible == true ? (
                                <span className="inline-error" >
                                  Please enter a unique document name
                                </span>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="d-block">
                            <label className="mandatory">Document Code:</label>
                        </div>

                        {this.state.selectedDivisionValue.map((division, index) => 
                        
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="d-block">
                                        <ComboBox
                                            dataItemKey="id"
                                            key={index}
                                            placeholder="Please Select"
                                            data={this.state.documentCodePrefixes}
                                            value={this.state.selectedDocCodePrefix[index] || null}
                                            onChange={this.handleChangeDocumentCode}
                                            name={index}
                                            filterable={true}
                                            onFilterChange={this.filterChangeCombo}
                                            textField="Name"
                                            required={true}
                                        />
                                    </div>
                                    {this.state.isErrorMessageVisible === true ? (
                                        <span className={this.validateProperty(this.state.selectedDocCodePrefix[index])} >
                                    Please enter a document code
                                    </span>
                                    ) : null}
                                </div>
                                <div className="col-md-3">
                                    <div className="d-block">
                                        <Input
                                        name="DocumentCodeDivision"
                                        autoComplete="off"
                                        type="text"
                                        disabled={true}
                                        value={division ? division.Code : []}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="d-block">
                                        <Input
                                        name={index}
                                        autoComplete="off"
                                        type="text"
                                        maxLength="15"
                                        value={this.state.documentCodeSuffix[index] || null}
                                        onChange={this.handleChangeDocumentCodeSuffix}
                                        required={true}
                                        />
                                    </div>
                                    {this.state.isErrorMessageVisible === true ? (
                                        <span className={this.validateProperty(this.state.documentCodeSuffix[index])} >
                                            Please enter a document code
                                        </span>
                                    ) : null}
                                </div>

                                {this.state.docCodeErrorMessageVisible == true ? (
                                    <span className="inline-error" >
                                        Please enter unique document code
                                    </span>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 overflow-x-hidden">
                        <div className="row">
                            <div className="col-md-12">
                                    <div className="d-block">
                                    <label className="mandatory">Upload Template:</label>
                                </div>
                                <input
                                    type="file"
                                    name="file"
                                    onChange={this.onChangeHandlerTemplate}
                                    value={this.state.qmsTemplateId}
                                    className="custom-file-input qms-template"
                                    multiple={false}
                                    disabled={this.state.selectedQMSTemplateFile.length === 0 ? false : true}
                                    required={true}

                                />

                                {this.state.selectedQMSTemplateFile.length === 0 ? (
                                    <span className='inline-error'>Maximum file size 30MB</span>
                                ) : null}
                                <br></br>
                                {this.state.isErrorMessageVisible === true ? (
                                    <span className={this.validateProperty(this.state.selectedQMSTemplateFile.FileName)} >
                                    Please upload a template
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                                <div className="col-md-12 d-flex align-items-center justify-content-between">
                                            <div className="input-qms-template" style={QMSTemplateStyle}>
                                                <div className="">
                                                    {this.state.selectedQMSTemplateFile && (
                                                        <div className="">
                                                            <div className="doc-list" >
                                                                <div className="row  mx-auto my-2">
                                                                    <span className="col-md-10 docu-upload">
                                                                        {this.state.selectedQMSTemplateFile.FileName}
                                                                    </span>
                                                                    <span className="col-md-2 docu-icon text-center">
                                                                        <a href={`${apiUrl}/integration/fileUpload/downloadqms?path=QMSTemplate_${encodeURIComponent(this.state.selectedQMSTemplateFile.FileName)}&&qmsNum=${encodeURIComponent(this.state.selectedQMSTemplateFile.FileName)}`} target="_blank">
                                                                            <span className="k-icon k-i-download ml-2"></span>
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="input-qms-template" style={QMSTemplateStyle}>
                                            {this.state.selectedQMSTemplateFile && (
                                                <a onClick={this.handleDeleteQmsTemplate}>
                                                    <div className="rounded-link d-flex align-items-center cursor-pointer">
                                                        <span className="k-icon k-i-delete" title="Delete" />
                                                    </div>
                                                </a>
                                                )}
                                            </div>
                                </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="d-block">
                            <label className="mandatory">Description:</label>
                        </div>
                        <textarea
                            className="k-textarea"
                            maxLength="100"
                            rows="3"
                            name="QMSUploadsComment"
                            value={this.state.QMSUploadsComment}
                            onChange={this.handleChangeQMSUploadsComment}
                            required={true}
                        />
                        {this.state.isErrorMessageVisible === true ? (
                            <span className={this.validateProperty(this.state.QMSUploadsComment)} >
                            Please enter a description
                            </span>
                        ) : null}
                    </div>
                </div>

              
                <div className="row mt-3">
                    <div className="col-md-12 btn-align-right">
                        <Button primary={true} type="submit" onClick={this.handleSubmit}>
                            Save
                        </Button>
                        <Button type="button" onClick={this.onClickCancel}>
                            Cancel
                        </Button>
                    </div>
                </div>

                <div>
                    {this.state.visible === true && (
                        <Dialog
                            title={this.state.dialogTitle}
                            onClose={this.toggleDialog}
                            width="400px">
                            
                            {this.state.showSuccessImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={SuccessImg} alt="Success"/>
                                </div>
                            )}

                            {this.state.showErrorImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={ErrorImg} alt="Error Image"/>
                                </div>
                            )}
                            <p style={{ margin: "25px", textAlign: "center" }}>
                                {this.state.dialogMessage}
                            </p>
                            <DialogActionsBar>
                                <button
                                    className="k-button modal-primary"
                                    onClick={
                                        (this.state.dialogTitle === "Error" || this.state.dialogTitle === "Upload Status" ||  this.state.dialogTitle === "Success") && !this.state.saveSuccess
                                          ? this.toggleDialog
                                            : this.redirectToUserSearchQMS
                                    }>
                                    OK
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

                <div>
                    {this.state.visibleConfirmationDialog === true && (
                        <Dialog 
                            title={this.state.dialogTitle} 
                            onClose={this.toggleConfirmationDialog} 
                            width="400px">
                            
                            {this.state.showSuccessImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={SuccessImg} alt="Success"/>
                                </div>
                            )}

                            {this.state.showErrorImage === true && (
                                <div className="text-center">
                                <img className="successImg" src={ErrorImg} alt="Error Image"/>
                                </div>
                            )}
                            <p style={{ margin: "25px", textAlign: "center" }}>
                                {this.state.dialogMessage}
                            </p>
                            <DialogActionsBar>                               
                                <button 
                                    className="k-button" 
                                    onClick={this.toggleConfirmationDialog}>
                                    No
                                </button>
                                <button 
                                    className="k-button modal-primary"
                                    onClick={this.onCancelOk}>
                                    Yes
                                </button>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>

                <div>
                    {this.state.visibleDeleteConfirmDialog === true && (
                        <Dialog 
                            title="Confirm" 
                            onClose={this.toggleDeleteConfirmDialog} 
                            width="400px">
                            
                            <p style={{ margin: "25px", textAlign: "center" }}>
                                {this.state.confirmDialogMessage}
                            </p>
                            <DialogActionsBar>
                                <button 
                                    className="k-button" 
                                    onClick={this.toggleDeleteConfirmDialog}>
                                    No
                                </button>
                                <button 
                                    className="k-button modal-primary"
                                    onClick={this.state.confirmDialogAction}>
                                    Yes
                                </button>                             
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
                <Loader loading={this.state.loading} />
            </div>
        );
    }
}

export default AddTemplate;
