/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Input, Checkbox, TextArea} from '@progress/kendo-react-inputs';
import { DebounceInput } from 'react-debounce-input';
import { Tooltip } from "reactstrap";
import { roundNumber } from './../../../integration/CommonUtils';

class RowCellNumericTextBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false,
            decimalPlaces: 0
        };
    }

    handleInput = event => {
        this.props.onChange({
            dataItem: this.props.dataItem,
            field: this.props.field,
            syntheticEvent: event.syntheticEvent,
            value: event.target.value
        });
    };

    render() {
        if (this.props.field == 'ApprovedDurationValue') {
            return (
                <td>
                    <div>
                        <Input
                          name="Duration"
                          value={this.props.dataItem[this.props.field]}
                          onChange={this.handleInput}
                          disabled={this.props.disabled}
                          required={true}
                          style={this.props.dataItem["IsNotValidDuration"]=== true ? { 'border-color': 'rgba(243,23,0,0.5)' } : {}}
                        />
                    </div>
                </td >
            );
        } else if (this.props.field == 'ApprovedTicketId') {
            return (
                <td>
                    <div>
                        <Input
                          name="ApprovedTicketId"
                          value={this.props.dataItem[this.props.field]}
                          onChange={this.handleInput}
                          disabled={this.props.disabled}
                          maxLength={20}
                        />
                    </div>
                </td >
            );
        } else if (this.props.field == 'ApprovedComment') {
            return (
                <td>
                    <div>
                        <textarea
                            className="k-textarea"
                            name="Comment"
                            id="Comment"
                            value={this.props.dataItem[this.props.field]}
                            onChange={this.handleInput}
                            maxLength={250}
                            disabled={this.props.disabled}
                            required={true}
                            style={this.props.dataItem["IsNotValidComment"]=== true ? { 'border-color': 'rgba(243,23,0,0.5)' } : {}}
                        />
                    </div>
                </td >
            );
        }   
    }
}

export default RowCellNumericTextBox;
