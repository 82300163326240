import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;
const timeSheetApproverRouter = apiUrl + '/timeSheetManagement/timeSheetApproval/'


/**
 * getResourceList - retirive task list
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
export  function getResourceList(parms){
    const endPoint = timeSheetApproverRouter + 'getResourceList';
    return  http.post(endPoint,parms);
}

/**
 * getCalculatedTotals - retirive calculations
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
export  function getCalculatedTotals(parms){
    const endPoint = timeSheetApproverRouter + 'getCalculatedTotals';
    return  http.post(endPoint,parms);
}

/**
 * getCalculatedTotalsDaily - retirive calculations daily
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
export  function getCalculatedTotalsDaily(parms){
    const endPoint = timeSheetApproverRouter + 'getCalculatedTotalsDaily';
    return  http.post(endPoint,parms);
}

/**
 * getCalculatedTotals - retirive calculations
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
export  function getBilableCalculatedTotals(parms){
    const endPoint = timeSheetApproverRouter + 'getBilableCalculatedTotals';
    return  http.post(endPoint,parms);
}

/**
 * getBilableCalculatedTotalsDaily - retirive calculations
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
export  function getBilableCalculatedTotalsDaily(parms){
    const endPoint = timeSheetApproverRouter + 'getBilableCalculatedTotalsDaily';
    return  http.post(endPoint,parms);
}

/**
 * getEnterdTotalHours - retirive calculations
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
export  function getEnterdTotalHours(parms){
    const endPoint = timeSheetApproverRouter + 'getEnterdTotalHours';
    return  http.post(endPoint,parms);
}
/**
 * getTaskList - retirive task list
 * @returns {Object}  Object of saved details of  task
 * @param parms
 */
export  function getTaskListAPI(parms){
    const endPoint = timeSheetApproverRouter + 'getTaskList';
    return  http.post(endPoint,parms);
}


/**
 * getTimeCards - retirive time entries
 * @returns {Object}  Object of saved details of  time entry
 * @param parms
 */
 export  function getTimeCards(parms){
    const endPoint = timeSheetApproverRouter + 'getTimeCards';
    return  http.post(endPoint,parms);
}


/**
 * checkFutureCardsExist - check is exist future time cards
 * @returns {Object}  Object of saved details of  time entry
 * @param parms
 */
 export  function checkFutureCardsExist(parms){
    const endPoint = timeSheetApproverRouter + 'checkFutureCardsExist';
    return  http.post(endPoint,parms);
}

/**
 * getTimeEntries - retirive time entries
 * @returns {Object}  Object of saved details of  time entry
 * @param parms
 */
 export  function getTimeEntries(parms){
    const endPoint = timeSheetApproverRouter + 'getTimeEntries';
    return  http.post(endPoint,parms);
}

/**
 * getDateForProjectFilter - retirive time entries
 * @returns {Object}  Object of saved details of  time entry
 *  */
 export  function getDateForProjectFilter(parms){
    const endPoint = timeSheetApproverRouter + 'getDateForProjectFilter';
    return  http.post(endPoint,parms);
}

/**
 * getDataForTaskFilter - retirive time entries
 * @returns {Object}  Object of saved details of  time entry
 *  */
 export  function getDataForTaskFilter(params){
    const endPoint = timeSheetApproverRouter + 'getDataForTaskFilter';
    return  http.post(endPoint,params);
}

/**
 * moveTimeEntry - move time entries
 * @returns {Object}  Object of saved details of  time entry
 *  */
 export  function moveTimeEntry(params){
    const endPoint = timeSheetApproverRouter + 'moveTimeEntry';
    return  http.post(endPoint,params);
}

/**
 * getAllResourceListForFilter - retirive resouces
 * @returns {Object}  Object of saved details of  time entry
 *  */
 export  function getAllResourceListForFilter(params){
    const endPoint = timeSheetApproverRouter + 'getAllResourceListForFilter';
    return  http.post(endPoint,params);
}

/**
 * saveSearchCriteria - save Search Criteria
 * @returns {Object}  Object of saved of Search Criteria
 * @param Search
 */
 export function saveSearchCriteria(Search) {
    const endPoint = timeSheetApproverRouter + 'saveSearchCriteria'
    return http.post(endPoint, Search);
  }

  /**
 * getSearchCriteria - get saved Search Criteria
 * @returns {Object}  Object of saved of Search Criteria
 */
export function getSearchCriteria() {
    const endPoint = timeSheetApproverRouter + 'getSearchCriteria'
    return http.get(endPoint);
  }

  /**
 * approveBillable - approve pending time entries
 * @returns {Object}  Object of saved of Search Criteria
 */
export function approveBillable(parms) {
    const endPoint = timeSheetApproverRouter + 'approveBillable'
    return http.post(endPoint,parms);
  }

  /**
 * approveBillable - approve pending time entries
 * @returns {Object}  Object of saved of Search Criteria
 */
   export function approveBillableAllPending(parms) {
    const endPoint = timeSheetApproverRouter + 'approveBillableAllPending'
    return http.post(endPoint,parms);
  }

/**
 * approveNonBillableAllPending - approve pending time entries
 * @returns {Object}  Object of saved of Search Criteria
 */
export function approveNonBillableAllPending(parms) {
    const endPoint = timeSheetApproverRouter + 'approveNonBillableAllPending'
    return http.post(endPoint,parms);
}

  /**
 * approveNonBillable - approve  time entries
 * @returns {Object}  Object of saved of Search Criteria
 */
export function approveNonBillable(parms) {
    const endPoint = timeSheetApproverRouter + 'approveNonBillable'
    return http.post(endPoint,parms);
  }

/**
* unApprove - unapprove  time entries
* @returns {Object}  Object of saved of Search Criteria
*/
export function unApprove(parms) {
    const endPoint = timeSheetApproverRouter + 'unApprove'
    return http.post(endPoint, parms);
}

  /**
 * rejectTimeEntry - reject  time entries
 * @returns {Object}  Object of saved of Search Criteria
 */
export function rejectTimeEntry(parms) {
    const endPoint = timeSheetApproverRouter + 'rejectTimeEntry'
    return http.post(endPoint,parms);
  }


  
  /**
 * getTimeApprovalFreezeDateFromTimesheetFreezeDate - get Time approval Freeze Date
 * @returns {Object}  Object of saved of Search Criteria
 */
export function getTimeApprovalFreezeDateFromTimesheetFreezeDate() {
    const endPoint = timeSheetApproverRouter + 'getTimeApprovalFreezeDateFromTimesheetFreezeDate'
    return http.get(endPoint);
  }
  
/**
 * getUserUnFreezeDays - get Time approval un Freeze Date
 * @returns {Object}  Object of saved of Search Criteria
 */
export function getUserUnFreezeDays(weekData) {
    const endPoint = timeSheetApproverRouter + 'getUserUnFreezeDays'
    return http.post(endPoint,weekData);
  }

  /**
 * getUserUnFreezeDaysWithUserId - get Time approval un Freeze Date
 * @returns {Object}  Object of saved of Search Criteria
 */
export function getUserUnFreezeDaysWithUserId(weekData) {
    const endPoint = timeSheetApproverRouter + 'getUserUnFreezeDaysWithUserId'
    return http.post(endPoint,weekData);
  }
  
  
  

  /**
 * setWorkflow - set workflow
 * @returns {Object}  Object of set workflow
 * @param UnfreezeRequestData
 */
 export function setWorkflow(UnfreezeRequestData) {
    const endPoint = timeSheetApproverRouter + 'setWorkflow'
    return http.post(endPoint, UnfreezeRequestData);
  }

  export function getAllPendingApprovalTimes(data) {
    const endPoint = timeSheetApproverRouter + 'getpendingtimeapprovals'
    return http.post(endPoint, data);
}
  