import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

class UpdatedDateCell extends Component {
  render() {
    const value = this.props.dataItem.updatedAt;
    return <td><Moment format="MM-DD-YYYY">
      {value}
    </Moment></td>;
  }
}

export default UpdatedDateCell;
