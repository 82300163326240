import React, { Component } from 'react';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import Timeline from './Timeline/Timeline';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { filterBy } from '@progress/kendo-data-query';
import moment from 'moment';

import SuccessImg from '../../../../ui/static/images/checked.svg';

import HeaderCellAddButton from '../common/HeaderCellAddButton';
import RowCellDeleteButton from '../common/RowCellDeleteButton';
import RowCellComboBox from '../common/RowCellComboBox';
import RowCellDropDown from '../common/RowCellDropDown';
import RowCellDatePicker from '../common/RowCellDatePicker';
import RowCellNumericTextBox from '../common/RowCellNumericTextBox';
import RowDetails from './RowDetails';
import cloneDeep from 'lodash/cloneDeep';

import { getRateTypes } from '../../../../masterData/rateCard/RateCardService';
import {
    getDeployableStatus,
    getRatesForAllocation,
    getResourceGroup
} from '../../../../resourceManagement/ResourceService';
import { getCost, getMultiYearCost } from '../../../../masterData/costCard/CostCardService';
import {
    getMultiYearInfoByYearandCostCardId
} from '../../../../masterData/multiyearIncrement/MultiyearIncrementService';

import {
    getActiveResourceRegionsIntegrated,
    getHolidaysIntegrated,
    getResourceRegionsIntegrated
} from '../../../../integration/MasterDataService';
import { getAllDesignations } from '../../../../masterData/designation/DesignationService';

import {
    retrieveConversionRate,
    calculateProjectEndDate,
    getStartDateByPriority,
    calculateCostDate, calculateDuration
} from '../../../cmSheet/CMSheetService';

import Loader from '../../../../integration/components/Loader';
import { getFormattedDate, formatNumber, isWeekend } from '../../../../integration/CommonUtils';
import { Tooltip } from 'reactstrap';
import ErrorImg from '../../../../ui/static/images/cancel.svg';
import { LOG_TYPES } from '../../../../integration/IntegrationEnums';
import * as loggerService from '../../../../integration/LoggerService';
import { View } from '@progress/kendo-react-dateinputs/dist/es/calendar/components/View';
import FactoryRowDetails from "../../../poolProject/FactoryRowDetails";
import {TabStripTab} from "@progress/kendo-react-layout";
import {getDesignationGroups} from "../../../../masterData/designationGroup/DesignationGroupService";

const CUSTOMER_RATE = 'CUSTOMER_RATE';
const REGION_RATE = 'REGION_RATE';
const LAST_CUS_RATE = 'LAST_CUS_RATE';
const CUSTOM_RATE = 'CUSTOM_RATE';
const ADMIN_RATE = 'ADMIN_RATE';
const APPROVED_PROJ_RATE = 'APPROVED_PROJ_RATE';
const PROJECT_APPROVED_RATE = 'PROJECT_APPROVED_RATE';
const PROJECT_APPROVED_CR_RATE = 'PROJECT_APPROVED_CR_RATE';


class ResourceAllocPool extends Component {
    OnsiteStatusValues = [{ id: 1, Name: 'Onsite' }, { id: 2, Name: 'Offshore' }];
    billableStatusValues = [
        { id: 1, Text: 'Billable', value: true },
        { id: 2, Text: 'Non-Billable', value: false }
    ];
    PaymentMethodValues = [{ id: 1, Text: 'Fixed' }, { id: 2, Text: 'T&M' }];
    comboBoxPlaceHolder = 'Please Select';

    constructor(props) {
        super(props);

        this.state = {
            startDateDisplay: '',
            endDateDisplay: '',

            timelineVisible: false,

            resourceAllocations: [],

            PhasesAll: [],
            DesignationsAll: [],
            RegionsAll: [],
            ResourceGrpAll:[],
            RateTypes: [],

            TotalFTEResourceAllocation: 0,
            TotalRevenueResourceAllocation: 0,
            TotalEffortDaysResourceAllocation: 0,
            TotalEffortDaysResourceAllocationNonBill: 0,
            TotalEffortDaysResourceAllocationFixed: 0,
            TotalEffortDaysResourceAllocationTnM: 0,
            TotalCostToRevenueRatioResourceAllocation: 0,

            costDate: new Date(),

            outsourcedCost: 0,
            skip: 0,
            take: 20,

            showMessageDialog: false,
            dialogMessage: '',
            dialogTitle: '',

            showConfirmDialog: false,
            confirmDialogMessage: '',
            confirmDialogAction: null,

            showOutsourcedCostDialog: false,
            OutsourcedCostDataItemIndex: -1,

            rowItemToDelete: [],
            isLoading: true,
            notValidEndDate: false,
            endDateToolTipOpen: false,
            isErrorAllocationPercentage: false,
            isRedBadge: false,
            isErrorRateValue: false,
            isErrorNoOfResources: false,
            isErrorEffortDays: false,
            isErrorEffortHours: false,
            StartDateIsAPastDate: false,
            duplicateResourceChecked: false,
            deleteResourceChecked: false,
            resourcesToDuplicate: [],

            showSuccessImage: false,
            showErrorImage: false,

            infoTabStartDateChanged: false,
            infoTabEndDateChanged: false,
            isStartDateChanged:false,
            isEndDateChanged:false,
            ResourceGrp: [],
            BillableStatuses: this.billableStatusValues,
        };
        this.toggleTimeline = this.toggleTimeline.bind(this);
    }

    componentDidMount() {
        try {
            this.populateData();
        } catch (e) {
            loggerService.writeLog('Error loading data for resource allocation.', LOG_TYPES.ERROR);
        }
        this.setStartDateAndEndDate();
        this.setAllocationPercentageBasedOnType();

    }

    componentDidUpdate(prevProps, prevState, ss) {
        if (this.props.project !== prevProps.project) {
            this.setStartDateAndEndDate();
        }

        if (this.props.changeRequest !== prevProps.changeRequest) {
            this.setStartDateAndEndDate();
        }

        if (this.props.cmSheet !== prevProps.cmSheet) {
            this.populateDataOnUpdate();
            this.setStartDateAndEndDate();
        }
    }

    setAllocationPercentageBasedOnType = async () => {
        if (this.props.apsTypeHasChanged && this.props.apsTypeHasChanged === true) {
            const resources = this.props.cmSheet.ResourceAllocations;

            let resourcesArray = [];
            if (resources && resources.length > 0 ) {
                for (const resource of resources) {
                    const resourceObj = JSON.parse(JSON.stringify(resource));
                    resourceObj.StartDate = resourceObj.StartDate? new Date(resourceObj.StartDate) : null;
                    resourceObj.EndDate = resourceObj.EndDate ? new Date(resourceObj.EndDate) :  null;
                    resourceObj.StartDateMin = resourceObj.StartDateMin ? new Date(resourceObj.StartDateMin) : null;
                    resourceObj.StartDateMax = resourceObj.StartDateMax ? new Date(resourceObj.StartDateMax) : null;
                    resourceObj.DateStartDate = resourceObj.DateStartDate ? new Date(resourceObj.DateStartDate) : null;

                    resourcesArray.push(resourceObj);
                }
            }

            setTimeout(() => {
                this.setState({ resourceAllocations: resourcesArray }, ()=>{
                    this.updateCMSheet();
                });
            }, 2000);

        }
    }

    pageChange = event => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take
        });
    };

    setStartDateAndEndDate = async () => {

        let startDate = new Date();
        let endDate = new Date();

        if (this.props.isAPS || this.props.isResourceAug || this.props.isBlanketWorkOrder) {
            if (this.props.cmSheetReference === 'crCMS') {
                startDate = new Date(this.props.changeRequest.StartDate);
                endDate = new Date(this.props.endDate);
            }
            else {
                startDate = new Date(this.props.project.StartDate);
                endDate = new Date(this.props.endDate);
            }
        }
        else {
            if (this.props.cmSheetReference === 'crCMS') {
                if (this.props.changeRequest && this.props.changeRequest.EstimatedStartDate && this.props.changeRequest.EstimatedEndDate) {
                    startDate = new Date(this.props.changeRequest.EstimatedStartDate);
                    endDate = new Date(this.props.changeRequest.EstimatedEndDate);

                    if (startDate > endDate) {
                        this.setState({
                            notValidEndDate: true
                        });
                    } else {
                        this.setState({
                            notValidEndDate: false
                        });
                    }
                }
            }
            else {
                if (this.props.project && this.props.project.EstimatedStartDate && this.props.project.EstimatedEndDate) {
                    startDate = new Date(this.props.project.EstimatedStartDate);
                    endDate = new Date(this.props.project.EstimatedEndDate);

                    if (startDate > endDate) {
                        this.setState({
                            notValidEndDate: true
                        });
                    } else {
                        this.setState({
                            notValidEndDate: false
                        });
                    }
                }
            }

        }

        if (startDate && endDate) {
            this.setState({
                startDateDisplay:
                    startDate.getMonth() + 1 + '/' + startDate.getDate() + '/' + startDate.getFullYear(),
                endDateDisplay:
                    endDate.getMonth() + 1 + '/' + endDate.getDate() + '/' + endDate.getFullYear()
            });
        } else {
            this.setState({
                startDateDisplay: '',
                endDateDisplay: ''
            });
        }

    };

    populateData = async () => {
        const resDesg = await getAllDesignations();
        const resReg = await getActiveResourceRegionsIntegrated();
        const resRT = await getRateTypes();
        const ResourceGrp = await getResourceGroup();
        //await this.populateResourceGroup();
        
        let rateTypes = [];
        if (resRT.data && resRT.data.length > 0) {
            for (const rateType of resRT.data) {
                if (rateType.Code !== CUSTOM_RATE) {
                    rateType.Rate = 0;
                    rateType.Text = rateType.Name + ' - 0.00';
                } else if (rateType.Code == PROJECT_APPROVED_RATE) {
                    rateType.Rate = 0;
                    rateType.Text = rateType.Name + ' - 0.00';
                } else if (rateType.Code == PROJECT_APPROVED_CR_RATE) {
                    rateType.Rate = 0;
                    rateType.Text = rateType.Name + ' - 0.00';
                }
                else {
                    rateType.Text = rateType.Name;
                }
                rateTypes.push(rateType);
            }

            if(this.props.cmSheetReference === 'crCMS'){

                const tempRateTypes = [];
                // Array picked using DB sequence (RateTypes)
                const arrayPickingOrderLst = [1, 2, 7, 8, 3, 4, 5, 6];
                for(var i of arrayPickingOrderLst){
                    tempRateTypes.push(rateTypes[i - 1]);
                }
                rateTypes = tempRateTypes;

            } else {
                const tempRateTypes = [];
                // Array picked using DB sequence (RateTypes)
                const arrayPickingOrderLst = [1, 2, 7, 3, 4, 5, 6];
                for(var i of arrayPickingOrderLst){
                    tempRateTypes.push(rateTypes[i - 1]);
                }
                rateTypes = tempRateTypes;
            }

        }

        await this.setState(
            {
                DesignationsAll: resDesg.data,
                RegionsAll: resReg.data,
                ResourceGrpAll:ResourceGrp.data,
            },
            () => {
                this.populateAllocations();
            }

        );
       
        if (this.props.cmSheet && this.props.cmSheet.ResourceAllocations && this.props.cmSheet.ResourceAllocations.length>0) {
            this.setState({
                infoTabStartDateChanged: this.props.infoTabStartDateChanged
            })
        };
    };

    populateDataOnUpdate = async () => {
        await this.populateAllocations();
    };

    setMinMaxDatesBasedOnProjectDates = dataItem => {
        let startDate = null;
        let endDate = null;
        if (this.props.project && this.props.project.StartDate) {
            startDate = new Date(this.props.cmSheetReference === 'crCMS' ? this.props.changeRequest.StartDate : this.props.project.StartDate);
        } else {
            startDate = new Date();
        }

        dataItem.StartDateMin = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
        );
        dataItem.StartDateMin.setHours(0, 0, 0, 0);

            dataItem.StartDateMax = new Date((this.props.cmSheetReference === 'crCMS' ? this.props.changeRequest.FinancialYear : this.props.project.FinancialYear) + 1, 2, 31);
            dataItem.StartDateMax.setHours(0, 0, 0, 0);

    };

    populateAllocations = async () => {

        this.setState({
            isLoading: true
        });
        const allocations = this.props.cmSheet.ResourceAllocations.slice();
        let i = 0;
        for (const allocation of allocations) {
            allocation.index = i;
            i++;

            // Designation
                const allDesignations = this.state.DesignationsAll.slice();
                allocation.CostDesignations = allDesignations
                if (allocation.Designation) {
                    const filterCostDesignation = allocation.CostDesignations.filter(
                        obj => obj.id === allocation.Designation
                    );
                    if (filterCostDesignation.length > 0) {
                        allocation.dataValueCostDesignation = filterCostDesignation[0];
                    }
                }


            // Resource Region
            allocation.ResourceRegions = this.state.RegionsAll.slice();
            if (allocation.ResourceRegion) {
                const filterResourceRegion = allocation.ResourceRegions.filter(
                    obj => obj.id === allocation.ResourceRegion
                );
                if (filterResourceRegion.length > 0) {
                    allocation.dataValueResourceRegion = filterResourceRegion[0];
                }
            }

            // Resource group
            allocation.ResourceGroups = this.state.ResourceGrpAll.slice();
            if (allocation.ResourceGroup) {
                const filterResourceGroup = allocation.ResourceGroups.filter(
                    obj => obj.value === allocation.ResourceGroup
                );
                if (filterResourceGroup.length > 0) {
                    allocation.dataValueResourceGrp = filterResourceGroup[0];
                }
            }

            // Onsite / Offshore
            allocation.OnsiteStatuses = this.OnsiteStatusValues;
            if (allocation.OnsiteStatus === 'Onsite') {
                allocation.dataValueOnsiteStatus = this.OnsiteStatusValues[0];
            } else if (allocation.OnsiteStatus === 'Offshore') {
                allocation.dataValueOnsiteStatus = this.OnsiteStatusValues[1];
            } else {
                allocation.dataValueOnsiteStatus =  { id: 2, Name: 'Offshore' };
            }

            //billable statuses
            allocation.BillableStatuses = this.billableStatusValues;
            if (allocation.BillableStatus === true) {
                allocation.dataValueBillableStatus = this.billableStatusValues[0];
            } else if (allocation.BillableStatus === false) {
                allocation.dataValueBillableStatus = this.billableStatusValues[1];
            } else {
                allocation.dataValueBillableStatus = null;
            }

            this.setMinMaxDatesBasedOnProjectDates(allocation);

            // set start date and end date
            if (allocation.StartDate) {
                allocation.DateStartDate = new Date(allocation.StartDate);
                allocation.StartDate = new Date(allocation.StartDate);
            } else {
                allocation.DateStartDate = null;
                allocation.StartDate = null;
            }

            // Payment Method
            if (allocation.PaymentMethod === 1) {
                allocation.dataValuePaymentMethod = this.PaymentMethodValues[0];
            }
            if (allocation.PaymentMethod === 2) {
                allocation.dataValuePaymentMethod = this.PaymentMethodValues[1];
            }

            if (!allocation.PaymentMethod && this.props.project.PaymentMethod === 3) {
                allocation.PaymentMethodShouldBeChanged = true;
            } else {
                allocation.PaymentMethodShouldBeChanged = false;
            }

            allocation.RateTypes = JSON.parse(JSON.stringify(this.state.RateTypes));

            // Rate Type
            if (allocation.RateTypes) {
                const filterRateType = allocation.RateTypes.filter(obj => obj.id === allocation.RateType);
                if (filterRateType.length > 0) {
                    allocation.dataValueRateType = filterRateType[0];
                }
            }

            //disable rate value
            if (allocation.dataValueRateType && allocation.dataValueRateType.Code !== CUSTOM_RATE) {
                // Custom
                allocation.RateValueDisabled = true;
            }

            // Set edit mode
            allocation.inEdit = true;

            await this.autoPopulateRatesInDropDown(allocation);
        }


        if (allocations && allocations.length > 0) {
            allocations.forEach(function(allocation) {
                allocation.Selected = false;
            });
        }

        if (this.props.isAPS || this.props.isResourceAug || this.props.isBlanketWorkOrder){

            for(const allocation of allocations){
                let projOrCrCountryId = '';
                if (allocation.EndDate) {
                    if (this.props.cmSheetReference === 'crCMS') {
                        projOrCrCountryId = this.props.changeRequest.CountryId;
                    } else {
                        projOrCrCountryId = this.props.project.CountryId;
                    }
                } else {
                    if (this.props.cmSheetReference === 'crCMS') {
                        projOrCrCountryId = this.props.changeRequest.CountryId;
                    } else {
                        projOrCrCountryId = this.props.project.CountryId;
                    }
                }
                await this.updateAllocation(allocation, projOrCrCountryId);
            }
        }


        await this.setState({
            resourceAllocations: allocations,
            TotalFTEResourceAllocation: this.props.cmSheet.TotalFTEResourceAllocation
                ? this.props.cmSheet.TotalFTEResourceAllocation
                : 0,
            TotalRevenueResourceAllocation: this.props.cmSheet.TotalRevenueResourceAllocation
                ? this.props.cmSheet.TotalRevenueResourceAllocation
                : 0,
            TotalEffortDaysResourceAllocation: this.props.cmSheet.TotalEffortDaysResourceAllocation
                ? this.props.cmSheet.TotalEffortDaysResourceAllocation
                : 0,
            TotalEffortDaysResourceAllocationNonBill: this.props.cmSheet.TotalNonDeployableResource
                ? this.props.cmSheet.TotalNonDeployableResource
                : 0,
            TotalCostToRevenueRatioResourceAllocation: this.props.cmSheet.TotalCostToRevenueRatioResourceAllocation
                ? this.props.cmSheet.TotalCostToRevenueRatioResourceAllocation
                : 0
        });
        this.setState({
            isLoading: false
        });
    };

    updateAllocation = async (allocation, projOrCrCountryId ) => {

        if (allocation && allocation.StartDate && allocation.EndDate) {

                let startDate = null;
                let endDate = null;
                let holidays = [];

                    startDate = new Date(allocation.StartDate);
                    endDate = new Date(allocation.EndDate);

                // Allocation percentage Calculation
                let onsiteStatus = allocation.dataValueOnsiteStatus ? allocation.dataValueOnsiteStatus : '';

                let defaultCountry = '';
                if (onsiteStatus && onsiteStatus.Name === 'Offshore') {
                    defaultCountry = allocation.dataValueResourceRegion ? allocation.dataValueResourceRegion.DefaultCountryId : projOrCrCountryId;
                } else {
                    defaultCountry = projOrCrCountryId;
                }

                // Holidays
                for(let i = startDate.getFullYear(); i <= endDate.getFullYear();i++ ){
                    let holiday = await getHolidaysIntegrated(defaultCountry, i).then(res => {
                        if (res && res.data) {
                            holidays = holidays.concat(res.data)
                        }
                    }).catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                }

                let startDateTimeZone = new Date(startDate);
                startDateTimeZone.setHours(0, 0, 0, 0);
                let end_date = moment(new Date(endDate));
                let start_date = moment(new Date(startDateTimeZone));

                let loop = new Date(start_date);
                let totalWorkingDays = 0;
                let isHoliday = false;
                while (loop <= end_date) {
                    isHoliday = false;
                    const dateStr = getFormattedDate(loop);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (!isHoliday && !isWeekend(loop)) {
                        totalWorkingDays++;
                    }

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

                const effortDays = ((totalWorkingDays * allocation.AllocationPercentage)/100).toFixed(2);

                if (effortDays >= 0) {
                    let num = effortDays * 8;
                    let roundoff = num.toFixed(2);
                    allocation.EffortHours = roundoff;
                }
                else if((!effortDays || effortDays === '0')){
                    allocation.EffortHours = 0;
                }
                allocation.EffortDays = effortDays;

                // Effort Days
                if (allocation.NoOfResources > 0 && allocation.EffortDays > 0) {
                    const tempEffortDays = allocation.EffortDays * allocation.NoOfResources;
                    allocation.TotalEffortDays = Number(tempEffortDays.toFixed(2));
                } else {
                    allocation.TotalEffortDays = 0;
                }
        }
    }

    handleAddButtonClick = async () => {

        this.setState({
            isStartDateChanged: false
        });

        const allocations = this.state.resourceAllocations.slice();


        for (const allocation of allocations) {
            allocation.index = allocation.index + 1;
        }

        let startDate = null

        const newItem = {
            index: 0,
            dataValueCostDesignation: null,
            dataValueResourceGrp: {
                value: 2,
                name: 'Functional Consultants'
            },
            dataValueResourceRegion: {
                CostRegion: true,
                Country: {
                    Code: 'LKA',
                    CountryRegion:{
                        CountryId: 209,
                        RegionId: 6,
                        Name: 'Sri Lanka',
                        id: 209
                    }
                },
                Currency:{
                    Code: 'LKR',
                    id: 5
                },
                id:6,
                Name: 'COE - SL',
                DefaultCountryId:209
            },
            ResourceRegion:6,
            dataValueOnsiteStatus: { id: 2, Name: 'Offshore' },
            dataValueBillableStatus: this.billableStatusValues[0],
            OnsiteStatus:  'Offshore',
            BillableStatus: true,
            FullProject: false,
            StartDate: this.props.cmSheetReference === "crCMS" ? this.props.changeRequest.StartDate : this.props.project.StartDate,
            EndDate: this.props.cmSheetReference === "crCMS" ? this.props.changeRequest.EndDate : this.props.project.EndDate,
            DateStartDate: this.props.cmSheetReference === "crCMS" ? this.props.changeRequest.StartDate : this.props.project.StartDate,
            DatesDisabled: false,
            AllocationPercentage: 100,
            AllocationHours: 0,
            dataValueRateType: null,
            RateType: null,
            RateValue: 0,
            RateValueDisabled: true,
            NoOfResources: 1,
            TotalCost: 0,
            TotalRevenue: 0,
            TotalEffortDays: 0,
            EffortDays: 0,
            EffortHours: 0,
            CostToRevenueRatio: 0,
            inEdit: true,
            //ProjectRoles: this.state.DesignationsAll.slice().filter(obj => obj.IsProjectRole === true),
            CostDesignations: this.state.DesignationsAll.slice(),
            ResourceGroups: this.state.ResourceGrpAll.slice(),
            ResourceRegions: this.state.RegionsAll.slice(),
            RateTypes: JSON.parse(JSON.stringify(this.state.RateTypes)),
            isStartDateChanged:false,
            OnsiteStatuses : this.OnsiteStatusValues,
            BillableStatuses : this.billableStatusValues
        };

        this.setMinMaxDatesBasedOnProjectDates(newItem);

        await this.calculateRowValues(newItem);

        allocations.unshift(newItem);

        this.setState(
            {
                resourceAllocations: allocations
            },
            () => {
                const totalValues = this.getTotalValues();
                this.setState(
                    {
                        TotalFTEResourceAllocation: totalValues.TotalFTEResourceAllocation,
                        TotalRevenueResourceAllocation: totalValues.TotalRevenueResourceAllocation,
                        TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation,
                        TotalEffortDaysResourceAllocationNonBill: totalValues.TotalEffortDaysResourceAllocationNonBill,
                        TotalCostToRevenueRatioResourceAllocation: totalValues.TotalCostToRevenueRatioResourceAllocation,
                        TotalEffortDaysResourceAllocationFixed: totalValues.TotalEffortDaysResourceAllocationFixed,
                        TotalEffortDaysResourceAllocationTnM: totalValues.TotalEffortDaysResourceAllocationTnM
                    },
                    () => {
                        this.updateCMSheet();
                    }
                );
            }
        );
    };

    handleDeleteButtonClick = dataItem => {
        const allocations = this.state.resourceAllocations.slice();

        let resourceToDelete = allocations.filter(resource => resource.Selected === true);

        this.setState(
            {
                rowItemToDelete: resourceToDelete
            },
            () => {
                const message = 'Are you sure, you want to delete this item?';
                this.toggleConfirmDialog(message, this.deleteRow);
            }
        );
    };

    deleteRow = () => {
        let deletedIndex = [];
        const allocations = this.state.resourceAllocations.slice();
        for(let obj of this.state.rowItemToDelete){
            deletedIndex.push(obj.index);
        }

        for (let i = deletedIndex.length - 1; i >= 0; i--) {
            allocations.splice(deletedIndex[i], 1);
        }

        for (let i = 0; i < allocations.length; i++) {
            allocations[i].index = i;
        }

        this.setState(
            {
                resourceAllocations: allocations
            },
            () => {
                const totalValues = this.getTotalValues();
                this.setState(
                    {
                        TotalFTEResourceAllocation: totalValues.TotalFTEResourceAllocation,
                        TotalRevenueResourceAllocation: totalValues.TotalRevenueResourceAllocation,
                        TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation,
                        TotalEffortDaysResourceAllocationNonBill: totalValues.TotalEffortDaysResourceAllocationNonBill,
                        TotalCostToRevenueRatioResourceAllocation: totalValues.TotalCostToRevenueRatioResourceAllocation,
                        TotalEffortDaysResourceAllocationFixed: totalValues.TotalEffortDaysResourceAllocationFixed,
                        TotalEffortDaysResourceAllocationTnM: totalValues.TotalEffortDaysResourceAllocationTnM,
                        rowItemToDelete: null
                    },
                    () => {
                        this.toggleConfirmDialog('', null);
                        this.updateCMSheet();
                    }
                );
            }
        );
    };

    handleChangeComboBox = async (event, dataItem) => {
        const allocations = this.state.resourceAllocations.slice();
        const index = dataItem.index;
        const field = event.target.name;
        const value = event.target.value;

        if (index > -1) {
            switch (field) {
                case 'CostDesignation': {
                    this.populateDesignations(allocations[index]);
                    allocations[index].Designation = value ? value.id : null;
                    allocations[index].dataValueCostDesignation = value;

                    if (value && value.Name === 'Outsourced') {
                        this.setState(
                            {
                                OutsourcedCostDataItemIndex: index
                            },
                            () => {
                                this.toggleOutsourcedCostDialog();
                            }
                        );
                    } else {
                        allocations[index].CostPerOutSourcedResource = 0;
                        await this.calculateRowValues(allocations[index]);
                    }

                    break;
                }
                case 'OnsiteStatus': {
                    allocations[index].OnsiteStatus = value ? value.id : null;
                    allocations[index].dataValueOnsiteStatus = value;
                    break;
                }
                case 'BillableStatus': {
                    allocations[index].BillableStatus = value && value.id ? value.value : null;
                    allocations[index].dataValueBillableStatus = value;
                    break;


                }
                case 'RateType': {
                    allocations[index].RateType = value && value.id ? value.id : null;
                    allocations[index].dataValueRateType = value && value.id ? value : null;
                    await this.populateRateValue(allocations[index]);
                    break;
                }
                case 'ResourceRegion': {
                    allocations[index].ResourceRegion = value ? value.id : null;
                    allocations[index].dataValueResourceRegion = value;
                    await this.calculateRowValues(allocations[index]);
                    break;
                }
                case 'ResourceGrp': {
                    allocations[index].ResourceGroup = value ? value.value : null;
                    allocations[index].dataValueResourceGrp = value;
                    //await this.calculateRowValues(allocations[index]);
                    break;
                }
                default: {
                    break;
                }
            }
        }

        await this.setState(
            {
                resourceAllocations: allocations
            },
            () => {
                const totalValues = this.getTotalValues();
                this.setState(
                    {
                        TotalFTEResourceAllocation: totalValues.TotalFTEResourceAllocation,
                        TotalRevenueResourceAllocation: totalValues.TotalRevenueResourceAllocation,
                        TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation,
                        TotalEffortDaysResourceAllocationNonBill: totalValues.TotalEffortDaysResourceAllocationNonBill,
                        TotalCostToRevenueRatioResourceAllocation: totalValues.TotalCostToRevenueRatioResourceAllocation,
                        TotalEffortDaysResourceAllocationFixed: totalValues.TotalEffortDaysResourceAllocationFixed,
                        TotalEffortDaysResourceAllocationTnM: totalValues.TotalEffortDaysResourceAllocationTnM
                    },
                    () => {
                        this.updateCMSheet();
                    }
                );
            }
        );
    };

    populateDesignations = dataItem => {
            dataItem.CostDesignations = this.state.DesignationsAll.slice()

            const filterData = dataItem.CostDesignations

            if (filterData.length > 0) {
                dataItem.dataValueCostDesignation = filterData[0];
                dataItem.Designation = dataItem.dataValueCostDesignation.id;
            }

    };

    handleChangeDropDown = async (event, dataItem) => {
        const allocations = this.state.resourceAllocations.slice();
        const field = event.target.name;
        const value = event.target.value;

        switch (field) {
            case 'PaymentMethod': {
                allocations[dataItem.index].PaymentMethod = value && value.id ? value.id : null;
                allocations[dataItem.index].dataValuePaymentMethod = value;
                break;
            }
            default: {
                break;
            }
        }

        await this.calculateRowValues(allocations[dataItem.index]);

        this.setState(
            {
                resourceAllocations: allocations
            },
            () => {
                const totalValues = this.getTotalValues();
                this.setState(
                    {
                        TotalFTEResourceAllocation: totalValues.TotalFTEResourceAllocation,
                        TotalRevenueResourceAllocation: totalValues.TotalRevenueResourceAllocation,
                        TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation,
                        TotalEffortDaysResourceAllocationNonBill: totalValues.TotalEffortDaysResourceAllocationNonBill,
                        TotalCostToRevenueRatioResourceAllocation: totalValues.TotalCostToRevenueRatioResourceAllocation,
                        TotalEffortDaysResourceAllocationFixed: totalValues.TotalEffortDaysResourceAllocationFixed,
                        TotalEffortDaysResourceAllocationTnM: totalValues.TotalEffortDaysResourceAllocationTnM
                    },
                    () => {
                        this.updateCMSheet();
                    }
                );
            }
        );
    };

    autoPopulateRatesInDropDown = async (dataItem, showErrors) => {
        let customerRate,
            regionRate,
            projectRate,
            projectCrRate,
            lastCustomRate = 0;
        let lastCustomRateProjectName = '';
        let lastApprovedProject_Rate = 0;

        let data = null;
        let customerId = null;
        let regionId = null;
        let projectId = null;
        if (this.props.cmSheetReference === 'crCMS') {
            data = this.props.changeRequest;
            customerId = this.props.changeRequest.CustomerId;
            regionId = this.props.changeRequest.BillingRegion;
            projectId = this.props.changeRequest.ProjectId;
        } else {
            data = this.props.project;
            customerId = this.props.project.CustomerId;
            regionId = this.props.project.BillingRegion;
            projectId = this.props.project.id;
        }


        if (dataItem.ProjectRole > 0) {
            // get customer rate
            const rateCardParams = {
                customerId: customerId,
                regionId: regionId,
                role: dataItem.ProjectRole,
                projectDate: this.state.costDate,
                data: data,
                dataType: this.props.cmSheetReference,
                projectId: projectId
            };

            await getRatesForAllocation(rateCardParams, showErrors)
                .then(async result => {
                    const defaultRate = result.data.defaultRate;

                    const customersRate = result.data.customerRate;
                    const projectSpecificRate = result.data.projectSpecificRate;
                    const projectSpecificCrRate = result.data.projectSpecificCrRate;
                    const lastCustomerRate = result.data.lastCustomerRate;
                    const lastApprovedProjRate = result.data.lastApprovedrate;

                    //For standard rate
                    if (defaultRate && defaultRate.Rate > 0) {
                        regionRate = defaultRate.Rate > 0 ? defaultRate.Rate : 0;
                    }
                    //For pre-agreed rate
                    if (customersRate && customersRate.Rate > 0) {
                        customerRate = customersRate.Rate > 0 ? customersRate.Rate : 0;
                    }
                    //For project specific rate
                    if (projectSpecificRate && projectSpecificRate.Rate > 0) {
                        projectRate = projectSpecificRate.Rate > 0 ? projectSpecificRate.Rate : 0;
                    }
                    //For project specific CR rate
                    if (projectSpecificCrRate && projectSpecificCrRate.Rate > 0) {
                        projectCrRate = projectSpecificCrRate.Rate > 0 ? projectSpecificCrRate.Rate : 0;
                    }
                    //For last overridden rate
                    if (lastCustomerRate && lastCustomerRate.Rate > 0) {
                        lastCustomRate = lastCustomerRate.Rate;
                        lastCustomRateProjectName = lastCustomerRate.Project.Name;
                    }
                    if (showErrors && (!customersRate || customersRate.Rate <= 0)) {
                        if (showErrors && (!regionRate || regionRate.Rate <= 0)) {
                            const message = 'Standard rate not found for ' + dataItem.dataValueProjectRole.Name;
                            const title = 'Error';
                            this.setState({
                                showSuccessImage: false,
                                showErrorImage: true
                            });
                            this.toggleMessageDialog(message, title);
                        }
                    }
                    //For overridden rate
                    if (lastApprovedProjRate && lastApprovedProjRate.Rate > 0) {
                        lastApprovedProject_Rate = lastApprovedProjRate.Rate;

                    }
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
        }

        for (const rateType of dataItem.RateTypes) {
            if (rateType.Code === CUSTOMER_RATE) {
                rateType.Rate = customerRate;
                rateType.Text = rateType.Name + ' - ' + formatNumber(rateType.Rate);
                //rateType.RateCardId = customerRateCardId;
            }
            if (rateType.Code === REGION_RATE) {
                rateType.Rate = regionRate;
                rateType.Text = rateType.Name + ' - ' + formatNumber(rateType.Rate);
                //rateType.RateCardId = regionRateCardId;
            }
            if (rateType.Code === PROJECT_APPROVED_RATE) {
                rateType.Rate = projectRate;
                rateType.Text = rateType.Name + ' - ' + formatNumber(rateType.Rate);
            }
            if (rateType.Code === PROJECT_APPROVED_CR_RATE) {
                rateType.Rate = projectCrRate;
                rateType.Text = rateType.Name + ' - ' + formatNumber(rateType.Rate);
            }
            if (rateType.Code === LAST_CUS_RATE) {
                rateType.Rate = lastCustomRate;
                rateType.Text = rateType.Name + ' - ' + formatNumber(rateType.Rate);
                rateType.LastCustomerRateProject = lastCustomRateProjectName;
            }
            if (rateType.Code === APPROVED_PROJ_RATE) {
                rateType.Rate = lastApprovedProject_Rate;
                rateType.Text = rateType.Name + ' - ' + formatNumber(rateType.Rate);
            }
        }
    };

    autoPopulateRate = async dataItem => {
        //Sorting in custom order to get prioritized rate
        const sortOrder = [PROJECT_APPROVED_CR_RATE, PROJECT_APPROVED_RATE, CUSTOMER_RATE, REGION_RATE, LAST_CUS_RATE, CUSTOM_RATE, ADMIN_RATE, APPROVED_PROJ_RATE];
        const rateTypes = cloneDeep(dataItem.RateTypes);
        const rateTypesFiltered = rateTypes.filter(obj => obj.Rate > 0);
        rateTypesFiltered.sort((a, b) => {
            return sortOrder.indexOf(a.Code) - sortOrder.indexOf(b.Code);
        });

        if (rateTypesFiltered.length > 0) {
            let prioritizedRate = rateTypesFiltered[0];
            dataItem.StandardRate = prioritizedRate.Rate;
            dataItem.rateNoLongerValid = false;
            dataItem.RateType = prioritizedRate.id;
            dataItem.dataValueRateType = prioritizedRate;
            dataItem.RateValue = prioritizedRate.Rate;
            dataItem.LastCustomerRateProject =
                prioritizedRate.Code === LAST_CUS_RATE ? prioritizedRate.LastCustomerRateProject : '';
            dataItem.RateValueDisabled = prioritizedRate.Code !== CUSTOM_RATE;
        } else {
            dataItem.RateType = null;
            dataItem.dataValueRateType = null;
            dataItem.RateValue = 0;
            dataItem.LastCustomerRateProject = '';
        }
    };

    populateRateValue = async dataItem => {
        const sortOrder = [PROJECT_APPROVED_CR_RATE, PROJECT_APPROVED_RATE, CUSTOMER_RATE, REGION_RATE, LAST_CUS_RATE, CUSTOM_RATE, ADMIN_RATE, APPROVED_PROJ_RATE];
        const rateTypes = cloneDeep(dataItem.RateTypes);
        const rateTypesFiltered = rateTypes.filter(obj => obj.Rate > 0);
        rateTypesFiltered.sort((a, b) => {
            return sortOrder.indexOf(a.Code) - sortOrder.indexOf(b.Code);
        });

        if (rateTypesFiltered.length > 0) {
            let prioritizedRate = rateTypesFiltered[0];
            dataItem.StandardRate = prioritizedRate.Rate;
        }

        dataItem.rateNoLongerValid = false;
        if (dataItem.dataValueRateType) {
            dataItem.RateValueDisabled = true;
            dataItem.RateValue = dataItem.dataValueRateType.Rate ? dataItem.dataValueRateType.Rate : 0;
            dataItem.LastCustomerRateProject = '';

            switch (dataItem.dataValueRateType.Code) {
                case LAST_CUS_RATE: {
                    // Last Customer Rates
                    dataItem.LastCustomerRateProject = dataItem.dataValueRateType.LastCustomerRateProject;
                    break;
                }
                case CUSTOM_RATE: {
                    // Custom Rate
                    dataItem.RateValueDisabled = false;
                    dataItem.RateValue = 0;
                    break;
                }
                default:{

                }
            }
            if (!dataItem.RateValue) {
                dataItem.RateValue = 0;
            }
        } else {
            dataItem.RateValue = 0;
        }

        if (dataItem.RateValue === 0 && dataItem.dataValueRateType && dataItem.dataValueRateType.Code !== ADMIN_RATE) {
            this.setState({ isErrorRateValue: true });
        } else {
            this.setState({ isErrorRateValue: false });
        }
        await this.calculateRowValues(dataItem);
    };

    itemChange = async event => {
        const value = event.value;
        const name = event.field;
        const dataItem = event.dataItem;
        this.setState({
            isLoading: true
        });
        var decimal = /^[-+]?[0-9]+\.[0-9]+$/;
        switch (name) {
            case 'DateStartDate':
                await this.handleDatePickerChange(event);
                break;
            case 'EndDate':
                await this.handleDatePickerChange(event);
                break;
            case 'AllocationPercentage':
                dataItem[name] = value;
                if (!value || value === '0') {
                    dataItem.AllocationPercentage = 0;
                    this.setState({ isErrorAllocationPercentage: true });
                } else {
                    this.setState({ isErrorAllocationPercentage: false });
                }
                break;
            case 'NoOfResources':
                dataItem[name] = value;
                let resourceCount = 0;
                if (value > 0) {
                    resourceCount = Number(value).toFixed(0);
                    dataItem.NoOfResources = resourceCount;
                }
                if (!value || value === '0' || value < 0) {
                    dataItem.NoOfResources = 0;
                    this.setState({ isErrorNoOfResources: true });
                } else {
                    this.setState({ isErrorNoOfResources: false });
                }
                break;
            case 'RateValue':
                dataItem[name] = value;
                if (!value || value === '0') {
                    dataItem.RateValue = 0;
                    this.setState({ isErrorRateValue: true });
                } else {
                    this.setState({ isErrorRateValue: false });
                }
                break;
            case 'EffortDays': {
                dataItem[name] = value;
                if (value >= 0) {
                    let num = value * 8;
                    let roundoff = num.toFixed(2);
                    dataItem.EffortHours = roundoff;
                }
                if (!value || value === '0') {
                    dataItem.EffortHours = 0;
                    dataItem.EffortDays = 0;
                    this.setState({ isErrorEffortDays: true });
                } else {
                    this.setState({ isErrorEffortDays: false });
                }
                break;
            }
            case 'EffortHours' : {
                dataItem[name] = value;
                if (value >= 0) {
                    let num = value / 8;
                    let roundoff = num.toFixed(2);
                    dataItem.EffortDays = roundoff;
                }
                if (!value || value === '0') {
                    dataItem.EffortDays = 0;
                    dataItem.EffortHours = 0;
                    this.setState({ isErrorEffortHours: true });
                } else {
                    this.setState({ isErrorEffortHours: false });
                }
                break;
            }
        }

        await this.calculateRowValues(dataItem);
        this.setState(
            {
                resourceAllocations: [...this.state.resourceAllocations]
            },
            () => {
                const totalValues = this.getTotalValues();
                this.setState(
                    {
                        TotalFTEResourceAllocation: totalValues.TotalFTEResourceAllocation,
                        TotalRevenueResourceAllocation: totalValues.TotalRevenueResourceAllocation,
                        TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation,
                        TotalEffortDaysResourceAllocationNonBill: totalValues.TotalEffortDaysResourceAllocationNonBill,
                        TotalCostToRevenueRatioResourceAllocation: totalValues.TotalCostToRevenueRatioResourceAllocation,
                        TotalEffortDaysResourceAllocationFixed: totalValues.TotalEffortDaysResourceAllocationFixed,
                        TotalEffortDaysResourceAllocationTnM: totalValues.TotalEffortDaysResourceAllocationTnM
                    },
                    () => {
                        this.updateCMSheet();
                    }
                );
            }
        );
        this.setState({
            isLoading: false
        });
    };

    handleDatePickerChange = async event => {
        const field = event.field;
        const dataItem = event.dataItem;
        this.setState({
            isLoading: true
        });
        // Start Date
        if (field === 'DateStartDate') {

            this.setState({
                isStartDateChanged: true
            });
            if (event.value) {
                let startDate = new Date(event.value);
                startDate.setHours(0, 0, 0, 0);

                if (startDate && (startDate >= new Date(dataItem.StartDateMin))) {
                    this.setState({
                        StartDateIsAPastDate: false
                    });
                    dataItem.StartDate = startDate;
                } else {
                    this.setState({
                        StartDateIsAPastDate: true
                    });
                    dataItem.StartDate = startDate;
                }

                dataItem.DateStartDate = startDate;

            } else {
                dataItem.StartDate = null;
                dataItem.DateStartDate = null;
            }
        }
        else if (field === 'EndDate') {
            if (event.value) {
                let endDate = new Date(event.value);
                endDate.setHours(0, 0, 0, 0);
                dataItem.endDate = endDate;
                dataItem.EndDate = endDate;
            } else {
                dataItem.EndDate = null;
                dataItem.endDate = null;
            }
        }

        await this.calculateRowValues(dataItem);
        this.setState(
            {
                resourceAllocations: [...this.state.resourceAllocations]
            },
            () => {
                const totalValues = this.getTotalValues();
                this.setState(
                    {
                        TotalFTEResourceAllocation: totalValues.TotalFTEResourceAllocation,
                        TotalRevenueResourceAllocation: totalValues.TotalRevenueResourceAllocation,
                        TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation,
                        TotalEffortDaysResourceAllocationNonBill: totalValues.TotalEffortDaysResourceAllocationNonBill,
                        TotalCostToRevenueRatioResourceAllocation: totalValues.TotalCostToRevenueRatioResourceAllocation,
                        TotalEffortDaysResourceAllocationFixed: totalValues.TotalEffortDaysResourceAllocationFixed,
                        TotalEffortDaysResourceAllocationTnM: totalValues.TotalEffortDaysResourceAllocationTnM
                    },
                    () => {
                        this.updateCMSheet();
                    }
                );
            }
        );
        this.setState({
            isLoading: false
        });
    };

    getProjectEstimatedEndDate = () => {
        let maxDate = null;
        for (const allocation of this.state.resourceAllocations) {
            if (allocation.EstimatedEndDate) {
                const tempDate = new Date(allocation.EstimatedEndDate);
                if ((maxDate && tempDate > maxDate) || !maxDate) {
                    maxDate = tempDate;
                }
            }
        }
        return maxDate;
    };

    calculateMultiYearSlots = async dataItem => {

        let projOrCRStartDate = null;

        //if(this.props.isAPS || this.props.isResourceAug || this.props.isBlanketWorkOrder){
            projOrCRStartDate = dataItem.StartDate;
        // }else{
        //     if (this.props.cmSheetReference === 'crCMS') {
        //         projOrCRStartDate = this.props.changeRequest.EstimatedStartDate;
        //     } else {
        //         projOrCRStartDate = this.props.project.EstimatedStartDate;
        //     }
        // }

        let projectStart = await moment(projOrCRStartDate);
        let projectEnd = null;
        //if(this.props.isAPS || this.props.isResourceAug || this.props.isBlanketWorkOrder){
            projectEnd = await moment(dataItem.EndDate);
        // }else{
        //     projectEnd = await moment(this.getProjectEstimatedEndDate());
        // }

        let yearslotsWithinStartEndProjectDates = [];

        let projectStartYear = new Date(projectStart).getFullYear();
        let projectEndYear = new Date(projectEnd).getFullYear();

        let yearListWithinStartEndDate = [];

        for (let i = projectStartYear; i <= projectEndYear; i++) {
            yearListWithinStartEndDate.push(i);
        }
        for (let i = 0; i <= yearListWithinStartEndDate.length - 1; i++) {
            let futureYearPart = (yearListWithinStartEndDate[i] + 1).toString();
            let futureFullYear = futureYearPart + '-03-31';
            let startYearPart = yearListWithinStartEndDate[i].toString();
            let startullYear = startYearPart + '-04-01';
            let futureYear = moment(futureFullYear).format();
            if (i === 0) {
                if (moment(startullYear).format() > projectStart.format()) {
                    let futureYearPartFirstSlot = yearListWithinStartEndDate[i].toString();
                    let futureFullYearFirstSlot = futureYearPartFirstSlot + '-03-31';
                    let startYearPartFirstSlot = (yearListWithinStartEndDate[i] - 1).toString();
                    let startullYearFirstSlot = startYearPartFirstSlot + '-04-01';
                    let futureYearFirstSlot = moment(futureFullYearFirstSlot).format();

                    const slots = {
                        startDate: moment(startullYearFirstSlot).format(),
                        endDate: futureYearFirstSlot
                    };
                    yearslotsWithinStartEndProjectDates.push(slots);
                }
            }
            const slots = {
                startDate: moment(startullYear).format(),
                endDate: futureYear
            };
            yearslotsWithinStartEndProjectDates.push(slots);
        }
        this.setState({ yearslotsWithinStartEndProjectDates: yearslotsWithinStartEndProjectDates });
    };

    calculateEffortforResourceDays = async dataItem => {
        if (this.state.yearslotsWithinStartEndProjectDates && this.state.yearslotsWithinStartEndProjectDates.length > 0) {
            let startDate = new Date();
            let noOfWorkingDays = dataItem.EffortDays;

            for (let i = 0; i <= this.state.yearslotsWithinStartEndProjectDates.length - 1; i++) {
                let endDate = new Date();
                let workingDaysCount = 0;

                if (i === 0) {
                    startDate = new Date(this.state.costDate);
                } else {
                    startDate = new Date(this.state.yearslotsWithinStartEndProjectDates[i].startDate);
                }
                if (this.state.yearslotsWithinStartEndProjectDates[i].endDate > dataItem.EstimatedEndDate) {
                    endDate = new Date(dataItem.EstimatedEndDate);
                    // Adding a day to the end date to make the loop
                    // run one extra time
                    endDate.setDate(endDate.getDate() + 1);
                } else {
                    endDate = new Date(this.state.yearslotsWithinStartEndProjectDates[i].endDate);
                    // Adding a day to the end date to make the loop
                    // run one extra time
                    endDate.setDate(endDate.getDate() + 1);
                }

                let countryId = null;

                if (dataItem.OnsiteStatus === 'Onsite') {
                    countryId = this.props.cmSheetReference && this.props.cmSheetReference === 'crCMS' ? this.props.changeRequest.CountryId : this.props.project.CountryId;
                } else {
                    countryId = dataItem.dataValueResourceRegion.DefaultCountryId;
                }


                let holidays = await getHolidaysIntegrated(countryId, startDate.getFullYear()).then(res => {
                    if (res && res.data) {
                        return res.data;
                    }
                    return [];
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });

                if (holidays && holidays.length === 0) {
                } else if (!holidays) {
                    holidays = [];
                }
                let currentYear = startDate.getFullYear();

                let count = 0;
                let holidayCount = 0;
                let weekendCount = 0;
                // TODO: This While loop has to be convereted to a DO While Loop
                while (startDate < endDate) {
                    if (currentYear !== endDate.getFullYear()) {
                        holidays = await getHolidaysIntegrated(countryId, endDate.getFullYear())
                            .then(res => {
                                if (res && res.data) {
                                    return res.data;
                                }
                                return [];
                            })
                            .catch(error => {
                                loggerService.writeLog(error, LOG_TYPES.ERROR);
                                return [];
                            });

                        currentYear = endDate.getFullYear();
                    }

                    let isHoliday = false;
                    const dateStr = getFormattedDate(startDate);
                    if (holidays.indexOf(dateStr) > -1) {
                        isHoliday = true;
                    }

                    if (isHoliday) {
                        holidayCount++;
                    }

                    if (isWeekend(startDate)) {
                        weekendCount++;
                    }

                    startDate.setDate(startDate.getDate() + 1);
                    count++;
                }

                let workingDayCount = count - weekendCount - holidayCount;

                workingDayCount = workingDayCount * (dataItem.AllocationPercentage / 100);
                if (workingDayCount > noOfWorkingDays) {
                    workingDayCount = noOfWorkingDays;
                }

                noOfWorkingDays = noOfWorkingDays - workingDayCount;
                this.state.yearslotsWithinStartEndProjectDates[i].workingDaysCount = workingDayCount;
            }
        }
    };

    calculateRowValues = async allocation => {

        // Effort Days
        if (allocation.NoOfResources > 0 && allocation.EffortDays > 0) {
            const tempEffortDays = allocation.EffortDays * allocation.NoOfResources;
            allocation.TotalEffortDays = Number(tempEffortDays.toFixed(2));
        } else {
            allocation.TotalEffortDays = 0;
        }
            if (this.props.cmSheetReference === 'projectCMS') {
                await this.updateAllocation(allocation, this.props.project.Region.DefaultCountryId);
            }
            else {
                await this.updateAllocation(allocation, this.props.changeRequest.Region.DefaultCountryId);
            }


        // Total Days
        if (allocation.AllocationPercentage > 0 && allocation.EffortDays > 0) {

            let startDateObj = null;
            if (this.props.cmSheetReference === 'crCMS') {
                startDateObj = getStartDateByPriority(allocation, this.props.changeRequest, this.props.cmSheet);
            } else {
                startDateObj = getStartDateByPriority(allocation, this.props.project, this.props.cmSheet);
            }

            let startDate = startDateObj.date;
            allocation.StartDateType = startDateObj.type;

            //Date passed to get the cost card for the particular designation
            this.setState({
                costDate: startDate
            });

            let endDate_,
                noOfWorkingDays_,
                totalDays_ = '';

            if (allocation.dataValueOnsiteStatus && allocation.dataValueOnsiteStatus.Name === 'Onsite') {
                // Status

                if (this.props.cmSheetReference === 'crCMS') {
                    let { endDate, noOfWorkingDays, totalDays } = await calculateProjectEndDate(allocation, startDate, this.props.changeRequest.CountryId);
                    endDate_ = endDate;
                    noOfWorkingDays_ = noOfWorkingDays;
                    totalDays_ = totalDays;
                } else {
                    let { endDate, noOfWorkingDays, totalDays } = await calculateProjectEndDate(allocation, startDate, this.props.project.CountryId
                    );
                    endDate_ = endDate;
                    noOfWorkingDays_ = noOfWorkingDays;
                    totalDays_ = totalDays;
                }

            } else {
                if (allocation.dataValueResourceRegion) {
                    let { endDate, noOfWorkingDays, totalDays } = await calculateProjectEndDate(allocation, startDate, allocation.dataValueResourceRegion.DefaultCountryId);
                    endDate_ = endDate;
                    noOfWorkingDays_ = noOfWorkingDays;
                    totalDays_ = totalDays;
                } else {
                    allocation.TotalDays = 0;
                    allocation.WorkDays = 0;
                    allocation.EstimatedEndDate = null;
                }
            }

            allocation.TotalDays = totalDays_;
            allocation.WorkDays = Math.ceil(noOfWorkingDays_);
            allocation.EstimatedEndDate = getFormattedDate(endDate_);
        } else {
            allocation.TotalDays = 0;
            allocation.WorkDays = 0;
            allocation.EstimatedEndDate = null;
        }



        // Project Duration
        allocation.Duration = (allocation.TotalDays !== 0 ? allocation.TotalDays/30 : 0.00)

        // Cost
        await this.getCostForDesignation(allocation);

        // FTE
            allocation.FTE = ((allocation.Duration * (allocation.AllocationPercentage / 100))/12);
           // allocation.FTE = (allocation.FTE).toFixed(2);

    };

    getCostForDesignation = async dataItem => {

        let region = null;
        let billingRegion = 0;
        let projOrCr = null;
        let projectId = null;
        if (this.props.cmSheetReference === 'crCMS') {
            region = this.props.changeRequest.Region;
            billingRegion = this.props.changeRequest.BillingRegion;
            projOrCr = this.props.changeRequest;
            projectId = this.props.changeRequest.ProjectId;
        } else {
            region = this.props.project.Region;
            billingRegion = this.props.project.BillingRegion;
            projOrCr = this.props.project;
            projectId = this.props.project.id;
        }

        if (dataItem.dataValueCostDesignation && dataItem.dataValueCostDesignation.Name === 'Outsourced' && dataItem.CostPerOutSourcedResource > 0) {
            const cost = dataItem.CostPerOutSourcedResource * dataItem.TotalEffortDays;
            dataItem.TotalCost = cost;
        } else if (dataItem.dataValueCostDesignation && dataItem.dataValueCostDesignation.Name === 'Outsourced') {
            this.setState(
                {
                    // set the data row index. then index can be used when user enters the cost
                    OutsourcedCostDataItemIndex: dataItem.index
                },
                () => {
                    // to get cost from user
                    // this.toggleOutsourcedCostDialog();
                }
            );
        } else {
            // Other designation
            if (dataItem.dataValueResourceRegion && dataItem.Designation && billingRegion) {

                const costDate_ = calculateCostDate(dataItem, this.props.cmSheet, projOrCr);
                const costCard = {
                    billingRegionId: billingRegion,
                    resourceRegionId: dataItem.dataValueResourceRegion.id,
                    designationId: dataItem.Designation,
                    projectId: projectId,
                    costDate: moment(costDate_).format('YYYY-MM-DD')
                };

                this.setState({
                    costDate: costDate_
                });
                await this.calculateMultiYearSlots(dataItem);
                await this.calculateEffortforResourceDays(dataItem);

                await getCost(costCard)
                    .then(async res => {
                        if (res.data.length > 0 && res.data[0].id > 0) {
                            const costCard = res.data[0];
                            const costRecord = {
                                CurrencyId: costCard.BillingRegion.Currency.id,
                                Overhead: costCard.CostCardDesignations[0].Overhead,
                                CostToCompany: costCard.CostCardDesignations[0].CostToCompany
                            };

                            let costPerResourceTotal = costRecord.Overhead + costRecord.CostToCompany;

                            if (costPerResourceTotal === 0) {
                                dataItem.CostPerResource = 0;
                                dataItem.DirectCost = 0;
                                dataItem.TotalCost = 0;
                                const message =
                                    'Cost card record not found for the selected designation and resource region';
                                const title = 'Error';
                                this.setState({
                                    showSuccessImage: false,
                                    showErrorImage: true
                                });
                                this.toggleMessageDialog(message, title);
                            } else {

                                if (region && region.Currency && region.Currency.id > 0) {
                                    // if currency id equals project currency, no need of conversion
                                    if (region.Currency.Code === 'USD') {
                                        let costPerResource = costRecord.Overhead + costRecord.CostToCompany;
                                        dataItem.CostPerResource = costPerResource;
                                        dataItem.DirectCost = costRecord.CostToCompany;

                                        await this.calculateTotalCost(dataItem, costPerResource, res.data[0].id, 0);
                                    } else {
                                        const proStartDate = new Date(this.state.costDate);

                                        const ratePerUSD = await retrieveConversionRate(
                                            region.Currency.id,
                                            proStartDate.getMonth() + 1,
                                            proStartDate.getFullYear()
                                        );

                                        if (ratePerUSD > 0) {
                                            let costPerResource =
                                                (costRecord.Overhead + costRecord.CostToCompany) * ratePerUSD;
                                            dataItem.CostPerResource = costPerResource;
                                            dataItem.DirectCost = costRecord.CostToCompany * ratePerUSD;
                                            dataItem.TotalCost = costPerResource * dataItem.TotalEffortDays;

                                            await this.calculateTotalCost(
                                                dataItem,
                                                costPerResource,
                                                res.data[0].id,
                                                ratePerUSD
                                            );
                                        } else {
                                            dataItem.CostPerResource = 0;
                                            dataItem.DirectCost = 0;
                                            dataItem.TotalCost = 0;
                                            const message = 'Currency conversion rate not found for the billing region';
                                            const title = 'Error';
                                            this.toggleMessageDialog(message, title);
                                        }
                                    }
                                } else {
                                    dataItem.CostPerResource = 0;
                                    dataItem.DirectCost = 0;
                                    dataItem.TotalCost = 0;
                                    this.toggleMessageDialog('Billing region currency not found', 'Error');
                                }

                            }
                        } else {
                            dataItem.CostPerResource = 0;
                            dataItem.DirectCost = 0;
                            dataItem.TotalCost = 0;
                            const message =
                                'Cost card record not found for the selected designation and resource region';
                            const title = 'Error';
                            this.setState({
                                showSuccessImage: false,
                                showErrorImage: true
                            });
                            this.toggleMessageDialog(message, title);
                        }
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        dataItem.CostPerResource = 0;
                        dataItem.DirectCost = 0;
                        dataItem.TotalCost = 0;
                    });
            } else {
                dataItem.CostPerResource = 0;
                dataItem.DirectCost = 0;
                dataItem.TotalCost = 0;
            }

            dataItem.costNoLongerValid = false;
        }

    };

    calculateTotalCost = async (dataItem, costPerResource, costCardId, ratePerUSD) => {

        if (dataItem.NoOfResources > 0) {
            costPerResource = costPerResource * dataItem.NoOfResources;
        }
        let costforMultiYear = 0;
        if (this.state.yearslotsWithinStartEndProjectDates.length > 0) {
            for (let a = 0; a <= this.state.yearslotsWithinStartEndProjectDates.length - 1; a++) {
                if (a === 0) {
                    let tempCalculation =
                        this.state.yearslotsWithinStartEndProjectDates[a].workingDaysCount * costPerResource;
                    this.state.yearslotsWithinStartEndProjectDates[a].CalculatedCost = costPerResource;
                    costforMultiYear = tempCalculation + costforMultiYear;
                }
            else if (a === 1) {
                    const costCardMultiYear = {
                        billingRegionId: this.props.cmSheetReference === 'crCMS' ? this.props.changeRequest.BillingRegion : this.props.project.BillingRegion,
                        resourceRegionId: dataItem.ResourceRegion,
                        designationId: dataItem.Designation,
                        costDate: this.state.yearslotsWithinStartEndProjectDates[a].startDate //start date of second year slot
                    };
                    await getMultiYearCost(costCardMultiYear)
                    // eslint-disable-next-line no-loop-func
                        .then(async res => {
                            if (res.data.length > 0 && res.data[0].id > 0) {
                                costCardId = res.data[0].id;
                                const costResCardMultiYear = res.data[0];
                                const costRecordMultiYear = {
                                    CurrencyId: costResCardMultiYear.BillingRegion.Currency.id,
                                    Overhead: costResCardMultiYear.CostCardDesignations[0].Overhead,
                                    CostToCompany: costResCardMultiYear.CostCardDesignations[0].CostToCompany
                                };
                                let costPerResourceMultiYear =
                                    costRecordMultiYear.Overhead + costRecordMultiYear.CostToCompany;
                                if (dataItem.NoOfResources > 0) {
                                    costPerResourceMultiYear = costPerResourceMultiYear * dataItem.NoOfResources;
                                }
                                if (ratePerUSD > 0) {
                                    costPerResourceMultiYear =
                                        (costRecordMultiYear.Overhead + costRecordMultiYear.CostToCompany) * ratePerUSD;
                                }
                                let tempCalculation =
                                    this.state.yearslotsWithinStartEndProjectDates[a].workingDaysCount *
                                    costPerResourceMultiYear;
                                this.state.yearslotsWithinStartEndProjectDates[
                                    a
                                    ].CalculatedCost = costPerResourceMultiYear;
                                costforMultiYear = tempCalculation + costforMultiYear;
                            } else {
                                const params = {
                                    Year: new Date(
                                        this.state.yearslotsWithinStartEndProjectDates[a].endDate
                                    ).getFullYear(),
                                    CostCardId: costCardId
                                };
                                await getMultiYearInfoByYearandCostCardId(params).then(res => {
                                    if (res.data.length > 0 && res.data[0].id > 0) {
                                        let percentage = res.data[0].MultiyearIncrementGrids[0].RatePercentage
                                            ? res.data[0].MultiyearIncrementGrids[0].RatePercentage
                                            : 0;
                                        let calculatedCost =
                                            this.state.yearslotsWithinStartEndProjectDates[a - 1].CalculatedCost *
                                            (1 + percentage / 100);
                                        this.state.yearslotsWithinStartEndProjectDates[
                                            a
                                            ].CalculatedCost = calculatedCost;
                                        let tempCalculation =
                                            this.state.yearslotsWithinStartEndProjectDates[a].workingDaysCount *
                                            (this.state.yearslotsWithinStartEndProjectDates[a - 1].CalculatedCost *
                                                (1 + percentage / 100));
                                        costforMultiYear = tempCalculation + costforMultiYear;
                                    } else {
                                        this.state.yearslotsWithinStartEndProjectDates[
                                            a
                                            ].CalculatedCost = this.state.yearslotsWithinStartEndProjectDates[
                                        a - 1
                                            ].CalculatedCost;
                                        let tempCalculation =
                                            this.state.yearslotsWithinStartEndProjectDates[a].workingDaysCount *
                                            this.state.yearslotsWithinStartEndProjectDates[a - 1].CalculatedCost;
                                        costforMultiYear = tempCalculation + costforMultiYear;
                                    }
                                });
                            }
                        })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });
                }
            else {
                    const params = {
                        Year: new Date(this.state.yearslotsWithinStartEndProjectDates[a].endDate).getFullYear(),
                        CostCardId: costCardId
                    };
                    // eslint-disable-next-line no-loop-func
                    await getMultiYearInfoByYearandCostCardId(params).then(res => {
                        if (res.data.length > 0 && res.data[0].id > 0) {
                            let percentage = res.data[0].MultiyearIncrementGrids[0].RatePercentage
                                ? res.data[0].MultiyearIncrementGrids[0].RatePercentage
                                : 0;
                            let calculatedCost =
                                this.state.yearslotsWithinStartEndProjectDates[a - 1].CalculatedCost *
                                (1 + percentage / 100);
                            this.state.yearslotsWithinStartEndProjectDates[a].CalculatedCost = calculatedCost;
                            let tempCalculation =
                                this.state.yearslotsWithinStartEndProjectDates[a].workingDaysCount *
                                (this.state.yearslotsWithinStartEndProjectDates[a - 1].CalculatedCost *
                                    (1 + percentage / 100));
                            costforMultiYear = tempCalculation + costforMultiYear;
                        } else {
                            this.state.yearslotsWithinStartEndProjectDates[
                                a
                                ].CalculatedCost = this.state.yearslotsWithinStartEndProjectDates[
                            a - 1
                                ].CalculatedCost;
                            let tempCalculation =
                                this.state.yearslotsWithinStartEndProjectDates[a].workingDaysCount *
                                this.state.yearslotsWithinStartEndProjectDates[a - 1].CalculatedCost;
                            costforMultiYear = tempCalculation + costforMultiYear;
                        }
                    })
                        .catch(error => {
                            loggerService.writeLog(error, LOG_TYPES.ERROR);
                        });
                }
            }
        }

        dataItem.TotalCost = costforMultiYear;
    };

    handleOutsourcedCostInputChange = event => {
        const value = event.target.value;
        this.setState({
            outsourcedCost: value
        });
    };

    confirmOutsourcedCost = async () => {
        if (this.state.OutsourcedCostDataItemIndex > -1) {
            const allocations = this.state.resourceAllocations.slice();
            // set the value to a temp field and access that field in calculations
            allocations[
                this.state.OutsourcedCostDataItemIndex
                ].CostPerOutSourcedResource = this.state.outsourcedCost;
            await this.calculateRowValues(allocations[this.state.OutsourcedCostDataItemIndex]);

            this.setState(
                {
                    resourceAllocations: allocations,
                    outsourcedCost: 0,
                    OutsourcedCostDataItemIndex: -1
                },
                () => {
                    const totalValues = this.getTotalValues();
                    this.setState(
                        {
                            TotalFTEResourceAllocation: totalValues.TotalFTEResourceAllocation,
                            TotalRevenueResourceAllocation: totalValues.TotalRevenueResourceAllocation,
                            TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation,
                            TotalEffortDaysResourceAllocationNonBill: totalValues.TotalEffortDaysResourceAllocationNonBill,
                            TotalCostToRevenueRatioResourceAllocation: totalValues.TotalCostToRevenueRatioResourceAllocation,
                            TotalEffortDaysResourceAllocationFixed: totalValues.TotalEffortDaysResourceAllocationFixed,
                            TotalEffortDaysResourceAllocationTnM: totalValues.TotalEffortDaysResourceAllocationTnM
                        },
                        () => {
                            this.updateCMSheet();
                        }
                    );
                    this.toggleOutsourcedCostDialog();
                }
            );
        }
    };

    getTotalValues =  () => {
        let totalFTEResourceAllocation = 0;
        let totalRevenueResourceAllocation = 0;
        let totalEffortDaysResourceAllocation = 0;
        let totalEffortDaysResourceAllocationNonBill = 0;
        let totalCostToRevenueRatioResourceAllocation = 0;

        // Fixed and T&M Effort days
        let totalEffortDaysResourceAllocationFixed = 0
        let totalEffortDaysResourceAllocationTnM = 0

        for (const allocation of this.state.resourceAllocations) {

            if(allocation.dataValueBillableStatus && allocation.dataValueBillableStatus.id === 1){

                totalFTEResourceAllocation = (totalFTEResourceAllocation + allocation.FTE);
                totalEffortDaysResourceAllocation = totalEffortDaysResourceAllocation + allocation.TotalCost;

            }

            else if(allocation.dataValueBillableStatus && allocation.dataValueBillableStatus.id === 2){
                totalEffortDaysResourceAllocationNonBill = totalEffortDaysResourceAllocationNonBill + allocation.TotalCost;

            }

            totalRevenueResourceAllocation = totalRevenueResourceAllocation + allocation.TotalRevenue;


            // Fixed and T&M Effort days
            if(allocation.PaymentMethod == 1){ // Fixed
                totalEffortDaysResourceAllocationFixed = totalEffortDaysResourceAllocationFixed + allocation.TotalEffortDays;
            } else if(allocation.PaymentMethod == 2){ // T&M
                totalEffortDaysResourceAllocationTnM = totalEffortDaysResourceAllocationTnM + allocation.TotalEffortDays;
            }

            totalCostToRevenueRatioResourceAllocation = totalFTEResourceAllocation / totalRevenueResourceAllocation;
        }

        return {
            TotalFTEResourceAllocation: totalFTEResourceAllocation,
            TotalRevenueResourceAllocation: totalRevenueResourceAllocation,
            TotalEffortDaysResourceAllocation: totalEffortDaysResourceAllocation,
            TotalEffortDaysResourceAllocationNonBill: totalEffortDaysResourceAllocationNonBill,
            TotalCostToRevenueRatioResourceAllocation: totalCostToRevenueRatioResourceAllocation,
            TotalEffortDaysResourceAllocationFixed: totalEffortDaysResourceAllocationFixed,
            TotalEffortDaysResourceAllocationTnM: totalEffortDaysResourceAllocationTnM
        };
    };

    updateCMSheet = async () => {
        const cmSheet = JSON.parse(JSON.stringify(this.props.cmSheet));
        cmSheet.TotalFTEResourceAllocation = this.state.TotalFTEResourceAllocation;
        cmSheet.TotalRevenueResourceAllocation = this.state.TotalRevenueResourceAllocation;
        cmSheet.TotalEffortDaysResourceAllocation = this.state.TotalEffortDaysResourceAllocation;
        cmSheet.TotalEffortDaysResourceAllocationNonBill = this.state.TotalEffortDaysResourceAllocationNonBill;
        cmSheet.TotalCostToRevenueRatioResourceAllocation = this.state.TotalCostToRevenueRatioResourceAllocation;

        cmSheet.ResourceAllocations = await this.state.resourceAllocations;
            await this.props.onUpdateCMSheet(cmSheet,false);
    };

    getOtherExpensesTotalValues = otherExpenses => {
        let nonBillTotal = 0;
        let billTotal = 0;

        for (const expense of otherExpenses) {
            if (expense.BillableToClient === true && expense.Cost) {
                billTotal = billTotal + expense.Cost;
            }

            if (expense.BillableToClient === false && expense.Cost) {
                nonBillTotal = nonBillTotal + expense.Cost;
            }
        }

        return { nonBillTotal: nonBillTotal, billTotal: billTotal };
    };

    filterChangeCombo = (event, dataItem) => {
        clearTimeout(this.timeout);

        const field = event.target.name;
        const filter = event.filter;

        this.timeout = setTimeout(() => {
            switch (field) {
                case 'CostDesignation': {
                        dataItem.CostDesignations = this.filterComboData(filter, this.state.DesignationsAll);

                    break;
                }
                case 'ResourceRegion': {
                    dataItem.ResourceRegions = this.filterComboData(filter, this.state.RegionsAll);
                    break;
                }
                case 'BillableStatus': {
                    dataItem.ResourceRegions = this.filterComboData(filter, this.billableStatusValues);
                    break;
                }
                default: {
                    break;
                }
            }

            this.setState({
                resourceAllocations: [...this.state.resourceAllocations]
            });
        }, 500);
    };

    filterComboData(filter, allData) {
        const data = allData.slice();
        return filterBy(data, filter);
    }

    toggleMessageDialog = (message, title) => {
        this.setState({
            showMessageDialog: !this.state.showMessageDialog,
            dialogMessage: message,
            dialogTitle: title
        });
    };

    toggleConfirmDialog = (message, action) => {
        this.setState({
            showConfirmDialog: !this.state.showConfirmDialog,
            confirmDialogMessage: message,
            confirmDialogAction: action,
            deleteResourceChecked: !this.state.deleteResourceChecked
        });
    };

    toggleDateReplaceConfirmDialog = async () => {
        await this.setState({
                infoTabStartDateChanged: !(this.state.infoTabStartDateChanged)
            }, () => {
                this.props.onStartDateChange({ infoTabStartDateChanged: this.state.infoTabStartDateChanged });
            }
        );
    }


    replaceResourceMixDates = async () => {
        this.setState({
            isLoading: true,
        });

        if (this.props.cmSheet.Type === 'Project') {
            const startDate = this.props.project.StartDate;
            let EndDate = null;

            if(this.props.isBlanketWorkOrder){

                EndDate = this.props.project.StartDate;

            }
            else{

                EndDate = this.props.endDate;
            }

            const resources = this.state.resourceAllocations;

            const resourceWithoutPhaseOrSubPhase = resources.filter(x => x.PhaseId === null);

            const resourcesArray = [];
            for (const resource of resourceWithoutPhaseOrSubPhase) {
                const resourceObj = JSON.parse(JSON.stringify(resource));
                resourceObj.StartDate = new Date(startDate);
                resourceObj.EndDate = new Date(EndDate);
                resourceObj.StartDateMin = new Date(resourceObj.StartDateMin);
                resourceObj.StartDateMax = new Date(resourceObj.StartDateMax);
                resourceObj.DateStartDate = new Date(startDate);

                await this.updateAllocation(resourceObj, this.props.project.CountryId);

                resourcesArray.push(resourceObj);
            }

            const resourcesWithPhaseOrSubphase = resources.filter(x => x.PhaseId);
            if (resourcesWithPhaseOrSubphase && resourcesWithPhaseOrSubphase.length>0) {
                for (const resource of resourcesWithPhaseOrSubphase) {
                    const resourceObj = JSON.parse(JSON.stringify(resource));
                    if(!resourceObj.dataValuePhase.StartDate && !resourceObj.dataValueSubPhase.StartDate){
                        resourceObj.StartDate = new Date(startDate);
                        resourceObj.EndDate = new Date(EndDate);
                        resourceObj.StartDateMin = new Date(resourceObj.StartDateMin);
                        resourceObj.StartDateMax = new Date(resourceObj.StartDateMax);
                        resourceObj.DateStartDate = new Date(startDate);

                        await this.updateAllocation(resourceObj, this.props.project.CountryId);

                        resourcesArray.push(resourceObj);
                    }
                    else {
                        resourceObj.StartDate = resourceObj.StartDate ? new Date(resourceObj.StartDate) : null;
                        resourceObj.EndDate = resourceObj.EndDate ? new Date(resourceObj.EndDate) : null;
                        resourceObj.StartDateMin = resourceObj.StartDateMin ? new Date(resourceObj.StartDateMin) : null;
                        resourceObj.StartDateMax = resourceObj.StartDateMax ? new Date(resourceObj.StartDateMax) : null;
                        resourceObj.DateStartDate = resourceObj.DateStartDate ? new Date(resourceObj.DateStartDate) : null;

                        resourcesArray.push(resourceObj);
                    }
                }
            }

            const cmSheet = JSON.parse(JSON.stringify(this.props.cmSheet));
            cmSheet.ResourceAllocations = resourcesArray;

            await this.props.onUpdateCMSheet(cmSheet, false);

            this.setState({
                resourceAllocations: resourcesArray,
                isLoading: false,
                infoTabStartDateChanged: !this.state.infoTabStartDateChanged
            }, ()=> {
                this.props.onStartDateChange({ infoTabStartDateChanged: this.state.infoTabStartDateChanged });
            });

        } else {

            const startDate = this.props.changeRequest.StartDate;
            let EndDate = null;

            if(this.props.isBlanketWorkOrder){
                EndDate = this.props.changeRequest.StartDate;
            }
            else{
                EndDate = this.props.endDate;
            }
            const resources = this.state.resourceAllocations;

            const resourceWithoutPhaseOrSubPhase = resources.filter(x => x.PhaseId === null);

            const resourcesArray = [];

            for (const resource of resourceWithoutPhaseOrSubPhase) {
                const resourceObj = JSON.parse(JSON.stringify(resource));
                resourceObj.StartDate = new Date(startDate);
                resourceObj.EndDate = new Date(EndDate);
                resourceObj.StartDateMin = new Date(resourceObj.StartDateMin);
                resourceObj.StartDateMax = new Date(resourceObj.StartDateMax);
                resourceObj.DateStartDate = new Date(startDate);

                await this.updateAllocation(resourceObj, this.props.project.CountryId);

                resourcesArray.push(resourceObj);
            }

            const resourcesWithPhaseOrSubphase = resources.filter(x => x.PhaseId);
            if (resourcesWithPhaseOrSubphase && resourcesWithPhaseOrSubphase.length>0) {
                for (const resource of resourcesWithPhaseOrSubphase) {
                    const resourceObj = JSON.parse(JSON.stringify(resource));
                    if(!resourceObj.dataValuePhase.StartDate && !resourceObj.dataValueSubPhase.StartDate){
                        resourceObj.StartDate = new Date(startDate);
                        resourceObj.EndDate = new Date(EndDate);
                        resourceObj.StartDateMin = new Date(resourceObj.StartDateMin);
                        resourceObj.StartDateMax = new Date(resourceObj.StartDateMax);
                        resourceObj.DateStartDate = new Date(startDate);

                        await this.updateAllocation(resourceObj, this.props.project.CountryId);

                        resourcesArray.push(resourceObj);
                    }
                    else {
                        resourceObj.StartDate = resourceObj.StartDate ? new Date(resourceObj.StartDate) : null;
                        resourceObj.EndDate = resourceObj.EndDate ? new Date(resourceObj.EndDate) : null;
                        resourceObj.StartDateMin = resourceObj.StartDateMin ? new Date(resourceObj.StartDateMin) : null;
                        resourceObj.StartDateMax = resourceObj.StartDateMax ? new Date(resourceObj.StartDateMax) : null;
                        resourceObj.DateStartDate = resourceObj.DateStartDate ? new Date(resourceObj.DateStartDate) : null;

                        resourcesArray.push(resourceObj);
                    }
                }
            }

            const cmSheet = JSON.parse(JSON.stringify(this.props.cmSheet));
            cmSheet.ResourceAllocations = resourcesArray;

            await this.props.onUpdateCMSheet(cmSheet, false);

            this.setState({
                resourceAllocations: resourcesArray,
                isLoading: false,
                infoTabStartDateChanged: !this.state.infoTabStartDateChanged
            }, ()=> {
                this.props.onStartDateChange({ infoTabStartDateChanged: this.state.infoTabStartDateChanged });
            });

        }

    }

    toggleOutsourcedCostDialog = () => {
        this.setState({
            showOutsourcedCostDialog: !this.state.showOutsourcedCostDialog
        });
    };

    toggleTimeline() {
        this.setState({
            timelineVisible: !this.state.timelineVisible
        });
    }

    toggleEndDateTooltip = () => {
        this.setState({
            endDateToolTipOpen: !this.state.endDateToolTipOpen
        });
    };

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        if (event.syntheticEvent.target.checked === true) {
            this.state.resourceAllocations.forEach(item => item.Selected = checked);
            this.forceUpdate();

            this.setState({
                duplicateResourceChecked: true,
                deleteResourceChecked: true
            });

            this.setState({
                resourcesToDuplicate: this.state.resourceAllocations
            });
        } else {
            if (this.state.resourceAllocations && this.state.resourceAllocations.length !== 0) {
                this.toggleDuplicateResourceButton();
                this.state.resourceAllocations.forEach(item => item.Selected = checked);
            }
        }
    };

    selectionChange = (event) => {
        event.dataItem.Selected = !event.dataItem.Selected;
        this.forceUpdate();

        if (event.dataItem.Selected === true) {
            this.setState({
                duplicateResourceChecked: true,
                deleteResourceChecked: true
            });
        } else {
            let duplicateResourceBtnChecked = false;
            let deleteResourceBtnChecked = false;
            this.state.resourceAllocations.forEach(function(resource) {
                if (resource.Selected === true) {
                    duplicateResourceBtnChecked = true;
                    deleteResourceBtnChecked = true;
                }
            });
            this.setState({
                duplicateResourceChecked: duplicateResourceBtnChecked,
                deleteResourceChecked: deleteResourceBtnChecked
            });
        }
    };

    toggleDuplicateResourceButton = () => {
        this.setState({
            duplicateResourceChecked: !this.state.duplicateResourceChecked
        });
    };

    duplicateResource = async () => {
        let tempArray = [];
        const allocations = this.state.resourceAllocations.slice();

        let resourceToDuplicate = allocations.filter(resource => resource.Selected === true);
        let newlyAddedResourceListLength = resourceToDuplicate.length;

        // Changing index of NEWLY ADDED RESOURCES
        let indexZero = 0;
        resourceToDuplicate.forEach(function(newlyAddedResource) {
            let duplicatingResourceObj = JSON.parse(JSON.stringify(newlyAddedResource));
            duplicatingResourceObj.index = indexZero;
            duplicatingResourceObj.StartDateMin = new Date(newlyAddedResource.StartDateMin);
            duplicatingResourceObj.StartDate = newlyAddedResource.StartDate ? new Date(newlyAddedResource.StartDate) : null;
            duplicatingResourceObj.DateStartDate = newlyAddedResource.DateStartDate ? new Date(newlyAddedResource.DateStartDate) : null;
            duplicatingResourceObj.EndDate = newlyAddedResource.EndDate ? new Date(newlyAddedResource.EndDate) : null;
            duplicatingResourceObj.StartDateMax = newlyAddedResource.StartDateMax ? new Date(newlyAddedResource.StartDateMax) : null;
            delete duplicatingResourceObj.id;
            indexZero++;
            tempArray.push(duplicatingResourceObj);
        });

        // Changing index of old resources
        allocations.forEach(function(allocation) {
            let existingResourceObj = JSON.parse(JSON.stringify(allocation));
            existingResourceObj.index = allocation.index + newlyAddedResourceListLength;
            existingResourceObj.StartDateMin = new Date(allocation.StartDateMin);
            existingResourceObj.StartDate = allocation.StartDate ? new Date(allocation.StartDate) : null;
            existingResourceObj.DateStartDate = allocation.DateStartDate ? new Date(allocation.DateStartDate) : null;
            existingResourceObj.EndDate = allocation.EndDate ? new Date(allocation.EndDate) : null;
            existingResourceObj.StartDateMax = allocation.StartDateMax ? new Date(allocation.StartDateMax) : null;
            tempArray.push(existingResourceObj);
        });

        if (tempArray && tempArray.length > 0) {
            const Selected = false;
            tempArray.forEach(function(allocation) {
                allocation.Selected = false;
            });
        }

        await this.setState({
            resourceAllocations: tempArray
        }, () => {
            const totalValues = this.getTotalValues();
            this.setState(
                {
                    TotalCostResourceAllocation: totalValues.TotalCostResourceAllocation,
                    TotalRevenueResourceAllocation: totalValues.TotalRevenueResourceAllocation,
                    TotalEffortDaysResourceAllocation: totalValues.TotalEffortDaysResourceAllocation,
                    TotalEffortDaysResourceAllocationNonBill: totalValues.TotalEffortDaysResourceAllocationNonBill,
                    TotalFTEResourceAllocation: totalValues.TotalFTEResourceAllocation,
                    TotalCostToRevenueRatioResourceAllocation: totalValues.TotalCostToRevenueRatioResourceAllocation,
                    TotalEffortDaysResourceAllocationFixed: totalValues.TotalEffortDaysResourceAllocationFixed,
                    TotalEffortDaysResourceAllocationTnM: totalValues.TotalEffortDaysResourceAllocationTnM
                },
                () => {
                    this.updateCMSheet();
                }
            );

        });

    };


    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <Loader loading={this.state.isLoading}/>
                        <Button primary={true} look="outline"
                                disabled={this.state.duplicateResourceChecked === false || this.props.isViewMode}
                                onClick={this.duplicateResource} type="button" style={{ margin: '10px' }}>
                            Duplicate Resource
                        </Button>

                        <Button primary={true} look="outline"
                                disabled={this.state.deleteResourceChecked === false || this.props.isViewMode}
                                onClick={this.handleDeleteButtonClick} type="button" style={{ margin: '10px' }}>
                            Delete
                        </Button>

                        <div className="resource-mix-dis">
                            <Grid style={{ height: '450px' }}
                                  data={this.state.resourceAllocations.slice(
                                      this.state.skip,
                                      this.state.take + this.state.skip
                                  )}
                                  skip={this.state.skip}
                                  take={this.state.take}
                                  total={this.state.resourceAllocations.length}
                                  pageable={true}
                                  onPageChange={this.pageChange}
                                  onItemChange={this.itemChange}
                                  selectedField="Selected"
                                  onHeaderSelectionChange={this.headerSelectionChange}
                                  onSelectionChange={this.selectionChange}
                                  editField="inEdit"
                                  detail={FactoryRowDetails}
                                  resizable
                                  className="resource-mix-grid"
                            >
                                {!this.props.isViewMode && !(this.state.resourceAllocations.length === 0) &&
                                <Column
                                    field="Selected"
                                    width="50px"
                                    headerSelectionValue={
                                        this.state.resourceAllocations.findIndex(dataItem => dataItem.Selected === false) === -1
                                    }/>}
                                <Column
                                    field=""
                                    title=""
                                    width="60px"
                                    headerCell={props => (
                                        <HeaderCellAddButton
                                            {...props}
                                            disabled={this.props.isViewMode}
                                            onAddButtonClick={this.handleAddButtonClick}
                                        />
                                    )}
                                    cell={props => (
                                        <td></td>
                                    )}
                                    // cell={props => (
                                    //     <RowCellDeleteButton
                                    //         {...props}
                                    //         disabled={this.props.isViewMode}
                                    //         onDeleteButtonClick={this.handleDeleteButtonClick}
                                    //     />
                                    // )}
                                />

                                <Column
                                    field="Designation"
                                    headerCell={props => <label className="mandatory">Designation (Cost)</label>}
                                    width="200px"
                                    cell={this.ComboBoxCell}
                                    maxLength={40}
                                />
                                <Column
                                    field="ResourceGrp"
                                    headerCell={props => <label className="mandatory">Resource Group</label>}
                                    width="200px"
                                    cell={this.ComboBoxCell}
                                    maxLength={40}
                                />
                                <Column
                                    field="ResourceRegion"
                                    headerCell={props => <label className="mandatory">Resource Region</label>}
                                    width="200px"
                                    maxLength={40}
                                    cell={this.ComboBoxCell}
                                />
                                <Column
                                    field="OnsiteStatus"
                                    headerCell={props => <label className="mandatory">Onsite / Offshore</label>}
                                    width="140px"
                                    maxLength={40}
                                    cell={this.ComboBoxCell}
                                />
                                <Column
                                    field="DateStartDate"
                                    headerCell={props => <label className="mandatory">Start Date</label>}
                                    width="140px"
                                    cell={this.DatePickerCell}
                                />
                                <Column
                                    field="EndDate"
                                    headerCell={props => <label className="mandatory">End Date</label>}
                                    width="140px"
                                    cell={this.DatePickerCell}
                                />
                                <Column
                                    field="AllocationPercentage"
                                    headerCell={props => <label className="mandatory">Allocation (%)</label>}
                                    width="120px"
                                    maxLength={3}
                                    cell={this.NumericTextBoxCell}
                                />
                                <Column
                                    field="BillableStatus"
                                    headerCell={props => <label className="mandatory">Billable / Non-Billable</label>}
                                    width="150px"
                                    cell={this.ComboBoxCell}
                                />
                                <Column
                                    field="NoOfResources"
                                    headerCell={props => <label className="mandatory">No. of Resources</label>}
                                    width="140px"
                                    maxLength={10}
                                    cell={this.NumericTextBoxCell}
                                />
                            </Grid>
                        </div>
                    </div>
                </div>

                {/*<div className="row">*/}
                {/*    <div className="col-md-3">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-5">*/}
                {/*                <label>Total Cost:</label>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-7">*/}
                {/*                <Input*/}
                {/*                    disabled={true}*/}
                {/*                    value={this.state.TotalCostResourceAllocation.toFixed(2).replace(*/}
                {/*                        /\B(?=(\d{3})+(?!\d))/g,*/}
                {/*                        ','*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className="col-md-3">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-5">*/}
                {/*                <label>Total Revenue:</label>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-7">*/}
                {/*                <Input*/}
                {/*                    disabled={true}*/}
                {/*                    value={this.state.TotalRevenueResourceAllocation.toFixed(2).replace(*/}
                {/*                        /\B(?=(\d{3})+(?!\d))/g,*/}
                {/*                        ','*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className="col-md-3">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-5">*/}
                {/*                <label>Contracted Effort Days:</label>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-7">*/}
                {/*                <Input*/}
                {/*                    disabled={true}*/}
                {/*                    value={this.state.TotalEffortDaysResourceAllocation.toFixed(2).replace(*/}
                {/*                        /\B(?=(\d{3})+(?!\d))/g,*/}
                {/*                        ','*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*    <div className="col-md-3">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-5">*/}
                {/*                <label>Total Cost to Revenue Ratio:</label>*/}
                {/*            </div>*/}
                {/*            <div className="col-md-7">*/}
                {/*                <Input*/}
                {/*                    disabled={true}*/}
                {/*                    value={this.state.TotalCostToRevenueRatioResourceAllocation.toFixed(2).replace(*/}
                {/*                        /\B(?=(\d{3})+(?!\d))/g,*/}
                {/*                        ','*/}
                {/*                    )}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}


                {/*</div>*/}

                <div className="row mt-2">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-5">
                                <label>Deployable FTE:</label>
                            </div>
                            <div className="col-md-7">
                                <Input
                                disabled={true}
                                value={this.state.TotalFTEResourceAllocation.toFixed(2).replace(
                                                       /\B(?=(\d{3})+(?!\d))/g,
                                                      ','
                                                   )}
                                               />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-5">
                                <label>Deployable resources:</label>
                            </div>
                            <div className="col-md-7">
                                               <Input
                                                  disabled={true}
                                                   value={this.state.TotalEffortDaysResourceAllocation.toFixed(2).replace(
                                                       /\B(?=(\d{3})+(?!\d))/g,
                                                       ','
                                                 )}
                                              />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-5">
                                <label>Non-deployable resources:</label>
                            </div>
                            <div className="col-md-7">
                                <Input
                                    disabled={true}
                                    value={this.state.TotalEffortDaysResourceAllocationNonBill.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showMessageDialog === true && (
                    <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">

                        {this.state.showSuccessImage === true && (
                            <div className="text-center">
                                <img className="successImg" src={SuccessImg} alt="Success"/>
                            </div>
                        )}

                        {this.state.showErrorImage === true && (
                            <div className="text-center">
                                <img className="successImg" src={ErrorImg} alt="Error Image"/>
                            </div>
                        )}

                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                        <DialogActionsBar>
                            {this.state.showErrorImage === true && (
                                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                                    OK
                                </button>
                            )}
                        </DialogActionsBar>
                    </Dialog>
                )}
                {this.state.showConfirmDialog === true && (
                    <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleConfirmDialog}>
                                No
                            </button>
                            <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
                {this.state.showOutsourcedCostDialog === true && (
                    <Dialog title="Enter Cost" onClose={this.toggleOutsourcedCostDialog} width="400px">
                        <div className="row">
                            <div className="col-md-12">
                                <p style={{ margin: '10px', textAlign: 'center' }}>
                                    Please enter the cost per resource{' '}
                                    {this.props.project.Region &&
                                    this.props.project.Region.Currency &&
                                    this.props.project.Region.Currency.Code
                                        ? ' in ' + this.props.project.Region.Currency.Code
                                        : ''}
                                </p>
                            </div>
                        </div>

                        <div className="col-md-12 mb-4">
                            <div className="row text-center">

                                <div className="col-md-4 text-right">
                                    <label for="">Cost:</label>
                                </div>
                                <div className="col-md-5">
                                    <NumericTextBox onChange={this.handleOutsourcedCostInputChange} min={0}/>
                                </div>
                            </div>
                        </div>

                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleOutsourcedCostDialog}>
                                Cancel
                            </button>
                            <button
                                className="k-button modal-primary"
                                onClick={this.confirmOutsourcedCost}
                                disabled={this.state.outsourcedCost === 0}
                            >
                                Save
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}


                {this.state.infoTabStartDateChanged === true && (!this.props.isBlanketWorkOrder) && (
                    <Dialog title="Confirm" onClose={this.toggleDateReplaceConfirmDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>Project start date and/or end date is changed. Do you wish to replace the start and end dates of the resource lines with the new start date or end date? Yes/No</p>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleDateReplaceConfirmDialog}>
                                No
                            </button>
                            <button className="k-button modal-primary" onClick={this.replaceResourceMixDates}>
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}

                {this.state.infoTabStartDateChanged === true && (this.props.isBlanketWorkOrder) && (
                    <Dialog title="Confirm" onClose={this.toggleDateReplaceConfirmDialog} width="400px">
                        <p style={{ margin: '25px', textAlign: 'center' }}>Project Start Date is changed. Do you wish to replace the start of the Resource lines with the new Start Date ? Yes/ No</p>
                        <DialogActionsBar>
                            <button className="k-button" onClick={this.toggleDateReplaceConfirmDialog}>
                                No
                            </button>
                            <button className="k-button modal-primary" onClick={this.replaceResourceMixDates}>
                                Yes
                            </button>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </div>
        );
    }

    /** ============= Grid Editor Cells ============================ */

    ComboBoxCell = props => {

        if (props.field === 'Designation') {
            return (
                <RowCellComboBox
                    {...props}
                    dataList="CostDesignations"
                    textField="Name"
                    dataItemKey="id"
                    dataValueField="dataValueCostDesignation"
                    onChangeSelection={this.handleChangeComboBox}
                    name="CostDesignation"
                    comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                    canFilter={true}
                    filterChangeCombo={this.filterChangeCombo}
                    disabled={this.props.isViewMode}
                    required={true}
                />
            );
        }

        if (props.field === 'ResourceGrp') {
            return (
                <RowCellComboBox
                    {...props}
                    dataList="ResourceGroups"
                    textField="name"
                    dataItemKey="value"
                    dataValueField="dataValueResourceGrp"
                    onChangeSelection={this.handleChangeComboBox}
                    name="ResourceGrp"
                    comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                    disabled={this.props.isViewMode}
                    required={true}
                />
            );
        }

        if (props.field === 'ResourceRegion') {
            return (
                <RowCellComboBox
                    {...props}
                    dataList="ResourceRegions"
                    textField="Name"
                    dataItemKey="id"
                    dataValueField="dataValueResourceRegion"
                    onChangeSelection={this.handleChangeComboBox}
                    name="ResourceRegion"
                    comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                    canFilter={true}
                    filterChangeCombo={this.filterChangeCombo}
                    disabled={this.props.isViewMode}
                    required={true}
                />
            );
        }

        if (props.field === 'OnsiteStatus') {
            return (
                <RowCellComboBox
                    {...props}
                    dataList="OnsiteStatuses"
                    textField="Name"
                    dataItemKey="id"
                    dataValueField="dataValueOnsiteStatus"
                    onChangeSelection={this.handleChangeComboBox}
                    name="OnsiteStatus"
                    comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                    disabled={this.props.isViewMode}
                    required={true}
                />
            );
        }


        if (props.field === 'BillableStatus') {
            return (
                <RowCellComboBox
                    {...props}
                    dataList="BillableStatuses"
                    textField="Text"
                    dataItemKey="id"
                    dataValueField="dataValueBillableStatus"
                    onChangeSelection={this.handleChangeComboBox}
                    name="BillableStatus"
                    comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                    disabled={this.props.isViewMode}
                    required={true}
                    canFilter={true}
                    filterChangeCombo={this.filterChangeCombo}
                />
            );
        }

        if (props.field === 'RateType') {
            return (
                <RowCellComboBox
                    {...props}
                    dataList="RateTypes"
                    textField="Text"
                    dataItemKey="id"
                    dataValueField="dataValueRateType"
                    onChangeSelection={this.handleChangeComboBox}
                    name="RateType"
                    disabled={this.props.isViewMode}
                    comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                    required={true}
                />
            );
        }
    };

    DropDownCell = props => {
        if (props.field === 'PaymentMethod') {
            return (
                <RowCellDropDown
                    {...props}
                    dataList={this.PaymentMethodValues}
                    textField="Text"
                    dataItemKey="id"
                    dataValueField="dataValuePaymentMethod"
                    onChangeSelection={this.handleChangeDropDown}
                    name="PaymentMethod"
                    disabled={this.props.isViewMode}
                />
            );
        }
    };

    NumericTextBoxCell = props => {
        if (props.field === 'AllocationPercentage') {
            return (
                <RowCellNumericTextBox
                    {...props}
                    disabled={this.props.isViewMode}
                    minValue={1}
                    onChange={this.itemChange}
                    toFixed={2}
                    required={true}
                    isError={this.state.isErrorAllocationPercentage}
                    isError={this.state.isErrorAllocationPercentage}
                    isRed={this.state.isRedBadge}
                />

            );
        }
        if (props.field === 'RateValue') {
            return (
                <RowCellNumericTextBox
                    {...props}
                    disabled={props.dataItem.RateValueDisabled || this.props.isViewMode}
                    minValue={0}
                    onChange={this.itemChange}
                    toFixed={2}
                    required={true}
                    styleClass={props.dataItem.rateNoLongerValid === true ? 'border-red' : ''}
                    isError={this.state.isErrorRateValue}
                />
            );
        }
        if (props.field === 'NoOfResources') {
            return (
                <RowCellNumericTextBox
                    {...props}
                    disabled={this.props.isViewMode}
                    minValue={1}
                    onChange={this.itemChange}
                    toFixed={0}
                    required={true}
                    isError={this.state.isErrorNoOfResources}
                />
            );
        }
        if (props.field === 'EffortDays') {
            return (
                <RowCellNumericTextBox
                    {...props}
                    disabled={this.props.isViewMode || this.props.isResourceAug || this.props.isBlanketWorkOrder}
                    minValue={0}
                    onChange={this.itemChange}
                    toFixed={2}
                    required={true}
                    isError={this.state.isErrorEffortDays}
                />
            );
        }
        if (props.field === 'EffortHours') {
            return (
                <RowCellNumericTextBox
                    {...props}
                    disabled={this.props.isViewMode || this.props.isBlanketWorkOrder}
                    minValue={0}
                    onChange={this.itemChange}
                    toFixed={0}
                    required={true}
                    isError={this.state.isErrorEffortHours}
                />
            );
        }
    };

    DatePickerCell = props => {
        if (props.field === 'DateStartDate') {
            return (
                <RowCellDatePicker
                    {...props}
                    disabled={props.dataItem.DatesDisabled || this.props.isViewMode}
                    minDateField="StartDateMin"
                    maxDateField="StartDateMax"
                    onChange={this.itemChange}
                    StartDateIsAPastDate={this.state.StartDateIsAPastDate}
                />
            );
        }

        if (props.field === 'EndDate') {
            return (
                <RowCellDatePicker
                    {...props}
                    disabled={props.dataItem.DatesDisabled || this.props.isViewMode}
                    minDateField="StartDate"
                    maxDateField="StartDateMax"
                    onChange={this.itemChange}
                />
            );
        }
    };
}

export default ResourceAllocPool;
