import React, { Component } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import {
    getHolidaysIntegrated,
    getRegionCurrencyIntegrated
} from '../../../integration/MasterDataService';
import moment from 'moment';
import { getCurrencies } from '../../../masterData/common/CommonService';
import { getPartnerCommission } from '../../../masterData/commission/CommissionService';
import {
    getCMSFilesByCMSheetIdandVersion,
    retrieveConversionRate,
    getCMSheet,
    calculateDuration
} from '../../cmSheet/CMSheetService';
import { Button } from '@progress/kendo-react-buttons';
import { getConsolidatedCMSData, getConsolidatedResourceMixData, getProjectAndCrStatistics } from '../../consolidatedCMSheet/ConsolidatedCMSheetService';
import { retrieveConversionRateIntegrated } from '../../../integration/ProjectManagementService';
import {
    getResourceRequestResourceMixData
} from '../../../resourceManagement/ResourceService';
import {
    formatNumber,
    getFormattedDate,
    isWeekend
} from '../../../integration/CommonUtils';

import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import RowDetails from '../../cmSheet/component/resourceAllocations/RowDetails';
import { Input } from '@progress/kendo-react-inputs';
import * as loggerService from "../../../integration/LoggerService";
import {LOG_TYPES} from "../../../integration/IntegrationEnums";
import RowCellComboBox from "../../cmSheet/component/common/RowCellComboBox";
import RowCellDropDown from "../../cmSheet/component/common/RowCellDropDown";
import Loader from '../../../integration/components/Loader';

const apiUrl = require('../../../config').get(process.env.REACT_APP_ENV).apiUrl;

class ConsolidatedCMSheetChart extends Component {
    comboBoxPlaceHolder = 'N/A';

    isMount = false;

    billableStatusValuesT = [
        { id: 1, Text: 'Billable', value: true },
        { id: 2, Text: 'Non-Billable', value: false }
    ];

    constructor(props) {
        super(props);

        this.state = {
            calculations: [],
            resourceMix: [],
            resourceMixOngoing: [],
            PhasesAll: [],
            otherExpenses: [],
            currencies: [],
            DesignationsAll: [],
            RegionsAll: [],
            RateTypes: [],
            dataValueCurrency: null,

            showMessageDialog: false,
            dialogMessage: '',
            dialogTitle: '',
            selected: 0,
            consolidatedCMSList: [],
            consolidatedResourceMixList: [],

            TotalCostResourceAllocation: 0,
            TotalRevenueResourceAllocation: 0,
            TotalEffortDaysResourceAllocation: 0,
            TotalCostToRevenueRatioResourceAllocation: 0,

            BillableTotalOtherExpenses: 0,
            NonBillableTotalOtherExpenses: 0,
            OtherExpensesTotal: 0,
            files: [],

            calculatedValues: null,
            projectsAndResources: null,
            calculatedActValues: null,
            loading: false,

            skip: 0,
            take: 20
        };
    }

    rowRender(trElement, props) {
        const isHeader = props.dataItem.isHeader;
        const className = 'summery-header-style';
        const trProps = { class: isHeader === true ? className : null };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    componentDidMount() {
        this.isMount = true;
        this.populateCurrencies();
    }

    componentWillUnmount() {
        this.isMount = false;
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.IdList || nextProps.IdList.length === 0) {
            if (this.isMount) {
                this.setState({
                    calculations: [],
                    resourceMix: [],
                    dataValueCurrency: null,
                    consolidatedCMSList: [],
                    otherExpenses: [],
                    files: [],
                    TotalCostResourceAllocation : 0.00,
                    TotalRevenueResourceAllocation : 0.00,
                    TotalEffortDaysResourceAllocation : 0.00,
                    TotalCostToRevenueRatioResourceAllocation : 0.00,
                    NonBillableTotalOtherExpenses : 0.00,
                    BillableTotalOtherExpenses : 0.00,
                    OtherExpensesTotal: 0.00
                });
            }
        }
        this.setProjectCurrency();
    }

    populateConsolidatedCMS = async (IdList) => {
        if (IdList && IdList.length > 0) {
            const List = [
                IdList
            ];
            await getConsolidatedCMSData(List).then(res => {
                if (this.isMount) {
                    this.setState({
                        consolidatedCMSList: res.data
                    });
                }
            })

              .catch(error => {
                  loggerService.writeLog(error, LOG_TYPES.ERROR);
              });
        }
    };

    populateFilesOfCMSheets = async (IdList, dataT) => {
        await this.getCMsFiles(IdList, dataT).then(
          response => {
              if (this.isMount) {
                  this.setState({
                      files: response
                  });
              }
          }
        )
          .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    };

    getCMsFiles = async (IdList, dataT) => {

        let allFiles = [];

        if (IdList && IdList.length > 0) {
            for (let i = 0; i < IdList.length; i++) {

                let Files = [];

                let cmSheets;
                cmSheets = getCMSFilesByCMSheetIdandVersion(IdList[i].id, IdList[i].Version)
                  .then(res => {
                      if (res && res.data.length > 0) {
                          res.data.forEach(function (file) {
                              const fileObj = {
                                  fileName: file.FileName,
                                  fileId: file.fileId,
                                  uid: file.uid,
                                  referenceId: file.CMSheetId, // CMSheet ID
                                  referenceVersion: file.CMSheetVersion, // CMsheet Version
                                  projectOrCrName: file.CMSheets && file.CMSheets.Type === 'ChangeRequest' ? file.CMSheets.ChangeRequest.CRName : file.CMSheets.Project.ProjectName,
                                  projectorCrId: file.CMSheets && file.CMSheets.Type === 'ChangeRequest' ? file.CMSheets.ChangeRequest.id : file.CMSheets.Project.id,
                                  cmsType: file.CMSheets && file.CMSheets.Type === 'ChangeRequest' ? 'ChangeRequest' : 'Project'
                              };
                              Files.push(fileObj);
                          });
                          const x = {
                              value: Files
                          };
                          allFiles.push(x);
                      }
                      this.setState({ files: allFiles });
                  })
                  .catch(error => {
                      loggerService.writeLog(error, LOG_TYPES.ERROR);
                  });
            }
            return allFiles;
        }
    };



    populateConsolidatedResourceMix = async (IdList, dataT) => {
        const resources = [];
        const otherExpenses = [];
        if (IdList && IdList.length > 0) {
            await getConsolidatedResourceMixData(IdList).then(response => {

                this.setState({
                    projectsAndResources: response.data
                });

                //resource mix
                response.data.forEach(function (data) {

                    const cmSheet = JSON.parse(JSON.stringify(data));
                    const phases = cmSheet.Phases;

                    cmSheet.ResourceAllocations.forEach(function (resource, index) {
                        resource.Phases = phases;
                        let resPhase = phases.filter(e => e.id === resource.PhaseId);
                        const dataValuePhase = resPhase[0];
                        cmSheet.ResourceAllocations[index].dataValuePhase = dataValuePhase;

                        if (resPhase.length > 0) {
                            if (resPhase[0].SubPhases) {
                                let resSubPhase = resPhase[0].SubPhases.filter(e => e.id === resource.SubPhaseId);
                                const dataValueSubPhase = resSubPhase[0];
                                cmSheet.ResourceAllocations[index].dataValueSubPhase = dataValueSubPhase;
                            }
                        }

                        // Role
                        const projRoles = dataT.DesingationsAll.slice();
                        resource.ProjectRoles = projRoles.filter(obj => obj.IsProjectRole === true);
                        if (resource.ProjectRole) {
                            const filterRole = resource.ProjectRoles.filter(obj => obj.id === resource.ProjectRole);
                            if (filterRole.length > 0) {
                                cmSheet.ResourceAllocations[index].dataValueProjectRole = filterRole[0];
                            }
                        }

                        // Designation
                        if (resource.dataValueProjectRole) {
                            const allDesignations = dataT.DesingationsAll.slice();
                            resource.CostDesignations = allDesignations.filter(
                              obj => obj.CategoryId === resource.dataValueProjectRole.CategoryId || obj.Name === 'Outsourced'
                            );
                            if (resource.Designation) {
                                const filterCostDesignation = resource.CostDesignations.filter(
                                  obj => obj.id === resource.Designation
                                );
                                if (filterCostDesignation.length > 0) {
                                    cmSheet.ResourceAllocations[index].dataValueCostDesignation = filterCostDesignation[0];
                                }
                            }
                        }

                        // Resource Region
                        resource.ResourceRegions = dataT.RegionsAll.slice();
                        if (resource.ResourceRegion) {
                            const filterResourceRegion = resource.ResourceRegions.filter(
                              obj => obj.id === resource.ResourceRegion
                            );
                            if (filterResourceRegion.length > 0) {
                                cmSheet.ResourceAllocations[index].dataValueResourceRegion = filterResourceRegion[0];
                            }
                        }

                        // Payment Method
                        if (resource.PaymentMethod === 1) {
                            resource.dataValuePaymentMethod = { id: 1, Text: 'Fixed' };
                        } else if (resource.PaymentMethod === 2) {
                            resource.dataValuePaymentMethod = { id: 2, Text: 'T&M' };
                        } else {
                            resource.dataValuePaymentMethod = null;
                        }

                        // Onsite / Offshore
                        if (resource.OnsiteStatus === 'Onsite') {
                            resource.dataValueOnsiteStatus = { id: 1, Name: 'Onsite' };
                        } else if (resource.OnsiteStatus === 'Offshore') {
                            resource.dataValueOnsiteStatus = { id: 2, Name: 'Offshore' };
                        } else {
                            resource.dataValueOnsiteStatus = null;
                        }

                        // Rate Type
                        resource.RateTypes = dataT.RateTypes;
                        if (resource.RateType) {
                            const filterRateType = resource.RateTypes.filter(obj => obj.id === resource.RateType);
                            if (filterRateType.length > 0) {
                                resource.dataValueRateType = filterRateType[0];
                            }
                        }

                        if (resource.dataValueRateType) {
                            const dataValue = JSON.parse(JSON.stringify(resource.dataValueRateType));
                            dataValue.Text = dataValue.Name + ' - ' + resource.RateValue;
                            resource.dataValueRateType = dataValue;
                        }

                        resources.push(resource);
                    });


                    //other expenses
                    if (cmSheet && cmSheet.OtherExpenses && cmSheet.OtherExpenses.length > 0) {
                        cmSheet.OtherExpenses.forEach(function (otherExpense, index) {

                            otherExpense.Phases = phases;
                            let resPhase = phases.filter(e => e.id === otherExpense.PhaseId);
                            const dataValuePhase = resPhase[0];
                            cmSheet.OtherExpenses[index].dataValuePhase = dataValuePhase;

                            if (resPhase.length > 0) {
                                if (resPhase[0].SubPhases) {
                                    let resSubPhase = resPhase[0].SubPhases.filter(e => e.id === otherExpense.SubPhaseId);
                                    const dataValueSubPhase = resSubPhase[0];
                                    cmSheet.OtherExpenses[index].dataValueSubPhase = dataValueSubPhase;
                                }
                            }

                            if (otherExpense.BillableToClient === true) {
                                otherExpense.dataValueBillable = { id: 1, Text: 'Billable', value: true };
                            } else {
                                otherExpense.dataValueBillable = { id: 2, Text: 'Non-Billable', value: false };
                            }

                            otherExpense.ExpenseTypes = dataT.ExpenseTypesAll.slice();
                            if (otherExpense.ExpenseTypeId) {
                                const filterType = otherExpense.ExpenseTypes.filter(obj => obj.id === otherExpense.ExpenseTypeId);
                                if (filterType.length > 0) {
                                    otherExpense.dataValueExpenseType = filterType[0];
                                }
                            }

                            otherExpenses.push(otherExpense);
                        });
                    }
                });

                if (this.isMount) {

                    this.setState({
                        resourceMix: resources,
                        otherExpenses: otherExpenses
                    }, () => {
                        this.getResourceAllocationsTotalValues(resources);
                        this.getOtherExpensesTotalValues(otherExpenses);
                    });
                }
            })
              .catch(error => {
                  loggerService.writeLog(error, LOG_TYPES.ERROR);
              });
        }
    };

    toggleMessageDialog = (message, title) => {
        if (this.isMount) {
            this.setState({
                showMessageDialog: !this.state.showMessageDialog,
                dialogMessage: message,
                dialogTitle: title
            });
        }
    };

    populateCurrencies = () => {
        getCurrencies()
          .then(res => {
              if (res.data && res.data.length > 0 && res.data[0].id) {
                  if (this.isMount) {
                      this.setState({
                          currencies: res.data
                      });
                  }
                  this.setProjectCurrency();
              }
          })
          .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
    };

    setProjectCurrency = () => {
        if (this.props.Currency) {
            let currentCurrency = null;
            if (this.props.Currency) {
                const filterCurrency = this.state.currencies.filter(
                  obj => obj.id === this.props.Currency
                );
                if (filterCurrency.length > 0) {
                    currentCurrency = filterCurrency[0];
                }
            }
            if (this.isMount) {
                this.setState({
                    dataValueCurrency: currentCurrency
                });
            }
        }
    };

    handleCurrencyChange = async event => {
        const value = event.target.value;

        if (this.state.dataValueCurrency) {
            const prevRate = await retrieveConversionRate(
              this.state.dataValueCurrency.id,
              new Date().getMonth() + 1,
              new Date().getFullYear()
            );

            if (prevRate > 0) {
                const newRate = await retrieveConversionRate(
                  value.id,
                  new Date().getMonth() + 1,
                  new Date().getFullYear()
                );

                if (newRate > 0) {

                    if (value) {
                        this.calculateValuesOnCurrencyChange(this.state.consolidatedCMSList[0], prevRate, newRate);
                        if (this.isMount) {
                            this.setState({
                                dataValueCurrency: value
                            });
                        }
                    }

                } else {
                    const message = 'Currency conversion rate not found for selected currency';
                    const title = 'Error';
                    this.toggleMessageDialog(message, title);
                }

            } else {
                const message = 'Currency conversion rate not found for previously selected currency';
                const title = 'Error';
                this.toggleMessageDialog(message, title);
            }
        } else {
            const message = 'Currency cannot be selected until a consolidated CMS is generated';
            const title = 'Error';
            this.toggleMessageDialog(message, title);
        }

    };

    calculateValuesOnCurrencyChange = (cmSheet, prevRate, newRate) => {
        cmSheet.billabletotalotherexpenses = this.calculateNewRate(cmSheet.billabletotalotherexpenses, prevRate, newRate);
        cmSheet.nonbillabletotalotherexpenses = this.calculateNewRate(cmSheet.nonbillabletotalotherexpenses, prevRate, newRate);

        // Other calculation values
        cmSheet.projectrevenueafterdiscount = this.calculateNewRate(cmSheet.projectrevenueafterdiscount, prevRate, newRate);
        cmSheet.netprofitloss = this.calculateNewRate(cmSheet.netprofitloss, prevRate, newRate);
        cmSheet.averagerateperday = this.calculateNewRate(cmSheet.averagerateperday, prevRate, newRate);
        cmSheet.partnercommission = this.calculateNewRate(cmSheet.partnercommission, prevRate, newRate);
        cmSheet.contingencyvalue = this.calculateNewRate(cmSheet.contingencyvalue, prevRate, newRate);
        cmSheet.discountvalue = this.calculateNewRate(cmSheet.discountvalue, prevRate, newRate);
        cmSheet.ratediscount = this.calculateNewRate(cmSheet.ratediscount, prevRate, newRate);
        cmSheet.revenueafterdiscountquotedtocustomer = this.calculateNewRate(cmSheet.revenueafterdiscountquotedtocustomer, prevRate, newRate);
        cmSheet.estimatedopecustomer = this.calculateNewRate(cmSheet.estimatedopecustomer, prevRate, newRate);
        cmSheet.totalprojectvalue = this.calculateNewRate(cmSheet.totalprojectvalue, prevRate, newRate);
        cmSheet.netdiscountvalue = this.calculateNewRate(cmSheet.netdiscountvalue, prevRate, newRate);
        cmSheet.grossprofitfromresourcedeployment = this.calculateNewRate(cmSheet.grossprofitfromresourcedeployment, prevRate, newRate);
        cmSheet.salescommision = this.calculateNewRate(cmSheet.salescommision, prevRate, newRate);
        cmSheet.totalrevenueresourceallocation = this.calculateNewRate(cmSheet.totalrevenueresourceallocation, prevRate, newRate);
        
        // Additional fields
        cmSheet.totalEffortRM = this.calculateNewRate(cmSheet.totalEffortRM, prevRate, newRate);
        cmSheet.totalEffortDaysCMS = this.calculateNewRate(cmSheet.totalEffortDaysCMS, prevRate, newRate);
        cmSheet.discountedRevenueToFortude = this.calculateNewRate(cmSheet.discountedRevenueToFortude, prevRate, newRate);
        cmSheet.discountedRevenueToFortudeCMS = this.calculateNewRate(cmSheet.discountedRevenueToFortudeCMS, prevRate, newRate);
        cmSheet.TotalOngoingGrossProfit = this.calculateNewRate(cmSheet.TotalOngoingGrossProfit, prevRate, newRate);
        cmSheet.TotalGrossProfit = this.calculateNewRate(cmSheet.TotalGrossProfit, prevRate, newRate);
        cmSheet.GrossVeriance = this.calculateNewRate(cmSheet.GrossVeriance, prevRate, newRate);
        cmSheet.netProfit = this.calculateNewRate(cmSheet.netProfit, prevRate, newRate);
        cmSheet.netProfitLossCMS = this.calculateNewRate(cmSheet.netProfitLossCMS, prevRate, newRate);

        this.populateCalculationsGrid(cmSheet);

        this.calculateResourceValuesOnCurrencyChange(prevRate, newRate);
        this.calculateOtherExpensesValuesOnCurrencyChange(prevRate, newRate);
    };

    calculateNewRate = (value, prevRate, newRate) => {
        value = value ? (value / prevRate) * newRate : 0;
        return parseFloat(value).toFixed(2);
    };

    calculateResourceValuesOnCurrencyChange = (prevRate, newRate) => {
        const resourceAllocations = this.state.resourceMix.slice();
        let allocationsList = [];

        if (resourceAllocations.length > 0) {
            for (let allocation of resourceAllocations) {
                allocation.RateValue = Number(this.calculateNewRate(allocation.RateValue, prevRate, newRate));
                allocation.TotalCost = Number(this.calculateNewRate(allocation.TotalCost, prevRate, newRate));
                allocation.TotalRevenue = Number(this.calculateNewRate(allocation.TotalRevenue, prevRate, newRate));
                allocation.CostToRevenueRatio = Number(this.calculateNewRate(allocation.CostToRevenueRatio, prevRate, newRate));
                if (allocation.CostPerOutSourcedResource > 0) {
                    allocation.CostPerOutSourcedResource =
                      (allocation.CostPerOutSourcedResource / prevRate) * newRate;
                }
                allocationsList.push(allocation);
            }
            if (this.isMount) {
                this.setState({
                    resourceMix: allocationsList
                });
            }
            this.getResourceAllocationsTotalValues(allocationsList);
        }

    };

    calculateOtherExpensesValuesOnCurrencyChange = (prevRate, newRate) => {
        const otherExpenses = this.state.otherExpenses.slice();

        if (otherExpenses.length > 0) {
            let otherExpensesList = [];
            let nonBillTotal = 0;
            let billTotal = 0;

            for (let expense of otherExpenses) {
                expense.BudgetedRate = Number(this.calculateNewRate(expense.BudgetedRate, prevRate, newRate));
                expense.Cost = Number(this.calculateNewRate(expense.Cost, prevRate, newRate));

                if (expense.BillableToClient === true && expense.Cost) {
                    billTotal = billTotal + expense.Cost;
                }

                if (expense.BillableToClient === false && expense.Cost) {
                    nonBillTotal = nonBillTotal + expense.Cost;
                }
                otherExpensesList.push(expense);
            }
            if (this.isMount) {
                this.setState({
                    otherExpenses: otherExpensesList
                });
            }

            this.getOtherExpensesTotalValues(otherExpensesList);
        }

    };

    populateCalculationsGrid = (consolidatedCMS) => {
        
        console.log("consolidatedCMS.discountedRevenueToFortude", consolidatedCMS.discountedRevenueToFortude);
        console.log("consolidatedCMS.totalEffortRM", consolidatedCMS.totalEffortRM);
        console.log("consolidatedCMS", consolidatedCMS);

        const calculatedActValues = consolidatedCMS.CalculatedActValues && consolidatedCMS.CalculatedActValues !== null ? consolidatedCMS.CalculatedActValues : null;

        const calculations = [
            {
                Field: '',
                Value1: 'Approved CMS Value',
                Value2: '',
                Value3: 'Projected Value',
                Value4: '',
                Value5: 'Variance %',
                Value6: 'Variance Value'
            },
            {
                Field: 'Duration | Effort',
                Value1: '',
                Value2: '',
                Value3: '',
                Value4: '',
                Value5: '',
                Value6: '',
                isHeader: true
            },
            {
                Field: 'Est. Project Duration (Calendar Days)',
                Value1: '',
                Value2: (consolidatedCMS.EstDurationStartDate && consolidatedCMS.EstDurationEndDate) ?
                        (` Start Date - ${(consolidatedCMS.EstDurationStartDate)} | End Date - ${(consolidatedCMS.EstDurationEndDate)}`) : '',
                Value3: '',
                Value4: (calculatedActValues !== null && calculatedActValues.EstDurationStartDate && calculatedActValues.EstDurationEndDate) ? 
                        (`Start Date - ${(calculatedActValues.EstDurationStartDate)} | End Date - ${(calculatedActValues.EstDurationEndDate)}`) : '' , 
                Value5: '',
                Value6: ''
            },
            {
                Field: 'Total Contracted Effort Days | Hours',
                Value1: formatNumber(Number(consolidatedCMS.totaleffortdaysresourceallocation)),
                Value2: formatNumber(Number(consolidatedCMS.totaleffortdaysresourceallocation * 8)),
                Value3: calculatedActValues !== null && calculatedActValues.ContractedEffortDays ? formatNumber(Number(calculatedActValues.ContractedEffortDays)) : 0.00,
                Value4: calculatedActValues !== null && calculatedActValues.ContractedEffort ? formatNumber(Number(calculatedActValues.ContractedEffort)) : 0.00,
                Value5: (calculatedActValues !== null && calculatedActValues.ConEffVarPercentage ? formatNumber(Number(calculatedActValues.ConEffVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.ConEffVariance ? formatNumber(Number(calculatedActValues.ConEffVariance)) : 0.00
            },
            {
                Field: 'Billable',
                Value1: '',
                Value2: '',
                Value3: calculatedActValues !== null && calculatedActValues.Billable ? formatNumber(Number(calculatedActValues.Billable)) : 0.00,
                Value4: '',
                Value5: (calculatedActValues !== null && calculatedActValues.BillVarPercentage ? formatNumber(Number(calculatedActValues.BillVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.BillVariance ? formatNumber(Number(calculatedActValues.BillVariance)) : 0.00
            },
            {
                Field: 'Non-Billable',
                Value1: '',
                Value2: '',
                Value3: calculatedActValues !== null && calculatedActValues.NonBillable ? formatNumber(Number(calculatedActValues.NonBillable)) : 0.00,
                Value4: '',
                Value5: (calculatedActValues !== null && calculatedActValues.NBVarPercentage ? formatNumber(Number(calculatedActValues.NBVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.NBVariance ? formatNumber(Number(calculatedActValues.NBVariance)) : 0.00
            },
            //-------------------------------------------------------------
            {
                Field: 'Consolidated Revenue',
                Value1: '',
                Value2: '',
                Value3: '',
                Value4: '',
                Value5: '',
                Value6: '',
                isHeader: true
            },
            {
                Field: 'Total Value to Customer',
                Value1: '',
                Value2:consolidatedCMS.totalprojectvalue  ? formatNumber(Number(consolidatedCMS.totalprojectvalue)) : '0.00',
                Value3: '',
                Value4: calculatedActValues !== null && calculatedActValues.TotalValueToCustomerAct ? formatNumber(Number(calculatedActValues.TotalValueToCustomerAct)) : 0.00,
                Value5: (calculatedActValues !== null && calculatedActValues.TotalValToCustomerVarPercentage ? formatNumber(Number(calculatedActValues.TotalValToCustomerVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.TotalValToCustomerVariance ? formatNumber(Number(calculatedActValues.TotalValToCustomerVariance)) : 0.00
            },
            {
                Field: 'Estimated OPE for Customer',
                Value1: '',
                Value2:consolidatedCMS.estimatedopecustomer ? formatNumber(Number(consolidatedCMS.estimatedopecustomer)) : '0.00',
                Value3: '',
                Value4: calculatedActValues !== null && calculatedActValues.EstOpeForCustomerAct ? formatNumber(Number(calculatedActValues.EstOpeForCustomerAct)) : 0.00,
                Value5: (calculatedActValues !== null && calculatedActValues.EstOpeVarPercentage ? formatNumber(Number(calculatedActValues.EstOpeVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.EstOpeVariance ? formatNumber(Number(calculatedActValues.EstOpeVariance)) : 0.00
            },
            {
                Field: 'Partner Commission',
                Value1: '',
                Value2: formatNumber(Number(consolidatedCMS.partnercommission)),
                Value3: '',
                Value4: calculatedActValues !== null && calculatedActValues.PartnerCommissionAct ? formatNumber(Number(calculatedActValues.PartnerCommissionAct)) : 0.00,
                Value5: (calculatedActValues !== null && calculatedActValues.PartnerCommVarPercentage ? formatNumber(Number(calculatedActValues.PartnerCommVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.PartnerCommVariance ? formatNumber(Number(calculatedActValues.PartnerCommVariance)) : 0.00
            },
            {
                Field: 'Revenue to Fortude After Discount',
                Value1: '',
                Value2: consolidatedCMS.projectrevenueafterdiscount ? formatNumber(Number(consolidatedCMS.projectrevenueafterdiscount)) : '0.00',
                Value3: '',
                Value4: calculatedActValues !== null && calculatedActValues.Revenue ? formatNumber(Number(calculatedActValues.Revenue)) : 0.00,
                Value5: (calculatedActValues !== null && calculatedActValues.RevVarPercentage ? formatNumber(Number(calculatedActValues.RevVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.RevVariance ? formatNumber(Number(calculatedActValues.RevVariance)) : 0.00
            },
            {
                Field: 'Average Rate per Day',
                Value1: '',
                Value2: consolidatedCMS.totaleffortdaysresourceallocation > 0
                  ? formatNumber(Number(parseFloat(consolidatedCMS.projectrevenueafterdiscount / consolidatedCMS.totaleffortdaysresourceallocation)))
                  : 0,
                Value3: '',
                Value4: calculatedActValues !== null && calculatedActValues.AvgRatePerDay ? formatNumber(Number(calculatedActValues.AvgRatePerDay)) : 0.00,
                Value5: (calculatedActValues !== null && calculatedActValues.AvgRateVarPercentage ? formatNumber(Number(calculatedActValues.AvgRateVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.AvgRateVariance ? formatNumber(Number(calculatedActValues.AvgRateVariance)) : 0.00
            },
            //-----------------------------------------------------------------
            {
                Field: 'Discounts | Deductions',
                Value1: '',
                Value2: '',
                Value3: '',
                Value4: '',
                Value5: '',
                Value6: '',
                isHeader: true
            },
            {
                Field: 'Value Discount / (Premium)',
                Value1: consolidatedCMS.projectrevenueafterdiscount ? formatNumber(Number(parseFloat(((consolidatedCMS.discountvalue / consolidatedCMS.totalrevenueresourceallocation) * 100)))) + '' : '0.00',
                Value2: consolidatedCMS.discountvalue ? formatNumber(Number(consolidatedCMS.discountvalue)) : '0.00',
                Value3: '',
                Value4: '',
                Value5: '',
                Value6: ''
            },
            {
                Field: 'Rate Discount / (Premium)',
                Value1: consolidatedCMS.projectrevenueafterdiscount ? formatNumber(Number(parseFloat(((consolidatedCMS.ratediscount / consolidatedCMS.totalrevenueresourceallocation) * 100)))) + '' : '0.00',
                Value2: consolidatedCMS.ratediscount ? formatNumber(Number(consolidatedCMS.ratediscount)) : '0.00',
                Value3: '',
                Value4: '',
                Value5: '',
                Value6: ''
            },
            {
                Field: 'Non-billable expenses',
                Value1: '',
                Value2: consolidatedCMS.nonbillabletotalotherexpenses ? formatNumber(Number(consolidatedCMS.nonbillabletotalotherexpenses)) : '0.00',
                Value3: '',
                Value4: calculatedActValues !== null && calculatedActValues.NbExpensesAct ? formatNumber(Number(calculatedActValues.NbExpensesAct)) : 0.00,
                Value5: (calculatedActValues !== null && calculatedActValues.NbExpensesVarPercentage ? formatNumber(Number(calculatedActValues.NbExpensesVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.NbExpensesVariance ? formatNumber(Number(calculatedActValues.NbExpensesVariance)) : 0.00
            },
            //--------------------------------------------------------------------
            {
                Field: 'Consolidated Profitability',
                Value1: '',
                Value2: '',
                Value3: '',
                Value4: '',
                Value5: '',
                Value6: '',
                isHeader: true
            },
            {
                Field: 'Gross margin % | Gross Profit From Resource Deployment',
                Value1: consolidatedCMS.projectrevenueafterdiscount ? formatNumber(Number(parseFloat(((consolidatedCMS.grossprofitfromresourcedeployment / consolidatedCMS.projectrevenueafterdiscount) * 100)))) + '%' : '0.00%',
                Value2: formatNumber(Number(consolidatedCMS.grossprofitfromresourcedeployment)),
                Value3: (calculatedActValues !== null && calculatedActValues.GrossPercentage ? formatNumber(Number(calculatedActValues.GrossPercentage) * 100) : 0.00) + '%',
                Value4: calculatedActValues !== null && calculatedActValues.GrossMargin ? formatNumber(Number(calculatedActValues.GrossMargin)) : 0.00,
                Value5: (calculatedActValues !== null && calculatedActValues.GrossMarginVarPercentage ? formatNumber(Number(calculatedActValues.GrossMarginVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.GrossMarginVariance ? formatNumber(Number(calculatedActValues.GrossMarginVariance)) : 0.00
            },
            {
                Field: 'Net Margin % | Net Profit / (Loss)',
                Value1: consolidatedCMS.projectrevenueafterdiscount ? formatNumber(Number(parseFloat(((consolidatedCMS.netprofitloss / consolidatedCMS.projectrevenueafterdiscount) * 100)))) + '%' : '0.00%',
                Value2: consolidatedCMS.netprofitloss ? formatNumber(Number(consolidatedCMS.netprofitloss)) : '0.00',
                Value3: (calculatedActValues !== null && calculatedActValues.NetPercentage ? formatNumber(Number(calculatedActValues.NetPercentage) * 100) : 0.00) + '%',
                Value4: calculatedActValues !== null && calculatedActValues.NetMargin ? formatNumber(Number(calculatedActValues.NetMargin)) : 0.00,
                Value5: (calculatedActValues !== null && calculatedActValues.NetMarginVarPercentage ? formatNumber(Number(calculatedActValues.NetMarginVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.NetMarginVariance ? formatNumber(Number(calculatedActValues.NetMarginVariance)) : 0.00
            },
            {
                Field: 'Other',
                Value1: '',
                Value2: '',
                Value3: '',
                Value4: '',
                Value5: '',
                Value6: '',
                isHeader: true
            },
            {
                Field: 'Total Contingency',
                Value1: this.state.TotalRevenueResourceAllocation ? formatNumber(Number(parseFloat(((consolidatedCMS.contingencyvalue / consolidatedCMS.totalrevenueresourceallocation) * 100)))) + '%' : '0.00%',
                Value2: formatNumber(Number(consolidatedCMS.contingencyvalue)),
                Value3: (calculatedActValues !== null && calculatedActValues.TotalContingencyPercentageAct ? formatNumber(Number(calculatedActValues.TotalContingencyPercentageAct)) : 0.00) + '%',
                Value4: calculatedActValues !== null && calculatedActValues.TotalContingencyAct ? formatNumber(Number(calculatedActValues.TotalContingencyAct)) : 0.00,
                Value5: (calculatedActValues !== null && calculatedActValues.TotalContingencyVarPercentage ? formatNumber(Number(calculatedActValues.TotalContingencyVarPercentage)) : 0.00) + '%',
                Value6: calculatedActValues !== null && calculatedActValues.TotalContingencyVariance ? formatNumber(Number(calculatedActValues.TotalContingencyVariance)) : 0.00
            }

        ];
        if (this.isMount) {
            this.setState({
                  calculations: calculations
              },
              () => {
                  this.forceUpdate();
              }
            );
        }
    };

    onClick = async event => {
        event.preventDefault();
        if (this.isMount) {
            this.setState({
                DesignationsAll: this.props.DesignationsAll
            });
        }
        if (this.props.IdList && this.props.IdList.length > 0) {
            this.setState({
                loading: true
            });

            this.setProjectCurrency();
            //await this.populateData();
            await this.populateConsolidatedCMS(this.props.IdList);
            await this.populateConsolidatedResourceMix(this.props.VersionAndIDList, this.props);
            if(this.state.projectsAndResources !== null){
                await this.setStartAndEndDatesForDuration();
            }
            await this.populateConsolidatedOngoingResourceMix();
            await this.handleCalculations();
            await this.handleActualCalculations();
            if (this.state.consolidatedCMSList && this.state.calculatedActValues) {
                await this.combineAditionalCalculationsToConsolidatedCMS(this.state.consolidatedCMSList[0], this.state.calculatedValues, this.state.calculatedActValues);
                await this.populateCalculationsGrid(this.state.consolidatedCMSList[0]);
            }
            await this.populateFilesOfCMSheets(this.props.VersionAndIDList, this.props);

            this.setState({
                loading: false
            });
        } else {
            this.setState({ visible: true });
            this.toggleMessageDialog(
              'Please select a CM Sheet to consolidate',
              'Error'
            );
            return;
        }
    };

    combineAditionalCalculationsToConsolidatedCMS = async (consolidatedCMS, additionalCalculatedCMS, calculatedActValues) => {

        let tempArray = [];
        let cmsObject = Object. assign({}, consolidatedCMS);
        let additionalObject = Object. assign({}, additionalCalculatedCMS);

        // Set values from additional CMS calculations
        cmsObject.totalEffortRM = additionalObject.totalEffortRM;
        cmsObject.totalEffortDaysCMS = additionalObject.totalEffortDaysCMS;
        cmsObject.discountedRevenueToFortude = additionalObject.discountedRevenueToFortude;
        cmsObject.discountedRevenueToFortudeCMS = additionalObject.discountedRevenueToFortudeCMS;
        cmsObject.TotalOngoingGrossProfit = additionalObject.TotalOngoingGrossProfit;
        cmsObject.TotalGrossProfit = additionalObject.TotalGrossProfit;
        cmsObject.GrossVeriance = additionalObject.GrossVeriance;
        cmsObject.netProfit = additionalObject.netProfit;
        cmsObject.netProfitLossCMS = additionalObject.netProfitLossCMS;
        cmsObject.EstDurationStartDate = additionalObject.EstDurationStartDate;
        cmsObject.EstDurationEndDate = additionalObject.EstDurationEndDate;

        // Set calculated atual values
        cmsObject.CalculatedActValues = calculatedActValues;

        tempArray.push(cmsObject);

        await this.setState({
            consolidatedCMSList: tempArray
        });
    };

    populateConsolidatedOngoingResourceMix = async () => {
        const IdList = this.props.VersionAndIDList;
        if (IdList && IdList.length > 0) {
            
            await getResourceRequestResourceMixData(IdList).then(async response => {
                const res = response.data;
                const resources = [];
                let index = 0;
                let allProjectCRphases = [];
                var delayInMilliseconds = 1000; //1 second

                setTimeout(function() {
                    //your code to be executed after 1 second
                }, delayInMilliseconds);
                for (let resource of res) {
                    for (let element of resource) {
                        element.selected = false;
                        element.index = index;
                        element.name = this.getProjectOrCRName(element);
                        const isConvertToActive = this.isProjectOrCrActive(element);

                        if (element.ResourceRequestSubLine.length > 0 && isConvertToActive) {
                            var allocateSublines = [];
                            for (const subline of element.ResourceRequestSubLine) {

                                if (subline && (subline.Status === 'CLOSE' || subline.Status === 'COMPLETE')) {
                                    allocateSublines.push(subline);
                                }

                            }
                            if (allocateSublines.length > 0) {
                                element.ResourceRequestSubLine = allocateSublines;
                                resources.push(element);
                                index++;
                            }
                        }
                        else if (isConvertToActive && (element.Status === 'CLOSE' || element.Status === 'COMPLETE')) {
                            resources.push(element);
                            index++;
                        }
                        

                        if (element.Type === 'Project') {
                            if (element.Project && element.Project.CMSheets && element.Project.CMSheets.length > 0) {
                                const cmsheet = element.Project.CMSheets[0];
                                if (cmsheet.Phases && cmsheet.Phases.length > 0) {
                                    for (const phase of cmsheet.Phases) {
                                        allProjectCRphases.push(phase);
                                    }
                                }
                            }
                        } else {
                            if (element.ChangeRequest && element.ChangeRequest.CMSheets && element.ChangeRequest.CMSheets.length > 0) {
                                const cmsheet = element.ChangeRequest.CMSheets[0];
                                if (cmsheet.Phases && cmsheet.Phases.length > 0) {
                                    for (const phase of cmsheet.Phases) {
                                        allProjectCRphases.push(phase);
                                    }
                                }
                            }
                        }

                        if (element.ResourceRequestSubLine && element.ResourceRequestSubLine.length > 0 && isConvertToActive) {
                            let index = 0;
                            let minStartDate = element.ResourceRequestSubLine[0].StartDate;
                            let maxEndDate = element.ResourceRequestSubLine[0].EndDate;
                            let cost = 0;
                            let revenue = 0;
                            let EffortDays = 0;
                            let CostVariance = 0;

                            for (const subLine of element.ResourceRequestSubLine) {

                                if (subLine && (subLine.Status === 'CLOSE' || subLine.Status === 'COMPLETE')) {

                                    if (subLine.EffortDays) {
                                        EffortDays += subLine.EffortDays;
                                    }
    
                                    if (minStartDate > subLine.StartDate) {
                                        minStartDate = subLine.StartDate;
                                    }
    
                                    if (maxEndDate < subLine.EndDate) {
                                        maxEndDate = subLine.EndDate;
                                    }
    
                                    if (subLine.Cost) {
                                        let tmpCost = Number(subLine.Cost);
                                        cost = cost + tmpCost;
                                    }
    
                                    if (subLine.Revenue) {
                                        revenue += Number(subLine.Revenue)
                                    }
    
                                    if (subLine.CostVariance) {
                                        CostVariance += Number(subLine.CostVariance);
                                    }
    
                                    element.TotalCost = cost.toFixed(2);
    
                                    subLine.index = index;
                                    index++;

                                }
                            }

                            element.TotalRevenue = revenue.toFixed(2);
                            const costToRevenueRatio = Number(revenue) === 0 ? 0.00 : cost / revenue;
                            element.CostToRevenueRatio = costToRevenueRatio.toFixed(2);

                            element.StartDate = minStartDate;
                            element.EndDate = maxEndDate;
                            element.EffortDays = EffortDays.toFixed(2);
                            element.CostVariance = CostVariance.toFixed(2);

                        }

                    }
                }

               

                let resourcesTemp = [];
                for (const resource of resources) {
                    const resourceObj = JSON.parse(JSON.stringify(resource));
                    resourcesTemp.push(resourceObj);
                }

                for (const resource of resources) {
                    let countryId;
                    if (resource.EndDate === null && resource.StartDate !== null) {
                        let totalEffortDays = 0;
                        totalEffortDays = (resource.EffortDays / (resource.AllocationPercentage / 100));
                        if (resource.OnsiteStatus === 'Onsite') {
                            countryId = resource.Type === "Project" ? resource.Project.CountryId : resource.ChangeRequest.CountryId;
                        } else {
                            countryId = resource.dataValueResourceRegion.DefaultCountryId;
                        }

                        let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.StartDate, countryId);
                        resource.EndDate = moment(endDate).format('YYYY-MM-DD');
                    }
                }

                this.setState({
                    resourceMixOngoing: resourcesTemp
                });
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        } else {
            this.setState({
                resourceMixOngoing: []
            });
        }
    };

    setStartAndEndDatesForDuration = async () => {
        let cmsArray = [...this.state.projectsAndResources];

        // For Est project duration 
        if(cmsArray.length > 0){
            for(let cmSheet of cmsArray){
                let project = null;
                let projOrCrStatus = null;
                let endDate = null;

                // Set phase and subphase
                const phases = cmSheet.Phases;
                cmSheet.ResourceAllocations.forEach(function (resource, index){
                    let resPhase = phases.filter(e => e.id === resource.PhaseId);
                    const dataValuePhase = resPhase[0];
                    cmSheet.ResourceAllocations[index].dataValuePhase = dataValuePhase;

                    if(resPhase.length > 0){
                        if(resPhase[0].SubPhases){
                            let resSubPhase = resPhase[0].SubPhases.filter(e => e.id === resource.SubPhaseId);
                            const dataValueSubPhase = resSubPhase[0];
                            cmSheet.ResourceAllocations[index].dataValueSubPhase = dataValueSubPhase;
                        }
                    }
                    cmSheet.ResourceAllocations[index].DateStartDate = resource.StartDate;
                });

                // Project
                if(cmSheet.Type == 'Project' && cmSheet.Project && cmSheet.Project !== null){
                    project = cmSheet.Project;
                    projOrCrStatus = project.Status && project.Status !== null ? project.Status : null;
                }
                // CR
                if(cmSheet.Type == 'ChangeRequest' && cmSheet.ChangeRequest && cmSheet.ChangeRequest !== null){
                    project = cmSheet.ChangeRequest;
                    projOrCrStatus = project.StatusId && project.StatusId !== null ? project.StatusId : null;
                }

                let { startDate, allocationStartDate } = await this.getEstimatedStartDate(project, cmSheet);
                cmSheet.StartDateForDuration = startDate;
                
                if(projOrCrStatus == 6 || projOrCrStatus == 1){
                    let totalDays = 0;
                    let totalWorkingDayCount = cmSheet.TotalWorkingDaysCount;
                    let endDateBillable = new Date(startDate);
            
                    let maxResource = await this.getResourceWithMaxEndDate(project, cmSheet);
                    let defaultCountry = null;
                    if(maxResource){
                        let onsiteStatus = maxResource.OnsiteStatus ? maxResource.OnsiteStatus : '';
                        if(onsiteStatus && onsiteStatus === 'Offshore'){
                            defaultCountry = maxResource.dataValueResourceRegion ? maxResource.dataValueResourceRegion.DefaultCountryId : project.CountryId;
                        } 
                        else{
                            defaultCountry = project.CountryId;
                        }
                    }
            
                    const holidays = await getHolidaysIntegrated(defaultCountry, endDateBillable.getFullYear()).then(res => {
                        if(res && res.data){
                            return res.data;
                        }
                        return [];
                    }).catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        return [];
                    });
            
                    let isHoliday = false;
                    for(let i = 1; i < totalWorkingDayCount; i++){
                        totalDays++;
                        endDateBillable.setDate(endDateBillable.getDate() + 1);
                
                        isHoliday = false;
                        const dateStr = getFormattedDate(endDateBillable);
                        if(holidays.indexOf(dateStr) > -1){
                            isHoliday = true;
                        }
                        if(isHoliday){
                            totalWorkingDayCount++;
                        }
                        if(isWeekend(endDateBillable)){
                            totalWorkingDayCount++;
                        }
                    }
                    endDate = endDateBillable;
                    cmSheet.EndDateForDuration = endDate;
                }
                else{
                    endDate = await this.getEstimatedEndDate(project, cmSheet);
                    cmSheet.EndDateForDuration = endDate;
                }
            }
        }
    };

    getEstimatedStartDate = async (project, cmSheet) => {
        let startDate = null;
        let allocationStartDate;
        let nullAllocDate = null;
    
        if(project && project.StartDate && project.StartDate !== null){
          startDate = new Date(project.StartDate);
        }
    
        if(!startDate){
          let minDate = null;
            
            if(cmSheet.ResourceAllocations && cmSheet.ResourceAllocations.length > 0){
                for(let allocation of cmSheet.ResourceAllocations){
                    if(allocation.StartDate){
                        const tempDate = new Date(allocation.StartDate);
                        if((minDate && tempDate < minDate) || !minDate){
                            minDate = tempDate;
                        }
                    }
                    else if(allocation.dataValueSubPhase && allocation.dataValueSubPhase.StartDate){
                        const tempDate = new Date(allocation.dataValueSubPhase.StartDate);
                        if((minDate && tempDate < minDate) || !minDate){
                            minDate = tempDate;
                        }
                    }
                    else if(allocation.dataValuePhase && allocation.dataValuePhase.StartDate){
                        const tempDate = new Date(allocation.dataValuePhase.StartDate);
                        if((minDate && tempDate < minDate) || !minDate){
                            minDate = tempDate;
                        }
                    }
                    else{
                        nullAllocDate = new Date();
                    }

                    if(minDate){
                        startDate = minDate;
                    }
                    else{
                        startDate = new Date();
                    }
                }

                if(startDate > nullAllocDate && nullAllocDate){
                    startDate = nullAllocDate;
                }
            }
        }
        allocationStartDate = startDate;
        return { startDate, allocationStartDate };
    };

    getResourceWithMaxEndDate = async (project, cmSheet) => {
        let maxDate = null;
        let maxAllocation = null;
        for(const allocation of cmSheet.ResourceAllocations){
            if(allocation.EstimatedEndDate){
                const tempDate = new Date(allocation.EstimatedEndDate);
                const tempAllocation = allocation;
                if((maxDate && tempDate > maxDate) || !maxDate){
                    maxDate = tempDate;
                    maxAllocation = tempAllocation;
                }
            }
        }
        return maxAllocation;
    };

    getEstimatedEndDate = async (project, cmSheet) => {
        let endDate = null;
        let maxDate = null;

        if(project && project.EndDate && project.EndDate !== null){
            endDate = new Date(project.EndDate);
            maxDate = endDate;
        }

        if(!endDate){
            if(cmSheet.ResourceAllocations && cmSheet.ResourceAllocations.length > 0){
                for(const allocation of cmSheet.ResourceAllocations){
                    if(allocation.EndDate !== null){
                        const tempDate = new Date(allocation.EndDate);
                        if((maxDate && tempDate > maxDate) || !maxDate){
                            maxDate = tempDate;
                        }
                    }
                    else if(allocation.EstimatedEndDate !== null){
                        const tempDate = new Date(allocation.EstimatedEndDate);
                        if((maxDate && tempDate > maxDate) || !maxDate){
                            maxDate = tempDate;
                        }
                    }
                }
            }
        }
        return maxDate;
    };

    handleCalculations = async () => {

        //ongoing cms values
        let totalCostRM = Number(0.00);
        let totalGrossCostProject = Number(0.00);
        let totalGrossCostCr = Number(0.00);
        let totalRevenueRM = Number(0.00);
        let totalEffortRM = Number(0.00);
        let calculatedValues = {};
        let discountedRevenueToFortude = Number(0.00);
        let netProfit = Number(0.00);
        let fixedPriceEffortOngoing = Number(0.00);
        let TMEffortOngoing = Number(0.00);

        //approved cms values
        let totalEffortDaysCMS = 0.00;
        let discountedRevenueToFortudeCMS = Number(0.00);
        let netProfitLossCMS = Number(0.00);
        let fixedPriceEffortApproved = Number(0.00);
        let TMEffortApproved = Number(0.00);
        let totalCostCMS = Number(0.00);

        let TotalGrossProfit = Number(0.00);
        let TotalOngoingGrossProfit = Number(0.00);
        let ServiceRevenue = Number(0.00);
        let GrossProfitMargin;

        let calculationResourceMix = this.state.resourceMixOngoing;
        const invalidStatusList = ['NEW_FROM_CMS', 'NEW_FROM_RM', 'REMOVED', 'RQST_APP_REJECTED', 'EDIT', 'RES_ALLOC_CANCELLED','CONFIRMED', 'RES_ALLOC_PENDING', 'ALLOC_APP_PENDING'];
        const approvedStatusList = [ 'COMPLETE', 'CLOSE'];

        for (const mainLine of calculationResourceMix) {
            if (mainLine.IsPreSales === false && mainLine.IsSoftBookingResource == false) {
                if (mainLine.Project && mainLine.Project.StatusConvertedToActive === true) {
                    if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                        for (const subLine of mainLine.ResourceRequestSubLine) {
                            if (!invalidStatusList.includes(subLine.Status) || approvedStatusList.includes(subLine.Status)) {
                                totalCostRM += Number(subLine.Cost);
                                totalRevenueRM += Number(subLine.Revenue);
                                totalEffortRM += Number(subLine.EffortDays);
                                totalGrossCostProject += Number(subLine.DirectCost) * Number(subLine.EffortDays);


                                if (mainLine.Project.PaymentMethod === 1) {
                                    fixedPriceEffortOngoing += Number(subLine.EffortDays);
                                }
                                else if (mainLine.Project.PaymentMethod === 2) {
                                    TMEffortOngoing += Number(subLine.EffortDays);
                                }
                                else if (mainLine.Project.PaymentMethod === 3) {
                                    //for calculate fixed price effort
                                    if (subLine.PaymentMethod === 1) {
                                        fixedPriceEffortOngoing += Number(subLine.EffortDays)
                                    }
                                    else {
                                        //for calculate T & M effort
                                        TMEffortOngoing += Number(subLine.EffortDays)
                                    }
                                }

                            }
                        }
                    } else {
                        if (!invalidStatusList.includes(mainLine.Status) || approvedStatusList.includes(mainLine.Status)) {
                            totalCostRM += Number(mainLine.TotalCost);
                            totalRevenueRM += Number(mainLine.TotalRevenue);
                            totalEffortRM += Number(mainLine.EffortDays);
                            totalGrossCostProject += Number(mainLine.DirectCost) * Number(mainLine.EffortDays);

                            if (mainLine.Project.PaymentMethod === 1) {
                                fixedPriceEffortOngoing += Number(mainLine.EffortDays);
                            }
                            else if (mainLine.Project.PaymentMethod === 2) {
                                TMEffortOngoing += Number(mainLine.EffortDays);
                            }
                            else if (mainLine.Project.PaymentMethod === 3) {

                                //for calculate fixed price effort
                                if (mainLine.PaymentMethod === 1) {
                                    fixedPriceEffortOngoing += Number(mainLine.EffortDays)

                                } else if (mainLine.PaymentMethod === 2) {
                                    //for calculate T & M effort
                                    TMEffortOngoing += Number(mainLine.EffortDays)

                                }
                                else if (mainLine.PaymentMethod === 3) {

                                    fixedPriceEffortOngoing += Number(mainLine.EffortDays)
                                }
                            }

                        }
                    }
                } else if (mainLine.ChangeRequest && mainLine.ChangeRequest.StatusConvertedToActive === true) {
                    if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                        for (const subLine of mainLine.ResourceRequestSubLine) {
                            if (!invalidStatusList.includes(subLine.Status) || approvedStatusList.includes(subLine.Status)) {
                                totalCostRM += Number(subLine.Cost);
                                totalRevenueRM += Number(subLine.Revenue);
                                totalEffortRM += Number(subLine.EffortDays);
                                totalGrossCostCr += Number(subLine.DirectCost) * Number(subLine.EffortDays);

                                if (mainLine.ChangeRequest.PaymentMethodId === 1) {
                                    fixedPriceEffortOngoing += Number(subLine.EffortDays);
                                }
                                else if (mainLine.ChangeRequest.PaymentMethodId === 2) {
                                    TMEffortOngoing += Number(subLine.EffortDays);
                                }
                                else if (mainLine.ChangeRequest.PaymentMethodId === 3) {
                                    //for calculate fixed price effort
                                    if (subLine.PaymentMethod === 1) {
                                        fixedPriceEffortOngoing += Number(subLine.EffortDays)

                                    }
                                    else {
                                        //for calculate T & M effort
                                        TMEffortOngoing += Number(subLine.EffortDays)
                                    }
                                }
                            }
                        }
                    } else {
                        if (!invalidStatusList.includes(mainLine.Status) || approvedStatusList.includes(mainLine.Status)) {
                            totalCostRM += Number(mainLine.TotalCost);
                            totalRevenueRM += Number(mainLine.TotalRevenue);
                            totalEffortRM += Number(mainLine.EffortDays);
                            totalGrossCostCr += Number(mainLine.DirectCost) * Number(mainLine.EffortDays);

                            if (mainLine.ChangeRequest.PaymentMethodId === 1) {

                                fixedPriceEffortOngoing += Number(mainLine.EffortDays);
                            } else if (mainLine.ChangeRequest.PaymentMethodId === 2) {
                                TMEffortOngoing += Number(mainLine.EffortDays);
                            } else if (mainLine.ChangeRequest.PaymentMethodId === 3) {
                                //for calculate fixed price effort
                                if (mainLine.PaymentMethod === 1) {
                                    fixedPriceEffortOngoing += mainLine.EffortDays

                                } else {
                                    //for calculate T & M effort
                                    TMEffortOngoing += mainLine.EffortDays

                                }
                            }

                        }
                    }
                }
            }
        }

        let CmsArray = [];
        for (let element of this.state.projectsAndResources) {
            const isConvertToActive = this.isProjectOrCrActive(element);
            element.isConvertToActive = isConvertToActive;
            CmsArray.push(element);
        }

        for (const item of CmsArray) {
            const mainLine = JSON.parse(JSON.stringify(item));
            if (mainLine.isConvertToActive === true && item.Type === 'Project') {

                const projectVersion = mainLine.Version !== null ? mainLine.Version : null;
                const projectID = mainLine.id !== null ? mainLine.id : null;
                let projcurrencyId = 0;
                let cmsRevenueAfterDiscountFixed = Number(0.00);

                await getRegionCurrencyIntegrated(mainLine.Project.BillingRegion).then(response => { //currencyId
                    projcurrencyId = response.data.CurrencyId;
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });
                const proStartDate = new Date(mainLine.Project.StartDate);
                const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated( //conversionRate
                    projcurrencyId,
                    proStartDate.getMonth() + 1,
                    proStartDate.getFullYear()
                );


                //approved CMS values
                if (projectID !== null && projectVersion !== null) {
                    await getCMSheet(projectID, projectVersion).then(res => {

                        if (res.data.Project.PaymentMethod === 1) {
                            fixedPriceEffortApproved += res.data.TotalEffortDaysResourceAllocation
                        }
                        else if (res.data.Project.PaymentMethod === 2) {
                            TMEffortApproved += res.data.TotalEffortDaysResourceAllocation
                        }
                        else if (res.data.Project.PaymentMethod === 3) {
                            for (let alloc of res.data.ResourceAllocations) {

                                if (alloc.PaymentMethod === 1) {
                                    fixedPriceEffortApproved += alloc.EffortDays
                                }
                                else {
                                    TMEffortApproved += alloc.EffortDays
                                }
                            }
                        }
                        totalEffortDaysCMS += Number(res.data.TotalEffortDaysResourceAllocation);

                        // Total gross profit
                        TotalGrossProfit += Number(res.data.GrossProfitFromResourceDeployment);

                        // Service revenue
                        ServiceRevenue += Number(res.data.ServiceRevenueValue);

                    })
                }

                if (projectID !== null && projectVersion !== null) {
                    await getCMSheet(projectID, projectVersion).then(res => {
                        //Revenue to Fortude after discount - CMS
                        const discountVal = (   // Value discount / (premium)
                            (Number(res.data.TotalRevenueResourceAllocation) + Number(res.data.ServiceRevenueValue) + Number(res.data.ContingencyValue)) *
                            (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
                        const x = Math.pow(10, Number(2) + 1);
                        const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
                        cmsRevenueAfterDiscountFixed =
                            Number(res.data.TotalRevenueResourceAllocation) + Number(res.data.ServiceRevenueValue) - Number(valueDiscount) + Number(res.data.ContingencyValue); // Revenue to company after discount
                        discountedRevenueToFortudeCMS += cmsRevenueAfterDiscountFixed;
    
                        //Net Profit - cms
                        const contingencyVal =
                            Number(Number(res.data.TotalCostResourceAllocation) * res.data.ContingencyPercentage / 100).toFixed(2);
                        const netProfitLossCMSproj = cmsRevenueAfterDiscountFixed -
                            res.data.TotalCostResourceAllocation -
                            cmsRevenueAfterDiscountFixed * (res.data.SalesCommissionPercentage / 100) -
                            res.data.NonBillableTotalOtherExpenses -
                            contingencyVal;
                        netProfitLossCMS += netProfitLossCMSproj;
                        totalCostCMS += Number(res.data.TotalCostResourceAllocation);
                        totalCostCMS += Number(contingencyVal);
                    })
                }


                //on-Going CMS values - based on project type
                //revenue to fortude after discount - ongoing
                if (mainLine.Project.PaymentMethod === 1) {    //fixed project
                    discountedRevenueToFortude += cmsRevenueAfterDiscountFixed;
                } else if (mainLine.Project.PaymentMethod === 2) { //T&M
                    //Sum of revenue from billable lines + sum of (Service Revenue - Discount + Contingency) from CMS
                    for (const mainLine of calculationResourceMix) {
                        if (mainLine.CMSheetId === projectID) { //Sum of revenue from billable lines of project
                            if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                                for (const subLine of mainLine.ResourceRequestSubLine) {
                                    if (!invalidStatusList.includes(subLine.Status) || approvedStatusList.includes(subLine.Status)) {
                                        discountedRevenueToFortude += Number(subLine.Revenue);
                                    }
                                }
                            } else {
                                if (!invalidStatusList.includes(mainLine.Status) || approvedStatusList.includes(mainLine.Status)) {
                                    discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                                }
                            }
                        }
                    }
                    if (projectID !== null && projectVersion !== null) {
                        await getCMSheet(projectID, projectVersion).then(res => {
                            discountedRevenueToFortude += Number(res.data.ServiceRevenueValue);
                            discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue);
                            discountedRevenueToFortude += Number(res.data.ContingencyValue);
                        })
                    }
                } else if (mainLine.Project.PaymentMethod === 3) { //hybrid
                    //T&M and fixed
                    for (const mainLine of calculationResourceMix) {
                        if (mainLine.CMSheetId === projectID && (mainLine.PaymentMethod === 2 || mainLine.PaymentMethod === 1)) { //Sum of revenue from billable lines of project
                            if (mainLine.ResourceRequestSubLine.length > 0) {
                                for (const subLine of mainLine.ResourceRequestSubLine) {
                                    if (!invalidStatusList.includes(subLine.Status) || approvedStatusList.includes(subLine.Status)) {
                                        discountedRevenueToFortude += Number(subLine.Revenue);
                                    }
                                }
                            } else {
                                if (!invalidStatusList.includes(mainLine.Status) || approvedStatusList.includes(mainLine.Status)) {
                                    discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                                }
                            }
                        }
                    }
                    if (projectID !== null && projectVersion !== null) {
                        await getCMSheet(projectID, projectVersion).then(res => {
                            discountedRevenueToFortude += Number(res.data.ServiceRevenueValue);
                            discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue);
                            discountedRevenueToFortude += Number(res.data.ContingencyValue);
                        })
                    }
                }

            }
        }


        //revenue to fortude after discount calculations of CR
        for (const cr of CmsArray) {
            if (cr.isConvertToActive === true && cr.Type === 'ChangeRequest') {

                const crVersion = cr.Version;
                const crID = cr.id;
                let currencyIdCR = 0;
                let cmsRevenueAfterDiscountCR = Number(0.00);

                await getRegionCurrencyIntegrated(cr.ChangeRequest.BillingRegion).then(response => {  //curencyID
                    currencyIdCR = response.data.CurrencyId;
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });
                const crStartDate = new Date(cr.ChangeRequest.StartDate);
                const crRateRecordCurrencyRate = await retrieveConversionRateIntegrated(  //conversion rate
                    currencyIdCR,
                    crStartDate.getMonth() + 1,
                    crStartDate.getFullYear()
                );

                //approved CMS values
                if (crID !== null && crVersion !== null) {
                    await getCMSheet(crID, crVersion).then(res => {

                        if (res.data.ChangeRequest.PaymentMethodId === 1) {
                            fixedPriceEffortApproved += res.data.TotalEffortDaysResourceAllocation
                        }
                        else if (res.data.ChangeRequest.PaymentMethodId === 2) {
                            TMEffortApproved += res.data.TotalEffortDaysResourceAllocation
                        }
                        else if (res.data.ChangeRequest.PaymentMethodId === 3) {
                            for (let alloc of res.data.ResourceAllocations) {

                                if (alloc.PaymentMethod === 1) {
                                    fixedPriceEffortApproved += alloc.EffortDays
                                }
                                else {
                                    TMEffortApproved += alloc.EffortDays
                                }
                            }
                        }
                        totalEffortDaysCMS += Number(res.data.TotalEffortDaysResourceAllocation);

                        // Total gross profit
                        TotalGrossProfit += Number(res.data.GrossProfitFromResourceDeployment);

                        // Service revenue
                        ServiceRevenue += Number(res.data.ServiceRevenueValue);
                    })
                }

                // Revenue to Fortude after discount - cms calculations
                if (crID !== null && crVersion !== null) {
                    await getCMSheet(crID, crVersion).then(res => {
                        const discountVal = (   // Value discount / (premium)
                            (Number(res.data.TotalRevenueResourceAllocation) + Number(res.data.ServiceRevenueValue) + Number(res.data.ContingencyValue)) *
                            (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
                        const x = Math.pow(10, Number(2) + 1);
                        const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
                        cmsRevenueAfterDiscountCR =
                            Number(res.data.TotalRevenueResourceAllocation) + Number(res.data.ServiceRevenueValue) - Number(valueDiscount) + Number(res.data.ContingencyValue); // Revenue to company after discount
                        discountedRevenueToFortudeCMS += cmsRevenueAfterDiscountCR;

                        //Net Profit - cms
                        const contingencyVal =
                            Number(Number(res.data.TotalCostResourceAllocation) * res.data.ContingencyPercentage / 100).toFixed(2);
                        const netProfitLossCMScr = cmsRevenueAfterDiscountCR -
                            res.data.TotalCostResourceAllocation -
                            cmsRevenueAfterDiscountCR * (res.data.SalesCommissionPercentage / 100) -
                            res.data.NonBillableTotalOtherExpenses -
                            contingencyVal;
                        netProfitLossCMS += netProfitLossCMScr;
                        totalCostCMS += Number(res.data.TotalCostResourceAllocation);
                        totalCostCMS += Number(contingencyVal);
                    })
                }

                //on-Going CMS values - based on CR type
                // Revenue to Fortude after discount - ongoing
                if (cr.ChangeRequest.PaymentMethodId === 1) { //fixed CR
                    discountedRevenueToFortude += cmsRevenueAfterDiscountCR;
                }
                else if (cr.ChangeRequest.PaymentMethodId === 2) { //T&M crs
                    for (const mainLine of calculationResourceMix) {
                        if (mainLine.CMSheetId === crID) {  //Sum of revenue from billable lines of CR
                            if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                                for (const subLine of mainLine.ResourceRequestSubLine) {
                                    if (!invalidStatusList.includes(subLine.Status) || approvedStatusList.includes(subLine.Status)) {
                                        discountedRevenueToFortude += Number(subLine.Revenue);
                                    }
                                }
                            } else {
                                if (!invalidStatusList.includes(mainLine.Status) || approvedStatusList.includes(mainLine.Status)) {
                                    discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                                }
                            }
                        }
                    }
                    await getCMSheet(crID, crVersion).then(res => {
                        discountedRevenueToFortude += Number(res.data.ServiceRevenueValue);
                        discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue);
                        discountedRevenueToFortude += Number(res.data.ContingencyValue);
                    })
                } else if (cr.ChangeRequest.PaymentMethodId === 3) { //hybrid
                    //T&M and fixed
                    for (const mainLine of calculationResourceMix) {
                        if (mainLine.CMSheetId === crID && (mainLine.PaymentMethod === 2 || mainLine.PaymentMethod === 1)) {  //Sum of revenue from billable lines of CR
                            if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                                for (const subLine of mainLine.ResourceRequestSubLine) {
                                    if (!invalidStatusList.includes(subLine.Status) || approvedStatusList.includes(subLine.Status)) {
                                        discountedRevenueToFortude += Number(subLine.Revenue);
                                    }
                                }
                            } else {
                                if (!invalidStatusList.includes(mainLine.Status) || approvedStatusList.includes(mainLine.Status)) {
                                    discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                                }
                            }
                        }
                    }
                    if (crID !== null && crVersion !== null) {
                        await getCMSheet(crID, crVersion).then(res => {
                            discountedRevenueToFortude += Number(res.data.ServiceRevenueValue);
                            discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue);
                            discountedRevenueToFortude += Number(res.data.ContingencyValue);
                        })
                    }
                }
            }
        }

        //Net profit calculations
        netProfit += discountedRevenueToFortude; //Revenue to Fortude after discount
        netProfit = netProfit - totalCostRM; //Cost from Resource Deployment

        for (const item of CmsArray) {
            if (item.isConvertToActive === true && item.Type === 'Project') {

                const projectVersion = item.Version;
                const projectID = item.id;
                let projcurrencyId = 0;
                await getRegionCurrencyIntegrated(item.Project.BillingRegion).then(response => {
                    projcurrencyId = response.data.CurrencyId;
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });
                const proStartDate = new Date(item.Project.StartDate);
                const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                    projcurrencyId,
                    proStartDate.getMonth() + 1,
                    proStartDate.getFullYear()
                );
                if (projectID !== null && projectVersion !== null) {
                    await getCMSheet(projectID, projectVersion).then(res => {
                        netProfit = netProfit - Number(res.data.NonBillableTotalOtherExpenses); //Non-Billable Other Expenses from CMSs
                        netProfit = netProfit - Number(Number(res.data.TotalCostResourceAllocation ) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
                        //Sales Commission from CMSs
                        const discountVal = (   // Value discount / (premium)
                            (Number(res.data.TotalRevenueResourceAllocation ) + Number(res.data.ServiceRevenueValue ) + Number(res.data.ContingencyValue )) *
                            (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
                        const x = Math.pow(10, Number(2) + 1);
                        const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
                        const cmsRevenueAfterDiscount =
                            Number(res.data.TotalRevenueResourceAllocation ) + Number(res.data.ServiceRevenueValue) - Number(valueDiscount) + Number(res.data.ContingencyValue); // Revenue to company after discount
                        netProfit = netProfit - Number((cmsRevenueAfterDiscount * res.data.SalesCommissionPercentage / 100).toFixed(2)); //Sales Commission from CMSs
                    })
                }
            }
        }

        for (const cr of CmsArray) {
            if (cr.isConvertToActive === true && cr.Type === 'ChangeRequest') {

                const crVersion = cr.Version;
                const crID = cr.id;
                let currencyIdCR = 0;
                await getRegionCurrencyIntegrated(cr.ChangeRequest.BillingRegion).then(response => {
                    currencyIdCR = response.data.CurrencyId;
                })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                    });
                const crStartDate = new Date(cr.ChangeRequest.StartDate);
                const crRateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                    currencyIdCR,
                    crStartDate.getMonth() + 1,
                    crStartDate.getFullYear()
                );
                await getCMSheet(crID, crVersion).then(res => {
                    netProfit = netProfit - Number(res.data.NonBillableTotalOtherExpenses); //Non-Billable Other Expenses from CMSs
                    netProfit = netProfit - Number(Number(res.data.TotalCostResourceAllocation) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
                    // - Sales Commission from CMSs
                    const discountVal = (     // Value discount / (premium)
                        (Number(res.data.TotalRevenueResourceAllocation) + Number(res.data.ServiceRevenueValue) + Number(res.data.ContingencyValue)) *
                        (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
                    const x = Math.pow(10, Number(2) + 1);
                    const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
                    const cmsRevenueAfterDiscount =
                        Number(res.data.TotalRevenueResourceAllocation) + Number(res.data.ServiceRevenueValue) - Number(valueDiscount) + Number(res.data.ContingencyValue); // Revenue to company after discount
                    netProfit = netProfit - Number((cmsRevenueAfterDiscount * res.data.SalesCommissionPercentage / 100).toFixed(2)); //Sales Commission from CMSs
                })
            }
        }

        // TotalOngoingGrossProfit calculation
        // TotalOngoingGrossProfit = discountedRevenueToFortude - totalGrossCostProject;
        // TotalOngoingGrossProfit = TotalOngoingGrossProfit - totalGrossCostCr;

        TotalOngoingGrossProfit = discountedRevenueToFortude;
        console.log("TotalOngoingGrossProfit before :", TotalOngoingGrossProfit);

        for (const item of CmsArray) {
            if (item.isConvertToActive === true && item.Type === 'Project') { // Project
                const projectVersion = item.Version;
                const projectID = item.id;

                let projcurrencyId = 0;
                await getRegionCurrencyIntegrated(item.Project.BillingRegion).then(response => {
                    projcurrencyId = response.data.CurrencyId;
                })
                .catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

                const proStartDate = new Date(item.Project.StartDate);
                const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                    projcurrencyId,
                    proStartDate.getMonth() + 1,
                    proStartDate.getFullYear()
                );

                // Calculate on going gross profit
                if (projectID !== null && projectVersion !== null) {
                    await getCMSheet(projectID, projectVersion).then(res => {
                        TotalOngoingGrossProfit = TotalOngoingGrossProfit - totalGrossCostProject;
                        TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.NonBillableTotalOtherExpenses);

                        let costContingencyVal = Number(Number(res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
                        TotalOngoingGrossProfit = TotalOngoingGrossProfit - costContingencyVal;

                        TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.ServiceRevenueValue); 

                        console.log("Total totalGrossCostProject - Project :", totalGrossCostProject);
                        console.log("Total of Non-Billable Other Expenses from CMSs - Project :", Number(res.data.NonBillableTotalOtherExpenses));
                        console.log("Cost Contingency from CMSs % - Project :", res.data.ContingencyPercentage);
                        console.log("Cost Contingency from CMSs - Project :", costContingencyVal);
                        console.log("Service Revenue - Project :", Number(res.data.ServiceRevenueValue));
                    })
                }
            }
        }

        for (const cr of CmsArray) {
            if (cr.isConvertToActive === true && cr.Type === 'ChangeRequest') {

                const crVersion = cr.Version;
                const crID = cr.id;
                let currencyIdCR = 0;
                await getRegionCurrencyIntegrated(cr.ChangeRequest.BillingRegion).then(response => {
                    currencyIdCR = response.data.CurrencyId;
                }).catch(error => {
                    loggerService.writeLog(error, LOG_TYPES.ERROR);
                });

                const crStartDate = new Date(cr.ChangeRequest.StartDate);
                const crRateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                    currencyIdCR,
                    crStartDate.getMonth() + 1,
                    crStartDate.getFullYear()
                );
                
                await getCMSheet(crID, crVersion).then(res => {
                    TotalOngoingGrossProfit = TotalOngoingGrossProfit - totalGrossCostCr;
                    TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.NonBillableTotalOtherExpenses);

                    let costContingencyVal = Number(Number(res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
                    TotalOngoingGrossProfit = TotalOngoingGrossProfit - costContingencyVal;

                    TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.ServiceRevenueValue ); 

                    console.log("Total totalGrossCostCr - CR :", totalGrossCostCr);
                    console.log("Total of Non-Billable Other Expenses from CMSs - CR :", Number(res.data.NonBillableTotalOtherExpenses ));
                    console.log("Cost Contingency from CMSs % - CR :", res.data.ContingencyPercentage);
                    console.log("Cost Contingency from CMSs - CR :", costContingencyVal);
                    console.log("Service Revenue - CR :", Number(res.data.ServiceRevenueValue ));
                })
            }
        }
        console.log("TotalOngoingGrossProfit after :", TotalOngoingGrossProfit);

        // Est Project Duration
        if(CmsArray.length > 0){
            let startDates = [];
            let endDates = [];
            for(let cmSheet of CmsArray){
                let startDate = cmSheet.StartDateForDuration && cmSheet.StartDateForDuration !== null ? cmSheet.StartDateForDuration : null;
                if(startDate !== null){
                    startDates.push(startDate);
                }

                let endDate = cmSheet.EndDateForDuration && cmSheet.EndDateForDuration !== null ? cmSheet.EndDateForDuration : null;
                if(endDate !== null){
                    endDates.push(endDate);
                }
            }

            const minStartDate = (startDates.length > 0) ? (new Date(Math.min.apply(null, startDates))) : null;
            const maxEndDate = (endDates.length > 0) ? (new Date(Math.max.apply(null, endDates))) : null;
            calculatedValues.EstDurationStartDate = minStartDate !== null ? moment(minStartDate).format('MM/DD/YYYY') : '';
            calculatedValues.EstDurationEndDate = maxEndDate !== null ? moment(maxEndDate).format('MM/DD/YYYY') : '';
        }

        // verified
        calculatedValues.totalEffortRM = totalEffortRM;
        calculatedValues.totalEffortDaysCMS = totalEffortDaysCMS;
        calculatedValues.discountedRevenueToFortude = discountedRevenueToFortude;
        calculatedValues.discountedRevenueToFortudeCMS = discountedRevenueToFortudeCMS;

        // Gross - Ongoing CMS Value / Ongoing CMS Value percentage
        calculatedValues.TotalOngoingGrossProfit = TotalOngoingGrossProfit;

        // Gross Profit / Gross Profit Marging %
        calculatedValues.TotalGrossProfit = TotalGrossProfit;

        // Gross - Variance value / percentage
        let GrossVeriance = ((calculatedValues.TotalOngoingGrossProfit ? Number(Math.abs(calculatedValues.TotalOngoingGrossProfit)) : 0.00) - (calculatedValues.TotalGrossProfit ? Number(Math.abs(calculatedValues.TotalGrossProfit)) : 0.00)).toFixed(2);
        calculatedValues.GrossVeriance = GrossVeriance;

        calculatedValues.netProfit = netProfit;
        calculatedValues.netProfitLossCMS = netProfitLossCMS;





        calculatedValues.totalRevenueRM = totalRevenueRM;
        calculatedValues.totalCostRM = totalCostRM;
        calculatedValues.fixedPriceEffortOngoing = fixedPriceEffortOngoing;
        calculatedValues.fixedPriceEffortApproved = fixedPriceEffortApproved;
        calculatedValues.TMEffortOngoing = TMEffortOngoing;
        calculatedValues.TMEffortOngoingApproved = TMEffortApproved;
        
        //calculatedValues.estDuration = estDuration;
        calculatedValues.totalCostCMS = totalCostCMS;

        console.log("=================================================================");
        console.log("totalEffortRM :", calculatedValues.totalEffortRM);
        console.log("totalEffortDaysCMS :", calculatedValues.totalEffortDaysCMS);
        console.log("discountedRevenueToFortude :", calculatedValues.discountedRevenueToFortude);
        console.log("discountedRevenueToFortudeCMS :", calculatedValues.discountedRevenueToFortudeCMS);
        console.log("TotalOngoingGrossProfit :", calculatedValues.TotalOngoingGrossProfit);
        console.log("TotalGrossProfit :", calculatedValues.TotalGrossProfit);
        console.log("GrossVeriance :", calculatedValues.GrossVeriance);
        console.log("netProfit :", calculatedValues.netProfit);
        console.log("netProfitLossCMS :", calculatedValues.netProfitLossCMS);
        console.log("=================================================================");

        
        GrossProfitMargin = calculatedValues.discountedRevenueToFortudeCMS ? formatNumber(Number(parseFloat(((calculatedValues.TotalGrossProfit / calculatedValues.discountedRevenueToFortudeCMS) * 100)))) + '%' : '0.00%';
        calculatedValues.GrossProfitMargin = GrossProfitMargin;


        await this.setState({
            calculatedValues: calculatedValues
        });
    }

    // Actual Calculations
    handleActualCalculations = async () => {
        let projIds = [];
        let projAndCrStatistics = [];
        let calculatedActValues = {};

        let fixedEffort = 0;
        let fixedLineApproveTimeSum = 0;
        let fixedLineFutureAllocSum = 0;
        let tmEffort = 0;
        let tmLineApproveTimeSum = 0;
        let tmLineFutureAllocSum = 0;
        let billEffort = 0;
        let billApproveTimeSum = 0;
        let billLineFutureAllocSum = 0;
        let nonBillEffort = 0;
        let nbApproveTimeSum = 0;
        let nbLineFutureAllocSum = 0;
        let contractedEffort = 0;
        let contractedEffAct = 0;
        let contractedEffFt = 0;
        let revenue = 0;
        let avgRatePerDay = 0;
        let totalCost = 0;
        let directCost = 0;
        let grossMargin = 0;
        let grossMarginPercentage = 0;
        let netMargin = 0;
        let netMarginPercentage = 0;
        let estOpeForCustomerAct = 0;
        let nbExpensesAct = 0;
        let totContingencyAct = 0;
        let totContingencyPercentageAct = 0;
        let partnerCommission = 0;
        let totValueToCustomer = 0;
        let earliestAllocDate = null;
        let furthestAllocDate = null;

         // For variance
        let conEffVariance = 0;
        let conEffVarPercentage = 0;
        let billVariance = 0;
        let billVarPercentage = 0;
        let nbVariance = 0;
        let nbVarPercentage = 0;
        let revVariance = 0;
        let revVarPercentage = 0;
        let avgRateVariance = 0;
        let avgRateVarPercentage = 0;
        let grossMarginVariance = 0;
        let grossMarginVarPercentage = 0;
        let netMarginVariance = 0;
        let netMarginVarPercentage = 0;
        let totValToCustomerVariance = 0;
        let totValToCustomerVarPercentage = 0;
        let estOpeVariance = 0;
        let estOpeVarPercentage = 0;
        let partnerCommVariance = 0;
        let partnerCommVarPercentage = 0;
        let nbExpensesVariance = 0;
        let nbExpensesVarPercentage = 0;
        let totContingencyVariance = 0;
        let totContingencyVarPercentage = 0;

        let cmsArray = this.state.projectsAndResources;
        let resourceMix = this.state.resourceMixOngoing;
        let approvedCmsValues = this.state.calculatedValues;
        let consolidatedCMSValues = this.state.consolidatedCMSList[0];

        if(cmsArray.length > 0){
            cmsArray.forEach(el => {
                if(el.Type && el.Type == 'Project'){ // Project
                    let obj = {
                        projectId: (el.Project && el.Project !== null && el.Project.id) ? (el.Project.id) : null,
                        crId: null
                    };
                    projIds.push(obj);
                }
                if(el.Type && el.Type == 'ChangeRequest'){ // CR
                    let obj = {
                        projectId: (el.ChangeRequest && el.ChangeRequest !== null && el.ChangeRequest.ProjectId) ? (el.ChangeRequest.ProjectId) : null,
                        crId: (el.ChangeRequest && el.ChangeRequest !== null && el.ChangeRequest.id) ? (el.ChangeRequest.id) : null
                    };
                    projIds.push(obj);
                }
            });

            // Get project and cr statistics
            await getProjectAndCrStatistics(projIds).then(response => {
                if(response.data && response.data.length > 0){
                    projAndCrStatistics = response.data;
                }
            }).catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            })

            if(cmsArray.length > 0){
                for(let cmSheet of cmsArray){
                    if(cmSheet !== null){
                        let cms = cmSheet;      // project or cr cms
                        let project = null;     // project or cr
                        let tempStats = null;
                        let projStat = null;    // project or cr statistics
                        let tempResMix = null;
                        let projResMix = null;  // project or cr resource mix

                        let tmBillAppTimeRev = 0;
                        let tmFtAllocRev = 0;
                        let fixedLineTotCost = 0;
                        let tmLineTotCost = 0;
                        let fixedLineDirCost = 0;
                        let tmLineDirCost = 0;

                        if(cms.Type && cms.Type == 'Project'){ // Project
                            project = (cms.Project && cms.Project !== null) ? (cms.Project) : null;

                            tempStats = (projAndCrStatistics.length > 0 && project !== null) ? 
                                        (projAndCrStatistics.filter(el => el.ProjectId == project.id && el.CrId == null)) : null;
                            projStat = (tempStats !== null && tempStats.length > 0) ? tempStats[0] : null;

                            tempResMix = (resourceMix.length > 0 && project !== null) ? 
                                         (resourceMix.filter(el => el.Type == 'Project' && el.ProjectId == project.id)) : null;
                            projResMix = (tempResMix !== null && tempResMix.length > 0) ? tempResMix : null;
                        }
                        else if(cms.Type && cms.Type == 'ChangeRequest'){ // CR
                            project = (cms.ChangeRequest && cms.ChangeRequest !== null) ? (cms.ChangeRequest) : null;

                            tempStats = (projAndCrStatistics.length > 0 && project !== null) ? 
                                        (projAndCrStatistics.filter(el => el.ProjectId == project.ProjectId && el.CrId == project.id)) : null;
                            projStat = (tempStats !== null && tempStats.length > 0) ? tempStats[0] : null;

                            tempResMix = (resourceMix.length > 0 && project !== null) ? 
                                         (resourceMix.filter(el => el.Type == 'ChangeRequest' && el.ProjectId == project.id)) : null;
                            projResMix = (tempResMix !== null && tempResMix.length > 0) ? tempResMix : null;
                        }
                        
                        if(projStat && projStat !== null){
                            furthestAllocDate = (furthestAllocDate !== null && projStat.FurthestAllocDate && projStat.FurthestAllocDate !== null) ? 
                                                ( (furthestAllocDate > projStat.FurthestAllocDate) ? (furthestAllocDate) : (projStat.FurthestAllocDate) ) : 
                                                ( (furthestAllocDate !== null) ? (furthestAllocDate) : 
                                                ( (projStat.FurthestAllocDate && projStat.FurthestAllocDate !== null) ? (projStat.FurthestAllocDate) : (null) ) );
                    
                            earliestAllocDate = (earliestAllocDate !== null && projStat.EarliestAllocDate && projStat.EarliestAllocDate !== null) ? 
                                                ( (earliestAllocDate < projStat.EarliestAllocDate) ? (earliestAllocDate) : (projStat.EarliestAllocDate) ) : 
                                                ( (earliestAllocDate !== null) ? (earliestAllocDate) : 
                                                ( (projStat.EarliestAllocDate && projStat.EarliestAllocDate !== null) ? (projStat.EarliestAllocDate) : (null) ) );

                            // For Fixed Price Effort
                            let fxdLineAppTimeSum = (projStat.FixedLineApproveTimeSum && projStat.FixedLineApproveTimeSum !== null ? Number(projStat.FixedLineApproveTimeSum) : 0);
                            let fxdLineFtAllocSum = (projStat.FixedLineFutureAllocSum && projStat.FixedLineFutureAllocSum !== null ? Number(projStat.FixedLineFutureAllocSum) : 0);
                            fixedEffort += ((fxdLineAppTimeSum / 8) + (fxdLineFtAllocSum));
                            fixedLineApproveTimeSum += (fxdLineAppTimeSum / 8);
                            fixedLineFutureAllocSum += (fxdLineFtAllocSum);
                            
                            // For T&M Effort
                            let tmLineAppTimeSum = (projStat.TMLineApproveTimeSum && projStat.TMLineApproveTimeSum !== null ? Number(projStat.TMLineApproveTimeSum) : 0);
                            let tmLineFtAllocSum = (projStat.TMLineFutureAllocSum && projStat.TMLineFutureAllocSum !== null ? Number(projStat.TMLineFutureAllocSum) : 0);
                            tmEffort += ((tmLineAppTimeSum / 8) + (tmLineFtAllocSum));
                            tmLineApproveTimeSum += (tmLineAppTimeSum / 8);
                            tmLineFutureAllocSum += (tmLineFtAllocSum);

                             // For Billable Effort
                            let billAppTimeSum = (projStat.BillApproveTimeSum && projStat.BillApproveTimeSum !== null ? Number(projStat.BillApproveTimeSum) : 0);
                            let billLineFtAllocSum = (projStat.BillLineFutureAllocSum && projStat.BillLineFutureAllocSum !== null ? Number(projStat.BillLineFutureAllocSum) : 0);
                            billEffort += ((billAppTimeSum / 8) + (billLineFtAllocSum));
                            billApproveTimeSum += (billAppTimeSum / 8);
                            billLineFutureAllocSum += (billLineFtAllocSum);

                            // For NonBillable Effort
                            let nbAppTimeSum = (projStat.NBApproveTimeSum && projStat.NBApproveTimeSum !== null ? Number(projStat.NBApproveTimeSum) : 0);
                            let nbLineFtAllocSum = (projStat.NBLineFutureAllocSum && projStat.NBLineFutureAllocSum !== null ? Number(projStat.NBLineFutureAllocSum) : 0);
                            nonBillEffort += ((nbAppTimeSum / 8) + (nbLineFtAllocSum));
                            nbApproveTimeSum += (nbAppTimeSum / 8);
                            nbLineFutureAllocSum += (nbLineFtAllocSum);

                            // For Revenue
                            tmBillAppTimeRev = projStat.TMLineBillApproveTimeDRSum && projStat.TMLineBillApproveTimeDRSum !== null ? 
                                               Number(projStat.TMLineBillApproveTimeDRSum) : 0;
                            tmFtAllocRev = projStat.TMLineBillFutureAllocDRSum && projStat.TMLineBillFutureAllocDRSum !== null ? 
                                           Number(projStat.TMLineBillFutureAllocDRSum) : 0;

                            // For Total Cost
                            fixedLineTotCost = (projStat.FixedLineCapValDCSum && projStat.FixedLineCapValDCSum !== null ? Number(projStat.FixedLineCapValDCSum) : 0) + 
                                               (projStat.FixedLineCapValOHSum && projStat.FixedLineCapValOHSum !== null ? Number(projStat.FixedLineCapValOHSum) : 0) + 
                                               (projStat.FixedLineFutureAllocDCSum && projStat.FixedLineFutureAllocDCSum !== null ? Number(projStat.FixedLineFutureAllocDCSum) : 0) + 
                                               (projStat.FixedLineFutureAllocOHSum && projStat.FixedLineFutureAllocOHSum !== null ? Number(projStat.FixedLineFutureAllocOHSum) : 0);

                            tmLineTotCost = (projStat.TMLineCapValDCSum && projStat.TMLineCapValDCSum !== null ? Number(projStat.TMLineCapValDCSum) : 0) + 
                                            (projStat.TMLineCapValOHSum && projStat.TMLineCapValOHSum !== null ? Number(projStat.TMLineCapValOHSum) : 0) + 
                                            (projStat.TMLineFutureAllocDCSum && projStat.TMLineFutureAllocDCSum !== null ? Number(projStat.TMLineFutureAllocDCSum) : 0) + 
                                            (projStat.TMLineFutureAllocOHSum && projStat.TMLineFutureAllocOHSum !== null ? Number(projStat.TMLineFutureAllocOHSum) : 0);

                            // For Direct Cost
                            fixedLineDirCost = (projStat.FixedLineCapValDCSum && projStat.FixedLineCapValDCSum !== null ? Number(projStat.FixedLineCapValDCSum) : 0) + 
                                               (projStat.FixedLineFutureAllocDCSum && projStat.FixedLineFutureAllocDCSum !== null ? Number(projStat.FixedLineFutureAllocDCSum) : 0);

                            tmLineDirCost = (projStat.TMLineCapValDCSum && projStat.TMLineCapValDCSum !== null ? Number(projStat.TMLineCapValDCSum) : 0) + 
                                            (projStat.TMLineFutureAllocDCSum && projStat.TMLineFutureAllocDCSum !== null ? Number(projStat.TMLineFutureAllocDCSum) : 0);
                        }

                        // Revenue & costs calculations
                        if (cms !== null && project !== null) {
                            let projRevenue = 0;
                            let partnerCommProj = 0;
                            let serviceRev = cms.ServiceRevenueValue && cms.ServiceRevenueValue !== null ? Number(cms.ServiceRevenueValue) : 0;
                            let discountPercentage = cms.DiscountPercentage && cms.DiscountPercentage !== null ? (Number(cms.DiscountPercentage) / 100) : 0;
                            let nonBillExpenses = ((cms.Status && cms.Status == 'APPROVED') && (cms.NonBillableTotalOtherExpenses && cms.NonBillableTotalOtherExpenses !== null)) ?
                                                  (Number(cms.NonBillableTotalOtherExpenses)) : 0;
                            let salesComPercentage = ((cms.Status && cms.Status == 'APPROVED') && (cms.SalesCommissionPercentage && cms.SalesCommissionPercentage !== null)) ?
                                                     (Number(cms.SalesCommissionPercentage) / 100) : 0;
                            let paymentMethod = (cms.Type && cms.Type == 'Project') ? 
                                                (project.PaymentMethod && project.PaymentMethod !== null ? project.PaymentMethod : null) : 
                                                (project.PaymentMethodId && project.PaymentMethodId !== null ? project.PaymentMethodId : null);

                            let cmsType = cms.Type && cms.Type == 'Project' ? 'projCMS' : 'crCMS';
                            const partnerCommPercentage = await this.retrievePartnerCommission(project, cmsType);
                    
                            // Revenue To Company & Partner Commission
                            if (paymentMethod !== null && paymentMethod == 1) { //Fixed
                                // Service revenue
                                const serviceRevenue = serviceRev.toFixed(2);
                                // Contingency
                                const contingencyValue = (Number(cms.TotalRevenueResourceAllocation) + Number(serviceRevenue)) * (Number(cms.ContingencyPercentage) / 100);
                                // Value discount / (premium)
                                const discountVal = ((Number(cms.TotalRevenueResourceAllocation) + Number(serviceRevenue) + Number(contingencyValue)) * (discountPercentage)).toFixed(2);
                                const x = Math.pow(10, Number(2) + 1);
                                const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));

                                if (project.StatusConvertedToActive && project.StatusConvertedToActive === true) {
                                    // Revenue to company after discount
                                    const revenueAfterDiscount = Number(cms.TotalRevenueResourceAllocation) + Number(serviceRevenue) - Number(valueDiscount) + Number(contingencyValue);
                                    projRevenue += revenueAfterDiscount;
                                }
                                
                                // Partner Commission
                                const partnerCommPercentageValue = (partnerCommPercentage && partnerCommPercentage !== null) ? (Number(partnerCommPercentage) / 100) : 0;
                                const partnerCommission = ((Number(cms.TotalRevenueResourceAllocation) + Number(serviceRevenue) - Number(valueDiscount) + Number(contingencyValue)) / (1 - Number(partnerCommPercentageValue))) - 
                                                          (Number(cms.TotalRevenueResourceAllocation) - Number(valueDiscount) + Number(contingencyValue));
                                partnerCommProj += partnerCommission;
                            }
                            if (paymentMethod !== null && paymentMethod == 2) { //T&M
                                // Service revenue
                                const serviceRevenue = serviceRev.toFixed(2);
                                // Contingency
                                const contingencyValue = (Number(cms.TotalRevenueResourceAllocation) + Number(serviceRevenue)) * (Number(cms.ContingencyPercentage) / 100);
                                // Value discount / (premium)
                                const discountVal = ((Number(cms.TotalRevenueResourceAllocation) + Number(serviceRevenue) + Number(contingencyValue)) * (discountPercentage)).toFixed(2);
                                const x = Math.pow(10, Number(2) + 1);
                                const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
                                projRevenue += ((tmBillAppTimeRev + tmFtAllocRev + Number(serviceRevenue)) * (1 - discountPercentage));

                                // Partner Commission
                                const partnerCommPercentageValue = (partnerCommPercentage && partnerCommPercentage !== null) ? (Number(partnerCommPercentage) / 100) : 0;
                                const partnerCommission = ((tmBillAppTimeRev + tmFtAllocRev + Number(serviceRevenue) - Number(valueDiscount) + Number(contingencyValue)) / (1 - Number(partnerCommPercentageValue))) - 
                                                          (Number(cms.TotalRevenueResourceAllocation) - Number(valueDiscount) + Number(contingencyValue));
                                partnerCommProj += partnerCommission;
                            }
                            if (paymentMethod !== null && paymentMethod == 3) { //Hybrid
                                let revForFixed = 0;
                                let fixedRevForPartnerComm = 0;
                                let tmRevForPartnerComm = 0;

                                if (projResMix.length > 0) {
                                    for (let mainLine of projResMix) {
                                        // Revenue for Fixed price lines
                                        // Revenue for Partner Commission for Fixed price lines
                                        if (mainLine.PaymentMethod && mainLine.PaymentMethod == 1) {
                                            if (mainLine.ResourceRequestSubLine.length > 0) {
                                                for (let subLine of mainLine.ResourceRequestSubLine) {
                                                    if (cms.Status && cms.Status == 'APPROVED' && subLine.Status && (subLine.Status == 'COMPLETE' || subLine.Status == 'CLOSE')) {
                                                        revForFixed += (subLine.Revenue && subLine.Revenue !== null ?  Number(subLine.Revenue) : 0);
                                                    }
                                                    
                                                    fixedRevForPartnerComm += (subLine.Revenue && subLine.Revenue !== null ?  Number(subLine.Revenue) : 0);
                                                }
                                            }
                                            else {
                                                if (cms.Status && cms.Status == 'APPROVED' && mainLine.Status && (mainLine.Status == 'COMPLETE' || mainLine.Status == 'CLOSE')) {
                                                    revForFixed += (mainLine.TotalRevenue && mainLine.TotalRevenue !== null ? Number(mainLine.TotalRevenue) : 0);
                                                }

                                                fixedRevForPartnerComm += (mainLine.TotalRevenue && mainLine.TotalRevenue !== null ? Number(mainLine.TotalRevenue) : 0);
                                            }
                                        }

                                        // Revenue for Partner Commission for T&M lines
                                        if (mainLine.PaymentMethod && mainLine.PaymentMethod == 2) {
                                            if (mainLine.ResourceRequestSubLine.length > 0) {
                                                for (let subLine of mainLine.ResourceRequestSubLine) {
                                                    tmRevForPartnerComm += (subLine.Revenue && subLine.Revenue !== null ?  Number(subLine.Revenue) : 0);
                                                }
                                            }
                                            else {
                                                tmRevForPartnerComm += (mainLine.TotalRevenue && mainLine.TotalRevenue !== null ? Number(mainLine.TotalRevenue) : 0);
                                            }
                                        }
                                    }
                                }
                    
                                // Revenue for T&M lines
                                let revForTM = (tmBillAppTimeRev + tmFtAllocRev + serviceRev);
                                projRevenue += ((revForFixed + revForTM) * (1 - discountPercentage));

                                // Partner Commission Percentage
                                const partnerCommPercentageValue = (partnerCommPercentage && partnerCommPercentage !== null) ? (Number(partnerCommPercentage) / 100) : 0;
                                // Service Revenue
                                const serviceRevenue = serviceRev.toFixed(2);

                                // Partner Commission for Fixed lines
                                // Contingency
                                const contingencyValueFxd = (Number(fixedRevForPartnerComm) + Number(serviceRevenue)) * (Number(cms.ContingencyPercentage) / 100);
                                // Value discount / (premium)
                                const discountValFxd = ((Number(fixedRevForPartnerComm) + Number(serviceRevenue) + Number(contingencyValueFxd)) * (discountPercentage)).toFixed(2);
                                const xValueFxd = Math.pow(10, Number(2) + 1);
                                const valueDiscountFxd = Number((Number(discountValFxd) + (1 / xValueFxd)).toFixed(2));

                                const fixedPartnerCommission = ( (Number(fixedRevForPartnerComm) + Number(serviceRevenue) - Number(valueDiscountFxd) + Number(contingencyValueFxd)) / (1 - Number(partnerCommPercentageValue)) ) - 
                                                             (Number(fixedRevForPartnerComm) - Number(valueDiscountFxd) + Number(contingencyValueFxd));

                                // Partner Commission for T&M lines
                                // Contingency
                                const contingencyValueTM = (Number(tmRevForPartnerComm) + Number(serviceRevenue)) * (Number(cms.ContingencyPercentage) / 100);
                                // Value discount / (premium)
                                const discountValTM = ((Number(tmRevForPartnerComm) + Number(serviceRevenue) + Number(contingencyValueTM)) * (discountPercentage)).toFixed(2);
                                const xValueTM = Math.pow(10, Number(2) + 1);
                                const valueDiscountTM = Number((Number(discountValTM) + (1 / xValueTM)).toFixed(2));

                                const tmPartnerCommission = ( (tmBillAppTimeRev + tmFtAllocRev + Number(serviceRevenue) - Number(valueDiscountTM) + Number(contingencyValueTM)) / (1 - Number(partnerCommPercentageValue)) ) - 
                                                          (Number(tmRevForPartnerComm) - Number(valueDiscountTM) + Number(contingencyValueTM));
    
                                partnerCommProj += (fixedPartnerCommission + tmPartnerCommission);
                            }

                            revenue += projRevenue;
                            partnerCommission += partnerCommProj;
                    
                            // Total Cost
                            totalCost += ((fixedLineTotCost) + (tmLineTotCost) + (nonBillExpenses) + (projRevenue * salesComPercentage));
                            // Direct Cost
                            directCost += ((fixedLineDirCost) + (tmLineDirCost) + (nonBillExpenses));
                        }
                    }
                }
            }

            // Est Project Duration
            let startDate = earliestAllocDate !== null ? moment(new Date(earliestAllocDate)).format('MM/DD/YYYY') : '';
            let endDate = furthestAllocDate !== null ? moment(new Date(furthestAllocDate)).format('MM/DD/YYYY') : '';

            // Contracted Effort(Days), Avg Rate Per Day
            contractedEffort = Number(fixedEffort.toFixed(2)) + Number(tmEffort.toFixed(2));
            contractedEffAct = Number(fixedLineApproveTimeSum.toFixed(2)) + Number(tmLineApproveTimeSum.toFixed(2));
            contractedEffFt = Number(fixedLineFutureAllocSum.toFixed(2)) + Number(tmLineFutureAllocSum.toFixed(2));
            avgRatePerDay = contractedEffort !== 0 ? (Number(revenue.toFixed(2)) / contractedEffort) : 0;

            // Gross Margin, Gross Margin Percentage
            grossMargin = Number(revenue.toFixed(2)) - Number(directCost.toFixed(2));
            grossMarginPercentage = revenue !== 0 ? (Number(grossMargin.toFixed(2)) / Number(revenue.toFixed(2))) : 0;

            // Net Margin, Net Margin Percentage
            netMargin = Number(revenue.toFixed(2)) - Number(totalCost.toFixed(2));
            netMarginPercentage = revenue !== 0 ? (Number(netMargin.toFixed(2)) / Number(revenue.toFixed(2))) : 0;

            // Est OPE
            estOpeForCustomerAct = (consolidatedCMSValues !== null && consolidatedCMSValues.estimatedopecustomer && consolidatedCMSValues.estimatedopecustomer !== null) ? 
                                   (Number(consolidatedCMSValues.estimatedopecustomer)) : 0;
            // Nonbillable expenses
            nbExpensesAct = (consolidatedCMSValues !== null && consolidatedCMSValues.nonbillabletotalotherexpenses && consolidatedCMSValues.nonbillabletotalotherexpenses !== null) ? 
                            (Number(consolidatedCMSValues.nonbillabletotalotherexpenses)) : 0;
            // Total contingency
            totContingencyAct = (consolidatedCMSValues !== null && consolidatedCMSValues.contingencyvalue && consolidatedCMSValues.contingencyvalue !== null) ? 
                                (Number(consolidatedCMSValues.contingencyvalue)) : 0;
            totContingencyPercentageAct = (consolidatedCMSValues !== null && consolidatedCMSValues.contingencyvalue && consolidatedCMSValues.contingencyvalue !== null && 
                                          consolidatedCMSValues.totalrevenueresourceallocation && consolidatedCMSValues.totalrevenueresourceallocation !== null && consolidatedCMSValues.totalrevenueresourceallocation > 0) ? 
                                          (Number(consolidatedCMSValues.contingencyvalue) / Number(consolidatedCMSValues.totalrevenueresourceallocation)) * 100 : 0;
            // Total value to customer
            totValueToCustomer = Number(revenue) + Number(estOpeForCustomerAct) + Number(partnerCommission);

            // CMS Values - For Variance Calculations
            let contractedEffCms = (consolidatedCMSValues !== null && consolidatedCMSValues.totaleffortdaysresourceallocation && consolidatedCMSValues.totaleffortdaysresourceallocation !== null) ? 
                                   (Number(consolidatedCMSValues.totaleffortdaysresourceallocation).toFixed(2)) : 0;
            let revenueCms = (consolidatedCMSValues !== null && consolidatedCMSValues.projectrevenueafterdiscount && consolidatedCMSValues.projectrevenueafterdiscount !== null) ? 
                             (Number(consolidatedCMSValues.projectrevenueafterdiscount).toFixed(2)) : 0;
            let avgRateCms = (consolidatedCMSValues !== null && 
                              consolidatedCMSValues.totaleffortdaysresourceallocation && consolidatedCMSValues.totaleffortdaysresourceallocation !== null && consolidatedCMSValues.totaleffortdaysresourceallocation > 0 && 
                              consolidatedCMSValues.projectrevenueafterdiscount && consolidatedCMSValues.projectrevenueafterdiscount !== null) ? 
                             (Number(consolidatedCMSValues.projectrevenueafterdiscount) / Number(consolidatedCMSValues.totaleffortdaysresourceallocation)).toFixed(2) : 0;
            let grossProfitCms = (consolidatedCMSValues !== null && consolidatedCMSValues.grossprofitfromresourcedeployment && consolidatedCMSValues.grossprofitfromresourcedeployment !== null) ? 
                                 (Number(consolidatedCMSValues.grossprofitfromresourcedeployment).toFixed(2)) : 0;
            let netProfitCms = (consolidatedCMSValues !== null && consolidatedCMSValues.netprofitloss && consolidatedCMSValues.netprofitloss !== null) ? 
                               (Number(consolidatedCMSValues.netprofitloss).toFixed(2)) : 0;
            let totValueToCustomerCms = (consolidatedCMSValues !== null && consolidatedCMSValues.totalprojectvalue && consolidatedCMSValues.totalprojectvalue !== null) ? 
                                        (Number(consolidatedCMSValues.totalprojectvalue).toFixed(2)) : 0;
            let estOpeCms = (consolidatedCMSValues !== null && consolidatedCMSValues.estimatedopecustomer && consolidatedCMSValues.estimatedopecustomer !== null) ? 
                            (Number(consolidatedCMSValues.estimatedopecustomer).toFixed(2)) : 0;
            let partnerCommCms = (consolidatedCMSValues !== null && consolidatedCMSValues.partnercommission && consolidatedCMSValues.partnercommission !== null) ? 
                                 (Number(consolidatedCMSValues.partnercommission).toFixed(2)) : 0;
            let nbExpensesCms = (consolidatedCMSValues !== null && consolidatedCMSValues.nonbillabletotalotherexpenses && consolidatedCMSValues.nonbillabletotalotherexpenses !== null) ? 
                                (Number(consolidatedCMSValues.nonbillabletotalotherexpenses).toFixed(2)) : 0;
            let totContingencyCms = (consolidatedCMSValues !== null && consolidatedCMSValues.contingencyvalue && consolidatedCMSValues.contingencyvalue !== null) ? 
                                    (Number(consolidatedCMSValues.contingencyvalue).toFixed(2)) : 0;

            // Variance Calculations
            conEffVariance = Number(contractedEffort.toFixed(2)) - Number(contractedEffCms);
            conEffVarPercentage = (Number(contractedEffCms) !== 0) ? ((Number(conEffVariance) / Number(contractedEffCms)) * 100) : 0;
            billVariance = Number(billEffort.toFixed(2)) - 0;
            billVarPercentage = 0 ;
            nbVariance = Number(nonBillEffort.toFixed(2)) - 0;
            nbVarPercentage = 0;
            revVariance = Number(revenue.toFixed(2)) - Number(revenueCms);
            revVarPercentage = Number(revenueCms) !== 0 ? ((Number(revVariance) / Number(revenueCms)) * 100) : 0;
            avgRateVariance = Number(avgRatePerDay.toFixed(2)) - Number(avgRateCms);
            avgRateVarPercentage = Number(avgRateCms) !== 0 ? ((Number(avgRateVariance) / Number(avgRateCms)) * 100) : 0;
            grossMarginVariance = Number(grossMargin.toFixed(2)) - Number(grossProfitCms);
            grossMarginVarPercentage = Number(grossProfitCms) !== 0 ? ((Number(grossMarginVariance) / Number(grossProfitCms)) * 100) : 0;
            netMarginVariance = Number(netMargin.toFixed(2)) - Number(netProfitCms);
            netMarginVarPercentage = Number(netProfitCms) !== 0 ? ((Number(netMarginVariance) / Number(netProfitCms)) * 100) : 0;
            totValToCustomerVariance = Number(totValueToCustomer.toFixed(2)) - Number(totValueToCustomerCms);
            totValToCustomerVarPercentage = (Number(totValueToCustomerCms) !== 0) ? ( (Number(totValToCustomerVariance) / Number(totValueToCustomerCms)) * 100 ) : 0;
            estOpeVariance = Number(estOpeForCustomerAct.toFixed(2)) - Number(estOpeCms);
            estOpeVarPercentage = (Number(estOpeCms) !== 0) ? ( (Number(estOpeVariance) / Number(estOpeCms)) * 100 ) : 0;
            partnerCommVariance = Number(partnerCommission.toFixed(2)) - Number(partnerCommCms);
            partnerCommVarPercentage = (Number(partnerCommCms) !== 0) ? ( (Number(partnerCommission) / Number(partnerCommCms)) * 100 ) : 0;
            nbExpensesVariance = Number(nbExpensesAct.toFixed(2)) - Number(nbExpensesCms);
            nbExpensesVarPercentage = (Number(nbExpensesCms) !== 0) ? ( (Number(nbExpensesVariance) / Number(nbExpensesCms)) * 100 ) : 0;
            totContingencyVariance = Number(totContingencyAct.toFixed(2)) - Number(totContingencyCms);
            totContingencyVarPercentage = (Number(totContingencyCms) !== 0) ? ( (Number(totContingencyVariance) / Number(totContingencyCms)) * 100 ) : 0;

            calculatedActValues = {
                EstDurationStartDate: startDate,
                EstDurationEndDate: endDate,
                FixedEffort: fixedEffort,
                FixedEffortAct: fixedLineApproveTimeSum,
                FixedEffortFt: fixedLineFutureAllocSum,
                TMEffort: tmEffort,
                TMEffortAct: tmLineApproveTimeSum,
                TMEffortFt: tmLineFutureAllocSum,
                ContractedEffort: contractedEffort * 8,
                ContractedEffortDays: contractedEffort,
                ContractedEffortAct: contractedEffAct,
                ContractedEffortFt: contractedEffFt,
                Billable: billEffort,
                BillApproveTimeSum: billApproveTimeSum,
                BillLineFutureAllocSum: billLineFutureAllocSum,
                NonBillable: nonBillEffort,
                NBApproveTimeSum: nbApproveTimeSum,
                NBLineFutureAllocSum: nbLineFutureAllocSum,
                Revenue: revenue,
                AvgRatePerDay: avgRatePerDay,
                GrossMargin: grossMargin,
                GrossPercentage: grossMarginPercentage,
                NetMargin: netMargin,
                NetPercentage: netMarginPercentage,
                EstOpeForCustomerAct: estOpeForCustomerAct,
                NbExpensesAct: nbExpensesAct,
                TotalContingencyAct: totContingencyAct,
                TotalContingencyPercentageAct: totContingencyPercentageAct,
                PartnerCommissionAct: partnerCommission,
                TotalValueToCustomerAct: totValueToCustomer,

                // Variance
                ConEffVariance: conEffVariance,
                ConEffVarPercentage: conEffVarPercentage,
                BillVariance: billVariance,
                BillVarPercentage: billVarPercentage,
                NBVariance: nbVariance,
                NBVarPercentage: nbVarPercentage,
                RevVariance: revVariance,
                RevVarPercentage: revVarPercentage,
                AvgRateVariance: avgRateVariance,
                AvgRateVarPercentage: avgRateVarPercentage,
                GrossMarginVariance: grossMarginVariance,
                GrossMarginVarPercentage: grossMarginVarPercentage,
                NetMarginVariance: netMarginVariance,
                NetMarginVarPercentage: netMarginVarPercentage,
                TotalValToCustomerVariance: totValToCustomerVariance,
                TotalValToCustomerVarPercentage: totValToCustomerVarPercentage,
                EstOpeVariance: estOpeVariance,
                EstOpeVarPercentage: estOpeVarPercentage,
                PartnerCommVariance: partnerCommVariance,
                PartnerCommVarPercentage: partnerCommVarPercentage,
                NbExpensesVariance: nbExpensesVariance,
                NbExpensesVarPercentage: nbExpensesVarPercentage,
                TotalContingencyVariance: totContingencyVariance,
                TotalContingencyVarPercentage: totContingencyVarPercentage
            };

            this.setState({
                calculatedActValues: calculatedActValues
            });
        }
    };

    retrievePartnerCommission = async (project, cmsType) => {
        let partner = 0;
        if (cmsType == 'crCMS') {
            partner = (project.PartnerId && project.PartnerId !== null) ? project.PartnerId : 0;
        } else {
            partner = (project.Partner && project.Partner !== null) ? project.Partner : 0;
        }
    
        let partnerCommision = 0;
        if (partner > 0) {
            partnerCommision = await getPartnerCommission(partner)
            .then(res => {
                if (res.data && res.data.CommissionPercentage) {
                    return res.data.CommissionPercentage;
                }
                return 0;
            })
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
                return 0;
            });
        }
        return partnerCommision;
    };

    calculateNewEstimatedEndDate = async (workingDays, startDate, countryId) => {
        let startDateObj = new Date(startDate);
        let endDate;

        // estimate end date
        const noOfWorkingDays = workingDays;

        let totalDays = 0;
        let workingDaysCount = 0;

        let holidays = await getHolidaysIntegrated(countryId, startDateObj.getFullYear()).then(
            res => {
                if (res && res.data) {
                    return res.data;
                }
                return [];
            }
        )
            .catch(error => {
                loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

        if (holidays && holidays.length === 0) {
            console.log('Holidays not found for ' + startDateObj.getFullYear());
        } else if (!holidays) {
            holidays = [];
        }

        endDate = new Date(startDate);
        let currentYear = startDateObj.getFullYear();
        let isHoliday = false;

        while (workingDaysCount < noOfWorkingDays - 1 || isWeekend(endDate) || isHoliday) {
            if (currentYear !== endDate.getFullYear()) {
                holidays = await getHolidaysIntegrated(countryId, endDate.getFullYear())
                    .then(res => {
                        if (res && res.data) {
                            return res.data;
                        }
                        return [];
                    })
                    .catch(error => {
                        loggerService.writeLog(error, LOG_TYPES.ERROR);
                        return [];
                    });

                currentYear = endDate.getFullYear();
            }

            totalDays++;
            endDate.setDate(endDate.getDate() + 1);

            isHoliday = false;
            const dateStr = getFormattedDate(endDate);
            if (holidays.indexOf(dateStr) > -1) {
                isHoliday = true;
            }

            if (!isWeekend(endDate) && !isHoliday) {
                workingDaysCount++;
            }
        }

        endDate.setDate(endDate.getDate());
        return { endDate, noOfWorkingDays, totalDays };
    };

    getProjectOrCRName = (dataItem) => {
        if (dataItem.Type === 'Project') {
            return dataItem.Project.ProjectName;
        } else if (dataItem.Type === 'ChangeRequest') {
            return dataItem.ChangeRequest.CRName;
        } else {
            return '';
        }
    };

    isProjectOrCrActive = (dataItem) => {
        if (dataItem.Type === 'Project') {
            return dataItem.Project.StatusConvertedToActive;
        } else if (dataItem.Type === 'ChangeRequest') {
            return dataItem.ChangeRequest.StatusConvertedToActive;
        } else {
            return false;
        }
    };

    handleTabSelect = e => {
        if (this.isMount) {
            this.setState({ selected: e.selected });
            if (e.selected === 1) {
                this.setState({
                    isInfoTab: true
                });
            } else {
                this.setState({
                    isInfoTab: false
                });
            }
        }
    };

    getResourceAllocationsTotalValues = resourceAllocations => {
        let totalCostResourceAllocation = 0;
        let totalRevenueResourceAllocation = 0;
        let totalEffortDaysResourceAllocation = 0;
        let totalCostToRevenueRatioResourceAllocation;

        for (const allocation of resourceAllocations) {
            totalCostResourceAllocation = totalCostResourceAllocation + allocation.TotalCost;
            totalRevenueResourceAllocation = totalRevenueResourceAllocation + allocation.TotalRevenue;
            totalEffortDaysResourceAllocation =
              totalEffortDaysResourceAllocation + allocation.TotalEffortDays;
            totalCostToRevenueRatioResourceAllocation = totalCostResourceAllocation / totalRevenueResourceAllocation;
        }

        this.setState({
            TotalCostResourceAllocation: totalCostResourceAllocation,
            TotalRevenueResourceAllocation: totalRevenueResourceAllocation,
            TotalEffortDaysResourceAllocation: totalEffortDaysResourceAllocation,
            TotalCostToRevenueRatioResourceAllocation: totalCostToRevenueRatioResourceAllocation
        });

        return {
            TotalCostResourceAllocation: totalCostResourceAllocation,
            TotalRevenueResourceAllocation: totalRevenueResourceAllocation,
            TotalEffortDaysResourceAllocation: totalEffortDaysResourceAllocation,
            TotalCostToRevenueRatioResourceAllocation: totalCostToRevenueRatioResourceAllocation
        };
    };

    getOtherExpensesTotalValues = otherExpenses => {
        let nonBillTotal = 0;
        let billTotal = 0;

        for (const expense of otherExpenses) {
            if (expense.BillableToClient === true && expense.Cost) {
                billTotal = billTotal + expense.Cost;
            }

            if (expense.BillableToClient === false && expense.Cost) {
                nonBillTotal = nonBillTotal + expense.Cost;
            }
        }

        if (this.isMount) {
            this.setState({
                NonBillableTotalOtherExpenses: nonBillTotal,
                BillableTotalOtherExpenses: billTotal,
                OtherExpensesTotal: nonBillTotal + billTotal
            });
        }

        return { nonBillTotal: nonBillTotal, billTotal: billTotal };
    };

    // For displaying Est. project duration
    formatValueTwoCell = props => {
        if(props.dataItem.Field == 'Est. Project Duration (Calendar Days)'){
            const values = props.dataItem.Value2 && props.dataItem.Value2 !== null ? props.dataItem.Value2.split("|") : [];
            if(values && values.length > 1){
                return (
                    <td className="font-weight-bold">
                        <span> {values[0]} </span>
                        <br/>
                        <span> {values[1]} </span>
                    </td>
                );
            }
            else{
                return (
                    <td className="font-weight-bold">{props.dataItem.Value2}</td>
                );
            }
        }
        else{
            return (
               <td className="font-weight-bold">{props.dataItem.Value2}</td>
            );
        }
    };

    formatValueFourCell = props => {
        if(props.dataItem.Field == 'Est. Project Duration (Calendar Days)'){
            const values = props.dataItem.Value4 && props.dataItem.Value4 !== null ? props.dataItem.Value4.split("|") : [];
            if(values && values.length > 1){
                return (
                    <td className="font-weight-bold">
                        <span> {values[0]} </span>
                        <br/>
                        <span> {values[1]} </span>
                    </td>
                );
            }
            else{
                return (
                    <td className="font-weight-bold">{props.dataItem.Value4}</td>
                );
            }
        }
        else{
            return (
               <td className="font-weight-bold">{props.dataItem.Value4}</td>
            );
        }
    };

    pageChange = event => {
        this.setState({
          skip: event.page.skip,
          take: event.page.take
        });
    };

    render() {
        const pStyle = {
            'margin-left': '10px'
        };
        return (
          <div className="row cms-grid">
              <div className="col-md-12">

                  <div className="row float-right">
                      <div className="col-md-6">

                      </div>
                      <div className="col-md-6 btn-align-right">
                          <Button primary={true} type="submit" onClick={this.onClick}>
                              Generate Consolidated CMS
                          </Button>
                      </div>
                  </div>
                  <div className="mt-5 ccms-grid">
                      <TabStrip selected={this.state.selected} onSelect={this.handleTabSelect}>
                          <TabStripTab title="Summary">
                              <div className="col-md-1">
                                  <label htmlFor="">
                                      Currency:
                                  </label>
                              </div>
                              <div className="col-md-3">
                                  <DropDownList
                                    data={this.state.currencies}
                                    textField="Code"
                                    dataItemKey="id"
                                    value={this.state.dataValueCurrency}
                                    onChange={this.handleCurrencyChange}
                                  />
                              </div>

                              <div className="row mt-4 resource-chart">

                                  <div className="col-md-12 sub-chart-section">
                                      <Grid width="100%" data={this.state.calculations} rowRender={this.rowRender}
                                            className="summary-grid">
                                          <Column
                                            field="Field"
                                            title="Field"
                                            width="355px"
                                            headerClassName="calculation-grid-header"
                                          />
                                          <Column field="Value1" width="130px" headerClassName="calculation-grid-header" />
                                          <Column
                                            field="Value2"
                                            headerClassName="calculation-grid-header" className="font-weight-bold"
                                            cell={this.formatValueTwoCell}
                                          />
                                          <Column
                                            field="Value3"
                                            width="150px"
                                            headerClassName="calculation-grid-header" className="font-weight-normal"
                                          />
                                          <Column
                                            field="Value4"
                                            headerClassName="calculation-grid-header" className="font-weight-bold"
                                            cell={this.formatValueFourCell}
                                          />
                                          <Column
                                            field="Value5"
                                            headerClassName="calculation-grid-header" className="font-weight-normal"
                                          />
                                          <Column
                                            field="Value6"
                                            headerClassName="calculation-grid-header" className="font-weight-bold"
                                          />
                                      </Grid>
                                  </div>

                              </div>

                          </TabStripTab>

                          <TabStripTab title="Resource Mix">
                              <Grid
                                    style={{ height: '450px' }}
                                    data={this.state.resourceMix.slice(
                                        this.state.skip,
                                        this.state.take + this.state.skip
                                    )}
                                    skip={this.state.skip}
                                    take={this.state.take}
                                    total={this.state.resourceMix.length}
                                    pageable={true}
                                    onPageChange={this.pageChange}
                                    onItemChange={this.itemChange}
                                    onHeaderSelectionChange={this.headerSelectionChange}
                                    onSelectionChange={this.selectionChange}
                                    className="resource-mix-grid"
                                    detail={RowDetails}
                              >
                                  <Column
                                    field="PhaseId"
                                    headerCell={props => <label>Phase</label>}
                                    width="160px"
                                    cell={this.ComboBoxCell}
                                    maxLength={10}
                                  />
                                  <Column
                                    field="SubPhaseId"
                                    headerCell={props => <label>Sub Phase</label>}
                                    width="160px"
                                    cell={this.ComboBoxCell}
                                    maxLength={10}
                                  />
                                  <Column
                                    field="ProjectRole"
                                    headerCell={props => <label className="mandatory">Project Role</label>}
                                    width="200px"
                                    cell={this.ComboBoxCell}
                                    maxLength={40}
                                  />
                                  <Column
                                    field="Designation"
                                    headerCell={props => <label className="mandatory">Designation (Cost)</label>}
                                    width="200px"
                                    cell={this.ComboBoxCell}
                                    maxLength={40}
                                  />
                                  <Column
                                    field="ResourceRegion"
                                    headerCell={props => <label className="mandatory">Resource Region</label>}
                                    width="140px"
                                    cell={this.ComboBoxCell}
                                    maxLength={6}
                                  />
                                  <Column
                                    field="OnsiteStatus"
                                    headerCell={props => <label className="mandatory">Onsite / Offshore</label>}
                                    width="130px"
                                    cell={this.ComboBoxCell}
                                    maxLength={5}
                                  />
                                  <Column
                                      field="StartDate"
                                      headerCell={props => <label>Start Date</label>}
                                      cell={props=><td>{props.dataItem.StartDate && moment(props.dataItem.StartDate).format('MM-DD-YYYY')}</td>}
                                      width="120px"
                                  />
                                  <Column
                                    field="EndDate"
                                    headerCell={props => <label>End Date</label>}
                                    cell={props=><td>{props.dataItem.EndDate && moment(props.dataItem.EndDate).format('MM-DD-YYYY')}</td>}
                                    width="120px"
                                />
                                  <Column
                                    field="EffortDays"
                                    headerCell={props => <label className="mandatory">Effort Days</label>}
                                    width="100px"
                                    maxLength={3}
                                  />
                                  <Column
                                    field="EffortHours"
                                    headerCell={props => <label className="mandatory">Effort Hours</label>}
                                    width="100px"
                                    //cell={this.NumericTextBoxCell}
                                    maxLength={6}
                                  />
                                  <Column
                                    field="AllocationPercentage"
                                    headerCell={props => <label className="mandatory">Allocation (%)</label>}
                                    width="120px"
                                    maxLength={3}
                                  />

                                  {this.props.cmSheetReference === 'crCMS' && this.props.changeRequest.PaymentMethod.id === 3 && (
                                    <Column
                                      field="PaymentMethod"
                                      headerCell={props => <label className="mandatory">Fixed / T&M</label>}
                                      width="100px"
                                    />
                                  )}
                                  <Column
                                    field="RateType"
                                    headerCell={props => <label className="mandatory">Rate Type</label>}
                                    width="200px"
                                    cell={this.ComboBoxCell}
                                    maxLength={10}
                                  />
                                  <Column
                                    field="RateValue"
                                    headerCell={props => <label className="mandatory">Rate Value</label>}
                                    width="100px"
                                    maxLength={7}
                                  />
                                  <Column
                                    field="NoOfResources"
                                    headerCell={props => <label className="mandatory">No. of Resources</label>}
                                    width="130px"
                                    maxLength={2}
                                  />
                              </Grid>

                              <div className="row">
                                  <div className="col-md-3">
                                      <div className="row">
                                          <div className="col-md-5">
                                              <label>Total Cost:</label>
                                          </div>
                                          <div className="col-md-7">
                                              <Input
                                                disabled={true}
                                                value={this.state.TotalCostResourceAllocation ? this.state.TotalCostResourceAllocation.toFixed(2).replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                                ) : 0.00}
                                              />
                                          </div>
                                      </div>
                                  </div>

                                  <div className="col-md-3">
                                      <div className="row">
                                          <div className="col-md-5">
                                              <label>Total Revenue:</label>
                                          </div>
                                          <div className="col-md-7">
                                              <Input
                                                disabled={true}
                                                value={this.state.TotalRevenueResourceAllocation ? this.state.TotalRevenueResourceAllocation.toFixed(2).replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                                ) : 0.00}
                                              />
                                          </div>
                                      </div>
                                  </div>

                                  <div className="col-md-3">
                                      <div className="row">
                                          <div className="col-md-5">
                                              <label>Contracted Effort Days:</label>
                                          </div>
                                          <div className="col-md-7">
                                              <Input
                                                disabled={true}
                                                value={this.state.TotalEffortDaysResourceAllocation ? this.state.TotalEffortDaysResourceAllocation.toFixed(2).replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                                ): 0.00}
                                              />
                                          </div>
                                      </div>
                                  </div>

                                  <div className="col-md-3">
                                      <div className="row">
                                          <div className="col-md-5">
                                              <label>Total Cost to Revenue Ratio:</label>
                                          </div>
                                          <div className="col-md-7">
                                              <Input
                                                disabled={true}
                                                value={this.state.TotalCostToRevenueRatioResourceAllocation ? this.state.TotalCostToRevenueRatioResourceAllocation.toFixed(2).replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                                ): 0.00}
                                              />
                                          </div>
                                      </div>
                                  </div>
                              </div>


                          </TabStripTab>


                          <TabStripTab title="Other Expenses">
                              <Grid
                                data={this.state.otherExpenses}
                                skip={this.state.skip}
                                take={this.state.take}
                                pageable={true}
                                onPageChange={this.pageChange}
                                onItemChange={this.itemChange}
                                onHeaderSelectionChange={this.headerSelectionChange}
                                onSelectionChange={this.selectionChange}
                                className="resource-mix-grid"
                                style={{ height: 'auto' }}
                              >
                                  <Column
                                    field="PhaseId"
                                    headerCell={props => <label>Phase</label>}
                                    width="160px"
                                    cell={this.ComboBoxCell}
                                    maxLength={10}
                                  />
                                  <Column
                                    field="SubPhaseId"
                                    headerCell={props => <label>Sub-Phase</label>}
                                    width="175px"
                                    cell={this.ComboBoxCell}
                                    maxLength={10}
                                  />
                                  <Column
                                    field="ExpenseTypeId"
                                    headerCell={props => <label className="mandatory">Expense Type</label>}
                                    width="175px"
                                    cell={this.ComboBoxCell}
                                    maxLength={30}
                                  />
                                  <Column
                                    field="BudgetedOccurrences"
                                    headerCell={props => <label className="mandatory">Budgeted Occurrence</label>}
                                    width="180px"
                                    cell={this.NumericTextBoxCell}
                                    maxLength={4}
                                  />
                                  <Column
                                    field="BudgetedRate"
                                    headerCell={props => <label className="mandatory">Budgeted Rate</label>}
                                    width="180px"
                                    cell={this.NumericTextBoxCell}
                                    maxLength={7}
                                  />

                                  <Column
                                    field="BillableToClient"
                                    headerCell={props => (
                                      <label className="mandatory">Billable to the Client (OPE)</label>
                                    )}
                                    width="220px"
                                    cell={this.DropDownCell}
                                    maxLength={5}
                                  />
                                  <Column
                                    field="Cost"
                                    headerCell={props => <label>Cost</label>}
                                    width="120px"
                                    format="{0:n2}"
                                    editable={false}
                                    maxLength={7}
                                  />
                              </Grid>

                              <div className="row">
                                  <div className="col-md-3">
                                      <div className="row">
                                          <div className="col-md-5">
                                              <label>Non-Billable Total:</label>
                                          </div>
                                          <div className="col-md-7">
                                              <Input
                                                disabled={true}
                                                value={this.state.NonBillableTotalOtherExpenses.toFixed(2).replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                                )}
                                              />
                                          </div>
                                      </div>
                                  </div>

                                  <div className="col-md-3">
                                      <div className="row">
                                          <div className="col-md-5">
                                              <label>Billable Total:</label>
                                          </div>
                                          <div className="col-md-7">
                                              <Input
                                                disabled={true}
                                                value={this.state.BillableTotalOtherExpenses.toFixed(2).replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                                )}
                                              />
                                          </div>
                                      </div>
                                  </div>

                                  <div className="col-md-3">
                                      <div className="row">
                                          <div className="col-md-5">
                                              <label>Total:</label>
                                          </div>
                                          <div className="col-md-7">
                                              <Input
                                                disabled={true}
                                                value={this.state.OtherExpensesTotal.toFixed(2).replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ','
                                                )}
                                              />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </TabStripTab>

                          {/* <TabStripTab title="File Upload">
                              <div className="column">
                                  <div className="col-md-12">
                                      <React.Fragment>
                                          {this.state.files.map((cmFile, index) => (
                                            <div className="mt-3">
                                                <ul key={index} className="file-upload-list">
                                                    {cmFile.value.map(file => (
                                                        <div className="col-6">
                                                            <li className="file-view mt-2" id={file.uid} key={file.fileName}>
                                                                <div className="row">
                                                                    <div className="col-8">
                                                                        <label>{file.projectOrCrName} - CMS Version {file.referenceVersion}</label><br></br>
                                                                        {file.fileName}
                                                                    </div>
                                                                    <div className="col-4 btn-align-right">
                                                                        <a
                                                                          href={`${apiUrl}/integration/fileUpload/download?path=CMsheet_${file.referenceId}_${file.referenceVersion}_${encodeURIComponent(file.fileName).replace('&', '%26').replace('#', '%23').replace('+', '%2B')}`}
                                                                          style={pStyle} target="_blank"><span className="k-icon k-i-download"></span></a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>
                                                      )
                                                    )}
                                                </ul>
                                                <div className="main-seperator"></div>
                                            </div>
                                          ))}
                                      </React.Fragment>
                                  </div>
                              </div>

                          </TabStripTab> */}
                      </TabStrip>
                  </div>
              </div>

              {this.state.showMessageDialog === true && (
                <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
                    <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
                    <DialogActionsBar>
                        <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                            OK
                        </button>
                    </DialogActionsBar>
                </Dialog>
              )}

              <Loader loading = {this.state.loading} />
          </div>
        );
    }

    toggleMessageDialog = (message, title) => {
        if (this.isMount) {
            this.setState({
                showMessageDialog: !this.state.showMessageDialog,
                dialogMessage: message,
                dialogTitle: title
            });
        }
    };

    ComboBoxCell = props => {
        if (props.field === 'PhaseId') {
            return (
              <RowCellComboBox
                {...props}
                dataList="Phases"
                textField="Text"
                dataItemKey="id"
                dataValueField="dataValuePhase"
                onChangeSelection={this.handleChangeComboBox}
                name="Phase"
                comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                canFilter={true}
                filterChangeCombo={this.filterChangeCombo}
                disabled={this.props.isViewMode}
              />
            );
        }

        if (props.field === 'SubPhaseId') {
            return (
              <RowCellComboBox
                {...props}
                dataList="SubPhases"
                textField="Text"
                dataItemKey="id"
                dataValueField="dataValueSubPhase"
                onChangeSelection={this.handleChangeComboBox}
                name="SubPhase"
                comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                canFilter={true}
                filterChangeCombo={this.filterChangeCombo}
                disabled={this.props.isViewMode}
              />
            );
        }

        if (props.field === 'ProjectRole') {
            return (
              <RowCellComboBox
                {...props}
                dataList="ProjectRoles"
                textField="Name"
                dataItemKey="id"
                dataValueField="dataValueProjectRole"
                onChangeSelection={this.handleChangeComboBox}
                name="ProjectRole"
                comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                canFilter={true}
                filterChangeCombo={this.filterChangeCombo}
                disabled={this.props.isViewMode}
                required={true}
              />
            );
        }

        if (props.field === 'Designation') {
            return (
              <RowCellComboBox
                {...props}
                dataList="CostDesignations"
                textField="Name"
                dataItemKey="id"
                dataValueField="dataValueCostDesignation"
                onChangeSelection={this.handleChangeComboBox}
                name="CostDesignation"
                comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                canFilter={true}
                filterChangeCombo={this.filterChangeCombo}
                disabled={this.props.isViewMode}
                required={true}
              />
            );
        }

        if (props.field === 'ResourceRegion') {
            return (
              <RowCellComboBox
                {...props}
                dataList="ResourceRegions"
                textField="Name"
                dataItemKey="id"
                dataValueField="dataValueResourceRegion"
                onChangeSelection={this.handleChangeComboBox}
                name="ResourceRegion"
                comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                canFilter={true}
                filterChangeCombo={this.filterChangeCombo}
                disabled={this.props.isViewMode}
                required={true}
              />
            );
        }

        if (props.field === 'OnsiteStatus') {
            return (
              <RowCellComboBox
                {...props}
                dataList="OnsiteStatuses"
                textField="Name"
                dataItemKey="id"
                dataValueField="dataValueOnsiteStatus"
                onChangeSelection={this.handleChangeComboBox}
                name="OnsiteStatus"
                comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                disabled={this.props.isViewMode}
                required={true}
              />
            );
        }

        if (props.field === 'RateType') {
            return (
              <RowCellComboBox
                {...props}
                dataList="RateTypes"
                textField="Text"
                dataItemKey="id"
                dataValueField="dataValueRateType"
                onChangeSelection={this.handleChangeComboBox}
                name="RateType"
                disabled={this.props.isViewMode}
                comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                required={true}
              />
            );
        }

        if (props.field === 'ExpenseTypeId') {
            return (
              <RowCellComboBox
                {...props}
                dataList="ExpenseTypes"
                textField="Name"
                dataItemKey="id"
                dataValueField="dataValueExpenseType"
                onChangeSelection={this.handleChangeComboBox}
                name="ExpenseType"
                comboBoxPlaceHolder={this.comboBoxPlaceHolder}
                canFilter={true}
                filterChangeCombo={this.filterChangeCombo}
                disabled={this.props.isViewMode}
              />
            );
        }
    };

    DropDownCell = props => {
        if (props.field === 'BillableToClient') {
            return (
              <RowCellDropDown
                {...props}
                dataList={this.billableStatusValuesT}
                textField="Text"
                dataItemKey="id"
                dataValueField="dataValueBillable"
                onChangeSelection={this.handleChangeDropDown}
                name="BillableStatus"
                disabled={this.props.isViewMode}
              />
            );
        }
    };
}

export default ConsolidatedCMSheetChart;
