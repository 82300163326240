import http from '../../integration/HttpService';

const apiUrl = require('../../config').get(process.env.REACT_APP_ENV).apiUrl;

const apiEndpoint = apiUrl + '/projectManagement/workflow';

/**
 * Save workflow details when create/update.
 * @param workflow.
 * @returns {array} array of workflow details
 */
export function saveWorkflow(workflow) {
  return http.post(apiEndpoint, workflow);
}

/**
 * Save workflow details when create/update.
 * @param workflow.
 * @returns {array} array of workflow details
 */
export function updateWorkflow(workflow, workflowId) {
  const endPoint = apiEndpoint + '/update/' + workflowId;
  return http.put(endPoint, workflow);
}

/**
 * Save workflow Approvers when Edit.
 * @param approvers.
 * @param workflowId.
 * @returns {array} array of workflow Approvers details
 */
export function saveWorkflowApproversOnEdit(approvers, workflowId) {
  const endPoint = apiEndpoint + '/EditWorkflow/' + workflowId;
  return http.post(endPoint, approvers);
}

/**
 * search Workflows by param.
 * @param params.
 * @returns {array} array of Workflows
 */
export function searchWorkflow(params) {
  const endPoint = apiUrl + '/projectManagement/workFlow/search';
  return http.post(endPoint, params);
}

/**
 * Update workflow Status.
 * @param IsActive.
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function updateWorkfowStatus(IsActive, id) {
  const endPoint = apiEndpoint + '/' + id;
  return http.put(endPoint, IsActive);
}

/**
 * deleteApprover workflow by id
 * @param id.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function workfowDelete(id) {
  const endPoint = apiEndpoint + '/approvalworkflow/' + id;
  return http.delete(endPoint);
}

/**
 * Get all workflow Operator (Ex: grater than, less than, equal to).
 * @returns {array} array of workflow Operator list details
 */
export function getWorkflowOperator() {
  const endPoint = apiEndpoint + '/workflowoperator';
  return http.get(endPoint);
}

/**
 * Get all workflow Parameter.
 * @returns {array} array of workflow Parameter
 */
export function getWorkflowParameter() {
  const endPoint = apiEndpoint + '/workflowparameter';
  return http.get(endPoint);
}

/**
 * Retrieve Workflow Types.
 * @returns {array} array of Workflows Type
 */
export function getWorkflowType() {
  const endPoint = apiEndpoint + '/workflowtype';
  return http.get(endPoint);
}

/**
 * Get a list of WF for CM sheet.
 * @param cmSheetId.
 * @param EstimatedStartDate
 * @returns {array} array of WF details.
 */
export function selectWorkflow(cmSheetId, EstimatedStartDate) {
  const endPoint = apiEndpoint + '/selectWorkflow/' + cmSheetId;
  return http.post(endPoint, EstimatedStartDate);
}

/**
 * Get a list of WF for status change.
 * @param cmSheetId.
 * @param EstimatedStartDate
 * @returns {array} array of WF details.
 */
export function selectWorkflowStatus(cmSheetId, EstimatedStartDate) {
  const endPoint = apiEndpoint + '/selectWorkflowStatus/' + cmSheetId;
  return http.post(endPoint, EstimatedStartDate);
}

/**
 * Get a list of WF for Revise CMS Request.
 * @param practice.
 * @returns {array} array of WF details.
 */
export function selectReviseCMSRequestWorkflow(projData) {
  const endPoint = apiEndpoint + '/selectReviseCMSRequestWorkflow';
  return http.post(endPoint, projData);
}

/**
 * Get a list of WF for CM sheet for pool project.
 * @param cmSheetId.
 * @returns {array} array of WF details.
 */
export function selectWorkflowPool(cmSheetId) {
  const endPoint = apiEndpoint + '/selectWorkflowPool/' + cmSheetId;
  return http.post(endPoint);
}

/**
 * Get a list of WF for Resource Requests.
 * @param projCMSId.
 * @returns {array} array of WF details.
 */
export function selectResourceRequestWorkflow(params) {
  const endPoint = apiEndpoint + '/selectResourceRequestWorkflow';
  return http.post(endPoint, params);
}

/**
 * Get a list of WF for Resource Allocations.
 * @param projCMSId.
 * @returns {array} array of WF details.
 */
export function selectResourceAllocationWorkflow(params) {
  const endPoint = apiEndpoint + '/selectResourceAllocationWorkflow';
  return http.post(endPoint, params);
}

/**
 * Update workflow Approver.
 * @returns {object}  response 0 or 1 (true or false) as an array
 * @param workflowData
 * @param newApproverId
 */
export function replaceApproverOfWorkflows(workflowData, newApproverId) {
  const endPoint = apiEndpoint + '/replaceApprover/' + newApproverId;
  return http.put(endPoint, workflowData);
}

/**
 * Delegate workflow Approver.
 * @returns {object}  response 0 or 1 (true or false) as an array
 * @param params
 */
export function delegateApproverOfWorkflows(params) {
  const endPoint = apiEndpoint + '/delegateApprover';
  return http.post(endPoint, params);
}

/**
 * Delete workflow Approver.
 * @returns {object}  response 0 or 1 (true or false) as an array
 * @param workflowData
 * @param currentApproverId
 */
export function deleteApproverOfWorkflows(workflowData, currentApproverId) {
  const endPoint = apiEndpoint + '/deleteApprover/' + currentApproverId;
  return http.put(endPoint, workflowData);
}

/**
 * Update workflow Parameter.
 * @returns {object}  response 0 or 1 (true or false) as an array
 * @param workflowData
 */
export function replaceParameterOfWorkflows(workflowData) {
  const endPoint = apiEndpoint + '/replaceParameter/' + workflowData.newDefinition.id;
  return http.put(endPoint, workflowData);
}

/**
 * Set WF for Resource Requests.
 * @param workflowData.
 * @returns {object}  response 0 or 1 (true or false) as an array
 */
export function setWorkFlow(workflowData) {
  const endPoint = apiEndpoint + '/setWorkFlow';
  return http.post(endPoint, workflowData);
}


/**
 * Get a list of WF for TimeSheet Unfreeze Request.
 * @param practice.
 * @returns {array} array of WF details.
 */
 export function selectTimeSheetUnfreezeRequestWorkflow() {
  const endPoint = apiEndpoint + '/selectTimeSheetUnfreezeRequestWorkflow';
  return http.post(endPoint);
}

/**
 * search Workflows by param.
 * @param params.
 * @returns {array} array of Workflows
 */
 export function searchWorkflowNew(params) {
  const endPoint = apiUrl + '/projectManagement/workFlow/searchnew';
  return http.post(endPoint, params);
}

 /**
 * getAllPendingReviseCMSRequests - get All Pending Revise CMS Requests
 * @returns {Object}  array of Requests
 */
 export function getAllPendingReviseCMSRequests() {
  const endPoint = apiUrl + '/projectManagement/workFlow/getAllPendingReviseCMSRequests'
  return http.get(endPoint);
}

/**
 * Get a list of WF for Invoice Request.
 * @param practice.
 * @returns {array} array of WF details.
 */
export function selectInvoiceRequestWorkflow(projData) {
  const endPoint = apiEndpoint + '/selectInvoiceRequestWorkflow';
  return http.post(endPoint, projData);
}

/**
 * getAllPendingInvoiceRequests - get All Pending Invoice Requests
 * @returns {Object}  array of Requests
 */
export function getAllPendingInvoiceRequests() {
  const endPoint = apiUrl + '/projectManagement/workFlow/getAllPendingInvoiceRequests'
  return http.get(endPoint);
}


