import React, { Component, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import approvedStatus from "../../enums/ApprovedStatusTypes";
import {Tooltip} from "reactstrap";

class DurationCell extends Component {

        state = {
            tooltipOpen:false
        }

    toggle = () =>{
        this.setState({
            tooltipOpen : !this.state.tooltipOpen
            }
        )
    }
   
    checkOveride = data => {
            if(this.props.status === 3){
                if (data && data.allocationHours && data.allocationHours.length !== 0) {
                    let times = data.allocationHours.filter(x => x.user === data.UserId)[0];
                    if (typeof times !== 'undefined') {
                        if (times.isOverride) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            else {
                let task = this.props.resource.tasks.filter(x => x.taskId === data.TaskId)[0];
                if (task && task.allocationHours && task.allocationHours.length !== 0) {
                    let times = task.allocationHours.filter(x => x.user === data.UserId)[0];
                    if (typeof times !== 'undefined') {
                        if (times.isOverride) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            };
            }

    render() {
        const dataItem = this.props.dataItem;



        if(this.props.status === 1){
            return (
                <td>
                    <td className="d-flex align-items-center justify-center" >
                        {(dataItem.IsBillable === true && dataItem.RateType === 5) ||
                        dataItem.IsOverEightHour === true ||
                        this.checkOveride(dataItem) ? (
                            <div>
                            <span
                                className=" d-inline-block my-auto text-truncate ta-desc"
                                style={{ color: 'red' }}
                                id={'tooltip-' + dataItem.Id}
                            >
                {dataItem.Duration}
                                {dataItem.IsShiftTask && dataItem.FromDateTime !== null
                                    ? `(${dataItem.FromDateTime} - ${dataItem.ToDateTime})`
                                    : null}
              </span>
                            <Tooltip
                                placement="right"
                                isOpen={this.state.tooltipOpen && ((dataItem.IsOverEightHour || (dataItem.IsBillable === true && dataItem.RateType === 5) || (this.checkOveride(dataItem))))}
                                target={'tooltip-' + dataItem.Id}
                                toggle={this.toggle}
                                className="timecard-tooltip"
                            >
                                <div className="text-left">
                                {dataItem.ResonOfMove !== null && (
                                    <div>Reason to Move : {dataItem.ResonOfMove}</div>
                                    )}
                                    {dataItem.IsBillable === true && dataItem.RateType === 5 ? (
                                        <div>
                                            Warning : This user has a non-billable allocation and should not be entering billable
                                            timesheets.
                                        </div>
                                    ) : null}
                                    {dataItem.IsOverEightHour === true ? (
                                        <div>Warning : This user has entered more than 8 hours of billable work.</div>
                                    ) : null}
                                    {this.checkOveride(dataItem) ? (
                                        <div>
                                            Warning : This user has utilized all the allocated billable time for the Project.
                                        </div>
                                    ) : null}
                                </div>
                            </Tooltip>
                            </div>
                        ) : (
                            <span className=" d-inline-block my-auto text-truncate ta-desc">
                {dataItem.Duration}
                                {dataItem.IsShiftTask && dataItem.FromDateTime !== null
                                    ? `(${dataItem.FromDateTime} - ${dataItem.ToDateTime})`
                                    : null}
              </span>
                        )}

                    </td>
                </td>
            );
        }
        else if(this.props.status === 3){
            return (
                <td>
                    <td className="d-flex align-items-center justify-center" >
                        {((dataItem.IsBillable === true && dataItem.RateType === 5) ||
                        dataItem.IsOverEightHour === true ||
                        this.checkOveride(dataItem)) && (dataItem.UserId === window.LOGGED_USER.user) ? (
                            <div>
                            <span
                                className=" d-inline-block my-auto text-truncate ta-desc"
                                style={{ color: 'red' }}
                                id={'tooltip-' + dataItem.Id}
                            >

                {this.props.setTotalTime(dataItem.Duration)}
                                {dataItem.IsShiftTask && dataItem.FromDateTime !== null
                                    ? `(${dataItem.FromDateTime} - ${dataItem.ToDateTime})`
                                    : null}
              </span>
                            <Tooltip
                                placement="right"
                                isOpen={this.state.tooltipOpen && ((dataItem.IsOverEightHour || (dataItem.IsBillable === true && dataItem.RateType === 5) || (this.checkOveride(dataItem)))) && ((dataItem.UserId === window.LOGGED_USER.user))}
                                target={'tooltip-' + dataItem.Id}
                                toggle={this.toggle}
                                className="timecard-tooltip"
                            >
                                <div className="text-left">
                                {dataItem.ResonOfMove !== null && (
                                    <div>Reason to Move : {dataItem.ResonOfMove}</div>
                                    )}
                                    {dataItem.IsBillable === true && dataItem.RateType === 5 ? (
                                        <div>
                                            Warning : This user has a non-billable allocation and should not be entering billable
                                            timesheets.
                                        </div>
                                    ) : null}
                                    {dataItem.IsOverEightHour === true ? (
                                        <div>Warning : This user has entered more than 8 hours of billable work.</div>
                                    ) : null}
                                    {this.checkOveride(dataItem) ? (
                                        <div>
                                            Warning : This user has utilized all the allocated billable time for the Project.
                                        </div>
                                    ) : null}
                                </div>
                            </Tooltip>
                            </div>
                        ) : (
                            <div>
                            <span className=" d-inline-block my-auto text-truncate ta-desc"  id={'tooltip-' + dataItem.Id}>
                {this.props.setTotalTime(dataItem.Duration)}
                                {dataItem.IsShiftTask && dataItem.FromDateTime !== null
                                    ? `(${dataItem.FromDateTime} - ${dataItem.ToDateTime})`
                                    : null}
              </span>
                                    <Tooltip
                                        placement="right"
                                        isOpen={this.state.tooltipOpen && dataItem.ResonOfMove}
                                        target={'tooltip-' + dataItem.Id}
                                        toggle={this.toggle}
                                        className="timecard-tooltip"
                                    >
                                        <div className="text-left">
                                            {dataItem.ResonOfMove !== null && (
                                                <div>Reason to Move : {dataItem.ResonOfMove}</div>
                                            )}
                                        </div>
                                    </Tooltip>
              </div>)}

                    </td>
                </td>
            );
        }

    }
}

export default DurationCell;
