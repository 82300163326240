import React, { Component } from "react";
import ResourceRequestStatusENUMS from "./ResourceRequestStatusEnums"
import moment from "moment";



class ResourceRequestSubLineStartDateCell extends Component {


    onCalculateEndDate = async (startDate) => {
        let dataItem = this.props.props.dataItem;
        let countryId;
        let totalEffortDays;

        if(dataItem.EndDate === null || dataItem.EndDate === ""){

            totalEffortDays = (dataItem.EffortDays/(dataItem.AllocationPercentage /100));

            if (dataItem.OnsiteStatus === 'Onsite') {
                countryId = dataItem.Type === "Project" ? dataItem.Project.CountryId : dataItem.ChangeRequest.CountryId;
            } else {
                countryId = dataItem.dataValueResourceRegion.DefaultCountryId;
            }

            let { endDate, noOfWorkingDays, totalDays } =  await this.props.props.calculateNewEstimatedEndDate(totalEffortDays,moment(startDate).format('YYYY-MM-DD'),countryId);

            endDate = moment(endDate).format('YYYY-MM-DD')
            dataItem.EndDate = endDate
        }
    };

    render() {

        let dataItem = this.props.props.dataItem;
        let dataItems = this.props.dataItem;
        let projectStartDate = null;

        if(dataItem.Type === 'Project'){
            projectStartDate = dataItem.Project.StartDate
        }
        else{
            projectStartDate = dataItem.ChangeRequest.StartDate
        }

        if(dataItems.StartDate === null || dataItems.StartDate === ''){

            if(dataItems.Phase && dataItems.Phase.StartDate){

                let value = dataItems.Phase;

                if(value.StartDate < projectStartDate){
                    dataItem.StartDate = null;
                    return <td>{moment(dataItems.StartDate).format('MM-DD-YYYY')}</td>;
                }
                else {

                    this.onCalculateEndDate(value.StartDate)
                    return <td>{moment(value.StartDate).format('MM-DD-YYYY')}</td>;
                }

            }
            else if (dataItems.SubPhase && dataItems.SubPhase.StartDate){
                let value = dataItems.SubPhase;

                if(value.StartDate < projectStartDate){
                    dataItems.StartDate = null;
                    return <td>{moment(dataItems.StartDate).format('MM-DD-YYYY')}</td>;
                }
                else {
                    this.onCalculateEndDate(value.StartDate)
                    return <td>{moment(value.StartDate).format('MM-DD-YYYY')}</td>;
                }

            }
            else {

                this.onCalculateEndDate(projectStartDate)
                return <td>{moment(projectStartDate).format('MM-DD-YYYY')}</td>;
            }
        }
        else{

            if(new Date(dataItems.StartDate) < new Date(projectStartDate)){
                dataItems.StartDate = null;
                return <td>{moment(dataItems.StartDate).format('MM-DD-YYYY')}</td>;
            }
            else {

                this.onCalculateEndDate(dataItems.StartDate)

                return <td>{moment(dataItems.StartDate).format('MM-DD-YYYY')}</td>;
            }
        }

    }
}

export default ResourceRequestSubLineStartDateCell;
